import { gql } from '@apollo/client';

export const earningPointRule = `
... on EarningByAmount {
  id
  earningType
  venueIds
  earningPoint
  amountSpend
  includedTax
}
`;

export const rewardRule = `
... on RewardByDiscountEntireSale {
  id
  rewardType
  rewardName
  venueIds
  pointsRequired
  discountAmount
  discountType
  maximumDiscountAmount
}`;

export const loyaltySettings = `
  singularTerm
  pluralTerm
`;
export const GET_LOYALTY_SETTINGS = gql`
  query LoyaltySettings {
    loyaltyPrograms {
      settings {
        ${loyaltySettings}
      }
      rules {
        rewardRules {
          ${rewardRule}
        }
        earningRules {
          ${earningPointRule}
        }
      }
    }
  }
`;

export const CREATE_AND_UPDATE_EARNING_RULES = gql`
  mutation CreateOrUpdateLoyaltyProgramEarningRule($input: CreateOrUpdateLoyaltyProgramEarningRuleInput) {
    createOrUpdateLoyaltyProgramEarningRule(input: $input) {
      ${earningPointRule}
    }
  }
`;

export const DELETE_EARNING_RULE = gql`
  mutation DeleteEarningRule($id: ID!) {
    deleteEarningRule(id: $id)
  }
`;
export const DELETE_REWARD_RULE = gql`
  mutation DeleteRewardRule($id: ID!) {
    deleteRewardRule(id: $id)
  }
`;

export const UPDATE_LOYALTY_SETTINGS = gql`
  mutation UpdateLoyaltySettings($input: LoyaltyProgramSettingsInput) {
    updateLoyaltyProgramSettings(input: $input) {
      ${loyaltySettings}
    }
  }
`;

export const CREATE_AND_UPDATE_REWARD_RULES = gql`
  mutation CreateOrUpdateLoyaltyProgramRewardRule($input: CreateOrUpdateLoyaltyProgramRewardRuleInput) {
    createOrUpdateLoyaltyProgramRewardRule(input: $input) {
      ${rewardRule}
    }
  }
`;
