import { useCallback, useEffect, useMemo, useState } from 'react';
import DeviceInfo from 'react-native-device-info';
import { Device } from '@hitz-group/domain';
import { useDeviceId } from './useDeviceId';
import { Platform } from 'react-native';
import UAParser from 'ua-parser-js';
import { useSession } from './useSession';

type VersionNum = { versionNum: string };
export type DeviceInfo = Partial<Device> & VersionNum;

export function useDeviceInfo(): DeviceInfo {
  const { deviceId } = useDeviceId();
  const [info, setInfo] = useState<DeviceInfo>();
  const [session] = useSession();

  const currentDeviceId = session.device?.uuid || deviceId;

  const versionNum =
    DeviceInfo &&
    DeviceInfo.getVersion() &&
    !(DeviceInfo.getVersion() === 'unknown')
      ? DeviceInfo.getVersion()
      : '0.0.1';

  const getDeviceInfo = useCallback(async () => {
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      const info = {
        name: await DeviceInfo.getDeviceName(),
        details: `${DeviceInfo.getSystemName()} ${DeviceInfo.getDeviceType()} (${await DeviceInfo.getBaseOs()} ${DeviceInfo.getSystemVersion()})`,
        uuid: currentDeviceId,
        appVersion: `${DeviceInfo.getApplicationName()} (${DeviceInfo.getVersion()})`,
      };
      setInfo(info as DeviceInfo);
    } else if (Platform.OS === 'web' && navigator) {
      const parser = new UAParser(navigator.userAgent);
      const result = parser.getResult();

      const info = {
        name: result.device?.vendor || navigator.appName,
        details: `${result.device?.vendor || navigator.appName} ${
          result.device?.type || ''
        } (${result.os?.name} ${result.os?.version})`,
        uuid: currentDeviceId,
        appVersion: `${result.browser?.name} (${result.browser?.version})`,
      };
      setInfo(info as DeviceInfo);
    }
  }, [currentDeviceId]);

  useEffect(() => {
    deviceId && getDeviceInfo();
  }, [deviceId, getDeviceInfo]);

  return useMemo(
    () => ({
      versionNum: versionNum,
      ...info,
    }),
    [versionNum, info],
  );
}
