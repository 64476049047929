import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import Layout from '../../../components/POSLayout/POSLayout';
import { LeftArrow } from '../../../components/HeaderIcons/HeaderIcons';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { useTranslation } from '@hitz-group/localization';
import { Order, PaymentType } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import DropDown from '../../../components/FormInput/DropDown';
import Button from '../../../components/Button/Button';
import Title from '../../../components/Title/Title';
import { GET_ALL_ORDER_TYPES } from '../../../graphql/orders';
import { useQuery } from '@apollo/client/react/hooks';
import { useNotification } from '../../../hooks/Notification';
import { parseApolloError } from '../../../utils/errorHandlers';
import {
  mapOrdersToOrdersView,
  filterOrderHistoryData,
} from '../../../utils/OrderHistoryHelper';
import OrderHistoryView, {
  OrderHistoryDataProps,
} from '../../../components/OrderDataView/OrderHistoryView';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import { filterBy } from '../../../utils/dateHelper';
import format from 'date-fns/format';
import {
  DrawerButton,
  SubscriptionStatusButton,
} from '../../../components/HeaderIcons/HeaderIcons';
import { useModal } from '@hitz-group/rn-use-modal';
import { usePaymentTypes } from '../../../hooks/app/usePaymentTypes';
import { OrderStatus, ORDERS_LIMIT } from '@hitz-group/domain';
import { useOrders, CursorType } from '../../../hooks/app/orders/useOrders';
import { useIsFocused } from '@react-navigation/native';
import { useReportSettings } from '../../../hooks/app/useReportSettings';
import Wrapper from '../../../components/Wrapper/Wrapper';
import { usePrinting } from '../../../hooks/PrintingProvider';
import { endOfDay } from '../../../utils/dateHelper';
import { CartProvider } from '../../../../src/hooks/CartProvider';
import * as styles from './OrderHistory.style';
import RefundWorkFlow from './RefundWorkFlow';
import { useSubscription } from '@apollo/client/react/hooks';
import { OrderEvents } from '../../../graphql/subscriptions';
import { useSession } from '../../../hooks/app/useSession';
import NewOrderButton from '../../../components/NewOrder/NewOrderButton';
interface PaymentTypeDisplay extends PaymentType {
  value: string;
  label: string;
}

export enum DateRangeFilter {
  'Today' = 'T',
  'Yesterday' = 'Y',
  'Custom' = 'C',
}

const OrderHistory: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const [filter, setFilter] = useState<string | undefined>();
  const [dateFilter, setDateFilter] = useState(translate('orderHistory.today'));
  const [startDate, setStartDate] = useState<number | undefined>(
    filterBy(filter || 'T'),
  );
  const [endDate, setEndDate] = useState<number | undefined>(
    // setting to end of the day
    endOfDay(new Date()),
  );
  const [paymentTypeFilter, setPaymentTypeFilter] = useState('');
  const [orderTypeFilter, setOrderTypeFilter] = useState('');
  const [keywordFilter, setKeyWordFilter] = useState('');
  const isFocussed = useIsFocused();
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [month, setMonth] = useState('');
  const [showSidePanel, setShowSidePanel] = useState<Boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string>('');

  const headerLeft = <LeftArrow />;
  const headerTitle = (
    <Title primary containerStyle={css(styles.titleContainerStyle)}>
      {translate('navigation.orderHistory')}
    </Title>
  );
  const headerRight = useMemo(
    () => (
      <>
        <SubscriptionStatusButton />
        <NewOrderButton />
        <DrawerButton />
      </>
    ),
    [],
  );

  const { dateTime, getDateTime } = useReportSettings();

  const {
    orders,
    error: ordersDataError,
    getPaginatedOrders,
    getOrdersFromCache,
  } = useOrders();

  const { paymentTypes, status } = usePaymentTypes();

  const { data: orderTypes, error: orderTypesError } =
    useQuery(GET_ALL_ORDER_TYPES);

  const { printBill } = usePrinting();
  const [session] = useSession();
  const currentStoreId = session?.currentStore?.id;
  const deviceId = session?.device?.id;
  const { data: subscriptionData } = useSubscription(OrderEvents, {
    variables: {
      storeId: currentStoreId,
      deviceId,
    },
  });

  useEffect(() => {
    subscriptionData &&
      setTimeout(() => getOrdersFromCache(OrderStatus.COMPLETED), 2000);
    subscriptionData &&
      setTimeout(() => getOrdersFromCache(OrderStatus.REFUNDED), 2000);
  }, [getOrdersFromCache, subscriptionData]);

  useEffect(() => {
    getDateTime();
  }, [getDateTime]);

  useEffect(() => {
    isFocussed && getOrdersFromCache(OrderStatus.COMPLETED);
    isFocussed && getOrdersFromCache(OrderStatus.REFUNDED);
  }, [isFocussed, getOrdersFromCache]);

  useEffect(() => {
    if (dateTime) {
      const startingHour = parseInt(dateTime.startTime.split(':')[0]);
      const startingHourMinutes = parseInt(dateTime.startTime.split(':')[1]);

      setHours(startingHour);
      setMinutes(startingHourMinutes);
      setMonth(dateTime.startMonth);
      setStartDate(
        prevStartDate =>
          prevStartDate &&
          new Date(prevStartDate).setHours(startingHour, startingHourMinutes),
      );
      setEndDate(
        prevStartDate => prevStartDate && endOfDay(new Date(prevStartDate)),
      );
    }
  }, [dateTime]);

  useEffect((): void => {
    if (ordersDataError) {
      showNotification({
        error: true,
        message: ordersDataError,
      });
    }
  }, [ordersDataError, showNotification]);

  useEffect(() => {
    if (status.error) {
      showNotification({
        error: true,
        message: status.error,
      });
    }
  }, [status.error, showNotification]);

  useEffect(() => {
    if (orderTypesError) {
      showNotification({
        error: true,
        message: parseApolloError(orderTypesError),
      });
    }
  }, [orderTypesError, showNotification]);

  useEffect(() => {
    if (!!startDate && !!endDate) {
      if (filter === DateRangeFilter.Today) {
        setDateFilter(translate('orderHistory.today'));
      } else if (filter === DateRangeFilter.Yesterday) {
        setDateFilter(translate('orderHistory.yesterday'));
      } else
        setDateFilter(
          `${format(startDate.valueOf(), 'dd.MM.yy')}-${format(
            endDate.valueOf(),
            'dd.MM.yy',
          )}`,
        );
    }
  }, [startDate, endDate, filter, translate]);

  const onPressPrintReceipt = useCallback(
    async (orderId: string, nthPaymentToPrint?: number) => {
      const order = orders[orderId];
      if (order) {
        const result = await printBill(order, nthPaymentToPrint);
        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
      }
    },
    [printBill, showNotification, orders],
  );

  const ordersArray = useMemo(() => Object.values(orders), [orders]);

  const filteredData = useMemo((): OrderHistoryDataProps[] => {
    return filterOrderHistoryData(
      mapOrdersToOrdersView(
        (ordersArray || []).filter(
          x =>
            x.status === OrderStatus.COMPLETED ||
            x.status === OrderStatus.REFUNDED,
        ),
        orderTypes ? orderTypes.orderTypes : [],
      ),
      paymentTypeFilter,
      orderTypeFilter,
      keywordFilter,
      endDate,
      startDate,
    );
  }, [
    ordersArray,
    orderTypeFilter,
    paymentTypeFilter,
    startDate,
    endDate,
    keywordFilter,
    orderTypes,
  ]);

  const orderTypesValues = useMemo(
    () => [
      { value: '', label: translate('orderHistory.viewAll') },
      ...(orderTypes ? orderTypes.orderTypes : []),
    ],
    [orderTypes, translate],
  );

  const getOrderTypeIdByName = useCallback(
    orderTypeName => {
      const orderType = (
        orderTypesValues as {
          id: string;
          value: string;
        }[]
      ).find(x => x.value === orderTypeName);

      return orderType?.id;
    },
    [orderTypesValues],
  );

  const selectedOrderTypeId = useMemo(() => {
    return getOrderTypeIdByName(orderTypeFilter);
  }, [orderTypeFilter, getOrderTypeIdByName]);

  useEffect(() => {
    if (endDate && startDate) {
      getPaginatedOrders({
        after: '',
        first: ORDERS_LIMIT,
        filter: {
          status: OrderStatus.COMPLETED,
          toDateTime: endDate?.valueOf(),
          fromDateTime: startDate?.valueOf(),
        },
      } as CursorType);

      getPaginatedOrders({
        after: '',
        first: ORDERS_LIMIT,
        filter: {
          status: OrderStatus.REFUNDED,
          toDateTime: endDate?.valueOf(),
          fromDateTime: startDate?.valueOf(),
        },
      } as CursorType);
    }
  }, [endDate, getPaginatedOrders, startDate]);

  const onChangeFilter = useCallback(
    (filter: string) => {
      setFilter(filter);
      if (filter !== DateRangeFilter.Custom)
        setStartDate(filterBy(filter, hours, minutes, month));
      if (filter === DateRangeFilter.Yesterday) {
        if (hours && minutes)
          setEndDate(new Date(Date.now()).setHours(hours, minutes));
        else setEndDate(endOfDay(new Date(filterBy(filter) || '')));
      } else setEndDate(endOfDay(new Date(Date.now()))); // setting to end of the day
    },
    [hours, minutes, month],
  );

  const onSelectCustomDates = useCallback(
    (startDate?: Date, endDate?: Date) => {
      setFilter(DateRangeFilter.Custom);
      if (!!startDate && !!endDate) {
        setDateFilter(
          `${format(
            new Date(startDate).setHours(hours, minutes),
            'dd.MM.yy',
          )}-${format(new Date(endDate).setHours(hours, minutes), 'dd.MM.yy')}`,
        );
        setStartDate(new Date(startDate).setHours(hours, minutes));
        if (startDate.valueOf() === endDate.valueOf())
          setEndDate(endOfDay(new Date(endDate.valueOf() + 86400000)));
        else setEndDate(endOfDay(new Date(endDate)));

        getPaginatedOrders({
          after: '',
          first: ORDERS_LIMIT,
          filter: {
            status: OrderStatus.COMPLETED,
            toDateTime: endDate.valueOf(),
            fromDateTime: startDate.valueOf(),
            ...(keywordFilter && {
              searchText: keywordFilter,
            }),
            ...(selectedOrderTypeId && {
              orderType: selectedOrderTypeId,
            }),
            ...(paymentTypeFilter && {
              paymentType: paymentTypeFilter,
            }),
          },
        } as CursorType);
        getPaginatedOrders({
          after: '',
          first: ORDERS_LIMIT,
          filter: {
            status: OrderStatus.REFUNDED,
            toDateTime: endDate.valueOf(),
            fromDateTime: startDate.valueOf(),
            ...(keywordFilter && {
              searchText: keywordFilter,
            }),
            ...(selectedOrderTypeId && {
              orderType: selectedOrderTypeId,
            }),
            ...(paymentTypeFilter && {
              paymentType: paymentTypeFilter,
            }),
          },
        } as CursorType);
      }
    },
    [
      hours,
      minutes,
      getPaginatedOrders,
      keywordFilter,
      paymentTypeFilter,
      selectedOrderTypeId,
    ],
  );

  const onPressDateRange = useCallback(
    () =>
      showModal(
        <DateRangePicker
          startDate={startDate ? new Date(startDate) : undefined}
          endDate={endDate ? new Date(endDate) : undefined}
          onSelectFilter={onChangeFilter}
          onPressApply={onSelectCustomDates}
          currentFilter={filter || 'T'}
        />,
        { onBackdropPress: closeModal },
      ),
    [
      showModal,
      endDate,
      filter,
      onChangeFilter,
      onSelectCustomDates,
      startDate,
      closeModal,
    ],
  );

  const getSelectedPaymentTypeByID = useCallback(
    selectedID => {
      return (
        paymentTypes.find(paymentType => paymentType.id === selectedID)?.id ||
        ''
      );
    },
    [paymentTypes],
  );

  const paymentTypesValues = useMemo(
    () => [
      { value: '', label: 'View All' },
      ...(paymentTypes ? (paymentTypes as PaymentTypeDisplay[]) : []),
    ],
    [paymentTypes],
  );

  const onPressNext = useCallback(
    (page: number, maxItemsPerPage) => {
      const totalPagesRendered = ordersArray.length / (maxItemsPerPage || 13);
      // loading pages just before user clicking on last but one page
      page >= Math.ceil(totalPagesRendered) - 1 &&
        getPaginatedOrders({
          after: '',
          first: ORDERS_LIMIT,
          filter: {
            status: OrderStatus.COMPLETED,
            fromDateTime: startDate,
            toDateTime: endDate,
            ...(keywordFilter && {
              searchText: keywordFilter,
            }),
            ...(selectedOrderTypeId && {
              orderType: selectedOrderTypeId,
            }),
            ...(paymentTypeFilter && {
              paymentType: paymentTypeFilter,
            }),
          },
        });

      getPaginatedOrders({
        after: '',
        first: ORDERS_LIMIT,
        filter: {
          status: OrderStatus.REFUNDED,
          fromDateTime: startDate,
          toDateTime: endDate,
          ...(keywordFilter && {
            searchText: keywordFilter,
          }),
          ...(selectedOrderTypeId && {
            orderType: selectedOrderTypeId,
          }),
          ...(paymentTypeFilter && {
            paymentType: paymentTypeFilter,
          }),
        },
      });
    },
    [
      getPaginatedOrders,
      ordersArray,
      startDate,
      endDate,
      keywordFilter,
      paymentTypeFilter,
      selectedOrderTypeId,
    ],
  );

  const fetchOrdersByFilter = useCallback(
    (prop, value) => {
      const orderTypeValue = getOrderTypeIdByName(selectedOrderTypeId);
      const paymentTypeValue = paymentTypeFilter;
      const searchText = keywordFilter;
      if (prop === 'orderType') {
        setOrderTypeFilter(value);
      } else if (prop === 'paymentType') {
        setPaymentTypeFilter(getSelectedPaymentTypeByID(value));
      } else if (prop === 'searchText') {
        setKeyWordFilter(value);
      }
      getPaginatedOrders({
        after: '',
        first: ORDERS_LIMIT,
        filter: {
          status: OrderStatus.COMPLETED,
          fromDateTime: startDate,
          toDateTime: endDate,
          ...(searchText && {
            searchText: searchText,
          }),
          ...(orderTypeValue && {
            orderType: orderTypeValue,
          }),
          ...(paymentTypeValue && {
            paymentType: paymentTypeValue,
          }),
          ...(value && {
            [prop]: value,
          }),
        },
      });

      getPaginatedOrders({
        after: '',
        first: ORDERS_LIMIT,
        filter: {
          status: OrderStatus.REFUNDED,
          fromDateTime: startDate,
          toDateTime: endDate,
          ...(searchText && {
            searchText: searchText,
          }),
          ...(orderTypeValue && {
            orderType: orderTypeValue,
          }),
          ...(paymentTypeValue && {
            paymentType: paymentTypeValue,
          }),
        },
      });
    },
    [
      getSelectedPaymentTypeByID,
      getOrderTypeIdByName,
      getPaginatedOrders,
      keywordFilter,
      selectedOrderTypeId,
      paymentTypeFilter,
      startDate,
      endDate,
    ],
  );

  const onShowSidePanel = useCallback((id: string): void => {
    setShowSidePanel(true);
    setSelectedOrderId(id);
  }, []);

  const changeSidePanelState = useCallback((bool: boolean) => {
    setShowSidePanel(bool);
  }, []);

  const onCompleteRefund = useCallback(() => {
    getOrdersFromCache(OrderStatus.REFUNDED);
  }, [getOrdersFromCache]);

  const getCachedRefundOrder = useCallback(
    (id: string): Order | undefined => {
      return orders[id];
    },
    [orders],
  );

  return (
    <Layout
      title={translate('navigation.orderHistoryTitle', {
        appName: translate('appName'),
      })}
      headerLeft={headerLeft}
      headerTitle={headerTitle}
      headerRight={headerRight}
      testID="order-history-page"
    >
      <View style={css(styles.filterContainer)}>
        <View>
          <View style={css(styles.dateRangeContainer)}>
            <Text style={css(styles.dateRangeTitleStyle)}>
              {translate('orderHistory.dateRange')}
            </Text>
          </View>
          <Button
            testID="dateRange"
            title={filter ? dateFilter : translate('orderHistory.today')}
            containerStyle={css(styles.dateRangeButtonStyle)}
            size="small"
            icon="AngleDown"
            iconPosition="right"
            iconContainerStyle={css(styles.iconContainerStyle)}
            iconProps={{ color: 'black' }}
            onPress={onPressDateRange}
            labelStyle={css(styles.buttonLabelStyle)}
          />
        </View>
        <DropDown
          values={orderTypesValues}
          title={translate('orderHistory.orderType')}
          style={css(styles.dropDownStyle)}
          selectedValue={orderTypeFilter || 'View All'}
          onValueChange={orderTypeValue =>
            fetchOrdersByFilter('orderType', orderTypeValue)
          }
          extraMainViewStyle={css(styles.dropDownMainViewStyle)}
          extraStyle={css(styles.dropdownExtraStyle)}
          extraViewStyle={css(styles.dropdownViewStyle)}
          textStyle={css(styles.lableStyle)}
          angleDownIconStyle={css(styles.angleDownIconStyle)}
          testID="order-history-order-types"
        />
        <DropDown
          values={paymentTypesValues}
          title={translate('orderHistory.paymentType')}
          style={css(styles.dropDownStyle)}
          selectedValue={paymentTypeFilter || 'View All'}
          onValueChange={paymentType =>
            fetchOrdersByFilter('paymentType', paymentType)
          }
          extraMainViewStyle={css(styles.dropDownMainViewStyle)}
          extraStyle={css(styles.dropdownExtraStyle)}
          extraViewStyle={css(styles.dropdownViewStyle)}
          textStyle={css(styles.lableStyle)}
          angleDownIconStyle={css(styles.angleDownIconStyle)}
          testID="order-history-payment-types"
        />
        <SearchBar
          secondary
          placeholder={translate('orderHistory.searchPlaceholder')}
          containerStyle={css(styles.searchContainerStyle)}
          onChange={searchText => fetchOrdersByFilter('searchText', searchText)}
          textInputStyle={css(styles.searchTextInputStyle)}
          testID="order-history-search"
        />
      </View>

      <Wrapper>
        <View style={css(styles.orderContainerStyle)}>
          <OrderHistoryView
            data={filteredData}
            onPressPrintReceipt={onPressPrintReceipt}
            onPressNext={onPressNext}
            showOrderSidePanel={onShowSidePanel}
          />
        </View>
      </Wrapper>
      <CartProvider>
        <RefundWorkFlow
          orderId={selectedOrderId}
          showSidePanel={showSidePanel}
          setShowSidePanel={changeSidePanelState}
          onCompleteRefund={onCompleteRefund}
          getCachedRefundOrder={getCachedRefundOrder}
        />
      </CartProvider>
    </Layout>
  );
};
export default OrderHistory;
