import { useMutation } from '@apollo/client/react/hooks';
import {
  User,
  LoginResponse,
  App,
  DeviceCodeLoginResponse,
  Organization,
  Device,
  DeviceProfile,
} from '@hitz-group/domain';
import { Venue, Store } from '@hitz-group/domain';
import { getExpiresAfter } from '../../state/preferences';
import { useCallback, useMemo } from 'react';
import {
  LOGIN_BY_DEVICE_CODE_MUTATION,
  LOGIN_MUTATION,
} from '../../graphql/session';
import { Session } from '../../state/Session';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { useOrderNumber } from '../orders/useOrderNumber';
import { useDeviceId } from './useDeviceId';
import { useSession } from './useSession';
import { AuthState, tokenUtility } from '../../state/tokenUtility';
import { onboardingUtility } from '../../state/onboardingUtility';
import { useAppToken } from './useAppToken';
import { useDevicePushNotification } from './useDevicePushNotification';
import * as Sentry from '@sentry/browser';
import { useDeviceInfo } from './useDeviceInfo';
import { identifyLogRocketUser } from '../../utils/logRocketHelper';

type UserExt = Omit<User, 'venue'> & { venues: Venue[] };
type LoginResponseExt = Omit<LoginResponse, 'user'> & { user: UserExt };
type LoginByDeviceCodeResponseExt = Omit<DeviceCodeLoginResponse, 'users'> & {
  users: UserExt[];
};

export function useLogin(): {
  login: (email: string, password: string) => Promise<Session | undefined>;
  loginByDeviceCode: (deviceCode: string) => Promise<Session | undefined>;
  loading: boolean;
  error?: string;
} {
  const [, setSession] = useSession();
  const { registerPushNotification, setStoreId } = useDevicePushNotification();
  const [loginMutation, { error, loading }] = useMutation(LOGIN_MUTATION, {
    onError: noopHandler,
  });
  const [
    loginByDeviceCodeMutation,
    { error: loginByDeviceCodeError, loading: loginByDeviceCodeLoading },
  ] = useMutation(LOGIN_BY_DEVICE_CODE_MUTATION, {
    onError: noopHandler,
  });

  const { requestAppToken, loading: appTokenLoading } = useAppToken();

  const { deviceId } = useDeviceId();
  const { appVersion, details } = useDeviceInfo();
  const { setOrderCounter } = useOrderNumber();

  const login = useCallback(
    async (email: string, password: string) => {
      try {
        const loginResponse = await loginMutation({
          variables: { email, password },
        });

        const response = loginResponse.data?.login as LoginResponseExt;

        if (response) {
          const { token, refreshToken, expiresIn, user } = response;
          tokenUtility.setTokenInfo({
            token,
            expiresAfter: expiresIn ? getExpiresAfter(expiresIn) : undefined,
            refreshToken,
            authState: AuthState.LOGGED_IN,
          });
          const appToken = await requestAppToken(App.BACKOFFICE);
          if (appToken) {
            tokenUtility.setTokenInfo({
              activeApp: App.BACKOFFICE,
              backOfficeToken: appToken,
            });
          } else {
            // Try to logout the user again!
            // Mostly it wont be the case, as this application needs internet connection (backoffice login - as of now)
            tokenUtility.clearToken();
          }

          const organizations = user?.organizations || [];
          const currentOrganization = organizations?.[0];

          if (currentOrganization) {
            Sentry.setUser({ email: user.email });
            Sentry.setTag('organization', currentOrganization.id);
            Sentry.setTag('organization_name', currentOrganization.name);
          }

          if (currentOrganization?.onboarding) {
            onboardingUtility.setOnboardingInfo(currentOrganization.onboarding);
          } else {
            onboardingUtility.clearOnboardingInfo();
          }
          let currentStore = undefined as unknown as Store;

          const currentVenue = (user?.venues as Venue[])?.find(venue => {
            const store = venue?.stores?.find(store =>
              store.devices.some(device => device.uuid === deviceId),
            );
            if (store) {
              currentStore = store;
            }
            return !!store;
          });

          const currentDevice = currentStore?.devices?.find(
            device => device.uuid === deviceId,
          );

          if (currentDevice) {
            setStoreId(currentStore.id);
            registerPushNotification(currentDevice.id);
          }

          currentDevice &&
            setOrderCounter(currentDevice.previousOrder?.orderNumber || '');

          const defaultDeviceProfile = currentStore?.deviceProfiles?.find(
            deviceProfile =>
              !!currentDevice &&
              deviceProfile.id === currentDevice.deviceProfile?.id,
          );

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let currentDeviceProfile: any;
          if (defaultDeviceProfile) {
            currentDeviceProfile = Object.assign({}, defaultDeviceProfile);
            currentDeviceProfile.defaultOrderType =
              defaultDeviceProfile?.defaultOrderType ||
              defaultDeviceProfile?.orderTypes?.[0];
          }

          const session: Session = {
            token,
            refreshToken,
            expiresIn,
            expiresAfter: expiresIn ? getExpiresAfter(expiresIn) : undefined,
            activeApp: App.BACKOFFICE,
            backOfficeToken: appToken,
            user,
            currentOrganization,
            availableOrganizations: organizations,
            authorized: true,
            currentVenue,
            currentStore,
            deviceProfile: currentDeviceProfile,
            device: currentDevice,
            settings: {
              showScrollSetting: true,
              showAdvancedCartActionsSetting: true,
            },
          };

          identifyLogRocketUser(session);
          setSession(session);
          return session;
        }
      } catch {}
      return undefined;
    },
    [
      loginMutation,
      setSession,
      deviceId,
      setOrderCounter,
      registerPushNotification,
      setStoreId,
      requestAppToken,
    ],
  );

  const loginByDeviceCode = useCallback(
    async (deviceCode: string) => {
      try {
        const loginResponse = await loginByDeviceCodeMutation({
          variables: {
            deviceCodeInput: {
              deviceCode,
              deviceUuid: deviceId,
              appVersion,
              deviceName: details,
            },
          },
        });
        const response = loginResponse.data
          ?.loginByDeviceCode as LoginByDeviceCodeResponseExt;
        if (response) {
          const {
            appToken,
            users,
            organization,
            store,
            venue,
            deviceId: responseDeviceId,
          } = response;

          // After 1 hour it will logged out
          const expiresIn = 3600;

          if (appToken) {
            tokenUtility.setTokenInfo({
              token: appToken,
              posAppToken: appToken,
              authState: AuthState.LOGGED_IN,
              activeApp: App.POS_APP,
              expiresAfter: expiresIn ? getExpiresAfter(expiresIn) : undefined,
            });
          } else {
            // Try to logout the user again!
            // Mostly it wont be the case, as this application needs internet connection (backoffice login - as of now)
            tokenUtility.clearToken();
          }

          let currentStore: Store | undefined = undefined;
          let currentVenue: Venue | undefined = undefined;
          let currentOrganization: Organization | undefined;

          users.some(user => {
            currentOrganization = user.organizations?.find(
              item => item.id === organization,
            ) as Organization;
            return !!currentOrganization;
          });

          users.some(user => {
            currentVenue = user.venues.find(item => item.id === venue) as Venue;
            return !!currentVenue;
          });
          if (currentVenue) {
            currentStore = (currentVenue as Venue)?.stores?.find(
              item => item.id === store,
            ) as Store;
          }

          if (currentOrganization) {
            Sentry.setUser({ email: users[0]?.email });
            Sentry.setTag(
              'organization',
              (currentOrganization as Organization).id,
            );
            Sentry.setTag(
              'organization_name',
              (currentOrganization as Organization).name,
            );
          }

          let currentDevice: Device | undefined = undefined;
          if (currentStore) {
            currentDevice = (currentStore as Store)?.devices.find(
              item => item.id === responseDeviceId,
            ) as Device;
          }

          if (currentDevice) {
            setStoreId(store);
            registerPushNotification(responseDeviceId);
          }

          currentDevice &&
            setOrderCounter(currentDevice?.previousOrder?.orderNumber || '');

          let defaultDeviceProfile: DeviceProfile | undefined;
          if (currentStore) {
            defaultDeviceProfile = (
              currentStore as Store
            )?.deviceProfiles?.find(
              deviceProfile =>
                !!currentDevice &&
                deviceProfile.id === currentDevice.deviceProfile?.id,
            );
          }

          let currentDeviceProfile: Partial<DeviceProfile> | undefined;
          if (defaultDeviceProfile) {
            currentDeviceProfile = Object.assign({}, defaultDeviceProfile);
            currentDeviceProfile.defaultOrderType =
              defaultDeviceProfile?.defaultOrderType ||
              defaultDeviceProfile?.orderTypes?.[0];
          }

          const session: Session = {
            token: appToken,
            activeApp: App.POS_APP,
            posAppToken: appToken,
            expiresIn,
            expiresAfter: expiresIn ? getExpiresAfter(expiresIn) : undefined,
            refreshToken: appToken,
            // FIXME: Right now we are using the first user as default from user array
            // FIXME:  Due to this the POS user can try to navigate to office user
            user: users[0],
            currentOrganization,
            availableOrganizations: currentOrganization
              ? [currentOrganization as Organization]
              : [],
            authorized: true,
            currentVenue,
            currentStore,
            deviceProfile: currentDeviceProfile,
            device: currentDevice,
            settings: {
              showScrollSetting: true,
              showAdvancedCartActionsSetting: true,
            },
          };

          identifyLogRocketUser(session);
          setSession(session);
          return session;
        }
      } catch {}
      return undefined;
    },
    [
      loginByDeviceCodeMutation,
      deviceId,
      appVersion,
      details,
      setOrderCounter,
      setSession,
      setStoreId,
      registerPushNotification,
    ],
  );

  return useMemo(
    () => ({
      login,
      loginByDeviceCode,
      loading: loading || appTokenLoading || loginByDeviceCodeLoading,
      error: error
        ? parseApolloError(error)
        : loginByDeviceCodeError
        ? parseApolloError(loginByDeviceCodeError)
        : undefined,
    }),
    [
      login,
      loginByDeviceCode,
      loading,
      appTokenLoading,
      loginByDeviceCodeLoading,
      error,
      loginByDeviceCodeError,
    ],
  );
}
