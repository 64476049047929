import React from 'react';
import { Text, ViewStyle, View, TextStyle } from 'react-native';
import { StyleFn, RenderProps, TextAlignment } from '@hitz-group/domain';
import { FelaComponent } from 'react-fela';
const getTextColor = (backgroundColor: string): string => {
  if (backgroundColor && backgroundColor.match(/^#([0-9a-f]{6})$/gi)) {
    return parseInt(backgroundColor.replace('#', '0x'), 16) > 0xffffff / 2
      ? '#1d2331'
      : '#FFFFFF';
  } else return '#1d2331';
};

export interface TitleProps {
  success?: boolean;
  secondary?: boolean;
  transparent?: boolean;
  danger?: boolean;
  circular?: boolean;
  pill?: boolean;
  keypad?: boolean;
  selected?: boolean;
  color?: string;
  textColor?: string;
  primary?: boolean;
  alignTitle?: TextAlignment;
  containerStyle?: ViewStyle | ViewStyle[];
  labelStyle?: TextStyle;
  numberOfLines?: number;
  testID?: string;
}

const textStyle: StyleFn = ({
  theme,
  transparent,
  success,
  danger,
  secondary,
  circular,
  fontSize,
  pill,
  keypad,
  color,
  textColor,
  primary,
  alignTitle,
}) => ({
  color: transparent
    ? theme.colors.paragraph
    : success || danger || secondary || pill || primary
    ? theme.colors.white
    : keypad
    ? theme.colors.primary
    : color
    ? textColor ?? getTextColor(color)
    : theme.colors.charcoal,
  fontSize: circular ? theme.fontSize.larger : fontSize ?? theme.fontSize.small,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
  textAlign: alignTitle,
  letterSpacing: -0.4,
});

const Title: React.FC<TitleProps> = (props: TitleProps) => (
  <FelaComponent style={textStyle} {...props}>
    {({ style }: RenderProps): React.ReactNode => (
      <View style={props.containerStyle}>
        <Text
          numberOfLines={props.numberOfLines}
          ellipsizeMode="tail"
          style={[style, props.labelStyle]}
          {...props}
          testID={props.testID}
        />
      </View>
    )}
  </FelaComponent>
);

Title.defaultProps = {
  alignTitle: 'center',
  numberOfLines: 3,
};

export default Title;
