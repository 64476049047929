import { StyleFn } from '@hitz-group/domain';

export const headerStyle: StyleFn = () => ({
  width: 430,
  height: 44,
  alignSelf: 'center',
  marginHorizontal: 8,
});

export const cancelButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 10,
});

export const modalContainer: StyleFn = () => ({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const scrollViewMaxHeight: StyleFn = () => ({
  minHeight: 225,
  maxHeight: 225,
});

export const modalStyle: StyleFn = () => ({
  width: 430,
  height: 324,
  borderRadius: 6,
  justifyContent: 'flex-start',
  margin: 0,
  paddingTop: 0,
});

export const customBodyStyle: StyleFn = ({ theme }) => ({
  paddingVertical: 0,
  marginTop: theme.spacing.small,
  height: '100%',
});

export const buttonOptionsContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing.small,
  // maxHeight: 250,
});

export const optionButtonStyle: StyleFn = ({ theme }) => ({
  width: '49%',
  paddingHorizontal: theme.spacing.small,
  marginVertical: theme.spacing.small / 2,
  height: 100,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.colors.boxBorder,
});

export const btnStyle: StyleFn = () => ({
  height: '100%',
  width: '100%',
});
