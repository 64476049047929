import { useIntercom as useIntercomWeb } from 'react-use-intercom';
import { useEffect, useMemo } from 'react';
import useBehaviorSubjectState from '../app/useSubjectState';
import { closeButtonVisibilityController } from './intercomObservables';

type CallbackFn = () => void;
export interface Company {
  companyId: string;
  name: string;
  customAttributes: {
    venueName: string;
    storeName: string;
  };
}
export interface User {
  email: string;
  userId: string;
  name: string;
  companies?: Company[];
}

export function useIntercom() {
  const { boot, shutdown, hide, show } = useIntercomWeb();

  const { setValue: setVisible } = useBehaviorSubjectState<boolean>(
    closeButtonVisibilityController,
  );

  const handler = useMemo(() => {
    return {
      start(user: User, callback?: CallbackFn) {
        boot(user);
        callback && callback();
        setVisible(true);
      },
      stop() {
        shutdown();
        setVisible(false);
      },

      show() {
        show();
      },

      hide() {
        hide();
      },
    };
  }, [boot, setVisible, shutdown, show, hide]);

  useEffect(() => {
    return () => {
      handler.stop();
    };
  }, [handler]);

  return handler;
}
