import {
  StyleFn,
  OrderType,
  CreateSalesChannelInput,
} from '@hitz-group/domain';
import React, { useEffect, useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import FormInput from '../../../components/FormInput/FormInput';
import Button from '../../../components/Button/Button';
import { CREATE_SALES_CHANNEL } from '../../../graphql/settings';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@hitz-group/localization';
import MultipleSelect from '../../../components/MultipleSelect/MultipleSelect';
import Modal from '../../../components/Modals/Modal';
import scale from '../../../common/theme';

interface SalesChannel {
  id: string;
  name: string;
  isActive: boolean;
  integrationStatus: string;
  orderTypes?: OrderType[];
}

interface SalesChannelModalProps {
  orderTypeData: OrderType[];
  onAddSalesChannel: (salesChanel: SalesChannel) => void;
}

const modalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const formInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: '100%',
});

const formTextStyle: StyleFn = () => ({
  alignSelf: 'flex-start',
});

const selectContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing.medium * 2,
  height: theme.input.height,
  minHeight: scale.moderateScale(27),
  marginTop: scale.moderateScale(5),
});

const selectContentStyle: StyleFn = () => ({
  height: 100,
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginLeft: 0,
  marginTop: theme.spacing.small * 3,
});

const buttonLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const iconTitleStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  textTransform: 'none',
  letterSpacing: -0.5,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.big / 2,
  height: 26,
  width: 26,
  paddingLeft: -theme.spacing.small / 2,
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 260,
  height: 38,
  marginTop: theme.spacing.medium,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const touchableStyle: StyleFn = () => ({
  minHeight: 45,
  flexWrap: 'wrap',
});

export const CreateSalesChannelModal: React.FC<SalesChannelModalProps> = ({
  orderTypeData,
  onAddSalesChannel,
}: SalesChannelModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [createForm, setCreateForm] = useState({} as CreateSalesChannelInput);
  const { showNotification } = useNotification();

  const onChange = useCallback((prop: string, value) => {
    setCreateForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onChangeMultiSelect = useCallback(
    (prop: string, value: string[]) => {
      setCreateForm({
        ...createForm,
        [prop]: value,
      });
    },
    [createForm],
  );

  const [createSalesChannel, createOperation] = useMutation(
    CREATE_SALES_CHANNEL,
    {
      onError: noopHandler,
    },
  );

  const onCreateSalesChannel = useCallback(() => {
    createSalesChannel({
      variables: {
        input: {
          name: createForm.name,
          isActive: createForm.isActive || false,
          orderTypes: createForm.orderTypes || [],
          integrationStatus: '',
        } as CreateSalesChannelInput,
      },
    });
  }, [createForm, createSalesChannel]);

  useEffect(() => {
    if (createOperation.data) {
      onAddSalesChannel({
        id: createOperation.data.createSalesChannel.id,
        name: createOperation.data.createSalesChannel.name,
        isActive: createOperation.data.createSalesChannel.isActive,
        orderTypes: (createForm.orderTypes || []).map(id => ({ id })),
      } as SalesChannel);
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficeSettings.newSalesChannelSuccessfullyAdded',
        ),
      });
    }
  }, [
    createOperation.data,
    showNotification,
    closeModal,
    onAddSalesChannel,
    translate,
    createForm.orderTypes,
  ]);

  useEffect(() => {
    if (createOperation.error) {
      showNotification({
        error: true,
        message: parseApolloError(createOperation.error),
      });
    }
  }, [createOperation.error, showNotification]);

  return (
    <Modal
      title={translate('backOfficeSettings.createChannelName')}
      onDismiss={closeModal}
      contentStyle={css(modalContainerStyle)}
    >
      <FormInput
        title={translate('backOfficeSettings.channelName')}
        placeholder={translate('backOfficeSettings.channelName')}
        value={createForm.name}
        containerStyle={css(formInputStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(null, 'name')}
      />

      <MultipleSelect
        label={translate('backOfficeSettings.assignedOrderTypes')}
        values={orderTypeData.map(order => ({
          label: order.name,
          value: order.id,
        }))}
        selectedValues={createForm.orderTypes || []}
        containerStyle={css(selectContainerStyle)}
        onValueChange={(value): void =>
          onChangeMultiSelect('orderTypes', value)
        }
        searchLabel={translate('backOfficeSettings.OrderTypes')}
        searchPlaceHolder={translate('backOfficeSettings.searchOrders')}
        contentStyle={css(selectContentStyle)}
        touchableStyle={css(touchableStyle)}
      />

      <Button
        title={translate('backOfficeSettings.activateNow')}
        labelStyle={css(iconTitleStyle)}
        onPress={onChange.bind(null, 'isActive', !createForm.isActive)}
        fluid
        iconPosition={'left'}
        containerStyle={css(checkBoxTitleContainer)}
        iconContainerStyle={css(iconContainerStyle)}
        icon={createForm.isActive ? 'toggle-on' : 'toggle-off'}
        iconProps={{
          color: createForm.isActive
            ? theme.colors.success
            : theme.colors.paragraph,
          size: 26,
        }}
      />

      <Button
        title={translate('backOfficeSettings.createChannelName')}
        containerStyle={css(buttonContainerStyle)}
        labelStyle={css(buttonLabelStyle)}
        color={theme.colors.green}
        onPress={onCreateSalesChannel}
      />
    </Modal>
  );
};
