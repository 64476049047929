import React, { useEffect, useMemo } from 'react';
import { View } from 'react-native';
import CartItems from '../../../../../components/Cart/CartItems';
import CartSummary from '../../../../../components/CartSummary/CartSummary';
import { useFela } from 'react-fela';
import {
  Order,
  StyleFn,
  RejectionReason,
  IntegrationApps,
} from '@hitz-group/domain';
import * as styles from '../../../../../components/RefundScreen/CartSection.style';
import CartActions from './CartActions';
import { useIntegrationPartners } from '../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { useSession } from '../../../../../hooks/app/useSession';

const orderNoteStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

interface CartSectionProps {
  order: Order;
  onPressComplete: (id: string) => void;
  onPressAccept: (ids: string[]) => void;
  onPressReject: (id: string, reason: RejectionReason) => void;
  onPressReprintDocket: (id: string) => void;
  customContainerStyle?: StyleFn;
  disableBtn?: boolean;
  onOrderPay?: (order: Order) => void;
}

const CartSection: React.FC<CartSectionProps> = ({
  order,
  onPressComplete,
  onPressAccept,
  onPressReject,
  onPressReprintDocket,
  customContainerStyle,
  disableBtn,
  onOrderPay,
}) => {
  const { css } = useFela();
  const { integrationPartners, getIntegrationPartnerSettings } =
    useIntegrationPartners();
  const [session] = useSession();

  useEffect(() => {
    getIntegrationPartnerSettings({
      store: session.currentStore?.id,
      appName: IntegrationApps.OOLIO_STORE,
    });
  }, [getIntegrationPartnerSettings, session.currentStore?.id]);

  const integrationPartnerSettings = useMemo(() => {
    return Object.values(integrationPartners).find(
      intergrationPartner =>
        intergrationPartner.store === session.currentStore?.id,
    )?.preferences?.onlineOrdering;
  }, [integrationPartners, session.currentStore?.id]);

  const deliveryFee = useMemo(() => {
    return (
      order?.deliveryFee ||
      integrationPartnerSettings?.deliverySettings?.deliveryFee
    );
  }, [
    integrationPartnerSettings?.deliverySettings?.deliveryFee,
    order.deliveryFee,
  ]);

  return (
    <View style={css([styles.cartStyle, customContainerStyle])}>
      <CartItems
        items={order?.orderItems}
        containerStyle={css(styles.cartItemsStyle)}
        orderNote={order?.orderNote}
        deliveryNote={order?.shippingAddress?.extraAddressInfo}
        noteStyle={css(orderNoteStyle)}
      />
      <CartSummary
        taxes={order?.taxes}
        discounts={order?.discounts || []}
        subTotal={order?.subTotal || 0}
        totalDue={order?.totalPrice || 0}
        discountAmount={order?.discountAmount || 0}
        surchargeAmount={order?.surchargeAmount || 0}
        adjustments={order.adjustments}
        deliveryFee={deliveryFee}
        tip={order.tip}
        serviceCharge={order.serviceCharge}
      />

      <CartActions
        onPressAccept={onPressAccept}
        onPressComplete={onPressComplete}
        onPressReject={onPressReject}
        onPressReprintDocket={onPressReprintDocket}
        order={order}
        disableBtn={disableBtn}
        onOrderPay={onOrderPay}
      />
    </View>
  );
};

export default CartSection;
