import { StyleFn } from '@hitz-group/domain/dist';

import { StyleSheet } from 'react-native';

export const seatManagementContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: 0,
  borderTopLeftRadius: theme.radius.small,
  borderTopRightRadius: theme.radius.small,
  flexDirection: 'row',
  width: 280,
  height: 'auto',
  marginBottom: 4,
  backgroundColor: theme.colors.white,
  paddingHorizontal: 0,
});
export const keyButtonStyle: StyleFn = ({ theme }) => ({
  height: 35,
  width: 54,
  marginHorizontal: 1,
  marginVertical: 1,
  borderRadius: theme.radius.small,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  backgroundColor: theme.colors.greyLight,
  padding: theme.padding.medium,
});

export const cartStyle: StyleFn = ({ theme }) => ({
  width: 300,
  flexDirection: 'column',
  paddingLeft: theme.spacing.small,
  paddingRight: theme.spacing.small,
  paddingTop: theme.padding.medium,
});

export const orderActionsStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingVertical: theme.padding.medium,
});

export const cartActionsStyle: StyleFn = () => ({
  marginVertical: 10,
});
export const cartActionButton: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
});

export const styles = StyleSheet.create({
  headerTitle: {
    width: 300,
    height: 40,
  },
  headerRight: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  orderActionButton: {
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    width: 138,
  },
  refundButton: {
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    height: 80,
    width: 280,
  },
  labelStyle: {
    paddingLeft: 8,
  },
});
