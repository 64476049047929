import { noopHandler } from '../../utils/errorHandlers';
import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import { UPDATE_ORG_BASIC_DETAILS } from '../../graphql/settings';
import {
  OnboardingAction,
  OnboardingSection,
  OnboardingArea,
  OnboardingCheckList,
  UpdateOrganizationInput,
} from '@hitz-group/domain';
import { isLoading } from '../../utils/apolloErrorResponse.util';
import { useSession } from './useSession';
import { onboardingUtility } from '../../state/onboardingUtility';
import { first } from 'rxjs/operators';
import { stripProperties } from '../../utils/stripObjectProps';

export interface UseOrganization {
  updateOnboardingStatus: (
    areaName: OnboardingArea,
    sectionKey: OnboardingSection,
    checklistName: OnboardingCheckList,
    action: OnboardingAction,
  ) => Promise<boolean>;
  loading?: boolean;
}

export function useOnboarding(): UseOrganization {
  const [session] = useSession();

  const [updateOrganizationRequest, updateOrganizationResponse] = useMutation(
    UPDATE_ORG_BASIC_DETAILS,
    {
      onError: noopHandler,
    },
  );

  const RESPONSE_ENTITIES = [updateOrganizationResponse];

  const loading = isLoading(RESPONSE_ENTITIES);

  const updateOnboardingStatus = useCallback(
    async (
      areaName: OnboardingArea,
      sectionKey: OnboardingSection,
      checklistName: OnboardingCheckList,
      action: OnboardingAction,
    ): Promise<boolean> => {
      if (session?.currentOrganization?.id) {
        const prevOnboardingInfo = await onboardingUtility.getOnboardingInfo$
          .pipe(first())
          .toPromise();
        if (prevOnboardingInfo) {
          const { areas } = prevOnboardingInfo;
          let alreadyChecked = false;
          const newOnboarding = areas.map(area => {
            // TODO: see if this logic can be moved to API. so that any un-intended changes cause any side effects (from a different application!)
            if (areaName === area.name) {
              const sections = area.sections.map(section => {
                if (sectionKey === section.key) {
                  const checkList = section.checklist.map(check => {
                    if (
                      checklistName === check.name &&
                      action === check.action
                    ) {
                      if (check.status) {
                        alreadyChecked = true;
                      }
                      return {
                        ...check,
                        status: true,
                      };
                    }
                    return check;
                  });
                  return {
                    ...section,
                    checklist: checkList,
                  };
                }
                return section;
              });
              return {
                name: area.name,
                sections,
              };
            }
            return area;
          });

          if (newOnboarding.length && !alreadyChecked) {
            const updateOrganizationData = {
              id: session.currentOrganization.id,
              onboarding: {
                areas: newOnboarding,
              },
            } as UpdateOrganizationInput;
            const response = await updateOrganizationRequest({
              variables: {
                input: stripProperties(updateOrganizationData, '__typename'),
              },
            });
            if (response?.data && response?.data?.updateOrganization) {
              onboardingUtility.setOnboardingInfo(
                response.data.updateOrganization.onboarding,
              );
              return true;
            }
          }
        }
      }
      return false;
    },
    [session?.currentOrganization?.id, updateOrganizationRequest],
  );

  return useMemo(
    () => ({
      updateOnboardingStatus,
      loading,
    }),
    [updateOnboardingStatus, loading],
  );
}
