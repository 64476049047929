/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from '@apollo/client';

const featuresQuery = `
  id
  name
  enabledByDefault
`;

export const userDetailQuery = `
  name
  id
  email
  pin
  organizations {
    id
    name
    country
    logoUrl
    createdAt
    businessIdentifier
    timezone
    features{
      ${featuresQuery}
    }
    onboarding {
      areas {
        name
        sections {
          key
          checklist {
            name
            status
            action
          }
          order
        }
      }
    }
  }
  venues {
    id
    name
    phone
    features{
      ${featuresQuery}
    }
    customerAccountSettings {
      defaultMaxOrderLimit
      defaultMaxBalanceLimit
      scheduleStatements
    }
    address {
    line1
    line2
    city
    state
    postalCode
    country
    }
    adjustments {
      id
      name
      amount
      adjustmentUnit
      adjustmentType
      allowOnPaymentType
    }
    stores {
      id
      name
      checkoutOptions {
        enableRoundOff
        roundOffValue
      }
      deviceProfiles {
        id
        name
        defaultSaleScreen
        postSaleScreen
        enableCashManagement
        enableFloorView
        enableCourses
        enableSeatManagement
        enableOnlineOrders
        receiptPrintOption
        isDefault
        status
        menu {
          id
          name
        }
        orderTypes {
          id
          name
          code
        }
        defaultOrderType
        {
          id
          name
          code
        }
        functionMap {
          id
          name
          functionActions {
            id
            name
            action
            params
            isGroup
            groupId
            color
            order
            feature
            featureContext
          }
        }
        sections {
          id
          name
          defaultPrefix
          defaultTableShape
          isActive
          tableCount
          tables {
            id
            name
            shape
            guestCount
            position {
              top
              left
            }
          }
        }
      }
      devices {
        id
        name
        uuid
        details
        salesPrefix
        returnPrefix
        deviceCode
        paymentTerminal {
          id
          name
          uuid
        }
        previousOrder {
          orderNumber
        }
        appVersion
        deviceProfile {
          id
        }
        customerDisplay {
          id
          name
          isPaired
          orgLogo
          backgroundImage
          promoImage
        }
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      user {
        ${userDetailQuery}
      }
      token
      refreshToken
      expiresIn
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation logout($token: String!) {
    logout(token: $token) {
      success
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      success
      message
    }
  }
`;

export const GET_APP_TOKEN = gql`
  mutation clientAppToken($source: App!) {
    clientAppToken(source: $source) {
      token
    }
  }
`;

export const LOGIN_BY_DEVICE_CODE_MUTATION = gql`
  mutation loginByDeviceCode($deviceCodeInput: DeviceCodeLoginInput!) {
    loginByDeviceCode(input: $deviceCodeInput) {
      appToken
      organization
      store
      deviceId
      venue
      users {
        ${userDetailQuery}
      }
    }
  }
`;
