import { useTranslation } from '@hitz-group/localization';
import { StyleFn } from '@hitz-group/domain';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import Button from '../../../../../components/Button/Button';
import IconButton from '../../../../../components/Button/IconButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import { useModal } from '@hitz-group/rn-use-modal';
import { useNotification } from '../../../../../hooks/Notification';
import { CreateProductTypeInput, ProductType } from '@hitz-group/domain/dist';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { useProductTypes } from '../../../../../hooks/app/useProductTypes';
import { Helmet } from 'react-helmet';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import { pick } from 'lodash';
import scale, { isWeb } from '../../../../../common/theme';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const scrollStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});
const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: isWeb ? '50%' : '100%',
  alignSelf: 'center',
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 300,
  height: theme.input.height,
  justifyContent: 'center',
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});
export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});
interface ProductTypeRowProps {
  index: number;
  productType: ProductType;
  onChange: (index: number, id: string, value: string) => void;
  onDeleteProductType: (index: number, id: string) => void;
}

const ProductTypeRow: React.FC<ProductTypeRowProps> = ({
  productType,
  onDeleteProductType,
  onChange,
  index,
}: ProductTypeRowProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  const { showModal, closeModal } = useModal();

  const onPressDelete = useCallback((): void => {
    if (productType.id === undefined) {
      onDeleteProductType(index, productType.id);
    } else {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: productType.name,
          })}
          onConfirm={() => {
            onDeleteProductType(index, productType.id);
            closeModal();
          }}
        />,
      );
    }
  }, [
    showModal,
    closeModal,
    onDeleteProductType,
    translate,
    productType,
    index,
  ]);
  return (
    <View style={css(rowStyle)} testID="product-type-row">
      <FormInput
        testID="product-type-name"
        placeholder={translate('backOfficeProductTypes.productTypeName')}
        value={productType.name}
        containerStyle={css(formInputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(null, index, productType?.id)}
      />
      <View style={css(flexStyle)}></View>
      <IconButton
        icon="TrashAlt"
        iconSize={24}
        containerSize={38}
        containerStyle={css(closeIconContainerStyle)}
        iconStyle={css(closeIconStyle)}
        onPress={onPressDelete}
      />
    </View>
  );
};

type FormState = Record<string, ProductType & { isChanged: boolean }>;

export const ProductTypes: React.FC = () => {
  const { css } = useFela();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<FormState>({});
  const [newProductTypes, setNewProductTypes] = useState<
    CreateProductTypeInput[]
  >([]);

  const [deletedProductTypeId, setDeletedProductTypeId] = useState<string>('');

  const {
    loading,
    error,
    productTypes,
    getProductTypes,
    createProductTypes,
    createdProductTypeIds,
    updateProductTypes,
    updatedProductTypeIds,
    deleteProductType,
    deletedProductType,
  } = useProductTypes();

  const { closeModal } = useModal();

  useEffect(() => {
    getProductTypes();
  }, [getProductTypes]);

  useEffect(() => {
    if (productTypes) {
      setForm(productTypes as FormState);
    }
  }, [productTypes]);

  useEffect(() => {
    if (deletedProductType && deletedProductTypeId) {
      delete form[deletedProductTypeId];
    }
  }, [deletedProductTypeId, deletedProductType, form]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onDeleteProductType = useCallback(
    (index: number, id: string): void => {
      if (!id) {
        const currentProductTypesCount = Object.keys(form).length;
        const updatedProductTypes = newProductTypes;
        newProductTypes.splice(index - currentProductTypesCount, 1);
        setNewProductTypes([...updatedProductTypes]);
      } else {
        setDeletedProductTypeId(id);
        deleteProductType(id);
      }
    },
    [deleteProductType, newProductTypes, form],
  );

  useEffect((): void => {
    if (deletedProductType) {
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficeProductTypes.productTypeDeletedSuccessfully',
        ),
      });
    }
  }, [deletedProductType, showNotification, translate, closeModal]);

  useEffect((): void => {
    if (updatedProductTypeIds && updatedProductTypeIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            updatedProductTypeIds.length > 1
              ? 'backOfficeProductTypes.productTypesUpdatedSuccessfully'
              : 'backOfficeProductTypes.productTypeUpdatedSuccessfully'
          }`,
        ),
      });
    }
  }, [updatedProductTypeIds, showNotification, translate]);

  useEffect((): void => {
    if (createdProductTypeIds && createdProductTypeIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            createdProductTypeIds.length > 1
              ? 'backOfficeProductTypes.productTypesCreatedSuccessfully'
              : 'backOfficeProductTypes.productTypeCreatedSuccessfully'
          }`,
        ),
      });
      setNewProductTypes([]);
    }
  }, [createdProductTypeIds, showNotification, translate, setNewProductTypes]);

  const onChangeName = useCallback(
    (index: number, id: string, value: string): void => {
      if (!id) {
        const currentProductTypesCount = Object.keys(form).length;
        const updatedProductTypes = newProductTypes;
        updatedProductTypes[index - currentProductTypesCount] = { name: value };
        setNewProductTypes([...updatedProductTypes]);
      } else {
        setForm(form => ({
          ...form,
          [id]: {
            ...form[id],
            name: value,
            isChanged: true,
          },
        }));
      }
    },
    [form, newProductTypes],
  );

  const onPressSave = useCallback((): void => {
    const productTypesToUpdate = Object.values(form)
      .filter(productType => productType.isChanged)
      .map(productType => pick(productType, ['id', 'name']));

    if (newProductTypes.some(productType => !productType.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeProductTypes.enterProductTypeName'),
      });
    } else {
      if (newProductTypes.length > 0) createProductTypes(newProductTypes);
      if (productTypesToUpdate.length > 0)
        updateProductTypes(productTypesToUpdate);
    }
  }, [
    createProductTypes,
    updateProductTypes,
    newProductTypes,
    showNotification,
    translate,
    form,
  ]);

  const onPressCreateNew = useCallback(() => {
    setNewProductTypes([...newProductTypes, {} as CreateProductTypeInput]);
  }, [newProductTypes]);

  const productTypesData = [
    ...Object.values(form),
    ...(newProductTypes as unknown as ProductType[]),
  ];

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ScrollView
              testID="general-settings-screen"
              contentContainerStyle={css(scrollStyle)}
            >
              <BackOfficeSection
                title={translate('backOfficeProductTypes.productTypes')}
                titleDescription={translate(
                  'backOfficeProductTypes.productTypesDescription',
                )}
                contentContainerStyle={css(formStyle)}
                containerStyle={css(containerStyle)}
                action={
                  <BackOfficeCreateNewButton onPress={onPressCreateNew} />
                }
              >
                <TableComponent
                  columns={[
                    {
                      title: translate(
                        'backOfficeProductTypes.productTypeName',
                      ),
                      flex: 1,
                      alignItems: 'flex-start',
                      containerStyle: { marginLeft: 15 },
                    },
                  ]}
                  columnContainerStyle={css(columnContainerStyle)}
                  data={productTypesData}
                  normalRows={true}
                  renderRow={(
                    item: ProductType,
                    index: number,
                  ): React.ReactNode => (
                    <ProductTypeRow
                      productType={item}
                      key={index}
                      index={index}
                      onChange={onChangeName}
                      onDeleteProductType={onDeleteProductType}
                    />
                  )}
                />
              </BackOfficeSection>
              <View style={css(bottomSpace)}></View>
            </ScrollView>
            <View style={css(actionsContainerStyle)}>
              <Button
                fluid
                testID="save-changes"
                title={translate('button.saveChanges')}
                containerStyle={css(saveButtonStyle)}
                labelStyle={css(titleStyle)}
                onPress={onPressSave}
              />
            </View>
          </>
        )}
      </View>
    </>
  );
};

export default ProductTypes;
