import {
  StyleFn,
  MoneyMovementReason,
  MoneyEventType,
} from '@hitz-group/domain';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import TableRow from '../../../../../components/TableComponent/TableRow';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import IconButton from '../../../../../components/Button/IconButton';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../../components/FormInput/FormInput';
import { Helmet } from 'react-helmet';
import { useRoute } from '@react-navigation/native';
import Button from '../../../../../components/Button/Button';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import { useMoneyMovements } from '../../../../../hooks/app/useMoneyMovements';
import DropDown from '../../../../../components/DropDown/DropDown';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { CreateMoneyMovementReason } from '../../../../../components/Modals/MoneyMovements/CreateMoneyMovementReason';
import { pick } from 'lodash';
import scale, { isWeb, isAndroid } from '../../../../../common/theme';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingVertical: theme.spacing.big,
  paddingHorizontal: theme.padding.large,
});

const scrollStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const moneyMovementReasonRowStyle: StyleFn = ({ index }) => ({
  zIndex: 1000 - 10 * index,
});

const tableRowStyle: StyleFn = () => ({
  paddingTop: -7,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: isWeb ? 260 : 210,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});
const dropDownContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 215,
  marginHorizontal: scale.moderateScale(2),
  marginTop: isWeb
    ? -10
    : isAndroid
    ? scale.moderateScale(5)
    : scale.moderateScale(2),
});
const extraPopoverStyle: StyleFn = () => ({
  width: 215,
});

const itemContainer: StyleFn = () => ({
  height: 38 * 3,
});
const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  marginTop: isWeb
    ? scale.moderateScale(0)
    : isAndroid
    ? scale.moderateScale(10)
    : scale.moderateScale(12),
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

interface MoneyMovementReasonRowProps {
  index: number;
  moneyMovementReason: MoneyMovementReason;
  onChange: (id: string, prop: string, value: string) => void;
  onDeleteReason: (id: string) => void;
}

const MoneyMovementReasonRow: React.FC<MoneyMovementReasonRowProps> = ({
  index,
  moneyMovementReason,
  onChange,
  onDeleteReason,
}: MoneyMovementReasonRowProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', {
          label: moneyMovementReason.name,
        })}
        onConfirm={() => {
          closeModal();
          onDeleteReason(moneyMovementReason.id);
        }}
      />,
    );
  }, [showModal, onDeleteReason, closeModal, translate, moneyMovementReason]);

  const eventTypes = [MoneyEventType.MONEY_IN, MoneyEventType.MONEY_OUT];
  const eventTypesData = eventTypes.map(event => ({
    label: event,
    value: event,
  }));

  return (
    <View style={css(moneyMovementReasonRowStyle({ theme, index }))}>
      <TableRow
        containerStyle={isWeb ? {} : css(tableRowStyle)}
        action={
          <IconButton
            testID={'delete-icon'}
            icon="TrashAlt"
            iconSize={24}
            containerSize={34}
            containerStyle={css(closeIconContainerStyle)}
            iconStyle={css(closeIconStyle)}
            onPress={onPressDelete}
          />
        }
      >
        <FormInput
          testID="moneyMovementReason-name"
          value={moneyMovementReason.name}
          containerStyle={css(formInputContainerStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChange.bind(null, moneyMovementReason.id, 'name')}
        />

        <DropDown
          options={eventTypesData}
          selectedValue={moneyMovementReason.eventType}
          containerStyle={css(dropDownContainerStyle)}
          onValueChange={onChange.bind(
            null,
            moneyMovementReason.id,
            'eventType',
          )}
          itemsContainerStyle={css(itemContainer)}
          extraPopoverStyle={css(extraPopoverStyle)}
        />

        <FormInput
          testID="moneyMovementReason-notes"
          value={moneyMovementReason.notes}
          containerStyle={css(formInputContainerStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChange.bind(null, moneyMovementReason.id, 'notes')}
        />
      </TableRow>
    </View>
  );
};

type FormState = Record<string, MoneyMovementReason & { isChanged: boolean }>;

export const CashManagement: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState<FormState>({});
  const { showModal } = useModal();

  const route = useRoute();

  const params = route.params as {
    venueId: string;
  };

  const venueId = params.venueId || '';

  const {
    loading,
    error,
    moneyMovementReasons,
    getMoneyMovementReasons,
    createMoneyMovementReason,
    createdMoneyMovementReasonId,
    updateMoneyMovementReasons,
    updatedMoneyMovementReasonIds,
    deleteMoneyMovementReason,
    deletedMoneyMovementReason,
  } = useMoneyMovements({ venueId });

  useEffect(() => {
    getMoneyMovementReasons();
  }, [getMoneyMovementReasons]);

  useEffect(() => {
    if (moneyMovementReasons) {
      setForm(moneyMovementReasons as FormState);
    }
  }, [moneyMovementReasons]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (createdMoneyMovementReasonId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDevices.moneyMovementReasonCreatedSuccessfully',
        ),
      });
    }
  }, [createdMoneyMovementReasonId, showNotification, translate]);

  useEffect((): void => {
    if (updatedMoneyMovementReasonIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDevices.moneyMovementReasonUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedMoneyMovementReasonIds, showNotification, translate]);

  const onChange = useCallback(
    (id: string, prop: string, value: string): void => {
      setForm(form => ({
        ...form,
        [id]: {
          ...form[id],
          [prop]: value,
          isChanged: true,
        },
      }));
    },
    [],
  );

  const onDeleteReason = useCallback(
    (id: string): void => {
      deleteMoneyMovementReason(id);
    },
    [deleteMoneyMovementReason],
  );

  useEffect((): void => {
    if (deletedMoneyMovementReason) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDevices.moneyMovementReasonDeletedSuccessfully',
        ),
      });
      // refetch;
      getMoneyMovementReasons();
    }
  }, [
    deletedMoneyMovementReason,
    showNotification,
    translate,
    getMoneyMovementReasons,
  ]);

  const onCreateNew = useCallback((): void => {
    showModal(
      <CreateMoneyMovementReason onCreate={createMoneyMovementReason} />,
      translate('backOfficeSettings.createReason'),
    );
  }, [createMoneyMovementReason, showModal, translate]);

  const onPressSave = useCallback((): void => {
    const data = Object.values(form)
      .filter(reason => reason.isChanged)
      .map(reason => pick(reason, ['id', 'name', 'eventType', 'notes']));

    if (data.length > 0) {
      if (data.some(reason => !reason.name || !reason.eventType)) {
        showNotification({
          error: true,
          message: translate('backOfficeDevices.fieldsMissing'),
        });
      } else {
        updateMoneyMovementReasons(data);
      }
    }
  }, [updateMoneyMovementReasons, form, showNotification, translate]);

  const moneyMovementReasonsData = Object.values(form);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ScrollView contentContainerStyle={css(scrollStyle)}>
            <BackOfficeSection
              title={translate('backOfficeSettings.manageCash.cashManage')}
              titleDescription={translate(
                'backOfficeSettings.manageCash.cashManageDescription',
              )}
              action={<BackOfficeCreateNewButton onPress={onCreateNew} />}
            >
              <TableComponent
                columns={[
                  {
                    title: translate(
                      'backOfficeSettings.manageCash.reasonName',
                    ),
                    width: 260,
                  },
                  {
                    title: translate('backOfficeSettings.manageCash.event'),
                    width: 260,
                  },
                  {
                    title: translate('backOfficeSettings.manageCash.notes'),
                    width: 260,
                  },
                  { title: '', width: 30 },
                ]}
                columnSpacing={0}
                data={moneyMovementReasonsData}
                columnContainerStyle={css(columnContainerStyle)}
                renderRow={(
                  item: MoneyMovementReason,
                  index: number,
                ): React.ReactNode => (
                  <MoneyMovementReasonRow
                    moneyMovementReason={item}
                    key={index}
                    index={index}
                    onChange={onChange}
                    onDeleteReason={onDeleteReason}
                  />
                )}
              />
            </BackOfficeSection>
          </ScrollView>
        )}
      </View>
      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
