import { CreateDeviceInput, DeviceProfile } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import FormInput from '../../../components/FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../components/Button/Button';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import Modal from '../Modal';
import DropDown from '../../../components/FormInput/DropDown';
import { CreateDeviceModalStyles } from './styles';
import { isNotEmpty } from '@hitz-group/client-utils';

interface CreateDeviceProps {
  onCreate: (input: CreateDeviceInput) => void;
  storeId: string;
  venueId: string;
  deviceProfiles: DeviceProfile[];
}
export const CreateDeviceModal: React.FC<CreateDeviceProps> = ({
  onCreate,
  storeId,
  venueId,
  deviceProfiles,
}) => {
  const defaultProfile = deviceProfiles.find(
    ({ isDefault }) => isDefault === true,
  ) as DeviceProfile;
  const styles = CreateDeviceModalStyles();

  const initialForm = {
    store: storeId,
    venue: venueId,
    isCreatedByBackoffice: true,
    deviceProfile: defaultProfile.id,
  };
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState({
    ...initialForm,
  } as unknown as CreateDeviceInput);
  const { theme } = useFela();

  const onChange = useCallback((prop: string, value: string) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onSubmit = useCallback(() => {
    if (!form.name || !isNotEmpty(form.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceModal.errorDeviceNameMissing'),
      });
    } else {
      onCreate(form);
    }
  }, [form, showNotification, translate, onCreate]);

  return (
    <Modal
      title={translate('backOfficeDeviceModal.createTitle')}
      onDismiss={closeModal}
      contentStyle={styles.modalContainerStyle}
    >
      <FormInput
        testID={'device-name-input'}
        title={translate('backOfficeDeviceModal.deviceName')}
        placeholder={translate('backOfficeDeviceModal.deviceName')}
        value={form.name}
        alignTitle={'left'}
        containerStyle={styles.formInputStyle}
        textStyle={styles.formTextStyle}
        onChangeText={onChange.bind(null, 'name')}
        maxLength={50}
      />

      <DropDown
        title={translate('backOfficeDeviceModal.deviceProfileTitle')}
        values={deviceProfiles.map(deviceProfile => ({
          value: deviceProfile.id,
          label: deviceProfile.name,
        }))}
        style={styles.formInputStyle}
        extraMainViewStyle={styles.dropDownMainViewStyle}
        extraViewStyle={styles.dropdownViewStyle}
        extraStyle={styles.dropdownExtraStyle}
        selectedValue={defaultProfile.id}
        onValueChange={onChange.bind(null, 'deviceProfile')}
      />
      <Button
        testID={'create-device-btn'}
        title={translate('backOfficeDeviceModal.createBtn')}
        containerStyle={styles.buttonContainerStyle}
        labelStyle={styles.labelStyle}
        color={theme.colors.green}
        onPress={onSubmit}
      />
    </Modal>
  );
};
