import { OrderEvent } from '@hitz-group/domain';
import { IMap } from '../screens/BackOffice/Reports/types';

type OrderEventMap = Record<string, OrderEvent[]>;

export const groupEventsByOrderId = (events: OrderEvent[]): OrderEventMap => {
  if (events.length === 0) {
    return {};
  }
  return events.reduce((acc, orderEvent) => {
    const { orderId } = orderEvent;
    return { ...acc, [orderId]: [...(acc[orderId] || []), orderEvent] };
  }, Object.create(null));
};

export const sortEventsByPrevious = (sortedEventsByTimeStamp: OrderEvent[]) => {
  const linkedEvents: OrderEvent[] = [];
  let currentEvent: OrderEvent | undefined = sortedEventsByTimeStamp[0];
  while (currentEvent !== undefined) {
    linkedEvents.push(currentEvent);
    currentEvent = sortedEventsByTimeStamp.find(
      event => event.previous === currentEvent?.id,
    );
  }
  return linkedEvents;
};

export const CONFLICTING_KEY_MAP: IMap<string> = {
  voidReason: 'reason',
  optionalVoidReason: 'reason',
  optionalReason: 'reason',
  rejectionReason: 'reason',
  optionalSeatNumber: 'seatNumber',
  optionalCustomerId: 'customerId',
  optionalOrderItemId: 'orderItemId',
  optionalPaymentRequestId: 'paymentRequestId',
  optionalPaymentStatus: 'paymentStatus',
};

export const revertConflictingFields = (events: OrderEvent[]) => {
  let textToModify = JSON.stringify(events);
  for (const key in CONFLICTING_KEY_MAP) {
    textToModify = textToModify.replace(
      new RegExp(`"${key}":`, 'g'),
      `"${CONFLICTING_KEY_MAP[key]}":`,
    );
  }

  return JSON.parse(textToModify) as OrderEvent[];
};
