import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import {
  CreatePrinterInput,
  Printer,
  UpdatePrinterInput,
  CopyPrinterInput,
} from '@hitz-group/domain';
import { ApolloError } from '@apollo/client';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  COPY_PRINTER_MUTATION,
  CREATE_PRINTER_MUTATION,
  DELETE_PRINTER_MUTATION,
  GET_PRINTERS_QUERY,
  GET_PRINTER_QUERY,
  UPDATE_PRINTERS_MUTATION,
  UPDATE_PRINTER_MUTATION,
} from '../../graphql/printers';

export interface UsePrinters {
  loading: boolean;
  error: string | undefined;
  printers: { [key: string]: Printer };
  createdPrinterId: string;
  copyPrinterId: string;
  updatedPrinterId: string;
  updatedPrinterIds: string[];
  deletedPrinter: boolean | undefined;
  getPrinters: () => void;
  createPrinter: (input: CreatePrinterInput) => void;
  copyPrinter: (input: CopyPrinterInput) => void;
  updatePrinter: (input: UpdatePrinterInput) => void;
  updatePrinters: (input: UpdatePrinterInput[]) => void;
  deletePrinter: (id: string) => void;
}

interface Props {
  printerId?: string;
  storeId?: string;
}

export const usePrinters = (props?: Props): UsePrinters => {
  const { printerId, storeId } = props || {};
  const [printers, setPrinters] = useState<Record<string, Printer>>({});
  const [copyPrinterId, setCopyPrinterId] = useState<string>('');
  const [createdPrinterId, setCreatedPrinterId] = useState<string>('');
  const [updatedPrinterId, setUpdatedPrinterId] = useState<string>('');
  const [updatedPrinterIds, setUpdatedPrinterIds] = useState<string[]>([]);
  const [deletedPrinter, setDeletedPrinter] = useState<boolean | undefined>(
    false,
  );

  // get printer
  const onCompleteGetPrinterRequest = useCallback(data => {
    if (data) {
      const printerData = data.printer as Printer;
      setPrinters(printers => ({
        ...printers,
        [printerData.id]: printerData,
      }));
    }
  }, []);

  const [getPrinterRequest, getPrinterResponse] = useLazyQuery(
    GET_PRINTER_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      context: {
        headers: { store: storeId },
      },
      onCompleted: onCompleteGetPrinterRequest,
    },
  );

  useEffect(() => {
    if (printerId) {
      getPrinterRequest({ variables: { id: printerId } });
    }
  }, [getPrinterRequest, printerId]);

  // get printers
  const onCompleteGetPrintersRequest = useCallback(data => {
    if (data) {
      const printersData = data.printers as Printer[];
      const printersTemp = {} as Record<string, Printer>;
      printersData.forEach(printerData => {
        printersTemp[printerData.id] = printerData;
      });
      setPrinters(printersTemp);
      // Reset deleted printer flag
      setDeletedPrinter(undefined);
    }
  }, []);

  const [getPrintersRequest, getPrintersResponse] = useLazyQuery(
    GET_PRINTERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      context: {
        headers: { store: storeId },
      },
      onCompleted: onCompleteGetPrintersRequest,
    },
  );

  // create
  const [createPrinterRequest, createPrinterResponse] = useMutation(
    CREATE_PRINTER_MUTATION,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
    },
  );

  useEffect(() => {
    if (createPrinterResponse.data) {
      const result = createPrinterResponse.data.createPrinter as Printer;

      setPrinters(printers => {
        return {
          ...printers,
          [result.id]: result,
        };
      });
      setCreatedPrinterId(result.id);
    }
  }, [createPrinterResponse.data]);

  const createPrinter = useCallback(
    (printerInput: CreatePrinterInput) => {
      createPrinterRequest({
        variables: {
          input: printerInput,
        },
      });
    },
    [createPrinterRequest],
  );

  // copy
  const [copyPrinterRequest, copyPrinterResponse] = useMutation(
    COPY_PRINTER_MUTATION,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
    },
  );

  useEffect(() => {
    if (copyPrinterResponse.data) {
      const result = copyPrinterResponse.data.copyPrinter as Printer;

      setPrinters(printers => {
        return {
          ...printers,
          [result.id]: result,
        };
      });
      setCopyPrinterId(result.id);
    }
  }, [copyPrinterResponse.data]);

  const copyPrinter = useCallback(
    (printerInput: CopyPrinterInput) => {
      copyPrinterRequest({
        variables: {
          input: printerInput,
        },
      });
    },
    [copyPrinterRequest],
  );

  // update
  const [updatePrinterRequest, updatePrinterResponse] = useMutation(
    UPDATE_PRINTER_MUTATION,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
    },
  );

  useEffect(() => {
    if (updatePrinterResponse.data) {
      const result = updatePrinterResponse.data.updatePrinter as Printer;

      setPrinters(printers => {
        return {
          ...printers,
          [result.id]: result,
        };
      });
      setUpdatedPrinterId(result.id);
    }
  }, [updatePrinterResponse.data]);

  const updatePrinter = useCallback(
    (printerInput: UpdatePrinterInput) => {
      updatePrinterRequest({
        variables: {
          input: printerInput,
        },
      });
    },
    [updatePrinterRequest],
  );

  // multi update
  const [updatePrintersRequest, updatePrintersResponse] = useMutation(
    UPDATE_PRINTERS_MUTATION,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
    },
  );

  useEffect(() => {
    if (updatePrintersResponse.data) {
      const result = updatePrintersResponse.data.updatePrinters as Printer[];
      setPrinters(prevPrinters => {
        const printersTemp = { ...prevPrinters } as Record<string, Printer>;
        result.forEach(printer => {
          printersTemp[printer.id] = printer;
        });
        return printersTemp;
      });
      setUpdatedPrinterIds(result.map(printer => printer.id));
    }
  }, [updatePrintersResponse.data]);

  const updatePrinters = useCallback(
    (printersInput: UpdatePrinterInput[]) => {
      updatePrintersRequest({
        variables: {
          input: printersInput,
        },
      });
    },
    [updatePrintersRequest],
  );

  // delete
  const [deletePrinterRequest, deletePrinterResponse] = useMutation(
    DELETE_PRINTER_MUTATION,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId },
      },
    },
  );

  useEffect(() => {
    if (deletePrinterResponse.data) {
      setDeletedPrinter(deletePrinterResponse.data.deletePrinter);
    }
  }, [deletePrinterResponse.data]);

  const deletePrinter = useCallback(
    (id: string) => {
      deletePrinterRequest({
        variables: {
          input: { id },
        },
      });
    },
    [deletePrinterRequest],
  );

  const error: ApolloError | undefined =
    getPrintersResponse.error ||
    getPrinterResponse.error ||
    createPrinterResponse.error ||
    copyPrinterResponse.error ||
    updatePrinterResponse.error ||
    updatePrintersResponse.error ||
    deletePrinterResponse.error;

  const loading: boolean =
    getPrintersResponse.loading ||
    getPrinterResponse.loading ||
    createPrinterResponse.loading ||
    copyPrinterResponse.loading ||
    updatePrinterResponse.loading ||
    updatePrintersResponse.loading ||
    deletePrinterResponse.loading;

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      printers,
      copyPrinterId,
      createdPrinterId,
      updatedPrinterId,
      updatedPrinterIds,
      deletedPrinter,
      getPrinters: getPrintersRequest,
      createPrinter,
      copyPrinter,
      updatePrinter,
      updatePrinters,
      deletePrinter,
    }),
    [
      loading,
      error,
      printers,
      getPrintersRequest,
      copyPrinterId,
      createdPrinterId,
      updatedPrinterId,
      updatedPrinterIds,
      deletedPrinter,
      createPrinter,
      copyPrinter,
      updatePrinter,
      updatePrinters,
      deletePrinter,
    ],
  );
};
