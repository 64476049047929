import { StyleFn, CreatePricingGroupInput } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { useNotification } from '../../../../hooks/Notification';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import Modal from '../../../../components/Modals/Modal';
import FormInput from '../../../../components/FormInput/FormInput';
import Button from '../../../../components/Button/Button';
import scale, { isWeb } from '../../../../common/theme';

const createModalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const buttonStyle: StyleFn = ({ theme, marginLeft, backgroundColor }) => ({
  width: 115,
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: backgroundColor || theme.colors.successLight,
  marginLeft: marginLeft || 0,
  marginTop: theme.spacing.medium,
});

const createVenueLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const inputStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: scale.textInputWidth180,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});
export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? 280 : '100%',
  marginTop: scale.moderateScale(2),
});
export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  height: scale.moderateScale(35),
  width: '90%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});

export const dropdownStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? 250 : '100%',
  marginTop: isWeb ? scale.moderateScale(10) : 0,
  left: isWeb ? 70 : 0,
});

const rowViewStyle: StyleFn = () => ({
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

export interface CreateProductModalProps {
  onCreate: (prodInput: CreatePricingGroupInput) => void;
}

export const CreatePriceListModal: React.FC<CreateProductModalProps> = ({
  onCreate,
}: CreateProductModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [pricingGroupName, setPricingGroupName] = useState<string>();
  const { showNotification } = useNotification();

  const onCreatePricingGroup = useCallback(() => {
    if ((pricingGroupName || '').trim().length === 0) {
      showNotification({
        error: true,
        message: translate('pricings.pricingNameIsRequired'),
      });
      return;
    }
    const createPricingGroupInput = {
      name: pricingGroupName,
      venues: [],
      isActive: true,
      orderTypes: [],
      prices: [],
      stores: [],
      salesChannels: [],
    } as unknown as CreatePricingGroupInput;
    onCreate(createPricingGroupInput);
  }, [onCreate, pricingGroupName, showNotification, translate]);

  return (
    <Modal
      title={translate('pricings.quickCreatePricingGroup')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
    >
      <FormInput
        title={translate('pricings.name')}
        placeholder={translate('pricings.name')}
        value={pricingGroupName}
        containerStyle={css(inputStyle)}
        onChangeText={text => setPricingGroupName(text)}
      />
      <View style={css(rowViewStyle)}>
        <Button
          title={translate('productSettings.create')}
          containerStyle={css(
            buttonStyle({
              theme,
              marginLeft: theme.spacing.medium,
              backgroundColor: theme.colors.successLight,
            }),
          )}
          labelStyle={css(createVenueLabelStyle)}
          color={theme.colors.green}
          onPress={onCreatePricingGroup}
        />
      </View>
    </Modal>
  );
};
