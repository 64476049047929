import { StyleFn } from '@hitz-group/domain';
import React, { useMemo, useCallback } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import SelectBar, {
  SelectBarOptions,
} from '../../../components/Button/SelectBar';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSession } from '../../../hooks/app/useSession';
import { AppScreen } from '../../../types/AppScreen';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { pendingOnlineOrdersCountVar } from '../../../state/cache';

export interface HeaderTabsProps {
  previousScreen?: string;
  selectedOption: 'OnlineOrders' | 'OpenOrders' | 'FloorView' | 'TakeOrder';
}

const headerTitleStyle: StyleFn = ({ count }) => ({
  width: count * 150,
  maxWidth: 300,
  height: 44,
});

const HeaderTabs: React.FC<HeaderTabsProps> = ({
  previousScreen,
  selectedOption,
}: HeaderTabsProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const [session] = useSession();
  const isOnlineOrdersEnabled = session?.deviceProfile?.enableOnlineOrders;
  const pendingOnlineOrdersCount = useReactiveVar<number>(
    pendingOnlineOrdersCountVar,
  );
  const onChangeTabs = useCallback(
    (screen: string) => {
      navigation.navigate(screen, {
        previousScreen: selectedOption,
      });
    },
    [navigation, selectedOption],
  );

  const tabOptions = useMemo(() => {
    const options: SelectBarOptions[] = [
      {
        label: translate('order.openOrders'),
        value: 'OpenOrders',
      },
    ];
    if ([previousScreen, selectedOption].includes(AppScreen.FLOOR_VIEW))
      options.push({
        label: translate('navigation.floorView'),
        value: 'FloorView',
      });
    else
      options.unshift({
        label: translate('order.currentOrder'),
        value: 'TakeOrder',
      });

    if (isOnlineOrdersEnabled) {
      options.push({
        label: translate('order.onlineOrders'),
        value: 'OnlineOrders',
        badge: pendingOnlineOrdersCount,
      });
    }

    return options;
  }, [
    previousScreen,
    translate,
    isOnlineOrdersEnabled,
    selectedOption,
    pendingOnlineOrdersCount,
  ]);

  return (
    <View style={css(headerTitleStyle({ theme, count: tabOptions.length }))}>
      <SelectBar
        secondary={true}
        options={tabOptions}
        onPress={onChangeTabs}
        selectedOption={selectedOption}
      />
    </View>
  );
};
export default HeaderTabs;
