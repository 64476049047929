import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela, withTheme } from 'react-fela';
import { ActivityIndicator, View } from 'react-native';
import Logo from '../../components/Logo/Logo';
import Gradient from '../../components/Gradient/Gradient';
import { LogoFillColor } from '../../components/Logo/Till';

const containerStyle: StyleFn = () => ({
  alignContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  justifyContent: 'center',
});
const screen: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

export const LoadingScreen: React.FC = () => {
  const { css, theme } = useFela();

  return (
    <Gradient
      colors={[theme.colors.brandPrimary, theme.colors.brandSecondary]}
      style={css(screen)}
      start={theme.gradient.startAxis}
      end={theme.gradient.endAxis}
      locations={theme.gradient.location}
    >
      <View style={css(containerStyle)}>
        <Logo fillColor={LogoFillColor.WHITE} />
        <ActivityIndicator testID="loading-spinner" />
      </View>
    </Gradient>
  );
};

export default withTheme(LoadingScreen);
