import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { CloseShiftInput, OrderAction, StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import PopupView from '../PopupView/PopupView';
import Title from '../Title/Title';
import Button from '../Button/Button';
import { useCurrency } from '@hitz-group/localization';
import ClosureKeypad from '../MoneyInputKeypad/ClosureKeypad';
import { usePaymentTypes } from '../../hooks/app/usePaymentTypes';
import { useTranslation } from '@hitz-group/localization';
import {
  Shift,
  PaymentTypeSaleSummary,
  PaymentTypeSaleSummaryInput,
} from '@hitz-group/domain';
import { useShifts } from '../../hooks/app/useShifts';
import { useNotification } from '../../hooks/Notification';
import TitleBar from '../TitleBar/TitleBar';
import { useModal } from '@hitz-group/rn-use-modal';
import IconButton from '../Button/IconButton';
import { stripProperties } from '../../utils/stripObjectProps';
import { isWeb } from '../../common/theme';
import { usePrinting } from '../../hooks/PrintingProvider';
import { userUtility } from '../../state/userUtility';
import { useSession } from '../../hooks/app/useSession';

export interface CloseShiftProps {
  data: Shift;
  refetch?: () => void;
}

const containerStyle: StyleFn = () => ({
  width: 350,
  alignSelf: 'center',
  alignItems: 'stretch',
});
const messageContainerStyle: StyleFn = ({ theme }) => ({
  paddingVertical: theme.padding.large,
  marginBottom: 10,
});
const finaliseMessageStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  fontFamily: theme.font.medium,
  lineHeight: 21,
  letterSpacing: -0.5,
  color: theme.colors.textLight,
});
const shiftContainerStyle: StyleFn = ({ theme }) => ({
  paddingVertical: theme.padding.large,
});
const shiftLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
});
const paymentTypesContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small * 1.5,
  marginBottom: theme.spacing.small * 3.5,
  height: 162,
});
const formPrefixStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  backgroundColor: theme.colors.background,
  width: 124,
  height: '100%',
  textAlign: 'left',
  paddingTop: theme.padding.medium,
  paddingLeft: theme.padding.medium * 1.5,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.medium,
  lineHeight: 24,
  letterSpacing: -0.5,
});
const formStyle: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.small * 1.5,
  width: 300,
  height: 44,
  flexDirection: 'row',
  borderWidth: 1,
  borderRadius: theme.radius.small,
  borderColor: theme.colors.boxBorder,
});
const paymentTotalCountedTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.black,
  backgroundColor: theme.colors.white,
  width: 156,
  height: '100%',
  textAlign: 'center',
  paddingTop: theme.padding.medium,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.medium,
  lineHeight: 24,
  letterSpacing: -0.5,
});
const titleIconStyle: StyleFn = () => ({
  position: 'absolute',
  left: 0,
});
const totalCountedStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingHorizontal: theme.padding.medium * 1.5,
  width: 300,
  paddingVertical: theme.padding.medium + 2,
  backgroundColor: theme.colors.background,
  marginBottom: theme.spacing.small,
  borderRadius: theme.radius.small,
});
const differenceStyle: StyleFn = ({ theme, danger }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingHorizontal: theme.padding.medium * 1.5,
  width: 300,
  paddingVertical: theme.padding.medium + 2,
  backgroundColor:
    danger === 'showRed'
      ? theme.colors.danger2
      : danger === 'showGreen'
      ? theme.colors.successLight
      : theme.colors.greyLight,
  borderRadius: theme.radius.small,
});
const totalCountedTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  textTransform: 'capitalize',
  fontSize: theme.fontSize.small,
  lineHeight: 21,
  letterSpacing: -0.5,
});
const totalDifferenceStyle: StyleFn = ({ theme, danger }) => ({
  color:
    danger === 'showRed'
      ? theme.colors.danger
      : danger === 'showGreen'
      ? theme.colors.success
      : theme.colors.blue,
  textTransform: 'capitalize',
  fontSize: theme.fontSize.small,
  lineHeight: 21,
  letterSpacing: -0.5,
});
const totalDifferenceAmountStyle: StyleFn = ({ theme, danger }) => ({
  color:
    danger === 'showRed'
      ? theme.colors.danger
      : danger === 'showGreen'
      ? theme.colors.success
      : theme.colors.blue,
  textTransform: 'capitalize',
  fontFamily: theme.font.semibold,
  fontSize: theme.fontSize.small,
  lineHeight: 21,
  letterSpacing: -0.5,
});
const totalCountedAmountStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.heading2,
  fontFamily: theme.font.semibold,
  fontSize: theme.fontSize.small,
  lineHeight: 21,
  letterSpacing: -0.5,
});
const buttonContainerStyle: StyleFn = () => ({
  marginTop: 20,
  marginBottom: isWeb ? 0 : 20,
  flexDirection: 'row',
  width: 300,
  justifyContent: 'space-between',
});
const buttonStyle: StyleFn = ({ theme }) => ({
  marginHorizontal: 0,
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
});

const CloseShift: React.FC<CloseShiftProps> = ({
  data,
  refetch,
}: CloseShiftProps) => {
  const [session] = useSession();
  const { css, theme } = useFela();
  const { formatCurrency } = useCurrency();
  const { paymentTypes } = usePaymentTypes();
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const { closeShift, error, mutationLoading, success, closedShiftData } =
    useShifts();
  const { showNotification } = useNotification();
  const [selectedPaymentType, setSelectedPaymentType] = useState('');
  const { printShiftReceipt, openCashDrawer } = usePrinting();

  const salesMap = data.salesByPaymentType.reduce<
    Record<string, PaymentTypeSaleSummary>
  >((acc, x) => ({ ...acc, [x.paymentType.id]: x }), {});

  const [saleByPaymentType, setSaleByPaymentType] = useState<
    Record<string, PaymentTypeSaleSummaryInput>
  >(
    paymentTypes.reduce(
      (acc, x) => ({
        ...acc,
        [x.id]: {
          paymentType: x.id,
          totalCounted: salesMap[x.id]?.totalCounted || 0,
        },
      }),
      {},
    ),
  );

  const totalCounted = useMemo(
    () =>
      Object.values(saleByPaymentType).reduce(
        (acc, x) => acc + (x.totalCounted || 0),
        0,
      ),
    [saleByPaymentType],
  );

  // difference = total counted ( cash + card ) - total recorded
  const difference = useMemo(() => {
    return data.totalRecorded >= 0
      ? totalCounted - data.totalRecorded
      : totalCounted + data.totalRecorded;
  }, [totalCounted, data.totalRecorded]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [showNotification, error]);

  useEffect(() => {
    if (success) {
      showNotification({
        success: true,
        message: translate('shift.finaliseSuccessMessage'),
      });
      refetch && refetch();
      closeModal();
    }
  }, [success, showNotification, closeModal, translate, refetch]);

  const onSubmitValue = useCallback(
    (prop: string, value: number) => {
      const x = { ...saleByPaymentType[prop] };
      x.totalCounted = value;
      setSaleByPaymentType(existingSale => ({
        ...existingSale,
        [prop]: x,
      }));
      setSelectedPaymentType('');
    },
    [saleByPaymentType],
  );

  useEffect(() => {
    if (success && closedShiftData) {
      const closedAt = Date.now();
      const posUser = session.user;

      const printShiftInput = stripProperties(
        {
          ...closedShiftData,
          closedAt,
          closedBy: {
            id: posUser?.id,
            name: posUser?.name,
          },
          closedByDevice: {
            id: session.device?.id,
            name: session.device?.name,
          },
        },
        'store',
        '__typename',
      );

      printShiftReceipt({
        ...printShiftInput,
        salesByPaymentType: printShiftInput.salesByPaymentType.map(
          (paymentSales: PaymentTypeSaleSummaryInput) => ({
            ...paymentSales,
          }),
        ),
        taxes: data.taxes,
      } as unknown as Shift);
    }
  }, [
    data.taxes,
    error,
    mutationLoading,
    paymentTypes,
    printShiftReceipt,
    session.device?.id,
    session.device?.name,
    session.user,
    closedShiftData,
    success,
  ]);

  const onCloseShift = useCallback(async () => {
    const closedAt = Date.now();
    const posUser = userUtility.posUser;

    const input = {
      id: data.id,
      closedAt,
      closedBy: posUser?.id,
      salesByPaymentType: Object.values(saleByPaymentType),
    } as CloseShiftInput;

    closeShift(input);
  }, [data.id, saleByPaymentType, closeShift]);

  const iconLeft = (
    <IconButton
      testID={'close-icon'}
      iconSize={20}
      icon={'times'}
      containerSize={44}
      containerStyle={css(titleIconStyle)}
      onPress={closeModal}
    />
  );

  return (
    <>
      {!!selectedPaymentType ? (
        <ClosureKeypad
          amount={(
            saleByPaymentType[selectedPaymentType].totalCounted || 0
          ).toString()}
          onSubmit={onSubmitValue.bind(null, selectedPaymentType)}
          showCurrencyInput={
            !!paymentTypes.find(
              x => x.id === selectedPaymentType && x.name === 'Cash',
            )
          }
          onCancel={() => setSelectedPaymentType('')}
        />
      ) : (
        <View style={css(containerStyle)}>
          <TitleBar
            testID={'title'}
            primary
            title={translate('shift.closureBy') + ' ' + data.shiftType}
            titleLeft={iconLeft}
          />
          <PopupView containerStyle={css(messageContainerStyle)}>
            <Text style={css(finaliseMessageStyle)} testID={'finalise-message'}>
              {translate('shift.closureMessage')}
            </Text>
          </PopupView>
          <PopupView containerStyle={css(shiftContainerStyle)}>
            <Title labelStyle={css(shiftLabelStyle)}>
              {translate('shift.closingCounts')}
            </Title>
            <ScrollView style={css(paymentTypesContainerStyle)}>
              {paymentTypes.map((type, index) =>
                type.name === OrderAction.ON_ACCOUNT_PAYMENT_TYPE ? null : (
                  <View
                    style={css(formStyle)}
                    key={index}
                    testID={`payment-type-${index}`}
                  >
                    <Text
                      style={css(formPrefixStyle)}
                      testID={'payment-type-name'}
                    >
                      {type.name}
                    </Text>
                    <TouchableOpacity
                      key={type.id}
                      onPress={setSelectedPaymentType.bind(null, type.id)}
                      testID={'payment-type-button'}
                    >
                      <Text
                        style={css(paymentTotalCountedTextStyle)}
                        testID={'payment-type-amount'}
                      >
                        {formatCurrency(
                          saleByPaymentType[type.id].totalCounted || 0,
                        )}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ),
              )}
            </ScrollView>
            <View style={css(totalCountedStyle)}>
              <Text
                style={css(totalCountedTextStyle)}
                testID={'total-counted-label'}
              >
                {translate('shift.totalCounted')}
              </Text>
              <Text
                style={css(totalCountedAmountStyle)}
                testID={'total-counted-value'}
              >
                {formatCurrency(totalCounted)}
              </Text>
            </View>
            <View
              style={css(
                differenceStyle({
                  theme,
                  danger:
                    difference < 0
                      ? 'showRed'
                      : difference > 0
                      ? 'showYellow'
                      : 'showGreen',
                }),
              )}
            >
              <Text
                style={css(
                  totalDifferenceStyle({
                    theme,
                    danger:
                      difference < 0
                        ? 'showRed'
                        : difference > 0
                        ? 'showYellow'
                        : 'showGreen',
                  }),
                )}
                testID={'difference-label'}
              >
                Difference
              </Text>
              <Text
                style={css(
                  totalDifferenceAmountStyle({
                    theme,
                    danger:
                      difference < 0
                        ? 'showRed'
                        : difference > 0
                        ? 'showYellow'
                        : 'showGreen',
                  }),
                )}
                testID={'difference-value'}
              >
                {formatCurrency(difference)}
              </Text>
            </View>
            <View style={css(buttonContainerStyle)}>
              <Button
                success
                title={translate('shift.close')}
                size="small"
                containerStyle={css(buttonStyle)}
                onPress={onCloseShift}
                loading={mutationLoading}
                testID={'end-shift'}
              />
              <Button
                secondary
                title={translate('shift.openDraw')}
                testID={'Open-Drawer'}
                size="small"
                onPress={openCashDrawer}
                containerStyle={css(buttonStyle)}
              />
            </View>
          </PopupView>
        </View>
      )}
    </>
  );
};

export default CloseShift;
