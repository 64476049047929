import { useTranslation } from '@hitz-group/localization';
import { StyleFn } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View, KeyboardAvoidingView } from 'react-native';
import { useModal } from '@hitz-group/rn-use-modal';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import FormInput from '../../FormInput/FormInput';
import PopupView from '../../PopupView/PopupView';
import TitleBar from '../../TitleBar/TitleBar';

export interface AddNoteModalProps {
  value?: string;
  readonly?: boolean;
  onSubmit?: (note: string) => void;
  onClose?: () => void | Promise<void>;
}

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 15,
});
const containerStyle: StyleFn = ({ theme }) => ({
  width: 350,
  height: 320,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
});

const popupStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.medium * 2.5,
  minHeight: 235,
});

const keyboardAvoidingViewStyle: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
});
const formInputStyle: StyleFn = () => ({ width: 300, height: 100 });
const textInputStyle: StyleFn = ({ theme }) => ({
  height: '100%',
  padding: theme.padding.medium * 1.5,
});
const buttonStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small * 3.5,
});

const AddNoteModal: React.FC<AddNoteModalProps> = ({
  readonly,
  value,
  onSubmit,
  onClose,
}: AddNoteModalProps) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [formState, setFormState] = useState(value || '');

  const onCloseModal = useCallback(() => {
    onClose && onClose();
    closeModal();
  }, [closeModal, onClose]);

  const titleLeft = (
    <IconButton
      testID={'noteModal-close'}
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={onCloseModal}
    />
  );
  const onSubmitNotes = useCallback(() => {
    closeModal();
    onSubmit && onSubmit(formState);
  }, [closeModal, onSubmit, formState]);

  return (
    <KeyboardAvoidingView
      behavior="padding"
      style={css(keyboardAvoidingViewStyle)}
    >
      <View style={css(containerStyle)}>
        <TitleBar
          testID={'noteModal-title'}
          titleLeft={titleLeft}
          primary
          title={translate(readonly ? 'modal.eventNote' : 'modal.addNote')}
        />
        <PopupView containerStyle={css(popupStyle)}>
          <FormInput
            testID={'note-input'}
            multiline
            containerStyle={css(formInputStyle)}
            value={formState}
            placeholder={
              translate(readonly ? 'modal.eventNote' : 'modal.addNote') + '...'
            }
            textInputStyle={css(textInputStyle)}
            readOnly={readonly}
            onChangeText={setFormState}
            autoFocus={true}
          />
          <Button
            testID={'save-note'}
            title={
              readonly
                ? translate('button.closeNote')
                : translate('button.saveNote')
            }
            fluid
            success
            size="small"
            containerStyle={css(buttonStyle)}
            onPress={onSubmitNotes}
          />
        </PopupView>
      </View>
    </KeyboardAvoidingView>
  );
};

export default AddNoteModal;
