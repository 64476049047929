import { useModal } from '@hitz-group/rn-use-modal';
import React, { useCallback } from 'react';
import { View, Text, KeyboardAvoidingView, Platform } from 'react-native';
import Button from '../../Button/Button';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import { translate } from '@hitz-group/localization';

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  paddingHorizontal: theme.spacing.small * 2.5,
  marginVertical: theme.spacing.small * 3.5,
});

const buttonWidthStyle: StyleFn = () => ({
  marginLeft: 30,
  width: '50%',
});

const modalTitleContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing.big,
  backgroundColor: theme.colors.background,
  width: 550,
  height: 165,
});

const completedTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.larger,
  fontFamily: theme.font.bold,
  textTransform: 'capitalize',
  letterSpacing: -0.5,
});

const finaliseMessageStyle: StyleFn = ({ theme }) => ({
  marginTop: 20,
  marginHorizontal: 20,
  textAlign: 'center',
  fontFamily: theme.font.medium,
  lineHeight: 21,
  letterSpacing: -0.5,
  color: theme.colors.textLight,
});

const modalStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  elevation: 5,
  borderColor: theme.colors.boxBorder,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  backgroundColor: theme.colors.white,
  width: 550,
  alignSelf: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

const inputContainerStyle: StyleFn = () => ({ alignItems: 'center' });

export interface OrderSyncAlertModalProps {
  onSync?: () => void;
}

export const OrderSyncAlertModal: React.FC<OrderSyncAlertModalProps> = ({
  onSync,
}) => {
  const { css } = useFela();
  const { closeModal } = useModal();

  const onPressSync = useCallback(() => {
    onSync && onSync();
    closeModal();
  }, [closeModal, onSync]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={css(modalStyle)}
    >
      <View style={css(modalTitleContainerStyle)}>
        <Text style={css(completedTextStyle)}>
          {translate('settings.syncOrders')}
        </Text>
        <Text testID="alertMessage" style={css(finaliseMessageStyle)}>
          {translate('order.syncAlertMessage')}
        </Text>
      </View>
      <View style={css(inputContainerStyle)}>
        <View style={css(buttonContainerStyle)}>
          <View style={css(buttonWidthStyle)}>
            <Button
              fluid
              testID="close"
              success
              title={translate('settings.cancel')}
              onPress={closeModal}
            />
          </View>

          <View style={css(buttonWidthStyle)}>
            <Button
              fluid
              testID="sync"
              success
              title={translate('settings.sync')}
              onPress={onPressSync}
            />
          </View>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};
