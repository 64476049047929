import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import { useTranslation } from '@hitz-group/localization';

const wrapperStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.medium,
  elevation: 5,
  borderColor: theme.colors.boxBorder,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  backgroundColor: theme.colors.white,
  width: 696,
  height: 316,
  padding: 20,
  alignSelf: 'center',
});

const headerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.medium,
  padding: 6,
  marginBottom: 10,
});

const modifierInfoWrapperStyle: StyleFn = ({}) => ({
  flex: 1,
  paddingLeft: 6,
  flexDirection: 'row',
  alignItems: 'center',
});
const modifierNameStyle: StyleFn = ({ theme }) => ({
  marginRight: 4,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.medium,
});
const modifierLimitInfoStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
});
const selectedModifierWrapperStyle: StyleFn = ({ theme, isQualified }) => ({
  borderRadius: theme.radius.medium,
  backgroundColor: isQualified ? theme.colors.green : theme.colors.orange,
  paddingHorizontal: 10,
  paddingVertical: 6,
});

const selectedModifierTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.medium,
  textTransform: 'capitalize',
  letterSpacing: -0.4,
  lineHeight: 14,
});

export interface RequiredModifierPromptProp {
  numOfSelectedModifiers: number;
  name: string;
  selectionLimit: {
    min: number;
    max: number;
  };
}

const RequiredModifierPrompt: React.FC<RequiredModifierPromptProp> = ({
  children,
  numOfSelectedModifiers,
  name,
  selectionLimit,
}) => {
  const { translate } = useTranslation();
  const { min, max } = selectionLimit;
  const isQualified = numOfSelectedModifiers >= min;
  const { css } = useFela({ isQualified });

  return (
    <View style={css(wrapperStyle)}>
      <View style={css(headerStyle)}>
        <View style={css(modifierInfoWrapperStyle)}>
          <Text style={css(modifierNameStyle)} testID="modifier-name">
            {translate('modifiers.chooseRequiredModifier', {
              modifierName: name,
            })}
          </Text>
          <Text
            style={css(modifierLimitInfoStyle)}
            testID="modifier-information"
          >
            {translate('modifiers.modifierLimitation', {
              min,
              max,
            })}
          </Text>
        </View>
        <View
          style={css(selectedModifierWrapperStyle)}
          testID="selected-modifiers-wrapper"
        >
          <Text
            style={css(selectedModifierTextStyle)}
            testID="selected-modifiers"
          >
            {translate('modifiers.selectedModifierOutOfTotal', {
              selected: numOfSelectedModifiers,
              total: max,
            })}
          </Text>
        </View>
      </View>
      {children}
    </View>
  );
};

export default RequiredModifierPrompt;
