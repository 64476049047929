import { useEffect, useMemo, useRef, useState } from 'react';

export function useInterval(callback: () => void, delay?: number | null) {
  const [start, startInterval] = useState(false);

  const savedCallback = useRef<() => void | null>();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (start) {
      function tick() {
        if (typeof savedCallback?.current !== 'undefined') {
          savedCallback?.current();
        }
      }

      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }
  }, [delay, start]);

  return useMemo(
    () => ({
      start: start,
      startInterval: startInterval,
    }),
    [start],
  );
}
