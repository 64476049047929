import { gql, Resolvers } from '@apollo/client';
import { backOfficeNavigation, NavMenu } from '../../../state/navigation';

export const resolvers: Resolvers = {
  Query: {
    backOfficeNavigation: (): Array<NavMenu> => {
      // TODO: filter features based on permissions
      return backOfficeNavigation.map(x => ({ ...x, __typename: 'NavMenu' }));
    },
  },
};

const featureQuery = `
    id
    name
    category
    title
    description
    app
    icon
    context
    enabledByDefault
    backgroundImageUrl
    subscriptionLevel
    createdBy
    subFeatureList {
      name
      description
    }
    screenshots
    about
    webhookUrl
`;

export const GET_FEATURES = gql`
  query featureOrgConfigurations {
    featureOrgConfigurations {
      ${featureQuery}
    }
  }
`;

export const GET_INTEGRATIONS = gql`
  query integrations {
    integrations {
      ${featureQuery}
    }
  }
`;

export const GET_FEATURES_BY_VENUE = gql`
  query featureVenues($id: ID!) {
    featureVenueConfigurations(id: $id) {
      venue {
        name
        id
      }
      featureId
      enabled
    }
  }
`;

export const GET_FEATURE = gql`
  query feature($id: ID!) {
    feature(id: $id) {
      ${featureQuery}
    }
  }
`;
export const ENABLE_FEATURE_AT_VENUE = gql`
  mutation enableFeatureAtVenueLevel($input: [ToggleFeatureInput!]) {
    enableFeatureAtVenueLevel(input: $input)
  }
`;

export const DISABLE_FEATURE_AT_VENUE = gql`
  mutation disableFeatureAtVenueLevel($input: [ToggleFeatureInput!]) {
    disableFeatureAtVenueLevel(input: $input)
  }
`;

export const ENABLE_FEATURE_AT_ORG = gql`
  mutation enableFeatureAtOrgLevel($input: ToggleFeatureInput!) {
    enableFeatureAtOrgLevel(input: $input)
  }
`;

export const DISABLE_FEATURE_AT_ORG = gql`
  mutation disableFeatureAtOrgLevel($input: ToggleFeatureInput!) {
    disableFeatureAtOrgLevel(input: $input)
  }
`;
