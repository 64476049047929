import { useCurrency } from '@hitz-group/localization';
import { StyleFn } from '@hitz-group/domain';
import { useDimensions } from '@react-native-community/hooks';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { Text, View } from 'react-native';
import IconButton from '../Button/IconButton';
import InputDisplay from '../InputDisplay/InputDisplay';
import NumberInput, { KeyButton } from '../NumberInput/NumberInput';
import { addToValue } from '../../utils/keypadHelper';
import Redo from '../Redo/Redo';
import { isWeb, isIos } from '../../common/theme';
import Modal from '../Modals/Modal';
import { MAX_KEYPAD_INPUT } from '../../types/Common';

export interface MoneyInputKeypadProps {
  onSubmit: (pin: number) => void;
  headerText?: string;
  title?: React.ReactNode;
  onDismiss?: () => void;
}

const numberInputStyle: StyleFn = () => ({
  flexGrow: 1,
  marginTop: '6.5%',
});

const popupStyle: StyleFn = ({ height }) => ({
  height,
  width: height * 0.72,
  alignItems: 'stretch',
  alignSelf: 'center',
});

const headerTextDefaultStyle: StyleFn = ({ theme }) => ({
  marginBottom: '6%',
  textAlign: 'center',
  justifyContent: 'center',
  lineHeight: 21,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.bold,
  flexDirection: 'row',
  textTransform: 'uppercase',
});

const keypadContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
});

const actionButtonContainerStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small * 2.5,
  marginTop: '6.5%',
  justifyContent: 'space-between',
});

const successActionButtonStyle: StyleFn = ({ theme, width }) => ({
  flex: 0.47,
  width,
  backgroundColor: theme.colors.successLight,
  borderRadius: theme.radius.small,
});

const cancelButtonStyle: StyleFn = ({ theme, width }) => ({
  flex: 0.47,
  width,
  backgroundColor: theme.colors.danger2,
  borderRadius: theme.radius.small,
});

const MoneyInputKeypad: React.FC<MoneyInputKeypadProps> = ({
  onSubmit,
  headerText,
  onDismiss,
}: MoneyInputKeypadProps) => {
  const { formatCurrency, decimalSeparator, unFormatCurrency } = useCurrency();
  const screenHeight = useDimensions().window.height;
  const { css, theme } = useFela({
    width: screenHeight * 0.084,
  });
  const [value, setValue] = useState('');

  const onPressKey = useCallback(
    (key: string): void => {
      setValue(value =>
        addToValue(value, key, decimalSeparator, MAX_KEYPAD_INPUT),
      );
    },
    [decimalSeparator],
  );
  const onSubmitPin = useCallback(() => {
    onSubmit(unFormatCurrency(value));
  }, [value, onSubmit, unFormatCurrency]);

  const onPressClear = useCallback((): void => {
    setValue('0');
    onDismiss && onDismiss();
  }, [onDismiss]);

  const onPressReset = useCallback((): void => {
    setValue('0');
  }, []);

  const height = screenHeight * 0.67;
  return (
    <Modal
      showCloseButton={false}
      contentStyle={css(popupStyle({ theme, height }))}
    >
      <Text style={css(headerTextDefaultStyle)}>{headerText}</Text>
      <View>
        <InputDisplay
          testID="passcode"
          value={formatCurrency(unFormatCurrency(value) || 0)}
          right={<Redo onPress={onPressReset} />}
        />
        <View style={css(keypadContainerStyle)}>
          <NumberInput
            testID="keypad"
            onPressKey={onPressKey}
            containerStyle={css(numberInputStyle)}
            keys={[
              { value: '1' } as KeyButton,
              { value: '2' } as KeyButton,
              { value: '3' } as KeyButton,
              { value: '4' } as KeyButton,
              { value: '5' } as KeyButton,
              { value: '6' } as KeyButton,
              { value: '7' } as KeyButton,
              { value: '8' } as KeyButton,
              { value: '9' } as KeyButton,
              { value: '0' } as KeyButton,
              { value: decimalSeparator } as KeyButton,
            ]}
            keyWidth={height * (isWeb ? 0.15 : 0.142)}
            keyHeight={height * (isWeb ? 0.15 : 0.144) * 0.9}
            twistKeyWidth={isWeb ? 2.05 : isIos ? 2.07 : 2.15}
          />
          <View style={css(actionButtonContainerStyle)}>
            <IconButton
              testID="check"
              icon={'check'}
              iconColor={theme.colors.success}
              containerStyle={css(successActionButtonStyle)}
              onPress={onSubmitPin}
            />
            <IconButton
              testID="times"
              danger
              icon={'times'}
              iconColor={theme.colors.danger}
              containerStyle={css(cancelButtonStyle)}
              onPress={onPressClear}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default MoneyInputKeypad;
