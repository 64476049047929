import {
  PrintBillWorkerInput,
  WorkerActionResult,
  WorkerActionResultStatus,
  WorkerInput,
} from '../utils';
import { printOverNetwork } from '../../hooks/app/usePrintingHelper';
import '../../devConfig';
import { Buffer } from 'buffer';
import { PrinterTarget } from '../../utils/printerTemplates/printerUtils';

/**
 * Process print request.
 */
export default async function (
  message: WorkerInput,
): Promise<WorkerActionResult> {
  const handlerResult = message.data?.bufferObjs || [];
  const order = (message.data as PrintBillWorkerInput)?.order;
  const returnData = {
    buffer: handlerResult[0]?.buffer,
    printer: handlerResult[0]?.printer,
    order,
  };

  const results: {
    success: boolean;
    message: string;
  }[] = [];

  // call platform specific print function
  for (const eachPrint of handlerResult) {
    try {
      const result = await printOverNetwork(
        eachPrint.printer as PrinterTarget,
        Buffer.from(eachPrint.buffer),
      );
      results.push(result);
    } catch (exception) {
      results.push({
        success: false,
        message: `Print job failed: ${exception}`,
      });
    }
  }

  // in case of kitchen
  if (!results.length)
    return {
      status: WorkerActionResultStatus.SUCCESS,
      message: '',
      data: returnData,
    };

  const aggregatedStatus = results.every(result => !!result.success);

  return {
    status: aggregatedStatus
      ? WorkerActionResultStatus.SUCCESS
      : WorkerActionResultStatus.ERROR,
    message: results[0].message,
    data: returnData,
  };
}
