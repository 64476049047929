import { StyleFn, Table } from '@hitz-group/domain';
import { MaterialTopTabScreenProps } from '@react-navigation/material-top-tabs';
import React, { useMemo, useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import Draggable from 'react-native-draggable';
import {
  TableIcon,
  TableIconProps,
} from '../../../../components/TableIcon/TableIcon';
import useBehaviorSubjectState from '../../../../hooks/app/useSubjectState';
import { editModeController, TableAction } from './floorViewObservables';
import MultiOrderTableIcon, {
  MultiOrderTableIconProps,
} from './MultiOrderTableIcon';

const iconWrapperStyle: StyleFn = ({ theme, showBorder }) => ({
  borderColor: theme.colors.blue,
  borderWidth: showBorder ? 1 : 0,
  borderRadius: theme.radius.small,
});

export const SECTION_VIEW_NUMBER_TABLE_COLUMNS = 8;

export type SectionViewParam = MaterialTopTabScreenProps<
  {
    SectionView: { id: string };
  },
  'SectionView'
>;

export const calculateTablePositionX = (index: number) =>
  (index % SECTION_VIEW_NUMBER_TABLE_COLUMNS) * 120 +
  (1024 - 120 * SECTION_VIEW_NUMBER_TABLE_COLUMNS) / 2;

export const calculateTablePositionY = (index: number) =>
  Math.floor(index / SECTION_VIEW_NUMBER_TABLE_COLUMNS) * 80 + 50;

export interface DraggableTableIconProps
  extends TableIconProps,
    Omit<MultiOrderTableIconProps, 'showPopover'> {
  onPressIn: () => void;
  onDragTable: (t: Table) => void;
  onDragReleaseTable: (t: Table) => void;
  isDraggingTable: boolean;
  index: number;
  displayAsGrid?: boolean;
  tableAction?: TableAction;
}

const DraggableTableIcon: React.FC<DraggableTableIconProps> = props => {
  const {
    onPressIn,
    onDragTable,
    onDragReleaseTable,
    isDraggingTable,
    index,
    displayAsGrid = false,
    onRequestClose,
    orders,
    onPressSubTable,
    onPressTable,
    tableAction,
    ...tableIconProps
  } = props;
  const { table } = tableIconProps;
  const { css, theme } = useFela();
  const { value: isEditMode } = useBehaviorSubjectState(editModeController);
  const [displayPopover, setDisplayPopover] = useState<boolean>(false);

  const onDrag = useCallback(() => {
    onDragTable(table);
  }, [onDragTable, table]);

  const onDragRelease = useCallback(
    (event, gestureState, bound) => {
      onDragReleaseTable({ ...table, position: bound });
    },
    [onDragReleaseTable, table],
  );

  const positionX = useMemo(() => {
    if (typeof table?.position?.left === 'number') return table?.position?.left;
    if (!displayAsGrid) return 20;
    return calculateTablePositionX(index);
  }, [table?.position?.left, displayAsGrid, index]);

  const positionY = useMemo(() => {
    if (typeof table?.position?.top === 'number') return table?.position?.top;
    if (!displayAsGrid) return 80 * index;
    return calculateTablePositionY(index);
  }, [table?.position?.top, displayAsGrid, index]);

  const onPressTableAndOpenPopover = useCallback(
    (table: Table) => {
      onPressTable(table);
      if (
        orders?.length &&
        orders?.length > 1 &&
        tableAction !== TableAction.MERGE
      ) {
        setDisplayPopover(true);
      }
    },
    [onPressTable, orders?.length, tableAction],
  );

  const onCloseTablePopover = useCallback(() => {
    onRequestClose();
    setDisplayPopover(false);
  }, [onRequestClose]);

  const onPressSubTableAndClosePopover = useCallback(
    (table: Table, orderIndex?: number) => {
      onPressSubTable(table, orderIndex);
      setDisplayPopover(false);
    },
    [onPressSubTable],
  );

  return (
    <Draggable
      key={`${table.id}-top-${table?.position?.top}-left-${table?.position?.left}`}
      x={positionX}
      y={positionY}
      z={50 + index}
      onPressIn={onPressIn}
      onDrag={onDrag}
      onDragRelease={onDragRelease}
      disabled={!isEditMode}
      touchableOpacityProps={{ activeOpacity: 1.0 }}
      maxX={1024}
      maxY={665}
      minX={0}
      minY={0}
      animatedViewProps={{ height: 1024 }}
    >
      <View
        style={css(
          iconWrapperStyle({
            theme,
            showBorder: isDraggingTable,
          }),
        )}
      >
        {isEditMode ? (
          <TableIcon {...tableIconProps} onPressTable={onPressTable} />
        ) : (
          <MultiOrderTableIcon
            key={table.id}
            showPopover={displayPopover} // Popover in RN_MODAL need to controlled with state
            onRequestClose={onCloseTablePopover}
            orders={orders}
            onPressSubTable={onPressSubTableAndClosePopover}
            table={table}
          >
            <TableIcon
              {...tableIconProps}
              onPressTable={onPressTableAndOpenPopover}
              isMultiOrder={(orders?.length || 0) > 1 ? true : false}
            />
          </MultiOrderTableIcon>
        )}
      </View>
    </Draggable>
  );
};

export default DraggableTableIcon;
