import React from 'react';
import {
  View,
  TouchableOpacity,
  GestureResponderEvent,
  ViewStyle,
} from 'react-native';
import { FelaComponent, useFela } from 'react-fela';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import Loading from '../../screens/Loading/Loading';
import Title from '../Title/Title';
export interface SelectBarOptions {
  label: string;
  value: string;
  badge?: string | number;
}

export interface SelectBarProps {
  options: Array<SelectBarOptions>;
  selectedOption: string;
  enumTranslate?: boolean;
  secondary?: boolean;
  onPress: (value: string) => void;
  containerStyle?: ViewStyle;
  itemContainerStyle?: ViewStyle;
  itemLabelStyle?: StyleFn;
}

const selectBarStyle: StyleFn = ({ theme, secondary }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  backgroundColor: secondary
    ? theme.colors.primaryDarkest
    : theme.colors.greyLight,
  height: 44,
});

const badgeStyle: StyleFn = ({ theme }) => ({
  height: 10,
  width: 10,
  borderRadius: 7,
  position: 'absolute',
  top: 6,
  right: 6,
  zIndex: 1,
  backgroundColor: theme.colors.orange,
  alignItems: 'center',
  justifyContent: 'center',
});

export interface ItemProps {
  testID?: string;
  title?: string;
  onPress?: (event?: GestureResponderEvent) => void;
  transparent?: boolean;
  selected?: boolean;
  loading?: boolean;
  badge?: string | number;
  itemContainerStyle?: ViewStyle;
  itemLabelStyle?: StyleFn;
}

const itemStyle: StyleFn = ({ theme, selected }) => ({
  flex: 1,
  margin: 5,
  height: 35,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  backgroundColor: selected ? theme.colors.white : null,
  borderRadius: theme.radius.small,
  shadowColor: selected ? theme.colors.black : null,
  shadowOpacity: selected ? 0.15 : 0,
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: selected ? 6 : 0,
  elevation: selected ? 6 : 0,
});

const selectedItemLabelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Regular,
  color: theme.colors.grey2,
  lineHeight: 21,
});
const unSelectedItemLabelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  lineHeight: 21,
});

export const SelectBarItem: React.FC<ItemProps> = (props: ItemProps) => {
  const { css } = useFela();

  return (
    <FelaComponent style={itemStyle} {...props}>
      {({ style }: RenderProps): React.ReactFragment => (
        <TouchableOpacity
          style={[style, props.itemContainerStyle]}
          testID={props.testID}
          disabled={props.selected}
          onPress={props.onPress}
        >
          {props.selected ? (
            <Title
              labelStyle={css(selectedItemLabelStyle, props.itemLabelStyle)}
            >
              {props.loading ? <Loading /> : props.title}
            </Title>
          ) : (
            <Title
              labelStyle={css(unSelectedItemLabelStyle, props.itemLabelStyle)}
            >
              {props.loading ? <Loading /> : props.title}
            </Title>
          )}
          {props.badge ? <View style={css(badgeStyle)}></View> : null}
        </TouchableOpacity>
      )}
    </FelaComponent>
  );
};

const SelectBar: React.FC<SelectBarProps> = (props: SelectBarProps) => {
  return (
    <FelaComponent style={selectBarStyle} {...props}>
      {({ style }: RenderProps): React.ReactFragment => (
        <View style={[style, props.containerStyle]}>
          {props.options.map((option, index) => {
            return (
              <SelectBarItem
                key={index}
                testID={option.value}
                title={option.label}
                selected={props.selectedOption === option.value}
                transparent={props.selectedOption !== option.value}
                onPress={(): void => props.onPress(option.value)}
                itemContainerStyle={props.itemContainerStyle}
                itemLabelStyle={props.itemLabelStyle}
                badge={option.badge}
              />
            );
          })}
        </View>
      )}
    </FelaComponent>
  );
};

export default SelectBar;
