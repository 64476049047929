import {
  CountryInfo,
  RenderProps,
  StyleFn,
  TextAlignment,
} from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import {
  Text,
  TextInput,
  View,
  TextStyle,
  ViewStyle,
  TextInputProps,
  Platform,
  StyleProp,
  TouchableOpacity,
} from 'react-native';
import IconButton from '../Button/IconButton';
import Icon from '../Icon/Icon';
import DropDown from './DropDown';
import { countries } from 'countries-list';
import scale, { isWeb } from '../../common/theme';
import clipBoard from '@react-native-community/clipboard';

interface DropdownOption {
  label: string;
  value: string;
}

interface PrefixProps {
  text: string;
  textStyle?: TextStyle;
  containerStyle?: ViewStyle;
}

export interface FormInputProps extends TextInputProps {
  testID?: string;
  error?: boolean;
  title?: string;
  isRequired?: boolean;
  showLabel?: boolean;
  fluid?: boolean;
  verified?: boolean;
  type?: 'text' | 'number' | 'phone' | 'password' | 'DropDownInput';
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectChange?: (itemValue: any, itemPosition?: number) => void;
  // onPressCountry?: (value: string, label: string) => void;
  onPressCountry?: (item: string) => void;
  country?: string;
  countries?: CountryInfo[];
  alignTitle?: TextAlignment;
  textStyle?: TextStyle;
  containerStyle?: ViewStyle;
  action?: React.ReactNode;
  placeHolderIcon?: string;
  textInputStyle?: TextStyle;
  iconContainerStyles?: ViewStyle;
  dropdownList?: DropdownOption[];
  selectedValue?: string;
  readOnly?: boolean;
  prefix?: PrefixProps;
  extraStyle?: ViewStyle;
  extraViewStyle?: ViewStyle;
  extraMainViewStyle?: ViewStyle;
  dropDownStyle?: ViewStyle;
  style?: ViewStyle;
  angleDownIconStyle?: ViewStyle;
  rightIcon?: React.ReactNode;
  onClick?: () => void;
  onInputBlur?: () => void;
  showCopy?: boolean;
  textToCopy?: string;
}

export interface CountryPickerProps extends TextInputProps {
  country?: string;
  onSelectChange?: string;
}

const countryFlagStyle: StyleFn = () => ({
  color: 'red',
  position: 'absolute',
  height: '100%',
  width: '120%',
  top: 0,
  fontSize: scale.moderateScale(15),
  textAlign: 'center',
  paddingTop: scale.moderateScale(6),
});

interface CountryFlagProps {
  country: string;
}

const CountryFlag: React.FC<CountryFlagProps> = ({
  country,
}: CountryFlagProps) => (
  <FelaComponent style={countryFlagStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text style={style}>{countries[country]?.emoji || ''}</Text>
    )}
  </FelaComponent>
);

const labelStyle: StyleFn = ({ theme, alignTitle, title }) => ({
  paddingHorizontal: scale.moderateScale(5),
  paddingVertical: title ? scale.moderateScale(7) : 0,
  textAlign: alignTitle,
  ...theme.font14RegularDarkGrey,
});

const copyIconContainerStyle: StyleFn = ({ theme }) => ({
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
  marginLeft: theme.spacing.small,
});

const copyIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Label: React.FC<any> = (props: any) => (
  <FelaComponent style={labelStyle} {...props}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text
        style={props.textStyle ? [style, props.textStyle] : style}
        {...props}
      />
    )}
  </FelaComponent>
);

const pickerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  height: scale.moderateScale(50),
  width: scale.moderateScale(50),
  flexDirection: 'row',
  borderWidth: 0,
  textAlign: 'right',
  opacity: 0,
  color: theme.colors.danger,
  alignItems: 'center',
  justifyContent: 'center',
});

const pickerContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: scale.moderateScale(30),
});

const flagContainerStyle: StyleFn = () => ({
  height: scale.moderateScale(30),
  width: scale.moderateScale(30),
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  borderWidth: 0,
  bottom: isWeb ? scale.moderateScale(2) : -scale.moderateScale(2),
});

const CountryPicker: React.FC<Partial<FormInputProps>> = ({
  country: selectedCountry,
  onSelectChange,
  onPressCountry,
  type,
}) => {
  const { css } = useFela();

  return (
    <FelaComponent style={pickerStyle}>
      {({ style }: RenderProps): React.ReactFragment => (
        <View style={css(pickerContainerStyle)}>
          <View style={css(flagContainerStyle)}>
            <CountryFlag country={selectedCountry || ''} />
          </View>
          <DropDown
            mode="dialog"
            testID="countryPicker"
            values={Object.keys(countries).map(code => ({
              value: code,
              label: countries[code].emoji + '  ' + countries[code].name,
            }))}
            style={style}
            selectedValue={selectedCountry}
            onValueChange={onSelectChange}
            type={type}
            onPressCountry={onPressCountry}
            downIconAndroid={false}
          />
        </View>
      )}
    </FelaComponent>
  );
};

export interface PlaceHolderIconProps {
  name: string;
}

const placeHolderIconStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.boxBorder,
  height: '100%',
  width: scale.moderateScale(30),
});

const PlaceHolderIcon: React.FC<PlaceHolderIconProps> = ({
  name,
}: PlaceHolderIconProps) => (
  <FelaComponent style={placeHolderIconStyle}>
    {({ style, theme }: RenderProps): React.ReactFragment => (
      <View style={style}>
        <Icon name={name} color={theme.colors.paragraph} />
      </View>
    )}
  </FelaComponent>
);

const dropDownStyle: StyleFn = ({ theme, error }) => ({
  borderColor: theme.colors.boxBorder,
  backgroundColor: error ? '#fae3e2' : theme.colors.white,
  borderWidth: 0.8,
  height: scale.moderateScale(30),
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: scale.moderateScale(5),
  paddingHorizontal: scale.moderateScale(5),
  width: '100%',
});

export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: '100%',
  marginTop: scale.moderateScale(2),
});
export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: '100%',
  height: scale.moderateScale(30),
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});
export const currencyViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  height: scale.moderateScale(35),
  width: '90%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  marginLeft: scale.moderateScale(7),
});

const DropDownInput: React.FC<Partial<FormInputProps>> = ({
  selectedValue,
  onSelectChange,
  error,
  dropdownList,
  ...props
}) => {
  return (
    <FelaComponent style={dropDownStyle} error={error}>
      {({ style }: RenderProps): React.ReactFragment => (
        <View>
          <DropDown
            values={dropdownList ? dropdownList : []}
            style={style}
            selectedValue={selectedValue}
            onValueChange={onSelectChange}
            {...props}
          />
        </View>
      )}
    </FelaComponent>
  );
};

const fieldContainerStyle: StyleFn = ({ theme, error, fluid }) => ({
  borderColor: error ? theme.colors.danger2 : theme.colors.boxBorder,
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  borderWidth: 0.8,
  height: theme.input.height,
  width: fluid ? '100%' : scale.moderateScale(180),
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const textInputStyles: StyleFn = ({ theme }) => ({
  flex: 1,
  height: theme.input.height,
  width: '100%',
  paddingLeft: scale.moderateScale(7),
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  ...theme.font14RegularCharcoal,
  ...Platform.select({
    web: {
      outlineWidth: 0,
    },
  }),
});
const defaultIconContainerStyles: StyleFn = () => ({
  paddingHorizontal: scale.moderateScale(3),
});
export const textLabelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  textAlign: 'left',
});

const formInputWrapperStyle: StyleFn = () => ({
  flexDirection: 'row',
});

export const Field: React.FC<Partial<FormInputProps>> = ({
  testID,
  error,
  verified,
  type,
  onSelectChange,
  country,
  containerStyle,
  textInputStyle,
  action,
  placeHolderIcon,
  selectedValue,
  dropdownList,
  readOnly,
  prefix,
  onPressCountry,
  extraStyle,
  extraViewStyle,
  extraMainViewStyle,
  dropDownStyle,
  fluid,
  placeholder,
  angleDownIconStyle,
  rightIcon,
  onBlur,
  onClick,
  onInputBlur,
  showCopy,
  textToCopy = '',
  // FIXME: I dont see these below props are adding value.
  // title,
  // isRequired,
  // countries,
  // alignTitle,
  // textStyle,
  // iconContainerStyles,
  // style,
  ...props
}) => {
  const isPassword = type === 'password';
  const [revealPassword, setRevealPassword] = useState(false);
  const onPressRevealPassword = useCallback(() => {
    setRevealPassword(!revealPassword);
  }, [revealPassword]);
  const { css, theme } = useFela();

  const onChangeText = useCallback(
    (text: string) => {
      if (type === 'number') {
        !isNaN(Number(text)) && props?.onChangeText?.(text);
      } else {
        props?.onChangeText?.(text);
      }
    },
    [props, type],
  );

  const copyToClipboard = useCallback(() => {
    if (Platform.OS === 'web') {
      const tempElement = document.createElement('textarea');
      tempElement.value = textToCopy;
      document.body.appendChild(tempElement);
      tempElement.select();
      navigator.clipboard.writeText(textToCopy);
      document.body.removeChild(tempElement);
    } else {
      clipBoard.setString(textToCopy);
    }
  }, [textToCopy]);

  return (
    <FelaComponent style={fieldContainerStyle} error={error} fluid={fluid}>
      {({ style }: RenderProps): React.ReactFragment => (
        <TouchableOpacity style={css(formInputWrapperStyle)} onPress={onClick}>
          <View style={containerStyle ? [style, containerStyle] : style}>
            {type === 'DropDownInput' && (
              <DropDownInput
                onSelectChange={onSelectChange}
                selectedValue={selectedValue}
                error={error}
                dropdownList={dropdownList}
                onBlur={onBlur}
                extraStyle={extraStyle}
                extraViewStyle={extraViewStyle}
                extraMainViewStyle={extraMainViewStyle}
                style={dropDownStyle}
                angleDownIconStyle={angleDownIconStyle}
              />
            )}
            {placeHolderIcon && <PlaceHolderIcon name={placeHolderIcon} />}

            {prefix && (
              <View style={prefix.containerStyle} testID={'prefix'}>
                <Text style={prefix.textStyle}>{prefix.text}</Text>
              </View>
            )}
            <FelaComponent style={textInputStyles} onClick={onClick}>
              {({ style }: RenderProps): React.ReactFragment => (
                <TextInput
                  testID={testID}
                  style={[style, textInputStyle]}
                  secureTextEntry={isPassword && !revealPassword}
                  editable={!readOnly}
                  placeholderTextColor={theme.colors.paragraph}
                  placeholder={placeholder}
                  {...props}
                  onChangeText={onChangeText}
                  onBlur={onInputBlur}
                />
              )}
            </FelaComponent>

            {error && (
              <View style={css(defaultIconContainerStyles)}>
                <Icon name="exclamation-triangle" size={20} color="red" />
              </View>
            )}
            {verified && (
              <View style={css(defaultIconContainerStyles)}>
                <Icon name="check" size={20} color="green" />
              </View>
            )}
            {!error && rightIcon && (
              <View style={css(defaultIconContainerStyles)}>{rightIcon}</View>
            )}
            {isPassword && (
              <View style={css(defaultIconContainerStyles)}>
                <IconButton
                  iconSize={24}
                  testID={`${testID}-revealPassword`}
                  icon={revealPassword ? 'EyeSlash' : 'Eye'}
                  onPress={onPressRevealPassword}
                  iconColor={
                    revealPassword
                      ? theme.colors.paragraph
                      : theme.colors.primary
                  }
                />
              </View>
            )}

            {type === 'phone' && (
              <CountryPicker
                country={country}
                onSelectChange={onSelectChange}
                onPressCountry={onPressCountry}
                type={'phone'}
              />
            )}

            {action}
          </View>

          {showCopy && (
            <IconButton
              icon="copy"
              iconSize={24}
              containerSize={34}
              containerStyle={css(copyIconContainerStyle)}
              iconStyle={css(copyIconStyle)}
              onPress={copyToClipboard}
            />
          )}
        </TouchableOpacity>
      )}
    </FelaComponent>
  );
};

const containerStyle: StyleFn = ({ fluid }) => ({
  flexDirection: 'column',
  justifyContent: 'space-around',
  width: fluid ? '100%' : 'auto',
});

const FormInput: React.FC<
  FormInputProps & {
    infoText?: string;
    infoTextStyle?: StyleProp<TextStyle>;
  }
> = ({ infoText, infoTextStyle, ...props }) => {
  const { css } = useFela({ fluid: props.fluid });
  const label = props.isRequired ? `${props.title} *` : props.title;
  return (
    <View style={css(containerStyle)}>
      {props.showLabel ? (
        <Label
          alignTitle={props.alignTitle}
          textStyle={[css(textLabelStyle), props.textStyle]}
          title={props.title}
        >
          {label}
        </Label>
      ) : null}
      <Field {...props} />
      {infoText && (
        <Text style={infoTextStyle} testID="infoText">
          {infoText}
        </Text>
      )}
    </View>
  );
};

FormInput.defaultProps = {
  alignTitle: 'center',
  readOnly: false,
  keyboardType: 'default',
  maxLength: 50,
  showLabel: true,
};

export default FormInput;
