import React, {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  View,
  ViewStyle,
  Text,
  TouchableOpacity,
  TextStyle,
  SectionList,
  StyleProp,
} from 'react-native';
import { useFela, FelaComponent } from 'react-fela';
import { StyleFn, RenderProps, Icons } from '@hitz-group/domain';
import Icon from '../Icon/Icon';
import scale, { isWeb } from '../../common/theme';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import IconButton from '../Button/IconButton';

const Label: React.FC<{
  alignTitle: 'left';
  titleStyle: StyleProp<TextStyle>;
  children: ReactNode;
}> = props => (
  <FelaComponent {...props} style={labelStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text style={[style, props.titleStyle]} {...props} />
    )}
  </FelaComponent>
);

export interface DropdownGroupProps {
  btnTestId?: string;
  title?: string;
  options: DropdownGroupOptionsProps[];
  selectedValue?: string;
  touchableStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  titleStyle?: TextStyle;
  itemsContainerStyle?: ViewStyle;
  itemStyle?: ViewStyle;
  onValueChange?: (value: DropdownGroupSection[]) => void;
  arrowVisible?: boolean;
  PopoverViewPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | 'auto';
  extraPopoverStyle?: ViewStyle;
  isDisabled?: boolean;
  iconStyleFunction?: StyleFn;
  sectionHeaderLabelSuffix?: string;
  testID?: string;
}

export const iconStyle: StyleFn = () => ({
  position: 'absolute',
  right: scale.moderateScale(30),
  top: scale.moderateScale(20),
  paddingHorizontal: scale.moderateScale(4),
});

export const textLabelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
});

const viewStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
});

const touchContainerStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
  borderWidth: 0.5,
  borderRadius: theme.radius.small,
  height: theme.input.height,
  paddingHorizontal: theme.padding.medium,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const cursorStyle: StyleFn = () => ({
  cursor: 'default',
});
const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  marginLeft: theme.spacing.small / 2,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginLeft: 'auto',
});

const labelStyle: StyleFn = ({ theme, alignTitle }) => ({
  ...theme.font14RegularDarkGrey,
  textAlign: alignTitle,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderWidth: 1,
  borderColor: theme.colors.boxBorder,
});
const backgroundStyle: StyleFn = ({ theme }) => ({
  height: scale.deviceHeight,
  width: '100%',
  backgroundColor: theme.colors.transparent,
  position: 'absolute',
});
const hideToolTipArrowStyle: StyleFn = () => ({
  backgroundColor: 'transparent',
});
const showToolTipArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});
const popoverStyle: StyleFn = () => ({
  width: isWeb ? 280 : 200,
});

const checkboxStyles: StyleFn = ({ theme }) => ({
  padding: theme.padding.medium,
  fontSize: theme.fontSize.regular,
});

const checkboxContainerStyles: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
  margin: isWeb ? 0 : 10,
});

const selectedItemTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  letterSpacing: -0.5,
  fontFamily: theme.font.medium,
  paddingHorizontal: theme.padding.small,
  paddingVertical: theme.padding.small,
});

const selectedItemContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.lightBlueSelect,
  marginLeft: theme.spacing.small * 0.7,
  flexDirection: 'row',
  borderRadius: theme.radius.small,
  marginVertical: theme.padding.small,
  maxWidth: 90,
  minWidth: 60,
  justifyContent: 'center',
});

const checkBoxTitleStyle: StyleFn = ({ theme }) => ({
  textTransform: 'none',
  ...theme.font14RegularCharcoal,
});

const separatorStyles: StyleFn = ({ theme }) => ({
  width: '90%',
  alignSelf: 'center',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});
export interface DropdownGroupSection {
  id: string;
  label: string;
  data: DropDownItem[];
  checked: boolean;
}

interface DropdownGroupItemProps extends Pick<DropDownItem, 'id' | 'label'> {
  checked?: boolean;
}

export interface DropdownGroupOptionsProps
  extends Pick<DropdownGroupSection, 'id' | 'label'> {
  data: DropdownGroupItemProps[];
  checked?: boolean;
}

interface DropDownItem {
  id: string;
  label: string;
  checked: boolean;
}

const ItemGroupHeader: FC<{
  section: DropdownGroupSection;
  onSelectSection: (props: DropdownGroupSection, checked: boolean) => void;
  sectionHeaderLabelSuffix?: string;
}> = ({ section, onSelectSection, sectionHeaderLabelSuffix }) => {
  const { css, theme } = useFela();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(section.checked);
  }, [section.checked]);

  const onPressItem = useCallback(() => {
    setChecked(prev => !prev);
    onSelectSection(section, !checked);
  }, [onSelectSection, section, checked]);

  return (
    <TouchableOpacity
      key={section.id}
      onPress={onPressItem}
      style={css(checkboxContainerStyles)}
    >
      <Icon
        style={css(checkboxStyles)}
        name={checked ? Icons.CheckSquare : Icons.SquareFull}
        color={checked ? theme.colors.success : theme.colors.charcoal}
      />
      <Text style={css(checkBoxTitleStyle)}>
        {section.label} {sectionHeaderLabelSuffix}
      </Text>
    </TouchableOpacity>
  );
};

const Item: FC<{
  item: DropDownItem;
  section: DropdownGroupSection;
  onSelectItem: (sectionId: string, itemId: string, checked: boolean) => void;
}> = ({ item, section, onSelectItem }) => {
  const { css, theme } = useFela();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(item.checked);
  }, [item.checked]);

  const onPressItem = useCallback(() => {
    setChecked(prev => !prev);
    onSelectItem(section?.id, item.id, !checked);
  }, [onSelectItem, section?.id, item.id, checked]);

  return (
    <TouchableOpacity
      key={item.id}
      onPress={onPressItem}
      style={css(checkboxContainerStyles)}
    >
      <Icon
        style={css(checkboxStyles)}
        name={checked ? Icons.CheckSquare : Icons.SquareFull}
        color={checked ? theme.colors.success : theme.colors.charcoal}
      />
      <Text style={css(checkBoxTitleStyle)}>{item.label}</Text>
    </TouchableOpacity>
  );
};

const SectionSeparatorComponent: FC<{
  trailingSection: DropdownGroupSection;
  trailingItem: DropdownGroupItemProps;
}> = ({ trailingSection, trailingItem }) => {
  const { css } = useFela();

  if (trailingSection && !trailingItem) {
    return <View style={css(separatorStyles)} />;
  }
  return null;
};

const DropdownGroup: React.FC<DropdownGroupProps> = ({
  btnTestId,
  title,
  options,
  onValueChange,
  containerStyle,
  titleStyle,
  itemsContainerStyle,
  touchableStyle,
  arrowVisible = false,
  PopoverViewPlacement = 'bottom',
  extraPopoverStyle,
  isDisabled,
  iconStyleFunction,
  sectionHeaderLabelSuffix,
  testID,
}) => {
  const { css, theme } = useFela();
  const [showOptions, setShowOptions] = useState(false);

  const onToggleOptions = useCallback((): void => {
    if (!isDisabled) {
      setShowOptions(current => !current);
    }
  }, [isDisabled]);

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const getPopoverPlacement = useCallback(() => {
    switch (PopoverViewPlacement) {
      case 'top':
        return PopoverPlacement.TOP;
      case 'bottom':
        return PopoverPlacement.BOTTOM;
      case 'center':
        return PopoverPlacement.CENTER;
      case 'left':
        return PopoverPlacement.LEFT;
      case 'right':
        return PopoverPlacement.RIGHT;
      case 'auto':
        return PopoverPlacement.AUTO;
      default:
        break;
    }
  }, [PopoverViewPlacement]);

  const [optionsList, setOptionsList] = useState<DropdownGroupSection[]>([]);

  useEffect(() => {
    setOptionsList(
      options.map(section => ({
        ...section,
        checked: section.checked || false,
        data: section.data.map(item => ({
          ...item,
          checked: item.checked || false,
        })),
      })),
    );
  }, [options]);

  const onSelectItem = useCallback(
    (sectionId: string, itemId: string, checked: boolean) => {
      const currentSection = optionsList.find(
        eachSection => eachSection.id === sectionId,
      ) as DropdownGroupSection;
      const updatedSectionItems = currentSection?.data.map(item => {
        if (item.id === itemId) {
          return { ...item, checked };
        }
        return item;
      });
      const updatedCurrentSection = optionsList.map(eachSection => {
        if (eachSection.id === sectionId) {
          return {
            ...eachSection,
            checked: updatedSectionItems?.every(item => item.checked),
            data: updatedSectionItems,
          };
        }
        return eachSection;
      });
      setOptionsList(updatedCurrentSection);
      if (onValueChange && updatedCurrentSection) {
        onValueChange(updatedCurrentSection);
      }
    },
    [optionsList, onValueChange],
  );

  const onSelectSection = useCallback(
    (section: DropdownGroupSection, checked: boolean) => {
      const currentSection = optionsList.find(
        eachSection => eachSection.id === section.id,
      );
      const updatedCurrentSection = {
        ...currentSection,
        checked: checked,
        data: currentSection?.data.map(item => ({
          ...item,
          checked: checked,
        })),
      };

      const updatedOptions = optionsList.map(eachSection => {
        if (eachSection.id === section.id) {
          return updatedCurrentSection as DropdownGroupSection;
        }
        return eachSection;
      });
      setOptionsList(updatedOptions);
      if (onValueChange && updatedOptions) {
        onValueChange(updatedOptions);
      }
    },
    [optionsList, onValueChange],
  );

  const renderSectionHeader = useCallback(
    ({ section }) => (
      <ItemGroupHeader
        section={section}
        onSelectSection={onSelectSection}
        sectionHeaderLabelSuffix={sectionHeaderLabelSuffix}
      />
    ),
    [onSelectSection, sectionHeaderLabelSuffix],
  );

  const renderItem = useCallback(
    ({ item, section }) => {
      return <Item section={section} item={item} onSelectItem={onSelectItem} />;
    },
    [onSelectItem],
  );

  const renderSelectedItems = useCallback(() => {
    const selectedItems = optionsList.reduce((acc, section) => {
      const checkedItems = section.data
        .filter(item => {
          if (item.checked) {
            return item.label;
          }
          return false;
        })
        .map(item => item.label);

      if (section.checked) {
        checkedItems.push(section.label);
      }

      return [...acc, ...checkedItems];
    }, [] as string[]);
    if (selectedItems?.length) {
      const remainingItemsCount =
        selectedItems.length - selectedItems.slice(0, 2).length;

      const selectedItemsView = selectedItems
        .slice(0, 2)
        .map((checkedItem, i) => {
          return (
            <View style={css(selectedItemContainerStyle)} key={i}>
              <Text numberOfLines={1} style={css(selectedItemTextStyle)}>
                {checkedItem}
              </Text>
            </View>
          );
        });

      return (
        <>
          {selectedItemsView}

          {remainingItemsCount ? (
            <View style={css(selectedItemContainerStyle)}>
              <Text style={css(selectedItemTextStyle)}>
                {remainingItemsCount}
              </Text>
            </View>
          ) : (
            <Fragment></Fragment>
          )}
        </>
      );
    }
    return <Text style={css(textStyle)}>Select location(s)</Text>;
  }, [optionsList, css]);

  return (
    <View style={[css(viewStyle), containerStyle]} testID={testID}>
      <Popover
        arrowStyle={
          arrowVisible ? css(showToolTipArrowStyle) : css(hideToolTipArrowStyle)
        }
        placement={getPopoverPlacement()}
        popoverStyle={[css(popoverStyle) && extraPopoverStyle]}
        from={
          <TouchableOpacity activeOpacity={1}>
            <>
              {title && (
                <Label alignTitle="left" titleStyle={titleStyle}>
                  {title}
                </Label>
              )}
              <TouchableOpacity
                testID={btnTestId}
                style={[
                  css([
                    touchContainerStyle,
                    isDisabled && isWeb && cursorStyle,
                  ]),
                  touchableStyle,
                ]}
                onPress={onToggleOptions}
                activeOpacity={isDisabled ? 1 : 0.2}
              >
                {renderSelectedItems()}

                <IconButton
                  icon={Icons.AngleDown}
                  primary
                  containerStyle={css(iconContainerStyle, iconStyleFunction)}
                  disabled
                  iconColor={
                    isDisabled ? theme.colors.transparent : theme.colors.primary
                  }
                />
              </TouchableOpacity>
            </>
          </TouchableOpacity>
        }
        backgroundStyle={css(backgroundStyle)}
        isVisible={showOptions}
        onRequestClose={onRequestClosePopover}
      >
        <View style={css(dropDownStyle)}>
          <SectionList
            style={itemsContainerStyle}
            sections={optionsList}
            renderItem={renderItem}
            renderSectionHeader={renderSectionHeader}
            SectionSeparatorComponent={SectionSeparatorComponent}
          />
        </View>
      </Popover>
    </View>
  );
};

export default DropdownGroup;
