import React, { useState } from 'react';
import {
  View,
  ViewStyle,
  Text,
  Modal,
  TouchableOpacity,
  TextStyle,
  FlatList,
  Platform,
} from 'react-native';
import { useFela, FelaComponent } from 'react-fela';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import { Picker } from '@react-native-community/picker';
import Button from '../../components/Button/Button';
import Icon from '../Icon/Icon';
import scale, { isWeb, isAndroid, isIos } from '../../common/theme';

const containerStyle: StyleFn = ({ theme }) => ({
  ...Platform.select({
    android: {
      borderColor: theme.colors.black,
      backgroundColor: theme.colors.white,
      height: theme.input.height - 2,
      width: '100%',
      overflow: 'hidden',
      borderRadius: theme.radius.small,
      paddingHorizontal: scale.moderateScale(10),
      padding: scale.moderateScale(5),
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    default: {
      borderColor: theme.colors.boxBorder,
      backgroundColor: theme.colors.white,
      height: theme.input.height,
      width: scale.textInputWidth180,
      borderRadius: theme.radius.small,
      padding: scale.moderateScale(7),
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...theme.font14RegularCharcoal,
    },
  }),
});

const extraMainView: StyleFn = ({ theme }) => ({
  height: theme.input.height,
});
const dropDownTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.black,
  flex: 1,
  ...theme.font14Regular,
});

const labelStyle: StyleFn = ({ theme, alignTitle }) => ({
  paddingHorizontal: scale.moderateScale(7),
  paddingVertical: scale.moderateScale(5),
  textAlign: alignTitle,
  ...theme.font14RegularDarkGrey,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Label: React.FC<any> = (props: any) => (
  <FelaComponent {...props} style={labelStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text style={[style, props.textStyle]} {...props} />
    )}
  </FelaComponent>
);

const viewStyle: StyleFn = ({ fluid }) => ({
  flexDirection: 'column',
  justifyContent: 'space-around',
  width: fluid ? '100%' : 'auto',
});

const Container: StyleFn = ({ theme }) => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.modalBgColor,
});
const innerContainer: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(200),
  height: scale.moderateScale(200),
  backgroundColor: theme.colors.white,
  alignItems: 'center',
  justifyContent: 'center',
  ...theme.shadow30,
  borderRadius: theme.radius.small,
});
const innerContainerForPhone: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(250),
  height: scale.moderateScale(300),
  backgroundColor: theme.colors.white,
  alignItems: 'center',
  justifyContent: 'center',
  ...theme.shadow30,
  borderRadius: theme.radius.small,
});
const pickerContainer: StyleFn = () => ({
  height: '70%',
  alignItems: 'center',
  justifyContent: 'center',
});
const buttonContainer: StyleFn = () => ({
  height: '25%',
  alignItems: 'center',
  justifyContent: 'center',
});

export interface DropDownProps {
  testID?: string;
  title?: string;
  fluid?: boolean;
  values: Array<{ label: string; value: string | number }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueChange?: (itemValue: any, itemPosition: number) => void;
  selectedValue?: string;
  style?: ViewStyle;
  extraStyle?: ViewStyle;
  extraViewStyle?: ViewStyle;
  extraMainViewStyle?: ViewStyle;
  mode?: 'dialog' | 'dropdown';
  accessibilityLabel?: string;
  accessibilityHint?: string;
  downIconAndroid?: boolean;
  textStyle?: TextStyle;
  type?: 'text' | 'number' | 'phone' | 'password' | 'DropDownInput';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPressCountry?: any;
  angleDownIconStyle?: ViewStyle;
}

export const iconStyle: StyleFn = () => ({
  position: 'absolute',
  right: scale.moderateScale(30),
  top: scale.moderateScale(20),
  paddingHorizontal: scale.moderateScale(4),
});

const textInputStyles: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: '45%',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  borderWidth: 0.8,
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.boxBorder,
  flexDirection: 'row',
  paddingLeft: scale.moderateScale(5),
});

const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});

const TitleTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});

const textContainer: StyleFn = () => ({
  width: '90%',
  justifyContent: 'center',
});
const iconContainer: StyleFn = () => ({
  width: '10%',
  justifyContent: 'center',
});
const dropDownIconContainer: StyleFn = () => ({
  width: '10%',
  justifyContent: 'center',
  right: scale.moderateScale(15),
});

const itemText: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  left: 10,
});
const itemContainer: StyleFn = ({ theme }) => ({
  margin: 10,
  backgroundColor: '#e5e5e5',
  paddingVertical: theme.padding.medium,
  width: scale.moderateScale(220),
  borderRadius: theme.radius.large,
});

const CountrytextInputStyles: StyleFn = ({ theme }) => ({
  height: scale.moderateScale(25),
  width: '45%',
  paddingLeft: scale.moderateScale(5),
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  backgroundColor: '#ffffff00',
  borderColor: theme.colors.white,
  flexDirection: 'row',
  top: 2,
});
export const textLabelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
});

const DropDown: React.FC<DropDownProps> = ({
  testID,
  title,
  values,
  onValueChange,
  selectedValue,
  style,
  extraStyle,
  extraViewStyle,
  extraMainViewStyle,
  downIconAndroid = true,
  mode,
  fluid,
  type,
  onPressCountry,
  angleDownIconStyle,
  ...props
}: DropDownProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { css, theme } = useFela({ fluid });

  const onSelectCountry = (item: string | number): void => {
    onPressCountry(item);
    setModalVisible(false);
  };

  return (
    <View style={[css(viewStyle), extraViewStyle]}>
      {title && (
        <Label
          alignTitle="left"
          textStyle={[css(textLabelStyle), props.textStyle]}
        >
          {title}
        </Label>
      )}
      {isAndroid && (
        <View style={[css(extraMainView), extraMainViewStyle]}>
          <Picker
            mode={mode}
            testID={testID}
            selectedValue={selectedValue}
            style={[css(containerStyle), type === 'phone' && style]}
            itemStyle={css(dropDownTextStyle)}
            onValueChange={onValueChange}
            accessibilityRole="menu"
            {...props}
          >
            {values.map(option => (
              <Picker.Item
                key={option.value}
                label={option.label}
                value={option.value}
              />
            ))}
          </Picker>
          {downIconAndroid && (
            <View style={css(dropDownIconContainer)}>
              <Icon name="AngleDown" size={25} color="black" />
            </View>
          )}
        </View>
      )}

      {isWeb && (
        <Picker
          mode={mode}
          testID={testID}
          selectedValue={selectedValue}
          style={[css(containerStyle), style]}
          itemStyle={css(dropDownTextStyle)}
          onValueChange={onValueChange}
          accessibilityRole="menu"
          {...props}
        >
          {values.map(option => (
            <Picker.Item
              key={option.value}
              label={option.label}
              value={option.value}
            />
          ))}
        </Picker>
      )}
      {isIos && (
        <Modal
          animationType="none"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <View style={css(Container)}>
            <View
              style={
                type === 'phone'
                  ? css(innerContainerForPhone)
                  : css(innerContainer)
              }
            >
              {type === 'phone' ? (
                <FlatList
                  data={values}
                  renderItem={({ item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          onSelectCountry(item.value);
                        }}
                      >
                        <View style={css(itemContainer)}>
                          <Text style={css(itemText)}>{item.label}</Text>
                        </View>
                      </TouchableOpacity>
                    );
                  }}
                />
              ) : (
                <View style={css(pickerContainer)}>
                  <Picker
                    mode={mode}
                    testID={testID}
                    selectedValue={selectedValue}
                    style={css(containerStyle)}
                    itemStyle={css(dropDownTextStyle)}
                    onValueChange={onValueChange}
                    accessibilityRole="menu"
                    {...props}
                  >
                    {values.map(option => {
                      return (
                        <Picker.Item
                          key={option.value}
                          label={option.label}
                          value={option.value}
                        />
                      );
                    })}
                  </Picker>
                </View>
              )}
              <View style={css(buttonContainer)}>
                <Button
                  testID="Done"
                  size="small"
                  success
                  onPress={() => setModalVisible(false)}
                  title={type === 'phone' ? 'Close' : 'Done'}
                />
              </View>
            </View>
          </View>
        </Modal>
      )}
      {isIos && type === 'phone' && (
        <TouchableOpacity onPress={() => setModalVisible(true)}>
          <View style={css(CountrytextInputStyles)}></View>
        </TouchableOpacity>
      )}
      {isIos && type !== 'phone' && (
        <TouchableOpacity onPress={() => setModalVisible(true)}>
          <View style={[css(textInputStyles), extraStyle]}>
            <View style={css(textContainer)}>
              <Text
                style={selectedValue ? css(textStyle) : css(TitleTextStyle)}
              >
                {(selectedValue &&
                  values &&
                  values.find(item => item.value === selectedValue)?.label) ||
                  (values.length > 0 && values[0].label) ||
                  title}
              </Text>
            </View>
            <View style={[angleDownIconStyle, css(iconContainer)]}>
              <Icon name="AngleDown" size={24} color={theme.colors.paragraph} />
            </View>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default DropDown;
