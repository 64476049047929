import { gql } from '@apollo/client';

const productPricingFragment = `
  id
  isActive
  taxInclusive
  pricingGroup {
    id
  }
  sellingPrice {
    amount
    currency
  }
  costPrice {
    amount
    currency
  }
  sellingTax {
    id
    name
    code
    rate
  }
  product {
    id
    name
    pages {
      id
    }
  }
`;

const pricingGroupFragment = `
  id
  name
  isActive
  prices{
    ${productPricingFragment}
  }
  venues{
    id
  }
  orderTypes {
    id
  }
  salesChannels {
    id
  }
  stores {
    id
  }
  schedule {
    dateRange {
      startDate
      endDate
    }
    timeBlocks {
      day
      isActive
      timeSlot {
        startTime
        endTime
      }
    }
  }
`;

export const GET_PRICING_GROUP = gql`
  query pricingGroup($id: ID!) {
    pricingGroup(id: $id) {
      ${pricingGroupFragment}
    }
  }
`;

export const GET_PRICING_GROUPS_QUERY = gql`
  query pricingGroups {
    pricingGroups {
      ${pricingGroupFragment}
    }
  }
`;

export const GET_PRICING_GROUPS_BY_STORE = gql`
  query pricingGroups($filter: PricingFilterInput) {
    pricingGroups(filter: $filter) {
      id
      name
      isActive
    }
  }
`;

export const CREATE_PRICING_GROUP = gql`
  mutation createPricingGroup($input: CreatePricingGroupInput!) {
    createPricingGroup(input: $input) {
      ${pricingGroupFragment}
    }
  }
`;

export const UPDATE_PRICING_GROUPS = gql`
  mutation updatePricingGroups($input: [UpdatePricingGroupInput!]!) {
    updatePricingGroups(input: $input) {
      ${pricingGroupFragment}
    }
  }
`;

export const CLONE_PRICING_GROUP = gql`
  mutation updatePricingGroup($input: ClonePricingGroupInput!) {
    clonePricingGroup(input: $input) {
      ${pricingGroupFragment}
    }
  }
`;

export const DELETE_PRICING_GROUPS = gql`
  mutation deletePricingGroups($ids: [ID!]!) {
    deletePricingGroups(ids: $ids)
  }
`;

/**
 * custum fragment on hooks
 */

export const pricingGroupInfoDetails = `
  id
  name
`;

export const getPricingGroupQuery = (customFragment?: string) => {
  const gqlQuery = gql`
    query pricingGroup($id: ID!) {
      pricingGroup(id: $id) {
        ${customFragment ? customFragment : pricingGroupFragment}
      }
    }
  `;
  return gqlQuery;
};

export const getPricingGroupsQuery = (customFragment?: string) => {
  const gqlQuery = gql`
    query pricingGroups {
      pricingGroups {
        ${customFragment ? customFragment : pricingGroupFragment}
      }
    }
  `;
  return gqlQuery;
};
