import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import FormInput from '../../FormInput/FormInput';
import PopupView from '../../PopupView/PopupView';
import TitleBar from '../../TitleBar/TitleBar';

import {
  StyleFn,
  OrderAction,
  NotificationType,
  NotificationMode,
  Order,
} from '@hitz-group/domain';
import { isValidEmail } from '../../../utils/validator';
import { v4 as uuidv4 } from 'uuid';

import { useModal } from '@hitz-group/rn-use-modal';
import { useFela } from 'react-fela';
import { useSession } from '../../../hooks/app/useSession';
import { useTranslation } from '@hitz-group/localization';
import { useSyncOrderEvents } from '../../../hooks/app/useSyncOrderEvents';

import { userUtility } from '../../../state/userUtility';
import { storeOrderEventsInDb } from '../../../utils/orderEventHelper';

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 8,
});
const containerStyle: StyleFn = ({ theme }) => ({
  width: 320,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  flex: 1,
});

const popupStyle: StyleFn = () => ({
  paddingTop: 8,
});

const buttonStyle: StyleFn = () => ({
  marginTop: 40,
});

export interface SendOrderReceiptModalProps {
  onSendSuccess: () => void;
  onSendFailed: () => void;
  order: Order;
}

const SendOrderReceiptModal: React.FC<SendOrderReceiptModalProps> = ({
  onSendSuccess,
  onSendFailed,
  order,
}) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [session] = useSession();

  const customerEmail = order?.customer?.email;
  const [email, setEmail] = useState<string | undefined>(customerEmail);
  const [isPressSubmit, setPressSubmit] = useState(false);

  const { syncOrderEvents } = useSyncOrderEvents(onSendSuccess, onSendFailed);

  const sendOrderReceipt = useCallback(
    async email => {
      if (session && order?.id) {
        const event = {
          action: OrderAction.CUSTOMER_NOTIFICATION,
          orderId: order.id,
          id: uuidv4(),
          notificationType: NotificationType.SEND_RECEIPT,
          notificationMode: NotificationMode.EMAIL,
          organizationId: session?.currentOrganization?.id || '',
          venueId: session?.currentVenue?.id || '',
          storeId: session?.currentStore?.id || '',
          deviceId: session?.device?.id || '',
          timestamp: new Date().getTime(),
          triggeredBy: userUtility.userActivity.posUser?.id || '',
          email: email,
        };
        storeOrderEventsInDb([event]);

        syncOrderEvents([event]);
      }
    },
    [session, order.id, syncOrderEvents],
  );

  const handleCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const titleLeft = (
    <IconButton
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={handleCloseModal}
    />
  );
  const handleSendReceipt = useCallback(() => {
    setPressSubmit(true);
    if (!isValidEmail(email || '')) return;
    sendOrderReceipt(email);
    handleCloseModal();
  }, [email, handleCloseModal, sendOrderReceipt]);

  return (
    <View style={css(containerStyle)}>
      <TitleBar
        titleLeft={titleLeft}
        primary
        title={translate('modal.emailReceipt')}
      />
      <PopupView containerStyle={css(popupStyle)}>
        <FormInput
          verified={isValidEmail(email || '')}
          error={isPressSubmit && !isValidEmail(email || '')}
          testID="email-address"
          value={email}
          fluid
          placeholder={translate('common.emailPlaceholder')}
          onChangeText={setEmail}
          title={translate('common.emailAddress')}
        />
        <Button
          title={translate('button.sendReceipt')}
          fluid
          success
          size="medium"
          testID="send-receipt"
          containerStyle={css(buttonStyle)}
          onPress={handleSendReceipt}
        />
      </PopupView>
    </View>
  );
};

export default SendOrderReceiptModal;
