import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import Product from './Product';
import Variant from './Variant';
import {
  StyleFn,
  OptionValue,
  OrderItemStatus,
  VoidReason,
  AdjustmentType,
} from '@hitz-group/domain';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { CartSelectionState } from '../Cart';
import { OrderItemModifier } from '@hitz-group/domain';

export interface CartItemProps {
  id: string;
  productId: string;
  name: string;
  quantity: number;
  price: number;
  isSelected?: boolean;
  variants?: OptionValue[];
  modifiers?: Partial<OrderItemModifier>[];
  onSelectItem?: (state: CartSelectionState) => void;
  notes?: string;
  discountAmount?: number;
  surchargeAmount?: number;
  variantId?: string;
  status: OrderItemStatus;
  reason?: string;
  testID?: string;
  disabled?: boolean;
  isRefundOrder?: boolean;
  selectedSubItem?: string;
  showFiredIcon?: boolean;
  itemFired?: boolean;
  seatNumber?: string;
}

const cartItemStyle: StyleFn = () => ({
  margin: 0,
  top: 5,
});

const itemNoteStyle: StyleFn = ({ theme, lineThrough }) => ({
  letterSpacing: -0.5,
  marginBottom: theme.spacing.small / 2,
  fontFamily: theme.font.medium,
  color: theme.colors.textLight,
  paddingLeft: theme.padding.large * 2,
  textDecorationLine: lineThrough ? 'line-through' : 'none',
  textDecorationStyle: 'solid',
});

const itemVoidReasonStyle: StyleFn = ({ theme }) => ({
  letterSpacing: -0.5,
  marginBottom: theme.spacing.small / 2,
  fontFamily: theme.font.medium,
  color: theme.colors.textLight,
  paddingLeft: theme.padding.large * 2,
});

const discountStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.big,
});

const Adjustment: React.FC<{
  value: number;
  lineThrough: boolean;
  type: AdjustmentType;
}> = ({ value, lineThrough, type }) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  return (
    <Product
      type="modifier"
      name={
        type == AdjustmentType.SURCHARGE
          ? translate('order.surcharge')
          : translate('order.discount')
      }
      quantity={0}
      price={value}
      testID="adjustment"
      lineThrough={lineThrough}
      containerStyle={css(discountStyle)}
      disabled
    />
  );
};

const CartItem: React.FC<CartItemProps> = ({
  id,
  productId,
  name,
  quantity,
  price,
  notes,
  variants,
  isSelected,
  onSelectItem,
  discountAmount,
  surchargeAmount,
  modifiers,
  variantId,
  status,
  reason,
  testID,
  disabled = false,
  isRefundOrder,
  selectedSubItem,
  showFiredIcon,
  itemFired,
  seatNumber,
}: CartItemProps) => {
  const itemVoided = status === OrderItemStatus.VOID;

  const { css } = useFela({ lineThrough: itemVoided });
  const { translate } = useTranslation();

  const onSelectVariantOption = useCallback(
    (variant: string): void => {
      onSelectItem &&
        onSelectItem({
          item: id,
          variant: variantId,
          selectedVariantKey: variant,
          product: productId,
        });
    },
    [id, onSelectItem, variantId, productId],
  );

  const onPressProduct = useCallback(() => {
    onSelectItem &&
      onSelectItem({
        item: id,
        product: productId,
        variant: variantId,
        quantity,
      });
  }, [id, onSelectItem, productId, variantId, quantity]);

  const onSelectModifier = useCallback(
    (modifier: string, modifierGroup: string, tempModifierId: string) => {
      onSelectItem &&
        onSelectItem({
          item: id,
          modifier,
          product: productId,
          modifierGroup,
          selectedModifierKey: tempModifierId,
        });
    },
    [id, onSelectItem, productId],
  );

  return (
    <View style={css(cartItemStyle)} testID={testID}>
      {
        <Product
          key={productId}
          type="product"
          name={name}
          price={price}
          quantity={quantity}
          onPress={onPressProduct}
          selected={selectedSubItem ? false : isSelected}
          highlighted={selectedSubItem && isSelected ? true : false}
          disabled={disabled || itemVoided}
          lineThrough={itemVoided}
          testID="cart-item-product"
          isRefundOrder={isRefundOrder}
          showFiredIcon={showFiredIcon}
          itemFired={itemFired}
          seatNumber={seatNumber}
        />
      }
      {variants !== undefined &&
        variants.map((item: OptionValue) => (
          <Variant
            key={item.key}
            name={item.value}
            selected={item.key === selectedSubItem && isSelected}
            onPress={onSelectVariantOption.bind(null, item.key)}
            highlighted={item.key !== selectedSubItem && isSelected}
            disabled={disabled || itemVoided}
            lineThrough={itemVoided}
          />
        ))}

      {modifiers &&
        modifiers.map((item: Partial<OrderItemModifier>) => {
          return Array(item.quantity || 1)
            .fill(1)
            .map((_, index) => {
              //we need this different id, as all line items for modifiers have same id, need a unique key
              const tempModifierId = `${id}-${item.id}-${index}`;
              return (
                <Product
                  type="modifier"
                  name={item?.name || ''}
                  price={(item.unitPrice || 0) * (quantity || 0)}
                  key={tempModifierId}
                  onPress={onSelectModifier.bind(
                    null,
                    item.id || '',
                    item?.modifierGroupId || '',
                    tempModifierId,
                  )}
                  selected={tempModifierId === selectedSubItem}
                  highlighted={tempModifierId !== selectedSubItem && isSelected}
                  lineThrough={itemVoided}
                  disabled={disabled || itemVoided}
                  quantity={quantity}
                  testID="cart-item-product-modifier"
                />
              );
            });
        })}
      {discountAmount ? (
        <Adjustment
          value={discountAmount}
          lineThrough={itemVoided}
          type={AdjustmentType.DISCOUNT}
        />
      ) : null}
      {surchargeAmount ? (
        <Adjustment
          value={surchargeAmount}
          lineThrough={itemVoided}
          type={AdjustmentType.SURCHARGE}
        />
      ) : null}
      {notes ? (
        <Text testID="item-notes" style={css(itemNoteStyle)}>
          {translate('order.itemNote', { value: notes })}
        </Text>
      ) : null}
      {itemVoided && reason ? (
        <Text testID="item-reason" style={css(itemVoidReasonStyle)}>
          {translate('order.itemVoidReason', {
            value: translate(`enums.${reason as VoidReason}`),
          })}
        </Text>
      ) : null}
    </View>
  );
};

export default CartItem;
