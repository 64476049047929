import {
  Address,
  BusinessSize,
  BusinessStatus,
  BusinessType,
  SignupInput,
  StyleFn,
} from '@hitz-group/domain';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { KeyboardAvoidingView, ScrollView, Text, View } from 'react-native';
import SelectBar from '../../../components/Button/SelectBar';
import Button from '../../../components/Button/Button';
import DropDown from '../../../components/FormInput/DropDown';
import FormInput from '../../../components/FormInput/FormInput';
import Layout from '../../../components/POSLayout/POSLayout';
import Logo from '../../../components/Logo/Logo';
import { countries } from 'countries-list';
import {
  getCountry,
  Timezone,
  TIMEZONES_MAP,
  useTranslation,
  usePhoneNumber,
} from '@hitz-group/localization';
import {
  isStrongPassword,
  isValidEmail,
  isValidName,
  isValidBusinessName,
  isValidNumber,
} from '../../../utils/validator';
import { useNotification } from '../../../hooks/Notification';
import { useNavigation } from '@react-navigation/native';
import { useFela } from 'react-fela';
import { useSignUp } from '../../../hooks/app/useSignUp';
import PlacesInput from '../../../components/PlacesInput';
import scale, { isWeb, isAndroid } from '../../../common/theme';
import Gradient from '../../../components/Gradient/Gradient';
import { navigateToBackOfficeParams } from '../../../state/navigation';
import { AuthState, tokenUtility } from '../../../state/tokenUtility';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

const containerStyle: StyleFn = ({ theme }) => ({
  marginTop: 'auto',
  marginBottom: 'auto',
  height: isAndroid ? 650 : 600,
  width: isWeb ? 660 : 750,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.large,
  ...theme.shadow30,
});

const rowStyle: StyleFn = () => ({
  width: scale.moderateScale(370),
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const placesRowStyle: StyleFn = () => ({
  zIndex: 10,
});
const tooltipRowStyle: StyleFn = () => ({
  width: scale.moderateScale(370),
  height: 70,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const rowStyleButton: StyleFn = () => ({
  width: scale.moderateScale(370),
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: isWeb ? 40 : isAndroid ? 50 : 40,
  alignItems: 'center',
  alignSelf: 'center',
});

const signUpStepTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  textAlign: 'center',
  textTransform: 'uppercase',
});
const filterStyle: StyleFn = () => ({
  margin: scale.moderateScale(2),
});

const subTitleStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  ...theme.font14Medium,
  color: theme.colors.grey2,
  paddingHorizontal: scale.moderateScale(50),
  paddingVertical: scale.moderateScale(15),
});

const buttonContainerStyle: StyleFn = () => ({
  height: scale.moderateScale(25),
  width: scale.moderateScale(225),
  alignSelf: 'center',
});

const phonePrefixStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  backgroundColor: theme.colors.white,
  minWidth: scale.moderateScale(15),
  textAlign: 'left',
  paddingLeft: scale.moderateScale(10),
  fontFamily: theme.font.regular,
  fontSize: scale.moderateScale(8),
});

const phonePrefixErrorBgStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger2,
});

export const formInputContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
});

const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  width: '55%',
  height: theme.input.height,
});
export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '55%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});
const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: '90%',
  paddingLeft: scale.moderateScale(10),
  marginTop: scale.moderateScale(2),
});
const screen: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});
const logo: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.medium,
  alignSelf: 'center',
  height: 60,
  width: 150,
});
const buttonStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  height: theme.input.height,
});
const backButtonStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  width: scale.moderateScale(70),
  height: theme.input.height,
});

const emptyViewStyle: StyleFn = () => ({
  height: 70,
  width: '49%',
  marginLeft: '1%',
  marginTop: 3,
  padding: 5,
});
const passwordCriteriaContainer: StyleFn = ({ theme }) => ({
  height: 70,
  width: '49%',
  marginLeft: '1%',
  backgroundColor: theme.colors.highlighted,
  borderRadius: theme.radius.small,
  marginTop: 3,
  padding: 5,
  justifyContent: 'center',
});
const passwordCriteriaTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Regular,
  fontSize: theme.fontSize.smallest,
  color: theme.colors.blue,
  textAlign: 'center',
});

const DEFAULT_COUNTRY_CODE = 'AU';
const TIMEZONES: Array<Timezone> = Object.values(TIMEZONES_MAP);

const SignUp: FC = () => {
  const [step, setStep] = useState<number>(1);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [formData, setFormData] = useState<SignupInput>({
    name: '',
    businessName: '',
    email: '',
    password: '',
    country: DEFAULT_COUNTRY_CODE,
    phone: '',
    businessType: BusinessType.CAFETERIA,
    size: BusinessSize.SMALL,
    status: BusinessStatus.NEW_BUSINESS,
  });
  const [isSignupDisabled, setIsSignupDisabled] = useState<boolean>(false);
  const { loading, createOrganization } = useSignUp();

  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const { theme, css } = useFela();
  const { translate } = useTranslation();
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  useEffect(() => {
    // if user is already logged in and try to visit the sign up route, then reset back to backoffice overview.
    const subscription: Subscription = tokenUtility.getTokenInfo$
      .pipe(
        take(1),
        filter(auth => auth.authState === AuthState.LOGGED_IN),
      )
      .subscribe(() => {
        navigation.reset(navigateToBackOfficeParams);
      });

    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [navigation]);

  const _onChangeProp = (prop: string, text: string): void => {
    setFormData({ ...formData, [prop]: text });
  };

  const _onSignIn = (): void => {
    navigation.goBack();
  };

  const addressProvided = Object.keys(formData.address || {}).length > 0;

  const isValid = (): boolean => {
    const { name, businessName, email, password, phone, country } = formData;

    const passes =
      isValidEmail(email) &&
      isValidName(name) &&
      isValidBusinessName(businessName) &&
      isValidNumber(getFullFormattedPhoneNumber(country as string, phone)) &&
      isStrongPassword(password) &&
      addressProvided;
    setIsSignupDisabled(!passes);
    return passes;
  };

  const _onSignUp = async (): Promise<void> => {
    if (isValid()) {
      // TODO: add csurf for this unguarded api
      const result = await createOrganization({
        ...formData,
        phone: getFullFormattedPhoneNumber(
          formData.country as string,
          formData.phone,
        ),
      });

      if (result.error) {
        showNotification({ error: true, message: result.error });
      } else if (result.success) {
        navigation.navigate('Login');
        showNotification({
          success: true,
          message: translate('signup.signupSuccessMsg'),
        });
      }
    }
  };

  const _onSelectCountry = (country: string): void => {
    setFormData({
      ...formData,
      country,
      phone: '',
    });
  };

  const _onNext = (): void => {
    // make sure the form in step 1 was filled with valid data
    if (isValid()) {
      setStep(step + 1);
    }
  };

  const _onFocus = (): void => {
    setShowTooltip(true);
  };

  const _onBlur = (): void => {
    setShowTooltip(false);
  };

  const _onPrev = (): void => {
    setStep(step - 1);
  };
  const onSelectAddress = useCallback((address: Address) => {
    const _countryCode = address?.isoCountryCode || DEFAULT_COUNTRY_CODE;
    const defaultTimezone = TIMEZONES.find((_tz: Timezone) => {
      return _tz.country === _countryCode;
    });
    const selectedCountry = getCountry(_countryCode);

    setFormData(_formData => ({
      ..._formData,
      address,
      country: _countryCode,
      timezone: defaultTimezone?.name,
      currencyCode: selectedCountry?.currency,
    }));
  }, []);

  return (
    <Layout
      title={translate('navigation.signupPageTitle', {
        step,
        appName: translate('appName'),
      })}
      hasHeader={false}
      useSafeArea={false}
    >
      <Gradient
        colors={[theme.colors.brandPrimary, theme.colors.brandSecondary]}
        style={css(screen)}
        start={theme.gradient.startAxis}
        end={theme.gradient.endAxis}
        locations={theme.gradient.location}
      >
        <KeyboardAvoidingView behavior={'padding'}>
          <ScrollView
            contentContainerStyle={css(containerStyle)}
            showsVerticalScrollIndicator={false}
            bounces={false}
          >
            <View>
              <Logo testID="App-Logo" style={css(logo)} />
              {step === 1 ? (
                <>
                  <View style={css(rowStyle)}>
                    <FormInput
                      verified={isValidName(formData.name)}
                      error={!isValidName(formData.name) && isSignupDisabled}
                      title={translate('signup.yourName')}
                      testID="Full Name"
                      alignTitle="left"
                      value={formData.name}
                      onSubmitEditing={_onNext}
                      placeholder={translate('signup.yourName')}
                      onChangeText={text => _onChangeProp('name', text)}
                    />

                    <FormInput
                      type="phone"
                      testID="Phone Number"
                      onSubmitEditing={_onNext}
                      title={translate('signup.phone')}
                      alignTitle="left"
                      verified={isValidNumber(
                        getFullFormattedPhoneNumber(
                          formData.country as string,
                          formData.phone,
                        ),
                      )}
                      error={
                        !isValidNumber(
                          getFullFormattedPhoneNumber(
                            formData.country as string,
                            formData.phone,
                          ),
                        ) && isSignupDisabled
                      }
                      placeholder={translate('signup.phoneHint')}
                      country={formData.country}
                      onSelectChange={(country): void =>
                        _onSelectCountry(country.toString())
                      }
                      onPressCountry={(country): void =>
                        _onSelectCountry(country.toString())
                      }
                      value={formData.phone}
                      onChangeText={text => _onChangeProp('phone', text)}
                      prefix={{
                        text: formData.country
                          ? `+${countries[formData.country].phone} `
                          : '',
                        textStyle:
                          !isValidNumber(
                            getFullFormattedPhoneNumber(
                              formData.country as string,
                              formData.phone,
                            ),
                          ) && isSignupDisabled
                            ? css(phonePrefixStyle, phonePrefixErrorBgStyle)
                            : css(phonePrefixStyle),
                      }}
                      containerStyle={css(formInputContainerStyle)}
                    />
                  </View>
                  <View style={css(rowStyle)}>
                    <FormInput
                      verified={isValidBusinessName(formData.businessName)}
                      error={
                        !isValidBusinessName(formData.businessName) &&
                        isSignupDisabled
                      }
                      alignTitle="left"
                      title={translate('signup.businessName')}
                      onSubmitEditing={_onNext}
                      value={formData.businessName}
                      placeholder={translate('signup.storeNameHint')}
                      testID="Business Name"
                      onChangeText={text => _onChangeProp('businessName', text)}
                    />
                    <DropDown
                      values={Object.values(BusinessType).map(value => ({
                        value: value,
                        label: translate('enums.' + value),
                      }))}
                      selectedValue={
                        formData.businessType || BusinessType.CAFETERIA
                      }
                      title={translate('signup.businessType')}
                      testID="Business Type"
                      onValueChange={text =>
                        _onChangeProp('businessType', text)
                      }
                      extraMainViewStyle={css(dropDownMainViewStyle)}
                      extraStyle={css(dropdownExtraStyle)}
                      extraViewStyle={css(dropdownViewStyle)}
                    />
                  </View>
                  {isAndroid ? (
                    <PlacesInput
                      title={translate('signup.businessAddress')}
                      placeholder={translate('signup.businessAddressHint')}
                      onChangeAddress={onSelectAddress}
                      address={formData.address as Address}
                      error={!addressProvided && isSignupDisabled}
                      valid={addressProvided}
                    />
                  ) : (
                    <View style={css(placesRowStyle)}>
                      <PlacesInput
                        title={translate('signup.businessAddress')}
                        placeholder={translate('signup.businessAddressHint')}
                        onChangeAddress={onSelectAddress}
                        address={formData.address as Address}
                        error={!addressProvided && isSignupDisabled}
                        valid={addressProvided}
                      />
                    </View>
                  )}
                  <View style={css(rowStyle)}>
                    <FormInput
                      verified={isValidEmail(formData.email)}
                      onSubmitEditing={_onNext}
                      error={!isValidEmail(formData.email) && isSignupDisabled}
                      title={translate('signup.loginEmail')}
                      alignTitle="left"
                      value={formData.email}
                      testID="Email Address"
                      placeholder={translate('signup.emailHint')}
                      onChangeText={text => _onChangeProp('email', text)}
                    />
                    <FormInput
                      type="password"
                      onSubmitEditing={_onNext}
                      title={translate('signup.password')}
                      testID="Password"
                      alignTitle="left"
                      value={formData.password}
                      verified={isStrongPassword(formData.password)}
                      placeholder={translate('signup.passwordHint')}
                      onFocus={_onFocus}
                      onBlur={_onBlur}
                      error={
                        !isStrongPassword(formData.password) && isSignupDisabled
                      }
                      onChangeText={text => _onChangeProp('password', text)}
                    />
                  </View>
                  <View style={css(tooltipRowStyle)}>
                    <View style={css(emptyViewStyle)} />
                    {showTooltip && (
                      <View style={css(passwordCriteriaContainer)}>
                        <Text style={css(passwordCriteriaTextStyle)}>
                          {translate('signup.passwordCriteria')}
                        </Text>
                      </View>
                    )}
                  </View>

                  <View style={css(rowStyleButton)}>
                    <Button
                      testID="signin"
                      size="medium"
                      pill
                      secondary
                      onPress={_onSignIn}
                      title={translate('button.login')}
                      containerStyle={css(buttonStyle)}
                    />
                    <Text style={css(signUpStepTextStyle)}>
                      {String(translate('signup.signupStep1')).toUpperCase()}
                    </Text>
                    <Button
                      testID="next"
                      size="medium"
                      pill
                      success
                      onPress={_onNext}
                      title={translate('button.next')}
                      containerStyle={css(buttonStyle)}
                    />
                  </View>
                </>
              ) : (
                <>
                  <Text numberOfLines={2} style={css(subTitleStyle)}>
                    {translate('signup.signupStep2Description')}
                  </Text>
                  <View style={css(filterStyle)} />
                  <View style={css(filterStyle)} />
                  <Text style={css(subTitleStyle)}>
                    {translate('signup.storeTypeQuestion', {
                      businessName: formData.businessName,
                    })}
                  </Text>
                  <View style={css(buttonContainerStyle)}>
                    <SelectBar
                      options={[
                        {
                          label: translate(
                            'enums.' + BusinessStatus.EXISTING_BUSINESS,
                          ),
                          value: BusinessStatus.EXISTING_BUSINESS,
                        },
                        {
                          label: translate(
                            'enums.' + BusinessStatus.NEW_BUSINESS,
                          ),
                          value: BusinessStatus.NEW_BUSINESS,
                        },
                      ]}
                      selectedOption={
                        formData.status || BusinessStatus.NEW_BUSINESS
                      }
                      onPress={text => _onChangeProp('status', text)}
                    />
                  </View>
                  <View style={css(filterStyle)} />
                  <View style={css(filterStyle)} />
                  <Text style={css(subTitleStyle)}>
                    {translate('signup.storeSizeQuestion', {
                      businessName: formData.businessName,
                    })}
                  </Text>
                  <View style={css(buttonContainerStyle)}>
                    <SelectBar
                      options={[
                        {
                          label: BusinessSize.SMALL,
                          value: BusinessSize.SMALL,
                        },
                        {
                          label: BusinessSize.MEDIUM,
                          value: BusinessSize.MEDIUM,
                        },
                        {
                          label: BusinessSize.BIG,
                          value: BusinessSize.BIG,
                        },
                      ]}
                      onPress={text => _onChangeProp('size', text)}
                      selectedOption={formData.size || BusinessSize.SMALL}
                    />
                  </View>
                  <View style={css(filterStyle)} />
                  <View style={css(filterStyle)} />
                  <View style={css(filterStyle)} />
                  <View style={css(rowStyleButton)}>
                    <Button
                      testID="back"
                      size="medium"
                      secondary
                      onPress={_onPrev}
                      title={translate('button.back')}
                      loading={loading}
                      disabled={loading}
                      containerStyle={css(backButtonStyle)}
                    />
                    <Text style={css(signUpStepTextStyle)}>
                      {translate('signup.signupStep2')}
                    </Text>
                    <Button
                      testID="signup"
                      size="medium"
                      success
                      onPress={_onSignUp}
                      title={translate('button.complete')}
                      loading={loading}
                      disabled={loading}
                      containerStyle={css(buttonStyle)}
                    />
                  </View>
                </>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </Gradient>
    </Layout>
  );
};

export default SignUp;
