import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import Button from '../../../Button/Button';
import PopupView from '../../../PopupView/PopupView';

import { StyleFn, Order, Customer } from '@hitz-group/domain';
import { useSession } from '../../../../hooks/app/useSession';
import { useModal } from '@hitz-group/rn-use-modal';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { usePosState } from '../../../../hooks/POSStateProvider';

const containerStyle: StyleFn = ({ theme }) => ({
  width: 600,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  flex: 1,
});

const popupStyle: StyleFn = () => ({
  paddingTop: 8,
});

const textHeader: StyleFn = ({ theme }) => ({
  marginTop: 10,
  lineHeight: 20,
  marginBottom: 20,
  color: theme.colors.warning,
  alignSelf: 'flex-start',
  fontWeight: 600,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  marginTop: 20,
  marginBottom: 20,
  color: theme.colors.white,
  alignSelf: 'flex-end',
});

const labelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
});

const cancelButtonStyle: StyleFn = () => ({
  marginTop: 20,
});

const buttonViewStyle: StyleFn = () => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: 300,
  alignSelf: 'flex-end',
});

export interface EnableOnAccountModalProps {
  order: Order;
  onConfirm?: () => void;
}

const EnableOnAccountModal: React.FC<EnableOnAccountModalProps> = ({
  order,
  onConfirm,
}) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [session] = useSession();
  const { updateCustomerBalance: updateCustomerOnAccount, loading } =
    usePosState('customers');

  const customerName = `${order?.customer?.firstName} ${order?.customer?.lastName}`;
  const customerAccountSettings =
    session?.currentVenue?.customerAccountSettings;

  const activateOnAccount = useCallback(() => {
    const customerAccountDetails = {
      accountPayment: true,
      currentBalance:
        order?.customer?.customerAccountDetails?.currentBalance || 0,
      maxOrderLimit:
        order?.customer?.customerAccountDetails?.maxOrderLimit ||
        customerAccountSettings?.defaultMaxOrderLimit ||
        100,
      maxBalanceLimit:
        order?.customer?.customerAccountDetails?.maxBalanceLimit ||
        customerAccountSettings?.defaultMaxBalanceLimit ||
        1000,
    };
    const customerForm = {
      id: order?.customer?.id,
      customerAccountDetails,
    } as unknown as Customer;
    const customerData = order?.customer;

    if (customerData) {
      updateCustomerOnAccount(customerForm);
      onConfirm && onConfirm();
      closeModal && closeModal();
    }
  }, [
    closeModal,
    customerAccountSettings?.defaultMaxBalanceLimit,
    customerAccountSettings?.defaultMaxOrderLimit,
    onConfirm,
    order?.customer,
    updateCustomerOnAccount,
  ]);
  if (loading) return <LoadingIndicator />;
  return (
    <View style={css(containerStyle)}>
      <PopupView containerStyle={css(popupStyle)}>
        <Text style={css(textHeader)}>
          {translate('order.enableOnAccountModal.title')}
        </Text>
        <Text>
          {translate('order.enableOnAccountModal.headerText', {
            customerName: customerName,
          })}
        </Text>
        <View style={css(buttonViewStyle)}>
          <Button
            title={translate('order.enableOnAccountModal.dismiss')}
            size="small"
            testID="dismiss"
            containerStyle={css(cancelButtonStyle)}
            onPress={closeModal}
          />
          <Button
            title={translate('order.enableOnAccountModal.enableAccountPayment')}
            warning
            size="small"
            labelStyle={css(labelStyle)}
            testID="handle-payment"
            containerStyle={css(buttonStyle)}
            onPress={activateOnAccount}
          />
        </View>
      </PopupView>
    </View>
  );
};

export default EnableOnAccountModal;
