import { useMutation } from '@apollo/client/react/hooks';
import { useCallback, useMemo } from 'react';
import { RESET_PASSWORD_MUTATION } from '../../graphql/session';
import { parseApolloError } from '../../utils/errorHandlers';
import { ResetPasswordStatus } from '@hitz-group/domain';
import { useNotification } from '../Notification';

interface RequestPasswordResetLink {
  email: string;
}

export interface UseResetPassword {
  requestPasswordResetLink: (email: string) => Promise<boolean>;
  loading: boolean;
  error: string | undefined;
}
/**
 *
 * @returns
 */
export const useResetPassword = (): UseResetPassword => {
  const [resetPasswordRequest, { loading, error }] = useMutation<
    { resetPassword: ResetPasswordStatus },
    RequestPasswordResetLink
  >(RESET_PASSWORD_MUTATION);
  const { showNotification } = useNotification();

  const requestPasswordResetLink = useCallback(
    async (email: string): Promise<boolean> => {
      try {
        const result = await resetPasswordRequest({
          variables: {
            email,
          },
        });
        if (result.data?.resetPassword) {
          showNotification({
            success: true,
            message: result.data.resetPassword.message,
          });

          return result.data.resetPassword.success;
        }
        return false;
      } catch (err) {
        showNotification({ error: true, message: 'Something went wrong' });
        return false;
      }
    },
    [resetPasswordRequest, showNotification],
  );

  return useMemo(
    () => ({
      requestPasswordResetLink,
      loading,
      error: error ? parseApolloError(error) : undefined,
    }),
    [requestPasswordResetLink, loading, error],
  );
};
