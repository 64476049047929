import { gql } from '@apollo/client';

export const GET_PRODUCT_TYPES_QUERY = gql`
  query {
    productTypes {
      id
      name
    }
  }
`;

export const CREATE_PRODUCT_TYPES = gql`
  mutation createProductTypes($input: [CreateProductTypeInput!]) {
    createProductTypes(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_PRODUCT_TYPES = gql`
  mutation updateProductTypes($input: [UpdateProductTypeInput!]) {
    updateProductTypes(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_PRODUCT_TYPE = gql`
  mutation deleteProductType($id: ID!) {
    deleteProductType(id: $id)
  }
`;
