import { gql } from '@apollo/client';

export const orderEventFragment = `
  __typename
  ... on InitiateOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderTypeId
    orderNumber
    salesChannelId
    integrationInfo {
      app
      id
    }
    status
  }
  ... on AssignTableEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    guestCount
    tableId
    tableName
    sectionId
    sectionName
  }
  ... on AssignOrderTypeEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderTypeId
    orderTypeName
  }
  ... on AddOrderItemEvent {
    id
    action
    costPrice
    deviceId
    orderId
    organizationId
    previous
    productId
    productName
    quantity
    taxInclusive
    storeId
    taxes {
      id
      name
      code
      rate
    }
    timestamp
    triggeredBy
    unitPrice
    venueId
    fireItem
    optionalSeatNumber: seatNumber
    courseId
    inventoryTracked
    modifiers {
      quantity
      unitPrice
      taxes {
        id
        name
        code
        rate
      }
      id
      name
      modifierGroupId
    }
    variantId
    variantName
  }
  ... on SaveOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on UpdateOrderNotesEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    notes
  }
  ... on UpdateFulfilmentDetail {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    shippingAddress {
      line1
      line2
      postalCode
      state
      isoCountryCode
      city
      country
    }
    requiredAt
  }
  ... on AssignCustomerEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    customerId
    firstName
    lastName
    email
    phone
    loyaltyMember
    isLoyaltyApplied
    customerAccountDetails {
      currentBalance
      accountPayment
      maxBalanceLimit
      maxOrderLimit
    }
  }

  ... on UpdateCustomerEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    loyaltyMember
    isLoyaltyApplied
  }
  ... on UnassignCustomerEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }

  ... on VoidOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    voidReason: reason
    productQuantities {
      id
      quantity
    }
    inventoryTracked
  }
  ... on RemoveOrderItemEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    optionalVoidReason: reason
    quantity
    inventoryTracked
    productId
  }
  ... on UpdateOrderItemPriceEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    adjustments {
      id
      name
      amount
      adjustmentUnit
      allowOnPaymentType
      adjustmentType
    }
    unitPrice
  }
  ... on UpdateOrderItemQuantityEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    quantity
    optionalVoidReason: reason
    inventoryTracked
    productId
  }
  ... on AddOrderDiscountEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    discount {
      amount
      type
    }
  }
  ... on AddModifierEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    unitPrice
    quantity
    taxes {
      id
      name
      code
      rate
    }
    name
    modifierId
    modifierGroupId
    orderItemId
  }
  ... on UpdateModifierQuantityEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    modifierId
    modifierGroupId
    quantity
  }
  ... on RemoveModifierEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    optionalOrderItemId: orderItemId
    modifierId
    modifierGroupId
  }
  ... on AddOrderItemNotesEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    note
  }
  ... on OrderPaymentEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    paymentTypeId
    tendered
    change
    tip
    roundOffDifference
    refundOrder
    onAccount
    optionalCustomerId: customerId
    paymentTypeName
    optionalPaymentRequestId: paymentRequestId
    optionalPaymentStatus: paymentStatus
  }
  ... on OrderPaymentProcessedEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    paymentStatus
    paymentRequestId
    paymentTransactionRef
    paymentCompletedAt
    paymentReceipt
    paymentSurcharge
  }
  ... on InitiateRefundEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    refundOf
    refundOrderNumber
    reason
  }
  ... on OrdersRefetchEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on RefundOrderItemsEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItems {
      id
      quantity
    }
  }
  ... on AssignRefundReasonEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    reason
  }
  ... on HoldOrderItemEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    optionalReason: reason
  }
  ... on FireOrderItemsEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemsToFire {
      id
      status
    }
  }
  ... on PrintKitchenDocketEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }

  ... on UpdateSeatNumberEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    seatNumber
    orderItemIds
  }
  ... on SwitchCourseItemEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderItemId
    courseId
  }

  ... on InitiateOnlineOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    source
    order
  }

  ... on AcceptOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on CompleteOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on RejectOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    rejectionReason: reason
  }
  ... on PartnerCancelOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
  }
  ... on AddOrderAdjustmentEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    adjustment {
      id
      name
      amount
      adjustmentUnit
      allowOnPaymentType
      adjustmentType
    }
  }
  ... on UpdateOrderLastCheckEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    lastCheck
  }
  ... on PrintOrderEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    print
    printerId
    printType
    purpose
  }
  ... on OrderPlacedEvent {
    id
    action
    triggeredBy
    venueId
    storeId
    deviceId
    organizationId
    orderId
    timestamp
    previous
    orderNumber
  }
  ... on OrderInitiateMergeEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
    source
    orderIdsToMerge
    itemMergeOptions
  }
  ... on OrderMergedEvent {
    id
    action
    triggeredBy
    timestamp
    previous
    venueId
    storeId
    deviceId
    organizationId
    orderId
    mergedInto
  }
`;

export const WorklogEvents = gql`
  subscription WorklogEvents(
    $storeId: String!
    $deviceId: String!
    $lastActiveTime: Float
  ) {
    worklogEvents(
      storeId: $storeId
      deviceId: $deviceId
      lastActiveTime: $lastActiveTime
    ) {
      action
      timestamp
      venueId
      storeId
      deviceId
      userId
      organizationId
    }
  }
`;

export const DeviceEvents = gql`
  subscription DeviceEvents($storeId: String!, $deviceId: String!) {
    deviceEvents(storeId: $storeId, deviceId: $deviceId) {
      deviceId
      storeId
      organizationId
      action
    }
  }
`;

export const OrderEvents = gql`
  subscription OrderEvents(
    $storeId: String!
    $deviceId: String!
    $lastActiveTime: Float
  ) {
    orderEvents(
      storeId: $storeId
      deviceId: $deviceId
      lastActiveTime: $lastActiveTime
    ) {
      ${orderEventFragment}
    }
  }
`;

export const UpdateProductsAvailabilityEvent = gql`
  subscription UpdateProductsAvailabilityEvent(
    $storeId: String!
    $deviceId: String!
  ) {
    updateProductsAvailabilityEvent(storeId: $storeId, deviceId: $deviceId) {
      deviceId
      storeId
      products {
        id
        name
        inventory {
          availableQuantity
        }
        isBeingTracked
      }
    }
  }
`;

export const UpdateCustomerInfoEvent = gql`
  subscription UpdateCustomerInfoEvent {
    updateCustomerEvent {
      id
      firstName
      lastName
      name
      phone
      email
      loyaltyMember
      loyaltyPoints
    }
  }
`;
