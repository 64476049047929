import { StyleFn } from '@hitz-group/domain';
import { ModifierGroup as ModifierGroupDefault } from '@hitz-group/domain';
import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import TableRow from '../../../../../components/TableComponent/TableRow';
import IconButton from '../../../../../components/Button/IconButton';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../../components/FormInput/FormInput';
import Button from '../../../../../components/Button/Button';
import { useNavigation } from '@react-navigation/native';
import scale, { isWeb } from '../../../../../common/theme';
export interface ModifierGroup extends ModifierGroupDefault {
  isSelected?: boolean;
}

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 300,
  height: theme.input.height,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
  marginLeft: 'auto',
  alignSelf: 'center',
  marginRight: -theme.spacing.small,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const productCountStyle: StyleFn = ({ theme }) => ({
  width: 65,
  height: theme.input.height,
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 38,
  height: 38,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing.big / 2,
});

const rightAngleStyle: StyleFn = () => ({
  height: 38,
});
const iconMarginStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small / 2,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  height: 17,
  width: 17,
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  height: 17,
  width: 17,
});
const rowContainerStyle: StyleFn = ({ theme }) => ({
  height: isWeb ? 'auto' : scale.moderateScale(35),
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  borderBottomColor: theme.colors.boxBorder,
});
const productCountText: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});
interface ModifierGroupRowProps {
  modifierGroup: ModifierGroup;
  onChange: (id: string, prop: string, value: string | boolean) => void;
  onPressCopy: (id: string) => void;
}

export const ModifierGroupRow: React.FC<ModifierGroupRowProps> = ({
  modifierGroup,
  onChange,
  onPressCopy,
}: ModifierGroupRowProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const navigation = useNavigation();

  const productCount = useMemo(() => {
    return (
      modifierGroup.modifiers?.length || modifierGroup.products?.length || 0
    );
  }, [modifierGroup]);

  return (
    <TableRow
      containerStyle={css(rowContainerStyle)}
      normalRows
      columnSpacing={theme.spacing.big}
      actionStyle={css(iconMarginStyle)}
      action={
        <IconButton
          icon="AngleRight"
          iconSize={24}
          containerSize={34}
          containerStyle={css(rightAngleStyle)}
          iconColor={theme.colors.paragraph}
          onPress={(): void =>
            // navigate to edit modifier group page
            navigation.navigate('CreateModifierGroupTab', {
              modifierGroupId: modifierGroup.id,
              title: modifierGroup.name,
            })
          }
        />
      }
    >
      <Button
        onPress={() =>
          onChange(modifierGroup.id, 'isSelected', !modifierGroup?.isSelected)
        }
        fluid
        iconPosition={'left'}
        containerStyle={css(checkBoxTitleContainer)}
        iconContainerStyle={
          modifierGroup?.isSelected
            ? css(checkIconContainer)
            : css(unCheckContainer)
        }
        icon={modifierGroup?.isSelected ? 'check' : 'null'}
        iconProps={{
          color: theme.colors.success,
          size: 15,
        }}
      />

      <FormInput
        testID="tax-name"
        placeholder={translate('modifiers.groupName')}
        value={modifierGroup.name}
        containerStyle={css(formInputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(null, modifierGroup.id, 'name')}
        maxLength={50}
      />

      <View style={css(productCountStyle)}>
        <Text style={css(productCountText)}>{productCount}</Text>
      </View>

      <IconButton
        icon="copy"
        iconSize={24}
        containerSize={34}
        containerStyle={css(closeIconContainerStyle)}
        iconStyle={css(closeIconStyle)}
        onPress={() => onPressCopy(modifierGroup.id)}
      />
    </TableRow>
  );
};
