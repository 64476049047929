import { ViewStyle, StyleProp, Text, ScrollView, View } from 'react-native';
import React, { useEffect, useCallback } from 'react';
import DataGridLayout from './DataGridLayout';
import DataTableHeader from './DataTableHeader';
import DataTableHeaderCell from './DataTableHeaderCell';
import DataTableRow from './DataTableRow';
import DataTableBodyCell from './DataTableBodyCell';
import TablePagination from '../TablePagination/TablePagination';
import { StyleFn } from '@hitz-group/domain';
import { useFela, withTheme } from 'react-fela';

export enum columnStyleOptions {
  SMALL = 'small',
  OPTION = 'option',
  REGULAR = 'regular',
  CLICKABLE = 'click',
}

export enum alignment {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export type columnForDataGrid = {
  columnName: string | React.ReactNode;
  columnDataKey: string;
  columnStyle?: columnStyleOptions;
  cellAlignmentStyle?: alignment;
};

export interface DataGridProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Partial<any>[];
  columns?: columnForDataGrid[];
  style?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<ViewStyle>;
  dataRowStyle?: StyleProp<ViewStyle>;
  scrollStyle?: StyleProp<ViewStyle>;
  paginationStyle?: StyleProp<ViewStyle>;
  rowCellTextAlignment?: StyleProp<ViewStyle>;
  headerCellTextAlignment?: StyleProp<ViewStyle>;
  pageSize?: number;
  setToStartPageIfDataChanges?: boolean;
  renderHeaderItem?: (item: string | React.ReactNode) => React.ReactNode;
  renderDataItem?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item: any,
    columnName: string,
    columnStyle?: string,
  ) => React.ReactNode;
  renderOptionItems?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item: any | string,
    columnName: string,
  ) => React.ReactNode;
  onPageChangeCallback?: (page: number) => void;
  isGreyPagination?: boolean;
  paginationContainerStyle?: ViewStyle;
}
const scrollViewStyle: StyleFn = () => ({
  height: '100%',
});

const DataGrid: React.FC<DataGridProps> = ({
  data,
  style,
  headerStyle,
  scrollStyle,
  dataRowStyle,
  paginationStyle,
  columns,
  pageSize,
  headerCellTextAlignment,
  rowCellTextAlignment,
  isGreyPagination,
  paginationContainerStyle,
  setToStartPageIfDataChanges,
  renderHeaderItem,
  renderDataItem,
  renderOptionItems,
  onPageChangeCallback,
}: DataGridProps) => {
  const { css } = useFela();
  const itemsPerPage = pageSize || (data?.length < 10 ? data.length : 10);
  const itemsDataInPageInital =
    data && data.slice((1 - 1) * itemsPerPage, 1 * itemsPerPage);
  const [itemsDataInPage, setPageData] = React.useState(itemsDataInPageInital);
  const [page, setPage] = React.useState(1);
  useEffect(() => {
    onPageChangeCallback && onPageChangeCallback(page);
    const itemsDataInPageIncremental = data?.slice(
      (page - 1) * itemsPerPage,
      page * itemsPerPage,
    );
    setPageData(itemsDataInPageIncremental);
  }, [page, onPageChangeCallback, data, itemsPerPage]);

  const onPageNumberPress = useCallback(page => {
    setPage(page);
  }, []);

  const header: string[] =
    itemsDataInPage && itemsDataInPage.length > 0
      ? Object.keys(itemsDataInPage[0])
      : [];
  let renderColumnsHeader: columnForDataGrid[] = [];
  if (!columns) {
    header.map(headerRow => {
      const tempobj = {
        columnName: headerRow,
        columnDataKey: headerRow,
      };
      renderColumnsHeader.push(tempobj);
    });
  } else {
    renderColumnsHeader = columns;
  }
  //If data changes set page
  useEffect(() => {
    if (data && setToStartPageIfDataChanges) {
      setPage(1);
    }
  }, [data, setToStartPageIfDataChanges]);

  return (
    <DataGridLayout style={style}>
      <DataTableHeader style={headerStyle}>
        {renderColumnsHeader &&
          renderColumnsHeader.map((renderRow, index) => {
            if (
              renderRow.columnStyle !== columnStyleOptions.OPTION &&
              renderRow.columnStyle !== columnStyleOptions.CLICKABLE
            ) {
              return (
                <DataTableHeaderCell
                  textAlignmentStyle={headerCellTextAlignment}
                  small={
                    renderRow.columnStyle === columnStyleOptions.SMALL
                      ? true
                      : false
                  }
                  key={index}
                >
                  {renderHeaderItem ? (
                    renderHeaderItem(renderRow.columnName)
                  ) : (
                    <Text>{renderRow.columnName}</Text>
                  )}
                </DataTableHeaderCell>
              );
            } else {
              return (
                <DataTableHeaderCell
                  textAlignmentStyle={headerCellTextAlignment}
                  option={renderRow.columnStyle === columnStyleOptions.OPTION}
                  clickable={
                    renderRow.columnStyle === columnStyleOptions.CLICKABLE
                  }
                  center={renderRow.cellAlignmentStyle === alignment.CENTER}
                  key={index}
                >
                  {renderHeaderItem ? (
                    renderHeaderItem(renderRow.columnName)
                  ) : (
                    <Text>{renderRow.columnName}</Text>
                  )}
                </DataTableHeaderCell>
              );
            }
          })}
      </DataTableHeader>
      <ScrollView style={[css(scrollViewStyle), scrollStyle]}>
        {itemsDataInPage &&
          itemsDataInPage.map((dataRow, index2) => {
            return (
              <DataTableRow
                style={dataRowStyle}
                key={dataRow?.id ? dataRow?.id : index2}
                rowIndex={index2}
              >
                {renderColumnsHeader &&
                  renderColumnsHeader.map((dataKeys, index3) => {
                    if (
                      dataKeys.columnStyle != columnStyleOptions.OPTION &&
                      dataKeys.columnStyle != columnStyleOptions.CLICKABLE
                    ) {
                      return (
                        <DataTableBodyCell
                          style={rowCellTextAlignment}
                          small={
                            dataKeys.columnStyle === columnStyleOptions.SMALL
                              ? true
                              : false
                          }
                          key={index3}
                        >
                          {renderDataItem ? (
                            renderDataItem(
                              dataRow,
                              dataKeys.columnDataKey,
                              dataKeys.columnStyle,
                            )
                          ) : (
                            <Text>{dataRow[dataKeys.columnDataKey]}</Text>
                          )}
                        </DataTableBodyCell>
                      );
                    } else {
                      return (
                        <DataTableBodyCell
                          style={rowCellTextAlignment}
                          option={
                            dataKeys.columnStyle === columnStyleOptions.OPTION
                          }
                          clickable={
                            dataKeys.columnStyle ===
                            columnStyleOptions.CLICKABLE
                          }
                          key={index3}
                          center={
                            dataKeys.cellAlignmentStyle === alignment.CENTER
                          }
                        >
                          {renderOptionItems ? (
                            renderOptionItems(dataRow, dataKeys.columnDataKey)
                          ) : (
                            <Text>{dataRow[dataKeys.columnDataKey]}</Text>
                          )}
                        </DataTableBodyCell>
                      );
                    }
                  })}
              </DataTableRow>
            );
          })}
      </ScrollView>
      {data?.length > itemsPerPage && (
        <View style={paginationContainerStyle}>
          <TablePagination
            style={paginationStyle}
            page={page}
            isGreyPagination={isGreyPagination}
            numberOfPages={Math.ceil(data?.length / itemsPerPage)}
            startIndex={(page - 1) * itemsPerPage}
            endIndex={page * itemsPerPage}
            total={data?.length}
            onPageChange={onPageNumberPress}
          />
        </View>
      )}
    </DataGridLayout>
  );
};

export default withTheme(DataGrid);
