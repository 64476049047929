import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useFela } from 'react-fela';
import {
  MoneyEventType,
  Money,
  StyleFn,
  MoneyMovement,
} from '@hitz-group/domain';
import Label from '../Label/Label';
import Title from '../Title/Title';
import format from 'date-fns/format';
import { useCurrency } from '@hitz-group/localization';
import IconButton from '../Button/IconButton';
import AddNoteModal from '../Modals/AddNote/AddNoteModal';
import { useModal } from '@hitz-group/rn-use-modal';
import { usePrinting } from '../../hooks/PrintingProvider';
export interface MoneyMovementRowProps {
  moneyMovement: MoneyMovement;
}

const eventContainer: StyleFn = ({ theme }) => ({
  width: 56,
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginRight: theme.spacing.small * 2.5,
});

const dateTimeContainer: StyleFn = ({ theme }) => ({
  width: 150,
  alignItems: 'flex-end',
  marginRight: theme.spacing.small * 2.5,
});

const customerContainer: StyleFn = ({ theme }) => ({
  flex: 1,
  alignItems: 'flex-start',
  marginRight: theme.spacing.small * 2.5,
});

const reasonContainer: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  width: 100,
  marginRight: theme.spacing.small * 2.5,
});

const amountContainer: StyleFn = ({ theme }) => ({
  width: 130,
  marginRight: theme.spacing.small * 2.5,
});

const methodContainer: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  width: 105,
  marginRight: theme.spacing.small * 2.5,
});

const notesActionContainer: StyleFn = ({ theme }) => ({
  width: 38,
  marginRight: theme.spacing.small * 1.5,
});

const printActionContainer: StyleFn = () => ({
  width: 38,
});

const textStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.regular,
  textTransform: 'capitalize',
});
const amountTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.regular,
  textTransform: 'capitalize',
  textAlign: 'right',
  width: 130,
});

const listContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.padding.large,
  width: '100%',
  height: 59,
  marginBottom: theme.spacing.small / 2,
  borderRadius: theme.radius.small,
});
const labelText: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
});

const MoneyMovementRow: React.FC<MoneyMovementRowProps> = ({
  moneyMovement,
}: MoneyMovementRowProps) => {
  const { css, theme } = useFela();
  const { formatCurrency } = useCurrency();
  const { printMoneyMovementReceipt } = usePrinting();
  const { showModal } = useModal();

  const onPressNote = useCallback(
    (notes: string) => showModal(<AddNoteModal readonly value={notes} />),
    [showModal],
  );

  const printMoneyMovement = useCallback(() => {
    printMoneyMovementReceipt(moneyMovement);
  }, [moneyMovement, printMoneyMovementReceipt]);

  return (
    <View style={css(listContainerStyle)} testID="tableRow">
      <View style={css(eventContainer)}>
        {moneyMovement.eventType === MoneyEventType.MONEY_IN ? (
          <Label success value={'IN'} textStyle={css(labelText)} />
        ) : (
          <Label danger value={'OUT'} textStyle={css(labelText)} />
        )}
      </View>
      <Title
        testID={'timestamp'}
        containerStyle={css(dateTimeContainer)}
        labelStyle={css(textStyle)}
        numberOfLines={1}
      >
        {format(+moneyMovement.timestamp, 'dd.MM.yy hh:mm a')}
      </Title>
      <Title
        testID={'username'}
        containerStyle={css(customerContainer)}
        labelStyle={css(textStyle)}
        numberOfLines={1}
      >
        {moneyMovement.user.name}
      </Title>
      <Title
        testID={'moneyMovement-reason'}
        containerStyle={css(reasonContainer)}
        labelStyle={css(textStyle)}
        numberOfLines={1}
      >
        {moneyMovement.reason.name}
      </Title>
      <Title
        testID={'amount'}
        containerStyle={css(amountContainer)}
        labelStyle={css(amountTextStyle)}
        numberOfLines={1}
      >
        {formatCurrency(+(moneyMovement.amount as Money).amount || 0)}
      </Title>
      <Title
        testID={'payment-type'}
        containerStyle={css(methodContainer)}
        labelStyle={css(textStyle)}
        numberOfLines={1}
      >
        {moneyMovement.paymentType.name}
      </Title>
      <View style={css(notesActionContainer)}>
        <IconButton
          testID={'note-icon'}
          key="0"
          circular
          icon="question-circle"
          color={'successLight'}
          iconColor={theme.colors.success}
          containerSize={38}
          iconSize={20}
          onPress={onPressNote.bind(null, moneyMovement.notes)}
        />
      </View>
      <View style={css(printActionContainer)}>
        <IconButton
          testID={'print-icon'}
          key="0"
          circular
          icon="print"
          color={'highlighted'}
          iconColor={theme.colors.blue}
          containerSize={38}
          iconSize={20}
          onPress={printMoneyMovement}
        />
      </View>
    </View>
  );
};

export default MoneyMovementRow;
