import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { ManageFeatureScreen } from './FeatureSettings/About/AboutTab';
import { FeaturesOverview } from './OverViewScreen/FeaturesOverview';

const Stack = createStackNavigator();

const FeaturesScreen: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="FeaturesOverview"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={ManageFeatureScreen} name="ManageFeature" />
    <Stack.Screen component={FeaturesOverview} name="FeaturesOverview" />
  </Stack.Navigator>
);

export default FeaturesScreen;
