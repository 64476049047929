import { StyleFn, Icons, Feature } from '@hitz-group/domain';
import React, { useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { Helmet } from 'react-helmet';
import {
  ImageBackground,
  PixelRatio,
  ScrollView,
  Text,
  View,
  Platform,
  TouchableOpacity,
} from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { Icon } from '../../../../../components/Icon/Icon';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import SearchBar from '../../../../../components/SearchBar/SearchBar';
import DropDown from '../../../../../components/FormInput/DropDown';
import IconButton from '../../../../../components/Button/IconButton';
import { filter, last } from 'lodash';
import { useFeatures } from '../../../../../hooks/app/features/useFeatures';
import { useNotification } from '../../../../../hooks/Notification';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
  paddingVertical: theme.padding.large,
});

const containerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  marginTop: theme.spacing.big,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: theme.spacing.medium * 2,
  width: '100%',
});
const sectionContainerStyle: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  marginBottom: theme.spacing.small,
  paddingBottom: theme.padding.small,
  borderRadius: PixelRatio.getPixelSizeForLayoutSize(5),
  borderBottomColor: theme.colors.boxBorder,
});

const boxContainerStyle: StyleFn = () => ({
  flex: 1,
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: theme.input.height,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  marginHorizontal: 6,
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  flex: 1,
  height: theme.input.height,
  color: theme.colors.paragraph,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
});

const BACKGROUND = {
  uri: 'https://till-x-features.s3.ap-south-1.amazonaws.com/Till_Feature_Cover.png',
};

const ctaHeader: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  textAlign: 'center',
  paddingVertical: theme.padding.xl,
  justifyContent: 'center',
  width: '100%',
  borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
});

const ctaTile: StyleFn = ({ theme }) => ({
  borderWidth: 0,
  borderStyle: 'solid',
  marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
  marginBottom: PixelRatio.getPixelSizeForLayoutSize(10),
  backgroundColor: theme.colors.lightBlueTile,
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  alignItems: 'flex-start',
  textAlign: 'left',
  padding: theme.padding.large * 1.5,
  width: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(300),
    default: PixelRatio.getPixelSizeForLayoutSize(187),
  }),
  height: 280,
});

const ctaTileGreen: StyleFn = ({ theme }) => ({
  borderWidth: 0,
  borderStyle: 'solid',
  marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
  marginBottom: PixelRatio.getPixelSizeForLayoutSize(10),
  backgroundColor: theme.colors.greenFeatureTile,
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  alignItems: 'flex-start',
  textAlign: 'left',
  padding: theme.padding.large * 1.5,
  width: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(300),
    default: PixelRatio.getPixelSizeForLayoutSize(187),
  }),
  height: 280,
});

const ctaSubTitleHeader: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  marginTop: theme.spacing.small,
  lineHeight: theme.spacing.medium,
  color: theme.colors.white,
  textAlign: 'center',
});

const ctaTitleHeader: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
  marginTop: theme.spacing.small,
  lineHeight: theme.spacing.medium,
  fontWeight: '600',
  color: theme.colors.white,
  textAlign: 'center',
});

const ctaIcon: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.highlighted,
  width: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(45),
    default: PixelRatio.getPixelSizeForLayoutSize(23),
  }),

  padding: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(10),
    default: PixelRatio.getPixelSizeForLayoutSize(5),
  }),

  borderRadius: PixelRatio.getPixelSizeForLayoutSize(300),
  textAlign: 'center',
});

const ctaIconGreen: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greenActive,
  width: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(45),
    default: PixelRatio.getPixelSizeForLayoutSize(23),
  }),

  padding: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(10),
    default: PixelRatio.getPixelSizeForLayoutSize(5),
  }),
  borderRadius: PixelRatio.getPixelSizeForLayoutSize(300),
  textAlign: 'center',
});

const ctaTitle: StyleFn = ({ theme }) => ({
  fontSize: 14,
  marginTop: theme.spacing.medium,
  lineHeight: theme.spacing.medium,
  color: theme.colors.primary,
  textAlign: 'left',
});

const ctaTitleApp: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  lineHeight: theme.spacing.small * 2,
  color: theme.colors.darkGrey,
  height: 20,
  width: '100%',
  textAlign: 'left',
});

const ctaAction: StyleFn = () => ({
  flex: 1,
  flexDirection: 'row',
  marginTop: PixelRatio.getPixelSizeForLayoutSize(30),
  width: Platform.select<number | string>({
    android: '90%',
    default: '100%',
  }),
});

const active: StyleFn = ({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  color: theme.colors.green,
  top: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(-30),
    default: PixelRatio.getPixelSizeForLayoutSize(-20),
  }),
  width: Platform.select<number | string>({
    android: '90%',
    default: '100%',
  }),
});

const ctaSubTitle: StyleFn = ({ theme }) => ({
  fontSize: 14,
  marginTop: theme.spacing.small,
  lineHeight: theme.spacing.medium,
  color: theme.colors.textLight,
  textAlign: 'left',
});

const searchStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  marginTop: theme.spacing.medium,
  height: 50,
  alignContent: 'flex-start',
  width: Platform.select<number | string>({
    android: '98%',
    default: '99%',
  }),
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  maxWidth: 320,
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  marginRight: PixelRatio.getPixelSizeForLayoutSize(10),
});

const dropdownFlexStyle: StyleFn = () => ({
  flex: 1,
});
const searchFlexStyle: StyleFn = () => ({
  flex: 2.5,
});

const noResult: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.warning,
  marginTop: 30,
});

const noResultIconStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  margin: 'auto',
  color: theme.colors.warning,
});

const noDisabledIconStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  margin: 'auto',
  color: theme.colors.success,
});

const noDisabledTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  marginTop: 10,
  color: theme.colors.success,
});

const noResultTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  marginTop: 10,
  color: theme.colors.warning,
});

const headerButton: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  marginHorizontal: PixelRatio.getPixelSizeForLayoutSize(5),
  marginVertical: PixelRatio.getPixelSizeForLayoutSize(4),
  color: theme.colors.blue,
  backgroundColor: theme.colors.white,
  textTransform: 'uppercase',
});

const headerText: StyleFn = ({ theme }) => ({
  marginTop: PixelRatio.getPixelSizeForLayoutSize(15),
  color: theme.colors.white,
});

export const FeatureHeader: React.FC<{ features: Feature[] }> = ({
  features,
}) => {
  const navigation = useNavigation();

  const navigateToFeatureDetails = (id: string, name: string) => {
    navigation.navigate('FeaturesSettings', {
      featureId: id,
      title: name,
    });
  };

  const disabledFeatures = filter(features, ['enabledByDefault', false]);

  const headerItem = last(disabledFeatures); // its an interim fix to show header
  const { css, theme } = useFela();
  const { translate } = useTranslation();

  return (
    <>
      <ImageBackground
        source={BACKGROUND}
        resizeMode="cover"
        style={css(ctaHeader)}
        key={headerItem?.title}
      >
        {disabledFeatures.length === 0 ? (
          <View style={css(noResult)}>
            <Icon
              name={Icons.Warning}
              color={theme.colors.success}
              style={css(noDisabledIconStyle)}
            />
            <Text style={css(noDisabledTextStyle)}>
              {translate('backOffice.features.allFeaturesAreEnabled')}
            </Text>
          </View>
        ) : (
          <>
            <View style={css(headerButton)}>
              <Text style={css(headerButton)}>NEW</Text>
            </View>
            <Text style={css(ctaTitleHeader)}>{headerItem?.title}</Text>
            <Text style={css(ctaSubTitleHeader)}>
              {headerItem?.description}
            </Text>
            <Text
              onPress={() =>
                navigateToFeatureDetails(
                  headerItem?.id as string,
                  headerItem?.name as string,
                )
              }
              style={css(headerText)}
              testID="learn-more-action"
            >
              {translate('backOffice.features.learnMore')}
              <IconButton
                icon={Icons.ArrowRight}
                iconSize={15}
                containerSize={20}
                testID="learn-more-action-second-button"
                iconColor={theme.colors.white}
                onPress={() =>
                  navigateToFeatureDetails(
                    headerItem?.id as string,
                    headerItem?.name as string,
                  )
                }
              />
            </Text>
          </>
        )}
      </ImageBackground>
    </>
  );
};

export const FeaturesOverview = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { css, theme } = useFela();
  const { showNotification } = useNotification();
  const isFocused = useIsFocused();

  const navigateToFeatureDetails = (id: string, name: string) => {
    navigation.navigate('FeaturesSettings', {
      featureId: id,
      title: name,
    });
  };

  const {
    features,
    getFeatures: getAllFeatures,
    error: featureError,
  } = useFeatures();

  useEffect(() => {
    if (isFocused) {
      getAllFeatures();
    }
  }, [isFocused, getAllFeatures]);
  useEffect(() => {
    if (featureError) {
      showNotification({
        error: true,
        message: featureError,
      });
    }
  }, [featureError, showNotification, translate]);

  const [searchFeature, setSearchFeature] = useState('');
  const [selectedCategory, setFilteredFeature] = useState('');

  const getFeatureBySearchQuery = (
    features: Feature[],
    searchQuery: string,
  ): Feature[] => {
    return features.filter(feature =>
      feature.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  const getFeatureByFilteredCategory = (
    features: Feature[],
    selectedCategory: string,
  ): Feature[] => {
    return features.filter(feature =>
      feature.category.toLowerCase().includes(selectedCategory.toLowerCase()),
    );
  };

  const allFeaturesArray = useMemo(() => {
    let allFeatures = features;
    if (searchFeature) {
      allFeatures = getFeatureBySearchQuery(allFeatures, searchFeature);
    }

    if (selectedCategory) {
      allFeatures = getFeatureByFilteredCategory(allFeatures, selectedCategory);
    }
    return allFeatures || [];
  }, [features, searchFeature, selectedCategory]);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.featuresPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView testID="overview-screen" style={css(pageStyle)}>
        <View style={css(containerStyle)}>
          <View>
            <View style={css(sectionContainerStyle)}>
              <FeatureHeader features={features} />
              <View style={css(searchStyle)}>
                <View style={css(dropdownFlexStyle)}>
                  <DropDown
                    onValueChange={setFilteredFeature}
                    values={[
                      {
                        label: translate('backOffice.features.categories.all'),
                        value: '',
                      },
                      {
                        label: translate('backOffice.features.categories.menu'),
                        value: translate('backOffice.features.categories.menu'),
                      },
                      {
                        label: translate('backOffice.features.categories.POS'),
                        value: translate('backOffice.features.categories.POS'),
                      },
                      {
                        label: translate(
                          'backOffice.features.categories.multiVenue',
                        ),
                        value: translate(
                          'backOffice.features.categories.multiVenue',
                        ),
                      },
                      {
                        label: translate(
                          'backOffice.features.categories.venue',
                        ),
                        value: translate(
                          'backOffice.features.categories.venue',
                        ),
                      },
                    ]}
                    style={css(dropDownStyle)}
                  />
                </View>
                <View style={css(searchFlexStyle)}>
                  <SearchBar
                    placeholder={translate('backOffice.features.searchFeature')}
                    containerStyle={css(searchContainerStyle)}
                    textInputStyle={css(searchTextInputStyle)}
                    iconColor={theme.colors.paragraph}
                    placeholderColor={theme.colors.paragraph}
                    onChange={setSearchFeature}
                  />
                </View>
              </View>
            </View>
            {allFeaturesArray.length > 0 ? (
              <View style={css(sectionContainerStyle, boxContainerStyle)}>
                {allFeaturesArray.map(feature => {
                  return (
                    <TouchableOpacity
                      key={feature.id}
                      onPress={() =>
                        navigateToFeatureDetails(feature?.id, feature?.name)
                      }
                    >
                      <View
                        style={css(
                          feature.enabledByDefault === false && ctaTileGreen
                            ? ctaTile
                            : ctaTileGreen,
                        )}
                        key={feature.id}
                      >
                        <View
                          style={css(
                            feature.enabledByDefault === false && ctaIconGreen
                              ? ctaIcon
                              : ctaIconGreen,
                          )}
                        >
                          <Icon
                            name={feature.icon}
                            color={
                              feature.enabledByDefault === false &&
                              theme.colors.green
                                ? theme.colors.blue
                                : theme.colors.green
                            }
                          />
                        </View>
                        {feature.enabledByDefault === true ? (
                          <View style={css(active)}>
                            <Text
                              onPress={() =>
                                navigateToFeatureDetails(
                                  feature?.id,
                                  feature?.name,
                                )
                              }
                              style={css(ctaTitleApp)}
                              testID="toggle-action-active"
                            ></Text>
                            <IconButton
                              primary
                              icon={Icons.CheckCircle}
                              iconSize={24}
                              containerSize={20}
                              iconColor={theme.colors.green}
                              testID="toggle-action-icon"
                              onPress={() =>
                                navigateToFeatureDetails(
                                  feature?.id,
                                  feature?.name,
                                )
                              }
                            />
                          </View>
                        ) : (
                          <View style={css(active)}></View>
                        )}
                        <Text
                          style={css(ctaTitle)}
                          testID={'toggle-action-title' + feature?.id}
                          onPress={() =>
                            navigateToFeatureDetails(feature?.id, feature?.name)
                          }
                        >
                          {feature?.title}

                          {translate(
                            feature?.enabledByDefault
                              ? 'backOffice.features.enabled'
                              : 'backOffice.features.empty',
                          )}
                        </Text>
                        <Text
                          onPress={() =>
                            navigateToFeatureDetails(feature?.id, feature?.name)
                          }
                          style={css(ctaSubTitle)}
                          testID={'toggle-action-description' + feature?.id}
                        >
                          {feature.description}
                        </Text>
                        <View style={css(ctaAction)}>
                          <Text style={css(ctaTitleApp)}>
                            {feature.category}
                          </Text>
                          <IconButton
                            primary
                            icon={Icons.ArrowRight}
                            iconSize={24}
                            containerSize={20}
                            iconColor={theme.colors.text}
                            onPress={() =>
                              navigateToFeatureDetails(
                                feature?.id,
                                feature?.name,
                              )
                            }
                            testID={'toggle-action-category' + feature?.id}
                          />
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </View>
            ) : (
              <View style={css(noResult)}>
                <Icon
                  name={Icons.Warning}
                  color={theme.colors.warning}
                  style={css(noResultIconStyle)}
                />
                <Text style={css(noResultTextStyle)} testID="text-no-feature">
                  {translate('backOffice.features.noFeaturesFound')}
                </Text>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};
