import { PaymentType } from '@hitz-group/domain';
import React, { useEffect, useCallback } from 'react';
import { useTranslation } from '@hitz-group/localization';
import { useMutation } from '@apollo/client/react/hooks';
import { DELETE_PAYMENT_TYPE } from '../../../graphql/settings';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import ConfirmationDialog from '../../../components/Modals/ConfirmationDialog';

export interface PaymentRowProps {
  paymentType: PaymentType;
  deletePaymentTypeFromState: (index: number, id: string) => void;
  onChange: (index: number, prop: string, value: string | boolean) => void;
  index: number;
}

interface DeleteModalProps {
  index: number;
  id: string;
  name: string;
  onDeletePayment: (index: number, id: string) => void;
}

export const DeletePaymentModal: React.FC<DeleteModalProps> = ({
  id,
  name,
  index,
  onDeletePayment,
}: DeleteModalProps) => {
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [deletePaymentType, deletedPaymentTypes] = useMutation(
    DELETE_PAYMENT_TYPE,
    {
      onError: noopHandler,
    },
  );

  useEffect((): void => {
    if (deletedPaymentTypes.error) {
      showNotification({
        error: true,
        message: parseApolloError(deletedPaymentTypes.error),
      });
    }
  }, [deletedPaymentTypes.error, showNotification]);

  useEffect((): void => {
    if (deletedPaymentTypes.data) {
      onDeletePayment(index, id);
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: name,
        }),
      });
    }
  }, [
    deletedPaymentTypes.data,
    showNotification,
    closeModal,
    name,
    onDeletePayment,
    index,
    id,
    translate,
  ]);

  const onDeletePaymentType = useCallback((): void => {
    deletePaymentType({ variables: { id: id } });
  }, [deletePaymentType, id]);

  return (
    <ConfirmationDialog
      title={translate('dialog.deleteTitle')}
      message={translate('dialog.deleteConfirmation', { label: name })}
      onConfirm={onDeletePaymentType}
    />
  );
};
