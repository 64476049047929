import {
  GestureResponderEvent,
  View,
  FlatList,
  ViewStyle,
  TextStyle,
  TouchableOpacity,
} from 'react-native';
import React, { useState, ReactElement, useCallback } from 'react';
import { StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import Button from '../Button/Button';
import Title from '../Title/Title';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import scale, { isWeb } from '../../common/theme';
import { Icons } from '@hitz-group/domain';

// FIXME: this restricting the types (not doing this right away as it needs some changes in component)
// this should be a generic type, so that it can be used with any sort of data
export interface ActionList {
  label: string;
  action: (event?: GestureResponderEvent) => void;
  icon?: string;
  iconColor?: string;
  textStyle?: TextStyle;
  id?: string;
}

export interface ButtonWithActionSheetProps {
  actionList: ActionList[];
  title?: string;
  scrollEnabled?: boolean;
  maxItemsToShow?: number;
  contentStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  customDropDownStyle?: ViewStyle;
  iconContainerStyle?: ViewStyle;
  btnTextlabelStyle?: View;
  btnContainerStyle?: View;
  iconColor?: string;
  dropDownTitleStyle?: TextStyle;
  arrowVisible?: boolean;
  icon?: Icons;
  PopoverViewPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | 'auto';
}

export const backgroundStyle: StyleFn = ({ theme }) => ({
  height: scale.deviceHeight,
  width: '100%',
  backgroundColor: theme.colors.transparent,
  position: 'absolute',
});

const popoverStyle: StyleFn = ({ theme }) => ({
  width: 310,
  borderRadius: theme.radius.large,
});

const hideToolTipArrowStyle: StyleFn = () => ({
  backgroundColor: 'transparent',
});
const showToolTipArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const viewStyle: StyleFn = () => ({
  flexDirection: 'column',
  minHeight: 38,
  width: 140,
});

const actionTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});

const dropDownInnerContainer: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  margin: isWeb ? 0 : 10,
  ...theme.shadow30,
  paddingVertical: theme.padding.medium * 3,
  borderRadius: theme.radius.large,
});

const flatListContentStyle: StyleFn = ({ theme, height }) => ({
  height: height + theme.spacing.medium,
  backgroundColor: theme.colors.white,
});

const btnCustomContainerStyle: StyleFn = ({ theme }) => ({
  width: 140,
  height: 38,
  backgroundColor: theme.colors.successLight,
});

const optionLabelStyle: StyleFn = ({ theme }) => ({
  textTransform: undefined,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
});

const dropDownTitleContainerStyle: StyleFn = () => ({
  width: '95%',
  alignItems: 'center',
});

const dropDownTitleLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primaryLightest,
  alignItems: 'flex-start',
  marginLeft: 15,
  marginBottom: 10,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.medium,
  lineHeight: 24,
  letterSpacing: 0,
});

const labelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
  paddingHorizontal: theme.padding.large / 2,
  paddingLeft: theme.padding.large,
});

const iconCustomContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successLight,
  height: 38,
  width: 38,
  alignItems: 'center',
  justifyContent: 'center',
  borderLeftColor: theme.colors.white,
  borderLeftWidth: 1,
  borderBottomRightRadius: 5,
  borderTopRightRadius: 5,
});

const checkBoxContainerStyle: StyleFn = ({ theme }) => ({
  width: '90%',
  justifyContent: 'space-between',
  alignSelf: 'center',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
  borderRadius: 0,
  height: 45,
  shadowColor: theme.colors.white,
});

const rightIconStyle: StyleFn = () => ({
  height: 34,
  width: 20,
  justifyContent: 'center',
  alignSelf: 'center',
});

const ButtonWithActionSheet: React.FC<ButtonWithActionSheetProps> = ({
  actionList,
  title,
  scrollEnabled,
  contentStyle,
  customDropDownStyle,
  btnTextlabelStyle,
  btnContainerStyle,
  iconColor,
  dropDownTitleStyle,
  arrowVisible = true,
  PopoverViewPlacement = 'bottom',
  containerStyle,
  icon,
  iconContainerStyle,
}: ButtonWithActionSheetProps) => {
  const { css, theme } = useFela();
  const [showOptions, setShowOptions] = useState(false);

  const getPopoverPlacement = useCallback(() => {
    switch (PopoverViewPlacement) {
      case 'top':
        return PopoverPlacement.TOP;
      case 'bottom':
        return PopoverPlacement.BOTTOM;
      case 'center':
        return PopoverPlacement.CENTER;
      case 'left':
        return PopoverPlacement.LEFT;
      case 'right':
        return PopoverPlacement.RIGHT;
      default:
        return PopoverPlacement.AUTO;
    }
  }, [PopoverViewPlacement]);

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const closeDropDown = useCallback((): void => {
    setShowOptions(false);
  }, []);

  const onBlur = useCallback(
    (e): void => {
      const targetEvent = (
        e.nativeEvent as unknown as {
          relatedTarget: unknown;
        }
      )?.relatedTarget;
      !targetEvent && closeDropDown();
    },
    [closeDropDown],
  );

  const onToggleOptions = useCallback((): void => {
    setShowOptions(!showOptions);
  }, [showOptions]);

  const handlePress = useCallback(
    (action: Function) => {
      action();
      closeDropDown();
    },
    [closeDropDown],
  );

  const onToggle = useCallback(
    (action: Function) => {
      onToggleOptions();
      setTimeout(() => {
        handlePress(action);
      }, 500);
    },
    [handlePress, onToggleOptions],
  );

  return (
    <View style={[css(viewStyle), containerStyle]}>
      <Popover
        arrowStyle={
          arrowVisible ? css(showToolTipArrowStyle) : css(hideToolTipArrowStyle)
        }
        placement={getPopoverPlacement()}
        popoverStyle={css(popoverStyle({ theme }))}
        from={
          <TouchableOpacity activeOpacity={1}>
            <Button
              fluid
              testID="Button-with-action-sheet"
              title={title}
              containerStyle={
                title &&
                ([
                  css(btnCustomContainerStyle),
                  btnContainerStyle,
                ] as unknown as ViewStyle)
              }
              labelStyle={
                [css(labelStyle), btnTextlabelStyle] as unknown as TextStyle
              }
              iconPosition="right"
              iconContainerStyle={
                [
                  css(iconCustomContainerStyle),
                  iconContainerStyle,
                ] as unknown as TextStyle
              }
              icon={icon || 'AngleDown'}
              iconProps={{
                color: iconColor || theme.colors.success,
                size: 20,
                primary: true,
              }}
              onPress={onToggleOptions}
              onBlur={onBlur}
            />
          </TouchableOpacity>
        }
        backgroundStyle={css(backgroundStyle)}
        isVisible={showOptions}
        onRequestClose={() => onRequestClosePopover()}
      >
        <View style={[css(dropDownInnerContainer), customDropDownStyle]}>
          <Title
            containerStyle={css(dropDownTitleContainerStyle)}
            labelStyle={
              [
                css(dropDownTitleLabelStyle),
                dropDownTitleStyle,
              ] as unknown as TextStyle
            }
          >
            {title}
          </Title>
          <FlatList
            scrollEnabled={scrollEnabled}
            data={actionList}
            contentContainerStyle={[
              css(
                flatListContentStyle({
                  theme,
                  height: 40 * actionList.length,
                }),
              ),
              contentStyle,
            ]}
            renderItem={({ item, index }): ReactElement => {
              return (
                <Button
                  title={item.label}
                  fluid
                  raised
                  key={index}
                  onPress={() => onToggle(item.action)}
                  iconPosition={'right'}
                  labelStyle={
                    (item.icon
                      ? [
                          css(optionLabelStyle),
                          css(actionTextStyle),
                          item.textStyle,
                        ]
                      : [css(optionLabelStyle), item.textStyle]) as TextStyle
                  }
                  containerStyle={css(checkBoxContainerStyle)}
                  iconContainerStyle={css(rightIconStyle)}
                  icon={item.icon || 'angle-right'}
                  iconProps={{
                    color: item.iconColor || theme.colors.paragraph,
                    size: 24,
                  }}
                />
              );
            }}
            // FIXME: added this `index` as fallback key, this should be refactored as per above generic type issue and keeping `index` as key should be avoided in future components while building itself.
            keyExtractor={(item: ActionList, index: number) =>
              item.id || index.toString()
            }
          />
        </View>
      </Popover>
    </View>
  );
};

export default ButtonWithActionSheet;
