import React from 'react';
import { CreateMenu } from './CreateMenu'; // to be changed
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';

const Tab = createMaterialTopTabNavigator();

const CreateMenuTab: React.FC = () => {
  const route = useRoute();
  const params = route.params as { menuId: string };
  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
        <TabBar tabBar={props} previousRoute={'Menus'} />
      )}
      initialRouteName="CreateMenu"
    >
      <Tab.Screen
        name="CreateMenu"
        component={CreateMenu}
        options={{ tabBarLabel: 'Create New' }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default CreateMenuTab;
