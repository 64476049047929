import { StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { Text, View } from 'react-native';
import { useSession } from '../../../hooks/app/useSession';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';

export interface CustomerDisplayProps {
  onClose?: () => void | Promise<void>;
}

const containerStyle: StyleFn = ({ theme }) => ({
  width: 500,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  padding: theme.spacing.big,
  backgroundColor: theme.colors.white,
});

const cardView: StyleFn = ({ theme }) => ({
  width: 450,
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  padding: theme.spacing.big,
  backgroundColor: theme.colors.blue,
});

const titleStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Bold,
  fontSize: theme.fontSize.large,
  color: theme.colors.white,
  marginTop: theme.spacing.medium,
});

const descStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Regular,
  color: theme.colors.white,
  fontSize: theme.fontSize.medium,
  textAlign: 'center',
  fontFamily: theme.font.regular,
  marginTop: theme.spacing.small,
});
const buttonStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small,
  backgroundColor: theme.colors.background,
});
const textStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.regular,
});

const CustomerDisplayModal: React.FC<CustomerDisplayProps> = ({ onClose }) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const [session] = useSession();
  const { translate } = useTranslation();

  const onCloseModal = useCallback(() => {
    closeModal();
    onClose && onClose();
  }, [closeModal, onClose]);

  return (
    <View style={css(containerStyle)}>
      <View style={css(cardView)}>
        <Icon name={'raindrops'} size={30} color={theme.colors.white} />
        <Text style={css(titleStyle)} testID="device-code">
          {session.device?.deviceCode ?? '---- ----'}
        </Text>
        <Text style={css(descStyle)}>
          {translate('CustomerDisplayModal.description')}
        </Text>
      </View>
      <Button
        testID={'cancel'}
        title={translate('settings.cancel')}
        fluid
        success
        size="small"
        containerStyle={css(buttonStyle)}
        onPress={onCloseModal}
        labelStyle={css(textStyle)}
      />
    </View>
  );
};

export default CustomerDisplayModal;
