import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';

export const HeaderStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flexDirection: 'column',
          paddingHorizontal: theme.spacing.big,
        },
        row1: {
          flex: 1,
          backgroundColor: theme.colors.white,
          width: '100%',
          flexDirection: 'row',
          zIndex: 950,
          alignContent: 'center',
          justifyContent: 'space-between',
        },
        row2: {
          flex: 1,
          width: '100%',
          backgroundColor: theme.colors.white,
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-between',
          marginTop: theme.spacing.big,
        },
        venueFilterView: {
          width: 180,
          marginLeft: theme.spacing.small,
          height: rowHeight,
        },
        basicDropdown: {
          marginLeft: 0,
          width: 200,
          paddingTop: theme.padding.medium,
          height: rowHeight,
        },
        dateRangeFilters: {
          width: 600,
          paddingTop: theme.padding.medium,
          height: rowHeight,
        },
        iconStyle: {
          width: 38,
          alignSelf: 'center',
          backgroundColor: theme.colors.highlighted,
          marginTop: theme.padding.large,
        },
        filtersButtonStyle: {
          borderRadius: theme.radius.small,
          color: theme.colors.blue,
          backgroundColor: theme.colors.highlighted,
        },
        columnsButtonStyle: {
          width: 38,
          height: 38,
          borderRadius: theme.radius.small,
          color: theme.colors.blue,
          backgroundColor: theme.colors.highlighted,
          justifyContent: 'center',
          alignItems: 'center',
        },
        filtersIconStyle: {
          color: theme.colors.blue,
        },
        columnsIconStyle: {
          color: theme.colors.blue,
        },
        updateTableStyle: {
          width: 180,
          alignSelf: 'center',
          backgroundColor: theme.colors.white,
        },
        updateContainerStyle: {
          backgroundColor: theme.colors.white,
          paddingTop: theme.padding.large,
          paddingRight: theme.padding.medium * 3,
        },
        searchContainerStyle: {
          width: 950,
          flexDirection: 'row',
          backgroundColor: theme.colors.white,
          paddingRight: theme.padding.medium * 3,
        },
        searchTypeStyle: {
          width: 150,
          height: rowHeight,
        },
        searchStyle: {
          width: 1000,
          height: rowHeight,
        },
        searchTypeInputStyle: {
          height: rowHeight,
        },
        searchInputStyle: {
          width: '95%',
          height: rowHeight,
        },
        downloadContainerStyle: {
          paddingTop: theme.padding.large,
          backgroundColor: theme.colors.white,
          paddingRight: theme.padding.medium * 3,
        },
        updateButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          alignSelf: 'center',
          borderRadius: theme.radius.small,
          color: theme.colors.success,
          backgroundColor: theme.colors.successLight,
        },
        downloadButtonStyle: {
          borderRadius: theme.radius.small,
          color: theme.colors.blue,
          backgroundColor: theme.colors.highlighted,
        },
        titleStyle: {
          color: theme.colors.blue,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
        downloadIconStyle: {
          color: theme.colors.blue,
        },
      }),
    [theme],
  );
};

export const Styles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        filtersStyle: {
          width: 265,
          flexDirection: 'column',
          height: 'calc(100vh - 135px)',
          position: 'absolute',
          zIndex: 1,
        },
        mainSectionStyle: {
          width: 1200,
          height: '100%',
          flexDirection: 'column',
          margin: 'auto',
        },
        headersStyle: {
          zIndex: 900,
          width: '100%',
          height: 78,
          alignSelf: 'flex-start',
        },
        chartRowStyle: {
          zIndex: 800,
          display: 'flex',
          flexGrow: 1,
          width: '100%',
          height: 200,
          flexDirection: 'row',
          alignSelf: 'flex-start',
        },
        tableStyle: {
          width: '100%',
          height: '100%',
          zIndex: 800,
          flex: 1,
          minHeight: 350,
        },
      }),
    [theme],
  );
};
