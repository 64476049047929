import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useCallback, useState } from 'react';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import {
  Terminal,
  CreateTerminalInput,
  TerminalDiagnosisResponse,
  TerminalDiagnosisInput,
} from '@hitz-group/domain';
import { ApolloError } from '@apollo/client';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { useSession } from './useSession';
import { stripProperties } from '../../utils/stripObjectProps';
import { cloneJSON } from '@hitz-group/client-utils';
import { Session } from '../../state/Session';
import {
  CREATE_TERMINAL,
  DELETE_TERMINAL,
  DIAGNOSE_TERMINAL_QUERY,
} from '../../graphql/terminals';

export interface UseTerminalProps {
  loading: boolean;
  error: string | undefined;
  terminal: Terminal | string | undefined;
  diagnosisResponse: TerminalDiagnosisResponse | undefined;
  createTerminal: (input: CreateTerminalInput) => void;
  deleteTerminal: (id: string) => void;
  diagnoseTerminal: (input: TerminalDiagnosisInput) => void;
}

export const useTerminals = (): UseTerminalProps => {
  const [terminal, setTerminal] = useState<Terminal | string | undefined>(
    undefined,
  );
  const [diagnosisResponse, setDiagnosisResponse] = useState<
    TerminalDiagnosisResponse | undefined
  >(undefined);

  const [session, setSession] = useSession();

  // Add terminal in session
  const addTerminalInSession = useCallback(
    (terminal: Terminal) => {
      try {
        const sessionCopy = cloneJSON(session) as Session;

        if (sessionCopy.device) {
          sessionCopy.device.paymentTerminal = { ...terminal };
        }

        if (sessionCopy.currentStore?.devices) {
          sessionCopy.currentStore.devices =
            sessionCopy.currentStore.devices.map(device => {
              if (device.id === sessionCopy.device?.id) {
                return {
                  ...device,
                  paymentTerminal: { ...terminal },
                };
              } else {
                return device;
              }
            });
        }

        if (sessionCopy.currentVenue?.stores) {
          sessionCopy.currentVenue.stores = sessionCopy.currentVenue.stores.map(
            store => {
              if (store.id === sessionCopy.currentStore?.id) {
                return { ...store, ...sessionCopy.currentStore };
              } else {
                return store;
              }
            },
          );
        }

        if (sessionCopy.user?.venues) {
          sessionCopy.user.venues = sessionCopy.user.venues.map(venue => {
            if (venue.id === sessionCopy.currentVenue?.id) {
              return { ...venue, ...sessionCopy.currentVenue };
            } else {
              return venue;
            }
          });
        }

        // update terminal in session if updated
        setSession(sessionCopy);
      } catch (error) {
        console.error(`Error while updating device in session: ${error}`);
      }
    },
    [session, setSession],
  );

  // Remove terminal from session if deleted
  const removeTerminalInSession = useCallback((): void => {
    try {
      const sessionCopy = cloneJSON(session) as Session;

      if (sessionCopy.device) {
        sessionCopy.device.paymentTerminal = undefined;
      }

      if (sessionCopy.currentStore?.devices) {
        sessionCopy.currentStore.devices = sessionCopy.currentStore.devices.map(
          device => {
            if (device.id === sessionCopy.device?.id) {
              return {
                ...device,
                paymentTerminal: undefined as unknown as Terminal,
              };
            } else {
              return device;
            }
          },
        );
      }

      if (sessionCopy.currentVenue?.stores) {
        sessionCopy.currentVenue.stores = sessionCopy.currentVenue.stores.map(
          store => {
            if (store.id === sessionCopy.currentStore?.id) {
              return { ...store, ...sessionCopy.currentStore };
            } else {
              return store;
            }
          },
        );
      }

      if (sessionCopy.user?.venues) {
        sessionCopy.user.venues = sessionCopy.user.venues.map(venue => {
          if (venue.id === sessionCopy.currentVenue?.id) {
            return { ...venue, ...sessionCopy.currentVenue };
          } else {
            return venue;
          }
        });
      }

      setSession(sessionCopy);
    } catch (error) {
      console.error(`Error while updating device in session: ${error}`);
    }
  }, [session, setSession]);

  const onCompleteCreateTerminalRequest = useCallback(
    async data => {
      const paymentTerminal = stripProperties(
        data.createTerminal as Terminal,
        '__typename',
      );
      addTerminalInSession(paymentTerminal);
      setTerminal(paymentTerminal);
    },
    [addTerminalInSession],
  );

  const [createTerminalRequest, createTerminalResponse] = useMutation(
    CREATE_TERMINAL,
    {
      onError: noopHandler,
      onCompleted: onCompleteCreateTerminalRequest,
    },
  );

  const onCompleteDeleteTerminalRequest = useCallback(async () => {
    removeTerminalInSession();
    setTerminal('deleted successfully');
  }, [removeTerminalInSession]);

  const [deleteTerminalRequest, deleteTerminalResponse] = useMutation(
    DELETE_TERMINAL,
    {
      onError: noopHandler,
      onCompleted: onCompleteDeleteTerminalRequest,
    },
  );

  const createTerminal = useCallback(
    (terminalInput: CreateTerminalInput) => {
      setTerminal(undefined);
      createTerminalRequest({
        variables: {
          input: terminalInput,
        },
      });
    },
    [createTerminalRequest],
  );

  const deleteTerminal = useCallback(
    (id: string) => {
      setTerminal(undefined);
      deleteTerminalRequest({
        variables: {
          id,
        },
      });
    },
    [deleteTerminalRequest],
  );

  const onCompleteDiagnoseTerminalRequest = useCallback(async data => {
    setDiagnosisResponse(
      stripProperties(
        data.diagnoseTerminal as TerminalDiagnosisResponse,
        '__typename',
      ),
    );
  }, []);

  const [diagnoseTerminalRequest, diagnoseTerminalResponse] = useLazyQuery(
    DIAGNOSE_TERMINAL_QUERY,
    {
      fetchPolicy: 'network-only',
      onError: noopHandler,
      onCompleted: onCompleteDiagnoseTerminalRequest,
    },
  );

  const diagnoseTerminal = useCallback(
    (diagnosisInput: TerminalDiagnosisInput) => {
      setDiagnosisResponse(undefined);
      diagnoseTerminalRequest({
        variables: {
          input: diagnosisInput,
        },
      });
    },
    [diagnoseTerminalRequest],
  );

  const RESPONSES = [
    createTerminalResponse,
    deleteTerminalResponse,
    diagnoseTerminalResponse,
  ];

  const error: ApolloError | undefined = getError(RESPONSES);
  const loading: boolean = isLoading(RESPONSES);

  return {
    loading,
    error: error ? parseApolloError(error) : undefined,
    terminal,
    diagnosisResponse,
    createTerminal,
    deleteTerminal,
    diagnoseTerminal,
  };
};
