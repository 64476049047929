import React, { useCallback, useState } from 'react';
import {
  View,
  FlatList,
  ViewStyle,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import FormInput from '../FormInput/FormInput';
import {
  useCurrency,
  getCurrencyOptions,
  useLocalization,
} from '@hitz-group/localization';
import { FelaComponent, useFela } from 'react-fela';
import Title from '../Title/Title';
import { isWeb, isAndroid } from '../../common/theme';

export interface InputKeyProps {
  index: number;
  onChange?: (currency: string, value: string) => void;
  currency: string;
  count: string;
}

export interface CurrencyInputProps {
  containerStyle?: ViewStyle;
  options?: Record<string, string>;
  onChange?: (value: string) => void;
}

const inputKeysStyle: StyleFn = ({ theme, index }) => ({
  height: isAndroid ? 35 : 44,
  width: 80,
  marginHorizontal: index % 3 === 1 ? theme.spacing.medium : 0,
});
const keypadRowStyle: StyleFn = () => ({
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: isWeb ? 20 : isAndroid ? 0 : 10,
  marginHorizontal: 0,
});
const formTextInputStyle: StyleFn = ({ theme }) => ({
  width: 60,
  paddingHorizontal: theme.padding.small + theme.padding.medium,
  textAlign: 'center',
});
const titleStyle: StyleFn = ({ theme }) => ({
  marginBottom: isWeb ? 4 : 0,
  fontFamily: theme.font.medium,
  top: isAndroid ? 20 : 2,
});

const InputKeys: React.FC<InputKeyProps> = ({
  onChange,
  currency,
  count,
  ...props
}: InputKeyProps) => {
  const { formatCurrency } = useCurrency();
  const { css } = useFela();
  const [value, setValue] = useState(count);
  const onPressKey = useCallback(
    (val: string) => {
      setValue(val);
      onChange && onChange(currency, val);
    },
    [onChange, currency],
  );
  return (
    <FelaComponent style={inputKeysStyle} {...props}>
      {({ style }: RenderProps): React.ReactFragment => (
        <View>
          <Title labelStyle={css(titleStyle)}>
            {formatCurrency(+currency)}
          </Title>
          <FormInput
            containerStyle={style}
            keyboardType={'number-pad'}
            value={value}
            textInputStyle={css(formTextInputStyle)}
            placeholder={'0'}
            onChangeText={onPressKey}
            type="text"
          />
        </View>
      )}
    </FelaComponent>
  );
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  containerStyle,
  onChange,
}: CurrencyInputProps) => {
  const { css } = useFela();
  const { country } = useLocalization();

  const initialOptions = getCurrencyOptions(country).reduce(
    (acc, x) => ({ ...acc, [x]: '0' }),
    {},
  );

  const [options, setOptions] = useState<Record<string, string>>({
    ...initialOptions,
  });

  const onChangeCurrencyValue = useCallback(
    (prop: string, value: string) => {
      const updatedOptions = { ...options, [prop]: value };
      setOptions(updatedOptions);
      const totalCounted = Object.keys(updatedOptions).reduce(
        (acc, x) => acc + +x * +updatedOptions[x],
        0,
      );
      onChange && onChange(totalCounted.toString());
    },
    [onChange, options],
  );
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <View testID={'testID'} style={containerStyle}>
        <FlatList
          style={containerStyle}
          numColumns={3}
          scrollEnabled={false}
          columnWrapperStyle={css(keypadRowStyle)}
          data={Object.keys(options).sort((a, b) => +b - +a)}
          renderItem={({ item: key, index }) => (
            <InputKeys
              index={index}
              key={index}
              currency={key}
              count={options[key]}
              onChange={onChangeCurrencyValue}
            />
          )}
        />
      </View>
    </KeyboardAvoidingView>
  );
};

export default CurrencyInput;
