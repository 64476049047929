import React, { useEffect, useCallback } from 'react';
import { DELETE_FEE_DETAILS } from '../../../graphql/settings';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@hitz-group/localization';
import ConfirmationDialog from '../../../components/Modals/ConfirmationDialog';

interface DeleteFeeModalProps {
  id: string;
  name: string;
  index: number;
  onDeleteCallBack: (index: number, id: string) => void;
}

export const DeleteFeeModal: React.FC<DeleteFeeModalProps> = ({
  id,
  name,
  index,
  onDeleteCallBack,
}: DeleteFeeModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [deleteFee, deleteFeeRequest] = useMutation(DELETE_FEE_DETAILS, {
    onError: noopHandler,
  });

  useEffect((): void => {
    if (deleteFeeRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(deleteFeeRequest.error),
      });
    }
  }, [deleteFeeRequest.error, showNotification]);

  useEffect((): void => {
    if (deleteFeeRequest.data) {
      onDeleteCallBack(index, id);
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: name,
        }),
      });
    }
  }, [
    onDeleteCallBack,
    index,
    id,
    deleteFeeRequest.data,
    showNotification,
    closeModal,
    name,
    translate,
  ]);

  const onDeleteFee = useCallback((): void => {
    deleteFee({ variables: { id: id } });
  }, [deleteFee, id]);

  return (
    <ConfirmationDialog
      title={translate('dialog.deleteTitle')}
      message={translate('dialog.deleteConfirmation', { label: name })}
      onConfirm={onDeleteFee}
    />
  );
};
