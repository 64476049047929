import { useLazyQuery } from '@apollo/client/react/hooks';
import { parseApolloError } from '../../utils/errorHandlers';
import { useCallback, useMemo, useState } from 'react';
import { GET_REPORT_FILTERS } from '../../graphql/reporting';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { Filters } from '@hitz-group/domain';
import { stripProperties } from '../../utils/stripObjectProps';

export interface UseReportFilters {
  loading: boolean;
  error: string | undefined;
  filters: Filters | undefined;
  getReportFilters: () => void;
}

export const useReportFilters = (): UseReportFilters => {
  const [filters, setFilters] = useState<Filters | undefined>(undefined);

  const onCompleteRequest = useCallback(async data => {
    const key = Object.keys(data)[0];
    setFilters(stripProperties(data[key] as Filters, '__typename'));
  }, []);

  const [getReportFiltersRequest, getReportFiltersResponse] = useLazyQuery(
    GET_REPORT_FILTERS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteRequest,
    },
  );

  const RESPONSE_ENTITIES = [getReportFiltersResponse];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      filters,
      loading,
      error: error ? parseApolloError(error) : undefined,
      getReportFilters: getReportFiltersRequest,
    }),
    [filters, loading, error, getReportFiltersRequest],
  );
};
