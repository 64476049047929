import { StyleFn } from '@hitz-group/domain';
import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { useNotification } from '../../hooks/Notification';
import BackOfficeHeader from '../BackOfficeHeader/BackOfficeHeader';
import BackOfficeSidebar from '../BackOfficeSidebar/BackOfficeSidebar';
import { NotificationList } from '../Notification/NotificationList';

export interface BackOfficeLayoutProps {
  children: React.ReactNode;
}

const pageContentStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  backgroundColor: theme.colors.white,
});

const styles = StyleSheet.create({
  sidebar: {
    height: '100%',
  },
  layout: {
    flex: 1,
    flexDirection: 'row-reverse',
  },
  scrollView: {
    flex: 1,
    height: '100%',
    width: '100%',
    zIndex: 0,
  },
});

const BackOfficeLayout: React.FC<BackOfficeLayoutProps> = ({
  children,
}: BackOfficeLayoutProps) => {
  const { css } = useFela();
  const { closeNotification, notifications } = useNotification();

  return (
    <SafeAreaView style={styles.layout}>
      <NotificationList
        notifications={notifications}
        onCloseNotification={closeNotification}
      />
      <View style={css(pageContentStyle)}>
        <BackOfficeHeader />
        {useMemo(
          () => (
            <ScrollView contentContainerStyle={styles.scrollView}>
              {children}
            </ScrollView>
          ),
          [children],
        )}
      </View>
      <BackOfficeSidebar containerStyle={styles.sidebar} />
    </SafeAreaView>
  );
};

export default BackOfficeLayout;
