import { StyleFn, Icons } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import React from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import Button from '../../../components/Button/Button';

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'stretch',
  marginTop: theme.spacing.big * 2,
  justifyContent: 'space-between',
  height: 'auto',
});

const buttonStyle: StyleFn = ({ theme }) => ({
  marginVertical: theme.padding.small,
  alignSelf: 'stretch',
});

const btnStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  letterSpacing: -0.4,
  fontSize: theme.fontSize.small,
});

const posBtnLabelStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  letterSpacing: -0.4,
  fontSize: theme.fontSize.small,
  color: theme.colors.success,
});

const clockOutContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successLight,
});

export interface WorkLogClockButtonProps {
  testID?: string;
  onPressGotoPOS: () => void;
  onPressDismiss: () => void;
  onPressClockInOnly: () => void;
  onPressEndBreak: () => void;
  onPressStartBreak: () => void;
  onPressClockOut: () => void;
  isClockedIn?: boolean;
  isBreak?: boolean;
  loading?: boolean;
  canGoToPOS: boolean;
}
export const TimeAndAttendanceButtonSet: React.FC<WorkLogClockButtonProps> = ({
  onPressGotoPOS,
  onPressDismiss,
  onPressClockInOnly,
  onPressStartBreak,
  onPressEndBreak,
  onPressClockOut,
  isClockedIn,
  isBreak,
  loading,
  canGoToPOS,
}) => {
  const { translate } = useTranslation();
  const { css, theme } = useFela();

  return (
    <View style={css(buttonContainerStyle)}>
      {isClockedIn ? (
        <>
          <View style={css(buttonStyle)}>
            <Button
              success
              fluid
              testID="clockScreenStartEndBreakBtn"
              loading={loading}
              title={
                isBreak
                  ? translate('interimLockScreen.clockScreen.endBreak')
                  : translate('interimLockScreen.clockScreen.startBreak')
              }
              labelStyle={css(btnStyle)}
              onPress={isBreak ? onPressEndBreak : onPressStartBreak}
            />
          </View>
          <View style={css(buttonStyle)}>
            <Button
              fluid
              testID="clockScreenClockOutBtn"
              loading={loading}
              title={translate('interimLockScreen.clockScreen.clockOut')}
              containerStyle={css(clockOutContainerStyle)}
              labelStyle={css(posBtnLabelStyle)}
              icon={Icons.ArrowRight}
              iconPosition="right"
              iconProps={{ color: theme.colors.success, size: 25 }}
              onPress={onPressClockOut}
            />
          </View>
        </>
      ) : (
        <>
          <View style={css(buttonStyle)}>
            <Button
              success
              fluid
              testID="clockScreenClockInOnlyBtn"
              loading={loading}
              title={translate('interimLockScreen.clockScreen.clockInOnly')}
              labelStyle={css(btnStyle)}
              onPress={onPressClockInOnly}
            />
          </View>
          {canGoToPOS && (
            <View style={css(buttonStyle)}>
              <Button
                fluid
                testID="clockScreenGotoPosBtn"
                loading={loading}
                title={translate(
                  'interimLockScreen.clockScreen.clockInAndGoToPOS',
                )}
                containerStyle={css(clockOutContainerStyle)}
                labelStyle={css(posBtnLabelStyle)}
                icon={Icons.ArrowRight}
                iconPosition="right"
                iconProps={{ color: theme.colors.success, size: 25 }}
                onPress={onPressGotoPOS}
              />
            </View>
          )}
        </>
      )}

      <View style={css(buttonStyle)}>
        <Button
          fluid
          transparent
          testID="clockScreenDismissBtn"
          loading={loading}
          title={translate('interimLockScreen.clockScreen.dismiss')}
          labelStyle={css(btnStyle)}
          onPress={onPressDismiss}
        />
      </View>
    </View>
  );
};

const TimeAndAttendanceScreenButtons = React.memo(TimeAndAttendanceButtonSet);

export default TimeAndAttendanceScreenButtons;
