import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { useTranslation } from '@hitz-group/localization';
import { useRoute } from '@react-navigation/native';
import TabBar from '../../../../../../components/TabBar/TabBar';
import { StoreSettings } from './StoreSettings';

const Tab = createMaterialTopTabNavigator();

interface RouteParams {
  storeId: string;
}

const XeroStoreSettingsPage: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const params = route.params as RouteParams;

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'SettingsTab'}
        />
      )}
      initialRouteName="SettingsTab"
    >
      <Tab.Screen
        name="StoreSettings"
        component={StoreSettings}
        options={{ tabBarLabel: translate('tabNames.settings') }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default XeroStoreSettingsPage;
