import React, { useCallback, useState, useEffect, Key } from 'react';
import {
  StyleFn,
  Customer,
  Icons,
  Features,
  FeatureContext,
} from '@hitz-group/domain';
import { useFela } from 'react-fela';
import { ScrollView, View, TouchableOpacity } from 'react-native';
import SearchBar from '../../SearchBar/SearchBar';
import { useTranslation } from '@hitz-group/localization';
import SettingItem from '../../SettingsItem/SettingsItem';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import AddCustomer from './AddCustomer';
import IconButton from '../../Button/IconButton';
import CustomerRewardModal from '../CustomerLoyalty/CustomerRewardModal';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import { usePosState } from '../../../hooks/POSStateProvider';

export interface SearchCustomerProps {
  searchListLength?: number;
  orderId?: string;
  assignCustomerToOrder?: (customer: Customer) => void;
  unassignCustomerToOrder?: () => void;
  onClose?: () => void | Promise<void>;
  assignedCustomerId?: string;
}

const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 58,
  backgroundColor: theme.colors.white,
  paddingLeft: theme.padding.small,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  borderRadius: theme.radius.small,
  marginBottom: 5,
  ...theme.shadow30,
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  width: '90%',
  height: 38,
  color: theme.colors.primary,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.large,
  minHeight: 58,
});

const searchBarStyle: StyleFn = () => ({
  flex: 1,
  width: '50%',
  marginTop: 90,
  alignSelf: 'center',
});

const scrollViewStyle: StyleFn = () => ({
  flex: 1,
});
const touchable: StyleFn = () => ({
  flex: 1,
});

const fontStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.primary,
  fontSize: theme.fontSize.small,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.colors.orangeLighter,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  paddingHorizontal: 10,
});

const iconGroupContainerStyle: StyleFn = ({}) => ({
  flexDirection: 'row',
});

const pointStyles: StyleFn = ({ theme }) => ({
  color: theme.colors.orange,
  paddingRight: 10,
});

const SearchCustomer: React.FC<SearchCustomerProps> = ({
  searchListLength,
  orderId,
  assignCustomerToOrder,
  unassignCustomerToOrder,
  onClose,
  assignedCustomerId,
}) => {
  const { css, theme } = useFela();
  const [searchString, setSearchString] = useState('');
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { error, searchResults, customerMaps } = usePosState('customers');
  const [customersList, setCustomersList] = useState<Array<Customer>>([]);
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isLoyaltyEnabled = isFeatureEnabled(
    Features.LOYALTY,
    FeatureContext.ORGANIZATION,
  );

  const assignedCustomer = assignedCustomerId
    ? customerMaps[assignedCustomerId]
    : null;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (searchResults) {
      const results = searchResults(searchString, searchListLength);
      setCustomersList(results);
    }
  }, [searchString, searchListLength, searchResults]);

  const onSelectCustomer = useCallback(
    (customer: Customer) => {
      // trigger assignCustomerToOrder event
      // on selecting customer trigger assign customer event
      assignCustomerToOrder && assignCustomerToOrder(customer);

      closeModal();
    },
    [closeModal, assignCustomerToOrder],
  );

  const onPressUnassign = useCallback(() => {
    unassignCustomerToOrder && unassignCustomerToOrder();
    closeModal();
  }, [closeModal, unassignCustomerToOrder]);

  const onAddNewCustomer = useCallback(() => {
    showModal(
      <AddCustomer
        name={searchString}
        orderId={orderId}
        assignCustomerToOrder={assignCustomerToOrder}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [showModal, searchString, closeModal, orderId, assignCustomerToOrder]);

  const onRedeem = (customer: Customer) => () => {
    // TODO: handle redemption
    onSelectCustomer(customer);
  };

  const onCloseModal = useCallback(() => {
    onClose && onClose();
    closeModal();
  }, [closeModal, onClose]);

  /* This is causing the modal to close in ios immediately
  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', closeModal);
    return () => {
      Keyboard.removeListener('keyboardDidHide', closeModal);
    };
  }, [closeModal]);
  */

  return (
    <View style={css(searchBarStyle)}>
      <SearchBar
        testID="search-bar"
        placeholder={translate('customer.searchCustomerPlaceholder')}
        containerStyle={css(searchContainerStyle)}
        textInputStyle={css(searchTextInputStyle)}
        iconColor={theme.colors.paragraph}
        placeholderColor={theme.colors.paragraph}
        onChange={setSearchString}
        value={searchString}
        showCancel={true}
        onCancel={setSearchString.bind(null, '')}
        autoFocus={true}
      />
      <TouchableOpacity
        style={css(touchable)}
        onPress={onCloseModal}
        activeOpacity={1}
      >
        <ScrollView
          style={css(scrollViewStyle)}
          showsHorizontalScrollIndicator={false}
        >
          {assignedCustomer && (
            <SettingItem
              testID="unassignCustomer"
              title={
                assignedCustomer.firstName + ' ' + assignedCustomer.lastName
              }
              titleTextStyle={css(fontStyle)}
              onPress={closeModal}
              contentRight={
                <View style={css(iconGroupContainerStyle)}>
                  {isLoyaltyEnabled && assignedCustomer.loyaltyMember ? (
                    <IconButton
                      primary
                      iconStyle={css(fontStyle)}
                      icon={Icons.Star}
                      iconSize={18}
                      iconColor={theme.colors.orange}
                      containerStyle={css(iconContainerStyle)}
                      text={`${assignedCustomer.loyaltyPoints}`}
                      textStyle={css(pointStyles)}
                      testID="star-button"
                      onPress={() => {
                        showModal(
                          <CustomerRewardModal
                            customer={assignedCustomer}
                            onRedeem={onRedeem(assignedCustomer)}
                          />,
                        );
                      }}
                    />
                  ) : null}
                  <IconButton
                    primary
                    icon={Icons.Times}
                    iconSize={26}
                    iconColor={theme.colors.red}
                    containerSize={32}
                    onPress={onPressUnassign}
                  />
                </View>
              }
            />
          )}
          <SettingItem
            testID="addNewCustomer"
            title={translate('customer.addNewCustomer')}
            titleTextStyle={css(fontStyle)}
            onPress={onAddNewCustomer}
            icon={Icons.AngleRight}
            iconColor={theme.colors.paragraphLight}
          />
          <SettingItem
            testID="continueAsGuest"
            title={translate('customer.continueAsGuest')}
            titleTextStyle={css(fontStyle)}
            onPress={onCloseModal}
            icon={Icons.AngleRight}
            iconColor={theme.colors.paragraphLight}
          />
          {customersList
            .filter(cust =>
              assignedCustomer ? cust.id !== assignedCustomer.id : true,
            )
            .map((customer: Customer, key: Key) => (
              <SettingItem
                testID="customer"
                key={key}
                title={customer.firstName + ' ' + customer.lastName}
                titleTextStyle={css(fontStyle)}
                onPress={() => onSelectCustomer(customer)}
                iconStyle={css(fontStyle)}
                contentRight={
                  <View style={css(iconGroupContainerStyle)}>
                    {isLoyaltyEnabled && customer.loyaltyMember ? (
                      <IconButton
                        primary
                        iconStyle={css(fontStyle)}
                        icon={Icons.Star}
                        iconSize={18}
                        iconColor={theme.colors.orange}
                        containerStyle={css(iconContainerStyle)}
                        text={`${customer.loyaltyPoints}`}
                        textStyle={css(pointStyles)}
                        testID="star-button"
                        onPress={() => {
                          showModal(
                            <CustomerRewardModal
                              customer={customer}
                              onRedeem={onRedeem(customer)}
                            />,
                          );
                        }}
                      />
                    ) : null}
                    <IconButton
                      primary
                      testID="add-customer"
                      icon={Icons.AngleRight}
                      iconSize={26}
                      iconColor={theme.colors.paragraphLight}
                      containerSize={32}
                      onPress={() => onSelectCustomer(customer)}
                    />
                  </View>
                }
              />
            ))}
        </ScrollView>
      </TouchableOpacity>
    </View>
  );
};

SearchCustomer.defaultProps = {
  searchListLength: 5,
};

export default SearchCustomer;
