import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useCallback, useState } from 'react';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import {
  PaymentResponse,
  InitiatePaymentInput,
  PaymentInput,
  InitiateRefundInput,
} from '@hitz-group/domain';
import { ApolloError } from '@apollo/client';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { stripProperties } from '../../utils/stripObjectProps';
import {
  CANCEL_PAYMENT_MUTATION,
  INITIATE_PAYMENT_MUTATION,
  INITIATE_REFUND_MUTATION,
  VERIFY_PAYMENT_QUERY,
} from '../../graphql/payments';

export interface UsePaymentsProps {
  loading: boolean;
  cancellationLoading: boolean;
  error: string | undefined;
  paymentResponse: PaymentResponse | undefined;
  cancellationResponse: PaymentResponse | undefined;
  initiatePayment: (input: InitiatePaymentInput) => void;
  verifyPayment: (input: PaymentInput) => void;
  initiateRefund: (input: InitiateRefundInput) => void;
  cancelPayment: (input: PaymentInput) => void;
  resetPaymentResponse: () => void;
  resetCancellationResponse: () => void;
}

export const usePayments = (): UsePaymentsProps => {
  const [paymentResponse, setPaymentResponse] = useState<
    PaymentResponse | undefined
  >(undefined);

  const [cancellationResponse, setCancellationResponse] = useState<
    PaymentResponse | undefined
  >(undefined);

  const onCompleteInitiatePaymentRequest = useCallback(async data => {
    setPaymentResponse(
      stripProperties(data.initiatePayment as PaymentResponse, '__typename'),
    );
  }, []);

  const [initiatePaymentRequest, initiatePaymentResponse] = useMutation(
    INITIATE_PAYMENT_MUTATION,
    {
      onError: noopHandler,
      onCompleted: onCompleteInitiatePaymentRequest,
    },
  );

  const initiatePayment = useCallback(
    (input: InitiatePaymentInput) => {
      setPaymentResponse(undefined);
      initiatePaymentRequest({
        variables: { input },
      });
    },
    [initiatePaymentRequest],
  );

  const onCompleteVerifyPaymentRequest = useCallback(async data => {
    setPaymentResponse(
      stripProperties(data.verifyPayment as PaymentResponse, '__typename'),
    );
  }, []);

  const [verifyPaymentRequest, verifyPaymentResponse] = useLazyQuery(
    VERIFY_PAYMENT_QUERY,
    {
      fetchPolicy: 'network-only',
      onError: noopHandler,
      onCompleted: onCompleteVerifyPaymentRequest,
    },
  );

  const verifyPayment = useCallback(
    (input: PaymentInput) => {
      setPaymentResponse(undefined);
      verifyPaymentRequest({
        variables: { input },
      });
    },
    [verifyPaymentRequest],
  );

  const onCompleteInitiateRefundRequest = useCallback(async data => {
    setPaymentResponse(
      stripProperties(data.initiateRefund as PaymentResponse, '__typename'),
    );
  }, []);

  const [initiateRefundRequest, initiateRefundResponse] = useMutation(
    INITIATE_REFUND_MUTATION,
    {
      onError: noopHandler,
      onCompleted: onCompleteInitiateRefundRequest,
    },
  );

  const initiateRefund = useCallback(
    (input: InitiateRefundInput) => {
      setPaymentResponse(undefined);
      initiateRefundRequest({
        variables: { input },
      });
    },
    [initiateRefundRequest],
  );

  const resetPaymentResponse = useCallback(() => {
    setPaymentResponse(undefined);
  }, []);

  const onCompleteCancelPaymentRequest = useCallback(async data => {
    setCancellationResponse(
      stripProperties(data.cancelPayment as PaymentResponse, '__typename'),
    );
  }, []);

  const [cancelPaymentRequest, cancelPaymentResponse] = useMutation(
    CANCEL_PAYMENT_MUTATION,
    {
      onError: noopHandler,
      onCompleted: onCompleteCancelPaymentRequest,
    },
  );

  const cancelPayment = useCallback(
    (input: PaymentInput) => {
      setCancellationResponse(undefined);
      cancelPaymentRequest({
        variables: { input },
      });
    },
    [cancelPaymentRequest],
  );

  const resetCancellationResponse = useCallback(() => {
    setCancellationResponse(undefined);
  }, []);

  const RESPONSES = [
    initiatePaymentResponse,
    verifyPaymentResponse,
    initiateRefundResponse,
  ];

  const error: ApolloError | undefined = getError([
    ...RESPONSES,
    cancelPaymentResponse,
  ]);
  const loading: boolean = isLoading(RESPONSES);
  const cancellationLoading: boolean = isLoading([cancelPaymentResponse]);

  return {
    loading,
    cancellationLoading,
    error: error ? parseApolloError(error) : undefined,
    paymentResponse,
    cancellationResponse,
    initiatePayment,
    verifyPayment,
    initiateRefund,
    resetPaymentResponse,
    cancelPayment,
    resetCancellationResponse,
  };
};
