import { useMutation, useLazyQuery } from '@apollo/client/react/hooks';
import { useMemo, useCallback, useState } from 'react';
import { CLOSE_SHIFT, GET_SHIFTS } from '../../graphql/shifts';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import {
  Shift,
  ShiftFilter,
  CloseShiftInput,
  SalesSummary,
} from '@hitz-group/domain';

export interface ShiftsProps {
  shifts: Array<Shift>;
  closeShift: (input: CloseShiftInput) => void;
  success?: boolean;
  queryLoading: boolean;
  mutationLoading: boolean;
  error: string | undefined;
  refetch: () => void;
  closedShiftData?: SalesSummary;
}

export function useShifts(
  filters?: ShiftFilter,
  retrieveAll?: boolean,
): ShiftsProps {
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [fetchShifts, fetchShiftsResponse] = useLazyQuery(GET_SHIFTS, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onCompleted: data => {
      if (data.shifts) {
        setShifts(data.shifts);
      }
    },
  });

  const [closeShiftMutation, shiftStatus] = useMutation(CLOSE_SHIFT, {
    onError: noopHandler,
  });

  const closeShift = useCallback(
    (input: CloseShiftInput) => {
      closeShiftMutation({ variables: { input } });
    },
    [closeShiftMutation],
  );

  const fetchShiftsData = useCallback(() => {
    fetchShifts({
      variables: { input: { filters, retrieveAll } },
    });
  }, [fetchShifts, filters, retrieveAll]);

  const err = fetchShiftsResponse.error || shiftStatus.error;

  return useMemo(
    () => ({
      shifts,
      closeShift,
      success: !!shiftStatus.data,
      queryLoading: fetchShiftsResponse.loading,
      mutationLoading: shiftStatus.loading,
      error: err ? parseApolloError(err) : undefined,
      refetch: fetchShiftsData,
      closedShiftData: shiftStatus?.data?.closeShift || undefined,
    }),
    [
      fetchShiftsResponse.loading,
      closeShift,
      shiftStatus.data,
      shiftStatus.loading,
      err,
      fetchShiftsData,
      shifts,
    ],
  );
}
