import {
  StyleFn,
  Page,
  UpdatePageInput,
  DEFAULT_ENTITY_ID,
} from '@hitz-group/domain';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { useNotification } from '../../../../hooks/Notification';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import { usePages } from '../../../../hooks/app/pages/usePages';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Operation } from '../../../../types/Operation';
import { useNavigation } from '@react-navigation/native';
import { PagesRow } from './PageRow';
import { useIsFocused } from '@react-navigation/native';
import { isWeb } from '../../../../common/theme';

const scrollStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: isWeb ? '50%' : '100%',
  alignSelf: 'center',
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const Pages: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [pagesMap, setPagesMap] = useState<Record<string, Page>>({});
  const { getPages, error, loading, operation, pages, updatePages } =
    usePages();

  const pageUpdated = !error && !loading && operation === Operation.UPDATE;

  const pagesArray = useMemo(() => {
    return Object.values(pagesMap);
  }, [pagesMap]);

  useEffect(() => {
    isFocused && getPages();
  }, [getPages, isFocused]);

  useEffect(() => {
    if (pages) {
      setPagesMap(pages);
    }
  }, [pages]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (pageUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeProducts.pagesWereUpdatedSuccessfully'),
      });
    }
  }, [pageUpdated, translate, showNotification]);

  const createPage = useCallback((): void => {
    // navigate to page details screen
    navigation.navigate('PageSettings', {
      pageId: DEFAULT_ENTITY_ID,
      title: 'Create Page',
    });
  }, [navigation]);

  const onChangeName = useCallback((id: string, value): void => {
    setPagesMap(prev => ({ ...prev, [id]: { ...prev[id], name: value } }));
  }, []);

  const onEditPage = useCallback(
    (id: string) => {
      navigation.navigate('PageSettings', {
        pageId: id,
        title: pagesMap[id]?.name,
      });
    },
    [navigation, pagesMap],
  );

  const onPressSave = useCallback((): void => {
    const pageDataToUpdate = [] as UpdatePageInput[];
    let errorCount = 0;
    pagesArray.forEach(page => {
      if (page.id && page.name) {
        pageDataToUpdate.push({
          id: page.id,
          name: page.name,
        });
      } else {
        errorCount = errorCount + 1;
      }
    });
    if (errorCount === 0) {
      updatePages(pageDataToUpdate);
    } else {
      showNotification({
        error: true,
        message: translate('backOfficeProducts.pleaseEnterPageName'),
      });
    }
  }, [updatePages, showNotification, translate, pagesArray]);

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollStyle)}>
          <BackOfficeSection
            title={translate('backOfficeProducts.pages')}
            titleDescription={translate('backOfficeProducts.pagesDescription')}
            action={<BackOfficeCreateNewButton onPress={createPage} />}
            contentContainerStyle={css(formStyle)}
            containerStyle={css(containerStyle)}
          >
            <TableComponent
              columns={[
                {
                  title: translate('backOfficeProducts.pageName'),
                  width: 310,
                  containerStyle: { paddingLeft: theme.spacing.big / 2 },
                },
                {
                  title: translate('backOfficeProducts.products'),
                  width: 80,
                },
                { title: '', flex: 1 },
                {
                  title: translate('backOfficeProducts.colour'),
                  width: 150,
                },
              ]}
              data={pagesArray}
              normalRows
              columnContainerStyle={css(columnContainerStyle)}
              renderRow={(item: Page, index: number): React.ReactNode => (
                <PagesRow
                  page={item}
                  onChange={onChangeName}
                  key={index}
                  onEditPage={onEditPage}
                />
              )}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
