import React, { useCallback } from 'react';
import { Icons, OrderType, OrderTypeCode, StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import { View, Text, Platform } from 'react-native';
import TableRow from '../../../../../../../components/TableComponent/TableRow';
import IconButton from '../../../../../../../components/Button/IconButton';

interface OrderTypesRowProps {
  orderType: OrderType;
  enabled: boolean;
  onToggle?: (id: string, value: boolean) => void;
  onOpenModal?: (code: OrderTypeCode) => void;
}

const nameTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  lineHeight: 21,
});

const toggleContainerStyle: StyleFn = () => ({
  width: 50,
});

const textWrapper: StyleFn = ({ theme }) => ({
  flex: 0.3,
  height: theme.input.height,
  borderRadius: theme.radius.small,
});

const smallContainer: StyleFn = ({ theme }) => ({
  flex: 0.6,
  borderRadius: theme.radius.small,
  paddingHorizontal: theme.padding.medium,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginRight: theme.spacing.medium + theme.spacing.small / 2,
});

const iconWrapperStyle: StyleFn = () => ({
  flex: 0.1,
});

const defaultCheckContainerStyle: StyleFn = ({ theme }) => {
  const defaultStyle = {
    width: 40,
    height: theme.input.height,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  };
  return Platform.OS === 'web'
    ? { ...defaultStyle, cursor: 'pointer' }
    : defaultStyle;
};

const tableContainerStyle: StyleFn = ({ theme }) => ({
  borderBottomWidth: 0,
  marginVertical: theme.spacing.small / 2,
  paddingVertical: theme.spacing.small / 2,
});

const tableContainerStyleDisabled: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
});

const tableContainerStyleEnabled: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successLightest,
});

const OrderTypesRow: React.FC<OrderTypesRowProps> = ({
  onToggle,
  orderType,
  enabled,
  onOpenModal,
}: OrderTypesRowProps) => {
  const { css, theme } = useFela();

  const onPressHandler = useCallback(() => {
    onToggle && onToggle(orderType.id, !enabled);
  }, [onToggle, orderType.id, enabled]);

  const onPressOpenModal = useCallback(() => {
    onOpenModal && onOpenModal(orderType.code as OrderTypeCode);
  }, [onOpenModal, orderType.code]);

  return (
    <TableRow
      normalRows={true}
      containerStyle={[
        css(tableContainerStyle),
        css(enabled ? tableContainerStyleEnabled : tableContainerStyleDisabled),
      ]}
    >
      <IconButton
        testID={'fullfilmentSetting-orderType'}
        primary
        icon={enabled ? Icons.ToggleOn : Icons.ToggleOff}
        iconSize={26}
        onPress={onPressHandler}
        containerSize={34}
        containerStyle={css(toggleContainerStyle)}
        iconColor={enabled ? theme.colors.success : theme.colors.boxBorder}
      />

      <View style={css(smallContainer)}>
        <Text
          style={css(nameTextStyle)}
          testID={'fullfilmentSetting-orderTypeName'}
        >
          {orderType.name}
        </Text>
      </View>
      <View style={css(textWrapper)} />
      <View style={css(iconWrapperStyle)}>
        <IconButton
          testID={'fullfilmentSetting-openOrderTypeModal'}
          fluid
          disabled={!enabled}
          onPress={onPressOpenModal}
          containerStyle={css(defaultCheckContainerStyle({ theme }))}
          icon={Icons.Setting}
          iconColor={enabled ? theme.colors.blue : theme.colors.grey}
        />
      </View>
    </TableRow>
  );
};

export default OrderTypesRow;
