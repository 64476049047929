import { FeatureSetting } from '@hitz-group/domain';
import React, { FC } from 'react';
import { useCheckFeatureEnabled } from '../../../src/hooks/app/features/useCheckFeatureEnabled';
interface Props {
  feature?: FeatureSetting;
  venue?: string;
}

export const WithFeature: FC<Props> = ({ feature, venue, children }) => {
  const isFeatureEnabled = useCheckFeatureEnabled();
  if (feature) {
    const { name, context, showTabIfEnabled } = feature;
    const showTab = showTabIfEnabled && isFeatureEnabled(name);
    const isEnabled = isFeatureEnabled(name, context, venue);

    if (
      (isEnabled || showTab) &&
      (showTabIfEnabled === undefined || showTabIfEnabled)
    ) {
      return <>{children}</>;
    } else if (!isEnabled && showTabIfEnabled === false) {
      return <>{children}</>;
    }
    return null;
  }
  return <>{children}</>;
};
