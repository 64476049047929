import { useCurrency, useTranslation } from '@hitz-group/localization';
import { Customer } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import TableComponent from '../TableComponent/TableComponent';
import IconButton from '../Button/IconButton';
import Title from '../Title/Title';
import CustomerDataRow from './CustomerDataRow';
import PaginatedView from '../PaginatedView/PaginatedView';
import * as styles from './CustomerView.style';
import { useNotification } from '../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import SetAmountKeypad from '../Modals/SetAmountKeypad/SetAmountKeypad';
import { useNavigation } from '@react-navigation/native';

export interface CustomerDataViewProps {
  data: Customer[];
  onPressNext?: (page: number, maxItemsPerPage: number) => void;
}

const CustomerView: React.FC<CustomerDataViewProps> = ({
  data,
  onPressNext,
}: CustomerDataViewProps) => {
  const { translate } = useTranslation();
  const { css, theme } = useFela();
  const navigation = useNavigation();
  const { formatCurrency } = useCurrency();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();

  const navigateToPayment = useCallback(
    (amount: number, customer: Customer) => {
      navigation.navigate('Orders', {
        screen: 'Payment',
        params: {
          customerAmount: amount,
          customerId: customer.id,
        },
      });
    },
    [navigation],
  );

  const onUpdateProcessingAmount = useCallback(
    (customerAmount: number, customer: Customer) => {
      const onSubmitAmount = (amount: number) => {
        if (amount === 0) {
          showNotification({
            message: translate('payment.enterAmount'),
            error: true,
          });
        } else if (amount > customerAmount) {
          showNotification({
            message: translate('payment.checkSetAmountOnCustomer'),
            error: true,
          });
        } else {
          navigateToPayment(amount, customer);
          closeModal();
        }
      };
      return showModal(
        <SetAmountKeypad
          onSubmit={onSubmitAmount}
          remainingDueAmount={customerAmount}
          processingAmount={customerAmount}
          closeModal={closeModal}
        />,
      );
    },
    [closeModal, navigateToPayment, showModal, showNotification, translate],
  );
  const renderCustomerHistoryItems = useCallback(
    (items: Customer[]) => (
      <TableComponent
        columnSpacing={25}
        columns={[
          {
            title: translate('customerView.name'),
            flex: 0.4,
            alignItems: 'flex-start',
          },
          {
            title: translate('customerView.phone'),
            flex: 0.4,
            alignItems: 'flex-start',
          },
          {
            title: translate('customerView.email'),
            flex: 0.5,
            width: 200,
            alignItems: 'flex-start',
          },
          {
            title: translate('customerView.balance'),
            flex: 0.4,
            alignItems: 'flex-end',
          },
          { title: '', width: 10 },
        ]}
        data={items}
        renderRow={(item: Customer, index: number): React.ReactNode => {
          return (
            <CustomerDataRow
              key={index}
              canSelect
              containerStyle={css(styles.rowStyle)}
              testID={`customer-row-${index}`}
            >
              <Title
                containerStyle={css(styles.customerContainer)}
                labelStyle={css(styles.customerTextStyle)}
                testID={`customer-row-${index}-customer-number`}
              >
                <Text>
                  {item.firstName} {item.lastName}
                </Text>
              </Title>
              <Title
                containerStyle={css(styles.customerContainer)}
                labelStyle={css(styles.customerTextStyle)}
                numberOfLines={1}
                testID={`customer-row-${index}-customer-name`}
              >
                {item.phone}
              </Title>
              <Title
                containerStyle={css(styles.customerContainer)}
                labelStyle={css(styles.customerTextStyle)}
                numberOfLines={1}
                testID={`customer-row-${index}-customer-name`}
              >
                {item.email}
              </Title>
              {item.customerAccountDetails?.accountPayment ? (
                <Title
                  containerStyle={css(styles.customerContainer)}
                  labelStyle={css(
                    item.customerAccountDetails?.currentBalance === 0
                      ? styles.customerBalanceEmptyTextStyle
                      : styles.customerBalanceTextStyle,
                  )}
                  numberOfLines={1}
                  testID={`customer-row-${index}-customer-name`}
                >
                  {formatCurrency(
                    Number(item.customerAccountDetails?.currentBalance),
                  )}
                </Title>
              ) : (
                <Title containerStyle={css(styles.customerContainer)}>
                  <Text>{'N/A'}</Text>
                </Title>
              )}
              <View style={css(styles.actionsContainer)}>
                <IconButton
                  key="0"
                  testID="show-payment-modal"
                  circular
                  icon="usd-circle"
                  color={'successLight'}
                  containerSize={35}
                  iconSize={20}
                  onPress={() => {
                    item.customerAccountDetails?.accountPayment &&
                      onUpdateProcessingAmount(
                        parseFloat(
                          (
                            item.customerAccountDetails?.currentBalance || 0
                          ).toFixed(2),
                        ),
                        item,
                      );
                  }}
                  iconStyle={{ color: theme.colors.success }}
                  containerStyle={css(styles.actionIconContainerStyle)}
                />
              </View>
            </CustomerDataRow>
          );
        }}
        columnContainerStyle={css(styles.columnContainerStyle)}
        normalRows
      />
    ),
    [
      translate,
      css,
      formatCurrency,
      onUpdateProcessingAmount,
      theme.colors.success,
    ],
  );

  return (
    <PaginatedView
      data={data}
      rowHeight={50}
      renderItems={renderCustomerHistoryItems}
      onPressNext={onPressNext}
      hideTotalItemsCount
    />
  );
};

export default CustomerView;
