import { StyleFn } from '@hitz-group/domain';
import { OfficeUser, PosUser } from '../../../state/userUtility';
import { User } from './TimeAndAttendanceScreen';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { Text, TouchableOpacity, View } from 'react-native';
import Avatar from '../../../components/Avatar/Avatar';
import { StoreUser } from '../../../hooks/POSUserRoleProvider';

const posUserNameStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  marginTop: theme.spacing.small,
  lineHeight: theme.spacing.medium,
  color: theme.colors.grey2,
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  textAlign: 'center',
});

const posUserNameFadedStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  marginTop: theme.spacing.small,
  lineHeight: theme.spacing.medium,
  color: theme.colors.greyFade,
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  textAlign: 'center',
});

const userContainerStyle: StyleFn = ({ theme }) => ({
  height: 124,
  width: 126,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.greyLight,
  margin: theme.padding.small,
  alignItems: 'center',
  justifyContent: 'center',
});

const userOverlayStyle: StyleFn = ({ theme }) => ({
  height: 124,
  width: 126,
  borderRadius: theme.radius.small,
  margin: theme.padding.small,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.colors.whiteTransparent,
});

export interface UserProfileTileProps {
  user: OfficeUser | PosUser;
  onSelectUser: (user: OfficeUser | PosUser) => void;
  disabled?: boolean;
  alwaysActive?: boolean;
}
const UserProfileTileComponent: React.FC<UserProfileTileProps> = ({
  user,
  onSelectUser,
  disabled,
  alwaysActive,
}) => {
  const { css } = useFela();

  const onPressUser = useCallback(() => {
    onSelectUser(user);
  }, [onSelectUser, user]);

  const userWorking =
    alwaysActive ||
    ((user as StoreUser).isWorking == true &&
      (user as User).lastWorklog &&
      !disabled);

  return (
    <View>
      <TouchableOpacity style={css(userContainerStyle)} onPress={onPressUser}>
        <Avatar name={user.name} />
        <Text
          nativeID="userName"
          style={
            userWorking ? css(posUserNameStyle) : css(posUserNameFadedStyle)
          }
        >
          {user.name}
        </Text>
        {!userWorking && <View style={css(userOverlayStyle)} />}
      </TouchableOpacity>
    </View>
  );
};

const UserProfileTile = React.memo(UserProfileTileComponent);
UserProfileTile.displayName = 'UserProfileTile';

export default UserProfileTile;
