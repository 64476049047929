import { StyleFn } from '@hitz-group/domain';

import { StyleSheet } from 'react-native';

export const pageContainerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.background,
});

export const catalogStyle: StyleFn = () => ({
  flex: 1,
});

export const headerButtonContainerStyle: StyleFn = () => ({
  width: 280,
  justifyContent: 'flex-start',
});

export const ContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.primaryDarkest,
  marginHorizontal: theme.spacing.small,
  height: 44,
  width: 400,
  justifyContent: 'center',
  alignItems: 'center',
});

export const titleFont: StyleFn = ({ theme }) => ({
  color: '#fff',
  textTransform: 'uppercase',
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.medium,
});

export const styles = StyleSheet.create({
  headerTitle: {
    width: 300,
    height: 40,
  },
  headerRight: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  orderActionButton: {
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    width: 138,
  },
  refundButton: {
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    height: 80,
    width: 280,
  },
  labelStyle: {
    paddingLeft: 8,
  },
});
