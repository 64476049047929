import { StyleFn } from '@hitz-group/domain';
import { Modifier as ModifierDefault } from '@hitz-group/domain';
import React from 'react';
import { View } from 'react-native';
import { useFela } from 'react-fela';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import Button from '../../../../../components/Button/Button';
import TableRow from '../../../../../components/TableComponent/TableRow';
import IconButton from '../../../../../components/Button/IconButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import DropDown from '../../../../../components/DropDown/DropDown';
import {
  getBestPriceOfModifier,
  getTaxFromModifier,
} from '@hitz-group/catalog-helper';
import scale, { isIos } from '../../../../../common/theme';
interface Modifier extends ModifierDefault {
  isSelected?: boolean;
  tax?: string;
  priceAmount?: string;
}

interface ModifiersRowProps {
  modifier: Modifier;
  onChange: (
    id: string,
    prop: string,
    value: string | boolean | string[],
  ) => void;
  modifierGroups: { value: string; label: string }[];
  taxes: { value: string; label: string }[];
  onDeleteRow: (id: string, name: string) => void;
  rowIndex: number;
}

const iconMarginStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small / 2,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 17,
  width: 17,
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 38,
  height: scale.moderateScale(27),
  marginLeft: theme.spacing.small,
});

const dropdownContainerStyle: StyleFn = ({ theme }) => ({
  width: 110,
  height: theme.input.height,
  marginHorizontal: 5,
  bottom: 10,
});
const extraPopoverStyle: StyleFn = () => ({
  width: 110,
});
const itemContainer: StyleFn = () => ({
  height: 38 * 3,
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 17,
  width: 17,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: isIos ? 150 : 200,
  height: theme.input.height,
});

const taxInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 80,
  height: theme.input.height,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  marginLeft: isIos ? 10 : 'auto',
  alignSelf: 'center',
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: isIos ? 250 : 422,
  minHeight: scale.moderateScale(27),
  alignSelf: 'center',
  marginHorizontal: theme.spacing.small,
  justifyContent: 'center',
});

const containerStyle: StyleFn = ({ rowIndex }) => ({
  zIndex: 1000 - 10 * rowIndex,
  minHeight: scale.moderateScale(27),
  alignItems: 'center',
});

const touchableStyle: StyleFn = () => ({
  minHeight: 40,
  flexWrap: 'wrap',
});
const selectStyle: StyleFn = () => ({
  paddingVertical: 0,
});
const container: StyleFn = () => ({
  justifyContent: 'flex-start',
});

export const ModifiersRow: React.FC<ModifiersRowProps> = ({
  modifier,
  onChange,
  modifierGroups,
  taxes,
  onDeleteRow,
  rowIndex,
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const price =
    modifier?.priceAmount !== undefined
      ? modifier?.priceAmount
      : getBestPriceOfModifier(modifier);
  const tax = modifier?.tax || getTaxFromModifier(modifier)?.[0]?.id;
  const { appendCurrency } = useCurrency();
  return (
    <TableRow
      normalRows
      columnSpacing={theme.spacing.big}
      actionStyle={css(iconMarginStyle)}
      containerStyle={css(containerStyle({ theme, rowIndex }))}
    >
      <View style={css(container)}>
        <Button
          onPress={() =>
            onChange(modifier.id, 'isSelected', !modifier?.isSelected)
          }
          fluid
          iconPosition={'left'}
          containerStyle={css(checkBoxTitleContainer)}
          iconContainerStyle={
            modifier?.isSelected
              ? css(checkIconContainer)
              : css(unCheckContainer)
          }
          icon={modifier?.isSelected ? 'check' : 'null'}
          iconProps={{
            color: theme.colors.success,
            size: 15,
          }}
        />
      </View>
      <View style={css(container)}>
        <FormInput
          testID="modifier-name"
          placeholder={translate('modifiers.modifierName')}
          value={modifier.name}
          containerStyle={css(formInputContainerStyle)}
          onChangeText={onChange.bind(null, modifier.id, 'name')}
          textStyle={css(formTextStyle)}
          maxLength={50}
        />
      </View>
      <MultipleSelect
        values={modifierGroups}
        selectedValues={(modifier.modifierGroups || [])?.map(
          x => x?.id || (x as unknown as string),
        )}
        containerStyle={css(dropDownStyle)}
        onValueChange={onChange.bind(null, modifier.id, 'modifierGroups')}
        searchLabel={translate('modifiers.modifierGroups')}
        searchPlaceHolder={translate('modifiers.searchModifierGroups')}
        maxItems={3}
        touchableStyle={css(touchableStyle)}
        selectedItemStyle={css(selectStyle)}
      />
      <View style={css(flexStyle)} />
      <View style={css(container)}>
        <FormInput
          testID="price"
          placeholder={translate('backOfficeProducts.productPrice')}
          value={appendCurrency((price || '').toString())}
          containerStyle={css(taxInputContainerStyle)}
          onChangeText={onChange.bind(null, modifier.id, 'priceAmount')}
          textStyle={css(formTextStyle)}
        />
      </View>
      <View style={css(container)}>
        <DropDown
          options={taxes}
          selectedValue={tax || ''}
          containerStyle={css(dropdownContainerStyle)}
          onValueChange={onChange.bind(null, modifier.id, 'tax')}
          itemsContainerStyle={css(itemContainer)}
          extraPopoverStyle={css(extraPopoverStyle)}
        />
      </View>

      <IconButton
        icon="TrashAlt"
        iconSize={24}
        containerSize={34}
        containerStyle={css(closeIconContainerStyle)}
        iconStyle={css(closeIconStyle)}
        onPress={() => onDeleteRow(modifier.id, modifier.name)}
      />
    </TableRow>
  );
};
