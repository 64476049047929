import React, { createContext } from 'react';
import { PriceListDetailsTab } from './PriceListDetailsTab';
import { ProductPriceTab } from './PriceProductsTab/ProductPriceTab';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
const Tab = createMaterialTopTabNavigator();

interface RouteParams {
  pricingGroupId: string;
  screen?: string;
  isDefault?: boolean;
  isNavigatedFromCreate: boolean;
}
export const PriceListContext = createContext({} as { params: RouteParams });

const PriceListTabNavigator: React.FC = () => {
  const route = useRoute();
  const params = route.params as RouteParams;

  return (
    <PriceListContext.Provider value={{ params }}>
      <Tab.Navigator
        tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
          <TabBar tabBar={props as MaterialTopTabBarProps} />
        )}
        backBehavior="none"
        initialRouteName={params?.screen || 'Details'}
      >
        <Tab.Screen
          name="Details"
          component={PriceListDetailsTab}
          initialParams={params}
        />
        <Tab.Screen
          name="Products"
          component={ProductPriceTab}
          initialParams={params}
        />
      </Tab.Navigator>
    </PriceListContext.Provider>
  );
};

export default PriceListTabNavigator;
