import { ApolloClient, ApolloProvider, makeVar } from '@apollo/client';
import { GQLClient, SubscriptionState } from '@hitz-group/gql-client';
import { Locale, LocalizationProvider } from '@hitz-group/localization';
import { useNetInfo } from '@react-native-community/netinfo';
import { persistCache } from 'apollo3-cache-persist';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-fela';
import { I18nManager } from 'react-native';
import { skip, Subscription } from 'rxjs';
import { APOLLO_CACHE_PERSIST_KEY } from './constants';
import './devConfig';
import { resolvers } from './graphql';
import { authFlowLink, SERVICE_URI } from './graphql/authFlowLink';
import {
  netWorkStatus,
  subscriptionStatus,
} from './hooks/app/useNetworkStatusVar';
import { useSettings } from './hooks/app/useSettings';
import { IntercomProvider } from './hooks/Intercom/IntercomProvider';
import { NotificationProvider } from './hooks/Notification';
import OfficeUserAuthorizationProvider from './hooks/OfficeUserAuthorizationProvider';
import OfficeUserRoleProvider from './hooks/OfficeUserRoleProvider';
import RoleProvider from './hooks/RoleProvider';
import { SessionProvider } from './hooks/SessionProvider';
import Navigator from './Navigator';
import { LoadingScreen } from './screens/Loading/Loading';
import { workerInstanceVar } from './state/cache';
import * as settings from './state/preferences';
import { Session } from './state/Session';
import { subscriptionStateUtil } from './state/subscriptionStateUtils';
import { AuthState, tokenUtility } from './state/tokenUtility';
import { ApolloStorageWrapper } from './storage/ApolloStorageWrapper';
import Database from './storage/database';
import * as storage from './storage/interface';
import { getApolloCache } from './utils/apolloClient';
import { identifyLogRocketUser } from './utils/logRocketHelper';
import { loadTheme } from './utils/ThemeFactory';
import BackgroundWorker from './workers/BackgroundWorker';

const DEFAULT_COUNTRY_CODE = 'AU';
const DEFAULT_THEME = 'light';
export const changeDueVar = makeVar<number | null>(null);

const App: React.FC = () => {
  // Cache the value
  useSettings('orderCounter');
  const [theme] = useSettings<string>('theme');
  const [locale, setLocale] = useState<Locale>();
  const [country, setCountry] = useState(DEFAULT_COUNTRY_CODE);
  const [client, setClient] = useState<ApolloClient<object> | undefined>(
    undefined,
  );
  const subscriptionStateRef = useRef<Subscription>();

  const onLocaleChange = useCallback((locale: Locale | undefined) => {
    if (locale) {
      setLocale(locale);
      I18nManager.forceRTL(locale.isRTL || false);
    }
  }, []);

  const onSessionChange = useCallback(
    (session: Partial<Session> | undefined) => {
      setCountry(session?.currentOrganization?.country ?? DEFAULT_COUNTRY_CODE);
    },
    [],
  );

  useEffect(() => {
    tokenUtility.setTokenInfo({ authState: AuthState.LOADING });
    const worker = new BackgroundWorker();
    if (worker.worker) workerInstanceVar(worker);
    return () => {
      if (worker.worker) worker.worker.terminate();
    };
  }, []);

  useEffect(() => {
    settings.getSession().then(session => {
      if (session?.user?.id) identifyLogRocketUser(session);
    });
  }, []);

  useEffect(() => {
    settings.getLocale().then(onLocaleChange);
    settings.getSession().then(onSessionChange);
    storage.addSubscription(settings.LOCALE_KEY, onLocaleChange);
    storage.addSubscription(settings.SESSION_KEY, onSessionChange);
    return () => {
      storage.removeSubscription(settings.LOCALE_KEY, onLocaleChange);
      storage.removeSubscription(settings.SESSION_KEY, onSessionChange);
    };
  }, [onLocaleChange, onSessionChange]);

  // On component mount, restore cache
  // Meanwhile app renders a loading screen
  useEffect(() => {
    const cache = getApolloCache();

    const getSession = async () => {
      return await settings.getSession();
    };

    const client = new GQLClient(
      {
        cache,
        resolvers,
        middlewares: authFlowLink,
        service: SERVICE_URI,
        getSession,
        client: 'pos-app',
        version: '1.0',
      },
      true,
      {
        onChangeSubscriptionState: subscriptionStateUtil.updateState,
      },
    ).getApolloClient();

    // Waiting for database initialization
    Database.init().then(async dbInstance =>
      // await before instantiating ApolloClient, else queries might run before the cache is persisted
      persistCache({
        cache,
        key: APOLLO_CACHE_PERSIST_KEY,
        storage: new ApolloStorageWrapper(dbInstance),
      }).then(() => {
        setClient(client);
      }),
    );
  }, []);

  useEffect(() => {
    if (!subscriptionStateRef.current) {
      // Use subscription connection status to determine the network state
      // low latency & reliable comparing with useNetInfo depended on the system
      subscriptionStateRef.current = subscriptionStateUtil.getSubscriptionState$
        .pipe(skip(1))
        .subscribe((state: SubscriptionState) => {
          subscriptionStatus(state === SubscriptionState.CONNECTING);
        });
    }

    return () => {
      subscriptionStateRef.current?.unsubscribe?.();
      subscriptionStateRef.current = undefined;
    };
  }, []);

  const netInfo = useNetInfo();
  useEffect(() => {
    netWorkStatus(!!netInfo?.isConnected && !!netInfo?.isInternetReachable);
  }, [netInfo]);

  return (
    <ThemeProvider theme={loadTheme(theme || DEFAULT_THEME)}>
      {client && locale ? (
        <LocalizationProvider locale={locale} country={country}>
          <SessionProvider>
            <ApolloProvider client={client}>
              <NotificationProvider>
                <IntercomProvider>
                  <RoleProvider>
                    <OfficeUserRoleProvider>
                      <OfficeUserAuthorizationProvider>
                        <Navigator />
                      </OfficeUserAuthorizationProvider>
                    </OfficeUserRoleProvider>
                  </RoleProvider>
                </IntercomProvider>
              </NotificationProvider>
            </ApolloProvider>
          </SessionProvider>
        </LocalizationProvider>
      ) : (
        <LoadingScreen />
      )}
    </ThemeProvider>
  );
};

export default App;
