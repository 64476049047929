import { StyleFn } from '@hitz-group/domain';
import React, { useState } from 'react';
import { useFela } from 'react-fela';
import DropDown from '../../../../../components/FormInput/DropDown';
import { useTranslation } from '@hitz-group/localization';
import Modal from '../../../../../components/Modals/Modal';
import Button from '../../../../../components/Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';
import { View } from 'react-native';

interface SetTaxProps {
  taxOptions: { label: string; value: string }[];
  onSubmit: (selectedTax: string) => void;
  countOfproducts?: number;
}

const createModalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const dropDownStyle: StyleFn = () => ({
  width: 280,
  height: 38,
});

const dismissButtonStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.white,
  marginTop: theme.spacing.big,
  zIndex: 200,
  marginLeft: 'auto',
});

const buttonStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
  marginTop: theme.spacing.big,
  zIndex: 200,
  marginLeft: 'auto',
});

const assignLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
});

const dismissLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
});

const buttonsWrapper: StyleFn = () => ({
  flexDirection: 'row',
});

const headerTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

export const SetTax: React.FC<SetTaxProps> = ({
  onSubmit,
  taxOptions,
}: SetTaxProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const defaultTax = taxOptions?.[0]?.value || '';
  const [selectedTax, setSelectedTax] = useState(defaultTax);

  const { closeModal } = useModal();
  return (
    <Modal
      title={translate('productBulkOperations.assignTax')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
      headerTextStyle={css(headerTextStyle)}
    >
      <DropDown
        title={translate('productBulkOperations.selectTax')}
        values={taxOptions}
        selectedValue={selectedTax}
        style={css(dropDownStyle)}
        onValueChange={val => setSelectedTax(val)}
      />
      <View style={css(buttonsWrapper)}>
        <Button
          title={translate('productBulkOperations.dismiss')}
          containerStyle={css(dismissButtonStyle)}
          labelStyle={css(dismissLabelStyle)}
          onPress={closeModal}
        />
        <Button
          title={translate('productBulkOperations.assign')}
          containerStyle={css(buttonStyle)}
          labelStyle={css(assignLabelStyle)}
          onPress={() => onSubmit(selectedTax)}
        />
      </View>
    </Modal>
  );
};
