import { useTranslation } from '@hitz-group/localization';
import React, { useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { Platform, ScrollView, View, Text } from 'react-native';
import { useModal } from '@hitz-group/rn-use-modal';
import { useNotification } from '../../../../hooks/Notification';
import * as styles from './style';
import Modal from '../../../../../src/components/Modals/Modal';
import IconButton from '../../../../../src/components/Button/IconButton';
import Button from '../../../../../src/components/Button/Button';
import { usePaymentTypes } from '../../../../../src/hooks/app/usePaymentTypes';
import TitleBar from '../../../../../src/components/TitleBar/TitleBar';
import Pagination from '../../../../components/Catalog/GridViewPagination';
import { PaymentType, StyleFn } from '@hitz-group/domain';
import { useSession } from '../../../../../src/hooks/app/useSession';
import { OOLIO_PAY_PAYMENT_TYPE } from '../../../../../src/types/Common';
import LoadingIndicator from '../../../../../src/components/LoadingIndicator/LoadingIndicator';
import { isOolioPayRefundLoading } from '../../../../state/cache';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { useIsMounted } from '../../../../useIsMounted';

const loadingContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: 100,
  height: 50,
  overflow: 'hidden',
  borderRadius: theme.radius.small,
});

const loadingTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing.small / 2,
  color: theme.colors.paragraph,
  fontFamily: theme.font.medium,
  lineHeight: 21,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.larger,
});

interface OrderHistoryModalProps {
  showOolioPay?: boolean;
  onPressRefundPayType: (paymentType: PaymentType) => void;
}

const paginationContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 10,
  height: 44,
});

const RefundPaymentTypesModal: React.FC<OrderHistoryModalProps> = ({
  showOolioPay = false,
  onPressRefundPayType,
}) => {
  const [ref, setRef] = useState<ScrollView | null>(null);
  const [btnYPos, setBtnYPos] = useState<{ [key: number]: number }>({});
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { css, theme } = useFela();
  const [session] = useSession();
  const showPagination =
    Platform.OS == 'web' && session?.settings?.showScrollSetting;
  const {
    paymentTypes,
    status: { error },
  } = usePaymentTypes();
  const processingRefund = useReactiveVar<boolean>(isOolioPayRefundLoading);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const scrollNext = () => {
    ref?.scrollTo({
      x: 0,
      y: btnYPos[currentPage + 1],
      animated: true,
    });
    setCurrentPage(cp => (cp += 1));
  };

  const scrollBack = () => {
    ref?.scrollTo({
      x: 0,
      y: btnYPos[currentPage - 1],
      animated: true,
    });
    setCurrentPage(cp => (cp -= 1));
  };

  const titleLeft = (
    <IconButton
      icon="times"
      containerStyle={css(styles.cancelButtonStyle)}
      onPress={closeModal}
    />
  );

  const allowedPaymentTypes = paymentTypes.filter(type => {
    if (type.name === OOLIO_PAY_PAYMENT_TYPE) {
      if (showOolioPay) return true;
    } else {
      return true;
    }
  });

  return (
    <View style={css(styles.modalContainer)}>
      <TitleBar
        primary
        title={translate('refundOrder.refundPayType')}
        titleLeft={titleLeft}
        containerStyle={css(styles.headerStyle)}
      />
      <Modal
        contentStyle={css(styles.modalStyle)}
        customBodyStyle={styles.customBodyStyle}
        showCloseButton={false}
      >
        <ScrollView
          showsHorizontalScrollIndicator={false}
          style={css(styles.scrollViewMaxHeight)}
          ref={ref => setRef(ref)}
        >
          {processingRefund ? (
            <>
              <View
                style={css(loadingContainerStyle)}
                testID="payment-loader-view"
              >
                <LoadingIndicator size={'large'} testID={'payment-loader'} />
              </View>
              <View testID={'payment-processing-text'}>
                <Text style={css(loadingTextStyle)}>
                  {translate('payment.processingRefund')}
                </Text>
              </View>
            </>
          ) : (
            <View style={css(styles.buttonOptionsContainerStyle)}>
              {allowedPaymentTypes.map((type, index) => {
                return (
                  <View
                    testID="on-layout"
                    onLayout={event => {
                      const layout = event.nativeEvent.layout;
                      if (index % 4 === 0) {
                        isMounted() &&
                          setBtnYPos(btnYPos => ({
                            ...btnYPos,
                            [index / 4 + 1]: layout.y,
                          }));
                        isMounted() && setTotalPages(tp => (tp += 1));
                      }
                    }}
                    style={css(styles.optionButtonStyle)}
                    key={index}
                  >
                    <Button
                      testID={type.name}
                      size="large"
                      containerStyle={css(styles.btnStyle)}
                      labelStyle={{
                        color: theme.colors.black,
                      }}
                      title={type.name}
                      onPress={() => onPressRefundPayType(type)}
                    />
                  </View>
                );
              })}
            </View>
          )}
        </ScrollView>
        {showPagination ? (
          <Pagination
            testID={'pagination-component'}
            onPressNext={scrollNext}
            onPressBack={scrollBack}
            scrollDirection="vertical"
            currentPage={currentPage}
            totalPages={totalPages}
            containerStyle={css(paginationContainerStyle)}
          />
        ) : null}
      </Modal>
    </View>
  );
};

export default RefundPaymentTypesModal;
