import { PrinterProfileType } from '@hitz-group/domain';
import {
  PrinterConfig,
  validatePrintConfigurationOrInput,
} from '../../../utils/printerTemplates/printingDataUtils';
import { Notification } from '../../../hooks/Notification';
import {
  BufferHandlerResult,
  DEFAULT_NET_PRINTER_PORT,
  PrintShiftWorkerInput,
  WorkerInput,
} from '../../utils';
import { getPrintableBuffer } from '../../../utils/printerTemplates/shiftTemplate';

const printShiftHandler = (message: WorkerInput): BufferHandlerResult[] => {
  const { shift, printerTemplateMapping, session, currency } =
    message.data as PrintShiftWorkerInput;

  const data = validatePrintConfigurationOrInput(
    undefined,
    printerTemplateMapping,
    PrinterProfileType.BILLING,
  );

  if (Object.keys(data).length > 0 && (data as Notification).message) {
    throw new Error((data as Notification).message);
  }

  const { printer } = data as PrinterConfig;
  const buffer = getPrintableBuffer(shift, session, currency);

  return [
    {
      printer: {
        id: printer.id,
        device_name: printer.name,
        host: printer.ipAddress,
        port: DEFAULT_NET_PRINTER_PORT,
        type: printer.printerType,
        bdAddress: printer.bdAddress,
        brand: printer.brand,
      },
      buffer,
    },
  ];
};
export default printShiftHandler;
