import { StyleFn } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import SearchBar from '../../../../../components/SearchBar/SearchBar';
import DropDown from '../../../../../components/DropDown/DropDown';
import { useTranslation } from '@hitz-group/localization';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { Modifier as ModifierAlias } from '@hitz-group/domain';

interface Modifier extends ModifierAlias {
  isChanged?: boolean;
}

interface LabelFilter {
  value: string;
  label: string;
}

interface ModifierRowProps {
  modifierList: Modifier[];
  modifierGroups: LabelFilter[];
  onFilterChange: (modifierData: Modifier[]) => void;
  onPressCreate: () => void;
}

export const getModifiersBySearchQuery = (
  modifiers: Modifier[],
  searchQuery: string,
): Modifier[] => {
  return modifiers.filter(modifier =>
    modifier.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
};

export const getModifiersByFilteredModifierGroup = (
  modifiers: Modifier[],
  selectedModifierGroup: string,
): Modifier[] => {
  return modifiers.filter(
    modifier =>
      modifier.modifierGroups?.filter(
        modGroup => modGroup.id == selectedModifierGroup,
      ).length,
  );
};

const titleActionStyle: StyleFn = () => ({
  flexDirection: 'row',
});

const dropdownInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 150,
  bottom: 10,
});
const extraPopoverStyle: StyleFn = () => ({
  width: 150,
});
const itemContainer: StyleFn = () => ({
  height: 40 * 2,
});

const searchContainerStyle: StyleFn = ({ theme }) => ({
  flexGrow: 1,
  width: 250,
  height: theme.input.height,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  paddingLeft: theme.padding.medium / 2,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  marginHorizontal: theme.spacing.medium,
  marginBottom: theme.spacing.small,
});

const searchTextInputStyle: StyleFn = () => ({
  width: '100%',
  flex: 1,
});

export const ModifierHeaderFilters: React.FC<ModifierRowProps> = ({
  modifierList,
  modifierGroups,
  onFilterChange,
  onPressCreate,
}: ModifierRowProps) => {
  const { css, theme } = useFela();
  const [searchString, setSearchString] = useState('');
  const [selectedModifierGroup, setSelectedModifierGroup] = useState('');
  const { translate } = useTranslation();

  const PageData = useCallback(
    (searchString, selectedModifierGroup) => {
      let modifiersDataInPage: Modifier[] = modifierList;
      if (searchString) {
        modifiersDataInPage = getModifiersBySearchQuery(
          modifiersDataInPage,
          searchString,
        );
      }
      if (selectedModifierGroup) {
        modifiersDataInPage = getModifiersByFilteredModifierGroup(
          modifiersDataInPage,
          selectedModifierGroup,
        );
      }
      return modifiersDataInPage;
    },
    [modifierList],
  );

  const onSearchTextChange = useCallback(
    (value: string) => {
      setSearchString(value);
      onFilterChange(PageData(value, selectedModifierGroup));
    },
    [selectedModifierGroup, onFilterChange, PageData],
  );

  const onModifierGroupChange = useCallback(
    (value: string) => {
      setSelectedModifierGroup(value);
      onFilterChange(PageData(searchString, value));
    },
    [searchString, onFilterChange, PageData],
  );

  return (
    <View style={css(titleActionStyle)}>
      <DropDown
        options={[
          {
            label: translate('modifiers.allGroups'),
            value: '',
          },
        ].concat(...(modifierGroups || []))}
        selectedValue={selectedModifierGroup}
        containerStyle={css(dropdownInputStyle)}
        onValueChange={onModifierGroupChange}
        itemsContainerStyle={css(itemContainer)}
        extraPopoverStyle={css(extraPopoverStyle)}
      />

      <SearchBar
        placeholder={translate('modifiers.searchModifierByName')}
        containerStyle={css(searchContainerStyle)}
        textInputStyle={css(searchTextInputStyle)}
        iconColor={theme.colors.paragraph}
        placeholderColor={theme.colors.paragraph}
        onChange={onSearchTextChange}
      />

      <BackOfficeCreateNewButton onPress={onPressCreate} />
    </View>
  );
};
