import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import Button from '../../Button/Button';
import { useNavigation } from '@react-navigation/native';
import { useModal } from '@hitz-group/rn-use-modal';
export interface CloseShiftModalProps {
  onClose?: () => void;
}

const modalStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  elevation: 5,
  borderColor: theme.colors.boxBorder,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  backgroundColor: theme.colors.white,
  alignSelf: 'center',
  justifyContent: 'center',
  padding: theme.padding.medium * 3.5,
  width: 690,
  height: 266,
});
const textStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  textAlign: 'center',
  lineHeight: 24,
  width: 500,
  marginTop: theme.spacing.big,
  alignSelf: 'center',
  marginBottom: theme.spacing.big + theme.spacing.medium,
});
const buttonContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
});
const buttonStyle: StyleFn = () => ({
  width: 200,
});
const cancelButtonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.background,
  color: theme.colors.textLight,
});

const CloseShiftModal: React.FC<CloseShiftModalProps> = ({
  onClose,
}: CloseShiftModalProps) => {
  const { translate } = useTranslation();
  const { css } = useFela();
  const { navigate } = useNavigation();
  const { closeModal } = useModal();

  const navigateToOpenOrders = useCallback(() => {
    navigate('Orders', { screen: 'OpenOrders' });
    closeModal();
  }, [navigate, closeModal]);
  return (
    <View style={css(modalStyle)}>
      <Text style={css(textStyle)} testID={'shift-pendingOrdersMessage'}>
        {translate('shift.pendingOrdersMessage')}
      </Text>
      <View style={css(buttonContainerStyle)}>
        <Button
          testID={'shift-cancel'}
          title={translate('shift.cancel')}
          size="small"
          onPress={closeModal}
          containerStyle={css(cancelButtonStyle)}
        />
        <Button
          testID={'shift-viewOpenOrders'}
          title={translate('shift.viewOpenOrders')}
          secondary
          size="small"
          containerStyle={css(buttonStyle)}
          onPress={navigateToOpenOrders}
        />
        <Button
          testID={'shift-close'}
          title={translate('shift.close')}
          success
          size="small"
          onPress={onClose}
        />
      </View>
    </View>
  );
};

export default CloseShiftModal;
