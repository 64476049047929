import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';

const usePrinterOptionsStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        mainView: {
          backgroundColor: theme.colors.white,
          flex: 1,
          paddingHorizontal: theme.padding.large,
        },
        columnContainerStyle: {
          flex: 1,
          paddingLeft: theme.spacing.small,
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          borderBottomWidth: 0,
          marginTop: theme.spacing.medium,
        },
        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
          flexDirection: 'row-reverse',
        },
        saveButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginRight: theme.spacing.medium,
          alignSelf: 'center',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
        noKitchenPrinterProfile: {
          textAlign: 'center',
        },
      }),
    [theme],
  );
};

export default usePrinterOptionsStyles;
