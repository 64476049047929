import React, { useMemo, useState, useCallback } from 'react';
import { View, ScrollView } from 'react-native';
import { withTheme } from 'react-fela';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import { keyBy } from 'lodash';
import SelectBar from '../../../../../components/Button/SelectBar';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';

export interface OrderViewPanelProps {
  showPanel: boolean;
  customHeader?: React.ReactChild;
  tabOptions: { title: string; value: string; component: React.ReactChild }[];
  selectedValue: string;
  loading?: boolean;
}

const container: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.border,
  position: 'absolute',
  right: 0,
  width: 330,
  height: '100%',
  shadowOffset: { width: 3, height: 4 },
  shadowOpacity: 0.4,
  shadowColor: theme.colors.Black,
  shadowRadius: 5,
  elevation: 5,
});
const mainContainer: StyleFn = ({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  backgroundColor: theme.colors.blackTransparent02,
});

const scrollViewContent: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  alignItems: 'center',
  height: '100%',
});

const headerTitleStyle: StyleFn = ({ count, theme }) => ({
  width: 125 * count,
  height: 30,
  borderRadius: theme.radius.small,
  marginBottom: theme.spacing.medium,
  marginTop: theme.spacing.small,
});

const OrderViewPanel: React.FC<OrderViewPanelProps> = ({
  showPanel,
  customHeader,
  tabOptions,
  loading,
}: OrderViewPanelProps) => {
  const { css, theme } = useFela();
  const [selectedTab, setSelectedTab] = useState(tabOptions?.[0]?.value || '');

  const tabOptionsMap = useMemo(() => keyBy(tabOptions, 'value'), [tabOptions]);

  const selectedTabContainer = useMemo(
    () => tabOptionsMap[selectedTab]?.component || <></>,
    [selectedTab, tabOptionsMap],
  );

  const onChangeTabs = useCallback(value => {
    setSelectedTab(value);
  }, []);

  const headerTitle = useMemo(
    () => (
      <View style={css(headerTitleStyle({ theme, count: tabOptions.length }))}>
        <SelectBar
          options={tabOptions.map(x => ({ label: x.title, value: x.value }))}
          onPress={onChangeTabs}
          selectedOption={selectedTab}
        />
      </View>
    ),
    [css, tabOptions, theme, selectedTab, onChangeTabs],
  );

  if (!showPanel) {
    return <></>;
  }

  return (
    <View style={css([mainContainer])}>
      <View style={css(container)}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            {customHeader}

            <ScrollView
              contentContainerStyle={css(scrollViewContent)}
              testID={'child-render-section'}
            >
              {headerTitle}
              {selectedTabContainer}
            </ScrollView>
          </>
        )}
      </View>
    </View>
  );
};

export default withTheme(OrderViewPanel);
