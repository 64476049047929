/* eslint-disable react-native/no-inline-styles */
import { Tax, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useMemo } from 'react';
import { View, ScrollView } from 'react-native';
import { Helmet } from 'react-helmet';
import scale, { isWeb } from '../../../../../../common/theme';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../../../components/FormInput/FormInput';
import {
  useTranslation,
  getCountry,
  listCountries,
} from '@hitz-group/localization';
import Button from '../../../../../../components/Button/Button';
import DropDown from '../../../../../../components/FormInput/DropDown';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import { DetailsStyles } from '../../styles/Details.styles';
import ConfirmationDialog from '../../../../../../components/Modals/ConfirmationDialog';
import { useNotification } from '../../../../../../hooks/Notification';
import { isNotEmpty } from '../../../../../../utils/validator';
import { useFela } from 'react-fela';
import { VenueDetail } from '../DetailsContainer';

interface Props {
  testID?: string;
  form: VenueDetail;
  taxes: Tax[];
  onChange: (prop: string, value: string) => void;
  onChangeAddress: (prop: string, value: string) => void;
  onChangePrintOptions: (prop: string, value: boolean) => void;
  onChangeEmailReceipt: (value: string) => void;
  onPressDelete: () => void;
  onPressSave: () => void;
}

const phoneContainer: StyleFn = () => ({
  width: isWeb ? scale.moderateScale(180) : '50%',
  left: 10,
});

const phoneContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  marginBottom: theme.spacing.big / 2,
  alignSelf: 'flex-end',
});

const phoneContainerInputStyle: StyleFn = () => ({
  width: 180,
});

const phonePrefixStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  minWidth: scale.moderateScale(25),
  textAlign: 'left',
  paddingLeft: scale.moderateScale(5),
  ...theme.font14RegularCharcoal,
});

export const Details: React.FC<Props> = ({
  form,
  taxes,
  onChange,
  onChangeAddress,
  onPressDelete,
  onPressSave,
}) => {
  const { translate } = useTranslation();
  const styles = DetailsStyles();
  const { showNotification } = useNotification();
  const { css, theme } = useFela();
  const { showModal, closeModal } = useModal();
  const onDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', {
          label: form.name,
        })}
        onConfirm={() => {
          closeModal();
          onPressDelete();
        }}
      />,
    );
  }, [showModal, onPressDelete, closeModal, translate, form]);

  const onSave = useCallback((): void => {
    if (!form.name || !isNotEmpty(form.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.VenueNameIsRequired'),
      });
      return;
    }
    onPressSave();
  }, [showNotification, translate, form, onPressSave]);

  const countryList = useMemo(
    () =>
      listCountries().map(country => ({
        label: country.name,
        value: country.name,
      })),
    [],
  );
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={styles.pageStyle}>
        <ScrollView
          testID="general-settings-screen"
          contentContainerStyle={styles.generalSettingsContainerWrapper}
        >
          <BackOfficeSection
            title={translate('backOfficeSettings.venueDetails')}
            contentContainerStyle={styles.formContainerStyleSmall}
            containerStyle={styles.backOfficeContainerStyle}
            iconColor={theme.colors.blue}
            icon={'info-circle'}
          >
            <FormInput
              error={false}
              testID="venue-name"
              placeholder={translate('form.venueName')}
              title={translate('form.venueName')}
              value={form.name || ''}
              alignTitle="left"
              containerStyle={styles.formInputContainerStyle}
              onChangeText={onChange.bind(null, 'name')}
              textInputStyle={styles.formLargeTextStyle}
              textStyle={styles.labelStyle}
              maxLength={50}
            />
            <DropDown
              title={translate('form.defaultTax')}
              values={taxes.map(value => ({
                value: value.id,
                label: value.name,
              }))}
              textStyle={isWeb ? {} : styles.dropLabelStyle}
              selectedValue={form.defaultTax || ''}
              testID="default-tax"
              extraMainViewStyle={styles.dropDownMainViewStyle}
              extraStyle={styles.dropdownExtraStyle}
              extraViewStyle={styles.dropdownViewStyle}
              style={styles.formInputContainerStyle}
              onValueChange={onChange.bind(null, 'defaultTax')}
            />
            <FormInput
              error={false}
              testID="email"
              placeholder={translate('form.email')}
              title={translate('form.email')}
              value={form.email || ''}
              alignTitle="left"
              containerStyle={styles.formInputContainerStyle}
              onChangeText={onChange.bind(null, 'email')}
              textInputStyle={styles.formLargeTextStyle}
              textStyle={styles.labelStyle}
              maxLength={50}
            />
            <View style={css(phoneContainer)}>
              <FormInput
                testID="phone-number"
                placeholder={translate('form.phoneNumberHint')}
                title={translate('form.phoneNumber')}
                value={form.phoneNumber || ''}
                alignTitle="left"
                containerStyle={css(phoneContainerStyle({ theme }))}
                onChangeText={text => onChange('phoneNumber', text)}
                type="phone"
                maxLength={10}
                textInputStyle={css(phoneContainerInputStyle({ theme }))}
                country={form.countryCode}
                onSelectChange={country => onChange('countryCode', country)}
                onPressCountry={country => onChange('countryCode', country)}
                prefix={{
                  text: getCountry(form.countryCode)?.phone,
                  textStyle: css(phonePrefixStyle({ theme })),
                }}
                textStyle={isWeb ? {} : styles.dropLabelStyle}
              />
            </View>
            <FormInput
              error={false}
              testID="addressLine"
              placeholder={translate('form.addressLine1')}
              title={translate('form.addressLine1')}
              value={form.address?.line1 || ''}
              alignTitle="left"
              containerStyle={styles.formInputContainerStyle}
              onChangeText={onChangeAddress.bind(null, 'line1')}
              textInputStyle={styles.formLargeTextStyle}
              textStyle={styles.labelStyle}
              maxLength={50}
            />
            <FormInput
              error={false}
              testID="suburb"
              placeholder={translate('form.addressLine2')}
              title={translate('form.addressLine2')}
              value={form.address?.line2 || ''}
              alignTitle="left"
              containerStyle={styles.formInputSuburbContainerStyle}
              onChangeText={onChangeAddress.bind(null, 'line2')}
              textInputStyle={styles.formLargeTextStyle}
              textStyle={styles.dropLabelStyle}
              maxLength={50}
            />
            <FormInput
              error={false}
              testID="city"
              placeholder={translate('form.city')}
              title={translate('form.city')}
              value={form.address?.city || ''}
              alignTitle="left"
              containerStyle={styles.formInputContainerStyle}
              textStyle={styles.labelStyle}
              onChangeText={onChangeAddress.bind(null, 'city')}
              textInputStyle={styles.formLargeTextStyle}
              maxLength={50}
            />
            <FormInput
              error={false}
              title={translate('form.state')}
              placeholder={translate('form.state')}
              value={form.address?.state || ''}
              onChangeText={onChangeAddress.bind(null, 'state')}
              testID="state"
              containerStyle={styles.formInputSuburbContainerStyle}
              textStyle={styles.labelStyle}
              textInputStyle={styles.formLargeTextStyle}
              maxLength={50}
            />

            <FormInput
              type="number"
              error={false}
              testID="postCode"
              placeholder={translate('form.postCode')}
              title={translate('form.postCode')}
              value={form.address?.postalCode || ''}
              alignTitle="left"
              containerStyle={styles.postCodeFieldContainerStyle}
              onChangeText={onChangeAddress.bind(null, 'postalCode')}
              textInputStyle={styles.postFieldTextInputStyle}
              textStyle={styles.dropLabelStyle}
              maxLength={6}
            />

            <DropDown
              title={translate('form.country')}
              values={countryList.map(item => ({
                value: item.value,
                label: item.label,
              }))}
              extraMainViewStyle={styles.dropDownMainViewStyle}
              extraStyle={styles.dropdownExtraStyle}
              extraViewStyle={styles.dropdownViewCountryStyle}
              selectedValue={form.address?.country}
              style={styles.formInputContainerStyle}
              onValueChange={onChangeAddress.bind(null, 'country')}
              textStyle={isWeb ? {} : styles.dropLabelStyle}
            />
          </BackOfficeSection>

          {/* <BackOfficeSection
            title={translate('backOfficeSettings.receiptPreferences')}
            contentContainerStyle={styles.formContainerStyleSmall}
            containerStyle={styles.backOfficeContainerStyle}
            titleBorderBottom
          >
            <Button
              title={translate('backOfficeSettings.print$0Modifiers')}
              labelStyle={styles.checkBoxTitleStyle}
              onPress={onChangePrintOptions.bind(
                null,
                'printModifiersWithNoCharge',
                !form.receiptOptions?.printOptions?.printModifiersWithNoCharge,
              )}
              fluid
              iconPosition={'left'}
              containerStyle={styles.checkBoxTitleContainer}
              iconContainerStyle={
                form.receiptOptions?.printOptions?.printModifiersWithNoCharge
                  ? styles.checkIconContainer
                  : styles.unCheckContainer
              }
              icon={
                form.receiptOptions?.printOptions?.printModifiersWithNoCharge
                  ? 'check'
                  : 'null'
              }
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            />

            <Button
              title={translate('backOfficeSettings.printCustomerDetails')}
              labelStyle={styles.checkBoxTitleStyle}
              onPress={onChangePrintOptions.bind(
                null,
                'printCustomer',
                !form.receiptOptions?.printOptions?.printCustomer,
              )}
              fluid
              iconPosition={'left'}
              containerStyle={styles.checkBoxTitleContainer}
              iconContainerStyle={
                form.receiptOptions?.printOptions?.printCustomer
                  ? styles.checkIconContainer
                  : styles.unCheckContainer
              }
              icon={
                form.receiptOptions?.printOptions?.printCustomer
                  ? 'check'
                  : 'null'
              }
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            />

            <Button
              title={translate('backOfficeSettings.printProductNotes')}
              labelStyle={styles.checkBoxTitleStyle}
              onPress={onChangePrintOptions.bind(
                null,
                'printNotes',
                !form.receiptOptions?.printOptions?.printNotes,
              )}
              fluid
              iconPosition={'left'}
              containerStyle={styles.checkBoxTitleContainer}
              iconContainerStyle={
                form.receiptOptions?.printOptions?.printNotes
                  ? styles.checkIconContainer
                  : styles.unCheckContainer
              }
              icon={
                form.receiptOptions?.printOptions?.printNotes ? 'check' : 'null'
              }
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            />

            <Button
              title={translate('backOfficeSettings.printProductNotes')}
              labelStyle={styles.checkBoxTitleStyle}
              onPress={onChangePrintOptions.bind(
                null,
                'printTips',
                !form.receiptOptions?.printOptions?.printTips,
              )}
              fluid
              iconPosition={'left'}
              containerStyle={styles.checkBoxTitleContainer}
              iconContainerStyle={
                form.receiptOptions?.printOptions?.printTips
                  ? styles.checkIconContainer
                  : styles.unCheckContainer
              }
              icon={
                form.receiptOptions?.printOptions?.printTips ? 'check' : 'null'
              }
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            />
            <View style={styles.emailReceiptsStyle}>
              <Button
                title={translate('backOfficeSettings.sendReceiptsViaEmail')}
                labelStyle={styles.checkBoxTitleStyle}
                onPress={onChangeEmailReceipt.bind(
                  null,
                  emailReceiptValue !== 'NONE' ? 'NONE' : 'AUTO',
                )}
                fluid
                iconPosition={'left'}
                containerStyle={styles.checkBoxTitleContainer}
                iconContainerStyle={
                  emailReceiptValue !== 'NONE'
                    ? styles.checkIconContainer
                    : styles.unCheckContainer
                }
                icon={emailReceiptValue !== 'NONE' ? 'check' : 'null'}
                iconProps={{
                  color: theme.colors.success,
                  size: 15,
                }}
              />

              {emailReceiptValue && emailReceiptValue !== 'NONE' && (
                <>
                  <Button
                    title={translate('backOfficeSettings.sendAutomatically')}
                    labelStyle={styles.checkBoxTitleStyle}
                    onPress={onChangeEmailReceipt.bind(null, 'AUTO')}
                    fluid
                    iconPosition={'left'}
                    containerStyle={styles.iconContainerStyle}
                    iconContainerStyle={styles.emailPreferenceCheckStyle}
                    icon={
                      emailReceiptValue === 'AUTO' ? 'recordAudio' : 'circle'
                    }
                    iconProps={{
                      color: theme.colors.success,
                      size: 15,
                    }}
                  />

                  <Button
                    title={translate('backOfficeSettings.sendManually')}
                    labelStyle={styles.checkBoxTitleStyle}
                    onPress={onChangeEmailReceipt.bind(null, 'MANUAL')}
                    fluid
                    iconPosition={'left'}
                    containerStyle={styles.iconContainerStyle}
                    iconContainerStyle={styles.emailPreferenceCheckStyle}
                    icon={
                      emailReceiptValue === 'MANUAL' ? 'recordAudio' : 'circle'
                    }
                    iconProps={{
                      color: theme.colors.success,
                      size: 15,
                    }}
                  />
                </>
              )}
            </View>
          </BackOfficeSection> */}

          <View style={styles.bottomSpace}></View>
        </ScrollView>

        <View style={styles.actionsContainerStyle}>
          <Button
            fluid
            testID="delete-changes"
            title={translate('button.deleteVenue')}
            containerStyle={styles.deleteButtonStyle}
            labelStyle={styles.dangerTitleStyle}
            onPress={onDelete}
          />
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={styles.saveButtonStyle}
            labelStyle={styles.titleStyle}
            onPress={onSave}
          />
        </View>
      </View>
    </>
  );
};
