import {
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  ProductModifierGroup,
  StyleFn,
  UpdateProductInput,
  UpdateVariantInput,
} from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFela } from 'react-fela';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import ButtonWithActionSheet from '../../../../components/ButtonWithActionSheet/ButtonWithActionSheet';
import { useModal } from '@hitz-group/rn-use-modal';
import { SetPriceModal } from '../../../../components/Modals/ProductPrices/SetPrices';
import { useNotification } from '../../../../hooks/Notification';
import { useProductPricings } from '../../../../hooks/app/useProductPricings';
import { ProductPricingInput, Money } from '@hitz-group/domain';
import { CatalogueItem } from './types';
import { Operation } from '../../../../types/Operation';
import { SetTax } from './BulkOperationsModals/SetTax';
import { AssignProductType } from './BulkOperationsModals/AssignProductType';
import { AssignModifiers } from './BulkOperationsModals/AssignModifiers';
import { AddPrinterProfile } from './BulkOperationsModals/AddPrinterProfile';
import { UpdatePages } from './BulkOperationsModals/UpdatePages';
import { AddStores } from './BulkOperationsModals/AddStores';
import { DeleteProducts } from './BulkOperationsModals/DeleteProducts';
import { unionBy } from 'lodash';

interface ProductBulkOptionsprops {
  selectedCatalogueItems: CatalogueItem[];
  defaultPricingGroupId: string;
  taxOptions: { value: string; label: string }[];
  productTypeOptions: { value: string; label: string }[];
  printerProfileOptions: { value: string; label: string }[];
  pageOptions: { value: string; label: string }[];
  storeOptions: { value: string; label: string }[];
  bulkOpSuccess: () => void;
  updateProducts: (productsDetails: UpdateProductInput[]) => void;
  updateVariants: (variantDetails: UpdateVariantInput[]) => void;
  deleteSelectedItems: () => void;
}

const customDropDownStyle: StyleFn = ({ theme }) => ({
  right: theme.spacing.small,
});

const redTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
});

const btnTextlabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  fontFamily: theme.font.regular,
  textTransform: 'none',
  paddingLeft: theme.padding.medium * 3,
});

const btnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.highlighted,
});

const dropDownTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primaryLightest,
  fontFamily: theme.font.regular,
  textTransform: 'none',
  marginLeft: theme.spacing.medium,
  fontSize: theme.fontSize.medium,
});

interface UpdateProductDetailsProps {
  productType?: string;
  printerProfiles?: string[];
  pages?: string[];
  stores?: string[];
  removePages?: string[];
  selectedProductModifiers?: ProductModifierGroup[];
}

export const ProductBulkOptions: React.FC<ProductBulkOptionsprops> = ({
  selectedCatalogueItems,
  defaultPricingGroupId,
  taxOptions,
  productTypeOptions,
  printerProfileOptions,
  bulkOpSuccess,
  updateProducts,
  updateVariants,
  pageOptions,
  storeOptions,
  deleteSelectedItems,
}: ProductBulkOptionsprops) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { currency, unAppendCurrency } = useCurrency();
  const selectedProducts = useMemo(() => {
    return selectedCatalogueItems.filter(x => x && !x.isVariant);
  }, [selectedCatalogueItems]);
  const selectedVariants = useMemo(() => {
    return selectedCatalogueItems.filter(x => x && x.isVariant);
  }, [selectedCatalogueItems]);

  const {
    error: errorPP,
    loading: loadingPP,
    operation: PPOperation,
    update: updateProductPricings,
  } = useProductPricings();

  const isDefaultPGUpdated =
    PPOperation === Operation.UPDATE && !errorPP && !loadingPP;

  useEffect(() => {
    if (isDefaultPGUpdated) {
      showNotification({
        success: true,
        message: translate('productBulkOperations.successfullyUpdated'),
      });
      bulkOpSuccess();
    }
  }, [showNotification, bulkOpSuccess, translate, isDefaultPGUpdated]);

  useEffect(() => {
    // if there is no default pricing group then close modals
    if (!defaultPricingGroupId) {
      closeModal();
      showNotification({
        error: true,
        message: translate('productBulkOperations.noDefaultPG'),
      });
    }
  }, [closeModal, defaultPricingGroupId, showNotification, translate]);

  const mapModifierGroupsToModifierGroupsInput = useCallback(
    (productModifierGroups: ProductModifierGroup[]) => {
      let defaultModifierGroup = undefined as unknown as ProductModifierGroup;
      const modifierGroups: ProductModifierGroup[] = [];
      productModifierGroups.forEach((productModifier, index) => {
        const productModifierGroup = {
          isGrouped: productModifier.isGrouped,
          isRequired: productModifier.isRequired,
          modifierGroup: productModifier.id,
          priority: index,
          modifiers: productModifier.modifiers.map(eachMod => ({
            id: eachMod.id,
            isDefault: eachMod.isDefault,
          })),
        } as unknown as ProductModifierGroup;
        // id and name  are same in case  of default modifier group
        if (productModifier.id === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) {
          defaultModifierGroup = productModifierGroup;
        } else {
          modifierGroups.push(productModifierGroup);
        }
      });
      return {
        modifierGroups,
        defaultModifierGroup,
      };
    },
    [],
  );

  const saveProductsWithNewPriceOrTax = useCallback(
    (newPrice: number | undefined, newTax: string | undefined) => {
      const updateProductPricingsInput = selectedProducts.map(x => {
        const price =
          newPrice !== undefined ? newPrice : +unAppendCurrency(x.price);
        const tax = newTax !== undefined ? newTax : x.tax;
        return {
          id: x.defaultPriceId,
          sellingPrice: {
            amount: price,
            currency: currency,
          } as Money,
          sellingTax: tax,
          pricingGroupId: defaultPricingGroupId,
          product: x.id,
        };
      }) as unknown as ProductPricingInput[];
      updateProductPricingsInput.length &&
        updateProductPricings(updateProductPricingsInput);
    },
    [
      defaultPricingGroupId,
      selectedProducts,
      currency,
      updateProductPricings,
      unAppendCurrency,
    ],
  );

  const updateProductDetails = useCallback(
    (input: UpdateProductDetailsProps) => {
      const {
        productType,
        printerProfiles,
        pages,
        stores,
        removePages,
        selectedProductModifiers,
      } = input;
      let updateInput: UpdateProductInput[] = [];
      let updateInputVariant: UpdateVariantInput[] = [];
      if (productType) {
        updateInput = selectedProducts.map(
          x =>
            ({
              id: x.id,
              name: x.name,
              productType: productType,
            } as UpdateProductInput),
        );
        updateInputVariant = selectedVariants.map(
          x =>
            ({
              id: x.id,
              productType: productType,
            } as UpdateVariantInput),
        );
      } else if (selectedProductModifiers?.length) {
        const {
          modifierGroups: selectedModifiers,
          defaultModifierGroup: selectedDefaultModifierGroup,
        } = mapModifierGroupsToModifierGroupsInput(selectedProductModifiers);

        updateInput = selectedProducts.map(x => {
          const {
            modifierGroups: existingModifierGroups,
            defaultModifierGroup: existingDefaultModifierGroup,
          } = mapModifierGroupsToModifierGroupsInput(x.modifierGroups || []);

          const defaultModifiers = unionBy(
            existingDefaultModifierGroup?.modifiers || [],
            selectedDefaultModifierGroup?.modifiers || [],
            'id',
          );
          const defaultModifierGroup = {
            ...(selectedDefaultModifierGroup || {}),
            ...(existingDefaultModifierGroup || {}),
            modifiers: defaultModifiers,
          };

          return {
            id: x.id,
            name: x.name,
            modifierGroups: defaultModifiers.length
              ? [
                  ...selectedModifiers,
                  defaultModifierGroup,
                  ...existingModifierGroups,
                ]
              : [...selectedModifiers, ...existingModifierGroups],
          } as unknown as UpdateProductInput;
        });
      } else if (printerProfiles) {
        updateInput = selectedProducts.map(
          x =>
            ({
              id: x.id,
              name: x.name,
              printerProfiles,
            } as UpdateProductInput),
        );
        updateInputVariant = selectedVariants.map(
          x =>
            ({
              id: x.id,
              printerProfiles,
            } as UpdateVariantInput),
        );
      } else if (pages?.length) {
        // updates only products, will not update variant products
        updateInput = selectedProducts
          .filter(x => x && !x.isVariantProduct)
          .map(
            x =>
              ({
                id: x.id,
                name: x.name,
                // to get unique ids
                pages: [...new Set([...x.pageIds, ...pages])],
              } as UpdateProductInput),
          );
        updateInputVariant = selectedVariants.map(
          x =>
            ({
              id: x.id,
              pages: [...new Set([...x.pageIds, ...pages])],
            } as UpdateVariantInput),
        );
      } else if (stores?.length) {
        // updates only products, will not update variant products
        updateInput = selectedProducts
          .filter(x => x && !x.isVariantProduct)
          .map(
            x =>
              ({
                id: x.id,
                name: x.name,
                // to get unique ids
                stores: [...new Set([...x.storeIds, ...stores])],
              } as UpdateProductInput),
          );
        updateInputVariant = selectedVariants.map(
          x =>
            ({
              id: x.id,
              stores: [...new Set([...x.storeIds, ...stores])],
            } as UpdateVariantInput),
        );
      } else if (removePages?.length) {
        // updates only products, will not update variant products
        updateInput = selectedProducts
          .filter(x => x && !x.isVariantProduct)
          .map(
            x =>
              ({
                id: x.id,
                name: x.name,
                pages:
                  x?.pageIds?.filter(x => x && !removePages.includes(x)) || [],
              } as UpdateProductInput),
          );
        updateInputVariant = selectedVariants.map(
          x =>
            ({
              id: x.id,
              pages:
                x?.pageIds?.filter(x => x && !removePages.includes(x)) || [],
            } as UpdateVariantInput),
        );
      }
      updateInput.length && updateProducts(updateInput);
      updateInputVariant.length && updateVariants(updateInputVariant);
      closeModal();
    },
    [
      updateProducts,
      updateVariants,
      closeModal,
      selectedProducts,
      selectedVariants,
      mapModifierGroupsToModifierGroupsInput,
    ],
  );

  const setPrices = useCallback(() => {
    if (selectedProducts.length > 0) {
      showModal(
        <SetPriceModal
          mode="set"
          onSubmit={newPrice =>
            saveProductsWithNewPriceOrTax(newPrice, undefined)
          }
          title={translate('productBulkOperations.setProductPrice')}
          submitLabel={translate('productBulkOperations.changePrice')}
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProducts,
    translate,
    saveProductsWithNewPriceOrTax,
  ]);

  const assignTax = useCallback(() => {
    showModal(
      <SetTax
        countOfproducts={selectedProducts.length}
        onSubmit={newTax => saveProductsWithNewPriceOrTax(undefined, newTax)}
        taxOptions={taxOptions}
      />,
      { onBackdropPress: closeModal },
    );
  }, [
    closeModal,
    showModal,
    selectedProducts,
    taxOptions,
    saveProductsWithNewPriceOrTax,
  ]);

  const assignProductType = useCallback(() => {
    if (productTypeOptions.length) {
      showModal(
        <AssignProductType
          countOfproducts={selectedProducts.length}
          onSubmit={productType => updateProductDetails({ productType })}
          productTypeOptions={productTypeOptions}
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('productBulkOperations.noProductTypes'),
      });
    }
  }, [
    closeModal,
    productTypeOptions,
    selectedProducts,
    showModal,
    updateProductDetails,
    showNotification,
    translate,
  ]);

  const addPrinterProfile = useCallback(() => {
    if (printerProfileOptions.length) {
      showModal(
        <AddPrinterProfile
          countOfproducts={selectedProducts.length}
          onSubmit={printerProfiles =>
            updateProductDetails({ printerProfiles })
          }
          printerProfileOptions={printerProfileOptions}
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('productBulkOperations.noPrinterProfile'),
      });
    }
  }, [
    closeModal,
    printerProfileOptions,
    selectedProducts,
    showModal,
    updateProductDetails,
    showNotification,
    translate,
  ]);

  const addPage = useCallback(() => {
    showModal(
      <UpdatePages
        title={translate('productBulkOperations.addToPage')}
        onSubmit={pages => updateProductDetails({ pages })}
        pageOptions={pageOptions}
        isPagesAdding
      />,
      { onBackdropPress: closeModal },
    );
  }, [pageOptions, closeModal, showModal, updateProductDetails, translate]);

  const removeFromPage = useCallback(() => {
    showModal(
      <UpdatePages
        title={translate('productBulkOperations.removeProductsFromPage')}
        onSubmit={removePages => updateProductDetails({ removePages })}
        pageOptions={pageOptions}
        isPagesAdding={false}
      />,
      { onBackdropPress: closeModal },
    );
  }, [pageOptions, closeModal, showModal, updateProductDetails, translate]);

  const addStore = useCallback(() => {
    showModal(
      <AddStores
        onSubmit={stores => updateProductDetails({ stores })}
        storeOptions={storeOptions}
      />,
      { onBackdropPress: closeModal },
    );
  }, [storeOptions, closeModal, showModal, updateProductDetails]);

  const assignModifiers = useCallback(() => {
    showModal(
      <AssignModifiers
        onSubmit={selectedProductModifiers =>
          updateProductDetails({ selectedProductModifiers })
        }
      />,
      { onBackdropPress: closeModal },
    );
  }, [showModal, closeModal, updateProductDetails]);

  const deleteProducts = useCallback(() => {
    showModal(
      <DeleteProducts
        onSubmit={deleteSelectedItems}
        countOfProducts={selectedCatalogueItems?.length?.toString()}
      />,
      { onBackdropPress: closeModal },
    );
  }, [closeModal, selectedCatalogueItems, showModal, deleteSelectedItems]);

  return (
    <ButtonWithActionSheet
      title={translate('productBulkOperations.bulkActions') + ':'}
      actionList={[
        {
          label: translate('productBulkOperations.setPrice'),
          action: setPrices,
        },
        {
          label: translate('productBulkOperations.assignTax'),
          action: assignTax,
        },
        {
          label: translate('productBulkOperations.assignProductType'),
          action: assignProductType,
        },
        {
          label: translate('productBulkOperations.addPrinterProfile'),
          action: addPrinterProfile,
        },
        {
          label: translate('productBulkOperations.addToPage'),
          action: addPage,
        },
        {
          label: translate('productBulkOperations.addToStore'),
          action: addStore,
        },
        {
          label: translate('productBulkOperations.assignModifiers'),
          action: assignModifiers,
        },
        {
          label: translate('productBulkOperations.removeFromPage'),
          action: removeFromPage,
          textStyle: css(redTextStyle),
        },
        {
          label: translate('productBulkOperations.deleteProducts'),
          action: deleteProducts,
          textStyle: css(redTextStyle),
        },
      ]}
      customDropDownStyle={css(customDropDownStyle)}
      btnTextlabelStyle={css(btnTextlabelStyle)}
      btnContainerStyle={css(btnContainerStyle)}
      iconContainerStyle={css(btnContainerStyle)}
      iconColor={theme.colors.blue}
      dropDownTitleStyle={css(dropDownTitleStyle)}
    />
  );
};
