import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import TitleBar from '../TitleBar/TitleBar';
import { useTranslation } from '@hitz-group/localization';
import Calendar from './Calendar';
import Button from '../Button/Button';
import { filters } from './../../utils/dateHelper';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import { useFela, FelaComponent } from 'react-fela';
import IconButton from '../Button/IconButton';
import format from 'date-fns/format';
import { useModal } from '@hitz-group/rn-use-modal';
export interface DateRangePickerProps {
  endDate?: Date;
  startDate?: Date;
  onPress?: (startDate: Date, endDate: Date) => void;
  onSelectFilter?: (filter: string) => void;
  onPressApply?: (startDate?: Date, endDate?: Date) => void;
  currentFilter?: string;
}

const containerStyle: StyleFn = () => ({
  width: 620,
  alignSelf: 'center',
});

const filterContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: theme.spacing.small * 1.5,
});

const calendarContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
});

const ButtonContainer: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: theme.spacing.small * 2.5,
});

const textStyle: StyleFn = ({ theme, isSelected }) => ({
  color: isSelected ? theme.colors.white : theme.colors.text,
  textTransform: 'uppercase',
  fontFamily: theme.font.bold,
});

const titleIconStyle: StyleFn = () => ({
  position: 'absolute',
  left: 0,
});

const dateRangeContainerStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  borderRadius: theme.radius.small,
  paddingTop: theme.padding.medium * 2.5,
  paddingBottom: theme.padding.medium * 3.5,
  paddingHorizontal: theme.padding.medium * 3.5,
  backgroundColor: theme.colors.white,
});

export interface FilterProps {
  isSelected?: boolean;
  onPress?: () => void;
  testID?: string;
}

const FilterText: React.FC<FilterProps> = (props: FilterProps) => (
  <FelaComponent style={textStyle} {...props}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text style={style} {...props} />
    )}
  </FelaComponent>
);

const filterStyle: StyleFn = ({ theme, isSelected }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  backgroundColor: isSelected ? theme.colors.blue : theme.colors.white,
  height: 44,
  width: 44,
  marginHorizontal: theme.spacing.small / 4,
  borderRadius: theme.radius.small,
});

const Filter: React.FC<FilterProps> = ({ onPress, ...props }: FilterProps) => {
  return (
    <FelaComponent style={filterStyle} {...props}>
      {({ style }: RenderProps): React.ReactFragment => (
        <TouchableOpacity style={style} onPress={onPress} {...props}>
          <FilterText {...props} />
        </TouchableOpacity>
      )}
    </FelaComponent>
  );
};

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  endDate,
  startDate,
  onSelectFilter,
  onPressApply,
  currentFilter,
}: DateRangePickerProps) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState(currentFilter);
  const [currentEndMonth, setcurrentEndMonth] = useState(
    endDate ? endDate.getMonth() : new Date().getMonth(),
  );
  const [currentEndYear, setcurrentEndYear] = useState(
    endDate ? endDate.getFullYear() : new Date().getFullYear(),
  );

  const [customStartDate, setCustomStartDate] = useState(startDate);
  const [customEndDate, setCustomEndDate] = useState(endDate);

  const [currentStartMonth, setCurrentStartMonth] = useState(
    startDate
      ? startDate.getMonth()
      : currentEndMonth === 0
      ? 11
      : currentEndMonth - 1,
  );

  const [currentStartYear, setCurrentStartYear] = useState(
    startDate
      ? startDate.getFullYear()
      : currentEndMonth === 0
      ? currentEndYear - 1
      : currentEndYear,
  );

  useEffect(() => {
    if (currentFilter) setSelectedFilter(currentFilter);
  }, [currentFilter]);

  useEffect(() => {
    if (startDate?.getMonth() === endDate?.getMonth()) {
      if (startDate?.getFullYear() !== endDate?.getFullYear()) {
        setCurrentStartMonth(currentEndMonth);
        startDate && setCurrentStartYear(startDate?.getUTCFullYear());
      } else {
        setCurrentStartMonth(currentEndMonth === 0 ? 11 : currentEndMonth - 1);
        setCurrentStartYear(
          currentEndMonth === 0 ? currentEndYear - 1 : currentEndYear,
        );
      }
    }
  }, [endDate, startDate, currentEndYear, currentEndMonth]);

  const onChangeEndMonth = useCallback(
    (quantity: number): void => {
      if (currentEndMonth + quantity > 11) {
        setcurrentEndMonth(0);
        setcurrentEndYear(currYear => currYear + 1);
      } else if (currentEndMonth + quantity < 0) {
        setcurrentEndMonth(11);
        setcurrentEndYear(currYear => currYear - 1);
      } else {
        setcurrentEndMonth(currMonth => currMonth + quantity);
      }
    },
    [currentEndMonth],
  );

  const onChangeStartMonth = useCallback(
    (quantity: number): void => {
      if (currentStartMonth + quantity > 11) {
        setCurrentStartMonth(0);
        setCurrentStartYear(currYear => currYear + 1);
      } else if (currentStartMonth + quantity < 0) {
        setCurrentStartMonth(11);
        setCurrentStartYear(currYear => currYear - 1);
      } else {
        setCurrentStartMonth(currMonth => currMonth + quantity);
      }
    },
    [currentStartMonth],
  );

  const onSelectDateFilter = useCallback(
    (filter: string): void => {
      setSelectedFilter(filter);
      onSelectFilter && onSelectFilter(filter);
      if (filter !== 'C') {
        closeModal();
      } else {
        setCustomStartDate(undefined);
      }
    },
    [onSelectFilter, closeModal],
  );

  const onClickApply = useCallback(() => {
    onPressApply && onPressApply(customStartDate, customEndDate);
    closeModal();
  }, [customStartDate, customEndDate, onPressApply, closeModal]);

  const title = useMemo((): string => {
    let title = translate('orderHistory.dateRangePicker');
    if (selectedFilter === 'C' && !!customStartDate && !!customEndDate) {
      title = `${format(customStartDate, 'dd/MM/yyyy')}-${format(
        customEndDate,
        'dd/MM/yyyy',
      )}`;
    }
    if (selectedFilter === 'C' && !customStartDate) {
      title = translate('orderHistory.selectStartDate');
    }
    return title;
  }, [customStartDate, customEndDate, selectedFilter, translate]);

  const onClickDate = useCallback(
    (date: Date): void => {
      if (title === translate('orderHistory.selectStartDate')) {
        setCustomStartDate(date);
        setCustomEndDate(new Date());
      } else {
        setCustomEndDate(date);
      }
    },
    [title, translate],
  );

  useEffect(() => {
    if (customStartDate && customEndDate && customStartDate > customEndDate) {
      const date = customStartDate;
      setCustomStartDate(customEndDate);
      setCustomEndDate(date);
    }
  }, [customStartDate, customEndDate]);

  const iconLeft = (
    <IconButton
      iconSize={20}
      icon={'times'}
      containerSize={44}
      containerStyle={css(titleIconStyle)}
      onPress={closeModal}
    />
  );
  return (
    <View style={css(containerStyle)}>
      <TitleBar primary title={title} titleLeft={iconLeft} />
      <View style={css(dateRangeContainerStyle)}>
        <View>
          <View style={css(filterContainerStyle)}>
            {filters.map((filter: string, index: number) => (
              <Filter
                testID={filter}
                key={index}
                isSelected={filter === selectedFilter}
                onPress={(): void => onSelectDateFilter(filter)}
              >
                {filter}
              </Filter>
            ))}
          </View>
          <View style={css(calendarContainerStyle)}>
            <Calendar
              startDate={customStartDate}
              endDate={
                customEndDate?.getMonth() === customStartDate?.getMonth() &&
                customEndDate?.getFullYear() === customStartDate?.getFullYear()
                  ? customEndDate
                  : undefined
              }
              month={currentStartMonth}
              year={currentStartYear}
              onPress={(quantity: number): void => onChangeStartMonth(quantity)}
              onSelectDate={onClickDate}
              disableNextMonth={
                currentStartYear > currentEndYear ||
                (currentStartYear === currentEndYear &&
                  currentStartMonth >= currentEndMonth - 1) ||
                (currentStartYear < currentEndYear && currentStartMonth === 11)
              }
            />
            <Calendar
              endDate={customEndDate}
              startDate={
                customEndDate?.getMonth() === customStartDate?.getMonth() &&
                customEndDate?.getFullYear() === customStartDate?.getFullYear()
                  ? customStartDate
                  : undefined
              }
              month={currentEndMonth}
              year={currentEndYear}
              onPress={(quantity: number): void => onChangeEndMonth(quantity)}
              onSelectDate={onClickDate}
              disablePrevMonth={
                currentStartYear > currentEndYear ||
                (currentStartYear === currentEndYear &&
                  currentEndMonth <= currentStartMonth + 1) ||
                (currentStartYear < currentEndYear && currentEndMonth === 0)
              }
            />
          </View>
          <View style={css(ButtonContainer)}>
            <Button success size="small" title="Apply" onPress={onClickApply} />
          </View>
        </View>
      </View>
    </View>
  );
};

export default DateRangePicker;
