import React, { useState, useCallback, useMemo, ReactElement } from 'react';
import {
  View,
  Text,
  ViewStyle,
  FlatList,
  TextStyle,
  TouchableOpacity,
} from 'react-native';
import { useFela, FelaComponent } from 'react-fela';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import IconButton from '../Button/IconButton';
import Button from '../Button/Button';
import keyBy from 'lodash/keyBy';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import scale, { isWeb } from '../../common/theme';
export interface Option {
  value: string;
  label: string;
}

export interface DropDownProps {
  testID?: string;
  btnTestId?: string;
  title?: string;
  options: Option[];
  selectedValue?: string;
  touchableStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  titleStyle?: TextStyle;
  itemsContainerStyle?: ViewStyle;
  itemStyle?: ViewStyle;
  onValueChange?: (itemValue: string, itemPosition: number) => void;
  arrowVisible?: boolean;
  PopoverViewPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | 'auto';
  extraPopoverStyle?: ViewStyle;
  isDisabled?: boolean;
  iconStyleFunction?: StyleFn;
}

interface LabelPros {
  alignTitle: 'left' | 'right' | 'center';
  titleStyle?: TextStyle;
}

const viewStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
});

const touchContainerStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
  borderWidth: 0.5,
  borderRadius: theme.radius.small,
  height: theme.input.height,
  paddingHorizontal: theme.padding.medium,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10,
});

const cursorStyle: StyleFn = () => ({
  cursor: 'default',
});
const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  marginLeft: theme.spacing.small / 2,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginLeft: 'auto',
});

const itemButtonStyle: StyleFn = ({ theme, selected }) => ({
  height: theme.input.height,
  justifyContent: 'flex-start',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
  borderRadius: 0,
  backgroundColor: selected ? theme.colors.blue : theme.colors.white,
});

const buttonTextStyle: StyleFn = ({ theme }) => ({
  textTransform: 'none',
  ...theme.font14RegularCharcoal,
});

const labelStyle: StyleFn = ({ theme, alignTitle }) => ({
  ...theme.font14RegularDarkGrey,
  textAlign: alignTitle,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderWidth: 1,
  borderColor: theme.colors.boxBorder,
});
export const backgroundStyle: StyleFn = ({ theme }) => ({
  height: scale.deviceHeight,
  width: '100%',
  backgroundColor: theme.colors.transparent,
  position: 'absolute',
});
const hideToolTipArrowStyle: StyleFn = () => ({
  backgroundColor: 'transparent',
});
const showToolTipArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});
const popoverStyle: StyleFn = () => ({
  width: isWeb ? 280 : 200,
});

const Label: React.FC<LabelPros> = (props: LabelPros) => (
  <FelaComponent {...props} style={labelStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text style={[style, props.titleStyle]} {...props} />
    )}
  </FelaComponent>
);

const DropDown: React.FC<DropDownProps> = ({
  btnTestId,
  title,
  options,
  selectedValue,
  onValueChange,
  containerStyle,
  titleStyle,
  itemStyle,
  itemsContainerStyle,
  touchableStyle,
  arrowVisible = false,
  PopoverViewPlacement = 'bottom',
  extraPopoverStyle,
  isDisabled,
  iconStyleFunction,
}: DropDownProps) => {
  const { css, theme } = useFela();
  const [showOptions, setShowOptions] = useState(false);

  const valuesDictionary = useMemo(() => {
    return keyBy(options, 'value');
  }, [options]);

  const onToggleOptions = useCallback((): void => {
    if (!isDisabled) {
      setShowOptions(current => !current);
    }
  }, [isDisabled]);

  const onPressItem = useCallback(
    (value, index): void => {
      onValueChange && onValueChange(value, index);
      onToggleOptions();
    },
    [onToggleOptions, onValueChange],
  );

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const getPopoverPlacement = useCallback(() => {
    switch (PopoverViewPlacement) {
      case 'top':
        return PopoverPlacement.TOP;
      case 'bottom':
        return PopoverPlacement.BOTTOM;
      case 'center':
        return PopoverPlacement.CENTER;
      case 'left':
        return PopoverPlacement.LEFT;
      case 'right':
        return PopoverPlacement.RIGHT;
      case 'auto':
        return PopoverPlacement.AUTO;
      default:
        break;
    }
  }, [PopoverViewPlacement]);

  return (
    <View style={[css(viewStyle), containerStyle]}>
      <Popover
        arrowStyle={
          arrowVisible ? css(showToolTipArrowStyle) : css(hideToolTipArrowStyle)
        }
        placement={getPopoverPlacement()}
        popoverStyle={[css(popoverStyle) && extraPopoverStyle]}
        from={
          <TouchableOpacity activeOpacity={1}>
            <>
              {title && (
                <Label alignTitle="left" titleStyle={titleStyle}>
                  {title}
                </Label>
              )}
              <TouchableOpacity
                testID={btnTestId}
                style={[
                  css([
                    touchContainerStyle,
                    isDisabled && isWeb && cursorStyle,
                  ]),
                  touchableStyle,
                ]}
                onPress={onToggleOptions}
                activeOpacity={isDisabled ? 1 : 0.2}
              >
                <Text style={css(textStyle)}>
                  {(selectedValue &&
                    valuesDictionary.hasOwnProperty(selectedValue) &&
                    valuesDictionary[selectedValue]?.label) ||
                    (options.length && options[0].label) ||
                    ''}
                </Text>

                <IconButton
                  icon={'AngleDown'}
                  primary
                  containerStyle={css(iconContainerStyle, iconStyleFunction)}
                  disabled
                  iconColor={
                    isDisabled ? theme.colors.transparent : theme.colors.primary
                  }
                />
              </TouchableOpacity>
            </>
          </TouchableOpacity>
        }
        backgroundStyle={css(backgroundStyle)}
        isVisible={showOptions}
        onRequestClose={() => onRequestClosePopover()}
      >
        <View style={css(dropDownStyle)}>
          <FlatList
            scrollEnabled={true}
            data={options}
            accessible={true}
            style={itemsContainerStyle}
            keyExtractor={item => item.value}
            renderItem={({ item, index }): ReactElement => {
              return (
                <Button
                  testID={item.label}
                  title={item.label}
                  labelStyle={css(buttonTextStyle)}
                  fluid
                  iconPosition={'left'}
                  onPress={() => onPressItem(item.value, index)}
                  containerStyle={
                    [
                      css(
                        itemButtonStyle({
                          theme,
                          selected: item.value === selectedValue,
                        }),
                      ),
                      itemStyle,
                    ] as ViewStyle
                  }
                />
              );
            }}
          />
        </View>
      </Popover>
    </View>
  );
};

export default DropDown;
