import { StyleFn } from '@hitz-group/domain';

export const iconContainerStyle: StyleFn = () => ({
  width: 25,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
});

export const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.padding.medium / 2,
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

export const defaultContainerStyle: StyleFn = ({ theme }) => ({
  width: 330,
  height: theme.input.height,
  marginTop: theme.spacing.small / 2,
  borderRadius: theme.radius.small,
});

export const formDropDownContainerStyle: StyleFn = ({ theme }) => ({
  width: 80,
  height: theme.input.height,
  marginLeft: theme.spacing.small,
});

export const deactivatedDropDown: StyleFn = ({ theme }) => ({
  width: 80,
  height: theme.input.height,
  marginLeft: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
});

export const defaultTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.medium,
  marginTop: 10,
  ...theme.font14RegularCharcoal,
});

export const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 70,
  height: theme.input.height,
});

export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: 70,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});
