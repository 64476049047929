import React from 'react';
import { useFela } from 'react-fela';
import IconButton from '../../../../components/Button/IconButton';
import { Text, View } from 'react-native';
import { EarnByAmount, EarningRule, StyleFn } from '@hitz-group/domain';
import TableRow from '../../../../components/TableComponent/TableRow';
import { useLoyaltyStyle } from './styles';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { useCallback } from 'react';

export interface ProductRowProps {
  item: EarningRule;
  onEdit: () => void;
  singularTerm: string;
  pluralTerm: string;
  disabled?: boolean;
  onDeleteItem: () => void;
}

const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const deleteIconStyle: StyleFn = ({ theme, disabled }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: disabled ? theme.colors.background : theme.colors.danger2,
  marginLeft: 'auto',
});

const EarningRuleRow: React.FC<ProductRowProps> = ({
  item,
  onEdit,
  singularTerm,
  pluralTerm,
  disabled,
  onDeleteItem,
}) => {
  const { css, theme } = useFela({ disabled });
  const styles = useLoyaltyStyle();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const generateDescription = useCallback(
    (earningRule: EarningRule) => {
      const amount = formatCurrency((earningRule as EarnByAmount).amountSpend);

      return translate('backOfficeLoyalty.earnPointForAmount', {
        point: earningRule.earningPoint,
        amount,
        unit: earningRule.earningPoint > 1 ? pluralTerm : singularTerm,
      });
    },
    [formatCurrency, pluralTerm, singularTerm, translate],
  );

  return (
    <TableRow containerStyle={styles.rowContainerStyle} normalRows={true}>
      <View style={styles.valueText}>
        <Text style={css(textStyle)} testID="rule-name">
          {`${item.earningPoint}  ${
            item.earningPoint > 1 ? pluralTerm : singularTerm
          }`}
        </Text>
      </View>
      <View style={styles.descriptionText}>
        <Text style={css(textStyle)}>{generateDescription(item)}</Text>
      </View>
      <View style={styles.venueText}>
        <Text style={css(textStyle)}>{item?.venueIds?.length || 0}</Text>
      </View>

      <View style={css(flexStyle)} />
      <View style={styles.editIconContainer}>
        <IconButton
          icon="pen"
          iconSize={18}
          containerSize={38}
          containerStyle={styles.editButtonStyle}
          iconColor={theme.colors.lightBlue}
          onPress={onEdit}
          testID="edit-icon"
        />
      </View>
      <View style={styles.editIconContainer}>
        <IconButton
          icon="TrashAlt"
          testID="delete-icon"
          iconSize={18}
          containerSize={38}
          containerStyle={css(deleteIconStyle)}
          iconColor={disabled ? theme.colors.paragraph : theme.colors.red}
          disabled={disabled}
          onPress={onDeleteItem}
        />
      </View>
    </TableRow>
  );
};

export default EarningRuleRow;
