import React, { useCallback } from 'react';
import { StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import { Text, View } from 'react-native';
import IconButton from '../Button/IconButton';
import { translate } from '@hitz-group/localization';

const containerStyle: StyleFn = ({ theme }) => ({
  width: 680,
  height: 44,
  backgroundColor: theme.colors.white,
  marginBottom: theme.spacing.small,
  paddingHorizontal: theme.padding.medium,
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: theme.radius.small,
});

const IconContainerStyle: StyleFn = ({ theme }) => ({
  marginHorizontal: theme.spacing.small,
});

const paymentView: StyleFn = () => ({
  flex: 1,
  alignItems: 'center',
});

const paymentText: StyleFn = () => ({
  marginLeft: -50,
  textTransform: 'uppercase',
});

export interface PaymentHeadingInfoProp {
  onClose?: () => void;
  nthPayment: number;
}

const PaymentHeadingInfo: React.FC<PaymentHeadingInfoProp> = ({
  onClose,
  nthPayment,
}) => {
  const { css, theme } = useFela({});

  const handleOnClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <View style={css(containerStyle)} testID="payment-option-header">
      <View style={css(IconContainerStyle)}>
        <IconButton
          icon="times"
          iconColor={theme.colors.black}
          onPress={handleOnClose}
          testID="payment-header-close-icon"
        />
      </View>
      <View style={css(paymentView)}>
        <Text testID="payment-name" style={css(paymentText)}>
          {translate('payment.splitPaymentStep', { step: nthPayment })}
        </Text>
      </View>
    </View>
  );
};

export default PaymentHeadingInfo;
