import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { FelaComponent } from 'react-fela';
import { StyleFn, RenderProps } from '@hitz-group/domain/dist';
export interface CartActionProps {
  children: React.ReactNode;
  containerStyle?: ViewStyle;
}

const buttonStyle: StyleFn = ({ theme }) => ({
  width: 60,
  height: 44,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const ActionButton: React.FC = ({ children }) => (
  <FelaComponent style={buttonStyle}>
    {({ style }: RenderProps) => <View style={style}>{children}</View>}
  </FelaComponent>
);

export const CartActions: React.FC<CartActionProps> = ({
  children,
  containerStyle,
}) => {
  return (
    <View testID="cart-actions" style={[styles.container, containerStyle]}>
      {React.Children.map(children, (x, i) => (
        <ActionButton key={i}>{x}</ActionButton>
      ))}
    </View>
  );
};
