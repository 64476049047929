import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
import { Details } from './SectionDetails/Details';
const Tab = createMaterialTopTabNavigator();

const SectionSettingsTabs: React.FC = () => {
  const route = useRoute();
  const params = route.params as {
    venueId: string;
    screen: string;
    fromStoreSection?: boolean | string;
    storeId?: string;
  };

  let fromStoreSection = params?.fromStoreSection || false;
  if (typeof fromStoreSection === 'string') {
    fromStoreSection = fromStoreSection === 'true';
  }

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={fromStoreSection ? 'MyStore' : 'VenueSettings'}
          previousScreen={{
            screen: 'SectionManagement',
            params,
          }}
        />
      )}
      initialRouteName={params.screen ?? 'Details'}
    >
      <Tab.Screen name="Details" component={Details} initialParams={params} />
    </Tab.Navigator>
  );
};

export default SectionSettingsTabs;
