import React from 'react';
import { PrinterProfiles } from './PrinterProfile/PrinterProfiles';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';

const Tab = createMaterialTopTabNavigator();

// not a stack anymore, rename it to just TaxesAndFees
const PrinterProfileAndReceiptStack: React.FC = () => {
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} fluid={true} />
      )}
      initialRouteName="PrinterProfile"
    >
      <Tab.Screen
        name="PrinterProfile"
        options={{ tabBarLabel: 'Printer Profile' }}
        component={PrinterProfiles}
      />
    </Tab.Navigator>
  );
};

export default PrinterProfileAndReceiptStack;
