import { View, Text } from 'react-native';
import React from 'react';
import { StyleFn } from '@hitz-group/domain';
import { useFela, withTheme } from 'react-fela';
export interface TableSegmentProps {
  title?: string;
  children: React.ReactChildren;
  button?: boolean;
  testID?: string;
}

const headerStyle: StyleFn = ({ theme }) => ({
  marginBottom: 18,
  marginLeft: theme.spacing.medium,
});

const containerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.background,
  width: '70%',
  marginLeft: '15%',
  marginTop: 50,
});
const titleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
  fontFamily: theme.font.medium,
  color: theme.colors.paragraph,
  textTransform: 'uppercase',
});

const SettingsSegment: React.FC<TableSegmentProps> = ({
  title,
  children,
}: TableSegmentProps) => {
  const { css } = useFela();

  return (
    <View testID="settings" style={css(containerStyle)}>
      <View testID="settings-segment-title" style={css(headerStyle)}>
        <Text style={css(titleStyle)}>{title}</Text>
      </View>
      {children}
    </View>
  );
};

export default withTheme(SettingsSegment);
