import React from 'react';
import { FilterObject } from '@hitz-group/domain';
import { FilterInputStyles } from '../styles/Component.styles';
import { View } from 'react-native';
import MultipleSelect from '../../../../components/MultipleSelect/MultipleSelect';
import { useTranslation } from '@hitz-group/localization';

interface FilterInputProps {
  options: FilterObject[];
  name: string;
  title: string;
  index: number;
  selectedValues: string[];
  showLabel?: boolean;
  updateFilters: (filter: string, value: string[]) => void;
}

export const FilterInput: React.FC<FilterInputProps> = ({
  options,
  name,
  title,
  index,
  selectedValues,
  updateFilters,
  showLabel = true,
}) => {
  const { translate } = useTranslation();
  const styles = FilterInputStyles(1000 - 10 * index);
  const filterOptions = options || [];

  return (
    <View style={styles.fieldViewStyle}>
      <MultipleSelect
        containerStyle={styles.attributesDropDownStyle}
        showCheckboxes={false}
        maxItems={3}
        placeholder={translate(title)}
        label={showLabel ? translate(title) : undefined}
        textStyle={styles.ddTextStyle}
        values={filterOptions.map(option => ({
          label: option.name,
          value: option.id,
        }))}
        selectedValues={selectedValues || []}
        onValueChange={(value): void => updateFilters(name, value)}
        searchLabel={translate(title)}
        searchPlaceHolder={translate(title)}
        touchableStyle={styles.touchableStyle}
        placeHolderStyle={styles.placeHolderStyle}
      />
    </View>
  );
};
