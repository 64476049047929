import { StyleFn } from '@hitz-group/domain';
import { PricingGroup as PricingGroupDefault } from '@hitz-group/domain';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View, Text } from 'react-native';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import { useNotification } from '../../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { PricingGroupRow } from './PricingGroupRow';
import { usePricingGroups } from '../../../../hooks/app/usePricingGroups';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import AlertModal from '../../../../components/Modals/AlertModal';
import { Operation } from '../../../../types/Operation';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { CreatePriceListModal } from './CreatePriceListModal';
import scale, { isWeb } from '../../../../common/theme';

interface PricingGroup extends PricingGroupDefault {
  isSelected?: boolean;
  isChanged?: boolean;
}

const scrollStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: isWeb ? '60%' : '100%',
  alignSelf: 'center',
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const noPricingGroupTextStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.big / 2,
  color: theme.colors.primaryLightest,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

export const PriceList: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const { showModal, closeModal } = useModal();
  const [pricingGroupsData, setPricingGroupsData] = useState<
    Record<string, PricingGroup>
  >({});

  const {
    pricingGroups,
    defaultPricingGroup,
    error,
    loading,
    operation,
    updatePricingGroups,
    clonePricingGroup,
    getAllPricingGroups,
    createPricingGroup,
    createdId,
  } = usePricingGroups();

  useEffect(() => {
    if (isFocused) {
      getAllPricingGroups();
    }
  }, [isFocused, getAllPricingGroups]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.DELETE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupsDeletedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.UPDATE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupsSavedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.CREATE && createdId) {
      closeModal();
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupCopiedSuccessfully'),
      });
      navigation.navigate('PriceListSettings', {
        pricingGroupId: createdId,
        isNavigatedFromCreate: true,
      });
    }
  }, [
    loading,
    operation,
    closeModal,
    showNotification,
    translate,
    error,
    createdId,
    navigation,
  ]);

  useEffect(() => {
    if (!error && !loading && pricingGroups) {
      setPricingGroupsData(pricingGroups);
    }
  }, [error, loading, pricingGroups]);

  const pricingGroupsArray = useMemo(() => {
    if (defaultPricingGroup) {
      const data = Object.values(pricingGroupsData || {}).filter(
        obj => obj.id !== defaultPricingGroup?.id,
      );
      [defaultPricingGroup, ...data] as PricingGroup[];
      return [defaultPricingGroup, ...data] as PricingGroup[];
    } else {
      return Object.values(pricingGroupsData || {}) as PricingGroup[];
    }
  }, [pricingGroupsData, defaultPricingGroup]);

  const onCreatePricingGroup = useCallback((): void => {
    showModal(<CreatePriceListModal onCreate={createPricingGroup} />);
  }, [createPricingGroup, showModal]);

  const onPressCopy = useCallback(
    (id: string): void => {
      if (id && pricingGroups[id]) {
        const selectedPricingGroup = pricingGroups[id];
        showModal(
          <AlertModal
            title={translate('pricings.copyPricingGroup')}
            actionTitle={translate('pricings.copy')}
            message={translate('pricings.pricingGroupCopyDescription', {
              name: selectedPricingGroup.name,
            })}
            onConfirm={() => {
              clonePricingGroup({
                id: selectedPricingGroup.id,
              });
            }}
          />,
        );
      }
    },
    [showModal, translate, clonePricingGroup, pricingGroups],
  );

  const onPressStatusChange = useCallback(
    (id: string, status: boolean) => {
      showModal(
        <ConfirmationDialog
          title={
            status
              ? translate('pricings.enable')
              : translate('pricings.disable')
          }
          message={
            status
              ? translate('pricings.enablePricingGroup')
              : translate('pricings.disablePricingGroup')
          }
          onConfirm={() => {
            closeModal();
            updatePricingGroups([{ id, isActive: status }]);
          }}
        />,
      );
    },
    [showModal, , updatePricingGroups, closeModal, translate],
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.pricingSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollStyle)}>
          <BackOfficeSection
            title={translate('pricings.pricingGroups')}
            titleDescription={translate('pricings.pricingGroupsDescription')}
            action={
              <BackOfficeCreateNewButton onPress={onCreatePricingGroup} />
            }
            contentContainerStyle={css(formStyle)}
            containerStyle={css(containerStyle)}
          >
            {(pricingGroupsArray.length < 1 && (
              <Text
                style={css(noPricingGroupTextStyle)}
                testID={'empty-pricing-groups-message'}
              >
                {translate('pricings.noPricingGroupsMessage')}
              </Text>
            )) || (
              <TableComponent
                columns={[
                  {
                    title: translate('pricings.active'),
                    width: 80,
                    alignItems: 'center',
                  },
                  {
                    title: translate('pricings.groupName'),
                    flex: 1,
                    alignItems: 'flex-start',
                    containerStyle: { paddingLeft: theme.spacing.medium },
                  },
                ]}
                data={pricingGroupsArray}
                normalRows
                columnContainerStyle={css(columnContainerStyle)}
                renderRow={(
                  item: PricingGroup,
                  index: number,
                ): React.ReactNode => (
                  <PricingGroupRow
                    pricingGroup={item}
                    key={index}
                    onPressCopy={onPressCopy}
                    onChangeStatus={onPressStatusChange}
                  />
                )}
              />
            )}
          </BackOfficeSection>
          <View style={css(bottomSpace)}></View>
        </ScrollView>
      </View>
    </>
  );
};
