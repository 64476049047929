import { Adjustment, Discount, Icons, StyleFn } from '@hitz-group/domain';
import { useCurrency } from '@hitz-group/localization';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { StyleSheet, View } from 'react-native';
import { addToValue } from '../../utils/keypadHelper';
import { is9DigitWith2Decimal } from '../../utils/validator';
import IconButton from '../Button/IconButton';
import CartSummary, { PaymentSummary } from '../CartSummary/CartSummary';
import InputDisplay from '../InputDisplay/InputDisplay';
import NumberInput from '../NumberInput/NumberInput';
import { CartKeyPadActions } from './CartKeyPadActions';

const inputContainerStyle: StyleFn = ({ theme }) => ({
  height: 70,
  backgroundColor: theme.colors.white,
  marginTop: 10,
});
const displayTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.largest,
});
const keypadStyle: StyleFn = () => ({
  flexDirection: 'row',
  marginTop: 7,
});
const keyRowStyle: StyleFn = ({ theme }) => ({
  marginTop: '5%',
  marginLeft: 3,
  width: 68,
  height: 41,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.white,
});
const numberInputStyle: StyleFn = () => ({
  flex: 1,
});
const buttonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderWidth: 0,
});
const rowStyle: StyleFn = () => ({
  marginTop: '1.70%',
});
const buttonTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
});
const cartActionButton: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.white,
});

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  keypad: {
    flex: 0.7,
  },
});

const splitIconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.background,
  padding: theme.padding.small + 2,
  transform: [{ scaleX: -1 }],
  borderRadius: theme.radius.small,
  right: 17,
});
const resetIconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.background,
  padding: theme.padding.small + 2,
  transform: [{ scaleX: -1 }],
  borderRadius: theme.radius.small,
  left: 17,
});
export interface CartKeyPadProps {
  onPressAction: (action: string) => void;
  onPressSplitProductFromCart: () => void;
  cancelActionDisabled: boolean;
  updatePriceActionDisabled: boolean;
  addActionDisable: boolean;
  subtractActionDisabled: boolean;
  splitProductDisabled: boolean;
  keypadInput?: string;
  setKeypadInput?: (value: number) => void;
  subTotal: number;
  totalDue: number;
  amountDue?: number;
  discountAmount?: number;
  surchargeAmount?: number;
  discounts?: Discount[];
  adjustments?: Adjustment[];
  paymentSummary?: PaymentSummary[];
}
const CartKeyPad: React.FC<CartKeyPadProps> = ({
  onPressAction,
  onPressSplitProductFromCart,
  cancelActionDisabled,
  updatePriceActionDisabled,
  addActionDisable,
  subtractActionDisabled,
  splitProductDisabled,
  keypadInput,
  setKeypadInput,
  discounts,
  adjustments,
  subTotal,
  totalDue,
  discountAmount,
  surchargeAmount,
  paymentSummary,
  amountDue,
}) => {
  const { css, theme } = useFela();
  const [input, setInput] = useState(keypadInput || '');
  const [showInputDisplay, setShowInputDisplay] = useState(
    !!keypadInput || false,
  );
  const { decimalSeparator, unFormatCurrency } = useCurrency();

  const iconKeys = [
    {
      icon: Icons.Delete,
      color: theme.colors.red,
      action: CartKeyPadActions.CancelAction,
      testID: CartKeyPadActions.CancelAction,
    },
    {
      icon: Icons.USDCircle,
      color: theme.colors.blue,
      action: CartKeyPadActions.UpdatePriceAction,
      testID: CartKeyPadActions.UpdatePriceAction,
    },
    {
      icon: Icons.PlusCircle,
      color: theme.colors.black,
      action: CartKeyPadActions.AddAction,
      testID: CartKeyPadActions.AddAction,
    },
    {
      icon: Icons.MinusCircle,
      color: theme.colors.black,
      action: CartKeyPadActions.SubtractAction,
      testID: CartKeyPadActions.SubtractAction,
    },
  ];

  const numberKeys = [
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '0' },
    { value: decimalSeparator },
  ];

  useEffect(() => {
    setInput(keypadInput || '');
    setShowInputDisplay(!!keypadInput);
  }, [keypadInput]);

  const onPressKey = useCallback(
    (key: string): void => {
      setShowInputDisplay(true);
      const newValue = input.concat(key);
      if (!is9DigitWith2Decimal(newValue)) return;
      setInput(value => addToValue(value.toString(), key, decimalSeparator));
      setKeypadInput && setKeypadInput(unFormatCurrency(newValue));
    },
    [input, setKeypadInput, decimalSeparator, unFormatCurrency],
  );

  const onResetAmount = useCallback(() => {
    setInput('');
    setKeypadInput && setKeypadInput(0);
    setShowInputDisplay(false);
  }, [setKeypadInput]);

  return (
    <View testID="cart-keypad-container">
      {showInputDisplay ? (
        <InputDisplay
          containerStyle={css(inputContainerStyle)}
          extraTextStyle={css(displayTextStyle)}
          value={input}
          left={
            <IconButton
              fluid
              icon={Icons.Split}
              containerStyle={css(splitIconContainerStyle)}
              onPress={() => {
                onPressSplitProductFromCart();
                onResetAmount();
              }}
              iconSize={20}
              iconColor={
                splitProductDisabled
                  ? theme.colors.paragraph
                  : theme.colors.black
              }
              testID={CartKeyPadActions.SplitAction}
              disabled={splitProductDisabled}
            />
          }
          right={
            <IconButton
              fluid
              icon={Icons.Redo}
              containerStyle={css(resetIconContainerStyle)}
              onPress={onResetAmount}
              iconSize={20}
              iconColor={theme.colors.black}
              testID={CartKeyPadActions.ResetAction}
            />
          }
        />
      ) : (
        <CartSummary
          discounts={discounts}
          subTotal={subTotal}
          totalDue={totalDue}
          discountAmount={discountAmount}
          surchargeAmount={surchargeAmount}
          paymentSummary={paymentSummary}
          adjustments={adjustments}
          amountDue={amountDue}
        />
      )}
      <View style={[css(keypadStyle), styles.fullWidth]}>
        <NumberInput
          keyWidth={68}
          keyHeight={41}
          twistKeyWidth={2.03}
          onPressKey={onPressKey}
          containerStyle={css(numberInputStyle)}
          keys={numberKeys}
          buttonStyle={css(buttonStyle)}
          rowStyle={css(rowStyle)}
          btnTextStyle={css(buttonTextStyle)}
        />
        <View>
          {iconKeys.map((iconKey, index) => {
            const isDisable =
              iconKey.action === CartKeyPadActions.CancelAction
                ? cancelActionDisabled
                : iconKey.action === CartKeyPadActions.UpdatePriceAction
                ? updatePriceActionDisabled
                : iconKey.action === CartKeyPadActions.AddAction
                ? addActionDisable
                : subtractActionDisabled;
            const iconColor =
              iconKey.action === CartKeyPadActions.CancelAction ||
              iconKey.action === CartKeyPadActions.UpdatePriceAction
                ? iconKey.color
                : addActionDisable
                ? theme.colors.paragraph
                : iconKey.color;

            const onPress = () => {
              onPressAction(iconKey.action);
              onResetAmount();
            };

            return (
              <View key={index} style={css(keyRowStyle)}>
                <IconButton
                  fluid
                  icon={iconKey.icon}
                  iconColor={iconColor}
                  containerStyle={css(cartActionButton)}
                  onPress={onPress}
                  disabled={isDisable}
                  testID={iconKey.testID}
                />
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};
export default CartKeyPad;
