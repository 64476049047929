import React, { useCallback, useEffect, useState } from 'react';
import { DeliverySettings, StyleFn, Icons } from '@hitz-group/domain';
import { getCurrencySymbol, useTranslation } from '@hitz-group/localization';
import { View, Text } from 'react-native';
import { useFela } from 'react-fela';
import ModalDialog from '../../../../../../../../components/Modals/Modal';
import Button from '../../../../../../../../components/Button/Button';
import IconButton from '../../../../../../../../components/Button/IconButton';
import FormInput from '../../../../../../../../components/FormInput/FormInput';
import { useModal } from '@hitz-group/rn-use-modal';
import { convertAlphaNumbericToNumber } from '@hitz-group/client-utils';
import * as styles from './Modal.styles';
import { useNotification } from '../../../../../../../../hooks/Notification';

const formInputViewContainerStyle: StyleFn = ({ theme }) => ({
  marginVertical: theme.spacing.small / 2,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  height: theme.input.height,
});

const prefixItemStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  padding: theme.spacing.small / 2 + 5,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.bold,
  borderRadius: theme.radius.small,
  lineHeight: 15,
});

interface DeliveryModalProps {
  deliverySettings?: DeliverySettings;
  onCancel?: () => void;
  onConfirm?: (settingName: string, settings: DeliverySettings) => void;
  currencyCode?: string;
}

const DeliveryModal: React.FC<DeliveryModalProps> = ({
  deliverySettings,
  currencyCode,
  onCancel,
  onConfirm,
}: DeliveryModalProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const [deliverySettingsData, setDeliverySettingsData] =
    useState<DeliverySettings>(deliverySettings as DeliverySettings);

  const errorValidation = useCallback(() => {
    return (
      (deliverySettingsData &&
        !deliverySettingsData.enablePayLater &&
        !deliverySettingsData.enablePayNow &&
        translate('backOfficeFeatures.noAllowedPayment')) ||
      undefined
    );
  }, [deliverySettingsData, translate]);

  const onPressCancel = useCallback(() => {
    closeModal();
    onCancel && onCancel();
  }, [onCancel, closeModal]);

  const onPressConfirm = useCallback(() => {
    const error = errorValidation();
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
      return;
    }
    closeModal();
    deliverySettingsData &&
      onConfirm &&
      onConfirm('deliverySettings', deliverySettingsData);
  }, [
    errorValidation,
    closeModal,
    onConfirm,
    deliverySettingsData,
    showNotification,
  ]);

  const onValueUpdate = useCallback((key: string, value: unknown) => {
    setDeliverySettingsData(settingData => ({ ...settingData, [key]: value }));
  }, []);

  useEffect(() => {
    deliverySettings && setDeliverySettingsData(deliverySettings);
  }, [deliverySettings]);

  return (
    <ModalDialog onDismiss={onPressCancel} showCloseButton={false}>
      <Text style={css(styles.headingStyle)} testID={'deliveryModal-title'}>
        {translate('backOfficeFeatures.orderTypesSettings', {
          orderType: translate('backOfficeFeatures.delivery'),
        })}
      </Text>
      <View style={css(styles.contentContainerStyle)}>
        <View style={css(formInputViewContainerStyle)}>
          <FormInput
            error={false}
            placeholder={translate(
              'backOfficeFeatures.deliverySettings.deliveryFee',
            )}
            title={translate('backOfficeFeatures.deliverySettings.deliveryFee')}
            alignTitle="left"
            prefix={{
              text: getCurrencySymbol(currencyCode),
              textStyle: css(prefixItemStyle),
            }}
            containerStyle={css(formInputContainerStyle)}
            value={(deliverySettingsData?.deliveryFee || '0').toString()}
            onChangeText={value =>
              onValueUpdate('deliveryFee', convertAlphaNumbericToNumber(value))
            }
          />
        </View>
        <View style={css(formInputViewContainerStyle)}>
          <FormInput
            error={false}
            placeholder={translate(
              'backOfficeFeatures.deliverySettings.deliveryTime',
            )}
            title={translate(
              'backOfficeFeatures.deliverySettings.deliveryTime',
            )}
            alignTitle="left"
            containerStyle={css(formInputContainerStyle)}
            value={(deliverySettingsData?.deliveryTime || '0').toString()}
            onChangeText={value =>
              onValueUpdate('deliveryTime', convertAlphaNumbericToNumber(value))
            }
          />
        </View>
        <View style={css(styles.rowStyle)}>
          <IconButton
            primary
            testID={'deliveryModal-payLater'}
            icon={
              (deliverySettingsData?.enablePayLater && Icons.ToggleOn) ||
              Icons.ToggleOff
            }
            iconSize={26}
            containerSize={34}
            iconColor={
              deliverySettingsData?.enablePayLater
                ? theme.colors.green
                : theme.colors.paragraph
            }
            containerStyle={css(styles.iconContainerStyle)}
            onPress={() =>
              onValueUpdate(
                'enablePayLater',
                !deliverySettingsData?.enablePayLater,
              )
            }
          />

          <View style={css(styles.defaultContainerStyle)}>
            <Text style={css(styles.defaultTextStyle)}>
              {translate('backOfficeFeatures.allowPayLater')}
            </Text>
          </View>
        </View>
        <View style={css(styles.rowStyle)}>
          <IconButton
            primary
            testID={'deliveryModal-payNow'}
            icon={
              (deliverySettingsData?.enablePayNow && Icons.ToggleOn) ||
              Icons.ToggleOff
            }
            iconSize={26}
            containerSize={34}
            iconColor={
              deliverySettingsData?.enablePayNow
                ? theme.colors.green
                : theme.colors.paragraph
            }
            containerStyle={css(styles.iconContainerStyle)}
            onPress={() =>
              onValueUpdate('enablePayNow', !deliverySettingsData?.enablePayNow)
            }
          />

          <View style={css(styles.defaultContainerStyle)}>
            <Text style={css(styles.defaultTextStyle)}>
              {translate('backOfficeFeatures.allowPayNow')}
            </Text>
          </View>
        </View>
      </View>
      <View style={css(styles.actionContainerStyle)}>
        <Button
          size="small"
          testID="cancel-button"
          title={translate('settings.cancel').toUpperCase()}
          onPress={onPressCancel}
          labelStyle={css(styles.buttonStyle, styles.dismissButtonStyle)}
          containerStyle={css(styles.buttonContainerStyle)}
          color={theme.colors.white}
        />
        <Button
          size="small"
          testID="confirm-button"
          title={translate('settings.confirm').toUpperCase()}
          onPress={onPressConfirm}
          labelStyle={css(styles.buttonStyle, styles.confirmButtonStyle)}
          containerStyle={css(styles.buttonContainerStyle)}
          color={theme.colors.blue}
        />
      </View>
    </ModalDialog>
  );
};

export default DeliveryModal;
