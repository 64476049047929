import {
  StyleFn,
  CreateProductInput,
  Money,
  UpdatePageInput,
  Colors,
  CreatePageInput,
  Page,
} from '@hitz-group/domain';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../components/Button/Button';
import DropDown from '../../../components/FormInput/DropDown';
import scale, { isWeb } from '../../../common/theme';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../hooks/Notification';
import { usePages } from '../../../hooks/app/pages/usePages';
import { Operation } from '../../../types/Operation';
import { colorOptions, PAGE_TYPE } from '../menuTypes';
import FormInput from '../../../components/FormInput/FormInput';

export interface CreateProductInterface extends CreateProductInput {
  sellingPrice: Money;
  sellingTax: string;
  isVariant: boolean;
  editMore?: boolean;
  taxInclusive: boolean;
}

interface MenuPageModalProps {
  id?: string;
  onClose?: () => void;
  pageOptions: { value: string; label: string }[];
  rootPage?: string;
  onPageAdd?: (pageData: Page, pageType: PAGE_TYPE) => void;
  pageType?: PAGE_TYPE;
}

const buttonStyle: StyleFn = ({ theme, marginLeft, backgroundColor }) => ({
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: backgroundColor || theme.colors.successLight,
  marginLeft: marginLeft || 0,
  marginTop: theme.spacing.medium,
});

const updateProductLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  height: theme.input.height,
});

const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  borderWidth: 1,
});

const dropdownStyle: StyleFn = () => ({
  marginTop: isWeb ? scale.moderateScale(10) : 0,
});

const halfSizedStyleRight1: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  marginBottom: theme.spacing.small,
  width: '100%',
});

const inputStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
});

const createNewFormTextStyle: StyleFn = () => ({
  alignSelf: 'flex-start',
});

export const MenuPageModal: React.FC<MenuPageModalProps> = ({
  id,
  pageOptions,
  rootPage,
  onPageAdd,
  onClose,
  pageType,
}: MenuPageModalProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [addSubPageStatus, setAddSubPageStatus] = useState(false);
  const [pageForm, setPageForm] = useState<{
    color?: string;
    id?: string;
    name?: string;
  }>({
    id: id || pageOptions?.[0]?.value,
    color: Colors.teal,
  });

  const {
    loading,
    updatePages,
    operation,
    getPage,
    pages,
    createPages,
    addOrRemoveAvailability,
    createdIds,
  } = usePages();

  useEffect(() => {
    setPageForm(prev => ({ ...prev, id }));
  }, [id]);

  useEffect(() => {
    if (pageForm.id) {
      getPage(pageForm.id);
    }
  }, [getPage, pageForm.id]);

  const isUpdated = !loading && operation === Operation.UPDATE;

  const isCreated = !loading && operation === Operation.CREATE;

  const onPageCreation = useCallback(() => {
    pages?.[createdIds?.[0]] &&
      onPageAdd &&
      pageType &&
      onPageAdd(pages[createdIds?.[0]], pageType);

    onClose && onClose();
  }, [createdIds, onClose, onPageAdd, pages, pageType]);

  const onPageUpdated = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    if (isUpdated && addSubPageStatus) {
      showNotification({
        success: true,
        message: translate('backOfficeProducts.pageCreatedSuccessfully'),
      });
      onPageCreation();
    } else if (isUpdated) {
      showNotification({
        success: true,
        message: translate('productBulkOperations.successfullyUpdated'),
      });
      onPageUpdated();
    }
  }, [
    addSubPageStatus,
    isUpdated,
    showNotification,
    translate,
    onPageCreation,
    onPageUpdated,
  ]);

  useEffect(() => {
    if (isCreated && createdIds?.length) {
      if (rootPage) {
        addOrRemoveAvailability({
          pageId: rootPage,
          addPages: createdIds,
          addVariants: [],
          addProductIds: [],
          removePages: [],
          removeProducts: [],
          removeVariants: [],
        });
        setAddSubPageStatus(true);
      } else {
        showNotification({
          success: true,
          message: translate('backOfficeProducts.pageCreatedSuccessfully'),
        });
        onPageCreation();
      }
    }
  }, [
    addOrRemoveAvailability,
    createdIds,
    createdIds?.length,
    isCreated,
    onClose,
    onPageAdd,
    onPageCreation,
    pages,
    rootPage,
    showNotification,
    translate,
  ]);

  const onChange = useCallback((prop: string, value) => {
    setPageForm(prev => {
      return { ...prev, [prop]: value };
    });
  }, []);

  const onSavePage = useCallback(() => {
    if (pageForm.id && pageForm.color && pages[pageForm.id]) {
      const pageDataInput = {
        id: pageForm.id,
        color: pageForm.color,
        name: pages[pageForm.id].name,
      } as UpdatePageInput;
      updatePages([pageDataInput]);
    }
  }, [pageForm.color, pageForm.id, pages, updatePages]);

  const onCreatePage = useCallback(() => {
    if (pageForm.name && pageForm.color) {
      const pageDataInput = {
        color: pageForm.color,
        name: pageForm.name,
      } as CreatePageInput;
      createPages([pageDataInput]);
    }
  }, [createPages, pageForm.color, pageForm.name]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {(id && (
        <DropDown
          title={translate('menus.selectPage')}
          values={pageOptions}
          extraStyle={css(dropdownExtraStyle)}
          extraViewStyle={css(dropdownStyle)}
          extraMainViewStyle={css(dropDownMainViewStyle)}
          selectedValue={pageForm.id || id}
          style={css(halfSizedStyleRight1)}
          onValueChange={onChange.bind(null, 'id')}
        />
      )) || (
        <FormInput
          title={translate('backOfficeProducts.pageName')}
          placeholder={translate('backOfficeProducts.pageName')}
          value={pageForm.name}
          containerStyle={css(inputStyle)}
          textStyle={css(createNewFormTextStyle)}
          onChangeText={onChange.bind(null, 'name')}
        />
      )}

      <DropDown
        title={translate('menuEditor.tileColor')}
        values={colorOptions}
        extraStyle={css(dropdownExtraStyle)}
        extraViewStyle={css(dropdownStyle)}
        extraMainViewStyle={css(dropDownMainViewStyle)}
        selectedValue={pageForm.color}
        style={css(halfSizedStyleRight1)}
        onValueChange={onChange.bind(null, 'color')}
      />
      {(id && (
        <Button
          title={translate('productSettings.update')}
          containerStyle={css(buttonStyle)}
          labelStyle={css(updateProductLabelStyle)}
          onPress={onSavePage}
        />
      )) || (
        <Button
          title={translate('productSettings.create')}
          containerStyle={css(buttonStyle)}
          labelStyle={css(updateProductLabelStyle)}
          onPress={onCreatePage}
        />
      )}
    </>
  );
};
