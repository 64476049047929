import { useTranslation } from '@hitz-group/localization';
import React, { useCallback } from 'react';
import { StyleFn, MoneyMovement } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import Button from '../Button/Button';
import TableComponent from '../TableComponent/TableComponent';
import { useModal } from '@hitz-group/rn-use-modal';
import MoneyEventModal from './MoneyEventModal';
import Icon from '../Icon/Icon';
import MoneyMovementRow from './MoneyMovementRow';
import PaginatedView from '../PaginatedView/PaginatedView';

export interface MoneyMovementsListProps {
  data: MoneyMovement[];
}

const containerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  marginBottom: theme.spacing.medium * 1.25,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.padding.large,
  marginVertical: theme.spacing.small * 1.5,
  height: 44,
  borderBottomWidth: 0,
  marginRight: 0,
  borderRadius: theme.radius.small,
});

const addEventButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.spacing.medium * 10,
  marginVertical: theme.spacing.small * 1.5,
});
const addEventLabelStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
});

const messageContainerStyle: StyleFn = () => ({
  width: 401,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  flex: 1,
});

const messageStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 174,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.white,
  borderWidth: 1,
  borderColor: theme.colors.boxBorder,
  marginBottom: theme.spacing.medium - 1,
  justifyContent: 'space-evenly',
  alignItems: 'center',
  paddingVertical: theme.padding.medium * 3,
});
const messageTextStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.medium,
  lineHeight: 24,
  letterSpacing: -0.4,
  color: theme.colors.primary,
});
const noteColStyle: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.small * 1.5,
});

const MoneyMovementsList: React.FC<MoneyMovementsListProps> = ({
  data,
}: MoneyMovementsListProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showModal } = useModal();

  const onPressAddNewEvent = useCallback(
    () => showModal(<MoneyEventModal />),
    [showModal],
  );

  const renderMoneyMovements = useCallback(
    (items: MoneyMovement[]) => (
      <TableComponent
        columnSpacing={25}
        columns={[
          {
            title: translate('moneyMovements.event'),
            width: 56,
            alignItems: 'flex-start',
          },
          {
            title: translate('moneyMovements.timestamp'),
            width: 150,
            alignItems: 'flex-start',
          },
          {
            title: translate('moneyMovements.user'),
            flex: 1,
            alignItems: 'flex-start',
          },
          {
            title: translate('moneyMovements.reason'),
            width: 100,
            alignItems: 'center',
          },
          {
            title: translate('moneyMovements.amount'),
            width: 130,
            alignItems: 'flex-end',
          },
          {
            title: translate('moneyMovements.method'),
            width: 105,
            alignItems: 'center',
          },
          {
            title: translate('moneyMovements.note'),
            width: 38,
            alignItems: 'center',
            containerStyle: css(noteColStyle),
          },
          {
            title: translate('moneyMovements.print'),
            width: 38,
            alignItems: 'center',
          },
        ]}
        data={items}
        renderRow={(item: MoneyMovement, index: number): React.ReactNode => (
          <MoneyMovementRow key={index} moneyMovement={item} />
        )}
        columnContainerStyle={css(columnContainerStyle)}
        normalRows
      />
    ),
    [css, translate],
  );

  return (
    <View style={css(containerStyle)}>
      {data.length > 0 ? (
        <>
          <PaginatedView
            data={data}
            rowHeight={65}
            renderItems={renderMoneyMovements}
            action={
              <Button
                testID={'addNewEvent'}
                success
                size="small"
                onPress={onPressAddNewEvent}
                title={translate('moneyMovements.addNewEvent')}
                containerStyle={css(addEventButtonStyle)}
                labelStyle={css(addEventLabelStyle)}
              />
            }
          />
        </>
      ) : (
        <View style={css(messageContainerStyle)}>
          <View style={css(messageStyle)}>
            <Icon
              name="exclamation-triangle"
              color={theme.colors.warning}
              size={39}
            />
            <Text style={css(messageTextStyle)}>
              {translate('moneyMovements.noEventsMessage')}
            </Text>
          </View>
          <Button
            success
            fluid
            size="small"
            onPress={onPressAddNewEvent}
            title={translate('moneyMovements.addNewEvent')}
            labelStyle={css(addEventLabelStyle)}
          />
        </View>
      )}
    </View>
  );
};

export default MoneyMovementsList;
