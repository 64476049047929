import * as React from 'react';
import {
  StyleProp,
  Text,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { useFela, withTheme } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';

export type DataTableBodyCellProps = {
  children: React.ReactNode;
  alignRight?: boolean;
  option?: boolean;
  onPress?: () => void;
  numberOfLines?: number;
  style?: StyleProp<ViewStyle>;
  small?: boolean;
  center?: boolean;
  clickable?: boolean;
};

const smallContainer: StyleFn = () => ({
  flex: 2,
});

const optionsContainer: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
});

const openRowButtonContainer: StyleFn = () => ({
  flex: 0.2,
  justifyContent: 'flex-end',
});

const container: StyleFn = () => ({
  flex: 3.6,
  flexDirection: 'row',
  alignContent: 'center',
  justifyContent: 'center',
});

const right: StyleFn = () => ({
  justifyContent: 'flex-end',
});

const centerAlign: StyleFn = () => ({
  justifyContent: 'center',
});

const DataTableBodyCell: React.FC<DataTableBodyCellProps> = props => {
  const { css } = useFela();
  const {
    children,
    alignRight,
    onPress,
    numberOfLines,
    style,
    option,
    small,
    center,
    clickable,
    ...rest
  } = props;

  return (
    <TouchableWithoutFeedback disabled={!onPress} onPress={onPress} {...rest}>
      <View
        style={[
          css(container),
          option && css(optionsContainer),
          small && css(smallContainer),
          alignRight && css(right),
          style,
          option && center && css(centerAlign),
          clickable && css(openRowButtonContainer),
        ]}
      >
        <Text numberOfLines={numberOfLines}>{children}</Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default withTheme(DataTableBodyCell);
export { DataTableBodyCell };
