import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';

export const PrintersListRowStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        rowContainerStyle: {
          flex: 1,
          justifyContent: 'space-between',
        },
        nameViewStyle: {
          height: theme.input.height,
          flex: 2,
        },
        ipAddressViewStyle: {
          height: theme.input.height,
          width: 180,
        },
        printerTypeViewStyle: {
          height: theme.input.height,
          width: 180,
          marginLeft: theme.padding.small,
        },
        actionsViewStyle: {
          height: theme.input.height,
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'flex-end',
        },
        closeIconContainerStyle: {
          alignSelf: 'flex-end',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.danger2,
          marginLeft: theme.spacing.small,
        },
        fieldInputContainerStyle: {
          width: '98%',
          height: theme.input.height,
        },
        fieldDropDownContainerStyle: {
          width: '90%',
          height: theme.input.height,
          bottom: 10,
        },
        itemContainer: {
          height: rowHeight * 3,
        },
        extraPopoverStyle: {
          width: 160,
        },
        formTextStyle: {
          paddingHorizontal: 0,
          paddingVertical: 0,
        },
        copyContainerStyle: {
          alignSelf: 'flex-end',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.highlighted,
        },
        navigateContainerStyle: {
          alignSelf: 'flex-end',
          borderRadius: theme.radius.small,
          marginLeft: theme.spacing.small,
        },
        closeIconStyle: {
          color: theme.colors.red,
        },
        copyIconStyle: {
          color: theme.colors.blue,
        },
      }),
    [theme],
  );
};

export const PrintersListStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        mainView: {
          backgroundColor: theme.colors.white,
          flex: 1,
          paddingHorizontal: theme.padding.large,
        },
        containerStyle: {
          width: '100%',
          alignSelf: 'center',
          maxWidth: 800,
        },
        columnContainerStyle: {
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: theme.spacing.small,
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          borderBottomWidth: 0,
          marginTop: theme.spacing.medium,
        },
        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
          flexDirection: 'row-reverse',
        },
        actionContainerStyle: {
          maxWidth: 200,
          height: theme.input.height,
          backgroundColor: theme.colors.white,
          paddingRight: theme.spacing.medium,
        },
        saveButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginRight: theme.spacing.medium,
          alignSelf: 'center',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
      }),
    [theme],
  );
};
