import { StyleFn, Section, UpdateSectionInput } from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import {
  useNavigation,
  useRoute,
  useIsFocused,
} from '@react-navigation/native';
import Button from '../../../../../components/Button/Button';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import { useSections } from '../../../../../hooks/app/sections/useSections';
import SectionRow from './SectionRow';
import { pick } from 'lodash';
import { Operation } from '../../../../../types/Operation';
import { useStores } from '../../../../../hooks/app/useStores';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingVertical: theme.spacing.big,
  paddingHorizontal: theme.padding.large,
});

const scrollStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
  alignItems: 'flex-start',
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

type FormState = Record<string, Section & { isChanged: boolean }>;

export const SectionManagement: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState<FormState>({});
  const route = useRoute();
  const navigation = useNavigation();
  const isFocussed = useIsFocused();

  const params = route.params as {
    venueId: string;
    storeId?: string;
  };

  const venueId = params.venueId || '';
  const storeId = params?.storeId || '';

  const {
    loading: loadingSection,
    error: errorSection,
    sections,
    getSections,
    updateSections,
    updatedSectionIds,
    copySection,
  } = useSections({ venueId });

  const {
    stores,
    getStores,
    loading: loadingStores,
    error: errorStores,
  } = useStores();

  const loading = loadingStores || loadingSection;

  const error = errorStores || errorSection;

  useEffect(() => {
    if (isFocussed) {
      getSections();
      getStores();
    }
  }, [isFocussed, getSections, getStores]);

  useEffect(() => {
    if (sections) {
      setForm(sections as FormState);
    }
  }, [sections]);

  const venueStores = useMemo(() => {
    const storesData = Object.values(stores || []).filter(
      store => store?.venue?.id === venueId,
    );
    return storesData;
  }, [venueId, stores]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedSectionIds.length > 0) {
      showNotification({
        success: true,
        message: translate('backOfficeSections.sectionUpdatedSuccessfully'),
      });
    }
  }, [updatedSectionIds, showNotification, translate]);

  const onChange = useCallback(
    (id: string, prop: string, value: string): void => {
      setForm(form => ({
        ...form,
        [id]: {
          ...form[id],
          [prop]: value,
          isChanged: true,
        },
      }));
    },
    [],
  );

  const onStoresSelect = useCallback(
    (id: string, values: string[]) => {
      const selectedStores = venueStores.filter(store =>
        values.includes(store.id),
      );
      setForm(form => ({
        ...form,
        [id]: {
          ...form[id],
          stores: selectedStores,
          isChanged: true,
        },
      }));
    },
    [venueStores],
  );

  const onSectionCopy = useCallback(
    (section: Section): void => {
      copySection(section.id);
    },
    [copySection],
  );

  const onPressCreateNew = useCallback((): void => {
    const paramsToNavigate = {
      venueId,
      operation: Operation.CREATE,
      storeId: storeId,
      fromStoreSection: Boolean(storeId),
    };
    navigation.navigate('SectionSettings', paramsToNavigate);
  }, [navigation, venueId, storeId]);

  const onPressSave = useCallback((): void => {
    const data = Object.values(form)
      .filter(section => section.isChanged)
      .map(
        section =>
          ({
            ...pick(section, ['id', 'name']),
            venue: venueId,
            stores: section.stores.map(({ id }) => id),
          } as UpdateSectionInput),
      );

    if (data.length > 0) {
      const lowerCasedSectionNames = Object.values(form).map(({ name }) =>
        (name || '').toLowerCase(),
      );
      const isDuplicateSectionName = lowerCasedSectionNames.some(
        (element, index) => {
          return lowerCasedSectionNames.indexOf(element) !== index;
        },
      );

      if (data.some(section => !section.name)) {
        showNotification({
          error: true,
          message: translate('backOfficeSections.sectionNameIsMissing'),
        });
      } else if (isDuplicateSectionName) {
        showNotification({
          error: true,
          message: translate('backOfficeSections.sectionWithSameNameExists'),
        });
      } else {
        updateSections(data);
      }
    }
  }, [form, showNotification, updateSections, venueId, translate]);

  const sectionsData = useMemo(() => Object.values(form), [form]);

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollStyle)}>
          <BackOfficeSection
            title={translate('backOfficeSettings.manageSection.sections')}
            titleDescription={translate(
              'backOfficeSettings.manageSection.sectionDescription',
            )}
            action={<BackOfficeCreateNewButton onPress={onPressCreateNew} />}
          >
            <TableComponent
              columns={[
                {
                  title: translate('backOfficeSettings.manageSection.section'),
                  width: 260,
                  containerStyle: { paddingLeft: theme.spacing.big / 2 },
                },
                {
                  title: translate(
                    'backOfficeSettings.manageSection.storeAvailability',
                  ),
                  flex: 1,
                  alignItems: 'flex-start',
                  containerStyle: { paddingLeft: theme.spacing.medium },
                },
                {
                  title: translate('backOfficeSettings.manageSection.tables'),
                  width: 270,
                },
              ]}
              normalRows
              columnSpacing={0}
              data={sectionsData}
              columnContainerStyle={css(columnContainerStyle)}
              renderRow={(item: Section, index: number): React.ReactNode => (
                <SectionRow
                  sectionData={item}
                  key={index}
                  index={index}
                  onChange={onChange}
                  stores={venueStores}
                  onStoresSelect={onStoresSelect}
                  onSectionCopy={onSectionCopy}
                />
              )}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>
      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
