import * as React from 'react';
import {
  View,
  TouchableWithoutFeedback,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { useFela, withTheme } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
export type DataTableRowProps = {
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
  onPress?: () => void;
  rowIndex?: number;
};

const container: StyleFn = ({ theme }) => ({
  minHeight: theme.spacing.medium,
  paddingHorizontal: theme.padding.small * 3,
});

const content: StyleFn = ({ theme, rowIndex }) => ({
  flex: 1,
  minHeight: 42,
  flexDirection: 'row',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 0.8,
  borderStyle: 'solid',
  paddingTop: theme.padding.medium,
  zIndex: 2000 - 20 * rowIndex,
});

const DataTableRow: React.FC<DataTableRowProps> = props => {
  const { css, theme } = useFela();
  const { style, onPress, ...rest } = props;
  return (
    <TouchableWithoutFeedback
      {...rest}
      onPress={onPress}
      style={css(container)}
    >
      <View style={[css(content({ theme, rowIndex: props.rowIndex })), style]}>
        {props.children}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default withTheme(DataTableRow);

export { DataTableRow };
