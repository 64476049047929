import * as React from 'react';
import { StyleProp, View, ViewStyle, Text } from 'react-native';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import IconButton from '../Button/IconButton';
import { useCallback } from 'react';

export type TablePaginationProps = {
  page: number;
  numberOfPages: number;
  onPageChange: (page: number) => void;
  style?: StyleProp<ViewStyle>;
  startIndex: number;
  endIndex: number;
  total: number;
  onPressNext?: (page: number) => void;
  hideTotalItemsCount?: boolean;
  isGreyPagination?: boolean;
  testID?: string;
};

const container: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  flexDirection: 'row',
  width: '100%',
  height: 45,
  alignItems: 'center',
  borderRadius: theme.radius.small,
});

const ContainerStyle: StyleFn = ({ theme, isGreyPagination }) => ({
  backgroundColor: isGreyPagination
    ? theme.colors.greyLight
    : theme.colors.white,
  height: theme.input.height,
  width: 50,
  marginLeft: isGreyPagination ? 0 : 5,
  borderRadius: isGreyPagination ? 0 : theme.radius.small,
});

const textStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.text,
  paddingLeft: theme.padding.medium,
});

const iconContainer: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'flex-end',
});

const pageNumberContainer: StyleFn = ({ theme, isGreyPagination }) => ({
  flex: 1,
  borderRadius: isGreyPagination ? 0 : theme.radius.small,
  height: theme.input.height,
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: isGreyPagination
    ? theme.colors.greyLight
    : theme.colors.white,
});

const TablePagination: React.FC<TablePaginationProps> = props => {
  const {
    page,
    numberOfPages,
    onPageChange,
    onPressNext,
    style,
    hideTotalItemsCount,
    isGreyPagination,
    ...rest
  } = props;
  const { css } = useFela({ isGreyPagination });
  const { startIndex, endIndex, total } = props;
  const endRange = endIndex < total ? endIndex : total;
  const { theme } = useFela();

  const fetchNextPage = useCallback(() => {
    onPageChange(page + 1);
    onPressNext && onPressNext(page + 1);
  }, [onPageChange, onPressNext, page]);

  const handlePressBack = useCallback(() => {
    onPageChange(page - 1);
  }, [onPageChange, page]);

  return (
    <View {...rest} style={[css(container), style]}>
      <View style={css(pageNumberContainer)}>
        <Text style={css(textStyle)} testID={'page-count'}>
          {hideTotalItemsCount
            ? `Showing first ${total}`
            : `Showing ${startIndex + 1} - ${endRange} of ${total}`}
        </Text>
      </View>
      <View style={css(iconContainer)}>
        <IconButton
          testID={'back-button'}
          disabled={page <= 1}
          icon={'arrow-left'}
          iconSize={24}
          containerSize={38}
          containerStyle={css(ContainerStyle)}
          iconStyle={{
            color: page <= 1 ? theme.colors.darkGrey : theme.colors.black,
          }}
          onPress={handlePressBack}
        />
        <IconButton
          testID={'next-button'}
          disabled={page >= numberOfPages}
          icon={'arrow-right'}
          iconSize={24}
          containerSize={38}
          containerStyle={css(ContainerStyle)}
          iconStyle={{
            color:
              page >= numberOfPages
                ? theme.colors.darkGrey
                : theme.colors.black,
          }}
          onPress={fetchNextPage}
        />
      </View>
    </View>
  );
};

export default TablePagination;
