import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';
import scale from '../../../../../common/theme';

export const ProfitMarginsStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        containerStyle: {
          width: '100%',
          alignSelf: 'center',
          maxWidth: 540,
        },
        messagePageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
          justifyContent: 'center',
          textAlign: 'center',
        },
        columnContainerStyle: {
          flex: 1,
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          borderBottomWidth: 0,
          paddingHorizontal: theme.spacing.big,
          marginTop: theme.spacing.medium,
        },
        bottomSpace: {
          height: scale.moderateScale(30),
        },
        mainStyle: {
          backgroundColor: theme.colors.white,
        },
        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
          flexDirection: 'row-reverse',
        },
        saveButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          alignSelf: 'center',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
        },
        actionContainerStyle: {
          zIndex: -1,
          maxWidth: 200,
          height: rowHeight,
          backgroundColor: theme.colors.white,
          paddingRight: theme.spacing.medium,
        },
        container: {
          alignSelf: 'center',
          paddingHorizontal: 100,
        },
      }),
    [theme],
  );
};

export const ProfitMarginRowStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        rowContainerStyle: {
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        productTypeViewStyle: {
          height: theme.input.height,
          flex: 2,
        },
        fieldInputContainerStyle: {
          alignSelf: 'flex-start',
          width: '90%',
          height: theme.input.height,
        },
        fielddropDownContainerStyle: {
          alignSelf: 'flex-start',
          width: '98%',
          height: theme.input.height,
          bottom: 10,
        },
        itemContainer: {
          height: rowHeight * 3,
        },
        extraPopoverStyle: {
          width: 400,
        },
        formTextStyle: {
          paddingHorizontal: 0,
          paddingVertical: 0,
        },
        textAvatarProductItemStyle: {
          color: theme.colors.paragraph,
          backgroundColor: theme.colors.greyLight,
          padding: theme.spacing.small / 2 + 6,
          marginRight: theme.spacing.small / 2,
          fontFamily: theme.font.bold,
          borderRadius: theme.radius.small,
          lineHeight: 15,
        },
        marginViewStyle: {
          height: theme.input.height,
          width: 150,
        },
        actionsViewStyle: {
          flex: 1,
          height: theme.input.height,
        },
        closeIconContainerStyle: {
          alignSelf: 'flex-end',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.danger2,
        },
        closeIconStyle: {
          color: theme.colors.red,
        },
      }),
    [theme],
  );
};
