import React from 'react';
import { ManageUser } from './ManageUsers';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';

const Tab = createMaterialTopTabNavigator();

const UsersTab: React.FC = () => {
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} showBack={false} />
      )}
      initialRouteName="ManageUsersTab"
    >
      <Tab.Screen
        name="ManageUsersTab"
        component={ManageUser}
        options={{ tabBarLabel: 'Manage Users' }}
      />
    </Tab.Navigator>
  );
};

export default UsersTab;
