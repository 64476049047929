import React, { useMemo } from 'react';
import { View, Text, TextStyle, ViewStyle } from 'react-native';
import { FelaComponent } from 'react-fela';
import { Colors, StyleFn, RenderProps } from '@hitz-group/domain';
export interface TextProps {
  color?: Colors;
  value?: string | number;
  textStyle?: TextStyle;
}

const colorPool = [
  [Colors.primary, Colors.primaryLightGrey],
  [Colors.amber, Colors.amberLight],
  [Colors.purple, Colors.purpleLight],
  [Colors.green, Colors.greenLight],
  [Colors.blue, Colors.blueLight],
  [Colors.orange, Colors.orangeLight],
  [Colors.teal, Colors.tealLight],
  [Colors.indigo, Colors.indigoLight],
  [Colors.red, Colors.redLight],
];

export interface LabelProps extends TextProps {
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
  colorKey?: number;
  testID?: string;
  containerStyle?: ViewStyle;
  extraStyleLabel?: ViewStyle;
}

const labelStyle: StyleFn = ({ theme, value, color }) => ({
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: theme.radius.small,
  minWidth: 56,
  paddingHorizontal: theme.spacing.small,
  paddingVertical: theme.spacing.small * (value ? 0.2 : 1),
  backgroundColor: color,
});

const labelTextStyle: StyleFn = ({ theme, color }) => ({
  color,
  lineHeight: 21,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.medium,
  textTransform: 'uppercase',
});

const LabelText: React.FC<TextProps> = (props: TextProps) => (
  <FelaComponent style={labelTextStyle} {...props}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text numberOfLines={1} style={[style, props.textStyle]} {...props} />
    )}
  </FelaComponent>
);

const Label: React.FC<LabelProps> = ({
  value,
  textStyle,
  colorKey,
  containerStyle,
  success,
  warning,
  danger,
  testID,
  extraStyleLabel,
}: LabelProps) => {
  const colorIndex = useMemo(() => {
    return colorKey ? colorKey : success ? 3 : warning ? 5 : danger ? 8 : 0;
  }, [colorKey, success, warning, danger]);
  return (
    <FelaComponent
      style={extraStyleLabel ? extraStyleLabel : labelStyle}
      value={value}
      color={colorPool[colorIndex][1]}
    >
      {({ style }: RenderProps): React.ReactFragment => (
        <View style={[style, containerStyle]} testID={testID}>
          <LabelText textStyle={textStyle} color={colorPool[colorIndex][0]}>
            {value}
          </LabelText>
        </View>
      )}
    </FelaComponent>
  );
};

export default Label;
