import {
  Order,
  OrderStatus,
  DateFilter,
  IntegrationApps,
  OrderTypeCode,
} from '@hitz-group/domain';
import orderBy from 'lodash/orderBy';
import { OnlineOrdersDataProps } from '../screens/POS/Orders/OnlineOrders/OrdersListView';

export const mapOrderToOnlineOrdersView = (
  order: Order,
): OnlineOrdersDataProps => ({
  id: order?.id || '',
  orderNumber: order.orderNumber,
  orderItems: order.orderItems,
  createdAt: order.createdAt,
  updatedAt: order.updatedAt || order.createdAt,
  customer: order?.customer?.firstName
    ? order.customer.firstName + ' ' + order.customer.lastName
    : 'N/A',
  age: '00:00',
  channel: order?.salesChannel?.name || '',
  type: order?.orderType?.name || '',
  amount: order.totalPrice,
  status: order.status,
  scheduled: order?.requiredAt || order.createdAt,
  ...(order?.amountDue && { amountDue: order?.amountDue }),
});

export const isValidOnlineOrder = (
  order: Order,
  isTableViewEnabled?: boolean,
): boolean => {
  if (order.integrationInfo?.app !== IntegrationApps.OOLIO_STORE) return true;

  // if tables and sections enabled and dine in order
  // we should show oolio orders on table view screen
  const showOolioTableOrders =
    isTableViewEnabled &&
    order.orderType?.code === OrderTypeCode.DINE_IN &&
    order.status === OrderStatus.IN_PROGRESS;

  // Oolio store order case
  // if payment is done
  if (
    order.payments.length &&
    order.amountDue !== undefined &&
    order.amountDue <= 0 &&
    !showOolioTableOrders
  )
    return true;

  // it should have customer
  if (!!order.customer && !showOolioTableOrders) return true;
  return false;
};

export const filterOnlineOrders = (
  orders: Order[],
  filter: {
    searchValue: string;
    status: string;
    day: string;
    salesChannel: string;
    isTableViewEnabled?: boolean;
  },
): OnlineOrdersDataProps[] => {
  const {
    searchValue,
    status,
    day: selectedDay,
    salesChannel,
    isTableViewEnabled,
  } = filter;
  return orderBy(
    orders
      .filter(eachOrder => {
        let isStatusQualified = [
          OrderStatus.IN_PROGRESS,
          OrderStatus.CREATED,
        ].includes(eachOrder.status);

        if (!isValidOnlineOrder(eachOrder, isTableViewEnabled)) {
          return false;
        }

        let isQualifiedDate = false;

        let isQualifiedSalesChannel = true;

        if (status) {
          isStatusQualified = status === eachOrder.status;
        }

        const dayStart = new Date().setHours(0, 0, 1);
        const endOfDay = new Date().setHours(23, 59, 59, 999);

        if (selectedDay === DateFilter.TODAY) {
          const orderTime = eachOrder.requiredAt || eachOrder.createdAt;
          isQualifiedDate =
            (orderTime && dayStart <= orderTime && orderTime <= endOfDay) ||
            false;
        }

        if (selectedDay === DateFilter.UPCOMING) {
          const orderTime = eachOrder.requiredAt;
          isQualifiedDate = (orderTime && orderTime > endOfDay) || false;
        }

        if (selectedDay === DateFilter.OLDER) {
          const orderTime = eachOrder.requiredAt || eachOrder.createdAt;
          isQualifiedDate = (orderTime && orderTime < dayStart) || false;
        }

        if (salesChannel) {
          isQualifiedSalesChannel =
            eachOrder?.salesChannel?.id === salesChannel;
        }

        if (isStatusQualified && isQualifiedDate && isQualifiedSalesChannel) {
          if (searchValue) {
            // query might be customer name, phone , product name, till id, partner id

            const query = searchValue.toLowerCase();

            const nameMatch =
              `${eachOrder.customer?.firstName} ${eachOrder.customer?.lastName}`
                ?.toLowerCase()
                .includes(query);

            if (nameMatch) {
              return true;
            }

            const phoneMatch = eachOrder?.customer?.phone?.includes(query);

            if (phoneMatch) {
              return true;
            }

            const productNameMatch = eachOrder.orderItems.find(eachItem =>
              eachItem?.product?.name?.toLocaleLowerCase()?.includes(query),
            );

            if (productNameMatch) {
              return true;
            }

            const onlineOrderIdMatch =
              eachOrder?.integrationInfo?.id?.includes(query);

            if (onlineOrderIdMatch) {
              return true;
            }

            const posOrderNumberMatch = eachOrder?.orderNumber
              ?.toLocaleLowerCase()
              ?.includes(query);

            if (posOrderNumberMatch) {
              return true;
            }
          } else {
            return true;
          }
        }
      })
      .map(mapOrderToOnlineOrdersView),
    'createdAt',
    'desc',
  );
};
