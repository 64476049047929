import { StyleFn } from '@hitz-group/domain';
import { isAndroid, isWeb } from '../../common/theme';

export const rowStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.medium * 1.5,
  borderRadius: theme.radius.small,
});

export const paymentOptions: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  marginRight: theme.spacing.small * 2.5,
  marginBottom: theme.spacing.small * 2.5,
});

export const consumerContainer: StyleFn = ({ theme }) => ({
  flex: 0.5,
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  marginRight: theme.spacing.small * 2.5,
});

export const customerContainer: StyleFn = ({ theme }) => ({
  flex: 0.5,
  alignItems: 'flex-end',
  marginRight: theme.spacing.small * 4,
});

export const payTypeContainer: StyleFn = ({ theme }) => ({
  width: 100,
  flex: 0.3,
  alignItems: 'center',
  marginRight: theme.spacing.small * 2.5,
});

export const totalValueContainer: StyleFn = ({ theme }) => ({
  width: 155,
  alignItems: 'center',
  marginRight: theme.spacing.small * 2.5,
  right: 15,
});

export const actionsContainer: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  width: isAndroid ? 30 : 20,
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing.small * 2.5,
});

export const actionIconContainerStyle: StyleFn = ({ theme }) => ({
  marginHorizontal: theme.spacing.small / 2,
});

export const refundOrderTextColor: StyleFn = ({ theme }) => ({
  color: theme.colors.redNegative,
});
export const customerTextStyle: StyleFn = () => ({
  textAlign: 'flex-end',
  width: '100%',
  textTransform: 'none',
});

export const customerBalanceTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'flex-end',
  textTransform: 'none',
  color: theme.colors.danger,
});

export const customerBalanceEmptyTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'flex-end',
  textTransform: 'none',
  color: theme.colors.success,
});

export const columnContainerStyle: StyleFn = ({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.padding.medium * 1.5,
  marginBottom: 10,
  marginTop: isWeb ? 0 : 10,
  marginRight: 0,
  height: 44,
  borderBottomWidth: 0,
  borderRadius: theme.radius.small,
});
