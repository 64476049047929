import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { ReportingProvider } from '../Reports/ReportingContext';
import SalesDashboardContainer from '../Reports/SalesDashboard/SalesDashboardContainer';

const Stack = createStackNavigator();

const DashboardStack: React.FC = () => (
  <ReportingProvider>
    <Stack.Navigator
      headerMode="none"
      initialRouteName="SalesOverview"
      screenOptions={{
        animationEnabled: true,
        cardOverlayEnabled: false,
        cardShadowEnabled: false,
      }}
    >
      <Stack.Screen component={SalesDashboardContainer} name="SalesOverview" />
    </Stack.Navigator>
  </ReportingProvider>
);

export default DashboardStack;
