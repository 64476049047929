import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Pages } from './Pages/Pages';
import { ProductsList } from './Products/ProductList';
import ProductSettingsStack from './ProductSettings/ProductSettingsStack';
import ModifierStack from './Modifiers/ModifierStack';
import { PriceList } from './PriceLists/PriceList';
import PriceListTabNavigator from './PriceLists/PriceListTabs/PriceListTabNavigator';
import { Menu } from './Menus/Menu';
import CreateMenuNavigator from './Menus/CreateMenu/CreateMenuNavigator';
import PageTabNavigator from './Pages/PageTabNavigator';
import Courses from './Courses/Courses';
import CourseTabNavigator from './Courses/CourseTabNavigator';
import { ProductTags } from './ProductTag/ProductTag';
import { SideGroups } from './SideGroups/SideGroups';

const Stack = createStackNavigator();

const ProductStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="Manage"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={Courses} name="Courses" />
    <Stack.Screen component={ProductTags} name="ProductTags" />
    <Stack.Screen component={SideGroups} name="SideGroups" />
    <Stack.Screen component={Pages} name="Pages" />
    <Stack.Screen component={ProductsList} name="Manage" />
    <Stack.Screen component={PriceList} name="PriceLists" />
    <Stack.Screen component={PriceListTabNavigator} name="PriceListSettings" />
    <Stack.Screen component={ProductSettingsStack} name="ProductSettings" />
    <Stack.Screen component={ModifierStack} name="Options" />
    <Stack.Screen component={Menu} name="Menus" />
    <Stack.Screen component={CreateMenuNavigator} name="MenuSettings" />
    <Stack.Screen component={PageTabNavigator} name="PageSettings" />
    <Stack.Screen component={CourseTabNavigator} name="CourseSettings" />
  </Stack.Navigator>
);

export default ProductStack;
