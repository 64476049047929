import {
  useMutation,
  useLazyQuery,
  useApolloClient,
} from '@apollo/client/react/hooks';
import { useMemo, useState, useCallback } from 'react';
import {
  GET_USER_WITH_ROLES,
  CREATE_UPDATE_DELETE_USER_ROLES,
  DELETE_USER_ROLES,
  GET_USER_ROLE_BY_ID,
} from '../../../graphql/users';
import {
  AssignRoleToUserInput,
  UpdateUserRoleInput,
  UserRoleDef,
} from '@hitz-group/domain';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';
import useOfficeUserRoles from './useOfficeUserRoles';

export interface UseUserRoles {
  batchCreateOrUpdateUserRoles: (
    input: AssignRoleToUserInput[] | UpdateUserRoleInput[],
    ids?: string[],
  ) => void;
  deleteUserRoles: (ids: string[]) => void;
  fetchUserRoles: (userId: string) => void;
  fetchUserRole: (userRoleId: string) => void;
  loading: boolean;
  error?: string;
  userRoles: Record<string, UserRoleDef>;
  isOwner: boolean;
  getUserRoles: (userId: string) => Promise<UserWithUserRoles>;
}

interface CreateUpdateDeleteUserRolesResponse {
  removeRolesFromUser: string[];
  assignRolesToUser: UserRoleDef[];
}
interface DeleteUserRolesResponse {
  removeRolesFromUser: string[];
}
export interface UserWithUserRoles {
  user: { roles: UserRoleDef[]; isOwner?: boolean };
}

interface Props {
  /**
   * userId belongs to current list of user-roles (not session the user)
   */
  userId: string;
  onCreateOrUpdateComplete?: () => void;
  onDeleteComplete?: () => void;
}

export function useUserRoles(props?: Props): UseUserRoles {
  const [userRoles, setUserRoles] = useState<Record<string, UserRoleDef>>({});
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const client = useApolloClient();
  const { assignOrUpdateUserRoles, removeUserRoles } = useOfficeUserRoles();

  const createUpdateDeleteUserRolesCompleted = useCallback(
    (data: CreateUpdateDeleteUserRolesResponse) => {
      let updatedUserRoles: Record<string, UserRoleDef> = { ...userRoles };
      // TODO: this could be improved once we introduced pagination
      if (data.assignRolesToUser) {
        updatedUserRoles = data.assignRolesToUser?.reduce(
          (acc, userRole) => ({ ...acc, [userRole.id]: userRole }),
          userRoles,
        );
      }
      if (data.removeRolesFromUser) {
        data.removeRolesFromUser.forEach((id: string) => {
          delete updatedUserRoles[id];
        });
      }
      setUserRoles(updatedUserRoles);
      props?.userId && assignOrUpdateUserRoles(props.userId, updatedUserRoles);
      if (props?.onCreateOrUpdateComplete) {
        props.onCreateOrUpdateComplete();
      }
    },
    [assignOrUpdateUserRoles, userRoles, props],
  );

  const deleteUserRolesCompleted = useCallback(
    (data: DeleteUserRolesResponse) => {
      const values = { ...userRoles };
      data.removeRolesFromUser.forEach((id: string) => {
        delete values[id];
      });
      setUserRoles(values);
      props?.userId && removeUserRoles(props.userId, data.removeRolesFromUser);
      if (props?.onDeleteComplete) {
        props.onDeleteComplete();
      }
    },
    [removeUserRoles, userRoles, props],
  );

  const [getUserRolesRequest, getUserRolesResponse] = useLazyQuery<
    UserWithUserRoles,
    { id: string }
  >(GET_USER_WITH_ROLES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setUserRoles(
        data.user.roles?.reduce(
          (acc, userRole) => ({ ...acc, [userRole.id]: userRole }),
          {},
        ),
      );
      setIsOwner(!!data.user.isOwner);
    },
  });

  const [getUserRoleByIdRequest, getUserRoleByIdResponse] = useLazyQuery<
    { userRole: UserRoleDef },
    { id: string }
  >(GET_USER_ROLE_BY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setUserRoles(prev => ({ ...prev, [data.userRole.id]: data.userRole }));
    },
  });

  const [updateUserRoleRequest, updateUserRoleResponse] = useMutation<
    CreateUpdateDeleteUserRolesResponse,
    {
      input: AssignRoleToUserInput[] | UpdateUserRoleInput[];
      ids: string[];
      deleteUserRoles: boolean;
      updateUserRoles: boolean;
    }
  >(CREATE_UPDATE_DELETE_USER_ROLES, {
    onError: noopHandler,
    onCompleted: createUpdateDeleteUserRolesCompleted,
  });

  const [deleteUserRoleRequest, deleteUserRoleResponse] = useMutation<
    DeleteUserRolesResponse,
    { ids: string[] }
  >(DELETE_USER_ROLES, {
    onError: noopHandler,
    onCompleted: deleteUserRolesCompleted,
  });

  const getUserRoles = useCallback(
    async (userId: string) => {
      const { data } = await client.query<UserWithUserRoles, { id: string }>({
        query: GET_USER_WITH_ROLES,
        variables: {
          id: userId,
        },
      });
      return data;
    },
    [client],
  );

  /**
   * Create or update user roles.
   * TODO: This must makes sure to send the attributes which ever are updated, and the rest attributes should not be sent
   */
  const batchCreateOrUpdateUserRoles = useCallback(
    (
      input: AssignRoleToUserInput[] | UpdateUserRoleInput[],
      ids: string[] = [],
    ) => {
      updateUserRoleRequest({
        variables: {
          input,
          ids: ids,
          deleteUserRoles: ids.length > 0,
          updateUserRoles: input.length > 0,
        },
      });
    },
    [updateUserRoleRequest],
  );

  /**
   * Deletes user role by a give association id
   */
  const deleteUserRoles = useCallback(
    (ids: string[]) => {
      deleteUserRoleRequest({
        variables: {
          ids,
        },
      });
    },
    [deleteUserRoleRequest],
  );

  /**
   * Get user - userRoles by userId
   */
  const fetchUserRoles = useCallback(
    (userId: string): void => {
      getUserRolesRequest({ variables: { id: userId } });
    },
    [getUserRolesRequest],
  );

  const fetchUserRole = useCallback(
    (userRoleId: string): void => {
      getUserRoleByIdRequest({ variables: { id: userRoleId } });
    },
    [getUserRoleByIdRequest],
  );

  const RESPONSE_ENTITIES = [
    getUserRolesResponse,
    updateUserRoleResponse,
    deleteUserRoleResponse,
    getUserRoleByIdResponse,
  ];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      batchCreateOrUpdateUserRoles,
      deleteUserRoles,
      fetchUserRoles,
      loading,
      error: error ? parseApolloError(error) : undefined,
      userRoles,
      isOwner,
      getUserRoles,
      fetchUserRole,
    }),
    [
      batchCreateOrUpdateUserRoles,
      deleteUserRoles,
      fetchUserRoles,
      loading,
      error,
      userRoles,
      isOwner,
      getUserRoles,
      fetchUserRole,
    ],
  );
}
