import React, { useCallback } from 'react';
import { View } from 'react-native';
import Button from '../../../../../components/Button/Button';
import { useFela } from 'react-fela';
import {
  Order,
  StyleFn,
  OrderStatus,
  RejectionReason,
} from '@hitz-group/domain';
import * as styles from '../../../../../components/RefundScreen/CartSection.style';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import { RejectOrderModal } from '../../../../../components/Modals/CancelOrder/RejectOrderModal';

interface CartActionProps {
  order: Order;
  onPressComplete: (id: string) => void;
  onPressAccept: (ids: string[]) => void;
  onPressReject: (
    id: string,
    reason: RejectionReason,
    description?: string,
  ) => void;
  customContainerStyle?: StyleFn;
  disableBtn?: boolean;
  onPressReprintDocket: (id: string) => void;
  onOrderPay?: (order: Order) => void;
}

const CartActions: React.FC<CartActionProps> = ({
  order,
  onPressComplete,
  onPressAccept,
  onPressReject,
  onPressReprintDocket,
  disableBtn,
  onOrderPay,
}) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const rejectOrder = useCallback(
    (reason: RejectionReason, description?: string) => {
      onPressReject(order.id, reason, description);
      closeModal();
    },
    [onPressReject, order, closeModal],
  );

  const showRejectionModal = useCallback(() => {
    showModal(
      <RejectOrderModal order={order as Order} onReject={rejectOrder} />,
    );
  }, [showModal, rejectOrder, order]);

  const onPressOrderBtn = useCallback(() => {
    onPressAccept([order.id]);
  }, [onPressAccept, order.id]);

  const onPressCompleteBtn = useCallback(() => {
    onPressComplete(order.id);
  }, [onPressComplete, order.id]);

  const onPressReprintDocketBtn = useCallback(() => {
    onPressReprintDocket(order.id);
  }, [onPressReprintDocket, order.id]);

  const onPressPayNow = useCallback(() => {
    onOrderPay && onOrderPay(order);
  }, [onOrderPay, order]);

  return (
    <View>
      {order.status == OrderStatus.CREATED ? (
        <>
          <Button
            testID={'accept-cart-btn'}
            key={0}
            title={translate('onlineOrders.accept')}
            disabled={disableBtn}
            size="large"
            containerStyle={css(styles.acceptOrderButtonContainer)}
            labelStyle={css(styles.acceptOrderText)}
            onPress={onPressOrderBtn}
          />
          <Button
            testID={'reject-cart-btn'}
            key={1}
            title={translate('onlineOrders.reject')}
            disabled={disableBtn}
            size="large"
            containerStyle={css(styles.rejectOrderButtonContainer)}
            labelStyle={css(styles.rejectOrderText)}
            onPress={showRejectionModal}
          />
        </>
      ) : order.status == OrderStatus.IN_PROGRESS ? (
        <>
          {order.amountDue === 0 ? (
            <Button
              testID={'complete-cart-btn'}
              key={2}
              title={translate('onlineOrders.markAsCompleted')}
              disabled={disableBtn}
              size="large"
              containerStyle={css(styles.acceptOrderButtonContainer)}
              labelStyle={css(styles.acceptOrderText)}
              onPress={onPressCompleteBtn}
            />
          ) : null}
          {(!!order.amountDue && order.amountDue > 0 && (
            <View style={css(styles.horizontalButtonsDeck)}>
              <Button
                testID={'pay-now-cart-btn'}
                key={3}
                title={translate('onlineOrders.payNow')}
                disabled={disableBtn}
                size="large"
                labelStyle={css(styles.payNowBtnText)}
                onPress={onPressPayNow}
                containerStyle={css(styles.payNowBtnContainer)}
              />

              <Button
                testID={'reprint-cart-btn'}
                key={3}
                title={translate('onlineOrders.rePrintDocket')}
                disabled={disableBtn}
                size="large"
                labelStyle={css(styles.rePrintBtnTxt)}
                onPress={onPressReprintDocketBtn}
                containerStyle={css(styles.rePrintDockerBtnContainer)}
              />
            </View>
          )) || (
            <Button
              secondary
              testID={'reprint-cart-btn'}
              key={3}
              title={translate('onlineOrders.rePrintDocket')}
              disabled={disableBtn}
              size="large"
              labelStyle={css(styles.acceptOrderText)}
              onPress={onPressReprintDocketBtn}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

export default CartActions;
