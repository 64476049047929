import {
  StyleFn,
  ProductPricing as ProductPricingDefault,
} from '@hitz-group/domain';
import React, { useMemo } from 'react';
import { View, Text } from 'react-native';
import { useFela } from 'react-fela';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import Button from '../../../../../../components/Button/Button';
import TableRow from '../../../../../../components/TableComponent/TableRow';
import IconButton from '../../../../../../components/Button/IconButton';
import FormInput from '../../../../../../components/FormInput/FormInput';
import scale, { isWeb, isIos } from '../../../../../../common/theme';
interface ProductPricing extends ProductPricingDefault {
  isSelected?: boolean;
  tax?: string;
  price?: string;
}

interface ProductPricingRowProps {
  productPricing: ProductPricing;
  defaultPricing: ProductPricing;
  isDefault?: boolean;
  onChange: (
    id: string,
    prop: string,
    value: string | boolean | string[],
  ) => void;
  onDeleteRow: (id: string) => void;
  rowIndex: number;
}

const iconMarginStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small / 2,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 17,
  width: 17,
  justifyContent: 'center',
});

const checkBoxTitleContainer: StyleFn = () => ({
  width: 38,
  height: 38,
  alignItems: 'center',
  justifyContent: 'center',
  left: isWeb ? 5 : 0,
});

const productCountStyle: StyleFn = ({ theme }) => ({
  width: 80,
  height: theme.input.height,
  alignItems: 'center',
  justifyContent: 'center',
  marginHorizontal: theme.spacing.big / 2,
  backgroundColor: theme.colors.greyLight,
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 17,
  width: 17,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: isWeb ? 422 : 260,
  height: theme.input.height,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.colors.paragraphLight,
  borderRadius: theme.radius.small,
  left: 5,
});

const formTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.padding.large,
  marginTop: 10,
  ...theme.font14RegularCharcoal,
});

const taxInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 80,
  height: theme.input.height,
  marginHorizontal: theme.spacing.big / 2,
});

const closeIconContainerStyle: StyleFn = ({ theme, marginValue }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  marginLeft: isIos ? 2 : theme.spacing.big * marginValue,
  alignSelf: 'center',
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const containerStyle: StyleFn = ({ theme, rowIndex }) => ({
  zIndex: 1000 - 10 * rowIndex,
  height: isWeb ? 'auto' : scale.moderateScale(35),
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  borderBottomColor: theme.colors.boxBorder,
});

export const ProductPriceRow: React.FC<ProductPricingRowProps> = ({
  productPricing,
  defaultPricing,
  isDefault,
  onChange,
  onDeleteRow,
  rowIndex,
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { appendCurrency } = useCurrency();
  const prices = useMemo(() => {
    const defaultPrice =
      (defaultPricing?.sellingPrice && defaultPricing?.sellingPrice.amount) ||
      0;
    const productPrice =
      productPricing?.sellingPrice && productPricing?.sellingPrice.amount;
    const change = (productPrice || 0) - defaultPrice;
    return {
      defaultPrice: defaultPrice,
      productPrice: productPrice,
      change: change.toFixed(2),
    };
  }, [defaultPricing, productPricing]);
  return (
    <TableRow
      normalRows
      columnSpacing={theme.spacing.big}
      actionStyle={css(iconMarginStyle)}
      containerStyle={css(containerStyle({ theme, rowIndex }))}
    >
      <Button
        onPress={() =>
          onChange(
            productPricing.product.id,
            'isSelected',
            !productPricing?.isSelected,
          )
        }
        fluid
        iconPosition={'left'}
        containerStyle={css(checkBoxTitleContainer)}
        iconContainerStyle={
          productPricing?.isSelected
            ? css(checkIconContainer)
            : css(unCheckContainer)
        }
        icon={productPricing?.isSelected ? 'check' : 'null'}
        iconProps={{
          color: theme.colors.success,
          size: 15,
        }}
      />
      <View testID="product-name" style={css(formInputContainerStyle)}>
        <Text style={css(formTextStyle)}>{productPricing.product?.name}</Text>
      </View>
      <View testID="default-price" style={css(productCountStyle)}>
        <Text style={css(formTextStyle)}>
          {appendCurrency(`${prices.defaultPrice || '0'}`)}
        </Text>
      </View>
      {!isDefault ? (
        <FormInput
          editable={!isDefault}
          testID="product-price-change"
          placeholder={translate('pricings.productPrice')}
          value={appendCurrency(`${prices.productPrice || '0'}`)}
          defaultValue={appendCurrency(`${prices.productPrice || '0'}`)}
          containerStyle={css(taxInputContainerStyle)}
          onChangeText={onChange.bind(
            null,
            productPricing.product.id,
            'sellingPrice',
          )}
        />
      ) : (
        <View testID="product-price" style={css(productCountStyle)}>
          <Text style={css(formTextStyle)}>
            {appendCurrency(`${prices.productPrice || '0'}`)}
          </Text>
        </View>
      )}
      <View testID="change-price" style={css(productCountStyle)}>
        <Text style={css(formTextStyle)}>
          {appendCurrency(`${prices.change || '0'}`)}
        </Text>
      </View>

      {!isDefault ? (
        <IconButton
          icon="times"
          iconSize={24}
          containerSize={34}
          containerStyle={css(
            closeIconContainerStyle({ theme, marginValue: 2 }),
          )}
          iconStyle={css(closeIconStyle)}
          onPress={() => onDeleteRow(productPricing.product.id)}
        />
      ) : (
        <View
          style={css(closeIconContainerStyle({ theme, marginValue: 3.5 }))}
        />
      )}
    </TableRow>
  );
};
