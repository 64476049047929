import { StyleFn } from '@hitz-group/domain';

export const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 540,
});

export const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  paddingLeft: theme.spacing.big,
  alignItems: 'center',
  minHeight: '100%',
});

export const prefixContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  height: '100%',
  lineHeight: 18,
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: theme.spacing.small,
});

export const prefixTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  borderRadius: theme.radius.small,
  lineHeight: 18,
});

export const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

export const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

export const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const titleTextStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

export const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
  ...theme.shadow15,
  paddingLeft: theme.padding.large,
});

export const onlinePaymentsContentStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const merchantCodeInputContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: theme.input.height,
});

export const activeButtonStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.greenLighter,
  alignSelf: 'center',
  marginTop: theme.spacing.medium,
});

export const inactiveButtonStyle: StyleFn = ({ theme }) => ({
  ...activeButtonStyle({ theme }),
  backgroundColor: theme.colors.danger2,
});

export const activeTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
  letterSpacing: -0.4,
});

export const inactiveTitleStyle: StyleFn = ({ theme }) => ({
  ...activeTitleStyle({ theme }),
  color: theme.colors.danger,
});
