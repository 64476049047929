/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import { Helmet } from 'react-helmet';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { Printer, PrinterBrand, PrinterType } from '@hitz-group/domain';
import { PrinterDetailsStyles } from '../styles/Printer.styles';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import Button from '../../../../../../components/Button/Button';
import FormInput from '../../../../../../components/FormInput/FormInput';
import DropDown from '../../../../../../components/DropDown/DropDown';

interface Props {
  printer: Printer;
  options: { loading: boolean };
  onChange: (prop: string, value: string) => void;
  onSave: () => void;
  onTestPrint: () => void;
}

const PrinterDetails: React.FC<Props> = ({
  printer,
  options: { loading },
  onTestPrint,
  onChange,
  onSave,
}) => {
  const { translate } = useTranslation();
  const styles = PrinterDetailsStyles();

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={styles.pageStyle}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ScrollView
            contentContainerStyle={styles.generalSettingsContainerWrapper}
          >
            <BackOfficeSection
              title={translate('backOfficeSettings.printerDetails')}
              contentContainerStyle={styles.formContainerStyleSmall}
              containerStyle={styles.printerDetailsSectionStyle}
            >
              <FormInput
                error={false}
                placeholder={translate('backOfficeSettings.printerName')}
                title={translate('backOfficeSettings.printerName')}
                value={printer.name}
                alignTitle="left"
                testID="printer-name"
                containerStyle={styles.formInputContainerStyle}
                onChangeText={onChange.bind(null, 'name')}
              />
              {printer.printerType === 'BLUETOOTH' ? (
                <FormInput
                  key="bdAddress"
                  error={false}
                  placeholder={translate('backOfficeSettings.bdAddress')}
                  title={translate('backOfficeSettings.bdAddress')}
                  value={printer.bdAddress}
                  alignTitle="left"
                  testID="printer-bdAddress"
                  containerStyle={styles.formInputContainerStyle}
                  onChangeText={onChange.bind(null, 'bdAddress')}
                />
              ) : (
                <FormInput
                  key="ipAddress"
                  error={false}
                  placeholder={translate('backOfficeSettings.ipAddress')}
                  title={translate('backOfficeSettings.ipAddress')}
                  value={printer.ipAddress}
                  alignTitle="left"
                  testID="printer-ipAddress"
                  containerStyle={styles.formInputContainerStyle}
                  onChangeText={onChange.bind(null, 'ipAddress')}
                />
              )}
              <DropDown
                title={translate('backOfficeSettings.printerType')}
                options={Object.values(PrinterType).map(printerType => ({
                  value: printerType,
                  label: printerType,
                }))}
                selectedValue={printer.printerType}
                containerStyle={styles.dropdownContainerStyle}
                itemsContainerStyle={styles.dropDownItemsContainerStyle}
                onValueChange={onChange.bind(null, 'printerType')}
                extraPopoverStyle={styles.extraPopoverStyle}
              />
              <DropDown
                title={translate('backOfficeSettings.brand')}
                options={Object.values(PrinterBrand).map(printerType => ({
                  value: printerType,
                  label: printerType,
                }))}
                selectedValue={printer.brand}
                containerStyle={styles.dropdownContainerStyle}
                itemsContainerStyle={styles.dropDownItemsContainerStyle}
                onValueChange={onChange.bind(null, 'brand')}
                extraPopoverStyle={styles.extraPopoverStyle}
              />
            </BackOfficeSection>
          </ScrollView>
        )}
        <View style={styles.actionsContainerStyle}>
          <Button
            fluid
            testID="test-print"
            title={translate('button.testPrint')}
            containerStyle={styles.testPrinterButtonStyle}
            labelStyle={styles.testPrinterTitleStyle}
            onPress={onTestPrint}
          />
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={styles.saveButtonStyle}
            labelStyle={styles.titleStyle}
            onPress={onSave}
          />
        </View>
      </View>
    </>
  );
};

export default PrinterDetails;
