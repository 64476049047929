import { format } from 'date-fns';
import { Address, IntegrationApps, Order } from '@hitz-group/domain';
import { table, TableUserConfig } from 'table';

export const isApplyForIntegrationOrder = (order: Order) => {
  // only apply for few integration app: #5678
  return [
    IntegrationApps.OOLIO_STORE,
    IntegrationApps.DELIVERECT,
    IntegrationApps.DOSHII,
  ].includes(order?.integrationInfo?.app as IntegrationApps);
};

export const getOnlineOrderChannel = (order: Order): {} => {
  if (!isApplyForIntegrationOrder(order) || !order?.salesChannel?.name)
    return '';

  return `${order?.salesChannel?.name} (${
    order.integrationInfo?.channelOrderId || order.orderNumber
  })`;
};

const getAddress = (address?: Address): string => {
  const formatAddress = `${address?.line1 || ''} ${address?.line2 || ''} ${
    address?.city || ''
  } ${address?.state || ''} ${address?.postalCode || ''}`;
  return formatAddress.trim();
};

export const getPickupTime = (order: Order): string => {
  return order.requiredAt || order.createdAt
    ? format(
        new Date(order.requiredAt || order.createdAt),
        'dd-MM-yyyy hh:mm aa',
      )
    : '';
};

export const getOnlineOrderCustomerInfo = (
  order: Order,
): { customerAddress: string; customerName: string; customerPhone: string } => {
  if (!isApplyForIntegrationOrder(order) || !order?.customer)
    return { customerAddress: '', customerName: '', customerPhone: '' };

  const { firstName, lastName, phone, preferredAddress } = order.customer;
  return {
    customerName: `${firstName} ${lastName}`,
    customerAddress: getAddress(preferredAddress),
    customerPhone: phone || '',
  };
};

export const getOnlineOrderType = (
  order: Order,
  tableConfig: TableUserConfig,
): string => {
  const orderTypeName = order.orderType?.name.toLocaleUpperCase();
  const { customerName } = getOnlineOrderCustomerInfo(order);
  let orderReceiver = '';
  if (orderTypeName == 'DINE IN') {
    orderReceiver = `Table ${order.table.name}`;
  } else {
    orderReceiver = customerName;
  }

  return table([[orderTypeName, orderReceiver]], tableConfig);
};

export const getOnlineOrderDetail = (
  order: Order,
  tableConfig: TableUserConfig,
): string => {
  const tableData = [];
  // Order Number
  tableData.push(['Order No:', order.orderNumber]);
  //  Due At
  tableData.push(['Due At:', getPickupTime(order)]);
  return table(tableData, tableConfig);
};
