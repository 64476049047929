import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ProfitMarginsList } from './ProfitMarginsList';
import { useNotification } from '../../../../../hooks/Notification';
import { useRoute } from '@react-navigation/native';
import { useStores } from '../../../../../hooks/app/useStores';
import { ProfitMargin } from '@hitz-group/domain';
import pick from 'lodash/pick';
import { useTranslation } from '@hitz-group/localization';
import { useProductTypes } from '../../../../../hooks/app/useProductTypes';

let defaultProfitMargin: ProfitMargin = {
  margin: 0.0,
  productTypeId: '',
};

export const ProfitMarginsContainer: React.FC = () => {
  const route = useRoute();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<ProfitMargin[]>([]);
  const params = route.params as {
    storeId: string;
    venueId: string;
  };
  const { storeId, venueId } = params;

  const {
    stores,
    updateStoreProfitMargins,
    updatedStoreId,
    loading: profitMarginsLoading,
    error: profitMarginsError,
  } = useStores({
    storeId,
    venueId,
  });

  const {
    productTypes,
    getProductTypes,
    loading: productTypesLoading,
    error: productTypesError,
  } = useProductTypes();

  const loading = profitMarginsLoading || productTypesLoading;
  const error = profitMarginsError || productTypesError;

  // fetch data
  useEffect(() => {
    getProductTypes();
  }, [getProductTypes]);

  useEffect(() => {
    if (stores[storeId]) {
      const profitMargins = stores[storeId]?.profitMargins || [];
      setForm(profitMargins as ProfitMargin[]);
    }
  }, [stores, storeId]);

  useEffect(() => {
    if (productTypes) {
      defaultProfitMargin = {
        margin: 0,
        productTypeId: Object.values(productTypes)[0]?.id,
      };
    }
  }, [productTypes]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedStoreId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeProfitMargins.profitMarginsUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedStoreId, showNotification, translate]);

  // on change events
  const onChange = useCallback(
    (prop: string, index: number, value: string): void => {
      const updatedForm = [...form];
      updatedForm[index] = { ...updatedForm[index], [prop]: value };
      setForm(updatedForm);
    },
    [form],
  );

  const onDelete = useCallback(
    (index: number): void => {
      const updatedForm = [...form];
      updatedForm.splice(index, 1);
      setForm(updatedForm);
    },
    [form],
  );

  const onPressCreateNew = useCallback(() => {
    setForm(form => [...form, defaultProfitMargin as ProfitMargin]);
  }, []);

  const onSave = useCallback((): void => {
    const productTypeIds = Object.keys(productTypes);
    const dataToUpdate = Object.values(form)
      .map(
        profitMargin =>
          ({
            ...pick(profitMargin, ['id', 'name']),
            margin: +(+profitMargin.margin)?.toFixed(2),
            productTypeId: profitMargin.productTypeId,
          } as ProfitMargin),
      )
      .filter(
        pm => !pm.productTypeId || productTypeIds.includes(pm.productTypeId),
      );

    if (
      dataToUpdate.some(
        profitMargin =>
          !profitMargin.productTypeId || profitMargin.margin === undefined,
      )
    ) {
      showNotification({
        error: true,
        message: translate('backOfficeProfitMargins.fieldsMissing'),
      });
    } else {
    }
    updateStoreProfitMargins({
      id: storeId,
      profitMargins: dataToUpdate,
    });
  }, [
    updateStoreProfitMargins,
    form,
    storeId,
    showNotification,
    translate,
    productTypes,
  ]);

  const productTypesData = useMemo(
    () => Object.values(productTypes),
    [productTypes],
  );

  return (
    <ProfitMarginsList
      profitMargins={form}
      productTypes={productTypesData}
      options={{ loading }}
      onPressCreateNew={onPressCreateNew}
      onChange={onChange}
      onDelete={onDelete}
      onSave={onSave}
    />
  );
};
