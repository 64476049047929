import { RenderProps, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import {
  Text,
  View,
  ViewStyle,
  TouchableOpacity,
  TextStyle,
} from 'react-native';
import Icon from '../Icon/Icon';
import { useTranslation } from '@hitz-group/localization';
export interface CollapsiblePanelProps {
  children: React.ReactChild | React.ReactChildren;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  vertical?: boolean;
  isSwitcherOpen?: boolean;
  defaultState?: 'collapsed' | 'expanded';
  containerStyle?: ViewStyle;
  contentStyle?: ViewStyle | ViewStyle[];
  icon?: string;
  title?: string;
  active?: boolean;
  onPress?: () => void;
  onSwitch?: () => void;
  iconStyle?: TextStyle;
}

const collapseButtonStyle: StyleFn = ({ theme, minHeight, vertical }) => ({
  height: minHeight,
  alignItems: 'center',
  width: vertical ? '100%' : 0,
  flexDirection: 'row',
  backgroundColor: theme.colors.primary,
});

const collapseContainer: StyleFn = ({ theme, vertical }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  left: vertical ? theme.spacing.medium * 2 : theme.spacing.medium * 1.3,
});

const collapseTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
  fontFamily: theme.font.semibold,
  marginLeft: theme.spacing.small,
  color: theme.colors.text,
});

export interface CollapseButtonProps {
  onPress: () => void;
  vertical?: boolean;
  minHeight?: number;
  collapsed?: boolean;
}

const CollapseButton: React.FC<CollapseButtonProps> = ({
  onPress,
  ...props
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  return (
    <FelaComponent style={collapseButtonStyle} {...props}>
      {({ style }: RenderProps): React.ReactFragment => (
        <TouchableOpacity style={style} onPress={onPress}>
          <View style={css(collapseContainer)}>
            {props.vertical && props.collapsed ? (
              <Icon name="AngleRight" color={theme.colors.white} />
            ) : (
              <Icon name="AngleLeft" color={theme.colors.white} />
            )}
            {!props.collapsed && props.vertical ? (
              <Text style={css(collapseTextStyle)}>
                {translate('button.collapse')}
              </Text>
            ) : null}
          </View>
        </TouchableOpacity>
      )}
    </FelaComponent>
  );
};

const rootStyle: StyleFn = ({
  theme,
  minWidth,
  maxWidth,
  collapsed,
  collapsedmenu,
  isSwitcherOpen,
  vertical,
  minHeight,
}) => ({
  minWidth,
  maxWidth,
  minHeight,
  width: vertical ? (collapsed || isSwitcherOpen ? minWidth : maxWidth) : null,
  height: vertical ? null : collapsedmenu ? minHeight : 'auto',
  backgroundColor: theme.colors.background,
  flexDirection: vertical ? 'column' : 'row',
  justifyContent: 'space-between',
});

const innerContentStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.primary,
});

const titleStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  height: 44,
  paddingHorizontal: theme.padding.medium,
  marginHorizontal: theme.spacing.small,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.primary,
});

const titleActiveStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  height: 44,
  paddingHorizontal: theme.padding.medium,
  marginHorizontal: theme.spacing.small,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.black,
});

const titleTextStyle: StyleFn = ({ theme }) => ({
  letterSpacing: -0.5,
  marginLeft: theme.spacing.medium * 1.15,
  color: theme.colors.white,
  fontFamily: theme.font.medium,
});

export interface TitleProps {
  testID?: string;
  icon?: string;
  title?: string;
  active?: boolean;
  onPress?: () => void;
  onToggleCollapse?: () => void;
  onToggleCollapseMenu?: () => void;
  iconStyle?: TextStyle;
}

const Title: React.FC<TitleProps> = ({
  icon,
  title,
  active,
  onPress,
  iconStyle,
  ...props
}) => {
  const { css } = useFela();
  return (
    <FelaComponent style={active ? titleActiveStyle : titleStyle} {...props}>
      {({ style }: RenderProps): React.ReactFragment => (
        <TouchableOpacity style={style} onPress={onPress}>
          {icon && (
            <Icon
              name={icon}
              success={active}
              primary={active}
              style={iconStyle}
              // color={theme.colors.white}
            />
          )}
          <Text style={css(titleTextStyle)}>{title}</Text>
        </TouchableOpacity>
      )}
    </FelaComponent>
  );
};

const CollapsiblePanel: React.FC<CollapsiblePanelProps> = ({
  children,
  containerStyle,
  contentStyle,
  defaultState,
  active,
  isSwitcherOpen,
  onPress,
  onSwitch,
  ...props
}: CollapsiblePanelProps) => {
  const { css } = useFela();
  const [collapsed, setCollapsed] = useState(defaultState === 'collapsed');
  const [collapsedmenu, setCollapsedMenu] = useState(true);

  const onToggleCollapse = useCallback(() => {
    setCollapsed(collapsed => (isSwitcherOpen ? false : !collapsed));
    isSwitcherOpen && onSwitch && onSwitch();
  }, [onSwitch, isSwitcherOpen]);

  const onToggleCollapseMenu = useCallback(() => {
    setCollapsedMenu(collapsedmenu => (active ? !collapsedmenu : false));
    onPress && onPress();
  }, [onPress, active]);

  return (
    <FelaComponent
      style={rootStyle}
      {...props}
      collapsed={collapsed}
      collapsedmenu={collapsedmenu}
      isSwitcherOpen={isSwitcherOpen}
    >
      {({ style }: RenderProps): React.ReactFragment => (
        <>
          <View
            style={containerStyle ? [style, containerStyle] : style}
            testID="collapse-panel"
          >
            <View
              testID="collapse-panel-content"
              style={
                contentStyle
                  ? [css(innerContentStyle), contentStyle]
                  : css(innerContentStyle)
              }
            >
              <Title
                testID="collapse-panel-content"
                title={props.title}
                icon={props.icon}
                active={active}
                iconStyle={props.iconStyle}
                onPress={onToggleCollapseMenu}
              />
              {children}
            </View>
            <CollapseButton
              onPress={onToggleCollapse}
              vertical={props.vertical}
              minHeight={props.minHeight}
              collapsed={collapsed || isSwitcherOpen}
            />
          </View>
        </>
      )}
    </FelaComponent>
  );
};

CollapsiblePanel.defaultProps = {
  minWidth: 74,
  minHeight: 74,
  maxWidth: 240,
  vertical: false,
  defaultState: 'expanded',
};

export default CollapsiblePanel;
