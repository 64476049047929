import { StyleFn, ResourceOperation } from '@hitz-group/domain';
import { PermissionTreeNode } from '../../../../state/jobRoleSettingsUtility';
import React, { FC } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import PermissionGroupCheckBox from './PermissionGroupCheckBox';
import PermissionCheckBox from './PermissionCheckBox';

const container: StyleFn = () => ({
  width: '100%',
  alignItems: 'flex-end',
  marginBottom: 10,
});

const subtitleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.smaller,
  color: theme.colors.text,
});

const permissionLabel: StyleFn = ({}) => ({});

const permissionContainer: StyleFn = ({}) => ({
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minHeight: 50,
  width: '100%',
});

const permissionDescContainer: StyleFn = ({}) => ({
  width: '70%',
  alignItems: 'flex-start',
  alignSelf: 'flex-start',
});

const labelContainer: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  width: '75%',
});
const checkboxContainer: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: 20,
  width: '25%',
});
const checkbox: StyleFn = () => ({
  marginLeft: 30,
  marginRight: 5,
});
const emptySpace: StyleFn = () => ({
  width: 23,
});

interface PermissionDetailProps {
  permission: PermissionTreeNode;
}
const PermissionDetail: FC<PermissionDetailProps> = ({ permission }) => {
  const { css } = useFela();

  return (
    <View style={css(container)}>
      <View style={css(permissionContainer)}>
        <View style={css(labelContainer)}>
          <Text testID="permissionTitle" style={css(permissionLabel)}>
            {permission.label}
          </Text>
        </View>

        <View style={css(checkboxContainer)}>
          {permission.isPermissionGroup && (
            <PermissionGroupCheckBox
              permissionGroup={permission}
              operation={permission.operation as boolean}
            />
          )}
          {!permission.isPermissionGroup &&
          !Array.isArray(permission.operation) ? (
            <PermissionCheckBox permission={permission} />
          ) : (
            permission.operation &&
            Array.isArray(permission.operation) &&
            (['view', 'update'] as ResourceOperation[]).map((operation, i) => {
              if (
                (permission.operation as ResourceOperation[])?.includes(
                  operation,
                )
              ) {
                return (
                  <PermissionCheckBox
                    key={permission.id + operation}
                    permission={permission}
                    operation={operation}
                  />
                );
              }
              return (
                <View style={css(checkbox)} key={permission.id + i}>
                  <View
                    testID="emptyPermissionOperation"
                    style={css(emptySpace)}
                  />
                </View>
              );
            })
          )}
        </View>
      </View>
      {!!permission.description && (
        <View style={css(permissionDescContainer)}>
          <Text testID="permissionDescription" style={css(subtitleStyle)}>
            {permission.description}
          </Text>
        </View>
      )}
    </View>
  );
};

export default PermissionDetail;
