import {
  Adjustment,
  AdjustmentType,
  AdjustmentUnit,
  CUSTOM_ADJUSTMENT_ID,
  OrderItem,
} from '@hitz-group/domain';
import { translate, useCurrency } from '@hitz-group/localization';
import { computeLineTotal } from '@hitz-group/order-helper';
import { useModal } from '@hitz-group/rn-use-modal';
import { RenderProps, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { StyleSheet, View } from 'react-native';
import { useNotification } from '../../../hooks/Notification';
import { addToValue } from '../../../utils/keypadHelper';
import Button from '../../Button/Button';
import InputDisplay from '../../InputDisplay/InputDisplay';
import NumberInput from '../../NumberInput/NumberInput';
import Modal from '../Modal';
import TitleBar from '../../TitleBar/TitleBar';
import { FelaComponent } from 'react-fela';
import IconButton from '../../Button/IconButton';
import { ButtonProps } from '../../Button/Button';
import { isWeb } from '../../../common/theme';
import SelectBar from '../../Button/SelectBar';
import { string as yupString } from 'yup';
import { MAX_KEYPAD_INPUT } from '../../../types/Common';

export enum SET_PRICE_OPTIONS {
  DISCOUNT_PERCENT = 'DISCOUNT_PERCENT',
  DISCOUNT_PRICE = 'DISCOUNT_PRICE',
  SURCHARGE_PERCENT = 'SURCHARGE_PERCENT',
  SURCHARGE_PRICE = 'SURCHARGE_PRICE',
}

export type AdjustmentItem = {
  label: string;
  value: AdjustmentUnit;
};

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const CloseButtons: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

export interface SetPriceModalProps {
  item: OrderItem;
  defaultPrice: number;
  adjustmentType: string;
  maxDiscounts?: number;
  minSellingPrice?: number;
  maxSellingPrice?: number;
  onSubmit: (
    price: number,
    adjustments: Adjustment[],
    isSurcharge?: boolean,
  ) => void;
}

const headerStyle: StyleFn = ({ theme }) => ({
  width: 360,
  marginTop: theme.spacing.small,
  alignSelf: 'center',
});

const keypadStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.medium * (isWeb ? 1.25 : 0.75),
  alignSelf: 'center',
});

const keyRowStyle: StyleFn = () => ({
  marginTop: '2%',
});

const numberInputStyle: StyleFn = () => ({
  flexGrow: 1,
});

const containerStyle: StyleFn = ({ isSurcharge }) => ({
  width: 360,
  minHeight: isSurcharge ? 605 : 510,
  alignSelf: 'center',
  height: isWeb ? 550 : 570,
  paddingTop: 0,
  paddingBottom: 0,
});
const inputDisplayStyle: StyleFn = () => ({
  height: 100,
});

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  keypad: {
    flex: 0.7,
  },
});

const deleteCharStyle: StyleFn = ({ theme }) => ({
  padding: theme.spacing.small + 1,
  transform: [{ scaleX: -1 }],
});

export function adjustAdjustments(
  adjustments: Adjustment[],
  maxDiscounts = Infinity,
) {
  return adjustments.slice(-maxDiscounts);
}

const selectBarStyle: StyleFn = () => ({
  marginTop: 14,
});
const customBodyStyle: StyleFn = ({}) => ({
  paddingTop: 0,
});

const adjustmentValueSchema = yupString().max(8);

export const SetPriceModalMap: React.FC<SetPriceModalProps> = ({
  item,
  onSubmit,
  maxDiscounts,
  defaultPrice,
  adjustmentType,
  minSellingPrice,
  maxSellingPrice,
}) => {
  const isSurcharge =
    adjustmentType === SET_PRICE_OPTIONS.SURCHARGE_PRICE ||
    adjustmentType === SET_PRICE_OPTIONS.SURCHARGE_PERCENT;
  const { css } = useFela({ item, isSurcharge });
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const { formatCurrency, currencySymbol, decimalSeparator, unFormatCurrency } =
    useCurrency();
  const [input, setInput] = useState('');
  const [unitPrice] = useState(item.unitPrice);

  const ADJUSTMENT_ITEMS: AdjustmentItem[] = [
    {
      label: '%',
      value: AdjustmentUnit.PERCENTAGE,
    },
    {
      label: currencySymbol,
      value: AdjustmentUnit.FLAT,
    },
  ];

  const [selectedAdjustmentItem, setAdjustmentItem] = useState<AdjustmentItem>(
    () => {
      if (
        adjustmentType === SET_PRICE_OPTIONS.DISCOUNT_PRICE ||
        adjustmentType === SET_PRICE_OPTIONS.SURCHARGE_PRICE
      )
        return ADJUSTMENT_ITEMS[1];
      return ADJUSTMENT_ITEMS[0];
    },
  );

  const originalTotal = computeLineTotal({
    ...item,
    unitPrice: defaultPrice,
    discounts: [],
  });

  const maxPercentage = 100;
  const numberKeys = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '0' },
    { value: decimalSeparator },
  ];

  const onPressKey = useCallback(
    (key: string): void => {
      setInput((preInput: string) => {
        const newValue = addToValue(
          preInput,
          key,
          decimalSeparator,
          MAX_KEYPAD_INPUT,
        );
        const inputWithOnlyDigit = newValue.replace(decimalSeparator, '');
        const isValid = adjustmentValueSchema.isValidSync(inputWithOnlyDigit);
        if (!isValid) return preInput;
        return newValue;
      });
    },
    [decimalSeparator],
  );

  const handlePressDeleteInput = useCallback((): void => {
    setInput(prev => {
      return prev.slice(0, -1);
    });
  }, []);

  const handleDiscountOff = useCallback(() => {
    const parseInput = unFormatCurrency(input);
    if (selectedAdjustmentItem.value === AdjustmentUnit.FLAT) {
      if (parseInput > originalTotal) {
        showNotification({
          message: translate('order.maxDiscountWarning', {
            value: `${formatCurrency(originalTotal)}`,
          }),
          error: true,
        });

        setInput('');
        return;
      }
    } else {
      if (parseInput > maxPercentage) {
        showNotification({
          message: translate('order.maxDiscountWarning', { value: '100%' }),
          error: true,
        });
        setInput('');
        return;
      }
    }
    closeModal();
    onSubmit(
      unitPrice,
      adjustAdjustments(
        [
          {
            id: CUSTOM_ADJUSTMENT_ID,
            amount: -parseInput,
            adjustmentUnit: selectedAdjustmentItem.value,
            allowOnPaymentType: false,
            adjustmentType: AdjustmentType.DISCOUNT,
          },
        ],
        maxDiscounts,
      ),
    );
  }, [
    selectedAdjustmentItem.value,
    closeModal,
    onSubmit,
    unitPrice,
    input,
    maxDiscounts,
    originalTotal,
    showNotification,
    formatCurrency,
    unFormatCurrency,
  ]);

  const handleSurcharge = useCallback(() => {
    const price = unFormatCurrency(input);
    if (isNaN(price)) return;

    if (
      selectedAdjustmentItem.value === AdjustmentUnit.FLAT &&
      minSellingPrice &&
      price < minSellingPrice
    ) {
      showNotification({
        message: translate('order.minPriceWarning', {
          price: formatCurrency(minSellingPrice),
        }),
        error: true,
      });
      setInput('');
    } else if (
      selectedAdjustmentItem.value === AdjustmentUnit.FLAT &&
      maxSellingPrice &&
      price > maxSellingPrice
    ) {
      showNotification({
        message: translate('order.maxPriceWarning', {
          price: formatCurrency(maxSellingPrice),
        }),
        error: true,
      });
      setInput('');
    } else {
      closeModal();
      onSubmit(
        unitPrice,
        adjustAdjustments(
          [
            {
              id: CUSTOM_ADJUSTMENT_ID,
              amount: price,
              adjustmentUnit: selectedAdjustmentItem.value,
              allowOnPaymentType: false,
              adjustmentType: AdjustmentType.SURCHARGE,
            },
          ],
          maxDiscounts,
        ),
        true,
      );
    }
  }, [
    input,
    selectedAdjustmentItem.value,
    minSellingPrice,
    maxSellingPrice,
    showNotification,
    formatCurrency,
    closeModal,
    onSubmit,
    maxDiscounts,
    unitPrice,
    unFormatCurrency,
  ]);

  const handleApplyChange = useCallback(() => {
    switch (adjustmentType) {
      case SET_PRICE_OPTIONS.DISCOUNT_PERCENT:
      case SET_PRICE_OPTIONS.DISCOUNT_PRICE:
        handleDiscountOff();
        break;
      case SET_PRICE_OPTIONS.SURCHARGE_PERCENT:
      case SET_PRICE_OPTIONS.SURCHARGE_PRICE:
        handleSurcharge();
        break;
    }
  }, [adjustmentType, handleDiscountOff, handleSurcharge]);

  const handleChangeSurchargeType = (selectedType: string): void => {
    const selectedDiscountItem = ADJUSTMENT_ITEMS.find(
      item => item.value === selectedType,
    ) as AdjustmentItem;
    setAdjustmentItem(selectedDiscountItem);
  };

  const postFixUnit =
    selectedAdjustmentItem.value === AdjustmentUnit.PERCENTAGE
      ? selectedAdjustmentItem.label
      : '';
  const displayInputValue =
    selectedAdjustmentItem.value === AdjustmentUnit.PERCENTAGE
      ? input || '0'
      : formatCurrency(unFormatCurrency(input));

  return (
    <View>
      <TitleBar
        primary
        testID="title"
        containerStyle={css(headerStyle)}
        title={
          isSurcharge
            ? translate('order.orderSurcharge')
            : translate('order.orderDiscount') +
              ` (${selectedAdjustmentItem.label})`
        }
        titleLeft={<CloseButtons onPress={closeModal} />}
      />

      <Modal
        customBodyStyle={customBodyStyle}
        contentStyle={css(containerStyle)}
        showCloseButton={false}
      >
        <InputDisplay
          testID="discount-display"
          value={`${displayInputValue}${postFixUnit}`}
          right={
            <IconButton
              testID="clearButton"
              circular
              icon="cancel"
              containerStyle={css(deleteCharStyle)}
              onPress={handlePressDeleteInput}
              iconSize={24}
            />
          }
          containerStyle={css(inputDisplayStyle)}
        />
        {isSurcharge && (
          <View style={css(selectBarStyle)}>
            <SelectBar
              options={ADJUSTMENT_ITEMS}
              onPress={handleChangeSurchargeType}
              selectedOption={selectedAdjustmentItem.value}
            />
          </View>
        )}
        <View style={[css(keypadStyle), styles.fullWidth]}>
          <NumberInput
            keyWidth={100}
            keyHeight={74}
            twistKeyWidth={2.05}
            onPressKey={onPressKey}
            rowStyle={css(keyRowStyle)}
            containerStyle={css(numberInputStyle)}
            keys={numberKeys}
          />
        </View>
        <Button
          fluid
          success
          testID="submit-button"
          onPress={handleApplyChange}
          title={translate('button.applyChanges')}
        />
      </Modal>
    </View>
  );
};
