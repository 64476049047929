import React, { useCallback, useState } from 'react';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import Modal from '../Modal';
import { CreateStoreInput, CreateVenueInput, Venue } from '@hitz-group//domain';
import { VenueForm } from './VenueForm';
import { StoreForm } from './StoreForm';
import DropDown from '../../FormInput/DropDown';
import { ModalStyles } from './styles';

interface Props {
  onCreateVenue: (input: CreateVenueInput) => void;
  onCreateStore: (input: CreateStoreInput) => void;
  venues: Venue[];
}

enum Mode {
  VENUE = 'VENUE',
  STORE = 'STORE',
}

export const CreateVenueOrStoreModal: React.FC<Props> = ({
  onCreateVenue,
  onCreateStore,
  venues,
}: Props) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [mode, setMode] = useState<Mode>(Mode.VENUE);

  const onChange = useCallback(value => {
    setMode(value);
  }, []);
  const styles = ModalStyles();

  return (
    <Modal
      title={translate('backOfficeVenues.createNew')}
      onDismiss={closeModal}
      contentStyle={styles.modalContainerStyle}
    >
      <DropDown
        title={translate('backOfficeVenues.selectCreateOption')}
        values={Object.values(Mode).map(value => ({
          value: value,
          label: value,
        }))}
        selectedValue={mode}
        style={styles.formInputStyle}
        extraMainViewStyle={styles.dropDownMainViewStyle}
        extraViewStyle={styles.dropdownViewStyle}
        extraStyle={styles.dropdownExtraStyle}
        onValueChange={onChange}
      />
      {mode === Mode.VENUE ? (
        <VenueForm onCreate={onCreateVenue} venues={venues}></VenueForm>
      ) : (
        <StoreForm onCreate={onCreateStore} venues={venues}></StoreForm>
      )}
    </Modal>
  );
};
