import React, { Suspense } from 'react';
import { AppRegistry, Platform } from 'react-native';
import * as Sentry from '@sentry/react-native';
import { createRenderer } from 'fela-native';
import { RendererProvider } from 'react-fela';
import { enableScreens } from 'react-native-screens';
import { LoadingScreen } from './screens/Loading/Loading';
import LogRocket from '@logrocket/react-native';
import { REACT_APP_LOGGING_ENV } from 'react-native-dotenv';
require('react-native-gesture-handler');

enableScreens();

// eslint-disable-next-line @typescript-eslint/no-var-requires
const App = require('./App').default;
const renderer = createRenderer();

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const wrappedApp = () => (
  <RendererProvider renderer={renderer}>
    <Suspense fallback={LoadingScreen}>
      <App />
    </Suspense>
  </RendererProvider>
);

export { wrappedApp as App };

AppRegistry.registerComponent('POSApp', () => wrappedApp);

if (Platform.OS === 'web') {
  AppRegistry.runApplication('POSApp', {
    rootTag: document.getElementById('root'),
  });
}

if (!__DEV__) {
  if (
    Platform.OS === 'android' ||
    Platform.OS === 'ios' ||
    Platform.OS === 'web'
  ) {
    Sentry.init({
      dsn: 'https://73e47659bca34fe39b4257910d4ce51f@sentry.io/1830344',
      environment: process.env['REACT_APP_LOGGING_ENV'] || process.env.NODE_ENV,
    });
  }

  if (
    process.env['REACT_APP_LOGGING_ENV'] === 'production' ||
    REACT_APP_LOGGING_ENV === 'production'
  ) {
    LogRocket.init(process.env['LOGROCKET_PROJECT'] || '0djiub/tillx');
    // attach logrocket session url to sentry events
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
}
