import { useTranslation } from '@hitz-group/localization';
import { Theme } from '@hitz-group/domain';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useMemo, useState } from 'react';
import { withTheme } from 'react-fela';
import {
  StyleSheet,
  View,
  Text,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import Button from '../../../components/Button/Button';
import Error from '../../../components/Error/Error';
import FormInput from '../../../components/FormInput/FormInput';
import Logo from '../../../components/Logo/Logo';
import PopupView from '../../../components/PopupView/PopupView';
import Layout from '../../../components/POSLayout/POSLayout';
import scale, { isAndroid } from '../../../common/theme';
import { useResetPassword } from '../../../hooks/app/useResetPassword';
import Gradient from '../../../components/Gradient/Gradient';
import { useFela } from 'react-fela';

export interface ForgotPasswordScreenProps {
  theme: Theme;
}

export interface State {
  form: {
    email: string;
  };
  isLoggedIn?: boolean;
  empPassCode: string;
  errorMessage: string;
  isFormComplete: boolean;
  hidePassword: boolean;
}

enum FormError {
  INVALID_EMAIL = 'login.invalidEmailMessage',
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getStyles = (theme: Theme) => {
  return StyleSheet.create({
    screen: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    forgotPasswordViewStyle: {
      width: scale.moderateScale(180),
      alignItems: 'center',
    },
    logo: {
      marginBottom: theme.spacing.medium,
      alignSelf: 'center',
      height: 62,
      width: 150,
    },
    buttonContainer: {
      width: 280,
      marginTop: 30,
      justifyContent: 'center',
      alignItems: 'stretch',
    },
    error: {
      position: 'absolute',
      left: scale.moderateScale(190),
      bottom: scale.moderateScale(10),
    },
    button: {
      marginTop: scale.moderateScale(10),
      overflow: 'hidden',
    },
    popupStyle: {
      height: isAndroid ? 460 : 448,
      width: 360,
      borderRadius: theme.radius.large,
    },
    container: {
      marginTop: scale.moderateScale(15),
    },
    forgotPassword: {
      fontFamily: theme.font.medium,
    },
    headerText: {
      textAlign: 'center',
      paddingVertical: scale.moderateScale(10),
      ...theme.font14Medium,
      color: theme.colors.grey2,
    },
    marginTop2: {
      marginTop: 10,
    },
    textInputContainer: {
      width: 280,
    },
    containerStyle: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};

const initialForm = {
  email: '',
};

const ForgotPassword: React.FC<ForgotPasswordScreenProps> = ({
  ...props
}: ForgotPasswordScreenProps) => {
  const { theme } = useFela();
  const [form, setForm] = useState({ ...initialForm });
  const [errorMessage, setErrorMessage] = useState('');
  const { translate } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<StackNavigationProp<any>>();
  const styles = useMemo(() => getStyles(props.theme), [props.theme]);
  const { requestPasswordResetLink } = useResetPassword();

  /**
   * Update state when a form input property has changed
   * @param prop name of input field
   * @param value updated value
   */
  const onChangeFormProp = useCallback(
    (prop: string, value: string): void => {
      if (errorMessage) {
        setErrorMessage('');
      }
      setForm(state => ({
        ...state,
        [prop]: value,
      }));
    },
    [errorMessage],
  );

  // Validate and submit form
  const onPressLogin = useCallback((): void => {
    setErrorMessage('');
    setForm({ ...initialForm });
    navigation.push('Login');
  }, [navigation, setForm, setErrorMessage]);

  const onPressResetPassword = useCallback(async (): Promise<void> => {
    if (form.email.length > 0) {
      setErrorMessage('');
      // TODO: add csurf for this unguarded api
      const success = await requestPasswordResetLink(form.email);
      if (success) {
        setForm({ ...initialForm });
        navigation.push('Login');
      }
    } else {
      setErrorMessage(FormError.INVALID_EMAIL);
    }
  }, [navigation, requestPasswordResetLink, form]);

  return (
    <React.Fragment>
      <Layout
        testID="ForgotPasswordScreen"
        title={translate('navigation.loginPageTitle', {
          appName: translate('appName'),
        })}
        hasHeader={false}
        navigation={navigation}
        useSafeArea={false}
      >
        <Gradient
          colors={[theme.colors.brandPrimary, theme.colors.brandSecondary]}
          style={styles.screen}
          start={theme.gradient.startAxis}
          end={theme.gradient.endAxis}
          locations={theme.gradient.location}
        >
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}
            style={styles.containerStyle}
          >
            <View
              accessible
              accessibilityLabel="forgot password"
              style={styles.screen}
            >
              <PopupView containerStyle={styles.popupStyle}>
                <View style={styles.forgotPasswordViewStyle}>
                  <Logo testID="App-Logo" style={styles.logo} />

                  <Text style={styles.headerText}>
                    {translate('forgotPassword.forgotPasswordTitle')}
                  </Text>

                  <FormInput
                    testID="email"
                    error={errorMessage === FormError.INVALID_EMAIL}
                    alignTitle="left"
                    title={translate('common.emailAddress')}
                    value={form.email}
                    placeholder={translate('login.emailHint')}
                    onChangeText={email => onChangeFormProp('email', email)}
                    containerStyle={styles.textInputContainer}
                  />
                  {errorMessage === FormError.INVALID_EMAIL && (
                    <Error
                      style={styles.error}
                      text={translate(errorMessage)}
                    />
                  )}
                </View>

                <View style={styles.buttonContainer}>
                  <View style={styles.button}>
                    <Button
                      size="small"
                      success
                      fluid
                      testID="reset"
                      title={translate('button.sendResetLink')}
                      onPress={onPressResetPassword}
                    />
                  </View>
                  <View style={styles.marginTop2}>
                    <Button
                      size="small"
                      secondary
                      fluid
                      testID="login"
                      title={translate('button.login')}
                      onPress={onPressLogin}
                    />
                  </View>
                </View>
              </PopupView>
            </View>
          </KeyboardAvoidingView>
        </Gradient>
      </Layout>
    </React.Fragment>
  );
};

export default withTheme(ForgotPassword);
