import { StyleFn, RenderProps } from '@hitz-group/domain';
import React from 'react';
import { ActivityIndicator } from 'react-native';
import { FelaComponent } from 'react-fela';
const loadingStyle: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

export interface LoadingIndicatorProps {
  testID?: string;
  size?: number | 'small' | 'large';
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  testID,
  size = 'small',
}) => (
  <FelaComponent style={loadingStyle}>
    {({ style, theme }: RenderProps): React.ReactNode => (
      <ActivityIndicator
        testID={testID}
        color={theme.colors.primary}
        style={style}
        size={size}
      />
    )}
  </FelaComponent>
);

export default LoadingIndicator;
