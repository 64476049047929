import { useCurrency } from '@hitz-group/localization';
import { StyleFn, Customer } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import IconButton from '../Button/IconButton';
import Label from '../Label/Label';
import Title from '../Title/Title';
import { useModal } from '@hitz-group/rn-use-modal';
import SearchCustomer from '../Modals/Customer/SearchCustomer';
export interface PaymentOptionHeaderProps {
  customerName: string;
  assignedCustomerId?: string;
  orderId: string;
  credit?: string | number;
  assignCustomerToOrder?: (customer: Customer) => void;
  onUnassignCustomerToOrder: () => void;
}

const containerStyle: StyleFn = ({ theme }) => ({
  width: 310,
  height: 44,
  backgroundColor: theme.colors.white,
  marginBottom: theme.spacing.small,
  paddingHorizontal: theme.padding.medium,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: theme.radius.small,
});
const IconContainerStyle: StyleFn = ({ theme }) => ({
  marginHorizontal: theme.spacing.small,
});

const labelContainerStyle: StyleFn = () => ({
  marginLeft: 'auto',
});

const PaymentOptionHeader: React.FC<PaymentOptionHeaderProps> = ({
  assignedCustomerId,
  customerName,
  credit,
  assignCustomerToOrder,
  onUnassignCustomerToOrder,
}) => {
  const { css, theme } = useFela();
  const { formatCurrency } = useCurrency();
  const { showModal, closeModal } = useModal();

  const onPressCustomer = useCallback(() => {
    showModal(
      <SearchCustomer
        assignCustomerToOrder={assignCustomerToOrder}
        assignedCustomerId={assignedCustomerId}
        unassignCustomerToOrder={onUnassignCustomerToOrder}
      />,
      { onBackdropPress: closeModal },
    );
  }, [
    showModal,
    assignCustomerToOrder,
    assignedCustomerId,
    onUnassignCustomerToOrder,
    closeModal,
  ]);

  return (
    <View style={css(containerStyle)} testID="payment-option-header">
      <View style={css(IconContainerStyle)}>
        <IconButton
          icon="user-circle"
          iconColor={theme.colors.brandPrimary}
          onPress={onPressCustomer}
        />
      </View>
      <Title testID="customer-name">{customerName.trim()}</Title>
      {credit && (
        <Label
          value={formatCurrency(+credit || 0)}
          containerStyle={css(labelContainerStyle)}
          colorKey={3}
        />
      )}
    </View>
  );
};

export default PaymentOptionHeader;
