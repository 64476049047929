import {
  useLazyQuery,
  useMutation,
  useSubscription,
} from '@apollo/client/react/hooks';
import { useNetInfo } from '@react-native-community/netinfo';
import {
  CREATE_CUSTOMER,
  GET_CUSTOMERS,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  GET_CUSTOMER,
  ENROLL_CUSTOMER_LOYALTY,
} from '../../graphql/customer';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { filterCustomersByKeyword } from '../../utils/customerHelper';
import {
  CreateCustomerRequest,
  Customer,
  CustomerAccountDetails,
  EnrollCustomerInput,
} from '@hitz-group/domain';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { UpdateCustomerInfoEvent } from '../../graphql/subscriptions';
import { keyBy } from 'lodash';
import { useNotification } from '../Notification';
import { translate } from '@hitz-group/localization';

export interface CustomerAddress {
  line1: string;
  line2?: string;
  city: string;
  state?: string;
  postalCode: string;
  country?: string;
}
export interface UpdateCustomerRequest {
  id: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  password?: string;
  preferredAddress?: CustomerAddress;
  customerAccountDetails?: CustomerAccountDetails;
}

export interface UpdateCustomerBalanceRequest {
  id: string;
  customerAccountDetails?: CustomerAccountDetails;
}

export interface UseCustomersProps {
  searchResults: (searchString: string, limit?: number) => Array<Customer>;
  customers: Array<Customer>;
  customerMaps: Record<string, Customer>;
  addNewCustomer: (
    input: Partial<CreateCustomerRequest>,
  ) => Promise<Customer | undefined>;
  loading: boolean;
  error: string | undefined;
  getCustomers: () => void;
  getCustomerById: (id: string) => void;
  deleteCustomer: (id: string) => Promise<void>;
  updateCustomer: (input: UpdateCustomerRequest) => Promise<void>;
  updateCustomerBalance: (input: UpdateCustomerRequest) => Promise<void>;
  searchCustomers: (searchString: string) => Customer[];
  checkEmailExists: (email?: string, customerId?: string) => boolean;
  enrollCustomerLoyalty: (input: EnrollCustomerInput) => Promise<Customer>;
}

const DEFAULT_SEARCH_RESULTS_LENGTH = 10;

export function useCustomers(): UseCustomersProps {
  const [customerMaps, setCustomerMaps] = useState<Record<string, Customer>>(
    {},
  );
  const { showNotification } = useNotification();
  const netInfo = useNetInfo();
  const isNetworkAvailable = netInfo.isConnected && netInfo.isInternetReachable;
  const { data: subscriptionData } = useSubscription(UpdateCustomerInfoEvent);
  const customers = useMemo(() => Object.values(customerMaps), [customerMaps]);
  const searchCustomers = useCallback(
    (searchQuery: string) => {
      return customers.filter(({ firstName, lastName, email, phone }) =>
        [
          firstName.toLowerCase(),
          lastName.toLowerCase(),
          email.toLowerCase(),
          phone,
        ].some(query => query.includes(searchQuery.toLowerCase())),
      );
    },
    [customers],
  );
  const checkEmailExists = useCallback(
    (email?: string, customerId?: string) => {
      if (
        email &&
        customers.find(
          customer => customer.email === email && customer.id !== customerId,
        )
      ) {
        return true;
      }
      return false;
    },
    [customers],
  );

  const updateCustomerInfo = useCallback((input?: Customer) => {
    const updateCustomerId = input?.id;
    if (!updateCustomerId) return;
    setCustomerMaps(preCustomerMaps => {
      return {
        ...preCustomerMaps,
        [updateCustomerId]: { ...preCustomerMaps[updateCustomerId], ...input },
      };
    });
  }, []);

  const [getCustomersRequest, getCustomersResponse] = useLazyQuery(
    GET_CUSTOMERS,
    {
      fetchPolicy: isNetworkAvailable ? 'cache-and-network' : 'cache-only',
      onError: noopHandler,
      onCompleted: response => {
        setCustomerMaps(keyBy(response.customers || [], 'id'));
      },
    },
  );

  const [getCustomerByIdRequest, getCustomerByIdResponse] = useLazyQuery(
    GET_CUSTOMER,
    {
      fetchPolicy: isNetworkAvailable ? 'cache-and-network' : 'cache-only',
      onError: noopHandler,
      onCompleted: response => {
        updateCustomerInfo(response.customerById);
      },
    },
  );

  const [createCustomerRequest, createCustomerResponse] = useMutation(
    CREATE_CUSTOMER,
    {
      onError: noopHandler,
    },
  );

  const [enrollCustomerLoyaltyRequest, enrollCustomerLoyaltyResponse] =
    useMutation<{
      enrollCustomerLoyalty: Customer;
    }>(ENROLL_CUSTOMER_LOYALTY, {
      onCompleted: () => {
        showNotification({
          message: translate('customerLoyalty.enrollLoyaltySuccessfully'),
          success: true,
        });
      },
    });

  const getCustomerById = useCallback(
    (id: string) => {
      getCustomerByIdRequest({
        variables: {
          id: id,
        },
      });
    },
    [getCustomerByIdRequest],
  );

  const getCustomers = useCallback((): void => {
    getCustomersRequest();
  }, [getCustomersRequest]);

  const [deleteCustomerRequest, deleteCustomerResponse] = useMutation(
    DELETE_CUSTOMER,
    {
      onError: noopHandler,
    },
  );

  const [updateCustomerRequest, updateCustomerResponse] = useMutation(
    UPDATE_CUSTOMER,
    {
      onError: noopHandler,
    },
  );

  const [updateCustomerBalanceRequest, updateCustomerBalanceResponse] =
    useMutation(UPDATE_CUSTOMER, {
      onError: noopHandler,
    });

  /**
   * Create a new customer an returns the created customer object
   *
   * If customer creation failed it will return `undefined`
   */
  const addNewCustomer = useCallback(
    async (
      input: Partial<CreateCustomerRequest>,
    ): Promise<Customer | undefined> => {
      const response = await createCustomerRequest({
        variables: {
          input,
        },
      });
      if (response?.data && response?.data?.createCustomer) {
        updateCustomerInfo(response?.data?.createCustomer);
        return response.data.createCustomer;
      }
      return undefined;
    },
    [createCustomerRequest, updateCustomerInfo],
  );

  const enrollCustomerLoyalty = useCallback(
    async (input: Partial<EnrollCustomerInput>): Promise<Customer> => {
      const response = await enrollCustomerLoyaltyRequest({
        variables: {
          input,
        },
      });
      updateCustomerInfo(response?.data?.enrollCustomerLoyalty);
      return response.data?.enrollCustomerLoyalty as Customer;
    },
    [enrollCustomerLoyaltyRequest, updateCustomerInfo],
  );

  const deleteCustomer = useCallback(
    async (id: string): Promise<void> => {
      const response = await deleteCustomerRequest({
        variables: {
          id,
        },
      });

      if (response?.data?.deleteCustomer) {
        setCustomerMaps(preCustomerMap => {
          const updateCustomerMaps = { ...preCustomerMap };
          delete updateCustomerMaps[id];
          return updateCustomerMaps;
        });
      }
    },
    [deleteCustomerRequest],
  );

  const updateCustomer = useCallback(
    async (input: UpdateCustomerRequest): Promise<void> => {
      await updateCustomerRequest({
        variables: { input },
      });
    },
    [updateCustomerRequest],
  );

  const updateCustomerBalance = useCallback(
    async (input: UpdateCustomerBalanceRequest): Promise<void> => {
      await updateCustomerBalanceRequest({
        variables: { input },
      });
    },
    [updateCustomerBalanceRequest],
  );

  useEffect(() => {
    if (updateCustomerResponse?.data?.updateCustomer) {
      updateCustomerInfo(updateCustomerResponse?.data?.updateCustomer);
    }
  }, [updateCustomerInfo, updateCustomerResponse?.data]);

  useEffect(() => {
    if (subscriptionData?.updateCustomerEvent) {
      const updateCustomer = subscriptionData.updateCustomerEvent;
      updateCustomerInfo(updateCustomer);
    }
  }, [subscriptionData, updateCustomerInfo]);

  /**
   * For now this is a filter on customers array (in-memory).
   * In future we can replace with an API call if needed
   *
   * In future need to accept start index as input
   */
  const searchResults = useCallback(
    (
      searchString: string,
      limit: number = DEFAULT_SEARCH_RESULTS_LENGTH,
    ): Array<Customer> => {
      // Check with team if we can use API querying here,
      return filterCustomersByKeyword(
        customers,
        searchString.toLowerCase(),
      ).slice(0, limit);
    },
    [customers],
  );

  const RESPONSE_ENTITIES = [
    getCustomersResponse,
    getCustomerByIdResponse,
    createCustomerResponse,
    deleteCustomerResponse,
    updateCustomerResponse,
    updateCustomerBalanceResponse,
    enrollCustomerLoyaltyResponse,
  ];

  const loading = isLoading(RESPONSE_ENTITIES);
  const _error = getError(RESPONSE_ENTITIES);

  return {
    searchResults,
    addNewCustomer,
    getCustomers,
    getCustomerById,
    deleteCustomer,
    updateCustomer,
    updateCustomerBalance,
    checkEmailExists,
    searchCustomers,
    loading,
    error: _error ? parseApolloError(_error) : undefined,
    customers,
    enrollCustomerLoyalty,
    customerMaps,
  };
}
