import firebase from 'firebase/app';
import 'firebase/messaging';
import { REACT_APP_FIREBASE_CONFIG } from 'react-native-dotenv';

if (process.env['REACT_APP_FIREBASE_CONFIG'] || REACT_APP_FIREBASE_CONFIG) {
  const firebaseConfig = JSON.parse(
    process.env['REACT_APP_FIREBASE_CONFIG'] || REACT_APP_FIREBASE_CONFIG,
  );
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
}

export default firebase;
