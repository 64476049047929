import { Customer } from '@hitz-group/domain';

export const filterCustomersByKeyword = (
  customers: Customer[],
  keyword: string,
): Customer[] =>
  customers.filter(
    customer =>
      customer?.name.toLowerCase().includes(keyword) ||
      customer.email.toLowerCase().includes(keyword) ||
      customer.phone.includes(keyword),
  );
