import { useMutation } from '@apollo/client/react/hooks';
import { App } from '@hitz-group/domain';
import { useCallback, useMemo } from 'react';
import { GET_APP_TOKEN } from '../../graphql/session';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';

export interface UseAppToken {
  requestAppToken: (source: App) => Promise<string | undefined>;
  loading: boolean;
  error?: string;
}

export function useAppToken(): UseAppToken {
  const [appTokenRequest, appTokenResponse] = useMutation<
    {
      clientAppToken: { token: string };
    },
    { source: App }
  >(GET_APP_TOKEN, {
    onError: noopHandler,
  });

  const requestAppToken = useCallback(
    async (source: App): Promise<string | undefined> => {
      try {
        const appTokenResponse = await appTokenRequest({
          variables: {
            source,
          },
        });

        if (appTokenResponse.data) {
          return appTokenResponse.data.clientAppToken.token;
        }
      } catch {
        // TODO: if failed retry for one more time
      }
      return undefined;
    },
    [appTokenRequest],
  );

  const RESPONSE_ENTITIES = [appTokenResponse];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      requestAppToken,
      loading,
      error: error ? parseApolloError(error) : undefined,
    }),
    [requestAppToken, loading, error],
  );
}
