import { StyleFn, Money, Currency, DEFAULT_TAX } from '@hitz-group/domain';
import React, { useCallback, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import Modal from '../../../../../components/Modals/Modal';
import DropDown from '../../../../../components/DropDown/DropDown';
import FormInput from '../../../../../components/FormInput/FormInput';
import Button from '../../../../../components/Button/Button';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import { CreateModifierInput, ModifierType } from '@hitz-group/domain';
import { pascalCase } from 'change-case';
import { isWeb } from '../../../../../common/theme';
interface CreateModifierInputAlias extends CreateModifierInput {
  tax?: string;
  price?: Money;
}
const createModalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const buttonStyle: StyleFn = ({ theme }) => ({
  width: 280,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginTop: theme.spacing.big,
  zIndex: 200,
});

const createNewFormTextStyle: StyleFn = () => ({
  alignSelf: 'flex-start',
});

const createVenueLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const inputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 280,
  marginBottom: theme.spacing.small,
});

const halfSizedStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  marginBottom: theme.spacing.small,
  width: 125,
});

const halfSizedStyleRight: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 125,
  marginLeft: theme.spacing.medium,
  marginTop: isWeb ? theme.spacing.small : theme.spacing.small + 2,
});

const dopDownStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 280,
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.big,
  zIndex: 50,
});

const extraPopoverStyle: StyleFn = () => ({
  width: 280,
});

const extraPopoverStyleTaxRate: StyleFn = () => ({
  width: 115,
});

const textInputStyle: StyleFn = ({ theme, width }) => ({
  height: theme.input.height,
  width: width,
});

const rowViewStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  marginTop: theme.spacing.small,
  zIndex: 500,
  justifyContent: 'space-between',
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: 280,
  height: theme.input.height,
  alignSelf: 'center',
  marginBottom: theme.spacing.big * 1.5,
  zIndex: 600,
});
const touchableStyle: StyleFn = () => ({
  minHeight: 45,
  flexWrap: 'wrap',
});

const itemContainer: StyleFn = () => ({
  height: 38 * 3,
  zIndex: -999,
});
const textStyle: StyleFn = () => ({
  top: 10,
  right: 5,
});

interface CreatemodifierModalProps {
  onCreate: (prodInput: CreateModifierInputAlias) => void;
  taxesOptions: { label: string; value: string }[];
  modifierGroups: { label: string; value: string }[];
  modifierDefaultValues?: Partial<CreateModifierInputAlias>;
}

export const CreatemodifierModal: React.FC<CreatemodifierModalProps> = ({
  onCreate,
  taxesOptions,
  modifierGroups,
  modifierDefaultValues,
}: CreatemodifierModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { currency, unAppendCurrency, appendCurrency } = useCurrency();
  // default tax should be GST
  const defaultTax =
    taxesOptions?.filter(x => x.label === DEFAULT_TAX)?.[0]?.value || '';
  const [modifiersForm, setmodifiersForm] = useState({
    tax: defaultTax,
    type: ModifierType.PRODUCT, // default type
    name: modifierDefaultValues?.name || '',
    modifierGroups: modifierDefaultValues?.modifierGroups || [],
    price: {
      amount: 0,
      currency: currency,
    },
  } as CreateModifierInputAlias);
  const { showNotification } = useNotification();

  const modifierTypesOptions = useMemo(() => {
    return Object.values(ModifierType).map(x => ({
      value: x,
      label: pascalCase(x),
    }));
  }, []);

  const onChange = useCallback(
    (prop: string, value) => {
      if (prop === 'price') {
        const temp = unAppendCurrency(value);
        value = { currency: currency as Currency, amount: temp };
      }
      setmodifiersForm(prev => {
        return { ...prev, [prop]: value };
      });
    },
    [unAppendCurrency, currency],
  );

  const onCreateModifier = useCallback(() => {
    let message = '';
    if (!modifiersForm.name) {
      message = translate('modifiers.pleaseEnterAModifierName');
    } else if (!modifiersForm?.tax) {
      message = translate('modifiers.pleaseSelectSellingTaxForTheModifier');
    } else if (!modifiersForm.type) {
      message = translate('modifiers.pleaseSelectModifierType');
    } else {
      let createProdForm = { ...modifiersForm };
      const price = modifiersForm?.price?.amount || 0;
      createProdForm = {
        ...modifiersForm,
        name: modifiersForm.name.trim(),
        price: {
          amount: +price,
          currency: currency as Currency,
        },
      };
      onCreate(createProdForm);
    }
    if (message) {
      showNotification({
        error: true,
        message,
      });
    }
  }, [onCreate, modifiersForm, showNotification, translate, currency]);

  const sellingPrice = modifiersForm?.price?.amount;

  return (
    <Modal
      title={translate('modifiers.createNewModifier')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
    >
      <FormInput
        title={translate('modifiers.modifierName')}
        placeholder={translate('modifiers.modifierName')}
        value={modifiersForm.name}
        containerStyle={css(inputStyle)}
        textStyle={css(createNewFormTextStyle)}
        onChangeText={onChange.bind(null, 'name')}
        maxLength={50}
      />

      <MultipleSelect
        label={translate('modifiers.modifierGroups')}
        values={modifierGroups}
        selectedValues={modifiersForm.modifierGroups}
        containerStyle={css(dropDownStyle)}
        onValueChange={onChange.bind(null, 'modifierGroups')}
        searchLabel={translate('modifiers.modifierGroups')}
        searchPlaceHolder={translate('modifiers.searchModifierGroups')}
        maxItems={3}
        touchableStyle={css(touchableStyle)}
        textStyle={css(textStyle)}
      />

      <DropDown
        title={translate('modifiers.modifierType')}
        options={modifierTypesOptions}
        selectedValue={modifiersForm.type}
        containerStyle={css(dopDownStyle)}
        onValueChange={onChange.bind(null, 'type')}
        itemsContainerStyle={css(itemContainer)}
        extraPopoverStyle={css(extraPopoverStyle)}
      />
      <View style={css(rowViewStyle)}>
        <FormInput
          title={translate('backOfficeProducts.productPrice')}
          placeholder={translate('backOfficeProducts.productPrice')}
          value={appendCurrency(sellingPrice + '')}
          containerStyle={css(halfSizedStyle)}
          textInputStyle={css(textInputStyle({ theme, width: 115 }))}
          textStyle={css(createNewFormTextStyle)}
          onChangeText={onChange.bind(null, 'price')}
        />

        <DropDown
          title={translate('modifiers.taxRate')}
          options={taxesOptions}
          selectedValue={modifiersForm.tax}
          containerStyle={css(halfSizedStyleRight)}
          onValueChange={onChange.bind(null, 'tax')}
          itemsContainerStyle={css(itemContainer)}
          extraPopoverStyle={css(extraPopoverStyleTaxRate)}
        />
      </View>

      <Button
        title={translate('productSettings.create')}
        containerStyle={css(buttonStyle)}
        labelStyle={css(createVenueLabelStyle)}
        color={theme.colors.green}
        onPress={onCreateModifier}
      />
    </Modal>
  );
};
