import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';

export interface Notification {
  id?: symbol;
  error?: boolean;
  success?: boolean;
  info?: boolean;
  message: string;
}

export interface NotificationProviderProps {
  children: React.ReactNode;
}

export interface NotificationContextValue {
  notifications: Notification[];
  showNotification: (notification: Notification, autoClose?: boolean) => void;
  closeNotification: (id: symbol | undefined) => void;
  closeAllNotifications: () => void;
}

const DEFAULT_TTL = 3000; // in ms
export const NotificationContext = createContext<NotificationContextValue>(
  {} as NotificationContextValue,
);

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState<Array<Notification>>([]);

  const closeNotification = useCallback((id: symbol | undefined) => {
    setNotifications(notifications =>
      notifications.filter(notification => notification.id !== id),
    );
  }, []);

  /**
   * @param newNotification Notification
   * @param autoClose boolean default value is true
   */
  const showNotification = useCallback(
    (newNotification: Notification, autoClose = true) => {
      const id = Symbol(newNotification.message);
      setNotifications(notifications => [
        ...notifications,
        { ...newNotification, id } as Notification,
      ]);
      if (autoClose) {
        setTimeout(() => {
          closeNotification(id);
        }, DEFAULT_TTL);
      }
    },
    [closeNotification],
  );

  const closeAllNotifications = useCallback(() => {
    setNotifications([]);
  }, []);

  const value = useMemo(
    () => ({
      showNotification,
      notifications,
      closeNotification,
      closeAllNotifications,
    }),
    [showNotification, notifications, closeNotification, closeAllNotifications],
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotification(): NotificationContextValue {
  return useContext(NotificationContext);
}
