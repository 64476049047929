import 'react-native-get-random-values';
import { EventInput, Order, OrderAction, OrderEvent } from '@hitz-group/domain';
import { v4 as uuidv4 } from 'uuid';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';
import Database, { COLLECTIONS } from '../storage/database';
import { mapOrderEvent } from '../storage/mappers/orderEvents';
import LogRocket from '@logrocket/react-native';
import { TRACKING_EVENTS } from './logRocketHelper';
import { getApolloCache } from './apolloClient';
import { GET_ORDER_FRAGMENT } from '../hooks/app/orders/graphql';

type SourceOptionsAttrs =
  | 'organizationId'
  | 'venueId'
  | 'storeId'
  | 'deviceId'
  | 'triggeredBy';

type SourceOptions = Record<SourceOptionsAttrs, string>;

const SOURCE_OPTIONS_ATTRS: SourceOptionsAttrs[] = [
  'organizationId',
  'venueId',
  'storeId',
  'deviceId',
  'triggeredBy',
];

export const getOrderFragmentFromCache = (orderId: string) => {
  const cache = getApolloCache();
  const cachedOrder = cache.readFragment<Order>({
    id: `Order:${orderId}`,
    fragment: GET_ORDER_FRAGMENT,
  });
  return cachedOrder;
};

export async function clearOrderEventsFromDb(orderId: string) {
  const db = (await Database.getInstance()).collections[
    COLLECTIONS.order_events
  ];
  if (!db) return [];
  const result = await db.find().where('orderID').eq(orderId).remove();
  return result as EventInput[];
}

export async function getOrderEventsFromDb(orderId: string) {
  const db = (await Database.getInstance()).collections[
    COLLECTIONS.order_events
  ];
  if (!db) return [];
  const result = (await db.find().where('orderID').eq(orderId).exec()).map(
    event => event.toJSON().payload,
  );
  return result as EventInput[];
}

export async function storeOrderEventsInDb(events: EventInput[]) {
  const db = (await Database.getInstance()).collections[
    COLLECTIONS.order_events
  ];
  if (db) {
    const res = await db.bulkInsert(events.map(mapOrderEvent));

    if (res.error.length) {
      res.error.forEach(
        async event =>
          event.writeRow?.document &&
          (await db.atomicUpsert(event.writeRow.document)),
      );
    }
  }
}

/**
 * Generate event object of type T which extends OrderEvent
 * helps to quickly create an order event by specifying only -
 * properties necessary for the event excluding all props from OrderEvent
 * @param action OrderAction eg: OrderAction.ORDER_INITIATE
 * @param session Session object, throws exception if invalid
 * @param input Props specific to event type
 */
export function generateOrderEvent<T extends OrderEvent>(
  action: OrderAction,
  sourceOptions: Partial<SourceOptions>,
  input?: Omit<T, keyof OrderEvent> & {
    orderId?: string;
    previous?: string;
    source?: string;
  },
): OrderEvent {
  if (typeof action !== 'string' || typeof sourceOptions !== 'object') {
    throw new Error(
      'Expected at least two arguments of type OrderAction and SourceOptions',
    );
  }

  if (input && typeof input !== 'object') {
    throw new Error(
      'Expected three arguments of type OrderAction, Session and OrderEvent',
    );
  }

  if (
    !SOURCE_OPTIONS_ATTRS.filter(attr => attr !== 'triggeredBy').every(
      attr => sourceOptions[attr] && typeof sourceOptions[attr] !== 'undefined',
    )
  ) {
    throw new Error('Invalid session information');
  }
  const event = {
    ...(input || {}),
    action,
    id: uuidv4(),
    timestamp: new Date().getTime(),
    orderId: input?.orderId || uuidv4(),
    ...(SOURCE_OPTIONS_ATTRS.reduce(
      (acc, attr) => ({ ...acc, [attr]: sourceOptions[attr] }),
      {},
    ) as Record<SourceOptionsAttrs, string>),
  } as OrderEvent;

  // store in db
  storeOrderEventsInDb([event]);

  return event;
}

/**
 * Remove duplicate order events based on repeated `previous` key
 * and correct the previous key in events accordingly.
 * @param events List of EventInput that we need to sync.
 */
export function removeOrderEventDuplicates(events: EventInput[]) {
  const uniqEvents = uniqBy(events, 'previous');
  return uniqEvents.map((event, index) => {
    if (index > 0 && event.previous !== uniqEvents[index - 1].id) {
      return { ...event, previous: uniqEvents[index - 1].id };
    }
    return event;
  });
}

/**
 * Checks if the events has any missing event
 * @param events List of EventInput that we need to sync.
 */
export function hasMissingEvents(events: EventInput[]) {
  let hasMissingEvents = false;
  const orderId = events[0]?.orderId;
  const allEventIds = events.map(event => event.id);
  // Events with previous as undefined. Generally these would be initial events for any order.
  const lengthOfUndefined = events.filter(event => !event.previous).length;
  if (lengthOfUndefined > 1) {
    hasMissingEvents = true;
  }
  let eventWithMisssingPrevious: EventInput | undefined;
  // Events with missing previous events.
  const lengthOfMissingEvents = events.filter(event => {
    if (event.previous && !allEventIds.includes(event.previous)) {
      eventWithMisssingPrevious = event;
      return true;
    }
  }).length;
  if (lengthOfMissingEvents > 1) hasMissingEvents = true;

  const cachedOrder = getOrderFragmentFromCache(orderId);
  if (
    cachedOrder &&
    lengthOfMissingEvents === 1 &&
    // previous is not matching last sycned value of order
    eventWithMisssingPrevious?.previous !== cachedOrder.lastSyncedEventId
  ) {
    hasMissingEvents = true;
  }

  if (!hasMissingEvents) {
    // clean events in db
    clearOrderEventsFromDb(orderId);
  }
  console.log('hasMissingEvents', {
    hasMissingEvents,
    events,
    eventWithMisssingPrevious,
    cachedOrder,
    lengthOfMissingEvents,
    lengthOfUndefined,
  });
  return hasMissingEvents;
}

export async function fixMissingEvents(events: EventInput[]) {
  const orderId = events[0]?.orderId;
  // fetch events from storage by orderId
  const storedEvents = (await getOrderEventsFromDb(orderId)) || [];

  const combinedEvents = uniqBy([...storedEvents, ...events], 'id');

  const cachedOrder = getOrderFragmentFromCache(orderId);
  LogRocket.track(TRACKING_EVENTS.POS_MISSING_EVENTS_OCCURRED);
  console.log(TRACKING_EVENTS.POS_MISSING_EVENTS_OCCURRED, {
    tags: 'APP_ERROR',
    cachedOrder: cachedOrder,
    storedEvents: storedEvents,
    activeEvents: events,
  });

  // clean events in db
  clearOrderEventsFromDb(orderId);
  return combinedEvents;
}

/**
 * Adds missing events back to the list if possible
 * @param events List of EventInput that we need to sync.
 */
export async function handleMissingEvents(events: EventInput[]) {
  // group by order id
  const groupbyids = groupBy(events, 'orderId');
  const updatedEvents: EventInput[] = (
    await Promise.all(
      Object.keys(groupbyids).map(async orderId => {
        try {
          if (hasMissingEvents(groupbyids[orderId])) {
            return await fixMissingEvents(groupbyids[orderId]);
          } else {
            return groupbyids[orderId];
          }
        } catch (error) {
          LogRocket.track(TRACKING_EVENTS.POS_MISSING_EVENTS_HANDLING_FAILED);
          console.log(TRACKING_EVENTS.POS_MISSING_EVENTS_HANDLING_FAILED, {
            tags: 'APP_ERROR',
            events: events,
            error: error,
          });
          return groupbyids[orderId];
        }
      }),
    )
  ).flat();
  return updatedEvents;
}
