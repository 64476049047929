// TODO: there is some improvement in code,
// will do it once we have more time :D
import { useMutation } from '@apollo/client/react/hooks';
import {
  EventInput,
  MoneyMovement,
  Order,
  OrderAction,
  OrderEvent,
  OrderItem,
  OrderStatus,
  Printer,
  PrinterProfileType,
  PrintingOptions,
  Shift,
} from '@hitz-group/domain';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import {
  computeOrderState,
  getPrintableOrderItems,
} from '@hitz-group/order-helper';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '../../devConfig';
import { getQueue, queueEvents, resetQueue } from '../../events/eventsQueue';
import { pushEvents } from '../../events/pushEvents';
import { workerInstanceVar } from '../../state/cache';
import { userUtility } from '../../state/userUtility';
import {
  generateOrderEvent,
  removeOrderEventDuplicates,
} from '../../utils/orderEventHelper';
import rxjsInstance, {
  KitchenPrintEvent,
} from '../../utils/printerTemplates/kotEvents';
import {
  groupByPrinterProfileType,
  PrinterTemplateMapping,
} from '../../utils/printerTemplates/printingDataUtils';
import printHandler from '../../workers/handlers/printHandler';
import {
  openCashDrawerHandler,
  printBillHandler,
  printMoneyMovementReceiptHandler,
  printShiftHandler,
  printTestTemplateHandler,
} from '../../workers/handlers/printHandlers';
import { printKitchenDocketHandler } from '../../workers/handlers/printHandlers/printKitchenDocketHandler';
import { useOrders } from './orders/useOrders';
import { useSyncOrderEvents } from './useSyncOrderEvents';
import {
  WorkerAction,
  WorkerActionResultStatus,
  WorkerInput,
} from '../../workers/utils';
import { ORDER_SAVE } from '../app/orders/graphql';
import { Notification, useNotification } from '../Notification';
import { useBase64Image } from './useBase64Image';
import { useDevices } from './useDevices';
import { useNetworkStatusVar } from './useNetworkStatusVar';
import { useSession } from './useSession';

export interface UsePrintingWithTemplate {
  printBill: (
    order: Order,
    nthPaymentToPrint?: number,
  ) => Promise<Notification | void>;
  reprintKitchenDocket: (
    order: Order,
    orderItems: OrderItem[],
  ) => Promise<Notification | void>;
  printMoneyMovementReceipt: (
    moneyMovement: MoneyMovement,
  ) => Promise<Notification | void>;
  printShiftReceipt: (shift: Shift) => Promise<Notification | void>;
  printTestTemplate: (printer: Printer) => Promise<Notification | void>;
  openCashDrawer: () => Promise<Notification | void>;
}

export enum DocketType {
  CANCEL_DOCKET = 'CANCEL_DOCKET',
  RESEND_DOCKET = 'RESEND_DOCKET',
  KITCHEN_DOCKET = 'KITCHEN_DOCKET',
}

/**
 * Used to print the receipt or order
 */
export function usePrintingWithTemplate(): UsePrintingWithTemplate {
  const [session] = useSession();

  // FIXME: once image issues are fixed with `pngjs`
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orgBase64Logo, setOrgBase64Logo] = useState<string>();
  const { currency } = useCurrency();

  const { getBase64Img, base64Logo } = useBase64Image();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const previousEventsRef = useRef<OrderEvent[]>([]);
  const isConnected = useNetworkStatusVar();
  const { getOrderFromCache } = useOrders();

  const { syncOrderEvents: syncOrderEventsToserver } =
    useSyncOrderEvents(resetQueue);

  const { devices, getDevicesSynchronously } = useDevices({
    deviceId: session?.device?.id,
    storeId: session?.currentStore?.id,
  });

  const [printerTemplateMapping, setPrinterTemplateMapping] =
    useState<PrinterTemplateMapping>({
      [PrinterProfileType.BILLING]: {},
      [PrinterProfileType.KITCHEN]: {},
    });

  const workerInstance = workerInstanceVar();

  const syncOrderEvents = useCallback(
    (events: EventInput[]) => {
      const syncAllEvents = async () => {
        const data = await getQueue();
        const uniqueEvents = removeOrderEventDuplicates(events);
        if (isConnected) {
          syncOrderEventsToserver([...data, ...uniqueEvents]);
        } else {
          await queueEvents(events);
        }
      };
      syncAllEvents();
    },
    [isConnected, syncOrderEventsToserver],
  );

  const [saveOrder] = useMutation(ORDER_SAVE, {});

  const onDocketPrint = useCallback(
    prevOrder => {
      const printDocketEvent = generateOrderEvent(
        OrderAction.ORDER_PRINT_KITCHEN_DOCKET,
        {
          organizationId: session.currentOrganization?.id,
          venueId: session.currentVenue?.id,
          deviceId: session.device?.id,
          storeId: session.currentStore?.id,
          triggeredBy: userUtility.posUser?.id,
        },
        {
          orderId: prevOrder?.id,
          previous: prevOrder?.prevEventId,
        },
      );
      // Save Order for client Cache
      const order = computeOrderState([printDocketEvent], prevOrder);
      saveOrder({ variables: { data: order } });
      // Sync All Events
      const updatedEvent = [...previousEventsRef.current, printDocketEvent];
      pushEvents(updatedEvent, syncOrderEvents);
      previousEventsRef.current = [];
    },
    [saveOrder, session, syncOrderEvents],
  );

  /**
   * Set base64 logo for printing
   */
  useEffect(() => {
    if (base64Logo) {
      setOrgBase64Logo(base64Logo);
    }
  }, [base64Logo]);

  /**
   * Get base64 logo for printing
   */
  useEffect(() => {
    // if (session.currentOrganization) {
    //   getBase64Img(
    //     'https://till-x-storage-development.s3-ap-southeast-2.amazonaws.com/images/organizations/logos/miniondevs-print.png',
    //   );
    // }
    if (
      session.currentOrganization?.id &&
      session.currentOrganization?.logoUrl
    ) {
      // fetch base64 logo
      getBase64Img(session.currentOrganization.logoUrl);
    }
  }, [session.currentOrganization, getBase64Img]);

  /**
   * Generate printer template, printer and printer profile mapping object.
   * It is used to pick the required configurations quickly while choosing between printer, template for printing
   */
  useEffect(() => {
    if (devices && session?.device?.id && devices[session?.device?.id]) {
      const currentDevice = devices[session?.device?.id];

      if (currentDevice.printingOptions) {
        const _groupByPrinterProfileType = groupByPrinterProfileType(
          currentDevice.printingOptions as PrintingOptions[],
        );
        setPrinterTemplateMapping(_groupByPrinterProfileType);
      }
    }
  }, [session.device?.id, devices]);

  /**
   * Print bill receipts for a given order
   */
  const printBill = useCallback(
    async (
      order: Order,
      nthPaymentToPrint?: number,
    ): Promise<Notification | void> => {
      const bufferPayload: WorkerInput = {
        action: WorkerAction.PRINT_BILL,
        data: {
          order,
          currency,
          printerTemplateMapping,
          session,
          nthPaymentToPrint,
        },
      };
      let bufferObjs;
      try {
        bufferObjs = printBillHandler(bufferPayload);
      } catch (error) {
        showNotification({ info: true, message: error?.message });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_BILL,
        data: {
          bufferObjs,
          order,
        },
        priority: 1,
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const result = await printHandler(workerPayload);
        if (result.status === WorkerActionResultStatus.ERROR) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
      return { success: true, message: translate('printing.printSuccess') };
    },
    [
      printerTemplateMapping,
      session,
      workerInstance,
      currency,
      translate,
      showNotification,
    ],
  );

  const syncPendingEvents = useCallback(() => {
    if (!previousEventsRef.current.length) return;
    pushEvents([...previousEventsRef.current], syncOrderEvents);
    previousEventsRef.current = [];
  }, [syncOrderEvents]);

  /**
   * Used by kitchen printing (as of now)  /**
   * Used from TakeOrder screen relay on order events
   */
  const printKitchenDocket = useCallback(
    async (order: Order, printItems: OrderItem[]) => {
      const bufferPayload = {
        action: WorkerAction.PRINT_KITCHEN_DOCKET,
        data: {
          order,
          printerTemplateMapping,
          session,
          printItems,
          translatedNowCourse: translate('common.now'),
        },
      };
      let bufferObjs;

      try {
        bufferObjs = printKitchenDocketHandler(bufferPayload);
      } catch (error) {
        showNotification({ info: true, message: error?.message });
        syncPendingEvents();
        return;
      }

      if (!bufferObjs.length) {
        syncPendingEvents();
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_KITCHEN_DOCKET,
        data: {
          bufferObjs,
          order,
        },
        priority: 2,
      };

      onDocketPrint(order);
      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const result = await printHandler(workerPayload);
        if (result.status === WorkerActionResultStatus.ERROR) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
    },
    [
      printerTemplateMapping,
      session,
      onDocketPrint,
      workerInstance,
      showNotification,
      syncPendingEvents,
      translate,
    ],
  );

  useEffect(() => {
    const subscription = rxjsInstance.events.subscribe(
      (eventsData: KitchenPrintEvent) => {
        const { orderId, preEvents } = eventsData;
        const order = getOrderFromCache(orderId) as Order;
        const printItems = getPrintableOrderItems(order?.orderItems);
        previousEventsRef.current = preEvents;
        const isPartiallyPay =
          preEvents.some(
            event =>
              event.action === OrderAction.ORDER_PAYMENT ||
              event.action === OrderAction.ORDER_PAYMENT_PROCESSED,
          ) && order.status !== OrderStatus.COMPLETED;

        if (printItems.length && !isPartiallyPay) {
          printKitchenDocket(order, printItems);
        } else {
          syncPendingEvents();
        }
      },
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [getOrderFromCache, printKitchenDocket, syncPendingEvents]);

  /**
   * Print money movement event receipts
   */
  const printMoneyMovementReceipt = useCallback(
    async (moneyMovement: MoneyMovement): Promise<Notification | void> => {
      const bufferPayload = {
        action: WorkerAction.PRINT_MONEY_MOVEMENT,
        data: {
          moneyMovement,
          printerTemplateMapping,
          session,
          currency,
        },
      };
      let bufferObjs;
      try {
        bufferObjs = printMoneyMovementReceiptHandler(bufferPayload);
      } catch (error) {
        showNotification({ info: true, message: error?.message });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_MONEY_MOVEMENT,
        data: {
          bufferObjs,
        },
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const result = await printHandler(workerPayload);
        if (result.status === WorkerActionResultStatus.ERROR) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
    },
    [
      printerTemplateMapping,
      currency,
      workerInstance,
      session,
      translate,
      showNotification,
    ],
  );

  /**
   * Used from TakeOrder screen to resend docket to kitchen
   */
  // temporary comments -> will handle this function later by using ref to cache latest print data ( just idea)
  const reprintKitchenDocket = useCallback(
    async (order: Order, orderItems: OrderItem[]) => {
      printKitchenDocket(order, orderItems);
    },
    [printKitchenDocket],
  );

  /**
   * Print shift summary receipt
   */
  const printShiftReceipt = useCallback(
    async (shift: Shift): Promise<Notification | void> => {
      const bufferPayload = {
        action: WorkerAction.PRINT_SHIFT,
        data: {
          shift,
          printerTemplateMapping,
          session,
          currency,
        },
      };
      let bufferObjs;
      try {
        bufferObjs = printShiftHandler(bufferPayload);
      } catch (error) {
        showNotification({ info: true, message: error?.message });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_SHIFT,
        data: {
          bufferObjs,
        },
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const result = await printHandler(workerPayload);

        if (result.status === WorkerActionResultStatus.ERROR) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
    },
    [
      printerTemplateMapping,
      session,
      workerInstance,
      currency,
      translate,
      showNotification,
    ],
  );

  const openCashDrawer = useCallback(async (): Promise<Notification | void> => {
    const bufferPayload = {
      action: WorkerAction.PRINT_OPEN_CASH_DRAWER,
      data: {
        printerTemplateMapping,
      },
    };
    let bufferObjs;
    try {
      bufferObjs = openCashDrawerHandler(bufferPayload);
    } catch (error) {
      showNotification({ info: true, message: error?.message });
      return;
    }

    const workerPayload = {
      action: WorkerAction.PRINT_OPEN_CASH_DRAWER,
      data: {
        bufferObjs,
      },
      priority: 0,
    };

    if (workerInstance) {
      workerInstance.send(workerPayload);
    } else {
      const result = await printHandler(workerPayload);
      if (result.status === WorkerActionResultStatus.ERROR) {
        return {
          error: true,
          message: translate('printing.printFailed'),
        };
      }
    }
  }, [printerTemplateMapping, workerInstance, translate, showNotification]);

  const printTestTemplate = useCallback(
    async (printer: Printer): Promise<Notification | void> => {
      const devices = await getDevicesSynchronously();
      const printerData = { ...printer };
      printerData.store = {
        ...printerData.store,
      };

      const bufferPayload = {
        action: WorkerAction.PRINT_TEST_RECEIPT,
        data: {
          devices: Object.values(devices),
          printer: printerData,
          session,
        },
      };
      let bufferObjs;
      try {
        bufferObjs = printTestTemplateHandler(bufferPayload);
      } catch (error) {
        showNotification({ info: true, message: error?.message });
        return;
      }

      const workerPayload = {
        action: WorkerAction.PRINT_TEST_RECEIPT,
        data: {
          bufferObjs,
        },
      };

      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const result = await printHandler(workerPayload);
        if (result.status === WorkerActionResultStatus.ERROR) {
          return {
            error: true,
            message: translate('printing.printFailed'),
          };
        }
      }
    },
    [
      session,
      workerInstance,
      getDevicesSynchronously,
      translate,
      showNotification,
    ],
  );

  const value = useMemo(
    () => ({
      printBill,
      reprintKitchenDocket,
      printMoneyMovementReceipt,
      printShiftReceipt,
      printTestTemplate,
      openCashDrawer,
    }),
    [
      printBill,
      printMoneyMovementReceipt,
      reprintKitchenDocket,
      printShiftReceipt,
      printTestTemplate,
      openCashDrawer,
    ],
  );

  return value;
}
