import {
  ShiftType,
  UpdateStoreInput,
  Store,
  DayOfWeek,
  OperatingHours,
  EmailType,
} from '@hitz-group/domain';
import { StyleFn } from '@hitz-group/domain';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useFela } from 'react-fela';
import { View, ScrollView } from 'react-native';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../components/FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../components/Button/Button';
import { useNotification } from '../../../../hooks/Notification';
// import TableRow from '../../../../components/TableComponent/TableRow';
// import { pascalCase } from 'change-case';
import DropDown from '../../../../components/FormInput/DropDown';
import { UPDATE_STORE_DETAILS } from '../../../../graphql/store';
import { parseApolloError, noopHandler } from '../../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { stripProperties } from '../../../../utils/stripObjectProps';
import { useNavigation, useRoute } from '@react-navigation/native';
import difference from 'lodash/difference';
import keyBy from 'lodash/keyBy';
// import { timeSlotOptions } from '../../../../utils/dateHelper';
import { useStores } from '../../../../hooks/app/useStores';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import { LoadingIndicator } from '../../../../components/Loading/LoadingIndicator';
import scale, { isWeb, isAndroid } from '.././../../../common/theme';
import { isNotEmpty, isValidEmail } from '../../../../utils/validator';
import MultipleSelect from '../../../../components/MultipleSelect/MultipleSelect';
import cloneDeep from 'lodash/cloneDeep';

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  marginBottom: theme.spacing.big / 2,
  marginTop: theme.spacing.small,
});

const storeNameInputContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: '100%',
  height: theme.input.height,
});

const checkBoxTitleContainer: StyleFn = ({ theme, marginLeft }) => ({
  width: 260,
  height: 38,
  marginTop: theme.spacing.medium,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: marginLeft ? marginLeft : 0,
});

const checkBoxTitleStyle: StyleFn = ({ theme, isActive }) => ({
  ...theme.font14Regular,
  textTransform: 'none',
  color: isActive ? theme.colors.charcoal : theme.colors.paragraph,
});

export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: 260,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  // alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? 150 : '25%',
  marginTop: scale.moderateScale(2),
});
export const deviceDropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? scale.moderateScale(180) : isAndroid ? '30%' : '70%',
  marginTop: scale.moderateScale(2),
});
const formContainerStyleSmall: StyleFn = () => ({
  width: '100%',
});

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing.medium,
  maxWidth: 545,
});

export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '100%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

export const formStyle: StyleFn = ({ theme }) => ({
  width: scale.backOfficeWidth400,
  alignSelf: 'center',
  paddingBottom: theme.spacing.big,
  alignItems: 'center',
});

export const width100row: StyleFn = () => ({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: 40,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});
export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const deleteButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginRight: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  alignSelf: 'auto',
});

const dangerTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
  letterSpacing: -0.5,
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 17,
  width: 17,
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 17,
  width: 17,
});

const touchableStyle: StyleFn = ({ theme }) => ({
  minHeight: 45,
  flexWrap: 'wrap',
  paddingTop: isWeb ? 0 : theme.spacing.small,
});

const selectContentStyle: StyleFn = () => ({
  height: 150,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: 545,
  height: theme.input.height,
  justifyContent: 'center',
  marginTop: theme.spacing.medium,
});

const multipleSelectTextStyle: StyleFn = () => ({
  paddingBottom: 8,
});

// const inventoryContentStyle: StyleFn = () => ({
//   flexDirection: 'column',
// });

// const inventoryTextStyle: StyleFn = ({ theme }) => ({
//   letterSpacing: -0.5,
//   fontSize: 14,
//   fontFamily: theme.font.medium,
//   color: theme.colors.card,
//   marginLeft: theme.spacing.medium * 2 + theme.spacing.small / 3,
// });

// const tableRowContainerStyle: StyleFn = () => ({
//   height: scale.moderateScale(35),
//   alignSelf: 'flex-start',
// });
// const ButtonContainer: StyleFn = () => ({
//   width: isWeb ? 700 : '100%',
//   flexDirection: 'row',
//   justifyContent: 'space-around',
//   alignSelf: 'flex-start',
// });

// interface OperatingHoursProps {
//   id?: string;
//   isActive: boolean;
//   day: string;
//   openingTime: string;
//   closingTime: string;
//   onChange: Function;
//   index: number;
// }

// const OperatingHoursRow: React.FC<OperatingHoursProps> = ({
//   closingTime,
//   day,
//   isActive,
//   openingTime,
//   onChange,
//   index,
// }: OperatingHoursProps) => {
//   const { css, theme } = useFela();
//   return (
//     <TableRow containerStyle={css(tableRowContainerStyle)}>
//       <View style={css(ButtonContainer)}>
//         <Button
//           title={day}
//           labelStyle={css(checkBoxTitleStyle({ theme, isActive }))}
//           onPress={onChange.bind(null, index, 'isActive', !isActive)}
//           fluid
//           iconPosition={'left'}
//           containerStyle={css(
//             checkBoxTitleContainerStyle({
//               theme,
//               noMargin: true,
//               marginLeft: -theme.spacing.small * 1.5,
//             }),
//           )}
//           iconContainerStyle={
//             isActive ? css(checkIconContainer) : css(unCheckContainer)
//           }
//           icon={isActive ? 'check' : 'null'}
//           iconProps={{
//             color: theme.colors.success,
//             size: 15,
//           }}
//         />
//         <DropDown
//           values={timeSlotOptions}
//           selectedValue={openingTime}
//           extraStyle={css(dropdownExtraStyle)}
//           extraViewStyle={css(dropdownViewStyle)}
//           style={css(timeSlotFormStyle({ theme, isActive }))}
//           onValueChange={isActive && onChange.bind(null, index, 'startTime')}
//         />

//         <DropDown
//           values={timeSlotOptions}
//           selectedValue={closingTime}
//           extraStyle={css(dropdownExtraStyle)}
//           extraViewStyle={css(dropdownViewStyle)}
//           style={css(timeSlotFormStyle({ theme, isActive }))}
//           onValueChange={isActive && onChange.bind(null, index, 'endTime')}
//         />
//       </View>
//     </TableRow>
//   );
// };

export const Details: React.FC = () => {
  const { DAILY_SALES_SUMMARY, LOW_INVENTORY_STOCK, PURCHASE_ORDER } =
    EmailType;
  const { css, theme } = useFela();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const route = useRoute();
  const { translate } = useTranslation();
  const [form, setForm] = useState({} as Store);
  const { showModal, closeModal } = useModal();
  const [emails, setEmails] = useState<{ [x: string]: string[] }>({
    [DAILY_SALES_SUMMARY]: [],
    [LOW_INVENTORY_STOCK]: [],
    [PURCHASE_ORDER]: [],
  });

  const emailPreferencesDictionary = useMemo(() => {
    return keyBy(form.emailPreferences || [], 'emailType');
  }, [form.emailPreferences]);

  const params = route.params as {
    storeId: string;
    venueId: string;
  };

  const { venueId, storeId } = params;

  const { stores, deleteStore, deletedStore, loading, error, getStores } =
    useStores({
      storeId,
      venueId,
    });

  const showErrorNotification = useCallback(
    (msg: string) =>
      showNotification({
        error: true,
        message: msg,
      }),
    [showNotification],
  );

  const showSuccessNotification = useCallback(
    (msg: string) =>
      showNotification({
        success: true,
        message: msg,
      }),
    [showNotification],
  );

  useEffect(() => {
    getStores();
  }, [getStores]);

  useEffect((): void => {
    if (error) {
      showErrorNotification(error);
    }
  }, [error, showErrorNotification]);

  const [updateStore, updatedStore] = useMutation(UPDATE_STORE_DETAILS, {
    onError: noopHandler,
    context: {
      headers: { venue: params.venueId, store: storeId },
    },
  });

  useEffect((): void => {
    if (updatedStore.error) {
      showErrorNotification(parseApolloError(updatedStore.error));
    }
  }, [updatedStore.error, showErrorNotification]);

  useEffect((): void => {
    if (updatedStore.data) {
      showSuccessNotification(
        translate('backOfficeSettings.successfullyUpdated'),
      );
    }
  }, [updatedStore.data, translate, showSuccessNotification]);

  useEffect(() => {
    if (stores && stores[storeId]) {
      const storeData = stores[storeId] as Store;
      // adding default time slots to form if not exist
      let { operatingHours } = cloneDeep(storeData);
      const { emailPreferences } = storeData;
      const emailsByType: { [x: string]: string[] } = {
        [DAILY_SALES_SUMMARY]: [],
        [LOW_INVENTORY_STOCK]: [],
        [PURCHASE_ORDER]: [],
      };

      if (emailPreferences) {
        emailPreferences.forEach(
          pref => (emailsByType[`${pref.emailType}`] = pref.emails || []),
        );
        setEmails(emailsByType);
      }

      if (!operatingHours) {
        operatingHours = {} as OperatingHours;
        operatingHours.name = 'operating-hours';
        operatingHours.timeBlocks = [];
      }
      const allDays = Object.keys(DayOfWeek);
      const daysOperatingHoursTracked = (operatingHours.timeBlocks || []).map(
        eachBlock => eachBlock.day,
      );
      difference(allDays, daysOperatingHoursTracked as string[]).map(
        unTrackedDay =>
          operatingHours.timeBlocks.push({
            day: unTrackedDay as DayOfWeek,
            isActive: false,
            timeSlots: [{ endTime: '00:00', startTime: '00:00' }],
          }),
      );
      setForm({
        ...storeData,
        operatingHours,
      });
    }
  }, [
    stores,
    storeId,
    DAILY_SALES_SUMMARY,
    LOW_INVENTORY_STOCK,
    PURCHASE_ORDER,
  ]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onChangeEmail = useCallback((prop: string, value: string[]): void => {
    setEmails(type => ({
      ...type,
      [prop]: value,
    }));

    setForm(form => {
      return {
        ...form,
        emailPreferences: form.emailPreferences.map(pref => {
          if (pref.emailType === prop) {
            return { ...pref, emails: value };
          }
          return pref;
        }),
      };
    });
  }, []);

  // const onPressInventoryOptions = useCallback((prop: string, value): void => {
  //   setForm(form => {
  //     return {
  //       ...form,
  //       inventoryOptions: {
  //         ...form.inventoryOptions,
  //         [prop]: value,
  //       },
  //     };
  //   });
  // }, []);

  const onChangeEmailPreference = useCallback(
    (emailType, isEnabled): void => {
      emailPreferencesDictionary[emailType] = {
        emailType,
        isEnabled,
      };
      setForm(form => {
        form.emailPreferences = Object.values(emailPreferencesDictionary);
        return {
          ...form,
        };
      });
    },
    [emailPreferencesDictionary],
  );

  // const onChangeOperatingHours = useCallback(
  //   (index: number, prop: string, value): void => {
  //     const OperatingHoursTemp = form;
  //     if (prop === 'isActive') {
  //       OperatingHoursTemp.operatingHours.timeBlocks[index] = {
  //         ...OperatingHoursTemp.operatingHours.timeBlocks[index],
  //         [prop]: value,
  //       };
  //     } else {
  //       OperatingHoursTemp.operatingHours.timeBlocks[index].timeSlots[0] = {
  //         ...OperatingHoursTemp.operatingHours.timeBlocks[index].timeSlots[0],
  //         [prop]: value,
  //       };
  //     }
  //     setForm({
  //       ...OperatingHoursTemp,
  //     });
  //   },
  //   [form],
  // );
  useEffect(() => {
    if (deletedStore) {
      showSuccessNotification(
        translate('backOfficeStores.storeDeletedSuccessfully'),
      );
      navigation.navigate('VenuesStores', {});
    }
  }, [deletedStore, translate, navigation, showSuccessNotification]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', {
          label: form.name,
        })}
        onConfirm={() => {
          closeModal();
          deleteStore(form.id);
        }}
      />,
    );
  }, [showModal, deleteStore, closeModal, translate, form]);

  const onPressSave = useCallback((): void => {
    if (!form.name || !isNotEmpty(form.name)) {
      showErrorNotification(
        translate('backOfficeSettings.storeNameIsRequired'),
      );
      return;
    }

    const updateStoreDetailsInput = stripProperties(
      {
        id: form.id,
        name: form.name,
        inventoryOptions: form.inventoryOptions,
        emailPreferences: form.emailPreferences,
        operatingHours: form.operatingHours,
        shiftType: form.shiftType,
      },
      '__typename',
    );

    updateStore({
      variables: {
        input: updateStoreDetailsInput as unknown as UpdateStoreInput,
      },
    });
  }, [form, updateStore, showErrorNotification, translate]);

  // const allowSellingWithNegativeInventory =
  //   form.inventoryOptions?.allowSellingWithNegativeInventory;

  const appendEmail = useCallback(
    (emailArr: string[], emailId: string): string[] => {
      if (emailArr.indexOf(emailId) === -1) {
        emailArr.push(emailId);
        return emailArr;
      }
      showErrorNotification(translate('storesSettings.emailAlreadyExists'));
      return emailArr;
    },
    [showErrorNotification, translate],
  );

  const getEmailType = useCallback(
    (emailType: string): EmailType => {
      switch (emailType) {
        case DAILY_SALES_SUMMARY:
          return DAILY_SALES_SUMMARY;
        case LOW_INVENTORY_STOCK:
          return LOW_INVENTORY_STOCK;
        case PURCHASE_ORDER:
          return LOW_INVENTORY_STOCK;

        default:
          return '' as EmailType;
      }
    },
    [DAILY_SALES_SUMMARY, LOW_INVENTORY_STOCK, PURCHASE_ORDER],
  );

  const onAddEmail = useCallback(
    (emailType: string, emailId: string): void => {
      if (!isValidEmail(emailId)) {
        showErrorNotification(translate('storesSettings.invalidEmailFormat'));
        return;
      }
      const _emailType = getEmailType(emailType);

      const emailArr = [...emails[_emailType]];
      const newEmailPreferences =
        emailPreferencesDictionary[emailType] && emailArr
          ? {
              ...emailPreferencesDictionary[emailType],
              emails: appendEmail(emailArr, emailId),
              __typename: 'EmailPreferences',
            }
          : {
              emailType: _emailType,
              isEnabled: false,
              emails: appendEmail(emailArr, emailId),
              __typename: 'EmailPreferences',
            };

      setEmails(prevEmails => {
        const newEmails = { ...prevEmails };
        newEmails[_emailType] = emailArr;
        return newEmails;
      });

      setForm(prevForm => {
        return {
          ...prevForm,
          emailPreferences: prevForm.emailPreferences
            ? prevForm.emailPreferences.map(ep => {
                if (ep.emailType === emailType) {
                  return newEmailPreferences;
                }
                return ep;
              })
            : [newEmailPreferences],
        };
      });
    },
    [
      appendEmail,
      emailPreferencesDictionary,
      emails,
      getEmailType,
      showErrorNotification,
      translate,
    ],
  );

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView testID="general-settings-screen" style={css(pageStyle)}>
        <View style={css(width100row)}>
          <BackOfficeSection
            title={translate('backOfficeSettings.storeDetails')}
            contentContainerStyle={css(formContainerStyleSmall)}
            containerStyle={css(backOfficeContainerStyle)}
          >
            <FormInput
              error={false}
              testID="outlet-name"
              placeholder={translate('form.storeName')}
              title={translate('form.storeName')}
              value={form.name || ''}
              alignTitle="left"
              containerStyle={css(storeNameInputContainerStyle)}
              onChangeText={onChange.bind(null, 'name')}
            />
          </BackOfficeSection>
          {/* <BackOfficeSection
            title={translate('backOfficeSettings.operatingHours')}
            contentContainerStyle={css(formContainerStyleSmall)}
            containerStyle={css(backOfficeContainerStyle)}
            titleBorderBottom
          >
            {form.operatingHours?.timeBlocks.map((item, index) => {
              return (
                <OperatingHoursRow
                  closingTime={item.timeSlots[0].endTime}
                  day={pascalCase(item.day)}
                  isActive={item.isActive}
                  openingTime={item.timeSlots[0].startTime}
                  key={index}
                  index={index}
                  onChange={onChangeOperatingHours}
                />
              );
            })}
          </BackOfficeSection> */}
          <BackOfficeSection
            title={translate('backOfficeSettings.shiftType')}
            containerStyle={css(backOfficeContainerStyle)}
          >
            <DropDown
              values={[
                {
                  value: ShiftType.EMPLOYEE,
                  label: translate('storesSettings.byEmployee'),
                },
                {
                  value: ShiftType.DEVICE,
                  label: translate('storesSettings.byDevice'),
                },
                {
                  value: ShiftType.STORE,
                  label: translate('storesSettings.byStore'),
                },
              ]}
              selectedValue={form.shiftType}
              testID="shift-type"
              extraStyle={css(dropdownExtraStyle)}
              extraMainViewStyle={css(dropDownMainViewStyle)}
              extraViewStyle={css(deviceDropdownViewStyle)}
              style={css(formInputContainerStyle)}
              onValueChange={onChange.bind(null, 'shiftType')}
            />
          </BackOfficeSection>
          {/* <BackOfficeSection
            title={translate('backOfficeSettings.inventoryPreferences')}
            contentContainerStyle={css(inventoryContentStyle)}
            containerStyle={css(backOfficeContainerStyle)}
            titleBorderBottom
          >
            <Button
              title={translate('backOfficeSettings.negativeInventory')}
              labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              onPress={onPressInventoryOptions.bind(
                null,
                'allowSellingWithNegativeInventory',
                !allowSellingWithNegativeInventory,
              )}
              fluid
              iconPosition={'left'}
              containerStyle={css(
                checkBoxTitleContainer({ theme, noMargin: true }),
              )}
              iconContainerStyle={
                allowSellingWithNegativeInventory
                  ? css(checkIconContainer)
                  : css(unCheckContainer)
              }
              icon={allowSellingWithNegativeInventory ? 'check' : 'null'}
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            />

            <View>
              <Text style={css(inventoryTextStyle)}>
                {translate('backOfficeSettings.inventoryInfo')}
              </Text>
            </View>
          </BackOfficeSection> */}

          <BackOfficeSection
            title={translate('backOfficeSettings.emailPreferences')}
            contentContainerStyle={css(formContainerStyleSmall)}
            containerStyle={css(backOfficeContainerStyle)}
          >
            <Button
              title={translate('storesSettings.dailySalesSummary')}
              labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              fluid
              iconPosition={'left'}
              onPress={(): void =>
                onChangeEmailPreference(
                  DAILY_SALES_SUMMARY,
                  !emailPreferencesDictionary[DAILY_SALES_SUMMARY]?.isEnabled,
                )
              }
              containerStyle={css(checkBoxTitleContainer({ theme }))}
              iconContainerStyle={
                emailPreferencesDictionary['DAILY_SALES_SUMMARY']?.isEnabled
                  ? css(checkIconContainer)
                  : css(unCheckContainer)
              }
              icon={
                emailPreferencesDictionary['DAILY_SALES_SUMMARY']?.isEnabled
                  ? 'check'
                  : 'null'
              }
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            />

            <MultipleSelect
              label={translate('storesSettings.addEmail')}
              placeholder={translate('storesSettings.addEmail') + '...'}
              values={emails[DAILY_SALES_SUMMARY].map(email => ({
                value: email,
                label: email,
              }))}
              selectedValues={[...emails[DAILY_SALES_SUMMARY]]}
              containerStyle={css(dropDownStyle)}
              touchableStyle={css(touchableStyle)}
              onValueChange={onChangeEmail.bind(null, DAILY_SALES_SUMMARY)}
              searchLabel={translate('storesSettings.searchLabel') + ':'}
              searchPlaceHolder={
                translate('storesSettings.searchLabel') + '...'
              }
              onAddItem={email => onAddEmail(DAILY_SALES_SUMMARY, email)}
              contentStyle={css(selectContentStyle)}
              isDisabled={
                !emailPreferencesDictionary[DAILY_SALES_SUMMARY]?.isEnabled
              }
              // eslint-disable-next-line react-native/no-inline-styles
              textStyle={css(multipleSelectTextStyle)}
            />

            {/* <Button
              title={translate('storesSettings.purchaseOrder')}
              labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              fluid
              iconPosition={'left'}
              onPress={(): void =>
                onChangeEmailPreference(
                  'PURCHASE_ORDER',
                  !emailPreferencesDictionary['PURCHASE_ORDER']?.isEnabled,
                )
              }
              containerStyle={css(
                checkBoxTitleContainer({ theme, noMargin: true }),
              )}
              iconContainerStyle={
                emailPreferencesDictionary['PURCHASE_ORDER']?.isEnabled
                  ? css(checkIconContainer)
                  : css(unCheckContainer)
              }
              icon={
                emailPreferencesDictionary['PURCHASE_ORDER']?.isEnabled
                  ? 'check'
                  : 'null'
              }
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            />

            <Button
              title={translate('storesSettings.lowInventoryStock')}
              labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              fluid
              iconPosition={'left'}
              onPress={(): void =>
                onChangeEmailPreference(
                  'LOW_INVENTORY_STOCK',
                  !emailPreferencesDictionary['LOW_INVENTORY_STOCK']?.isEnabled,
                )
              }
              containerStyle={css(checkBoxTitleContainer({ theme }))}
              iconContainerStyle={
                emailPreferencesDictionary['LOW_INVENTORY_STOCK']?.isEnabled
                  ? css(checkIconContainer)
                  : css(unCheckContainer)
              }
              icon={
                emailPreferencesDictionary['LOW_INVENTORY_STOCK']?.isEnabled
                  ? 'check'
                  : 'null'
              }
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            /> */}
          </BackOfficeSection>
        </View>
        <View style={css(bottomSpace)}></View>
      </ScrollView>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="delete-changes"
            title={translate('button.deleteStore')}
            containerStyle={css(deleteButtonStyle)}
            labelStyle={css(dangerTitleStyle)}
            onPress={onPressDelete}
          />
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
