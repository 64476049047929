/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, Image, ScrollView } from 'react-native';
import Button from '../../../components/Button/Button';
import { useNavigation } from '@react-navigation/native';
import { useFela } from 'react-fela';
import { Store, StyleFn } from '@hitz-group/domain';
import { CreateDeviceInput, DeviceProfile, Venue } from '@hitz-group/domain';
import IconButton from '../../../components/Button/IconButton';
import { useSession } from '../../../hooks/app/useSession';
import { useTranslation } from '@hitz-group/localization';
import { useDeviceAssignment } from '../../../hooks/app/useDeviceAssignment';
import { useNotification } from '../../../hooks/Notification';
import Layout from '../../../components/POSLayout/POSLayout';
import { useDeviceInfo } from '../../../hooks/app/useDeviceInfo';
import { stripProperties } from '../../../utils/stripObjectProps';
import { useLogout } from '../../../hooks/app/useLogout';
import { useDeviceProfiles } from '../../../hooks/app/useDeviceProfiles';

type SetStep = 'Venue' | 'Store' | 'Device Profile';

const screen: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
});

const headerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.spacing.small * 2.5,
});

const body: StyleFn = () => ({
  alignItems: 'stretch',
});

const logoStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 100,
  resizeMode: 'contain',
  alignSelf: 'center',
  backgroundColor: theme.colors.white,
  marginTop: 30,
  marginBottom: 50,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.highlighted,
  marginHorizontal: 0,
  marginRight: theme.spacing.small * 1.5,
  marginBottom: theme.spacing.medium,
});

const labelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  fontSize: theme.fontSize.small,
});

const buttonContainerStyle: StyleFn = () => ({
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignSelf: 'center',
});

const textBlackStyleContainer: StyleFn = () => ({
  width: '100%',
  height: 54,
  marginTop: 20,
  marginBottom: 20,
  flexDirection: 'column',
  textAlign: 'center',
  alignSelf: 'center',
});

const textBlackStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 27,
  fontFamily: 'Inter',
  fontSize: theme.fontSize.large,
  fontWeight: '500',
  fontStyle: 'normal',
  lineHeight: 27,
  color: theme.colors.primary,
  textAlign: 'center',
});

const textGrayStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 24,
  fontFamily: 'Inter',
  fontSize: theme.fontSize.medium,
  fontWeight: '500',
  fontStyle: 'normal',
  lineHeight: 27,
  color: '#808080',
  textAlign: 'center',
});

const continueBtnStyle: StyleFn = ({ theme }) => ({
  width: 400,
  height: 44,
  backgroundColor: theme.colors.brandPrimary,
  alignSelf: 'center',
  marginTop: 20,
  marginBottom: 50,
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: theme.radius.small,
});

const continueBtnTextStyle: StyleFn = ({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: theme.fontSize.small,
  fontWeight: '600',
  fontStyle: 'normal',
  lineHeight: 44,
  color: theme.colors.white,
  textAlign: 'center',
  overflow: 'visible',
});
const btnBackContainer: StyleFn = ({ theme }) => ({
  height: 35,
  width: 35,
  backgroundColor: theme.colors.black,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
});
const btnContainer: StyleFn = ({}) => ({
  margin: 5,
  minWidth: 125,
});

const logoutButtonStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  width: 260,
  height: theme.button.footerButtonHeight,
  marginTop: theme.padding.small,
  backgroundColor: theme.colors.warningBg,
  alignSelf: 'center',
});

const logoutTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
});

const AssignToDeviceProfile: React.FC = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { css, theme } = useFela();
  const [session, setSession] = useSession();
  const { showNotification } = useNotification();
  const [step, setStep] = useState<SetStep>('Venue');
  const [registerId, setRegisterId] = useState(0);
  const [displayOptions, setDisplayOptions] = useState<
    Venue[] | Store[] | DeviceProfile[]
  >([]);
  const [isBackPressed, setIsBackPressed] = useState<boolean>(false);
  const { logout } = useLogout();

  const {
    loading: deviceProfleLoading,
    deviceProfiles,
    getAllDeviceProfiles,
    error: deviceProfileError,
  } = useDeviceProfiles({ storeId: '', venueId: '' });

  const onPressLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  const navigateToBackOffice = useCallback(() => {
    setSession({
      ...session,
      currentVenue: undefined,
      currentStore: undefined,
    });
    navigation.reset({ index: 0, routes: [{ name: 'BackOffice' }] });
  }, [navigation, session, setSession]);
  const onPressBack = useCallback(() => {
    setIsBackPressed(true);
    if (step === 'Venue') {
      navigateToBackOffice();
    } else if (step === 'Store') {
      setStep('Venue');
      setSession({
        ...session,
        currentVenue: undefined,
      });
    } else {
      setStep('Store');
      setSession({
        ...session,
        currentStore: undefined,
      });
    }
  }, [step, navigateToBackOffice, session, setSession]);
  const {
    assignDevice,
    loading: deviceAssignmentLoading,
    error: deviceAssignmentError,
  } = useDeviceAssignment();
  const deviceInfo = useDeviceInfo();

  const loading = deviceProfleLoading || deviceAssignmentLoading;

  const error = deviceProfileError || deviceAssignmentError;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (session.currentVenue) {
      if (session.currentStore) {
        setStep('Device Profile');
      } else {
        setStep('Store');
      }
    }
  }, [session.currentVenue, session.currentStore, translate]);

  const onAssignDeviceProfile = useCallback(
    async (index: number) => {
      const deviceProfile = session?.currentStore?.deviceProfiles?.[index];
      deviceInfo.name &&
        assignDevice({
          ...stripProperties(deviceInfo, 'versionNum'),
          deviceProfile: deviceProfile?.id || '',
          store: session.currentStore?.id || '',
        } as CreateDeviceInput);
    },
    [assignDevice, session, deviceInfo],
  );

  const onSelectOption = useCallback(
    (index: number) => {
      setIsBackPressed(false);
      switch (step) {
        case translate('assignRegister.venue'):
          const currentVenue = session?.user?.venues?.[
            index
          ] as unknown as Venue;
          setSession({
            ...session,
            currentVenue,
            currentStore: undefined,
          });
          setStep('Store');
          break;
        case translate('assignRegister.store'):
          const currentStore = session?.currentVenue?.stores?.[
            index
          ] as unknown as Venue;
          setSession({
            ...session,
            currentStore,
          });
          setStep('Device Profile');
          break;
        case translate('assignRegister.register'):
          onAssignDeviceProfile(index);
          setRegisterId(index);
          break;
      }
    },
    [setSession, session, step, translate, onAssignDeviceProfile],
  );

  const onDeviceProfileStep = useCallback(() => {
    const deviceProfs = session.currentStore?.deviceProfiles || [];
    setDisplayOptions(deviceProfs);
    const defaultRegisterIndex = deviceProfs.findIndex(r => r.isDefault);
    if (deviceProfs.length === 1) {
      onSelectOption(0);
    } else if (
      defaultRegisterIndex !== undefined &&
      defaultRegisterIndex !== -1
    ) {
      onSelectOption(defaultRegisterIndex);
    }
  }, [session.currentStore, onSelectOption]);

  useEffect(() => {
    const existingDeviceProfiles = session.currentStore?.deviceProfiles || [];
    if (
      Object.values(deviceProfiles).length > 0 &&
      existingDeviceProfiles.length == 0
    ) {
      setSession({
        ...session,
        currentStore: {
          ...session.currentStore,
          deviceProfiles: Object.values(deviceProfiles),
        },
      });
      onDeviceProfileStep();
    }
  }, [deviceProfiles, onDeviceProfileStep, session, setSession]);

  useEffect(() => {
    switch (step) {
      case translate('assignRegister.venue'):
        setDisplayOptions((session.user?.venues || []) as unknown as Venue[]);
        if (!isBackPressed && session.user?.venues?.length === 1)
          onSelectOption(0);
        break;
      case translate('assignRegister.store'):
        setDisplayOptions(
          (session.currentVenue?.stores || []) as unknown as Store[],
        );
        if (!isBackPressed && session.currentVenue?.stores?.length === 1)
          onSelectOption(0);
        break;
      case translate('assignRegister.register'):
        const deviceProfilesFromSession =
          session.currentStore?.deviceProfiles || [];
        if (deviceProfilesFromSession.length == 0) {
          getAllDeviceProfiles({
            context: {
              headers: {
                store: session.currentStore?.id,
                venue: session.currentVenue?.id,
              },
            },
          });
        } else {
          onDeviceProfileStep();
        }
        break;
    }
  }, [
    step,
    session,
    translate,
    onSelectOption,
    isBackPressed,
    onDeviceProfileStep,
    getAllDeviceProfiles,
    showNotification,
  ]);

  const firstVenue = session?.user?.venues && session.user?.venues[0];
  const firstStore =
    session?.user?.venues &&
    session.user?.venues[0]?.stores &&
    session.user?.venues[0]?.stores[0];

  const onPressContinue = useCallback(() => {
    setSession({
      ...session,
      currentVenue: firstVenue,
      currentStore: firstStore,
    });
  }, [firstStore, session, firstVenue, setSession]);

  return (
    <Layout
      title={translate('navigation.assignRegister')}
      headerLeft={
        <View style={css(btnBackContainer)}>
          <IconButton
            icon="arrow-left"
            iconColor={theme.colors.white}
            onPress={onPressBack}
            iconSize={30}
          />
        </View>
      }
      contentStyle={css(screen)}
      headerStyle={css(headerStyle)}
    >
      <View style={css(body)}>
        <Image
          style={css(logoStyle)}
          source={{
            uri: session.currentOrganization?.logoUrl,
          }}
        />
        {firstVenue && firstStore ? (
          <>
            <View style={css(textBlackStyleContainer)}>
              {step === 'Venue' && (
                <>
                  <Text style={css(textBlackStyle)}>
                    {translate('assignRegister.venueText1', {
                      venue: firstVenue?.name,
                      store: firstStore?.name,
                    })}
                  </Text>
                  <Text style={css(textBlackStyle)}>
                    {translate('assignRegister.venueText2')}
                  </Text>
                </>
              )}
              {step === 'Store' && (
                <>
                  <Text style={css(textBlackStyle)}>
                    {translate('assignRegister.storeText', {
                      venue: session?.currentVenue?.name,
                    })}
                  </Text>
                </>
              )}
              {step === 'Device Profile' && (
                <>
                  <Text style={css(textBlackStyle)}>
                    {translate('assignRegister.registerText')}
                  </Text>
                </>
              )}
            </View>
            {step === 'Venue' && (
              <>
                <IconButton
                  containerStyle={css(continueBtnStyle({ theme }))}
                  textStyle={css(continueBtnTextStyle({ theme }))}
                  text={translate('assignRegister.continue').toUpperCase()}
                  icon="arrow-right"
                  iconColor={theme.colors.white}
                  onPress={onPressContinue}
                  testID="continueBtn"
                />

                <View style={css(textBlackStyleContainer)}>
                  <Text style={css(textGrayStyle)}>
                    {translate('assignRegister.venueText3')}
                  </Text>
                </View>
              </>
            )}
          </>
        ) : (
          <>
            <Text style={css(textGrayStyle)}>
              {translate('appAccess.noStoresAssigned')}
            </Text>
            <Button
              testID="logout-btn"
              size="small"
              fluid
              onPress={onPressLogout}
              color={theme.colors.primary}
              containerStyle={css(logoutButtonStyle)}
              title={translate('button.logout')}
              labelStyle={css(logoutTextStyle)}
            />
          </>
        )}
        <ScrollView contentContainerStyle={css(buttonContainerStyle)}>
          {(displayOptions as any).map(
            (x: Venue | Store | DeviceProfile, index: number) => {
              return (
                <View style={css(btnContainer)} key={index}>
                  <Button
                    fluid
                    key={x.name}
                    containerStyle={css(
                      buttonStyle({
                        theme,
                      }),
                    )}
                    size="large"
                    title={x.name}
                    disabled={loading}
                    loading={index === registerId && loading}
                    labelStyle={css(
                      labelStyle({
                        theme,
                      }),
                    )}
                    onPress={onSelectOption.bind(null, index)}
                    numberOfLines={1}
                  />
                </View>
              );
            },
          )}
        </ScrollView>
      </View>
    </Layout>
  );
};

export default AssignToDeviceProfile;
