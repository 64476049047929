import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import { NO_DATA, StyleFn } from '@hitz-group/domain';
import Title from '../Title/Title';
import IconButton from '../Button/IconButton';
import { useFela } from 'react-fela';
import Label from '../Label/Label';
import format from 'date-fns/format';
import { Shift, ShiftStatus } from '@hitz-group/domain';
import { useCurrency } from '@hitz-group/localization';

interface ClosedShiftRow {
  item: Shift;
  onPress?: (id: string) => void;
  onPressPrint: (shift?: Shift) => void;
}

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.padding.medium * 1.5,
  height: 55,
  alignItems: 'center',
  marginBottom: theme.spacing.small / 2,
  borderRadius: theme.radius.small,
});
const statusStyle: StyleFn = () => ({
  minWidth: 80,
  marginRight: 10,
});
const tableNumStyle: StyleFn = () => ({
  minWidth: 50,
  marginRight: 13,
});
const dateStyle: StyleFn = () => ({
  width: 180,
  alignItems: 'flex-start',
  marginRight: 10,
});
const userStyle: StyleFn = () => ({
  flex: 1,
  alignItems: 'flex-start',
  marginRight: 3,
});
const registerStyle: StyleFn = () => ({
  width: 108,
  alignItems: 'flex-start',
  marginRight: 20,
});
const diffStyle: StyleFn = () => ({
  width: 82,
  alignItems: 'center',
  marginRight: 24,
});
const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  textTransform: 'capitalize',
  lineHeight: 24,
});
const iconContainer: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.transparent,
});
const labelText: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});

const ClosedShiftRow: React.FC<ClosedShiftRow> = ({
  item,
  onPressPrint,
  onPress,
}: ClosedShiftRow) => {
  const { css, theme } = useFela();
  const { formatCurrency } = useCurrency();

  const print = useCallback(() => {
    onPressPrint(item);
  }, [item, onPressPrint]);

  return (
    <TouchableOpacity
      testID={'shift-row'}
      style={css(rowStyle)}
      onPress={onPress?.bind(null, item.id)}
    >
      <Label
        testID={'shift-status'}
        containerStyle={css(statusStyle)}
        warning={item.shiftStatus === ShiftStatus.OPEN}
        success={item.shiftStatus === ShiftStatus.CLOSED}
        value={item.shiftStatus === ShiftStatus.OPEN ? 'ACTIVE' : 'CLOSED'}
        textStyle={css(labelText)}
      />
      <Label
        testID={'shift-number'}
        containerStyle={css(tableNumStyle)}
        value={item.shiftNumber}
        colorKey={6}
        textStyle={css(labelText)}
      />
      <Title
        testID={'shift-startDate'}
        containerStyle={css(dateStyle)}
        labelStyle={css(textStyle)}
        numberOfLines={1}
      >
        {format(+item.createdAt, 'dd/MM hh:mm a')}
      </Title>
      <Title
        testID={'shift-device'}
        containerStyle={css(registerStyle)}
        labelStyle={css(textStyle)}
        numberOfLines={1}
      >
        {item?.closedByDevice?.name || NO_DATA}
      </Title>
      <Title
        testID={'shift-closedByUsername'}
        containerStyle={css(userStyle)}
        labelStyle={css(textStyle)}
        numberOfLines={1}
      >
        {item.closedBy?.name || NO_DATA}
      </Title>
      <Title
        testID={'shift-closedAt'}
        containerStyle={css(userStyle)}
        labelStyle={css(textStyle)}
        numberOfLines={1}
      >
        {item.closedAt ? format(item.closedAt, 'dd/MM hh:mm a') : NO_DATA}
      </Title>
      <Title
        testID={'shift-difference'}
        containerStyle={css(diffStyle)}
        labelStyle={css(textStyle)}
        numberOfLines={1}
      >
        {formatCurrency(+item.difference?.toFixed(2))}
      </Title>
      <IconButton
        testID={'shift-print'}
        key="0"
        circular
        icon="print"
        color={'highlighted'}
        containerSize={35}
        iconColor={theme.colors.blue}
        onPress={print}
        iconSize={20}
        containerStyle={{ marginRight: theme.spacing.small }}
      />
      <IconButton
        testID={'shift-angleButton'}
        iconSize={20}
        icon={'AngleRight'}
        iconColor={theme.colors.card}
        disabled={true}
        containerStyle={css(iconContainer)}
      />
    </TouchableOpacity>
  );
};

export default ClosedShiftRow;
