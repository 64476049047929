import React from 'react';
import { View } from 'react-native';
import { Order } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import CartSummary from '../../../../../src/components/CartSummary/CartSummary';
import SummaryItem from '../../../../../src/components/CartSummary/CartSummaryItem';
import * as styles from './ModalOrderSummary.style';

interface ModalOrderSummaryProps {
  order?: Order;
}

const ModalOrderSummary: React.FC<ModalOrderSummaryProps> = ({ order }) => {
  const { css } = useFela();
  const totalPaid = (order?.totalPrice || 0) + (order?.roundingAmount || 0);

  return (
    <>
      <View style={css(styles.cartSummaryStyle)}>
        <CartSummary
          taxes={order?.taxes}
          discounts={order?.discounts || []}
          subTotal={order?.subTotal || 0}
          totalDue={totalPaid}
          discountAmount={order?.discountAmount || 0}
          isCalledFromRefund={true}
          summaryItemStyle={css(styles.summaryItemStyle)}
          customTotalValueTextStyle={styles.totalValueTextStyle}
          containerStyle={css(styles.summaryContainerStyle)}
        />
      </View>

      <View
        testID={'horizontal-line'}
        style={css(styles.horizontalLine)}
      ></View>

      <View style={css(styles.summaryItemContainer)}>
        {order && order.payments && (
          <SummaryItem
            label={order.payments
              .map(payType => payType.paymentType.name)
              .join(', ')}
            value={totalPaid}
            highlighted={true}
            testID={'payment-summary-item'}
          />
        )}
      </View>
    </>
  );
};

export default ModalOrderSummary;
