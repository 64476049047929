import { ProductModifierGroup, StyleFn } from '@hitz-group/domain';
import React, { useState } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../../components/Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';
import { View, Text } from 'react-native';
import { ProductModifiers } from '../../ProductSettings/Modifiers/ProductModifiers';
import PopupView from '../../../../../components/PopupView/PopupView';
interface AssignModifiersProps {
  onSubmit: (productModifiers: ProductModifierGroup[]) => void;
}

const createModalContainerStyle: StyleFn = () => ({
  width: 540,
  height: 500,
  flexDirection: 'column',
  alignSelf: 'center',
  alignItems: 'flex-start',
});

const dismissButtonStyle: StyleFn = ({ theme }) => ({
  width: 110,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.white,
  marginTop: theme.spacing.big,
});

const dismissLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
});

const buttonsWrapper: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.spacing.big,
});

const removeButton: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1,
});

const headerTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.medium,
  flex: 1,
  lineHeight: 24,
  letterSpacing: -0.5,
});

const headerStyle: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 15,
});

const updateButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginTop: theme.spacing.big,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.blue,
});

const removeButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginTop: theme.spacing.big,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const removeLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const AssignModifiers: React.FC<AssignModifiersProps> = ({
  onSubmit,
}: AssignModifiersProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [productModifiers, setProductModifiers] = useState(
    [] as ProductModifierGroup[],
  );
  const onSave = () => {
    onSubmit(productModifiers);
    setTimeout(() => {
      closeModal();
    }, 200);
  };

  const onRemoveAll = () => {
    setProductModifiers([]);
  };

  const updateProductModifiers = (value: ProductModifierGroup[]) => {
    setProductModifiers(value);
  };

  return (
    <PopupView containerStyle={css(createModalContainerStyle)}>
      <View style={css(headerStyle)}>
        <Text style={css(headerTextStyle)}>
          {translate('productBulkOperations.assignModifiers')}
        </Text>
      </View>
      <ProductModifiers
        productModifiers={productModifiers}
        setProductModifiers={updateProductModifiers}
      />
      <View style={css(buttonsWrapper)}>
        <View style={css(removeButton)}>
          <Button
            testID="removeAll-button"
            title={translate('button.removeAll')}
            containerStyle={css(removeButtonStyle)}
            labelStyle={css(removeLabelStyle)}
            onPress={onRemoveAll}
          />
          <Button
            testID="dismiss-button"
            title={translate('productBulkOperations.dismiss')}
            containerStyle={css(dismissButtonStyle)}
            labelStyle={css(dismissLabelStyle)}
            onPress={closeModal}
          />
        </View>
        <Button
          fluid
          testID="save-changes"
          title={translate('button.update')}
          containerStyle={css(updateButtonStyle)}
          labelStyle={css(titleStyle)}
          onPress={onSave}
        />
      </View>
    </PopupView>
  );
};
