import {
  useLazyQuery,
  useMutation,
  useApolloClient,
} from '@apollo/client/react/hooks';
import { useMemo, useCallback, useEffect, useState } from 'react';
import {
  CONVERT_PRODUCT_TO_VARIANT_MUTATION,
  GET_VARIANTS_QUERY,
  DELETE_VARIANT_MUTATION,
  DELETE_VARIANTS_MUTATION,
  updateVariantMutation,
  getVariantsQuery,
  getVariantQuery,
  updateVariantsMutation,
  createVariantMutation,
  copyVariantMutation,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import {
  UpdateVariantInput,
  Variant,
  ConvertProductToVariantInput,
  CreateVariantProductInput,
  CopyVariantInput,
} from '@hitz-group/domain';
import { Operation } from '../../../types/Operation';
import { ApolloError } from '@apollo/client';

export interface useVariantsProps {
  variants: { [key: string]: Variant };
  updateVariant: (variantDetails: UpdateVariantInput) => void;
  updateVariants: (variantDetails: UpdateVariantInput[]) => void;
  getAllVariants: () => void;
  refetchAllVariants: () => void;
  convertProductToVariant: (
    productDetails: ConvertProductToVariantInput,
  ) => void;
  createVariant: (variantInput: CreateVariantProductInput) => void;
  copyVariant: (copyVariantInput: CopyVariantInput) => void;
  deleteVariant: (id: string) => void;
  deleteVariants: (ids: string[]) => void;
  getVariantData: (id: string) => void;
  createdVariantId: string;
  deletedVariantId: string;
  loading: boolean;
  error: string | undefined;
  operation: Operation;
}

export function useVariants(
  variantId?: string,
  customFragment?: string,
): useVariantsProps {
  const [variants, setVariants] = useState<Record<string, Variant>>({});
  const [operation, setOperation] = useState<Operation>(Operation.READ);
  const [createdVariantId, setCreatedVariantId] = useState<string>('');
  const [deletedVariantId, setDeletedVariantId] = useState<string>('');
  const client = useApolloClient();

  const [getVariant, variantGetRequest] = useLazyQuery(
    getVariantQuery(customFragment),
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getAllVariants, getAllVariantsRequest] = useLazyQuery(
    getVariantsQuery(customFragment),
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [updateVariantReq, updateVariantRes] = useMutation(
    updateVariantMutation(customFragment),
    {
      onError: noopHandler,
    },
  );

  const [updateVariantsReq, updateVariantsRes] = useMutation(
    updateVariantsMutation(customFragment),
    {
      onError: noopHandler,
    },
  );

  const [createVariant, createRequest] = useMutation(
    createVariantMutation(customFragment),
    {
      onError: noopHandler,
    },
  );
  const [copyVariantReq, copyVariantRes] = useMutation(
    copyVariantMutation(customFragment),
    {
      onError: noopHandler,
    },
  );

  const [deleteVariant, deleteVariantRequest] = useMutation(
    DELETE_VARIANT_MUTATION,
    {
      onError: noopHandler,
    },
  );

  const [deleteVariants, deleteVariantsResponse] = useMutation(
    DELETE_VARIANTS_MUTATION,
    {
      onError: noopHandler,
    },
  );

  const [convertProductToVariant, convertRequest] = useMutation(
    CONVERT_PRODUCT_TO_VARIANT_MUTATION,
    {
      onError: noopHandler,
    },
  );

  //cache issue while fetching all variants
  // useEffect(() => {
  //   if (getAllVariantsRequest.data) {
  //     getAllVariantsRequest?.client?.writeQuery({
  //       query: GET_VARIANTS_QUERY,
  //       data: {
  //         variants: getAllVariantsRequest.data,
  //       }
  //     });
  //   }
  // }, [getAllVariantsRequest]);

  // Get variant by id
  useEffect(() => {
    if (variantGetRequest.data) {
      const variantData = variantGetRequest.data.variant as Variant;
      setVariants(variants => ({
        ...variants,
        [variantData.id]: variantData,
      }));
    }
  }, [variantGetRequest.data]);

  useEffect(() => {
    if (variantId) {
      getVariant({ variables: { id: variantId } });
      setOperation(Operation.READ);
    }
  }, [getVariant, variantId]);

  const getVariantData = useCallback(
    id => {
      getVariant({ variables: { id } });
      setOperation(Operation.READ);
    },
    [getVariant],
  );

  // Get variant by id end

  // GET ALL VARIANTS
  const getAllVariantsData = useCallback(() => {
    getAllVariants();
    setOperation(Operation.READ);
  }, [getAllVariants]);

  useEffect(() => {
    if (getAllVariantsRequest.data) {
      const variantsData = getAllVariantsRequest.data.variants as Variant[];
      setVariants(() => {
        const variantsTemp = {} as Record<string, Variant>;
        variantsData.forEach(variant => {
          variantsTemp[variant.id] = variant;
        });
        return variantsTemp;
      });
    }
  }, [getAllVariantsRequest.data]);

  // update variant
  useEffect(() => {
    if (updateVariantRes.data) {
      const variantData = updateVariantRes.data.updateVariant as Variant;
      setVariants(variants => ({ ...variants, [variantData.id]: variantData }));
    }
  }, [updateVariantRes.data]);

  // update bulk variants
  useEffect(() => {
    if (updateVariantsRes.data) {
      setVariants(prevVariants => {
        const tempVars = { ...prevVariants };
        const variantsData = updateVariantsRes.data.updateVariants as Variant[];
        variantsData.forEach(eachVar => {
          tempVars[eachVar.id] = eachVar;
        });
        return tempVars;
      });
    }
  }, [updateVariantsRes.data]);

  const updateVariant = useCallback(
    (variantInput: UpdateVariantInput) => {
      updateVariantReq({
        variables: {
          input: variantInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateVariantReq],
  );

  const updateVariants = useCallback(
    (variantInput: UpdateVariantInput[]) => {
      updateVariantsReq({
        variables: {
          input: variantInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateVariantsReq],
  );
  // update variant end

  // convert product to variant
  const convertProductToVariantData = useCallback(
    (input: ConvertProductToVariantInput) => {
      convertProductToVariant({
        variables: {
          input,
        },
      });
      setOperation(Operation.CREATE);
    },
    [convertProductToVariant],
  );
  // convert product to variant end

  useEffect(() => {
    if (createRequest.data) {
      const variantsData = createRequest.data.createVariant as Variant;
      setVariants(variants => ({
        ...variants,
        [variantsData.id]: variantsData,
      }));
      setCreatedVariantId(variantsData.id);
    }
  }, [createRequest.data]);

  useEffect(() => {
    if (copyVariantRes.data) {
      const variantsData = copyVariantRes.data.copyVariant as Variant;
      setVariants(variants => ({
        ...variants,
        [variantsData.id]: variantsData,
      }));
      setCreatedVariantId(variantsData.id);
    }
  }, [copyVariantRes.data]);

  const createVariantDetails = useCallback(
    (input: CreateVariantProductInput) => {
      createVariant({
        variables: {
          input,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createVariant],
  );

  const deleteVariantsFromCache = useCallback(
    (variants: Record<string, Variant>, deletedIds?: Map<string, string>) => {
      let updatedVariants = Object.values(variants);
      if (!updatedVariants.length) {
        const variants =
          (
            client.cache.readQuery({
              query: GET_VARIANTS_QUERY,
            }) as { variants: Variant[] }
          )?.variants || [];
        updatedVariants =
          (deletedIds &&
            deletedIds.size &&
            variants.filter(variant => !deletedIds.has(variant.id))) ||
          variants;
      }
      client.cache.writeQuery({
        query: GET_VARIANTS_QUERY,
        data: {
          variants: updatedVariants,
        },
      });
    },
    [client],
  );

  useEffect(() => {
    if (deleteVariantRequest.data && deletedVariantId) {
      const deletedId = new Map();
      deletedId.set(deletedVariantId, deletedVariantId);
      setOperation(Operation.DELETE);

      deletedVariantId &&
        setVariants(variants => {
          const temp = { ...variants };
          delete temp[deletedVariantId];
          deleteVariantsFromCache(temp, deletedId);
          return temp;
        });
      deletedVariantId && setDeletedVariantId('');
    }
  }, [deleteVariantRequest.data, deleteVariantsFromCache, deletedVariantId]);

  const deleteVariantDetails = useCallback(
    (variantId: string) => {
      deleteVariant({
        variables: {
          input: variantId,
        },
      });
      setDeletedVariantId(variantId);
    },
    [deleteVariant],
  );

  const deleteVariantsDetails = useCallback(
    (varIds: string[]) => {
      deleteVariants({
        variables: {
          input: varIds,
        },
      });
      setVariants(prev => {
        const temp = { ...prev };
        const deletedIds = new Map();
        varIds.forEach(eachVarId => {
          delete temp[eachVarId];
          deletedIds.set(eachVarId, eachVarId);
        });
        deleteVariantsFromCache(temp, deletedIds);
        return temp;
      });
      setOperation(Operation.DELETE);
    },
    [deleteVariants, deleteVariantsFromCache],
  );

  const copyVariant = useCallback(
    (input: CopyVariantInput) => {
      copyVariantReq({ variables: { input } });
      setOperation(Operation.CREATE);
      setCreatedVariantId('');
    },
    [copyVariantReq],
  );

  const refetchAllVariants = useCallback(() => {
    const refetchedData = getAllVariantsRequest.client?.readQuery({
      query: GET_VARIANTS_QUERY,
    });
    setVariants(prev => {
      const tempPrev = { ...prev };
      const refetchedVariants = refetchedData?.variants as Variant[];
      if (refetchedVariants?.length) {
        refetchedVariants.forEach(eachVar => {
          tempPrev[eachVar.id] = eachVar;
        });
      }
      return tempPrev;
    });
    setOperation(Operation.READ);
  }, [getAllVariantsRequest.client]);

  const error: ApolloError | undefined =
    updateVariantRes.error ||
    updateVariantsRes.error ||
    variantGetRequest.error ||
    convertRequest.error ||
    getAllVariantsRequest.error ||
    deleteVariantRequest.error ||
    createRequest.error ||
    deleteVariantsResponse.error ||
    copyVariantRes.error;

  const loading: boolean =
    variantGetRequest.loading ||
    updateVariantRes.loading ||
    updateVariantsRes.loading ||
    convertRequest.loading ||
    getAllVariantsRequest.loading ||
    deleteVariantRequest.loading ||
    createRequest.loading ||
    deleteVariantsResponse.loading ||
    copyVariantRes.loading;

  return useMemo(
    () => ({
      variants: variants,
      updateVariant,
      updateVariants,
      getAllVariants: getAllVariantsData,
      refetchAllVariants: refetchAllVariants,
      convertProductToVariant: convertProductToVariantData,
      createVariant: createVariantDetails,
      deleteVariant: deleteVariantDetails,
      deleteVariants: deleteVariantsDetails,
      getVariantData,
      createdVariantId,
      deletedVariantId,
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
      copyVariant,
    }),
    [
      variants,
      error,
      updateVariant,
      updateVariants,
      getAllVariantsData,
      createVariantDetails,
      deleteVariantDetails,
      deleteVariantsDetails,
      getVariantData,
      createdVariantId,
      deletedVariantId,
      operation,
      loading,
      convertProductToVariantData,
      refetchAllVariants,
      copyVariant,
    ],
  );
}
