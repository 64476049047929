import { useApolloClient, useMutation } from '@apollo/client/react/hooks';
import {
  InitiateOrderEvent,
  InitiateRefundEvent,
  IntegrationApps,
  Order,
  OrderAction,
  OrderEvent,
  OrderItem,
  OrderItemStatus,
  OrderStatus,
  Features,
} from '@hitz-group/domain';
import { computeOrderState } from '@hitz-group/order-helper';
import kitchenOrderEvents from '../../utils/printerTemplates/kotEvents';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  GET_ORDER,
  ORDER_SAVE,
  GET_ORDER_FRAGMENT,
} from '../../hooks/app/orders/graphql';
import { parseApolloError } from '../../utils/errorHandlers';
import {
  clearOrderEventsFromDb,
  generateOrderEvent,
  storeOrderEventsInDb,
} from '../../utils/orderEventHelper';
import { stripProperties } from '../../utils/stripObjectProps';
import { useSession } from '../app/useSession';
import { useOrderNumber, extractCounter } from './useOrderNumber';
import { useSettings } from '../app/useSettings';
import { ErrorPolicy } from '@apollo/client/core';
import isEqual from 'lodash/isEqual';
import { userUtility } from '../../state/userUtility';
import { useNetInfo } from '@react-native-community/netinfo';
import { isNil } from 'lodash';
import { currentOrderActionObservable } from '../app/orders/ordersObservableUtils';
import { useSalesChannels } from '../app/salesChannels/useSalesChannels';
import { SYNC_CDS_ORDER_EVENTS } from '../../graphql/syncEvents';
import { useCheckFeatureEnabled } from '../app/features/useCheckFeatureEnabled';

/**
 * Evaluates whether to send the events (pending / new) to server
 * or to keep them in local instance state
 * @param action
 */

const canSyncToServer = (action: OrderAction): boolean => {
  switch (action) {
    case OrderAction.ORDER_SAVE:
    case OrderAction.ORDER_VOID:
      return true;
    default:
      return false;
  }
};

/**
 * Filter removed items (cancelled) from Order.
 * @param orderItems OrderItem
 */
const filterCancelledItems = (orderItems: OrderItem[]): OrderItem[] => {
  return orderItems.filter(item => item.status !== OrderItemStatus.CANCELLED);
};

/**
 * Order management hook
 * Fetches order if orderId argument is given
 * Creates new order if orderId argument is not given
 * Provides updateCart method to update order with new actions
 *
 * Example:
 * ```
 * // create new order
 * const { order, updateCart } = useCart();
 *
 * const onPressAddItem = (item) => {
 *  updateCart(OrderAction.ORDER_ITEM_ADD_EVENT, item);
 * }
 *
 * // fetch order
 * const { order, updateCart, status } = useCart('xxx-xxx-xxx');
 *
 * ```
 * @param orderId
 */
export function useCart() {
  const [session] = useSession();
  const [error, setError] = useState<string>('');
  const params = useRef<{
    orderId?: string;
    orderTypeId?: string;
    tableId?: string;
  }>();

  const [, setPendingEvents] = useState<OrderEvent[]>([]);
  const [currentState, setCurrentState] = useState<Order | undefined>();
  const [originalState, setOriginalState] = useState<Order | undefined>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { generate: generateOrderNumber } = useOrderNumber();
  const onSaveCompleteHandler = useRef<Function>();
  const netInfo = useNetInfo();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isCdsEnabled = isFeatureEnabled(Features.CDS);

  const { inStoreSaleChannel, getSalesChannels } = useSalesChannels();

  const [saveOrder] = useMutation(ORDER_SAVE, {
    onCompleted: () => {
      if (params.current?.orderId) {
        currentOrderActionObservable.next({
          orderId: params.current?.orderId,
          lastOrderAction: OrderAction.ORDER_SAVE,
          timestamp: Date.now(),
          isSyncComplete: false,
        });
      }
      onSaveCompleteHandler.current && onSaveCompleteHandler.current();
      onSaveCompleteHandler.current = undefined;
    },
  });
  const [syncCdsEvent] = useMutation(SYNC_CDS_ORDER_EVENTS);
  const [counter, setCounter] = useSettings<number>('orderCounter');

  useEffect(() => {
    getSalesChannels();
  }, [getSalesChannels]);

  const client = useApolloClient();

  const initiateRefund = useCallback(
    (prevOrder: Order, reason: string) => {
      setPendingEvents(oldEvents => {
        if (oldEvents.length !== 0) {
          return oldEvents;
        }
        const action = OrderAction.ORDER_REFUND_INITIATE;
        const event = generateOrderEvent<InitiateRefundEvent>(
          action,
          {
            organizationId: session.currentOrganization?.id,
            venueId: session.currentVenue?.id,
            deviceId: session.device?.id,
            storeId: session.currentStore?.id,
            triggeredBy: userUtility.posUser?.id,
          },
          {
            refundOf: prevOrder.id,
            reason,
            refundOrderNumber: session.device
              ? session.device.returnPrefix +
                '-' +
                prevOrder.orderNumber.split('-').splice(2).join('-')
              : prevOrder.orderNumber,
          },
        );
        const order = computeOrderState([event], prevOrder);
        order.orderItems = filterCancelledItems(order.orderItems);
        isCdsEnabled &&
          syncCdsEvent({
            variables: {
              input: [...oldEvents, event],
            },
          });

        const updated = [event];
        setCurrentState(order);
        setOriginalState(order);
        return updated;
      });
    },
    [session, syncCdsEvent, isCdsEnabled],
  );

  const mergeCachedOrderWithEvents = useCallback(
    async (orderId: string, errorPolicy?: ErrorPolicy) => {
      try {
        const response = await client.query<{ order: Order }>({
          query: GET_ORDER,
          variables: { orderId },
          fetchPolicy: 'cache-first',
          returnPartialData: false,
          errorPolicy: errorPolicy ?? 'none',
        });

        if (response.error) {
          setError(parseApolloError(response.error));
        } else if (response.errors?.length) {
          setError(response.errors.map(error => error.message).join(', '));
        } else {
          setError('');
          const order = response.data?.order;
          if (order) {
            let pendingOrderEvents: OrderEvent[] = [];
            setPendingEvents(oldEvents => {
              if (
                [OrderStatus.COMPLETED, OrderStatus.VOID].includes(order.status)
              ) {
                return oldEvents.filter(event => event.orderId !== order.id);
              } else {
                const firstMatchedEvent = oldEvents.find(
                  event => event.orderId === order.id,
                );

                if (firstMatchedEvent)
                  firstMatchedEvent.previous = order.prevEventId;

                pendingOrderEvents = oldEvents.filter(
                  event => event.orderId === order.id,
                );

                return oldEvents;
              }
            });
            if (pendingOrderEvents.length > 0) {
              storeOrderEventsInDb(pendingOrderEvents);
              setCurrentState(computeOrderState(pendingOrderEvents, order));
            } else {
              setCurrentState(order);
            }
            setOriginalState(order);
            return order;
          }
        }
      } catch (error) {
        setError((error as Error)?.message);
      }
    },
    [client],
  );

  const getOrderData = useCallback(
    async (orderId: string, errorPolicy?: ErrorPolicy) => {
      try {
        let order =
          client.cache.readFragment<Order>({
            id: `Order:${orderId}`,
            fragment: GET_ORDER_FRAGMENT,
          }) ?? undefined;
        if (!order) {
          const response = await client.query<{ order: Order }>({
            query: GET_ORDER,
            variables: { orderId },
            fetchPolicy: !netInfo.isConnected ? 'cache-only' : 'cache-first',
            returnPartialData: false,
            errorPolicy: errorPolicy ?? 'none',
          });
          if (response.error) {
            setError(parseApolloError(response.error));
          } else if (response.errors?.length) {
            setError(response.errors.map(error => error.message).join(', '));
          } else {
            setError('');
            order = response.data?.order;
          }
        }
        if (order) {
          setCurrentState(order);
          setOriginalState(original =>
            original?.id !== order?.id ? order : original,
          );
          return order;
        }
      } catch (error) {
        setError((error as Error)?.message);
      }
      return undefined;
    },
    [client, netInfo.isConnected],
  );

  const resetCart = useCallback(async () => {
    if (!session) {
      return '';
    }
    const event = generateOrderEvent<InitiateOrderEvent>(
      OrderAction.ORDER_INITIATE,
      {
        organizationId: session.currentOrganization?.id,
        venueId: session.currentVenue?.id,
        deviceId: session.device?.id,
        storeId: session.currentStore?.id,
        triggeredBy: userUtility.posUser?.id,
      },
      {
        tableId: params.current?.tableId,
        orderTypeId: params.current?.orderTypeId,
        orderNumber: await generateOrderNumber(),
        salesChannelId: inStoreSaleChannel?.id,
      },
    );
    const order = computeOrderState([event]);

    setCurrentState(order);
    setOriginalState(order);
    setPendingEvents([event]);
    saveOrder({ variables: { data: order } });
    isCdsEnabled &&
      syncCdsEvent({
        variables: {
          input: [event],
        },
      });

    currentOrderActionObservable.next({
      orderId: order.id,
      lastOrderAction: OrderAction.ORDER_INITIATE,
      lastEventId: event.id,
      timestamp: Date.now(),
      isSyncComplete: false,
    });

    return order.id;
  }, [
    session,
    generateOrderNumber,
    inStoreSaleChannel?.id,
    saveOrder,
    syncCdsEvent,
    isCdsEnabled,
  ]);

  /**
   * Perform cart update actions
   * Events are stored until the next save point
   */

  const updateCart = useCallback(
    <T extends OrderEvent>(
      action: OrderAction,
      input?: Omit<T, keyof OrderEvent>,
    ) => {
      /**
       * Using setOrder this way helps avoid add order to dependency list
       * avoiding updateCart changing every time it is called since it modifies order
       */
      setCurrentState(prevOrder => {
        if (!prevOrder) {
          setError('Actions are not permitted when order is undefined');
          return;
        } else {
          setError('');
        }
        const eventInput = stripProperties({ ...input }, '__typename');
        const event = generateOrderEvent(
          action,
          {
            organizationId: session.currentOrganization?.id,
            venueId: session.currentVenue?.id,
            deviceId: session.device?.id,
            storeId: session.currentStore?.id,
            triggeredBy: userUtility.posUser?.id,
            ...(prevOrder.isOnline && {
              integrationApp: IntegrationApps.DOSHII,
            }),
          },
          {
            ...eventInput,
            orderId: prevOrder?.id,
            previous: prevOrder?.prevEventId,
          },
        );

        const order = computeOrderState([event], prevOrder);
        order.orderItems = filterCancelledItems(order.orderItems);

        /**
         * Accumulate events until save order action is performed
         * Using setPendingEvents this way helps avoid add pendingEvents to dependency list
         * avoiding updateCart changing every time it is called since it modifies pendingEvents
         */
        setPendingEvents(oldEvents => {
          isCdsEnabled &&
            syncCdsEvent({
              variables: {
                input: [...oldEvents, event],
              },
            });
          if (!event.previous && oldEvents.length == 1) {
            event.previous = oldEvents[0].id;
          }
          const updatedEvents = [...oldEvents, event];
          const isSaveAction = action === OrderAction.ORDER_SAVE;

          // When there are no changes made to order
          if (isSaveAction && oldEvents.length === 0) {
            setIsDirty(true);
            return [];
          } else if (canSyncToServer(action)) {
            setIsDirty(false);
            setOriginalState(order);
            saveOrder({ variables: { data: order } });
            if (extractCounter(order.orderNumber) > (counter || 0))
              setCounter(extractCounter(order.orderNumber));
            if (action !== OrderAction.ORDER_SAVE) {
              currentOrderActionObservable.next({
                orderId: order.id,
                lastOrderAction: action,
                lastEventId: event.id,
                timestamp: Date.now(),
                isSyncComplete: false,
              });
            }

            onSaveCompleteHandler.current = () => {
              kitchenOrderEvents.publishToKotUtil({
                orderId: order.id,
                preEvents: updatedEvents,
              });
            };

            return [];
          } else {
            setIsDirty(true);
          }
          return updatedEvents;
        });
        return order;
      });
    },
    [
      session.currentOrganization?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.currentStore?.id,
      syncCdsEvent,
      saveOrder,
      counter,
      setCounter,
      isCdsEnabled,
    ],
  );

  /**
   * Set current state back to original before performing cart updates
   * This method only discards actions up to the last save point
   */
  const discardChanges = useCallback(() => {
    // ORDER_INITIATE event shouldn't be discarded
    setPendingEvents(old => {
      const firstEvent = old[0];
      if (firstEvent?.orderId) clearOrderEventsFromDb(firstEvent.orderId);
      if (firstEvent?.action === OrderAction.ORDER_INITIATE) {
        return [firstEvent];
      }
      return [];
    });

    setCurrentState(originalState);
    setIsDirty(false);
  }, [originalState]);

  const setCartParams = useCallback(
    async (
      orderId?: string,
      orderTypeId?: string,
      tableId?: string,
      isExisting?: boolean,
    ): Promise<void> => {
      params.current = {
        orderId,
        orderTypeId: !isNil(orderTypeId)
          ? orderTypeId
          : params.current?.orderTypeId,
        tableId: !isNil(tableId) ? tableId : params.current?.tableId,
      };
      currentOrderActionObservable.next(undefined);
      // fetch order
      if (orderId && isExisting) {
        await getOrderData(orderId);
      }
    },
    [getOrderData],
  );

  const clearPriorPendingEvents = useCallback(() => {
    setPendingEvents([]);
  }, []);

  const closeOrderCart = useCallback(() => {
    const event = generateOrderEvent(
      OrderAction.ORDER_CLOSE,
      {
        organizationId: session.currentOrganization?.id,
        venueId: session.currentVenue?.id,
        deviceId: session.device?.id,
        storeId: session.currentStore?.id,
        triggeredBy: userUtility.posUser?.id,
      },
      {
        orderId: currentState?.id,
      },
    );
    setPendingEvents(preEvents => {
      isCdsEnabled &&
        syncCdsEvent({
          variables: {
            input: [...preEvents, event],
          },
        });
      return preEvents;
    });
  }, [
    session.currentOrganization?.id,
    session.currentVenue?.id,
    session.device?.id,
    session.currentStore?.id,
    currentState?.id,
    isCdsEnabled,
    syncCdsEvent,
  ]);

  const openOrderCart = useCallback(
    (orderId: string) => {
      const event = generateOrderEvent(
        OrderAction.ORDER_OPEN,
        {
          organizationId: session.currentOrganization?.id,
          venueId: session.currentVenue?.id,
          deviceId: session.device?.id,
          storeId: session.currentStore?.id,
          triggeredBy: userUtility.posUser?.id,
        },
        {
          orderId: orderId,
        },
      );
      isCdsEnabled &&
        syncCdsEvent({
          variables: {
            input: [event],
          },
        });
    },
    [
      session.currentOrganization?.id,
      session.currentVenue?.id,
      session.device?.id,
      session.currentStore?.id,
      isCdsEnabled,
      syncCdsEvent,
    ],
  );

  return {
    status: {
      error,
      // always be false as the queries above are synchronous
      loading: false,
    },
    updateCart,
    discardChanges,
    resetCart,
    clearPriorPendingEvents,
    order: currentState,
    itemsChanged: !isEqual(currentState?.orderItems, originalState?.orderItems),
    getOrderData,
    mergeCachedOrderWithEvents,
    setCartParams,
    isDirty,
    initiateRefund,
    closeOrderCart,
    openOrderCart,
  };
}
