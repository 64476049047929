/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IntegrationApps,
  IntegrationPartner,
  Store,
  UpdateIntegrationPartnerInput,
} from '@hitz-group/domain';
import { IMap } from '../screens/BackOffice/Reports/types';

import { stringToUrlPattern } from '@hitz-group/client-utils';

export interface OnlineStoreSettings extends IntegrationPartner {
  isNew?: boolean;
}

const setStorePreferences = (
  key: string,
  value: any,
  storeSettingsOld: OnlineStoreSettings,
) => {
  return {
    onlineOrdering: {
      ...storeSettingsOld.preferences?.onlineOrdering,
      [key]: value,
    },
  };
};

export const onChangeStoreSettings = (
  storeId: string,
  key: string,
  value: any,
  oldStoreSettings: OnlineStoreSettings,
  stores: IMap<Store>,
  app: IntegrationApps,
) => {
  const venueId = stores[storeId]?.venue?.id;
  let storeSettingsOld = { ...oldStoreSettings };

  if (!storeSettingsOld?.id) {
    // default settings
    // TODO: we are considering store id as integration id till on boarding process is completed
    storeSettingsOld = {
      id: storeId,
      appName: app,
      isActive: false,
      modules: {
        onlineOrdering: true,
      },
      store: storeId,
      preferences: {
        onlineOrdering: {
          menu: '',
          pricingGroup: '',
          storeSlug: stringToUrlPattern(stores[storeId]?.name || ''),
          merchantCode: '',
        },
      },
      link: '',
      isNew: true,
      venue: venueId,
    };
  }

  if (key === 'isActive') {
    storeSettingsOld = {
      ...storeSettingsOld,
      isActive: value,
      venue: venueId,
    };
  } else {
    storeSettingsOld.preferences = setStorePreferences(
      key,
      value,
      storeSettingsOld,
    );
  }

  return storeSettingsOld;
};

export const onChangeOnlineIntegrationStoreSettings = (
  key: string,
  value: any,
  oldStoreSettings: OnlineStoreSettings,
) => {
  const storeSettingsOld = { ...oldStoreSettings };
  storeSettingsOld.preferences = setStorePreferences(
    key,
    value,
    storeSettingsOld,
  );
  return storeSettingsOld;
};

export const onUpdateDeliverItSettings = (
  integrationSettingsArray: UpdateIntegrationPartnerInput[],
  stores: IMap<Store>,
) => {
  return integrationSettingsArray.map(x => ({
    ...x,
    ...(x?.store &&
      stores[x.store] && {
        venue: stores[x.store]?.venue?.id,
      }),
  }));
};
