import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Helmet } from 'react-helmet';
import { View, Text, ScrollView } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import { useFela } from 'react-fela';
import { styles } from './style';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { CreateCustomerRequest, Customer, Icons } from '@hitz-group/domain';
import IconButton from '../../../../components/Button/IconButton';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import BackOfficeCustomerModal from '../../../../components/Modals/BackOfficeCustomerModal/BackOfficeCustomerModal';
import {
  UpdateCustomerRequest,
  useCustomers,
} from '../../../../hooks/orders/useCustomers';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../../hooks/Notification';
import { writeToString } from '@fast-csv/format';
import { buildURI, downloadFile } from '../../../../utils/csv';
import DataGrid, {
  columnForDataGrid,
  columnStyleOptions,
} from '../../../../components/DataGrid/DataGrid';
import { Operation } from '../../../../types/Operation';
import { noopHandler } from '../../../../utils/errorHandlers';
import { getFormattedAddress } from '../../../../utils/places.util';
import { useIsFocused, useNavigation } from '@react-navigation/native';

const ManageCustomers: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const [searchString, setSearchString] = useState<string>('');
  const [customerData, setCustomerData] = useState<Array<Customer>>([]);
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const {
    customers,
    getCustomers,
    addNewCustomer,
    updateCustomer,
    checkEmailExists,
    loading,
    error,
    searchCustomers,
  } = useCustomers();

  const action = useRef('');
  const customerNameRef = useRef<string>('');
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      getCustomers();
    }
  }, [isFocused, getCustomers]);
  useEffect(() => {
    if (loading) {
      <LoadingIndicator />;
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);
  useEffect(() => {
    const actionType = action.current;
    if (!loading && !error && actionType !== '') {
      closeModal();
      showNotification({
        success: true,
        message:
          actionType === Operation.CREATE
            ? translate('backOfficeCustomers.successMessage', {
                customerName: customerNameRef.current,
              })
            : actionType === Operation.UPDATE
            ? translate('backOfficeCustomers.editSuccessMessage', {
                customerName: customerNameRef.current,
              })
            : translate('customer.deleteSuccessMessage'),
      });
      action.current = '';
    }
  }, [closeModal, loading, showNotification, translate, error]);

  useEffect(() => {
    setCustomerData(customers);
  }, [customers]);

  useEffect(() => {
    const searchResult = searchCustomers(searchString);
    setCustomerData(searchResult);
  }, [searchCustomers, searchString]);

  const onSearchTextChange: (value: string) => void = useCallback(
    (value: string) => {
      setSearchString(value);
    },
    [],
  );

  const createCustomer = useCallback(
    async (customerInfo: Partial<CreateCustomerRequest>) => {
      const { firstName, lastName } = customerInfo;
      action.current = Operation.CREATE;
      customerNameRef.current = `${firstName} ${lastName}`;
      await addNewCustomer(customerInfo);
    },
    [addNewCustomer],
  );

  const editCustomer = useCallback(
    async (customerInfo: UpdateCustomerRequest) => {
      const { firstName, lastName } = customerInfo;
      action.current = Operation.UPDATE;
      customerNameRef.current = `${firstName} ${lastName}`;
      await updateCustomer(customerInfo);
    },
    [updateCustomer],
  );

  const showCreateCustomerModal: () => void = useCallback(() => {
    showModal(
      <BackOfficeCustomerModal
        action={Operation.CREATE}
        addNewCustomer={createCustomer}
        updateCustomer={editCustomer}
        checkEmailExists={checkEmailExists}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [showModal, createCustomer, editCustomer, checkEmailExists, closeModal]);

  const navigateToManageCustomers: (customer: Customer) => void = useCallback(
    (customer: Customer) => {
      navigation &&
        navigation.navigate('ManageCustomersTab', {
          customer: customer,
        });
    },
    [navigation],
  );

  const COLUMNS: columnForDataGrid[] = useMemo(
    () => [
      {
        columnName: translate('form.customerName'),
        columnDataKey: 'customerName',
        columnStyle: columnStyleOptions.REGULAR,
      },
      {
        columnName: translate('form.email'),
        columnDataKey: 'email',
        columnStyle: columnStyleOptions.REGULAR,
      },
      {
        columnName: translate('form.phone'),
        columnDataKey: 'phone',
        columnStyle: columnStyleOptions.REGULAR,
      },
      {
        columnName: 'Points',
        columnDataKey: 'points',
        columnStyle: columnStyleOptions.REGULAR,
      },
      {
        columnName: '',
        columnDataKey: Operation.UPDATE,
        columnStyle: columnStyleOptions.OPTION,
      },
      {
        columnName: '',
        columnDataKey: Operation.DELETE,
        columnStyle: columnStyleOptions.OPTION,
      },
    ],
    [translate],
  );

  const getRowData = useCallback(
    (row: Customer, columnName: React.ReactNode) => {
      let columnData = '';
      if (columnName === 'Customer Name') {
        columnData = `${row.firstName} ${row.lastName}`;
      }
      if (columnName === 'Phone') {
        columnData = `${row.phone}`;
      }
      if (columnName === 'Email') {
        columnData = `${row.email}`;
      }
      return columnData;
    },
    [],
  );

  const exportDataToCSV = useCallback(async () => {
    const rows = customerData.map(rowData => {
      return COLUMNS.filter(col => col.columnName).map(
        (col: columnForDataGrid) => {
          return getRowData(rowData, col.columnName);
        },
      );
    });

    const csvData = await writeToString(rows, {
      delimiter: ',',
      rowDelimiter: '\n',
      quote: '"',
      headers: COLUMNS.filter(col => col.columnName).map(_col => {
        return `${_col.columnName}`;
      }),
    });

    const uri = buildURI(csvData);
    downloadFile(uri, 'Customer_Export.csv');
  }, [customerData, COLUMNS, getRowData]);

  const renderDataItem = useCallback(
    (item: Customer, columnDataKey: string): React.ReactNode => {
      const {
        lastName,
        firstName,
        email,
        phone,
        preferredAddress,
        loyaltyPoints,
      } = item;
      let data = '';
      if (columnDataKey === 'customerName') {
        data = `${firstName} ${lastName}`;
      } else if (columnDataKey === 'phone') {
        data = `${phone}`;
      } else if (columnDataKey === 'email') {
        data = `${email}`;
      } else if (columnDataKey === 'address' && preferredAddress) {
        data = getFormattedAddress(preferredAddress);
      } else if (columnDataKey === 'points') {
        data = `${loyaltyPoints}`;
      }
      return (
        <View style={css(styles.tableRowCells)}>
          <Text style={css(styles.tableRowCellsText)}>{data}</Text>
        </View>
      );
    },
    [css],
  );

  const renderOptionItems = useCallback(
    (customer: Customer, columnDataKey: string): React.ReactNode => {
      const optionConfig = {
        icon: '',
        onPress: noopHandler,
        containerStyle: {},
        iconColor: '',
      };
      switch (columnDataKey) {
        case Operation.UPDATE:
          optionConfig.icon = Icons.AngleRight;
          optionConfig.onPress = () => navigateToManageCustomers(customer);
          optionConfig.iconColor = theme.colors.paragraph;
          optionConfig.containerStyle = styles.editIconContainer;
          break;
      }
      return (
        <IconButton
          primary
          icon={optionConfig.icon}
          iconColor={optionConfig.iconColor}
          iconSize={24}
          containerSize={34}
          containerStyle={css(optionConfig.containerStyle)}
          onPress={optionConfig.onPress}
        />
      );
    },
    [css, navigateToManageCustomers, theme.colors],
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <View style={css(styles.pageStyle)}>
        <ScrollView>
          <BackOfficeSection
            title={translate('backOfficeCustomers.manageCustomers')}
            titleAction={
              <View style={css(styles.searchAreaView)}>
                <SearchBar
                  placeholder={
                    translate(
                      'backOfficeCustomers.SearchCustomersByNameEmailOrPhone',
                    ) + '...'
                  }
                  containerStyle={css(styles.searchContainerStyle)}
                  textInputStyle={css(styles.searchTextInputStyle)}
                  iconColor={theme.colors.paragraph}
                  placeholderColor={theme.colors.paragraph}
                  onChange={onSearchTextChange}
                />
                <View style={css(styles.createButtonStyle)}>
                  <BackOfficeCreateNewButton
                    onPress={showCreateCustomerModal}
                  />
                </View>

                <IconButton
                  testID="download-report"
                  icon="cloud-download"
                  primary
                  containerSize={38}
                  containerStyle={css(styles.downloadButtonStyle)}
                  iconStyle={css(styles.downloadIconStyle)}
                  iconSize={25}
                  onPress={() => exportDataToCSV()}
                />
              </View>
            }
            titleContainerStyle={css(styles.titleContainerStyle)}
            customTitleTextStyle={css(styles.customTitleTextStyle)}
            customTitleDescTextStyle={css(styles.customTitleDescTextStyle)}
            contentContainerStyle={css(styles.contentStyle)}
          >
            <DataGrid
              data={customerData}
              columns={COLUMNS}
              headerStyle={css(styles.columnContainerStyle)}
              headerCellTextAlignment={css(styles.headerCellTextAlignment)}
              rowCellTextAlignment={css(styles.rowCellTextAlignment)}
              dataRowStyle={css(styles.dataRowStyle)}
              renderOptionItems={renderOptionItems}
              renderHeaderItem={(item: string): React.ReactNode => {
                return (
                  <View>
                    <Text style={css(styles.tableHeaderTextStyle)}>{item}</Text>
                  </View>
                );
              }}
              renderDataItem={renderDataItem}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>
    </>
  );
};

export default ManageCustomers;
