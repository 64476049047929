import { useTranslation } from '@hitz-group/localization';
import { Theme, User } from '@hitz-group/domain';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useLogin } from '../../../hooks/app/useLogin';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTheme } from 'react-fela';
import {
  StyleSheet,
  View,
  KeyboardAvoidingView,
  Platform,
  Text,
  TouchableOpacity,
} from 'react-native';
import Button from '../../../components/Button/Button';
import Error from '../../../components/Error/Error';
import FormInput from '../../../components/FormInput/FormInput';
import Logo from '../../../components/Logo/Logo';
import PopupView from '../../../components/PopupView/PopupView';
import Layout from '../../../components/POSLayout/POSLayout';
import { useNotification } from '../../../hooks/Notification';
import { navigateToBackOfficeParams } from '../../../state/navigation';
import { isStrongPassword } from '../../../utils/validator';
import scale from '../../../common/theme';
import { AuthState, tokenUtility } from '../../../state/tokenUtility';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import Gradient from '../../../components/Gradient/Gradient';
import { useFela } from 'react-fela';
import { userUtility } from '../../../state/userUtility';
import { useLogout } from '../../../hooks/app/useLogout';
import { Session } from '../../../state/Session';

export interface LoginScreenProps {
  theme: Theme;
}

export interface State {
  form: {
    email: string;
    password: string;
  };
  isLoggedIn?: boolean;
  empPassCode: string;
  errorMessage: string;
  isFormComplete: boolean;
  hidePassword: boolean;
}

enum FormError {
  INVALID_EMAIL = 'login.invalidEmailMessage',
  INVALID_PASSWORD = 'login.invalidPasswordMessage',
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getStyles = (theme: Theme) => {
  return StyleSheet.create({
    screen: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    loginViewStyle: {
      width: scale.moderateScale(180),
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    logo: {
      marginBottom: theme.spacing.medium,
      alignSelf: 'center',
      height: 62,
      width: 150,
    },
    buttonContainer: {
      width: 280,
      marginTop: 30,
      justifyContent: 'space-between',
      alignItems: 'stretch',
    },
    error: {
      position: 'absolute',
      left: scale.moderateScale(185),
      bottom: scale.moderateScale(5),
    },
    button: {
      marginVertical: scale.moderateScale(5),
      overflow: 'hidden',
    },
    signUpBtn: {
      marginTop: 10,
    },
    popupStyle: {
      height: Platform.OS === 'web' ? 514 : 550,
      width: 360,
      borderRadius: theme.radius.large,
      ...theme.shadow30,
    },
    container: {
      marginTop: scale.moderateScale(10),
    },
    forgotPassword: {
      ...theme.font14Medium,
      color: theme.colors.paragraph,
      textTransform: 'none',
    },
    centerAlignment: {
      alignItems: 'center',
    },
    marginTop10: {
      marginTop: 10,
    },
    textInputContainer: {
      width: 280,
    },
    containerStyle: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};

const initialForm = {
  email: '',
  password: '',
};

const Login: React.FC<LoginScreenProps> = ({ ...props }: LoginScreenProps) => {
  const { theme } = useFela();
  const [form, setForm] = useState({ ...initialForm });
  const [errorMessage, setErrorMessage] = useState('');
  const { loading: logoutLoading } = useLogout();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { login, error, loading } = useLogin();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<StackNavigationProp<any>>();

  const styles = useMemo(() => getStyles(props.theme), [props.theme]);

  useEffect(() => {
    // if user is already logged in and try to visit the login route, then reset back to backoffice overview.
    const subscription: Subscription = tokenUtility.getTokenInfo$
      .pipe(
        take(1),
        filter(auth => auth.authState === AuthState.LOGGED_IN),
      )
      .subscribe(() => {
        navigation.reset(navigateToBackOfficeParams);
      });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [navigation]);

  // Update state when login fails or succeeds
  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onPressCreateAccount = useCallback((): void => {
    setErrorMessage('');
    setForm({ ...initialForm });
    navigation.push('Signup');
  }, [navigation, setForm, setErrorMessage]);

  const onPressForgotPassword = useCallback((): void => {
    setErrorMessage('');
    setForm({ ...initialForm });
    navigation.push('ForgotPassword');
  }, [navigation, setForm, setErrorMessage]);

  const onPressDeviceCodeLogin = useCallback((): void => {
    setErrorMessage('');
    setForm({ ...initialForm });
    navigation.push('DeviceCodeLogin');
  }, [navigation, setForm, setErrorMessage]);

  /**
   * Update state when a form input property has changed
   * @param prop name of input field
   * @param value updated value
   */
  const onChangeFormProp = useCallback(
    (prop: string, value: string): void => {
      if (errorMessage) {
        setErrorMessage('');
      }

      setForm({
        ...form,
        [prop]: value,
      });
    },
    [form, errorMessage],
  );

  // Validate and submit form
  const onPressLogin = useCallback(async (): Promise<void> => {
    if (form?.email?.replace(/\s/g, '') && form?.password?.replace(/\s/g, '')) {
      // TODO: add csurf for this unguarded api
      const session = (await login(form.email, form.password)) as Session;
      if (session?.user?.id) {
        setErrorMessage('');
        userUtility.addOfficeUser(session.user as User);
        navigation.reset(navigateToBackOfficeParams);
      }
    } else if (form.email?.replace(/\s/g, '')) {
      setErrorMessage(FormError.INVALID_PASSWORD);
    } else {
      setErrorMessage(FormError.INVALID_EMAIL);
    }
  }, [form, login, navigation]);

  return (
    <React.Fragment>
      <Layout
        title={translate('navigation.loginPageTitle', {
          appName: translate('appName'),
        })}
        hasHeader={false}
        navigation={navigation}
        useSafeArea={false}
      >
        <Gradient
          colors={[theme.colors.brandPrimary, theme.colors.brandSecondary]}
          style={styles.screen}
          start={theme.gradient.startAxis}
          end={theme.gradient.endAxis}
          locations={theme.gradient.location}
        >
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : undefined}
            style={styles.containerStyle}
          >
            <View
              testID="Login Screen"
              accessible
              accessibilityLabel="Login Screen"
            >
              <PopupView containerStyle={styles.popupStyle}>
                <View style={styles.loginViewStyle}>
                  <Logo testID="App-Logo" style={styles.logo} />
                  <FormInput
                    testID="email"
                    error={errorMessage === FormError.INVALID_EMAIL}
                    alignTitle="left"
                    title={translate('login.email')}
                    value={form.email}
                    onSubmitEditing={onPressLogin}
                    placeholder={translate('login.emailHint')}
                    onChangeText={email => onChangeFormProp('email', email)}
                    containerStyle={styles.textInputContainer}
                  />
                  {errorMessage === FormError.INVALID_EMAIL && (
                    <Error
                      style={styles.error}
                      text={translate(errorMessage)}
                    />
                  )}
                </View>
                <View style={styles.marginTop10}>
                  <FormInput
                    type="password"
                    testID="type-password"
                    alignTitle="left"
                    onSubmitEditing={onPressLogin}
                    verified={isStrongPassword(form.email)}
                    error={errorMessage === FormError.INVALID_PASSWORD}
                    title={translate('login.password')}
                    value={form.password}
                    placeholder={translate('login.passwordHint')}
                    onChangeText={onChangeFormProp.bind(null, 'password')}
                    containerStyle={styles.textInputContainer}
                  />
                  {errorMessage === FormError.INVALID_PASSWORD && (
                    <Error
                      style={styles.error}
                      text={translate(errorMessage)}
                    />
                  )}
                </View>
                <View style={styles.buttonContainer}>
                  <View style={styles.button}>
                    <Button
                      size="small"
                      success
                      fluid
                      testID="login"
                      loading={loading || logoutLoading}
                      title={translate('button.login')}
                      onPress={onPressLogin}
                    />
                  </View>
                  <View style={styles.button}>
                    <Button
                      secondary
                      testID="device-code-button"
                      size="small"
                      fluid
                      title={translate('button.deviceCodeLogin')}
                      onPress={onPressDeviceCodeLogin}
                    />
                  </View>
                  <View style={[styles.button, styles.signUpBtn]}>
                    <TouchableOpacity
                      onPress={onPressCreateAccount}
                      testID="signup"
                      style={styles.centerAlignment}
                    >
                      <Text style={styles.forgotPassword}>
                        {translate('button.signup')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={styles.button}>
                    <TouchableOpacity
                      testID="forgot-password-btn"
                      onPress={onPressForgotPassword}
                      style={styles.centerAlignment}
                    >
                      <Text style={styles.forgotPassword}>
                        {translate('button.forgotPassword')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </PopupView>
            </View>
          </KeyboardAvoidingView>
        </Gradient>
      </Layout>
    </React.Fragment>
  );
};

export default withTheme(Login);
