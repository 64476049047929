import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';
import scale, { isWeb } from '../../../common/theme';

export const PrintJobsListRowStyles = () => {
  const { theme } = useFela();
  const rowHeight = 46;

  return useMemo(
    () =>
      StyleSheet.create({
        rowContainerStyle: {
          flex: 1,
          justifyContent: 'space-between',
          paddingLeft: theme.spacing.medium,
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: theme.colors.white,
          paddingHorizontal: theme.padding.large,
          width: '100%',
          height: 46,
          marginBottom: theme.spacing.small / 2,
          borderRadius: theme.radius.small,
        },
        fieldViewStyle: {
          height: rowHeight,
          flex: 1,
          paddingHorizontal: theme.spacing.small,
          justifyContent: 'center',
          alignItems: 'center',
        },
        fieldViewEndStyle: {
          height: rowHeight,
          flex: 1,
          flexDirection: 'row',
          alignItems: 'flex-end',
          paddingHorizontal: theme.spacing.small,
          justifyContent: 'space-around',
        },
        textStyle: {
          fontSize: theme.fontSize.small,
          fontFamily: theme.font.regular,
          textTransform: 'capitalize',
        },
        labelText: {
          fontFamily: theme.font.regular,
        },
        labelStyle: {
          width: 80,
        },
        printIconStyle: {
          color: theme.colors.blue,
        },
        removeIconStyle: {
          color: theme.colors.danger,
        },
      }),
    [theme],
  );
};

export const PrintJobsListStyles = () => {
  const { theme } = useFela();
  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        scrollStyle: {
          width: '100%',
          alignSelf: 'center',
        },
        columnContainerStyle: {
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: theme.colors.white,
          paddingHorizontal: theme.padding.large,
          marginVertical: theme.spacing.small * 1.5,
          height: 44,
          borderBottomWidth: 0,
          marginRight: 0,
          borderRadius: theme.radius.small,
        },
        headerTitleStyle: {
          width: 400,
          backgroundColor: theme.colors.primaryDarkest,
          padding: theme.spacing.small * 1.5,
          borderRadius: theme.radius.small,
        },
        loadingStyle: {
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        },
        filterContainerStyle: {
          flexDirection: 'row',
          paddingHorizontal: theme.spacing.big,
          paddingTop: theme.spacing.small,
          justifyContent: 'space-between',
        },
        searchContainerStyle: {
          borderColor: theme.colors.boxBorder,
          backgroundColor: theme.colors.white,
          borderWidth: 0.8,
          height: theme.input.height,
          flex: isWeb ? 0.8 : 0.7,
          borderRadius: theme.radius.small,
          justifyContent: 'center',
          marginTop: isWeb ? scale.moderateScale(18) : scale.moderateScale(17),
        },
        searchTextInputStyle: {
          paddingHorizontal: scale.moderateScale(1),
          paddingVertical: scale.moderateScale(5),
        },
        printAllBtnStyle: {
          borderColor: theme.colors.boxBorder,
          backgroundColor: theme.colors.blue,
          borderWidth: 0.8,
          height: theme.input.height,
          flex: isWeb ? 0.1 : 0.1,
          borderRadius: theme.radius.small,
          justifyContent: 'center',
          marginTop: isWeb ? scale.moderateScale(18) : scale.moderateScale(17),
        },
        btnTextStyle: {
          color: theme.colors.white,
        },
        clearAllBtnStyle: {
          borderColor: theme.colors.boxBorder,
          backgroundColor: theme.colors.danger,
          borderWidth: 0.8,
          height: theme.input.height,
          flex: isWeb ? 0.1 : 0.1,
          borderRadius: theme.radius.small,
          justifyContent: 'center',
          marginTop: isWeb ? scale.moderateScale(18) : scale.moderateScale(17),
        },
        summaryDisplayStyle: {
          height: '100%',
          width: 300,
          right: 0,
          marginTop: theme.spacing.small,
          position: 'absolute',
          backgroundColor: theme.colors.background,
          flexDirection: 'column',
          alignItems: 'center',
          shadowColor: theme.colors.paragraph,
        },
        summaryHeaderStyle: {
          flexDirection: 'row',
          height: 44,
          width: '90%',
          backgroundColor: theme.colors.white,
          alignItems: 'center',
        },
        iconButtonStyle: {
          justifyContent: 'center',
          height: 36,
          width: 36,
          alignItems: 'center',
          borderRadius: 5,
        },
        headerTextStyle: {
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: theme.fontSize.medium,
          fontFamily: theme.font.regular,
          textTransform: 'capitalize',
        },
      }),
    [theme],
  );
};
