import React from 'react';
import { View } from 'react-native';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import Button from '../Button/Button';
import IconButton from '../Button/IconButton';

const container: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const tabManageContainer: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  height: 48,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  height: 48,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
  marginLeft: theme.spacing.small,
  flex: 1,
});

const selectedButtonStyle: StyleFn = ({ theme }) => ({
  height: 30,
  borderRadius: theme.radius.small,
  marginLeft: theme.spacing.small,
  marginTop: 9,
  flex: 1,
  backgroundColor: theme.colors.greyLight,
});

const backButtonStyle: StyleFn = () => ({
  height: 38,
  width: 30,
  position: 'absolute',
  left: 0,
});

const navTabsContainerStyle: StyleFn = () => ({
  justifyContent: 'center',
  flexDirection: 'row',
  marginLeft: 30,
});

const deSelectLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontFamily: theme.font.semibold,
  letterSpacing: -0.5,
  textTransform: 'none',
});

const selectLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
  letterSpacing: -0.5,
});

interface PreviousRouteProps {
  screen?: string;
  params?: Object;
}
export interface TabBarProps {
  tabBar: MaterialTopTabBarProps;
  showBack?: boolean;
  fluid?: boolean;
  previousRoute?: string;
  previousScreen?: string | PreviousRouteProps;
}
const TabBar: React.FC<TabBarProps> = ({
  tabBar,
  showBack,
  previousRoute,
  previousScreen,
  fluid,
}: TabBarProps) => {
  const { css } = useFela();
  return (
    <View style={css(container)}>
      <View style={css(tabManageContainer)}>
        {showBack && (
          <IconButton
            primary
            icon="AngleLeft"
            iconSize={24}
            containerSize={34}
            containerStyle={css(backButtonStyle)}
            onPress={
              previousRoute
                ? () => {
                    tabBar.navigation.navigate(
                      previousRoute,
                      previousScreen
                        ? typeof previousScreen === 'string'
                          ? { screen: previousScreen }
                          : previousScreen
                        : {},
                    );
                  }
                : tabBar.navigation.goBack
            }
          />
        )}

        <View style={css(navTabsContainerStyle)}>
          {tabBar.state.routes.map(
            (route: { name: string; key: string }, index: number) => {
              const isFocused = tabBar.state.index === index;
              const { options } = tabBar.descriptors[route.key] as {
                options: { tabBarLabel?: string };
              };
              const label: string =
                (options.tabBarLabel as string) || route.name;
              return (
                <Button
                  fluid={fluid}
                  title={label}
                  key={index}
                  numberOfLines={1}
                  containerStyle={
                    isFocused ? css(selectedButtonStyle) : css(buttonStyle)
                  }
                  labelStyle={
                    isFocused ? css(selectLabelStyle) : css(deSelectLabelStyle)
                  }
                  onPress={(): void => {
                    !isFocused && tabBar.navigation.navigate(route.name);
                  }}
                />
              );
            },
          )}
        </View>
      </View>
    </View>
  );
};

TabBar.defaultProps = {
  showBack: true,
};
export default TabBar;
