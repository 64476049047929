import {
  Adjustment,
  Order,
  OrderItem,
  OrderTax,
  AdjustmentType,
  AdjustmentUnit,
} from '@hitz-group/domain';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import {
  computeOrderItemTotal,
  getPaymentAdjustmentValue,
  getAdjustmentValue,
} from '@hitz-group/order-helper';
import _ from 'lodash';
import { formatMoneyValue } from '@hitz-group/localization';

/**
 * Order items section has three columns and `n` row(s)
 */
export const config: TableUserConfig = {
  columns: {
    0: {
      width: 3,
    },
    1: {
      width: 29,
    },
    2: {
      alignment: 'right',
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    // TODO get these from api / our custom hook
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};
export const printModifiers = (
  acc: FixedTuple[],
  item: OrderItem,
  currency: string,
) => {
  if (item.modifiers && item.modifiers?.length > 0) {
    item.modifiers.forEach(modifier => {
      let priceExists = '';
      const printQuantity = item.quantity * modifier.quantity;
      if (modifier?.unitPrice) {
        const printPrice = item.quantity * modifier.unitPrice;
        priceExists = formatMoneyValue(
          +printPrice * +modifier.quantity,
          currency,
        );
      }
      if (printQuantity == 1) {
        acc.push(['', ` ${modifier.name}`, priceExists]);
      } else {
        acc.push([` ${printQuantity}`, `  ${modifier.name}`, priceExists]);
      }
    });
  }
};

export const adjustmentsFormatted = (
  adjustments: Adjustment[],
  currency: string,
  type: AdjustmentType,
) => {
  adjustments = adjustments?.filter(
    adjustment =>
      adjustment.adjustmentType == type &&
      adjustment?.allowOnPaymentType == false,
  );

  if (
    Array.isArray(adjustments) &&
    adjustments.length === 1 &&
    adjustments[0].adjustmentUnit === AdjustmentUnit.FLAT
  ) {
    return '';
  } else {
    return (adjustments || [])
      .map(x =>
        x.adjustmentUnit === AdjustmentUnit.PERCENTAGE
          ? `${x.amount}%`
          : `${formatMoneyValue(x.amount, currency)}`,
      )
      .join(', ');
  }
};

export const paymentTypeSurchargeName = (adjustments: Adjustment[]) => {
  adjustments = adjustments?.filter(
    adjustment => adjustment?.allowOnPaymentType == true,
  );
  return (adjustments || []).map(x => x.name).join(', ');
};

export const paymentTypeSurchargeAmount = (
  adjustments: Adjustment[],
  subTotal: number,
) => {
  const surchargeAmount = getAdjustmentValue(subTotal, adjustments, {
    escapePaymentType: true,
  });
  return getPaymentAdjustmentValue(
    subTotal + surchargeAmount || 0,
    adjustments || [],
  );
};

export const printItemDiscount = (
  acc: FixedTuple[],
  item: OrderItem,
  currency: string,
) => {
  if (item.discountAmount) {
    acc.push([
      '',
      'Discount',
      `-${formatMoneyValue(item.discountAmount, currency)}`,
    ]);
  }
};

export const printItemNotes = (acc: FixedTuple[], item: OrderItem) => {
  if (item.notes) {
    acc.push(['', `**${item.notes}`, '\n']);
  }
};

export interface ExtraValueTransformationOptions {
  currency: string;
  order: Order;
}

export type FixedTuple = [string, string, string];

/**
 * Row definitions
 */
const orderItemRows: Array<{
  description?: string;
  path: string; // object path
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueTransformationFn: (...args: any) => FixedTuple[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  canShow?: (...args: any) => boolean; // any transformation required on result
}> = [
  {
    description: 'Order Items qty, name, and price',
    path: 'orderItems',
    valueTransformationFn: (
      orderItems: OrderItem[],
      { currency }: ExtraValueTransformationOptions,
    ) => {
      return orderItems.reduce((acc, item) => {
        const variant = item.variant;
        const product = item.product;
        const variantOptions = product?.optionValues || [];
        const productRow: FixedTuple = [
          item.quantity + '',
          `${variant?.name || product?.name}${variantOptions.map(
            (v: { value: string }) => `\n${v.value}`,
          )}`,
          formatMoneyValue(computeOrderItemTotal(item), currency),
        ];
        acc.push(productRow);
        printModifiers(acc, item, currency);
        printItemDiscount(acc, item, currency);
        printItemNotes(acc, item);
        return acc;
      }, [] as FixedTuple[]);
    },
    canShow: (orderItems: OrderItem[]) => orderItems?.length > 0,
  },
  {
    description: 'Order Notes',
    path: 'orderNote',
    valueTransformationFn: (value = '') => {
      const rows: FixedTuple[] = [
        ['', '', ''],
        ['', `**${value}`, ''],
        ['', '', ''],
      ];
      return rows;
    },
    canShow: (value: string) => value?.length > 0,
  },
  {
    path: 'subTotal',
    valueTransformationFn: (
      value = 0,
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        ['', '', ''],
        ['', 'Sub Total', formatMoneyValue(value, currency)],
      ];
      return rows;
    },
    canShow: () => true,
  },
  {
    path: 'adjustments',
    valueTransformationFn: (
      adjustments: Adjustment[],
      { currency, order }: ExtraValueTransformationOptions,
    ) => {
      const surchargeAmount = getAdjustmentValue(order?.subTotal, adjustments, {
        escapePaymentType: true,
        adjustmentType: AdjustmentType.SURCHARGE,
      });

      const surchargeTxt = adjustmentsFormatted(
        order?.adjustments || [],
        currency,
        AdjustmentType.SURCHARGE,
      );

      const rows: FixedTuple[] = [
        [
          '',
          `Surcharge${surchargeTxt.trim() !== '' ? ` (${surchargeTxt})` : ''}`,
          `${formatMoneyValue(surchargeAmount, currency)}`,
        ],
      ];
      return rows;
    },
    canShow: (adjustments: Adjustment[]) => {
      return adjustments?.some(
        adjustment =>
          adjustment.adjustmentType == AdjustmentType.SURCHARGE &&
          adjustment?.allowOnPaymentType == false,
      );
    },
  },
  {
    path: 'adjustments',
    valueTransformationFn: (
      adjustments: Adjustment[],
      { currency, order }: ExtraValueTransformationOptions,
    ) => {
      const discountAmount = order?.discountAmount || 0;

      const discountTxt = adjustmentsFormatted(
        order?.adjustments || [],
        currency,
        AdjustmentType.DISCOUNT,
      );

      const rows: FixedTuple[] = [
        [
          '',
          `Discount${discountTxt.trim() !== '' ? ` (${discountTxt})` : ''}`,
          `-${formatMoneyValue(discountAmount, currency)}`,
        ],
      ];
      return rows;
    },
    canShow: (adjustments: Adjustment[]) => {
      return adjustments?.some(
        adjustment =>
          adjustment.adjustmentType == AdjustmentType.DISCOUNT &&
          adjustment?.allowOnPaymentType == false,
      );
    },
  },
  {
    path: 'adjustments',
    valueTransformationFn: (
      adjustments: Adjustment[],
      { order, currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        [
          '',
          `${paymentTypeSurchargeName(order?.adjustments || [])}`,
          `${formatMoneyValue(
            paymentTypeSurchargeAmount(order.adjustments || [], order.subTotal),
            currency,
          )}`,
        ],
      ];
      return rows;
    },
    canShow: (adjustments: Adjustment[]) => {
      return adjustments?.some(adjustment => adjustment?.allowOnPaymentType);
    },
  },
  {
    path: 'roundingAmount',
    valueTransformationFn: (
      value = 0,
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        ['', 'Cash Rounding', formatMoneyValue(value || 0, currency)],
      ];
      return rows;
    },
    canShow: (value: string) => !!value,
  },
  {
    // TODO: do we need to show taxes individually
    path: 'taxes',
    valueTransformationFn: (
      taxes: OrderTax[] = [],
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = taxes.map(tax => [
        '',
        tax.tax?.name || '',
        formatMoneyValue(tax.amount || 0, currency),
      ]);

      if (taxes.length > 1) {
        rows.push([
          '',
          'Total Tax',
          formatMoneyValue(
            taxes.reduce((total, tax) => total + tax.amount, 0),
            currency,
          ),
        ]);
      }
      return rows;
    },
    canShow: () => true,
  },
  {
    path: 'deliveryFee',
    valueTransformationFn: (
      deliveryFee,
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        ['', 'Delivery Fee', formatMoneyValue(+deliveryFee, currency)],
      ];
      return rows;
    },
    canShow: (value: string) => !!value,
  },
  {
    path: 'serviceCharge',
    valueTransformationFn: (
      serviceCharge,
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        ['', 'Service Charge', formatMoneyValue(+serviceCharge, currency)],
      ];
      return rows;
    },
    canShow: (value: string) => !!value,
  },
  {
    path: 'tip',
    valueTransformationFn: (
      tip = 0,
      { currency }: ExtraValueTransformationOptions,
    ) => {
      const rows: FixedTuple[] = [
        ['', 'Tips', formatMoneyValue(tip, currency)],
      ];
      return rows;
    },
    canShow: (value: string) => !!value,
  },
];

export const generateOrderItems = (order: Order, currency: string) => {
  const itemsTable = orderItemRows.reduce((acc, row) => {
    const value = _.get(order, row.path);
    if (row.canShow && row.canShow(value)) {
      const rowWithData: FixedTuple[] = row.valueTransformationFn(value, {
        currency,
        order,
      });
      return [...acc, ...rowWithData];
    }
    return acc;
  }, [] as FixedTuple[]);
  return table(itemsTable, config);
};

export const generateOrderItemsHeaders = (filter: { refundOrder: boolean }) => {
  let data = [[' ', ' ', ' ']];
  if (filter.refundOrder) {
    data = [[' ', ' ', ' ']];
  }
  return table(data, config);
};
