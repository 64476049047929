import { useQuery, useSubscription } from '@apollo/client/react/hooks';
import { OrderStatus, StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useRoute } from '@react-navigation/core';
import {
  RouteProp,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import NewOrderButton from '../../../components/NewOrder/NewOrderButton';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { ActivityIndicator, View } from 'react-native';
import scale, { isAndroid, isIos, isWeb } from '../../../common/theme';
import DropDown from '../../../components/FormInput/DropDown';
import {
  DrawerButton,
  SubscriptionStatusButton,
} from '../../../components/HeaderIcons/HeaderIcons';
import OpenOrdersView, {
  OpenOrdersDataProps,
} from '../../../components/OrderDataView/OpenOrdersView';
import Layout from '../../../components/POSLayout/POSLayout';
import SearchBar from '../../../components/SearchBar/SearchBar';
import Title from '../../../components/Title/Title';
import { GET_ALL_ORDER_TYPES } from '../../../graphql/orders';
import { OrderEvents } from '../../../graphql/subscriptions';
import { useOrders } from '../../../hooks/app/orders/useOrders';
import { useSession } from '../../../hooks/app/useSession';
import useBehaviorSubjectState from '../../../hooks/app/useSubjectState';
import { useNotification } from '../../../hooks/Notification';
import { usePrinting } from '../../../hooks/PrintingProvider';
import { lastActiveTimeSubject } from '../../../state/lastActiveTime';
import { AppScreen } from '../../../types/AppScreen';
import { parseApolloError } from '../../../utils/errorHandlers';
import {
  filterOpenOrdersData,
  mapOrdersToOpenOrdersView,
} from '../../../utils/OpenOrdersHelper';
import HeaderTabs from './HeaderTabs';
import { useCartContext } from '../../../hooks/CartProvider';

const filterContainer: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  paddingHorizontal: theme.spacing.big,
  paddingTop: theme.spacing.small * 2.5,
  height: scale.moderateScale(45),
  width: '100%',
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: 255,
  height: theme.input.height,
  paddingVertical: scale.moderateScale(7),
});

export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: scale.textInputWidth180,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

export const dropdownViewStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  height: theme.input.height,
  width: isWeb ? scale.moderateScale(180) : '25%',
  right: isIos ? scale.moderateScale(7) : 0,
  bottom: scale.moderateScale(5),
});

export const extraMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '90%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  marginLeft: scale.moderateScale(15),
});

const searchInputStyle: StyleFn = () => ({
  flex: 1,
});

export const searchContainerStyle: StyleFn = ({ theme, error }) => ({
  borderColor: error ? theme.colors.danger2 : theme.colors.boxBorder,
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  borderWidth: 0.8,
  height: theme.input.height,
  width: isWeb ? '99%' : isIos ? '100%' : '96%',
  borderRadius: theme.radius.small,
  justifyContent: 'flex-end',
  left: isWeb ? scale.moderateScale(8) : 0,
  top: scale.moderateScale(5),
  alignItems: 'flex-end',
});

export const searchTextInputStyle: StyleFn = ({ theme }) => ({
  width: '98%',
  paddingHorizontal: theme.padding.medium,
});

const orderTitleTitleStyle: StyleFn = () => ({
  textTransform: 'none',
  width: 200,
  left: isAndroid ? 30 : 5,
});
const angleDownIconStyle: StyleFn = () => ({
  right: 5,
});

const searchBarTitleStyle: StyleFn = ({ theme }) => ({
  textTransform: 'none',
  position: 'absolute',
  bottom: 0,
  ...theme.font14RegularDarkGrey,
  left: 5,
});

const orderRowsContainerStyle: StyleFn = () => ({
  flex: 1,
});
const loaderContainer: StyleFn = () => ({
  justifyContent: 'center',
  height: scale.moderateScale(40),
});

type OpenOrdersParamList = RouteProp<
  {
    OpenOrders: { previousScreen: string };
  },
  'OpenOrders'
>;

const OpenOrders: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const route = useRoute<OpenOrdersParamList>();
  const { previousScreen } = route.params ?? {};
  const [orderTypeFilter, setOrderTypeFilter] = useState('');
  const [keywordFilter, setKeyWordFilter] = useState('');
  const isFocused = useIsFocused();
  const [session] = useSession();
  const currentStoreId = session?.currentStore?.id;
  const deviceId = session?.device?.id;
  const { value: lastActiveTime } = useBehaviorSubjectState(
    lastActiveTimeSubject,
  );
  const { data: subscriptionData } = useSubscription(OrderEvents, {
    variables: {
      storeId: currentStoreId,
      deviceId,
      lastActiveTime,
    },
    shouldResubscribe: true,
  });

  const { printBill } = usePrinting();
  const { openOrderCart } = useCartContext();

  const {
    orders,
    loading,
    error: ordersDataError,
    getOrdersFromCache,
  } = useOrders();

  useEffect(() => {
    subscriptionData &&
      setTimeout(() => getOrdersFromCache(OrderStatus.IN_PROGRESS), 2000);
  }, [getOrdersFromCache, subscriptionData]);

  useEffect(() => {
    isFocused && getOrdersFromCache(OrderStatus.IN_PROGRESS);
  }, [getOrdersFromCache, isFocused]);

  const { data: orderTypesData, error: orderTypesError } =
    useQuery(GET_ALL_ORDER_TYPES);

  useEffect(() => {
    if (orderTypesError) {
      showNotification({
        error: true,
        message: parseApolloError(orderTypesError),
      });
    }
  }, [orderTypesError, showNotification]);

  useEffect((): void => {
    if (ordersDataError) {
      showNotification({
        error: true,
        message: ordersDataError,
      });
    }
  }, [ordersDataError, showNotification]);

  const ordersArray = useMemo(() => {
    return Object.values(orders);
  }, [orders]);

  const filteredData = useMemo<OpenOrdersDataProps[]>(() => {
    const filteredInprogress = filterOpenOrdersData(
      mapOrdersToOpenOrdersView(
        (ordersArray || []).filter(x => x.status === OrderStatus.IN_PROGRESS),
        orderTypesData ? orderTypesData.orderTypes : [],
      ),
      orderTypeFilter,
      keywordFilter,
    );
    return filteredInprogress;
  }, [ordersArray, orderTypeFilter, keywordFilter, orderTypesData]);

  const headerTitle = useMemo(
    () => (
      <HeaderTabs
        previousScreen={previousScreen}
        selectedOption={AppScreen.OPEN_ORDER}
      />
    ),
    [previousScreen],
  );

  const headerRight = useMemo(
    () => (
      <>
        <SubscriptionStatusButton />
        <NewOrderButton />
        <DrawerButton />
      </>
    ),
    [],
  );

  const orderTypeFilterOptions = useMemo(
    () => [
      {
        label: 'View All',
        value: '',
      },
      ...(orderTypesData?.orderTypes || []),
    ],
    [orderTypesData],
  );

  const navigateToOrder = useCallback(
    orderId => {
      navigation.navigate('TakeOrder', {
        id: orderId,
        isCompleted: false,
        isExisting: true,
      });
      openOrderCart(orderId);
    },
    [navigation, openOrderCart],
  );

  const onPressPrintReceipt = useCallback(
    async (orderId: string, nthPayment?: number) => {
      const order = orders[orderId];
      if (order) {
        const result = await printBill(order, nthPayment);
        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
      }
    },
    [printBill, showNotification, orders],
  );

  const onVoidOrder = useCallback(() => {
    // FIXME: Voided order is not reflected immediately in cache.
    setTimeout(() => getOrdersFromCache(OrderStatus.IN_PROGRESS), 200);
  }, [getOrdersFromCache]);

  return (
    <Layout
      title={translate('navigation.openOrdersTitle', {
        appName: translate('appName'),
      })}
      hasHeader
      headerRight={headerRight}
      headerTitle={headerTitle}
      testID="open-orders-page"
    >
      <View style={css(filterContainer)}>
        {!orderTypesData ? (
          <View style={css(loaderContainer)}>
            <ActivityIndicator color={theme.colors.primary} />
          </View>
        ) : (
          <DropDown
            values={orderTypeFilterOptions}
            title={translate('orderHistory.orderType')}
            style={css(dropDownStyle)}
            extraStyle={css(dropdownExtraStyle)}
            extraViewStyle={css(dropdownViewStyle)}
            extraMainViewStyle={css(extraMainViewStyle)}
            selectedValue={orderTypeFilter}
            onValueChange={(selectedValue): void =>
              setOrderTypeFilter(selectedValue)
            }
            textStyle={css(orderTitleTitleStyle)}
            angleDownIconStyle={css(angleDownIconStyle)}
            testID="open-order-types"
          />
        )}
        <View style={css(searchInputStyle)}>
          <Title alignTitle="left" labelStyle={css(searchBarTitleStyle)}>
            {translate('openOrders.searchBarTitle')}
          </Title>
          <SearchBar
            secondary
            placeholder={translate('openOrders.searchPlaceholder')}
            onChange={(text): void => setKeyWordFilter(text)}
            containerStyle={css(searchContainerStyle)}
            textInputStyle={css(searchTextInputStyle)}
            testID="open-orders-search"
          />
        </View>
      </View>
      <View style={css(orderRowsContainerStyle)}>
        {loading ? (
          <View style={css(loaderContainer)}>
            <ActivityIndicator color={theme.colors.primary} />
          </View>
        ) : (
          <OpenOrdersView
            data={filteredData}
            orders={orders}
            onSelectOrder={navigateToOrder}
            onPressPrintReceipt={onPressPrintReceipt}
            onVoidOrder={onVoidOrder}
          />
        )}
      </View>
    </Layout>
  );
};

export default OpenOrders;
