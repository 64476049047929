import { useCallback, useMemo } from 'react';
import { Store, User, Venue } from '@hitz-group/domain';
import { useDeviceId } from './useDeviceId';
import { useOrderNumber } from '../orders/useOrderNumber';
import { useSession } from './useSession';
import { useSettings } from './useSettings';

export interface useSetUserSessionProps {
  setUserSession: (user: User) => void;
}

export const useSetUserSession = (): useSetUserSessionProps => {
  const { deviceId } = useDeviceId();
  const { setOrderCounter } = useOrderNumber();
  const [session, setSession] = useSession();
  const [counter] = useSettings<number>('orderCounter');

  const currentDeviceId = session.device?.uuid || deviceId;

  const setUserSession = useCallback(
    (user: Partial<User>) => {
      if (user && user?.organizations) {
        const organizations = user?.organizations;
        const currentOrganization = organizations?.[0];

        let currentStore = undefined as unknown as Store;

        const currentVenue = (user?.venues as Venue[])?.find(venue => {
          const store = venue?.stores?.find(store =>
            store.devices.some(device => device.uuid === currentDeviceId),
          );
          if (store) {
            currentStore = store;
          }
          return !!store;
        });

        const currentDevice = currentStore?.devices?.find(
          device => device.uuid === currentDeviceId,
        );

        currentDevice &&
          !counter &&
          setOrderCounter(currentDevice.previousOrder?.orderNumber || '');

        const defaultDeviceProfile = currentStore?.deviceProfiles?.find(
          deviceProfile =>
            !!currentDevice &&
            deviceProfile.id === currentDevice.deviceProfile?.id,
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let currentDeviceProfile: any;
        if (defaultDeviceProfile) {
          currentDeviceProfile = Object.assign({}, defaultDeviceProfile);
          currentDeviceProfile.defaultOrderType =
            defaultDeviceProfile?.defaultOrderType ||
            defaultDeviceProfile?.orderTypes?.[0];
        }
        setSession({
          ...session,
          user,
          currentOrganization,
          availableOrganizations: organizations,
          currentVenue,
          currentStore,
          deviceProfile: currentDeviceProfile,
          device: currentDevice,
        });
      }
    },
    [currentDeviceId, counter, session, setSession, setOrderCounter],
  );

  return useMemo(
    () => ({
      setUserSession: setUserSession,
    }),
    [setUserSession],
  );
};
