import { App, StyleFn } from '@hitz-group/domain';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { TouchableOpacity, View, Text } from 'react-native';
import Button from '../Button/Button';
import Logo from '../Logo/Logo';
import { useTranslation } from '@hitz-group/localization';
import { SetupGuide } from './SetupGuide';
import { useAppToken } from '../../hooks/app/useAppToken';
import { tokenUtility } from '../../state/tokenUtility';
import { useNotification } from '../../hooks/Notification';
import { navigateToLockScreen } from '../../state/navigation';
import useOfficeUserAuthorization from '../../hooks/app/users/useOfficeUserAuthorization';
import { useModal } from '@hitz-group/rn-use-modal';
import ReportIssueModal from '../Modals/ReportIssueModal';

const containerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  paddingVertical: theme.padding.large,
  marginHorizontal: theme.padding.large,
  borderBottomWidth: 2,
  borderBottomColor: theme.colors.boxBorder,
  alignItems: 'center',
  zIndex: 9,
});

const setupGuideContainer: StyleFn = () => ({
  marginRight: 20,
});

const posButtonStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  width: 80,
  height: theme.button.footerButtonHeight,
  marginHorizontal: 10,
});

const reportIssueButtonStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  width: 120,
  height: theme.button.footerButtonHeight,
  marginHorizontal: 10,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
});

const logoContainerStyle: StyleFn = () => ({
  width: 50,
});
const buttonContainerStyle: StyleFn = () => ({
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

const logoStyle: StyleFn = () => ({
  width: 44,
  height: 44,
  marginBottom: 0,
});
const menuTitleStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});
const subMenuTitleStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  color: theme.colors.textLight,
});
const menuTitleContainer: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  padding: theme.padding.medium,
  minHeight: 30,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  alignItems: 'center',
});
const subMenuTitleContainer: StyleFn = ({ theme }) => ({
  paddingVertical: theme.padding.medium,
  paddingHorizontal: theme.padding.small,
  minHeight: 30,
  justifyContent: 'center',
  alignItems: 'center',
});
const rowContainer: StyleFn = () => ({
  flexDirection: 'row',
});

interface RouteState {
  index?: number;
  key: string;
  name?: string;
  params?: {
    [key: string]: string;
  };
  state?: RouteState;
  routeNames?: string[];
  routes?: RouteState[];
}

const initialState: string[] = [];

function getFullRoutePath(route: RouteState): string[] {
  if (route?.state?.routes) {
    const idx = route?.state?.index || 0;
    const subRoute = route?.state?.routes[idx];
    const routeName = route?.state?.routes[idx]?.name || '';
    const screenName = route?.state?.routes[idx]?.params?.screen || '';
    const paths = getFullRoutePath(subRoute as RouteState) || [];
    if (subRoute?.params?.title) {
      return [...paths];
    } else {
      return [routeName, screenName, ...paths] as string[];
    }
  } else {
    const title: string = route?.params?.title || '';
    return [title];
  }
}

const BackOfficeHeader: React.FC = () => {
  const { css, theme } = useFela();
  const [currentRoute, setCurrentRoute] = useState(initialState);
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { requestAppToken } = useAppToken();
  const route = useNavigationState(
    state => state.routes[state.routes.length - 1],
  );
  const { showNotification } = useNotification();
  const { canAccessPOSApp } = useOfficeUserAuthorization();

  useEffect(() => {
    if (route.state) {
      const routePaths = getFullRoutePath(route as RouteState);
      const filteredRoutePaths = routePaths.filter(
        (path, idx) => !!path && routePaths.indexOf(path) === idx,
      );
      const santisedRoutePaths = filteredRoutePaths.map(path => {
        let result = '';
        switch (path) {
          case 'VenuesStores':
            result = 'Venues & Stores';
            break;
          case 'TaxesFees':
            result = 'Taxes & Fees';
            break;
          case 'PaymentTypes':
            result = 'Payment Types';
            break;
          case 'SalesChannels':
            result = 'Sales Channels';
            break;
          case 'JobRoles':
            result = 'Job Roles';
            break;
          case 'SalesOverview':
            result = 'Sales Overview';
            break;
          case 'RefundReasons':
            result = 'Refund Reasons';
            break;
          default:
            result = path;
            break;
        }
        return result;
      });
      const refactorRoutePaths = santisedRoutePaths.map((path, idx) => {
        return idx === 0 ? path : `/ ${path}`;
      });
      setCurrentRoute(refactorRoutePaths);
    }
  }, [route]);

  const onPressPOSButton = useCallback(async () => {
    const appToken = await requestAppToken(App.POS_APP);
    if (appToken) {
      tokenUtility.setTokenInfo({
        posAppToken: appToken,
        activeApp: App.POS_APP,
      });
      navigation.reset(navigateToLockScreen());
    } else {
      showNotification({
        error: true,
        message: translate('authMessages.logoutFailed'),
      });
    }
  }, [navigation, requestAppToken, showNotification, translate]);
  const { showModal } = useModal();

  const onPressReportIssue = useCallback(() => {
    showModal(<ReportIssueModal />);
  }, [showModal]);

  const onPressHome = useCallback(() => {
    navigation.navigate('Account', { screen: 'Overview' });
  }, [navigation]);

  const renderBreadCrumbs = () => {
    return currentRoute.map((route, idx) => {
      if (idx === 0) {
        return (
          <View style={css(menuTitleContainer)} key={`view-${idx}`}>
            <Text style={css(menuTitleStyle)} testID="menu-title">
              {route}
            </Text>
          </View>
        );
      } else {
        return (
          <View style={css(subMenuTitleContainer)} key={`view-${idx}`}>
            <Text
              style={css(subMenuTitleStyle)}
              testID={`subMenu-title-${idx}`}
            >
              {route}
            </Text>
          </View>
        );
      }
    });
  };

  const accessPOSApp = useMemo(() => canAccessPOSApp(), [canAccessPOSApp]);

  return (
    <View style={css(containerStyle)}>
      <View style={css(logoContainerStyle)}>
        <TouchableOpacity testID="app-logo-btn" onPress={onPressHome}>
          <Logo style={css(logoStyle)} />
        </TouchableOpacity>
      </View>
      <View style={css(rowContainer)}>{renderBreadCrumbs()}</View>
      <View style={css(buttonContainerStyle)}>
        <View style={css(setupGuideContainer)}>
          <SetupGuide />
        </View>
        <Button
          testID="report-issue"
          size="small"
          fluid
          onPress={onPressReportIssue}
          color={theme.colors.lightBlue}
          containerStyle={css(reportIssueButtonStyle)}
          title={translate('reportIssue.titleText')}
        />
        {accessPOSApp && (
          <Button
            testID="pos-app-navigation-btn"
            size="small"
            fluid
            onPress={onPressPOSButton}
            color={theme.colors.primary}
            containerStyle={css(posButtonStyle)}
            title={translate('button.pos')}
            icon={'ArrowRight'}
            iconPosition="right"
          />
        )}
      </View>
    </View>
  );
};

export default BackOfficeHeader;
