import { Course } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import FormInput from '../../../../components/FormInput/FormInput';
import IconButton from '../../../../components/Button/IconButton';
import TableRow from '../../../../components/TableComponent/TableRow';
import { CourseRowStyles } from './styles';

interface CourseRowProps {
  course: Course;
  onChange: (value: Course) => void;
  onEditCourse: (id: string) => void;
  onDrag?: () => void;
  isErrorName?: boolean;
}

export type FieldKeys = 'name' | 'autoFire';

const CourseRow: React.FC<CourseRowProps> = ({
  course,
  onChange,
  onEditCourse,
  onDrag,
  isErrorName,
}) => {
  const { theme } = useFela();
  const styles = CourseRowStyles();

  const handleChange = (key: FieldKeys, value: string | boolean) => {
    onChange({ ...course, [key]: value });
  };

  return (
    <TableRow containerStyle={styles.rowContainer} normalRows={true}>
      <IconButton
        icon="draggabledots"
        iconSize={18}
        containerSize={28}
        containerStyle={styles.dragIconContainer}
        iconStyle={styles.dragIconStyle}
        onLongPress={onDrag}
      />
      <FormInput
        testID={`course-name-${course.id}`}
        value={course.name}
        containerStyle={styles.formInputContainerStyle}
        textStyle={styles.formTextStyle}
        onChangeText={value => handleChange('name', value)}
        maxLength={24}
        error={isErrorName}
      />

      <View style={styles.productCountStyle}>
        <Text style={styles.productCountText}>
          {course?.products?.length || 0}
        </Text>
      </View>
      <View style={styles.checkboxContainer}>
        <IconButton
          onPress={() => handleChange('autoFire', !course.autoFire)}
          icon={course.autoFire ? 'check-square' : 'square-full'}
          iconColor={
            course.autoFire ? theme.colors.success : theme.colors.darkGrey
          }
          iconSize={22}
          testID={`course-auto-fire-${course.id}`}
        />
      </View>
      <View style={styles.detailCourseContainer}>
        <IconButton
          testID={`course-edit-${course.id}`}
          icon="AngleRight"
          iconSize={24}
          containerSize={34}
          containerStyle={styles.rightAngleStyle}
          iconColor={theme.colors.paragraph}
          onPress={() => onEditCourse(course.id)}
        />
      </View>
    </TableRow>
  );
};

export default CourseRow;
