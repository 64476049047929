import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useCallback, useEffect, useState } from 'react';
import {
  GET_OPTION_QUERY,
  GET_OPTIONS_QUERY,
  UPDATE_OPTION_MUTATION,
  CREATE_OPTION_MUTATION,
} from '../../graphql/options';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import {
  Option,
  UpdateOptionInput,
  CreateOptionInput,
} from '@hitz-group/domain';
import { Operation } from '../../types/Operation';
import { ApolloError } from '@apollo/client';

export interface useOptionsProps {
  options: { [key: string]: Option };
  updateOption: (optionDetails: UpdateOptionInput) => void;
  createOption: (optionDetails: CreateOptionInput) => void;
  createdOptionId: string;
  loading: boolean;
  error: string | undefined;
  operation: Operation;
}

export function useOptions(optionId?: string): useOptionsProps {
  const [options, setOptions] = useState<Record<string, Option>>({});

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [createdOptionId, setCreatedOptionId] = useState('');

  const [getOption, optionGetRequest] = useLazyQuery(GET_OPTION_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const [getAllOptions, getAllOptionsRequest] = useLazyQuery(
    GET_OPTIONS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [updateOption, updateRequest] = useMutation(UPDATE_OPTION_MUTATION, {
    onError: noopHandler,
  });

  const [createOption, createRequest] = useMutation(CREATE_OPTION_MUTATION, {
    onError: noopHandler,
  });

  // Get option by id
  useEffect(() => {
    if (optionGetRequest.data) {
      const optionData = optionGetRequest.data.option as Option;
      setOptions(options => ({
        ...options,
        [optionData.id]: optionData,
      }));
    }
  }, [optionGetRequest.data]);

  useEffect(() => {
    if (optionId) {
      getOption({ variables: { id: optionId } });
      setOperation(Operation.READ);
    } else {
      getAllOptions();
      setOperation(Operation.READ);
    }
  }, [getOption, optionId, getAllOptions]);
  // Get option by id end

  useEffect(() => {
    if (getAllOptionsRequest.data) {
      const optionsData = getAllOptionsRequest.data.options as Option[];
      setOptions(prevState => {
        const optionsTemp = { ...prevState };
        optionsData.forEach(option => {
          optionsTemp[option.id] = option;
        });
        return optionsTemp;
      });
    }
  }, [getAllOptionsRequest.data]);

  // update option
  useEffect(() => {
    if (updateRequest.data) {
      const optionData = updateRequest.data.updateOption as Option;
      setOptions(options => ({ ...options, [optionData.id]: optionData }));
    }
  }, [updateRequest.data]);

  const updateOptionDetails = useCallback(
    (optionInput: UpdateOptionInput) => {
      updateOption({
        variables: {
          input: optionInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateOption],
  );
  // update option end

  // create option
  const createOptionDetails = useCallback(
    (createOptionInput: CreateOptionInput) => {
      setCreatedOptionId('');
      createOption({ variables: { input: createOptionInput } });
      setOperation(Operation.CREATE);
    },
    [createOption],
  );

  useEffect(() => {
    if (createRequest.data) {
      const createdOption = createRequest.data.createOption as Option;
      setOptions(options => ({
        ...options,
        [createdOption.id]: createdOption,
      }));
      setCreatedOptionId(createdOption.id);
    }
  }, [createRequest.data]);
  // create option end

  const error: ApolloError | undefined =
    updateRequest.error ||
    optionGetRequest.error ||
    getAllOptionsRequest.error ||
    createRequest.error;

  const loading: boolean =
    optionGetRequest.loading ||
    updateRequest.loading ||
    getAllOptionsRequest.loading ||
    createRequest.loading;

  return useMemo(
    () => ({
      options: options,
      updateOption: updateOptionDetails,
      createOption: createOptionDetails,
      createdOptionId,
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
    }),
    [
      options,
      error,
      updateOptionDetails,
      createOptionDetails,
      createdOptionId,
      operation,
      loading,
    ],
  );
}
