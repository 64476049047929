import { gql } from '@apollo/client';

const featuresQuery = `
  id
  name
  enabledByDefault
`;

const venueWithCompleteDetails = `
  id
  name
  phone
  features{
    ${featuresQuery}
  }
  address {
    line1
    line2
    city
    state
    postalCode
    country
  }
  adjustments {
    id
    name
    amount
    adjustmentUnit
    adjustmentType
    allowOnPaymentType
  }
  stores {
    id
    name
    checkoutOptions {
      enableRoundOff
      roundOffValue
    }
    deviceProfiles {
      id
      name
      defaultSaleScreen
      postSaleScreen
      enableCashManagement
      enableFloorView
      enableCourses
      enableSeatManagement
      enableOnlineOrders
      receiptPrintOption
      isDefault
      status
      menu {
        id
        name
      }
      orderTypes {
        id
        name
        code
      }
      defaultOrderType
      {
        id
        name
        code
      }
      functionMap {
        id
        name
        functionActions {
          id
          name
          action
          params
          isGroup
          groupId
          color
          order
          feature
          featureContext
        }
      }
      sections {
        id
        name
        defaultPrefix
        defaultTableShape
        isActive
        tableCount
        tables {
          id
          name
          shape
          guestCount
          position {
            top
            left
          }
        }
      }
    }
    devices {
      id
      name
      uuid
      details
      salesPrefix
      returnPrefix
      previousOrder {
        orderNumber
      }
      appVersion
      deviceProfile {
        id
      }
    }
  }
`;

export const FRAGMENTS = {
  venueDetails: gql`
    fragment VenueDetails on Venue {
      id
      name
      email
      phone
      address {
        line1
        line2
        state
        postalCode
        city
        country
      }
      isActive
      defaultTax
      receiptOptions {
        emailReceipt
        printOptions {
          printModifiersWithNoCharge
          printCustomer
          printNotes
          printTips
        }
      }
    }
  `,

  venueDetailsWithStores: gql`
    fragment VenueDetailsWithStores on Venue {
      id
      name
      email
      phone
      address {
        line1
        line2
        state
        postalCode
        city
        country
      }
      isActive
      defaultTax
      receiptOptions {
        emailReceipt
        printOptions {
          printModifiersWithNoCharge
          printCustomer
          printNotes
          printTips
        }
      }
      customerAccountSettings {
        defaultMaxOrderLimit
        defaultMaxBalanceLimit
        scheduleStatements
      }
      stores {
        id
        name
      }
      features {
        id
        name
        enabledByDefault
      }
    }
  `,
};

export const GET_VENUE_BY_ID = gql`
  query venueById($id: ID!) {
    venue(id: $id) {
      ...VenueDetailsWithStores
    }
  }
  ${FRAGMENTS.venueDetailsWithStores}
`;
export const GET_VENUE_BY_ID_AND_TAXES_QUERY = gql`
  query venueById($id: ID!) {
    venue(id: $id) {
      ...VenueDetails
    }
    taxes {
      id
      name
    }
  }
  ${FRAGMENTS.venueDetails}
`;

export const ACTIVATE_VENUE = gql`
  mutation activateVenue($input: ActivateVenueInput!) {
    activateVenue(input: $input) {
      ...VenueDetailsWithStores
    }
  }
  ${FRAGMENTS.venueDetailsWithStores}
`;

export const UPDATE_VENUE_DETAILS = gql`
  mutation updateVenue($input: UpdateVenueInput!) {
    updateVenue(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_VENUE = gql`
  mutation deleteVenue($id: ID!) {
    deleteVenue(id: $id)
  }
`;

export const CREATE_VENUE = gql`
  mutation createVenue($input: CreateVenueInput!) {
    createVenue(input: $input) {
      ${venueWithCompleteDetails}
    }
  }
`;

export const GET_USER_VENUES_QUERY = gql`
  query userVenues {
    me {
      venues {
        ...VenueDetailsWithStores
      }
    }
  }
  ${FRAGMENTS.venueDetailsWithStores}
`;
