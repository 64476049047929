import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ScrollView, View } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import Modal from '../Modal';
import TitleBar from '../../TitleBar/TitleBar';
import IconButton from '../../Button/IconButton';
import * as styles from './ItemAvailability.style';
import { useFela } from 'react-fela';
import { useModal } from '@hitz-group/rn-use-modal/lib';
//TODO Do not delete! Will need it for the next sprint/epic
// import SelectBar from '../../Button/SelectBar';
import Table from '../../ItemAvailability/Table';
import {
  Product,
  UpdateProductInput as UpdateProductAvailabilityInput,
} from '@hitz-group/domain';
import SearchWithDropdown from '../../ItemAvailability/SearchWithDropdown';
import { useSession } from '../../../../src/hooks/app/useSession';
import { useCatalogue } from '../../../../src/hooks/app/catalogue/useCatalogue';
import { cloneDeep, keyBy } from 'lodash';
import Button from '../../Button/Button';
import { IMap } from '../../../../src/screens/BackOffice/Reports/types';
import { useProducts } from '../../../../src/hooks/app/products/useProducts';

const ItemAvailability = () => {
  const { translate } = useTranslation();
  const { css } = useFela();
  const { closeModal } = useModal();
  const [session] = useSession();

  const currentStoreId = session?.currentStore?.id;
  const currentMenuId = session?.deviceProfile?.menu?.id;
  const deviceId = session?.device?.id || '';

  const { pages } = useCatalogue({
    store: currentStoreId,
    menuId: currentMenuId,
    fetchPolicy: 'cache-only',
  });
  const {
    updateProducts: updateProductsAvailabilityOnServer,
    updateProductsAvailabilityEvent,
    loading,
  } = useProducts();

  const pagesObject = useMemo(() => {
    return keyBy(pages, 'id');
  }, [pages]);

  //TODO Do not delete! Will need it for the next sprint/epic
  // const [selectedOption, setSelectedOption] = useState('ALL_ITEMS');
  const [selectedPage, setSelectedPage] = useState<string>('');
  const [moveToStartPage, setMoveToStartPage] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [products, setProducts] = useState<Product[]>([]);
  const [updatedProducts, setUpdatedProducts] = useState<IMap<Product>>({});
  const [allProductsInAPage, setAllProductsInAPage] = useState<IMap<Product>>(
    {},
  );

  const prevPageIdRef = useRef<string>('');
  const updatedProductsOnSave = useRef<
    UpdateProductAvailabilityInput[] | undefined
  >([]);

  useEffect(() => {
    const updatedProducts = updatedProductsOnSave.current;
    if (!loading && !updatedProducts) {
      closeModal();
      updatedProductsOnSave.current = [];
    } else if (!loading && updatedProducts && updatedProducts.length) {
      closeModal();
      updateProductsAvailabilityEvent({
        products: updatedProducts,
        deviceId,
        storeId: currentStoreId || '',
      });
      updatedProductsOnSave.current = [];
    }
  }, [
    closeModal,
    currentStoreId,
    deviceId,
    loading,
    updateProductsAvailabilityEvent,
  ]);

  //TODO Do not delete! Will need it for the next sprint/epic

  // const selectBarOptions = [
  //   {
  //     label: translate('functionMaps.itemAvailability.allItems'),
  //     value: 'ALL_ITEMS',
  //   },
  //   {
  //     label: translate('functionMaps.itemAvailability.unavailable'),
  //     value: 'UNAVAILABLE',
  //   },
  //   {
  //     label: translate('functionMaps.itemAvailability.tracking'),
  //     value: 'TRACKING_ITEMS',
  //   },
  // ];

  const updatedProductFieldsToSend = [
    'id',
    'name',
    'isBeingTracked',
    'isAvailable',
    'inventory',
    'availableQuantity',
    'resetQuantity',
    'isOutOfStockNotificationEnabled',
    'outOfStockAlertQuantity',
    'isNegativeQuantityAllowed',
    'isResetToAvailableEnabled',
  ];

  const sortedProducts = useCallback((unsortedProducts: Product[]) => {
    const productsCopy = cloneDeep(unsortedProducts);
    productsCopy.sort((productOne: Product, productTwo: Product) => {
      if (productOne.name > productTwo.name) {
        return 1;
      } else if (productOne.name < productTwo.name) {
        return -1;
      }
      return 0;
    });

    productsCopy.sort((productOne: Product, productTwo: Product) => {
      if (productOne.isBeingTracked && !productTwo.isBeingTracked) {
        return -1;
      } else if (!productOne.isBeingTracked && productTwo.isBeingTracked) {
        return 1;
      }
      return 0;
    });
    return productsCopy;
  }, []);

  const onPressSaveButton = async () => {
    const updatedProductsArray = Object.values(updatedProducts).map(product =>
      JSON.parse(JSON.stringify(product, updatedProductFieldsToSend)),
    );
    updateProductsAvailabilityOnServer(updatedProductsArray);
    updatedProductsOnSave.current = updatedProductsArray.length
      ? updatedProductsArray
      : undefined;
  };

  const updateProducts = (updatedProduct: Product) => {
    setUpdatedProducts(prev => {
      const allProductsInPage = {
        ...allProductsInAPage,
        [updatedProduct.id]: updatedProduct,
      };
      setAllProductsInAPage(allProductsInPage);
      searchValue
        ? setProducts(
            getFilteredProductsByName(
              Object.values(allProductsInPage),
              searchValue,
            ),
          )
        : setProducts(Object.values(allProductsInPage));
      return {
        ...prev,
        [updatedProduct.id]: updatedProduct,
      };
    });
  };

  const onUpdateIsBeingTracked = (product: Product, updatedValue: boolean) => {
    let updatedInventory;
    if (!product.inventory && updatedValue) {
      updatedInventory = { availableQuantity: 0 };
    } else if (product.inventory?.availableQuantity === null && updatedValue) {
      updatedInventory = { ...product.inventory, availableQuantity: 0 };
    } else {
      updatedInventory = {
        ...product.inventory,
        availableQuantity: product.inventory!.availableQuantity,
      };
    }
    const updatedProduct = {
      ...product,
      isBeingTracked: updatedValue,
      inventory: updatedInventory,
    };
    updateProducts(updatedProduct);
  };

  const onUpdateQuantity = (product: Product, updatedValue: string) => {
    const updatedProduct = {
      ...product,
      inventory: { ...product.inventory, availableQuantity: +updatedValue },
    };
    updateProducts(updatedProduct);
  };

  const getAllProductsInPage = useCallback(
    (pageId: string) => {
      const products = pagesObject[pageId]?.products || [];
      const variants = pagesObject[pageId]?.variants || [];
      let allVariantProducts = [] as unknown as Product[];
      variants.forEach(variant => {
        allVariantProducts = allVariantProducts.concat(variant.products);
      });

      const allProducts = products.map(product => {
        if (updatedProducts[`${product.id}`]) {
          return updatedProducts[`${product.id}`];
        }
        return product;
      });

      allVariantProducts = allVariantProducts.map(product => {
        if (updatedProducts[`${product.id}`]) {
          return updatedProducts[`${product.id}`];
        }
        return product;
      });

      return [...allProducts, ...allVariantProducts];
    },
    [pagesObject, updatedProducts],
  );

  const getFilteredProductsByName = useCallback(
    (allProducts: Product[], searchText: string) => {
      return allProducts.filter(({ name }) =>
        name.toLowerCase().includes(searchText),
      );
    },
    [],
  );

  const onChangeSelectedPage = useCallback(
    (id: string) => {
      const allProducts = sortedProducts(getAllProductsInPage(id));
      setSelectedPage(id);
      setMoveToStartPage(true);
      setAllProductsInAPage(keyBy(allProducts, 'id'));
      searchValue
        ? setProducts(getFilteredProductsByName(allProducts, searchValue))
        : setProducts(allProducts);
    },
    [
      getAllProductsInPage,
      getFilteredProductsByName,
      searchValue,
      sortedProducts,
    ],
  );

  const onChangeSearchValue = (searchText: string) => {
    const allProductsArray = Object.values(allProductsInAPage);
    if (!searchText) {
      setProducts(allProductsArray);
    } else {
      const filteredProductsByName = getFilteredProductsByName(
        allProductsArray,
        searchText,
      );
      setProducts(filteredProductsByName);
    }
    setSearchValue(searchText);
  };

  useEffect(() => {
    if (pages && pages.length > 0 && !selectedPage) {
      onChangeSelectedPage(pages[0].id);
    }
  }, [onChangeSelectedPage, pages, selectedPage]);

  useEffect(() => {
    if (selectedPage !== prevPageIdRef.current && moveToStartPage) {
      prevPageIdRef.current = selectedPage;
      setMoveToStartPage(false);
    }
  }, [moveToStartPage, selectedPage]);

  const titleLeft = (
    <IconButton
      icon="times"
      containerStyle={css(styles.cancelButtonStyle)}
      onPress={closeModal}
    />
  );

  return (
    <View style={css(styles.modalContainer)}>
      <TitleBar
        testID="item-availability-header"
        primary
        title={translate('functionMaps.itemAvailability.title')}
        titleLeft={titleLeft}
        containerStyle={css(styles.headerStyle)}
      />
      <Modal
        contentStyle={css(styles.modalStyle)}
        customBodyStyle={styles.customBodyStyle}
        showCloseButton={false}
      >
        {/*TODO Do not delete! Will need it for the next sprint/epic */}

        {/* <SelectBar
          containerStyle={css(styles.selectBarContainerStyle)}
          itemLabelStyle={css(styles.selectBarLabelStyle)}
          itemContainerStyle={css(styles.selectBarItemStyle)}
          options={selectBarOptions}
          selectedOption={selectedOption}
          onPress={setSelectedOption}
        /> */}
        <View style={css(styles.searchView)}>
          <SearchWithDropdown
            dropdownData={pages}
            setPage={onChangeSelectedPage}
            onChangeSearch={onChangeSearchValue}
          />
        </View>
        <ScrollView style={css(styles.scrollBodyStyle)}>
          <Table
            data={products}
            onUpdateIsBeingTracked={onUpdateIsBeingTracked}
            onUpdateQuantity={onUpdateQuantity}
            moveToStart={moveToStartPage}
          />
        </ScrollView>

        <Button
          testID={'save-item-info-btn'}
          secondary={true}
          key={0}
          title={translate('functionMaps.itemAvailability.saveChanges')}
          size="small"
          containerStyle={css([styles.saveButtonContainer])}
          labelStyle={css(styles.saveChangesText)}
          onPress={onPressSaveButton}
          loading={loading}
        />
      </Modal>
    </View>
  );
};

export default ItemAvailability;
