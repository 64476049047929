import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useTranslation } from '@hitz-group/localization';
import { ManageFeatureScreen } from './FeatureSettings/About/AboutTab';
import { useRoute } from '@react-navigation/native';

const Tab = createMaterialTopTabNavigator();
interface RouteParams {
  productId: string;
  isVariant: boolean;
  isVariantProduct: boolean;
  screen?: string;
}
const ManageFeaturesAndIntegrationsStack: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const params = route.params as RouteParams;
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'ManageFeatures'}
        />
      )}
      initialRouteName="AboutTab"
    >
      <Tab.Screen
        name="AboutTab"
        component={ManageFeatureScreen}
        options={{ tabBarLabel: translate('tabNames.about') }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default ManageFeaturesAndIntegrationsStack;
