import React from 'react';
import { RefundReasons } from './RefundReasons/RefundReasons';
import { ProductTypes } from './ProductTypes/ProductTypes';
import { ReportSettings } from './ReportSettings/ReportSettings';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { FeatureContext, Features } from '@hitz-group/domain';
import { useCheckFeatureEnabled } from '../../../../../src/hooks/app/features/useCheckFeatureEnabled';

const Tab = createMaterialTopTabNavigator();

const OtherSettingsStack: React.FC = () => {
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isRefundReasonsEnabled = isFeatureEnabled(
    Features.REFUND_REASON,
    FeatureContext.VENUE,
  );
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName={
        isRefundReasonsEnabled ? 'RefundReasons' : 'ProductTypes'
      }
    >
      {isRefundReasonsEnabled ? (
        <Tab.Screen
          name="RefundReasons"
          component={RefundReasons}
          options={{ tabBarLabel: 'Refund Reasons' }}
        />
      ) : null}
      <Tab.Screen
        name="ProductTypes"
        component={ProductTypes}
        options={{ tabBarLabel: 'Product Types' }}
      />
      <Tab.Screen
        name="ReportSettings"
        component={ReportSettings}
        options={{ tabBarLabel: 'Report Settings' }}
      />
    </Tab.Navigator>
  );
};

export default OtherSettingsStack;
