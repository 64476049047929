import {
  Product,
  Tag,
  TagType,
  CreateTagInput,
  PRODUCT_UNITS,
  UpdateVariantInput,
  ProductType,
  LOCALE,
  GeneralProductData,
} from '@hitz-group/domain';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import {
  GET_TAGS_QUERY,
  ADD_TAG_MUTATION,
} from '../../../../../graphql/settings';
import { useNotification } from '../../../../../hooks/Notification';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import { useTranslation } from '@hitz-group/localization';
import { UnitType } from '@hitz-group/domain';
import { useVariants } from '../../../../../hooks/app/variants/useVariants';
import { Operation } from '../../../../../types/Operation';
import { useProductTypes } from '../../../../../hooks/app/useProductTypes';
import { usePrinterProfiles } from '../../../../../hooks/app/usePrinterProfiles';
import { GeneralForm } from './GeneralForm';
import { VARIANTS_GENERAL_FRAGMENT } from '../../../../../hooks/app/variants/graphql';
import {
  encodeAlternateNameValuesToBase64,
  decodeAlternateNameValuesToBase64,
} from '@hitz-group/client-utils';
import { updateAlternateNamesWithNewValue } from '../../../../../utils/AlternateNameHelper';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';

interface GeneralVariantProps {
  variantId: string;
}
const unitsValues: { [key: string]: { label: string; value: string }[] } = {
  Volume: PRODUCT_UNITS[UnitType.Volume].map(unit => ({
    label: unit,
    value: unit,
  })),
  Weight: PRODUCT_UNITS[UnitType.Weight].map(unit => ({
    label: unit,
    value: unit,
  })),
};

export const GeneralVariant: React.FC<GeneralVariantProps> = ({
  variantId,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [productData, setProductData] = useState<GeneralProductData>(
    {} as GeneralProductData,
  );

  const [dietaryTagsArray, setDietaryTagsArray] = useState<
    { label: string; value: string }[]
  >([]);

  const {
    variants,
    error: varError,
    updateVariant,
    operation: varOperation,
    loading: varLoading,
  } = useVariants(variantId, VARIANTS_GENERAL_FRAGMENT);

  const error = varError;

  const tagsQuery = useQuery(GET_TAGS_QUERY, {
    variables: { filter: { tagType: TagType.DIETARY } },
    fetchPolicy: 'cache-and-network',
  });

  const { productTypes, getProductTypes } = useProductTypes();
  const { printerProfiles, getPrinterProfiles } = usePrinterProfiles();

  useEffect(() => {
    getProductTypes();
    getPrinterProfiles();
  }, [getProductTypes, getPrinterProfiles]);

  useEffect(() => {
    if (!varError && varOperation === Operation.UPDATE && !varLoading) {
      showNotification({
        success: true,
        message: translate('productSettings.variantUpdatedSuccessfully'),
      });
    }
  }, [varOperation, varLoading, showNotification, translate, varError]);

  const [addTag, addTagRequest] = useMutation(ADD_TAG_MUTATION, {
    onError: noopHandler,
  });

  useEffect(() => {
    if (tagsQuery.data?.tags?.length) {
      const tagsData = (tagsQuery.data?.tags as Tag[]).map(tag => ({
        label: tag.name,
        value: tag.id,
      }));
      setDietaryTagsArray(tagsData);
    }
  }, [tagsQuery.data]);

  const productTypeOptions = useMemo(() => {
    return Object.values(productTypes).map(x => ({
      value: x.id,
      label: x.name,
    }));
  }, [productTypes]);

  const printerProfileOptions = useMemo(() => {
    return Object.values(printerProfiles).map(x => ({
      value: x.id,
      label: x.name,
    }));
  }, [printerProfiles]);

  useEffect(() => {
    if (addTagRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(addTagRequest.error),
      });
    }
  }, [addTagRequest.error, showNotification]);

  useEffect(() => {
    if (addTagRequest.data) {
      showNotification({
        success: true,
        message: translate('productSettings.tagAddedSuccessfully'),
      });
      if (addTagRequest.data.createTag) {
        setDietaryTagsArray(previous => {
          const tempArray = [...previous];
          tempArray.push({
            label: addTagRequest.data.createTag.name,
            value: addTagRequest.data.createTag.id,
          });
          return tempArray;
        });
      }
    }
  }, [addTagRequest.data, showNotification, translate]);

  useEffect(() => {
    if (tagsQuery.error) {
      showNotification({
        error: true,
        message: parseApolloError(tagsQuery.error),
      });
    }
  }, [tagsQuery.error, showNotification]);

  useEffect(() => {
    if (variantId && variants[variantId]) {
      const variant = variants[variantId] as unknown as Product;
      const productModal = {
        ...variant,
        alternateNames: decodeAlternateNameValuesToBase64(
          variant.alternateNames || [],
        ),
        printerProfiles: variant.printerProfiles?.map(
          printerProfile => printerProfile.id,
        ),
      } as unknown as GeneralProductData;
      setProductData(productModal);
    }
  }, [variants, variantId]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onChange = useCallback((prop: string, value): void => {
    if (prop === 'productType') {
      setProductData(form => {
        return {
          ...form,
          productType: { id: value } as ProductType,
        };
      });
    } else if (prop === 'dietaryTags') {
      setProductData(form => {
        return {
          ...form,
          dietaryTags: value.map(
            (tagId: string) => ({ id: tagId } as unknown as Tag),
          ),
        };
      });
    } else {
      setProductData(form => {
        return {
          ...form,
          [prop]: value,
        };
      });
    }
  }, []);

  const onChangeAlternateName = useCallback(
    (prop: string, value: string, locale: LOCALE): void => {
      setProductData(previousProduct => {
        return updateAlternateNamesWithNewValue(
          previousProduct,
          locale,
          prop,
          value,
        );
      });
    },
    [],
  );

  const onAddTag = useCallback(
    (name: string): void => {
      addTag({
        variables: {
          input: { name: name, tagType: TagType.DIETARY } as CreateTagInput,
        },
      });
    },
    [addTag],
  );

  const onChangeMeasures = useCallback(
    (prop: string, value): void => {
      const product = { ...productData };
      product.measuredBy = {
        ...product.measuredBy,
        [prop]: value,
      };
      if (prop === 'unitType') {
        if (value === UnitType.Units) {
          product.measuredBy.units = '';
        } else {
          product.measuredBy.units = unitsValues[value][0]['value'];
        }
      }
      if (prop === 'defaultSize') {
        product.measuredBy.defaultSize = parseFloat(value);
      }
      setProductData(product);
    },
    [productData],
  );

  const onSaveVariant = useCallback((): void => {
    const updateVariantInput = {
      id: productData.id,
      name: productData.name,
      productType: productData?.productType?.id,
      printerProfiles: productData?.printerProfiles,
      barcode: productData.barcode,
      sku: productData.sku,
      description: productData.description,
      measuredBy: {
        units: productData.measuredBy?.units || '',
        unitType: productData.measuredBy?.unitType || UnitType.Units,
        defaultSize: productData.measuredBy?.defaultSize || 1,
      },
      dietaryTags: (productData.dietaryTags || []).map(t => t.id),
      alternateNames: encodeAlternateNameValuesToBase64(
        productData.alternateNames || [],
      ),
    } as unknown as UpdateVariantInput;

    updateVariant(updateVariantInput);
  }, [productData, updateVariant]);

  if (varLoading) {
    return <LoadingIndicator />;
  }

  return (
    <GeneralForm
      isProduct={false}
      onAddTag={onAddTag}
      onChange={onChange}
      onChangeMeasures={onChangeMeasures}
      onChangeAlternateName={onChangeAlternateName}
      onSave={onSaveVariant}
      productData={productData}
      dietaryTagsOptions={dietaryTagsArray}
      productTypeOptions={productTypeOptions}
      printerProfileOptions={printerProfileOptions}
    />
  );
};
