import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { KeyboardAvoidingView, View } from 'react-native';
import Modal from '../Modal';
import { isIos } from '../../../common/theme';
import {
  getCountry,
  getCountryCodeByPhoneCode,
  usePhoneNumber,
  translate,
} from '@hitz-group/localization';
import { styles } from './style';
import FormInput from '../../FormInput/FormInput';
import {
  isValidEmail,
  isValidName,
  isValidNumber,
  trimString,
} from '../../../utils/validator';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import { useNotification } from '../../../hooks/Notification';
import Button from '../../Button/Button';
import { isNotEmpty } from '@hitz-group/client-utils';
import { CreateCustomerRequest, Customer } from '@hitz-group/domain';
import { UpdateCustomerRequest } from '../../../hooks/orders/useCustomers';
import { Operation } from '../../../types/Operation';
import { DEFAULT_COUNTRY_CODE } from '../../../constants';
import { useOrganization } from '../../../hooks/app/useOrganization';
import { useSession } from '../../../hooks/app/useSession';

interface BackOfficeCustomerModalProps {
  customerInfo?: Customer;
  action: string;
  addNewCustomer: (input: Partial<CreateCustomerRequest>) => Promise<void>;
  updateCustomer: (input: UpdateCustomerRequest) => Promise<void>;
  checkEmailExists: (email?: string, customerId?: string) => boolean;
}

interface ICustomerForm {
  id?: string;
  firstName: string;
  lastName: string;
  line1: string;
  email: string;
  city: string;
  postalCode: string;
  countryCode: string;
  phone: string;
}

const BackOfficeCustomerModal: React.FC<BackOfficeCustomerModalProps> = ({
  action,
  customerInfo,
  addNewCustomer,
  updateCustomer,
  checkEmailExists,
}) => {
  const { css, theme } = useFela();
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  const [form, setForm] = useState<ICustomerForm>({
    id: '',
    firstName: '',
    lastName: '',
    line1: '',
    email: '',
    city: '',
    postalCode: '',
    countryCode: DEFAULT_COUNTRY_CODE,
    phone: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const [session] = useSession();

  const {
    organization,

    getOrganizationById,
  } = useOrganization();
  useEffect(() => {
    if (session?.currentOrganization?.id) {
      getOrganizationById(session.currentOrganization.id);
    }
  }, [session, getOrganizationById]);
  useEffect(() => {
    const _phoneCode = (organization?.phone || '').split(' ')[0];
    if (_phoneCode !== '') {
      setForm(formData => ({
        ...formData,
        countryCode: getCountryCodeByPhoneCode(_phoneCode),
      }));
    }
  }, [organization?.phone]);
  useEffect(() => {
    if (
      action === Operation.UPDATE &&
      customerInfo &&
      customerInfo.preferredAddress
    ) {
      const { isoCountryCode, ...address } = customerInfo.preferredAddress;
      setForm({
        id: customerInfo.id,
        firstName: customerInfo.firstName,
        lastName: customerInfo.lastName,
        line1: address.line1,
        city: address.city,
        postalCode: address.postalCode,
        countryCode: isoCountryCode ? isoCountryCode : DEFAULT_COUNTRY_CODE,
        phone: customerInfo.phone,
        email: customerInfo.email,
      });
    }
  }, [action, customerInfo]);

  useEffect(() => {
    if (errorMessage !== '') {
      showNotification({
        error: true,
        message: errorMessage,
      });
      setErrorMessage('');
    }
  }, [errorMessage, showNotification]);

  const isPhoneValid = isValidNumber(
    getFullFormattedPhoneNumber(form.countryCode, form.phone),
  );

  const onChangeFormInput = useCallback((prop: string, value: string) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const setCustomerData = useCallback(() => {
    const { firstName, lastName, email, phone, id } = form;
    if (trimString(firstName) === '') {
      setErrorMessage(
        translate('backOfficeCustomerModal.errorFirstNameMissing'),
      );
      return;
    } else if (email === '' && phone.length === 0) {
      setErrorMessage(translate('customer.emptyEmailandPhoneMessage'));
      return;
    } else if (email && !isValidEmail(email)) {
      setErrorMessage(translate('customer.invalidEmailMessage'));
      return;
    } else if (phone && !isPhoneValid) {
      setErrorMessage(translate('customer.invalidPhoneMessage'));
      return;
    } else if (checkEmailExists(email, id)) {
      setErrorMessage(translate('customer.emailExists'));
      return;
    }
    const preferredAddress = {
      line1: form.line1,
      city: form.city,
      postalCode: form.postalCode,
      isoCountryCode: form.countryCode,
    };

    const customerAccountDetails = {
      currentBalance: 0,
      accountPayment: false,
    };
    const customer = {
      id: form.id ? form.id : undefined,
      firstName,
      lastName,
      email,
      phone: getFullFormattedPhoneNumber(form.countryCode, form.phone),
      preferredAddress,
      customerAccountDetails,
    } as Customer;
    return customer;
  }, [checkEmailExists, getFullFormattedPhoneNumber, form, isPhoneValid]);

  const createCustomer = useCallback(async () => {
    const customer = setCustomerData();
    if (customer) {
      await addNewCustomer(customer);
    }
  }, [addNewCustomer, setCustomerData]);

  const editCustomer = useCallback(async () => {
    const customer = setCustomerData();
    if (customer) {
      await updateCustomer(customer);
    }
  }, [setCustomerData, updateCustomer]);

  return (
    <KeyboardAvoidingView behavior={isIos ? 'padding' : undefined}>
      <View style={css(styles.addCustomerContainerStyle)}>
        <Modal
          title={translate(
            action === Operation.CREATE
              ? 'backOfficeCustomerModal.createTitle'
              : 'backOfficeCustomerModal.editTitle',
          )}
          contentStyle={css(styles.containerStyle)}
          showCloseButton={false}
          headerTextStyle={css([
            styles.modalHeaderTextStyle,
            action === Operation.UPDATE
              ? styles.editCustomerTextColor
              : styles.createCustomerTextColor,
          ])}
          customBodyStyle={styles.customBodyStyle}
        >
          <View style={css(styles.rowStyle)}>
            <FormInput
              title={`${translate('form.firstName')} *`}
              alignTitle={'left'}
              value={form.firstName}
              onChangeText={onChangeFormInput.bind(null, 'firstName')}
              verified={isValidName(form.firstName)}
              placeholder={'Jenny'}
              containerStyle={css(styles.formInputContainerStyle)}
              textStyle={css(styles.textStyle)}
            />
            <FormInput
              title={translate('form.lastName')}
              alignTitle={'left'}
              value={form.lastName}
              onChangeText={onChangeFormInput.bind(null, 'lastName')}
              verified={isValidName(form.lastName)}
              placeholder={'Murtaugh'}
              containerStyle={css(styles.formInputContainerStyle)}
              textStyle={css(styles.textStyle)}
            />
          </View>
          <View style={css(styles.rowStyle)}>
            <FormInput
              title={`${translate('form.phoneNumber')}`}
              type="phone"
              alignTitle={'left'}
              value={form.phone}
              country={form.countryCode}
              verified={isPhoneValid}
              onChangeText={onChangeFormInput.bind(null, 'phone')}
              onSelectChange={onChangeFormInput.bind(null, 'countryCode')}
              onPressCountry={onChangeFormInput.bind(null, 'countryCode')}
              placeholder={'+61 1234 5689'}
              prefix={{
                text: getCountry(form.countryCode)?.phone,

                textStyle: !isPhoneValid
                  ? css(styles.phonePrefixStyle, styles.phonePrefixErrorBgStyle)
                  : css(styles.phonePrefixStyle),
              }}
              textInputStyle={css(styles.phoneTextInputStyle)}
              containerStyle={css(styles.formInputContainerStyle)}
              textStyle={css(styles.textStyle)}
            />
            <FormInput
              title={`${translate('common.emailAddress')}`}
              alignTitle={'left'}
              value={form.email}
              onChangeText={onChangeFormInput.bind(null, 'email')}
              verified={
                isValidEmail(form.email) &&
                !checkEmailExists(form.email, form.id)
              }
              placeholder={'jenny@email.com'}
              containerStyle={css(styles.formInputContainerStyle)}
              textStyle={css(styles.textStyle)}
            />
          </View>

          <View style={css(styles.rowStyle)}>
            <FormInput
              title={translate('backOfficeCustomerModal.address')}
              alignTitle={'left'}
              value={form.line1}
              onChangeText={onChangeFormInput.bind(null, 'line1')}
              verified={isNotEmpty(form.line1)}
              placeholder={'102 flora street Australia'}
              containerStyle={css(styles.formInputContainerStyle)}
              textStyle={css(styles.textStyle)}
            />
            <View style={css(styles.inrowStyle)}>
              <FormInput
                title={translate('form.suburb')}
                alignTitle={'left'}
                value={form.city}
                onChangeText={onChangeFormInput.bind(null, 'city')}
                verified={isValidName(form.city)}
                placeholder={'City'}
                containerStyle={css(styles.subAddressContainerText)}
                textStyle={css(styles.cityLableStyle)}
              />
              <FormInput
                title={translate('form.postCode')}
                alignTitle={'left'}
                value={form.postalCode}
                onChangeText={onChangeFormInput.bind(null, 'postalCode')}
                verified={isNotEmpty(form.postalCode)}
                placeholder={'100043'}
                containerStyle={css(styles.pinCodeAddressContainerText)}
                maxLength={6}
                textStyle={css(styles.codeLableStyle)}
              />
            </View>
          </View>
          <View style={css(styles.buttonContainerStyle)}>
            <Button
              labelStyle={css(styles.dismissLabelStyle)}
              title={translate('backOfficeCustomerModal.dismiss')}
              onPress={closeModal}
            />
            <Button
              labelStyle={css([
                styles.btnLabelStyle,
                action === Operation.UPDATE
                  ? styles.editCustomerTextColor
                  : styles.createCustomerTextColor,
              ])}
              color={
                action === Operation.UPDATE
                  ? theme.colors.highlighted
                  : theme.colors.successLight
              }
              title={translate(
                action === Operation.CREATE
                  ? 'backOfficeCustomerModal.createCustomerBtn'
                  : 'backOfficeCustomerModal.editBtn',
              )}
              onPress={
                action === Operation.CREATE ? createCustomer : editCustomer
              }
            />
          </View>
        </Modal>
      </View>
    </KeyboardAvoidingView>
  );
};

export default BackOfficeCustomerModal;
