import React, { useState } from 'react';
import { View, ViewStyle } from 'react-native';
import { StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import { useIsMounted } from './../../useIsMounted';

interface Props {
  start: { x: number; y: number };
  end: { x: number; y: number };
  colors: Array<string | number>;
  locations: Array<number>;
  useAngle?: boolean;
  angle?: number;
  style?: ViewStyle;
  children?: React.ReactNode;
}

const Gradient: React.FC<Props> = ({
  start,
  end,
  colors,
  locations,
  useAngle = false,
  angle = 0,
  style,
  children,
}) => {
  const { css } = useFela();
  const isMounted = useIsMounted();

  const gradientStyle: StyleFn = () => ({
    backgroundImage: `linear-gradient(${getAngle()},${getColors()})`,
  });

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const measure = ({ nativeEvent }: any) => {
    isMounted() && setWidth(nativeEvent.layout.width);
    isMounted() && setHeight(nativeEvent.layout.height);
  };

  const getAngle = () => {
    if (useAngle) {
      return angle + 'deg';
    }
    const anglePoint =
      Math.atan2(width * (end.y - start.y), height * (end.x - start.x)) +
      Math.PI / 2;
    return anglePoint + 'rad';
  };

  const getColors = () =>
    colors
      .map((color, index) => {
        const location = locations[index];
        let locationStyle = '';
        if (location) {
          locationStyle = ' ' + location * 100 + '%';
        }
        return color + locationStyle;
      })
      .join(',');

  return (
    <View style={[style, css(gradientStyle)]} onLayout={measure}>
      {children}
    </View>
  );
};
export default Gradient;
