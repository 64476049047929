import { StyleFn, CreateProductInput, Money } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import IconButton from '../../../components/Button/IconButton';
import { useTranslation } from '@hitz-group/localization';
import DropDown from '../../../components/FormInput/DropDown';
import { MenuTile } from '../menuTypes';

export interface CreateProductInterface extends CreateProductInput {
  sellingPrice: Money;
  sellingTax: string;
  isVariant: boolean;
  editMore?: boolean;
  taxInclusive: boolean;
}

const menuProductModalStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: '97%',
  backgroundColor: theme.colors.white,
  marginLeft: 10,
  marginTop: 10,
  paddingHorizontal: 70,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  alignItems: 'center',
});

const titleRowStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: 60,
  marginLeft: theme.spacing.medium,
  flexDirection: 'row',
});

const titleTextStyle: StyleFn = ({ theme }) => ({
  lineHeight: 24,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.medium,
  alignSelf: 'center',
});

const modalBodyStyle: StyleFn = ({ theme }) => ({
  width: 260,
  flex: 1,
  paddingHorizontal: theme.padding.medium,
});

const closeContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  marginLeft: 'auto',
  left: -theme.spacing.big,
});

const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  height: theme.input.height,
});

const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  borderWidth: 1,
});

const halfSizedStyleRight1: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  marginBottom: theme.spacing.small,
  width: '100%',
});

interface BasicSidePanelProps {
  onClose?: () => void;
  children: React.ReactNode;
  onChangeType?: (type: MenuTile) => void;
  type: MenuTile;
}

export const BasicSidePanel: React.FC<BasicSidePanelProps> = ({
  onClose,
  children,
  type,
}: BasicSidePanelProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  return (
    <View style={css(menuProductModalStyle)}>
      <View style={css(titleRowStyle)}>
        <Text style={css(titleTextStyle)}>
          {translate('menuEditor.editTile')}
        </Text>
        <IconButton
          icon="times"
          primary
          iconSize={25}
          containerStyle={css(closeContainerStyle)}
          onPress={onClose}
        />
      </View>

      <View style={css(modalBodyStyle)}>
        <DropDown
          title={translate('menuEditor.type')}
          values={[
            {
              label: 'Product',
              value: 'Product',
            },
            {
              label: 'Page',
              value: 'Page',
            },
          ]}
          extraStyle={css(dropdownExtraStyle)}
          extraMainViewStyle={css(dropDownMainViewStyle)}
          selectedValue={type}
          style={css(halfSizedStyleRight1)}
        />
        {children}
      </View>
    </View>
  );
};
