import React from 'react';
import { ProductType, ProfitMargin } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../../components/FormInput/FormInput';
import IconButton from '../../../../../components/Button/IconButton';
import TableRow from '../../../../../components/TableComponent/TableRow';
import DropDown from '../../../../../components/DropDown/DropDown';
import { View } from 'react-native';
import { ProfitMarginRowStyles } from './ProfitMargins.styles';

interface Props {
  index: number;
  profitMargin: ProfitMargin;
  productTypes: ProductType[];
  onChange: (prop: string, index: number, value: string) => void;
  onDelete: (index: number) => void;
}

export const ProfitMarginRow: React.FC<Props> = ({
  index,
  profitMargin,
  productTypes,
  onChange,
  onDelete,
}) => {
  const { translate } = useTranslation();
  const styles = ProfitMarginRowStyles();
  const rowStyle = {
    zIndex: 1000 - 10 * index,
  };

  if (
    profitMargin.productTypeId &&
    !productTypes.some(pt => pt.id === profitMargin.productTypeId)
  )
    return null;

  return (
    <View style={rowStyle}>
      <TableRow normalRows={true} containerStyle={styles.rowContainerStyle}>
        <View style={styles.productTypeViewStyle}>
          <DropDown
            options={productTypes.map(productType => ({
              value: productType.id,
              label: productType.name,
            }))}
            selectedValue={profitMargin.productTypeId}
            containerStyle={styles.fielddropDownContainerStyle}
            onValueChange={onChange.bind(null, 'productTypeId', index)}
            itemsContainerStyle={styles.itemContainer}
            extraPopoverStyle={styles.extraPopoverStyle}
          />
        </View>
        <View style={styles.marginViewStyle}>
          <FormInput
            placeholder={translate('backOfficeProfitMargins.marginPlaceholder')}
            value={`${profitMargin.margin || 0}`}
            type={'number'}
            containerStyle={styles.fieldInputContainerStyle}
            textStyle={styles.formTextStyle}
            onBlur={() =>
              onChange(
                'margin',
                index,
                (parseFloat(profitMargin.margin.toString()) || 0).toFixed(2),
              )
            }
            onChangeText={onChange.bind(null, 'margin', index)}
            prefix={{
              text: '%',
              textStyle: styles.textAvatarProductItemStyle,
            }}
          />
        </View>
        <View style={styles.actionsViewStyle}>
          <IconButton
            icon="TrashAlt"
            iconSize={24}
            containerSize={34}
            containerStyle={styles.closeIconContainerStyle}
            iconStyle={styles.closeIconStyle}
            onPress={onDelete.bind(null, index)}
          />
        </View>
      </TableRow>
    </View>
  );
};
