import FormInput from '../../../../components/FormInput/FormInput';
import React, { useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { jobRoleSettingsUtility } from '../../../../state/jobRoleSettingsUtility';
import scale, { isAndroid } from '../../../../common/theme';
import Icon from '../../../../components/Icon/Icon';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';

const iconContainer: StyleFn = ({ theme }) => ({
  paddingRight: theme.padding.medium,
});

const formInputContainerStyle = () => ({
  width: '100%',
  marginVertical: 20,
  marginBottom: 15,
});

const formLargeTextStyle = () => ({
  width: 260,
  height: isAndroid ? scale.moderateScale(35) : scale.moderateScale(30),
});

const SearchIcon = () => {
  const { css, theme } = useFela();
  return (
    <View style={css(iconContainer)}>
      <Icon name="search" size={20} color={theme.colors.text} />
    </View>
  );
};

const SearchPermissionInput = () => {
  const { translate } = useTranslation();
  const { css } = useFela();
  const [searchText, setSearchText] = useState('');

  const onChangeSearchText = (value: string) => {
    setSearchText(value);
    jobRoleSettingsUtility.search = value;
  };

  return (
    <FormInput
      placeholder={translate('backofficeManageRoles.form.searchPermission')}
      value={searchText}
      alignTitle="left"
      containerStyle={css(formInputContainerStyle)}
      onChangeText={onChangeSearchText}
      textInputStyle={css(formLargeTextStyle)}
      testID="search-permission-input"
      fluid
      action={<SearchIcon />}
    />
  );
};

export default SearchPermissionInput;
