import React from 'react';
import CourseDetail from './CourseDetail/CourseDetail';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
import { translate } from '@hitz-group/localization';

const Tab = createMaterialTopTabNavigator();

const CourseStack: React.FC = () => {
  const route = useRoute();
  const params = route.params as { courseId: string; title: string };

  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => {
        return <TabBar tabBar={props} />;
      }}
      initialRouteName="CourseDetails"
    >
      <Tab.Screen
        name="CourseDetails"
        component={CourseDetail}
        options={{ tabBarLabel: translate('backOfficeProducts.createNew') }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default CourseStack;
