import { IntegrationApps, Feature } from '@hitz-group/domain';
import React, { useCallback, useEffect } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { View, ScrollView } from 'react-native';
import { FeatureTile } from './components/FeatureTile';
import { useNavigation } from '@react-navigation/native';
import { useFeatures } from '../../../../hooks/app/features/useFeatures';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import * as styles from './IntegrationTiles.styles';

const INTEGRATIONS_SETTINGS_FILES: Record<string, string> = {
  [IntegrationApps.DOSHII]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.DELIVERIT]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.OOLIO_STORE]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.DELIVERECT]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.XERO]: 'XeroSettings',
  [IntegrationApps.DEPUTY]: 'DeputySettings',
};

export const IntegrationTiles: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const onPressIntegrationTile = useCallback(
    (integration: Feature) => {
      navigation.navigate(INTEGRATIONS_SETTINGS_FILES[integration.id], {
        app: integration.app,
      });
    },
    [navigation],
  );

  const { integrations, getIntegrations, loadings } = useFeatures('');

  useEffect(() => {
    getIntegrations();
  }, [getIntegrations]);

  const addIntegrationTile = useCallback(
    (integration: Feature) => {
      return (
        <View style={css(styles.pageStyle)}>
          <FeatureTile
            feature={integration}
            onPress={() => onPressIntegrationTile(integration)}
          />
        </View>
      );
    },
    [css, onPressIntegrationTile],
  );

  if (loadings) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.integrationsPage', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(styles.pageContainerStyle)}>
        <ScrollView
          testID="general-settings-screen"
          style={css(styles.pageContainer)}
        >
          <View style={css(styles.container)}>
            {integrations.map(addIntegrationTile)}
          </View>
        </ScrollView>
      </View>
    </>
  );
};
