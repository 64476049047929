import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { useFela } from 'react-fela';
import IconButton from '../../../../../../components/Button/IconButton';
import DropDown from '../../../../../../components/FormInput/DropDown';
import { useNavigation } from '@react-navigation/native';
import * as styles from './StoreRow.styles';
import { IntegrationApps } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';

// const getNavigationComponentName = (app: IntegrationApps): string => {
//   if (app === IntegrationApps.DELIVERIT) return 'DeliveritSettings';
//   else if (app === IntegrationApps.DOSHII) return 'DoshiiStoreSettings';
//   else return 'OolioOnlineStoreStoreSettings';
// };

interface StoreRowProps {
  isEnabled: boolean;
  name: string;
  menu: string;
  pricingGroup: string;
  menusOptions: { label: string; value: string }[];
  pricingGroupsOptions: { label: string; value: string }[];
  onChange: (storeId: string, key: string, value: string | boolean) => void;
  storeId: string;
  id: string;
  integrationApp: IntegrationApps;
  onOpenStoreInfo: (storeId: string) => void;
}
export const StoreRow: React.FC<StoreRowProps> = ({
  isEnabled,
  menu,
  name,
  pricingGroup,
  menusOptions,
  pricingGroupsOptions,
  id: integrationId,
  onChange,
  storeId,
  integrationApp,
  onOpenStoreInfo,
}: StoreRowProps) => {
  const navigation = useNavigation();
  const { translate } = useTranslation();

  const navigateToStoreSettings = useCallback(() => {
    navigation.navigate('OnlineOrderIntegrationStoreSettings', {
      storeId,
      app: integrationApp,
    });
  }, [integrationApp, navigation, storeId]);

  const isDeliverectApp = integrationApp === IntegrationApps.DELIVERECT;

  const isFieldEnabled = isEnabled && !!integrationId;
  const { css, theme } = useFela({
    isShrink: isDeliverectApp,
    active: isFieldEnabled,
  });

  return (
    <View style={css(styles.rowStyle)}>
      {!isDeliverectApp && (
        <IconButton
          testID="toggle-active-store"
          primary
          icon={(isEnabled && 'toggle-on') || 'toggle-off'}
          iconSize={26}
          containerSize={34}
          iconColor={isEnabled ? theme.colors.green : theme.colors.paragraph}
          containerStyle={css(styles.iconContainerStyle)}
          onPress={() =>
            onChange(storeId || integrationId, 'isActive', !isEnabled)
          }
        />
      )}
      <View style={css(styles.defaultContainerStyle)}>
        <Text style={css(styles.defaultTextStyle)}>{name}</Text>
      </View>
      <DropDown
        values={menusOptions}
        selectedValue={menu}
        style={
          isFieldEnabled
            ? css(styles.formDropDownContainerStyle)
            : css(styles.deActivatedDropDownContainerStyle)
        }
        extraStyle={css(styles.dropdownExtraStyle)}
        extraMainViewStyle={css(styles.dropDownMainViewStyle)}
        onValueChange={value =>
          isFieldEnabled && onChange(storeId, 'menu', value)
        }
      />
      <DropDown
        values={pricingGroupsOptions}
        selectedValue={pricingGroup}
        style={
          isFieldEnabled
            ? css(styles.formDropDownContainerStyle)
            : css(styles.deActivatedDropDownContainerStyle)
        }
        extraStyle={css(styles.dropdownExtraStyle)}
        extraMainViewStyle={css(styles.dropDownMainViewStyle)}
        onValueChange={value =>
          isFieldEnabled && onChange(storeId, 'pricingGroup', value)
        }
      />
      {isDeliverectApp && (
        <>
          <View
            style={css(styles.defaultContainerStyle, styles.connectedStatus)}
            testID={'connect-status'}
          >
            <Text style={css(styles.connectedStatusText)}>
              {isFieldEnabled
                ? translate('common.active')
                : translate('common.inactive')}
            </Text>
          </View>

          <IconButton
            icon="info-circle"
            testID="info-icon"
            iconSize={18}
            containerSize={34}
            containerStyle={css(styles.infoIconStyle)}
            iconColor={theme.colors.blue}
            onPress={() => onOpenStoreInfo(storeId)}
          />
        </>
      )}

      {isFieldEnabled && (
        <IconButton
          primary
          icon={'Setting'}
          iconSize={26}
          containerSize={34}
          iconColor={theme.colors.blue}
          containerStyle={css(styles.settingsContainerStyle)}
          onPress={navigateToStoreSettings}
        />
      )}
    </View>
  );
};
