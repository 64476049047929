import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import SearchBar from '../SearchBar/SearchBar';
import { useTranslation } from '@hitz-group/localization';
import { useFela } from 'react-fela';
import * as styles from './SearchWithDropdown.style';
import DropDown from '../DropDown/DropDown';
import { Page } from '@hitz-group/domain';

interface Props {
  dropdownData: Partial<Page>[];
  setPage: (pageId: string) => void;
  onChangeSearch: (searchString: string) => void;
}

const SearchWithDropdown: React.FC<Props> = ({
  dropdownData,
  setPage,
  onChangeSearch,
}) => {
  const { translate } = useTranslation();
  const { css, theme } = useFela();

  const dropdownOptions = useMemo(() => {
    return dropdownData.map(page => ({
      label: page.name || '',
      value: page.id || '',
    }));
  }, [dropdownData]);

  const [dropDownSelectedValue, setDropdownSelectedValue] =
    useState<string>('');

  useEffect(() => {
    if (!dropDownSelectedValue && dropdownData.length > 0) {
      setDropdownSelectedValue(dropdownData[0].id || '');
    }
  }, [dropDownSelectedValue, dropdownData, dropdownData.length]);

  const onDropdownValueChange = (id: string) => {
    setDropdownSelectedValue(id);
    setPage(id);
  };

  const onSearchTextChange: (value: string) => void = (value: string) => {
    onChangeSearch(String(value).toLowerCase());
  };

  return (
    <View style={css(styles.dropdownSearchBarStyle)}>
      <DropDown
        selectedValue={dropDownSelectedValue}
        options={dropdownOptions}
        containerStyle={css(styles.dropDownStyle)}
        extraPopoverStyle={css(styles.extraPopoverStyle)}
        touchableStyle={css(styles.dropdownTouchableStyle)}
        onValueChange={id => onDropdownValueChange(id)}
      />
      <SearchBar
        placeholder={translate('functionMaps.itemAvailability.search')}
        containerStyle={css(styles.searchContainerStyle)}
        textInputStyle={css(styles.searchTextInputStyle)}
        iconColor={theme.colors.paragraph}
        placeholderColor={theme.colors.paragraph}
        onChange={onSearchTextChange}
      />
    </View>
  );
};

export default SearchWithDropdown;
