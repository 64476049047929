import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { OverviewScreen } from './OverviewScreen';

const Stack = createStackNavigator();

const AccountStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="Overview"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={OverviewScreen} name="Overview" />
  </Stack.Navigator>
);

export default AccountStack;
