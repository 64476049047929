import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSession } from './useSession';
import { useCatalogue } from './catalogue/useCatalogue';
import { useOrders } from './orders/useOrders';
import { useDevicePushNotification } from './useDevicePushNotification';
import { useUser } from './useUser';
import { NetworkStatus } from '@apollo/client';
import { useSetUserSession } from './useSetUserSession';
import { useNotification } from '../Notification';
import { useTranslation } from '@hitz-group/localization';

export interface useSettingsProps {
  syncApp: () => void;
  loading: boolean;
}

export const useSettingsSync = (): useSettingsProps => {
  const [session] = useSession();
  const currentMenuId = session?.deviceProfile?.menu?.id;
  const { refetch: refetchCatalog, networkStatus: catalogNetworkStatus } =
    useCatalogue({ menuId: currentMenuId });
  const { refetchOrdersFromServer } = useOrders();
  const { user, refetchUser, networkStatus: userNetworkStatus } = useUser();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();

  const { registerPushNotification } = useDevicePushNotification();
  const { setUserSession } = useSetUserSession();
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const syncApp = useCallback((): void => {
    refetchCatalog();
    refetchOrdersFromServer();
    refetchUser();
    if (session?.device?.id && !session?.device?.pushNotificationToken) {
      registerPushNotification(session.device?.id);
    }
    setTimeout(() => setSyncLoading(true), 100);
  }, [
    refetchCatalog,
    refetchOrdersFromServer,
    refetchUser,
    registerPushNotification,
    session.device,
  ]);

  useEffect(() => {
    if (
      user &&
      syncLoading &&
      userNetworkStatus &&
      userNetworkStatus == NetworkStatus.ready &&
      catalogNetworkStatus &&
      catalogNetworkStatus == NetworkStatus.ready
    ) {
      setUserSession(user);
      setSyncLoading(false);
    }
  }, [
    setUserSession,
    user,
    catalogNetworkStatus,
    syncLoading,
    userNetworkStatus,
  ]);

  useEffect(() => {
    if (
      (userNetworkStatus === NetworkStatus.error ||
        catalogNetworkStatus === NetworkStatus.error) &&
      syncLoading
    ) {
      setSyncLoading(false);
      showNotification({
        error: true,
        message: `${translate('settings.syncErrorOffline')}`,
      });
    }
  }, [
    userNetworkStatus,
    catalogNetworkStatus,
    syncLoading,
    translate,
    showNotification,
  ]);

  return useMemo(
    () => ({
      syncApp: syncApp,
      loading: syncLoading,
    }),
    [syncApp, syncLoading],
  );
};
