import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import DevicesListContainer from '../../StoreSettings/Devices/DevicesListContainer';
import { DeviceProfiles } from '../../StoreSettings/DeviceProfiles';
import PrintersListContainer from '../../StoreSettings/Printers/PrintersListContainer';
import { Checkout } from '../../StoreSettings/Checkout';
import { ProfitMarginsContainer } from '../../StoreSettings/ProfitMargins/ProfitMarginsContainer';
import { Details } from '../../StoreSettings/Details';
import Adjustments from '../Adjustments/Adjustments';
import { CashManagement } from '../CashManagement/CashManagement';
import { useSession } from '../../../../../../src/hooks/app/useSession';
import { SectionManagement } from '../SectionManagement/SectionManagement';
import { AccountSales } from '../AccountSales/AccountSales';
import { translate } from '@hitz-group/localization';

const Tab = createMaterialTopTabNavigator();
const MyStoreSettingsTabs: React.FC = () => {
  const [session] = useSession();

  const params = {
    storeId:
      session.currentStore?.id ||
      (session?.user?.venues && session?.user?.venues[0].stores[0].id),
    venueId:
      session.currentVenue?.id ||
      (session?.user?.venues && session?.user?.venues[0].id),
  };

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} showBack={false} />
      )}
      initialRouteName={'Details'}
    >
      <Tab.Screen name="Details" component={Details} initialParams={params} />
      <Tab.Screen
        name="Adjustments"
        component={Adjustments}
        initialParams={params}
      />
      <Tab.Screen
        name="CashManagement"
        component={CashManagement}
        options={{
          tabBarLabel: translate('storesSettings.tabNames.manageCash'),
        }}
        initialParams={params}
      />

      <Tab.Screen
        name="Devices"
        component={DevicesListContainer}
        initialParams={params}
      />
      <Tab.Screen
        name={translate('storesSettings.tabNames.deviceProfiles')}
        options={{
          tabBarLabel: translate('storesSettings.tabNames.deviceProfiles'),
        }}
        component={DeviceProfiles}
        initialParams={params}
      />
      <Tab.Screen
        name="SectionManagement"
        component={SectionManagement}
        options={{ tabBarLabel: 'Sections & Tables' }}
        initialParams={params}
      />

      <Tab.Screen
        name="Printers"
        component={PrintersListContainer}
        initialParams={params}
      />
      <Tab.Screen name="Checkout" component={Checkout} initialParams={params} />
      <Tab.Screen
        name="ProfitMargins"
        options={{
          tabBarLabel: translate('storesSettings.tabNames.profileMargins'),
        }}
        component={ProfitMarginsContainer}
        initialParams={params}
      />
      <Tab.Screen
        name="AccountSales"
        options={{
          tabBarLabel: translate('storesSettings.tabNames.customerAccount'),
        }}
        component={AccountSales}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default MyStoreSettingsTabs;
