import { translate, useCurrency } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import React, { useCallback, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useNotification } from '../../../hooks/Notification';
import { addToValue } from '../../../utils/keypadHelper';
import InputDisplay from '../../InputDisplay/InputDisplay';
import NumberInput, { NumberKey } from '../../NumberInput/NumberInput';
import Title from '../../Title/Title';
import TitleBar from '../../TitleBar/TitleBar';
import { FelaComponent } from 'react-fela';
import IconButton from '../../Button/IconButton';
import { ButtonProps } from '../../Button/Button';
import { getFastCashOptions } from '../../../utils/fastCashOptions';
import { roundOffByValue } from '@hitz-group/client-utils';
import { MAX_KEYPAD_INPUT } from '../../../types/Common';

export interface CashPaymentModalProps {
  amountDue: number;
  receivingAmount: number;
  onSubmit: (amount: number) => void;
  enableRoundOff?: boolean;
  roundOffValue?: number;
}

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const CloseButtons: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

const keypadStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.medium * 1.25,
});

const keyRowStyle: StyleFn = () => ({
  marginTop: '4%',
});

const numberInputStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing.medium * 1.25,
});
const headerStyle: StyleFn = ({ theme }) => ({
  width: 445,
  marginTop: theme.spacing.small,
  alignSelf: 'center',
});

const containerStyle: StyleFn = ({ theme }) => ({
  width: 445,
  minHeight: 550,
  alignSelf: 'center',
  height: 550,
  padding: 25,
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.small,
});

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  keypad: {
    flex: 0.7,
  },
});

const buttonContainerStyle: StyleFn = ({ theme, rightAlign }) => ({
  flexDirection: 'row',
  justifyContent: rightAlign ? 'space-between' : 'center',
  alignItems: 'center',
  flex: 0.9,
  width: 390,
  minHeight: 60,
  borderRadius: theme.radius.small,
  padding: 1,
  backgroundColor: theme.colors.success,
  paddingHorizontal: theme.padding.medium,
});

const changeContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successDark,
  padding: theme.padding.medium,
  margin: theme.spacing.small,
  borderRadius: theme.radius.small,
});

const remainingContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.orangeLight,
  padding: theme.padding.medium,
  margin: theme.spacing.small,
  borderRadius: theme.radius.small,
});

const remainingLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.orange,
});

const inputContainerStyle: StyleFn = () => ({
  height: 80,
});
const btnTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
});

const redoStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.primaryDarkest,
  padding: theme.spacing.small + 1,
  transform: [{ scaleX: -1 }],
});
const completeSaleTouchableStyle: StyleFn = () => ({
  minHeight: 60,
});

export const CashPaymentModal: React.FC<CashPaymentModalProps> = ({
  amountDue = 0,
  receivingAmount,
  onSubmit,
  enableRoundOff,
  roundOffValue = 1,
}) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const {
    formatCurrency,
    decimalSeparator,
    unFormatCurrency,
    formatMoneyValue,
  } = useCurrency();
  const [amountStr, setAmountStr] = useState<string>(
    formatMoneyValue(receivingAmount),
  );

  const remainingAmount = Number(
    (amountDue - unFormatCurrency(amountStr)).toFixed(2),
  );
  const roundedOffRemaining = enableRoundOff
    ? roundOffByValue(remainingAmount, roundOffValue)
    : remainingAmount;

  const fastCashOptions = useMemo(
    () => getFastCashOptions(amountDue, enableRoundOff, roundOffValue),
    [amountDue, enableRoundOff, roundOffValue],
  );

  const onPressKey = useCallback(
    (key: string): void => {
      setAmountStr(value => {
        if (value === formatMoneyValue(amountDue)) {
          return addToValue('', key, decimalSeparator, MAX_KEYPAD_INPUT);
        } else {
          return addToValue(value.toString(), key, decimalSeparator, 7);
        }
      });
    },
    [amountDue, decimalSeparator, formatMoneyValue],
  );

  const onResetAmount = useCallback(() => {
    setAmountStr(amountDue.toString());
  }, [amountDue]);

  const onPressSubmit = useCallback(() => {
    if (roundedOffRemaining > 0) {
      showNotification({
        message: translate('payment.payRemainingAmount'),
        error: true,
      });
    } else {
      closeModal();
      onSubmit(Number(amountStr));
    }
  }, [roundedOffRemaining, showNotification, onSubmit, amountStr, closeModal]);

  const numberKeys = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '0' },
    { value: decimalSeparator },
  ];

  return (
    <View>
      <TitleBar
        primary
        testID="title"
        containerStyle={css(headerStyle)}
        title={translate('payment.cashPayment')}
        titleLeft={<CloseButtons onPress={closeModal} />}
      />
      <View style={css(containerStyle)}>
        <InputDisplay
          containerStyle={css(inputContainerStyle)}
          value={`${formatCurrency(unFormatCurrency(amountStr))}`}
          testID="total-amount-input"
          right={
            <IconButton
              testID="reset"
              circular
              icon="redo"
              containerStyle={css(redoStyle)}
              onPress={onResetAmount}
              iconSize={16}
            />
          }
        />
        <Title
          containerStyle={css(remainingContainerStyle)}
          labelStyle={css(remainingLabelStyle)}
          testID="remaining-amount"
        >
          {translate('payment.remainingAmount') +
            ' ' +
            String(
              formatCurrency(
                roundedOffRemaining >= 0 ? roundedOffRemaining : 0,
              ),
            )}
        </Title>
        <View style={[css(keypadStyle), styles.fullWidth]}>
          <NumberInput
            keyWidth={80}
            keyHeight={60}
            twistKeyWidth={2.06}
            onPressKey={onPressKey}
            containerStyle={css(numberInputStyle)}
            keys={numberKeys}
          />
          <View>
            {Array(4)
              .fill(null)
              .map((X, index) => {
                return (
                  <View
                    key={index}
                    testID="cash-tender-options"
                    style={css(keyRowStyle)}
                  >
                    <NumberKey
                      width={120}
                      height={60}
                      testID={`cash-${fastCashOptions[index]}`}
                      onPressKey={() => {
                        setAmountStr(fastCashOptions[index].toFixed(2));
                      }}
                      value={formatCurrency(fastCashOptions[index])}
                      labelStyle={css(btnTextStyle)}
                    />
                  </View>
                );
              })}
          </View>
        </View>

        <TouchableOpacity
          testID="submit-button"
          onPress={() => onPressSubmit()}
          style={css(completeSaleTouchableStyle)}
        >
          <View
            style={css(
              buttonContainerStyle({
                theme,
                rightAlign: roundedOffRemaining <= 0,
              }),
            )}
          >
            {roundedOffRemaining <= 0 && (
              <Title primary containerStyle={css(changeContainerStyle)}>
                {'Change  ' +
                  String(formatCurrency(Math.abs(roundedOffRemaining)))}
              </Title>
            )}
            <Title success>{translate('payment.completeSale')}</Title>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};
