import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { useFela } from 'react-fela';
import TableComponent from '../TableComponent/TableComponent';
import { useTranslation } from '@hitz-group/localization';
import { StyleFn, PaymentType, Resource } from '@hitz-group/domain';
import ClosedShiftRow from './ClosedShiftRow';
import ShiftDisplay from './ShiftDisplay';
import { usePaymentTypes } from '../../hooks/app/usePaymentTypes';
import DropDown from '../FormInput/DropDown';
import SelectBar from '../Button/SelectBar';
import { useNotification, Notification } from '../../hooks/Notification';
import PaginatedView from '../PaginatedView/PaginatedView';
import { Shift, PaymentTypeSaleSummary } from '@hitz-group/domain';
import { pick } from 'lodash';
import scale, { isWeb } from '../../common/theme';
import { usePrinting } from '../../hooks/PrintingProvider';
import usePOSUserAuthorization from '../../hooks/app/users/usePOSUserAuthorization';

interface PaymentTypeDisplay extends PaymentType {
  value: string;
  label: string;
}

interface ShiftHistoryViewProps {
  data: Shift[];
  refetch: () => void;
}

const columnContainerStyle: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.padding.medium,
  height: 44,
  borderBottomWidth: 0,
  marginRight: 0,
  borderRadius: theme.radius.small,
  marginBottom: 5,
});
const rowsContainerStyle: StyleFn = () => ({
  flex: 1,
  flexDirection: 'row',
});
const tableStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  paddingHorizontal: theme.padding.small,
});
const selectBarStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  height: 38,
  margin: 1,
  alignSelf: 'stretch',
  alignItems: 'center',
  marginBottom: theme.spacing.small * 1.5,
});
const SummaryDisplayStyle: StyleFn = ({}) => ({
  marginTop: 10,
  width: 300,
});
const shiftStyle: StyleFn = ({}) => ({
  flex: 0.4,
});
const shiftSalesAndTaxesStyle: StyleFn = ({}) => ({
  flex: 0.6,
});
const paginatedContainerStyle: StyleFn = ({ theme, showSummary }) => ({
  marginRight: showSummary
    ? theme.spacing.small * 1.5
    : theme.spacing.small * 2.5,
  marginLeft: theme.spacing.small * 2.5,
});
const optionsStyle: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.small * 1.5,
});

export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '90%',
  borderRadius: scale.moderateScale(4),
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  marginLeft: scale.moderateScale(7),
});

export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: scale.textInputWidth180,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? scale.moderateScale(180) : '100%',
});

const summaryStyle: StyleFn = () => ({ flex: 1 });

const ShiftHistoryView: React.FC<ShiftHistoryViewProps> = ({
  data,
}: ShiftHistoryViewProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const [summaryType, setSummaryType] = useState('sales');
  const { paymentTypes, status } = usePaymentTypes();
  const { showNotification } = useNotification();
  const [displaySummary, setDisplaySummary] = useState<string | undefined>(
    undefined,
  );
  const [selectedPaymentType, setSelectedPaymentType] = useState<
    string | undefined
  >('');
  const { canI } = usePOSUserAuthorization();
  useEffect(() => {
    if (status.error) {
      showNotification({
        error: true,
        message: status.error,
      });
    }
  }, [status.error, showNotification]);
  const toggleDisplaySummary = useCallback(
    (id: string) =>
      setDisplaySummary(displaySummary => {
        if (displaySummary === id) {
          return undefined;
        }
        return id;
      }),
    [],
  );

  const { printShiftReceipt } = usePrinting();
  const onPressPrint = useCallback(
    async (shift?: Shift) => {
      if (shift) {
        const hasAccess = canI([{ onResource: Resource.PRINT_SHIFT_SUMMARY }], {
          prompt: true,
        });
        if (!hasAccess) {
          return;
        }
        const res = await printShiftReceipt(shift);
        if (res) showNotification(res as Notification);
      }
    },
    [printShiftReceipt, showNotification, canI],
  );

  const renderSummaryRowItems = useCallback(
    (items: Shift[]) => (
      <TableComponent
        columns={[
          {
            title: translate('shift.status'),
            width: 80,
            alignItems: 'center',
            containerStyle: { marginRight: 10 },
          },
          {
            title: translate('shift.number'),
            width: 50,
            alignItems: 'center',
            containerStyle: { marginRight: 13 },
          },
          {
            title: translate('shift.createdAt'),
            width: 180,
            alignItems: 'flex-start',
            containerStyle: { marginRight: 10 },
          },
          {
            title: translate('shift.device'),
            width: 108,
            alignItems: 'flex-start',
            containerStyle: { marginRight: 20 },
          },
          {
            title: translate('shift.closedBy'),
            flex: 1,
            alignItems: 'flex-start',
            containerStyle: { marginRight: 3 },
          },
          {
            title: translate('shift.closedAt'),
            flex: 1,
            alignItems: 'flex-start',
            containerStyle: { marginRight: 24 },
          },
          {
            title: translate('shift.difference'),
            width: 82,
            alignItems: 'center',
            containerStyle: { marginRight: 24 },
          },
          {
            title: translate('shift.print'),
            width: 36,
            alignItems: 'center',
            containerStyle: { marginRight: 37 },
          },
        ]}
        data={items}
        renderRow={(item: Shift): React.ReactNode => (
          <ClosedShiftRow
            key={item.id}
            item={item}
            onPress={toggleDisplaySummary}
            onPressPrint={onPressPrint}
          />
        )}
        columnContainerStyle={css(columnContainerStyle)}
        normalRows
      />
    ),
    [css, translate, toggleDisplaySummary, onPressPrint],
  );

  const paymentTypeSalesSummaryData = useMemo(() => {
    const item = data.find(x => x.id === displaySummary);
    const x = item?.salesByPaymentType.find(
      x => x.paymentType.id === selectedPaymentType,
    ) as PaymentTypeSaleSummary;

    const recorded = selectedPaymentType
      ? x?.recordedAmount || 0
      : item?.totalRecorded || 0;
    return {
      [translate('shift.counted')]: selectedPaymentType
        ? x?.totalCounted || 0
        : item?.totalCounted || 0,
      [translate('shift.recorded')]: recorded,
      [translate('shift.difference')]: selectedPaymentType
        ? x?.variance || 0
        : item?.difference || 0,
    };
  }, [data, displaySummary, selectedPaymentType, translate]);

  const itemSalesData = useMemo(() => {
    const item = Object.assign(
      {},
      data.find(x => x.id === displaySummary) as Shift,
    );
    if (item && item['avgSalesPerHour']) {
      // limiting decimals to 2
      item['avgSalesPerHour'] = +item['avgSalesPerHour']?.toFixed(2);
    }

    const salesSummaryKeys = [
      'totalSalesCount',
      'totalSales',
      'moneyIn',
      'moneyOut',
      'refundCount',
      'avgSalesPerHour',
      'refundItemsCount',
      'totalRecorded',
      'totalRefund',
      'totalDiscount',
      'totalRoundingAmount',
      'avgSaleAmount',
      'totalCounted',
      'difference',
      'balancePayment',
    ];

    const salesData = pick(item, salesSummaryKeys) as { [key: string]: number };

    if (item && item['salesAverage']) {
      // sales average name changing to avg sale amount
      salesData['avgSaleAmount'] = +item['salesAverage']?.toFixed(2);
    }
    return {
      sales: salesData,
      taxes: item?.taxes?.reduce(
        (acc, x) => ({ ...acc, [x.tax.name]: x.amount }),
        {},
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as Record<string, any>;
  }, [data, displaySummary]);

  return (
    <View style={css(rowsContainerStyle)}>
      <View style={css(tableStyle)}>
        <PaginatedView
          data={data}
          rowHeight={65}
          renderItems={renderSummaryRowItems}
          style={css(
            paginatedContainerStyle({
              theme,
              showSummary: !!selectedPaymentType,
            }),
          )}
        />
      </View>
      {displaySummary && (
        <View style={css(SummaryDisplayStyle)}>
          <View style={css(shiftStyle)}>
            <ShiftDisplay
              title={`${translate('shift.currentShift')}#${
                data.find(x => x.id === displaySummary)?.shiftNumber
              }`}
              data={paymentTypeSalesSummaryData}
              displayOptions={
                <DropDown
                  testID={'payment-types'}
                  values={[
                    {
                      label: translate('shift.allPaymentTypes'),
                      value: '',
                    },
                    ...(paymentTypes as PaymentTypeDisplay[]),
                  ]}
                  onValueChange={(selectedValue): void =>
                    setSelectedPaymentType(selectedValue)
                  }
                  selectedValue={selectedPaymentType}
                  style={css(optionsStyle)}
                  extraMainViewStyle={css(dropDownMainViewStyle)}
                  extraStyle={css(dropdownExtraStyle)}
                  extraViewStyle={css(dropdownViewStyle)}
                />
              }
            />
          </View>
          <View style={css(shiftSalesAndTaxesStyle)}>
            <ShiftDisplay
              data={itemSalesData[summaryType]}
              displayOptions={
                <SelectBar
                  options={[
                    {
                      label: translate('shift.sales'),
                      value: 'sales',
                    },
                    {
                      label: translate('shift.taxes'),
                      value: 'taxes',
                    },
                  ]}
                  selectedOption={summaryType}
                  onPress={setSummaryType}
                  containerStyle={css(selectBarStyle)}
                />
              }
              style={css(summaryStyle)}
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default ShiftHistoryView;
