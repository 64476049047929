import { useCallback, useMemo, useState } from 'react';
import {
  CreateAndUpdateEarningRule,
  CreateAndUpdateRewardRule,
  EarningRule,
  LoyaltyProgram,
  LoyaltySettings,
  RewardRule,
  UpdateLoyaltySettingsInput,
} from '@hitz-group/domain';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { noopHandler, parseApolloError } from '../../../utils/errorHandlers';
import { useTranslation } from '@hitz-group/localization';

import {
  GET_LOYALTY_SETTINGS,
  CREATE_AND_UPDATE_EARNING_RULES,
  CREATE_AND_UPDATE_REWARD_RULES,
  DELETE_EARNING_RULE,
  DELETE_REWARD_RULE,
  UPDATE_LOYALTY_SETTINGS,
} from './graphql';
import { useNotification } from '../../Notification';
import omitKey from 'lodash/omit';

export interface useLoyaltyProps {
  loading: boolean;
  error: string | undefined;
  loyaltySettings: Partial<LoyaltySettings>;
  earningRules: EarningRule[];
  rewardRules: RewardRule[];
  getLoyaltyPrograms: () => void;
  createAndUpdateEarningRule: (
    earningPointRule: CreateAndUpdateEarningRule,
  ) => void;
  createAndUpdateRewardRules: (redeemRule: CreateAndUpdateRewardRule) => void;
  deleteEarningRule: (id: string) => void;
  deleteRewardRule: (id: string) => void;
  updateLoyaltySetting: (input: UpdateLoyaltySettingsInput) => void;
}

const removeTypename = <T extends object>(item: T) => {
  return omitKey(item, '__typename');
};

export function useLoyalty(venueId?: string): useLoyaltyProps {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [loyaltySettings, setLoyaltySetting] = useState<
    Partial<LoyaltySettings>
  >({});

  const [earningRules, setEarningRules] = useState<EarningRule[]>([]);
  const [rewardRules, setRewardRules] = useState<RewardRule[]>([]);

  const [getLoyaltyPrograms, getLoyaltyProgramsRes] = useLazyQuery<{
    loyaltyPrograms: LoyaltyProgram;
  }>(GET_LOYALTY_SETTINGS, {
    onError: noopHandler,
    fetchPolicy: 'cache-and-network',
    onCompleted: response => {
      const { loyaltyPrograms } = response;
      const { settings, rules } = loyaltyPrograms;
      setLoyaltySetting(removeTypename(settings));
      setEarningRules((rules.earningRules || []).map(removeTypename));
      setRewardRules((rules.rewardRules || []).map(removeTypename));
    },
  });

  const [createAndUpdateEarningRuleReq, createAndUpdateEarningRuleRes] =
    useMutation<
      { createOrUpdateLoyaltyProgramEarningRule: EarningRule },
      { input: CreateAndUpdateEarningRule }
    >(CREATE_AND_UPDATE_EARNING_RULES, {
      onError: noopHandler,
      onCompleted: response => {
        const updatedEarningRule =
          response?.createOrUpdateLoyaltyProgramEarningRule;

        setEarningRules(preEarningRules =>
          preEarningRules
            .filter(earningRule => earningRule.id !== updatedEarningRule?.id)
            .concat(removeTypename(updatedEarningRule)),
        );
      },
    });

  const [createAndUpdateRewardRulesReq, createAndUpdateRewardRulesRes] =
    useMutation<
      { createOrUpdateLoyaltyProgramRewardRule: RewardRule },
      { input: CreateAndUpdateRewardRule }
    >(CREATE_AND_UPDATE_REWARD_RULES, {
      onError: noopHandler,
      onCompleted: response => {
        const updatedRewardRule =
          response?.createOrUpdateLoyaltyProgramRewardRule;

        setRewardRules(preRewardRules =>
          preRewardRules
            .filter(rewardRule => rewardRule.id !== updatedRewardRule?.id)
            .concat(removeTypename(updatedRewardRule)),
        );
      },
    });

  const [deleteEarningRuleReq, deleteEarningRuleRes] = useMutation<
    { deleteEarningRule: string },
    { id: string }
  >(DELETE_EARNING_RULE, {
    onError: noopHandler,
    onCompleted: response => {
      showNotification({
        message: translate('backOfficeLoyalty.deleteEarningRuleSuccessfully'),
        success: true,
      });
      setEarningRules(preRules =>
        preRules.filter(rule => rule.id !== response.deleteEarningRule),
      );
    },
  });

  const [deleteRewardRuleReq, deleteRewardRuleRes] = useMutation<
    { deleteRewardRule: string },
    { id: string }
  >(DELETE_REWARD_RULE, {
    onError: noopHandler,
    onCompleted: response => {
      showNotification({
        message: translate('backOfficeLoyalty.deleteRewardRuleSuccessfully'),
        success: true,
      });

      setRewardRules(preRules =>
        preRules.filter(rule => rule.id !== response.deleteRewardRule),
      );
    },
  });

  const [updateLoyaltySettingReq, updateLoyaltySettingRes] = useMutation<
    { updateLoyaltyProgramSettings: LoyaltySettings },
    { input: UpdateLoyaltySettingsInput }
  >(UPDATE_LOYALTY_SETTINGS, {
    onError: noopHandler,
    onCompleted: response => {
      showNotification({
        message: translate(
          'backOfficeLoyalty.updateLoyaltySettingSuccessfully',
        ),
        success: true,
      });
      setLoyaltySetting(removeTypename(response.updateLoyaltyProgramSettings));
    },
  });

  const updateLoyaltySetting = useCallback(
    (input: UpdateLoyaltySettingsInput) => {
      updateLoyaltySettingReq({ variables: { input } });
    },
    [updateLoyaltySettingReq],
  );

  const createAndUpdateEarningRule = useCallback(
    (earningRule: CreateAndUpdateEarningRule) => {
      createAndUpdateEarningRuleReq({ variables: { input: earningRule } });
    },
    [createAndUpdateEarningRuleReq],
  );

  const createAndUpdateRewardRules = useCallback(
    (rewardRule: CreateAndUpdateRewardRule) => {
      createAndUpdateRewardRulesReq({ variables: { input: rewardRule } });
    },
    [createAndUpdateRewardRulesReq],
  );

  const deleteEarningRule = useCallback(
    (id: string) => {
      deleteEarningRuleReq({ variables: { id } });
    },
    [deleteEarningRuleReq],
  );

  const deleteRewardRule = useCallback(
    (id: string) => {
      deleteRewardRuleReq({ variables: { id } });
    },
    [deleteRewardRuleReq],
  );

  const error =
    getLoyaltyProgramsRes.error ||
    createAndUpdateRewardRulesRes.error ||
    createAndUpdateEarningRuleRes.error ||
    deleteEarningRuleRes.error ||
    deleteRewardRuleRes.error ||
    updateLoyaltySettingRes.error;

  const loading =
    getLoyaltyProgramsRes.loading ||
    createAndUpdateRewardRulesRes.loading ||
    createAndUpdateEarningRuleRes.loading ||
    deleteRewardRuleRes.loading ||
    deleteEarningRuleRes.loading ||
    updateLoyaltySettingRes.loading;

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      loyaltySettings,
      getLoyaltyPrograms,
      createAndUpdateEarningRule,
      createAndUpdateRewardRules,
      earningRules: venueId
        ? earningRules.filter(earningRule =>
            (earningRule.venueIds || []).includes(venueId),
          )
        : earningRules,
      rewardRules: venueId
        ? rewardRules.filter(reward =>
            (reward.venueIds || []).includes(venueId),
          )
        : rewardRules,
      deleteEarningRule,
      deleteRewardRule,
      updateLoyaltySetting,
    }),
    [
      loading,
      error,
      loyaltySettings,
      getLoyaltyPrograms,
      createAndUpdateEarningRule,
      createAndUpdateRewardRules,
      venueId,
      earningRules,
      rewardRules,
      deleteEarningRule,
      deleteRewardRule,
      updateLoyaltySetting,
    ],
  );
}
