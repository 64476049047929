import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import FormInput from '../../../../../components/FormInput/FormInput';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';

const rowStyle: StyleFn = ({ theme, index }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.padding.medium / 2,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
  zIndex: 1000 - 10 * index,
});

const formTextStyle: StyleFn = () => ({
  height: 0,
});

const formContStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 150,
  marginRight: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
  borderColor: 'transparent',
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  minHeight: theme.input.height,
  width: 375,
  marginTop: theme.spacing.small - 2,
});

const touchableStyle: StyleFn = () => ({
  height: 40,
  flexWrap: 'wrap',
});

export interface ItemRowProps {
  title: string;
  data: { value: string; label: string }[];
  index: number;
  dropDownTitle: string;
  searchTitle: string;
  onChange: (prop: string, value: string[]) => void;
  selectedValues: string[];
  property: string;
}

export const AvailabilityItemRow: React.FC<ItemRowProps> = ({
  data,
  title,
  index,
  dropDownTitle,
  searchTitle,
  selectedValues,
  onChange,
  property,
}: ItemRowProps) => {
  const { css, theme } = useFela();

  return (
    <View style={css(rowStyle({ theme, index }))}>
      <FormInput
        value={title}
        containerStyle={css(formContStyle)}
        textStyle={css(formTextStyle)}
      />

      <MultipleSelect
        selectedValues={selectedValues}
        containerStyle={css(dropDownStyle)}
        values={data}
        searchPlaceHolder={searchTitle}
        searchLabel={dropDownTitle}
        onValueChange={value => onChange(property, value)}
        touchableStyle={css(touchableStyle)}
      />
    </View>
  );
};
