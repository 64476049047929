import React from 'react';
import { useFela } from 'react-fela';
import IconButton from '../../../../../components/Button/IconButton';
import { Text, View } from 'react-native';
import { StyleFn } from '@hitz-group/domain';
import TableRow from '../../../../../components/TableComponent/TableRow';
import { AssignedProduct } from './CourseDetail';
import { CourseDetailStyles } from '../styles';
export interface SelectedItem {
  id: string;
  name: string;
  type: string;
  priority: number;
  entityType?: string;
}
interface ProductRowProps {
  rowIndex: number;
  item: AssignedProduct;
  onDelete: () => void;
}

const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const assignIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
});

const assignIconContainerStyle: StyleFn = ({ theme }) => ({
  height: 28,
  width: 28,
  borderRadius: 14,
  backgroundColor: theme.colors.blue,
  alignSelf: 'center',
});

const ProductRow: React.FC<ProductRowProps> = ({
  item,
  onDelete,
  rowIndex,
}) => {
  const { css, theme } = useFela();
  const styles = CourseDetailStyles();
  return (
    <TableRow containerStyle={styles.rowContainerStyle} normalRows={true}>
      <IconButton
        icon="TagAlt"
        iconSize={18}
        containerSize={18}
        containerStyle={css(assignIconContainerStyle)}
        iconStyle={css(assignIconStyle)}
      />
      <View style={styles.productNameStyle}>
        <Text style={css(textStyle)} testID={`course-product-name-${rowIndex}`}>
          {item.name}
        </Text>
      </View>

      <View style={css(flexStyle)} />
      <View
        style={styles.deleteIconContainerStyle}
        testID={`delete-icon-${rowIndex}`}
      >
        <IconButton
          icon="TrashAlt"
          iconSize={18}
          containerSize={38}
          containerStyle={styles.deleteIconStyle}
          iconColor={theme.colors.red}
          onPress={onDelete}
        />
      </View>
    </TableRow>
  );
};

export default ProductRow;
