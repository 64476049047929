import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';

const usePrinterOptionRowStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        rowContainerStyle: {
          flex: 1,
          justifyContent: 'space-between',
          zIndex: 999,
        },
        nameViewStyle: {
          flex: 1,
          justifyContent: 'center',
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          paddingLeft: theme.spacing.small,
        },
        printerTypeViewStyle: {
          height: theme.input.height,
          flex: 1,
          paddingLeft: theme.spacing.small,
        },
        popOverStyle: {
          width: 350,
        },
        fieldInputContainerStyle: {
          width: '100%',
          height: theme.input.height,
          zIndex: 999,
          bottom: 10,
        },
        itemContainer: {
          height: rowHeight * 3,
        },
      }),
    [theme],
  );
};

export default usePrinterOptionRowStyles;
