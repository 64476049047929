import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import ActivityLog from './ActivityLog/ActivityLog';
import Customers from './Customers/Customers';
import Overview from './Overview/Overview';
import Settings from './Settings/Settings';

const Stack = createStackNavigator();

const LoyaltyStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="Overview"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={Overview} name="Overview" />
    <Stack.Screen component={ActivityLog} name="ActivityLog" />
    <Stack.Screen component={Customers} name="Customers" />
    <Stack.Screen component={Settings} name="Settings" />
  </Stack.Navigator>
);

export default LoyaltyStack;
