import { Product, UpdateProductsAvailabilityInput } from '@hitz-group/domain';
import { useCallback } from 'react';
import { useProducts } from '../products/useProducts';
import { cloneDeep, keyBy } from 'lodash';
import { IMap } from '../,,/../../../../src/screens/BackOffice/Reports/types';
import { productFragment } from '../catalogue/graphql';
import { areProductsAvailabilityUpdatedViaSubscription } from '../../../../src/state/cache';

interface UseProductEventsProps {
  productEventsHandler: (events: UpdateProductsAvailabilityInput[]) => void;
}
export const useProductEvents = (): UseProductEventsProps => {
  const { getProductsFromCache, updateProductsInCache } = useProducts(
    undefined,
    productFragment,
  );

  const productEventsHandler = useCallback(
    async (events: UpdateProductsAvailabilityInput[]) => {
      const productMap = keyBy(getProductsFromCache(), 'id');
      const updatedProducts: IMap<Product> = {};

      events.forEach(event => {
        const { products } = event;
        products.forEach(product => {
          const updatedProduct = cloneDeep(productMap[product.id]);
          updatedProduct.inventory!.availableQuantity =
            product.inventory!.availableQuantity;
          updatedProduct.name = product.name!;
          updatedProduct.isBeingTracked = product.isBeingTracked;
          updatedProducts[product.id] = updatedProduct;
        });
      });

      Object.keys(updatedProducts).length &&
        updateProductsInCache(updatedProducts);
      Object.keys(updatedProducts).length &&
        areProductsAvailabilityUpdatedViaSubscription(true);
    },
    [getProductsFromCache, updateProductsInCache],
  );

  return {
    productEventsHandler,
  };
};
