import {
  CreatePageInput,
  StyleFn,
  UpdatePageInput,
  ENTITY_TYPE,
} from '@hitz-group/domain';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../../components/FormInput/FormInput';
import ColorDropDown from '../../../../../components/DropDown/ColorDropDown';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@hitz-group/localization';
import scale, { isAndroid, isWeb } from '../../../../../common/theme';
import { ProductRow, SelectedItem } from '../ProductRow/ProductRow';
import Button from '../../../../../components/Button/Button';
import { usePages } from '../../../../../hooks/app/pages/usePages';
import { useNotification } from '../../../../../hooks/Notification';
import { Operation } from '../../../../../types/Operation';
import { useRoute } from '@react-navigation/native';
import { useModal } from '@hitz-group/rn-use-modal';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import {
  Colors,
  DEFAULT_ENTITY_ID,
  PageItemOrderInput,
} from '@hitz-group/domain';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { useNavigation } from '@react-navigation/native';
import MultipleSearchAdd from '../../../../../components/MultipleSelect/MultipleSearchAdd';
import { useVariants } from '../../../../../hooks/app/variants/useVariants';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { CatalogueItem } from '../../Products/types';
import DraggableFlatList from '../../../../../components/DraggableFlatList/DraggableFlatList';
import { keyBy } from 'lodash';

enum SelectedItemType {
  Product = 'Product',
  Page = 'Page',
}

interface PagesAndProducts {
  id: string;
  priority?: number;
}

const scrollStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: 600,
  alignSelf: 'center',
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 270,
  height: theme.input.height,
  justifyContent: 'center',
});

const textStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.padding.small,
});

export const formTextStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  fontSize: scale.moderateScale(10),
  paddingHorizontal: theme.padding.large,
});

export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: scale.textInputWidth180,
  height: scale.moderateScale(30),
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? scale.moderateScale(180) : '50%',
  marginTop: scale.moderateScale(2),
});

export const innerContainer: StyleFn = ({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingRight: theme.spacing.small,
  alignItems: 'center',
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: 590,
  marginLeft: theme.spacing.big / 2,
  marginTop: theme.spacing.medium,
  marginBottom: theme.spacing.small * 2,
  zIndex: 1500,
});

const dropdownStyle: StyleFn = ({ theme }) => ({
  width: 600,
  marginLeft: theme.spacing.big / 2,
  paddingRight: theme.spacing.small,
  marginTop: theme.spacing.medium,
  marginBottom: theme.spacing.small * 2,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

export const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: scale.moderateScale(30),
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  paddingLeft: theme.padding.small,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  marginRight: theme.spacing.medium,
});

export const searchTextInputStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  flex: 1,
  height: scale.moderateScale(30),
  color: theme.colors.text,
  fontFamily: theme.font.medium,
  fontSize: scale.moderateScale(10),
});

export const deleteButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginRight: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  alignSelf: 'auto',
});

export const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

export const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

export const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const dangerTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
  letterSpacing: -0.5,
});

export const productNameStyle: StyleFn = () => ({
  marginRight: 420,
});

const colorDropDownStyle: StyleFn = () => ({
  width: 300,
  marginBottom: 15,
});
const extraPopoverStyle: StyleFn = ({ theme }) => ({
  width: 300,
  marginBottom: theme.spacing.small,
});

const colorOptions = [
  { label: 'Deep Purple', value: Colors.deepPurple, color: Colors.deepPurple },
  { label: 'Indigo', value: Colors.indigo, color: Colors.indigo },
  { label: 'Cyan', value: Colors.cyan, color: Colors.cyan },
  { label: 'Teal', value: Colors.teal, color: Colors.teal },
  { label: 'Light Green', value: Colors.lime, color: Colors.lime },
];

export const Personalisation: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const route = useRoute();
  const { showNotification } = useNotification();
  // default color teal - #009688
  const [pageData, setPageData] = useState({
    color: Colors.teal,
  } as UpdatePageInput);
  const { showModal, closeModal } = useModal();
  const navigation = useNavigation();
  const pageId = (
    route.params as {
      pageId: string;
    }
  )?.pageId;
  const [selectedPagesAndProds, setSelectedPagesAndProds] = useState<
    PagesAndProducts[]
  >([]);

  const [dataSelected, setDataSelected] = useState<SelectedItem[]>([]);

  const {
    deletePage,
    operation,
    pages,
    error: pageErr,
    loading: pageLoading,
    createPages,
    updatePages,
    getPages,
    createdIds,
    addOrRemoveAvailability,
    getPage,
    updatePageItemsOrdering,
  } = usePages();

  const {
    products,
    error: prodErr,
    getAllProducts,
    loading: prodLoading,
  } = useProducts();
  const {
    variants,
    error: variantErr,
    getAllVariants,
    loading: varLoading,
  } = useVariants();

  useEffect(() => {
    if (pageId !== DEFAULT_ENTITY_ID) {
      getAllProducts();
      getAllVariants();
      getPages();
    }
  }, [getPages, getAllProducts, getAllVariants, pageId]);

  useEffect(() => {
    if (pageId && pages[pageId] && pageId !== DEFAULT_ENTITY_ID) {
      setPageData(pages[pageId]);
      const selectedValues: PagesAndProducts[] = [];
      const page = pages[pageId];
      if (page.products?.length) {
        selectedValues.push(...page.products);
      }
      if (page.variants?.length) {
        selectedValues.push(...page.variants);
      }
      if (page.pages?.length) {
        selectedValues.push(...page.pages);
      }
      selectedValues.sort((a, b) => (a.priority || 0) - (b.priority || 0));
      setSelectedPagesAndProds(selectedValues);
    }
  }, [pageId, pages]);

  useEffect(() => {
    if (pageId && pages[pageId]) {
      // update the tab title
      navigation.setOptions({
        tabBarLabel: pages[pageId].name,
      });
    }
  }, [navigation, pageId, pages]);

  const error = pageErr || variantErr || prodErr;

  const loading = pageLoading || varLoading || prodLoading;

  const isUpdated = !error && !loading && operation === Operation.UPDATE;

  const isDeleted = !error && !loading && operation === Operation.DELETE;

  const isCreated = !error && !loading && operation === Operation.CREATE;

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (isCreated && createdIds.length) {
      navigation.navigate('PageDetails', {
        pageId: createdIds[0],
      });
    }
  }, [createdIds, isCreated, navigation]);

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeProducts.pageUpdatedSuccessfully'),
      });
    }
  }, [isUpdated, translate, showNotification, getPage, pageId]);

  useEffect(() => {
    if (isDeleted) {
      showNotification({
        success: true,
        message: translate('backOfficeProducts.itemWasDeletedSuccessfully', {
          item: 'Page',
        }),
      });
      navigation.navigate('Pages');
    }
  }, [isDeleted, translate, showNotification, navigation]);

  useEffect(() => {
    if (isCreated) {
      showNotification({
        success: true,
        message: translate('backOfficeProducts.pageCreatedSuccessfully'),
      });
    }
  }, [isCreated, translate, showNotification]);

  const onChange = (prop: string, value: string) => {
    setPageData(prev => ({ ...prev, [prop]: value }));
  };

  const onDeletePage = useCallback(
    id => {
      deletePage(id);
      closeModal();
    },
    [deletePage, closeModal],
  );

  const onDeleteModal = useCallback(() => {
    if (pageId) {
      showModal(
        <ConfirmationDialog
          title={
            translate('dialog.deleteTitle') +
            ' ' +
            translate('backOfficeProducts.page')
          }
          message={translate('dialog.deleteConfirmation', {
            label: pageData.name,
          })}
          onConfirm={() => onDeletePage(pageId)}
        />,
      );
    }
  }, [pageId, pageData, onDeletePage, showModal, translate]);

  const pagesArray = useMemo(() => {
    return Object.values(pages);
  }, [pages]);

  const productListData = useMemo(() => {
    const variantsList = Object.values(variants);
    const productList = Object.values(products);
    const productsDataInPageMap = {} as {
      [key: string]: CatalogueItem;
    };
    productList.forEach(prod => {
      productsDataInPageMap[prod.id] = {
        id: prod.id,
        isSellable: prod.isSellable,
        isVariant: false,
        isVariantProduct: false,
        name: prod.name,
        price: '',
        tax: '',
        taxName: '',
        productTypeName: prod?.productType?.name,
        productTypeId: prod?.productType?.id,
        pageIds: (prod?.pages || []).map(x => x.id),
        storeIds: (prod?.stores || [])?.map(x => x.id),
        defaultPriceId: '',
      } as CatalogueItem;
    });

    variantsList.forEach(variant => {
      productsDataInPageMap[variant.id] = {
        id: variant.id,
        isSellable: false,
        isVariant: true,
        isVariantProduct: false,
        name: variant.name,
        price: '',
        productTypeName: variant?.productType?.name || '',
        productTypeId: variant?.productType?.id,
        tax: '',
        taxName: '',
        pageIds: (variant?.pages || []).map(x => x.id),
        isSelected: false,
        storeIds: (variant?.stores || [])?.map(x => x.id),
      };
      variant.products.forEach(variantProd => {
        // deleting variant products
        delete productsDataInPageMap?.[variantProd?.id];
      });
    });
    return productsDataInPageMap;
  }, [products, variants]);

  useEffect(() => {
    const result: SelectedItem[] = [];
    if (selectedPagesAndProds?.length) {
      selectedPagesAndProds.forEach(eachSelected => {
        if (productListData?.[eachSelected.id]) {
          result.push({
            id: eachSelected.id,
            name: productListData?.[eachSelected.id]?.name,
            priority: eachSelected.priority || 0,
            type: SelectedItemType.Product,
            entityType: productListData?.[eachSelected.id]?.isVariant
              ? ENTITY_TYPE.Variant
              : ENTITY_TYPE.Product,
          });
        } else {
          result.push({
            id: eachSelected.id,
            name: pages?.[eachSelected.id]?.name,
            priority: eachSelected.priority || 0,
            type: SelectedItemType.Page,
            entityType: ENTITY_TYPE.Page,
          });
        }
      });
    }
    setDataSelected(result.sort((a, b) => a.priority - b.priority));
  }, [selectedPagesAndProds, pages, productListData]);

  const pagesAndProductsDataOptions = useMemo(() => {
    let result: {
      value: string;
      label: string;
      type: SelectedItemType;
      itemTitle: string;
    }[] = [];

    const productsList = Object.values(productListData);

    result = productsList.map(
      eachProd => ({
        itemTitle: SelectedItemType.Product,
        label: eachProd.name,
        value: eachProd.id,
        type: SelectedItemType.Product,
      }),
      [],
    );

    pagesArray.forEach(eachPage => {
      let title = [''];
      if (eachPage?.products?.length) {
        title = eachPage?.products?.map(eachprod => {
          return productListData[eachprod.id]?.name;
        }, []);
      }
      result.push({
        itemTitle: SelectedItemType.Page,
        label: eachPage.name + ' (' + `${title?.join(',')}` + ')',
        type: SelectedItemType.Page,
        value: eachPage.id,
      });
    }, []);

    return result;
  }, [productListData, pagesArray]);

  const onChangeOfDropDown = useCallback(
    (values: string[]) => {
      const selectedPagesAndProdsMap = keyBy(selectedPagesAndProds, 'id');
      const newValue = values.map(x => {
        if (selectedPagesAndProdsMap[x]) {
          return selectedPagesAndProdsMap[x];
        }
        return {
          id: x,
          priority: 0,
        };
      });
      setSelectedPagesAndProds(newValue);
    },
    [selectedPagesAndProds],
  );

  const onDeleteRow = useCallback((id: string) => {
    setSelectedPagesAndProds(prevValues => prevValues.filter(x => x.id !== id));
  }, []);

  const updatePageItemsOrder = useCallback(() => {
    if (pageId) {
      const item = dataSelected.map(x => ({
        id: x.id,
        type: x.entityType,
        priority: x.priority,
      })) as PageItemOrderInput[];
      return updatePageItemsOrdering(pageId, item);
    }
  }, [dataSelected, pageId, updatePageItemsOrdering]);

  const addOrRemoveProductsPages = useCallback(async () => {
    // add or remove pages, products
    const existingPages: string[] = [];
    const existingProds: string[] = [];
    const existingVars: string[] = [];
    const selectedVars: string[] = [];
    const selectedProds: string[] = [];
    const selectedPages: string[] = [];

    const page = pages[pageId];
    if (page.products?.length) {
      page.products?.forEach(x => existingProds.push(x.id));
    }
    if (page.variants?.length) {
      page.variants?.forEach(x => existingVars.push(x.id));
    }
    if (page.pages?.length) {
      page.pages?.forEach(x => existingPages.push(x.id));
    }

    selectedPagesAndProds.forEach(eachProdOrPage => {
      if (productListData?.[eachProdOrPage.id]) {
        // prod or var
        if (productListData?.[eachProdOrPage.id]?.isVariant) {
          selectedVars.push(eachProdOrPage.id);
        } else {
          selectedProds.push(eachProdOrPage.id);
        }
      } else {
        // page
        selectedPages.push(eachProdOrPage.id);
      }
    });
    await addOrRemoveAvailability({
      pageId: pageId,
      addPages: selectedPages.filter(x => x && !existingPages.includes(x)),
      addVariants: selectedVars.filter(x => x && !existingVars.includes(x)),
      addProductIds: selectedProds.filter(x => x && !existingProds.includes(x)),
      removePages: existingPages.filter(x => x && !selectedPages.includes(x)),
      removeProducts: existingProds.filter(
        x => x && !selectedProds.includes(x),
      ),
      removeVariants: existingVars.filter(x => x && !selectedVars.includes(x)),
    });
    await updatePageItemsOrder();
    getPage(pageId);
  }, [
    addOrRemoveAvailability,
    getPage,
    pageId,
    pages,
    productListData,
    selectedPagesAndProds,
    updatePageItemsOrder,
  ]);

  const onPressSave = () => {
    if (pageData?.id) {
      // update
      const pageDataInput = {
        id: pageData.id,
        color: pageData.color,
        name: pageData.name,
      } as UpdatePageInput;
      updatePages([pageDataInput]);
      addOrRemoveProductsPages();
    } else {
      // create
      const pageDataInput = {
        color: pageData?.color,
        name: pageData.name,
      } as CreatePageInput;
      createPages([pageDataInput]);
    }
  };

  const onDragEnd = useCallback((data: Array<SelectedItem>) => {
    const dataOrder = data.map((pageRow, index) => ({
      ...pageRow,
      priority: index,
    }));
    setDataSelected(dataOrder);
  }, []);
  if (loading) return <LoadingIndicator />;
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollStyle)}>
          <BackOfficeSection
            title={translate('backOfficeProducts.personalisation')}
            contentContainerStyle={css(formStyle)}
            containerStyle={css(containerStyle)}
            iconColor={theme.colors.blue}
            icon={'info-circle'}
          >
            <View style={css(innerContainer)}>
              <FormInput
                testID="page-name"
                placeholder={translate('backOfficeProducts.pageName')}
                value={pageData.name}
                title={translate('backOfficeProducts.pageName')}
                alignTitle="left"
                containerStyle={css(formInputContainerStyle)}
                onChangeText={onChange.bind(null, 'name')}
                maxLength={50}
                textStyle={css(textStyle)}
              />
              <ColorDropDown
                title={translate('menus.selectPageColor')}
                options={colorOptions}
                containerStyle={css(colorDropDownStyle)}
                onValueChange={onChange.bind(null, 'color')}
                selectedValue={pageData.color}
                extraPopoverStyle={css(extraPopoverStyle)}
              />
            </View>
          </BackOfficeSection>

          {pageData?.id && (
            <BackOfficeSection
              title={translate('backOfficeProducts.assignProduct')}
              contentContainerStyle={css(formStyle)}
              containerStyle={css(containerStyle)}
              iconColor={theme.colors.blue}
              icon={'info-circle'}
            >
              <MultipleSearchAdd
                values={pagesAndProductsDataOptions}
                selectedValues={selectedPagesAndProds.map(x => x.id)}
                containerStyle={
                  isAndroid ? css(dropdownStyle) : css(dropDownStyle)
                }
                onValueChange={onChangeOfDropDown}
                searchLabel={translate(
                  'backOfficeProducts.searchProductsPagesByName',
                )}
                scrollEnabled
              />
              <DraggableFlatList
                columns={[
                  {
                    title: translate('backOfficeProducts.order'),
                    width: 60,
                    alignItems: 'center',
                  },
                  {
                    title: translate('backOfficeProducts.type'),
                    width: 85,
                    alignItems: 'center',
                  },
                  {
                    title: translate('backOfficeProducts.name'),
                    flex: 1,
                    alignItems: 'flex-start',
                    containerStyle: { paddingLeft: theme.spacing.big / 2 },
                  },
                ]}
                data={dataSelected}
                normalRows
                columnContainerStyle={css(columnContainerStyle)}
                onDragEnd={(data: Array<SelectedItem>) => onDragEnd(data)}
                renderRow={(
                  item: SelectedItem,
                  index: number,
                  drag: () => void,
                ): React.ReactNode => (
                  <ProductRow
                    item={item}
                    rowIndex={index}
                    key={index}
                    onDelete={onDeleteRow}
                    drag={drag}
                  />
                )}
              />
            </BackOfficeSection>
          )}
        </ScrollView>
      </View>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          {pageId && (
            <Button
              fluid
              testID="delete-changes"
              title={translate('button.delete')}
              containerStyle={css(deleteButtonStyle)}
              labelStyle={css(dangerTitleStyle)}
              onPress={onDeleteModal}
            />
          )}
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
