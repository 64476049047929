import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import Modal from '../Modals/Modal';
import Button from '../Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';
import { View, Text } from 'react-native';

interface PromptModalProps {
  onSubmit: () => void;
  title?: string;
  description?: string;
  actionButtonTitle?: string;
}

const createModalContainerStyle: StyleFn = () => ({
  width: 500,
  height: 250,
});
const createModalBodyStyle: StyleFn = () => ({
  flex: 1,
});

const dismissButtonStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.boxBorder,
  zIndex: 200,
  marginHorizontal: theme.padding.small,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger,
  zIndex: 200,
  marginHorizontal: theme.padding.small,
});

const assignLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
});

const dismissLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
  backgroundColor: theme.colors.boxBorder,
});

const buttonsWrapper: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const headerTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
});

const textContainerStyle: StyleFn = () => ({
  height: 100,
});
const textStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  marginLeft: theme.spacing.small * 1.5,
});

const PromptModal: React.FC<PromptModalProps> = ({
  onSubmit,
  title,
  description,
  actionButtonTitle,
}: PromptModalProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { closeModal } = useModal();

  return (
    <Modal
      title={title}
      onDismiss={closeModal}
      showCloseButton={false}
      customBodyStyle={css(createModalBodyStyle)}
      contentStyle={css(createModalContainerStyle)}
      headerTextStyle={css(headerTextStyle)}
    >
      <View style={css(textContainerStyle)}>
        <View style={css(textContainerStyle)}>
          <Text style={css(textStyle)} testID="description">
            {description}
          </Text>
        </View>

        <View style={css(buttonsWrapper)}>
          <Button
            title={translate('productBulkOperations.dismiss')}
            containerStyle={css(dismissButtonStyle)}
            labelStyle={css(dismissLabelStyle)}
            onPress={closeModal}
            testID="dismissBtn"
          />
          <Button
            title={actionButtonTitle}
            containerStyle={css(buttonStyle)}
            labelStyle={css(assignLabelStyle)}
            onPress={onSubmit}
            testID="submit"
          />
        </View>
      </View>
    </Modal>
  );
};

export default PromptModal;
