import { FeatureSetting, RenderProps, StyleFn } from '@hitz-group/domain';
import { pascalCase } from 'change-case';
import React from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { Text, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';
import CollapsiblePanel from '../CollapsiblePanel/CollapsiblePanel';
import { WithFeature } from '../features/WithFeature';

export interface TitleProps {
  icon: string;
  title: string;
  active?: boolean;
  onPress?: () => void;
  iconStyle?: TextStyle;
}

const subNavItemStyle: StyleFn = ({ theme, active }) => ({
  paddingLeft: theme.padding.large * 3.6,
  height: 30,
  lineHeight: 30,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
  fontFamily: theme.font.medium,
  color: active ? theme.colors.white : theme.colors.textLight,
});

export interface SubNavItemProps {
  title: string;
  active?: boolean;
  onPress?: () => void;
}

const SubNavItem: React.FC<SubNavItemProps> = ({
  title,
  onPress,
  ...props
}: SubNavItemProps) => (
  <FelaComponent style={subNavItemStyle} {...props}>
    {({ style }: RenderProps): React.ReactFragment => (
      <TouchableOpacity onPress={onPress}>
        <Text style={style}>{title}</Text>
      </TouchableOpacity>
    )}
  </FelaComponent>
);

const navItemStyle: StyleFn = ({ theme, active }) => ({
  backgroundColor: active ? theme.colors.boxBorder : theme.colors.background,
});

const panelContentStyle: StyleFn = () => ({
  flexDirection: 'column',
  overflow: 'hidden',
});

export interface NavItemProps {
  title: string;
  subnav?: string[];
  subroute?: Array<{
    title: string;
    routing: string;
    feature?: FeatureSetting;
  }>;
  active?: boolean;
  selected?: string;
  icon: string;
  iconStyle?: TextStyle;
  contentStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  onPress?: (route: string) => void;
  onSelectSubNav?: (route: string) => void;
}

const NavItem: React.FC<NavItemProps> = ({
  icon,
  title,
  // TODO: Not Using Fix Me
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  subnav,
  subroute,
  selected,
  onPress = () => undefined,
  onSelectSubNav = () => undefined,
  contentStyle,
  containerStyle,
  active,
  ...props
}: NavItemProps) => {
  const { css } = useFela();
  // const firstChild = subnav ? subnav[0] : null;
  const firstChild = subroute ? subroute[0] : null;
  const defaultRoute = firstChild
    ? `${pascalCase(title)}::${pascalCase(firstChild.routing)}`
    : title;

  return (
    <FelaComponent style={navItemStyle} {...props}>
      {({ style }: RenderProps): React.ReactFragment => (
        <CollapsiblePanel
          containerStyle={containerStyle ? [style, containerStyle] : style}
          minHeight={44}
          defaultState={active ? 'expanded' : 'collapsed'}
          contentStyle={[css(panelContentStyle), contentStyle]}
          title={title}
          icon={icon}
          active={active}
          iconStyle={props.iconStyle}
          onPress={() => onPress(defaultRoute)}
        >
          <>
            {(subroute || []).map((child, i) => {
              const route = `${pascalCase(title)}::${pascalCase(
                child.routing,
              )}`;
              return (
                <WithFeature key={i} feature={child.feature}>
                  <SubNavItem
                    key={i}
                    title={child.title}
                    active={selected === child.routing}
                    onPress={() => onSelectSubNav(route)}
                  />
                </WithFeature>
              );
            })}
          </>
        </CollapsiblePanel>
      )}
    </FelaComponent>
  );
};

NavItem.defaultProps = {
  subnav: [],
};

export default NavItem;
