import React, { useState, useCallback, useEffect } from 'react';
import { View, KeyboardAvoidingView } from 'react-native';
import { useFela } from 'react-fela';
import { Customer, StyleFn } from '@hitz-group/domain';
import TitleBar from '../../TitleBar/TitleBar';
import IconButton from '../../Button/IconButton';
import Modal from '../Modal';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Button/Button';
import {
  isNotEmpty,
  isValidEmail,
  isValidName,
  isValidNumber,
} from '../../../utils/validator';
import { countries } from 'countries-list';
import { useTranslation, usePhoneNumber } from '@hitz-group/localization';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import scale, { isWeb, isAndroid, isIos } from '../../../common/theme';
import { usePosState } from '../../../hooks/POSStateProvider';
export interface AddCustomerProps {
  name?: string;
  // If orderId provided (in open orders screen), the customer can be assigned to a specific order (useCart will use this order id)
  orderId?: string;
  assignCustomerToOrder?: (customer: Customer) => void;
}

const addCustomerContainerStyle: StyleFn = () => ({
  width: 620,
  alignSelf: 'center',
});

const rowStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1,
});

const cancelButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 10,
});

const formContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small * 1.5,
});

const subAddressContainer: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small * 1.5,
  width: 170,
});

const subAddressContainerText: StyleFn = ({ theme }) => ({
  width: 160,
  marginRight: theme.spacing.small,
  marginLeft: theme.spacing.small,
});
const pinCodeAddressContainer: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small * 1.5,
  width: scale.moderateScale(110),
});

const buttonContainerStyle: StyleFn = ({}) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 40,
  marginBottom: isWeb ? 0 : 20,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  width: 560,
  height: theme.button.footerButtonHeight,
});
const textStyle: StyleFn = () => ({
  left: 10,
});

const phonePrefixStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  backgroundColor: theme.colors.white,
  minWidth: 50,
  textAlign: 'left',
  paddingLeft: theme.padding.medium * 1.5,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  lineHeight: 24,
});

const phoneTextInputStyle: StyleFn = () => ({
  minWidth: isWeb ? 140 : 150,
});

const pinCodeAddressContainerText: StyleFn = () => ({
  width: 110,
});

const phonePrefixErrorBgStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger2,
});

export const formInputContainerStyle: StyleFn = () => ({
  width: 280,
});
const containerStyle: StyleFn = () => ({
  width: 620,
  minHeight: isWeb ? 450 : isAndroid ? 610 : 420,
  alignSelf: 'center',
  height: isWeb ? 450 : isAndroid ? 610 : 420,
  paddingTop: 0,
  paddingBottom: 0,
});
const headerStyle: StyleFn = ({ theme }) => ({
  width: 620,
  marginTop: theme.spacing.small,
  alignSelf: 'center',
});

interface CustomerForm {
  id?: string;
  firstName: string;
  lastName: string;
  line1: string;
  email: string;
  city: string;
  postalCode: string;
  country: string;
  phone: string;
}

const AddCustomer: React.FC<AddCustomerProps> = ({
  name,
  assignCustomerToOrder,
}: AddCustomerProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { closeModal } = useModal();
  const [errorMessage, setErrorMessage] = useState('');
  const [form, setForm] = useState<CustomerForm>({
    phone: '',
    email: '',
    line1: '',
    city: '',
    postalCode: '',
    country: 'AU',
    firstName: name?.split(' ')[0] || '',
    lastName: name?.split(' ')[1] || '',
  });

  const { addNewCustomer, error, loading } = usePosState('customers');
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  const isPhoneValid = isValidNumber(
    getFullFormattedPhoneNumber(form.country, form.phone),
  );

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (errorMessage !== '') {
      showNotification({ error: true, message: errorMessage });
    }
  }, [errorMessage, showNotification, translate]);

  const onChangeCountry = useCallback((country: string) => {
    setForm(form => ({
      ...form,
      country,
      phone: `+${countries[country].phone} `,
    }));
  }, []);

  const onChangeFormInput = useCallback(
    (prop: string, value: string) => {
      setForm(form => ({
        ...form,
        [prop]: value,
      }));
      if (errorMessage) {
        setErrorMessage('');
      }
    },
    [errorMessage],
  );

  const onAddNewCustomer = useCallback(async () => {
    const { firstName, lastName, email, phone } = form;
    if (firstName === '' && lastName === '') {
      setErrorMessage(translate('customer.emptyNameMessage'));
    } else if (email === '' && phone.length === 0) {
      setErrorMessage(translate('customer.emptyEmailandPhoneMessage'));
      return;
    } else if (email && !isValidEmail(email)) {
      setErrorMessage(translate('customer.invalidEmailMessage'));
      return;
    } else if (phone && !isPhoneValid) {
      setErrorMessage(translate('customer.invalidPhoneMessage'));
      return;
    } else {
      const preferredAddress = {
        line1: form.line1,
        city: form.city,
        postalCode: form.postalCode,
      };
      const newCustomer = await addNewCustomer({
        firstName,
        lastName,
        email,
        phone: getFullFormattedPhoneNumber(form.country, form.phone),
        preferredAddress,
      });
      if (newCustomer?.id) {
        assignCustomerToOrder && assignCustomerToOrder(newCustomer);
        closeModal();
        showNotification({
          success: true,
          message: translate('customer.successMessage'),
        });
      }
    }
  }, [
    form,
    addNewCustomer,
    translate,
    closeModal,
    showNotification,
    assignCustomerToOrder,
    isPhoneValid,
    getFullFormattedPhoneNumber,
  ]);

  const titleLeft = (
    <IconButton
      icon="times"
      containerStyle={css(cancelButtonStyle)}
      onPress={closeModal}
      testID="modal-close-button"
    />
  );

  return (
    <KeyboardAvoidingView behavior={isIos ? 'padding' : undefined}>
      <View style={css(addCustomerContainerStyle)}>
        <TitleBar
          primary
          title={translate('customer.addNewCustomer')}
          titleLeft={titleLeft}
          containerStyle={css(headerStyle)}
        />
        <Modal contentStyle={css(containerStyle)} showCloseButton={false}>
          <View style={css(rowStyle)}>
            <FormInput
              title={translate('form.firstName')}
              alignTitle={'left'}
              value={form.firstName}
              onChangeText={onChangeFormInput.bind(null, 'firstName')}
              verified={isValidName(form.firstName)}
              placeholder={'Jenny'}
              containerStyle={css(formInputContainerStyle)}
              testID="customer-first-name"
            />
            <FormInput
              title={translate('form.lastName')}
              alignTitle={'left'}
              value={form.lastName}
              onChangeText={onChangeFormInput.bind(null, 'lastName')}
              verified={isValidName(form.lastName)}
              placeholder={'Murtaugh'}
              containerStyle={css(formInputContainerStyle)}
              testID="customer-last-name"
            />
          </View>
          <View style={css(rowStyle)}>
            <View style={css(formContainerStyle)}>
              <FormInput
                title={translate('form.phoneNumber')}
                type="phone"
                alignTitle={'left'}
                country={form.country}
                verified={isPhoneValid}
                // error={!isPhoneValid}
                onChangeText={onChangeFormInput.bind(null, 'phone')}
                onSelectChange={onChangeCountry.bind(null)}
                onPressCountry={onChangeCountry.bind(null)}
                placeholder={'0423 456 789'}
                prefix={{
                  text: form.country
                    ? `+${countries[form.country].phone} `
                    : '',
                  textStyle: !isPhoneValid
                    ? css(phonePrefixStyle, phonePrefixErrorBgStyle)
                    : css(phonePrefixStyle),
                }}
                textInputStyle={css(phoneTextInputStyle)}
                containerStyle={css(formInputContainerStyle)}
                testID="customer-phone"
              />
            </View>
            <View style={css(formContainerStyle)}>
              <FormInput
                title={translate('common.emailAddress')}
                alignTitle={'left'}
                onChangeText={onChangeFormInput.bind(null, 'email')}
                verified={isValidEmail(form.email)}
                placeholder={'jenny@email.com'}
                containerStyle={css(formInputContainerStyle)}
                testID="customer-email"
              />
            </View>
          </View>

          <View style={css(rowStyle)}>
            <View style={css(formContainerStyle)}>
              <FormInput
                title={'Address Line 1'}
                alignTitle={'left'}
                value={form.line1}
                onChangeText={onChangeFormInput.bind(null, 'line1')}
                verified={isNotEmpty(form.line1)}
                placeholder={'Unit 4, 12 Example St'}
                containerStyle={css(formInputContainerStyle)}
                testID="customer-address-1"
              />
            </View>
            <View style={css(subAddressContainer)}>
              <FormInput
                title={'Suburb'}
                alignTitle={'left'}
                value={form.city}
                onChangeText={onChangeFormInput.bind(null, 'city')}
                verified={isValidName(form.city)}
                placeholder={'Albert Park'}
                containerStyle={css(subAddressContainerText)}
                textStyle={css(textStyle)}
                testID="customer-suburb"
              />
            </View>

            <View style={css(pinCodeAddressContainer)}>
              <FormInput
                title={'Post Code'}
                alignTitle={'left'}
                value={form.postalCode}
                onChangeText={onChangeFormInput.bind(null, 'postalCode')}
                verified={isNotEmpty(form.postalCode)}
                placeholder={'3000'}
                containerStyle={css(pinCodeAddressContainerText)}
                testID="customer-postcode"
              />
            </View>
          </View>
          <View style={css(buttonContainerStyle)}>
            <Button
              success
              fluid
              size="small"
              containerStyle={css(buttonStyle)}
              loading={loading}
              title={translate('customer.addNewCustomer')}
              onPress={onAddNewCustomer}
              testID="add-customer"
            />
          </View>
        </Modal>
      </View>
    </KeyboardAvoidingView>
  );
};

export default AddCustomer;
