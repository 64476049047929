import { StyleFn } from '@hitz-group/domain';
import scale, { isWeb, isAndroid } from '../../../common/theme';

const addCustomerContainerStyle: StyleFn = () => ({
  width: scale.moderateScale(600),
  alignSelf: 'center',
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1,
  marginVertical: theme.spacing.small,
});

const inrowStyle = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const textStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

const codeLableStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

const cityLableStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.medium,
});

const containerStyle: StyleFn = () => ({
  width: scale.moderateScale(500),
  minHeight: 450,
  alignSelf: 'center',
  height: isWeb ? 500 : isAndroid ? 610 : 550,
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: isWeb ? 0 : 20,
  marginTop: theme.spacing.medium,
});

const buttonStyle: StyleFn = ({}) => ({
  width: scale.moderateScale(280),
});

export const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(220),
  marginRight: theme.spacing.small / 2,
  marginLeft: theme.spacing.small / 2,
});

export const modalHeaderTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
  letterSpacing: -0.4,
  marginTop: theme.spacing.big + 20,
});
export const createCustomerTextColor: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
});
export const editCustomerTextColor: StyleFn = ({ theme }) => ({
  color: theme.colors.lightBlue,
});

const customBodyStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  justifyContent: 'flex-start',
  marginTop: theme.spacing.small,
  paddingTop: 0,
  paddingBottom: theme.padding.medium,
});

const subAddressContainerText: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(120),
  marginRight: theme.spacing.small,
  marginLeft: theme.spacing.medium,
});
const pinCodeAddressContainerText: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(85),
  marginRight: theme.padding.small,
  marginLeft: theme.spacing.small,
});

const phonePrefixStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  padding: theme.spacing.small,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  borderRadius: theme.radius.small,
  lineHeight: 18,
});

const phonePrefixErrorBgStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger2,
});

const phoneTextInputStyle: StyleFn = () => ({
  minWidth: isWeb ? 140 : 150,
});
const btnLabelStyle: StyleFn = () => ({
  textTransform: 'none',
});
const dismissLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
});

export const styles = {
  addCustomerContainerStyle,
  rowStyle,
  containerStyle,
  buttonContainerStyle,
  buttonStyle,
  formInputContainerStyle,
  modalHeaderTextStyle,
  customBodyStyle,
  subAddressContainerText,
  pinCodeAddressContainerText,
  phonePrefixStyle,
  phonePrefixErrorBgStyle,
  phoneTextInputStyle,
  btnLabelStyle,
  dismissLabelStyle,
  createCustomerTextColor,
  editCustomerTextColor,
  inrowStyle,
  textStyle,
  codeLableStyle,
  cityLableStyle,
};
