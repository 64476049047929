import {
  IntegrationApps,
  IntegrationPartner,
  Store,
  UpdateIntegrationPartnerInput,
} from '@hitz-group/domain';
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { View, ScrollView } from 'react-native';
import { keyBy } from 'lodash';
import { useStores } from '../../../../../../../src/hooks/app/useStores';
import { useNotification } from '../../../../../../../src/hooks/Notification';
import { useIntegrationPartners } from '../../../../../../../src/hooks/app/useIntegrationPartners/useIntegrationPartners';
import { Operation } from '../../../../../../../src/types/Operation';
import { stripProperties } from '../../../../../../../src/utils/stripObjectProps';
import LoadingIndicator from '../../../../../../../src/components/LoadingIndicator/LoadingIndicator';
import BackOfficeSection from '../../../../../../../src/components/BackOfficeSection/BackOfficeSection';
import TableComponent from '../../../../../../../src/components/TableComponent/TableComponent';
import Button from '../../../../../../../src/components/Button/Button';
import {
  onChangeStoreSettings,
  onChangeOnlineIntegrationStoreSettings,
  onUpdateDeliverItSettings,
} from '../../../../../../utils/OnlineOrderIntegrationHelpers';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import * as styles from './SettingsTab.styles';
import { StoreRow } from './StoreRow';
import { SettingsContext } from '../OnlineOrderIntegrationsSettingsTabs';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import IntegrationWebhookInfoModal from './IntegrationWebhookInfoModal';
import { useFeatures } from '../../../../../../hooks/app/features/useFeatures';

type dropDownType = {
  label: string;
  value: string;
};

export const SettingsTab: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  const [integrationSettings, setIntegrationSettings] = useState<
    Record<string, IntegrationPartner & { isNew?: boolean }>
  >({});

  const {
    stores,
    getStores,
    loading: storesLoading,
    error: storesErr,
  } = useStores();

  const {
    loading: integrationPartnerLoading,
    getIntegrationPartnerSettings,
    integrationPartners,
    updateIntegrationPartnerSettings,
    operation,
    error: integrationPartnerError,
  } = useIntegrationPartners();

  const route = useRoute();
  const context = useContext(SettingsContext);

  const params = context.params || (route.params as { app: IntegrationApps });
  const app = params.app;

  const { currentFeature, loadings: featureLoading } = useFeatures(
    params.app && params.app,
  );

  useFocusEffect(
    useCallback(() => {
      getStores();
      getIntegrationPartnerSettings({ appName: app });
    }, [getStores, getIntegrationPartnerSettings, app]),
  );

  const storesArray = useMemo(() => Object.values(stores), [stores]);

  const loading = integrationPartnerLoading || storesLoading;
  const error = integrationPartnerError || storesErr;
  const isUpdated = !error && !loading && operation === Operation.UPDATE;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const allOptions: Record<
    string,
    {
      menuOptions: dropDownType[];
      pricingGroupOptions: dropDownType[];
    }
  > = useMemo(() => {
    const result: Record<
      string,
      {
        menuOptions: dropDownType[];
        pricingGroupOptions: dropDownType[];
      }
    > = {};

    const storesValues = Object.values(stores);

    storesValues.forEach(eachStore => {
      const menuOptions: dropDownType[] = [];
      const pricingGroupOptions: dropDownType[] = [];
      menuOptions.push({ label: 'Please Select', value: '' });
      pricingGroupOptions.push({ label: 'Please Select', value: '' });
      (eachStore?.catalogues || []).forEach(eachCat => {
        menuOptions.push({ label: eachCat.name, value: eachCat.id });
      });

      (eachStore?.pricingGroups || []).forEach(eachPG => {
        pricingGroupOptions.push({ label: eachPG.name, value: eachPG.id });
      });

      result[eachStore.id] = {
        pricingGroupOptions,
        menuOptions,
      };
    });
    return result;
  }, [stores]);

  useEffect(() => {
    const integrationPartnerSettings = Object.values(integrationPartners).map(
      x => ({ ...x, isNew: false }),
    );
    const integrationSettingsByStore = keyBy(
      integrationPartnerSettings,
      'store',
    );
    setIntegrationSettings(integrationSettingsByStore);
  }, [integrationPartners]);

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeFeatures.settingsUpdatedSuccessfully', {
          appName: app,
        }),
      });
    }
  }, [app, isUpdated, showNotification, translate]);

  const onChange = useCallback(
    (storeId, key, value) => {
      setIntegrationSettings(prev => {
        let storeSettingsOld = { ...prev[storeId] };

        if (app === IntegrationApps.DELIVERIT) {
          storeSettingsOld = onChangeStoreSettings(
            storeId,
            key,
            value,
            storeSettingsOld,
            stores,
            app,
          );
        } else if (
          app === IntegrationApps.DOSHII ||
          app === IntegrationApps.DELIVERECT
        ) {
          storeSettingsOld = onChangeOnlineIntegrationStoreSettings(
            key,
            value,
            storeSettingsOld,
          );
        } else {
          storeSettingsOld = onChangeStoreSettings(
            storeId,
            key,
            value,
            storeSettingsOld,
            stores,
            app,
          );
        }
        return {
          ...prev,
          [storeSettingsOld.store as string]: storeSettingsOld,
        };
      });
    },
    [app, stores],
  );

  const isValidOnlineOrderingSetting = useCallback(
    (onlineOrderingSettings: UpdateIntegrationPartnerInput[] = []) => {
      return onlineOrderingSettings
        .filter(setting => setting.isActive)
        .every(setting => {
          const onlineOrderSetting = setting.preferences?.onlineOrdering;
          return (
            onlineOrderSetting?.menu !== '' &&
            onlineOrderSetting?.pricingGroup !== ''
          );
        });
    },
    [],
  );

  const onUpdateSettings = useCallback(() => {
    let integrationSettingsArray = Object.values(
      stripProperties(
        integrationSettings,
        '__typename',
        'token',
        'isNew',
        'accountId',
      ),
    ) as UpdateIntegrationPartnerInput[];

    if (app === IntegrationApps.DELIVERIT) {
      integrationSettingsArray = onUpdateDeliverItSettings(
        integrationSettingsArray,
        stores,
      );
    }

    if (integrationSettingsArray.length) {
      const isValid = isValidOnlineOrderingSetting(integrationSettingsArray);
      if (isValid) updateIntegrationPartnerSettings(integrationSettingsArray);
      else {
        showNotification({
          error: true,
          message: translate(
            'backOfficeFeatures.pleaseSelectMenuAndPricingGroup',
            {
              appName: app,
            },
          ),
        });
      }
    } else {
      showNotification({
        info: true,
        message: translate('backOfficeFeatures.noStoresIntegrationsEnabled', {
          appName: app,
        }),
      });
    }
  }, [
    integrationSettings,
    app,
    stores,
    isValidOnlineOrderingSetting,
    updateIntegrationPartnerSettings,
    showNotification,
    translate,
  ]);

  const onOpenStoreInfo = useCallback(
    (storeId: string) => {
      const storeInfo = stores[storeId];
      const webhookUrl = currentFeature?.feature?.webhookUrl || '';
      showModal(
        <IntegrationWebhookInfoModal
          storeId={storeId}
          webhookUrl={webhookUrl}
          storeName={storeInfo.name}
        />,
      );
    },
    [currentFeature?.feature?.webhookUrl, showModal, stores],
  );

  const columns = [
    {
      title: translate('backOfficeFeatures.venuesAndSettings'),
      width: 280,
    },
    {
      title: translate('backOfficeFeatures.menu'),
      width: 224,
      alignItems: 'flex-start',
    },
    {
      title: translate('backOfficeFeatures.priceList'),
      width: 224,
      alignItems: 'flex-start',
      containerStyle: { paddingLeft: 15 },
    },
    { title: '', width: 44 },
  ];

  const backOfficeSectionTitle =
    app === IntegrationApps.DELIVERIT
      ? 'backOfficeFeatures.deliveritSettings'
      : app === IntegrationApps.DOSHII
      ? 'backOfficeFeatures.doshiiSettings'
      : 'backOfficeFeatures.oolioOnlineStoreSettings';

  if (loading || featureLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.integrationsPage', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView
        testID="general-settings-screen"
        contentContainerStyle={css(styles.generalSettingsContainerWrapper)}
      >
        <View style={css(styles.pageStyle)}>
          <BackOfficeSection
            title={translate(backOfficeSectionTitle)}
            contentContainerStyle={css(styles.formStyle)}
          >
            <TableComponent
              columns={columns}
              data={storesArray}
              normalRows
              columnContainerStyle={css(styles.columnContainerStyle)}
              renderRow={(item: Store, index: number): React.ReactNode => {
                const settings =
                  integrationSettings[item.id]?.preferences?.onlineOrdering;
                const isActive = integrationSettings[item.id]?.isActive;
                return (
                  <StoreRow
                    id={
                      app === IntegrationApps.DELIVERIT
                        ? integrationSettings[item.id]?.id
                        : item.id
                    }
                    isEnabled={isActive}
                    menu={settings?.menu || ''}
                    pricingGroup={settings?.pricingGroup || ''}
                    name={item.name}
                    key={index}
                    menusOptions={allOptions[item.id]?.menuOptions}
                    pricingGroupsOptions={
                      allOptions[item.id]?.pricingGroupOptions
                    }
                    onChange={onChange}
                    storeId={item.id}
                    integrationApp={app}
                    onOpenStoreInfo={onOpenStoreInfo}
                  />
                );
              }}
            />
          </BackOfficeSection>
        </View>
      </ScrollView>
      <View style={css(styles.actionsContainerStyle)}>
        <Button
          fluid
          testID="save-changes"
          title={translate('button.saveChanges')}
          containerStyle={css(styles.saveButtonStyle)}
          labelStyle={css(styles.titleStyle)}
          onPress={onUpdateSettings}
        />
      </View>
    </>
  );
};
