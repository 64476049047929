import { StyleFn } from '@hitz-group/domain';
import {
  CheckoutOptions,
  TipCalculation,
  UpdateStoreCheckoutOptionsInput,
  RoundOffValue,
} from '@hitz-group/domain';
import React, { useState, useCallback, useEffect } from 'react';
import { useFela } from 'react-fela';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { View, ScrollView } from 'react-native';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../components/Button/Button';
import { useNotification } from '../../../../hooks/Notification';
import DropDown from '../../../../components/DropDown/DropDown';
import { pascalCase } from 'change-case';
import { useRoute } from '@react-navigation/native';
import { parseApolloError, noopHandler } from '../../../../utils/errorHandlers';
import { stripProperties } from '../../../../utils/stripObjectProps';
import {
  GET_CHECKOUT_OPTIONS_QUERY,
  UPDATE_STORE_CHECKOUT_OPTIONS,
} from '../../../../graphql/store';
import { useSession } from '../../../../hooks/app/useSession';

const RoundOffOption = ['CASH ROUNDING'];

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big + theme.spacing.medium,
});

const optionsContainerStyle: StyleFn = ({ theme, marginTop, zIndex }) => ({
  zIndex,
  marginTop: marginTop ? marginTop : 0,
  paddingBottom: theme.spacing.big,
  left: 10,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const checkoutPageContainerWrapper: StyleFn = ({ theme }) => ({
  width: 545,
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  textTransform: 'none',
  marginLeft: theme.spacing.small,
  alignItems: 'flex-start',
  width: 115,
  ...theme.font14RegularCharcoal,
});

const checkBoxTitleStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  textTransform: 'none',
  letterSpacing: -0.5,
  lineHeight: 21,
  fontSize: theme.fontSize.small,
  color: theme.colors.primary,
});

const saveButtonLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const dropDownContainerStyle: StyleFn = ({ theme, width }) => ({
  width: width,
  height: theme.input.height,
  marginBottom: theme.spacing.big / 2,
  marginRight: (theme.spacing.big + theme.spacing.medium) / 2,
});

const dropTitleStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

const extraPopoverStyle = (width: number) => ({
  width: width,
});
const itemContainer: StyleFn = () => ({
  height: 38 * 3,
});
const checkBoxContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.medium + theme.padding.small,
  marginLeft: -theme.spacing.big / 2,
  justifyContent: 'flex-start',
  height: theme.input.height,
  width: 370,
});

const optionsStyle: StyleFn = () => ({
  flexDirection: 'row',
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  marginHorizontal: theme.spacing.small,
  height: 17,
  width: 17,
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  marginHorizontal: theme.spacing.small,
  height: 17,
  width: 17,
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 38,
  marginTop: theme.spacing.medium,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingHorizontal: 0,
  marginBottom: theme.spacing.medium,
});

export const Checkout: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState({} as CheckoutOptions);
  const [session, updateSession] = useSession();

  const route = useRoute();

  const params = route.params as {
    storeId: string;
    venueId: string;
  };

  const { venueId, storeId } = params;

  const checkoutDetailsQuery = useQuery(GET_CHECKOUT_OPTIONS_QUERY, {
    variables: { id: storeId },
    fetchPolicy: 'cache-and-network',
  });

  const onUpdateStoreSuccess = (data: {
    updateStoreCheckoutOptions: { checkoutOptions: CheckoutOptions };
  }) => {
    const checkOutOptions = data?.updateStoreCheckoutOptions?.checkoutOptions;
    if (checkOutOptions) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyUpdated'),
      });
      updateCheckoutOptionInSession(checkOutOptions);
    }
  };

  const [updateStore, updateOperation] = useMutation(
    UPDATE_STORE_CHECKOUT_OPTIONS,
    {
      onError: noopHandler,
      onCompleted: onUpdateStoreSuccess,
      context: {
        headers: { venue: venueId, store: storeId },
      },
    },
  );

  useEffect((): void => {
    if (updateOperation.error) {
      showNotification({
        error: true,
        message: parseApolloError(updateOperation.error),
      });
    }
  }, [updateOperation.error, showNotification]);

  const updateCheckoutOptionInSession = useCallback(
    (input: CheckoutOptions) => {
      updateSession({
        ...session,
        currentStore: {
          ...session.currentStore,
          checkoutOptions: input,
        },
      });
    },
    [session, updateSession],
  );

  useEffect(() => {
    if (checkoutDetailsQuery.error) {
      showNotification({
        error: true,
        message: parseApolloError(checkoutDetailsQuery.error),
      });
    }
  }, [checkoutDetailsQuery.error, showNotification]);

  useEffect(() => {
    if (checkoutDetailsQuery.data) {
      setForm({
        ...checkoutDetailsQuery.data.store.checkoutOptions,
      });
    }
  }, [checkoutDetailsQuery.data]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onPressSave = useCallback((): void => {
    let formTemp = {
      checkoutOptions: {
        ...form,
        enableTips: form?.enableTips || false,
      } as CheckoutOptions,
      id: storeId,
    };
    // removing __typename from object
    formTemp = stripProperties(formTemp, '__typename');
    updateStore({
      variables: {
        input: formTemp as unknown as UpdateStoreCheckoutOptionsInput,
      },
    });
  }, [form, updateStore, storeId]);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView
          testID="checkout-screen"
          contentContainerStyle={css(checkoutPageContainerWrapper)}
        >
          <BackOfficeSection
            title={translate('storesSettings.checkoutOptions')}
            containerStyle={css(backOfficeContainerStyle)}
          >
            <View style={css(optionsContainerStyle({ theme, zIndex: 1001 }))}>
              <Button
                title={translate('backOfficeSettings.enableRoundOff')}
                labelStyle={css(titleStyle)}
                containerStyle={css(checkBoxTitleContainer({ theme }))}
                onPress={onChange.bind(
                  null,
                  'enableRoundOff',
                  !form.enableRoundOff,
                )}
                iconPosition={'left'}
                icon={form.enableRoundOff ? 'check' : 'null'}
                iconContainerStyle={
                  form.enableRoundOff
                    ? css(checkIconContainer)
                    : css(unCheckContainer)
                }
                iconProps={{
                  color: form.enableRoundOff
                    ? theme.colors.success
                    : theme.colors.paragraph,
                  size: 15,
                }}
              />

              {form.enableRoundOff && (
                <View style={css(optionsStyle)}>
                  <DropDown
                    options={RoundOffOption.map(value => ({
                      value: value,
                      label: pascalCase(value, { delimiter: ' ' }),
                    }))}
                    title={translate('backOfficeSettings.roundingMethod')}
                    selectedValue={form.roundingMethod}
                    containerStyle={css(
                      dropDownContainerStyle({ theme, width: 260 }),
                    )}
                    isDisabled={true}
                    onValueChange={onChange.bind(null, 'roundingMethod')}
                    itemsContainerStyle={css(itemContainer)}
                    titleStyle={css(dropTitleStyle)}
                    extraPopoverStyle={css(extraPopoverStyle(260))}
                  />
                  <DropDown
                    options={Object.keys(RoundOffValue).map((value, index) => ({
                      value: value,
                      label: Object.values(RoundOffValue)[index],
                    }))}
                    title={translate('backOfficeSettings.value')}
                    selectedValue={form.roundOffValue}
                    containerStyle={css(
                      dropDownContainerStyle({ theme, width: 100 }),
                    )}
                    onValueChange={onChange.bind(null, 'roundOffValue')}
                    itemsContainerStyle={css(itemContainer)}
                    titleStyle={css(dropTitleStyle)}
                    extraPopoverStyle={css(extraPopoverStyle(100))}
                  />
                </View>
              )}
            </View>

            <View
              style={css(
                optionsContainerStyle({
                  theme,
                  zIndex: 1000,
                }),
              )}
            >
              <Button
                title={translate('backOfficeSettings.enableTipping')}
                labelStyle={css(titleStyle)}
                onPress={onChange.bind(null, 'enableTips', !form.enableTips)}
                iconPosition={'left'}
                icon={form.enableTips ? 'check' : 'null'}
                containerStyle={css(checkBoxTitleContainer({ theme }))}
                iconContainerStyle={
                  form.enableTips
                    ? css(checkIconContainer)
                    : css(unCheckContainer)
                }
                iconProps={{
                  color: form.enableTips
                    ? theme.colors.success
                    : theme.colors.paragraph,
                  size: 15,
                }}
              />

              {form.enableTips && (
                <View style={css(optionsStyle)}>
                  <DropDown
                    options={Object.keys(TipCalculation).map(value => ({
                      value: value,
                      label: pascalCase(value, { delimiter: ' ' }),
                    }))}
                    title={translate('backOfficeSettings.tipCalculation')}
                    selectedValue={form.tipCalculation}
                    containerStyle={css(
                      dropDownContainerStyle({ theme, width: 260 }),
                    )}
                    onValueChange={onChange.bind(null, 'tipCalculation')}
                    titleStyle={css(dropTitleStyle)}
                    extraPopoverStyle={css(extraPopoverStyle(260))}
                  />
                  <Button
                    title={translate('backOfficeSettings.withdrawTips')}
                    labelStyle={css(checkBoxTitleStyle)}
                    onPress={onChange.bind(
                      null,
                      'withdrawTipAtClosureTime',
                      !form.withdrawTipAtClosureTime,
                    )}
                    fluid
                    iconPosition={'left'}
                    containerStyle={css(checkBoxContainerStyle)}
                    iconContainerStyle={
                      form.withdrawTipAtClosureTime
                        ? css(checkIconContainer)
                        : css(unCheckContainer)
                    }
                    icon={form.withdrawTipAtClosureTime ? 'check' : 'null'}
                    iconProps={{
                      color: theme.colors.success,
                      size: 15,
                    }}
                  />
                </View>
              )}
            </View>
          </BackOfficeSection>
        </ScrollView>

        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(saveButtonLabelStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
