import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { View, ScrollView } from 'react-native';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@hitz-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  OrderTypeCode,
  StyleFn,
  FeatureContext,
  Features,
  Icons,
} from '@hitz-group/domain';
import {
  DeviceProfile,
  UpdateDeviceProfileOptionsInput,
} from '@hitz-group/domain';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import { pick } from 'lodash';
import { useNotification } from '../../../../../hooks/Notification';
import { useDeviceProfiles } from '../../../../../hooks/app/useDeviceProfiles';
import { DeviceProfileSettingsFooter } from './DeviceProfileSettingsFooter';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import { WithFeature } from '../../../../../components/features/WithFeature';
import { useFeatures } from '../../../../../hooks/app/features/useFeatures';
import IconButton from '../../../../../components/Button/IconButton';
import { Label } from '../../../../../components/FormInput/FormInput';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  paddingBottom: theme.padding.large * 2,
});

const formContainerStyleSmall: StyleFn = ({ theme }) => ({
  width: 545,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: theme.spacing.small,
});

const backOfficeContainerStyle: StyleFn = ({}) => ({
  width: 545,
  marginTop: 40,
});

const checkBoxTitleStyle: StyleFn = ({ theme, isActive }) => ({
  ...theme.font14Regular,
  textTransform: 'none',
  color: isActive ? theme.colors.charcoal : theme.colors.paragraph,
});

const viewStyle: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  marginLeft: theme.spacing.small,
  width: 260,
  height: 38,
});

export const Options: React.FC = () => {
  const { css, theme } = useFela();
  const route = useRoute();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState({} as DeviceProfile);
  const params = route.params as {
    deviceProfileId: string;
    storeId: string;
    venueId: string;
  };

  const deviceProfileId = params.deviceProfileId || '';
  const storeId = params.storeId || '';
  const venueId = params.venueId || '';

  const {
    deviceProfiles: deviceProfiles,
    orderTypes,
    loading,
    error,
    updatedDeviceProfileId,
    unAssignedDeviceProfileId,
    deletedDeviceProfile,
    getOrderTypes,
    updateDeviceProfileOptions,
    deleteDeviceProfile,
    unAssignDeviceProfile,
  } = useDeviceProfiles({ deviceProfileId, storeId, venueId });

  const { getFeatureVenues } = useFeatures();

  useEffect(() => {
    getOrderTypes();
  }, [getOrderTypes]);

  useEffect(() => {
    getFeatureVenues();
  }, [getFeatureVenues]);

  useEffect(() => {
    if (deviceProfiles[deviceProfileId]) {
      setForm({
        ...deviceProfiles[deviceProfileId],
      });
    }
  }, [deviceProfiles, setForm, deviceProfileId]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedDeviceProfileId, showNotification, translate]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const diableSeatManagementIfFloorViewIsDisabled = (
    updateParams: UpdateDeviceProfileOptionsInput,
  ) => {
    if (updateParams.enableFloorView) return updateParams;
    else return { ...updateParams, enableSeatManagement: false };
  };

  const onPressSave = useCallback((): void => {
    let updateParams: UpdateDeviceProfileOptionsInput = pick(form, [
      'id',
      'enableAudio',
      'receiptPrintOption',
      'signatureAndTipping',
      'enableCashManagement',
      'enableFloorView',
      'enableCourses',
      'enableSeatManagement',
      'enableOnlineOrders',
    ]);

    if (form.enableFloorView) {
      const dineInOrderTypeId =
        orderTypes &&
        (orderTypes.find(orderType => orderType.code === OrderTypeCode.DINE_IN)
          ?.id as string);

      updateParams.orderTypes = form.orderTypes.map(orderType => orderType.id);

      if (!updateParams.orderTypes.includes(dineInOrderTypeId || '')) {
        updateParams.orderTypes = [
          ...updateParams.orderTypes,
          dineInOrderTypeId,
        ];
      }
    }
    updateParams = diableSeatManagementIfFloorViewIsDisabled(updateParams);
    updateDeviceProfileOptions(updateParams);
  }, [form, updateDeviceProfileOptions, orderTypes]);

  useEffect(() => {
    if (deletedDeviceProfile) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: deviceProfiles[deviceProfileId]?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: deviceProfiles[deviceProfileId]?.store?.id,
        screen: 'DeviceProfiles',
      });
    }
  }, [
    deletedDeviceProfile,
    navigation,
    deviceProfiles,
    showNotification,
    translate,
    deviceProfileId,
  ]);

  useEffect(() => {
    if (unAssignedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeSettings.deviceProfileUnassignedSuccessfully',
        ),
      });
    }
  }, [showNotification, translate, unAssignedDeviceProfileId]);

  const setOrderTypeOnEnablingFloorView = useCallback((): void => {
    if (!form.enableFloorView) {
      const dineInOrderType = orderTypes?.find(
        orderType => orderType?.code == OrderTypeCode.DINE_IN,
      );
      dineInOrderType &&
        setForm({ ...form, orderTypes: [...form.orderTypes, dineInOrderType] });
    }
    onChange('enableFloorView', !form.enableFloorView);
  }, [form, onChange, orderTypes]);

  const onChangeEnableSeatManagement = () => {
    if (!form.enableSeatManagement && !form.enableFloorView)
      showNotification({
        error: true,
        message: translate(
          'deviceProfileSettings.enableSeatManagementExceptionForFloorView',
        ),
      });
    else onChange('enableSeatManagement', !form.enableSeatManagement);
  };
  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView
          testID="deviceProfile-settings-screen"
          contentContainerStyle={css(generalSettingsContainerWrapper)}
        >
          <BackOfficeSection
            title={translate('tabNames.features')}
            contentContainerStyle={css(formContainerStyleSmall)}
            containerStyle={css(backOfficeContainerStyle)}
            iconColor={theme.colors.blue}
          >
            <WithFeature
              feature={{
                name: Features.TABLE_MANAGEMENT,
                context: FeatureContext.VENUE,
              }}
              venue={params.venueId}
            >
              <View
                style={css(viewStyle)}
                testID="deviceProfile-enableFloorView"
              >
                <IconButton
                  icon={form.enableFloorView ? 'toggle-on' : 'toggle-off'}
                  iconColor={
                    form.enableFloorView
                      ? theme.colors.success
                      : theme.colors.paragraph
                  }
                  onPress={setOrderTypeOnEnablingFloorView}
                />
                <Label
                  textStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
                >
                  {translate('backOfficeSettings.floorView')}
                </Label>
              </View>
            </WithFeature>

            <WithFeature
              feature={{
                name: Features.COURSES,
                context: FeatureContext.VENUE,
              }}
              venue={params.venueId}
            >
              <View style={css(viewStyle)} testID="deviceProfile-enableCourses">
                <IconButton
                  icon={form.enableCourses ? 'toggle-on' : 'toggle-off'}
                  iconColor={
                    form.enableCourses
                      ? theme.colors.success
                      : theme.colors.paragraph
                  }
                  onPress={onChange.bind(
                    null,
                    'enableCourses',
                    !form.enableCourses,
                  )}
                />
                <Label
                  textStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
                >
                  {translate('deviceProfileSettings.enableCourses')}
                </Label>
              </View>
            </WithFeature>

            <View
              style={css(viewStyle)}
              testID="deviceProfile-enableSeatManagement"
            >
              <IconButton
                icon={form.enableSeatManagement ? 'toggle-on' : 'toggle-off'}
                iconColor={
                  form.enableSeatManagement
                    ? theme.colors.success
                    : theme.colors.paragraph
                }
                onPress={onChangeEnableSeatManagement}
              />
              <Label
                textStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              >
                {translate('deviceProfileSettings.enableSeatManagement')}
              </Label>
            </View>

            <View
              style={css(viewStyle)}
              testID="deviceProfile-enableOnlineOrders"
            >
              <IconButton
                icon={form.enableOnlineOrders ? 'toggle-on' : 'toggle-off'}
                iconColor={
                  form.enableOnlineOrders
                    ? theme.colors.success
                    : theme.colors.paragraph
                }
                onPress={onChange.bind(
                  null,
                  'enableOnlineOrders',
                  !form.enableOnlineOrders,
                )}
              />
              <Label
                textStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              >
                {translate('deviceProfileSettings.enableOnlineOrder')}
              </Label>
            </View>
          </BackOfficeSection>
          {/* <BackOfficeSection
            title={translate('backOfficeSettings.audioNotifications')}
            contentContainerStyle={css(formContainerStyleSmall)}
            containerStyle={css(backOfficeContainerStyle)}
            titleBorderBottom
          >
            <Button
              title={translate('deviceProfileSettings.onlineOrders')}
              labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              fluid
              testID="deviceProfile-enableAudio"
              iconPosition={'left'}
              onPress={onChange.bind(null, 'enableAudio', !form.enableAudio)}
              containerStyle={css(checkBoxTitleContainer({ theme }))}
              iconContainerStyle={
                form.enableAudio
                  ? css(checkIconContainer)
                  : css(unCheckContainer)
              }
              icon={form.enableAudio ? 'check' : 'null'}
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            />

            <View>
              <Text style={css(labelTextStyle)}>
                {translate('deviceProfileSettings.onlineOrdersDesc')}
              </Text>
            </View>
          </BackOfficeSection> */}

          <BackOfficeSection
            title={translate('backOfficeSettings.customerReceiptPrinting')}
            contentContainerStyle={css(formContainerStyleSmall)}
            containerStyle={css(backOfficeContainerStyle)}
            iconColor={theme.colors.blue}
          >
            <View
              style={css(viewStyle)}
              testID="deviceProfile-receiptPrintOption-PROMPT"
            >
              <IconButton
                icon={
                  form.receiptPrintOption === 'PROMPT'
                    ? 'recordAudio'
                    : 'circle'
                }
                iconSize={20}
                iconColor={theme.colors.success}
                onPress={onChange.bind(null, 'receiptPrintOption', 'PROMPT')}
              />
              <Label
                textStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              >
                {translate('deviceProfileSettings.promptForPrintEmailReceipts')}
              </Label>
            </View>

            <View
              style={css(viewStyle)}
              testID="deviceProfile-receiptPrintOption-AUTO"
            >
              <IconButton
                icon={
                  form.receiptPrintOption === 'AUTO' ? 'recordAudio' : 'circle'
                }
                iconSize={20}
                iconColor={theme.colors.success}
                onPress={onChange.bind(null, 'receiptPrintOption', 'AUTO')}
              />
              <Label
                textStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              >
                {translate('deviceProfileSettings.printReceiptsAutomatically')}
              </Label>
            </View>

            <View
              style={css(viewStyle)}
              testID="deviceProfile-receiptPrintOption-NO_PRINT"
            >
              <IconButton
                icon={
                  form.receiptPrintOption === 'NO_PRINT'
                    ? 'recordAudio'
                    : 'circle'
                }
                iconSize={20}
                iconColor={theme.colors.success}
                onPress={onChange.bind(null, 'receiptPrintOption', 'NO_PRINT')}
              />
              <Label
                textStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              >
                {translate('deviceProfileSettings.doNotPrintOrPrompt')}
              </Label>
            </View>
          </BackOfficeSection>

          {/* <BackOfficeSection
            title={translate(
              'backOfficeSettings.signatureTippingMerchantCopies',
            )}
            contentContainerStyle={css(formContainerStyleSmall)}
            containerStyle={css(backOfficeContainerStyle)}
            titleBorderBottom
          >
            <Button
              title={translate('deviceProfileSettings.tipSignOnPaymentScreen')}
              labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              testID="deviceProfile-signatureAndTipping-TIP_AND_SIGN_PAYMENT_SCREEN"
              fluid
              iconPosition={'left'}
              onPress={onChange.bind(
                null,
                'signatureAndTipping',
                'TIP_AND_SIGN_PAYMENT_SCREEN',
              )}
              containerStyle={css(checkBoxTitleContainer({ theme }))}
              iconContainerStyle={css(radioCheckStyle)}
              icon={
                form.signatureAndTipping === 'TIP_AND_SIGN_PAYMENT_SCREEN'
                  ? 'recordAudio'
                  : 'circle'
              }
              iconProps={{
                color: theme.colors.success,
                size: 20,
              }}
            />

            <View>
              <Text style={css(labelTextStyle)}>
                {translate('deviceProfileSettings.tipSignOnPaymentScreenDesc')}
              </Text>
            </View>

            <Button
              title={translate(
                'deviceProfileSettings.tipSignOnPaymentDeviceProfile',
              )}
              labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              testID="deviceProfile-signatureAndTipping-TIP_AND_SIGN_PAYMENT_DEVICE"
              fluid
              iconPosition={'left'}
              onPress={onChange.bind(
                null,
                'signatureAndTipping',
                'TIP_AND_SIGN_PAYMENT_DEVICE',
              )}
              containerStyle={css(checkBoxTitleContainer({ theme }))}
              iconContainerStyle={css(radioCheckStyle)}
              icon={
                form.signatureAndTipping === 'TIP_AND_SIGN_PAYMENT_DEVICE'
                  ? 'recordAudio'
                  : 'circle'
              }
              iconProps={{
                color: theme.colors.success,
                size: 20,
              }}
            />

            <View>
              <Text style={css(labelTextStyle)}>
                {translate(
                  'deviceProfileSettings.tipSignOnPaymentDeviceProfileDesc',
                )}
              </Text>
            </View>

            <Button
              title={translate('deviceProfileSettings.tipSignOnBillReceipt')}
              labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              testID="deviceProfile-signatureAndTipping-TIP_AND_SIGN_RECEIPT"
              fluid
              iconPosition={'left'}
              onPress={onChange.bind(
                null,
                'signatureAndTipping',
                'TIP_AND_SIGN_RECEIPT',
              )}
              containerStyle={css(checkBoxTitleContainer({ theme }))}
              iconContainerStyle={css(radioCheckStyle)}
              icon={
                form.signatureAndTipping === 'TIP_AND_SIGN_RECEIPT'
                  ? 'recordAudio'
                  : 'circle'
              }
              iconProps={{
                color: theme.colors.success,
                size: 20,
              }}
            />

            <View>
              <Text style={css(labelTextStyle)}>
                {translate('deviceProfileSettings.tipSignOnBillReceiptDesc')}
              </Text>
            </View>

            <Button
              title={translate('deviceProfileSettings.doNothing')}
              labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              testID="deviceProfile-signatureAndTipping-NONE"
              fluid
              iconPosition={'left'}
              onPress={onChange.bind(null, 'signatureAndTipping', 'NONE')}
              containerStyle={css(checkBoxTitleContainer({ theme }))}
              iconContainerStyle={css(radioCheckStyle)}
              icon={
                form.signatureAndTipping === 'NONE' ? 'recordAudio' : 'circle'
              }
              iconProps={{
                color: theme.colors.success,
                size: 20,
              }}
            />

            <View>
              <Text style={css(labelTextStyle)}>
                {translate('deviceProfileSettings.doNothingDesc')}
              </Text>
            </View>
          </BackOfficeSection>
          */}

          <BackOfficeSection
            title={translate('backOfficeSettings.cashManagement')}
            contentContainerStyle={css(formContainerStyleSmall)}
            containerStyle={css(backOfficeContainerStyle)}
            iconColor={theme.colors.blue}
          >
            <View
              style={css(viewStyle)}
              testID="deviceProfile-enableCashManagement"
            >
              <IconButton
                icon={
                  form.enableCashManagement
                    ? Icons.CheckSquare
                    : Icons.SquareFull
                }
                iconSize={20}
                iconColor={
                  form.enableCashManagement
                    ? theme.colors.success
                    : theme.colors.paragraph
                }
                onPress={onChange.bind(
                  null,
                  'enableCashManagement',
                  !form.enableCashManagement,
                )}
              />
              <Label
                textStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
              >
                {translate('deviceProfileSettings.enableCashManagement')}
              </Label>
            </View>
          </BackOfficeSection>
        </ScrollView>
        <DeviceProfileSettingsFooter
          deleteDeviceProfile={deleteDeviceProfile}
          unAssignDeviceProfile={unAssignDeviceProfile}
          onPressSave={onPressSave}
          deviceProfileId={deviceProfileId}
          deviceProfiles={deviceProfiles}
          status={form.status}
        />
      </View>
    </>
  );
};
