import {
  UpdatePricingGroupInput,
  CreatePricingGroupInput,
  DEFAULT_PRICING_GROUP,
  DateRange,
  DayOfWeek,
  TimeRoutine,
  Features,
} from '@hitz-group/domain';
import {
  DEFAULT_ENTITY_ID,
  PricingGroup,
  SELECT_ALL,
} from '@hitz-group/domain';
import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation } from '@hitz-group/localization';
import { usePricingGroups } from '../../../../../hooks/app/usePricingGroups';
import { Operation } from '../../../../../types/Operation';
import { DetailForm } from './DetailForm';
import AlertModal from '../../../../../components/Modals/AlertModal';
import { useModal } from '@hitz-group/rn-use-modal';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useAvailabilityOptions } from '../../../../../hooks/app/useAvailabilityOptions';
import { PriceListContext } from './PriceListTabNavigator';
import keyBy from 'lodash/keyBy';
import {
  defaultEndTime,
  defaultStartTime,
} from '../../../../../utils/dateHelper';
import { stripProperties } from '../../../../../utils/stripObjectProps';

interface Options {
  value: string;
  label: string;
}

export const PriceListDetailsTab: React.FC = () => {
  const priceListContext = useContext(PriceListContext);
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const route = useRoute();
  const { showModal, closeModal } = useModal();
  const {
    pricingGroups,
    updatePricingGroups,
    createPricingGroup,
    deletePricingGroups,
    error: pricingGroupErr,
    operation,
    loading,
  } = usePricingGroups();
  const {
    venues: allVenues,
    salesChannels,
    orderTypes,
    stores: allStores,
  } = useAvailabilityOptions();

  const venues = useMemo(() => {
    const allowedVenues = allVenues.filter(venue => {
      const priceListFeature = venue.features.find(
        feature => feature.name === Features.PRICE_LIST,
      );
      if (priceListFeature?.enabledByDefault) {
        return venue;
      }
    });
    return allowedVenues;
  }, [allVenues]);

  const stores = useMemo(() => {
    const allowedStores = allStores.filter(store => {
      const venue = venues.find(venue => venue.value === store.venue.id);
      if (venue) {
        return store;
      }
    });
    return allowedStores;
  }, [allStores, venues]);

  const [pricingGroupData, setPricingGroupData] = useState<PricingGroup>(
    {} as PricingGroup,
  );

  const params =
    priceListContext.params ||
    (route.params as {
      pricingGroupId: string;
      screen?: string;
      isNavigatedFromCreate?: boolean;
    });

  const pricingGroupId = params?.pricingGroupId || '';
  const isDefault = pricingGroupData?.name === DEFAULT_PRICING_GROUP;

  const options = useMemo(() => {
    const selectedVenues = keyBy(pricingGroupData?.venues, 'id');
    const stores: Options[] = [];
    venues
      .filter(x => selectedVenues[x.value])
      .forEach(el => {
        stores.push(...el.stores);
      });
    const selectedSalesChannels = keyBy(pricingGroupData?.salesChannels, 'id');
    const orderTypes: Options[] = [];
    salesChannels
      .filter(x => selectedSalesChannels[x.value])
      .forEach(el => {
        orderTypes.push(...el.orderTypes);
      });
    return {
      stores,
      orderTypes,
    };
  }, [pricingGroupData, venues, salesChannels]);

  useEffect(() => {
    navigation.setParams(params);
  }, [params, navigation]);

  useEffect(() => {
    const pricingGroupData: PricingGroup = pricingGroups[pricingGroupId];
    if (pricingGroupData) {
      setPricingGroupData(pricingGroupData);
    }
  }, [pricingGroupId, pricingGroups]);

  useEffect(() => {
    if (!pricingGroupErr && operation === Operation.UPDATE && !loading) {
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupUpdatedSuccessfully'),
      });
    }
  }, [operation, loading, showNotification, translate, pricingGroupErr]);

  useEffect(() => {
    if (!pricingGroupErr && operation === Operation.CREATE && !loading) {
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupCreatedSuccessfully'),
      });
      navigation.goBack();
    }
  }, [
    operation,
    loading,
    showNotification,
    translate,
    pricingGroupErr,
    navigation,
  ]);

  useEffect(() => {
    if (pricingGroupErr) {
      showNotification({
        error: true,
        message: pricingGroupErr,
      });
    }
  }, [pricingGroupErr, showNotification, translate]);

  useEffect(() => {
    if (!loading && !pricingGroupErr && operation === Operation.DELETE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('pricings.pricingGroupsDeletedSuccessfully'),
      });
      navigation.goBack();
    }
  }, [
    loading,
    operation,
    closeModal,
    showNotification,
    translate,
    pricingGroupErr,
    navigation,
  ]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <AlertModal
        title={translate('pricings.deletingPricingGroups')}
        actionTitle={translate('dialog.deleteTitle')}
        message={translate('pricings.deletePricingGroupMessage', {
          name: pricingGroupData.name,
        })}
        onConfirm={() => {
          deletePricingGroups([pricingGroupData.id]);
          closeModal();
        }}
      />,
    );
  }, [
    showModal,
    closeModal,
    deletePricingGroups,
    translate,
    pricingGroupData?.id,
    pricingGroupData?.name,
  ]);

  const onCreateTimeSlot = useCallback(() => {
    const newTimeBlock: TimeRoutine = {
      day: DayOfWeek.ALL,
      isActive: true,
      timeSlot: {
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      },
    };
    const defaultDateRange: DateRange = {
      startDate: Date.now(),
      endDate: Date.now(),
    };
    setPricingGroupData(prevData => {
      return {
        ...prevData,
        schedule: {
          dateRange: prevData.schedule?.dateRange || defaultDateRange,
          timeBlocks: [...(prevData.schedule?.timeBlocks || []), newTimeBlock],
        },
      };
    });
  }, []);

  const onDeleteTimeSlot = useCallback(idx => {
    const defaultDateRange: DateRange = {
      startDate: Date.now(),
      endDate: Date.now(),
    };
    setPricingGroupData(prevData => {
      const updatedTimeBlock = (prevData.schedule?.timeBlocks || []).filter(
        (x, index) => index !== idx,
      );
      return {
        ...prevData,
        schedule: {
          dateRange: prevData.schedule?.dateRange || defaultDateRange,
          timeBlocks: updatedTimeBlock,
        },
      };
    });
  }, []);

  const onChange = useCallback(
    (prop: string, value): void => {
      switch (prop) {
        case 'venuesAndStores': {
          setPricingGroupData(form => {
            return {
              ...form,
              venues: venues.map(venue => ({ id: venue.value })),
              stores: stores.map(store => ({ id: store.value })),
            } as unknown as PricingGroup;
          });
          break;
        }
        case 'venues': {
          setPricingGroupData(form => {
            if (value.includes(SELECT_ALL)) {
              return {
                ...form,
                venues: venues.map(x => ({ id: x.value })),
              };
            }
            return {
              ...form,
              venues: value.map((x: Array<string>) => ({ id: x })),
            };
          });
          break;
        }
        case 'orderTypes': {
          setPricingGroupData(form => {
            if (value.includes(SELECT_ALL)) {
              return {
                ...form,
                orderTypes: orderTypes.map(x => ({ id: x.value })),
              };
            }
            return {
              ...form,
              orderTypes: value.map((x: Array<string>) => ({ id: x })),
            };
          });
          break;
        }
        case 'stores': {
          setPricingGroupData(form => {
            if (value.includes(SELECT_ALL)) {
              return {
                ...form,
                stores: stores.map(x => ({ id: x.value })),
              };
            }
            return {
              ...form,
              stores: value.map((x: Array<string>) => ({ id: x })),
            };
          });
          break;
        }
        case 'salesChannels': {
          setPricingGroupData(form => {
            if (value.includes(SELECT_ALL)) {
              return {
                ...form,
                salesChannels: salesChannels.map(x => ({ id: x.value })),
              };
            }
            return {
              ...form,
              salesChannels: value.map((x: Array<string>) => ({ id: x })),
            };
          });
          break;
        }
        case 'dateRange': {
          setPricingGroupData(form => {
            return {
              ...form,
              schedule: {
                timeBlocks: form.schedule?.timeBlocks || [],
                dateRange: value as DateRange,
              },
            };
          });
          break;
        }
        case 'timeSlot': {
          setPricingGroupData(form => {
            const updatedTimeBlock = (form.schedule?.timeBlocks || []).map(
              (timeBlock, index) => {
                if (index === value.idx) {
                  return {
                    day: value?.day || timeBlock.day,
                    isActive: timeBlock.isActive,
                    timeSlot: {
                      startTime:
                        value?.startTime || timeBlock.timeSlot.startTime,
                      endTime: value?.endTime || timeBlock.timeSlot.endTime,
                    },
                  };
                }
                return timeBlock;
              },
            );
            const defaultDateRange: DateRange = {
              startDate: Date.now(),
              endDate: Date.now(),
            };
            return {
              ...form,
              schedule: {
                timeBlocks: updatedTimeBlock,
                dateRange: form.schedule?.dateRange || defaultDateRange,
              },
            };
          });
          break;
        }
        default: {
          setPricingGroupData(form => {
            return {
              ...form,
              [prop]: value,
            };
          });
        }
      }
    },
    [salesChannels, stores, orderTypes, venues],
  );

  useEffect(() => {
    if (params.isNavigatedFromCreate && venues && venues.length === 1) {
      onChange('venuesAndStores', SELECT_ALL);
    }
  }, [onChange, params.isNavigatedFromCreate, venues]);

  const onSavePricingGroup = useCallback((): void => {
    if (!pricingGroupData.name || pricingGroupData.name.trim().length === 0) {
      showNotification({
        error: true,
        message: translate('pricings.pricingNameIsRequired'),
      });
      return;
    }
    if (pricingGroupId === DEFAULT_ENTITY_ID) {
      const createPricingGroupInput = {
        name: pricingGroupData.name,
        venues: (pricingGroupData.venues || []).map(x => x.id),
        isActive: true,
        orderTypes: (pricingGroupData.orderTypes || []).map(x => x.id),
        prices: pricingGroupData.prices || [],
        stores: (pricingGroupData.stores || []).map(x => x.id),
        salesChannels: (pricingGroupData.salesChannels || []).map(x => x.id),
        schedule: pricingGroupData.schedule
          ? stripProperties(pricingGroupData.schedule, '__typename')
          : null,
      } as unknown as CreatePricingGroupInput;
      createPricingGroup(createPricingGroupInput);
    } else {
      const updatePricingGroupInput = {
        id: pricingGroupData.id,
        name: pricingGroupData.name,
        venues: (pricingGroupData.venues || []).map(x => x.id),
        isActive: true,
        orderTypes: (pricingGroupData.orderTypes || []).map(x => x.id),
        stores: (pricingGroupData.stores || []).map(x => x.id),
        salesChannels: (pricingGroupData.salesChannels || []).map(x => x.id),
        schedule: pricingGroupData.schedule
          ? stripProperties(pricingGroupData.schedule, '__typename')
          : null,
      } as unknown as UpdatePricingGroupInput;
      updatePricingGroups([updatePricingGroupInput]);
    }
  }, [
    createPricingGroup,
    updatePricingGroups,
    pricingGroupId,
    pricingGroupData,
    translate,
    showNotification,
  ]);

  return (
    <DetailForm
      onChange={onChange}
      onSave={onSavePricingGroup}
      onDelete={onPressDelete}
      isDefault={isDefault}
      pricingGroupData={pricingGroupData}
      storeOptions={options.stores?.length ? options.stores : stores}
      venueOptions={venues}
      orderTypeOptions={
        options.orderTypes?.length ? options.orderTypes : orderTypes
      }
      channelOptions={salesChannels}
      onCreateTimeSlot={onCreateTimeSlot}
      onDeleteTimeSlot={onDeleteTimeSlot}
    />
  );
};
