import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { Operation } from '../../types/Operation';
import { ApolloError } from '@apollo/client';
import {
  UpdateTradingPeriodInput,
  CreateTradingPeriodInput,
  DateTimeInput,
  TradingPeriod,
  DateTime,
} from '@hitz-group/domain';
import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import {
  GET_TRADING_PERIODS_QUERY,
  CREATE_TRADING_PERIODS,
  UPDATE_TRADING_PERIODS,
  DELETE_TRADING_PERIOD,
  GET_DATES_TIME_QUERY,
  UPDATE_DATES_TIME_QUERY,
} from '../../graphql/reportSettings';
import keyBy from 'lodash/keyBy';

export interface UseReportSettingsProps {
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  tradingPeriods: { [key: string]: TradingPeriod };
  refetch: (() => void) | undefined;
  getTradingPeriods: () => void;
  dateTime: DateTime | undefined;
  getDateTime: () => void;
  updateDateTime: (dateTime: DateTime) => void;
  createTradingPeriods: (
    tradingPeriodsInput: CreateTradingPeriodInput[],
  ) => void;
  createdTradingPeriodIds: string[];
  updateTradingPeriods: (
    tradingPeriodsInput: UpdateTradingPeriodInput[],
  ) => void;
  updatedTradingPeriodIds: string[];
  deleteTradingPeriod: (id: string) => void;
  deletedTradingPeriod: boolean;
  updatedDateTime: boolean;
}

export function useReportSettings(): UseReportSettingsProps {
  const [tradingPeriods, setTradingPeriods] = useState<
    Record<string, TradingPeriod>
  >({});

  const [dateTime, setDateTime] = useState<DateTime>();

  const [updatedDateTime, setUpdatedDateTime] = useState(false);

  const [createdTradingPeriodIds, setCreatedTradingPeriodIds] = useState<
    string[]
  >([]);

  const [updatedTradingPeriodIds, setUpdatedTradingPeriodIds] = useState<
    string[]
  >([]);

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [deletedTradingPeriod, setDeletedTradingPeriod] = useState(false);

  const onCompleteGetDateTimeRequest = useCallback(
    data => {
      if (data) {
        const dateTimeData = data.dateTime as DateTime;
        setDateTime(dateTimeData);
        setUpdatedDateTime(false);
      }
    },
    [setDateTime],
  );

  const onCompleteGetTradingPeriodsRequest = useCallback(
    data => {
      if (data) {
        setDeletedTradingPeriod(false);
        const tradingPeriodsData = data.tradingPeriods as TradingPeriod[];
        const tradingPeriodsDict: Record<string, TradingPeriod> = keyBy(
          tradingPeriodsData,
          'id',
        );
        setTradingPeriods(tradingPeriodsDict);
      }
    },
    [setTradingPeriods],
  );

  const [getDateTime, getDateTimeResponse] = useLazyQuery(
    GET_DATES_TIME_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetDateTimeRequest,
    },
  );

  const [getTradingPeriods, getTradingPeriodsResponse] = useLazyQuery(
    GET_TRADING_PERIODS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetTradingPeriodsRequest,
    },
  );

  const [updateDateTimeRequest, updateDateTimeResponse] = useMutation(
    UPDATE_DATES_TIME_QUERY,
    {
      onError: noopHandler,
    },
  );

  useEffect(() => {
    if (updateDateTimeResponse.data) {
      const updatedDateTime = updateDateTimeResponse.data
        .updateDateTime as DateTime;
      setUpdatedDateTime(true);
      setDateTime(updatedDateTime);
    }
  }, [updateDateTimeResponse.data]);

  const updateDateTime = useCallback(
    (updateDateTimeInput: DateTimeInput) => {
      updateDateTimeRequest({
        variables: {
          input: updateDateTimeInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateDateTimeRequest],
  );

  // create
  const [createTradingPeriodsRequest, createTradingPeriodsResponse] =
    useMutation(CREATE_TRADING_PERIODS, {
      onError: noopHandler,
    });

  const createTradingPeriods = useCallback(
    (tradingPeriodsInput: CreateTradingPeriodInput[]) => {
      createTradingPeriodsRequest({
        variables: {
          input: tradingPeriodsInput,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createTradingPeriodsRequest],
  );

  useEffect(() => {
    if (createTradingPeriodsResponse.data) {
      const newlyCreatedTradingPeriods = createTradingPeriodsResponse.data
        .createTradingPeriods as TradingPeriod[];

      setTradingPeriods(tradingPeriods => {
        return {
          ...tradingPeriods,
          ...keyBy(newlyCreatedTradingPeriods || [], 'id'),
        };
      });

      setCreatedTradingPeriodIds(
        newlyCreatedTradingPeriods.map(tradingPeriod => tradingPeriod.id),
      );
    }
  }, [createTradingPeriodsResponse.data]);

  const [updateTradingPeriodsRequest, updateTradingPeriodsResponse] =
    useMutation(UPDATE_TRADING_PERIODS, {
      onError: noopHandler,
    });

  useEffect(() => {
    if (updateTradingPeriodsResponse.data) {
      const updatedTradingPeriods = updateTradingPeriodsResponse.data
        .updateTradingPeriods as TradingPeriod[];

      setTradingPeriods(tradingPeriods => {
        return {
          ...tradingPeriods,
          ...keyBy(updatedTradingPeriods || [], 'id'),
        };
      });
      setUpdatedTradingPeriodIds(
        updatedTradingPeriods.map(tradingPeriod => tradingPeriod.id),
      );
    }
  }, [updateTradingPeriodsResponse.data]);

  const updateTradingPeriods = useCallback(
    (updateTradingPeriodInput: UpdateTradingPeriodInput[]) => {
      updateTradingPeriodsRequest({
        variables: {
          input: updateTradingPeriodInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateTradingPeriodsRequest],
  );

  // delete

  const [deleteTradingPeriodRequest, deleteTradingPeriodResponse] = useMutation(
    DELETE_TRADING_PERIOD,
    {
      onError: noopHandler,
    },
  );

  const deleteTradingPeriod = useCallback(
    (id: string) => {
      deleteTradingPeriodRequest({
        variables: {
          id,
        },
      });
      setOperation(Operation.DELETE);
    },
    [deleteTradingPeriodRequest],
  );

  useEffect(() => {
    if (deleteTradingPeriodResponse.data) {
      setDeletedTradingPeriod(
        deleteTradingPeriodResponse.data.deleteTradingPeriod,
      );
    }
  }, [deleteTradingPeriodResponse.data]);

  const loading: boolean =
    getDateTimeResponse.loading ||
    getTradingPeriodsResponse.loading ||
    updateTradingPeriodsResponse.loading ||
    createTradingPeriodsResponse.loading ||
    updateDateTimeResponse.loading ||
    deleteTradingPeriodResponse.loading;

  const error: ApolloError | undefined =
    getDateTimeResponse.error ||
    getTradingPeriodsResponse.error ||
    updateTradingPeriodsResponse.error ||
    updateDateTimeResponse.error ||
    createTradingPeriodsResponse.error ||
    deleteTradingPeriodResponse.error;

  const refetch = useCallback(() => {
    const refetchedData = getTradingPeriodsResponse.client?.readQuery({
      query: GET_TRADING_PERIODS_QUERY,
    });
    setTradingPeriods(prev => {
      const tempPrev = { ...prev };
      const refetchedTradingPeriods =
        refetchedData?.tradingPeriods as TradingPeriod[];
      if (refetchedTradingPeriods?.length) {
        refetchedTradingPeriods.forEach(eachVar => {
          tempPrev[eachVar.id] = eachVar;
        });
      }
      return tempPrev;
    });
    setOperation(Operation.READ);
  }, [getTradingPeriodsResponse.client]);

  return {
    loading,
    error: error ? parseApolloError(error) : undefined,
    operation,
    refetch,
    tradingPeriods,
    getDateTime,
    dateTime,
    updateDateTime,
    getTradingPeriods,
    createTradingPeriods,
    createdTradingPeriodIds,
    updateTradingPeriods,
    updatedTradingPeriodIds,
    deleteTradingPeriod,
    deletedTradingPeriod,
    updatedDateTime,
  };
}
