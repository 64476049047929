import { useTranslation } from '@hitz-group/localization';
import {
  DeputySettingsInput,
  StyleFn,
  Device,
  WorkforceIntegrationStorePreferences,
  POSTerminalDeputyAreaMapInput,
} from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { View, Text, ScrollView } from 'react-native';
import { useModal } from '@hitz-group/rn-use-modal';
import Button from '../../../../../../../../components/Button/Button';
import PopupView from '../../../../../../../../components/PopupView/PopupView';
import DropDown from '../../../../../../../../components/FormInput/DropDown';
import { useWorkforceIntegration } from '../../../../../../../../hooks/app/workforceIntegrations/useWorkforceIntegration';
import { useNotification } from '../../../../../../../../hooks/Notification';
import { useDevices } from '../../../../../../../../hooks/app/useDevices';
import LoadingIndicator from '../../../../../../../../components/LoadingIndicator/LoadingIndicator';

export interface DeputyModalProps {
  locationId: number;
  storeId: string;
  connectedActions: WorkforceIntegrationStorePreferences;
  isOrder: boolean;
}

const containerStyle: StyleFn = ({ theme }) => ({
  width: 450,
  alignSelf: 'center',
  justifyContent: 'center',
  marginTop: 40,
  borderRadius: theme.radius.small,
  flex: 1,
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.skyBlue,
  color: theme.colors.blue,
});

const buttonLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  alignItems: 'center',
});

const popupStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.medium * 2.5,
  minHeight: 235,
});

const actionButtonContainer: StyleFn = () => ({
  flexDirection: 'row',
  width: '100%',
  height: 'auto',
  marginTop: 30,
  marginBottom: 30,
  justifyContent: 'space-between',
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  paddingBottom: theme.padding.medium / 2,
  borderBottomColor: theme.colors.boxBorder,
  justifyContent: 'space-between',
});

const headerTextStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  flex: 1,
  justifyContent: 'space-between',
  paddingLeft: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  paddingRight: 20,
  marginLeft: 10,
  marginTop: theme.spacing.medium,
});

const headerText: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
});

const footerText: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  marginTop: 15,
  alignSelf: 'center',
});

export const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: '100%',
});

export const dropdownLabelStyle: StyleFn = () => ({
  width: 400,
});

export const defaulLabelStyle: StyleFn = () => ({
  width: '100%',
  marginTop: 20,
});

export const labelStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  marginBottom: 10,
  color: theme.colors.paragraph,
  paddingLeft: theme.spacing.medium,
});

export const defaultContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  marginTop: theme.spacing.small / 2,
  marginRight: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  width: 'auto',
});

export const defaultTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.medium,
  paddingRight: theme.spacing.medium,
  marginTop: 10,
  width: 120,
  ...theme.font14RegularCharcoal,
});

export const headerStyle: StyleFn = ({ theme }) => ({
  marginTop: 10,
  marginBottom: 10,
  width: '100%',
  height: 50,
  backgroundColor: theme.colors.greyLight,
});

export const footerStyle: StyleFn = ({ theme }) => ({
  marginTop: 10,
  width: '100%',
  height: 50,
  justifyContent: 'space-between',
  backgroundColor: theme.colors.greyLight,
  alignItems: 'center',
});

const DeputyModal: React.FC<DeputyModalProps> = ({
  locationId,
  storeId,
  isOrder,
}: DeputyModalProps) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const {
    loading,
    deputyAreas,
    getAllDeputyAreas,
    integrationPartnerStores,
    getIntegrationPartnerStoreConfig,
    updateStoreWithDeputySettings,
    updatedStoreWithDeputySettings,
    employeeSyncRequestSent,
    syncEmployees,
  } = useWorkforceIntegration();
  const { devices, getDevices } = useDevices({
    storeId,
  });

  const [deputyAreasState, setDeputyAreasState] = useState<
    POSTerminalDeputyAreaMapInput[]
  >([]);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (storeId) {
      getDevices();
      getIntegrationPartnerStoreConfig(storeId);
    }
  }, [getDevices, getIntegrationPartnerStoreConfig, storeId]);

  const devicesData = useMemo(
    () =>
      Object.values(devices).map(device => ({
        ...device,
      })),
    [devices],
  );

  const onPress = useCallback(() => {
    syncEmployees(storeId);
    closeModal();
  }, [syncEmployees, storeId, closeModal]);

  const onPressDismiss = useCallback(() => {
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    if (employeeSyncRequestSent || updatedStoreWithDeputySettings) {
      showNotification({
        success: true,
        message: translate('settingSection.deputy.notification.updatedAction'),
      });
    }
  }, [
    showNotification,
    translate,
    employeeSyncRequestSent,
    updatedStoreWithDeputySettings,
  ]);

  const onPressSave = useCallback(() => {
    const data = {
      syncOrders: integrationPartnerStores?.syncOrders,
      syncUsers: integrationPartnerStores?.syncUsers,
      syncTimesheets: integrationPartnerStores?.syncTimesheets,
      posTerminalAreaMappings: deputyAreasState,
    } as unknown as DeputySettingsInput;
    updateStoreWithDeputySettings(data, storeId);
    setDeputyAreasState([]);
  }, [
    deputyAreasState,
    integrationPartnerStores?.syncOrders,
    integrationPartnerStores?.syncTimesheets,
    integrationPartnerStores?.syncUsers,
    storeId,
    updateStoreWithDeputySettings,
  ]);

  const onChangeVenue = useCallback(
    selectedvalues => {
      const allValues = selectedvalues.split(',', 3);
      const area = deputyAreas.find(item => item.id === Number(allValues[0]));
      setDeputyAreasState([
        ...deputyAreasState.filter(item => item.posTerminalId !== allValues[1]),
        {
          areaId: area?.id as number,
          areaName: area?.areaName as string,
          posTerminalId: allValues[1],
          posTerminalName: allValues[2],
        },
      ]);
    },
    [deputyAreas, deputyAreasState],
  );

  useEffect(() => {
    getAllDeputyAreas(locationId);
  }, [getAllDeputyAreas, locationId]);

  if (loading && devicesData.length === 0) return <LoadingIndicator />;

  return (
    <ScrollView scrollEnabled scrollsToTop>
      <View style={css(containerStyle)}>
        <PopupView containerStyle={css(popupStyle)}>
          {isOrder && (
            <>
              <View style={css(headerStyle)}>
                <View style={css(headerTextStyle)}>
                  <Text style={css(headerText)}>
                    {translate('settingSection.deputy.modal.posTerminal')}
                  </Text>
                  <Text style={css(headerText)}>
                    {translate('settingSection.deputy.modal.area')}
                  </Text>
                </View>
              </View>
              {devicesData?.map((device: Device) => {
                return (
                  <View key={device.id} style={css(rowStyle)}>
                    <View style={css(defaultContainerStyle)}>
                      <Text style={css(defaultTextStyle)}>{device.name}</Text>
                    </View>
                    <DropDown
                      {...(integrationPartnerStores?.posTerminalAreaMappings?.find(
                        x => x.posTerminalId === device.id,
                      )
                        ? {
                            values: [
                              {
                                value: `${
                                  integrationPartnerStores?.posTerminalAreaMappings?.find(
                                    x => x.posTerminalId === device.id,
                                  )?.areaId
                                },${
                                  integrationPartnerStores?.posTerminalAreaMappings?.find(
                                    x => x.posTerminalId === device.id,
                                  )?.posTerminalId
                                },${
                                  integrationPartnerStores?.posTerminalAreaMappings?.find(
                                    x => x.posTerminalId === device.id,
                                  )?.posTerminalName
                                }`,
                                label: `${
                                  integrationPartnerStores?.posTerminalAreaMappings?.find(
                                    x => x.posTerminalId === device.id,
                                  )?.areaName
                                }`,
                              },
                              ...deputyAreas.map(x => {
                                return {
                                  value: `${x.id},${device.id},${device.name}`,
                                  label: x.areaName,
                                };
                              }),
                            ],
                          }
                        : {
                            values: deputyAreas.map(x => ({
                              value: `${x.id},${device.id},${device.name}`,
                              label: x.areaName,
                            })),
                          })}
                      onValueChange={onChangeVenue}
                      testID={'change-value'}
                      extraStyle={css(dropdownExtraStyle)}
                    />
                  </View>
                );
              })}
            </>
          )}

          <View style={css(footerStyle)}>
            <Text style={css(footerText)}>
              {employeeSyncRequestSent &&
                translate('settingSection.deputy.syncEmployeeSuccess')}
            </Text>
          </View>
          <View style={css(actionButtonContainer)}>
            <Button
              title={translate('settingSection.deputy.modal.button.dismiss')}
              size="small"
              onPress={onPressDismiss}
              testID={'close-modal'}
            />

            <Button
              testID={'run-deputy'}
              title={translate('settingSection.deputy.modal.button.runNow')}
              containerStyle={css(buttonContainerStyle)}
              labelStyle={css(buttonLabelStyle)}
              size="small"
              onPress={onPress}
            />

            <Button
              testID={'save-deputy'}
              title={translate('settingSection.deputy.modal.button.save')}
              success
              size="small"
              onPress={onPressSave}
            />
          </View>
        </PopupView>
      </View>
    </ScrollView>
  );
};

export default DeputyModal;
