import { useTranslation } from '@hitz-group/localization';
import React, { useCallback, useState } from 'react';
import Modal from '../Modal';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Button/Button';
import { useFela } from 'react-fela';
import { Order, StyleFn } from '@hitz-group/domain';
import { isValidEmail } from '../../../utils/validator';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import scale from '../../../common/theme';
import { View } from 'react-native';

export interface OrderInvoiceModalProps {
  order: Order;
  changeDue?: number;
  sendReceipt?: (order: Order, email: string) => void;
  customer?: {
    email?: string;
  };
}

export interface OrderInvoiceFormProps {
  email: string;
}

const modalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const formInputTextStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'flex-start',
  paddingHorizontal: theme.spacing.small * 1.5,
});

const formInputStyle: StyleFn = ({ theme }) => ({
  height: scale.moderateScale(30),
  width: scale.textInputWidth180,
  fontSize: scale.moderateScale(10),
  fontFamily: theme.font.regular,
  letterSpacing: -0.5,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

const textInputStyle: StyleFn = ({ theme }) => ({
  height: scale.moderateScale(30),
  width: scale.textInputWidth180,
  fontSize: scale.moderateScale(10),
  fontFamily: theme.font.regular,
  letterSpacing: -0.5,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

const sendContainerStyle: StyleFn = ({ theme }) => ({
  maxWidth: 100,
  height: 38,
  flex: 1,
  backgroundColor: theme.colors.highlighted,
  marginLeft: 0,
});

const sendLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  maxWidth: 100,
  height: 38,
  flex: 1,
  backgroundColor: theme.colors.transparent,
  borderRadius: 5,
  marginLeft: 0,
});

const buttonLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const modalTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const buttonSectionStyle: StyleFn = ({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginRight: theme.spacing.small,
  marginTop: theme.spacing.medium,
});

const OrderInvoiceModal: React.FC<OrderInvoiceModalProps> = ({
  order,
  sendReceipt,
  customer = {},
}: OrderInvoiceModalProps) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();

  const { css } = useFela();
  const [formData, setFormData] = useState<OrderInvoiceFormProps>({
    email: customer?.email || '',
  });

  const [formSubmitted, setFormSubmit] = useState<boolean>(false);

  const { showNotification } = useNotification();
  const _onChangeProp = (prop: string, text: string): void => {
    setFormData({ ...formData, [prop]: text });
  };

  const _sendEmailReceipt = useCallback(() => {
    setFormSubmit(true);
    if (formData.email && sendReceipt) {
      if (isValidEmail(formData.email)) {
        closeModal();
        sendReceipt(order, formData.email);
      } else {
        showNotification({
          error: true,
          message: 'Invalid email',
        });
      }
    } else {
      showNotification({
        error: true,
        message: 'Provide email address',
      });
    }
  }, [sendReceipt, formData, closeModal, showNotification, order]);

  return (
    <Modal
      title={translate('deviceProfileSettings.emailReceipt')}
      onDismiss={closeModal}
      contentStyle={css(modalContainerStyle)}
      headerTextStyle={css(modalTextStyle)}
    >
      <FormInput
        testID="email-address"
        title={translate('common.emailAddress')}
        alignTitle="left"
        value={formData.email}
        placeholder={translate('signup.emailHint')}
        containerStyle={css(formInputStyle)}
        textInputStyle={css(textInputStyle)}
        textStyle={css(formInputTextStyle)}
        onSubmitEditing={_sendEmailReceipt}
        onChangeText={text => _onChangeProp('email', text)}
        verified={isValidEmail(formData.email)}
        error={formSubmitted && !isValidEmail(formData.email)}
      />
      <View style={css(buttonSectionStyle)}>
        <Button
          testID="dismiss-button"
          title={translate('form.dismiss')}
          containerStyle={css(buttonContainerStyle)}
          labelStyle={css(buttonLabelStyle)}
          onPress={closeModal}
        />
        <Button
          testID="send-button"
          title={translate('form.send')}
          containerStyle={css(sendContainerStyle)}
          labelStyle={css(sendLabelStyle)}
          onPress={_sendEmailReceipt}
        />
      </View>
    </Modal>
  );
};

export default OrderInvoiceModal;
