import { useTranslation } from '@hitz-group/localization';
import { Order, OrderItem, OrderItemStatus } from '@hitz-group/domain';
import React, { useState, useEffect } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import TitleBar from '../../TitleBar/TitleBar';
import { useModal } from '@hitz-group/rn-use-modal';

import * as styles from './RefundModal.style';
import Modal from '../Modal';
import IconButton from '../../Button/IconButton';
import Button from '../../Button/Button';
import ModalOrderItems from './Components/ModalOrderItems';
import ModalOrderSummary from './Components/ModalOrderSummary';
import { CartSelectionState } from '../../Cart/Cart';
import CompleteRefundButton from '../../RefundScreen/CompleteRefundButton';
import { computeOrderTotals } from '@hitz-group/order-helper';
import { IMap } from '../../../../src/screens/BackOffice/Reports/types';

interface RefundModalProps {
  order: Order;
  onPressBtn: (selectedOrderItemIds: IMap<number>) => void;
}

const RefundModal: React.FC<RefundModalProps> = ({ order, onPressBtn }) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const orderNumber = order?.orderNumber || '';
  const { css } = useFela();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [refundOrderObj, setRefundOrderObj] = useState<Order>(order);
  const [totalRefund, setTotalRefund] = useState<number>(0);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [itemAndQuantityToRefund, setItemAndQuantityToRefund] = useState<
    IMap<number>
  >({});

  useEffect(() => {
    if (order.orderItems) {
      const newOrderItemsArray = [] as OrderItem[];
      order.orderItems.forEach(item => {
        if (item.status === OrderItemStatus.COMPLETED) {
          const itemCopy = { ...item };
          itemCopy.quantity = 1;
          for (let i = 0; i < item.quantity; i++) {
            itemCopy.id = item.id + `-${i}`;
            newOrderItemsArray.push({ ...itemCopy });
          }
        }
      });
      setOrderItems(newOrderItemsArray);
    }
  }, [order.orderItems]);

  useEffect(() => {
    const newRefundTotals = computeOrderTotals(refundOrderObj);
    setTotalRefund(newRefundTotals.totalPrice);
  }, [refundOrderObj]);

  const onSelectedItemsChange = (updatedSelectedItems: string[]) => {
    const selectedOrderItems = orderItems.filter(item =>
      updatedSelectedItems.includes(item.id),
    );

    setRefundOrderObj({ ...order, orderItems: selectedOrderItems });
  };

  const updateItemsAndQuantityToBeRefunded = (
    itemId: string,
    quantity?: number,
  ) => {
    if (quantity && quantity > 0) {
      setItemAndQuantityToRefund(prev => ({
        ...prev,
        [itemId]: quantity,
      }));
    } else if (quantity === 0) {
      setItemAndQuantityToRefund(prev => {
        const prevCopy = { ...prev };
        delete prevCopy[itemId];
        return prevCopy;
      });
    } else {
      setItemAndQuantityToRefund(prev => ({
        ...prev,
        [itemId]: 1,
      }));
    }
  };

  const onSelectCartItem = (state: CartSelectionState) => {
    setSelectedItems(prev => {
      const updatedSelectedItems = [...prev];
      const itemIndex = prev.indexOf(state.item);
      const actualItemId = state.item.split('-').slice(0, -1).join('-');
      const quantity = itemAndQuantityToRefund[actualItemId];
      if (itemIndex === -1) {
        updatedSelectedItems.push(state.item);
        onSelectedItemsChange(updatedSelectedItems);
        if (quantity) {
          updateItemsAndQuantityToBeRefunded(actualItemId, quantity + 1);
        } else {
          updateItemsAndQuantityToBeRefunded(actualItemId);
        }
        return updatedSelectedItems;
      }
      updatedSelectedItems.splice(itemIndex, 1);
      onSelectedItemsChange(updatedSelectedItems);
      updateItemsAndQuantityToBeRefunded(actualItemId, quantity - 1);
      return updatedSelectedItems;
    });
  };

  const onPressRefundBtn = () => {
    onPressBtn(itemAndQuantityToRefund);
  };

  const unselectAllItems = () => {
    setSelectedItems([]);
    setItemAndQuantityToRefund({});
    setTotalRefund(order.totalPrice);
  };

  const titleLeft = (
    <IconButton
      icon="times"
      containerStyle={css(styles.cancelButtonStyle)}
      onPress={closeModal}
    />
  );

  return (
    <View style={css(styles.modalContainer)}>
      <TitleBar
        testID="order-number"
        primary
        title={translate('refundOrder.headerTitle', { orderNumber })}
        titleLeft={titleLeft}
        containerStyle={css(styles.headerStyle)}
      />
      <Modal
        contentStyle={css(styles.modalStyle)}
        customBodyStyle={styles.customBodyStyle}
        showCloseButton={false}
      >
        <ModalOrderItems
          orderItems={orderItems}
          selectedItems={selectedItems}
          onSelectCartItem={onSelectCartItem}
        />
        <View style={css(styles.horizontalLine)}></View>
        <ModalOrderSummary order={order} />
        {!selectedItems.length ? (
          <Button
            testID="refundAll-btn"
            success
            title={translate('refundOrder.refundAll')}
            disabled={false}
            size="large"
            containerStyle={css(styles.refundOrderButtonContainer)}
            labelStyle={css(styles.refundOrderText)}
            onPress={onPressRefundBtn}
          />
        ) : (
          <>
            <CompleteRefundButton
              isDisabled={true}
              refundTotal={totalRefund}
              totalItems={selectedItems.length}
              onPress={onPressRefundBtn}
            />
            <Button
              testID="unselect-all-btn"
              title={translate('refundOrder.unselectAllButton')}
              disabled={false}
              size="large"
              containerStyle={css(styles.unSelectAllButtonContainer)}
              labelStyle={css(styles.unSelectAllButtonLabelStyle)}
              onPress={unselectAllItems}
            />
          </>
        )}
      </Modal>
    </View>
  );
};

export default RefundModal;
