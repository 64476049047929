import { StyleFn } from '@hitz-group/domain';
import { isIos } from '../../common/theme';

export const dropdownSearchBarStyle: StyleFn = ({ theme }) => ({
  // marginTop: 24, Do not delete! Will need it for the next sprint/epic
  marginBottom: 15,
  flexDirection: 'row',
  flex: 1,
  borderWidth: 1,
  borderColor: theme.colors.boxBorder,
  borderRadius: 3,
  paddingVertical: 1,
  paddingHorizontal: 1,
  paddingRight: isIos ? theme.spacing.medium : 0,
});

export const searchContainerStyle: StyleFn = ({ theme }) => ({
  flex: 2,
  height: theme.input.height,
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.white,
  borderRadius: 0,
  padding: 0,
  margin: 0,
  borderWidth: 0,
});

export const searchTextInputStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.black,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
});

export const dropDownStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  height: theme.input.height,
  backgroundColor: theme.colors.greyLight,
  borderColor: theme.colors.white,
  borderWidth: 0,
  padding: 0,
  borderRadius: 0,
  margin: 0,
});

export const itemContainerDropDownStyle: StyleFn = ({ theme }) => ({
  width: 110,
  backgroundColor: theme.colors.greyLight,
  paddingHorizontal: 5,
});

export const dropdownContainer: StyleFn = () => ({
  height: 115,
});

export const extraPopoverStyle: StyleFn = () => ({
  width: 110,
});

export const dropdownTouchableStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderWidth: 0,
  borderRadius: 0,
  height: theme.input.height,
  paddingHorizontal: theme.padding.medium,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 0,
});
