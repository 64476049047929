import {
  Course,
  DEFAULT_ENTITY_ID,
  UpdateCourseInput,
} from '@hitz-group/domain';
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ScrollView, View } from 'react-native';
import DraggableFlatList from '../../../../components/DraggableFlatList/DraggableFlatList';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import { useCourses } from '../../../../hooks/app/courses/useCourses';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import CourseRow from './CourseRow';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { differenceWith, isEmpty, isEqual, omit, sortBy } from 'lodash';
import { useNotification } from '../../../../hooks/Notification';
import { CourseListStyles } from './styles';

const validateDuplicationName = (
  courses: Course[],
): Record<number, boolean> => {
  const errors: Record<number, boolean> = {};

  const duplicateNameMap = courses.reduce((nameObj, current) => {
    const key = current.name?.toLowerCase().trim();
    if (key) {
      nameObj[key] = (nameObj[key] || 0) + 1;
    }
    return nameObj;
  }, {} as Record<string, number>);
  courses.forEach(({ name }, index) => {
    if (duplicateNameMap[name?.toLowerCase().trim() || ''] > 1) {
      errors[index] = true;
    }
  });
  return errors;
};

const Courses: React.FC = () => {
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const styles = CourseListStyles();
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState<Record<number, boolean>>({});
  const [courses, setCourses] = useState<Course[]>([]);
  const originalCourseRef = useRef<Course[]>([]);
  const { getCourses, courseMaps, loading, updateCourses, error } = useCourses({
    onUpdateCoursesComplete: handleOnCompleteUpdateCourse,
  });

  useEffect(() => {
    if (!isFocused) return;
    getCourses();
    setErrors({});
  }, [getCourses, isFocused]);

  function handleOnCompleteUpdateCourse() {
    showNotification({
      message: translate('backOfficeProducts.coursesUpdatedSuccessfully'),
      success: true,
    });
    setErrors({});
  }

  const handleCreateCourse = useCallback((): void => {
    navigation.navigate('CourseSettings', {
      courseId: DEFAULT_ENTITY_ID,
      title: translate('backOfficeProducts.newCourse'),
    });
  }, [navigation, translate]);

  const handleCourseItemChange = (index: number, newCourseValue: Course) => {
    setCourses(preCourses => {
      const updateCourse = [...preCourses];
      updateCourse.splice(index, 1, newCourseValue);
      return updateCourse;
    });
  };
  const onEditCourse = useCallback(
    (item: Course) => {
      navigation.navigate('CourseSettings', {
        courseId: item.id,
        title: item.name,
      });
    },
    [navigation],
  );

  const onPressSave = useCallback((): void => {
    const errors = validateDuplicationName(courses);
    if (isEmpty(errors)) {
      const coursesToUpdate = differenceWith(
        courses,
        originalCourseRef.current,
        isEqual,
      ).map(course =>
        omit(course, ['products', '__typename']),
      ) as UpdateCourseInput[];
      updateCourses(coursesToUpdate);
    } else {
      showNotification({
        message: translate(
          'backOfficeProducts.duplicateNameCourseWarningMessage',
        ),
        error: true,
      });
      setErrors(errors);
    }
  }, [courses, showNotification, translate, updateCourses]);

  const onDragEnd = useCallback(
    (data: Array<Course>) => {
      const updatedCourses = data.map((course, index) => ({
        ...course,
        priority: index,
      }));
      setCourses(updatedCourses);
    },
    [setCourses],
  );

  useEffect(() => {
    const sortedCourses = sortBy(
      Object.values(courseMaps) || [],
      course => course.priority,
    );
    setCourses(sortedCourses);
    originalCourseRef.current = sortedCourses;
  }, [courseMaps]);

  useEffect(() => {
    if (!error) return;
    showNotification({ error: true, message: error });
  }, [error, showNotification]);

  const hasDataChanged = !isEqual(courses, originalCourseRef.current);

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={styles.pageStyle}>
        <ScrollView contentContainerStyle={styles.scrollStyle}>
          <BackOfficeSection
            title={translate('backOfficeProducts.courses')}
            titleDescription={translate('backOfficeProducts.courseDescription')}
            action={<BackOfficeCreateNewButton onPress={handleCreateCourse} />}
            contentContainerStyle={styles.formStyle}
            containerStyle={styles.containerStyle}
          >
            <DraggableFlatList
              columns={[
                {
                  title: translate('backOfficeProducts.courseName'),
                  width: 290,
                  containerStyle: { paddingLeft: 14 },
                },
                {
                  title: translate('backOfficeProducts.products'),
                  width: 60,
                  containerStyle: { marginLeft: 15 },
                },
                {
                  title: translate('backOfficeProducts.autoFire'),
                  width: 80,
                  containerStyle: { marginLeft: 10 },
                },
              ]}
              data={courses}
              normalRows
              columnContainerStyle={styles.columnContainerStyle}
              onDragEnd={onDragEnd}
              renderRow={(
                item: Course,
                index: number,
                drag: () => void,
              ): React.ReactNode => (
                <CourseRow
                  course={item}
                  onChange={handleCourseItemChange.bind(null, index)}
                  key={index}
                  onEditCourse={onEditCourse.bind(null, item)}
                  onDrag={drag}
                  isErrorName={errors[index]}
                />
              )}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>

      <View style={styles.mainStyle}>
        {hasDataChanged && (
          <View
            style={styles.actionsContainerStyle}
            testID="save-changes-course-container"
          >
            <Button
              fluid
              testID="save-changes-button"
              title={translate('button.saveChanges')}
              containerStyle={styles.saveButtonStyle}
              labelStyle={styles.titleStyle}
              onPress={onPressSave}
            />
          </View>
        )}
      </View>
    </>
  );
};

export default Courses;
