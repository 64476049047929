import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { App, UserRoleDef } from '@hitz-group/domain';
import { UserWithUserRoles } from './app/users/useUserRoles';
import { Subscription } from 'rxjs/internal/Subscription';
import {
  computeAppAccess,
  OfficeUser,
  UserActivity,
  userUtility,
} from '../state/userUtility';
import { AppAccessExtras } from './OfficeUserAuthorizationProvider';
import useRolesContext from './app/users/useRolesContext';
import { useApolloClient } from '@apollo/client/react/hooks';
import { GET_USER_WITH_ROLES } from '../graphql/users';
import { filter, map, pluck, distinctUntilChanged } from 'rxjs/operators';

export interface Props {
  children: ReactNode;
}

export interface OfficeUserRoleContextType {
  isUserAllowedToAccess: (app: App, options?: AppAccessExtras) => boolean;
  userRoles: Record<string, UserRoleDef>;
  /**
   * Call this after office user login to fetch and update user-roles
   */
  updateUserRoles: (updateUserRoles: UserWithUserRoles) => void;
  /**
   * Call this whenever a new role is assigned or updated permissions or app access
   */
  assignOrUpdateUserRoles: (
    userId: string,
    userRoles: Record<string, UserRoleDef>,
  ) => void;
  /**
   * Call this whenever a existing role is removed from user
   */
  removeUserRoles: (userId: string, userRoleIds: string[]) => void;
}

export const OfficeUserRoleContext = createContext<OfficeUserRoleContextType>(
  {} as OfficeUserRoleContextType,
);

const OfficeUserRoleProvider: FC<Props> = ({ children }) => {
  const [userRoles, setUserRoles] = useState<Record<string, UserRoleDef>>({});
  const { rolesById } = useRolesContext();
  const client = useApolloClient();

  const restoreUserRoles = useCallback(
    (userId: string) => {
      const data = client.cache.readQuery<UserWithUserRoles, { id: string }>({
        query: GET_USER_WITH_ROLES,
        variables: {
          id: userId,
        },
      });

      if (data) {
        setUserRoles(
          data.user.roles?.reduce(
            (acc, userRole) => ({ ...acc, [userRole.id]: userRole }),
            {},
          ),
        );
      }
      // TODO: in else case may be we can do a logout
    },
    [client],
  );

  const updateUserRoles = useCallback((data: UserWithUserRoles) => {
    setUserRoles(
      data.user.roles?.reduce(
        (acc, userRole) => ({ ...acc, [userRole.id]: userRole }),
        {},
      ),
    );
  }, []);

  const assignOrUpdateUserRoles = useCallback(
    (userId: string, data: Record<string, UserRoleDef>) => {
      if (
        userUtility.recentOfficeUser?.id === userId &&
        userUtility.recentOfficeUser?.active
      ) {
        // replace with latest user-role ino
        setUserRoles(data);
      }
    },
    [],
  );

  const removeUserRoles = useCallback(
    (userId: string, userRoleIds: string[]) => {
      if (
        userUtility.recentOfficeUser?.id === userId &&
        userUtility.recentOfficeUser?.active
      ) {
        setUserRoles(prev => {
          const clone = { ...prev };
          userRoleIds.forEach(userRoleId => {
            delete clone[userRoleId];
          });
          return clone;
        });
      }
    },
    [],
  );

  /**
   * TODO: move this to a dedicated hook, because the inputs for this method frequently changes (venue or store or both and can be multiple in future)
   */
  const isUserAllowedToAccess = useCallback(
    (app: App, options?: AppAccessExtras) => {
      // Where as for Office user, if the user having app access at any one of the location, we will consider that (excluding the role switcher or venue/ store switcher scenarios)
      if (options?.store) {
        // TODO: handle store filters here
      }

      const user = userUtility.recentOfficeUser;

      return (
        user?.active &&
        computeAppAccess(rolesById, Object.values(userRoles), app)
      );
    },
    [rolesById, userRoles],
  );

  useEffect(() => {
    const subscription: Subscription = userUtility.retrieveUserActivity$
      .pipe(
        pluck<UserActivity, 'officeUsers'>('officeUsers'),
        map<UserActivity['officeUsers'], OfficeUser>(
          users => Object.values(users).find(user => user.active) as OfficeUser,
        ),
        map<OfficeUser, OfficeUser['id']>(user => user?.id),
        filter(userId => userId !== undefined),
        distinctUntilChanged(),
      )
      .subscribe(userId => {
        restoreUserRoles(userId);
      });

    // TODO: On logout, call reset()

    return () => {
      subscription?.unsubscribe();
    };
  }, [restoreUserRoles]);

  const value = useMemo(
    () => ({
      isUserAllowedToAccess,
      userRoles,
      updateUserRoles,
      assignOrUpdateUserRoles,
      removeUserRoles,
    }),
    [
      isUserAllowedToAccess,
      userRoles,
      updateUserRoles,
      assignOrUpdateUserRoles,
      removeUserRoles,
    ],
  );

  return (
    <OfficeUserRoleContext.Provider value={value}>
      {children}
    </OfficeUserRoleContext.Provider>
  );
};

export default OfficeUserRoleProvider;
