import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { Operation } from '../../types/Operation';
import { ApolloError } from '@apollo/client';
import {
  RefundReason,
  CreateRefundReasonInput,
  UpdateRefundReasonInput,
} from '@hitz-group/domain';
import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import {
  GET_REFUND_REASONS_QUERY,
  CREATE_REFUND_REASONS,
  UPDATE_REFUND_REASONS,
  DELETE_REFUND_REASON,
} from '../../graphql/refundReasons';
import keyBy from 'lodash/keyBy';

export interface UseRefundReasonsProps {
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  refundReasons: { [key: string]: RefundReason };
  refetch: (() => void) | undefined;
  getRefundReasons: () => void;
  createRefundReasons: (refundReasonsInput: CreateRefundReasonInput[]) => void;
  createdRefundReasonIds: string[];
  updateRefundReasons: (refundReasonsInput: UpdateRefundReasonInput[]) => void;
  updatedRefundReasonIds: string[];
  deleteRefundReason: (id: string) => void;
  deletedRefundReason: boolean;
}

export function useRefundReasons(): UseRefundReasonsProps {
  const [refundReasons, setRefundReasons] = useState<
    Record<string, RefundReason>
  >({});

  const [createdRefundReasonIds, setCreatedRefundReasonIds] = useState<
    string[]
  >([]);

  const [updatedRefundReasonIds, setUpdatedRefundReasonIds] = useState<
    string[]
  >([]);

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [deletedRefundReason, setDeletedRefundReason] = useState(false);

  const onCompleteGetRefundReasonsRequest = useCallback(
    data => {
      if (data) {
        setDeletedRefundReason(false);
        const refundReasonsData = data.refundReasons as RefundReason[];
        const refundReasonsDict: Record<string, RefundReason> = keyBy(
          refundReasonsData,
          'id',
        );
        setRefundReasons(refundReasonsDict);
      }
    },
    [setRefundReasons],
  );

  const [getRefundReasons, getRefundReasonsResponse] = useLazyQuery(
    GET_REFUND_REASONS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetRefundReasonsRequest,
    },
  );

  // create
  const [createRefundReasonsRequest, createRefundReasonsResponse] = useMutation(
    CREATE_REFUND_REASONS,
    {
      onError: noopHandler,
    },
  );

  const createRefundReasons = useCallback(
    (createRefundReasonsInput: CreateRefundReasonInput[]) => {
      createRefundReasonsRequest({
        variables: {
          input: createRefundReasonsInput,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createRefundReasonsRequest],
  );

  useEffect(() => {
    if (createRefundReasonsResponse.data) {
      const newlyCreatedRefundReasons = createRefundReasonsResponse.data
        .createRefundReasons as RefundReason[];

      setRefundReasons(refundReasons => {
        return {
          ...refundReasons,
          ...keyBy(newlyCreatedRefundReasons || [], 'id'),
        };
      });

      setCreatedRefundReasonIds(
        newlyCreatedRefundReasons.map(refundReason => refundReason.id),
      );
    }
  }, [createRefundReasonsResponse.data]);

  // create
  const [updateRefundReasonsRequest, updateRefundReasonsResponse] = useMutation(
    UPDATE_REFUND_REASONS,
    {
      onError: noopHandler,
    },
  );

  useEffect(() => {
    if (updateRefundReasonsResponse.data) {
      const updatedRefundReasons = updateRefundReasonsResponse.data
        .updateRefundReasons as RefundReason[];

      setRefundReasons(refundReasons => {
        return {
          ...refundReasons,
          ...keyBy(updatedRefundReasons || [], 'id'),
        };
      });
      setUpdatedRefundReasonIds(
        updatedRefundReasons.map(refundReason => refundReason.id),
      );
    }
  }, [updateRefundReasonsResponse.data]);

  const updateRefundReasons = useCallback(
    (updateRefundReasonsInput: UpdateRefundReasonInput[]) => {
      updateRefundReasonsRequest({
        variables: {
          input: updateRefundReasonsInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateRefundReasonsRequest],
  );

  // delete

  const [deleteRefundReasonRequest, deleteRefundReasonResponse] = useMutation(
    DELETE_REFUND_REASON,
    {
      onError: noopHandler,
    },
  );

  const deleteRefundReason = useCallback(
    (id: string) => {
      deleteRefundReasonRequest({
        variables: {
          id,
        },
      });
      setOperation(Operation.DELETE);
    },
    [deleteRefundReasonRequest],
  );

  useEffect(() => {
    if (deleteRefundReasonResponse.data) {
      setDeletedRefundReason(
        deleteRefundReasonResponse.data.deleteRefundReason,
      );
    }
  }, [deleteRefundReasonResponse.data]);

  const loading: boolean =
    getRefundReasonsResponse.loading ||
    updateRefundReasonsResponse.loading ||
    createRefundReasonsResponse.loading ||
    deleteRefundReasonResponse.loading;

  const error: ApolloError | undefined =
    getRefundReasonsResponse.error ||
    updateRefundReasonsResponse.error ||
    createRefundReasonsResponse.error ||
    deleteRefundReasonResponse.error;

  const refetch = getRefundReasonsResponse.refetch;

  return {
    loading,
    error: error ? parseApolloError(error) : undefined,
    operation,
    refetch,
    refundReasons,
    getRefundReasons,
    createRefundReasons,
    createdRefundReasonIds,
    updateRefundReasons,
    updatedRefundReasonIds,
    deleteRefundReason,
    deletedRefundReason,
  };
}
