import { Order, OrderStatus, Table } from '@hitz-group/domain';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import format from 'date-fns/format';
import _ from 'lodash';

/**
 * Order status text mapping for customer
 *
 * Notes:
 * - Cancelled is not allowed to print
 */
const orderStatusDictionary = {
  [OrderStatus.VOID]: 'Void',
  [OrderStatus.DRAFT]: 'Draft',
  [OrderStatus.CREATED]: 'In Progress',
  [OrderStatus.REFUNDED]: 'Refunded',
  [OrderStatus.IN_PROGRESS]: 'In Progress',
  [OrderStatus.ON_HOLD]: 'On Hold',
  [OrderStatus.COMPLETED]: 'Paid',
  [OrderStatus.CANCELLED]: 'Cancelled',
  [OrderStatus.PARTNER_CANCELLED]: 'Partner Cancelled',
  [OrderStatus.MERGED]: 'Order Merged',
};

/**
 * Order details section has two columns and `n` row(s)
 */
const config: TableUserConfig = {
  columns: {
    0: {},
    1: {
      alignment: 'right',
      width: 25,
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    // TODO: get these from api / our custom hook
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

/**
 * Row definitions
 */
const orderDetailRows: Array<{
  label: string; // Label to be displayed on left
  path: string; // object path
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueTransformationFn?: (args: any) => string | undefined;
  hideEmptyValue?: boolean; // any transformation required on result
}> = [
  {
    label: 'Order No.',
    path: 'orderNumber',
  },
  {
    label: 'Channel Name',
    path: 'salesChannel.name',
  },
  {
    label: 'Order Date',
    path: 'createdAt',
    valueTransformationFn: (value: number) =>
      value ? format(new Date(value), 'dd.MM.yy hh:mm a') : undefined,
  },
  {
    label: 'Order Status',
    path: 'status',
    valueTransformationFn: (value: OrderStatus) => orderStatusDictionary[value],
  },
  {
    label: 'Refund Date',
    path: 'createdAt',
    valueTransformationFn: (value: number) =>
      value ? format(new Date(value), 'dd.MM.yy hh:mm a') : undefined,
  },
  {
    label: 'Refund Status',
    path: 'status',
    valueTransformationFn: (value: OrderStatus) => orderStatusDictionary[value],
  },
  {
    label: 'Order Type',
    path: 'orderType.name',
  },
  {
    label: 'Table No',
    path: 'table',
    valueTransformationFn: (value: Table) => (value && value.name) || '',
    hideEmptyValue: true,
  },
  {
    label: 'Served By',
    path: 'createdBy.name',
  },
  {
    label: 'Device',
    path: 'updatedByDevice.name',
  },
];

/**
 * Generate the order details rows for billing or kitchen receipt
 * @param order
 *
 * Example output:
 * ```bash
 * Order No.                           SP-34234
 * Order Date                       20-Feb-2021
 * Order Status                     In Progress
 * Order Type                          Takeaway
 * Served By                             Minion
 * ```
 */
export const generateOrderDetails = (
  order: Order,
  columns?: string[],
): string => {
  const orderDetailsTable = orderDetailRows.reduce((acc, row) => {
    let value = _.get(order, row.path);
    if (row.valueTransformationFn) {
      value = row.valueTransformationFn(value);
    }
    if ((row.hideEmptyValue && !value) || !columns?.includes(row.label)) {
      return acc;
    }
    const rowWithData = [row.label, value || ''];
    acc.push(rowWithData);
    return acc;
  }, [] as string[][]);

  return table(orderDetailsTable, config);
};

export const BILL_RECEIPT_ORDER_COLUMNS = [
  'Order No.',
  'Channel Name',
  'Order Date',
  'Order Status',
  'Order Type',
  'Table No',
  'Served By',
  'Device',
];

export const REFUND_BILL_RECEIPT_ORDER_COLUMNS = [
  'Order No.',
  'Refund Date',
  'Refund Status',
  'Order Type',
  'Table No',
  'Served By',
  'Device',
];

export const DOCKET_ORDER_COLUMNS = [
  'Order No.',
  'Order Date',
  'Served By',
  'Device',
];
