import { StyleFn } from '@hitz-group/domain';
import * as React from 'react';
import { FelaComponent } from 'react-fela';
import { TouchableOpacity } from 'react-native';
import scale, { isWeb } from '../../common/theme';
import { Icons, RenderProps } from '@hitz-group/domain';
import Icon from '../Icon/Icon';

const paginationButtonStyle: StyleFn = ({ theme, disabled }) => ({
  flex: 0.485,
  height: isWeb ? scale.moderateScale(52) : scale.moderateScale(42),
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: disabled ? theme.colors.boxBorder : theme.colors.white,
  overflow: 'hidden',
});

export interface PaginationBtnProps {
  testID?: string;
  type: 'next' | 'prev' | 'done';
  onPress: () => void;
  scrollDirection: 'vertical' | 'horizontal';
  currentPage: number;
  totalPages: number;
  disabled?: boolean;
}

export const PaginationButton: React.FC<PaginationBtnProps> = (
  props: PaginationBtnProps,
) => (
  <FelaComponent style={paginationButtonStyle} {...props}>
    {({ style }: RenderProps): React.ReactNode => (
      <TouchableOpacity style={style} {...props}>
        <Icon
          name={
            props.type === 'done'
              ? Icons.Check
              : props.type === 'prev'
              ? props.scrollDirection === 'vertical'
                ? Icons.AngleUp
                : Icons.AngleLeft
              : props.scrollDirection === 'vertical'
              ? Icons.AngleDown
              : Icons.AngleRight
          }
          disabled={props.disabled}
          primary={!props.disabled}
        />
      </TouchableOpacity>
    )}
  </FelaComponent>
);

export default PaginationButton;
