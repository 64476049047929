import { useTranslation } from '@hitz-group/localization';
import { useAppState } from '@react-native-community/hooks';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { differenceInHours } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AppStateStatus, Platform, Linking } from 'react-native';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, pluck } from 'rxjs/operators';
import { App } from '@hitz-group/domain';
import { useDevices } from './hooks/app/useDevices';
import { useInterval } from './hooks/app/useInterval';
import { useOrderNotifications } from './hooks/app/useOrderNotifications';
import { useSession } from './hooks/app/useSession';
import useBehaviorSubjectState from './hooks/app/useSubjectState';
import { useSyncOrderEvents } from './hooks/app/useSyncOrderEvents';
import { useNotification } from './hooks/Notification';
import CreatePassword from './screens/Auth/CreatePassword/CreatePassword';
import DeviceCodeLogin from './screens/Auth/DeviceCodeLogin/DeviceCodeLogin';
import ForgotPasswordScreen from './screens/Auth/ForgotPassword/ForgotPasswordScreen';
import LoginScreen from './screens/Auth/Login/LoginScreen';
import SignUpScreen from './screens/Auth/Signup/SignUpScreen';
import BackOfficeNavigator from './screens/BackOffice/BackOfficeNavigator';
import LoadingScreen from './screens/Loading/Loading';
import POSNavigator from './screens/POS/POSNavigator';
import { failedPrintJobsCountVar } from './state/cache';
import { lastActiveTimeSubject } from './state/lastActiveTime';
import { deepLinkConfig, navigateToLockScreen } from './state/navigation';
import * as settings from './state/preferences';
import { getUserActivity, setUserActivity } from './state/preferences';
import { AuthState, tokenUtility } from './state/tokenUtility';
import { userUtility } from './state/userUtility';
import * as storage from './storage/interface';
import {
  firebaseMessagingClient,
  requestPushNotificationToken,
} from './utils/firebaseClientHelper';
import { WorkerActionResult, WorkerActionResultStatus } from './workers/utils';
import { useSettings } from './hooks/app/useSettings';
import { Session } from './state/Session';

const isNavigationToPosScreen = (url: string | null) => {
  return url && /^https?:\/\/[^\/]+\/pos/gi.test(url);
};

const Stack = createStackNavigator();

const loginRoute = {
  index: 0,
  routes: [{ name: 'Login' }],
};

const Navigator: React.FC = () => {
  const [session] = useSession();
  const [sessionStorage] = useSettings('session');
  const [initialURL, setInitialURL] = useState<string | null>(null);

  const { updateDeviceToken } = useDevices({
    storeId: session?.currentStore?.id,
  });
  const deviceID = session?.device?.id;
  const currentAppState = useAppState();
  const previousAppState = useRef<AppStateStatus>();
  const { saveNotification, processNotification } = useOrderNotifications();
  const { syncAllOrderEvents } = useSyncOrderEvents();
  const { startInterval } = useInterval(() => {
    syncAllOrderEvents();
  }, 60000);
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOADING);
  const [fcmToken, setFcmToken] = useState('');
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { setValue: setLastActiveTime } = useBehaviorSubjectState(
    lastActiveTimeSubject,
  );

  const onWorkerMessage = useCallback(
    async (messages: WorkerActionResult[]) => {
      try {
        if (messages) {
          const newMessage = (messages || [])[messages?.length - 1 || 0];
          if (newMessage?.status === WorkerActionResultStatus.ERROR) {
            failedPrintJobsCountVar(
              (messages || []).reduce(
                (acc, item) =>
                  item.status === WorkerActionResultStatus.ERROR ? ++acc : acc,
                0,
              ),
            );
          } else {
            failedPrintJobsCountVar(0);
          }

          if (newMessage && !newMessage.processed) {
            showNotification({
              error: true,
              message: `${translate('printing.printFailed')} ${
                newMessage.message
              }`,
            });

            await storage.setItem(
              settings.WORKER_MESSAGES_KEY,
              messages.map(message =>
                message.requestId === newMessage.requestId
                  ? { ...message, processed: true }
                  : message,
              ),
            );
          }
        }
      } catch {
        // ignore
      }
    },
    [showNotification, translate],
  );

  useEffect(() => {
    (async () => {
      const responses =
        (await storage.getItem<WorkerActionResult[]>(
          settings.WORKER_MESSAGES_KEY,
        )) || [];
      await storage.setItem<WorkerActionResult[]>(
        settings.WORKER_MESSAGES_KEY,
        responses.filter(job => {
          if (job.timestamp) {
            try {
              return (
                differenceInHours(new Date(), new Date(job.timestamp)) < 24
              );
            } catch {}
            return false;
          }
        }),
      );
    })();
  }, []);

  useEffect(() => {
    storage.addSubscription(settings.WORKER_MESSAGES_KEY, onWorkerMessage);
    return () => {
      storage.removeSubscription(settings.WORKER_MESSAGES_KEY, onWorkerMessage);
    };
  }, [onWorkerMessage]);

  useEffect(() => {
    async function getURL() {
      const initialURL = await Linking.getInitialURL();
      setInitialURL(initialURL);
    }
    getURL();
  }, []);

  const onPushNotificationReceive = useCallback(async () => {
    try {
      processNotification();
    } catch {
      // ignore
    }
  }, [processNotification]);

  useEffect(() => {
    storage.addSubscription(
      settings.PUSH_NOTIFICATION_MESSAGE_KEY,
      onPushNotificationReceive,
    );
    return () => {
      storage.removeSubscription(
        settings.PUSH_NOTIFICATION_MESSAGE_KEY,
        onPushNotificationReceive,
      );
    };
  }, [onPushNotificationReceive]);

  useEffect(() => {
    if (authState === AuthState.LOGGED_IN && currentAppState == 'active') {
      startInterval(true);
    }
  }, [startInterval, currentAppState, authState]);

  useEffect(() => {
    if (previousAppState.current !== 'active' && currentAppState === 'active') {
      setLastActiveTime(Date.now());
    }
    previousAppState.current = currentAppState;
  }, [currentAppState, setLastActiveTime]);

  useEffect(() => {
    const subscription: Subscription = tokenUtility.getTokenInfo$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.authState === curr.authState),
        pluck('authState'),
      )
      .subscribe(authState => {
        setAuthState(authState || AuthState.LOADING);
      });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [setAuthState]);

  useEffect(() => {
    let unsubscribeMessaging: { (): void; (): void },
      unsubscribefromRefreshToken: { (): void; (): void };
    if (firebaseMessagingClient) {
      if (Platform.OS == 'web') {
        firebaseMessagingClient.requestPermission().catch(e => console.warn(e));
      }
      if (Platform.OS == 'ios' || Platform.OS == 'android') {
        requestPushNotificationToken();
      }
      if (Platform.OS == 'web' || Platform.OS == 'android') {
        unsubscribeMessaging = firebaseMessagingClient.onMessage(payload => {
          if (payload?.data?.orderEvent) {
            const orderEvent = JSON.parse(payload.data.orderEvent);
            saveNotification(orderEvent);
          }
        });
      }
      unsubscribefromRefreshToken = firebaseMessagingClient.onTokenRefresh(
        fcmToken => {
          setFcmToken(fcmToken);
        },
      );
    }
    return () => {
      if (unsubscribeMessaging) unsubscribeMessaging();
      if (unsubscribefromRefreshToken) unsubscribefromRefreshToken();
    };
  }, [saveNotification]);

  useEffect(() => {
    if (fcmToken && deviceID) {
      updateDeviceToken({
        id: deviceID,
        pushNotificationToken: fcmToken,
      });
    }
  }, [fcmToken, deviceID, updateDeviceToken]);

  const restoreUserActivity = useCallback(async () => {
    const activity = await getUserActivity();
    if (activity) {
      userUtility.setUserActivity(activity);
    }
  }, []);

  useEffect(() => {
    restoreUserActivity();
  }, [restoreUserActivity]);

  useEffect(() => {
    const subscription: Subscription = userUtility.retrieveUserActivity$
      .pipe(filter(activity => Object.keys(activity.officeUsers).length > 0))
      .subscribe(activity => {
        if (activity) {
          setUserActivity(activity);
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  const linking = {
    prefixes: ['https://app.tillpos.co', 'till://'],
    config: deepLinkConfig,
  };

  const initialState = useMemo(() => {
    if (authState !== AuthState.LOGGED_IN) return loginRoute;
    if (
      (sessionStorage as Session)?.device &&
      (Platform.OS !== 'web' || isNavigationToPosScreen(initialURL))
    ) {
      return navigateToLockScreen(App.POS_APP, true);
    }
    return undefined;
  }, [authState, initialURL, sessionStorage]);

  if (authState === AuthState.LOADING) {
    return <LoadingScreen />;
  }

  return (
    <NavigationContainer
      linking={linking}
      initialState={initialState}
      fallback={<LoadingScreen />}
    >
      <Stack.Navigator
        screenOptions={{ animationEnabled: true }}
        initialRouteName="BackOffice"
        headerMode="none"
      >
        <Stack.Screen component={LoginScreen} name="Login" />
        <Stack.Screen component={SignUpScreen} name="Signup" />
        <Stack.Screen component={ForgotPasswordScreen} name="ForgotPassword" />
        <Stack.Screen component={DeviceCodeLogin} name="DeviceCodeLogin" />
        <Stack.Screen component={CreatePassword} name="CreatePassword" />
        <Stack.Screen component={BackOfficeNavigator} name="BackOffice" />
        <Stack.Screen component={POSNavigator} name="POS" />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigator;
