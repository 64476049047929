import React from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import DropDown from '../../../../../../../components/FormInput/DropDown';
import IconButton from '../../../../../../../components/Button/IconButton';
import { timeSlotOptions } from '../../../../../../../utils/dateHelper';
import { pascalCase } from 'pascal-case';
import * as styles from './TimePeriodRow.styles';

export interface TimePeriodRowProps {
  isActive: boolean;
  startTime: string;
  endTime: string;
  onChange: (day: string, key: string, value: boolean | string) => void;
  name: string;
}

export const TimePeriodRow: React.FC<TimePeriodRowProps> = ({
  isActive,
  name,
  onChange,
  startTime,
  endTime,
}) => {
  const { css, theme } = useFela();

  return (
    <View style={css(styles.rowStyle)}>
      <IconButton
        primary
        icon={(isActive && 'toggle-on') || 'toggle-off'}
        iconSize={26}
        containerSize={34}
        iconColor={isActive ? theme.colors.green : theme.colors.paragraph}
        containerStyle={css(styles.iconContainerStyle)}
        onPress={() => onChange(name, 'isActive', !isActive)}
      />

      <View style={css(styles.defaultContainerStyle)}>
        <Text style={css(styles.defaultTextStyle)}>{pascalCase(name)}</Text>
      </View>

      <DropDown
        values={timeSlotOptions}
        selectedValue={startTime}
        style={
          isActive
            ? css(styles.formDropDownContainerStyle)
            : css(styles.deactivatedDropDown)
        }
        extraStyle={css(styles.dropdownExtraStyle)}
        extraMainViewStyle={css(styles.dropDownMainViewStyle)}
        onValueChange={value => isActive && onChange(name, 'startTime', value)}
      />

      <DropDown
        values={timeSlotOptions}
        selectedValue={endTime}
        style={
          isActive
            ? css(styles.formDropDownContainerStyle)
            : css(styles.deactivatedDropDown)
        }
        extraStyle={css(styles.dropdownExtraStyle)}
        extraMainViewStyle={css(styles.dropDownMainViewStyle)}
        onValueChange={value => isActive && onChange(name, 'endTime', value)}
      />
    </View>
  );
};
