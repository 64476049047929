import React from 'react';
import { View } from 'react-native';
import { OrderItem } from '@hitz-group/domain';
import CartItems from '../../../Cart/CartItems';
import { useFela } from 'react-fela';
import * as styles from './ModalOrderItems.style';
import { CartSelectionState } from '../../../../../src/components/Cart/Cart';

interface ModalItemsProps {
  orderItems?: OrderItem[];
  selectedItems: string[];
  onSelectCartItem: (state: CartSelectionState) => void;
}

const ModalOrderItems: React.FC<ModalItemsProps> = ({
  orderItems,
  selectedItems,
  onSelectCartItem,
}) => {
  const { css } = useFela();
  return (
    <View style={css(styles.itemsContainer)}>
      <CartItems
        items={orderItems}
        containerStyle={css(styles.cartItemsStyle)}
        customPaginationContainerStyle={styles.customPaginationContainerStyle}
        onSelectItem={onSelectCartItem}
        selectedItemIds={selectedItems}
      />
    </View>
  );
};

export default ModalOrderItems;
