import React, { useEffect, useState, useCallback } from 'react';
import { View, ScrollView } from 'react-native';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { useNotification } from '../../../../hooks/Notification';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { useTranslation } from '@hitz-group/localization';
import { useFocusEffect } from '@react-navigation/native';
import { useModal } from '@hitz-group/rn-use-modal';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import { useVenues } from '../../../../hooks/app/useVenues';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Venue } from '@hitz-group/domain';
import { VenuesListStyles } from './VenuesScreen.styles';
import { CreateVenueOrStoreModal } from '../../../../components/Modals/Venues/CreateVenueOrStoreModal';
import { useStores } from '../../../../hooks/app/useStores';
import { VenueRow } from './VenueRow';
import { useFela } from 'react-fela';

export const VenueSettingsScreen: React.FC = () => {
  const { translate } = useTranslation();
  const styles = VenuesListStyles();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();
  const [venuesData, setVenuesData] = useState([] as Venue[]);
  const [searchString, setSearchString] = useState('');
  const {
    getVenues,
    searchVenues,
    createVenue,
    activateVenue,
    createdVenueId,
    activatedVenueId,
    loading: venueLoading,
    error: venueError,
  } = useVenues();

  const {
    createStore,
    createdStoreId,
    loading: storeLoading,
    error: storeError,
  } = useStores({ storeId: '', venueId: '' });

  const loading = venueLoading || storeLoading;
  const error = venueError || storeError;

  useEffect(() => {
    getVenues();
  }, [getVenues]);

  useFocusEffect(
    useCallback(() => {
      getVenues();
    }, [getVenues]),
  );

  useEffect(() => {
    async function updateData() {
      const searchResult = await searchVenues(searchString);
      setVenuesData(searchResult);
    }
    updateData();
  }, [searchVenues, searchString]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onSearchTextChange = useCallback((value: string) => {
    setSearchString(value);
  }, []);

  useEffect(() => {
    if (createdVenueId) {
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.VenueCreatedSuccessfully'),
      });
    }
  }, [createdVenueId, closeModal, showNotification, translate]);

  useEffect(() => {
    if (createdStoreId) {
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.storeCreatedSuccessfully'),
      });
      getVenues();
    }
  }, [getVenues, closeModal, createdStoreId, showNotification, translate]);

  useEffect(() => {
    if (activatedVenueId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyUpdated'),
      });
    }
  }, [activatedVenueId, showNotification, translate]);

  const onPressCreateNewVenue = useCallback(() => {
    showModal(
      <CreateVenueOrStoreModal
        venues={venuesData}
        onCreateStore={createStore}
        onCreateVenue={createVenue}
      />,
    );
  }, [showModal, createVenue, createStore, venuesData]);

  const onPressStatusChange = useCallback(
    (id, status) => {
      showModal(
        <ConfirmationDialog
          title={
            status
              ? translate('backOfficeSettings.enable')
              : translate('backOfficeSettings.disable')
          }
          message={
            status
              ? translate('backOfficeSettings.enableVenueMessage')
              : translate('backOfficeSettings.disableVenueMessage')
          }
          onConfirm={() => {
            closeModal();
            activateVenue({ id: id, isActive: status });
          }}
        />,
      );
    },
    [showModal, activateVenue, closeModal, translate],
  );

  const COLUMNS = [
    {
      title: translate('backOfficeVenues.active'),
      alignItems: 'center',
      flex: 1,
    },
    {
      title: translate('backOfficeVenues.name'),
      alignItems: 'flex-start',
      flex: 4,
    },
    { flex: 1 },
  ].map(columnName => ({
    ...columnName,
  }));
  const { theme } = useFela();

  return (
    <ScrollView testID="general-settings-screen" style={styles.pageStyle}>
      <View style={styles.width100row}>
        <BackOfficeSection
          title={translate('backOfficeVenues.venuesAndStores')}
          titleDescription={translate('backOfficeVenues.titleDescription')}
          contentContainerStyle={styles.formStyle}
          titleAction={
            <View style={styles.titleActionStyle}>
              <SearchBar
                placeholder={translate('backOfficeVenues.search')}
                containerStyle={styles.searchContainerStyle}
                textInputStyle={styles.searchTextInputStyle}
                iconColor={theme.colors.paragraph}
                placeholderColor={theme.colors.paragraph}
                onChange={onSearchTextChange}
                maxLength={50}
              />
              <View style={styles.createButtonStyle}>
                <BackOfficeCreateNewButton onPress={onPressCreateNewVenue} />
              </View>
            </View>
          }
        ></BackOfficeSection>
      </View>
      <View style={styles.tableContainer}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <TableComponent
            columns={COLUMNS}
            normalRows={true}
            data={venuesData}
            columnContainerStyle={styles.columnContainerStyle}
            renderRow={(item: Venue, index: number): React.ReactNode => (
              <VenueRow
                venue={item as Venue}
                key={index}
                onChangeVenueStatus={onPressStatusChange}
              />
            )}
          />
        )}
      </View>
    </ScrollView>
  );
};
