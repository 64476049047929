import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import TableRow from '../../../../../components/TableComponent/TableRow';
import IconButton from '../../../../../components/Button/IconButton';

export interface PageItem {
  name: string;
  subPages: string;
  productCount: number;
}

const pageNameStyle: StyleFn = ({ theme }) => ({
  width: 180,
  height: 38,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
});

const pageRowTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});

const subPagesStyle: StyleFn = ({ theme }) => ({
  width: 200,
  height: 38,
  marginLeft: 20,
  justifyContent: 'center',
  backgroundColor: theme.colors.greyLight,
  paddingLeft: theme.padding.small,
});

const productCountStyle: StyleFn = ({ theme }) => ({
  width: 60,
  height: 38,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.greyLight,
  marginLeft: theme.spacing.medium,
});

const noPageStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
});

const iconMarginStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small / 2,
});

const dragIconContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.big,
  height: 28,
  width: 28,
  borderRadius: theme.radius.small,
  alignSelf: 'center',
});

const dragIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.darkGrey,
});

const containerStyle: StyleFn = ({ rowIndex }) => ({
  zIndex: 1000 + 10 * rowIndex,
});

interface PageRowProps {
  page: PageItem;
  rowIndex: number;
  drag?: () => void;
}

export const PageRow: React.FC<PageRowProps> = ({
  page,
  rowIndex,
  drag,
}: PageRowProps) => {
  const { css, theme } = useFela();
  return (
    <TableRow
      normalRows
      columnSpacing={theme.spacing.big}
      actionStyle={css(iconMarginStyle)}
      containerStyle={css(containerStyle({ theme, rowIndex }))}
    >
      <IconButton
        icon="draggabledots"
        iconSize={18}
        containerSize={28}
        containerStyle={css(dragIconContainer)}
        iconStyle={css(dragIconStyle)}
        onLongPress={drag}
      />

      <View style={css(pageNameStyle)}>
        <Text style={css(pageRowTextStyle)}>{page.name}</Text>
      </View>

      <View style={css(productCountStyle)}>
        <Text style={css(pageRowTextStyle)}>{page.productCount}</Text>
      </View>

      <View style={css(subPagesStyle)}>
        {(page.subPages && <Text>{page.subPages}</Text>) || (
          <Text style={css(noPageStyle)}>{'No pages within.'}</Text>
        )}
      </View>
    </TableRow>
  );
};
