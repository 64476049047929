import {
  IntegrationApps,
  OnlineOrderingPreferences,
  TimeBlock,
  UpdateIntegrationPartnerInput,
  DEFAULT_PREP_TIME_MINS,
  OrderType,
  OrderTypeCode,
  StyleFn,
  DeliverySettings,
  BaseOrderTypeSettings,
  DineInSettings,
  PickUpSettings,
} from '@hitz-group/domain';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { View, ScrollView } from 'react-native';
import { useStores } from '../../../../../../../hooks/app/useStores';
import BackOfficeSection from '../../../../../../../components/BackOfficeSection/BackOfficeSection';
import { useIntegrationPartners } from '../../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { Operation } from '../../../../../../../types/Operation';
import Button from '../../../../../../../components/Button/Button';
import { useNotification } from '../../../../../../../hooks/Notification';
import LoadingIndicator from '../../../../../../../components/LoadingIndicator/LoadingIndicator';
import { useRoute } from '@react-navigation/native';
import DropDown from '../../../../../../../components/FormInput/DropDown';
import { keyBy, isEqual, mergeWith, isNull } from 'lodash';
import { stripProperties } from '../../../../../../../utils/stripObjectProps';
import FormInput from '../../../../../../../components/FormInput/FormInput';
import { convertAlphaNumbericToNumber } from '@hitz-group/client-utils';
import { useDevices } from '../../../../../../../hooks/app/useDevices';
import * as styles from './Fullfilments.styles';
import TableComponent from '../../../../../../../components/TableComponent/TableComponent';
import OrderTypesRow from './OrderTypesRow';
import { useDeviceProfiles } from '../../../../../../../hooks/app/useDeviceProfiles';
import { useModal } from '@hitz-group/rn-use-modal';
import DeliveryModal from './OrderTypeModals/DeliveryModal';
import DineInModal from './OrderTypeModals/DineInModal';
import PickUpModal from './OrderTypeModals/PickUpModal';
import { useOrganization } from '../../../../../../../hooks/app/useOrganization';
import { useSession } from '../../../../../../../hooks/app/useSession';
import { isOrderTypeEnabled } from '../../../../../../../utils/orderTypesHelper';

export enum OrderAcceptance {
  Enable = 'Enable',
  Disable = 'Disable',
}

type IntegrationPreferences = OnlineOrderingPreferences & {
  operatingHoursMap?: { [key: string]: TimeBlock };
} & { locationId?: string; accountId?: string };

const orderTypesCodeSupported = [
  OrderTypeCode.DELIVERY,
  OrderTypeCode.PICK_UP,
  OrderTypeCode.DINE_IN,
] as string[];

const defaultDeliverySettingsState: DeliverySettings = {
  deliveryFee: 0,
  deliveryTime: 30,
};

const defaultBaseSettingsState: BaseOrderTypeSettings = {
  enablePayLater: false,
  enablePayNow: true,
};

const columnContainerStyle: StyleFn = () => ({
  borderBottomWidth: 0,
});

export const Fullfilments: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [integrationPreferences, setIntegrationPreferences] =
    useState<IntegrationPreferences>({
      autoAcceptOrders: false,
      defaultPrepTime: DEFAULT_PREP_TIME_MINS,
      printDevice: '',
      orderTypes: [],
      deliverySettings: {
        ...defaultBaseSettingsState,
        ...defaultDeliverySettingsState,
      },
      pickUpSettings: { ...defaultBaseSettingsState },
      dineInSettings: { ...defaultBaseSettingsState },
    });

  const route = useRoute();
  const { storeId, app } = route.params as {
    storeId: string;
    app: IntegrationApps;
  };

  const isDeliverectApp = app === IntegrationApps.DELIVERECT;

  const { css, theme } = useFela({ isRowDisplay: isDeliverectApp });

  const {
    stores,
    loading: storesLoading,
    error: storesError,
  } = useStores({ storeId });

  const [session] = useSession();
  const { organization, getOrganizationById } = useOrganization();

  useEffect(() => {
    session.currentOrganization?.id &&
      getOrganizationById(session.currentOrganization?.id);
  }, [getOrganizationById, session.currentOrganization?.id]);

  const { showModal } = useModal();

  const venueId = useMemo(() => {
    return storeId && stores[storeId] ? stores[storeId].venue.id : '';
  }, [storeId, stores]);

  const { devices, getDevices } = useDevices({ storeId });
  const deviceSelectOptions = useMemo(() => {
    return Object.values(devices).map(device => ({
      label: device.name,
      value: device.id,
    }));
  }, [devices]);

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  const { orderTypes: orderTypesData, getOrderTypes } = useDeviceProfiles({
    storeId,
    venueId,
  });
  const orderTypes = useMemo(() => {
    return orderTypesData.filter(
      orderType => orderTypesCodeSupported.indexOf(orderType.code) !== -1,
    );
  }, [orderTypesData]);

  useEffect(() => {
    getOrderTypes();
  }, [getOrderTypes]);

  const {
    loading: integrationLoading,
    getIntegrationPartnerSettings,
    integrationPartners: allIntegrationPartners,
    updateIntegrationPartnerSettings,
    createIntegrationPartner,
    operation,
    error: integrationErr,
  } = useIntegrationPartners();

  const integrationPartners = useMemo(() => {
    return keyBy(Object.values(allIntegrationPartners), 'store');
  }, [allIntegrationPartners]);

  useEffect(() => {
    if (storeId) {
      getIntegrationPartnerSettings({
        appName: app,
        store: storeId,
      });
    }
  }, [getIntegrationPartnerSettings, app, storeId]);

  useEffect(() => {
    if (storeId && integrationPartners[storeId]?.preferences) {
      const preferences =
        integrationPartners[storeId]?.preferences?.onlineOrdering;

      setIntegrationPreferences({
        autoAcceptOrders: preferences?.autoAcceptOrders || false,
        defaultPrepTime: preferences?.defaultPrepTime || DEFAULT_PREP_TIME_MINS,
        printDevice: preferences?.printDevice || '',
        orderTypes:
          preferences?.orderTypes || orderTypes.map(orderType => orderType.id),
        deliverySettings: preferences?.deliverySettings || {
          ...defaultBaseSettingsState,
          ...defaultDeliverySettingsState,
        },
        pickUpSettings: preferences?.pickUpSettings || {
          ...defaultBaseSettingsState,
        },
        dineInSettings: preferences?.dineInSettings || {
          ...defaultBaseSettingsState,
        },
      });
    }
  }, [integrationPartners, storeId, orderTypes]);

  const loading = storesLoading || integrationLoading;

  const error = storesError || integrationErr;

  const isUpdated =
    !integrationErr && !integrationLoading && operation === Operation.UPDATE;

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeFeatures.settingsUpdatedSuccessfully', {
          appName: app,
        }),
      });
    }
  }, [isUpdated, app, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const isSettingsChanged = useCallback(() => {
    const preferences =
      integrationPartners[storeId]?.preferences?.onlineOrdering;

    return (
      !!preferences &&
      (integrationPreferences?.autoAcceptOrders !==
        preferences?.autoAcceptOrders ||
        integrationPreferences?.defaultPrepTime !==
          preferences?.defaultPrepTime ||
        integrationPreferences?.printDevice !== preferences?.printDevice ||
        (integrationPreferences?.orderTypes &&
          preferences?.orderTypes &&
          !isEqual(
            [...integrationPreferences?.orderTypes].sort(),
            [...preferences?.orderTypes].sort(),
          )) ||
        (!!preferences?.deliverySettings &&
          !isEqual(
            integrationPreferences?.deliverySettings,
            preferences.deliverySettings,
          )) ||
        (preferences?.pickUpSettings &&
          !isEqual(
            integrationPreferences?.pickUpSettings,
            preferences.pickUpSettings,
          )) ||
        (preferences?.dineInSettings &&
          !isEqual(
            integrationPreferences?.dineInSettings,
            preferences.dineInSettings,
          )))
    );
  }, [
    integrationPartners,
    storeId,
    integrationPreferences?.autoAcceptOrders,
    integrationPreferences?.defaultPrepTime,
    integrationPreferences?.printDevice,
    integrationPreferences?.orderTypes,
    integrationPreferences?.deliverySettings,
    integrationPreferences?.pickUpSettings,
    integrationPreferences?.dineInSettings,
  ]);

  const saveIntegrationSettings = useCallback(() => {
    let defaultPrintDeviceId = integrationPreferences?.printDevice;

    if (deviceSelectOptions.length && !defaultPrintDeviceId) {
      defaultPrintDeviceId = deviceSelectOptions?.[0]?.value;
    }

    const enabledOrderTypesIds = integrationPreferences?.orderTypes as string[];
    const sortUpdatedOrderType =
      enabledOrderTypesIds.length > 1
        ? [...enabledOrderTypesIds].sort(
            (firstOrderTypeId, secondOrderTypeId) => {
              const firstOrderType = orderTypes.find(
                orderType => orderType.id === firstOrderTypeId,
              );
              const secondOrderType = orderTypes.find(
                orderType => orderType.id === secondOrderTypeId,
              );
              return orderTypesCodeSupported.indexOf(
                firstOrderType?.code as string,
              ) >
                orderTypesCodeSupported.indexOf(secondOrderType?.code as string)
                ? 1
                : -1;
            },
          )
        : integrationPreferences?.orderTypes;

    const onlineOrdering =
      integrationPartners[storeId] &&
      mergeWith(
        {},
        integrationPartners[storeId].preferences?.onlineOrdering,
        {
          orderTypes: sortUpdatedOrderType,
          deliverySettings: isOrderTypeEnabled(
            enabledOrderTypesIds,
            orderTypes,
            OrderTypeCode.DELIVERY,
          )
            ? integrationPreferences?.deliverySettings
            : {},
          pickUpSettings: isOrderTypeEnabled(
            enabledOrderTypesIds,
            orderTypes,
            OrderTypeCode.PICK_UP,
          )
            ? integrationPreferences?.pickUpSettings
            : {},
          dineInSettings: isOrderTypeEnabled(
            enabledOrderTypesIds,
            orderTypes,
            OrderTypeCode.DINE_IN,
          )
            ? integrationPreferences?.dineInSettings
            : {},
          autoAcceptOrders: integrationPreferences?.autoAcceptOrders,
          defaultPrepTime: integrationPreferences?.defaultPrepTime,
          printDevice: defaultPrintDeviceId,
        },
        (sourceValue, destinationValue) =>
          isNull(destinationValue) ? sourceValue : destinationValue,
      );

    const updateSetting: UpdateIntegrationPartnerInput[] = [
      {
        id: integrationPartners[storeId]?.id,
        isActive: true,
        appName: app,
        modules: {
          onlineOrdering: true,
        },
        store: storeId,
        preferences: {
          onlineOrdering,
        },
        link: '',
        venue: venueId,
      },
    ];

    if (app === IntegrationApps.OOLIO_STORE && !integrationPartners[storeId]) {
      createIntegrationPartner(
        stripProperties({ ...updateSetting[0] }, '__typename'),
      );
    } else {
      if (
        updateSetting[0].preferences?.onlineOrdering?.orderTypes?.length === 0
      ) {
        showNotification({
          error: true,
          message: translate('backOfficeFeatures.noActiveOrderTypes'),
        });
        return;
      }
      if (!isSettingsChanged()) {
        showNotification({
          error: true,
          message: translate('backOfficeFeatures.noSettingsChanged'),
        });
        return;
      }
      updateIntegrationPartnerSettings(
        stripProperties(updateSetting, '__typename'),
      );
    }
  }, [
    integrationPreferences?.printDevice,
    integrationPreferences?.orderTypes,
    integrationPreferences?.deliverySettings,
    integrationPreferences?.pickUpSettings,
    integrationPreferences?.dineInSettings,
    integrationPreferences?.autoAcceptOrders,
    integrationPreferences?.defaultPrepTime,
    deviceSelectOptions,
    integrationPartners,
    storeId,
    orderTypes,
    app,
    venueId,
    createIntegrationPartner,
    isSettingsChanged,
    updateIntegrationPartnerSettings,
    showNotification,
    translate,
  ]);

  const onChangeMainDetails = useCallback((key, value) => {
    setIntegrationPreferences(prev => ({ ...prev, [key]: value }));
  }, []);

  const orderAcceptanceOptions = useMemo(
    () => [
      {
        value: OrderAcceptance.Enable,
        label: translate('backOfficeFeatures.enabled'),
      },
      {
        value: OrderAcceptance.Disable,
        label: translate('backOfficeFeatures.disabled'),
      },
    ],
    [translate],
  );

  const onUpdateOrderTypeSettings = useCallback(
    (
      settingName: string,
      settings: DeliverySettings | DineInSettings | PickUpSettings,
    ) => {
      onChangeMainDetails(settingName, settings);
    },
    [onChangeMainDetails],
  );

  const openOrderTypeModal = useCallback(
    (code: OrderTypeCode) => {
      showModal(
        (code === OrderTypeCode.DELIVERY && (
          <DeliveryModal
            deliverySettings={integrationPreferences.deliverySettings}
            onConfirm={onUpdateOrderTypeSettings}
            currencyCode={organization?.currencyCode}
          />
        )) ||
          (code === OrderTypeCode.PICK_UP && (
            <PickUpModal
              pickUpSettings={integrationPreferences.pickUpSettings}
              onConfirm={onUpdateOrderTypeSettings}
            />
          )) ||
          (code === OrderTypeCode.DINE_IN && (
            <DineInModal
              dineInSettings={integrationPreferences.dineInSettings}
              onConfirm={onUpdateOrderTypeSettings}
            />
          )),
      );
    },
    [
      integrationPreferences.deliverySettings,
      integrationPreferences.dineInSettings,
      integrationPreferences.pickUpSettings,
      onUpdateOrderTypeSettings,
      organization?.currencyCode,
      showModal,
    ],
  );

  const toggleOnOrderType = useCallback(
    (orderTypeId: string) => {
      const activeOrderTypes =
        (integrationPreferences?.orderTypes && [
          ...integrationPreferences.orderTypes,
        ]) ||
        [];
      activeOrderTypes.push(orderTypeId);
      onChangeMainDetails('orderTypes', activeOrderTypes);
    },
    [integrationPreferences?.orderTypes, onChangeMainDetails],
  );

  const toggleOffOrderType = useCallback(
    (orderTypeId: string) => {
      const activeOrderTypes =
        (integrationPreferences?.orderTypes && [
          ...integrationPreferences.orderTypes,
        ]) ||
        [];
      const orderTypeIndex = activeOrderTypes.indexOf(orderTypeId);
      orderTypeIndex !== -1 && activeOrderTypes.splice(orderTypeIndex, 1);
      onChangeMainDetails('orderTypes', activeOrderTypes);
    },
    [integrationPreferences?.orderTypes, onChangeMainDetails],
  );

  const onToggleOrderType = useCallback(
    (orderTypeId: string, value: boolean) => {
      if (value) toggleOnOrderType(orderTypeId);
      else toggleOffOrderType(orderTypeId);
    },
    [toggleOffOrderType, toggleOnOrderType],
  );

  const renderOrderTypes = useMemo(() => {
    return (
      <BackOfficeSection
        title={translate('backOfficeFeatures.orderTypes')}
        contentContainerStyle={css(styles.orderTypesContainerStyle)}
      >
        <TableComponent
          columns={[
            {
              width: 400,
            },
            {
              width: 100,
              alignItems: 'flex-end',
            },
          ]}
          columnSpacing={theme.spacing.medium + theme.spacing.small / 2}
          columnContainerStyle={css(columnContainerStyle)}
          data={orderTypes}
          normalRows={true}
          renderRow={(item: OrderType, index: number): React.ReactNode => (
            <OrderTypesRow
              key={index}
              orderType={item}
              onToggle={onToggleOrderType}
              enabled={
                integrationPreferences.orderTypes?.indexOf(item.id) !== -1
                  ? true
                  : false
              }
              onOpenModal={openOrderTypeModal}
            />
          )}
        />
      </BackOfficeSection>
    );
  }, [
    css,
    integrationPreferences.orderTypes,
    onToggleOrderType,
    openOrderTypeModal,
    orderTypes,
    theme.spacing.medium,
    theme.spacing.small,
    translate,
  ]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.integrationsPage', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView
        testID="general-settings-screen"
        contentContainerStyle={css(styles.generalSettingsContainerWrapper)}
      >
        <View style={css(styles.pageStyle)}>
          <BackOfficeSection
            title={translate('backOfficeFeatures.autoAcceptOrders')}
            contentContainerStyle={css(styles.autoAcceptOrdersContentStyle)}
          >
            <DropDown
              testID="order-acceptance-dropdown"
              values={orderAcceptanceOptions}
              title={translate('backOfficeFeatures.autoAcceptOrders')}
              selectedValue={
                integrationPreferences?.autoAcceptOrders
                  ? OrderAcceptance.Enable
                  : OrderAcceptance.Disable
              }
              style={css(styles.orderAcceptanceDropDown)}
              extraStyle={css(styles.orderAcceptanceDropDownExtraStyle)}
              extraMainViewStyle={css(
                styles.orderAcceptanceDropDownMainViewStyle,
              )}
              onValueChange={value =>
                onChangeMainDetails(
                  'autoAcceptOrders',
                  value === OrderAcceptance.Enable ? true : false,
                )
              }
            />

            <FormInput
              error={false}
              testID="default-prep-time"
              placeholder={translate('backOfficeFeatures.defaultPrepTime')}
              title={translate('backOfficeFeatures.defaultPrepTime')}
              value={integrationPreferences?.defaultPrepTime?.toString()}
              alignTitle="left"
              containerStyle={css(styles.defaultPrepTimeInputContainerStyle)}
              textStyle={css(styles.titleTextStyle)}
              onChangeText={value =>
                onChangeMainDetails(
                  'defaultPrepTime',
                  convertAlphaNumbericToNumber(value),
                )
              }
            />
            <DropDown
              testID="device-select-button"
              values={deviceSelectOptions}
              title={translate('backOfficeFeatures.printRoutingSelection')}
              style={css(styles.orderAcceptanceDropDown)}
              extraStyle={css(styles.orderAcceptanceDropDownExtraStyle)}
              extraViewStyle={css(styles.extraViewStyle)}
              extraMainViewStyle={css(
                styles.orderAcceptanceDropDownMainViewStyle,
              )}
              onValueChange={value => onChangeMainDetails('printDevice', value)}
              selectedValue={integrationPreferences.printDevice}
            />
          </BackOfficeSection>
          {renderOrderTypes}
        </View>
      </ScrollView>

      <View style={css(styles.actionsContainerStyle)}>
        <Button
          fluid
          testID="save-changes"
          title={translate('button.saveChanges')}
          containerStyle={css(styles.saveButtonStyle)}
          labelStyle={css(styles.titleStyle)}
          onPress={saveIntegrationSettings}
        />
      </View>
    </>
  );
};
