import { useCurrency } from '@hitz-group/localization';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { addToValue } from '../../utils/keypadHelper';
import Button from '../Button/Button';
import SelectBar from '../Button/SelectBar';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import InputDisplay from '../InputDisplay/InputDisplay';
import NumberInput, { KeyButton } from '../NumberInput/NumberInput';
import PopupView from '../PopupView/PopupView';
import TitleBar from '../TitleBar/TitleBar';
import { ButtonProps } from '../Button/Button';
import { FelaComponent } from 'react-fela';
import IconButton from '../Button/IconButton';
import { useModal } from '@hitz-group/rn-use-modal';
import { useTranslation } from '@hitz-group/localization/dist';
import Redo from '../Redo/Redo';
import { isIos } from '../../common/theme';
import { MAX_KEYPAD_INPUT } from '../../types/Common';

export interface ClosureKeypadProps {
  amount: string;
  onSubmit?: (value: number) => void;
  showCurrencyInput?: boolean;
  onCancel?: () => void;
}

const selectBarContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  height: 40,
  margin: 1,
  flex: undefined,
  alignSelf: 'stretch',
  alignItems: 'center',
  marginBottom: theme.spacing.small * 2.5,
});
const numberInputStyle: StyleFn = () => ({
  width: '100%',
});
const popupStyle: StyleFn = ({ theme }) => ({
  width: 334,
  height: 520,
  alignSelf: 'center',
  paddingHorizontal: theme.padding.large,
  paddingVertical: theme.padding.large,
});
const popupWithCurrencyInputStyle: StyleFn = ({ theme }) => ({
  width: 334,
  height: 600,
  alignSelf: 'center',
  paddingHorizontal: theme.padding.large,
  paddingVertical: theme.padding.large,
});
const InputDisplayContainerStyle: StyleFn = ({ theme }) => ({
  height: 100,
  backgroundColor: theme.colors.primary,
});
const buttonContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: 20,
  marginBottom: isIos ? 20 : 0,
});
const buttonStyle: StyleFn = () => ({ marginHorizontal: 0 });
const cancelButtonStyle: StyleFn = ({ theme }) => ({
  marginHorizontal: 0,
  backgroundColor: theme.colors.background,
  color: theme.colors.textLight,
});

const headerStyle: StyleFn = ({ theme }) => ({
  width: 334,
  marginTop: theme.spacing.small,
  alignSelf: 'center',
});

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const CloseButtons: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

const ClosureKeypad: React.FC<ClosureKeypadProps> = ({
  amount,
  onSubmit,
  showCurrencyInput,
  onCancel,
}: ClosureKeypadProps) => {
  const { css } = useFela();
  const { formatCurrency, decimalSeparator, unFormatCurrency } = useCurrency();
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [keypadType, setKeypadType] = useState('counted');
  const [value, setValue] = useState(amount);

  const onPressKey = useCallback(
    (key: string): void => {
      setValue(value =>
        addToValue(value, key, decimalSeparator, MAX_KEYPAD_INPUT),
      );
    },
    [decimalSeparator],
  );

  const onSubmitValue = useCallback(() => {
    onSubmit && onSubmit(unFormatCurrency(value));
  }, [onSubmit, value, unFormatCurrency]);

  const onChangeKeypadType = useCallback((type: string) => {
    setValue('0');
    setKeypadType(type);
  }, []);

  const onPressClear = useCallback((): void => {
    setValue('');
  }, []);

  const reset = <Redo onPress={onPressClear} />;

  return (
    <>
      <TitleBar
        primary
        testID="title"
        containerStyle={css(headerStyle)}
        title={translate('shift.enterCashAmount')}
        titleLeft={<CloseButtons onPress={closeModal} />}
      />
      <PopupView
        containerStyle={
          showCurrencyInput ? css(popupWithCurrencyInputStyle) : css(popupStyle)
        }
      >
        {showCurrencyInput && (
          <SelectBar
            containerStyle={css(selectBarContainerStyle)}
            options={[
              { label: 'Counted', value: 'counted' },
              { label: 'Currency', value: 'currency' },
            ]}
            selectedOption={keypadType}
            onPress={onChangeKeypadType}
          />
        )}
        <InputDisplay
          testID="passcode"
          value={formatCurrency(+value)}
          containerStyle={css(InputDisplayContainerStyle)}
          right={reset}
        />
        {keypadType === 'counted' ? (
          <NumberInput
            testID="keypad"
            onPressKey={onPressKey}
            containerStyle={css(numberInputStyle)}
            keys={[
              { value: '1' } as KeyButton,
              { value: '2' } as KeyButton,
              { value: '3' } as KeyButton,
              { value: '4' } as KeyButton,
              { value: '5' } as KeyButton,
              { value: '6' } as KeyButton,
              { value: '7' } as KeyButton,
              { value: '8' } as KeyButton,
              { value: '9' } as KeyButton,
              { value: '0' } as KeyButton,
              { value: decimalSeparator } as KeyButton,
            ]}
            keyWidth={93}
            keyHeight={70}
            twistKeyWidth={2.06}
          />
        ) : (
          <CurrencyInput
            onChange={setValue}
            containerStyle={css(numberInputStyle)}
          />
        )}
        <View style={css(buttonContainerStyle)}>
          <Button
            size="small"
            title="cancel"
            containerStyle={css(cancelButtonStyle)}
            onPress={onCancel}
          />
          <Button
            size="small"
            title="save"
            success
            containerStyle={css(buttonStyle)}
            onPress={onSubmitValue}
          />
        </View>
      </PopupView>
    </>
  );
};

export default ClosureKeypad;
