import {
  IntegrationApps,
  Feature,
  UpdateIntegrationPartnerInput,
} from '@hitz-group/domain';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { View, ScrollView, Text, Image } from 'react-native';
import { ImageCarousel } from '../../../../../../components/ImageCarousel/ImageCarousel';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import { useFeatures } from '../../../../../../hooks/app/features/useFeatures';
import Button from '../../../../../../components/Button/Button';
import * as styles from './About.styles';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { SettingsContext } from '../OnlineOrderIntegrationsSettingsTabs';
import { useIntegrationPartners } from '../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { stripProperties } from '../../../../../../../src/utils/stripObjectProps';

export const AboutTab: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const route = useRoute();
  const context = useContext(SettingsContext);
  const navigation = useNavigation();
  const [isActive, setIsActive] = useState(false);

  const params = context.params || (route.params as { app: IntegrationApps });
  const { currentFeature, loadings: featureLoading } = useFeatures(
    params.app && params.app,
  );

  const {
    loading: integrationParnetsloading,
    getIntegrationPartnerSettings,
    integrationPartners,
    updateIntegrationPartnerSettings,
  } = useIntegrationPartners();

  useFocusEffect(
    useCallback(() => {
      getIntegrationPartnerSettings({ appName: params.app });
    }, [getIntegrationPartnerSettings, params.app]),
  );

  useEffect(() => {
    const integrationPartnersArray = Object.values(integrationPartners);

    const isActive = integrationPartnersArray.some(
      integrationPartner => integrationPartner.isActive,
    );

    setIsActive(isActive);
  }, [integrationPartners]);

  const toggleConnect = useCallback(() => {
    if (isActive) {
      const integrationPartnersArray = Object.values(
        stripProperties(integrationPartners, '__typename'),
      ) as UpdateIntegrationPartnerInput[];
      integrationPartnersArray.forEach(
        integrationPartner => (integrationPartner.isActive = false),
      );

      updateIntegrationPartnerSettings(integrationPartnersArray);
    } else {
      // Have to enable at least one store in Settings Tab to connect the Oolio. So, app should navigate to SettingsTab
      navigation.navigate('SettingsTab');
    }
  }, [
    navigation,
    isActive,
    integrationPartners,
    updateIntegrationPartnerSettings,
  ]);

  if (featureLoading || integrationParnetsloading) {
    return <LoadingIndicator />;
  }

  const featureData = currentFeature?.feature as Feature;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.integrationsPage', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView
        testID="online-orders-integration-about-screen"
        contentContainerStyle={css(styles.aboutContainerStyle)}
      >
        <View style={css(styles.pageStyle)}>
          <Image
            style={css(styles.imageContainerStyle)}
            source={{ uri: featureData?.icon }}
          />
          <Text style={css(styles.ctaTitle)}>{featureData.name}</Text>
          <Text style={css(styles.ctaSubTitle)}>{featureData.about}</Text>
          <Button
            fluid
            testID={isActive ? 'disconnect-button' : 'connect-button'}
            title={translate(
              isActive
                ? 'backOfficeFeatures.disconnect'
                : 'backOfficeFeatures.connect',
            )}
            containerStyle={css(
              isActive
                ? styles.disconnectButtonStyle
                : styles.connectButtonStyle,
            )}
            labelStyle={css(
              isActive ? styles.disconnectTitleStyle : styles.connectTitleStyle,
            )}
            disabled={featureLoading}
            onPress={toggleConnect}
          />
        </View>

        <View style={css(styles.detailsContainerStyle)}>
          {featureData.subFeatureList.map(eachList => (
            <View style={css(styles.detailsTileStyle)} key={eachList.name}>
              <Text style={css(styles.detailsTitleStyle)}>{eachList.name}</Text>
              <Text style={css(styles.ctaSubTitle)}>
                {eachList?.description}
              </Text>
            </View>
          ))}

          <View style={css(styles.detailsTileStyle)}>
            <Text style={css(styles.detailsTitleStyle)}>
              {translate('backOfficeFeatures.screenshots')}
            </Text>
            <Text style={css(styles.ctaSubTitle)}></Text>
            {featureData?.screenshots?.length && (
              <ImageCarousel imageUrls={featureData?.screenshots} />
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};
