import { StyleFn, Theme } from '@hitz-group/domain';
import { StyleSheet } from 'react-native';

export const getStyles = (theme: Theme) => {
  const styles = StyleSheet.create({
    container: {
      marginTop: 0,
      alignItems: 'center',
      marginBottom: 4,
      backgroundColor: theme.colors.whiteTransparent,
      paddingHorizontal: 0,
    },
    cartHeaderButton: {
      width: 56,
      height: 24,
      marginVertical: 0,
      marginHorizontal: 0,
      alignSelf: 'center',
    },

    cartHeaderLeftOrderNumberView: {
      flex: 1,
      alignSelf: 'center',
      paddingHorizontal: 0,
      borderRadius: theme.radius.small,
    },
    labelContainer: {
      flex: 1,
    },

    cartHeaderLeftView: {
      height: '100%',
      flex: 2,
      backgroundColor: theme.colors.white,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: theme.padding.medium,
      borderRadius: theme.radius.small,
    },

    orderNumberAndTypeContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },

    boldText: {
      ...theme.font14SemiBold,
    },

    cartHeaderBadge: {
      fontSize: theme.fontSize.small,
      textAlign: 'center',
    },
    labelText: {
      ...theme.font14RegularCharcoal,
    },

    cartHeaderRightTableNumberView: {
      width: 56,
      height: 24,
      marginHorizontal: 0,
      alignSelf: 'center',
      backgroundColor: theme.colors.info,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.radius.small,
    },
    cartHeaderRightBadge: {
      fontSize: theme.fontSize.small,
      textAlign: 'center',
      color: theme.colors.white,
    },
  });

  return styles;
};

export const cursorStyle: StyleFn = () => ({
  cursor: 'default',
});
