import React, { FC } from 'react';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

export enum LogoFillColor {
  WHITE = 'white',
}
interface Props {
  fillColor?: LogoFillColor;
}
const Till: FC<Props> = ({ fillColor }) => {
  const fill = `url(#${
    fillColor === LogoFillColor.WHITE ? 'white' : 'gradient'
  })`;
  return (
    <Svg viewBox="0 0 130 84">
      <Defs>
        <LinearGradient id="gradient" x1="0" y1="0" x2="1" y2="1">
          <Stop offset="0" stopColor="#4b44b6" stopOpacity="1" />
          <Stop offset="1" stopColor="#ae61d0" stopOpacity="1" />
        </LinearGradient>

        <LinearGradient id="white" x1="0" y1="0" x2="1" y2="1">
          <Stop offset="0" stopColor="#ffffff" stopOpacity="1" />
          <Stop offset="1" stopColor="#ffffff" stopOpacity="1" />
        </LinearGradient>
      </Defs>
      <Path
        fill={fill}
        d="M83.3 4c-1.8 1.1-4.6 3.8-6.2 6-3.2 4.4-4.2 8.5-8.2 31-1.2 6.9-2.7 13.6-3.5 15.1C64 58.9 55.6 67 54 67c-1.9 0-1-5.1 2.5-13.8 4.6-11.1 5.4-16.2 2.9-18.6C54.1 29.3 40 41.3 40 51.2c0 3.2-.8 4.5-5.4 9.3-5.6 5.7-11.3 8.9-14 7.9-2.1-.8-2.1-6.2 0-15.8l1.7-7.5 4.3.6c6.3.8 9.6-1.5 9.2-6.5l-.3-3.7-5.2-.3-5.2-.3 3.5-7.2c3.6-7.5 4.1-10.5 2.4-13.8-1.3-2.3-7.7-2.6-11.8-.5-4 2-7.3 8.7-7.9 15.8-.4 4.2-1.1 6.1-2.2 6.5-4.8 1.7-7 7.6-3.2 8.6 1.8.4 1.8.8-.5 9.3-4.1 15.4-2.8 24.1 4.1 27 7 2.9 14.7.8 22.3-6 2.1-2 4.2-3.6 4.6-3.6.3 0 .6 1.5.6 3.3 0 4.4 2.8 7 7.8 7 4.5 0 8.9-2.2 14.3-7l3.9-3.5v3c0 10 13.1 10.1 24.5.1l3.5-3.1v3c0 1.6.8 4 1.8 5.3 2.4 2.9 9.3 2.9 14.8.1 8.6-4.4 19.4-16.5 19.4-21.8 0-1.2-.7-2.8-1.6-3.5-1.4-1.1-1.8-1-2.6.6C120.9 58 110.4 68 108.7 68c-2.5 0-2.2-3.6 1.8-18.5 2.1-8.1 5.5-17.4 8.7-24 6-12.8 6.8-18.4 2.9-21.6-4.3-3.5-10.3-1.9-15.8 4.5-3.9 4.3-5.6 9.8-8.3 25.5-3.2 19.2-3.9 21.7-7.1 25.8-6.2 7.8-11.9 10.7-11.9 6 0-5.8 7.3-29.9 12-39.7 2.7-5.8 5.2-12.4 5.6-14.7.5-3.6.3-4.5-1.9-6.7-3.1-3.1-7-3.3-11.4-.6z"
      />
      <Path
        fill={fill}
        d="M55.3 11.5c-2.6 1.8-4.7 7.7-3.9 11 1.6 6.2 7.8 7.4 12 2.4 6.6-7.8-.2-19-8.1-13.4z"
      />
    </Svg>
  );
};

export default Till;
