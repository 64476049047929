import { StyleFn, DEFAULT_ENTITY_ID, STANDARD_MENU } from '@hitz-group/domain';
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View, Text } from 'react-native';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { useNotification } from '../../../../hooks/Notification';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import { MenuRow, Catalogue } from './MenuRow';
import { useMenus } from '../../../../hooks/app/menus/useMenus';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { Operation } from '../../../../types/Operation';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import find from 'lodash/find';
import { useModal } from '@hitz-group/rn-use-modal';
import AlertModal from '../../../../components/Modals/AlertModal';
import { usePages } from '../../../../hooks/app/pages/usePages';

import scale, { isWeb } from '../../../../common/theme';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const scrollStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: isWeb ? '60%' : '100%',
  alignSelf: 'center',
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const noMenuesTextStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.big / 2,
  color: theme.colors.primaryLightest,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
});

const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const deleteButtonStyle: StyleFn = ({ theme, selected }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  borderRadius: theme.radius.small,
  backgroundColor: selected ? theme.colors.danger2 : theme.colors.border,
  alignSelf: 'auto',
});

const deleteTitleStyle: StyleFn = ({ theme, selected }) => ({
  color: selected ? theme.colors.red : theme.colors.paragraph,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 38,
  height: 38,
  backgroundColor: theme.colors.greyLight,
  alignItems: 'center',
  justifyContent: 'center',
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  height: 17,
  width: 17,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  height: 17,
  width: 17,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});
export const Menu: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const { showModal, closeModal } = useModal();
  const [menuData, setMenuData] = useState<Record<string, Catalogue>>({});
  const {
    error: menusErr,
    loading: menusLoading,
    getMenus,
    menus,
    operation,
    deleteMenus,
    copyMenu,
  } = useMenus();

  const { error: pageErr, loading: pagesLoading, getPages, pages } = usePages();

  const loading = pagesLoading || menusLoading;

  const error = pageErr || menusErr;

  const calculateProductsInMenu = useCallback(
    (menu: Catalogue) => {
      let productsCount = 0;
      menu.itemGroups.forEach(eachItemGroup => {
        const pageId = eachItemGroup?.page?.id;
        productsCount += pages[pageId].products?.length || 0;

        // if variant products exist add
        if (pages?.[pageId]?.variants?.length) {
          // adding variant products
          const variantsInPage = pages?.[pageId]?.variants;
          variantsInPage?.forEach(eachVar => {
            productsCount = productsCount + eachVar.products?.length;
          });
        }
      });
      return productsCount;
    },
    [pages],
  );

  const menusArray = useMemo(() => {
    return Object.values(menuData).map(eachMenu => ({
      ...eachMenu,
      productCount: calculateProductsInMenu(eachMenu),
    }));
  }, [menuData, calculateProductsInMenu]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    isFocused && getMenus();
    isFocused && getPages();
  }, [getMenus, isFocused, getPages]);

  useEffect(() => {
    if (!error && !loading && menus) {
      setMenuData(menus);
    }
  }, [error, loading, menus]);

  useEffect(() => {
    if (!error && !loading && operation === Operation.UPDATE) {
      showNotification({
        success: true,
        message: translate('menus.menusUpdatedSuccessfully'),
      });
    }
  }, [error, loading, operation, showNotification, translate]);

  useEffect(() => {
    if (!error && !loading && operation === Operation.DELETE) {
      showNotification({
        success: true,
        message: translate('menus.menusDeletedSuccessfully'),
      });
    }
  }, [error, loading, operation, showNotification, translate]);

  useEffect(() => {
    if (!error && !loading && operation === Operation.CREATE) {
      showNotification({
        success: true,
        message: translate('menus.menuCopiedSuccessfully'),
      });
    }
  }, [error, loading, operation, showNotification, translate]);

  const onCreateMenu = useCallback((): void => {
    navigation.navigate('MenuSettings', {
      menuId: DEFAULT_ENTITY_ID,
      title: 'Create Menu',
    });
  }, [navigation]);

  const onDeleteMenus = useCallback(() => {
    const selectedMenus = menusArray.filter(
      eachMenu => eachMenu && eachMenu.isSelected,
    );
    const standardMenu = selectedMenus.filter(
      x => x && x.name === STANDARD_MENU,
    );
    if (standardMenu?.length) {
      showNotification({
        error: true,
        message: translate('menus.deleteStandardMenuPreventMessage'),
      });
    } else {
      const deleteIds = selectedMenus.map(x => x && x.id);
      deleteMenus(deleteIds);
    }
    closeModal();
  }, [menusArray, deleteMenus, translate, closeModal, showNotification]);

  const onPressDelete = useCallback((): void => {
    const selectedNames = menusArray
      .filter(eachMenu => eachMenu && eachMenu.isSelected)
      .map(x => x && x.name);
    const deleteModalTitle =
      selectedNames?.length === 1
        ? translate('menus.deleteMenu')
        : translate('menus.deleteMenus');

    const deleteMessage =
      selectedNames?.length === 1
        ? translate('menus.deleteMenuMessage', { name: selectedNames })
        : translate('menus.deleteMenusMessage', { names: selectedNames });

    showModal(
      <AlertModal
        title={deleteModalTitle}
        actionTitle={translate('dialog.deleteTitle')}
        message={deleteMessage}
        onConfirm={onDeleteMenus}
      />,
    );
  }, [showModal, menusArray, translate, onDeleteMenus]);

  const onCopyMenu = useCallback(
    (copyId: string) => {
      copyMenu(copyId);
      closeModal();
    },
    [closeModal, copyMenu],
  );

  const onCopy = useCallback(
    (copyId: string, name: string): void => {
      showModal(
        <AlertModal
          title={translate('menus.copyMenu')}
          actionTitle={translate('menus.copyTitle')}
          message={translate('menus.copyMenuesMessage', {
            name,
          })}
          onConfirm={() => onCopyMenu(copyId)}
        />,
      );
    },
    [showModal, translate, onCopyMenu],
  );

  const onChange = useCallback((id: string, prop: string, value) => {
    setMenuData(prev => ({ ...prev, [id]: { ...prev[id], [prop]: value } }));
  }, []);

  const titleCheckBoxStatus = useMemo(() => {
    if (find(menusArray, { isSelected: true })) {
      return true;
    }
    return false;
  }, [menusArray]);

  const selectToggle = useCallback(
    (isSelected): void => {
      const tempObj = {} as Record<
        string,
        Catalogue & { isSelected?: boolean }
      >;
      menusArray.forEach(x => {
        tempObj[x.id] = {
          ...x,
          isSelected: isSelected,
        };
      });
      setMenuData(tempObj);
    },
    [menusArray],
  );

  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <>
      <Helmet>
        <title>
          {translate('menus.menuSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollStyle)}>
          <BackOfficeSection
            title={translate('menus.menusHeading')}
            titleDescription={translate('menus.description')}
            action={<BackOfficeCreateNewButton onPress={onCreateMenu} />}
            contentContainerStyle={css(formStyle)}
            containerStyle={css(containerStyle)}
          >
            {(!menusArray.length && (
              <Text
                style={css(noMenuesTextStyle)}
                testID={'empty-menues-message'}
              >
                {translate('menus.noMenuesMessage')}
              </Text>
            )) || (
              <TableComponent
                columns={[
                  {
                    title: (
                      <Button
                        testID={'title-checkbox'}
                        fluid
                        iconPosition={'left'}
                        containerStyle={css(checkBoxTitleContainer)}
                        onPress={() => selectToggle(!titleCheckBoxStatus)}
                        iconContainerStyle={
                          titleCheckBoxStatus
                            ? css(checkIconContainer)
                            : css(unCheckContainer)
                        }
                        icon={titleCheckBoxStatus ? 'check' : 'null'}
                        iconProps={{
                          color: theme.colors.success,
                          size: 15,
                        }}
                      />
                    ),
                    width: 58,
                  },
                  {
                    title: translate('menus.menuName'),
                    width: 300,
                    alignItems: 'flex-start',
                    containerStyle: {
                      paddingLeft: theme.spacing.big / 2,
                    },
                  },
                  {
                    title: translate('menus.productCount'),
                    flex: 1,
                    alignItems: 'flex-start',
                    containerStyle: {
                      paddingLeft: theme.spacing.small,
                    },
                  },
                ]}
                data={menusArray}
                normalRows
                columnContainerStyle={css(columnContainerStyle)}
                renderRow={(item: Catalogue): React.ReactNode => (
                  <MenuRow
                    menu={item}
                    onChange={onChange}
                    onPressCopy={() => onCopy(item.id, item.name)}
                  />
                )}
              />
            )}
          </BackOfficeSection>
          <View style={css(bottomSpace)}></View>
        </ScrollView>
      </View>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="delete"
            title={translate('button.delete')}
            containerStyle={css(
              deleteButtonStyle({ theme, selected: titleCheckBoxStatus }),
            )}
            labelStyle={css(
              deleteTitleStyle({ theme, selected: titleCheckBoxStatus }),
            )}
            onPress={onPressDelete}
            disabled={!titleCheckBoxStatus}
          />
        </View>
      </View>
    </>
  );
};
