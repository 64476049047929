import { StyleFn } from '@hitz-group/domain';
import * as React from 'react';
import { useFela } from 'react-fela';
import { Animated, StyleProp, ViewStyle } from 'react-native';
import scale from '../../common/theme';
import PaginationButton from './PaginationButton';
import { MenuTile } from './menuTypes';

const catalogGridItemStyle: StyleFn = () => ({
  height: scale.moderateScale(52),
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: null,
});

export interface GridPaginationProps {
  style?: StyleProp<ViewStyle>;
  scrollDirection: 'vertical' | 'horizontal';
  currentPage: number;
  totalPages: number;
  onPressNext: () => void;
  onPressBack: () => void;
  onPressDone?: (type: MenuTile) => void;
  next?: boolean;
  back?: boolean;
  type?: MenuTile;
  totalItems?: number;
}
export const GridPagination: React.FC<GridPaginationProps> = ({
  style,
  scrollDirection,
  currentPage,
  totalPages,
  onPressBack,
  onPressNext,
  onPressDone,
  next,
  back,
  type,
  totalItems,
}) => {
  const { css } = useFela();
  return (
    <Animated.View style={[css(catalogGridItemStyle), style]}>
      <PaginationButton
        type="prev"
        testID={'back-button'}
        scrollDirection={scrollDirection}
        currentPage={currentPage}
        totalPages={totalPages}
        onPress={onPressBack}
        disabled={back ? !back : currentPage <= 1}
      />

      {(!next &&
        totalItems &&
        (type === 'Modifier' || type === 'VariantOption') &&
        onPressDone && (
          <PaginationButton
            type="done"
            testID={'next-button'}
            scrollDirection={scrollDirection}
            currentPage={currentPage}
            totalPages={totalPages}
            onPress={() => onPressDone(type)}
          />
        )) || (
        <PaginationButton
          type="next"
          testID={'next-button'}
          scrollDirection={scrollDirection}
          currentPage={currentPage}
          totalPages={totalPages}
          onPress={onPressNext}
          disabled={!next || currentPage >= totalPages}
        />
      )}
    </Animated.View>
  );
};

export default GridPagination;
