import React, { useCallback, useEffect, useState } from 'react';
import { useNotification } from '../../../../../hooks/Notification';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useDevices } from '../../../../../hooks/app/useDevices';
import DeviceDetails from './UIComponents/DeviceDetails';
import { Device, DeviceProfile, UpdateDeviceInput } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import pick from 'lodash/pick';
import { useDeviceProfiles } from '../../../../../hooks/app/useDeviceProfiles';

export const DeviceDetailsContainer: React.FC = () => {
  const route = useRoute();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<Device>({} as Device);
  const navigation = useNavigation();

  const params = route.params as {
    storeId: string;
    deviceId: string;
  };

  const storeId = params.storeId;
  const deviceId = params.deviceId;

  const {
    devices,
    updateDevice,
    deleteDevice,
    resetDeviceCode,
    resetedDeviceCode,
    deletedDevice,
    updatedDeviceId,
    loading: devicesLoading,
    error: devicesError,
  } = useDevices({
    deviceId,
    storeId,
  });
  const {
    deviceProfiles,
    getAllDeviceProfiles,
    loading: registersLoading,
    error: registersError,
  } = useDeviceProfiles({
    storeId,
  });

  const loading = devicesLoading || registersLoading;
  const error = devicesError || registersError;

  useEffect(() => {
    if (deviceId && devices[deviceId]) {
      setForm(devices[deviceId]);
    }
  }, [devices, deviceId]);

  useEffect(() => {
    getAllDeviceProfiles();
  }, [getAllDeviceProfiles]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  // on change events
  const onChange = useCallback((prop: string, value: string): void => {
    if (prop === 'register') {
      setForm(form => ({
        ...form,
        deviceProfile: { id: value } as DeviceProfile,
      }));
      return;
    }
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onSave = useCallback((): void => {
    const input = pick(form, [
      'id',
      'name',
      'salesPrefix',
      'returnPrefix',
    ]) as UpdateDeviceInput;

    if (Object.values(input).some(field => !field)) {
      showNotification({
        error: true,
        message: translate('backOfficeDevices.fieldsMissing'),
      });
      return;
    }
    input.deviceProfile = form.deviceProfile?.id;
    input.cashDrawer = 'default';
    // input.paymentTerminal = 'default';

    updateDevice(input as UpdateDeviceInput);
  }, [showNotification, translate, updateDevice, form]);

  useEffect(() => {
    if (updatedDeviceId) {
      showNotification({
        success: true,
        message: translate('backOfficeDevices.deviceUpdatedSuccessfully'),
      });
    }
  }, [updatedDeviceId, showNotification, translate]);

  useEffect(() => {
    if (deletedDevice) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: form?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: form?.store?.id,
        screen: 'Devices',
      });
    }
  }, [deletedDevice, navigation, showNotification, form, translate]);

  useEffect(() => {
    if (resetedDeviceCode) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deviceCodeResetSuccessful', {
          name: form?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: form?.store?.id,
        screen: 'Devices',
      });
    }
  }, [resetedDeviceCode, navigation, showNotification, form, translate]);
  return (
    <DeviceDetails
      device={form}
      deviceProfiles={Object.values(deviceProfiles)}
      options={{ loading }}
      onChange={onChange}
      deleteDevice={() => deleteDevice(form.id)}
      onSave={onSave}
      resetDevice={() => resetDeviceCode(form.id)}
    />
  );
};
