import React, { useCallback, useState, Key, useMemo } from 'react';
import { StyleFn, Product } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import {
  ScrollView,
  View,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { useTranslation } from '@hitz-group/localization';
import SettingItem from '../../../components/SettingsItem/SettingsItem';
import { useModal } from '@hitz-group/rn-use-modal';
import { isIos } from '../../../common/theme';

export interface SearchProductProps {
  onSelectProduct?: (item: Product) => void;
  searchListLength?: number;
  allProducts: Record<string, Product>;
}

const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 58,
  backgroundColor: theme.colors.white,
  paddingLeft: theme.padding.small,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  borderRadius: theme.radius.small,
  marginBottom: 5,
  alignSelf: 'center',
  zIndex: 100,
  ...theme.shadow30,
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  width: '90%',
  height: 58,
  color: theme.colors.primary,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.medium,
  lineHeight: 24,
  letterSpacing: -0.5,
});

const searchBarStyle: StyleFn = () => ({
  flex: 1,
  width: '50%',
  marginTop: 70,
  alignSelf: 'center',
});

const scrollViewStyle: StyleFn = () => ({
  flex: 1,
});

const fontStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.regular,
  lineHeight: 24,
  letterSpacing: -0.5,
});
const touchable: StyleFn = () => ({
  flex: 1,
});

const footerSpace: StyleFn = () => ({
  height: 20,
});

const SearchProduct: React.FC<SearchProductProps> = ({
  onSelectProduct,
  searchListLength,
  allProducts,
}: SearchProductProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const [searchString, setSearchString] = useState('');
  const { closeModal } = useModal();

  const searchResults = useMemo(() => {
    if (searchString !== '') {
      return Object.values(allProducts)
        .filter((product: Product) =>
          product.name.toLowerCase().includes(searchString.toLowerCase()),
        )
        .slice(0, searchListLength);
    }
    return [];
  }, [searchString, allProducts, searchListLength]);

  const onPress = useCallback(
    (selectedData: Product): void => {
      onSelectProduct && onSelectProduct(selectedData);
      closeModal();
    },
    [closeModal, onSelectProduct],
  );

  /* This is causing the modal to close in ios immediately
  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', closeModal);
    return () => {
      Keyboard.removeListener('keyboardDidHide', closeModal);
    };
  }, [closeModal]);
  */
  return (
    <KeyboardAvoidingView
      behavior={isIos ? 'padding' : 'height'}
      style={css(touchable)}
    >
      <TouchableOpacity
        testID="touch-wrapper"
        style={css(touchable)}
        onPress={() => closeModal()}
        activeOpacity={1}
      >
        <View style={css(searchBarStyle)}>
          <TouchableOpacity activeOpacity={1.0}>
            <SearchBar
              testID="search-bar"
              placeholder={translate(
                'order.searchProduct.searchProductPlaceholder',
              )}
              containerStyle={css(searchContainerStyle)}
              textInputStyle={css(searchTextInputStyle)}
              iconColor={theme.colors.primary}
              placeholderColor={theme.colors.paragraph}
              onChange={setSearchString}
              value={searchString}
              showCancel={true}
              onCancel={setSearchString.bind(null, '')}
              autoFocus={true}
            />
          </TouchableOpacity>
          <ScrollView
            style={css(scrollViewStyle)}
            showsHorizontalScrollIndicator={false}
          >
            {searchResults.map((searchList: Product, key: Key) => (
              <SettingItem
                testID="product-Card"
                key={key}
                title={searchList.name}
                titleTextStyle={css(fontStyle)}
                onPress={onPress.bind(null, searchList)}
                icon={'AngleRight'}
                iconStyle={css(fontStyle)}
              />
            ))}
            <View style={css(footerSpace)} />
          </ScrollView>
        </View>
      </TouchableOpacity>
    </KeyboardAvoidingView>
  );
};

SearchProduct.defaultProps = {
  searchListLength: 50,
};
export default SearchProduct;
