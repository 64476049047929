import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import { useFela, FelaComponent } from 'react-fela';
import IconButton from '../Button/IconButton';
import Title from '../Title/Title';
import {
  calculateStartDayOfMonth,
  isLeapYear,
  DAYS,
  DAY_NAMES,
  DAYS_LEAP,
  MONTHS,
} from '../../utils/dateHelper';
export interface CalendarProps {
  startDate?: Date;
  endDate?: Date;
  onPress?: (quantity: number) => void;
  month: number;
  year: number;
  onSelectDate?: (date: Date) => void;
  disableNextMonth?: boolean;
  disablePrevMonth?: boolean;
}

export interface DateProps {
  isSelected?: boolean;
  isInDateRange?: boolean;
  onPress?: () => void;
  disabled?: boolean;
  isStartDate?: boolean;
  isEndDate?: boolean;
  testID?: string;
}

const containerStyle: StyleFn = ({ theme }) => ({
  padding: theme.padding.medium,
});

const monthRow: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 255,
  flexWrap: 'wrap',
});

const row: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
  width: 255,
  flexWrap: 'wrap',
});

const paginationContainer: StyleFn = () => ({
  flexDirection: 'row',
});

const iconColor: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
});

const textStyle: StyleFn = ({ theme, isSelected, isInDateRange }) => ({
  color: isSelected
    ? theme.colors.white
    : isInDateRange
    ? theme.colors.blue
    : theme.colors.primary,
  textTransform: 'uppercase',
  fontFamily: theme.font.bold,
});

const DayText: React.FC<DateProps> = (props: DateProps) => (
  <FelaComponent style={textStyle} {...props}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text style={style} {...props} />
    )}
  </FelaComponent>
);

const dateStyle: StyleFn = ({
  theme,
  isSelected,
  isInDateRange,
  isStartDate,
  isEndDate,
}) => ({
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: isSelected
    ? theme.colors.blue
    : isInDateRange
    ? theme.colors.highlighted
    : theme.colors.white,
  borderBottomLeftRadius: isStartDate ? 5 : 0,
  borderTopLeftRadius: isStartDate ? 5 : 0,
  borderBottomRightRadius: isEndDate ? 5 : 0,
  borderTopRightRadius: isEndDate ? 5 : 0,
  height: 34,
  width: 34,
  margin: 1,
});

const Day: React.FC<DateProps> = ({ onPress, ...props }: DateProps) => {
  return (
    <FelaComponent style={dateStyle} {...props}>
      {({ style }: RenderProps): React.ReactFragment => (
        <TouchableOpacity style={style} onPress={onPress} {...props}>
          <DayText {...props} />
        </TouchableOpacity>
      )}
    </FelaComponent>
  );
};

const Calendar: React.FC<CalendarProps> = ({
  startDate,
  endDate,
  onPress,
  month,
  onSelectDate,
  year,
  disableNextMonth,
  disablePrevMonth,
}: CalendarProps) => {
  const { css } = useFela();
  const [date, setDate] = useState(startDate || endDate);
  const [currMonth, setCurrMonth] = useState(date ? date.getMonth() : month);
  const [currYear, setCurrYear] = useState(date ? date.getFullYear() : year);
  const [startDay, setStartDay] = useState(
    calculateStartDayOfMonth(date ? date : new Date()),
  );
  useEffect(() => {
    setCurrMonth(month);
    setCurrYear(year);
    if (!date) {
      setDate(new Date(year, month));
    }
  }, [year, month, date]);

  useEffect(() => {
    setCurrMonth(month);
    setCurrYear(year);
    setStartDay(calculateStartDayOfMonth(new Date(year, month)));
  }, [month, year]);

  useEffect(() => {
    if (date) {
      setCurrMonth(date.getMonth());
      setCurrYear(date.getFullYear());
      setStartDay(calculateStartDayOfMonth(date));
    }
  }, [date]);

  const onClickDate = useCallback(
    (date: Date) => {
      setDate(date);
      onSelectDate && onSelectDate(date);
    },
    [onSelectDate],
  );

  const isDateSelected = useCallback(
    (d: number): boolean => {
      if (!startDate && !endDate) {
        const today = new Date();

        return (
          d === today.getDate() &&
          currMonth === today.getMonth() &&
          currYear === today.getFullYear()
        );
      }
      return (
        (d === startDate?.getDate() || d === endDate?.getDate()) &&
        (currMonth === startDate?.getMonth() ||
          currMonth === endDate?.getMonth()) &&
        (currYear === startDate?.getFullYear() ||
          currYear === endDate?.getUTCFullYear())
      );
    },
    [startDate, endDate, currYear, currMonth],
  );

  const isInDateRange = useCallback(
    (d: number): boolean => {
      const date = new Date(currYear, currMonth, d);
      if (startDate && endDate) return date > startDate && date < endDate;
      if (startDate) return date > startDate;
      if (endDate) return date < endDate;
      return false;
    },
    [startDate, endDate, currMonth, currYear],
  );
  const days = isLeapYear(currYear) ? DAYS_LEAP : DAYS;
  return (
    <View style={css(containerStyle)}>
      <View style={css(monthRow)}>
        <Title>{MONTHS[currMonth] + ' ' + currYear}</Title>
        <View style={css(paginationContainer)}>
          <IconButton
            iconSize={20}
            icon={'AngleLeft'}
            iconStyle={css(iconColor)}
            containerSize={44}
            onPress={(): void => onPress && onPress(-1)}
            disabled={disablePrevMonth}
          />
          <IconButton
            iconSize={20}
            icon={'AngleRight'}
            iconStyle={css(iconColor)}
            containerSize={44}
            onPress={(): void => onPress && onPress(1)}
            disabled={disableNextMonth}
          />
        </View>
      </View>
      <View style={css(row)}>
        {DAY_NAMES.map((day, index) => (
          <Day key={index} disabled>
            {day}
          </Day>
        ))}
      </View>
      <View style={css(row)}>
        {Array(days[currMonth] + startDay)
          .fill(null)
          .map((_, index) => {
            const d = index - (startDay - 1);
            return d > 0 ? (
              <Day
                testID={`day ${d}`}
                key={index}
                isSelected={isDateSelected(d)}
                isInDateRange={isInDateRange(d)}
                onPress={(): void =>
                  onClickDate(new Date(currYear, currMonth, d))
                }
                isStartDate={d === startDate?.getDate()}
                isEndDate={d === endDate?.getDate()}
              >
                {d}
              </Day>
            ) : (
              <View key={index} style={css(dateStyle)} />
            );
          })}
      </View>
    </View>
  );
};

export default Calendar;
