import { App, Resource, StyleFn, Icons } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useNetInfo } from '@react-native-community/netinfo';
import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { DrawerActions, useLinkProps } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFela, withTheme } from 'react-fela';
import { Text, View, TouchableOpacity, Platform } from 'react-native';
import usePOSUserAuthorization from '../../hooks/app/users/usePOSUserAuthorization';
import { useSession } from '../../hooks/app/useSession';
import { useNotification } from '../../hooks/Notification';
import { userUtility } from '../../state/userUtility';
import IconButton from '../Button/IconButton';
import { DrawerItem } from '../DrawerItem/DrawerItem';
import { useModal } from '@hitz-group/rn-use-modal';
import ReportIssueModal from '../Modals/ReportIssueModal';
import { useIntercom } from '../../hooks/Intercom/useIntercom';

const containerStyle: StyleFn = ({ theme }) => ({
  width: 275,
  paddingLeft: theme.padding.medium,
  paddingRight: theme.padding.medium,
  paddingTop: theme.padding.large,
  paddingBottom: 0,
  backgroundColor: theme.colors.primary,
  height: '100%',
});

const closeIconContainer: StyleFn = () => ({
  alignSelf: 'flex-end',
});
const itemContainer: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: 'transparent',
  marginLeft: 0,
  marginRight: 0,
  marginTop: theme.padding.small,
  marginBottom: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingRight: theme.padding.medium,
});

const drawerIcon: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  width: 44,
  height: 44,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.black,
});
const itemTextStyle: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.small,
  color: theme.colors.white,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
});
const itemsContainerStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.small,
});

const drawerCloseButtonStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  width: 44,
  height: 44,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.black,
  marginRight: theme.spacing.small,
  alignSelf: 'flex-end',
});

const VenueContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.black,
  width: '95%',
  padding: theme.padding.medium,
  borderRadius: theme.radius.small,
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
  position: 'absolute',
  bottom: 10,
});

const itemStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: 'transparent',
  marginLeft: 0,
  marginRight: 0,
  marginTop: theme.padding.small,
  marginBottom: 0,
  alignItems: 'center',
  justifyContent: 'center',
});

const itemText1Style: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.small,
  color: theme.colors.white,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.smaller,
  textAlign: 'center',
});

const itemText2Style: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.small,
  color: '#808080',
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.smaller,
  textAlign: 'center',
});

const Drawer: React.FC<DrawerContentComponentProps> = ({
  navigation,
  descriptors,
  state,
}: DrawerContentComponentProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const [session] = useSession();
  const netInfo = useNetInfo();
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  const { canActiveUserAccessBackOffice, activeUser, canI } =
    usePOSUserAuthorization();

  const closeDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.closeDrawer());
  }, [navigation]);
  const onPressDrawerClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const onPressSwitchUser = useCallback(
    routeName => {
      // Reset pos user
      userUtility.clearPosUser();

      navigation.navigate(routeName, {
        app: App.POS_APP,
      });
    },
    [navigation],
  );

  const onPressBackOffice = useCallback(async () => {
    if (netInfo.isConnected) {
      const userActivity = userUtility.userActivity;
      const recentOfficeUser = userUtility.recentOfficeUser;
      if (canActiveUserAccessBackOffice) {
        if (
          userActivity.recentUserId === session.user?.id &&
          recentOfficeUser?.id === userActivity.recentUserId
        ) {
          if (recentOfficeUser.active) {
            navigation.reset({ index: 0, routes: [{ name: 'BackOffice' }] });
          } else {
            userUtility.clearPosUser();
            // ask the person to login to office
            navigation.navigate('Lock', {
              app: App.BACKOFFICE,
            });
          }
        } else {
          userUtility.clearPosUser();
          // ask the person to login
          navigation.navigate('Lock', {
            app: App.BACKOFFICE,
          });
        }
      } else {
        showNotification({
          message: translate('appAccess.noOfficeAccess'),
          info: true,
        });
        closeDrawer();
      }
    } else {
      showNotification({
        message: translate('order.offlineWarning'),
        info: true,
      });
      closeDrawer();
    }
  }, [
    navigation,
    translate,
    showNotification,
    canActiveUserAccessBackOffice,
    session.user?.id,
    closeDrawer,
    netInfo.isConnected,
  ]);

  const onPressShifts = useCallback(
    routeName => {
      const hasAccess = canI([{ onResource: Resource.VIEW_SHIFT_SUMMARY }], {
        prompt: true,
      });
      closeDrawer();
      if (hasAccess) {
        navigation.navigate(routeName);
      }
    },
    [canI, navigation, closeDrawer],
  );

  const { onPress: linkToTakeOrder } = useLinkProps({ to: '/pos/order' });
  const { onPress: linkToFloorView } = useLinkProps({
    to: '/pos/orders/floor-view/',
  });
  const onNavigateWithUrl = useCallback(
    (linkTo: Function, routeName: string, screenName: string) => {
      if (Platform.OS === 'web') {
        linkTo();
      } else {
        navigation.navigate(routeName, {
          screen: screenName,
        });
      }
    },
    [navigation],
  );

  const onPressReportIssue = useCallback(() => {
    closeDrawer();
    showModal(<ReportIssueModal />);
  }, [closeDrawer, showModal]);

  const Intercom = useIntercom();

  const onPressSupport = useCallback(() => {
    const IntercomUser = {
      email: session?.user?.email || '',
      userId: session?.user?.id || '',
      name: session?.user?.name || '',
      companies: [
        {
          companyId: session?.currentOrganization?.id || '',
          name: session?.currentOrganization?.name || '',
          customAttributes: {
            venueName: session?.currentVenue?.name || '',
            storeName: session?.currentStore?.name || '',
          },
        },
      ],
    };
    Intercom.start(IntercomUser, Intercom.show);
    closeDrawer();
  }, [Intercom, closeDrawer, session]);

  useEffect(() => {
    /**
     * When trying to access POS app url without PIN verification, redirects to Lock
     */
    if (userUtility.posUser === undefined) {
      onPressSwitchUser('Lock');
    }
  }, [onPressSwitchUser]);

  const onPressDrawerItem = useCallback(
    (routeName, screenName) => {
      const actionHandlers: Record<string, Function> = {
        Lock: onPressSwitchUser,
        OfficeSettings: onPressBackOffice,
        Shifts: onPressShifts,
        FloorViewStack: onNavigateWithUrl.bind(
          null,
          linkToFloorView,
          'Orders',
          'FloorView',
        ),
        Orders: onNavigateWithUrl.bind(
          null,
          linkToTakeOrder,
          'Orders',
          'TakeOrder',
        ),
      };
      if (actionHandlers[routeName]) {
        actionHandlers[routeName].call(null, routeName, screenName);
      } else if (routeName) {
        navigation.navigate(routeName, {
          screen: screenName,
        });
      }
    },
    [
      onPressSwitchUser,
      onPressBackOffice,
      navigation,
      onPressShifts,
      onNavigateWithUrl,
      linkToTakeOrder,
      linkToFloorView,
    ],
  );

  const drawerItems = useMemo(() => {
    return Object.values(descriptors).map((route, i) => {
      const {
        options: { drawerIcon, drawerLabel, title },
      } = route;
      return (
        <DrawerItem
          icon={drawerIcon}
          title={drawerLabel as string}
          screenName={title}
          key={i}
          onPress={onPressDrawerItem}
          routeName={state.routeNames[i]}
        />
      );
    });
  }, [descriptors, onPressDrawerItem, state.routeNames]);

  return (
    <View testID="drawer" style={css(containerStyle)}>
      <View style={css(closeIconContainer)}>
        <IconButton
          primary
          circular
          icon={Icons.Times}
          iconSize={24}
          iconColor={theme.colors.white}
          containerSize={44}
          onPress={onPressDrawerClose}
          containerStyle={css(drawerCloseButtonStyle)}
        />

        <View style={css(itemsContainerStyle)}>{drawerItems}</View>

        <TouchableOpacity
          testID="support-button"
          onPress={onPressSupport}
          style={css(itemContainer)}
        >
          <Text style={css(itemTextStyle)}>{translate('drawer.support')}</Text>
          <IconButton
            primary
            circular
            icon={Icons.Chat}
            iconSize={18}
            iconColor={theme.colors.white}
            containerSize={44}
            onPress={onPressSupport}
            containerStyle={css(drawerIcon)}
          />
        </TouchableOpacity>

        <TouchableOpacity
          testID="report-issue-button"
          onPress={onPressReportIssue}
          style={css(itemContainer)}
        >
          <Text style={css(itemTextStyle)}>
            {translate('reportIssue.titleText')}
          </Text>
          <IconButton
            primary
            circular
            icon={Icons.Warning}
            iconSize={18}
            iconColor={theme.colors.white}
            containerSize={44}
            onPress={onPressReportIssue}
            containerStyle={css(drawerIcon)}
          />
        </TouchableOpacity>
      </View>

      {session && (
        <View style={css(VenueContainerStyle)} testID="posDetails">
          <View style={css(itemStyle)}>
            <Text style={css(itemText1Style)} numberOfLines={1}>
              {session.currentVenue?.name}
            </Text>
          </View>
          <View style={css(itemStyle)}>
            <Text style={css(itemText2Style)} numberOfLines={1}>
              {session.currentStore?.name}
            </Text>
          </View>
          <View style={css(itemStyle)}>
            <Text style={css(itemText2Style)} numberOfLines={1}>
              {`${translate('assignRegister.device')}: ${session.device?.name}`}
            </Text>
          </View>
          <View style={css(itemStyle)}>
            <Text
              style={css(itemText2Style)}
              numberOfLines={1}
              testID="posUserName"
            >
              {activeUser?.name}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

export default withTheme(Drawer);
