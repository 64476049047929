import { StyleFn } from '@hitz-group/domain';
import scale from '../../common/theme';
import React from 'react';
import { useFela } from 'react-fela';
import { View, TextInput, Text, Platform } from 'react-native';
import { Label } from '../FormInput/FormInput';
import {
  Control,
  Controller,
  FieldError,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

const textInputStyles: StyleFn = ({ theme }) => ({
  flex: 1,
  height: theme.input.height,
  width: 540,

  paddingLeft: scale.moderateScale(7),
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  ...theme.font14RegularCharcoal,
  ...Platform.select({
    web: {
      outlineWidth: 0,
    },
  }),
});

const containerStyle: StyleFn = ({ fluid }) => ({
  justifyContent: 'space-around',
  width: fluid ? '100%' : 'auto',
});

const fieldContainerStyle: StyleFn = ({ theme, error }) => ({
  borderColor: error ? theme.colors.danger2 : theme.colors.boxBorder,
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  borderWidth: 0.8,
  height: theme.input.height,
  width: 540,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});
const textLabelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  textAlign: 'left',
});
const errorText: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.smallest,
  paddingLeft: theme.padding.small,
  color: theme.colors.danger,
  textAlign: 'left',
});

export interface ControlledFormInputProps<T extends FieldValues> {
  control: Control<T>;
  readOnly?: boolean;
  name: FieldPath<T>;
  title: string;
  placeholder?: string;
}

/**
 * Originally these are un-controlled
 * @param param0
 * @returns
 */
const ControlledFormInput = <T extends FieldValues>({
  control,
  readOnly = false,
  name,
  title,
  placeholder,
}: ControlledFormInputProps<T>) => {
  const { css, theme } = useFela();

  return (
    <Controller
      control={control}
      render={({
        field: { onChange, onBlur, value },
        formState: { isDirty, errors },
      }) => (
        <View style={css(containerStyle)}>
          <Label
            alignTitle="left"
            textStyle={[css(textLabelStyle)]}
            title={title}
          >
            <Text>{title}</Text>
          </Label>
          <View style={css(fieldContainerStyle)}>
            <TextInput
              style={css(textInputStyles)}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value as string}
              placeholderTextColor={theme.colors.paragraph}
              placeholder={placeholder || title}
              editable={!readOnly}
            />
          </View>
          {isDirty && (errors[name] as FieldError)?.message && (
            <Text style={css(errorText)} testID="errorMessage">
              {(errors[name] as FieldError)?.message}
            </Text>
          )}
        </View>
      )}
      name={name}
    />
  );
};

export default ControlledFormInput;
