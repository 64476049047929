import React, { useState, useCallback } from 'react';
import { View, TouchableOpacity, ViewStyle } from 'react-native';
import IconButton from '../Button/IconButton';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';

export interface AccordionProps {
  testID?: string;
  children?: React.ReactChildren | React.ReactNode;
  accordionTitle: React.ReactNode;
  onToggle?: (event?: boolean) => void;
  disableTitle?: boolean;
  titleStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  openIcon?: string;
  closeIcon?: string;
  childrenStyle?: ViewStyle;
  titleAction?: React.ReactNode;
  iconColor?: string;
}

const iconContainerStyle: StyleFn = ({ theme }) => ({
  width: 40,
  backgroundColor: theme.colors.white,
  height: 44,
  alignSelf: 'flex-end',
  marginBottom: 0,
  marginLeft: -theme.spacing.small,
  borderRadius: theme.radius.small,
});

const titleRowStyle: StyleFn = () => ({
  flex: 1,
});

const touchableStyle: StyleFn = () => ({
  flexDirection: 'row',
});

const Accordion: React.FC<AccordionProps> = ({
  testID,
  accordionTitle,
  children,
  onToggle,
  titleStyle,
  containerStyle,
  openIcon,
  closeIcon,
  childrenStyle,
  titleAction,
  iconColor,
}: AccordionProps) => {
  const { css } = useFela();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const onToggleAccordion = useCallback((): void => {
    setIsCollapsed(!isCollapsed);
    onToggle && onToggle(!isCollapsed);
  }, [isCollapsed, onToggle]);

  return (
    <View testID={testID} style={containerStyle}>
      <TouchableOpacity
        style={css(touchableStyle)}
        testID="accordion-parent"
        onPress={onToggleAccordion}
      >
        <View style={[css(titleRowStyle), titleStyle]}>
          {accordionTitle}
          {isCollapsed && titleAction}
        </View>
        {openIcon && closeIcon && (
          <IconButton
            icon={isCollapsed ? openIcon : closeIcon}
            primary
            iconColor={iconColor}
            containerStyle={css(iconContainerStyle)}
            onPress={onToggleAccordion}
            disabled
          />
        )}
      </TouchableOpacity>
      {isCollapsed && children ? (
        <View style={childrenStyle}>{children}</View>
      ) : (
        <></>
      )}
    </View>
  );
};

Accordion.defaultProps = {
  openIcon: 'AngleUp',
  closeIcon: 'AngleDown',
};
export default Accordion;
