import { useState } from 'react';
import { setItem, getItem } from '../../storage/interface';
import {
  Order,
  OrderAction,
  OrderEvent,
  OrderFilterInput,
} from '@hitz-group/domain';
import { mapOrderToGraphObject } from '../../graphql/orders';
import { GET_ORDER, GET_ORDERS } from '../../hooks/app/orders/graphql';
import { useLazyQuery, useApolloClient } from '@apollo/client/react/hooks';
import { computeOrderState } from '@hitz-group/order-helper';
import { parseApolloError } from '../../utils/errorHandlers';
import { PUSH_NOTIFICATION_MESSAGE_KEY } from '../../state/preferences';

export interface UseOrderNotifications {
  processNotification: () => void;
  saveNotification: (eventData: OrderEvent) => void;
  error: string | undefined;
}
type OrderEventMap = Record<string, OrderEvent[]>;

export const removeOrderFromOrderMapImplementation = async (
  orderId: string,
) => {
  let orderMap: OrderEventMap = {};
  orderMap =
    ((await getItem(PUSH_NOTIFICATION_MESSAGE_KEY)) as OrderEventMap) || {};
  if (Object.keys(orderMap).length === 0 && orderMap.constructor === Object) {
    await setItem(PUSH_NOTIFICATION_MESSAGE_KEY, {});
  }
  if (orderMap[orderId]) delete orderMap[orderId];
  await setItem(PUSH_NOTIFICATION_MESSAGE_KEY, orderMap);
};

export const checkForPreviousValidation = (orderEvents: OrderEvent[]) => {
  const eventsToBeValidated = orderEvents.filter(
    orderEvent => orderEvent.previous,
  );
  return eventsToBeValidated.every(orderEvent =>
    orderEvents.find(allOrderEvent => orderEvent.previous == allOrderEvent.id),
  );
};

//check if the sequence in local is eligible to be processed
export const canProcessNotifications = (
  orderEvents: OrderEvent[],
  existingOrder?: Order,
) => {
  if (!orderEvents || !orderEvents?.length) return false;
  //sequence is invalid for a single event in local ex. ORDER_SAVE
  else if (orderEvents.length == 1) return false;
  //sequence is invalid for a first event being ORDER_INITIATE and second ORDER_SAVE
  else if (
    orderEvents.length > 1 &&
    orderEvents[1].action == OrderAction.ORDER_SAVE &&
    orderEvents[0].action == OrderAction.ORDER_INITIATE
  ) {
    return false;
    //sequence is invalid if it does not contains ORDER_SAVE
  } else if (
    !orderEvents.some(
      orderEvent => orderEvent.action === OrderAction.ORDER_SAVE,
    )
  ) {
    return false;
    //sequence is invalid if it does contains ORDER_SAVE but not ORDER_INITIATE or is not present in existing orders
  } else if (
    orderEvents.some(
      orderEvent => orderEvent.action === OrderAction.ORDER_SAVE,
    ) &&
    !(
      existingOrder?.id === orderEvents[0].orderId ||
      orderEvents.some(
        orderEvent => orderEvent.action === OrderAction.ORDER_INITIATE,
      )
    )
  ) {
    return false;
    //sequence is invalid if it does contains previous Id accordingly to the process occurring
  } else if (!checkForPreviousValidation(orderEvents)) {
    return false;
  } else {
    return true;
  }
};

const persistNotificationEvents = async (
  eventData: OrderEvent,
): Promise<OrderEvent[]> => {
  if (!(eventData.action && eventData.orderId)) return [];
  let orderMap: OrderEventMap = {};
  orderMap =
    ((await getItem(PUSH_NOTIFICATION_MESSAGE_KEY)) as OrderEventMap) || {};
  if (Object.keys(orderMap).length === 0 && orderMap.constructor === Object) {
    await setItem(PUSH_NOTIFICATION_MESSAGE_KEY, {});
  }
  const orderEventArray: Array<OrderEvent> = orderMap[eventData.orderId] || [];
  orderEventArray.push(eventData);
  orderEventArray.sort((a, b) => a.timestamp - b.timestamp);
  orderMap[eventData.orderId] = orderEventArray;
  await setItem(PUSH_NOTIFICATION_MESSAGE_KEY, orderMap);
  return orderMap[eventData.orderId];
};

export const useOrderNotifications = (): UseOrderNotifications => {
  const [currentEvents, setCurrentEvents] = useState<OrderEvent[] | []>();
  const client = useApolloClient();
  let existingOrders: Order[] = [];

  const fetchExistingOrders = (input: OrderFilterInput) => {
    try {
      const { orders } = client.cache.readQuery({
        query: GET_ORDERS,
        variables: { filter: input },
      }) as {
        orders: Order[];
      };
      existingOrders = orders;
    } catch (e) {}
  };

  const fetchExistingOrder = (orderId: string) => {
    try {
      const { order } = client.cache.readQuery({
        query: GET_ORDER,
        variables: { orderId },
      }) as {
        order: Order;
      };

      return order;
    } catch (e) {}
  };

  const onCompleteGetOrder = ({ order }: { order: Order }) => {
    if (order && currentEvents?.length) {
      if (currentEvents[0].orderId == order.id) {
        const computedOrder = computeOrderState(currentEvents, order);
        if (computedOrder && computedOrder.id) saveO(computedOrder as Order);
        if (order && order.id)
          (async () => {
            await removeOrderFromOrderMapImplementation(order.id);
          })();
      }
    }
  };

  const [getOrder, getOrderQuery] = useLazyQuery(GET_ORDER, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onCompleteGetOrder,
  });

  const saveO = (input: Order) => {
    fetchExistingOrders({ status: input.status });
    const order = mapOrderToGraphObject(input);
    if (order) {
      client.cache.writeQuery({
        query: GET_ORDER,
        variables: { orderId: input.id },
        data: {
          order,
        },
      });
    }
    if (existingOrders?.length && order) {
      existingOrders = existingOrders?.filter(x => x.id !== order.id);
      client.cache.writeQuery({
        query: GET_ORDERS,
        variables: { filter: { status: input.status } },
        data: {
          orders: [...existingOrders, order],
        },
      });
    }
  };

  const processOrderEvents = async (orderEvent: OrderEvent[]) => {
    const [initalOrder, ...otherEvents] = orderEvent;
    const existingOrder = fetchExistingOrder(initalOrder.orderId);
    let order, computedOrder;
    if (initalOrder.action == OrderAction.ORDER_INITIATE) {
      order = computeOrderState([initalOrder]);
      computedOrder = computeOrderState(otherEvents, order);
    } else if (existingOrder) {
      order = existingOrder;
      computedOrder = computeOrderState(orderEvent, order);
    } else {
      setCurrentEvents(orderEvent);
      getOrder({ variables: { orderId: initalOrder.orderId } });
    }
    if (computedOrder && computedOrder.id) {
      saveO(computedOrder as Order);
      if (order && order.id)
        await removeOrderFromOrderMapImplementation(order.id);
    }
  };

  const processOrderMap = async (orderId?: string) => {
    const data = (await getItem(
      PUSH_NOTIFICATION_MESSAGE_KEY,
    )) as OrderEventMap;
    if (data) {
      if (orderId && data.hasOwnProperty(orderId)) {
        await processOrderEvents(data[orderId]);
      }
    }
  };

  const saveNotification = async (eventData: OrderEvent) => {
    const existingOrder = fetchExistingOrder(eventData.orderId);
    const orderEvents = await persistNotificationEvents(eventData);
    if (
      existingOrder &&
      !(
        eventData.action == OrderAction.ORDER_INITIATE ||
        eventData.action == OrderAction.ORDER_SAVE
      )
    ) {
      await processOrderMap(eventData.orderId);
      return;
    }
    if (canProcessNotifications(orderEvents, existingOrder)) {
      await processOrderMap(eventData.orderId);
    }
  };

  const processNotifications = async () => {
    const orderEventMap =
      ((await getItem(PUSH_NOTIFICATION_MESSAGE_KEY)) as OrderEventMap) || {};
    if (orderEventMap) {
      Object.keys(orderEventMap).forEach(async key => {
        if (orderEventMap[key]) {
          const existingOrder = fetchExistingOrder(key);
          if (canProcessNotifications(orderEventMap[key], existingOrder)) {
            await processOrderMap(key);
          }
        }
      });
    }
  };

  return {
    processNotification: processNotifications,
    saveNotification: saveNotification,
    error: getOrderQuery.error
      ? parseApolloError(getOrderQuery.error)
      : undefined,
  };
};
