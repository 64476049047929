import { Feature, FeatureContext, Features } from '@hitz-group/domain';
import { useCallback } from 'react';
import { useSession } from '../useSession';

export const useCheckFeatureEnabled = () => {
  const [session] = useSession();

  const isFeatureEnabledInAnyVenue = useCallback(
    (featureName: Features) => {
      const venue = session.user?.venues?.filter(venue => {
        const feature = venue?.features?.find(
          feature => feature.name === featureName,
        );
        if (feature && feature.enabledByDefault) {
          return venue;
        }
      });

      return !!(venue && venue.length);
    },
    [session.user?.venues],
  );

  const isFeatureEnabled = useCallback(
    (
      featureName: Features | undefined,
      context?: string,
      venueId?: string,
    ): boolean => {
      let features: Feature[] | undefined;

      if (!featureName) {
        return true;
      }

      if (!context) {
        return isFeatureEnabledInAnyVenue(featureName);
      }

      if (context === FeatureContext.ORGANIZATION) {
        features = session.currentOrganization?.features;
      } else {
        if (venueId) {
          const venue = session.user?.venues?.find(
            venue => venue.id === venueId,
          );
          features = venue?.features;
        } else {
          if (session.currentVenue) {
            features = session.currentVenue.features;
          } else {
            features = session.user?.venues && session.user?.venues[0].features;
          }
        }
      }

      if (features) {
        return (
          features.find(feature => feature.name === featureName)
            ?.enabledByDefault || false
        );
      }
      return false;
    },
    [
      isFeatureEnabledInAnyVenue,
      session.currentOrganization?.features,
      session.currentVenue,
      session.user?.venues,
    ],
  );
  return isFeatureEnabled;
};
