import React, { useCallback, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import DataGrid, {
  alignment,
  columnForDataGrid,
  columnStyleOptions,
} from '../DataGrid/DataGrid';
import { useFela } from 'react-fela';
import IconButton from '../Button/IconButton';
import { Product, Variant } from '@hitz-group/domain';
import * as styles from './Table.style';
import FormInput from '../FormInput/FormInput';
// import ItemInfo from '../Modals/ItemAvailability/ItemInfo'; //TODO Do not delete! Will need it for the next sprint/epic
// import { useModal } from '@hitz-group/rn-use-modal'; //TODO Do not delete! Will need it for the next sprint/epic

interface TableProps {
  data: Product[] | Variant[];
  moveToStart?: boolean;
  onUpdateIsBeingTracked: (product: Product, updatedValue: boolean) => void;
  onUpdateQuantity: (product: Product, updatedValue: string) => void;
}

const Table: React.FC<TableProps> = ({
  data,
  moveToStart,
  onUpdateIsBeingTracked,
  onUpdateQuantity,
}) => {
  const { translate } = useTranslation();
  const { css, theme } = useFela();
  // const { showModal } = useModal(); //TODO Do not delete! Will need it for the next sprint/epic

  const [showCheck, setShowCheck] = useState<boolean>(true);

  const COLUMNS: columnForDataGrid[] = useMemo(
    () => [
      {
        columnName: translate('functionMaps.itemAvailability.allItems'),
        columnDataKey: 'allItems',
        columnStyle: columnStyleOptions.SMALL,
      },
      //TODO Do not delete! Will need it for the next sprint/epic
      // {
      //   columnName: 'Available',
      //   columnDataKey: 'available',
      //   columnStyle: columnStyleOptions.OPTION,
      //   cellAlignmentStyle: alignment.CENTER,
      // },
      {
        columnName: 'Track Item',
        columnDataKey: 'tracking',
        columnStyle: columnStyleOptions.OPTION,
        cellAlignmentStyle: alignment.CENTER,
      },
      {
        columnName: 'Quantity',
        columnDataKey: 'quantity',
        columnStyle: columnStyleOptions.OPTION,
        cellAlignmentStyle: alignment.CENTER,
      },
      //TODO Do not delete! Will need it for the next sprint/epic
      // {
      //   columnName: '',
      //   columnDataKey: 'itemInfo',
      //   columnStyle: columnStyleOptions.CLICK,
      // },
    ],
    [translate],
  );

  //TODO Do not delete! Will need it for the next sprint/epic
  // const onPressTableRow = useCallback(
  //   (product: Product) => {
  //     showModal(<ItemInfo product={product} />);
  //   },
  //   [showModal],
  // );

  const onChangeQuantity = useCallback(
    (product: Product, quantity: string) => {
      onUpdateQuantity(product, quantity);
    },
    [onUpdateQuantity],
  );

  const renderOptionItems = useCallback(
    (product: Product, columnDataKey: string) => {
      const { isBeingTracked, inventory } = product;
      if (columnDataKey === 'available') {
        return (
          <IconButton
            primary
            icon={showCheck ? 'check' : ''}
            iconSize={15}
            containerSize={30}
            containerStyle={
              showCheck
                ? css(styles.checkIconContainer)
                : css(styles.unCheckContainer)
            }
            iconColor={showCheck && theme.colors.success}
            onPress={() => {
              setShowCheck(!showCheck);
            }}
          />
        );
      } else if (columnDataKey === 'quantity') {
        return (
          <FormInput
            containerStyle={css(styles.textInput)}
            textInputStyle={css(styles.textInputText)}
            showLabel={false}
            readOnly={!isBeingTracked}
            placeholder="-"
            textAlign={'center'}
            value={
              inventory && inventory.availableQuantity != null
                ? inventory.availableQuantity.toString()
                : undefined
            }
            onChangeText={(text: string) => onChangeQuantity(product, text)}
            keyboardType={'numeric'}
          />
        );
      } else if (columnDataKey === 'itemInfo') {
        return (
          <IconButton
            testID="preview-table-row"
            icon={'angle-right'}
            iconSize={25}
            iconColor={theme.colors.darkGrey}
            // onPress={() => onPressTableRow(product)} //TODO Do not delete! Will need it for the next sprint/epic
          />
        );
      } else {
        return (
          <IconButton
            primary
            icon={isBeingTracked ? 'toggle-on' : 'toggle-off'}
            iconSize={25}
            onPress={() =>
              onUpdateIsBeingTracked(
                product,
                isBeingTracked === null ? true : !isBeingTracked,
              )
            }
            containerStyle={css(styles.toggleContainerStyle)}
            iconColor={
              isBeingTracked ? theme.colors.success : theme.colors.boxBorder
            }
          />
        );
      }
    },
    [
      showCheck,
      css,
      theme.colors.success,
      theme.colors.darkGrey,
      theme.colors.boxBorder,
      onChangeQuantity,
      // onPressTableRow, //TODO Do not delete! Will need it for the next sprint/epic
      onUpdateIsBeingTracked,
    ],
  );

  const renderDataItem = useCallback(
    (item: Product): React.ReactNode => {
      return (
        <View style={css(styles.tableRowCells)}>
          <Text style={css(styles.tableRowCellsText)}>{item.name}</Text>
        </View>
      );
    },
    [css],
  );
  return (
    <DataGrid
      data={data}
      columns={COLUMNS}
      headerStyle={css(styles.columnContainerStyle)}
      headerCellTextAlignment={css(styles.headerCellTextAlignment)}
      rowCellTextAlignment={css(styles.rowCellTextAlignment)}
      dataRowStyle={css(styles.dataRowStyle)}
      renderOptionItems={renderOptionItems}
      pageSize={8}
      setToStartPageIfDataChanges={moveToStart}
      renderHeaderItem={(item: string): React.ReactNode => {
        return (
          <View>
            <Text style={css(styles.tableHeaderTextStyle)}>{item}</Text>
          </View>
        );
      }}
      renderDataItem={renderDataItem}
    />
  );
};

export default Table;
