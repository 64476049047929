import { gql } from '@apollo/client';

const productGraphQuery = `
  id
  name
  maxSellingPrice
  minSellingPrice
  variablePricing
  isBeingTracked
  isAvailable
  inventory {
    availableQuantity
  }
  imageUrl
  modifierGroups{
    id
    name
    isGrouped
    isRequired,
    priority
    modifiers {
      id
      isDefault
      price {
        amount
        currency
      }
      priority
    }
  }
  productType {
    id
    name
  }
  printerProfiles{
    id
    name
    printerProfileType
  }
  barcode
  sku
  plu
  gtin
  isSellable
  optionValues {
    parentOptionId
    key
    value
  }
  measuredBy {
    unitType
    defaultSize
    units
  }
  description
  dietaryTags {
    id
    name
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  pages {
    id
  }
  salesChannels {
    id
  }
  stores {
    id
  }
  pricingGroups {
    id
    name
    prices {
      id
      taxInclusive
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
      costTax {
        id
      }
      costPrice {
        amount
        currency
      }
    }
  }
`;

export const GET_PRODUCT_QUERY = gql`
  query productPricesInfo($id: String!) {
    product(id: $id) {
      ${productGraphQuery}
    }
  }
`;

export const GET_PRODUCTS_QUERY = gql`
  query products {
    products {
      ${productGraphQuery}
    }
  }
`;

export const GET_PRODUCTS_QUERY_WITH_FILTER = gql`
  query products($input: String) {
    products(filter:{name: $input}){
      ${productGraphQuery}
    }
  }
`;

export const UPDATE_PRODUCT_DETAILS_MUTATION = gql`
  mutation updateProduct($input: UpdateProductInput) {
    updateProduct(input: $input) {
      ${productGraphQuery}
    }
  }
`;

export const UPDATE_PRODUCTS_DETAILS_MUTATION = gql`
  mutation updateProducts($input: [UpdateProductInput]) {
    updateProducts(input: $input) {
      ${productGraphQuery}
    }
  }
`;

export const DELETE_PRODUCT_MUTATION = gql`
  mutation deleteProduct($input: String!) {
    deleteProduct(id: $input)
  }
`;

export const DELETE_PRODUCTS_MUTATION = gql`
  mutation deleteProducts($input: [ID!]!) {
    deleteProducts(ids: $input)
  }
`;

export const CREATE_PRODUCT_MUTATION = gql`
  mutation createProduct($input: CreateProductInput) {
    createProduct(input: $input) {
      ${productGraphQuery}
    }
  }
`;

export const COPY_PRODUCT_MUTATION = gql`
  mutation copyProduct($input: CopyProductInput) {
    copyProduct(input: $input) {
      ${productGraphQuery}
    }
  }
`;

export const UPLOAD_PRODUCT_IMAGE_MUTATION = gql`
  mutation uploadProductImage($input: ImageUploadInput, $productId: String) {
    uploadProductImage(input: $input, productId: $productId)
  }
`;

/**
 *custom fragments of product screen and product settings
 */

export const MODIFIERS_GROUP_FRAGMENT = `
  modifierGroups{
    id
    name
    isGrouped
    isRequired,
    priority
    modifiers {
      id
      isDefault
      price {
        amount
        currency
      }
      priority
    }
    products {
      id
      isDefault
      name
      price {
        amount
        currency
      }
      priority
    }
  }
`;

export const PRODUCT_MODIFIERS_FRAGMENT = `
  id
  name
  isCombo
  ${MODIFIERS_GROUP_FRAGMENT}
`;

export const PRODUCT_LIST_SCREEN_FRAGMENT = `
  id
  name
  productType {
    id
    name
  }
  imageUrl
  isSellable
  stores {
    id
  }
  pages {
    id
  }
  isCombo
  ${MODIFIERS_GROUP_FRAGMENT}
  pricingGroups {
    id
    name
    prices {
      id
      taxInclusive
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
    }
  }
`;

export const PRODUCT_GENERAL_FRAGMENT = `
  id
  name
  isSellable
  imageUrl
  productType {
    id
    name
  }
  printerProfiles{
    id
    name
    printerProfileType
  }
  barcode
  sku
  plu
  gtin
  dietaryTags {
    id
    name
  }
  alternateNames {
    locale
    display
    kitchen
    receipt
    description
  }
  measuredBy {
    unitType
    defaultSize
    units
  }
  description
  isCombo
`;

export const PRODUCT_AVAILABILITY_FRAGMENT = `
  id
  name
  pages {
    id
  }
  salesChannels {
    id
  }
  stores {
    id
  }
`;

export const PRODUCT_PRICING_FRAGMENT = `
  id
  name
  variablePricing
  maxSellingPrice
  minSellingPrice
  pricingGroups {
    id
    name
    prices {
      id
      taxInclusive
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
      costTax {
        id
      }
      costPrice {
        amount
        currency
      }
    }
  }
`;

export const getProductsQuery = (customFragment?: string) => {
  const gqlQuery = gql`
     query products {
       products {
         ${customFragment ? customFragment : productGraphQuery}
       }
     }
   `;
  return gqlQuery;
};

export const getProductQuery = (customFragment?: string) => {
  const gqlQuery = gql`
  query productPricesInfo($id: String!) {
    product(id: $id) {
      ${customFragment ? customFragment : productGraphQuery}
    }
  }
`;
  return gqlQuery;
};

export const getProductQueryWithFilter = (customFragment?: string) => {
  const gqlQuery = gql`
  query products($input: String) {
    products(filter:{name: $input}){
      ${customFragment ? customFragment : productGraphQuery}
    }
  }
`;
  return gqlQuery;
};

export const updateProductMutation = (customFragment?: string) => {
  const gqlQuery = gql`
  mutation updateProduct($input: UpdateProductInput) {
    updateProduct(input: $input) {
      ${customFragment ? customFragment : productGraphQuery}
    }
  }
`;
  return gqlQuery;
};

export const updateProductsMutation = (customFragment?: string) => {
  const gqlQuery = gql`
  mutation updateProducts($input: [UpdateProductInput]) {
    updateProducts(input: $input) {
      ${customFragment ? customFragment : productGraphQuery}
    }
  }
`;
  return gqlQuery;
};

export const createProductMutation = (customFragment?: string) => {
  const gqlQuery = gql`
  mutation createProduct($input: CreateProductInput) {
    createProduct(input: $input) {
      ${customFragment ? customFragment : productGraphQuery}
    }
  }
`;
  return gqlQuery;
};

export const copyProductMutation = (customFragment?: string) => {
  const gqlQuery = gql`
  mutation copyProduct($input: CopyProductInput) {
    copyProduct(input: $input) {
      ${customFragment ? customFragment : productGraphQuery}
    }
  }
`;
  return gqlQuery;
};

export const UPDATE_PRODUCTS_AVAILABILITY_EVENT = gql`
  mutation syncProductAvailabilityEvents(
    $input: [UpdateProductsAvailabilityInput!]
  ) {
    syncProductAvailabilityEvents(input: $input)
  }
`;

export const UPDATE_STORE_STATUS_EVENT = gql`
  mutation syncUpdateStoreStatusEvents($input: [UpdateStoreStatusInput!]) {
    syncUpdateStoreStatusEvents(input: $input)
  }
`;
