import { StyleFn } from '@hitz-group/domain';
import React, { useMemo, useState, useCallback } from 'react';
import { useFela } from 'react-fela';
import { View, Image } from 'react-native';
import IconButton from '..//Button/IconButton';

const containerStyle: StyleFn = () => ({
  width: 600,
  height: 240,
  flexDirection: 'row',
  overflow: 'hidden',
});

const leftImageStyle: StyleFn = () => ({
  width: 110,
  height: 240,
  flexDirection: 'row',
  overflow: 'hidden',
});

const centerImageStyle: StyleFn = () => ({
  width: 360,
  height: 240,
  flexDirection: 'row',
  overflow: 'hidden',
});

const imageTileSyle: StyleFn = () => ({
  width: 360,
  height: 240,
});

const arrowContainerStyle: StyleFn = ({ theme }) => ({
  width: 38,
  height: 38,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  marginLeft: theme.spacing.small,
  backgroundColor: theme.colors.black,
  top: -130,
  left: -310,
  borderRadius: theme.radius.medium,
});

const arrowRightContainerStyle: StyleFn = ({ theme }) => ({
  width: 38,
  height: 38,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  marginLeft: theme.spacing.small,
  backgroundColor: theme.colors.black,
  top: -170,
  right: -210,
  borderRadius: theme.radius.medium,
});

export interface ImageCarouselProps {
  imageUrls: string[];
}

export const ImageCarousel: React.FC<ImageCarouselProps> = ({ imageUrls }) => {
  const { css, theme } = useFela();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const leftImageIndex = useMemo(() => {
    if (selectedImageIndex === 0 && imageUrls.length) {
      return imageUrls.length - 1;
    } else {
      return selectedImageIndex - 1;
    }
  }, [imageUrls, selectedImageIndex]);

  const rightImageIndex = useMemo(() => {
    if (selectedImageIndex >= imageUrls.length - 1) {
      return 0;
    } else {
      return selectedImageIndex + 1;
    }
  }, [imageUrls, selectedImageIndex]);

  const slideLeft = useCallback(() => {
    setSelectedImageIndex(prev => {
      if (prev <= 0) {
        return imageUrls.length - 1;
      } else {
        return prev - 1;
      }
    });
  }, [imageUrls]);

  const slideRight = useCallback(() => {
    setSelectedImageIndex(prev => {
      if (prev >= imageUrls.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }, [imageUrls]);

  return (
    <>
      <View style={css(containerStyle)}>
        <View style={css(leftImageStyle)}>
          <Image
            style={css(imageTileSyle)}
            source={{ uri: imageUrls[leftImageIndex] }}
          />
        </View>

        <View style={css(centerImageStyle)}>
          <Image
            style={css(imageTileSyle)}
            source={{ uri: imageUrls[selectedImageIndex] }}
          />
        </View>

        <View style={css(leftImageStyle)}>
          <Image
            style={css(imageTileSyle)}
            source={{ uri: imageUrls[rightImageIndex] }}
          />
        </View>
      </View>

      <IconButton
        primary
        icon={'ArrowLeft'}
        iconSize={26}
        containerSize={34}
        iconColor={theme.colors.white}
        containerStyle={css(arrowContainerStyle)}
        onPress={slideLeft}
      />
      <IconButton
        primary
        icon={'ArrowRight'}
        iconSize={26}
        containerSize={34}
        iconColor={theme.colors.white}
        containerStyle={css(arrowRightContainerStyle)}
        onPress={slideRight}
      />
    </>
  );
};
