import { StyleFn, Page } from '@hitz-group/domain';
import React, { useEffect, useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client/react/hooks';
import { useFela } from 'react-fela';
import { ScrollView, View, Text } from 'react-native';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import TableRow from '../../../../components/TableComponent/TableRow';
import { DELETE_PAGE } from '../../../../graphql/settings';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import IconButton from '../../../../components/Button/IconButton';
import { useNotification } from '../../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import { parseApolloError, noopHandler } from '../../../../utils/errorHandlers';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../components/FormInput/FormInput';
import Button from '../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import scale from '../../../../common/theme';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';

const scrollStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

const formInputContainerStyle: StyleFn = () => ({
  width: 300,
  height: 38,
  justifyContent: 'center',
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const productCountStyle: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(40),
  height: scale.moderateScale(20),
  paddingVertical: theme.padding.medium,

  alignItems: 'center',
  flex: 1,
  justifyContent: 'center',
  right: 30,
  backgroundColor: theme.colors.greyLight,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

interface ProductTagRowProps {
  page: Page;
  onDeletePage: (index: number, id: string, name: string) => void;
  onChange: (index: number, value: string) => void;
  index: number;
}
const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const assignIconContainerStyle: StyleFn = ({ theme }) => ({
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
  alignSelf: 'center',
  marginRight: 10,
});

const assignIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const ProductTagRow: React.FC<ProductTagRowProps> = ({
  page,
  onDeletePage,
  onChange,
  index,
}: ProductTagRowProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  const productCount = useMemo(() => {
    return (
      (page.products?.length || 0) +
      ((page.variants &&
        page.variants?.reduce((acc, val) => {
          return acc + (val?.products?.length || 0);
        }, 0)) ||
        0)
    );
  }, [page]);

  return (
    <TableRow
      action={
        <IconButton
          icon="times"
          iconSize={24}
          containerSize={38}
          containerStyle={css(closeIconContainerStyle)}
          iconStyle={css(closeIconStyle)}
        />
      }
    >
      <FormInput
        testID="tax-name"
        placeholder={translate('backOfficeProducts.pageName')}
        value={page.name}
        containerStyle={css(formInputContainerStyle)}
        textStyle={css(formTextStyle)}
        textInputStyle={css(formInputContainerStyle)}
        onChangeText={onChange.bind(null, index)}
      />

      <View style={css(productCountStyle)}>
        <Text>{productCount}</Text>
      </View>
      <IconButton
        icon="TagAlt"
        iconSize={24}
        containerSize={34}
        containerStyle={css(assignIconContainerStyle)}
        iconStyle={css(assignIconStyle)}
        onPress={() => onDeletePage}
      />
    </TableRow>
  );
};

interface DeleteModalProps {
  id: string;
  name: string;
  index: number;
  deletePageInState: (index: number) => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  id,
  name,
  index,
  deletePageInState,
}: DeleteModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [deletePage, deleteOperation] = useMutation(DELETE_PAGE, {
    onError: noopHandler,
  });

  useEffect((): void => {
    if (deleteOperation.error) {
      showNotification({
        error: true,
        message: parseApolloError(deleteOperation.error),
      });
    }
  }, [deleteOperation.error, showNotification]);

  useEffect((): void => {
    if (deleteOperation.data) {
      deletePageInState(index);
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeProducts.itemWasDeletedSuccessfully', {
          item: name,
        }),
      });
    }
  }, [
    deletePageInState,
    index,
    id,
    deleteOperation.data,
    showNotification,
    closeModal,
    name,
    translate,
  ]);

  const onDeletePage = useCallback((): void => {
    deletePage({ variables: { id: id } });
  }, [deletePage, id]);

  return (
    <ConfirmationDialog
      title={
        translate('dialog.deleteTitle') +
        ' ' +
        translate('backOfficeProducts.page')
      }
      message={translate('dialog.deleteConfirmation', { label: name })}
      onConfirm={onDeletePage}
    />
  );
};

export const ProductTags: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();

  const onCreateProductTag = () => {
    console.log('');
  };
  const onChangeName = () => {
    console.log('');
  };
  const onPressSave = () => {
    console.log('');
  };
  const onPressDelete = () => {
    console.log('');
  };

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollStyle)}>
          <BackOfficeSection
            title={translate('productTagCatalogue.ProductTags')}
            titleDescription={translate(
              'productTagCatalogue.ProductTagsDescription',
            )}
            action={<BackOfficeCreateNewButton onPress={onCreateProductTag} />}
          >
            <TableComponent
              columns={[
                {
                  title: translate('productTagCatalogue.tagName'),
                  width: 150,
                },
                {
                  title: translate('productTagCatalogue.products'),
                  width: 80,
                },
                {
                  title: translate('productTagCatalogue.assign'),
                  width: 80,
                },
                { title: '', width: 30 },
              ]}
              data={producTagData}
              renderRow={(item: Page, index: number): React.ReactNode => (
                <ProductTagRow
                  page={item}
                  onDeletePage={onPressDelete}
                  onChange={onChangeName}
                  key={index}
                  index={index}
                />
              )}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>

      <View style={css(actionsContainerStyle)}>
        <Button
          fluid
          testID="save-changes"
          title={translate('button.saveChanges')}
          containerStyle={css(saveButtonStyle)}
          labelStyle={css(titleStyle)}
          onPress={onPressSave}
        />
      </View>
    </>
  );
};

const producTagData = [
  {
    id: '3a2d9bb0-21fb-4547-bba4-354dd03e488f',
    name: 'course1',
    products: [
      { id: '6810240a-784b-4acc-b4d4-652c18bca1e3', __typename: 'Product' },
      { id: 'cbac2448-cd5a-4183-aeed-0abac1484b49', __typename: 'Product' },
    ],
  },
  {
    id: '3a2d9bb0-21fb-4547-bba4-354dd03e4882',
    name: 'course2',
    products: [
      { id: '6810240a-784b-4acc-b4d4-652c18bca1e3', __typename: 'Product' },
      { id: 'cbac2448-cd5a-4183-aeed-0abac1484b49', __typename: 'Product' },
    ],
  },
];
