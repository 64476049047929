import { RenderProps, StyleFn } from '@hitz-group/domain';
import React from 'react';
import { FelaComponent } from 'react-fela';
import FormInput, { FormInputProps } from '../FormInput/FormInput';

const textAreaStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 150,
  paddingTop: theme.padding.medium,
  paddingHorizontal: theme.padding.small,
});

export const TextArea: React.FC<FormInputProps> = ({
  value,
  onChangeText,
  placeholder,
  title,
  maxLength,
}) => {
  return (
    <FelaComponent style={textAreaStyle}>
      {({ style }: RenderProps) => (
        <FormInput
          fluid
          multiline
          value={value}
          title={title}
          containerStyle={style}
          onChangeText={onChangeText}
          textInputStyle={style}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      )}
    </FelaComponent>
  );
};
