import { roundOffByValue } from '@hitz-group/client-utils';

export const getFastCashOptions = (
  displayAmount: number,
  enableRoundOff?: boolean,
  roundOffValue?: number,
): number[] => {
  const value =
    displayAmount % 5 === 0
      ? displayAmount + 1
      : displayAmount <= 0
      ? 1
      : displayAmount;
  let values = [
    displayAmount <= 0 ? 1 : displayAmount,
    Math.ceil(value / 5) * 5,
    Math.ceil(value / 10) * 10,
    Math.ceil(value / 20) * 20,
    Math.ceil(value / 50) * 50,
    Math.ceil(value / 100) * 100,
    Math.ceil(value / 200) * 200,
    Math.ceil(value / 500) * 500,
    Math.ceil(value / 1000) * 1000,
  ];
  if (enableRoundOff && roundOffValue)
    values = values.map(val => roundOffByValue(val, roundOffValue));
  if (displayAmount === 0) {
    return values.map(() => 0);
  }
  return [...new Set(values)].slice(0, 5);
};
