import { useTranslation } from '@hitz-group/localization';
import { StyleFn } from '@hitz-group/domain';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import Button from '../../../../../components/Button/Button';
import IconButton from '../../../../../components/Button/IconButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import TableRow from '../../../../../components/TableComponent/TableRow';
import { useModal } from '@hitz-group/rn-use-modal';
import { useNotification } from '../../../../../hooks/Notification';
import {
  CreatePrinterProfileInput,
  PrinterProfile,
  PrinterProfileType,
} from '@hitz-group/domain';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { usePrinterProfiles } from '../../../../../hooks/app/usePrinterProfiles';
import { Helmet } from 'react-helmet';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import { pick } from 'lodash';
import scale, { isWeb } from '../../../../../common/theme';
import { isNotEmpty } from '@hitz-group/client-utils';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});
const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});
const scrollStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
});
const formStyle: StyleFn = ({ theme }) => ({
  width: 545,
  marginTop: theme.spacing.medium,
  paddingBottom: theme.spacing.big,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 300,
  height: theme.input.height,
  justifyContent: 'center',
  marginLeft: -15,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  bottom: isWeb ? 0 : 2,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});
const rowContainerStyle: StyleFn = ({ theme }) => ({
  height: isWeb ? 'auto' : scale.moderateScale(35),
  alignItems: 'center',
  alignContent: 'flex-start',
  borderBottomColor: theme.colors.boxBorder,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

interface PrinterProfileRowProps {
  index: number;
  printerProfile: PrinterProfile;
  onChange: (index: number, id: string, value: string) => void;
  onDeletePrinterProfile: (index: number, id: string) => void;
}

const PrinterProfileRow: React.FC<PrinterProfileRowProps> = ({
  printerProfile,
  onDeletePrinterProfile,
  onChange,
  index,
}: PrinterProfileRowProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  const { showModal, closeModal } = useModal();

  const onPressDelete = useCallback((): void => {
    if (printerProfile.id === undefined) {
      onDeletePrinterProfile(index, printerProfile.id);
    } else {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: printerProfile.name,
          })}
          onConfirm={() => {
            onDeletePrinterProfile(index, printerProfile.id);
            closeModal();
          }}
        />,
      );
    }
  }, [
    showModal,
    closeModal,
    onDeletePrinterProfile,
    translate,
    printerProfile,
    index,
  ]);
  return (
    <TableRow
      containerStyle={css(rowContainerStyle)}
      action={
        <IconButton
          icon="TrashAlt"
          iconSize={24}
          containerSize={38}
          containerStyle={css(closeIconContainerStyle)}
          iconStyle={css(closeIconStyle)}
          onPress={onPressDelete}
        />
      }
    >
      <FormInput
        testID="printer-profile-name"
        placeholder={translate('backOfficePrinterProfiles.printerProfileName')}
        value={printerProfile.name}
        containerStyle={css(formInputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(null, index, printerProfile?.id)}
      />
    </TableRow>
  );
};

type FormState = Record<string, PrinterProfile & { isChanged: boolean }>;

export const PrinterProfiles: React.FC = () => {
  const { css, theme } = useFela();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<FormState>({});
  const [newPrinterProfiles, setNewPrinterProfiles] = useState<
    CreatePrinterProfileInput[]
  >([]);

  const [deletedPrinterProfileId, setDeletedPrinterProfileId] =
    useState<string>('');

  const {
    loading,
    error,
    printerProfiles,
    getPrinterProfiles,
    createPrinterProfiles,
    createdPrinterProfileIds,
    updatePrinterProfiles,
    updatedPrinterProfileIds,
    deletePrinterProfile,
    isPrinterProfileDeleted,
  } = usePrinterProfiles();

  const { closeModal } = useModal();

  useEffect(() => {
    getPrinterProfiles();
  }, [getPrinterProfiles]);

  useEffect(() => {
    if (printerProfiles) {
      setForm(printerProfiles as FormState);
    }
  }, [printerProfiles]);

  useEffect(() => {
    if (isPrinterProfileDeleted && deletedPrinterProfileId) {
      setForm(prev => {
        const tempForm = { ...prev };
        delete tempForm[deletedPrinterProfileId];
        return tempForm;
      });
    }
  }, [deletedPrinterProfileId, isPrinterProfileDeleted]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onDeletePrinterProfile = useCallback(
    (index: number, id: string): void => {
      if (!id) {
        const currentPrinterProfilesCount = Object.keys(form).length;
        const updatedPrinterProfiles = newPrinterProfiles;
        newPrinterProfiles.splice(index - currentPrinterProfilesCount, 1);
        setNewPrinterProfiles([...updatedPrinterProfiles]);
      } else {
        setDeletedPrinterProfileId(id);
        deletePrinterProfile(id);
      }
    },
    [deletePrinterProfile, newPrinterProfiles, form],
  );

  useEffect((): void => {
    if (isPrinterProfileDeleted) {
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficePrinterProfiles.printerProfileDeletedSuccessfully',
        ),
      });
    }
  }, [isPrinterProfileDeleted, showNotification, translate, closeModal]);

  useEffect((): void => {
    if (updatedPrinterProfileIds && updatedPrinterProfileIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            updatedPrinterProfileIds.length > 1
              ? 'backOfficePrinterProfiles.printerProfilesUpdatedSuccessfully'
              : 'backOfficePrinterProfiles.printerProfileUpdatedSuccessfully'
          }`,
        ),
      });
    }
  }, [updatedPrinterProfileIds, showNotification, translate]);

  useEffect((): void => {
    if (createdPrinterProfileIds && createdPrinterProfileIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            createdPrinterProfileIds.length > 1
              ? 'backOfficePrinterProfiles.printerProfilesCreatedSuccessfully'
              : 'backOfficePrinterProfiles.printerProfileCreatedSuccessfully'
          }`,
        ),
      });
      setNewPrinterProfiles([]);
    }
  }, [
    createdPrinterProfileIds,
    showNotification,
    translate,
    setNewPrinterProfiles,
  ]);

  const onChangeName = useCallback(
    (index: number, id: string, value: string): void => {
      if (!id) {
        const currentPrinterProfilesCount = Object.keys(form).length;
        const updatedPrinterProfiles = newPrinterProfiles;
        updatedPrinterProfiles[index - currentPrinterProfilesCount] = {
          name: value,
          printerProfileType: PrinterProfileType.KITCHEN,
        };
        setNewPrinterProfiles([...updatedPrinterProfiles]);
      } else {
        setForm(form => ({
          ...form,
          [id]: {
            ...form[id],
            name: value,
            printerProfileType: PrinterProfileType.KITCHEN,
            isChanged: true,
          },
        }));
      }
    },
    [form, newPrinterProfiles],
  );

  const onPressSave = useCallback((): void => {
    const printerProfilesToUpdate = Object.values(form)
      .filter(printerProfile => printerProfile.isChanged)
      .map(printerProfile =>
        pick(printerProfile, ['id', 'name', 'printerProfileType']),
      );

    if (
      newPrinterProfiles.some(
        printerProfile =>
          !printerProfile.name || !isNotEmpty(printerProfile.name),
      )
    ) {
      showNotification({
        error: true,
        message: translate('backOfficePrinterProfiles.enterPrinterProfileName'),
      });
    } else {
      if (newPrinterProfiles.length > 0)
        createPrinterProfiles(newPrinterProfiles);
      if (printerProfilesToUpdate.length > 0)
        updatePrinterProfiles(printerProfilesToUpdate);
    }
  }, [
    createPrinterProfiles,
    updatePrinterProfiles,
    newPrinterProfiles,
    showNotification,
    translate,
    form,
  ]);

  const onPressCreateNew = useCallback(() => {
    setNewPrinterProfiles([
      ...newPrinterProfiles,
      {} as CreatePrinterProfileInput,
    ]);
  }, [newPrinterProfiles]);

  const printerProfilesData = [
    ...Object.values(form),
    ...(newPrinterProfiles as unknown as PrinterProfile[]),
  ];

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ScrollView
              testID="general-settings-screen"
              contentContainerStyle={css(scrollStyle)}
            >
              <BackOfficeSection
                title={translate('backOfficePrinterProfiles.printerProfiles')}
                titleDescription={translate(
                  'backOfficePrinterProfiles.printerProfilesDescription',
                )}
                contentContainerStyle={css(formStyle)}
                action={
                  <BackOfficeCreateNewButton onPress={onPressCreateNew} />
                }
              >
                <TableComponent
                  columnContainerStyle={css(columnContainerStyle)}
                  columns={[
                    {
                      title: translate(
                        'backOfficePrinterProfiles.printerProfileName',
                      ),
                      flex: 1,
                      containerStyle: { marginLeft: theme.spacing.small },
                    },
                    { title: '', width: 30 },
                  ]}
                  data={printerProfilesData}
                  normalRows={true}
                  renderRow={(
                    item: PrinterProfile,
                    index: number,
                  ): React.ReactNode => (
                    <PrinterProfileRow
                      printerProfile={item}
                      key={index}
                      index={index}
                      onChange={onChangeName}
                      onDeletePrinterProfile={onDeletePrinterProfile}
                    />
                  )}
                />
              </BackOfficeSection>
              <View style={css(bottomSpace)}></View>
            </ScrollView>
            <View style={css(actionsContainerStyle)}>
              <Button
                fluid
                testID="save-changes"
                title={translate('button.saveChanges')}
                containerStyle={css(saveButtonStyle)}
                labelStyle={css(titleStyle)}
                onPress={onPressSave}
              />
            </View>
          </>
        )}
      </View>
    </>
  );
};
