import SecondarySidePanel from '../../../../src/components/SecondarySidePanel/SecondarySidePanel';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FeatureContext,
  Features,
  Order,
  OrderAction,
  OrderPaymentEvent,
  RefundOrderItemsEvent,
  PaymentType,
  ReceiptPrintOption,
  StyleFn,
  AssignRefundReasonEvent,
  InitiateRefundInput,
} from '@hitz-group/domain';
import { useRefundReasons } from '../../../../src/hooks/app/useRefundReasons';
import { useCartContext as useCart } from '../../../../src/hooks/CartProvider';
import { useModal } from '@hitz-group/rn-use-modal';
import { useNotification } from '../../../../src/hooks/Notification';
import CartSection from '../../../../src/components/RefundScreen/CartSection';
import RefundModal from '../../../components/Modals/OrderHistoryModals/RefundModal';
import { PickerModal } from '../../../../src/components/Modals/Picker/Picker';
import RefundPaymentTypesModal from '../../../../src/components/Modals/OrderHistoryModals/RefundPaymentTypes/RefundPaymentTypes';
import PaymentConfirmationModal from '../../../../src/components/Modals/PaymentConfirmation/PaymentConfirmation';
import { useTranslation } from '@hitz-group/localization';
import { RefundCartHeader } from '../../../../src/components/RefundScreen/RefunCartHeader';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { GET_REFUND_ORDER_QUERY } from '../../../../src/hooks/app/orders/graphql';
import LoadingIndicator from '../../../../src/components/LoadingIndicator/LoadingIndicator';
import { useSession } from '../../../../src/hooks/app/useSession';
import { SYNC_ORDER_EVENTS } from '../../../../src/graphql/syncEvents';
import { noopHandler } from '../../../../src/utils/errorHandlers';
import { sendOrderReceipt } from '../../../../src/utils/OrderHistoryHelper';
import { usePrinting } from '../../../../src/hooks/PrintingProvider';
import { useNetworkStatusVar } from '../../../../src/hooks/app/useNetworkStatusVar';
import { useCheckFeatureEnabled } from '../../../../src/hooks/app/features/useCheckFeatureEnabled';
import { getRoundingAmount } from '../../../../src/utils/roundOffHelper';
import { IMap } from '../../BackOffice/Reports/types';
import { cloneDeep } from 'lodash';
import {
  AdditionalPaymentInfo,
  DEFAULT_PAYMENT_CURRENCY,
  OOLIO_PAY_PAYMENT_TYPE,
} from '../../../../src/types/Common';
import { usePayments } from '../../../../src/hooks/app/usePayments';
import { isOolioPayRefundLoading } from '../../../state/cache';

export const cartSectionContainer: StyleFn = () => ({
  flex: 1,
});

interface RefundWorkFlow {
  showSidePanel: Boolean;
  orderId: string;
  setShowSidePanel: (bool: boolean) => void;
  onCompleteRefund: () => void;
  getCachedRefundOrder: (id: string) => Order | undefined;
}

const RefundWorkFlow: React.FC<RefundWorkFlow> = ({
  showSidePanel,
  orderId,
  setShowSidePanel,
  onCompleteRefund,
  getCachedRefundOrder,
}) => {
  const CASH_PAYMENT_TYPE = 'cash';
  const refundReason = useRef<string>('Unknown');
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const orderIdRef = useRef<string>('');
  const refundOrderRef = useRef<Order | undefined>(undefined);
  const originalPaymentDetailsRef = useRef<
    Pick<Order, 'payments' | 'totalPrice'> | undefined
  >(undefined);
  const paymentTypeRef = useRef<PaymentType | undefined>(undefined);
  const [initiatePrint, setInitiatePrint] = useState<boolean>(false);
  const [disableConfirmRefundBtn, setDisableConfirmRefundBtn] =
    useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>(
    undefined as unknown as Order,
  );
  const [session] = useSession();
  const receiptPrintOption = session.deviceProfile?.receiptPrintOption;
  const networkStatus = useNetworkStatusVar();
  // Using useRef here because callback sent to showModal is getting previous state
  // State was not getting updated in the callback function sent to showModal
  const networkStatusRef = useRef<boolean>(true);
  networkStatusRef.current = networkStatus;
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isRefundReasonsEnabled = isFeatureEnabled(
    Features.REFUND_REASON,
    FeatureContext.VENUE,
  );
  const [syncEvents] = useMutation(SYNC_ORDER_EVENTS, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        success: true,
        message: translate('common.sendSaleReceiptToCustomerSuccess'),
      });
    },
  });
  const [getRefundOrderReq, getRefundOrderRes] = useLazyQuery(
    GET_REFUND_ORDER_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  );

  const {
    loading: refundReasonLoading,
    error: refundReasonError,
    refundReasons,
    getRefundReasons,
  } = useRefundReasons();

  const {
    order: refundOrder,
    getOrderData,
    status: { error: orderError },
    updateCart,
    initiateRefund,
    discardChanges,
  } = useCart();

  const {
    loading: isRefundLoading,
    error: oolioRefundError,
    paymentResponse: oolioRefundResponse,
    initiateRefund: initiateOolioRefund,
    resetPaymentResponse,
  } = usePayments();

  // FIXME: Using reactive to show loader for Refund api.
  // "isRefundLoading" when passed as prop is not updating the component,
  // useCallback is not updating on state dependency update.
  useEffect(() => {
    isOolioPayRefundLoading(isRefundLoading);
  }, [isRefundLoading]);

  useEffect(() => {
    if (oolioRefundError) {
      showNotification({
        error: true,
        message: oolioRefundError,
      });
    }
  }, [oolioRefundError, showNotification]);

  const { printBill } = usePrinting();

  const ifNoNetwork = useCallback(() => {
    if (!networkStatusRef.current) {
      closeModal();
      showNotification({
        error: true,
        message: translate('refundOrder.noInternet'),
      });
      return true;
    }
    return false;
  }, [closeModal, showNotification, translate]);

  useEffect(() => {
    if (networkStatus && selectedOrder) {
      getRefundOrderReq({
        variables: { refundOf: selectedOrder.id },
      });
    }
  }, [getRefundOrderReq, networkStatus, selectedOrder]);

  useEffect(() => {
    getRefundReasons();
  }, [getRefundReasons]);

  useEffect(() => {
    if (refundReasonLoading || getRefundOrderRes.loading) {
      <LoadingIndicator />;
    }
  }, [getRefundOrderRes.loading, refundReasonLoading]);

  useEffect(() => {
    const network = networkStatusRef.current;
    if (orderIdRef.current !== orderId) {
      network &&
        getRefundOrderReq({
          variables: { refundOf: orderId },
        });
      async function getData() {
        const order = await getOrderData(orderId);
        if (order) {
          orderIdRef.current = orderId;
          originalPaymentDetailsRef.current = {
            payments: order.payments,
            totalPrice: order.totalPrice,
          };
          setSelectedOrder(cloneDeep(order));
        }
        setDisableConfirmRefundBtn(true);
      }
      getData();
    }
  }, [
    getOrderData,
    getRefundOrderReq,
    orderId,
    session.currentStore?.id,
    ifNoNetwork,
  ]);

  useEffect(() => {
    if (getRefundOrderRes.data) {
      if (getRefundOrderRes.data.refundOrder) {
        setDisableConfirmRefundBtn(true);
      } else {
        setDisableConfirmRefundBtn(false);
      }
    }
  }, [getRefundOrderRes.data]);

  useEffect(() => {
    const isInternetActive = networkStatusRef.current;
    if (!isInternetActive && selectedOrder?.refundOf) {
      setDisableConfirmRefundBtn(false);
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (refundReasonError) {
      showNotification({
        error: true,
        message: refundReasonError,
      });
    }

    if (getRefundOrderRes.error) {
      showNotification({
        error: true,
        message: getRefundOrderRes.error.message,
      });
    }
  }, [
    getRefundOrderRes.error,
    ifNoNetwork,
    orderError,
    refundReasonError,
    selectedOrder,
    showNotification,
  ]);

  const onPressPrintReceipt = useCallback(async () => {
    if (refundOrder) {
      const order = getCachedRefundOrder(refundOrder.id);
      const result = order && (await printBill(order));
      closeModal();
      if (result && Object.keys(result)?.length > 0 && result.error) {
        showNotification(result);
      }
    }
  }, [
    closeModal,
    getCachedRefundOrder,
    printBill,
    refundOrder,
    showNotification,
  ]);

  useEffect(() => {
    if (refundOrder) {
      refundOrderRef.current = refundOrder;
      if (getCachedRefundOrder(refundOrderRef.current.id) && initiatePrint) {
        onPressPrintReceipt();
        setInitiatePrint(false);
      }
    }
  }, [getCachedRefundOrder, initiatePrint, onPressPrintReceipt, refundOrder]);

  const closeSidePanel = useCallback(() => {
    setShowSidePanel(false);
  }, [setShowSidePanel]);

  const onPressSendRefundReceipt = useCallback(
    async (email: string) => {
      await sendOrderReceipt(
        refundOrderRef.current as unknown as Order,
        email,
        syncEvents,
        session,
      );
      closeModal();
    },
    [closeModal, session, syncEvents],
  );

  const printBillHelper = useCallback(() => {
    if (receiptPrintOption === ReceiptPrintOption.AUTO) {
      setInitiatePrint(true);
      return false;
    } else if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
      return true;
    }
    return false;
  }, [receiptPrintOption]);

  const setInitiatePrintToTrue = async () => {
    setInitiatePrint(true);
  };

  const onCompleteRefundPayment = useCallback(
    (additionalInfo?: AdditionalPaymentInfo) => {
      if (!ifNoNetwork()) {
        if (refundOrder) {
          const paymentType = { ...paymentTypeRef.current } as PaymentType;
          let roundingAmount = refundOrderRef.current?.roundingAmount || 0;
          if (paymentType?.name?.toLowerCase() === CASH_PAYMENT_TYPE) {
            roundingAmount = +getRoundingAmount(
              session,
              paymentType,
              refundOrderRef.current,
            ).toFixed(2);
          }
          updateCart<OrderPaymentEvent>(OrderAction.ORDER_PAYMENT, {
            tendered:
              (refundOrderRef.current?.totalPrice || 0) + roundingAmount,
            paymentTypeId: paymentType.id,
            tip: 0,
            change: 0,
            roundOffDifference: roundingAmount,
            refundOrder: true,
            paymentTypeName: paymentType.name,
            ...(additionalInfo &&
              additionalInfo.paymentTransactionRef && {
                paymentTransactionRef: additionalInfo.paymentTransactionRef,
              }),
            ...(additionalInfo &&
              additionalInfo.paymentCompletedAt && {
                paymentCompletedAt: additionalInfo.paymentCompletedAt,
              }),
            ...(additionalInfo &&
              additionalInfo.paymentReceipt && {
                paymentReceipt: additionalInfo.paymentReceipt,
              }),
          });
          updateCart(OrderAction.ORDER_SAVE);
          setDisableConfirmRefundBtn(true);
          refundReason.current = 'Unknown';
          paymentTypeRef.current = undefined;
          closeModal();
          // FIXME: Look for a better way
          setTimeout(onCompleteRefund, 200);
          if (printBillHelper()) {
            showModal(
              <PaymentConfirmationModal
                isRefundOrder={true}
                onPressPrintReceipt={setInitiatePrintToTrue}
                sendReceipt={onPressSendRefundReceipt}
              />,
              {
                onBackdropPress: closeModal,
              },
            );
          }
        }
      }
    },
    [
      closeModal,
      ifNoNetwork,
      onCompleteRefund,
      onPressSendRefundReceipt,
      printBillHelper,
      refundOrder,
      session,
      showModal,
      updateCart,
    ],
  );

  const onPressRefundPayType = useCallback(
    (paymentType: PaymentType) => {
      if (!ifNoNetwork()) {
        if (refundOrder) {
          paymentTypeRef.current = paymentType;
          if (
            paymentType.name === OOLIO_PAY_PAYMENT_TYPE &&
            originalPaymentDetailsRef.current &&
            refundOrderRef.current
          ) {
            const paymentInfo =
              originalPaymentDetailsRef.current?.payments?.[0];

            const refundPayload = {
              deviceId: session.device?.deviceCode as string,
              terminalId: session.device?.paymentTerminal?.uuid as string,
              transactionRef: paymentInfo?.paymentTransactionRef as string,
              transactionCompletedAt: paymentInfo?.paymentCompletedAt as string,
            } as InitiateRefundInput;

            if (
              originalPaymentDetailsRef.current?.totalPrice !==
              refundOrderRef.current?.totalPrice
            ) {
              refundPayload.transactionId = refundOrderRef.current.orderNumber;
              refundPayload.amount = refundOrderRef.current.amountDue;
              refundPayload.currency = DEFAULT_PAYMENT_CURRENCY;
            }

            initiateOolioRefund(refundPayload);
            return;
          } else {
            onCompleteRefundPayment();
          }
        }
      }
    },
    [
      ifNoNetwork,
      initiateOolioRefund,
      onCompleteRefundPayment,
      refundOrder,
      session.device,
    ],
  );

  useEffect(() => {
    if (!isRefundLoading && oolioRefundResponse) {
      if (oolioRefundResponse.success) {
        onCompleteRefundPayment({
          paymentTransactionRef:
            oolioRefundResponse.additionalInfo?.transactionId,
          paymentCompletedAt:
            oolioRefundResponse.additionalInfo?.transactionCompletedAt,
        });

        showNotification({
          message: translate('payment.oolioPayRefundSuccess'),
          success: true,
        });
      } else {
        showNotification({
          error: true,
          message: oolioRefundResponse.message,
        });
      }
      resetPaymentResponse();
    }
  }, [
    isRefundLoading,
    onCompleteRefundPayment,
    oolioRefundResponse,
    resetPaymentResponse,
    showNotification,
    translate,
  ]);

  // Flag for showing Oolio Pay only when payment was from Oolio Pay.
  const showOolioPay =
    originalPaymentDetailsRef.current &&
    originalPaymentDetailsRef.current?.payments &&
    originalPaymentDetailsRef.current?.payments?.length === 1 &&
    originalPaymentDetailsRef.current?.payments?.[0].paymentType.name ===
      OOLIO_PAY_PAYMENT_TYPE
      ? true
      : false;

  const showPaymentTypesModal = useCallback(() => {
    if (selectedOrder) {
      showModal(
        <RefundPaymentTypesModal
          showOolioPay={showOolioPay}
          onPressRefundPayType={onPressRefundPayType}
        />,
        {
          onBackdropPress: closeModal,
        },
      );
    }
  }, [
    closeModal,
    onPressRefundPayType,
    selectedOrder,
    showModal,
    showOolioPay,
  ]);

  const onSelectRefundReason = useCallback(
    (reason: string) => {
      if (!ifNoNetwork()) {
        refundReason.current = refundReasons[reason].name;
        updateCart<AssignRefundReasonEvent>(
          OrderAction.ORDER_ASSIGN_REFUND_REASON,
          {
            reason: refundReason.current,
          },
        );
        showPaymentTypesModal();
      }
    },
    [ifNoNetwork, refundReasons, showPaymentTypesModal, updateCart],
  );

  const showRefundReasonsModal = useCallback(
    (refundedOrderItems: IMap<number>) => {
      const orderItems = [];
      for (const [key, value] of Object.entries(refundedOrderItems)) {
        orderItems.push({ id: key, quantity: value });
      }
      if (orderItems.length) {
        updateCart<RefundOrderItemsEvent>(OrderAction.ORDER_REFUND_ITEMS, {
          orderItems,
        });
      }
      if (!ifNoNetwork()) {
        if (isRefundReasonsEnabled) {
          showModal(
            <PickerModal
              onSelect={onSelectRefundReason}
              title={translate('refundOrder.selectRefundReason')}
              options={(Object.entries(refundReasons) || []).map(x => ({
                label: x[1].name,
                value: x[0],
              }))}
            />,
          );
        } else {
          showPaymentTypesModal();
        }
      }
    },
    [
      ifNoNetwork,
      isRefundReasonsEnabled,
      onSelectRefundReason,
      refundReasons,
      showModal,
      showPaymentTypesModal,
      translate,
      updateCart,
    ],
  );

  const showRefundModal = useCallback(() => {
    discardChanges();
    initiateRefund({ ...selectedOrder }, refundReason.current);
    if (!ifNoNetwork()) {
      setShowSidePanel(false);
      if (selectedOrder) {
        showModal(
          <RefundModal
            order={selectedOrder}
            onPressBtn={showRefundReasonsModal}
          />,
          {
            onBackdropPress: closeModal,
          },
        );
      }
    }
  }, [
    closeModal,
    discardChanges,
    ifNoNetwork,
    initiateRefund,
    selectedOrder,
    setShowSidePanel,
    showModal,
    showRefundReasonsModal,
  ]);

  const sidePanelHeader = useMemo(
    () =>
      selectedOrder && (
        <RefundCartHeader order={selectedOrder} onPressClose={closeSidePanel} />
      ),
    [closeSidePanel, selectedOrder],
  );

  const renderSidePanelContainer = selectedOrder ? (
    <CartSection
      order={selectedOrder}
      onPressConfirm={
        selectedOrder.refundOf ? onPressPrintReceipt : showRefundModal
      }
      customContainerStyle={cartSectionContainer}
      isCalledFromRefund={true}
      disableBtn={disableConfirmRefundBtn}
    />
  ) : null;

  return (
    <SecondarySidePanel
      showPanel={showSidePanel}
      onPressHideShowPanel={() => setShowSidePanel(false)}
      renderContainer={renderSidePanelContainer}
      customHeader={sidePanelHeader}
      isLoading={orderIdRef.current !== orderId || getRefundOrderRes.loading}
    />
  );
};

export default RefundWorkFlow;
