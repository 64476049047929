import React from 'react';
import { View, Text, ScrollView } from 'react-native';
import CartSummary from '../../../../../components/CartSummary/CartSummary';
import { useFela } from 'react-fela';
import {
  Order,
  RejectionReason,
  StyleFn,
  Address,
  Courier,
} from '@hitz-group/domain';
import * as styles from '../../../../../components/RefundScreen/CartSection.style';
import format from 'date-fns/format';
import { useTranslation } from '@hitz-group/localization';
import CartActions from './CartActions';

interface OrderDetailsSectionProps {
  order: Order;
  onPressComplete: (id: string) => void;
  onPressAccept: (ids: string[]) => void;
  onPressReject: (id: string, reason: RejectionReason) => void;
  onPressReprintDocket: (id: string) => void;
  customContainerStyle?: StyleFn;
  disableBtn?: boolean;
  onOrderPay?: (order: Order) => void;
}

interface DetailsTileProps {
  title: string;
  value: string;
}

const tileContainerStyle: StyleFn = ({ theme }) => ({
  width: 260,
  minHeight: 55,
  borderRadius: theme.radius.small,
  marginBottom: theme.spacing.small,
  marginTop: theme.spacing.small,
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.boxBorder,
  marginLeft: theme.spacing.small,
  paddingLeft: theme.spacing.small,
});

const titleTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  marginBottom: theme.spacing.small,
});

const valueContainerStyle: StyleFn = () => ({
  minHeight: 20,
});

const valueTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
});

const cartItemsStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  width: '100%',
  borderRadius: theme.radius.large,
  overflow: 'hidden',
  marginBottom: 10,
});

const cartSummaryStyle: StyleFn = ({ theme }) => ({
  paddingRight: theme.spacing.small,
});

export const DetailsTile: React.FC<DetailsTileProps> = ({ title, value }) => {
  const { css } = useFela();

  if (!value) return null;

  return (
    <View style={css(tileContainerStyle)}>
      <Text style={css(titleTextStyle)}>{title}</Text>
      <View style={css(valueContainerStyle)}>
        <Text style={css(valueTextStyle)}>{value}</Text>
      </View>
    </View>
  );
};

const getAddress = (address?: Address): string => {
  const addressResult: string[] = Object.values(address || {});
  return addressResult.join(', ');
};

const getDriverName = (courier?: Courier): string => {
  const { firstName, lastName } = courier || {};
  if (!firstName && !lastName) return '';
  return `${firstName} ${lastName}`;
};

const OrderDetailsSection: React.FC<OrderDetailsSectionProps> = ({
  order,
  onPressComplete,
  onPressAccept,
  customContainerStyle,
  onPressReject,
  disableBtn,
  onPressReprintDocket,
  onOrderPay,
}) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  const detailsData: DetailsTileProps[] = [
    {
      title: translate('onlineOrders.placedOn'),
      value: order.createdAt
        ? format(new Date(order.createdAt), 'dd/MM/yyyy hh:mm aa')
        : '',
    },
    {
      title: translate('onlineOrders.pickupTime'),
      value:
        order.requiredAt || order.createdAt
          ? format(
              new Date(order.requiredAt || order.createdAt),
              'dd/MM/yyyy hh:mm aa',
            )
          : '',
    },
    {
      title: translate('onlineOrders.deliveryBy'),
      value: order?.courier?.deliveryBy
        ? `${order.courier.deliveryBy} (${order?.orderType?.name})`
        : '',
    },
    {
      title: translate('onlineOrders.customerName'),
      value: `${order?.customer?.firstName || 'N/A'} ${
        order?.customer?.lastName || ''
      }`,
    },
    {
      title: translate('onlineOrders.customerPhone'),
      value: `${order?.customer?.phone || 'N/A'}`,
    },
    {
      title: translate('onlineOrders.address'),
      value: getAddress(
        order.shippingAddress || order?.customer?.preferredAddress,
      ),
    },
    {
      title: translate('onlineOrders.deliveryDriverName'),
      value: getDriverName(order?.courier),
    },
    {
      title: translate('onlineOrders.deliveryDriverPhone'),
      value: order?.courier?.phoneNumber || '',
    },
  ];

  return (
    <View style={css([styles.cartStyle, customContainerStyle])}>
      <ScrollView style={css(cartItemsStyle)}>
        {detailsData.map((orderData, index) => (
          <DetailsTile
            title={orderData.title}
            value={orderData.value}
            key={index + orderData.value}
          />
        ))}
      </ScrollView>
      <View style={css(cartSummaryStyle)}>
        <CartSummary
          taxes={order?.taxes}
          discounts={order?.discounts || []}
          subTotal={order?.subTotal || 0}
          totalDue={order?.totalPrice || 0}
          discountAmount={order?.discountAmount || 0}
          surchargeAmount={order?.surchargeAmount || 0}
          adjustments={order.adjustments}
          deliveryFee={order.deliveryFee}
          serviceCharge={order.serviceCharge}
          tip={order.tip}
        />
      </View>
      <View style={css(cartSummaryStyle)}>
        <CartActions
          onPressAccept={onPressAccept}
          onPressComplete={onPressComplete}
          onPressReject={onPressReject}
          onPressReprintDocket={onPressReprintDocket}
          order={order}
          disableBtn={disableBtn}
          onOrderPay={onOrderPay}
        />
      </View>
    </View>
  );
};

export default OrderDetailsSection;
