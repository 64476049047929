import React, { createContext, useEffect } from 'react';
import Cubejs, { CubejsApi } from '@cubejs-client/core';
import { useSession } from '../../../hooks/app/useSession';
import { useReportFilters } from '../../../hooks/app/useReportFilters';
import { Filters } from '@hitz-group/domain';
import { useNotification } from '../../../hooks/Notification';

export interface ReportingContextProps {
  cubejsApi: CubejsApi | undefined;
  filters: Filters | undefined;
}

export const ReportingContext = createContext<ReportingContextProps>({
  cubejsApi: undefined,
  filters: undefined,
});

export const ReportingProvider: React.FC = ({ children }) => {
  const [session] = useSession();
  const { showNotification } = useNotification();

  const REACT_APP_ANALYTICS_API_URL =
    process.env['REACT_APP_ANALYTICS_API_URL'] ||
    'http://localhost:4020/api/analytics/v1';

  const cubejsApi = Cubejs(session.token || '', {
    apiUrl: `${REACT_APP_ANALYTICS_API_URL}`,
    headers: {
      organization: session?.currentOrganization?.id || '',
    },
  });

  const { filters, error, getReportFilters } = useReportFilters();

  useEffect(() => {
    getReportFilters && getReportFilters();
  }, [getReportFilters]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  return (
    <ReportingContext.Provider value={{ cubejsApi, filters }}>
      {children}
    </ReportingContext.Provider>
  );
};
