import {
  StyleFn,
  Option,
  Variant,
  Product,
  DEFAULT_PRICING_GROUP,
  ProductPricingInput,
  Currency,
  CreateOptionInput,
  UpdateVariantInput,
  DEFAULT_TAX,
} from '@hitz-group/domain';
import { UpdateProductInput } from '@hitz-group/domain';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { View, ScrollView } from 'react-native';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import Button from '../../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { VariantProductRow } from './VariantProductRow';
import { VariantOptionsRow } from './VariantOptionsRow';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { useVariants } from '../../../../../hooks/app/variants/useVariants';
import { useTaxes } from '../../../../../hooks/app/useTaxes';
import { useOptions } from '../../../../../hooks/app/useOptions';
import { useProductPricings } from '../../../../../hooks/app/useProductPricings';
import { usePricingGroups } from '../../../../../hooks/app/usePricingGroups';
import findIndex from 'lodash/findIndex';
import { cloneDeep } from 'lodash';
import { Operation } from '../../../../../types/Operation';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { useModal } from '@hitz-group/rn-use-modal';
import { useFela } from 'react-fela';
import { useIsFocused } from '@react-navigation/native';
import { useRef } from 'react';

interface CustomVariantModel extends Variant {
  productPriceDictionary: Record<string, ProductPricingInput>;
  addProductPricing: {
    productId: string;
    pricings: {
      pricingGroupId: string;
      productPricing: ProductPricingInput;
    }[];
  }[];
}

const updateVariantsButtonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.border,
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  borderRadius: theme.radius.small,
});

const updateVariantsButtonLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  lineHeight: 21,
  letterSpacing: -0.5,
  alignSelf: 'center',
  textAlign: 'center',
  textTransform: 'uppercase',
});

const backOfficeContentStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const attributeButtonsContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
});

const createButtonStyle: StyleFn = () => ({
  marginRight: 'auto',
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const scrollContentWrapper: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const backOfficeContainerStyle: StyleFn = ({ theme, zIndex }) => ({
  width: 600,
  alignSelf: 'center',
  paddingTop: theme.padding.medium * 3,
  zIndex: zIndex,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

interface VariantSettingsForVariantProps {
  variantId: string;
}

const variantProps = {
  isDefault: 'isDefault',
};

export const VariantSettingsForVariant: React.FC<
  VariantSettingsForVariantProps
> = ({ variantId }) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { closeModal, showModal } = useModal();
  const [variant, setVariant] = useState({} as CustomVariantModel);
  const [copyVariant, setCopyVariant] = useState([] as Option[]);
  const { currency, formatCurrency } = useCurrency();
  const currencySymbol = formatCurrency(0).split('0.00')[0];
  const [selectedOptionId, setSelectedOptionId] = useState('');
  const [createdOptionValue, setCreatedOptionValue] = useState('');
  const variantRef = useRef<CustomVariantModel | undefined>();
  const { error: taxesError, loading: taxesLoading, taxesOptions } = useTaxes();
  const {
    variants,
    error: varErr,
    updateVariant,
    loading: varLoading,
    operation: varOperation,
    getVariantData,
  } = useVariants(variantId);

  const {
    options,
    error: optErr,
    createOption,
    createdOptionId,
    loading: optLoading,
    operation: optOperation,
    updateOption,
  } = useOptions();

  const {
    update: updatePP,
    error: errPP,
    loading: loadingPP,
    operation: operationPP,
    addBulkProductPricings: addPP,
  } = useProductPricings();

  const {
    error: prodErr,
    updateProducts,
    operation: prodOperation,
    loading: prodLoading,
    deleteProduct,
    deletedProductIds,
  } = useProducts();

  const {
    defaultPricingGroup,
    error: PGError,
    loading: PGLoading,
  } = usePricingGroups();

  const error = varErr || optErr || prodErr || errPP || PGError || taxesError;

  const loading =
    prodLoading ||
    loadingPP ||
    optLoading ||
    varLoading ||
    PGLoading ||
    taxesLoading;

  // default tax should be GST
  const defaultTax =
    taxesOptions?.filter(x => x.label === DEFAULT_TAX)?.[0]?.value || '';
  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!varLoading && !varErr && varOperation === Operation.UPDATE) {
      showNotification({
        success: true,
        message: translate('productSettings.variantUpdatedSuccessfully'),
      });
    }
  }, [varErr, varLoading, varOperation, showNotification, translate]);

  useEffect(() => {
    if (!varLoading && !varErr && varOperation === Operation.UPDATE) {
      addPP(variant.addProductPricing);
    }
  }, [varErr, varLoading, varOperation, addPP, variant]);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      getVariantData(variantId);
    }
  }, [isFocused, getVariantData, variantId]);

  useEffect(() => {
    if (!loadingPP && !errPP && operationPP === Operation.CREATE) {
      getVariantData(variantId);
    }
  }, [operationPP, errPP, loadingPP, getVariantData, variantId]);

  useEffect(() => {
    if (
      !optErr &&
      !optLoading &&
      createdOptionId &&
      optOperation === Operation.CREATE &&
      options[createdOptionId] &&
      selectedOptionId
    ) {
      setVariant(prev => {
        const variantData = { ...prev };
        const index = findIndex(variantData.options, {
          id: selectedOptionId,
        });
        if (index >= 0) {
          variantData.options[index] = {
            id: createdOptionId,
            key: options[createdOptionId].key,
            values: options[createdOptionId].values,
          };
        }
        return variantData;
      });
      showNotification({
        success: true,
        message: translate('productSettings.optionCreatedSuccessfully'),
      });
    }
  }, [
    optErr,
    optLoading,
    createdOptionId,
    optOperation,
    options,
    showNotification,
    translate,
    selectedOptionId,
  ]);

  useEffect(() => {
    if (
      !optErr &&
      !optLoading &&
      optOperation === Operation.UPDATE &&
      selectedOptionId &&
      createdOptionValue
    ) {
      showNotification({
        success: true,
        message: translate('productSettings.optionUpdatedSuccessfully'),
      });
      setVariant(prev => {
        const variantData = { ...prev };
        const index = findIndex(variantData.options, {
          id: selectedOptionId,
        });
        if (index >= 0) {
          variantData.options[index] = {
            ...variantData.options[index],
            values: [...variantData.options[index].values, createdOptionValue],
          };
        }
        return variantData;
      });
      setCreatedOptionValue('');
    }
  }, [
    optErr,
    optLoading,
    optOperation,
    options,
    showNotification,
    translate,
    selectedOptionId,
    createdOptionValue,
  ]);

  useEffect(() => {
    if (
      !prodLoading &&
      !prodErr &&
      prodOperation === Operation.UPDATE &&
      !loadingPP &&
      !errPP &&
      operationPP === Operation.UPDATE
    ) {
      showNotification({
        success: true,
        message: translate('productSettings.updatedSuccessfully'),
      });
      getVariantData(variantId);
    }
  }, [
    prodErr,
    prodOperation,
    showNotification,
    prodLoading,
    operationPP,
    errPP,
    loadingPP,
    translate,
    getVariantData,
    variantId,
  ]);

  useEffect(() => {
    if (
      !prodLoading &&
      !prodErr &&
      prodOperation === Operation.DELETE &&
      deletedProductIds?.length
    ) {
      if (deletedProductIds?.length && variant && variant?.products?.length) {
        const variantData = { ...variant };
        const index = findIndex(variantData.products, {
          id: deletedProductIds[0],
        });
        if (index >= 0) {
          closeModal();
          showNotification({
            success: true,
            message: translate('productSettings.productDeletedSuccessfully'),
          });
          getVariantData(variantId);
        }
      }
    }
  }, [
    prodErr,
    prodLoading,
    prodOperation,
    showNotification,
    translate,
    closeModal,
    variant,
    deletedProductIds,
    getVariantData,
    variantId,
  ]);

  useEffect(() => {
    if (variantId && variants && variants[variantId]) {
      const variantTemp = { ...variants[variantId] };
      const productPriceInputDic: Record<string, ProductPricingInput> = {};
      const addProductPricings: {
        productId: string;
        pricings: {
          pricingGroupId: string;
          productPricing: ProductPricingInput;
        }[];
      }[] = [];
      variantTemp.products.forEach(prod => {
        const defaultPG = prod.pricingGroups.find(
          x => x.name === DEFAULT_PRICING_GROUP && x,
        );
        defaultPG?.prices.forEach(eachPrice => {
          productPriceInputDic[prod.id] = {
            pricingGroupId: defaultPG.id,
            product: prod.id,
            id: eachPrice.id,
            costPrice: {
              amount: eachPrice.costPrice?.amount || 0,
              currency: eachPrice?.costPrice?.currency || currency,
            },
            sellingPrice: {
              amount: eachPrice.sellingPrice?.amount || 0,
              currency: eachPrice.sellingPrice?.currency || currency,
            },
            sellingTax: defaultTax,
          } as ProductPricingInput;
        });
        if (!defaultPG && defaultPricingGroup.id) {
          addProductPricings.push({
            pricings: [
              {
                pricingGroupId: defaultPricingGroup.id,
                productPricing: {
                  pricingGroupId: defaultPricingGroup.id,
                  product: prod.id,
                  costPrice: {
                    amount: 0,
                    currency: currency,
                  },
                  sellingPrice: {
                    amount: 0,
                    currency: currency,
                  },
                  sellingTax: defaultTax,
                  taxInclusive: true,
                } as ProductPricingInput,
              },
            ],
            productId: prod.id,
          });
        }
      });
      if (!variantTemp?.options?.length) {
        variantTemp.options.push({
          id: '',
          key: '',
          values: [],
        });
      }
      setVariant({
        ...variantTemp,
        productPriceDictionary: productPriceInputDic,
        addProductPricing: addProductPricings,
      });
      if (!copyVariant.length) {
        setCopyVariant([...variantTemp.options]);
      }
    }
  }, [
    variants,
    variantId,
    currency,
    defaultPricingGroup,
    copyVariant,
    defaultTax,
  ]);

  const allKeys = useMemo(() => {
    const optionsAvailable = Object.keys(options);
    const optionsInVariant = (variant?.options || []).map(x => x.id);
    const keys: { value: string; label: string }[] = [];
    optionsAvailable.forEach(x => {
      if (!optionsInVariant.includes(x)) {
        keys.push({
          label: options[x].key,
          value: x,
        });
      }
    });
    return keys;
  }, [options, variant]);

  const onCreate = useCallback(() => {
    const variantData = { ...variant };
    const optionsAvailable = Object.keys(options);
    if (!variantData.options) {
      variantData.options = [];
    }
    const optionsInVariant = variantData.options.map(x => x.id);
    let optionToAdd = '';
    optionsAvailable.forEach(x => {
      if (!optionToAdd && !optionsInVariant.includes(x)) {
        optionToAdd = x;
      }
    });
    if (optionToAdd) {
      variantData.options.push({
        id: optionToAdd,
        key: options[optionToAdd].key,
        values: options[optionToAdd].values,
      });
    } else {
      variantData.options.push({
        id: '',
        key: '',
        values: [],
      });
    }
    setVariant(variantData);
  }, [variant, options]);

  const onChangeValues = useCallback(
    (id, values) => {
      const variantData = cloneDeep(variant);
      const index = findIndex(variantData.options, { id });
      if (index >= 0) {
        variantData.options[index].values = values;
        setVariant(() => variantData);
      }
    },
    [variant],
  );

  useEffect(() => {
    variantRef.current = variant;
    return () => {
      variantRef.current = undefined;
    };
  }, [variant]);

  const onChangeProductDetails = useCallback(
    (id, prop, value) => {
      const variantData = { ...variant };
      const index = findIndex(variantData.products, { id });
      if (index >= 0) {
        if (prop === variantProps.isDefault) {
          // remove old default variant
          const previousDefaultIndex = variantData.products.findIndex(
            prod => prod.isDefault,
          );

          variantData.products[previousDefaultIndex] = {
            ...variantData.products[previousDefaultIndex],
            isDefault: false,
          };
        }
        variantData.products[index] = {
          ...variantData.products[index],
          [prop]: value,
        };
        setVariant(variantData);
      }
    },
    [variant],
  );

  const onChangeProductPricings = useCallback(
    (id, prop, value) => {
      const variantData = { ...variant };
      if (prop === 'costPrice' || prop === 'sellingPrice') {
        const temp = value.includes(currencySymbol)
          ? value.split(currencySymbol)[1]
          : value;
        value = { currency: currency as Currency, amount: temp };
      }

      variantData.productPriceDictionary[id] = {
        ...variantData.productPriceDictionary[id],
        product: id,
        pricingGroupId: defaultPricingGroup.id,
        [prop]: value,
      };
      if (!variantData.productPriceDictionary[id]?.id) {
        const index = findIndex(variantData.addProductPricing, {
          productId: id,
        });
        variantData.addProductPricing[index].pricings[0].productPricing = {
          ...variantData.addProductPricing[index].pricings[0].productPricing,
          [prop]: value,
        };
      }
      setVariant(variantData);
    },
    [variant, currency, currencySymbol, defaultPricingGroup],
  );

  const onSaveProducts = useCallback(() => {
    const productPricingsInput = Object.values(variant.productPriceDictionary);
    const productInputs: UpdateProductInput[] = variant.products.map(
      prod =>
        ({
          id: prod.id,
          isSellable: prod.isSellable || false,
          isDefault: prod.isDefault || false,
          name: prod.name,
        } as UpdateProductInput),
    );
    const updateProdPricingInput: ProductPricingInput[] = [];

    productPricingsInput.forEach(x => {
      if (x && x.id) {
        updateProdPricingInput.push({
          ...x,
          sellingPrice: {
            ...x.sellingPrice,
            amount: +x.sellingPrice.amount,
          },
          costPrice: {
            ...x.costPrice,
            amount: +x.costPrice.amount,
          },
        });
      }
    });
    updateProducts(productInputs);
    updatePP(updateProdPricingInput);
  }, [variant, updateProducts, updatePP]);

  const onAddOption = useCallback(
    (name, selectedOptionId) => {
      const input = { key: name, values: [] } as CreateOptionInput;
      if (selectedOptionId) {
        setSelectedOptionId(selectedOptionId);
      }
      createOption(input);
    },
    [createOption],
  );

  const onAddOptionValue = useCallback(
    (id, name) => {
      const currentOption = { ...options[id] };
      if (currentOption && !currentOption?.values?.includes(name)) {
        currentOption['values'] = [...currentOption.values, name];
        setSelectedOptionId(id);
        setCreatedOptionValue(name);
        updateOption({
          id: currentOption.id,
          key: currentOption.key,
          values: currentOption.values,
        });
      }
    },
    [options, updateOption],
  );

  const onDeleteOptionRow = useCallback(
    id => {
      const variantData = { ...variant };
      const index = findIndex(variantData.options, { id });
      if (index >= 0) {
        variantData.options.splice(index, 1);
        setVariant(variantData);
      }
    },
    [variant],
  );

  const updateVariantDetails = useCallback(() => {
    const variantData = variantRef.current;
    const updateInput = {
      id: variantData?.id,
      options: variantData?.options.map(x => ({
        id: x?.id,
        values: x?.values,
      })),
    } as UpdateVariantInput;
    updateVariant(updateInput);
  }, [updateVariant]);

  const onUpdateProduct = useCallback(() => {
    showModal(
      <ConfirmationDialog
        title={translate('productSettings.updateVariant')}
        message={translate('productSettings.updateVariantOptions')}
        onConfirm={() => {
          updateVariantDetails();
          closeModal();
        }}
      />,
    );
  }, [translate, showModal, updateVariantDetails, closeModal]);

  const onDeleteProduct = useCallback(
    (productId, name) => {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', { label: name })}
          onConfirm={() => deleteProduct(productId)}
        />,
      );
    },
    [translate, deleteProduct, showModal],
  );

  const onChangeSelectedOption = useCallback(
    (prevId, selectedId) => {
      const variantData = { ...variant };
      const index = findIndex(variantData.options, { id: prevId });
      if (index >= 0) {
        variantData.options[index] = {
          ...options[selectedId[0]],
        };
        setVariant(variantData);
      }
    },
    [options, variant],
  );

  const requestDeleteModal = useCallback(
    (onChangeOfDropDown, isSelected, value) => {
      let canShowDropDown = false;
      if (value && variants && variants[variantId]) {
        const allProducts = variants[variantId].products;
        if (
          allProducts.some(x => x.optionValues.some(y => y.value === value))
        ) {
          canShowDropDown = true;
        }
      }
      if (canShowDropDown) {
        showModal(
          <ConfirmationDialog
            title={translate('dialog.deleteTitle')}
            message={translate('productSettings.deleteVariantValues')}
            onConfirm={async () => {
              await onChangeOfDropDown(isSelected, value);
              await new Promise(resolve => setTimeout(resolve, 50));
              await updateVariantDetails();
              closeModal();
            }}
          />,
        );
      } else {
        onChangeOfDropDown(isSelected, value);
      }
    },
    [
      showModal,
      translate,
      closeModal,
      updateVariantDetails,
      variantId,
      variants,
    ],
  );

  const requestAddModal = useCallback(
    (onChangeOfDropDown, isSelected, value) => {
      showModal(
        <ConfirmationDialog
          title={translate('productSettings.addVariant')}
          message={translate('productSettings.addVariantValues')}
          onConfirm={async () => {
            await onChangeOfDropDown(isSelected, value);
            await new Promise(resolve => setTimeout(resolve, 50));
            await updateVariantDetails();
            closeModal();
          }}
        />,
      );
    },
    [showModal, closeModal, updateVariantDetails, translate],
  );

  if (loading) return <LoadingIndicator />;

  const updateBtnDisabled =
    JSON.stringify(variant.options) === JSON.stringify(copyVariant)
      ? true
      : false;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.variantSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollContentWrapper)}>
          <BackOfficeSection
            contentContainerStyle={css(backOfficeContentStyle)}
            containerStyle={css(
              backOfficeContainerStyle({ theme, zIndex: 100 }),
            )}
            title={translate('productSettings.attributes')}
            titleDescription={translate(
              'productSettings.attributesDescription',
            )}
            action={
              <View style={css(attributeButtonsContainerStyle)}>
                <View style={css(createButtonStyle)}>
                  <BackOfficeCreateNewButton onPress={onCreate} />
                </View>
                <Button
                  title={translate('productSettings.updateVariants')}
                  containerStyle={css(updateVariantsButtonStyle)}
                  labelStyle={css(updateVariantsButtonLabelStyle)}
                  onPress={onUpdateProduct}
                  disabled={updateBtnDisabled}
                />
              </View>
            }
          >
            <TableComponent
              columns={[
                {
                  title: translate('productSettings.attribute'),
                  width: 140,
                  containerStyle: { paddingLeft: theme.spacing.big / 2 },
                },
                {
                  title: translate('productSettings.values'),
                  flex: 1,
                  alignItems: 'flex-start',
                  containerStyle: { paddingLeft: theme.spacing.medium },
                },
              ]}
              data={variant.options || []}
              normalRows
              columnContainerStyle={css(columnContainerStyle)}
              renderRow={(item: Option, index: number): React.ReactNode => (
                <VariantOptionsRow
                  allKeys={allKeys}
                  selectedOption={item}
                  allValues={options[item.id]?.values}
                  key={index}
                  index={index}
                  onValuesChange={onChangeValues}
                  onAddOption={onAddOption}
                  onAddOptionValue={onAddOptionValue}
                  onDeleteOptionRow={onDeleteOptionRow}
                  onChangeSelectedOption={onChangeSelectedOption}
                  isDisabled={false}
                  deleteRequestModal={requestDeleteModal}
                  addRequestModal={requestAddModal}
                />
              )}
            />
          </BackOfficeSection>

          <BackOfficeSection
            contentContainerStyle={css(backOfficeContentStyle)}
            containerStyle={css(
              backOfficeContainerStyle({ theme, zIndex: 10 }),
            )}
            title={translate('productSettings.variants')}
            iconColor={theme.colors.blue}
            icon={'info-circle'}
          >
            <TableComponent
              columns={[
                {
                  title: translate('productSettings.default'),
                  width: 38,
                  containerStyle: { paddingLeft: theme.spacing.big / 2 },
                },
                {
                  title: translate('productSettings.name'),
                  width: 228,
                },
                {
                  title: translate('productSettings.costPrice'),
                  width: 80,
                },
                {
                  title: translate('productSettings.sellPrice'),
                  width: 80,
                },
                { title: '', width: 30 },
              ]}
              data={variant.products || []}
              normalRows
              columnContainerStyle={css(columnContainerStyle)}
              renderRow={(item: Product, index: number): React.ReactNode => (
                <VariantProductRow
                  variantId={variant.id}
                  product={item}
                  key={index}
                  pricings={variant.productPriceDictionary[item.id]}
                  currencySymbol={currencySymbol}
                  onChangeProductDetails={onChangeProductDetails}
                  onChangeProductPricings={onChangeProductPricings}
                  onDeleteProduct={onDeleteProduct}
                />
              )}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onSaveProducts}
          />
        </View>
      </View>
    </>
  );
};
