import {
  PushNotificationWorkerActionData,
  WorkerAction,
  WorkerActionResult,
  WorkerActionResultStatus,
  WorkerInput,
} from './utils';
import { Thread } from 'react-native-threads';
import { OrderEvent } from '@hitz-group/domain';
import { Platform } from 'react-native';
import * as storage from '../storage/interface';
import {
  WORKER_MESSAGES_KEY,
  PUSH_NOTIFICATION_MESSAGE_KEY,
} from '../state/preferences';
type OrderEventMap = Record<string, OrderEvent[]>;
class BackgroundWorker {
  counter = 0;
  worker: Worker;

  constructor() {
    try {
      if (Platform.OS === 'web') {
        this.worker = new Worker('./worker.web.js', { type: 'module' });
      } else {
        this.worker = new Thread('src/workers/worker.js');
      }
    } catch {
      this.worker = undefined as unknown as Worker;
      return;
    }
    this.worker.onmessage = this.receive.bind(this);
  }

  send({ action, data, priority }: WorkerInput) {
    const requestId = `${Date.now()}${++this.counter}`;
    const workerInput: WorkerInput = { requestId, action, data, priority };
    return this.worker.postMessage(JSON.stringify(workerInput));
  }

  private static _shouldPersistMessage(result: WorkerActionResult) {
    return (
      result &&
      result.status === WorkerActionResultStatus.ERROR &&
      result.action &&
      [
        WorkerAction.PRINT,
        WorkerAction.PRINT_BILL,
        WorkerAction.PRINT_KITCHEN_DOCKET,
        WorkerAction.PRINT_RESEND_KITCHEN_DOCKET,
      ].includes(result.action)
    );
  }

  async persistNotificationEvents(
    eventData: OrderEvent,
  ): Promise<OrderEvent[]> {
    if (!(eventData.action && eventData.orderId)) return [];
    let orderMap: OrderEventMap = {};
    orderMap =
      ((await storage.getItem(
        PUSH_NOTIFICATION_MESSAGE_KEY,
      )) as OrderEventMap) || {};
    if (Object.keys(orderMap).length === 0 && orderMap.constructor === Object) {
      await storage.setItem(PUSH_NOTIFICATION_MESSAGE_KEY, {});
    }
    const orderEventArray: Array<OrderEvent> =
      orderMap[eventData.orderId] || [];
    orderEventArray.push(eventData);
    orderEventArray.sort((a, b) => a.timestamp - b.timestamp);
    orderMap[eventData.orderId] = orderEventArray;
    await storage.setItem(PUSH_NOTIFICATION_MESSAGE_KEY, orderMap);
    return orderMap[eventData.orderId];
  }

  async receive(event: WorkerActionResult | { data: WorkerActionResult }) {
    const data = (Platform.OS === 'web' ? event.data : event) as string;
    try {
      const result = (data && JSON.parse(data)) as WorkerActionResult;
      if (result.action === WorkerAction.PUSH_NOTIFICATION) {
        const payload = result?.data as PushNotificationWorkerActionData;
        if (payload?.orderEvent) {
          const orderEvent = JSON.parse(payload.orderEvent);
          await this.persistNotificationEvents(orderEvent);
        }
        return;
      }
      const responses =
        (await storage.getItem<WorkerActionResult[]>(WORKER_MESSAGES_KEY)) ||
        [];
      if (BackgroundWorker._shouldPersistMessage(result)) {
        responses.push(result);
        await storage.setItem(WORKER_MESSAGES_KEY, responses);
      }
    } catch {
      await storage.setItem(WORKER_MESSAGES_KEY, []);
    }
  }
}

export default BackgroundWorker;
