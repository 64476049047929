import { RenderProps, StyleFn } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { FelaComponent } from 'react-fela';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import { useNotification } from '../../hooks/Notification';
import IconButton from '../Button/IconButton';
import { NotificationList } from '../Notification/NotificationList';
import PopupView from '../PopupView/PopupView';

export interface ModalProps {
  title?: string;
  children: React.ReactNode;
  onDismiss?: () => void;
  showCloseButton?: boolean;
  contentStyle?: ViewStyle;
  closeButtonStyle?: ViewStyle;
  closeIconSize?: number;
  headerTextStyle?: ViewStyle;
  customBodyStyle?: StyleFn;
}

const styles = StyleSheet.create({
  header: {
    // For reference not removing below code
    // flex: 1,
    // alignItems: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
  },
  container: {
    alignSelf: 'center',
    alignItems: 'flex-start',
  },
});

interface CloseButtonProps {
  onPressClose?: () => void;
  closeButtonStyle?: ViewStyle;
  closeIconSize?: number;
  testID?: string;
}

const closeButtonDefaultStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  top: theme.spacing.small * 3.25,
  right: theme.spacing.small * 2.5,
});

const CloseButton: React.FC<CloseButtonProps> = ({
  onPressClose,
  closeButtonStyle,
  closeIconSize,
  testID,
}) => (
  <FelaComponent style={closeButtonDefaultStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <IconButton
        icon="times"
        onPress={onPressClose}
        primary
        iconSize={closeIconSize || 25}
        containerStyle={[style, closeButtonStyle] as ViewStyle}
        testID={testID}
      />
    )}
  </FelaComponent>
);

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.medium,
  flex: 1,
  lineHeight: 24,
  letterSpacing: -0.5,
});

interface HeaderProps {
  title?: string;
  headerTextStyle?: ViewStyle;
}

const Header: React.FC<HeaderProps> = ({ title, headerTextStyle }) => (
  <FelaComponent style={titleStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <View style={styles.header}>
        <Text style={[style, headerTextStyle]}>{title}</Text>
      </View>
    )}
  </FelaComponent>
);

interface BodyProps {
  children: React.ReactNode;
  customBodyStyle?: StyleFn;
}

const bodyStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  justifyContent: 'flex-start',
  marginTop: theme.spacing.medium,
  paddingTop: theme.padding.medium,
  paddingBottom: theme.padding.small * 3,
});

const Body: React.FC<BodyProps> = ({
  children,
  customBodyStyle,
}: BodyProps) => (
  <FelaComponent style={[bodyStyle, customBodyStyle]}>
    {({ style }: RenderProps): React.ReactFragment => (
      <View style={style}>{children}</View>
    )}
  </FelaComponent>
);

const Modal: React.FC<ModalProps> = ({
  children,
  title,
  onDismiss,
  contentStyle,
  showCloseButton,
  closeButtonStyle,
  closeIconSize,
  headerTextStyle,
  customBodyStyle,
}: ModalProps) => {
  const { closeNotification, notifications, closeAllNotifications } =
    useNotification();

  const onPressClose = useCallback(() => {
    onDismiss && onDismiss();
    closeAllNotifications();
  }, [closeAllNotifications, onDismiss]);

  return (
    <>
      <NotificationList
        notifications={notifications}
        onCloseNotification={closeNotification}
      />
      <PopupView containerStyle={[styles.container, contentStyle]}>
        {title && <Header title={title} headerTextStyle={headerTextStyle} />}
        {showCloseButton && (
          <CloseButton
            onPressClose={onPressClose}
            closeButtonStyle={closeButtonStyle}
            closeIconSize={closeIconSize}
            testID="modal-close-button"
          />
        )}
        <Body customBodyStyle={customBodyStyle}>{children}</Body>
      </PopupView>
    </>
  );
};

Modal.defaultProps = {
  showCloseButton: true,
};

export default Modal;
