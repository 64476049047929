import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';
import scale from '../../../../../../common/theme';

export const PrinterDetailsStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        bottomSpace: {
          height: scale.moderateScale(30),
        },
        dropDownItemsContainerStyle: {
          height: 38 * 3,
        },
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        generalSettingsContainerWrapper: {
          width: '100%',
          alignItems: 'center',
          backgroundColor: theme.colors.white,
        },
        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
        },
        saveButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginLeft: 'auto',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
          alignSelf: 'auto',
        },
        testPrinterButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.skyBlue,
          alignSelf: 'auto',
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
        testPrinterTitleStyle: {
          color: theme.colors.blue,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
        checkIconContainer: {
          borderColor: theme.colors.green,
          borderWidth: 2,
          marginRight: theme.spacing.big / 2,
          height: 17,
          width: 17,
        },
        partialCheckIconContainer: {
          borderColor: theme.colors.blue,
          borderWidth: 2,
          marginRight: theme.spacing.big / 2,
          height: 17,
          width: 17,
        },
        unCheckContainer: {
          borderColor: theme.colors.paragraphLight,
          borderWidth: 2,
          marginRight: theme.spacing.big / 2,
          height: 17,
          width: 17,
        },
        accordionContainerStyle: {
          paddingVertical: theme.spacing.small,
          borderBottomColor: theme.colors.boxBorder,
          borderBottomWidth: 1,
        },
        accordionTitleContainerStyle: { flex: 1, flexDirection: 'row' },
        childContainerStyle: {
          marginTop: theme.spacing.big / 2,
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: 545,
          justifyContent: 'space-between',
        },
        checkBoxContainerStyle: {
          flex: 1,
          justifyContent: 'flex-start',
          width: 100,
        },
        assignedCountStyle: {
          alignSelf: 'flex-end',
          marginRight: 35,
          margin: 'auto',
        },
        productsContainerStyle: {
          justifyContent: 'flex-start',
          width: 260,
        },
        loadMoreWrapperStyle: {
          width: '100%',
          marginVertical: theme.spacing.medium,
        },
        loadMoreContainerStyle: {
          justifyContent: 'center',
          alignSelf: 'center',
          backgroundColor: theme.colors.successLight,
          width: 100,
          height: 38,
        },
        loadMoreLabelStyle: {
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.small,
          textTransform: 'none',
          color: theme.colors.paragraph,
        },
        searchContainerStyle: {
          width: '100%',
          height: 38,
          justifyContent: 'center',
          backgroundColor: theme.colors.white,
          paddingLeft: theme.spacing.small / 2,
          borderColor: theme.colors.boxBorder,
          borderWidth: 1,
          marginBottom: theme.spacing.medium,
        },
        searchTextInputStyle: {
          width: '100%',
          height: 38,
          color: theme.colors.paragraph,
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.small,
        },
        formContainerStyleSmall: {
          width: 545,
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        },
        formInputContainerStyle: {
          justifyContent: 'space-between',
          width: 260,
          height: theme.input.height,
          marginBottom: theme.spacing.big / 2,
        },
        dropdownContainerStyle: {
          justifyContent: 'space-between',
          width: 260,
          height: theme.input.height,
          marginBottom: theme.spacing.big,
        },
        extraPopoverStyle: {
          width: 260,
        },
        checkBoxTitleStyle: {
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.small,
          textTransform: 'none',
        },
        printerDetailsSectionStyle: {
          marginTop: theme.spacing.small,
          marginBottom: theme.spacing.medium,
          zIndex: 20,
        },
        assignProductsSectionStyle: {
          marginTop: theme.spacing.small,
          marginBottom: theme.spacing.medium,
        },
        formLabelStyle: {
          paddingHorizontal: theme.spacing.small,
        },
        buttonIconStyle: {
          color: theme.colors.success,
        },
        checkIconStyle: {
          color: theme.colors.success,
        },
        partialCheckIconStyle: {
          color: theme.colors.blue,
        },
        unCheckIconStyle: {
          color: theme.colors.paragraph,
        },
      }),
    [theme],
  );
};
