import { StyleFn } from '@hitz-group/domain/dist';
import { StyleSheet } from 'react-native';

export const pageContainerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  backgroundColor: theme.colors.background,
});

export const catalogStyle: StyleFn = () => ({
  flex: 1,
});

export const headerButtonContainerStyle: StyleFn = () => ({
  width: 280,
  justifyContent: 'flex-start',
});

// export const titleContainerStyle: StyleFn = ({ theme }) => ({
//   width: 413,
//   backgroundColor: theme.colors.primaryDarkest,
//   padding: theme.spacing.small * 1.5,
//   borderRadius: theme.radius.small,
// });

export const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

export const styles = StyleSheet.create({
  headerTitle: {
    width: 300,
    height: 40,
  },
  headerRight: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  orderActionButton: {
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    width: 138,
  },
  refundButton: {
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    height: 80,
    width: 280,
  },
  labelStyle: {
    paddingLeft: 8,
  },
});
