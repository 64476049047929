import { roundOffByValue } from '@hitz-group/client-utils';
import {
  AddOrderSurcharge,
  AssignCustomerEvent,
  Customer,
  FeatureContext,
  Features,
  Order,
  OrderAction,
  OrderPaymentStatus,
  PaymentAction,
  PaymentMode,
  PaymentSubScreen,
  PaymentType,
  Resource,
  StyleFn,
} from '@hitz-group/domain';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { getAdjustmentValue, isOrderEditable } from '@hitz-group/order-helper';
import { useModal } from '@hitz-group/rn-use-modal';
import { round } from 'lodash';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFela } from 'react-fela';
import { Text, View } from 'react-native';
import CustomerAccountIdentityModal from '../../components/Modals/Customer/ComfirmAccountIdentity/ConfirmAccountIdentityModal';
import PaymentSurcharge from '../../components/Modals/PaymentSurcharge/PaymentSurcharge';
import { usePayments } from '../../hooks/app/usePayments';
import { usePaymentTypes } from '../../hooks/app/usePaymentTypes';
import usePOSUserAuthorization from '../../hooks/app/users/usePOSUserAuthorization';
import { useSession } from '../../hooks/app/useSession';
import { useCartContext as useCart } from '../../hooks/CartProvider';
import { useNotification } from '../../hooks/Notification';
import {
  OrderPaymentDetails,
  PaymentActionProp,
} from '../../screens/POS/Orders/PaymentScreen';
import { getFastCashOptions } from '../../utils/fastCashOptions';
import { stripProperties } from '../../utils/stripObjectProps';
import Button from '../Button/Button';
import SelectBar from '../Button/SelectBar';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import EnableOnAccountModal from '../Modals/Customer/EnableOnAccount/EnableOnAccountModal';
import SetAmountKeypad from '../Modals/SetAmountKeypad/SetAmountKeypad';
import SetSplitPaymentCount from '../Modals/SetSplitPaymentCount/SetSplitPaymentCount';
import MoneyInputKeypad from '../MoneyInputKeypad/MoneyInputKeypad';
import PaymentAmountOptions from './PaymentAmountOptions';
import {
  AdditionalPaymentInfo,
  DEFAULT_PAYMENT_CURRENCY,
  OOLIO_PAY_PAYMENT_TYPE,
  PAYMENT_INPROGRESS_MESSAGES,
  POS_PAYMENT_CANCEL_MESSAGES,
  TempPaymentInfo,
} from '../../types/Common';
import { useNetworkStatusVar } from '../../hooks/app/useNetworkStatusVar';
import { useCheckFeatureEnabled } from '../../hooks/app/features/useCheckFeatureEnabled';
import { generateCustomFlatAdjustment } from '../../utils/adjustmentHelper';

const nanoid = customAlphabet(alphanumeric, 10);
export const SPLIT_PAYMENT_EQUAL_OPTIONS = [
  {
    splitNumber: 2,
    label: '2',
  },
  {
    splitNumber: 3,
    label: '3',
  },
  {
    splitNumber: 4,
    label: '4',
  },
  {
    splitNumber: undefined,
    label: 'Other',
  },
];

export interface PaymentOptionsProps {
  onPressPay?: (
    paymentType: PaymentType,
    amount: number,
    surchargeAmount?: number,
    customer?: Customer,
    additionalInfo?: AdditionalPaymentInfo,
  ) => void;
  onPaymentProcessed?: (additionalInfo: AdditionalPaymentInfo) => void;
  enableRoundOff?: boolean;
  roundOffValue?: number;
  customer?: Customer;
  orderPaymentDetails: OrderPaymentDetails;
  dispatch: React.Dispatch<PaymentActionProp>;
  customerBalancePayment?: boolean;
  customerAmount?: number;
}

export interface OtherPaymentType extends PaymentType {
  color?: string;
}
const containerStyle: StyleFn = ({ theme }) => ({
  width: 600,
  height: 450,
  maxHeight: 550,
  overflow: 'hidden',
  borderRadius: theme.radius.small,
});

const loadingContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: 90,
  height: 50,
  overflow: 'hidden',
  borderRadius: theme.radius.small,
});

const willRemainingTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.blue,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
});

const editTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing.small / 2,
  color: theme.colors.paragraph,
  fontFamily: theme.font.medium,
  lineHeight: 21,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.small,
});

const loadingTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing.small / 2,
  color: theme.colors.paragraph,
  fontFamily: theme.font.medium,
  lineHeight: 21,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.larger,
});

const selectBarStyle: StyleFn = ({}) => ({
  height: 40,
  marginTop: 20,
});

const itemLabelStyle: StyleFn = ({}) => ({
  textTransform: 'capitalize',
});

const cashCardTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  fontFamily: theme.font.medium,
  color: theme.colors.paragraph,
  lineHeight: 21,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.small,
  textTransform: 'uppercase',
  marginVertical: 20,
});

const buttonOptionsContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const buttonOptionContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const optionButtonStyle: StyleFn = ({ theme }) => ({
  width: 110,
  margin: theme.padding.small,
  marginVertical: theme.padding.small,
});

const labelStyle: StyleFn = ({ theme, disabled }) => ({
  color: disabled ? theme.colors.paragraph : theme.colors.white,
  textTransform: 'capitalize',
});

const splitButtonStyle: StyleFn = ({}) => ({
  width: 600,
});

const splitEqualButtonStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  marginHorizontal: theme.padding.small,
  marginVertical: theme.padding.small,
});

const willRemainingAmountContainer: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.lightBlueSelect,
  padding: 12,
  marginTop: 10,
});

const verifyPaymentButtonStyle: StyleFn = ({ theme }) => ({
  width: 110,
  marginHorizontal: 'auto',
  marginVertical: theme.padding.small,
  backgroundColor: theme.colors.skyBlue,
});

const verifyPaymentLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  textTransform: 'capitalize',
});

const cancelPaymentButtonStyle: StyleFn = ({ theme }) => ({
  width: 110,
  marginHorizontal: 'auto',
  marginVertical: theme.padding.small,
  backgroundColor: theme.colors.greyLight,
});

const cancelPaymentLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  textTransform: 'capitalize',
});

const paymentActionContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big,
  overflow: 'hidden',
  flexDirection: 'row',
  marginHorizontal: '30%',
});

const PaymentOptions: React.FC<PaymentOptionsProps> = ({
  onPressPay,
  onPaymentProcessed,
  enableRoundOff,
  roundOffValue,
  customer,
  dispatch,
  customerAmount,
  orderPaymentDetails,
}: PaymentOptionsProps) => {
  const { css, theme } = useFela();
  const { formatCurrency } = useCurrency();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { paymentTypes, status } = usePaymentTypes();
  const { showModal, closeModal } = useModal();
  const { order, updateCart } = useCart();
  const [, setAssignedCustomer] = useState<Partial<Customer>>();
  const { canI } = usePOSUserAuthorization();
  const [session] = useSession();
  const paymentDetailsRef = useRef<TempPaymentInfo | undefined>(undefined);

  const isFeatureEnabled = useCheckFeatureEnabled();
  const isLoyaltyEnabled = isFeatureEnabled(
    Features.LOYALTY,
    FeatureContext.ORGANIZATION,
  );

  const networkStatus = useNetworkStatusVar();
  // Using useRef here because callback sent to showModal is getting previous state
  // State was not getting updated in the callback function sent to showModal
  const networkStatusRef = useRef<boolean>(true);
  networkStatusRef.current = networkStatus;

  const pendingPayment = useMemo(() => {
    if (
      order?.payments &&
      order?.payments?.length > 0 &&
      isOrderEditable(order?.status)
    ) {
      const pendingPayment = order?.payments.find(
        payment => payment.status === OrderPaymentStatus.PENDING,
      );

      if (pendingPayment) {
        paymentDetailsRef.current = {
          paymentType: pendingPayment.paymentType as PaymentType,
          orderAmount: pendingPayment.amount,
          requestId: pendingPayment.paymentRequestId,
        };
      }

      return pendingPayment;
    }

    return undefined;
  }, [order?.payments, order?.status]);

  const {
    loading: isPaymentLoading,
    cancellationLoading,
    error: paymentError,
    paymentResponse,
    cancellationResponse,
    initiatePayment,
    verifyPayment,
    resetPaymentResponse,
    cancelPayment,
    resetCancellationResponse,
  } = usePayments();

  const {
    paymentOption,
    processingAmount,
    remainingDueAmount,
    totalDueAmount,
    nthPayment,
    activeScreen,
    isCustomerBalancePayment,
  } = orderPaymentDetails;
  const displayAmount = processingAmount;

  const [allowOnAccountAmount, setAllowOnAccountAmount] = useState(0);
  const [allowOnAccountAmountOrderLimit, setAllowOnAccountAmountOrderLimit] =
    useState(0);

  const otherPaymentTypes: OtherPaymentType[] = useMemo(
    () => paymentTypes?.filter((x: { name: string }) => x.name !== 'Cash'),
    [paymentTypes],
  );
  const getPaymentTypeByName = useCallback(
    (name: string): PaymentType => {
      return paymentTypes.find(
        (paymentType: { name: string }) =>
          paymentType.name.toLowerCase() == name.toLowerCase(),
      ) as PaymentType;
    },
    [paymentTypes],
  );

  useEffect((): void => {
    if (status.error) {
      showNotification({
        error: true,
        message: status.error,
      });
    }
  }, [status.error, showNotification]);

  useEffect((): void => {
    if (paymentError) {
      showNotification(
        {
          error: true,
          message: paymentError,
        },
        false,
      );
    }
  }, [paymentError, showNotification]);

  const applySurcharge = useCallback(
    (paymentType, surcharge) => {
      if (isCustomerBalancePayment) {
        onPressPay && onPressPay(paymentType, customerAmount as number);
      }
      if (order && surcharge?.amount) {
        updateCart<AddOrderSurcharge>(OrderAction.ORDER_ADD_ADJUSTMENT, {
          adjustment: surcharge,
        });
        const surchargeAmount = getAdjustmentValue(
          processingAmount,
          [surcharge],
          { adjustmentType: surcharge.adjustmentType },
        );

        onPressPay &&
          onPressPay(
            paymentType,
            processingAmount + surchargeAmount,
            surchargeAmount,
          );
      } else {
        onPressPay && onPressPay(paymentType, processingAmount);
      }
    },
    [
      isCustomerBalancePayment,
      order,
      onPressPay,
      customerAmount,
      updateCart,
      processingAmount,
    ],
  );

  const fastCashOptions = useMemo(
    () =>
      getFastCashOptions(
        processingAmount || (customerAmount as number),
        enableRoundOff,
        roundOffValue,
      ),
    [processingAmount, customerAmount, enableRoundOff, roundOffValue],
  );

  const checkAllowAdjustmentPermission = useCallback(() => {
    const allowAdjustment = canI([{ onResource: Resource.ALLOW_ADJUSTMENTS }], {
      prompt: true,
    });
    return allowAdjustment;
  }, [canI]);

  const onPressPaymentOption = useCallback(
    (paymentType: PaymentType, amount: number) => {
      const paymentTypeInput = stripProperties(
        { ...paymentType },
        'label',
        'value',
      );
      closeModal();
      if (paymentType.adjustmentPrompt) {
        showModal(
          <PaymentSurcharge
            orderTotal={processingAmount || (customerAmount as number)}
            onSubmit={applySurcharge}
            paymentType={paymentTypeInput}
            checkAllowAdjustmentPermission={checkAllowAdjustmentPermission}
          />,
        );
      } else {
        onPressPay && onPressPay(paymentTypeInput, amount || 0, 0, customer);
      }
    },
    [
      closeModal,
      showModal,
      processingAmount,
      customerAmount,
      applySurcharge,
      onPressPay,
      customer,
      checkAllowAdjustmentPermission,
    ],
  );
  const customerAccountSettings =
    session?.currentVenue?.customerAccountSettings;
  const assignCustomerToOrder = useCallback(
    customer => {
      updateCart<AssignCustomerEvent>(OrderAction.ORDER_ASSIGN_CUSTOMER, {
        customerId: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        loyaltyMember: customer.loyaltyMember,
        customerAccountDetails: {
          accountPayment: true,
          currentBalance: customer?.customerAccountDetails?.currentBalance,
          maxOrderLimit:
            customer?.customerAccountDetails?.maxOrderLimit ||
            customerAccountSettings?.defaultMaxOrderLimit ||
            100,
          maxBalanceLimit:
            customer?.customerAccountDetails?.maxBalanceLimit ||
            customerAccountSettings?.defaultMaxBalanceLimit ||
            1000,
        },
        isLoyaltyApplied: isLoyaltyEnabled && customer.loyaltyMember,
      });
      setAssignedCustomer(customer);
    },
    [
      customerAccountSettings?.defaultMaxBalanceLimit,
      customerAccountSettings?.defaultMaxOrderLimit,
      isLoyaltyEnabled,
      updateCart,
    ],
  );

  const onSetCustomInputAmount = useCallback(
    (amount: number) => {
      const validProcessingAmount = enableRoundOff
        ? roundOffByValue(processingAmount, roundOffValue || 0.05)
        : processingAmount;
      if (amount < validProcessingAmount) {
        showNotification({
          message: translate('payment.amountLessThanDueAmount'),
          error: true,
        });
        return;
      }
      closeModal();
      onPressPaymentOption(getPaymentTypeByName('Cash'), amount);
    },
    [
      closeModal,
      enableRoundOff,
      getPaymentTypeByName,
      onPressPaymentOption,
      processingAmount,
      roundOffValue,
      showNotification,
      translate,
    ],
  );
  const reAssignCustomerToOrder = useCallback((): void => {
    // trigger assign order to customer event
    assignCustomerToOrder(order?.customer);
  }, [assignCustomerToOrder, order?.customer]);

  const onPressShowKeypad = useCallback(() => {
    showModal(
      <MoneyInputKeypad
        headerText={translate('payment.setAmountToPay')}
        onSubmit={onSetCustomInputAmount}
        onDismiss={closeModal}
      />,
      { onBackdropPress: closeModal },
    );
  }, [showModal, translate, onSetCustomInputAmount, closeModal]);

  const onUpdateProcessingAmount = useCallback(
    (type: 'SET' | 'UPDATE') => {
      const onSubmitAmount = (amount: number) => {
        if (amount === 0) {
          showNotification({
            message: translate('payment.amountCannotBeZero'),
            error: true,
          });
        } else if (amount > remainingDueAmount) {
          showNotification({
            message: translate('payment.amountCannotGreaterThanDueAmount'),
            error: true,
          });
        } else {
          dispatch({
            type:
              type == 'SET'
                ? PaymentAction.SET_PAYMENT_AMOUNT
                : PaymentAction.UPDATE_PAYMENT_AMOUNT,
            payload: { processingAmount: amount },
          });
          closeModal();
        }
      };

      return showModal(
        <SetAmountKeypad
          onSubmit={onSubmitAmount}
          remainingDueAmount={remainingDueAmount}
          processingAmount={type == 'SET' ? 0 : processingAmount}
          step={type == 'SET' ? nthPayment + 1 : nthPayment}
          closeModal={closeModal}
        />,
      );
    },
    [
      showModal,
      remainingDueAmount,
      processingAmount,
      nthPayment,
      closeModal,
      dispatch,
      showNotification,
      translate,
    ],
  );

  useEffect(() => {
    const { currentBalance = 0, maxBalanceLimit = 0 } =
      order?.customer?.customerAccountDetails || {};
    setAllowOnAccountAmount(maxBalanceLimit - currentBalance);
  }, [order?.customer?.customerAccountDetails]);

  useEffect(() => {
    const { maxOrderLimit = 0 } = order?.customer?.customerAccountDetails || {};
    setAllowOnAccountAmountOrderLimit(maxOrderLimit);
  }, [order?.customer?.customerAccountDetails]);

  const onChangeOption = useCallback(
    (paymentMode: string) => {
      dispatch({
        type: PaymentAction.UPDATE_PAYMENT_MODE,
        payload: {
          paymentMode: paymentMode as PaymentMode,
        },
      });
    },
    [dispatch],
  );

  const onSubmitPaymentByOnAccount = useCallback(() => {
    onPressPaymentOption(
      getPaymentTypeByName(OrderAction.ON_ACCOUNT_PAYMENT_TYPE),
      processingAmount,
    );
  }, [getPaymentTypeByName, onPressPaymentOption, processingAmount]);

  const handleOnPressOnAccount = useCallback(() => {
    if (!order?.customer) {
      showNotification({
        message: translate('payment.notificationSelectCustomer'),
        error: true,
      });
      return;
    }

    if (!order?.customer?.customerAccountDetails?.accountPayment) {
      showModal(
        <EnableOnAccountModal
          order={order as Order}
          onConfirm={() => {
            closeModal();
            reAssignCustomerToOrder();
          }}
        />,
      );
    } else if (
      processingAmount <= allowOnAccountAmount &&
      displayAmount <= allowOnAccountAmountOrderLimit
    ) {
      showModal(
        <CustomerAccountIdentityModal
          customer={order.customer}
          processingAmount={processingAmount}
          onConfirmPay={onSubmitPaymentByOnAccount}
        />,
      );
    } else {
      showNotification({
        message: translate('payment.onAccountLimitNotification', {
          customerName: order.customer.firstName,
        }),
        error: true,
      });
    }
  }, [
    allowOnAccountAmount,
    allowOnAccountAmountOrderLimit,
    closeModal,
    displayAmount,
    onSubmitPaymentByOnAccount,
    order,
    processingAmount,
    reAssignCustomerToOrder,
    showModal,
    showNotification,
    translate,
  ]);

  const ifNoNetwork = useCallback(() => {
    if (!networkStatusRef.current) {
      showNotification({
        error: true,
        message: translate('settings.noInternet'),
      });
      return true;
    }
    return false;
  }, [showNotification, translate]);

  useEffect(() => {
    if (cancellationResponse) {
      showNotification({
        success: true,
        message: translate('payment.oolioPayCancellationSuccess'),
      });

      /**
       * In offline scenarios, original request stays loading.
       * With this flag we are unblocking user to close the order with payment.
       * The current one still stays in pending.
       */
      setTimeout(() => {
        paymentDetailsRef.current = {
          ...(paymentDetailsRef.current as TempPaymentInfo),
          cancellationRequested: true,
        };
      }, 2000);

      resetCancellationResponse();
    }
  }, [
    cancellationResponse,
    resetCancellationResponse,
    showNotification,
    translate,
  ]);

  useEffect(() => {
    if (paymentResponse) {
      // TODO: Cancel original "initiatePayment" request, if success is provided by Verify.
      const paymentDetailsClone = {
        ...(paymentDetailsRef.current as TempPaymentInfo),
      };

      let paymentStatus: OrderPaymentStatus;
      const isPaymentStillInProgress = PAYMENT_INPROGRESS_MESSAGES.includes(
        paymentResponse.message,
      );

      let paymentSurcharge: number | undefined;

      if (paymentResponse.success) {
        paymentStatus = OrderPaymentStatus.COMPLETE;

        if (paymentResponse.additionalInfo?.transactionAdjustment) {
          const transactionAdjustment =
            paymentResponse.additionalInfo?.transactionAdjustment;

          paymentSurcharge = transactionAdjustment?.amount;

          updateCart<AddOrderSurcharge>(OrderAction.ORDER_ADD_ADJUSTMENT, {
            adjustment: generateCustomFlatAdjustment(
              transactionAdjustment.amount,
            ),
          });
        }
      } else {
        paymentStatus = POS_PAYMENT_CANCEL_MESSAGES.includes(
          paymentResponse.message,
        )
          ? OrderPaymentStatus.CANCELLED
          : OrderPaymentStatus.REJECTED;

        showNotification(
          {
            ...(!isPaymentStillInProgress && { error: true }),
            ...(isPaymentStillInProgress && { info: true }),
            message: paymentResponse.message,
          },
          false,
        );
      }

      resetPaymentResponse();

      if (!isPaymentStillInProgress) {
        paymentStatus &&
          onPaymentProcessed &&
          onPaymentProcessed({
            paymentStatus,
            paymentRequestId: paymentDetailsClone?.requestId as string,
            paymentTransactionRef:
              paymentResponse.additionalInfo?.transactionId,
            paymentCompletedAt:
              paymentResponse.additionalInfo?.transactionCompletedAt,
            paymentReceipt: paymentResponse.additionalInfo?.paymentReceipt,
            paymentSurcharge,
          });

        if (!isPaymentLoading) paymentDetailsRef.current = undefined;
      }
    }
  }, [
    getPaymentTypeByName,
    isPaymentLoading,
    onPaymentProcessed,
    onPressPaymentOption,
    paymentResponse,
    processingAmount,
    resetPaymentResponse,
    showNotification,
    translate,
    updateCart,
  ]);

  const initiateOolioPayment = useCallback(() => {
    if (!ifNoNetwork()) {
      const requestId = nanoid();
      initiatePayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: requestId,
        transactionId: order?.orderNumber as string,
        amount: paymentDetailsRef.current?.orderAmount as number,
        currency: DEFAULT_PAYMENT_CURRENCY,
      });

      onPressPay &&
        onPressPay(
          paymentDetailsRef.current?.paymentType as PaymentType,
          paymentDetailsRef.current?.orderAmount as number,
          0,
          undefined,
          {
            paymentRequestId: requestId,
            paymentStatus: OrderPaymentStatus.PENDING,
          },
        );

      paymentDetailsRef.current = {
        ...(paymentDetailsRef.current as TempPaymentInfo),
        requestId,
      };
    }
  }, [
    ifNoNetwork,
    initiatePayment,
    onPressPay,
    order?.orderNumber,
    session.device,
  ]);

  const verifyOolioPayment = useCallback(() => {
    if (!ifNoNetwork()) {
      verifyPayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: paymentDetailsRef.current?.requestId as string,
      });
    }
  }, [ifNoNetwork, session.device, verifyPayment]);

  const cancelOolioPayment = useCallback(() => {
    if (!ifNoNetwork()) {
      /**
       * Cancel payment is acknowledgement only.
       * The actual cancellation is verified by original request or
       * verify status.
       */
      cancelPayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: paymentDetailsRef.current?.requestId as string,
      });
    }
  }, [ifNoNetwork, session.device, cancelPayment]);

  const addSurchargeToAmount = useCallback(
    (paymentType, surcharge) => {
      if (order && surcharge?.amount) {
        updateCart<AddOrderSurcharge>(OrderAction.ORDER_ADD_ADJUSTMENT, {
          adjustment: surcharge,
        });

        const surchargeAmount = getAdjustmentValue(
          processingAmount,
          [surcharge],
          surcharge.adjustmentType,
        );

        paymentDetailsRef.current = {
          paymentType,
          orderAmount: processingAmount + surchargeAmount,
          surchargeAmount: surchargeAmount,
        };

        initiateOolioPayment();
      } else {
        paymentDetailsRef.current = {
          paymentType,
          orderAmount: processingAmount,
        };

        initiateOolioPayment();
      }
    },
    [order, updateCart, processingAmount, initiateOolioPayment],
  );

  const handleOnPressOolioPay = useCallback(() => {
    if (processingAmount === 0) {
      showNotification({
        message: translate('payment.payZeroError'),
        error: true,
      });
      return;
    }
    if (session.device?.paymentTerminal) {
      const paymentType = getPaymentTypeByName(OOLIO_PAY_PAYMENT_TYPE);
      const paymentTypeInput = stripProperties(
        { ...paymentType },
        'label',
        'value',
      );

      if (paymentType.adjustmentPrompt) {
        showModal(
          <PaymentSurcharge
            orderTotal={processingAmount}
            onSubmit={addSurchargeToAmount}
            paymentType={paymentTypeInput}
            checkAllowAdjustmentPermission={checkAllowAdjustmentPermission}
          />,
        );
      } else {
        paymentDetailsRef.current = {
          paymentType: paymentTypeInput,
          orderAmount: processingAmount,
        };

        initiateOolioPayment();
      }
    } else {
      showNotification({
        message: translate('payment.paymentTerminalNotLinked'),
        error: true,
      });
    }
  }, [
    addSurchargeToAmount,
    checkAllowAdjustmentPermission,
    getPaymentTypeByName,
    initiateOolioPayment,
    processingAmount,
    session.device?.paymentTerminal,
    showModal,
    showNotification,
    translate,
  ]);

  const onPressOtherPaymentType = useCallback(
    (paymentType: OtherPaymentType) => {
      switch (paymentType.name) {
        case OrderAction.ON_ACCOUNT_PAYMENT_TYPE:
          handleOnPressOnAccount();
          break;

        case OOLIO_PAY_PAYMENT_TYPE:
          handleOnPressOolioPay();
          break;

        default:
          onPressPaymentOption(
            getPaymentTypeByName(paymentType.name),
            processingAmount || (customerAmount as number),
          );
          break;
      }
    },
    [
      customerAmount,
      getPaymentTypeByName,
      handleOnPressOnAccount,
      handleOnPressOolioPay,
      onPressPaymentOption,
      processingAmount,
    ],
  );

  const submitSplitPaymentCount = useCallback(
    (splitNumber: number) => {
      dispatch({
        type: PaymentAction.SETUP_NUMBER_OF_SPLIT_PAYMENT,
        payload: {
          numberOfSplitPayment: splitNumber,
        },
      });
    },
    [dispatch],
  );

  const showSetNumberOfSplitPaymentModal = useCallback(() => {
    showModal(
      <SetSplitPaymentCount
        closeModal={closeModal}
        onSubmit={submitSplitPaymentCount}
      />,
    );
  }, [showModal, closeModal, submitSplitPaymentCount]);

  const onClickSplitEqual = useCallback(
    (splitNumber?: number) => {
      if (splitNumber) {
        submitSplitPaymentCount(splitNumber);
      } else {
        showSetNumberOfSplitPaymentModal();
      }
    },
    [showSetNumberOfSplitPaymentModal, submitSplitPaymentCount],
  );

  useEffect(() => {
    if (
      orderPaymentDetails.isSplitPayment &&
      orderPaymentDetails.numberOfSplitPayment !== Infinity
    ) {
      if (
        orderPaymentDetails.nthSplitPayment <
        orderPaymentDetails.numberOfSplitPayment
      ) {
        dispatch({
          type: PaymentAction.UPDATE_PAYMENT_AMOUNT,
          payload: {
            processingAmount: round(
              orderPaymentDetails.remainingDueAmount /
                (orderPaymentDetails.numberOfSplitPayment +
                  1 -
                  orderPaymentDetails.nthSplitPayment),
              2,
            ),
          },
        });
      } else {
        dispatch({
          type: PaymentAction.UPDATE_PAYMENT_AMOUNT,
          payload: {
            processingAmount: orderPaymentDetails.remainingDueAmount,
          },
        });
      }
    }
  }, [
    orderPaymentDetails.isSplitPayment,
    orderPaymentDetails.numberOfSplitPayment,
    orderPaymentDetails.nthSplitPayment,
    dispatch,
    orderPaymentDetails.remainingDueAmount,
  ]);

  const amountOutstanding = remainingDueAmount - processingAmount;

  if (
    !paymentDetailsRef.current?.cancellationRequested &&
    (isPaymentLoading || pendingPayment)
  ) {
    return (
      <View style={css(containerStyle)} testID="payment-options">
        {activeScreen === PaymentSubScreen.MAIN && (
          <Text style={css(editTextStyle)}>
            {translate('payment.paymentAmount')}
          </Text>
        )}
        <PaymentAmountOptions
          value={paymentDetailsRef.current?.orderAmount as number}
        />
        <View style={css(loadingContainerStyle)} testID="payment-loader-view">
          <LoadingIndicator size={'large'} testID={'payment-loader'} />
        </View>
        <View testID={'payment-processing-text'}>
          <Text style={css(loadingTextStyle)}>
            {translate('payment.processingPayment')}
          </Text>
        </View>
        <View style={css(paymentActionContainerStyle)}>
          <Button
            testID={'cancel-payment-btn'}
            size="large"
            title={translate('payment.cancelOolioPay')}
            onPress={cancelOolioPayment}
            containerStyle={[css(cancelPaymentButtonStyle)]}
            labelStyle={css(cancelPaymentLabelStyle)}
            disabled={cancellationLoading}
          />
          <Button
            testID={'verify-payment-btn'}
            size="large"
            title={translate('payment.verifyOolioPay')}
            onPress={verifyOolioPayment}
            containerStyle={[css(verifyPaymentButtonStyle)]}
            labelStyle={css(verifyPaymentLabelStyle)}
          />
        </View>
      </View>
    );
  }

  return (
    <View style={css(containerStyle)} testID="payment-options">
      {activeScreen === PaymentSubScreen.MAIN && (
        <Text style={css(editTextStyle)}>
          {translate('payment.paymentAmount')}
        </Text>
      )}
      <PaymentAmountOptions
        value={processingAmount}
        onPressEdit={() => onUpdateProcessingAmount('UPDATE')}
        showEditButton={
          activeScreen === PaymentSubScreen.PAYMENT_OPTIONS &&
          !(
            orderPaymentDetails.nthSplitPayment ===
              orderPaymentDetails.numberOfSplitPayment &&
            orderPaymentDetails.isSplitPayment &&
            orderPaymentDetails.numberOfSplitPayment !== Infinity
          )
        }
      />
      {activeScreen === PaymentSubScreen.PAYMENT_OPTIONS && (
        <View style={css(willRemainingAmountContainer)}>
          <Text style={css(willRemainingTextStyle)} testID="remaining-amount">
            {translate('payment.remainingAmountOutOfTotal', {
              willRemainingAmount: formatCurrency(amountOutstanding),
              totalDueAmount: formatCurrency(totalDueAmount),
            })}
          </Text>
        </View>
      )}
      {activeScreen === PaymentSubScreen.MAIN && (
        <SelectBar
          options={[
            {
              label: translate('payment.payInFull'),
              value: PaymentMode.PAY_IN_FULL,
            },
            {
              label: translate('payment.splitPayment'),
              value: PaymentMode.SPLIT_PAYMENT,
            },
          ]}
          selectedOption={paymentOption}
          onPress={onChangeOption}
          containerStyle={css(selectBarStyle)}
          itemLabelStyle={itemLabelStyle}
        />
      )}
      {paymentOption === PaymentMode.PAY_IN_FULL ||
      activeScreen === PaymentSubScreen.PAYMENT_OPTIONS ? (
        <View testID="fast-cash-option-section">
          <Text style={css(cashCardTextStyle)}>
            {translate('payment.cashOptions')}
          </Text>
          <View style={css(buttonOptionsContainerStyle)}>
            {Array(5)
              .fill(null)
              .map((X, index) => {
                return (
                  <Button
                    key={index}
                    testID={
                      index < 4
                        ? `cash-${fastCashOptions[index]}`
                        : 'cash-other'
                    }
                    success
                    size="large"
                    containerStyle={[
                      css(optionButtonStyle),
                      { backgroundColor: theme.colors.green },
                    ]}
                    labelStyle={css(labelStyle)}
                    title={
                      index < 4
                        ? formatCurrency(fastCashOptions[index] || 0)
                        : translate('payment.other')
                    }
                    onPress={
                      index < 4
                        ? onPressPaymentOption.bind(
                            null,
                            getPaymentTypeByName('Cash'),
                            fastCashOptions[index],
                          )
                        : onPressShowKeypad
                    }
                  />
                );
              })}
          </View>
          <Text style={css(cashCardTextStyle)}>
            {translate('payment.otherOptions')}
          </Text>
          <View style={css(buttonOptionContainerStyle)}>
            {otherPaymentTypes.map((otherPaymentType, index) => {
              const disabled =
                isCustomerBalancePayment &&
                otherPaymentTypes[index].name ===
                  OrderAction.ON_ACCOUNT_PAYMENT_TYPE;
              return (
                <Button
                  key={index}
                  testID={`other-payment-type-${otherPaymentType.name}`}
                  success
                  size="large"
                  containerStyle={[
                    css(optionButtonStyle),
                    {
                      backgroundColor: disabled
                        ? theme.colors.greyLight
                        : theme.colors.blue,
                    },
                  ]}
                  labelStyle={css(labelStyle({ disabled, theme }))}
                  title={otherPaymentType.name}
                  onPress={() => onPressOtherPaymentType(otherPaymentType)}
                  disabled={disabled}
                />
              );
            })}
          </View>
        </View>
      ) : (
        <View>
          <Text style={css(cashCardTextStyle)}>
            {translate('payment.splitEqual')}
          </Text>
          <View style={css(buttonOptionsContainerStyle)}>
            {SPLIT_PAYMENT_EQUAL_OPTIONS.map(splitOption => (
              <Button
                key={`split-equally-${splitOption.label}`}
                testID={`split-equally-${splitOption.label}`}
                success
                size="large"
                containerStyle={[
                  css(splitEqualButtonStyle),
                  { backgroundColor: theme.colors.blue },
                ]}
                labelStyle={css(labelStyle)}
                title={splitOption.label}
                onPress={() => onClickSplitEqual(splitOption.splitNumber)}
              />
            ))}
          </View>
          <Text style={css(cashCardTextStyle)}>
            {translate('payment.otherSplitOptions')}
          </Text>
          <Button
            key={'split-by-amount'}
            testID={'split-by-amount'}
            success
            size="large"
            containerStyle={[
              css(splitButtonStyle),
              { backgroundColor: theme.colors.orange },
            ]}
            labelStyle={css(labelStyle)}
            title={translate('payment.splitByAmount')}
            onPress={() => onUpdateProcessingAmount('SET')}
          />
        </View>
      )}
    </View>
  );
};
export default PaymentOptions;
