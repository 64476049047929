import React from 'react';
import Avatar from '../Avatar/Avatar';
import { View, Text, ViewStyle } from 'react-native';
import { Icon, IconProps } from '../Icon/Icon';
import { FelaComponent, useFela } from 'react-fela';
import { RenderProps, StyleFn } from '@hitz-group/domain';
export interface ProfileDisplayProps {
  name: string;
  info?: string;
  iconRight?: string;
  iconUp?: string;
  iconProps?: Partial<IconProps>;
  containerStyle?: ViewStyle;
  iconUpContainerStyle?: ViewStyle;
}

const profileDisplayStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.padding.medium / 1.95,
  paddingRight: 7,
});

const contentStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  padding: theme.padding.large,
});

const titleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  letterSpacing: -0.4,
  color: theme.colors.white,
  fontFamily: theme.font.medium,
  lineHeight: 20,
});

const subTitleStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  color: theme.colors.textLight,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
  marginTop: theme.spacing.small / 2,
});

const ProfileDisplay: React.FC<ProfileDisplayProps> = ({
  name,
  info,
  iconRight,
  iconUp,
  iconProps,
  containerStyle,
  iconUpContainerStyle,
}: ProfileDisplayProps) => {
  const { css, theme } = useFela();
  return (
    <FelaComponent style={profileDisplayStyle}>
      {({ style }: RenderProps): React.ReactFragment => (
        <View style={containerStyle ? [style, containerStyle] : style}>
          <Avatar name={name || ''} />
          <View style={css(contentStyle)}>
            <Text style={css(titleStyle)}>{name}</Text>
            {info ? <Text style={css(subTitleStyle)}>{info}</Text> : null}
          </View>
          <View>
            <View style={iconUpContainerStyle}>
              {iconUp && (
                <Icon
                  size={20}
                  name={iconUp}
                  color={theme.colors.textLight}
                  {...(iconProps || {})}
                />
              )}
            </View>
            {iconRight && (
              <Icon
                size={20}
                name={iconRight}
                color={theme.colors.textLight}
                {...(iconProps || {})}
              />
            )}
          </View>
        </View>
      )}
    </FelaComponent>
  );
};

export default ProfileDisplay;
