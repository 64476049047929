import {
  Order,
  OrderItemStatus,
  OrderPayment,
  PaymentType,
} from '@hitz-group/domain';
import { OrderType, Product } from '@hitz-group/domain';
import { cloneJSON } from '@hitz-group/client-utils';
import { OpenOrdersDataProps } from '../components/OrderDataView/OpenOrdersView';
import { OrderTypesMap } from './orderTypesHelper';

export const mapOrderToOpenOrdersView = (
  obj: Order,
  orderTypes: OrderTypesMap,
): OpenOrdersDataProps => {
  const orderType = (obj.orderType || {}) as unknown as OrderType;
  return {
    id: obj.id,
    orderNumber: obj.orderNumber,
    createdAt: obj.createdAt,
    updatedAt: obj.updatedAt || obj.createdAt,
    staff: obj.createdBy.name,
    orderItems: obj.orderItems
      ? obj.orderItems.map((item: { product: unknown }) => ({
          name: (item.product as unknown as Product).name,
        }))
      : [],
    customer: obj.customer
      ? obj.customer.firstName + ' ' + obj.customer.lastName
      : 'N/A',
    orderType: {
      ...orderType,
      colorId: orderTypes[orderType.id]?.colorId || 0,
      code: orderTypes[orderType.id]?.code || orderType.code || '',
    },
    orderNote: obj.orderNote || '',
    totalValue: obj.totalPrice,
    table: obj.table,
    payTypes: (obj.payments as unknown as Array<OrderPayment>).map(
      (payment: OrderPayment) => {
        const paymentName = (payment?.paymentType as unknown as PaymentType)
          ?.name;
        const paymentAmount = payment?.amount;
        return {
          id: (payment?.paymentType as unknown as PaymentType)?.id,
          name: paymentName,
          amount: paymentAmount,
        };
      },
    ),
  };
};

export const mapOrdersToOpenOrdersView = (
  array: Array<Order>,
  orderTypesMap: OrderTypesMap,
): OpenOrdersDataProps[] => {
  return array
    .map(item => mapOrderToOpenOrdersView(item, orderTypesMap))
    .sort((a, b) => b.createdAt - a.createdAt);
};

export const filterOpenOrdersData = (
  orders: Array<OpenOrdersDataProps>,
  orderTypeFilter: OrderType['name'],
  keywordFilter: string,
): Array<OpenOrdersDataProps> =>
  orders.filter(order => {
    if (orderTypeFilter && !order.orderType?.name?.includes(orderTypeFilter)) {
      return false;
    }

    if (keywordFilter) {
      const query = keywordFilter.toLowerCase();
      const customerMatch = !!order.customer?.toLowerCase().includes(query);
      const orderNumberMatch = !!order.orderNumber
        ?.toLowerCase()
        .includes(query);
      const itemsNameMatch = !!order.orderItems?.some(({ name }) =>
        name?.toLowerCase().includes(query),
      );
      const notesMatch = !!order.orderNote?.toLowerCase().includes(query);
      const tableNumberMatch = !!order.table?.name
        ?.toLowerCase()
        .includes(query);
      return (
        customerMatch ||
        orderNumberMatch ||
        itemsNameMatch ||
        notesMatch ||
        tableNumberMatch
      );
    }
    return true;
  });

export const productQuantitiesToReturnOnVoidOrder = (
  allProducts: Record<string, Product>,
  order: Order,
) => {
  const productQuantities = {} as Record<string, number>;

  const acceptedOrderItemStatuses = [
    OrderItemStatus.IN_PROGRESS,
    OrderItemStatus.ON_HOLD,
  ];
  order.orderItems.forEach(orderItem => {
    const itemProductId = orderItem.product.id;
    const product = cloneJSON(allProducts[itemProductId]);
    const isProductTracked = product.isBeingTracked;
    if (
      isProductTracked &&
      acceptedOrderItemStatuses.includes(orderItem.status)
    ) {
      productQuantities[itemProductId] = productQuantities[itemProductId]
        ? productQuantities[itemProductId] - orderItem.quantity
        : -orderItem.quantity;
    }
  });

  return productQuantities;
};

export const updateProductQuantities = (
  productQuantities: Record<string, number>,
  allProducts: Record<string, Product>,
) => {
  const updatedProducts = { ...allProducts };
  Object.keys(productQuantities).forEach(productId => {
    updatedProducts[productId].inventory!.availableQuantity -=
      productQuantities[productId];
  });

  return updatedProducts;
};
