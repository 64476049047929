import React from 'react';
import { useFela } from 'react-fela';
import { View, Text, Image } from 'react-native';
import Button from '../../../../../components/Button/Button';
import { Icons, StyleFn, Feature, IntegrationApps } from '@hitz-group/domain';
import { Icon } from '../../../../../components/Icon/Icon';

export type FeatureTileType = {
  icon: string;
  title: string;
  description: string;
  id: string;
  featureNavigationText: string;
};

const featureTileBox: StyleFn = ({ theme }) => ({
  width: 320,
  height: 250,
  marginLeft: 10,
  marginBottom: 40,
  paddingBottom: theme.spacing.small,
});

const ctaTile: StyleFn = ({ theme }) => ({
  borderWidth: 0,
  borderStyle: 'solid',
  marginRight: 10,
  marginBottom: 10,
  backgroundColor: '#f8fcfd',
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  alignItems: 'flex-start',
  textAlign: 'left',
  paddingVertical: theme.padding.large,
  paddingHorizontal: theme.padding.small * 3,
  justifyContent: 'flex-start',
  width: 280,
  height: 280,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.big * 2,
});

const btnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: 'transparent',
  marginBottom: theme.spacing.big,
  marginTop: theme.spacing.medium,
  marginRight: 'auto',
});

const labelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
  letterSpacing: 1,
});

const ctaIcon: StyleFn = () => ({
  width: 50,
  borderRadius: 300,
  textAlign: 'center',
});

const ctaTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  marginTop: theme.spacing.medium,
  lineHeight: theme.spacing.medium,
  color: theme.colors.primary,
  textAlign: 'left',
  fontFamily: theme.font.medium,
});

const ctaSubTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  lineHeight: theme.spacing.medium,
  marginTop: theme.spacing.small,
  letterSpacing: 1,
});

const imageContainerStyle: StyleFn = ({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: theme.radius.medium,
});

interface FeatureTileProps {
  feature: Feature;
  onPress: (id?: string) => void;
}

export const FeatureTile: React.FC<FeatureTileProps> = ({
  feature,
  onPress,
}) => {
  const { css, theme } = useFela();
  const styleMapper: Record<string, { iconName: string; color: string }> = {
    [IntegrationApps.DELIVERIT]: {
      iconName: 'shopping-cart-alt',
      color: theme.colors.blue,
    },
  };
  return (
    <View style={css(featureTileBox)}>
      {feature && (
        <View style={css(ctaTile)} key={feature.id}>
          <View style={css(ctaIcon)}>
            {styleMapper[feature.id] ? (
              <Icon
                name={styleMapper[feature.id].iconName}
                color={styleMapper[feature.id].color}
              />
            ) : (
              <Image
                style={css(imageContainerStyle)}
                source={{ uri: feature.icon }}
              />
            )}
          </View>

          <Text style={css(ctaTitle)}>{feature.title}</Text>
          <Text style={css(ctaSubTitle)}>{feature.about}</Text>

          <Button
            onPress={() => onPress(feature.id)}
            iconPosition={'right'}
            iconContainerStyle={css(checkIconContainer)}
            containerStyle={css(btnContainerStyle)}
            labelStyle={css(labelStyle)}
            icon={Icons.ArrowRight}
            iconProps={{
              color: theme.colors.paragraph,
              size: 25,
            }}
            title={feature.category}
          />
        </View>
      )}
    </View>
  );
};
