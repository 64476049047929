import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';

export const DevicesListRowStyles = () => {
  const { theme } = useFela();
  const rowHeight = 50;

  return useMemo(
    () =>
      StyleSheet.create({
        rowContainerStyle: {
          alignItems: 'center',
        },
        columnLarge: { width: 200 },
        columnRegular: { width: 160, paddingLeft: 30 },
        columnSmall: { width: 100 },
        columnFluid: { flexGrow: 1 },
        fieldViewStyle: {
          height: rowHeight,
          flex: 1,
          paddingHorizontal: theme.spacing.small,
          justifyContent: 'center',
        },
        fieldViewEndStyle: {
          height: rowHeight,
          flex: 1,
          alignItems: 'flex-end',
          paddingHorizontal: theme.spacing.small,
          justifyContent: 'center',
        },
        actionsViewStyle: {
          height: rowHeight,
          justifyContent: 'center',
        },
        closeIconContainerStyle: {
          alignSelf: 'flex-end',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.danger2,
        },
        navigateContainerStyle: {
          alignSelf: 'flex-end',
          borderRadius: theme.radius.small,
        },
        copyIconStyle: {
          color: theme.colors.blue,
        },
        textStyle: {
          ...theme.font14RegularCharcoal,
        },
        highlightedContainerStyle: {
          backgroundColor: theme.colors.skyBlue,
        },
        highlightedTextStyle: {
          color: theme.colors.blue,
          fontSize: 8,
        },
      }),
    [theme],
  );
};

export const DevicesListStyles = () => {
  const { theme } = useFela();
  const rowHeight = 50;

  return useMemo(
    () =>
      StyleSheet.create({
        scrollStyle: {
          backgroundColor: theme.colors.white,
        },
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
          paddingHorizontal: theme.padding.large,
        },
        containerStyle: {
          width: '100%',
          alignSelf: 'center',
          maxWidth: 800,
        },
        columnContainerStyle: {
          flex: 1,
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          borderBottomWidth: 0,
          paddingHorizontal: theme.spacing.big,
          marginTop: theme.spacing.medium,
        },
        actionsContainerStyle: {
          height: 70,
          borderTopColor: theme.colors.boxBorder,
          backgroundColor: theme.colors.white,
          borderTopWidth: 1,
          flexDirection: 'row-reverse',
          paddingRight: theme.spacing.big,
        },
        actionContainerStyle: {
          maxWidth: 200,
          height: rowHeight,
          backgroundColor: theme.colors.white,
          paddingRight: theme.spacing.medium,
        },
        saveButtonStyle: {
          width: 200,
          height: rowHeight,
          marginRight: theme.spacing.medium,
          alignSelf: 'center',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
      }),
    [theme],
  );
};
