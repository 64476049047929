import { StyleFn } from '@hitz-group/domain';
import { isWeb } from '../../common/theme';

export const cartStyle: StyleFn = ({ theme }) => ({
  width: 330,
  flexWrap: 'wrap',
  flexDirection: 'column',
  paddingLeft: theme.spacing.small,
  paddingTop: theme.padding.medium,
  paddingBottom: theme.padding.medium,
});

export const acceptOrderButtonContainer: StyleFn = ({ theme }) => ({
  width: '100%',
  marginHorizontal: 0,
  backgroundColor: theme.colors.green,
  marginBottom: theme.spacing.small,
});

export const horizontalButtonsDeck: StyleFn = () => ({
  flexDirection: 'row',
});

export const payNowBtnContainer: StyleFn = ({ theme }) => ({
  width: '49%',
  marginHorizontal: 0,
  marginBottom: theme.spacing.small,
  backgroundColor: theme.colors.successLight,
});

export const payNowBtnText: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});

export const rePrintDockerBtnContainer: StyleFn = ({ theme }) => ({
  width: '49%',
  backgroundColor: theme.colors.highlighted,
  marginBottom: theme.spacing.small,
  marginLeft: 'auto',
});

export const rePrintBtnTxt: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

export const acceptOrderText: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
});

export const rejectOrderButtonContainer: StyleFn = ({ theme }) => ({
  width: '100%',
  marginHorizontal: 0,
  backgroundColor: theme.colors.danger2,
});

export const rejectOrderText: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

export const cartItemsStyle: StyleFn = () => ({
  marginBottom: 10,
});

export const refundOrderButtonContainer: StyleFn = () => ({
  width: '100%',
  marginHorizontal: 0,
});

export const refundOrderText: StyleFn = ({ theme, success }) => ({
  color: success ? theme.colors.white : theme.colors.paragraph,
});

export const refundInfoContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginTop: 10,
  marginBottom: isWeb ? 10 : 0,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  paddingHorizontal: theme.spacing.medium,
  height: 44,
});

export const refundInfoCreditContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginTop: 0,
  marginBottom: isWeb ? 10 : 0,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  paddingHorizontal: theme.spacing.medium,
  height: 60,
});

export const customerSection: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginBottom: isWeb ? 10 : 0,
  borderRadius: theme.radius.small,
  paddingHorizontal: theme.spacing.medium,
  width: '100%',
  height: 44,
  marginTop: 0,
  flexDirection: 'row',
});

export const textContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: isWeb ? 0 : theme.padding.small,
});

export const iconStyle: StyleFn = () => ({
  alignSelf: 'flex-end',
});

export const totalRemainingStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontSize: theme.fontSize.medium,
  textTransform: 'capitalize',
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  lineHeight: 35,
});

export const customerInfoTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontSize: theme.fontSize.medium,
  textTransform: 'capitalize',
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  lineHeight: 35,
  textAlign: 'center',
  flex: 1,
  alignSelf: 'center',
});

export const totalRemainingAmtStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  lineHeight: 35,
});

export const totalRefundedAmtStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  lineHeight: 35,
  color: theme.colors.red,
});

export const creditStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.smaller,
  fontFamily: theme.font.regular,
  color: theme.colors.paragraph,
  letterSpacing: -0.5,
  lineHeight: 35,
});

export const transactionTypeStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.smaller,
  lineHeight: 24,
});
