import React from 'react';
import { StyleFn } from '@hitz-group/domain';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { useFela } from 'react-fela';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../../components/FormInput/FormInput';
import DropDown from '../../../../../components/FormInput/DropDown';
import * as DefaultData from './default.json';
import { getCurrencySymbol } from '@hitz-group/localization';
import { useSession } from '../../../../../hooks/app/useSession';
import { countries } from 'countries-list';
const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});
const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big + theme.spacing.medium,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  height: theme.input.height,
});

const accountSalesStye: StyleFn = ({ theme }) => ({
  width: 545,
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: theme.colors.white,
});

const formContainerStyle: StyleFn = ({ theme }) => ({
  width: 540,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  paddingBottom: theme.spacing.big,
});

const prefixItemStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  padding: theme.spacing.small / 2 + 5,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.bold,
  borderRadius: theme.radius.small,
  lineHeight: 15,
});

export const AccountSales: React.FC = () => {
  const { css } = useFela();
  const [session] = useSession();
  const { country = 'AU' } = session?.currentOrganization || {};
  const currencyCode = countries[country]?.currency;
  const { translate } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView style={css(accountSalesStye)}>
          <BackOfficeSection
            title={translate('storesSettings.preferences')}
            containerStyle={css(backOfficeContainerStyle)}
            contentContainerStyle={css(formContainerStyle)}
            titleBorderBottom
          >
            <FormInput
              error={false}
              testID="maximum-orderLimit"
              placeholder={translate('form.defaultMaxOrderLimit')}
              title={translate('form.defaultMaxOrderLimit')}
              value={DefaultData.defaultMaxOrderLimit}
              alignTitle="left"
              prefix={{
                text: getCurrencySymbol(currencyCode),
                textStyle: css(prefixItemStyle),
              }}
              containerStyle={css(formInputContainerStyle)}
            />
            <FormInput
              error={false}
              placeholder={translate('form.defaultMaxBalanceLimit')}
              title={translate('form.defaultMaxBalanceLimit')}
              value={DefaultData.defaultMaxBalanceLimit}
              alignTitle="right"
              prefix={{
                text: getCurrencySymbol(currencyCode),
                textStyle: css(prefixItemStyle),
              }}
              containerStyle={css(formInputContainerStyle)}
            />
            <DropDown
              title={translate('form.scheduleStatements')}
              values={DefaultData.scheduleStatements.map(x => ({
                value: x,
                label: x,
              }))}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>
    </>
  );
};
