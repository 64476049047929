import * as React from 'react';
import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Animated,
  GestureResponderEvent,
  PanResponder,
  PanResponderGestureState,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import scale, { isWeb } from '../../common/theme';
import Block from './Block';
import EmptyBlock from './EmptyBlock';
import GridPagination from './GridPagination';
import { differenceBy, findIndex } from 'lodash';
import { Colors, Product, Variant } from '@hitz-group/domain';
import { MenuTile, GRID_TYPE, PAGE_TYPE } from './menuTypes';
import { useIsMounted } from './../../useIsMounted';

export interface OnLayoutEvent {
  nativeEvent: {
    layout: { x: number; y: number; width: number; height: number };
  };
}
export interface BaseItemType {
  key: string | number;
  name: string | number;
  disabledDrag?: boolean;
  disabledReSorted?: boolean;
  __typename?: string;
  id?: string;
  modifierGroups?: [];
  options?: [];
  color?: string;
}

export interface PositionOffset {
  x: number;
  y: number;
}

export interface MenuItemOrder {
  order: number;
}

export interface Item<DataType> {
  key: string | number;
  itemData: DataType;
  currentPosition: Animated.AnimatedValueXY;
}

export interface DraggableGridProps<DataType extends BaseItemType> {
  numColumns: number;
  numRows: number;
  data: DataType[] | Product[] | DataType[][];
  renderItem: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item: any,
    order: number,
    blockType?: MenuTile,
  ) => React.ReactElement<unknown>;
  style?: StyleProp<ViewStyle>;
  itemHeight?: number;
  onDragStart?: (item: DataType) => void;
  onToggleItem?: (item: DataType) => void;
  onPressToggleItem?: (productId?: string) => void;
  onDragging?: (gestureState: PanResponderGestureState) => void;
  onDragRelease?: (newSortedData: DataType[]) => void;
  onSelectVariant?: (variantId: string) => void;
  onSelectModifier?: (typeName: string, productId: string) => void;
  blockType?: MenuTile;
  onSelectionDone?: (type: MenuTile) => void;
  selectedItem?: string;
  onSelectTilePopup?: (
    type?: MenuTile,
    id?: string,
    createType?: PAGE_TYPE,
  ) => void;
  scrollDirection: 'vertical' | 'horizontal';
  onSelectPage?: (pageId: string, items: Product[] | Variant[]) => void;
  highlightSelectedItem?: boolean;
  gridType: GRID_TYPE;
}

let activeBlockOffset = { x: 0, y: 0 };

export const DraggableGrid = <DataType extends BaseItemType>(
  props: DraggableGridProps<DataType>,
) => {
  const [blockPositions] = useState<PositionOffset[]>([]);
  const [orderMap] = useState<Record<string, MenuItemOrder>>({});
  const [itemMap] = useState<Record<string, DataType>>({});
  const [items] = useState<Item<DataType>[]>([]);
  const [blockHeight, setBlockHeight] = useState(0);
  const [blockWidth, setBlockWidth] = useState(0);
  const [gridHeight] = useState<Animated.Value>(new Animated.Value(0));
  const [hadInitBlockSize, setHadInitBlockSize] = useState(false);
  const [dragStartAnimatedValue] = useState(new Animated.Value(1));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const itemPerPage = props.numRows * props.numColumns - 1;
  const totalPageCount = items ? Math.ceil(items.length / itemPerPage) : 1;
  const [gridLayout, setGridLayout] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const itemRowIndex =
    props.numRows == 3
      ? 14
      : props.numRows == 2
      ? 9
      : props.numRows == 5
      ? 24
      : 0;
  const paginationStartIndex = (currentPage - 1) * itemPerPage;
  const paginationEndIndex = currentPage * itemPerPage;
  const isMounted = useIsMounted();

  const loadEmptyTilesByLength = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: Array<any>, size: number) => {
      const emptyItems = [];
      let sizeTemp = size;
      let i = data.length || 0;
      if (props.gridType === GRID_TYPE.PAGE_ITEMS) {
        emptyItems.push({
          key: 'AddBlankPageItemTile',
          name: 'Add',
          disabledDrag: false,
          disabledReSorted: false,
          id: 'BLANK',
          color: Colors.white,
          __typename: 'BLANK_PAGE_ITEM',
        } as unknown as DataType);
        sizeTemp = size - 1;
      }
      if (props.gridType === GRID_TYPE.PAGE) {
        emptyItems.push({
          key: 'AddBlankPageTile',
          name: 'Add',
          disabledDrag: false,
          disabledReSorted: false,
          id: 'BLANK',
          color: Colors.white,
          __typename: 'BLANK_PAGE',
        } as unknown as DataType);
        sizeTemp = size - 1;
      }

      for (i; i < sizeTemp; i++) {
        emptyItems.push({
          key: i,
          name: '',
          disabledDrag: false,
          disabledReSorted: false,
        });
      }
      return [...(data?.length ? data : []), ...emptyItems];
    },
    [props.gridType],
  );

  const isModifierOrVariantOptions = useMemo(
    () => props.blockType === 'Modifier' || props.blockType === 'VariantOption',
    [props.blockType],
  );

  const dataCustomized = useMemo(() => {
    if (isModifierOrVariantOptions && props.data?.length) {
      const dataOfSubItems = props.data as DataType[][];
      return loadEmptyTilesByLength(
        dataOfSubItems[currentPage - 1],
        props.numColumns * props.numRows,
      );
    } else {
      return loadEmptyTilesByLength(
        props.data,
        props.numColumns * props.numRows,
      );
    }
  }, [
    isModifierOrVariantOptions,
    props.data,
    props.numColumns,
    props.numRows,
    loadEmptyTilesByLength,
    currentPage,
  ]);

  const [activeItemIndex, setActiveItemIndex] = useState<undefined | number>();
  const assessGridSize = (event: OnLayoutEvent) => {
    if (!hadInitBlockSize && isMounted()) {
      const blockWidth = event.nativeEvent.layout.width / props.numColumns;
      const blockHeight = props.itemHeight || blockWidth;
      setBlockWidth(blockWidth);
      setBlockHeight(blockHeight);
      setGridLayout(event.nativeEvent.layout);
      setHadInitBlockSize(true);
    }
  };
  const [panResponderCapture, setPanResponderCapture] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const findKey = (map: { [key: string]: any }, fn: (item: any) => boolean) => {
    const keys = Object.keys(map);
    for (let i = 0; i < keys.length; i++) {
      if (fn(map[keys[i]])) {
        return keys[i];
      }
    }
  };

  const getBlockPositionByOrder = useCallback(
    (order: number) => {
      if (blockPositions[order]) {
        return blockPositions[order];
      }
      const columnOnRow = order % props.numColumns;
      const y = 78 * Math.floor(order / props.numColumns);
      const x = columnOnRow * (isWeb ? 145 : 115);
      return {
        x,
        y,
      };
    },
    [blockPositions, props.numColumns],
  );

  const resetGridHeight = () => {
    const rowCount = Math.ceil(dataCustomized.length / props.numColumns);
    gridHeight.setValue(rowCount * blockHeight);
  };

  const onStartDrag = (
    _: GestureResponderEvent,
    gestureState: PanResponderGestureState,
  ) => {
    const activeItem = getActiveItem();
    if (!activeItem) return false;
    props.onDragStart && props.onDragStart(activeItem.itemData);
    const { x0, y0, moveX, moveY } = gestureState;
    const activeOrigin = blockPositions[orderMap[activeItem.key].order];
    const x = activeOrigin.x - x0;
    const y = activeOrigin.y - y0;
    activeItem.currentPosition.setOffset({
      x,
      y,
    });
    activeBlockOffset = {
      x,
      y,
    };
    activeItem.currentPosition.setValue({
      x: moveX,
      y: moveY,
    });
  };
  const onHandMove = (
    _: GestureResponderEvent,
    gestureState: PanResponderGestureState,
  ) => {
    const activeItem = getActiveItem();
    if (!activeItem) return false;
    const { moveX, moveY } = gestureState;
    props.onDragging && props.onDragging(gestureState);

    const xChokeAmount = Math.max(
      0,
      activeBlockOffset.x + moveX - (gridLayout.width - blockWidth),
    );
    const xMinChokeAmount = Math.min(0, activeBlockOffset.x + moveX);

    const dragPosition = {
      x: moveX - xChokeAmount - xMinChokeAmount,
      y: moveY,
    };
    const originPosition = blockPositions[orderMap[activeItem.key].order];
    const dragPositionToActivePositionDistance = getDistance(
      dragPosition,
      originPosition,
    );
    activeItem.currentPosition.setValue(dragPosition);

    let closetItemIndex = activeItemIndex as number;
    let closetDistance = dragPositionToActivePositionDistance;

    items.forEach((item, index) => {
      if (item.itemData.disabledReSorted) return;
      if (index != activeItemIndex) {
        const dragPositionToItemPositionDistance = getDistance(
          dragPosition,
          blockPositions[orderMap[item.key].order],
        );
        if (
          dragPositionToItemPositionDistance < closetDistance &&
          dragPositionToItemPositionDistance < blockWidth
        ) {
          closetItemIndex = index;
          closetDistance = dragPositionToItemPositionDistance;
        }
      }
    });
    if (activeItemIndex != closetItemIndex) {
      const closetOrder = orderMap[items[closetItemIndex].key].order;
      resetBlockPositionByOrder(orderMap[activeItem.key].order, closetOrder);
      orderMap[activeItem.key].order = closetOrder;
    }
  };

  const moveBlockToBlockOrderPosition = useCallback(
    (itemKey: string | number) => {
      const itemIndex = findIndex(
        items,
        item => `${item.key}` === `${itemKey}`,
      );
      items[itemIndex].currentPosition.flattenOffset();
      Animated.timing(items[itemIndex].currentPosition, {
        toValue: blockPositions[orderMap[itemKey].order]
          ? blockPositions[orderMap[itemKey].order]
          : { x: Number(itemIndex) * scale.moderateScale(52), y: 0 },
        duration: 200,
        useNativeDriver: false,
      }).start();
    },
    [blockPositions, items, orderMap],
  );

  const getSortData = useCallback(() => {
    const sortData: DataType[] = [];
    items.forEach(item => {
      sortData[orderMap[item.key].order] = item.itemData;
    });
    return sortData;
  }, [items, orderMap]);

  const getActiveItem = useCallback(() => {
    if (activeItemIndex === undefined) return false;
    return items[activeItemIndex];
  }, [activeItemIndex, items]);

  const onHandRelease = useCallback(() => {
    const activeItem = getActiveItem();
    if (!activeItem) return false;
    props.onDragRelease && props.onDragRelease(getSortData());
    setPanResponderCapture(false);
    activeItem.currentPosition.flattenOffset();
    moveBlockToBlockOrderPosition(activeItem.key);
    setActiveItemIndex(undefined);
  }, [getActiveItem, getSortData, moveBlockToBlockOrderPosition, props]);

  const resetBlockPositionByOrder = (
    activeItemOrder: number,
    insertedPositionOrder: number,
  ) => {
    let disabledReSortedItemCount = 0;
    if (activeItemOrder > insertedPositionOrder) {
      for (let i = activeItemOrder - 1; i >= insertedPositionOrder; i--) {
        const key = getKeyByOrder(i);
        const item = itemMap[key];
        if (item && item.disabledReSorted) {
          disabledReSortedItemCount++;
        } else {
          orderMap[key].order += disabledReSortedItemCount + 1;
          disabledReSortedItemCount = 0;
          moveBlockToBlockOrderPosition(key);
        }
      }
    } else {
      for (let i = activeItemOrder + 1; i <= insertedPositionOrder; i++) {
        const key = getKeyByOrder(i);
        const item = itemMap[key];
        if (item && item.disabledReSorted) {
          disabledReSortedItemCount++;
        } else {
          orderMap[key].order -= disabledReSortedItemCount + 1;
          disabledReSortedItemCount = 0;
          moveBlockToBlockOrderPosition(key);
        }
      }
    }
  };

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onStartShouldSetPanResponderCapture: () => false,
    onMoveShouldSetPanResponder: () => panResponderCapture,
    onMoveShouldSetPanResponderCapture: () => panResponderCapture,
    onShouldBlockNativeResponder: () => false,
    onPanResponderTerminationRequest: () => false,
    onPanResponderGrant: onStartDrag,
    onPanResponderMove: onHandMove,
    onPanResponderRelease: onHandRelease,
  });

  const getKeyByOrder = (order: number) => {
    return findKey(
      orderMap,
      (item: MenuItemOrder) => item.order === order,
    ) as string;
  };

  const getDistance = (
    startOffset: PositionOffset,
    endOffset: PositionOffset,
  ) => {
    const xDistance = startOffset.x + activeBlockOffset.x - endOffset.x;
    const yDistance = startOffset.y + activeBlockOffset.y - endOffset.y;
    return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
  };
  const setActiveBlock = useCallback((itemIndex: number, item: DataType) => {
    if (item.disabledDrag) return;
    setPanResponderCapture(true);
    setActiveItemIndex(itemIndex);
  }, []);

  const getBlockStyle = useCallback(
    (itemIndex: number): StyleProp<ViewStyle> => {
      return [
        {
          alignItems: 'center',
        },
        hadInitBlockSize && {
          height: isWeb ? scale.moderateScale(52) : scale.moderateScale(42),
          width: isWeb ? scale.moderateScale(100) : scale.moderateScale(63), // `${(isWeb ? 90 : 97) / props.numColumns}%`,
          position: 'absolute',
          top: items[itemIndex]
            ? items[itemIndex].currentPosition.getLayout().top
            : scale.moderateScale(40) * 4 + 17,
          left: items[itemIndex]
            ? items[itemIndex].currentPosition.getLayout().left
            : (isWeb ? 141 : 110) * 2 + 8,
        },
      ] as StyleProp<ViewStyle>;
    },
    [hadInitBlockSize, items],
  );

  const onNext = useCallback(() => {
    setCurrentPage(
      prev => (props.data?.length >= prev + 1 && prev + 1) || prev,
    );
  }, [props.data?.length]);

  const onBack = useCallback(() => {
    setCurrentPage(prev => (1 <= prev - 1 && prev - 1) || prev);
  }, []);

  const onPressDone = useCallback(
    (type: MenuTile) => {
      setCurrentPage(1);
      props?.onSelectionDone && props.onSelectionDone(type);
    },
    [props],
  );

  const paginationBlockStyle = (itemIndex: number) => {
    let topStyle = items[itemIndex]
      ? items[itemIndex].currentPosition.getLayout().top
      : scale.moderateScale(52) * 4 + 17;

    if (itemIndex === 0) {
      topStyle =
        scale.moderateScale(52) * props.numRows * props.numColumns - 25;
    }

    return [
      {
        alignItems: 'center',
      },
      hadInitBlockSize && {
        height: isWeb ? scale.moderateScale(52) : scale.moderateScale(42),
        width: isWeb ? scale.moderateScale(100) : scale.moderateScale(63),
        position: 'absolute',
        top: topStyle,
        left: items[itemIndex]
          ? items[itemIndex].currentPosition.getLayout().left
          : (isWeb ? 141 : 110) * 4 + 17,
      },
    ] as StyleProp<ViewStyle>;
  };

  const getDefaultDragStartAnimation = useMemo(() => {
    return {
      transform: [
        {
          scale: dragStartAnimatedValue,
        },
      ],
      shadowColor: '#000000',
      shadowOpacity: 0.2,
      shadowRadius: 6,
      shadowOffset: {
        width: 1,
        height: 1,
      },
    };
  }, [dragStartAnimatedValue]);

  const getDragStartAnimation = useCallback(
    (itemIndex: number): StyleProp<ViewStyle> => {
      if (activeItemIndex != itemIndex) {
        return;
      }

      const dragStartAnimation = getDefaultDragStartAnimation;
      return {
        zIndex: 3,
        ...dragStartAnimation,
        ...dragStartAnimatedValue,
      } as unknown as StyleProp<ViewStyle>;
    },
    [activeItemIndex, dragStartAnimatedValue, getDefaultDragStartAnimation],
  );

  const addItem = (item: DataType, index: number) => {
    blockPositions.push(getBlockPositionByOrder(items.length));
    orderMap[item.key] = {
      order: index,
    };
    itemMap[item.key] = item;
    items.push({
      key: item.key,
      itemData: item,
      currentPosition: new Animated.ValueXY(getBlockPositionByOrder(index)),
    });
  };

  const removeItem = (item: Item<DataType>) => {
    const itemIndex = findIndex(items, curItem => curItem.key === item.key);
    items.splice(itemIndex, 1);
    blockPositions.pop();
    delete orderMap[item.key];
  };

  const onSelectPage = useCallback(
    (pageId: string, item): void => {
      props.onSelectPage &&
        props.onSelectPage(pageId, [
          ...item.products,
          ...item.variants,
          ...item.pages,
        ]);
    },
    [props],
  );

  const diffData = () => {
    dataCustomized?.length &&
      dataCustomized.forEach((item, index) => {
        if (orderMap[item.key]) {
          if (orderMap[item.key] && orderMap[item.key].order != index) {
            orderMap[item.key].order = index;
            moveBlockToBlockOrderPosition(item.key);
          }
          const currentItem = items.find(i => i.key === item.key);
          if (currentItem) {
            currentItem.itemData = item;
          }
          itemMap[item.key] = item;
        } else {
          addItem(item, index);
        }
      });
    const deleteItems = differenceBy(items, dataCustomized, 'key');
    deleteItems.forEach(item => {
      removeItem(item);
    });
  };
  useEffect(() => {
    const startDragStartAnimation = () => {
      dragStartAnimatedValue.setValue(1);
      Animated.timing(dragStartAnimatedValue, {
        toValue: 1.1,
        duration: 100,
        useNativeDriver: false,
      }).start();
    };
    startDragStartAnimation();
  }, [dragStartAnimatedValue]);
  useEffect(() => {
    const initBlockPositions = () => {
      items.forEach((_, index) => {
        blockPositions[index] = getBlockPositionByOrder(index);
      });
    };
    if (hadInitBlockSize) {
      initBlockPositions();
    }
  }, [
    blockPositions,
    getBlockPositionByOrder,
    gridLayout,
    hadInitBlockSize,
    items,
  ]);
  useEffect(() => {
    resetGridHeight();
  });
  if (hadInitBlockSize) {
    diffData();
  }
  const paginatedData = useMemo(() => {
    if (isModifierOrVariantOptions) {
      return items;
    }
    const result =
      items && items.length > itemPerPage
        ? items.slice(paginationStartIndex, paginationEndIndex)
        : items;

    return result;
  }, [
    itemPerPage,
    items,
    paginationEndIndex,
    paginationStartIndex,
    isModifierOrVariantOptions,
  ]);

  const itemList = useMemo(() => {
    const result =
      paginatedData &&
      paginatedData.map((item, itemIndex) => {
        if (item.itemData.name == '') {
          return (
            <EmptyBlock style={getBlockStyle(itemIndex)}>
              {props.renderItem({}, itemIndex, props.blockType)}
            </EmptyBlock>
          );
        } else {
          return (
            <Block
              onLongPress={setActiveBlock.bind(null, itemIndex, item.itemData)}
              panHandlers={panResponder.panHandlers}
              style={getBlockStyle(itemIndex)}
              dragStartAnimationStyle={getDragStartAnimation(itemIndex)}
              key={item.key}
              typeName={item?.itemData?.__typename}
              productId={item?.itemData?.id}
              modifierGroupsLength={item?.itemData?.modifierGroups?.length}
              variantLength={item.itemData?.options?.length}
              onSelectVariant={props.onSelectVariant}
              onSelectModifier={props.onSelectModifier}
              blockType={props.blockType}
              selectedItem={props.selectedItem}
              onSelectTilePopup={props.onSelectTilePopup}
              onSelectPage={() =>
                onSelectPage(item?.itemData?.id || '', item.itemData)
              }
              color={item?.itemData?.color}
              highlightSelectedItem={props.highlightSelectedItem}
            >
              {props.renderItem(item?.itemData, itemIndex, props.blockType)}
            </Block>
          );
        }
      });
    return result;
  }, [
    getBlockStyle,
    getDragStartAnimation,
    onSelectPage,
    paginatedData,
    panResponder.panHandlers,
    props,
    setActiveBlock,
  ]);

  for (let i = paginatedData.length; i < itemPerPage; i++) {
    itemList.push(
      <EmptyBlock style={getBlockStyle(i)}>
        {props.renderItem({}, i, props.blockType)}
      </EmptyBlock>,
    );
  }

  const hasNext = useMemo(() => {
    if (isModifierOrVariantOptions) {
      return currentPage < props.data?.length;
    } else {
      return currentPage <= totalPageCount;
    }
  }, [
    currentPage,
    isModifierOrVariantOptions,
    props.data?.length,
    totalPageCount,
  ]);

  return (
    <Animated.View
      style={[
        styles.draggableGrid,
        props.style,
        {
          height: gridHeight,
        },
      ]}
      onLayout={assessGridSize}
      testID="draggle-grid-container"
    >
      {hadInitBlockSize && itemList}
      <GridPagination
        scrollDirection={props.scrollDirection}
        currentPage={currentPage}
        totalPages={totalPageCount}
        style={paginationBlockStyle(itemRowIndex)}
        onPressNext={onNext}
        onPressBack={onBack}
        onPressDone={onPressDone}
        next={hasNext}
        back={currentPage - 1 > 0}
        type={props.blockType}
        totalItems={props.data?.length}
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  draggableGrid: {
    flexDirection: 'row',
  },
  blockContainer: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
});

export default DraggableGrid;
