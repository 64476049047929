import { gql } from '@apollo/client';

export const GET_WORKFORCE_PARTNER_CONSENT_URL = gql`
  query getWorkforcePartnerConsentUrl($integrationPartner: App) {
    getWorkforcePartnerConsentUrl(integrationPartner: $integrationPartner)
  }
`;

export const GET_INTEGRATION_PARTNER_CONFIGS = gql`
  query getIntegrationPartnerConfigs($orgId: String) {
    getIntegrationPartnerConfigs(orgId: $orgId) {
      preferences {
        workforce {
          deputy {
            storeLocationMappings {
              locationId
              companyName
              storeId
            }
          }
        }
      }
    }
  }
`;
export const GET_INTEGRATION_PARTNERS_STORE_CONFIGS = gql`
  query getIntegrationPartnerStoreConfigs($store: String) {
    getIntegrationPartnerStoreConfigs(store: $store) {
      id
      store
      preferences {
        syncOrders
        syncUsers
        syncTimesheets
        userSyncDetail {
          lastSyncTime
          numDeputyEmployeesCreated
          numDeputyEmployeesFailed
          numTillUsersCreated
          numTillUsersFailed
        }
        posTerminalAreaMappings {
          posTerminalId
          posTerminalName
          areaId
          areaName
        }
      }
    }
  }
`;

export const SYNC_EMPLOYEES = gql`
  mutation syncEmployees($storeId: String) {
    syncEmployees(storeId: $storeId)
  }
`;

export const GET_AREAS = gql`
  query getAllDeputyAreas($locationId: Int) {
    getAllDeputyAreas(locationId: $locationId) {
      id
      active
      areaName
      companyId
    }
  }
`;

export const LINK_STORES_WITH_DEPUTY_LOCATIONS = gql`
  mutation linkStoresWithDeputyLocations(
    $input: [DeputyStoreLocationLinkInput]
  ) {
    linkStoresWithDeputyLocations(storeLocationLinks: $input)
  }
`;

export const UPDATE_STORE_WITH_DEPUTY_SETTINGS = gql`
  mutation updateStoreWithDeputySettings(
    $input: DeputySettingsInput
    $storeId: String
  ) {
    updateStoreWithDeputySettings(deputySettings: $input, store: $storeId)
  }
`;

export const GET_LOCATIONS = gql`
  query getAllDeputyLocations {
    getAllDeputyLocations {
      id
      active
      companyName
    }
  }
`;

export const OAUTH_CALLBACK = gql`
  mutation createDeputyIntegration($input: String) {
    createDeputyIntegration(input: $input) {
      id
      appName
      isActive
      preferences {
        workforce {
          deputy {
            companyName
          }
        }
      }
      modules {
        workforce
      }
    }
  }
`;

export const TOGGLE_INTEGRATION_CONNECTION = gql`
  mutation toggleWorkforceIntegrationConnection($integrationPartner: App) {
    toggleWorkforceIntegrationConnection(
      integrationPartner: $integrationPartner
    ) {
      id
      appName
      isActive
      preferences {
        workforce {
          deputy {
            companyName
          }
        }
      }
      modules {
        workforce
      }
    }
  }
`;
