import {
  StyleFn,
  Currency,
  ProductAsModifier,
  Product,
} from '@hitz-group/domain';
import {
  ModifierGroup,
  CreateModifierGroupInput,
  DEFAULT_ENTITY_ID,
  UpdateModifierGroupInput,
} from '@hitz-group/domain';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../../components/FormInput/FormInput';
import Button from '../../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import { useNotification } from '../../../../../hooks/Notification';
import { useModifierGroups } from '../../../../../hooks/app/modifierGroups/useModifierGroups';
import { useModifiers } from '../../../../../hooks/app/modifiers/useModifiers';
import { useTaxes } from '../../../../../hooks/app/useTaxes';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import { Operation } from '../../../../../types/Operation';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModifierRow } from './ModifierRow';
import { Modifier as ModifierDefault } from '@hitz-group/domain';
import { findIndex } from 'lodash';
import IconButton from '../../../../../components/Button/IconButton';
import { CreatemodifierModal } from '../ModifiersTab/CreateModifier';
import { useModal } from '@hitz-group/rn-use-modal';
import { Money, ProductPricingInput } from '@hitz-group/domain';
import { usePricingGroups } from '../../../../../hooks/app/usePricingGroups';
import {
  CreateModifierInput,
  ModifierPricingInput,
  ModifierType,
} from '@hitz-group/domain';
import { useProductPricings } from '../../../../../hooks/app/useProductPricings';
import { useModifierPricings } from '../../../../../hooks/app/modifierPricings/useModifierPricings';
import { getBestPriceOfModifier } from '@hitz-group/catalog-helper';
import scale from '../../../../../common/theme';
import DraggableFlatList from '../../../../../components/DraggableFlatList/DraggableFlatList';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { PRODUCT_LIST_SCREEN_FRAGMENT } from '../../../../../hooks/app/products/graphql';

interface CreateModifierInputAlias extends CreateModifierInput {
  tax?: string;
  price?: Money;
}

interface Modifier extends ModifierDefault {
  isSelected?: boolean;
  tax?: string;
}

const BackOfficeContainerStyle: StyleFn = ({ theme }) => ({
  paddingBottom: theme.padding.medium * 1.5,
  width: 545,
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const addButtonStyle: StyleFn = () => ({
  marginTop: 0,
});

const scrollContentWrapper: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  alignItems: 'center',
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});
export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const formInputContainerStyle: StyleFn = ({ theme, width }) => ({
  width: width || 260,
  height: theme.input.height,
  marginBottom: theme.spacing.small * 1.5,
  marginRight: (theme.spacing.big + theme.spacing.medium) / 2,
});

const formInputContainerSelectionStyle: StyleFn = ({ theme, width }) => ({
  width: width || 260,
  height: theme.input.height,
  marginBottom: theme.spacing.small * 1.5,
  marginRight: theme.spacing.small,
});

const prefixTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  alignContent: 'center',
  paddingLeft: theme.padding.medium * 1.5,
});

const rowBlockStyle: StyleFn = () => ({
  flexDirection: 'row',
});

const rowContainerStyle: StyleFn = () => ({
  width: 545,
  flexDirection: 'row',
});

const groupNameStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.big,
  marginRight: 0,
});

const orderLabelStyle: StyleFn = ({}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
});

const draggableContainerStyle: StyleFn = () => ({
  zIndex: 1000,
});

const addIconContainer: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successLight,
  marginRight: 0,
  height: 34,
  width: 34,
  borderRadius: theme.radius.small,
  marginLeft: 'auto',
  marginVertical: 0,
});

const addIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});

const createProductAsModifier = (
  product: Product,
  currency: string,
  index: number,
): ProductAsModifier => {
  return {
    id: product.id,
    name: product.name,
    price: {
      amount: 0,
      currency: currency,
    },
    pricingGroups: [...product.pricingGroups],
    type: ModifierType.PRODUCT,
    isDefault: false,
    priority: index,
  } as ProductAsModifier;
};

export const CreateModifierGroup: React.FC = () => {
  const { css, theme } = useFela();
  const route = useRoute();
  const { translate } = useTranslation();
  const { currency, unAppendCurrency } = useCurrency();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();
  const navigation = useNavigation();
  const [createModifiersData, setCreateModifiersData] = useState(
    {} as CreateModifierInputAlias,
  );
  const [modifierToReplaceAfterAdded, setModifierToReplaceAfterAdded] =
    useState('');
  const [modifierGroup, setModifierGroup] = useState({
    selectionLimit: {
      max: 1,
      min: 1,
    },
    modifiers: [] as Modifier[],
    name: '',
  } as ModifierGroup);

  const modifierGroupIdParam = (
    route.params as {
      modifierGroupId: string;
    }
  ).modifierGroupId;

  const modifierGroupId =
    (modifierGroupIdParam != DEFAULT_ENTITY_ID && modifierGroupIdParam) || '';

  const {
    error: modGroupError,
    loading: modGroupLoading,
    modifierGroups,
    createModifierGroups,
    operation,
    updateModifierGroups,
    getModifierGroupById,
    getAllModifierGroups,
    createdIds: modifierGroupCreatedIds,
  } = useModifierGroups(modifierGroupId);

  const {
    modifiers,
    loading: modLoading,
    getAllModifiers,
    error: modError,
    createModifiers,
    operation: modOperation,
    createdIds,
  } = useModifiers();

  const { taxesOptions, loading: taxesLoading, error: taxesError } = useTaxes();

  const {
    defaultPricingGroup,
    error: PGErr,
    loading: PGLoading,
    getAllPricingGroups,
  } = usePricingGroups();

  const {
    error: errorPP,
    loading: loadingPP,
    operation: PPOperation,
    addProductPricing,
  } = useProductPricings();

  const {
    addModifierPricing,
    error: MPError,
    loading: MPLoading,
    operation: MPOperation,
  } = useModifierPricings();

  const {
    products,
    error: prodErr,
    getAllProducts,
    loading: prodLoading,
  } = useProducts('', PRODUCT_LIST_SCREEN_FRAGMENT);

  const isModifierCreated =
    !modError && !modLoading && modOperation === Operation.CREATE;

  const isProductPricingCreated =
    !errorPP && !loadingPP && PPOperation === Operation.CREATE;

  const isModifierPriceCreated =
    !MPError && !MPLoading && MPOperation === Operation.CREATE;

  const loading =
    modGroupLoading || modLoading || taxesLoading || PGLoading || prodLoading;

  const error = modGroupError || modError || taxesError || PGErr || prodErr;

  useEffect(() => {
    getAllModifiers();
    getAllModifierGroups();
    getAllPricingGroups();
    getAllProducts();
  }, [
    getAllModifiers,
    getAllModifierGroups,
    getAllPricingGroups,
    getAllProducts,
  ]);

  const productsData = useMemo(() => {
    return Object.values(products).filter(product => !product.isCombo);
  }, [products]);

  useEffect(() => {
    if (modifierGroupId && modifierGroups[modifierGroupId]) {
      // update the tab title
      navigation.setOptions({
        tabBarLabel: modifierGroups[modifierGroupId].name,
      });
    }
  }, [navigation, modifierGroups, modifierGroupId, modifierGroupCreatedIds]);

  useEffect(() => {
    if (
      modifierGroupCreatedIds.length &&
      modifierGroups[modifierGroupCreatedIds[0]]
    ) {
      // update the tab title
      navigation.setOptions({
        tabBarLabel: modifierGroups[modifierGroupCreatedIds[0]].name,
      });
    }
  }, [navigation, modifierGroups, modifierGroupId, modifierGroupCreatedIds]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (
      !loading &&
      modifierGroupCreatedIds.length &&
      modifierGroups[modifierGroupCreatedIds[0]]
    ) {
      setModifierGroup(modifierGroups[modifierGroupCreatedIds[0]]);
    }
  }, [modifierGroupId, modifierGroups, loading, modifierGroupCreatedIds]);

  useEffect(() => {
    if (!loading && modifierGroupId && modifierGroups[modifierGroupId]) {
      const modifiers = [...modifierGroups[modifierGroupId].modifiers];
      setModifierGroup({
        ...modifierGroups[modifierGroupId],
        modifiers,
      });
    }
  }, [modifierGroupId, modifierGroups, loading]);

  const modifiersMap = useMemo(() => modifiers, [modifiers]);
  const productsMap = useMemo(() => products, [products]);

  const modifierArrayValues = useMemo(
    () => Object.values(modifiers),
    [modifiers],
  );

  const isOnlyProductAsOption = useMemo(() => {
    if (
      modifierGroup.products &&
      modifierGroup.modifiers.length < modifierGroup.products?.length &&
      modifierGroup.products.some(pro => pro.id !== '')
    ) {
      return true;
    }

    return false;
  }, [modifierGroup]);

  const isOnlyModifierAsOption = useMemo(() => {
    if (
      !modifierGroup.products &&
      modifierGroup.modifiers.some(mod => mod.id !== '')
    ) {
      return true;
    }
    if (
      modifierGroup.products &&
      modifierGroup.products.length < modifierGroup.modifiers.length &&
      modifierGroup.modifiers.some(mod => mod.id !== '')
    )
      return true;
    return false;
  }, [modifierGroup]);

  const modifierOptions = useMemo(() => {
    const assignedModifiers = (modifierGroup.modifiers || []).map(
      x => x && x.id,
    );
    const assignedProducts = (modifierGroup.products || []).map(x => x && x.id);
    let unassignedModifiers: { value: string; label: string }[] = [];

    modifierArrayValues.forEach(x => {
      !assignedModifiers.includes(x.id) &&
        unassignedModifiers.push({
          value: x.id,
          label: x.name,
        });
    });
    productsData
      .filter(prod => !assignedProducts.includes(prod.id))
      .map(prod =>
        unassignedModifiers.push({ value: prod.id, label: prod.name }),
      );
    if (isOnlyModifierAsOption) {
      unassignedModifiers = unassignedModifiers.filter(
        ({ value }) => !productsData.find(pro => pro.id == value),
      );
    }

    if (isOnlyProductAsOption) {
      unassignedModifiers = unassignedModifiers.filter(
        ({ value }) => !modifierArrayValues.find(mod => mod.id == value),
      );
    }
    return unassignedModifiers;
  }, [
    modifierGroup,
    modifierArrayValues,
    productsData,
    isOnlyModifierAsOption,
    isOnlyProductAsOption,
  ]);

  const onChange = useCallback((prop, value) => {
    if (prop === 'min' || prop === 'max') {
      setModifierGroup(prev => ({
        ...prev,
        selectionLimit: {
          ...prev.selectionLimit,
          [prop]: parseInt(value),
        },
      }));
    } else {
      setModifierGroup(prev => ({ ...prev, [prop]: value }));
    }
  }, []);

  useEffect(() => {
    if (
      !error &&
      !loading &&
      operation === Operation.CREATE &&
      modifierGroupCreatedIds.length
    ) {
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupCreatedSuccessfully'),
      });
      if (modifierGroupCreatedIds.length) {
        getModifierGroupById(modifierGroupCreatedIds[0]);
        navigation.navigate('CreateModifierGroup', {
          modifierGroupId: modifierGroupCreatedIds[0],
        });
      }
    }
  }, [
    loading,
    operation,
    showNotification,
    error,
    translate,
    getModifierGroupById,
    modifierGroupCreatedIds,
    navigation,
  ]);

  useEffect(() => {
    if (!error && !loading && operation === Operation.UPDATE) {
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupUpdatedSuccessfully'),
      });
      getAllModifiers();
      getModifierGroupById(modifierGroup.id);
    }
  }, [
    loading,
    operation,
    showNotification,
    error,
    translate,
    modifierGroup,
    getModifierGroupById,
    getAllModifiers,
  ]);

  const updateModifierGroupsData = useCallback(
    (createdModifier?: {
      id: string;
      price: Money;
      type: ModifierType;
      name: string;
    }) => {
      const updateModifierGroupInput = {
        id: modifierGroup.id,
        name: modifierGroup.name.trim(),
        kitchenPrintLabel: modifierGroup.kitchenPrintLabel,
        selectionLimit: {
          max: modifierGroup.selectionLimit.max,
          min: modifierGroup.selectionLimit.min,
        },
        modifiers: (
          (modifierGroup?.modifiers || []).filter(x => x && x.id) as Modifier[]
        ).map((x, index) => ({
          id: x.id,
          price: {
            amount:
              x?.price?.amount === undefined
                ? getBestPriceOfModifier({ ...modifiersMap[x.id] })
                : +x.price?.amount,
            currency: currency as Currency,
          },
          name: x.name || modifiersMap[x.id]?.name,
          type: x.type || modifiersMap[x.id]?.type,
          priority: index,
        })),
        products: (modifierGroup?.products || [])
          .filter(x => x && x.id)
          .map((x, index) => ({
            id: x.id,
            price: {
              amount: x?.price?.amount === undefined ? 0 : +x.price?.amount,
              currency: currency as Currency,
            },
            name: x.name || productsMap[x.id]?.name,
            type: x.type || ModifierType.PRODUCT,
            priority: index,
            isDefault: false,
          })),
      } as UpdateModifierGroupInput;
      if (createdModifier?.id && updateModifierGroupInput.modifiers) {
        const modifiersCopy = [...updateModifierGroupInput.modifiers];
        !updateModifierGroupInput?.modifiers?.find(
          x => x.id === createdModifier.id,
        ) &&
          modifiersCopy.push({
            id: createdModifier.id,
            price: createdModifier.price,
            name: createdModifier.name,
            type: createdModifier.type,
            priority: modifierGroup.modifiers.length,
          });
        updateModifierGroupInput.modifiers = modifiersCopy;
      }
      updateModifierGroups([updateModifierGroupInput]);
    },
    [currency, modifierGroup, modifiersMap, updateModifierGroups, productsMap],
  );

  const onSave = useCallback(() => {
    const minVal = modifierGroup?.selectionLimit?.min;
    const maxVal = modifierGroup?.selectionLimit?.max;
    if (!modifierGroup?.name?.trim().length) {
      // no name
      showNotification({
        error: true,
        message: translate('modifiers.modifierNameIsRequired'),
      });
    } else if (isNaN(minVal) || isNaN(maxVal)) {
      // max value and min value should be numbers
      showNotification({
        error: true,
        message: translate('modifiers.integerError'),
      });
    } else if (maxVal < minVal) {
      // max value should always be more than min value
      showNotification({
        error: true,
        message: translate('modifiers.maxValueError'),
      });
    } else if (!modifierGroup?.id && modifierGroup?.name) {
      // create
      createModifierGroups([
        {
          name: modifierGroup.name.trim(),
          kitchenPrintLabel: modifierGroup.kitchenPrintLabel,
          selectionLimit: modifierGroup.selectionLimit,
          modifiers: modifierGroup.modifiers.map((x, index) => ({
            id: x.id,
            price: { amount: x.price?.amount || 0, currency: currency },
            name: x.name || modifiersMap[x.id]?.name,
            type: x.type || modifiersMap[x.id]?.type,
            priority: index,
          })),
        },
      ] as CreateModifierGroupInput[]);
    } else {
      // update
      updateModifierGroupsData();
    }
  }, [
    modifierGroup,
    createModifierGroups,
    showNotification,
    translate,
    currency,
    modifiersMap,
    updateModifierGroupsData,
  ]);

  const selections = useMemo(() => {
    const selectionTemp = modifierGroup?.selectionLimit;
    return {
      max: selectionTemp?.max || 0,
      min: selectionTemp?.min || 0,
    };
  }, [modifierGroup?.selectionLimit]);

  const onChangeModifier = useCallback(
    (currentModifierId, newModifierId, rowIndex) => {
      setModifierGroup(prev => {
        const prevTemp = { ...prev };

        if (
          prevTemp.modifiers.every(mod => mod.id === '') &&
          prevTemp.products?.every(prod => prod.id === '')
        ) {
          if (modifiers?.[newModifierId]) {
            const index = currentModifierId
              ? findIndex(prev.modifiers, { id: currentModifierId })
              : rowIndex;

            if (index >= 0 && modifiers?.[newModifierId]) {
              prevTemp.modifiers[index] = {
                ...modifiers?.[newModifierId],
              } as Modifier;

              prevTemp.products = [];
            }
          }

          if (products?.[newModifierId]) {
            const indexOfProduct = currentModifierId
              ? findIndex(prev.products, {
                  id: currentModifierId,
                })
              : rowIndex;
            if (indexOfProduct >= 0 && products?.[newModifierId]) {
              prevTemp.products[indexOfProduct] = createProductAsModifier(
                products[newModifierId],
                currency,
                indexOfProduct,
              );
              prevTemp.modifiers = [];
            }
          }
        } else if (prevTemp.modifiers.length) {
          const index = currentModifierId
            ? findIndex(prev.modifiers, { id: currentModifierId })
            : rowIndex;
          if (index >= 0 && modifiers?.[newModifierId]) {
            prevTemp.modifiers[index] = {
              ...modifiers?.[newModifierId],
            } as Modifier;
          }
        } else if (prevTemp?.products?.length) {
          const indexOfProduct = currentModifierId
            ? findIndex(prev.products, {
                id: currentModifierId,
              })
            : rowIndex;
          if (indexOfProduct >= 0 && products?.[newModifierId]) {
            prevTemp.products[indexOfProduct] = createProductAsModifier(
              products[newModifierId],
              currency,
              indexOfProduct + 1,
            );
          }
        }

        return prevTemp;
      });
    },
    [currency, modifiers, products],
  );

  const onChangePrice = useCallback(
    (modId, value) => {
      setModifierGroup(prev => {
        const tempPrev = { ...prev };
        const modifierValIndex = findIndex(tempPrev.modifiers, { id: modId });
        const productModifierIndex = findIndex(tempPrev.products, {
          id: modId,
        });
        if (modifierValIndex >= 0) {
          tempPrev.modifiers[modifierValIndex] = {
            ...tempPrev.modifiers[modifierValIndex],
            price: {
              amount: unAppendCurrency(value),
              currency: currency,
            },
          } as unknown as Modifier;
        }
        if (productModifierIndex >= 0 && tempPrev.products?.length) {
          tempPrev.products[productModifierIndex] = {
            ...tempPrev.products[productModifierIndex],
            price: {
              amount: unAppendCurrency(value),
              currency: currency,
            },
          } as unknown as ProductAsModifier;
        }
        return tempPrev;
      });
    },
    [unAppendCurrency, currency],
  );

  const onDeleteRow = useCallback(
    (currentModifierId, rowIndex) => {
      setModifierGroup(prev => {
        const prevTemp = { ...prev };
        if (!currentModifierId) {
          prevTemp.modifiers.splice(rowIndex, 1);
          (prevTemp?.products || []).splice(rowIndex, 1);
        }

        if (modifiers?.[currentModifierId]) {
          const index = currentModifierId
            ? findIndex(prev.modifiers, { id: currentModifierId })
            : rowIndex;
          prevTemp.modifiers.splice(index, 1);
        }
        if (products?.[currentModifierId]) {
          prevTemp.products = prevTemp.products?.filter(
            prod => prod.id !== currentModifierId,
          );
        }
        return prevTemp;
      });
    },
    [modifiers, products],
  );

  const addModifierColumn = useCallback(() => {
    // add empty modifier column
    setModifierGroup(prev => {
      const prevTemp = { ...prev };
      const modifiersCopy = [...prevTemp.modifiers];
      const productsCopy = [...(prevTemp?.products || [])];

      if (!modifiersCopy?.length && !productsCopy.length) {
        prevTemp.modifiers = [{ id: '' }] as Modifier[];
        prevTemp.products = [{ id: '' }] as ProductAsModifier[];
        return { ...prevTemp };
      }

      if (modifiersCopy?.length > 0) {
        modifiersCopy.push({ id: '' } as Modifier);
      }

      if (productsCopy?.length > 0) {
        productsCopy.push({ id: '' } as ProductAsModifier);
      }

      return {
        ...prevTemp,
        modifiers: modifiersCopy,
        products: productsCopy,
      };
    });
  }, []);

  const onCreateModifier = useCallback(
    (input: CreateModifierInputAlias): void => {
      createModifiers([
        {
          name: input.name,
          modifierGroups: input.modifierGroups,
          type: input.type,
        },
      ]);
      setCreateModifiersData(input);
    },
    [createModifiers],
  );

  const modifierGroupsDropDownData = useMemo(() => {
    return Object.values(modifierGroups).map(x => ({
      value: x.id,
      label: x.name,
    }));
  }, [modifierGroups]);

  const onAddModifier = useCallback(
    (modifierName: string, modifierIdToReplace: string) => {
      if (defaultPricingGroup?.id) {
        showModal(
          <CreatemodifierModal
            modifierGroups={modifierGroupsDropDownData}
            onCreate={onCreateModifier}
            taxesOptions={taxesOptions}
            modifierDefaultValues={{
              name: modifierName,
              modifierGroups: [modifierGroup.id],
            }}
          />,
        );
        setModifierToReplaceAfterAdded(modifierIdToReplace);
      } else {
        showNotification({
          error: true,
          message: translate('productSettings.defaultPricingGroupMissing'),
        });
      }
    },
    [
      modifierGroupsDropDownData,
      onCreateModifier,
      taxesOptions,
      showModal,
      modifierGroup,
      showNotification,
      defaultPricingGroup,
      translate,
    ],
  );

  useEffect(() => {
    if (
      isModifierCreated &&
      defaultPricingGroup &&
      createdIds.length &&
      createModifiersData.type === ModifierType.MODIFIER
    ) {
      addModifierPricing(createdIds[0], [
        {
          pricingGroupId: defaultPricingGroup.id,
          modifierPricing: {
            sellingPrice: createModifiersData?.price,
            sellingTax: createModifiersData?.tax,
          } as ModifierPricingInput,
        },
      ]);
    }
  }, [
    isModifierCreated,
    defaultPricingGroup,
    createdIds,
    addModifierPricing,
    createModifiersData,
  ]);

  useEffect(() => {
    if (
      isModifierCreated &&
      defaultPricingGroup &&
      createdIds.length &&
      createModifiersData.type === ModifierType.PRODUCT
    ) {
      addProductPricing(createdIds[0], [
        {
          pricingGroupId: defaultPricingGroup.id,
          productPricing: {
            sellingPrice: createModifiersData?.price,
            sellingTax: createModifiersData?.tax,
          } as ProductPricingInput,
        },
      ]);
    }
  }, [
    isModifierCreated,
    defaultPricingGroup,
    createdIds,
    addProductPricing,
    createModifiersData,
  ]);

  useEffect(() => {
    if (
      createModifiersData?.name &&
      (isProductPricingCreated || isModifierPriceCreated) &&
      isModifierCreated &&
      createdIds.length
    ) {
      closeModal();
      const modifiersData = modifiers?.[createdIds?.[0]];
      if (modifiersData && modifiersData?.id && modifiersData?.name) {
        updateModifierGroupsData({
          id: createdIds[0],
          price:
            createModifiersData?.price || ({ amount: 0, currency } as Money),
          type: createModifiersData.type,
          name: createModifiersData.name,
        });
      }
    }
  }, [
    modifiers,
    createModifiersData,
    isProductPricingCreated,
    isModifierPriceCreated,
    isModifierCreated,
    createdIds,
    closeModal,
    updateModifierGroupsData,
    currency,
  ]);

  useEffect(() => {
    if (!loading && createdIds.length && modOperation === Operation.CREATE) {
      onChangeModifier(modifierToReplaceAfterAdded || '', createdIds[0], '');
    }
  }, [
    createdIds,
    modifierToReplaceAfterAdded,
    modOperation,
    onChangeModifier,
    loading,
  ]);

  const onDragEnd = useCallback((data: Array<Modifier>) => {
    setModifierGroup(prev => {
      const tempPrev = { ...prev };
      if (tempPrev.modifiers.length) {
        tempPrev.modifiers = data.map((modifierRow, index) => ({
          ...modifierRow,
          priority: index,
        }));
      } else if (tempPrev.products?.length) {
        tempPrev.products = data.map(
          (modifierRow, index) =>
            ({
              id: modifierRow.id,
              name: modifierRow.name,
              price: modifierRow.price,
              isDefault: modifierRow.isDefault,
              priority: index,
            } as unknown as ProductAsModifier),
        );
      }
      return tempPrev;
    });
  }, []);

  const getProductAsModifier = useCallback(
    (id, index) => {
      const product = products?.[id];
      if (product) {
        const dataProductAsModifier = createProductAsModifier(
          product,
          currency,
          index,
        );
        return {
          ...dataProductAsModifier,
          modifierGroups: [],
        } as unknown as Modifier;
      }
    },
    [products, currency],
  );

  const getDataList = useCallback(() => {
    if (modifierGroup?.modifiers.length) return modifierGroup.modifiers;
    return (modifierGroup.products || []).map(prodMod => ({
      ...prodMod,
      id: prodMod.id,
    }));
  }, [modifierGroup]);

  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollContentWrapper)}>
          <BackOfficeSection
            contentContainerStyle={css(BackOfficeContainerStyle)}
            titleBorderBottom
            title={translate('modifiers.general')}
          >
            <View style={css(rowContainerStyle)}>
              <FormInput
                error={false}
                placeholder={translate('modifiers.groupName')}
                title={translate('modifiers.groupName')}
                value={modifierGroup?.name || ''}
                alignTitle="left"
                containerStyle={css(formInputContainerStyle)}
                onChangeText={onChange.bind(null, 'name')}
                maxLength={50}
              />

              <FormInput
                error={false}
                testID="kotLabel"
                placeholder={translate('modifiers.kotLabel')}
                title={translate('modifiers.kotLabel')}
                value={modifierGroup?.kitchenPrintLabel || ''}
                alignTitle="left"
                containerStyle={css(formInputContainerStyle)}
                onChangeText={onChange.bind(null, 'kitchenPrintLabel')}
                maxLength={50}
              />
            </View>

            <View style={css(rowContainerStyle)}>
              <View style={css(rowBlockStyle)}>
                <FormInput
                  error={false}
                  title={translate('modifiers.selections')}
                  value={selections?.min + ''}
                  alignTitle="left"
                  containerStyle={css(
                    formInputContainerSelectionStyle({ theme, width: 125 }),
                  )}
                  onChangeText={value => onChange('min', value)}
                  prefix={{
                    text: translate('productSettings.min') + ':',
                    textStyle: css(prefixTextStyle),
                  }}
                  type={'number'}
                />

                <FormInput
                  error={false}
                  value={selections?.max + ''}
                  title={' '}
                  alignTitle="left"
                  containerStyle={css(
                    formInputContainerSelectionStyle({ theme, width: 125 }),
                  )}
                  onChangeText={value => onChange('max', value)}
                  prefix={{
                    text: translate('productSettings.max') + ':',
                    textStyle: css(prefixTextStyle),
                  }}
                  type={'number'}
                />
              </View>
            </View>
          </BackOfficeSection>

          {modifierGroup?.id && (
            <BackOfficeSection
              contentContainerStyle={css(BackOfficeContainerStyle)}
              titleBorderBottom
              title={translate('modifiers.assignModifiers')}
              actionContainerStyle={css(addButtonStyle)}
            >
              <DraggableFlatList
                columns={[
                  {
                    title: translate('modifiers.order'),
                    width: 40,
                    alignItems: 'flex-start',
                    containerStyle: css(orderLabelStyle),
                  },
                  {
                    title: translate('modifiers.modifier'),
                    width: 340,
                    alignItems: 'flex-start',
                    containerStyle: css(groupNameStyle),
                  },
                  {
                    title: translate('backOfficeProducts.productPrice'),
                    alignItems: 'flex-start',
                    width: 90,
                  },
                  {
                    title: (
                      <IconButton
                        icon="plus"
                        iconSize={18}
                        containerSize={28}
                        containerStyle={css(addIconContainer)}
                        iconStyle={css(addIconStyle)}
                        onPress={addModifierColumn}
                      />
                    ),
                    width: 34,
                  },
                ]}
                containerStyle={css(draggableContainerStyle)}
                onDragEnd={(data: Array<Modifier>) => onDragEnd(data)}
                data={getDataList()}
                normalRows
                renderRow={(
                  item: Modifier,
                  index: number,
                  drag: () => void,
                ): React.ReactNode => (
                  <ModifierRow
                    modifier={
                      ({
                        ...(modifiersMap[item?.id] ||
                          getProductAsModifier(item?.id, index)),
                        price: item.price,
                      } || {}) as Modifier
                    }
                    availableModifiers={modifierOptions}
                    onChangeModifier={onChangeModifier}
                    onDeleteRow={onDeleteRow}
                    rowIndex={index}
                    onAddModifier={onAddModifier}
                    onChangePrice={onChangePrice}
                    drag={drag}
                    isOnlyShowProductAsOption={isOnlyProductAsOption}
                    isOnlyShowModifierOption={isOnlyModifierAsOption}
                  />
                )}
              />
            </BackOfficeSection>
          )}
          <View style={css(bottomSpace)}></View>
        </ScrollView>
      </View>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onSave}
          />
        </View>
      </View>
    </>
  );
};
