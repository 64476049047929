import { gql } from '@apollo/client';

export const FRAGMENTS = {
  printerTemplate: gql`
    fragment PrinterTemplateDetails on PrinterTemplate {
      id
      name
      template
      isDeleted
      organization
      templateType
    }
  `,
  devicePrinterSetup: gql`
    fragment DevicePrinterSetupDetails on PrintingOptions {
      printerProfileType
      template {
        id
      }
      printer {
        id
      }
      profile {
        id
      }
    }
  `,
};

export const GET_PRINTER_TEMPLATES = gql`
  query printerTemplates {
    printerTemplates {
      ...PrinterTemplateDetails
    }
  }
  ${FRAGMENTS.printerTemplate}
`;

export const GET_PRINTER_TEMPLATE = gql`
  query printerTemplate($id: ID!) {
    printerTemplate(id: id) {
      ...PrinterTemplateDetails
    }
  }
  ${FRAGMENTS.printerTemplate}
`;

export const CREATE_OR_UPDATE_PRINTING_SETUP = gql`
  mutation createOrUpdateDevicePrintingOptions(
    $input: CreateOrUpdateDevicePrinterSetupInput!
  ) {
    createOrUpdateDevicePrintingOptions(input: $input) {
      ...DevicePrinterSetupDetails
    }
  }
  ${FRAGMENTS.devicePrinterSetup}
`;
