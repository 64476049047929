import React from 'react';
import { View } from 'react-native';

interface MenuRowProps {
  onChange?: (id: string, prop: string, value: string | boolean) => void;
  onPressCopy?: (id: string) => void;
}

export const CustomerOrders: React.FC<MenuRowProps> = ({}: MenuRowProps) => {
  return <View></View>;
};
