/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import {
  FeatureContext,
  Page,
  Product,
  RenderProps,
  StyleFn,
  Variant,
} from '@hitz-group/domain';
import {
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  Product as ProductAlias,
  ModifierAlias,
  ProductModifierGroup,
  Features,
} from '@hitz-group/domain';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FelaComponent, useFela, withTheme } from 'react-fela';
import { View } from 'react-native';
import { MemoCatalogGridView as CatalogGridView } from './CatalogGridView';
import CatalogItem, { CatalogItemProps } from './CatalogItem';
import IconButton from '../Button/IconButton';
import { keyBy } from 'lodash';
import { useNotification } from '../../hooks/Notification';
import { translate } from '@hitz-group/localization';
import { isWeb, isAndroid } from '../../common/theme';
import { changeDueVar } from '../../App';
import { useCheckFeatureEnabled } from '../../../src/hooks/app/features/useCheckFeatureEnabled';
import RequiredModifierPrompt from './RequiredModifierPrompt';
import { useModal } from '@hitz-group/rn-use-modal';

export interface Action {
  name: string;
  color?: string;
  onPress: () => void;
  isGroup: boolean;
  feature?: Features;
  featureContext?: FeatureContext;
}

export type CartSelectionState = {
  item: string;
  modifier?: string;
  variant?: string;
  product?: string;
  modifierGroup?: string;
};

export interface CatalogDisplayProps {
  testID?: string;
  pages: Array<Page>;
  allProducts: Record<string, Product>;
  allVariants: Record<string, Variant>;
  selectedProduct?: Product | Variant;
  selectedVariantKey?: string;
  actions?: Action[];
  selectedItem?: CartSelectionState;
  isCartItemDeleted?: boolean;
  selectedModifiers?: { [key: string]: string[] };
  orderId?: string;
  showRequiredModifierModal: boolean;
  onSelectNewPage: () => void;
  addProductToCart: (item: Product | Variant) => void;
  addModifierToProduct: (
    item: Partial<Product>,
    selectedModifiers: CatalogModifier[],
    orderItemId?: string,
  ) => void;
  addVariantToProduct: (selectedOptions: string[], item: Variant) => void;
  removeItemFromCart: (item: string, type: 'Variant' | 'Product') => void;
  replaceVariant: (
    orderItem: string,
    selectedProduct: Variant,
    selectedOptions: string[],
  ) => void;
  onSelectProduct?: (id: string) => void;
  unselectCartItem: () => void;
  setCartItemDeleted?: (value: boolean) => void;
  setShowRequiredModifierModal: (val: boolean) => void;
}

export interface CatalogModifier {
  id: string;
  name: string;
  type: ModifierAlias | 'BACK' | 'CHECK';
  modifierGroup?: string;
  isSelected: boolean;
  isDefault: boolean;
}

export interface CatalogModifierGroup {
  id: string; // id of mod group
  modifiers: string[]; // stores selected / default modifiers
  isSelected: boolean; // true when selection is done
  isQualified: boolean; // true when it satifies the limit of mod group
  isChanged: boolean; // to track if it is changed
  isGrouped?: boolean; // modifier group is grouped or not
  minLimit: number;
  maxLimit: number;
  defaultMods: string[];
  selectedDefaultMods: number;
}

const modifierSelectionCompleted = 'COMPLETED';

const body: StyleFn = () => ({
  flex: 1,
  flexDirection: 'row',
});

const pageSubContainer: StyleFn = () => ({
  flex: 1,
  flexDirection: 'column',
});

const pageContainer: StyleFn = ({ theme }) => ({
  width: isWeb ? 180 : isAndroid ? 140 : 130,
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingLeft: theme.padding.small + 2,
  paddingRight: theme.padding.small + 2,
  marginTop: 10,
  marginBottom: 7,
});

const productContainer: StyleFn = () => ({
  flex: 0.5,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  marginTop: 10,
});

const variantContainer: StyleFn = () => ({
  flex: 0.3,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  marginTop: isWeb ? 10 : 0,
});

const extrasContainer: StyleFn = () => ({
  flex: 0.2,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  marginTop: isWeb ? 10 : 0,
});

const eightNoOfRowPageSeparator: StyleFn = () => ({
  marginTop: isWeb ? 12 : 20,
});
const fifthNoOfRowPageSeparator: StyleFn = () => ({
  marginTop: isWeb ? 15 : 24,
});

const catalogItemStyle: StyleFn = ({ theme, rows, columns }) => ({
  width: `${(isWeb ? 99 : 96.5) / columns}%`,
  paddingHorizontal: columns > 1 ? (isWeb ? 2 : 0) : 0,
  paddingVertical: isWeb ? 0 : columns > 1 ? 1 : 0,
  borderRadius: theme.radius.small,
  height: columns > 1 ? `${91 / rows}%` : `${89 / rows}%`,
  overflow: 'hidden',
  justifyContent: 'center',
  alignSelf: 'stretch',
});

const backLabelStyling: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.larger,
});

const selectItemStyle: StyleFn = ({ theme, rows, columns }) => ({
  backgroundColor: theme.colors.white,
  width: `${(isWeb ? 94 : 96.5) / columns}%`,
  borderRadius: theme.radius.small,
  height: columns > 1 ? `${91 / rows}%` : `${89 / rows}%`,
  overflow: 'hidden',
  justifyContent: 'center',
  marginHorizontal: 2,
});

const tickContainerStyle: StyleFn = () => ({
  height: '100%',
  width: '100%',
});
const labelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
});

const modifierPromptGridViewStyle: StyleFn = ({}) => ({
  flex: 1,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  marginTop: isWeb ? 10 : 0,
});

export const StyledCatalogItem: React.FC<
  CatalogItemProps & { rows: number; columns: number; containerStyle?: StyleFn }
> = ({ rows, columns, containerStyle, ...props }) => (
  <FelaComponent
    rows={rows}
    columns={columns}
    style={[catalogItemStyle, containerStyle]}
  >
    {({ style }: RenderProps) => (
      <CatalogItem {...props} containerStyle={style} />
    )}
  </FelaComponent>
);

const Catalog: React.FC<CatalogDisplayProps> = ({
  pages,
  allProducts,
  allVariants,
  actions,
  addProductToCart,
  addModifierToProduct,
  addVariantToProduct,
  selectedProduct: selectedProductProp,
  onSelectNewPage,
  selectedVariantKey,
  replaceVariant,
  onSelectProduct,
  removeItemFromCart,
  selectedItem,
  unselectCartItem,
  selectedModifiers,
  orderId,
  showRequiredModifierModal,
  setShowRequiredModifierModal,
}: CatalogDisplayProps) => {
  const { css, theme } = useFela();
  const { showModal, closeModal } = useModal();
  const [selectedPage, setSelectedPage] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<Product | Variant>();
  const [currOptionIndx, setCurrOptionIndx] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedModGroup, setSelectedModGroup] = useState<string>('');
  const [showRequiredModifierPrompt, setShowRequiredModifierPrompt] =
    useState(false);
  const [selectedProductModifierGroups, setSelectedProductModifierGroups] =
    useState<Record<string, CatalogModifierGroup>>({});
  const isShowingRequiredModifier = useRef(false);
  const pagesMap = useMemo(() => {
    return keyBy(pages, 'id');
  }, [pages]);

  const isFeatureEnabled = useCheckFeatureEnabled();

  const { showNotification } = useNotification();

  useEffect(() => {
    // un select product when a new order initiated
    if (orderId) {
      setSelectedProduct({} as Product);
    }
  }, [orderId]);

  useEffect(() => {
    if (selectedProductProp) {
      setSelectedProduct(selectedProductProp);
      setSelectedProductModifierGroups({});
      setSelectedModGroup('');
    }
  }, [selectedProductProp]);

  useEffect(() => {
    if (
      !!selectedVariantKey &&
      selectedProduct &&
      (selectedProduct as Variant).options
    ) {
      const optionsIndex = (selectedProduct as Variant).options?.findIndex(
        elem => elem.key === selectedVariantKey,
      );
      setCurrOptionIndx(optionsIndex >= 0 ? optionsIndex : 0);
    } else {
      setCurrOptionIndx(0);
    }
  }, [selectedProduct, selectedVariantKey]);

  const productModifierGroupsMap: {
    [key: string]: ProductModifierGroup;
  } = useMemo(() => {
    return keyBy((selectedProduct as ProductAlias)?.modifierGroups || [], 'id');
  }, [selectedProduct]);

  useEffect(() => {
    // when selected Item changes and when it is undefined
    // this helps remove modifiers when unselected from modifiers selection tab
    if (selectedItem === undefined) {
      setSelectedProduct({} as Product);
    }
  }, [selectedItem]);

  useEffect(() => {
    const lengthOfSelectedProdModGroups = Object.keys(
      selectedProductModifierGroups,
    ).length;
    if (
      !lengthOfSelectedProdModGroups &&
      (selectedProduct as ProductAlias)?.modifierGroups?.length
    ) {
      const tempSelectedProdModGroups = { ...selectedProductModifierGroups };
      const modGroups = (selectedProduct as ProductAlias)?.modifierGroups || [];
      modGroups.forEach(eachModGroup => {
        const defaultMods =
          eachModGroup.modifiers.filter(x => x && x.isDefault) || [];

        const maxLimit =
          productModifierGroupsMap?.[eachModGroup?.id]?.selectionLimit?.max ||
          0;

        const { isRequired } = productModifierGroupsMap?.[eachModGroup?.id];
        const actualLimit =
          productModifierGroupsMap?.[eachModGroup?.id]?.selectionLimit?.min ||
          0;
        const minLimit = isRequired && actualLimit < 1 ? 1 : actualLimit;

        const isQualified =
          (minLimit <= defaultMods.length && maxLimit >= defaultMods.length) ||
          eachModGroup.name === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME;
        // selects all modifier groups except selected item modifier group in cart
        const isSelected =
          selectedItem && !(selectedItem?.modifierGroup === eachModGroup.id);
        /**
         * updates state variable of selected product mod group
         * id: id of mod group;
         * modifiers: stores selected / default modifiers
         * isSelected: true when selection is done
         * isQualified: true when it satifies the limit of mod group
         * isChanged:  to track if it is changed by user
         */
        tempSelectedProdModGroups[eachModGroup.id] = {
          id: eachModGroup.id,
          isQualified: isQualified,
          isSelected: isSelected || false,
          isChanged: false,
          minLimit: minLimit,
          maxLimit: maxLimit,
          isGrouped: eachModGroup?.isGrouped,
          defaultMods: defaultMods.map(x => x && x.id),
          selectedDefaultMods: defaultMods.length,
          modifiers:
            (defaultMods.length && defaultMods.map(x => x && x.id)) || [],
        };

        if (!eachModGroup?.isGrouped) {
          // if not grouped min and max limit will not be applied and hence qualified
          tempSelectedProdModGroups[eachModGroup.id] = {
            ...tempSelectedProdModGroups[eachModGroup.id],
            isQualified: true,
          };
        }

        const defaultModifiersMap =
          (defaultMods.length && keyBy(defaultMods, 'id')) || {};

        if (selectedModifiers && selectedModifiers[eachModGroup.id]) {
          // if default mod group there in seleccted mod list then we have to remove
          selectedModifiers[eachModGroup.id].forEach(x => {
            if (defaultModifiersMap[x]) {
              const index =
                tempSelectedProdModGroups[eachModGroup.id].modifiers.indexOf(x);
              if (index >= 0) {
                tempSelectedProdModGroups[eachModGroup.id].modifiers.splice(
                  index,
                  1,
                );
                // if default mod removed then its selection count also should decrease
                tempSelectedProdModGroups[eachModGroup.id].selectedDefaultMods =
                  tempSelectedProdModGroups[eachModGroup.id]
                    .selectedDefaultMods - 1;
              }
            } else if (
              !tempSelectedProdModGroups[eachModGroup.id].modifiers.includes(x)
            ) {
              tempSelectedProdModGroups[eachModGroup.id].modifiers.push(x);
              tempSelectedProdModGroups[eachModGroup.id].isQualified = true;
              tempSelectedProdModGroups[eachModGroup.id].isChanged = true;
            }
          });
        }
      });
      setSelectedProductModifierGroups(tempSelectedProdModGroups);
    }
  }, [
    selectedProduct,
    selectedProductModifierGroups,
    productModifierGroupsMap,
    selectedModifiers,
    addModifierToProduct,
    selectedItem,
  ]);

  const onSelectPage = useCallback(
    (pageId: string): void => {
      setSelectedPage(pageId);
      onSelectNewPage && onSelectNewPage();
    },
    [setSelectedPage, onSelectNewPage],
  );

  const renderRequiredModGroups = useCallback(() => {
    const requiredModGroup = (selectedProduct as ProductAlias)?.modifierGroups
      .filter(x => x && x?.isRequired)
      .sort((a, b) => b.priority - a.priority);
    // selects required modifier group if requried mod group selection is not yet done

    if (requiredModGroup.length && !selectedModGroup) {
      let currentModifierGroupId = '';
      requiredModGroup.forEach(eachModGroup => {
        if (!selectedProductModifierGroups?.[eachModGroup.id]?.isSelected) {
          currentModifierGroupId = eachModGroup.id;
        }
      });
      if (currentModifierGroupId) {
        setSelectedModGroup(currentModifierGroupId);
        setShowRequiredModifierPrompt(true);
      } else {
        setShowRequiredModifierPrompt(false);
      }
    }
  }, [selectedModGroup, selectedProduct, selectedProductModifierGroups]);

  const modifierGroupsToDisplay: ProductModifierGroup[] = useMemo(() => {
    const prodModGroupDetails = productModifierGroupsMap[selectedModGroup];
    if (
      selectedModGroup &&
      (prodModGroupDetails?.isRequired || prodModGroupDetails?.isGrouped)
    ) {
      // returns modifiers of selected modifier groups
      return [prodModGroupDetails] as ProductModifierGroup[];
    }

    // returns all modifier groups if not selected any modifier group
    return (selectedProduct as ProductAlias)?.modifierGroups || [];
  }, [productModifierGroupsMap, selectedModGroup, selectedProduct]);

  const mainPages = useMemo(() => {
    return (pages || []).filter(x => x && !x.isSubPage);
  }, [pages]);

  const page =
    mainPages.length && (selectedPage ? pagesMap[selectedPage] : mainPages[0]);

  const pageContent = useMemo(
    () =>
      (page &&
        [
          ...(page.products || []),
          ...(page.pages
            ?.filter(x => x && pagesMap[x.id])
            .map(x => ({ ...pagesMap[x.id], priority: x.priority })) || []),
          ...(page.variants || []),
        ].sort((a, b) => (a.priority || 0) - (b.priority || 0))) ||
      [],
    [page, pagesMap],
  );

  useEffect(() => {
    if (selectedItem?.modifier && selectedItem?.item) {
      const product = allProducts[selectedItem?.product as string];
      setSelectedProduct(product);
      setSelectedModGroup(selectedItem?.modifierGroup || '');
      setSelectedProductModifierGroups({});
    } else if (selectedItem?.product) {
      const product = allProducts[selectedItem?.product as string];
      if (product?.modifierGroups?.length) {
        setSelectedProduct(product);
        setSelectedModGroup(DEFAULT_PRODUCT_MODIFIER_GROUP_NAME);
        setSelectedProductModifierGroups({});
      }
    }
  }, [selectedItem, page, allProducts]);

  useEffect(() => {
    if (selectedItem?.variant && selectedItem?.item) {
      const variantProduct = allVariants[selectedItem.variant];
      if (
        selectedProduct &&
        (selectedProduct as Variant).id !== selectedItem.variant
      ) {
        setSelectedOptions([]);
      }
      if (variantProduct) setSelectedProduct(variantProduct);
    }
  }, [allVariants, selectedItem, selectedProduct]);

  /**
   * Filters out the available variant options to render
   */
  const productContent: any[] = useMemo((): any[] => {
    if (selectedProduct && (selectedProduct as Variant).options?.length) {
      let options = (selectedProduct as Variant).options[currOptionIndx]
        ?.values;

      let productOptions = (selectedProduct as Variant).products.reduce(
        (accumulator: string[], prod) => {
          prod.optionValues.forEach(optionValue =>
            accumulator.push(optionValue.value),
          );
          return accumulator;
        },
        [],
      );

      productOptions = [...new Set(productOptions)];

      if (selectedOptions && selectedOptions.length) {
        options = options.reduce((filteredOptions, op) => {
          const isProductExist = (selectedProduct as Variant).products.some(
            prod => {
              if (selectedVariantKey) {
                return prod.optionValues.find(
                  optionValue =>
                    optionValue.key === selectedVariantKey &&
                    optionValue.value === op,
                );
              }
              const prodOptions = prod.optionValues.map(
                optionValue => optionValue.value,
              );
              return (
                prodOptions.includes(op) &&
                selectedOptions.every(selected =>
                  prodOptions.includes(selected),
                )
              );
            },
          );

          if (isProductExist) {
            filteredOptions.push(op);
          }

          return filteredOptions;
        }, [] as any);
      } else {
        options = options.filter(op => {
          return productOptions.includes(op);
        });
      }
      return options;
    }
    return [];
  }, [selectedProduct, currOptionIndx, selectedOptions, selectedVariantKey]);

  const variantProducts = useMemo(() => {
    if (selectedProduct && (selectedProduct as Variant).products?.length) {
      return (selectedProduct as Variant).products;
    }
    return [];
  }, [selectedProduct]);

  const productModifierGroups: CatalogModifier[] = useMemo(() => {
    if (
      selectedProduct &&
      (selectedProduct as ProductAlias)?.modifierGroups?.length
    ) {
      const result: CatalogModifier[] = [];

      renderRequiredModGroups();

      if (selectedModGroup === modifierSelectionCompleted) {
        // when done with selection of mod or mod group returns empty array
        return [];
      }

      const modifierGroupsToShow: ProductModifierGroup[] =
        modifierGroupsToDisplay;
      let isModifierGroupsQualifiedToBeChecked = true;

      modifierGroupsToShow.forEach(modGroup => {
        const isSelected =
          selectedProductModifierGroups?.[modGroup.id]?.isSelected;

        isModifierGroupsQualifiedToBeChecked =
          isModifierGroupsQualifiedToBeChecked &&
          selectedProductModifierGroups?.[modGroup.id]?.isQualified;

        // when not grouped or when group is default group or when its mod group is required and selection not completed we show only modifiers
        if (
          (!modGroup?.isGrouped ||
            modGroup.name === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME ||
            (selectedModGroup === modGroup.id && modGroup.isRequired)) &&
          !(isSelected && (modGroup?.isGrouped || modGroup?.isRequired))
        ) {
          // loads required and non grouped modifier groups's modifiers
          modGroup.modifiers.forEach(mod => {
            const isSelectedMod = selectedProductModifierGroups[
              modGroup.id
            ]?.modifiers?.includes(mod.id);
            result.push({
              id: mod.id,
              name: mod.name,
              type: ModifierAlias.Modifier,
              modifierGroup: modGroup.id,
              isSelected: isSelectedMod || false,
              isDefault: mod?.isDefault || false,
            });
          });
        } else if (
          (!selectedModGroup ||
            selectedModGroup === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) &&
          !modGroup?.isRequired
        ) {
          // loads non required and grouped modifier groups
          result.push({
            id: modGroup.id,
            name: modGroup.name,
            type: ModifierAlias.ModifierGroup,
            isSelected: false,
            isDefault: false,
          });
        } else if (
          selectedModGroup &&
          selectedModGroup === modGroup.id &&
          modGroup?.isGrouped
        ) {
          // load modifiers when selected is optional mod group
          !modGroup?.isRequired &&
            result.push({
              id: 'BACK',
              name: '<-',
              type: 'BACK',
              isSelected: false,
              isDefault: false,
            });
          modGroup.modifiers.forEach(mod => {
            const isSelecteddMod = selectedProductModifierGroups[
              modGroup.id
            ]?.modifiers?.includes(mod.id);
            result.push({
              id: mod.id,
              name: mod.name,
              type: ModifierAlias.Modifier,
              modifierGroup: modGroup.id,
              isSelected: isSelecteddMod || false,
              isDefault: mod?.isDefault || false,
            });
          });
        }
      });
      if (isModifierGroupsQualifiedToBeChecked && result.length >= 13) {
        // adding check button
        result.splice(13, 0, {
          id: 'CHECK',
          name: '',
          type: 'CHECK',
          isSelected: false,
          isDefault: false,
        });
      }
      return result;
    }
    return [];
  }, [
    selectedProduct,
    selectedProductModifierGroups,
    selectedModGroup,
    modifierGroupsToDisplay,
    renderRequiredModGroups,
  ]);

  const setSelectedProductToState = useCallback(
    (prevState, newState: Product | Variant) => {
      // pre-conditions while changing state
      if ((prevState as ProductAlias)?.modifierGroups?.length) {
        prevState?.id && removeItemFromCart(prevState?.id, 'Product');
      }
      if (
        (prevState as Variant)?.products?.length &&
        !(prevState as Variant)?.products?.find(prod => prod.isDefault) // to check if having default variant
      ) {
        showNotification({
          error: true,
          message: translate('order.variantRemoved', {
            name: (prevState as Variant)?.name,
          }),
        });
      }
      return newState;
    },
    [removeItemFromCart, showNotification],
  );

  const onPressItem = useCallback(
    (index: number): void => {
      changeDueVar(null);
      unselectCartItem();
      setShowRequiredModifierModal(false);
      const item = pageContent[index];
      // if product clicked on is a page with variants
      // replace modifiers wit the variants
      if ((item as Variant).options) {
        setSelectedProduct(prevState =>
          setSelectedProductToState(prevState, item as Variant),
        );
        addVariantToProduct([], item as Variant);
        setCurrOptionIndx(0);
        onSelectProduct && onSelectProduct(item.id);
        setSelectedModGroup('');
        setSelectedProductModifierGroups({});
      }
      // if product clicked is having modifiers
      else if ((item as ProductAlias)?.modifierGroups?.length) {
        setSelectedProduct(prevState =>
          setSelectedProductToState(prevState, item as Product),
        );
        addModifierToProduct(item, [] as CatalogModifier[]);
        setSelectedModGroup('');
        setSelectedProductModifierGroups({});
      }
      // if the product clicked on is a page
      else if ((item as Page).products) {
        onSelectPage(item.id);
      } else {
        setSelectedProduct(prevState =>
          setSelectedProductToState(prevState, item as Product),
        );
        addProductToCart(item as Product);
        onSelectProduct && onSelectProduct(item.id);
      }
      setSelectedOptions([]);
    },
    [
      unselectCartItem,
      setShowRequiredModifierModal,
      pageContent,
      addVariantToProduct,
      onSelectProduct,
      setSelectedProductToState,
      addModifierToProduct,
      onSelectPage,
      addProductToCart,
    ],
  );

  const onPressOption = useCallback(
    (index: number): void => {
      const item = productContent[index];

      const optionLength = (selectedProduct as Variant).options.length;
      let newSelectedOptions = [...new Set([...selectedOptions, item])];
      if (selectedItem?.variant) {
        if (selectedItem.product && selectedVariantKey) {
          const selectedProd = allProducts[selectedItem.product];
          newSelectedOptions = selectedProd.optionValues
            .filter(optionValue => optionValue.key !== selectedVariantKey)
            .map(optionValue => optionValue.value)
            .concat(item);

          replaceVariant(
            selectedItem.item,
            selectedProduct as Variant,
            newSelectedOptions,
          );
        }

        setSelectedOptions(newSelectedOptions);
      } else if (currOptionIndx >= optionLength - 1) {
        setSelectedProduct(
          (selectedProduct as Variant).products[
            (selectedProduct as Variant).products.findIndex(element =>
              newSelectedOptions.every(e => element.name.includes(e)),
            )
          ],
        );
        setSelectedOptions(newSelectedOptions);
        addVariantToProduct(newSelectedOptions, selectedProduct as Variant);
      } else {
        setSelectedOptions(newSelectedOptions);
        setCurrOptionIndx(currOptionIndx => currOptionIndx + 1);
      }
    },
    [
      selectedProduct,
      selectedItem,
      currOptionIndx,
      addVariantToProduct,
      selectedOptions,
      productContent,
      selectedVariantKey,
      allProducts,
      replaceVariant,
    ],
  );

  const onPressModifierGroup = useCallback((modGroupId: string) => {
    // when modifier group is pressed then it updates selectedModGroup state with its id
    setSelectedModGroup(modGroupId);
  }, []);

  const onPressModifier = useCallback(
    (item: CatalogModifier) => {
      setSelectedProductModifierGroups(prev => {
        // when modifier got clicked updates state
        const tempPrev = { ...prev };

        if (item?.modifierGroup && tempPrev[item.modifierGroup]) {
          const modIndex = tempPrev[item.modifierGroup].modifiers.indexOf(
            item.id,
          );

          const isItemDefaultMod =
            tempPrev[item.modifierGroup]?.defaultMods?.includes(item.id) &&
            true;
          // we dont check for min and max rules when there is no actual modifier group
          const isQualifiedToAddModifier =
            item.modifierGroup !== DEFAULT_PRODUCT_MODIFIER_GROUP_NAME &&
            tempPrev[item.modifierGroup].modifiers?.length + 1 >
              tempPrev[item.modifierGroup].maxLimit +
                tempPrev[item.modifierGroup].selectedDefaultMods &&
            !(modIndex >= 0);

          if (isQualifiedToAddModifier && !isItemDefaultMod) {
            // throw error that he can not add more than # number of modifiers
            showNotification({
              error: true,
              message: translate('order.modifiersExceeded', {
                min: tempPrev[item.modifierGroup].minLimit,
                max: tempPrev[item.modifierGroup].maxLimit,
              }),
            });
          } else {
            modIndex >= 0
              ? tempPrev[item.modifierGroup].modifiers.splice(modIndex, 1)
              : tempPrev[item.modifierGroup].modifiers.push(item.id);
            if (modIndex >= 0 && isItemDefaultMod) {
              tempPrev[item.modifierGroup].selectedDefaultMods -= 1;
            } else if (modIndex < 0 && isItemDefaultMod) {
              tempPrev[item.modifierGroup].selectedDefaultMods += 1;
            }
            selectedProduct &&
              addModifierToProduct(selectedProduct, [item], selectedItem?.item);
          }

          const modifiersLength =
            (item.modifierGroup &&
              tempPrev[item.modifierGroup]?.modifiers?.length) ||
            0;
          const selectedModsCount =
            tempPrev[item.modifierGroup]?.selectedDefaultMods || 0;
          const minLimit = tempPrev[item.modifierGroup]?.minLimit || 0;
          const maxLimit = tempPrev[item.modifierGroup]?.maxLimit || 0;
          let isQualified =
            (minLimit <= modifiersLength &&
              maxLimit + selectedModsCount >= modifiersLength) ||
            item?.modifierGroup === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME;

          if (!tempPrev[item.modifierGroup]?.isGrouped) {
            // if not grouped then it is qualified with out min and max limit
            isQualified = true;
          }

          tempPrev[item.modifierGroup] = {
            ...tempPrev[item.modifierGroup],
            isQualified,
            isChanged: true,
            // if the modifiers were changed and reached max selection then the mod group should be in selected state
            isSelected:
              tempPrev[item.modifierGroup].modifiers.length ===
                maxLimit + selectedModsCount || false,
          };

          if (tempPrev[item.modifierGroup]?.isSelected) {
            // if the modifiers were changed and reached max selection then the mod group should be in selected state and selected modifier group is removed
            setSelectedModGroup('');
          }
        }
        return tempPrev;
      });
    },
    [addModifierToProduct, selectedProduct, selectedItem, showNotification],
  );

  const onPressBackToModifiersGroup = useCallback(() => {
    setSelectedModGroup('');
  }, []);

  const markRequiredModGroupAsSelected = useCallback((modGroupId?: string) => {
    if (modGroupId) {
      setSelectedProductModifierGroups(prev => {
        const tempPrev = { ...prev };
        if (tempPrev[modGroupId]) {
          tempPrev[modGroupId] = {
            ...tempPrev[modGroupId],
            isSelected: true,
          };
        }
        return tempPrev;
      });
      setSelectedModGroup('');
    } else {
      setSelectedModGroup(modifierSelectionCompleted);
      setSelectedProduct({} as Product);
    }
  }, []);

  const renderProduct = useCallback(
    (index: number, item?: Product | Page): React.ReactNode => (
      <StyledCatalogItem
        rows={5}
        columns={5}
        key={index}
        testID={`product-${index}`}
        title={item?.name}
        color={
          (item &&
            ((item as Page).color
              ? (item as Page).color
              : theme.colors.deepPurpleDark)) ||
          theme.colors.boxBorder
        }
        disabled={!item}
        isGroup={((item as Page)?.products?.length || 0) > 0}
        trackedItemQuantity={
          (item as Product)?.isBeingTracked
            ? (item as Product)?.inventory?.availableQuantity
            : undefined
        }
        onPress={(): void => onPressItem(index)}
      />
    ),
    [onPressItem, theme.colors.deepPurpleDark, theme.colors.boxBorder],
  );

  const renderExtras = useCallback(
    (index: number, item: Action): React.ReactNode => {
      return isFeatureEnabled(
        item?.feature,
        item?.featureContext ? item.featureContext : FeatureContext.VENUE,
      ) ? (
        <StyledCatalogItem
          rows={2}
          columns={5}
          testID={`extra-${(item?.name || '')
            .toLowerCase()
            .split(' ')
            .join('-')}`}
          key={index}
          title={item?.name}
          color={
            item && item?.name === 'BACK' ? item.color : theme.colors.black
          }
          disabled={!item}
          onPress={item?.onPress}
          isGroup={item?.isGroup}
        />
      ) : (
        <StyledCatalogItem
          rows={2}
          columns={5}
          testID={`extra-${''.toLowerCase().split(' ').join('-')}`}
          key={index}
          title={''}
          color={
            item && item?.name === 'BACK' ? item.color : theme.colors.black
          }
          disabled={true}
          isGroup={item?.isGroup}
        />
      );
    },
    [isFeatureEnabled, theme.colors.black],
  );

  const renderPage = useCallback(
    (index: number, item?: Page): React.ReactNode => (
      <StyledCatalogItem
        rows={10}
        columns={1}
        key={index}
        type={'page'}
        testID={`catalog-page-${index}`}
        title={item?.name}
        color={item?.color ?? theme.colors.boxBorder}
        selected={item && item === page}
        disabled={!item}
        containerStyle={
          index === 5
            ? fifthNoOfRowPageSeparator
            : index === 8
            ? eightNoOfRowPageSeparator
            : undefined
        }
        isGroup={(item?.pages?.length || 0) > 0}
        onPress={(): void => onSelectPage(item?.id || '')}
        labelStyle={css(labelStyle)}
      />
    ),
    [onSelectPage, page, theme.colors.boxBorder, css],
  );

  const renderVariants = useCallback(
    (index: number, item?: Product | string): React.ReactNode => {
      return (
        <StyledCatalogItem
          rows={3}
          columns={5}
          key={index}
          testID={`variant-${index}`}
          type={'variant'}
          title={productContent[index]}
          color={item && theme.colors.white}
          disabled={!item}
          trackedItemQuantity={
            (item as Product)?.isBeingTracked
              ? (item as Product)?.inventory?.availableQuantity
              : undefined
          }
          onPress={(): void => onPressOption(index)}
        />
      );
    },
    [onPressOption, productContent, theme.colors.white],
  );

  const renderModifierGroups = useCallback(
    (index: number, item?: CatalogModifier) => {
      // renders modifier group item of catalogue
      const isChanged =
        item?.id && selectedProductModifierGroups[item?.id]?.isChanged;
      const selectedModGroups =
        item?.id && selectedProductModifierGroups[item?.id]?.modifiers;

      let typeOfModGroup = 'modifierGroupDanger';

      /**
       * shows red indicator when default mod in mod group is selected and user did not acknowledged
       * shows yellow indicator when user not acknowledged and have no default mod
       * shows green indicator when user acknowleged
       */

      if (isChanged) {
        typeOfModGroup = 'modifierGroup';
      } else if (!isChanged && !selectedModGroups?.length) {
        typeOfModGroup = 'modifierGroupNotSelected';
      } else if (selectedModGroups?.length && !isChanged) {
        typeOfModGroup = 'modifierGroupDanger';
      }
      return (
        <StyledCatalogItem
          rows={3}
          columns={5}
          key={index}
          testID={`modifier-group-${index}`}
          type={typeOfModGroup}
          title={item && item.name}
          color={item && theme.colors.white}
          disabled={!item}
          selected
          onPress={() => onPressModifierGroup(item?.id || '')}
        />
      );
    },
    [theme, onPressModifierGroup, selectedProductModifierGroups],
  );

  const renderModifier = useCallback(
    // renders modifier item of catalogue
    (index: number, item: CatalogModifier) => {
      const defaultModSelected = item.isDefault && item.isSelected;
      const color =
        item && !showRequiredModifierPrompt ? theme.colors.white : undefined;
      return (
        <StyledCatalogItem
          rows={3}
          columns={5}
          key={index}
          testID={`modifier-${index}`}
          type={
            item.isDefault && !defaultModSelected
              ? 'modifierDeSelect'
              : 'modifier'
          }
          title={item && item.name}
          color={color}
          disabled={!item}
          selected={item.isSelected || item.isDefault}
          onPress={() => onPressModifier(item)}
        />
      );
    },
    [onPressModifier, showRequiredModifierPrompt, theme.colors.white],
  );

  const renderModifiersBackButton = useCallback(
    // renders back button when modifier group is selected and modifiers were listed
    (index: number, item: CatalogModifier) => {
      return (
        <StyledCatalogItem
          rows={3}
          columns={5}
          key={index}
          testID={`modifier-back-${index}`}
          type={''}
          title={'BACK'}
          color={item && theme.colors.white}
          disabled={!item}
          selected
          onPress={onPressBackToModifiersGroup}
          labelStyle={css(backLabelStyling)}
        />
      );
    },
    [theme, onPressBackToModifiersGroup, css],
  );

  const renderTickButton = useCallback(
    (rows: number, columns: number, modGroup?: string) => {
      return (
        <View
          testID={'tick'}
          style={[
            css(catalogItemStyle({ theme, rows, columns })),
            css(selectItemStyle({ theme, rows, columns })),
          ]}
        >
          <IconButton
            icon={'check'}
            iconSize={24}
            iconColor={theme.colors.green}
            onPress={() => markRequiredModGroupAsSelected(modGroup)}
            containerStyle={css(tickContainerStyle)}
          />
        </View>
      );
    },
    [theme, css, markRequiredModGroupAsSelected],
  );

  const renderModifierOrModifierGroup = useCallback(
    (index: number, item?: CatalogModifier): React.ReactNode => {
      if (item?.type === ModifierAlias.Modifier) {
        return renderModifier(index, item);
      } else if (item?.type === 'BACK') {
        return renderModifiersBackButton(index, item);
      } else if (
        selectedModGroup &&
        index === 14 &&
        productModifierGroups.length &&
        selectedProductModifierGroups?.[selectedModGroup]?.isQualified
      ) {
        return renderTickButton(3, 5, selectedModGroup);
      } else if (
        (!selectedModGroup &&
          index % 14 === 0 &&
          !(item?.type === ModifierAlias.ModifierGroup) &&
          productModifierGroups.length) ||
        item?.type === 'CHECK'
      ) {
        return renderTickButton(3, 5);
      } else {
        return renderModifierGroups(index, item);
      }
    },
    [
      productModifierGroups,
      renderModifier,
      renderModifierGroups,
      renderModifiersBackButton,
      renderTickButton,
      selectedProductModifierGroups,
      selectedModGroup,
    ],
  );

  const closeRequiredModifierPrompt = useCallback(() => {
    closeModal();
    isShowingRequiredModifier.current = false;
  }, [closeModal]);

  const requiredModifierPrompt = useCallback(() => {
    const modifierGroupInfo = selectedProductModifierGroups[selectedModGroup];

    isShowingRequiredModifier.current = true;
    const modifierName =
      productModifierGroupsMap[selectedModGroup] &&
      productModifierGroupsMap[selectedModGroup].name;
    showModal(
      <RequiredModifierPrompt
        numOfSelectedModifiers={modifierGroupInfo?.modifiers?.length}
        name={modifierName}
        selectionLimit={{
          min: modifierGroupInfo && modifierGroupInfo.minLimit,
          max: modifierGroupInfo && modifierGroupInfo.maxLimit,
        }}
      >
        <CatalogGridView
          testID="required-modifier-prompt"
          rows={3}
          columns={5}
          paginate={productModifierGroups.length > 15}
          data={productModifierGroups}
          renderItem={renderModifierOrModifierGroup}
          containerStyle={modifierPromptGridViewStyle}
        />
      </RequiredModifierPrompt>,
    );
  }, [
    showModal,
    productModifierGroups,
    productModifierGroupsMap,
    renderModifierOrModifierGroup,
    selectedModGroup,
    selectedProductModifierGroups,
  ]);

  useEffect(() => {
    const modifierGroupInfo = selectedProductModifierGroups[selectedModGroup];
    if (
      showRequiredModifierPrompt &&
      modifierGroupInfo &&
      showRequiredModifierModal
    ) {
      requiredModifierPrompt();
    } else {
      isShowingRequiredModifier.current && closeRequiredModifierPrompt();
    }
  }, [
    closeRequiredModifierPrompt,
    productModifierGroups,
    productModifierGroupsMap,
    renderModifierOrModifierGroup,
    requiredModifierPrompt,
    selectedModGroup,
    selectedProductModifierGroups,
    showModal,
    showRequiredModifierModal,
    showRequiredModifierPrompt,
  ]);

  return (
    <View style={css(body)} testID="catalog">
      <View style={css(pageSubContainer)}>
        <CatalogGridView
          testID="products"
          rows={5}
          columns={5}
          paginate={true}
          renderItem={renderProduct}
          data={pageContent}
          containerStyle={productContainer}
        />
        {variantProducts?.length ? (
          <CatalogGridView
            testID="variants"
            rows={3}
            columns={5}
            paginate={true}
            data={variantProducts}
            renderItem={renderVariants}
            containerStyle={variantContainer}
          />
        ) : (
          <CatalogGridView
            testID="modifierGroupsAndModifiers"
            rows={3}
            columns={5}
            paginate={productModifierGroups.length > 15}
            data={!showRequiredModifierPrompt ? productModifierGroups : []}
            renderItem={renderModifierOrModifierGroup}
            containerStyle={variantContainer}
          />
        )}
        <CatalogGridView
          testID="extras"
          rows={2}
          columns={5}
          data={actions}
          renderItem={renderExtras}
          containerStyle={extrasContainer}
        />
      </View>
      <CatalogGridView
        testID="pages"
        rows={10}
        columns={1}
        renderItem={renderPage}
        data={mainPages}
        paginate={true}
        containerStyle={pageContainer}
      />
    </View>
  );
};

export default withTheme(Catalog);
