import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/ProductsSummary.styles';
import { View, ScrollView } from 'react-native';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import {
  DateRangeFilter,
  Filters,
  FilterValue,
  NO_DATA,
  Widget,
  WidgetChartType,
} from '@hitz-group/domain';
import { ReportHeader } from '../UIComponents/ReportHeader';
import { Table, TableRef } from '../UIComponents/Table';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import {
  getHourRangeIn12HrsFormat,
  GRANULARITY_FORMATS,
  transformFieldsToTableColumn,
} from '../reportsHelper';
import {
  ReportTableColumn,
  IMap,
  ExportType,
  Search,
  PivotTableData,
  HelperText,
  DropDownFilter,
  LineChartType,
} from '../types';
import { columnStyleOptions } from '../../../../components/DataGrid/DataGrid';
import { CubejsApi } from '@cubejs-client/core';
import {
  LineChart,
  LineChartData,
  LineChartRow,
} from '../UIComponents/LineChart';
import { PieChart } from '../UIComponents/PieChart';
import keyBy from 'lodash/keyBy';
import getHours from 'date-fns/getHours';

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  search: Search;
  widgets: Widget[];
  dateRangeFilter: DateRangeFilter;
  updateFilters: (filter: string, value: string[]) => void;
  updateSearch: (filter: string, value: string) => void;
  resetFilters: () => void;
  updateReport: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  cubejsApi: CubejsApi;
  allFilters: Filters;
  updateCount: number;
}

const TABLE_GRANULARITY_FORMATS: IMap<string> = {
  ...GRANULARITY_FORMATS,
  day: 'MMM dd, yyyy',
};

export const VoidSummaryReport: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  filterOptions,
  widgets,
  dateRangeFilter,
  search,
  updateFilters,
  updateSearch,
  updateReport,
  resetFilters,
  updateDateRangeFilters,
  cubejsApi,
  allFilters,
  updateCount,
}) => {
  const { translate } = useTranslation();
  const { appendCurrency } = useCurrency();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const tableWidget =
    widgets.find(widget => widget.chartType === WidgetChartType.TABLE) ||
    ({} as Widget);

  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const tableColumns: ReportTableColumn[] = useMemo(() => {
    const columns = [];
    if (tableWidget.query?.dimensions)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.dimensions).map(
          field => ({
            ...field,
            style: columnStyleOptions.REGULAR,
          }),
        ),
      );

    if (tableWidget.query?.measures)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.measures),
      );
    return columns;
  }, [tableWidget.query]);

  useEffect(() => {
    setSelectedColumns(
      (tableColumns || []).filter(column => column.showByDefault),
    );
  }, [tableColumns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        tableColumns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [tableColumns],
  );

  const tableWidgetRef = useRef<TableRef>({} as TableRef);
  const exportReport = useCallback((type = ExportType.CSV) => {
    if (type === ExportType.CSV)
      tableWidgetRef.current.exportData &&
        tableWidgetRef.current.exportData(
          `VoidSummaryReport-${Date.now()}.csv`,
        );
  }, []);

  const lineChartDataTransformationFn = useCallback(
    (serializedData: PivotTableData): LineChartData[] => {
      const reasonKey = 'OrderItems.reason';
      const translatedData: PivotTableData = serializedData.map(data => ({
        ...data,
        [reasonKey]: translate(`enums.${data[reasonKey]}`),
      }));

      const reasonMap = keyBy(translatedData, reasonKey);
      const reasons = Object.keys(reasonMap);

      const reasonBlankHrs: Record<
        string,
        Array<LineChartRow>
      > = reasons.reduce((acc, reason) => {
        return {
          ...acc,
          [reason]: Array.from({ length: 24 }).map((_, i) => ({
            x: i,
            y: 0,
          })),
        };
      }, {} as Record<string, LineChartRow[]>);

      const dataByReason = translatedData.reduce(
        (acc: Record<string, Record<string, Record<string, number>>>, row) => {
          const reason = row[reasonKey] as string;
          const createdHour = row['Orders.createdAt.hour'] as string;

          const dateHour = getHours(new Date(createdHour));

          return {
            ...acc,
            [reason]: {
              ...(acc[reason] || {}),
              [dateHour]: {
                voids: +row['OrderItems.itemsCount'] || 0,
                amount: +row['OrderItems.grossSales'] || 0,
              },
            },
          };
        },
        {},
      );

      return reasons.map(reason => {
        const reasonData = dataByReason[reason];
        return reasonBlankHrs[reason].map(defaultHrData => {
          const { start, end } = getHourRangeIn12HrsFormat(
            defaultHrData.x as number,
          );

          const lineData: LineChartRow = {
            x: `${start} - ${end}`,
            y: 0,
            text: reason as string,
          };

          if (reasonData[defaultHrData.x + '']) {
            lineData.y = reasonData[defaultHrData.x + ''].amount;
            lineData.labelText = `${reason} (${start})\nItems: ${
              reasonData[defaultHrData.x + ''].voids
            }, Amount: ${appendCurrency(`${lineData.y}`)}`;
          } else {
            lineData.labelText = `${reason} (${start})\n${NO_DATA}`;
          }

          return lineData;
        });
      });
    },
    [appendCurrency, translate],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView scrollEnabled={!showFilters} style={styles.pageStyle}>
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <ReportHeader
              allFilters={allFilters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              exportReport={exportReport}
              filters={filters}
              search={search}
              updateSearch={updateSearch}
              columns={{ all: tableColumns, updateColumns, selectedColumns }}
              updateFilters={updateFilters}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
              translationParentKey={'backOfficeVoidSummary'}
            />
          </View>
          <View style={styles.chartRowStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {widgets
                  .filter(widget => widget.chartType !== WidgetChartType.TABLE)
                  .map(widget => {
                    let renderComp;
                    switch (widget.chartType) {
                      case WidgetChartType.LINE:
                        renderComp = (
                          <LineChart
                            widget={widget}
                            helper={HelperText.DEVICE_SUMMARY}
                            keys={[]}
                            type={LineChartType.BASIC}
                            dataTransformationFn={lineChartDataTransformationFn}
                            cubejsApi={cubejsApi}
                            updateCount={updateCount}
                          />
                        );
                        break;

                      case WidgetChartType.PIE:
                        const pieKeys = {
                          name: widget.query.dimensions[1].key,
                          value: widget.query.measures[0].key,
                        };
                        renderComp = (
                          <PieChart
                            widget={widget}
                            helper={HelperText.VOID_SUMMARY}
                            keys={pieKeys}
                            columnType={widget.query.measures[0]?.type}
                            cubejsApi={cubejsApi}
                            updateCount={updateCount}
                          />
                        );
                        break;
                    }
                    return renderComp;
                  })}
              </>
            )}
          </View>
          <View style={styles.tableStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                widget={tableWidget}
                columns={selectedColumns}
                helper={HelperText.VOID_SUMMARY}
                pageSize={5}
                granularityFormats={TABLE_GRANULARITY_FORMATS}
                ref={tableWidgetRef}
                filters={filters}
                cubejsApi={cubejsApi}
                updateCount={updateCount}
              ></Table>
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default VoidSummaryReport;
