import { StyleFn, OrderType } from '@hitz-group/domain';
import React, { useEffect, useCallback } from 'react';
import { View } from 'react-native';
import { useFela } from 'react-fela';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import FormInput from '../../../../components/FormInput/FormInput';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import IconButton from '../../../../components/Button/IconButton';
import { CreateOrderTypeModal } from '../../../../components/Modals/OrderTypes/CreateOrderTypeModal';
import { DELETE_ORDER_TYPE } from '../../../../graphql/settings';
import { useNotification } from '../../../../hooks/Notification';
import { parseApolloError, noopHandler } from '../../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import { isWeb } from '../../../../common/theme';
import { OrderTypeCode } from '@hitz-group/domain';
interface OrderTypesRowProps {
  orderType: OrderType;
  onDeleteOrderType: (id: string, name: string) => void;
  onChange: (id: string, prop: string, value: string) => void;
}

const inputContainerStyle: StyleFn = ({ theme }) => ({
  width: 240,
  height: theme.input.height,
  justifyContent: 'center',
  marginLeft: theme.spacing.small,
});

const inputContainerSmallStyle: StyleFn = ({ theme }) => ({
  width: 80,
  height: theme.input.height,
  paddingLeft: theme.spacing.small / 2,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});
export const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

export const containerStyle: StyleFn = () => ({
  width: isWeb ? '60%' : '100%',
  alignSelf: 'center',
});
export const innerContainer: StyleFn = () => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const closeIconGreyContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const greyedOutStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.grey,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

interface DeleteModalProps {
  id: string;
  name: string;
  onDeleteOrderType: (id: string) => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  id,
  name,
  onDeleteOrderType,
}: DeleteModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [deleteOrderType, deleteRequest] = useMutation(DELETE_ORDER_TYPE, {
    onError: noopHandler,
  });

  useEffect((): void => {
    if (deleteRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(deleteRequest.error),
      });
    }
  }, [deleteRequest.error, showNotification]);

  useEffect((): void => {
    if (deleteRequest.data) {
      onDeleteOrderType(id);
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.orderTypeDeleted'),
      });
    }
  }, [
    deleteRequest.data,
    showNotification,
    closeModal,
    name,
    onDeleteOrderType,
    id,
    translate,
  ]);

  const onPressDelete = useCallback((): void => {
    deleteOrderType({ variables: { id: id } });
  }, [deleteOrderType, id]);

  return (
    <ConfirmationDialog
      title={translate('dialog.deleteTitle')}
      message={translate('dialog.deleteConfirmation', { label: name })}
      onConfirm={onPressDelete}
    />
  );
};

export const OrderTypesRow: React.FC<OrderTypesRowProps> = ({
  orderType,
  onDeleteOrderType,
  onChange,
}: OrderTypesRowProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const isPreDefinedOrderType = Object.values(OrderTypeCode).includes(
    orderType.code as OrderTypeCode,
  );
  return (
    <View style={css(rowStyle)}>
      <FormInput
        testID="code"
        placeholder={translate('backOfficeSettings.code')}
        value={orderType.code}
        containerStyle={css(inputContainerSmallStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={
          !isPreDefinedOrderType
            ? onChange.bind(null, orderType.id, 'code')
            : undefined
        }
      />
      <FormInput
        testID="order-type-name"
        placeholder={translate('backOfficeSettings.OrderTypeName')}
        value={orderType.name}
        containerStyle={css(inputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={
          !isPreDefinedOrderType
            ? onChange.bind(null, orderType.id, 'name')
            : undefined
        }
      />
      <View style={css(flexStyle)} />
      <IconButton
        icon="TrashAlt"
        iconSize={24}
        containerSize={34}
        containerStyle={
          isPreDefinedOrderType
            ? css(closeIconGreyContainerStyle)
            : css(closeIconContainerStyle)
        }
        iconStyle={
          isPreDefinedOrderType ? css(greyedOutStyle) : css(closeIconStyle)
        }
        onPress={(): void => onDeleteOrderType(orderType.id, orderType.name)}
        disabled={isPreDefinedOrderType}
      />
    </View>
  );
};

interface OrderTypesSectionProps {
  orderTypesData: OrderType[];
  onChange: (id: string, prop: string, value: string) => void;
  onDeleteOrderType: (id: string) => void;
  onAddOrderType: (orderType: OrderType) => void;
}

export const OrderTypesSection: React.FC<OrderTypesSectionProps> = ({
  orderTypesData,
  onChange,
  onDeleteOrderType,
  onAddOrderType,
}: OrderTypesSectionProps) => {
  const { css, theme } = useFela();
  const { showModal } = useModal();
  const { translate } = useTranslation();

  const OnPressCreate = useCallback((): void => {
    showModal(<CreateOrderTypeModal onAddOrderType={onAddOrderType} />);
  }, [showModal, onAddOrderType]);

  const onPressDelete = useCallback(
    (id: string, name: string): void => {
      showModal(
        <DeleteModal
          id={id}
          name={name}
          onDeleteOrderType={onDeleteOrderType}
        />,
      );
    },
    [showModal, onDeleteOrderType],
  );

  return (
    <BackOfficeSection
      title={translate('backOfficeSettings.OrderTypes')}
      titleDescription={translate('backOfficeSettings.orderTypesDescription')}
      contentContainerStyle={css(formStyle)}
      containerStyle={css(containerStyle)}
      action={<BackOfficeCreateNewButton onPress={OnPressCreate} />}
    >
      <TableComponent
        columns={[
          {
            title: translate('backOfficeSettings.code'),
            width: 80,
            containerStyle: { paddingLeft: 15 },
          },
          {
            title: translate('backOfficeSettings.OrderTypeName'),
            flex: 1,
            alignItems: 'flex-start',
            containerStyle: { paddingLeft: theme.spacing.medium },
          },
        ]}
        data={orderTypesData}
        normalRows
        columnContainerStyle={css(columnContainerStyle)}
        renderRow={(item: OrderType, index: number): React.ReactNode => (
          <OrderTypesRow
            orderType={item}
            key={index}
            onDeleteOrderType={onPressDelete}
            onChange={onChange}
          />
        )}
      />
    </BackOfficeSection>
  );
};
