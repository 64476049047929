import { useCurrency, useTranslation } from '@hitz-group/localization';
import { Customer, Icons, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import FormInput from '../../FormInput/FormInput';
import { isValidEmail } from '../../../utils/validator';
import { useModal } from '@hitz-group/rn-use-modal';
import { useMemo } from 'react';
import Modal from '../Modal';

const containerStyle: StyleFn = ({ theme }) => ({
  width: 400,
  paddingVertical: theme.spacing.big,
  alignSelf: 'center',
  alignItems: 'center',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.white,
  paddingHorizontal: 30,
});

const saleView: StyleFn = ({ theme }) => ({
  width: 340,
  height: 180,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.large,
  backgroundColor: theme.colors.successLightest,
});

const saleText: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
  ...theme.font14SemiBold,
  fontSize: 24,
  marginTop: theme.spacing.small,
});

const changeDueText: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
  ...theme.font14SemiBold,
  fontSize: 24,
  marginTop: theme.spacing.small,
});

const saleCompleteText: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  ...theme.font14Medium,
  fontSize: 14,
  marginTop: theme.spacing.small,
});

const phoneView: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: theme.spacing.small,
  width: 340,
});

const formInputContainerStyleCss: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 250,
  alignSelf: 'stretch',
});

const formLabelStyleCss: StyleFn = ({}) => ({
  marginLeft: 7,
});

const sendButtonStyle: StyleFn = ({ theme, disabled }) => ({
  width: 80,
  height: theme.input.height,
  marginTop: 'auto',
  marginLeft: theme.spacing.small,
  backgroundColor: disabled
    ? theme.colors.background
    : theme.colors.paletteLightBlue,
});

const printButtonStyle: StyleFn = ({ theme, isSplitPayment }) => ({
  height: theme.input.height,
  width: isSplitPayment ? 165 : 120,
  backgroundColor: theme.colors.blue,
  marginRight: 10,
});

const labelStyle: StyleFn = ({ theme, disabled }) => ({
  color: disabled ? theme.colors.paragraph : theme.colors.blue,
  textTransform: 'uppercase',
});

const printStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  textTransform: 'uppercase',
});

const customerAccountTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
});

const actionButtonContainer: StyleFn = ({ isSplitPayment }) => ({
  flexDirection: isSplitPayment ? 'column' : 'row',
  width: 340,
  height: 'auto',
  marginTop: 30,
  marginBottom: isSplitPayment ? 30 : 0,
});

const printButtonContainer: StyleFn = ({}) => ({
  flexDirection: 'row',
});

const saleButtonContainer: StyleFn = ({ isSplitPayment }) => ({
  flex: 1,
  marginTop: isSplitPayment ? 10 : 0,
});

const saleButtonStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  backgroundColor: theme.colors.green,
});
export interface CompleteSaleModalModalProps {
  sendReceipt?: (email: string) => void;
  onPressPrintReceipt: () => Promise<void>;
  onPressNewSale: () => void;
  changeDue: number | null;
  customer?: Customer;
  amount?: number;
  isSplitPayment?: boolean;
  onPrintSplit: () => void;
  isOnAccount?: boolean;
  loyaltyAction: React.ReactElement | null;
  earnedPointInfo?: string;
}

interface FormData {
  phone: string;
  email: string;
  phoneCountryCode: string;
}

const initialFormState: FormData = {
  email: '',
  phone: '',
  phoneCountryCode: '',
};

const CompleteSaleModal: React.FC<CompleteSaleModalModalProps> = ({
  sendReceipt,
  onPressPrintReceipt,
  onPressNewSale,
  changeDue,
  customer,
  amount,
  isSplitPayment,
  onPrintSplit,
  isOnAccount,
  loyaltyAction,
  earnedPointInfo,
}: CompleteSaleModalModalProps) => {
  const [form, setForm] = useState<FormData>(initialFormState);
  const { css, theme } = useFela({ isSplitPayment });
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { closeModal } = useModal();

  const formValidationStatus = useMemo<Record<string, boolean>>(
    () => ({
      email: isValidEmail(form.email),
    }),
    [form.email],
  );
  const sendEmailReceipt = useCallback(() => {
    sendReceipt && sendReceipt(form.email);
  }, [form.email, sendReceipt]);

  const onChange = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const handlePressNewSale = useCallback(() => {
    closeModal();
    onPressNewSale();
  }, [closeModal, onPressNewSale]);

  const totalAmount =
    (customer?.customerAccountDetails?.currentBalance || 0) + (amount || 0);

  const disableSendEmail = !formValidationStatus.email;
  return (
    <Modal contentStyle={css(containerStyle)} showCloseButton={false}>
      <View>
        {isOnAccount ? (
          <View style={css(saleView)}>
            <IconButton
              icon={Icons.CheckCircle}
              iconColor={theme.colors.green}
              iconSize={70}
            />
            <Text style={css(saleText)}>
              {translate('payment.currentBalance')}:{' '}
              {formatCurrency(totalAmount)}
            </Text>
            <Text style={css(customerAccountTextStyle)}>
              {translate('payment.customer')}:{' '}
              {customer?.firstName ? customer?.firstName : undefined}{' '}
              {customer?.lastName ? customer?.lastName : undefined}
            </Text>
            <Text style={css(customerAccountTextStyle)}>
              {translate('payment.orderTotal')}:{' '}
              {formatCurrency(amount as number)}
            </Text>
          </View>
        ) : (
          <View style={css(saleView)}>
            <IconButton
              icon={Icons.CheckCircle}
              iconColor={theme.colors.green}
              iconSize={70}
            />
            <Text style={css(changeDueText)} testID="change-due">
              {changeDue
                ? translate('payment.changeDueAmount', {
                    changeDue: formatCurrency(changeDue),
                  })
                : translate('payment.noChangeDue')}
            </Text>
            {earnedPointInfo ? (
              <Text style={css(changeDueText)} testID="earned-point">
                {earnedPointInfo}
              </Text>
            ) : null}
            <Text style={css(saleCompleteText)}>
              {translate('payment.saleComplete')}
            </Text>
          </View>
        )}
        <View style={css(phoneView)}>
          <FormInput
            testID="phone-number"
            placeholder={translate('form.phoneNumberHint')}
            title={translate('form.phoneNumber')}
            value={form.phone || ''}
            alignTitle="left"
            containerStyle={css(formInputContainerStyleCss)}
            textStyle={css(formLabelStyleCss)}
            type="phone"
            maxLength={10}
            country={form.phoneCountryCode}
          />
          <Button
            key={'send'}
            testID={'send-phone'}
            success
            size="large"
            containerStyle={css(sendButtonStyle({ theme, disabled: true }))}
            labelStyle={css(labelStyle({ theme, disabled: true }))}
            title={translate('button.send')}
          />
        </View>
        <View style={css(phoneView)}>
          <FormInput
            testID="email-address"
            placeholder={translate('common.emailPlaceholder')}
            title={translate('common.emailAddress')}
            value={form.email || ''}
            alignTitle="left"
            containerStyle={css(formInputContainerStyleCss)}
            textStyle={css(formLabelStyleCss)}
            onChangeText={onChange.bind(null, 'email')}
            verified={formValidationStatus.email}
          />
          <Button
            key={'send'}
            testID={'send-email'}
            success
            size="large"
            containerStyle={css(
              sendButtonStyle({ theme, disabled: disableSendEmail }),
            )}
            labelStyle={css(labelStyle({ theme, disabled: disableSendEmail }))}
            title={translate('button.send')}
            onPress={sendEmailReceipt}
            disabled={disableSendEmail}
          />
        </View>
        <View>{loyaltyAction}</View>
        <View style={css(actionButtonContainer)}>
          <View style={css(printButtonContainer)}>
            {isSplitPayment && (
              <Button
                key={'print-split'}
                testID={'print-split'}
                success
                size="large"
                containerStyle={css(printButtonStyle)}
                labelStyle={css(printStyle)}
                title={translate('button.printSplit')}
                onPress={onPrintSplit}
              />
            )}
            <Button
              key={'print-order'}
              testID={'print-order'}
              success
              size="large"
              containerStyle={css(printButtonStyle)}
              labelStyle={css(printStyle)}
              title={translate('button.printOrder')}
              onPress={onPressPrintReceipt}
            />
          </View>
          <View style={css(saleButtonContainer)}>
            <Button
              key={'new-sale'}
              testID={'new-sale'}
              success
              containerStyle={css(saleButtonStyle)}
              labelStyle={css(printStyle)}
              title={translate('button.newSale')}
              iconPosition="right"
              icon="arrow-right"
              iconProps={{
                color: theme.colors.white,
                size: 22,
                primary: true,
              }}
              onPress={handlePressNewSale}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default CompleteSaleModal;
