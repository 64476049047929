import { useCallback, useState, useRef } from 'react';
import {
  CreateCourseInput,
  DEFAULT_ENTITY_ID,
  UpdateCourseInput,
  UpdateProductsInCourseInput,
} from '@hitz-group/domain';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { noopHandler, parseApolloError } from '../../../utils/errorHandlers';
import { useNetInfo } from '@react-native-community/netinfo';

import { Course } from '@hitz-group/domain';
import {
  CREATE_COURSE,
  DELETE_COURSE,
  GET_COURSES_QUERY,
  GET_COURSE_QUERY,
  UPDATE_COURSE,
  UPDATE_PRODUCTS_IN_COURSE,
} from './graphql';
import { useNotification } from '../../Notification';
import { useTranslation } from '@hitz-group/localization';
import { omit, keyBy, sortBy } from 'lodash';
import { useNavigation } from '@react-navigation/native';

export type CourseInfo = Omit<Course, 'products'>;

export type UpdateProductsInCourse = Omit<
  UpdateProductsInCourseInput,
  'courseId'
>;

export interface UseCourseProps {
  loading: boolean;
  error: string | undefined;
  courseMaps: Record<string, Course>;
  getCourses: () => void;
  getCourse: (id: string) => void;
  createCourse: (
    newCourse: CreateCourseInput,
    productsToUpdate: UpdateProductsInCourse,
  ) => void;
  deleteCourse: (id: string, courseName: string) => void;
  updateCourses: (coursesToUpdate: UpdateCourseInput[]) => void;
  updateProductsInCourse: (input: UpdateProductsInCourseInput) => void;
  courses: Course[];
}

export interface UseCourseArgs {
  onUpdateCoursesComplete?: () => void;
}

export function useCourses(input?: UseCourseArgs): UseCourseProps {
  const netInfo = useNetInfo();

  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [courseMaps, setCourseMaps] = useState<Record<string, Course>>({});
  const [courses, setCourses] = useState<Course[]>([]);
  const deletingCourseNameRef = useRef('');
  const creatingCoursesRef = useRef<CourseInfo | null>();

  const updatingProductsInCourse = useRef<UpdateProductsInCourse>({});
  const navigation = useNavigation();

  const [getCoursesRequest, getCoursesRes] = useLazyQuery(GET_COURSES_QUERY, {
    onError: noopHandler,
    fetchPolicy: netInfo.isConnected ? 'cache-and-network' : 'cache-first',
    onCompleted: (response: { courses: Course[] }) => {
      const courses = response.courses;
      const coursesMap = keyBy(courses, 'id');
      setCourseMaps(coursesMap);

      const nowCourse: Course = {
        id: DEFAULT_ENTITY_ID,
        autoFire: true,
        name: translate('common.now'),
        products: [],
        priority: 0,
      };
      const sortedCourses = sortBy(
        courses.filter(course => course.products?.length > 0),
        course => course.priority,
      );
      setCourses([nowCourse, ...sortedCourses]);
    },
  });
  const [getCourseDetailRequest, getCourseDetailRes] = useLazyQuery(
    GET_COURSE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: (response: { course: Course }) => {
        const course = response?.course;
        if (!course) return;
        setCourseMaps({ [course.id]: course });
      },
    },
  );

  const [deleteCourseRequest, deleteCourseRes] = useMutation(DELETE_COURSE, {
    onError: () => {
      showNotification({
        message: translate('backOfficeProducts.courseFailedToDelete'),
        error: true,
      });
    },
    onCompleted: () => {
      showNotification({
        message: translate('backOfficeProducts.itemWasDeletedSuccessfully', {
          item: deletingCourseNameRef.current,
        }),
        success: true,
      });
      navigation.navigate('Courses');
    },
  });

  const [updateProductsInCourseReq, updateProductsInCourseRes] = useMutation<
    void,
    { input: UpdateProductsInCourseInput }
  >(UPDATE_PRODUCTS_IN_COURSE, {
    onError: noopHandler,
    onCompleted: () => {
      if (creatingCoursesRef.current) {
        showNotification({
          message: translate('backOfficeProducts.courseCreatedSuccessfully'),
          success: true,
        });

        navigation.setParams({
          courseId: creatingCoursesRef.current.id,
          title: creatingCoursesRef.current.name,
        });
      } else {
        showNotification({
          message: translate('backOfficeProducts.courseUpdatedSuccessfully'),
          success: true,
        });
        typeof getCourseDetailRes?.refetch == 'function' &&
          getCourseDetailRes?.refetch();
      }
    },
  });

  const updateProductsInCourse = useCallback(
    (input: UpdateProductsInCourseInput) => {
      creatingCoursesRef.current = null;
      updateProductsInCourseReq({ variables: { input } });
    },
    [updateProductsInCourseReq],
  );

  const [createCourseRequest, createCourseRes] = useMutation(CREATE_COURSE, {
    onError: noopHandler,
    onCompleted: (data: { createCourse: CourseInfo }) => {
      const createdCourse = omit(data.createCourse, '__typename');
      creatingCoursesRef.current = createdCourse;
      updateProductsInCourseReq({
        variables: {
          input: {
            courseId: createdCourse.id,
            ...updatingProductsInCourse.current,
          },
        },
      });
    },
  });

  const [updateCoursesRequest, updateCoursesRes] = useMutation<
    void,
    { input: UpdateCourseInput[] }
  >(UPDATE_COURSE, {
    onError: noopHandler,
    onCompleted: () => {
      if (typeof input?.onUpdateCoursesComplete === 'function')
        input.onUpdateCoursesComplete();
    },
  });

  const getCourse = useCallback(
    (courseId: string) => {
      getCourseDetailRequest({ variables: { courseId } });
    },
    [getCourseDetailRequest],
  );

  const getCourses = useCallback(() => {
    getCoursesRequest({ variables: {} });
  }, [getCoursesRequest]);

  const deleteCourse = useCallback(
    (courseId: string, courseName: string) => {
      deletingCourseNameRef.current = courseName;
      deleteCourseRequest({ variables: { id: courseId } });
    },
    [deleteCourseRequest],
  );

  const createCourse = useCallback(
    (
      newCourse: CreateCourseInput,
      productsToUpdate: UpdateProductsInCourse,
    ) => {
      updatingProductsInCourse.current = productsToUpdate;
      createCourseRequest({ variables: { input: newCourse } });
    },
    [createCourseRequest],
  );

  const updateCourses = useCallback(
    (coursesToUpdate: UpdateCourseInput[]) => {
      creatingCoursesRef.current = null;
      updateCoursesRequest({ variables: { input: coursesToUpdate } });
    },
    [updateCoursesRequest],
  );

  const error =
    getCourseDetailRes.error ||
    getCoursesRes.error ||
    updateProductsInCourseRes.error ||
    updateCoursesRes.error ||
    createCourseRes.error;

  const loading =
    getCoursesRes.loading ||
    updateCoursesRes.loading ||
    getCourseDetailRes.loading ||
    deleteCourseRes.loading ||
    createCourseRes.loading ||
    updateProductsInCourseRes.loading;

  return {
    loading,
    error: error ? parseApolloError(error) : undefined,
    courseMaps,
    getCourses,
    getCourse,
    updateCourses,
    deleteCourse,
    createCourse,
    updateProductsInCourse,
    courses,
  };
}
