import { useCurrency } from '@hitz-group/localization';
import { Icons, StyleFn } from '@hitz-group/domain';
import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { TouchableOpacity, View, ViewStyle, StyleSheet } from 'react-native';
import { default as NumberTile, default as Text } from '../../Title/Title';
import IconButton from '../../Button/IconButton';
export interface ProductProps {
  quantity?: number;
  name: string;
  price?: number;
  onPress?: () => void;
  type: 'product' | 'modifier';
  selected?: boolean;
  highlighted?: boolean;
  testID?: string;
  containerStyle?: ViewStyle;
  disabled?: boolean;
  lineThrough?: boolean;
  isRefundOrder?: boolean;
  showFiredIcon?: boolean;
  itemFired?: boolean;
  seatNumber?: string;
}

const productStyle: StyleFn = () => ({
  alignItems: 'center',
  height: 26,
  width: '100%',
  flexDirection: 'row',
  marginBottom: 5,
});

const quantityTextStyle: StyleFn = ({
  theme,
  selected,
  type,
  lineThrough,
}) => ({
  fontFamily: theme.font.medium,
  color:
    selected && type === 'product'
      ? theme.colors.white
      : type === 'modifier'
      ? theme.colors.textLight
      : theme.colors.primary,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.4,
  lineHeight: 14,
  textDecorationLine: lineThrough ? 'line-through' : 'none',
  textDecorationStyle: 'solid',
});

const quantityStyle: StyleFn = ({
  theme,
  selected,
  type,
  highlighted,
  itemFired,
}) => ({
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  marginRight: 2,
  minWidth: 28,
  height: 26,
  paddingHorizontal: theme.padding.medium,
  backgroundColor:
    type === 'product'
      ? selected
        ? theme.colors.blue
        : highlighted
        ? theme.colors.highlighted
        : itemFired
        ? theme.colors.successLightest
        : theme.colors.background
      : null,
});

const seatNumberTextStyle: StyleFn = ({
  theme,
  selected,
  type,
  lineThrough,
}) => ({
  fontFamily: theme.font.medium,
  color:
    selected && type === 'product'
      ? theme.colors.white
      : type === 'modifier'
      ? theme.colors.textLight
      : theme.colors.avatar1,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.4,
  lineHeight: 14,
  textDecorationLine: lineThrough ? 'line-through' : 'none',
  textDecorationStyle: 'solid',
});

const seatNumberStyle: StyleFn = ({ theme, selected, type, highlighted }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  marginRight: 2,
  minWidth: 28,
  paddingHorizontal: theme.padding.medium / 2,
  height: 26,
  backgroundColor:
    type === 'product'
      ? selected
        ? theme.colors.blue
        : highlighted
        ? theme.colors.highlighted
        : theme.colors.lightDeepPurple
      : null,
});
const labelContainerStyle: StyleFn = ({
  theme,
  selected,
  highlighted,
  type,
  itemFired,
}) => ({
  flex: 1,
  backgroundColor: selected
    ? theme.colors.blue
    : highlighted
    ? theme.colors.highlighted
    : itemFired
    ? theme.colors.successLightest
    : type === 'product'
    ? theme.colors.background
    : null,
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: theme.radius.small,
  paddingHorizontal: theme.padding.small,
  paddingVertical: theme.padding.medium / 2,
  justifyContent: 'space-between',
  height: 26,
});

const labelStyle: StyleFn = ({ theme, selected, type, lineThrough }) => ({
  color: selected
    ? theme.colors.white
    : type === 'modifier'
    ? theme.colors.textLight
    : theme.colors.primary,
  textTransform: 'uppercase',
  fontFamily: theme.font.medium,
  textAlign: 'left',
  fontSize: theme.fontSize.small,
  letterSpacing: -0.4,
  lineHeight: 14,
  textDecorationLine: lineThrough ? 'line-through' : 'none',
  textDecorationStyle: 'solid',
  flex: 1,
});

const priceStyle: StyleFn = ({ theme, selected, lineThrough }) => ({
  fontFamily: theme.font.medium,
  letterSpacing: -0.4,
  color: selected ? theme.colors.white : theme.colors.primary,
  fontSize: theme.fontSize.small,
  lineHeight: 14,
  textDecorationLine: lineThrough ? 'line-through' : 'none',
  textDecorationStyle: 'solid',
});

const styles = StyleSheet.create({
  productName: {
    width: '75%',
    flex: 1,
    marginLeft: 4,
  },
});

const Product: React.FC<ProductProps> = ({
  quantity,
  price,
  name,
  onPress,
  selected,
  highlighted,
  type,
  testID,
  containerStyle,
  lineThrough = false,
  disabled = false,
  isRefundOrder,
  showFiredIcon,
  itemFired,
  seatNumber,
}: ProductProps) => {
  const { css, theme } = useFela({
    type,
    selected,
    highlighted,
    lineThrough,
    itemFired,
  });

  const { formatCurrency } = useCurrency();
  return useMemo(
    () => (
      <TouchableOpacity
        style={css(productStyle)}
        onPress={onPress}
        testID={testID}
        disabled={disabled}
      >
        {seatNumber && (
          <NumberTile
            labelStyle={css(seatNumberTextStyle)}
            containerStyle={[css(seatNumberStyle), containerStyle]}
            testID="product-seatNumber"
          >
            {seatNumber}
          </NumberTile>
        )}
        {quantity ? (
          <NumberTile
            labelStyle={css(quantityTextStyle)}
            containerStyle={[css(quantityStyle), containerStyle]}
            testID="product-quantity"
          >
            {type === 'modifier' && quantity <= 1
              ? null
              : isRefundOrder
              ? `- ${quantity}`
              : quantity}
          </NumberTile>
        ) : null}

        <View style={[css(labelContainerStyle), containerStyle]}>
          {showFiredIcon && itemFired && (
            <IconButton
              icon={Icons.Check}
              iconSize={16}
              iconColor={theme.colors.success}
              testID="fired-item-icon"
            />
          )}
          <Text
            numberOfLines={1}
            labelStyle={css(labelStyle)}
            containerStyle={styles.productName}
            testID="product-name"
          >
            {name}
          </Text>

          {price !== undefined && (
            <Text
              numberOfLines={1}
              labelStyle={css(priceStyle)}
              testID="product-price"
            >
              {isRefundOrder && '- '}
              {formatCurrency(price)}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    ),
    [
      css,
      onPress,
      testID,
      disabled,
      seatNumber,
      containerStyle,
      quantity,
      type,
      isRefundOrder,
      showFiredIcon,
      itemFired,
      theme.colors.success,
      name,
      price,
      formatCurrency,
    ],
  );
};

export default Product;
