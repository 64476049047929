/* eslint-disable @typescript-eslint/no-explicit-any */
import { Printer } from '@hitz-group/domain';
import '../../printing/epos-2.17.0';

declare const epson: any;

interface WebPrinter extends Partial<Printer> {
  device_name: string;
  host: string;
  port: number;
}

const DEFAULT_PRINTER_PORT = 8043;
const TIMEOUT = 5000;

const printerDeviceMap: Record<string, any> = [];
/**
 * ----------------------------------------------------------------------------------
 * Network thermal printer (web only) and may be for desktop environments too
 * ----------------------------------------------------------------------------------
 * Supported printers: https://download.epson-biz.com/epson/epson_public_document.php?name=js2170.pdf
 *
 * Connect to network printer and then sends data to printer
 *
 * TODO: need to add other type like BLE / USB
 */
export const printOverNetwork = async (
  printer: WebPrinter,
  message: Buffer,
): Promise<{ success: boolean; message: string }> => {
  /**
   * Make a new connection & Instantiate device with epson sdk, then send message to connected  printer
   */

  return new Promise((resolve, reject) => {
    try {
      const ePosDev = new epson.ePOSDevice();

      const print = (printerDevice: any) => {
        printerDevice.addCommand(message.toString());
        printerDevice.send();
        resolve({ success: true, message: 'Sent to printer' });
      };

      const instantiatePrinter = (deviceObj: any, responseCode: string) => {
        if (responseCode == 'OK') {
          deviceObj.timeout = TIMEOUT;
          printerDeviceMap[deviceObj.connectionObj.address_p] = deviceObj;
          print(printerDeviceMap[deviceObj.connectionObj.address_p]);
        } else {
          reject({
            success: false,
            message: 'Error while connecting to printer',
          });
        }
      };

      const createDevice = (data: string) => {
        if (data == 'OK' || data == 'SSL_CONNECT_OK') {
          ePosDev.createDevice(
            'local_printer',
            ePosDev.DEVICE_TYPE_PRINTER,
            { crypto: false, buffer: true },
            instantiatePrinter,
          );
        } else {
          reject({
            success: false,
            message: 'Error while connecting to printer',
          });
        }
      };

      if (
        printerDeviceMap &&
        printerDeviceMap[printer.host] &&
        printerDeviceMap[printer.host].ePosDev.isConnected()
      ) {
        print(printerDeviceMap[printer.host]);
      } else {
        ePosDev.connect(printer.host, DEFAULT_PRINTER_PORT, createDevice, {
          eposprint: true,
        });
      }
    } catch (err) {
      reject({
        success: false,
        message: err ? err.toString() : '',
      });
    }
  });
};
