import { StyleFn, ResourceOperation, Icons } from '@hitz-group/domain';
import React, { FC } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import { PermissionTreeNode } from '../../../../state/jobRoleSettingsUtility';
import Icon from '../../../../components/Icon/Icon';
import PermissionGroupCheckBox from './PermissionGroupCheckBox';

const header: StyleFn = ({ theme }) => ({
  width: 540,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTopWidth: 1.5,
  borderColor: theme.colors.boxBorder,
  paddingVertical: 15,
});

const headerText: StyleFn = () => ({
  textAlign: 'left',
  paddingLeft: 5,
});

const checkboxContainer: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const checkbox: StyleFn = () => ({
  marginLeft: 30,
  marginRight: 5,
});

const emptySpace: StyleFn = () => ({
  width: 23,
});

const labelContainer: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
});

const PermissionGroupHeader: FC<{
  section: PermissionTreeNode;
  isActive?: boolean;
}> = ({ section, isActive }) => {
  const availableOps = Array.from(
    new Set(section.children?.map(e => e.operation).flat()),
  ) as string[];
  const { css, theme } = useFela();

  return (
    <View style={css(header)}>
      <View style={css(labelContainer)}>
        <Icon
          name={isActive ? Icons.AngleUp : Icons.AngleDown}
          color={isActive ? theme.colors.charcoal : theme.colors.darkGrey}
        />

        <Text testID="permissionGrpTitle" style={css(headerText)}>
          {section.label}
        </Text>
      </View>

      <View style={css(checkboxContainer)}>
        {(['view', 'update'] as ResourceOperation[]).map((operation, i) => {
          if (availableOps?.includes(operation)) {
            return (
              <PermissionGroupCheckBox
                key={section.id + operation}
                permissionGroup={section}
                operation={operation}
              />
            );
          }
          return (
            <View style={css(checkbox)} key={section.id + i}>
              <View testID="noOperationOnPermission" style={css(emptySpace)} />
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default PermissionGroupHeader;
