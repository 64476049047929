import { App, Icons, StyleFn } from '@hitz-group/domain';
import React, { useState, useEffect, FC, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Subscription } from 'rxjs';
import { jobRoleSettingsUtility } from '../../../../state/jobRoleSettingsUtility';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { useFela } from 'react-fela';
import Icon from '../../../../components/Icon/Icon';
import { useTranslation } from '@hitz-group/localization';

const container: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
});
const iconStyle: StyleFn = () => ({
  padding: 10,
});

export interface AppAccessButtonProps {
  app: App;
}

const AppAccessButton: FC<AppAccessButtonProps> = ({ app }) => {
  const { css, theme } = useFela();
  const [enableAppAccess, setEnableAppAccess] = useState<boolean>(false);
  const { translate } = useTranslation();
  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    const subscription: Subscription = jobRoleSettingsUtility.appAccessSettings$
      .pipe(pluck(app))
      .subscribe(data => {
        setEnableAppAccess(!!data);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [app]);

  useEffect(() => {
    const subscription: Subscription = jobRoleSettingsUtility.isEditable$
      .pipe(distinctUntilChanged())
      .subscribe(setIsEditable);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const switchAppAccess = useCallback(() => {
    if (isEditable) {
      jobRoleSettingsUtility.appAccessSettings = {
        ...jobRoleSettingsUtility.appAccessSettings,
        [app]: !jobRoleSettingsUtility.appAccessSettings[app],
      };
    }
  }, [app, isEditable]);

  return (
    <View style={css(container)}>
      <TouchableOpacity onPress={switchAppAccess} disabled={!isEditable}>
        <Icon
          name={enableAppAccess ? Icons.CheckSquare : Icons.SquareFull}
          color={enableAppAccess ? theme.colors.success : theme.colors.charcoal}
          style={css(iconStyle)}
        />
      </TouchableOpacity>
      <Text>{translate(`backofficeManageRoles.${app}`)}</Text>
    </View>
  );
};

export default AppAccessButton;
