import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { StyleFn } from '@hitz-group/domain';
import { useFela, withTheme } from 'react-fela';
export type DataTableHeaderProps = {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

const header: StyleFn = ({ theme }) => ({
  minHeight: 30,
  flexDirection: 'row',
  height: 48,
  paddingHorizontal: theme.padding.small * 3,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 0.8,
  borderStyle: 'solid',
  paddingVertical: theme.padding.small,
  alignItems: 'center',
});

const DataTableHeader: React.FC<DataTableHeaderProps> = props => {
  const { css } = useFela();
  const { children, style, ...rest } = props;

  return (
    <View {...rest} style={[css(header), style]}>
      {children}
    </View>
  );
};

export default withTheme(DataTableHeader);

export { DataTableHeader };
