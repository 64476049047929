import { EscPos } from '@tillpos/xml-escpos-helper';
import format from 'date-fns/format';
import { divider } from './printDivider';
import { MoneyMovement } from '@hitz-group/domain';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import { pascalCase } from 'pascal-case';
import { formatMoneyValue } from '@hitz-group/localization';

export const moneyMovementTemplate = `
  <?xml version="1.0" encoding="UTF-8"?>
  <document>
    <align mode="center">
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <!-- Money Event Details -->
        {{#moneyMovementTopDetails}}
          <text-line>{{moneyMovementTopDetails}}</text-line>
        {{/moneyMovementTopDetails}}
      </align>
      <line-feed />
      <align mode="center">
      <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        {{#moneyMovementBottomDetails}}
          <text-line>{{moneyMovementBottomDetails}}</text-line>
        {{/moneyMovementBottomDetails}}
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- Footer Note -->
      {{#footerNote}}
        <align mode="center">
          <text-line size="0:0">  {{{footerNote}}} </text-line>
        </align>
        <line-feed />
      {{/footerNote}}
      <line-feed />
      <line-feed />
      <line-feed />
    </align>
    <paper-cut />
  </document>
  `;

export const getPrintableBuffer = (
  moneyMovement: MoneyMovement,
  currency: string,
) => {
  const payload = {
    moneyMovementTopDetails: generateMoneyMovementTopDetails(moneyMovement),
    moneyMovementBottomDetails: generateMoneyMovementBottomDetails(
      moneyMovement,
      currency,
    ),
    footerNote: 'Powered By',
    divider: divider() + '\n',
  };

  // Generate buffer
  return EscPos.getBufferFromTemplate(
    moneyMovementTemplate,
    payload,
  ) as unknown as Buffer;
};

// Building print components
/**
 * Money movement details section has two columns
 */
const config: TableUserConfig = {
  columns: {
    0: {
      width: 12,
    },
    1: {
      alignment: 'right',
      width: 31,
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

/**
 * Example output:
 *
 * ```
 * Event Type                           Money In
 * Generated On                         07:48 PM 20-Feb-2021
 * Generated By                         Joanna
 * Device                               POS A1
 * ```
 */
export const generateMoneyMovementTopDetails = (
  moneyMovement: MoneyMovement,
): string => {
  const moneyMovementTable = [
    ['Event Type', pascalCase(moneyMovement.eventType, { delimiter: ' ' })],
    [
      'Generated On',
      format(new Date(+moneyMovement.timestamp), 'hh:mm a dd-MM-yy'),
    ],
    ['Generated By', moneyMovement.user?.name],
    ['Device', moneyMovement.device?.name],
  ];

  return table(moneyMovementTable, config);
};

/**
 * Example output:
 *
 * ```
 * Reason                         Open device
 * Notes                          Float Amount
 * Payment Type                   Cash
 * Amount                         $1.00
 * ```
 */
export const generateMoneyMovementBottomDetails = (
  moneyMovement: MoneyMovement,
  currency: string,
): string => {
  const moneyMovementTable = [
    ['Reason', moneyMovement.reason?.name],
    ['Notes', moneyMovement.notes],
    ['Payment Type', moneyMovement.paymentType?.name],
    ['Amount', formatMoneyValue(+moneyMovement.amount?.amount, currency)],
  ];

  return table(moneyMovementTable, config);
};
