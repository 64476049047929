import React from 'react';
import { Personalisation } from './Personalisation/Personalisation';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
import { translate } from '@hitz-group/localization';

const Tab = createMaterialTopTabNavigator();

const PageStack: React.FC = () => {
  const route = useRoute();
  const params = route.params as { pageId: string };

  return (
    <Tab.Navigator
      tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
        <TabBar tabBar={props} />
      )}
      initialRouteName="PageDetails"
    >
      <Tab.Screen
        name="PageDetails"
        component={Personalisation}
        options={{ tabBarLabel: translate('backOfficeProducts.createNew') }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default PageStack;
