import { CreateDeviceInput } from '@hitz-group/domain';
import React, { useCallback, useEffect } from 'react';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import TableComponent from '../../../../../../components/TableComponent/TableComponent';
import { Helmet } from 'react-helmet';
import BackOfficeCreateNewButton from '../../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { ScrollView, View } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import DevicesListRow from './DevicesListRow';
import { DevicesListStyles } from '../styles/Devices.styles';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import { DeviceData } from '../DevicesListContainer';
import { CreateDeviceModal } from '../../../../../../components/Modals/Devices/CreateDevice';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import { useDeviceProfiles } from '../../../../../../hooks/app/useDeviceProfiles';

interface Props {
  devices: DeviceData[];
  options: {
    loading: boolean;
    error: string | undefined;
    createdDeviceId: string;
  };
  storeId: string;
  venueId: string;
  onPressCreateDevice: (device: CreateDeviceInput) => void;
}

const DevicesList: React.FC<Props> = ({
  devices,
  options: { loading },
  storeId,
  venueId,
  onPressCreateDevice,
}) => {
  const { translate } = useTranslation();
  const styles = DevicesListStyles();
  const { showModal } = useModal();

  const { deviceProfiles, getAllDeviceProfiles } = useDeviceProfiles({
    storeId,
    venueId,
  });

  useEffect(() => {
    getAllDeviceProfiles();
  }, [getAllDeviceProfiles]);

  const onPressCreateNew = useCallback(() => {
    showModal(
      <CreateDeviceModal
        onCreate={onPressCreateDevice}
        storeId={storeId}
        venueId={venueId}
        deviceProfiles={Object.values(deviceProfiles)}
      />,
    );
  }, [deviceProfiles, onPressCreateDevice, showModal, storeId, venueId]);

  const COLUMNS = [
    {
      title: translate('backOfficeDevices.deviceName'),
      width: 130,
      alignItems: 'flex-start',
    },
    {
      title: translate('backOfficeDevices.detailsCombined'),
      flex: 1,
      alignItems: 'flex-start',
    },
    {
      title: translate('backOfficeDevices.appVersion'),
      width: 200,
      alignItems: 'flex-start',
    },
    {
      title: translate('backOfficeDevices.status'),
      width: 100,
      alignItems: 'flex-start',
    },
  ].map(columnName => ({
    ...columnName,
  }));

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <View style={styles.pageStyle}>
          <ScrollView contentContainerStyle={styles.scrollStyle}>
            <BackOfficeSection
              containerStyle={styles.containerStyle}
              action={
                <BackOfficeCreateNewButton
                  buttonText={'backOfficeDevices.addDeviceBTN'}
                  onPress={onPressCreateNew}
                />
              }
              title={translate('backOfficeSettings.devices')}
              titleDescription={translate(
                'backOfficeDevices.deviceSettingsDescription',
              )}
            >
              <TableComponent
                normalRows={true}
                columns={COLUMNS}
                columnContainerStyle={styles.columnContainerStyle}
                data={devices}
                renderRow={(item: DeviceData) => (
                  <DevicesListRow
                    key={item.id}
                    device={item}
                    storeId={storeId}
                  />
                )}
              />
            </BackOfficeSection>
          </ScrollView>
        </View>
      )}
    </>
  );
};

export default DevicesList;
