import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';
import scale, { isWeb } from '../../../../common/theme';

export const StoresListRowStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        rowViewStyle: {
          flexDirection: 'row',
        },
        storeRowContainerStyle: {
          flex: 5,
          alignContent: 'flex-start',
          paddingLeft: theme.padding.small,
          ...theme.borderDashed,
        },
        nameViewStyle: {
          height: rowHeight,
          flex: 4,
          justifyContent: 'center',
        },
        nameContainerStyle: {
          marginVertical: 'auto',
          ...theme.font14RegularCharcoal,
        },
        statusViewStyle: {
          height: rowHeight,
          flex: 1,
        },
        actionsViewStyle: {
          height: rowHeight,
          flex: 1,
        },
        fieldInputContainerStyle: {
          width: '90%',
          paddingVertical: 'auto',
          height: rowHeight,
        },
        settingsContainerStyle: {
          alignSelf: 'flex-end',
          borderRadius: theme.radius.small,
        },
        settingsIconStyle: { color: theme.colors.blue },
        navigationContainerStyle: {
          alignSelf: 'flex-end',
          borderRadius: theme.radius.small,
        },
        navigationIconStyle: { color: theme.colors.paragraph },
      }),
    [theme],
  );
};

export const VenuesListRowStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        rowContainerStyle: {
          flex: 1,
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          borderBottomColor: theme.colors.boxBorder,
        },
        nameViewStyle: {
          height: rowHeight,
          flex: 4,
          justifyContent: 'center',
        },
        nameContainerStyle: {
          ...theme.font14RegularCharcoal,
        },
        statusViewStyle: {
          alignSelf: 'flex-start',
          alignItems: 'flex-start',
          height: rowHeight,
          flex: 1,
        },
        actionsViewStyle: {
          height: rowHeight,
          flex: 1,
        },
        fieldInputContainerStyle: {
          width: '90%',
          height: rowHeight,
        },
        settingsContainerStyle: {
          alignSelf: 'flex-end',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.highlighted,
        },
        settingsIconStyle: { color: theme.colors.blue },
        formTextStyle: {
          paddingHorizontal: 0,
          paddingVertical: 0,
        },
        checkBoxTitleContainer: {
          height: rowHeight,
          alignItems: 'center',
        },
        checkIconContainer: {
          borderColor: theme.colors.green,
          borderWidth: 2,
          height: 17,
          width: 17,
        },
        unCheckContainer: {
          borderColor: theme.colors.paragraphLight,
          borderWidth: 2,
          height: 17,
          width: 17,
        },
      }),
    [theme],
  );
};

export const VenuesListStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        width100row: {
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        },
        formStyle: {
          // width: scale.backOfficeWidth400,
          width: isWeb ? scale.moderateScale(450) : '90%',
          alignSelf: 'center',
          paddingBottom: theme.spacing.big,
          alignItems: 'center',
        },
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        columnContainerStyle: {
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          borderBottomWidth: 0,
        },
        tableContainer: {
          width: isWeb ? scale.moderateScale(450) : '90%',
          alignSelf: 'center',
        },
        titleActionStyle: {
          flexDirection: 'row',
          marginTop: theme.spacing.medium,
          justifyContent: 'center',
          alignItems: 'center',
          width: isWeb ? scale.moderateScale(450) : '100%',
        },
        searchContainerStyle: {
          flexGrow: 1,
          width: '70%',
          height: theme.input.height,
          justifyContent: 'center',
          backgroundColor: theme.colors.white,
          borderColor: theme.colors.boxBorder,
          borderWidth: 1,
        },
        searchTextInputStyle: {
          width: '100%',
          flex: 1,
          height: theme.input.height,
          paddingVertical: scale.moderateScale(5),
        },
        iconContainer: {
          width: 140,
        },
        createButtonStyle: {
          marginLeft: 15,
        },
        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
        },
        saveButtonStyle: {
          width: 120,
          height: 38,
          marginLeft: 'auto',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
          alignSelf: 'auto',
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
      }),
    [theme],
  );
};
