import {
  NotificationMode,
  NotificationType,
  OrderAction,
  OrderPaymentStatus,
  OrderType,
  PaymentType,
  Product,
} from '@hitz-group/domain';
import { Order } from '@hitz-group/domain';
import { OrderHistoryDataProps } from '../components/OrderDataView/OrderHistoryView';
import { Session } from '../state/Session';
import { userUtility } from '../state/userUtility';
import { OrderTypesMap, createOrderTypesMap } from './orderTypesHelper';
import { v4 as uuidv4 } from 'uuid';
import { IN_COMPLETE_PAYMENT_STATUS_LIST } from '../types/Common';

export const mapOrderToOrderHistoryView = (
  obj: Order,
  orderTypes: OrderTypesMap,
): OrderHistoryDataProps => {
  const orderType = (obj.orderType || {}) as unknown as OrderType;
  return {
    id: obj.id,
    orderNumber: obj.orderNumber,
    createdAt: obj.createdAt,
    completedAt: obj.updatedAt || 0,
    orderItems: obj.orderItems
      ? obj.orderItems.map((item: { product: unknown }) => ({
          name: (item.product as unknown as Product).name,
        }))
      : [],
    customer: obj.customer,
    customerName: obj.customer
      ? obj.customer?.firstName + ' ' + obj.customer?.lastName
      : 'N/A',
    orderType: {
      ...orderType,
      colorId: orderTypes[orderType.id]?.colorId || 0,
      code: orderTypes[orderType.id]?.code || orderType.code || '',
    },
    payTypes: obj.payments
      .filter(
        payment =>
          !IN_COMPLETE_PAYMENT_STATUS_LIST.includes(
            payment.status as OrderPaymentStatus,
          ),
      )
      .map(payment => {
        return {
          id: payment?.paymentType?.id,
          name: payment?.paymentType?.name,
          amount: payment?.amount || 0,
        };
      }),
    totalValue: obj.totalPrice + (obj?.roundingAmount || 0),
    table: obj.table,
    refundOf: obj.refundOf ? obj.refundOf : undefined,
  };
};

export const sendOrderReceipt = async (
  order: Order,
  email: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  syncEventsCallback: any,
  session?: Session,
) => {
  if (session && order?.id) {
    const event = {
      action: OrderAction.CUSTOMER_NOTIFICATION,
      orderId: order.id,
      id: uuidv4(),
      notificationType: NotificationType.SEND_RECEIPT,
      notificationMode: NotificationMode.EMAIL,
      organizationId: session.currentOrganization?.id || '',
      venueId: session.currentVenue?.id || '',
      storeId: session.currentStore?.id || '',
      deviceId: session.device?.id || '',
      timestamp: new Date().getTime(),
      triggeredBy: userUtility.userActivity.posUser?.id,
      email: email,
    };
    syncEventsCallback({ variables: { input: [event] } });
  }
};

export const mapOrdersToOrdersView = (
  array: Array<Order>,
  orderTypes: OrderType[],
): OrderHistoryDataProps[] => {
  const orderTypesDict = createOrderTypesMap(orderTypes);
  return array.map(item => mapOrderToOrderHistoryView(item, orderTypesDict));
};

export const getPaymentTypeName = (
  paymentTypes: PaymentType[],
  paymentTypeId: string,
): string => {
  return paymentTypes.find(payType => payType.id === paymentTypeId)?.name || '';
};

export const filterOrderHistoryData = (
  orders: Array<OrderHistoryDataProps>,
  paymentTypeFilter: PaymentType['id'],
  orderTypeFilter: OrderType['name'],
  keywordFilter: string,
  endDate?: number,
  startDate?: number,
): Array<OrderHistoryDataProps> =>
  orders
    .filter(order => {
      if (startDate && order.completedAt < startDate) {
        return false;
      }

      if (endDate && order.completedAt >= endDate) {
        return false;
      }

      if (
        paymentTypeFilter &&
        !order.payTypes?.find(x => x.id && x.id.includes(paymentTypeFilter))
      ) {
        return false;
      }

      if (
        orderTypeFilter &&
        !order.orderType?.name?.includes(orderTypeFilter)
      ) {
        return false;
      }

      if (keywordFilter) {
        const query = keywordFilter.toLowerCase();
        const customerMatch = !!order.customerName
          ?.toLowerCase()
          .includes(query);
        const orderNumberMatch = !!order.orderNumber
          ?.toLowerCase()
          .includes(query);
        const itemsNameMatch = !!order.orderItems?.some(({ name }) =>
          name?.toLowerCase().includes(query),
        );
        const tableNumberMatch = !!order.table?.name
          ?.toLowerCase()
          .includes(query);

        return (
          customerMatch ||
          orderNumberMatch ||
          itemsNameMatch ||
          tableNumberMatch
        );
      }
      return true;
    })
    .sort((x, y) => y.completedAt - x.completedAt);
