import { Icons, StyleFn } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import {
  Platform,
  PixelRatio,
  Text,
  View,
  TouchableOpacity,
} from 'react-native';
import { Icon } from '../../../../../../components/Icon/Icon';
import { useNavigation } from '@react-navigation/native';
import IconButton from '../../../../../../components/Button/IconButton';

const ctaTitleApp: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,

  width: '100%',
  color: theme.colors.paragraph,
  textAlign: 'left',
});

const ctaTile: StyleFn = ({ theme }) => ({
  borderWidth: 0,
  borderStyle: 'solid',
  marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
  marginBottom: PixelRatio.getPixelSizeForLayoutSize(5),
  backgroundColor: theme.colors.lightBlueTile,
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  alignItems: 'flex-start',
  textAlign: 'left',
  paddingVertical: theme.padding.large,
  paddingHorizontal: theme.padding.small * 3,
  justifyContent: 'flex-start',
  width: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(300),
    default: PixelRatio.getPixelSizeForLayoutSize(160),
    ios: PixelRatio.getPixelSizeForLayoutSize(160),
  }),
  height: 280,
});

const ctaIcon: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.highlighted,
  width: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(45),
    default: PixelRatio.getPixelSizeForLayoutSize(23),
  }),

  padding: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(10),
    default: PixelRatio.getPixelSizeForLayoutSize(5),
  }),
  borderRadius: PixelRatio.getPixelSizeForLayoutSize(300),
  textAlign: 'center',
});

const ctaSubTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  marginTop: theme.spacing.small,
  maxWidth: 802,
  flex: 1,
  flexWrap: 'wrap',
  color: theme.colors.dark,
  textAlign: 'left',
});

const ctaTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  marginTop: theme.spacing.medium,

  color: theme.colors.primary,
  textAlign: 'left',
});

const ctaActionSimilarFeatures: StyleFn = () => ({
  flex: 1,
  flexDirection: 'row',
  marginTop: PixelRatio.getPixelSizeForLayoutSize(30),
  width: '90%',
});

export interface RelatedFeatureTileProps {
  id: string;
  icon: string;
  title: string;
  description: string;
  category: string;
}

export const DisabledFeatures: React.FC<RelatedFeatureTileProps> = ({
  id,
  icon,
  title,
  description,
  category,
}) => {
  const { css, theme } = useFela();
  const navigation = useNavigation();
  const navigateToFeatureDetails = useCallback(() => {
    navigation &&
      navigation.navigate('FeaturesSettings', {
        featureId: id,
        title: title,
      });
  }, [navigation, id, title]);
  return (
    <TouchableOpacity key={id} onPress={navigateToFeatureDetails}>
      <View style={css(ctaTile)} key={id}>
        <View style={css(ctaIcon)}>
          <Icon name={icon} color={theme.colors.blue} />
        </View>

        <Text
          onPress={navigateToFeatureDetails}
          style={css(ctaTitle)}
          testID="toggle-action"
        >
          {title}
        </Text>
        <Text
          onPress={navigateToFeatureDetails}
          style={css(ctaSubTitle)}
          testID="toggle-action-description"
        >
          {description}
        </Text>
        <View style={css(ctaActionSimilarFeatures)}>
          <Text
            onPress={navigateToFeatureDetails}
            style={css(ctaTitleApp)}
            testID="toggle-action-learnmore"
          >
            {category}
          </Text>
          <IconButton
            primary
            icon={Icons.ArrowRight}
            iconSize={24}
            containerSize={20}
            iconColor={theme.colors.text}
            testID="toggle-action-icon"
            onPress={navigateToFeatureDetails}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};
