import {
  StyleFn,
  Page,
  Product,
  Variant,
  ModifierGroup,
  Modifier,
} from '@hitz-group/domain';
import React, { useCallback, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { View, Text, Animated } from 'react-native';
import DraggableGrid from './DraggableGrid';
import { isWeb } from '../../common/theme';
import scale from '../../common/theme';
import sortBy from 'lodash/sortBy';
import { MenuTile, DraggableTile, GRID_TYPE, PAGE_TYPE } from './menuTypes';
import { SidePanelProps, SidePanel } from './SidePanels/SidePanel';
const body: StyleFn = ({}) => ({
  flex: 1,
  flexDirection: 'row',
});
const itemText: StyleFn = ({ theme, blockType }) => ({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: theme.fontSize.small,
  paddingLeft: 35,
  paddingRight: 10,
  textAlign: 'center',
  color:
    (blockType as MenuTile) === 'Modifier' ||
    (blockType as MenuTile) === 'VariantOption'
      ? theme.colors.black
      : theme.colors.white,
});
const gridStyle: StyleFn = ({}) => ({
  width: isWeb
    ? scale.moderateScale(101) * 5 + 25
    : scale.moderateScale(63) * 5 + 25,
});

const flex09: StyleFn = ({}) => ({ flex: 1.8 });
const flex05: StyleFn = ({}) => ({
  flex: 1.2,
});

const gridSubView: StyleFn = () => ({
  flex: 1,
  margin: 10,
  flexDirection: 'row',
});

export interface DraggableMenuLayoutProps {
  isLeftMargin?: boolean;
  pages: Array<Page>;
  pageItems: DraggableTile[];
  allProductsMap: { [key: string]: Product };
  allVariantsMap: { [key: string]: Variant };
  onSelectPage: (pageId: string, items: Product[] | Variant[]) => void;
  onDragProducts: (objcts: DraggableTile[]) => void;
  onDragPages: (pages: DraggableTile[]) => void;
  selectedPage: string;
  pageOptions: { value: string; label: string }[];
}

export const DraggableMenuLayout = (props: DraggableMenuLayoutProps) => {
  const { css, theme } = useFela();
  const {
    selectedPage,
    pageItems,
    pages,
    onDragProducts,
    onDragPages,
    pageOptions,
  } = props;
  const [animatePress, setAnimatePress] = useState(new Animated.Value(0));
  const [subProductItems, setSubProductItems] = useState(
    [] as DraggableTile[][],
  );
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [sidePanelState, setSidePanelState] = useState<SidePanelProps>();

  const renderItems = useCallback(
    (item: Product, index: number, blockType?: MenuTile) => {
      return (
        <Text
          key={index}
          style={css(itemText({ theme, blockType }))}
          numberOfLines={2}
        >
          {item.name}
        </Text>
      );
    },
    [css, theme],
  );

  const onDragStart = useCallback(() => {
    setAnimatePress(new Animated.Value(1));
    Animated.timing(animatePress, {
      toValue: 3,
      duration: 400,
      useNativeDriver: isWeb ? true : false, // Add This line
    }).start();
  }, [animatePress]);

  const onSelectVariant = useCallback(
    (variantId: string) => {
      const variantData = props?.allVariantsMap?.[variantId];
      const allKeyValuePages: DraggableTile[][] = [];

      variantData.options?.forEach(eachOption => {
        const keyPage: DraggableTile[] = eachOption?.values?.map(
          (data: string, i: number) => {
            return {
              keyValue: data,
              key: i,
              name: data,
              disabledDrag: false,
              disabledReSorted: false,
            };
          },
        );
        allKeyValuePages.push(keyPage);
      });
      setSubProductItems(allKeyValuePages);
      setSelectedProduct(variantId);
    },
    [props?.allVariantsMap],
  );

  const onSelectModifier = useCallback(
    (typeName: string, productId: string) => {
      const modifierGroups = props?.allProductsMap?.[productId]?.modifierGroups;

      const requiredModGroup: ModifierGroup[] = [];
      const nonReqGroupedModGroup: ModifierGroup[] = [];
      let miscModGroup: Modifier[] = [];
      sortBy(modifierGroups, ['priority']).forEach(eachModGroup => {
        if (eachModGroup.isRequired) requiredModGroup.push(eachModGroup);
        else if (eachModGroup.isGrouped)
          nonReqGroupedModGroup.push(eachModGroup);
        else if (eachModGroup?.modifiers?.length)
          miscModGroup = miscModGroup.concat(eachModGroup?.modifiers);
      });

      const subItemsTemp: DraggableTile[][] = [];

      if (requiredModGroup?.length) {
        // converting modifiers data to dragable tile data format
        requiredModGroup.forEach(eachReqModGroup => {
          const modifiersBatch: DraggableTile[] = [];
          eachReqModGroup.modifiers?.forEach((eachMod, modIndex) => {
            modifiersBatch.push({
              ...eachMod,
              key: modIndex,
              name: eachMod.name,
              disabledDrag: false,
              disabledReSorted: false,
            });
          });
          subItemsTemp.push(modifiersBatch);
        });
      }

      const miscModGroupsTemp = [...nonReqGroupedModGroup, ...miscModGroup];

      if (miscModGroupsTemp?.length) {
        const modifiersBatch: DraggableTile[] = [];
        // converting modifiers data to dragable tile data format
        miscModGroupsTemp?.forEach((eachMod, indexMod) => {
          modifiersBatch.push({
            ...eachMod,
            key: indexMod,
            name: eachMod.name,
            disabledDrag: false,
            disabledReSorted: false,
          });
        });
        subItemsTemp.push(modifiersBatch);
      }
      setSubProductItems(subItemsTemp);
      setSelectedProduct(productId);
    },
    [props?.allProductsMap],
  );

  const onDone = useCallback((type: MenuTile) => {
    if (type === 'Modifier' || type === 'VariantOption') {
      setSubProductItems([]);
      setSelectedProduct('');
    }
  }, []);

  const onSelectTilePopup = useCallback(
    (type?: MenuTile, id?: string, pageType?: PAGE_TYPE) => {
      if (type && id) {
        setSidePanelState({ type, id } as SidePanelProps);
      } else if (type) {
        setSidePanelState({
          type,
          pages:
            (pageType === PAGE_TYPE.SUBPAGE && pageType && [selectedPage]) ||
            [],
          pageType,
        } as SidePanelProps);
      }
      id && setSelectedProduct(id);
    },
    [selectedPage],
  );

  const onCloseSidePanel = useCallback(() => {
    setSelectedProduct('');
    setSidePanelState({} as SidePanelProps);
  }, []);

  const onChangeType = useCallback((type?: MenuTile) => {
    setSidePanelState(prev => ({ ...prev, type } as SidePanelProps));
  }, []);

  const pagesData = useMemo(() => {
    const result: DraggableTile[] = pages
      .filter(x => x && !x.isSubPage)
      .map((data: Page, i: number) => {
        return {
          ...data,
          key: i,
          name: data.name,
          disabledDrag: false,
          disabledReSorted: false,
        };
      });
    return result;
  }, [pages]);

  const addPageToMainPage = useCallback(
    (pageData: Page) => {
      const isPageExisting = pagesData.find(x => x && x.id == pageData?.id);
      if (pageData?.id && !isPageExisting) {
        const modifiedPages = [
          ...pagesData,
          {
            ...pageData,
            key: pagesData.length,
            disabledDrag: false,
            disabledReSorted: false,
          },
        ];
        onDragPages(modifiedPages);
      }
    },
    [onDragPages, pagesData],
  );

  const addPageToSubPage = useCallback(
    (pageData: Page) => {
      const tempPageItems = [
        ...pageItems,
        {
          ...pageData,
          key: pageItems.length,
          disabledDrag: false,
          disabledReSorted: false,
        },
      ];
      onDragProducts(tempPageItems);
    },
    [onDragProducts, pageItems],
  );

  const onPageAdd = useCallback(
    (pageData: Page, pageType: PAGE_TYPE) => {
      if (pageType === PAGE_TYPE.SUBPAGE && selectedPage) {
        addPageToSubPage(pageData);
      }
      addPageToMainPage(pageData);
    },
    [addPageToMainPage, addPageToSubPage, selectedPage],
  );

  const onProductAdd = useCallback(
    (prodData: Product) => {
      const tempPageItems = [
        ...pageItems,
        {
          ...prodData,
          key: pageItems.length,
          disabledDrag: false,
          disabledReSorted: false,
        },
      ];
      onDragProducts(tempPageItems);
    },
    [onDragProducts, pageItems],
  );

  const onProductUpdate = useCallback(() => {
    const pageData = pages.find(x => x.id === selectedPage);
    if (pageData?.id && selectedPage) {
      props.onSelectPage &&
        props.onSelectPage(selectedPage, [
          ...(pageData?.products || []),
          ...(pageData?.variants || []),
          ...(pageData?.pages || []),
        ] as Product[]);
    }
  }, [pages, props, selectedPage]);

  return (
    <View style={css(body)}>
      <SidePanel
        {...sidePanelState}
        onClose={onCloseSidePanel}
        onChangeType={onChangeType}
        pageOptions={pageOptions}
        onPageAdd={onPageAdd}
        onProductAdd={onProductAdd}
        onProductUpdate={onProductUpdate}
      />
      <View style={css(gridSubView)}>
        <View style={css(gridStyle)}>
          <View style={css(flex09)}>
            <DraggableGrid
              numColumns={5}
              numRows={5}
              renderItem={renderItems}
              data={pageItems}
              onDragStart={onDragStart}
              onDragRelease={onDragProducts}
              onSelectVariant={onSelectVariant}
              onSelectModifier={onSelectModifier}
              selectedItem={selectedProduct}
              onSelectTilePopup={onSelectTilePopup}
              scrollDirection={'horizontal'}
              highlightSelectedItem={true}
              gridType={GRID_TYPE.PAGE_ITEMS}
            />
          </View>
          <View style={css(flex05)}>
            <DraggableGrid
              numColumns={5}
              numRows={3}
              renderItem={renderItems}
              data={subProductItems}
              onDragStart={onDragStart}
              blockType={'Modifier'}
              onSelectionDone={onDone}
              scrollDirection={'horizontal'}
              gridType={GRID_TYPE.PRODUCT_ITEMS}
            />
          </View>
        </View>

        <DraggableGrid
          numColumns={1}
          numRows={9}
          renderItem={renderItems}
          data={pagesData}
          onDragStart={onDragStart}
          scrollDirection={'vertical'}
          onSelectTilePopup={onSelectTilePopup}
          onSelectPage={props.onSelectPage}
          selectedItem={selectedPage}
          onDragRelease={onDragPages}
          gridType={GRID_TYPE.PAGE}
        />
      </View>
    </View>
  );
};

export default DraggableMenuLayout;
