import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';

import { useMemo, useCallback, useEffect, useState } from 'react';
import {
  Section,
  CreateSectionInput,
  UpdateSectionInput,
} from '@hitz-group/domain';
import { Operation } from '../../../types/Operation';
import { ApolloError } from '@apollo/client';
import {
  CREATE_SECTION,
  DELETE_SECTION,
  GET_SECTIONS,
  GET_SECTION,
  UPDATE_SECTION,
  UPDATE_SECTIONS,
  COPY_SECTION,
} from './graphql';
import { noopHandler, parseApolloError } from '../../../utils/errorHandlers';
import { keyBy } from 'lodash';

export interface UseSections {
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  section: Section;
  sections: { [key: string]: Section };
  createdSectionId: string;
  copiedSectionId: string;
  updatedSectionIds: string[];
  updatedSectionId: string;
  getSection: () => void;
  getSections: () => void;
  createSection: (sectionsInput: CreateSectionInput) => void;
  copySection: (id: string) => void;
  updateSection: (sectionsInput: UpdateSectionInput) => void;
  updateSections: (sectionsInput: UpdateSectionInput[]) => void;
  deleteSection: (id: string) => void;
}

export interface Props {
  venueId?: string;
  sectionId?: string;
}

export const useSections = (props?: Props): UseSections => {
  const { venueId, sectionId } = props || {};

  const [sections, setSections] = useState<Record<string, Section>>({});

  const [section, setSection] = useState<Section>({} as Section);

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [createdSectionId, setCreatedSectionId] = useState<string>('');

  const [copiedSectionId, setCopiedSectionId] = useState<string>('');

  const [updatedSectionIds, setUpdatedSectionIds] = useState<string[]>([]);

  const [updatedSectionId, setUpdatedSectionId] = useState<string>('');

  const [deletedSectionId, setDeletedSectionId] = useState<string>('');

  // get section
  const onCompleteGetSectionRequest = useCallback(
    data => {
      if (data) {
        const sectionData = data.section as Section;
        setSection(sectionData);
      }
    },
    [setSection],
  );

  const [getSectionRequest, getSectionResponse] = useLazyQuery(GET_SECTION, {
    fetchPolicy: 'cache-and-network',
    context: {
      headers: { venue: venueId },
    },
    variables: { id: sectionId },
    onCompleted: onCompleteGetSectionRequest,
  });

  // get sections
  const onCompleteGetSectionsRequest = useCallback(
    data => {
      if (data && data?.sections?.length) {
        setSections(keyBy(data.sections, 'id'));

        setOperation(Operation.READ);
      }
    },
    [setSections],
  );

  const [getSectionsRequest, getSectionsResponse] = useLazyQuery(GET_SECTIONS, {
    fetchPolicy: 'cache-and-network',
    context: {
      headers: { venue: venueId },
    },
    variables: { id: venueId },
    onCompleted: onCompleteGetSectionsRequest,
  });

  // batch update sections
  const [updateSectionsRequest, updateSectionsResponse] = useMutation(
    UPDATE_SECTIONS,
    {
      onError: noopHandler,
      context: {
        headers: { venue: venueId },
      },
    },
  );

  useEffect(() => {
    if (updateSectionsResponse.data) {
      const sectionsData = updateSectionsResponse.data
        .updateSections as Section[];

      setSections(previousSections => {
        const sectionsTemp = { ...previousSections };
        sectionsData.forEach(sectionData => {
          sectionsTemp[sectionData.id] = {
            ...sectionsTemp[sectionData.id],
            ...sectionData,
          };
        });
        return sectionsTemp;
      });
      setUpdatedSectionIds(sectionsData.map(section => section.id));
    }
  }, [updateSectionsResponse.data]);

  const updateSections = useCallback(
    (sectionsInput: Partial<UpdateSectionInput[]>) => {
      updateSectionsRequest({
        variables: {
          input: sectionsInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateSectionsRequest],
  );

  // update section
  const [updateSectionRequest, updateSectionResponse] = useMutation(
    UPDATE_SECTION,
    {
      onError: noopHandler,
      context: {
        headers: { venue: venueId },
      },
    },
  );

  useEffect(() => {
    if (updateSectionResponse.data) {
      const sectionData = updateSectionResponse.data.updateSection as Section;

      setSections(previousSections => {
        const sectionsTemp = { ...previousSections };
        sectionsTemp[sectionData.id] = sectionData;
        return sectionsTemp;
      });
      setUpdatedSectionIds(previousIds => [...previousIds, sectionData.id]);
      setUpdatedSectionId(sectionData.id);
    }
  }, [updateSectionResponse.data]);

  const updateSection = useCallback(
    (sectionInput: Partial<UpdateSectionInput>) => {
      updateSectionRequest({
        variables: {
          input: sectionInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateSectionRequest],
  );

  // create
  const [createSectionRequest, createSectionResponse] = useMutation(
    CREATE_SECTION,
    {
      onError: noopHandler,
      context: {
        headers: { venue: venueId },
      },
    },
  );

  useEffect(() => {
    if (createSectionResponse.data) {
      const sectionData = createSectionResponse.data.createSection as Section;

      setSections(section => {
        return {
          ...section,
          [sectionData.id]: sectionData,
        };
      });
      setCreatedSectionId(sectionData.id);
    }
  }, [createSectionResponse.data]);

  const createSection = useCallback(
    (sectionInput: CreateSectionInput) => {
      createSectionRequest({
        variables: {
          input: sectionInput,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createSectionRequest],
  );

  // copy
  const [copySectionRequest, copySectionResponse] = useMutation(COPY_SECTION, {
    onError: noopHandler,
    context: {
      headers: { venue: venueId },
    },
  });

  const copySection = useCallback(
    (id: string) => {
      copySectionRequest({
        variables: {
          id,
        },
      });
      setOperation(Operation.CREATE);
    },
    [copySectionRequest],
  );

  useEffect(() => {
    if (copySectionResponse.data) {
      const sectionData = copySectionResponse.data.copySection as Section;

      setSections(section => {
        return {
          ...section,
          [sectionData.id]: sectionData,
        };
      });
      setCopiedSectionId(sectionData.id);
    }
  }, [copySectionResponse.data, copiedSectionId]);

  // delete
  const [deleteSectionRequest, deleteSectionResponse] = useMutation(
    DELETE_SECTION,
    {
      onError: noopHandler,
      context: {
        headers: { venue: venueId },
      },
    },
  );

  const deleteSection = useCallback(
    (id: string) => {
      setDeletedSectionId(id);
      deleteSectionRequest({
        variables: {
          id,
        },
      });
      setOperation(Operation.DELETE);
    },
    [deleteSectionRequest],
  );

  useEffect(() => {
    if (deleteSectionResponse.data) {
      if (deletedSectionId)
        setSections(section => {
          delete section[deletedSectionId];
          return {
            ...section,
          };
        });
    }
  }, [deleteSectionResponse.data, deletedSectionId]);

  const error: ApolloError | undefined =
    getSectionResponse.error ||
    getSectionsResponse.error ||
    updateSectionResponse.error ||
    updateSectionsResponse.error ||
    deleteSectionResponse.error ||
    createSectionResponse.error ||
    copySectionResponse.error;

  const loading: boolean =
    getSectionResponse.loading ||
    getSectionsResponse.loading ||
    updateSectionResponse.loading ||
    updateSectionsResponse.loading ||
    deleteSectionResponse.loading ||
    createSectionResponse.loading ||
    copySectionResponse.loading;

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
      section,
      sections,
      createdSectionId,
      copiedSectionId,
      updatedSectionIds,
      updatedSectionId,
      getSection: getSectionRequest,
      getSections: getSectionsRequest,
      createSection,
      copySection,
      updateSection,
      updateSections,
      deleteSection,
    }),
    [
      loading,
      error,
      operation,
      section,
      sections,
      createdSectionId,
      copiedSectionId,
      updatedSectionIds,
      updatedSectionId,
      createSection,
      copySection,
      getSectionRequest,
      getSectionsRequest,
      updateSection,
      updateSections,
      deleteSection,
    ],
  );
};
