/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect, useMemo } from 'react';
import Fingerprint2 from '@fingerprintjs/fingerprintjs';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
declare const window: any;

export function useDeviceId(): {
  deviceId: string;
  error?: string;
} {
  const [id, setId] = useState('');
  const [error, setError] = useState('');
  const createFingerPrint = useCallback(
    () =>
      Fingerprint2.get(function (components: any) {
        const values = components.map(function (component: { value: any }) {
          return component.value;
        });

        const murmur = Fingerprint2.x64hash128(values.join(''), 31);
        setId(murmur);
      }),
    [],
  );

  useEffect(() => {
    let fingerPrintTimeOut: any;
    try {
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        setId(DeviceInfo.getUniqueId());
      } else {
        if (window.requestIdleCallback) {
          window.requestIdleCallback(function () {
            createFingerPrint();
          });
        } else {
          fingerPrintTimeOut = setTimeout(function () {
            createFingerPrint();
          }, 500);
        }
      }
    } catch (e) {
      setError(e);
    }
    return () => {
      fingerPrintTimeOut && clearTimeout(fingerPrintTimeOut);
    };
  }, [createFingerPrint]);

  return useMemo(
    () => ({
      deviceId: id,
      error,
    }),
    [id, error],
  );
}
