import {
  DeputySettingsInput,
  StyleFn,
  WorkforceIntegrationStorePreferences,
} from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../../../../../components/BackOfficeSection/BackOfficeSection';
import { ScrollView, View, Text } from 'react-native';
import { isWeb } from '../../../../../../../common/theme';
import { bottomSpace } from '../../../../../Settings/GeneralSettings.styles';
import { useIsFocused, useRoute } from '@react-navigation/native';
import IconButton from '../../../../../../../components/Button/IconButton';
import Button from '../../../../../../../components/Button/Button';
import { useWorkforceIntegration } from '../../../../../../../hooks/app/workforceIntegrations/useWorkforceIntegration';
import DeputyModal from './DeputyRunModal/DeputyModal';
import { useModal } from '@hitz-group/rn-use-modal';
import { useNotification } from '../../../../../../../hooks/Notification';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});
const scrollStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: isWeb ? '60%' : '100%',
  alignSelf: 'center',
});

const noMenuesTextStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.big / 2,
  color: theme.colors.primaryLightest,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
});

export const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.padding.medium / 2,
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

export const rowStyleActive: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.padding.medium / 2,
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
  backgroundColor: theme.colors.successLightest,
});

export const defaultContainerStyle: StyleFn = ({ theme }) => ({
  width: 300,
  height: theme.input.height,
  marginTop: theme.spacing.small / 2,
  marginRight: theme.spacing.big / 1,
  borderRadius: theme.radius.small,
});
export const defaultTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.medium,
  marginTop: 10,
  ...theme.font14RegularCharcoal,
});

export const uploadContainerStyle: StyleFn = () => ({
  width: 34,
  height: 34,
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginLeft: 'auto',
});

export const iconContainerStyle: StyleFn = () => ({
  width: 25,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
});

export const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 20,
  height: theme.input.height,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: theme.spacing.small / 5,
  alignSelf: 'flex-start',
});

export const checkContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.big / 2,
});

const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const StoreSettings: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const route = useRoute();
  const { showModal } = useModal();
  const [toggleOrdersAction, setToggleOrdersAction] = useState(false);
  const [toggleUsersAction, setToggleUsersAction] = useState(false);
  const [toggleTimeSheetsAction, setToggleTimeSheetsAction] = useState(false);
  const { showNotification } = useNotification();

  const routeParams = route.params as {
    locationId: number;
    storeId: string;
  };
  const { locationId, storeId } = routeParams;
  const isFocused = useIsFocused();
  const location = locationId;
  const store = storeId;
  const {
    error,
    loading,
    integrationPartnerStores,
    getIntegrationPartnerStoreConfig,
    updateStoreWithDeputySettings,
    updatedStoreWithDeputySettings,
  } = useWorkforceIntegration();

  useEffect(() => {
    if (isFocused) {
      getIntegrationPartnerStoreConfig(store);
    }
  }, [
    isFocused,
    getIntegrationPartnerStoreConfig,
    store,
    integrationPartnerStores,
  ]);

  const connectedLocations = {
    actions: [
      'New Order -> Create Sale',
      'New User -> Add Team Member',
      'Timesheet -> Sync Timesheets',
    ],
  };

  const connectedActions = useMemo(() => {
    const data = {
      syncOrders: integrationPartnerStores?.syncOrders,
      syncUsers: integrationPartnerStores?.syncUsers,
      syncTimesheets: integrationPartnerStores?.syncTimesheets,
    };
    return data as unknown as WorkforceIntegrationStorePreferences;
  }, [integrationPartnerStores]);

  useEffect(() => {
    if (connectedActions) {
      setToggleOrdersAction(connectedActions.syncOrders);
      setToggleUsersAction(connectedActions.syncUsers);
      setToggleTimeSheetsAction(connectedActions.syncTimesheets);
    }
  }, [connectedActions, getIntegrationPartnerStoreConfig, store]);

  const deputyModal = useCallback(
    (locationId, isOrder: boolean): void => {
      showModal(
        <DeputyModal
          storeId={store}
          connectedActions={connectedActions}
          locationId={locationId}
          isOrder={isOrder}
        />,
      );
    },
    [connectedActions, showModal, store],
  );
  const toggleButtonUsers = useCallback((): void => {
    setToggleUsersAction(prevCheck => !prevCheck);
  }, []);

  const toggleButtonOrders = useCallback((): void => {
    setToggleOrdersAction(prevCheck => !prevCheck);
  }, []);

  const toggleButtonTimeSheets = useCallback((): void => {
    if (toggleUsersAction === false && toggleTimeSheetsAction === false) {
      showNotification({
        error: true,
        message: translate(
          'settingSection.deputy.notification.enableEmployeeSync',
        ),
      });
      return;
    }
    setToggleTimeSheetsAction(prevCheck => !prevCheck);
  }, [showNotification, toggleTimeSheetsAction, toggleUsersAction, translate]);

  useEffect((): void => {
    if (updatedStoreWithDeputySettings && !error) {
      showNotification({
        success: true,
        message: translate('settingSection.deputy.notification.updatedAction'),
      });
    }
  }, [showNotification, updatedStoreWithDeputySettings, error, translate]);

  const onPressSave = useCallback(() => {
    const data = {
      syncOrders: toggleOrdersAction,
      syncUsers: toggleUsersAction,
      syncTimesheets: toggleTimeSheetsAction,
    } as unknown as DeputySettingsInput;
    updateStoreWithDeputySettings(data, store);
  }, [
    store,
    toggleOrdersAction,
    toggleTimeSheetsAction,
    toggleUsersAction,
    updateStoreWithDeputySettings,
  ]);
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollStyle)}>
          <BackOfficeSection
            title={translate('tabHeaders.deputy.availableActions')}
            contentContainerStyle={css(formStyle)}
            containerStyle={css(containerStyle)}
          >
            {(!connectedActions && (
              <Text
                style={css(noMenuesTextStyle)}
                testID={'empty-menues-message'}
              >
                {'No  location'}
              </Text>
            )) || (
              <>
                <View style={css()}>
                  <View
                    style={css(
                      toggleOrdersAction === true ? rowStyleActive : rowStyle,
                    )}
                  >
                    <Button
                      onPress={toggleButtonOrders}
                      testID={'buttonOrders'}
                      containerStyle={css(checkBoxTitleContainer)}
                      iconContainerStyle={css(checkContainer)}
                      icon={
                        toggleOrdersAction === true ? 'toggle-on' : 'toggle-off'
                      }
                      iconProps={{
                        color:
                          toggleOrdersAction === true
                            ? theme.colors.success
                            : theme.colors.paragraph,
                        size: 26,
                      }}
                    />
                    <View style={css(defaultContainerStyle)}>
                      <Text style={css(defaultTextStyle)}>
                        {connectedLocations.actions[0]}
                      </Text>
                    </View>
                    {toggleOrdersAction === true ? (
                      <IconButton
                        primary
                        icon={'Setting'}
                        iconSize={26}
                        containerSize={34}
                        iconColor={theme.colors.blue}
                        containerStyle={css(uploadContainerStyle)}
                        onPress={deputyModal.bind(null, location, true)}
                      />
                    ) : null}
                  </View>

                  <View
                    style={css(
                      toggleUsersAction === true ? rowStyleActive : rowStyle,
                    )}
                  >
                    <Button
                      onPress={toggleButtonUsers}
                      testID={'buttonUsers'}
                      containerStyle={css(checkBoxTitleContainer)}
                      iconContainerStyle={css(checkContainer)}
                      icon={
                        toggleUsersAction === true ? 'toggle-on' : 'toggle-off'
                      }
                      iconProps={{
                        color:
                          toggleUsersAction === true
                            ? theme.colors.success
                            : theme.colors.paragraph,
                        size: 26,
                      }}
                    />
                    <View style={css(defaultContainerStyle)}>
                      <Text style={css(defaultTextStyle)}>
                        {connectedLocations.actions[1]}
                      </Text>
                    </View>

                    {toggleUsersAction === true ? (
                      <IconButton
                        primary
                        icon={'Setting'}
                        iconSize={26}
                        containerSize={34}
                        iconColor={theme.colors.blue}
                        containerStyle={css(uploadContainerStyle)}
                        onPress={deputyModal.bind(null, location, false)}
                      />
                    ) : null}
                  </View>

                  <View
                    style={css(
                      toggleTimeSheetsAction === true
                        ? rowStyleActive
                        : rowStyle,
                    )}
                  >
                    <Button
                      onPress={toggleButtonTimeSheets}
                      testID={'buttonTimeSheets'}
                      containerStyle={css(checkBoxTitleContainer)}
                      iconContainerStyle={css(checkContainer)}
                      icon={
                        toggleTimeSheetsAction === true
                          ? 'toggle-on'
                          : 'toggle-off'
                      }
                      iconProps={{
                        color:
                          toggleTimeSheetsAction === true
                            ? theme.colors.success
                            : theme.colors.paragraph,
                        size: 26,
                      }}
                    />
                    <View style={css(defaultContainerStyle)}>
                      <Text style={css(defaultTextStyle)}>
                        {connectedLocations.actions[2]}
                      </Text>
                    </View>
                  </View>
                </View>
              </>
            )}
          </BackOfficeSection>
          <View style={css(bottomSpace)}></View>
        </ScrollView>
        <View style={css(mainStyle)}>
          <View style={css(actionsContainerStyle)}>
            <Button
              fluid
              testID="save-changes"
              title={translate('button.saveChanges')}
              containerStyle={css(saveButtonStyle)}
              labelStyle={css(titleStyle)}
              onPress={onPressSave}
              disabled={loading}
              loading={loading}
            />
          </View>
        </View>
      </View>
    </>
  );
};
