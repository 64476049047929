import React from 'react';
import { StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import {
  View,
  Text,
  TouchableOpacity,
  GestureResponderEvent,
  TextStyle,
  ViewStyle,
} from 'react-native';
import IconButton from '../Button/IconButton';
export interface SettingsItemProps {
  title: string;
  value?: string;
  icon?: string;
  iconColor?: string;
  testID?: string;
  onPress?: (event?: GestureResponderEvent) => void;
  iconStyle?: TextStyle;
  titleTextStyle?: TextStyle;
  valueTextStyle?: TextStyle;
  contentRight?: React.ReactNode;
  customContainerStyle?: ViewStyle;
}

const containerStyle: StyleFn = ({ theme }) => ({
  height: 44,
  marginTop: theme.spacing.small,
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.small,
  paddingLeft: theme.padding.large,
  justifyContent: 'center',
});

const componentStyle: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
});

const selectTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
});

const rightAlignStyle: StyleFn = ({ theme }) => ({
  marginLeft: 'auto',
  paddingRight: theme.spacing.small,
  flexDirection: 'row',
});

const valueStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
  color: theme.colors.paragraph,
  paddingTop: theme.spacing.small / 2,
  paddingRight: theme.spacing.small,
});

const containerAsOptionStyle: StyleFn = ({ theme }) => ({
  height: 44,
  margin: 4,
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.small,
  paddingLeft: theme.padding.large,
  justifyContent: 'center',
  ...theme.shadow30,
});

const SettingsItem: React.FC<SettingsItemProps> = ({
  testID,
  title,
  value,
  icon,
  onPress,
  iconStyle,
  titleTextStyle,
  valueTextStyle,
  contentRight,
  customContainerStyle,
  iconColor,
}) => {
  const { css } = useFela();

  const selectItemComponents = (
    <View testID={testID} style={css(componentStyle)}>
      <View testID="settings-item-title">
        <Text style={[css(selectTextStyle), titleTextStyle]}>{title}</Text>
      </View>
      <View testID="settings-item-value" style={css(rightAlignStyle)}>
        <Text style={[css(valueStyle), valueTextStyle]}>{value}</Text>
        {icon && (
          <IconButton
            testID="settings-item-icon"
            icon={icon}
            primary
            iconStyle={iconStyle}
            onPress={onPress}
            iconColor={iconColor}
          />
        )}
        {contentRight && contentRight}
      </View>
    </View>
  );

  return onPress ? (
    <TouchableOpacity
      style={[css(containerAsOptionStyle), customContainerStyle]}
      onPress={onPress}
    >
      {selectItemComponents}
    </TouchableOpacity>
  ) : (
    <View style={[css(containerStyle), customContainerStyle]}>
      {selectItemComponents}
    </View>
  );
};

export default SettingsItem;
