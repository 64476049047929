import React, { useMemo } from 'react';
import { View, Text } from 'react-native';
import Button from '../Button/Button';
import CartItems from '../Cart/CartItems';
import { CartHeader } from '../CartHeader/CartHeader';
import CartSummary from '../CartSummary/CartSummary';
import { useFela } from 'react-fela';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { useSession } from '../../../src/hooks/app/useSession';
import { getRoundOffValue } from '../../utils/roundOffHelper';
import { roundOffByValue } from '@hitz-group/client-utils';
import {
  Customer,
  Order,
  OrderPayment,
  StyleFn,
  OrderStatus,
  OrderPaymentStatus,
} from '@hitz-group/domain';
import * as styles from './CartSection.style';
import IconButton from '../Button/IconButton';

export interface IMap<T> {
  [key: string]: T;
}

interface ICartSection {
  refundAmount?: number;
  transactions?: IMap<number>;
  order: Order;
  onPressConfirm: () => void;
  showTransaction?: boolean | undefined;
  isCalledFromRefund?: boolean;
  customContainerStyle?: StyleFn;
  disableBtn?: boolean;
}
interface ICustomerSection {
  customer: Customer;
}

interface ITransactionsSection {
  transactions?: IMap<number>;
  isCalledFromRefund?: boolean;
  payments: OrderPayment[];
  totalPrice: number;
  isRefundOrder: boolean;
}

const CASH_PAYMENT_TYPE = 'cash';

const CustomerSection: React.FC<ICustomerSection> = ({ customer }) => {
  const { css, theme } = useFela();
  return (
    <View style={css(styles.customerSection)}>
      <IconButton
        icon="user-circle"
        iconColor={theme.colors.black}
        iconStyle={css(styles.iconStyle)}
      />
      <Text testID="customerName" style={css(styles.customerInfoTextStyle)}>
        {customer.firstName + ' ' + customer.lastName}
      </Text>
    </View>
  );
};

const TransactionsSection: React.FC<ITransactionsSection> = ({
  transactions,
  isCalledFromRefund,
  payments,
  totalPrice,
  isRefundOrder,
}) => {
  const { formatCurrency } = useCurrency();
  const { translate } = useTranslation();
  const { css } = useFela();
  const [session] = useSession();
  const enableRoundOff =
    session?.currentStore?.checkoutOptions?.enableRoundOff || false;
  const roundOffValue = getRoundOffValue(
    session?.currentStore?.checkoutOptions?.roundOffValue,
  );

  const transactionSummary = useMemo(
    () =>
      transactions &&
      Object.keys(transactions).map(paymentType => (
        <View key={paymentType} style={css(styles.textContainerStyle)}>
          <Text style={css(styles.transactionTypeStyle)}>{paymentType}</Text>
          <Text style={css(styles.transactionTypeStyle)}>
            {paymentType.toLowerCase() == CASH_PAYMENT_TYPE
              ? formatCurrency(
                  enableRoundOff
                    ? roundOffByValue(transactions[paymentType], roundOffValue)
                    : transactions[paymentType],
                )
              : formatCurrency(transactions[paymentType])}
          </Text>
        </View>
      )),
    [css, formatCurrency, transactions, enableRoundOff, roundOffValue],
  );
  return (
    <View style={css(styles.refundInfoContainerStyle)}>
      {!isCalledFromRefund && transactionSummary}
      <View style={css(styles.textContainerStyle)}>
        <Text testID="totalRemaining" style={css(styles.totalRemainingStyle)}>
          {!isCalledFromRefund
            ? translate('refundOrder.toRefund')
            : payments
                .filter(
                  payType =>
                    payType.status === undefined ||
                    payType.status === OrderPaymentStatus.COMPLETE,
                )
                .map(payType => payType.paymentType.name)
                .join(', ')}
        </Text>
        <Text
          testID="totalRemainingAmt"
          style={css(styles.totalRemainingAmtStyle)}
        >
          {isRefundOrder && '- '}
          {formatCurrency(Math.abs(totalPrice) || 0)}
        </Text>
      </View>
    </View>
  );
};

const RefundedSection: React.FC<ITransactionsSection> = ({
  isCalledFromRefund,
  totalPrice,
  isRefundOrder,
}) => {
  const { formatCurrency } = useCurrency();
  const { translate } = useTranslation();
  const { css } = useFela();

  return (
    <View style={css(styles.refundInfoCreditContainerStyle)}>
      {!isCalledFromRefund}
      <View style={css(styles.textContainerStyle)}>
        <Text testID="totalRefunded" style={css(styles.creditStyle)}>
          {translate('refundOrder.credit')}
        </Text>
        <Text testID="totalRefundedAmt" style={css(styles.creditStyle)}>
          {isRefundOrder && '- '}
          {formatCurrency(Math.abs(totalPrice) || 0)}
        </Text>
      </View>

      <View style={css(styles.textContainerStyle)}>
        <Text testID="totalRefunded" style={css(styles.totalRemainingStyle)}>
          {translate('refundOrder.totalRefunded')}
        </Text>
        <Text
          testID="totalRefundedAmt"
          style={css(styles.totalRefundedAmtStyle)}
        >
          {isRefundOrder && '- '}
          {formatCurrency(Math.abs(totalPrice) || 0)}
        </Text>
      </View>
    </View>
  );
};

const CartSection: React.FC<ICartSection> = ({
  refundAmount,
  transactions,
  order,
  onPressConfirm,
  isCalledFromRefund,
  customContainerStyle,
  disableBtn,
}) => {
  const { css } = useFela({
    success: (refundAmount && refundAmount <= 0) || isCalledFromRefund,
  });
  const { translate } = useTranslation();
  const refundedOrder = !!order.refundOf;
  return (
    <View style={css([styles.cartStyle, customContainerStyle])}>
      {!isCalledFromRefund ? (
        <CartHeader
          orderNumber={order?.orderNumber || ''}
          orderType={order?.orderType?.name}
          tableNumber={order?.table?.name || 'T'}
          sectionName={order?.table?.section?.name}
        />
      ) : null}

      {order.customer ? <CustomerSection customer={order.customer} /> : null}

      <CartItems
        items={order?.orderItems}
        isRefundOrder={!!order?.refundOf}
        containerStyle={css(styles.cartItemsStyle)}
      />
      <CartSummary
        taxes={order?.taxes}
        adjustments={order.adjustments || []}
        surchargeAmount={order?.surchargeAmount || 0}
        discounts={order?.discounts || []}
        subTotal={order?.subTotal || 0}
        totalDue={order?.totalPrice + (order?.roundingAmount || 0) || 0}
        discountAmount={order?.discountAmount || 0}
        isCalledFromRefund={isCalledFromRefund}
        isRefundOrder={!!order?.refundOf}
        deliveryFee={order?.deliveryFee}
        serviceCharge={order?.serviceCharge}
        tip={order?.tip}
      />
      <TransactionsSection
        transactions={transactions}
        payments={order.payments}
        totalPrice={order?.totalPrice + (order?.roundingAmount || 0)}
        isCalledFromRefund={isCalledFromRefund}
        isRefundOrder={!!order?.refundOf}
      />
      {order.status == OrderStatus.REFUNDED ? (
        <RefundedSection
          transactions={transactions}
          payments={order.payments}
          totalPrice={order?.totalPrice + (order?.roundingAmount || 0)}
          isCalledFromRefund={isCalledFromRefund}
          isRefundOrder={!!order?.refundOf}
        />
      ) : null}
      <Button
        testID={'confirm-cart-btn'}
        warning={!refundedOrder}
        secondary={refundedOrder}
        key={0}
        title={translate(
          refundedOrder ? 'refundOrder.printReceipt' : 'refundOrder.confirm',
        )}
        disabled={disableBtn}
        size="large"
        containerStyle={css(styles.refundOrderButtonContainer)}
        labelStyle={css(styles.refundOrderText)}
        onPress={onPressConfirm}
      />
    </View>
  );
};

export default CartSection;
