import React, { useState, useCallback, useEffect } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import {
  StyleFn,
  StyleFnProps,
  RenderProps,
  PaymentType,
  MoneyEventType,
  MoneyMovementReason,
  MoneyMovement,
} from '@hitz-group/domain';
import { useFela, FelaComponent } from 'react-fela';
import IconButton from '../Button/IconButton';
import { useDimensions } from '@react-native-community/hooks';
import TitleBar from '../TitleBar/TitleBar';
import PopupView from '../PopupView/PopupView';
import SelectBar from '../Button/SelectBar';
import DropDown from '../FormInput/DropDown';
import FormInput from '../FormInput/FormInput';
import Button from '../Button/Button';
import { useMutation, useQuery } from '@apollo/client/react/hooks';
import {
  CREATE_MONEY_MOVEMENT,
  GET_MONEY_MOVEMENTS_REASONS,
  SET_MONEYMOVEMENT_RECORD,
} from '../../graphql/manageMoney';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { useNotification } from '../../hooks/Notification';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import { usePaymentTypes } from '../../hooks/app/usePaymentTypes';
import scale from '../../common/theme';
import { usePrinting } from '../../hooks/PrintingProvider';
import { userUtility } from '../../state/userUtility';
import { ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER } from '../../types/Common';
interface PaymentTypeDisplay extends PaymentType {
  value: string;
  label: string;
}

enum FormError {
  INVALID_AMOUNT = 'moneyMovements.invalidAmountMessage',
  INVALID_PAYMENTTYPE = 'moneyMovements.invalidPaymentTypeMessage',
  INVALID_REASON = 'moneyMovements.invalidReasonMessage',
}

enum FormSuccess {
  MONEY_IN = 'Money In',
  MONEY_OUT = 'Money Out',
}
const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 15,
});
const selectBarContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  height: theme.input.height,
  margin: 1,
  flex: undefined,
  alignSelf: 'stretch',
  alignItems: 'center',
});
const selectBarLabelStyle: StyleFn = ({ theme }) => ({
  fontSize: 12,
  letterSpacing: -0.5,
  fontFamily: theme.font.medium,
});
const selectBarItemStyle: StyleFn = () => ({
  margin: 2,
});
const labelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: 15,
  letterSpacing: -0.5,
  fontFamily: theme.font.medium,
  paddingLeft: theme.padding.small + 2,
  paddingVertical: theme.padding.medium,
  textAlign: 'left',
});

const eventItemContainerStyle: StyleFn = () => ({
  alignSelf: 'stretch',
  marginTop: 15,
});
const selectOptionStyle: StyleFn = ({ theme, errorMessage }) => ({
  width: '100%',
  backgroundColor:
    errorMessage === FormError.INVALID_REASON
      ? theme.colors.danger2
      : theme.colors.white,

  justifyContent: 'space-between',
  height: 44,
});
export const amountDropdownViewStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  width: '100%',
  height: theme.input.height,
});
export const amountDropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: '70%',
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
});
export const amountExtraMainDropDownViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '70%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  alignItems: 'center',
});
export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: '100%',
  marginTop: scale.moderateScale(3),
});
export const angleDownIconStyle: StyleFn = () => ({
  right: 5,
});
export const dropDownStyle: StyleFn = ({ theme }) => ({
  borderWidth: 0,
  width: '100%',
  height: theme.input.height,
  backgroundColor: theme.colors.boxBorder,
  bottom: scale.moderateScale(5),
  borderRadius: 0,
  borderTopLeftRadius: scale.moderateScale(4),
  borderBottomLeftRadius: scale.moderateScale(4),
});
export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: '100%',
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});
export const extraMainDropDownViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '100%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});
const notesContainerStyle: StyleFn = () => ({
  width: '100%',
});
const dropDownInputContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: theme.input.height,
});
const dropDownInputTextStyle: StyleFn = () => ({
  width: '100%',
  letterSpacing: -0.5,
  right: 30,
});
const buttonContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing.big + 5,
  marginHorizontal: 0,
  marginBottom: 10,
});
const containerStyle: StyleFn = () => ({
  width: 350,
  alignSelf: 'center',
});
const loadingStyle: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

const popupStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.medium * 2.5,
  minHeight: 480,
});

const initialForm = {
  reason: '',
  paymentType: '',
  amount: '',
  notes: '',
  eventType: MoneyEventType.MONEY_IN,
};

const Loading: React.FC = () => (
  <FelaComponent style={loadingStyle}>
    {({ style, theme }: RenderProps): React.ReactNode => (
      <ActivityIndicator color={theme.colors.primary} style={style} />
    )}
  </FelaComponent>
);

const MoneyEventModal: React.FC = () => {
  const { css, theme } = useFela();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const { closeModal } = useModal();
  const [form, setForm] = useState({ ...initialForm });
  const { formatCurrency, currency } = useCurrency();
  const [setMoneyMovementRecord] = useMutation(SET_MONEYMOVEMENT_RECORD);

  const [moneyMovementEvent, moneyMovementEventStatus] = useMutation(
    CREATE_MONEY_MOVEMENT,
    {
      onError: noopHandler,
    },
  );
  const { paymentTypes, status } = usePaymentTypes();
  const { printMoneyMovementReceipt, openCashDrawer } = usePrinting();

  const { data: reasonsData, error: reasonsError } = useQuery(
    GET_MONEY_MOVEMENTS_REASONS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  useEffect(() => {
    if (paymentTypes) {
      const cashPaymentType = paymentTypes.find(
        x => (x as unknown as PaymentTypeDisplay).label === 'Cash',
      );
      setForm({
        ...initialForm,
        paymentType: (cashPaymentType as PaymentTypeDisplay)?.value || '',
      });
    }
  }, [paymentTypes]);

  useEffect(() => {
    if (moneyMovementEventStatus.error) {
      showNotification({
        error: true,
        message: parseApolloError(moneyMovementEventStatus.error),
      });
    }
  }, [moneyMovementEventStatus.error, showNotification]);

  useEffect(() => {
    if (moneyMovementEventStatus.data) {
      const moneyMovement = moneyMovementEventStatus.data
        .moveMoney as MoneyMovement;
      showNotification({
        success: true,
        message: `${
          FormSuccess[moneyMovement.eventType as MoneyEventType]
        } event added successfully`,
      });
      if (
        ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER.includes(
          moneyMovement.paymentType?.name?.toLowerCase(),
        )
      ) {
        openCashDrawer();
      }

      // trigger print
      printMoneyMovementReceipt(moneyMovement);

      setMoneyMovementRecord({
        variables: { data: moneyMovementEventStatus.data },
      });
      closeModal();
    }
  }, [
    moneyMovementEventStatus.data,
    showNotification,
    closeModal,
    setMoneyMovementRecord,
    printMoneyMovementReceipt,
    openCashDrawer,
  ]);

  useEffect(() => {
    if (status.error) {
      showNotification({
        error: true,
        message: status.error,
      });
    }
  }, [status.error, showNotification]);

  useEffect(() => {
    if (reasonsError) {
      showNotification({
        error: true,
        message: parseApolloError(reasonsError),
      });
    }
  }, [reasonsError, showNotification]);

  const onChangeForm = useCallback(
    (prop: string, value: string) => {
      setForm({
        ...form,
        [prop]: value,
      });
      if (errorMessage) {
        setErrorMessage('');
      }
    },
    [form, errorMessage],
  );

  useEffect(() => {
    if (errorMessage !== '') {
      showNotification({ error: true, message: translate(errorMessage) });
    }
  }, [errorMessage, showNotification, translate]);

  const saveMoneyEvent = useCallback(() => {
    if (!form.reason) {
      setErrorMessage(FormError.INVALID_REASON);
    } else if (isNaN(parseInt(form.amount)) || parseInt(form.amount) <= 0) {
      setErrorMessage(FormError.INVALID_AMOUNT);
    } else if (!form.paymentType) {
      setErrorMessage(FormError.INVALID_PAYMENTTYPE);
    } else {
      const finalFormData = {
        ...form,
        amount: { amount: +form.amount, currency },
        userId: userUtility.posUser?.id,
      };
      moneyMovementEvent({ variables: finalFormData });
      setErrorMessage('');
    }
  }, [form, currency, moneyMovementEvent]);

  const titleLeft = (
    <IconButton
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={closeModal}
      testID={'close-icon'}
    />
  );
  const height = useDimensions().window.height * 0.665;

  return (
    <View style={css(containerStyle({ height } as unknown as StyleFnProps))}>
      {paymentTypes && reasonsData ? (
        <>
          <TitleBar
            titleLeft={titleLeft}
            primary
            title={translate('moneyMovements.addMoneyEvent')}
            testID={'money-event-title'}
          />
          <PopupView containerStyle={css(popupStyle)}>
            <SelectBar
              containerStyle={css(selectBarContainerStyle)}
              itemLabelStyle={css(selectBarLabelStyle)}
              itemContainerStyle={css(selectBarItemStyle)}
              options={[
                {
                  label: translate('moneyMovements.moneyIn'),
                  value: MoneyEventType.MONEY_IN,
                },
                {
                  label: translate('moneyMovements.moneyOut'),
                  value: MoneyEventType.MONEY_OUT,
                },
              ]}
              selectedOption={form.eventType}
              onPress={onChangeForm.bind(null, 'eventType')}
            />
            <View style={css(eventItemContainerStyle)}>
              <Text style={css(labelStyle)}>
                {translate('moneyMovements.selectReason')}
              </Text>
              <DropDown
                testID={'moneyMovements-reason'}
                values={[
                  {
                    label: 'Select',
                    value: '',
                  },
                  ...(reasonsData?.moneyMovementReasons.filter(
                    (reason: MoneyMovementReason) =>
                      reason.eventType === form.eventType,
                  ) || []),
                ]}
                extraStyle={css(dropdownExtraStyle)}
                extraViewStyle={css(dropdownViewStyle)}
                extraMainViewStyle={css(extraMainDropDownViewStyle)}
                style={css(selectOptionStyle({ theme, errorMessage }))}
                onValueChange={onChangeForm.bind(null, 'reason')}
                selectedValue={form.reason}
              />
            </View>
            <View
              style={css(eventItemContainerStyle)}
              testID={'moneyMovement-label'}
            >
              <FormInput
                onChangeText={onChangeForm.bind(null, 'notes')}
                value={form.notes}
                title={translate('moneyMovements.addNotes')}
                placeholder={`${translate('moneyMovements.addNotes')}...`}
                containerStyle={css(notesContainerStyle)}
                alignTitle={'left'}
                testID={'moneyMovement-notes'}
              />
            </View>
            <View
              style={css(eventItemContainerStyle)}
              testID={'moneyMovement-amount'}
            >
              <FormInput
                testID="dropdownInput"
                error={
                  errorMessage === FormError.INVALID_PAYMENTTYPE ||
                  errorMessage === FormError.INVALID_AMOUNT
                }
                onSelectChange={onChangeForm.bind(null, 'paymentType')}
                selectedValue={form.paymentType}
                onChangeText={onChangeForm.bind(null, 'amount')}
                value={form.amount}
                title={translate('moneyMovements.amount')}
                type="DropDownInput"
                placeholder={formatCurrency(0)}
                containerStyle={css(dropDownInputContainerStyle)}
                textInputStyle={css(dropDownInputTextStyle)}
                dropdownList={[
                  {
                    label: 'Select',
                    value: '',
                  },
                  ...((paymentTypes as PaymentTypeDisplay[]) || []),
                ]}
                alignTitle={'left'}
                extraStyle={css(amountDropdownExtraStyle)}
                extraViewStyle={css(amountDropdownViewStyle)}
                extraMainViewStyle={css(amountExtraMainDropDownViewStyle)}
                dropDownStyle={css(dropDownStyle)}
                angleDownIconStyle={css(angleDownIconStyle)}
              />
            </View>
            <Button
              secondary
              size={'small'}
              onPress={saveMoneyEvent}
              loading={moneyMovementEventStatus.loading}
              title={
                form.eventType === MoneyEventType.MONEY_IN
                  ? translate('moneyMovements.saveMoneyIn')
                  : translate('moneyMovements.saveMoneyOut')
              }
              containerStyle={css(buttonContainerStyle)}
              testID={'add-moneyMovement-button'}
            />
          </PopupView>
        </>
      ) : (
        <Loading />
      )}
    </View>
  );
};

export default MoneyEventModal;
