import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';

export const ContainerStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          width: '100%',
          height: '100%',
          backgroundColor: theme.colors.white,
        },
      }),
    [theme],
  );
};

export const FilterInputStyles = (zIndex: number) => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        rowContainerStyle: {
          flex: 1,
          justifyContent: 'space-between',
          paddingLeft: theme.spacing.medium,
        },
        fieldViewStyle: {
          zIndex,
          height: rowHeight,
          flex: 1,
          fontSize: theme.fontSize.small,
          letterSpacing: -0.5,
          fontFamily: theme.font.regular,
          paddingLeft: 5,
          paddingRight: theme.spacing.small,
        },
        attributesDropDownStyle: {
          width: 220,
          minHeight: 38,
          alignSelf: 'center',
        },
        touchableStyle: {
          minHeight: 38,
          flexWrap: 'wrap',
        },
        placeHolderStyle: {
          marginLeft: 15,
        },
        ddTextStyle: {
          paddingBottom: 10,
        },
      }),
    [theme, zIndex],
  );
};

export const DateRangeFiltersStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flexDirection: 'row',
        },
        dateRangeType: {
          width: 180,
          fontSize: theme.fontSize.small,
        },
        primaryFilter: {
          width: 190,
          marginLeft: theme.spacing.medium,
        },
        secondaryFilter: {
          width: 170,
        },
        primaryFilterLabel: {
          width: '80%',
        },
        secondaryFilterLabel: {
          width: '80%',
        },
        primaryFilterLabelText: {
          paddingLeft: theme.spacing.medium,
          color: theme.colors.primaryLightest,
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.medium,
          lineHeight: 24,
          letterSpacing: 0,
        },
        secondaryFilterLabelText: {
          paddingLeft: theme.spacing.medium,
          color: theme.colors.primaryLightest,
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.medium,
          lineHeight: 24,
          letterSpacing: 0,
        },
        datePopoverStyle: {
          width: 175,
        },
        timePopoverStyle: {
          width: 120,
        },
        singlePopoverStyle: {
          width: 240,
        },
        touchableOpacityStyle: {
          minHeight: rowHeight + 2,
        },
        collapsedViewTextStyle: {
          justifyContent: 'center',
          fontSize: theme.fontSize.smaller,
          letterSpacing: -0.5,
          fontFamily: theme.font.regular,
          paddingHorizontal: theme.spacing.small,
        },
        startDate: {
          width: '80%',
          height: rowHeight,
          marginLeft: theme.spacing.medium,
        },
        startDateInput: {
          width: 50,
          height: rowHeight,
        },
        startDateText: {
          width: 50,
          height: rowHeight,
          paddingHorizontal: theme.spacing.small,
        },
        startTimeInput: {
          width: 50,
          height: rowHeight,
        },
        startTimeText: {
          width: 50,
          height: rowHeight,
          paddingHorizontal: theme.spacing.small,
        },
        endDate: {
          height: rowHeight,
          marginLeft: theme.spacing.medium,
          width: '80%',
          marginTop: theme.spacing.small,
        },
        endDateInput: {
          width: 50,
          height: rowHeight,
        },
        endDateText: {
          width: 50,
          height: rowHeight,
          paddingHorizontal: theme.spacing.small,
        },
        endTimeInput: {
          width: 50,
          height: rowHeight,
        },
        endTimeDropDown: {
          width: 55,
          height: rowHeight,
        },
        endTimeText: {
          width: 50,
          height: rowHeight,
          paddingHorizontal: theme.spacing.small,
        },
        startTime: {
          width: '80%',
          height: rowHeight,
          marginLeft: theme.spacing.medium,
        },
        endTime: {
          height: rowHeight,
          marginLeft: theme.spacing.medium,
          width: '80%',
          marginTop: theme.spacing.small,
        },
        fieldInputContainerStyle: {
          width: '100%',
          fontSize: theme.fontSize.smaller,
          height: rowHeight,
          marginTop: -theme.spacing.small,
        },
        itemsContainerStyle: {
          width: 178,
        },
        extraPopoverStyle: {
          width: 180,
        },
      }),
    [theme],
  );
};

export const TableStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        columnContainerStyle: {
          flex: 1,
          paddingLeft: theme.spacing.medium,
        },
        actionsContainerStyle: {
          height: 70,
          borderTopColor: theme.colors.boxBorder,
          backgroundColor: theme.colors.white,
          borderTopWidth: 1,
          flexDirection: 'row-reverse',
          paddingRight: theme.spacing.big,
        },
        actionContainerStyle: {
          maxWidth: 200,
          height: rowHeight,
          backgroundColor: theme.colors.white,
          paddingRight: theme.spacing.medium,
        },
        saveButtonStyle: {
          width: 200,
          height: rowHeight,
          marginRight: theme.spacing.medium,
          alignSelf: 'center',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
        downloadButtonStyle: {
          borderRadius: theme.radius.small,
          color: theme.colors.blue,
          backgroundColor: theme.colors.highlighted,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
          marginTop: 5,
        },
        downloadIconStyle: {
          color: theme.colors.blue,
          marginTop: 5,
        },
        angleButtonStyle: {
          borderRadius: 5,
        },
        angleIconStyle: {
          color: theme.colors.card,
        },
        statusIconStyle: {
          marginRight: 10,
        },
      }),
    [theme],
  );
};

export const FiltersStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
          borderRightWidth: 1,
          borderRightColor: theme.colors.paragraph,
          flexDirection: 'column',
          paddingBottom: theme.padding.medium,
          paddingTop: theme.padding.medium,
        },
        actionsContainerStyle: {
          height: 70,
          flexDirection: 'row-reverse',
          justifyContent: 'center',
        },
        saveButtonStyle: {
          width: 200,
          height: rowHeight,
          alignSelf: 'center',
          borderRadius: theme.radius.small,
          color: theme.colors.blue,
          backgroundColor: theme.colors.highlighted,
        },
        titleStyle: {
          color: theme.colors.blue,
          fontFamily: theme.font.semibold,
          fontSize: theme.fontSize.medium,
          lineHeight: 24,
          letterSpacing: -0.5,
        },
        headerStyle: {
          marginTop: 20,
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingLeft: theme.padding.small * 3,
          paddingRight: theme.padding.small * 3,
        },
        headerTextStyle: {
          fontSize: theme.fontSize.larger,
          fontFamily: theme.font.semibold,
          color: theme.colors.heading2,
          alignSelf: 'flex-start',
        },
        headerCloseIconStyle: {
          alignSelf: 'flex-end',
          alignItems: 'flex-end',
        },
        headerCloseIconStyleContainer: {
          backgroundColor: theme.colors.white,
          borderRadius: 100,
        },
        filterContainer: {
          paddingTop: theme.spacing.small,
        },
      }),
    [theme],
  );
};

export const Styles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
          flexDirection: 'row',
        },
        filtersStyle: {
          width: 265,
          flexDirection: 'column',
          height: 'calc(100vh - 135px)',
          position: 'absolute',
          zIndex: 1,
        },
        mainSectionStyle: {
          width: 1200,
          height: '100%',
          flexDirection: 'column',
          margin: 'auto',
        },
        headersStyle: {
          zIndex: 900,
          width: '100%',
          height: 78,
          alignSelf: 'flex-start',
        },
        chartRowStyle: {
          zIndex: 800,
          display: 'flex',
          flexGrow: 1,
          width: '100%',
          height: 200,
          flexDirection: 'row',
          alignSelf: 'flex-start',
        },
        tableStyle: {
          width: '100%',
          height: '100%',
          zIndex: 800,
        },
        chartTitleStyle: {
          textAlign: 'center',
          position: 'absolute',
          width: '100%',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          top: 10,
          fontSize: theme.fontSize.smallest,
          zIndex: -1,
        },
      }),
    [theme],
  );
};

export const PreviewStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        viewStyle: {
          paddingLeft: theme.spacing.medium,
          paddingRight: theme.spacing.medium,
          paddingBottom: theme.spacing.medium,
        },
        tableStyle: {
          paddingTop: theme.spacing.medium,
          paddingBottom: 15,
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.boxBorder,
        },
        firstTableStyle: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 15,
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.boxBorder,
        },
        rowStyle: {
          flex: 1,
          alignSelf: 'stretch',
          flexDirection: 'row',
          marginBottom: 5,
        },
        headerStyle: {
          fontWeight: '600',
        },
        cellStyle: {
          flex: 0.9,
          alignSelf: 'stretch',
          textAlign: 'left',
        },
        rightAlignCellStyle: {
          flex: 1.1,
          alignSelf: 'stretch',
          textAlign: 'right',
        },
        centerAlignCellStyle: {
          flex: 1,
          alignSelf: 'stretch',
          textAlign: 'center',
        },
        loaderViewStyle: {
          padding: theme.spacing.medium,
        },
      }),
    [theme],
  );
};

export const HeaderStyles = () => {
  const { theme } = useFela();
  const rowHeight = 38;

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flexDirection: 'column',
          paddingHorizontal: theme.spacing.big,
        },
        row1: {
          flex: 1,
          backgroundColor: theme.colors.white,
          width: '100%',
          flexDirection: 'row',
          zIndex: 950,
          alignContent: 'center',
          justifyContent: 'space-between',
          marginTop: theme.spacing.big / 2,
        },
        row2: {
          flex: 1,
          width: '100%',
          backgroundColor: theme.colors.white,
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'space-between',
          marginTop: theme.spacing.small,
        },
        venueFilterView: {
          width: 225,
          height: rowHeight,
        },
        dateRangeFilters: {
          width: 600,
          height: rowHeight,
        },
        iconStyle: {
          width: 38,
          alignSelf: 'center',
          backgroundColor: theme.colors.highlighted,
        },
        filtersButtonStyle: {
          borderRadius: theme.radius.small,
          color: theme.colors.blue,
          backgroundColor: theme.colors.highlighted,
        },
        columnsButtonStyle: {
          width: 38,
          height: 38,
          borderRadius: theme.radius.small,
          color: theme.colors.blue,
          backgroundColor: theme.colors.highlighted,
          justifyContent: 'center',
          alignItems: 'center',
        },
        filtersIconStyle: {
          color: theme.colors.blue,
        },
        columnsIconStyle: {
          color: theme.colors.blue,
        },
        updateTableStyle: {
          width: 180,
          alignSelf: 'center',
          backgroundColor: theme.colors.white,
        },
        updateContainerStyle: {
          backgroundColor: theme.colors.white,
          paddingRight: theme.spacing.big,
        },
        searchContainerStyle: {
          flex: 1,
          width: 950,
          flexDirection: 'row',
          backgroundColor: theme.colors.white,
          paddingRight: theme.spacing.big,
        },
        searchTypeStyle: {
          width: 150,
          height: rowHeight,
        },
        extraPopoverStyle: {
          width: 150,
        },
        searchStyle: {
          flexGrow: 1,
          height: rowHeight,
          marginLeft: theme.spacing.small,
        },
        searchTypeInputStyle: {
          height: rowHeight,
          width: 150,
        },
        searchTypeTouchableStyle: {
          marginTop: 0,
        },
        searchInputStyle: {
          width: '95%',
          height: rowHeight,
        },
        downloadContainerStyle: {
          backgroundColor: theme.colors.white,
          paddingRight: theme.spacing.big,
        },
        updateButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          alignSelf: 'center',
          borderRadius: theme.radius.small,
          color: theme.colors.success,
          backgroundColor: theme.colors.successLight,
        },
        downloadButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          alignSelf: 'center',
          borderRadius: theme.radius.small,
          color: theme.colors.blue,
          backgroundColor: theme.colors.highlighted,
        },
        titleStyle: {
          color: theme.colors.blue,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
      }),
    [theme],
  );
};
