import { useTranslation } from '@hitz-group/localization';
import { StyleFn } from '@hitz-group/domain';
import React, { useCallback, useEffect, useState } from 'react';
import DropDown from '../../../../../components/FormInput/DropDown';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import Button from '../../../../../components/Button/Button';
import IconButton from '../../../../../components/Button/IconButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import { useModal } from '@hitz-group/rn-use-modal';
import { SHIFT_INTERVALS_30_MIN } from '@hitz-group/client-utils';
import { useNotification } from '../../../../../hooks/Notification';
import {
  CreateTradingPeriodInput,
  TradingPeriod,
  DateTime,
} from '@hitz-group/domain';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { useReportSettings } from '../../../../../hooks/app/useReportSettings';
import { Helmet } from 'react-helmet';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import { pick } from 'lodash';
import scale, { isWeb } from '../../../../../common/theme';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const scrollStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});
const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const formTimeStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: isWeb ? '50%' : '100%',
  alignSelf: 'center',
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 240,
  height: theme.input.height,
  justifyContent: 'center',
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  marginLeft: theme.spacing.small,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

export const dropdownStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  justifyContent: 'space-between',
  width: isWeb ? '100%' : 260,
  height: theme.input.height,
  paddingLeft: theme.spacing.small,
});

export const timeDropdownStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  justifyContent: 'space-between',
  width: 100,
  height: theme.input.height,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
});
export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '90%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  marginLeft: theme.spacing.small,
  paddingLeft: theme.spacing.small,
});
export const dropdownViewStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  width: isWeb ? '45%' : '48%',
  marginTop: scale.moderateScale(2),
  paddingLeft: theme.spacing.small,
});
export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: scale.textInputWidth180,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  paddingLeft: theme.spacing.small,
});
export const timeDropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: 100,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});
export const timeDropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: 100,
});
export const timeDropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: 100,
  height: theme.input.height,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
});

export const arrowStyle: StyleFn = () => ({
  left: -15,
});

export const textStyle: StyleFn = () => ({
  left: 15,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

interface TradingPeriodRowProps {
  index: number;
  tradingPeriod: TradingPeriod;
  onChangeShiftRow: (
    index: number,
    id: string,
    prop: string,
    value: string,
  ) => void;
  onDeleteTradingPeriod: (index: number, id: string) => void;
}

const START_TIMES = SHIFT_INTERVALS_30_MIN;
const START_TIME_OPTIONS: Array<{
  label: string;
  value: string;
}> = generateComboOptions(START_TIMES);
const START_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const START_DAYS_OPTIONS: Array<{
  label: string;
  value: string;
}> = generateComboOptions(START_DAYS);
const START_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const START_MONTHS_OPTIONS: Array<{
  label: string;
  value: string;
}> = generateComboOptions(START_MONTHS);

function generateComboOptions(
  values: Array<string>,
): Array<{ label: string; value: string }> {
  return values.map(val => ({
    label: val,
    value: val,
  })) as Array<{ label: string; value: string }>;
}

const TradingPeriodRow: React.FC<TradingPeriodRowProps> = ({
  tradingPeriod,
  onDeleteTradingPeriod,
  onChangeShiftRow,
  index,
}: TradingPeriodRowProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  const { showModal, closeModal } = useModal();

  const onPressDelete = useCallback((): void => {
    if (tradingPeriod.id === undefined) {
      onDeleteTradingPeriod(index, tradingPeriod.id);
    } else {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: tradingPeriod.name,
          })}
          onConfirm={() => {
            onDeleteTradingPeriod(index, tradingPeriod.id);
            closeModal();
          }}
        />,
      );
    }
  }, [
    showModal,
    closeModal,
    onDeleteTradingPeriod,
    translate,
    tradingPeriod,
    index,
  ]);
  return (
    <View style={css(rowStyle)} testID="report-setting-row">
      <FormInput
        testID="report-shift-name"
        placeholder={translate('backOfficeReportSettings.tradingPeriodName')}
        value={tradingPeriod.name}
        containerStyle={css(formInputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChangeShiftRow.bind(
          null,
          index,
          tradingPeriod?.id,
          'name',
        )}
      />
      <View style={css(flexStyle)}></View>
      <DropDown
        testID="report-shift-startTime"
        values={START_TIME_OPTIONS || []}
        selectedValue={tradingPeriod?.startTime}
        onValueChange={onChangeShiftRow.bind(
          null,
          index,
          tradingPeriod?.id,
          'startTime',
        )}
        style={css(timeDropdownStyle)}
        extraMainViewStyle={css(timeDropDownMainViewStyle)}
        extraViewStyle={css(timeDropdownViewStyle)}
        extraStyle={css(timeDropdownExtraStyle)}
        textStyle={css(textStyle)}
        angleDownIconStyle={css(arrowStyle)}
      />
      <IconButton
        icon="TrashAlt"
        iconSize={24}
        containerSize={38}
        containerStyle={css(closeIconContainerStyle)}
        iconStyle={css(closeIconStyle)}
        onPress={onPressDelete}
      />
    </View>
  );
};

type FormState = Record<string, TradingPeriod & { isChanged: boolean }>;

export const ReportSettings: React.FC = () => {
  const { css } = useFela();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [tradingPeriodForm, setTradingPeriodForm] = useState<FormState>({});
  const [datesForm, setDatesForm] = useState({
    startTime: '00:00',
    startDay: 'Sunday',
    startMonth: 'January',
  });
  const [newTradingPeriods, setNewTradingPeriods] = useState<
    CreateTradingPeriodInput[]
  >([]);

  const [newDateTime, setNewDateTime] = useState<DateTime>();

  const [valueChanged, setComboValueChanged] = useState(false);

  const [deletedTradingPeriodId, setDeletedTradingPeriodId] =
    useState<string>('');

  const {
    loading,
    error,
    tradingPeriods,
    dateTime,
    getDateTime,
    updateDateTime,
    getTradingPeriods,
    createTradingPeriods,
    createdTradingPeriodIds,
    updateTradingPeriods,
    updatedTradingPeriodIds,
    deleteTradingPeriod,
    deletedTradingPeriod,
    updatedDateTime,
  } = useReportSettings();

  const { closeModal } = useModal();

  useEffect(() => {
    getDateTime();
  }, [getDateTime]);

  useEffect(() => {
    if (dateTime) {
      setDatesForm(dateTime);
      setNewDateTime(dateTime);
    }
  }, [dateTime, setNewDateTime]);

  useEffect(() => {
    getTradingPeriods();
  }, [getTradingPeriods]);

  useEffect(() => {
    if (tradingPeriods) {
      setTradingPeriodForm(tradingPeriods as FormState);
    }
  }, [tradingPeriods]);

  useEffect(() => {
    if (deletedTradingPeriod && deletedTradingPeriodId) {
      delete tradingPeriodForm[deletedTradingPeriodId];
    }
  }, [deletedTradingPeriodId, deletedTradingPeriod, tradingPeriodForm]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onDeleteTradingPeriod = useCallback(
    (index: number, id: string): void => {
      if (!id) {
        const currentTradingPeriodsCount =
          Object.keys(tradingPeriodForm).length;
        const updatedTradingPeriods = newTradingPeriods;
        updatedTradingPeriods.splice(index - currentTradingPeriodsCount, 1);
        setNewTradingPeriods([...updatedTradingPeriods]);
      } else {
        setDeletedTradingPeriodId(id);
        deleteTradingPeriod(id);
      }
    },
    [deleteTradingPeriod, newTradingPeriods, tradingPeriodForm],
  );

  useEffect((): void => {
    if (
      updatedDateTime &&
      valueChanged &&
      datesForm.startTime === newDateTime?.startTime &&
      datesForm.startDay === newDateTime?.startDay &&
      datesForm.startMonth === newDateTime?.startMonth
    ) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeReportSettings.dateTimeUpdatedSuccessfully',
        ),
      });
      setComboValueChanged(false);
    }
  }, [
    updatedDateTime,
    datesForm,
    newDateTime,
    valueChanged,
    showNotification,
    translate,
  ]);

  useEffect((): void => {
    if (deletedTradingPeriod) {
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficeReportSettings.tradingPeriodDeletedSuccessfully',
        ),
      });
    }
  }, [deletedTradingPeriod, showNotification, translate, closeModal]);

  useEffect((): void => {
    if (updatedTradingPeriodIds && updatedTradingPeriodIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            updatedTradingPeriodIds.length > 1
              ? 'backOfficeReportSettings.tradingPeriodsUpdatedSuccessfully'
              : 'backOfficeReportSettings.tradingPeriodUpdatedSuccessfully'
          }`,
        ),
      });
    }
  }, [updatedTradingPeriodIds, showNotification, translate]);

  useEffect((): void => {
    if (createdTradingPeriodIds && createdTradingPeriodIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            createdTradingPeriodIds.length > 1
              ? 'backOfficeReportSettings.tradingPeriodsCreatedSuccessfully'
              : 'backOfficeReportSettings.tradingPeriodCreatedSuccessfully'
          }`,
        ),
      });
      setNewTradingPeriods([]);
    }
  }, [
    createdTradingPeriodIds,
    showNotification,
    translate,
    setNewTradingPeriods,
  ]);

  const onChangeDateForm = useCallback(
    (prop: string, value: string) => {
      setDatesForm({
        ...datesForm,
        [prop]: value,
      });
      setNewDateTime({ ...datesForm });
      setComboValueChanged(true);
    },
    [datesForm, setNewDateTime, setComboValueChanged],
  );

  const onChangeShiftRow = useCallback(
    (index: number, id: string, prop: string, value: string): void => {
      if (!id) {
        const currentTradingPeriodsCount =
          Object.keys(tradingPeriodForm).length;
        const updatedTradingPeriods = newTradingPeriods;
        updatedTradingPeriods[index - currentTradingPeriodsCount] = {
          ...updatedTradingPeriods[index - currentTradingPeriodsCount],
          [prop]: value,
        };
        setNewTradingPeriods([...updatedTradingPeriods]);
      } else {
        setTradingPeriodForm(tradingPeriodForm => ({
          ...tradingPeriodForm,
          [id]: {
            ...tradingPeriodForm[id],
            [prop]: value,
            isChanged: true,
          },
        }));
      }
    },
    [tradingPeriodForm, newTradingPeriods],
  );

  const onPressSave = useCallback((): void => {
    const tradingPeriodsToUpdate = Object.values(tradingPeriodForm)
      .filter(tradingPeriod => tradingPeriod.isChanged)
      .map(tradingPeriod => pick(tradingPeriod, ['id', 'name', 'startTime']));

    if (newTradingPeriods.some(tradingPeriod => !tradingPeriod.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeReportSettings.enterTradingPeriodName'),
      });
    } else if (
      newTradingPeriods.some(tradingPeriod => !tradingPeriod.startTime)
    ) {
      showNotification({
        error: true,
        message: translate(
          'backOfficeReportSettings.enterTradingPeriodStartTimeName',
        ),
      });
    } else {
      if (newTradingPeriods.length > 0) createTradingPeriods(newTradingPeriods);
      if (tradingPeriodsToUpdate.length > 0)
        updateTradingPeriods(tradingPeriodsToUpdate);
    }
    const updatedDateTime = pick(datesForm, [
      'startTime',
      'startDay',
      'startMonth',
    ]);
    if (newDateTime) {
      updateDateTime(updatedDateTime as DateTime);
    }
  }, [
    createTradingPeriods,
    updateTradingPeriods,
    newTradingPeriods,
    newDateTime,
    showNotification,
    updateDateTime,
    translate,
    tradingPeriodForm,
    datesForm,
  ]);

  const onPressCreateNew = useCallback(() => {
    setNewTradingPeriods([
      ...newTradingPeriods,
      {} as CreateTradingPeriodInput,
    ]);
  }, [newTradingPeriods]);

  const tradingPeriodsData = [
    ...Object.values(tradingPeriodForm),
    ...(newTradingPeriods as unknown as TradingPeriod[]),
  ];
  const dateTimeData = datesForm as DateTime;
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ScrollView
              testID="general-settings-screen"
              contentContainerStyle={css(scrollStyle)}
            >
              <BackOfficeSection
                title={translate('backOfficeReportSettings.datesTime')}
                titleDescription={translate(
                  'backOfficeReportSettings.datesTimeDescription',
                )}
                contentContainerStyle={css(formStyle)}
                containerStyle={css(containerStyle)}
              >
                <DropDown
                  testID="report-dateTime-startTime"
                  title={translate('backOfficeReportSettings.startTimeTitle')}
                  values={START_TIME_OPTIONS || []}
                  selectedValue={dateTimeData?.startTime}
                  onValueChange={onChangeDateForm.bind(null, 'startTime')}
                  style={css(dropdownStyle)}
                  extraMainViewStyle={css(dropDownMainViewStyle)}
                  extraViewStyle={css(dropdownViewStyle)}
                  extraStyle={css(dropdownExtraStyle)}
                  textStyle={css(textStyle)}
                />
                <DropDown
                  testID="report-dateTime-startDay"
                  title={translate('backOfficeReportSettings.startDayTitle')}
                  values={START_DAYS_OPTIONS || []}
                  selectedValue={dateTimeData?.startDay}
                  onValueChange={onChangeDateForm.bind(null, 'startDay')}
                  style={css(dropdownStyle)}
                  extraMainViewStyle={css(dropDownMainViewStyle)}
                  extraViewStyle={css(dropdownViewStyle)}
                  extraStyle={css(dropdownExtraStyle)}
                  textStyle={css(textStyle)}
                />
                <DropDown
                  testID="report-dateTime-startMonth"
                  title={translate('backOfficeReportSettings.startMonthTitle')}
                  values={START_MONTHS_OPTIONS || []}
                  selectedValue={dateTimeData?.startMonth}
                  style={css(dropdownStyle)}
                  extraMainViewStyle={css(dropDownMainViewStyle)}
                  extraViewStyle={css(dropdownViewStyle)}
                  onValueChange={onChangeDateForm.bind(null, 'startMonth')}
                  extraStyle={css(dropdownExtraStyle)}
                  textStyle={css(textStyle)}
                />
              </BackOfficeSection>
              <BackOfficeSection
                contentContainerStyle={css(formTimeStyle)}
                containerStyle={css(containerStyle)}
                title={translate('backOfficeReportSettings.tradingPeriods')}
                titleDescription={translate(
                  'backOfficeReportSettings.tradingPeriodDescription',
                )}
                action={
                  <BackOfficeCreateNewButton onPress={onPressCreateNew} />
                }
              >
                <TableComponent
                  columns={[
                    {
                      title: translate(
                        'backOfficeReportSettings.tradingPeriodName',
                      ),
                      flex: 1,
                      containerStyle: { marginLeft: 15 },
                    },
                    {
                      title: translate('backOfficeReportSettings.startTime'),
                      width: 150,
                      alignItems: 'flex-start',
                    },
                  ]}
                  data={tradingPeriodsData}
                  columnContainerStyle={css(columnContainerStyle)}
                  normalRows
                  renderRow={(
                    item: TradingPeriod,
                    index: number,
                  ): React.ReactNode => (
                    <TradingPeriodRow
                      tradingPeriod={item}
                      key={index}
                      index={index}
                      onChangeShiftRow={onChangeShiftRow}
                      onDeleteTradingPeriod={onDeleteTradingPeriod}
                    />
                  )}
                />
              </BackOfficeSection>
              <View style={css(bottomSpace)}></View>
            </ScrollView>
            <View style={css(actionsContainerStyle)}>
              <Button
                fluid
                testID="save-changes"
                title={translate('button.saveChanges')}
                containerStyle={css(saveButtonStyle)}
                labelStyle={css(titleStyle)}
                onPress={onPressSave}
              />
            </View>
          </>
        )}
      </View>
    </>
  );
};
