import { GestureResponderEvent } from 'react-native';
import React from 'react';
import { StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useFela } from 'react-fela';
import Button from '../Button/Button';
export interface BackOfficeCreateNewButtonProps {
  onPress?: (event?: GestureResponderEvent) => void;
  buttonText?: string;
}

const containerStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'flex-start',
  height: theme.button.footerButtonHeight,
  backgroundColor: theme.colors.successLight,
});

const labelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.semibold,
  textAlign: 'center',
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successLight,
  paddingLeft: theme.padding.medium,
});

const BackOfficeCreateNewButton: React.FC<BackOfficeCreateNewButtonProps> = ({
  onPress,
  buttonText,
}: BackOfficeCreateNewButtonProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();

  return (
    <Button
      testID="create-new-button"
      title={translate(buttonText || 'button.createNew')}
      containerStyle={css(containerStyle)}
      labelStyle={css(labelStyle)}
      iconPosition="right"
      iconContainerStyle={css(iconContainerStyle)}
      icon="plus"
      iconProps={{
        color: theme.colors.success,
        size: 18,
        primary: true,
      }}
      onPress={onPress}
    />
  );
};

export default BackOfficeCreateNewButton;
