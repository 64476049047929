import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { App, ResourceOperation, Resource } from '@hitz-group/domain';
import usePOSUserRoles from './app/users/usePOSUserRoles';
import { filter, map, Subscription } from 'rxjs';
import {
  canPerformOn,
  getUserCumulativePermissions,
  PosUser,
  ResourceWithOperation,
  UserActivity,
  userUtility,
} from '../state/userUtility';
import useRolesContext from './app/users/useRolesContext';
import { useModal } from '@hitz-group/rn-use-modal';
import PermissionsModal from '../components/Modals/Users/PermissionsModal';
import { useTranslation } from '@hitz-group/localization';
import { useSession } from './app/useSession';

interface Props {
  children: ReactNode;
}

type ComputedUserPermissions = Partial<
  Record<Resource, ResourceOperation[] | boolean>
>;

export interface POSUserAuthorizationContextType {
  /**
   * Use this when wanted to verify the all permissions (from applicable roles) available for a user on a particular store.
   *
   * @param input - Array of individual resources with a set of expected operations to perform on respective resources
   *
   * @example refer to test cases, which covers most of scenarios
   */
  canI: (
    input: ResourceWithOperation[],
    options?: { prompt?: boolean },
  ) => boolean;
  canActiveUserAccessBackOffice: boolean;
  canActiveUserAccessPOSApp: boolean;
  canUserAccessBackOffice: (userId: string) => boolean;
  canUserAccessPOSApp: (userId: string) => boolean;
  activeUser?: PosUser;
  /**
   * Current active User permissions in device `store`
   * Note: This will have roleId filters in future
   */
  userPermissions: ComputedUserPermissions;
}

export const POSUserAuthorizationContext =
  createContext<POSUserAuthorizationContextType>(
    {} as POSUserAuthorizationContextType,
  );

const POSUserAuthorizationProvider: FC<Props> = ({ children }) => {
  const { getUserByUserId, canGivenUserAccess, fetchStoreUsers } =
    usePOSUserRoles();
  const [activeUser, setActivePOSUserInfo] = useState<PosUser>();
  const { rolesById, permissions } = useRolesContext();
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const [session] = useSession();

  const userPermissions = useMemo(() => {
    if (!activeUser?.id) {
      return {};
    }
    const posUser = getUserByUserId(activeUser?.id);
    if (!posUser) {
      return {};
    }

    const userRoles = posUser.roles;

    return getUserCumulativePermissions(
      permissions,
      rolesById,
      Object.values(userRoles),
    );
  }, [rolesById, activeUser, permissions, getUserByUserId]);

  /**
   * Whether the active user is allowed to access BACKOFFICE
   */
  const canActiveUserAccessBackOffice = useMemo(() => {
    if (activeUser?.id) {
      return canGivenUserAccess(App.BACKOFFICE, activeUser?.id);
    }
    return false;
  }, [canGivenUserAccess, activeUser?.id]);

  /**
   * Whether the active user is allowed to access POS
   */
  const canActiveUserAccessPOSApp = useMemo(() => {
    if (activeUser?.id) {
      return canGivenUserAccess(App.POS_APP, activeUser?.id);
    }
    return false;
  }, [canGivenUserAccess, activeUser?.id]);

  /**
   * Whether the given user is allowed to access BACKOFFICE
   */
  const canUserAccessBackOffice = useCallback(
    (userId: string) => {
      return canGivenUserAccess(App.BACKOFFICE, userId);
    },
    [canGivenUserAccess],
  );

  /**
   * Whether the given user is allowed to access POS
   */
  const canUserAccessPOSApp = useCallback(
    (userId: string) => {
      return canGivenUserAccess(App.POS_APP, userId);
    },
    [canGivenUserAccess],
  );

  const canI = useCallback(
    (input: ResourceWithOperation[], options = { prompt: false }) => {
      const result = canPerformOn(input, userPermissions);
      if (options.prompt) {
        if (!result) {
          const permissionsText = input
            .map(permission => {
              return translate(
                `authorization.resourcePermissions.${permission.onResource}`,
              );
            })
            .join(',');
          showModal(
            <PermissionsModal
              title={translate('authorization.notAuthorizedTitle')}
              message={translate('authorization.notAuthorizedMessage', {
                permissionsText,
              })}
            />,
          );
        }
      }
      return result;
    },
    [userPermissions, showModal, translate],
  );

  useEffect(() => {
    if (session?.currentStore?.id) {
      fetchStoreUsers(session.currentStore.id);
    }
  }, [session?.currentStore?.id, fetchStoreUsers]);

  useEffect(() => {
    const subscription: Subscription = userUtility.retrieveUserActivity$
      .pipe(
        map<UserActivity, PosUser>(activity => activity.posUser as PosUser),
        filter<PosUser>(user => typeof user !== 'undefined'),
      )
      .subscribe(data => {
        if (data) {
          setActivePOSUserInfo(data);
        }
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const value = useMemo(
    () => ({
      canI,
      canActiveUserAccessBackOffice,
      canActiveUserAccessPOSApp,
      canUserAccessBackOffice,
      canUserAccessPOSApp,
      activeUser,
      userPermissions,
    }),
    [
      canI,
      canActiveUserAccessBackOffice,
      canActiveUserAccessPOSApp,
      canUserAccessBackOffice,
      canUserAccessPOSApp,
      activeUser,
      userPermissions,
    ],
  );

  return (
    <POSUserAuthorizationContext.Provider value={value}>
      {children}
    </POSUserAuthorizationContext.Provider>
  );
};

export default POSUserAuthorizationProvider;

// TODO: improvements
// A `role` filter (or a shift based) only be added to this in future, nothing else is required
