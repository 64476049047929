import { StyleFn } from '@hitz-group/domain';

export const headingStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
  lineHeight: 20,
  letterSpacing: -0.4,
  width: '100%',
  textAlign: 'left',
  color: theme.colors.blue,
  fontWeight: 500,
  marginLeft: theme.spacing.small,
});

export const contentContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small,
});

export const actionContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: 30,
});

export const buttonContainerStyle: StyleFn = () => ({
  marginLeft: 10,
});

export const buttonStyle: StyleFn = ({ theme }) => ({
  ...theme.font14SemiBold,
  fontSize: 12,
  lineHeight: 21,
  letterSpacing: -0.4,
});

export const confirmButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
});

export const dismissButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
});

export const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 37,
  paddingBottom: theme.padding.medium / 2,
  marginRight: theme.spacing.small,
});

export const iconContainerStyle: StyleFn = () => ({
  width: 25,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
});

export const defaultContainerStyle: StyleFn = ({ theme }) => ({
  width: 330,
  height: theme.input.height,
  marginTop: theme.spacing.small / 2,
  borderRadius: theme.radius.small,
});

export const defaultTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.medium,
  marginTop: 10,
  ...theme.font14RegularCharcoal,
});
