import {
  Adjustment,
  AdjustmentType,
  AdjustmentUnit,
  CUSTOM_ADJUSTMENT_ID,
} from '@hitz-group/domain';

export function generateCustomFlatAdjustment(amount: number): Adjustment {
  let adjustmentType = AdjustmentType.SURCHARGE;
  let adjustmentNameSuffix = 'Surcharge';

  if (amount < 0) {
    adjustmentType = AdjustmentType.DISCOUNT;
    adjustmentNameSuffix = 'Discount';
  }

  return {
    id: CUSTOM_ADJUSTMENT_ID,
    name: `Payment ${adjustmentNameSuffix}`,
    adjustmentType,
    adjustmentUnit: AdjustmentUnit.FLAT,
    allowOnPaymentType: true,
    amount: amount,
  };
}
