import { useTranslation } from '@hitz-group/localization';
import { StyleFn, Icons } from '@hitz-group/domain';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import Button from '../../../../../components/Button/Button';
import IconButton from '../../../../../components/Button/IconButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import { useModal } from '@hitz-group/rn-use-modal';
import { useNotification } from '../../../../../hooks/Notification';
import { CreateRefundReasonInput, RefundReason } from '@hitz-group/domain/dist';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { useRefundReasons } from '../../../../../hooks/app/useRefundReasons';
import { Helmet } from 'react-helmet';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import { pick } from 'lodash';
import scale, { isWeb } from '../../../../../common/theme';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const scrollStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});
const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: isWeb ? '50%' : '100%',
  alignSelf: 'center',
});
const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});
const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
  paddingLeft: theme.padding.small,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});
export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const iconButtonContainerStyle: StyleFn = () => ({
  width: 80,
  alignItems: 'center',
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});
interface RefundReasonRowProps {
  index: number;
  refundReason: RefundReason;
  onChange: (
    index: number,
    id: string,
    toggleOn: boolean,
    value: string,
  ) => void;
  onDeleteRefundReason: (index: number, id: string) => void;
}

const RefundReasonRow: React.FC<RefundReasonRowProps> = ({
  refundReason,
  onDeleteRefundReason,
  onChange,
  index,
}: RefundReasonRowProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();

  const { showModal, closeModal } = useModal();

  const onPressDelete = useCallback((): void => {
    if (refundReason.id === undefined) {
      onDeleteRefundReason(index, refundReason.id);
    } else {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', {
            label: refundReason.name,
          })}
          onConfirm={() => {
            onDeleteRefundReason(index, refundReason.id);
            closeModal();
          }}
        />,
      );
    }
  }, [
    showModal,
    closeModal,
    onDeleteRefundReason,
    translate,
    refundReason,
    index,
  ]);
  const restockSelected = refundReason?.restock || false;
  return (
    <View style={css(rowStyle)} testID="refund-reason-row">
      <FormInput
        testID="refund-reason-name"
        placeholder={translate('backOfficeRefundReasons.refundReasonName')}
        value={refundReason.name}
        containerStyle={css(formInputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(
          null,
          index,
          refundReason?.id,
          restockSelected,
        )}
      />
      <View style={css(iconButtonContainerStyle)}>
        <IconButton
          testID="refund-reason-restock"
          iconColor={
            restockSelected ? theme.colors.success : theme.colors.boxBorder
          }
          onPress={() =>
            onChange(
              index,
              refundReason?.id,
              !restockSelected,
              refundReason.name,
            )
          }
          icon={restockSelected ? Icons.CheckSquare : Icons.SquareFull}
        />
      </View>

      <View style={css(flexStyle)}></View>
      <IconButton
        icon="TrashAlt"
        iconSize={24}
        containerSize={38}
        containerStyle={css(closeIconContainerStyle)}
        iconStyle={css(closeIconStyle)}
        onPress={onPressDelete}
      />
    </View>
  );
};

type FormState = Record<string, RefundReason & { isChanged: boolean }>;

export const RefundReasons: React.FC = () => {
  const { css } = useFela();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<FormState>({});
  const [newRefundReasons, setNewRefundReasons] = useState<
    CreateRefundReasonInput[]
  >([]);

  const [deletedRefundReasonId, setDeletedRefundReasonId] =
    useState<string>('');

  const {
    loading,
    error,
    refundReasons,
    getRefundReasons,
    createRefundReasons,
    createdRefundReasonIds,
    updateRefundReasons,
    updatedRefundReasonIds,
    deleteRefundReason,
    deletedRefundReason,
  } = useRefundReasons();

  const { closeModal } = useModal();

  useEffect(() => {
    getRefundReasons();
  }, [getRefundReasons]);

  useEffect(() => {
    if (refundReasons) {
      setForm(refundReasons as FormState);
    }
  }, [refundReasons]);

  useEffect(() => {
    if (deletedRefundReason && deletedRefundReasonId) {
      delete form[deletedRefundReasonId];
    }
  }, [deletedRefundReasonId, deletedRefundReason, form]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onDeleteRefundReason = useCallback(
    (index: number, id: string): void => {
      if (!id) {
        const currentRefundReasonsCount = Object.keys(form).length;
        const updatedRefundReasons = newRefundReasons;
        newRefundReasons.splice(index - currentRefundReasonsCount, 1);
        setNewRefundReasons([...updatedRefundReasons]);
      } else {
        setDeletedRefundReasonId(id);
        deleteRefundReason(id);
      }
    },
    [deleteRefundReason, newRefundReasons, form],
  );

  useEffect((): void => {
    if (deletedRefundReason) {
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficeRefundReasons.refundReasonDeletedSuccessfully',
        ),
      });
    }
  }, [deletedRefundReason, showNotification, translate, closeModal]);

  useEffect((): void => {
    if (updatedRefundReasonIds && updatedRefundReasonIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            updatedRefundReasonIds.length > 1
              ? 'backOfficeRefundReasons.refundReasonsUpdatedSuccessfully'
              : 'backOfficeRefundReasons.refundReasonUpdatedSuccessfully'
          }`,
        ),
      });
    }
  }, [updatedRefundReasonIds, showNotification, translate]);

  useEffect((): void => {
    if (createdRefundReasonIds && createdRefundReasonIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          `${
            createdRefundReasonIds.length > 1
              ? 'backOfficeRefundReasons.refundReasonsCreatedSuccessfully'
              : 'backOfficeRefundReasons.refundReasonCreatedSuccessfully'
          }`,
        ),
      });
      setNewRefundReasons([]);
    }
  }, [
    createdRefundReasonIds,
    showNotification,
    translate,
    setNewRefundReasons,
  ]);

  const onChangeReason = useCallback(
    (index: number, id: string, toggleOn: boolean, value: string): void => {
      if (!id) {
        const currentRefundReasonsCount = Object.keys(form).length;
        const updatedRefundReasons = newRefundReasons;
        updatedRefundReasons[index - currentRefundReasonsCount] = {
          name: value,
          restock: toggleOn,
        };
        setNewRefundReasons([...updatedRefundReasons]);
      } else {
        setForm(form => ({
          ...form,
          [id]: {
            ...form[id],
            name: value,
            restock: toggleOn,
            isChanged: true,
          },
        }));
      }
    },
    [form, newRefundReasons],
  );

  const onPressSave = useCallback((): void => {
    const refundReasonsToUpdate = Object.values(form)
      .filter(refundReason => refundReason.isChanged)
      .map(refundReason => pick(refundReason, ['id', 'name', 'restock']));

    if (
      newRefundReasons.some(
        refundReason => !(refundReason?.name || '').trim(),
      ) ||
      refundReasonsToUpdate.some(
        refundReason => !(refundReason?.name || '').trim(),
      )
    ) {
      showNotification({
        error: true,
        message: translate('backOfficeRefundReasons.enterRefundReasonName'),
      });
    } else {
      if (newRefundReasons.length > 0) createRefundReasons(newRefundReasons);
      if (refundReasonsToUpdate.length > 0)
        updateRefundReasons(refundReasonsToUpdate);
    }
  }, [
    createRefundReasons,
    updateRefundReasons,
    newRefundReasons,
    showNotification,
    translate,
    form,
  ]);

  const onPressCreateNew = useCallback(() => {
    setNewRefundReasons([...newRefundReasons, {} as CreateRefundReasonInput]);
  }, [newRefundReasons]);

  const refundReasonsData = [
    ...Object.values(form),
    ...(newRefundReasons as unknown as RefundReason[]),
  ];

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ScrollView
              testID="general-settings-screen"
              contentContainerStyle={css(scrollStyle)}
            >
              <BackOfficeSection
                title={translate('backOfficeRefundReasons.refundReasons')}
                titleDescription={translate(
                  'backOfficeRefundReasons.refundReasonsDescription',
                )}
                contentContainerStyle={css(formStyle)}
                containerStyle={css(containerStyle)}
                action={
                  <BackOfficeCreateNewButton onPress={onPressCreateNew} />
                }
              >
                <TableComponent
                  columns={[
                    {
                      title: translate(
                        'backOfficeRefundReasons.refundReasonName',
                      ),
                      width: 260,
                      containerStyle: { marginLeft: 15 },
                    },
                    {
                      title: 'Restock',
                      width: 55,
                      alignItems: 'flex-start',
                    },
                  ]}
                  data={refundReasonsData}
                  normalRows={true}
                  columnContainerStyle={css(columnContainerStyle)}
                  renderRow={(
                    item: RefundReason,
                    index: number,
                  ): React.ReactNode => (
                    <RefundReasonRow
                      refundReason={item}
                      key={index}
                      index={index}
                      onChange={onChangeReason}
                      onDeleteRefundReason={onDeleteRefundReason}
                    />
                  )}
                />
              </BackOfficeSection>
              <View style={css(bottomSpace)}></View>
            </ScrollView>
            <View style={css(actionsContainerStyle)}>
              <Button
                fluid
                testID="save-changes"
                title={translate('button.saveChanges')}
                containerStyle={css(saveButtonStyle)}
                labelStyle={css(titleStyle)}
                onPress={onPressSave}
              />
            </View>
          </>
        )}
      </View>
    </>
  );
};
export default RefundReasons;
