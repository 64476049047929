import {
  IntegrationApps,
  InvoiceExportStatus,
  PaymentType,
  Tax,
  AccountingIntegrationStorePreferences,
  IntegrationPartnerStoreConfiguration,
  FinancialAccount,
} from '@hitz-group/domain';
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { View, ScrollView, Text } from 'react-native';
import TableComponent from '../../../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import Button from '../../../../../../components/Button/Button';
import { useRoute } from '@react-navigation/native';
import DropDown from '../../../../../../components/FormInput/DropDown';
import FormInput from '../../../../../../components/FormInput/FormInput';
import * as styles from './StoreSettings.styles';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import { useAccountingIntegration } from '../../../../../../hooks/app/accountingIntegrations/useAccountingIntegration';
import { usePaymentTypes } from '../../../../../../hooks/app/usePaymentTypes';
import { useTaxes } from '../../../../../../hooks/app/useTaxes';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { stripProperties } from '../../../../../../utils/stripObjectProps';

export const StoreSettings: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();

  const route = useRoute();
  const params = route.params as {
    storeId: string;
    app: IntegrationApps;
  };

  const storeId = params.storeId;

  const {
    accountingIntegrationStoresSettings,
    getAccountingIntegrationStoresSettings,
    loading,
    chartOfAccounts,
    getChartOfAccounts,
    taxRates,
    getTaxRates,
    updateAccountingIntegrationStoreSettings,
  } = useAccountingIntegration();

  const { paymentTypes } = usePaymentTypes();
  const { taxes } = useTaxes();

  const [storeSettings, setStoreSettings] =
    useState<IntegrationPartnerStoreConfiguration>();

  useEffect(() => {
    if (accountingIntegrationStoresSettings?.[0])
      setStoreSettings(cloneDeep(accountingIntegrationStoresSettings?.[0]));
  }, [accountingIntegrationStoresSettings]);

  useEffect(() => {
    if (storeId) {
      getAccountingIntegrationStoresSettings([storeId], params.app);
    }
  }, [storeId, getAccountingIntegrationStoresSettings, params]);

  useEffect(() => {
    getChartOfAccounts();
  }, [getChartOfAccounts]);

  useEffect(() => {
    getTaxRates(params.app);
  }, [getTaxRates, params]);

  const saveAccountingIntegrationStoreSettings = useCallback(() => {
    if (storeSettings) {
      const updatedStoreSettings = stripProperties(
        { ...storeSettings },
        '__typename',
        'preferences.__typename',
      );
      updateAccountingIntegrationStoreSettings(updatedStoreSettings);
    }
  }, [storeSettings, updateAccountingIntegrationStoreSettings]);

  const refreshAccounts = useCallback(() => {
    getChartOfAccounts();
  }, [getChartOfAccounts]);

  const onChangeStorePreferences = useCallback(
    (prop: string, value: string) => {
      setStoreSettings(storeSettings => {
        if (storeSettings) {
          const defaultPreferences = {
            taxCodes: {},
            paymentTypes: {},
          } as AccountingIntegrationStorePreferences;
          const updatedStoreSettings = {
            ...storeSettings,
            preferences: {
              ...(storeSettings.preferences || defaultPreferences),
            },
          };
          set(updatedStoreSettings.preferences, prop, value);
          return updatedStoreSettings;
        }
      });
    },
    [],
  );

  const invoiceExportStatuses = useMemo(
    () => [
      {
        value: InvoiceExportStatus.Approved,
        label: translate('backOfficeFeatures.approved'),
      },
      {
        value: InvoiceExportStatus.Draft,
        label: translate('backOfficeFeatures.draft'),
      },
    ],
    [translate],
  );

  const mapAccountsToOptions = useCallback(
    (accounts: FinancialAccount[]) => {
      return [
        {
          label: translate('backOfficeFeatures.select'),
          value: '',
        },
        ...accounts.map(({ code, name, type }) => {
          return {
            label: translate('backOfficeFeatures.accountOption', {
              code,
              name,
              type,
            }),
            value: code,
          };
        }),
      ];
    },
    [translate],
  );

  const chartOfAccountsOptions = mapAccountsToOptions(chartOfAccounts);
  const paymentTypeChartOfAccountsOptions = mapAccountsToOptions(
    chartOfAccounts.filter(({ enablePaymentsToAccount, type }) => {
      // because accounts of type bank have enabledPayementsToAccount always set to false
      // because xero-node package cannot be resolved, so the property has to be converted to string for comparison
      return enablePaymentsToAccount || `${type}` == 'BANK';
    }),
  );

  const mapTaxRatesToOptions = useMemo(() => {
    return [
      {
        label: translate('backOfficeFeatures.select'),
        value: '',
      },
      ...taxRates.map(({ name, effectiveRate, taxType }) => {
        const label = `${name} (${effectiveRate})`;
        return {
          label,
          value: taxType,
        };
      }),
    ];
  }, [taxRates, translate]);

  if (loading) {
    return <LoadingIndicator />;
  }

  const storePreferences =
    storeSettings?.preferences as AccountingIntegrationStorePreferences;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.integrationsPage', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView
        testID="xero-settings-screen"
        contentContainerStyle={css(styles.xeroSettingsContainerWrapper)}
      >
        <View style={css(styles.pageStyle)}>
          <BackOfficeSection
            title={translate('backOfficeFeatures.invoicePrefixes')}
            contentContainerStyle={css(styles.xeroSettingsContentStyle)}
          >
            <View style={css(styles.innerContainer)}>
              <FormInput
                error={false}
                testID="invoice-prefix"
                title={translate('backOfficeFeatures.invoicePrefix')}
                value={storePreferences?.invoicePrefix?.toString()}
                alignTitle="left"
                containerStyle={css(styles.formInput)}
                textStyle={css(styles.formInputLabel)}
                onChangeText={value =>
                  onChangeStorePreferences('invoicePrefix', value)
                }
              />
              <DropDown
                testID="dropdown"
                values={invoiceExportStatuses}
                title={translate(
                  'backOfficeFeatures.reconciliationInvoiceStatus',
                )}
                selectedValue={storePreferences?.defaultInvoiceStatus}
                style={css(styles.accountsDropDown)}
                extraStyle={css(styles.accountsDropDownExtraStyle)}
                extraViewStyle={css(styles.extraViewStyle)}
                extraMainViewStyle={css(styles.accountsDropDownMainViewStyle)}
                onValueChange={value =>
                  onChangeStorePreferences('defaultInvoiceStatus', value)
                }
              />
            </View>
          </BackOfficeSection>

          <BackOfficeSection
            title={translate('backOfficeFeatures.defaultAccounts')}
            contentContainerStyle={css(styles.xeroSettingsContentStyle)}
          >
            <View style={css(styles.innerContainer)}>
              <DropDown
                testID="dropdown"
                values={chartOfAccountsOptions}
                title={translate('backOfficeFeatures.defaultIncomeAccount')}
                selectedValue={
                  storePreferences?.accountsMap?.defaultIncomeAccount
                }
                style={css(styles.accountsDropDown)}
                extraStyle={css(styles.accountsDropDownExtraStyle)}
                extraViewStyle={css(styles.extraViewStyle)}
                extraMainViewStyle={css(styles.accountsDropDownMainViewStyle)}
                onValueChange={value =>
                  onChangeStorePreferences(
                    'accountsMap.defaultIncomeAccount',
                    value,
                  )
                }
              />
              <DropDown
                testID="dropdown"
                values={chartOfAccountsOptions}
                title={translate('backOfficeFeatures.prePaymentAccount')}
                selectedValue={storePreferences?.accountsMap?.prePaymentAccount}
                style={css(styles.accountsDropDown)}
                extraStyle={css(styles.accountsDropDownExtraStyle)}
                extraViewStyle={css(styles.extraViewStyle)}
                extraMainViewStyle={css(styles.accountsDropDownMainViewStyle)}
                onValueChange={value =>
                  onChangeStorePreferences(
                    'accountsMap.prePaymentAccount',
                    value,
                  )
                }
              />
            </View>
            <View style={css(styles.innerContainer)}>
              <DropDown
                testID="dropdown"
                values={chartOfAccountsOptions}
                title={translate('backOfficeFeatures.varianceAccount')}
                selectedValue={storePreferences?.accountsMap?.varianceAccount}
                style={css(styles.accountsDropDown)}
                extraStyle={css(styles.accountsDropDownExtraStyle)}
                extraViewStyle={css(styles.extraViewStyle)}
                extraMainViewStyle={css(styles.accountsDropDownMainViewStyle)}
                onValueChange={value =>
                  onChangeStorePreferences('accountsMap.varianceAccount', value)
                }
              />
              <DropDown
                testID="dropdown"
                values={chartOfAccountsOptions}
                title={translate('backOfficeFeatures.moneyAccount')}
                selectedValue={storePreferences?.accountsMap?.moneyAccount}
                style={css(styles.accountsDropDown)}
                extraStyle={css(styles.accountsDropDownExtraStyle)}
                extraViewStyle={css(styles.extraViewStyle)}
                extraMainViewStyle={css(styles.accountsDropDownMainViewStyle)}
                onValueChange={value =>
                  onChangeStorePreferences('accountsMap.moneyAccount', value)
                }
              />
            </View>
          </BackOfficeSection>

          <BackOfficeSection
            title={translate('backOfficeFeatures.incomeTaxCodes')}
            contentContainerStyle={css(styles.xeroSettingsContentStyle)}
          >
            <TableComponent
              columns={[
                {
                  title: translate('hourlySales.Taxes'),
                  width: 200,
                  alignItems: 'flex-start',
                  containerStyle: { paddingLeft: 20 },
                },
                {
                  title: translate('backOfficeFeatures.xeroAccount'),
                  width: 330,
                  alignItems: 'flex-start',
                },
              ]}
              data={Object.values(taxes)}
              normalRows
              columnContainerStyle={css(styles.columnContainerStyle)}
              renderRow={({ id, name }: Tax): React.ReactNode => {
                return (
                  <View style={css(styles.taxesAndPaymentTypeRowContainer)}>
                    <View style={css(styles.taxAndPaymentTypeLabel)}>
                      <Text style={css(styles.pageRowTextStyle)}>{name}</Text>
                    </View>
                    <DropDown
                      testID="dropdown"
                      values={mapTaxRatesToOptions}
                      selectedValue={storePreferences?.taxCodes?.[id]}
                      style={css(styles.accountsDropdown)}
                      onValueChange={value =>
                        onChangeStorePreferences(`taxCodes[${id}]`, value)
                      }
                    />
                  </View>
                );
              }}
            />
          </BackOfficeSection>

          <BackOfficeSection
            title={translate('backOfficeSettings.paymentTypes')}
            contentContainerStyle={css(styles.xeroSettingsContentStyle)}
          >
            <TableComponent
              columns={[
                {
                  title: translate('orderHistory.paymentType'),
                  width: 200,
                  alignItems: 'flex-start',
                  containerStyle: { paddingLeft: 20 },
                },
                {
                  title: translate('backOfficeFeatures.xeroAccount'),
                  width: 330,
                  alignItems: 'flex-start',
                },
              ]}
              data={paymentTypes}
              normalRows
              columnContainerStyle={css(styles.columnContainerStyle)}
              renderRow={({ id, name }: PaymentType): React.ReactNode => {
                return (
                  <View style={css(styles.taxesAndPaymentTypeRowContainer)}>
                    <View style={css(styles.taxAndPaymentTypeLabel)}>
                      <Text style={css(styles.pageRowTextStyle)}>{name}</Text>
                    </View>
                    <DropDown
                      testID="dropdown"
                      values={paymentTypeChartOfAccountsOptions}
                      selectedValue={storePreferences?.paymentTypes?.[id]}
                      style={css(styles.accountsDropdown)}
                      onValueChange={value =>
                        onChangeStorePreferences(`paymentTypes[${id}]`, value)
                      }
                    />
                  </View>
                );
              }}
            />
          </BackOfficeSection>
        </View>
      </ScrollView>

      <View style={css(styles.actionsContainerStyle)}>
        <Button
          fluid
          testID="refresh-accounts"
          title={translate('backOfficeFeatures.refreshAccounts')}
          containerStyle={css(styles.refreshAccountsButtonStyle)}
          labelStyle={css(styles.refreshAccountsTextStyle)}
          onPress={refreshAccounts}
        />
        <Button
          fluid
          testID="save-changes"
          title={translate('button.saveChanges')}
          containerStyle={css(styles.saveButtonStyle)}
          labelStyle={css(styles.titleStyle)}
          onPress={saveAccountingIntegrationStoreSettings}
        />
      </View>
    </>
  );
};
