import {
  View,
  Text,
  ViewStyle,
  KeyboardAvoidingView,
  Platform,
  TouchableOpacity,
} from 'react-native';
import React from 'react';
import { StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import Icon from '../Icon/Icon';
export interface BackOfficeSectionProps {
  title?: string;
  titleDescription?: string;
  titleAction?: React.ReactNode;
  children?: React.ReactNode;
  action?: React.ReactNode;
  actionContainerStyle?: ViewStyle;
  contentContainerStyle?: ViewStyle;
  customTitleStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  titleBorderBottom?: boolean;
  backgroundColor?: string;
  icon?: string;
  iconColor?: string;
  titleContainerStyle?: ViewStyle;
  customTitleTextStyle?: ViewStyle;
  customTitleDescTextStyle?: ViewStyle;
}

const defaultContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'column',
  marginVertical: theme.spacing.medium,
});

const titleStyle: StyleFn = ({ theme, titleBorderBottom }) => ({
  borderRadius: theme.radius.small,
  borderBottomWidth: titleBorderBottom ? 1 : 0,
  borderBottomColor: titleBorderBottom ? theme.colors.boxBorder : undefined,
});

const colorStyle: StyleFn = ({ icon, backgroundColor, title, theme }) => ({
  backgroundColor: title
    ? backgroundColor || theme.colors.lightBlueHeader
    : 'transparent',
  paddingVertical: 10,
  paddingRight: theme.spacing.small,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: icon ? 'space-between' : 'flex-start',
});

const titleTextStyle: StyleFn = ({ theme }) => ({
  ...theme.backOfficeSectionTitle,
  paddingLeft: theme.spacing.small + theme.spacing.small / 2,
});

const iconStyle: StyleFn = () => ({
  marginHorizontal: 5,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const actionContainerDefaultStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small * 2.5,
});

const titleDescriptionTextStyle: StyleFn = ({ theme }) => ({
  ...theme.backOfficeSectionDescription,
  marginTop: theme.spacing.small,
  paddingLeft: theme.spacing.small + theme.spacing.small / 2,
});

const BackOfficeSection: React.FC<BackOfficeSectionProps> = ({
  title,
  titleDescription,
  titleAction,
  children,
  icon,
  iconColor,
  action,
  actionContainerStyle,
  contentContainerStyle,
  containerStyle,
  titleBorderBottom,
  customTitleStyle,
  backgroundColor,
  titleContainerStyle,
  customTitleTextStyle,
  customTitleDescTextStyle,
}: BackOfficeSectionProps) => {
  const { css, theme } = useFela();

  return (
    <View style={[css(defaultContainerStyle), containerStyle]}>
      {(!!title || titleAction) && (
        <View
          style={[
            css(titleStyle({ theme, titleBorderBottom })),
            customTitleStyle,
          ]}
        >
          <View
            style={[
              css(
                colorStyle({
                  icon,
                  theme,
                  titleBorderBottom,
                  title,
                  backgroundColor,
                }),
              ),
              titleContainerStyle,
            ]}
          >
            <View style={css(flexStyle)}>
              {!!title && (
                <Text style={[css(titleTextStyle), customTitleTextStyle]}>
                  {title}
                </Text>
              )}
              {!!titleDescription && (
                <Text
                  style={[
                    css(titleDescriptionTextStyle),
                    customTitleDescTextStyle,
                  ]}
                >
                  {titleDescription}
                </Text>
              )}
            </View>
            {icon && (
              <TouchableOpacity>
                <Icon
                  style={css(iconStyle)}
                  name={icon}
                  size={20}
                  color={iconColor}
                />
              </TouchableOpacity>
            )}
          </View>
          {titleAction}
        </View>
      )}
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        style={contentContainerStyle}
      >
        {children}
      </KeyboardAvoidingView>
      {action && (
        <View style={[css(actionContainerDefaultStyle), actionContainerStyle]}>
          {action}
        </View>
      )}
    </View>
  );
};

export default BackOfficeSection;
