import { gql } from '@apollo/client';

const modifierGroupQueryFragment = `
  id
  name
  kitchenPrintLabel
  selectionLimit {
    min
    max
  }
  modifiers{
    id
    name
    price {
      amount
      currency
    }
    priority
    isDefault
  }
  products {
    id
    name
    price {
      amount
      currency
    }
    priority
    isDefault
  }
`;

export const GET_MODIFIER_GROUP_QUERY = gql`
  query modifierGroupData($id: ID!) {
    modifierGroup(id: $id) {
      ${modifierGroupQueryFragment}
    }
  }
`;

export const GET_MODIFIER_GROUPS_QUERY = gql`
  query modifierGroupsData {
    modifierGroups {
      ${modifierGroupQueryFragment}
    }
  }
`;

export const CREATE_MODIFIER_GROUPS = gql`
  mutation createModifierGroups($input: [CreateModifierGroupInput!]!) {
    createModifierGroups(input: $input) {
      ${modifierGroupQueryFragment}
    }
  }
`;

export const UPDATE_MODIFIER_GROUPS = gql`
  mutation updateModifierGroups($input: [UpdateModifierGroupInput!]!) {
    updateModifierGroups(input: $input) {
      ${modifierGroupQueryFragment}
    }
  }
`;

export const DELETE_MODIFIER_GROUPS = gql`
  mutation deleteModifierGroups($ids: [ID!]!) {
    deleteModifierGroups(ids: $ids)
  }
`;

export const CLONE_MODIFIER_GROUP = gql`
mutation cloneModifierGroup($input: CloneModifierGroupInput!) {
  cloneModifierGroup(input: $input){
    ${modifierGroupQueryFragment}
  }
}
`;
