import React from 'react';
import { useFela } from 'react-fela';
import IconButton from '../../../../../components/Button/IconButton';
import { Text, View } from 'react-native';
import { StyleFn } from '@hitz-group/domain';
export interface SelectedItem {
  id: string;
  name: string;
  type: string;
  priority: number;
  entityType?: string;
}
interface ProductRowProps {
  rowIndex: number;
  item: SelectedItem;
  onDelete: (id: string) => void;
  drag?: () => void;
}

const innerContainerType: StyleFn = ({ theme }) => ({
  width: 75,
  backgroundColor: theme.colors.greyLight,
  padding: 7,
  marginLeft: 0,
  margin: theme.padding.small,
  alignItems: 'center',
  borderRadius: theme.radius.small,
});

const dragIconContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.big,
  marginLeft: theme.spacing.small,
  height: 28,
  width: 28,
  borderRadius: theme.radius.small,
  alignSelf: 'center',
});

const dragIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.darkGrey,
});

const innerContainerName: StyleFn = ({ theme }) => ({
  width: 260,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  padding: 7,
  margin: 5,
});

const textStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
  lineHeight: 21,
  letterSpacing: -0.5,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

export const ProductRow: React.FC<ProductRowProps> = ({
  item,
  onDelete,
  drag,
}) => {
  const { css } = useFela();
  return (
    <View style={css(rowStyle)}>
      <IconButton
        icon="draggabledots"
        iconSize={18}
        containerSize={28}
        containerStyle={css(dragIconContainer)}
        iconStyle={css(dragIconStyle)}
        onLongPress={drag}
      />
      <View style={css(innerContainerType)}>
        <Text style={css(textStyle)}>{item.type}</Text>
      </View>

      <View style={css(innerContainerName)}>
        <Text style={css(textStyle)}>{item.name}</Text>
      </View>

      <View style={css(flexStyle)} />

      <IconButton
        icon="TrashAlt"
        iconSize={24}
        containerSize={38}
        containerStyle={css(closeIconContainerStyle)}
        iconStyle={css(closeIconStyle)}
        onPress={() => onDelete(item.id)}
      />
    </View>
  );
};
