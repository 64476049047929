import { OrderTypeCode } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import { useNavigation } from '@react-navigation/native';
import { noopHandler } from '../../utils/errorHandlers';
import React, { useCallback, useMemo } from 'react';
import { useSession } from '../../hooks/app/useSession';
import { useCartContext } from '../../hooks/CartProvider';
import { useTimeout } from '../../hooks/useTimeout';
import { NewOrderButton as NewOrderIcon } from '../HeaderIcons/HeaderIcons';
import { DiscardChangesModal } from '../Modals/DiscardChanges/DiscardChanges';
import { PickerModal } from '../Modals/Picker/Picker';

export interface NewOrderButtonProps {
  isCartUnSaved?: boolean;
  onSave?: (callback?: Function) => void;
  hideDineIn?: boolean;
}

const NewOrderButton: React.FC<NewOrderButtonProps> = ({
  onSave,
  isCartUnSaved,
  hideDineIn,
}: NewOrderButtonProps) => {
  const navigation = useNavigation();
  const { resetCart, setCartParams } = useCartContext();
  const { showModal, closeModal } = useModal();
  const [{ deviceProfile }] = useSession();

  const { translate } = useTranslation();

  const nonDineInOrderTypes = useMemo(
    () =>
      deviceProfile?.orderTypes?.filter(
        orderType => orderType.code !== OrderTypeCode.DINE_IN,
      ) ?? [],
    [deviceProfile],
  );

  const dineInOrderTypes = useMemo(
    () =>
      deviceProfile?.orderTypes?.filter(
        orderType => orderType.code === OrderTypeCode.DINE_IN,
      ) ?? [],
    [deviceProfile],
  );

  const onCreateNewOrder = useCallback(
    async (orderTypeId: string) => {
      setCartParams(undefined, orderTypeId, '', false);
      const newOrderId = await resetCart();
      closeModal();
      navigation.navigate('Orders', {
        screen: 'TakeOrder',
        params: {
          id: newOrderId,
          isExisting: false,
          orderType: orderTypeId,
        },
      });
    },
    [setCartParams, resetCart, closeModal, navigation],
  );

  const setOrderType = useCallback(() => {
    let orderTypes = deviceProfile?.orderTypes || [];
    if (hideDineIn) orderTypes = nonDineInOrderTypes;
    if (orderTypes.length === 1) {
      onCreateNewOrder(orderTypes[0].id);
    } else if (orderTypes.length === 0 && dineInOrderTypes.length > 0) {
      onCreateNewOrder(dineInOrderTypes[0].id);
    } else {
      showModal(
        <PickerModal
          onSelect={onCreateNewOrder}
          onClose={noopHandler}
          title={translate('order.changeOrderType')}
          options={orderTypes.map((x: { name: string; id: string }) => ({
            label: x.name,
            value: x.id,
          }))}
        />,
      );
    }
  }, [
    deviceProfile?.orderTypes,
    hideDineIn,
    nonDineInOrderTypes,
    dineInOrderTypes,
    onCreateNewOrder,
    showModal,
    translate,
  ]);

  const onPressSave = useCallback(() => {
    if (onSave) {
      onSave(setOrderType);
    } else {
      setOrderType();
    }
  }, [onSave, setOrderType]);

  const setOrderTypeAfterDelay = useTimeout(setOrderType);

  const onDiscardOrder = useCallback(async () => {
    setOrderTypeAfterDelay.start(350);
  }, [setOrderTypeAfterDelay]);

  const initiateNewOrder = useCallback(() => {
    if (isCartUnSaved) {
      showModal(
        <DiscardChangesModal
          onSave={onPressSave}
          onClose={onDiscardOrder}
          title={translate('order.discardChangeTitle')}
          subTitle={translate('order.discardChangeDescription')}
        />,
      );
    } else {
      setOrderType();
    }
  }, [
    isCartUnSaved,
    translate,
    showModal,
    setOrderType,
    onPressSave,
    onDiscardOrder,
  ]);

  return <NewOrderIcon onPress={initiateNewOrder} />;
};

export default NewOrderButton;
