import React, { useCallback, useState } from 'react';
import { CreateVenueInput, Venue } from '@hitz-group/domain';
import FormInput from '../../FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../Button/Button';
import { useNotification } from '../../../hooks/Notification';
import { VenueFormStyles } from './styles';
import DropDown from '../../FormInput/DropDown';

interface Props {
  onCreate: (input: CreateVenueInput) => void;
  venues: Venue[];
}
export const VenueForm: React.FC<Props> = ({ onCreate, venues }) => {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState({} as CreateVenueInput);
  const styles = VenueFormStyles();

  const onChange = useCallback((prop: string, value: string) => {
    if (prop === 'name') {
      setForm(form => ({
        ...form,
        [prop]: value,
      }));
    } else {
      setForm(form => ({
        ...form,
        copyFrom: {
          ...form.copyFrom,
          [prop]: value,
        },
      }));
    }
  }, []);

  const onSubmit = useCallback(() => {
    if (!form.name) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.VenueNameIsRequired'),
      });
    } else {
      onCreate({ ...form, isActive: false });
    }
  }, [form, onCreate, translate, showNotification]);

  return (
    <>
      <FormInput
        title={translate('form.name')}
        placeholder={translate('form.name')}
        value={form.name}
        containerStyle={styles.formInputStyle}
        textStyle={styles.formTextStyle}
        onChangeText={onChange.bind(null, 'name')}
        maxLength={50}
      />

      <DropDown
        title={translate('backOfficeSettings.copySettingsFrom')}
        values={venues.map(value => ({
          value: value.id,
          label: value.name,
        }))}
        selectedValue={form.copyFrom?.settings}
        testID="rate-type"
        style={styles.formInputStyle}
        extraMainViewStyle={styles.dropDownMainViewStyle}
        extraViewStyle={styles.dropdownViewStyle}
        extraStyle={styles.dropdownExtraStyle}
        onValueChange={onChange.bind(null, 'settings')}
      />

      <DropDown
        title={translate('backOfficeSettings.copyProductsFrom')}
        values={venues.map(value => ({
          value: value.id,
          label: value.name,
        }))}
        selectedValue={form.copyFrom?.products}
        testID="rate-type"
        style={styles.formInputStyle}
        extraMainViewStyle={styles.dropDownMainViewStyle}
        extraViewStyle={styles.dropdownViewStyle}
        extraStyle={styles.dropdownExtraStyle}
        onValueChange={onChange.bind(null, 'products')}
      />

      <Button
        title={translate('backOfficeSettings.createVenue')}
        containerStyle={styles.buttonContainerStyle}
        labelStyle={styles.labelStyle}
        onPress={onSubmit}
      />
    </>
  );
};
