import { useTranslation } from '@hitz-group/localization';
import { StyleFn, Terminal, TerminalDiagnosisInfo } from '@hitz-group/domain';
import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import SettingsItem from '../SettingsItem/SettingsItem';
import SettingsSegment from '../SettingsSegment/SettingsSegment';
import Logo from '../../components/Logo/Logo';
import Spinner from '../../components/Spinner/Spinner';
import { useModal } from '@hitz-group/rn-use-modal';
import ConnectPaymentTerminal from '../Modals/PaymentTerminals/ConnectPaymentTerminal';
import { useSession } from '../../hooks/app/useSession';
import { useTerminals } from '../../hooks/app/useTerminals';
import { useNotification } from '../../hooks/Notification';
import Button from '../../components/Button/Button';
import IconButton from '../../components/Button/IconButton';
import CustomerDisplayModal from '../Modals/CustomerDisplayModal/CustomerDisplay';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import TerminalDiagnosis from '../Modals/PaymentTerminals/TerminalDiagnosis';
import { useCustomerDisplay } from '../../hooks/app/useCustomerDisplay';

const logoStyle: StyleFn = () => ({
  width: 54,
  height: 54,
  alignSelf: 'center',
});

const textStyle: StyleFn = ({ theme }) => ({
  color: 'grey',
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  textAlign: 'center',
  lineHeight: 24,
  width: 500,
  marginTop: theme.spacing.big,
  alignSelf: 'center',
});

const customContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small,
  justifyContent: 'center',
  alignItems: 'center',
});

const titleTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});

const dangerTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  width: 60,
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
  alignSelf: 'center',
  marginRight: -theme.spacing.small,
});

const buttonTextStyle: StyleFn = ({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.colors.danger,
  fontSize: theme.fontSize.medium,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big / 2,
  height: 44,
});

const diagnoseIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  transform: [{ rotate: '45deg' }],
});

const loadingContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: 150,
  height: 50,
  overflow: 'hidden',
  borderRadius: theme.radius.small,
});

const DEFAULT_TERMINAL_DATA = {
  id: '',
  uuid: '',
  name: 'Not Assigned',
} as Terminal;

const SettingsHardwareTab: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showModal } = useModal();
  const [session] = useSession();
  const { showNotification } = useNotification();
  const { createCdsOnDevice, forgetCdsOnDevice } = useCustomerDisplay();
  const {
    error,
    terminal,
    loading,
    diagnosisResponse,
    deleteTerminal,
    diagnoseTerminal,
  } = useTerminals();
  const hostDiagnosisCalledRef = useRef<boolean>(false);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (terminal) {
      showNotification({
        success: true,
        message: translate('paymentTerminal.deletedSuccessfully'),
      });
    }
  }, [terminal, showNotification, translate]);

  const paymentTerminal = useMemo(() => {
    return session.device?.paymentTerminal || DEFAULT_TERMINAL_DATA;
  }, [session.device?.paymentTerminal]);

  const onPressConnect = useCallback(() => {
    showModal(<ConnectPaymentTerminal />);
  }, [showModal]);

  const onPressDisconnect = useCallback(() => {
    deleteTerminal(paymentTerminal.id);
  }, [deleteTerminal, paymentTerminal.id]);

  const onCustomerDisplay = useCallback(async () => {
    if (session.device && !session.device?.customerDisplay) {
      await createCdsOnDevice({
        deviceId: session.device?.id as string,
        name: 'default',
      });
    }
    showModal(<CustomerDisplayModal />);
  }, [session.device, showModal, createCdsOnDevice]);

  const onForgetCustomerDisplay = useCallback(async () => {
    await forgetCdsOnDevice({
      deviceId: session.device?.id as string,
    });
    showNotification({
      message: translate('settings.forgetCustomerDisplaySuccess'),
      success: true,
    });
  }, [session.device, showNotification, forgetCdsOnDevice, translate]);

  const diagnoseTerminalFn = useCallback(() => {
    hostDiagnosisCalledRef.current = false;
    diagnoseTerminal({
      deviceId: session.device?.deviceCode as string,
      terminalId: paymentTerminal.uuid,
    });
  }, [diagnoseTerminal, paymentTerminal, session.device?.deviceCode]);

  useEffect(() => {
    if (diagnosisResponse) {
      if (diagnosisResponse.success) {
        showModal(
          <TerminalDiagnosis
            diagnosisData={
              diagnosisResponse.additionalInfo as TerminalDiagnosisInfo
            }
          />,
        );

        if (
          diagnosisResponse.additionalInfo &&
          diagnosisResponse.additionalInfo?.unconfirmedBatchCount > 0 &&
          !hostDiagnosisCalledRef.current
        ) {
          diagnoseTerminal({
            deviceId: session.device?.deviceCode as string,
            terminalId: paymentTerminal.uuid,
            hostDiagnosis: true,
          });

          hostDiagnosisCalledRef.current = true;
        }
      } else {
        showNotification({
          error: true,
          message: diagnosisResponse.message,
        });
      }
    }
  }, [
    diagnoseTerminal,
    diagnosisResponse,
    paymentTerminal.uuid,
    session.device?.deviceCode,
    showModal,
    showNotification,
  ]);

  if (loading) {
    return (
      <View style={css(loadingContainerStyle)} testID="diagnosis-loader-view">
        <LoadingIndicator size={'large'} testID={'diagnosis-loader'} />
      </View>
    );
  }

  return (
    <View>
      <SettingsSegment
        testID="paymentTerminalsSegment"
        title={translate('settings.paymentTerminal')}
      >
        {paymentTerminal.uuid ? (
          <SettingsItem
            testID="paymentTerminalInfo"
            title={`${paymentTerminal.name} (${paymentTerminal.uuid})`}
            icon={'wifi'}
            iconStyle={css(diagnoseIconStyle)}
            onPress={diagnoseTerminalFn}
          />
        ) : (
          <SettingsItem
            testID="paymentTerminalInfo"
            title={`${paymentTerminal.name}`}
          />
        )}
        {!paymentTerminal.id ? (
          <SettingsItem
            testID="connectPaymentTerminal"
            title={translate('settings.connectPaymentTerminal')}
            customContainerStyle={css(customContainerStyle)}
            titleTextStyle={css(titleTextStyle)}
            onPress={onPressConnect}
          />
        ) : (
          <SettingsItem
            testID="forgetPaymentTerminal"
            title={translate('settings.forgetPaymentTerminal')}
            customContainerStyle={css(customContainerStyle)}
            titleTextStyle={css(dangerTextStyle)}
            onPress={onPressDisconnect}
          />
        )}
      </SettingsSegment>
      <SettingsSegment title={translate('settings.customerDisplay')}>
        <SettingsItem
          testID="customerDisplay"
          title={
            !session.device?.customerDisplay
              ? translate('settings.connectCustomerDisplay')
              : translate('settings.customerDisplay')
          }
          onPress={(): void => {
            onCustomerDisplay();
          }}
          customContainerStyle={
            !session.device?.customerDisplay && css(customContainerStyle)
          }
          titleTextStyle={
            !session.device?.customerDisplay && css(titleTextStyle)
          }
          contentRight={
            !!session.device?.customerDisplay && (
              <IconButton
                primary
                icon={'check'}
                iconSize={26}
                iconColor={theme.colors.green}
                containerSize={34}
                containerStyle={css(iconContainerStyle)}
              />
            )
          }
        />
        {!!session.device?.customerDisplay && (
          <Button
            testID="forget_button"
            title={translate('settings.forgetDisplay')}
            fluid
            onPress={onForgetCustomerDisplay}
            containerStyle={css(buttonStyle)}
            labelStyle={css(buttonTextStyle)}
          />
        )}
      </SettingsSegment>
      <Text style={css(textStyle)}>{translate('settings.from')}</Text>
      <Logo style={css(logoStyle)} testID="App-Logo" />
      <Spinner isLoading={false} testID="Spinner" />
    </View>
  );
};

export default SettingsHardwareTab;
