import React, { FC } from 'react';
import { PrinterProfileType, StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import TableRow from '../../../../../components/TableComponent/TableRow';
import DropDown from '../../../../../components/DropDown/DropDown';
import usePrinterOptionRowStyles from './printerOptionRow.styles';
import { CreateOrUpdatePrintingOptions } from '@hitz-group/domain';

const rowStyle: StyleFn = ({ theme, index }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.spacing.small / 2,
  zIndex: 1000 - 10 * index,
});

export interface Props {
  preference: PreferencesByPrinterProfile;
  printerOptions: Array<{ value: string; label: string }>;
  printerTemplateOptions: Array<{ value: string; label: string }>;
  printerProfileType: PrinterProfileType;
  onChange: (
    attr: 'printer' | 'template',
    preference: PreferencesByPrinterProfile,
    printerOrTemplateId: string,
  ) => void;
  index: number;
}

export type PreferencesByPrinterProfile = Partial<
  CreateOrUpdatePrintingOptions & { name: string }
>;

const PrintingSetupRow: FC<Props> = ({
  preference,
  printerOptions,
  printerTemplateOptions,
  onChange,
  index,
}) => {
  const { css, theme } = useFela();
  const styles = usePrinterOptionRowStyles();

  return (
    <View style={css(rowStyle({ theme, index }))}>
      <TableRow normalRows={true} containerStyle={styles.rowContainerStyle}>
        <View style={styles.nameViewStyle}>
          <Text testID="profile-name" style={theme.font14RegularCharcoal}>
            {preference.name}
          </Text>
        </View>

        <View style={styles.printerTypeViewStyle}>
          <DropDown
            btnTestId="printers"
            options={printerOptions}
            selectedValue={preference.printer}
            extraPopoverStyle={styles.popOverStyle}
            containerStyle={styles.fieldInputContainerStyle}
            onValueChange={onChange.bind(undefined, 'printer', preference)}
            itemsContainerStyle={styles.itemContainer}
            PopoverViewPlacement={'top'}
          />
        </View>

        <View style={styles.printerTypeViewStyle}>
          <DropDown
            btnTestId="templates"
            options={printerTemplateOptions}
            selectedValue={preference.template}
            extraPopoverStyle={styles.popOverStyle}
            containerStyle={styles.fieldInputContainerStyle}
            onValueChange={onChange.bind(undefined, 'template', preference)}
            itemsContainerStyle={styles.itemContainer}
            PopoverViewPlacement={'top'}
          />
        </View>
      </TableRow>
    </View>
  );
};

export default PrintingSetupRow;
