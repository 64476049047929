import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { View, ScrollView } from 'react-native';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@hitz-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import { StyleFn } from '@hitz-group/domain';
import {
  DeviceProfile,
  UpdateDeviceProfileWorkflowInput,
  AppScreen,
} from '@hitz-group/domain';
import DropDown from '../../../../../components/DropDown/DropDown';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import { pick } from 'lodash';
import { useDeviceProfiles } from '../../../../../hooks/app/useDeviceProfiles';
import { useNotification } from '../../../../../hooks/Notification';
import { DeviceProfileSettingsFooter } from './DeviceProfileSettingsFooter';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';

const removeUnderScores = (str: string): string => {
  return str.replace('_', ' ');
};

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
});

const formContainerStyle: StyleFn = ({ theme }) => ({
  width: 545,
  flexDirection: 'row',
  justifyContent: 'space-between',
  zIndex: 5,
  marginBottom: theme.spacing.big,
  height: 65,
  marginTop: theme.spacing.medium,
});

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  width: 545,
  marginTop: theme.spacing.big + theme.spacing.medium,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: 260,
  marginRight: theme.spacing.medium + theme.spacing.small / 2,
});
const extraPopoverStyle: StyleFn = () => ({
  width: 260,
});
const dropDownItemsContainerStyle: StyleFn = () => ({
  height: 38 * 3,
});
const titleStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

export const Workflow: React.FC = () => {
  const { css, theme } = useFela();
  const route = useRoute();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState({} as DeviceProfile);

  const params = route.params as {
    deviceProfileId: string;
    storeId: string;
  };

  const deviceProfileId = params.deviceProfileId || '';
  const storeId = params.storeId || '';

  const {
    deviceProfiles,
    loading,
    error,
    updatedDeviceProfileId,
    unAssignedDeviceProfileId,
    deletedDeviceProfile,
    updateDeviceProfileWorkflow,
    unAssignDeviceProfile,
    deleteDeviceProfile,
  } = useDeviceProfiles({ deviceProfileId, storeId });

  useEffect(() => {
    if (deviceProfiles[deviceProfileId]) {
      setForm({
        ...deviceProfiles[deviceProfileId],
      });
    }
  }, [deviceProfiles, setForm, deviceProfileId]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedDeviceProfileId, showNotification, translate]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onPressSave = useCallback((): void => {
    const updateParams: UpdateDeviceProfileWorkflowInput = pick(form, [
      'id',
      'defaultSaleScreen',
      'postSaleScreen',
    ]);

    updateDeviceProfileWorkflow(updateParams);
  }, [form, updateDeviceProfileWorkflow]);

  useEffect(() => {
    if (deletedDeviceProfile) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: deviceProfiles[deviceProfileId]?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: deviceProfiles[deviceProfileId]?.store?.id,
        screen: 'DeviceProfiles',
      });
    }
  }, [
    deletedDeviceProfile,
    navigation,
    deviceProfiles,
    showNotification,
    translate,
    deviceProfileId,
  ]);

  useEffect(() => {
    if (unAssignedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.registerUnassignedSuccessfully'),
      });
    }
  }, [showNotification, translate, unAssignedDeviceProfileId]);

  if (loading) return <LoadingIndicator />;

  const orderCompletionScreen = Object.values(AppScreen).filter(
    (screen: AppScreen) => {
      const excludeScreensArray = [AppScreen.OPEN_ORDER];
      if (!form.enableOnlineOrders) {
        excludeScreensArray.push(AppScreen.ONLINE_ORDER);
      }
      if (!form.enableFloorView) {
        excludeScreensArray.push(AppScreen.FLOOR_VIEW);
      }
      return !excludeScreensArray.includes(screen);
    },
  );
  const defaultSalesScreen = Object.values(AppScreen).filter(
    (screen: AppScreen) => {
      const excludeScreensArray = [AppScreen.LOGIN, AppScreen.ORDER_HISTORY];
      if (!form.enableOnlineOrders) {
        excludeScreensArray.push(AppScreen.ONLINE_ORDER);
      }
      if (!form.enableFloorView) {
        excludeScreensArray.push(AppScreen.FLOOR_VIEW);
      }
      return !excludeScreensArray.includes(screen);
    },
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView
          testID="deviceProfile-settings-screen"
          contentContainerStyle={css(generalSettingsContainerWrapper)}
        >
          <BackOfficeSection
            title={translate('backOfficeSettings.registerWorkflow')}
            contentContainerStyle={css(formContainerStyle)}
            containerStyle={css(backOfficeContainerStyle)}
            iconColor={theme.colors.blue}
            icon={'info-circle'}
          >
            <DropDown
              title={translate('form.screenAfterOrderPlaced')}
              options={orderCompletionScreen.map(screen => ({
                label: removeUnderScores(screen),
                value: screen,
              }))}
              selectedValue={form.postSaleScreen}
              containerStyle={css(dropDownStyle)}
              itemsContainerStyle={css(dropDownItemsContainerStyle)}
              onValueChange={onChange.bind(null, 'postSaleScreen')}
              titleStyle={css(titleStyle)}
              extraPopoverStyle={css(extraPopoverStyle)}
            />

            <DropDown
              title={translate('form.defaultSalesScreen')}
              options={defaultSalesScreen.map(screen => ({
                label: removeUnderScores(screen),
                value: screen,
              }))}
              selectedValue={form.defaultSaleScreen}
              containerStyle={css(dropDownStyle)}
              itemsContainerStyle={css(dropDownItemsContainerStyle)}
              onValueChange={onChange.bind(null, 'defaultSaleScreen')}
              titleStyle={css(titleStyle)}
              extraPopoverStyle={css(extraPopoverStyle)}
            />
          </BackOfficeSection>
        </ScrollView>
        <DeviceProfileSettingsFooter
          deleteDeviceProfile={deleteDeviceProfile}
          unAssignDeviceProfile={unAssignDeviceProfile}
          onPressSave={onPressSave}
          deviceProfileId={deviceProfileId}
          deviceProfiles={deviceProfiles}
          status={form.status}
        />
      </View>
    </>
  );
};
