import React, { useCallback, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { DineInSettings, Icons } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import ModalDialog from '../../../../../../../../components/Modals/Modal';
import Button from '../../../../../../../../components/Button/Button';
import IconButton from '../../../../../../../../components/Button/IconButton';
import { useModal } from '@hitz-group/rn-use-modal';
import { useFela } from 'react-fela';
import * as styles from './Modal.styles';
import { useNotification } from '../../../../../../../../hooks/Notification';

interface DineInModalProps {
  dineInSettings?: DineInSettings;
  onCancel?: () => void;
  onConfirm?: (settingName: string, settings: DineInSettings) => void;
}

const DineInModal = ({
  dineInSettings,
  onCancel,
  onConfirm,
}: DineInModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [dineInData, setDineInData] = useState<DineInSettings>(
    dineInSettings as DineInSettings,
  );

  const errorValidation = useCallback(() => {
    return (
      (dineInData &&
        !dineInData.enablePayLater &&
        !dineInData.enablePayNow &&
        translate('backOfficeFeatures.noAllowedPayment')) ||
      undefined
    );
  }, [dineInData, translate]);

  const onPressCancel = useCallback(() => {
    closeModal();
    onCancel && onCancel();
  }, [onCancel, closeModal]);

  const onPressConfirm = useCallback(() => {
    const error = errorValidation();
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
      return;
    }
    closeModal();
    dineInData && onConfirm && onConfirm('dineInSettings', dineInData);
  }, [closeModal, dineInData, errorValidation, onConfirm, showNotification]);

  const onValueUpdate = useCallback((key: string, value: unknown) => {
    setDineInData(settingData => ({ ...settingData, [key]: value }));
  }, []);

  useEffect(() => {
    dineInSettings && setDineInData(dineInSettings);
  }, [dineInSettings]);

  return (
    <ModalDialog onDismiss={onPressCancel} showCloseButton={false}>
      <Text style={css(styles.headingStyle)} testID={'dineInModal-title'}>
        {translate('backOfficeFeatures.orderTypesSettings', {
          orderType: translate('backOfficeFeatures.dineIn'),
        })}
      </Text>
      <View style={css(styles.contentContainerStyle)}>
        <View style={css(styles.rowStyle)}>
          <IconButton
            primary
            testID={'dineInModal-payLater'}
            icon={
              (dineInData?.enablePayLater && Icons.ToggleOn) || Icons.ToggleOff
            }
            iconSize={26}
            containerSize={34}
            iconColor={
              dineInData?.enablePayLater
                ? theme.colors.green
                : theme.colors.paragraph
            }
            containerStyle={css(styles.iconContainerStyle)}
            onPress={() =>
              onValueUpdate('enablePayLater', !dineInData?.enablePayLater)
            }
          />

          <View style={css(styles.defaultContainerStyle)}>
            <Text style={css(styles.defaultTextStyle)}>
              {translate('backOfficeFeatures.allowPayLater')}
            </Text>
          </View>
        </View>
        <View style={css(styles.rowStyle)}>
          <IconButton
            primary
            testID={'dineInModal-payNow'}
            icon={
              (dineInData?.enablePayNow && Icons.ToggleOn) || Icons.ToggleOff
            }
            iconSize={26}
            containerSize={34}
            iconColor={
              dineInData?.enablePayNow
                ? theme.colors.green
                : theme.colors.paragraph
            }
            containerStyle={css(styles.iconContainerStyle)}
            onPress={() =>
              onValueUpdate('enablePayNow', !dineInData?.enablePayNow)
            }
          />

          <View style={css(styles.defaultContainerStyle)}>
            <Text style={css(styles.defaultTextStyle)}>
              {translate('backOfficeFeatures.allowPayNow')}
            </Text>
          </View>
        </View>
      </View>
      <View style={css(styles.actionContainerStyle)}>
        <Button
          size="small"
          testID="cancel-button"
          title={translate('settings.cancel').toUpperCase()}
          onPress={onPressCancel}
          labelStyle={css(styles.buttonStyle, styles.dismissButtonStyle)}
          containerStyle={css(styles.buttonContainerStyle)}
          color={theme.colors.white}
        />
        <Button
          size="small"
          testID="confirm-button"
          title={translate('settings.confirm').toUpperCase()}
          onPress={onPressConfirm}
          labelStyle={css(styles.buttonStyle, styles.confirmButtonStyle)}
          containerStyle={css(styles.buttonContainerStyle)}
          color={theme.colors.blue}
        />
      </View>
    </ModalDialog>
  );
};

export default DineInModal;
