import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';
import {
  GET_ACCOUNTING_INTEGRATION_STORES_SETTINGS,
  GET_ACCOUNTING_PARTNER_CONSENT_URL,
  OAUTH_CALLBACK,
  TOGGLE_ACCOUNTING_INTEGRATION_STORE_CONNECTION,
  GET_CHART_OF_ACCOUNTS,
  GET_TAX_RATES,
  UPDATE_ACCOUNTING_INTEGRATION_STORES_SETTINGS,
  DISCONNECT_ACCOUNTING_PARTNER,
} from './graphql';
import {
  IntegrationApps,
  IntegrationPartner,
  IntegrationPartnerStoreConfiguration,
  FinancialAccount,
  TaxRate,
} from '@hitz-group/domain';

export interface UseAccountingIntegration {
  loading: boolean;
  error?: string;
  accountingPartnerConsentUrl?: string;
  getAccountingPartnerConsentUrl: () => void;
  integrationData?: IntegrationPartner;
  saveIntegrationDetails: (url: string) => void;
  accountingIntegrationStoresSettings: Array<IntegrationPartnerStoreConfiguration>;
  getAccountingIntegrationStoresSettings: (
    storeIds: Array<string>,
    integrationPartner: IntegrationApps,
  ) => void;
  toggleAccountingIntegrationStoreConnection: (
    storeId: string,
    integrationPartner: IntegrationApps,
  ) => void;
  chartOfAccounts: Array<FinancialAccount>;
  getChartOfAccounts: () => void;
  taxRates: Array<TaxRate>;
  getTaxRates: (integrationPartner: IntegrationApps) => void;
  updateAccountingIntegrationStoreSettings: (
    accountingIntegrationStoreSettings: IntegrationPartnerStoreConfiguration,
  ) => void;
  disconnectAccountingIntegration: (accountingPartner: IntegrationApps) => void;
}

export function useAccountingIntegration(): UseAccountingIntegration {
  const [accountingPartnerConsentUrl, setAccountingPartnerConsentUrl] =
    useState<string | undefined>();

  const [integrationData, setIntegrationData] = useState<
    IntegrationPartner | undefined
  >();

  const [
    accountingIntegrationStoresSettings,
    setAccountingIntegrationStoresSettings,
  ] = useState<Array<IntegrationPartnerStoreConfiguration>>([]);

  const [chartOfAccounts, setChartOfAccounts] = useState<
    Array<FinancialAccount>
  >([]);

  const [taxRates, setTaxRates] = useState<Array<TaxRate>>([]);

  const onRetrieve = useCallback(
    data => {
      if (data && data.getAccountingPartnerConsentUrl) {
        setAccountingPartnerConsentUrl(data.getAccountingPartnerConsentUrl);
      }
    },
    [setAccountingPartnerConsentUrl],
  );

  const onFetchAccountingIntegrationStoresSettings = useCallback(
    data => {
      if (data && data.getAccountingIntegrationStoresSettings) {
        setAccountingIntegrationStoresSettings(
          data.getAccountingIntegrationStoresSettings,
        );
      }
    },
    [setAccountingIntegrationStoresSettings],
  );

  const [
    getAccountingPartnerConsentUrl,
    getAccountingPartnerConsentUrlResponse,
  ] = useLazyQuery(GET_ACCOUNTING_PARTNER_CONSENT_URL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onRetrieve,
  });

  const [
    getAccountingIntegrationStoresSettingsRequest,
    getAccountingIntegrationStoresSettingsResponse,
  ] = useLazyQuery(GET_ACCOUNTING_INTEGRATION_STORES_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onFetchAccountingIntegrationStoresSettings,
  });

  const getAccountingIntegrationStoresSettings = useCallback(
    (storeIds: string[], integrationPartner: IntegrationApps) => {
      getAccountingIntegrationStoresSettingsRequest({
        variables: {
          storeIds,
          integrationPartner,
        },
      });
    },
    [getAccountingIntegrationStoresSettingsRequest],
  );

  const [saveIntegrationDetailsRequest, saveIntegrationDetailsResponse] =
    useMutation(OAUTH_CALLBACK, {
      onError: noopHandler,
    });

  const onToggleAccountingIntegrationStoreConnection = useCallback(
    data => {
      if (data?.toggleAccountingIntegrationStoreConnection) {
        let updatedAccountingIntegrationStoresSettings = [
          ...accountingIntegrationStoresSettings,
        ];
        const hasStoreSettings = accountingIntegrationStoresSettings.find(
          ({ store }) =>
            store === data.toggleAccountingIntegrationStoreConnection.store,
        );
        if (!hasStoreSettings) {
          updatedAccountingIntegrationStoresSettings.push(
            data.toggleAccountingIntegrationStoreConnection,
          );
        } else {
          updatedAccountingIntegrationStoresSettings =
            updatedAccountingIntegrationStoresSettings.map(storeSettings => {
              return storeSettings.store ===
                data.toggleAccountingIntegrationStoreConnection.store
                ? data.toggleAccountingIntegrationStoreConnection
                : storeSettings;
            });
        }
        setAccountingIntegrationStoresSettings(
          updatedAccountingIntegrationStoresSettings,
        );
      }
    },
    [
      accountingIntegrationStoresSettings,
      setAccountingIntegrationStoresSettings,
    ],
  );

  const [
    toggleAccountingIntegrationStoreConnectionRequest,
    toggleAccountingIntegrationStoreConnectionResponse,
  ] = useMutation(TOGGLE_ACCOUNTING_INTEGRATION_STORE_CONNECTION, {
    onError: noopHandler,
    onCompleted: onToggleAccountingIntegrationStoreConnection,
  });

  const onFetchChartOfAccounts = useCallback(
    data => {
      if (data?.getChartOfAccounts) {
        setChartOfAccounts(data.getChartOfAccounts);
      }
    },
    [setChartOfAccounts],
  );

  const [getChartOfAccounts, getChartOfAccountsResponse] = useLazyQuery(
    GET_CHART_OF_ACCOUNTS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onFetchChartOfAccounts,
    },
  );

  const onFetchTaxRates = useCallback(
    data => {
      if (data?.getTaxRates) {
        setTaxRates(data.getTaxRates);
      }
    },
    [setTaxRates],
  );

  const [getTaxRatesRequest, getTaxRatesResponse] = useLazyQuery(
    GET_TAX_RATES,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onFetchTaxRates,
    },
  );

  const getTaxRates = useCallback(
    (integrationPartner: IntegrationApps) => {
      getTaxRatesRequest({
        variables: {
          integrationPartner,
        },
      });
    },
    [getTaxRatesRequest],
  );

  const toggleAccountingIntegrationStoreConnection = useCallback(
    (storeId: string, integrationPartner: IntegrationApps) => {
      toggleAccountingIntegrationStoreConnectionRequest({
        variables: {
          storeId,
          integrationPartner,
        },
      });
    },
    [toggleAccountingIntegrationStoreConnectionRequest],
  );

  const onDisconnectAccountingIntegration = useCallback(
    data => {
      if (data && data.disconnectAccountingIntegration) {
        setIntegrationData({
          ...(integrationData || ({} as IntegrationPartner)),
          isActive: false,
        });
      }
    },
    [setIntegrationData, integrationData],
  );

  const [
    disconnectAccountingIntegrationRequest,
    disconnectAccountingIntegrationResponse,
  ] = useMutation(DISCONNECT_ACCOUNTING_PARTNER, {
    onCompleted: onDisconnectAccountingIntegration,
    onError: noopHandler,
  });

  const disconnectAccountingIntegration = useCallback(
    (accountingPartner: IntegrationApps) => {
      disconnectAccountingIntegrationRequest({
        variables: {
          integrationPartner: accountingPartner,
        },
      });
    },
    [disconnectAccountingIntegrationRequest],
  );

  const saveIntegrationDetails = useCallback(
    (url: string) => {
      saveIntegrationDetailsRequest({
        variables: {
          input: url,
        },
      });
    },
    [saveIntegrationDetailsRequest],
  );

  useEffect(() => {
    if (saveIntegrationDetailsResponse.data) {
      setIntegrationData(
        saveIntegrationDetailsResponse.data.createAccountingIntegration,
      );
    }
  }, [saveIntegrationDetailsResponse.data]);

  const onUpdateAccountingIntegrationStoreSettings = useCallback(
    data => {
      if (data?.updateAccountingIntegrationStoreSettings) {
        const updatedAccountingIntegrationStoresSettings =
          accountingIntegrationStoresSettings.map(storeSettings => {
            return storeSettings.store ===
              data.updateAccountingIntegrationStoreSettings.store
              ? data.updateAccountingIntegrationStoreSettings
              : storeSettings;
          });
        setAccountingIntegrationStoresSettings(
          updatedAccountingIntegrationStoresSettings,
        );
      }
    },
    [accountingIntegrationStoresSettings],
  );

  const [
    updateAccountingIntegrationStoreSettingsRequest,
    updateAccountingIntegrationStoreSettingsResponse,
  ] = useMutation(UPDATE_ACCOUNTING_INTEGRATION_STORES_SETTINGS, {
    onError: noopHandler,
    onCompleted: onUpdateAccountingIntegrationStoreSettings,
  });

  const updateAccountingIntegrationStoreSettings = useCallback(
    (
      accountingIntegrationStoreSettings: IntegrationPartnerStoreConfiguration,
    ) => {
      updateAccountingIntegrationStoreSettingsRequest({
        variables: {
          accountingIntegrationStoreSettings,
        },
      });
    },
    [updateAccountingIntegrationStoreSettingsRequest],
  );

  const RESPONSE_ENTITIES = [
    getAccountingPartnerConsentUrlResponse,
    saveIntegrationDetailsResponse,
    getAccountingIntegrationStoresSettingsResponse,
    toggleAccountingIntegrationStoreConnectionResponse,
    getChartOfAccountsResponse,
    getTaxRatesResponse,
    updateAccountingIntegrationStoreSettingsResponse,
    disconnectAccountingIntegrationResponse,
  ];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      accountingPartnerConsentUrl,
      getAccountingPartnerConsentUrl,
      saveIntegrationDetails,
      integrationData,
      accountingIntegrationStoresSettings,
      getAccountingIntegrationStoresSettings,
      toggleAccountingIntegrationStoreConnection,
      getChartOfAccounts,
      chartOfAccounts,
      taxRates,
      getTaxRates,
      updateAccountingIntegrationStoreSettings,
      disconnectAccountingIntegration,
    }),
    [
      loading,
      error,
      accountingPartnerConsentUrl,
      getAccountingPartnerConsentUrl,
      saveIntegrationDetails,
      integrationData,
      accountingIntegrationStoresSettings,
      getAccountingIntegrationStoresSettings,
      toggleAccountingIntegrationStoreConnection,
      getChartOfAccounts,
      chartOfAccounts,
      taxRates,
      getTaxRates,
      updateAccountingIntegrationStoreSettings,
      disconnectAccountingIntegration,
    ],
  );
}
