import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  App,
  UserRoleDef,
  WorklogAction,
  WorklogEvent,
} from '@hitz-group/domain';
import { initializeWorklog, updateWorklog } from '@hitz-group/worklog-helper';
import {
  computeAppAccess,
  PosUser,
  UserWithUserRoles,
  StoreUsers,
} from '../state/userUtility';
import { useNetInfo } from '@react-native-community/netinfo';
import { parseApolloError } from '../utils/errorHandlers';
import { hashedString } from '@hitz-group/client-utils';
import { GET_STORE_USERS_QUERY } from '../graphql/store';
import { getError, isLoading } from '../utils/apolloErrorResponse.util';
import { useLazyQuery } from '@apollo/client/react/hooks';
import useRolesContext from './app/users/useRolesContext';
import keyBy from 'lodash/keyBy';
import { User as WorklogUser } from '../screens/POS/LockScreen/TimeAndAttendanceScreen';

interface Props {
  children: ReactNode;
}

export interface StoreUser extends PosUser {
  isWorking?: boolean;
}
export interface POSUserRoleContextType {
  /**
   * Verifies whether app access is available for a given user (role filter too)
   */
  canGivenUserAccess: (app: App, userId: string, roleId?: string) => boolean;
  validateUserPin: (pin: string, userId: string) => PosUser | undefined;
  loading: boolean;
  error?: string;
  fetchStoreUsers: (storeId: string) => void;
  users: StoreUser[];
  getUserByUserId: (userId: string) => UserWithUserRoles | void;
  /**
   * Used by subscription and lockscreen to update isWorking status
   */
  updateUserWorkLogBaseOnEvents: (events: WorklogEvent[]) => void;
}

export const POSUserRoleContext = createContext<POSUserRoleContextType>(
  {} as POSUserRoleContextType,
);

const POSUserRoleProvider: FC<Props> = ({ children }) => {
  const [usersWithUserRoles, setUsersWithUserRoles] = useState<
    UserWithUserRoles[]
  >([]);
  const netInfo = useNetInfo();
  const [users, setUsers] = useState<StoreUser[]>([]);
  const { rolesById, loading: loadingRoles } = useRolesContext();

  const onFetchStoreUsersCompleted = useCallback((data: StoreUsers) => {
    setUsersWithUserRoles(
      data.store.users.map(user => {
        return {
          ...user,
          storeId: data.store.id,
        };
      }) || [],
    );

    setUsers(
      data.store.users.map(user => {
        return {
          id: user.id,
          name: user.name,
          pin: user.pin,
          isWorking: user.isWorking || false,
          lastWorklog: (user as WorklogUser).lastWorklog,
        };
      }),
    );
  }, []);

  const [request, response] = useLazyQuery<
    StoreUsers,
    {
      storeId: string;
    }
  >(GET_STORE_USERS_QUERY, {
    fetchPolicy: netInfo.isConnected ? 'cache-and-network' : 'cache-only',
    onCompleted: onFetchStoreUsersCompleted,
  });

  const getUserByUserId = useCallback(
    (userId: string) => {
      return usersWithUserRoles.find(user => user.id === userId);
    },
    [usersWithUserRoles],
  );

  const canGivenUserAccess = useCallback(
    (app: App, userId: string, roleId?: string) => {
      const posUser = getUserByUserId(userId);
      if (posUser) {
        const userRoles = posUser.roles.filter((userRole: UserRoleDef) =>
          roleId ? userRole.role.id === roleId : true,
        );
        return computeAppAccess(rolesById, userRoles, app);
      }
      return false;
    },
    [getUserByUserId, rolesById],
  );

  const fetchStoreUsers = useCallback(
    (storeId: string): void => {
      request({
        variables: {
          storeId,
        },
      });
    },
    [request],
  );

  const validateUserPin = useCallback(
    (pin: string, userId: string) => {
      const hashedPin = hashedString(pin);
      const currentUser = users.find(user => user.id === userId);
      return currentUser?.pin === hashedPin ? currentUser : undefined;
    },
    [users],
  );

  const updateUserWorkLogBaseOnEvents = useCallback(
    async (events: WorklogEvent[]) => {
      const eventsByUserId = keyBy(events, 'userId');
      const updatedStoreUsers = await Promise.all(
        users.map(async user => {
          const event = eventsByUserId[user.id];
          if (event) {
            const updatedUser = {
              ...user,
              lastWorklog: { ...((user as WorklogUser).lastWorklog || {}) },
            } as WorklogUser;
            switch (event.action) {
              case WorklogAction.WORKLOG_CLOCK_IN:
                await initializeWorklog(
                  updatedUser.lastWorklog || {},
                  updatedUser,
                );
                break;
              case WorklogAction.WORKLOG_BREAK_END:
              case WorklogAction.WORKLOG_CLOCK_OUT:
              case WorklogAction.WORKLOG_BREAK_START:
                await updateWorklog(
                  event,
                  updatedUser.lastWorklog || {},
                  updatedUser,
                );
                break;
            }
            return {
              ...updatedUser,
              lastWorklog: { ...(updatedUser.lastWorklog || {}) },
            } as WorklogUser;
          }
          return user;
        }),
      );
      setUsers(updatedStoreUsers);
    },
    [users],
  );

  const RESPONSE_ENTITIES = [response];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  const value = useMemo(
    () => ({
      users,
      validateUserPin,
      loading: loading || loadingRoles,
      error: error ? parseApolloError(error) : undefined,
      fetchStoreUsers,
      canGivenUserAccess,
      getUserByUserId,
      updateUserWorkLogBaseOnEvents,
    }),
    [
      canGivenUserAccess,
      validateUserPin,
      loading,
      error,
      fetchStoreUsers,
      users,
      getUserByUserId,
      loadingRoles,
      updateUserWorkLogBaseOnEvents,
    ],
  );

  return (
    <POSUserRoleContext.Provider value={value}>
      {children}
    </POSUserRoleContext.Provider>
  );
};

export default POSUserRoleProvider;
