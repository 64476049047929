import {
  StyleFn,
  ProductPricing as ProductPricingDefault,
  Product,
} from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { GestureResponderEvent, View } from 'react-native';
import SearchBar from '../../../../../../components/SearchBar/SearchBar';
import Button from '../../../../../../components//Button/Button';
import DropDown from '../../../../../../components/DropDown/DropDown';
import { useTranslation } from '@hitz-group/localization';
import { useAvailabilityOptions } from '../../../../../../hooks/app/useAvailabilityOptions';
import { useProducts } from '../../../../../../hooks/app/products/useProducts';
import debounce from 'lodash/debounce';
import ButtonWithActionSheet from '../../../../../../components/ButtonWithActionSheet/ButtonWithActionSheet';

export interface ProductPricing extends ProductPricingDefault {
  isSelected?: boolean;
  isChanged?: boolean;
  isNew?: boolean;
  tax?: string;
  price?: string;
  productId: string;
}
interface Data {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
}

interface ProductPriceHeaderFilterProps {
  filterValue: string;
  isDefault?: boolean;
  defaultPricing: Record<string, ProductPricing>;
  onFilter: (filterValue: string) => void;
  onPressAdd: (data: Data) => void;
  onPressAddAll: (data: ProductPricing[]) => void;
  setPrices: (event?: GestureResponderEvent) => void;
  modifyPrices: (event?: GestureResponderEvent) => void;
  resetPrices: (event?: GestureResponderEvent) => void;
}

const titleActionStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  paddingHorizontal: theme.padding.medium * 3,
});

const dropdownInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 150,
  bottom: 10,
});
const extraPopoverStyle: StyleFn = () => ({
  width: 150,
});
const itemContainer: StyleFn = () => ({
  height: 38 * 3,
});

const searchResultStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 40,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  paddingLeft: theme.padding.small,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  marginTop: theme.spacing.small,
});

const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: theme.input.height,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  paddingLeft: theme.padding.small,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
});

const searchBox: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  flex: 1,
  width: '100%',
  marginHorizontal: theme.spacing.medium,
});

const searchBoxInner: StyleFn = () => ({
  width: '100%',
});

const searchTextInputStyle: StyleFn = () => ({
  width: '100%',
  flex: 1,
});

const titleStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  textTransform: 'none',
  letterSpacing: -0.5,
  marginHorizontal: theme.spacing.small,
});

const rightIconStyle: StyleFn = () => ({
  height: 34,
  width: 20,
  justifyContent: 'center',
  alignSelf: 'center',
  marginLeft: 'auto',
});

export const ProductPriceHeaderFilter: React.FC<
  ProductPriceHeaderFilterProps
> = ({
  filterValue,
  isDefault,
  defaultPricing,
  onFilter,
  onPressAdd,
  onPressAddAll,
  setPrices,
  modifyPrices,
  resetPrices,
}) => {
  const { css, theme } = useFela();
  const { pages } = useAvailabilityOptions();
  const { products, getProductsByName } = useProducts();
  const { translate } = useTranslation();
  const [searchString, setSearchString] = useState('');
  const [productData, setProductData] = useState<Record<string, Product>>({});
  const [isFocus, setIsFocus] = useState<boolean>(false);

  useEffect(() => {
    if (products) {
      setProductData(products);
    }
  }, [products]);

  const searchProduct = debounce(
    (name: string) => getProductsByName(name),
    300,
  );

  const productDataOption = useMemo(() => {
    return Object.values(productData);
  }, [productData]);

  const onSearchTextChange = useCallback(
    (value: string) => {
      setSearchString(value);
      searchProduct(value);
    },
    [searchProduct],
  );

  const handlePress = useCallback(() => {
    setSearchString('');
    setProductData({});
  }, []);

  const handleCreate = useCallback(
    (product: Product) => {
      onPressAdd({ ...defaultPricing[product.id] });
      handlePress();
    },
    [defaultPricing, onPressAdd, handlePress],
  );

  const handleAddAllProduct = useCallback(() => {
    onPressAddAll(Object.values(defaultPricing));
  }, [defaultPricing, onPressAddAll]);

  return (
    <View style={css(titleActionStyle)}>
      <DropDown
        options={[
          {
            label: translate('backOfficeProducts.productFilterByPage'),
            value: '',
          },
        ].concat(...pages)}
        selectedValue={filterValue}
        containerStyle={css(dropdownInputStyle)}
        itemsContainerStyle={css(itemContainer)}
        onValueChange={onFilter}
        extraPopoverStyle={css(extraPopoverStyle)}
      />
      {!isDefault && (
        <>
          <View style={css(searchBox)}>
            <View style={css(searchBoxInner)}>
              <SearchBar
                onCancel={handlePress}
                value={isDefault ? '' : searchString}
                placeholder={translate('pricings.searchProductByName')}
                containerStyle={css(searchContainerStyle)}
                textInputStyle={css(searchTextInputStyle)}
                iconColor={theme.colors.paragraph}
                placeholderColor={theme.colors.paragraph}
                onChange={onSearchTextChange}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setTimeout(() => setIsFocus(false), 500)}
              />
              {isFocus && (
                <Button
                  testID="addAllProductsBtn"
                  fluid
                  containerStyle={css(searchResultStyle)}
                  labelStyle={css(titleStyle)}
                  iconContainerStyle={css(rightIconStyle)}
                  iconProps={{
                    color: theme.colors.paragraph,
                    size: 24,
                  }}
                  title={translate('pricings.addAllProducts')}
                  iconPosition={'right'}
                  icon={'plus'}
                  onPress={() => handleAddAllProduct()}
                />
              )}
              {searchString.trim().length > 0 &&
                productDataOption.map(product => (
                  <Button
                    fluid
                    key={product.id}
                    containerStyle={css(searchResultStyle)}
                    labelStyle={css(titleStyle)}
                    iconContainerStyle={css(rightIconStyle)}
                    iconProps={{
                      color: theme.colors.paragraph,
                      size: 24,
                    }}
                    title={product.name}
                    iconPosition={'right'}
                    icon={'plus'}
                    onPress={() => handleCreate(product)}
                  />
                ))}
            </View>
          </View>
          <ButtonWithActionSheet
            title="Bulk Options"
            actionList={[
              { label: 'Set Price', action: setPrices },
              { label: 'Modify Price', action: modifyPrices },
              { label: 'Reset Price', action: resetPrices },
            ]}
          />
        </>
      )}
    </View>
  );
};
