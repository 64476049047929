/**
 * Default country code for phone number input
 */
export const DEFAULT_COUNTRY_CODE = 'AU';

export const DURATION_FORMAT = ['days', 'hours', 'minutes', 'seconds'];
export const DATABASE_NAME = 'tillx';
export const APOLLO_CACHE_PERSIST_KEY = 'apollo_cache_persist';
export const DATE_FORMAT = 'hh:mm aa dd/MM';

export const DUMP_CHANGE = '1';
