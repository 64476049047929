import { useTranslation } from '@hitz-group/localization';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { useModal } from '@hitz-group/rn-use-modal';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import PopupView from '../../PopupView/PopupView';
import TitleBar from '../../TitleBar/TitleBar';
import { StyleFn, TerminalDiagnosisInfo } from '@hitz-group/domain';
import {
  TerminalDiagnosisRow,
  TerminalDiagnosisRowProps,
} from './TerminalDiagnosisRow';

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 15,
});

const containerStyle: StyleFn = ({ theme }) => ({
  width: 600,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  flex: 1,
});

const popupStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.medium * 2.5,
  minHeight: 235,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small * 3.5,
  alignSelf: 'center',
  backgroundColor: theme.colors.greyLight,
});

const labelTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  textTransform: 'capitalize',
});

export type TerminalDiagnosisProps = {
  diagnosisData: TerminalDiagnosisInfo | undefined;
};

const TerminalDiagnosis: React.FC<TerminalDiagnosisProps> = ({
  diagnosisData,
}: TerminalDiagnosisProps) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const onCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const titleLeft = (
    <IconButton
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={onCloseModal}
    />
  );

  const getDataToDisplay = useCallback(() => {
    if (diagnosisData) {
      return (Object.keys(diagnosisData) as [keyof TerminalDiagnosisInfo])
        .filter(label => {
          if (
            diagnosisData[label as keyof TerminalDiagnosisInfo] === null ||
            diagnosisData[label as keyof TerminalDiagnosisInfo] === undefined
          ) {
            return false;
          }
          return true;
        })
        .map(label => (
          <TerminalDiagnosisRow
            key={`diagnosis-info-row-${label}`}
            label={label}
            value={diagnosisData[label] as TerminalDiagnosisRowProps['value']}
          />
        ));
    }

    return [];
  }, [diagnosisData]);

  return (
    <View style={css(containerStyle)}>
      <TitleBar
        titleLeft={titleLeft}
        primary
        title={translate('paymentTerminal.diagnosis.header')}
      />
      <PopupView containerStyle={css(popupStyle)}>
        {getDataToDisplay()}
        <Button
          title={translate('form.dismiss')}
          testID="dismiss-modal-btn"
          secondary
          fluid
          size="small"
          containerStyle={css(buttonStyle)}
          onPress={onCloseModal}
          labelStyle={css(labelTextStyle)}
        />
      </PopupView>
    </View>
  );
};

export default TerminalDiagnosis;
