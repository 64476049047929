import { useMutation } from '@apollo/client/react/hooks';
import { useMemo, useCallback, useState } from 'react';
import {
  ADD_MODIFIER_PRICING_MUTATION,
  UPDATE_MODIFIER_PRICING_MUTATION,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { Operation } from '../../../types/Operation';
import { ModifierPricingInput } from '@hitz-group/domain';

export interface usemodifierPricingsProps {
  update: (input: ModifierPricingInput[]) => void;
  addModifierPricing: (
    modifierId: string,
    pricings: {
      pricingGroupId: string;
      modifierPricing: ModifierPricingInput;
    }[],
  ) => void;
  loading: boolean;
  error: string | undefined;
  operation: Operation;
}

export function useModifierPricings(): usemodifierPricingsProps {
  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [update, updateRequest] = useMutation(
    UPDATE_MODIFIER_PRICING_MUTATION,
    {
      onError: noopHandler,
    },
  );

  const [addProductPricing, addProductPricingRequest] = useMutation(
    ADD_MODIFIER_PRICING_MUTATION,
    {
      onError: noopHandler,
    },
  );

  const updateProductPricings = useCallback(
    input => {
      update({ variables: { input } });
      setOperation(Operation.UPDATE);
    },
    [update],
  );

  const addModifierPricingDetails = useCallback(
    (
      modifierId: string,
      pricings: {
        pricingGroupId: string;
        modifierPricing: ModifierPricingInput;
      }[],
    ) => {
      addProductPricing({ variables: { modifierId, pricings } });
      setOperation(Operation.CREATE);
    },
    [addProductPricing],
  );

  const error = updateRequest.error || addProductPricingRequest.error;

  const loading = updateRequest.loading || addProductPricingRequest.loading;

  return useMemo(
    () => ({
      update: updateProductPricings,
      addModifierPricing: addModifierPricingDetails,
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
    }),
    [
      updateProductPricings,
      operation,
      addModifierPricingDetails,
      error,
      loading,
    ],
  );
}
