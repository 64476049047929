import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFela } from 'react-fela';
import { View, ScrollView, Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@hitz-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  StyleFn,
  STANDARD_MENU,
  Catalogue,
  AppScreen,
  CreateDeviceProfileInput,
  FunctionMap,
  DeviceProfile,
  Store,
  UpdateDeviceProfileDetailsInput,
  Section,
  OrderTypeCode,
  Features,
  FeatureContext,
  Icons,
} from '@hitz-group/domain';
import DropDown from '../../../../../components/DropDown/DropDown';
import FormInput, {
  Label,
} from '../../../../../components/FormInput/FormInput';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import Button from '../../../../../components/Button/Button';
import { useNotification } from '../../../../../hooks/Notification';
import { pick } from 'lodash';
import { useDeviceProfiles } from '../../../../../hooks/app/useDeviceProfiles';
import { DeviceProfileSettingsFooter } from './DeviceProfileSettingsFooter';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import { useStores } from '../../../../../hooks/app/useStores';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import { useMenus } from '../../../../../hooks/app/menus/useMenus';
import { isAndroid } from '../../../../../common/theme';
import { Operation } from '../../../../../../src/types/Operation';
import { OrderType } from '@hitz-group/domain';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import TableRow from '../../../../../components/TableComponent/TableRow';
import IconButton from '../../../../../components/Button/IconButton';
import { useSections } from '../../../../../hooks/app/sections/useSections';
import { WithFeature } from '../../../../../components/features/WithFeature';

interface OrderTypesRowProps {
  orderType: OrderType;
  enabled: boolean;
  isDefault: boolean;
  onToggle: (id: string, value: boolean) => void;
  onSelectDefault: (id: string) => void;
}

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
});

const formContainerStyleSmall: StyleFn = ({ theme }) => ({
  width: 545,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: theme.spacing.small,
});

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  width: 545,
  marginTop: theme.spacing.big,
});

const backOfficeContainerDetailsStyle: StyleFn = ({ theme }) => ({
  width: 545,
  marginTop: theme.spacing.big + theme.spacing.medium,
  zIndex: 5,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  height: theme.input.height,
  marginBottom: theme.spacing.big / 2,
});
const functionMapContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  height: theme.input.height,
  marginBottom: theme.spacing.big / 2,
});
const extraPopoverStyle: StyleFn = () => ({
  width: 260,
});
const dropDownItemsContainerStyle: StyleFn = () => ({
  height: 38 * 3,
});
const titleStyle: StyleFn = () => ({
  left: 10,
});

const defaultContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  height: theme.input.height,
  marginBottom: theme.spacing.big / 2,
  marginTop: theme.spacing.big,
});

const selectedItemStyle: StyleFn = () => ({
  marginVertical: 5,
});

const textStyle: StyleFn = () => ({
  marginTop: isAndroid ? -6 : 0,
});

const dropdownContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  height: theme.input.height,
  marginTop: isAndroid ? theme.padding.small + 2 : 0,
});
const touchableStyle: StyleFn = ({ theme }) => ({
  minHeight: theme.input.height,
  flexWrap: 'wrap',
});

const formLabelStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  paddingHorizontal: theme.spacing.small,
});

const checkBoxTitleStyle: StyleFn = ({ theme, isActive }) => ({
  ...theme.font14Regular,
  textTransform: 'none',
  color: isActive ? theme.colors.charcoal : theme.colors.paragraph,
});

const viewStyle: StyleFn = () => ({
  alignItems: 'center',
  flexDirection: 'row',
  width: 260,
  height: 38,
});

const nameTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  lineHeight: 21,
});

const toggleContainerStyle: StyleFn = () => ({
  width: 50,
});

const textWrapper: StyleFn = ({ theme }) => ({
  flex: 0.3,
  height: theme.input.height,
  borderRadius: theme.radius.small,
});

const smallContainer: StyleFn = ({ theme }) => ({
  flex: 0.6,
  borderRadius: theme.radius.small,
  paddingHorizontal: theme.padding.medium,
  backgroundColor: theme.colors.greyLight,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginRight: theme.spacing.medium + theme.spacing.small / 2,
});

const iconWrapperStyle: StyleFn = () => ({
  flex: 0.1,
});

const defaultCheckContainerStyle: StyleFn = ({ theme }) => ({
  width: 40,
  height: theme.input.height,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
});

export const OrderTypesRow: React.FC<OrderTypesRowProps> = ({
  onToggle,
  orderType,
  enabled,
  isDefault,
  onSelectDefault,
}: OrderTypesRowProps) => {
  const { css, theme } = useFela();

  const onPressHandler = useCallback(() => {
    onToggle(orderType.id, !enabled);
  }, [onToggle, orderType.id, enabled]);

  const onPressDefault = useCallback(() => {
    onSelectDefault(orderType.id);
  }, [onSelectDefault, orderType.id]);

  return (
    <TableRow normalRows={true}>
      <IconButton
        testID="deviceProfile-orderType"
        primary
        icon={enabled ? 'toggle-on' : 'toggle-off'}
        iconSize={26}
        onPress={onPressHandler}
        containerSize={34}
        containerStyle={css(toggleContainerStyle)}
        iconColor={enabled ? theme.colors.success : theme.colors.boxBorder}
      />

      <View style={css(smallContainer)}>
        <Text style={css(nameTextStyle)}>{orderType.name}</Text>
      </View>
      <View style={css(textWrapper)} />
      <View style={css(iconWrapperStyle)}>
        <Button
          testID="deviceProfile-defaultOrderType"
          labelStyle={css(checkBoxTitleStyle({ theme, isActive: false }))}
          fluid
          iconPosition={'left'}
          disabled={!enabled}
          onPress={onPressDefault}
          containerStyle={css(defaultCheckContainerStyle({ theme }))}
          icon={'recordAudio'}
          iconProps={{
            color: isDefault ? theme.colors.success : theme.colors.boxBorder,
            size: 20,
          }}
        />
      </View>
    </TableRow>
  );
};

export const Details: React.FC = () => {
  const { css, theme } = useFela();
  const route = useRoute();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState({
    printerOptions: {
      emailReceipt: false,
      printReceipt: false,
    },
  } as DeviceProfile);

  const params = route.params as {
    deviceProfileId: string;
    storeId: string;
    venueId: string;
    operation: string;
  };

  const deviceProfileId = params.deviceProfileId || '';
  const storeId = params.storeId || '';
  const venueId = params.venueId || '';
  const operationRef = useRef(params.operation || 'edit');

  const {
    deviceProfiles: deviceProfiles,
    orderTypes,
    functionMaps,
    loading: useDeviceProfilesLoading,
    error: useDeviceProfilesError,
    updatedDeviceProfileId,
    unAssignedDeviceProfileId,
    deletedDeviceProfile,
    getOrderTypes,
    getFunctionMaps,
    createDeviceProfile,
    updateDeviceProfileDetails,
    unAssignDeviceProfile,
    deleteDeviceProfile,
  } = useDeviceProfiles({ deviceProfileId, storeId, venueId });
  const {
    stores,
    getStores,
    loading: useStoresLoading,
    error: useStoresError,
  } = useStores();
  const {
    sections,
    getSections,
    loading: useSectionsLoading,
    error: useSectionsError,
  } = useSections({ venueId });

  const {
    getMenusOptions,
    loading: menuLoading,
    error: menuErr,
    menus,
  } = useMenus();

  const error =
    useDeviceProfilesError || useStoresError || menuErr || useSectionsError;
  const loading =
    useDeviceProfilesLoading ||
    useStoresLoading ||
    menuLoading ||
    useSectionsLoading;

  const formInputContainerStyleCss = css(formInputContainerStyle);
  const formLabelStyleCss = css(formLabelStyle);

  const returnDefaultOrderType = (form: DeviceProfile) => {
    if (form.defaultOrderType) {
      return form.defaultOrderType?.id;
    } else if (form.orderTypes && form.orderTypes.length > 0) {
      return form.orderTypes[0].id;
    }
    return '';
  };

  const [defaultOrderType, setDefaultOrderType] = useState<string>(
    returnDefaultOrderType(form),
  );

  useEffect(() => {
    if (!loading && !error && operationRef.current === '') {
      showNotification({
        success: true,
        message: translate('backOfficeDeviceProfiles.deviceCreated'),
      });

      navigation.navigate('StoreSettings', {
        storeId: storeId,
        venueId,
        screen: 'DeviceProfiles',
      });
    }
  }, [
    error,
    loading,
    navigation,
    showNotification,
    storeId,
    translate,
    venueId,
  ]);

  useEffect(() => {
    getOrderTypes();
    getMenusOptions();
  }, [getOrderTypes, getMenusOptions]);

  useEffect(() => {
    getFunctionMaps();
  }, [getFunctionMaps]);

  useEffect(() => {
    getStores();
  }, [getStores]);

  useEffect(() => {
    getSections();
  }, [getSections]);

  const menuOptions = useMemo(() => {
    const menusData = Object.values(menus);
    return menusData.map(x => ({ value: x.id, label: x.name }));
  }, [menus]);

  const standardMenu = useMemo(() => {
    const standardMenuDetails = menuOptions.filter(
      x => x && x.label === STANDARD_MENU,
    );
    return standardMenuDetails?.[0];
  }, [menuOptions]);

  useEffect(() => {
    if (deviceProfiles[deviceProfileId]) {
      setForm(form => {
        return {
          ...form,
          ...deviceProfiles[deviceProfileId],
        };
      });
      setDefaultOrderType(deviceProfiles[deviceProfileId].defaultOrderType?.id);
    } else if (operationRef.current === Operation.CREATE) {
      setForm(form => {
        return {
          ...form,
          store: { id: storeId },
          orderTypes: [],
          sections: [],
        } as unknown as DeviceProfile;
      });
    }
  }, [deviceProfiles, setForm, deviceProfileId, storeId]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect((): void => {
    if (updatedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedDeviceProfileId, showNotification, translate]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onChangeStore = useCallback((value): void => {
    setForm(form => {
      return {
        ...form,
        store: { id: value } as Store,
      };
    });
  }, []);

  const onChangeSection = useCallback((values: string[]): void => {
    setForm(form => {
      return {
        ...form,
        sections: values.map((value: string) => ({ id: value } as Section)),
      };
    });
  }, []);

  const onChangeFunctionMap = useCallback((value): void => {
    setForm(form => {
      return {
        ...form,
        functionMap: { id: value } as FunctionMap,
      };
    });
  }, []);

  const onChangeMenu = useCallback((value): void => {
    setForm(form => {
      return {
        ...form,
        menu: { id: value } as Catalogue,
      };
    });
  }, []);

  const validateDineInOrderTypeForFloorViewEnabled = useCallback(
    (orderTypeId: string) => {
      const orderType = form.orderTypes.find(
        orderType => orderType.id === orderTypeId,
      );
      return form.enableFloorView && orderType?.code === OrderTypeCode.DINE_IN;
    },
    [form.enableFloorView, form.orderTypes],
  );

  const onChangeOrderTypes = useCallback(
    (id: string, value: boolean): void => {
      if (id === defaultOrderType) {
        showNotification({
          error: true,
          message: translate('backOfficeDeviceProfiles.disableDefaultWarning'),
        });
        return;
      }
      if (value) {
        const orderTypeData = orderTypes.find(orderType => orderType.id === id);
        orderTypeData &&
          setForm({ ...form, orderTypes: [...form.orderTypes, orderTypeData] });
      } else {
        if (validateDineInOrderTypeForFloorViewEnabled(id)) {
          showNotification({
            error: true,
            message: translate('backOfficeDeviceProfiles.disableDineInWarning'),
          });
          return;
        }
        const index = form.orderTypes.findIndex(
          orderType => orderType.id === id,
        );
        if (index !== -1) {
          const orderTypes = [...form.orderTypes];
          orderTypes.splice(index, 1);
          setForm({
            ...form,
            orderTypes,
          });
        }
      }
    },
    [
      defaultOrderType,
      form,
      orderTypes,
      showNotification,
      translate,
      validateDineInOrderTypeForFloorViewEnabled,
    ],
  );

  const updateDefaultOrderType = useCallback(
    (orderTypeId: string) => {
      if (defaultOrderType !== orderTypeId) {
        setDefaultOrderType(orderTypeId);
      }
    },
    [defaultOrderType],
  );

  const onPressSave = useCallback((): void => {
    if (!form.name) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceProfiles.nameRequired'),
      });
      return;
    } else if (form.orderTypes.length === 0) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceProfiles.orderTypeRequired'),
      });
      return;
    }
    const params = pick(form, [
      'name',
    ]) as unknown as UpdateDeviceProfileDetailsInput;

    if (Object.values(params).some(value => !value)) {
      showNotification({
        error: true,
        message: translate('backOfficeDeviceProfiles.fieldsMissing'),
      });

      return;
    }
    params.isDefault = form.isDefault;
    params.printerOptions = pick(form.printerOptions, [
      'emailReceipt',
      'printReceipt',
    ]);
    params.orderTypes = form.orderTypes.map(orderType => orderType.id);
    params.menu = form?.menu?.id || standardMenu?.value;
    params.sections = form.sections.map(({ id }) => id);

    params.defaultOrderType = defaultOrderType || params.orderTypes[0];

    if (operationRef.current === Operation.CREATE) {
      operationRef.current = '';
      createDeviceProfile({
        ...params,
        defaultSaleScreen: AppScreen.NEW_ORDER,
        postSaleScreen: AppScreen.NEW_ORDER,
      } as CreateDeviceProfileInput);
    } else {
      const deviceProfileId = pick(form, ['id']) as { id: string };

      params.isDefault = form.isDefault;
      if (form.store?.id) params.store = form.store.id;
      params.printerOptions = pick(form.printerOptions, [
        'emailReceipt',
        'printReceipt',
      ]);
      if (form.functionMap) params.functionMap = form.functionMap.id;

      updateDeviceProfileDetails({
        ...params,
        ...deviceProfileId,
      } as UpdateDeviceProfileDetailsInput);
    }
  }, [
    form,
    standardMenu?.value,
    defaultOrderType,
    showNotification,
    translate,
    createDeviceProfile,
    updateDeviceProfileDetails,
  ]);

  useEffect(() => {
    if (deletedDeviceProfile) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: deviceProfiles[deviceProfileId]?.name,
        }),
      });

      navigation.navigate('StoreSettings', {
        storeId: deviceProfiles[deviceProfileId]?.store?.id,
        screen: 'DeviceProfiles',
        venueId,
      });
    }
  }, [
    deletedDeviceProfile,
    navigation,
    deviceProfiles,
    showNotification,
    translate,
    deviceProfileId,
    venueId,
  ]);

  useEffect(() => {
    if (unAssignedDeviceProfileId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.registerUnassignedSuccessfully'),
      });
    }
  }, [showNotification, translate, unAssignedDeviceProfileId]);

  if (loading) return <LoadingIndicator />;

  const selectedOrderTypes =
    (form.orderTypes &&
      form.orderTypes.reduce<Record<string, OrderType>>(
        (acc, x) => ({ ...acc, [x.id]: x }),
        {},
      )) ||
    {};

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView
          testID="deviceProfile-settings-screen"
          contentContainerStyle={css(generalSettingsContainerWrapper)}
        >
          <BackOfficeSection
            title={translate('backOfficeSettings.registerDetails')}
            contentContainerStyle={css(formContainerStyleSmall)}
            containerStyle={css(backOfficeContainerDetailsStyle)}
            iconColor={theme.colors.blue}
            icon={'info-circle'}
          >
            <FormInput
              error={false}
              testID="deviceProfile-name"
              placeholder={translate('form.registerName')}
              title={translate('form.registerName')}
              isRequired={true}
              value={form.name || ''}
              alignTitle="left"
              containerStyle={formInputContainerStyleCss}
              textStyle={formLabelStyleCss}
              onChangeText={onChange.bind(null, 'name')}
            />
            <MultipleSelect
              label={translate('form.assignedStore')}
              values={Object.values(stores).map(store => ({
                label: store.name,
                value: store.id,
              }))}
              textStyle={css(textStyle)}
              selectedValues={[form.store?.id]}
              selectedItemStyle={css(selectedItemStyle)}
              showCheckboxes={false}
              containerStyle={css(dropdownContainerStyle)}
              onValueChange={values => onChangeStore(values[1])}
              touchableStyle={css(touchableStyle)}
            />
            <DropDown
              title={translate('form.functionMap')}
              options={[
                {
                  value: '',
                  label: 'None',
                },
                ...(functionMaps
                  ? functionMaps?.map(fnMap => {
                      return { value: fnMap.id, label: fnMap.name };
                    })
                  : []),
              ]}
              selectedValue={form.functionMap?.id || ''}
              containerStyle={css(functionMapContainerStyle)}
              itemsContainerStyle={css(dropDownItemsContainerStyle)}
              onValueChange={onChangeFunctionMap}
              titleStyle={css(titleStyle)}
              extraPopoverStyle={css(extraPopoverStyle)}
            />
            <DropDown
              title={translate('form.selectMenu')}
              options={menuOptions}
              itemsContainerStyle={css(dropDownItemsContainerStyle)}
              selectedValue={form?.menu?.id || standardMenu?.value}
              containerStyle={formInputContainerStyleCss}
              onValueChange={value => onChangeMenu(value)}
              titleStyle={css(titleStyle)}
              extraPopoverStyle={css(extraPopoverStyle)}
            />
            <WithFeature
              feature={{
                name: Features.TABLE_MANAGEMENT,
                context: FeatureContext.VENUE,
              }}
              venue={params.venueId}
            >
              <MultipleSelect
                label={translate('form.assignedSection')}
                values={Object.values(sections).map(section => ({
                  label: section.name,
                  value: section.id,
                }))}
                testID="deviceProfile-assignSection"
                selectedValues={(form.sections || []).map(({ id }) => id)}
                selectedItemStyle={css(selectedItemStyle)}
                containerStyle={css({
                  ...dropdownContainerStyle,
                  width: 550,
                  marginTop: 15,
                })}
                onValueChange={onChangeSection}
                touchableStyle={css(touchableStyle)}
              />
            </WithFeature>
            <View style={css(defaultContainerStyle)}>
              <View
                style={css(viewStyle)}
                testID="deviceProfile-enableCashManagement"
              >
                <IconButton
                  icon={form.isDefault ? Icons.CheckSquare : Icons.SquareFull}
                  iconSize={20}
                  iconColor={
                    form.isDefault
                      ? theme.colors.success
                      : theme.colors.paragraph
                  }
                  onPress={onChange.bind(null, 'isDefault', !form.isDefault)}
                />
                <Label
                  textStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
                >
                  {translate('deviceProfileSettings.setAsDefault')}
                </Label>
              </View>
            </View>
          </BackOfficeSection>
          <BackOfficeSection
            title={translate('backOfficeDeviceProfiles.orderTypes')}
            contentContainerStyle={css(formContainerStyleSmall)}
            containerStyle={css(backOfficeContainerStyle)}
            iconColor={theme.colors.blue}
            icon={'info-circle'}
          >
            <TableComponent
              columns={[
                {
                  title: translate(
                    'deviceProfileSettings.EnableDisableOrderType',
                  ),
                  width: 400,
                },
                {
                  title: translate('deviceProfileSettings.Default'),
                  width: 100,
                  alignItems: 'flex-end',
                },
              ]}
              columnSpacing={theme.spacing.medium + theme.spacing.small / 2}
              data={orderTypes}
              normalRows={true}
              renderRow={(item: OrderType, index: number): React.ReactNode => (
                <OrderTypesRow
                  key={index}
                  orderType={item}
                  onToggle={onChangeOrderTypes}
                  enabled={selectedOrderTypes[item.id] ? true : false}
                  isDefault={item.id === defaultOrderType}
                  onSelectDefault={updateDefaultOrderType}
                />
              )}
            />
          </BackOfficeSection>
        </ScrollView>
        <DeviceProfileSettingsFooter
          deleteDeviceProfile={deleteDeviceProfile}
          unAssignDeviceProfile={unAssignDeviceProfile}
          onPressSave={onPressSave}
          deviceProfileId={deviceProfileId}
          deviceProfiles={deviceProfiles}
          status={form.status}
        />
      </View>
    </>
  );
};
