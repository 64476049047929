import React, {
  CSSProperties,
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { FelaComponent } from 'react-fela';
import {
  StyleFn,
  RenderProps,
  ImageUploadInput,
  ImageType,
} from '@hitz-group/domain';
import {
  View,
  Image as ImageRN,
  Text,
  StyleProp,
  ImageStyle,
  TouchableOpacity,
} from 'react-native';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { useNotification } from '../../hooks/Notification';
import { SUPPORTED_IMAGE_FORMATS } from '../../types/Common';
import FormInput, { Label } from '../FormInput/FormInput';
import Icon from '../Icon/Icon';
import scale from '../../common/theme';

const styles = {
  container: {
    display: 'flex',
    cursor: 'pointer',
  },
  input: {
    border: 0,
    width: 0,
    height: 0,
    visibility: 'hidden',
    display: 'none',
    alignItems: 'center',
  },
};

const addImageTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.smallest,
  letterSpacing: 0,
  color: theme.colors.white,
  fontFamily: theme.font.bold,
});

const addImageContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.avatar1,
  height: '20%',
  width: '100%',
  opacity: 0.5,
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  position: 'absolute',
  bottom: 0,
});

const avatarStyle: StyleFn = () => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
});

const avatarTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large * 2,
  letterSpacing: -0.5,
  color: theme.colors.avatar1,
  fontFamily: theme.font.bold,
});

const formInputTextStyle: StyleFn = () => ({
  color: 'transparent',
});

const imagePreviewcontainerStyle: StyleFn = ({ fluid }) => ({
  flexDirection: 'column',
  width: fluid ? '100%' : 'auto',
});

const textLabelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  textAlign: 'left',
  paddingVerticle: scale.moderateScale(7),
});

export interface ImagePickerProps {
  style?: StyleProp<ImageStyle>;
  imageStyle?: React.CSSProperties;
  onComplete: (data: ImageUploadInput) => void;
  onFail?: (error: string) => void;
  nameInitials?: string;
  imageUrl?: string;
  isFormInput?: boolean;
  formInputStyle?: StyleFn;
  title?: string;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  style,
  imageStyle,
  onComplete,
  nameInitials = 'NA',
  imageUrl,
  isFormInput,
  formInputStyle,
  title,
}: ImagePickerProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const [imageURI, setImageURI] = useState(imageUrl);
  const [imgInfo, setImgInfo] = useState<{ width: number; height: number }>();
  const { showNotification } = useNotification();
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>('');

  useEffect(() => {
    if (imageURI) {
      const img = new Image();
      img.src = imageURI;
      img.onload = function () {
        setImgInfo({
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [imageURI]);

  const handleUpload = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const file = event.target.files[0];
      const format = (file?.type || '').replace(/^image\//, '');
      if (!/^image\//.test(file?.type)) {
        showNotification({
          error: true,
          message: translate('avatar.notAnImage'),
        });
        return;
      }
      if (file && !SUPPORTED_IMAGE_FORMATS.includes(format)) {
        showNotification({
          error: true,
          message: translate('avatar.formatNotSupported', {
            format,
          }),
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = event => {
        const uriResult = (event.target?.result || '') as string;
        setImageURI(uriResult);
        setFileName(file.name);

        const image = new Image();
        image.src = event?.target?.result as string;
        image.onload = event => {
          const { width, height } = event.target as unknown as {
            width: number;
            height: number;
          };
          if (width && height && (width < 256 || height < 256)) {
            showNotification({
              error: true,
              message: translate('avatar.imageSizeError'),
            });
            return;
          }
          // if no error returns data
          onComplete({
            base64: uriResult,
            name: 'temp',
            type: format as ImageType,
          });
        };
      };

      reader.onerror = err => {
        showNotification({
          error: true,
          message: JSON.stringify(err),
        });
      };

      reader.readAsDataURL(file);
    },
    [showNotification, translate, onComplete],
  );

  const imageTempURL = (imageURI || imageUrl)?.includes('base64')
    ? imageURI || imageUrl
    : (imageURI || imageUrl) &&
      `${imageURI || imageUrl}?cache=${new Date().getTime()}`;
  const formatImgStyle = useMemo(() => {
    return {
      ...imageStyle,
      width: 250,
      height:
        imgInfo?.width &&
        imgInfo.height &&
        imgInfo.height * (250 / imgInfo?.width),
    } as unknown as StyleProp<ImageStyle>;
  }, [imageStyle, imgInfo?.height, imgInfo?.width]);
  return (
    <FelaComponent>
      {({ style: defaultStyle }: RenderProps): React.ReactFragment => (
        <View style={[defaultStyle, style]}>
          {!isFormInput && (
            <View style={css(avatarStyle)}>
              {imageTempURL ? (
                <ImageRN source={{ uri: imageTempURL }} style={style} />
              ) : (
                <Text style={css(avatarTextStyle)}>{nameInitials}</Text>
              )}
            </View>
          )}
          <label
            onChange={event => handleUpload(event)}
            style={styles.container}
          >
            <input
              style={styles.input as CSSProperties}
              type="file"
              accept="image/*"
              ref={inputRef}
            />
            {!isFormInput && (
              <View style={css(addImageContainerStyle)}>
                <Text style={css(addImageTextStyle)}>
                  {imageTempURL
                    ? translate('button.changeImage')
                    : translate('productSettings.addImage')}
                </Text>
              </View>
            )}
          </label>
          {isFormInput && (
            <View>
              {!imageTempURL && (
                <FormInput
                  title={title}
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                  rightIcon={
                    <Icon
                      name="cloud-upload"
                      size={30}
                      color="cornflowerblue"
                    />
                  }
                  placeholder={fileName}
                  textInputStyle={css(formInputTextStyle)}
                  containerStyle={formInputStyle && css(formInputStyle)}
                />
              )}
              {imageTempURL && (
                <View style={css(imagePreviewcontainerStyle)}>
                  <Label textStyle={css(textLabelStyle)} title={title}>
                    {title}
                  </Label>
                  <TouchableOpacity
                    onPress={() => {
                      inputRef.current?.click();
                    }}
                  >
                    <ImageRN
                      source={{ uri: imageTempURL }}
                      style={formatImgStyle}
                      resizeMode={'center'}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
          )}
        </View>
      )}
    </FelaComponent>
  );
};

export default ImagePicker;
