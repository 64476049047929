import { getFormattedAddress, COMBINED_SEGMENT } from '../../utils/places.util';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import { Session } from '../../state/Session';

const config: TableUserConfig = {
  columns: {
    0: {
      width: 40,
      alignment: 'center',
      wrapWord: true,
    },
  },
  border: getBorderCharacters('void'),
  drawHorizontalLine: () => {
    return false;
  },
};

export const generateStoreAddress = (session: Session): string => {
  const storeAddress = [
    session.currentVenue?.address
      ? [getFormattedAddress(session.currentVenue?.address, COMBINED_SEGMENT)]
      : [undefined],
  ];
  return table(storeAddress, config);
};
