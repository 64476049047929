import React, { useEffect, useState } from 'react';
import { FilterValue } from '@hitz-group/domain';
import { useReporting } from '../../../../../hooks/app/useReporting';
import { CubejsApi } from '@cubejs-client/core';
import { useNotification } from '../../../../../hooks/Notification';
import { useCurrency } from '@hitz-group/localization';
import { generateFilterData } from '../../reportsHelper';
import {
  CardComponentData,
  DateRangeFilterInput,
  ShiftQueryInput,
} from '../../types';
import { MetricCard } from '../../UIComponents/MetricCard';

interface ShiftVarianceProps {
  filters: FilterValue;
  dateRangeFilters?: DateRangeFilterInput;
  cubejsApi: CubejsApi;
  updateCount: number;
}

export const ShiftVarianceMetric: React.FC<ShiftVarianceProps> = ({
  filters,
  dateRangeFilters,
  cubejsApi,
}) => {
  const { showNotification } = useNotification();
  const { appendCurrency } = useCurrency();

  const [varianceData, setVarianceData] = useState<CardComponentData>({
    title: 'salesDashboard.ShiftVariance',
    value: appendCurrency('0.00'),
  });

  const { shiftReportData, loading, error, getShiftDifference } =
    useReporting(cubejsApi);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (shiftReportData?.shifts instanceof Array) {
      const varianceTotal = shiftReportData.shifts.reduce((acc, shift) => {
        return (acc += shift.difference);
      }, 0);

      setVarianceData(prev => ({
        ...prev,
        value: appendCurrency(varianceTotal.toFixed(2)),
      }));
    }
  }, [appendCurrency, shiftReportData?.shifts]);

  useEffect(() => {
    const input: ShiftQueryInput = {
      filter: filters,
      dateRange: dateRangeFilters as DateRangeFilterInput,
    };

    getShiftDifference &&
      getShiftDifference({
        variables: {
          input: {
            filter: generateFilterData(
              input?.filter as FilterValue,
              input?.dateRange as DateRangeFilterInput,
            ),
            retrieveAll: true,
          },
        },
      });
  }, [getShiftDifference, filters, dateRangeFilters]);

  return (
    <MetricCard
      key={'variance'}
      data={varianceData}
      width={'25%'}
      loading={loading}
    />
  );
};
