import { AlternateName, LOCALE, GeneralProductData } from '@hitz-group/domain';

export const updateAlternateNamesWithNewValue = (
  previousProduct: GeneralProductData,
  locale: LOCALE,
  prop: string,
  value: string,
) => {
  const index = previousProduct.alternateNames?.findIndex(
    altName => altName.locale === locale,
  );

  const alternateNames = previousProduct.alternateNames
    ? [...previousProduct.alternateNames]
    : [];

  if (index == undefined || index == -1) {
    const alternateName = {
      locale: locale,
      display: '',
      receipt: '',
      kitchen: '',
      description: '',
      [prop]: value,
    } as AlternateName;
    alternateNames.push(alternateName);
  } else {
    alternateNames[index] = { ...alternateNames[index], [prop]: value };
  }
  return {
    ...previousProduct,
    alternateNames: alternateNames,
  };
};
