import React, { useState, useCallback, useMemo, ReactElement } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
  FlatList,
  TextStyle,
} from 'react-native';
import { useFela, FelaComponent } from 'react-fela';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import IconButton from '../Button/IconButton';
import Button from '../Button/Button';
import keyBy from 'lodash/keyBy';
import scale, { isWeb } from '../../common/theme';
import Popover, { PopoverPlacement } from 'react-native-popover-view';

export interface Option {
  value: string;
  label: string;
  color: string;
}

export interface DropDownProps {
  btnTestId?: string;
  title?: string;
  options: Option[];
  selectedValue?: string;
  containerStyle?: ViewStyle;
  titleStyle?: TextStyle;
  itemStyle?: ViewStyle;
  onValueChange?: (itemValue: string, itemPosition: number) => void;
  arrowVisible?: boolean;
  PopoverViewPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | 'auto';
  extraPopoverStyle?: ViewStyle;
}

interface LabelPros {
  alignTitle: 'left' | 'right' | 'center';
  titleStyle?: TextStyle;
}

const viewStyle: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(200),
  height: theme.input.height,
});

const touchContainerStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
  borderWidth: 0.5,
  borderRadius: theme.radius.small,
  height: theme.input.height,
  paddingHorizontal: theme.padding.medium,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  lineHeight: 21,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginLeft: 'auto',
});

const labelStyle: StyleFn = ({ theme, alignTitle }) => ({
  paddingLeft: theme.padding.medium * 1.5,
  paddingBottom: theme.padding.medium,
  textAlign: alignTitle,
  ...theme.font14RegularDarkGrey,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderWidth: 1,
  borderColor: theme.colors.boxBorder,
});

const rowItemStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
  alignItems: 'center',
  paddingLeft: theme.padding.medium,
});

const colourContainer: StyleFn = ({ theme, pageColor }) => ({
  height: scale.moderateScale(18),
  width: scale.moderateScale(18),
  backgroundColor: pageColor || theme.colors.green,
  borderRadius: theme.radius.small * 3,
  right: 20,
  marginTop: theme.spacing.small / 3,
  marginLeft: theme.spacing.medium,
});

const flatListContentStyle: StyleFn = () => ({
  height: 44 * 3,
});

const optionLabelStyle: StyleFn = ({ theme }) => ({
  textTransform: undefined,
  ...theme.font14RegularCharcoal,
});

const checkBoxContainerStyle: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(150),
  justifyContent: 'flex-start',
  alignSelf: 'center',
  borderRadius: 0,
  height: scale.moderateScale(30),
  shadowColor: theme.colors.white,
});

const rightIconStyle: StyleFn = () => ({
  height: 34,
  width: 20,
  justifyContent: 'center',
  alignSelf: 'center',
  marginLeft: 'auto',
});
const hideToolTipArrowStyle: StyleFn = () => ({
  backgroundColor: 'transparent',
});
const showToolTipArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});
const popoverStyle: StyleFn = () => ({
  width: isWeb ? 280 : 200,
});
export const backgroundStyle: StyleFn = ({ theme }) => ({
  height: scale.deviceHeight,
  width: '100%',
  backgroundColor: theme.colors.transparent,
  position: 'absolute',
});

const Label: React.FC<LabelPros> = (props: LabelPros) => (
  <FelaComponent {...props} style={labelStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text style={[style, props.titleStyle]} {...props} />
    )}
  </FelaComponent>
);

const DropDown: React.FC<DropDownProps> = ({
  btnTestId,
  title,
  options,
  selectedValue,
  onValueChange,
  containerStyle,
  titleStyle,
  arrowVisible = false,
  PopoverViewPlacement = 'bottom',
  extraPopoverStyle,
}: DropDownProps) => {
  const { css, theme } = useFela();
  const [showOptions, setShowOptions] = useState(false);

  const valuesDictionary = useMemo(() => {
    return keyBy(options, 'value');
  }, [options]);

  const onToggleOptions = useCallback((): void => {
    setShowOptions(current => !current);
  }, []);

  const onPressItem = useCallback(
    (value, index): void => {
      onValueChange && onValueChange(value, index);
      onToggleOptions();
    },
    [onToggleOptions, onValueChange],
  );
  const getPopoverPlacement = useCallback(() => {
    switch (PopoverViewPlacement) {
      case 'top':
        return PopoverPlacement.TOP;
      case 'bottom':
        return PopoverPlacement.BOTTOM;
      case 'center':
        return PopoverPlacement.CENTER;
      case 'left':
        return PopoverPlacement.LEFT;
      case 'right':
        return PopoverPlacement.RIGHT;
      case 'auto':
        return PopoverPlacement.AUTO;
      default:
        break;
    }
  }, [PopoverViewPlacement]);
  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  return (
    <View style={[css(viewStyle), containerStyle]}>
      <Popover
        arrowStyle={
          arrowVisible ? css(showToolTipArrowStyle) : css(hideToolTipArrowStyle)
        }
        placement={getPopoverPlacement()}
        popoverStyle={[css(popoverStyle) && extraPopoverStyle]}
        from={
          <TouchableOpacity activeOpacity={1}>
            {title && (
              <Label alignTitle="left" titleStyle={titleStyle}>
                {title}
              </Label>
            )}
            <TouchableOpacity
              testID={btnTestId}
              style={css(touchContainerStyle)}
              onPress={onToggleOptions}
            >
              {selectedValue && (
                <View
                  style={css(
                    colourContainer({
                      theme,
                      pageColor: valuesDictionary[selectedValue]?.color,
                    }),
                  )}
                ></View>
              )}

              <Text style={css(textStyle)}>
                {(selectedValue &&
                  valuesDictionary.hasOwnProperty(selectedValue) &&
                  valuesDictionary[selectedValue]?.label) ||
                  (options.length && options[0].label) ||
                  ''}
              </Text>

              <IconButton
                icon={'AngleDown'}
                primary
                containerStyle={css(iconContainerStyle)}
                disabled
                iconColor={theme.colors.primary}
              />
            </TouchableOpacity>
          </TouchableOpacity>
        }
        backgroundStyle={css(backgroundStyle)}
        isVisible={showOptions}
        onRequestClose={() => onRequestClosePopover()}
      >
        <View style={css(dropDownStyle)}>
          <FlatList
            scrollEnabled={true}
            data={options}
            contentContainerStyle={[css(flatListContentStyle)]}
            renderItem={({ item, index }): ReactElement => {
              return (
                <View style={css(rowItemStyle)}>
                  <View
                    style={css(
                      colourContainer({
                        theme,
                        pageColor: item.color,
                      }),
                    )}
                  ></View>
                  <Button
                    title={item.label}
                    fluid
                    raised
                    key={index}
                    onPress={() => onPressItem(item.value, index)}
                    iconPosition={'right'}
                    labelStyle={[css(optionLabelStyle)] as unknown as TextStyle}
                    containerStyle={css(checkBoxContainerStyle)}
                    iconContainerStyle={css(rightIconStyle)}
                    icon={'AngleRight'}
                    iconProps={{
                      color: theme.colors.paragraph,
                      size: 24,
                    }}
                  />
                </View>
              );
            }}
          />
        </View>
      </Popover>
    </View>
  );
};

export default DropDown;
