/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import { StyleFn, OnboardingNavigationRoutes } from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { Helmet } from 'react-helmet';
import { ScrollView, Text, View, TouchableOpacity } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { Icon } from '../../../components/Icon/Icon';
import { useSession } from '../../../hooks/app/useSession';
import { useNavigation } from '@react-navigation/native';
import { noopHandler } from '../../../utils/errorHandlers';
import { Subscription } from 'rxjs';
import {
  mapOnboardingUIModel,
  OnboardingActionListUI,
  onboardingUtility,
  ONBOARDING_DISPLAY_UTIL,
} from '../../../state/onboardingUtility';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

const containerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  marginTop: theme.spacing.big,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: theme.spacing.medium * 5,
  width: '100%',
  maxWidth: 980,
});
const sectionContainerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  maxWidth: 802,
  marginBottom: theme.spacing.big,
  paddingBottom: theme.padding.medium * 3,
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.boxBorder,
});

const boxContainerStyle: StyleFn = () => ({
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  maxWidth: 802,
});

const titleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
  lineHeight: theme.spacing.big,
  color: theme.colors.primary,
});

const subtitleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  marginTop: theme.spacing.small / 2,
  lineHeight: theme.spacing.medium,
  marginBottom: theme.spacing.medium,
  color: theme.colors.text,
});

const onboardingTextStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  color: theme.colors.text,
});

const featurePointStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  lineHeight: theme.spacing.medium,
  marginBottom: theme.spacing.small,
  color: theme.colors.charcoal,
  fontWeight: '500',
});

const subTitleContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  marginTop: theme.spacing.small / 2,
  marginBottom: theme.spacing.medium,
});

const stepTile: StyleFn = ({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: theme.spacing.medium,
  paddingBottom: theme.spacing.small,
  paddingHorizontal: theme.spacing.small,
  justifyContent: 'center',
  width: 150,
  height: 170,
});

const stepTileDone: StyleFn = ({ theme }) => ({
  borderWidth: 0,
  backgroundColor: theme.colors.successLight,
});

const stepTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  marginTop: theme.spacing.small,
  lineHeight: 24,
  color: theme.colors.primary,
  maxWidth: 130,
  textAlign: 'center',
});

const titleContainerStyle: StyleFn = () => ({
  height: 80,
});

const ctaTile: StyleFn = ({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  alignItems: 'center',
  textAlign: 'center',
  paddingVertical: theme.padding.large,
  paddingHorizontal: theme.padding.small * 3,
  justifyContent: 'center',
  width: 254,
  height: 226,
});

const ctaIcon: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.highlighted,
  padding: theme.padding.large,
  borderRadius: theme.radius.small,
});

const ctaTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  marginTop: theme.spacing.medium,
  lineHeight: theme.spacing.medium,
  width: 173,
  color: theme.colors.primary,
  textAlign: 'center',
});

const ctaSubTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  marginTop: theme.spacing.small,
  lineHeight: theme.spacing.medium,
  width: 195,
  color: theme.colors.textLight,
  textAlign: 'center',
});

const integrationTile: StyleFn = ({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  width: 380,
  height: 226,
  padding: theme.spacing.medium,
});

const integrationTextLayout: StyleFn = () => ({
  flex: 2,
  justifyContent: 'center',
  height: 50,
});

const integrationTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
  lineHeight: theme.spacing.big,
  color: theme.colors.primary,
});

const integrationSubtitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  lineHeight: theme.spacing.medium,
  color: theme.colors.textLight,
});

const integrationLogo: StyleFn = ({ theme }) => ({
  flex: 1,
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.background,
  justifyContent: 'center',
  marginRight: theme.spacing.medium,
  width: 100,
});

export const OnboardingSection: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const [session] = useSession();

  const navigateTo = useCallback(
    action => {
      switch (action) {
        case OnboardingNavigationRoutes.NAVIGATE_TO_VENUE:
          if (session?.user?.venues) {
            navigation.navigate('Settings', {
              screen: 'VenueSettings',
              params: {
                venueId: session?.user?.venues[0].id,
              },
            });
          }
          break;
        case OnboardingNavigationRoutes.NAVIGATE_TO_STORE_REGISTER:
          if (session?.user?.venues && session?.user?.venues[0].stores) {
            navigation.navigate('Settings', {
              screen: 'StoreSettings',
              params: {
                screen: 'RegisterProfiles',
                venueId: session?.user?.venues[0].id,
                storeId: session?.user?.venues[0].stores[0].id,
              },
            });
          }
          break;
        case OnboardingNavigationRoutes.NAVIGATE_TO_TAXES:
          navigation.navigate('Settings', { screen: 'TaxesFees' });
          break;
        case OnboardingNavigationRoutes.NAVIGATE_TO_PAYMENT_TYPES:
          navigation.navigate('Settings', { screen: 'PaymentTypes' });
          break;
        case OnboardingNavigationRoutes.NAVIGATE_TO_PAGES:
          navigation.navigate('Products', { screen: 'Pages' });
          break;
        case OnboardingNavigationRoutes.NAVIGATE_TO_PRODUCTS:
          navigation.navigate('Products', { screen: 'Manage' });
          break;
        default:
          noopHandler();
      }
    },
    [navigation, session?.user?.venues],
  );

  const [actionList, setActionList] = useState<OnboardingActionListUI[]>([]);

  useEffect(() => {
    const subscription: Subscription =
      onboardingUtility.getOnboardingInfo$.subscribe(onboarding => {
        if (onboarding) {
          const data = mapOnboardingUIModel(onboarding);
          if (Object.keys(data).length > 0) {
            const steps = Object.values(data)
              .flat()
              .map(section => {
                return {
                  ...section,
                  isCompleted: section.checklist.every(
                    actionSet => actionSet.status === true,
                  ),
                  label:
                    ONBOARDING_DISPLAY_UTIL[section.sectionKey].translationKey,
                  action: () =>
                    navigateTo(
                      ONBOARDING_DISPLAY_UTIL[section.sectionKey].navigateTo,
                    ),
                };
              })
              .sort(section => section.order);
            setActionList(steps);
          }
        }
      });

    return () => subscription.unsubscribe();
  }, [navigateTo]);

  const stepsProgressText = `${
    actionList.filter(x => x.isCompleted).length
  } / ${actionList.length}`;
  const allCompleted = useMemo(
    () => actionList.every(x => x.isCompleted),
    [actionList],
  );
  if (allCompleted) return <></>;
  return (
    <View style={css(sectionContainerStyle)}>
      <Text testID="onboarding-title" style={css(titleStyle)}>
        {translate('home.getStarted')}
      </Text>
      <View style={css(subTitleContainerStyle)}>
        <Text style={css(onboardingTextStyle)}>
          {translate('home.onboardingSectionTitle')}
        </Text>
        <Text style={{ textAlign: 'right' }}>
          {`${stepsProgressText} ${translate('home.completed')}`}
        </Text>
      </View>
      <View style={css(boxContainerStyle)}>
        {actionList.map(step => (
          <OnboardingStepItem key={step.sectionKey} step={step} />
        ))}
      </View>
    </View>
  );
};

export interface OnboardingStepItemProps {
  step: OnboardingActionListUI;
}

export const OnboardingStepItem: React.FC<OnboardingStepItemProps> = ({
  step,
}) => {
  const { css, theme } = useFela();
  const itemStyle = step.isCompleted
    ? css(stepTile, stepTileDone)
    : css(stepTile);
  const { translate } = useTranslation();

  return (
    <TouchableOpacity style={itemStyle} onPress={() => step.action()}>
      <Icon
        name="CheckCircle"
        color={
          step.isCompleted ? theme.colors.success : theme.colors.paragraphLight
        }
        size={theme.fontSize.medium * 1.5}
      />
      <View style={css(titleContainerStyle)}>
        <Text style={css(stepTitle)}>{translate(step.label)}</Text>
      </View>
      <Icon
        name={step.isCompleted ? 'Check' : 'ArrowRight'}
        color={theme.colors.green}
        size={theme.fontSize.medium * 1.5}
      />
    </TouchableOpacity>
  );
};

export const CallToActions: React.FC = () => {
  const { css } = useFela();
  const callToActionItems = [
    {
      icon: 'QuestionCircle',
      title: 'Help & Support',
      description: 'Need a helping hand with setup? Visit our help center.',
    },
    {
      icon: 'Desktop',
      title: 'Looking for Hardware?',
      description: 'Get the best POS hardware for your business.',
    },
    {
      icon: 'Gift',
      title: 'Refer To Friend',
      description: 'Love Till? Share with your friend and get $100 each.',
    },
  ];
  return (
    <View style={css(sectionContainerStyle, boxContainerStyle)}>
      {callToActionItems.map(CallToActionItem)}
    </View>
  );
};

export interface CallToActionItemProps {
  icon: string;
  title: string;
  description: string;
}

export const CallToActionItem: React.FC<CallToActionItemProps> = ({
  icon,
  title,
  description,
}) => {
  const { css, theme } = useFela();
  return (
    <View style={css(ctaTile)} key={title}>
      <View style={css(ctaIcon)}>
        <Icon name={icon} color={theme.colors.blue} />
      </View>
      <Text style={css(ctaTitle)}>{title}</Text>
      <Text style={css(ctaSubTitle)}>{description}</Text>
    </View>
  );
};

export interface IntegrationTileProps {
  logos: string[];
  title: string;
  description: string;
}

export const IntegrationItem: React.FC<IntegrationTileProps> = ({
  logos,
  title,
  description,
}) => {
  const { css } = useFela();
  return (
    <View style={css(integrationTile)} key={title}>
      <View style={css(boxContainerStyle)}>
        {logos.map((_, index) => {
          return (
            <View
              key={`${title.replace(/\s/g, '').toLowerCase()}-${index}`}
              style={css(integrationLogo)}
            ></View>
          );
        })}
      </View>
      <View style={css(integrationTextLayout)}>
        <Text style={css(integrationTitle)}>{title}</Text>
        <Text style={css(integrationSubtitle)}>{description}</Text>
      </View>
    </View>
  );
};

export const IntegrationsSection: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const integrations = [
    {
      logos: ['', '', '', ''],
      title: 'Online Orders',
      description:
        'Manage your accounts and online orders easily with these integrations.',
    },
    {
      logos: ['', '', '', ''],
      title: 'Payments',
      description:
        'Improve your service with tightly integrated payment solutions.',
    },
  ];
  return (
    <View style={css(sectionContainerStyle)}>
      <Text style={css(titleStyle)}>{translate('home.integrationsTitle')}</Text>
      <Text style={css(subtitleStyle)}>
        {translate('home.integrationsDescription')}
      </Text>
      <View style={css(boxContainerStyle)}>
        {integrations.map(IntegrationItem)}
      </View>
    </View>
  );
};

export const UpcomingItem: React.FC<string> = text => {
  const { css } = useFela();
  return (
    <Text
      style={css(featurePointStyle)}
      key={text}
    >{`• Feature – ${text}`}</Text>
  );
};

export const UpcomingSection: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const newFeatures = [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'Ut enim ad minim veniam, quis nostrud exercitation ullamcolaboris nisi ut aliquip ex ea commodo consequat.',
    'Duis aute irure dolor in reprehenderit in voluptate velitesse cillum dolore eu fugiat nulla pariatur',
    'xcepteur sint occaecat cupidatat non proident, sunt inculpa qui officia deserunt mollit anim id est laborum.',
    'Sed ut perspiciatis unde omnis iste natus error sitvoluptatem accusantium doloremque laudantium.',
  ];
  return (
    <View style={css(sectionContainerStyle)}>
      <Text style={css(titleStyle)}>
        {translate('home.upcomingSectionTitle')}
      </Text>
      <Text style={css(subtitleStyle)}>
        {translate('home.upcomingSectionSubTitle')}
      </Text>
      <View>{newFeatures.map(UpcomingItem)}</View>
    </View>
  );
};

export const OverviewScreen: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.overviewPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView testID="overview-screen" style={css(pageStyle)}>
        <View style={css(containerStyle)}>
          <OnboardingSection />
          <CallToActions />
          <IntegrationsSection />
          <UpcomingSection />
        </View>
      </ScrollView>
    </>
  );
};
