import { StyleFn } from '@hitz-group/domain';
import { isAndroid, isWeb } from '../../common/theme';

export const rowStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.medium * 1.5,
  borderRadius: theme.radius.small,
});

export const orderContainer: StyleFn = ({ theme }) => ({
  flex: 0.5,
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing.small * 2.5,
});

export const dateTimeContainer: StyleFn = ({ theme }) => ({
  width: 151,
  alignItems: 'flex-start',
  marginRight: theme.spacing.small * 2.5,
});

export const customerContainer: StyleFn = ({ theme }) => ({
  flex: 0.5,
  alignItems: 'flex-start',
  marginRight: theme.spacing.small * 2.5,
});

export const orderTypeContainer: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing.small,
});

export const payTypeContainer: StyleFn = ({ theme }) => ({
  width: 100,
  flex: 0.3,
  alignItems: 'center',
  marginRight: theme.spacing.small * 2.5,
});

export const totalValueContainer: StyleFn = ({ theme }) => ({
  width: 155,
  alignItems: 'center',
  marginRight: theme.spacing.small * 2.5,
  right: 15,
});

export const actionsContainer: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  width: isAndroid ? 30 : 20,
  alignItems: 'center',
  justifyContent: 'center',
  marginHorizontal: theme.spacing.small * 3.5,
});

export const actionIconContainerStyle: StyleFn = ({ theme }) => ({
  marginHorizontal: theme.spacing.small / 2,
  borderRadius: theme.radius.small,
});

export const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  ...theme.font14Medium,
  textTransform: 'capitalize',
  textAlign: 'right',
  width: '100%',
});

export const refundOrderTextColor: StyleFn = ({ theme }) => ({
  color: theme.colors.redNegative,
});
export const customerTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  ...theme.font14Medium,
  textTransform: 'capitalize',
  textAlign: 'left',
  width: '100%',
});
export const payTypeTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  ...theme.font14Medium,
  textTransform: 'capitalize',
  textAlign: 'left',
  width: '100%',
});

export const dateTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  ...theme.font14Medium,
  textTransform: 'capitalize',
});
export const labelText: StyleFn = ({ theme, isDineInOrder }) => ({
  ...theme.font14RegularCharcoal,
  ...theme.font14SemiBold,
  color: isDineInOrder ? theme.colors.teal : theme.colors.blue,
});

export const columnContainerStyle: StyleFn = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.padding.medium * 1.5,
  marginBottom: 10,
  marginTop: isWeb ? 0 : 10,
  marginRight: 0,
  height: 44,
  borderBottomWidth: 0,
  borderRadius: theme.radius.small,
});
