import React, {
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useFela } from 'react-fela';
import { TableStyles } from '../styles/Component.styles';
import DataGrid, {
  columnStyleOptions,
  columnForDataGrid,
} from '../../../../components/DataGrid/DataGrid';
import { View, Text } from 'react-native';
import {
  Widget,
  StyleFn,
  DateRangeGranularity,
  FilterValue,
  ColumnType,
} from '@hitz-group/domain';
import { useReporting } from '../../../../hooks/app/useReporting';
import { CubejsApi, ResultSet } from '@cubejs-client/core';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../../hooks/Notification';
import { useCurrency } from '@hitz-group/localization';
import { useTranslation } from '@hitz-group/localization';
import { writeToString } from '@fast-csv/format';
import { buildURI, downloadFile } from '../../../../utils/csv';
import { IMap, PivotTableData, ReportTableColumn } from '../types';
import {
  arePropsEqual,
  convertDateByFormat,
  MAX_ROW_HEIGHT,
} from '../reportsHelper';
import { ExportDetailedSummary } from '../SalesSummary/UIComponents/ExportDetailedSummary';
import { endOfMonth, endOfWeek } from 'date-fns';
import { TableRowPreview } from './TableRowPreview';
import cloneDeep from 'lodash/cloneDeep';

export interface TableRef {
  exportData: (fileName?: string) => void;
}

interface TableProps {
  widget: Widget;
  columns: ReportTableColumn[];
  helper: string;
  pageSize?: number;
  granularityFormats: IMap<string>;
  filters?: FilterValue;
  onTableRowPress?: (item: PivotTableData) => void;
  dataTransformationFn?: (
    data: PivotTableData,
    columns: ReportTableColumn[],
  ) => PivotTableData;
  cubejsApi: CubejsApi;
  updateCount: number;
  cellDataLeftAligned?: boolean;
}

const rowCellTextAlignment: StyleFn = () => ({
  justifyContent: 'flex-start',
});

const headerCellTextAlignment: StyleFn = () => ({
  textAlign: 'left',
});

const dataItemContainerStyle: StyleFn = ({ theme }) => ({
  flexWrap: 'nowrap',
  backgroundColor: theme.colors.white,
  padding: theme.padding.small,
  paddingTop: 0,
  paddingBottom: 0,
  height: 34,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const dataRowStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.small,
  paddingBottom: theme.padding.small,
  paddingLeft: theme.padding.medium,
  maxHeight: MAX_ROW_HEIGHT,
  overflow: 'hidden',
});

const headerStyle: StyleFn = ({ theme }) => ({
  height: 44,
  fontSize: theme.fontSize.small,
  borderStyle: 'solid',
  marginTop: theme.spacing.small,
  paddingBottom: theme.padding.medium,
  paddingLeft: theme.padding.medium,
  backgroundColor: theme.colors.greyLight,
});

const paginationStyle: StyleFn = () => ({
  bottom: -5,
});

const TableNameStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  letterSpacing: 0,
  fontFamily: theme.font.medium,
  lineHeight: 32,
  textAlign: 'left',
});

const HeaderParentStyle: StyleFn = () => ({
  margin: 0,
});

const RowTextStyle: StyleFn = () => ({
  color: 'inherit',
});

const TableComponent: React.FC<TableProps & { ref?: React.Ref<TableRef> }> =
  forwardRef(
    (
      {
        widget,
        columns,
        helper,
        pageSize,
        granularityFormats,
        filters,
        onTableRowPress,
        dataTransformationFn = undefined,
        cubejsApi,
        cellDataLeftAligned,
      },
      ref,
    ) => {
      const { translate } = useTranslation();
      const { showNotification } = useNotification();
      const { css } = useFela();
      const { appendCurrency } = useCurrency();

      const { loading, error, widgetData, getWidgetData } =
        useReporting(cubejsApi);

      const scrollStyle: StyleFn = () => ({
        height: !pageSize || (pageSize && pageSize > 5) ? 350 : 224,
      });

      useEffect((): void => {
        if (error) {
          showNotification({
            error: true,
            message: error,
          });
        }
      }, [error, showNotification]);

      useEffect(() => {
        widget.query && getWidgetData(widget, helper);
      }, [widget, getWidgetData, helper]);
      const widgetDateRangeFilters =
        widget.query?.dateRangeFilters || undefined;
      const granularity =
        (widgetDateRangeFilters &&
          widgetDateRangeFilters.length > 0 &&
          widgetDateRangeFilters[0].granularity) ||
        DateRangeGranularity.DAY;

      const columnsCopy = cloneDeep(columns) as ReportTableColumn[];
      const columnsExtras: IMap<Partial<ReportTableColumn>> = useMemo(() => {
        const typesObj: IMap<Partial<ReportTableColumn>> = {};
        columnsCopy.forEach(column => {
          typesObj[column.dataKey || column.key] = {
            type: column.type,
            blankValue: column.blankValue,
            textStyleCallback: column.textStyleCallback,
          };
        });
        return typesObj;
      }, [columnsCopy]);

      const COLUMNS = columnsCopy.map((column: ReportTableColumn) => {
        return {
          columnDataKey: column.dataKey || column.key,
          columnName:
            [ColumnType.EXPORT, ColumnType.PREVIEW].indexOf(
              column.key as ColumnType,
            ) === -1
              ? translate(column.title)
              : '',
          columnStyle: column.style || columnStyleOptions.SMALL,
        } as columnForDataGrid;
      });

      const columnsStyles: IMap<string | undefined> = useMemo(() => {
        const typesObj: IMap<string | undefined> = {};
        COLUMNS.forEach((column: columnForDataGrid) => {
          typesObj[column.columnName as string] = column.columnStyle;
        });
        return typesObj;
      }, [COLUMNS]);

      const styles = TableStyles();

      const data = useMemo(() => {
        if (!widgetData || !widget?.name || !widgetData[widget.name])
          return {} as ResultSet;
        return widgetData[widget.name] as ResultSet;
      }, [widgetData, widget]);

      const getRowText = useCallback(
        (
          item,
          columnDataKey: string,
          type?: ColumnType,
          blankValue?: string,
        ): string => {
          let rowText = item?.[columnDataKey];

          switch (type) {
            case ColumnType.MONEY:
              rowText = appendCurrency(item?.[columnDataKey] || '0.00');
              break;
            case ColumnType.NUMBER:
              rowText = `${item?.[columnDataKey] || '0.00'}`;
              break;
            case ColumnType.PERCENT:
              rowText = `${parseFloat(
                `${item?.[columnDataKey] || '0.00'}`,
              ).toFixed(2)}%`;
              break;
            case ColumnType.STRING:
              rowText = `${item?.[columnDataKey] || blankValue}`;
              break;
            case ColumnType.DATE:
              rowText = item?.[columnDataKey]
                ? convertDateByFormat(
                    item?.[columnDataKey],
                    granularity,
                    granularityFormats[granularity],
                    widget.query?.timezone,
                  )
                : '';
              break;
            case ColumnType.ENUM:
              rowText = item?.[columnDataKey]
                ? translate(`enums.${item?.[columnDataKey]}`)
                : '';
              break;
          }
          return rowText;
        },
        [
          appendCurrency,
          granularity,
          granularityFormats,
          widget.query?.timezone,
          translate,
        ],
      );

      const renderDataItem = useCallback(
        (
          item,
          columnDataKey: string,
          columnStyle?: string,
        ): React.ReactNode => {
          const { textStyleCallback, type, blankValue } =
            columnsExtras[columnDataKey] || {};
          let textStyle = RowTextStyle;

          if (textStyleCallback) {
            textStyle = textStyleCallback(item);
          }

          return (
            <View
              style={css(dataItemContainerStyle, {
                width: columnStyle == columnStyleOptions.SMALL ? 90 : 180,
              })}
            >
              <Text
                numberOfLines={1}
                ellipsizeMode={'tail'}
                style={css(textStyle)}
              >
                {getRowText(
                  item,
                  columnDataKey,
                  type as ColumnType,
                  blankValue,
                )}
              </Text>
            </View>
          );
        },
        [columnsExtras, css, getRowText],
      );

      const exportSalesSummaryDetails = useCallback(
        (item): React.ReactNode => {
          const range = { from: '', to: '' };
          const firstColKey = columnsCopy[0].key;
          const dateValue = item[firstColKey];
          let date;

          if (dateValue) {
            switch (granularity) {
              case DateRangeGranularity.DAY:
                date = dateValue.split('T')[0];
                range.from = date;
                range.to = date;
                break;
              case DateRangeGranularity.WEEK:
                range.from = dateValue.split('T')[0];
                range.to = endOfWeek(new Date(dateValue))
                  .toISOString()
                  .split('T')[0];
                break;
              case DateRangeGranularity.MONTH:
                range.from = dateValue.split('T')[0];
                range.to = endOfMonth(new Date(dateValue))
                  .toISOString()
                  .split('T')[0];
                break;
            }
          }

          return (
            <ExportDetailedSummary
              filters={filters as FilterValue}
              range={range}
            />
          );
        },
        [filters, granularity, columnsCopy],
      );

      const renderOptionItems = useCallback(
        (item, key: string): React.ReactNode => {
          const togglePreview = () => {
            onTableRowPress && onTableRowPress(item as PivotTableData);
          };

          if (key === ColumnType.EXPORT) {
            return exportSalesSummaryDetails(item);
          } else {
            return <TableRowPreview onTableRowPress={togglePreview} />;
          }
        },
        [onTableRowPress, exportSalesSummaryDetails],
      );

      const renderHeaderItem = useCallback(
        (item: string): React.ReactNode => {
          const columnStyle = columnsStyles[item];
          return (
            <View
              style={css(HeaderParentStyle, {
                width: columnStyle == columnStyleOptions.SMALL ? 90 : 180,
              })}
            >
              <Text style={css(TableNameStyle)}>{item}</Text>
            </View>
          );
        },
        [css, columnsStyles],
      );

      const serializedData = useMemo(() => {
        const tablePivot = (data.tablePivot && data.tablePivot()) || [];
        if (dataTransformationFn && tablePivot && tablePivot.length > 0) {
          return dataTransformationFn(tablePivot, columns);
        }
        return tablePivot;
      }, [data, dataTransformationFn, columns]);

      const exportData = useCallback(
        async fileName => {
          const rows = serializedData.map(rowData => {
            return COLUMNS.filter(
              (col: columnForDataGrid) => col.columnName,
            ).map(
              (_col: columnForDataGrid) =>
                `${getRowText(rowData, _col.columnDataKey)}`,
            );
          });

          const csvData = await writeToString(rows, {
            delimiter: ',',
            rowDelimiter: '\n',
            quote: '"',
            headers: COLUMNS.filter(
              (col: columnForDataGrid) => col.columnName,
            ).map((col: columnForDataGrid) => `${col.columnName}`),
          });

          const uri = buildURI(csvData);
          downloadFile(uri, fileName);
        },
        [serializedData, COLUMNS, getRowText],
      );

      useImperativeHandle(ref, () => ({ exportData }));

      return (
        <View style={styles.pageStyle}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <DataGrid
              columns={COLUMNS}
              data={serializedData}
              renderDataItem={renderDataItem}
              pageSize={pageSize || 10}
              dataRowStyle={css(dataRowStyle)}
              scrollStyle={css(scrollStyle)}
              headerStyle={css(headerStyle)}
              paginationStyle={css(paginationStyle)}
              renderHeaderItem={renderHeaderItem}
              renderOptionItems={renderOptionItems}
              rowCellTextAlignment={
                cellDataLeftAligned && css(rowCellTextAlignment)
              }
              headerCellTextAlignment={
                cellDataLeftAligned && css(headerCellTextAlignment)
              }
            />
          )}
        </View>
      );
    },
  );

TableComponent.displayName = 'Table';

export const Table = React.memo(TableComponent, arePropsEqual);
