import React, { useEffect, useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import IconButton from '../../../../../components/Button/IconButton';
import Button from '../../../../../components/Button/Button';
import { CreateFeeModal } from '../../../../../components/Modals/Fees/CreateFeeModal';
import { DeleteFeeModal } from '../../../../../components/Modals/Fees/DeleteFeeModal';
import { GET_FEES_DETAILS, UPDATE_FEES } from '../../../../../graphql/settings';
import { useNotification } from '../../../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import {
  Fee,
  StyleFn,
  RateType,
  UpdateFeeInput,
  Tax,
} from '@hitz-group/domain';
import { Helmet } from 'react-helmet';
import DropDown from '../../../../../components/FormInput/DropDown';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import { sentenceCase } from 'change-case';
import { TaxesGroupSection } from '../Taxes/TaxesGroupSection';
import {
  GET_TAXES_DETAILS,
  DELETE_TAX_DETAILS,
} from '../../../../../graphql/settings';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import scale, { isWeb } from '../../../../../common/theme';
import {
  isValidPrice,
  isLessThanLimit,
  isFloat,
} from '../../../../../utils/validator';
interface TaxToTax {
  id: string;
  name: string;
  code: string;
  rate?: string;
  isActive?: boolean;
  taxes: Tax[];
}

export interface FeeRowProps {
  fee: Fee;
  deleteInStateWhenDeleted: (index: number, id: string) => void;
  index: number;
  onChange: (index: number, prop: string, value: string) => void;
}

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(27),
});

export const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: isWeb ? '70%' : '100%',
  alignSelf: 'center',
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const inputContainerStyle: StyleFn = ({ theme }) => ({
  width: 240,
  height: theme.input.height,
  justifyContent: 'center',
  marginLeft: theme.spacing.small,
});

const formInputContainerSmallStyle: StyleFn = ({ theme }) => ({
  width: 80,
  height: theme.input.height,
  paddingLeft: theme.padding.small,
});

const rateFormInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: theme.input.height,
});

export const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  width: 240,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
});
export const dropdownViewStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  width: 240,
  marginLeft: theme.spacing.small,
  marginTop: theme.spacing.small / 2,
});

const angleDownIconStyle: StyleFn = () => ({
  right: 5,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: 240,
  height: theme.input.height,
});
export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: 240,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});

const prefixItemStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  padding: theme.spacing.small / 2 + 6,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.bold,
  borderRadius: theme.radius.small,
  lineHeight: 15,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small,
  marginLeft: theme.spacing.small,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const scrollStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.padding.medium / 2,
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

const FeeRow: React.FC<FeeRowProps> = ({
  fee,
  deleteInStateWhenDeleted,
  onChange,
  index,
}: FeeRowProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showModal } = useModal();
  const { formatCurrency } = useCurrency();
  const currencySymbol = formatCurrency(0).split('0.00')[0];
  const percentageSymbol = '%';

  const onPressDelete = useCallback(
    (index: number, id: string, name: string): void => {
      showModal(
        <DeleteFeeModal
          index={index}
          id={id}
          name={name}
          onDeleteCallBack={deleteInStateWhenDeleted}
        />,
      );
    },
    [showModal, deleteInStateWhenDeleted],
  );

  const onChangeRate = useCallback(
    (value: string) => {
      let extractedValue;
      if (fee.rateType === RateType.FIXED) {
        extractedValue = value.includes(currencySymbol)
          ? value.split(currencySymbol)[1]
          : value;
      } else {
        extractedValue = value.includes(percentageSymbol)
          ? value.split(percentageSymbol)[0]
          : value;
      }

      onChange(index, 'rate', extractedValue);
    },
    [onChange, index, currencySymbol, fee],
  );

  const formattedRate =
    fee.rateType === RateType.FIXED
      ? `${currencySymbol}${fee.rate}`
      : `${fee.rate}${percentageSymbol}`;

  return (
    <View style={css(rowStyle)}>
      <FormInput
        testID="code"
        placeholder={translate('backOfficeSettings.code')}
        value={fee.code}
        containerStyle={css(formInputContainerSmallStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(null, index, 'code')}
        maxLength={6}
      />
      <FormInput
        testID="fee-name"
        placeholder={translate('backOfficeSettings.feeName')}
        value={fee.name}
        containerStyle={css(inputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(null, index, 'name')}
        maxLength={50}
      />

      <DropDown
        values={Object.values(RateType).map(value => ({
          value: value,
          label: sentenceCase(value),
        }))}
        style={css(dropDownStyle)}
        extraMainViewStyle={css(dropDownMainViewStyle)}
        extraStyle={css(dropdownExtraStyle)}
        extraViewStyle={css(dropdownViewStyle)}
        onValueChange={onChange.bind(null, index, 'rateType')}
        selectedValue={fee.rateType}
        testID="rate-type"
        angleDownIconStyle={css(angleDownIconStyle)}
      />
      <View style={css(flexStyle)}></View>
      <FormInput
        testID="fee-rate"
        placeholder={translate('backOfficeSettings.feeRate')}
        value={formattedRate}
        containerStyle={css(rateFormInputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChangeRate}
        keyboardType={'numeric'}
        prefix={{
          text: '%',
          textStyle: css(prefixItemStyle),
        }}
      />
      <IconButton
        icon="TrashAlt"
        iconSize={24}
        containerSize={34}
        containerStyle={css(closeIconContainerStyle)}
        iconStyle={css(closeIconStyle)}
        onPress={(): void => onPressDelete(index, fee.id, fee.name)}
      />
    </View>
  );
};

interface DeleteModalProps {
  index: number;
  id: string;
  name: string;
  onDeleteCallBack: (index: number, id: string) => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  id,
  name,
  index,
  onDeleteCallBack,
}: DeleteModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [deleteTax, deletedTax] = useMutation(DELETE_TAX_DETAILS, {
    onError: noopHandler,
  });

  useEffect((): void => {
    if (deletedTax.error) {
      showNotification({
        error: true,
        message: parseApolloError(deletedTax.error),
      });
    }
  }, [deletedTax.error, showNotification]);

  useEffect((): void => {
    if (deletedTax.data) {
      closeModal();
      onDeleteCallBack(index, id);
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: name,
        }),
      });
    }
  }, [
    onDeleteCallBack,
    index,
    id,
    deletedTax.data,
    showNotification,
    closeModal,
    name,
    translate,
  ]);

  const onDeleteTax = useCallback((): void => {
    deleteTax({ variables: { id: id } });
  }, [deleteTax, id]);

  return (
    <ConfirmationDialog
      title={translate('dialog.deleteTitle')}
      message={translate('dialog.deleteConfirmation', { label: name })}
      onConfirm={onDeleteTax}
    />
  );
};

export const feeRateValidation = (fee: Fee) => {
  if (fee.rateType === RateType.FIXED && !isValidPrice(String(fee.rate))) {
    return false;
  } else if (
    fee.rateType === RateType.PERCENTAGE &&
    !isLessThanLimit(String(fee.rate), 100.0)
  ) {
    return false;
  } else return true;
};

export const FeesSection: React.FC = () => {
  const { css, theme } = useFela();
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const [feeForm, setFeeForm] = useState([] as Fee[]);
  const feeDetails = useQuery(GET_FEES_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateFee, updateFeeRequest] = useMutation(UPDATE_FEES, {
    onError: noopHandler,
  });

  const [taxesData, setTaxesData] = useState([] as TaxToTax[]);

  const taxesDetails = useQuery(GET_TAXES_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect((): void => {
    if (taxesDetails.data) {
      setTaxesData(taxesDetails.data.taxes);
    }
  }, [taxesDetails.data]);

  useEffect((): void => {
    if (updateFeeRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(updateFeeRequest.error),
      });
    }
  }, [updateFeeRequest.error, showNotification]);

  useEffect((): void => {
    if (updateFeeRequest.data) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyUpdated'),
      });
    }
  }, [updateFeeRequest.data, showNotification, translate]);

  useEffect((): void => {
    if (feeDetails.error) {
      showNotification({
        error: true,
        message: parseApolloError(feeDetails.error),
      });
    }
  }, [feeDetails.error, showNotification]);

  useEffect((): void => {
    if (feeDetails.data) {
      setFeeForm(feeDetails.data.fees);
    }
  }, [feeDetails.data]);

  const onDeleteUpdateState = useCallback(
    (index: number, id: string): void => {
      const tempFeeData = [...feeForm];
      if (tempFeeData[index].id === id) {
        tempFeeData.splice(index, 1);
        setFeeForm(tempFeeData);
      }
    },
    [feeForm],
  );

  const onAddUpdateState = useCallback(
    (fee: Fee): void => {
      const tempFeeData = [...feeForm];
      tempFeeData.push(fee);
      setFeeForm(tempFeeData);
    },
    [feeForm],
  );

  const onPressCreateNewVenue = useCallback(() => {
    showModal(
      <CreateFeeModal onAddFee={onAddUpdateState} />,
      translate('backOfficeSettings.createFee'),
    );
  }, [showModal, onAddUpdateState, translate]);

  const onChange = useCallback((index: number, prop, value): void => {
    setFeeForm(prevForm => {
      const tempPrevForm = [...prevForm];
      tempPrevForm[index] = {
        ...tempPrevForm[index],
        [prop]: value,
      };
      return tempPrevForm;
    });
  }, []);

  const onPressSave = useCallback(() => {
    const input = feeForm.map<UpdateFeeInput>(fee => ({
      ...stripProperties(fee, '__typename'),
      rate: parseFloat(fee.rate?.toString() || '0'),
    }));
    if (input.some(fee => !fee.name || !fee.code)) {
      showNotification({
        error: true,
        message: translate('backOfficeDevices.fieldsMissing'),
      });
    } else if (
      feeForm.some(
        fee =>
          fee.rate &&
          (!isFloat(String(fee.rate)) || !feeRateValidation(fee as Fee)),
      )
    ) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'Rate' }),
      });
    } else {
      updateFee({
        variables: {
          input,
        },
      });
    }
  }, [feeForm, updateFee, translate, showNotification]);

  const onChangeTaxesGroup = useCallback(
    (index: number, prop, value): void => {
      const taxesDataTemp = [...taxesData];
      if (Array.isArray(value)) {
        if (!value.length) {
          const taxSelected = taxesDataTemp[index];
          showNotification({
            error: true,
            message: translate('backOfficeTaxes.atLeastOneTaxMustExist', {
              name: taxSelected.name,
            }),
          });
        } else {
          const taxesFiltered = taxesDataTemp.filter(tax =>
            value.includes(tax.id),
          ) as unknown as Tax[];
          taxesDataTemp[index] = {
            ...taxesDataTemp[index],
            taxes: taxesFiltered,
          };
        }
      } else {
        taxesDataTemp[index] = { ...taxesDataTemp[index], [prop]: value };
      }
      setTaxesData(taxesDataTemp);
    },
    [taxesData, showNotification, translate],
  );

  const deleteInStateWhenDeleted = useCallback(
    (index: number, id: string): void => {
      const tempTaxData = [...taxesData];
      if (tempTaxData[index].id === id) {
        tempTaxData.splice(index, 1);
        setTaxesData(tempTaxData);
      }
    },
    [taxesData],
  );
  const onAddTax = useCallback(
    (tax: TaxToTax): void => {
      const tempTaxData = [...taxesData];
      tempTaxData.push(tax);
      setTaxesData(tempTaxData);
    },
    [taxesData],
  );
  const onPressDelete = useCallback(
    (index: number, id: string, name: string): void => {
      showModal(
        <DeleteModal
          index={index}
          id={id}
          name={name}
          onDeleteCallBack={deleteInStateWhenDeleted}
        />,
      );
    },
    [showModal, deleteInStateWhenDeleted],
  );
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollStyle)}>
          <BackOfficeSection
            title={translate('backOfficeSettings.fees')}
            action={
              <BackOfficeCreateNewButton onPress={onPressCreateNewVenue} />
            }
            contentContainerStyle={css(formStyle)}
            containerStyle={css(containerStyle)}
          >
            <TableComponent
              columnContainerStyle={css(columnContainerStyle)}
              columns={[
                {
                  title: translate('backOfficeSettings.code'),
                  width: 80,
                  containerStyle: { paddingLeft: 15 },
                },
                {
                  title: translate('backOfficeSettings.feeName'),
                  width: 250,
                  alignItems: 'flex-start',
                  containerStyle: { paddingLeft: theme.spacing.medium },
                },
                {
                  title: translate('backOfficeSettings.rateType'),
                  flex: 1,
                  alignItems: 'flex-start',
                  containerStyle: { paddingLeft: theme.spacing.medium },
                },
                {
                  title: translate('backOfficeSettings.feeRate'),
                  width: 100,
                },
                { title: '', width: 44 },
              ]}
              data={feeForm}
              normalRows
              renderRow={(item: Fee, index: number): React.ReactNode => (
                <FeeRow
                  key={index}
                  fee={item}
                  deleteInStateWhenDeleted={onDeleteUpdateState}
                  onChange={onChange}
                  index={index}
                />
              )}
            />
          </BackOfficeSection>
          <TaxesGroupSection
            taxes={taxesData}
            onChange={onChangeTaxesGroup}
            openDeleteModal={onPressDelete}
            onAddTax={onAddTax}
            isFeeModal={true}
          />
          <View style={css(bottomSpace)}></View>
        </ScrollView>
      </View>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
