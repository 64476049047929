import { Subject } from 'rxjs';
import { OrderEvent } from '@hitz-group/domain';

export interface CreateKotEventsInstanceProps {
  publishToKotUtil: (data: KitchenPrintEvent) => void;
  events: Subject<KitchenPrintEvent>;
}

export interface KitchenPrintEvent {
  orderId: string;
  preEvents: OrderEvent[];
}

/*eslint prefer-const: 0*/
let kitchenOrderEvents: CreateKotEventsInstanceProps;

/**
 * TODO: a basic and minimal implementation
 */
export const initKitchenOrderEvents = () => {
  if (kitchenOrderEvents) {
    return kitchenOrderEvents;
  }
  const events = new Subject<KitchenPrintEvent>();

  const publishToKotUtil = (data: KitchenPrintEvent) => {
    events.next(data);
  };

  return {
    publishToKotUtil,
    events,
  } as CreateKotEventsInstanceProps;
};

kitchenOrderEvents = initKitchenOrderEvents();

export default kitchenOrderEvents;
