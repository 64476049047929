import { StyleFn, IntegrationApps } from '@hitz-group/domain';
import React, { useEffect, useMemo } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { View, ScrollView } from 'react-native';
import { useStores } from '../../../../../../hooks/app/useStores';
import TableComponent from '../../../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import { useAccountingIntegration } from '../../../../../../hooks/app/accountingIntegrations/useAccountingIntegration';
import { useNotification } from '../../../../../../hooks/Notification';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import { XeroStoreRow } from './XeroStoreRow';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 600,
});

const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  paddingLeft: theme.spacing.big,
  alignItems: 'center',
  flex: 1,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

export const SettingsTab: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const { stores, getStores, loading: storesLoading, error } = useStores();
  const storeIds = useMemo(() => Object.keys(stores), [stores]);

  const {
    accountingIntegrationStoresSettings,
    getAccountingIntegrationStoresSettings,
    toggleAccountingIntegrationStoreConnection,
    loading: accountingIntegrationLoading,
  } = useAccountingIntegration();

  useEffect(() => {
    getStores();
  }, [getStores]);

  useEffect(() => {
    getAccountingIntegrationStoresSettings(storeIds, IntegrationApps.XERO);
  }, [getAccountingIntegrationStoresSettings, storeIds]);

  const storesConnectionStatus = storeIds.map(storeId => {
    const storeSettings = accountingIntegrationStoresSettings.find(
      storeSettings => storeSettings.store === storeId,
    );
    return {
      storeId,
      isActive: storeSettings?.isActive ?? false,
    };
  });

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const loading = storesLoading || accountingIntegrationLoading;

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.integrationsPage', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView
        testID="general-settings-screen"
        contentContainerStyle={css(generalSettingsContainerWrapper)}
      >
        <View style={css(pageStyle)}>
          <BackOfficeSection
            title={translate('backOfficeFeatures.storeConfiguration')}
            contentContainerStyle={css(formStyle)}
          >
            <TableComponent
              columns={[
                {
                  title: translate('backOfficeSalesFeed.Store'),
                  width: 400,
                  alignItems: 'flex-start',
                  containerStyle: { paddingLeft: 65 },
                },
                {
                  title: translate('backOfficeSalesFeed.Status'),
                  width: 148,
                  alignItems: 'center',
                },
                { title: '', width: 42 },
              ]}
              data={storesConnectionStatus}
              normalRows
              columnContainerStyle={css(columnContainerStyle)}
              renderRow={({
                storeId,
                isActive,
              }: {
                storeId: string;
                isActive: boolean;
              }): React.ReactNode => {
                return (
                  <XeroStoreRow
                    isEnabled={isActive}
                    name={stores[storeId].name}
                    storeId={storeId}
                    toggleAccountingIntegrationStoreConnection={
                      toggleAccountingIntegrationStoreConnection
                    }
                  />
                );
              }}
            />
          </BackOfficeSection>
        </View>
      </ScrollView>
    </>
  );
};
