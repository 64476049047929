import { useMutation } from '@apollo/client/react/hooks';
import { OrderEvent } from '@hitz-group/domain';
import { useCallback, useMemo, useRef } from 'react';
import { getQueue, resetQueue } from '../../events/eventsQueue';
import { SYNC_ORDER_EVENTS } from '../../graphql/syncEvents';
import { currentOrderActionObservable } from '../app/orders/ordersObservableUtils';
import { useSubscriptionStatusVar } from './useNetworkStatusVar';

export function useSyncOrderEvents(
  postCompleteSync?: () => void,
  postErrorSyncPost?: () => void,
) {
  const syncAllEventsFlag = useRef<Boolean>(false);
  const orderIdForCurrentSync = useRef<string>();

  const onErrorSync = useCallback(() => {
    postErrorSyncPost && postErrorSyncPost();
  }, [postErrorSyncPost]);

  const onCompletedSync = useCallback(
    data => {
      if (data?.syncEvents) {
        if (syncAllEventsFlag.current) resetQueue();
        if (orderIdForCurrentSync.current) {
          currentOrderActionObservable.next({
            orderId: orderIdForCurrentSync.current,
            timestamp: Date.now(),
            isSyncComplete: true,
          });
        }
        postCompleteSync && postCompleteSync();
      }
    },
    [postCompleteSync],
  );

  const [syncEvents] = useMutation(SYNC_ORDER_EVENTS, {
    onError: onErrorSync,
    onCompleted: onCompletedSync,
  });
  const isConnected = useSubscriptionStatusVar();

  const syncEventFromQueue = useCallback(() => {
    const syncAllEvents = async () => {
      const data = (await getQueue()) || [];
      if (isConnected && data.length > 0) {
        syncAllEventsFlag.current = true;
        syncEvents({ variables: { input: [...data] } });
      }
    };
    syncAllEvents();
  }, [isConnected, syncEvents]);

  const syncOrderEvents = useCallback(
    (events: OrderEvent[]) => {
      if (isConnected) {
        orderIdForCurrentSync.current = events[0].orderId;
        syncAllEventsFlag.current = false;
        syncEvents({ variables: { input: events } });
      }
    },
    [isConnected, syncEvents],
  );

  return useMemo(
    () => ({
      syncOrderEvents: syncOrderEvents,
      syncAllOrderEvents: syncEventFromQueue,
    }),
    [syncEventFromQueue, syncOrderEvents],
  );
}
