import { formatDuration, intervalToDuration } from 'date-fns';
import { DURATION_FORMAT } from '../constants';
import { DayOfWeek } from '@hitz-group/domain';
import { format } from 'date-fns';
export const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const DAY_NAMES = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const filters = ['T', 'Y', '1W', '4W', '1Y', 'MTD', 'QTD', 'YTD', 'C'];

export const calculateStartDayOfMonth = (date: Date): number => {
  return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
};

export const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getStartingMonthOfQuarter = (
  months: string[],
  currentMonth: string,
) => {
  const monthIndex = months.findIndex(month => month === currentMonth);
  if (0 <= monthIndex && monthIndex <= 2) {
    return months[0];
  }
  if (3 <= monthIndex && monthIndex <= 5) {
    return months[3];
  }
  if (6 <= monthIndex && monthIndex <= 8) {
    return months[6];
  }
  if (9 <= monthIndex && monthIndex <= 11) {
    return months[9];
  }

  return months[0];
};

export const filterBy = (
  filter: string,
  hours = 0,
  minutes = 0,
  startMonth?: string,
): number | undefined => {
  const month = new Date(Date.now()).getMonth();
  const year = new Date(Date.now()).getFullYear();
  const startMonthIndex = MONTHS.findIndex(month => month === startMonth);
  switch (filter) {
    case 'Y':
      return new Date(Date.now() - 86400000).setHours(hours, minutes, 0, 0);
    case '1W':
      return new Date(Date.now() - 604800000).setHours(hours, minutes, 0, 0);
    case '4W':
      return new Date(Date.now() - 604800000 * 4).setHours(
        hours,
        minutes,
        0,
        0,
      );
    case '1Y':
      return new Date(Date.now() - 604800000 * 52).setHours(
        hours,
        minutes,
        0,
        0,
      );
    case 'MTD':
      return new Date(year, month).setHours(hours, minutes);
    case 'QTD':
      const quarter = Math.floor((month + 1) / 3);
      const currentMonth = MONTHS[month];
      const customMonthSequence = [
        ...MONTHS.slice(startMonthIndex),
        ...MONTHS.slice(0, startMonthIndex),
      ];
      const startingMonthOfQuarter = getStartingMonthOfQuarter(
        customMonthSequence,
        currentMonth,
      );
      const startingMonthOfQuarterIndex = MONTHS.findIndex(
        month => month === startingMonthOfQuarter,
      );
      return startMonth
        ? new Date(
            startingMonthOfQuarterIndex > month ? year - 1 : year,
            startingMonthOfQuarterIndex,
          ).setHours(hours, minutes)
        : new Date(year, quarter * 3).setHours(hours, minutes);
    case 'YTD':
      return startMonth
        ? new Date(
            startMonthIndex > month ? year - 1 : year,
            startMonthIndex,
          ).setHours(hours, minutes)
        : new Date(year, 0).setHours(hours, minutes);
    case 'C':
      return;
    default:
      return new Date(Date.now()).setHours(hours, minutes, 0, 0);
  }
};

export const prefixZero = (n: number): string => (n < 9 ? `0${n}` : `${n}`);

export const getTimeElapsed = (input: number): string => {
  const timeDiff = Date.now() - input;
  const diffSeconds = Math.ceil((timeDiff % 60000) / 1000);
  const diffMinutes = Math.floor(timeDiff / 60000);
  return `${prefixZero(diffMinutes)}:${prefixZero(diffSeconds)}`;
};

export const getDayFormat = (input: number): string => {
  if (input === 0) {
    return '--';
  }

  const duration = intervalToDuration({
    start: new Date(input),
    end: new Date(),
  });
  const formattedDuration = formatDuration(duration, {
    format: DURATION_FORMAT,
  });

  const formats = formattedDuration.split(' ');

  return formattedDuration ? formats[0] + ' ' + formats[1] : '1 second';
};

/**
 * A function to format date into DD-MM-YYYY.
 * @param input - A date being formatted
 * @param separator - separator between date component. default is '-'
 * @returns {string} - DD-MM-YYYY
 */
export const formatDate: (
  input: Date | string | number,
  separator?: '/' | '-',
) => string = (input, separator = '-') => {
  if (typeof input === 'string') input = new Date(input);
  return format(input, `d${separator}L${separator}y`);
};

/**
 * Time-slot generated for 24 hrs in format HH:MM with 30 minutes interval
 */
export const timeSlotOptions: { label: string; value: string }[] = [
  ...[...Array(24).keys()].map(num => {
    const firstHalfSlot = {
      label: `${num}:00`,
      value: `${num}:00`,
    };
    const secondHalfSlot = {
      label: `${num}:30`,
      value: `${num}:30`,
    };
    if (num < 10) {
      firstHalfSlot.label = '0' + firstHalfSlot.label;
      firstHalfSlot.value = '0' + firstHalfSlot.value;
      secondHalfSlot.label = '0' + secondHalfSlot.label;
      secondHalfSlot.value = '0' + secondHalfSlot.value;
    }
    return [firstHalfSlot, secondHalfSlot];
  }),
  {
    label: '23:59',
    value: '23:59',
  },
].flat();

export const weekDaysOptions = [
  ...Object.keys(DayOfWeek).map(key => ({
    label: key,
    value: DayOfWeek[key as keyof typeof DayOfWeek],
  })),
];

export const defaultStartTime = '00:00';
export const defaultEndTime = '23:59';

export const endOfDay = (date: Date) => date.setHours(23, 59, 59, 999);
