import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import DropDown from '../../../../components/FormInput/DropDown';
import { useTranslation } from '@hitz-group/localization';
import { CatalogueItem } from './types';
import scale, { isWeb, isIos, isAndroid } from '../../../../common/theme';
export interface LabelFilter {
  value: string;
  label: string;
}

interface ProductRowProps {
  stores: LabelFilter[];
  pages: LabelFilter[];
  setSearchString: (value: string) => void;
  setFilteredStore: (value: string) => void;
  setFilteredPage: (value: string) => void;
  selectedStore: string;
  selectedPage: string;
}

export const getProductBySearchQuery = (
  product: CatalogueItem[],
  searchQuery: string,
): CatalogueItem[] => {
  return product.filter(product =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
};

export const getProductByFilteredStore = (
  product: CatalogueItem[],
  selectedStore: string,
): CatalogueItem[] => {
  return product.filter(
    product => product.storeIds?.filter(store => store == selectedStore).length,
  );
};

export const getProductByFilteredPage = (
  product: CatalogueItem[],
  selectedPage: string,
): CatalogueItem[] => {
  return product.filter(
    product => product.pageIds?.filter(page => page == selectedPage).length,
  );
};

const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: isWeb ? '60%' : '50%',
  height: theme.input.height,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  marginHorizontal: 6,
  right: isIos ? 320 : 0,
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  width: '60%',
  flex: 1,
  height: theme.input.height,
  color: theme.colors.paragraph,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
});

const dropdownInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: '90%',
  marginBottom: theme.spacing.small,
  marginRight: theme.spacing.small,
});
export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '90%',
  height: theme.input.height + 2,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  marginLeft: scale.moderateScale(15),
  marginBottom: isAndroid ? 6 : 0,
});
export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  height: theme.input.height,
  width: '46%',
});

export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? '20%' : isAndroid ? '25%' : '50%',
  marginHorizontal: 6,
  marginBottom: isIos ? scale.moderateScale(2) : 0,
});

export const angleDownIconStyle: StyleFn = () => ({
  right: 10,
});

const titleActionStyle: StyleFn = () => ({
  flexDirection: 'row',
  width: '100%',
});
export const dropdownViewStyleIOS: StyleFn = () => ({
  justifyContent: 'center',
  width: '50%',
  marginBottom: scale.moderateScale(2),
  right: 160,
});

export const ProductHeaderFilters: React.FC<ProductRowProps> = ({
  stores,
  pages,
  setSearchString,
  setFilteredStore,
  selectedStore,
  setFilteredPage,
  selectedPage,
}: ProductRowProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  return (
    <View style={css(titleActionStyle)}>
      <DropDown
        values={[
          {
            label: translate('backOfficeProducts.productFilterByVenue'),
            value: '',
          },
        ].concat(...stores)}
        selectedValue={selectedStore}
        extraMainViewStyle={css(dropDownMainViewStyle)}
        extraViewStyle={css(dropdownViewStyle)}
        extraStyle={css(dropdownExtraStyle)}
        style={css(dropdownInputStyle)}
        onValueChange={setFilteredStore}
        angleDownIconStyle={css(angleDownIconStyle)}
      />
      <DropDown
        values={[
          {
            label: translate('backOfficeProducts.productFilterByPage'),
            value: '',
          },
        ].concat(...(pages || []))}
        selectedValue={selectedPage}
        extraMainViewStyle={css(dropDownMainViewStyle)}
        extraStyle={css(dropdownExtraStyle)}
        extraViewStyle={
          isIos ? css(dropdownViewStyleIOS) : css(dropdownViewStyle)
        }
        style={css(dropdownInputStyle)}
        onValueChange={setFilteredPage}
        angleDownIconStyle={css(angleDownIconStyle)}
      />
      <SearchBar
        placeholder={translate('backOfficeProducts.productSearchByName')}
        containerStyle={css(searchContainerStyle)}
        textInputStyle={css(searchTextInputStyle)}
        iconColor={theme.colors.paragraph}
        placeholderColor={theme.colors.paragraph}
        onChange={setSearchString}
      />
    </View>
  );
};
