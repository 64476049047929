import { Order } from '@hitz-group/domain';
import { table } from 'table';
import { config, FixedTuple } from './orderItems';
import { formatMoneyValue } from '@hitz-group/localization';

export const generateOrderTotalAmountDue = (order: Order, currency: string) => {
  const totalAmountDue = (order.totalPrice || 0) + (order?.roundingAmount || 0);
  const rows: FixedTuple[] = [
    ['', 'Total Amount Due', formatMoneyValue(+totalAmountDue, currency)],
  ];

  return table(rows, config);
};
