import React, { useCallback, useEffect } from 'react';
import { View, ScrollView, Text } from 'react-native';
import { PreviewStyles } from '../../styles/Component.styles';
import { OrderItemList } from '../../UIComponents/OrderItemList';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../../../hooks/Notification';
import { useReporting } from '../../../../../hooks/app/useReporting';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { cloneJSON } from '@hitz-group/client-utils';
import { ORDER_ITEM_QUERY } from '@hitz-group/analytics-helper';
import {
  ColumnType,
  CubeQuery,
  DateRangeGranularity,
} from '@hitz-group/domain';
import { CubejsApi } from '@cubejs-client/core';
import { convertDateByFormat, DAY_FORMAT } from '../../reportsHelper';
import { IMap } from '../../types';

interface DataPreviewProps {
  previewData: IMap<string | number> | undefined;
  timezone: string | undefined;
  cubejsApi: CubejsApi;
}

enum KeyPrefix {
  ORDERS = 'Orders',
  ORDER_PAYMENTS = 'OrderPayments',
  TRANSLATION = 'backOfficeSalesFeed.rowPreview',
}

interface PreviewTableRow {
  prefix: KeyPrefix;
  key: string;
  type: ColumnType;
}

interface PreviewTable {
  rows: PreviewTableRow[];
}

const ORDER_INFO_TABLE: PreviewTable = {
  rows: [
    { prefix: KeyPrefix.ORDERS, key: 'orderNumber', type: ColumnType.STRING },
    { prefix: KeyPrefix.ORDERS, key: 'createdAt', type: ColumnType.DATE },
    { prefix: KeyPrefix.ORDERS, key: 'orderStatus', type: ColumnType.STRING },
    {
      prefix: KeyPrefix.ORDERS,
      key: 'orderTypeName',
      type: ColumnType.STRING,
    },
    {
      prefix: KeyPrefix.ORDERS,
      key: 'createdByName',
      type: ColumnType.STRING,
    },
  ],
};

const TABLES: PreviewTable[] = [
  {
    rows: [
      { prefix: KeyPrefix.ORDERS, key: 'subTotal', type: ColumnType.MONEY },
      {
        prefix: KeyPrefix.ORDERS,
        key: 'orderDiscount',
        type: ColumnType.MONEY,
      },
      { prefix: KeyPrefix.ORDERS, key: 'totalTax', type: ColumnType.MONEY },
      { prefix: KeyPrefix.ORDERS, key: 'totalTips', type: ColumnType.MONEY },
      {
        prefix: KeyPrefix.ORDERS,
        key: 'roundingAmount',
        type: ColumnType.MONEY,
      },
      { prefix: KeyPrefix.ORDERS, key: 'totalRevenue', type: ColumnType.MONEY },
    ],
  },
  {
    rows: [
      {
        prefix: KeyPrefix.ORDER_PAYMENTS,
        key: 'paymentTypeName',
        type: ColumnType.STRING,
      },
      {
        prefix: KeyPrefix.ORDER_PAYMENTS,
        key: 'paymentAmount',
        type: ColumnType.MONEY,
      },
    ],
  },
];

export const DataPreview: React.FC<DataPreviewProps> = ({
  previewData,
  timezone,
  cubejsApi,
}) => {
  const styles = PreviewStyles();
  const { translate } = useTranslation();
  const { appendCurrency } = useCurrency();

  const { showNotification } = useNotification();

  const { loading, error, queryData, getQueryData } = useReporting(cubejsApi);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (previewData) {
      const query = cloneJSON(ORDER_ITEM_QUERY) as CubeQuery;
      const orderIdFilter = query.filters.find(
        filter => filter.member === 'Orders.orderId',
      );
      orderIdFilter?.values.push(previewData['Orders.orderId'] as string);
      getQueryData(query);
    }
  }, [previewData, getQueryData]);

  const getTextByColumn = useCallback(
    (text, type) => {
      let toReturn: string;
      switch (type) {
        case ColumnType.MONEY:
          toReturn = appendCurrency(text || '0.00');
          break;
        case ColumnType.DATE:
          toReturn = text
            ? convertDateByFormat(
                text,
                DateRangeGranularity.DAY,
                DAY_FORMAT,
                timezone,
              )
            : '';
          break;
        default:
          toReturn = text || '';
          break;
      }
      return toReturn;
    },
    [appendCurrency, timezone],
  );

  const tableRenderer = (
    previewData: IMap<string | number>,
    data: PreviewTable,
    index: number,
  ) => {
    return (
      <View
        testID={'preview-table'}
        key={`preview-table-${index}`}
        style={index === 0 ? styles.firstTableStyle : styles.tableStyle}
      >
        {data.rows.map((row, rowIndex) => (
          <View
            key={`preview-table-${index}-row-${rowIndex}`}
            style={styles.rowStyle}
          >
            <View style={styles.cellStyle}>
              <Text>{translate(`${KeyPrefix.TRANSLATION}.${row.key}`)}</Text>
            </View>
            <View style={styles.rightAlignCellStyle}>
              <Text>
                {getTextByColumn(
                  previewData[`${row.prefix}.${row.key}`],
                  row.type,
                )}
              </Text>
            </View>
          </View>
        ))}
      </View>
    );
  };

  if (loading) {
    return (
      <ScrollView style={styles.loaderViewStyle}>
        <LoadingIndicator testID={'data-preview-loader'} />
      </ScrollView>
    );
  }

  return (
    <ScrollView style={styles.viewStyle}>
      {previewData && (
        <>
          {tableRenderer(previewData, ORDER_INFO_TABLE, 0)}
          <OrderItemList itemsData={queryData} />
          {TABLES.map((tableData, tableIndex) => {
            return tableRenderer(previewData, tableData, tableIndex + 1);
          })}
          <View testID={'preview-table'} style={styles.tableStyle}>
            <View style={styles.rowStyle}>
              <View style={styles.centerAlignCellStyle}>
                <Text>
                  {previewData[`${KeyPrefix.ORDERS}.customerText`] || ''}
                </Text>
              </View>
            </View>
            <View style={styles.rowStyle}>
              <View style={styles.centerAlignCellStyle}>
                <Text>
                  {previewData[`${KeyPrefix.ORDERS}.storeName`] || ''}
                </Text>
              </View>
            </View>
          </View>
        </>
      )}
    </ScrollView>
  );
};
