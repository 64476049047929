import {
  Adjustment,
  AdjustmentType,
  AdjustmentUnit,
  CUSTOM_ADJUSTMENT_ID,
  Icons,
  OrderItem,
  PaymentType,
  RenderProps,
  StyleFn,
} from '@hitz-group/domain';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { getAdjustmentValue } from '@hitz-group/order-helper';
import { useModal } from '@hitz-group/rn-use-modal';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import {
  ActivityIndicator,
  FlatList,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSession } from '../../../hooks/app/useSession';
import { formatPercent } from '../../../utils/formatPercent';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import PopupView from '../../PopupView/PopupView';
import TitleBar from '../../TitleBar/TitleBar';
import { SetPriceModalMap, SET_PRICE_OPTIONS } from '../FunctionMaps/SetPrice';
export interface PaymentSurchargeModalProps {
  orderTotal?: number;
  paymentType: PaymentType;
  onSubmit?: (paymentType: PaymentType, item?: Adjustment) => void;
  onClose?: () => void | Promise<void>;
  checkAllowAdjustmentPermission?: () => boolean;
}

export interface cardProps {
  item: Adjustment;
  orderTotal?: number;
  onPress?: (item: Adjustment) => void;
}

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 15,
});

const textContainer: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: theme.padding.medium,
});

const buttonLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  fontSize: 10,
});
const customButtonStyle: StyleFn = ({}) => ({
  width: 195,
  flex: 1,
  height: 50,
  marginRight: 3,
});
const removeButtonStyle: StyleFn = ({ theme }) => ({
  width: 195,
  flex: 1,
  height: 50,
  marginLeft: 3,
  backgroundColor: theme.colors.orange,
});
const containerStyle: StyleFn = ({ theme }) => ({
  width: 450,
  height: 250,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  flex: 1,
});

const popupStyle: StyleFn = () => ({
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const surchargeSelectionStyle: StyleFn = ({ theme }) => ({
  height: 100,
  width: '24%',
  paddingHorizontal: theme.padding.small,
  borderWidth: 0.8,
  borderRadius: theme.radius.small,
  borderColor: theme.colors.boxBorder,
  marginHorizontal: '0.5%',
  marginBottom: theme.radius.small,
  backgroundColor: theme.colors.greyLight,
});

const adjustmentAmountStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.secondary,
  marginBottom: theme.spacing.small / 2,
  marginTop: theme.spacing.small,
  padding: theme.spacing.small / 2,
  fontWeight: '500',
});
const itemNameTextStyle: StyleFn = () => ({
  textAlign: 'center',
});

const totalChargeStyle: StyleFn = ({ theme }) => ({
  height: 50,
  backgroundColor: theme.colors.primary,
  borderRadius: theme.radius.small,
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 25,
});

const font20Style: StyleFn = ({ theme }) => ({
  color: theme.colors.orange,
  fontSize: 20,
  fontWeight: '500',
});

const buttonRowStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.medium * 1.5,
  flexDirection: 'row',
});
const cardRowStyle: StyleFn = () => ({
  width: '100%',
  marginTop: 10,
  height: 150,
});

const loadingStyle: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

const titleBarStyle: StyleFn = ({ theme }) => ({
  height: 40,
  marginBottom: theme.spacing.small,
  ...theme.shadow15,
});

const titleColor: StyleFn = ({ theme }) => ({ color: theme.colors.primary });

const subTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.darkGrey,
  fontSize: 12,
  fontWeight: '500',
});

export const Loading: React.FC = () => (
  <FelaComponent style={loadingStyle}>
    {({ style, theme }: RenderProps): React.ReactNode => (
      <ActivityIndicator color={theme.colors.primary} style={style} />
    )}
  </FelaComponent>
);

export const Card: React.FC<cardProps> = ({ item, orderTotal, onPress }) => {
  const { css } = useFela();
  const { formatCurrency } = useCurrency();
  const onCardClicked = useCallback((): void => {
    onPress && onPress(item);
  }, [onPress, item]);

  const surchargeAmount = orderTotal
    ? getAdjustmentValue(orderTotal, [item], {
        adjustmentType: item.adjustmentType,
      })
    : 0;
  const getItemAmount = {
    [AdjustmentUnit.PERCENTAGE]: function () {
      return formatPercent(item.amount);
    },
    [AdjustmentUnit.FLAT]: function () {
      return formatCurrency(item.amount);
    },
  };
  const itemAmount = item ? getItemAmount[item?.adjustmentUnit]() : 0;
  return useMemo(() => {
    return (
      <TouchableOpacity
        testID="card"
        style={css(surchargeSelectionStyle)}
        onPress={onCardClicked}
      >
        <View style={css(textContainer)}>
          <Text style={css(adjustmentAmountStyle)}>{itemAmount}</Text>
          <Text numberOfLines={2} style={css(itemNameTextStyle)}>
            {item.name}
          </Text>
          <Text style={css(subTitleStyle)}>
            {formatCurrency((orderTotal || 0) + surchargeAmount)}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }, [
    css,
    formatCurrency,
    item,
    itemAmount,
    onCardClicked,
    orderTotal,
    surchargeAmount,
  ]);
};
const PaymentSurcharge: React.FC<PaymentSurchargeModalProps> = ({
  orderTotal = 0,
  paymentType,
  onSubmit,
  onClose,
  checkAllowAdjustmentPermission = () => false,
}: PaymentSurchargeModalProps) => {
  const { css, theme } = useFela();
  const { showModal, closeModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const [session] = useSession();
  const adjustmentAllowedOnPayment = useMemo(() => {
    const adjustments = session?.currentVenue?.adjustments || [];
    return adjustments.filter(
      (adjustment: Adjustment) => adjustment?.allowOnPaymentType,
    );
  }, [session?.currentVenue]);

  const onCloseModal = useCallback(() => {
    onClose && onClose();
    closeModal();
  }, [closeModal, onClose]);

  const titleLeft = (
    <IconButton
      containerStyle={css(iconButtonStyle)}
      icon={Icons.ArrowLeft}
      iconColor={theme.colors.primary}
      onPress={onCloseModal}
    />
  );

  const onRemoveSurcharge = useCallback(() => {
    const isAllowAdjustment = checkAllowAdjustmentPermission();
    if (!isAllowAdjustment) return;
    onSubmit && onSubmit(paymentType);
    closeModal();
  }, [checkAllowAdjustmentPermission, onSubmit, paymentType, closeModal]);

  const applyCustomSurcharge = useCallback(
    (unitPrice: number, discounts: Adjustment[]) => {
      if (!discounts || !discounts.length) return;
      const discount = discounts[0];
      const amount = Math.abs(discount.amount);
      const customSurcharge = {
        id: CUSTOM_ADJUSTMENT_ID,
        amount: amount,
        adjustmentUnit: discount.adjustmentUnit,
        allowOnPaymentType: false,
        adjustmentType: AdjustmentType.SURCHARGE,
      };
      onSubmit && onSubmit(paymentType, customSurcharge);
    },
    [paymentType, onSubmit],
  );

  const onCustomSurcharge = useCallback(() => {
    const isAllowAdjustment = checkAllowAdjustmentPermission();
    if (!isAllowAdjustment) return;
    showModal(
      <SetPriceModalMap
        item={{} as OrderItem}
        onSubmit={applyCustomSurcharge}
        adjustmentType={SET_PRICE_OPTIONS.SURCHARGE_PERCENT}
        defaultPrice={orderTotal || 0}
      />,
    );
  }, [
    checkAllowAdjustmentPermission,
    showModal,
    applyCustomSurcharge,
    orderTotal,
  ]);

  const onSurchargeSelected = useCallback(
    item => {
      const isAllowAdjustment = checkAllowAdjustmentPermission();
      if (!isAllowAdjustment) return;
      closeModal();
      onSubmit && onSubmit(paymentType, item);
    },
    [closeModal, paymentType, onSubmit, checkAllowAdjustmentPermission],
  );

  return (
    <View style={css(containerStyle)}>
      <TitleBar
        titleLeft={titleLeft}
        labelStyle={css(titleColor)}
        title={String(translate('adjustment.applyAdjustment')).toUpperCase()}
        containerStyle={css(titleBarStyle)}
      />
      <PopupView containerStyle={css(popupStyle)}>
        <View style={css(totalChargeStyle)}>
          <Text style={css(font20Style)}>{formatCurrency(orderTotal)}</Text>
        </View>
        <Text style={css(subTitleStyle)}>
          {String(translate('adjustment.selectSurcharge')).toUpperCase()}
        </Text>

        <View style={css(cardRowStyle)}>
          {adjustmentAllowedOnPayment &&
            adjustmentAllowedOnPayment.length > 0 && (
              <FlatList
                numColumns={4}
                data={adjustmentAllowedOnPayment}
                showsVerticalScrollIndicator={false}
                renderItem={({ item }): ReactElement => (
                  <Card
                    item={item}
                    orderTotal={orderTotal}
                    onPress={onSurchargeSelected}
                  />
                )}
              />
            )}
        </View>
        <View style={css(buttonRowStyle)}>
          <Button
            title={String(
              translate('adjustment.customAdjustment'),
            ).toUpperCase()}
            fluid
            secondary
            size="small"
            containerStyle={css(customButtonStyle)}
            onPress={onCustomSurcharge}
            labelStyle={css(buttonLabelStyle({ theme }))}
          />
          <Button
            title={String(translate('adjustment.noAdjustment')).toUpperCase()}
            fluid
            danger
            size="small"
            containerStyle={css(removeButtonStyle)}
            onPress={onRemoveSurcharge}
            labelStyle={css(buttonLabelStyle({ theme }))}
          />
        </View>
      </PopupView>
    </View>
  );
};

export default PaymentSurcharge;
