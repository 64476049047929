import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useFela } from 'react-fela';
import { ScrollView, View, Text } from 'react-native';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../../components/FormInput/FormInput';

import { isAndroid } from '../../../../../common/theme';
import { useTranslation } from '@hitz-group/localization';
import ProductRow from './ProductRow';
import Button from '../../../../../components/Button/Button';
import { useNotification } from '../../../../../hooks/Notification';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useModal } from '@hitz-group/rn-use-modal/lib/useModal';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import MultipleSearchAdd, {
  ValuesType,
} from '../../../../../components/MultipleSelect/MultipleSearchAdd';
import {
  concat,
  intersection,
  keyBy,
  pullAll,
  difference,
  sortBy,
  omit,
  isEqual,
  uniq,
} from 'lodash';
import { CourseDetailStyles } from '../styles';
import IconButton from '../../../../../components/Button/IconButton';
import TableComponent from '../../../../../components/TableComponent/TableComponent';

import {
  CourseInfo,
  useCourses,
} from '../../../../../hooks/app/courses/useCourses';
import { usePages } from '../../../../../hooks/app/pages/usePages';
import {
  CreateCourseInput,
  DEFAULT_ENTITY_ID,
  Product,
  UpdateCourseInput,
} from '@hitz-group/domain';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { productsFragment } from '../../../../../hooks/app/courses/graphql';

export enum SelectedItemType {
  Product = 'Product',
  Page = 'Page',
}
export interface AssignedProduct {
  id: string;
  name: string;
}

type KeyInput = 'autoFire' | 'name';

const defaultCourseInfo: Partial<CourseInfo> = {
  name: '',
  autoFire: false,
};

const CourseDetail: React.FC = () => {
  const { theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();
  const navigation = useNavigation();
  const styles = CourseDetailStyles();
  const route = useRoute();

  const [courseInfo, setCourseInfo] =
    useState<Partial<CourseInfo>>(defaultCourseInfo);
  const [assignedProducts, setAssignedProducts] = useState<string[]>([]);
  const originalAssignedProductsRef = useRef<string[]>([]);
  const originalCourseInfoRef = useRef<Partial<CourseInfo>>({});

  const { courseId } = route?.params as {
    courseId: string;
    title: string;
  };
  const {
    error,
    getCourse,
    courseMaps,
    loading: getCourseLoading,
    deleteCourse,
    updateCourses,
    createCourse,
    updateProductsInCourse,
  } = useCourses({
    onUpdateCoursesComplete: handleOnCompleteUpdateCourses,
  });
  const { getPages, pages: pageMaps, loading: getPagesLoading } = usePages();
  const {
    products,
    getAllProducts,
    loading: getProductsLoading,
  } = useProducts(undefined, productsFragment);

  const hasCourseInfoChanged = !isEqual(
    courseInfo,
    originalCourseInfoRef.current,
  );
  const hasAssignedProductsChanged = !isEqual(
    assignedProducts.sort(),
    originalAssignedProductsRef.current,
  );

  function handleOnCompleteUpdateCourses() {
    if (!hasAssignedProductsChanged) {
      showNotification({
        message: translate('backOfficeProducts.courseUpdatedSuccessfully', {
          courseName: courseInfo.name,
        }),
        success: true,
      });
      navigation.setParams({
        courseId: courseInfo.id,
        title: courseInfo.name,
      });
    }
  }

  const handleCourseInfoChange = (key: KeyInput, value: string | boolean) => {
    setCourseInfo(pre => ({
      ...pre,
      [key]: value,
    }));
  };

  const handleAssignedProducts = (_: string[], item: ValuesType) => {
    const selectedId = item.value;
    if (item.type === SelectedItemType.Product) {
      setAssignedProducts(preProducts => {
        if (preProducts.includes(selectedId))
          return preProducts.filter(productId => productId !== selectedId);
        return [...preProducts].concat(selectedId);
      });
    } else {
      const allPagesMaps = keyBy(allPages, 'id');
      const productsInPage = (allPagesMaps[selectedId]?.products || []).map(
        product => product.id,
      );

      setAssignedProducts(preProducts => {
        const updatedProducts = [...preProducts];
        const commonProducts = intersection(updatedProducts, productsInPage);
        if (commonProducts.length === productsInPage.length) {
          pullAll(updatedProducts, productsInPage);
          return uniq(updatedProducts);
        }
        const notAssignedProducts = difference(productsInPage, commonProducts);
        return uniq(concat(updatedProducts, notAssignedProducts));
      });
    }
  };

  const handleDeleteItem = (item: AssignedProduct) => {
    setAssignedProducts(preProducts => {
      return preProducts.filter(productId => productId !== item.id);
    });
  };

  const handleSaveCourse = () => {
    if (!courseInfo.name)
      return showNotification({
        error: true,
        message: translate('backOfficeProducts.emptyCourseNameValidation'),
      });
    if (!assignedProducts.length)
      return showNotification({
        error: true,
        message: translate(
          'backOfficeProducts.numberOfAssignedProductsValidation',
        ),
      });

    if (!courseInfo.id) {
      const originals = originalAssignedProductsRef.current;
      return createCourse(courseInfo as CreateCourseInput, {
        productsToAdd: difference(assignedProducts, originals),
        productsToRemove: difference(originals, assignedProducts),
      });
    }
    hasCourseInfoChanged && updateCourses([courseInfo as UpdateCourseInput]);
    if (hasAssignedProductsChanged) {
      const originals = originalAssignedProductsRef.current;
      updateProductsInCourse({
        courseId: courseInfo.id,

        productsToAdd: difference(assignedProducts, originals),
        productsToRemove: difference(originals, assignedProducts),
      });
    }
  };

  const handleDeleteCourse = (courseId: string) => {
    showModal(
      <ConfirmationDialog
        title={
          translate('dialog.deleteTitle') +
          ' ' +
          translate('backOfficeProducts.course')
        }
        message={translate('dialog.deleteConfirmation', {
          label: courseInfo.name,
        })}
        onConfirm={() => {
          deleteCourse(courseId, courseMaps[courseId].name);
          closeModal();
        }}
      />,
    );
  };

  const allPages = useMemo(() => {
    const pages = Object.values(pageMaps);
    if (!pages) return [];
    return pages.map(page => {
      const productOfVariant =
        page.variants?.reduce((allProducts, variant) => {
          if (variant.products) return [...allProducts, ...variant.products];
          return allProducts;
        }, [] as Product[]) || [];
      return {
        ...page,
        products: [...(page.products || []), ...productOfVariant],
      };
    });
  }, [pageMaps]);

  const pagesAndProducts = useMemo<ValuesType[]>(() => {
    const productItems: ValuesType[] = Object.values(products).map(product => ({
      label: product.name,
      value: product.id,
      itemTitle: translate('backOfficeProductsSummary.productName'),
      type: SelectedItemType.Product,
    }));

    const pageItems: ValuesType[] = allPages.map(page => {
      return {
        label: page.name,
        value: page.id,
        itemTitle: translate('backOfficeProducts.page'),
        type: SelectedItemType.Page,
      };
    });
    return [...productItems, ...pageItems];
  }, [allPages, products, translate]);

  const selectedValues = useMemo<string[]>(() => {
    const results: string[] = [];
    const allPagesMaps = keyBy(allPages, 'id');
    pagesAndProducts.forEach(item => {
      const { value: itemId, type } = item;
      if (type === SelectedItemType.Product) {
        assignedProducts.includes(itemId) && results.push(itemId);
      } else {
        const productsInPage = (allPagesMaps[itemId]?.products || []).map(
          product => product.id,
        );
        const isAllProductsAssigned = productsInPage.every(productId =>
          assignedProducts.includes(productId),
        );
        if (!productsInPage.length || !isAllProductsAssigned) return;
        results.push(itemId);
      }
    });
    return results;
  }, [allPages, assignedProducts, pagesAndProducts]);

  const sortedAssignedProducts = useMemo<AssignedProduct[]>(() => {
    return sortBy(
      assignedProducts.map(productId => ({
        id: productId,
        name: products[productId]?.name,
      })),
      product => product.name,
    );
  }, [assignedProducts, products]);

  useEffect(() => {
    if (courseId === DEFAULT_ENTITY_ID) return;
    getCourse(courseId);
  }, [courseId, getCourse]);

  useEffect(() => {
    getPages();
    getAllProducts();
  }, [getAllProducts, getPages]);

  useEffect(() => {
    const tabBarLabel = courseMaps[courseId]?.name;
    if (!tabBarLabel) return;
    navigation.setOptions({ tabBarLabel });
  }, [courseId, courseMaps, navigation]);

  useEffect(() => {
    if (!courseMaps[courseId]) return;
    const { products, ...courseInfo } = courseMaps[courseId];
    const sortedProducts = (products || []).map(product => product.id).sort();
    originalAssignedProductsRef.current = sortedProducts;
    setAssignedProducts(sortedProducts);
    const onlyCourseInfo = omit(courseInfo, '__typename');
    originalCourseInfoRef.current = onlyCourseInfo;
    setCourseInfo(onlyCourseInfo);
  }, [courseId, courseMaps]);

  useEffect(() => {
    if (!error) return;
    showNotification({ error: true, message: error });
  }, [error, showNotification]);

  const hasDataChanged = hasCourseInfoChanged || hasAssignedProductsChanged;
  const loading = getCourseLoading || getPagesLoading || getProductsLoading;

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.courseSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={styles.pageStyle}>
        <ScrollView contentContainerStyle={styles.scrollStyle}>
          <BackOfficeSection
            title={translate('backOfficeProducts.courseDetails')}
            contentContainerStyle={styles.formStyle}
            containerStyle={styles.containerStyle}
          >
            <View style={styles.innerContainer}>
              <FormInput
                testID="course-name"
                placeholder={translate('backOfficeProducts.courseName')}
                value={courseInfo?.name}
                title={translate('backOfficeProducts.courseName')}
                alignTitle="left"
                containerStyle={styles.formInputContainerStyle}
                onChangeText={handleCourseInfoChange.bind(null, 'name')}
                maxLength={50}
                textStyle={styles.textStyle}
              />
              <View style={styles.toggleContainerStyle}>
                <IconButton
                  primary
                  icon={courseInfo?.autoFire ? 'toggle-on' : 'toggle-off'}
                  iconSize={26}
                  containerSize={38}
                  iconColor={
                    courseInfo?.autoFire
                      ? theme.colors.success
                      : theme.colors.paragraph
                  }
                  onPress={handleCourseInfoChange.bind(
                    null,
                    'autoFire',
                    !courseInfo?.autoFire,
                  )}
                />
                <Text style={styles.autoFireItemStyle}>
                  {translate('backOfficeProducts.autoFireItems')}
                </Text>
              </View>
            </View>
          </BackOfficeSection>

          <BackOfficeSection
            title={translate('backOfficeProducts.assignProduct')}
            contentContainerStyle={styles.formStyle}
            containerStyle={styles.containerStyle}
          >
            <MultipleSearchAdd
              values={pagesAndProducts}
              selectedValues={selectedValues}
              containerStyle={
                isAndroid ? styles.dropdownStyle : styles.dropDownStyle
              }
              onValueChange={handleAssignedProducts}
              searchLabel={translate(
                'backOfficeProducts.searchProductsPagesByName',
              )}
              scrollEnabled
            />
            {sortedAssignedProducts.length > 0 ? (
              <TableComponent
                data={sortedAssignedProducts}
                normalRows
                columnContainerStyle={styles.columnContainerStyle}
                showPagination
                paginationStyle={styles.paginationStyle}
                columns={[
                  {
                    title: translate('backOfficeProducts.products'),
                    width: 200,
                    containerStyle: { paddingLeft: 14 },
                  },
                ]}
                renderRow={(
                  item: AssignedProduct,
                  index: number,
                ): React.ReactNode => (
                  <ProductRow
                    item={item}
                    rowIndex={index}
                    key={index}
                    onDelete={handleDeleteItem.bind(null, item)}
                  />
                )}
              />
            ) : null}
          </BackOfficeSection>
        </ScrollView>
      </View>

      <View style={styles.mainStyle}>
        <View style={styles.actionsContainerStyle}>
          {courseInfo?.id && (
            <View testID="delete-changes">
              <Button
                fluid
                title={translate('button.delete')}
                containerStyle={styles.deleteButtonStyle}
                labelStyle={styles.dangerTitleStyle}
                onPress={handleDeleteCourse.bind(null, courseInfo.id)}
              />
            </View>
          )}

          {hasDataChanged && (
            <View testID="save-changes" style={styles.saveButtonContainer}>
              <Button
                fluid
                title={translate('button.saveChanges')}
                labelStyle={styles.titleStyle}
                containerStyle={styles.saveButtonStyle}
                onPress={handleSaveCourse}
              />
            </View>
          )}
        </View>
      </View>
    </>
  );
};

export default CourseDetail;
