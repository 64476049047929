import { useTranslation } from '@hitz-group/localization';
import { Icons, StyleFn, Theme, User } from '@hitz-group/domain';
import { useNavigation } from '@react-navigation/native';
import { useLogin } from '../../../hooks/app/useLogin';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { View, KeyboardAvoidingView, Platform } from 'react-native';
import Button from '../../../components/Button/Button';
import Error from '../../../components/Error/Error';
import FormInput from '../../../components/FormInput/FormInput';
import Logo from '../../../components/Logo/Logo';
import PopupView from '../../../components/PopupView/PopupView';
import { useNotification } from '../../../hooks/Notification';
import { navigateToBackOfficeParams } from '../../../state/navigation';
import { isStrongPassword } from '../../../utils/validator';
import scale from '../../../common/theme';
import { useFela } from 'react-fela';
import { OfficeUser, userUtility } from '../../../state/userUtility';
import { useLogout } from '../../../hooks/app/useLogout';
import UserProfileTile from './UserProfileTile';
import { useResetPassword } from '../../../hooks/app/useResetPassword';
import { noopHandler } from '../../../utils/errorHandlers';
import { useNetInfo } from '@react-native-community/netinfo';
import useOfficeUserRoles from '../../../hooks/app/users/useOfficeUserRoles';
import { useUserRoles } from '../../../hooks/app/users/useUserRoles';

export interface LoginScreenProps {
  theme: Theme;
}

export interface State {
  form: {
    email: string;
    password: string;
  };
  isLoggedIn?: boolean;
  empPassCode: string;
  errorMessage: string;
  isFormComplete: boolean;
  hidePassword: boolean;
}

enum FormError {
  INVALID_EMAIL = 'login.invalidEmailMessage',
  INVALID_PASSWORD = 'login.invalidPasswordMessage',
}

const loginViewStyle: StyleFn = () => ({
  width: scale.moderateScale(180),
  justifyContent: 'space-around',
  alignItems: 'center',
});
const logoStyle: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.medium,
  alignSelf: 'center',
  height: 62,
  width: 150,
});
const buttonContainerStyle: StyleFn = ({ theme }) => ({
  width: 280,
  marginTop: theme.spacing.big,
  justifyContent: 'space-between',
  alignItems: 'stretch',
});
const errorStyle: StyleFn = () => ({
  position: 'absolute',
  left: scale.moderateScale(185),
  bottom: scale.moderateScale(5),
});
const buttonStyle: StyleFn = () => ({
  marginVertical: scale.moderateScale(5),
});
const popupStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.large,
  paddingTop: theme.spacing.big + 5,
  paddingBottom: theme.spacing.medium + 5,
  ...theme.shadow30,
});
const forgotPasswordStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  fontSize: theme.fontSize.small,
  textTransform: 'none',
});
const btnStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  letterSpacing: -0.4,
  fontSize: theme.fontSize.small,
});
const textInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 280,
  marginTop: theme.spacing.big / 2,
});
const containerStyle: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

const gotoOfficeBtnStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  width: 'auto',
  marginTop: theme.padding.medium,
  alignItems: 'center',
});

interface LoginForm {
  email?: string;
  password?: string;
}

const OfficeUserLoginComponent: FC<{
  onSelectPosUsers: () => void;
  user?: OfficeUser;
  onPressGotoOfficeUsers: () => void;
  newLogin?: boolean;
}> = ({ onSelectPosUsers, onPressGotoOfficeUsers, user, newLogin = false }) => {
  const netInfo = useNetInfo();
  const [form, setForm] = useState<LoginForm>();
  const [errorMessage, setErrorMessage] = useState('');
  const { loading: logoutLoading } = useLogout();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { login, error, loading } = useLogin();
  const navigation = useNavigation();
  const { requestPasswordResetLink } = useResetPassword();
  const [recentUser, setRecentUser] = useState<OfficeUser>();
  const { getUserRoles } = useUserRoles();
  const { updateUserRoles } = useOfficeUserRoles();
  const { css, theme } = useFela();

  /**
   * This `cb` can be any function, which will be executed when device is connected to internet
   */
  const runOnline = useCallback(
    async (cb: Function) => {
      if (netInfo.isConnected) {
        await cb();
      } else {
        showNotification({
          message: translate('interimLockScreen.office.offline'),
          info: true,
        });
      }
    },
    [netInfo, showNotification, translate],
  );

  const setRecentOfficeUser = useCallback(
    async (isActive: boolean) => {
      if (user) {
        setForm(_form => ({ ..._form, email: user.email }));
        setRecentUser(user);
      } else if (!newLogin) {
        if (isActive) {
          const recentOfficeUser = userUtility.recentOfficeUser;
          if (recentOfficeUser) {
            setForm(_form => ({ ..._form, email: recentOfficeUser.email }));
            setRecentUser(recentOfficeUser);
          }
        }
      }
    },
    [newLogin, user],
  );

  useEffect(() => {
    let isActive = true;
    setRecentOfficeUser(isActive);
    return () => {
      isActive = false;
    };
  }, [setRecentOfficeUser]),
    // Update state when login fails or succeeds
    useEffect(() => {
      if (error) {
        showNotification({
          error: true,
          message: error,
        });
      }
    }, [error, showNotification]);

  const forgotPassword = useCallback(async () => {
    if (form?.email) {
      await requestPasswordResetLink(form.email);
    } else {
      showNotification({
        message: translate('interimLockScreen.forgotPasswordEmailRequired'),
        error: true,
      });
    }
  }, [form, showNotification, requestPasswordResetLink, translate]);

  const onPressForgotPassword = useCallback(async () => {
    await runOnline(forgotPassword);
  }, [runOnline, forgotPassword]);

  /**
   * Update state when a form input property has changed
   * @param prop name of input field
   * @param value updated value
   */
  const onChangeFormProp = useCallback(
    (prop: string, value: string): void => {
      if (errorMessage) {
        setErrorMessage('');
      }

      setForm({
        ...form,
        [prop]: value,
      });
    },
    [errorMessage, form],
  );

  // Validate and submit form
  const validateAndLogin = useCallback(async (): Promise<void> => {
    if (form?.email && form?.password) {
      // FIXME: Question can we do user id based authentication ? (asking this as we need to store user email in local storage, which i don't feel secure (because of main login - back-office))
      const session = await login(form.email, form.password);
      if (session?.user?.id) {
        setErrorMessage('');
        userUtility.addOfficeUser(session.user as User);
        const user = await getUserRoles(session.user.id);
        updateUserRoles(user);
        navigation.reset(navigateToBackOfficeParams);
      }
    } else if ((form?.password || '').replace(/\s/g, '').length === 0) {
      setErrorMessage(FormError.INVALID_PASSWORD);
    } else if ((form?.email || '').replace(/\s/g, '').length === 0) {
      setErrorMessage(FormError.INVALID_EMAIL);
    }
  }, [form, login, navigation, updateUserRoles, getUserRoles]);

  const onPressLogin = useCallback(async (): Promise<void> => {
    await runOnline(validateAndLogin);
  }, [validateAndLogin, runOnline]);
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={css(containerStyle)}
    >
      <View testID="Login Screen" accessible accessibilityLabel="Login Screen">
        <PopupView containerStyle={css(popupStyle)}>
          <View style={css(loginViewStyle)} testID="loginFormContainer">
            <Logo testID="App-Logo" style={css(logoStyle)} />
            {recentUser ? (
              <UserProfileTile
                user={recentUser}
                onSelectUser={noopHandler}
                alwaysActive={true}
              />
            ) : (
              <>
                <FormInput
                  testID="email"
                  error={errorMessage === FormError.INVALID_EMAIL}
                  alignTitle="left"
                  title={translate('login.email')}
                  value={form?.email}
                  onSubmitEditing={onPressLogin}
                  placeholder={translate('login.emailHint')}
                  onChangeText={email => onChangeFormProp('email', email)}
                  containerStyle={css(textInputContainerStyle)}
                  autoComplete="off"
                  autoCorrect={false}
                />
                {errorMessage === FormError.INVALID_EMAIL && (
                  <Error
                    style={css(errorStyle)}
                    text={translate(errorMessage)}
                  />
                )}
              </>
            )}
          </View>
          <View>
            <FormInput
              type="password"
              testID="passwordInput"
              alignTitle="left"
              onSubmitEditing={onPressLogin}
              verified={isStrongPassword(form?.email as string)}
              error={errorMessage === FormError.INVALID_PASSWORD}
              value={form?.password}
              placeholder={translate('interimLockScreen.office.passwordHint')}
              onChangeText={onChangeFormProp.bind(null, 'password')}
              containerStyle={css(textInputContainerStyle)}
              autoCorrect={false}
              autoComplete="off"
            />
            {errorMessage === FormError.INVALID_PASSWORD && (
              <Error style={css(error)} text={translate(errorMessage)} />
            )}
          </View>
          <View style={css(buttonContainerStyle)}>
            <View style={css(buttonStyle)}>
              <Button
                size="small"
                success
                fluid
                testID="officeUserLoginBtn"
                loading={loading || logoutLoading}
                title={translate('button.login')}
                labelStyle={css(btnStyle)}
                onPress={onPressLogin}
              />
            </View>
            <View style={css(buttonStyle)}>
              <Button
                size="small"
                secondary
                fluid
                testID="switchUser"
                title={translate('interimLockScreen.switchUser')}
                labelStyle={css(btnStyle)}
                onPress={onPressGotoOfficeUsers}
              />
            </View>
            <View style={css(buttonStyle)}>
              <Button
                transparent
                size="small"
                fluid
                labelStyle={css(forgotPasswordStyle)}
                title={translate('button.forgotPassword')}
                onPress={onPressForgotPassword}
                testID="forgotPasswordBtn"
              />
            </View>
          </View>
        </PopupView>
      </View>
      <Button
        testID="gotoPosBtn"
        title={translate('interimLockScreen.office.gotoPos')}
        size="small"
        color={theme.colors.white}
        containerStyle={css(gotoOfficeBtnStyle)}
        onPress={onSelectPosUsers}
        icon={Icons.ArrowRight}
        iconPosition="right"
        iconProps={{ color: theme.colors.black, size: 25 }}
      />
    </KeyboardAvoidingView>
  );
};
const OfficeUserLogin = React.memo(OfficeUserLoginComponent);
OfficeUserLogin.displayName = 'OfficeUserLogin';

export default OfficeUserLogin;
