import {
  StyleFn,
  DEFAULT_USER_PIN,
  DEFAULT_USER_PASSWORD,
  DEFAULT_USER_LANGUAGE,
} from '@hitz-group/domain';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { useNotification } from '../../../../hooks/Notification';
import { getCountry, useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../components/FormInput/FormInput';
import Button from '../../../../components/Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';
import Modal from '../../../../components/Modals/Modal';
import { CreateUserRequest } from '@hitz-group/domain';
import { isValidEmail, isValidNumber } from '../../../../utils/validator';
import scale from '../../../../common/theme';
import { DEFAULT_COUNTRY_CODE } from '../../../../constants';
import { useSession } from '../../../../hooks/app/useSession';

const createModalContainerStyle: StyleFn = () => ({
  width: 365,
  flexDirection: 'column',
});

const btnLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const createNewFormInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  marginBottom: theme.spacing.small,
});

const buttonStyle: StyleFn = ({ theme, marginLeft }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginLeft: marginLeft || 0,
  marginTop: theme.spacing.medium,
});

const rowViewStyle: StyleFn = () => ({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
});

const phonePrefixErrorBgStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger2,
});

const phonePrefixStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  backgroundColor: theme.colors.white,
  minWidth: scale.moderateScale(25),
  textAlign: 'left',
  paddingLeft: scale.moderateScale(5),
  fontFamily: theme.font.regular,
  fontSize: scale.moderateScale(8),
});

type UserWithPhoneCountryCode = CreateUserRequest & {
  countryCode: string;
};

interface UserModalProps {
  createUser: (input: CreateUserRequest, isEditMore: boolean) => void;
}

export const UserModal: React.FC<UserModalProps> = ({
  createUser,
}: UserModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [userForm, setUserForm] = useState({
    countryCode: DEFAULT_COUNTRY_CODE,
  } as unknown as UserWithPhoneCountryCode);
  const { showNotification } = useNotification();
  const [session] = useSession();

  const onChangeUser = useCallback((prop: string, value: string) => {
    setUserForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const getFormattedPhone = useCallback(() => {
    if (userForm.countryCode) {
      return `${getCountry(userForm.countryCode).phone} ${userForm.phone}`;
    }
    return userForm.phone;
  }, [userForm.countryCode, userForm.phone]);

  const formValidationStatus: Record<string, boolean> = useMemo(
    () => ({
      firstName: userForm.firstName?.length > 0,
      email: isValidEmail(userForm.email),
      phone: userForm.phone ? isValidNumber(getFormattedPhone()) : false,
    }),
    [userForm.email, userForm.phone, getFormattedPhone, userForm.firstName],
  );
  const onCreateUser = useCallback(
    (isEditMore: boolean) => {
      if (
        Object.keys(formValidationStatus).some(
          _key => formValidationStatus[_key] === false,
        )
      ) {
        showNotification({
          error: true,
          message: translate('backOfficeProducts.fillAllFields'),
        });
      } else {
        createUser(
          {
            firstName: userForm.firstName,
            lastName: userForm.lastName,
            phone: getFormattedPhone(),
            preferredLanguage: DEFAULT_USER_LANGUAGE,
            email: userForm.email,
            pin: DEFAULT_USER_PIN,
            password: DEFAULT_USER_PASSWORD,
          } as CreateUserRequest,
          isEditMore,
        );
      }
    },
    [
      userForm,
      createUser,
      translate,
      showNotification,
      getFormattedPhone,
      formValidationStatus,
    ],
  );
  const { country = 'AU' } = session?.currentOrganization || {};
  useEffect(() => {
    if (country) {
      setUserForm(formData => ({
        ...formData,
        countryCode: country,
      }));
    }
  }, [country]);

  return (
    <Modal
      title={translate('backOfficeUsers.createNewUser')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
    >
      <FormInput
        testID={'firstName'}
        title={translate('form.firstName') + '*'}
        placeholder={translate('form.firstName')}
        value={userForm.firstName}
        containerStyle={css(createNewFormInputStyle)}
        onChangeText={onChangeUser.bind(null, 'firstName')}
      />

      <FormInput
        testID={'lastName'}
        title={translate('form.lastName')}
        placeholder={translate('form.lastName')}
        value={userForm.lastName}
        containerStyle={css(createNewFormInputStyle)}
        onChangeText={onChangeUser.bind(null, 'lastName')}
      />

      <FormInput
        testID={'email'}
        title={translate('form.email') + '*'}
        placeholder={translate('form.email')}
        value={userForm.email}
        containerStyle={css(createNewFormInputStyle)}
        onChangeText={onChangeUser.bind(null, 'email')}
      />

      <FormInput
        testID="phoneNo"
        placeholder={translate('form.phone')}
        title={translate('form.phone')}
        value={userForm.phone || ''}
        alignTitle="left"
        containerStyle={css(createNewFormInputStyle)}
        onChangeText={onChangeUser.bind(null, 'phone')}
        type="phone"
        maxLength={10}
        verified={formValidationStatus.phone}
        error={!formValidationStatus.phone}
        country={userForm.countryCode}
        onSelectChange={(country): void =>
          onChangeUser('countryCode', country.toString())
        }
        onPressCountry={(country): void =>
          onChangeUser('countryCode', country.toString())
        }
        prefix={{
          text: getCountry(userForm.countryCode)?.phone,
          textStyle: !formValidationStatus.phone
            ? css(phonePrefixStyle, phonePrefixErrorBgStyle)
            : css(phonePrefixStyle),
        }}
      />

      <View style={css(rowViewStyle)}>
        <Button
          title={translate('productSettings.editMore')}
          containerStyle={css(buttonStyle)}
          labelStyle={css(btnLabelStyle)}
          color={theme.colors.green}
          onPress={onCreateUser.bind(null, true)}
        />

        <Button
          title={translate('productSettings.create')}
          containerStyle={css(
            buttonStyle({ theme, marginLeft: theme.spacing.medium }),
          )}
          labelStyle={css(btnLabelStyle)}
          color={theme.colors.green}
          onPress={onCreateUser.bind(null, false)}
        />
      </View>
    </Modal>
  );
};
