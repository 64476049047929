import React, {
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useFela } from 'react-fela';
import { TableStyles } from '../../styles/Component.styles';
import DataGrid, {
  columnStyleOptions,
  columnForDataGrid,
} from '../../../../../components/DataGrid/DataGrid';
import { View, Text } from 'react-native';
import {
  StyleFn,
  DateRangeGranularity,
  ColumnType,
  ShiftStatus,
} from '@hitz-group/domain';
import { useCurrency } from '@hitz-group/localization';
import { useTranslation } from '@hitz-group/localization';
import { writeToString } from '@fast-csv/format';
import { buildURI, downloadFile } from '../../../../../utils/csv';
import {
  IMap,
  PivotTableData,
  ReportTableColumn,
  DisplayLabels,
} from '../../types';
import {
  convertDateByFormat,
  formatMinutesToHourAndMinutes,
  MAX_ROW_HEIGHT,
} from '../../reportsHelper';
import Icon from '../../../../../components/Icon/Icon';
import { cloneJSON } from '@hitz-group/client-utils';
import { TableRowPreview } from './../../UIComponents/TableRowPreview';
import { useSession } from '../../../../../../src/hooks/app/useSession';

export interface TableRef {
  exportData: (fileName?: string) => void;
}

interface TableProps {
  columns: ReportTableColumn[];
  pageSize?: number;
  granularityFormats: IMap<string>;
  data: Array<IMap<string | number> | object>;
  onTableRowPress?: (item: PivotTableData) => void;
}

const dataItemContainerStyle: StyleFn = ({ theme }) => ({
  flexWrap: 'nowrap',
  backgroundColor: theme.colors.white,
  padding: theme.padding.small,
  paddingTop: 0,
  paddingBottom: 0,
  height: 34,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const dataRowStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.small,
  paddingBottom: theme.padding.small,
  paddingLeft: theme.padding.medium,
  maxHeight: MAX_ROW_HEIGHT,
  overflow: 'hidden',
});

const headerStyle: StyleFn = ({ theme }) => ({
  minHeight: 45,
  fontSize: theme.fontSize.small,
  paddingHorizontal: 0,
  borderStyle: 'solid',
  marginTop: 15,
  paddingBottom: theme.padding.small3,
  paddingLeft: theme.padding.medium,
  backgroundColor: theme.colors.greyLight,
});

const paginationStyle: StyleFn = () => ({
  bottom: -45,
});

const TableNameStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  letterSpacing: 0,
  fontFamily: theme.font.medium,
  lineHeight: 32,
  textAlign: 'left',
});

const HeaderParentStyle: StyleFn = () => ({
  margin: 0,
});

export const Table: React.FC<TableProps & { ref?: React.Ref<TableRef> }> =
  forwardRef(
    ({ columns, pageSize, granularityFormats, data, onTableRowPress }, ref) => {
      const { translate } = useTranslation();
      const { css, theme } = useFela();
      const { appendCurrency } = useCurrency();
      const [session] = useSession();

      const columnsCopy = cloneJSON(columns);
      const columnsTypes: IMap<string | undefined> = useMemo(() => {
        const typesObj: IMap<string | undefined> = {};
        columnsCopy.forEach((column: ReportTableColumn) => {
          typesObj[column.key] = column.type;
        });
        return typesObj;
      }, [columnsCopy]);

      const COLUMNS = columnsCopy.map((column: ReportTableColumn) => {
        return {
          columnDataKey: column.dataKey || column.key,
          columnName:
            column.key !== ColumnType.PREVIEW ? translate(column.title) : '',
          columnStyle: column.style || columnStyleOptions.SMALL,
        } as columnForDataGrid;
      });

      const columnsStyles: IMap<string | undefined> = useMemo(() => {
        const typesObj: IMap<string | undefined> = {};
        COLUMNS.forEach((column: columnForDataGrid) => {
          typesObj[column.columnName as string] = column.columnStyle;
        });
        return typesObj;
      }, [COLUMNS]);

      const styles = TableStyles();

      const serializedData = useMemo(() => {
        return data as IMap<number | string>[];
      }, [data]);

      const getRowText = useCallback(
        (item, columnDataKey: string): string => {
          const type = columnsTypes[columnDataKey];
          let rowText = item?.[columnDataKey];

          switch (type) {
            case ColumnType.MONEY:
              rowText = appendCurrency(`${item?.[columnDataKey] || '0.00'}`);
              break;
            case ColumnType.NUMBER:
              rowText = `${item?.[columnDataKey] || '0.00'}`;
              break;
            case ColumnType.PERCENT:
              rowText = `${item?.[columnDataKey] || '0.00'}%`;
              break;
            case ColumnType.DATE:
              rowText = item?.[columnDataKey]
                ? convertDateByFormat(
                    item?.[columnDataKey],
                    DateRangeGranularity.DAY,
                    granularityFormats[DateRangeGranularity.DAY],
                    session.currentOrganization?.timezone,
                  )
                : DisplayLabels.SHIFT_NOT_CLOSED;
              break;
            case ColumnType.TIME:
              rowText = formatMinutesToHourAndMinutes(
                item?.[columnDataKey] ?? 0,
              );
              break;
          }
          return rowText;
        },
        [
          columnsTypes,
          appendCurrency,
          granularityFormats,
          session.currentOrganization?.timezone,
        ],
      );

      const renderDataItem = useCallback(
        (
          item,
          columnDataKey: string,
          columnStyle?: string,
        ): React.ReactNode => {
          return (
            <View
              style={css(dataItemContainerStyle, {
                width: columnStyle == columnStyleOptions.SMALL ? 90 : 180,
              })}
            >
              {columnDataKey === 'shiftNumber' && (
                <Icon
                  name={'circle'}
                  size={15}
                  style={styles.statusIconStyle}
                  color={
                    item.status === ShiftStatus.OPEN
                      ? theme.colors.orange
                      : theme.colors.green
                  }
                />
              )}
              <Text numberOfLines={1} ellipsizeMode={'tail'}>
                {getRowText(item, columnDataKey)}
              </Text>
            </View>
          );
        },
        [css, theme, styles.statusIconStyle, getRowText],
      );

      const renderHeaderItem = useCallback(
        (item: string): React.ReactNode => {
          const columnStyle = columnsStyles[item];
          return (
            <View
              style={css(HeaderParentStyle, {
                width: columnStyle == columnStyleOptions.SMALL ? 90 : 180,
              })}
            >
              <Text style={css(TableNameStyle)}>{item}</Text>
            </View>
          );
        },
        [css, columnsStyles],
      );

      const renderOptionItems = useCallback(
        (item): React.ReactNode => {
          const togglePreview = () => {
            onTableRowPress && onTableRowPress(item as PivotTableData);
          };

          return <TableRowPreview onTableRowPress={togglePreview} />;
        },
        [onTableRowPress],
      );

      const exportData = useCallback(
        async fileName => {
          const rows = serializedData.map(rowData => {
            return COLUMNS.filter(
              (col: columnForDataGrid) => col.columnName,
            ).map(
              (_col: columnForDataGrid) =>
                `${getRowText(rowData, _col.columnDataKey)}`,
            );
          });

          const csvData = await writeToString(rows, {
            delimiter: ',',
            rowDelimiter: '\n',
            quote: '"',
            headers: COLUMNS.filter(
              (col: columnForDataGrid) => col.columnName,
            ).map((col: columnForDataGrid) => `${col.columnName}`),
          });

          const uri = buildURI(csvData);
          downloadFile(uri, fileName);
        },
        [serializedData, COLUMNS, getRowText],
      );

      useImperativeHandle(ref, () => ({ exportData }));

      return (
        <View style={styles.pageStyle}>
          <DataGrid
            columns={COLUMNS}
            data={serializedData}
            renderDataItem={renderDataItem}
            pageSize={pageSize || 10}
            headerStyle={css(headerStyle)}
            dataRowStyle={css(dataRowStyle)}
            paginationStyle={css(paginationStyle)}
            renderHeaderItem={renderHeaderItem}
            renderOptionItems={renderOptionItems}
          />
        </View>
      );
    },
  );

Table.displayName = 'Table';
