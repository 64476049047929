import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useTranslation } from '@hitz-group/localization';
import { CustomerDetails } from './CustomerDetails/CustomerDetails';
import { CustomerOrders } from './CustormerOrders/CustomerOrders';
import { useRoute } from '@react-navigation/native';

const Tab = createMaterialTopTabNavigator();
interface RouteParams {
  productId: string;
  isVariant: boolean;
  isVariantProduct: boolean;
  screen?: string;
}
const ManageCustomerTabs: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const params = route.params as RouteParams;
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'ManageCustomers'}
        />
      )}
      initialRouteName="ManageCustomersTab"
    >
      <Tab.Screen
        name="CustomerDetailsTab"
        component={CustomerDetails}
        options={{
          tabBarLabel: translate('backOfficeCustomers.tabNames.details'),
        }}
        initialParams={params}
      />

      <Tab.Screen
        name="CustomerOrdersTab"
        component={CustomerOrders}
        options={{
          tabBarLabel: translate('backOfficeCustomers.tabNames.orderHistory'),
        }}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default ManageCustomerTabs;
