import { OrderStatus, OrderType, Product, StyleFn } from '@hitz-group/domain';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import format from 'date-fns/format';
import React, { useCallback, useMemo } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import Button from '../../../../components/Button/Button';
import Label from '../../../../components/Label/Label';
import OrderDataRow from '../../../../components/OrderDataView/OrderDataRow';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import Title from '../../../../components/Title/Title';
import { DATE_FORMAT } from '../../../../constants';
import { getDayFormat } from '../../../../utils/dateHelper';

interface TimerStatus {
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
}

export const PayNow = 'PAY_NOW';

export interface OrderTypeDisplayProps extends OrderType {
  colorId: number;
  code: string;
}

export interface OnlineOrdersDataProps {
  id: string;
  orderNumber: string;
  orderItems: Array<Partial<Product>>;
  createdAt: number;
  updatedAt?: number;
  customer: string;
  age?: string;
  channel: string;
  type: string;
  amount: number;
  status: OrderStatus;
  scheduled?: number;
  amountDue?: number;
}
export interface OrdersListViewProps {
  data: OnlineOrdersDataProps[];
  onSelectOrder: (id: string) => void;
  acceptOrders: (ids: string[]) => void;
  completeOrder: (id: string) => void;
  onOrderPay: (id: string) => void;
}

const ageContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.padding.small,
});

const amountContainer: StyleFn = ({ theme }) => ({
  marginLeft: -theme.spacing.small,
});

const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  textTransform: 'capitalize',
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
});

const rowStyle: StyleFn = ({ theme }) => ({
  height: 50,
  paddingHorizontal: theme.padding.medium,
  borderRadius: theme.radius.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
  width: '100%',
});

const labelLeftMarginContainerStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

const labelText: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  textTransform: 'none',
});

const containerStyle: StyleFn = () => ({
  width: '100%',
  maxWidth: 822,
});

const statusButtonStyle: StyleFn = ({ theme, color }) => ({
  width: 100,
  height: 34,
  borderRadius: theme.radius.small,
  backgroundColor: color || theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const halfFlexStyle: StyleFn = () => ({
  flex: 0.5,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const twoFlexStyle: StyleFn = () => ({
  flex: 2,
});

const OrdersListView: React.FC<OrdersListViewProps> = ({
  data,
  onSelectOrder,
  acceptOrders,
  completeOrder,
  onOrderPay,
}: OrdersListViewProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const orderActionButtonMapByStatus: Record<
    string,
    {
      title: string;
      containerColor: string;
      textColor: string;
      action?: (id: string) => void;
      disabled?: boolean;
    }
  > = useMemo(
    () => ({
      CREATED: {
        title: translate('onlineOrders.accept'),
        containerColor: theme.colors.orangeLight,
        textColor: theme.colors.darkYellow,
        action: id => acceptOrders([id]),
      },
      IN_PROGRESS: {
        title: translate('onlineOrders.complete'),
        containerColor: theme.colors.greenLighter,
        textColor: theme.colors.success,
        action: completeOrder,
      },
      PAY_NOW: {
        title: translate('onlineOrders.payNow'),
        containerColor: theme.colors.successLight,
        textColor: theme.colors.green,
        action: onOrderPay,
      },
    }),
    [
      translate,
      theme.colors.orangeLight,
      theme.colors.darkYellow,
      theme.colors.greenLighter,
      theme.colors.success,
      theme.colors.successLight,
      theme.colors.green,
      completeOrder,
      onOrderPay,
      acceptOrders,
    ],
  );

  const orderTimerStatus = useCallback((input: number): TimerStatus => {
    const diff = Date.now() - input;
    return diff <= 600000
      ? { success: true }
      : diff > 600000 && diff <= 900000
      ? { warning: true }
      : { danger: true };
  }, []);

  const getOrderActionMap = useCallback(
    (order: OnlineOrdersDataProps) => {
      let orderStatus: string = order.status;

      // when the order is in inprogress and amount is due we show pay now
      if (
        order.status === OrderStatus.IN_PROGRESS &&
        !!order.amountDue &&
        order.amountDue > 0
      ) {
        orderStatus = PayNow;
      }
      return orderActionButtonMapByStatus[orderStatus];
    },
    [orderActionButtonMapByStatus],
  );

  return (
    <ScrollView style={css(containerStyle)}>
      <TableComponent
        columnSpacing={theme.spacing.medium}
        columns={[
          {
            title: translate('onlineOrders.age'),
            alignItems: 'center',
            containerStyle: css(flexStyle),
          },
          {
            title: translate('onlineOrders.channel'),
            alignItems: 'center',
            containerStyle: css(flexStyle),
          },
          {
            title: translate('onlineOrders.type'),
            alignItems: 'center',
            containerStyle: css(flexStyle),
          },
          {
            title: translate('onlineOrders.scheduled'),
            alignItems: 'center',
            containerStyle: css(twoFlexStyle),
          },
          {
            title: translate('onlineOrders.customer'),
            alignItems: 'center',
            containerStyle: css(twoFlexStyle),
          },
          {
            title: translate('onlineOrders.amount'),
            alignItems: 'flex-end',
            containerStyle: css(flexStyle),
          },
          {
            title: translate('onlineOrders.actions'),
            alignItems: 'center',
            containerStyle: css(flexStyle),
          },
          {
            title: '',
            containerStyle: css(halfFlexStyle),
          },
        ]}
        data={data}
        renderRow={(
          order: OnlineOrdersDataProps,
          index: number,
        ): React.ReactNode => (
          <OrderDataRow
            key={order.id}
            canSelect
            containerStyle={css(rowStyle)}
            onPress={onSelectOrder?.bind(null, order.id)}
            testID={`online-order-${index}`}
            iconContainerStyle={css(halfFlexStyle)}
          >
            <View style={[css(flexStyle), css(ageContainer)]}>
              <Label
                testID={`online-order-${index}-age`}
                {...orderTimerStatus(order.updatedAt || order.createdAt)}
                value={getDayFormat(order.updatedAt || order.createdAt)}
                textStyle={css(labelText)}
              />
            </View>
            <View style={css(flexStyle)}>
              <Label
                testID={`online-order-${index}-channel`}
                value={
                  order.channel?.length > 8
                    ? order?.channel.slice(0, 8) + '...'
                    : order.channel || 'Online'
                }
                textStyle={css(labelText)}
                extraStyleLabel={{}}
              />
            </View>
            <View style={css(flexStyle)}>
              <Label
                testID={`online-order-${index}-type`}
                value={order.type}
                textStyle={css(labelText)}
                extraStyleLabel={{}}
                containerStyle={css(labelLeftMarginContainerStyle)}
              />
            </View>
            <Title
              containerStyle={css(twoFlexStyle)}
              labelStyle={css(textStyle)}
              numberOfLines={1}
              testID={`online-order-${index}-scheduled-at`}
            >
              {order.scheduled
                ? format(new Date(order.scheduled), DATE_FORMAT)
                : '-'}
            </Title>
            <Title
              containerStyle={css(twoFlexStyle)}
              labelStyle={css(textStyle)}
              numberOfLines={1}
              testID={`online-order-${index}-customer`}
            >
              {order.customer}
            </Title>
            <Title
              containerStyle={[css(flexStyle), css(amountContainer)]}
              labelStyle={css(textStyle)}
              numberOfLines={1}
              testID={`online-order-${index}-amount`}
            >
              {formatCurrency(order.amount)}
            </Title>

            <TouchableOpacity
              disabled={!!getOrderActionMap(order)?.disabled}
              onPress={getOrderActionMap(order)?.action?.bind(null, order.id)}
              style={css(flexStyle)}
              testID={'action-btn'}
            >
              <Button
                fluid
                testID={`online-order-${index}-status-button`}
                title={getOrderActionMap(order)?.title || ''}
                containerStyle={css(
                  statusButtonStyle({
                    theme,
                    color: getOrderActionMap(order)?.containerColor || '',
                  }),
                )}
                labelStyle={{
                  ...css(titleStyle),
                  color: getOrderActionMap(order)?.textColor || '',
                }}
                disabled
              />
            </TouchableOpacity>
          </OrderDataRow>
        )}
        columnContainerStyle={css(columnContainerStyle)}
        normalRows
      />
    </ScrollView>
  );
};
export default OrdersListView;
