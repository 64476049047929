import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ScrollView, View } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { TaxesSection } from './TaxesSection';
import { TaxesGroupSection } from './TaxesGroupSection';
import { useFela } from 'react-fela';
import {
  OnboardingAction,
  OnboardingSection,
  OnboardingArea,
  OnboardingCheckList,
  StyleFn,
  Tax,
} from '@hitz-group/domain';
import Button from '../../../../../components/Button/Button';
import {
  GET_TAXES_DETAILS,
  UPDATE_TAX,
  DELETE_TAX_DETAILS,
} from '../../../../../graphql/settings';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import { useNotification } from '../../../../../hooks/Notification';
import { useOnboarding } from '../../../../../hooks/app/useOnboarding';
import { useModal } from '@hitz-group/rn-use-modal';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import scale from '../../../../../common/theme';
import { isLessThanLimit, isFloat } from '../../../../../utils/validator';

interface TaxToTax {
  id: string;
  name: string;
  code: string;
  rate?: string;
  isActive?: boolean;
  taxes: Tax[];
}

interface TaxToTaxInput {
  id: string;
  name: string;
  code: string;
  rate?: number;
  isActive?: boolean;
  taxes: string[];
}

const mainStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(27),
});
const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

interface DeleteModalProps {
  index: number;
  id: string;
  name: string;
  onDeleteCallBack: (index: number, id: string) => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  id,
  name,
  index,
  onDeleteCallBack,
}: DeleteModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [deleteTax, deletedTax] = useMutation(DELETE_TAX_DETAILS, {
    onError: noopHandler,
  });

  useEffect((): void => {
    if (deletedTax.error) {
      showNotification({
        error: true,
        message: parseApolloError(deletedTax.error),
      });
    }
  }, [deletedTax.error, showNotification]);

  useEffect((): void => {
    if (deletedTax.data) {
      closeModal();
      onDeleteCallBack(index, id);
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: name,
        }),
      });
    }
  }, [
    onDeleteCallBack,
    index,
    id,
    deletedTax.data,
    showNotification,
    closeModal,
    name,
    translate,
  ]);

  const onDeleteTax = useCallback((): void => {
    deleteTax({ variables: { id: id } });
  }, [deleteTax, id]);

  return (
    <ConfirmationDialog
      title={translate('dialog.deleteTitle')}
      message={translate('dialog.deleteConfirmation', { label: name })}
      onConfirm={onDeleteTax}
    />
  );
};

export const TaxesScreen: React.FC = () => {
  const { css } = useFela();
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const [taxesData, setTaxesData] = useState([] as TaxToTax[]);
  const { updateOnboardingStatus } = useOnboarding();

  const [updateTaxes, updatedTaxes] = useMutation(UPDATE_TAX, {
    onError: noopHandler,
  });

  const taxesDetails = useQuery(GET_TAXES_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    updateOnboardingStatus(
      OnboardingArea.SETTINGS,
      OnboardingSection.VIEW_ADD_TAXES,
      OnboardingCheckList.TAXES_FEES,
      OnboardingAction.READ,
    );
  }, [updateOnboardingStatus]);

  useEffect((): void => {
    if (taxesDetails.error) {
      showNotification({
        error: true,
        message: parseApolloError(taxesDetails.error),
      });
    }
  }, [taxesDetails.error, showNotification]);

  useEffect((): void => {
    if (taxesDetails.data) {
      setTaxesData(taxesDetails.data.taxes);
    }
  }, [taxesDetails.data, showNotification]);

  useEffect((): void => {
    if (updatedTaxes.error) {
      showNotification({
        error: true,
        message: parseApolloError(updatedTaxes.error),
      });
    }
  }, [updatedTaxes.error, showNotification]);

  useEffect((): void => {
    if (updatedTaxes.data) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyUpdated'),
      });
    }
  }, [updatedTaxes.data, showNotification, translate]);

  const onPressSave = useCallback(() => {
    if (taxesData.some(tax => !tax.name)) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'name' }),
      });
      return;
    } else if (taxesData.some(tax => !tax.code)) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'code' }),
      });
      return;
    } else if (
      taxesData.some(tax => !isFloat(String(tax.rate) || '') && !tax.rate)
    ) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'rate' }),
      });
      return;
    } else {
      const taxDataTemp: TaxToTaxInput[] = taxesData.map(tax => ({
        id: tax.id,
        name: tax.name,
        code: tax.code,
        rate: parseFloat(tax.rate || '0'),
        taxes: tax.taxes.map(i => i.id),
      }));
      updateTaxes({
        variables: {
          input: taxDataTemp,
        },
      });
    }
  }, [taxesData, updateTaxes, showNotification, translate]);

  const onChange = useCallback(
    (index: number, prop, value): void => {
      const taxesDataTemp = [...taxesData];
      if (Array.isArray(value)) {
        if (!value.length) {
          const taxSelected = taxesDataTemp[index];
          showNotification({
            error: true,
            message: translate('backOfficeTaxes.atLeastOneTaxMustExist', {
              name: taxSelected.name,
            }),
          });
        } else {
          const taxesFiltered = taxesDataTemp.filter(tax =>
            value.includes(tax.id),
          ) as unknown as Tax[];
          taxesDataTemp[index] = {
            ...taxesDataTemp[index],
            taxes: taxesFiltered,
          };
        }
      } else {
        taxesDataTemp[index] = { ...taxesDataTemp[index], [prop]: value };
      }
      if (
        taxesDataTemp.some(tax =>
          tax.rate
            ? !isFloat(String(tax.rate)) || !isLessThanLimit(tax.rate, 100.0)
            : false,
        )
      ) {
        showNotification({
          error: true,
          message: translate('form.requiredField', { fieldName: 'rate' }),
        });
        return;
      }
      setTaxesData(taxesDataTemp);
    },
    [taxesData, showNotification, translate],
  );

  const deleteInStateWhenDeleted = useCallback(
    (index: number, id: string): void => {
      const tempTaxData = [...taxesData];
      if (tempTaxData[index].id === id) {
        tempTaxData.splice(index, 1);
        setTaxesData(tempTaxData);
      }
    },
    [taxesData],
  );

  const onAddTax = useCallback(
    (tax: TaxToTax): void => {
      const tempTaxData = [...taxesData];
      tempTaxData.push(tax);
      setTaxesData(tempTaxData);
    },
    [taxesData],
  );

  const onPressDelete = useCallback(
    (index: number, id: string, name: string): void => {
      showModal(
        <DeleteModal
          index={index}
          id={id}
          name={name}
          onDeleteCallBack={deleteInStateWhenDeleted}
        />,
      );
    },
    [showModal, deleteInStateWhenDeleted],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(mainStyle)}>
          <TaxesSection
            taxes={taxesData}
            onChange={onChange}
            openDeleteModal={onPressDelete}
            onAddTax={onAddTax}
          />
          <TaxesGroupSection
            taxes={taxesData}
            onChange={onChange}
            openDeleteModal={onPressDelete}
            onAddTax={onAddTax}
          />
          <View style={css(bottomSpace)}></View>
        </ScrollView>

        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
