import React, { useCallback } from 'react';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import TableComponent from '../../../../../../components/TableComponent/TableComponent';
import { Helmet } from 'react-helmet';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { Printer } from '@hitz-group/domain';
import PrintersListRow from './PrintersListRow';
import { PrintersListStyles } from '../styles/Printers.styles';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import Button from '../../../../../../components/Button/Button';
import { useNavigation } from '@react-navigation/native';
import { NEW_PRINTER_ID } from '../constants';

interface Props {
  printers: Printer[];
  options: { loading: boolean };
  storeId: string;
  onChange: (id: string, prop: string, value: string) => void;
  onCopy: (id: string) => void;
  onDelete: (id: string) => void;
  onSave: () => void;
}

const PrintersList: React.FC<Props> = ({
  printers,
  options: { loading },
  storeId,
  onChange,
  onCopy,
  onDelete,
  onSave,
}) => {
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const styles = PrintersListStyles();

  const COLUMNS = [
    {
      title: translate('backOfficeSettings.printerName'),
      flex: 2,
    },
    {
      title: translate('backOfficeSettings.address'),
      width: 180,
      alignItems: 'flex-start',
    },
    {
      title: translate('backOfficeSettings.printerType'),
      width: 180,
      alignItems: 'flex-start',
    },
    { title: '', flex: 1 },
  ].map(columnName => ({
    ...columnName,
  }));

  const onCreateNew = useCallback(() => {
    navigation.navigate('PrinterDetails', {
      printerId: NEW_PRINTER_ID,
      storeId,
    });
  }, [navigation, storeId]);

  return (
    <View style={styles.pageStyle}>
      <View style={styles.mainView}>
        <Helmet>
          <title>
            {translate('navigation.generalSettingsPageTitle', {
              appName: translate('appName'),
            })}
          </title>
        </Helmet>

        {loading ? (
          <LoadingIndicator />
        ) : (
          <ScrollView>
            <BackOfficeSection
              title={translate('backOfficeSettings.printers')}
              actionContainerStyle={styles.actionContainerStyle}
              action={<BackOfficeCreateNewButton onPress={onCreateNew} />}
              containerStyle={styles.containerStyle}
            >
              <TableComponent
                normalRows={true}
                columns={COLUMNS}
                columnContainerStyle={styles.columnContainerStyle}
                data={printers}
                renderRow={(item: Printer, index: number) => (
                  <PrintersListRow
                    index={index}
                    key={item.id}
                    printer={item}
                    storeId={storeId}
                    onChange={onChange}
                    onCopy={onCopy}
                    onDelete={onDelete}
                  />
                )}
              />
            </BackOfficeSection>
          </ScrollView>
        )}
      </View>
      <View style={styles.actionsContainerStyle}>
        <Button
          fluid
          testID="save-changes"
          title={translate('button.saveChanges')}
          containerStyle={styles.saveButtonStyle}
          labelStyle={styles.titleStyle}
          onPress={onSave}
        />
      </View>
    </View>
  );
};

export default PrintersList;
