import { getLoyaltyUnit } from '@hitz-group/client-utils';
import {
  Customer,
  OrderAction,
  RewardRule,
  StyleFn,
  UpdateCustomerEvent,
} from '@hitz-group/domain';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, Text, View } from 'react-native';
import { useLoyalty } from '../../../hooks/app/loyalty/useLoyalty';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import Gradient from '../../Gradient/Gradient';
import Modal from '../Modal';
import RewardDisplay from './RewardDisplay';
import { usePosState } from '../../../hooks/POSStateProvider';
import { useCartContext as useCart } from '../../../hooks/CartProvider';

const containerStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'center',
  alignItems: 'center',
  borderRadius: theme.radius.large,
  backgroundColor: theme.colors.white,
  paddingVertical: 0,
  paddingHorizontal: 0,
  margin: 0,
  width: 440,
  maxWidth: '100%',
});

const customBodyStyle: StyleFn = () => ({
  marginTop: 0,
  paddingTop: 0,
  paddingBottom: 0,
});

const emptySectionStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  height: 250,
  padding: theme.padding.xl,
  borderRadius: theme.radius.medium,
  margin: theme.spacing.medium,
  alignItems: 'center',
  justifyContent: 'center',
});

const emptySectionFontStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.textLight,
});

/* Reward Styles */
const rewardContainerStyle: StyleFn = ({ theme }) => ({
  padding: theme.padding.large,
  paddingBottom: theme.padding.large - theme.spacing.small,
});

const rewardItemStyle: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.small,
});

const rewardPlaceholderStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.medium,
  height: 76,
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  padding: theme.padding.large,
  paddingTop: 0,
});

/* Heading Styles */
const headingContainer: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: 200,
  color: theme.colors.white,
});

const closeButtonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: 'transparent',
  position: 'absolute',
  right: 'auto',
  left: theme.spacing.small * 2.5,
  top: theme.spacing.small * 2.5,
  zIndex: 9,
});

const pointBalanceStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Bold,
  fontSize: theme.fontSize.largest,
  color: theme.colors.white,
  lineHeight: 34,
});
const pointAvailableStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.white,
  opacity: 0.6,
});
const customerNameStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.white,
  marginTop: theme.padding.large,
  marginBottom: theme.padding.small,
});
const sinceStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.white,
  fontSize: 12,
  opacity: 0.6,
});

export interface CustomerRewardModalProps {
  customer?: Customer | undefined;
  onRedeem: (rewards?: RewardRule[]) => void;
  onDismiss?: () => void;
}

const CustomerRewardModal: React.FC<CustomerRewardModalProps> = ({
  customer,
  onRedeem,
  onDismiss,
}) => {
  const { translate } = useTranslation();
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const [appliedRewardMap, setAppliedRewardMap] = useState<{
    [key: string]: { quantity: number; points: number }; // reward id => { quantity, points }
  }>({});
  const {
    loading: loadingLoyalty,
    getLoyaltyPrograms,
    loyaltySettings,
    rewardRules,
  } = useLoyalty();
  const { formatCurrency, unAppendCurrency, decimalSeparator } = useCurrency();
  const { updateCart } = useCart();
  const { enrollCustomerLoyalty, loading } = usePosState('customers');
  useEffect(() => {
    getLoyaltyPrograms();
  }, [getLoyaltyPrograms]);

  const onRewardChange = (reward: RewardRule, quantity: number) => {
    const newRewardMap = {
      ...appliedRewardMap,
      [reward.id]: { quantity, points: reward.pointsRequired },
    };
    setAppliedRewardMap(newRewardMap);
  };

  const handleClose = () => {
    onDismiss && onDismiss();
    closeModal();
  };

  const handleRedeem = () => {
    onRedeem();
  };

  const remainingBalance = useMemo(
    () =>
      Object.keys(appliedRewardMap).reduce((acc, curr) => {
        const mapValue = appliedRewardMap[curr];
        const cost = mapValue.points * mapValue.quantity;
        return acc - cost;
      }, customer?.loyaltyPoints || 0),
    [appliedRewardMap, customer],
  );

  const shouldDisableIncrement = (reward: RewardRule): boolean =>
    remainingBalance < reward.pointsRequired;

  const formatBalance = useCallback(
    (value: number) => {
      const currencyString = formatCurrency(value);
      const withoutCurrency = unAppendCurrency(currencyString);
      const withoutDecimal = withoutCurrency.split(decimalSeparator)[0];
      return withoutDecimal.padStart(2, '0');
    },
    [formatCurrency, unAppendCurrency, decimalSeparator],
  );
  const formatSinceDate = (date: number) => {
    return format(date, 'dd/MM/yy');
  };

  const filteredRewards = rewardRules.filter(
    reward => reward.pointsRequired <= (customer?.loyaltyPoints || 0),
  );

  const onEnrollLoyalty = useCallback(async () => {
    await enrollCustomerLoyalty({ customerId: customer?.id as string });
    updateCart<UpdateCustomerEvent>(OrderAction.ORDER_UPDATE_CUSTOMER, {
      loyaltyMember: true,
      isLoyaltyApplied: true,
    });
    closeModal();
  }, [closeModal, customer?.id, enrollCustomerLoyalty, updateCart]);

  return (
    <Modal
      onDismiss={handleClose}
      contentStyle={css(containerStyle)}
      customBodyStyle={customBodyStyle}
      showCloseButton={false}
    >
      {!loadingLoyalty && !isEmpty(loyaltySettings) && (
        <>
          <Gradient
            style={css(headingContainer)}
            colors={['rgb(48,35,174)', 'rgb(62,130,240)']}
            start={theme.gradient.startAxis}
            end={theme.gradient.endAxis}
            locations={theme.gradient.location}
          >
            <IconButton
              containerStyle={css(closeButtonStyle)}
              icon="times"
              color="white"
              iconSize={25}
              onPress={handleClose}
              testID="dismiss"
            />
            <Text style={css(pointBalanceStyle)} testID="balance">
              {customer?.loyaltyPoints &&
              remainingBalance != customer?.loyaltyPoints
                ? translate('customerLoyalty.remainingOfTotal', {
                    remaining: formatBalance(remainingBalance),
                    total: formatBalance(customer?.loyaltyPoints || 0),
                  })
                : formatBalance(remainingBalance)}
            </Text>
            <Text style={css(pointAvailableStyle)}>
              {translate('customerLoyalty.pointsAvailable', {
                unit: getLoyaltyUnit(remainingBalance, loyaltySettings),
              })}
            </Text>
            <Text style={css(customerNameStyle)} testID="customer-name">
              {customer?.name || translate('customerLoyalty.noCustomerAdded')}
            </Text>
            <Text style={css(sinceStyle)}>
              {customer && !customer?.loyaltyMember // not loyalty
                ? translate('customerLoyalty.notEnrolled')
                : customer?.createdAt // since
                ? translate('customerLoyalty.sinceDate', {
                    date: formatSinceDate(customer.createdAt),
                  })
                : 'N/A'}
            </Text>
          </Gradient>
          {customer?.loyaltyMember ? (
            <>
              <ScrollView style={css(rewardContainerStyle)}>
                {filteredRewards.length ? (
                  <>
                    {filteredRewards.map(reward => (
                      <RewardDisplay
                        key={reward.id}
                        reward={reward}
                        loyaltySettings={loyaltySettings}
                        onChange={onRewardChange}
                        quantity={appliedRewardMap[reward.id]?.quantity || 0}
                        disableIncrement={shouldDisableIncrement(reward)}
                        style={css(rewardItemStyle)}
                      />
                    ))}
                    {/* render placeholder in case rewards are lacking */}
                    {filteredRewards.length < 3
                      ? [...Array(3 - filteredRewards.length)].map(
                          (item, idx) => (
                            <View
                              key={idx}
                              style={[
                                css(rewardPlaceholderStyle),
                                css(rewardItemStyle),
                              ]}
                            />
                          ),
                        )
                      : null}
                  </>
                ) : (
                  <View style={[css(emptySectionStyle), css({ margin: 0 })]}>
                    <Text
                      testID="empty-reward"
                      style={css(emptySectionFontStyle)}
                    >
                      {translate('customerLoyalty.noRewardAvailable')}
                    </Text>
                  </View>
                )}
              </ScrollView>
              <View style={css(buttonContainerStyle)}>
                <Button
                  title={translate('customerLoyalty.redeemRewards')}
                  success
                  disabled={
                    !customer.loyaltyPoints ||
                    remainingBalance == customer.loyaltyPoints
                  }
                  onPress={handleRedeem}
                  testID="redeem"
                />
              </View>
            </>
          ) : (
            <View>
              <View style={css(emptySectionStyle)}>
                <Text style={css(emptySectionFontStyle)}>
                  {translate('customerLoyalty.enrollToEarnAndRedeem')}
                </Text>
              </View>
              <View style={css(buttonContainerStyle)}>
                <Button
                  title={translate('customerLoyalty.enrollCustomer')}
                  success
                  testID="enroll-loyalty"
                  onPress={onEnrollLoyalty}
                  loading={loading}
                />
              </View>
            </View>
          )}
        </>
      )}
    </Modal>
  );
};

export default CustomerRewardModal;
