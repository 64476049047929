import { FeatureContext, Features } from '@hitz-group/domain';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useCheckFeatureEnabled } from '../../../../src/hooks/app/features/useCheckFeatureEnabled';
import { GeneralSettingsScreen } from './GeneralSettingsScreen';
import OtherSettingsStack from './OtherSettings/OtherSettingsStack';
import { PaymentsTypes } from './PaymentTypes/PaymentsTypes';
import PrinterProfileAndReceiptStack from './PrinterProfilesAndReceipt/PrinterProfileAndReceiptStack';
import { SalesChannelScreen } from './SalesChannel/SalesChannelScreen';
import SectionSettingsTabs from './SectionSettings/SectionSettingsTabs';
import DeviceProfileSettingsNavigator from './StoreSettings/DeviceProfiles/DeviceProfileSettingsNavigator';
import DeviceTabs from './StoreSettings/Devices/DeviceTabs';
import PrinterTabs from './StoreSettings/Printers/PrinterTabs';
import StoreSettingsTabs from './StoreSettings/StoreSettingsTabs';
import TaxesAndFeesStack from './TaxesAndFees/TaxesAndFeesStack';
import { VenueSettingsScreen } from './Venues/VenuesScreen';
import MyStoreSettingsTabs from './VenueSettings/MyStoreSettings/MyStoreSettingsTabs';
import VenueSettingsTabs from './VenueSettings/VenueSettingsTabs';

const Stack = createStackNavigator();

const SettingsStack: React.FC = () => {
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isMultiVenueEnabled = isFeatureEnabled(
    Features.MULTI_VENUE,
    FeatureContext.ORGANIZATION,
  );
  return (
    <Stack.Navigator
      headerMode="none"
      initialRouteName="General"
      screenOptions={{
        animationEnabled: true,
        cardOverlayEnabled: false,
        cardShadowEnabled: false,
      }}
    >
      <Stack.Screen component={GeneralSettingsScreen} name="General" />
      {isMultiVenueEnabled ? (
        <Stack.Screen component={VenueSettingsScreen} name="VenuesStores" />
      ) : (
        <Stack.Screen component={MyStoreSettingsTabs} name="MyStore" />
      )}
      <Stack.Screen component={TaxesAndFeesStack} name="TaxesFees" />
      <Stack.Screen component={PaymentsTypes} name="PaymentTypes" />
      <Stack.Screen component={VenueSettingsTabs} name="VenueSettings" />
      <Stack.Screen component={StoreSettingsTabs} name="StoreSettings" />
      <Stack.Screen
        component={DeviceProfileSettingsNavigator}
        name="DeviceProfileSettings"
      />
      <Stack.Screen component={DeviceTabs} name="DeviceSettings" />
      <Stack.Screen component={SalesChannelScreen} name="SalesChannels" />
      <Stack.Screen component={PrinterTabs} name="PrinterDetails" />
      <Stack.Screen component={PrinterProfileAndReceiptStack} name="Printing" />
      <Stack.Screen component={OtherSettingsStack} name="Other" />
      <Stack.Screen component={SectionSettingsTabs} name="SectionSettings" />
    </Stack.Navigator>
  );
};

export default SettingsStack;
