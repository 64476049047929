import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import { View, StyleSheet } from 'react-native';
import Button from '../Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';
import IconButton from '../Button/IconButton';
import LogRocket from '@logrocket/react-native';
import FormInput from '../FormInput/FormInput';
import { TextArea } from '../TextArea/TextArea';
import TitleBar from '../TitleBar/TitleBar';
import PopupView from '../PopupView/PopupView';

const containerStyle: StyleFn = ({ theme }) => ({
  width: 350,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  flex: 1,
});

const popupStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.medium * 2.5,
  minHeight: 235,
});

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 15,
});

const submitButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  textTransform: 'uppercase',
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
  letterSpacing: 0,
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  height: 38,
  width: 300,
  marginLeft: 0,
  backgroundColor: theme.colors.blue,
  alignSelf: 'center',
  marginTop: theme.spacing.big,
});

const styles = StyleSheet.create({
  container: {
    width: 660,
    justifyContent: 'center',
  },
  titleField: {
    width: 300,
    justifyContent: 'center',
    marginBottom: 20,
    paddingRight: 10,
  },
  messageField: {
    justifyContent: 'center',
    height: 120,
    paddingHorizontal: 10,
  },
});

const ReportIssueModal: React.FC = ({}) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const [issueTitle, setIssueTitle] = useState('');
  const [issueText, setIssueText] = useState('');

  const onPressConfirm = useCallback(() => {
    LogRocket.captureException(
      { name: 'Customer Issue', message: issueTitle },
      {
        tags: {
          issueType: 'Customer Ticket',
        },
        extra: {
          message: issueText,
        },
      },
    );
    closeModal();
  }, [issueTitle, issueText, closeModal]);

  const onCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const titleLeft = (
    <IconButton
      testID={'modal-close-button'}
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={onCloseModal}
    />
  );

  return (
    <View style={css(containerStyle)}>
      <TitleBar
        testID={'noteModal-title'}
        titleLeft={titleLeft}
        primary
        title={'Report Issue'}
      />
      <PopupView containerStyle={css(popupStyle)}>
        <FormInput
          placeholder="Add title..."
          testID="title-field"
          title={'Issue title'}
          onChangeText={setIssueTitle}
          value={issueTitle}
          containerStyle={styles.titleField}
          maxLength={100}
        />
        <TextArea
          placeholder="Full description of the issue..."
          testID="message-field"
          value={issueText}
          onChangeText={setIssueText}
          containerStyle={styles.messageField}
          maxLength={300}
        />
        <Button
          testID="submit-btn"
          onPress={onPressConfirm}
          labelStyle={css(submitButtonStyle)}
          containerStyle={css(buttonContainerStyle({ theme, success: true }))}
          title={'Submit issue'}
        />
      </PopupView>
    </View>
  );
};

export default ReportIssueModal;
