import React from 'react';
import { Platform } from 'react-native';
import { IntercomProvider as Provider } from 'react-use-intercom';
import CloseButton from '../../components/IntercomCloseButton/IntercomCloseButton';

export interface IntercomProviderProps {
  children: React.ReactNode;
}

const INTERCOM_APP_ID = process.env['REACT_APP_INTERCOM_APP_ID'] || '';

export const IntercomProvider: React.FC<IntercomProviderProps> = ({
  children,
}: IntercomProviderProps) => {
  if (Platform.OS !== 'web') return <>{children}</>;
  return (
    <>
      <Provider appId={INTERCOM_APP_ID}>
        {children}
        <CloseButton />
      </Provider>
    </>
  );
};
