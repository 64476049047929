import { OrderItemStatus, PrinterProfileType } from '@hitz-group/domain';
import { validatePrintConfigurationOrInput } from '../../../utils/printerTemplates/printingDataUtils';
import { Notification } from '../../../hooks/Notification';
import {
  BufferHandlerResult,
  DEFAULT_NET_PRINTER_PORT,
  PrintKitchenDocketWorkerInput,
  WorkerInput,
} from '../../utils';
import {
  groupItemsByPrinterProfile,
  getPrintableKitchenOrder,
} from '../../../utils/printerTemplates/kitchenReceiptLayout';

export const printKitchenDocketHandler = (
  message: WorkerInput,
): BufferHandlerResult[] => {
  const results: BufferHandlerResult[] = [];

  const {
    order,
    printerTemplateMapping,
    session,
    printItems = [],
    translatedNowCourse,
  } = message.data as PrintKitchenDocketWorkerInput;

  const itemsByPrinterProfiles = groupItemsByPrinterProfile(printItems);

  if (Object.keys(itemsByPrinterProfiles).length === 0) {
    // No items have a assigned printer profiles
    return [];
  }

  const printerProfileIds = Object.keys(itemsByPrinterProfiles);

  const data = validatePrintConfigurationOrInput(
    order,
    {
      ...printerTemplateMapping,
      [PrinterProfileType.KITCHEN]: printerProfileIds.reduce(
        (acc, printerProfileId) => {
          return {
            ...acc,
            [printerProfileId]:
              printerTemplateMapping[PrinterProfileType.KITCHEN][
                printerProfileId
              ],
          };
        },
        {},
      ),
    },
    PrinterProfileType.KITCHEN,
  );

  if (Object.keys(data).length > 0 && (data as Notification).message) {
    throw new Error((data as Notification).message);
  }

  const kitchenPrinterProfiles =
    printerTemplateMapping[PrinterProfileType.KITCHEN];

  // Iterate through all the printer profile currently available with cart original order items
  // Send the printable (partial) items to printers with respective templates
  printerProfileIds
    .filter(
      printerProfileId => itemsByPrinterProfiles[printerProfileId]?.length,
    )
    .map(async printerProfileId => {
      if (!kitchenPrinterProfiles[printerProfileId]) {
        return;
      }
      const { printer, template } = kitchenPrinterProfiles[printerProfileId];

      const printablePartialOrders = [];
      const printItems = itemsByPrinterProfiles[printerProfileId];

      const voidItems = printItems.filter(
        item => item.status === OrderItemStatus.VOID,
      );

      const newItems = printItems.filter(
        item =>
          item.status === OrderItemStatus.IN_PROGRESS ||
          item.status === OrderItemStatus.COMPLETED,
      );

      if (newItems.length) {
        printablePartialOrders.push(
          getPrintableKitchenOrder({
            order,
            printItems: newItems,
            session,
            template,
            translatedNowCourse,
          }),
        );
      }
      if (voidItems.length) {
        printablePartialOrders.push(
          getPrintableKitchenOrder({
            order,
            printItems: voidItems,
            session,
            template,
            hasVoidOrderItem: true,
            translatedNowCourse,
          }),
        );
      }

      printablePartialOrders.forEach(printablePartialOrder => {
        printablePartialOrder &&
          results.push({
            printer: {
              id: printer.id,
              device_name: printer.name,
              host: printer.ipAddress,
              port: DEFAULT_NET_PRINTER_PORT,
              type: printer.printerType,
              bdAddress: printer.bdAddress,
              brand: printer.brand,
            },
            buffer: printablePartialOrder,
          });
      });
    });

  return results;
};
