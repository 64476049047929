import React, { useEffect, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../components/FormInput/FormInput';
import IconButton from '../../../../components/Button/IconButton';

import { useTranslation } from '@hitz-group/localization';
import EarningRuleRow from './EarningRuleRow';
import RewardRuleRow from './RewardRuleRow';

import { useLoyaltyStyle } from './styles';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import {
  CreateAndUpdateEarningRule,
  EarningRule,
  RewardRule,
  CreateAndUpdateRewardRule,
  LoyaltySettings,
} from '@hitz-group/domain';
import { useLoyalty } from '../../../../hooks/app/loyalty/useLoyalty';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import { useVenues } from '../../../../hooks/app/useVenues';
import CreateAndEditEarningPointModal from './EarningRuleModal';
import CreateAndEditRewardRuleModal from './RewardRuleModal';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import Button from '../../../../components/Button/Button';

import isEqual from 'lodash/isEqual';

const LoyaltySettingScreen: React.FC = () => {
  const { translate } = useTranslation();
  const { theme } = useFela();
  const styles = useLoyaltyStyle();
  const { showModal, closeModal } = useModal();
  const [loyaltySettings, setLoyaltySettings] = useState<
    Partial<LoyaltySettings>
  >({});
  const {
    getLoyaltyPrograms,
    loyaltySettings: loyaltySettingsProp,
    earningRules,
    rewardRules,
    loading,
    createAndUpdateEarningRule,
    createAndUpdateRewardRules,
    deleteEarningRule,
    deleteRewardRule,
    updateLoyaltySetting,
  } = useLoyalty();
  const { getVenues, venues } = useVenues();

  useEffect(() => {
    getLoyaltyPrograms();
    getVenues();
  }, [getLoyaltyPrograms, getVenues]);

  useEffect(() => {
    if (loyaltySettingsProp) setLoyaltySettings(loyaltySettingsProp);
  }, [loyaltySettingsProp]);

  const onCreateOrUpdateEarningRule = useCallback(
    (earningRule: CreateAndUpdateEarningRule) => {
      closeModal();
      createAndUpdateEarningRule(earningRule);
    },
    [closeModal, createAndUpdateEarningRule],
  );
  const onCreateOrEditEarningRule = useCallback(
    (selectedItem?: EarningRule) => {
      const editingValue = selectedItem
        ? {
            ...selectedItem,
            venueIds: selectedItem?.venueIds || [],
            earningPoint: selectedItem.earningPoint.toString(),
            amountSpend: selectedItem.amountSpend.toString(),
          }
        : undefined;
      showModal(
        <CreateAndEditEarningPointModal
          allVenues={Object.values(venues)}
          editingEarningPoint={editingValue}
          onCreateAndUpdate={onCreateOrUpdateEarningRule}
          loyaltySettings={loyaltySettingsProp}
        />,
      );
    },
    [onCreateOrUpdateEarningRule, showModal, venues, loyaltySettingsProp],
  );

  const onCreateAndUpdateRewardRule = useCallback(
    (rewardRuleData: CreateAndUpdateRewardRule) => {
      closeModal();
      createAndUpdateRewardRules(rewardRuleData);
    },
    [createAndUpdateRewardRules, closeModal],
  );

  const onCreateOrEditRewardRule = useCallback(
    (selectedItem?: RewardRule) => {
      const editingRewardRule = selectedItem
        ? {
            ...selectedItem,
            venueIds: selectedItem?.venueIds || [],
            pointsRequired: selectedItem.pointsRequired.toString(),
            discountAmount: (selectedItem?.discountAmount || '').toString(),
            maximumDiscountAmount: (
              selectedItem?.maximumDiscountAmount || ''
            ).toString(),
          }
        : undefined;
      showModal(
        <CreateAndEditRewardRuleModal
          allVenues={Object.values(venues)}
          onCreateAndUpdate={onCreateAndUpdateRewardRule}
          editingRewardRule={editingRewardRule}
          loyalSettings={loyaltySettingsProp}
        />,
      );
    },
    [onCreateAndUpdateRewardRule, showModal, venues, loyaltySettingsProp],
  );

  const onDeleteRuleItem = useCallback(
    (id: string, type: 'earningType' | 'rewardType') => {
      const onConfirmDeleteRule = () => {
        closeModal();
        if (type === 'earningType') {
          deleteEarningRule(id);
        } else {
          deleteRewardRule(id);
        }
      };
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate(
            type === 'earningType'
              ? 'backOfficeLoyalty.deleteEarningRule'
              : 'backOfficeLoyalty.deleteRewardRule',
          )}
          onConfirm={onConfirmDeleteRule}
        />,
      );
    },
    [closeModal, deleteEarningRule, deleteRewardRule, showModal, translate],
  );

  const onChangeLoyalSettings = useCallback((key: string, value: string) => {
    setLoyaltySettings(pre => ({
      ...pre,
      [key]: value,
    }));
  }, []);

  const onPressSaveGeneralSetting = useCallback(() => {
    updateLoyaltySetting(loyaltySettings);
  }, [loyaltySettings, updateLoyaltySetting]);

  const hasChangeGeneralSettings = !isEqual(
    loyaltySettings,
    loyaltySettingsProp,
  );

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.courseSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={styles.pageStyle}>
        <ScrollView contentContainerStyle={styles.scrollStyle}>
          <BackOfficeSection
            title={translate('backOfficeLoyalty.general')}
            contentContainerStyle={styles.formStyle}
            containerStyle={styles.containerStyle}
          >
            <View style={styles.settingRowContainer}>
              <FormInput
                testID="singular-term"
                value={loyaltySettings?.singularTerm}
                title={translate('backOfficeLoyalty.singularTerm')}
                alignTitle="left"
                containerStyle={styles.formInputContainerStyle}
                maxLength={50}
                textStyle={styles.textStyle}
                onChangeText={onChangeLoyalSettings.bind(null, 'singularTerm')}
              />
              <FormInput
                testID="plural-term"
                value={loyaltySettings?.pluralTerm}
                title={translate('backOfficeLoyalty.pluralTerm')}
                alignTitle="left"
                containerStyle={styles.formInputContainerStyle}
                maxLength={50}
                textStyle={styles.textStyle}
                onChangeText={onChangeLoyalSettings.bind(null, 'pluralTerm')}
              />
            </View>
          </BackOfficeSection>
          <BackOfficeSection
            title={translate('backOfficeLoyalty.earningPoints', {
              points: loyaltySettingsProp.pluralTerm,
            })}
            contentContainerStyle={styles.formStyle}
            containerStyle={styles.containerStyle}
          >
            <TableComponent
              data={earningRules}
              normalRows
              columnContainerStyle={styles.columnContainerStyle}
              showPagination
              paginationStyle={styles.paginationStyle}
              columns={[
                {
                  title: translate('backOfficeLoyalty.value'),
                  width: 100,
                  containerStyle: { paddingLeft: 14 },
                  alignItems: 'flex-start',
                },
                {
                  title: translate('backOfficeLoyalty.description'),
                  width: 300,
                  containerStyle: { paddingLeft: 14 },
                  alignItems: 'flex-start',
                },
                {
                  title: translate('backOfficeLoyalty.venues'),
                  width: 50,
                  containerStyle: { paddingLeft: 14 },
                },
              ]}
              renderRow={(
                item: EarningRule,
                index: number,
              ): React.ReactNode => (
                <EarningRuleRow
                  item={item}
                  key={index}
                  onEdit={() => onCreateOrEditEarningRule(item)}
                  pluralTerm={loyaltySettingsProp?.pluralTerm || ''}
                  singularTerm={loyaltySettingsProp?.singularTerm || ''}
                  onDeleteItem={() => onDeleteRuleItem(item.id, 'earningType')}
                  disabled={earningRules.length == 1}
                />
              )}
            />
            <IconButton
              containerStyle={styles.addIconContainer}
              icon="plus"
              iconSize={18}
              iconStyle={styles.addIcon}
              iconColor={theme.colors.green}
              onPress={() => onCreateOrEditEarningRule()}
              testID="add-earning-rule"
            />
          </BackOfficeSection>
          <BackOfficeSection
            title={translate('backOfficeLoyalty.redeemReward')}
            contentContainerStyle={styles.formStyle}
            containerStyle={styles.containerStyle}
          >
            <TableComponent
              data={rewardRules}
              normalRows
              columnContainerStyle={styles.columnContainerStyle}
              showPagination
              paginationStyle={styles.paginationStyle}
              columns={[
                {
                  title: translate('backOfficeLoyalty.value'),
                  width: 100,
                  containerStyle: { paddingLeft: 14 },
                  alignItems: 'flex-start',
                },
                {
                  title: translate('backOfficeLoyalty.description'),
                  width: 300,
                  containerStyle: { paddingLeft: 14 },
                  alignItems: 'flex-start',
                },
                {
                  title: translate('backOfficeLoyalty.venues'),
                  width: 50,
                  containerStyle: { paddingLeft: 14 },
                },
              ]}
              renderRow={(item: RewardRule, index: number): React.ReactNode => (
                <RewardRuleRow
                  item={item}
                  key={index}
                  onEdit={() => onCreateOrEditRewardRule(item)}
                  pluralTerm={loyaltySettingsProp?.pluralTerm || ''}
                  singularTerm={loyaltySettingsProp?.singularTerm || ''}
                  onDeleteItem={() => onDeleteRuleItem(item.id, 'rewardType')}
                  disabled={rewardRules.length === 1}
                />
              )}
            />
            <View>
              <IconButton
                containerStyle={styles.addIconContainer}
                icon="plus"
                iconSize={18}
                iconStyle={styles.addIcon}
                iconColor={theme.colors.green}
                onPress={() => onCreateOrEditRewardRule()}
                testID="add-reward-rule"
              />
            </View>
          </BackOfficeSection>
        </ScrollView>
      </View>
      <View style={styles.mainStyle}>
        {hasChangeGeneralSettings && (
          <View
            style={styles.actionsContainerStyle}
            testID="save-changes-button-container"
          >
            <Button
              fluid
              testID="save-changes-button"
              title={translate('button.saveChanges')}
              containerStyle={styles.saveButtonStyle}
              labelStyle={styles.titleStyle}
              onPress={onPressSaveGeneralSetting}
            />
          </View>
        )}
      </View>
    </>
  );
};

export default LoyaltySettingScreen;
