import { useCurrency } from '@hitz-group/localization';
import { RenderProps, StyleFn } from '@hitz-group/domain';
import React from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { Text, TouchableOpacity, View } from 'react-native';
import IconButton from '../Button/IconButton';

export interface ValueProps {
  value?: number | string;
  onPress?: () => void;
  disableCustomInput?: boolean;
}

export interface PaymentAmountOptionsProps {
  value: number | string;
  onPressEdit?: () => void;
  onPressDiscount?: () => void;
  disableCustomInput?: boolean;
  showEditButton?: boolean;
}

const containerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.primary,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '20%',
  borderRadius: theme.radius.small,
  elevation: 4,
  width: '100%',
  position: 'relative',
});

const textStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.warning,
  fontSize: theme.fontSize.medium * 2,
  lineHeight: 36,
  fontFamily: theme.font.semibold,
  textAlign: 'center',
  marginRight: 20,
});

const editContainerStyle: StyleFn = () => ({
  position: 'absolute',
  right: 20,
});

export const Amount: React.FC<ValueProps> = ({ ...props }) => (
  <FelaComponent style={textStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <TouchableOpacity>
        <Text style={style} {...props} />
      </TouchableOpacity>
    )}
  </FelaComponent>
);

const PaymentAmountOptions: React.FC<PaymentAmountOptionsProps> = ({
  value,
  onPressEdit,
  showEditButton,
  disableCustomInput,
}) => {
  const { formatCurrency } = useCurrency();
  const { theme, css } = useFela();
  return (
    <FelaComponent style={containerStyle}>
      {({ style }: RenderProps): React.ReactFragment => (
        <View style={style}>
          <Amount>{formatCurrency(+value || 0)}</Amount>
          {showEditButton && (
            <IconButton
              disabled={disableCustomInput}
              primary
              icon={'pen'}
              iconSize={24}
              containerSize={34}
              containerStyle={css(editContainerStyle)}
              onPress={onPressEdit}
              testID="edit-amount"
              iconColor={
                disableCustomInput ? theme.colors.darkGrey : theme.colors.white
              }
            />
          )}
        </View>
      )}
    </FelaComponent>
  );
};

export default PaymentAmountOptions;
