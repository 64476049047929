import { EscPos } from '@tillpos/xml-escpos-helper';
import format from 'date-fns/format';
import { divider } from './printDivider';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import { pascalCase } from 'pascal-case';
import { Session } from '../../state/Session';
import {
  getFormattedAddress,
  ROW_ONE_SEGMENTS,
  ROW_TWO_SEGMENTS,
} from '../places.util';
import { WALK_IN_CUSTOMER, Shift } from '@hitz-group/domain';
import { formatMoneyValue, translate } from '@hitz-group/localization';
import { isArray } from 'lodash';

const BLANK_ROW_ENTRY = ['None'];

export const shiftTemplate = `
  <?xml version="1.0" encoding="UTF-8"?>
  <document>
    <align mode="center">
      <!-- Organization logo -->
      <line-feed />
      <!--<image density="d24">{{{orgLogo}}}</image> -->
      <!-- store -->
      <align mode="center">
        <bold>
          <text-line size="0:1">{{storeName}}</text-line>
        </bold>
        <line-feed />
        <text-line>{{storeAddress}}</text-line>
        <text-line>{{storeAddressLineTwo}}</text-line>
        <text-line>{{storePhone}}</text-line>
      </align>
      <under-line mode="two-point"></under-line>
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <!-- header -->
      <line-feed />
      <align mode="center">
        <text-line>{{header}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- payments -->
      {{ #payments }}
        <bold>
          <text-line>{{ name }} Payments</text-line>
        </bold>
        <line-feed />
        <align mode="center">
          <text-line>{{ summary }}</text-line>
        </align>
        <line-feed />
        <align mode="center">
          <text-line size="0:0">{{{divider}}}</text-line>
        </align>
        <line-feed />
      {{ /payments }}
      <!-- Tax Summary -->
      <bold>
        <text-line>Tax Summary</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line>{{taxSummaryDetails}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- Sales Summary -->
      <bold>
        <text-line>Sales Summary</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line>{{salesSummaryDetails}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- Customers -->
      <bold>
        <text-line>Customers</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line>{{customerTotals}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- Void Summary -->
      <bold>
        <text-line>Void Summary</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line>{{voidSummaryDetails}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- Product Type Summary -->
      <bold>
        <text-line>Product Type Summary</text-line>
      </bold>
      <line-feed />
      <align mode="center">
        <text-line>{{productTypeSummaryDetails}}</text-line>
      </align>
      <line-feed />
      <align mode="center">
        <text-line size="0:0">{{{divider}}}</text-line>
      </align>
      <line-feed />
      <!-- Footer Note -->
      <align mode="center">
        <text-line size="0:0">{{{footerNote}}}</text-line>
      </align>
      <line-feed />
    </align>
    <paper-cut />
  </document>
  `;

export const getPrintableBuffer = (
  shift: Shift,
  session: Session,
  currency: string,
) => {
  const payload = {
    storeName: session.currentStore?.name || session.currentVenue?.name,
    storeAddress: session.currentVenue?.address
      ? getFormattedAddress(session.currentVenue?.address, ROW_ONE_SEGMENTS)
      : undefined,
    storeAddressLineTwo: session.currentVenue?.address
      ? getFormattedAddress(session.currentVenue?.address, ROW_TWO_SEGMENTS)
      : undefined,
    storePhone: session.currentVenue?.phone,
    header: generateHeaderDetails(shift),
    payments: generatePaymentDetails(shift, currency),
    taxSummaryDetails: generateTaxSummaryDetails(shift, currency),
    salesSummaryDetails: generateSalesSummaryDetails(shift, currency),
    productTypeSummaryDetails: generateProductTypeSummaryDetails(
      shift,
      currency,
    ),
    customerTotals: generateCustomerTotals(shift),
    voidSummaryDetails: generateVoidSummaryDetails(shift, currency),
    footerNote: 'Printed by Till-X POS. Go # paperless',
    divider: divider() + '\n',
  };

  // Generate buffer
  return EscPos.getBufferFromTemplate(
    shiftTemplate,
    payload,
  ) as unknown as Buffer;
};

// Building print components
/**
 * Money movement details section has two columns
 */
const config: TableUserConfig = {
  columns: {
    0: {
      width: 23,
    },
    1: {
      alignment: 'right',
      width: 20,
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

export const generateHeaderDetails = (shift: Shift): string => {
  const shiftTable = [
    ['Shift Type', pascalCase(shift.shiftType, { delimiter: ' ' })],
    [
      `Shift Number #${shift.shiftNumber}`,
      format(
        new Date(+(shift.closedAt || shift.createdAt)),
        'hh:mm a dd-MM-yy',
      ),
    ],
    ['Generated On', format(new Date(), 'hh:mm a dd-MM-yy')],
    ['Created By', shift.createdBy?.name],
    ['Closed By', shift.closedBy?.name],
    [
      'Device',
      shift.closedByDevice
        ? shift.closedByDevice.name
        : shift.createdByDevice.name,
    ],
  ];

  return table(shiftTable, config);
};

export const generatePaymentDetails = (
  shift: Shift,
  currency: string,
): Array<{ name: string; summary: string }> => {
  const payments = shift.salesByPaymentType.map(type => ({
    name: type.paymentType.name,
    summary: table(
      [
        ['Counted', type?.totalCounted || 0],
        ['Recorded', formatMoneyValue(+(type?.recordedAmount || 0), currency)],
        ['Difference', formatMoneyValue(+(type?.variance || 0), currency)],
        [
          'Refund Amount',
          formatMoneyValue(+(type?.refundAmount || 0), currency),
        ],
        ['Refund Count', +(type?.refundsCount || 0)],
        ['', ''],
        [
          'Payments',
          `(${type.salesCount}) ${formatMoneyValue(
            +(type?.amount || 0),
            currency,
          )}`,
        ],
        [
          'Money Movement',
          formatMoneyValue(+(type?.movedMoney || 0), currency),
        ],
        ['Money In', formatMoneyValue(+(type?.moneyIn || 0), currency)],
        ['Money Out', formatMoneyValue(+(type?.moneyOut || 0), currency)],
        [
          'Total Amount',
          formatMoneyValue(
            +((type?.amount || 0) + (type?.movedMoney || 0)),
            currency,
          ),
        ],
      ],
      config,
    ),
  }));

  return payments;
};

export const generateTaxSummaryDetails = (
  shift: Shift,
  currency: string,
): string => {
  const totalTax = shift.taxes.reduce(
    (sum, taxSummary) => +sum + +taxSummary.amount,
    0,
  );

  const taxLines = shift.taxes.map(taxSummary => [
    `${taxSummary.tax?.code} ${
      taxSummary.tax?.rate ? ' (' + taxSummary.tax?.rate + '%)' : ''
    }`,
    formatMoneyValue(+(taxSummary?.amount || 0), currency),
  ]);

  const shiftTable = [
    ...taxLines,
    ['Total', formatMoneyValue(totalTax, currency)],
  ];

  return table(shiftTable, config);
};

export const generateSalesSummaryDetails = (
  shift: Shift,
  currency: string,
): string => {
  const totalTax = shift.taxes.reduce(
    (sum, taxSummary) => +sum + +taxSummary.amount,
    0,
  );

  const shiftTable = [
    [
      'Gross Sales (Orders)',
      `(${shift.totalSalesCount}) ${formatMoneyValue(
        +(shift.totalGrossSales || 0),
        currency,
      )}`,
    ],
    ['Discounts', formatMoneyValue(+(shift.totalDiscount || 0), currency)],
    ['Refunds', formatMoneyValue(+(shift.totalRefund || 0), currency)],
    [
      'Rounding Adjustments',
      formatMoneyValue(+(shift.totalRoundingAmount || 0), currency),
    ],
    ['Net Amount', formatMoneyValue(+(shift.totalNetAmount || 0), currency)],
    ['Tax Amount', formatMoneyValue(totalTax, currency)],
    ['Tips', formatMoneyValue(+(shift.tips || 0), currency)],
    ['Total', formatMoneyValue(+(shift.totalSales || 0), currency)],
    ['Cost of sales', formatMoneyValue(+(shift.totalCostPrice || 0), currency)],
    ['Profits', formatMoneyValue(+(shift.profit || 0), currency)],
    ['Total Items Voided', +(shift.voidedItemsCount || 0)],
    [
      'Amount Voided',
      formatMoneyValue(+(shift.totalVoidAmount || 0), currency),
    ],
  ];

  return table(shiftTable, config);
};

export const generateProductTypeSummaryDetails = (
  shift: Shift,
  currency: string,
): string => {
  const totalSales =
    shift.salesByProductType?.reduce(
      (sum, summary) => +sum + +summary.amount,
      0,
    ) || 0;

  const summaryLines =
    shift.salesByProductType?.map(summary => [
      `${summary.productType?.name}`,
      formatMoneyValue(+summary.amount, currency),
    ]) || [];

  const shiftTable = [
    ...summaryLines,
    ['Total', formatMoneyValue(+totalSales, currency)],
  ];

  return table(shiftTable, config);
};

export const generateCustomerTotals = (shift: Shift): string => {
  const customerCounts = {
    walkIn: 0,
    registered: 0,
  };

  const notRegisteredIndex =
    shift?.customers?.findIndex(customer => customer.id === WALK_IN_CUSTOMER) ||
    -1;

  if (notRegisteredIndex !== -1) {
    customerCounts.walkIn = shift?.customers[notRegisteredIndex].count;
  }

  customerCounts.registered =
    shift?.customers?.reduce((acc, customer, index) => {
      if (index === notRegisteredIndex) return acc;
      else return acc + customer.count;
    }, 0) || 0;

  const shiftTable = [
    ['Walk In', `${customerCounts.walkIn}`],
    ['Registered', `${customerCounts.registered}`],
  ];

  return table(shiftTable, config);
};

export const generateVoidSummaryDetails = (
  shift: Shift,
  currency: string,
): string => {
  let shiftTable = [BLANK_ROW_ENTRY];

  if (
    isArray(shift.voidedAmountByReason) &&
    shift.voidedAmountByReason.length > 0
  ) {
    shiftTable = shift.voidedAmountByReason.map(rowData => [
      translate(`enums.${rowData?.reason}`),
      formatMoneyValue(+rowData.amount, currency),
    ]);
  }

  return table(shiftTable, config);
};
