import { StyleFn } from '@hitz-group/domain';

export const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 540,
});

export const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  paddingLeft: theme.spacing.big,
  alignItems: 'center',
  minHeight: '100%',
});

export const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

export const operatingHoursContentStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
  marginLeft: theme.spacing.small,
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const brandingContentStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
  marginLeft: theme.spacing.small,
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
});

export const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

export const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
  ...theme.shadow15,
  paddingLeft: theme.padding.large,
});

export const formDropDownContainerStyle: StyleFn = ({
  theme,
  isRowDisplay,
}) => ({
  width: isRowDisplay ? 260 : 540,
  height: theme.input.height,
  marginLeft: theme.spacing.small,
});

export const storeNameInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 250,
  height: theme.input.height,
  marginLeft: theme.spacing.medium,
});

export const titleTextStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.medium,
});

export const dropdownExtraStyle: StyleFn = ({ theme, isRowDisplay }) => ({
  backgroundColor: theme.colors.white,
  width: isRowDisplay ? 260 : 540,
  height: theme.input.height,
});

export const extraViewStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small * 1.5,
});

export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: 540,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});

export const dropDownTitleStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small / 2,
});

export const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

export const dropDownStyle: StyleFn = ({ theme }) => ({
  minHeight: theme.input.height,
  width: 540,
  marginTop: theme.spacing.small - 2,
  marginLeft: theme.spacing.small,
});

export const touchableStyle: StyleFn = () => ({
  height: 40,
  flexWrap: 'wrap',
});

export const storeSlug: StyleFn = () => ({
  width: '100%',
  flexDirection: 'column',
});

export const menuAndPricingWrapperStyle: StyleFn = ({ isRowDisplay }) => ({
  width: '100%',
  flexDirection: isRowDisplay ? 'row' : 'column',
});

export const locationWrapperStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
  width: '100%',
  flexDirection: 'row',
});

export const locationIdStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
});

export const accountIdStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
  marginLeft: theme.spacing.small,
});

export const autoAcceptOrdersContentStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
  marginLeft: theme.spacing.small,
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const orderAcceptanceDropDown: StyleFn = ({ theme }) => ({
  width: 250,
  height: theme.input.height,
});

export const orderAcceptanceDropDownExtraStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 250,
  height: theme.input.height,
});

export const orderAcceptanceDropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: 250,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});

export const defaultPrepTimeInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 250,
  height: theme.input.height,
  marginLeft: theme.spacing.medium,
});

export const colorPickerContainerStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
  justifyContent: 'center',
  marginRight: theme.spacing.small,
});

export const imagePickerContainerStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
  justifyContent: 'center',
});

export const prefixContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  height: '100%',
  lineHeight: 18,
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: theme.spacing.small,
});

export const prefixTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  borderRadius: theme.radius.small,
  lineHeight: 18,
});

export const formInputContainerStyle: StyleFn = () => ({
  width: '95%',
  flexDirection: 'row',
});

export const textStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});
