import { useTranslation } from '@hitz-group/localization';
import React, { useCallback, useMemo } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import {
  Colors,
  Icons,
  StyleFn,
  TerminalDiagnosisInfo,
  TerminalPrinterStatus,
  TerminalStatus,
} from '@hitz-group/domain';
import IconButton from '../../Button/IconButton';

const labelTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  textTransform: 'capitalize',
});

const labelContainerStyle: StyleFn = () => ({
  flex: 1,
  alignSelf: 'stretch',
  flexDirection: 'row',
  marginBottom: 5,
  minHeight: 25,
});

const labelCellStyle: StyleFn = () => ({
  flex: 0.9,
  alignSelf: 'stretch',
  textAlign: 'left',
});

const labelCellRightAlignStyle: StyleFn = () => ({
  flex: 1.1,
  alignSelf: 'stretch',
  textAlign: 'right',
  alignItems: 'flex-end',
});

const labelColorGreen: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});

const labelColorRed: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

export interface TerminalDiagnosisRowProps {
  label: keyof TerminalDiagnosisInfo;
  value: string | number | boolean | TerminalStatus | TerminalPrinterStatus;
}

export const GREEN_BATTERY_LEVEL = 60;

export const TerminalDiagnosisRow: React.FC<TerminalDiagnosisRowProps> = ({
  label,
  value,
}: TerminalDiagnosisRowProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  const getTextBlock = useCallback(
    (text: string | number, valueStyle?: StyleFn) => {
      return (
        <View style={css(labelCellRightAlignStyle)}>
          <Text
            testID={'diagnosis-text-value'}
            style={valueStyle && css(valueStyle)}
          >
            {text as string | number}
          </Text>
        </View>
      );
    },
    [css],
  );

  const getIconBlock = useCallback(
    (value: boolean) => {
      return (
        <View style={css(labelCellRightAlignStyle)}>
          <IconButton
            testID={'diagnosis-icon-value'}
            disabled={true}
            iconColor={(value as boolean) ? Colors.green : Colors.red}
            icon={(value as boolean) ? Icons.Check : Icons.Times}
          />
        </View>
      );
    },
    [css],
  );

  const labelBlock = useMemo(() => {
    if (typeof value === 'boolean') {
      return getIconBlock(value);
    }

    switch (label) {
      case 'status':
        return getTextBlock(
          translate(
            `paymentTerminal.diagnosis.labelEnums.status.${value as string}`,
          ),
          value === TerminalStatus.OK ? labelColorGreen : labelColorRed,
        );
      case 'printerStatus':
        return getTextBlock(
          translate(
            `paymentTerminal.diagnosis.labelEnums.printerStatus.${
              value as string
            }`,
          ),
          value === TerminalPrinterStatus.OK ? labelColorGreen : labelColorRed,
        );
      case 'batteryLevel':
        const batteryTxt = value as string;
        const batteryLevel = parseFloat(batteryTxt.replace('%', ''));
        return getTextBlock(
          `${batteryLevel}%`,
          batteryLevel > GREEN_BATTERY_LEVEL ? labelColorGreen : labelColorRed,
        );
      default:
        return getTextBlock(value as string | number);
    }
  }, [value, label, getIconBlock, getTextBlock, translate]);

  return (
    <View testID={'diagnosis-info-row'} style={css(labelContainerStyle)}>
      <View style={css(labelCellStyle)}>
        <Text testID={'diagnosis-label-text'} style={css(labelTextStyle)}>
          {translate(`paymentTerminal.diagnosis.labels.${label}`)}
        </Text>
      </View>
      {labelBlock}
    </View>
  );
};
