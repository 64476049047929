import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { Operation } from '../../types/Operation';
import { ApolloError } from '@apollo/client';
import {
  ProductType,
  CreateProductTypeInput,
  UpdateProductTypeInput,
} from '@hitz-group/domain';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import {
  CREATE_PRODUCT_TYPES,
  DELETE_PRODUCT_TYPE,
  GET_PRODUCT_TYPES_QUERY,
  UPDATE_PRODUCT_TYPES,
} from '../../graphql/productTypes';
import keyBy from 'lodash/keyBy';

export interface UseProductTypesProps {
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  productTypes: { [key: string]: ProductType };
  refetch: (() => void) | undefined;
  getProductTypes: () => void;
  createProductTypes: (productTypesInput: CreateProductTypeInput[]) => void;
  createdProductTypeIds: string[];
  updateProductTypes: (productTypesInput: UpdateProductTypeInput[]) => void;
  updatedProductTypeIds: string[];
  deleteProductType: (id: string) => void;
  deletedProductType: boolean;
}

export function useProductTypes(): UseProductTypesProps {
  const [productTypes, setProductTypes] = useState<Record<string, ProductType>>(
    {},
  );

  const [createdProductTypeIds, setCreatedProductTypeIds] = useState<string[]>(
    [],
  );

  const [updatedProductTypeIds, setUpdatedProductTypeIds] = useState<string[]>(
    [],
  );

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [deletedProductType, setDeletedProductType] = useState(false);

  const onCompleteGetProductTypesRequest = useCallback(
    data => {
      if (data) {
        setDeletedProductType(false);
        const productTypesData = data.productTypes as ProductType[];
        const productTypesDict: Record<string, ProductType> = keyBy(
          productTypesData,
          'id',
        );
        setProductTypes(productTypesDict);
      }
    },
    [setProductTypes],
  );

  const [getProductTypes, getProductTypesResponse] = useLazyQuery(
    GET_PRODUCT_TYPES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetProductTypesRequest,
    },
  );

  // create
  const [createProductTypesRequest, createProductTypesResponse] = useMutation(
    CREATE_PRODUCT_TYPES,
    {
      onError: noopHandler,
    },
  );

  const createProductTypes = useCallback(
    (createProductTypesInput: CreateProductTypeInput[]) => {
      createProductTypesRequest({
        variables: {
          input: createProductTypesInput,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createProductTypesRequest],
  );

  useEffect(() => {
    if (createProductTypesResponse.data) {
      const newlyCreatedProductTypes = createProductTypesResponse.data
        .createProductTypes as ProductType[];

      setProductTypes(productTypes => {
        return {
          ...productTypes,
          ...keyBy(newlyCreatedProductTypes || [], 'id'),
        };
      });

      setCreatedProductTypeIds(
        newlyCreatedProductTypes.map(productType => productType.id),
      );
    }
  }, [createProductTypesResponse.data]);

  // create
  const [updateProductTypesRequest, updateProductTypesResponse] = useMutation(
    UPDATE_PRODUCT_TYPES,
    {
      onError: noopHandler,
    },
  );

  useEffect(() => {
    if (updateProductTypesResponse.data) {
      const updatedProductTypes = updateProductTypesResponse.data
        .updateProductTypes as ProductType[];

      setProductTypes(productTypes => {
        return {
          ...productTypes,
          ...keyBy(updatedProductTypes || [], 'id'),
        };
      });
      setUpdatedProductTypeIds(
        updatedProductTypes.map(productType => productType.id),
      );
    }
  }, [updateProductTypesResponse.data]);

  const updateProductTypes = useCallback(
    (updateProductTypesInput: UpdateProductTypeInput[]) => {
      updateProductTypesRequest({
        variables: {
          input: updateProductTypesInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateProductTypesRequest],
  );

  // delete

  const [deleteProductTypeRequest, deleteProductTypeResponse] = useMutation(
    DELETE_PRODUCT_TYPE,
    {
      onError: noopHandler,
    },
  );

  const deleteProductType = useCallback(
    (id: string) => {
      deleteProductTypeRequest({
        variables: {
          id,
        },
      });
      setOperation(Operation.DELETE);
    },
    [deleteProductTypeRequest],
  );

  useEffect(() => {
    if (deleteProductTypeResponse.data) {
      setDeletedProductType(deleteProductTypeResponse.data.deleteProductType);
    }
  }, [deleteProductTypeResponse.data]);

  const refetch = useCallback(() => {
    const refetchedData = getProductTypesResponse.client?.readQuery({
      query: GET_PRODUCT_TYPES_QUERY,
    });
    setProductTypes(prev => {
      const tempPrev = { ...prev };
      const refetchedProductTypes =
        refetchedData?.productTypes as ProductType[];
      if (refetchedProductTypes?.length) {
        refetchedProductTypes.forEach(eachVar => {
          tempPrev[eachVar.id] = eachVar;
        });
      }
      return tempPrev;
    });
    setOperation(Operation.READ);
  }, [getProductTypesResponse.client]);

  const loading: boolean =
    getProductTypesResponse.loading ||
    updateProductTypesResponse.loading ||
    createProductTypesResponse.loading ||
    deleteProductTypeResponse.loading;

  const error: ApolloError | undefined =
    getProductTypesResponse.error ||
    updateProductTypesResponse.error ||
    createProductTypesResponse.error ||
    deleteProductTypeResponse.error;

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
      refetch,
      productTypes,
      getProductTypes,
      createProductTypes,
      createdProductTypeIds,
      updateProductTypes,
      updatedProductTypeIds,
      deleteProductType,
      deletedProductType,
    }),
    [
      loading,
      error,
      operation,
      refetch,
      productTypes,
      getProductTypes,
      createProductTypes,
      createdProductTypeIds,
      updateProductTypes,
      updatedProductTypeIds,
      deleteProductType,
      deletedProductType,
    ],
  );
}
