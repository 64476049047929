import React from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import ModalComponent, { ModalProps } from 'react-native-modal';
import Backdrop from '../components/Modals/Backdrop';

const styles = StyleSheet.create({
  modalStyle: {
    flex: 1,
  },
});

const { height, width } = Dimensions.get('window');

interface ModalWrapperProp {
  isVisible: boolean;
}

const ModalWrapper: React.FC<ModalWrapperProp> = ({ children, isVisible }) => {
  const defaultModalProps: Partial<ModalProps> = {
    deviceHeight: height,
    deviceWidth: width,
    customBackdrop: <Backdrop />,
    animationInTiming: 50,
    animationOutTiming: 50,
    animationIn: 'fadeIn',
    animationOut: 'fadeOut',
  };

  if (!isVisible) return null;

  return (
    <ModalComponent
      {...defaultModalProps}
      isVisible={isVisible}
      useNativeDriver
      hideModalContentWhileAnimating
      hardwareAccelerated={true}
      presentationStyle="overFullScreen"
      style={styles.modalStyle}
    >
      {children}
    </ModalComponent>
  );
};

export default ModalWrapper;
