import { RenderProps, StyleFn, Icons } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { View, ViewStyle, TextStyle, ScrollView } from 'react-native';
import { NavMenu } from '../../state/navigation';
import NavItem from '../BackOfficeNavItem/BackOfficeNavItem';
import { useIntercom } from '../../hooks/Intercom/useIntercom';
import { useSession } from '../../hooks/app/useSession';
import { useTranslation } from '@hitz-group/localization';

export interface NavBarProps {
  menu: Array<NavMenu>;
  active?: string;
  itemIconStyle?: TextStyle;
  itemContainerStyle?: ViewStyle;
  onNavigate?: (route: string) => void;
}

const containerStyle: StyleFn = ({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: 'transparent',
  marginTop: theme.spacing.medium * 2.5,
  marginBottom: theme.spacing.medium,
});

const navItemContainerStyle: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.small,
});

const NavBar: React.FC<NavBarProps> = ({
  menu,
  active,
  onNavigate,
  itemContainerStyle,
  itemIconStyle,
}) => {
  const { css } = useFela();
  const activeMenu = active ? active.split('::')[0] : undefined;
  const selectedChild = active ? active.split('::')[1] : undefined;
  const { translate } = useTranslation();
  const [session] = useSession();
  const Intercom = useIntercom();

  const onPressSupport = useCallback(() => {
    const IntercomUser = {
      email: session?.user?.email || '',
      userId: session?.user?.id || '',
      name: session?.user?.name || '',
      companies: [
        {
          companyId: session?.currentOrganization?.id || '',
          name: session?.currentOrganization?.name || '',
          customAttributes: {
            venueName: session?.currentVenue?.name || '',
            storeName: session?.currentStore?.name || '',
          },
        },
      ],
    };
    Intercom.start(IntercomUser, Intercom.show);
  }, [Intercom, session]);

  return (
    <FelaComponent style={containerStyle}>
      {({ style }: RenderProps): React.ReactChild => (
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={style}>
            {menu.map((x, i) => {
              return (
                <NavItem
                  key={i}
                  active={activeMenu === x.title}
                  title={x.title}
                  icon={x.icon}
                  subnav={x.children}
                  subroute={x.title === activeMenu ? x.route : []}
                  onPress={onNavigate}
                  onSelectSubNav={onNavigate}
                  selected={
                    selectedChild
                      ? selectedChild
                      : x.route && x.route.length > 0
                      ? x.route?.[0]?.routing
                      : x.children[0]
                  }
                  iconStyle={itemIconStyle}
                  contentStyle={itemContainerStyle}
                  containerStyle={css(navItemContainerStyle)}
                />
              );
            })}

            <NavItem
              active={false}
              title={translate('drawer.support')}
              icon={Icons.Chat}
              onPress={onPressSupport}
              iconStyle={itemIconStyle}
              contentStyle={itemContainerStyle}
              containerStyle={css(navItemContainerStyle)}
            />
          </View>
        </ScrollView>
      )}
    </FelaComponent>
  );
};

export default NavBar;
