import React from 'react';
import {
  DateRangeFilter,
  FilterObject,
  Filters,
  FilterValue,
} from '@hitz-group/domain';
import { HeaderStyles } from '../../styles/HourlySales.styles';
import { View } from 'react-native';
import { FilterInput } from '../../UIComponents/FilterInput';
import { DateRangeFilters } from '../../UIComponents/DateRangeFilters';
import IconButton from '../../../../../components/Button/IconButton';
import Button from '../../../../../components/Button/Button';
import { useTranslation } from '@hitz-group/localization';
import { ExportType, ReportTableColumn } from '../../types';
import Icon from '../../../../../components/Icon/Icon';
import MultipleDropDown from '../../../../../components/MultipleSelect/MultipleSelect';
import { HourlySalesReportHelper } from '@hitz-group/analytics-helper';
import { TRADING_PERIOD_KEY } from '../../reportsHelper';

interface HeaderProps {
  allFilters: Filters;
  filters: FilterValue;
  dateRangeFilter: DateRangeFilter;
  updateReport: () => void;
  exportReport: (type: ExportType) => void;
  toggleFilters: () => void;
  updateFilters: (filter: string, value: string[]) => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  columns: {
    all: ReportTableColumn[];
    updateColumns: (columnKeys: string[]) => void;
    selectedColumns: ReportTableColumn[];
  };
}

export const HourlySalesHeader: React.FC<HeaderProps> = ({
  allFilters,
  filters,
  dateRangeFilter,
  columns,
  updateReport,
  exportReport,
  toggleFilters,
  updateFilters,
  updateDateRangeFilters,
}) => {
  const { translate } = useTranslation();
  const styles = HeaderStyles();

  const headerFilter = {
    key: HourlySalesReportHelper.DEFAULT_FILTER,
    title:
      `backOfficeReports.filters.${HourlySalesReportHelper.DEFAULT_FILTER}` as string,
    values: allFilters[HourlySalesReportHelper.DEFAULT_FILTER as keyof Filters],
  };

  const tradingPeriods: FilterObject[] =
    allFilters[TRADING_PERIOD_KEY as keyof Filters];

  const dateRangeStyleOverride = { ...styles.dateRangeFilters, marginTop: 20 };

  return (
    <View style={styles.pageStyle}>
      <View style={styles.row1}>
        <View style={styles.venueFilterView}>
          <FilterInput
            key={0}
            index={0}
            options={headerFilter?.values as FilterObject[]}
            name={headerFilter?.key as string}
            title={headerFilter?.title}
            showLabel={false}
            selectedValues={
              filters[headerFilter?.key as keyof FilterValue] || []
            }
            updateFilters={updateFilters}
          ></FilterInput>
        </View>
        <View style={dateRangeStyleOverride}>
          <DateRangeFilters
            tradingPeriods={tradingPeriods}
            dateRangeFilter={dateRangeFilter}
            updateDateRangeFilters={updateDateRangeFilters}
          ></DateRangeFilters>
        </View>
        <View style={styles.iconStyle}>
          <IconButton
            icon="sliders-v"
            onPress={() => toggleFilters()}
            primary
            containerStyle={styles.filtersButtonStyle}
            iconStyle={styles.filtersIconStyle}
            containerSize={38}
            iconSize={25}
          ></IconButton>
        </View>
        <MultipleDropDown
          containerStyle={styles.iconStyle}
          showSearch={false}
          values={columns.all?.map(col => ({
            label: translate(col.title),
            value: col.title,
          }))}
          selectedValues={columns.selectedColumns?.map(col => col.title)}
          onValueChange={columns.updateColumns}
          collapsedView={
            <View style={styles.columnsButtonStyle}>
              <Icon
                size={25}
                name="table"
                primary
                style={styles.columnsIconStyle}
                disabled={false}
              />
            </View>
          }
          toggleIcon={<></>}
        />
        <View style={styles.updateContainerStyle}>
          <Button
            testID="update-report"
            fluid
            title={translate('backOfficeReports.header.updatedBtn')}
            containerStyle={styles.updateButtonStyle}
            labelStyle={styles.titleStyle}
            onPress={updateReport}
          />
        </View>
        <View style={styles.downloadContainerStyle}>
          <IconButton
            testID="download-report"
            icon="CloudDownload"
            primary
            containerSize={38}
            containerStyle={styles.downloadButtonStyle}
            iconStyle={styles.downloadIconStyle}
            iconSize={25}
            onPress={() => exportReport(ExportType.CSV)}
          ></IconButton>
        </View>
      </View>
    </View>
  );
};
