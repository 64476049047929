import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useState, useEffect, useCallback } from 'react';
import {
  CREATE_PRICING_GROUP,
  UPDATE_PRICING_GROUPS,
  DELETE_PRICING_GROUPS,
  CLONE_PRICING_GROUP,
  GET_PRICING_GROUPS_BY_STORE,
  getPricingGroupsQuery,
  getPricingGroupQuery,
} from '../../graphql/pricingGroups';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import { ApolloError } from '@apollo/client';
import { Operation } from '../../types/Operation';
import {
  DEFAULT_PRICING_GROUP,
  CreatePricingGroupInput,
  UpdatePricingGroupInput,
  ClonePricingGroupInput,
} from '@hitz-group/domain';
import keyBy from 'lodash/keyBy';

import { PricingGroup } from '@hitz-group/domain';
export interface usePricingGroupsProps {
  pricingGroups: { [key: string]: PricingGroup };
  defaultPricingGroup: PricingGroup;
  getPricingGroup: (pricingGroupId: string) => void;
  getAllPricingGroups: () => void;
  getAllPricingGroupsByStore: (store: string) => void;
  createPricingGroup: (
    createPricingGroupInput: Partial<CreatePricingGroupInput>,
  ) => void;
  updatePricingGroups: (
    updatePricingGroupInput: Partial<UpdatePricingGroupInput>[],
  ) => void;
  deletePricingGroups: (ids: string[]) => void;
  clonePricingGroup: (input: ClonePricingGroupInput) => void;
  pricingGroupByName: (input: string) => { [key: string]: PricingGroup };
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  createdId: string;
  deletedIds: string[];
}

export function usePricingGroups(
  doNotPreLoadData?: boolean,
  customFragment?: string,
): usePricingGroupsProps {
  const [pricingGroups, setPricingGroups] = useState<
    Record<string, PricingGroup>
  >({});

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  const [createdId, setCreatedId] = useState<string>('');

  // all graphql operations
  const [getPricingGroups, getPricingGroupsRequest] = useLazyQuery(
    getPricingGroupsQuery(customFragment),
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [getPricingGroup, getPricingGroupRequest] = useLazyQuery(
    getPricingGroupQuery(customFragment),
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getPricingGroupsByStore, getPricingGroupsByStoreResponse] =
    useLazyQuery(GET_PRICING_GROUPS_BY_STORE, {
      fetchPolicy: 'network-only',
    });

  const [createPricingGroup, createPricingGroupRequest] = useMutation(
    CREATE_PRICING_GROUP,
    {
      onError: noopHandler,
    },
  );

  const [deletePricingGroups, deletePricingGroupRequest] = useMutation(
    DELETE_PRICING_GROUPS,
    {
      onError: noopHandler,
    },
  );

  const [updatePricingGroups, updatePricingGroupRequest] = useMutation(
    UPDATE_PRICING_GROUPS,
    {
      onError: noopHandler,
    },
  );

  const [clonePricingGroup, clonePricingGroupRequest] = useMutation(
    CLONE_PRICING_GROUP,
    {
      onError: noopHandler,
    },
  );

  const getAllPricingGroupsData = useCallback(() => {
    getPricingGroups();
    setOperation(Operation.READ);
  }, [getPricingGroups]);

  useEffect(() => {
    if (getPricingGroupsRequest.data) {
      const pricingGroupsData = getPricingGroupsRequest.data
        .pricingGroups as PricingGroup[];
      if (pricingGroupsData.length > 0) {
        setPricingGroups(keyBy(pricingGroupsData, 'id'));
      }
    }
  }, [getPricingGroupsRequest.data]);

  const getAllPricingGroupsByStore = useCallback(
    (storeId: string) => {
      getPricingGroupsByStore({ variables: { filter: { storeId } } });
      setOperation(Operation.READ);
    },
    [getPricingGroupsByStore],
  );

  useEffect(() => {
    if (getPricingGroupsByStoreResponse.data) {
      const pricingGroupsData = getPricingGroupsByStoreResponse.data
        .pricingGroups as PricingGroup[];
      if (pricingGroupsData.length > 0) {
        setPricingGroups(keyBy(pricingGroupsData, 'id'));
      }
    }
  }, [getPricingGroupsByStoreResponse.data]);

  const defaultPricingGroup = useMemo(() => {
    if (Object.keys(pricingGroups).length === 0) {
      // load data when preLoadData is false or undefined
      !doNotPreLoadData && getPricingGroups();
    }
    return Object.values(pricingGroups).find(
      x => x.name === DEFAULT_PRICING_GROUP,
    ) as PricingGroup;
  }, [pricingGroups, getPricingGroups, doNotPreLoadData]);

  const pricingGroupByName = useCallback(
    (name: string) => {
      if (Object.keys(pricingGroups).length === 0) {
        getPricingGroups();
      }
      const filteredData = Object.values(pricingGroups).filter(x =>
        x.name.toLowerCase().includes(name.toLowerCase()),
      ) as PricingGroup[];
      return keyBy(filteredData, 'id');
    },
    [pricingGroups, getPricingGroups],
  );

  const getPricingGroupData = useCallback(
    (pricingGroupId: string) => {
      getPricingGroup({ variables: { id: pricingGroupId } });
      setOperation(Operation.READ);
    },
    [getPricingGroup],
  );

  useEffect(() => {
    if (getPricingGroupRequest.data) {
      const pricingGroupsData = getPricingGroupRequest.data
        .pricingGroup as PricingGroup;
      setPricingGroups(prev => {
        const temp = { ...prev };
        temp[pricingGroupsData.id] = pricingGroupsData;
        return temp;
      });
    }
  }, [getPricingGroupRequest.data]);

  const createPricingGroupData = useCallback(
    (pricingGroup: Partial<CreatePricingGroupInput>) => {
      createPricingGroup({
        variables: {
          input: pricingGroup,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createPricingGroup],
  );

  useEffect(() => {
    if (createPricingGroupRequest.data) {
      const createdPricingGroupsData = createPricingGroupRequest.data
        .createPricingGroup as PricingGroup;
      setPricingGroups(prev => {
        const temp = { ...prev };
        temp[createdPricingGroupsData.id] = createdPricingGroupsData;
        return temp;
      });
      setCreatedId(createdPricingGroupsData.id);
    }
  }, [createPricingGroupRequest.data]);

  const deletePricingGroupsData = useCallback(
    (ids: string[]) => {
      deletePricingGroups({
        variables: {
          ids,
        },
      });
      setOperation(Operation.DELETE);
      setDeletedIds(ids);
    },
    [deletePricingGroups],
  );

  useEffect(() => {
    if (deletePricingGroupRequest.data) {
      setPricingGroups(prev => {
        const tempPricingGroups = { ...prev };
        deletedIds.forEach(x => delete tempPricingGroups[x]);
        return tempPricingGroups;
      });
    }
  }, [deletedIds, deletePricingGroupRequest.data, getPricingGroups]);

  useEffect(() => {
    if (updatePricingGroupRequest.data) {
      const updatedData = updatePricingGroupRequest.data
        .updatePricingGroups as PricingGroup[];
      setPricingGroups(prev => {
        const temp = { ...prev };
        updatedData.forEach(x => {
          temp[x.id] = x;
        });
        return temp;
      });
    }
  }, [updatePricingGroupRequest.data]);

  const updatePricingGroupsData = useCallback(
    (updatePricingGroupsData: Partial<UpdatePricingGroupInput>[]) => {
      updatePricingGroups({
        variables: {
          input: updatePricingGroupsData,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updatePricingGroups],
  );

  useEffect(() => {
    if (clonePricingGroupRequest.data) {
      const clonedPricingGroupData = clonePricingGroupRequest.data
        .clonePricingGroup as PricingGroup;
      setPricingGroups(prev => {
        const temp = { ...prev };
        temp[clonedPricingGroupData.id] = clonedPricingGroupData;
        return temp;
      });
      setCreatedId(clonedPricingGroupData.id);
    }
  }, [clonePricingGroupRequest.data]);

  const clonePricingGroupData = useCallback(
    (clonePricingGroupsData: ClonePricingGroupInput) => {
      clonePricingGroup({
        variables: {
          input: clonePricingGroupsData,
        },
      });
      setOperation(Operation.CREATE);
    },
    [clonePricingGroup, setOperation],
  );

  const error: ApolloError | undefined =
    getPricingGroupsRequest.error ||
    getPricingGroupRequest.error ||
    createPricingGroupRequest.error ||
    deletePricingGroupRequest.error ||
    updatePricingGroupRequest.error ||
    clonePricingGroupRequest.error ||
    getPricingGroupsByStoreResponse.error;

  const loading: boolean =
    getPricingGroupsRequest.loading ||
    getPricingGroupRequest.loading ||
    createPricingGroupRequest.loading ||
    deletePricingGroupRequest.loading ||
    updatePricingGroupRequest.loading ||
    clonePricingGroupRequest.loading ||
    getPricingGroupsByStoreResponse.loading;

  return useMemo(
    () => ({
      pricingGroups,
      defaultPricingGroup,
      getPricingGroup: getPricingGroupData,
      getAllPricingGroups: getAllPricingGroupsData,
      createPricingGroup: createPricingGroupData,
      deletePricingGroups: deletePricingGroupsData,
      updatePricingGroups: updatePricingGroupsData,
      clonePricingGroup: clonePricingGroupData,
      getAllPricingGroupsByStore,
      pricingGroupByName,
      deletedIds: deletedIds,
      error: error ? parseApolloError(error) : undefined,
      loading,
      operation,
      createdId: createdId,
    }),
    [
      pricingGroups,
      defaultPricingGroup,
      error,
      loading,
      operation,
      getPricingGroupData,
      getAllPricingGroupsData,
      createPricingGroupData,
      deletePricingGroupsData,
      updatePricingGroupsData,
      clonePricingGroupData,
      pricingGroupByName,
      getAllPricingGroupsByStore,
      createdId,
      deletedIds,
    ],
  );
}
