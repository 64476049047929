import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View, Text, ViewStyle } from 'react-native';

export interface HeaderProps {
  testID?: string;
  right?: React.ReactNode;
  left?: React.ReactNode;
  title?: React.ReactNode | string;
  leftContainerStyle?: ViewStyle;
  rightContainerStyle?: ViewStyle;
  titleStyle?: ViewStyle;
  headerStyle?: ViewStyle;
}

export const HEIGHT = 70;

const headerContainerStyle: StyleFn = ({ theme }) => ({
  height: HEIGHT,
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.primary,
});

const titleNameStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  justifyContent: 'center',
  fontSize: theme.fontSize.larger,
  fontFamily: theme.font.regular,
  flexDirection: 'row',
});

const headerLeftStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: 0,
  paddingLeft: theme.padding.medium,
  flexDirection: 'row',
});

const defaultTitleStyle: StyleFn = () => ({
  flexDirection: 'row',
  alignSelf: 'center',
});

const headerRightStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  right: 0,
  paddingRight: theme.padding.medium,
  flexDirection: 'row',
});

const Header: React.FC<HeaderProps> = ({
  testID,
  left,
  title,
  right,
  leftContainerStyle,
  rightContainerStyle,
  titleStyle,
  headerStyle,
}: HeaderProps) => {
  const { css } = useFela();
  return (
    <View testID={testID} style={[css(headerContainerStyle), headerStyle]}>
      <View testID="left" style={[css(headerLeftStyle), leftContainerStyle]}>
        {left}
      </View>
      {React.isValidElement(title) ? (
        <View style={[css(defaultTitleStyle), titleStyle]} testID="title">
          {title}
        </View>
      ) : (
        <Text testID="title-name" style={[css(titleNameStyle), titleStyle]}>
          {title || ''}
        </Text>
      )}
      <View testID="right" style={[css(headerRightStyle), rightContainerStyle]}>
        {right}
      </View>
    </View>
  );
};

export default Header;
