import React, { useEffect, useState, useMemo } from 'react';
import { View, ActivityIndicator } from 'react-native';
import SelectBar from '../../../components/Button/SelectBar';
import { useFela } from 'react-fela';
import Layout from '../../../components/POSLayout/POSLayout';
import { useTranslation } from '@hitz-group/localization';
import {
  AppScreen,
  Resource,
  Shift,
  ShiftStatus,
  StyleFn,
} from '@hitz-group/domain';
import {
  LeftArrow,
  DrawerButton,
  SubscriptionStatusButton,
} from '../../../components/HeaderIcons/HeaderIcons';
import ShiftHistoryView from '../../../components/Shift/ShiftsHistoryView';
import ActiveShiftsView from '../../../components/Shift/ActiveShiftsView';
import { useShifts } from '../../../hooks/app/useShifts';
import { useNotification } from '../../../hooks/Notification';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import NewOrderButton from '../../../components/NewOrder/NewOrderButton';

const containerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.background,
  height: '100%',
});

const loadingStyle: StyleFn = () => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
});

export type Tab = {
  label: string;
  value: ShiftStatus;
};

const Shifts: React.FC = () => {
  const { translate } = useTranslation();
  const { css, theme } = useFela();
  const { showNotification } = useNotification();
  const { canI } = usePOSUserAuthorization();
  const navigation = useNavigation();
  const TABS = [
    {
      label: translate('shift.activeShifts'),
      value: ShiftStatus.OPEN,
    },
    {
      label: translate('shift.shiftsHistory'),
      value: ShiftStatus.CLOSED,
    },
  ];

  const [currentTab, setCurrentTab] = useState<Tab>(TABS[0]);

  const onPressSettingsTab = (tabValue: string): void => {
    const selectedtab = TABS.filter(x => x.value === tabValue)[0];
    setCurrentTab(selectedtab);
    const data = shiftsFilter(selectedtab.value);
    setFilteredShifts(data);
  };

  const headerTitleStyle: StyleFn = () => ({
    width: 400,
    height: 40,
  });

  const [filteredShifts, setFilteredShifts] = useState<Shift[]>([]);

  const { shifts, queryLoading, error, refetch } = useShifts();

  const shiftsFilter = useCallback(
    (type: ShiftStatus) => {
      return shifts.filter(item => item.shiftStatus === type);
    },
    [shifts],
  );

  const isFocused = useIsFocused();
  useEffect(() => {
    isFocused && refetch();
  }, [isFocused, refetch]);

  useEffect(() => {
    if (shifts) {
      const data = shiftsFilter(currentTab.value);
      setFilteredShifts(data);
    }
  }, [currentTab, shiftsFilter, shifts]);

  useEffect(() => {
    if (isFocused) {
      const hasAccess = canI([{ onResource: Resource.VIEW_SHIFT_SUMMARY }], {
        prompt: false,
      });
      if (!hasAccess) {
        navigation.navigate('Orders', {
          screen: { name: AppScreen.NEW_ORDER },
        });
      }
    }
  }, [canI, navigation, isFocused]);

  const headerTitle = (
    <View testID="settings-head-title" style={css(headerTitleStyle)}>
      <SelectBar
        secondary
        options={TABS}
        onPress={onPressSettingsTab}
        selectedOption={currentTab.value}
      />
    </View>
  );
  const headerLeft = <LeftArrow />;
  const headerRight = useMemo(
    () => (
      <>
        <SubscriptionStatusButton />
        <NewOrderButton />
        <DrawerButton />
      </>
    ),
    [],
  );
  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);
  return (
    <Layout
      hasHeader={true}
      title={translate('navigation.shiftsTitle')}
      headerTitle={headerTitle}
      headerLeft={headerLeft}
      headerRight={headerRight}
    >
      {queryLoading ? (
        <ActivityIndicator
          color={theme.colors.primary}
          style={css(loadingStyle)}
        />
      ) : (
        <View testID="settings" style={css(containerStyle)}>
          {currentTab.value === ShiftStatus.OPEN ? (
            <ActiveShiftsView data={filteredShifts} refetch={refetch} />
          ) : (
            <ShiftHistoryView data={filteredShifts} refetch={refetch} />
          )}
        </View>
      )}
    </Layout>
  );
};

export default Shifts;
