import React from 'react';
import {
  View,
  Text,
  GestureResponderEvent,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { withTheme } from 'react-fela';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import IconButton from '../../components/Button/IconButton';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

export interface SidePanelProps {
  renderContainer: React.ReactChildren;
  showPanel: boolean;
  onPressHideShowPanel?: (event?: GestureResponderEvent) => void;
  onPressHeaderLeftIcon?: (event?: GestureResponderEvent) => void;
  onPressHeaderRightIcon?: (event?: GestureResponderEvent) => void;
  headerText: string;
  headerLeftIcon?: string;
  headerRightIcon?: string;
  customHeader?: React.ReactChild;
  isLoading?: boolean;
}
const container: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.border,
  position: 'absolute',
  right: 0,
  width: 330,
  height: '100%',
  shadowOffset: { width: 3, height: 4 },
  shadowOpacity: 0.4,
  shadowColor: theme.colors.Black,
  shadowRadius: 5,
  elevation: 5,
});
const mainContainer: StyleFn = ({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  backgroundColor: theme.colors.blackTransparent02,
});
const TouchableStyle: StyleFn = () => ({
  flex: 1,
});
const iconButtonStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.colors.greyLight,
  height: 36,
  width: 36,
  alignItems: 'center',
  borderRadius: 5,
});
const leftIconButtonStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.colors.highlighted,
  height: 36,
  width: 36,
  alignItems: 'center',
  borderRadius: 5,
  marginRight: 10,
});
const rightIconButtonStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.colors.highlighted,
  height: 36,
  width: 36,
  alignItems: 'center',
  borderRadius: 5,
  right: 5,
});
const headerTextStyle: StyleFn = ({ theme }) => ({
  fontSize: 15,
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  paddingLeft: 10,
});
const headerContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  padding: 10,
});
const headerTextContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
});
const headerLeftRightIcon: StyleFn = () => ({
  flexDirection: 'row',
});

const scrollViewContent: StyleFn = () => ({
  flex: 1,
});

const SecondarySidePanel: React.FC<SidePanelProps> = ({
  showPanel,
  onPressHideShowPanel,
  headerText,
  headerLeftIcon,
  headerRightIcon,
  onPressHeaderLeftIcon,
  onPressHeaderRightIcon,
  renderContainer,
  customHeader,
  isLoading,
}: SidePanelProps) => {
  const { css, theme } = useFela();

  return (
    <>
      {showPanel && (
        <View style={css([mainContainer])}>
          <TouchableOpacity
            testID="touch-on-screen"
            style={css(TouchableStyle)}
            onPress={onPressHideShowPanel}
          >
            <View></View>
          </TouchableOpacity>
          <View style={css(container)}>
            {!isLoading &&
              (!customHeader ? (
                <View style={css(headerContainerStyle)}>
                  <View style={css(headerTextContainerStyle)}>
                    <IconButton
                      testID={'secondary-side-panel-back-icon'}
                      containerStyle={css(iconButtonStyle)}
                      icon="arrow-left"
                      onPress={onPressHideShowPanel}
                      iconColor={theme.colors.black}
                    />
                    <Text testID={'heading-text'} style={css(headerTextStyle)}>
                      {headerText}
                    </Text>
                  </View>
                  <View style={css(headerLeftRightIcon)}>
                    {headerLeftIcon && (
                      <IconButton
                        testID={'secondary-side-panel-left-icon'}
                        containerStyle={css(leftIconButtonStyle)}
                        icon={headerLeftIcon}
                        onPress={onPressHeaderLeftIcon}
                        iconColor={theme.colors.blue}
                      />
                    )}
                    {headerRightIcon && (
                      <IconButton
                        testID={'secondary-side-panel-right-icon'}
                        containerStyle={css(rightIconButtonStyle)}
                        icon={headerRightIcon}
                        onPress={onPressHeaderRightIcon}
                        iconColor={theme.colors.blue}
                      />
                    )}
                  </View>
                </View>
              ) : (
                customHeader
              ))}
            <ScrollView
              contentContainerStyle={css(scrollViewContent)}
              testID={'child-render-section'}
            >
              {isLoading ? <LoadingIndicator /> : renderContainer}
            </ScrollView>
          </View>
        </View>
      )}
    </>
  );
};

export default withTheme(SecondarySidePanel);
