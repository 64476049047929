import { StyleFn } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useFela } from 'react-fela';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import IconButton from '../../../../../components/Button/IconButton';
import { useModal } from '@hitz-group/rn-use-modal';
import { Tax, TAX_FREE_CODE } from '@hitz-group/domain';
import { CreateTaxModal } from '../../../../../components/Modals/Taxes/CreateTaxModal';
import { isWeb } from '../../../../../common/theme';
import { isDigit } from '../../../../../utils/validator';
interface TaxToTax {
  id: string;
  name: string;
  code: string;
  rate?: string;
  isActive?: boolean;
  taxes: Tax[];
}

interface TaxesProps {
  taxes: TaxToTax[];
  onChange: (index: number, prop: string, value: string) => void;
  openDeleteModal: (index: number, id: string, name: string) => void;
  onAddTax: (tax: TaxToTax) => void;
  isFeeModal?: boolean;
}

interface TaxesRowProps {
  tax: TaxToTax;
  onDeleteTax: (index: number, id: string, name: string) => void;
  onChange: (index: number, prop: string, value: string) => void;
  index: number;
}

export const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

export const containerStyle: StyleFn = () => ({
  width: isWeb ? '70%' : '100%',
  alignSelf: 'center',
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 240,
  height: theme.input.height,
  justifyContent: 'center',
  marginLeft: theme.spacing.small,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const formInputContainerSmallStyle: StyleFn = ({ theme }) => ({
  width: 80,
  height: theme.input.height,
  paddingLeft: theme.padding.small,
});

const rateFormInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: theme.input.height,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const prefixItemStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  padding: theme.spacing.small / 2 + 5,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.bold,
  borderRadius: theme.radius.small,
  lineHeight: 15,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small,
  marginLeft: theme.spacing.small,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const closeIconContainerDisabledStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small,
  marginLeft: theme.spacing.small,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.background,
});

const deleteIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const deleteIconDisabledStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.darkGrey,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.padding.medium / 2,
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

const TaxesRow: React.FC<TaxesRowProps> = ({
  tax,
  onDeleteTax,
  onChange,
  index,
}: TaxesRowProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  return (
    (tax.taxes.length === 0 && (
      <View style={css(rowStyle)}>
        <FormInput
          testID="code"
          placeholder={translate('backOfficeSettings.code')}
          value={tax.code}
          containerStyle={css(formInputContainerSmallStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChange.bind(null, index, 'code')}
          maxLength={6}
        />
        <FormInput
          testID="tax-name"
          placeholder={translate('backOfficeSettings.taxName')}
          value={tax.name}
          containerStyle={css(formInputContainerStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChange.bind(null, index, 'name')}
          maxLength={50}
        />
        <View style={css(flexStyle)}></View>
        <FormInput
          testID="rate"
          placeholder={translate('backOfficeSettings.rate')}
          value={tax.rate}
          containerStyle={css(rateFormInputContainerStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChange.bind(null, index, 'rate')}
          type={'number'}
          maxLength={6}
          readOnly={isDigit(String(tax.rate)) && Number(tax.rate) == 0}
          prefix={{
            text: '%',
            textStyle: css(prefixItemStyle),
          }}
        />
        <IconButton
          icon="TrashAlt"
          iconSize={24}
          containerSize={34}
          containerStyle={
            tax.code === TAX_FREE_CODE
              ? css(closeIconContainerDisabledStyle)
              : css(closeIconContainerStyle)
          }
          iconStyle={
            tax.code === TAX_FREE_CODE
              ? css(deleteIconDisabledStyle)
              : css(deleteIconStyle)
          }
          disabled={tax.code === TAX_FREE_CODE}
          onPress={(): void => onDeleteTax(index, tax.id, tax.name)}
        />
      </View>
    )) ||
    null
  );
};

export const TaxesSection: React.FC<TaxesProps> = ({
  taxes,
  onChange,
  openDeleteModal,
  onAddTax,
}: TaxesProps) => {
  const { css, theme } = useFela();
  const { showModal } = useModal();
  const { translate } = useTranslation();

  const onCreateNewTax = useCallback((): void => {
    showModal(<CreateTaxModal onAddTax={onAddTax} />);
  }, [showModal, onAddTax]);

  return (
    <BackOfficeSection
      title={translate('backOfficeSettings.taxes')}
      contentContainerStyle={css(formStyle)}
      containerStyle={css(containerStyle)}
      action={<BackOfficeCreateNewButton onPress={onCreateNewTax} />}
    >
      <TableComponent
        columns={[
          {
            title: translate('backOfficeSettings.code'),
            width: 80,
            containerStyle: { paddingLeft: 15 },
          },
          {
            title: translate('backOfficeSettings.taxName'),
            flex: 1,
            alignItems: 'flex-start',
            containerStyle: { paddingLeft: theme.spacing.medium },
          },
          {
            title: translate('backOfficeSettings.rate') + ' (%)',
            width: 100,
          },
          { title: '', width: 44 },
        ]}
        data={taxes}
        normalRows
        columnContainerStyle={css(columnContainerStyle)}
        renderRow={(item: TaxToTax, index: number): React.ReactNode => (
          <TaxesRow
            key={index}
            tax={item}
            onDeleteTax={openDeleteModal}
            onChange={onChange}
            index={index}
          />
        )}
      />
    </BackOfficeSection>
  );
};
