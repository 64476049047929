import { StyleFn, Page, Product } from '@hitz-group/domain';
import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import { MenuTile, PAGE_TYPE } from '../menuTypes';
import { useTranslation } from '@hitz-group/localization';
import { MenuProductModal } from './MenuProductModal';
import { BasicSidePanel } from './BasicSidePanel';
import { MenuPageModal } from './MenuPageModal';

export interface SidePanelProps {
  type?: MenuTile;
  id?: string;
  onClose?: () => void;
  onChangeType?: (type: MenuTile) => void;
  pageOptions: { value: string; label: string }[];
  pages?: string[];
  onPageAdd?: (pageData: Page, pageType: PAGE_TYPE) => void;
  pageType?: PAGE_TYPE;
  onProductAdd?: (productData: Product) => void;
  onProductUpdate?: () => void;
}

const filtersEmptyStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: '97%',
  backgroundColor: theme.colors.white,
  marginLeft: 10,
  marginTop: 10,
  paddingHorizontal: 70,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  alignItems: 'center',
});

const tileFontGray: StyleFn = ({ theme }) => ({
  color: theme.colors.primaryGrey,
  fontFamily: theme.font.semibold,
  fontSize: theme.fontSize.small,
  lineHeight: 24,
  letterSpacing: -0.5,
});

export const SidePanel: React.FC<SidePanelProps> = ({
  id,
  type,
  onClose,
  onChangeType,
  pageOptions,
  pages,
  onPageAdd,
  pageType,
  onProductAdd,
  onProductUpdate,
}: SidePanelProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  const defaultModal = useMemo(
    () => (
      <View style={css(filtersEmptyStyle)}>
        <Text style={css(tileFontGray)}>
          {translate('menuEditor.clickOnTile')}
        </Text>
        <Text style={css(tileFontGray)}>
          {translate('menuEditor.toViewOptions')}
        </Text>
      </View>
    ),
    [css, translate],
  );

  if (type === ('Product' as MenuTile)) {
    return (
      <BasicSidePanel onClose={onClose} onChangeType={onChangeType} type={type}>
        <MenuProductModal
          id={id}
          pages={pages}
          onProductAdd={onProductAdd}
          onClose={onClose}
          onProductUpdate={onProductUpdate}
        />
      </BasicSidePanel>
    );
  } else if (type === ('Page' as MenuTile)) {
    return (
      <BasicSidePanel onClose={onClose} onChangeType={onChangeType} type={type}>
        <MenuPageModal
          id={id}
          pageOptions={pageOptions}
          rootPage={(pages && pages?.[0]) || ''}
          onPageAdd={onPageAdd}
          onClose={onClose}
          pageType={pageType}
        />
      </BasicSidePanel>
    );
  } else {
    return defaultModal;
  }
};

export default SidePanel;
