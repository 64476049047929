import { StyleFn } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import Modal from '../../../../../components/Modals/Modal';
import Button from '../../../../../components/Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';
import { View } from 'react-native';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';

interface AddStoresProps {
  storeOptions: { label: string; value: string }[];
  onSubmit: (selectedStores: string[]) => void;
}

const createModalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const dropDownStyle: StyleFn = () => ({
  width: 280,
  height: 38,
  justifyContent: 'center',
});

const touchableStyle: StyleFn = () => ({
  height: 38,
  flexWrap: 'wrap',
});

const dismissButtonStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.white,
  marginTop: theme.spacing.big,
  zIndex: 200,
  marginLeft: 'auto',
});

const buttonStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
  marginTop: theme.spacing.big,
  zIndex: 200,
  marginLeft: 'auto',
});

const assignLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
});

const dismissLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
});

const buttonsWrapper: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
  height: 100,
});

const headerTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

export const AddStores: React.FC<AddStoresProps> = ({
  onSubmit,
  storeOptions,
}: AddStoresProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const [selected, setSelected] = useState<{ stores: string[] }>({
    stores: [],
  });
  const { closeModal } = useModal();
  const onChangeStore = useCallback(values => {
    setSelected(prev => ({ ...prev, stores: values }));
  }, []);
  return (
    <Modal
      title={translate('productBulkOperations.addToStore')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
      headerTextStyle={css(headerTextStyle)}
    >
      <View style={css(buttonsWrapper)}>
        <MultipleSelect
          values={storeOptions}
          label={translate('productBulkOperations.selectStores')}
          selectedValues={selected.stores}
          containerStyle={css(dropDownStyle)}
          touchableStyle={css(touchableStyle)}
          onValueChange={onChangeStore}
          searchLabel={translate('productBulkOperations.stores')}
          searchPlaceHolder={translate('productBulkOperations.searchStores')}
          maxItems={3}
          PopoverViewPlacement={'bottom'}
        />
      </View>

      <View style={css(buttonsWrapper)}>
        <Button
          title={translate('productBulkOperations.dismiss')}
          containerStyle={css(dismissButtonStyle)}
          labelStyle={css(dismissLabelStyle)}
          onPress={closeModal}
        />
        <Button
          title={translate('productBulkOperations.add')}
          containerStyle={css(buttonStyle)}
          labelStyle={css(assignLabelStyle)}
          onPress={() => onSubmit(selected.stores)}
        />
      </View>
    </Modal>
  );
};
