import React, { useEffect, useCallback } from 'react';
import { FilterValue } from '@hitz-group/domain';
import { TableStyles } from '../../styles/Component.styles';
import { ReportRange } from '../../types';
import IconButton from '../../../../../components/Button/IconButton';

import { useNotification } from '../../../../../hooks/Notification';
import { useSession } from '../../../../../hooks/app/useSession';
import { useReportExport } from '../../../../../hooks/app/useReportExport';
import { downloadFile } from '../../../../../utils/report';
import { cloneJSON } from '@hitz-group/client-utils';

interface ExportProps {
  filters: FilterValue;
  range: ReportRange;
}

export const ExportDetailedSummary: React.FC<ExportProps> = ({
  filters,
  range,
}) => {
  const { showNotification } = useNotification();
  const [session] = useSession();
  const styles = TableStyles();

  const orgName = session?.currentOrganization?.name;

  const { loading, error, reportUrl, generateDetailedSummaryReport } =
    useReportExport();

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const exportRowData = useCallback(() => {
    const filtersCopy = cloneJSON(filters);
    for (const key in filtersCopy) {
      if (filtersCopy[key] === null) {
        delete filtersCopy[key];
      }
    }
    orgName &&
      generateDetailedSummaryReport({
        variables: {
          input: {
            organization: { name: orgName },
            filters: filtersCopy,
            range,
          },
        },
      });
  }, [orgName, generateDetailedSummaryReport, filters, range]);

  useEffect(() => {
    if (reportUrl) {
      downloadFile(
        reportUrl,
        `Detailed_summary_report${new Date().getTime()}.pdf`,
      );
    }
  }, [reportUrl]);

  return (
    <IconButton
      testID="download-report"
      icon="CloudDownload"
      primary
      disabled={loading}
      containerSize={24}
      containerStyle={styles.downloadButtonStyle}
      iconStyle={styles.downloadIconStyle}
      iconSize={15}
      onPress={() => exportRowData()}
    />
  );
};
