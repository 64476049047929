import {
  Order,
  OrderAction,
  AcceptOrderEvent,
  CompleteOrderEvent,
  RejectOrderEvent,
  RejectionReason,
  OrderEvent,
  IntegrationApps,
} from '@hitz-group/domain';
import { useMemo, useCallback, useRef } from 'react';
import { ORDER_SAVE } from '../../../hooks/app/orders/graphql';
import { computeOrderState } from '@hitz-group/order-helper';
import { useMutation } from '@apollo/client/react/hooks';
import { useSession } from '../useSession';
import { userUtility } from '../../../state/userUtility';
import { generateOrderEvent } from '../../../utils/orderEventHelper';
import kitchenOrderEvents from '../../../utils/printerTemplates/kotEvents';

export interface UseOnlineOrderEvents {
  acceptOrders: (orders: Order[]) => Order[];
  rejectOrder: (order: Order, reason: RejectionReason) => Order;
  completeOrder: (order: Order) => Order;
}

export const useOnlineOrderEvents = (): UseOnlineOrderEvents => {
  const onSaveCompleteHandler = useRef<{ [orderId: string]: Function }>({});
  const [saveOrder] = useMutation(ORDER_SAVE, {
    onCompleted: ({ saveOrder }) => {
      onSaveCompleteHandler.current[saveOrder?.id]?.();
      delete onSaveCompleteHandler.current[saveOrder?.id];
    },
  });

  const [session] = useSession();

  const eventSourceInfo = useMemo(
    () => ({
      organizationId: session.currentOrganization?.id,
      venueId: session.currentVenue?.id,
      deviceId: session.device?.id,
      storeId: session.currentStore?.id,
      triggeredBy: userUtility.posUser?.id,
    }),
    [session],
  );

  const acceptOrders = useCallback(
    (orders: Order[]): Order[] => {
      return orders.map(order => {
        const event = generateOrderEvent<AcceptOrderEvent>(
          OrderAction.ORDER_ACCEPT,
          eventSourceInfo,
          {
            orderId: order?.id,
            previous: order?.prevEventId,
            integrationApp: order.integrationInfo?.app,
            integrationInfo: {
              app: order.integrationInfo?.app as IntegrationApps,
              id: order.integrationInfo?.id as string,
            },
            orderNumber: order.orderNumber,
          },
        );

        onSaveCompleteHandler.current[order.id] = () => {
          kitchenOrderEvents.publishToKotUtil({
            orderId: order.id,
            preEvents: [event],
          });
        };
        const updatedOrder = computeOrderState([event], order);
        saveOrder({ variables: { data: updatedOrder } });
        return updatedOrder;
      });
    },
    [saveOrder, eventSourceInfo],
  );

  const saveAndPrintOrder = useCallback(
    (order: Order, events: OrderEvent[]) => {
      onSaveCompleteHandler.current[order.id] = () => {
        kitchenOrderEvents.publishToKotUtil({
          orderId: order.id,
          preEvents: events,
        });
      };
      saveOrder({ variables: { data: order } });
    },
    [saveOrder],
  );

  const rejectOrder = useCallback(
    (order: Order, reason: RejectionReason): Order => {
      const event = generateOrderEvent<RejectOrderEvent>(
        OrderAction.ORDER_REJECT,
        eventSourceInfo,
        {
          orderId: order?.id,
          previous: order?.prevEventId,
          reason,
          integrationApp: order.integrationInfo?.app,
          integrationInfo: {
            app: order.integrationInfo?.app as IntegrationApps,
            id: order.integrationInfo?.id as string,
          },
          orderNumber: order.orderNumber,
        },
      );

      const updatedOrder = computeOrderState([event], order);
      saveAndPrintOrder(updatedOrder, [event]);
      return updatedOrder;
    },
    [saveAndPrintOrder, eventSourceInfo],
  );

  const completeOrder = useCallback(
    (order: Order): Order => {
      const event = generateOrderEvent<CompleteOrderEvent>(
        OrderAction.ORDER_COMPLETE,
        eventSourceInfo,
        {
          orderId: order?.id,
          previous: order?.prevEventId,
          integrationApp: order.integrationInfo?.app,
          integrationInfo: {
            app: order.integrationInfo?.app as IntegrationApps,
            id: order.integrationInfo?.id as string,
          },
          orderNumber: order.orderNumber,
        },
      );

      const updatedOrder = computeOrderState([event], order);
      saveAndPrintOrder(updatedOrder, [event]);
      return updatedOrder;
    },
    [saveAndPrintOrder, eventSourceInfo],
  );

  return useMemo(
    () => ({
      acceptOrders,
      completeOrder,
      rejectOrder,
    }),
    [acceptOrders, completeOrder, rejectOrder],
  );
};
