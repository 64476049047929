import { DEFAULT_PRICING_GROUP, StyleFn } from '@hitz-group/domain';
import { PricingGroup as PricingGroupDefault } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import IconButton from '../../../../components/Button/IconButton';
import { useNavigation } from '@react-navigation/native';

export interface PricingGroup extends PricingGroupDefault {
  isSelected?: boolean;
}

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 300,
  height: theme.input.height,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.colors.paragraphLight,
  borderRadius: theme.radius.small,
  marginRight: 'auto',
});

const formTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.padding.large,
  marginTop: theme.padding.medium,
  ...theme.font14Regular,
  color: theme.colors.charcoal,
});

const defaultContainerStyle: StyleFn = ({ theme }) => ({
  width: 300,
  height: theme.input.height,
  marginLeft: 80,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  marginRight: 'auto',
});

const defaultTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.medium,
  marginTop: 10,
  ...theme.font14RegularCharcoal,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
  marginLeft: 'auto',
  alignSelf: 'center',
  marginRight: -theme.spacing.small,
});

const arrowIconContainerStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.medium,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const iconContainerStyle: StyleFn = () => ({
  width: 80,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
});

interface PricingGroupRowProps {
  pricingGroup: PricingGroup;
  onPressCopy: (id: string) => void;
  onChangeStatus: (id: string, isActive: boolean) => void;
}

export const PricingGroupRow: React.FC<PricingGroupRowProps> = ({
  pricingGroup,
  onPressCopy,
  onChangeStatus,
}: PricingGroupRowProps) => {
  const { css, theme } = useFela();
  const navigation = useNavigation();

  return (
    <View style={css(rowStyle)}>
      {pricingGroup.name === DEFAULT_PRICING_GROUP ? (
        <View style={css(defaultContainerStyle)}>
          <Text style={css(defaultTextStyle)}>{pricingGroup.name}</Text>
        </View>
      ) : (
        <>
          <IconButton
            primary
            icon={(pricingGroup.isActive && 'toggle-on') || 'toggle-off'}
            iconSize={26}
            containerSize={34}
            iconColor={
              pricingGroup.isActive
                ? theme.colors.green
                : theme.colors.paragraph
            }
            containerStyle={css(iconContainerStyle)}
            onPress={(): void =>
              onChangeStatus(pricingGroup.id, !pricingGroup.isActive)
            }
          />
          <View style={css(formInputContainerStyle)}>
            <Text style={css(formTextStyle)}>{pricingGroup.name}</Text>
          </View>
        </>
      )}
      <IconButton
        icon="copy"
        iconSize={24}
        containerSize={34}
        containerStyle={css(closeIconContainerStyle)}
        iconStyle={css(closeIconStyle)}
        onPress={() => onPressCopy(pricingGroup.id)}
      />
      <IconButton
        icon="AngleRight"
        iconSize={24}
        containerSize={34}
        containerStyle={css(arrowIconContainerStyle)}
        iconColor={theme.colors.paragraph}
        onPress={(): void =>
          navigation.navigate('PriceListSettings', {
            pricingGroupId: pricingGroup.id,
            title: pricingGroup.name,
          })
        }
      />
    </View>
  );
};
