import React from 'react';
import { View, Text, ViewStyle, TouchableOpacity } from 'react-native';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import IconButton from '../Button/IconButton';
import { Icons } from '@hitz-group/domain';

export interface SelectOptionProps {
  title?: string;
  values: {
    label: string;
    value: string;
  }[];
  onPress?: (selectedValue: string) => void;
  testID?: string;
  selectedValue: string;
  containerStyle?: ViewStyle;
}

const itemStyle: StyleFn = ({ theme, isSelected }) => ({
  flexDirection: 'row',
  backgroundColor: isSelected ? theme.colors.lightBlueTile : theme.colors.white,
  height: 40,
  justifyContent: 'center',
  borderRadius: theme.radius.small,
});

const itemTitlesColumnStyle: StyleFn = () => ({
  flexDirection: 'column',
  width: '80%',
});

const itemNameStyle: StyleFn = ({ theme, isSelected }) => ({
  fontFamily: theme.font.medium,
  marginLeft: 15,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
  color: isSelected ? theme.colors.blue : theme.colors.black,
  lineHeight: 40,
});

const addIconStyle: StyleFn = ({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.spacing.small,
  backgroundColor: theme.colors.white,
});

const titleStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  justifyContent: 'center',
  paddingLeft: theme.spacing.big / 2,
  marginBottom: theme.spacing.small / 2,
});

const titleTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontFamily: theme.font.medium,
  height: 40,
  lineHeight: 40,
});

const SelectList: React.FC<SelectOptionProps> = ({
  onPress,
  selectedValue,
  values,
  title,
  containerStyle,
}) => {
  const { css, theme } = useFela();
  return (
    <View style={containerStyle}>
      <View style={css(titleStyle)}>
        <Text style={css(titleTextStyle)}>{title}</Text>
      </View>

      {values.map((item, index) => {
        const isSelected = item.value === selectedValue;
        return (
          <TouchableOpacity
            key={index + `${item.label}`}
            style={css(itemStyle({ theme, isSelected }))}
            onPress={() => onPress && onPress(item.value)}
          >
            <View style={css(itemTitlesColumnStyle)}>
              <Text style={css(itemNameStyle({ theme, isSelected }))}>
                {item.label}
              </Text>
            </View>
            <IconButton
              icon={Icons.AngleRight}
              iconColor={
                isSelected ? theme.colors.blue : theme.colors.paragraph
              }
              iconSize={20}
              containerStyle={css(addIconStyle)}
              disabled
            />
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default SelectList;
