const EXTRACT_VALUE_PATTERN = /\d+(\.\d+)?/;
const EXTRACT_SURCHARGE_OR_OFF_PATTERN = /\s(OFF|SURCHARGE)/;

export const replaceFunctionMapCurrency = (
  functionMapLabel: string,
  formatFunction: Function,
): string => {
  const valueResult = EXTRACT_VALUE_PATTERN.exec(
    functionMapLabel,
  ) as RegExpExecArray;
  const changePriceTextResult = EXTRACT_SURCHARGE_OR_OFF_PATTERN.exec(
    functionMapLabel,
  ) as RegExpExecArray;
  return `${formatFunction(valueResult[0])}${changePriceTextResult[0]}`;
};
