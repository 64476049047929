import { UpdateOrganizationInput } from '@hitz-group/domain';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { View, ScrollView, Text } from 'react-native';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../components/FormInput/FormInput';
import {
  useTranslation,
  TIMEZONES_MAP,
  COUNTRY_CODE_CURRENCY_MAP,
  listCountries,
  getCountry,
  getCountryCodeByPhoneCode,
  Timezone,
  CountryCurrencyMap,
  Country,
} from '@hitz-group/localization';
import Button from '../../../components/Button/Button';
import { useNotification } from '../../../hooks/Notification';
import { useSession } from '../../../hooks/app/useSession';
import DropDown from '../../../components/FormInput/DropDown';
import { useOrganization } from '../../../hooks/app/useOrganization';
import { getAvatarName } from '../../../utils/getAvatarName';
import PlacesInput from '../../../components/PlacesInput';
import Avatar from '../../../components/Avatar/Avatar';

import { Address } from '@hitz-group/domain';
import {
  isValidDomain,
  isValidEmail,
  isValidName,
  isValidBusinessName,
  isValidNumber,
  isNotEmpty,
  isValidABN,
} from '../../../utils/validator';
import {
  mainStyle,
  actionsContainerStyle,
  formStyle,
  formInputContainerStyle,
  formLabelStyle,
  pageStyle,
  phonePrefixErrorBgStyle,
  phonePrefixStyle,
  saveButtonStyle,
  dropdownStyle,
  dropDownMainViewStyle,
  currencyViewStyle,
  titleStyle,
  rowStyle,
  rowAndriodStyle,
  searchContainerStyle,
  labelStyle,
  width100row,
  dropdownExtraStyle,
  dropdownViewStyle,
  innerContainer,
  innerContainerSingleView,
  phoneContainer,
  SearchMainContainer,
  SearchtextInputStyle,
  bottomSpace,
  avatarContainerStyle,
  avatarbodyStyle,
  avatarTextStyle,
  addImageContainerStyle,
  addImageTextStyle,
  avatarDescriptionStyle,
  avatarDescriptionTextStyle,
} from './GeneralSettings.styles';
import { isAndroid } from '../../../common/theme';

interface FormData {
  businessName: string;
  businessIdentifier: string;
  website: string;
  name: string;
  phone: string;
  email: string;
  line1: string;
  line2?: string;
  postalCode: string;
  country: string;
  state: string;
  city: string;
  timeZone: string;
  currency: string;
  phoneCountryCode: string; // used for component internal identification
  address?: Address;
}
const initialFormState: FormData = {
  businessName: '',
  businessIdentifier: '',
  website: '',
  name: '',
  phone: '',
  email: '',
  line1: '',
  line2: '',
  postalCode: '',
  country: '',
  state: '',
  city: '',
  timeZone: '',
  currency: '',
  phoneCountryCode: '',
};

const COUNTRIES = listCountries().sort((country1, country2) =>
  country1.name.localeCompare(country2.name),
);
const TIMEZONES: Array<Timezone> = Object.values(TIMEZONES_MAP);

// Declaring these constants outside component, as they don't change frequently
// ask some one for better place to move these out of this file
const CURRENCY_OPTIONS = [{ label: '-- Select Currency --', value: '' }].concat(
  COUNTRY_CODE_CURRENCY_MAP.map((_currency: CountryCurrencyMap) => ({
    label: _currency.name,
    value: _currency.abbr,
  })),
);
const COUNTRY_OPTIONS = [{ label: '-- Select Country --', value: '' }].concat(
  COUNTRIES.map((_country: Country) => ({
    label: _country.name,
    value: _country.code,
  })),
);
const TIMEZONE_OPTIONS = [{ label: '-- Select Timezone --', value: '' }].concat(
  TIMEZONES.map((timezone: Timezone) => ({
    label: `(UTC${timezone.utcOffsetStr}) - ${timezone.name}`,
    value: timezone.name,
  })),
);
export const GeneralSettingsScreen: React.FC = () => {
  const { css } = useFela();
  const [session] = useSession();
  const { translate } = useTranslation();
  const { theme } = useFela();
  const { showNotification } = useNotification();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [isSignupDisabled, setIsSignupDisabled] = useState<boolean>(false);
  const [form, setForm] = useState<FormData>(initialFormState);
  const {
    organization,
    error,
    updateOrganization,
    loading,
    getOrganizationById,
  } = useOrganization();
  useEffect(() => {
    if (session.currentOrganization?.id) {
      getOrganizationById(session.currentOrganization.id);
    }
  }, [session, getOrganizationById]);

  useEffect((): void => {
    if (organization) {
      // mobile no from API is something like this +61 1111333222
      const [_phoneCode, _phoneNumber] = organization.phone.split(' ');
      setForm({
        businessName: organization.name || '',
        line1: organization.address?.line1 || '',
        line2: organization.address?.line2,
        state: organization.address?.state || '',
        city: organization.address?.city || '',
        postalCode: organization.address?.postalCode || '',
        country: organization.country || '',
        businessIdentifier: organization.businessIdentifier || '',
        // This is used to render the country flag in dropdown
        // 1. Which can be changed by user
        // 2. and this doesn't change when the other geolocation related details were changed!
        phoneCountryCode: getCountryCodeByPhoneCode(_phoneCode) || '',
        phone: _phoneNumber || '',
        name: session?.user?.name || '',
        timeZone: organization.timezone || '',
        currency: organization.currencyCode || '',
        website: organization.website || '',
        email: organization.email || '',
      });
    }
  }, [organization, session]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onChange = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onChangeCountry = useCallback((_countryCode: string) => {
    // Q1: what if a country code is having multiple timezones ?
    // There are multiple timezones associated with a given country (in some cases)
    // we will pick the first one, if the user wants to change he/she can change

    // Q2: what of country was unable to map to specific country code ?
    // We will reset to empty

    const defaultTimezone = TIMEZONES.find((_tz: Timezone) => {
      return _tz.country === _countryCode;
    });
    const selectedCountry = getCountry(_countryCode);

    setForm(_form => ({
      ..._form,
      country: _countryCode,
      timeZone: defaultTimezone?.name || '',
      currency: selectedCountry?.currency || '',
    }));
  }, []);

  const getFormattedPhone = () => {
    if (form.phoneCountryCode) {
      return `${getCountry(form.phoneCountryCode).phone} ${form.phone}`;
    }
    return form.phone;
  };

  const formValidationStatus: Record<string, boolean> = {
    // user
    email: isValidEmail(form.email),
    phone: isValidNumber(getFormattedPhone()),
    name: isValidName(form.name),
    // organization demographics
    timeZone: isNotEmpty(form.timeZone),

    state: isNotEmpty(form.state),
    city: isNotEmpty(form.city),
    line1: isNotEmpty(form.line1),
    country: isNotEmpty(form.country),
    // org basic details
    website: form.website ? isValidDomain(form.website) : true,
    currency: isNotEmpty(form.currency),
    businessIdentifier: isValidABN(form.businessIdentifier),
    businessName: isValidBusinessName(form.businessName),
  };

  const onPressSave = async (): Promise<void> => {
    if (
      organization &&
      Object.keys(formValidationStatus).every(
        _key => formValidationStatus[_key] === true,
      )
    ) {
      const payload: UpdateOrganizationInput = {
        id: organization.id,
        phone: getFormattedPhone(),
        email: form.email,
        website: form.website,
        businessIdentifier: form.businessIdentifier,
        name: form.businessName,
        address: {
          line1: form.line1,
          line2: form.line2,
          city: form.city,
          state: form.state,
          postalCode: form.postalCode,
          country: getCountry(form.country).name,
        },
        country: form.country,
        currencyCode: form.currency, // INR
        timezone: form.timeZone, // Asia/Kolkata
      };
      const status = await updateOrganization(payload);
      if (status) {
        showNotification({
          success: true,
          message: translate('generalSettings.organizationUpdateSuccess'),
        });
      }
    }
  };

  const onSelectAddress = useCallback((address: Address) => {
    setForm(form => ({
      ...form,
      address,
    }));
  }, []);

  const addressProvided = Object.keys(form.address || {}).length > 0;

  const formLabelStyleCss = css(formLabelStyle);
  const formInputContainerStyleCss = css(formInputContainerStyle);
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView testID="overview-screen" style={css(pageStyle)}>
        <View style={css(avatarContainerStyle)}>
          <Avatar
            name={getAvatarName(form.businessName || '')}
            containerStyle={css(avatarbodyStyle)}
            textStyle={css(avatarTextStyle)}
          />
          <View style={css(addImageContainerStyle)}>
            <Text style={css(addImageTextStyle)}>
              {translate('productSettings.addImage')}
            </Text>
          </View>
        </View>
        <View style={css(avatarDescriptionStyle)}>
          <Text style={css(avatarDescriptionTextStyle)}>
            {translate('avatar.imageSizeDescription')}
          </Text>
        </View>
        <View style={css(width100row)}>
          <BackOfficeSection contentContainerStyle={css(formStyle)}>
            <View style={css(innerContainer)}>
              <FormInput
                verified={formValidationStatus.businessName}
                error={!formValidationStatus.businessName}
                testID="organization-name"
                placeholder={translate('form.organizationName')}
                title={translate('form.organizationName')}
                value={form.businessName || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                textStyle={formLabelStyleCss}
                onChangeText={onChange.bind(null, 'businessName')}
                maxLength={50}
              />
              <FormInput
                verified={formValidationStatus.businessIdentifier}
                error={!formValidationStatus.businessIdentifier}
                testID="business-number"
                placeholder={translate('form.businessNumber')}
                title={translate('form.businessNumber')}
                value={form.businessIdentifier || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                textStyle={formLabelStyleCss}
                onChangeText={onChange.bind(null, 'businessIdentifier')}
                maxLength={50}
              />
            </View>
            <View style={css(innerContainer)}>
              <DropDown
                title={translate('form.currency')}
                values={CURRENCY_OPTIONS}
                extraStyle={css(dropdownExtraStyle)}
                extraViewStyle={css(dropdownViewStyle)}
                extraMainViewStyle={css(currencyViewStyle)}
                style={css(
                  dropdownStyle({
                    theme,
                    error: !formValidationStatus.currency,
                  }),
                )}
                onValueChange={onChange.bind(null, 'currency')}
                selectedValue={form.currency}
                textStyle={formLabelStyleCss}
              />
              <FormInput
                verified={formValidationStatus.website}
                error={!formValidationStatus.website}
                testID="website"
                placeholder={translate('form.website')}
                title={translate('form.website')}
                value={form.website || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                textStyle={formLabelStyleCss}
                onChangeText={onChange.bind(null, 'website')}
                maxLength={50}
              />
            </View>
          </BackOfficeSection>

          <BackOfficeSection
            title="Contact Details"
            contentContainerStyle={css(formStyle)}
            iconColor={theme.colors.blue}
            icon={'info-circle'}
          >
            <View style={css(innerContainer)}>
              <FormInput
                verified={formValidationStatus.name}
                error={!formValidationStatus.name}
                testID="contact-name"
                placeholder={translate('form.contactName')}
                title={translate('form.contactName')}
                value={form.name || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                textStyle={formLabelStyleCss}
                onChangeText={onChange.bind(null, 'name')}
                autoCapitalize={'words'}
                maxLength={50}
              />
              <View style={css(phoneContainer)}>
                <FormInput
                  testID="phone-number"
                  placeholder={translate('form.phoneNumberHint')}
                  title={translate('form.phoneNumber')}
                  value={form.phone || ''}
                  alignTitle="left"
                  containerStyle={formInputContainerStyleCss}
                  textStyle={formLabelStyleCss}
                  onChangeText={onChange.bind(null, 'phone')}
                  type="phone"
                  maxLength={10}
                  verified={formValidationStatus.phone}
                  error={!formValidationStatus.phone}
                  country={form.phoneCountryCode}
                  onSelectChange={(country): void =>
                    onChange('phoneCountryCode', country.toString())
                  }
                  onPressCountry={(country): void =>
                    onChange('phoneCountryCode', country.toString())
                  }
                  prefix={{
                    text: getCountry(form.phoneCountryCode)?.phone,
                    textStyle: !formValidationStatus.phone
                      ? css(phonePrefixStyle, phonePrefixErrorBgStyle)
                      : css(phonePrefixStyle),
                  }}
                />
              </View>
            </View>
            <View style={css(innerContainerSingleView)}>
              <FormInput
                verified={formValidationStatus.email}
                error={!formValidationStatus.email}
                testID="email-address"
                placeholder={translate('common.emailPlaceholder')}
                title={translate('common.emailAddress')}
                value={form.email || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                textStyle={formLabelStyleCss}
                onChangeText={onChange.bind(null, 'email')}
              />
            </View>
          </BackOfficeSection>
          <BackOfficeSection
            title="Location Details"
            contentContainerStyle={css(formStyle)}
            iconColor={theme.colors.blue}
            icon={'info-circle'}
          >
            {isAndroid ? (
              <View style={css(rowAndriodStyle)}>
                <PlacesInput
                  title={'Address'}
                  placeholder={translate('form.searchAddress')}
                  onChangeAddress={onSelectAddress}
                  address={form.address as Address}
                  valid={addressProvided}
                  extraStyleSearchBar={css(searchContainerStyle)}
                  extraStyleContainer={css(SearchMainContainer)}
                  extraTextInputStyle={css(SearchtextInputStyle)}
                  extraStyleLabel={css(labelStyle)}
                />
              </View>
            ) : (
              <View style={css(rowStyle)}>
                <PlacesInput
                  title={'Address'}
                  placeholder={translate('form.searchAddress')}
                  onChangeAddress={onSelectAddress}
                  address={form.address as Address}
                  valid={addressProvided}
                  extraStyleSearchBar={css(searchContainerStyle)}
                  extraStyleContainer={css(SearchMainContainer)}
                  extraTextInputStyle={css(SearchtextInputStyle)}
                  extraStyleLabel={css(labelStyle)}
                />
              </View>
            )}

            <View style={css(innerContainer)}>
              <FormInput
                verified={formValidationStatus.line1}
                error={!formValidationStatus.line1}
                testID="street-address"
                placeholder={translate('form.streetAddress')}
                title={translate('form.streetAddress')}
                value={form.line1 || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                textStyle={formLabelStyleCss}
                onChangeText={onChange.bind(null, 'line1')}
              />
              <FormInput
                verified={formValidationStatus.city}
                error={!formValidationStatus.city}
                testID="city"
                placeholder={translate('form.city')}
                title={translate('form.city')}
                value={form.city || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                textStyle={formLabelStyleCss}
                onChangeText={onChange.bind(null, 'city')}
              />
            </View>
            <View style={css(innerContainer)}>
              <FormInput
                verified={formValidationStatus.state}
                error={!formValidationStatus.state}
                testID="state"
                placeholder={translate('form.state')}
                title={translate('form.state')}
                value={form.state || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                textStyle={formLabelStyleCss}
                onChangeText={onChange.bind(null, 'state')}
              />
              <DropDown
                title={translate('form.country')}
                values={COUNTRY_OPTIONS}
                style={css(
                  dropdownStyle({
                    theme,
                    error: !formValidationStatus.country,
                  }),
                )}
                textStyle={formLabelStyleCss}
                extraMainViewStyle={css(dropDownMainViewStyle)}
                extraStyle={css(dropdownExtraStyle)}
                extraViewStyle={css(dropdownViewStyle)}
                onValueChange={onChangeCountry}
                selectedValue={form.country}
              />
            </View>
            <View style={css(innerContainerSingleView)}>
              {/* <FormInput
                verified={formValidationStatus.postalCode}
                error={!formValidationStatus.postalCode}
                testID="postalCode"
                placeholder={translate('form.postalCodeHint')}
                title={translate('form.postalCode')}
                value={form.postalCode || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                textStyle={formLabelStyleCss}
                onChangeText={onChange.bind(null, 'postalCode')}
              /> */}
              <DropDown
                title={translate('form.timeZone')}
                values={TIMEZONE_OPTIONS}
                style={css(
                  dropdownStyle({
                    theme,
                    error: !formValidationStatus.timeZone,
                  }),
                )}
                textStyle={formLabelStyleCss}
                extraMainViewStyle={css(dropDownMainViewStyle)}
                extraStyle={css(dropdownExtraStyle)}
                extraViewStyle={css(dropdownViewStyle)}
                onValueChange={onChange.bind(null, 'timeZone')}
                selectedValue={form.timeZone}
              />
            </View>
          </BackOfficeSection>
        </View>
        <View style={css(bottomSpace)}></View>
      </ScrollView>
      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
            disabled={loading}
            loading={loading}
          />
        </View>
      </View>
    </>
  );
};
