import { useMutation } from '@apollo/client/react/hooks';
import { useMemo, useCallback, useState, useEffect } from 'react';
import {
  UPDATE_PRODUCT_PRICING_MUTATION,
  DELETE_PRODUCT_PRICING_MUTATION,
  ADD_PRODUCT_PRICING_MUTATION,
  addBulkProductPricingsMutation,
} from '../../graphql/productPricings';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import { Operation } from '../../types/Operation';
import {
  ProductPricingInput,
  ProductPricing,
  DeleteProductPricingInput,
} from '@hitz-group/domain';

export interface useProductPricingsProps {
  productPricings: { [key: string]: ProductPricing };
  update: (input: ProductPricingInput[]) => void;
  delete: (input: DeleteProductPricingInput[]) => void;
  addProductPricing: (
    productId: string,
    pricings: {
      pricingGroupId: string;
      productPricing: ProductPricingInput;
    }[],
  ) => void;
  addBulkProductPricings: (
    input: {
      productId: string;
      pricings: {
        pricingGroupId: string;
        productPricing: ProductPricingInput;
      }[];
    }[],
  ) => void;
  loading: boolean;
  error: string | undefined;
  operation: Operation | null;
  deletedIds: Array<string>;
}

export function useProductPricings(): useProductPricingsProps {
  const [pricings, setPricings] = useState<Record<string, ProductPricing>>({});

  const [operation, setOperation] = useState<Operation | null>(Operation.READ);
  const [deletedIds, setDeletedIds] = useState<Array<string>>([]);

  const [update, updateRequest] = useMutation(UPDATE_PRODUCT_PRICING_MUTATION, {
    onError: noopHandler,
  });

  const [addProductPricing, addProductPricingRequest] = useMutation(
    ADD_PRODUCT_PRICING_MUTATION,
    {
      onError: noopHandler,
    },
  );

  const [addBulkProductPricing, addBulkProductPricingRequest] = useMutation(
    addBulkProductPricingsMutation,
    {
      onError: noopHandler,
    },
  );

  const [deletePricings, deleteOperation] = useMutation(
    DELETE_PRODUCT_PRICING_MUTATION,
    {
      onError: noopHandler,
    },
  );

  const updateProductPricings = useCallback(
    input => {
      update({ variables: { input } });
      setOperation(Operation.UPDATE);
    },
    [update],
  );

  useEffect(() => {
    if (updateRequest.data) {
      const updatedPricings = updateRequest.data
        .updateProductPricings as ProductPricing[];
      setPricings(pricings => {
        const pricingsTemp = { ...pricings };
        updatedPricings.forEach(x => {
          pricingsTemp[x.id] = x;
        });
        return pricingsTemp;
      });
    }
  }, [updateRequest.data]);

  useEffect(() => {
    if (addProductPricingRequest.data) {
      const createdPricing = addProductPricingRequest.data
        .addProductPricings as ProductPricing[];
      setPricings(pricings => {
        const pricingsTemp = { ...pricings };
        createdPricing.forEach(x => {
          pricingsTemp[x.id] = x;
        });
        return pricingsTemp;
      });
      setOperation(Operation.CREATE);
    }
  }, [addProductPricingRequest.data]);

  const deleteProductPricings = useCallback(
    (input: DeleteProductPricingInput[]) => {
      deletePricings({ variables: { input } });
      setOperation(Operation.DELETE);
      setDeletedIds(input.map(x => x.productId));
    },
    [deletePricings],
  );

  const addProductPricingDetails = useCallback(
    (
      productId: string,
      pricings: {
        pricingGroupId: string;
        productPricing: ProductPricingInput;
      }[],
    ) => {
      addProductPricing({ variables: { productId, pricings } });
      setOperation(null);
    },
    [addProductPricing],
  );

  useEffect(() => {
    if (addBulkProductPricingRequest.data) {
      const prodPricings = addBulkProductPricingRequest.data
        .addBulkProductPricings as ProductPricing[];
      setPricings(prev => {
        const prevPricings = { ...prev };
        prodPricings.forEach(x => {
          prevPricings[x.id] = x;
        });
        return prevPricings;
      });
    }
  }, [addBulkProductPricingRequest.data]);

  const addBulkProductPricingsDetails = useCallback(
    input => {
      addBulkProductPricing({ variables: { input } });
      setOperation(Operation.CREATE);
    },
    [addBulkProductPricing],
  );

  const error =
    updateRequest.error ||
    deleteOperation.error ||
    addProductPricingRequest.error ||
    addBulkProductPricingRequest.error;

  const loading =
    updateRequest.loading ||
    deleteOperation.loading ||
    addProductPricingRequest.loading ||
    addBulkProductPricingRequest.loading;

  return useMemo(
    () => ({
      productPricings: pricings,
      update: updateProductPricings,
      delete: deleteProductPricings,
      addProductPricing: addProductPricingDetails,
      addBulkProductPricings: addBulkProductPricingsDetails,
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
      deletedIds,
    }),
    [
      updateProductPricings,
      pricings,
      operation,
      deleteProductPricings,
      addProductPricingDetails,
      addBulkProductPricingsDetails,
      error,
      loading,
      deletedIds,
    ],
  );
}
