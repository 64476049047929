import {
  StyleFn,
  TableShape,
  Table,
  UpdateTableInput,
} from '@hitz-group/domain';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import Button from '../../../../../components/Button/Button';
import { isAndroid, isIos } from '../../../../../common/theme';
import Modal from '../../../../../components/Modals/Modal';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../../components/FormInput/FormInput';
import DropDown from '../../../../../components/FormInput/DropDown';
import { useNotification } from '../../../../../hooks/Notification';
import { isNotEmpty } from '../../../../../utils/validator';
import { pick } from 'lodash';

const containerStyle: StyleFn = () => ({
  width: 340,
  minHeight: isAndroid ? 610 : 480,
  alignSelf: 'center',
  height: isAndroid ? 610 : 480,
  paddingTop: 0,
  paddingBottom: 0,
  borderRadius: 10,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1,
  marginTop: theme.spacing.small,
});

const formInputContainerStyle: StyleFn = () => ({
  width: 130,
});

const formInputLongContainerStyle: StyleFn = () => ({
  width: 290,
});

const btnContainer: StyleFn = ({ theme }) => ({
  width: 290,
  height: 38,
  marginTop: theme.spacing.small,
  color: theme.colors.white,
});

const extraViewStyle: StyleFn = () => ({
  width: isIos ? 650 : null,
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.info,
  fontSize: theme.fontSize.medium,
  marginBottom: theme.spacing.small,
  marginLeft: theme.spacing.small,
});

interface Props {
  table: Table;
  onTableUpdate: (updatedTable: UpdateTableInput) => void;
  onTableDelete: (id: string) => void;
}

export const EditTableModal: React.FC<Props> = ({
  table,
  onTableDelete,
  onTableUpdate,
}) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState<Table>(table);

  const onChange = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onChangeNumber = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value ? parseInt(value.replace(/[^0-9]/g, '')) : value,
    }));
  }, []);

  const onPressUpdate = useCallback(() => {
    if (!form.name || !isNotEmpty(form.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.tableNameIsRequired'),
      });
      return;
    }

    const updatedTable = {
      ...pick(form, ['id', 'name', 'shape']),
      horizontalSeats: form.horizontalSeats,
      verticalSeats: form.verticalSeats,
      section: form.section.id,
    } as UpdateTableInput;

    onTableUpdate(updatedTable);
  }, [form, onTableUpdate, showNotification, translate]);

  const onPressDelete = useCallback(
    () => onTableDelete(table.id),
    [onTableDelete, table.id],
  );

  return (
    <>
      <Modal contentStyle={css(containerStyle)} showCloseButton={false}>
        <Text style={css(titleStyle)}>
          {translate('backOfficeSettings.createTable.editTable')}
        </Text>

        <View style={css(rowStyle)}>
          <FormInput
            title={translate('backOfficeSettings.createTable.tableName')}
            alignTitle={'left'}
            containerStyle={css(formInputLongContainerStyle)}
            testID="table-name"
            value={form.name}
            onChangeText={onChange.bind(null, 'name')}
          />
        </View>

        <View style={css(rowStyle)}>
          <FormInput
            title={translate('backOfficeSettings.createTable.seatsHorizontal')}
            alignTitle={'left'}
            onChangeText={onChangeNumber.bind(null, 'horizontalSeats')}
            containerStyle={css(formInputContainerStyle)}
            testID="tables-horizontal-seats"
            type="number"
            value={form.horizontalSeats.toString()}
          />
          <FormInput
            title={translate('backOfficeSettings.createTable.seatsVertical')}
            alignTitle={'left'}
            onChangeText={onChangeNumber.bind(null, 'verticalSeats')}
            containerStyle={css(formInputContainerStyle)}
            testID="tables-vertical-seats"
            type="number"
            value={form.verticalSeats.toString()}
          />
        </View>

        <View style={css(rowStyle)}>
          <DropDown
            title={translate('backOfficeSettings.createTable.tableShape')}
            values={Object.values(TableShape).map((key: string) => ({
              value: key,
              label: key,
            }))}
            selectedValue={form.shape}
            testID="table-shapes"
            style={css(formInputLongContainerStyle)}
            onValueChange={onChange.bind(null, 'shape')}
            extraViewStyle={css(extraViewStyle)}
          />
        </View>

        <View>
          <Button
            title={translate('backOfficeSettings.createTable.update')}
            onPress={onPressUpdate}
            containerStyle={css(btnContainer)}
            secondary
          />
          <Button
            title={translate('backOfficeSettings.createTable.delete')}
            onPress={onPressDelete}
            containerStyle={css(btnContainer)}
            danger
          />
          <Button
            title={translate('backOfficeSettings.createTable.dismiss')}
            onPress={closeModal}
            containerStyle={css(btnContainer)}
            transparent
          />
        </View>
      </Modal>
    </>
  );
};
