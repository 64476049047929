import { StyleFn, CreateTaxInput } from '@hitz-group/domain';
import React, { useEffect, useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import FormInput from '../../../components/FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../components/Button/Button';
import { CREATE_TAX } from '../../../graphql/settings';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { Tax } from '@hitz-group/domain';
import Modal from '../Modal';
import scale from '../../../common/theme';
import { isLessThanLimit, isFloat } from '../../../utils/validator';

interface TaxToTax {
  id: string;
  name: string;
  code: string;
  rate?: string;
  isActive?: boolean;
  taxes: Tax[];
}

const modalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginLeft: 0,
  marginTop: theme.spacing.medium,
});

const formInputTextStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'flex-start',
  paddingHorizontal: theme.padding.medium * 1.5,
});

const createLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const formInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: scale.textInputWidth180,
  fontSize: scale.moderateScale(10),
  fontFamily: theme.font.regular,
  letterSpacing: -0.5,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});
interface CreateTaxModalProps {
  onAddTax: (tax: TaxToTax) => void;
}

export const CreateTaxModal: React.FC<CreateTaxModalProps> = ({
  onAddTax,
}: CreateTaxModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [createForm, setCreateForm] = useState(
    {} as { name: string; code: string; rate: string },
  );
  const { showNotification } = useNotification();

  const onChangeTax = useCallback((prop: string, value: string) => {
    setCreateForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const [createTax, createdTax] = useMutation(CREATE_TAX, {
    onError: noopHandler,
  });

  const onCreateTax = useCallback(() => {
    if (!createForm.name || !createForm.code || !createForm.rate) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
    } else if (
      !isFloat(String(createForm.rate)) ||
      !isLessThanLimit(createForm.rate, 100)
    ) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'rate' }),
      });
    } else {
      const createTaxInputForm = {
        name: createForm.name,
        code: createForm.code,
        rate: parseFloat(createForm.rate) || 0,
      } as CreateTaxInput;
      createTax({
        variables: {
          input: createTaxInputForm,
        },
      });
    }
  }, [createForm, createTax, showNotification, translate]);

  useEffect(() => {
    if (createdTax.data && createdTax.data.createTax?.id) {
      onAddTax({
        name: createForm.name,
        id: createdTax.data.createTax.id,
        code: createForm.code,
        taxes: [],
        isActive: false,
        rate: createForm.rate,
      });
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.TaxItemCreatedSuccessfully'),
      });
    }
  }, [
    onAddTax,
    createForm,
    createdTax.data,
    showNotification,
    closeModal,
    translate,
  ]);

  useEffect(() => {
    if (createdTax.error) {
      showNotification({
        error: true,
        message: parseApolloError(createdTax.error),
      });
    }
  }, [createdTax.error, showNotification]);

  return (
    <Modal
      title={translate('backOfficeSettings.createTaxHeader')}
      onDismiss={closeModal}
      contentStyle={css(modalContainerStyle)}
    >
      <FormInput
        title={translate('backOfficeSettings.taxName') + '*'}
        placeholder={translate('backOfficeSettings.taxName')}
        value={createForm.name}
        alignTitle={'left'}
        containerStyle={css(formInputStyle)}
        textStyle={css(formInputTextStyle)}
        onChangeText={onChangeTax.bind(null, 'name')}
        maxLength={50}
      />
      <FormInput
        title={translate('backOfficeSettings.taxCode') + '*'}
        placeholder={translate('backOfficeSettings.taxCode')}
        value={createForm.code}
        containerStyle={css(formInputStyle)}
        textStyle={css(formInputTextStyle)}
        onChangeText={onChangeTax.bind(null, 'code')}
        maxLength={6}
      />
      <FormInput
        title={translate('backOfficeSettings.taxRate') + '*'}
        placeholder={translate('backOfficeSettings.taxRate')}
        value={createForm.rate?.toString() || ''}
        containerStyle={css(formInputStyle)}
        textStyle={css(formInputTextStyle)}
        onChangeText={onChangeTax.bind(null, 'rate')}
        keyboardType={'numeric'}
      />

      <Button
        title={translate('backOfficeSettings.createTax')}
        containerStyle={css(buttonContainerStyle)}
        labelStyle={css(createLabelStyle)}
        color={theme.colors.green}
        onPress={onCreateTax}
      />
    </Modal>
  );
};
