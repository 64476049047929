import { StyleFn, Product, ProductPricingInput } from '@hitz-group/domain';
import { Icons } from '@hitz-group/domain/dist/enums';
import React from 'react';
import { useFela } from 'react-fela';
import TableRow from '../../../../../components/TableComponent/TableRow';
import IconButton from '../../../../../components/Button/IconButton';
import Button from '../../../../../components/Button/Button';
import FormInput from '../../../../../components/FormInput/FormInput';
import { StackActions, useNavigation } from '@react-navigation/native';

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  flex: 1,
});
const editIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  flex: 1,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});
const editIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const attributeRowStyle: StyleFn = () => ({
  height: 57,
});

const formInputContainerStyle: StyleFn = ({ theme, width }) => ({
  width: width || 80,
  height: theme.input.height,
});

const checkBoxTitleStyle: StyleFn = ({ theme, isActive }) => ({
  ...theme.font14Regular,
  lineHeight: 21,
  textTransform: 'none',
  color: isActive ? theme.colors.black : theme.colors.paragraph,
});

const defaultCheckContainerStyle: StyleFn = ({ theme }) => ({
  width: 40,
  height: theme.input.height,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 0,
});

const radioCheckStyle: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.small,
});

interface VariantProductRowProps {
  variantId: string;
  product: Product;
  pricings: ProductPricingInput;
  currencySymbol: string;
  onChangeProductDetails: (
    id: string,
    prop: string,
    value: string | boolean,
  ) => void;
  onChangeProductPricings: (id: string, prop: string, value: string) => void;
  onDeleteProduct: (id: string, name: string) => void;
}

export const VariantProductRow: React.FC<VariantProductRowProps> = ({
  variantId,
  product,
  pricings,
  currencySymbol,
  onChangeProductDetails,
  onChangeProductPricings,
  onDeleteProduct,
}: VariantProductRowProps) => {
  const { css, theme } = useFela();
  const costPrice = pricings?.costPrice?.amount;
  const selPrice = pricings?.sellingPrice?.amount;
  const isDefault = product?.isDefault || false;

  const navigation = useNavigation();

  return (
    <TableRow
      containerStyle={css(attributeRowStyle)}
      action={
        <IconButton
          icon="times"
          iconSize={24}
          containerSize={34}
          containerStyle={css(closeIconContainerStyle)}
          iconStyle={css(closeIconStyle)}
          onPress={() => onDeleteProduct(product.id, product.name)}
        />
      }
    >
      <Button
        labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
        fluid
        iconPosition={'left'}
        onPress={onChangeProductDetails.bind(
          null,
          product.id,
          'isDefault',
          true,
        )}
        containerStyle={css(defaultCheckContainerStyle({ theme }))}
        iconContainerStyle={css(radioCheckStyle)}
        icon={isDefault ? Icons.RecordAudio : Icons.Circle}
        iconProps={{
          color: theme.colors.success,
          size: 20,
        }}
      />

      <FormInput
        value={product.name}
        containerStyle={css(formInputContainerStyle({ theme, width: 228 }))}
        onChangeText={value =>
          onChangeProductDetails(product.id, 'name', value)
        }
      />

      <FormInput
        value={costPrice || costPrice === 0 ? currencySymbol + costPrice : ''}
        containerStyle={css(formInputContainerStyle)}
        onChangeText={value =>
          onChangeProductPricings(product.id, 'costPrice', value)
        }
      />
      <FormInput
        value={selPrice || selPrice === 0 ? currencySymbol + selPrice : ''}
        containerStyle={css(formInputContainerStyle)}
        textInputStyle={css(formInputContainerStyle)}
        onChangeText={value =>
          onChangeProductPricings(product.id, 'sellingPrice', value)
        }
      />
      <IconButton
        icon="pen"
        iconSize={24}
        containerSize={34}
        containerStyle={css(editIconContainerStyle)}
        iconStyle={css(editIconStyle)}
        onPress={() => {
          navigation.dispatch(
            StackActions.push('ProductSettings', {
              productId: variantId,
              isVariant: true,
              isVariantProduct: undefined,
              screen: 'Variants',
            }),
          );
          navigation.navigate('ProductSettings', {
            productId: product.id,
            title: product.name,
            isVariant: undefined,
            isVariantProduct: true,
            screen: 'General',
          });
        }}
      />
    </TableRow>
  );
};
