import { Order } from '@hitz-group/domain';
import { round } from 'lodash';
import moment from 'moment';

export const getPricePerHour = (order: Order) =>
  round(
    order.totalPrice /
      (Math.floor(moment.duration(moment().diff(order?.createdAt)).asHours()) ||
        1),
    1,
  );

export const getCustomerName = (order: Order) =>
  !order.customer?.firstName && !order.customer?.lastName
    ? '--'
    : `${order.customer?.firstName} ${order.customer?.lastName}`;
