import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { useRoute } from '@react-navigation/native';
import { Helmet } from 'react-helmet';
import {
  Adjustment,
  AdjustmentUnit,
  NewAdjustment,
  UpdateAdjustment,
} from '@hitz-group/domain';
import Button from '../../../../../components/Button/Button';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import AdjustmentRow from './AdjustmentRow';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';

import { useModal } from '@hitz-group/rn-use-modal';
import { useAdjustments } from '../../../../../hooks/app/useAdjustments';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import { AdjustmentsStyles } from '../styles/Adjustments.styles';
import { useNotification } from '../../../../..//hooks/Notification';
import { differenceWith, isEmpty, isEqual } from 'lodash';
import { number as numberSchema } from 'yup';

export type ErrorState = Record<'name' | 'amount', boolean>;

export interface AdjustmentRowItem extends Partial<Adjustment> {
  amountStr: string;
}

const discountAmountSchema = numberSchema().min(-100);

const validateUpdateAdjustments = (
  adjustments: AdjustmentRowItem[],
): {
  errors: Record<number, ErrorState>;
  message: string;
} => {
  const errors: Record<number, ErrorState> = {};

  adjustments.forEach(({ amountStr, adjustmentUnit }, index) => {
    const amount = +amountStr;
    if (
      adjustmentUnit === AdjustmentUnit.PERCENTAGE &&
      !discountAmountSchema.isValidSync(amount)
    ) {
      errors[index] = { ...errors[index], amount: true };
    }
  });
  if (!isEmpty(errors))
    return {
      errors,
      message: 'backofficeVenueSettingAdjustments.discountConstrain',
    };

  adjustments.forEach(({ name, amountStr }, index) => {
    const amount = +amountStr;
    if (!amount || !name) {
      errors[index] = {
        amount: !Boolean(amount),
        name: !Boolean(name),
      };
    }
  });

  if (!isEmpty(errors))
    return {
      errors,
      message: 'backofficeVenueSettingAdjustments.emptyValueWarningMessage',
    };

  const duplicateNameMap = adjustments.reduce((nameObj, current) => {
    const key = current.name?.toLowerCase().trim();
    if (key) {
      nameObj[key] = (nameObj[key] || 0) + 1;
    }
    return nameObj;
  }, {} as Record<string, number>);

  adjustments.forEach(({ name }, index) => {
    if (duplicateNameMap[name?.toLowerCase().trim() || ''] > 1) {
      errors[index] = {
        ...errors[index],
        name: true,
      };
    }
  });

  return {
    errors,
    message: 'backofficeVenueSettingAdjustments.duplicateNameWarningMessage',
  };
};

const Adjustments: React.FC = () => {
  const route = useRoute();
  const { translate } = useTranslation();
  const styles = AdjustmentsStyles();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const [customAdjustments, setCustomAdjustments] = useState<
    AdjustmentRowItem[]
  >([]);
  const [errors, setErrors] = useState<Record<number, ErrorState>>([]);
  const originalAdjustments = useRef<AdjustmentRowItem[]>([]);
  const deletingId = useRef('');

  const { venueId } = route.params as {
    venueId: string;
  };

  const handleDeleteItemComplete = () => {
    setCustomAdjustments(preAdjustments => {
      return preAdjustments.filter(item => item.id != deletingId.current);
    });
    originalAdjustments.current = originalAdjustments.current.filter(
      item => item.id !== deletingId.current,
    );
  };
  const {
    adjustments,
    getAllAdjustments,
    deleteAdjustment,
    loading,
    updateAdjustments,
  } = useAdjustments({ venueId, onDeleteComplete: handleDeleteItemComplete });

  const COLUMNS = [
    {
      title: translate('backofficeVenueSettingAdjustments.adjustment'),
      width: 300,
      containerStyle: {
        marginRight: 10,
        paddingLeft: 10,
      },
    },
    {
      title: translate('backofficeVenueSettingAdjustments.amount'),
      width: 130,
      alignItems: 'center',
      containerStyle: {
        marginRight: 10,
      },
    },
    {
      title: translate('backofficeVenueSettingAdjustments.allowOnPaymentType'),
      flex: 1,
      alignItems: 'flex-start',
    },
  ];

  const handleChangeItem = (newItem: AdjustmentRowItem, index: number) => {
    setCustomAdjustments(preAdjustments => {
      const updateAdjustments = [...preAdjustments];
      updateAdjustments.splice(index, 1, newItem);
      return updateAdjustments;
    });
  };

  const handleCreateAdjustment = () => {
    setCustomAdjustments(preAdjustments => {
      return [
        ...preAdjustments,
        {
          adjustmentUnit: AdjustmentUnit.PERCENTAGE,
          amount: 0,
          name: '',
          allowOnPaymentType: false,
          amountStr: '0.00',
        },
      ];
    });
  };

  const handleSave = () => {
    const { errors, message } = validateUpdateAdjustments(customAdjustments);

    if (isEmpty(errors)) {
      const filterUpdateAdjustments = differenceWith(
        customAdjustments,
        originalAdjustments.current || [],
        isEqual,
      );

      const adjustmentInputs = filterUpdateAdjustments.map(item => {
        const {
          adjustmentUnit,
          allowOnPaymentType = false,
          id,
          name,
          amountStr,
        } = item;

        return {
          adjustmentUnit,
          allowOnPaymentType,
          amount: parseFloat(amountStr),
          name,
          id,
        };
      });

      const newData = adjustmentInputs.filter(
        adjustment => !adjustment.id,
      ) as NewAdjustment[];

      const updateData = adjustmentInputs.filter(
        adjustment => adjustment.id,
      ) as UpdateAdjustment[];

      updateAdjustments({
        newAdjustments: newData,
        updateAdjustments: updateData,
      });
      return;
    }

    showNotification({
      message: translate(message),
      error: true,
    });
    setErrors(errors);
  };

  const handleDeleteItem = useCallback(
    (item: AdjustmentRowItem, index: number): void => {
      if (!item?.id) {
        setCustomAdjustments(pre => {
          pre.splice(index, 1);
          return [...pre];
        });
        setErrors(errors => {
          const newError = { ...errors };
          delete newError[index];
          return newError;
        });
      } else {
        showModal(
          <ConfirmationDialog
            title={translate('dialog.deleteTitle')}
            message={translate('dialog.deleteConfirmation', {
              label: item.name,
            })}
            onConfirm={() => {
              deleteAdjustment(item.id as string);
              deletingId.current = item.id as string;
              closeModal();
            }}
          />,
        );
      }
    },
    [setErrors, showModal, translate, deleteAdjustment, closeModal],
  );

  useEffect(() => {
    getAllAdjustments();
  }, [getAllAdjustments]);

  useEffect(() => {
    if (!adjustments) return;
    const customAdjustmentData = adjustments.map(item => ({
      ...item,
      amountStr: `${item.amount}`,
    }));
    setCustomAdjustments(customAdjustmentData);
    originalAdjustments.current = customAdjustmentData;
    setErrors([]);
  }, [adjustments]);

  const hasDataChanged = !isEqual(
    originalAdjustments.current,
    customAdjustments,
  );

  if (loading) return <LoadingIndicator />;
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={styles.pageStyle}>
        <ScrollView contentContainerStyle={styles.scrollStyle}>
          <BackOfficeSection
            title={translate('backofficeVenueSettingAdjustments.adjustments')}
            titleDescription={translate(
              'backofficeVenueSettingAdjustments.adjustmentDescription',
            )}
            action={
              <BackOfficeCreateNewButton onPress={handleCreateAdjustment} />
            }
            contentContainerStyle={styles.formStyle}
            containerStyle={styles.containerStyle}
          >
            <TableComponent
              columns={COLUMNS}
              normalRows={true}
              data={customAdjustments}
              columnContainerStyle={styles.columnContainerStyle}
              renderRow={(
                item: AdjustmentRowItem,
                index: number,
              ): React.ReactNode => (
                <AdjustmentRow
                  item={item}
                  key={index}
                  index={index}
                  onDeleteItem={handleDeleteItem}
                  onChangeItem={handleChangeItem}
                  error={errors[index]}
                />
              )}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>

      {hasDataChanged && !loading && (
        <View style={styles.actionsContainerStyle}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={styles.saveButtonStyle}
            labelStyle={styles.titleStyle}
            onPress={handleSave}
          />
        </View>
      )}
    </>
  );
};

export default Adjustments;
