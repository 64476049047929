import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { Text, View, StyleSheet } from 'react-native';
import Button from '../Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';
import ModalDialog from './Modal';
export interface ConfirmationModalProps {
  onConfirm?: () => void;
  title: string;
  message: string;
}

const headingStyle: StyleFn = ({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: theme.fontSize.small,
  width: '100%',
  textAlign: 'center',
  color: theme.colors.paragraph,
  fontFamily: theme.font.semibold,
});

const confirmationStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
  width: '100%',
  textAlign: 'center',
  color: theme.colors.primary,
  fontFamily: theme.font.medium,
  marginTop: theme.spacing.big / 2,
  marginBottom: theme.spacing.big,
});

const rowContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
});

const deleteButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  textTransform: 'capitalize',
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
});

const buttonContainerStyle: StyleFn = ({ theme, rightAlign, success }) => ({
  height: 38,
  width: 100,
  marginLeft: rightAlign ? 'auto' : 0,
  backgroundColor: success ? theme.colors.success : theme.colors.danger,
});

const styles = StyleSheet.create({
  container: {
    width: 265,
  },
});

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  title,
  message,
}: ConfirmationModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const onPressConfirm = useCallback(() => {
    onConfirm && onConfirm();
  }, [onConfirm]);

  const onPressReject = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <ModalDialog onDismiss={onPressReject}>
      <View style={styles.container}>
        <Text style={css(headingStyle)} testID={'confirm-title'}>
          {title}
        </Text>
        <Text style={css(confirmationStyle)} testID={'confirm-message'}>
          {message}
        </Text>
        <View style={css(rowContainerStyle)}>
          <Button
            testID={'confirm-yes'}
            fluid
            color={theme.colors.danger2}
            onPress={onPressConfirm}
            labelStyle={css(deleteButtonStyle)}
            containerStyle={css(buttonContainerStyle({ theme, success: true }))}
            title={translate('dialog.yes')}
          />
          <Button
            testID={'confirm-no'}
            fluid
            color={theme.colors.danger2}
            onPress={onPressReject}
            labelStyle={css(deleteButtonStyle)}
            containerStyle={css(
              buttonContainerStyle({ theme, rightAlign: true }),
            )}
            title={translate('dialog.no')}
          />
        </View>
      </View>
    </ModalDialog>
  );
};

export default ConfirmationModal;
