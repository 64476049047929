import { Order, StyleFn, Table, TableShape } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { FlatList, View } from 'react-native';
import Popover, {
  PopoverMode,
  PopoverPlacement,
} from 'react-native-popover-view';
import scale from '../../../../common/theme';
import { TableIcon } from '../../../../components/TableIcon/TableIcon';

const TABLE_ICON_SQUARE = 70;
const TABLE_ICON_MARGIN_HORIZONTAL = 8.5;
const TABLE_ICON_MARGIN_TOP = 20;
const TABLE_ICON_MARGIN_BOTTOM = 8.5;

const showToolTipArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const popoverStyle: StyleFn = ({ theme, numberOrder }) => ({
  width: (TABLE_ICON_SQUARE + TABLE_ICON_MARGIN_HORIZONTAL * 2) * numberOrder,
  height: TABLE_ICON_SQUARE + TABLE_ICON_MARGIN_TOP + TABLE_ICON_MARGIN_BOTTOM,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.white,
  ...theme.shadow30,
});

const backgroundStyle: StyleFn = ({ theme }) => ({
  height: scale.deviceHeight,
  width: '100%',
  backgroundColor: theme.colors.background,
  position: 'absolute',
  opacity: 0.85,
});

const flatListStyle: StyleFn = () => ({
  flex: 1,
});

export interface MultiOrderTableIconProps {
  showPopover: boolean;
  onRequestClose: () => void;
  children?: React.ReactNode;
  table: Table;
  orders?: Order[];
  onPressSubTable: (table: Table, orderIndex?: number) => void;
}

const MultiOrderTableIcon: React.FC<MultiOrderTableIconProps> = ({
  children,
  showPopover,
  onRequestClose,
  orders = [],
  table,
  onPressSubTable,
}) => {
  const { css } = useFela({
    numberOrder: orders.length < 4 ? 4 : orders.length,
  });
  return (
    <Popover
      placement={PopoverPlacement.TOP}
      from={<View>{children}</View>}
      backgroundStyle={css(backgroundStyle)}
      popoverStyle={css(popoverStyle)}
      arrowStyle={css(showToolTipArrowStyle)}
      onRequestClose={onRequestClose}
      isVisible={showPopover}
      mode={PopoverMode.RN_MODAL}
    >
      <FlatList
        style={css(flatListStyle)}
        horizontal
        showsHorizontalScrollIndicator={false}
        data={orders}
        renderItem={({ item: order, index }) => {
          const subTable = {
            name: `${table.name}-${index + 1}`,
            shape: TableShape.ROUND,
            status: order.table?.status,
          } as Table;
          const onPress = () => onPressSubTable(table, index);
          return <TableIcon table={subTable} onPressTable={onPress} />;
        }}
      />
    </Popover>
  );
};

export default MultiOrderTableIcon;
