import { App, StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { OfficeUser, PosUser } from '../../../state/userUtility';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { View, Text, ScrollView } from 'react-native';
import Button from '../../../components/Button/Button';
import Logo from '../../../components/Logo/Logo';
import UserProfileTile from './UserProfileTile';
import { useNetInfo } from '@react-native-community/netinfo';

const boxStyle: StyleFn = ({ theme }) => ({
  width: 610,
  height: 530,
  borderRadius: theme.radius.large,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  paddingVertical: theme.spacing.big,
  ...theme.shadow15,
  ...theme.border.borderSolid,
});

const posUserInstructionTitleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  marginTop: theme.spacing.small,
  lineHeight: theme.spacing.medium,
  marginBottom: theme.spacing.big,
  textAlign: 'center',
});

const gotoOfficeBtnStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  width: 'auto',
  height: 32,
  marginTop: theme.padding.large,
  marginHorizontal: theme.padding.small,
  alignSelf: 'center',
});

const loginAsAnotherUserBtnTxtStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.grey2,
});

const gotoOfficeBtnTxtStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
});

const usersWrapperStyle: StyleFn = () => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: 546,
});

const logoStyle: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.small,
  alignSelf: 'center',
  height: 62,
  width: 150,
});

const footerBtnsWrapperStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small / 2,
  flexDirection: 'row',
  justifyContent: 'center',
});

const boxViewStyle: StyleFn = ({ theme }) => ({
  height: 124,
  width: 126,
  borderRadius: theme.radius.small,
  margin: theme.padding.small,
  backgroundColor: theme.colors.greyLight,
});

export interface UserLockProfilesProps {
  users: Array<OfficeUser | PosUser>;
  onSelectedUser: (user: OfficeUser | PosUser) => void;
  btnAttrs: {
    title: string;
    onPress: () => void;
  };
  title: string;
  app?: App;
  onPressNewUserLogin?: () => void;
}
const ActiveUsersComponent: React.FC<UserLockProfilesProps> = ({
  users,
  onSelectedUser,
  btnAttrs,
  title,
  app = App.POS_APP,
  onPressNewUserLogin,
}) => {
  const { translate } = useTranslation();
  const { css, theme } = useFela();
  const netInfo = useNetInfo();

  let box = 8 - users.length;
  if (box < 0) {
    box = 4 + box;
  }
  const onSelectUser = useCallback(
    (user: OfficeUser | PosUser) => {
      onSelectedUser(user);
    },
    [onSelectedUser],
  );

  return (
    <View style={css(boxStyle)}>
      {!netInfo.isConnected && (
        <Text
          nativeID="noInternetInfoText"
          style={css(posUserInstructionTitleStyle)}
        >
          {translate('settings.noInternet')}
        </Text>
      )}
      <Logo testID="logo" style={css(logoStyle)} />

      <Text
        nativeID="interimLockOfficeInfoText"
        style={css(posUserInstructionTitleStyle)}
      >
        {title}
      </Text>
      <ScrollView
        contentContainerStyle={css(usersWrapperStyle)}
        showsVerticalScrollIndicator={false}
      >
        {users.map(user => {
          return (
            <UserProfileTile
              key={user.id}
              user={user}
              disabled={!netInfo.isConnected}
              onSelectUser={onSelectUser}
            />
          );
        })}
        {/* // TODO: improve this logic (optimization) for later version! */}
        {[...Array(box)].map((n, i) => {
          return <View key={i} style={css(boxViewStyle)}></View>;
        })}
      </ScrollView>
      <View style={css(footerBtnsWrapperStyle)}>
        <Button
          title={btnAttrs.title}
          size="xsmall"
          color={theme.colors.blue}
          containerStyle={css(gotoOfficeBtnStyle)}
          labelStyle={css(gotoOfficeBtnTxtStyle)}
          onPress={btnAttrs.onPress}
        />
        {app === App.BACKOFFICE && (
          <Button
            title={translate('interimLockScreen.office.loginAsDifferentUser')}
            size="xsmall"
            color={theme.colors.white}
            containerStyle={css(gotoOfficeBtnStyle)}
            labelStyle={css([
              gotoOfficeBtnTxtStyle,
              loginAsAnotherUserBtnTxtStyle,
            ])}
            onPress={onPressNewUserLogin}
          />
        )}
      </View>
    </View>
  );
};

const ActiveUsers = React.memo(ActiveUsersComponent);
ActiveUsers.displayName = 'ActiveUsers';

export default ActiveUsers;
