import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View, Text } from 'react-native';
import { useModal } from '@hitz-group/rn-use-modal';
import TableComponent from '../../../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import { useNotification } from '../../../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import Button from '../../../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import { useProductPricings } from '../../../../../../hooks/app/useProductPricings';
import { usePricingGroups } from '../../../../../../hooks/app/usePricingGroups';
import find from 'lodash/find';
import { Operation } from '../../../../../../types/Operation';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import { SetPriceModal } from '../../../../../../components/Modals/ProductPrices/SetPrices';
import AlertModal from '../../../../../../components/Modals/AlertModal';
import keyBy from 'lodash/keyBy';
import { ProductPriceRow } from './ProductPriceRow';
import { ProductPriceHeaderFilter } from './ProductPriceHeaderFilter';
import { PriceListContext } from '../PriceListTabNavigator';
import { stripProperties } from '../../../../../../utils/stripObjectProps';
import {
  StyleFn,
  ProductPricing as ProductPricingDefault,
  DEFAULT_PRICING_GROUP,
  DEFAULT_ENTITY_ID,
  ProductPricingInput,
  DeleteProductPricingInput,
} from '@hitz-group/domain';
import { useNavigation, useRoute } from '@react-navigation/native';
import scale, { isWeb } from '../../../../../../common/theme';
import { differenceBy } from 'lodash';

export interface ProductPricing extends ProductPricingDefault {
  isSelected?: boolean;
  isChanged?: boolean;
  isNew?: boolean;
  tax?: string;
  price?: string;
  productId: string;
}

const scrollStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  paddingTop: theme.spacing.big + theme.spacing.small / 2,
  paddingHorizontal: theme.padding.medium * 3,
});

const contentContainerStyle: StyleFn = () => ({
  zIndex: 1000,
  flex: 1,
});

const backOfficeContainerStyle: StyleFn = () => ({
  flex: 1,
});

const customTitleStyle: StyleFn = () => ({
  zIndex: 2000,
});

const defaultTextBarStyle: StyleFn = ({ theme }) => ({
  width: '80%',
  justifyContent: 'center',
  marginHorizontal: 'auto',
  marginTop: theme.spacing.small,
  padding: theme.padding.medium,
  borderRadius: theme.radius.large,
  backgroundColor: '#fff6da', // should be added according to the theme
});

const defaultTextStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.big / 2,
  color: '#FF9800', // should be added according to the theme
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.medium,
  lineHeight: 21,
  textAlign: 'center',
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 38,
  height: 38,
  marginLeft: theme.spacing.big / 2,
});

const noModifierGroupTextStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.big / 2,
  color: theme.colors.primaryLightest,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 17,
  width: 17,
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 17,
  width: 17,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

export const ProductPriceTab: React.FC = () => {
  const priceListContext = useContext(PriceListContext);
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const route = useRoute();
  const navigation = useNavigation();
  const { unAppendCurrency } = useCurrency();
  const { showModal, closeModal } = useModal();
  const [productPricingData, setProductPricingData] = useState<
    Record<string, ProductPricing>
  >({});
  const [deletedProductPricing, setDeletedProductPricing] = useState<
    DeleteProductPricingInput[]
  >([]);
  const [defaultPricing, setDefaultPricing] = useState<
    Record<string, ProductPricing>
  >({});

  const [filterValue, setFilterValue] = useState('');

  const params =
    priceListContext.params ||
    (route.params as {
      pricingGroupId: string;
      screen?: string;
    });

  const {
    error: errorPP,
    loading: loadingPP,
    operation: PPOperation,
    addBulkProductPricings,
    delete: deleteProductPricings,
    update: updateProductPricings,
    deletedIds,
  } = useProductPricings();

  const {
    pricingGroups,
    defaultPricingGroup,
    error: PGErr,
    loading: PGLoading,
    getPricingGroup,
    getAllPricingGroups,
  } = usePricingGroups();

  const pricingGroupId = params?.pricingGroupId || '';
  const isDefault =
    pricingGroups[pricingGroupId]?.name === DEFAULT_PRICING_GROUP;

  useEffect(() => {
    navigation.setParams(params);
  }, [params, navigation]);

  const pricesDataInDb = useMemo(() => {
    if (pricingGroups && pricingGroupId) {
      return keyBy(
        (pricingGroups?.[pricingGroupId]?.prices || []).map(x => ({
          ...x,
          productId: x.product?.id,
        })),
        'productId',
      );
    }
  }, [pricingGroupId, pricingGroups]);

  useEffect(() => {
    if (pricesDataInDb) {
      setProductPricingData(pricesDataInDb);
    }
  }, [pricesDataInDb, pricingGroupId, pricingGroups]);

  useEffect(() => {
    if (defaultPricingGroup) {
      const defaultPricingData = keyBy(
        (defaultPricingGroup?.prices || []).map(x => ({
          ...x,
          productId: x.product?.id,
        })),
        'productId',
      );
      setDefaultPricing(defaultPricingData);
    }
  }, [defaultPricingGroup]);

  const error = PGErr || errorPP;

  const loading = PGLoading || loadingPP;

  const isProductPricingCreated =
    !errorPP && !loadingPP && PPOperation === Operation.CREATE;
  const isProductPricingUpdated =
    !errorPP && !loadingPP && PPOperation === Operation.UPDATE;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!loading && !errorPP && PPOperation === Operation.DELETE) {
      showNotification({
        success: true,
        message: translate('pricings.productPricingsDeletedSuccessfully'),
      });
    }
  }, [PPOperation, loading, errorPP, showNotification, translate, deletedIds]);

  useEffect(() => {
    if (isProductPricingCreated) {
      showNotification({
        success: true,
        message: translate('pricings.productPricingCreatedSuccessfully'),
      });
      getAllPricingGroups();
    }
  }, [
    isProductPricingCreated,
    translate,
    showNotification,
    getAllPricingGroups,
  ]);

  const productPricingDataArray = useMemo(() => {
    return Object.values(productPricingData).filter(
      x => x.product?.id,
    ) as ProductPricing[];
  }, [productPricingData]);

  const filterData = useMemo(() => {
    const filteredProductPrice = productPricingDataArray.filter(x =>
      filterValue.length > 1
        ? (x.product?.pages || []).map(y => y.id).includes(filterValue)
        : true,
    );
    return filteredProductPrice;
  }, [filterValue, productPricingDataArray]);

  const selectedProductPrices = useMemo(() => {
    return productPricingDataArray.filter(x => x.isSelected);
  }, [productPricingDataArray]);

  const titleCheckBoxStatus = useMemo(() => {
    if (find(productPricingDataArray, { isSelected: true })) {
      return true;
    }
    return false;
  }, [productPricingDataArray]);

  const onSetPrices = useCallback(
    (newPrice: number) => {
      const tempObj = {} as Record<string, ProductPricing>;
      selectedProductPrices.forEach(x => {
        tempObj[x.productId] = {
          ...x,
          sellingPrice: {
            ...x.sellingPrice,
            amount: newPrice,
          },
          isChanged: true,
        };
      });
      setProductPricingData(prev => {
        return {
          ...prev,
          ...tempObj,
        };
      });
      showNotification({
        success: true,
        message: translate('pricings.operationSetSuccess', {
          value: '$' + newPrice,
        }),
      });
    },
    [selectedProductPrices, showNotification, translate],
  );

  const setPrices = useCallback(() => {
    if (selectedProductPrices.length > 0) {
      showModal(
        <SetPriceModal
          onSubmit={newPrice => {
            onSetPrices(newPrice);
            closeModal();
          }}
          title="set prices"
          mode="set"
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProductPrices,
    translate,
    onSetPrices,
  ]);

  const onModifyPrices = useCallback(
    (input: number, sign: boolean, percent: boolean) => {
      const tempObj = {} as Record<string, ProductPricing>;
      selectedProductPrices.forEach(x => {
        const currentPrice = x.sellingPrice.amount;
        let updatedPrice = 0;
        if (percent) {
          const priceVariation = (currentPrice * input) / 100;
          updatedPrice = sign
            ? currentPrice + priceVariation
            : currentPrice - priceVariation;
        } else {
          updatedPrice = sign ? currentPrice + input : currentPrice - input;
        }
        tempObj[x.productId] = {
          ...x,
          sellingPrice: {
            ...x.sellingPrice,
            amount: updatedPrice,
          },
          isChanged: true,
        };
      });
      setProductPricingData(prev => {
        return {
          ...prev,
          ...tempObj,
        };
      });
      showNotification({
        success: true,
        message: translate('pricings.operationModifySuccess', {
          value: (sign ? '+' : '-') + input + (percent ? '%' : '$'),
        }),
      });
    },
    [selectedProductPrices, showNotification, translate],
  );

  const modifyPrices = useCallback(() => {
    if (selectedProductPrices.length > 0) {
      showModal(
        <SetPriceModal
          onSubmit={(input, sign, percent) => {
            onModifyPrices(input, sign, percent);
            closeModal();
          }}
          title="modify prices"
          mode="modify"
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProductPrices,
    translate,
    onModifyPrices,
  ]);

  const onResetPrices = useCallback(() => {
    const tempObj = {} as Record<string, ProductPricing>;
    selectedProductPrices.forEach(x => {
      tempObj[x.productId] = {
        ...x,
        sellingPrice:
          defaultPricing[x.product.id]?.sellingPrice || x.sellingPrice,
        isChanged: true,
      };
    });
    setProductPricingData(prev => {
      return {
        ...prev,
        ...tempObj,
      };
    });
    showNotification({
      success: true,
      message: translate('pricings.operationResetSuccess'),
    });
  }, [selectedProductPrices, defaultPricing, showNotification, translate]);

  const resetPrices = useCallback(() => {
    if (selectedProductPrices.length > 0) {
      showModal(
        <AlertModal
          title={translate('pricings.resetTitle')}
          actionTitle={translate('pricings.resetActionTitle')}
          message={translate('pricings.resetMessage')}
          onConfirm={() => {
            onResetPrices();
            closeModal();
          }}
        />,
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProductPrices,
    translate,
    onResetPrices,
  ]);

  const onChange = useCallback(
    (id, prop, value) => {
      if (prop === 'sellingPrice') {
        setProductPricingData(prev => {
          const sellingPrice = prev[id]?.sellingPrice;
          return {
            ...prev,
            [id]: {
              ...prev[id],
              costPrice: defaultPricing[id].costPrice,
              [prop]: {
                ...sellingPrice,
                amount: unAppendCurrency(value),
              },
              isChanged: true,
            },
          };
        });
      } else if (prop === 'isSelected') {
        setProductPricingData(prev => {
          return {
            ...prev,
            [id]: {
              ...prev[id],
              isSelected: value,
            },
          };
        });
      }
    },
    [defaultPricing, unAppendCurrency],
  );

  const selectToggle = useCallback(
    (isSelected): void => {
      const tempObj = {} as Record<string, ProductPricing>;
      productPricingDataArray.forEach(x => {
        tempObj[x.productId] = {
          ...x,
          isSelected: isSelected,
        };
      });
      setProductPricingData(tempObj);
    },
    [productPricingDataArray],
  );

  const changedProductPricings = useMemo(() => {
    return productPricingDataArray.filter(x => x && x.isChanged);
  }, [productPricingDataArray]);

  useEffect(() => {
    if (isProductPricingUpdated) {
      showNotification({
        success: true,
        message: translate('pricings.productPricingUpdatedSuccessfully'),
      });
      getPricingGroup(pricingGroupId);
    }
  }, [
    isProductPricingUpdated,
    showNotification,
    translate,
    getPricingGroup,
    pricingGroupId,
  ]);

  const addProductPricingData = useCallback(() => {
    const input = Object.values(productPricingData)
      .filter(x => x.isNew)
      .map(x => {
        const productId = x.productId;
        const sellingTax = x.sellingTax?.id;
        const costPrice = x.costPrice;

        const newProductPricing = stripProperties(
          x,
          'isSelected',
          'id',
          'isNew',
          'isChanged',
          '__typename',
          'isActive',
          'pricingGroup',
          'productId',
          'costPrice',
        );

        return {
          productId,
          pricings: [
            {
              pricingGroupId,
              productPricing: {
                ...newProductPricing,
                product: productId,
                pricingGroupId,
                sellingPrice: {
                  ...newProductPricing.sellingPrice,
                  amount: +newProductPricing.sellingPrice.amount,
                },
                sellingTax,
                ...(costPrice && {
                  costPrice: {
                    amount: +costPrice.amount,
                    currency: costPrice.currency,
                  },
                }),
              } as ProductPricingInput,
            },
          ],
        };
      });
    if (input.length > 0) {
      addBulkProductPricings(input);
    }
  }, [productPricingData, pricingGroupId, addBulkProductPricings]);

  const deleteProductPricing = useCallback(() => {
    if (deletedProductPricing.length > 0) {
      deleteProductPricings(deletedProductPricing);
    }
  }, [deleteProductPricings, deletedProductPricing]);

  const updateProductPricingsData = useCallback(() => {
    const updateProductPricingsInput = changedProductPricings
      .filter(x => !x.isNew)
      .map(x => ({
        id: x.id,
        sellingPrice: {
          amount: +x.sellingPrice.amount,
          currency: x.sellingPrice.currency,
        },
        ...(x.costPrice && {
          costPrice: {
            amount: +x.costPrice.amount,
            currency: x.costPrice.currency,
          },
        }),
        product: x.product.id,
        pricingGroupId,
      })) as ProductPricingInput[];
    if (updateProductPricingsInput.length > 0) {
      updateProductPricings(updateProductPricingsInput);
    }
  }, [changedProductPricings, updateProductPricings, pricingGroupId]);

  const onPressSave = useCallback((): void => {
    if (
      !changedProductPricings.length &&
      !deletedProductPricing.length &&
      !Object.values(productPricingData).some(x => x.isNew)
    ) {
      showNotification({
        error: true,
        message: translate('pricings.noRecordsChanged'),
      });
    } else {
      deleteProductPricing();
      addProductPricingData();
      updateProductPricingsData();
    }
  }, [
    changedProductPricings,
    deletedProductPricing,
    updateProductPricingsData,
    addProductPricingData,
    deleteProductPricing,
    translate,
    showNotification,
    productPricingData,
  ]);

  const onDeleteRow = useCallback(
    (id: string) => {
      if (isDefault) {
        showNotification({
          error: true,
          message: translate('pricings.defaultPricingGroupError'),
        });
      } else {
        if (pricesDataInDb && pricesDataInDb[id]) {
          setDeletedProductPricing(prev => [
            ...prev,
            { productId: id, pricingGroupId },
          ]);
        }
        setProductPricingData(prev => {
          const temp = { ...prev };
          delete temp[id];
          return temp;
        });
      }
    },
    [translate, isDefault, pricingGroupId, showNotification, pricesDataInDb],
  );

  const onPressAdd = useCallback(
    data => {
      const isExistsInState = productPricingData?.[data.product?.id];
      if (isExistsInState?.id) {
        showNotification({
          error: true,
          message: translate('pricings.duplicateProductPricing'),
        });
      } else {
        setProductPricingData(prev => ({
          ...prev,
          [data.productId]: { ...data, isNew: true },
        }));
        const remainingProductPricingToDelete = deletedProductPricing.filter(
          x => x.productId !== data.productId,
        );
        setDeletedProductPricing(remainingProductPricingToDelete);
      }
    },
    [showNotification, translate, productPricingData, deletedProductPricing],
  );

  const onPressAddAll = useCallback(
    (DataArray: ProductPricing[]) => {
      const newData = DataArray.filter(
        (data: { product: { id: string | number } }) =>
          !!!productPricingData?.[data.product?.id],
      );
      const newProductPricingData = newData.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.productId]: { ...curr, isNew: true },
        }),
        {},
      );
      setProductPricingData(prev => ({
        ...prev,
        ...newProductPricingData,
      }));
      const remainingProductPricingToDelete = differenceBy(
        deletedProductPricing,
        newData,
        'productId',
      );
      setDeletedProductPricing(remainingProductPricingToDelete);
    },
    [deletedProductPricing, productPricingData],
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  if (pricingGroupId === DEFAULT_ENTITY_ID) {
    return (
      <>
        <Helmet>
          <title>
            {translate('navigation.pricingSettingsPageTitle', {
              appName: translate('appName'),
            })}
          </title>
        </Helmet>
        <View style={css(pageStyle)}>
          <BackOfficeSection
            titleBorderBottom
            customTitleStyle={css(customTitleStyle)}
            containerStyle={css(backOfficeContainerStyle)}
            contentContainerStyle={css(contentContainerStyle)}
          >
            <Text
              style={css(noModifierGroupTextStyle)}
              testID={'empty-product-pricing-message'}
            >
              {translate('pricings.noProductPricingMessage')}
            </Text>
          </BackOfficeSection>
        </View>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.pricingSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView style={css(scrollStyle)}>
        <View style={css(pageStyle)}>
          {isDefault && (
            <View style={css(defaultTextBarStyle)}>
              <Text style={css(defaultTextStyle)}>
                {translate('pricings.defaultPricingText')}
              </Text>
            </View>
          )}
          <BackOfficeSection
            customTitleStyle={css(customTitleStyle)}
            titleAction={
              <ProductPriceHeaderFilter
                setPrices={setPrices}
                modifyPrices={modifyPrices}
                resetPrices={resetPrices}
                filterValue={filterValue}
                defaultPricing={defaultPricing}
                isDefault={isDefault}
                onFilter={value => setFilterValue(value)}
                onPressAdd={onPressAdd}
                onPressAddAll={onPressAddAll}
              />
            }
            contentContainerStyle={css(contentContainerStyle)}
          >
            {(!productPricingDataArray.length && (
              <Text
                style={css(noModifierGroupTextStyle)}
                testID={'empty-product-pricing-message'}
              >
                {translate('pricings.noProductPricingMessage')}
              </Text>
            )) || (
              <TableComponent
                columns={[
                  {
                    title: (
                      <Button
                        testID={'title-checkbox'}
                        onPress={() => selectToggle(!titleCheckBoxStatus)}
                        fluid
                        iconPosition={'left'}
                        containerStyle={css(
                          checkBoxTitleContainer({ theme, marginLeft: true }),
                        )}
                        iconContainerStyle={
                          titleCheckBoxStatus
                            ? css(checkIconContainer)
                            : css(unCheckContainer)
                        }
                        icon={titleCheckBoxStatus ? 'check' : 'null'}
                        iconProps={{
                          color: theme.colors.success,
                          size: 15,
                        }}
                      />
                    ),
                    width: 38,
                  },
                  {
                    title: translate('pricings.productName'),
                    width: isWeb ? 410 : 260,
                    alignItems: 'flex-start',
                  },
                  {
                    title: translate('pricings.defaultPrice'),
                    width: 100,
                    alignItems: 'flex-start',
                  },
                  {
                    title: translate('pricings.newPrice'),
                    width: 100,
                    alignItems: 'flex-start',
                  },
                  {
                    title: translate('pricings.changePrice'),
                    width: 100,
                    alignItems: 'flex-start',
                  },
                ]}
                data={
                  filterValue.length > 1 ? filterData : productPricingDataArray
                }
                normalRows
                columnSpacing={theme.spacing.big / 2}
                renderRow={(
                  item: ProductPricing,
                  index: number,
                ): React.ReactNode => (
                  <ProductPriceRow
                    defaultPricing={defaultPricing[item.product?.id]}
                    productPricing={item}
                    isDefault={isDefault}
                    onChange={onChange}
                    onDeleteRow={onDeleteRow}
                    key={item.id}
                    rowIndex={index}
                  />
                )}
              />
            )}
          </BackOfficeSection>
        </View>
        <View style={css(bottomSpace)}></View>
      </ScrollView>

      {!isDefault && (
        <View style={css(mainStyle)}>
          <View style={css(actionsContainerStyle)}>
            <Button
              fluid
              testID="save-changes"
              title={translate('button.saveChanges')}
              containerStyle={css(saveButtonStyle)}
              labelStyle={css(titleStyle)}
              onPress={onPressSave}
            />
          </View>
        </View>
      )}
    </>
  );
};
