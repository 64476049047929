import { StyleFn } from '@hitz-group/domain';
import scale, { isIos, isWeb } from '../../../common/theme';

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

export const formInputContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 260,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

export const formContainerStyle: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(400),
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  paddingBottom: theme.padding.medium * 3,
});

export const logoContainerStyle: StyleFn = ({ theme }) => ({
  paddingVertical: theme.padding.small * 3,
  alignSelf: 'center',
});

export const formStyle: StyleFn = ({ theme }) => ({
  width: 545,
  marginTop: theme.spacing.medium,
});

export const containerStyle: StyleFn = () => ({
  marginBottom: 0,
});

export const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: '100%',
  paddingHorizontal: theme.padding.large,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

export const formLabelStyle: StyleFn = () => ({
  marginLeft: 7,
});

export const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  alignItems: 'center',
  paddingTop: theme.padding.medium * 3,
});

export const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

export const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

export const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const titleTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  fontFamily: theme.font.regular,
  textTransform: 'none',
  alignSelf: 'center',
});

export const dropdownStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  justifyContent: 'space-between',
  width: 262,
  height: theme.input.height,
});
export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: 260,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});
export const currencyViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '90%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  marginLeft: scale.moderateScale(7),
});
export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '90%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  marginLeft: scale.moderateScale(15),
});

export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? scale.textInputWidth180 : '49%',
  marginTop: scale.moderateScale(2),
});

export const phonePrefixStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  minWidth: scale.moderateScale(25),
  textAlign: 'left',
  color: theme.colors.charcoal,
  paddingLeft: scale.moderateScale(5),
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
});

export const phonePrefixErrorBgStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger2,
});

export const width100row: StyleFn = () => ({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const phoneContainer: StyleFn = () => ({
  width: isWeb ? 262 : '50%',
  marginLeft: isIos ? 10 : 0,
});

export const innerContainer: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 5,
});
export const innerContainerSingleView: StyleFn = () => ({
  width: '100%',
  flexDirection: 'row',
  alignSelf: 'flex-start',
  marginTop: 5,
});

export const phoneTextInputStyle: StyleFn = () => ({
  minWidth: 130,
});
export const SearchMainContainer: StyleFn = () => ({
  width: '100%',
  justifyContent: 'center',
});
export const SearchtextInputStyle: StyleFn = () => ({
  paddingHorizontal: scale.moderateScale(1),
  paddingVertical: scale.moderateScale(5),
});

export const rowStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  zIndex: 10,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: (theme.spacing.small + theme.spacing.medium) / 2,
});
export const rowAndriodStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: (theme.spacing.small + theme.spacing.medium) / 2,
});

export const searchContainerStyle: StyleFn = ({ theme, error }) => ({
  borderColor: error ? theme.colors.danger2 : theme.colors.boxBorder,
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  borderWidth: 0.8,
  height: theme.input.height,
  width: isWeb ? '99%' : '100%',
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  marginTop: 7,
});

export const labelStyle: StyleFn = ({ theme, value }) => ({
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: theme.radius.small,
  minWidth: 56,
  paddingHorizontal: theme.padding.medium,
  paddingVertical: theme.padding.medium * (value ? 0.2 : 1),
});
export const avatarContainerStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: 100,
  backgroundColor: theme.colors.avatar2,
  borderRadius: theme.radius.small,
  alignSelf: 'center',
  marginTop: 10,
  overflow: 'hidden',
});
export const avtarStyle: StyleFn = () => ({
  width: '100%',
  height: '100%',
  resizeMode: 'cover',
});
export const avatarbodyStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: 100,
  backgroundColor: theme.colors.avatar2,
  borderRadius: theme.radius.small,
  alignSelf: 'center',
});

export const addImageTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.smallest,
  letterSpacing: 0,
  color: theme.colors.white,
  fontFamily: theme.font.bold,
});

export const addImageContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.avatar1,
  height: 25,
  width: 100,
  opacity: 0.2,
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  position: 'absolute',
  bottom: 0,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
});
export const avatarTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large * 2,
  letterSpacing: -0.5,
  color: theme.colors.avatar1,
  fontFamily: theme.font.bold,
});
export const avatarDescriptionStyle: StyleFn = ({ theme }) => ({
  width: isIos ? 545 : scale.backOfficeWidth400,
  height: theme.input.height,
  backgroundColor: theme.colors.lightBlueSelect,
  marginTop: theme.spacing.big / 2,
  justifyContent: 'center',
  alignSelf: 'center',
  borderRadius: theme.radius.small,
});

export const avatarDescriptionTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  lineHeight: 21,
  textAlign: 'center',
});
