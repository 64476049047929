import { StyleFn, App } from '@hitz-group/domain';
import { PermissionTreeNode } from '../../../../state/jobRoleSettingsUtility';
import React, { FC, memo } from 'react';
import { View } from 'react-native';
import PermissionDetail from './PermissionDetail';
import Icon from '../../../../components/Icon/Icon';
import { useFela } from 'react-fela';

const permissionDetailContainer: StyleFn = ({ level }) => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: level === 1 ? 10 : level === 2 ? 30 : level === 3 ? 60 : 0,
});

const childIcon: StyleFn = () => ({
  width: 40,
  height: 40,
  position: 'absolute',
  left: 30,
  top: 10,
});
interface PermissionTreeProps {
  permission: PermissionTreeNode;
  level?: number;
}

const PermissionTree: FC<PermissionTreeProps> = memo(
  ({ permission, level = 1 }) => {
    const { css, theme } = useFela();
    if (permission?.children && permission?.children?.length > 0) {
      // first need to render this node, then the leaf child nodes
      return (
        <View>
          {!(
            permission.isPermissionGroup &&
            permission.clientApp === App.BACKOFFICE
          ) && (
            <View style={css(permissionDetailContainer({ theme, level }))}>
              <PermissionDetail permission={permission} key={permission.id} />
            </View>
          )}
          {permission.children
            ?.sort((a, b) => a.sortOrder - b.sortOrder)
            .map((child: PermissionTreeNode) => {
              return (
                <PermissionTree
                  key={child.id}
                  permission={child}
                  level={level + 1}
                />
              );
            })}
        </View>
      );
    }

    if (permission) {
      return (
        <View style={css(permissionDetailContainer({ theme, level }))}>
          {level === 3 && (
            <View style={css(childIcon)}>
              <Icon name={'CornerDownRight'} color={theme.colors.darkGrey} />
            </View>
          )}
          <PermissionDetail permission={permission} key={permission.id} />
        </View>
      );
    }
    return null;
  },
);

PermissionTree.displayName = 'PermissionTree';

export default PermissionTree;
