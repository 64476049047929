import { VoidReason, Order } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import { RenderProps, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useMemo, useState } from 'react';
import { FelaComponent } from 'react-fela';
import { StyleSheet, View } from 'react-native';
import Button, { ButtonProps } from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import { CartHeader, CartHeaderProps } from '../../CartHeader/CartHeader';
import DropDown from '../../FormInput/DropDown';
import FormInput, { FormInputProps } from '../../FormInput/FormInput';
import PopupView from '../../PopupView/PopupView';
import TitleBar from '../../TitleBar/TitleBar';
import { useFela } from 'react-fela';
import scale, { isWeb } from '../../../common/theme';

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const CloseButton: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

const orderSummaryStyle: StyleFn = ({ theme }) => ({
  height: 44,
  paddingVertical: 0,
  marginBottom: theme.spacing.small,
  paddingHorizontal: theme.padding.small * 3,
});

const OrderSummary: React.FC<CartHeaderProps> = props => (
  <FelaComponent style={orderSummaryStyle}>
    {({ style }: RenderProps) => (
      <PopupView containerStyle={style}>
        <CartHeader {...props} />
      </PopupView>
    )}
  </FelaComponent>
);

const submitButtonStyle: StyleFn = () => ({
  marginBottom: isWeb ? 0 : 20,
  marginTop: 20,
});

const SubmitButton: React.FC<ButtonProps> = ({ onPress }) => {
  const { translate } = useTranslation();
  return (
    <FelaComponent style={submitButtonStyle}>
      {({ style }: RenderProps) => (
        <Button
          fluid
          danger
          onPress={onPress}
          containerStyle={style}
          testID="submit-button"
          title={translate('order.voidOrder')}
        />
      )}
    </FelaComponent>
  );
};

const textAreaStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 150,
  paddingTop: theme.padding.medium,
  paddingHorizontal: theme.padding.small,
});

const TextArea: React.FC<FormInputProps> = ({ value, onChangeText }) => {
  const { translate } = useTranslation();
  return (
    <FelaComponent style={textAreaStyle}>
      {({ style }: RenderProps) => (
        <FormInput
          fluid
          multiline
          value={value}
          containerStyle={style}
          onChangeText={onChangeText}
          textInputStyle={style}
          placeholder={translate('order.voidReasonPlaceholder')}
        />
      )}
    </FelaComponent>
  );
};

export interface CancelOrderModalProps {
  order: Order;
  onCancel?: (reason: VoidReason, description: string, order: Order) => void;
}

const styles = StyleSheet.create({
  container: {
    width: 400,
    alignSelf: 'center',
  },
  fullWidth: {
    justifyContent: 'space-between',
    width: '100%',
    height: 44,
    alignSelf: 'center',
  },
});
export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  height: scale.moderateScale(35),
  width: 350,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});
export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: 350,
  height: scale.moderateScale(30),
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

export const dropdownViewStyle: StyleFn = () => ({
  alignSelf: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: scale.moderateScale(2),
});

export const labelTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.smaller,
  paddingLeft: theme.padding.medium,
});

export const textInputContainer: StyleFn = () => ({
  marginTop: 10,
  width: '100%',
  height: 150,
});

const popupContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  paddingVertical: theme.padding.medium * 1.9,
});

export const CancelOrderModal: React.FC<CancelOrderModalProps> = ({
  order,
  onCancel,
}) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [reason, setReason] = useState(VoidReason.OTHER);
  const [description, setDescription] = useState('');
  const { css } = useFela();

  const reasonOptions = useMemo(
    () =>
      Object.values(VoidReason).map(value => ({
        value,
        label: translate(`enums.${value}`),
      })),
    [translate],
  );

  const onPressSubmit = useCallback(() => {
    onCancel && onCancel(reason, description, order);
    closeModal();
  }, [onCancel, reason, description, order, closeModal]);

  return (
    <View style={styles.container}>
      <TitleBar
        primary
        testID="title"
        title={translate('order.voidOrder')}
        titleLeft={<CloseButton onPress={closeModal} />}
      />
      <OrderSummary
        tableNumber={order?.table?.name || ''}
        orderNumber={order?.orderNumber || ''}
        orderType={order?.orderType?.name}
      />
      <PopupView containerStyle={css(popupContainerStyle)}>
        <DropDown
          fluid
          values={reasonOptions}
          selectedValue={reason}
          onValueChange={setReason}
          style={styles.fullWidth}
          extraMainViewStyle={css(dropDownMainViewStyle)}
          extraStyle={css(dropdownExtraStyle)}
          extraViewStyle={css(dropdownViewStyle)}
          title={translate('order.voidReasonLabel')}
          textStyle={css(labelTextStyle)}
        />
        {reason === VoidReason.OTHER && (
          <View style={css(textInputContainer)}>
            <TextArea value={description} onChangeText={setDescription} />
          </View>
        )}
        <SubmitButton onPress={onPressSubmit} />
      </PopupView>
    </View>
  );
};
