import { StyleFn, Catalogue as CatalogueDefault } from '@hitz-group/domain';
import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import TableRow from '../../../../components/TableComponent/TableRow';
import IconButton from '../../../../components/Button/IconButton';
import Button from '../../../../components/Button/Button';
import { useNavigation } from '@react-navigation/native';
import scale from '../../../../common/theme';

export interface Catalogue extends CatalogueDefault {
  isSelected?: boolean;
  productCount?: number;
}

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 300,
  height: theme.input.height,
  paddingLeft: scale.moderateScale(10),
  justifyContent: 'center',
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
});
const menuStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
  marginLeft: 'auto',
  alignSelf: 'center',
  marginRight: -theme.spacing.small,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const productCountStyle: StyleFn = ({ theme }) => ({
  width: 65,
  height: theme.input.height,
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing.small,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.greyLight,
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 38,
  height: 38,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing.big / 2,
});

const rightAngleStyle: StyleFn = () => ({
  height: scale.moderateScale(27),
});
const iconMarginStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small / 2,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  height: 17,
  width: 17,
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  height: 17,
  width: 17,
});
interface MenuRowProps {
  menu: Catalogue;
  onChange: (id: string, prop: string, value: string | boolean) => void;
  onPressCopy: (id: string) => void;
}

export const MenuRow: React.FC<MenuRowProps> = ({
  menu,
  onPressCopy,
  onChange,
}: MenuRowProps) => {
  const { css, theme } = useFela();
  const navigation = useNavigation();

  const productCount = useMemo(() => {
    return menu.productCount || 0;
  }, [menu]);

  return (
    <TableRow
      normalRows
      columnSpacing={theme.spacing.big}
      actionStyle={css(iconMarginStyle)}
      action={
        <IconButton
          icon="AngleRight"
          iconSize={24}
          containerSize={34}
          containerStyle={css(rightAngleStyle)}
          iconColor={theme.colors.paragraphLight}
          onPress={(): void =>
            // navigate to edit menu page
            navigation.navigate('MenuSettings', {
              menuId: menu.id,
              title: menu.name,
            })
          }
        />
      }
    >
      <Button
        onPress={() => onChange(menu.id, 'isSelected', !menu?.isSelected)}
        fluid
        iconPosition={'left'}
        containerStyle={css(checkBoxTitleContainer)}
        iconContainerStyle={
          menu?.isSelected ? css(checkIconContainer) : css(unCheckContainer)
        }
        icon={menu?.isSelected ? 'check' : 'null'}
        iconProps={{
          color: theme.colors.success,
          size: 15,
        }}
      />
      <View style={css(formInputContainerStyle)}>
        <Text style={css(menuStyle)}>{menu.name}</Text>
      </View>

      <View style={css(productCountStyle)}>
        <Text style={css(menuStyle)}>{productCount}</Text>
      </View>

      <IconButton
        icon="copy"
        iconSize={24}
        containerSize={34}
        containerStyle={css(closeIconContainerStyle)}
        iconStyle={css(closeIconStyle)}
        onPress={() => onPressCopy(menu.id)}
      />
    </TableRow>
  );
};
