/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View, ViewStyle, ScrollView } from 'react-native';
import { StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import TablePagination from '../TablePagination/TablePagination';
import { useIsMounted } from './../../useIsMounted';

export interface PaginatedProps {
  data: any[];
  renderItems: (items: any[]) => React.ReactFragment;
  rowHeight: number;
  action?: React.ReactNode;
  style?: ViewStyle;
  onPressNext?: (page: number, maxItemsPerPage: number) => void;
  hideTotalItemsCount?: boolean;
  marginTop?: number;
}

interface LayoutType {
  x: number;
  y: number;
  width: number;
  height: number;
}

const containerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  marginHorizontal: theme.spacing.big,
  marginBottom: theme.spacing.small,
  marginTop: theme.spacing.small * 1.5,
});
const paginationStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small,
  width: '100%',
});
const actionContainer: StyleFn = () => ({
  alignItems: 'center',
  position: 'absolute',
  bottom: 0,
  alignSelf: 'center',
});

const PaginatedView: React.FC<PaginatedProps> = ({
  action,
  data,
  renderItems,
  rowHeight,
  style,
  onPressNext,
  hideTotalItemsCount,
  marginTop,
}: PaginatedProps) => {
  const { css } = useFela();
  const [height, setHeight] = useState(500);
  const [page, setPage] = React.useState(1);
  const itemsPerPage = Math.floor(height / rowHeight); //65
  const itemsDataInPageInital = useMemo(() => {
    return data && data.slice((1 - 1) * itemsPerPage, 1 * itemsPerPage);
  }, [data, itemsPerPage]);
  const [itemsDataInPage, setPageData] = React.useState(itemsDataInPageInital);
  const isMounted = useIsMounted();

  useEffect(() => {
    // if we change data ex: by search then page should reset to 1st page
    setPage(1);
  }, [data]);

  const getDimensions = (layout: LayoutType) => {
    const { height } = layout;
    if (action) {
      isMounted() && setHeight(height - rowHeight * 3);
    } else {
      isMounted() && setHeight(height - rowHeight * 2 - (marginTop || 0));
    }
  };

  useEffect(() => {
    const itemsDataInPageIncremental = data?.slice(
      (page - 1) * itemsPerPage,
      page * itemsPerPage,
    );
    setPageData(itemsDataInPageIncremental);
  }, [page, data, itemsPerPage]);

  const onPageNumberPress = useCallback((page: number): void => {
    setPage(page);
  }, []);

  const handlePressNexPage = (nextPage: number) => {
    typeof onPressNext === 'function' && onPressNext(nextPage, itemsPerPage);
  };

  return (
    <View
      style={[css(containerStyle), style]}
      onLayout={event => {
        getDimensions(event.nativeEvent.layout);
      }}
    >
      <ScrollView>
        {renderItems(itemsDataInPage)}
        {data?.length > itemsPerPage && (
          <TablePagination
            testID={'pagination'}
            style={css(paginationStyle)}
            page={page}
            numberOfPages={Math.ceil(data?.length / itemsPerPage)}
            startIndex={(page - 1) * itemsPerPage}
            endIndex={page * itemsPerPage}
            total={data?.length}
            onPageChange={(page: number): void => {
              onPageNumberPress(page);
            }}
            onPressNext={handlePressNexPage}
            hideTotalItemsCount={hideTotalItemsCount}
          />
        )}
      </ScrollView>
      {action && <View style={css(actionContainer)}>{action}</View>}
    </View>
  );
};

export default PaginatedView;
