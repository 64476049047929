import { StyleFn } from '@hitz-group/domain';
import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { useFela } from 'react-fela';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import IconButton from '../../../../../components/Button/IconButton';
import { useModal } from '@hitz-group/rn-use-modal';
import { useTranslation } from '@hitz-group/localization';
import { Tax } from '@hitz-group/domain';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import find from 'lodash/find';
import { CreateGroupTaxModal } from '../../../../../components/Modals/Taxes/CreateGroupTaxModal';
import { isWeb } from '../../../../../common/theme';
interface TaxToTax {
  id: string;
  name: string;
  code: string;
  rate?: string;
  isActive?: boolean;
  taxes: Tax[];
}

interface TaxesProps {
  taxes: TaxToTax[];
  onChange: (index: number, prop: string, value: string | string[]) => void;
  openDeleteModal: (index: number, id: string, name: string) => void;
  onAddTax: (tax: TaxToTax) => void;
  isFeeModal?: boolean;
}

interface TaxesGroupRowProps {
  Tax: TaxToTax;
  onDeleteTax: (index: number, id: string, name: string) => void;
  onChange: (index: number, prop: string, value: string | string[]) => void;
  taxes: TaxToTax[];
  taxesWithoutGroupTax: { label: string; value: string }[];
  index: number;
  selectedTaxes: string[];
}

const inputContainerStyle: StyleFn = ({ theme }) => ({
  width: 240,
  height: theme.input.height,
  justifyContent: 'center',
  marginLeft: theme.spacing.small,
});

const inputContainerSmallStyle: StyleFn = ({ theme }) => ({
  width: 80,
  height: theme.input.height,
  paddingLeft: theme.padding.small,
});

const rateFormInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: theme.input.height,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const prefixItemStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  padding: theme.spacing.small / 2 + 6,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.bold,
  borderRadius: theme.radius.small,
  lineHeight: 15,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small,
  marginLeft: theme.spacing.small,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  marginTop: 7.5,
  marginLeft: theme.spacing.small,
  width: 240,
});
const touchableStyle: StyleFn = () => ({
  minHeight: 40,
  flexWrap: 'wrap',
});

const selectedStyle: StyleFn = () => ({
  marginVertical: 0,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const flexStyle: StyleFn = () => ({
  flex: 1,
});

const containerStyle: StyleFn = () => ({
  width: isWeb ? '70%' : '100%',
  alignSelf: 'center',
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.padding.medium / 2,
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

const TaxesGroupRow: React.FC<TaxesGroupRowProps> = ({
  Tax,
  onDeleteTax,
  taxes,
  taxesWithoutGroupTax,
  selectedTaxes,
  onChange,
  index,
}: TaxesGroupRowProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const calculatedTax = useMemo(() => {
    let value = 0;
    (selectedTaxes || []).map(taxId => {
      const tax = find(taxes, { id: taxId });
      value = value + parseFloat(tax?.rate || '0');
      return value;
    });
    return value;
  }, [selectedTaxes, taxes]);

  return (
    (Tax.taxes.length && (
      <View style={css(rowStyle)} testID={'taxes-table-row'}>
        <FormInput
          testID="tax-code"
          placeholder={translate('backOfficeSettings.code')}
          value={Tax.code}
          containerStyle={css(inputContainerSmallStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChange.bind(null, index, 'code')}
          maxLength={6}
        />
        <FormInput
          testID="tax-name"
          placeholder={translate('backOfficeSettings.taxName')}
          value={Tax.name}
          containerStyle={css(inputContainerStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChange.bind(null, index, 'name')}
          maxLength={50}
        />
        <MultipleSelect
          values={taxesWithoutGroupTax}
          selectedValues={selectedTaxes || []}
          containerStyle={css(dropDownStyle)}
          onValueChange={onChange.bind(null, index, 'taxes')}
          searchLabel={translate('backOfficeSettings.assignTaxes')}
          searchPlaceHolder={translate('backOfficeSettings.searchTaxes')}
          touchableStyle={css(touchableStyle)}
          selectedItemStyle={css(selectedStyle)}
        />
        <View style={css(flexStyle)}></View>
        <FormInput
          testID="rate"
          value={calculatedTax.toString()}
          placeholder={translate('backOfficeSettings.rate')}
          containerStyle={css(rateFormInputContainerStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChange.bind(null, index, 'rate')}
          keyboardType={'numeric'}
          readOnly
          prefix={{
            text: '%',
            textStyle: css(prefixItemStyle),
          }}
        />
        <IconButton
          icon="TrashAlt"
          iconSize={24}
          containerSize={34}
          containerStyle={css(closeIconContainerStyle)}
          iconStyle={css(closeIconStyle)}
          onPress={(): void => onDeleteTax(index, Tax.id, Tax.name)}
        />
      </View>
    )) ||
    null
  );
};

export const TaxesGroupSection: React.FC<TaxesProps> = ({
  taxes,
  onChange,
  openDeleteModal,
  onAddTax,
  isFeeModal,
}: TaxesProps) => {
  const { css, theme } = useFela();
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const taxesWithoutGroupTax = useMemo(() => {
    return taxes
      .filter(x => x && x.taxes.length === 0)
      .map(eachTax => {
        return { label: eachTax.name, value: eachTax.id };
      });
  }, [taxes]);

  const onCreateNew = useCallback((): void => {
    showModal(
      <CreateGroupTaxModal
        taxes={taxes}
        onAddTax={onAddTax}
        taxesWithoutGroupTax={taxesWithoutGroupTax}
      />,
      translate('backOfficeSettings.createGroupTax'),
    );
  }, [showModal, taxes, onAddTax, translate, taxesWithoutGroupTax]);

  return (
    <BackOfficeSection
      title={
        isFeeModal
          ? translate('backOfficeSettings.groupFees')
          : translate('backOfficeSettings.groupTaxes')
      }
      contentContainerStyle={css(formStyle)}
      containerStyle={css(containerStyle)}
      action={<BackOfficeCreateNewButton onPress={onCreateNew} />}
    >
      <TableComponent
        columnContainerStyle={css(columnContainerStyle)}
        columns={[
          {
            title: translate('backOfficeSettings.code'),
            width: 80,
            containerStyle: { paddingLeft: 15 },
          },
          {
            title: translate('backOfficeSettings.groupName'),
            width: 250,
            alignItems: 'flex-start',
            containerStyle: { paddingLeft: theme.spacing.medium },
          },
          {
            title: `${
              isFeeModal
                ? translate('backOfficeSettings.assignedFees')
                : translate('backOfficeSettings.assignedTaxes')
            }`,
            flex: 1,
            alignItems: 'flex-start',
            containerStyle: { paddingLeft: theme.spacing.medium },
          },
          {
            title: `${
              isFeeModal
                ? translate('backOfficeSettings.feeRate')
                : translate('backOfficeSettings.rate')
            }`,
            width: 100,
          },
          { title: '', width: 44 },
        ]}
        data={taxes}
        normalRows
        renderRow={(item: TaxToTax, index: number): React.ReactNode => {
          const selectedTaxes = item.taxes.map(taxGroup => taxGroup.id);
          return (
            <TaxesGroupRow
              key={index}
              Tax={item}
              onDeleteTax={openDeleteModal}
              taxes={taxes}
              taxesWithoutGroupTax={taxesWithoutGroupTax}
              selectedTaxes={selectedTaxes}
              onChange={onChange}
              index={index}
            />
          );
        }}
      />
    </BackOfficeSection>
  );
};
