import React, { useState, useEffect, useMemo } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';

interface ITimeAndAttendanceTimer {
  worklogStartTime?: number;
  breakTime?: number;
  breakTimeStart?: number;
}

const TimerBoxStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.regular,
  lineHeight: theme.spacing.medium,
  textAlign: 'center',
  backgroundColor: theme.colors.greyLight,
  paddingVertical: theme.spacing.small,
  paddingHorizontal: theme.spacing.big * 1.5,
  borderRadius: theme.spacing.small,
});

const totalWorkTimeStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.bold,
  fontSize: theme.fontSize.largest,
  paddingVertical: theme.padding.small,
});

const breakTimeStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.semibold,
  paddingVertical: theme.padding.small,
  color: theme.colors.paragraph,
});

const TimeAndAttendanceTimer: React.FC<ITimeAndAttendanceTimer> = ({
  worklogStartTime,
  breakTime,
  breakTimeStart,
}) => {
  const { css } = useFela();
  const [clockInTimer, setClockInTimer] = useState(Date.now());
  const [breakTimeTimer, setBreakTimeTimer] = useState(Date.now());
  const { translate } = useTranslation();

  const breakTimeInMS = breakTime ? breakTime * 60 * 1000 : undefined;

  useEffect(() => {
    let interval: number;
    if (worklogStartTime) {
      const timeDiff = Date.now() - worklogStartTime;
      setClockInTimer(timeDiff);
      interval = setInterval(() => {
        const timeDiff = Date.now() - worklogStartTime;
        setClockInTimer(timeDiff);
      }, 1000) as unknown as number;
    } else {
      setClockInTimer(0);
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [worklogStartTime, breakTimeStart]);

  useEffect(() => {
    let interval: number;
    if (breakTimeStart) {
      const timeDiff = (breakTimeInMS || 0) + (Date.now() - breakTimeStart);
      setBreakTimeTimer(timeDiff);
      interval = setInterval(() => {
        const timeDiff = (breakTimeInMS || 0) + (Date.now() - breakTimeStart);
        setBreakTimeTimer(timeDiff);
      }, 1000) as unknown as number;
    } else {
      setBreakTimeTimer(breakTimeInMS || 0);
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [breakTimeInMS, breakTimeStart]);

  const totalWorkTimeFormat = useMemo(
    () => new Date(clockInTimer).toISOString().slice(11, 16),
    [clockInTimer],
  );

  const totalBreakTimeFormat = useMemo(
    () => new Date(breakTimeTimer).toISOString().slice(11, 16),
    [breakTimeTimer],
  );

  return (
    <View style={css(TimerBoxStyle)}>
      <Text style={css(totalWorkTimeStyle)}>{totalWorkTimeFormat}</Text>
      <Text style={css(breakTimeStyle)}>
        {translate('interimLockScreen.clockScreen.totalBreakTime')}:
        {totalBreakTimeFormat}
      </Text>
    </View>
  );
};

const TimeAndAttendanceTimerScreen = React.memo(TimeAndAttendanceTimer);

export default TimeAndAttendanceTimerScreen;
