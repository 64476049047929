import { useReporting } from '../../../../hooks/app/useReporting';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import TaxesReport from './TaxesReport';
import { useNotification } from '../../../../hooks/Notification';
import { ReportingContext } from '../ReportingContext';
import {
  DateRangeFilter,
  FilterValue,
  Widget,
  WidgetChartType,
  ReportFilters,
  Filters,
  ReportFilterDropdown,
} from '@hitz-group/domain';
import { View } from 'react-native';
import { ContainerStyles } from '../styles/Component.styles';
import { buildFilterObject, setReportStates } from '../reportsHelper';
import {
  DateRangeFilterInput,
  Search,
  HelperText,
  DropDownFilter,
  ReportWidget,
} from '../types';
import * as storage from '../../../../storage/interface';
import { CubejsApi } from '@cubejs-client/core';
import { cloneJSON } from '@hitz-group/client-utils';

export const TaxesContainer: React.FC = () => {
  const { showNotification } = useNotification();
  const { cubejsApi, filters: allFilters } = useContext(ReportingContext);
  const [filters, setFilters] = useState<FilterValue>({});
  const [search, setSearch] = useState<Search>({
    type: 'taxName',
    value: '',
  });
  const [reportProps, setReportProps] = useState<ReportWidget>({
    updateCount: 0,
    widgets: [],
  });

  const [dateRangeFilters, setDateRangeFilters] =
    useState<DateRangeFilterInput>();
  const styles = ContainerStyles();

  const { reportMetadata, error, getReportMetadata } = useReporting(cubejsApi);

  const ddFilters = useMemo(() => {
    if (allFilters) {
      return reportMetadata?.filters?.map((filter: ReportFilterDropdown) => {
        return {
          ...filter,
          values: allFilters[filter.key as string as keyof Filters],
        } as DropDownFilter;
      });
    }
  }, [reportMetadata?.filters, allFilters]);

  const { widgets, updateCount } = reportProps;
  const setWidgets = (updatedWidgets: Widget[]) => {
    setReportProps(prev => ({ ...prev, widgets: updatedWidgets }));
  };

  useEffect(() => {
    allFilters && getReportMetadata && getReportMetadata(HelperText.TAXES);
  }, [allFilters, getReportMetadata]);

  useEffect(() => {
    if (reportMetadata?.widgets) {
      setReportStates(
        reportMetadata?.widgets,
        HelperText.TAXES,
        WidgetChartType.TABLE,
        setWidgets,
        setFilters,
        setDateRangeFilters,
      );
    }
  }, [reportMetadata?.widgets]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const updateFilters = useCallback((filter: string, value: string[]) => {
    setFilters(filters => {
      return {
        ...filters,
        [filter]: value,
      };
    });
  }, []);

  const updateSearch = useCallback((prop: string, value) => {
    setSearch(search => {
      return {
        ...search,
        [prop]: value,
      } as Search;
    });
  }, []);

  const updateDateRangeFilters = useCallback((value: DateRangeFilterInput) => {
    const toSet = buildFilterObject(value);
    setDateRangeFilters(toSet);
  }, []);

  const resetFilters = useCallback(() => {
    setFilters({});
  }, []);

  const updateReport = useCallback(() => {
    delete dateRangeFilters?.startDate;
    delete dateRangeFilters?.endDate;
    delete dateRangeFilters?.startTime;
    delete dateRangeFilters?.endTime;

    const searchValue =
      search?.type && search.value.trim() !== '' ? [search.value] : null;

    const reportFilters: ReportFilters = {
      filters: {
        ...filters,
        ...{ [search.type]: searchValue },
      },
      dateRangeFilters: [dateRangeFilters as DateRangeFilter],
    };

    storage.setItem(HelperText.TAXES, reportFilters);

    const updatedWidgets = reportProps.widgets.map(widget => {
      return cloneJSON({
        ...widget,
        query: {
          ...widget?.query,
          ...reportFilters,
        },
      }) as Widget;
    });

    setReportProps(prev => ({
      updateCount: prev.updateCount + 1,
      widgets: updatedWidgets,
    }));
  }, [
    dateRangeFilters,
    search.type,
    search.value,
    filters,
    reportProps.widgets,
  ]);

  const loading = widgets && widgets.length > 0 ? false : true;

  return (
    <View style={styles.pageStyle}>
      <TaxesReport
        filters={filters}
        filterOptions={ddFilters || ([] as DropDownFilter[])}
        allFilters={allFilters || ({} as Filters)}
        updateFilters={updateFilters}
        resetFilters={resetFilters}
        updateReport={updateReport}
        dateRangeFilter={dateRangeFilters || ({} as DateRangeFilterInput)}
        updateDateRangeFilters={updateDateRangeFilters}
        options={{ loading }}
        search={search}
        updateSearch={updateSearch}
        widgets={widgets}
        cubejsApi={cubejsApi as CubejsApi}
        updateCount={updateCount}
      />
    </View>
  );
};

export default TaxesContainer;
