import {
  IntegrationApps,
  OnlineOrderingPreferences,
  TimeBlock,
  UpdateIntegrationPartnerInput,
  DayOfWeek,
  TimeSlot,
  DEFAULT_ONLINE_STORE_OPEN_TIME,
  DEFAULT_ONLINE_STORE_CLOSE_TIME,
  ImageUploadInput,
  DEFAULT_PREP_TIME_MINS,
} from '@hitz-group/domain';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { View, ScrollView, Platform } from 'react-native';
import { useStores } from '../../../../../../../hooks/app/useStores';
import TableComponent from '../../../../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../../../../components/BackOfficeSection/BackOfficeSection';
import { useIntegrationPartners } from '../../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { Operation } from '../../../../../../../types/Operation';
import Button from '../../../../../../../components/Button/Button';
import { useNotification } from '../../../../../../../hooks/Notification';
import LoadingIndicator from '../../../../../../../components/LoadingIndicator/LoadingIndicator';
import { useRoute } from '@react-navigation/native';
import DropDown from '../../../../../../../components/FormInput/DropDown';
import { TimePeriodRow } from './TimePeriodRow';
import { keyBy, mergeWith, isNull } from 'lodash';
import { stripProperties } from '../../../../../../../utils/stripObjectProps';
import MultipleSelect from '../../../../../../../components/MultipleSelect/MultipleSelect';
import FormInput from '../../../../../../../components/FormInput/FormInput';
import * as styles from './Details.styles';
import { REACT_APP_ONLINE_STORE_PREVIEW_URL } from 'react-native-dotenv';
import ColorPicker from '../../../../../../../components/ColorPicker/ColorPicker';
import ImagePickerWeb from '../../../../../../../components/ImagePicker/ImagePicker.web';
import { convertAlphaNumbericToNumber } from '@hitz-group/client-utils';
import { useDevices } from '../../../../../../../hooks/app/useDevices';
import { stringToUrlPattern } from '@hitz-group/client-utils';

type dropDownType = {
  label: string;
  value: string;
};

export enum OrderAcceptance {
  Enable = 'Enable',
  Disable = 'Disable',
}

const emptyOption = {
  value: '',
  label: 'Please Select',
};

const imageStyle = {
  opacity: 1,
  zIndex: 1,
  position: 'relative',
} as React.CSSProperties;

export const oolioPreviewPrefix =
  process.env['REACT_APP_ONLINE_STORE_PREVIEW_URL'] ||
  REACT_APP_ONLINE_STORE_PREVIEW_URL ||
  'https://tillx-online-store.vercel.app';

type IntegrationPreferences = OnlineOrderingPreferences & {
  operatingHoursMap: { [key: string]: TimeBlock };
} & { locationId?: string; accountId?: string };

export const Details: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [imageRawData, setImageRawData] = useState<ImageUploadInput>();
  const [integrationPreferences, setIntegrationPreferences] =
    useState<IntegrationPreferences>({
      menu: '',
      pricingGroup: '',
      operatingHoursMap: {},
      storeSlug: '',
      accentColour: '',
      favImage: '',
      autoAcceptOrders: false,
      defaultPrepTime: DEFAULT_PREP_TIME_MINS,
      printDevice: '',
    });

  const route = useRoute();
  const params = route.params as {
    storeId: string;
    app: IntegrationApps;
  };
  const [isStoreSlugVerified, setStoreSlugverified] = useState(false);
  const storeId = params.storeId;

  const isDeliverectApp = params.app === IntegrationApps.DELIVERECT;

  const { css } = useFela({ isRowDisplay: isDeliverectApp });

  const {
    stores,
    loading: storesLoading,
    error: storesError,
    slugs,
    getSlugsByStore,
  } = useStores({ storeId });

  const { devices, getDevices } = useDevices({ storeId });
  const deviceSelectOptions = useMemo(() => {
    return Object.values(devices).map(device => ({
      label: device.name,
      value: device.id,
    }));
  }, [devices]);

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  const {
    loading: integrationLoading,
    getIntegrationPartnerSettings,
    integrationPartners: allIntegrationPartners,
    updateIntegrationPartnerSettings,
    uploadOnlineStoreFavImageFile,
    createIntegrationPartner,
    operation,
    error: integrationErr,
    checkStoreSlugExists,
  } = useIntegrationPartners();

  const venueId = useMemo(() => {
    return storeId && stores[storeId] ? stores[storeId].venue.id : '';
  }, [storeId, stores]);

  const defaultStoreSlug = useMemo(() => {
    if (slugs?.store) {
      return stringToUrlPattern(slugs.store);
    }
  }, [slugs?.store]);

  useEffect(() => {
    if (!defaultStoreSlug && storeId) {
      getSlugsByStore(storeId);
    }
  }, [defaultStoreSlug, storeId, getSlugsByStore]);

  const integrationPartners = useMemo(() => {
    return keyBy(Object.values(allIntegrationPartners), 'store');
  }, [allIntegrationPartners]);

  useEffect(() => {
    if (storeId) {
      getIntegrationPartnerSettings({
        appName: params.app,
        store: storeId,
      });
    }
  }, [getIntegrationPartnerSettings, params.app, storeId]);

  useEffect(() => {
    if (storeId && integrationPartners[storeId]?.preferences) {
      const defaultTimeSlot: TimeSlot = {
        endTime: DEFAULT_ONLINE_STORE_CLOSE_TIME,
        startTime: DEFAULT_ONLINE_STORE_OPEN_TIME,
      };

      const preferences =
        integrationPartners[storeId]?.preferences?.onlineOrdering;

      const operatingHoursArray = preferences?.operatingHours?.map(opHours => ({
        ...opHours,
        timeSlots: [
          {
            ...defaultTimeSlot,
            ...opHours.timeSlots?.[0],
          },
        ],
      }));

      const operatingHoursObj = keyBy(operatingHoursArray, 'day');
      setIntegrationPreferences({
        pricingGroup: preferences?.pricingGroup,
        menu: preferences?.menu,
        operatingHoursMap: operatingHoursObj,
        devices: preferences?.devices || [],
        locationId: integrationPartners[storeId].id,
        accountId: integrationPartners[storeId].accountId,
        accentColour: preferences?.accentColour || '',
        favImage: preferences?.favImage || '',
        autoAcceptOrders: preferences?.autoAcceptOrders || false,
        defaultPrepTime: preferences?.defaultPrepTime || DEFAULT_PREP_TIME_MINS,
        printDevice: preferences?.printDevice || '',
        storeSlug: stringToUrlPattern(
          preferences?.storeSlug || defaultStoreSlug || '',
        ),
      });
    }
  }, [integrationPartners, storeId, defaultStoreSlug]);

  const loading = storesLoading || integrationLoading;

  const error = storesError || integrationErr;

  const isUpdated =
    !integrationErr && !integrationLoading && operation === Operation.UPDATE;

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeFeatures.settingsUpdatedSuccessfully', {
          appName: params.app,
        }),
      });
    }
  }, [isUpdated, params.app, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const menuOptions = useMemo(() => {
    let result: dropDownType[] = [];
    if (storeId && stores[storeId]?.catalogues?.length) {
      result = stores[storeId]?.catalogues?.map(x => ({
        label: x.name,
        value: x.id,
      }));
    }

    return [emptyOption, ...result];
  }, [stores, storeId]);

  const pricingGroupOptions = useMemo(() => {
    let result: dropDownType[] = [];
    if (stores[storeId]?.pricingGroups?.length) {
      result = stores[storeId]?.pricingGroups?.map(x => ({
        label: x.name,
        value: x.id,
      }));
    }
    return [emptyOption, ...result];
  }, [stores, storeId]);

  const deviceOptions = useMemo(() => {
    let result: dropDownType[] = [];
    if (stores[storeId]?.devices?.length) {
      result = stores[storeId]?.devices?.map(x => ({
        label: x.name,
        value: x.id,
      }));
    }
    return result;
  }, [storeId, stores]);

  const onChangeOperatingHours = useCallback((day, key, value) => {
    setIntegrationPreferences(prev => {
      const defaultTimeBlock: TimeBlock = {
        day,
        timeSlots: [
          {
            endTime: DEFAULT_ONLINE_STORE_CLOSE_TIME,
            startTime: DEFAULT_ONLINE_STORE_OPEN_TIME,
          },
        ],
        isActive: false,
      };

      const result = {
        ...prev,
        operatingHoursMap: {
          ...prev.operatingHoursMap,
          [day]: {
            ...defaultTimeBlock,
            ...prev.operatingHoursMap[day],
            timeSlots: [
              {
                ...defaultTimeBlock.timeSlots[0],
                ...prev.operatingHoursMap[day]?.timeSlots?.[0],
              },
            ],
          },
        } as { [key: string]: TimeBlock },
      };

      if (key === 'startTime' || key === 'endTime') {
        result.operatingHoursMap[day].timeSlots = [
          {
            ...result.operatingHoursMap[day].timeSlots?.[0],
            [key]: value,
          },
        ];
      } else {
        result.operatingHoursMap[day] = {
          ...prev.operatingHoursMap[day],
          [key]: value,
          day,
        };
      }

      return result;
    });
  }, []);

  const saveIntegrationSettings = useCallback(() => {
    if (params.app === IntegrationApps.OOLIO_STORE && !isStoreSlugVerified) {
      showNotification({
        error: true,
        message: translate('backOfficeFeatures.storeSlugError'),
      });
      return;
    }
    const operatingHours = Object.values(
      stripProperties(integrationPreferences.operatingHoursMap, '__typename'),
    ) as TimeBlock[];

    const onlineOrdering =
      integrationPartners[storeId] &&
      mergeWith(
        {},
        integrationPartners[storeId].preferences?.onlineOrdering,
        {
          storeSlug: integrationPreferences?.storeSlug || defaultStoreSlug,
          menu: integrationPreferences?.menu,
          pricingGroup: integrationPreferences?.pricingGroup,
          operatingHours,
          devices: integrationPreferences?.devices,
          accentColour: integrationPreferences?.accentColour,
          autoAcceptOrders: integrationPreferences?.autoAcceptOrders,
          defaultPrepTime: integrationPreferences?.defaultPrepTime,
          printDevice: integrationPreferences?.printDevice,
        },
        (sourceValue, destinationValue) =>
          isNull(destinationValue) ? sourceValue : destinationValue,
      );

    const updateSetting: UpdateIntegrationPartnerInput[] = [
      {
        id: integrationPartners[storeId]?.id,
        isActive: true,
        appName: params.app,
        modules: {
          onlineOrdering: true,
        },
        store: storeId,
        preferences: {
          onlineOrdering,
        },
        link: '',
        venue: venueId,
      },
    ];
    imageRawData &&
      updateSetting[0].id &&
      uploadOnlineStoreFavImageFile(imageRawData, updateSetting[0].id);

    if (
      !integrationPreferences?.menu ||
      !integrationPreferences?.pricingGroup
    ) {
      showNotification({
        error: true,
        message: translate('backOfficeFeatures.pleaseSelectMenuAndPriceGroup'),
      });
    } else {
      if (
        params.app === IntegrationApps.OOLIO_STORE &&
        !integrationPartners[storeId]
      ) {
        createIntegrationPartner(
          stripProperties({ ...updateSetting[0] }, '__typename'),
        );
      } else {
        updateIntegrationPartnerSettings(
          stripProperties(updateSetting, '__typename'),
        );
      }
    }
  }, [
    createIntegrationPartner,
    defaultStoreSlug,
    imageRawData,
    integrationPartners,
    integrationPreferences?.accentColour,
    integrationPreferences?.autoAcceptOrders,
    integrationPreferences?.defaultPrepTime,
    integrationPreferences?.devices,
    integrationPreferences?.menu,
    integrationPreferences.operatingHoursMap,
    integrationPreferences?.pricingGroup,
    integrationPreferences?.printDevice,
    integrationPreferences?.storeSlug,
    isStoreSlugVerified,
    params.app,
    showNotification,
    storeId,
    translate,
    updateIntegrationPartnerSettings,
    uploadOnlineStoreFavImageFile,
    venueId,
  ]);

  const onChangeMainDetails = useCallback((key, value) => {
    setIntegrationPreferences(prev => ({ ...prev, [key]: value }));
  }, []);

  const checkSlug = useCallback(async () => {
    if (
      integrationPreferences?.storeSlug &&
      integrationPreferences?.storeSlug?.length > 3 &&
      params.app === IntegrationApps.OOLIO_STORE
    ) {
      const res = await checkStoreSlugExists(
        IntegrationApps.OOLIO_STORE,
        integrationPreferences?.storeSlug,
        params.storeId,
      );
      setStoreSlugverified(res);
    }
  }, [
    checkStoreSlugExists,
    integrationPreferences?.storeSlug,
    params.app,
    params.storeId,
  ]);

  useEffect(() => {
    checkSlug();
  }, [checkSlug]);

  const previewLink = `${oolioPreviewPrefix}/${stringToUrlPattern(
    integrationPreferences.storeSlug || defaultStoreSlug || '',
  )}`;

  const orderAcceptanceOptions = useMemo(
    () => [
      {
        value: OrderAcceptance.Enable,
        label: translate('backOfficeFeatures.enabled'),
      },
      {
        value: OrderAcceptance.Disable,
        label: translate('backOfficeFeatures.disabled'),
      },
    ],
    [translate],
  );

  const renderOperatingHours = useMemo(() => {
    if (isDeliverectApp) return null;
    return (
      <BackOfficeSection
        title={translate('backOfficeFeatures.operatingHours')}
        contentContainerStyle={css(styles.operatingHoursContentStyle)}
      >
        <TableComponent
          columns={[
            {
              title: translate('backOfficeFeatures.day'),
              width: 380,
              alignItems: 'flex-start',
            },
            {
              title: translate('backOfficeFeatures.opens'),
              width: 80,
              alignItems: 'flex-start',
            },
            {
              title: translate('backOfficeFeatures.closes'),
              width: 80,
              alignItems: 'flex-start',
            },
          ]}
          data={Object.values(DayOfWeek).filter(x => x && x != DayOfWeek.ALL)}
          normalRows
          columnContainerStyle={css(styles.columnContainerStyle)}
          renderRow={(dayName: string, index: number): React.ReactNode => (
            <TimePeriodRow
              isActive={
                integrationPreferences.operatingHoursMap[dayName]?.isActive ||
                false
              }
              startTime={
                integrationPreferences.operatingHoursMap[dayName]
                  ?.timeSlots?.[0]?.startTime || DEFAULT_ONLINE_STORE_OPEN_TIME
              }
              endTime={
                integrationPreferences.operatingHoursMap[dayName]
                  ?.timeSlots?.[0]?.endTime || DEFAULT_ONLINE_STORE_CLOSE_TIME
              }
              onChange={onChangeOperatingHours}
              name={dayName}
              key={`${dayName}${index}`}
            />
          )}
        />
      </BackOfficeSection>
    );
  }, [
    css,
    integrationPreferences?.operatingHoursMap,
    isDeliverectApp,
    onChangeOperatingHours,
    translate,
  ]);

  const renderBranding = useMemo(() => {
    if (isDeliverectApp) return null;

    return (
      <BackOfficeSection
        title={translate('backOfficeFeatures.branding')}
        contentContainerStyle={css(styles.brandingContentStyle)}
      >
        <ColorPicker
          title={translate('backOfficeFeatures.accentColour')}
          value={integrationPreferences?.accentColour?.toString()}
          onColourChange={colourString =>
            onChangeMainDetails('accentColour', colourString)
          }
          containerStyle={styles.colorPickerContainerStyle}
        ></ColorPicker>
        {Platform.OS === 'web' ? (
          <ImagePickerWeb
            onComplete={imageRawData => {
              setImageRawData(imageRawData);
            }}
            imageUrl={imageRawData?.base64 || integrationPreferences?.favImage}
            isFormInput={true}
            title={translate('backOfficeFeatures.faviconImage')}
            formInputStyle={styles.imagePickerContainerStyle}
            imageStyle={imageStyle}
          />
        ) : // TODO: Should add support for native later
        null}
      </BackOfficeSection>
    );
  }, [
    css,
    imageRawData?.base64,
    integrationPreferences?.accentColour,
    integrationPreferences?.favImage,
    isDeliverectApp,
    onChangeMainDetails,
    translate,
  ]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.integrationsPage', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView
        testID="general-settings-screen"
        contentContainerStyle={css(styles.generalSettingsContainerWrapper)}
      >
        <View style={css(styles.pageStyle)}>
          {params.app === IntegrationApps.OOLIO_STORE ? (
            <BackOfficeSection
              title={translate('backOfficeFeatures.storeDetails')}
              contentContainerStyle={css(styles.formStyle)}
            >
              <FormInput
                title={`${translate('backOfficeFeatures.storeUrlSlug')}`}
                alignTitle={'left'}
                value={integrationPreferences.storeSlug}
                verified={isStoreSlugVerified}
                error={!isStoreSlugVerified}
                placeholder={defaultStoreSlug}
                onChangeText={slug =>
                  onChangeMainDetails('storeSlug', stringToUrlPattern(slug))
                }
                prefix={{
                  text: oolioPreviewPrefix.replace(/^https:\/\//, '') + '/',
                  textStyle: css(styles.prefixTextStyle),
                  containerStyle: css(styles.prefixContainerStyle),
                }}
                containerStyle={css(styles.formInputContainerStyle)}
                textStyle={css(styles.textStyle)}
                showCopy
                textToCopy={previewLink}
              />
            </BackOfficeSection>
          ) : null}

          <BackOfficeSection
            title={translate('backOfficeFeatures.preferences')}
            contentContainerStyle={css(styles.formStyle)}
          >
            {isDeliverectApp && (
              <View style={css(styles.locationWrapperStyle)}>
                <FormInput
                  value={integrationPreferences.locationId}
                  testID="location-id"
                  title={translate('backOfficeFeatures.locationID')}
                  containerStyle={css(styles.locationIdStyle)}
                  verified
                />

                <FormInput
                  value={integrationPreferences.accountId}
                  title={translate('backOfficeFeatures.accountID')}
                  testID="account-id"
                  containerStyle={css(styles.accountIdStyle)}
                  verified
                />
              </View>
            )}

            <View style={css(styles.menuAndPricingWrapperStyle)}>
              <DropDown
                values={menuOptions}
                testID="menu-dropdown"
                title={translate('backOfficeFeatures.selectMenus')}
                selectedValue={integrationPreferences.menu}
                style={css(styles.formDropDownContainerStyle)}
                extraStyle={css(styles.dropdownExtraStyle)}
                extraViewStyle={css(styles.extraViewStyle)}
                extraMainViewStyle={css(styles.dropDownMainViewStyle)}
                textStyle={css(styles.dropDownTitleStyle)}
                onValueChange={value => onChangeMainDetails('menu', value)}
              />

              <DropDown
                values={pricingGroupOptions}
                title={translate('backOfficeFeatures.selectPricingGroup')}
                selectedValue={integrationPreferences.pricingGroup}
                testID="menu-dropdown"
                style={css(styles.formDropDownContainerStyle)}
                extraStyle={css(styles.dropdownExtraStyle)}
                extraViewStyle={css(styles.extraViewStyle)}
                extraMainViewStyle={css(styles.dropDownMainViewStyle)}
                textStyle={css(styles.dropDownTitleStyle)}
                onValueChange={value =>
                  onChangeMainDetails('pricingGroup', value)
                }
              />
            </View>

            {params.app !== IntegrationApps.OOLIO_STORE && !isDeliverectApp && (
              <MultipleSelect
                label={translate('backOfficeFeatures.selectDevices')}
                selectedValues={integrationPreferences.devices}
                containerStyle={css(styles.dropDownStyle)}
                values={deviceOptions}
                onValueChange={value => onChangeMainDetails('devices', value)}
                touchableStyle={css(styles.touchableStyle)}
                testID="devices"
              />
            )}
          </BackOfficeSection>
          {params.app !== IntegrationApps.OOLIO_STORE && (
            <BackOfficeSection
              title={translate('backOfficeFeatures.autoAcceptOrders')}
              contentContainerStyle={css(styles.autoAcceptOrdersContentStyle)}
            >
              <DropDown
                testID="order-acceptance-dropdown"
                values={orderAcceptanceOptions}
                title={translate('backOfficeFeatures.autoAcceptOrders')}
                selectedValue={
                  integrationPreferences?.autoAcceptOrders
                    ? OrderAcceptance.Enable
                    : OrderAcceptance.Disable
                }
                style={css(styles.orderAcceptanceDropDown)}
                extraStyle={css(styles.orderAcceptanceDropDownExtraStyle)}
                extraMainViewStyle={css(
                  styles.orderAcceptanceDropDownMainViewStyle,
                )}
                onValueChange={value =>
                  onChangeMainDetails(
                    'autoAcceptOrders',
                    value === OrderAcceptance.Enable ? true : false,
                  )
                }
              />

              <FormInput
                error={false}
                testID="default-prep-time"
                placeholder={translate('backOfficeFeatures.defaultPrepTime')}
                title={translate('backOfficeFeatures.defaultPrepTime')}
                value={integrationPreferences?.defaultPrepTime?.toString()}
                alignTitle="left"
                containerStyle={css(styles.defaultPrepTimeInputContainerStyle)}
                textStyle={css(styles.titleTextStyle)}
                onChangeText={value =>
                  onChangeMainDetails(
                    'defaultPrepTime',
                    convertAlphaNumbericToNumber(value),
                  )
                }
              />
              <DropDown
                testID="device-select-button"
                values={deviceSelectOptions}
                title={translate('backOfficeFeatures.printRoutingSelection')}
                style={css(styles.orderAcceptanceDropDown)}
                extraStyle={css(styles.orderAcceptanceDropDownExtraStyle)}
                extraViewStyle={css(styles.extraViewStyle)}
                extraMainViewStyle={css(
                  styles.orderAcceptanceDropDownMainViewStyle,
                )}
                onValueChange={value =>
                  onChangeMainDetails('printDevice', value)
                }
                selectedValue={integrationPreferences.printDevice}
              />
            </BackOfficeSection>
          )}
          {renderOperatingHours}
          {renderBranding}
        </View>
      </ScrollView>

      <View style={css(styles.actionsContainerStyle)}>
        <Button
          fluid
          testID="save-changes"
          title={translate('button.saveChanges')}
          containerStyle={css(styles.saveButtonStyle)}
          labelStyle={css(styles.titleStyle)}
          onPress={saveIntegrationSettings}
        />
      </View>
    </>
  );
};
