import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useEffect, useState, useCallback } from 'react';
import {
  GET_MENUS_QUERY,
  GET_MENU_QUERY,
  CREATE_MENU,
  UPDATE_MENU,
  DELETE_MENU,
  COPY_MENU,
  GET_MENU_OPTIONS,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { Operation } from '../../../types/Operation';
import { ApolloError } from '@apollo/client';
import { keyBy } from 'lodash';
import {
  Catalogue,
  CreateCatalogueInput,
  UpdateCatalogueInput,
} from '@hitz-group/domain';

export interface UseMenusProps {
  menus: Record<string, Catalogue>;
  error: string | undefined;
  loading: boolean;
  operation: Operation;
  getMenus: () => void;
  createMenu: (createMenusInput: CreateCatalogueInput) => void;
  copyMenu: (copyId: string) => void;
  updateMenu: (updateMenuInputs: UpdateCatalogueInput) => void;
  deleteMenus: (ids: string[]) => void;
  getMenu: (id: string) => void;
  getMenusOptions: () => void;
  createdMenu: string;
}

export function useMenus(): UseMenusProps {
  const [menus, setMenus] = useState<Record<string, Catalogue>>({});
  const [operation, setOperation] = useState<Operation>(Operation.READ);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [createdMenu, setCreatedMenu] = useState<string>('');

  const onCompleteGetMenusRequest = useCallback(
    data => {
      if (data) {
        setMenus(keyBy(data.catalogues as Catalogue[], 'id'));
      }
    },
    [setMenus],
  );

  const [getMenusRequest, menusGetResponse] = useLazyQuery(GET_MENUS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: onCompleteGetMenusRequest,
  });

  const getMenus = useCallback(() => {
    getMenusRequest();
    setOperation(Operation.READ);
  }, [getMenusRequest]);

  const onCompleteGetMenuRequest = useCallback(data => {
    if (data) {
      const menuData = data.catalogue as Catalogue;
      setMenus(prev => ({ ...prev, [menuData.id]: menuData }));
    }
  }, []);

  const [getMenuReq, menuGetRes] = useLazyQuery(GET_MENU_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: onCompleteGetMenuRequest,
  });

  const getMenu = useCallback(
    (menuId: string) => {
      getMenuReq({
        variables: {
          id: menuId,
        },
      });
      setOperation(Operation.READ);
    },
    [getMenuReq],
  );

  // get menu id and name only for drop down
  const onCompleteGetMenusOptionsRequest = useCallback(
    data => {
      if (data) {
        setMenus(keyBy(data.catalogues as Catalogue[], 'id'));
      }
    },
    [setMenus],
  );

  const [getMenusOptionsRequest, menusGetOptionsResponse] = useLazyQuery(
    GET_MENU_OPTIONS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetMenusOptionsRequest,
    },
  );

  const getMenusOptions = useCallback(() => {
    getMenusOptionsRequest();
    setOperation(Operation.READ);
  }, [getMenusOptionsRequest]);

  const [createMenuRequest, createMenuResponse] = useMutation(CREATE_MENU, {
    onError: noopHandler,
  });

  const createMenu = useCallback(
    (createMenuInput: CreateCatalogueInput) => {
      createMenuRequest({
        variables: {
          input: createMenuInput,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createMenuRequest],
  );

  useEffect(() => {
    if (createMenuResponse.data) {
      const newMenu = createMenuResponse.data.createCatalogue as Catalogue;
      setMenus(prev => ({ ...prev, [newMenu.id]: newMenu }));
      setCreatedMenu(newMenu?.id);
    }
  }, [createMenuResponse.data]);

  const [copyMenuRequest, copyMenuResponse] = useMutation(COPY_MENU, {
    onError: noopHandler,
  });

  const copyMenu = useCallback(
    (copyId: string) => {
      copyMenuRequest({
        variables: {
          input: copyId,
        },
      });
      setOperation(Operation.CREATE);
    },
    [copyMenuRequest],
  );

  useEffect(() => {
    if (copyMenuResponse.data) {
      const newMenu = copyMenuResponse.data.cloneCatalogue as Catalogue;
      setMenus(prev => ({ ...prev, [newMenu.id]: newMenu }));
    }
  }, [copyMenuResponse.data]);

  const [updateMenuRequest, updateMenusResponse] = useMutation(UPDATE_MENU, {
    onError: noopHandler,
  });

  const updateMenu = useCallback(
    (updateMenuInput: UpdateCatalogueInput) => {
      updateMenuRequest({
        variables: {
          input: updateMenuInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateMenuRequest],
  );

  useEffect(() => {
    if (updateMenusResponse.data) {
      const updatedMenu = updateMenusResponse.data.updateCatalogue as Catalogue;
      setMenus(prev => ({ ...prev, [updatedMenu.id]: updatedMenu }));
    }
  }, [updateMenusResponse.data]);

  const [deleteMenusRequest, deleteMenusResponse] = useMutation(DELETE_MENU, {
    onError: noopHandler,
  });

  const deleteMenus = useCallback(
    (ids: string[]) => {
      deleteMenusRequest({
        variables: {
          input: ids,
        },
      });
      setDeletedIds(ids);
      setOperation(Operation.DELETE);
    },
    [deleteMenusRequest],
  );

  useEffect(() => {
    if (deleteMenusResponse.data) {
      const isDeleted = deleteMenusResponse.data
        .deleteCatalogues as Catalogue[];
      if (isDeleted && deletedIds.length) {
        setMenus(prev => {
          const tempPrevState = { ...prev };
          deletedIds.forEach(eachId => {
            delete tempPrevState[eachId];
          });
          return tempPrevState;
        });
      }
    }
  }, [deleteMenusResponse.data, deletedIds]);

  const error: ApolloError | undefined =
    menusGetResponse.error ||
    menuGetRes.error ||
    createMenuResponse.error ||
    updateMenusResponse.error ||
    deleteMenusResponse.error ||
    copyMenuResponse.error ||
    menusGetOptionsResponse.error;
  const loading: boolean =
    menusGetResponse.loading ||
    menuGetRes.loading ||
    createMenuResponse.loading ||
    updateMenusResponse.loading ||
    deleteMenusResponse.loading ||
    copyMenuResponse.loading ||
    menusGetOptionsResponse.loading;

  return useMemo(
    () => ({
      menus,
      getMenus,
      createMenu,
      copyMenu,
      updateMenu,
      deleteMenus,
      getMenu,
      getMenusOptions,
      operation,
      error: error ? parseApolloError(error) : undefined,
      loading,
      createdMenu,
    }),
    [
      menus,
      error,
      loading,
      getMenus,
      createMenu,
      copyMenu,
      updateMenu,
      deleteMenus,
      getMenu,
      getMenusOptions,
      operation,
      createdMenu,
    ],
  );
}
