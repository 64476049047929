import { StyleFn, OrderType } from '@hitz-group/domain';
import React, { useEffect, useCallback } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import FormInput from '../../../../components/FormInput/FormInput';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import IconButton from '../../../../components/Button/IconButton';
import { CreateSalesChannelModal } from '../../../../components/Modals/SalesChannels/CreateSalesChannelModal';
import { DELETE_SALES_CHANNEL } from '../../../../graphql/settings';
import { useNotification } from '../../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import { parseApolloError, noopHandler } from '../../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@hitz-group/localization';
import MultipleSelect from '../../../../components/MultipleSelect/MultipleSelect';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import { isWeb } from '../../../../common/theme';
interface SalesChannel {
  id: string;
  name: string;
  isActive: boolean;
  integrationStatus: string;
  orderTypes?: OrderType[];
}

interface SalesChannelRowProps {
  SalesChannel: SalesChannel;
  onDeleteSalesChannel: (id: string, name: string) => void;
  onChange: (
    id: string,
    prop: string,
    value: string | boolean | string[],
  ) => void;
  index?: number;
  orderTypeData: OrderType[];
}

const inputContainerStyle: StyleFn = ({ theme }) => ({
  width: 240,
  height: theme.input.height,
  marginLeft: theme.spacing.medium,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  marginLeft: theme.spacing.small,
});

const touchableStyle: StyleFn = ({ theme }) => ({
  minHeight: theme.input.height,
  flexWrap: 'wrap',
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.medium,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const toggleContainerStyle: StyleFn = ({ theme }) => ({
  width: 38,
  marginLeft: theme.spacing.small,
  alignItems: 'flex-start',
});

export const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

export const containerStyle: StyleFn = () => ({
  width: isWeb ? '60%' : '100%',
  alignSelf: 'center',
});
const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const selectContentStyle: StyleFn = () => ({
  height: 100,
});

const selectedStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.padding.small,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

interface DeleteModalProps {
  id: string;
  name: string;
  onDeleteSalesChannel: (id: string) => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  onDeleteSalesChannel,
  id,
  name,
}: DeleteModalProps) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [deleteSalesChannel, deleteRequest] = useMutation(
    DELETE_SALES_CHANNEL,
    {
      onError: noopHandler,
    },
  );

  useEffect((): void => {
    if (deleteRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(deleteRequest.error),
      });
    }
  }, [deleteRequest.error, showNotification]);

  useEffect((): void => {
    if (deleteRequest.data) {
      onDeleteSalesChannel(id);
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.salesChannelDeleted'),
      });
    }
  }, [
    deleteRequest.data,
    showNotification,
    closeModal,
    name,
    onDeleteSalesChannel,
    id,
    translate,
  ]);

  const onDeletePage = useCallback((): void => {
    deleteSalesChannel({ variables: { id: id } });
  }, [deleteSalesChannel, id]);

  return (
    <ConfirmationDialog
      title={translate('dialog.deleteTitle')}
      message={translate('dialog.deleteConfirmation', { label: name })}
      onConfirm={onDeletePage}
    />
  );
};

const SalesChannelRow: React.FC<SalesChannelRowProps> = ({
  SalesChannel,
  onDeleteSalesChannel,
  onChange,
  orderTypeData,
}: SalesChannelRowProps) => {
  const { theme, css } = useFela();
  const { translate } = useTranslation();

  return (
    <View style={css(rowStyle)}>
      <View style={css(toggleContainerStyle)}>
        <IconButton
          primary
          icon={(SalesChannel.isActive && 'toggle-on') || 'toggle-off'}
          iconSize={26}
          containerSize={38}
          iconColor={
            SalesChannel.isActive
              ? theme.colors.success
              : theme.colors.paragraph
          }
          onPress={onChange.bind(
            null,
            SalesChannel.id,
            'isActive',
            !SalesChannel.isActive,
          )}
        />
      </View>

      <FormInput
        testID="channel-name"
        placeholder={translate('backOfficeSettings.channelName')}
        value={SalesChannel.name}
        containerStyle={css(inputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(null, SalesChannel.id, 'name')}
      />

      <MultipleSelect
        values={orderTypeData.map(order => ({
          label: order.name,
          value: order.id,
        }))}
        selectedValues={(SalesChannel.orderTypes || [])?.map(x => x.id)}
        containerStyle={css(dropDownStyle)}
        touchableStyle={css(touchableStyle)}
        onValueChange={onChange.bind(null, SalesChannel.id, 'orderTypes')}
        searchLabel={translate('backOfficeSettings.OrderTypes')}
        searchPlaceHolder={translate('backOfficeSettings.searchOrders')}
        contentStyle={css(selectContentStyle)}
        selectedItemStyle={css(selectedStyle)}
      />
      <IconButton
        primary
        icon="TrashAlt"
        iconSize={24}
        containerSize={34}
        iconColor={theme.colors.red}
        containerStyle={css(closeIconContainerStyle)}
        onPress={(): void =>
          onDeleteSalesChannel(SalesChannel.id, SalesChannel.name)
        }
      />
    </View>
  );
};

interface SalesChannelsProps {
  salesChannelsData: SalesChannel[];
  onChange: (
    id: string,
    prop: string,
    value: string | boolean | string[],
  ) => void;
  orderTypesData: OrderType[];
  onDeleteSalesChannel: (id: string) => void;
  onAddSalesChannel: (salesChannel: SalesChannel) => void;
}

export const SalesChannelsSection: React.FC<SalesChannelsProps> = ({
  salesChannelsData,
  onChange,
  orderTypesData,
  onDeleteSalesChannel,
  onAddSalesChannel,
}: SalesChannelsProps) => {
  const { css, theme } = useFela();
  const { showModal } = useModal();
  const { translate } = useTranslation();

  const onCreateNew = useCallback((): void => {
    showModal(
      <CreateSalesChannelModal
        orderTypeData={orderTypesData}
        onAddSalesChannel={onAddSalesChannel}
      />,
    );
  }, [showModal, orderTypesData, onAddSalesChannel]);

  const onPressDelete = useCallback(
    (id: string, name: string): void => {
      showModal(
        <DeleteModal
          id={id}
          name={name}
          onDeleteSalesChannel={onDeleteSalesChannel}
        />,
      );
    },
    [showModal, onDeleteSalesChannel],
  );

  return (
    <BackOfficeSection
      title={translate('backOfficeSettings.salesChannels')}
      titleDescription={translate('backOfficeSettings.salesChannelDescription')}
      contentContainerStyle={css(formStyle)}
      containerStyle={css(containerStyle)}
      action={<BackOfficeCreateNewButton onPress={onCreateNew} />}
    >
      <TableComponent
        columns={[
          {
            title: translate('backOfficeSettings.use'),
            width: 80,
            containerStyle: { paddingLeft: 15 },
          },
          {
            title: translate('backOfficeSettings.channelName'),
            width: 250,
            alignItems: 'flex-start',
            containerStyle: { paddingLeft: theme.spacing.medium },
          },
          {
            title: translate('backOfficeSettings.assignedOrderTypes'),
            flex: 1,
            alignItems: 'flex-start',
            containerStyle: { paddingLeft: theme.spacing.medium },
          },
        ]}
        normalRows
        columnContainerStyle={css(columnContainerStyle)}
        data={salesChannelsData}
        renderRow={(item: SalesChannel, index: number): React.ReactNode => (
          <SalesChannelRow
            key={index}
            SalesChannel={item}
            onDeleteSalesChannel={onPressDelete}
            onChange={onChange}
            index={index}
            orderTypeData={orderTypesData}
          />
        )}
      />
    </BackOfficeSection>
  );
};
