import { StyleFn, Page } from '@hitz-group/domain';
import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../components/FormInput/FormInput';
import IconButton from '../../../../components/Button/IconButton';

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 310,
  height: theme.input.height,
  paddingLeft: theme.padding.small,
  justifyContent: 'center',
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const productCountStyle: StyleFn = ({ theme }) => ({
  width: 60,
  height: theme.input.height,
  borderRadius: theme.radius.small,
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
});

const colourContainer: StyleFn = ({ theme, pageColor }) => ({
  height: 20,
  width: 20,
  backgroundColor: pageColor || theme.colors.green,
  borderRadius: theme.radius.large,
  marginHorizontal: theme.spacing.big,
});

const rightAngleStyle: StyleFn = () => ({
  height: 38,
});
const pageRowText: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});
const flexStyle: StyleFn = () => ({
  flex: 1,
});

interface DeviceRowProps {
  page: Page;
  onChange: (id: string, value: string) => void;
  onEditPage: (id: string) => void;
}

export const PagesRow: React.FC<DeviceRowProps> = ({
  page,
  onChange,
  onEditPage,
}: DeviceRowProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const productCount = useMemo(() => {
    return (
      (page.products?.length || 0) +
      ((page.variants &&
        page.variants?.reduce((acc, val) => {
          return acc + (val?.products?.length || 0);
        }, 0)) ||
        0)
    );
  }, [page]);

  return (
    <View style={css(rowStyle)}>
      <FormInput
        testID="page-name"
        placeholder={translate('backOfficeProducts.pageName')}
        value={page.name}
        containerStyle={css(formInputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(null, page?.id)}
        maxLength={50}
      />

      <View style={css(productCountStyle)}>
        <Text style={css(pageRowText)}>{productCount}</Text>
      </View>
      <View style={css(flexStyle)} />
      <View
        style={css(colourContainer({ theme, pageColor: page.color }))}
      ></View>
      <IconButton
        icon="AngleRight"
        iconSize={24}
        containerSize={34}
        containerStyle={css(rightAngleStyle)}
        iconColor={theme.colors.paragraph}
        onPress={() => onEditPage(page.id)}
      />
    </View>
  );
};
