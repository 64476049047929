import { Order, StyleFn, Table, TableStatus, Theme } from '@hitz-group/domain';
import { translate } from '@hitz-group/localization';
import React from 'react';
import { useFela } from 'react-fela';
import { Text, View } from 'react-native';

const dotColorByStatus = (theme: Theme) => ({
  [TableStatus.OCCUPIED]: theme.colors.blue,
  [TableStatus.DONE_SOON]: theme.colors.success,
  [TableStatus.SEATED]: theme.colors.blue,
  [TableStatus.AVAILABLE]: theme.colors.boxBorder,
  [TableStatus.YOUR_TABLE]: theme.colors.white,
  [TableStatus.IN_USE]: theme.colors.white,
  [TableStatus.INACTIVE]: theme.colors.white,
  [TableStatus.MULTI_USE]: theme.colors.deepPurpleDark,
});
const dotStyle: StyleFn = ({ theme, status }) => ({
  backgroundColor: dotColorByStatus(theme)[status as TableStatus],
  width: 12,
  height: 12,
  borderRadius: 6,
  marginLeft: 12,
  marginRight: 4,
  marginTop: 1,
});

const legendTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14SemiBold,
  color: theme.colors.text,
  textTransform: 'uppercase',
});
const legendContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 10,
});

interface FloorViewActionsProps {
  tables: Table[];
  inProgressOrders: Order[];
  tableToOrdersMap?: Record<string, Order[]>;
}

const FloorViewStats: React.FC<FloorViewActionsProps> = ({
  tables,
  inProgressOrders,
  tableToOrdersMap,
}) => {
  const { css, theme } = useFela();
  const numberOfOccupiedTables = inProgressOrders.filter(
    order => order.table.status === TableStatus.OCCUPIED,
  ).length;
  const numberOfDoneSoonTables = inProgressOrders.filter(
    order => order.table.status === TableStatus.DONE_SOON,
  ).length;
  const numberOfAvailableTables = tables.filter(
    table => table.status === TableStatus.AVAILABLE || !table.status,
  ).length;
  const mutipleOrderTables = tables.filter(
    table =>
      table.id && tableToOrdersMap && tableToOrdersMap[table.id]?.length > 1,
  ).length;

  return (
    <View style={css(legendContainerStyle)}>
      <View style={css(dotStyle({ theme, status: TableStatus.OCCUPIED }))} />
      <Text style={css(legendTextStyle)} testID="occupied-tables-amount">
        {translate('tableFloorView.occupiedTablesAmount', {
          number: numberOfOccupiedTables,
        })}
      </Text>
      <View style={css(dotStyle({ theme, status: TableStatus.DONE_SOON }))} />
      <Text style={css(legendTextStyle)} testID="done-soon-tables-amount">
        {translate('tableFloorView.doneSoonTablesAmount', {
          number: numberOfDoneSoonTables,
        })}
      </Text>
      <View style={css(dotStyle({ theme, status: TableStatus.MULTI_USE }))} />
      <Text style={css(legendTextStyle)} testID="multiple-tables-amount">
        {translate('tableFloorView.multipleTablesAmount', {
          number: mutipleOrderTables,
        })}
      </Text>
      <View style={css(dotStyle({ theme, status: TableStatus.AVAILABLE }))} />
      <Text style={css(legendTextStyle)} testID="available-tables-amount">
        {translate('tableFloorView.availableTablesAmount', {
          number: numberOfAvailableTables,
        })}
      </Text>
    </View>
  );
};

export default FloorViewStats;
