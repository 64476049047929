import { useQuery } from '@apollo/client/react/hooks';
import { useMemo } from 'react';
import { GET_TAXES_DETAILS } from '../../graphql/taxes';
import { parseApolloError } from '../../utils/errorHandlers';
import { Tax } from '@hitz-group/domain';
import keyBy from 'lodash/keyBy';

export interface useTaxesProps {
  taxes: { [key: string]: Tax };
  taxesOptions: { label: string; value: string }[];
  loading: boolean;
  error: string | undefined;
}

export function useTaxes(): useTaxesProps {
  const { data, loading, error } = useQuery(GET_TAXES_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });

  const taxesOptionsData = useMemo(() => {
    return ((data?.taxes || []) as Tax[]).map(x => ({
      value: x.id,
      label: x.name,
    }));
  }, [data]);

  const taxesDictionary = useMemo(() => {
    return keyBy(data?.taxes || [], 'id');
  }, [data]);

  return useMemo(
    () => ({
      taxes: taxesDictionary,
      taxesOptions: taxesOptionsData,
      loading,
      error: error ? parseApolloError(error) : undefined,
    }),
    [taxesOptionsData, loading, error, taxesDictionary],
  );
}
