import { Organization, StyleFn, User, Venue } from '@hitz-group/domain';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import { useSession } from '../../hooks/app/useSession';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import Button from '../Button/Button';
import IconButton from '../Button/IconButton';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ViewStyle,
  ScrollView,
} from 'react-native';
import PopupView from '../PopupView/PopupView';
import { Session } from '../../state/Session';
import { backOfficeNavigation } from '../../state/navigation';
import NavBar from '../BackOfficeNavBar/BackOfficeNavBar';
import ProfileSwitch from '../BackOfficeProfileSwitch/BackOfficeProfileSwitch';
import CollapsiblePanel from '../CollapsiblePanel/CollapsiblePanel';
import { useLogout } from '../../hooks/app/useLogout';
import { useCheckFeatureEnabled } from '../../hooks/app/features/useCheckFeatureEnabled';

import SearchBar from '../SearchBar/SearchBar';
import { useVenues } from '../../hooks/app/useVenues';
import { isWeb } from '../../common/theme';
export interface BackOfficeSidebarProps {
  containerStyle?: ViewStyle;
}

const panelStyle: StyleFn = () => ({
  zIndex: 10,
});

const navStyle: StyleFn = () => ({
  flexDirection: 'row',
});

const popupStyle: StyleFn = ({ theme }) => ({
  zIndex: 10,
  width: 300,
  height: '100%',
  borderRadius: theme.radius.small,
  position: 'absolute',
  left: 74,
  top: 0,
  paddingVertical: 0,
  paddingHorizontal: 0,
  shadowOpacity: 0,
  shadowRadius: 0,
  borderRightWidth: 1,
  borderRightColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
});

const headerStyle: StyleFn = ({ theme }) => ({
  height: 85,
  width: '100%',
  padding: theme.spacing.medium,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.charcoal,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.medium,
  letterSpacing: -0.4,
  fontWeight: '500',
  marginLeft: 15,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  height: 34,
  width: 34,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.greyLight,
});

const switchContainer: StyleFn = ({ theme }) => ({
  flex: 1,
  padding: theme.padding.large,
});

const innerSwitchContainer: StyleFn = () => ({
  flex: 1,
});

const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
  marginBottom: theme.spacing.small,
  paddingLeft: theme.spacing.small / 2,
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  borderWidth: 1,
  borderColor: theme.colors.boxBorder,
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  height: theme.input.height,
  ...theme.font14RegularDarkGrey,
});

const venueRowStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: 30,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: theme.radius.small,
  marginVertical: theme.spacing.small,
  marginTop: theme.spacing.medium,
  backgroundColor: theme.colors.greyLight,
});

const venueTitle: StyleFn = ({ theme }) => ({
  color: theme.colors.charcoal,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.4,
  marginLeft: 15,
});

const storeRowStyle: StyleFn = ({ theme }) => ({
  width: 260,
  paddingVertical: theme.spacing.small,
  paddingBottom: 15,
  backgroundColor: theme.colors.white,
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.boxBorder,
});

const storeTitle: StyleFn = ({ theme }) => ({
  color: theme.colors.charcoal,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.4,
  marginLeft: 15,
});

const logoutButtonStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  width: 260,
  height: theme.button.footerButtonHeight,
  marginTop: theme.padding.small,
  backgroundColor: theme.colors.warningBg,
  alignSelf: 'center',
});

const logoutTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
});

const profileSwitchStyle: StyleFn = ({ theme }) => ({
  height: 74,
  zIndex: 10,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: theme.spacing.medium * 2,
  paddingLeft: theme.spacing.big / 3.5,
});

const styles = StyleSheet.create({
  collapsedItem: {
    minWidth: 74,
    paddingLeft: 0,
    height: 44,
  },
  collapsedItemIcon: {
    minWidth: 30,
  },
});

const collapsedItemIcon: StyleFn = ({ theme }) => ({
  minWidth: 30,
  color: theme.colors.white,
});

export const getMenuItems = () => {
  const itemsToRemove = ['Dashboard', 'Reports'];
  const menuItemsWeb = backOfficeNavigation;
  const menuItemsForNative = backOfficeNavigation.filter(
    item => !itemsToRemove.includes(item.title),
  );
  if (isWeb) {
    return menuItemsWeb;
  } else {
    return menuItemsForNative;
  }
};

const BackOfficeSidebar: React.FC<BackOfficeSidebarProps> = ({
  containerStyle,
}: BackOfficeSidebarProps) => {
  const { css, theme } = useFela();
  const [session] = useSession();
  const [collapsed] = useState(false);
  const [closeSwitch, setCloseSwitch] = useState(false);
  const { logout } = useLogout();
  const { translate } = useTranslation();
  const navigation = useNavigation();
  const route = useNavigationState(
    state => state.routes[state.routes.length - 1],
  );
  const [venuesData, setVenuesData] = useState([] as Venue[]);
  const [searchString, setSearchString] = useState('');
  const { getVenues, searchVenues } = useVenues();
  const isFeatureEnabled = useCheckFeatureEnabled();

  const menuItems = useMemo(() => {
    const items = getMenuItems();
    return items.filter(item => {
      if (item.feature) {
        return isFeatureEnabled(item.feature.name, item.feature.context);
      }
      return true;
    });
  }, [isFeatureEnabled]);
  // Calculate current route name to show navigation active state
  // Output example: Account::Overview
  const currentRoute = useMemo(() => {
    if (route.state) {
      const stack = route.state.routes
        ? route.state.routes[route.state.routes.length - 1]
        : null;
      const stackName = stack ? stack.name : '';

      const childRoute =
        stack && stack.state
          ? stack.state.routes[stack.state.routes.length - 1]
          : null;
      const childRouteName = childRoute ? childRoute.name : '';

      return childRouteName ? `${stackName}::${childRouteName}` : stackName;
    }
    return route.name;
  }, [route.state, route.name]);

  const onSwitch = useCallback(() => {
    setCloseSwitch(closeSwitch => !closeSwitch);
  }, []);

  const onNavigateToRoute = useCallback(
    (routeName: string) => {
      const [stack, screen] = routeName.split('::');
      navigation.navigate(stack, { screen });
      if (closeSwitch) onSwitch();
    },
    [navigation, onSwitch, closeSwitch],
  );

  const navBar = useMemo(
    () => (
      <NavBar
        active={currentRoute}
        menu={menuItems}
        onNavigate={onNavigateToRoute}
        itemIconStyle={
          collapsed ? css(collapsedItemIcon) : css(collapsedItemIcon)
        }
        itemContainerStyle={collapsed ? styles.collapsedItem : undefined}
      />
    ),
    [currentRoute, collapsed, css, onNavigateToRoute, menuItems],
  );

  const {
    currentOrganization,
    availableOrganizations,
    user,
  }: Partial<Session> = session || {};

  useEffect(() => {
    getVenues();
  }, [getVenues]);

  useEffect(() => {
    async function updateData() {
      const searchResult = await searchVenues(searchString);
      setVenuesData(searchResult);
    }
    updateData();
  }, [searchVenues, searchString]);

  const activeOrganization = useMemo<Partial<Organization> | undefined>(
    () =>
      (availableOrganizations || []).find(
        x => x.id === currentOrganization?.id,
      ),
    [availableOrganizations, currentOrganization?.id],
  );

  const onPressTrigger = useCallback(() => {
    onSwitch && onSwitch();
  }, [onSwitch]);

  const onPressVenue = useCallback(
    (venueId: string) => {
      navigation.navigate('Settings', {
        screen: 'VenueSettings',
        params: {
          venueId,
        },
      });
      onPressTrigger();
    },
    [navigation, onPressTrigger],
  );

  const onLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  const popupComponent = useMemo(() => {
    if (!closeSwitch) {
      return null;
    }

    return (
      <PopupView containerStyle={css(popupStyle)}>
        <View style={css(headerStyle)}>
          <Text style={css(titleStyle)}>{activeOrganization?.name || ''}</Text>
          <IconButton
            testID="close-icon"
            icon="times"
            onPress={onPressTrigger}
            primary
            containerStyle={css(iconContainerStyle)}
            containerSize={25}
            iconSize={25}
          ></IconButton>
        </View>
        <View style={css(switchContainer)}>
          <View style={css(innerSwitchContainer)}>
            <SearchBar
              testID="search-bar"
              placeholder={translate('backOfficeVenues.searchVenue')}
              containerStyle={css(searchContainerStyle)}
              textInputStyle={css(searchTextInputStyle)}
              iconColor={theme.colors.paragraph}
              placeholderColor={theme.colors.paragraph}
              onChange={setSearchString}
              value={searchString}
            />
            <ScrollView>
              {venuesData.map(venue => {
                return (
                  <View key={venue.id}>
                    <TouchableOpacity
                      style={css(venueRowStyle)}
                      onPress={(): void => {
                        onPressVenue(venue.id);
                      }}
                    >
                      <Text style={css(venueTitle)}>{venue.name}</Text>
                      <IconButton
                        icon="AngleRight"
                        iconSize={24}
                        containerSize={34}
                        iconColor={theme.colors.paragraph}
                      />
                    </TouchableOpacity>

                    {venue.stores.map(store => {
                      return (
                        <View key={store.id} style={css(storeRowStyle)}>
                          <Text style={css(storeTitle)}>{store.name}</Text>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </ScrollView>
          </View>
          <Button
            testID="logout-btn"
            size="small"
            fluid
            onPress={onLogout}
            color={theme.colors.primary}
            containerStyle={css(logoutButtonStyle)}
            title={translate('button.logout')}
            labelStyle={css(logoutTextStyle)}
          />
        </View>
      </PopupView>
    );
  }, [
    activeOrganization,
    closeSwitch,
    css,
    onPressTrigger,
    onLogout,
    onPressVenue,
    searchString,
    theme,
    translate,
    venuesData,
  ]);

  return (
    <View style={css(navStyle)}>
      <CollapsiblePanel
        vertical
        contentStyle={css(panelStyle)}
        containerStyle={
          containerStyle ? [containerStyle, css(panelStyle)] : css(panelStyle)
        }
        isSwitcherOpen={closeSwitch}
        onSwitch={onSwitch}
      >
        <>
          <ProfileSwitch
            user={user as User}
            active={currentOrganization?.id || ''}
            containerStyle={css(profileSwitchStyle)}
            organizations={availableOrganizations || []}
            onSwitch={onSwitch}
          />
          {navBar}
        </>
      </CollapsiblePanel>
      {popupComponent}
    </View>
  );
};

export default BackOfficeSidebar;
