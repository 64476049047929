import {
  Course,
  Order,
  OrderItem,
  OrderType,
  Features,
  FeatureContext,
  OrderTypeCode,
  OrderPaymentStatus,
} from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import React, { useMemo } from 'react';
import { isNull } from 'lodash';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { CartHeader } from '../CartHeader/CartHeader';
import CartItems from './CartItems';
import { CartActions } from '../CartActions/CartActions';
import IconButton from '../Button/IconButton';
import CartSummary from '../CartSummary/CartSummary';
import CartKeyPad from '../CartKeyPad/CartKeyPad';
import Button from '../Button/Button';
import {
  cartStyle,
  cartActionsStyle,
  orderActionsStyle,
  cartActionButton,
  styles,
} from './Cart.styles';
import ChangeSummary from '../ChangeSummary/ChangeSummary';
import { useChangeDueReactiveVar } from '../../hooks/app/useChangeDueReactiveVar';
import { useSession } from '../../hooks/app/useSession';
import { useCheckFeatureEnabled } from '../../hooks/app/features/useCheckFeatureEnabled';
import { IN_COMPLETE_PAYMENT_STATUS_LIST } from '../../types/Common';

export type CartSelectionState = {
  item: string;
  quantity?: number;
  modifier?: string;
  variant?: string;
  product?: string;
  modifierGroup?: string;
  selectedVariantKey?: string;
  selectedModifierKey?: string;
};

export interface CartProps {
  order: Order;
  orderType: OrderType;
  onPressChangeOrderType: () => void;
  onPressTableNumber: () => void;
  onIncrementSeatNumber: () => void;
  setSelectedSeatNumber: (seatNumber?: number) => void;
  orderItems: OrderItem[];
  courses?: Course[];
  onToggleAutoFire?: (courseId: string) => void;
  onSelectCartItem: (state: CartSelectionState) => void;
  selectedCartItem: CartSelectionState | undefined;
  isAdvancedKeypad: boolean;
  disableCartActions: boolean;
  isOrderComplete: boolean;
  onPressDangerAction: (quantityToBeKept?: number | undefined) => void;
  onPressUpdatePrice: () => void;
  onDeselectCartItem: () => void;
  onPressUpdateQuantity: () => void;
  splitProductFromCart: () => void;
  onPressAction: (action: string) => void;
  onPressSplitProductFromCart: () => void;
  advancedKeypadValue: number;
  setAdvancedKeypadValue: (value: number) => void;
  onPressPay: () => void;
  disableOrderActions: boolean;
  isDirty: boolean;
  onPressNewOrder: () => void;
  onPressSave: () => void;
  disableSaveAction: boolean;
  enableQuickPaymentMode: boolean;
  navigateToRefundScreen?: () => void;
  onSwitchCourseItem?: (orderItemId: string, courseId: string) => void;
  onPressCompleteOrder?: () => void;
}

const Cart: React.FC<CartProps> = ({
  order,
  orderType,
  onPressChangeOrderType,
  onPressTableNumber,
  orderItems,
  onSelectCartItem,
  selectedCartItem,
  isAdvancedKeypad,
  disableCartActions,
  isOrderComplete,
  onPressDangerAction,
  onPressUpdatePrice,
  onPressUpdateQuantity,
  splitProductFromCart,
  onPressAction,
  onPressSplitProductFromCart,
  advancedKeypadValue,
  setAdvancedKeypadValue,
  onPressPay,
  disableOrderActions,
  onPressSave,
  disableSaveAction,
  onDeselectCartItem,
  enableQuickPaymentMode,
  navigateToRefundScreen,
  isDirty,
  onPressNewOrder,
  courses,
  onToggleAutoFire,
  onIncrementSeatNumber,
  setSelectedSeatNumber,
  onSwitchCourseItem,
  onPressCompleteOrder,
}: CartProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const changeDue = useChangeDueReactiveVar();
  const [session] = useSession();
  const isFeatureEnabled = useCheckFeatureEnabled();

  const isCourseFeatureEnabledAtVenue = isFeatureEnabled(
    Features.COURSES,
    FeatureContext.VENUE,
    session.currentVenue?.id,
  );

  const isCoursesEnabled =
    isCourseFeatureEnabledAtVenue &&
    session?.deviceProfile?.enableCourses &&
    orderType?.code === OrderTypeCode.DINE_IN;

  const paymentSummary = useMemo(() => {
    const paymentsMap = (order?.payments || [])
      .filter(
        payment =>
          !IN_COMPLETE_PAYMENT_STATUS_LIST.includes(
            payment.status as OrderPaymentStatus,
          ),
      )
      .reduce((acc, payment) => {
        acc[payment.paymentType.name] =
          (acc[payment.paymentType.name] || 0) + payment.amount;
        return acc;
      }, {} as Record<string, number>);

    return Object.keys(paymentsMap).map(paymentName => ({
      name: translate('payment.paidByPaymentName', { paymentName }),
      amount: paymentsMap[paymentName],
    }));
  }, [order?.payments, translate]);

  return (
    <View style={css(cartStyle)} testID="cart">
      <CartHeader
        orderNumber={order?.orderNumber || ''}
        orderType={(orderType?.name || '').toUpperCase()}
        onSelectOrderType={onPressChangeOrderType}
        tableNumber={order?.table?.name}
        sectionName={order?.table?.section?.name}
        onSelectTableNumber={onPressTableNumber}
      />

      <CartItems
        orderGuestCount={order?.table?.guestCount}
        items={orderItems}
        courses={courses}
        onToggleAutoFire={onToggleAutoFire}
        onSelectItem={onSelectCartItem}
        onDeselectItem={onDeselectCartItem}
        onIncrementSeatNumber={onIncrementSeatNumber}
        setSelectedSeatNumber={setSelectedSeatNumber}
        orderNote={order?.orderNote}
        selectedItem={selectedCartItem}
        scrollToBottom
        onSwitchCourseItem={onSwitchCourseItem}
        isCoursesEnabled={isCoursesEnabled}
        orderType={orderType}
      />
      {enableQuickPaymentMode && !isNull(changeDue) && (
        <ChangeSummary change={changeDue} />
      )}
      {!isAdvancedKeypad && (
        <CartActions containerStyle={css(cartActionsStyle)}>
          <IconButton
            fluid
            testID="trash-icon"
            danger
            icon="TrashAlt"
            disabled={disableCartActions || isOrderComplete}
            containerStyle={css(cartActionButton)}
            onPress={() => onPressDangerAction()}
          />
          <IconButton
            fluid
            secondary
            icon="usd-circle"
            disabled={
              disableCartActions ||
              selectedCartItem?.modifier !== undefined ||
              isOrderComplete
            }
            onPress={onPressUpdatePrice}
            containerStyle={css(cartActionButton)}
          />
          <IconButton
            fluid
            success
            icon="exposure-increase"
            disabled={!selectedCartItem || isOrderComplete}
            containerStyle={css(cartActionButton)}
            onPress={onPressUpdateQuantity}
          />
          <IconButton
            testID={'split-icon-button'}
            fluid
            secondary
            icon="align-center-h"
            disabled={
              !selectedCartItem ||
              isOrderComplete ||
              selectedCartItem.quantity === 1
            }
            containerStyle={css(cartActionButton)}
            onPress={splitProductFromCart}
          />
        </CartActions>
      )}
      {!isAdvancedKeypad && (
        <CartSummary
          taxes={order?.taxes}
          adjustments={order?.adjustments || []}
          subTotal={order?.subTotal || 0}
          totalDue={order?.totalPrice || 0}
          discountAmount={order?.discountAmount || 0}
          surchargeAmount={order?.surchargeAmount || 0}
          paymentSummary={paymentSummary}
          amountDue={order?.amountDue}
        />
      )}
      {isAdvancedKeypad && (
        <CartKeyPad
          onPressAction={action => onPressAction(action)}
          onPressSplitProductFromCart={onPressSplitProductFromCart}
          cancelActionDisabled={disableCartActions || isOrderComplete}
          updatePriceActionDisabled={
            disableCartActions ||
            selectedCartItem?.modifier !== undefined ||
            isOrderComplete
          }
          addActionDisable={!selectedCartItem || isOrderComplete}
          subtractActionDisabled={!selectedCartItem || isOrderComplete}
          splitProductDisabled={!selectedCartItem || isOrderComplete}
          keypadInput={
            advancedKeypadValue ? advancedKeypadValue.toString() : ''
          }
          setKeypadInput={(value: number): void =>
            setAdvancedKeypadValue(value)
          }
          discounts={order?.discounts || []}
          adjustments={order?.adjustments || []}
          subTotal={order?.subTotal || 0}
          totalDue={order?.totalPrice || 0}
          discountAmount={order?.discountAmount || 0}
          surchargeAmount={order?.surchargeAmount || 0}
          paymentSummary={paymentSummary}
          amountDue={order?.amountDue}
        />
      )}
      <View style={css(orderActionsStyle)}>
        {isOrderComplete && (
          <Button
            success
            size="large"
            title={'Refund Order'}
            testID="refund-button"
            containerStyle={styles.refundButton}
            onPress={navigateToRefundScreen}
          />
        )}
        {!isOrderComplete && (
          <>
            {(order?.amountDue === 0 && (
              <Button
                success
                size="large"
                title={translate('button.complete')}
                onPress={() => onPressCompleteOrder && onPressCompleteOrder()}
                testID="complete-now-button"
                containerStyle={styles.orderActionButton}
                disabled={disableOrderActions}
                labelStyle={{
                  color: disableOrderActions
                    ? theme.colors.paragraph
                    : theme.colors.white,
                }}
              />
            )) || (
              <Button
                success
                size="large"
                title={translate('button.payNow')}
                onPress={onPressPay}
                testID="pay-now-button"
                containerStyle={styles.orderActionButton}
                disabled={disableOrderActions}
                labelStyle={{
                  color: disableOrderActions
                    ? theme.colors.paragraph
                    : theme.colors.white,
                }}
              />
            )}
            <Button
              secondary
              size="large"
              testID="save-button"
              title={translate('button.send')}
              onPress={isDirty ? onPressSave : onPressNewOrder}
              containerStyle={styles.orderActionButton}
              disabled={disableSaveAction}
              labelStyle={{
                color: disableSaveAction
                  ? theme.colors.paragraph
                  : theme.colors.white,
              }}
            />
          </>
        )}
      </View>
    </View>
  );
};

export default Cart;
