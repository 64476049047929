import { useLazyQuery } from '@apollo/client/react/hooks';
import { useCallback, useMemo, useState } from 'react';
import { ClientAppPermission } from '@hitz-group/domain';
import { GET_SYSTEM_PERMISSIONS } from '../../../graphql/users';
import { parseApolloError } from '../../../utils/errorHandlers';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';

export interface UsePermissions {
  loading: boolean;
  error?: string;
  permissions: ClientAppPermission[];
  fetchPermissions: () => void;
}

export const usePermissions = (): UsePermissions => {
  const [permissions, setPermissions] = useState<ClientAppPermission[]>([]);

  const [getPermissionsRequest, getPermissionsResponse] = useLazyQuery<{
    appPermissions: ClientAppPermission[];
  }>(GET_SYSTEM_PERMISSIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setPermissions(data.appPermissions);
    },
  });

  const fetchPermissions = useCallback((): void => {
    getPermissionsRequest();
  }, [getPermissionsRequest]);

  const RESPONSE_ENTITIES = [getPermissionsResponse];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      permissions,
      loading,
      fetchPermissions,
      error: error ? parseApolloError(error) : undefined,
    }),
    [loading, error, permissions, fetchPermissions],
  );
};
