import React, { useCallback, useMemo } from 'react';
import {
  DateRangeFilter,
  DateRangeFilterPresets,
  DateRangeFilterType,
  FilterObject,
} from '@hitz-group/domain';
import { DateRangeFiltersStyles } from '../styles/Component.styles';
import { Text, View } from 'react-native';
import DropDown from '../../../../components/DropDown/DropDown';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../components/FormInput/FormInput';
import formatDate from 'date-fns/format';
import { capitalCase } from 'change-case';
import Popover from '../../../../components/Popover/Popover';
import TimeInput from '../../../../components/TimeInput/TimeInput';
import {
  CUSTOM_DATE_DISPLAY_FORMAT,
  SKIP_DATE_RANGE_PRESET,
} from '../reportsHelper';
import { DateRangeFilterInput } from '../types';

interface DateRangeFilterProps {
  tradingPeriods?: FilterObject[];
  dateRangeFilter: DateRangeFilter;
  reportType?: string;
  updateDateRangeFilters?: (value: DateRangeFilterInput) => void;
  updatePrimaryFilter?: (prop: string, value: string) => void;
  updateSecondaryFilter?: (prop: string, value: string) => void;
  dateRanges?: DateRangeFilterType[];
}

export const DateRangeFilters: React.FC<DateRangeFilterProps> = ({
  dateRangeFilter,
  reportType,
  updateDateRangeFilters,
  tradingPeriods,
  dateRanges = Object.values(DateRangeFilterType),
}) => {
  const styles = DateRangeFiltersStyles();

  const defaultDateRanges = {
    [DateRangeFilterType.FILTER_BY_PRESET]: {
      type: DateRangeFilterType.FILTER_BY_PRESET,
      value: DateRangeFilterPresets.TODAY,
      range: [],
    },
    [DateRangeFilterType.CUSTOM_DATES]: {
      type: DateRangeFilterType.CUSTOM_DATES,
      value: '',
      range: [
        `${formatDate(new Date(), CUSTOM_DATE_DISPLAY_FORMAT)}`,
        `${formatDate(new Date(), CUSTOM_DATE_DISPLAY_FORMAT)}`,
      ],
    },
    [DateRangeFilterType.FILTER_BY_TIME]: {
      type: DateRangeFilterType.FILTER_BY_TIME,
      value: '',
      range: [
        `${formatDate(new Date(), CUSTOM_DATE_DISPLAY_FORMAT)} 00:00`,
        `${formatDate(new Date(), CUSTOM_DATE_DISPLAY_FORMAT)} 23:59`,
      ],
    },
    [DateRangeFilterType.FILTER_BY_TRADING_PERIOD]: {
      type: DateRangeFilterType.FILTER_BY_TRADING_PERIOD,
      value: '',
      range: [
        `${formatDate(new Date(), CUSTOM_DATE_DISPLAY_FORMAT)} ${
          tradingPeriods?.[0]?.value || '00:00'
        }`,
        `${formatDate(new Date(), CUSTOM_DATE_DISPLAY_FORMAT)} ${
          tradingPeriods?.[1]?.value || tradingPeriods?.[0]?.value || '23:59'
        }`,
      ],
    },
  };

  const getFilterComponents = (type: DateRangeFilterType) => {
    switch (type) {
      case DateRangeFilterType.FILTER_BY_PRESET: {
        return (
          <FilterByPresetComponents
            dateRangeFilter={dateRangeFilter}
            updatePrimaryFilter={updatePrimaryFilter}
            reportType={reportType}
          ></FilterByPresetComponents>
        );
      }
      case DateRangeFilterType.CUSTOM_DATES: {
        return (
          <FilterByCustomComponents
            dateRangeFilter={dateRangeFilter}
            updatePrimaryFilter={updatePrimaryFilter}
          ></FilterByCustomComponents>
        );
      }
      case DateRangeFilterType.FILTER_BY_TIME: {
        return (
          <FilterByTimeComponents
            dateRangeFilter={dateRangeFilter}
            updatePrimaryFilter={updatePrimaryFilter}
            updateSecondaryFilter={updateSecondaryFilter}
          ></FilterByTimeComponents>
        );
      }
      case DateRangeFilterType.FILTER_BY_TRADING_PERIOD: {
        return (
          <FilterByTradingPeriodComponents
            dateRangeFilter={dateRangeFilter}
            updatePrimaryFilter={updatePrimaryFilter}
            updateSecondaryFilter={updateSecondaryFilter}
            tradingPeriods={tradingPeriods}
          ></FilterByTradingPeriodComponents>
        );
      }
    }
  };

  const updatePrimaryFilter = useCallback(
    (prop: string, value: string) => {
      updateDateRangeFilters &&
        updateDateRangeFilters({
          ...dateRangeFilter,
          [prop]: value,
        });
    },
    [dateRangeFilter, updateDateRangeFilters],
  );

  const updateSecondaryFilter = useCallback(
    (prop: string, value: string) => {
      updateDateRangeFilters &&
        updateDateRangeFilters({
          ...dateRangeFilter,
          [prop]: value,
        });
    },
    [dateRangeFilter, updateDateRangeFilters],
  );

  const dateRangeOptions = useMemo(() => {
    return Object.values(DateRangeFilterType)
      .filter(filterType => dateRanges?.includes(filterType))
      .map(filterType => ({
        value: filterType,
        label: capitalCase(filterType, { delimiter: ' ' }),
      }));
  }, [dateRanges]);

  return (
    <>
      <View style={styles.pageStyle}>
        <View style={styles.dateRangeType}>
          <DropDown
            btnTestId={'preset-change-toggle'}
            options={dateRangeOptions}
            selectedValue={dateRangeFilter.type}
            containerStyle={styles.fieldInputContainerStyle}
            itemsContainerStyle={styles.itemsContainerStyle}
            extraPopoverStyle={styles.extraPopoverStyle}
            onValueChange={value =>
              updateDateRangeFilters &&
              updateDateRangeFilters({
                ...dateRangeFilter,
                ...defaultDateRanges[value as DateRangeFilterType],
              })
            }
          ></DropDown>
        </View>
        {getFilterComponents(dateRangeFilter.type)}
      </View>
    </>
  );
};

export const FilterByPresetComponents: React.FC<DateRangeFilterProps> = ({
  dateRangeFilter,
  reportType,
  updatePrimaryFilter,
}) => {
  const styles = DateRangeFiltersStyles();
  const presetToSkip = reportType && SKIP_DATE_RANGE_PRESET[reportType];
  const presetOptions = Object.values(DateRangeFilterPresets)
    .filter(preset => {
      if (presetToSkip && presetToSkip?.indexOf(preset) === -1) {
        return preset;
      } else if (presetToSkip === undefined) {
        return preset;
      }
    })
    .map(filterType => ({
      value: filterType,
      label: capitalCase(filterType, { delimiter: ' ' }),
    }));
  return (
    <>
      <View style={styles.primaryFilter}>
        <DropDown
          btnTestId={'preset-value-toggle'}
          options={presetOptions}
          selectedValue={dateRangeFilter?.value}
          containerStyle={styles.fieldInputContainerStyle}
          itemsContainerStyle={styles.itemsContainerStyle}
          extraPopoverStyle={styles.extraPopoverStyle}
          onValueChange={value =>
            updatePrimaryFilter && updatePrimaryFilter('value', value)
          }
        ></DropDown>
      </View>
    </>
  );
};

export const FilterByCustomComponents: React.FC<DateRangeFilterProps> = ({
  dateRangeFilter,
  updatePrimaryFilter,
}) => {
  const { translate } = useTranslation();
  const styles = DateRangeFiltersStyles();
  const startDate =
    (dateRangeFilter.range && dateRangeFilter.range[0]) ||
    `${formatDate(new Date(), CUSTOM_DATE_DISPLAY_FORMAT)}`;
  const endDate =
    (dateRangeFilter.range && dateRangeFilter.range[1]) ||
    `${formatDate(new Date(), CUSTOM_DATE_DISPLAY_FORMAT)}`;
  return (
    <>
      <View style={styles.primaryFilter}>
        <Popover
          toggleTestId={'date-range-toggle'}
          align="down"
          containerStyle={styles.singlePopoverStyle}
          touchableOpacityStyle={styles.touchableOpacityStyle}
          collapsedView={
            <Text
              style={styles.collapsedViewTextStyle}
            >{`${startDate} - ${endDate}`}</Text>
          }
          toggleIcon={<></>}
        >
          {() => (
            <>
              <View style={styles.primaryFilterLabel}>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.primaryFilterLabelText}
                >
                  {translate('backOfficeReports.header.selectDates')}
                </Text>
              </View>
              <FormInput
                testID={'start-date-input'}
                title={translate('backOfficeReports.header.startDate')}
                onChangeText={value =>
                  updatePrimaryFilter && updatePrimaryFilter('startDate', value)
                }
                value={startDate}
                placeholder={translate(
                  'backOfficeReports.header.startDatePlaceholder',
                )}
                containerStyle={styles.startDate}
                textInputStyle={styles.startDateInput}
                alignTitle={'left'}
              />
              <FormInput
                testID={'end-date-input'}
                title={translate('backOfficeReports.header.endDate')}
                onChangeText={value =>
                  updatePrimaryFilter && updatePrimaryFilter('endDate', value)
                }
                value={endDate}
                placeholder={translate(
                  'backOfficeReports.header.endDatePlaceholder',
                )}
                containerStyle={styles.endDate}
                textInputStyle={styles.endDateInput}
                alignTitle={'left'}
              />
            </>
          )}
        </Popover>
      </View>
      <View style={styles.secondaryFilter}></View>
    </>
  );
};

export const FilterByTimeComponents: React.FC<DateRangeFilterProps> = ({
  dateRangeFilter,
  updatePrimaryFilter,
  updateSecondaryFilter,
}) => {
  const { translate } = useTranslation();
  const styles = DateRangeFiltersStyles();

  const startDate =
    dateRangeFilter.range && dateRangeFilter.range[0].split(' ')[0];
  const endDate =
    dateRangeFilter.range && dateRangeFilter.range[1].split(' ')[0];

  const startTime =
    dateRangeFilter.range && dateRangeFilter.range[0].split(' ')[1];
  const endTime =
    dateRangeFilter.range && dateRangeFilter.range[1].split(' ')[1];

  return (
    <>
      <View style={styles.primaryFilter}>
        <Popover
          toggleTestId={'date-range-toggle'}
          align="down"
          containerStyle={styles.datePopoverStyle}
          touchableOpacityStyle={styles.touchableOpacityStyle}
          collapsedView={
            <Text
              style={styles.collapsedViewTextStyle}
            >{`${startDate} - ${endDate}`}</Text>
          }
          toggleIcon={<></>}
        >
          {() => (
            <>
              <View style={styles.primaryFilterLabel}>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.primaryFilterLabelText}
                >
                  {translate('backOfficeReports.header.selectDates')}
                </Text>
              </View>
              <FormInput
                testID={'start-date-input'}
                title={translate('backOfficeReports.header.startDate')}
                onChangeText={value =>
                  updatePrimaryFilter && updatePrimaryFilter('startDate', value)
                }
                value={startDate}
                placeholder={translate(
                  'backOfficeReports.header.startDatePlaceholder',
                )}
                containerStyle={styles.startDate}
                textInputStyle={styles.startDateInput}
                alignTitle={'left'}
              />
              <FormInput
                testID={'end-date-input'}
                title={translate('backOfficeReports.header.endDate')}
                onChangeText={value =>
                  updatePrimaryFilter && updatePrimaryFilter('endDate', value)
                }
                value={endDate}
                placeholder={translate(
                  'backOfficeReports.header.endDatePlaceholder',
                )}
                containerStyle={styles.endDate}
                textInputStyle={styles.endDateInput}
                alignTitle={'left'}
              />
            </>
          )}
        </Popover>
      </View>
      <View style={styles.secondaryFilter}>
        <Popover
          toggleTestId={'time-range-toggle'}
          align="down"
          containerStyle={styles.timePopoverStyle}
          touchableOpacityStyle={styles.touchableOpacityStyle}
          collapsedView={
            <Text
              style={styles.collapsedViewTextStyle}
            >{`${startTime} - ${endTime}`}</Text>
          }
          toggleIcon={<></>}
        >
          {() => (
            <>
              <View style={styles.secondaryFilterLabel}>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.secondaryFilterLabelText}
                >
                  {translate('backOfficeReports.header.selectTimes')}
                </Text>
              </View>
              <TimeInput
                testID={'start-time-input'}
                title={translate('backOfficeReports.header.startTime')}
                onChangeText={value =>
                  updateSecondaryFilter &&
                  updateSecondaryFilter('startTime', value)
                }
                value={startTime}
                placeholder={translate(
                  'backOfficeReports.header.startTimePlaceholder',
                )}
                containerStyle={styles.startTime}
                inputContainerStyle={styles.startTimeInput}
                textInputStyle={styles.startTimeText}
                dropDownStyle={styles.endTimeDropDown}
                alignTitle={'left'}
              />
              <TimeInput
                testID={'end-time-input'}
                title={translate('backOfficeReports.header.endTime')}
                onChangeText={value =>
                  updateSecondaryFilter &&
                  updateSecondaryFilter('endTime', value)
                }
                value={endTime}
                placeholder={translate(
                  'backOfficeReports.header.endTimePlaceholder',
                )}
                containerStyle={styles.endTime}
                inputContainerStyle={styles.endTimeInput}
                dropDownStyle={styles.endTimeDropDown}
                textInputStyle={styles.endTimeText}
                alignTitle={'left'}
              />
            </>
          )}
        </Popover>
      </View>
    </>
  );
};

/**
 * Todo: Need to update this to actually set `TradingPeriod` id in filters
 * rather than setting time value.
 * Blocker: Need to maintain Trading Period in orders.
 * Once we have it we just need to replace time by Id (we will only set date range).
 */
export const FilterByTradingPeriodComponents: React.FC<
  DateRangeFilterProps
> = ({
  dateRangeFilter,
  updatePrimaryFilter,
  updateSecondaryFilter,
  tradingPeriods,
}) => {
  const { translate } = useTranslation();
  const styles = DateRangeFiltersStyles();

  const startDate =
    dateRangeFilter.range && dateRangeFilter.range[0].split(' ')[0];
  const endDate =
    dateRangeFilter.range && dateRangeFilter.range[1].split(' ')[0];

  const time = dateRangeFilter.range && dateRangeFilter.range[0].split(' ')[1];

  const matchedPeriod = tradingPeriods?.find(period => time === period.value);
  const selectedPeriod = matchedPeriod || (tradingPeriods && tradingPeriods[0]);

  const setTimeFilter = useCallback(
    value => {
      const valueIndex = (tradingPeriods &&
        tradingPeriods?.findIndex(period => period.value === value)) as number;

      let nextTradingPeriodIndex = valueIndex + 1;

      if (tradingPeriods && valueIndex === tradingPeriods.length - 1) {
        nextTradingPeriodIndex = 0;
      }

      const nextTradingPeriodValue =
        tradingPeriods &&
        (tradingPeriods[nextTradingPeriodIndex].value as string);

      updateSecondaryFilter &&
        updateSecondaryFilter('time', `${value}-${nextTradingPeriodValue}`);
    },
    [tradingPeriods, updateSecondaryFilter],
  );

  return (
    <>
      <View style={styles.primaryFilter}>
        <Popover
          toggleTestId={'date-range-toggle'}
          align="down"
          containerStyle={styles.datePopoverStyle}
          touchableOpacityStyle={styles.touchableOpacityStyle}
          collapsedView={
            <Text
              style={styles.collapsedViewTextStyle}
            >{`${startDate} - ${endDate}`}</Text>
          }
          toggleIcon={<></>}
        >
          {() => (
            <>
              <View style={styles.primaryFilterLabel}>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.primaryFilterLabelText}
                >
                  {translate('backOfficeReports.header.selectDates')}
                </Text>
              </View>
              <FormInput
                testID={'start-date-input'}
                title={translate('backOfficeReports.header.startDate')}
                onChangeText={value =>
                  updatePrimaryFilter && updatePrimaryFilter('startDate', value)
                }
                value={startDate}
                placeholder={translate(
                  'backOfficeReports.header.startDatePlaceholder',
                )}
                containerStyle={styles.startDate}
                textInputStyle={styles.startDateInput}
                alignTitle={'left'}
              />
              <FormInput
                testID={'end-date-input'}
                title={translate('backOfficeReports.header.endDate')}
                onChangeText={value =>
                  updatePrimaryFilter && updatePrimaryFilter('endDate', value)
                }
                value={endDate}
                placeholder={translate(
                  'backOfficeReports.header.endDatePlaceholder',
                )}
                containerStyle={styles.endDate}
                textInputStyle={styles.endDateInput}
                alignTitle={'left'}
              />
            </>
          )}
        </Popover>
      </View>
      <View style={styles.secondaryFilter}>
        <DropDown
          btnTestId={'time-range-toggle'}
          options={
            (tradingPeriods &&
              tradingPeriods?.map(period => ({
                value: period.value as string,
                label: capitalCase(period.name, { delimiter: ' ' }),
              }))) ||
            []
          }
          selectedValue={selectedPeriod?.value}
          containerStyle={styles.fieldInputContainerStyle}
          itemsContainerStyle={styles.itemsContainerStyle}
          extraPopoverStyle={styles.extraPopoverStyle}
          onValueChange={setTimeFilter}
        />
      </View>
    </>
  );
};
