import React from 'react';
import { FelaComponent } from 'react-fela';
import { RenderProps, StyleFn } from '@hitz-group/domain';
import { ActivityIndicator, View, Modal } from 'react-native';
import { useFela } from 'react-fela';
export interface SpinnerProps {
  isLoading?: boolean;
  testID?: string;
}
const container: StyleFn = ({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.whiteTransparent,
  borderRadius: theme.radius.small,
});
const indicatorContainer: StyleFn = ({ theme }) => ({
  height: 125,
  width: 125,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.small,
  ...theme.shadow15,
});
const Spinner: React.FC<SpinnerProps> = ({
  isLoading,
  testID,
}: SpinnerProps) => {
  const { css } = useFela();
  if (isLoading) {
    return (
      <Modal testID={testID} animationType="none" transparent={true}>
        <FelaComponent>
          {({ style, theme }: RenderProps): React.ReactNode => (
            <View style={css(container)}>
              <View style={css(indicatorContainer)}>
                <ActivityIndicator color={theme.colors.primary} style={style} />
              </View>
            </View>
          )}
        </FelaComponent>
      </Modal>
    );
  }
  return null;
};
export default Spinner;
