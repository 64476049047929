import { gql } from '@apollo/client';
import { userDetailQuery } from './session';

const userQuery = `
  id
  firstName
  lastName
  phone
  email
  pin
  preferredLanguage
  venues {
    id
    name
  }
  stores {
    id
    name
  }
  isOwner
`;

export const GET_USERS_DETAILS = gql`
  query {
    users {
      ${userQuery}
    }
  }
`;

export const CREATE_USER_DETAILS = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ${userQuery}
    }
  }
`;

export const UPDATE_USER_DETAILS = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
     ${userQuery}
    }
  }
`;

export const DELETE_USER_DETAILS = gql`
  mutation deleteUser($id: ID) {
    deleteUser(id: $id)
  }
`;

export const GET_USER_DETAILS = gql`
  query user($id: ID!) {
    user(id: $id) {
      ${userDetailQuery}
    }
  }
`;

const ROLE_FRAGMENT = `
  name
  description
  id
  isEditable
  permissions {
    id
    operations
  }
  apps {
    POS_APP
    BACKOFFICE
  }
`;

export const GET_SYSTEM_PERMISSIONS = gql`
  query appPermissions {
    appPermissions {
      id
      resource
      label
      parent
      description
      clientApp
      isAtomic
      isPermissionGroup
      operation
      sortOrder
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query Roles($includeSystemRoles: Boolean, $includeUserCount: Boolean!) {
    roles(includeSystemRoles: $includeSystemRoles) {
      ${ROLE_FRAGMENT}
      usersCount @include(if: $includeUserCount)
    }
  }
`;
export const CREATE_ROLE = gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      ${ROLE_FRAGMENT}
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteRole($id: ID!) {
    deleteRole(id: $id)
  }
`;

export const GET_ROLE_BY_ID = gql`
  query role($id: ID!) {
    role(id: $id) {
      ${ROLE_FRAGMENT}
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      ${ROLE_FRAGMENT}
    }
  }
`;

const USER_ROLE_FIELDS = gql`
  fragment UserRoleFields on UserRole {
    locations {
      store {
        id
        name
      }
      venue {
        id
        name
      }
    }
    role {
      id
    }
    id
    overridePermissions {
      id
      operations
    }
    apps {
      BACKOFFICE
      POS_APP
    }
  }
`;

export const GET_USER_ROLE_BY_ID = gql`
  ${USER_ROLE_FIELDS}
  query userRoleById($id: ID!) {
    userRole(id: $id) {
      ...UserRoleFields
      user {
        id
      }
    }
  }
`;

export const GET_USER_WITH_ROLES = gql`
  ${USER_ROLE_FIELDS}
  query userWithRoles($id: ID!) {
    user(id: $id) {
      roles {
        ...UserRoleFields
      }
      isOwner
    }
  }
`;

export const CREATE_UPDATE_DELETE_USER_ROLES = gql`
  ${USER_ROLE_FIELDS}
  mutation createUpdateDeleteUserRole(
    $input: [AssignRoleToUserInput!]
    $ids: [ID!]
    $deleteUserRoles: Boolean!
    $updateUserRoles: Boolean!
  ) {
    assignRolesToUser(input: $input) @include(if: $updateUserRoles) {
      ...UserRoleFields
    }
    removeRolesFromUser(ids: $ids) @include(if: $deleteUserRoles)
  }
`;

export const DELETE_USER_ROLES = gql`
  mutation removeRolesFromUser($ids: [ID!]) {
    removeRolesFromUser(ids: $ids)
  }
`;
