import React, { useState, useCallback } from 'react';
import {
  View,
  TouchableOpacity,
  ViewStyle,
  TouchableWithoutFeedback,
} from 'react-native';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import IconButton from '../Button/IconButton';
export type valuesType = {
  value: string;
  label: string;
  isSelected?: boolean;
};

export interface PopoverProps {
  containerStyle?: ViewStyle;
  touchableOpacityStyle?: ViewStyle;
  align: 'down' | 'top';
  toggleIcon?: React.ReactElement;
  collapsedView?: React.ReactElement;
  children?: () => React.ReactNode;
  isDisabled?: boolean;
  toggleTestId?: string;
}

const viewStyle: StyleFn = () => ({
  flexDirection: 'column',
  minHeight: 38,
  width: 160,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: 240,
  backgroundColor: theme.colors.white,
  borderWidth: 2,
  borderColor: theme.colors.white,
  borderRadius: theme.radius.large,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  flexDirection: 'column',
  paddingVertical: theme.padding.medium * 3,
  position: 'absolute',
  right: -30,
  top: 38,
});

const dropTopStyle: StyleFn = ({ theme }) => ({
  width: 240,
  backgroundColor: theme.colors.white,
  borderWidth: 2,
  borderColor: theme.colors.white,
  borderRadius: theme.radius.large,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  flexDirection: 'column',
  paddingVertical: theme.padding.medium * 3,
  position: 'absolute',
  right: -30,
  bottom: 30,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginLeft: 'auto',
  marginRight: theme.spacing.small,
});

const touchContainerStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
  borderWidth: 0.5,
  width: '100%',
  minHeight: 38,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const Popover: React.FC<PopoverProps> = ({
  collapsedView,
  containerStyle,
  touchableOpacityStyle,
  align,
  toggleIcon,
  isDisabled,
  children,
  toggleTestId,
}: PopoverProps) => {
  const { css, theme } = useFela();
  const [showOptions, setShowOptions] = useState(false);

  const onToggleOptions = useCallback((): void => {
    !isDisabled && setShowOptions(!showOptions);
  }, [showOptions, isDisabled]);

  const onBlur = useCallback(
    (e): void => {
      const targetEvent = (
        e.nativeEvent as unknown as {
          relatedTarget: unknown;
        }
      )?.relatedTarget;
      !targetEvent && onToggleOptions();
    },
    [onToggleOptions],
  );

  return (
    <View style={[css(viewStyle), containerStyle]}>
      <TouchableOpacity
        style={[css(touchContainerStyle), touchableOpacityStyle]}
        onPress={onToggleOptions}
        onBlur={onBlur}
        testID={toggleTestId}
      >
        {collapsedView}
        {toggleIcon || (
          <IconButton
            icon={'AngleDown'}
            primary
            containerStyle={css(iconContainerStyle)}
            disabled={isDisabled}
          />
        )}
      </TouchableOpacity>

      {!isDisabled && showOptions && (
        <TouchableWithoutFeedback onBlur={onBlur}>
          <View
            style={
              align === 'down'
                ? css(dropDownStyle({ theme }))
                : css(dropTopStyle({ theme }))
            }
          >
            {children && children()}
          </View>
        </TouchableWithoutFeedback>
      )}
    </View>
  );
};

export default Popover;
