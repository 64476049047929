import React from 'react';
import { View } from 'react-native';
import { StyleFn, DayOfWeek } from '@hitz-group/domain';
import TableRow from '../../../../../components/TableComponent/TableRow';
import { useFela } from 'react-fela';
import { isWeb } from '../../../../../common/theme';
import {
  timeSlotOptions,
  weekDaysOptions,
} from '../../../../../utils/dateHelper';
import DropDown from '../../../../../components/FormInput/DropDown';
import IconButton from '../../../../../components/Button/IconButton';

export const dropdownViewStyleWeekDay: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 300,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  margin: 'auto',
});
export const dropdownViewStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 110,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  margin: 'auto',
});

const ButtonContainer: StyleFn = ({ theme }) => ({
  width: isWeb ? 560 : '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignSelf: 'flex-start',
  marginLeft: -theme.spacing.small * 1.5,
});

const closeActionStyle: StyleFn = () => ({
  display: 'flex',
  justifyContent: 'center',
  paddingRight: 0,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const datePickerStyle: StyleFn = () => ({
  zIndex: 0,
});

export interface RoutineProps {
  id?: string;
  day: DayOfWeek;
  startTime: string;
  endTime: string;
  onChange: Function;
  index: number;
  onPressDelete: (idx: number) => void;
}

export const TimeRoutineRow: React.FC<RoutineProps> = ({
  endTime,
  day,
  startTime,
  onChange,
  onPressDelete,
  index,
}: RoutineProps) => {
  const { css } = useFela();
  return (
    <TableRow
      containerStyle={css(datePickerStyle)}
      action={
        <IconButton
          icon="TrashAlt"
          iconSize={24}
          containerSize={34}
          containerStyle={css(closeIconContainerStyle)}
          iconStyle={css(closeIconStyle)}
          onPress={() => onPressDelete(index)}
        />
      }
      actionStyle={css(closeActionStyle)}
    >
      <View style={css(ButtonContainer)}>
        <DropDown
          values={weekDaysOptions}
          selectedValue={day}
          style={css(dropdownViewStyleWeekDay)}
          onValueChange={value =>
            onChange('timeSlot', { day: value, idx: index })
          }
        />
        <DropDown
          values={timeSlotOptions}
          selectedValue={startTime}
          style={css(dropdownViewStyle)}
          onValueChange={value =>
            onChange('timeSlot', { idx: index, startTime: value })
          }
        />

        <DropDown
          values={timeSlotOptions}
          selectedValue={endTime}
          style={css(dropdownViewStyle)}
          onValueChange={value =>
            onChange('timeSlot', { idx: index, endTime: value })
          }
        />
      </View>
    </TableRow>
  );
};
