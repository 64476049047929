import React from 'react';
import { TouchableOpacity, ViewStyle } from 'react-native';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import { FelaComponent } from 'react-fela';
import IconButton from '../Button/IconButton';
import { useFela } from 'react-fela';
export interface OrderDataRowProps {
  children: React.ReactElement[];
  canSelect?: boolean;
  onPress?: () => void;
  containerStyle?: ViewStyle;
  testID?: string;
  iconContainerStyle?: ViewStyle;
}

const consumerContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.padding.medium,
  width: '100%',
  height: 50,
  marginBottom: theme.spacing.small / 2,
});
const iconContainer: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.transparent,
  alignItems: 'flex-end',
  width: 15,
  marginLeft: 5,
  marginRight: -5,
});

const CustomerDataRow: React.FC<OrderDataRowProps> = ({
  children,
  onPress,
  canSelect,
  containerStyle,
  testID,
  iconContainerStyle,
}: OrderDataRowProps) => {
  const { css } = useFela();

  return (
    <FelaComponent style={consumerContainerStyle}>
      {({ style, theme }: RenderProps): React.ReactFragment => (
        <TouchableOpacity
          style={[style, containerStyle]}
          disabled={!canSelect}
          onPress={onPress}
          testID={testID}
        >
          {children}
          <IconButton
            iconSize={20}
            icon={'AngleRight'}
            iconColor={theme.colors.card}
            disabled={true}
            containerStyle={
              [css(iconContainer), iconContainerStyle] as ViewStyle
            }
          />
        </TouchableOpacity>
      )}
    </FelaComponent>
  );
};

export default CustomerDataRow;
