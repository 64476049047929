import React, { useCallback, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { PickUpSettings, Icons } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import ModalDialog from '../../../../../../../../components/Modals/Modal';
import Button from '../../../../../../../../components/Button/Button';
import IconButton from '../../../../../../../../components/Button/IconButton';
import { useModal } from '@hitz-group/rn-use-modal';
import { useFela } from 'react-fela';
import * as styles from './Modal.styles';
import { useNotification } from '../../../../../../../../hooks/Notification';

interface DineInModalProps {
  pickUpSettings?: PickUpSettings;
  onCancel?: () => void;
  onConfirm?: (settingName: string, settings: PickUpSettings) => void;
}

const PickUpModal = ({
  pickUpSettings,
  onCancel,
  onConfirm,
}: DineInModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [pickUpData, setPickUpdata] = useState<PickUpSettings>(
    pickUpSettings as PickUpSettings,
  );

  const errorValidation = useCallback(() => {
    return (
      (pickUpData &&
        !pickUpData.enablePayLater &&
        !pickUpData.enablePayNow &&
        translate('backOfficeFeatures.noAllowedPayment')) ||
      undefined
    );
  }, [pickUpData, translate]);

  const onPressCancel = useCallback(() => {
    closeModal();
    onCancel && onCancel();
  }, [onCancel, closeModal]);

  const onPressConfirm = useCallback(() => {
    const error = errorValidation();
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
      return;
    }
    closeModal();
    pickUpData && onConfirm && onConfirm('pickUpSettings', pickUpData);
  }, [errorValidation, closeModal, onConfirm, pickUpData, showNotification]);

  const onValueUpdate = useCallback((key: string, value: unknown) => {
    setPickUpdata(settingData => ({ ...settingData, [key]: value }));
  }, []);

  useEffect(() => {
    pickUpSettings && setPickUpdata(pickUpSettings);
  }, [pickUpSettings]);

  return (
    <ModalDialog onDismiss={onPressCancel} showCloseButton={false}>
      <Text style={css(styles.headingStyle)} testID={'pickUpModal-title'}>
        {translate('backOfficeFeatures.orderTypesSettings', {
          orderType: translate('backOfficeFeatures.pickUp'),
        })}
      </Text>
      <View style={css(styles.contentContainerStyle)}>
        <View style={css(styles.rowStyle)}>
          <IconButton
            testID={'pickUpModal-payLater'}
            primary
            icon={(pickUpData?.enablePayLater && 'toggle-on') || 'toggle-off'}
            iconSize={26}
            containerSize={34}
            iconColor={
              pickUpData?.enablePayLater
                ? theme.colors.green
                : theme.colors.paragraph
            }
            containerStyle={css(styles.iconContainerStyle)}
            onPress={() =>
              onValueUpdate('enablePayLater', !pickUpData?.enablePayLater)
            }
          />

          <View style={css(styles.defaultContainerStyle)}>
            <Text style={css(styles.defaultTextStyle)}>
              {translate('backOfficeFeatures.allowPayLater')}
            </Text>
          </View>
        </View>
        <View style={css(styles.rowStyle)}>
          <IconButton
            primary
            testID={'pickUpModal-payNow'}
            icon={
              (pickUpData?.enablePayNow && Icons.ToggleOn) || Icons.ToggleOff
            }
            iconSize={26}
            containerSize={34}
            iconColor={
              pickUpData?.enablePayNow
                ? theme.colors.green
                : theme.colors.paragraph
            }
            containerStyle={css(styles.iconContainerStyle)}
            onPress={() =>
              onValueUpdate('enablePayNow', !pickUpData?.enablePayNow)
            }
          />

          <View style={css(styles.defaultContainerStyle)}>
            <Text style={css(styles.defaultTextStyle)}>
              {translate('backOfficeFeatures.allowPayNow')}
            </Text>
          </View>
        </View>
      </View>
      <View style={css(styles.actionContainerStyle)}>
        <Button
          size="small"
          testID="cancel-button"
          title={translate('settings.cancel').toUpperCase()}
          onPress={onPressCancel}
          labelStyle={css(styles.buttonStyle, styles.dismissButtonStyle)}
          containerStyle={css(styles.buttonContainerStyle)}
          color={theme.colors.white}
        />
        <Button
          size="small"
          testID="confirm-button"
          title={translate('settings.confirm').toUpperCase()}
          onPress={onPressConfirm}
          labelStyle={css(styles.buttonStyle, styles.confirmButtonStyle)}
          containerStyle={css(styles.buttonContainerStyle)}
          color={theme.colors.blue}
        />
      </View>
    </ModalDialog>
  );
};

export default PickUpModal;
