import { useCallback, useMemo, useState } from 'react';
import { requestPushNotificationToken } from '../../utils/firebaseClientHelper';
import { useDevices } from './useDevices';
import { useSession } from './useSession';

export function useDevicePushNotification(): {
  registerPushNotification: (deviceId: string) => void;
  setStoreId: (storeId: string) => void;
} {
  const [session] = useSession();
  const [storeId, setStore] = useState('');

  const setStoreId = useCallback((storeId: string) => {
    setStore(storeId);
  }, []);
  const { updateDeviceToken } = useDevices({
    storeId: storeId || session?.currentStore?.id,
  });
  const registerPushNotification = useCallback(
    (deviceId: string) => {
      if (deviceId || storeId) {
        (async () => {
          const fcmToken = await requestPushNotificationToken();
          if (fcmToken) {
            updateDeviceToken({
              id: deviceId,
              pushNotificationToken: fcmToken,
            });
          }
        })();
      }
    },
    [updateDeviceToken, storeId],
  );

  return useMemo(
    () => ({
      registerPushNotification,
      setStoreId: setStoreId,
    }),
    [registerPushNotification, setStoreId],
  );
}
