import { OrderEvent, OrderAction } from '@hitz-group/domain';
import { uniq } from 'lodash';

interface Validator {
  setNext(validator: Validator): Validator;
  validate(events: OrderEvent[], lastProcessedEvent: string): boolean;
}

abstract class AbstractValidator implements Validator {
  private nextValidator: Validator | undefined;

  public setNext(validator: Validator): Validator {
    this.nextValidator = validator;
    return validator;
  }

  public validate(events: OrderEvent[], lastProcessedEvent: string): boolean {
    if (this.nextValidator) {
      return this.nextValidator.validate(events, lastProcessedEvent);
    }
    return true;
  }
}

/**
 * validator: order events contains same orderId
 */
class OrderEventsOrderIdValidator extends AbstractValidator {
  public validate(events: OrderEvent[], lastProcessedEvent: string): boolean {
    if (uniq(events.map((item: OrderEvent) => item.orderId)).length > 1) {
      return false;
    }
    return super.validate(events, lastProcessedEvent);
  }
}

/**
 * validator: order events should contains at least 2 events
 */
class OrderEventsLengthValidator extends AbstractValidator {
  public validate(events: OrderEvent[], lastProcessedEvent: string): boolean {
    if (events.length < 1) {
      return false;
    }
    return super.validate(events, lastProcessedEvent);
  }
}

/**
 * validator: order events should begin with initiate event
 */
class OrderEventsBeginWithEventValidator extends AbstractValidator {
  public validate(events: OrderEvent[], lastProcessedEvent: string): boolean {
    const [first] = events.slice(0, 1);
    if (
      first.action !== OrderAction.ORDER_INITIATE &&
      first.action !== OrderAction.ORDER_INITIATE_ONLINE &&
      first.action !== OrderAction.ORDER_REFUND_INITIATE
    ) {
      return false;
    }
    return super.validate(events, lastProcessedEvent);
  }
}

/**
 * validator: order events should end with save event
 */

const VALIDATED_END_EVENTS = [
  OrderAction.ORDER_SAVE,
  OrderAction.ORDER_PRINT_KITCHEN_DOCKET,
  OrderAction.ORDER_INITIATE_ONLINE,
  OrderAction.ORDER_ACCEPT,
  OrderAction.ORDER_REJECT,
  OrderAction.ORDER_COMPLETE,
  OrderAction.ORDER_PARTNER_CANCEL,
  OrderAction.ORDER_PLACED,
];
class OrderEventsEndWithEventValidator extends AbstractValidator {
  public validate(events: OrderEvent[], lastProcessedEvent: string): boolean {
    const [last] = events.slice(-1);
    if (!VALIDATED_END_EVENTS.includes(last.action)) {
      return false;
    }
    return super.validate(events, lastProcessedEvent);
  }
}

class OrderEventsCheckForPreviousValidator extends AbstractValidator {
  public validate(events: OrderEvent[], lastProcessedEvent: string): boolean {
    const eventsToBeValidated = events.filter(
      orderEvent => orderEvent.previous,
    );
    if (
      !eventsToBeValidated.every(orderEvent =>
        events.find(
          anyEvent =>
            orderEvent.previous == anyEvent.id ||
            orderEvent.previous == lastProcessedEvent,
        ),
      )
    ) {
      return false;
    }
    return super.validate(events, lastProcessedEvent);
  }
}

export const orderAddedEventsValidation = (events: OrderEvent[]): boolean => {
  const orderEventsLengthValidator = new OrderEventsLengthValidator();
  const orderEventsBeginWithEventValidator =
    new OrderEventsBeginWithEventValidator();
  const orderEventsEndWithEventValidator =
    new OrderEventsEndWithEventValidator();
  const orderEventsOrderIdValidator = new OrderEventsOrderIdValidator();
  const orderEventsCheckForPreviousValidator =
    new OrderEventsCheckForPreviousValidator();

  orderEventsLengthValidator
    .setNext(orderEventsBeginWithEventValidator)
    .setNext(orderEventsEndWithEventValidator)
    .setNext(orderEventsOrderIdValidator)
    .setNext(orderEventsCheckForPreviousValidator);
  return orderEventsLengthValidator.validate(events, '');
};

export const orderUpdateEventsValidation = (
  events: OrderEvent[],
  lastProcessedEvent: string,
): boolean => {
  const orderEventsLengthValidator = new OrderEventsLengthValidator();
  const orderEventsOrderIdValidator = new OrderEventsOrderIdValidator();
  const orderEventsCheckForPreviousValidator =
    new OrderEventsCheckForPreviousValidator();
  orderEventsLengthValidator
    .setNext(orderEventsOrderIdValidator)
    .setNext(orderEventsCheckForPreviousValidator);
  return orderEventsLengthValidator.validate(events, lastProcessedEvent);
};
