import React, { useCallback } from 'react';
import { View } from 'react-native';
import Button from '../../../../../components/Button/Button';
import { useFela } from 'react-fela';
import { DeviceProfile, DeviceProfileStatus } from '@hitz-group/domain';
import { StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';

interface Props {
  unAssignDeviceProfile: (deviceProfileId: string, deviceId: string) => void;
  deleteDeviceProfile: (id: string) => void;
  onPressSave: () => void;
  status: DeviceProfileStatus;
  deviceProfileId: string;
  deviceProfiles: { [key: string]: DeviceProfile };
}

export const DeviceProfileSettingsFooter: React.FC<Props> = ({
  deleteDeviceProfile,
  onPressSave,
  deviceProfileId,
  deviceProfiles,
  status,
}) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const actionsContainerStyle: StyleFn = ({ theme }) => ({
    ...theme.footerButtonActionsContainer,
  });

  const saveButtonStyle: StyleFn = ({ theme }) => ({
    width: theme.button.footerButtonWidth,
    height: theme.button.footerButtonHeight,
    marginLeft: 'auto',
    borderRadius: theme.radius.small,
    backgroundColor: theme.colors.successLight,
    alignSelf: 'auto',
  });

  const deleteButtonStyle: StyleFn = ({ theme }) => ({
    width: 120,
    height: 38,
    marginRight: 'auto',
    borderRadius: theme.radius.small,
    backgroundColor: theme.colors.danger2,
    alignSelf: 'auto',
  });

  const dangerTitleStyle: StyleFn = ({ theme }) => ({
    color: theme.colors.danger,
    fontFamily: theme.font.semibold,
    textTransform: 'uppercase',
    letterSpacing: -0.4,
  });

  const titleStyle: StyleFn = ({ theme }) => ({
    color: theme.colors.success,
    fontFamily: theme.font.semibold,
    textTransform: 'none',
  });

  const onPressUnassignOrDelete = useCallback(() => {
    const name = deviceProfiles[deviceProfileId]?.name;

    status === DeviceProfileStatus.ASSIGNED
      ? showModal(
          <ConfirmationDialog
            title={translate('dialog.unassignTitle')}
            message={translate('dialog.unassignConfirmation')}
            onConfirm={() => {
              closeModal();
            }}
          />,
        )
      : showModal(
          <ConfirmationDialog
            title={translate('dialog.deleteTitle')}
            message={translate('dialog.deleteConfirmation', { label: name })}
            onConfirm={() => {
              closeModal();
              deleteDeviceProfile(deviceProfileId);
            }}
          />,
        );
  }, [
    showModal,
    closeModal,
    deviceProfileId,
    deviceProfiles,
    status,
    deleteDeviceProfile,
    translate,
  ]);

  return (
    <View style={css(actionsContainerStyle)}>
      <Button
        fluid
        testID="delete-unassign"
        title={
          status === DeviceProfileStatus.ASSIGNED
            ? translate('button.unassign')
            : translate('button.delete')
        }
        containerStyle={css(deleteButtonStyle)}
        labelStyle={css(dangerTitleStyle)}
        disabled={
          deviceProfiles[deviceProfileId]?.isDefault || !deviceProfileId
        }
        onPress={onPressUnassignOrDelete}
      />
      <Button
        fluid
        testID="save-changes"
        title={translate('button.saveChanges')}
        containerStyle={css(saveButtonStyle)}
        labelStyle={css(titleStyle)}
        onPress={onPressSave}
      />
    </View>
  );
};
