import { Order } from '@hitz-group/domain';
import { formatMoneyValue } from '@hitz-group/localization';
import { table } from 'table';
import { config, FixedTuple } from './orderItems';

export const generateCashRounding = (order: Order, currency: string) => {
  const rows: FixedTuple[] = [
    [
      '',
      'Cash Rounding',
      formatMoneyValue(+(order.roundingAmount || 0), currency),
    ],
  ];

  return table(rows, config);
};
