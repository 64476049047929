import { Icons, Role, StyleFn, Venue } from '@hitz-group/domain';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import DropDown from '../../../../../../components/FormInput/DropDown';
import DropdownGroup, {
  DropdownGroupOptionsProps,
  DropdownGroupSection,
} from '../../../../../../components/FormInput/DropdownGroup';
import IconButton from '../../../../../../components/Button/IconButton';
import TableRow from '../../../../../../components/TableComponent/TableRow';
import {
  StoreUserRoleMapping,
  StoreUserRoleMappingByRole,
  userRoleInputUtility,
} from './userRoleInputUtility';
import { useNavigation } from '@react-navigation/native';
import scale from '../../../../../../common/theme';

const closeIconContainerStyle: StyleFn = ({ theme, disabled }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  marginLeft: theme.spacing.small,
  ...(disabled && { opacity: 0.5 }),
});

const settingsContainerStyle: StyleFn = ({ theme, disabled }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
  ...(disabled && { opacity: 0.5 }),
});

const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: scale.moderateScale(100),
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  height: 40,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(135),
  height: theme.input.height,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const settingsIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const extraPopoverStyle: StyleFn = () => ({
  width: 260,
});

const actionContainerStyle: StyleFn = () => ({
  width: 38,
  paddingHorizontal: 0,
});

const smallContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.medium / 2,
});

const btnRowStyle: StyleFn = ({}) => ({
  flexDirection: 'row',
});

interface Props {
  rolesById: Record<string, Role>;
  venues: Record<string, Venue>;
  userRoleGroup: StoreUserRoleMappingByRole;
  deleteUserRoles: (ids: string[]) => void;
  isOwner: boolean;
  userId: string;
}

const UserPermissionRow: FC<Props> = ({
  rolesById,
  venues,
  userRoleGroup,
  deleteUserRoles,
  isOwner,
  userId,
}) => {
  const navigation = useNavigation();
  const { css } = useFela({ disabled: isOwner });
  const { translate } = useTranslation();
  const [selectedRole, setSelectedRole] = useState(userRoleGroup.roleId);
  const [roleOptions, setRoleOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [venuesOptionsList, setVenuesOptionsList] = useState<
    DropdownGroupOptionsProps[]
  >([]);

  useEffect(() => {
    setVenuesOptionsList(
      Object.values(venues).map(venue => {
        const checkedStores = userRoleGroup.locations.map(
          location => location.storeId,
        );
        const hasAccessToAllStores = venue.stores.every(store =>
          checkedStores.includes(store.id),
        );
        return {
          id: venue.id,
          label: venue.name,
          checked: hasAccessToAllStores,
          data: venue.stores.map(store => ({
            id: store.id,
            label: store.name,
            checked: checkedStores.includes(store.id),
          })),
        };
      }),
    );
  }, [venues, userRoleGroup]);

  useEffect(() => {
    setRoleOptions(
      Object.values(rolesById).map(role => ({
        value: role.id,
        label: role.name,
      })),
    );
  }, [rolesById]);

  const onChangeRole = useCallback(
    (roleId: string) => {
      setSelectedRole(roleId);
      userRoleInputUtility.modifyRole(userRoleGroup.id, roleId);
    },
    [userRoleGroup.id],
  );

  const onDelete = useCallback(() => {
    deleteUserRoles([userRoleGroup.id]);
  }, [deleteUserRoles, userRoleGroup.id]);

  const onChangeLocation = useCallback(
    (sections: DropdownGroupSection[]) => {
      const selectedLocations = sections.reduce<StoreUserRoleMapping[]>(
        (acc, venue) => {
          const locations = venue.data
            .filter(store => store.checked === true)
            .map(store => {
              return {
                venueId: venue.id,
                storeId: store.id,
              };
            });
          return [...acc, ...locations];
        },
        [],
      );
      userRoleInputUtility.modifyStores(userRoleGroup.id, selectedLocations);
    },
    [userRoleGroup.id],
  );

  const navigateToOverride = useCallback(() => {
    navigation.navigate('CustomizeUserRole', {
      userRoleId: userRoleGroup.id,
      userId,
    });
  }, [navigation, userRoleGroup.id, userId]);

  return (
    <TableRow
      normalRows
      actionStyle={css(actionContainerStyle)}
      action={<View style={css(btnRowStyle)}></View>}
    >
      <View style={css(smallContainer)}>
        <DropDown
          values={roleOptions}
          selectedValue={selectedRole}
          onValueChange={onChangeRole}
          style={css(dropDownStyle)}
          extraMainViewStyle={css(dropDownMainViewStyle)}
        />
      </View>

      <View style={css(smallContainer)}>
        <DropdownGroup
          sectionHeaderLabelSuffix={`(${translate(
            'backOfficeUsers.allStores',
          )})`}
          options={venuesOptionsList}
          selectedValue={selectedRole}
          onValueChange={onChangeLocation}
          extraPopoverStyle={css(extraPopoverStyle)}
        />
      </View>

      <View style={css(smallContainer)}>
        <IconButton
          testID="userRoleSettings"
          icon={Icons.Setting}
          iconSize={24}
          containerSize={34}
          containerStyle={css(settingsContainerStyle)}
          iconStyle={css(settingsIconStyle)}
          disabled={isOwner}
          onPress={navigateToOverride}
        />
      </View>
      <View style={css(smallContainer)}>
        <IconButton
          testID="deleteUserRolesButton"
          icon={Icons.Delete}
          iconSize={24}
          containerSize={34}
          containerStyle={css(closeIconContainerStyle)}
          iconStyle={css(closeIconStyle)}
          onPress={onDelete}
          disabled={isOwner}
        />
      </View>
    </TableRow>
  );
};

export default UserPermissionRow;
