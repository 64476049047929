import { Organization, StyleFn, User } from '@hitz-group/domain';
import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View, ViewStyle, TouchableOpacity } from 'react-native';
import ProfileDisplay from '../ProfileDisplay/ProfileDisplay';
import { useFela } from 'react-fela';
export interface BackOfficeProfileSwitchProps {
  active?: string;
  organizations: Array<Pick<Organization, 'id' | 'name'>>;
  user?: Pick<User, 'id' | 'name' | 'email'>;
  onLogout?: () => void;
  onSwitch?: () => void;
  containerStyle?: ViewStyle;
  onSelectOrganization?: (orgId: string) => void;
}

const styles = StyleSheet.create({
  container: {
    zIndex: 10,
  },
  trigger: {
    flex: 1,
    width: '100%',
    overflow: 'hidden',
    height: 74,
    justifyContent: 'center',
  },
  iconUpContainerStyle: {
    top: 5,
  },
});

const container: StyleFn = ({ theme }) => ({
  zIndex: 10,
  backgroundColor: theme.colors.black,
  position: 'absolute',
  top: 0,
  width: '100%',
});

const BackOfficeProfileSwitch: React.FC<BackOfficeProfileSwitchProps> = ({
  user,
  active,
  onSwitch,
  organizations,
  containerStyle,
}: BackOfficeProfileSwitchProps) => {
  const { css } = useFela();

  const activeOrganization = useMemo<Partial<Organization> | undefined>(
    () => organizations.find(x => x.id === active),
    [organizations, active],
  );

  const onPressTrigger = useCallback(() => {
    onSwitch && onSwitch();
  }, [onSwitch]);

  return (
    <View
      style={
        containerStyle ? [css(container), containerStyle] : styles.container
      }
    >
      <TouchableOpacity
        testID="profile-switch-trigger"
        onPress={onPressTrigger}
        style={styles.trigger}
      >
        <ProfileDisplay
          name={activeOrganization?.name || ''}
          info={user?.name}
          iconRight="AngleDown"
          iconUp="AngleUp"
          iconUpContainerStyle={styles.iconUpContainerStyle}
        />
      </TouchableOpacity>
    </View>
  );
};

export default BackOfficeProfileSwitch;
