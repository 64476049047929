import { Venue, Icons } from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import { useNotification } from '../../../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import Modal from '../../../../../components/Modals/Modal';
import Button from '../../../../../components/Button/Button';
import { styles } from '../style';
import SearchBar from '../../../../../components/SearchBar/SearchBar';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import { ScrollView } from 'react-native-gesture-handler';
import { cloneDeep } from 'lodash';
import { useTranslation } from '@hitz-group/localization';
import { useFeatures } from '../../../../../hooks/app/features/useFeatures';
import { useNavigation } from '@react-navigation/native';
import { useVenues } from '../../../../../hooks/app/useVenues';
export interface ToggleFeatureMap {
  enable: ToggleFeatureInput[];
  disable: ToggleFeatureInput[];
}

export interface ToggleVenueModalProps {
  onUpdate: (venueInput: ToggleFeatureMap) => void;
  featureId: string;
  isConfigure?: boolean;
}

export interface ToggleFeatureInput {
  featureId: string;
  venueId: string;
}

export interface EnabledFeatureVenues {
  venue: Venue;
  featureId: string;
  enabled: boolean;
}

export interface FeatureVenues extends Partial<Venue> {
  id: string;
  name: string | undefined;
  enabled: boolean;
}

export const ToggleVenueModal: React.FC<ToggleVenueModalProps> = ({
  featureId,
  onUpdate,
  isConfigure,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { currentFeature } = useFeatures(featureId);
  const feature = currentFeature?.feature;
  const { getVenues, searchVenues } = useVenues();
  const [venuesData, setVenuesData] = useState([] as Venue[]);
  const [searchString] = useState('');

  const {
    venues: enabledVenues,
    getFeatureVenues,
    toggleEnableFeatureOrgLevel,
    disableFeatureOrgLevel,
    loadings: enabledfeatureLoading,
    error: enabledfeatureError,
  } = useFeatures(featureId);

  useEffect(() => {
    if (getFeatureVenues) {
      getFeatureVenues();
    }
  }, [getFeatureVenues]);

  useEffect(() => {
    getVenues();
  }, [getVenues]);

  useEffect(() => {
    async function updateData() {
      const searchResult = await searchVenues(searchString);
      setVenuesData(searchResult);
    }
    updateData();
  }, [searchVenues, searchString]);
  const { showNotification } = useNotification();

  const navigation = useNavigation();

  const allVenues = venuesData;

  const [featureVenues, setFeatureVenues] = useState<FeatureVenues[]>([]);

  const [searchVenue, setSearchVenue] = useState('');

  const enabledVenueIds: string[] = useMemo(() => {
    return enabledVenues?.map(venue => venue.venue.id);
  }, [enabledVenues]);

  useEffect(() => {
    setFeatureVenues(
      allVenues.map(venue => ({
        ...venue,
        enabled: enabledVenueIds.includes(venue?.id) ? true : false,
      })),
    );
  }, [allVenues, enabledVenueIds]);

  useEffect(() => {
    if (enabledfeatureError) {
      showNotification({
        error: true,
        message: enabledfeatureError,
      });
    }
  }, [enabledfeatureError, showNotification, translate]);
  const toggleEnableVenue = useCallback(() => {
    toggleEnableFeatureOrgLevel({ featureId });
    if (!enabledfeatureError) {
      showNotification({
        success: true,
        message: translate('backOffice.features.notifications.featureEnabled'),
      });
    }
  }, [
    toggleEnableFeatureOrgLevel,
    featureId,
    enabledfeatureError,
    showNotification,
    translate,
  ]);

  const toggleDisableVenue = useCallback(() => {
    disableFeatureOrgLevel({ featureId });
    if (!enabledfeatureError) {
      showNotification({
        success: true,
        message: translate('backOffice.features.notifications.featureDisabled'),
      });
    }
  }, [
    disableFeatureOrgLevel,
    enabledfeatureError,
    featureId,
    showNotification,
    translate,
  ]);
  const onChangeVenue = useCallback(
    (id: string) => {
      const venues = cloneDeep(featureVenues);
      const venue = venues.find(venue => venue.id === id);
      if (venue) {
        venue.enabled = !venue.enabled;
        setFeatureVenues(venues);
      }
    },
    [featureVenues],
  );

  const toggleFeatureOnVenue = useCallback(() => {
    const updatedFeatureMap: ToggleFeatureMap = {
      enable: [],
      disable: [],
    };

    featureVenues.forEach(venue => {
      if (venue.enabled) {
        updatedFeatureMap.enable.push({
          featureId,
          venueId: venue?.id as string,
        });
      } else if (
        !venue.enabled &&
        enabledVenueIds.indexOf(venue?.id as string) > -1
      ) {
        updatedFeatureMap.disable.push({
          featureId,
          venueId: venue?.id as string,
        });
      }
    });

    onUpdate(updatedFeatureMap);
    if (updatedFeatureMap?.enable.length === 0) {
      toggleDisableVenue();
    } else {
      toggleEnableVenue();
    }
    setTimeout(() => {
      navigation.navigate('ManageFeatures');
      closeModal();
    }, 1000);
  }, [
    featureVenues,
    onUpdate,
    toggleDisableVenue,
    closeModal,
    enabledVenueIds,
    featureId,
    navigation,
    toggleEnableVenue,
  ]);

  const allVenueArray = useMemo(() => {
    let allVenues = featureVenues;
    if (searchVenue) {
      allVenues = allVenues.filter(venue =>
        venue.name?.toLowerCase().includes(searchVenue.toLowerCase()),
      );
    }
    return allVenues;
  }, [featureVenues, searchVenue]);

  const { css, theme } = useFela();
  if (enabledfeatureLoading) return <LoadingIndicator />;

  return (
    <Modal
      onDismiss={closeModal}
      contentStyle={css(styles.createModalContainerStyle)}
    >
      <Text style={css(styles.modalTitle)}>
        {translate('backOffice.features.modals.enable')} {feature?.title}
      </Text>
      <Text style={css(styles.modalDescription)}>
        {translate('backOffice.features.selectStores')}
      </Text>
      <SearchBar
        placeholder={translate('backOffice.features.searchVenue')}
        containerStyle={css(styles.searchContainerStyle)}
        textInputStyle={css(styles.searchTextInputStyle)}
        iconColor={theme.colors.paragraph}
        placeholderColor={theme.colors.paragraph}
        onChange={setSearchVenue}
      />
      <ScrollView showsHorizontalScrollIndicator={true}>
        {allVenueArray.length == 0 ? (
          <View>
            <Text>{translate('backOffice.features.noVenuesFound')}</Text>
          </View>
        ) : (
          <View>
            <View style={css(styles.iconButtonContainerStyle)}></View>

            {allVenueArray?.map(venue => {
              return (
                <View key={venue?.id}>
                  <Button
                    title={venue.name}
                    key={venue?.id}
                    testID={'toggle-venue-action' + venue?.id}
                    labelStyle={css(
                      styles.checkBoxTitleStyle({ theme, isActive: true }),
                    )}
                    fluid
                    iconPosition={'left'}
                    onPress={onChangeVenue.bind(null, venue?.id as string)}
                    containerStyle={css(
                      styles.checkBoxTitleContainer({ theme }),
                    )}
                    iconContainerStyle={css(styles.checkIconContainer)}
                    icon={venue.enabled ? Icons.Check : Icons.Uncheck}
                    iconProps={{
                      color: theme.colors.success,
                      size: 14,
                    }}
                  />
                </View>
              );
            })}
          </View>
        )}
      </ScrollView>
      <View style={css(styles.rowViewStyle)}>
        <Button
          title={translate('backOffice.features.modals.dismiss')}
          containerStyle={css(
            styles.buttonStyle({
              theme,
              marginLeft: theme.spacing.medium,
              backgroundColor: theme.colors.white,
            }),
          )}
          labelStyle={css(styles.createVenueLabelStyle)}
          color={theme.colors.paragraph}
          onPress={closeModal}
          testID="close-modal-action"
        />
        {isConfigure ? (
          <Button
            title={translate('backOffice.features.modals.update')}
            containerStyle={css(
              styles.buttonStyle({
                theme,
                marginLeft: theme.spacing.medium,
                backgroundColor: theme.colors.blue,
              }),
            )}
            labelStyle={css(styles.createVenueLabelStyle)}
            color={theme.colors.primaryLightest}
            onPress={toggleFeatureOnVenue}
            testID="enable-venue-action-update"
          />
        ) : (
          <Button
            title={translate('backOffice.features.modals.apply')}
            containerStyle={css(
              styles.buttonStyle({
                theme,
                marginLeft: theme.spacing.medium,
                backgroundColor: theme.colors.success,
              }),
            )}
            labelStyle={css(styles.createVenueLabelStyle)}
            color={theme.colors.green}
            onPress={toggleFeatureOnVenue}
            testID="enable-venue-action"
          />
        )}
      </View>
    </Modal>
  );
};
