import { StyleFn } from '@hitz-group/domain';
import { useDimensions } from '@react-native-community/hooks';
import React from 'react';
import { useFela, withTheme } from 'react-fela';
import { Helmet } from 'react-helmet';
import { SafeAreaView, ScrollView, StatusBar, ViewStyle } from 'react-native';
import { useNotification } from '../../hooks/Notification';
import Header, { HEIGHT } from '../Header/Header';
import {
  DrawerButton,
  SubscriptionStatusButton,
} from '../HeaderIcons/HeaderIcons';
import { NotificationList } from '../Notification/NotificationList';

const containerStyle: StyleFn = ({ hasHeader, windowHeight }) => ({
  height:
    windowHeight - (hasHeader ? HEIGHT : 0) - (StatusBar.currentHeight || 0),
});

const scrollViewStyle: StyleFn = () => ({
  flex: 1,
});

export interface LayoutProps {
  testID?: string;
  children: React.ReactChild;
  title?: string;
  description?: string;
  hasHeader?: boolean;
  headerRight?: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerTitle?: React.ReactNode | string;
  contentStyle?: ViewStyle;
  headerStyle?: ViewStyle;
  useSafeArea?: boolean;
}

const DefaultHeaderRight: JSX.Element = (
  <>
    <SubscriptionStatusButton />
    <DrawerButton />
  </>
);

const Layout: React.FC<LayoutProps> = ({
  testID,
  children,
  description,
  title,
  hasHeader,
  headerLeft,
  headerRight = DefaultHeaderRight,
  headerTitle,
  contentStyle,
  headerStyle,
  useSafeArea = true,
}: LayoutProps) => {
  const { css, theme } = useFela();
  const { closeNotification, notifications } = useNotification();
  const { height: windowHeight } = useDimensions().window;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <StatusBar barStyle="light-content" />
      {hasHeader && (
        <Header
          testID="layout-header"
          left={headerLeft}
          title={headerTitle}
          right={headerRight}
          headerStyle={headerStyle}
        />
      )}

      {useSafeArea ? (
        <SafeAreaView
          style={css(containerStyle({ theme, hasHeader, windowHeight }))}
        >
          <ScrollView
            testID={testID}
            contentInsetAdjustmentBehavior="automatic"
            bounces={false}
            contentContainerStyle={
              contentStyle
                ? [css(scrollViewStyle), contentStyle]
                : css(scrollViewStyle)
            }
          >
            {children}
          </ScrollView>
          <NotificationList
            notifications={notifications}
            onCloseNotification={closeNotification}
          />
        </SafeAreaView>
      ) : (
        <>
          <NotificationList
            notifications={notifications}
            onCloseNotification={closeNotification}
          />
          {children}
        </>
      )}
    </>
  );
};

Layout.defaultProps = {
  title: 'TILL POS | Powerful Restaurant POS & management software',
  description:
    'TILL is a Point-Of-Sale platform that provides affordable and easy to use software with actionable insights to help run and grow your business.',
  hasHeader: true,
};

export default withTheme(Layout);
