import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';
import {
  GET_AREAS,
  GET_INTEGRATION_PARTNERS_STORE_CONFIGS,
  GET_INTEGRATION_PARTNER_CONFIGS,
  GET_LOCATIONS,
  GET_WORKFORCE_PARTNER_CONSENT_URL,
  LINK_STORES_WITH_DEPUTY_LOCATIONS,
  OAUTH_CALLBACK,
  SYNC_EMPLOYEES,
  TOGGLE_INTEGRATION_CONNECTION,
  UPDATE_STORE_WITH_DEPUTY_SETTINGS,
} from './graphql';
import {
  DeputyArea,
  DeputyLocation,
  DeputySettingsInput,
  DeputyStoreLocationLink,
  DeputyStoreLocationLinkInput,
  IntegrationApps,
  IntegrationPartner,
  WorkforceIntegrationStorePreferences,
} from '@hitz-group/domain';

export interface UseWorkforceIntegration {
  loading: boolean;
  error?: string;
  workforcePartnerConsentUrl?: string;
  locations: DeputyLocation[];
  deputyAreas: DeputyArea[];
  integrationPartner: DeputyStoreLocationLink[];
  integrationPartnerStores?: WorkforceIntegrationStorePreferences;
  updatedStoreWithDeputySettings: boolean;
  linkedDeputyStores: boolean;
  getWorkforcePartnerConsentUrl: (integrationApp: IntegrationApps) => void;
  integrationData?: IntegrationPartner;
  saveIntegrationDetails: (url: string) => void;
  getLocations: () => void;
  getIntegrationPartnerConfig: (orgId: string) => void;
  getAllDeputyAreas: (locationId: number) => void;
  getIntegrationPartnerStoreConfig: (storeId: string) => void;
  linkStoresWithDeputyLocations: (
    input: DeputyStoreLocationLinkInput,
  ) => Promise<void>;
  updateStoreWithDeputySettings: (
    input: DeputySettingsInput,
    storeId: string,
  ) => Promise<void>;
  toggleWorkforceIntegrationConnection: (
    integrationApp: IntegrationApps,
  ) => void;
  employeeSyncRequestSent: boolean;
  syncEmployees: (storeId: string) => void;
}

export function useWorkforceIntegration(): UseWorkforceIntegration {
  const [workforcePartnerConsentUrl, setWorkforcePartnerConsentUrl] = useState<
    string | undefined
  >();

  const [integrationData, setIntegrationData] = useState<
    IntegrationPartner | undefined
  >();

  const [locations, setLocations] = useState<DeputyLocation[]>([]);
  const [deputyAreas, setDeputyAreas] = useState<DeputyArea[]>([]);
  const [integrationPartnerStores, setIntegrationPartnerStores] =
    useState<WorkforceIntegrationStorePreferences>();

  const [integrationPartner, setIntegrationPartner] = useState<
    DeputyStoreLocationLink[]
  >([]);

  const [linkedDeputyStores, setLinkedDeputyStores] = useState(false);
  const [updatedStoreWithDeputySettings, setUpdateStoreWithDeputySettings] =
    useState(false);

  const [employeeSyncRequestSent, setEmployeeSyncRequestSent] =
    useState<boolean>(false);

  const onRetrieve = useCallback(
    data => {
      if (data && data.getWorkforcePartnerConsentUrl) {
        setWorkforcePartnerConsentUrl(data.getWorkforcePartnerConsentUrl);
      }
    },
    [setWorkforcePartnerConsentUrl],
  );

  const [
    getWorkforcePartnerConsentUrlRequest,
    getWorkforcePartnerConsentUrlResponse,
  ] = useLazyQuery(GET_WORKFORCE_PARTNER_CONSENT_URL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onRetrieve,
  });

  const getWorkforcePartnerConsentUrl = useCallback(
    (app: IntegrationApps) => {
      getWorkforcePartnerConsentUrlRequest({
        variables: {
          integrationPartner: app,
        },
      });
    },
    [getWorkforcePartnerConsentUrlRequest],
  );

  const [
    linkStoresWithDeputyLocationRequest,
    linkStoresWithDeputyLocationResponse,
  ] = useMutation(LINK_STORES_WITH_DEPUTY_LOCATIONS, {
    onError: noopHandler,
  });

  const [
    updateStoreWithDeputySettingsRequest,
    updateStoreWithDeputySettingsResponse,
  ] = useMutation(UPDATE_STORE_WITH_DEPUTY_SETTINGS, {
    onError: noopHandler,
  });

  const [saveIntegrationDetailsRequest, saveIntegrationDetailsResponse] =
    useMutation(OAUTH_CALLBACK, {
      onError: noopHandler,
    });

  const [
    toggleIntegrationConnectionRequest,
    toggleIntegrationConnectionResponse,
  ] = useMutation(TOGGLE_INTEGRATION_CONNECTION, {
    onError: noopHandler,
  });

  const [syncEmployeesRequest, syncEmployeesResponse] = useMutation(
    SYNC_EMPLOYEES,
    {
      onError: noopHandler,
    },
  );

  const syncEmployees = useCallback(
    (storeId: string) => {
      setEmployeeSyncRequestSent(false);
      syncEmployeesRequest({
        variables: {
          storeId,
        },
      });
    },
    [syncEmployeesRequest],
  );

  const saveIntegrationDetails = useCallback(
    (url: string) => {
      saveIntegrationDetailsRequest({
        variables: {
          input: url,
        },
      });
    },
    [saveIntegrationDetailsRequest],
  );

  const toggleWorkforceIntegrationConnection = useCallback(
    (app: IntegrationApps) => {
      toggleIntegrationConnectionRequest({
        variables: {
          integrationPartner: app,
        },
      });
    },
    [toggleIntegrationConnectionRequest],
  );

  useEffect(() => {
    if (syncEmployeesResponse.data?.syncEmployees) {
      setEmployeeSyncRequestSent(true);
    }
  }, [syncEmployeesResponse.data?.syncEmployees]);

  useEffect(() => {
    if (saveIntegrationDetailsResponse.data) {
      setIntegrationData(
        saveIntegrationDetailsResponse.data.createDeputyIntegration,
      );
    }
  }, [saveIntegrationDetailsResponse.data]);

  useEffect(() => {
    if (toggleIntegrationConnectionResponse.data) {
      setIntegrationData(
        toggleIntegrationConnectionResponse.data
          .toggleWorkforceIntegrationConnection,
      );
    }
  }, [toggleIntegrationConnectionResponse.data]);

  const [getLocationsRequest, getLocationsResponse] = useLazyQuery(
    GET_LOCATIONS,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: response => {
        setLocations(response.getAllDeputyLocations || []);
      },
    },
  );

  const [getAllDeputyAreasRequest, getAllDeputyAreasResponse] = useLazyQuery(
    GET_AREAS,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: response => {
        setDeputyAreas(response.getAllDeputyAreas || []);
      },
    },
  );

  const [
    getIntegrationPartnerConfigRequest,
    getIntegrationPartnerConfigResponse,
  ] = useLazyQuery<
    { getIntegrationPartnerConfigs: IntegrationPartner },
    { orgId: string }
  >(GET_INTEGRATION_PARTNER_CONFIGS, {
    fetchPolicy: 'cache-and-network',
    onError: noopHandler,
    onCompleted: response => {
      setIntegrationPartner(
        response?.getIntegrationPartnerConfigs?.preferences?.workforce?.deputy
          ?.storeLocationMappings || [],
      );
    },
  });

  const [
    getIntegrationPartnerStoreConfigRequest,
    getIntegrationPartnerStoreConfigResponse,
  ] = useLazyQuery(GET_INTEGRATION_PARTNERS_STORE_CONFIGS, {
    fetchPolicy: 'cache-and-network',
    onError: noopHandler,
    onCompleted: response => {
      setIntegrationPartnerStores(
        response?.getIntegrationPartnerStoreConfigs?.preferences || [],
      );
    },
  });

  useEffect(() => {
    if (getLocationsResponse?.data?.getAllDeputyLocations)
      setLocations(getLocationsResponse.data.getAllDeputyLocations);
  }, [getLocationsResponse?.data?.getAllDeputyLocations]);

  useEffect(() => {
    if (getAllDeputyAreasResponse?.data?.getAllDeputyAreas)
      setDeputyAreas(getAllDeputyAreasResponse.data.getAllDeputyAreas);
  }, [getAllDeputyAreasResponse.data]);

  const getLocations = useCallback((): void => {
    getLocationsRequest();
  }, [getLocationsRequest]);

  const getAllDeputyAreas = useCallback(
    (locationId): void => {
      getAllDeputyAreasRequest({
        variables: {
          locationId: locationId,
        },
      });
    },
    [getAllDeputyAreasRequest],
  );

  const linkStoresWithDeputyLocations = useCallback(
    async (input: DeputyStoreLocationLinkInput): Promise<void> => {
      await linkStoresWithDeputyLocationRequest({
        variables: { input },
      });
    },
    [linkStoresWithDeputyLocationRequest],
  );

  const updateStoreWithDeputySettings = useCallback(
    async (input: DeputySettingsInput, storeId: string): Promise<void> => {
      await updateStoreWithDeputySettingsRequest({
        variables: { input, storeId },
      });
    },
    [updateStoreWithDeputySettingsRequest],
  );

  const getIntegrationPartnerStoreConfig = useCallback(
    (storeId: string): void => {
      getIntegrationPartnerStoreConfigRequest({
        variables: {
          store: storeId,
        },
      });
    },
    [getIntegrationPartnerStoreConfigRequest],
  );

  useEffect(() => {
    if (
      getIntegrationPartnerStoreConfigResponse?.data
        ?.getIntegrationPartnerStoreConfigs
    )
      setIntegrationPartnerStores(
        getIntegrationPartnerStoreConfigResponse.data
          .getIntegrationPartnerStoreConfigs?.preferences,
      );
  }, [
    getIntegrationPartnerStoreConfigResponse?.data
      ?.getIntegrationPartnerStoreConfigs,
  ]);

  const getIntegrationPartnerConfig = useCallback(
    (orgId: string): void => {
      getIntegrationPartnerConfigRequest({
        variables: {
          orgId,
        },
      });
    },
    [getIntegrationPartnerConfigRequest],
  );

  useEffect(() => {
    if (getIntegrationPartnerConfigResponse?.data?.getIntegrationPartnerConfigs)
      setIntegrationPartner(
        getIntegrationPartnerConfigResponse.data.getIntegrationPartnerConfigs
          ?.preferences?.workforce?.deputy?.storeLocationMappings || [],
      );
  }, [getIntegrationPartnerConfigResponse?.data?.getIntegrationPartnerConfigs]);

  useEffect(() => {
    if (
      linkStoresWithDeputyLocationResponse?.data?.linkStoresWithDeputyLocations
    )
      setLinkedDeputyStores(
        linkStoresWithDeputyLocationResponse.data.linkStoresWithDeputyLocations,
      );
  }, [
    linkStoresWithDeputyLocationResponse?.data?.linkStoresWithDeputyLocations,
  ]);

  useEffect(() => {
    if (
      updateStoreWithDeputySettingsResponse?.data?.updateStoreWithDeputySettings
    )
      setUpdateStoreWithDeputySettings(
        updateStoreWithDeputySettingsResponse.data
          .updateStoreWithDeputySettings,
      );
  }, [
    updateStoreWithDeputySettingsResponse?.data?.updateStoreWithDeputySettings,
  ]);

  const RESPONSE_ENTITIES = [
    getWorkforcePartnerConsentUrlResponse,
    saveIntegrationDetailsResponse,
    toggleIntegrationConnectionResponse,
    getLocationsResponse,
    getIntegrationPartnerStoreConfigResponse,
    linkStoresWithDeputyLocationResponse,
    getIntegrationPartnerConfigResponse,
    syncEmployeesResponse,
  ];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      workforcePartnerConsentUrl,
      getWorkforcePartnerConsentUrl,
      saveIntegrationDetails,
      toggleWorkforceIntegrationConnection,
      integrationData,
      locations,
      integrationPartner,
      getIntegrationPartnerConfig,
      deputyAreas,
      getLocations,
      getAllDeputyAreas,
      integrationPartnerStores,
      getIntegrationPartnerStoreConfig,
      linkStoresWithDeputyLocations,
      linkedDeputyStores,
      updateStoreWithDeputySettings,
      updatedStoreWithDeputySettings,
      employeeSyncRequestSent,
      syncEmployees,
    }),
    [
      loading,
      error,
      workforcePartnerConsentUrl,
      getWorkforcePartnerConsentUrl,
      saveIntegrationDetails,
      toggleWorkforceIntegrationConnection,
      integrationData,
      locations,
      integrationPartner,
      getIntegrationPartnerConfig,
      deputyAreas,
      getLocations,
      getAllDeputyAreas,
      integrationPartnerStores,
      getIntegrationPartnerStoreConfig,
      linkStoresWithDeputyLocations,
      linkedDeputyStores,
      updateStoreWithDeputySettings,
      updatedStoreWithDeputySettings,
      employeeSyncRequestSent,
      syncEmployees,
    ],
  );
}
