import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useState, useCallback, useEffect } from 'react';
import {
  GET_PAGES_QUERY,
  CREATE_PAGES,
  UPDATE_PAGES,
  DELETE_PAGES,
  GET_PAGE_QUERY,
  ADD_OR_REMOVE_AVAILABILITY,
  UPDATE_PAGE_ENTITY_ORDER,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { Operation } from '../../../types/Operation';
import { ApolloError } from '@apollo/client';
import { keyBy } from 'lodash';
import {
  CreatePageInput,
  Page,
  UpdatePageInput,
  PageItemOrderInput,
} from '@hitz-group/domain';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';

export interface AddOrRemoveAvailabilityProps {
  pageId: string;
  addProductIds: string[];
  addVariants: string[];
  addPages: string[];
  removeProducts: string[];
  removePages: string[];
  removeVariants: string[];
}

export interface UsePagesProps {
  pages: Record<string, Page>;
  error: string | undefined;
  loading: boolean;
  operation: Operation;
  getPages: () => void;
  createPages: (createPagesInput: CreatePageInput[]) => void;
  updatePages: (updatePagesInput: UpdatePageInput[]) => void;
  deletePage: (id: string) => void;
  getPage: (id: string) => void;
  createdIds: string[];
  addOrRemoveAvailability: (
    input: AddOrRemoveAvailabilityProps,
  ) => Promise<boolean>;
  updatePageItemsOrdering: (
    pageId: string,
    item: PageItemOrderInput[],
  ) => Promise<boolean>;
}

export function usePages(): UsePagesProps {
  const [pages, setPages] = useState<Record<string, Page>>({});
  const [operation, setOperation] = useState<Operation>(Operation.READ);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [createdIds, setCreatedIds] = useState<string[]>([]);

  const onCompleteGetPagesRequest = useCallback(data => {
    if (data) {
      setPages(keyBy(data.pages as Page[], 'id'));
    }
  }, []);

  // TODO: cache is not being updated for priority. can be changed to cache-and-network.
  const [getPages, pagesGetRequest] = useLazyQuery(GET_PAGES_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: onCompleteGetPagesRequest,
  });

  const getPagesData = useCallback(() => {
    getPages();
    setOperation(Operation.READ);
  }, [getPages]);

  // get page

  const onCompleteGetPageRequest = useCallback(data => {
    if (data) {
      const page = data.page as Page;
      setPages(prev => ({ ...prev, [page.id]: page }));
    }
  }, []);

  // TODO: cache is not being updated for priority. can be changed to cache-and-network.
  const [getPage, pageGetRequest] = useLazyQuery(GET_PAGE_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: onCompleteGetPageRequest,
  });

  const getPageData = useCallback(
    (pageId: string) => {
      getPage({
        variables: {
          input: pageId,
        },
      });
      setOperation(Operation.READ);
    },
    [getPage],
  );

  // create
  const [createPages, createPagesResponse] = useMutation(CREATE_PAGES, {
    onError: noopHandler,
  });

  const [updatePageItemsOrder, updatePageItemsOrderResponse] = useMutation(
    UPDATE_PAGE_ENTITY_ORDER,
    {
      onError: noopHandler,
    },
  );

  const createPagesData = useCallback(
    (createPagesInput: CreatePageInput[]) => {
      createPages({
        variables: {
          input: createPagesInput,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createPages],
  );

  const updatePageItemsOrdering = useCallback(
    async (pageId: string, item: PageItemOrderInput[]) => {
      const response = await updatePageItemsOrder({
        variables: {
          pageId,
          item,
        },
      });
      if (response.data) {
        setOperation(Operation.UPDATE);
        return true;
      }
      return false;
    },
    [updatePageItemsOrder],
  );

  useEffect(() => {
    if (createPagesResponse.data) {
      const pages = createPagesResponse.data.createPages as Page[];
      setPages(prev => {
        const tempPrev = { ...prev };
        pages.forEach(eachPage => {
          tempPrev[eachPage.id] = eachPage;
        });
        return tempPrev;
      });
      setCreatedIds(pages.map(x => x && x.id));
    }
  }, [createPagesResponse.data]);

  // update
  const [updatePages, updatePagesResponse] = useMutation(UPDATE_PAGES, {
    onError: noopHandler,
  });

  const updatePagesData = useCallback(
    (createPagesInput: UpdatePageInput[]) => {
      updatePages({
        variables: {
          input: createPagesInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updatePages],
  );

  useEffect(() => {
    if (updatePagesResponse.data) {
      const pages = updatePagesResponse.data.updatePages as Page[];
      setPages(prev => {
        const tempPrev = { ...prev };
        pages.forEach(eachPage => {
          tempPrev[eachPage.id] = eachPage;
        });
        return tempPrev;
      });
    }
  }, [updatePagesResponse.data]);

  // delete
  const [deletePage, deletePageResponse] = useMutation(DELETE_PAGES, {
    onError: noopHandler,
  });

  const deletePageData = useCallback(
    (id: string) => {
      deletePage({
        variables: {
          input: id,
        },
      });
      setDeletedIds([id]);
      setOperation(Operation.DELETE);
    },
    [deletePage],
  );

  useEffect(() => {
    if (deletePageResponse.data?.deletePage) {
      setPages(prev => {
        const tempPrev = { ...prev };
        deletedIds.forEach(x => delete tempPrev[x]);
        return tempPrev;
      });
    }
  }, [deletePageResponse.data, deletedIds]);

  // add or remove

  const [addOrRemoveAvailabilityReq, addOrRemoveAvailabilityResponse] =
    useMutation(ADD_OR_REMOVE_AVAILABILITY, {
      onError: noopHandler,
    });

  const addOrRemoveAvailability = useCallback(
    async (input: AddOrRemoveAvailabilityProps) => {
      const response = await addOrRemoveAvailabilityReq({
        variables: { ...input },
      });
      if (response.data) {
        setOperation(Operation.UPDATE);
        return true;
      }
      return false;
    },
    [addOrRemoveAvailabilityReq],
  );

  const RESPONSE_ENTITIES = [
    pagesGetRequest,
    createPagesResponse,
    updatePagesResponse,
    pageGetRequest,
    deletePageResponse,
    addOrRemoveAvailabilityResponse,
    updatePageItemsOrderResponse,
  ];

  const error: ApolloError | undefined = getError(RESPONSE_ENTITIES);
  const loading: boolean = isLoading(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      pages,
      getPages: getPagesData,
      operation,
      error: error ? parseApolloError(error) : undefined,
      loading,
      createPages: createPagesData,
      updatePages: updatePagesData,
      deletePage: deletePageData,
      getPage: getPageData,
      createdIds,
      addOrRemoveAvailability,
      updatePageItemsOrdering,
    }),
    [
      pages,
      error,
      loading,
      operation,
      getPagesData,
      createPagesData,
      updatePagesData,
      deletePageData,
      getPageData,
      createdIds,
      addOrRemoveAvailability,
      updatePageItemsOrdering,
    ],
  );
}
