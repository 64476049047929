import firebase from './firebase';

export const firebaseMessagingClient = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export const getFcmToken = async (): Promise<string | null | undefined> => {
  if (!firebaseMessagingClient) return undefined;
  try {
    return await firebaseMessagingClient.getToken();
  } catch (e) {
    console.warn(e);
  }
};

export const requestPushNotificationToken = async (): Promise<
  string | undefined | null
> => {
  try {
    if (firebaseMessagingClient) {
      return await getFcmToken();
    } else {
      return Promise.resolve('');
    }
  } catch (e) {
    console.warn(e);
  }
};
