import { StyleFn, OrderType, CreateOrderTypeInput } from '@hitz-group/domain';
import React, { useEffect, useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Button/Button';
import { CREATE_ORDER_TYPE } from '../../../graphql/settings';
import { useNotification } from '../../../hooks/Notification';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import Modal from '../Modal';

const modalContainer: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginLeft: 0,
  marginTop: theme.spacing.medium,
});

const createLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const formInputStyle: StyleFn = ({ theme, noMargin }) => ({
  height: theme.input.height,
  marginBottom: noMargin ? 0 : theme.spacing.small,
  width: 265,
});

interface OrderTypeProps {
  onAddOrderType: (orderType: OrderType) => void;
}

export const CreateOrderTypeModal: React.FC<OrderTypeProps> = ({
  onAddOrderType,
}: OrderTypeProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [createForm, setCreateForm] = useState({} as CreateOrderTypeInput);
  const { showNotification } = useNotification();

  const OnChangeModal = useCallback((prop: string, value: string) => {
    setCreateForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const [createOrderType, createOperation] = useMutation(CREATE_ORDER_TYPE, {
    onError: noopHandler,
  });

  const onPressCreate = useCallback(() => {
    if (!createForm.code || !createForm.name) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
    } else {
      createOrderType({
        variables: { input: createForm as CreateOrderTypeInput },
      });
    }
  }, [createForm, createOrderType, showNotification, translate]);

  useEffect(() => {
    if (createOperation.data) {
      onAddOrderType(createOperation.data.createOrderType);
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.newOrderTypeSuccessfullyAdded'),
      });
    }
  }, [
    createOperation.data,
    showNotification,
    closeModal,
    onAddOrderType,
    translate,
  ]);

  useEffect(() => {
    if (createOperation.error) {
      showNotification({
        error: true,
        message: parseApolloError(createOperation.error),
      });
    }
  }, [createOperation.error, showNotification]);

  return (
    <Modal
      title={translate('backOfficeSettings.createOrderType')}
      onDismiss={closeModal}
      contentStyle={css(modalContainer)}
    >
      <FormInput
        title={translate('backOfficeSettings.orderTypeName') + '*'}
        placeholder={translate('backOfficeSettings.orderTypeName')}
        value={createForm.name}
        containerStyle={css(formInputStyle)}
        onChangeText={OnChangeModal.bind(null, 'name')}
      />
      <FormInput
        title={translate('backOfficeSettings.orderTypeCode') + '*'}
        placeholder={translate('backOfficeSettings.orderTypeCode')}
        value={createForm.code}
        containerStyle={css(formInputStyle)}
        onChangeText={OnChangeModal.bind(null, 'code')}
      />

      <Button
        title={translate('backOfficeSettings.createOrderType')}
        containerStyle={css(buttonContainerStyle)}
        labelStyle={css(createLabelStyle)}
        color={theme.colors.green}
        onPress={onPressCreate}
      />
    </Modal>
  );
};
