import { useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@hitz-group/localization';
import {
  OnboardingAction,
  OnboardingSection,
  OnboardingArea,
  OnboardingCheckList,
  PaymentType,
  StyleFn,
  UpdatePaymentTypeInput,
  Icons,
} from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import Button from '../../../../components/Button/Button';
import IconButton from '../../../../components/Button/IconButton';
import FormInput from '../../../../components/FormInput/FormInput';
import { CreatePaymentModal } from '../../../../components/Modals/Payments/CreatePaymentModal';
import { DeletePaymentModal } from '../../../../components/Modals/Payments/DeletePaymentModal';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import TableRow from '../../../../components/TableComponent/TableRow';
import { UPDATE_PAYMENT_TYPES } from '../../../../graphql/settings';
import { usePaymentTypes } from '../../../../hooks/app/usePaymentTypes';
import { useModal } from '@hitz-group/rn-use-modal';
import { useNotification } from '../../../../hooks/Notification';
import { useOnboarding } from '../../../../hooks/app/useOnboarding';
import { noopHandler, parseApolloError } from '../../../../utils/errorHandlers';
import { stripProperties } from '../../../../utils/stripObjectProps';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import scale, { isWeb } from '../../../../common/theme';

export interface PaymentRowProps {
  paymentType: PaymentType;
  onDeletePayment: (index: number, id: string) => void;
  onChange: (index: number, prop: string, value: string | boolean) => void;
  index: number;
}

interface PaymentTypeDisplay extends PaymentType {
  value: string;
  label: string;
}

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const mainView: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
  width: isWeb ? '50%' : '100%',
  alignSelf: 'center',
});

const rowStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginLeft: -(theme.spacing.big / 2),
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
});

const closeIconGreyContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
});

const greyedOutStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.grey,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const inputContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: scale.textInputWidth180,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
  letterSpacing: -0.5,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  marginTop: theme.padding.medium,
  height: 18,
  width: 18,
});

const iconButtonContainerStyle: StyleFn = () => ({
  width: scale.textInputWidth180,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  marginTop: 10,
  height: 18,
  width: 18,
});

const closeActionStyle: StyleFn = () => ({
  paddingRight: 0,
});

const checkIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: 120,
  height: 38,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

export enum DefaultPaymentTypes {
  CASH = 'Cash',
  CARD = 'Card',
  ONLINE = 'Online',
}

const PaymentRow: React.FC<PaymentRowProps> = ({
  paymentType,
  onDeletePayment,
  onChange,
  index,
}: PaymentRowProps) => {
  const { css } = useFela();
  const { showModal } = useModal();
  const { translate } = useTranslation();

  const onPaymentTypePromptChange = () => {
    onChange(index, 'adjustmentPrompt', !paymentType.adjustmentPrompt);
  };

  const isPreDefinedPaymentType = Object.values(DefaultPaymentTypes).includes(
    paymentType.name as DefaultPaymentTypes,
  );

  const onPressDelete = useCallback(
    (index: number, id: string, name: string): void => {
      showModal(
        <DeletePaymentModal
          index={index}
          id={id}
          name={name}
          onDeletePayment={onDeletePayment}
        />,
      );
    },
    [showModal, onDeletePayment],
  );

  return (
    <TableRow
      containerStyle={css(rowStyle)}
      action={
        <IconButton
          icon="TrashAlt"
          iconSize={24}
          containerSize={34}
          containerStyle={
            isPreDefinedPaymentType
              ? css(closeIconGreyContainerStyle)
              : css(closeIconContainerStyle)
          }
          iconStyle={
            isPreDefinedPaymentType ? css(greyedOutStyle) : css(closeIconStyle)
          }
          onPress={(): void =>
            onPressDelete(index, paymentType.id, paymentType.name)
          }
          disabled={isPreDefinedPaymentType}
        />
      }
      actionStyle={css(closeActionStyle)}
    >
      <FormInput
        testID="payment-type-name"
        placeholder={translate('backOfficeSettings.paymentTypeName')}
        value={paymentType.name}
        containerStyle={css(inputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={
          !isPreDefinedPaymentType
            ? onChange.bind(null, index, 'name')
            : undefined
        }
      />

      <View style={css(iconButtonContainerStyle)}>
        <IconButton
          testID="adjustmentPrompt"
          icon={paymentType.adjustmentPrompt ? Icons.Check : ''}
          iconSize={15}
          containerSize={18}
          containerStyle={
            paymentType.adjustmentPrompt
              ? css(checkIconContainer)
              : css(unCheckContainer)
          }
          iconStyle={css(checkIconStyle)}
          onPress={onPaymentTypePromptChange}
        />
      </View>

      {/* <View style={css(iconButtonContainerStyle)}>
        <IconButton
          icon={paymentType.quickPay ? 'check' : ''}
          iconSize={15}
          containerSize={18}
          containerStyle={
            paymentType.quickPay
              ? css(checkIconContainer)
              : css(unCheckContainer)
          }
          iconStyle={css(checkIconStyle)}
          onPress={onChange.bind(
            null,
            index,
            'quickPay',
            !paymentType.quickPay,
          )}
        />
      </View> */}

      {/* <View style={css(iconButtonContainerStyle)}>
        <IconButton
          icon={paymentType.requireCustomer ? 'check' : ''}
          iconSize={15}
          containerSize={18}
          containerStyle={
            paymentType.requireCustomer
              ? css(checkIconContainer)
              : css(unCheckContainer)
          }
          iconStyle={css(checkIconStyle)}
          onPress={onChange.bind(
            null,
            index,
            'requireCustomer',
            !paymentType.requireCustomer,
          )}
        />
      </View>

      <View style={css(iconButtonContainerStyle)}>
        <IconButton
          icon={paymentType.adjustmentPrompt ? 'check' : ''}
          iconSize={15}
          containerSize={18}
          containerStyle={
            paymentType.adjustmentPrompt
              ? css(checkIconContainer)
              : css(unCheckContainer)
          }
          iconStyle={css(checkIconStyle)}
          onPress={onChange.bind(
            null,
            index,
            'adjustmentPrompt',
            !paymentType.adjustmentPrompt,
          )}
        />
      </View> */}
    </TableRow>
  );
};

export const PaymentsTypes: React.FC = () => {
  const { css } = useFela();
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const { updateOnboardingStatus } = useOnboarding();
  const [paymentTypesData, setPaymentTypesData] = useState([] as PaymentType[]);

  const { paymentTypes, status, refetch } = usePaymentTypes();

  const [updatePaymentTypes, updatePaymentRequest] = useMutation(
    UPDATE_PAYMENT_TYPES,
    {
      onError: noopHandler,
    },
  );

  useEffect((): void => {
    if (updatePaymentRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(updatePaymentRequest.error),
      });
    }
  }, [updatePaymentRequest.error, showNotification]);

  useEffect((): void => {
    if (updatePaymentRequest.data) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeSettings.paymentTypesUpdatedSuccessfully',
        ),
      });
      refetch && refetch();
    }
  }, [updatePaymentRequest.data, showNotification, translate, refetch]);

  useEffect((): void => {
    if (status.error) {
      showNotification({
        error: true,
        message: status.error,
      });
    }
  }, [status.error, showNotification]);

  useEffect((): void => {
    if (paymentTypes.length) {
      setPaymentTypesData(
        (paymentTypes as PaymentTypeDisplay[]).map(x =>
          stripProperties(x, 'label', 'value'),
        ),
      );
    }
  }, [paymentTypes]);

  useEffect(() => {
    updateOnboardingStatus(
      OnboardingArea.SETTINGS,
      OnboardingSection.VIEW_ADD_PAYMENT_TYPE,
      OnboardingCheckList.PAYMENT_TYPES,
      OnboardingAction.READ,
    );
  }, [updateOnboardingStatus]);

  const onAddPayment = useCallback(
    (paymentType: PaymentType): void => {
      const paymentTypesTempData = [...paymentTypesData];
      paymentTypesTempData.push(paymentType);
      setPaymentTypesData(paymentTypesTempData);
    },
    [paymentTypesData],
  );

  const onDeletePayment = useCallback(
    (index: number, id: string): void => {
      const paymentTypesDataTemp = [...paymentTypesData];
      if (paymentTypesDataTemp[index].id === id) {
        paymentTypesDataTemp.splice(index, 1);
        setPaymentTypesData(paymentTypesDataTemp);
      }
    },
    [paymentTypesData],
  );

  const onCreatePayment = useCallback((): void => {
    showModal(
      <CreatePaymentModal onAddPayment={onAddPayment} />,
      translate('backOfficeSettings.createPaymentType'),
    );
  }, [showModal, onAddPayment, translate]);

  const onPressSave = useCallback((): void => {
    const input = stripProperties(
      paymentTypesData,
      '__typename',
    ) as PaymentType[];

    const inputMapped = input.map(paymentType => {
      paymentType.adjustmentPrompt = Boolean(paymentType.adjustmentPrompt);
      return paymentType;
    });

    if (input.some(paymentType => !paymentType.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeDevices.fieldsMissing'),
      });
    } else {
      updatePaymentTypes({
        variables: { input: inputMapped as UpdatePaymentTypeInput[] },
      });
    }
  }, [paymentTypesData, updatePaymentTypes, showNotification, translate]);

  const onChange = useCallback(
    (index: number, prop, value): void => {
      const formDataTemp = paymentTypesData;
      formDataTemp[index] = { ...formDataTemp[index], [prop]: value };
      setPaymentTypesData([...formDataTemp]);
    },
    [paymentTypesData],
  );

  const COLUMNS = useMemo(
    () => [
      {
        title: translate('backOfficeSettings.paymentTypeName'),
        width: 300,
      },
      // {
      //   title: translate('backOfficeSettings.quickPayButton'),
      //   width: 150,
      // },
      // {
      //   title: translate('backOfficeSettings.requireCustomer'),
      //   width: 150,
      // },
      {
        title: translate('backOfficeSettings.promptAdjustment'),
        width: 300,
      },
      { width: 44 },
    ],
    [translate],
  );

  return (
    <>
      <View style={css(pageStyle)}>
        {status.loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <View style={css(mainView)}>
              <ScrollView
                testID="general-settings-screen"
                contentContainerStyle={css(generalSettingsContainerWrapper)}
              >
                <BackOfficeSection
                  title={translate('backOfficeSettings.paymentTypes')}
                  titleDescription={translate(
                    'backOfficeSettings.paymentTypesDescription',
                  )}
                  containerStyle={css(pageStyle)}
                  action={
                    <BackOfficeCreateNewButton onPress={onCreatePayment} />
                  }
                >
                  <TableComponent
                    columns={COLUMNS}
                    data={paymentTypesData}
                    columnContainerStyle={css(columnContainerStyle)}
                    renderRow={(
                      item: PaymentType,
                      index: number,
                    ): React.ReactNode => (
                      <PaymentRow
                        key={item.id}
                        paymentType={item}
                        onDeletePayment={onDeletePayment}
                        onChange={onChange}
                        index={index}
                      />
                    )}
                  />
                </BackOfficeSection>
                <View style={css(bottomSpace)}></View>
              </ScrollView>
            </View>
            <View style={css(actionsContainerStyle)}>
              <Button
                fluid
                testID="save-changes"
                title={translate('button.saveChanges')}
                containerStyle={css(saveButtonStyle)}
                labelStyle={css(titleStyle)}
                onPress={onPressSave}
                disabled={updatePaymentRequest.loading}
              />
            </View>
          </>
        )}
      </View>
    </>
  );
};
