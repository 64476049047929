import React, { useCallback, useEffect, useMemo } from 'react';
import { useNotification } from '../../../../../hooks/Notification';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { useDevices } from '../../../../../hooks/app/useDevices';
import DevicesList from './UIComponents/DevicesList';
import { useSession } from '../../../../../hooks/app/useSession';
import { CreateDeviceInput, Device } from '@hitz-group/domain';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
export interface DeviceData extends Device {
  isCurrent?: boolean;
}

export const DevicesListContainer: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const isFocused = useIsFocused();
  const params = route.params as {
    storeId: string;
    venueId: string;
  };

  const onCreateDeviceCompleted = useCallback(
    deviceId => {
      showNotification({
        success: true,
        message: translate('backOfficeDeviceModal.successfullySaved'),
      });
      closeModal();
      navigation.navigate('DeviceSettings', {
        storeId: params.storeId,
        deviceId: deviceId,
      });
    },
    [closeModal, navigation, params.storeId, showNotification, translate],
  );

  const { devices, createDevice, getDevices, createdDeviceId, loading, error } =
    useDevices({
      storeId: params?.storeId,
      onCreateDeviceCompleted,
    });

  const onPressCreateDevice = useCallback(
    (newDevice: CreateDeviceInput) => {
      createDevice(newDevice);
    },
    [createDevice],
  );

  const [session] = useSession();
  useEffect(() => {
    if (params?.storeId && isFocused) {
      getDevices();
    }
  }, [getDevices, isFocused, params?.storeId]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const devicesData = useMemo(
    () =>
      Object.values(devices).map(device => ({
        ...device,
        isCurrent: session.device && session.device.id === device.id,
      })),
    [devices, session],
  );

  return (
    <DevicesList
      onPressCreateDevice={onPressCreateDevice}
      devices={devicesData}
      options={{ loading, error, createdDeviceId }}
      storeId={params?.storeId}
      venueId={params?.venueId}
    />
  );
};

export default DevicesListContainer;
