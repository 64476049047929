import { StyleFn } from '@hitz-group/domain';
import { isAndroid } from '../../../common/theme';

export const headerStyle: StyleFn = () => ({
  width: 350,
  height: 44,
  alignSelf: 'center',
  marginHorizontal: 8,
});

export const cancelButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 10,
});

export const modalContainer: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

export const modalStyle: StyleFn = ({ theme }) => ({
  width: 350,
  borderRadius: 6,
  justifyContent: 'flex-start',
  margin: 0,
  paddingTop: 0,
  paddingBottom: theme.spacing.small,
});

export const refundOrderButtonContainer: StyleFn = () => ({
  width: '100%',
  marginHorizontal: 0,
  height: 44,
  marginBottom: isAndroid && 10,
});
export const refundOrderText: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
});

export const customBodyStyle: StyleFn = ({ theme }) => ({
  paddingVertical: 0,
  marginTop: theme.spacing.small,
});

export const horizontalLine: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 1,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.colors.boxBorder,
  marginTop: theme.spacing.small,
  marginBottom: 5,
});

export const unSelectAllButtonContainer: StyleFn = ({ theme }) => ({
  width: '100%',
  marginHorizontal: 0,
  height: 44,
  backgroundColor: theme.colors.skyBlue,
  marginTop: theme.spacing.small,
});

export const unSelectAllButtonLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});
