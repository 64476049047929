import { App, StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { Subscription } from 'rxjs/internal/Subscription';
import Gradient from '../../../components/Gradient/Gradient';
import Layout from '../../../components/POSLayout/POSLayout';
import { useOrders } from '../../../hooks/app/orders/useOrders';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import usePOSUserRoles from '../../../hooks/app/users/usePOSUserRoles';
import { useSession } from '../../../hooks/app/useSession';
import useBehaviorSubjectState from '../../../hooks/app/useSubjectState';
import { useWorkLogEvents } from '../../../hooks/app/worklog/useWorkLogEvents';
import { useNotification } from '../../../hooks/Notification';
import { lastActiveTimeSubject } from '../../../state/lastActiveTime';
import { OfficeUser, PosUser, userUtility } from '../../../state/userUtility';
import { AppScreen } from '../../../types/AppScreen';
import Loading from '../../Loading/Loading';
import ActiveUsers from './ActiveUsers';
import OfficeUserLogin from './OfficeUserLogin';
import PosUserPin from './PosUserPin';

const screen: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

enum UserActiveScreen {
  OFFICE_USERS = 'office users',
  OFFICE_USER_LOGIN = 'office user login',
  OFFICE_NEW_USER_LOGIN = 'office new user login',
  POS_USERS = 'pos users',
  POS_USER_LOGIN = 'pos user login',
}

export const LockScreen: React.FC = () => {
  const { css, theme } = useFela();
  const [session] = useSession();
  const navigation = useNavigation();
  const { defaultSaleScreen } = session.deviceProfile || {};
  const {
    fetchStoreUsers,
    loading,
    error,
    users: posUsers,
  } = usePOSUserRoles();
  const { showNotification } = useNotification();
  const [currentChosenUser, setCurrentChosenUser] = useState<
    OfficeUser | PosUser
  >();
  const [officeUsers, setOfficeUsers] = useState<OfficeUser[]>([]);
  const route =
    useRoute<
      RouteProp<{ Lock: { app: App; notAutoRedirect: boolean } }, 'Lock'>
    >();
  const [userScreen, setUserScreen] = useState<UserActiveScreen>();
  const { translate } = useTranslation();
  const isFocused = useIsFocused();
  const { refetchOrdersFromServer } = useOrders();
  const { canUserAccessPOSApp } = usePOSUserAuthorization();
  const currentStoreId = session?.currentStore?.id || '';
  const deviceId = session?.device?.id || '';
  const { value: lastActiveTime } = useBehaviorSubjectState(
    lastActiveTimeSubject,
  );

  const { error: worklogSubscriptionError } = useWorkLogEvents({
    currentStoreId,
    deviceId,
    lastActiveTime: lastActiveTime ?? 0,
  });

  const loadParams = useCallback(() => {
    if (route.params && route.params.app && !userScreen) {
      // the default user screen loaded will be based on params or the current active application
      if (route.params.app === App.BACKOFFICE) {
        setUserScreen(UserActiveScreen.OFFICE_USER_LOGIN);
      } else if (route.params.app === App.POS_APP) {
        setUserScreen(UserActiveScreen.POS_USERS);
      }
    } else if (!userScreen) {
      setUserScreen(UserActiveScreen.POS_USERS);
    }
  }, [route.params, userScreen]);

  useEffect(() => {
    if (isFocused) {
      loadParams();
    }
  }, [isFocused, loadParams]);

  useEffect(() => {
    const subscription: Subscription =
      userUtility.retrieveUserActivity$.subscribe(userActivity => {
        setOfficeUsers(Object.values(userActivity.officeUsers));
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [setOfficeUsers]);

  const onPressGotoOffice = useCallback(() => {
    // if same user -> navigate
    // if not same user, navigate to last recent active back office user
    // if no backoffice user at all , do logout!
    setUserScreen(UserActiveScreen.OFFICE_USER_LOGIN);
    setCurrentChosenUser(undefined);
  }, []);

  const onPressGotoOfficeUsers = useCallback(() => {
    // if same user -> navigate
    // if not same user, navigate to last recent active back office user
    // if no backoffice user at all , do logout!
    setUserScreen(UserActiveScreen.OFFICE_USERS);
    setCurrentChosenUser(undefined);
  }, []);

  const onSelectPosUser = useCallback((user: OfficeUser | PosUser) => {
    // To lock screen
    setUserScreen(UserActiveScreen.POS_USER_LOGIN);
    setCurrentChosenUser(user);
  }, []);

  const onSelectGoToPOS = useCallback(() => {
    // To lock screen
    setUserScreen(UserActiveScreen.POS_USERS);
    setCurrentChosenUser(undefined);
  }, []);

  const onPosUserVerificationComplete = useCallback(async () => {
    try {
      refetchOrdersFromServer();
    } catch (err) {
      // error of offline
    }
    setCurrentChosenUser(undefined);
    setUserScreen(undefined);

    const screen = {
      name: defaultSaleScreen ? AppScreen[defaultSaleScreen] : 'Orders',
      params: { showSpinner: true },
    };

    navigation.navigate('Orders', {
      screen,
    });
  }, [defaultSaleScreen, navigation, refetchOrdersFromServer]);

  const onPressNewUserLogin = useCallback(() => {
    // Empty user object for login
    setCurrentChosenUser(undefined);
    setUserScreen(UserActiveScreen.OFFICE_NEW_USER_LOGIN);
  }, []);
  const onSelectedOfficeUser = useCallback((user: OfficeUser | PosUser) => {
    setCurrentChosenUser(user);
    setUserScreen(UserActiveScreen.OFFICE_USER_LOGIN);
  }, []);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const canLoad = useCallback(async () => {
    if (userUtility.posUser !== undefined) {
      if (
        canUserAccessPOSApp(userUtility.posUser.id) &&
        !route.params?.notAutoRedirect
      ) {
        onPosUserVerificationComplete();
      }
    }
  }, [
    onPosUserVerificationComplete,
    canUserAccessPOSApp,
    route.params?.notAutoRedirect,
  ]);

  useEffect(() => {
    canLoad();
  }, [canLoad]);

  const getStoreUsersForCurrentLocation = useCallback(() => {
    if (session?.currentStore?.id) {
      fetchStoreUsers(session.currentStore.id);
    }
  }, [session?.currentStore?.id, fetchStoreUsers]);

  useEffect((): void => {
    if (worklogSubscriptionError) {
      showNotification({
        error: true,
        message: worklogSubscriptionError,
      });
    }
  }, [worklogSubscriptionError, showNotification]);

  useEffect(() => {
    if (isFocused) {
      getStoreUsersForCurrentLocation();
    }
  }, [getStoreUsersForCurrentLocation, isFocused]);

  let displayCard = null;
  if (userScreen === UserActiveScreen.OFFICE_USERS) {
    displayCard = (
      <ActiveUsers
        users={officeUsers}
        onSelectedUser={onSelectedOfficeUser}
        btnAttrs={{
          title: translate('interimLockScreen.office.gotoPos'),
          onPress: onSelectGoToPOS,
        }}
        title={translate('interimLockScreen.office.title')}
        app={App.BACKOFFICE}
        onPressNewUserLogin={onPressNewUserLogin}
      />
    );
  } else if (userScreen === UserActiveScreen.OFFICE_USER_LOGIN) {
    displayCard = (
      <OfficeUserLogin
        onSelectPosUsers={onSelectGoToPOS}
        onPressGotoOfficeUsers={onPressGotoOfficeUsers}
        user={currentChosenUser as OfficeUser}
      />
    );
  } else if (userScreen === UserActiveScreen.OFFICE_NEW_USER_LOGIN) {
    displayCard = (
      <OfficeUserLogin
        onSelectPosUsers={onSelectGoToPOS}
        onPressGotoOfficeUsers={onPressGotoOfficeUsers}
        newLogin
      />
    );
  } else if (userScreen === UserActiveScreen.POS_USERS) {
    displayCard = (
      <ActiveUsers
        users={posUsers}
        onSelectedUser={onSelectPosUser}
        btnAttrs={{
          title: translate('interimLockScreen.pos.gotoOffice'),
          onPress: onPressGotoOffice,
        }}
        title={translate('interimLockScreen.pos.title')}
      />
    );
  } else if (
    userScreen === UserActiveScreen.POS_USER_LOGIN &&
    currentChosenUser
  ) {
    displayCard = (
      <PosUserPin
        onBack={onSelectGoToPOS}
        onVerificationComplete={onPosUserVerificationComplete}
        user={currentChosenUser as PosUser}
      />
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Layout hasHeader={false} useSafeArea={false}>
      <Gradient
        colors={[theme.colors.brandPrimary, theme.colors.brandSecondary]}
        style={css(screen)}
        start={theme.gradient.startAxis}
        end={theme.gradient.endAxis}
        locations={theme.gradient.location}
      >
        {displayCard}
      </Gradient>
    </Layout>
  );
};

export default LockScreen;
