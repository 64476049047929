import {
  StyleFn,
  Product,
  CreateProductInput,
  CreateVariantProductInput,
  ProductPricingInput,
  DEFAULT_PRICING_GROUP,
  UpdateProductInput,
  UpdateVariantInput,
  Currency,
  Money,
  OnboardingArea,
  OnboardingCheckList,
  OnboardingSection,
  OnboardingAction,
} from '@hitz-group/domain';
import { useNavigation } from '@react-navigation/native';
import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View, Text } from 'react-native';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import { useNotification } from '../../../../hooks/Notification';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { useVariants } from '../../../../hooks/app/variants/useVariants';
import { useProducts } from '../../../../hooks/app/products/useProducts';
import { PRODUCT_LIST_SCREEN_FRAGMENT } from '../../../../hooks/app/products/graphql';
import { VARIANTS_LIST_FRAGMENT } from '../../../../hooks/app/variants/graphql';
import { pricingGroupInfoDetails } from '../../../../graphql/pricingGroups';
import { useTaxes } from '../../../../hooks/app/useTaxes';
import { useProductPricings } from '../../../../hooks/app/useProductPricings';
import { usePricingGroups } from '../../../../hooks/app/usePricingGroups';
import { useAvailabilityOptions } from '../../../../hooks/app/useAvailabilityOptions';
import { usePrinterProfiles } from '../../../../hooks/app/usePrinterProfiles';
import { useOnboarding } from '../../../../hooks/app/useOnboarding';
import orderBy from 'lodash/orderBy';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { useModal } from '@hitz-group/rn-use-modal';
import {
  CreateProductModal,
  CreateProductInterface,
} from './CreateProductModal';
import { Operation } from '../../../../types/Operation';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { useProductTypes } from '../../../../hooks/app/useProductTypes';
import DataGrid, {
  columnForDataGrid,
  columnStyleOptions,
} from '../../../../components/DataGrid/DataGrid';
import IconButton from '../../../../components/Button/IconButton';
import Avatar from '../../../../components/Avatar/Avatar';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import {
  ProductHeaderFilters,
  getProductBySearchQuery,
  getProductByFilteredPage,
  getProductByFilteredStore,
} from './ProductHeaderFilters';
import { useIsFocused } from '@react-navigation/native';
import Button from '../../../../components/Button/Button';
import { ProductBulkOptions } from './ProductBulkOptions';
import { CatalogueItem } from './types';
import FormInput from '../../../../components/FormInput/FormInput';
import DropDown from '../../../../components/DropDown/DropDown';
import { isWeb } from '../../../../common/theme';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  paddingVertical: theme.spacing.big,
  paddingHorizontal: theme.padding.large,
  flex: 1,
});

const CreateButtonStyle: StyleFn = () => ({
  width: 140,
  marginLeft: 'auto',
});

const dataItemContainerStyle: StyleFn = ({ theme }) => ({
  flexWrap: 'nowrap',
  borderColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
  borderWidth: 0.8,
  borderRadius: theme.radius.small,
  padding: theme.padding.small,
  height: 34,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  left: 10,
});

const dataItemTextStyleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
  lineHeight: 24,
  letterSpacing: -0.5,
  paddingLeft: theme.padding.medium,
});

const TableNameStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  letterSpacing: 0,
  fontFamily: theme.font.medium,
});
const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});
const iconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
});

const titleActionStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing.medium,
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const backgroundAvatarProductItemStyle: StyleFn = ({ theme }) => ({
  height: theme.spacing.medium + 2,
  width: theme.spacing.medium + 2,
  backgroundColor: theme.colors.purpleLight,
});

const textAvatarProductItemStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.purple,
  backgroundColor: theme.colors.purpleLight,
  padding: theme.spacing.small / 2 + 8,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.bold,
  borderRadius: theme.radius.small,
  lineHeight: 15,
});

const deleteIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});
const deleteIconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger2,
  borderRadius: theme.radius.small,
  right: 5,
});
const sellingIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
});
const sellingIconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successLight,
  borderRadius: theme.radius.small,
});
const editIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});
const successIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
});
const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  marginRight: theme.spacing.big / 1.5,
  height: 17,
  width: 17,
  alignSelf: 'center',
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  marginRight: theme.spacing.big / 1.5,
  height: 17,
  width: 17,
});

const checkBoxTitleContainer: StyleFn = () => ({
  width: 20,
  height: 20,
  justifyContent: 'center',
  alignItems: 'center',
});

const checkBoxUnSelected: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  height: 17,
  width: 17,
});

const checkBoxSelected: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  height: 17,
  width: 17,
});

const checkBoxSelectedVariant: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.blue,
  borderWidth: 2,
  height: 17,
  width: 17,
});

const actionContainerStyle: StyleFn = () => ({
  zIndex: 2000,
});

const contentContainerStyle: StyleFn = () => ({
  zIndex: 1000,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: isWeb ? 200 : 150,
  height: theme.input.height,
  left: isWeb ? 5 : 0,
  bottom: isWeb ? 10 : 0,
});

const priceFormInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 90,
  height: theme.input.height,
  bottom: isWeb ? 10 : 0,
  left: isWeb ? 10 : 0,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

const dropdownContainerStyle: StyleFn = ({ theme }) => ({
  width: 110,
  height: theme.input.height,
  left: isWeb ? 7 : 0,
});

const dropdownContainerStyleProductType: StyleFn = ({ theme }) => ({
  width: 165,
  height: theme.input.height,
  left: isWeb ? 7 : 0,
});

const extraPopoverStyleProductType: StyleFn = () => ({
  width: 165,
});

const extraPopoverStyle: StyleFn = () => ({
  width: 110,
});
const touchableDropDownStyle: StyleFn = () => ({
  marginTop: isWeb ? 3 : 0,
});
const itemContainer: StyleFn = () => ({
  height: 38 * 3,
});
const productFilterContainer: StyleFn = () => ({
  flex: 0.8,
});
const createNewProductContainer: StyleFn = () => ({
  flex: 0.2,
});

const dataRowStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height + 20,
  justifyContent: 'center',
});

const iconWrapperStyle: StyleFn = () => ({
  height: '100%',
  justifyContent: 'center',
  paddingBottom: 10,
});
const paginationContainerStyle: StyleFn = () => ({
  marginTop: 10,
});

export type allProductsforDataGridKeys =
  | 'id'
  | 'name'
  | 'productTypeId'
  | 'price'
  | 'tax'
  | 'isSellable'
  | 'isVariant'
  | 'isVariantProduct';

export const getDefaultInfoForProduct = (
  product: Product,
): { price: number; taxId: string; priceId: string; currency: Currency } => {
  const defaultPG = product?.pricingGroups?.filter(
    pricingGroup => pricingGroup.name === DEFAULT_PRICING_GROUP,
  );

  const defaultPrice = defaultPG?.[0]?.['prices'][0];

  return {
    price: defaultPrice?.sellingPrice?.amount || 0,
    priceId: defaultPrice?.id || '',
    taxId: defaultPrice?.sellingTax?.id || '',
    currency: defaultPrice?.sellingPrice?.currency || Currency.AUD,
  };
};

interface CreateStatus {
  isCreatingProduct?: boolean;
  isCreatingVariant?: boolean;
  isCopyMode?: boolean;
  isEditMore?: boolean;
}
export const ProductsList: React.FC = () => {
  const isFocused = useIsFocused();
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { formatCurrency, appendCurrency, unAppendCurrency, currency } =
    useCurrency();
  const { showModal, closeModal } = useModal();
  const { updateOnboardingStatus } = useOnboarding();
  const navigation = useNavigation();
  const [allProductsMap, setAllProductsMap] = useState<{
    [key: string]: CatalogueItem;
  }>({});
  const [searchString, setSearchString] = useState('');
  const [selectedStore, setFilteredStore] = useState('');
  const [selectedPage, setFilteredPage] = useState('');
  const [productPriceAdded, setProductPriceAdded] = useState(false);
  const createStatusRef = useRef<CreateStatus>({});

  const updateCreateStatusRef = useCallback((input: CreateStatus) => {
    createStatusRef.current = {
      ...createStatusRef.current,
      ...input,
    };
  }, []);

  const sortedProducts = useMemo(() => {
    // sorting only products and variants
    const productsArray = Object.values(allProductsMap);
    const sortedNonVarProds = orderBy(
      productsArray.filter(item => item && !item.isVariantProduct),
      ['name'],
      ['asc'],
    );
    const allItems: CatalogueItem[] = [];
    sortedNonVarProds.forEach(item => {
      if (item.isVariant) {
        allItems.push(item);
        item.productIds?.forEach(varProdId => {
          allProductsMap?.[varProdId] &&
            allItems.push(allProductsMap[varProdId]);
        });
      } else {
        allItems.push(item);
      }
    });
    return allItems;
  }, [allProductsMap]);

  const allProductsArray = useMemo(() => {
    let allProducts = sortedProducts;
    if (searchString) {
      allProducts = getProductBySearchQuery(allProducts, searchString);
    }
    if (selectedPage) {
      allProducts = getProductByFilteredPage(allProducts, selectedPage);
    }
    if (selectedStore) {
      allProducts = getProductByFilteredStore(allProducts, selectedStore);
    }
    return allProducts;
  }, [sortedProducts, searchString, selectedPage, selectedStore]);

  const selectedProducts = useMemo(() => {
    return allProductsArray.filter(x => x && x.isSelected);
  }, [allProductsArray]);

  const changedProducts = useMemo(() => {
    return allProductsArray.filter(x => x && x.isChanged);
  }, [allProductsArray]);

  const [createProductForm, setCreateProductForm] =
    useState<CreateProductInterface>();

  const {
    products,
    error: prodErr,
    getAllProducts,
    loading: prodLoading,
    operation: prodOperation,
    createProduct,
    createdProductId,
    updateProducts,
    deleteProducts,
    copyProduct,
  } = useProducts('', PRODUCT_LIST_SCREEN_FRAGMENT);

  const {
    variants,
    error: variantErr,
    getAllVariants,
    createVariant,
    loading: varLoading,
    operation: varOperation,
    createdVariantId,
    updateVariants,
    deleteVariants,
    copyVariant,
  } = useVariants('', VARIANTS_LIST_FRAGMENT);
  const {
    error: errorPP,
    loading: loadingPP,
    operation: PPOperation,
    addProductPricing,
    update: updateProductPricings,
  } = useProductPricings();
  const {
    error: optionsErr,
    loading: optionsLoading,
    salesChannels,
    stores,
    pages,
    refetchAvailabilityData,
  } = useAvailabilityOptions();

  const {
    defaultPricingGroup,
    getAllPricingGroups,
    error: PGErr,
    loading: PricingGroupLoading,
  } = usePricingGroups(undefined, pricingGroupInfoDetails);

  const { taxesOptions, taxes, error: taxesErr } = useTaxes();

  const {
    productTypes,
    getProductTypes,
    error: productTypesErr,
  } = useProductTypes();

  const {
    error: errorPrintProfile,
    loading: loadingPrintProfile,
    printerProfiles,
    getPrinterProfiles,
  } = usePrinterProfiles();

  const error =
    prodErr ||
    variantErr ||
    PGErr ||
    errorPP ||
    taxesErr ||
    productTypesErr ||
    errorPrintProfile;

  const loading =
    optionsLoading ||
    loadingPP ||
    varLoading ||
    prodLoading ||
    loadingPrintProfile ||
    PricingGroupLoading;

  const isVariantOrProductUpdated =
    (prodOperation === Operation.UPDATE && !prodErr && !prodLoading) ||
    (varOperation === Operation.UPDATE && !variantErr && !varLoading);

  const isVariantOrProductDeleted =
    (prodOperation === Operation.DELETE && !prodErr && !prodLoading) ||
    (varOperation === Operation.DELETE && !variantErr && !varLoading);

  const isProductCreated =
    !prodErr && !prodLoading && prodOperation === Operation.CREATE;

  const isProductPricingAdded =
    !errorPP &&
    !loadingPP &&
    PPOperation === Operation.CREATE &&
    productPriceAdded;

  const productTypeOptions = useMemo(() => {
    return Object.values(productTypes).map(x => ({
      label: x.name,
      value: x.id,
    }));
  }, [productTypes]);

  const printerProfileOptions = useMemo(() => {
    return Object.values(printerProfiles).map(x => ({
      label: x.name,
      value: x.id,
    }));
  }, [printerProfiles]);

  const unSelectProducts = useCallback(() => {
    if (selectedProducts.length) {
      setAllProductsMap(prev => {
        const tempPrev = { ...prev };
        selectedProducts.forEach(x => {
          tempPrev[x.id] = {
            ...tempPrev[x.id],
            isSelected: false,
          };
        });
        return tempPrev;
      });
    } else {
      setAllProductsMap(prev => {
        const tempPrev = { ...prev };
        allProductsArray.forEach(x => {
          tempPrev[x.id] = {
            ...tempPrev[x.id],
            isSelected: true,
          };
        });
        return tempPrev;
      });
    }
  }, [selectedProducts, allProductsArray]);

  const columnsforProductTable: columnForDataGrid[] = [
    {
      columnName: (
        <Button
          testID={'title-checkbox'}
          onPress={unSelectProducts}
          fluid
          iconPosition={'left'}
          containerStyle={css(checkBoxTitleContainer)}
          iconContainerStyle={
            selectedProducts.length
              ? css(checkIconContainer)
              : css(unCheckContainer)
          }
          icon={selectedProducts.length ? 'check' : 'null'}
          iconProps={{
            color: theme.colors.success,
            size: 15,
          }}
        />
      ),
      columnDataKey: 'check',
      columnStyle: columnStyleOptions.OPTION,
    },
    {
      columnName: translate('backOfficeProducts.productName'),
      columnDataKey: 'name',
    },
    {
      columnName: translate('backOfficeProducts.productType'),
      columnDataKey: 'productTypeId',
      columnStyle: columnStyleOptions.SMALL,
    },
    {
      columnName: translate('backOfficeProducts.productPrice'),
      columnDataKey: 'price',
      columnStyle: columnStyleOptions.SMALL,
    },
    {
      columnName: translate('backOfficeProducts.productTax'),
      columnDataKey: 'tax',
      columnStyle: columnStyleOptions.SMALL,
    },
    {
      columnName: translate('backOfficeProducts.productSellingConfig'),
      columnDataKey: 'isSellable',
      columnStyle: columnStyleOptions.OPTION,
    },
    {
      columnName: translate('backOfficeProducts.edit'),
      columnDataKey: 'edit',
      columnStyle: columnStyleOptions.OPTION,
    },
    {
      columnName: 'Copy',
      columnDataKey: 'copy',
      columnStyle: columnStyleOptions.OPTION,
    },
    {
      columnName: translate('backOfficeProducts.delete'),
      columnDataKey: 'delete',
      columnStyle: columnStyleOptions.OPTION,
    },
  ];
  const currencySymbol = formatCurrency(0).split('0.00')[0];

  useEffect(() => {
    if (isFocused && PPOperation) {
      getAllProducts();
      getAllVariants();
      getProductTypes();
      getAllPricingGroups();
      getPrinterProfiles();
      refetchAvailabilityData && refetchAvailabilityData();
    }
  }, [
    getAllProducts,
    getAllVariants,
    getProductTypes,
    getAllPricingGroups,
    PPOperation,
    isFocused,
    getPrinterProfiles,
    refetchAvailabilityData,
  ]);

  useEffect(() => {
    if (isVariantOrProductDeleted) {
      showNotification({
        success: true,
        message: translate('productSettings.productDeletedSuccessfully'),
      });
    }
  }, [isVariantOrProductDeleted, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (isVariantOrProductUpdated) {
      showNotification({
        success: true,
        message: translate('productBulkOperations.successfullyUpdated'),
      });
      closeModal();
    }
  }, [isVariantOrProductUpdated, showNotification, translate, closeModal]);

  useEffect(() => {
    // load product data on load
    const variantsList = Object.values(variants);
    const productList = Object.values(products);
    const productsDataInPageMap = {} as {
      [key: string]: CatalogueItem;
    };
    if (productList.length) {
      productList.forEach(prod => {
        const { taxId, price, priceId, currency } =
          getDefaultInfoForProduct(prod);
        productsDataInPageMap[prod.id] = {
          id: prod.id,
          isSellable: prod.isSellable,
          isVariant: false,
          isVariantProduct: false,
          name: prod.name,
          price: price.toString() || '',
          tax: taxId,
          taxName: taxes?.[taxId]?.name || '',
          productTypeName: prod?.productType?.name,
          productTypeId: prod?.productType?.id,
          pageIds: (prod?.pages || []).map(x => x.id),
          storeIds: (prod?.stores || [])?.map(x => x.id),
          defaultPriceId: priceId,
          modifierGroups: prod?.modifierGroups || [],
          isCombo: prod?.isCombo || false,
          currency,
        } as CatalogueItem;
      });
    }
    variantsList.forEach(variant => {
      productsDataInPageMap[variant.id] = {
        id: variant.id,
        isSellable: false,
        isVariant: true,
        isVariantProduct: false,
        name: variant.name,
        price: '',
        productTypeName: variant?.productType?.name || '',
        productTypeId: variant?.productType?.id,
        tax: '',
        taxName: '',
        pageIds: (variant?.pages || []).map(x => x.id),
        isSelected: false,
        storeIds: (variant?.stores || [])?.map(x => x.id),
      };
      const productIds: string[] = [];
      variant.products.forEach(variantProduct => {
        // all products were added to productsDataInPage so we are checking which product is variant product
        productIds.push(variantProduct.id);
        if (productsDataInPageMap[variantProduct.id]) {
          productsDataInPageMap[variantProduct.id] = {
            ...productsDataInPageMap[variantProduct.id],
            isVariantProduct: true,
            // variant products making available for filtering
            storeIds: productsDataInPageMap[variant.id].storeIds,
            pageIds: productsDataInPageMap[variant.id].pageIds,
          };
        }
      });
      // add product ids to variant
      productsDataInPageMap[variant.id] = {
        ...productsDataInPageMap[variant.id],
        productIds: productIds,
      };
    });
    setAllProductsMap(productsDataInPageMap);
  }, [variants, products, currencySymbol, taxes]);

  useEffect(() => {
    const { isCreatingProduct, isCopyMode, isEditMore } =
      createStatusRef.current;
    if (
      isCreatingProduct &&
      createdProductId &&
      (isProductPricingAdded || isCopyMode)
    ) {
      updateCreateStatusRef({ isCreatingProduct: false });
      setProductPriceAdded(false);
      showNotification({
        success: true,
        message: translate('productSettings.productSuccessfullyAdded', {
          name: products[createdProductId]?.name,
        }),
      });
      updateOnboardingStatus(
        OnboardingArea.CATALOGUES,
        OnboardingSection.ADD_A_PAGE_AND_PRODUCT,
        OnboardingCheckList.PRODUCTS,
        OnboardingAction.CREATE,
      );
      if (isEditMore && createdProductId) {
        // redirect to variant settings for variant
        navigation.navigate('ProductSettings', {
          productId: createdProductId,
        });
      }
    }
  }, [
    isProductPricingAdded,
    showNotification,
    navigation,
    createdProductId,
    products,
    translate,
    isProductCreated,
    updateOnboardingStatus,
    updateCreateStatusRef,
  ]);

  useEffect(() => {
    const { isCopyMode } = createStatusRef.current;
    if (isProductCreated && createProductForm && !isCopyMode) {
      if (defaultPricingGroup && defaultPricingGroup.id && createdProductId) {
        addProductPricing(createdProductId, [
          {
            pricingGroupId: defaultPricingGroup.id,
            productPricing: {
              taxInclusive: createProductForm?.taxInclusive,
              sellingPrice: createProductForm?.sellingPrice,
              sellingTax: createProductForm?.sellingTax,
            } as ProductPricingInput,
          },
        ]);
        setProductPriceAdded(true);
      } else if (!defaultPricingGroup && createdProductId) {
        showNotification({
          error: true,
          message: translate('productSettings.defaultPricingGroupMissing'),
        });
      }
    }
  }, [
    createProductForm,
    createdProductId,
    defaultPricingGroup,
    addProductPricing,
    isProductCreated,
    showNotification,
    translate,
  ]);

  useEffect(() => {
    const { isCreatingVariant, isCopyMode, isEditMore } =
      createStatusRef.current;
    if (
      createdVariantId &&
      isCreatingVariant &&
      !variantErr &&
      !varLoading &&
      varOperation === Operation.CREATE
    ) {
      updateCreateStatusRef({ isCreatingVariant: false });
      closeModal();
      showNotification({
        success: true,
        message: translate('productSettings.variantSuccessfullyAdded', {
          name: variants[createdVariantId].name,
        }),
      });
      if (isEditMore && createdVariantId) {
        // redirect to variant settings for variant
        navigation.navigate('ProductSettings', {
          productId: createdVariantId,
          isVariant: true,
          screen: 'Variations',
        });
      } else if (isCopyMode) {
        getAllProducts();
      }
    }
  }, [
    variantErr,
    varLoading,
    showNotification,
    varOperation,
    closeModal,
    createdVariantId,
    navigation,
    variants,
    translate,
    getAllProducts,
    updateCreateStatusRef,
  ]);

  const deleteCatalogueItem = useCallback(
    CatalogueItemId => {
      const item = allProductsMap[CatalogueItemId];
      const selectedProds: string[] = [];
      const selectedVars: string[] = [];
      if (item?.isVariant) {
        item.productIds?.forEach(eachProd => {
          allProductsMap?.[eachProd] &&
            selectedProds.push(allProductsMap[eachProd].id);
        });
        selectedVars.push(item.id);
      } else {
        allProductsMap?.[item.id] &&
          selectedProds.push(allProductsMap[item.id].id);
      }

      selectedProds.length && deleteProducts(selectedProds);
      selectedVars.length && deleteVariants(selectedVars);
      closeModal();
    },
    [allProductsMap, closeModal, deleteProducts, deleteVariants],
  );

  const onDeleteProduct = useCallback(
    (itemId: string, itemName: string): void => {
      showModal(
        <ConfirmationModal
          title={translate('backOfficeProducts.deletePopUpPromptHeader')}
          message={translate('backOfficeProducts.deletePopUpPromptBody', {
            productName: itemName,
          })}
          onConfirm={(): void => deleteCatalogueItem(itemId)}
        />,
      );
    },
    [showModal, translate, deleteCatalogueItem],
  );

  const onCreate = useCallback(
    (productInput: CreateProductInterface, editMore: boolean) => {
      if (
        !productInput.isVariant &&
        productInput.name &&
        !optionsLoading &&
        !optionsErr
      ) {
        createProduct({
          name: productInput.name,
          productType: productInput.productType,
          isSellable: true,
          variablePricing: false,
          stores: stores.map(x => x.value),
          salesChannels: salesChannels.map(x => x.value),
          pages: productInput.pages,
        } as CreateProductInput);
        updateCreateStatusRef({ isCreatingProduct: true });
      }

      if (productInput.isVariant && productInput.name) {
        createVariant({
          name: productInput.name,
          productType: productInput.productType,
          stores: stores.map(x => x.value),
          salesChannels: salesChannels.map(x => x.value),
          pages: productInput.pages,
        } as CreateVariantProductInput);
        updateCreateStatusRef({ isCreatingVariant: true });
      }
      setCreateProductForm({ ...productInput });
      updateCreateStatusRef({ isCopyMode: false, isEditMore: editMore });
      closeModal();
    },
    [
      optionsLoading,
      optionsErr,
      updateCreateStatusRef,
      closeModal,
      createProduct,
      stores,
      salesChannels,
      createVariant,
    ],
  );

  const onSelectToggle = useCallback(productId => {
    setAllProductsMap(prev => {
      const tempData = { ...prev };
      const prodOrVariant = prev[productId];
      tempData[productId].isSelected = !prodOrVariant.isSelected;
      if (prodOrVariant.isVariant) {
        // select variant products
        tempData[productId].productIds?.forEach(x => {
          if (tempData?.[x]) {
            tempData[x].isSelected = prodOrVariant.isSelected;
          }
        });
      }
      return tempData;
    });
  }, []);

  const onConfirmCopyProduct = useCallback(
    (originalProduct: CatalogueItem) => {
      const onCreateCopyProduct = (
        productInput: CreateProductInterface,
        editMore: boolean,
      ) => {
        const {
          name,
          productType = '',
          pages = [],
          sellingPrice,
          sellingTax,
        } = productInput;

        if (originalProduct.isVariant) {
          copyVariant({
            name,
            productType,
            pages,
            originalVariantId: originalProduct.id,
          });
          updateCreateStatusRef({ isCreatingVariant: true });
        } else {
          copyProduct({
            name,
            pages,
            productType,
            sellingPrice,
            originalProductId: originalProduct.id,
            sellingTax,
            defaultPricingGroupId: defaultPricingGroup.id,
          });
          updateCreateStatusRef({ isCreatingProduct: true });
        }
        closeModal();
        updateCreateStatusRef({ isCopyMode: true, isEditMore: editMore });
      };
      showModal(
        <CreateProductModal
          onCreate={onCreateCopyProduct}
          taxesOptions={taxesOptions}
          selectedProduct={{
            name: '',
            sellingPrice: {
              amount: Number(originalProduct.price),
              currency: originalProduct.currency || Currency.AUD,
            },
            pages: originalProduct.pageIds,
            isVariant: originalProduct.isVariant,
            sellingTax: originalProduct.tax,
            productType: originalProduct.productTypeId,
          }}
        />,
      );
    },
    [
      closeModal,
      copyProduct,
      copyVariant,
      defaultPricingGroup?.id,
      showModal,
      taxesOptions,
      updateCreateStatusRef,
    ],
  );

  const onCopyProduct = useCallback(
    (selectedProduct: CatalogueItem) => {
      showModal(
        <ConfirmationDialog
          title={translate('dialog.copyTitle')}
          message={translate('dialog.copyProduct', {
            label: selectedProduct.name,
          })}
          onConfirm={() => onConfirmCopyProduct(selectedProduct)}
        />,
      );
    },
    [onConfirmCopyProduct, showModal, translate],
  );

  const renderOptionItems = useCallback(
    (item: CatalogueItem, columnDataKey: string): React.ReactNode => {
      const optionConfig = {
        disabled: false,
        icon: '',
        onPress: (): void => {
          null;
        },
        iconStyle: {},
        containerStyle: {},
      };
      const EditProduct = (): void =>
        navigation.navigate('ProductSettings', {
          productId: item.id,
          isVariant: item.isVariant,
          isVariantProduct: item.isVariantProduct,
          title: item.name,
        });

      const onPressSelect = (): void => {
        onSelectToggle(item.id);
      };

      const DeleteProduct = (): void => {
        onDeleteProduct(item.id, item.name);
      };

      let iconForIsSelected = '';
      let containerWhenSelected = checkBoxUnSelected;
      let iconStyleWhenSelected = editIconStyle;

      if (item?.isVariant && item?.isSelected) {
        iconForIsSelected = 'minus';
        containerWhenSelected = checkBoxSelectedVariant;
      } else if (item?.isSelected) {
        iconForIsSelected = 'check';
        containerWhenSelected = checkBoxSelected;
        iconStyleWhenSelected = successIconStyle;
      }

      switch (columnDataKey) {
        case 'check':
          optionConfig.icon = iconForIsSelected;
          optionConfig.onPress = onPressSelect;
          optionConfig.iconStyle = iconStyleWhenSelected;
          optionConfig.containerStyle = containerWhenSelected;
          break;
        case 'edit':
          optionConfig.icon = 'Pen';
          optionConfig.onPress = EditProduct;
          optionConfig.iconStyle = editIconStyle;
          optionConfig.containerStyle = iconContainerStyle;
          break;
        case 'copy':
          if (item.isVariantProduct) return;
          optionConfig.icon = 'Copy';
          optionConfig.onPress = () => onCopyProduct(item);
          optionConfig.iconStyle = editIconStyle;
          optionConfig.containerStyle = iconContainerStyle;
          break;
        case 'delete':
          optionConfig.icon = 'TrashAlt';
          optionConfig.onPress = DeleteProduct;
          optionConfig.iconStyle = deleteIconStyle;
          optionConfig.containerStyle = deleteIconContainerStyle;
          break;
        case 'isSellable':
          optionConfig.icon = item.isSellable ? 'CheckSquare' : 'Square';
          optionConfig.iconStyle = sellingIconStyle;
          optionConfig.containerStyle = sellingIconContainerStyle;
          optionConfig.disabled = true;
      }
      return (
        <View style={css(iconWrapperStyle)}>
          <IconButton
            disabled={optionConfig.disabled}
            icon={optionConfig.icon}
            iconSize={24}
            containerSize={34}
            containerStyle={css(optionConfig.containerStyle)}
            iconStyle={css(optionConfig.iconStyle)}
            onPress={optionConfig.onPress}
          />
        </View>
      );
    },
    [css, navigation, onCopyProduct, onDeleteProduct, onSelectToggle],
  );

  const saveProducts = useCallback(() => {
    const updateInputProduct: UpdateProductInput[] = [];
    const updateInputVariant: UpdateVariantInput[] = [];
    const updateProductPricingsInput: ProductPricingInput[] = [];
    const productsChanged = changedProducts.filter(x => x && !x.isVariant);
    const variantsChanged = changedProducts.filter(x => x && x.isVariant);

    productsChanged.forEach(eachChangedProd => {
      // update product input
      updateInputProduct.push({
        id: eachChangedProd.id,
        name: eachChangedProd.name,
        productType: eachChangedProd.productTypeId,
      } as UpdateProductInput);
      // update product pricing input
      updateProductPricingsInput.push({
        id: eachChangedProd.defaultPriceId,
        sellingPrice: {
          amount: +unAppendCurrency(eachChangedProd.price),
          currency: currency as Currency,
        } as Money,
        sellingTax: eachChangedProd.tax,
        pricingGroupId: defaultPricingGroup?.id,
        product: eachChangedProd.id,
      } as unknown as ProductPricingInput);
    });
    variantsChanged.forEach(eachChangedVariant => {
      // update variant input
      updateInputVariant.push({
        id: eachChangedVariant.id,
        name: eachChangedVariant.name,
        productType: eachChangedVariant.productTypeId,
      } as UpdateVariantInput);
    });
    updateInputProduct.length && updateProducts(updateInputProduct);
    updateInputVariant.length && updateVariants(updateInputVariant);
    updateProductPricingsInput.length &&
      updateProductPricings(updateProductPricingsInput);
  }, [
    changedProducts,
    updateVariants,
    updateProducts,
    currency,
    defaultPricingGroup,
    unAppendCurrency,
    updateProductPricings,
  ]);

  const onChangeOfItem = useCallback(
    (id, prop, value) => {
      if (prop === 'price') {
        value = unAppendCurrency(value);
      }
      setAllProductsMap(prevMap => {
        return {
          ...prevMap,
          [id]: { ...prevMap[id], [prop]: value, isChanged: true },
        };
      });
    },
    [unAppendCurrency],
  );

  const renderNameField = useCallback(
    (item: CatalogueItem) => {
      let prefixText = 'P';
      if (item.isVariant) prefixText = 'V';
      else if (item.isCombo) prefixText = 'C';

      return (
        <FormInput
          testID="modifier-name"
          placeholder={translate('modifiers.modifierName')}
          value={item['name'] as string}
          containerStyle={css(formInputContainerStyle)}
          onChangeText={onChangeOfItem.bind(null, item.id, 'name')}
          textStyle={css(formTextStyle)}
          selection={{ start: item['name']?.toString().length, end: 0 }}
          prefix={{
            text: prefixText,
            textStyle: css(textAvatarProductItemStyle),
          }}
        />
      );
    },
    [css, translate, onChangeOfItem],
  );

  const renderProductType = useCallback(
    (item: CatalogueItem) => {
      return (
        <DropDown
          options={[{ label: 'Select Product Type', value: '' }].concat(
            productTypeOptions,
          )}
          selectedValue={item.productTypeId}
          containerStyle={css(dropdownContainerStyleProductType)}
          itemsContainerStyle={css(itemContainer)}
          onValueChange={onChangeOfItem.bind(null, item.id, 'productTypeId')}
          touchableStyle={css(touchableDropDownStyle)}
          extraPopoverStyle={css(extraPopoverStyleProductType)}
        />
      );
    },
    [css, productTypeOptions, onChangeOfItem],
  );

  const renderTax = useCallback(
    (item: CatalogueItem) => {
      return (
        <DropDown
          options={taxesOptions}
          selectedValue={item.tax}
          containerStyle={css(dropdownContainerStyle)}
          itemsContainerStyle={css(itemContainer)}
          onValueChange={onChangeOfItem.bind(null, item.id, 'tax')}
          touchableStyle={css(touchableDropDownStyle)}
          extraPopoverStyle={css(extraPopoverStyle)}
        />
      );
    },
    [css, taxesOptions, onChangeOfItem],
  );

  const renderPriceField = useCallback(
    (item: CatalogueItem) => {
      return (
        <FormInput
          testID="modifier-name"
          placeholder={translate('backOfficeProducts.productPrice')}
          value={appendCurrency(item['price'] as string)}
          containerStyle={css(priceFormInputContainerStyle)}
          onChangeText={value => onChangeOfItem(item.id, 'price', value)}
          textStyle={css(formTextStyle)}
        />
      );
    },
    [css, translate, onChangeOfItem, appendCurrency],
  );

  const renderDataItem = useCallback(
    (
      item: CatalogueItem,
      columnDataKey: string,
      columnStyle?: string,
    ): React.ReactNode => {
      const hasAvatar =
        columnDataKey == 'name' && !item?.isVariantProduct ? true : false;
      if (columnDataKey === 'name') {
        return renderNameField(item);
      } else if (columnDataKey === 'productTypeId') {
        return renderProductType(item);
      } else if (columnDataKey === 'tax' && !item.isVariant) {
        // renders tax drop down only if it is not a variant
        return renderTax(item);
      } else if (columnDataKey === 'price' && !item.isVariant) {
        // renders price text input field drop down only if it is not a variant
        return renderPriceField(item);
      }
      return (
        <View
          style={css(dataItemContainerStyle, {
            width: columnStyle == columnStyleOptions.SMALL ? 90 : 180,
          })}
        >
          {hasAvatar && (
            <Avatar
              containerStyle={css(backgroundAvatarProductItemStyle)}
              textStyle={css(textAvatarProductItemStyle)}
              name={item.isVariant ? 'Variant' : 'Product'}
              onlySingleCharacter
            />
          )}
          <Text numberOfLines={1} style={css(dataItemTextStyleStyle)}>
            {item[columnDataKey as allProductsforDataGridKeys]}
          </Text>
        </View>
      );
    },
    [css, renderNameField, renderProductType, renderTax, renderPriceField],
  );

  const onCreateNewProduct = useCallback(() => {
    showModal(
      <CreateProductModal onCreate={onCreate} taxesOptions={taxesOptions} />,
    );
  }, [showModal, onCreate, taxesOptions]);

  const bulkOpSuccess = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const deleteSelectedItems = useCallback(() => {
    const selectedProds = selectedProducts.filter(x => x && !x.isVariant);
    const selectedVars = selectedProducts.filter(x => x && x.isVariant);
    // if variant is deleted then its related variant products must also auto delete
    selectedVars.forEach(eachVar => {
      eachVar.productIds?.forEach(eachProd => {
        allProductsMap?.[eachProd] &&
          selectedProds.push(allProductsMap[eachProd]);
      });
    });
    // making delete product ids unique and deleting
    selectedProds.length &&
      deleteProducts([...new Set(selectedProds.map(x => x && x.id))]);
    selectedVars.length && deleteVariants(selectedVars.map(x => x && x.id));
    closeModal();
  }, [
    selectedProducts,
    deleteProducts,
    deleteVariants,
    closeModal,
    allProductsMap,
  ]);

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView>
          <BackOfficeSection
            title={translate('backOfficeProducts.products')}
            titleDescription={translate(
              'backOfficeProducts.productsDescription',
            )}
            customTitleStyle={css(actionContainerStyle)}
            contentContainerStyle={css(contentContainerStyle)}
            titleAction={
              <View style={css(titleActionStyle)}>
                <View style={css(productFilterContainer)}>
                  <ProductHeaderFilters
                    stores={stores}
                    pages={pages}
                    setSearchString={setSearchString}
                    setFilteredStore={setFilteredStore}
                    selectedStore={selectedStore}
                    setFilteredPage={setFilteredPage}
                    selectedPage={selectedPage}
                  />
                </View>
                <View style={css(createNewProductContainer)}>
                  <View style={css(CreateButtonStyle)}>
                    {(!selectedProducts.length && (
                      <BackOfficeCreateNewButton onPress={onCreateNewProduct} />
                    )) || (
                      <ProductBulkOptions
                        selectedCatalogueItems={selectedProducts}
                        defaultPricingGroupId={defaultPricingGroup?.id}
                        taxOptions={taxesOptions}
                        bulkOpSuccess={bulkOpSuccess}
                        productTypeOptions={productTypeOptions}
                        printerProfileOptions={printerProfileOptions}
                        updateProducts={updateProducts}
                        updateVariants={updateVariants}
                        pageOptions={pages}
                        storeOptions={stores}
                        deleteSelectedItems={deleteSelectedItems}
                      />
                    )}
                  </View>
                </View>
              </View>
            }
            titleBorderBottom
          >
            {allProductsArray && allProductsArray.length > 0 && (
              <DataGrid
                data={allProductsArray}
                columns={columnsforProductTable}
                renderOptionItems={renderOptionItems}
                dataRowStyle={isWeb ? {} : css(dataRowStyle)}
                greyPagination
                renderHeaderItem={(item: string): React.ReactNode => {
                  return (
                    <View>
                      <Text style={css(TableNameStyle)}>{item}</Text>
                    </View>
                  );
                }}
                renderDataItem={renderDataItem}
                paginationContainerStyle={css(paginationContainerStyle)}
              />
            )}
          </BackOfficeSection>
        </ScrollView>
      </View>
      {(changedProducts.length && (
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={saveProducts}
          />
        </View>
      )) ||
        null}
    </>
  );
};
