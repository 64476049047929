import { View, Text, ScrollView } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { useModifierGroups } from '../../../../../hooks/app/modifierGroups/useModifierGroups';
import { useModifiers } from '../../../../../hooks/app/modifiers/useModifiers';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import { useFela } from 'react-fela';
import MultipleSearchAdd from '../../../../../components/MultipleSelect/MultipleSearchAdd';
import {
  ProductModifierGroup,
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  ModifierGroup,
  Modifier,
  UpdateProductInput,
  StyleFn,
  DEFAULT_OPTION_GROUP,
  ModifierType as ModifierTypeAlias,
  ProductAsModifier,
} from '@hitz-group/domain';
import orderBy from 'lodash/orderBy';
import { Dictionary, findIndex, keyBy } from 'lodash';
import { ProductModifierRow } from './ProductModifierRow';
import Button from '../../../../../components/Button/Button';
import { Operation } from '../../../../../types/Operation';
import DraggableFlatList from '../../../../../components/DraggableFlatList/DraggableFlatList';
import { PRODUCT_MODIFIERS_FRAGMENT } from '../../../../../hooks/app/products/graphql';
import { isAndroid, isWeb } from '../../../../../common/theme';

const mainViewStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});
const loadingContainer: StyleFn = () => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const webStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: '100%',
  flex: 1,
});

const scrollStyle: StyleFn = () => ({
  flexGrow: 1,
});

const searchStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing.big,
  width: '100%',
});

const zIndexSearchStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing.big,
  zIndex: 2000,
  width: '100%',
});

const emptyBoxStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  height: 250,
  justifyContent: 'center',
  alignItems: 'center',
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  marginBottom: theme.spacing.medium,
  zIndex: 1500,
  marginLeft: 0,
  width: '100%',
});

const dropdownStyle: StyleFn = ({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  marginBottom: theme.spacing.medium,
  marginLeft: 0,
  width: '100%',
});

const selectContentStyle: StyleFn = () => ({
  height: 150,
  width: '100%',
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: 600,
  alignSelf: 'center',
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

enum ModifierType {
  Modifier = 'Modifiers',
  ModifierGroup = 'ModifierGroup',
}

enum ProductType {
  Product = 'Product',
}

interface ProductModifierSectionProps {
  productId: string;
}

export const ProductModifiersSection: React.FC<ProductModifierSectionProps> = ({
  productId,
}) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [productModifiers, setProductModifiers] = useState(
    [] as ProductModifierGroup[],
  );
  const updateProductModifiers = (value: ProductModifierGroup[]) => {
    setProductModifiers(value);
  };

  const { currency } = useCurrency();

  const {
    products,
    error: prodErr,
    loading: prodLoading,
    updateProduct,
    operation: prodOperation,
  } = useProducts(productId, PRODUCT_MODIFIERS_FRAGMENT);

  const loading = prodLoading;

  const error = prodErr;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (
      productId &&
      products[productId] &&
      products[productId]?.modifierGroups?.length
    ) {
      setProductModifiers(
        products[productId]?.modifierGroups.filter(x => x && x.id),
      );
    }
  }, [productId, products]);

  const onSave = useCallback(() => {
    const modifierGroups = [] as ProductModifierGroup[];
    const defaultModGroup = {
      isGrouped: false,
      isRequired: false,
      modifierGroup: DEFAULT_OPTION_GROUP,
      priority: 1,
      modifiers: [],
      products: [],
    } as unknown as ProductModifierGroup;
    productModifiers.forEach((modGroup, index) => {
      if (modGroup.name === DEFAULT_OPTION_GROUP) {
        defaultModGroup.products?.push(
          ...modGroup?.products?.map(
            (prod, innerIndex) =>
              ({
                id: prod?.id,
                name: prod?.name,
                isDefault: false,
                type: ModifierTypeAlias.PRODUCT,
                price: {
                  amount: 0,
                  currency: prod?.price?.currency || currency,
                },
                priority: index + innerIndex,
              } as unknown as ProductAsModifier),
          ),
        );
      } else {
        modifierGroups.push({
          isGrouped: modGroup.isGrouped,
          isRequired: modGroup.isRequired,
          modifierGroup: modGroup.id,
          priority: index,
          modifiers: modGroup.modifiers.map(eachMod => ({
            id: eachMod.id,
            isDefault: eachMod.isDefault,
          })),
          products: modGroup?.products?.map(prod => ({
            id: prod.id,
            isDefault: prod.isDefault,
            name: prod.name,
            price: {
              amount: prod.price?.amount,
              currency: prod.price?.currency,
            },
            priority: prod.priority,
            type: prod.type,
          })),
        } as unknown as ProductModifierGroup);
      }
    });
    defaultModGroup.products?.length && modifierGroups.push(defaultModGroup);
    updateProduct({
      id: productId,
      modifierGroups: modifierGroups,
    } as unknown as UpdateProductInput);
  }, [productModifiers, updateProduct, productId, currency]);

  useEffect(() => {
    if (prodOperation === Operation.UPDATE && !error && !loading) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyUpdated'),
      });
    }
  }, [prodOperation, error, loading, showNotification, translate]);

  if (loading) {
    return (
      <View style={css(loadingContainer)}>
        <LoadingIndicator />
      </View>
    );
  }

  return (
    <>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(mainViewStyle)}>
          <BackOfficeSection
            title={translate('modifiers.modifiers')}
            titleDescription={translate(
              'modifiers.productModifiersDiscription',
            )}
            contentContainerStyle={css(backOfficeContainerStyle)}
            containerStyle={css(containerStyle)}
          >
            <ProductModifiers
              productModifiers={productModifiers}
              setProductModifiers={updateProductModifiers}
              productId={productId}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>
      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onSave}
          />
        </View>
      </View>
    </>
  );
};

export interface ProductModifiersProps {
  productModifiers: ProductModifierGroup[];
  setProductModifiers: (value: ProductModifierGroup[]) => void;
  productId?: string;
}

const getModifierGroupToDisplay = (
  item: ProductModifierGroup,
  modifierGroups: { [key: string]: ModifierGroup },
) => {
  if (item.id === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) {
    return {
      id: item.id,
      name: 'Miscellaneous',
    } as ModifierGroup;
  }
  if (modifierGroups[item.id]) return modifierGroups[item.id];
  if (item.name === DEFAULT_OPTION_GROUP) {
    const productAsModifiers = item?.products;
    return {
      id: productAsModifiers?.[0].id,
      name: productAsModifiers?.[0].name,
    } as ModifierGroup;
  }
  return {
    id: item.id,
    name: 'Miscellaneous',
  } as ModifierGroup;
};

export const ProductModifiers: React.FC<ProductModifiersProps> = ({
  productModifiers,
  setProductModifiers,
  productId,
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { currency } = useCurrency();
  const { showNotification } = useNotification();

  const {
    modifiers,
    getAllModifiers,
    error: modErr,
    loading: modLoading,
  } = useModifiers();

  const {
    products,
    error: prodErr,
    getAllProducts,
    loading: prodLoading,
  } = useProducts('', PRODUCT_MODIFIERS_FRAGMENT);

  const {
    modifierGroups,
    getAllModifierGroups,
    error: modGroupErr,
    loading: modGroupLoading,
  } = useModifierGroups();

  const loading = modLoading || modGroupLoading || prodLoading;

  const error = modErr || modGroupErr || prodErr;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    getAllModifiers();
    getAllModifierGroups();
    productId && getAllProducts();
  }, [getAllModifiers, getAllModifierGroups, getAllProducts, productId]);

  const isOnlyProductAsModifier = useMemo(() => {
    return (
      productModifiers?.length > 0 &&
      productModifiers.every(
        mod => mod?.products?.length && mod.modifiers.length === 0,
      )
    );
  }, [productModifiers]);

  const isOnlyModifier = useMemo(() => {
    return (
      productModifiers?.length > 0 &&
      productModifiers.every(
        mod =>
          mod.modifiers.length > 0 &&
          (!mod?.products || mod.products.length === 0),
      )
    );
  }, [productModifiers]);

  const productValues = useMemo(() => {
    return Object.values(products).filter(
      prod => prod.id !== productId && !prod?.isCombo,
    );
  }, [products, productId]);

  const modifierValues = useMemo(() => {
    return Object.values(modifiers);
  }, [modifiers]);

  const modifierGroupValues = useMemo(() => {
    return Object.values(modifierGroups);
  }, [modifierGroups]);

  const modifiersAndModifierGroupsData = useMemo(() => {
    const modifiersOptions = modifierValues.map(x => ({
      value: x.id,
      label: x.name,
      type: ModifierType.Modifier,
      itemTitle: translate('modifiers.modifier'),
    }));
    const modifierGroups = modifierGroupValues
      .filter(modGroup => {
        if (isOnlyProductAsModifier)
          return modGroup.products && modGroup?.products?.length > 0;
        if (isOnlyModifier) return modGroup.modifiers.length > 0;
        return modGroup;
      })
      .map(x => {
        const modifiersNames = x.modifiers.map(
          (x: Modifier) => modifiers[x.id]?.name || '',
        );
        const productNames = x.products?.map(prod => prod.name).join(',');
        const nameModified =
          x.name + `(${modifiersNames.length ? modifiersNames : productNames})`;
        return {
          value: x.id,
          label: nameModified,
          type: ModifierType.ModifierGroup,
          itemTitle: translate('modifiers.optionGroup'),
        };
      });
    const productsOptions = productValues.map(prod => ({
      value: prod.id,
      label: prod.name,
      type: ProductType.Product,
      itemTitle: translate('modifiers.product'),
    }));
    let modifierAndModifierGroups = [];

    if (isOnlyProductAsModifier) {
      modifierAndModifierGroups = [...productsOptions, ...modifierGroups];
    } else if (isOnlyModifier) {
      modifierAndModifierGroups = [...modifiersOptions, ...modifierGroups];
    } else {
      modifierAndModifierGroups = [
        ...modifiersOptions,
        ...modifierGroups,
        ...productsOptions,
      ];
    }
    const result = orderBy(modifierAndModifierGroups, ['label'], ['asc']);
    return result;
  }, [
    modifierValues,
    modifierGroupValues,
    modifiers,
    translate,
    productValues,
    isOnlyModifier,
    isOnlyProductAsModifier,
  ]);

  const dropDownOptionsDictionary = keyBy(
    modifiersAndModifierGroupsData,
    'value',
  );

  const keyByExistingModifiers = useCallback(() => {
    const existingProductModifiersMapTemp = keyBy(
      { ...productModifiers },
      'id',
    );
    delete existingProductModifiersMapTemp?.[
      DEFAULT_PRODUCT_MODIFIER_GROUP_NAME
    ];
    return existingProductModifiersMapTemp;
  }, [productModifiers]);

  const dropDownSelectedValues = useMemo(() => {
    const result: string[] = [];
    if (productModifiers.length) {
      productModifiers.forEach(x => {
        if (x.name === DEFAULT_OPTION_GROUP) {
          result.push(...x?.products?.map(pro => pro.id));
        } else if (x.id !== DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) {
          result.push(x.id);
        } else {
          result.push(...x.modifiers.map(x => x.id));
          result.push(...(x?.products || []).map(pro => pro.id));
        }
      });
    }
    return result;
  }, [productModifiers]);

  const onDeleteRow = useCallback(
    modifierGroupId => {
      const modifierGroupIndex = findIndex(productModifiers, {
        id: modifierGroupId,
      });
      const tempPrev = [...productModifiers];
      if (modifierGroupIndex === -1) {
        tempPrev.forEach(prodMod => {
          prodMod.name === DEFAULT_OPTION_GROUP &&
            (prodMod.products = prodMod?.products.filter(
              pro => pro.id !== modifierGroupId,
            ));
        });
        const emptyProductsIndex = tempPrev.findIndex(
          modifierGroup => modifierGroup.products?.length === 0,
        );
        emptyProductsIndex !== -1 && tempPrev.splice(emptyProductsIndex, 1);
      } else {
        tempPrev.splice(modifierGroupIndex, 1);
      }
      setProductModifiers(tempPrev);
    },
    [productModifiers, setProductModifiers],
  );

  const onChange = useCallback(
    (modifierGroupId: string, prop, value, modifierId) => {
      const modifierGroupIndex = findIndex(productModifiers, {
        id: modifierGroupId,
      });
      if (modifierId) {
        const tempPrev = [...productModifiers];
        const modifiersData = tempPrev[modifierGroupIndex].modifiers;
        const productAsModData = tempPrev[modifierGroupIndex].products;
        const modifierIndex = findIndex(modifiersData, { id: modifierId });
        const productIndex = findIndex(productAsModData, { id: modifierId });
        if (modifierIndex >= 0) {
          // If current changing item is a modifier
          modifiersData[modifierIndex] = {
            id: modifierId,
            isDefault: !modifiersData[modifierIndex].isDefault,
            price: modifiersData[modifierIndex]?.price,
          } as unknown as Modifier;
          tempPrev[modifierGroupIndex].modifiers = modifiersData;
          setProductModifiers(tempPrev);
        } else if (productIndex >= 0) {
          // If current changing item is a product but treat as modifier
          // this prop should be : 'isDefault' and value should be boolean
          productAsModData[productIndex] = {
            ...productAsModData[productIndex],
            [prop]: value,
          };
          tempPrev[modifierGroupIndex].products = productAsModData;
          setProductModifiers(tempPrev);
        }
      } else {
        const tempPrev = [...productModifiers];
        tempPrev[modifierGroupIndex] = {
          ...tempPrev[modifierGroupIndex],
          [prop]: value,
        };
        if (tempPrev[modifierGroupIndex]?.isRequired) {
          // when is Required true then is grouped must also be true

          tempPrev[modifierGroupIndex] = {
            ...tempPrev[modifierGroupIndex],
            isGrouped: true,
          };

          // if modifier is required then it should be moved to top automatically
          const cloneOfReqProdMod = { ...tempPrev[modifierGroupIndex] };
          tempPrev.splice(modifierGroupIndex, 1);
          tempPrev.splice(0, 0, cloneOfReqProdMod);
        }
        setProductModifiers(tempPrev);
      }
    },
    [productModifiers, setProductModifiers],
  );

  const mergeModifierGroups = useCallback(
    (
      existingModifierGroups,
      newModifierGroupId,
      newProductModifiersMap,
      currentMaxPriority,
    ) => {
      const existingValue = { ...existingModifierGroups };
      const modifierGroupData = modifierGroups[newModifierGroupId];
      const existingDetails =
        existingValue?.[newModifierGroupId] ||
        ({
          isGrouped: false,
          isRequired: false,
          modifiers: modifierGroupData.modifiers.map(
            tempMod =>
              ({
                id: tempMod.id,
                name: tempMod.name,
                isDefault: false,
                price: tempMod.price,
              } as Modifier),
          ),
          priority: currentMaxPriority,
        } as ProductModifierGroup);
      newProductModifiersMap[newModifierGroupId] = {
        ...modifierGroupData,
        ...existingDetails,
      } as ProductModifierGroup;
      return newProductModifiersMap;
    },
    [modifierGroups],
  );

  const mergeModifiers = useCallback(
    (
      existingModifiersValues,
      newModifierId,
      newProductModifiersMap,
      currentMaxPriority,
    ) => {
      const existingModifiers = { ...existingModifiersValues };
      const defaultProdModData =
        existingModifiers?.[DEFAULT_PRODUCT_MODIFIER_GROUP_NAME];
      const existingDefaultModData =
        defaultProdModData ||
        ({
          isGrouped: false,
          isRequired: false,
          id: DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
          modifiers: [] as Modifier[],
          priority: currentMaxPriority,
        } as ProductModifierGroup);
      const isModExists = existingDefaultModData?.modifiers?.find(
        (x: Modifier) => x.id === newModifierId,
      );
      if (!isModExists) {
        existingDefaultModData.modifiers.push({
          id: newModifierId,
          isDefault: false,
        } as Modifier);
      }
      newProductModifiersMap[DEFAULT_PRODUCT_MODIFIER_GROUP_NAME] = {
        ...existingDefaultModData,
      } as ProductModifierGroup;
      return newProductModifiersMap;
    },
    [],
  );

  const mapProductAsModifierGroup = useCallback(
    (productIdSelected, newProductModifiersMap, currentMaxPriority) => {
      const product = productValues.find(prod => prod.id === productIdSelected);
      if (productId) {
        if (newProductModifiersMap[productId]) {
          newProductModifiersMap[productId].products.push({
            id: product?.id,
            name: product?.name,
            isDefault: false,
            price: {
              amount: 0,
              currency: currency,
            },
            priority: currentMaxPriority,
          });
        } else {
          const defaultModifierGroup = {
            isGrouped: false,
            isRequired: false,
            id: productId,
            name: DEFAULT_OPTION_GROUP,
            modifiers: [] as Modifier[],
            products: [
              {
                id: product?.id,
                name: product?.name,
                isDefault: false,
                price: {
                  amount: 0,
                  currency: currency,
                },
                priority: currentMaxPriority,
              },
            ],
            priority: 1,
          } as ProductModifierGroup;

          newProductModifiersMap[productId] = {
            ...defaultModifierGroup,
          } as ProductModifierGroup;
        }
      }

      return newProductModifiersMap;
    },
    [productValues, currency, productId],
  );

  // Get the maximum priority in the product modifier group
  const getMaxPriorityFromProductModifiersData = useCallback(
    (existingProductModifiersMap: Dictionary<ProductModifierGroup>) => {
      const maxPriority = Object.values(existingProductModifiersMap).reduce(
        (maxPriority, item) => {
          if (item.name === DEFAULT_OPTION_GROUP) {
            item.products.forEach(prod => {
              if (prod.priority && prod.priority > maxPriority)
                maxPriority = prod.priority;
            });
          } else {
            maxPriority =
              maxPriority > item.priority ? maxPriority : item.priority;
          }
          return maxPriority;
        },
        0,
      );
      return maxPriority;
    },
    [],
  );

  // Get priority map for each mod group data
  const getPriorityMapFromProductModifiersData = useCallback(
    (existingProductModifiersMap: Dictionary<ProductModifierGroup>) => {
      const currPriorityMap: { [key: string]: number } = {};
      Object.values(existingProductModifiersMap).forEach(item => {
        if (item.name === DEFAULT_OPTION_GROUP) {
          item.products.forEach(prod => {
            currPriorityMap[prod.id] = prod?.priority || 0;
          });
        } else {
          currPriorityMap[item.id] = item.priority;
        }
      });
      return currPriorityMap;
    },
    [],
  );

  const onChangeOfDropDown = useCallback(
    (values: string[]) => {
      let existingProductModifiersMap = keyByExistingModifiers();
      const currPriorityMap: { [key: string]: number } =
        getPriorityMapFromProductModifiersData(existingProductModifiersMap);

      const maxPriorityInDataOld = getMaxPriorityFromProductModifiersData(
        existingProductModifiersMap,
      );
      // calculate the biggest priority number when changing the selection
      const biggestPriority =
        values.length > maxPriorityInDataOld
          ? values.length
          : maxPriorityInDataOld + 1;

      let newProductModifiersMap: Dictionary<ProductModifierGroup> = {};
      values.forEach(eachVal => {
        const isProduct =
          dropDownOptionsDictionary[eachVal]?.type === ProductType.Product;
        const isModifiersGroup =
          dropDownOptionsDictionary[eachVal]?.type ===
          ModifierType.ModifierGroup;
        // Save the correct priority number for each item in values
        const savedOrderNum = currPriorityMap[eachVal]
          ? currPriorityMap[eachVal]
          : biggestPriority;
        if (isModifiersGroup) {
          newProductModifiersMap = mergeModifierGroups(
            existingProductModifiersMap,
            eachVal,
            newProductModifiersMap,
            savedOrderNum,
          );
        } else if (isProduct) {
          newProductModifiersMap = mapProductAsModifierGroup(
            eachVal,
            newProductModifiersMap,
            savedOrderNum,
          );
        } else {
          existingProductModifiersMap = mergeModifiers(
            existingProductModifiersMap,
            eachVal,
            newProductModifiersMap,
            savedOrderNum,
          );
        }
      });
      const existingModifiersArray = Object.values(newProductModifiersMap);

      setProductModifiers(existingModifiersArray);
    },
    [
      keyByExistingModifiers,
      setProductModifiers,
      dropDownOptionsDictionary,
      mergeModifierGroups,
      mergeModifiers,
      mapProductAsModifierGroup,
      getPriorityMapFromProductModifiersData,
      getMaxPriorityFromProductModifiersData,
    ],
  );

  const onDragEnd = useCallback(
    (data: Array<ProductModifierGroup>) => {
      // should not able to drag non required modifier on top of required modifiers
      // or always required modifiers should be on top
      let canChange = true;
      const indexesOfRequiredModifiers = data
        .map((x, index) => x && x.isRequired && index)
        .filter(index => !!index);
      data.forEach((eachProdModifier, index) => {
        if (!eachProdModifier.isRequired) {
          indexesOfRequiredModifiers.forEach(reqPriority => {
            if (reqPriority >= index) {
              // do not allow to change
              canChange = false;
              showNotification({
                error: true,
                message: translate('modifiers.dragLimitationError'),
              });
            }
          });
        }
      });

      canChange &&
        setProductModifiers(
          data.map((x, index) => {
            x.name === DEFAULT_OPTION_GROUP &&
              x.products.forEach(pro => (pro.priority = index));
            return { ...x, priority: index };
          }),
        );
    },
    [showNotification, translate, setProductModifiers],
  );

  const productModifierRowData = useMemo(() => {
    const data = [] as unknown as ProductModifierGroup[];
    productModifiers.forEach(proModGroup => {
      if (proModGroup.name === DEFAULT_OPTION_GROUP) {
        data.push(
          ...proModGroup?.products?.map(
            prod =>
              ({
                isGrouped: false,
                isRequired: false,
                id: prod.id,
                name: DEFAULT_OPTION_GROUP,
                modifiers: [] as Modifier[],
                products: [
                  {
                    id: prod?.id,
                    name: prod?.name,
                    isDefault: false,
                    price: {
                      amount: 0,
                      currency: currency,
                    },
                    priority: prod.priority,
                  },
                ],
                priority: prod.priority,
              } as ProductModifierGroup),
          ),
        );
      } else data.push(proModGroup);
    });
    return orderBy(data, ['priority'], ['asc']);
  }, [currency, productModifiers]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <View style={isWeb ? css(webStyle) : css(pageStyle)}>
      <View style={isAndroid ? css(searchStyle) : css(zIndexSearchStyle)}>
        <MultipleSearchAdd
          values={modifiersAndModifierGroupsData}
          selectedValues={dropDownSelectedValues}
          containerStyle={isAndroid ? css(dropdownStyle) : css(dropDownStyle)}
          onValueChange={onChangeOfDropDown}
          searchLabel={translate('modifiers.searchModifiersAndGroupsByName')}
          contentStyle={css(selectContentStyle)}
          scrollEnabled
        />
      </View>
      <ScrollView style={css(scrollStyle)}>
        {productModifiers.length ? (
          <DraggableFlatList
            columns={[
              {
                title: translate('modifiers.modifiers'),
                flex: 1,
                alignItems: 'flex-start',
                containerStyle: { paddingLeft: theme.spacing.big / 2 },
              },
              {
                title: translate('modifiers.default'),
                width: 60,
                alignItems: 'center',
              },
              {
                title: translate('modifiers.group'),
                width: 60,
                alignItems: 'center',
              },
              {
                title: translate('modifiers.required'),
                width: 75,
                alignItems: 'center',
              },
              { title: '', width: 38 },
              { title: '', width: 38 },
            ]}
            normalRows
            columnContainerStyle={css(columnContainerStyle)}
            onDragEnd={(data: Array<ProductModifierGroup>) => onDragEnd(data)}
            data={productModifierRowData}
            renderRow={(
              item: ProductModifierGroup,
              index: number,
              drag: () => void,
            ): React.ReactNode => (
              <ProductModifierRow
                isGrouped={item.isGrouped}
                isRequired={item.isRequired}
                modifiers={item.modifiers.map(x => ({
                  ...modifiers[x.id],
                  isDefault: x.isDefault,
                  price: x.price,
                  priority: x?.priority || index,
                }))}
                isProductAsModifierGroup={item.name === DEFAULT_OPTION_GROUP}
                products={item.products}
                modifierGroup={getModifierGroupToDisplay(item, modifierGroups)}
                onChange={onChange}
                onDeleteRow={onDeleteRow}
                rowIndex={index}
                key={index}
                drag={drag}
              />
            )}
          />
        ) : (
          <View style={css(emptyBoxStyle)}>
            <Text>{translate('modifiers.emptyBoxMsg')}</Text>
          </View>
        )}
      </ScrollView>
    </View>
  );
};
