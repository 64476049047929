import { StyleFn } from '@hitz-group/domain';

export const iconContainerStyle: StyleFn = () => ({
  width: 25,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
});

export const settingsContainerStyle: StyleFn = ({ theme }) => ({
  width: 34,
  height: 34,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  backgroundColor: theme.colors.paletteLightBlue,
  marginLeft: 'auto',
});

export const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.padding.medium / 2,
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

export const defaultContainerStyle: StyleFn = ({ theme, isShrink }) => ({
  width: isShrink ? 196 : 240,
  height: theme.input.height,
  marginTop: theme.spacing.small / 2,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
});

export const formDropDownContainerStyle: StyleFn = ({ theme, isShrink }) => ({
  width: isShrink ? 196 : 224,
  height: theme.input.height,
  marginLeft: theme.spacing.small,
});

export const deActivatedDropDownContainerStyle: StyleFn = ({
  theme,
  isShrink,
}) => ({
  width: isShrink ? 196 : 224,
  height: theme.input.height,
  marginLeft: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
});

export const defaultTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.medium,
  marginTop: 10,
  ...theme.font14RegularCharcoal,
});

export const dropdownExtraStyle: StyleFn = ({ theme, isShrink }) => ({
  backgroundColor: theme.colors.white,
  width: isShrink ? 196 : 224,
  height: theme.input.height,
});

export const dropDownMainViewStyle: StyleFn = ({ theme, isShrink }) => ({
  borderColor: theme.colors.boxBorder,
  width: isShrink ? 196 : 260,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});

export const connectedStatus: StyleFn = ({ theme, active }) => ({
  width: 86,
  backgroundColor: active
    ? theme.colors.greenLightest
    : theme.colors.orangeVeryLight,
  borderRadius: theme.radius.small,
  marginLeft: 10,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
});

export const connectedStatusText: StyleFn = ({ theme, active }) => ({
  textTransform: 'uppercase',
  color: active ? theme.colors.green : theme.colors.orange,
});

export const actionsViewStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  justifyContent: 'center',
});

export const moreDetailStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'flex-end',
  borderRadius: theme.radius.small,
});

export const infoIconStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.paletteLightBlue,
  marginLeft: 10,
  marginRight: 10,
  width: 34,
  height: 34,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
});
