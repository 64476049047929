import { StyleFn, App, Icons } from '@hitz-group/domain';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import IconButton from '../../../../components/Button/IconButton';
import AppAccessButton from './AppAccessButton';

const permissionsHeader: StyleFn = ({ theme }) => ({
  height: 40,
  width: 540,
  marginVertical: theme.padding.small,
  backgroundColor: theme.colors.greyLight,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: theme.radius.small,
  paddingHorizontal: theme.padding.small,
});

interface PermissionSectionProps {
  app: App;
  children: ReactNode;
}

const PermissionSection: FC<PermissionSectionProps> = ({ app, children }) => {
  const [isActive, setIsActive] = useState<boolean>(true);
  const { css, theme } = useFela();

  const toggle = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  return (
    <>
      <View style={css(permissionsHeader)}>
        <AppAccessButton app={app} />
        <IconButton
          testID="expandCollapseIcon"
          icon={isActive ? Icons.AngleRight : Icons.AngleUp}
          iconColor={theme.colors.charcoal}
          onPress={toggle}
        />
      </View>
      <Collapsible collapsed={isActive}>{children}</Collapsible>
    </>
  );
};

export default PermissionSection;
