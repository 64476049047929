import { useCurrency, useTranslation } from '@hitz-group/localization';
import {
  Customer,
  OrderStatus,
  OrderType,
  Product,
  Table,
} from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import TableComponent from '../../components/TableComponent/TableComponent';
import IconButton from '../Button/IconButton';
import Title from '../Title/Title';
import OrderDataRow from './OrderDataRow';
import format from 'date-fns/format';
import Label from '../Label/Label';
import PaginatedView from '../PaginatedView/PaginatedView';
import { useSession } from '../../hooks/app/useSession';
import {
  NotificationMode,
  NotificationType,
  OrderAction,
} from '@hitz-group/domain';
import { v4 as uuidv4 } from 'uuid';
import { useModal } from '@hitz-group/rn-use-modal';
import OrderReceiptActionModal from '../Modals/OrderReceiptAction/OrderReceiptAction';
import { isAndroid, isWeb } from '../../common/theme';
import { useNotification } from '../../hooks/Notification';
import { userUtility } from '../../state/userUtility';
import * as styles from './OrderHistoryView.style';
import PrintActionButton from './PrintActionButton';
import { useSyncOrderEvents } from './../../hooks/app/useSyncOrderEvents';
import { storeOrderEventsInDb } from './../../utils/orderEventHelper';

export interface OrderTypeDisplayProps extends OrderType {
  colorId: number;
}
export interface PaymentTypeDisplay {
  id: string;
  name: string;
  amount: number;
}

export interface OrderHistoryDataProps {
  orderNumber: string;
  id: string;
  orderItems: Array<Partial<Product>>;
  createdAt: number;
  completedAt: number;
  customer: Customer;
  customerName: string;
  orderType: OrderTypeDisplayProps;
  payTypes: Array<PaymentTypeDisplay>;
  totalValue: number;
  table: Table;
  refundOf?: string;
}

export interface OrderDataViewProps {
  data: OrderHistoryDataProps[];
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
  onSelectOrder?: (id: string) => void;
  onPressNext?: (page: number, maxItemsPerPage: number) => void;
  showOrderSidePanel: (id: string) => void;
}

const OrderHistoryView: React.FC<OrderDataViewProps> = ({
  data,
  onPressPrintReceipt,
  onPressNext,
  showOrderSidePanel,
}: OrderDataViewProps) => {
  const { translate } = useTranslation();
  const { css, theme } = useFela();
  const { formatCurrency } = useCurrency();
  const [session] = useSession();
  const { showNotification } = useNotification();
  const { showModal } = useModal();

  const onSyncComplete = () => {
    showNotification({
      success: true,
      message: translate('common.sendSaleReceiptToCustomerSuccess'),
    });
  };

  const { syncOrderEvents } = useSyncOrderEvents(onSyncComplete);

  const sendOrderReceipt = useCallback(
    async (order, email) => {
      if (session && order?.id) {
        const event = {
          action: OrderAction.CUSTOMER_NOTIFICATION,
          orderId: order.id,
          id: uuidv4(),
          notificationType: NotificationType.SEND_RECEIPT,
          notificationMode: NotificationMode.EMAIL,
          organizationId: session.currentOrganization?.id || '',
          venueId: session.currentVenue?.id || '',
          storeId: session.currentStore?.id || '',
          deviceId: session.device?.id || '',
          timestamp: new Date().getTime(),
          triggeredBy: userUtility.userActivity.posUser?.id || '',
          email: email,
        };
        storeOrderEventsInDb([event]);

        syncOrderEvents([event]);
      }
    },
    [session, syncOrderEvents],
  );

  const showPrintModal = useCallback(
    order => {
      if (order) {
        showModal(
          <OrderReceiptActionModal
            order={order}
            sendReceipt={sendOrderReceipt}
            customer={order?.customer}
          />,
        );
      }
    },
    [sendOrderReceipt, showModal],
  );

  const renderOrderHistoryItems = useCallback(
    (items: OrderHistoryDataProps[]) => (
      <TableComponent
        columnSpacing={25}
        columns={[
          {
            title: translate('openOrders.type'),
            width: 56,
            alignItems: 'center',
            containerStyle: { marginRight: 10 },
          },
          {
            title: translate('orderHistory.order'),
            flex: 0.5,
            alignItems: 'center',
          },
          {
            title: translate('orderHistory.completedAt'),
            width: 151,
            alignItems: 'flex-start',
          },
          {
            title: translate('orderHistory.customer'),
            flex: 0.5,
            alignItems: 'flex-start',
          },
          {
            title: translate('orderHistory.payType'),
            width: 100,
            flex: 0.3,
            alignItems: 'flex-start',
          },
          {
            title: translate('orderHistory.totalValue'),
            width: 150,
            alignItems: 'flex-end',
          },
          {
            title: translate('orderHistory.actions'),
            width: isAndroid ? 60 : isWeb ? 45 : 50,
            alignItems: 'center',
          },
          { title: '', width: 10 },
        ]}
        data={items}
        renderRow={(
          item: OrderHistoryDataProps,
          index: number,
        ): React.ReactNode => {
          const isDineInOrder = item.orderType.code === 'D';
          return (
            <OrderDataRow
              key={index}
              canSelect
              containerStyle={css(styles.rowStyle)}
              onPress={() => showOrderSidePanel(item?.id)}
              testID={`order-row-${index}`}
            >
              <View style={css(styles.orderTypeContainer)}>
                {item?.table ? (
                  <Label
                    value={item?.table.name || item?.table.id}
                    colorKey={isDineInOrder ? 6 : 4}
                    textStyle={css(styles.labelText({ theme, isDineInOrder }))}
                    testID={`order-row-${index}-table-number`}
                  />
                ) : (
                  <Label
                    value={item.orderType.code}
                    colorKey={isDineInOrder ? 6 : 4}
                    textStyle={css(styles.labelText({ theme, isDineInOrder }))}
                    testID={`order-row-${index}-order-type`}
                  />
                )}
              </View>
              <View style={css(styles.orderContainer)}>
                <Label
                  value={item.orderNumber}
                  danger={!!item?.refundOf}
                  textStyle={css([
                    styles.labelText,
                    item?.refundOf && styles.refundOrderTextColor,
                  ])}
                  testID={`order-row-${index}-order-number`}
                />
              </View>
              <Title
                containerStyle={css(styles.dateTimeContainer)}
                labelStyle={css(styles.dateTextStyle)}
                numberOfLines={1}
                testID={`order-row-${index}-completed-at`}
              >
                {format(item.completedAt, 'dd.MM.yy hh:mm a')}
              </Title>
              <Title
                containerStyle={css(styles.customerContainer)}
                labelStyle={css(styles.customerTextStyle)}
                numberOfLines={1}
                testID={`order-row-${index}-customer-name`}
              >
                {item.customerName}
              </Title>
              <Title
                containerStyle={css(styles.payTypeContainer)}
                labelStyle={css(styles.payTypeTextStyle)}
                numberOfLines={1}
                testID={`order-row-${index}-pay-type`}
              >
                {item.payTypes.length > 1
                  ? item.payTypes
                      .map(
                        payType =>
                          `${payType.name} (${formatCurrency(payType.amount)})`,
                      )
                      .join(', ')
                  : item.payTypes[0]?.name}
              </Title>
              <Title
                containerStyle={css(styles.totalValueContainer)}
                labelStyle={css([
                  styles.textStyle,
                  item?.refundOf && styles.refundOrderTextColor,
                ])}
                numberOfLines={1}
                testID={`order-row-${index}-total-due`}
              >
                {item?.refundOf && '-'}
                {formatCurrency(item.totalValue)}
              </Title>
              <View style={css(styles.actionsContainer)}>
                <PrintActionButton
                  options={item.payTypes}
                  onPrintReceipt={paymentIndex =>
                    onPressPrintReceipt(item.id, paymentIndex)
                  }
                  orderStatus={OrderStatus.COMPLETED}
                />
                <IconButton
                  key="0"
                  icon="envelopeAlt"
                  color={'paletteLightBlue'}
                  containerSize={35}
                  iconSize={20}
                  iconStyle={{ color: theme.colors.blue }}
                  containerStyle={css(styles.actionIconContainerStyle)}
                  onPress={() => showPrintModal(item)}
                  testID="email-receipt-button"
                />
              </View>
            </OrderDataRow>
          );
        }}
        columnContainerStyle={css(styles.columnContainerStyle)}
        normalRows
      />
    ),
    [
      translate,
      css,
      theme,
      formatCurrency,
      showOrderSidePanel,
      onPressPrintReceipt,
      showPrintModal,
    ],
  );

  return (
    <PaginatedView
      data={data}
      rowHeight={50}
      renderItems={renderOrderHistoryItems}
      onPressNext={onPressNext}
      hideTotalItemsCount
    />
  );
};

export default OrderHistoryView;
