import React from 'react';
import { useTranslation } from '@hitz-group/localization';
import PromptModal from '../../../../../components/PromptModal/PromptModal';

interface DeleteProductsProps {
  onSubmit: () => void;
  countOfProducts: string;
}

export const DeleteProducts: React.FC<DeleteProductsProps> = ({
  onSubmit,
  countOfProducts,
}: DeleteProductsProps) => {
  const { translate } = useTranslation();

  return (
    <PromptModal
      onSubmit={onSubmit}
      title={translate('productBulkOperations.deleteProductsTitle', {
        count: countOfProducts,
      })}
      description={translate('productBulkOperations.deleteProductsDiscription')}
      actionButtonTitle={translate('productBulkOperations.delete')}
    />
  );
};
