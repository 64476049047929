import { Icons, Role, StyleFn, Venue } from '@hitz-group/domain';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import DropDown from '../../../../../../components/FormInput/DropDown';
import DropdownGroup, {
  DropdownGroupOptionsProps,
  DropdownGroupSection,
} from '../../../../../../components/FormInput/DropdownGroup';
import IconButton from '../../../../../../components/Button/IconButton';
import TableRow from '../../../../../../components/TableComponent/TableRow';
import {
  StoreUserRoleMapping,
  StoreUserRoleMappingByRole,
  userRoleInputUtility,
} from './userRoleInputUtility';

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  marginLeft: theme.spacing.small,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const extraPopoverStyle: StyleFn = () => ({
  width: 300,
});

const actionContainerStyle: StyleFn = () => ({
  width: 38,
  paddingHorizontal: 0,
});

const smallContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.medium / 2,
});

const NewUserPermissionRow: FC<{
  rolesById: Record<string, Role>;
  venues: Record<string, Venue>;
  newUserRoleData: StoreUserRoleMappingByRole;
}> = ({ rolesById, venues, newUserRoleData }) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [roleOptions, setRoleOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const [venuesOptionsList, setVenuesOptionsList] = useState<
    DropdownGroupOptionsProps[]
  >([]);
  useEffect(() => {
    setVenuesOptionsList(
      Object.values(venues).map(venue => {
        const checkedStores = newUserRoleData.locations.map(
          location => location.storeId,
        );
        const hasAccessToAllStores = venue.stores.every(store =>
          checkedStores.includes(store.id),
        );
        return {
          id: venue.id,
          label: venue.name,
          checked: hasAccessToAllStores,
          data: venue.stores.map(store => ({
            id: store.id,
            label: store.name,
            checked: checkedStores.includes(store.id),
          })),
        };
      }),
    );
  }, [venues, newUserRoleData.locations]);

  useEffect(() => {
    const options = Object.values(rolesById).reduce(
      (acc, role) => {
        return [
          ...acc,
          {
            value: role.id,
            label: role.name,
          },
        ];
      },
      [{ value: '', label: translate('backOfficeUsers.selectRole') }],
    );
    setRoleOptions(options);
  }, [rolesById, translate]);

  const onChangeRole = useCallback(
    (roleId: string) => {
      setSelectedRole(roleId);
      userRoleInputUtility.modifyRole(newUserRoleData.id, roleId);
    },
    [newUserRoleData.id],
  );

  const onDelete = useCallback(() => {
    userRoleInputUtility.deleteInput(newUserRoleData.id);
  }, [newUserRoleData.id]);

  const onChangeLocation = useCallback(
    (sections: DropdownGroupSection[]) => {
      const selectedLocations = sections.reduce<StoreUserRoleMapping[]>(
        (acc, venue) => {
          const locations = venue.data
            .filter(store => store.checked === true)
            .map(store => {
              return {
                venueId: venue.id,
                storeId: store.id,
              };
            });
          return [...acc, ...locations];
        },
        [],
      );
      userRoleInputUtility.modifyStores(newUserRoleData.id, selectedLocations);
    },
    [newUserRoleData.id],
  );

  return (
    <TableRow normalRows actionStyle={css(actionContainerStyle)}>
      <View style={css(smallContainer)}>
        <DropDown
          values={roleOptions}
          selectedValue={selectedRole}
          onValueChange={onChangeRole}
        />
      </View>

      <View style={css(smallContainer)}>
        <DropdownGroup
          sectionHeaderLabelSuffix={`(${translate(
            'backOfficeUsers.allStores',
          )})`}
          options={venuesOptionsList}
          onValueChange={onChangeLocation}
          extraPopoverStyle={css(extraPopoverStyle)}
        />
      </View>
      <View style={css(smallContainer)}>
        <IconButton
          testID="deleteUserRolesButton"
          icon={Icons.Trash}
          iconSize={24}
          containerSize={34}
          containerStyle={css(closeIconContainerStyle)}
          iconStyle={css(closeIconStyle)}
          onPress={onDelete}
        />
      </View>
    </TableRow>
  );
};

export default NewUserPermissionRow;
