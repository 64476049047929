import {
  App,
  AppAccess,
  PermittedLocation,
  StyleFn,
  UserRoleDef,
} from '@hitz-group/domain';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { View, ScrollView } from 'react-native';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../../../components/Button/Button';
import { useUserRoles } from '../../../../../../hooks/app/users/useUserRoles';
import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import { useVenues } from '../../../../../../hooks/app/useVenues';
import { useNotification } from '../../../../../../hooks/Notification';
import DropDown from '../../../../../../components/FormInput/DropDown';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import useRolesContext from '../../../../../../hooks/app/users/useRolesContext';
import { jobRoleSettingsUtility } from '../../../../../../state/jobRoleSettingsUtility';
import SearchPermissionInput from '../../../Roles/SearchPermissionInput';
import PermissionSection from '../../../Roles/PermissionSection';
import OfficePermissions from '../../../Roles/OfficePermissions';
import POSAppPermissions from '../../../Roles/POSAppPermissions';
import DropdownGroup, {
  DropdownGroupOptionsProps,
  DropdownGroupSection,
} from '../../../../../../components/FormInput/DropdownGroup';
import {
  computeAppAccess,
  getUpdateUserRoleInput,
  groupPermissionsByIdAndOperations,
} from '../ManageUserRoles/userRoleInputUtility';
import { DeleteUserRole } from './DeleteUserRole';

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  width: 540,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  paddingBottom: theme.spacing.big,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  alignItems: 'center',
  paddingVertical: theme.spacing.big,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme, disabled }) => ({
  width: 120,
  height: 38,
  marginLeft: 'auto',
  borderRadius: 5,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
  ...(disabled && { opacity: 0.5 }),
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const smallContainer: StyleFn = ({}) => ({
  flexDirection: 'row',
});

type CustomizeUserRolesParamList = {
  PermissionOverride: { userRoleId: string; userId: string };
};
export type CustomizeUserRolesRouteProp = RouteProp<
  CustomizeUserRolesParamList,
  'PermissionOverride'
>;

export const CustomizeUserRole: React.FC = () => {
  const { translate } = useTranslation();
  const { css } = useFela();
  const { showNotification } = useNotification();
  const { rolesById, permissions } = useRolesContext();
  const { params } = useRoute<CustomizeUserRolesRouteProp>();
  const {
    venues,
    loading: venuesLoading,
    error: venuesError,
    getVenues,
  } = useVenues();
  const [roleOptions, setRoleOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [selectedRole, setSelectedRole] = useState<string>();
  const [selectedLocations, setSelectedLocations] = useState<
    PermittedLocation[]
  >([]);
  const [venuesOptionsList, setVenuesOptionsList] = useState<
    DropdownGroupOptionsProps[]
  >([]);
  const [userRole, setUserRole] = useState<UserRoleDef>();
  const isFocused = useIsFocused();

  const onCreateOrUpdateComplete = useCallback(() => {
    showNotification({
      success: true,
      message: translate('backOfficeUsers.userRolesCreatedUpdatedSuccessfully'),
    });
  }, [showNotification, translate]);

  const {
    userRoles: userRolesById,
    fetchUserRole,
    batchCreateOrUpdateUserRoles,
    loading,
    error,
  } = useUserRoles({ userId: params.userId, onCreateOrUpdateComplete });

  const updateRolePermissions = useCallback(
    (roleId: string) => {
      const role = rolesById[roleId];

      jobRoleSettingsUtility.selectedPermissions = {
        ...groupPermissionsByIdAndOperations(role.permissions),
        ...groupPermissionsByIdAndOperations(
          userRole?.overridePermissions || [],
        ),
      };
      const userRoleApps = userRole?.apps as AppAccess;
      const roleApps = role.apps as AppAccess;

      jobRoleSettingsUtility.appAccessSettings = {
        [App.BACKOFFICE]: computeAppAccess(
          userRoleApps,
          App.BACKOFFICE,
          roleApps,
        ),
        [App.POS_APP]: computeAppAccess(userRoleApps, App.POS_APP, roleApps),
      };
      jobRoleSettingsUtility.isEditable = true;
      setSelectedRole(roleId);
    },
    [rolesById, userRole?.apps, userRole?.overridePermissions],
  );

  const onSubmit = useCallback(() => {
    const payload = getUpdateUserRoleInput(
      jobRoleSettingsUtility.selectedPermissions,
      rolesById,
      jobRoleSettingsUtility.appAccessSettings,
      selectedLocations,
      selectedRole as string,
      userRole as UserRoleDef,
    );

    batchCreateOrUpdateUserRoles([payload]);
  }, [
    selectedLocations,
    userRole,
    batchCreateOrUpdateUserRoles,
    selectedRole,
    rolesById,
  ]);

  const onChangeRole = useCallback(
    (roleId: string) => {
      updateRolePermissions(roleId);
    },
    [updateRolePermissions],
  );

  const onChangeLocation = useCallback((sections: DropdownGroupSection[]) => {
    const selectedLocations = sections.reduce<PermittedLocation[]>(
      (acc, venue) => {
        const locations = venue.data
          .filter(store => store.checked === true)
          .map(store => {
            return [
              {
                venueId: venue.id,
                storeId: store.id,
              },
            ];
          })
          .flat();
        return [...acc, ...locations];
      },
      [],
    );
    setSelectedLocations(selectedLocations);
  }, []);
  useEffect(() => {
    if (userRole && venues) {
      setVenuesOptionsList(
        Object.values(venues).map(venue => {
          const checkedStores = userRole.locations.map(
            location => location.store.id,
          );
          const hasAccessToAllStores = venue.stores.every(store =>
            checkedStores.includes(store.id),
          );
          return {
            id: venue.id,
            label: venue.name,
            checked: hasAccessToAllStores,
            data: venue.stores.map(store => ({
              id: store.id,
              label: store.name,
              checked: checkedStores.includes(store.id),
            })),
          };
        }),
      );
      setSelectedLocations(
        userRole.locations.map(location => ({
          storeId: location.store.id,
          venueId: location.venue.id,
        })),
      );
    }
  }, [venues, userRole]);

  useEffect(() => {
    setRoleOptions(
      Object.values(rolesById).map(role => ({
        value: role.id,
        label: role.name,
      })),
    );
  }, [rolesById]);

  useEffect(() => {
    if (isFocused) {
      getVenues();
    }
  }, [getVenues, isFocused]);

  useEffect(() => {
    if (isFocused && params?.userRoleId) {
      fetchUserRole(params.userRoleId);
    }
  }, [fetchUserRole, params?.userRoleId, isFocused]);

  useEffect(() => {
    if (error || venuesError) {
      showNotification({
        error: true,
        message: (error || venuesError) as string,
      });
    }
  }, [error, venuesError, showNotification]);

  useEffect(() => {
    jobRoleSettingsUtility.permissions = Object.values(permissions);
  }, [permissions]);

  useEffect(() => {
    if (userRolesById && params.userRoleId) {
      setUserRole(userRolesById[params.userRoleId]);
    }
  }, [params.userRoleId, userRolesById]);

  useEffect(() => {
    if (rolesById && userRole) {
      const role = rolesById[userRole.role.id];
      updateRolePermissions(role.id);
    }
  }, [userRole, rolesById, updateRolePermissions]);

  useEffect(() => {
    return () => {
      jobRoleSettingsUtility.reset();
    };
  }, []);

  if (loading || venuesLoading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.jobRoleSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView
          contentContainerStyle={css(generalSettingsContainerWrapper)}
        >
          <View style={css(pageStyle)}>
            <BackOfficeSection
              title={translate('backOfficeUsers.manageRolesAndPermissions')}
              containerStyle={css(backOfficeContainerStyle)}
            >
              <View style={css(smallContainer)}>
                <DropDown
                  values={roleOptions}
                  selectedValue={selectedRole}
                  onValueChange={onChangeRole}
                  testID="roleNameDropdown"
                />
                <DropdownGroup
                  sectionHeaderLabelSuffix={`(${translate(
                    'backOfficeUsers.allStores',
                  )})`}
                  options={venuesOptionsList}
                  onValueChange={onChangeLocation}
                  testID="locationNameDropdown"
                />
              </View>
              <SearchPermissionInput />
              <PermissionSection app={App.BACKOFFICE}>
                <OfficePermissions />
              </PermissionSection>
              <PermissionSection app={App.POS_APP}>
                <POSAppPermissions />
              </PermissionSection>
            </BackOfficeSection>
          </View>
        </ScrollView>

        <View style={css(actionsContainerStyle)}>
          {userRole && (
            <DeleteUserRole
              userId={userRole.user?.id}
              userRoleId={userRole.id}
            />
          )}

          <Button
            fluid
            testID="saveRoleBtn"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onSubmit}
            loading={loading || venuesLoading}
          />
        </View>
      </View>
    </>
  );
};
