import { View, FlatList, TextStyle, TouchableOpacity } from 'react-native';
import React, { useState, ReactElement, useCallback, useMemo } from 'react';
import { DEFAULT_ENTITY_ID, OrderStatus, StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import Button from '../Button/Button';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import scale, { isWeb } from '../../common/theme';
import IconButton from '../Button/IconButton';
import * as styles from './OrderHistoryView.style';
import { translate, useCurrency } from '@hitz-group/localization';
import { PaymentTypeDisplay } from './OrderHistoryView';

export interface PrintActionButtonProps {
  orderStatus: OrderStatus;
  options: PaymentTypeDisplay[];
  onPrintReceipt: (index?: number) => void;
}

export const backgroundStyle: StyleFn = ({ theme }) => ({
  height: scale.deviceHeight,
  width: '100%',
  backgroundColor: theme.colors.transparent,
  position: 'absolute',
});

const popoverStyle: StyleFn = ({ theme }) => ({
  width: 180,
  borderRadius: theme.radius.large,
});

const showToolTipArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const viewStyle: StyleFn = () => ({
  minHeight: 38,
});

const dropDownInnerContainer: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  margin: isWeb ? 0 : 10,
  ...theme.shadow30,
  paddingVertical: 10,
  borderRadius: theme.radius.medium,
});

const flatListContentStyle: StyleFn = ({ theme, height }) => ({
  height: height,
  backgroundColor: theme.colors.white,
});

const optionLabelStyle: StyleFn = ({ theme }) => ({
  textTransform: undefined,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
});

const checkBoxContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'flex-start',
  alignSelf: 'center',
  height: 38,
  shadowColor: theme.colors.white,
});

const rightIconStyle: StyleFn = () => ({
  height: 34,
  width: 20,
  justifyContent: 'center',
  alignSelf: 'center',
  marginLeft: 'auto',
});

const PrintActionButton: React.FC<PrintActionButtonProps> = ({
  options,
  onPrintReceipt,
  orderStatus,
}) => {
  const { css, theme } = useFela();
  const { formatCurrency } = useCurrency();

  const includedPrintOrderOptions = useMemo(
    () =>
      options.concat([
        {
          name: translate('button.printOrder'),
          amount: 0,
          id: DEFAULT_ENTITY_ID,
        },
      ]),
    [options],
  );

  const [showOptions, setShowOptions] = useState(false);

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const closeDropDown = useCallback((): void => {
    setShowOptions(false);
  }, []);

  const onBlur = useCallback(
    (e): void => {
      const targetEvent = (
        e.nativeEvent as unknown as {
          relatedTarget: unknown;
        }
      )?.relatedTarget;
      !targetEvent && closeDropDown();
    },
    [closeDropDown],
  );

  const onToggleOption = useCallback(() => {
    setShowOptions(!showOptions);
  }, [showOptions]);

  const onPressOption = useCallback(
    (selectedIndex: number) => {
      if (includedPrintOrderOptions[selectedIndex].id === DEFAULT_ENTITY_ID) {
        onPrintReceipt();
      } else {
        onPrintReceipt(selectedIndex);
      }
      onToggleOption();
    },
    [includedPrintOrderOptions, onPrintReceipt, onToggleOption],
  );

  const showPopoverPayments =
    (orderStatus === OrderStatus.COMPLETED && options.length > 1) ||
    (orderStatus !== OrderStatus.COMPLETED && options.length >= 1);

  if (!showPopoverPayments)
    return (
      <IconButton
        icon="print"
        color={'paletteLightBlue'}
        containerSize={35}
        iconSize={20}
        iconStyle={{ color: theme.colors.blue }}
        containerStyle={css(styles.actionIconContainerStyle)}
        onPress={() => onPrintReceipt()}
        testID="print-receipt-button"
      />
    );

  return (
    <View style={css(viewStyle)}>
      <Popover
        arrowStyle={css(showToolTipArrowStyle)}
        placement={PopoverPlacement.BOTTOM}
        popoverStyle={css(popoverStyle({ theme }))}
        from={
          <TouchableOpacity activeOpacity={1} onBlur={onBlur}>
            <IconButton
              icon="print"
              color={'paletteLightBlue'}
              containerSize={35}
              iconSize={20}
              iconStyle={{ color: theme.colors.blue }}
              containerStyle={css(styles.actionIconContainerStyle)}
              onPress={() => onToggleOption()}
              testID="print-receipt-button"
            />
          </TouchableOpacity>
        }
        backgroundStyle={css(backgroundStyle)}
        isVisible={showOptions}
        onRequestClose={() => onRequestClosePopover()}
      >
        <View style={css(dropDownInnerContainer)}>
          <FlatList
            data={includedPrintOrderOptions}
            contentContainerStyle={[
              css(
                flatListContentStyle({
                  theme,
                  height: 38 * includedPrintOrderOptions.length,
                }),
              ),
            ]}
            renderItem={({ item, index }): ReactElement => {
              return (
                <Button
                  title={
                    item.id === DEFAULT_ENTITY_ID
                      ? item.name
                      : `${item.name} (${formatCurrency(item.amount)})`
                  }
                  fluid
                  raised
                  key={index}
                  onPress={() => onPressOption(index)}
                  iconPosition={'right'}
                  labelStyle={[css(optionLabelStyle)] as TextStyle}
                  containerStyle={css(checkBoxContainerStyle)}
                  iconContainerStyle={css(rightIconStyle)}
                  icon={'angle-right'}
                  iconProps={{
                    color: theme.colors.paragraph,
                    size: 24,
                  }}
                />
              );
            }}
            keyExtractor={(item: PaymentTypeDisplay, index: number) =>
              index.toString()
            }
          />
        </View>
      </Popover>
    </View>
  );
};

export default PrintActionButton;
