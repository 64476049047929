import React, { createContext } from 'react';
import { Details } from './Details';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { Checkout } from './Checkout';
import { ProfitMarginsContainer } from './ProfitMargins/ProfitMarginsContainer';
import { DeviceProfiles } from './DeviceProfiles';
// import { TablesSection } from './TablesSections';
import { useRoute } from '@react-navigation/native';
import PrintersListContainer from './Printers/PrintersListContainer';
import { DevicesListContainer } from './Devices/DevicesListContainer';
const Tab = createMaterialTopTabNavigator();

interface RouteParams {
  storeId: string;
  venueId?: string;
  screen?: string;
}
export const StoreSettingsContext = createContext(
  {} as { params: RouteParams },
);

const StoreSettingsTabs: React.FC = () => {
  const route = useRoute();
  const params = route.params as RouteParams;
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'VenuesStores'}
        />
      )}
      initialRouteName={params.screen ?? 'Details'}
    >
      <Tab.Screen name="Details" component={Details} initialParams={params} />
      <Tab.Screen
        name="Devices"
        component={DevicesListContainer}
        initialParams={params}
      />
      <Tab.Screen
        name="DeviceProfiles"
        options={{ tabBarLabel: 'Device Profiles' }}
        component={DeviceProfiles}
        initialParams={params}
      />
      <Tab.Screen
        name="Printers"
        component={PrintersListContainer}
        initialParams={params}
      />
      {/* <Tab.Screen
        name="Tables"
        component={TablesSection}
        options={{ tabBarLabel: 'Tables & Layouts' }}
        initialParams={params}
      />
    */}
      <Tab.Screen name="Checkout" component={Checkout} initialParams={params} />
      <Tab.Screen
        name="ProfitMargins"
        options={{ tabBarLabel: 'Profit Margins' }}
        component={ProfitMarginsContainer}
        initialParams={params}
      />
    </Tab.Navigator>
  );
};

export default StoreSettingsTabs;
