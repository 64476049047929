import React, { useCallback, useMemo } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import TitleBar from '../../../../../components/TitleBar/TitleBar';
import {
  getStyles,
  cursorStyle,
} from '../../../../../components/RefundScreen/RefundCartHeader.style';
import { isWeb } from '../../../../../common/theme';
import IconButton from '../../../../../components/Button/IconButton';
import { Order } from '@hitz-group/domain';

export interface CartHeaderprops {
  order: Order;
  onPressClose: () => void;
}

export const CartHeader: React.FC<CartHeaderprops> = ({
  order,
  onPressClose,
}) => {
  const { css, theme } = useFela();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const TitleLeft = useCallback((): React.ReactElement => {
    return (
      <View
        testID="change-order-type"
        style={[styles.cartHeaderLeftView, isWeb && css(cursorStyle)]}
      >
        <View style={styles.orderNumberAndTypeContainer}>
          <IconButton
            testID={'secondary-side-panel-back-icon'}
            icon="times"
            onPress={onPressClose}
            iconColor={theme.colors.black}
          />
          <View style={styles.cartHeaderLeftOrderNumberView}>
            <Text
              testID="order-number"
              style={[styles.cartHeaderBadge, styles.boldText]}
            >
              {`Order#${order?.orderNumber}`}
            </Text>
          </View>
          {order?.table?.section?.name && order?.table?.name && (
            <View style={styles.cartHeaderRightTableNumberView}>
              <Text
                testID="order-table-number"
                style={styles.cartHeaderRightBadge}
              >
                {`${order?.table?.section?.name?.slice(0, 3)}-${
                  order?.table?.name
                }`}
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  }, [styles, css, theme, order, onPressClose]);

  return (
    <TitleBar
      containerStyle={styles.container}
      labelContainerStyle={styles.labelContainer}
      titleLeft={<TitleLeft />}
      testID="cart-header"
    />
  );
};
