import React, { useCallback, useMemo } from 'react';
import { View, ScrollView, Text } from 'react-native';
import { PreviewStyles } from '../../styles/Component.styles';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import {
  ColumnType,
  DateRangeGranularity,
  WALK_IN_CUSTOMER,
} from '@hitz-group/domain';
import { convertDateByFormat, DAY_FORMAT } from '../../reportsHelper';
import { DisplayLabels, ShiftPreview } from '../../types';
import { useSession } from '../../../../../../src/hooks/app/useSession';

interface DataPreviewProps {
  previewData: ShiftPreview | undefined;
}

enum KeyPrefix {
  TRANSLATION = 'backOfficeShifts.rowPreview',
}

interface PreviewTableRow {
  key: string;
  type: ColumnType;
  processedKey?: boolean;
}

interface PreviewTable {
  name: string;
  rows: PreviewTableRow[];
}

const TABLE_ONE: PreviewTableRow[] = [
  { key: 'shiftType', type: ColumnType.STRING },
  { key: 'shiftNumber', type: ColumnType.STRING },
  { key: 'createdAt', type: ColumnType.DATE },
  { key: 'closedAt', type: ColumnType.DATE },
  { key: 'device', type: ColumnType.STRING },
];

const PAYMENT_ROW_KEYS: PreviewTableRow[] = [
  { key: 'totalCounted', type: ColumnType.MONEY },
  { key: 'recordedAmount', type: ColumnType.MONEY },
  { key: 'variance', type: ColumnType.MONEY },
  { key: 'salesCount', type: ColumnType.NUMBER },
  { key: 'amount', type: ColumnType.MONEY },
  { key: 'movedMoney', type: ColumnType.MONEY },
];

const TABLES: PreviewTable[] = [
  {
    name: 'Sales Summary',
    rows: [
      { key: 'totalSalesCount', type: ColumnType.NUMBER },
      { key: 'totalGrossSales', type: ColumnType.MONEY },
      { key: 'totalDiscount', type: ColumnType.MONEY },
      { key: 'totalRoundingAmount', type: ColumnType.MONEY },
      { key: 'totalRefund', type: ColumnType.MONEY },
      { key: 'totalNetAmount', type: ColumnType.MONEY },
      { key: 'tips', type: ColumnType.MONEY },
      { key: 'totalSales', type: ColumnType.MONEY },
      { key: 'totalCostPrice', type: ColumnType.MONEY },
      { key: 'profit', type: ColumnType.MONEY },
      { key: 'voidedItemsCount', type: ColumnType.NUMBER },
      { key: 'totalVoidAmount', type: ColumnType.MONEY },
    ],
  },
  {
    name: 'Customers',
    rows: [
      { key: 'walkIn', type: ColumnType.NUMBER, processedKey: true },
      { key: 'registered', type: ColumnType.NUMBER, processedKey: true },
    ],
  },
];

export const DataPreview: React.FC<DataPreviewProps> = ({ previewData }) => {
  const styles = PreviewStyles();
  const { translate } = useTranslation();
  const { appendCurrency } = useCurrency();
  const [session] = useSession();

  const getObjPath = useCallback((obj: object | string, path: string) => {
    return path.split('.').reduce((o, i) => {
      if (o !== undefined && o[i as keyof object] !== undefined)
        return o[i as keyof object];
      else return '';
    }, obj);
  }, []);

  const convertNumberToText = (text: string | number) => {
    return text !== null && !isNaN(text as number) ? (text as string) : '0.00';
  };

  const getTextByColumn = useCallback(
    (text, type) => {
      let toReturn: string;
      switch (type) {
        case ColumnType.MONEY:
          toReturn = appendCurrency(`${convertNumberToText(text)}`);
          break;
        case ColumnType.NUMBER:
          toReturn = convertNumberToText(text);
          break;
        case ColumnType.DATE:
          toReturn = text
            ? convertDateByFormat(
                new Date(text).toISOString(),
                DateRangeGranularity.DAY,
                DAY_FORMAT,
                session.currentOrganization?.timezone,
              )
            : DisplayLabels.SHIFT_NOT_CLOSED;
          break;
        default:
          toReturn = text || '';
          break;
      }
      return toReturn;
    },
    [appendCurrency, session.currentOrganization?.timezone],
  );

  const taxesTotals = useMemo(() => {
    return {
      name: 'All Taxes',
      amount: previewData?.taxes.reduce((a, t) => a + t.amount, 0),
    };
  }, [previewData?.taxes]);

  const processed = useMemo(() => {
    const toReturn = {
      walkIn: 0,
      registered: 0,
    };

    if (previewData?.customers) {
      const notRegisteredIndex = previewData?.customers.findIndex(
        customer => customer.id === WALK_IN_CUSTOMER,
      );
      if (notRegisteredIndex !== -1) {
        toReturn.walkIn = previewData?.customers[notRegisteredIndex].count;
      }
      toReturn.registered = previewData?.customers.reduce(
        (acc, customer, index) => {
          if (index === notRegisteredIndex) return acc;
          else return acc + customer.count;
        },
        0,
      );
    }

    return toReturn;
  }, [previewData?.customers]);

  const productTypeTotals = useMemo(() => {
    return {
      name: 'All Product Types',
      amount: previewData?.salesByProductType?.reduce(
        (a, t) => a + t.amount,
        0,
      ),
    };
  }, [previewData?.salesByProductType]);

  return (
    <ScrollView style={styles.viewStyle}>
      {previewData && (
        <>
          <View
            testID={'preview-table'}
            key={'preview-table-one'}
            style={styles.firstTableStyle}
          >
            {TABLE_ONE.map((row, rowIndex) => (
              <View
                key={`preview-table-one-row-${rowIndex}`}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text>
                    {translate(`${KeyPrefix.TRANSLATION}.${row.key}`)}
                  </Text>
                </View>
                <View style={styles.rightAlignCellStyle}>
                  <Text>
                    {getTextByColumn(
                      getObjPath(previewData, row.key),
                      row.type,
                    )}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          {previewData?.salesByPaymentType.map((paymentData, paymentIndex) => (
            <View
              testID={'preview-table'}
              key={`payment-table-${paymentIndex}`}
              style={styles.tableStyle}
            >
              <View
                key={`payment-table-${paymentIndex}-heading`}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>
                    {getObjPath(paymentData, 'paymentType.name')} Payments
                  </Text>
                </View>
              </View>
              {PAYMENT_ROW_KEYS.map((row, rowIndex) => (
                <View
                  key={`payment-table-${paymentIndex}-row-${rowIndex}`}
                  style={styles.rowStyle}
                >
                  <View style={styles.cellStyle}>
                    <Text>
                      {translate(`${KeyPrefix.TRANSLATION}.${row.key}`)}
                    </Text>
                  </View>
                  <View style={styles.rightAlignCellStyle}>
                    <Text>
                      {getTextByColumn(
                        getObjPath(paymentData, row.key),
                        row.type,
                      )}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          ))}
          <View
            testID={'preview-table'}
            key={'tax-table-one'}
            style={styles.tableStyle}
          >
            <View key={'tax-table-heading'} style={styles.rowStyle}>
              <View style={styles.cellStyle}>
                <Text style={styles.headerStyle}>Tax Summary</Text>
              </View>
            </View>
            {previewData?.taxes.map((taxData, taxIndex) => (
              <View
                key={`tax-table-${taxIndex}-row-${taxIndex}`}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text>
                    {taxData.tax.name}
                    {taxData.tax.rate ? ` (${taxData.tax.rate}%)` : ''}
                  </Text>
                </View>
                <View style={styles.rightAlignCellStyle}>
                  <Text>
                    {getTextByColumn(taxData.amount, ColumnType.MONEY)}
                  </Text>
                </View>
              </View>
            ))}
            {previewData?.taxes.length > 1 && (
              <View key={'tax-table-total-row'} style={styles.rowStyle}>
                <View style={styles.cellStyle}>
                  <Text>{taxesTotals.name}</Text>
                </View>
                <View style={styles.rightAlignCellStyle}>
                  <Text>
                    {getTextByColumn(taxesTotals.amount, ColumnType.MONEY)}
                  </Text>
                </View>
              </View>
            )}
          </View>
          {TABLES.map((table, tableIndex) => (
            <View
              testID={'preview-table'}
              key={`preview-table-${tableIndex}`}
              style={styles.tableStyle}
            >
              <View
                key={`preview-table-${tableIndex}-heading`}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>{table.name}</Text>
                </View>
              </View>
              {table.rows.map((row, rowIndex) => (
                <View
                  key={`preview-table-${tableIndex}-row-${rowIndex}`}
                  style={styles.rowStyle}
                >
                  <View style={styles.cellStyle}>
                    <Text>
                      {translate(`${KeyPrefix.TRANSLATION}.${row.key}`)}
                    </Text>
                  </View>
                  <View style={styles.rightAlignCellStyle}>
                    <Text>
                      {getTextByColumn(
                        getObjPath(
                          row.processedKey ? processed : previewData,
                          row.key,
                        ),
                        row.type,
                      )}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          ))}
          {previewData?.voidedAmountByReason.length > 0 && (
            <View
              testID={'preview-table'}
              key={'voided-amount-by-reason-table-one'}
              style={styles.tableStyle}
            >
              <View
                key={'voided-amount-by-reason-table-heading'}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text style={styles.headerStyle}>Void Summary</Text>
                </View>
              </View>
              {previewData?.voidedAmountByReason.map((voidData, typeIndex) => (
                <View
                  key={`voided-amount-by-reason-table-${typeIndex}-row-${typeIndex}`}
                  style={styles.rowStyle}
                >
                  <View style={styles.cellStyle}>
                    <Text>{translate(`enums.${voidData.reason}`)}</Text>
                  </View>
                  <View style={styles.rightAlignCellStyle}>
                    <Text>
                      {getTextByColumn(voidData.amount, ColumnType.MONEY)}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          )}
          <View
            testID={'preview-table'}
            key={'product-type-table-one'}
            style={styles.tableStyle}
          >
            <View key={'product-type-table-heading'} style={styles.rowStyle}>
              <View style={styles.cellStyle}>
                <Text style={styles.headerStyle}>Product Type Summary</Text>
              </View>
            </View>
            {previewData?.salesByProductType.map((productData, typeIndex) => (
              <View
                key={`product-type-table-${typeIndex}-row-${typeIndex}`}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text>{productData.productType.name}</Text>
                </View>
                <View style={styles.rightAlignCellStyle}>
                  <Text>
                    {getTextByColumn(productData.amount, ColumnType.MONEY)}
                  </Text>
                </View>
              </View>
            ))}
            {previewData?.salesByProductType.length > 1 && (
              <View
                key={'product-type-table-total-row'}
                style={styles.rowStyle}
              >
                <View style={styles.cellStyle}>
                  <Text>{productTypeTotals.name}</Text>
                </View>
                <View style={styles.rightAlignCellStyle}>
                  <Text>
                    {getTextByColumn(
                      productTypeTotals.amount,
                      ColumnType.MONEY,
                    )}
                  </Text>
                </View>
              </View>
            )}
          </View>
        </>
      )}
    </ScrollView>
  );
};
