import { gql } from '@apollo/client';

export const FRAGMENTS = {
  deviceDetails: gql`
    fragment DeviceDetails on Device {
      id
      name
      store {
        id
      }
      deviceProfile {
        id
        orderTypes {
          id
          name
          code
        }
        defaultOrderType {
          id
          name
          code
        }
      }
      previousOrder {
        orderNumber
      }
      deviceCode
      isPaired
      details
      uuid
      appVersion
      salesPrefix
      returnPrefix
      cashDrawer
      paymentTerminal {
        id
        name
        uuid
      }
      pushNotificationToken
      printingOptions {
        profile {
          id
          name
        }
        printer {
          id
          name
          ipAddress
          bdAddress
          brand
          printerType
          isActive
        }
        printerProfileType
        template {
          id
          isDeleted
          name
          template
        }
      }
      isOnline
      lastDisconnectedAt
    }
  `,
};

export const GET_DEVICE_BY_ID_QUERY = gql`
  query deviceById($id: ID!) {
    device(id: $id) {
      ...DeviceDetails
    }
  }
  ${FRAGMENTS.deviceDetails}
`;

export const GET_DEVICES_BY_STORE_QUERY = gql`
  query devicesByStore {
    devices {
      ...DeviceDetails
    }
  }
  ${FRAGMENTS.deviceDetails}
`;

export const CREATE_DEVICE = gql`
  mutation createDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      ...DeviceDetails
    }
  }
  ${FRAGMENTS.deviceDetails}
`;

export const UPDATE_DEVICE = gql`
  mutation updateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      ...DeviceDetails
    }
  }
  ${FRAGMENTS.deviceDetails}
`;

export const UPDATE_DEVICE_TOKEN = gql`
  mutation updateDevicePushNotificationToken(
    $input: UpdateDevicePushNotificationTokenInput!
  ) {
    updateDevicePushNotificationToken(input: $input) {
      ...DeviceDetails
    }
  }
  ${FRAGMENTS.deviceDetails}
`;

export const DELETE_DEVICE = gql`
  mutation deleteDevice($id: ID!) {
    deleteDevice(id: $id)
  }
`;
export const RESET_DEVICE = gql`
  mutation resetDeviceCode($input: ResetDeviceCodeInput!) {
    resetDeviceCode(input: $input) {
      deviceCode
    }
  }
`;
