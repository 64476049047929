import React from 'react';
import { Text, View } from 'react-native';
import { Venue } from '@hitz-group/domain';
import { VenuesListRowStyles } from './VenuesScreen.styles';
import { useNavigation } from '@react-navigation/native';
import TableRow from '../../../../components/TableComponent/TableRow';
import Button from '../../../../components/Button/Button';
import IconButton from '../../../../components/Button/IconButton';
import { StoreRow } from './StoreRow';
import { useFela } from 'react-fela';

interface VenueRowProps {
  venue: Venue;
  onChangeVenueStatus: (id: string, isActive: boolean) => void;
}

export const VenueRow: React.FC<VenueRowProps> = ({
  venue,
  onChangeVenueStatus,
}: VenueRowProps) => {
  const navigation = useNavigation();
  const styles = VenuesListRowStyles();
  const { theme } = useFela();

  return (
    <View>
      <TableRow containerStyle={styles.rowContainerStyle} normalRows={true}>
        <View style={styles.statusViewStyle}>
          <Button
            onPress={(): void => onChangeVenueStatus(venue.id, !venue.isActive)}
            fluid
            iconPosition={'left'}
            containerStyle={styles.checkBoxTitleContainer}
            iconContainerStyle={
              venue.isActive
                ? styles.checkIconContainer
                : styles.unCheckContainer
            }
            icon={venue.isActive ? 'check' : 'null'}
            iconProps={{
              color: theme.colors.success,
              size: 15,
            }}
          />
        </View>
        <View style={styles.nameViewStyle}>
          <Text style={styles.nameContainerStyle}>{venue.name}</Text>
        </View>
        <View style={styles.actionsViewStyle}>
          <IconButton
            icon="setting"
            iconSize={22}
            containerSize={34}
            iconStyle={styles.settingsIconStyle}
            containerStyle={styles.settingsContainerStyle}
            onPress={(): void =>
              navigation.navigate('VenueSettings', {
                venueId: venue.id,
                title: venue.name,
              })
            }
          />
        </View>
      </TableRow>

      {venue.stores.map((item, index) => (
        <StoreRow store={item} key={index} venueId={venue.id} />
      ))}
    </View>
  );
};
