import { useCallback, useMemo, useState } from 'react';

export interface PaginatedDataProps {
  currentPage: number;
  onChange: (pageNumber: number) => void;
  totalPages: number;
  paginate: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paginatedItems: any[];
}
export function usePagination(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[],
  itemsPerPage: number,
  paginateBy?: 'page' | 'item',
): PaginatedDataProps {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemIndex, setItemIndex] = useState(itemsPerPage);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const isPaginatable = data.length > itemsPerPage;
  const paginationStartIndex =
    (currentPage - 1) * (itemsPerPage - (isPaginatable ? 1 : 0));
  const paginationEndIndex =
    currentPage * (itemsPerPage - (isPaginatable ? 1 : 0));

  const paginatedItems = useMemo(() => {
    switch (paginateBy) {
      case 'item':
        return isPaginatable
          ? data.slice(itemIndex - itemsPerPage, itemIndex)
          : data;
      case 'page':
      default:
        return isPaginatable
          ? data.slice(paginationStartIndex, paginationEndIndex)
          : data;
    }
  }, [
    data,
    itemsPerPage,
    paginationEndIndex,
    paginationStartIndex,
    itemIndex,
    isPaginatable,
    paginateBy,
  ]);

  const onChange = useCallback(
    (pageNumber: number) => {
      switch (paginateBy) {
        case 'item':
          if (
            (pageNumber === 1 &&
              itemIndex >= itemsPerPage &&
              itemIndex < Object.keys(data).length) ||
            (pageNumber === -1 && itemIndex > itemsPerPage)
          ) {
            setItemIndex(prevIndex => prevIndex + pageNumber);
          }
          return;
        case 'page':
        default:
          setCurrentPage(pageNumber);
          return;
      }
    },
    [paginateBy, itemIndex, itemsPerPage, data],
  );
  return useMemo(
    () => ({
      currentPage,
      onChange,
      paginate: isPaginatable,
      totalPages,
      paginatedItems,
    }),
    [currentPage, totalPages, paginatedItems, onChange, isPaginatable],
  );
}
