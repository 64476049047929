import {
  Adjustment,
  OrderTax,
  AdjustmentType,
  Discount,
  RenderProps,
  StyleFn,
  AdjustmentUnit,
} from '@hitz-group/domain';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import React, { useMemo } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { View, ViewStyle } from 'react-native';
import {
  getAdjustmentValue,
  getPaymentAdjustmentValue,
} from '@hitz-group/order-helper';
import SummaryItem from './CartSummaryItem';

export interface PaymentSummary {
  name: string;
  amount: number;
}

export interface CartSummaryProps {
  subTotal: number;
  totalDue: number;
  amountDue?: number;
  discountAmount?: number;
  discounts?: Discount[];
  adjustments?: Adjustment[];
  surchargeAmount?: number;
  containerStyle?: ViewStyle;
  taxes?: Array<OrderTax>;
  isCalledFromRefund?: boolean;
  isCalledFromPaymentScreen?: boolean;
  summaryItemStyle?: ViewStyle;
  isRefundOrder?: boolean;
  customTotalValueTextStyle?: StyleFn;
  paymentSummary?: PaymentSummary[];
  deliveryFee?: number;
  serviceCharge?: number;
  tip?: number;
}

const cartSummaryStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.padding.large,
  paddingVertical: theme.padding.medium,
});

const dividerStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.border,
  marginTop: theme.padding.small * 2,
  marginBottom: theme.padding.small,
  borderTopWidth: 1,
  height: 2,
});

const CartSummary: React.FC<CartSummaryProps> = ({
  taxes,
  subTotal,
  totalDue,
  amountDue,
  discountAmount = 0,
  adjustments,
  containerStyle,
  isCalledFromRefund,
  summaryItemStyle,
  isRefundOrder,
  customTotalValueTextStyle,
  paymentSummary = [],
  isCalledFromPaymentScreen,
  deliveryFee,
  serviceCharge,
  tip,
}) => {
  const { translate } = useTranslation();

  const { css } = useFela();
  const taxItems = useMemo(
    () =>
      (taxes || []).map(({ tax, amount }) => (
        <SummaryItem
          label={tax.name}
          testID={`cart-${tax.name}-tax`}
          value={amount}
          key={tax.id}
          customContainerStyle={summaryItemStyle}
          isRefundOrder={isRefundOrder}
        />
      )),
    [isRefundOrder, summaryItemStyle, taxes],
  );
  const { formatCurrency } = useCurrency();

  const discounts = adjustments?.filter(
    adjustment => adjustment.adjustmentType == AdjustmentType.DISCOUNT,
  );
  const nonPaymentSurcharges = adjustments?.filter(
    adjustment =>
      adjustment.adjustmentType == AdjustmentType.SURCHARGE &&
      !adjustment.allowOnPaymentType,
  );

  const paymentSurcharges = adjustments?.filter(
    surcharge => surcharge.allowOnPaymentType,
  );

  const nonPaymentSurchargeAmount =
    getAdjustmentValue(subTotal, adjustments || [], {
      escapePaymentType: true,
    }) || 0;

  const surchargeFormatted = useMemo(
    () =>
      (nonPaymentSurcharges || [])
        .map(x =>
          x.adjustmentUnit === AdjustmentUnit.PERCENTAGE
            ? `${x.amount}%`
            : `${formatCurrency(x.amount)}`,
        )
        .join(', '),
    [nonPaymentSurcharges, formatCurrency],
  );

  const discountFormatted = useMemo(
    () =>
      (discounts || [])
        .map(x =>
          x.adjustmentUnit === AdjustmentUnit.PERCENTAGE
            ? `${x.amount}%`
            : `${formatCurrency(x.amount)}`,
        )
        .join(', '),
    [discounts, formatCurrency],
  );
  const paymentSurchargeFormatted = useMemo(
    () =>
      (paymentSurcharges || [])
        .map(
          x =>
            x.name +
            '(' +
            String(
              x.adjustmentUnit === AdjustmentUnit.PERCENTAGE
                ? `${x.amount}%`
                : `${formatCurrency(x.amount)}`,
            ) +
            ')',
        )
        .join(', '),
    [paymentSurcharges, formatCurrency],
  );

  const discountLabel = translate('payment.discount', {
    discounts: discountFormatted,
  });

  const surchargeLabel = translate('payment.surcharge', {
    discounts: surchargeFormatted,
  });

  const paymentValue = getPaymentAdjustmentValue(
    subTotal + nonPaymentSurchargeAmount || 0,
    adjustments || [],
  );

  const hasPartialPayment = Boolean(paymentSummary.length);
  return (
    <FelaComponent style={cartSummaryStyle}>
      {({ style }: RenderProps): React.ReactFragment => (
        <View style={[style, containerStyle]} testID="cart-summary">
          <SummaryItem
            label={translate('payment.subTotal')}
            testID="cart-sub-total"
            value={subTotal}
            customContainerStyle={summaryItemStyle}
            isRefundOrder={isRefundOrder}
          />
          {taxItems}
          {discounts && discounts.length > 0 && (
            <SummaryItem
              label={discountLabel}
              testID="cart-discount"
              value={discountAmount}
              customContainerStyle={summaryItemStyle}
              isRefundOrder={isRefundOrder}
              isDiscount
            />
          )}
          {nonPaymentSurcharges && nonPaymentSurcharges.length > 0 && (
            <SummaryItem
              label={surchargeLabel}
              testID="cart-surcharge"
              value={nonPaymentSurchargeAmount}
              customContainerStyle={summaryItemStyle}
              isRefundOrder={isRefundOrder}
              isDiscount
            />
          )}
          {paymentSurcharges && paymentSurcharges.length > 0 && (
            <SummaryItem
              label={paymentSurchargeFormatted}
              testID="cart-surcharge"
              value={paymentValue}
              customContainerStyle={summaryItemStyle}
              isRefundOrder={isRefundOrder}
              isDiscount
            />
          )}
          {deliveryFee ? (
            <SummaryItem
              label={translate('onlineOrders.deliveryFee')}
              testID="delivery-fee"
              value={deliveryFee}
              customContainerStyle={summaryItemStyle}
            />
          ) : null}
          {serviceCharge ? (
            <SummaryItem
              label={translate('onlineOrders.serviceCharge')}
              testID="service-charge"
              value={serviceCharge}
              customContainerStyle={summaryItemStyle}
            />
          ) : null}
          {tip ? (
            <SummaryItem
              label={translate('onlineOrders.tip')}
              testID="tip"
              value={tip}
              customContainerStyle={summaryItemStyle}
            />
          ) : null}
          <SummaryItem
            highlighted={!hasPartialPayment}
            label={
              isCalledFromRefund
                ? isRefundOrder
                  ? translate('refundOrder.totalAmount')
                  : translate('refundOrder.totalPaid')
                : translate('payment.totalDue')
            }
            value={totalDue}
            customContainerStyle={summaryItemStyle}
            customTotalValueTextStyle={customTotalValueTextStyle}
            isRefundOrder={isRefundOrder}
            testID="total"
          />
          {isCalledFromPaymentScreen && Boolean(paymentSummary.length) && (
            <View testID="divider-line" style={css(dividerStyle)} />
          )}
          {paymentSummary.map((payment, index) => (
            <SummaryItem
              key={index}
              label={payment.name}
              value={payment.amount}
              customContainerStyle={summaryItemStyle}
              customTotalValueTextStyle={customTotalValueTextStyle}
              isRefundOrder={isRefundOrder}
              testID="transaction-item"
            />
          ))}
          {!isCalledFromPaymentScreen && Boolean(paymentSummary.length) && (
            <SummaryItem
              highlighted
              label={translate('payment.totalOutstanding')}
              value={amountDue || 0}
              customContainerStyle={summaryItemStyle}
              customTotalValueTextStyle={customTotalValueTextStyle}
              isRefundOrder={isRefundOrder}
              testID="totalOutstanding"
            />
          )}
        </View>
      )}
    </FelaComponent>
  );
};

export default CartSummary;
