import { Colors } from '@hitz-group/domain';

export type MenuTile =
  | 'Modifier'
  | 'VariantOption'
  | 'Product'
  | 'Variant'
  | 'Page';

export interface DraggableTile {
  id?: string;
  key: string | number;
  name: string | number;
  disabledDrag?: boolean;
  disabledReSorted?: boolean;
  __typename?: string;
}

export const colorOptions = [
  { label: 'Deep Purple', value: Colors.deepPurple, color: Colors.deepPurple },
  { label: 'Indigo', value: Colors.indigo, color: Colors.indigo },
  { label: 'Cyan', value: Colors.cyan, color: Colors.cyan },
  { label: 'Teal', value: Colors.teal, color: Colors.teal },
  { label: 'Light Green', value: Colors.lime, color: Colors.lime },
];

export enum GRID_TYPE {
  'PAGE_ITEMS' = 'PAGE_ITEMS',
  'PAGE' = 'PAGE',
  'PRODUCT_ITEMS' = 'PRODUCT_ITEMS',
}

export enum PAGE_TYPE {
  'PAGE' = 'PAGE',
  'SUBPAGE' = 'SUBPAGE',
}
