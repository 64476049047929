import { useSubscription } from '@apollo/client/react/hooks';
import {
  DateFilter,
  Order,
  OrderStatus,
  RejectionReason,
  StyleFn,
  Features,
  FeatureContext,
} from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import {
  DrawerButton,
  SubscriptionStatusButton,
} from '../../../../components/HeaderIcons/HeaderIcons';
import NewOrderButton from '../../../../components/NewOrder/NewOrderButton';
import Layout from '../../../../components/POSLayout/POSLayout';
import { OrderEvents } from '../../../../graphql/subscriptions';
import { useOnlineOrderEvents } from '../../../../hooks/app/orders/useOnlineOrderEvents';
import { useOrders } from '../../../../hooks/app/orders/useOrders';
import { useSalesChannels } from '../../../../hooks/app/salesChannels/useSalesChannels';
import { useIntegrationPartners } from '../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { useSession } from '../../../../hooks/app/useSession';
import useBehaviorSubjectState from '../../../../hooks/app/useSubjectState';
import { useNotification } from '../../../../hooks/Notification';
import { usePrinting } from '../../../../hooks/PrintingProvider';
import { useTimeout } from '../../../../hooks/useTimeout';
import { pendingOnlineOrdersCountVar } from '../../../../state/cache';
import { lastActiveTimeSubject } from '../../../../state/lastActiveTime';
import { AppScreen } from '../../../../types/AppScreen';
import {
  filterOnlineOrders,
  isValidOnlineOrder,
} from '../../../../utils/OnlineOrdersHelper';
import HeaderTabs from '../HeaderTabs';
import { OrderFiltersSection } from './OrderFiltersSection';
import OrdersListHeader from './OrdersListHeader';
import OrdersListView, { OnlineOrdersDataProps } from './OrdersListView';
import { CartHeader } from './sideBar/CartHeader';
import CartSection from './sideBar/CartSection';
import OrderDetailsSection from './sideBar/OrderDetailsSection';
import OnlineSidePanel from './sideBar/OrderViewPanel';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';
import useBehaviorSubjectEffect from '../../../../hooks/app/useSubjectEffect';
import { refetchOrderObservable } from '../../../../hooks/app/orders/ordersObservableUtils';
import { useNavigation } from '@react-navigation/native';

const cartSectionContainer: StyleFn = () => ({
  flex: 1,
});

const containerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  borderRadius: theme.radius.small,
});

const orderRowsContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  paddingLeft: theme.spacing.big,
  alignItems: 'center',
  flex: 8,
  marginLeft: 0,
  margin: theme.spacing.medium,
  borderRadius: theme.radius.small,
});

type OnlineOrdersParamList = RouteProp<
  {
    OnlineOrders: { previousScreen: string };
  },
  'OnlineOrders'
>;

const OnlineOrders: React.FC = () => {
  const { css } = useFela();
  const [session] = useSession();
  const { translate } = useTranslation();
  const route = useRoute<OnlineOrdersParamList>();
  const { showNotification } = useNotification();
  const isFocused = useIsFocused();
  const { previousScreen } = route.params ?? {};
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [ordersMapState, setOrdersMapState] = useState<Record<string, Order>>(
    {},
  );
  const [filters, setFilters] = useState<{
    status: string;
    dateFilter: string;
    salesChannel: string;
    searchValue: string;
  }>({
    dateFilter: DateFilter.TODAY,
    salesChannel: '',
    status: '',
    searchValue: '',
  });
  const isOnlineOrdersEnabled = session?.deviceProfile?.enableOnlineOrders;
  const {
    orders,
    error: ordersDataError,
    getOrderFromCache,
    getOnlineOrdersFromCache,
  } = useOrders();
  const { value: lastActiveTime } = useBehaviorSubjectState(
    lastActiveTimeSubject,
  );
  const navigation = useNavigation();

  const resetSelectedOrder = useCallback(() => {
    setSelectedOrderId('');
  }, []);

  const navigateToPayment = useCallback(
    (orderId: string) => {
      if (orderId) {
        navigation.navigate('Payment', {
          orderId,
        });
      }
    },
    [navigation],
  );

  const onOrderPay = useCallback(
    (order: Order) => {
      navigateToPayment(order.id);
      resetSelectedOrder();
    },
    [navigateToPayment, resetSelectedOrder],
  );

  const { updateOnlineOrderStoreSettings, error: integrationError } =
    useIntegrationPartners();

  const isFeatureEnabled = useCheckFeatureEnabled();
  const isFloorViewEnabled = session?.deviceProfile?.enableFloorView;
  const isTableFeatureEnabled = isFeatureEnabled(
    Features.TABLE_MANAGEMENT,
    FeatureContext.VENUE,
    session.currentVenue?.id,
  );

  const error = integrationError || ordersDataError;

  const currentStoreId = session?.currentStore?.id;
  const deviceId = session?.device?.id;

  const { data: subscriptionData } = useSubscription(OrderEvents, {
    variables: {
      storeId: currentStoreId,
      deviceId,
      lastActiveTime,
    },
    shouldResubscribe: true,
  });

  const { salesChannels, getSalesChannels } = useSalesChannels();

  useEffect(() => {
    if (orders) {
      setOrdersMapState(orders);
    }
  }, [orders]);

  const ordersArray = useMemo(
    () => Object.values(ordersMapState),
    [ordersMapState],
  );

  useEffect(() => {
    if (isFocused && isOnlineOrdersEnabled) {
      getSalesChannels();
      getOnlineOrdersFromCache([OrderStatus.CREATED, OrderStatus.IN_PROGRESS]);
    }
  }, [
    isFocused,
    getSalesChannels,
    isOnlineOrdersEnabled,
    getOnlineOrdersFromCache,
  ]);

  const { acceptOrders, completeOrder, rejectOrder } = useOnlineOrderEvents();
  const { reprintKitchenDocket } = usePrinting();

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const refreshOrders = useCallback(() => {
    getOnlineOrdersFromCache([OrderStatus.CREATED, OrderStatus.IN_PROGRESS]);
  }, [getOnlineOrdersFromCache]);

  useEffect(() => {
    if (isFocused) {
      getOnlineOrdersFromCache([OrderStatus.CREATED, OrderStatus.IN_PROGRESS]);
    }
  }, [getOnlineOrdersFromCache, isFocused]);

  const refreshOrdersWithDelay = useTimeout(refreshOrders);

  useEffect(() => {
    if (subscriptionData) refreshOrdersWithDelay.start(2000);
  }, [subscriptionData, refreshOrdersWithDelay]);

  useBehaviorSubjectEffect(refetchOrderObservable, refreshOrders);

  const selectedOrderDetails = useMemo(() => {
    return ordersMapState?.[selectedOrderId];
  }, [ordersMapState, selectedOrderId]);

  const isTableViewEnabled =
    (isTableFeatureEnabled && isFloorViewEnabled) || false;

  useEffect(() => {
    const pendingOrdersCount = ordersArray.filter(
      eachOrder =>
        eachOrder.status === OrderStatus.CREATED &&
        isValidOnlineOrder(eachOrder, isTableViewEnabled),
    ).length;
    pendingOnlineOrdersCountVar(pendingOrdersCount);
  }, [isTableViewEnabled, ordersArray]);

  const filteredOrders: OnlineOrdersDataProps[] = useMemo(() => {
    return filterOnlineOrders(ordersArray, {
      searchValue: filters.searchValue,
      status: filters.status,
      day: filters.dateFilter,
      salesChannel: filters.salesChannel,
      isTableViewEnabled,
    });
  }, [ordersArray, filters, isTableViewEnabled]);

  const headerTitle = useMemo(
    () => (
      <HeaderTabs
        previousScreen={previousScreen}
        selectedOption={AppScreen.ONLINE_ORDER}
      />
    ),
    [previousScreen],
  );

  const headerRight = useMemo(
    () => (
      <>
        <NewOrderButton />
        <SubscriptionStatusButton />
        <DrawerButton />
      </>
    ),
    [],
  );
  const onSelectOrder = useCallback(orderId => {
    setSelectedOrderId(orderId);
  }, []);

  const onPressAccept = useCallback(
    (orderIds: string[]) => {
      const orders = orderIds
        .map(id => ordersMapState?.[id])
        .filter(order => typeof order === 'object');
      if (orders.length) {
        const updatedOrders = acceptOrders(orders);
        setOrdersMapState(prev => {
          const map = { ...prev };
          updatedOrders.forEach(order => {
            map[order.id] = { ...order };
          });
          return map;
        });
      }
    },
    [ordersMapState, acceptOrders],
  );

  const onAcceptAllOrders = useCallback(() => {
    const orderIds = ordersArray
      .filter(eachOrder => eachOrder.status === OrderStatus.CREATED)
      .map(order => order.id);
    onPressAccept(orderIds);
  }, [onPressAccept, ordersArray]);

  const onPressReject = useCallback(
    (orderId: string, reason: RejectionReason) => {
      const order = getOrderFromCache(orderId) || ordersMapState?.[orderId];
      if (order) {
        const updatedOrder = rejectOrder(order, reason);
        setOrdersMapState(prev => ({
          ...prev,
          [order.id]: {
            ...updatedOrder,
          },
        }));
      }
    },
    [ordersMapState, rejectOrder, getOrderFromCache],
  );

  const onPressComplete = useCallback(
    (orderId: string) => {
      const order = getOrderFromCache(orderId) || ordersMapState?.[orderId];
      if (order) {
        const updatedOrder = completeOrder(order);
        setOrdersMapState(prev => ({
          ...prev,
          [order.id]: {
            ...updatedOrder,
          },
        }));
      }
    },
    [ordersMapState, completeOrder, getOrderFromCache],
  );

  const onPressReprintDocket = useCallback(
    (orderId: string) => {
      const order = getOrderFromCache(orderId) || ordersMapState?.[orderId];
      if (order) {
        reprintKitchenDocket && reprintKitchenDocket(order, order.orderItems);
      }
    },
    [ordersMapState, reprintKitchenDocket, getOrderFromCache],
  );

  const sidePanelTabOptions = useMemo(
    () => [
      {
        title: translate('onlineOrders.details'),
        component: (
          <OrderDetailsSection
            order={selectedOrderDetails as Order}
            customContainerStyle={cartSectionContainer}
            onPressAccept={onPressAccept}
            onPressComplete={onPressComplete}
            onPressReject={onPressReject}
            onPressReprintDocket={onPressReprintDocket}
            onOrderPay={onOrderPay}
          />
        ),
        value: 'details',
      },
      {
        title: translate('onlineOrders.cart'),
        component: (
          <CartSection
            order={selectedOrderDetails as Order}
            customContainerStyle={cartSectionContainer}
            onPressAccept={onPressAccept}
            onPressComplete={onPressComplete}
            onPressReject={onPressReject}
            onPressReprintDocket={onPressReprintDocket}
            onOrderPay={onOrderPay}
          />
        ),
        value: 'cart',
      },
    ],
    [
      translate,
      selectedOrderDetails,
      onPressAccept,
      onPressComplete,
      onPressReject,
      onPressReprintDocket,
      onOrderPay,
    ],
  );

  const sidePanelHeader = useMemo(
    () =>
      selectedOrderId && (
        <CartHeader
          order={selectedOrderDetails as Order}
          onPressClose={resetSelectedOrder}
        />
      ),
    [selectedOrderDetails, selectedOrderId, resetSelectedOrder],
  );

  const onChangeOfFilter = useCallback((key: string, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  }, []);

  const salesChannelOptions = useMemo(() => {
    return Object.values(salesChannels).map(x => ({
      label: x.name,
      value: x.id,
    }));
  }, [salesChannels]);

  const onSetOnlineOrderSettings = useCallback(
    (key: 'snoozeTime' | 'prepTime', value: number) => {
      if (!value && key === 'prepTime') {
        showNotification({
          error: true,
          message: translate('onlineOrders.onlineSettingsSuccussfullyUpdated'),
        });
      }
      // allow to set busy mode with 0, with active again the store
      currentStoreId &&
        updateOnlineOrderStoreSettings({
          store: currentStoreId,
          [key]: value,
        });
    },
    [
      currentStoreId,
      updateOnlineOrderStoreSettings,
      showNotification,
      translate,
    ],
  );

  if (!isOnlineOrdersEnabled) {
    // if disabled show empty screen
    return <></>;
  }

  return (
    <Layout
      title={translate('navigation.onlineOrdersTitle', {
        appName: translate('appName'),
      })}
      hasHeader
      headerTitle={headerTitle}
      headerRight={headerRight}
      testID="online-orders-page"
    >
      <View style={css(containerStyle)}>
        <OrderFiltersSection
          salesChannelOptions={salesChannelOptions}
          onChangeOfFilter={onChangeOfFilter}
          filters={filters}
        />
        <View style={css(orderRowsContainerStyle)}>
          <OrdersListHeader
            onSearchTextChange={value => onChangeOfFilter('searchValue', value)}
            onAcceptAllOrders={onAcceptAllOrders}
            setBusyTime={val => onSetOnlineOrderSettings('snoozeTime', val)}
            setPreparationTime={val =>
              onSetOnlineOrderSettings('prepTime', val)
            }
          />
          <OrdersListView
            data={filteredOrders}
            onSelectOrder={onSelectOrder}
            acceptOrders={onPressAccept}
            completeOrder={onPressComplete}
            onOrderPay={navigateToPayment}
          />
        </View>
      </View>
      <OnlineSidePanel
        showPanel={!!selectedOrderId}
        customHeader={sidePanelHeader}
        tabOptions={sidePanelTabOptions}
        loading={!selectedOrderDetails}
      />
    </Layout>
  );
};

export default OnlineOrders;
