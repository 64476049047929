import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';
import { isWeb } from '../../../../../common/theme';

export const AdjustmentsStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        formStyle: {
          width: '100%',
        },
        containerStyle: {
          width: isWeb ? 800 : '100%',
          alignSelf: 'center',
          marginTop: 40,
        },
        saveButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginLeft: 'auto',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
          alignSelf: 'auto',
        },
        columnContainerStyle: {
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          borderBottomWidth: 0,
          marginTop: theme.spacing.small,
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
        scrollStyle: {
          paddingHorizontal: theme.padding.large,
          backgroundColor: theme.colors.white,
        },
        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
        },
        actionContainerStyle: {
          marginTop: 20,
        },
        rowItemFormInputContainerStyle: {
          width: 300,
          height: theme.input.height,
          paddingLeft: theme.padding.small,
        },
        rowItemNameViewStyle: {
          marginRight: 10,
        },
        rowItemAmountViewStyle: {
          width: 130,
          marginRight: 10,
        },
        rowItemDeleteIconContainerStyle: {
          flex: 1,
        },
        rowItemPaymentTypeViewStyle: {
          width: 150,
          flexDirection: 'row',
          justifyContent: 'center',
        },
        rowItemRowContainerStyle: {
          height: 57,
          alignItems: 'center',
        },
        rowItemDeleteIconStyle: {
          color: theme.colors.red,
        },
        rowItemCloseIconContainerStyle: {
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.danger2,
          marginLeft: 'auto',
        },
      }),
    [theme],
  );
};
