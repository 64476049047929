import {
  OrderEvent,
  Order,
  OrderStatus,
  Customer,
  OrderAction,
  InitiateRefundEvent,
  AddOrderItemEvent,
  Product,
  VoidOrderEvent,
  IntegrationPartner,
} from '@hitz-group/domain';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client/react/hooks';
import { GET_ORDERS, ORDER_SAVE } from '../../../hooks/app/orders/graphql';
import { GET_CUSTOMERS } from '../../../graphql/customer';
import { computeOrderState } from '@hitz-group/order-helper';
import {
  orderAddedEventsValidation,
  orderUpdateEventsValidation,
} from '../../../utils/eventValidator';
import { useMutation } from '@apollo/client/react/hooks';
import { pendingOnlineOrdersCountVar } from '../../../state/cache';
import kitchenOrderEvents from '../../../utils/printerTemplates/kotEvents';
import { useProducts } from '../products/useProducts';
import { cloneDeep, Dictionary, keyBy } from 'lodash';
import { IMap } from '../,,/../../../../src/screens/BackOffice/Reports/types';
import { productFragment } from '../catalogue/graphql';
import { isValidOnlineOrder } from '../../../utils/OnlineOrdersHelper';
import { useIntegrationPartners } from '../useIntegrationPartners/useIntegrationPartners';
import { useOnlineOrderEvents } from './useOnlineOrderEvents';
import { useSession } from '../useSession';

const EVENTS_FOR_PRODUCT_QUANTITY_UPDATE = [
  OrderAction.ORDER_ITEM_ADD,
  OrderAction.ORDER_ITEM_REMOVE,
  OrderAction.ORDER_ITEM_UPDATE_QUANTITY,
  OrderAction.ORDER_VOID,
];

const updateProductQuantity = (
  productMap: Dictionary<Product>,
  productId: string,
  quantity: number,
) => {
  const product = cloneDeep(productMap[productId]);
  if (product?.inventory?.availableQuantity) {
    product.inventory.availableQuantity -= quantity;
  }

  return product;
};
export interface useOrderEvents {
  orderEventsHandler: (events: OrderEvent[]) => Promise<void>;
  handleSaveOnlineOrder?: (saveOrder: Order) => void;
}
export const useOrderEvents = (): useOrderEvents => {
  const client = useApolloClient();
  const { getProductsFromCache, updateProductsInCache } = useProducts(
    undefined,
    productFragment,
  );
  const {
    getIntegrationPartnerSettings,
    integrationPartners: allIntegrationPartners,
    loading: integrationSettingsLoading,
  } = useIntegrationPartners();
  const [session] = useSession();
  const { acceptOrders: acceptOnlineOrders } = useOnlineOrderEvents();
  const integrationPartners = useMemo(() => {
    return keyBy(Object.values(allIntegrationPartners), 'store');
  }, [allIntegrationPartners]);
  const [unprocessedOnlineOrder, setUnprocessedOnlineOrder] =
    useState<Order[]>();
  const [integrationPartnersSettings, setIntegrationPartnersSettings] =
    useState<Dictionary<IntegrationPartner>[]>();

  const handleUnprocessedOrder = useCallback(
    (
      currentOrder: Order,
      integrationSettings?: Dictionary<IntegrationPartner>,
    ) => {
      const storeId = session.currentStore?.id || '';
      const settings = integrationPartnersSettings?.find(
        setting =>
          setting[storeId] &&
          setting[storeId].appName === currentOrder.integrationInfo?.app,
      );

      let storeSettings = settings && settings[storeId];

      if (
        !storeSettings &&
        integrationSettings &&
        integrationSettings[storeId] &&
        integrationSettings[storeId].appName ===
          currentOrder.integrationInfo?.app
      ) {
        // when integration info in state is undefined
        // we are getting integration info from params
        storeSettings = integrationSettings[storeId];
      }

      const isAutoAccept =
        currentOrder.store &&
        storeSettings &&
        storeSettings.preferences?.onlineOrdering?.autoAcceptOrders;
      const isCurrentDevice =
        currentOrder.store &&
        storeSettings &&
        session?.device?.id ===
          storeSettings.preferences?.onlineOrdering?.printDevice;
      // If auto accept is enabled, order is in pending state and current device is assigned to printing
      // ---> accept the order and print kitchen docket
      if (
        currentOrder.status === OrderStatus.CREATED &&
        isAutoAccept &&
        isCurrentDevice
      ) {
        acceptOnlineOrders([currentOrder]);
      }
      // If order is not in pending state and current device is assigend to printing
      // ---> call print docket and print if any item is not fired
      // ex: Cancel order on partner side
      else if (currentOrder.status !== OrderStatus.CREATED && isCurrentDevice) {
        kitchenOrderEvents.publishToKotUtil({
          orderId: currentOrder?.id,
          preEvents: [],
        });
      }
    },
    [
      acceptOnlineOrders,
      integrationPartnersSettings,
      session.currentStore?.id,
      session?.device,
    ],
  );

  const handleSaveOnlineOrder = useCallback(
    (saveOrder: Order) => {
      if (!!saveOrder?.integrationInfo?.id) {
        const storeId = session.currentStore?.id || '';
        const settings = integrationPartnersSettings?.find(
          setting =>
            setting[storeId] &&
            setting[storeId].appName === saveOrder.integrationInfo?.app,
        );
        if (!settings) {
          setUnprocessedOnlineOrder(orders => {
            if (orders && orders?.length > 0) return [...orders, saveOrder];
            else return [saveOrder];
          });
          saveOrder &&
            saveOrder.integrationInfo &&
            saveOrder.store &&
            getIntegrationPartnerSettings({
              appName: saveOrder.integrationInfo.app,
              store: session.currentStore?.id,
            });
        } else {
          handleUnprocessedOrder(saveOrder, integrationPartners);
        }
      }
    },
    [
      getIntegrationPartnerSettings,
      handleUnprocessedOrder,
      integrationPartnersSettings,
      session.currentStore?.id,
      integrationPartners,
    ],
  );

  useEffect(() => {
    if (
      unprocessedOnlineOrder &&
      unprocessedOnlineOrder?.length > 0 &&
      Object.keys(allIntegrationPartners).length &&
      !integrationSettingsLoading
    ) {
      const ordersToHandle = [...unprocessedOnlineOrder];
      let i = ordersToHandle.length;
      while (i--) {
        const currentOrder = ordersToHandle[i];
        handleUnprocessedOrder(currentOrder, integrationPartners);
        ordersToHandle.splice(i, 1);
      }
      setIntegrationPartnersSettings(settings => [
        ...(settings || []),
        integrationPartners,
      ]);
      setUnprocessedOnlineOrder(ordersToHandle);
    }
  }, [
    allIntegrationPartners,
    handleUnprocessedOrder,
    integrationPartners,
    integrationSettingsLoading,
    unprocessedOnlineOrder,
  ]);

  const [saveOrder] = useMutation(ORDER_SAVE, {
    onCompleted: data => {
      handleSaveOnlineOrder(data.saveOrder);
    },
  });
  const getOrdersFromCache = useCallback(
    (status: OrderStatus, isOnline?: boolean) => {
      if (client) {
        const clientOrders = client.cache.readQuery({
          query: GET_ORDERS,
          returnPartialData: true,
          variables: { filter: { status, ...(isOnline && { isOnline }) } },
        }) as { orders: Order[] };
        const orders = clientOrders?.orders;
        return orders ? orders : [];
      }
    },
    [client],
  );

  const isValidOrderEvents = (
    events: OrderEvent[],
    isExistingOrder: boolean,
    lastProcessedEvent: string,
  ) => {
    return isExistingOrder
      ? orderUpdateEventsValidation(events, lastProcessedEvent)
      : orderAddedEventsValidation(events);
  };

  /**
   * Filter events to process
   * @param events All events sent to process.
   * @param lastProcessedEvent Last processed event.
   * @returns Events left to process after 'prevEventId'.
   */
  const filterEventsToProcess = (
    events: OrderEvent[],
    lastProcessedEvent: string,
  ) => {
    const nextEventToProcessIndex = events.findIndex(
      x => x.previous === lastProcessedEvent,
    );
    if (nextEventToProcessIndex === -1) return [];
    return events.slice(nextEventToProcessIndex);
  };

  const customerMapper = useCallback((customer: Customer) => {
    return {
      id: customer.id,
      firstName: customer.firstName,
      lastName: customer.lastName,
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
      preferredAddress: {
        line1: customer.preferredAddress?.line1,
        city: customer.preferredAddress?.city,
        postalCode: customer.preferredAddress?.postalCode,
        isoCountryCode: customer.preferredAddress?.isoCountryCode,
        __typename: 'Address',
      },
      __typename: 'Customer',
    };
  }, []);

  const orderEventsHandler = useCallback(
    async (events: OrderEvent[]) => {
      const openOrders = getOrdersFromCache(OrderStatus.IN_PROGRESS) || [];
      const completedOrders = getOrdersFromCache(OrderStatus.COMPLETED) || [];
      const pendingOnlineOrders =
        getOrdersFromCache(OrderStatus.CREATED, true) || [];
      const openOnlineOrders =
        getOrdersFromCache(OrderStatus.IN_PROGRESS, true) || [];
      const orders = [
        ...openOrders,
        ...completedOrders,
        ...pendingOnlineOrders,
        ...openOnlineOrders,
      ];
      const productMap = keyBy(getProductsFromCache(), 'id');
      const updatedProducts: IMap<Product> = {};

      const beginEvent = events[0];
      const isRefundOrder =
        beginEvent.action === OrderAction.ORDER_REFUND_INITIATE;
      const orderId = isRefundOrder
        ? (beginEvent as InitiateRefundEvent)?.refundOf
        : beginEvent.orderId;
      const existingOrder = orders?.find(
        (order: Order) => order.id === orderId,
      );
      const isExistingOrder = existingOrder ? true : false;
      const eventsNotProcessed =
        isExistingOrder && !isRefundOrder
          ? filterEventsToProcess(events, existingOrder?.prevEventId as string)
          : events;

      eventsNotProcessed.forEach(event => {
        if (EVENTS_FOR_PRODUCT_QUANTITY_UPDATE.includes(event.action)) {
          if (event.action === OrderAction.ORDER_VOID) {
            const { productQuantities } = event as VoidOrderEvent;
            productQuantities.forEach(productDetails => {
              const product = updateProductQuantity(
                productMap,
                productDetails.id,
                productDetails.quantity,
              );

              productMap[productDetails.id] = product;
              updatedProducts[productDetails.id] = product;
            });
          } else {
            const product = updateProductQuantity(
              productMap,
              (event as AddOrderItemEvent).productId,
              (event as AddOrderItemEvent).quantity,
            );

            productMap[(event as AddOrderItemEvent).productId] = product;
            updatedProducts[(event as AddOrderItemEvent).productId] = product;
          }
        }
      });
      Object.keys(updatedProducts).length &&
        updateProductsInCache(updatedProducts);

      const isValid: boolean = isRefundOrder
        ? true
        : isValidOrderEvents(
            eventsNotProcessed,
            isExistingOrder,
            existingOrder?.prevEventId || '',
          );
      if (isValid) {
        const computedOrder = existingOrder
          ? computeOrderState(eventsNotProcessed, existingOrder)
          : computeOrderState(eventsNotProcessed);
        if (computedOrder.customer) {
          const data = client.cache.readQuery({
            query: GET_CUSTOMERS,
            returnPartialData: true,
          }) as { customers: Customer[] };
          if (data?.customers) {
            client.cache.writeQuery({
              query: GET_CUSTOMERS,
              data: {
                customers: [
                  ...data?.customers,
                  customerMapper(computedOrder.customer),
                ],
              },
            });
          }
        }
        // Set lastSyncedEventId
        computedOrder.lastSyncedEventId = computedOrder.prevEventId;
        if (
          computedOrder.status === OrderStatus.CREATED &&
          !!computedOrder.integrationInfo?.id &&
          isValidOnlineOrder(computedOrder)
        ) {
          pendingOnlineOrdersCountVar(1);
        }
        saveOrder({ variables: { data: computedOrder } });
      }
    },
    [
      getOrdersFromCache,
      getProductsFromCache,
      updateProductsInCache,
      saveOrder,
      client,
      customerMapper,
    ],
  );

  return useMemo(
    () => ({
      orderEventsHandler,
      handleSaveOnlineOrder,
    }),
    [orderEventsHandler, handleSaveOnlineOrder],
  );
};
