import { StyleFn } from '@hitz-group/domain';

export const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  textAlign: 'center',
  alignItems: 'center',
  width: 680,
});

export const detailsContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big + theme.spacing.small,
  width: 680,
  borderRadius: theme.radius.small,
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.boxBorder,
  borderTopWidth: 1,
  borderTopColor: theme.colors.boxBorder,
});

export const detailsTileStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big + theme.spacing.small,
});

export const aboutContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  paddingVertical: theme.spacing.big,
  paddingLeft: theme.spacing.big,
  alignItems: 'center',
});

export const ctaTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
  marginTop: theme.spacing.medium,
  lineHeight: theme.spacing.medium,
  color: theme.colors.primary,
  fontFamily: theme.font.semibold,
});

export const detailsTitleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  marginTop: theme.spacing.medium,
  lineHeight: theme.spacing.medium,
  color: theme.colors.primary,
  fontFamily: theme.font.semibold,
});

export const ctaSubTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  lineHeight: theme.spacing.medium,
  marginTop: theme.spacing.small,
  letterSpacing: 1,
});

export const imageContainerStyle: StyleFn = ({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: theme.radius.medium,
  marginTop: theme.spacing.big,
});

export const connectButtonStyle: StyleFn = ({ theme }) => ({
  width: 120,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.greenLighter,
  alignSelf: 'center',
  marginTop: theme.spacing.medium,
});

export const disconnectButtonStyle: StyleFn = ({ theme }) => ({
  ...connectButtonStyle({ theme }),
  backgroundColor: theme.colors.danger2,
});

export const connectTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
  letterSpacing: -0.4,
});

export const disconnectTitleStyle: StyleFn = ({ theme }) => ({
  ...connectTitleStyle({ theme }),
  color: theme.colors.danger,
});
