import { StyleFn } from '@hitz-group/domain';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFela } from 'react-fela';
import { View, ScrollView } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../../../components/FormInput/FormInput';
import { Customer } from '@hitz-group/domain';
import { DEFAULT_COUNTRY_CODE } from '../../../../../../constants';
import { Operation } from '../../../../../../types/Operation';
import Button from '../../../../../../components/Button/Button';
import { useNotification } from '../../../../../../hooks/Notification';
import { useCustomers } from '../../../../../../hooks/orders/useCustomers';
import ConfirmationModal from '../../../../../../components/Modals/ConfirmationDialog';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import { getCurrencySymbol } from '@hitz-group/localization';
import { useSession } from '../../../../../../hooks/app/useSession';
import { countries } from 'countries-list';

interface MenuRowProps {
  onChange: (id: string, prop: string, value: string | boolean) => void;
  onPressCopy: (id: string) => void;
  customerInfo?: Customer;
  action: string;
  checkEmailExists: (email?: string, customerId?: string) => boolean;
}

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});
const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big + theme.spacing.medium,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  height: theme.input.height,
});

const formDropdownContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 120,
  height: theme.input.height,
});

const accountSalesStye: StyleFn = ({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: theme.colors.white,
});

const formContainerStyle: StyleFn = ({ theme }) => ({
  width: 540,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  paddingBottom: theme.spacing.big,
});

const prefixItemStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  padding: theme.spacing.small / 2 + 5,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.bold,
  borderRadius: theme.radius.small,
  lineHeight: 15,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
  ...theme.shadow15,
  paddingLeft: theme.padding.large,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const deleteButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginRight: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  alignSelf: 'auto',
});

const dangerTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
  letterSpacing: -0.5,
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
});

const checkBoxTitleStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  textTransform: 'none',
  color: theme.colors.paragraph,
});
const checkContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.big / 2,
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: theme.spacing.small,
  alignSelf: 'flex-start',
});
interface ICustomerForm {
  id?: string;
  firstName: string;
  lastName: string;
  line1: string;
  email: string;
  city: string;
  postalCode: string;
  country: string;
  phone: string;
  maxOrderLimit: number;
  maxBalanceLimit: number;
  currentBalance: number;
  accountPayment: boolean;
  state: string;
}

export const CustomerDetails: React.FC<MenuRowProps> = ({}: MenuRowProps) => {
  const { css, theme } = useFela();
  const [paymentEnabled, setPaymentEnable] = useState(false);
  const [accountPaymentEnabled, setAccountPayment] = useState(false);
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const route = useRoute();
  const { showModal, closeModal } = useModal();
  const routeParams = route.params as {
    customer: Customer;
  };
  const { customer: customerProp } = routeParams;
  const [form, setForm] = useState<ICustomerForm>({
    id: '',
    firstName: '',
    lastName: '',
    line1: '',
    email: '',
    city: '',
    postalCode: '',
    country: DEFAULT_COUNTRY_CODE,
    phone: '',
    maxOrderLimit: 0,
    maxBalanceLimit: 0,
    currentBalance: 0,
    accountPayment: false,
    state: '',
  });
  const [customerUpdated, setCustomerUpdated] = useState(false);
  const action = useRef('');
  const customerInfo = customerProp;
  const {
    updateCustomer,
    loading,
    error,
    deleteCustomer,
    getCustomerById,
    customerMaps,
  } = useCustomers();

  const customer = customerMaps[customerProp.id];
  const removeCustomer = useCallback(
    async (customerId: string) => {
      action.current = Operation.DELETE;
      await deleteCustomer(customerId);
      navigation.navigate('ManageCustomers');
    },
    [deleteCustomer, navigation],
  );

  useEffect((): void => {
    getCustomerById(customerProp.id);
  }, [customerProp.id, getCustomerById]);
  const [session] = useSession();
  const { country = 'AU' } = session?.currentOrganization || {};
  const currencyCode = countries[country]?.currency;
  useEffect((): void => {
    if (customerProp?.customerAccountDetails?.accountPayment === true) {
      setPaymentEnable(true);
      setAccountPayment(true);
    } else {
      setPaymentEnable(false);
      setAccountPayment(false);
    }
  }, [customerProp?.customerAccountDetails?.accountPayment]);

  const togglePayment = useCallback((): void => {
    setPaymentEnable(prevCheck => !prevCheck);
    setAccountPayment(prevCheck => !prevCheck);
  }, []);

  useEffect(() => {
    if (customer) {
      const { id, firstName, lastName, phone, email, preferredAddress } =
        customer;
      setForm({
        id,
        firstName,
        lastName,
        line1: (preferredAddress && preferredAddress.line1) || '',
        city: (preferredAddress && preferredAddress.city) || '',
        state: (preferredAddress && preferredAddress.state) || '',
        postalCode: (preferredAddress && preferredAddress.postalCode) || '',
        country: preferredAddress?.isoCountryCode
          ? preferredAddress?.isoCountryCode
          : DEFAULT_COUNTRY_CODE,
        phone,
        email,
        maxOrderLimit: customer?.customerAccountDetails
          ?.maxOrderLimit as number,
        maxBalanceLimit: customer?.customerAccountDetails
          ?.maxBalanceLimit as number,
        currentBalance: customer?.customerAccountDetails
          ?.currentBalance as number,
        accountPayment: customer?.customerAccountDetails
          ?.accountPayment as boolean,
      });
    }
  }, [customer]);

  useEffect(() => {
    const action = Operation.UPDATE;
    if (
      action === Operation.UPDATE &&
      customerInfo &&
      customerInfo.preferredAddress
    ) {
      const { isoCountryCode, ...address } = customerInfo.preferredAddress;
      setForm({
        id: customerInfo.id,
        firstName: customerInfo.firstName,
        lastName: customerInfo.lastName,
        line1: address.line1,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: isoCountryCode ? isoCountryCode : DEFAULT_COUNTRY_CODE,
        phone: customerInfo.phone,
        email: customerInfo.email,
        maxOrderLimit: customerInfo?.customerAccountDetails
          ?.maxOrderLimit as number,
        maxBalanceLimit: customerInfo?.customerAccountDetails
          ?.maxBalanceLimit as number,
        currentBalance: customerInfo?.customerAccountDetails
          ?.currentBalance as number,
        accountPayment: customerInfo?.customerAccountDetails
          ?.accountPayment as boolean,
      });
    }
  }, [action, customerInfo]);
  const customerName = customerInfo.firstName;
  useEffect(() => {
    if (!error && !loading && customerUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeCustomers.editSuccessMessage', {
          customerName,
        }),
      });
    }
  }, [
    loading,
    showNotification,
    translate,
    error,
    customerUpdated,
    customerName,
  ]);

  const onChangeFormInput = useCallback((prop: string, value: string) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const setCustomerData = useCallback(() => {
    const { firstName, lastName, email, phone } = form;

    const preferredAddress = {
      line1: form.line1,
      city: form.city,
      postalCode: form.postalCode,
      isoCountryCode: form.country,
    };
    const customerAccountDetails = {
      maxOrderLimit: Number(form.maxOrderLimit),
      maxBalanceLimit: Number(form.maxBalanceLimit),
      currentBalance: Number(form.currentBalance),
      accountPayment: paymentEnabled,
    };
    const customer = {
      id: form.id,
      firstName,
      lastName,
      email,
      phone,
      preferredAddress,
      customerAccountDetails,
    } as unknown as Customer;
    return customer;
  }, [form, paymentEnabled]);

  const onDelete = useCallback(
    (customerId: string, customerName: string): void => {
      showModal(
        <ConfirmationModal
          title={translate('backOfficeCustomers.deletePopUpPromptHeader')}
          message={translate('backOfficeCustomers.deletePopUpPromptBody', {
            customerName,
          })}
          onConfirm={() => {
            closeModal();
            removeCustomer(customerId);
          }}
        />,
      );
    },
    [showModal, translate, closeModal, removeCustomer],
  );

  const editCustomer = useCallback(async () => {
    const customer = setCustomerData();
    if (customer) {
      await updateCustomer(customer);
      setCustomerUpdated(true);
      navigation.navigate('ManageCustomers');
    }
  }, [navigation, setCustomerData, updateCustomer]);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView style={css(accountSalesStye)}>
          <BackOfficeSection
            title={translate('form.customerDetails')}
            containerStyle={css(backOfficeContainerStyle)}
            contentContainerStyle={css(formContainerStyle)}
            titleBorderBottom
          >
            <FormInput
              error={false}
              placeholder={translate('form.firstName')}
              title={translate('form.firstName')}
              value={form?.firstName}
              onChangeText={text => {
                onChangeFormInput('firstName', text);
              }}
              testID="form-firstname"
              alignTitle="left"
              containerStyle={css(formInputContainerStyle)}
            />
            <FormInput
              error={false}
              placeholder={translate('form.lastName')}
              title={translate('form.lastName')}
              onChangeText={text => {
                onChangeFormInput('lastName', text);
              }}
              value={form?.lastName}
              alignTitle="right"
              containerStyle={css(formInputContainerStyle)}
            />
            <FormInput
              error={false}
              placeholder={translate('form.phone')}
              title={translate('form.phone')}
              onChangeText={onChangeFormInput.bind(null, 'phone')}
              value={form?.phone}
              alignTitle="right"
              containerStyle={css(formInputContainerStyle)}
            />
            <FormInput
              error={false}
              placeholder={translate('form.email')}
              title={translate('form.email')}
              onChangeText={onChangeFormInput.bind(null, 'email')}
              value={form?.email}
              alignTitle="right"
              containerStyle={css(formInputContainerStyle)}
            />
            <FormInput
              error={false}
              placeholder={translate('form.addressLine')}
              title={translate('form.addressLine')}
              onChangeText={onChangeFormInput.bind(null, 'line1')}
              value={customerProp?.preferredAddress?.line1}
              alignTitle="right"
              containerStyle={css(formInputContainerStyle)}
            />
            {/* <FormInput
              error={false}
              placeholder={translate('form.suburb')}
              title={translate('form.suburb')}
              onChangeText={onChangeFormInput.bind(null, 'city')}
              value={customer?.preferredAddress?.city}
              alignTitle="right"
              containerStyle={css(formInputContainerStyle)}
            /> */}
            <FormInput
              error={false}
              placeholder={translate('form.city')}
              title={translate('form.city')}
              onChangeText={onChangeFormInput.bind(null, 'city')}
              value={form.city}
              alignTitle="right"
              containerStyle={css(formInputContainerStyle)}
            />
            <FormInput
              error={false}
              placeholder={translate('form.state')}
              title={translate('form.state')}
              value={form.state}
              alignTitle="right"
            />
            <FormInput
              error={false}
              placeholder={translate('form.postCode')}
              title={translate('form.postCode')}
              onChangeText={onChangeFormInput.bind(null, 'postalCode')}
              value={form.postalCode}
              alignTitle="right"
              containerStyle={css(formDropdownContainerStyle)}
              maxLength={6}
            />
            <FormInput
              error={false}
              placeholder={translate('form.country')}
              title={translate('form.country')}
              onChangeText={onChangeFormInput.bind(null, 'country')}
              value={form.country || ''}
              alignTitle="right"
              containerStyle={css(formInputContainerStyle)}
            />
          </BackOfficeSection>
          <BackOfficeSection
            title={translate('form.accountDetails')}
            titleBorderBottom
          >
            <Button
              title={translate('form.enableAccountPayment')}
              testID={'togglePayment'}
              labelStyle={css(checkBoxTitleStyle)}
              onPress={togglePayment}
              fluid
              iconPosition={'left'}
              containerStyle={css(checkBoxTitleContainer)}
              iconContainerStyle={css(checkContainer)}
              icon={paymentEnabled === true ? 'toggle-on' : 'toggle-off'}
              iconProps={{
                color:
                  paymentEnabled === true
                    ? theme.colors.success
                    : theme.colors.paragraph,
                size: 26,
              }}
            />
            <View style={css(formContainerStyle)}>
              <FormInput
                error={false}
                placeholder={translate('form.maxOrderLimit')}
                title={translate('form.maxOrderLimit')}
                onChangeText={onChangeFormInput.bind(null, 'maxOrderLimit')}
                value={
                  form.maxOrderLimit ? form.maxOrderLimit.toString() : undefined
                }
                alignTitle="right"
                prefix={{
                  text: getCurrencySymbol(currencyCode),
                  textStyle: css(prefixItemStyle),
                }}
                containerStyle={css(formInputContainerStyle)}
              />
              {(accountPaymentEnabled === true && (
                <FormInput
                  error={false}
                  placeholder={translate('form.maxBalanceLimit')}
                  onChangeText={onChangeFormInput.bind(null, 'maxBalanceLimit')}
                  title={translate('form.maxBalanceLimit')}
                  value={
                    form?.maxBalanceLimit
                      ? form?.maxBalanceLimit.toString()
                      : undefined
                  }
                  alignTitle="right"
                  prefix={{
                    text: getCurrencySymbol(currencyCode),
                    textStyle: css(prefixItemStyle),
                  }}
                  containerStyle={css(formInputContainerStyle)}
                />
              )) ||
                null}
              <FormInput
                error={false}
                placeholder={translate('form.currentBalance')}
                onChangeText={onChangeFormInput.bind(null, 'currentBalance')}
                title={translate('form.currentBalance')}
                readOnly
                value={
                  form?.currentBalance
                    ? form?.currentBalance.toString()
                    : undefined
                }
                alignTitle="right"
                prefix={{
                  text: getCurrencySymbol(currencyCode),
                  textStyle: css(prefixItemStyle),
                }}
                containerStyle={css(formInputContainerStyle)}
              />
            </View>
          </BackOfficeSection>
        </ScrollView>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="delete-changes"
            title={translate('button.delete')}
            containerStyle={css(deleteButtonStyle)}
            labelStyle={css(dangerTitleStyle)}
            onPress={() => onDelete(customer?.id || '', customer?.name || '')}
          />
          <Button
            fluid
            testID="updateCustomerButton"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={editCustomer}
          />
        </View>
      </View>
    </>
  );
};
