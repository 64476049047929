import { gql } from '@apollo/client';

export const FRAGMENTS = {
  printerDetails: gql`
    fragment PrinterDetails on Printer {
      id
      name
      ipAddress
      bdAddress
      brand
      printerType
    }
  `,
};

export const GET_PRINTERS_QUERY = gql`
  query printers {
    printers {
      ...PrinterDetails
    }
  }
  ${FRAGMENTS.printerDetails}
`;

export const GET_PRINTER_QUERY = gql`
  query printer($id: ID!) {
    printer(id: $id) {
      ...PrinterDetails
    }
  }
  ${FRAGMENTS.printerDetails}
`;

export const CREATE_PRINTER_MUTATION = gql`
  mutation createPrinter($input: CreatePrinterInput!) {
    createPrinter(input: $input) {
      ...PrinterDetails
    }
  }
  ${FRAGMENTS.printerDetails}
`;

export const COPY_PRINTER_MUTATION = gql`
  mutation copyPrinter($input: CopyPrinterInput!) {
    copyPrinter(input: $input) {
      ...PrinterDetails
    }
  }
  ${FRAGMENTS.printerDetails}
`;

export const UPDATE_PRINTER_MUTATION = gql`
  mutation updatePrinter($input: UpdatePrinterInput!) {
    updatePrinter(input: $input) {
      ...PrinterDetails
    }
  }
  ${FRAGMENTS.printerDetails}
`;

export const UPDATE_PRINTERS_MUTATION = gql`
  mutation updatePrinters($input: [UpdatePrinterInput!]!) {
    updatePrinters(input: $input) {
      ...PrinterDetails
    }
  }
  ${FRAGMENTS.printerDetails}
`;

export const DELETE_PRINTER_MUTATION = gql`
  mutation deletePrinter($input: DeletePrinterInput!) {
    deletePrinter(input: $input)
  }
`;
