import { IntegrationApps, StyleFn } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { useFela } from 'react-fela';
import IconButton from '../../../../../../components/Button/IconButton';
import Label from '../../../../../../components/Label/Label';
import { useTranslation } from '@hitz-group/localization';
import { useNavigation } from '@react-navigation/native';

interface XeroStoreRowProps {
  isEnabled: boolean;
  name: string;
  toggleAccountingIntegrationStoreConnection: (
    storeId: string,
    integrationPartner: IntegrationApps,
  ) => void;
  storeId: string;
}

const iconContainerStyle: StyleFn = ({ theme }) => ({
  width: 25,
  margin: theme.spacing.small,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.padding.medium / 2,
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

const defaultContainerStyle: StyleFn = ({ theme }) => ({
  minWidth: 350,
  height: theme.input.height,
  marginTop: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
});

const defaultTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.medium,
  marginTop: 10,
  ...theme.font14RegularCharcoal,
});

const statusContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'flex-end',
});

const statusStyle: StyleFn = ({ theme }) => ({
  width: 130,
  margin: theme.spacing.small * 1.5,
  marginRight: theme.spacing.small / 2,
});

const labelStyle: StyleFn = ({ theme }) => ({
  paddingVertical: theme.spacing.small / 5,
});

export const XeroStoreRow: React.FC<XeroStoreRowProps> = ({
  isEnabled,
  name,
  toggleAccountingIntegrationStoreConnection,
  storeId,
}: XeroStoreRowProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const navigation = useNavigation();

  const navigateToStoreSettingsPage = useCallback(() => {
    navigation.navigate('XeroSettingsPage', {
      storeId,
      app: IntegrationApps.XERO,
    });
  }, [navigation, storeId]);

  return (
    <View style={css(rowStyle)}>
      <IconButton
        testID="toggle-icon"
        primary
        icon={isEnabled ? 'toggle-on' : 'toggle-off'}
        iconSize={26}
        containerSize={34}
        iconColor={isEnabled ? theme.colors.green : theme.colors.paragraph}
        containerStyle={css(iconContainerStyle)}
        onPress={() =>
          toggleAccountingIntegrationStoreConnection(
            storeId,
            IntegrationApps.XERO,
          )
        }
      />

      <View style={css(defaultContainerStyle)}>
        <Text style={css(defaultTextStyle)}>{name}</Text>
      </View>

      <View style={css(statusContainerStyle)}>
        <View style={css(statusStyle)}>
          <Label
            testID={'storeConnectionStatus'}
            value={translate(
              isEnabled ? 'pricings.active' : 'pricings.inActive',
            )}
            success={isEnabled}
            danger={!isEnabled}
            textStyle={css(labelStyle)}
          />
        </View>
        {isEnabled && (
          <IconButton
            primary
            icon={'AngleRight'}
            iconSize={26}
            containerSize={34}
            iconColor={theme.colors.paragraph}
            containerStyle={css(iconContainerStyle)}
            onPress={navigateToStoreSettingsPage}
          />
        )}
      </View>
    </View>
  );
};
