import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  Venue,
  AssignRoleToUserInput,
  UpdateUserRoleInput,
  UserRoleDef,
  RolePermissions,
  AppAccess,
  App,
  PermittedLocation,
  Role,
} from '@hitz-group/domain';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';
import { getDiffValues } from '@hitz-group/client-utils';
import { stripProperties } from '../../../../../../utils/stripObjectProps';
import { SelectedPermission } from '../../../../../../state/jobRoleSettingsUtility';

export interface StoreUserRoleMapping {
  storeId: string;
  delete?: boolean;
  venueId: string;
}

export interface StoreUserRoleMappingByRole {
  locations: StoreUserRoleMapping[];
  roleId?: string;
  unSaved?: boolean; // unSaved means new item available with POS, API is not aware of this yet
  id: string; // a UUID to keep references b/w API and UI data
}

class UserRoleInputUtility {
  private _formInput = new BehaviorSubject<
    Record<string, StoreUserRoleMappingByRole>
  >({});

  set formInput(value: Record<string, StoreUserRoleMappingByRole>) {
    this._formInput.next(value);
  }

  get formInput(): Record<string, StoreUserRoleMappingByRole> {
    return this._formInput.value;
  }

  get formInput$(): Observable<Record<string, StoreUserRoleMappingByRole>> {
    return this._formInput.asObservable();
  }

  modifyRole(id: string, roleId: string) {
    const latest = this.formInput;
    this._formInput.next({
      ...latest,
      [id]: { ...latest[id], roleId },
    });
  }

  modifyStores(id: string, locations: StoreUserRoleMapping[]) {
    const latest = this.formInput;
    this._formInput.next({ ...latest, [id]: { ...latest[id], locations } });
  }

  addInput(): boolean {
    const latest = this.formInput;
    const inputsWithOutValues = Object.values(latest)
      .filter(userRole => userRole.unSaved === true)
      .filter(userRole => {
        return !(!!userRole.roleId && userRole.locations.length > 0);
      });
    if (inputsWithOutValues.length > 0) {
      return false;
    }
    const data = {
      locations: [],
      id: uuidv4(),
      unSaved: true,
      roleId: undefined,
    };

    this._formInput.next({
      ...latest,
      [data.id]: data,
    });
    return true;
  }

  deleteInput(id: string) {
    const latest = this.formInput;
    delete latest[id];
    return this._formInput.next(latest);
  }

  init(data: Record<string, StoreUserRoleMappingByRole>) {
    this._formInput.next(data);
  }

  reset() {
    this.formInput = {};
  }
}

export const userRoleInputUtility = new UserRoleInputUtility();

export const mapVenueIdsByStoreIds = (venues: Record<string, Venue>) => {
  return Object.values(venues).reduce((acc, venue) => {
    const venueByStoreIds = venue.stores.reduce(
      (storeVenueMap, store) => ({
        ...storeVenueMap,
        [store.id]: venue.id,
      }),
      {} as Record<string, string>,
    );
    return { ...acc, ...venueByStoreIds };
  }, {} as Record<string, string>);
};

export const mapApiPayloadToCreateUserRole = (
  userId: string,
  formValues: Record<string, StoreUserRoleMappingByRole>,
) => {
  return Object.values(formValues)
    .filter(
      userRoleInput =>
        userRoleInput.locations.length > 0 && userRoleInput.roleId,
    )
    .map(userRoleInput => {
      return {
        ...(!userRoleInput.unSaved && { id: userRoleInput.id }),
        userId,
        roleId: userRoleInput.roleId,
        locations: userRoleInput.locations.filter(location => !location.delete),
      } as AssignRoleToUserInput | UpdateUserRoleInput;
    })
    .flat();
};

export const mapUserRolesByRoleIdAndStoreIds = (
  userRolesById: Record<string, UserRoleDef>,
): Record<string, StoreUserRoleMappingByRole> => {
  return Object.values(userRolesById).reduce<
    Record<string, StoreUserRoleMappingByRole>
  >((acc, userRole) => {
    const locations = userRole.locations.map(location => {
      return {
        storeId: location.store.id,
        venueId: location.venue.id,
      };
    });

    return {
      ...acc,
      [userRole.id]: {
        id: userRole.id,
        locations,
        roleId: userRole.role.id,
      },
    };
  }, {});
};

export const hasOperationsChanged = <T extends RolePermissions>(
  oldPermission: T,
  newPermission: T,
): boolean => {
  if (newPermission.id === oldPermission.id) {
    if (typeof newPermission.operations === 'boolean') {
      return newPermission.operations === oldPermission.operations;
    } else if (
      Array.isArray(newPermission.operations) &&
      Array.isArray(oldPermission.operations)
    ) {
      return isEqual(
        newPermission.operations.slice().sort(),
        (oldPermission.operations as string[]).slice().sort(),
      );
    }
  }
  return false;
};

export const getUpdatedPermissions = <T extends RolePermissions>(
  permissionsInput: T[],
  oldPermissions: T[],
) => {
  return differenceWith<T, T>(
    permissionsInput.slice().sort((p1, p2) => p1.id.localeCompare(p2.id)),
    oldPermissions.slice().sort((p1, p2) => p1.id.localeCompare(p2.id)),
    hasOperationsChanged,
  );
};

/**
 * Return the final app access value from base role and user-role
 *
 * @param apps
 * @param app
 * @param defaultValue
 * @returns
 */
export const computeAppAccess = (
  apps: AppAccess,
  app: App,
  defaultValue: AppAccess,
): boolean | undefined => {
  if (apps) {
    if (typeof apps[app] === 'boolean') {
      return apps[app];
    }
    if (typeof defaultValue[app] === 'boolean') {
      return defaultValue[app];
    }
  }
  if (defaultValue) {
    if (typeof defaultValue[app] === 'boolean') {
      return defaultValue[app];
    }
  }
  return;
};

export const getUpdateUserRoleInput = (
  selectedPermissionsById: SelectedPermission,
  rolesById: Record<string, Role>,
  appsInput: AppAccess,
  selectedLocations: PermittedLocation[],
  selectedRole: string,
  userRole: UserRoleDef,
): UpdateUserRoleInput => {
  const currentRoleOverridePermissions = rolesById[selectedRole].permissions;
  const currentRoleApps = rolesById[selectedRole].apps as AppAccess;
  const permissionsInput = Object.keys(selectedPermissionsById)
    .filter(id => typeof id !== 'undefined')
    .reduce<RolePermissions[]>((acc, id) => {
      const permission = {
        id,
        operations: selectedPermissionsById[id],
      };
      acc.push(permission);
      return acc;
    }, []);

  const apps = getDiffValues<AppAccess, App.POS_APP | App.BACKOFFICE>(
    [App.BACKOFFICE, App.POS_APP],
    currentRoleApps,
    appsInput,
  );

  const overridePermissions = getUpdatedPermissions(
    permissionsInput,
    currentRoleOverridePermissions,
  );
  return {
    apps:
      Object.keys(apps).length > 0
        ? stripProperties({ ...currentRoleApps, ...appsInput }, '__typename')
        : undefined,
    overridePermissions,
    roleId: selectedRole || userRole.role.id,
    id: userRole.id,
    locations: selectedLocations,
    userId: userRole.id,
  };
};

export const groupPermissionsByIdAndOperations = (
  permissions: RolePermissions[],
) => {
  return permissions.reduce(
    (acc, permission) => ({ ...acc, [permission.id]: permission.operations }),
    {},
  );
};
