import React, { useMemo } from 'react';
import { View } from 'react-native';
import { ResultSet } from '@cubejs-client/core';
import {
  OrderItem,
  OrderItemModifier,
  OrderItemStatus,
} from '@hitz-group/domain';
import { PreviewStyles } from '../styles/Component.styles';
import CartItem from '../../../../components/Cart/CartItem/CartItem';
import { IMap } from '../types';
import { computeOrderItemTotal } from '@hitz-group/order-helper';
import groupBy from 'lodash/groupBy';

interface OrderItemListProps {
  itemsData: ResultSet | undefined;
}

export const OrderItemList: React.FC<OrderItemListProps> = ({ itemsData }) => {
  const basicItemDetails = (item: IMap<string | number | boolean>) => ({
    id: item['OrderItems.orderItemId'],
    quantity: +item['OrderItems.quantity'],
    unitPrice: +item['OrderItems.unitPrice'],
    status: item['OrderItems.itemStatus'],
    reason: item['OrderItems.reason'],
  });

  const orderItems = useMemo(() => {
    const data =
      (itemsData && itemsData.tablePivot && itemsData.tablePivot()) || [];
    const groupedItemData = Object.values(
      groupBy(data, 'OrderItems.orderItemId'),
    );

    return groupedItemData.map(items => {
      const productIndex = items.findIndex(
        item => item['OrderItems.belongsTo'] === '',
      );

      const product = items.splice(productIndex, 1);

      const item = {
        ...basicItemDetails(product[0]),
        product: {
          id: product[0]['OrderItems.productId'],
          name: product[0]['OrderItems.productName'],
        },
        modifiers: [],
      } as unknown as OrderItem;

      for (let i = 0; i < items.length; i++) {
        item.modifiers.push({
          ...basicItemDetails(items[i]),
          name: items[i]['OrderItems.productName'],
        } as unknown as OrderItemModifier);
      }

      return item;
    });
  }, [itemsData]);

  const styles = PreviewStyles();

  return (
    <View testID={'order-items'} style={styles.tableStyle}>
      {orderItems.map((item, index) => {
        const product = item.product;
        return (
          <CartItem
            id={item?.id}
            key={item?.id}
            notes={item?.notes}
            productId={product?.id}
            name={product?.name}
            quantity={Math.abs(item?.quantity)}
            modifiers={item?.modifiers}
            price={computeOrderItemTotal(item)}
            discountAmount={item?.discountAmount}
            status={item?.status}
            reason={item?.reason}
            isRefundOrder={item?.status === OrderItemStatus.REFUNDED}
            disabled
            testID={`sales-feed-preview-order-item-${index}`}
          />
        );
      })}
    </View>
  );
};
