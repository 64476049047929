import { table, getBorderCharacters, TableUserConfig } from 'table';
//import { responseData } from 'services/payments/src/helpers/mapper';

export type FixedTuple = [string, string, string];
/**
 * Payment details section has two columns and `n` row(s)
 */
const config: TableUserConfig = {
  columns: {
    0: {
      width: 17,
      alignment: 'left',
    },
    1: {
      width: 1,
    },
    2: {
      width: 20,
      alignment: 'right',
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawHorizontalLine: () => {
    return false;
  },
};
// {
//   "txdate": "08/04/2022",
//   "txtime": "21:23:36",
//   "pan": "****9999",
//   "panSeq": "33",
//   "preferredName": "mc en gbr gbp",
//   "cardType": "mc",
//   "paymentMethod": "mc",
//   "paymentMethodVariant": "mc",
//   "posEntryMode": "Contactless chip",
//   "aid": "A000000004101001",
//   "mid": "50",
//   "tid": "e280-347239280",
//   "ptid": "47239280",
//   "authCode": "123456",
//   "txRef": "CD5s001649417016012",
//   "mref": "SP-22-6-20220408",
//   "txtype": "GOODS_SERVICES",
//   "totalAmount": "$18.00"
// }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const paymentResponse = (paymentReceipt: any): string => {
  const processedData = `${paymentReceipt.txdate}`.replace(/\//g, '-');
  const paymentsTable = [
    ['Transaction Date', '', ` ${processedData} ${paymentReceipt.txtime}`],
    ['Card Type', '', paymentReceipt.cardType],
    ['Card No.', '', paymentReceipt.pan],
    ['PAN Seq.', '', paymentReceipt.panSeq],
    ['Pref.name', '', paymentReceipt.preferredName],
    ['Payment Method', '', paymentReceipt.paymentMethod],
    ['Payment Variant', '', paymentReceipt.paymentMethodVariant],
    ['Entry Mode', '', paymentReceipt.posEntryMode],
    ['AID', '', paymentReceipt.aid],
    ['MID', '', paymentReceipt.mid],
    ['TID', '', paymentReceipt.tid],
    ['PTID', '', paymentReceipt.ptid],
    ['Auth Code', '', paymentReceipt.authCode],
    ['Tender', '', paymentReceipt.txRef],
    ['Reference', '', paymentReceipt.mref],
    ['Type', '', paymentReceipt.txtype],
    ['Total', '', paymentReceipt.totalAmount],
  ];

  return table(paymentsTable, config);
};
