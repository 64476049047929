import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import SettingsScreen from './SettingsScreen';
import POSMenuEditor from './POSMenuEditor';

const Stack = createStackNavigator();

const SettingsStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="Account"
    screenOptions={{ animationEnabled: true }}
  >
    <Stack.Screen component={SettingsScreen} name="Account" />
    <Stack.Screen component={POSMenuEditor} name="POSMenuEditor" />
  </Stack.Navigator>
);

export default SettingsStack;
