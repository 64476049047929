import { Currency, OrderPaymentStatus, PaymentType } from '@hitz-group/domain';

export const SUPPORTED_IMAGE_FORMATS = ['png', 'jpeg', 'jpg'];

export const OOLIO_PAY_PAYMENT_TYPE = 'Card';

export const DEFAULT_PAYMENT_CURRENCY = Currency.AUD;

export const MAX_KEYPAD_INPUT = 6;

export type AdditionalPaymentInfo = {
  paymentRequestId?: string;
  paymentTransactionRef?: string;
  paymentCompletedAt?: string;
  paymentStatus?: OrderPaymentStatus;
  paymentReceipt?: object;
  paymentSurcharge?: number;
};

export const BUFFER_TIME_TO_FETCH_ORDER = 1000 * 60 * 30;

export const IN_COMPLETE_PAYMENT_STATUS_LIST = [
  OrderPaymentStatus.CANCELLED,
  OrderPaymentStatus.REJECTED,
  OrderPaymentStatus.PENDING,
];

export interface TempPaymentInfo {
  requestId?: string;
  paymentType: PaymentType;
  orderAmount: number;
  surchargeAmount?: number;
  cancellationRequested?: boolean;
}

export const PAYMENT_INPROGRESS_MESSAGES = ['Uncompleted transaction'];

export const POS_PAYMENT_CANCEL_MESSAGES = [
  '104 Merchant cancelled tx',
  '224 Merchant cancelled during authorize online',
  'Transaction aborted',
];

export const ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER = ['cash'];
