import gql from 'graphql-tag';

const pagesFragment = `
  id
  name
  color
  products {
    id
    priority
  }
  variants {
    id
    priority
    products {
      id
      priority
    }
  }
  pages {
    id
    priority
  }
`;
export const GET_PAGES_QUERY = gql`
  query pages {
    pages {
      ${pagesFragment}
    }
  }
`;

export const GET_PAGE_QUERY = gql`
  query page($input: String!) {
    page(id: $input) {
      ${pagesFragment}
    }
  }
`;

export const CREATE_PAGES = gql`
  mutation createPages($input: [CreatePageInput]) {
    createPages(input: $input) {
      ${pagesFragment}
    }
  }
`;

export const UPDATE_PAGES = gql`
  mutation updatePages($input: [UpdatePageInput]) {
    updatePages(input: $input) {
      ${pagesFragment}
    }
  }
`;

export const DELETE_PAGES = gql`
  mutation deletePage($input: ID) {
    deletePage(id: $input)
  }
`;

export const ADD_OR_REMOVE_AVAILABILITY = gql`
  mutation addOrRemoveAvailability(
    $pageId: ID
    $addProductIds: [ID!]!
    $addVariants: [ID!]!
    $addPages: [ID!]!
    $removeProducts: [ID!]!
    $removePages: [ID!]!
    $removeVariants: [ID!]!
  ) {
    addProductsToPage(pageId: $pageId, productIds: $addProductIds) {
      id
    }
    addVariantsToPage(pageId: $pageId, variantIds: $addVariants) {
      id
    }
    addPagesToPage(parent: $pageId, children: $addPages) {
      id
    }
    removeProductsFromPage(pageId: $pageId, productIds: $removeProducts)
    removePagesFromPage(parent: $pageId, children: $removePages)
    removeVariantsFromPage(pageId: $pageId, variantIds: $removeVariants)
  }
`;

export const UPDATE_PAGE_ENTITY_ORDER = gql`
  mutation updatePageItemsOrdering($pageId: ID, $item: [PageItemOrderInput]) {
    updatePageItemsOrdering(pageId: $pageId, item: $item)
  }
`;
