import {
  StyleFn,
  ClientAppPermission,
  ResourceOperation,
  Icons,
} from '@hitz-group/domain';
import React, { useState, useEffect, FC } from 'react';
import { useFela } from 'react-fela';
import { TouchableOpacity } from 'react-native';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { jobRoleSettingsUtility } from '../../../../state/jobRoleSettingsUtility';
import Icon from '../../../../components/Icon/Icon';

const checkbox: StyleFn = () => ({
  marginLeft: 30,
  marginRight: 5,
});

const PermissionCheckBox: FC<{
  permission: ClientAppPermission;
  operation?: ResourceOperation;
}> = ({ permission, operation }) => {
  const { css, theme } = useFela();
  const [checked, setChecked] = useState(false);
  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    const subscription: Subscription =
      jobRoleSettingsUtility.selectedPermissions$
        .pipe(pluck(permission.id), distinctUntilChanged())
        .subscribe(selectedPermissionValue => {
          if (permission.isAtomic) {
            setChecked(selectedPermissionValue as boolean);
          } else {
            setChecked(
              (selectedPermissionValue as ResourceOperation[])?.includes(
                operation as ResourceOperation,
              ),
            );
          }
        });
    return () => {
      subscription.unsubscribe();
    };
  }, [permission, operation]);

  useEffect(() => {
    const subscription: Subscription = jobRoleSettingsUtility.isEditable$
      .pipe(distinctUntilChanged())
      .subscribe(setIsEditable);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onOperationSelected = () => {
    if (isEditable) {
      jobRoleSettingsUtility.setPermissionsToQueue(
        operation as ResourceOperation,
        permission,
      );
    }
  };
  return (
    <TouchableOpacity
      disabled={!isEditable}
      key={permission.id}
      onPress={onOperationSelected}
      style={css(checkbox)}
    >
      <Icon
        name={checked ? Icons.CheckSquare : Icons.SquareFull}
        color={
          !isEditable
            ? theme.colors.darkGrey
            : checked
            ? theme.colors.success
            : theme.colors.charcoal
        }
      />
    </TouchableOpacity>
  );
};

export default PermissionCheckBox;
