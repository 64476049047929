import React, { useCallback } from 'react';
import { View } from 'react-native';
import Title from '../../../components/Title/Title';
import Label from '../../../components/Label/Label';
import { format } from 'date-fns';
import IconButton from '../../../components/Button/IconButton';
import { PrintJobsListRowStyles } from './PrintJobs.styles';
import TableRow from '../../../components/TableComponent/TableRow';
import { PrintJob } from './PrintJobs';
import { Icons, Order, OrderTypeCode } from '@hitz-group/domain';
import { PrintWorkerActionData, WorkerAction } from '../../../workers/utils';

export interface PrintJobRowProps {
  printJob: PrintJob;
  reprint: (printJob: PrintJob) => void;
  remove: (printJobs: PrintJob[]) => void;
  testID?: string;
}

const PrintJobRow: React.FC<PrintJobRowProps> = ({
  printJob,
  reprint,
  remove,
  testID,
}) => {
  const styles = PrintJobsListRowStyles();
  const getDescripton = useCallback((order?: Order) => {
    if (order) {
      let text;
      switch (order.orderType?.code) {
        case OrderTypeCode.DINE_IN:
          text = order?.table?.name;
          break;
        case OrderTypeCode.DELIVERY:
          text = order?.customer?.firstName;
          break;
        default:
          text = order?.orderNote;
          break;
      }
      return text;
    }
  }, []);
  const getPrintType = useCallback((action?: WorkerAction) => {
    if (action) {
      let text;
      switch (action) {
        case WorkerAction.PRINT_BILL:
          text = 'BILL';
          break;
        case WorkerAction.PRINT_KITCHEN_DOCKET:
        case WorkerAction.PRINT:
        case WorkerAction.PRINT_RESEND_KITCHEN_DOCKET:
          text = 'KITCHEN';
          break;
      }
      return text;
    }
  }, []);

  const onPressReprint = useCallback(() => {
    reprint(printJob);
  }, [reprint, printJob]);

  const onPressRemove = useCallback(() => {
    remove([printJob]);
  }, [remove, printJob]);

  return (
    <TableRow
      normalRows={true}
      containerStyle={styles.rowContainerStyle}
      testID={testID}
    >
      <View style={styles.fieldViewStyle}>
        <Label
          danger
          containerStyle={styles.labelStyle}
          value="FAILED"
          textStyle={styles.labelText}
        />
      </View>
      <View style={styles.fieldViewStyle}>
        <Label
          success
          containerStyle={styles.labelStyle}
          value={getPrintType(printJob.action)}
          textStyle={styles.labelText}
          testID={`${testID}-action`}
        />
      </View>
      <Title
        containerStyle={styles.fieldViewStyle}
        labelStyle={styles.textStyle}
        testID={`${testID}-order-number`}
      >
        {(printJob.data as PrintWorkerActionData)?.order?.orderNumber || ''}
      </Title>
      <Title
        containerStyle={styles.fieldViewStyle}
        labelStyle={styles.textStyle}
        numberOfLines={1}
        testID={`${testID}-timestamp`}
      >
        {printJob.timestamp && format(+printJob.timestamp, 'dd.MM.yy hh:mm a')}
      </Title>
      <Title
        containerStyle={styles.fieldViewStyle}
        labelStyle={styles.textStyle}
        numberOfLines={1}
        testID={`${testID}-device-name`}
      >
        {(printJob.data as PrintWorkerActionData)?.printer?.device_name}
      </Title>
      <Title
        containerStyle={styles.fieldViewStyle}
        labelStyle={styles.textStyle}
        testID={`${testID}-description`}
      >
        {getDescripton((printJob.data as PrintWorkerActionData)?.order)}
      </Title>
      <View style={styles.fieldViewEndStyle}>
        <IconButton
          circular
          icon={Icons.Print}
          color={'highlighted'}
          iconStyle={styles.printIconStyle}
          containerSize={38}
          iconSize={20}
          onPress={onPressReprint}
          testID={`${testID}-print`}
        />
        <IconButton
          circular
          icon={Icons.Delete}
          color={'danger2'}
          iconStyle={styles.removeIconStyle}
          containerSize={38}
          iconSize={20}
          onPress={onPressRemove}
          testID={`${testID}-cancel`}
        />
      </View>
    </TableRow>
  );
};

export default PrintJobRow;
