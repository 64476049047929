import { StyleFn, PricingGroup } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import FormInput from '../../../../../components/FormInput/FormInput';
import TableRow from '../../../../../components/TableComponent/TableRow';
import IconButton from '../../../../../components/Button/IconButton';

const pricingRowContainerStyle: StyleFn = () => ({
  height: 57,
});

const pricingTexContainerStyle: StyleFn = ({ theme, width, centerAlign }) => ({
  height: theme.input.height,
  justifyContent: 'center',
  width: width,
  alignItems: centerAlign ? 'center' : undefined,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  flex: 1,
});

const formInputContainerStyle: StyleFn = ({ theme, width }) => ({
  width: width,
  height: theme.input.height,
  marginRight: theme.spacing.small,
  top: 5,
});
const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Regular,
  color: theme.colors.charcoal,
});

interface PricingListRowProps {
  pricingGroup: PricingGroup;
  defaultPrice: String;
  onChange: (id: string, prop: string, value: string) => void;
  onDeletePricing: (id: string, name: string) => void;
}

export const PricingListRow: React.FC<PricingListRowProps> = ({
  pricingGroup,
  defaultPrice,
  onChange,
  onDeletePricing,
}: PricingListRowProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const currencySymbol = formatCurrency(0).split('0.00')[0];
  const sellingPrice = pricingGroup.prices?.length
    ? pricingGroup.prices?.[0]?.sellingPrice?.amount?.toString()
    : '';
  return (
    <TableRow
      containerStyle={css(pricingRowContainerStyle)}
      action={
        <IconButton
          icon="TrashAlt"
          iconSize={24}
          containerSize={34}
          containerStyle={css(closeIconContainerStyle)}
          iconColor={theme.colors.red}
          onPress={onDeletePricing.bind(
            null,
            pricingGroup.id,
            pricingGroup.name,
          )}
        />
      }
    >
      <View style={css(pricingTexContainerStyle({ theme, width: 300 }))}>
        <Text style={css(textStyle)}>{pricingGroup.name}</Text>
      </View>

      <View
        style={css(
          pricingTexContainerStyle({ theme, width: 80, centerAlign: true }),
        )}
      >
        <Text style={css(textStyle)}>{defaultPrice}</Text>
      </View>

      <FormInput
        placeholder={translate('productSettings.setPrice')}
        value={currencySymbol + (sellingPrice || '0')}
        containerStyle={css(formInputContainerStyle({ theme, width: 80 }))}
        onChangeText={value => onChange(pricingGroup.id, 'sellingPrice', value)}
      />
    </TableRow>
  );
};
