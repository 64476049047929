import { StyleFn, ResourceOperation } from '@hitz-group/domain';
import React, { useState, useEffect, FC } from 'react';
import { useFela } from 'react-fela';
import { TouchableOpacity } from 'react-native';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import {
  flatten,
  getPermissionGroupIconColor,
  jobRoleSettingsUtility,
  PermissionTreeNode,
  SelectedPermission,
  PermissionGroupState,
} from '../../../../state/jobRoleSettingsUtility';
import Icon from '../../../../components/Icon/Icon';

const checkbox: StyleFn = () => ({
  marginLeft: 30,
  marginRight: 5,
});

const PermissionGroupCheckBox: FC<{
  permissionGroup: PermissionTreeNode;
  operation?: ResourceOperation | boolean;
}> = ({ permissionGroup, operation }) => {
  const { css, theme } = useFela();
  const [groupStatus, setGroupStatus] = useState(
    PermissionGroupState.UN_CHECKED,
  );
  const [isEditable, setIsEditable] = useState(true);

  useEffect(() => {
    const childPermissionIds = flatten(permissionGroup.children)
      .filter(permission => {
        if (!permission.isAtomic) {
          return (permission.operation as ResourceOperation[])?.includes(
            operation as ResourceOperation,
          );
        } else if (typeof operation === 'boolean') {
          return operation;
        }
        return false;
      })
      .map(permission => permission.id);

    const subscription: Subscription =
      jobRoleSettingsUtility.selectedPermissions$
        .pipe(
          map(selectedPermissions => {
            const selectedChildPermissions = childPermissionIds.reduce(
              (acc, permissionId) => {
                return {
                  ...acc,
                  [permissionId]: selectedPermissions[permissionId],
                };
              },
              {} as SelectedPermission,
            );

            let checkedItems: string[] = [];
            if (typeof operation === 'string') {
              checkedItems = Object.keys(selectedChildPermissions).filter(pId =>
                (
                  selectedChildPermissions[pId] as ResourceOperation[]
                )?.includes(operation),
              );
            } else if (typeof operation === 'boolean') {
              checkedItems = Object.keys(selectedChildPermissions).filter(
                pId => selectedChildPermissions[pId],
              );
            }
            return [checkedItems, childPermissionIds];
          }),
        )
        .subscribe(([checkedItems, childPermissionIds]) => {
          if (checkedItems.length === childPermissionIds.length) {
            setGroupStatus(PermissionGroupState.CHECKED);
          } else if (
            checkedItems.length > 0 &&
            checkedItems.length < childPermissionIds.length
          ) {
            setGroupStatus(PermissionGroupState.PARTIAL);
          } else {
            setGroupStatus(PermissionGroupState.UN_CHECKED);
          }
        });
    return () => {
      subscription.unsubscribe();
    };
  }, [permissionGroup.children, operation]);

  useEffect(() => {
    const subscription: Subscription = jobRoleSettingsUtility.isEditable$
      .pipe(distinctUntilChanged())
      .subscribe(setIsEditable);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const { color, icon } = getPermissionGroupIconColor(theme)[groupStatus];

  const onOperationSelected = () => {
    if (isEditable) {
      jobRoleSettingsUtility.updateGroupPermissions(
        operation as ResourceOperation,
        groupStatus,
        permissionGroup,
      );
    }
  };

  return (
    <TouchableOpacity
      disabled={!isEditable}
      key={permissionGroup.id}
      onPress={onOperationSelected}
      style={css(checkbox)}
    >
      <Icon name={icon} color={isEditable ? color : theme.colors.darkGrey} />
    </TouchableOpacity>
  );
};

export default PermissionGroupCheckBox;
