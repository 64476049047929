import { useSubscription } from '@apollo/client/react/hooks';
import {
  Order,
  OrderAction,
  StyleFn,
  UpdateOrderLastCheckEvent,
} from '@hitz-group/domain';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { useFocusEffect, useNavigation } from '@react-navigation/core';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import size from '../../../../common/theme';
import IconButton from '../../../../components/Button/IconButton';
import { OrderEvents } from '../../../../graphql/subscriptions';
import { refetchOrderObservable } from '../../../../hooks/app/orders/ordersObservableUtils';
import { useOrders } from '../../../../hooks/app/orders/useOrders';
import { useSession } from '../../../../hooks/app/useSession';
import useBehaviorSubjectEffect from '../../../../hooks/app/useSubjectEffect';
import useBehaviorSubjectState from '../../../../hooks/app/useSubjectState';
import { useCartContext } from '../../../../hooks/CartProvider';
import { useNotification } from '../../../../hooks/Notification';
import { usePrinting } from '../../../../hooks/PrintingProvider';
import { useTimeout } from '../../../../hooks/useTimeout';
import { lastActiveTimeSubject } from '../../../../state/lastActiveTime';
import {
  orderIdSelectionController,
  TableAction,
  tableActionController,
  tableSelectionController,
  tableStatsVisibilityController,
} from './floorViewObservables';
import { getCustomerName, getPricePerHour } from './TableStatPanel.utils';
interface TableStatPanel {
  isVisible: Boolean;
  onClose: (bool: boolean) => void;
}

const container: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.border,
  position: 'absolute',
  right: 0,
  width: 330,
  height: '100%',
  shadowOffset: { width: 3, height: 4 },
  shadowOpacity: 0.4,
  shadowColor: theme.colors.Black,
  shadowRadius: 5,
  elevation: 5,
  padding: 5,
});
const mainContainer: StyleFn = () => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
});
const touchableStyle: StyleFn = () => ({
  flex: 1,
});
const fullScreenStyle: StyleFn = () => ({
  position: 'absolute',
  width: size.deviceWidth,
  height: size.deviceHeight,
});
const headerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  borderRadius: theme.radius.medium,
  margin: theme.padding.small,
  paddingHorizontal: theme.spacing.small,
  backgroundColor: theme.colors.white,
  flexDirection: 'row',
  alignItems: 'center',
});

const titleStyle: StyleFn = ({ theme }) => ({
  ...theme.font14SemiBold,
  fontSize: theme.fontSize.small,
  textAlign: 'center',
  flex: 1,
  alignSelf: 'center',
});
const contentView: StyleFn = ({ theme }) => ({
  flex: 1,
  padding: theme.spacing.small,
  borderRadius: theme.radius.medium,
  backgroundColor: theme.colors.white,
  marginHorizontal: theme.padding.small,
});

const rowStyle: StyleFn = ({ theme }) => ({
  height: 65,
  paddingHorizontal: theme.spacing.small,
  justifyContent: 'center',
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.boxBorder,
});

const rowView: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing.small - 2,
});

const titleText: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
});

const smallText: StyleFn = ({ theme }) => ({
  ...theme.font14SemiBold,
});

const smallTextButton: StyleFn = ({ theme }) => ({
  ...theme.font14SemiBold,
  color: theme.colors.blue,
});

const buttonView: StyleFn = () => ({
  height: 70,
  flexDirection: 'row',
});

const iconView: StyleFn = ({ theme }) => ({
  flex: 1,
  margin: theme.padding.small / 2,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.brandPrimary,
});

const TableStatPanel: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { setCartParams, updateCart, resetCart } = useCartContext();
  const { formatCurrency } = useCurrency();
  const [{ currentStore, device }] = useSession();
  const navigation = useNavigation();
  const { printBill } = usePrinting();
  const { showNotification } = useNotification();
  const { value: isVisible, setValue: setVisible } = useBehaviorSubjectState(
    tableStatsVisibilityController,
  );
  const { value: table, setValue: setTable } = useBehaviorSubjectState(
    tableSelectionController,
  );
  const { value: currentOrderId } = useBehaviorSubjectState<string>(
    orderIdSelectionController,
  );
  const { setValue: setTableIconAction } = useBehaviorSubjectState(
    tableActionController,
  );
  const { value: lastActiveTime } = useBehaviorSubjectState(
    lastActiveTimeSubject,
  );
  const [orderOfCurrentTable, setOrderOfCurrentTable] = useState<Order>();

  const { getOrderFromCache } = useOrders();

  const refreshOrder = useCallback(() => {
    const foundOrder = getOrderFromCache(currentOrderId ?? '');
    setOrderOfCurrentTable(foundOrder);
  }, [getOrderFromCache, currentOrderId]);

  const delayRefreshTableOrderMap = useTimeout(refreshOrder);

  const { data: subscriptionData } = useSubscription(OrderEvents, {
    variables: {
      storeId: currentStore?.id,
      deviceId: device?.id,
      lastActiveTime,
    },
    shouldResubscribe: true,
  });

  const onClose = useCallback(() => {
    setVisible(false);
    setTable(null);
    setTableIconAction(TableAction.DEFAULT);
    resetCart();
  }, [setTable, setVisible, setTableIconAction, resetCart]);

  const updateTableLastCheck = useCallback(() => {
    updateCart<UpdateOrderLastCheckEvent>(OrderAction.ORDER_UPDATE_LAST_CHECK, {
      lastCheck: Date.now(),
    });
    updateCart(OrderAction.ORDER_SAVE);
    delayRefreshTableOrderMap.start();
  }, [updateCart, delayRefreshTableOrderMap]);

  useFocusEffect(refreshOrder);
  useBehaviorSubjectEffect(refetchOrderObservable, refreshOrder);
  useEffect(() => {
    if (subscriptionData) {
      delayRefreshTableOrderMap.start(2000);
    }
  }, [subscriptionData, delayRefreshTableOrderMap]);

  useEffect(() => {
    if (orderOfCurrentTable) {
      setCartParams(
        orderOfCurrentTable.id,
        orderOfCurrentTable.orderType.id,
        undefined,
        true,
      );
    }
  }, [setCartParams, orderOfCurrentTable]);

  const navigateToTakeOrder = useCallback(() => {
    if (orderOfCurrentTable) {
      navigation.navigate('TakeOrder', {
        id: orderOfCurrentTable.id,
        isCompleted: false,
        isExisting: true,
      });
    }
  }, [navigation, orderOfCurrentTable]);

  const printOrder = useCallback(async () => {
    if (orderOfCurrentTable) {
      const onPrintResult = await printBill(orderOfCurrentTable);
      if (onPrintResult && Object.keys(onPrintResult)?.length > 0) {
        if (onPrintResult.success) {
          await updateCart(OrderAction.ORDER_PRINT);
          await updateCart(OrderAction.ORDER_SAVE);
          refreshOrder();
        } else {
          showNotification(onPrintResult);
        }
      }
    }
  }, [
    orderOfCurrentTable,
    printBill,
    refreshOrder,
    showNotification,
    updateCart,
  ]);

  const payOrder = useCallback(() => {
    if (orderOfCurrentTable) {
      navigation.navigate('Payment', { orderId: orderOfCurrentTable.id });
    }
  }, [navigation, orderOfCurrentTable]);

  if (!isVisible) return null;
  if (!orderOfCurrentTable)
    return (
      <TouchableOpacity
        testID="touch-on-screen"
        style={css(fullScreenStyle)}
        onPress={onClose}
      />
    );

  const pricePerHour = getPricePerHour(orderOfCurrentTable);
  const customerName = getCustomerName(orderOfCurrentTable);

  return (
    <View style={css([mainContainer])}>
      <TouchableOpacity
        testID="touch-on-screen"
        style={css(touchableStyle)}
        onPress={onClose}
      />
      <View style={css(container)}>
        <View style={css(headerStyle)}>
          <IconButton
            testID={'table-stat-cross-icon'}
            icon="times"
            onPress={onClose}
            iconColor={theme.colors.black}
          />
          <Text testID="order-number" style={css(titleStyle)}>
            {translate('tableStatPanel.tableTitle', { name: table?.name })}
          </Text>
        </View>

        <View style={css(contentView)}>
          <ScrollView>
            <View style={css(rowStyle)}>
              <Text style={css(titleText)}>
                {translate('tableStatPanel.occupiedSince')}
              </Text>
              <View style={css(rowView)}>
                <Text style={css(smallText)} testID="table-stat-created-time">
                  {moment(orderOfCurrentTable?.createdAt).format('hh:mm A')}
                </Text>
                <Text style={css(smallText)} testID="table-stat-occupied-time">
                  {moment(orderOfCurrentTable.createdAt).fromNow()}
                </Text>
              </View>
            </View>
            <View style={css(rowStyle)}>
              <Text style={css(titleText)}>
                {translate('tableStatPanel.customer')}
              </Text>
              <View style={css(rowView)}>
                <Text style={css(smallText)} testID="table-stat-customer">
                  {customerName}
                </Text>
                <Text style={css(smallText)} testID="table-stat-guest">
                  {orderOfCurrentTable?.table?.guestCount ?? 1}{' '}
                  {translate('tableStatPanel.guest', {
                    plural:
                      orderOfCurrentTable?.table?.guestCount > 1 ? 's' : '',
                  })}
                </Text>
              </View>
            </View>
            <View style={css(rowStyle)}>
              <Text style={css(titleText)}>
                {translate('tableStatPanel.currentSpend')}
              </Text>
              <View style={css(rowView)}>
                <Text style={css(smallText)} testID="table-stat-price">
                  {formatCurrency(orderOfCurrentTable?.totalPrice)}
                  {` (${formatCurrency(pricePerHour)}/hr)`}
                </Text>
                <Text
                  style={css(smallText)}
                  testID="table-stat-price-per-guest"
                >
                  {typeof orderOfCurrentTable.table.guestCount === 'number' &&
                  orderOfCurrentTable.table.guestCount > 0
                    ? `${formatCurrency(
                        orderOfCurrentTable?.totalPrice /
                          orderOfCurrentTable.table.guestCount,
                      )}${translate('tableStatPanel.head')}`
                    : '--'}
                </Text>
              </View>
            </View>
            <View style={css(rowStyle)}>
              <Text style={css(titleText)}>
                {translate('tableStatPanel.lastOrder')}
              </Text>
              <View style={css(rowView)}>
                <Text style={css(smallText)} testID="table-stat-updated-time">
                  {orderOfCurrentTable?.lastOrderedAt
                    ? moment(orderOfCurrentTable?.lastOrderedAt).format(
                        'hh:mm A',
                      )
                    : '--'}
                </Text>
                <Text style={css(smallText)}>
                  {orderOfCurrentTable?.lastOrderedAt
                    ? moment(orderOfCurrentTable?.lastOrderedAt).fromNow()
                    : '--'}
                </Text>
              </View>
            </View>
            <View style={css(rowStyle)}>
              <Text style={css(titleText)}>
                {translate('tableStatPanel.lastCheck')}
              </Text>
              <View style={css(rowView)}>
                <Text style={css(smallText)}>
                  {orderOfCurrentTable?.lastCheck
                    ? moment(orderOfCurrentTable?.lastCheck).fromNow()
                    : '--'}
                </Text>
                <TouchableOpacity
                  testID="table-stat-update-last-check-button"
                  onPress={updateTableLastCheck}
                  hitSlop={{ top: 5, bottom: 5, left: 5, right: 5 }}
                >
                  <Text style={css(smallTextButton)}>
                    {translate('tableStatPanel.update')}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
          <View style={css(buttonView)}>
            <IconButton
              containerStyle={css(iconView)}
              testID={'statistics-cart-icon'}
              icon="shopping-cart-alt"
              iconColor={theme.colors.white}
              onPress={navigateToTakeOrder}
            />
            <IconButton
              containerStyle={css(iconView)}
              testID={'statistics-print-icon'}
              icon="print"
              iconColor={theme.colors.white}
              onPress={printOrder}
            />
            <IconButton
              containerStyle={css(iconView)}
              testID={'statistics-pay-icon'}
              icon="usd-circle"
              iconColor={theme.colors.white}
              onPress={payOrder}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default TableStatPanel;
