import React, { useEffect, useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import FormInput from '../../../components/FormInput/FormInput';
import Button from '../../../components/Button/Button';
import { CREATE_FEE } from '../../../graphql/settings';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { StyleFn, Fee, RateType, CreateFeeInput } from '@hitz-group/domain';
import DropDown from '../../../components/FormInput/DropDown';
import { sentenceCase } from 'change-case';
import Modal from '../Modal';
import scale, { isWeb, isIos, isAndroid } from '../../../common/theme';
import { feeRateValidation } from '../../../screens/BackOffice/Settings/TaxesAndFees/Fees/FeesSection';
import { isFloat } from '../../../utils/validator';
interface CreateFeeModalProps {
  onAddFee: (fee: Fee) => void;
}

const formTextInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: scale.textInputWidth180,
  fontSize: scale.moderateScale(10),
  fontFamily: theme.font.regular,
  letterSpacing: -0.5,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

const formInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: scale.textInputWidth180,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
});
export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: isWeb ? scale.textInputWidth180 : '90%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  marginLeft: isWeb
    ? scale.moderateScale(15)
    : isAndroid
    ? 0
    : scale.moderateScale(7),
});
export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb
    ? scale.textInputWidth180
    : isIos
    ? scale.moderateScale(320)
    : '100%',
  marginTop: scale.moderateScale(2),
  marginLeft: scale.moderateScale(7),
});
const formInputTextStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'flex-start',
  paddingLeft: theme.padding.medium * 1.5,
});
const formDropDownStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'flex-start',
  paddingRight: theme.padding.medium,
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginLeft: 0,
  marginTop: theme.spacing.medium,
});

const createLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const modalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

export const CreateFeeModal: React.FC<CreateFeeModalProps> = ({
  onAddFee,
}: CreateFeeModalProps) => {
  const defaultState = { rate: 0, rateType: RateType.FIXED } as CreateFeeInput;
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const [createForm, setCreateForm] = useState<CreateFeeInput>(defaultState);
  const { showNotification } = useNotification();
  const { closeModal } = useModal();
  const { formatCurrency } = useCurrency();
  const currencySymbol = formatCurrency(0).split('0.00')[0];
  const percentageSymbol = '%';

  const formatRate = useCallback(
    (value: string): string => {
      let extractedValue;
      if (createForm.rateType === RateType.FIXED) {
        extractedValue = value.includes(currencySymbol)
          ? value.split(currencySymbol)[1]
          : value;
      } else {
        extractedValue = value.includes(percentageSymbol)
          ? value.split(percentageSymbol)[0]
          : value;
      }
      return extractedValue || '0';
    },
    [currencySymbol, createForm],
  );

  const formattedRate =
    createForm.rateType === RateType.FIXED
      ? `${currencySymbol}${createForm.rate}`
      : `${createForm.rate}${percentageSymbol}`;

  const onChangeFee = useCallback(
    (prop: string, value) => {
      const formattedValue = prop === 'rate' ? formatRate(value) : value;
      setCreateForm(form => ({
        ...form,
        [prop]: formattedValue,
      }));
    },
    [formatRate],
  );

  const [createFee, createdFee] = useMutation(CREATE_FEE, {
    onError: noopHandler,
  });

  const onCreateFee = useCallback(() => {
    if (
      createForm.rate &&
      (!isFloat(String(createForm.rate)) ||
        !feeRateValidation(createForm as Fee))
    ) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'Rate' }),
      });
      return;
    }
    if (!createForm.name || createForm.rate === undefined || !createForm.code) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
      return;
    }
    createFee({
      variables: {
        input: {
          name: createForm.name,
          code: createForm.code,
          rateType: createForm.rateType || RateType.FIXED,
          rate: parseFloat(createForm.rate?.toString() || '0'),
        } as CreateFeeInput,
      },
    });
  }, [createForm, createFee, showNotification, translate]);

  useEffect(() => {
    if (createdFee.data && createdFee.data.createFee) {
      onAddFee({
        name: createForm.name,
        code: createForm.code,
        rateType: createForm.rateType || (RateType['FIXED'] as RateType),
        rate: parseFloat(createForm.rate?.toString() || '0'),
        id: createdFee.data.createFee.id,
      });

      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.FeeItemCreatedSuccessfully'),
      });
    }
  }, [
    onAddFee,
    createForm,
    createdFee.data,
    showNotification,
    closeModal,
    translate,
  ]);

  useEffect(() => {
    if (createdFee.error) {
      showNotification({
        error: true,
        message: parseApolloError(createdFee.error),
      });
    }
  }, [createdFee.error, showNotification]);

  return (
    <Modal
      title={translate('backOfficeSettings.createFee')}
      onDismiss={closeModal}
      contentStyle={css(modalContainerStyle)}
    >
      <FormInput
        title={translate('backOfficeSettings.feeName')}
        placeholder={translate('backOfficeSettings.feeName')}
        value={createForm.name}
        containerStyle={css(formInputStyle)}
        textStyle={css(formInputTextStyle)}
        onChangeText={onChangeFee.bind(null, 'name')}
        maxLength={50}
      />
      <FormInput
        title={translate('backOfficeSettings.feeCode')}
        placeholder={translate('backOfficeSettings.feeCode')}
        value={createForm.code}
        containerStyle={css(formInputStyle)}
        textStyle={css(formInputTextStyle)}
        onChangeText={onChangeFee.bind(null, 'code')}
        maxLength={6}
      />

      <DropDown
        title={translate('backOfficeSettings.rateType')}
        values={Object.keys(RateType).map(value => ({
          value: value,
          label: sentenceCase(value),
        }))}
        extraMainViewStyle={css(dropDownMainViewStyle)}
        extraViewStyle={css(dropdownViewStyle)}
        selectedValue={createForm.rateType}
        testID="rate-type"
        style={css(formDropDownStyle)}
        onValueChange={onChangeFee.bind(null, 'rateType')}
        textStyle={css(formDropDownStyle)}
      />

      <FormInput
        title={translate('backOfficeSettings.feeRate')}
        placeholder={translate('backOfficeSettings.feeRate')}
        value={createForm.rate ? formattedRate : ''}
        containerStyle={css(formInputStyle)}
        textInputStyle={css(formTextInputStyle)}
        textStyle={css(formInputTextStyle)}
        onChangeText={onChangeFee.bind(null, 'rate')}
        keyboardType={'numeric'}
      />

      <Button
        title={translate('backOfficeSettings.createFee')}
        containerStyle={css(buttonContainerStyle)}
        labelStyle={css(createLabelStyle)}
        color={theme.colors.green}
        onPress={onCreateFee}
      />
    </Modal>
  );
};
