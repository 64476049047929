import { ProductAsModifier, StyleFn } from '@hitz-group/domain';
import {
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  Modifier as ModifierDefault,
  ModifierGroup,
} from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { Text, View } from 'react-native';
import { useFela } from 'react-fela';
import IconButton from '../../../../../components/Button/IconButton';
import Button from '../../../../../components/Button/Button';
import { useCurrency } from '@hitz-group/localization';
import { getBestPriceOfModifier } from '@hitz-group/catalog-helper';

interface Modifier extends ModifierDefault {
  isDefault?: boolean;
}

interface ProductModifierRowProps {
  modifierGroup: ModifierGroup;
  modifiers: Modifier[];
  isProductAsModifierGroup?: boolean;
  products: ProductAsModifier[];
  isGrouped: boolean;
  isRequired: boolean;
  onChange: (
    modifierGroupId: string,
    prop: string,
    val: string | boolean,
    modifierId?: string,
  ) => void;
  rowIndex: number;
  onDeleteRow: (id: string) => void;
  drag?: () => void;
}

const rowStyle: StyleFn = ({ theme }) => ({
  paddingRight: theme.spacing.small,
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

const nameStyle: StyleFn = () => ({
  flex: 1,
  flexDirection: 'row',
  height: 58,
  alignItems: 'center',
});

const childNameStyle: StyleFn = () => ({
  flex: 1,
  flexDirection: 'row',
  height: 43,
  alignItems: 'center',
});

const dragIconContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.small,
  height: 28,
  width: 28,
  borderRadius: theme.radius.small,
  alignSelf: 'center',
  alignItems: 'center',
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  marginLeft: theme.spacing.small,
  alignSelf: 'center',
});

const childIconContainer: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  alignSelf: 'center',
  marginRight: 10,
});

const toggleIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
  alignSelf: 'center',
  marginLeft: theme.spacing.small,
});

const toggleCloseIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.border,
  alignSelf: 'center',
  marginLeft: theme.spacing.small,
});

const dragIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.darkGrey,
});

const childIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const toggleIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const toggleCloseIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
});

const spaceStyle: StyleFn = () => ({
  width: 200,
});

const checkBoxContainer: StyleFn = () => ({
  width: 60,
  alignItems: 'center',
  alignSelf: 'center',
  textAlign: 'center',
});

const checkBoxTitleContainer: StyleFn = () => ({
  width: 75,
  alignItems: 'center',
  alignSelf: 'center',
  textAlign: 'center',
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  height: 17,
  width: 17,
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  height: 17,
  width: 17,
});
const groupName: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
  letterSpacing: -0.5,
});

export const ProductModifierRow: React.FC<ProductModifierRowProps> = ({
  modifierGroup,
  onDeleteRow,
  onChange,
  isRequired,
  isGrouped,
  modifiers,
  drag,
  isProductAsModifierGroup,
  products,
}) => {
  const { css, theme } = useFela();
  const [showChild, setShowChild] = useState(false);
  const { appendCurrency } = useCurrency();
  const toggleRow = useCallback(() => {
    setShowChild(prev => !prev);
  }, []);
  const isDefaultModGroup =
    modifierGroup.id === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME;
  const shouldShowCheckBox =
    (!products || !products.length) && !isDefaultModGroup;

  const changeIsDefault = useCallback(
    (item: ProductAsModifier | Modifier) =>
      onChange(modifierGroup.id, 'isDefault', !item.isDefault, item.id),
    [onChange, modifierGroup],
  );
  return (
    <>
      <View style={css(rowStyle)} testID="product-modifier-row">
        <View style={css(nameStyle)}>
          <IconButton
            icon="draggabledots"
            iconSize={17}
            containerSize={28}
            containerStyle={css(dragIconContainer)}
            iconStyle={css(dragIconStyle)}
            onLongPress={drag}
          />

          <Text style={css(groupName)}>{modifierGroup.name}</Text>
        </View>

        {shouldShowCheckBox && (
          <Button
            onPress={() => onChange(modifierGroup.id, 'isGrouped', !isGrouped)}
            iconPosition={'left'}
            containerStyle={css(checkBoxContainer)}
            iconContainerStyle={
              isGrouped ? css(checkIconContainer) : css(unCheckContainer)
            }
            icon={isGrouped ? 'check' : 'null'}
            iconProps={{
              color: theme.colors.success,
              size: 15,
            }}
          />
        )}

        {shouldShowCheckBox && (
          <Button
            onPress={() =>
              onChange(modifierGroup.id, 'isRequired', !isRequired)
            }
            iconPosition={'left'}
            containerStyle={css(checkBoxTitleContainer)}
            iconContainerStyle={
              isRequired ? css(checkIconContainer) : css(unCheckContainer)
            }
            icon={isRequired ? 'check' : 'null'}
            iconProps={{
              color: theme.colors.success,
              size: 15,
            }}
          />
        )}

        {!isProductAsModifierGroup && (
          <IconButton
            icon={(showChild && 'AngleUp') || 'AngleDown'}
            iconSize={24}
            containerSize={28}
            containerStyle={
              (showChild && css(toggleIconContainerStyle)) ||
              css(toggleCloseIconContainerStyle)
            }
            iconStyle={
              (showChild && css(toggleIconStyle)) || css(toggleCloseIconStyle)
            }
            onPress={toggleRow}
          />
        )}

        <IconButton
          icon="TrashAlt"
          iconSize={24}
          containerSize={28}
          containerStyle={css(closeIconContainerStyle)}
          iconStyle={css(closeIconStyle)}
          onPress={() => onDeleteRow(modifierGroup.id)}
        />
      </View>
      {showChild &&
        modifiers?.map((mod, index) => (
          <View style={css(rowStyle)} key={index} testID="child-row">
            <View style={css(childNameStyle)}>
              <IconButton
                icon="corner-down-right"
                iconSize={19}
                containerSize={28}
                containerStyle={css(childIconContainer)}
                iconStyle={css(childIconStyle)}
              />
              <Text>{mod.name}</Text>
            </View>

            <View style={css(checkBoxTitleContainer)}>
              <Text>
                {appendCurrency(
                  getBestPriceOfModifier(mod as ModifierDefault).toString(),
                )}
              </Text>
            </View>

            <Button
              onPress={() => changeIsDefault(mod)}
              fluid
              iconPosition={'left'}
              containerStyle={css(checkBoxTitleContainer)}
              iconContainerStyle={
                mod.isDefault ? css(checkIconContainer) : css(unCheckContainer)
              }
              icon={mod.isDefault ? 'check' : 'null'}
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            />
            <View style={css(spaceStyle)} />
          </View>
        ))}
      {!isProductAsModifierGroup &&
        showChild &&
        products?.map((prod, index) => (
          <View style={css(rowStyle)} key={index} testID="child-row">
            <View style={css(childNameStyle)}>
              <IconButton
                icon="corner-down-right"
                iconSize={19}
                containerSize={28}
                containerStyle={css(childIconContainer)}
                iconStyle={css(childIconStyle)}
              />
              <Text>{prod.name}</Text>
            </View>

            <View style={css(checkBoxTitleContainer)}>
              <Text>
                {appendCurrency(prod.price?.amount.toString() || '0')}
              </Text>
            </View>

            <Button
              onPress={() => changeIsDefault(prod)}
              fluid
              iconPosition={'left'}
              containerStyle={css(checkBoxTitleContainer)}
              iconContainerStyle={
                prod.isDefault ? css(checkIconContainer) : css(unCheckContainer)
              }
              icon={prod.isDefault ? 'check' : 'null'}
              iconProps={{
                color: theme.colors.success,
                size: 15,
              }}
            />
            <View style={css(spaceStyle)} />
          </View>
        ))}
    </>
  );
};
