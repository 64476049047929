import { StyleFn } from '@hitz-group/domain';
import scale, { isWeb, isAndroid } from '../../common/theme';

export const searchContainerStyle: StyleFn = ({ theme, error }) => ({
  borderColor: error ? theme.colors.danger2 : theme.colors.boxBorder,
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  borderWidth: scale.moderateScale(0.8),
  height: theme.input.height,
  width: scale.moderateScale(370),
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'center',
  paddingLeft: 0,
});

export const searchContainerStyle1: StyleFn = () => ({
  height: scale.moderateScale(30),
  width: scale.moderateScale(370),
});
export const container: StyleFn = () => ({
  width: '100%',
});

export const searchTextInputStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  ...theme.font14RegularCharcoal,
});
export const placeTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});

export const labelStyle: StyleFn = ({ theme }) => ({
  textAlign: 'left',
  textTransform: 'capitalize',
  marginLeft: 5,
  ...theme.font14RegularDarkGrey,
});

export const labelContainerStyle: StyleFn = () => ({
  marginTop: 10,
  paddingHorizontal: scale.moderateScale(7),
  alignItems: 'flex-start',
});

export const flatListContentStyle: StyleFn = ({ theme }) => ({
  height: scale.moderateScale(120),
  width: isAndroid ? '95%' : '100%',
  backgroundColor: theme.colors.background,
  position: 'absolute',
  top: 78,
  zIndex: 999,
  paddingHorizontal: theme.spacing.small,
  borderRadius: theme.radius.large,
  alignSelf: 'center',
});

export const placeItemStyle: StyleFn = ({ theme }) => ({
  height: scale.moderateScale(25),
  width: '100%',
  marginTop: theme.spacing.small,
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.small,
  paddingLeft: scale.moderateScale(12),
  justifyContent: 'center',
});

export const iconStyle: StyleFn = () => ({
  position: 'absolute',
  right: isWeb ? scale.moderateScale(32) : scale.moderateScale(40),
  top: 12,
  paddingHorizontal: scale.moderateScale(2),
});

export const loadingStyle: StyleFn = () => ({
  position: 'absolute',
  right: scale.moderateScale(60),
  top: isWeb ? scale.moderateScale(15) : scale.moderateScale(12),
});

export const noResultsStyle: StyleFn = ({ theme }) => ({
  height: 100,
  width: isAndroid ? '95%' : '100%',
  backgroundColor: theme.colors.background,
  position: 'absolute',
  top: 78,
  zIndex: 999,
  paddingHorizontal: theme.spacing.small,
  borderRadius: theme.radius.large,
  alignSelf: 'center',
  padding: theme.padding.medium,
});
export const noResultsTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  padding: theme.padding.medium,
});
