import {
  useLazyQuery,
  useQuery,
  useMutation,
} from '@apollo/client/react/hooks';
import {
  ItemType,
  Page,
  Product,
  Variant,
  UpdateCatalogueInput,
} from '@hitz-group/domain';
import { Catalogue } from '@hitz-group/domain';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { GET_MENU_QUERY, UPDATE_MENU } from './graphql';
import { GET_MENU_OPTIONS } from '../menus/graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useNotification } from '../../Notification';
import { useTranslation } from '@hitz-group/localization';
import { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { keyBy } from 'lodash';

type ProductMap = Record<string, Product>;
type VariantMap = Record<string, Variant>;

type CatalogFilterType = {
  store?: string;
  salesChannel?: string;
  menuId?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
  showEmptyPages?: boolean;
};
export interface UseCatalogueProps {
  allProducts: ProductMap;
  allVariants: VariantMap;
  menus: Catalogue[];
  products: ProductMap;
  pages: Page[];
  status: {
    error: string | undefined;
    loading: boolean;
  };
  networkStatus: number;
  refetch: () => void;
  updateCatalogue: (updateCatalogueInput: UpdateCatalogueInput) => void;
}
/**
 * Creates pricing dictionary
 * TODO: Remove this once catalog service is built
 * TODO: Create Catalog specific interfaces and data
 * Constraints: data needs to contain all pages including the ones nested in other pages
 * @param data pages list
 */
export function filterProductsForIsSellable(products: Product[]): Product[] {
  return products.filter(product => product.isSellable);
}
export function filterProductsOrVariantsforStore(
  productsOrVariant: (Product | Variant)[],
  catalogFilter: CatalogFilterType,
): (Product | Variant)[] {
  if (!catalogFilter || !catalogFilter.store) return productsOrVariant;
  return productsOrVariant
    .filter(
      product =>
        (product as Product).optionValues?.length > 0 ||
        (product.stores && product.stores.length > 0),
    )
    .filter(
      product =>
        (product as Product).optionValues?.length > 0 ||
        product.stores.filter(store => store.id === catalogFilter.store)
          .length > 0,
    );
}

export function reduceProducts(products: Product[]) {
  const result = products.reduce<ProductMap>(
    (acc, x) => ({ ...acc, [x.id]: x }),
    {},
  );
  return result;
}

export function reduceVariants(products: Variant[]) {
  const result = products.reduce<VariantMap>(
    (acc, x) => ({ ...acc, [x.id]: x }),
    {},
  );
  return result;
}

export function sortVariantOptionValuesByOptions(
  variantProducts: Product[],
  variant: Variant,
) {
  const sortingArrKey = variant?.options?.map(option => option.key);
  if (!sortingArrKey || !(sortingArrKey.length > 0)) return variantProducts;
  const sorter = (a: { key: string }, b: { key: string }) => {
    return sortingArrKey.indexOf(a.key) - sortingArrKey.indexOf(b.key);
  };
  const sortedVariantProducts = variantProducts.map(variantProduct => {
    const sortedVariantProductOptions = JSON.parse(
      JSON.stringify(variantProduct),
    );
    sortedVariantProductOptions.optionValues.sort(sorter);
    return sortedVariantProductOptions;
  });
  return sortedVariantProducts;
}

export function mapProducts(
  data: Page[],
  catalogFilter?: CatalogFilterType | false,
): ProductMap {
  const products = filterProductsOrVariantsforStore(
    data.map((page: Page) => page.products || []).flat(),
    catalogFilter as CatalogFilterType,
  ) as Product[];
  const variants = filterProductsOrVariantsforStore(
    data.map((page: Page) => page.variants || []).flat(),
    catalogFilter as CatalogFilterType,
  ) as Variant[];
  const variantProducts = variants
    .map((variant: Variant) =>
      sortVariantOptionValuesByOptions(variant.products, variant),
    )
    .flat();

  return reduceProducts([...products, ...variantProducts]);
}

export function useCatalogue(
  catalogFilter?: CatalogFilterType,
): UseCatalogueProps {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [menu, setMenu] = useState<Catalogue>();
  const [menus, setMenus] = useState<Catalogue[]>([]);

  //TODO: priority is coming as null in page fragments while cache-and-network. can be fixed later
  const [getmenu, menuResponse] = useLazyQuery(GET_MENU_QUERY, {
    fetchPolicy: catalogFilter?.fetchPolicy
      ? catalogFilter.fetchPolicy
      : 'cache-and-network',
    errorPolicy: 'all',
    nextFetchPolicy: 'cache-first',
  });
  const {
    data,
    loading: menusLoading,
    error: menusError,
  } = useQuery(GET_MENU_OPTIONS);
  useEffect(() => {
    if (data) {
      setMenus(data.catalogues);
    }
  }, [data]);

  useEffect(() => {
    if (catalogFilter?.menuId) {
      getmenu({
        variables: {
          id: catalogFilter?.menuId,
        },
      });
    }
  }, [catalogFilter?.menuId, getmenu]);

  useEffect(() => {
    if (menuResponse.data) {
      const menuData = menuResponse.data.catalogue as Catalogue;
      setMenu(menuData);
    }
  }, [menuResponse.data]);

  const canShowPage = useCallback(
    (
      pageItem: Page,
      pagesMap: Record<string, Page>,
      pagesChecked: string[],
    ) => {
      pagesChecked.push(pageItem.id);
      if (!pageItem?.products?.length && !pageItem?.variants?.length) {
        // there are no variants or products then we check its sub pages have products or variants
        const canShowSubpages = pageItem?.pages?.map(
          eachPage =>
            !pagesChecked.includes(eachPage.id) &&
            canShowPage(pagesMap[eachPage.id], pagesMap, pagesChecked),
        );

        // if any sub page contains products or variants we should show parent page
        if (
          canShowSubpages &&
          canShowSubpages.filter(x => x && true).includes(true)
        ) {
          return true;
        }
      } else {
        // if there are products or variants we should show page
        return true;
      }
      return false;
    },
    [],
  );

  const getAllSubProductsAndSubVariants = useCallback((page: Page) => {
    const productsMap = {} as Record<string, Product>;
    const variantsMap = {} as Record<string, Variant>;
    // getting level 1 products and variants
    // can call recursively when we extend
    page?.products?.forEach(eachProduct => {
      productsMap[eachProduct?.id] = eachProduct;
    });

    page?.variants?.forEach(eachVariant => {
      variantsMap[eachVariant?.id] = eachVariant;
      eachVariant?.products?.forEach(eachVarProd => {
        productsMap[eachVarProd?.id] = eachVarProd;
      });
    });
    return { productsMap, variantsMap };
  }, []);

  const productsVariantsPages = useMemo(() => {
    let productsMap = {} as Record<string, Product>;
    const pagesMap = {} as Record<string, Page>;
    let pages = [] as Page[];
    const variants = [] as Variant[];
    if (menu?.id) {
      menu.itemGroups.forEach(eachItemGroup => {
        const pageData = {
          ...eachItemGroup.page,
          pages: [] as Page[],
          variants: [] as Variant[],
          products: [] as Product[],
        };

        // fallback priority
        const priorityMap = keyBy(
          [
            eachItemGroup.page?.products || [],
            eachItemGroup.page?.variants || [],
            eachItemGroup.page?.pages || [],
          ].flat(),
          'id',
        );

        // iterating through each root page
        eachItemGroup.items.forEach(eachItem => {
          if (eachItem.type === ItemType.Page) {
            pages.push({ ...eachItem.item, isSubPage: true } as Page);

            // add sub page to root page
            if (!pageData?.pages?.length) {
              pageData.pages = [];
            }

            pageData.pages.push({
              ...eachItem.item,
              isSubPage: true,
              priority: priorityMap[eachItem.item.id]?.priority,
            } as Page);
            pagesMap[eachItem.id] = {
              ...pagesMap[eachItem.id],
              ...eachItem.item,
              isSubPage: true,
            };
            const { productsMap: subProductsMap } =
              getAllSubProductsAndSubVariants(eachItem.item as Page);

            productsMap = {
              ...productsMap,
              ...subProductsMap,
            };
          } else if (eachItem.type === ItemType.Product) {
            const productItem = eachItem.item as Product;
            productsMap[productItem?.id] = productItem;

            // add products to page
            if (!pageData?.products?.length) {
              pageData.products = [];
            }
            if (!(eachItem.item as Product).optionValues?.length) {
              // add only non variant products
              const varProdItem = {
                ...eachItem.item,
                priority: priorityMap[eachItem.item.id]?.priority,
              } as Product;
              pageData.products?.push(varProdItem);
              productsMap[varProdItem?.id] = varProdItem;
            }
          } else if (eachItem.type === ItemType.Variant) {
            const variantData = eachItem.item as Variant;
            variants.push(variantData);

            // add variants to page
            if (!pageData?.variants?.length) {
              pageData.variants = [];
            }
            pageData.variants?.push({
              ...eachItem.item,
              priority: priorityMap[eachItem.item.id]?.priority,
            } as Variant);

            // add variant products here
            if (variantData?.products?.length) {
              variantData?.products?.forEach(eachVarProd => {
                productsMap[eachVarProd?.id] = eachVarProd;
              });
            }
          }
        });
        pagesMap[pageData.id] = pageData;
        pages.push(pageData);
      });
      // filtering pages, where we should only show pages which contains products or sub pages with products
      if (!catalogFilter?.showEmptyPages) {
        pages = pages.filter(
          eachPage => eachPage && canShowPage(eachPage, pagesMap, []),
        );
      }
    }
    return {
      products: Object.values(productsMap),
      variants,
      pages,
    };
  }, [
    menu?.id,
    menu?.itemGroups,
    catalogFilter?.showEmptyPages,
    getAllSubProductsAndSubVariants,
    canShowPage,
  ]);

  const filteredPageProductsAndVariantsByFilters = useMemo(() => {
    if (productsVariantsPages.pages) {
      if (!catalogFilter || !catalogFilter.store)
        return productsVariantsPages.pages;
      const pages = productsVariantsPages.pages.map((page: Page) => {
        const filteredPage = Object.assign({}, page);
        filteredPage['products'] = filterProductsOrVariantsforStore(
          page['products'] || [],
          catalogFilter,
        ) as Product[];
        filteredPage['products'] = filterProductsForIsSellable(
          filteredPage['products'] || [],
        ) as Product[];
        filteredPage['variants'] = filterProductsOrVariantsforStore(
          page['variants'] || [],
          catalogFilter,
        ) as Variant[];
        return filteredPage as Page;
      });
      return pages;
    } else {
      return productsVariantsPages.pages;
    }
  }, [productsVariantsPages.pages, catalogFilter]);

  const allProducts = useMemo(() => {
    const storeProducts = filterProductsOrVariantsforStore(
      productsVariantsPages.products,
      catalogFilter as CatalogFilterType,
    ) as Product[];

    const storeVariants = filterProductsOrVariantsforStore(
      productsVariantsPages.variants,
      catalogFilter as CatalogFilterType,
    ) as Variant[];

    let variantProducts: Product[] = [];
    storeVariants.forEach((variant: Variant) => {
      variantProducts = variantProducts.concat(variant.products);
    });

    const products = filterProductsForIsSellable(
      [...storeProducts, ...variantProducts] || [],
    ) as Product[];
    return reduceProducts(products);
  }, [
    productsVariantsPages.products,
    productsVariantsPages.variants,
    catalogFilter,
  ]);

  const allVariants = useMemo(() => {
    const variants = filterProductsOrVariantsforStore(
      productsVariantsPages.variants,
      catalogFilter as CatalogFilterType,
    ) as Variant[];
    return reduceVariants(variants);
  }, [productsVariantsPages.variants, catalogFilter]);

  const products = useMemo(() => {
    const filteredProducts = mapProducts(
      productsVariantsPages.pages,
      catalogFilter ? catalogFilter : false,
    );
    return filteredProducts;
  }, [productsVariantsPages, catalogFilter]);

  const refetch = useCallback(() => {
    menuResponse?.refetch && menuResponse.refetch();
  }, [menuResponse]);

  const onCatalogueUpdated = useCallback(
    data => {
      if (data?.updateCatalogue) {
        showNotification({
          message: translate('menus.menuUpdatedSuccessfully'),
          success: true,
        });
      }
    },
    [showNotification, translate],
  );

  const [updateMenuRequest, updateMenusResponse] = useMutation(UPDATE_MENU, {
    onError: noopHandler,
    onCompleted: onCatalogueUpdated,
  });

  const updateCatalogue = useCallback(
    (updateCatalogueInput: UpdateCatalogueInput) => {
      updateMenuRequest({
        variables: {
          input: updateCatalogueInput,
        },
      });
    },
    [updateMenuRequest],
  );

  const loading =
    menuResponse.loading || menusLoading || updateMenusResponse.loading;

  const error = menuResponse.error || menusError || updateMenusResponse.error;

  const networkStatus = menuResponse.networkStatus;

  return {
    allProducts,
    allVariants,
    menus,
    products,
    pages: filteredPageProductsAndVariantsByFilters,
    status: {
      error: error ? parseApolloError(error) : undefined,
      loading: loading,
    },
    networkStatus: networkStatus,
    refetch,
    updateCatalogue,
  };
}
