import React, { useCallback, useMemo } from 'react';
import { useFela } from 'react-fela';
import { View, FlatList } from 'react-native';
import Button from '../Button/Button';
import IconButton from '../Button/IconButton';
import { StyleFn, Icons, DEFAULT_TABLE_ABBREVIATION } from '@hitz-group/domain';
import { seatManagementContainerStyle } from './Cart.styles';

export type CartSelectionState = {
  item: string;
  quantity?: number;
  modifier?: string;
  variant?: string;
  product?: string;
  modifierGroup?: string;
  selectedVariantKey?: string;
  selectedModifierKey?: string;
};

export interface CartSeatLayoutProps {
  seatNumbers: number;
  selectedSeatNumber?: string;
  onSelectSeatNumber: (seatNumber: string) => void;
}

const keyButtonStyle: StyleFn = ({ theme }) => ({
  height: 35,
  width: 54,
  marginHorizontal: 1,
  marginVertical: 1,
  borderRadius: theme.radius.small,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  backgroundColor: theme.colors.greyLight,
  alignItems: 'center',
  justifyContent: 'center',
});
const selectedKeyButtonStyle: StyleFn = ({ theme }) => ({
  height: 35,
  width: 54,
  marginHorizontal: 1,
  marginVertical: 1,
  borderRadius: theme.radius.small,
  borderColor: theme.colors.boxBorder,
  borderWidth: 0,
  backgroundColor: theme.colors.blue,
  alignItems: 'center',
  justifyContent: 'center',
});
const addButtonStyle: StyleFn = ({}) => ({
  height: 35,
  width: 54,
  marginHorizontal: 1,
  marginVertical: 1,
  borderWidth: 0,
  backgroundColor: 'transparent',
  alignItems: 'center',
  justifyContent: 'center',
});
const seatButtonLabelStyle: StyleFn = ({ theme, isSelected }) => ({
  color: isSelected ? theme.colors.white : theme.colors.black,
});

const CartSeatLayout: React.FC<CartSeatLayoutProps> = ({
  seatNumbers,
  selectedSeatNumber,
  onSelectSeatNumber,
}: CartSeatLayoutProps) => {
  const { css, theme } = useFela();
  const seats = useMemo(() => {
    const seatNumberArray = Array.from({ length: seatNumbers }).map(
      (item, index) => {
        return { id: String(index + 1), title: `S${String(index + 1)}` };
      },
    );

    return [
      { id: 'table', title: DEFAULT_TABLE_ABBREVIATION },
      ...seatNumberArray,
      { id: 'add', title: '+' },
    ];
  }, [seatNumbers]);

  const onSeatPress = useCallback(
    (id: string) => {
      onSelectSeatNumber && onSelectSeatNumber(id);
    },
    [onSelectSeatNumber],
  );
  const handelAddSeatPress = useCallback(() => {
    onSelectSeatNumber && onSelectSeatNumber('+');
  }, [onSelectSeatNumber]);

  const renderItem = useCallback(
    ({ item }) => {
      const isSelected = selectedSeatNumber == item.id;
      if (item.id === 'add') {
        return (
          <IconButton
            testID={'add'}
            icon={Icons.Plus}
            iconColor={theme.colors.green}
            containerStyle={css(addButtonStyle)}
            onPress={() => handelAddSeatPress()}
          />
        );
      }

      return (
        <Button
          keypad
          title={item.title}
          testID={item.id}
          containerStyle={
            isSelected ? css(selectedKeyButtonStyle) : css(keyButtonStyle)
          }
          labelStyle={css(seatButtonLabelStyle({ theme, isSelected }))}
          onPress={() => onSeatPress(item.id)}
        />
      );
    },
    [selectedSeatNumber, css, theme, handelAddSeatPress, onSeatPress],
  );

  return (
    <View style={css(seatManagementContainerStyle)}>
      <FlatList
        data={seats}
        numColumns={5}
        keyExtractor={item => item.id}
        renderItem={renderItem}
        extraData={selectedSeatNumber}
      />
    </View>
  );
};

export default CartSeatLayout;
