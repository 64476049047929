import { Icons, StyleFn } from '@hitz-group/domain';
import * as React from 'react';
import { ReactElement, useCallback, useState, useMemo } from 'react';
import { useFela } from 'react-fela';
import {
  Animated,
  FlatList,
  GestureResponderHandlers,
  StyleProp,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import scale, { isWeb } from '../../common/theme';
import Icon from '../Icon/Icon';
import { translate } from '@hitz-group/localization';
import { MenuTile, PAGE_TYPE } from './menuTypes';

export enum MenuOptionName {
  Modifiers = 'Modifiers',
  Variations = 'Variations',
  Hide = 'Hide',
  Remove = 'Remove',
  Tile = 'Tile',
  ShowProducts = 'ShowProducts',
  AddProduct = 'AddProduct',
  AddPage = 'AddPage',
}
export interface MenuOption {
  label: string;
  isColor: boolean;
  name: MenuOptionName;
  isVisible: boolean;
}

const blockItemStyle: StyleFn = ({ theme, blockType, color }) => ({
  backgroundColor:
    color ||
    ((blockType as MenuTile) === 'Modifier' ||
    (blockType as MenuTile) === 'VariantOption'
      ? theme.colors.white
      : theme.colors.orange),

  justifyContent: 'center',
  height: isWeb ? scale.moderateScale(52) : scale.moderateScale(42),
  flex: 1,
  borderRadius: theme.radius.small,
});

const blockIconStyle: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'center',
});

const userOverlayStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  height: isWeb ? scale.moderateScale(52) : scale.moderateScale(42),
  flex: 1,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.whiteTransparent,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const touchableView: StyleFn = ({ theme }) => ({
  height: isWeb ? scale.moderateScale(52) : scale.moderateScale(42),
  width: isWeb ? scale.moderateScale(20) : scale.moderateScale(15),
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'center',
  backgroundColor: theme.colors.blackTransparent02,
  borderTopLeftRadius: theme.radius.small,
  borderBottomLeftRadius: theme.radius.small,
});
const catalogItemStyle: StyleFn = ({ theme }) => ({
  height: isWeb ? scale.moderateScale(52) : scale.moderateScale(42),
  flexDirection: 'row',
  backgroundColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
});

const hideToolTipArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.transparent,
});
const showToolTipArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});
const popoverStyle: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(170),
  borderRadius: theme.radius.large,
});
const rowContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  padding: theme.padding.small,
  justifyContent: 'space-between',
  alignItems: 'center',
});

const labelTextContainer: StyleFn = () => ({
  width: scale.moderateScale(17),
});
const renderItemContainerStyle: StyleFn = ({ theme }) => ({
  height: scale.moderateScale(25),
  backgroundColor: theme.colors.white,
  justifyContent: 'center',
  paddingHorizontal: 5,
});
const optionLabelStyle: StyleFn = ({ theme }) => ({
  textTransform: undefined,
  ...theme.font14RegularCharcoal,
});
const actionTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});
const backgroundStyle: StyleFn = ({ theme }) => ({
  height: scale.deviceHeight,
  width: '100%',
  backgroundColor: theme.colors.transparent,
  position: 'absolute',
});
const toolTipContainer: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.large,
  flexDirection: 'column',
  backgroundColor: theme.colors.white,
  paddingVertical: theme.padding.medium,
  borderWidth: 2,
  borderColor: theme.colors.white,
});

const triangleCorner: StyleFn = ({ theme }) => ({
  width: 0,
  height: 0,
  backgroundColor: theme.colors.transparent,
  borderStyle: 'solid',
  borderRightWidth: 20,
  borderTopWidth: 20,
  borderRightColor: theme.colors.transparent,
  borderTopColor: theme.colors.white,
  position: 'absolute',
  top: 3,
  left: isWeb ? scale.moderateScale(23) : scale.moderateScale(18),
});

interface BlockProps {
  style?: StyleProp<ViewStyle>;
  dragStartAnimationStyle?: StyleProp<ViewStyle>;
  productId?: string;
  onPress?: () => void;
  onLongPress: () => void;
  onSelectModifier?: (typeName: string, productId: string) => void;
  onSelectVariant?: (productId: string) => void;
  onSelectTilePopup?: (
    type?: MenuTile,
    id?: string,
    createType?: PAGE_TYPE,
  ) => void;
  arrowVisible?: boolean;
  panHandlers: GestureResponderHandlers;
  children: React.ReactNode | string;
  typeName?: string;
  modifierGroupsLength?: number;
  variantLength?: number;
  blockType?: MenuTile;
  selectedItem?: string;
  onSelectPage?: () => void;
  color?: string;
  highlightSelectedItem?: boolean;
}

const insertItemIntoArray = (
  arr: MenuOption[],
  index: number,
  newItem: MenuOption,
) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

const Block: React.FC<BlockProps> = ({
  style,
  dragStartAnimationStyle,
  onLongPress,
  onSelectModifier,
  onSelectVariant,
  onSelectTilePopup,
  children,
  arrowVisible = true,
  panHandlers,
  typeName,
  modifierGroupsLength = 0,
  variantLength: variantLength = 0,
  productId,
  blockType,
  selectedItem,
  onSelectPage,
  color,
  highlightSelectedItem,
}) => {
  const { css, theme } = useFela();
  const [showOptions, setShowOptions] = useState(false);

  const commonTileOptions: MenuOption[] = useMemo(() => {
    return [
      {
        label: translate('menuEditor.editTile'),
        isColor: false,
        name: MenuOptionName.Tile,
        isVisible: true,
      },
    ];
  }, []);

  const productTileOptions: MenuOption[] = useMemo(() => {
    return [
      {
        label: translate('menuEditor.editTile'),
        isColor: false,
        name: MenuOptionName.Tile,
        isVisible: true,
      },
      {
        label: translate('menuEditor.hideMenu'),
        isColor: true,
        name: MenuOptionName.Hide,
        isVisible: true,
      },
      {
        label: translate('menuEditor.removePage'),
        isColor: true,
        name: MenuOptionName.Remove,
        isVisible: true,
      },
    ];
  }, []);

  const productWithModifierOptions = useMemo(() => {
    return insertItemIntoArray(productTileOptions, 1, {
      label: translate('menuEditor.editModifiersLayout'),
      isColor: false,
      name: MenuOptionName.Modifiers,
      isVisible: true,
    });
  }, [productTileOptions]);

  const variantsOptions = useMemo(() => {
    return insertItemIntoArray(productTileOptions, 1, {
      label: translate('menuEditor.editVariationsLayout'),
      isColor: false,
      name: MenuOptionName.Variations,
      isVisible: true,
    });
  }, [productTileOptions]);

  const pageTileOptions = useMemo(() => {
    return insertItemIntoArray(commonTileOptions, 1, {
      label: translate('menuEditor.showProducts'),
      isColor: false,
      name: MenuOptionName.ShowProducts,
      isVisible: true,
    });
  }, [commonTileOptions]);

  const emptyProductTileoptions = useMemo(
    () => [
      {
        label: translate('menuEditor.addProduct'),
        isColor: true,
        name: MenuOptionName.AddProduct,
        isVisible: true,
      },
      {
        label: translate('menuEditor.addPage'),
        isColor: true,
        name: MenuOptionName.AddPage,
        isVisible: true,
      },
    ],
    [],
  );

  const emptyPageTileOptions = useMemo(
    () => [
      {
        label: translate('menuEditor.addPage'),
        isColor: true,
        name: MenuOptionName.AddPage,
        isVisible: true,
      },
    ],
    [],
  );

  const filteredOptions = useCallback(() => {
    if (typeName && typeName === 'Page') {
      return pageTileOptions;
    } else if (typeName && typeName === 'BLANK_PAGE_ITEM') {
      return emptyProductTileoptions;
    } else if (typeName && typeName === 'BLANK_PAGE') {
      return emptyPageTileOptions;
    } else if (modifierGroupsLength) {
      return productWithModifierOptions;
    } else if (variantLength) {
      return variantsOptions;
    } else return productTileOptions;
  }, [
    emptyPageTileOptions,
    emptyProductTileoptions,
    modifierGroupsLength,
    pageTileOptions,
    productTileOptions,
    productWithModifierOptions,
    typeName,
    variantLength,
    variantsOptions,
  ]);

  const onToggleOptions = useCallback((): void => {
    setShowOptions(!showOptions);
  }, [showOptions]);

  const onPressPopoverItem = useCallback(
    (item: MenuOption): void => {
      if (item?.name === MenuOptionName.Modifiers) {
        onSelectModifier && onSelectModifier(typeName || '', productId || '');
      } else if (item?.name === MenuOptionName.Variations) {
        onSelectVariant && onSelectVariant(productId || '');
      } else if (item?.name === MenuOptionName.Tile) {
        onSelectTilePopup && onSelectTilePopup(typeName as MenuTile, productId);
      } else if (item?.name === MenuOptionName.ShowProducts) {
        onSelectPage && onSelectPage();
      } else if (item?.name === MenuOptionName.AddProduct) {
        onSelectTilePopup &&
          onSelectTilePopup('Product', '', PAGE_TYPE.SUBPAGE);
      } else if (item?.name === MenuOptionName.AddPage) {
        onSelectTilePopup &&
          onSelectTilePopup(
            'Page',
            '',
            typeName === 'BLANK_PAGE_ITEM' ? PAGE_TYPE.SUBPAGE : PAGE_TYPE.PAGE,
          );
      }
      onRequestClosePopover();
    },
    [
      onSelectModifier,
      typeName,
      productId,
      onSelectVariant,
      onSelectTilePopup,
      onSelectPage,
    ],
  );
  const onRequestClosePopover = () => {
    setShowOptions(false);
  };

  const isSelectedBlock = (selectedItem && selectedItem === productId) || false;

  const isBlankTile =
    typeName === 'BLANK_PAGE_ITEM' || typeName === 'BLANK_PAGE';

  return (
    <Animated.View
      style={[css(catalogItemStyle), style, dragStartAnimationStyle]}
      {...panHandlers}
    >
      <Popover
        arrowStyle={
          arrowVisible ? css(showToolTipArrowStyle) : css(hideToolTipArrowStyle)
        }
        placement={PopoverPlacement.BOTTOM}
        popoverStyle={css(popoverStyle({ theme }))}
        from={
          <TouchableWithoutFeedback
            onPress={onToggleOptions}
            testID="toggle-options"
          >
            <View style={css(blockItemStyle({ theme, blockType, color }))}>
              {!isBlankTile && (
                <TouchableOpacity
                  style={css(touchableView)}
                  onLongPress={onLongPress}
                >
                  <Icon name={Icons.DraggableDots} size={24} />
                </TouchableOpacity>
              )}
              {selectedItem == productId ? (
                <View
                  testID="select-traiangle-shape"
                  style={css(triangleCorner)}
                />
              ) : null}
              {(isBlankTile && (
                <View style={css(blockIconStyle)}>
                  <Icon name={'plus'} size={24} color={theme.colors.green} />
                </View>
              )) ||
                children}
              {!isSelectedBlock && selectedItem && highlightSelectedItem ? (
                <View testID="block-overlay" style={css(userOverlayStyle)} />
              ) : null}
            </View>
          </TouchableWithoutFeedback>
        }
        backgroundStyle={css(backgroundStyle)}
        isVisible={showOptions}
        onRequestClose={onRequestClosePopover}
      >
        <View style={css(toolTipContainer)}>
          <FlatList
            data={filteredOptions()}
            renderItem={({ item }): ReactElement => {
              return (
                <TouchableOpacity
                  onPress={() => onPressPopoverItem(item as MenuOption)}
                  testID="option-item"
                >
                  {item.isVisible && (
                    <View
                      style={css(
                        renderItemContainerStyle({
                          theme,
                        }),
                      )}
                    >
                      <View style={css(rowContainerStyle)}>
                        <Text
                          style={
                            item.isColor
                              ? [css(optionLabelStyle), css(actionTextStyle)]
                              : css(optionLabelStyle)
                          }
                        >
                          {item.label}
                        </Text>
                        <View style={css(labelTextContainer)}>
                          <Icon
                            name={'angle-right'}
                            size={24}
                            color={theme.colors.paragraph}
                          />
                        </View>
                      </View>
                    </View>
                  )}
                </TouchableOpacity>
              );
            }}
            keyExtractor={item => item.label?.trim()}
          />
        </View>
      </Popover>
    </Animated.View>
  );
};

export default Block;
