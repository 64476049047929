import { StyleFn } from '@hitz-group/domain';
import { isAndroid, isWeb } from '../../common/theme';

export const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  paddingVertical: theme.padding.small,
});

export const headerCellTextAlignment: StyleFn = () => ({
  textAlign: 'left',
});

export const rowCellTextAlignment: StyleFn = () => ({
  justifyContent: 'flex-start',
});

export const dataRowStyle: StyleFn = ({ theme }) => ({
  padding: isWeb ? 0 : theme.padding.small,
  paddingHorizontal: theme.padding.large - 4,
  top: isAndroid ? -5 : 0,
});

export const tableHeaderTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.medium,
  lineHeight: 32,
});

//Table row

export const tableRowCells: StyleFn = ({ theme }) => ({
  flex: 1,
  flexWrap: 'wrap',
  flexDirection: 'row',
  minHeight: theme.input.height - 6,
});

export const tableRowCellsText: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.4,
  lineHeight: 20,
  flexWrap: 'wrap',
});

export const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  height: 18,
  width: 18,
});

export const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  height: 18,
  width: 18,
});

export const checkIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});

export const textInput: StyleFn = () => ({
  width: 50,
  height: 28,
  paddingLeft: 0,
});
export const textInputText: StyleFn = () => ({
  width: 50,
  height: 28,
  paddingLeft: 0,
  textAlign: 'center',
});

export const toggleContainerStyle: StyleFn = () => ({
  width: 50,
});
