import { Icons, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { View, ScrollView } from 'react-native';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../../../components/Button/Button';
import IconButton from '../../../../../../components/Button/IconButton';
import { useUserRoles } from '../../../../../../hooks/app/users/useUserRoles';
import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import { useVenues } from '../../../../../../hooks/app/useVenues';
import { Subscription } from 'rxjs';
import TableComponent from '../../../../../../components/TableComponent/TableComponent';
import { useNotification } from '../../../../../../hooks/Notification';
import {
  mapApiPayloadToCreateUserRole,
  StoreUserRoleMappingByRole,
  userRoleInputUtility,
  mapUserRolesByRoleIdAndStoreIds,
} from './userRoleInputUtility';
import UserPermissionRow from './UserPermissionRow';
import NewUserPermissionRow from './NewUserPermissionRow';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import useRolesContext from '../../../../../../hooks/app/users/useRolesContext';

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  width: 640,
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.medium,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  alignItems: 'center',
  paddingTop: theme.spacing.big,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme, disabled }) => ({
  width: 120,
  height: 38,
  marginLeft: 'auto',
  borderRadius: 5,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
  ...(disabled && { opacity: 0.5 }),
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const successIconContainerStyle: StyleFn = ({ theme, disabled }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginLeft: theme.spacing.small,
  ...(disabled && { opacity: 0.5 }),
});

const successIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

type ManageUserRolesParamList = {
  Venues: { userId: string };
};
export type ManageUserRolesRouteProp = RouteProp<
  ManageUserRolesParamList,
  'Venues'
>;

export const ManageUserRolesContainer: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { rolesById } = useRolesContext();
  const { params } = useRoute<ManageUserRolesRouteProp>();
  const isFocused = useIsFocused();
  const {
    venues,
    loading: venuesLoading,
    error: venuesError,
    getVenues,
  } = useVenues();

  const onCreateOrUpdateComplete = useCallback(() => {
    showNotification({
      success: true,
      message: translate('backOfficeUsers.userRolesCreatedUpdatedSuccessfully'),
    });
  }, [showNotification, translate]);

  const {
    userRoles: userRolesById,
    fetchUserRoles,
    batchCreateOrUpdateUserRoles,
    deleteUserRoles,
    loading,
    error,
    isOwner,
  } = useUserRoles({ userId: params?.userId, onCreateOrUpdateComplete });

  const { css, theme } = useFela({ disabled: isOwner });

  useEffect(() => {
    if (isFocused) {
      getVenues();
    }
  }, [getVenues, isFocused]);

  useEffect(() => {
    if (isFocused && params?.userId) {
      fetchUserRoles(params.userId);
    }
  }, [fetchUserRoles, params?.userId, isFocused]);

  const userRoles = useMemo(() => {
    return mapUserRolesByRoleIdAndStoreIds(userRolesById);
  }, [userRolesById]);

  useEffect(() => {
    userRoleInputUtility.init(userRoles);
  }, [userRoles]);

  useEffect(() => {
    if (error || venuesError) {
      showNotification({
        error: true,
        message: (error || venuesError) as string,
      });
    }
  }, [error, venuesError, showNotification]);

  const onSave = useCallback(() => {
    const userRolesInput = userRoleInputUtility.formInput;

    if (
      !Object.keys(userRolesInput).every(
        key =>
          userRolesInput[key].locations.length > 0 &&
          userRolesInput[key].roleId,
      )
    ) {
      showNotification({
        error: true,
        message: translate('backOfficeUsers.selectRoleAndStoresInput'),
      });
      return;
    }

    const userRolesToCreateOrUpdate = mapApiPayloadToCreateUserRole(
      params.userId,
      userRolesInput,
    );

    batchCreateOrUpdateUserRoles(userRolesToCreateOrUpdate);
  }, [
    params?.userId,
    batchCreateOrUpdateUserRoles,
    showNotification,
    translate,
  ]);

  const [emptyRows, setEmptyRowInputs] = useState<StoreUserRoleMappingByRole[]>(
    [],
  );

  const addEmptyRow = useCallback(() => {
    const status = userRoleInputUtility.addInput();
    if (!status) {
      showNotification({
        error: true,
        message: translate('backOfficeUsers.selectRoleAndStoresInput'),
      });
    }
  }, [showNotification, translate]);

  useEffect(() => {
    const subscription: Subscription =
      userRoleInputUtility.formInput$.subscribe(input => {
        const data = Object.values(input).filter(
          (formInput: StoreUserRoleMappingByRole) => formInput.unSaved === true,
        );
        setEmptyRowInputs(data);
      });
    return () => {
      userRoleInputUtility.reset();
      subscription.unsubscribe();
    };
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: translate('backOfficeUsers.jobRole'),
        flex: 1,
        alignItems: 'flex-start',
        containerStyle: { paddingLeft: theme.spacing.medium },
      },
      {
        title: translate('backOfficeUsers.stores'),
        flex: 2,
        alignItems: 'flex-start',
        containerStyle: { paddingLeft: theme.spacing.small },
      },
      {
        title: '',
        alignItems: 'flex-start',
        width: 30,
      },
      {
        title: '',
        alignItems: 'flex-start',
        width: 30,
      },
    ];
  }, [translate, theme]);

  const renderExistingUserRole = useCallback(
    (userRoleGroup: StoreUserRoleMappingByRole): React.ReactNode => (
      <UserPermissionRow
        rolesById={rolesById}
        key={userRoleGroup.id}
        venues={venues}
        userRoleGroup={userRoleGroup}
        deleteUserRoles={deleteUserRoles}
        isOwner={isOwner}
        userId={params?.userId}
      />
    ),
    [rolesById, venues, deleteUserRoles, isOwner, params?.userId],
  );
  const renderNewUserRole = useCallback(
    (emptyRow: StoreUserRoleMappingByRole): React.ReactNode => (
      <NewUserPermissionRow
        rolesById={rolesById}
        key={emptyRow.id}
        venues={venues}
        newUserRoleData={emptyRow}
      />
    ),
    [rolesById, venues],
  );

  if (loading || venuesLoading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.jobRoleSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView
          contentContainerStyle={css(generalSettingsContainerWrapper)}
        >
          <BackOfficeSection
            title={translate('backOfficeUsers.manageRolesAndPermissions')}
            containerStyle={css(backOfficeContainerStyle)}
            action={
              <IconButton
                icon={Icons.Plus}
                iconSize={24}
                containerSize={34}
                containerStyle={css(successIconContainerStyle)}
                iconStyle={css(successIconStyle)}
                onPress={addEmptyRow}
                disabled={isOwner}
              />
            }
          >
            <TableComponent
              columns={columns}
              columnSpacing={theme.spacing.large + theme.spacing.medium}
              data={Object.values(userRoles)}
              normalRows
              renderRow={renderExistingUserRole}
              columnContainerStyle={css(columnContainerStyle)}
            />

            <TableComponent
              columnSpacing={theme.spacing.large + theme.spacing.medium}
              data={emptyRows}
              normalRows
              renderRow={renderNewUserRole}
            />
          </BackOfficeSection>
        </ScrollView>

        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onSave}
            disabled={isOwner}
            loading={loading || venuesLoading}
          />
        </View>
      </View>
    </>
  );
};
