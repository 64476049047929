import { useTranslation } from '@hitz-group/localization';
import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import Button from '../../../../components/Button/Button';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import scale, { isWeb } from '../../../../common/theme';
import { OrderSettingsOptions } from './orderSettingsActions/OrderSettingsOptions';

const containerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  maxWidth: 822,
  marginTop: theme.spacing.small,
});

const acceptAllButtonContainer: StyleFn = ({ theme }) => ({
  flex: 1,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.success,
  paddingHorizontal: theme.spacing.small,
  justifyContent: 'center',
  marginRight: theme.spacing.small,
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const searchContainerStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
  borderWidth: 0.8,
  height: 38,
  flex: 8,
  borderRadius: theme.radius.small,
  justifyContent: 'flex-end',
  left: isWeb ? scale.moderateScale(8) : 0,
  marginRight: theme.spacing.medium,
  alignItems: 'flex-end',
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  flex: 8,
  paddingHorizontal: theme.padding.medium,
});

export interface OrdersListHeaderProps {
  onSearchTextChange: (name: string) => void;
  setPreparationTime?: (time: number) => void;
  setBusyTime?: (time: number) => void;
  onAcceptAllOrders?: () => void;
}

const OrdersListHeader: React.FC<OrdersListHeaderProps> = ({
  setPreparationTime,
  setBusyTime,
  onAcceptAllOrders,
  onSearchTextChange,
}: OrdersListHeaderProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  return (
    <View style={css(containerStyle)}>
      <OrderSettingsOptions
        setBusyTime={setBusyTime}
        setPreparationTime={setPreparationTime}
      />

      <SearchBar
        secondary
        placeholder={translate('onlineOrders.searchByName')}
        onChange={(text): void => onSearchTextChange(text)}
        containerStyle={css(searchContainerStyle)}
        textInputStyle={css(searchTextInputStyle)}
        testID="online-orders-search"
      />

      <Button
        fluid
        testID={'online-order-accept-all-orders'}
        title={translate('onlineOrders.acceptAll')}
        containerStyle={css(acceptAllButtonContainer)}
        labelStyle={css(titleStyle)}
        onPress={onAcceptAllOrders}
        numberOfLines={1}
      />
    </View>
  );
};
export default OrdersListHeader;
