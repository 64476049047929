import {
  StyleFn,
  CreateProductInput,
  Money,
  Currency,
  UpdateProductInput,
  ProductPricingInput,
  Product,
  DEFAULT_PRICING_GROUP,
} from '@hitz-group/domain';
import React, { useCallback, useState, useEffect } from 'react';
import { useFela } from 'react-fela';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import FormInput from '../../../components/FormInput/FormInput';
import Button from '../../../components/Button/Button';
import DropDown from '../../../components/FormInput/DropDown';
import scale, { isWeb } from '../../../common/theme';
import { useTaxes } from '../../../hooks/app/useTaxes';
import { useProducts } from '../../../hooks/app/products/useProducts';
import { useMemo } from 'react';
import { convertAlphaNumbericToNumber } from '@hitz-group/client-utils';
import { pricingGroupInfoDetails } from '../../../graphql/pricingGroups';
import { usePricingGroups } from '../../../hooks/app/usePricingGroups';
import { useProductPricings } from '../../../hooks/app/useProductPricings';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import { Operation } from '../../../types/Operation';
import { useNotification } from '../../../hooks/Notification';

export interface CreateProductInterface extends CreateProductInput {
  sellingPrice: Money;
  sellingTax: string;
  isVariant: boolean;
  editMore?: boolean;
  taxInclusive: boolean;
}

const createNewFormTextStyle: StyleFn = () => ({
  alignSelf: 'flex-start',
});

const inputStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
});

interface MenuProductModalProps {
  id?: string;
  onClose?: () => void;
  pages?: string[];
  onProductAdd?: (productData: Product) => void;
  onProductUpdate?: () => void;
}

const buttonStyle: StyleFn = ({ theme, marginLeft, backgroundColor }) => ({
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: backgroundColor || theme.colors.successLight,
  marginLeft: marginLeft || 0,
  marginTop: theme.spacing.medium,
});

const updateProductLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const halfSizedStyle1: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: '100%',
});

const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  height: theme.input.height,
});

const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  borderWidth: 1,
});

const dropdownStyle: StyleFn = () => ({
  marginTop: isWeb ? scale.moderateScale(10) : 0,
});

const halfSizedStyleRight1: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  marginBottom: theme.spacing.small,
  width: '100%',
});

export const getDefaultInfoforProduct = (
  product: Product,
): { price: number; taxId: string; priceId: string } => {
  const defaultPG = product?.pricingGroups?.filter(
    pricingGroup => pricingGroup.name === DEFAULT_PRICING_GROUP,
  );

  const defaultPrice = defaultPG?.[0]?.['prices'][0];

  return {
    price: defaultPrice?.sellingPrice?.amount || 0,
    priceId: defaultPrice?.id || '',
    taxId: defaultPrice?.sellingTax?.id || '',
  };
};

export const MenuProductModal: React.FC<MenuProductModalProps> = ({
  id,
  pages,
  onProductAdd,
  onClose,
  onProductUpdate,
}: MenuProductModalProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { taxesOptions, loading: taxesLoading } = useTaxes();
  const { showNotification } = useNotification();
  const { currency, formatCurrency, unAppendCurrency } = useCurrency();
  const currencySymbol = formatCurrency(0).split('0.00')[0];
  const {
    updateProducts,
    loading: prodLoading,
    products,
    operation,
    createProduct,
    createdProductId,
  } = useProducts(id);

  const { defaultPricingGroup } = usePricingGroups(
    undefined,
    pricingGroupInfoDetails,
  );

  const {
    loading: loadingPP,
    update: updateProductPricings,
    addProductPricing,
    operation: operationPP,
  } = useProductPricings();

  const loading = prodLoading || taxesLoading || loadingPP;

  const isUpdated = operation === Operation.UPDATE && !prodLoading;

  const isCreated = operation === Operation.CREATE && !prodLoading;

  const isCreatedPP = operationPP === Operation.CREATE && !loadingPP;

  const onProductCreation = useCallback(() => {
    products?.[createdProductId] &&
      onProductAdd &&
      onProductAdd(products[createdProductId]);

    onClose && onClose();
  }, [products, createdProductId, onProductAdd, onClose]);

  const onSaveProductUpdate = useCallback(() => {
    id && onProductUpdate && onProductUpdate();

    onClose && onClose();
  }, [id, onClose, onProductUpdate]);

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('productBulkOperations.successfullyUpdated'),
      });
      onSaveProductUpdate();
    }
  }, [isUpdated, onSaveProductUpdate, showNotification, translate]);

  useEffect(() => {
    if (isCreatedPP && createdProductId) {
      showNotification({
        success: true,
        message: translate('productSettings.productSuccessfullyAdded', {
          name: products[createdProductId]?.name,
        }),
      });
      onProductCreation();
    }
  }, [
    createdProductId,
    isCreatedPP,
    onProductCreation,
    products,
    showNotification,
    translate,
  ]);

  const defaultTax = useMemo(() => {
    if (!loading) {
      return taxesOptions?.[0]?.value || '';
    }
  }, [loading, taxesOptions]);

  const [productForm, setProductForm] = useState({
    sellingTax: defaultTax,
    productType: undefined,
    pages: pages || [],
  } as CreateProductInterface);

  useEffect(() => {
    if (isCreated && createdProductId && defaultPricingGroup?.id) {
      addProductPricing(createdProductId, [
        {
          pricingGroupId: defaultPricingGroup.id,
          productPricing: {
            taxInclusive: true,
            sellingPrice: {
              amount: +unAppendCurrency(productForm.sellingPrice?.amount + '0'),
              currency: currency as Currency,
            } as Money,
            sellingTax: productForm?.sellingTax,
          } as ProductPricingInput,
        },
      ]);
    }
  }, [
    isCreated,
    createdProductId,
    addProductPricing,
    defaultPricingGroup,
    unAppendCurrency,
    productForm.sellingPrice?.amount,
    productForm?.sellingTax,
    currency,
  ]);

  useEffect(() => {
    if (id && products?.[id]) {
      const { price, taxId } = getDefaultInfoforProduct(products[id]);
      setProductForm(prev => ({
        ...prev,
        name: products[id].name,
        sellingPrice: {
          amount: price,
          currency: currency,
        } as Money,
        sellingTax: taxId,
      }));
    }
  }, [currency, id, products]);

  const onChange = useCallback(
    (prop: string, value) => {
      if (prop === 'sellingPrice') {
        const tempAmount = value.includes(currencySymbol)
          ? value.split(currencySymbol)[1]
          : value;
        value = {
          currency: currency as Currency,
          amount: convertAlphaNumbericToNumber(tempAmount),
        };
      }
      setProductForm(prev => {
        return { ...prev, [prop]: value };
      });
    },
    [currency, currencySymbol],
  );

  const onSaveProduct = useCallback(() => {
    if (id && defaultPricingGroup?.id) {
      const updateInput: UpdateProductInput = {
        id: id,
        name: productForm.name,
      } as UpdateProductInput;
      const updateProductPricingsInput = {
        id: defaultPricingGroup?.id,
        sellingPrice: {
          amount: +unAppendCurrency(productForm.sellingPrice?.amount + ''),
          currency: currency as Currency,
        } as Money,
        sellingTax: productForm?.sellingTax,
        pricingGroupId: defaultPricingGroup?.id,
        product: id,
      } as unknown as ProductPricingInput;
      updateProducts([updateInput]);
      updateProductPricings([updateProductPricingsInput]);
    }
  }, [
    currency,
    defaultPricingGroup?.id,
    id,
    productForm.name,
    productForm.sellingPrice?.amount,
    productForm?.sellingTax,
    unAppendCurrency,
    updateProducts,
    updateProductPricings,
  ]);

  const onCreateProduct = useCallback(() => {
    if (defaultPricingGroup?.id) {
      const createInput: CreateProductInput = {
        name: productForm.name,
        pages: productForm.pages,
        isSellable: true,
        variablePricing: false,
      } as CreateProductInput;

      createProduct(createInput);
    }
  }, [
    defaultPricingGroup?.id,
    productForm.name,
    productForm.pages,
    createProduct,
  ]);

  const sellingPrice = productForm?.sellingPrice?.amount;

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <FormInput
        title={translate('productSettings.productName')}
        placeholder={translate('productSettings.productName')}
        value={productForm.name}
        containerStyle={css(inputStyle)}
        textStyle={css(createNewFormTextStyle)}
        onChangeText={onChange.bind(null, 'name')}
      />
      <FormInput
        title={translate('productSettings.sellingPrice')}
        placeholder={translate('productSettings.sellingPrice')}
        value={sellingPrice ? currencySymbol + sellingPrice : ''}
        containerStyle={css(halfSizedStyle1)}
        textStyle={css(createNewFormTextStyle)}
        onChangeText={onChange.bind(null, 'sellingPrice')}
      />
      <DropDown
        title={translate('productSettings.sellingTax')}
        values={taxesOptions}
        extraStyle={css(dropdownExtraStyle)}
        extraViewStyle={css(dropdownStyle)}
        extraMainViewStyle={css(dropDownMainViewStyle)}
        selectedValue={productForm.sellingTax}
        style={css(halfSizedStyleRight1)}
        onValueChange={onChange.bind(null, 'sellingTax')}
      />
      {(id && (
        <Button
          title={translate('productSettings.update')}
          containerStyle={css(buttonStyle)}
          labelStyle={css(updateProductLabelStyle)}
          onPress={onSaveProduct}
        />
      )) || (
        <Button
          title={translate('productSettings.create')}
          containerStyle={css(buttonStyle)}
          labelStyle={css(updateProductLabelStyle)}
          onPress={onCreateProduct}
        />
      )}
    </>
  );
};
