import { useCurrency, useTranslation } from '@hitz-group/localization';
import { StyleFn, PaymentType } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { Text, View } from 'react-native';

import Button from '../Button/Button';
import IconButton from '../Button/IconButton';
import { OrderPaymentDetails } from '../../screens/POS/Orders/PaymentScreen';
export interface PaymentCompleteScreenProps {
  testId?: string;
  onPressPrintReceipt: () => void;
  showPrintReceiptButton: boolean;
  orderPaymentDetails: OrderPaymentDetails;
  onCompleteSale: () => void;
  onTakeNextPayment: () => void;
  changeDueAmount: number;
  receivedAmount: number;
  onPrintSplit: () => void;
  loyaltyAction: React.ReactElement | null;
  earnedPointInfo?: string;
}

export interface OtherPaymentType extends PaymentType {
  color?: string;
}
const containerStyle: StyleFn = ({ theme }) => ({
  width: 600,
  height: 450,
  maxHeight: 550,
  overflow: 'hidden',
  borderRadius: theme.radius.small,
});

const saleView: StyleFn = ({ theme }) => ({
  width: 600,
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.large,
  backgroundColor: theme.colors.successLightest,
});

const saleText: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
  ...theme.font14SemiBold,
  fontSize: 24,
  lineHeight: 36,
});

const buttonView: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: theme.spacing.small,
});

const saleButtonStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  height: 60,
  marginTop: 20,
  marginLeft: theme.spacing.small,
  backgroundColor: theme.colors.green,
});

const printButtonStyle: StyleFn = ({ theme }) => ({
  width: 150,
  height: 60,
  marginTop: 20,
  marginRight: 10,
  backgroundColor: theme.colors.blue,
});

const printStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  textTransform: 'uppercase',
});

const salesText: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  marginTop: theme.spacing.big,
});

const PaymentCompleteScreen: React.FC<PaymentCompleteScreenProps> = ({
  orderPaymentDetails,
  onPressPrintReceipt,
  onCompleteSale,
  onTakeNextPayment,
  showPrintReceiptButton,
  changeDueAmount,
  receivedAmount,
  onPrintSplit,
  loyaltyAction,
  earnedPointInfo,
}) => {
  const {
    isSplitPayment,
    remainingDueAmount,
    isPaymentCompleted,
    totalDueAmount,
    isCustomerBalancePayment,
  } = orderPaymentDetails;
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const handleNextStepAction = useCallback(() => {
    if (isPaymentCompleted) {
      onCompleteSale();
    } else {
      onTakeNextPayment();
    }
  }, [isPaymentCompleted, onCompleteSale, onTakeNextPayment]);

  return (
    <View>
      <View style={css(containerStyle)} testID="payment-options">
        <View style={css(saleView)}>
          <IconButton
            icon={'check-circle'}
            iconColor={theme.colors.green}
            iconSize={70}
          />
          <Text style={css(saleText)}>
            {translate('payment.amountPaid', {
              amount: formatCurrency(receivedAmount || 0),
            })}
          </Text>

          <Text style={css(saleText)} testID="change-due">
            {changeDueAmount
              ? translate('payment.changeDueAmount', {
                  changeDue: formatCurrency(changeDueAmount || 0),
                })
              : translate('payment.noChangeDue')}
          </Text>
          {earnedPointInfo ? (
            <Text style={css(saleText)} testID="loyalty-earned-points">
              {earnedPointInfo}
            </Text>
          ) : null}

          {remainingDueAmount > 0 && (
            <Text style={css(salesText)}>
              {translate('payment.remainingAmountOutOfTotal', {
                willRemainingAmount: formatCurrency(remainingDueAmount),
                totalDueAmount: formatCurrency(totalDueAmount),
              })}
            </Text>
          )}
        </View>
        <View style={css(buttonView)}>
          {isSplitPayment && !isCustomerBalancePayment && (
            <Button
              key={'print-split'}
              testID={'print-split'}
              success
              size="large"
              containerStyle={css(printButtonStyle)}
              labelStyle={css(printStyle)}
              title={translate('payment.printSplit')}
              onPress={onPrintSplit}
            />
          )}
          {showPrintReceiptButton &&
            isPaymentCompleted &&
            !isCustomerBalancePayment && (
              <Button
                key={'print-order'}
                testID={'print-order'}
                success
                size="large"
                containerStyle={css(printButtonStyle)}
                labelStyle={css(printStyle)}
                title={translate('payment.printOrder')}
                onPress={onPressPrintReceipt}
              />
            )}
          <Button
            key={'complete-sale'}
            testID={'complete-sale'}
            success
            size="large"
            containerStyle={css(saleButtonStyle)}
            labelStyle={css(printStyle)}
            title={
              isPaymentCompleted
                ? translate('payment.completeSale')
                : translate('payment.takeNextPayment')
            }
            iconPosition="right"
            icon="arrow-right"
            iconProps={{
              color: theme.colors.white,
              size: 22,
              primary: true,
            }}
            onPress={handleNextStepAction}
          />
        </View>
        <View>{loyaltyAction}</View>
      </View>
    </View>
  );
};

export default PaymentCompleteScreen;
