import formatDate from 'date-fns/format';
import { useCallback, useMemo } from 'react';
import { useSession } from '../app/useSession';
import { useSettings } from '../app/useSettings';
import * as storage from '../../storage/interface';

export function getShortVersion(orderNumber: string): string {
  if (orderNumber && typeof orderNumber === 'string') {
    return orderNumber.split('-').slice(0, -1).join('-');
  }

  return '';
}

export function extractCounter(orderNumber: string): number {
  if (orderNumber && typeof orderNumber === 'string') {
    const value = orderNumber.split('-').slice(-2, -1)[0];
    return value ? +value : 0;
  }

  return 0;
}

export function useOrderNumber() {
  const [session] = useSession();
  const [counter, setCounter] = useSettings<number>('orderCounter');
  const generate = useCallback(async () => {
    const prefix = session?.device?.salesPrefix || '';
    const date = formatDate(new Date(), 'yyyyMMdd');
    let number = counter || 0 + 1;
    const latestOrderCounter =
      (await storage.getItem<number>('orderCounter')) || '';
    if (latestOrderCounter) number = latestOrderCounter + 1;
    return `${prefix}-${number}-${date}`;
  }, [counter, session]);

  const setOrderCounter = useCallback(
    (lastOrderNumber: string) => {
      if (lastOrderNumber) {
        setCounter(extractCounter(lastOrderNumber));
      } else {
        setCounter(0);
      }
    },
    [setCounter],
  );

  return useMemo(
    () => ({ generate, setOrderCounter }),
    [generate, setOrderCounter],
  );
}
