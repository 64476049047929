import {
  StyleFn,
  PRODUCT_UNITS,
  UnitType,
  LOCALE,
  ImageUploadInput,
  GeneralProductData,
} from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View, Text } from 'react-native';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../../components/FormInput/FormInput';
import Button from '../../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import DropDown from '../../../../../components/DropDown/DropDown';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import ImagePicker from '../../../../../components/ImagePicker/ImagePicker';
import scale, { isAndroid, isWeb } from '../../../../../common/theme';
import { AlternateNamesSection } from './AlternateNamesSection';
import { getAvatarName } from '../../../../../utils/getAvatarName';

const unitsValues: { [key: string]: { label: string; value: string }[] } = {
  Volume: PRODUCT_UNITS[UnitType.Volume].map(unit => ({
    label: unit,
    value: unit,
  })),
  Weight: PRODUCT_UNITS[UnitType.Weight].map(unit => ({
    label: unit,
    value: unit,
  })),
};

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  paddingBottom: theme.padding.large,
  width: 545,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: theme.spacing.medium,
});

const measureContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.spacing.big,
});

const avatarContainerStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: 100,
  backgroundColor: theme.colors.avatar2,
  borderRadius: theme.radius.small,
  alignSelf: 'center',
});

const avatarDescriptionStyle: StyleFn = ({ theme }) => ({
  width: 545,
  height: theme.input.height,
  backgroundColor: theme.colors.lightBlueSelect,
  marginTop: theme.spacing.big / 2,
  justifyContent: 'center',
  alignSelf: 'center',
  borderRadius: theme.radius.small,
});

const avatarDescriptionTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  lineHeight: 21,
  textAlign: 'center',
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
  paddingTop: theme.spacing.medium * 2,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const dropTitleStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.small,
});

const formInputContainerStyle: StyleFn = ({ theme, width }) => ({
  width: width || 260,
  height: theme.input.height,
  marginBottom: theme.spacing.small * 1.5,
});

const closelySpacedFormInputContainerStyle: StyleFn = ({ theme, width }) => ({
  width: width || 260,
  height: theme.input.height,
});

const formInputDefaultContainerStyle: StyleFn = ({ theme, width }) => ({
  width: width || 120,
  height: theme.input.height,
});

const viewStyle: StyleFn = ({ theme }) => ({
  marginTop: isWeb
    ? theme.padding.small
    : isAndroid
    ? theme.spacing.big / 2
    : theme.spacing.small,
});

const formTextInputStyle: StyleFn = ({ theme, width, centerAlign }) => ({
  width: width || 260,
  height: theme.input.height,
  paddingLeft: centerAlign ? 0 : theme.padding.medium * 1.5,
  textAlign: centerAlign ? 'center' : undefined,
});

const dropDownStyle: StyleFn = ({ theme, width }) => ({
  height: 65,
  width: width,
  zIndex: 5,
  marginTop: isWeb ? theme.spacing.small : theme.spacing.big / 2,
});
const extraPopoverStyle: StyleFn = () => ({
  width: 260,
});
const unitsPopoverStyle: StyleFn = () => ({
  width: 120,
});
const itemContainer: StyleFn = () => ({
  height: 38 * 2,
});

const checkBoxTitleStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  textTransform: 'none',
  color: theme.colors.paragraph,
});

const formLabelStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  paddingHorizontal: 10,
  paddingVertical: 0,
  lineHeight: 21,
  paddingBottom: theme.padding.medium,
  letterSpacing: -0.5,
});

const checkContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.big / 2,
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: theme.spacing.small,
  alignSelf: 'center',
});
export const width100row: StyleFn = () => ({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});
export const rowContainer: StyleFn = () => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: scale.moderateScale(10),
});
export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(50),
});

const touchableStyle: StyleFn = () => ({
  minHeight: 40,
  flexWrap: 'wrap',
});

const avtarStyle: StyleFn = () => ({
  width: '100%',
  height: '100%',
  resizeMode: 'cover',
});

const textStyle: StyleFn = () => ({
  marginTop: isAndroid ? -6 : 0,
});

const selectedItemStyle: StyleFn = () => ({
  marginVertical: 5,
});

const printerProfileContainerStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: 'auto',
  marginTop: isAndroid ? theme.padding.small + 2 : 0,
});

interface GeneralFormProps {
  isProduct: boolean;
  isVariantProduct?: boolean;
  productData: GeneralProductData;
  onChange: (
    prop: string,
    value: string | boolean | string[] | ImageUploadInput,
  ) => void;
  onChangeMeasures: (prop: string, value: string) => void;
  onChangeAlternateName: (prop: string, value: string, locale: LOCALE) => void;
  onAddTag: (name: string) => void;
  dietaryTagsOptions: { label: string; value: string }[];
  productTypeOptions: { label: string; value: string }[];
  printerProfileOptions: { label: string; value: string }[];
  onSave: () => void;
}

export const GeneralForm: React.FC<GeneralFormProps> = ({
  isProduct,
  isVariantProduct,
  productData,
  productTypeOptions,
  printerProfileOptions,
  onAddTag,
  onChange,
  onChangeMeasures,
  onChangeAlternateName,
  dietaryTagsOptions,
  onSave,
}: GeneralFormProps) => {
  const { css, theme } = useFela();
  const formLabelStyleCss = css(formLabelStyle);
  const { translate } = useTranslation();

  const defaultAlternateNames = productData.alternateNames?.find(
    altName => altName.locale === LOCALE.ENGLISH_US,
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView testID="overview-screen" style={css(pageStyle)}>
        <View style={css(avatarContainerStyle)}>
          <ImagePicker
            style={css(avtarStyle)}
            nameInitials={getAvatarName(productData?.name || '')}
            onComplete={imageRawData => onChange('imageRawData', imageRawData)}
            imageUrl={productData?.imageUrl}
          />
        </View>

        <View style={css(avatarDescriptionStyle)}>
          <Text style={css(avatarDescriptionTextStyle)}>
            {translate('avatar.imageSizeDescription')}
          </Text>
        </View>
        <View style={css(width100row)}>
          <BackOfficeSection
            contentContainerStyle={css(backOfficeContainerStyle)}
          >
            <FormInput
              error={false}
              placeholder={
                isProduct
                  ? translate('productSettings.name')
                  : translate('productSettings.variantName')
              }
              title={
                isProduct
                  ? translate('productSettings.name')
                  : translate('productSettings.variantName')
              }
              value={productData.name || ''}
              alignTitle="left"
              containerStyle={css(formInputContainerStyle)}
              onChangeText={value => {
                onChange('name', value);
                onChangeAlternateName('displayName', value, LOCALE.ENGLISH_US);
              }}
            />
            <FormInput
              error={false}
              placeholder={translate('productSettings.posName')}
              title={translate('productSettings.posName')}
              value={defaultAlternateNames?.receipt || ''}
              alignTitle="left"
              containerStyle={css(formInputContainerStyle)}
              onChangeText={value =>
                onChangeAlternateName('receipt', value, LOCALE.ENGLISH_US)
              }
            />
            <FormInput
              error={false}
              placeholder={translate('productSettings.kitchenName')}
              title={translate('productSettings.kitchenName')}
              value={defaultAlternateNames?.kitchen || ''}
              alignTitle="left"
              containerStyle={css(formInputContainerStyle)}
              onChangeText={value =>
                onChangeAlternateName('kitchen', value, LOCALE.ENGLISH_US)
              }
            />
            {isProduct && (
              <Button
                title={translate('productSettings.sellProduct')}
                labelStyle={css(checkBoxTitleStyle)}
                onPress={onChange.bind(
                  null,
                  'isSellable',
                  !productData.isSellable,
                )}
                fluid
                iconPosition={'left'}
                containerStyle={css(checkBoxTitleContainer)}
                iconContainerStyle={css(checkContainer)}
                icon={productData.isSellable ? 'toggle-on' : 'toggle-off'}
                iconProps={{
                  color: productData.isSellable
                    ? theme.colors.success
                    : theme.colors.paragraph,
                  size: 26,
                }}
              />
            )}

            <DropDown
              title={translate('productSettings.productType')}
              options={[{ label: 'Select Product Type', value: '' }].concat(
                productTypeOptions,
              )}
              selectedValue={productData.productType?.id}
              onValueChange={value => onChange('productType', value)}
              containerStyle={css(dropDownStyle({ theme, width: 260 }))}
              itemsContainerStyle={css(itemContainer)}
              extraPopoverStyle={css(extraPopoverStyle)}
              isDisabled={isVariantProduct}
              titleStyle={css(dropTitleStyle)}
            />
            <MultipleSelect
              label={translate('productSettings.printerProfile')}
              values={printerProfileOptions}
              textStyle={css(textStyle)}
              selectedValues={productData?.printerProfiles || []}
              selectedItemStyle={css(selectedItemStyle)}
              showCheckboxes={false}
              containerStyle={css(printerProfileContainerStyle)}
              onValueChange={value => onChange('printerProfiles', value)}
              touchableStyle={css(touchableStyle)}
              isDisabled={isVariantProduct}
              testID="printer-profile"
            />
            <View style={css(measureContainerStyle)}>
              <DropDown
                title={translate('productSettings.measuredBy')}
                options={Object.keys(UnitType).map(eachUnit => ({
                  label: eachUnit,
                  value: eachUnit,
                }))}
                selectedValue={
                  productData.measuredBy?.unitType || UnitType.Units
                }
                containerStyle={css(dropDownStyle({ theme, width: 260 }))}
                onValueChange={onChangeMeasures.bind(null, 'unitType')}
                itemsContainerStyle={css(itemContainer)}
                extraPopoverStyle={css(extraPopoverStyle)}
                titleStyle={css(dropTitleStyle)}
              />

              {productData.measuredBy?.unitType &&
                productData.measuredBy?.unitType !== UnitType.Units && (
                  <>
                    <View style={css(viewStyle)}>
                      <FormInput
                        type="number"
                        testID="email"
                        title={translate('productSettings.defaultSize')}
                        placeholder={translate('productSettings.defaultSize')}
                        maxLength={9}
                        defaultValue={'1'}
                        value={
                          productData.measuredBy?.defaultSize
                            ? productData.measuredBy?.defaultSize.toString()
                            : undefined
                        }
                        alignTitle="left"
                        containerStyle={css(formInputDefaultContainerStyle)}
                        textInputStyle={css(
                          formTextInputStyle({
                            theme,
                            width: 120,
                            centerAlign: true,
                          }),
                        )}
                        textStyle={formLabelStyleCss}
                        onChangeText={onChangeMeasures.bind(
                          null,
                          'defaultSize',
                        )}
                        keyboardType={'numeric'}
                      />
                    </View>
                    <DropDown
                      title={translate('productSettings.units')}
                      onValueChange={onChangeMeasures.bind(null, 'units')}
                      options={
                        unitsValues[
                          productData.measuredBy?.unitType || UnitType.Volume
                        ]
                      }
                      selectedValue={
                        productData.measuredBy?.units ||
                        unitsValues[
                          productData.measuredBy?.unitType || UnitType.Volume
                        ][0]['value']
                      }
                      containerStyle={css(dropDownStyle({ theme, width: 120 }))}
                      itemsContainerStyle={css(itemContainer)}
                      extraPopoverStyle={css(unitsPopoverStyle)}
                      titleStyle={css(dropTitleStyle)}
                    />
                  </>
                )}
            </View>
            <FormInput
              error={false}
              testID="productDescription"
              placeholder={translate('productSettings.addDescription') + '...'}
              title={translate('productSettings.description')}
              value={productData.description || ''}
              alignTitle="left"
              maxLength={300}
              containerStyle={css(
                formInputContainerStyle({ theme, width: 545 }),
              )}
              textInputStyle={css(formTextInputStyle({ theme, width: 545 }))}
              textStyle={formLabelStyleCss}
              onChangeText={value => {
                onChange('description', value);
                onChangeAlternateName('description', value, LOCALE.ENGLISH_US);
              }}
            />
          </BackOfficeSection>
          <BackOfficeSection
            title={translate('backOfficeSettings.productIdentifiers')}
            contentContainerStyle={css(backOfficeContainerStyle)}
          >
            <View style={css(rowContainer)}>
              <FormInput
                error={false}
                testID="email"
                placeholder={translate('productSettings.barcode')}
                title={translate('productSettings.barcode')}
                value={productData.barcode || ''}
                alignTitle="left"
                containerStyle={css(
                  closelySpacedFormInputContainerStyle({ theme, width: 260 }),
                )}
                onChangeText={onChange.bind(null, 'barcode')}
              />
              <FormInput
                error={false}
                testID="email"
                placeholder={translate('productSettings.sku')}
                title={translate('productSettings.sku')}
                value={productData.sku || ''}
                alignTitle="left"
                containerStyle={css(
                  closelySpacedFormInputContainerStyle({ theme, width: 260 }),
                )}
                onChangeText={onChange.bind(null, 'sku')}
              />
            </View>
            <View style={css(rowContainer)}>
              <FormInput
                error={false}
                testID="email"
                placeholder={translate('productSettings.plu')}
                title={translate('productSettings.plu')}
                value={productData.plu || ''}
                alignTitle="left"
                containerStyle={css(
                  closelySpacedFormInputContainerStyle({ theme, width: 260 }),
                )}
                onChangeText={onChange.bind(null, 'plu')}
              />
              <FormInput
                error={false}
                testID="email"
                placeholder={translate('productSettings.gtin')}
                title={translate('productSettings.gtin')}
                value={productData.gtin || ''}
                alignTitle="left"
                containerStyle={css(
                  closelySpacedFormInputContainerStyle({ theme, width: 260 }),
                )}
                onChangeText={onChange.bind(null, 'gtin')}
              />
            </View>
            <View style={css(rowContainer)}>
              <MultipleSelect
                label={translate('productSettings.dietaryTags')}
                selectedValues={(productData.dietaryTags || []).map(t => t.id)}
                containerStyle={css(dropDownStyle({ theme, width: 545 }))}
                showCheckboxes={false}
                maxItems={3}
                onAddItem={onAddTag}
                values={dietaryTagsOptions || []}
                searchPlaceHolder={
                  translate('productSettings.searchOrAddValue') + '...'
                }
                searchLabel={translate('productSettings.searchLabel')}
                onValueChange={values => onChange('dietaryTags', values)}
                isDisabled={false}
                touchableStyle={css(touchableStyle)}
              />
            </View>
          </BackOfficeSection>
          <AlternateNamesSection
            alternateNames={productData.alternateNames || []}
            onChangeAlternateName={onChangeAlternateName}
          />
        </View>
        <View style={css(bottomSpace)}></View>
      </ScrollView>
      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onSave}
          />
        </View>
      </View>
    </>
  );
};
