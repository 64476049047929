import { StyleFn } from '@hitz-group/domain';
import {
  DeviceProfile,
  UpdateDeviceProfileInput,
  OnboardingAction,
  OnboardingSection,
  OnboardingArea,
  OnboardingCheckList,
} from '@hitz-group/domain';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, Text, View } from 'react-native';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import TableRow from '../../../../components/TableComponent/TableRow';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import IconButton from '../../../../components/Button/IconButton';
import { useNotification } from '../../../../hooks/Notification';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../components/FormInput/FormInput';
import { Helmet } from 'react-helmet';
import {
  useNavigation,
  useRoute,
  useIsFocused,
  useFocusEffect,
} from '@react-navigation/native';
import Button from '../../../../components/Button/Button';
import { useDeviceProfiles } from '../../../../hooks/app/useDeviceProfiles';
import { LoadingIndicator } from '../../../../components/Loading/LoadingIndicator';
import { pick } from 'lodash';
import scale, { isWeb } from '../../../../common/theme';
import { useOnboarding } from '../../../../hooks/app/useOnboarding';
import { Operation } from '../../../../types/Operation';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

const containerStyle: StyleFn = () => ({
  width: '100%',
  alignSelf: 'center',
  maxWidth: 800,
});

const scrollStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const tableStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.medium,
});

const tableRowStyle: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  height: theme.input.height + 20,
});

const nameContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 260,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
  bottom: isWeb ? 0 : 5,
});

const devicesContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});
const deviceContainer: StyleFn = ({ theme }) => ({
  width: 80,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: theme.spacing.small + theme.spacing.small / 2,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const checkBoxTitleStyle: StyleFn = ({ theme, isActive }) => ({
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  lineHeight: 21,
  letterSpacing: -0.5,
  textTransform: 'none',
  color: isActive ? theme.colors.black : theme.colors.paragraph,
});

const defaultCheckContainerStyle: StyleFn = ({ theme, marginLeft }) => ({
  width: 70,
  height: theme.input.height,
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: marginLeft ? marginLeft : 0,
});

const navigationContainerStyle: StyleFn = ({ theme }) => ({
  width: 40,
  height: theme.input.height,
  alignSelf: 'flex-end',
  justifyContent: 'flex-end',
  marginBottom: 15,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

interface DeviceProfileRowProps {
  deviceProfile: DeviceProfile;
  venueId: string;
  storeId: string;
  onChangeName: (id: string, value: string) => void;
  onChangeDefault: (id: string) => void;
}

export const DeviceProfilesRow: React.FC<DeviceProfileRowProps> = ({
  deviceProfile,
  storeId,
  venueId,
  onChangeName,
  onChangeDefault,
}: DeviceProfileRowProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const navigation = useNavigation();

  return (
    <TableRow
      containerStyle={css(tableRowStyle)}
      normalRows={true}
      action={
        <IconButton
          primary
          icon="AngleRight"
          iconSize={24}
          containerSize={34}
          containerStyle={css(navigationContainerStyle({ theme }))}
          iconColor={theme.colors.paragraph}
          onPress={(): void => {
            deviceProfile.id &&
              navigation.navigate('DeviceProfileSettings', {
                deviceProfileId: deviceProfile.id,
                storeId,
                venueId,
              });
          }}
        />
      }
    >
      <Button
        labelStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}
        fluid
        iconPosition={'left'}
        onPress={onChangeDefault.bind(null, deviceProfile.id)}
        containerStyle={css(defaultCheckContainerStyle({ theme }))}
        icon={deviceProfile.isDefault ? 'recordAudio' : 'circle'}
        iconProps={{
          color: theme.colors.success,
          size: 20,
        }}
      />

      <FormInput
        testID="deviceProfile-name"
        placeholder={translate('backOfficeSettings.deviceProfileName')}
        value={deviceProfile.name}
        containerStyle={css(nameContainerStyle)}
        onChangeText={onChangeName.bind(null, deviceProfile.id)}
      />

      <View style={css(deviceContainer)}>
        <Text style={css(devicesContainerStyle)}>
          {deviceProfile.devices?.length || 0}
        </Text>
      </View>
      <View style={css(pageStyle)} />
    </TableRow>
  );
};

type FormState = Record<string, DeviceProfile & { isChanged: boolean }>;

export const DeviceProfiles: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [form, setForm] = useState<FormState>({});
  const isFocused = useIsFocused();
  const navigation = useNavigation();

  const route = useRoute();
  const { updateOnboardingStatus } = useOnboarding();

  const params = route.params as {
    storeId: string;
    venueId: string;
  };

  const storeId = params.storeId || '';
  const venueId = params.venueId || '';

  const {
    loading,
    deviceProfiles,
    getAllDeviceProfiles,
    updateDeviceProfiles,
    updatedDeviceProfileIds,
    error: deviceProfileError,
  } = useDeviceProfiles({ storeId, venueId });

  useFocusEffect(
    useCallback(() => {
      getAllDeviceProfiles();
    }, [getAllDeviceProfiles]),
  );

  useEffect(() => {
    isFocused && getAllDeviceProfiles();
  }, [getAllDeviceProfiles, isFocused]);

  useEffect(() => {
    updateOnboardingStatus(
      OnboardingArea.SETTINGS,
      OnboardingSection.VIEW_STORE_AND_REGISTER_SETUP,
      OnboardingCheckList.STORES_REGISTER_PROFILES,
      OnboardingAction.READ,
    );
  }, [updateOnboardingStatus]);

  useEffect(() => {
    if (deviceProfiles) {
      setForm(deviceProfiles as FormState);
    }
  }, [deviceProfiles]);

  useEffect((): void => {
    if (deviceProfileError) {
      showNotification({
        error: true,
        message: deviceProfileError,
      });
    }
  }, [deviceProfileError, showNotification]);

  useEffect((): void => {
    if (updatedDeviceProfileIds && updatedDeviceProfileIds.length > 0) {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
        ),
      });
    }
  }, [updatedDeviceProfileIds, showNotification, translate]);

  const onPressCreateNew = useCallback(() => {
    navigation.navigate('DeviceProfileSettings', {
      storeId,
      operation: Operation.CREATE,
      venueId,
    });
  }, [navigation, storeId, venueId]);

  const onPressSave = useCallback((): void => {
    // update deviceProfiles
    const data = Object.values(form)
      .filter(deviceProfile => deviceProfile.isChanged)
      .map(deviceProfile =>
        pick(deviceProfile, ['id', 'name', 'isDefault']),
      ) as unknown as UpdateDeviceProfileInput[];

    if (data.length > 0) {
      if (data.some(deviceProfile => !deviceProfile.name)) {
        showNotification({
          error: true,
          message: translate('backOfficeDeviceProfiles.fieldsMissing'),
        });
        return;
      } else {
        updateDeviceProfiles(data);
      }
    }
  }, [showNotification, translate, updateDeviceProfiles, form]);

  const onChangeName = useCallback((id: string, value: string): void => {
    setForm(form => ({
      ...form,
      [id]: {
        ...form[id],
        name: value,
        isChanged: true,
      },
    }));
  }, []);

  const onChangeDefault = useCallback(
    (id: string): void => {
      const existingDefaultRegister = Object.values(form).find(
        r => r.isDefault && r.id !== id,
      );
      if (existingDefaultRegister) {
        setForm(form => ({
          ...form,
          [existingDefaultRegister.id]: {
            ...form[existingDefaultRegister.id],
            isDefault: false,
            isChanged: true,
          },
        }));
      }

      setForm(form => ({
        ...form,
        [id]: {
          ...form[id],
          isDefault: true,
          isChanged: true,
        },
      }));
    },
    [form],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ScrollView contentContainerStyle={css(scrollStyle)}>
            <BackOfficeSection
              action={<BackOfficeCreateNewButton onPress={onPressCreateNew} />}
              containerStyle={css(containerStyle)}
              title={translate('backOfficeSettings.registerProfiles')}
              titleDescription={translate(
                'backOfficeSettings.registerProfilesDescription',
              )}
            >
              <TableComponent
                columns={[
                  {
                    title: translate('backOfficeSettings.default'),
                    width: 70,
                    alignItems: 'flex-start',
                  },
                  {
                    title: translate('backOfficeSettings.registerProfileName'),
                    width: 260,
                    alignItems: 'flex-start',
                  },
                  {
                    title: translate('backOfficeSettings.activeDevices'),
                    flex: 1,
                    alignItems: 'flex-start',
                  },
                ]}
                data={Object.values(form)}
                columnContainerStyle={css(tableStyle)}
                normalRows={true}
                renderRow={(
                  item: DeviceProfile,
                  index: number,
                ): React.ReactNode => (
                  <DeviceProfilesRow
                    deviceProfile={item}
                    storeId={storeId}
                    venueId={venueId}
                    key={index}
                    onChangeName={onChangeName}
                    onChangeDefault={onChangeDefault}
                  />
                )}
              />
            </BackOfficeSection>
          </ScrollView>
        )}
      </View>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
