import { StyleFn } from '@hitz-group/domain';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import React from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import Button from '../../../../components/Button/Button';

export interface TabBarProps {
  tabBar: MaterialTopTabBarProps;
}

const container: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  alignSelf: 'center',
  backgroundColor: theme.colors.white,
  marginTop: 10,
  ...theme.border.borderSolid,
  borderRadius: theme.radius.medium,
  padding: 8,
  position: 'absolute',
  zIndex: 1000,
  ...theme.shadow15,
});
const selectedButtonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.info,
});
const buttonStyle: StyleFn = () => ({});
const selectLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  ...theme.font14Medium,
});
const deSelectLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.grey2,
  ...theme.font14Medium,
});

const FloorViewTabBar: React.FC<TabBarProps> = ({ tabBar }) => {
  const { css } = useFela();
  return (
    <View style={css(container)}>
      {tabBar.state.routes.map(
        (route: { name: string; key: string }, index: number) => {
          const isFocused = tabBar.state.index === index;
          const { options } = tabBar.descriptors[route.key] as {
            options: { tabBarLabel?: string };
          };
          const label: string = (options.tabBarLabel as string) || route.name;
          return (
            <Button
              size="small"
              title={label}
              key={index}
              numberOfLines={1}
              containerStyle={
                isFocused ? css(selectedButtonStyle) : css(buttonStyle)
              }
              labelStyle={
                isFocused ? css(selectLabelStyle) : css(deSelectLabelStyle)
              }
              onPress={(): void => {
                !isFocused && tabBar.navigation.navigate(route.name);
              }}
            />
          );
        },
      )}
    </View>
  );
};

export default FloorViewTabBar;
