import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import { StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import Title from '../Title/Title';
import Icon from '../Icon/Icon';
export interface CompleteRefundButtonProps {
  onPress: () => void;
  refundTotal: number;
  totalItems: number;
  isDisabled: boolean;
}

const completeSaleButtonStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.colors.success,
  paddingLeft: theme.padding.medium,
  paddingRight: theme.padding.large,
});

const refundTotalContainerStyle: StyleFn = ({ theme }) => ({
  height: 30,
  width: 60,
  backgroundColor: theme.colors.successDark,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  alignItems: 'center',
});
const totalRefundItemView: StyleFn = () => ({
  flexDirection: 'row',
});
const refundTotalItemsContainer: StyleFn = () => ({
  marginTop: 3,
});

const CompleteRefundButton: React.FC<CompleteRefundButtonProps> = ({
  onPress,
  refundTotal,
  totalItems,
  isDisabled,
}: CompleteRefundButtonProps) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { css } = useFela({ isDisabled });
  const refundTotalDue = `${formatCurrency(Math.abs(refundTotal))}`;
  return (
    <TouchableOpacity
      style={css(completeSaleButtonStyle)}
      disabled={!isDisabled}
      onPress={onPress}
    >
      <Title primary containerStyle={css(refundTotalContainerStyle)}>
        {refundTotalDue}
      </Title>

      <View style={css(totalRefundItemView)}>
        <Title
          success={isDisabled}
          transparent={!isDisabled}
          containerStyle={css(refundTotalItemsContainer)}
        >
          {translate('payment.refundItems', {
            noOfItems: totalItems,
          })}
        </Title>
        <Icon name="arrow-right" />
      </View>
    </TouchableOpacity>
  );
};

export default CompleteRefundButton;
