import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Widget, DateRangeGranularity, ColumnType } from '@hitz-group/domain';
import { View, Text } from 'react-native';
import { useReporting } from '../../../../hooks/app/useReporting';
import { CubejsApi, ResultSet } from '@cubejs-client/core';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../../hooks/Notification';
import {
  VictoryChart,
  VictoryAxis,
  VictoryVoronoiContainer,
  VictoryLine,
  VictoryGroup,
  VictoryTooltip,
  VictoryLegend,
} from 'victory-native';
import { Styles } from '../styles/Component.styles';
import { IMap, LineChartType, DisplayLabels } from '../types';
import {
  convertDateByFormat,
  GRANULARITY_FORMATS,
  CHART_Y_AXIS_STYLE,
  CHART_CONTAINER_STYLE,
  CHART_COLOR_SCALE,
  COMMON_CHART_STYLES,
  CHART_X_AXIS_STYLE,
  CHART_TOOLTIP_STYLE,
  TOOLTIP_GAP,
  CHART_LEGEND_STYLE,
  LEGEND_BOTTOM_GAP,
  arePropsEqual,
  getLabelAccordingToType,
} from '../reportsHelper';
import { useCurrency } from '@hitz-group/localization';

export interface LineChartRow {
  x: string | number | boolean;
  y: number;
  text?: string;
  labelText?: string;
}

export type LineChartData = LineChartRow[];

interface LineChartProps {
  widget: Widget;
  helper: string;
  keys: Array<IMap<string>>;
  type: LineChartType;
  // This can be used to customize the result data
  dataTransformationFn?: (
    pivotTableData: PivotTableData,
    widgetName: string,
  ) => LineChartData[];
  cubejsApi: CubejsApi;
  updateCount: number;
  columnType?: ColumnType;
}

export type PivotTableData = Array<{
  [key: string]: string | number | boolean;
}>;

const AXIS_FORMATS = { ...GRANULARITY_FORMATS, hour: 'HH', day: 'd   ' };

const LineChartComponent: React.FC<LineChartProps> = ({
  widget,
  helper,
  keys,
  type,
  dataTransformationFn,
  cubejsApi,
  columnType,
}) => {
  const { appendCurrency } = useCurrency();
  const { showNotification } = useNotification();

  const { loading, error, widgetData, getWidgetData } = useReporting(cubejsApi);

  const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });
  const graphRef = useCallback(node => {
    if (node !== null) {
      node.getBoundingClientRect &&
        setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  const styles = Styles();

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    widget.query && getWidgetData(widget, helper);
  }, [widget, getWidgetData, helper]);

  const data = useMemo(() => {
    if (!widgetData || !widget?.name || !widgetData[widget.name])
      return {} as ResultSet;
    return widgetData[widget.name] as ResultSet;
  }, [widgetData, widget]);

  const chartMargin =
    CHART_CONTAINER_STYLE.marginLeft + CHART_CONTAINER_STYLE.marginRight;
  const chartStyle = {
    ...CHART_CONTAINER_STYLE,
    width: `calc(${widget.width} - ${chartMargin}px)`,
  };

  const serializedData = (data.tablePivot && data.tablePivot()) || [];

  let chartData: LineChartData[] = keys.map(() => []);

  if (serializedData.length > 0) {
    if (dataTransformationFn) {
      chartData = dataTransformationFn(serializedData, widget.name);
    } else {
      serializedData.forEach(row => {
        keys.forEach((key, index) => {
          chartData[index].push({
            x:
              typeof row[key.name] === 'string' && row[key.name] !== ''
                ? row[key.name]
                : DisplayLabels.BLANK_LABEL,
            y:
              typeof row[key.value] === 'string' && row[key.value] !== ''
                ? parseFloat(row[key.value] as string)
                : 0,
          });
        });
      });
    }
  } else {
    chartData = [
      keys.map(() => {
        return { x: DisplayLabels.NO_RECORD, y: 0 };
      }),
    ];
  }

  const widgetDateRangeFilters = widget.query.dateRangeFilters || undefined;
  const granularity =
    (widgetDateRangeFilters && widgetDateRangeFilters[0].granularity) ||
    DateRangeGranularity.DAY;

  const convertLabelText = (label: string, forAxis?: boolean) => {
    let text: string;
    const granularityFormats = forAxis ? AXIS_FORMATS : GRANULARITY_FORMATS;
    if (type === LineChartType.DATE_RANGE) {
      if (label === 'Others') {
        return label;
      }
      try {
        text = convertDateByFormat(
          label,
          granularity,
          granularityFormats[granularity],
        );
      } catch (err) {
        text = label;
      }
    } else {
      text = label;
    }
    return text;
  };

  const legends = chartData.map(dataArr => ({
    name: dataArr?.[0]?.text,
  }));

  const legendWidth = legends.length * 38;
  const legendX = boundingRect.width / 2 - legendWidth;
  const legendY = boundingRect.height - LEGEND_BOTTOM_GAP;

  return (
    <View style={chartStyle} ref={graphRef}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <VictoryChart
          width={boundingRect.width}
          height={boundingRect.height ? boundingRect.height : 0}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => {
                if (datum.labelText) {
                  return datum.labelText;
                } else if (datum.text) {
                  return `${datum.text}\n${convertLabelText(datum.x)}: ${
                    datum.y
                  }`;
                } else {
                  return getLabelAccordingToType(
                    datum,
                    appendCurrency,
                    columnType,
                  );
                }
              }}
              labelComponent={
                <VictoryTooltip
                  dy={TOOLTIP_GAP}
                  style={CHART_TOOLTIP_STYLE.style}
                  flyoutStyle={CHART_TOOLTIP_STYLE.flyoutStyle}
                />
              }
            />
          }
        >
          <VictoryAxis
            style={CHART_X_AXIS_STYLE}
            tickFormat={t => {
              let text = convertLabelText(t, true);
              if (text && typeof text === 'string') {
                text = text.split('-')[0];
              }
              return text;
            }}
            fixLabelOverlap={true}
          />
          <VictoryAxis
            dependentAxis
            orientation="left"
            style={CHART_Y_AXIS_STYLE}
            tickFormat={t => {
              if (t < 1e-4) return 0;
              return t >= 1000 ? `${t / 1000}k` : t;
            }}
          />
          <VictoryGroup colorScale={CHART_COLOR_SCALE}>
            {chartData.map((data, index) => (
              <VictoryLine
                key={`line-chart-${index}`}
                data={data}
                style={{
                  labels: COMMON_CHART_STYLES.labels,
                }}
              />
            ))}
          </VictoryGroup>
          {legends.length > 1 && (
            <VictoryLegend
              style={CHART_LEGEND_STYLE.style}
              x={legendX}
              y={legendY}
              orientation="horizontal"
              symbolSpacer={CHART_LEGEND_STYLE.symbolSpace}
              gutter={CHART_LEGEND_STYLE.legendSpace}
              colorScale={CHART_COLOR_SCALE}
              data={legends}
            />
          )}
        </VictoryChart>
      )}
      <Text style={styles.chartTitleStyle}>{widget.name}</Text>
    </View>
  );
};

export const LineChart = React.memo(LineChartComponent, arePropsEqual);
