import React, { useState, useCallback, useMemo, ReactElement } from 'react';
import {
  View,
  Text,
  ViewStyle,
  FlatList,
  TouchableWithoutFeedback,
} from 'react-native';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import IconButton from '../Button/IconButton';
import SearchBar from '../SearchBar/SearchBar';
import Label from '../Label/Label';
export type ValuesType = {
  value: string;
  label: string;
  isSelected?: boolean;
  itemTitle?: string;
  type?: string;
};

export interface MultipleSearchAddProps {
  testID?: string;
  label?: string;
  values: ValuesType[];
  selectedValues?: string[];
  onValueChange?: (selectedValues: string[], item: ValuesType) => void;
  containerStyle?: ViewStyle;
  searchLabel?: string;
  contentStyle?: ViewStyle;
  scrollEnabled?: boolean;
  maxItems?: number;
  isDisabled?: boolean;
}

const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 38,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  alignSelf: 'center',
  //marginLeft: -theme.spacing.big,
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 38,
  color: theme.colors.black,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  paddingLeft: theme.padding.medium,
});

const flatListContentStyle: StyleFn = ({ theme, height, label }) => ({
  height: height || 150,
  width: '100%',
  flex: 1,
  backgroundColor: theme.colors.white,
  borderWidth: 2,
  borderColor: theme.colors.white,
  borderRadius: theme.radius.large,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  flexDirection: 'column',
  zIndex: 2000,
  position: 'absolute',
  top: label ? 75 : 40,
  //marginLeft: -theme.spacing.small,
  elevation: 5,
});

const itemStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  borderBottomWidth: 0.5,
  borderBottomColor: theme.colors.paragraph,
  backgroundColor: theme.colors.white,
});

const itemTitlesColumnStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'column',
  width: '80%',
  marginBottom: theme.spacing.small * 1.5,
});

const itemTitleStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.large,
  color: theme.colors.paragraph,
  fontFamily: theme.font.medium,
  marginLeft: 15,
  fontSize: theme.fontSize.smallest + 1,
  textTransform: 'uppercase',
});

const itemNameStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.large,
  fontFamily: theme.font.regular,
  marginLeft: 15,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
});

const addIconStyle: StyleFn = () => ({
  marginLeft: 'auto',
  marginRight: 30,
});

const labelContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  alignItems: 'flex-start',
  paddingLeft: 0,
});

const labelTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small + 1,
  fontFamily: theme.font.medium,
  paddingBottom: theme.padding.medium,
  textAlign: 'left',
  textTransform: 'none',
});

const MultipleSearchAdd: React.FC<MultipleSearchAddProps> = ({
  label,
  values,
  containerStyle,
  onValueChange,
  searchLabel,
  contentStyle,
  selectedValues,
  scrollEnabled,
  maxItems,
  isDisabled,
}: MultipleSearchAddProps) => {
  const { css, theme } = useFela();
  const [showOptions, setShowOptions] = useState(false);
  const [searchString, setSearchString] = useState('');
  const onSearchTextChange = useCallback((value): void => {
    setSearchString(value);
  }, []);

  const searchResults = useMemo(() => {
    if (searchString !== '' && values && values.length) {
      const searchLabel = values.filter((eachValue: ValuesType) =>
        eachValue.label.toLowerCase().includes(searchString.toLowerCase()),
      );
      setShowOptions(true);
      return searchLabel;
    } else {
      setShowOptions(false);
      return values;
    }
  }, [searchString, values]);

  const onToggleOptions = useCallback((): void => {
    !isDisabled && setShowOptions(!showOptions);
    setSearchString('');
  }, [showOptions, isDisabled]);

  const closeDropDown = useCallback((): void => {
    setShowOptions(false);
    setSearchString('');
  }, []);

  const onBlur = useCallback(
    (e): void => {
      const targetEvent = (
        e.nativeEvent as unknown as {
          relatedTarget: unknown;
        }
      )?.relatedTarget;
      !targetEvent && closeDropDown();
    },
    [closeDropDown],
  );

  const valuesFiltered = useMemo(() => {
    return searchResults;
  }, [searchResults]);

  const onChange = useCallback(
    (selected, item: ValuesType) => {
      const id = item.value;
      if (selectedValues) {
        const index = selectedValues.indexOf(id);
        if (selected && index <= -1) {
          selectedValues.push(id);
        } else {
          selectedValues.splice(index, 1);
        }
      }
      onValueChange && onValueChange(selectedValues || [id], item);
      setShowOptions(false);
      setSearchString('');
    },
    [selectedValues, onValueChange],
  );

  const maxItemsToShow = useMemo(() => {
    let maxItemsPerScroll = maxItems || valuesFiltered.length;
    if (maxItems && valuesFiltered.length < maxItems) {
      maxItemsPerScroll = valuesFiltered.length;
    }
    return maxItemsPerScroll;
  }, [valuesFiltered, maxItems]);

  return (
    <View style={containerStyle}>
      {label && (
        <Label
          containerStyle={css(labelContainerStyle)}
          textStyle={css(labelTextStyle)}
          value={label}
        />
      )}

      <SearchBar
        placeholder={searchLabel || ''}
        containerStyle={css(searchContainerStyle)}
        textInputStyle={css(searchTextInputStyle)}
        iconColor={theme.colors.paragraph}
        placeholderColor={theme.colors.paragraph}
        onChange={onSearchTextChange}
        showFindIcon
        onPressFind={onToggleOptions}
        value={searchString}
      />

      {showOptions && (
        <TouchableWithoutFeedback onBlur={onBlur}>
          <FlatList
            scrollEnabled={scrollEnabled}
            data={valuesFiltered}
            style={[
              css(
                flatListContentStyle({
                  theme,
                  height: 72 * maxItemsToShow,
                  label,
                }),
              ),
              contentStyle,
            ]}
            renderItem={({ item, index }): ReactElement => {
              const isSelected =
                (selectedValues && selectedValues.indexOf(item.value) !== -1) ||
                false;
              return (
                <View key={index + `${item.label}`} style={css(itemStyle)}>
                  <View style={css(itemTitlesColumnStyle)}>
                    <Text style={css(itemTitleStyle)}>{item.itemTitle}</Text>
                    <Text style={css(itemNameStyle)}>{item.label}</Text>
                  </View>
                  <IconButton
                    icon={isSelected ? 'times' : 'plus'}
                    iconColor={
                      isSelected ? theme.colors.red : theme.colors.green
                    }
                    iconSize={20}
                    containerStyle={css(addIconStyle)}
                    onPress={() => onChange(!item.isSelected, item)}
                  />
                </View>
              );
            }}
          />
        </TouchableWithoutFeedback>
      )}
    </View>
  );
};

MultipleSearchAdd.defaultProps = {
  scrollEnabled: true,
  maxItems: 4,
  isDisabled: false,
};

export default MultipleSearchAdd;
