import {
  StyleFn,
  CreateProductInput,
  Money,
  Currency,
  Colors,
  DEFAULT_TAX,
} from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { useNotification } from '../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import Modal from '../../../../components/Modals/Modal';
import DropDown from '../../../../components/FormInput/DropDown';
import FormInput from '../../../../components/FormInput/FormInput';
import Button from '../../../../components/Button/Button';
import scale, { isWeb } from '../../../../common/theme';
import { isValidPrice } from '../../../../utils/validator';
import MultipleSelect from '../../../../components/MultipleSelect/MultipleSelect';
import IconButton from '../../../../components/Button/IconButton';
import { usePages } from '../../../../hooks/app/pages/usePages';
import { useProductTypes } from '../../../../hooks/app/useProductTypes';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';

const createModalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const buttonStyle: StyleFn = ({ theme, marginLeft, backgroundColor }) => ({
  width: 115,
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: backgroundColor || theme.colors.successLight,
  marginLeft: marginLeft || 0,
  marginTop: theme.spacing.medium,
});

const createNewFormTextStyle: StyleFn = () => ({
  alignSelf: 'flex-start',
});

const createVenueLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const inputStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
});

export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: scale.textInputWidth180,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});
export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? 280 : '100%',
  marginTop: scale.moderateScale(2),
});
export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '90%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});

export const dropdownStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? 250 : '100%',
  marginTop: isWeb ? scale.moderateScale(10) : 0,
  left: isWeb ? 70 : 0,
});

const halfSizedStyle1: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: '100%',
});

const halfSizedStyleRight1: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  marginBottom: theme.spacing.small,
  width: '50%',
});

const rowViewStyle: StyleFn = () => ({
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});
const width50Per: StyleFn = () => ({
  width: '50%',
  alignItems: 'center',
  justifyContent: 'center',
});

const titleStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  textTransform: 'none',
  letterSpacing: -0.5,
  marginLeft: theme.spacing.small,
  alignItems: 'flex-start',
  width: 108,
});

const hasVariantsStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.medium,
});

const attributesDropDownStyle: StyleFn = () => ({
  marginTop: 20,
  width: '100%',
  minHeight: 38,
  alignSelf: 'center',
  zIndex: 9,
});

const selectPlaceHolderStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontSize: 14,
  fontFamily: theme.font.regular,
  lineHeight: 21,
  letterSpacing: -0.5,
});

const searchIconContainerStyle: StyleFn = ({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.spacing.small,
  alignSelf: 'center',
  alignContent: 'center',
});

const touchableStyle: StyleFn = () => ({
  minHeight: 45,
  flexWrap: 'wrap',
});

export interface CreateProductInterface extends CreateProductInput {
  sellingPrice: Money;
  sellingTax: string;
  isVariant: boolean;
  taxInclusive: boolean;
}

interface CreateProductModalProps {
  onCreate: (prodInput: CreateProductInterface, editMore: boolean) => void;
  taxesOptions: { label: string; value: string }[];
  selectedProduct?: {
    pages?: string[];
    name: string;
    isVariant: boolean;
    sellingPrice: Money;
    productType?: string;
    sellingTax: string;
  };
}

export const CreateProductModal: React.FC<CreateProductModalProps> = ({
  onCreate,
  taxesOptions,
  selectedProduct,
}: CreateProductModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { currency, formatCurrency } = useCurrency();

  const isCopyProductMode = selectedProduct !== undefined;
  const {
    pages,
    createPages,
    getPages,
    error: pageError,
    loading,
  } = usePages();
  const {
    productTypes,
    getProductTypes,
    createProductTypes,
    error: productTypesErr,
  } = useProductTypes();

  // default tax should be GST
  const defaultTax =
    taxesOptions?.filter(x => x.label === DEFAULT_TAX)?.[0]?.value || '';

  const [productForm, setProductForm] = useState({
    sellingTax: defaultTax,
    productType: undefined,
  } as CreateProductInterface);
  const { showNotification } = useNotification();
  const currencySymbol = formatCurrency(0).split('0.00')[0];
  useEffect(() => {
    getPages();
    getProductTypes();
  }, [getPages, getProductTypes]);

  const productTypeOptions = useMemo(() => {
    return Object.values(productTypes).map(x => ({
      label: x.name,
      value: x.id,
    }));
  }, [productTypes]);

  useEffect(() => {
    const errorMessage = pageError || productTypesErr;
    if (errorMessage) {
      showNotification({
        error: true,
        message: errorMessage,
      });
    }
  }, [showNotification, pageError, productTypesErr]);

  const pageOptions = useMemo(() => {
    return Object.values(pages).map(x => ({ label: x.name, value: x.id }));
  }, [pages]);

  const onChange = useCallback(
    (prop: string, value) => {
      if (prop === 'sellingPrice') {
        const temp = value.includes(currencySymbol)
          ? value.split(currencySymbol)[1]
          : value;
        value = { currency: currency as Currency, amount: temp };
      }
      setProductForm(prev => {
        return { ...prev, [prop]: value };
      });
    },
    [currencySymbol, currency],
  );

  const onCreateProduct = useCallback(
    editMore => {
      let message = '';
      if (!productForm.name) {
        message = translate('productSettings.pleaseEnterProductName');
      } else if (!productForm.isVariant && !productForm?.sellingPrice?.amount) {
        message = translate(
          'productSettings.pleaseSelectSellingPriceForTheProduct',
        );
      } else if (!productForm.isVariant && !productForm?.sellingTax) {
        message = translate('productSettings.pleaseSelectSellTaxForTheProduct');
      } else if (
        !productForm.isVariant &&
        !isValidPrice(String(productForm.sellingPrice?.amount))
      ) {
        message = translate('form.requiredField', {
          fieldName: 'Selling Price',
        });
      } else {
        let createProdForm = { ...productForm };
        if (!productForm.isVariant) {
          createProdForm = {
            ...productForm,
            taxInclusive: true,
            sellingPrice: {
              amount: +productForm.sellingPrice?.amount || 0,
              currency: productForm.sellingPrice.currency,
            },
          };
        }
        onCreate(createProdForm, editMore);
      }
      if (message) {
        showNotification({
          error: true,
          message,
        });
      }
    },
    [onCreate, productForm, showNotification, translate],
  );

  const sellingPrice = productForm?.sellingPrice?.amount;

  useEffect(() => {
    if (!selectedProduct) return;
    setProductForm({
      ...(selectedProduct as unknown as CreateProductInterface),
    });
  }, [selectedProduct]);

  return (
    <Modal
      title={translate('productSettings.quickCreateProduct')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <FormInput
            title={translate('productSettings.productName')}
            placeholder={translate('productSettings.productName')}
            value={productForm.name}
            containerStyle={css(inputStyle)}
            textStyle={css(createNewFormTextStyle)}
            onChangeText={onChange.bind(null, 'name')}
          />
          <MultipleSelect
            values={pageOptions}
            selectedValues={productForm.pages}
            placeholder={translate('productSettings.addPages') + '...'}
            showCheckboxes={false}
            containerStyle={css(attributesDropDownStyle)}
            searchLabel={translate('productSettings.searchPages') + ':'}
            searchPlaceHolder={translate('productSettings.searchPages') + '...'}
            maxItems={3}
            placeHolderStyle={css(selectPlaceHolderStyle)}
            onValueChange={values => onChange('pages', values)}
            onAddItem={name => createPages([{ name, color: Colors.teal }])}
            testID="add-page"
            toggleIcon={
              <IconButton
                icon={'search-alt'}
                iconSize={20}
                iconColor={theme.colors.paragraph}
                containerStyle={css(searchIconContainerStyle)}
              />
            }
            touchableStyle={css(touchableStyle)}
          />
          <MultipleSelect
            values={productTypeOptions}
            selectedValues={
              productForm.productType ? [productForm.productType] : undefined
            }
            placeholder={translate('productSettings.productType') + '...'}
            showCheckboxes={false}
            containerStyle={css(attributesDropDownStyle)}
            searchLabel={translate('productSettings.productType') + ':'}
            searchPlaceHolder={translate('productSettings.productType') + '...'}
            maxItems={3}
            placeHolderStyle={css(selectPlaceHolderStyle)}
            onValueChange={values => {
              onChange(
                'productType',
                values.length ? values[values.length - 1] : undefined,
              );
            }}
            onAddItem={name => createProductTypes([{ name }])}
            toggleIcon={
              <IconButton
                icon={'search-alt'}
                iconSize={20}
                iconColor={theme.colors.paragraph}
                containerStyle={css(searchIconContainerStyle)}
              />
            }
            touchableStyle={css(touchableStyle)}
          />
          {!productForm.isVariant && (
            <View style={css(rowViewStyle)}>
              <View style={css(width50Per)}>
                <FormInput
                  title={translate('productSettings.sellingPrice')}
                  placeholder={translate('productSettings.sellingPrice')}
                  value={sellingPrice ? currencySymbol + sellingPrice : ''}
                  containerStyle={css(halfSizedStyle1)}
                  textStyle={css(createNewFormTextStyle)}
                  onChangeText={onChange.bind(null, 'sellingPrice')}
                />
              </View>
              <View style={css(width50Per)}>
                <DropDown
                  title={translate('productSettings.sellingTax')}
                  values={taxesOptions}
                  extraStyle={css(dropdownExtraStyle)}
                  extraViewStyle={css(dropdownStyle)}
                  extraMainViewStyle={css(dropDownMainViewStyle)}
                  selectedValue={productForm.sellingTax}
                  style={css(halfSizedStyleRight1)}
                  onValueChange={onChange.bind(null, 'sellingTax')}
                />
              </View>
            </View>
          )}
          {!isCopyProductMode && (
            <Button
              title={translate('productSettings.hasVariants')}
              labelStyle={css(titleStyle)}
              onPress={onChange.bind(null, 'isVariant', !productForm.isVariant)}
              iconPosition={'left'}
              icon={productForm.isVariant ? 'toggle-on' : 'toggle-off'}
              iconProps={{
                color: productForm.isVariant
                  ? theme.colors.success
                  : theme.colors.paragraph,
                size: 26,
              }}
              containerStyle={css(hasVariantsStyle)}
              testID="hasVariant-toggle"
            />
          )}
          <View style={css(rowViewStyle)}>
            <Button
              title={translate('productSettings.editMore')}
              containerStyle={css(buttonStyle)}
              labelStyle={css(createVenueLabelStyle)}
              color={theme.colors.green}
              onPress={onCreateProduct.bind(null, true)}
              secondary
            />

            <Button
              title={translate('productSettings.create')}
              containerStyle={css(
                buttonStyle({
                  theme,
                  marginLeft: theme.spacing.medium,
                  backgroundColor: theme.colors.successLight,
                }),
              )}
              labelStyle={css(createVenueLabelStyle)}
              color={theme.colors.green}
              onPress={onCreateProduct.bind(null, false)}
            />
          </View>
        </>
      )}
    </Modal>
  );
};
