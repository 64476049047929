import { StyleFn } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../../../components/Button/Button';
import { useUserRoles } from '../../../../../../hooks/app/users/useUserRoles';
import { RouteProp } from '@react-navigation/native';
import { useNotification } from '../../../../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import ConfirmationDialog from '../../../../../../components/Modals/ConfirmationDialog';
import { useNavigation } from '@react-navigation/native';

const deleteButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginRight: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  alignSelf: 'auto',
});

const dangerTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
  fontFamily: theme.font.semibold,
  letterSpacing: -0.5,
});

type CustomizeUserRolesParamList = {
  PermissionOverride: { userRoleId: string };
};
export type CustomizeUserRolesRouteProp = RouteProp<
  CustomizeUserRolesParamList,
  'PermissionOverride'
>;

interface Props {
  userId: string;
  userRoleId: string;
}
export const DeleteUserRole: React.FC<Props> = ({ userId, userRoleId }) => {
  const { translate } = useTranslation();
  const { css } = useFela();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();
  const navigation = useNavigation();

  const onDeleteComplete = useCallback(() => {
    showNotification({
      success: true,
      message: translate('backOfficeUsers.userRoleDeletedUpdatedSuccessfully'),
    });
    navigation.navigate('Permissions', {
      userId: userId,
    });
  }, [showNotification, translate, , navigation, userId]);

  const { deleteUserRoles, loading } = useUserRoles({
    userId,
    onDeleteComplete,
  });

  const onDeleteConfirm = useCallback(() => {
    closeModal();
    deleteUserRoles([userRoleId]);
  }, [closeModal, deleteUserRoles, userRoleId]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('backofficeManageRoles.deleteRoleMessage')}
        onConfirm={onDeleteConfirm}
      />,
    );
  }, [translate, showModal, onDeleteConfirm]);

  return (
    <Button
      fluid
      testID="deleteRoleBtn"
      title={translate('button.delete')}
      containerStyle={css(deleteButtonStyle)}
      labelStyle={css(dangerTitleStyle)}
      onPress={onPressDelete}
      loading={loading}
    />
  );
};
