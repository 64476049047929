import { StyleFn } from '@hitz-group/domain';
import scale, { isAndroid, isWeb } from '../../../../common/theme';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

const searchContainerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  height: theme.input.height,
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  color: theme.colors.black,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  paddingHorizontal: theme.padding.small - 3,
});

const titleContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  paddingVertical: theme.spacing.small + 4,
  borderRadius: theme.radius.small,
});

const customTitleTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  color: theme.colors.primaryLightest,
  fontFamily: theme.font.medium,
});

const customTitleDescTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  lineHeight: 20,
  letterSpacing: -0.4,
  fontFamily: theme.font.medium,
  color: theme.colors.darkGrey,
  marginTop: theme.spacing.small - 4,
});

const contentStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.medium,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  paddingVertical: theme.padding.small,
});

const searchAreaView: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  marginTop: theme.spacing.medium,
  marginBottom: theme.spacing.small,
});

const downloadButtonStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  color: theme.colors.blue,
  backgroundColor: theme.colors.highlighted,
  marginLeft: theme.spacing.small,
});

const createButtonStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

const downloadIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const tableRowCells: StyleFn = ({ theme }) => ({
  flex: 1,
  minHeight: theme.input.height - 6,
});

const tableRowCellsText: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.4,
  lineHeight: 20,
});

const customModalContainerStyle: StyleFn = () => ({
  minWidth: scale.moderateScale(500),
  minHeight: 450,
  alignSelf: 'center',
  height: isWeb ? 500 : isAndroid ? 610 : 480,
  paddingTop: 0,
  paddingBottom: 0,
});

const tableHeaderTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.medium,
  lineHeight: 32,
});

const headerCellTextAlignment: StyleFn = () => ({
  textAlign: 'left',
});

const rowCellTextAlignment: StyleFn = () => ({
  justifyContent: 'flex-start',
});

const dataRowStyle: StyleFn = ({ theme }) => ({
  padding: isWeb ? 0 : theme.padding.small,
  paddingHorizontal: theme.padding.large - 4,
  top: isAndroid ? -5 : 0,
});

const editIconContainer: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  marginHorizontal: theme.spacing.small - 5,
});

const deleteIconContainer: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.warningBg,
  marginLeft: theme.spacing.small - 5,
});

export const styles = {
  pageStyle,
  searchContainerStyle,
  searchTextInputStyle,
  titleContainerStyle,
  customTitleTextStyle,
  customTitleDescTextStyle,
  contentStyle,
  columnContainerStyle,
  searchAreaView,
  downloadButtonStyle,
  downloadIconStyle,
  tableRowCells,
  tableRowCellsText,
  customModalContainerStyle,
  tableHeaderTextStyle,
  headerCellTextAlignment,
  rowCellTextAlignment,
  dataRowStyle,
  editIconContainer,
  deleteIconContainer,
  createButtonStyle,
};
