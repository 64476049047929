import { useTranslation } from '@hitz-group/localization';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleFn, Icons } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import IconButton from '../../../components/Button/IconButton';
import Keypad from '../../../components/Keypad/Keypad';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { useNotification } from '../../../hooks/Notification';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import { PosUser, userUtility } from '../../../state/userUtility';
import usePOSUserRoles from '../../../hooks/app/users/usePOSUserRoles';
import { KeyButton } from '../../../components/NumberInput/NumberInput';
import TimeAndAttendanceScreen from './TimeAndAttendanceScreen';
import { useNetInfo } from '@react-native-community/netinfo';

const titleLabelStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  marginLeft: 'auto',
  marginRight: '48%',
});

const titleContainerStyle: StyleFn = () => ({
  justifyContent: 'flex-start',
  width: '100%',
});
const titleHeaderContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.small,
  borderColor: theme.colors.boxBorder,
  height: 40,
  marginBottom: 15,
  ...theme.shadow30,
});

const iconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
});

const defaultKeyArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successLight,
});

const fadedKeyArrowStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.successLightest,
});

interface PosUserPinProps {
  onBack: () => void;
  onVerificationComplete: () => void;
  user: PosUser;
}

const PosUserPinComponent: React.FC<PosUserPinProps> = ({
  onBack,
  onVerificationComplete,
  user,
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const { validateUserPin } = usePOSUserRoles();
  const { showNotification } = useNotification();
  const { canUserAccessPOSApp } = usePOSUserAuthorization();
  const [displayClockInView, setDisplayClockInView] = useState(false);
  const netInfo = useNetInfo();

  const onSubmitPasscode = useCallback(
    (pin: string): void => {
      const currentUser = validateUserPin(pin, user.id);
      if (currentUser) {
        if (!canUserAccessPOSApp(currentUser.id)) {
          showNotification({
            info: true,
            message: translate('appAccess.noPOSAccess'),
          });
          return;
        }

        userUtility.setPosUser({
          id: currentUser.id,
          name: currentUser.name,
          pin: currentUser.pin,
        });

        onVerificationComplete();
      } else {
        setErrorMessage(translate('login.wrongPinMessage'));
      }
    },
    [
      translate,
      validateUserPin,
      showNotification,
      canUserAccessPOSApp,
      user,
      onVerificationComplete,
    ],
  );

  const handleStaffAttendancePress = useCallback(
    (pin: string) => {
      const currentUser = validateUserPin(pin, user.id);
      if (currentUser) {
        setDisplayClockInView(true);
      } else {
        setErrorMessage(translate('login.wrongPinMessage'));
        return false;
      }
    },
    [translate, user, validateUserPin],
  );

  const keys = useMemo(
    () => [
      { value: '1' } as KeyButton,
      { value: '2' } as KeyButton,
      { value: '3' } as KeyButton,
      { value: '4' } as KeyButton,
      { value: '5' } as KeyButton,
      { value: '6' } as KeyButton,
      { value: '7' } as KeyButton,
      { value: '8' } as KeyButton,
      { value: '9' } as KeyButton,
      {
        name: Icons.Clock,
        onPress: handleStaffAttendancePress,
        containerStyle: netInfo.isConnected
          ? defaultKeyArrowStyle
          : fadedKeyArrowStyle,
        iconColor: netInfo.isConnected
          ? theme.colors.success
          : theme.colors.successLight,
        disabled: !netInfo.isConnected,
      } as unknown as KeyButton,
      { value: '0' } as KeyButton,
      {
        name: 'arrow-right',
        onPress: onSubmitPasscode,
        containerStyle: defaultKeyArrowStyle,
        iconColor: theme.colors.success,
      } as unknown as KeyButton,
    ],
    [
      handleStaffAttendancePress,
      onSubmitPasscode,
      theme.colors.success,
      theme.colors.successLight,
      netInfo.isConnected,
    ],
  );

  const clearErrorMessage = useCallback((): void => {
    setErrorMessage('');
  }, [setErrorMessage]);

  if (displayClockInView) {
    return (
      <TimeAndAttendanceScreen
        user={user}
        onClockedInGoToPOS={onVerificationComplete}
        onPressDismiss={onBack}
        title={translate('interimLockScreen.clockScreen.title')}
        instructions={translate('interimLockScreen.clockScreen.instruction')}
      />
    );
  }

  const titleLeft = (
    <IconButton
      testID='[testID="Dashboard"]'
      icon={Icons.ArrowLeft}
      onPress={onBack}
      iconStyle={css(iconStyle)}
    />
  );

  const titleBar = (
    <View style={css(titleHeaderContainerStyle)}>
      <TitleBar
        title={translate('switchUser.enterPin')}
        titleLeft={titleLeft}
        containerStyle={css(titleContainerStyle)}
        labelContainerStyle={css(titleContainerStyle)}
        labelStyle={css(titleLabelStyle)}
      />
    </View>
  );
  return (
    <Keypad
      onSubmit={onSubmitPasscode}
      error={errorMessage}
      onReset={clearErrorMessage}
      title={titleBar}
      secureText={true}
      keys={keys}
    />
  );
};

const PosUserPin = React.memo(PosUserPinComponent);
PosUserPin.displayName = 'PosUserPin';

export default PosUserPin;
