import { StyleFn, User, CreateUserRequest, Icons } from '@hitz-group/domain';
import React, { useEffect, useState, useCallback } from 'react';
import { useFela } from 'react-fela';
import { Helmet } from 'react-helmet';
import { View, ScrollView, Text, TouchableOpacity } from 'react-native';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import IconButton from '../../../../components/Button/IconButton';
import { useTranslation } from '@hitz-group/localization';
import { useNavigation } from '@react-navigation/native';
import TableRow from '../../../../components/TableComponent/TableRow';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { useUsers } from '../../../../hooks/app/useUsers';
import { useNotification } from '../../../../hooks/Notification';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { UserModal } from './CreateUserModal';
import { useModal } from '@hitz-group/rn-use-modal';
import { Operation } from '../../../../types/Operation';
import { useIsFocused } from '@react-navigation/native';
import { isWeb } from '../../../../common/theme';
import Icon from '../../../../components/Icon/Icon';

interface UserRowProps {
  userData: User;
}

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  width: isWeb ? 800 : '100%',
  alignSelf: 'center',
  marginTop: theme.spacing.big + theme.spacing.small / 2,
});

const smallContainer: StyleFn = ({ theme }) => ({
  flex: 1,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginRight: theme.spacing.medium + theme.spacing.small / 2,
});

const emailContainer: StyleFn = ({ theme }) => ({
  flex: 2,
  height: 38,
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
  marginRight: theme.spacing.medium + theme.spacing.small / 2,
});

const actionContainerStyle: StyleFn = () => ({
  width: 38,
  paddingHorizontal: 0,
  paddingTop: 4,
});

const phoneContainer: StyleFn = ({ theme }) => ({
  flex: 1,
  height: 38,
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing.medium + theme.spacing.small / 2,
});

const nameTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  lineHeight: 21,
});

const actionView: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing.medium,
  marginBottom: theme.spacing.small,
});

const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: '85%',
  height: theme.input.height,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  marginRight: theme.spacing.small,
  paddingLeft: theme.spacing.small / 2,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  width: '95%',
  height: theme.input.height,
});

const contentStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing.medium,
});

const adminIconStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

export const UsersRow: React.FC<UserRowProps> = ({
  userData,
}: UserRowProps) => {
  const { css, theme } = useFela();
  const navigation = useNavigation();

  const navigateToUserDetails = useCallback(() => {
    navigation.navigate('UserDetails', {
      userId: userData.id,
    });
  }, [navigation, userData.id]);

  return (
    <TouchableOpacity onPress={navigateToUserDetails}>
      <TableRow
        normalRows={true}
        actionStyle={css(actionContainerStyle)}
        action={
          <IconButton
            primary
            icon={Icons.AngleRight}
            iconSize={24}
            containerSize={34}
            iconColor={theme.colors.text}
            onPress={navigateToUserDetails}
          />
        }
      >
        <View style={css(smallContainer)}>
          <Text style={css(nameTextStyle)}>
            {userData.firstName} {userData.lastName}
          </Text>
        </View>

        <View style={css(emailContainer)}>
          <Text style={css(nameTextStyle)}>{userData.email}</Text>
          {userData.isOwner && (
            <Icon
              style={css(adminIconStyle)}
              name={Icons.InfoCircle}
              size={20}
              color={theme.colors.blue}
            />
          )}
        </View>

        <View style={css(phoneContainer)}>
          <Text style={css(nameTextStyle)}>{userData.phone}</Text>
        </View>
      </TableRow>
    </TouchableOpacity>
  );
};

export const ManageUser: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const {
    searchUsers,
    refetch,
    error,
    loading,
    createUser,
    createdUserId,
    operation,
  } = useUsers();
  const { showModal, closeModal } = useModal();
  const [usersData, setUsersData] = useState<User[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [selectedEditMore, setSelectedEditMore] = useState<boolean>(false);
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const [createdFlag, setCreatedFlag] = useState(false);

  useEffect(() => {
    if (isFocused) {
      refetch();
      setSearchString('');
    }
  }, [isFocused, refetch, setSearchString]);

  useEffect(() => {
    const searchResult = searchUsers(searchString);
    setUsersData(searchResult);
  }, [searchUsers, searchString]);

  const onSearchTextChange = useCallback((value: string) => {
    setSearchString(value);
  }, []);

  const openCreateUserModal = useCallback(() => {
    showModal(
      <UserModal
        createUser={(input: CreateUserRequest, isEditMore: boolean) => {
          setSelectedEditMore(isEditMore);
          setCreatedFlag(false);
          createUser(input);
        }}
      />,
    );
  }, [showModal, createUser]);

  useEffect(() => {
    if (
      createdUserId &&
      !error &&
      !loading &&
      operation === Operation.CREATE &&
      !createdFlag
    ) {
      showNotification({
        success: true,
        message: translate('backOfficeUsers.userCreatedSuccessfully'),
      });
      setCreatedFlag(true);
      closeModal();
      if (selectedEditMore) {
        navigation.navigate('UserDetails', {
          userId: createdUserId,
        });
      }
    }
  }, [
    createdUserId,
    error,
    loading,
    operation,
    selectedEditMore,
    showNotification,
    translate,
    navigation,
    createdFlag,
    closeModal,
  ]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView>
          <BackOfficeSection
            title={translate('backOfficeUsers.manageUsers')}
            titleAction={
              <View style={css(actionView)}>
                <SearchBar
                  placeholder={
                    translate('backOfficeUsers.SearchUsersByNameEmailOrPhone') +
                    '...'
                  }
                  containerStyle={css(searchContainerStyle)}
                  textInputStyle={css(searchTextInputStyle)}
                  iconColor={theme.colors.paragraph}
                  placeholderColor={theme.colors.paragraph}
                  onChange={onSearchTextChange}
                />
                <BackOfficeCreateNewButton onPress={openCreateUserModal} />
              </View>
            }
            contentContainerStyle={css(contentStyle)}
            titleBorderBottom
            containerStyle={css(backOfficeContainerStyle)}
          >
            <TableComponent
              columns={[
                {
                  title: 'User Name',
                  flex: 1,
                  alignItems: 'flex-start',
                },
                {
                  title: translate('form.email'),
                  flex: 2,
                  alignItems: 'flex-start',
                },
                {
                  title: translate('form.phone'),
                  flex: 1,
                  alignItems: 'center',
                },
                { title: '', width: 38 },
              ]}
              columnSpacing={theme.spacing.medium + theme.spacing.small / 2}
              data={usersData}
              normalRows={true}
              renderRow={(item: User, index: number): React.ReactNode => (
                <UsersRow userData={item} key={index} />
              )}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>
    </>
  );
};
