import { useTranslation } from '@hitz-group/localization';
import {
  Icons,
  LoyaltySettings,
  RewardRule,
  StyleFn,
} from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import Modal from '../Modal';
import { getLoyaltyUnit } from '@hitz-group/client-utils';

const containerStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'center',
  alignItems: 'center',
  borderRadius: theme.radius.large,
  backgroundColor: theme.colors.white,
  paddingVertical: 0,
  paddingHorizontal: 0,
  margin: 0,
});

const customBodyStyle: StyleFn = ({}) => ({
  marginTop: 0,
  paddingTop: 0,
  paddingBottom: 0,
});

const statisticView: StyleFn = ({ theme }) => ({
  height: 180,
  alignItems: 'center',
  justifyContent: 'center',
  borderTopLeftRadius: theme.radius.large,
  borderTopRightRadius: theme.radius.large,
  backgroundColor: theme.colors.success,
});

const earnText: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  ...theme.font14SemiBold,
  fontSize: 20,
  marginTop: theme.spacing.small,
});

const availableRewardsHeading: StyleFn = ({ theme }) => ({
  ...theme.font14SemiBold,
  fontSize: theme.fontSize.medium,
  textAlign: 'center',
});

const earnPointText: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  marginTop: theme.spacing.small,
  color: theme.colors.white,
  textAlign: 'center',
});

const requirePointStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.textLight,
  textAlign: 'center',
  padding: theme.padding.small,
});

const rewardNameStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.grey2,
  textAlign: 'center',
  padding: theme.padding.small,
});

const dismissButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  textTransform: 'uppercase',
});

const actionButtonContainer: StyleFn = () => ({
  flexDirection: 'row',
  width: 340,
  height: 'auto',
  marginBottom: 0,
  marginTop: 20,
});

const dismissButton: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  backgroundColor: theme.colors.greyLight,
  marginBottom: theme.spacing.small,
});

const rewardItemContainer: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  paddingVertical: theme.padding.large - 4,
  paddingHorizontal: theme.padding.large,
  marginTop: theme.padding.large,
  borderRadius: theme.border.medium,
});

const formContainer: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  paddingVertical: theme.padding.large,
});

export interface ShowAvailableRewardsProps {
  totalPoints: number;
  onDismiss: () => void;
  pointEarned: number;
  availableRewards: RewardRule[];
  loyaltySettings: Partial<LoyaltySettings>;
}

const ShowAvailableRewards: React.FC<ShowAvailableRewardsProps> = ({
  totalPoints,
  onDismiss,
  pointEarned,
  availableRewards,
  loyaltySettings,
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();

  return (
    <Modal
      contentStyle={css(containerStyle)}
      showCloseButton={false}
      customBodyStyle={customBodyStyle}
    >
      <View style={css(statisticView)}>
        <IconButton
          icon={Icons.Star}
          iconColor={theme.colors.white}
          iconSize={50}
        />
        <Text style={css(earnText)} testID="earn-text">
          {translate('customerLoyalty.pointsEarned', {
            points: pointEarned,
            unit: getLoyaltyUnit(pointEarned, loyaltySettings),
          })}
        </Text>
        <Text style={css(earnPointText)}>
          {translate('customerLoyalty.earnedPointsThisPurchase', {
            points: pointEarned,
            unit: getLoyaltyUnit(pointEarned, loyaltySettings),
          })}
        </Text>
        <Text style={css(earnPointText)}>
          {translate('customerLoyalty.totalPoints', {
            points: totalPoints,
            unit: loyaltySettings.pluralTerm,
          })}{' '}
        </Text>
      </View>
      <View style={css(formContainer)}>
        <Text style={css(availableRewardsHeading)}>
          {translate('customerLoyalty.availableRewards')}
        </Text>
        {availableRewards.length ? (
          availableRewards.map(reward => {
            return (
              <View
                key={reward.id}
                style={css(rewardItemContainer)}
                testID="reward-item"
              >
                <Text style={css(requirePointStyle)}>
                  {`${reward.pointsRequired} ${getLoyaltyUnit(
                    reward.pointsRequired,
                    loyaltySettings,
                  )}`}
                </Text>
                <Text style={css(rewardNameStyle)}>{reward.rewardName}</Text>
              </View>
            );
          })
        ) : (
          <View style={css(rewardItemContainer)}>
            <Text style={css(requirePointStyle)} testID="no-reward">
              {translate('customerLoyalty.noRewardsEarned')}
            </Text>
          </View>
        )}
        <View style={css(actionButtonContainer)}>
          <Button
            key={'dismiss'}
            testID={'dismiss'}
            containerStyle={css(dismissButton)}
            labelStyle={css(dismissButtonStyle)}
            title={translate('button.dismiss')}
            onPress={onDismiss}
            fluid
          />
        </View>
      </View>
    </Modal>
  );
};

export default ShowAvailableRewards;
