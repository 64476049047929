import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';

export const useLoyaltyStyle = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        rowContainerStyle: {
          height: 53,
          alignItems: 'center',
          paddingLeft: 14,
        },
        editButtonStyle: {
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.highlighted,
          marginLeft: 'auto',
        },
        editIconContainer: {
          flex: 1,
        },
        valueText: {
          width: 100,
        },
        descriptionText: {
          width: 300,
        },
        venueText: {
          width: 50,
        },
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        formStyle: {
          width: '100%',
          paddingBottom: theme.spacing.big,
        },
        containerStyle: {
          width: 600,
          alignSelf: 'center',
          marginBottom: 0,
        },

        formInputContainerStyle: {
          width: 270,
          height: theme.input.height,
          justifyContent: 'center',
        },

        textStyle: {
          marginLeft: theme.padding.small,
        },

        settingRowContainer: {
          width: '100%',
          flexDirection: 'row',
          paddingRight: theme.spacing.small,
          alignItems: 'center',
          justifyContent: 'space-between',
        },

        scrollStyle: {
          paddingHorizontal: theme.padding.large,
          backgroundColor: theme.colors.white,
        },

        columnContainerStyle: {
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          borderBottomWidth: 0,
          marginTop: theme.spacing.small,
        },
        paginationStyle: {
          marginRight: 10,
          marginTop: 10,
        },
        addIcon: {
          color: theme.colors.primary,
        },
        addIconContainer: {
          backgroundColor: theme.colors.successLight,
          marginRight: theme.spacing.small,
          height: 34,
          width: 34,
          borderRadius: theme.radius.small,
          marginLeft: 'auto',
          marginVertical: 10,
        },
        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
        },
        mainStyle: {
          backgroundColor: theme.colors.white,
        },
        saveButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginLeft: 'auto',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
          alignSelf: 'auto',
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
      }),
    [theme],
  );
};
