import {
  StyleFn,
  Table,
  TableShape,
  TableStatus,
  Theme,
} from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View, Text, TouchableOpacity } from 'react-native';

const tableBackgroundColorByStatus = (theme: Theme) => ({
  [TableStatus.OCCUPIED]: theme.colors.blue,
  [TableStatus.DONE_SOON]: theme.colors.success,
  [TableStatus.SEATED]: theme.colors.blue,
  [TableStatus.YOUR_TABLE]: theme.colors.white,
  [TableStatus.AVAILABLE]: theme.colors.white,
  [TableStatus.IN_USE]: theme.colors.white,
  [TableStatus.INACTIVE]: theme.colors.white,
  [TableStatus.MULTI_USE]: theme.colors.deepPurpleDark,
});

const tableBorderColorByStatus = (theme: Theme) => ({
  [TableStatus.OCCUPIED]: theme.colors.blue,
  [TableStatus.DONE_SOON]: theme.colors.success,
  [TableStatus.SEATED]: theme.colors.blue,
  [TableStatus.YOUR_TABLE]: theme.colors.paragraph,
  [TableStatus.AVAILABLE]: theme.colors.paragraph,
  [TableStatus.IN_USE]: theme.colors.paragraph,
  [TableStatus.INACTIVE]: theme.colors.paragraph,
  [TableStatus.MULTI_USE]: theme.colors.deepPurpleDark,
});

const wrapperStyle: StyleFn = ({
  width,
  height,
  theme,
  blur,
  highlight,
  status,
}) => ({
  width,
  height,
  alignItems: 'center',
  justifyContent: 'center',
  margin: 10,
  padding: 5,
  borderRadius: theme.radius.small,
  opacity: blur ? 0.3 : 1,
  borderColor: highlight
    ? tableBorderColorByStatus(theme)[status as TableStatus]
    : undefined,
  borderWidth: highlight ? 1 : 0,
});

const roundContainerStyle: StyleFn = ({ theme, status }) => ({
  width: 60,
  height: 60,
  borderColor:
    tableBorderColorByStatus(theme)[status as TableStatus] ||
    theme.colors.paragraph,
  backgroundColor:
    tableBackgroundColorByStatus(theme)[status as TableStatus] ||
    theme.colors.white,
  borderWidth: 2,
  borderRadius: 30,
  alignItems: 'center',
  justifyContent: 'center',
});

const squareContainerStyle: StyleFn = ({ theme, status }) => ({
  width: 60,
  height: 60,
  borderColor:
    tableBorderColorByStatus(theme)[status as TableStatus] ||
    theme.colors.paragraph,
  backgroundColor:
    tableBackgroundColorByStatus(theme)[status as TableStatus] ||
    theme.colors.white,
  borderWidth: 2,
  alignItems: 'center',
  justifyContent: 'center',
});

const rectangleContainerStyle: StyleFn = ({ theme, status }) => ({
  width: 90,
  height: 60,
  borderColor:
    tableBorderColorByStatus(theme)[status as TableStatus] ||
    theme.colors.paragraph,
  backgroundColor:
    tableBackgroundColorByStatus(theme)[status as TableStatus] ||
    theme.colors.white,
  borderWidth: 2,
  alignItems: 'center',
  justifyContent: 'center',
});

const tableDotBackgroundByStatus = (theme: Theme) => ({
  [TableStatus.OCCUPIED]: theme.colors.blue,
  [TableStatus.DONE_SOON]: theme.colors.success,
  [TableStatus.SEATED]: theme.colors.blue,
  [TableStatus.YOUR_TABLE]: '',
  [TableStatus.AVAILABLE]: '',
  [TableStatus.IN_USE]: '',
  [TableStatus.INACTIVE]: '',
  [TableStatus.MULTI_USE]: theme.colors.deepPurpleDark,
});

const tableDotBorderByStatus = (theme: Theme) => ({
  [TableStatus.OCCUPIED]: theme.colors.white,
  [TableStatus.DONE_SOON]: theme.colors.white,
  [TableStatus.SEATED]: theme.colors.white,
  [TableStatus.YOUR_TABLE]: '',
  [TableStatus.AVAILABLE]: '',
  [TableStatus.IN_USE]: '',
  [TableStatus.INACTIVE]: '',
  [TableStatus.MULTI_USE]: theme.colors.white,
});

const tableDot: StyleFn = ({ theme, top, left, status }) => ({
  width: 14,
  height: 14,
  position: 'absolute',
  borderColor:
    tableDotBorderByStatus(theme)[status as TableStatus] ||
    theme.colors.paragraph,
  backgroundColor:
    tableDotBackgroundByStatus(theme)[status as TableStatus] ||
    theme.colors.white,
  borderWidth: 2,
  borderRadius: 7,
  top,
  left,
});

const tripleDot: StyleFn = ({ theme, top, left }) => ({
  width: 10,
  height: 10,
  position: 'absolute',
  borderColor: theme.colors.white,
  backgroundColor: theme.colors.deepPurpleDark,
  borderWidth: 2,
  borderRadius: 5,
  top,
  left,
});

const tableNameColorByStatus = (theme: Theme) => ({
  [TableStatus.OCCUPIED]: theme.colors.white,
  [TableStatus.DONE_SOON]: theme.colors.white,
  [TableStatus.SEATED]: theme.colors.white,
  [TableStatus.YOUR_TABLE]: '',
  [TableStatus.AVAILABLE]: '',
  [TableStatus.IN_USE]: '',
  [TableStatus.INACTIVE]: '',
  [TableStatus.MULTI_USE]: theme.colors.white,
});

const tableNameStyle: StyleFn = ({ theme, status }) => ({
  ...theme.font14Regular,
  fontWeight: 'bold',
  color:
    tableNameColorByStatus(theme)[status as TableStatus] || theme.colors.grey2,
});
export interface TableIconProps {
  onPressTable: (table: Table) => void;
  table: Table;
  disabled?: boolean;
  blur?: boolean;
  highlight?: boolean;
  isMultiOrder?: boolean;
}

export const TableIcon: React.FC<TableIconProps> = ({
  onPressTable,
  table,
  blur,
  highlight,
  disabled = false,
  isMultiOrder = false,
}) => {
  const { css, theme } = useFela();
  let container: StyleFn = roundContainerStyle;
  let dot1 = { top: 0, left: 0 },
    dot2 = { top: 0, left: 42 },
    dot3 = { top: 42, left: 0 },
    dot4 = { top: 42, left: 42 };
  const dot5 = { top: -8, left: 51 },
    dot6 = { top: 50, left: 51 };

  let multiOrderDot1 = { top: 67, left: 30 },
    multiOrderDot2 = { top: 67, left: 38 },
    multiOrderDot3 = { top: 67, left: 46 };
  let wrapperWidth = 60;
  const wrapperHeight = 60;

  switch (table.shape) {
    case TableShape.SQUARE:
      container = squareContainerStyle;
      dot1 = { top: -8, left: 21 };
      dot2 = { top: 21, left: 50 };
      dot3 = { top: 50, left: 21 };
      dot4 = { top: 21, left: -8 };
      multiOrderDot1 = { top: 67, left: 17 };
      multiOrderDot2 = { top: 67, left: 25 };
      multiOrderDot3 = { top: 67, left: 33 };
      break;
    case TableShape.RECTANGLE:
      container = rectangleContainerStyle;
      dot1 = { top: -8, left: 21 };
      dot2 = { top: 21, left: 80 };
      dot3 = { top: 50, left: 21 };
      dot4 = { top: 21, left: -8 };
      wrapperWidth = 90;
      break;
    default:
      container = roundContainerStyle;
      dot1 = { top: 0, left: 0 };
      dot2 = { top: 0, left: 42 };
      dot3 = { top: 42, left: 0 };
      dot4 = { top: 42, left: 42 };
      multiOrderDot1 = { top: 67, left: 17 };
      multiOrderDot2 = { top: 67, left: 25 };
      multiOrderDot3 = { top: 67, left: 33 };
      break;
  }

  const statusForUi = isMultiOrder ? TableStatus.MULTI_USE : table.status;

  return (
    <View
      style={css(
        wrapperStyle({
          theme,
          width: wrapperWidth + 10,
          height: wrapperHeight + 10,
          status: statusForUi,
          blur,
          highlight,
        }),
      )}
    >
      <TouchableOpacity onPress={() => onPressTable(table)} disabled={disabled}>
        <View
          style={css(container({ theme, status: statusForUi }))}
          testID="table-background"
        >
          <View
            testID="table-dot-1"
            style={css(tableDot({ theme, ...dot1, status: statusForUi }))}
          />
          <View
            testID="table-dot-2"
            style={css(tableDot({ theme, ...dot2, status: statusForUi }))}
          />
          <View
            testID="table-dot-3"
            style={css(tableDot({ theme, ...dot3, status: statusForUi }))}
          />
          <View
            testID="table-dot-4"
            style={css(tableDot({ theme, ...dot4, status: statusForUi }))}
          />
          {table.shape === TableShape.RECTANGLE ? (
            <>
              <View
                testID="table-dot-5"
                style={css(tableDot({ theme, ...dot5, status: statusForUi }))}
              />
              <View
                testID="table-dot-6"
                style={css(tableDot({ theme, ...dot6, status: statusForUi }))}
              />
            </>
          ) : null}
          <Text style={css(tableNameStyle({ theme, status: statusForUi }))}>
            {table.name}
          </Text>
        </View>

        {isMultiOrder && (
          <>
            <View
              testID="multi-table-dot-1"
              style={css(tripleDot({ theme, ...multiOrderDot1 }))}
            />
            <View
              testID="multi-table-dot-2"
              style={css(tripleDot({ theme, ...multiOrderDot2 }))}
            />
            <View
              testID="multi-table-dot-3"
              style={css(tripleDot({ theme, ...multiOrderDot3 }))}
            />
          </>
        )}
      </TouchableOpacity>
    </View>
  );
};
