import {
  EnrollCustomerInput,
  FeatureContext,
  Features,
  NotificationMode,
  NotificationType,
  OrderAction,
  OrderEvent,
  OrderPaymentEvent,
  OrderPaymentProcessedEvent,
  OrderPaymentStatus,
  OrderStatus,
  OrderTypeCode,
  PaymentAction,
  PaymentSubScreen,
} from '@hitz-group/domain';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import {
  ReceiptPrintOption,
  RenderProps,
  StyleFn,
  PaymentType,
  Customer,
  PaymentMode,
} from '@hitz-group/domain';
import { AssignCustomerEvent } from '@hitz-group/domain';
import {
  useRoute,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { ActivityIndicator, Text, View } from 'react-native';
import CartItems from '../../../components/Cart/CartItems';
import { CartHeader } from '../../../components/CartHeader/CartHeader';
import CartSummary from '../../../components/CartSummary/CartSummary';
import { LeftArrow } from '../../../components/HeaderIcons/HeaderIcons';
import PaymentOptionHeader from '../../../components/PaymentOptions/PaymentOptionHeader';
import PaymentOptions from '../../../components/PaymentOptions/PaymentOptions';
import Layout from '../../../components/POSLayout/POSLayout';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { useModal } from '@hitz-group/rn-use-modal';
import { useNotification } from '../../../hooks/Notification';
import { useCartContext as useCart } from '../../../hooks/CartProvider';
import { useSession } from '../../../hooks/app/useSession';
import { AppScreen } from '../../../types/AppScreen';
import { v4 as uuidv4 } from 'uuid';
import { usePrinting } from '../../../hooks/PrintingProvider';
import { isWeb } from '../../../common/theme';
import { changeDueVar } from '../../../App';
import { useOrders } from '../../../hooks/app/orders/useOrders';
import { userUtility } from '../../../state/userUtility';
import { getLoyaltyUnit, roundOffByValue } from '@hitz-group/client-utils';
import { getRoundOffValue } from '../../../utils/roundOffHelper';
import { useCourses } from '../../../hooks/app/courses/useCourses';
import PaymentHeadingInfo from '../../../components/PaymentOptions/HeadingInfo';
import PaymentSuccessScreen from '../../../components/PaymentOptions/PaymentSuccessScreen';
import CompleteSaleModal from '../../../components/Modals/CompleteSaleModal/CompleteSaleModal';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import { usePostSalesNavigation } from '../../../hooks/app/usePostSalesNavigation';
import { takeOrderUnFocusClearParamController } from './takeOrderObservable';
import { isEmpty } from 'lodash';
import { useSyncOrderEvents } from '../../../hooks/app/useSyncOrderEvents';
import {
  AdditionalPaymentInfo,
  ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER,
  IN_COMPLETE_PAYMENT_STATUS_LIST,
} from '../../../types/Common';
import ShowAvailableRewards from '../../../components/Modals/CustomerLoyalty/ShowAvailableRewards';
import { useLoyalty } from '../../../hooks/app/loyalty/useLoyalty';
import { calculatePointOfEarnByAmount } from '@hitz-group/client-utils';
import Button from '../../../components/Button/Button';
import ModalWrapper from '../../../hooks/ModalWrapper';
import lastItem from 'lodash/last';
import { usePosState } from '../../../hooks/POSStateProvider';
import EnrollCustomerModal from '../../../components/Modals/CustomerLoyalty/EnrollCustomerModal';

const CASH_PAYMENT_TYPE = 'cash';
const ON_ACCOUNT_PAYMENT_TYPE = 'On Account';

const body: StyleFn = ({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  backgroundColor: theme.colors.background,
  paddingLeft: theme.spacing.small,
  paddingRight: theme.spacing.small,
});

const cartStyle: StyleFn = ({ theme }) => ({
  width: 330,
  flexWrap: 'wrap',
  flexDirection: 'column',
  paddingLeft: theme.spacing.small,
  paddingRight: theme.spacing.small,
  paddingTop: theme.padding.medium,
  paddingBottom: theme.padding.medium,
});

const paymentContainerStyle: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

const paymentOptionContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.small,
  padding: theme.spacing.big + 10,
  paddingBottom: theme.spacing.big + 10,
  alignItems: 'center',
});

const titleContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.primaryDarkest,
  width: 413,
  height: 44,
  justifyContent: 'center',
  alignItems: 'center',
});

const loadingStyle: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

const LightLabelStyle: StyleFn = ({ theme, highlighted }) => ({
  fontFamily: highlighted ? theme.font.semibold : theme.font.regular,
  fontSize: highlighted ? theme.fontSize.medium : theme.fontSize.smaller,
  color: highlighted ? theme.colors.heading : theme.colors.paragraph,
  marginTop: 10,
});

const cartItemsStyle: StyleFn = () => ({
  marginBottom: 10,
});

const loyaltyButtonStyle: StyleFn = ({ theme }) => ({
  height: 50,
  marginTop: 20,
  marginRight: 10,
  backgroundColor: theme.colors.paletteLightBlue,
});

const loyaltyLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  textTransform: 'uppercase',
});

export const Loading: React.FC = () => (
  <FelaComponent style={loadingStyle}>
    {({ style, theme }: RenderProps): React.ReactNode => (
      <ActivityIndicator color={theme.colors.primary} style={style} />
    )}
  </FelaComponent>
);

const paymentInfoContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginTop: 10,
  marginBottom: isWeb ? 10 : 0,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  paddingHorizontal: theme.spacing.medium,
  paddingVertical: theme.padding.small,
});

const textContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: isWeb ? 0 : theme.padding.small,
});

const totalRemainingStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontSize: theme.fontSize.medium,
  textTransform: 'capitalize',
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  lineHeight: 35,
});

const totalOutstandingAmountStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontSize: theme.fontSize.medium,
  textTransform: 'capitalize',
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  lineHeight: 35,
});

const currentBalanceAmtStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontSize: theme.fontSize.medium,
  textTransform: 'capitalize',
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  lineHeight: 35,
});

const totalRemainingAmtStyle: StyleFn = ({ theme, success }) => ({
  color: success ? theme.colors.success : theme.colors.danger,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  lineHeight: 35,
});

export interface OrderPaymentDetails {
  totalDueAmount: number;
  totalPaidAmount: number;
  remainingDueAmount: number;
  processingAmount: number;
  paymentOption: PaymentMode;
  nthPayment: number;
  activeScreen: PaymentSubScreen;
  isPaymentCompleted: boolean;
  isSplitPayment: boolean;
  isCustomerBalancePayment: boolean;
  lastChangeDueAmount: number;
  lastReceivedAmount: number;
  numberOfSplitPayment: number;
  nthSplitPayment: number;
}

interface PaymentActionPayload {
  paymentMode: PaymentMode;
  amountDue: number;
  totalAmount: number;
  processingAmount: number;
  isPaymentCompleted: boolean;
  nthPayment: number;
  customerAmount: number;
  lastChangeDueAmount: number;
  lastReceivedAmount: number;
  numberOfSplitPayment: number;
}

export interface PaymentActionProp {
  type: PaymentAction;
  payload: Partial<PaymentActionPayload>;
}

export const initialState: OrderPaymentDetails = {
  activeScreen: PaymentSubScreen.MAIN,
  paymentOption: PaymentMode.PAY_IN_FULL,
  isSplitPayment: false,
  processingAmount: 0,
  nthPayment: 0,
  totalPaidAmount: 0,
  remainingDueAmount: 0,
  totalDueAmount: 0,
  isPaymentCompleted: false,
  isCustomerBalancePayment: false,
  lastReceivedAmount: 0,
  lastChangeDueAmount: 0,
  numberOfSplitPayment: Infinity,
  nthSplitPayment: Infinity,
};

function paymentReducer(
  state: OrderPaymentDetails,
  action: PaymentActionProp,
): OrderPaymentDetails {
  const { type, payload } = action;

  switch (type) {
    case PaymentAction.UPDATE_PAYMENT_MODE:
      return {
        ...state,
        paymentOption: payload.paymentMode as PaymentMode,
        isSplitPayment: payload.paymentMode === PaymentMode.SPLIT_PAYMENT,
      };

    case PaymentAction.SET_PAYMENT_AMOUNT:
      return {
        ...state,
        processingAmount: payload.processingAmount as number,
        activeScreen: PaymentSubScreen.PAYMENT_OPTIONS,
        nthPayment: state.nthPayment + 1,
      };

    case PaymentAction.UPDATE_PAYMENT_AMOUNT:
      return {
        ...state,
        processingAmount: payload.processingAmount as number,
      };

    case PaymentAction.UPDATE_PAYMENT_INFO_FROM_ORDER:
      const {
        totalAmount = 0,
        amountDue = 0,
        isPaymentCompleted,
        nthPayment = 0,
      } = payload;

      const totalPaidAmount = totalAmount - amountDue;

      const nextScreen = isPaymentCompleted
        ? PaymentSubScreen.PAYMENT_COMPLETE
        : state.activeScreen;

      return {
        ...state,
        totalDueAmount: totalAmount,
        totalPaidAmount,
        processingAmount: amountDue,
        remainingDueAmount: amountDue,
        activeScreen: nextScreen,
        isPaymentCompleted: Boolean(isPaymentCompleted),
        nthPayment,
      };

    case PaymentAction.TAKE_NEXT_PAYMENT:
      return {
        ...state,
        nthPayment: state.nthPayment + 1,
        activeScreen: PaymentSubScreen.PAYMENT_OPTIONS,
        nthSplitPayment: state.isSplitPayment
          ? state.nthSplitPayment + 1
          : state.nthSplitPayment,
      };

    case PaymentAction.CANCEL_CURRENT_STEP:
      return {
        ...state,
        activeScreen: PaymentSubScreen.MAIN,
        processingAmount: state.totalDueAmount - state.totalPaidAmount,
        nthPayment: Math.max(0, state.nthPayment - 1),
        numberOfSplitPayment: Infinity,
        nthSplitPayment: Infinity,
      };
    case PaymentAction.PAY_OUT:
      const newState = {
        ...state,
        activeScreen: PaymentSubScreen.PAYMENT_COMPLETE,
        lastChangeDueAmount: payload.lastChangeDueAmount || 0,
        lastReceivedAmount: payload.lastReceivedAmount || 0,
      };
      if (state.isCustomerBalancePayment) {
        const newProcessingAmount =
          state.remainingDueAmount - state.processingAmount;
        const isPaymentCompleted = newProcessingAmount <= 0;
        return {
          ...newState,
          isPaymentCompleted,
          remainingDueAmount: newProcessingAmount,
          processingAmount: newProcessingAmount,
          totalPaidAmount: state.totalPaidAmount + state.processingAmount,
        };
      }
      return {
        ...newState,
        processingAmount: 0,
      };

    case PaymentAction.RESET_INITIAL_STATE:
      return initialState;

    case PaymentAction.UPDATE_CUSTOMER_BALANCE_PAYMENT:
      const { customerAmount = 0 } = payload;
      return {
        ...state,
        processingAmount: customerAmount,
        isCustomerBalancePayment: true,
        remainingDueAmount: customerAmount,
        totalDueAmount: customerAmount,
        nthPayment: 0,
      };

    case PaymentAction.SETUP_NUMBER_OF_SPLIT_PAYMENT:
      const { numberOfSplitPayment = 0 } = payload;
      return {
        ...state,
        numberOfSplitPayment: numberOfSplitPayment,
        isSplitPayment: true,
        activeScreen: PaymentSubScreen.PAYMENT_OPTIONS,
        nthPayment: state.nthPayment + 1,
        nthSplitPayment: 1,
      };

    default:
      return state;
  }
}

const PaymentScreen: React.FC = () => {
  const route = useRoute();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { formatCurrency } = useCurrency();
  const isFocused = useIsFocused();
  const {
    updateCustomerBalance: updateCustomerOnAccount,
    customerMaps,
    enrollCustomerLoyalty,
    loading: customerLoading,
  } = usePosState('customers');

  const routeParams = route.params as {
    customerAmount: number;
    customerId: string;
  };
  const { customerAmount, customerId } = routeParams;
  const navigation = useNavigation();
  const { navigateToPostSaleScreen } = usePostSalesNavigation();
  const [orderPaymentDetails, dispatch] = useReducer(
    paymentReducer,
    initialState,
  );
  const {
    order,
    updateCart,
    resetCart,
    setCartParams,
    getOrderData,
    status: { loading, error },
  } = useCart();

  const [isShowCompleteSaleModal, setShowCompleteSaleModal] = useState(false);
  const [isShowRewardModal, setShowRewardModal] = useState(false);
  const [isShowEnrollLoyaltyModal, setShowEnrollLoyaltyModal] = useState(false);
  const hideLoyaltyEnrollButton = useRef(false);

  const {
    remainingDueAmount,
    isPaymentCompleted,
    activeScreen,
    nthPayment,
    isSplitPayment,
    processingAmount,
    isCustomerBalancePayment,
    lastChangeDueAmount,
    lastReceivedAmount,
  } = orderPaymentDetails;

  const [session] = useSession();
  const { getCourses, courses } = useCourses();
  const currentVenueId = session?.currentVenue?.id;
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isLoyaltyEnabled = isFeatureEnabled(
    Features.LOYALTY,
    FeatureContext.ORGANIZATION,
  );
  const { getLoyaltyPrograms, earningRules, rewardRules, loyaltySettings } =
    useLoyalty(currentVenueId);

  const onSendSuccess = () => {
    showNotification({
      success: true,
      message: translate('common.sendSaleReceiptToCustomerSuccess'),
    });
    navigateToPostSaleScreen();
    closeModal();
  };
  const { syncOrderEvents } = useSyncOrderEvents(onSendSuccess);

  const isCoursesEnabled =
    session.deviceProfile?.enableCourses &&
    isFeatureEnabled(
      Features.COURSES,
      FeatureContext.VENUE,
      session?.currentVenue?.id,
    ) &&
    order?.orderType?.code === OrderTypeCode.DINE_IN;

  const enableQuickPaymentMode: boolean =
    session?.settings?.enableQuickPaymentModeSetting || false;

  const params = route.params as {
    orderId: string;
  };
  const enableRoundOff =
    session?.currentStore?.checkoutOptions?.enableRoundOff || false;
  const roundOffValue = getRoundOffValue(
    session?.currentStore?.checkoutOptions?.roundOffValue,
  );

  const { postSaleScreen, receiptPrintOption, defaultOrderType } =
    session.deviceProfile || {};
  const showPrintReceiptButton =
    receiptPrintOption === ReceiptPrintOption.NO_PRINT;

  const { getOrderFromCache } = useOrders();

  useEffect(() => {
    if (params?.orderId) {
      getOrderData(params?.orderId);
    }
  }, [params?.orderId, getOrderData]);

  useEffect(() => {
    isCoursesEnabled && getCourses();
  }, [getCourses, isCoursesEnabled]);

  useEffect(() => {
    if (!earningRules?.length) {
      getLoyaltyPrograms();
    }
  }, [earningRules?.length, getLoyaltyPrograms]);

  const { printBill, openCashDrawer } = usePrinting();

  const orderNumber = order?.orderNumber || '';

  const { css } = useFela({ success: remainingDueAmount <= 0 });

  useEffect(() => {
    if (customerAmount && isFocused) {
      dispatch({
        type: PaymentAction.UPDATE_CUSTOMER_BALANCE_PAYMENT,
        payload: {
          customerAmount,
        },
      });
    }
  }, [customerAmount, isFocused]);

  const assignedCustomer = useMemo(() => {
    const selectedCustomerId = customerId || order?.customer?.id || '';
    return customerMaps[selectedCustomerId];
  }, [customerId, customerMaps, order?.customer?.id]);

  const paymentSummary = useMemo(() => {
    return (order?.payments || [])
      .filter(
        payment =>
          !IN_COMPLETE_PAYMENT_STATUS_LIST.includes(
            payment.status as OrderPaymentStatus,
          ),
      )
      .map(payment => {
        return {
          name: payment.paymentType.name,
          amount: payment.amount,
        };
      });
  }, [order?.payments]);

  /**
   * On 'success' - send sale receipt event
   */
  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (customerAmount) return;
    dispatch({
      type: PaymentAction.UPDATE_PAYMENT_INFO_FROM_ORDER,
      payload: {
        totalAmount: (order?.totalPrice || 0) as number,
        amountDue: (order?.amountDue || 0) as number,
        isPaymentCompleted: order?.status === OrderStatus.COMPLETED,
        nthPayment: order?.payments?.length || 0,
      },
    });
  }, [
    customerAmount,
    order?.amountDue,
    order?.payments?.length,
    order?.status,
    order?.totalPrice,
  ]);

  const navigateToDefaultScreen = useCallback(async () => {
    if (postSaleScreen && AppScreen[postSaleScreen] != AppScreen.NEW_ORDER) {
      const newOrderId = await resetCart();
      setCartParams(newOrderId, defaultOrderType?.id, '', false);
    }
    navigateToPostSaleScreen();
    closeModal();
  }, [
    closeModal,
    defaultOrderType?.id,
    navigateToPostSaleScreen,
    postSaleScreen,
    resetCart,
    setCartParams,
  ]);

  const onPrintSplit = useCallback(async () => {
    if (order) {
      const cachedOrder = getOrderFromCache(order.id) || order;
      const nthPaymentToPrint = (cachedOrder?.payments || []).length - 1;
      const result = await printBill(cachedOrder, nthPaymentToPrint);
      if (result && Object.keys(result)?.length > 0 && result.error) {
        showNotification(result);
      }
      if (isPaymentCompleted) navigateToDefaultScreen();
    }
  }, [
    getOrderFromCache,
    isPaymentCompleted,
    navigateToDefaultScreen,
    order,
    printBill,
    showNotification,
  ]);

  /**
   * Send order sale receipt to customer's email address
   *
   * @param email  email address of customer incase when the order is being placed without customer information
   */
  const sendOrderReceiptToEmail = useCallback(
    async (email: string) => {
      if (session && order?.id) {
        const event = {
          action: OrderAction.CUSTOMER_NOTIFICATION,
          orderId: order.id,
          id: uuidv4(),
          notificationType: NotificationType.SEND_RECEIPT,
          notificationMode: NotificationMode.EMAIL,
          organizationId: session.currentOrganization?.id || '',
          venueId: session.currentVenue?.id || '',
          storeId: session.currentStore?.id || '',
          deviceId: session.device?.id || '',
          timestamp: Date.now(),
          triggeredBy: userUtility.userActivity.posUser?.id || '',
          email,
        };
        syncOrderEvents([event]);
      }
    },
    [session, order?.id, syncOrderEvents],
  );

  useEffect(() => {
    if (!isFocused) return;
    return () => {
      dispatch({ type: PaymentAction.RESET_INITIAL_STATE, payload: {} });
    };
  }, [isFocused]);

  const onPressPrintReceipt = useCallback(async (): Promise<void> => {
    if (order) {
      const cachedOrder = getOrderFromCache(order.id) || order;
      const result = await printBill(cachedOrder);
      if (result && Object.keys(result)?.length > 0 && result.error) {
        showNotification(result);
      }
      navigateToDefaultScreen();
    }
  }, [
    order,
    getOrderFromCache,
    printBill,
    navigateToDefaultScreen,
    showNotification,
  ]);

  /**
   * Case1: If the customer receipt printing option is set as Print Receipts Automatically in the register profile settings
   * Receipt should be printed automatically when the complete sale button is selected from the payment screen
   *
   * OR
   *
   * Case2: If the customer receipt printing Option is set as Prompt for Print/Email Receipt in the register profile settings,
   * then the staff would be prompted with a modal after the complete sale button is selected where the print button would print the receipt , Ignore button wouldn't print the receipt
   */
  const onCompleteSale = useCallback(async () => {
    if (isCustomerBalancePayment) {
      navigation.navigate('Customers');
      return;
    }

    if (postSaleScreen && AppScreen[postSaleScreen] != AppScreen.NEW_ORDER) {
      const newOrderId = await resetCart();
      setCartParams(newOrderId, defaultOrderType?.id, '', false);
      takeOrderUnFocusClearParamController.next();
    }

    if (receiptPrintOption === ReceiptPrintOption.AUTO) {
      // print bill receipt with delay silently
      setTimeout(() => onPressPrintReceipt(), 1000);
    }

    navigateToPostSaleScreen();
  }, [
    isCustomerBalancePayment,
    postSaleScreen,
    receiptPrintOption,
    navigateToPostSaleScreen,
    navigation,
    resetCart,
    setCartParams,
    defaultOrderType?.id,
    onPressPrintReceipt,
  ]);

  const onAssignCustomerToOrder = useCallback(
    customer => {
      updateCart<AssignCustomerEvent>(OrderAction.ORDER_ASSIGN_CUSTOMER, {
        customerId: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        loyaltyMember: customer.loyaltyMember,
        customerAccountDetails: isEmpty(customer?.customerAccountDetails)
          ? {}
          : {
              accountPayment: customer?.customerAccountDetails?.accountPayment,
              currentBalance: customer?.customerAccountDetails?.currentBalance,
              maxBalanceLimit:
                customer?.customerAccountDetails?.maxBalanceLimit,
              maxOrderLimit: customer?.customerAccountDetails?.maxOrderLimit,
            },
        isLoyaltyApplied: isLoyaltyEnabled && customer.loyaltyMember,
      });
    },
    [isLoyaltyEnabled, updateCart],
  );

  const recentEarnedPoints = useMemo(
    () => calculatePointOfEarnByAmount(earningRules, order?.totalPrice || 0),
    [earningRules, order?.totalPrice],
  );

  const earnedPointInfo = useMemo(() => {
    if (!isLoyaltyEnabled || !assignedCustomer?.loyaltyMember) return;
    return translate('customerLoyalty.pointsEarned', {
      points: recentEarnedPoints,
      unit: getLoyaltyUnit(recentEarnedPoints, loyaltySettings),
    });
  }, [
    assignedCustomer?.loyaltyMember,
    isLoyaltyEnabled,
    loyaltySettings,
    recentEarnedPoints,
    translate,
  ]);

  const availableRewards = useMemo(() => {
    const totalPoints =
      recentEarnedPoints + (assignedCustomer?.loyaltyPoints || 0);
    return rewardRules.filter(reward => reward.pointsRequired <= totalPoints);
  }, [assignedCustomer?.loyaltyPoints, recentEarnedPoints, rewardRules]);

  const onViewRewards = useCallback(() => {
    setShowCompleteSaleModal(false);
    setShowRewardModal(true);
  }, []);

  const onEnrollToLoyalty = useCallback(() => {
    if (assignedCustomer) {
      enrollCustomerLoyalty({
        customerId: assignedCustomer.id,
        latestOrderId: order?.id,
      });
      return;
    }
    if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
      setShowCompleteSaleModal(false);
    }
    setShowEnrollLoyaltyModal(true);
  }, [assignedCustomer, enrollCustomerLoyalty, order?.id, receiptPrintOption]);

  const onUnassignCustomerToOrder = useCallback(() => {
    updateCart<OrderEvent>(OrderAction.ORDER_UNASSIGN_CUSTOMER);
  }, [updateCart]);

  const loyaltyAction = useMemo(() => {
    if (!isLoyaltyEnabled || hideLoyaltyEnrollButton.current) return null;
    return (
      <Button
        loading={customerLoading}
        testID={'loyalty-action'}
        fluid
        containerStyle={css(loyaltyButtonStyle)}
        labelStyle={css(loyaltyLabelStyle)}
        title={
          assignedCustomer?.loyaltyMember
            ? translate('customerLoyalty.viewRewards', {
                numberOfReward: availableRewards.length,
              })
            : translate('customerLoyalty.enrollToLoyalty')
        }
        onPress={
          assignedCustomer?.loyaltyMember ? onViewRewards : onEnrollToLoyalty
        }
      />
    );
  }, [
    assignedCustomer,
    availableRewards?.length,
    css,
    customerLoading,
    isLoyaltyEnabled,
    onEnrollToLoyalty,
    onViewRewards,
    translate,
  ]);

  const onPaymentComplete = useCallback(
    (roundedOffChange: number) => {
      if (enableQuickPaymentMode) {
        changeDueVar(roundedOffChange);
      }

      if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
        setShowCompleteSaleModal(true);
      } else if (enableQuickPaymentMode) {
        onCompleteSale();
      }
    },
    [enableQuickPaymentMode, onCompleteSale, receiptPrintOption],
  );

  /**
   * Add transaction and publish payment event
   */
  const onPressPay = useCallback(
    (
      paymentType: PaymentType,
      amount: number,
      surchargeAmount = 0,
      customer?: Customer,
      additionalInfo?: AdditionalPaymentInfo,
    ) => {
      let validReceivedAmount = amount || 0;
      if (
        paymentType.name.toLowerCase() == CASH_PAYMENT_TYPE &&
        enableRoundOff
      ) {
        validReceivedAmount = roundOffByValue(amount, roundOffValue);
      }

      const change = parseFloat(
        (validReceivedAmount - processingAmount - surchargeAmount).toFixed(2),
      );

      const roundedOffChange = enableRoundOff
        ? roundOffByValue(change, roundOffValue)
        : change;

      if (isCustomerBalancePayment) {
        const {
          maxBalanceLimit,
          maxOrderLimit,
          currentBalance = 0,
          accountPayment = true,
        } = assignedCustomer?.customerAccountDetails || {};

        const updatedCustomerAccountBalance = {
          id: customerId,
          customerAccountDetails: {
            currentBalance: parseFloat(
              (currentBalance - processingAmount).toFixed(2),
            ),
            maxOrderLimit,
            maxBalanceLimit,
            accountPayment,
          },
        };
        updateCustomerOnAccount(updatedCustomerAccountBalance);
      } else {
        updateCart<OrderPaymentEvent>(OrderAction.ORDER_PAYMENT, {
          tendered: validReceivedAmount,
          paymentTypeId: paymentType.id,
          tip: 0,
          change: roundedOffChange,
          roundOffDifference: change - roundedOffChange,
          onAccount: paymentType.name === ON_ACCOUNT_PAYMENT_TYPE,
          customerId: order?.customer?.id,
          paymentTypeName: paymentType.name,
          ...(additionalInfo &&
            additionalInfo.paymentRequestId && {
              paymentRequestId: additionalInfo.paymentRequestId,
            }),
          ...(additionalInfo &&
            additionalInfo.paymentStatus && {
              paymentStatus: additionalInfo.paymentStatus,
            }),
        });
        // Open cash drawer
        if (
          ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER.includes(
            paymentType.name?.toLowerCase(),
          )
        ) {
          openCashDrawer();
        }
        updateCart(OrderAction.ORDER_SAVE);

        const isPaymentCompleted =
          remainingDueAmount === processingAmount &&
          additionalInfo?.paymentStatus !== OrderPaymentStatus.PENDING;
        if (isPaymentCompleted) {
          onPaymentComplete(roundedOffChange);
        }
      }

      if (additionalInfo?.paymentStatus !== OrderPaymentStatus.PENDING) {
        dispatch({
          type: PaymentAction.PAY_OUT,
          payload: {
            lastReceivedAmount: validReceivedAmount,
            lastChangeDueAmount: roundedOffChange,
          },
        });
      }
    },
    [
      enableRoundOff,
      processingAmount,
      roundOffValue,
      isCustomerBalancePayment,
      assignedCustomer?.customerAccountDetails,
      customerId,
      updateCustomerOnAccount,
      updateCart,
      order?.customer?.id,
      remainingDueAmount,
      onPaymentComplete,
      openCashDrawer,
    ],
  );
  const onPaymentProcessed = useCallback(
    (additionalInfo: AdditionalPaymentInfo) => {
      const pendingPayment = order?.payments.find(
        payment => payment.paymentRequestId === additionalInfo.paymentRequestId,
      );

      let paymentAmount: number = pendingPayment?.amount || 0;

      if (additionalInfo.paymentSurcharge)
        paymentAmount += additionalInfo.paymentSurcharge;

      const roundedOffChange = 0;

      updateCart<OrderPaymentProcessedEvent>(
        OrderAction.ORDER_PAYMENT_PROCESSED,
        {
          paymentStatus: additionalInfo.paymentStatus as OrderPaymentStatus,
          paymentRequestId: additionalInfo.paymentRequestId as string,
          ...(additionalInfo &&
            additionalInfo.paymentTransactionRef && {
              paymentTransactionRef: additionalInfo.paymentTransactionRef,
            }),
          ...(additionalInfo &&
            additionalInfo.paymentCompletedAt && {
              paymentCompletedAt: additionalInfo.paymentCompletedAt,
            }),
          ...(additionalInfo &&
            additionalInfo.paymentReceipt && {
              paymentReceipt: additionalInfo.paymentReceipt,
            }),
          ...(additionalInfo &&
            additionalInfo.paymentSurcharge && {
              paymentSurcharge: additionalInfo.paymentSurcharge,
            }),
        },
      );

      updateCart(OrderAction.ORDER_SAVE);

      const isPaymentCompleted =
        remainingDueAmount === processingAmount &&
        additionalInfo?.paymentStatus === OrderPaymentStatus.COMPLETE;

      if (isPaymentCompleted) {
        onPaymentComplete(roundedOffChange);
      }

      if (additionalInfo?.paymentStatus === OrderPaymentStatus.COMPLETE) {
        dispatch({
          type: PaymentAction.PAY_OUT,
          payload: {
            lastReceivedAmount: paymentAmount,
            // Change will be 0 for terminal payments.
            lastChangeDueAmount: 0,
          },
        });
      }
    },
    [
      onPaymentComplete,
      order?.payments,
      processingAmount,
      remainingDueAmount,
      updateCart,
    ],
  );

  const onTakeNextPayment = useCallback(() => {
    dispatch({ type: PaymentAction.TAKE_NEXT_PAYMENT, payload: {} });
  }, []);

  const onCloseSubPaymentScreen = useCallback(() => {
    dispatch({ type: PaymentAction.CANCEL_CURRENT_STEP, payload: {} });
  }, []);

  const showPaymentSuccessScreen = useMemo(() => {
    if (activeScreen !== PaymentSubScreen.PAYMENT_COMPLETE) return false;
    if (
      !isCustomerBalancePayment &&
      isPaymentCompleted &&
      (receiptPrintOption === ReceiptPrintOption.PROMPT ||
        enableQuickPaymentMode)
    )
      return false;
    return true;
  }, [
    activeScreen,
    enableQuickPaymentMode,
    isCustomerBalancePayment,
    isPaymentCompleted,
    receiptPrintOption,
  ]);

  const latestPayment = useMemo(
    () => lastItem(order?.payments),
    [order?.payments],
  );

  const onDismissRewardModal = useCallback(() => {
    setShowRewardModal(false);
    if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
      setShowCompleteSaleModal(true);
    }
  }, [receiptPrintOption]);

  const onCloseEnrollLoyaltyModal = useCallback(() => {
    setShowEnrollLoyaltyModal(false);
    if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
      setShowCompleteSaleModal(true);
    }
  }, [receiptPrintOption]);

  const onEnrollToLoyaltyByPhone = useCallback(
    async (customerInfo: EnrollCustomerInput) => {
      await enrollCustomerLoyalty({
        ...customerInfo,
        latestOrderId: order?.id,
      });
      hideLoyaltyEnrollButton.current = true;
      onCloseEnrollLoyaltyModal();
    },
    [enrollCustomerLoyalty, onCloseEnrollLoyaltyModal, order?.id],
  );

  return (
    <>
      <Layout
        hasHeader
        title={translate('navigation.paymentPageTitle', {
          appName: translate('appName'),
        })}
        headerLeft={<LeftArrow />}
        headerTitle={
          <TitleBar
            primary
            title={
              isCustomerBalancePayment
                ? translate('payment.balancePayment')
                : `${translate('payment.order')} #${orderNumber}`
            }
            containerStyle={css(titleContainerStyle)}
            testID="order-number-title"
          />
        }
        testID="payment-screen-page"
      >
        {loading ? (
          <Loading />
        ) : (
          <View style={css(body)}>
            <View style={css(cartStyle)}>
              <PaymentOptionHeader
                customerName={`${assignedCustomer?.firstName || ''} ${
                  assignedCustomer?.lastName || ''
                } `}
                orderId={order?.id || ''}
                assignCustomerToOrder={onAssignCustomerToOrder}
                assignedCustomerId={assignedCustomer?.id as string}
                onUnassignCustomerToOrder={onUnassignCustomerToOrder}
              />
              {!isCustomerBalancePayment ? (
                <CartHeader
                  orderNumber={orderNumber || ''}
                  orderType={order?.orderType?.name}
                  tableNumber={order?.table?.name || 'T'}
                  sectionName={order?.table?.section?.name}
                />
              ) : null}
              <CartItems
                items={order?.orderItems}
                containerStyle={css(cartItemsStyle)}
                courses={courses}
                isCoursesEnabled={isCoursesEnabled}
                readonlyCourse
              />
              {!isCustomerBalancePayment ? (
                <CartSummary
                  taxes={order?.taxes}
                  discounts={order?.discounts || []}
                  subTotal={order?.subTotal || 0}
                  totalDue={order?.totalPrice || 0}
                  discountAmount={order?.discountAmount || 0}
                  adjustments={order?.adjustments || []}
                  surchargeAmount={order?.surchargeAmount || 0}
                  paymentSummary={paymentSummary}
                  isCalledFromPaymentScreen
                />
              ) : (
                <CartSummary
                  subTotal={customerAmount || 0}
                  discountAmount={0}
                  totalDue={customerAmount || 0}
                />
              )}
              <View style={css(paymentInfoContainerStyle)}>
                <View style={css(textContainerStyle)}>
                  <Text
                    testID="totalRemaining"
                    style={css(totalOutstandingAmountStyle)}
                  >
                    {translate('payment.totalOutstanding')}
                  </Text>
                  <Text
                    testID="totalRemainingAmt"
                    style={css(totalRemainingAmtStyle)}
                  >
                    {formatCurrency(Math.abs(remainingDueAmount) || 0)}
                  </Text>
                </View>
              </View>
              {assignedCustomer?.customerAccountDetails?.accountPayment ? (
                <View style={css(paymentInfoContainerStyle)}>
                  <View style={css(textContainerStyle)}>
                    <Text
                      testID="balanceLimitTitle"
                      style={css(LightLabelStyle)}
                    >
                      {translate('payment.balanceLimit')}
                    </Text>
                    <Text testID="maxBalanceLimit" style={css(LightLabelStyle)}>
                      {formatCurrency(
                        Math.abs(
                          Number(
                            assignedCustomer?.customerAccountDetails
                              ?.maxBalanceLimit,
                          ),
                        ) || 0,
                      )}
                    </Text>
                  </View>

                  <View style={css(textContainerStyle)}>
                    <Text testID="totalRemaining" style={css(LightLabelStyle)}>
                      {translate('payment.orderLimit')}
                    </Text>
                    <Text testID="maxOrderLimit" style={css(LightLabelStyle)}>
                      {formatCurrency(
                        Math.abs(
                          Number(
                            assignedCustomer?.customerAccountDetails
                              ?.maxOrderLimit,
                          ),
                        ) || 0,
                      )}
                    </Text>
                  </View>
                  <View style={css(textContainerStyle)}>
                    <Text
                      testID="totalRemaining"
                      style={css(totalRemainingStyle)}
                    >
                      {translate('payment.currentBalance')}
                    </Text>
                    <Text
                      testID="currentBalance"
                      style={css(currentBalanceAmtStyle)}
                    >
                      {formatCurrency(
                        Math.abs(
                          Number(
                            assignedCustomer?.customerAccountDetails
                              ?.currentBalance,
                          ),
                        ) || 0,
                      )}
                    </Text>
                  </View>
                </View>
              ) : null}
            </View>
            <View style={css(paymentContainerStyle)}>
              {activeScreen !== PaymentSubScreen.MAIN &&
                !isPaymentCompleted && (
                  <PaymentHeadingInfo
                    nthPayment={nthPayment}
                    onClose={onCloseSubPaymentScreen}
                  />
                )}
              <View style={css(paymentOptionContainerStyle)}>
                {showPaymentSuccessScreen ? (
                  <PaymentSuccessScreen
                    onCompleteSale={onCompleteSale}
                    onTakeNextPayment={onTakeNextPayment}
                    onPressPrintReceipt={onPressPrintReceipt}
                    showPrintReceiptButton={showPrintReceiptButton}
                    orderPaymentDetails={orderPaymentDetails}
                    changeDueAmount={lastChangeDueAmount}
                    receivedAmount={lastReceivedAmount}
                    onPrintSplit={onPrintSplit}
                    loyaltyAction={loyaltyAction}
                    earnedPointInfo={earnedPointInfo}
                  />
                ) : (
                  <PaymentOptions
                    enableRoundOff={enableRoundOff}
                    roundOffValue={roundOffValue}
                    onPressPay={onPressPay}
                    onPaymentProcessed={onPaymentProcessed}
                    dispatch={dispatch}
                    orderPaymentDetails={orderPaymentDetails}
                  />
                )}
              </View>
            </View>
          </View>
        )}
      </Layout>
      <ModalWrapper isVisible={isShowCompleteSaleModal}>
        <CompleteSaleModal
          sendReceipt={sendOrderReceiptToEmail}
          onPressPrintReceipt={onPressPrintReceipt}
          onPressNewSale={onCompleteSale}
          changeDue={
            (latestPayment?.tendered || 0) - (latestPayment?.amount || 0)
          }
          isSplitPayment={isSplitPayment}
          onPrintSplit={onPrintSplit}
          customer={order?.customer}
          isOnAccount={
            latestPayment?.paymentType?.name === ON_ACCOUNT_PAYMENT_TYPE
          }
          amount={order?.subTotal}
          loyaltyAction={loyaltyAction}
          earnedPointInfo={earnedPointInfo}
        />
      </ModalWrapper>
      <ModalWrapper isVisible={isShowRewardModal}>
        <ShowAvailableRewards
          totalPoints={
            recentEarnedPoints + (assignedCustomer?.loyaltyPoints || 0)
          }
          pointEarned={recentEarnedPoints}
          availableRewards={availableRewards}
          loyaltySettings={loyaltySettings}
          onDismiss={onDismissRewardModal}
        />
      </ModalWrapper>
      <ModalWrapper isVisible={isShowEnrollLoyaltyModal}>
        <EnrollCustomerModal
          onCloseModal={onCloseEnrollLoyaltyModal}
          onEnrollLoyalty={onEnrollToLoyaltyByPhone}
          loading={customerLoading}
        />
      </ModalWrapper>
    </>
  );
};

export default PaymentScreen;
