import React, { useCallback } from 'react';
import { Icons, StyleFn } from '@hitz-group/domain';
import { TouchableOpacity } from 'react-native';
import Icon from '../../components/Icon/Icon';
import { useIntercom } from '../../hooks/Intercom/useIntercom';
import useBehaviorSubjectState from '../../hooks/app/useSubjectState';
import { closeButtonVisibilityController } from '../../hooks/Intercom/intercomObservables';
import { useFela } from 'react-fela';

export interface IntercomProviderProps {
  appId: string;
  children: React.ReactNode;
}

const closeButtonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger,
  height: 20,
  width: 20,
  borderRadius: 10,
  position: 'absolute',
  bottom: 85,
  right: 15,
  alignItems: 'center',
  justifyContent: 'center',
});

const CloseButton: React.FC = () => {
  const Intercom = useIntercom();
  const { css } = useFela();
  const { value: isVisible } = useBehaviorSubjectState(
    closeButtonVisibilityController,
  );

  const onPressClose = useCallback(() => {
    Intercom.stop();
  }, [Intercom]);

  if (!isVisible) return null;

  return (
    <>
      <TouchableOpacity style={css(closeButtonStyle)} onPress={onPressClose}>
        <Icon name={Icons.Times} />
      </TouchableOpacity>
    </>
  );
};

export default CloseButton;
