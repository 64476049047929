import { gql } from '@apollo/client';

export const GET_REFUND_REASONS_QUERY = gql`
  query {
    refundReasons {
      id
      name
      restock
    }
  }
`;

export const CREATE_REFUND_REASONS = gql`
  mutation createRefundReasons($input: [CreateRefundReasonInput!]) {
    createRefundReasons(input: $input) {
      id
      name
      restock
    }
  }
`;

export const UPDATE_REFUND_REASONS = gql`
  mutation updateRefundReasons($input: [UpdateRefundReasonInput!]) {
    updateRefundReasons(input: $input) {
      id
      name
      restock
    }
  }
`;

export const DELETE_REFUND_REASON = gql`
  mutation deleteRefundReason($id: ID!) {
    deleteRefundReason(id: $id)
  }
`;
