import { useLazyQuery } from '@apollo/client/react/hooks';
import { useCallback } from 'react';
import { GET_EVENTS_BY_STORE_QUERY } from '../../../graphql/events';

export const useListEventsByTime = () => {
  const [getListEventsRequest, listEventsResponse] = useLazyQuery(
    GET_EVENTS_BY_STORE_QUERY,
    {
      returnPartialData: true,
    },
  );

  const getListEventsByTime = useCallback(
    (timestamp: number) => {
      getListEventsRequest({
        variables: {
          filter: {
            timestamp: timestamp,
          },
        },
      });
    },
    [getListEventsRequest],
  );

  return {
    getListEventsByTime,
    listEventsResponse,
  };
};
