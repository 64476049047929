import { RenderProps, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { View, Text } from 'react-native';
import Error from '../Error/Error';
import InputDisplay from '../InputDisplay/InputDisplay';
import NumberInput, { KeyButton } from '../NumberInput/NumberInput';
import PopupView from '../PopupView/PopupView';
import { useDimensions } from '@react-native-community/hooks';
import { isWeb, isIos, isAndroid } from '../../common/theme';
import ClearButton from '../ClearButton/ClearButton';
export interface KeypadProps {
  maxLength?: number;
  error?: string;
  onSubmit: (pin: string) => void;
  onReset?: () => void;
  secureText?: boolean;
  keys?: KeyButton[];
  footer?: React.ReactNode;
  headerText?: string;
  title?: React.ReactNode;
}

const containerStyle: StyleFn = ({ width, height }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  width,
  height,
});

const errorMessageStyle: StyleFn = () => ({
  position: 'absolute',
  left: isIos || isAndroid ? '110%' : '100%',
  top: isIos || isAndroid ? 80 : 63,
});

const numberInputStyle: StyleFn = () => ({
  width: '100%',
  flexGrow: 1,
  marginTop: isAndroid ? 20 : 15,
});

const popupStyle: StyleFn = ({ theme, height }) => ({
  height: height * (isWeb ? 0.86 : isAndroid ? 0.85 : 0.9),
  width: height * (isAndroid ? 0.65 : 0.62),
  paddingVertical: theme.padding.large,
  paddingHorizontal: theme.padding.large,
});

const headerTextDefaultStyle: StyleFn = ({ theme }) => ({
  marginTop: '-5.4%',
  marginBottom: '4%',
  justifyContent: 'center',
  lineHeight: 21,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.bold,
  flexDirection: 'row',
});

const btnTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.larger,
});

const Keypad: React.FC<KeypadProps> = ({
  error,
  onSubmit,
  maxLength,
  onReset,
  secureText,
  keys,
  footer,
  headerText,
  title,
}: KeypadProps) => {
  const { css, theme } = useFela();
  const [value, setValue] = useState('');

  const onPressKey = useCallback(
    (key: number): void => {
      setValue((value + key).substr(0, maxLength));
    },
    [value, setValue, maxLength],
  );
  const onSubmitPin = useCallback(() => {
    onSubmit(value);
  }, [value, onSubmit]);

  const onPressClear = useCallback((): void => {
    onReset && onReset();
    const data = value.slice(0, -1);
    setValue(data);
  }, [onReset, value]);

  const height = useDimensions().window.height * (headerText ? 0.78 : 0.6);
  const windowHeight = useDimensions().window.height;

  const reset = () => <ClearButton onPress={onPressClear} />;

  return (
    <FelaComponent
      style={containerStyle}
      width={height * (isAndroid ? 0.65 : 0.62)}
    >
      {({ style }: RenderProps): React.ReactFragment => (
        <View style={style}>
          {title}
          <PopupView containerStyle={css(popupStyle({ theme, height }))}>
            <Text style={css(headerTextDefaultStyle)}>{headerText}</Text>
            <InputDisplay
              testID="passcode"
              secure={secureText ? true : false}
              value={value}
              right={reset()}
            />
            <NumberInput
              testID="keypad"
              onPressKey={onPressKey}
              onSubmit={onSubmitPin}
              containerStyle={css(numberInputStyle)}
              btnTextStyle={css(btnTextStyle)}
              onPressClear={onPressClear}
              keys={keys}
              keyWidth={windowHeight * (isAndroid ? 0.109 : 0.103)}
              keyHeight={windowHeight * 0.09 * (isAndroid ? 0.8 : 0.9)}
              twistKeyWidth={isWeb ? 2.1 : isIos ? 2.07 : 2.15}
              pin={value}
            />
            {footer}
            {error ? (
              <Error style={css(errorMessageStyle)} text={error} />
            ) : undefined}
          </PopupView>
        </View>
      )}
    </FelaComponent>
  );
};

Keypad.defaultProps = {
  maxLength: 6,
};

export default Keypad;
