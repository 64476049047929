import { useReporting } from '../../../../hooks/app/useReporting';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ShiftReport from './ShiftReport';
import { useNotification } from '../../../../hooks/Notification';
import { ReportingContext } from '../ReportingContext';
import {
  FilterValue,
  Shift,
  DateRangeFilterPresets,
  DateRangeFilterType,
  Filters,
  ReportFilterDropdown,
} from '@hitz-group/domain';
import { View } from 'react-native';
import { ContainerStyles } from '../styles/Component.styles';
import {
  buildFilterObject,
  generateFilterData,
  SHIFT_REPORT_FILTERS,
} from '../reportsHelper';
import {
  DateRangeFilterInput,
  CustomReports,
  Search,
  ShiftQueryInput,
  DropDownFilter,
} from '../types';
import * as storage from '../../../../storage/interface';

export const ShiftContainer: React.FC = () => {
  const { showNotification } = useNotification();
  const { cubejsApi, filters: allFilters } = useContext(ReportingContext);

  const [filters, setFilters] = useState<FilterValue>({});
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [search, setSearch] = useState<Search>({
    type: 'productName',
    value: '',
  });

  const [dateRangeFilters, setDateRangeFilters] =
    useState<DateRangeFilterInput>({
      key: 'date',
      text: '',
      type: DateRangeFilterType.FILTER_BY_PRESET,
      value: DateRangeFilterPresets.TODAY,
    } as DateRangeFilterInput);

  const styles = ContainerStyles();

  const { shiftReportData, loading, error, getShiftData } =
    useReporting(cubejsApi);

  const [didLoad, setDidLoad] = useState<boolean>(false);

  const ddFilters = useMemo(() => {
    if (allFilters) {
      return SHIFT_REPORT_FILTERS.map((filter: ReportFilterDropdown) => {
        return {
          ...filter,
          values: allFilters[filter.key as string as keyof Filters],
        } as DropDownFilter;
      });
    }
  }, [allFilters]);

  useEffect(() => {
    const getReportData = async () => {
      const cached: ShiftQueryInput | undefined = await storage.getItem(
        CustomReports.SHIFT,
      );

      const input: ShiftQueryInput = cached || {
        filter: filters,
        dateRange: dateRangeFilters,
      };

      getShiftData &&
        getShiftData({
          variables: {
            input: {
              filter: generateFilterData(
                input?.filter as FilterValue,
                input?.dateRange as DateRangeFilterInput,
              ),
              retrieveAll: true,
            },
          },
        });
    };

    if (!didLoad) {
      getReportData();
      setDidLoad(true);
    }
  }, [didLoad, getShiftData, filters, dateRangeFilters]);

  useEffect(() => {
    shiftReportData &&
      shiftReportData.query &&
      setFilters(shiftReportData.query.filter as FilterValue);
    shiftReportData &&
      shiftReportData.query &&
      setDateRangeFilters(shiftReportData.query.dateRange);

    shiftReportData &&
      shiftReportData.shifts &&
      setShifts(shiftReportData.shifts);
  }, [shiftReportData]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const updateFilters = useCallback((filter: string, value: string[]) => {
    setFilters((filters: FilterValue) => {
      return {
        ...filters,
        [filter]: value,
      };
    });
  }, []);

  const updateDateRangeFilters = useCallback((value: DateRangeFilterInput) => {
    const toSet = buildFilterObject(value);
    setDateRangeFilters(toSet);
  }, []);

  const updateSearch = useCallback((prop: string, value) => {
    setSearch(search => {
      return {
        ...search,
        [prop]: value,
      } as Search;
    });
  }, []);

  useEffect(() => {
    if (
      shiftReportData?.shifts instanceof Array &&
      shiftReportData?.shifts.length > 0
    ) {
      if (search.value.trim() !== '') {
        setShifts(
          shiftReportData?.shifts.filter(summary =>
            summary.shiftNumber.includes(search.value),
          ) as Shift[],
        );
      } else {
        setShifts(shiftReportData?.shifts as Shift[]);
      }
    }
  }, [search, shiftReportData?.shifts]);

  const resetFilters = useCallback(() => {
    setFilters({});
  }, []);

  const updateReport = useCallback(() => {
    delete dateRangeFilters?.startDate;
    delete dateRangeFilters?.endDate;
    delete dateRangeFilters?.startTime;
    delete dateRangeFilters?.endTime;

    storage.setItem(CustomReports.SHIFT, {
      dateRange: dateRangeFilters,
      filter: filters,
    });

    getShiftData &&
      getShiftData({
        variables: {
          input: {
            filter: generateFilterData(
              filters as FilterValue,
              dateRangeFilters as DateRangeFilterInput,
            ),
            retrieveAll: true,
          },
        },
      });
  }, [filters, dateRangeFilters, getShiftData]);

  const containerLoading = loading || !allFilters;

  return (
    <View style={styles.pageStyle}>
      <ShiftReport
        shifts={shifts}
        filters={filters}
        search={search}
        filterOptions={ddFilters || ([] as DropDownFilter[])}
        allFilters={allFilters || ({} as Filters)}
        updateFilters={updateFilters}
        updateSearch={updateSearch}
        resetFilters={resetFilters}
        dateRangeFilter={dateRangeFilters || ({} as DateRangeFilterInput)}
        updateDateRangeFilters={updateDateRangeFilters}
        options={{ loading: containerLoading }}
        updateReport={updateReport}
      />
    </View>
  );
};

export default ShiftContainer;
