import React, { useContext, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ProductModifiersSection } from './ProductModifiers';
import { ProductContext } from '../ProductSettingsStack';
export const Modifiers: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const productContext = useContext(ProductContext);
  const params =
    productContext.params ||
    (route.params as {
      productId: string;
      isVariant: boolean;
    });

  useEffect(() => {
    navigation.setParams(params);
  }, [params, navigation]);

  const productId = !params?.isVariant ? params?.productId : '';

  return (
    (productId && <ProductModifiersSection productId={productId} />) || null
  );
};
