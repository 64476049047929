import { useTranslation } from '@hitz-group/localization';
import React, { useCallback, useState } from 'react';
import { View, KeyboardAvoidingView, Platform } from 'react-native';
import FormInput from '../../FormInput/FormInput';
import { useFela } from 'react-fela';
import { Order, StyleFn } from '@hitz-group/domain';
import { isValidEmail } from '../../../utils/validator';
import { useNotification } from '../../../hooks/Notification';
import IconButton from '../../Button/IconButton';
import TitleBar from '../../TitleBar/TitleBar';
import { useModal } from '@hitz-group/rn-use-modal';
import { isWeb } from '../../../common/theme';

export interface OrderReceiptActionModalProps {
  order: Order;
  changeDue?: number;
  sendReceipt?: (order: Order, email: string) => void;
  customer?: {
    email?: string;
    phone?: string;
    id?: string;
  };
}

export interface ReceiptPrintFormProps {
  email: string;
  phone: string;
}

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 15,
});

const actionButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  right: 0,
  paddingHorizontal: theme.padding.medium,
  height: 44,
});

const phoneFormStyle: StyleFn = ({ theme }) => ({
  width: 350,
  height: 44,
  marginBottom: theme.spacing.medium + 1,
});

const emailFormStyle: StyleFn = () => ({
  width: 350,
  height: 44,
});

const keyboardAvoidingViewStyle: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
});

const modalStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  elevation: 5,
  borderColor: theme.colors.boxBorder,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  backgroundColor: theme.colors.white,
  width: 400,
  alignSelf: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

const containerStyle: StyleFn = ({ theme }) => ({
  width: 400,
  alignSelf: 'center',
  borderRadius: theme.radius.small,
  marginTop: -theme.spacing.small,
});

const inputContainerStyle: StyleFn = () => ({
  marginVertical: isWeb ? 30 : 10,
  alignItems: 'center',
});

const OrderReceiptActionModal: React.FC<OrderReceiptActionModalProps> = ({
  order,
  sendReceipt,
  customer = {},
}) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();

  const { css, theme } = useFela();
  const [formData, setFormData] = useState<ReceiptPrintFormProps>({
    email: customer?.email || '',
    phone: customer?.phone || '',
  });
  const { showNotification } = useNotification();
  const _onChangeProp = (prop: string, text: string): void => {
    setFormData({ ...formData, [prop]: text });
  };

  const titleLeft = (
    <IconButton
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={closeModal}
    />
  );

  const _sendEmailReceipt = useCallback(() => {
    if (formData.email && sendReceipt) {
      if (isValidEmail(formData.email)) {
        closeModal();
        sendReceipt(order, formData.email);
      } else {
        showNotification({
          error: true,
          message: 'Invalid email',
        });
      }
    } else {
      showNotification({
        error: true,
        message: 'Provide email address',
      });
    }
  }, [sendReceipt, formData, closeModal, showNotification, order]);
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={css(keyboardAvoidingViewStyle)}
    >
      <View style={css(modalStyle)}>
        <View style={css(containerStyle)}>
          <TitleBar titleLeft={titleLeft} primary title={'Send Receipt'} />
          <View style={css(inputContainerStyle)}>
            <FormInput
              placeholder={translate('payment.phonePlaceholder')}
              placeHolderIcon={'phone-volume'}
              containerStyle={css(phoneFormStyle)}
              value={formData.phone}
              onChangeText={text => _onChangeProp('phone', text)}
              action={
                <IconButton
                  icon="arrow-right"
                  iconColor={theme.colors.paragraph}
                  containerStyle={css(actionButtonStyle)}
                />
              }
            />
            <FormInput
              placeholder={translate('common.emailPlaceholder')}
              placeHolderIcon={'envelope-alt'}
              containerStyle={css(emailFormStyle)}
              value={formData.email}
              onChangeText={text => _onChangeProp('email', text)}
              action={
                <IconButton
                  testID="SendEmailReceipt"
                  icon="arrow-right"
                  iconColor={theme.colors.paragraph}
                  containerStyle={css(actionButtonStyle)}
                  onPress={_sendEmailReceipt}
                />
              }
            />
          </View>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default OrderReceiptActionModal;
