import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Howl } from 'howler';
import { useTimeout } from '../useTimeout';

export function useAudioNotification() {
  const audio = useRef(
    new Howl({
      src: ['/notification.mp3'],
      loop: true,
    }),
  ).current;
  const stopAfterDelay = useTimeout(useCallback(() => audio.stop(), [audio]));

  const handler = useMemo(() => {
    return {
      play() {
        if (audio.state() !== 'loaded') return;
        handler.stop();
        audio.play();
      },

      stop() {
        if (audio.state() !== 'loaded') return;
        audio.stop();
      },

      playWithTime(time: number) {
        handler.play();
        stopAfterDelay.start(time);
      },

      stopPlayWithTime() {
        handler.stop();
        stopAfterDelay.stop();
      },
    };
  }, [audio, stopAfterDelay]);

  useEffect(() => {
    return () => {
      handler.stop();
    };
  }, [handler]);

  return handler;
}
