import {
  useLazyQuery,
  useMutation,
  useApolloClient,
} from '@apollo/client/react/hooks';
import { useMemo, useCallback, useEffect, useState } from 'react';
import {
  GET_PRODUCT_QUERY,
  GET_PRODUCTS_QUERY,
  DELETE_PRODUCT_MUTATION,
  DELETE_PRODUCTS_MUTATION,
  getProductsQuery,
  getProductQuery,
  getProductQueryWithFilter,
  updateProductMutation,
  updateProductsMutation,
  createProductMutation,
  PRODUCT_LIST_SCREEN_FRAGMENT,
  UPLOAD_PRODUCT_IMAGE_MUTATION,
  UPDATE_PRODUCTS_AVAILABILITY_EVENT,
  copyProductMutation,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import {
  CreateProductInput,
  UpdateProductsAvailabilityInput,
  CopyProductInput,
  UpdateProductInput,
  Product,
  ImageUploadInput,
} from '@hitz-group/domain';
import { Operation } from '../../../types/Operation';
import { ApolloError } from '@apollo/client';
import { keyBy } from 'lodash';

export interface useProductsProps {
  products: { [key: string]: Product };
  updateProduct: (productDetails: UpdateProductInput) => void;
  refetchAllProduct: () => void;
  refetchProduct: () => void;
  updateProducts: (productsDetails: UpdateProductInput[]) => void;
  deleteProduct: (productId: string) => void;
  deleteProducts: (productIds: string[]) => void;
  getAllProducts: () => void;
  copyProduct: (productInput: CopyProductInput) => void;
  createProduct: (productCreate: CreateProductInput) => void;
  getProductsByName: (name: string) => void;
  createdProductId: string;
  deletedProductIds: string[];
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  getProductById: (productId: string) => void;
  updateProductsInCache: (productsMap: Record<string, Product>) => void;
  uploadProductImage: (input: ImageUploadInput, productId: string) => void;
  getProductsFromCache: () => Product[];
  updateProductsAvailabilityEvent: (
    event: UpdateProductsAvailabilityInput,
  ) => void;
}

export function useProducts(
  productId?: string,
  customFragment?: string,
): useProductsProps {
  const [products, setProducts] = useState<Record<string, Product>>({});

  const [operation, setOperation] = useState<Operation>(Operation.READ);

  const [createdProductId, setCreatedProductId] = useState('');

  const [deletedProductIds, setDeletedProductIds] = useState<string[]>([]);
  const client = useApolloClient();

  const [syncProductAvailabilityEvents, syncUpdateProductResponse] =
    useMutation(UPDATE_PRODUCTS_AVAILABILITY_EVENT, {
      onError: noopHandler,
    });

  const [getProduct, productGetRequest] = useLazyQuery(
    getProductQuery(customFragment),
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getProducts, getProductsRequest] = useLazyQuery(
    getProductsQuery(customFragment),
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      nextFetchPolicy: 'cache-first',
    },
  );

  const [getProductFilter, getProductFilterRequest] = useLazyQuery(
    getProductQueryWithFilter(customFragment),
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [updateProduct, updateRequest] = useMutation(
    updateProductMutation(customFragment),
    {
      onError: noopHandler,
    },
  );

  const [updateProducts, updateProductsRequest] = useMutation(
    updateProductsMutation(customFragment),
    {
      onError: noopHandler,
    },
  );

  const updateProductsAvailabilityEvent = useCallback(
    (event: UpdateProductsAvailabilityInput): void => {
      syncProductAvailabilityEvents({
        variables: { input: [event] },
      });
    },
    [syncProductAvailabilityEvents],
  );

  const onUploadImage = useCallback(
    data => {
      if (data?.uploadProductImage && productId) {
        setProducts(prev => ({
          ...prev,
          [productId]: {
            ...prev[productId],
            imageUrl: data?.uploadProductImage,
          },
        }));
      }
    },
    [productId],
  );

  const [uploadProdImage, uploadProdImageResponse] = useMutation(
    UPLOAD_PRODUCT_IMAGE_MUTATION,
    {
      onError: noopHandler,
      onCompleted: onUploadImage,
    },
  );

  const [deleteProduct, deleteProductRequest] = useMutation(
    DELETE_PRODUCT_MUTATION,
    {
      onError: noopHandler,
    },
  );

  const [deleteProducts, deleteProductsResponse] = useMutation(
    DELETE_PRODUCTS_MUTATION,
    {
      onError: noopHandler,
    },
  );

  const [createProduct, createProductRequest] = useMutation(
    createProductMutation(customFragment),
    {
      onError: noopHandler,
    },
  );

  const [copyProductRequest, copyProductResponse] = useMutation(
    copyProductMutation(customFragment),
    {
      onError: noopHandler,
    },
  );

  const copyProduct = useCallback(
    (input: CopyProductInput) => {
      copyProductRequest({ variables: { input } });
      setOperation(Operation.CREATE);
      setCreatedProductId('');
    },
    [copyProductRequest],
  );

  const getProductById = useCallback(
    productId => {
      getProduct({ variables: { id: productId } });
      setOperation(Operation.READ);
    },
    [getProduct],
  );
  // Get product by id
  useEffect(() => {
    if (productGetRequest.data) {
      const productData = productGetRequest.data.product as Product;
      setProducts(products => {
        const productsTemp = { ...products };
        productsTemp[productData.id] = productData;
        return productsTemp;
      });
    }
  }, [productGetRequest.data]);

  useEffect(() => {
    if (getProductsRequest.data) {
      const productsData = getProductsRequest.data.products as Product[];
      setProducts(() => {
        const tempProds = {} as Record<string, Product>;
        productsData.forEach(eachProd => {
          tempProds[eachProd.id] = eachProd;
        });
        return tempProds;
      });
    }
  }, [getProductsRequest.data]);

  useEffect(() => {
    if (productId) {
      getProduct({ variables: { id: productId } });
      setOperation(Operation.READ);
    }
  }, [getProduct, productId]);
  // Get product by id end

  // product filter
  // update product
  useEffect(() => {
    if (updateRequest.data) {
      const productData = updateRequest.data.updateProduct as Product;
      setProducts(products => {
        return { ...products, [productData.id]: productData };
      });
    }
  }, [updateRequest.data]);

  const getProductsByName = useCallback(
    (name: string) => {
      getProductFilter({
        variables: {
          input: name,
        },
      });
      setOperation(Operation.READ);
    },
    [getProductFilter],
  );
  // update product
  useEffect(() => {
    if (getProductFilterRequest.data) {
      const productsData = getProductFilterRequest.data.products as Product[];
      setProducts(prevProds => {
        const tempProd = { ...prevProds };
        productsData.forEach(eachProd => {
          tempProd[eachProd.id] = eachProd;
        });
        return tempProd;
      });
    }
  }, [getProductFilterRequest.data]);

  const updateProductDetails = useCallback(
    (product: UpdateProductInput) => {
      updateProduct({
        variables: {
          input: product,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateProduct],
  );

  const getProductsFromCache = useCallback(() => {
    if (client.cache) {
      const prod = client.cache.readQuery({
        query: getProductsQuery(customFragment),
      }) as { products: Product[] };

      const cachedProducts: Product[] = prod && prod.products;
      return cachedProducts ? cachedProducts : ([] as Product[]);
    }
    return [] as unknown as Product[];
  }, [client.cache, customFragment]);

  // update product end

  const updateProductsInCache = useCallback(
    (productsMap: Record<string, Product>) => {
      let updatedProducts = Object.values(productsMap);
      const updatedProductIds = Object.keys(productsMap);

      const products = getProductsFromCache();

      updatedProducts = products.map(prod =>
        updatedProductIds.includes(prod.id) ? productsMap[`${prod.id}`] : prod,
      );

      client.cache.writeQuery({
        query: getProductsQuery(customFragment),
        data: {
          products: updatedProducts,
        },
      });
    },
    [client.cache, customFragment, getProductsFromCache],
  );

  useEffect(() => {
    if (updateProductsRequest.data) {
      const productData = updateProductsRequest.data
        .updateProducts as Product[];
      setProducts(products => {
        const productsTemp = { ...products };
        productData.forEach(prod => {
          productsTemp[prod.id] = prod;
        });
        updateProductsInCache(keyBy(productData, 'id'));
        return productsTemp;
      });
    }
  }, [updateProductsInCache, updateProductsRequest.data]);

  const updateProductsDetails = useCallback(
    (products: UpdateProductInput[]) => {
      updateProducts({
        variables: {
          input: products,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateProducts],
  );

  useEffect(() => {
    if (createProductRequest.data) {
      const productData = createProductRequest.data?.createProduct as Product;
      setProducts(products => {
        return { ...products, [productData.id]: productData };
      });
      setCreatedProductId(productData.id);
    }
  }, [createProductRequest.data]);

  useEffect(() => {
    if (copyProductResponse.data) {
      const productData = copyProductResponse.data?.copyProduct as Product;
      setProducts(products => {
        return { ...products, [productData.id]: productData };
      });
      setCreatedProductId(productData.id);
    }
  }, [copyProductResponse.data]);

  const createProductDetails = useCallback(
    (productInput: CreateProductInput) => {
      setCreatedProductId('');
      createProduct({
        variables: {
          input: productInput,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createProduct],
  );

  const uploadProductImage = useCallback(
    (input: ImageUploadInput, productId: string) => {
      uploadProdImage({
        variables: {
          input,
          productId,
        },
      });
    },
    [uploadProdImage],
  );

  const deleteProductsFromCache = useCallback(
    (
      productsMap: Record<string, Product>,
      deletedIds?: Map<string, string>,
    ) => {
      let updatedProducts = Object.values(productsMap);
      if (!updatedProducts.length) {
        const data = client.cache.readQuery({
          query: getProductsQuery(PRODUCT_LIST_SCREEN_FRAGMENT),
        }) as { products: Product[] };

        const products = data?.products || [];

        updatedProducts =
          (deletedIds &&
            deletedIds.size &&
            products.filter(prod => !deletedIds.has(prod.id))) ||
          products;
      }
      client.cache.writeQuery({
        query: getProductsQuery(PRODUCT_LIST_SCREEN_FRAGMENT),
        data: {
          products: updatedProducts,
        },
      });
    },
    [client.cache],
  );

  const deleteProductDetails = useCallback(
    (productId: string) => {
      deleteProduct({
        variables: {
          input: productId,
        },
      });
      setProducts(prevProducts => {
        const temp = { ...prevProducts };
        const deletedProdId = new Map();
        if (productId) deletedProdId.set(productId, productId);
        delete temp[productId];
        // delete from cache
        deleteProductsFromCache(temp, deletedProdId);
        return temp;
      });
      setOperation(Operation.DELETE);
      setDeletedProductIds([productId]);
    },
    [deleteProduct, deleteProductsFromCache],
  );

  const deleteProductsDetails = useCallback(
    (productIds: string[]) => {
      deleteProducts({
        variables: {
          input: productIds,
        },
      });

      setProducts(prev => {
        const temp = { ...prev };
        const deletedProdIds = new Map<string, string>();
        productIds.forEach(eachProd => {
          deletedProdIds.set(eachProd, eachProd);
          delete temp[eachProd];
        });
        // delete prod from cache
        deleteProductsFromCache(temp, deletedProdIds);
        return temp;
      });

      setOperation(Operation.DELETE);
    },
    [deleteProducts, deleteProductsFromCache],
  );

  const refetchAllProducts = useCallback(() => {
    const refetchedData = getProductsRequest.client?.readQuery({
      query: GET_PRODUCTS_QUERY,
    });
    setProducts(prev => {
      const tempPrev = { ...prev };
      const refetchedProducts = refetchedData?.products as Product[];
      if (refetchedProducts?.length) {
        refetchedProducts.forEach(eachProd => {
          tempPrev[eachProd.id] = eachProd;
        });
      }
      return tempPrev;
    });
    setOperation(Operation.READ);
  }, [getProductsRequest.client]);

  const refetchProduct = useCallback(() => {
    const refetchedData = productGetRequest.client?.readQuery({
      query: GET_PRODUCT_QUERY,
      variables: {
        id: productId,
      },
    });
    setProducts(prev => {
      const tempPrev = { ...prev };
      const refetchedProd = refetchedData?.product as Product;
      if (refetchedProd) {
        tempPrev[refetchedProd.id] = refetchedProd;
      }
      return tempPrev;
    });
    setOperation(Operation.READ);
  }, [productGetRequest.client, productId]);

  const getAllProducts = useCallback(() => {
    getProducts();
    setOperation(Operation.READ);
  }, [getProducts]);

  const error: ApolloError | undefined =
    updateRequest.error ||
    productGetRequest.error ||
    getProductsRequest.error ||
    updateProductsRequest.error ||
    deleteProductRequest.error ||
    createProductRequest.error ||
    getProductFilterRequest.error ||
    deleteProductsResponse.error ||
    uploadProdImageResponse.error ||
    syncUpdateProductResponse.error ||
    copyProductResponse.error;

  const loading: boolean =
    productGetRequest.loading ||
    updateRequest.loading ||
    getProductsRequest.loading ||
    updateProductsRequest.loading ||
    deleteProductRequest.loading ||
    createProductRequest.loading ||
    getProductFilterRequest.loading ||
    deleteProductsResponse.loading ||
    uploadProdImageResponse.loading ||
    syncUpdateProductResponse.loading ||
    copyProductResponse.loading;

  return useMemo(
    () => ({
      products: products,
      refetchAllProduct: refetchAllProducts,
      refetchProduct,
      updateProduct: updateProductDetails,
      updateProducts: updateProductsDetails,
      deleteProduct: deleteProductDetails,
      deleteProducts: deleteProductsDetails,
      createProduct: createProductDetails,
      getProductsByName: getProductsByName,
      createdProductId,
      deletedProductIds,
      getAllProducts: getAllProducts,
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
      getProductById,
      updateProductsInCache,
      uploadProductImage,
      getProductsFromCache,
      updateProductsAvailabilityEvent,
      copyProduct,
    }),
    [
      products,
      error,
      updateProductDetails,
      updateProductsDetails,
      deleteProductDetails,
      deleteProductsDetails,
      createProductDetails,
      getProductsByName,
      createdProductId,
      deletedProductIds,
      operation,
      getAllProducts,
      loading,
      refetchAllProducts,
      refetchProduct,
      getProductById,
      updateProductsInCache,
      uploadProductImage,
      getProductsFromCache,
      updateProductsAvailabilityEvent,
      copyProduct,
    ],
  );
}
