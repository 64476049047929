import { StyleFn } from '@hitz-group/domain';
import React, { useState } from 'react';
import { useFela } from 'react-fela';
import DropDown from '../../../../../components/FormInput/DropDown';
import { useTranslation } from '@hitz-group/localization';
import Modal from '../../../../../components/Modals/Modal';
import Button from '../../../../../components/Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';

interface SetProductTypesProps {
  onSubmit: (selectedProductType: string) => void;
  productTypeOptions: { label: string; value: string }[];
  countOfproducts: number;
}

const createModalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const dropDownStyle: StyleFn = () => ({
  width: 280,
  height: 38,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  width: 280,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginTop: theme.spacing.big,
  zIndex: 200,
});

const createVenueLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const AssignProductType: React.FC<SetProductTypesProps> = ({
  onSubmit,
  countOfproducts,
  productTypeOptions,
}: SetProductTypesProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const defaultPT = productTypeOptions?.[0]?.value || '';
  const [selectedPT, setSelectedPT] = useState(defaultPT);

  const { closeModal } = useModal();
  return (
    <Modal
      title={translate('productBulkOperations.assignProductType')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
    >
      <DropDown
        title={translate('productBulkOperations.selectProductType')}
        values={productTypeOptions}
        selectedValue={selectedPT}
        style={css(dropDownStyle)}
        onValueChange={val => setSelectedPT(val)}
      />
      <Button
        title={translate('productBulkOperations.assignProductTypesToProducts', {
          countOfproducts,
        })}
        containerStyle={css(buttonStyle)}
        labelStyle={css(createVenueLabelStyle)}
        color={theme.colors.green}
        onPress={() => onSubmit(selectedPT)}
      />
    </Modal>
  );
};
