import React from 'react';
import { useRoute } from '@react-navigation/native';
import { AvailabilityProduct } from './AvailabilityProduct';
import { AvailabilityVariant } from './AvailabilityVariant';
export const Availability: React.FC = () => {
  const route = useRoute();
  const params = route.params as {
    productId: string;
    isVariant: string | boolean;
  };

  let isVariant;
  if (typeof params?.isVariant === 'string') {
    isVariant = params?.isVariant === 'true';
  } else {
    isVariant = params?.isVariant;
  }
  const productId = !isVariant ? params?.productId : '';
  const variantId = isVariant ? params?.productId : '';
  return (
    (variantId && <AvailabilityVariant variantId={variantId} />) ||
    (productId && <AvailabilityProduct productId={productId} />) ||
    null
  );
};
