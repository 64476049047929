import { StyleFn, Icons } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import ButtonWithActionSheet from '../../../../../components/ButtonWithActionSheet/ButtonWithActionSheet';
import { useModal } from '@hitz-group/rn-use-modal';
import { SetPrepTimeModal } from './SetPrepTimeModal';
import { SetBusyModal } from './SetBusyModal';

const customDropDownStyle: StyleFn = ({ theme }) => ({
  right: theme.spacing.small,
});

const btnTextlabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  fontFamily: theme.font.regular,
  textTransform: 'none',
  paddingLeft: theme.padding.medium * 3,
});

const dropDownTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primaryLightest,
  fontFamily: theme.font.regular,
  textTransform: 'none',
  marginLeft: theme.spacing.medium,
  fontSize: theme.fontSize.medium,
});

const preparationTimeStyle: StyleFn = ({ theme }) => ({
  width: 38,
  height: 38,
  flex: 0.5,
  backgroundColor: theme.colors.blue,
  borderRadius: theme.radius.small,
  marginTop: -theme.spacing.small / 2,
  marginRight: theme.spacing.medium,
  marginLeft: -theme.spacing.medium,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.blue,
});
interface OrderSettingsOptionsProps {
  setPreparationTime?: (time: number) => void;
  setBusyTime?: (time: number) => void;
}

export const OrderSettingsOptions: React.FC<OrderSettingsOptionsProps> = ({
  setBusyTime,
  setPreparationTime,
}: OrderSettingsOptionsProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showModal } = useModal();

  const setPrepTimeModal = useCallback(() => {
    showModal(<SetPrepTimeModal setPreparationTime={setPreparationTime} />);
  }, [showModal, setPreparationTime]);

  const setBusyModal = useCallback(() => {
    showModal(<SetBusyModal setBusyTime={setBusyTime} />);
  }, [showModal, setBusyTime]);

  return (
    <ButtonWithActionSheet
      actionList={[
        {
          label: translate('onlineOrders.setPrepTime'),
          action: setPrepTimeModal,
        },
        {
          label: translate('onlineOrders.setAsBusy'),
          action: setBusyModal,
        },
      ]}
      customDropDownStyle={css(customDropDownStyle)}
      btnTextlabelStyle={css(btnTextlabelStyle)}
      containerStyle={css(preparationTimeStyle)}
      iconContainerStyle={css(iconContainerStyle)}
      iconColor={theme.colors.white}
      dropDownTitleStyle={css(dropDownTitleStyle)}
      icon={Icons.Clock}
    />
  );
};
