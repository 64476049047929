import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import TitleBar from '../TitleBar/TitleBar';
import { getStyles, cursorStyle } from './RefundCartHeader.style';
import { isWeb } from '../../../src/common/theme';
import IconButton from '../Button/IconButton';
import { Order } from '@hitz-group/domain';
import Label from '../Label/Label';
import {
  createOrderTypesMap,
  OrderTypesMap,
} from '../../../src/utils/orderTypesHelper';

export interface RefundCartHeaderProps {
  order: Order;
  onPressClose: () => void;
}

export const RefundCartHeader: React.FC<RefundCartHeaderProps> = ({
  order,
  onPressClose,
}) => {
  const { css, theme } = useFela();
  const styles = useMemo(() => getStyles(theme), [theme]);
  const orderTypeMap = useRef([] as unknown as OrderTypesMap);

  useEffect(() => {
    orderTypeMap.current = createOrderTypesMap([order.orderType]);
  }, [order]);

  const TitleLeft = useCallback((): React.ReactElement => {
    return (
      <View
        testID="change-order-type"
        style={[styles.cartHeaderLeftView, isWeb && css(cursorStyle)]}
      >
        <View style={styles.orderNumberAndTypeContainer}>
          <IconButton
            testID={'secondary-side-panel-back-icon'}
            icon="times"
            onPress={onPressClose}
            iconColor={theme.colors.black}
          />
          <View style={styles.cartHeaderLeftOrderNumberView}>
            <Text
              testID="order-number"
              style={[styles.cartHeaderBadge, styles.boldText]}
            >
              {order.orderNumber}
            </Text>
          </View>
          {order.table ? (
            <Label
              value={order.table.name || order.table.id}
              textStyle={styles.labelText}
            />
          ) : (
            <Label value={order.orderType.code} textStyle={styles.labelText} />
          )}
        </View>
      </View>
    );
  }, [styles, css, theme, order, onPressClose]);

  return (
    <TitleBar
      containerStyle={styles.container}
      labelContainerStyle={styles.labelContainer}
      titleLeft={<TitleLeft />}
      testID="cart-header"
    />
  );
};
