import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as settings from '../state/preferences';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import fetch from 'cross-fetch';
import {
  AuthState,
  DEFAULT_TOKEN_INFO,
  TokenInfo,
  tokenUtility,
} from '../state/tokenUtility';
import { noopHandler } from '../utils/errorHandlers';
import { App } from '@hitz-group/domain';
import { REACT_APP_API_URL } from 'react-native-dotenv';
import { ApolloLink } from '@apollo/client/link/core';
import { handleMissingEvents } from '../utils/orderEventHelper';
import Observable from 'zen-observable';
import LogRocket from '@logrocket/react-native';
import { TRACKING_EVENTS } from '../utils/logRocketHelper';
import { isValidToken } from '../utils/validator';

export const SERVICE_URI =
  process.env['REACT_APP_API_URL'] ||
  REACT_APP_API_URL ||
  'http://localhost:4000';

interface AuthHeader {
  authorization?: string;
  organization?: string;
  venue?: string;
  store?: string;
  deviceProfile?: string;
  device?: string;
}

let authOptions: TokenInfo = DEFAULT_TOKEN_INFO;

// FIXME: keep un-subscription for this
tokenUtility.getTokenInfo$.subscribe((data = DEFAULT_TOKEN_INFO) => {
  authOptions = data;
});

export const tokenLookup = async (): Promise<AuthHeader> => {
  const session = await settings.getSession();
  let appToken;
  switch (authOptions.activeApp) {
    case App.BACKOFFICE:
      appToken = authOptions.backOfficeToken;
      break;
    case App.POS_APP:
      appToken = authOptions.posAppToken;
      break;
    default:
      appToken = undefined;
  }

  return {
    ...(authOptions.token && { authorization: authOptions.token }),
    organization: session?.currentOrganization?.id || '',
    venue: session?.currentVenue?.id || '',
    store: session?.currentStore?.id || '',
    device: session?.device?.id || '',
    ...(!!appToken && { appToken }),
    deviceProfile: session?.deviceProfile?.id || '',
  };
};

/* istanbul ignore file */
export const withToken = setContext((_, { headers }) => {
  return tokenLookup().then(tokenHeaders => ({
    headers: {
      ...tokenHeaders,
      ...headers,
    },
  }));
});

enum ErrorCodes {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export const resetToken = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    if (
      graphQLErrors.some(
        gqlError => gqlError?.extensions?.code === ErrorCodes.UNAUTHENTICATED,
      )
    ) {
      clearSession();
    }
  }
});

export const clearSession = async () => {
  await settings.setSession({ authorized: false });
  tokenUtility.clearToken();
};

/**
 * This will only validate or handle refresh token related errors
 */
export const refreshSession = new TokenRefreshLink({
  isTokenValidOrUndefined: () => {
    if (typeof authOptions.token === 'undefined') {
      // token not available so either it can be a guest session
      return true;
    }
    if (typeof authOptions.refreshToken !== 'undefined') {
      // Must have a token and a refresh token
      if (
        authOptions.expiresAfter &&
        authOptions.expiresAfter > settings.getExpiresAfter(0)
      ) {
        // token is not expired
        return true;
      }
    }
    return false;
  },
  fetchAccessToken: () => {
    return fetch(SERVICE_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `mutation refreshToken { refreshToken(token: "${authOptions.refreshToken}") { token expiresIn refreshToken } }`,
      }),
    });
  },
  handleResponse: () => async (response: Response) => {
    const json = await response.json();
    const getTokenData = json.data.refreshToken;
    const oldSession = await settings.getSession();

    if (!((getTokenData || {}).token && oldSession)) {
      await clearSession();
      return {
        access_token: undefined,
      };
    }

    // update new access token
    const _token = getTokenData.token;
    const expiresAfter = settings.getExpiresAfter(getTokenData.expiresIn);
    tokenUtility.setTokenInfo({
      token: _token,
      refreshToken: getTokenData.refreshToken,
      expiresAfter,
      authState: AuthState.LOGGED_IN,
    });
    await settings.setSession({
      ...oldSession,
      refreshToken: getTokenData.refreshToken,
      token: _token,
      expiresIn: getTokenData.expiresIn,
      expiresAfter,
    });
    return {
      access_token: _token,
    };
  },
  handleFetch: noopHandler,
  handleError: async error => {
    // logout user
    console.error('FAIL TO REFRESH USER TOKEN', error);
    await clearSession();
  },
});

/**
 * This will only validate or handle refresh token related errors
 */
export const refreshAppToken = new TokenRefreshLink({
  isTokenValidOrUndefined: () => {
    if (authOptions.authState !== AuthState.LOGGED_IN) {
      return true;
    }
    const token =
      authOptions.activeApp === App.BACKOFFICE
        ? authOptions.backOfficeToken
        : authOptions.posAppToken;
    if (!token) {
      return true;
    }
    if (isValidToken(token)) {
      return true;
    }
    return false;
  },
  fetchAccessToken: () => {
    return fetch(SERVICE_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: authOptions.token as string,
      },
      body: JSON.stringify({
        query:
          'mutation clientAppToken($source: App!) {\n  clientAppToken(source: $source) {\n    token\n    __typename\n  }\n}\n',
        variables: {
          source: authOptions.activeApp,
        },
      }),
    });
  },
  handleResponse: () => async (response: Response) => {
    const json = await response.json();
    const appToken = json.data.clientAppToken?.token;

    if (appToken) {
      tokenUtility.setTokenInfo(
        authOptions.activeApp === App.BACKOFFICE
          ? {
              ...authOptions,
              backOfficeToken: appToken,
            }
          : {
              ...authOptions,
              posAppToken: appToken,
            },
      );
    }

    return {
      access_token: appToken,
    };
  },
  handleFetch: noopHandler,
  handleError: async error => {
    console.error('FAIL TO RE_FETCH APP_TOKEN', error);
    await clearSession();
  },
});

export const syncOrderEvents = new ApolloLink((operation, forward) => {
  if (operation.operationName === 'syncEvents') {
    return new Observable(observer => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let handle: any;
      // check if payload has missing events
      handleMissingEvents(operation.variables.input)
        .then(updatedEvents => {
          // Overwriting the event payload with updatedEvents which will have missing events attached.
          operation.variables.input = updatedEvents;
          // Continuing with the api call. Attaching api hooks to the observable
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(error => {
          // Tracking the error with debug info
          LogRocket.track(TRACKING_EVENTS.POS_MISSING_EVENTS_HANDLING_FAILED);
          console.log(TRACKING_EVENTS.POS_MISSING_EVENTS_HANDLING_FAILED, {
            tags: 'APP_ERROR',
            operation,
            error,
          });
          // In case of any error, the issue is delegated to api layer. (fallback to normal flow)
          // Continuing with the api call. Attaching api hooks to the observable
          forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        });
      return () => {
        if (handle) handle.unsubscribe();
      };
    });
  } else {
    return forward(operation);
  }
});
export const authFlowLink = [
  refreshSession,
  refreshAppToken,
  withToken,
  resetToken,
  syncOrderEvents,
];
