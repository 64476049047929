import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import Button from '../../../../../components/Button/Button';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Text, View, ScrollView } from 'react-native';
import { ProfitMarginRow } from './ProfitMarginRow';
import { ProfitMarginsStyles } from './ProfitMargins.styles';
import { useTranslation } from '@hitz-group/localization';
import { ProductType, ProfitMargin } from '@hitz-group/domain';

interface Props {
  profitMargins: ProfitMargin[];
  productTypes: ProductType[];
  options: { loading: boolean };
  onChange: (prop: string, index: number, value: string) => void;
  onPressCreateNew: () => void;
  onDelete: (index: number) => void;
  onSave: () => void;
}

export const ProfitMarginsList: React.FC<Props> = ({
  profitMargins,
  productTypes,
  options: { loading = true },
  onChange,
  onPressCreateNew,
  onDelete,
  onSave,
}) => {
  const styles = ProfitMarginsStyles();
  const { translate } = useTranslation();

  const COLUMNS = [
    {
      title: translate('backOfficeProfitMargins.productType'),
      flex: 2,
      alignItems: 'flex-start',
    },
    {
      title: translate('backOfficeProfitMargins.margin') + ' (%)',
      width: 150,
      alignItems: 'flex-start',
    },
    { flex: 1 },
  ].map(columnName => ({
    ...columnName,
  }));

  if (!productTypes.length && !loading) {
    return (
      <View testID={'noProductTypes'} style={styles.messagePageStyle}>
        <Text>
          {translate('backOfficeProfitMargins.noProductTypesMessage')}
        </Text>
      </View>
    );
  }
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={styles.pageStyle}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ScrollView>
            <BackOfficeSection
              title={translate('backOfficeProfitMargins.title')}
              titleDescription={translate('backOfficeProfitMargins.titleDesc')}
              actionContainerStyle={styles.actionContainerStyle}
              action={<BackOfficeCreateNewButton onPress={onPressCreateNew} />}
              containerStyle={styles.containerStyle}
            >
              <TableComponent
                columns={COLUMNS}
                data={profitMargins}
                columnContainerStyle={styles.columnContainerStyle}
                normalRows={true}
                renderRow={(item: ProfitMargin, index: number) => (
                  <ProfitMarginRow
                    index={index}
                    key={`${index}-${item.productTypeId}`}
                    profitMargin={item}
                    productTypes={productTypes}
                    onChange={onChange}
                    onDelete={onDelete}
                  />
                )}
              />
            </BackOfficeSection>
          </ScrollView>
        )}
      </View>
      <View style={styles.mainStyle}>
        <View style={styles.actionsContainerStyle}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={styles.saveButtonStyle}
            labelStyle={styles.titleStyle}
            onPress={onSave}
          />
        </View>
      </View>
    </>
  );
};
