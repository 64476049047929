import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import Button from '../../../Button/Button';
import IconButton from '../../../Button/IconButton';
import PopupView from '../../../PopupView/PopupView';
import TitleBar from '../../../TitleBar/TitleBar';

import { StyleFn, Customer } from '@hitz-group/domain';

import { useModal } from '@hitz-group/rn-use-modal';
import { useFela } from 'react-fela';
import { useCurrency, useTranslation } from '@hitz-group/localization';

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 8,
});
const containerStyle: StyleFn = ({ theme }) => ({
  width: 600,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  flex: 1,
});

const popupStyle: StyleFn = () => ({
  paddingTop: 8,
});

const customerAccountLabel: StyleFn = ({ theme }) => ({
  color: theme.colors.primaryDarkGrey,
  marginTop: 8,
});

const customerAccountStyling: StyleFn = () => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

const customerAccountTextStyling: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  padding: 20,
  textAlign: 'center',
  fontWeight: theme.font.bold,
});

const customerAccountAmountStyling: StyleFn = () => ({
  justifyContent: 'center',
  textAlign: 'center',
  fontWeight: 600,
});

const customerBalanceTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
  fontSize: theme.fontSize.small,
  fontWeight: 600,
});

const customerBalanceEmptyTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontSize: theme.fontSize.small,
  fontWeight: 600,
});

const textHeader: StyleFn = () => ({
  marginTop: 10,
  marginBottom: 20,
});

const buttonStyle: StyleFn = () => ({
  marginTop: 20,
  width: 300,
  alignSelf: 'center',
});

const cancelButtonStyle: StyleFn = () => ({
  marginTop: 20,
  width: 300,
  alignSelf: 'center',
});

export interface ConfirmAccountIdentityModalProps {
  customer: Customer;
  processingAmount: number;
  onConfirmPay: () => void;
}

const ConfirmAccountIdentityModal: React.FC<
  ConfirmAccountIdentityModalProps
> = ({ customer, onConfirmPay, processingAmount }) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const customerName = customer?.firstName + ' ' + customer?.lastName;

  const handleCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const titleLeft = (
    <IconButton
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={handleCloseModal}
    />
  );

  return (
    <View style={css(containerStyle)}>
      <TitleBar
        titleLeft={titleLeft}
        primary
        title={translate('order.customerAccountIdentity.title')}
      />
      <PopupView containerStyle={css(popupStyle)}>
        <Text style={css(textHeader)}>
          {translate('order.customerAccountIdentity.confirmation', {
            customerName: customerName,
            amount: formatCurrency(processingAmount),
          })}
        </Text>
        <Text> {customer?.email}</Text>
        <Text> {customer?.phone}</Text>

        <View style={css(customerAccountStyling)}>
          <View style={css(customerAccountTextStyling)}>
            <Text style={css(customerAccountAmountStyling)}>
              {formatCurrency(
                customer?.customerAccountDetails?.maxBalanceLimit || 0,
              )}
            </Text>
            <Text style={css(customerAccountLabel)}>
              {translate('order.customerAccountIdentity.balanceLimit')}
            </Text>
          </View>
          <View style={css(customerAccountTextStyling)}>
            <Text style={css(customerAccountAmountStyling)}>
              {formatCurrency(
                customer?.customerAccountDetails?.maxOrderLimit || 0,
              )}
            </Text>
            <Text style={css(customerAccountLabel)}>
              {translate('order.customerAccountIdentity.maxOrderLimit')}
            </Text>
          </View>
          <View style={css(customerAccountTextStyling)}>
            <Text
              style={css(
                customer?.customerAccountDetails?.currentBalance === 0
                  ? customerBalanceEmptyTextStyle
                  : customerBalanceTextStyle,
              )}
            >
              {formatCurrency(
                customer?.customerAccountDetails?.currentBalance || 0,
              )}
            </Text>
            <Text style={css(customerAccountLabel)}>
              {translate('order.customerAccountIdentity.currentBalance')}
            </Text>
          </View>
        </View>

        <Button
          title={translate(
            'order.customerAccountIdentity.confirmAndCompletePayment',
          )}
          fluid
          success
          size="small"
          testID="handle-payment"
          containerStyle={css(buttonStyle)}
          onPress={onConfirmPay}
        />

        <Button
          title={translate('order.customerAccountIdentity.cancel')}
          fluid
          size="small"
          testID="cancel-modal"
          containerStyle={css(cancelButtonStyle)}
          onPress={closeModal}
        />
      </PopupView>
    </View>
  );
};

export default ConfirmAccountIdentityModal;
