import React, { FC } from 'react';
import { View, ViewStyle, Text } from 'react-native';
import FormInput from './FormInput';
import DropDown, { Option } from '../DropDown/DropDown';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';

export interface FormInputWithDropdownProps {
  containerStyle?: ViewStyle;
  inputValue: string;
  onInputChange?: (value: string) => void;
  dropdownValue: string;
  dropdownOptions: Option[];
  onDropdownChange: (itemValue: string) => void;
  dropdownWidth?: number;
  defaultValue?: string;
  error?: boolean;
  testID?: string;
  reverseRow?: boolean;
  title?: string;
  titleStyle?: ViewStyle;
}

const inputContainerStyle: StyleFn = ({ theme, error }) => ({
  borderWidth: 0,
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
});
const inputOuterStyle: StyleFn = ({}) => ({
  flex: 1,
});

const dropdownContainerStyle: StyleFn = ({ dropdownWidth }) => ({
  width: dropdownWidth,
});

const dropdownExtraPopoverStyle: StyleFn = ({ dropdownWidth }) => ({
  width: dropdownWidth,
});

const dropdownTouchableStyle: StyleFn = ({ theme }) => ({
  marginTop: 0,
  borderWidth: 0,
  backgroundColor: theme.colors.greyLight,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
});

const containerStyle: StyleFn = ({ theme, reverseRow }) => ({
  borderWidth: 1,
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  flexDirection: reverseRow ? 'row-reverse' : 'row',
  alignItems: 'center',
  maxWidth: 300,
  width: '100%',
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.transparent,
  paddingLeft: 3,
});

const labelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  textAlign: 'left',
  paddingLeft: 10,
  marginBottom: 10,
});
const FormInputWithDropdown: FC<FormInputWithDropdownProps> = ({
  inputValue,
  onInputChange,
  dropdownValue,
  dropdownOptions,
  onDropdownChange,
  containerStyle: containerStyleProp,
  dropdownWidth = 80,
  error,
  testID,
  reverseRow,
  title,
  titleStyle,
}) => {
  const { css } = useFela({ dropdownWidth, error, reverseRow });

  return (
    <>
      {title && (
        <View testID="input-with-unit-title">
          <Text style={css(labelStyle, titleStyle)}>{title}</Text>
        </View>
      )}
      <View
        testID={`${testID}-wrapper`}
        style={[css(containerStyle), containerStyleProp]}
      >
        <View style={css(inputOuterStyle)}>
          <FormInput
            value={inputValue}
            onChangeText={onInputChange}
            containerStyle={css(inputContainerStyle)}
            fluid
            keyboardType="number-pad"
          />
        </View>
        <DropDown
          options={dropdownOptions}
          selectedValue={dropdownValue}
          onValueChange={onDropdownChange}
          containerStyle={css(dropdownContainerStyle)}
          extraPopoverStyle={css(dropdownExtraPopoverStyle)}
          touchableStyle={css(dropdownTouchableStyle)}
          iconStyleFunction={iconContainerStyle}
        />
      </View>
    </>
  );
};

export default FormInputWithDropdown;
