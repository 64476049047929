import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { Text, View, ViewStyle } from 'react-native';
import { useCurrency } from '@hitz-group/localization';
export interface SummaryItemProps {
  label: string;
  testID?: string;
  value: string | number;
  highlighted?: boolean;
  customContainerStyle?: ViewStyle;
  isRefundOrder?: boolean;
  customTotalValueTextStyle?: StyleFn;
  isDiscount?: boolean;
}

const containerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginVertical: theme.spacing.small / 2,
});

const labelStyle: StyleFn = ({ theme, highlighted }) => ({
  fontFamily: highlighted ? theme.font.semibold : theme.font.regular,
  fontSize: highlighted ? theme.fontSize.medium : theme.fontSize.smaller,
  color: highlighted ? theme.colors.heading : theme.colors.paragraph,
});

const valueStyle: StyleFn = ({ theme, highlighted }) => ({
  fontFamily: highlighted ? theme.font.semibold : theme.font.regular,
  fontSize: highlighted ? theme.fontSize.medium : theme.fontSize.smaller,
  color: highlighted ? theme.colors.heading : theme.colors.paragraph,
  textAlign: 'right',
});

const SummaryItem: React.FC<SummaryItemProps> = ({
  label,
  value,
  highlighted,
  testID,
  customContainerStyle,
  isRefundOrder,
  customTotalValueTextStyle,
  isDiscount,
}: SummaryItemProps) => {
  const { css } = useFela({ highlighted });
  const { formatCurrency } = useCurrency();

  return (
    <View style={[css(containerStyle), customContainerStyle]} testID={testID}>
      <Text style={css(labelStyle)}>{label}</Text>
      <Text style={css([valueStyle, customTotalValueTextStyle])}>
        {isRefundOrder && '-'}
        {isDiscount && '('}
        {formatCurrency(+value || 0)}
        {isDiscount && ')'}
      </Text>
    </View>
  );
};

export default SummaryItem;
