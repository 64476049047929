import {
  Product,
  Tag,
  TagType,
  CreateTagInput,
  PRODUCT_UNITS,
  ProductType,
  LOCALE,
  ImageUploadInput,
  GeneralProductData,
} from '@hitz-group/domain';
import { UpdateProductInput } from '@hitz-group/domain';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client/react/hooks';
import {
  GET_TAGS_QUERY,
  ADD_TAG_MUTATION,
} from '../../../../../graphql/settings';
import { useNotification } from '../../../../../hooks/Notification';
import {
  parseApolloError,
  noopHandler,
} from '../../../../../utils/errorHandlers';
import { useTranslation } from '@hitz-group/localization';
import { UnitType } from '@hitz-group/domain';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { useProductTypes } from '../../../../../hooks/app/useProductTypes';
import { usePrinterProfiles } from '../../../../../hooks/app/usePrinterProfiles';
import { Operation } from '../../../../../types/Operation';
import { GeneralForm } from './GeneralForm';
import { PRODUCT_GENERAL_FRAGMENT } from '../../../../../hooks/app/products/graphql';
import {
  encodeAlternateNameValuesToBase64,
  decodeAlternateNameValuesToBase64,
} from '@hitz-group/client-utils';
import { updateAlternateNamesWithNewValue } from '../../../../../utils/AlternateNameHelper';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';

interface GeneralProductProps {
  productId: string;
  isVariantProduct?: boolean;
}

const unitsValues: { [key: string]: { label: string; value: string }[] } = {
  Volume: PRODUCT_UNITS[UnitType.Volume].map(unit => ({
    label: unit,
    value: unit,
  })),
  Weight: PRODUCT_UNITS[UnitType.Weight].map(unit => ({
    label: unit,
    value: unit,
  })),
};

export const GeneralProduct: React.FC<GeneralProductProps> = ({
  productId,
  isVariantProduct,
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [productData, setProductData] = useState<GeneralProductData>(
    {} as GeneralProductData,
  );

  const [dietaryTagsArray, setDietaryTagsArray] = useState<
    { label: string; value: string }[]
  >([]);

  const {
    products,
    error: prodErr,
    updateProduct,
    operation,
    loading,
    uploadProductImage,
  } = useProducts(productId, PRODUCT_GENERAL_FRAGMENT);

  const { productTypes, getProductTypes } = useProductTypes();
  const { printerProfiles, getPrinterProfiles } = usePrinterProfiles();

  useEffect(() => {
    getProductTypes();
    getPrinterProfiles();
  }, [getProductTypes, getPrinterProfiles]);

  const tagsQuery = useQuery(GET_TAGS_QUERY, {
    variables: { filter: { tagType: TagType.DIETARY } },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!prodErr && operation === Operation.UPDATE && !loading) {
      showNotification({
        success: true,
        message: translate('productSettings.productSavedSuccessfully'),
      });
    }
  }, [operation, loading, showNotification, translate, prodErr]);

  const [addTag, addTagRequest] = useMutation(ADD_TAG_MUTATION, {
    onError: noopHandler,
  });

  useEffect(() => {
    if (tagsQuery.data?.tags?.length) {
      const tagsData = (tagsQuery.data?.tags as Tag[]).map(tag => ({
        label: tag.name,
        value: tag.id,
      }));
      setDietaryTagsArray(tagsData);
    }
  }, [tagsQuery.data]);

  const productTypeOptions = useMemo(() => {
    return Object.values(productTypes).map(x => ({
      value: x.id,
      label: x.name,
    }));
  }, [productTypes]);

  const printerProfileOptions = useMemo(() => {
    return Object.values(printerProfiles).map(x => ({
      value: x.id,
      label: x.name,
    }));
  }, [printerProfiles]);

  useEffect(() => {
    if (addTagRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(addTagRequest.error),
      });
    }
  }, [addTagRequest.error, showNotification]);

  useEffect(() => {
    if (addTagRequest.data) {
      showNotification({
        success: true,
        message: translate('productSettings.tagAddedSuccessfully'),
      });
      if (addTagRequest.data.createTag) {
        setDietaryTagsArray(previous => {
          const tempTagOptions = [...previous];
          tempTagOptions.push({
            label: addTagRequest.data.createTag.name,
            value: addTagRequest.data.createTag.id,
          });
          return tempTagOptions;
        });
      }
    }
  }, [addTagRequest.data, showNotification, translate]);

  useEffect(() => {
    if (tagsQuery.error) {
      showNotification({
        error: true,
        message: parseApolloError(tagsQuery.error),
      });
    }
  }, [tagsQuery.error, showNotification]);

  useEffect(() => {
    if (productId && products[productId]) {
      const product = products[productId] as Product;
      const productModal = {
        ...product,
        alternateNames: decodeAlternateNameValuesToBase64(
          product.alternateNames || [],
        ),
        printerProfiles: product.printerProfiles?.map(
          printerProfile => printerProfile.id,
        ),
      } as unknown as GeneralProductData;
      setProductData(productModal);
    }
  }, [products, productId]);

  useEffect(() => {
    if (prodErr) {
      showNotification({
        error: true,
        message: prodErr,
      });
    }
  }, [prodErr, showNotification]);

  const onChange = useCallback((prop: string, value): void => {
    if (prop === 'productType') {
      setProductData(form => {
        return {
          ...form,
          productType: { id: value } as ProductType,
        };
      });
    } else if (prop === 'dietaryTags') {
      setProductData(form => {
        return {
          ...form,
          dietaryTags: value.map(
            (tagId: string) => ({ id: tagId } as unknown as Tag),
          ),
        };
      });
    } else {
      setProductData(form => {
        return {
          ...form,
          [prop]: value,
        };
      });
    }
  }, []);

  const onChangeAlternateName = useCallback(
    (prop: string, value: string, locale: LOCALE): void => {
      setProductData(previousProduct => {
        return updateAlternateNamesWithNewValue(
          previousProduct,
          locale,
          prop,
          value,
        );
      });
    },
    [],
  );

  const onAddTag = useCallback(
    (name: string): void => {
      addTag({
        variables: {
          input: { name: name, tagType: TagType.DIETARY } as CreateTagInput,
        },
      });
    },
    [addTag],
  );

  const onChangeMeasures = useCallback(
    (prop: string, value): void => {
      const product = { ...productData };
      product.measuredBy = {
        ...product.measuredBy,
        [prop]: value,
      };
      if (prop === 'unitType') {
        if (value === UnitType.Units) {
          product.measuredBy.units = '';
        } else {
          product.measuredBy.units = unitsValues[value][0]['value'];
        }
      }

      if (prop === 'defaultSize') {
        product.measuredBy.defaultSize = parseFloat(value);
      }
      setProductData(product);
    },
    [productData],
  );

  const onSaveProduct = useCallback((): void => {
    const updateProductInput = {
      id: productData.id,
      name: productData.name,
      productType: productData?.productType?.id,
      printerProfiles: productData?.printerProfiles,
      barcode: productData.barcode,
      sku: productData.sku,
      plu: productData.plu,
      gtin: productData.gtin,
      isSellable: productData.isSellable,
      description: productData.description,
      measuredBy: {
        units: productData.measuredBy?.units || '',
        unitType: productData.measuredBy?.unitType || UnitType.Units,
        defaultSize: productData.measuredBy?.defaultSize || 1,
      },
      dietaryTags: (productData.dietaryTags || []).map(t => t.id),
      alternateNames: encodeAlternateNameValuesToBase64(
        productData.alternateNames || [],
      ),
    } as unknown as UpdateProductInput;

    updateProduct(updateProductInput);

    if (productData?.imageRawData) {
      uploadProductImage(
        {
          base64: productData.imageRawData.base64,
          name: productData.imageRawData.name,
          type: productData.imageRawData.type,
        } as ImageUploadInput,
        productData.id,
      );
    }
  }, [productData, uploadProductImage, updateProduct]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <GeneralForm
      isProduct
      isVariantProduct={isVariantProduct}
      onAddTag={onAddTag}
      onChange={onChange}
      onChangeMeasures={onChangeMeasures}
      onChangeAlternateName={onChangeAlternateName}
      onSave={onSaveProduct}
      productData={productData}
      dietaryTagsOptions={dietaryTagsArray}
      productTypeOptions={productTypeOptions}
      printerProfileOptions={printerProfileOptions}
    />
  );
};
