import { useEffect } from 'react';
import { userUtility } from '../../state/userUtility';
import { useNavigation } from '@react-navigation/native';
import { navigateToLockScreen } from '../../state/navigation';
import { currentOrderActionObservable } from './orders/ordersObservableUtils';
import useBehaviorSubjectState from './useSubjectState';
import { OrderAction } from '@hitz-group/domain';
import { postSalesObservableForLogin } from './usePostSalesNavigation';

export const useSwitchPosUserEffect = () => {
  const navigation = useNavigation();
  const { value: currentOrderAction } = useBehaviorSubjectState(
    currentOrderActionObservable,
  );
  const { value: loginFlowTrigged } = useBehaviorSubjectState(
    postSalesObservableForLogin,
  );

  useEffect(() => {
    if (
      loginFlowTrigged &&
      (currentOrderAction?.isSyncComplete ||
        currentOrderAction?.lastOrderAction == OrderAction.ORDER_SAVE)
    ) {
      postSalesObservableForLogin.next(false);
      userUtility.clearPosUser();
      navigation.reset(navigateToLockScreen());
    }
  }, [currentOrderAction, loginFlowTrigged, navigation]);
};
