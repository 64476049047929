import { StyleFn, Product, OptionValue } from '@hitz-group/domain';
import React, { useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import { VariantOptionsRow } from './VariantOptionsRow';
import { useProducts } from '../../../../../hooks/app/products/useProducts';

const backOfficeContentStyle: StyleFn = () => ({
  width: 545,
  zIndex: 10,
});

const scrollContentWrapper: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  alignItems: 'center',
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

const backOfficeContainerStyle: StyleFn = ({ theme, zIndex }) => ({
  paddingTop: theme.padding.medium,
  zIndex: zIndex,
});

interface VariantSettingsForVariantProductProps {
  variantProductId: string;
}

export const VariantSettingsForVariantProduct: React.FC<
  VariantSettingsForVariantProductProps
> = ({ variantProductId }) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [variant, setVariant] = useState({} as Product);
  const { products, error: prodErr } = useProducts(variantProductId);
  const error = prodErr;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (variantProductId && products && products[variantProductId]) {
      const variantTemp = products[variantProductId];
      setVariant({
        ...variantTemp,
      });
    }
  }, [variantProductId, products]);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.variantSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(scrollContentWrapper)}>
          <BackOfficeSection
            contentContainerStyle={css(backOfficeContentStyle)}
            containerStyle={css(
              backOfficeContainerStyle({ theme, zIndex: 100 }),
            )}
            title={translate('productSettings.attributes')}
            titleDescription={translate(
              'productSettings.attributesDescription',
            )}
            titleBorderBottom
          >
            <TableComponent
              columns={[
                {
                  title: translate('productSettings.attribute'),
                  width: 150,
                },
                {
                  title: translate('productSettings.values'),
                  width: 283,
                },
                { title: '', width: 30 },
                { title: '', width: 30 },
              ]}
              data={variant.optionValues || []}
              renderRow={(
                item: OptionValue,
                index: number,
              ): React.ReactNode => {
                const { parentOptionId, ...otherProps } = item;
                const itemOption = { id: parentOptionId, ...otherProps };
                return (
                  <VariantOptionsRow
                    allKeys={[]}
                    selectedOption={{ ...itemOption, values: [item.value] }}
                    allValues={[item.value]}
                    key={index}
                    index={index}
                    isDisabled
                  />
                );
              }}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>
    </>
  );
};
