import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';
import { isWeb } from '../../../../../../common/theme';

export const DeviceDetailsStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        generalSettingsContainerWrapper: {
          width: 545,
          alignSelf: 'center',
          margin: 'auto',
          backgroundColor: theme.colors.white,
        },
        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
        },
        saveButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginLeft: 'auto',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
          alignSelf: 'auto',
        },
        deleteButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginRight: 'auto',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.danger2,
          alignSelf: 'auto',
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
        dropDownLabelText: {
          left: 10,
          marginLeft: isWeb ? theme.spacing.small : 0,
        },
        deviceView: {
          marginTop: theme.spacing.medium,
          marginLeft: theme.spacing.small,
        },
        deviceCodeContainer: {
          borderRadius: 10,
          backgroundColor: theme.colors.greyLight,
        },
        deviceCodeText: {
          padding: 15,
          fontSize: 16,
          fontWeight: 'bold',
          lineHeight: 20,
          textAlign: 'center',
          color: theme.colors.grey2,
        },
        deviceCodeSectionStyle: {
          alignItems: 'center',
          width: '100%',
          paddingVertical: 30,
        },
        deviceCodeNote: {
          fontSize: 14,
          marginTop: 10,
          fontWeight: '500',
          lineHeight: 20,
          letterSpacing: -0.4,
          textAlign: 'center',
          color: theme.colors.text,
        },
        deviceCodeNoteUrl: {
          fontSize: 14,
          marginTop: 10,
          fontWeight: '500',
          lineHeight: 20,
          letterSpacing: -0.4,
          textAlign: 'center',
          color: theme.colors.blue,
        },
        labelStyle: {
          marginBottom: theme.spacing.medium,
          textTransform: 'none',
          ...theme.font14RegularDarkGrey,
        },
        textStyle: {
          marginBottom: theme.spacing.big,
          textTransform: 'none',
          ...theme.font14RegularCharcoal,
        },
        dangerTitleStyle: {
          color: theme.colors.danger,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
          letterSpacing: -0.5,
        },
        checkIconContainer: {
          borderColor: theme.colors.green,
          borderWidth: 2,
          marginRight: theme.spacing.big / 2,
          height: 17,
          width: 17,
        },
        partialCheckIconContainer: {
          borderColor: theme.colors.blue,
          borderWidth: 2,
          marginRight: theme.spacing.big / 2,
          height: 17,
          width: 17,
        },
        unCheckContainer: {
          borderColor: theme.colors.paragraphLight,
          borderWidth: 2,
          marginRight: theme.spacing.big / 2,
          height: 17,
          width: 17,
        },
        accordionContainerStyle: {
          paddingVertical: theme.spacing.small,
          borderBottomColor: theme.colors.boxBorder,
          borderBottomWidth: 1,
        },
        accordionTitleContainerStyle: { flex: 1, flexDirection: 'row' },
        childContainerStyle: {
          marginTop: theme.spacing.big / 2,
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: 545,
          justifyContent: 'space-between',
        },
        checkBoxContainerStyle: {
          flex: 1,
          justifyContent: 'flex-start',
          width: 100,
        },
        assignedCountStyle: {
          alignSelf: 'flex-end',
          marginRight: 35,
          margin: 'auto',
        },
        productsContainerStyle: {
          justifyContent: 'flex-start',
          width: 260,
        },
        loadMoreWrapperStyle: {
          width: '100%',
          marginVertical: theme.spacing.medium,
        },
        loadMoreContainerStyle: {
          justifyContent: 'center',
          alignSelf: 'center',
          backgroundColor: theme.colors.successLight,
          width: 100,
          height: theme.input.height,
        },
        loadMoreLabelStyle: {
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.small,
          textTransform: 'none',
          color: theme.colors.paragraph,
        },
        searchContainerStyle: {
          width: '100%',
          height: theme.input.height,
          justifyContent: 'center',
          backgroundColor: theme.colors.white,
          paddingLeft: theme.spacing.small / 2,
          borderColor: theme.colors.boxBorder,
          borderWidth: 1,
          marginBottom: theme.spacing.medium,
        },
        searchTextInputStyle: {
          width: '100%',
          height: theme.input.height,
          color: theme.colors.paragraph,
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.small,
        },
        formContainerStyleSmall: {
          width: 545,
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        },
        formInputContainerStyle: {
          width: 260,
          height: theme.input.height,
          marginBottom: theme.spacing.big / 2,
        },
        dropdownContainerStyle: {
          marginTop: theme.spacing.small,
          width: 260,
          height: theme.input.height,
        },
        popOverStyle: {
          width: 280,
        },
        registerDropdownContainerStyle: {
          marginTop: isWeb ? theme.spacing.small : 15,
          width: 260,
          height: theme.input.height,
          zIndex: 900,
        },
        dropDownItemsContainerStyle: {
          height: 38 * 3,
        },
        checkBoxTitleStyle: {
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.small,
          textTransform: 'none',
        },
        deviceDetailsSectionStyle: {
          width: '100%',
          alignSelf: 'center',
          maxWidth: 545,
          marginTop: theme.spacing.big,
          marginBottom: theme.spacing.medium,
          lineHeight: theme.spacing.big,
          zIndex: -999,
        },
        assignProductsSectionStyle: {
          marginTop: theme.spacing.small,
          marginBottom: theme.spacing.medium,
        },
        formLabelStyle: {
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.small,
          paddingHorizontal: theme.spacing.small,
        },
        buttonIconStyle: {
          color: theme.colors.success,
        },
        checkIconStyle: {
          color: theme.colors.success,
        },
        partialCheckIconStyle: {
          color: theme.colors.blue,
        },
        unCheckIconStyle: {
          color: theme.colors.paragraph,
        },
      }),
    [theme],
  );
};
