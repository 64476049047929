import { useLazyQuery } from '@apollo/client/react/hooks';
import { PrinterTemplate } from '@hitz-group/domain';
import { isLoading, getError } from '../../utils/apolloErrorResponse.util';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { useState, useCallback, useMemo } from 'react';
import {
  GET_PRINTER_TEMPLATE,
  GET_PRINTER_TEMPLATES,
} from '../../graphql/printerTemplates';

export interface UsePrinterTemplates {
  printerTemplates: Array<PrinterTemplate>;
  loading: boolean;
  error: string | undefined;
  getPrinterTemplates: () => void;
  printerTemplate?: PrinterTemplate;
  getPrinterTemplate: (templateId: string) => void;
}

/**
 * Used to get the list of printer templates available under a organization
 */
export function usePrinterTemplates(): UsePrinterTemplates {
  const [printerTemplates, setPrinterTemplates] = useState<
    Array<PrinterTemplate>
  >([]);

  const [printerTemplate, setPrinterTemplate] = useState<PrinterTemplate>();

  const getAllPrinterTemplatesResReceived = useCallback(data => {
    if (data) {
      setPrinterTemplates(data.printerTemplates);
    }
  }, []);

  const getAllPrinterTemplateResReceived = useCallback(data => {
    if (data) {
      setPrinterTemplate(data.printerTemplate);
    }
  }, []);

  const [getPrinterTemplates, getAllPrinterTemplatesRes] = useLazyQuery(
    GET_PRINTER_TEMPLATES,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: getAllPrinterTemplatesResReceived,
    },
  );

  const [getPrinterTemplateReq, getPrinterTemplateRes] = useLazyQuery(
    GET_PRINTER_TEMPLATE,
    {
      fetchPolicy: 'cache-and-network',
      onError: noopHandler,
      onCompleted: getAllPrinterTemplateResReceived,
    },
  );

  /**
   * get all printer templates in a organization
   *
   */

  /**
   * Get printer template by id
   */
  const getPrinterTemplate = useCallback(
    (templateId: string) => {
      getPrinterTemplateReq({
        variables: {
          id: templateId,
        },
      });
    },
    [getPrinterTemplateReq],
  );

  const RESPONSE_ENTITIES = [getAllPrinterTemplatesRes, getPrinterTemplateRes];

  const loading = isLoading(RESPONSE_ENTITIES);
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      printerTemplate,
      getPrinterTemplates,
      loading,
      error: error ? parseApolloError(error) : undefined,
      printerTemplates,
      getPrinterTemplate,
    }),
    [
      loading,
      error,
      getPrinterTemplates,
      printerTemplates,
      getPrinterTemplate,
      printerTemplate,
    ],
  );
}
