import { gql } from '@apollo/client';
import { orderEventFragment } from './subscriptions';

export const GET_EVENTS_BY_STORE_QUERY = gql`
  query events($filter: EventsFilterInput!) {
    events(filter: $filter) {
      ${orderEventFragment}
    }
  }
`;
