import { StyleFn } from '@hitz-group/domain';
import { translate } from '@hitz-group/localization';
import { useNotification } from '../../../../hooks/Notification';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import Button from '../../../../components/Button/Button';
import IconButton from '../../../../components/Button/IconButton';
import useBehaviorSubjectState from '../../../../hooks/app/useSubjectState';
import {
  tableActionController,
  sectionStatsVisibilityController,
  TableAction,
} from './floorViewObservables';

const dividerStyle: StyleFn = ({ theme }) => ({
  width: 1,
  backgroundColor: theme.colors.boxBorder,
});

const Divider: React.FC = () => {
  const { css } = useFela();
  return <View style={css(dividerStyle)} />;
};

const containerStyle: StyleFn = ({ theme }) => ({
  borderBottomWidth: 1,
  borderColor: theme.colors.boxBorder,
  borderTopRightRadius: theme.radius.medium,
  borderTopLeftRadius: theme.radius.medium,
  paddingVertical: theme.padding.small,
  paddingHorizontal: theme.spacing.small,
  flexDirection: 'row',
});

const newOrderLabelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Medium,
  color: theme.colors.success,
});
const buttonStyle: StyleFn = ({ theme, highlight }) => ({
  paddingVertical: 12,
  paddingHorizontal: theme.spacing.medium,
  backgroundColor: highlight ? theme.colors.info : theme.colors.white,
});
const buttonLabelStyle: StyleFn = ({ theme, highlight }) => ({
  color: highlight ? theme.colors.white : theme.colors.black,
});
const iconButtonStyle: StyleFn = ({ theme, highlight }) => ({
  paddingHorizontal: theme.spacing.medium,
  borderRadius: theme.radius.small,
  backgroundColor: highlight ? theme.colors.info : theme.colors.white,
});

interface FloorViewActionsProps {
  onPressNewOrder: () => void;
}

const FloorViewActions: React.FC<FloorViewActionsProps> = ({
  onPressNewOrder,
}) => {
  const { css, theme } = useFela();
  const { value: isSectionStatsPanelVisible, setValue: setSectionStatsPanel } =
    useBehaviorSubjectState<boolean>(sectionStatsVisibilityController);
  const { value: tableAction, setValue: setTableAction } =
    useBehaviorSubjectState<TableAction>(tableActionController);
  const { showNotification } = useNotification();

  const onClickTableInfoButton = useCallback(() => {
    if (tableAction === TableAction.SHOW_TABLE_STATS) {
      setTableAction(TableAction.DEFAULT);
    } else {
      setTableAction(TableAction.SHOW_TABLE_STATS);
    }
  }, [setTableAction, tableAction]);

  const onClickTablePrint = useCallback(() => {
    if (tableAction === TableAction.PRINT) {
      setTableAction(TableAction.DEFAULT);
    } else {
      setTableAction(TableAction.PRINT);
    }
  }, [setTableAction, tableAction]);

  const onClickTablePay = useCallback(() => {
    if (tableAction === TableAction.PAY) {
      setTableAction(TableAction.DEFAULT);
    } else {
      setTableAction(TableAction.PAY);
    }
  }, [setTableAction, tableAction]);

  const showSectionStatsPanel = useCallback(() => {
    setTableAction(TableAction.DEFAULT);
    setSectionStatsPanel(true);
  }, [setSectionStatsPanel, setTableAction]);

  const transferTableSection = useCallback(() => {
    if (tableAction === TableAction.TRANSFER) {
      setTableAction(TableAction.DEFAULT);
    } else {
      setTableAction(TableAction.TRANSFER);
      showNotification({
        info: true,
        message: translate('tableFloorView.transferTableMessage'),
      });
    }
  }, [setTableAction, showNotification, tableAction]);

  const mergeTableSelection = useCallback(() => {
    if (tableAction === TableAction.MERGE) {
      setTableAction(TableAction.DEFAULT);
    } else {
      setTableAction(TableAction.MERGE);
      showNotification({
        info: true,
        message: translate('tableFloorView.mergeTableActionMessage'),
      });
    }
  }, [setTableAction, showNotification, tableAction]);

  return (
    <View style={css(containerStyle)}>
      <Button
        testID="section-print"
        containerStyle={[
          css(
            buttonStyle({
              theme,
              highlight: tableAction === TableAction.PRINT,
            }),
          ),
        ]}
        size="small"
        title={translate('tableFloorView.print')}
        onPress={onClickTablePrint}
        labelStyle={css(
          buttonLabelStyle({
            theme,
            highlight: tableAction === TableAction.PRINT,
          }),
        )}
      />
      <Button
        testID="section-pay"
        containerStyle={[
          css(
            buttonStyle({
              theme,
              highlight: tableAction === TableAction.PAY,
            }),
          ),
        ]}
        size="small"
        title={translate('tableFloorView.pay')}
        onPress={onClickTablePay}
        labelStyle={css(
          buttonLabelStyle({
            theme,
            highlight: tableAction === TableAction.PAY,
          }),
        )}
      />
      <Button
        testID="section-stats-button"
        containerStyle={[
          css(buttonStyle({ theme, highlight: isSectionStatsPanelVisible })),
        ]}
        size="small"
        title={translate('tableFloorView.stats')}
        labelStyle={css(
          buttonLabelStyle({ theme, highlight: isSectionStatsPanelVisible }),
        )}
        onPress={showSectionStatsPanel}
      />
      <Divider />
      <Button
        testID="section-transfer-button"
        containerStyle={[
          css(
            buttonStyle({
              theme,
              highlight: tableAction === TableAction.TRANSFER,
            }),
          ),
        ]}
        size="small"
        title={translate('tableFloorView.transfer')}
        labelStyle={css(
          buttonLabelStyle({
            theme,
            highlight: tableAction === TableAction.TRANSFER,
          }),
        )}
        onPress={transferTableSection}
      />
      <Button
        testID="section-merge-button"
        containerStyle={[
          css(
            buttonStyle({
              theme,
              highlight: tableAction === TableAction.MERGE,
            }),
          ),
        ]}
        size="small"
        title={translate('tableFloorView.merge')}
        labelStyle={css(
          buttonLabelStyle({
            theme,
            highlight: tableAction === TableAction.MERGE,
          }),
        )}
        onPress={mergeTableSelection}
      />
      <Divider />
      <IconButton
        testID="table-stats-button"
        containerStyle={css(
          iconButtonStyle({
            theme,
            highlight: tableAction === TableAction.SHOW_TABLE_STATS,
          }),
        )}
        icon="InfoCircle"
        iconColor={
          tableAction === TableAction.SHOW_TABLE_STATS
            ? theme.colors.white
            : theme.colors.grey2
        }
        iconSize={18}
        onPress={onClickTableInfoButton}
      />
      <Divider />
      <Button
        containerStyle={css(buttonStyle)}
        size="small"
        title={translate('tableFloorView.newOrder')}
        labelStyle={css(newOrderLabelStyle)}
        onPress={onPressNewOrder}
      />
    </View>
  );
};

export default FloorViewActions;
