import React from 'react';
import { Text, View } from 'react-native';
import { Store } from '@hitz-group/domain';
import { useNavigation } from '@react-navigation/native';
import TableRow from '../../../../components/TableComponent/TableRow';
import IconButton from '../../../../components/Button/IconButton';
import { StoresListRowStyles } from './VenuesScreen.styles';
import { useFela } from 'react-fela';

interface StoreRowProps {
  store: Store;
  venueId: string;
}

export const StoreRow: React.FC<StoreRowProps> = ({
  store,
  venueId,
}: StoreRowProps) => {
  const navigation = useNavigation();
  const styles = StoresListRowStyles();
  const { theme } = useFela();
  return (
    <View style={styles.rowViewStyle}>
      <View style={styles.statusViewStyle}></View>
      <TableRow
        containerStyle={styles.storeRowContainerStyle}
        normalRows={true}
      >
        <View style={styles.nameViewStyle}>
          <Text style={styles.nameContainerStyle}>{store.name}</Text>
        </View>
        <View style={styles.actionsViewStyle}>
          <IconButton
            icon="AngleRight"
            iconSize={24}
            containerSize={34}
            iconStyle={styles.navigationIconStyle}
            iconColor={theme.colors.paragraph}
            containerStyle={styles.navigationContainerStyle}
            onPress={(): void =>
              navigation.navigate('StoreSettings', {
                storeId: store.id,
                venueId,
                title: store.name,
              })
            }
          />
        </View>
      </TableRow>
    </View>
  );
};
