import {
  StyleFn,
  CataloguePageInput,
  UpdateCatalogueInput,
  DEFAULT_ENTITY_ID,
  STANDARD_MENU,
} from '@hitz-group/domain';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../../components/FormInput/FormInput';
import { Helmet } from 'react-helmet';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation } from '@hitz-group/localization';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import scale, { isAndroid } from '../../../../../common/theme';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import { usePages } from '../../../../../hooks/app/pages/usePages';
import { useMenus } from '../../../../../hooks/app/menus/useMenus';
import { PageRow } from './PageRow';
import Button from '../../../../../components/Button/Button';
import { Operation } from '../../../../../types/Operation';
import { useRoute } from '@react-navigation/native';
import { omit } from 'lodash';
import DraggableFlatList from '../../../../../components/DraggableFlatList/DraggableFlatList';
import { useNavigation } from '@react-navigation/native';
import { useVenues } from '../../../../../hooks/app/useVenues';

const scrollStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  paddingTop: theme.spacing.big + theme.spacing.small / 2,
});
export const formStyle: StyleFn = () => ({
  width: 540,
});

const detailsFormStyle: StyleFn = ({ theme }) => ({
  width: 545,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: theme.spacing.small,
});

const storeDetailsformStyle: StyleFn = () => ({
  width: 540,
  marginTop: -50,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(40),
});
const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 540,
  alignSelf: 'center',
});

const formLabelStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  paddingHorizontal: theme.spacing.small,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  height: theme.input.height,
  marginBottom: theme.spacing.big / 2,
});

const textStyle: StyleFn = () => ({
  marginTop: isAndroid ? -6 : 0,
});

const multipleDropDownStyle: StyleFn = ({ theme }) => ({
  width: 540,
  height: theme.input.height,
  justifyContent: 'center',
  marginTop: 20,
  marginBottom: theme.spacing.big,
});

const groupNameStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.medium,
  marginRight: 0,
});

const pagesContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const venueDropDownStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  height: theme.input.height,
  marginTop: isAndroid ? theme.padding.small + 2 : 0,
});

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  width: 545,
  marginTop: theme.spacing.big + theme.spacing.medium,
  zIndex: 5,
});

const storeDropDownStyle: StyleFn = () => ({
  minHeight: 38,
  width: 540,
});

const touchableStyle: StyleFn = ({ theme }) => ({
  minHeight: theme.input.height,
  flexWrap: 'wrap',
});

export const CreateMenu: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const route = useRoute();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const [menuData, setMenuData] = useState<UpdateCatalogueInput>({
    id: '',
    name: '',
    pages: [],
    venues: [],
    stores: [],
  });
  const { error: pageErr, loading: pagesLoading, getPages, pages } = usePages();
  const {
    operation,
    createMenu,
    error: menuErr,
    loading: menusLoading,
    getMenu,
    menus,
    updateMenu,
    createdMenu,
  } = useMenus();
  const { getVenues, venues } = useVenues();
  const loading = pagesLoading || menusLoading;
  const error = pageErr || menuErr;
  const menuId = (route.params as { menuId: string })?.menuId;

  const isCreated = !error && !loading && operation === Operation.CREATE;

  useEffect(() => {
    getPages();
    getVenues();
  }, [getPages, getVenues]);

  const venuesDetailsArray = useMemo(() => Object.values(venues), [venues]);

  const venueOptions = useMemo(() => {
    return venuesDetailsArray.map(x => ({ label: x.name, value: x.id }));
  }, [venuesDetailsArray]);

  const selectedVenueStoreOptions = useMemo(() => {
    const storeOptions: { value: string; label: string }[] = [];
    venuesDetailsArray
      .filter(x => menuData.venues?.includes(x.id))
      .forEach(x => {
        x.stores.forEach(eachStore => [
          storeOptions.push({
            value: eachStore.id,
            label: eachStore.name,
          }),
        ]);
      });
    return storeOptions;
  }, [venuesDetailsArray, menuData]);

  useEffect(() => {
    menuId && menuId !== DEFAULT_ENTITY_ID && getMenu(menuId);
  }, [menuId, getMenu]);

  useEffect(() => {
    if (menuId && menus[menuId]) {
      // update the tab title
      navigation.setOptions({
        tabBarLabel: menus[menuId].name,
      });
    }
  }, [navigation, menuId, menus]);

  const addVariantsDetailsToPage = useCallback(
    (pageToAdd: CataloguePageInput) => {
      const tempPage = { ...pageToAdd };
      // if variants exist in page
      if (pages?.[tempPage?.id]?.variants?.length) {
        const variantsData: { id: string; priority: number }[] = [];

        pages?.[tempPage?.id]?.variants?.forEach((eachVar, indexOfVar) => {
          // adding variants
          variantsData.push({
            id: eachVar.id,
            priority: indexOfVar,
          });
        });
        tempPage.variants = variantsData;
      }
      return tempPage;
    },
    [pages],
  );

  const onChangePages = useCallback(
    (pageIds: string[]) => {
      setMenuData(prev => {
        const tempPrev = { ...prev };
        tempPrev.pages = pageIds.map((pageId, index) => {
          let pageToAdd = {
            priority: index,
            id: pageId,
            products: (pages?.[pageId]?.products || []).map(
              (eachProd, indexOfProd) => ({
                id: eachProd.id,
                priority: indexOfProd,
              }),
            ),
            pages: (pages?.[pageId]?.pages || []).map(
              (eachPage, indexOfPage) => ({
                id: eachPage.id,
                priority: indexOfPage,
              }),
            ),
          } as CataloguePageInput;

          pageToAdd = addVariantsDetailsToPage(pageToAdd);

          return pageToAdd;
        }) as CataloguePageInput[];
        return { ...tempPrev };
      });
    },
    [pages, addVariantsDetailsToPage],
  );

  useEffect(() => {
    // if menu data loads set to state
    if (menuId && menus?.[menuId]) {
      const menuData = menus?.[menuId];
      const menuDataInput = {
        id: menuData.id,
        name: menuData.name,
        pages: [],
        venues: [],
        stores: [],
      } as UpdateCatalogueInput;
      if (menuData.itemGroups?.length) {
        menuData.itemGroups.forEach(eachItemGroup => {
          const pageDetails = eachItemGroup?.page;
          let pageToAdd = {} as CataloguePageInput;
          pageToAdd = {
            priority: 0,
            id: pageDetails.id,
            products: (pages?.[pageDetails?.id]?.products || []).map(
              (eachProd, indexOfProd) => ({
                id: eachProd.id,
                priority: indexOfProd,
              }),
            ),
            pages: (pages?.[pageDetails?.id]?.pages || []).map(
              (eachPage, indexOfPage) => ({
                id: eachPage.id,
                priority: indexOfPage,
              }),
            ),
          } as CataloguePageInput;

          // if variants exist in page
          pageToAdd = addVariantsDetailsToPage(pageToAdd);
          menuDataInput.pages?.push(pageToAdd);
        });
      }
      menuDataInput.venues = (menuData?.venues || []).map(x => x && x.id);
      menuDataInput.stores = (menuData?.stores || []).map(x => x && x.id);
      setMenuData(menuDataInput);
    }
  }, [menuId, menus, pages, addVariantsDetailsToPage]);

  const isStandardMenu = useMemo(
    () => menus?.[menuId]?.name === STANDARD_MENU,
    [menuId, menus],
  );

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (isCreated && createdMenu) {
      showNotification({
        success: true,
        message: translate('menus.menuCreatedSuccessfully'),
      });
      navigation.navigate('CreateMenu', {
        menuId: createdMenu,
      });
    }
  }, [isCreated, createdMenu, navigation, showNotification, translate]);

  useEffect(() => {
    if (!error && !loading && operation === Operation.UPDATE) {
      showNotification({
        success: true,
        message: translate('menus.menuUpdatedSuccessfully'),
      });
    }
  }, [error, showNotification, loading, operation, translate]);

  const selectedPages = useMemo(() => {
    return (menuData?.pages || []).map(x => x.id);
  }, [menuData]);

  const pagesOptions = useMemo(() => {
    return Object.values(pages).map(x => ({ value: x.id, label: x.name }));
  }, [pages]);

  const onChangeName = useCallback((name: string) => {
    setMenuData(prev => ({ ...prev, name }));
  }, []);

  const getPageDetails = useCallback(
    (pageItems: CataloguePageInput) => {
      // sub-pages will be implemented after adding pages to page implemented
      let count = pages?.[pageItems.id]?.products?.length || 0;
      const subPages: string[] = [];
      pageItems?.pages?.forEach(eachPage => {
        subPages.push(eachPage && pages?.[eachPage?.id]?.name);
        count = count + (pages?.[eachPage?.id]?.products?.length || 0);
      });

      if (pages?.[pageItems?.id]?.variants?.length) {
        // adding variant products
        const variantsInPage = pages?.[pageItems?.id]?.variants;
        variantsInPage?.forEach(eachVar => {
          count = count + eachVar.products?.length;
        });
      }
      return {
        count,
        subPages: subPages.join(','),
        name: pages?.[pageItems.id]?.name || '',
      };
    },
    [pages],
  );

  const onSaveCatalog = useCallback(() => {
    if (!menuData?.name?.trim()) {
      showNotification({
        error: true,
        message: translate('menus.pleaseEnterMenuName'),
      });
    }
    // save
    else if (!menuData?.id) {
      // create
      createMenu(omit(menuData, 'id'));
    } else {
      // update
      updateMenu(menuData);
    }
  }, [createMenu, menuData, updateMenu, showNotification, translate]);

  const onDragEnd = useCallback(
    (data: Array<CataloguePageInput>) => {
      setMenuData({
        ...menuData,
        pages: data.map((pageRow, index) => ({ ...pageRow, priority: index })),
      });
    },
    [menuData],
  );

  const onChangeVenue = useCallback(
    selectedvalues => {
      const storeOptions: string[] = [];
      venuesDetailsArray
        .filter(x => selectedvalues?.includes(x.id))
        .forEach(x => {
          x.stores.forEach(eachStore => {
            storeOptions.push(eachStore.id);
          });
        });
      setMenuData(prev => ({
        ...prev,
        venues: selectedvalues,
        stores: storeOptions,
      }));
    },
    [venuesDetailsArray],
  );

  const onChangeStore = useCallback(selectedvalues => {
    setMenuData(prev => ({ ...prev, stores: selectedvalues }));
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('menus.menuSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView style={css(scrollStyle)}>
        <View style={css(pageStyle)}>
          <BackOfficeSection
            title={translate('menus.title')}
            titleBorderBottom
            contentContainerStyle={css(detailsFormStyle)}
            containerStyle={css(backOfficeContainerStyle)}
          >
            <FormInput
              testID="organization-name"
              title={translate('menus.menuName')}
              placeholder={translate('menus.menuName')}
              value={menuData.name}
              alignTitle="left"
              containerStyle={css(formInputContainerStyle)}
              textStyle={css(formLabelStyle)}
              onChangeText={(!isStandardMenu && onChangeName) || undefined}
            />
            <MultipleSelect
              selectedValues={menuData.venues}
              containerStyle={css(venueDropDownStyle)}
              values={venueOptions}
              searchPlaceHolder={''}
              searchLabel={''}
              label={translate('menus.venues')}
              textStyle={css(textStyle)}
              onValueChange={onChangeVenue}
              touchableStyle={css(touchableStyle)}
            />
          </BackOfficeSection>

          <BackOfficeSection contentContainerStyle={css(storeDetailsformStyle)}>
            <MultipleSelect
              selectedValues={menuData.stores}
              containerStyle={css(storeDropDownStyle)}
              values={selectedVenueStoreOptions}
              searchPlaceHolder={''}
              searchLabel={''}
              label={translate('menus.stores')}
              onValueChange={onChangeStore}
              touchableStyle={css(touchableStyle)}
            />
          </BackOfficeSection>

          <BackOfficeSection
            title={translate('menus.displayPreference')}
            titleDescription={translate('menus.displayPreferenceDesc')}
            titleBorderBottom
            contentContainerStyle={css(formStyle)}
          >
            <MultipleSelect
              label={translate('menus.selectPagesToShowInMenu')}
              values={pagesOptions}
              selectedValues={selectedPages}
              containerStyle={css(multipleDropDownStyle)}
              onValueChange={onChangePages}
              searchLabel={translate('menus.selectPage')}
              searchPlaceHolder={translate('menus.searchPages')}
              maxItems={3}
            />
            {(selectedPages.length && (
              <DraggableFlatList
                columns={[
                  {
                    title: translate('modifiers.order'),
                    width: 40,
                  },
                  {
                    title: translate('menus.pagesColumn'),
                    width: 200,
                    alignItems: 'flex-start',
                    containerStyle: css(groupNameStyle),
                  },
                  {
                    title: translate('backOfficeProducts.products'),
                    alignItems: 'flex-start',
                    width: 70,
                  },
                  {
                    title: translate('menus.subPageColumn'),
                    alignItems: 'flex-start',
                    width: 200,
                    containerStyle: css(groupNameStyle),
                  },
                ]}
                data={menuData.pages}
                normalRows
                containerStyle={css(pagesContainerStyle)}
                onDragEnd={(data: Array<CataloguePageInput>) => onDragEnd(data)}
                renderRow={(
                  item: CataloguePageInput,
                  index: number,
                  drag: () => void,
                ): React.ReactNode => {
                  const { subPages, count, name } = getPageDetails(item);
                  return (
                    <PageRow
                      page={{
                        name,
                        productCount: count,
                        subPages: subPages,
                      }}
                      rowIndex={index}
                      drag={drag}
                    />
                  );
                }}
              />
            )) ||
              null}
          </BackOfficeSection>
        </View>
        <View style={css(bottomSpace)}></View>
      </ScrollView>
      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onSaveCatalog}
          />
        </View>
      </View>
    </>
  );
};
