import { StyleFn } from '@hitz-group/domain';

export const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

export const pageContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  height: '100%',
});

export const container: StyleFn = () => ({
  flex: 1,
  flexWrap: 'wrap',
  flexDirection: 'row',
});

export const pageContainer: StyleFn = ({ theme }) => ({
  flex: 1,
  paddingHorizontal: theme.padding.large,
  paddingVertical: theme.padding.large,
  backgroundColor: theme.colors.white,
});
