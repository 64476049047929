import { InMemoryCache } from '@apollo/client/core';

let cache: InMemoryCache | undefined;
export const getApolloCache = (): InMemoryCache => {
  if (!cache) {
    cache = new InMemoryCache({
      typePolicies: {
        Page: {
          keyFields: false,
        },
        PricingGroup: {
          keyFields: false,
        },
        ProductModifierGroup: {
          keyFields: false,
        },
        Modifier: {
          keyFields: ['id', 'price', 'isDefault'],
        },
        OrderItemModifier: {
          keyFields: ['id', 'modifierGroupId', 'quantity'],
        },
        ModifierPricingGroup: {
          keyFields: false,
        },
        OrderItem: {
          keyFields: ['id', 'status'],
        },
        CatalogueItem: {
          keyFields: ['id', 'priority', 'type'],
        },
        Order: {
          fields: {
            isEdited: {
              read(isEdited = false) {
                return isEdited;
              },
            },
            lastSyncedEventId: {
              read(cachedValue, { readField }) {
                return cachedValue || readField('prevEventId') || 'unknown';
              },
              merge(existing, incoming, { readField }) {
                return (
                  incoming || existing || readField('prevEventId') || 'unknown'
                );
              },
            },
          },
        },
        OrderTable: {
          keyFields: false,
        },
      },
    });
  }
  return cache;
};
