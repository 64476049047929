import { StyleFn, CreateTaxInput } from '@hitz-group/domain';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useFela } from 'react-fela';
import FormInput from '../../../components/FormInput/FormInput';
import { CREATE_TAX } from '../../../graphql/settings';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useMutation } from '@apollo/client/react/hooks';
import { useTranslation } from '@hitz-group/localization';
import { Tax } from '@hitz-group/domain';
import Button from '../../../components/Button/Button';
import MultipleSelect, {
  MultipleSelectOption,
} from '../../../components/MultipleSelect/MultipleSelect';
import keyBy from 'lodash/keyBy';
import Modal from '../Modal';
import scale from '../../../common/theme';

interface TaxToTax {
  id: string;
  name: string;
  code: string;
  rate?: string;
  isActive?: boolean;
  taxes: Tax[];
}

const modalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginTop: theme.spacing.medium,
  marginLeft: 0,
});

const formTextStyle: StyleFn = ({ theme }) => ({
  alignSelf: 'flex-start',
  paddingHorizontal: theme.padding.medium * 1.5,
});

const createLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const formInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: scale.textInputWidth180,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

const selectContainerStyle: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.medium * 1.5,
  minHeight: 30,
  height: theme.input.height,
  width: scale.textInputWidth180,
  paddingLeft: scale.moderateScale(5),
  marginTop: scale.moderateScale(7),
});

const touchableStyle: StyleFn = () => ({
  bottom: 15,
  left: 10,
});

interface CreateGroupTaxModalProps {
  onAddTax: (tax: TaxToTax) => void;
  taxesWithoutGroupTax: { label: string; value: string }[];
  taxes: TaxToTax[];
}

export const CreateGroupTaxModal: React.FC<CreateGroupTaxModalProps> = ({
  taxes,
  taxesWithoutGroupTax,
  onAddTax,
}: CreateGroupTaxModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [createForm, setCreateForm] = useState({
    assignedTaxes: taxesWithoutGroupTax,
  } as {
    name: string;
    code: string;
    rate: string;
    assignedTaxes: MultipleSelectOption[];
    selectedTaxes: string[];
  });
  const { showNotification } = useNotification();
  const taxesDictionary = useMemo(() => {
    return keyBy(taxes, 'id');
  }, [taxes]);

  const onChangeTax = useCallback((prop: string, value: string) => {
    setCreateForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onChangeGroupTax = useCallback(
    (prop: string, value: string[]) => {
      setCreateForm({
        ...createForm,
        [prop]: value,
      });
    },
    [createForm],
  );

  const [createTax, createOperation] = useMutation(CREATE_TAX, {
    onError: noopHandler,
  });

  const calculatedTax = useMemo(() => {
    let value = 0;
    (createForm.selectedTaxes || []).map(taxId => {
      const tax = taxesDictionary[taxId];
      value = value + parseFloat(tax?.rate || '0');
      return value;
    });
    return value;
  }, [createForm, taxesDictionary]);

  const onCreateTax = useCallback(() => {
    if (
      !createForm.name ||
      !createForm.code ||
      !createForm.selectedTaxes.length
    ) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
    } else {
      const createInput = {
        code: createForm.code,
        name: createForm.name,
        rate: calculatedTax,
        taxes: createForm.selectedTaxes,
      } as CreateTaxInput;
      createTax({
        variables: {
          input: createInput,
        },
      });
    }
  }, [createForm, createTax, showNotification, translate, calculatedTax]);

  useEffect(() => {
    if (createOperation.data && createOperation.data.createTax.id) {
      const taxesToTaxesAdded: Tax[] = createForm.selectedTaxes.map(taxId => {
        const taxTemp = taxesDictionary[taxId];
        return {
          code: taxTemp?.code,
          id: taxTemp?.id,
          name: taxTemp?.name,
          isActive: taxTemp?.isActive,
          rate: parseFloat(taxTemp?.rate || '0'),
        } as Tax;
      });
      onAddTax({
        code: createForm.code,
        id: createOperation.data.createTax.id,
        name: createForm.name,
        isActive: false,
        rate: createForm.rate,
        taxes: taxesToTaxesAdded,
      });
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeSettings.TaxItemCreatedSuccessfully'),
      });
    }
  }, [
    taxes,
    onAddTax,
    createForm,
    createOperation.data,
    showNotification,
    closeModal,
    translate,
    taxesDictionary,
  ]);

  useEffect(() => {
    if (createOperation.error) {
      showNotification({
        error: true,
        message: parseApolloError(createOperation.error),
      });
    }
  }, [createOperation.error, showNotification]);

  return (
    <Modal
      title={translate('backOfficeSettings.createGroupTax')}
      onDismiss={closeModal}
      contentStyle={css(modalContainerStyle)}
    >
      <FormInput
        title={translate('backOfficeSettings.groupName') + '*'}
        placeholder={translate('backOfficeSettings.groupName')}
        value={createForm.name}
        containerStyle={css(formInputStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChangeTax.bind(null, 'name')}
        maxLength={50}
      />
      <FormInput
        title={translate('backOfficeSettings.groupTaxCode') + '*'}
        placeholder={translate('backOfficeSettings.groupTaxCode')}
        value={createForm.code}
        containerStyle={css(formInputStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChangeTax.bind(null, 'code')}
        maxLength={6}
      />

      <MultipleSelect
        label={translate('backOfficeSettings.selectTaxes') + '*'}
        placeholder={translate('backOfficeSettings.selectTaxes')}
        values={createForm.assignedTaxes}
        containerStyle={css(selectContainerStyle)}
        selectedValues={createForm.selectedTaxes || []}
        onValueChange={(value): void =>
          onChangeGroupTax('selectedTaxes', value)
        }
        searchLabel={translate('backOfficeSettings.searchTaxes')}
        searchPlaceHolder={translate('backOfficeSettings.searchTaxes')}
        touchableStyle={css(touchableStyle)}
      />

      <FormInput
        title={translate('backOfficeSettings.groupTaxesRate')}
        value={calculatedTax.toString() || ''}
        containerStyle={css(formInputStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChangeTax.bind(null, 'rate')}
        keyboardType={'numeric'}
        readOnly
      />

      <Button
        title={translate('backOfficeSettings.createGroupTax')}
        containerStyle={css(buttonContainerStyle)}
        labelStyle={css(createLabelStyle)}
        color={theme.colors.green}
        onPress={onCreateTax}
      />
    </Modal>
  );
};
