import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';
import scale, { isWeb, isAndroid, isIos } from '../../../../../common/theme';

export const DetailsStyles = () => {
  const { theme, noMargin, marginLeft } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        formLargeTextStyle: {
          width: 260,
          height: isAndroid ? scale.moderateScale(35) : scale.moderateScale(30),
        },
        formInputContainerStyle: {
          justifyContent: 'space-between',
          width: 260,
          marginBottom: 15,
        },
        formInputSuburbContainerStyle: {
          justifyContent: 'space-between',
          width: 260,
          marginBottom: 15,
          left: 10,
        },
        labelStyle: {
          marginLeft: 5,
        },
        dropLabelStyle: {
          marginLeft: theme.spacing.small,
        },
        dropdownExtraStyle: {
          backgroundColor: theme.colors.white,
          width: scale.textInputWidth180,
          height: theme.input.height,
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          marginHorizontal: scale.moderateScale(2),
          marginBottom: scale.moderateScale(7),
        },
        dropDownMainViewStyle: {
          borderColor: theme.colors.boxBorder,
          width: 260,
          height: theme.input.height + 3,
          borderRadius: theme.radius.small,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderWidth: 1,
          marginLeft: scale.moderateScale(7),
        },
        dropDownMainViewStateStyle: {
          borderColor: theme.colors.boxBorder,
          width: 160,
          height: theme.input.height + 3,
          borderRadius: theme.radius.small,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderWidth: 1,
          marginLeft: scale.moderateScale(7),
        },
        dropdownViewStyle: {
          justifyContent: 'center',
          width: isWeb ? scale.moderateScale(180) : '50%',
          left: isWeb ? 0 : 10,
        },
        dropdownViewCountryStyle: {
          justifyContent: 'center',
          width: isWeb ? scale.moderateScale(180) : '52%',
          right: isWeb ? 0 : 10,
        },
        dropdownExtraStateStyle: {
          width: 150,
          height: theme.input.height,
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          marginHorizontal: scale.moderateScale(5),
          marginBottom: scale.moderateScale(7),
        },
        dropdownViewStateStyle: {
          justifyContent: 'center',
          width: 120,
          left: isIos ? 15 : isWeb ? 0 : -10,
        },
        textStyle: {
          left: isIos ? -15 : theme.spacing.small,
        },
        stateFieldContainerStyleCss: {
          width: 140,
        },
        angleDownIconStyle: {
          right: 10,
        },
        postCodeFieldContainerStyle: {
          width: 100,
          marginBottom: isWeb ? 13 : 20,
          left: isAndroid ? 20 : 10,
        },
        postFieldTextInputStyle: {
          width: 100,
          height: theme.input.height,
          alignSelf: 'flex-end',
        },
        checkBoxTitleContainer: {
          width: 260,
          height: scale.moderateScale(30),
          marginBottom: noMargin ? 0 : theme.spacing.big / 2,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginLeft: marginLeft ? marginLeft : 0,
        },
        checkBoxTitleStyle: {
          fontFamily: theme.font.regular,
          fontSize: theme.fontSize.small,
          lineHeight: 21,
          letterSpacing: -0.5,
          textTransform: 'none',
          color: theme.colors.black,
        },
        formContainerStyleSmall: {
          width: 545,
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        },
        backOfficeContainerStyle: {
          width: 545,
          marginTop: theme.spacing.big + theme.spacing.medium,
        },
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        formLabelStyle: {
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.small,
          paddingHorizontal: theme.spacing.small,
        },
        dropdownLabelStyle: {
          fontFamily: theme.font.medium,
          fontSize: theme.fontSize.small,
          paddingHorizontal: theme.spacing.small,
          left: 10,
        },
        generalSettingsContainerWrapper: {
          width: '100%',
          alignItems: 'center',
          backgroundColor: theme.colors.white,
        },
        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
        },
        saveButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginLeft: 'auto',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
          alignSelf: 'auto',
        },
        deleteButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginRight: 'auto',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.danger2,
          alignSelf: 'auto',
        },
        dangerTitleStyle: {
          color: theme.colors.danger,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
          letterSpacing: -0.5,
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
        checkIconContainer: {
          borderColor: theme.colors.green,
          borderWidth: 2,
          marginRight: theme.spacing.big / 2,
          height: 17,
          width: 17,
        },
        unCheckContainer: {
          borderColor: theme.colors.paragraphLight,
          borderWidth: 2,
          marginRight: theme.spacing.big / 2,
          height: 17,
          width: 17,
        },
        iconContainerStyle: {
          marginTop: -theme.spacing.small,
          marginLeft: theme.spacing.medium,
          justifyContent: 'flex-start',
        },
        emailReceiptsStyle: {
          width: 260,
          marginBottom: theme.spacing.big / 2,
          flexDirection: 'column',
          alignContent: 'flex-start',
        },
        emailPreferenceCheckStyle: {
          marginRight: theme.spacing.small,
        },
        bottomSpace: {
          height: scale.moderateScale(30),
        },
      }),
    [theme, noMargin, marginLeft],
  );
};
