import { StyleFn, Table } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import { useRoute } from '@react-navigation/core';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { RouteProp } from '@react-navigation/native';
import React, { useCallback, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { Text, View } from 'react-native';
import {
  CheckIcon,
  CloseIcon,
  DrawerButton,
  EditButton,
  SubscriptionStatusButton,
} from '../../../components/HeaderIcons/HeaderIcons';
import { DiscardChangesModal } from '../../../components/Modals/DiscardChanges/DiscardChanges';
import Layout from '../../../components/POSLayout/POSLayout';
import { useTables } from '../../../hooks/app/tables/useTables';
import { useSession } from '../../../hooks/app/useSession';
import useBehaviorSubjectState from '../../../hooks/app/useSubjectState';
import { AppScreen } from '../../../types/AppScreen';
import HeaderTabs from './HeaderTabs';
import {
  editModeController,
  unsavedTableController,
  sectionIdController,
} from './Sections/floorViewObservables';
import FloorViewTabBar from './Sections/FloorViewTabBar';
import SectionStatsPanel from './Sections/SectionStatsPanel';
import SectionView from './Sections/SectionsView';
import TableStatPanel from './Sections/TableStatPanel';
import NewOrderButton from '../../../components/NewOrder/NewOrderButton';
import Spinner from '../../../components/Spinner/Spinner';

const containerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  borderRadius: theme.radius.medium,
  backgroundColor: theme.colors.white,
});

const textStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
});

const titleContainer: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.primaryDarkest,
  marginHorizontal: theme.spacing.small,
  height: 44,
  width: 250,
  justifyContent: 'center',
  alignItems: 'center',
});

type FloorOrdersParamList = RouteProp<
  {
    FloorView: { previousScreen: string };
  },
  'FloorView'
>;

const Tab = createMaterialTopTabNavigator();

const FloorView: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const [lastRollbackKey, setLastRollbackKey] = useState(Date.now());
  const { value: isEditMode, setValue: setIsEditMode } =
    useBehaviorSubjectState(editModeController);
  const { value: unsavedTables, setValue: setUnsavedTables } =
    useBehaviorSubjectState<Table[]>(unsavedTableController);
  const { value: sectionId } =
    useBehaviorSubjectState<string>(sectionIdController);
  const route = useRoute<FloorOrdersParamList>();
  const { previousScreen } = route.params ?? {};
  const { showModal, closeModal } = useModal();
  const [{ deviceProfile }] = useSession();
  const { updateTables, loading } = useTables({
    sectionId,
  });

  const onPressEdit = useCallback(() => {
    setIsEditMode(!isEditMode);
    setUnsavedTables([]);
  }, [setUnsavedTables, setIsEditMode, isEditMode]);

  const discardChanges = useCallback(() => {
    setIsEditMode(false);
    setUnsavedTables([]);
    setLastRollbackKey(Date.now());
  }, [setUnsavedTables, setIsEditMode]);

  const onPressSave = useCallback(async () => {
    if (!unsavedTables || unsavedTables.length === 0) {
      closeModal();
      setIsEditMode(false);
      return;
    }
    const updateTablesInput = unsavedTables.map(table => ({
      id: table.id,
      position: table.position,
      section: table.section?.id,
    }));
    await updateTables(updateTablesInput);
    setIsEditMode(false);
    closeModal();
  }, [closeModal, updateTables, unsavedTables, setIsEditMode]);

  const onCloseEditMode = useCallback(() => {
    setIsEditMode(false);
    if (unsavedTables?.length && unsavedTables.length > 0) {
      showModal(
        <DiscardChangesModal
          onSave={onPressSave}
          onClose={discardChanges}
          title={translate('floorView.discardPositionChangeTitle')}
          subTitle={translate('floorView.discardPositionChangeDescription')}
        />,
      );
    }
  }, [
    setIsEditMode,
    unsavedTables?.length,
    showModal,
    onPressSave,
    discardChanges,
    translate,
  ]);

  const headerTitle = useMemo(
    () =>
      isEditMode ? (
        <View style={css(titleContainer)}>
          <Text style={css(textStyle)}>
            {translate('floorView.editSectionLayout')}
          </Text>
        </View>
      ) : (
        <HeaderTabs
          selectedOption={AppScreen.FLOOR_VIEW}
          previousScreen={previousScreen}
        />
      ),
    [css, isEditMode, previousScreen, translate],
  );

  const headerLeft = useMemo(
    () => (isEditMode ? <CloseIcon onPress={onCloseEditMode} /> : null),
    [isEditMode, onCloseEditMode],
  );

  const headerRight = useMemo(
    () =>
      isEditMode ? (
        <CheckIcon onPress={onPressSave} />
      ) : (
        <>
          <EditButton onPress={onPressEdit} />
          <SubscriptionStatusButton />
          <NewOrderButton hideDineIn={true} />
          <DrawerButton />
        </>
      ),
    [isEditMode, onPressEdit, onPressSave],
  );

  const listTabScreen = useMemo(() => {
    if (deviceProfile?.sections?.length) {
      return deviceProfile.sections.map(
        (section: { name: string; id: string }) => (
          <Tab.Screen
            name={section.name}
            key={section.id}
            initialParams={{
              id: section.id,
            }}
            component={SectionView}
          />
        ),
      );
    }
    return [];
  }, [deviceProfile]);

  return (
    <Layout
      title={translate('navigation.floorView', {
        appName: translate('appName'),
      })}
      hasHeader
      headerLeft={headerLeft}
      headerRight={headerRight}
      headerTitle={headerTitle}
      testID="floor-view-page"
    >
      <View style={css(containerStyle)}>
        {listTabScreen.length > 0 ? (
          <Tab.Navigator
            key={`${lastRollbackKey}`}
            swipeEnabled={false}
            tabBar={(props): React.ReactNode => (
              <FloorViewTabBar tabBar={props as MaterialTopTabBarProps} />
            )}
          >
            {listTabScreen}
          </Tab.Navigator>
        ) : null}
      </View>
      <TableStatPanel />
      <SectionStatsPanel />
      <Spinner isLoading={loading} />
    </Layout>
  );
};

export default FloorView;
