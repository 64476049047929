import {
  Order,
  OrderItem,
  PrinterTemplate,
  RemoveOrderItemEvent,
  Course,
  OrderStatus,
  OrderItemStatus,
  Features,
  OrderTypeCode,
} from '@hitz-group/domain';
import { EscPos } from '@tillpos/xml-escpos-helper';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import { Session } from '../../state/Session';
import { dashDivider, divider } from './printDivider';
import { sortBy, get } from 'lodash';
import { format } from 'date-fns';
import { getShortVersion } from '../../hooks/orders/useOrderNumber';
import {
  getOnlineOrderChannel,
  getOnlineOrderCustomerInfo,
  getOnlineOrderDetail,
  getOnlineOrderType,
  isApplyForIntegrationOrder,
} from './generateOnlineOrderDetail';
export interface CoursePrintItems {
  courseName: string;
  priority: number;
  printItems: OrderItem[];
}

export type PrintableProductRow = [
  string | undefined,
  string | undefined,
  string | undefined,
];

interface OrderItemsPrintDetails {
  items: PrintableLineItems[];
  header?: string;
}

interface PrintableLineItems {
  product: string;
  note: string;
  modifiers: string;
  seat: string;
}

/**
 * Order details section has two columns and `n` row(s)
 */
export const twoColumnConfig: TableUserConfig = {
  columns: {
    0: { alignment: 'left', width: 18 },
    1: { alignment: 'right', width: 20 },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

const tableOrderItemConfig: TableUserConfig = {
  columns: {
    0: { width: 2 },
    1: {
      width: 25,
    },
    2: {
      width: 5,
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

const tableOrderItemModifierConfig: TableUserConfig = {
  columns: {
    0: { width: 5 },
    1: {
      width: 25,
    },
    2: { width: 5 },
  },

  border: getBorderCharacters('norc'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

const tableOrderItemNoteConfig: TableUserConfig = {
  columns: {
    0: { width: 2 },
    1: {
      width: 25,
      wrapWord: false,
    },
    2: { width: 5 },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

const tableSeatConfig: TableUserConfig = {
  columns: {
    0: { width: 5 },
    1: {
      width: 20,
      alignment: 'left',
    },
    2: { width: 5 },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

export interface RemoveOrderItemDocketEvent extends RemoveOrderItemEvent {
  quantity?: number;
}

export const groupItemsByPrinterProfile = (orderItems: OrderItem[]) => {
  return orderItems.reduce((acc, item) => {
    const printerProfiles = item.product?.printerProfiles || [];
    if (printerProfiles.length > 0) {
      printerProfiles.forEach(printerProfile => {
        acc[printerProfile.id] = [...(acc[printerProfile.id] || []), item];
      });
    }
    return acc;
  }, {} as Record<string, OrderItem[]>);
};

/**
 * ========================================================================================
 * Utilities for Partial orders (partial order items)
 * ========================================================================================
 *
 */

/**
 * Get product name (for all types of events)
 *
 * return newly added ot modified variants or notes
 * @param item
 * @param events
 */
// FIXME: once modifier events have been added here
export const getProductDetails = (item: OrderItem) => {
  const variant = item.variant;
  const product = item.product;
  const variantOptions = product?.optionValues || [];

  const productName = `${variant?.name || product?.name} ${variantOptions
    .map((v: { value: string }) => `${v.value}`)
    .join(',')}`;

  // TODO: add modifiers / variants etc
  return productName;
};

export const appendModifiers = (
  acc: PrintableProductRow[],
  item: OrderItem,
) => {
  if (item.modifiers && item.modifiers?.length > 0) {
    item.modifiers.forEach(modifier => {
      const printQuantity = item.quantity * modifier.quantity;
      if (printQuantity == 1) {
        acc.push([' ', ` ${modifier?.name || ''}`, '']);
      } else {
        acc.push([`   ${printQuantity}`, ` ${modifier?.name || ''}`, '']);
      }
    });
  }
};

/**
 * Returns partial order (items) printable array
 *
 * @param partialItems
 * @param events
 */
export const getOrderItemsPrintString = (
  printItems: OrderItem[],
): PrintableLineItems[] => {
  const result: PrintableLineItems[] = [];
  printItems.forEach(x => {
    const product = getProductDetails(x);
    const modifierRows: PrintableProductRow[] = [];
    const isVoidedItem = x.status === OrderItemStatus.VOID;
    const quantity = isVoidedItem ? `-${x.quantity}` : `${x.quantity}`;
    const productRow: PrintableProductRow = [`${quantity}`, product, ''];
    appendModifiers(modifierRows, x);
    const printableLineItem = {
      product: table([productRow], tableOrderItemConfig),
      note: isVoidedItem
        ? table([['', `*** ${x?.reason} ***`, '']], tableOrderItemNoteConfig)
        : x?.notes
        ? table([['', `*** ${x?.notes} ***`, '']], tableOrderItemNoteConfig)
        : '',
      modifiers:
        modifierRows.length > 0
          ? table(modifierRows, tableOrderItemModifierConfig)
          : '',
      seat: x?.seatNumber
        ? table([['', `[Seat ${x?.seatNumber}]`, '']], tableSeatConfig)
        : '',
    };
    result.push(printableLineItem);
  });
  return result;
};

/**
 * Returns partial order (items) printable array with Course Layout
 *
 * @param partialItems
 */
export const getOrderItemsPrintStringByCourse = (
  itemsWithCourse: CoursePrintItems[],
): OrderItemsPrintDetails[] => {
  const printableOrder = itemsWithCourse.reduce(
    (acc: OrderItemsPrintDetails[], course) => {
      const { courseName, printItems } = course;
      const printableItems = getOrderItemsPrintString(printItems);
      acc.push({
        items: printableItems,
        header: courseName.toLocaleUpperCase() + '\n',
      });
      return acc;
    },
    [],
  );

  return printableOrder;
};

type PartialPrintableKitchenOrder = (args: {
  order: Order;
  session: Session;
  template: PrinterTemplate;
  printItems: OrderItem[];
  hasVoidOrderItem?: boolean;
  translatedNowCourse: string;
}) => Buffer | undefined;

export const getPrintableKitchenOrder: PartialPrintableKitchenOrder = ({
  order,
  printItems,
  session,
  hasVoidOrderItem,
  template,
  translatedNowCourse,
}) => {
  const isCourseFeatureEnabled = !!session.currentVenue?.features?.filter(
    feature => feature.name === Features.COURSES,
  )?.[0]?.enabledByDefault;

  const isCoursesEnabled =
    session.deviceProfile?.enableCourses &&
    isCourseFeatureEnabled &&
    order?.orderType?.code === OrderTypeCode.DINE_IN;

  let printableItems = [] as OrderItemsPrintDetails[];
  if (isCoursesEnabled) {
    const itemsWithCourse = printItems.reduce((courses, item) => {
      const course =
        item.course || ({ name: translatedNowCourse, priority: -1 } as Course);
      const courseIndex = courses.findIndex(x => x.courseName === course.name);
      if (courseIndex < 0) {
        courses.push({
          courseName: course.name,
          priority: course.priority,
          printItems: [item],
        });
      } else {
        const existCourse = courses[courseIndex];
        courses[courseIndex] = {
          ...existCourse,
          printItems: [...existCourse.printItems, item],
        };
      }

      return courses;
    }, [] as CoursePrintItems[]);
    const sortedCourses = sortBy(itemsWithCourse, course => course.priority);

    printableItems = getOrderItemsPrintStringByCourse(sortedCourses);
  } else {
    printableItems = [
      {
        items: getOrderItemsPrintString(printItems),
      },
    ];
  }
  return getPrintableBuffer({
    orderItems: printableItems,
    originalOrder: order,
    template,
    title: hasVoidOrderItem
      ? 'CANCELLATION'
      : order.isEdited
      ? 'EDIT ORDER'
      : 'NEW ORDER',
  });
};

export const getOrderType = (order: Order): string => {
  if (isApplyForIntegrationOrder(order))
    return getOnlineOrderType(order, twoColumnConfig);

  const orderTypeName = order.orderType?.name.toLocaleUpperCase() || 'DINE IN';

  const orderNumber = getShortVersion(order.orderNumber);
  const orderIdentifier =
    orderTypeName == 'DINE IN' ? `Table ${order.table.name}` : orderNumber;
  return table([[orderTypeName, orderIdentifier]], twoColumnConfig);
};

export const getTableDetail = (order: Order): string => {
  if (isApplyForIntegrationOrder(order))
    return getOnlineOrderDetail(order, twoColumnConfig);

  const tableData = [
    'table.section.name',
    'table.guestCount',
    'createdBy.name',
    'createdByDevice.name',
  ];

  let rowInfo: string[] = [];
  const result = tableData.reduce((acc, infoPath) => {
    let orderDetail = get(order, infoPath);
    if (!orderDetail) return acc;

    if (infoPath === 'table.guestCount') {
      orderDetail = 'Guests: ' + orderDetail;
    }

    rowInfo.push(orderDetail);
    if (rowInfo.length == 2) {
      acc.push([...rowInfo] as [string, string]);
      rowInfo = [];
    }
    return acc;
  }, [] as [string, string][]);

  if (!result.length) return '';

  return table(result, twoColumnConfig);
};

export const getPrintableBuffer = (args: {
  originalOrder: Order;
  template: PrinterTemplate;
  orderItems: OrderItemsPrintDetails[];
  title: String;
}): Buffer | undefined => {
  const { originalOrder, orderItems, template, title } = args;

  let reasonOrNote = '';
  let customerInfo = '';
  if (originalOrder.status === OrderStatus.VOID && originalOrder.reason) {
    reasonOrNote = `*** REASON: ${originalOrder.reason} ***`;
  } else if (originalOrder.orderNote) {
    reasonOrNote = `*** NOTES: ${originalOrder.orderNote} ***`;
  }

  if (originalOrder.customer) {
    customerInfo = `CUSTOMER: ${originalOrder.customer.firstName} ${originalOrder.customer.lastName}`;
  }
  const { customerAddress, customerName, customerPhone } =
    getOnlineOrderCustomerInfo(originalOrder);

  const data = {
    orderDetails: getTableDetail(originalOrder),
    orderItems,
    divider: divider() + '\n',
    dashDivider: dashDivider() + '\n',
    title: title + '\n',
    orderType: getOrderType(originalOrder),
    timeStamp:
      format(
        new Date(originalOrder.updatedAt || new Date()),
        'dd-MM-yyyy hh:mm a',
      ) + '\n',
    reasonOrNote,
    customerInfo,
    onlineOrderChannel: getOnlineOrderChannel(originalOrder),
    onlineOrderCustomerName: customerName,
    onlineOrderCustomerAddress: customerAddress,
    onlineOrderCustomerPhone: customerPhone,
  };

  return EscPos.getBufferFromTemplate(
    template.template,
    data,
  ) as unknown as Buffer;
};
