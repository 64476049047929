import { gql } from '@apollo/client';

export const UPDATE_PRODUCT_PRICING_MUTATION = gql`
  mutation updateProductPricings($input: [ProductPricingInput]) {
    updateProductPricings(input: $input) {
      id
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
    }
  }
`;

export const DELETE_PRODUCT_PRICING_MUTATION = gql`
  mutation deleteProductPricings($input: [DeleteProductPricingInput!]!) {
    deleteProductPricings(input: $input)
  }
`;

export const ADD_PRODUCT_PRICING_MUTATION = gql`
  mutation addProductPricings($productId: ID, $pricings: [ProductPricesInput]) {
    addProductPricings(productId: $productId, pricings: $pricings) {
      id
      sellingPrice {
        amount
        currency
      }
      sellingTax {
        id
      }
      product {
        id
        name
      }
    }
  }
`;

export const addBulkProductPricingsMutation = gql`
  mutation addBulkProductPricings($input: [AddProductPricingInput]) {
    addBulkProductPricings(input: $input) {
      id
      product {
        id
        name
      }
    }
  }
`;
