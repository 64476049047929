import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  AssignCustomerEvent,
  OrderAction,
  OrderItem,
  OrderPaymentEvent,
  OrderStatus,
  UpdateOrderItemPriceEvent,
  Product as ProductAlias,
  FunctionMapActions,
  ShiftStatus,
  OrderItemModifier,
  DEVICE_PRICING_GROUP_ID,
  FUNCTION_MAPS_FOR_ORDER_COMPLETED,
  VoidOrderEvent,
  VoidReason,
  Order,
  OrderItemStatus,
  OrderType,
  OrderTypeCode,
  RemoveOrderItemEvent,
  AddOrderSurcharge,
  AdjustmentType,
  CUSTOM_ADJUSTMENT_ID,
  AdjustmentUnit,
  Adjustment,
  Resource,
  USER_EXPLICIT_SELECT_PRICING_GROUP,
  OrderPaymentStatus,
  OrderPaymentProcessedEvent,
  Features,
  FeatureContext,
  TransactionAdjustment,
} from '@hitz-group/domain';
import {
  Customer,
  PaymentType,
  ReceiptPrintOption,
  Variant,
  Product,
  Catalogue,
} from '@hitz-group/domain';
import { useModal } from '@hitz-group/rn-use-modal';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { useNavigation } from '@react-navigation/native';
import { isEmpty, keyBy, map } from 'lodash';
import { CartSelectionState } from '../../components/Cart/Cart';
import { CancelOrderItemModalMap } from '../../components/Modals/FunctionMaps/CancelOrderItem';
import SendOrderReceiptModal from '../../components/Modals/SendOrderReceipt/SendOrderReceiptModal';
import { AppScreen } from '../../types/AppScreen';
import { CancelOrderModal } from '../../components/Modals/CancelOrder/CancelOrder';
import {
  adjustAdjustments,
  SetPriceModalMap,
  SET_PRICE_OPTIONS,
} from '../../components/Modals/FunctionMaps/SetPrice';
import { CashPaymentModal } from '../../components/Modals/FunctionMaps/Payment';
import AddCustomer from '../../components/Modals/Customer/AddCustomer';
import { PickerModal } from '../../components/Modals/Picker/Picker';
import { useSession } from '../../hooks/app/useSession';
import { useCartContext as useCart } from '../../hooks/CartProvider';
import * as storage from '../../storage/interface';
import { useOrders } from '../../hooks/app/orders/useOrders';
import MoneyEventModal from '../../components/ManageMoneyView/MoneyEventModal';
import { useShifts } from '../../hooks/app/useShifts';
import CloseShift from '../../components/Shift/CloseShift';
import CloseShiftModal from '../../components/Modals/Shift/CloseShiftModal';
import { usePaymentTypes } from '../../hooks/app/usePaymentTypes';
import { useNotification } from '../../hooks/Notification';
import { usePrinting } from '../../hooks/PrintingProvider';
import { computeLineTotal, isOrderEditable } from '@hitz-group/order-helper';
import { useLogout } from '../../hooks/app/useLogout';
import { useNetInfo } from '@react-native-community/netinfo';
import { isErrorForDeviceOffline } from '../../utils/validator';
import PaymentSurcharge from '../../components/Modals/PaymentSurcharge/PaymentSurcharge';
import { getAdjustmentValue } from '@hitz-group/order-helper';

// helpers
import { cloneJSON, roundOffByValue } from '@hitz-group/client-utils';
import { getRoundOffValue } from '../../utils/roundOffHelper';
import ItemAvailability from '../../../src/components/Modals/ItemAvailability/ItemAvailability';
import usePOSUserAuthorization from '../app/users/usePOSUserAuthorization';
import { useProducts } from '../app/products/useProducts';
import { productFragment } from '../app/catalogue/graphql';
import {
  productQuantitiesToReturnOnVoidOrder,
  updateProductQuantities,
} from '../../../src/utils/OpenOrdersHelper';
import CompleteSaleModal from '../../components/Modals/CompleteSaleModal/CompleteSaleModal';
import { replaceFunctionMapCurrency } from '../../utils/replaceFunctionMapCurrency';
import {
  AdditionalPaymentInfo,
  ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER,
  OOLIO_PAY_PAYMENT_TYPE,
} from '../../types/Common';
import { useCheckFeatureEnabled } from '../app/features/useCheckFeatureEnabled';
import CustomerRewardModal from '../../components/Modals/CustomerLoyalty/CustomerRewardModal';
import { generateCustomFlatAdjustment } from '../../utils/adjustmentHelper';
import { useCardPayFunctionMap } from './useCardPayFunctionMap';

const CASH_PAYMENT_TYPE = 'cash';
const READ_ONLY_STATUSES = [
  OrderStatus.COMPLETED,
  OrderStatus.REFUNDED,
  OrderStatus.VOID,
];

export interface UseFunctionMapsProps {
  orderItems: {
    product: ProductAlias;
    variant: Variant;
    id: string;
    quantity: number;
    status: OrderItemStatus;
    notes: string;
    modifiers: OrderItemModifier[];
    taxInclusive: boolean;
    costPrice: number;
  }[];
  selectedCartItem: CartSelectionState | undefined;
  escapeDiscardModal: { current: boolean };
  advancedKeypadValue: number;
  pricingGroup: string;
  orderType: OrderType | undefined;
  orderTypes: OrderType[] | undefined;
  assignedCustomer: Partial<Customer> | undefined;
  pricingGroupOptions: {
    label: string;
    value: string;
  }[];
  isOrderComplete: boolean;
  menuId: string;
  menus: {
    id: string;
    name: string;
  }[];
  selectCartItem: (value: CartSelectionState | undefined) => void;
  setAdvancedKeypadValue: (value: number) => void;
  setPricingGroup: (value: string) => void;
  onPressUpdateOrderNotes: () => void | Promise<void>;
  productsMap: Record<string, Product>;
  onPressNewOrder: () => void;
  onPressOpenTable: () => void;
  setMenuId: (value: string) => void;
  openTableNumberModal: (callback?: () => void) => void;
  unselectCartItem: () => void;
  areCartItemsValid: (errMsg: string) => boolean;
  checkForUnfiredItems: (callback: () => void) => void;
  isValidTotalPriceBeforeRemoveItem: (quantityToBeKept?: number) => boolean;
  navigateToPostSaleScreen: () => void;
}

export const useFunctionMaps = (
  orderItems: UseFunctionMapsProps['orderItems'],
  selectCartItem: UseFunctionMapsProps['selectCartItem'],
  selectedCartItem: UseFunctionMapsProps['selectedCartItem'],
  escapeDiscardModal: UseFunctionMapsProps['escapeDiscardModal'],
  advancedKeypadValue: UseFunctionMapsProps['advancedKeypadValue'],
  pricingGroup: UseFunctionMapsProps['pricingGroup'],
  pricingGroupOptions: UseFunctionMapsProps['pricingGroupOptions'],
  isOrderComplete: UseFunctionMapsProps['isOrderComplete'],
  menuId: UseFunctionMapsProps['menuId'],
  menus: UseFunctionMapsProps['menus'],
  setAdvancedKeypadValue: UseFunctionMapsProps['setAdvancedKeypadValue'],
  setPricingGroup: UseFunctionMapsProps['setPricingGroup'],
  onPressUpdateOrderNotes: UseFunctionMapsProps['onPressUpdateOrderNotes'],
  productsMap: UseFunctionMapsProps['productsMap'],
  onPressNewOrder: UseFunctionMapsProps['onPressNewOrder'],
  onPressOpenTable: UseFunctionMapsProps['onPressOpenTable'],
  setMenuId: UseFunctionMapsProps['setMenuId'],
  orderType: UseFunctionMapsProps['orderType'],
  openTableNumberModal: UseFunctionMapsProps['openTableNumberModal'],
  unselectCartItem: UseFunctionMapsProps['unselectCartItem'],
  areCartItemsValid: UseFunctionMapsProps['areCartItemsValid'],
  checkForUnfiredItems: UseFunctionMapsProps['checkForUnfiredItems'],
  isValidTotalPriceBeforeRemoveItem: UseFunctionMapsProps['isValidTotalPriceBeforeRemoveItem'],
  navigateToPostSaleScreen: UseFunctionMapsProps['navigateToPostSaleScreen'],
  assignedCustomer: UseFunctionMapsProps['assignedCustomer'],
) => {
  const { canI } = usePOSUserAuthorization();
  const [session, setSession] = useSession();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { formatCurrency } = useCurrency();

  const { logout } = useLogout();
  const { showNotification } = useNotification();
  const { order, updateCart, discardChanges, resetCart, closeOrderCart } =
    useCart();

  const orderId = order?.id;
  const { printBill, printShiftReceipt, reprintKitchenDocket, openCashDrawer } =
    usePrinting();

  const { shifts, refetch, error, queryLoading: shiftLoading } = useShifts();
  const [printShiftTriggered, triggerPrintShift] = useState<boolean>(false);

  const netInfo = useNetInfo();
  const { postSaleScreen, receiptPrintOption } = session.deviceProfile || {};
  const enableRoundOff =
    session?.currentStore?.checkoutOptions?.enableRoundOff || false;
  const roundOffValue = getRoundOffValue(
    session?.currentStore?.checkoutOptions?.roundOffValue,
  );
  const { paymentTypes } = usePaymentTypes();

  const isFeatureEnabled = useCheckFeatureEnabled();
  const isLoyaltyEnabled = isFeatureEnabled(
    Features.LOYALTY,
    FeatureContext.ORGANIZATION,
  );

  const processingPaymentAmount = order?.amountDue || 0;

  const {
    orders,
    getOrdersFromCache,
    getOrderFromCache,
    getLatestCompletedOrderByDevice,
  } = useOrders();
  const { updateProductsInCache, getProductsFromCache } = useProducts(
    undefined,
    productFragment,
  );

  useEffect(() => {
    getOrdersFromCache(OrderStatus.IN_PROGRESS);
    getOrdersFromCache(OrderStatus.COMPLETED);
  }, [getOrdersFromCache]);

  const ordersArray = useMemo(() => {
    return Object.values(orders);
  }, [orders]);

  const { appendCurrency, currencySymbol } = useCurrency();

  const [functionMap, setFunctionMap] = useState('');

  const isZeroAmountDue = useCallback(
    (order?: Order) => {
      if (order && order?.amountDue === 0) {
        showNotification({
          message: translate('payment.payZeroError'),
          error: true,
        });
        return true;
      }
      return false;
    },
    [showNotification, translate],
  );

  const navigateToDefaultScreen = useCallback(async () => {
    const screen: AppScreen = postSaleScreen
      ? AppScreen[postSaleScreen]
      : AppScreen.NEW_ORDER;
    if (screen && screen == AppScreen.NEW_ORDER) {
      await onPressNewOrder();
    } else {
      navigateToPostSaleScreen();
    }
  }, [postSaleScreen, onPressNewOrder, navigateToPostSaleScreen]);

  const assignCustomerToOrder = useCallback(
    (customer: Customer): void => {
      // trigger assign order to customer event
      updateCart<AssignCustomerEvent>(OrderAction.ORDER_ASSIGN_CUSTOMER, {
        customerId: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        loyaltyMember: customer.loyaltyMember,
        customerAccountDetails: isEmpty(customer?.customerAccountDetails)
          ? {}
          : {
              accountPayment: customer?.customerAccountDetails?.accountPayment,
              currentBalance: customer?.customerAccountDetails?.currentBalance,
              maxBalanceLimit:
                customer?.customerAccountDetails?.maxBalanceLimit,
              maxOrderLimit: customer?.customerAccountDetails?.maxOrderLimit,
            },
        isLoyaltyApplied: Boolean(isLoyaltyEnabled && customer?.loyaltyMember),
      });
    },
    [updateCart, isLoyaltyEnabled],
  );

  const onCancelOrder = useCallback(
    async (reason: VoidReason, description: string, order: Order) => {
      // Discarding all unsaved events in local, as order is getting "VOIDED".
      const allProducts = cloneJSON(keyBy(getProductsFromCache(), 'id'));
      const productQuantities = productQuantitiesToReturnOnVoidOrder(
        allProducts,
        order,
      );

      const updatedProducts = updateProductQuantities(
        productQuantities,
        allProducts,
      );

      updateProductsInCache(updatedProducts);
      discardChanges();
      updateCart<VoidOrderEvent>(OrderAction.ORDER_VOID, {
        reason,
        description,
        productQuantities: Object.keys(productQuantities).map(key => {
          return { id: key, quantity: productQuantities[key] };
        }),
        inventoryTracked: !!Object.keys(productQuantities).length,
      });
      onPressNewOrder();
      closeOrderCart();
    },
    [
      discardChanges,
      getProductsFromCache,
      onPressNewOrder,
      updateCart,
      updateProductsInCache,
      closeOrderCart,
    ],
  );

  const removeCartItem = useCallback(
    (item: OrderItem, reason: VoidReason) => {
      const product = item.product;
      const variant = item.variant;
      updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
        orderItemId: item.id,
        productId: product.id || variant.id,
        quantity: -item.quantity,
        inventoryTracked: product.isBeingTracked,
        reason,
      });

      unselectCartItem();
      const allProducts = getProductsFromCache();
      const updatedProducts = allProducts.map(prod => {
        const updatedProduct: ProductAlias = cloneJSON(prod);
        if (
          product.id === prod.id &&
          updatedProduct.inventory?.availableQuantity !== undefined
        ) {
          updatedProduct.inventory.availableQuantity += item.quantity;
        }
        return updatedProduct;
      });
      updateProductsInCache(
        keyBy(updatedProducts, 'id') as unknown as Record<string, ProductAlias>,
      );
    },

    [unselectCartItem, getProductsFromCache, updateProductsInCache, updateCart],
  );

  const onPressVoidSale = useCallback(() => {
    const selectedOrderItem = orderItems.find(
      item => item.id === selectedCartItem?.item,
    );

    if (
      selectedOrderItem &&
      selectedOrderItem?.status === OrderItemStatus.IN_PROGRESS
    ) {
      const allowVoidItem = canI([{ onResource: Resource.VOID_ORDER_ITEMS }], {
        prompt: true,
      });
      if (!allowVoidItem) return;
      if (!isValidTotalPriceBeforeRemoveItem()) {
        showNotification({
          message: translate('payment.amountCannotBeLessThanRemaining'),
          error: true,
        });
        return;
      }
      showModal(
        <CancelOrderItemModalMap
          orderId={order?.id || ''}
          item={selectedOrderItem as unknown as OrderItem}
          onSubmit={(orderItem: OrderItem, reason: VoidReason) =>
            removeCartItem(orderItem as unknown as OrderItem, reason)
          }
        />,
      );
    } else if (
      selectedOrderItem?.status === OrderItemStatus.CREATED ||
      selectedOrderItem?.status === OrderItemStatus.ON_HOLD ||
      order?.status === OrderStatus.CREATED
    ) {
      return showNotification({
        error: true,
        message: translate('order.canNotVoidOrder'),
      });
    } else {
      const allowVoidOrder = canI([{ onResource: Resource.VOID_ORDERS }], {
        prompt: true,
      });
      if (!allowVoidOrder) return;
      showModal(
        <CancelOrderModal order={order as Order} onCancel={onCancelOrder} />,
      );
    }
  }, [
    orderItems,
    order,
    selectedCartItem?.item,
    isValidTotalPriceBeforeRemoveItem,
    showModal,
    showNotification,
    removeCartItem,
    translate,
    onCancelOrder,
    canI,
  ]);

  const onPressShift = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.PERFORM_SHIFT_CLOSURE }], {
      prompt: true,
    });
    if (!hasAccess) {
      return;
    }
    const data = shifts.find(item => item.shiftStatus === ShiftStatus.OPEN);
    const closeShift = (): void => {
      if (data) {
        showModal(<CloseShift data={data} refetch={refetch} />);
      }
    };
    if (ordersArray.find(x => x.status === OrderStatus.IN_PROGRESS)) {
      showModal(<CloseShiftModal onClose={closeShift} />);
    } else closeShift();
  }, [shifts, showModal, refetch, ordersArray, canI]);

  const onPrintShift = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.PRINT_SHIFT_SUMMARY }], {
      prompt: true,
    });
    if (!hasAccess) {
      return;
    }
    refetch();
    // FIXME: Setting this in timeout and can be solved with a better approach later
    // it takes a little delay for code to set "shiftLoading" to true.
    setTimeout(() => triggerPrintShift(true), 200);
  }, [refetch, canI]);

  useEffect(() => {
    if (!shiftLoading && printShiftTriggered && shifts) {
      if (error) {
        const errorMessage = isErrorForDeviceOffline(error)
          ? translate('shift.printShiftOfflineErrorMessage')
          : error;
        showNotification({
          error: true,
          message: errorMessage,
        });
      } else {
        const summary = shifts.find(
          item => item.shiftStatus === ShiftStatus.OPEN,
        );
        summary && printShiftReceipt(summary);
        triggerPrintShift(false);
      }
    }
  }, [
    printShiftTriggered,
    shifts,
    shiftLoading,
    printShiftReceipt,
    error,
    showNotification,
    translate,
  ]);

  const getPaymentTypeByName = useCallback(
    (name: string): PaymentType => {
      return paymentTypes.find(
        (paymentType: { name: string }) =>
          paymentType?.name.toLowerCase() == name.toLowerCase(),
      ) as PaymentType;
    },
    [paymentTypes],
  );

  const onPressAddNewCustomer = useCallback(() => {
    showModal(
      <AddCustomer
        orderId={orderId}
        assignCustomerToOrder={assignCustomerToOrder}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [showModal, closeModal, orderId, assignCustomerToOrder]);

  const onConfirmSetPrice = useCallback(
    (unitPrice: number, adjustments: Adjustment[]) => {
      if (selectedCartItem) {
        updateCart<UpdateOrderItemPriceEvent>(
          OrderAction.ORDER_ITEM_UPDATE_PRICE,
          {
            unitPrice,
            adjustments,
            orderItemId: selectedCartItem?.item || '',
          },
        );
        selectCartItem(undefined);
      } else {
        updateCart(OrderAction.ORDER_ADD_ADJUSTMENT, {
          adjustment: adjustments[0],
        });
      }
    },
    [updateCart, selectedCartItem, selectCartItem],
  );

  const onPressPrintReceipt = useCallback(
    async (isPrintSplit?: boolean) => {
      if (order) {
        const cachedOrder = getOrderFromCache(order.id) || order;
        let nthPaymentToPrint;
        if (isPrintSplit) {
          nthPaymentToPrint = (cachedOrder?.payments || []).length - 1;
        }
        const result = await printBill(cachedOrder, nthPaymentToPrint);

        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
        closeModal();
        navigateToDefaultScreen();
      }
    },
    [
      order,
      getOrderFromCache,
      printBill,
      closeModal,
      navigateToDefaultScreen,
      showNotification,
    ],
  );

  const readOnly = READ_ONLY_STATUSES.includes(
    order?.status || OrderStatus.CREATED,
  );
  const totalCartItems = order?.orderItems.length || 0;
  const disableCartActions = totalCartItems === 0;
  const disableOrderActions = readOnly || disableCartActions;

  const sendOrderReceipt = useCallback(async () => {
    const lastCompletedOrder = getLatestCompletedOrderByDevice(
      session.device?.id,
    );
    if (!lastCompletedOrder) {
      showNotification({
        message: translate('common.noLastOrderFound'),
        error: true,
      });
      return;
    }
    const handleSendSuccess = () => {
      showNotification({
        message: translate('common.sendSaleReceiptToCustomerSuccess'),
        success: true,
      });
    };
    const handleSendFailed = () => {
      showNotification({
        message: translate('common.sendSaleReceiptToCustomerFailed'),
        error: true,
      });
    };
    showModal(
      <SendOrderReceiptModal
        onSendSuccess={handleSendSuccess}
        onSendFailed={handleSendFailed}
        order={lastCompletedOrder}
      />,
    );
  }, [
    getLatestCompletedOrderByDevice,
    session.device?.id,
    showModal,
    showNotification,
    translate,
  ]);

  const onPressUpdatePriceMap = useCallback(
    (type, item?: OrderItem) => {
      const maxPercentage = 100;
      escapeDiscardModal.current = false;
      if (!type) {
        // sanity check, default value
        type = SET_PRICE_OPTIONS.DISCOUNT_PRICE;
      }
      if (advancedKeypadValue) {
        switch (type) {
          case SET_PRICE_OPTIONS.DISCOUNT_PERCENT:
            if (advancedKeypadValue > maxPercentage) {
              showNotification({
                message: translate('order.maxDiscountWarning', {
                  value: '100%',
                }),
                error: true,
              });
            } else {
              onConfirmSetPrice(
                item ? item.unitPrice : order?.subTotal || 0,
                adjustAdjustments(
                  [
                    {
                      id: CUSTOM_ADJUSTMENT_ID,
                      amount: -Number(advancedKeypadValue),
                      adjustmentUnit: AdjustmentUnit.PERCENTAGE,
                      allowOnPaymentType: false,
                      adjustmentType: AdjustmentType.DISCOUNT,
                    },
                  ],
                  1,
                ),
              );
            }
            break;

          case SET_PRICE_OPTIONS.DISCOUNT_PRICE:
            const originalTotal = computeLineTotal({
              ...(item
                ? item
                : ({
                    unitPrice: order?.subTotal || 0,
                    quantity: 1,
                    discounts: order?.discounts || [],
                  } as OrderItem)),
              unitPrice: item ? item.unitPrice : order?.subTotal || 0,
              discounts: [],
            });
            if (advancedKeypadValue > originalTotal) {
              showNotification({
                message: translate('order.maxDiscountWarning', {
                  value: `${formatCurrency(originalTotal)}`,
                }),
                error: true,
              });
            } else {
              onConfirmSetPrice(
                item ? item.unitPrice : order?.subTotal || 0,
                adjustAdjustments(
                  [
                    {
                      id: CUSTOM_ADJUSTMENT_ID,
                      amount: -Number(advancedKeypadValue),
                      adjustmentUnit: AdjustmentUnit.FLAT,
                      allowOnPaymentType: false,
                      adjustmentType: AdjustmentType.DISCOUNT,
                    },
                  ],
                  1,
                ),
              );
            }
            break;

          case SET_PRICE_OPTIONS.SURCHARGE_PERCENT:
          case SET_PRICE_OPTIONS.SURCHARGE_PRICE:
            const product = item ? productsMap[item.product.id] : undefined;
            const price = item ? item.unitPrice : order?.subTotal || 0;
            const surchargeType =
              type === SET_PRICE_OPTIONS.SURCHARGE_PERCENT
                ? AdjustmentUnit.PERCENTAGE
                : AdjustmentUnit.FLAT;

            if (
              product &&
              product.minSellingPrice &&
              price < product.minSellingPrice
            ) {
              showNotification({
                message: translate('order.minPriceWarning', {
                  price: appendCurrency(product.minSellingPrice.toString()),
                }),
                error: true,
              });
            } else if (
              product &&
              product.maxSellingPrice &&
              price > product.maxSellingPrice
            ) {
              showNotification({
                message: translate('order.maxPriceWarning', {
                  price: appendCurrency(product.maxSellingPrice.toString()),
                }),
                error: true,
              });
            } else {
              onConfirmSetPrice(
                price,
                adjustAdjustments(
                  [
                    {
                      id: CUSTOM_ADJUSTMENT_ID,
                      amount: advancedKeypadValue,
                      adjustmentUnit: surchargeType,
                      allowOnPaymentType: false,
                      adjustmentType: AdjustmentType.SURCHARGE,
                    },
                  ],
                  1,
                ),
              );
            }
            break;
        }
        setAdvancedKeypadValue(0);
      } else {
        showModal(
          <SetPriceModalMap
            item={
              item
                ? item
                : ({
                    unitPrice: order?.subTotal || 0,
                    quantity: 1,
                    discounts: order?.discounts || [],
                  } as OrderItem)
            }
            onSubmit={onConfirmSetPrice}
            maxDiscounts={1}
            adjustmentType={type}
            defaultPrice={item ? item.unitPrice : order?.subTotal || 0}
          />,
          {
            onBackdropPress: closeModal,
          },
        );
      }
    },
    [
      advancedKeypadValue,
      order?.subTotal,
      order?.discounts,
      productsMap,
      showNotification,
      translate,
      onConfirmSetPrice,
      appendCurrency,
      showModal,
      closeModal,
      setAdvancedKeypadValue,
      escapeDiscardModal,
      formatCurrency,
    ],
  );

  const onCompleteSale = useCallback(
    async (roundedOffChange: number) => {
      setTimeout(async () => {
        if (order) {
          const { postSaleScreen, defaultOrderType } =
            session.deviceProfile || {};
          if (
            postSaleScreen &&
            AppScreen[postSaleScreen] != AppScreen.NEW_ORDER
          ) {
            const newOrderId = await resetCart();
            navigation.setParams({
              id: newOrderId,
              orderType: defaultOrderType?.id,
              tableId: '',
              isCompleted: false,
              isExisting: false,
            });
          }
        }
      });
      if (receiptPrintOption === ReceiptPrintOption.AUTO) {
        // print bill receipt with delay silently
        setTimeout(onPressPrintReceipt);
      } else if (receiptPrintOption === ReceiptPrintOption.PROMPT) {
        const isPartialPayment =
          processingPaymentAmount < (order?.totalPrice || 0);
        showModal(
          <CompleteSaleModal
            sendReceipt={sendOrderReceipt}
            onPressPrintReceipt={onPressPrintReceipt}
            onPrintSplit={() => onPressPrintReceipt(true)}
            onPressNewSale={navigateToPostSaleScreen}
            changeDue={roundedOffChange}
            isSplitPayment={isPartialPayment}
            loyaltyAction={null}
          />,
        );
      } else {
        navigateToDefaultScreen();
      }
    },
    [
      receiptPrintOption,
      order,
      session.deviceProfile,
      resetCart,
      navigation,
      onPressPrintReceipt,
      processingPaymentAmount,
      showModal,
      sendOrderReceipt,
      navigateToPostSaleScreen,
      navigateToDefaultScreen,
    ],
  );

  const onPressPayAmount = useCallback(
    (
      paymentType: PaymentType,
      amount: number,
      surchargeAmount = 0,
      additionalInfo?: AdditionalPaymentInfo,
    ) => {
      let validReceivedAmount = amount;

      if (
        paymentType.name.toLowerCase() == CASH_PAYMENT_TYPE &&
        enableRoundOff
      ) {
        validReceivedAmount = roundOffByValue(amount, roundOffValue);
      }

      const change = parseFloat(
        (
          validReceivedAmount -
          processingPaymentAmount -
          surchargeAmount
        ).toFixed(2),
      );

      const roundedOffChange = enableRoundOff
        ? roundOffByValue(change, roundOffValue)
        : change;

      updateCart<OrderPaymentEvent>(OrderAction.ORDER_PAYMENT, {
        tendered: validReceivedAmount,
        paymentTypeId: paymentType.id,
        tip: 0,
        change: roundedOffChange,
        roundOffDifference: parseFloat((change - roundedOffChange).toFixed(2)),
        paymentTypeName: paymentType.name,
        ...(additionalInfo &&
          additionalInfo.paymentRequestId && {
            paymentRequestId: additionalInfo.paymentRequestId,
          }),
        ...(additionalInfo &&
          additionalInfo.paymentStatus && {
            paymentStatus: additionalInfo.paymentStatus,
          }),
      });
      // Open cash drawer
      if (
        ALLOWED_PAYMENT_TYPES_FOR_CASH_DRAWER.includes(
          paymentType.name?.toLowerCase(),
        )
      ) {
        openCashDrawer();
      }
      updateCart(OrderAction.ORDER_SAVE);

      if (additionalInfo?.paymentStatus !== OrderPaymentStatus.PENDING) {
        onCompleteSale(roundedOffChange);
      }
    },
    [
      enableRoundOff,
      onCompleteSale,
      processingPaymentAmount,
      roundOffValue,
      updateCart,
      openCashDrawer,
    ],
  );

  const onPaymentAdjustment = useCallback(
    (transactionAdjustment: TransactionAdjustment) => {
      updateCart<AddOrderSurcharge>(OrderAction.ORDER_ADD_ADJUSTMENT, {
        adjustment: generateCustomFlatAdjustment(transactionAdjustment.amount),
      });
    },
    [updateCart],
  );

  const onPaymentProcessed = useCallback(
    (additionalInfo: AdditionalPaymentInfo) => {
      const roundedOffChange = 0;

      updateCart<OrderPaymentProcessedEvent>(
        OrderAction.ORDER_PAYMENT_PROCESSED,
        {
          paymentStatus: additionalInfo.paymentStatus as OrderPaymentStatus,
          paymentRequestId: additionalInfo.paymentRequestId as string,
          ...(additionalInfo &&
            additionalInfo.paymentTransactionRef && {
              paymentTransactionRef: additionalInfo.paymentTransactionRef,
            }),
          ...(additionalInfo &&
            additionalInfo.paymentCompletedAt && {
              paymentCompletedAt: additionalInfo.paymentCompletedAt,
            }),
          ...(additionalInfo &&
            additionalInfo.paymentReceipt && {
              paymentReceipt: additionalInfo.paymentReceipt,
            }),
          ...(additionalInfo &&
            additionalInfo.paymentSurcharge && {
              paymentSurcharge: additionalInfo.paymentSurcharge,
            }),
        },
      );

      updateCart(OrderAction.ORDER_SAVE);

      if (additionalInfo?.paymentStatus === OrderPaymentStatus.COMPLETE) {
        onCompleteSale(roundedOffChange);
      }
    },
    [onCompleteSale, updateCart],
  );

  const { createCardPayment } = useCardPayFunctionMap(
    onPressPayAmount,
    onPaymentProcessed,
    onPaymentAdjustment,
  );

  useEffect(() => {
    if (
      navigation.isFocused() &&
      order?.payments &&
      order?.payments?.length > 0 &&
      order?.updatedByDevice?.id === session.device?.id &&
      isOrderEditable(order?.status)
    ) {
      const pendingPayment = order?.payments.find(
        payment => payment.status === OrderPaymentStatus.PENDING,
      );

      if (pendingPayment) {
        createCardPayment(
          pendingPayment.amount,
          order?.orderNumber as string,
          pendingPayment.paymentType as PaymentType,
          undefined,
          pendingPayment.paymentRequestId,
        );
      }
    }
  }, [
    navigation,
    onPaymentProcessed,
    onPressPayAmount,
    onPaymentAdjustment,
    order?.orderNumber,
    order?.payments,
    order?.status,
    order?.updatedByDevice,
    session.device,
    createCardPayment,
  ]);

  const onApplyDiscount = useCallback(
    (discountType: AdjustmentUnit, amount, item?: OrderItem) => {
      const price =
        (item ? item.unitPrice * item.quantity : order?.totalPrice) || 0;
      if (
        (item || order) &&
        discountType == AdjustmentUnit.FLAT &&
        price < +amount
      ) {
        showNotification({
          message: translate('order.maxDiscountWarning', {
            value: `${formatCurrency(price)}`,
          }),
          error: true,
        });
      } else {
        onConfirmSetPrice(
          (item ? item.unitPrice : order?.totalPrice) || 0,
          adjustAdjustments(
            [
              {
                id: CUSTOM_ADJUSTMENT_ID,
                amount: -Number(amount),
                adjustmentUnit: discountType,
                allowOnPaymentType: false,
                adjustmentType: AdjustmentType.DISCOUNT,
              },
            ],
            1,
          ),
        );
      }
    },
    [order, showNotification, translate, onConfirmSetPrice, formatCurrency],
  );

  const onApplySurcharge = useCallback(
    (surchargeType: AdjustmentUnit, amount, item?: OrderItem) => {
      onConfirmSetPrice(
        (item ? item.unitPrice : order?.totalPrice) || 0,
        adjustAdjustments(
          [
            {
              id: CUSTOM_ADJUSTMENT_ID,
              amount: Number(amount),
              adjustmentUnit: surchargeType,
              allowOnPaymentType: false,
              adjustmentType: AdjustmentType.SURCHARGE,
            },
          ],
          1,
        ),
      );
    },
    [order, onConfirmSetPrice],
  );

  const applySurcharge = useCallback(
    (paymentType, surcharge) => {
      if (order && surcharge?.amount) {
        updateCart<AddOrderSurcharge>(OrderAction.ORDER_ADD_ADJUSTMENT, {
          adjustment: surcharge,
        });
        const surchargeAmount = getAdjustmentValue(
          processingPaymentAmount,
          [surcharge],
          { adjustmentType: AdjustmentType.SURCHARGE },
        );
        onPressPayAmount &&
          onPressPayAmount(
            paymentType,
            processingPaymentAmount + surchargeAmount,
            surchargeAmount,
          );
      } else {
        onPressPayAmount &&
          onPressPayAmount(paymentType, processingPaymentAmount);
      }
    },
    [order, updateCart, processingPaymentAmount, onPressPayAmount],
  );

  const checkAllowAdjustmentPermission = useCallback(() => {
    const allowAdjustment = canI([{ onResource: Resource.ALLOW_ADJUSTMENTS }], {
      prompt: true,
    });
    return allowAdjustment;
  }, [canI]);

  const applySurchargeTillPay = useCallback(
    (paymentType, surcharge) => {
      setTimeout(() => {
        let total = processingPaymentAmount;

        if (order && surcharge?.amount) {
          updateCart<AddOrderSurcharge>(OrderAction.ORDER_ADD_ADJUSTMENT, {
            adjustment: surcharge,
          });
          const surchargeAmount = getAdjustmentValue(
            processingPaymentAmount,
            [surcharge],
            { adjustmentType: surcharge.adjustmentType },
          );

          total = processingPaymentAmount + surchargeAmount;
        }

        createCardPayment(
          total,
          order?.orderNumber as string,
          paymentType,
          total - processingPaymentAmount,
        );
      }, 500);
    },
    [order, updateCart, processingPaymentAmount, createCardPayment],
  );

  const payExactTillAmount = useCallback(async () => {
    const paymentType = getPaymentTypeByName(OOLIO_PAY_PAYMENT_TYPE);
    if (paymentType) {
      if (paymentType.adjustmentPrompt) {
        showModal(
          <PaymentSurcharge
            orderTotal={processingPaymentAmount}
            onSubmit={applySurchargeTillPay}
            paymentType={paymentType}
            checkAllowAdjustmentPermission={checkAllowAdjustmentPermission}
          />,
        );
      } else {
        createCardPayment(
          processingPaymentAmount,
          order?.orderNumber as string,
          paymentType,
        );
      }
    }
  }, [
    order,
    getPaymentTypeByName,
    showModal,
    processingPaymentAmount,
    applySurchargeTillPay,
    checkAllowAdjustmentPermission,
    createCardPayment,
  ]);

  const payExactCashAmount = useCallback(
    async (type: string, paymentType: PaymentType | undefined) => {
      if (paymentType && paymentType.adjustmentPrompt) {
        showModal(
          <PaymentSurcharge
            orderTotal={processingPaymentAmount}
            onSubmit={applySurcharge}
            paymentType={paymentType}
            checkAllowAdjustmentPermission={checkAllowAdjustmentPermission}
          />,
        );
      } else {
        onPressPayAmount(
          getPaymentTypeByName(type) as PaymentType,
          processingPaymentAmount,
        );
      }
    },
    [
      applySurcharge,
      checkAllowAdjustmentPermission,
      getPaymentTypeByName,
      onPressPayAmount,
      processingPaymentAmount,
      showModal,
    ],
  );

  const onPressPayExactAmount = useCallback(
    async paymentName => {
      if (order && !disableOrderActions && !isZeroAmountDue(order)) {
        const callEvent = areCartItemsValid('order.incorrectOrder');
        if (callEvent) {
          switch (paymentName) {
            case 'cash':
              const paymentType = paymentTypes.find(
                currentPaymentType =>
                  currentPaymentType.name.toLowerCase() == paymentName,
              );
              payExactCashAmount(paymentName, paymentType);
              break;
            case 'card':
              if (netInfo.isConnected) {
                payExactTillAmount();
              } else {
                showNotification({
                  message: translate('order.offlineWarning'),
                  error: true,
                });
              }
              break;
          }
        }
      }
    },
    [
      isZeroAmountDue,
      order,
      disableOrderActions,
      areCartItemsValid,
      paymentTypes,
      payExactCashAmount,
      netInfo.isConnected,
      payExactTillAmount,
      showNotification,
      translate,
    ],
  );

  const onPressCustomPay = useCallback(() => {
    const callEvent = areCartItemsValid('order.incorrectOrder');
    if (callEvent && !isZeroAmountDue(order)) {
      const onPressCashPay = (amount: number) => {
        onPressPayAmount(getPaymentTypeByName('Cash'), amount);
      };

      showModal(
        <CashPaymentModal
          amountDue={processingPaymentAmount}
          receivingAmount={advancedKeypadValue || processingPaymentAmount}
          onSubmit={onPressCashPay}
          enableRoundOff={enableRoundOff}
          roundOffValue={roundOffValue}
        />,
        {
          onBackdropPress: closeModal,
        },
      );
    }
  }, [
    areCartItemsValid,
    isZeroAmountDue,
    order,
    showModal,
    processingPaymentAmount,
    advancedKeypadValue,
    enableRoundOff,
    roundOffValue,
    closeModal,
    onPressPayAmount,
    getPaymentTypeByName,
  ]);

  const onPressAddNewMoneyEvent = useCallback(
    () =>
      showModal(<MoneyEventModal />, {
        onBackdropPress: closeModal,
      }),
    [showModal, closeModal],
  );

  const switchPricingGroup = useCallback(
    value => {
      if (value) {
        storage.setItem(USER_EXPLICIT_SELECT_PRICING_GROUP, true);
        storage.setItem(DEVICE_PRICING_GROUP_ID, value);
        setPricingGroup(value);
        closeModal();
      }
    },
    [closeModal, setPricingGroup],
  );

  const onPressSwitchPricingGroup = useCallback(
    () =>
      showModal(
        <PickerModal
          value={pricingGroup}
          onSelect={switchPricingGroup}
          title={translate('order.switchPricingGroup')}
          options={pricingGroupOptions}
        />,
        {
          onBackdropPress: closeModal,
        },
      ),
    [
      showModal,
      closeModal,
      pricingGroupOptions,
      translate,
      pricingGroup,
      switchPricingGroup,
    ],
  );

  const onPressItemAvailability = useCallback(
    () =>
      showModal(<ItemAvailability />, {
        onBackdropPress: closeModal,
      }),
    [showModal, closeModal],
  );

  const switchMenu = useCallback(
    value => {
      if (value) {
        const menu = menus.find(menu => menu.id === value);
        if (menu) {
          setSession({
            ...session,
            deviceProfile: {
              ...session.deviceProfile,
              menu: {
                id: value,
                ...session.deviceProfile?.menu,
              } as Catalogue,
            },
          });
        }
        setMenuId(value);
        closeModal();
      }
    },
    [closeModal, setMenuId, menus, session, setSession],
  );

  const menuOptions = useMemo(() => {
    const options = map(menus, menu => ({
      label: menu.name,
      value: menu.id,
    }));
    return options;
  }, [menus]);

  const onPressSwitchMenu = useCallback(
    () =>
      showModal(
        <PickerModal
          value={menuId}
          onSelect={switchMenu}
          title={translate('order.switchMenu')}
          options={menuOptions}
        />,
        {
          onBackdropPress: closeModal,
        },
      ),
    [showModal, closeModal, menuOptions, translate, menuId, switchMenu],
  );

  const onPressLogout = useCallback(async () => {
    if (netInfo.isConnected) {
      await logout();
    } else {
      showNotification({
        message: translate('order.offlineWarning'),
        error: true,
      });
    }
  }, [logout, netInfo, showNotification, translate]);

  const onPressShowRewards = useCallback(async () => {
    if (!order?.customer) {
      return showNotification({
        error: true,
        message: translate('customerLoyalty.requireAddCustomer'),
      });
    }
    showModal(
      <CustomerRewardModal
        customer={assignedCustomer as Customer}
        onRedeem={() => {
          // TODO: handle redemption
          closeModal();
        }}
      />,
    );
  }, [
    order?.customer,
    showModal,
    assignedCustomer,
    showNotification,
    closeModal,
    translate,
  ]);

  const printPreviousReceipt = useCallback(() => {
    const lastCompletedOrder = getLatestCompletedOrderByDevice(
      session.device?.id,
    );
    lastCompletedOrder && printBill(lastCompletedOrder);
  }, [getLatestCompletedOrderByDevice, printBill, session.device?.id]);

  const resendToKitchen = useCallback(() => {
    if (order && selectedCartItem) {
      const selectedOrderItem = orderItems.find(
        item => item.id == selectedCartItem.item,
      );
      selectedOrderItem &&
        reprintKitchenDocket &&
        reprintKitchenDocket(order, [selectedOrderItem as OrderItem]);
    }
  }, [order, orderItems, reprintKitchenDocket, selectedCartItem]);

  const actionDispatcher = useCallback(
    async (functionName: string | undefined, params: string | undefined) => {
      const item = orderItems.find(
        x => x.id === selectedCartItem?.item,
      ) as OrderItem;
      escapeDiscardModal.current = true;
      switch (functionName) {
        case FunctionMapActions.VOID_SALE:
          onPressVoidSale();
          break;
        case FunctionMapActions.OPEN_TABLE:
          onPressOpenTable();
          break;
        case FunctionMapActions.ADD_NOTE:
          onPressUpdateOrderNotes();
          break;
        case FunctionMapActions.PAY:
          checkForUnfiredItems(() => {
            const exactPayCallback = () => onPressPayExactAmount(params);
            if (orderType?.code === OrderTypeCode.DINE_IN && !order?.table)
              openTableNumberModal(exactPayCallback);
            else exactPayCallback();
          });
          break;
        case FunctionMapActions.CASH_PAY:
          if (
            advancedKeypadValue &&
            processingPaymentAmount &&
            advancedKeypadValue < processingPaymentAmount
          ) {
            showNotification({
              message: translate('payment.amountCannotBeLessThanRemaining'),
              error: true,
            });
            return;
          }
          checkForUnfiredItems(() => {
            if (orderType?.code === OrderTypeCode.DINE_IN && !order?.table)
              openTableNumberModal(onPressCustomPay);
            else onPressCustomPay();
          });
          break;
        case FunctionMapActions.ADD_NEW_CUSTOMER:
          onPressAddNewCustomer();
          break;
        case FunctionMapActions.BACK:
          setFunctionMap('');
          break;
        case FunctionMapActions.MONEY_IN_OUT:
          onPressAddNewMoneyEvent();
          break;
        case FunctionMapActions.END_SHIFT:
          onPressShift();
          break;
        case FunctionMapActions.PRINT_SHIFT_SUMMARY:
          onPrintShift();
          break;
        case FunctionMapActions.SWITCH_PRICING_GRP:
          onPressSwitchPricingGroup();
          break;
        case FunctionMapActions.ORDER_RECEIPT:
          sendOrderReceipt();
          break;
        case FunctionMapActions.OPEN_DRAWER:
          openCashDrawer();
          break;
        case FunctionMapActions.PRINT_PREV_RECEIPT:
          printPreviousReceipt();
          break;
        case FunctionMapActions.RESEND_TO_KITCHEN:
          resendToKitchen();
          break;
        case FunctionMapActions.DISCOUNT_PERCENT:
          escapeDiscardModal.current = false;
          if (params) {
            onApplyDiscount(AdjustmentUnit.PERCENTAGE, params, item);
          }
          break;
        case FunctionMapActions.DISCOUNT_PRICE:
          escapeDiscardModal.current = false;
          if (params) {
            onApplyDiscount(AdjustmentUnit.FLAT, params, item);
          }
          break;
        case FunctionMapActions.SURCHARGE_PRICE:
          escapeDiscardModal.current = false;
          if (params) {
            onApplySurcharge(AdjustmentUnit.FLAT, params, item);
          }
          break;
        case FunctionMapActions.SURCHARGE_PERCENT:
          escapeDiscardModal.current = false;
          if (params) {
            onApplySurcharge(AdjustmentUnit.PERCENTAGE, params, item);
          }
          break;
        case FunctionMapActions.UPDATE_PRICE:
          onPressUpdatePriceMap(params, item);
          break;
        case FunctionMapActions.SYSTEM_LOGOUT:
          onPressLogout();
          break;
        case FunctionMapActions.SWITCH_MENU:
          onPressSwitchMenu();
          break;
        case FunctionMapActions.ITEM_AVAILABILITY:
          onPressItemAvailability();
          break;
        case FunctionMapActions.SHOW_REWARDS:
          onPressShowRewards();
          break;
      }
    },
    [
      orderItems,
      escapeDiscardModal,
      selectedCartItem?.item,
      onPressVoidSale,
      onPressOpenTable,
      onPressUpdateOrderNotes,
      checkForUnfiredItems,
      advancedKeypadValue,
      processingPaymentAmount,
      onPressAddNewCustomer,
      onPressAddNewMoneyEvent,
      onPressShift,
      onPrintShift,
      onPressSwitchPricingGroup,
      sendOrderReceipt,
      openCashDrawer,
      printPreviousReceipt,
      resendToKitchen,
      onPressUpdatePriceMap,
      onPressLogout,
      onPressSwitchMenu,
      onPressItemAvailability,
      orderType?.code,
      order?.table,
      openTableNumberModal,
      onPressPayExactAmount,
      showNotification,
      translate,
      onPressCustomPay,
      onApplyDiscount,
      onApplySurcharge,
      onPressShowRewards,
    ],
  );

  const actionMap = useMemo(() => {
    let actionsArray: {
      id: string;
      name: string;
      action?: string;
      params?: string;
      isGroup?: boolean;
      groupId?: string;
      color?: string;
      order?: number;
      feature?: string;
      featureContext?: string;
      onPress?: () => void;
    }[] = [];
    if (session.deviceProfile && session.deviceProfile.functionMap) {
      const actions = session.deviceProfile.functionMap?.functionActions || [];

      if (functionMap == '') {
        actionsArray = actions.filter(action => !action.groupId);
        actionsArray = actionsArray.sort(
          (a, b) => (a.order || 0) - (b.order || 0),
        );
      } else {
        actionsArray = actions.filter(
          action => action.groupId && action.groupId == functionMap,
        );
        actionsArray = actionsArray.map(action => {
          if (
            action.action === FunctionMapActions.DISCOUNT_PRICE ||
            action.action === FunctionMapActions.SURCHARGE_PRICE
          ) {
            const formattedLabel = replaceFunctionMapCurrency(
              action.name,
              appendCurrency,
            );
            return {
              ...action,
              name: formattedLabel,
            };
          }
          return action;
        });
        actionsArray = actionsArray.map(action => {
          if (
            action.action === FunctionMapActions.UPDATE_PRICE &&
            (action.params === SET_PRICE_OPTIONS.SURCHARGE_PRICE ||
              action.params === SET_PRICE_OPTIONS.DISCOUNT_PRICE)
          ) {
            return {
              ...action,
              name: action.name.replace('$', currencySymbol),
            };
          }
          return action;
        });
        const backButton = {
          id: 'back-button',
          name: 'BACK',
          isGroup: false,
          color: '#fff',
          action: FunctionMapActions.BACK,
        };
        actionsArray = actionsArray.sort(
          (a, b) => (a.order || 0) - (b.order || 0),
        );
        actionsArray = [backButton, ...actionsArray];
      }
      actionsArray = actionsArray.map(action => {
        if (action) {
          return {
            ...action,
            onPress: (): void => {
              if (action.isGroup) {
                if (
                  action.name ===
                    FUNCTION_MAPS_FOR_ORDER_COMPLETED.functionMapNames[1] &&
                  order?.orderItems?.length !== 0
                ) {
                  showNotification({
                    message: translate('order.manageFunctionWarning'),
                    error: true,
                  });
                } else if (
                  action.name.toUpperCase() === 'DISCOUNTS' ||
                  action.name.toUpperCase() === 'SURCHARGES'
                ) {
                  if (!checkAllowAdjustmentPermission()) return;
                  setFunctionMap(action.id);
                } else {
                  setFunctionMap(action.id);
                }
              } else {
                actionDispatcher(action.action, action.params);
              }
            },
          };
        } else {
          return action;
        }
      });
      if (isOrderComplete) {
        actionsArray = actionsArray.filter(action => {
          if (
            FUNCTION_MAPS_FOR_ORDER_COMPLETED.functionMapNames.includes(
              action.name,
            )
          )
            return true;
          else return false;
        });
      }
      return [...actionsArray];
    } else {
      return [];
    }
  }, [
    session.deviceProfile,
    functionMap,
    isOrderComplete,
    order?.orderItems?.length,
    showNotification,
    translate,
    checkAllowAdjustmentPermission,
    actionDispatcher,
    appendCurrency,
    currencySymbol,
  ]);

  return actionMap;
};

export default useFunctionMaps;
