import { FeatureContext, Features } from '@hitz-group/domain';
import { translate } from '@hitz-group/localization';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { useRoute } from '@react-navigation/native';
import React from 'react';
import TabBar from '../../../../components/TabBar/TabBar';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';
// import { FunctionMaps } from './FunctionMaps/FunctionMaps';
import { AccountSales } from './AccountSales/AccountSales';
import Adjustments from './Adjustments/Adjustments';
import { CashManagement } from './CashManagement/CashManagement';
import { DetailsContainer } from './Details/DetailsContainer';
import { SectionManagement } from './SectionManagement/SectionManagement';

const Tab = createMaterialTopTabNavigator();

const VenueSettingsTabs: React.FC = () => {
  const route = useRoute();
  const params = route.params as {
    venueId: string;
    screen: string;
  };
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isTableFeatureEnabled = isFeatureEnabled(
    Features.TABLE_MANAGEMENT,
    FeatureContext.VENUE,
    params.venueId,
  );

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar
          tabBar={props as MaterialTopTabBarProps}
          previousRoute={'VenuesStores'}
        />
      )}
      initialRouteName={params.screen ?? 'Details'}
    >
      <Tab.Screen
        name="Details"
        component={DetailsContainer}
        initialParams={params}
      />
      <Tab.Screen
        name="Adjustments"
        component={Adjustments}
        initialParams={params}
      />
      <Tab.Screen
        name="CashManagement"
        component={CashManagement}
        options={{
          tabBarLabel: translate('storesSettings.tabNames.manageCash'),
        }}
        initialParams={params}
      />
      {/* <Tab.Screen
        name="FunctionMaps"
        options={{ tabBarLabel: 'Function Maps' }}
        component={FunctionMaps}
        initialParams={params}
      /> */}
      <Tab.Screen
        name="AccountSales"
        component={AccountSales}
        options={{
          tabBarLabel: translate('storesSettings.tabNames.customerAccount'),
        }}
        initialParams={params}
      />

      {isTableFeatureEnabled && (
        <Tab.Screen
          name="SectionManagement"
          component={SectionManagement}
          options={{
            tabBarLabel: translate(
              'storesSettings.tabNames.sectionsManagement',
            ),
          }}
          initialParams={params}
        />
      )}
    </Tab.Navigator>
  );
};

export default VenueSettingsTabs;
