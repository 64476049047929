import {
  StyleFn,
  Option,
  CreateOptionInput,
  ConvertProductToVariantInput,
  ProductPricingInput,
  DEFAULT_PRICING_GROUP,
  DEFAULT_TAX,
} from '@hitz-group/domain';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import Button from '../../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { VariantOptionsRow } from './VariantOptionsRow';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { useVariants } from '../../../../../hooks/app/variants/useVariants';
import { useOptions } from '../../../../../hooks/app/useOptions';
import findIndex from 'lodash/findIndex';
import { Operation } from '../../../../../types/Operation';
import { useProductPricings } from '../../../../../hooks/app/useProductPricings';
import { usePricingGroups } from '../../../../../hooks/app/usePricingGroups';
import { useTaxes } from '../../../../../hooks/app/useTaxes';

const updateVariantsButtonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.border,
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  borderRadius: theme.radius.small,
});

const updateVariantsButtonLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  fontFamily: theme.font.regular,
  fontSize: theme.fontSize.small,
  lineHeight: 21,
  letterSpacing: -0.5,
  alignSelf: 'center',
  textAlign: 'center',
  textTransform: 'uppercase',
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const mainViewStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const backOfficeContentStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const attributeButtonsContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
});

const createButtonStyle: StyleFn = () => ({
  marginRight: 'auto',
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const backOfficeContainerStyle: StyleFn = ({ theme, zIndex }) => ({
  width: 600,
  alignSelf: 'center',
  paddingTop: theme.padding.medium * 3,
  zIndex: zIndex,
});
export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});
export const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

export const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

export const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

interface VariantSettingsForProductProps {
  productId: string;
}

export const VariantSettingsForProduct: React.FC<
  VariantSettingsForProductProps
> = ({ productId }) => {
  const { css, theme } = useFela();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [selectedOptionId, setSelectedOptionId] = useState('');
  const [createdOptionValue, setCreatedOptionValue] = useState('');
  const [productData, setProduct] = useState(
    {} as { id: string; options: Option[] },
  );
  const { currency } = useCurrency();

  const { error: taxesError, taxesOptions } = useTaxes();

  const {
    error: varErr,
    convertProductToVariant,
    loading: varLoading,
    operation: varOperation,
    variants,
    getVariantData,
  } = useVariants();

  const {
    options,
    error: optErr,
    createOption,
    createdOptionId,
    loading: optLoading,
    operation: optOperation,
    updateOption,
  } = useOptions();

  const {
    error: errPP,
    loading: loadingPP,
    operation: operationPP,
    addBulkProductPricings: addPP,
  } = useProductPricings();

  const { defaultPricingGroup, error: PGError } = usePricingGroups();

  const { products, error: prodErr } = useProducts(productId);

  const error = varErr || optErr || prodErr || PGError || taxesError;

  // default tax should be GST
  const defaultTax =
    taxesOptions?.filter(x => x.label === DEFAULT_TAX)?.[0]?.value || '';

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!errPP && !loadingPP && operationPP === Operation.CREATE && productId) {
      showNotification({
        success: true,
        message: translate(
          'productSettings.productConvertedToVariantSuccessfully',
        ),
      });
      // redirect to variant settings for variant
      navigation.navigate('ProductSettings', {
        productId: productId,
        isVariant: true,
        isVariantProduct: false,
        screen: 'Variants',
      });
    }
  }, [
    errPP,
    loadingPP,
    operationPP,
    navigation,
    showNotification,
    productId,
    translate,
  ]);

  const productDefaultTax = useMemo(() => {
    let tax = defaultTax;
    if (productId && products && products[productId]) {
      const defaultPricingGroup = products[productId]?.pricingGroups?.filter(
        x => x.name === DEFAULT_PRICING_GROUP,
      );
      if (defaultPricingGroup.length) {
        tax = defaultPricingGroup[0]?.prices[0]?.sellingTax?.id;
      }
    }
    return tax;
  }, [productId, products, defaultTax]);

  useEffect(() => {
    if (
      productId &&
      variants[productId] &&
      defaultPricingGroup.id &&
      varOperation === Operation.READ &&
      !varLoading &&
      !varErr
    ) {
      const addProductPricings: {
        productId: string;
        pricings: {
          pricingGroupId: string;
          productPricing: ProductPricingInput;
        }[];
      }[] = [];

      const variantTemp = { ...variants[productId] };
      variantTemp.products.forEach(prod => {
        addProductPricings.push({
          pricings: [
            {
              pricingGroupId: defaultPricingGroup.id,
              productPricing: {
                pricingGroupId: defaultPricingGroup.id,
                product: prod.id,
                costPrice: {
                  amount: 0,
                  currency: currency,
                },
                sellingPrice: {
                  amount: 0,
                  currency: currency,
                },
                sellingTax: productDefaultTax,
                taxInclusive: true,
              } as ProductPricingInput,
            },
          ],
          productId: prod.id,
        });
      });
      addPP(addProductPricings);
    }
  }, [
    variants,
    productId,
    addPP,
    defaultPricingGroup,
    currency,
    varOperation,
    varLoading,
    varErr,
    productDefaultTax,
  ]);

  useEffect(() => {
    if (
      !varLoading &&
      !varErr &&
      varOperation === Operation.CREATE &&
      productId &&
      !variants[productId]
    ) {
      getVariantData(productId);
    }
  }, [varLoading, varOperation, varErr, getVariantData, productId, variants]);

  useEffect(() => {
    if (
      !optErr &&
      !optLoading &&
      createdOptionId &&
      optOperation === Operation.CREATE &&
      options[createdOptionId] &&
      selectedOptionId
    ) {
      setProduct(prev => {
        const prodData = { ...prev };
        const index = findIndex(prodData.options, { id: selectedOptionId });
        if (index >= 0) {
          prodData.options[index] = {
            id: createdOptionId,
            key: options[createdOptionId].key,
            values: options[createdOptionId].values,
          };
        }
        return prodData;
      });
      showNotification({
        success: true,
        message: translate('productSettings.optionCreatedSuccessfully'),
      });
    }
  }, [
    optErr,
    optLoading,
    createdOptionId,
    optOperation,
    options,
    showNotification,
    translate,
    selectedOptionId,
  ]);

  useEffect(() => {
    if (
      !optErr &&
      !optLoading &&
      optOperation === Operation.UPDATE &&
      selectedOptionId &&
      createdOptionValue
    ) {
      showNotification({
        success: true,
        message: translate('productSettings.optionUpdatedSuccessfully'),
      });
      setProduct(prev => {
        const prodData = { ...prev };
        const index = findIndex(prodData.options, { id: selectedOptionId });
        if (index >= 0) {
          prodData.options[index] = {
            ...prodData.options[index],
            values: [...prodData.options[index].values, createdOptionValue],
          };
        }
        return prodData;
      });
      setCreatedOptionValue('');
    }
  }, [
    optErr,
    optLoading,
    optOperation,
    options,
    showNotification,
    translate,
    selectedOptionId,
    createdOptionValue,
  ]);

  useEffect(() => {
    if (productId && products && products[productId]) {
      const variantTemp = { ...products[productId] };
      setProduct({
        id: variantTemp.id,
        options: [],
      });
      setCreatedOptionValue('');
    }
  }, [products, productId, currency]);

  const allKeys = useMemo(() => {
    const optionsAvailable = Object.keys(options);
    const optionsInVariant = (productData?.options || []).map(x => x.id);
    const keys: { value: string; label: string }[] = [];
    optionsAvailable.forEach(x => {
      if (!optionsInVariant.includes(x)) {
        keys.push({
          label: options[x].key,
          value: x,
        });
      }
    });
    return keys;
  }, [options, productData]);

  const onCreate = useCallback(() => {
    const productDataTemp = { ...productData };
    const optionsAvailable = Object.keys(options);
    if (!productDataTemp.options) {
      productDataTemp.options = [];
    }
    const optionsInVariant = productDataTemp.options.map(x => x.id);
    let optionToAdd = '';
    optionsAvailable.forEach(x => {
      if (!optionToAdd && !optionsInVariant.includes(x)) {
        optionToAdd = x;
      }
    });
    if (optionToAdd) {
      productDataTemp.options.push({
        id: optionToAdd,
        key: options[optionToAdd].key,
        values: options[optionToAdd].values,
      });
    } else {
      productDataTemp.options.push({
        id: '',
        key: '',
        values: [],
      });
    }
    setProduct(productDataTemp);
  }, [productData, options]);

  const onChangeValues = useCallback((id, values) => {
    setProduct(prev => {
      const productDataTemp = { ...prev };
      const index = findIndex(productDataTemp.options, { id });
      if (index >= 0) {
        productDataTemp.options[index] = {
          ...productDataTemp.options[index],
          values,
        };
      }
      return productDataTemp;
    });
  }, []);

  const onAddOption = useCallback(
    (name, selectedOptionId) => {
      const input = { key: name, values: [] } as CreateOptionInput;
      if (selectedOptionId) {
        setSelectedOptionId(selectedOptionId);
      }
      createOption(input);
    },
    [createOption],
  );

  const onAddOptionValue = useCallback(
    (id, name) => {
      const currentOption = { ...options[id] };
      if (currentOption && !currentOption.values.includes(name)) {
        currentOption.values = [...currentOption.values, name];

        setSelectedOptionId(id);
        setCreatedOptionValue(name);
        updateOption({
          id: currentOption.id,
          key: currentOption.key,
          values: currentOption.values,
        });
      }
    },
    [options, updateOption],
  );

  const onDeleteOptionRow = useCallback(
    id => {
      const productDataTemp = { ...productData };
      const index = findIndex(productDataTemp.options, { id });
      if (index >= 0) {
        productDataTemp.options.splice(index, 1);
        setProduct(productDataTemp);
      }
    },
    [productData],
  );

  const updateVariantDetails = useCallback(() => {
    const updateInput = {
      product: productData.id,
      options: productData.options.map(x => ({ id: x.id, values: x.values })),
    } as ConvertProductToVariantInput;
    convertProductToVariant(updateInput);
  }, [convertProductToVariant, productData]);

  const onChangeSelectedOption = useCallback(
    (prevId, selectedId) => {
      const prodData = { ...productData };
      const index = findIndex(prodData.options, { id: prevId });
      if (index >= 0) {
        prodData.options[index] = {
          ...options[selectedId[0]],
        };
        setProduct(prodData);
      }
    },
    [options, productData],
  );
  const onPressSave = () => {
    console.log('');
  };
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.variantSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(mainViewStyle)}>
          <BackOfficeSection
            contentContainerStyle={css(backOfficeContentStyle)}
            containerStyle={css(
              backOfficeContainerStyle({ theme, zIndex: 100 }),
            )}
            title={translate('productSettings.attributes')}
            titleDescription={translate(
              'productSettings.attributesDescription',
            )}
            action={
              <View style={css(attributeButtonsContainerStyle)}>
                <View style={css(createButtonStyle)}>
                  <BackOfficeCreateNewButton onPress={onCreate} />
                </View>
                <Button
                  title={translate('productSettings.updateVariants')}
                  containerStyle={css(updateVariantsButtonStyle)}
                  labelStyle={css(updateVariantsButtonLabelStyle)}
                  onPress={updateVariantDetails}
                />
              </View>
            }
          >
            <TableComponent
              columns={[
                {
                  title: translate('productSettings.attribute'),
                  width: 140,
                  containerStyle: { paddingLeft: theme.spacing.big / 2 },
                },
                {
                  title: translate('productSettings.values'),
                  flex: 1,
                  alignItems: 'flex-start',
                  containerStyle: { paddingLeft: theme.spacing.medium },
                },
              ]}
              data={productData.options || []}
              normalRows
              columnContainerStyle={css(columnContainerStyle)}
              renderRow={(item: Option, index: number): React.ReactNode => (
                <VariantOptionsRow
                  allKeys={allKeys}
                  selectedOption={item}
                  allValues={options[item.id]?.values}
                  key={index}
                  index={index}
                  onValuesChange={onChangeValues}
                  onAddOption={onAddOption}
                  onAddOptionValue={onAddOptionValue}
                  onDeleteOptionRow={onDeleteOptionRow}
                  onChangeSelectedOption={onChangeSelectedOption}
                  isDisabled={false}
                />
              )}
            />
          </BackOfficeSection>
        </ScrollView>
      </View>
      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
            disabled={false}
            loading={false}
          />
        </View>
      </View>
    </>
  );
};
