/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloError,
  OperationVariables,
  QueryLazyOptions,
} from '@apollo/client';
import {
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client/react/hooks';
import {
  Feature,
  Organization,
  ToggleFeature,
  Venue,
} from '@hitz-group/domain';
import { useCallback, useState } from 'react';
import { Operation } from '../../../types/Operation';
import { getError, isLoading } from '../../../utils/apolloErrorResponse.util';
import { noopHandler, parseApolloError } from '../../../utils/errorHandlers';
import { stripProperties } from '../../../utils/stripObjectProps';
import { useSession } from '../useSession';
import {
  DISABLE_FEATURE_AT_ORG,
  DISABLE_FEATURE_AT_VENUE,
  ENABLE_FEATURE_AT_ORG,
  ENABLE_FEATURE_AT_VENUE,
  GET_FEATURE,
  GET_FEATURES,
  GET_FEATURES_BY_VENUE,
  GET_INTEGRATIONS,
} from './graphql';

export interface EnabledFeatureVenues {
  venue: Venue;
  featureId: string;
  enabled: boolean;
}
export interface ToggleFeatureInput {
  featureId: string;
  venueId: string;
}

export interface ToggleFeatureMap {
  enable: ToggleFeatureInput[];
  disable: ToggleFeatureInput[];
}

export interface useFeaturesProps {
  loadings: boolean;
  integrations: Feature[];
  getIntegrations: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  getFeatureVenues: (
    options?:
      | QueryLazyOptions<{
          id: string;
        }>
      | undefined,
  ) => void;
  venues: EnabledFeatureVenues[];
  error: string | undefined;
  getFeatures: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  features: Feature[];
  currentFeature: { feature: Feature };
  response: string[];
  disableFeatureOrgLevel: (ToggleFeature: ToggleFeature) => void;
  disableOperation: Operation;
  toggleEnableFeatureOrgLevel: (ToggleFeature: ToggleFeature) => void;
  enableOperation: Operation;
  toggleFeatureVenue: (inputMap: ToggleFeatureMap) => Promise<void>;
  toggleFeatureOperation: Operation;
  enableFeatureVenueOrgLevelRequestData: any;
  disableFeatureVenueOrgLevelRequestData: any;
}

export const useFeatures = (featureId?: string): useFeaturesProps => {
  const [features, setFeatures] = useState<Feature[]>([]);
  const onCompleteRequest = useCallback(data => {
    const key = Object.keys(data)[0];
    setFeatures(stripProperties(data[key] as Feature, '__typename'));
  }, []);
  const [integrations, setIntegrations] = useState<Feature[]>([]);
  const [session, setSession] = useSession();

  const [getFeaturesRequest, getFeaturesResponse] = useLazyQuery(GET_FEATURES, {
    fetchPolicy: 'network-only',
    onCompleted: onCompleteRequest,
  });

  const [getIntegrationsRequest, getIntegrationsResponse] = useLazyQuery(
    GET_INTEGRATIONS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => setIntegrations(data.integrations),
    },
  );

  const { data, loading } = useQuery(GET_FEATURE, {
    variables: { id: featureId },
    errorPolicy: 'all',
    skip: !featureId,
  });

  const singleFeatureLoading = loading;
  const [venues, setFeatureVenues] = useState<EnabledFeatureVenues[]>([]);
  const onCompleteVenueRequest = useCallback(data => {
    const key = Object.keys(data)[0];
    setFeatureVenues(
      stripProperties(data[key] as EnabledFeatureVenues, '__typename'),
    );
  }, []);

  const [getFeaturesByVenueRequest, getFeaturesByVenueResponse] = useLazyQuery(
    GET_FEATURES_BY_VENUE,
    {
      variables: { id: featureId },
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteVenueRequest,
    },
  );

  const getFeatureVenues = useCallback(() => {
    if (featureId) getFeaturesByVenueRequest();
  }, [getFeaturesByVenueRequest, featureId]);

  const [disableOperation] = useState<Operation>(Operation.READ);

  const [disableFeatureOrgLevel, disableFeatureVenueOrgLevelRequest] =
    useMutation(DISABLE_FEATURE_AT_ORG, {
      onError: noopHandler,
      notifyOnNetworkStatusChange: true,
    });

  const disableResponses: string[] = disableFeatureVenueOrgLevelRequest.data;

  const [enableOperation] = useState<Operation>(Operation.READ);

  const toggleFeatureHelper = (
    locationArr: Organization[] | Venue[],
    locationIndex: number,
    feature: ToggleFeature | ToggleFeatureInput,
    status: boolean,
  ) => {
    const newLocationArr = locationArr;
    if (locationIndex !== -1) {
      const updatedFeatures = locationArr[locationIndex].features?.map(feat => {
        if (feat.id === feature.featureId) {
          feat.enabledByDefault = status;
          return feat;
        }
        return feat;
      });

      newLocationArr[locationIndex] = {
        ...newLocationArr[locationIndex],
        features: updatedFeatures,
      };
    }
    return newLocationArr;
  };

  const toggleOrgLevelFeature = useCallback(
    (featuresToUpdate: ToggleFeature[], status: boolean) => {
      const orgs = session.user?.organizations;
      let updatedOrgs: Organization[] | undefined;

      orgs &&
        session.currentOrganization?.id &&
        featuresToUpdate.forEach(feature => {
          const orgIndx = orgs?.findIndex(
            o => o.id === session.currentOrganization?.id,
          );
          updatedOrgs = toggleFeatureHelper(
            orgs,
            orgIndx,
            feature,
            status,
          ) as unknown as Organization[];
        });

      setSession({
        ...session,
        currentOrganization:
          updatedOrgs && (updatedOrgs[0] as unknown as Organization),
        user: { ...session.user, organizations: updatedOrgs && updatedOrgs },
      });
    },
    [session, setSession],
  );

  const toggleVenueFeaturesInSession = useCallback(
    (featuresToUpdate: ToggleFeatureInput[], status: boolean) => {
      const venues = session.user?.venues;
      let updatedVenues: Venue[] | undefined;

      venues &&
        featuresToUpdate.forEach(feature => {
          const venueIndx = venues.findIndex(
            venue => venue.id === feature.venueId,
          );
          updatedVenues = toggleFeatureHelper(
            updatedVenues ? updatedVenues : venues,
            venueIndx,
            feature,
            status,
          ) as unknown as Venue[];
        });

      setSession({
        ...session,
        user: {
          ...session.user,
          venues: updatedVenues ? updatedVenues : venues,
        },
      });
    },
    [session, setSession],
  );

  const toggleFeatureOnVenue = useCallback(
    (ToggleFeature: ToggleFeature) => {
      disableFeatureOrgLevel({
        variables: {
          input: ToggleFeature,
        },
      });
      toggleOrgLevelFeature([ToggleFeature], false);
    },
    [disableFeatureOrgLevel, toggleOrgLevelFeature],
  );

  const [toggleEnableFeatureOrgLevel, enableFeatureVenueOrgLevelRequest] =
    useMutation(ENABLE_FEATURE_AT_ORG, {
      onError: noopHandler,
    });

  const toggleFeatureEnableVenueData = useCallback(
    (ToggleFeature: ToggleFeature) => {
      toggleEnableFeatureOrgLevel({
        variables: {
          input: ToggleFeature,
        },
      });
      toggleOrgLevelFeature([ToggleFeature], true);
    },
    [toggleEnableFeatureOrgLevel, toggleOrgLevelFeature],
  );

  const [toggleFeatureOperation] = useState<Operation>(Operation.READ);

  const [enableFeatureVenue, enableFeatureVenueRequest] = useMutation(
    ENABLE_FEATURE_AT_VENUE,
    {
      onError: noopHandler,
    },
  );

  const [disableFeatureVenue, disableFeatureVenueRequest] = useMutation(
    DISABLE_FEATURE_AT_VENUE,
    {
      onError: noopHandler,
    },
  );

  const toggleFeatureVenueFn = useCallback(
    async (inputMap: ToggleFeatureMap) => {
      const calls = [];
      if (inputMap.enable.length > 0) {
        calls.push(
          enableFeatureVenue({
            variables: {
              input: inputMap.enable,
            },
          }),
        );
      }

      if (inputMap.disable.length > 0) {
        calls.push(
          disableFeatureVenue({
            variables: {
              input: inputMap.disable,
            },
          }),
        );
      }

      if (calls.length > 0) {
        await Promise.all(calls);
        toggleVenueFeaturesInSession(inputMap.enable, true);
        toggleVenueFeaturesInSession(inputMap.disable, false);
      }
    },
    [enableFeatureVenue, disableFeatureVenue, toggleVenueFeaturesInSession],
  );

  const TOGGLE_FEATURE_RESPONSE_ENTITIES = [
    enableFeatureVenueRequest,
    disableFeatureVenueRequest,
  ];

  const toggleFeatureLoading = isLoading(TOGGLE_FEATURE_RESPONSE_ENTITIES);
  const toggleFeatureError = getError(TOGGLE_FEATURE_RESPONSE_ENTITIES);
  const enableFeatureVenueOrgLevelRequestData =
    enableFeatureVenueOrgLevelRequest.data;
  const disableFeatureVenueOrgLevelRequestData =
    disableFeatureVenueOrgLevelRequest.data;
  const errors: ApolloError | undefined =
    getFeaturesResponse.error ||
    getFeaturesByVenueResponse.error ||
    disableFeatureVenueOrgLevelRequest.error ||
    enableFeatureVenueOrgLevelRequest.error ||
    toggleFeatureError ||
    getIntegrationsResponse.error;
  const loadings: boolean =
    getFeaturesResponse.loading ||
    getIntegrationsResponse.loading ||
    singleFeatureLoading ||
    getFeaturesByVenueResponse.loading ||
    disableFeatureVenueOrgLevelRequest.loading ||
    enableFeatureVenueOrgLevelRequest.loading ||
    toggleFeatureLoading;

  return {
    loadings,
    integrations,
    getIntegrations: getIntegrationsRequest,
    getFeatureVenues,
    venues,
    error: errors ? parseApolloError(errors) : undefined,
    getFeatures: getFeaturesRequest,
    features,
    currentFeature: data,
    response: disableResponses,
    disableFeatureOrgLevel: toggleFeatureOnVenue,
    disableOperation,
    toggleEnableFeatureOrgLevel: toggleFeatureEnableVenueData,
    enableOperation,
    toggleFeatureVenue: toggleFeatureVenueFn,
    toggleFeatureOperation,
    enableFeatureVenueOrgLevelRequestData,
    disableFeatureVenueOrgLevelRequestData,
  };
};
