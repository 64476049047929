import React from 'react';
import { StyleFn } from '@hitz-group/domain';
import { View } from 'react-native';
import { useFela } from 'react-fela';
import { PanViewProps } from './PanView';
import './style.css';

const container: StyleFn = ({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: 5,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.background,
});
const tableGridContainer: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  height: 665,
  width: 1024,
  borderRadius: theme.radius.large,
  overflow: 'hidden',
});

const PanView: React.FC<PanViewProps> = ({ children }) => {
  const { css } = useFela();
  return (
    <View style={css(container)}>
      <View style={css(tableGridContainer)}>
        <div className="pattern-container">{children}</div>
      </View>
    </View>
  );
};

export default PanView;
