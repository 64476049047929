import { StyleFn } from '@hitz-group/domain';
import React, { useState } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import Modal from '../../../../../components/Modals/Modal';
import Button from '../../../../../components/Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import { isAndroid } from '../../../../../common/theme';

interface AddPrinterProfileProps {
  onSubmit: (selectedPrinterProfiles: string[]) => void;
  printerProfileOptions: { label: string; value: string }[];
  countOfproducts: number;
}

const createModalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const buttonStyle: StyleFn = ({ theme }) => ({
  width: 280,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginTop: theme.spacing.big,
  zIndex: 200,
});

const createVenueLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});
const textStyle: StyleFn = () => ({
  marginTop: isAndroid ? -6 : 0,
});

const selectedItemStyle: StyleFn = () => ({
  marginVertical: 5,
});

const printerProfileContainerStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: 'auto',
  marginTop: isAndroid ? theme.padding.small + 2 : 0,
});

const touchableStyle: StyleFn = () => ({
  minHeight: 40,
  flexWrap: 'wrap',
});

export const AddPrinterProfile: React.FC<AddPrinterProfileProps> = ({
  onSubmit,
  countOfproducts,
  printerProfileOptions,
}: AddPrinterProfileProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const defaultPP = printerProfileOptions?.[0]?.value || '';
  const [selectedPP, setSelectedPP] = useState<string[]>([defaultPP]);

  const { closeModal } = useModal();
  return (
    <Modal
      title={translate('productBulkOperations.assignPrnterProfile')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
    >
      <MultipleSelect
        label={translate('productSettings.printerProfile')}
        values={printerProfileOptions}
        textStyle={css(textStyle)}
        selectedValues={selectedPP}
        selectedItemStyle={css(selectedItemStyle)}
        showCheckboxes={false}
        containerStyle={css(printerProfileContainerStyle)}
        onValueChange={val => setSelectedPP(val)}
        touchableStyle={css(touchableStyle)}
      />

      <Button
        title={translate(
          'productBulkOperations.assignPrinterProfilesToProducts',
          {
            countOfproducts,
          },
        )}
        containerStyle={css(buttonStyle)}
        labelStyle={css(createVenueLabelStyle)}
        color={theme.colors.green}
        onPress={() => onSubmit(selectedPP)}
      />
    </Modal>
  );
};
