import { StyleFn } from '@hitz-group/domain';

export const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 800,
});

export const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  paddingLeft: theme.spacing.big,
  alignItems: 'center',
  flex: 1,
});

export const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

export const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

export const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

export const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
  ...theme.shadow15,
  paddingLeft: theme.padding.large,
});
