import { ApolloError } from '@apollo/client';
import { useApolloClient, useLazyQuery } from '@apollo/client/react/hooks';
import {
  Connection,
  Order,
  OrderFilterInput,
  OrderStatus,
  ORDERS_LIMIT,
} from '@hitz-group/domain';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { parseApolloError } from '../../../utils/errorHandlers';
import {
  GET_ORDER,
  GET_ORDERS,
  GET_ORDER_FRAGMENT,
  GET_PAGINATED_ORDER_QUERY,
} from './graphql';
import { refetchOrderObservable } from './ordersObservableUtils';
import { useIsMounted } from '../../../useIsMounted';
import { useSession } from '../useSession';
import { pendingOnlineOrdersCountVar } from '../../../state/cache';
import { isValidOnlineOrder } from '../../../utils/OnlineOrdersHelper';

export interface CursorType {
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  filter?: OrderFilterInput;
}

export interface UseOrdersProps {
  orders: Record<string, Order>;
  loading: boolean;
  error: string | undefined;
  getPaginatedOrders: (props: CursorType) => void;
  getOnlineOrders: (statuses: OrderStatus[]) => void;
  recentCursor: string;
  getOrdersFromCache: (status: OrderStatus, isOnline?: boolean) => void;
  getOrderFromCache: (orderId: string) => Order | undefined;
  returnOrdersFromCache: (status: OrderStatus, isOnline?: boolean) => Order[];
  refetchOrdersFromServer: () => Promise<boolean>;
  getLatestCompletedOrderByDevice: (deviceId?: string) => Order | undefined;
  getOnlineOrdersFromCache: (statusArr: OrderStatus[]) => void;
}

const GET_PAGINATED_IN_PROGRESS_ORDERS = {
  query: GET_PAGINATED_ORDER_QUERY,
  variables: {
    filter: {
      status: OrderStatus.IN_PROGRESS,
    },
  },
};

const GET_PAGINATED_COMPLETE_ORDERS = {
  query: GET_PAGINATED_ORDER_QUERY,
  variables: {
    after: '',
    filter: {
      status: OrderStatus.COMPLETED,
    },
    first: ORDERS_LIMIT,
  },
};

const GET_PAGINATED_REFUND_ORDERS = {
  query: GET_PAGINATED_ORDER_QUERY,
  variables: {
    after: '',
    filter: {
      status: OrderStatus.REFUNDED,
    },
    first: ORDERS_LIMIT,
  },
};

export function useOrders(): UseOrdersProps {
  const [orders, setOrdersData] = useState<Record<string, Order>>({});
  const client = useApolloClient();
  const isMounted = useIsMounted();
  const [session] = useSession();
  const [paginatedOrdersRequest, ordersResponse] = useLazyQuery(
    GET_PAGINATED_ORDER_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const getOnlineOrdersFromCache = useCallback(
    (statusArr: OrderStatus[]) => {
      if (client) {
        let orders: Order[] = [];

        statusArr.forEach(status => {
          const clientOrders = client.cache.readQuery({
            query: GET_ORDERS,
            returnPartialData: true,
            variables: {
              filter: { status, isOnline: true },
            } as {
              filter: OrderFilterInput;
            },
          }) as { orders: Order[] };

          if (Array.isArray(clientOrders?.orders)) {
            orders = orders.concat(clientOrders?.orders);
          }
        });

        setOrdersData(keyBy(orders, 'id'));
      }
    },
    [client],
  );

  const getOrdersFromCache = useCallback(
    (status: OrderStatus, isOnline?: boolean) => {
      if (client) {
        const clientOrders = client.cache.readQuery({
          query: GET_ORDERS,
          returnPartialData: true,
          variables: { filter: { status, ...(isOnline && { isOnline }) } } as {
            filter: OrderFilterInput;
          },
        }) as { orders: Order[] };

        const orders = clientOrders?.orders;

        if (
          (status === OrderStatus.IN_PROGRESS ||
            status === OrderStatus.CREATED) &&
          Array.isArray(clientOrders?.orders)
        ) {
          // Not keeping previous ones, as order might be voided.
          // VOID orders will be removed from CREATED or IN_PROGRESS cache.
          setOrdersData(keyBy(orders, 'id'));
        } else if (clientOrders?.orders?.length) {
          setOrdersData(prev => ({ ...keyBy(orders, 'id'), ...prev }));
        }
      }
      return [];
    },
    [client],
  );

  const getOrderFromCache = useCallback(
    (orderId: string): Order | undefined => {
      if (client) {
        const cacheQueryResult = client.cache.readQuery({
          query: GET_ORDER,
          returnPartialData: true,
          variables: { orderId },
        }) as { order: Order };
        if (cacheQueryResult?.order) return cacheQueryResult?.order;

        const cacheFragmentResult =
          client.cache.readFragment<Order>({
            id: `Order:${orderId}`,
            fragment: GET_ORDER_FRAGMENT,
          }) || undefined;
        return cacheFragmentResult;
      }
      return undefined;
    },
    [client],
  );

  const returnOrdersFromCache = useCallback(
    (status: OrderStatus, isOnline?: boolean): Order[] => {
      if (client) {
        const clientOrders = client.cache.readQuery({
          query: GET_ORDERS,
          returnPartialData: true,
          variables: { filter: { status, ...(isOnline && { isOnline }) } } as {
            filter: OrderFilterInput;
          },
        }) as { orders: Order[] };
        const orders = clientOrders?.orders || [];
        return orders;
      } else {
        return [];
      }
    },
    [client],
  );

  const writeOrdersToCache = useCallback(
    (
      ordersData: { paginatedOrders: Connection<Order> },
      status: OrderStatus,
      isOnline?: boolean,
    ) => {
      /**
       * For CREATED, IN_PROGRESS orders we are accepting blank array as well,
       * As we have to clear cache if there are no open orders.
       */
      if (
        client &&
        (((status === OrderStatus.IN_PROGRESS ||
          status === OrderStatus.CREATED) &&
          Array.isArray(ordersData?.paginatedOrders?.edges)) ||
          (status && ordersData?.paginatedOrders?.edges?.length))
      ) {
        const paginatedOrders = ordersData.paginatedOrders as Connection<Order>;
        const ordersMap: Record<string, Order> = {};
        paginatedOrders.edges.forEach(eachEdge => {
          eachEdge?.node?.id &&
            (ordersMap[eachEdge?.node?.id] = {
              ...eachEdge.node,
              lastSyncedEventId: eachEdge.node.prevEventId,
            });
        });

        const clientOrders = client.cache.readQuery({
          query: GET_ORDERS,
          returnPartialData: true,
          variables: { filter: { status, ...(isOnline && { isOnline }) } } as {
            filter: OrderFilterInput;
          },
        }) as { orders: Order[] };

        const updatedOrders: Record<string, Order> = {
          ...ordersMap,
        };

        if (
          status !== OrderStatus.IN_PROGRESS &&
          status !== OrderStatus.CREATED
        ) {
          Object.assign(updatedOrders, keyBy(clientOrders?.orders || [], 'id'));
        }

        client.cache.writeQuery({
          query: GET_ORDERS,
          variables: { filter: { status, ...(isOnline && { isOnline }) } } as {
            filter: OrderFilterInput;
          },
          data: {
            orders: Object.values(updatedOrders) as Order[],
          },
        });
        isMounted() && setOrdersData(prev => ({ ...ordersMap, ...prev }));
      }
    },
    [client, isMounted],
  );

  useEffect(() => {
    if (ordersResponse.data) {
      const { filter } = ordersResponse.variables as {
        filter: { status: OrderStatus; isOnline: boolean };
      };
      if (filter?.status) {
        writeOrdersToCache(
          ordersResponse.data,
          filter?.status as OrderStatus,
          !!filter?.isOnline,
        );
      }
    }
  }, [ordersResponse.variables, ordersResponse.data, writeOrdersToCache]);

  const getRecentCursor = useCallback((paginatedOrders: Connection<Order>) => {
    let result = '';
    if (paginatedOrders?.pageInfo?.hasNextPage) {
      result = paginatedOrders?.pageInfo?.endCursor || '';
    }
    return result;
  }, []);

  const recentCursor = useMemo(() => {
    /**
     * recentCursor
     * returns the endCursor of paginated orders query of recently used
     */
    let result = '';
    if (ordersResponse.data) {
      const paginatedOrders = ordersResponse.data
        .paginatedOrders as Connection<Order>;
      result = getRecentCursor(paginatedOrders);
    }
    return result;
  }, [ordersResponse.data, getRecentCursor]);

  const recentCursorOfOrders = useCallback(
    (
      status: OrderStatus,
      input: {
        recentCursor?: string;
        fromDate?: number;
        toDate?: number;
        orderType?: string;
        paymentType?: string;
        searchText?: string;
      },
    ): string | undefined => {
      const {
        fromDate: fromDateTime,
        recentCursor,
        toDate: toDateTime,
        orderType,
        searchText,
        paymentType,
      } = input || {};

      const filter = {
        after: recentCursor || '',
        filter: {
          status: status,
          ...(fromDateTime && toDateTime && { fromDateTime, toDateTime }),
          ...(orderType && { orderType }),
          ...(searchText && { searchText }),
          ...(paymentType && { paymentType }),
        },
        first: ORDERS_LIMIT,
      } as CursorType;

      const result = client.cache.readQuery({
        query: GET_PAGINATED_ORDER_QUERY,
        variables: filter,
      }) as { paginatedOrders: Connection<Order> };

      if (
        result?.paginatedOrders?.edges &&
        result?.paginatedOrders?.pageInfo?.endCursor &&
        result?.paginatedOrders?.pageInfo?.hasNextPage
      ) {
        // check if recent cursor data already there in cache
        return recentCursorOfOrders(status, {
          recentCursor: result?.paginatedOrders?.pageInfo?.endCursor,
          fromDate: fromDateTime,
          toDate: toDateTime,
          orderType,
          paymentType,
          searchText,
        });
      } else if (
        result?.paginatedOrders?.edges &&
        !result?.paginatedOrders?.pageInfo?.hasNextPage
      ) {
        return undefined;
      }

      return recentCursor || '';
    },
    [client],
  );

  const getPaginatedOrders = useCallback(
    ({ first, after, filter }: CursorType) => {
      const {
        status,
        toDateTime,
        fromDateTime,
        orderType,
        paymentType,
        searchText,
      } = filter || {};
      if (status === OrderStatus.COMPLETED || status === OrderStatus.REFUNDED) {
        let recentCursor = recentCursorOfOrders(status, {
          recentCursor: after || '',
          ...(toDateTime && { toDate: toDateTime }),
          ...(fromDateTime && { fromDate: fromDateTime }),
        });

        if (
          recentCursor !== undefined &&
          (searchText || paymentType || orderType)
        ) {
          recentCursor = recentCursorOfOrders(status, {
            recentCursor: after || '',
            toDate: toDateTime || 0,
            fromDate: fromDateTime || 0,
            orderType,
            paymentType,
            searchText,
          });
        }
        if (recentCursor !== undefined) {
          paginatedOrdersRequest({
            variables: {
              first,
              after: recentCursor,
              filter,
            },
          });
        }
      } else {
        paginatedOrdersRequest({
          variables: {
            first,
            after: after || '',
            filter,
          },
        });
      }
    },
    [paginatedOrdersRequest, recentCursorOfOrders],
  );

  const getOnlineOrders = useCallback(
    async (statuses: OrderStatus[]) => {
      for (const status of statuses) {
        const result = await client.query({
          query: GET_PAGINATED_ORDER_QUERY,
          variables: {
            filter: {
              status,
              isOnline: true,
            },
          },
          fetchPolicy: 'network-only',
        });

        const paginatedOrders = result.data
          .paginatedOrders as Connection<Order>;
        const pendingOrdersCount = paginatedOrders.edges.filter(
          eachOrder =>
            eachOrder.node.status === OrderStatus.CREATED &&
            isValidOnlineOrder(eachOrder.node),
        ).length;
        pendingOnlineOrdersCountVar(pendingOrdersCount);

        writeOrdersToCache(
          result?.data as { paginatedOrders: Connection<Order> },
          status,
          true,
        );
      }
    },
    [client, writeOrdersToCache],
  );

  const refetchOrdersFromServer = useCallback(async () => {
    /**
     * Calls paginated query and keeps data in cache
     * get all open orders
     * get 50 completed orders
     * usually calls / used when pos app logges in
     * Let IN_PROGRESS order overwrite COMPLETE order
     * Then status of table in table management will be based on IN_PROGRESS order
     * calls online in progess and created status orders if online orders is turned on
     */

    // network call for completed orders
    const completedOrders = await client.query({
      ...GET_PAGINATED_COMPLETE_ORDERS,
      fetchPolicy: 'network-only',
    });

    writeOrdersToCache(
      completedOrders?.data as { paginatedOrders: Connection<Order> },
      OrderStatus.COMPLETED,
    );

    const inProgressOrders = await client.query({
      ...GET_PAGINATED_IN_PROGRESS_ORDERS,
      fetchPolicy: 'network-only',
    });

    writeOrdersToCache(
      inProgressOrders?.data as { paginatedOrders: Connection<Order> },
      OrderStatus.IN_PROGRESS,
    );

    const refundedOrders = await client.query({
      ...GET_PAGINATED_REFUND_ORDERS,
      fetchPolicy: 'network-only',
    });

    writeOrdersToCache(
      refundedOrders?.data as { paginatedOrders: Connection<Order> },
      OrderStatus.REFUNDED,
    );

    const isOnlineOrdersEnabled = session?.deviceProfile?.enableOnlineOrders;

    if (isOnlineOrdersEnabled) {
      await getOnlineOrders([OrderStatus.CREATED, OrderStatus.IN_PROGRESS]);
    }

    refetchOrderObservable.next({
      timestamp: Date.now(),
    });

    return true;
  }, [
    client,
    getOnlineOrders,
    session?.deviceProfile?.enableOnlineOrders,
    writeOrdersToCache,
  ]);

  const getLatestCompletedOrderByDevice = useCallback(
    (deviceId?: string) => {
      if (!deviceId) return;
      const completedOrders = returnOrdersFromCache(OrderStatus.COMPLETED);
      const sortedOrdersByDevices = orderBy(
        completedOrders.filter(
          order => order?.updatedByDevice?.id === deviceId,
        ),
        ['updatedAt'],
        ['desc'],
      );
      return sortedOrdersByDevices[0];
    },
    [returnOrdersFromCache],
  );

  const error: ApolloError | undefined = ordersResponse.error;

  const loading: boolean = ordersResponse.loading;

  return {
    orders,
    loading,
    error: error ? parseApolloError(error) : undefined,
    getPaginatedOrders,
    recentCursor,
    getOrdersFromCache,
    refetchOrdersFromServer: refetchOrdersFromServer,
    returnOrdersFromCache,
    getOrderFromCache,
    getLatestCompletedOrderByDevice,
    getOnlineOrders,
    getOnlineOrdersFromCache,
  };
}
