import gql from 'graphql-tag';

const catalogueFragment = `
  id
  name
  count
  venues {
    id
    name
  }
  stores {
    id
    name
  }
  itemGroups {
    id
    count
    priority
    page {
      id
      name
    }
    items {
      id
      type
      priority
      item {
        ... on Product {
          id
          name
        }
        ... on Page {
          id
          name
        }
        ... on Variant {
          id
          name
        }
      }
    }
  }
`;

export const GET_MENU_OPTIONS = gql`
  query {
    catalogues {
      id
      name
    }
  }
`;

export const GET_MENUS_QUERY = gql`
  query {
    catalogues {
      ${catalogueFragment}
    }
  }
`;

export const GET_MENU_QUERY = gql`
  query catalogue($id: ID!) {
    catalogue(id: $id) {
      ${catalogueFragment}
    }
  }
`;

export const CREATE_MENU = gql`
  mutation createCatalogue($input: CreateCatalogueInput!) {
    createCatalogue(input: $input) {
      ${catalogueFragment}
    }
  }
`;

export const COPY_MENU = gql`
  mutation cloneCatalogue($input: ID!) {
    cloneCatalogue(id: $input) {
      ${catalogueFragment}
    }
  }
`;

export const UPDATE_MENU = gql`
  mutation updateCatalogue($input: UpdateCatalogueInput) {
    updateCatalogue(input: $input) {
      ${catalogueFragment}
    }
  }
`;

export const DELETE_MENU = gql`
  mutation deleteCatalogues($input: [ID!]!) {
    deleteCatalogues(ids: $input)
  }
`;
