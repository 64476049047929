import { StyleFn, Option } from '@hitz-group/domain';
import React from 'react';
import { View } from 'react-native';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import IconButton from '../../../../../components/Button/IconButton';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';

const dropDownStyle: StyleFn = ({ theme }) => ({
  minHeight: theme.input.height,
  flex: 1,
  marginTop: theme.spacing.small,
  marginLeft: theme.spacing.small,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  maxWidth: 35,
  marginLeft: theme.spacing.small,
  marginTop: theme.spacing.small,
  marginRight: theme.spacing.small,
  alignSelf: 'center',
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const attributesDropDownStyle: StyleFn = ({ theme }) => ({
  width: 140,
  minHeight: theme.input.height,
  marginTop: theme.spacing.small,
  alignSelf: 'center',
});

const selectPlaceHolderStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
  lineHeight: 21,
  letterSpacing: -0.5,
});

const rowStyle: StyleFn = ({ theme, index }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  paddingBottom: theme.padding.medium / 2,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
  zIndex: 1000 - 10 * index,
});
const touchableStyle: StyleFn = () => ({
  minHeight: 40,
  flexWrap: 'wrap',
});

interface VariantOptionsRowProps {
  allKeys: { value: string; label: string }[];
  selectedOption: Option;
  allValues: string[];
  index: number;
  onValuesChange?: (id: string, values: string[]) => void;
  onAddOption?: (name: string, selectedOptionId: string) => void;
  onAddOptionValue?: (id: string, name: string) => void;
  onDeleteOptionRow?: (id: string) => void;
  onChangeSelectedOption?: (id: string, values: string[]) => void;
  isDisabled: boolean;
  deleteRequestModal?: (
    func: Function,
    isSelected: boolean | undefined,
    value: string,
  ) => void;
  addRequestModal?: (
    func: Function,
    isSelected: boolean | undefined,
    value: string,
  ) => void;
}

export const VariantOptionsRow: React.FC<VariantOptionsRowProps> = ({
  allKeys,
  selectedOption,
  allValues,
  index,
  onValuesChange,
  onAddOption,
  onAddOptionValue,
  onDeleteOptionRow,
  onChangeSelectedOption,
  isDisabled,
  deleteRequestModal,
  addRequestModal,
}: VariantOptionsRowProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();

  return (
    <View style={css(rowStyle({ theme, index }))}>
      <MultipleSelect
        values={allKeys}
        placeholder={selectedOption.key}
        showCheckboxes={false}
        onAddItem={name => onAddOption && onAddOption(name, selectedOption.id)}
        containerStyle={css(attributesDropDownStyle)}
        searchLabel={translate('productSettings.setAttribute') + ':'}
        searchPlaceHolder={translate('productSettings.searchAttribute') + '...'}
        maxItems={3}
        placeHolderStyle={css(selectPlaceHolderStyle)}
        onValueChange={values =>
          onChangeSelectedOption &&
          onChangeSelectedOption(selectedOption.id, values)
        }
        isDisabled={isDisabled}
        touchableStyle={css(touchableStyle)}
      />

      <MultipleSelect
        selectedValues={selectedOption.values}
        containerStyle={css(dropDownStyle)}
        showCheckboxes={false}
        maxItems={3}
        onAddItem={value =>
          onAddOptionValue && onAddOptionValue(selectedOption.id, value)
        }
        values={(allValues || []).map(x => ({
          value: x,
          label: x,
        }))}
        searchPlaceHolder={
          translate('productSettings.searchOrAddValue') + '...'
        }
        searchLabel={translate('productSettings.searchLabel')}
        onValueChange={values =>
          onValuesChange && onValuesChange(selectedOption.id, values)
        }
        isDisabled={isDisabled}
        deleteRequestModal={deleteRequestModal}
        addRequestModal={addRequestModal}
        touchableStyle={css(touchableStyle)}
      />

      {onDeleteOptionRow && (
        <IconButton
          testID={'delete-icon'}
          icon="TrashAlt"
          iconSize={24}
          containerSize={34}
          containerStyle={css(closeIconContainerStyle)}
          iconStyle={css(closeIconStyle)}
          onPress={() => onDeleteOptionRow(selectedOption.id)}
        />
      )}
    </View>
  );
};
