import {
  StackNavigationState,
  PartialState,
  LinkingOptions,
} from '@react-navigation/native';

import {
  App,
  FeatureContext,
  Features,
  FeatureSetting,
} from '@hitz-group/domain';

/* istanbul ignore file */
export interface NavMenu {
  title: string;
  icon: string;
  children: string[];
  route?: Array<{
    title: string;
    routing: string;
    feature?: FeatureSetting;
  }>;
  feature?: {
    name: Features;
    context: FeatureContext;
  };
}

type ParamsList = Record<string, object | undefined>;

export const navigateToBackOfficeParams:
  | StackNavigationState<ParamsList>
  | PartialState<StackNavigationState<ParamsList>> = {
  index: 0,
  routes: [
    {
      name: 'BackOffice',
      state: {
        routes: [
          { name: 'Account', state: { routes: [{ name: 'Overview' }] } },
        ],
      },
    },
  ],
};

export const navigateToLockScreen = (
  app: App = App.POS_APP,
  notAutoRedirect?: boolean,
):
  | StackNavigationState<ParamsList>
  | PartialState<StackNavigationState<ParamsList>> => ({
  index: 0,
  routes: [
    {
      name: 'POS',
      state: {
        routes: [
          {
            name: 'Lock',
            params: {
              app,
              notAutoRedirect,
            },
          },
        ],
      },
    },
  ],
});

export const backOfficeNavigation: Array<NavMenu> = [
  {
    title: 'Account',
    icon: 'user-circle',
    children: [
      'Overview',
      // 'Profile',
      // 'Subscriptions',
      // 'Payment Methods',
      // 'Invoices',
      // 'Organizations',
    ],
    route: [
      { title: 'Overview', routing: 'Overview' },
      // { title: 'Profile', routing: 'Profile' },
      // { title: 'Subscriptions', routing: 'Subscriptions' },
      // { title: 'Payment Methods', routing: 'PaymentMethods' },
      // { title: 'Invoices', routing: 'Invoices' },
      // { title: 'Organizations', routing: 'Organizations' },
    ],
  },
  {
    title: 'Dashboard',
    icon: 'tachometer-fast',
    children: ['Sales Overview'],
    route: [{ title: 'Sales Overview', routing: 'SalesOverview' }],
  },
  {
    title: 'Products',
    icon: 'tag-alt',
    children: [
      'Manage',
      'Menus',
      'Modifiers',
      'Pages',
      // 'Courses',
      'Price Lists',
      // 'Product Tags',
      // 'Side Groups',
    ],
    route: [
      { title: 'Manage', routing: 'Manage' },

      { title: 'Menus', routing: 'Menus' },

      { title: 'Options', routing: 'Options' },
      {
        title: 'Courses',
        routing: 'Courses',
        feature: {
          name: Features.COURSES,
          context: FeatureContext.VENUE,
          showTabIfEnabled: true,
        },
      },
      { title: 'Pages', routing: 'Pages' },
      {
        title: 'Price Lists',
        routing: 'PriceLists',
        feature: {
          name: Features.PRICE_LIST,
          context: FeatureContext.VENUE,
          showTabIfEnabled: true,
        },
      },
      // { title: 'Product Tags', routing: 'ProductTags' },
      // { title: 'Side Groups', routing: 'SideGroups' },
    ],
  },
  // {
  //   title: 'Inventory',
  //   icon: 'shopping-cart',
  //   children: [
  //     'Dashboard',
  //     'Stock Check',
  //     'Purchases',
  //     'Transfers',
  //     'Stock Rate',
  //     'Suppliers',
  //   ],
  //   route: [
  //     { title: 'Dashboard', routing: 'Dashboard' },
  //     { title: 'Stock Check', routing: 'StockCheck' },
  //     { title: 'Purchases', routing: 'Purchases' },
  //     { title: 'Transfers', routing: 'Transfers' },
  //     { title: 'Stock Rate', routing: 'StockRate' },
  //     { title: 'Suppliers', routing: 'Suppliers' },
  //   ],
  // },
  {
    title: 'Customers',
    icon: 'users-alt',
    children: [
      'Manage',
      // 'Groups'
    ],
    route: [
      { title: 'Manage', routing: 'Manage' },
      // { title: 'Groups', routing: 'Groups' },
    ],
  },

  {
    title: 'Loyalty',
    icon: 'star',
    children: ['Overview', 'Activity Log', 'Customers', 'Settings'],
    route: [
      { title: 'Overview', routing: 'Overview' },

      { title: 'Activity Log', routing: 'Activity Log' },

      { title: 'Customers', routing: 'Customers' },

      { title: 'Settings', routing: 'Settings' },
    ],
    feature: {
      name: Features.LOYALTY,
      context: FeatureContext.ORGANIZATION,
    },
  },
  {
    title: 'Reports',
    icon: 'analytics',
    children: [
      'Sales',
      // 'Inventory',
      'Payments',
      'Shift Summaries',
      // 'Customers', 'Users'
      'Users',
      'Voids & Refunds',
    ],
    route: [
      { title: 'Sales', routing: 'Sales' },
      // { title: 'Inventory', routing: 'Inventory' },
      { title: 'Payments', routing: 'Payments' },
      { title: 'Shift Summaries', routing: 'ShiftSummaries' },
      // { title: 'Customers', routing: 'Customers' },
      { title: 'Users', routing: 'Users' },
      { title: 'Voids & Refunds', routing: 'VoidsRefunds' },
    ],
  },
  {
    title: 'Users',
    icon: 'user',
    children: [
      'Manage',
      'Job Roles',
      // 'Roster'
    ],
    route: [
      { title: 'Manage', routing: 'ManageUsers' },
      { title: 'Job Roles', routing: 'JobRoles' },
      // { title: 'Roster', routing: 'Roster' },
    ],
  },
  {
    title: 'Features',
    icon: 'create-dashboard',
    children: ['Manage'],
    route: [{ title: 'Manage', routing: 'ManageFeatures' }],
  },
  {
    title: 'Settings',
    icon: 'cog',
    children: [
      'General',
      'Venues & Stores',
      'My Store',
      'Taxes & Fees',
      'Payment Types',
      'Sales Channels',
      'Printing',
      'Other',
    ],
    route: [
      { title: 'General', routing: 'General' },
      {
        title: 'Venues & Stores',
        routing: 'VenuesStores',
        feature: {
          name: Features.MULTI_VENUE,
          context: FeatureContext.ORGANIZATION,
          showTabIfEnabled: true,
        },
      },
      {
        title: 'My Store',
        routing: 'MyStore',
        feature: {
          name: Features.MULTI_VENUE,
          context: FeatureContext.ORGANIZATION,
          showTabIfEnabled: false,
        },
      },
      { title: 'Taxes & Fees', routing: 'TaxesFees' },
      { title: 'Payment Types', routing: 'PaymentTypes' },
      { title: 'Sales Channels', routing: 'SalesChannels' },
      { title: 'Printing', routing: 'Printing' },
      { title: 'Other', routing: 'Other' },
    ],
  },
];

export const deepLinkConfig: LinkingOptions['config'] = {
  screens: {
    Login: 'login',
    Signup: 'signup',
    POS: {
      path: 'pos',
      screens: {
        AssignToDevice: 'assign-to-device',
        Orders: {
          screens: {
            TakeOrder: 'order/:id?',
            OpenOrders: 'orders/open-orders',
            Payment: 'orders/payment',
            SearchCustomer: 'orders/:id/search-customer',
            AddCustomer: 'orders/:id/add-customer',
            PrintJobs: 'orders/print-queue',
            OnlineOrders: 'orders/online-orders',
            FloorView: {
              path: 'orders/floor-view',
              screens: {
                Section: ':name?',
              },
            },
          },
        },
        POSSettings: {
          path: 'settings',
          screens: {
            Account: 'account',
            POSMenuEditor: 'menu-editor',
          },
        },
        Lock: 'unlock',
        ManageMoney: 'manage-money',
        OrderHistory: 'orders/history',
        Customers: 'customers',
        Shift: 'shift',
      },
    },
    BackOffice: {
      path: 'backoffice',
      initialRouteName: 'Account',
      screens: {
        Account: {
          initialRouteName: 'Overview',
          path: 'account',
          screens: {
            Overview: 'overview',
          },
        },
        Dashboard: {
          initialRouteName: 'SalesOverview',
          path: 'dashboard',
          screens: {
            SalesOverview: 'sales-overview',
          },
        },
        Products: {
          initialRouteName: 'Manage',
          path: 'products',
          screens: {
            Menus: 'menus',
            MenuSettings: {
              initialRouteName: 'CreateMenu',
              path: 'menus/:menuId',
              screens: {
                CreateMenu: 'create-menu',
              },
            },
            Pages: 'pages',
            PageSettings: {
              initialRouteName: 'PageDetails',
              path: 'page/:pageId',
              screens: {
                PageDetails: 'page-details',
              },
            },
            Products: 'manage',
            Modifiers: {
              initialRouteName: 'ModifiersTabs',
              path: 'modifiers',
              screens: {
                ModifiersTabs: {
                  initialRouteName: 'ModifierGroupsTab',
                  path: 'groups',
                  screens: {
                    ModifierGroupsTab: 'modifier-groups-tab',
                    ModifierTab: 'modifier-list',
                  },
                },
                CreateModifierGroupTab: {
                  initialRouteName: 'CreateModifierGroup',
                  path: 'modifierGroup/:modifierGroupId',
                  screens: {
                    CreateModifierGroup: 'modifierGroup',
                  },
                },
              },
            },
            Courses: 'courses',
            PriceLists: 'price-lists',
            ProductTags: 'product-tags',
            SideGroups: 'side-groups',
            PriceListSettings: {
              initialRouteName: 'Details',
              path: 'price/:pricingGroupId',
              screens: {
                Details: 'details',
                Products: 'products',
              },
            },
            ProductSettings: {
              initialRouteName: 'Availability',
              path: 'product/:productId',
              screens: {
                Availability: 'availability',
                General: 'general',
                Options: 'options',
                Pricing: 'pricing',
                Variants: 'variants',
                Modifiers: 'modifiers',
                // Sides: 'sides',
              },
            },
          },
        },
        Customers: {
          initialRouteName: 'ManageCustomers',
          path: 'customers',
          screens: {
            ManageCustomers: {
              initialRouteName: 'ManageCustomersTab',
              path: 'manage',
              screens: {
                ManageCustomersTab: 'manage-customers-tab',
              },
            },
          },
        },
        Loyalty: {
          initialRouteName: 'Overview',
          path: 'Loyalty',
          screens: {
            Overview: 'overview',
            ActivityLog: 'activity-log',
            Customers: 'customers',
            Settings: 'settings',
          },
        },

        Users: {
          initialRouteName: 'ManageUsers',
          path: 'users',
          screens: {
            ManageUsers: {
              initialRouteName: 'ManageUsersTab',
              path: 'manage',
              screens: {
                ManageUsersTab: 'manage-users-tab',
              },
            },
            UserDetails: {
              initialRouteName: 'General',
              path: 'details',
              screens: {
                General: 'general',
                Venues: 'venues',
              },
            },
            JobRoles: 'job-roles',
            JobRoleSettings: {
              initialRouteName: 'CreateOrEditRole',
              path: 'job-roles/:roleId',
              screens: {
                CreateOrEditRole: 'details',
              },
            },
          },
        },
        Features: {
          initialRouteName: 'ManageFeatures',
          path: 'features',
          screens: {
            ManageFeatures: {
              initialRouteName: 'FeaturesTab',
              path: 'manage',
              screens: {
                FeaturesTab: {
                  initialRouteName: 'FeaturesOverview',
                  path: 'features-tab',
                  screens: {
                    ManageFeature: 'manage-feature',
                    FeaturesOverview: 'overview',
                  },
                },
                IntegrationsTab: 'integrations-tab',
              },
            },
            FeaturesSettings: {
              initialRouteName: 'AboutTab',
              path: 'about-feature/:featureId',
              screens: {
                AboutTab: 'about-feature',
              },
            },
            DoshiiSettings: {
              initialRouteName: 'AboutTab',
              path: 'doshii',
              screens: {
                AboutTab: 'doshii-about',
                SettingsTab: 'doshii-settings',
              },
            },
            DeliveritSettings: {
              initialRouteName: 'AboutTab',
              path: 'deliverit',
              screens: {
                AboutTab: 'deliverit-about',
                SettingsTab: 'deliverit-settings',
              },
            },
            XeroSettings: {
              initialRouteName: 'AboutTab',
              path: 'xero',
              screens: {
                AboutTab: 'xero-about',
                SettingsTab: 'xero-settings',
              },
            },
            DeputySettings: {
              initialRouteName: 'AboutTab',
              path: 'deputy',
              screens: {
                AboutTab: 'deputy-about',
                SettingsTab: 'settings',
                DeputyStoreTab: {
                  initialRouteName: 'DeputyStoreTab',
                  path: 'about-feature/:storeName',
                  screens: {
                    DeputyStoreTab: 'about-feature',
                  },
                },
              },
            },
            OolioStoreSettings: {
              initialRouteName: 'AboutTab',
              path: 'oolio-online-store',
              screens: {
                AboutTab: 'about',
                SettingsTab: 'settings',
              },
            },
            OnlineOrderIntegrationsSettings: {
              initialRouteName: 'AboutTab',
              path: 'online-order-integration/:app',
              screens: {
                AboutTab: 'about',
                SettingsTab: 'settings',
              },
            },
          },
        },
        Reports: {
          initialRouteName: 'SalesFeed',
          path: 'reports',
          screens: {
            Sales: {
              path: 'sales',
              screens: {
                SalesFeed: 'sales-feed',
                SalesSummary: 'sales-summary',
                Devices: 'devices',
                Products: 'products',
                HourlySales: 'hourly-sales',
                Staff: 'staff',
                Taxes: 'taxes',
                Customers: 'customers',
                ProductsSummary: 'products-summary',
                Dashboard: 'dashboard',
                Adjustments: 'adjustments',
              },
            },
            Payments: {
              path: 'payments-summary',
              screens: {
                Payments: 'payments',
              },
            },
            ShiftSummaries: {
              path: 'shift-summaries',
              screens: {
                Shifts: 'shifts',
              },
            },
            Users: {
              path: 'users-summary',
              screens: {
                Attendance: 'attendance',
              },
            },
            VoidsRefunds: {
              path: 'voids-refunds',
              screens: {
                Voids: 'voids',
              },
            },
          },
        },
        Settings: {
          initialRouteName: 'General',
          path: 'settings',
          screens: {
            General: 'general',
            VenuesStores: 'venues-and-stores',
            Printing: {
              initialRouteName: 'PrinterProfile',
              path: 'printing',
              screens: {
                PrinterProfile: 'printer-profile',
                ReceiptTemplate: 'receipt-template',
              },
            },
            Other: {
              initialRouteName: 'RefundReasons',
              path: 'other',
              screens: {
                RefundReasons: 'refund-reasons',
                ProductTypes: 'product-types',
                ReportSettings: 'report-settings',
              },
            },
            TaxesFees: {
              initialRouteName: 'Taxes',
              path: 'taxes-fees',
              screens: {
                Taxes: 'taxes',
                Fees: 'fees',
              },
            },
            PaymentTypes: 'payment-types',
            VenueSettings: {
              initialRouteName: 'Details',
              path: 'venue/:venueId',
              screens: {
                Details: 'details',
                Adjustments: 'adjustments',
                CashManagement: 'manage-cash',
                FunctionMaps: 'function-maps',
                SectionManagement: 'manage-section',
                AccountSales: 'account-sales',
              },
            },
            StoreSettings: {
              initialRouteName: 'Details',
              path: 'venue/:venueId/store/:storeId',
              screens: {
                Details: 'details',
                Checkout: 'checkout',
                Devices: 'devices',
                DeviceProfiles: 'device-profiles',
                Tables: 'tables',
                Printers: 'printers',
                ProfitMargins: 'profit-margins',
                DevicePrinterSetup: 'printing-setup',
              },
            },
            DeviceProfileSettings: {
              initialRouteName: 'Details',
              path: 'store/:storeId/device-profile/:deviceProfileId/venue/:venueId?',
              screens: {
                Details: 'details',
                Options: 'options',
                Workflow: 'workflow',
              },
            },
            DeviceSettings: {
              initialRouteName: 'Details',
              path: 'store/:storeId/device/:deviceId',
              screens: {
                Details: 'details',
                PrintingSetup: 'printing-setup',
              },
            },
            SectionSettings: {
              initialRouteName: 'Details',
              path: 'venue/:venueId/section/:sectionId',
              screens: {
                Details: 'details',
              },
            },
            SalesChannels: 'sales-channels',
            PrinterDetails: {
              initialRouteName: 'Details',
              path: 'store/:storeId/printer/:printerId',
              screens: {
                Details: 'details',
              },
            },
          },
        },
      },
    },
  },
};
