import React from 'react';
import { FelaComponent } from 'react-fela';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import { StyleProp, ImageStyle, View } from 'react-native';
import Till, { LogoFillColor } from './Till';

const containerStyle: StyleFn = ({ theme }) => ({
  width: 136,
  height: 100,
  padding: 0,
  marginBottom: theme.spacing.medium * 2.5,
  resizeMode: 'contain',
});

export interface LogoProps {
  testID?: string;
  style?: StyleProp<ImageStyle>;
  fillColor?: LogoFillColor;
}

const Logo: React.FC<LogoProps> = ({ style, testID, fillColor }: LogoProps) => {
  return (
    <FelaComponent style={containerStyle}>
      {({ style: defaultStyle }: RenderProps): React.ReactFragment => (
        <View style={[defaultStyle, style]} testID={testID}>
          <Till fillColor={fillColor} />
        </View>
      )}
    </FelaComponent>
  );
};

export default Logo;
