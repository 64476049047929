import { Icons, StyleFn } from '@hitz-group/domain';
import Title from '../Title/Title';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { TouchableOpacity, View } from 'react-native';
import IconButton from '../Button/IconButton';

export interface CourseItemProp {
  name: string;
  id: string;
  onPress?: () => void;
  disabled?: boolean;
  autoFire: boolean;
  onToggleAutoFire?: (courseId: string) => void;
  onPressCourseHeader?: (id: string) => void;
  isCollapsed?: boolean;
  onCollapseCourse: (id: string, status: boolean) => void;
  readonlyCourse?: boolean;
}

const headerContainer: StyleFn = ({}) => ({
  flexDirection: 'row',
  marginTop: 4,
});
const labelContainerStyle: StyleFn = ({
  theme,
  autoFire,
  disabled,
  readonlyCourse,
}) => ({
  flex: 1,
  backgroundColor: readonlyCourse
    ? theme.colors.faitOrange
    : disabled
    ? theme.colors.background
    : autoFire
    ? theme.colors.greenLighter
    : theme.colors.orangeLighter,

  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: theme.radius.small,
  paddingHorizontal: theme.padding.medium,
  justifyContent: 'space-between',
  height: 26,
});

const labelStyle: StyleFn = ({
  theme,
  autoFire,
  disabled,
  readonlyCourse,
}) => ({
  color: readonlyCourse
    ? theme.colors.black
    : disabled
    ? theme.colors.grey2
    : autoFire
    ? theme.colors.green
    : theme.colors.orange,
});

const toggleIconStyle: StyleFn = ({
  autoFire,
  theme,
  disabled,
  readonlyCourse,
}) => ({
  color: readonlyCourse
    ? theme.colors.black
    : disabled
    ? theme.colors.paragraphLight
    : autoFire
    ? theme.colors.green
    : theme.colors.orange,
});

const toggleIconContainerStyle: StyleFn = ({ autoFire, theme, disabled }) => ({
  backgroundColor: disabled
    ? theme.colors.background
    : autoFire
    ? theme.colors.greenLighter
    : theme.colors.orangeLighter,
  marginRight: 2,
  borderRadius: theme.radius.small,
  width: 28,
});

const titleContainer: StyleFn = ({}) => ({
  flex: 1,
  flexDirection: 'row',
});

const CourseWrapper: React.FC<CourseItemProp> = ({
  name,
  onToggleAutoFire,
  children,
  autoFire,
  id,
  disabled,
  isCollapsed,
  onCollapseCourse,
  onPressCourseHeader,
  readonlyCourse,
}) => {
  const { css } = useFela({ autoFire, disabled, readonlyCourse });
  const handleCollapse = useCallback(() => {
    onCollapseCourse(id, !isCollapsed);
  }, [id, isCollapsed, onCollapseCourse]);

  const handleToggle = useCallback(() => {
    onToggleAutoFire && onToggleAutoFire(id);
  }, [id, onToggleAutoFire]);

  const handlePressCourseHeader = useCallback(() => {
    onPressCourseHeader && onPressCourseHeader(id);
  }, [id, onPressCourseHeader]);

  return (
    <View>
      <View style={css(headerContainer)}>
        {!readonlyCourse && (
          <IconButton
            icon={autoFire ? Icons.Bolt : Icons.BoltSlash}
            iconSize={18}
            containerSize={26}
            iconStyle={css(toggleIconStyle)}
            onPress={handleToggle}
            containerStyle={css(toggleIconContainerStyle)}
            testID="autoFire-icon"
            disabled={disabled}
          />
        )}
        <View style={css(labelContainerStyle)}>
          <TouchableOpacity
            onPress={handlePressCourseHeader}
            style={css(titleContainer)}
            testID="course-name-wrapper"
          >
            <Title
              numberOfLines={1}
              labelStyle={css(labelStyle)}
              testID="course-name"
            >
              {name}
            </Title>
          </TouchableOpacity>
          {!readonlyCourse && (
            <IconButton
              icon={isCollapsed ? Icons.AngleDown : Icons.AngleUp}
              iconSize={24}
              containerSize={28}
              iconStyle={css(toggleIconStyle)}
              onPress={handleCollapse}
              testID="collapse-icon"
            />
          )}
        </View>
      </View>
      <View>{(!isCollapsed || readonlyCourse) && children}</View>
    </View>
  );
};

export default CourseWrapper;
