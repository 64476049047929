import { EventInput } from '@hitz-group/domain';
import { OrderEventModel } from '../models/orderEvent';

export const mapOrderEvent = (event: EventInput): OrderEventModel => {
  return {
    eventID: event.id,
    orderID: event.orderId,
    previous: event.previous,
    timestamp: event.timestamp,
    payload: event,
  };
};
