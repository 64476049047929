import { useCallback, useMemo, useState } from 'react';
import { SignupInput } from '@hitz-group/domain';
import fetch from 'cross-fetch';
import { REACT_APP_SIGNUP_API_URL } from 'react-native-dotenv';

export interface UseSignupProps {
  createOrganization: (input: SignupInput) => Promise<{
    error?: string;
    success?: boolean;
  }>;
  loading: boolean;
}

const SIGNUP_ENDPOINT =
  process.env['REACT_APP_SIGNUP_API_URL'] ||
  REACT_APP_SIGNUP_API_URL ||
  'https://3335wtq4e1.execute-api.ap-south-1.amazonaws.com/development/signup';

export function useSignUp(): UseSignupProps {
  const [loading, setLoading] = useState<boolean>(false);

  const createOrganization = useCallback(
    async (
      input: SignupInput,
    ): Promise<{
      error?: string;
      success?: boolean;
    }> => {
      try {
        setLoading(true);
        const request = await fetch(SIGNUP_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(input),
        });
        const response = await request.json();
        setLoading(false);

        if (response.success) {
          return { success: true };
        }

        return {
          success: false,
          error:
            response.errors && response.errors.length > 0
              ? response.errors[0].message
              : [],
        };
      } catch (err) {
        setLoading(false);
        return { error: JSON.stringify(err) };
      }
    },
    [],
  );

  return useMemo(
    () => ({
      createOrganization,
      loading,
    }),
    [loading, createOrganization],
  );
}
