import { Features, StyleFn, ToggleFeature } from '@hitz-group/domain';
import { Helmet } from 'react-helmet';
import { useTranslation, translate } from '@hitz-group/localization';
import { useIsFocused, useRoute } from '@react-navigation/native';
import React, { useEffect, useState, useCallback } from 'react';
import { useFela } from 'react-fela';
import { Platform, PixelRatio, Text, View, ScrollView } from 'react-native';
import { Icon } from '../../../../../../components/Icon/Icon';
import { useNotification } from '../../../../../../hooks/Notification';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import { filter } from 'lodash';
import { useFeatures } from '../../../../../../hooks/app/features/useFeatures';
import { ToggleVenueModal } from '../../ToggleVenueModal/ToggleVenueModal';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import { ImageCarousel } from '../../../../../../components/ImageCarousel/ImageCarousel';
import { DisabledFeatures } from './DisabledFeatures';
import { useVenues } from '../../../../../../../src/hooks/app/useVenues';
import { useStores } from '../../../../../../../src/hooks/app/useStores';

enum FeatureContext {
  ORGANIZATION = 'ORGANIZATION',
  VENUE = 'VENUE',
}

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
  paddingVertical: theme.padding.large,
});

const containerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  marginTop: theme.spacing.big,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: theme.spacing.medium * 2,
  width: '100%',
});
const sectionContainerStyle: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  flex: 1,
  flexDirection: 'row',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
  borderBottomColor: theme.colors.boxBorder,
});

const boxContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  marginBottom: theme.spacing.medium,
  maxWidth: 600,
});

const featuressectionContainerStyle: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  marginTop: theme.spacing.medium,
  borderRadius: PixelRatio.getPixelSizeForLayoutSize(5),
  borderBottomColor: theme.colors.boxBorder,
});

const featuresboxContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  height: PixelRatio.getPixelSizeForLayoutSize(150),
  marginBottom: PixelRatio.getPixelSizeForLayoutSize(30),
});

const ctaTileFeature: StyleFn = ({ theme }) => ({
  borderWidth: 0,
  borderStyle: 'solid',
  marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
  marginBottom: PixelRatio.getPixelSizeForLayoutSize(5),
  backgroundColor: theme.colors.lightBlueTile,
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  alignItems: 'center',
  textAlign: 'center',
  paddingVertical: theme.padding.large,
  paddingHorizontal: theme.padding.small * 3,
  justifyContent: 'center',
  width: '100%',
  height: 250,
  borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(0.7),
  borderBottomColor: theme.colors.boxBorder,
});

const ctaTileGreen: StyleFn = ({ theme }) => ({
  borderWidth: 0,
  borderStyle: 'solid',
  marginRight: PixelRatio.getPixelSizeForLayoutSize(5),
  marginBottom: PixelRatio.getPixelSizeForLayoutSize(5),
  backgroundColor: theme.colors.paleGreen,
  borderColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
  alignItems: 'center',
  textAlign: 'center',
  paddingVertical: theme.padding.large,
  paddingHorizontal: theme.padding.small * 3,
  justifyContent: 'center',
  width: '100%',
  height: 250,
  borderBottomWidth: PixelRatio.getPixelSizeForLayoutSize(0.7),
  borderBottomColor: theme.colors.boxBorder,
});

const ctaIcon: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.highlighted,
  width: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(45),
    default: PixelRatio.getPixelSizeForLayoutSize(23),
  }),

  padding: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(10),
    default: PixelRatio.getPixelSizeForLayoutSize(5),
  }),
  borderRadius: PixelRatio.getPixelSizeForLayoutSize(300),
  textAlign: 'center',
});

const ctaIconGreen: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greenActive,
  width: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(45),
    default: PixelRatio.getPixelSizeForLayoutSize(23),
  }),

  padding: Platform.select<number | string>({
    android: PixelRatio.getPixelSizeForLayoutSize(10),
    default: PixelRatio.getPixelSizeForLayoutSize(5),
  }),
  borderRadius: PixelRatio.getPixelSizeForLayoutSize(300),
  textAlign: 'center',
});

const ctaTitle: StyleFn = ({ theme }) => ({
  width: 200,
  height: 18,
  fontSize: 18,
  fontWeight: '600',
  fontStyle: 'normal',
  lineHeight: 18,
  letterSpacing: 0,
  textAlign: 'center',
  marginTop: theme.spacing.medium,
  color: theme.colors.primary,
});

const ctaTitleInfo: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  marginBottom: 20,
  marginTop: PixelRatio.getPixelSizeForLayoutSize(30),
  fontWeight: 600,
  lineHeight: 20,
  color: theme.colors.primary,
  textAlign: 'left',
});

const featureHeader: StyleFn = ({ theme }) => ({
  borderTopWidth: 1,
  marginTop: theme.spacing.medium,
  paddingTop: theme.spacing.medium,
  borderTopColor: theme.colors.boxBorder,
});

const ctaTitleEnable: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
  fontSize: theme.fontSize.medium,
  width: '100%',
  textAlign: Platform.select<number | string>({
    android: 'center',
    default: 'center',
  }),
});

const ctaTitleDisable: StyleFn = ({ theme }) => ({
  color: theme.colors.redNegative,
  textAlign: Platform.select<number | string>({
    android: 'center',
    default: 'right',
  }),
});

const ctaTitleConfigure: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  width: '100%',
  color: theme.colors.blue,
  textAlign: 'left',
  marginLeft: PixelRatio.getPixelSizeForLayoutSize(5),
});

const ctaAction: StyleFn = ({ theme }) => ({
  flex: 1,
  flexDirection: 'row',
  lineHeight: 0,
  marginTop: PixelRatio.getPixelSizeForLayoutSize(20),
  color: theme.colors.paragraph,
});

const ctaSubTitle: StyleFn = ({ theme }) => ({
  fontSize: 14,
  marginTop: theme.spacing.medium,
  marginBottom: theme.spacing.small,
  maxWidth: 600,
  flex: 1,
  flexWrap: 'wrap',
  lineHeight: 20,
  fontWeight: 'normal',
  color: theme.colors.dark,
  textAlign: 'justify',
});

const ctaSubTitleAbout: StyleFn = ({ theme }) => ({
  fontSize: 14,
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.small,
  maxWidth: 600,
  flex: 1,
  flexWrap: 'wrap',
  fontWeight: 'normal',
  color: theme.colors.dark,
  textAlign: 'justify',
  lineHeight: 30,
});

const ctaScreenshot: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.medium,
  height: 500,
  width: 680,
  borderRadius: 20,
  flex: 1,
  flexWrap: 'wrap',
  color: theme.colors.dark,
  textAlign: 'left',
});

const ctaSubTitleHeaderThree: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontWeight: '600',
});

const featureInfoName: StyleFn = ({ theme }) => ({
  color: theme.colors.dark,
  fontSize: theme.fontSize.small,
  marginBottom: theme.spacing.medium,
  lineHeight: 20,
  fontWeight: '600',
});

const featureInfoDescription: StyleFn = ({ theme }) => ({
  color: theme.colors.dark,
  fontSize: 14,
  marginBottom: theme.spacing.medium,
  width: 600,
  lineHeight: 30,
});

export const AboutTab: React.FC<{ featureId: string }> = ({ featureId }) => {
  const { css, theme } = useFela();
  const { showModal } = useModal();
  const { showNotification } = useNotification();
  const [enabled, setEnabled] = useState(false);
  const [disableMultiVenue, setDisableMultiVenue] = useState(false);

  const {
    currentFeature,
    venues: featureVenues,
    getFeatureVenues,
    toggleFeatureVenue,
    toggleEnableFeatureOrgLevel,
    loadings: enableLoading,
    enableFeatureVenueOrgLevelRequestData,
    disableFeatureOrgLevel,
    loadings: disableLoading,
    disableFeatureVenueOrgLevelRequestData,
    error: enableError,
    error: disableError,
  } = useFeatures(featureId);
  const { venues, getVenues, loading: venueLoading } = useVenues();
  const { stores, getStores, loading: storeLoading } = useStores();
  const isFocused = useIsFocused();

  const feature = currentFeature?.feature;

  useEffect(() => {
    if (feature?.name === Features.MULTI_VENUE && isFocused) {
      getVenues();
      getStores();
    }
  }, [feature?.name, getStores, getVenues, isFocused]);

  useEffect(() => {
    if (feature?.name === Features.MULTI_VENUE && isFocused) {
      if (Object.keys(venues).length > 1 || Object.keys(stores).length > 1) {
        setDisableMultiVenue(true);
      }
    }
  }, [feature?.name, isFocused, stores, venues]);

  useEffect(() => {
    getFeatureVenues();
  }, [getFeatureVenues]);

  useEffect((): void => {
    const enabled =
      featureVenues.filter(venue => venue.enabled === true).length > 0;
    setEnabled(enabled);
  }, [featureVenues]);

  const toggleEnableVenue = useCallback(() => {
    const featureInput: ToggleFeature = {
      featureId: featureId,
    };
    toggleEnableFeatureOrgLevel(featureInput);
    setEnabled(true);
  }, [toggleEnableFeatureOrgLevel, featureId]);

  const toggleDisableVenue = useCallback(() => {
    const featureInput: ToggleFeature = {
      featureId: featureId,
    };
    if (feature?.name === Features.MULTI_VENUE && !disableMultiVenue) {
      disableFeatureOrgLevel(featureInput);
      setEnabled(false);
      return;
    } else if (
      currentFeature?.feature?.title === Features.MULTI_VENUE &&
      disableMultiVenue
    ) {
      showNotification({
        error: true,
        message: translate('backOffice.cantDisableFeature'),
      });
    } else {
      disableFeatureOrgLevel(featureInput);
      setEnabled(false);
    }
  }, [
    featureId,
    feature?.name,
    disableMultiVenue,
    currentFeature?.feature?.title,
    disableFeatureOrgLevel,
    showNotification,
  ]);
  const enableModal = useCallback(
    (featureId): void => {
      showModal(
        <ToggleVenueModal
          featureId={featureId}
          onUpdate={toggleFeatureVenue}
        />,
      );
    },
    [showModal, toggleFeatureVenue],
  );

  const configureModal = useCallback(
    (featureId): void => {
      showModal(
        <ToggleVenueModal
          featureId={featureId}
          onUpdate={toggleFeatureVenue}
          isConfigure
        />,
      );
    },
    [showModal, toggleFeatureVenue],
  );

  useEffect((): void => {
    if (enableFeatureVenueOrgLevelRequestData && !enableError) {
      showNotification({
        success: true,
        message: translate('backOffice.features.notifications.featureEnabled'),
      });
    }
  }, [enableError, enableFeatureVenueOrgLevelRequestData, showNotification]);

  useEffect((): void => {
    if (disableFeatureVenueOrgLevelRequestData && !disableError) {
      showNotification({
        success: true,
        message: translate('backOffice.features.notifications.featureDisabled'),
      });
    }
  }, [disableFeatureVenueOrgLevelRequestData, disableError, showNotification]);

  if (enableLoading || disableLoading || storeLoading || venueLoading) {
    return <LoadingIndicator />;
  }

  return (
    <View>
      <View style={css(boxContainerStyle)}>
        <View
          style={css(
            enabled == false && ctaTileGreen ? ctaTileFeature : ctaTileGreen,
          )}
          key={feature?.id}
        >
          <View
            style={css(
              enabled == false && ctaIconGreen ? ctaIcon : ctaIconGreen,
            )}
          >
            {feature ? (
              <Icon
                name={feature?.icon}
                color={
                  enabled == false && theme.colors.green
                    ? theme.colors.blue
                    : theme.colors.green
                }
              />
            ) : null}
          </View>

          <Text testID={'title'} style={css(ctaTitle)}>
            {feature?.title}
          </Text>
          <Text style={css(ctaSubTitle)}>{feature?.description}</Text>
          <View style={css(ctaAction)}>
            {feature?.context === FeatureContext.ORGANIZATION ? (
              <Text
                testID="toggle-enable-action"
                onPress={
                  enabled === false ? toggleEnableVenue : toggleDisableVenue
                }
                style={[css(ctaTitleEnable), enabled && css(ctaTitleDisable)]}
              >
                {translate(
                  enabled
                    ? 'backOffice.features.buttons.disable'
                    : 'backOffice.features.buttons.enable',
                )}
              </Text>
            ) : (
              <Text
                onPress={() => enableModal(feature?.id)}
                testID="enable-modal-action"
                style={[css(ctaTitleEnable), enabled && css(ctaTitleDisable)]}
              >
                {translate(
                  enabled
                    ? 'backOffice.features.buttons.disable'
                    : 'backOffice.features.buttons.enable',
                )}
              </Text>
            )}
            {feature?.context == FeatureContext.VENUE ? (
              <Text
                onPress={() => configureModal(feature?.id)}
                testID={'configure-modal-action'}
                style={css(
                  enabled == false && ctaTitleConfigure
                    ? ctaTitleEnable
                    : ctaTitleConfigure,
                )}
              >
                {enabled == false &&
                translate('backOffice.features.buttons.configure')
                  ? ''
                  : translate('backOffice.features.buttons.configure')}
              </Text>
            ) : null}
          </View>
        </View>
      </View>

      <View style={css(sectionContainerStyle, boxContainerStyle)}>
        <View key={feature?.id}>
          <View>
            <Text style={css(ctaTitleInfo)}>
              {translate('backOffice.features.about')}
            </Text>
            <Text style={css(ctaSubTitleAbout)}>{feature?.about}</Text>
            <Text style={css(ctaSubTitle)}>
              <Text style={css(ctaSubTitleHeaderThree)}>
                {translate('backOffice.features.featureHeader')}
              </Text>
            </Text>
            {feature ? (
              <View style={css(ctaSubTitle)}>
                {feature.subFeatureList.map(FeatureInfoItem)}
              </View>
            ) : null}

            <Text style={css(ctaSubTitle)}>
              <Text style={css(ctaSubTitleHeaderThree)}>
                {translate('backOffice.features.screenshots')}
              </Text>
            </Text>
            {feature ? (
              <View style={css(ctaScreenshot)}>
                <ImageCarousel imageUrls={feature?.screenshots} />
              </View>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
};

export interface OtherFeaturesProps {
  name: string;
  description: string;
}

export const FeatureInfoItem: React.FC<OtherFeaturesProps> = ({
  name,
  description,
}) => {
  const { css } = useFela();
  return (
    <View key={name}>
      <Text style={css(featureInfoName)}>{name}</Text>
      <Text style={css(featureInfoDescription)}>{description}</Text>
    </View>
  );
};

export const ManageFeatureScreen: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const route = useRoute();
  const routeParams = route.params as {
    featureId: string;
    featureName: string;
  };
  const { featureId } = routeParams;
  const { showNotification } = useNotification();

  const {
    features,
    getFeatures,
    loadings: featureLoading,
    error: featureError,
  } = useFeatures();

  useEffect(() => {
    if (getFeatures) {
      getFeatures();
    }
  }, [getFeatures]);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (featureError) {
      showNotification({
        error: true,
        message: featureError,
      });
    }
  }, [featureError, showNotification]);

  if (featureLoading) return <LoadingIndicator />;

  const relatedFeatures = filter(features, ['enabledByDefault', false]);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.featuresPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView testID="overview-screen" style={css(pageStyle)}>
        <View style={css(containerStyle)}>
          {isFocused && <AboutTab featureId={featureId} />}
          <View>
            <View style={css(featureHeader)}>
              <Text>
                <Text style={css(ctaSubTitleHeaderThree)}>
                  {translate('backOffice.features.similarFeatures')}
                </Text>
              </Text>
            </View>
            <View
              style={css(
                featuressectionContainerStyle,
                featuresboxContainerStyle,
              )}
            >
              {relatedFeatures.map((feature, index) => (
                <DisabledFeatures
                  key={`disabled-feature-${index}`}
                  id={feature.id}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                  category={feature.category}
                />
              ))}
            </View>
          </View>
        </View>
      </ScrollView>
    </>
  );
};
