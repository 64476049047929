import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
import { Details } from './Details';
import { Options } from './Options';
import { Workflow } from './Workflow';
const Tab = createMaterialTopTabNavigator();

const DeviceProfileSettingsNavigator: React.FC = () => {
  const route = useRoute();
  const params = route.params;

  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      backBehavior="none"
      initialRouteName="Details"
    >
      <Tab.Screen name="Details" component={Details} initialParams={params} />
      <Tab.Screen name="Options" component={Options} initialParams={params} />
      <Tab.Screen name="Workflow" component={Workflow} initialParams={params} />
    </Tab.Navigator>
  );
};

export default DeviceProfileSettingsNavigator;
