import React from 'react';
import {
  DateRangeFilter,
  FilterValue,
  FilterObject,
  Filters,
  DateRangeFilterType,
} from '@hitz-group/domain';
import { HeaderStyles } from '../styles/Component.styles';
import { View } from 'react-native';
import { FilterInput } from '../UIComponents/FilterInput';
import { DateRangeFilters } from '../UIComponents/DateRangeFilters';
import IconButton from '../../../../components/Button/IconButton';
import Button from '../../../../components/Button/Button';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../components/FormInput/FormInput';
import { ExportType, IMap, ReportTableColumn, Search } from '../types';
import Icon from '../../../../components/Icon/Icon';
import MultipleDropDown from '../../../../components/MultipleSelect/MultipleSelect';
import DropDown from '../../../../components/DropDown/DropDown';
import { pascalCase } from 'change-case';
import { BaseReportHelper } from '@hitz-group/analytics-helper';
import { TRADING_PERIOD_KEY } from '../reportsHelper';

interface HeaderProps {
  allFilters: Filters;
  filters: FilterValue;
  dateRangeFilter: DateRangeFilter;
  search: Search;
  updateSearch: (filter: string, value: string) => void;
  toggleFilters: () => void;
  columns: {
    all: ReportTableColumn[];
    updateColumns: (columnKeys: string[]) => void;
    selectedColumns: ReportTableColumn[];
  };
  updateReport: () => void;
  exportReport: (type: ExportType) => void;
  updateFilters: (filter: string, value: string[]) => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  translationParentKey: string;
  searchTypeOptions?: IMap<string>;
  dateRanges?: DateRangeFilterType[];
}

export const ReportHeader: React.FC<HeaderProps> = ({
  allFilters,
  filters,
  dateRangeFilter,
  search,
  updateReport,
  exportReport,
  updateSearch,
  columns,
  updateFilters,
  toggleFilters,
  updateDateRangeFilters,
  translationParentKey,
  searchTypeOptions,
  dateRanges,
}) => {
  const { translate } = useTranslation();
  const styles = HeaderStyles();

  const headerFilter = {
    key: BaseReportHelper.DEFAULT_FILTER,
    title:
      `backOfficeReports.filters.${BaseReportHelper.DEFAULT_FILTER}` as string,
    values: allFilters[BaseReportHelper.DEFAULT_FILTER as keyof Filters],
  };

  const tradingPeriods: FilterObject[] =
    allFilters[TRADING_PERIOD_KEY as keyof Filters];

  const dateRangeStyleOverride = { ...styles.dateRangeFilters };

  return (
    <View style={styles.pageStyle}>
      <View style={styles.row1}>
        <View style={styles.venueFilterView}>
          <FilterInput
            key={0}
            index={0}
            options={headerFilter?.values as FilterObject[]}
            name={BaseReportHelper.DEFAULT_FILTER as string}
            title={headerFilter?.title}
            showLabel={false}
            selectedValues={
              filters[headerFilter?.key as keyof FilterValue] || []
            }
            updateFilters={updateFilters}
          ></FilterInput>
        </View>
        <View style={dateRangeStyleOverride}>
          <DateRangeFilters
            tradingPeriods={tradingPeriods}
            dateRangeFilter={dateRangeFilter}
            updateDateRangeFilters={updateDateRangeFilters}
            dateRanges={dateRanges}
          ></DateRangeFilters>
        </View>
        <View style={styles.iconStyle}>
          <IconButton
            icon="sliders-v"
            onPress={() => toggleFilters()}
            primary
            containerStyle={styles.filtersButtonStyle}
            iconStyle={styles.filtersIconStyle}
            containerSize={38}
            iconSize={25}
          ></IconButton>
        </View>
        <MultipleDropDown
          containerStyle={styles.iconStyle}
          showSearch={false}
          values={columns.all?.map(col => ({
            label: translate(col.title),
            value: col.title,
          }))}
          selectedValues={columns.selectedColumns?.map(col => col.title)}
          onValueChange={columns.updateColumns}
          collapsedView={
            <View style={styles.columnsButtonStyle}>
              <Icon
                size={25}
                name="table"
                primary
                style={styles.columnsIconStyle}
                disabled={false}
              />
            </View>
          }
          toggleIcon={<></>}
        />
        <View style={styles.updateContainerStyle}>
          <Button
            testID="update-report"
            fluid
            title={translate('backOfficeReports.header.updatedBtn')}
            containerStyle={styles.updateButtonStyle}
            labelStyle={styles.titleStyle}
            onPress={updateReport}
          />
        </View>
      </View>
      <View style={styles.row2}>
        <View style={styles.searchContainerStyle}>
          {searchTypeOptions && (
            <View style={styles.searchTypeStyle}>
              <DropDown
                btnTestId="toggle-search-type"
                options={Object.keys(searchTypeOptions).map(filterType => ({
                  value: filterType,
                  label: pascalCase(filterType, { delimiter: ' ' }),
                }))}
                touchableStyle={styles.searchTypeTouchableStyle}
                selectedValue={search.type}
                containerStyle={styles.searchTypeInputStyle}
                onValueChange={value => {
                  updateSearch && updateSearch('type', value);
                  updateSearch && updateSearch('value', '');
                }}
                extraPopoverStyle={styles.extraPopoverStyle}
              ></DropDown>
            </View>
          )}
          <View style={styles.searchStyle}>
            <FormInput
              fluid
              onChangeText={value =>
                updateSearch && updateSearch('value', value)
              }
              value={search.value}
              placeholder={translate(`${translationParentKey}.header.search`)}
              containerStyle={styles.searchInputStyle}
              textInputStyle={styles.searchInputStyle}
              alignTitle={'left'}
            />
          </View>
        </View>
        <View style={styles.downloadContainerStyle}>
          <Button
            testID="download-report"
            fluid
            title={translate('backOfficeReports.header.downloadBtn')}
            containerStyle={styles.downloadButtonStyle}
            labelStyle={styles.titleStyle}
            onPress={() => exportReport(ExportType.CSV)}
          />
        </View>
      </View>
    </View>
  );
};
