import React, { useCallback, useEffect, useRef } from 'react';
import { useIsFocused } from '@react-navigation/native';
import {
  PaymentType,
  TransactionAdjustment,
  OrderPaymentStatus,
} from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import {
  AdditionalPaymentInfo,
  DEFAULT_PAYMENT_CURRENCY,
  PAYMENT_INPROGRESS_MESSAGES,
  POS_PAYMENT_CANCEL_MESSAGES,
  TempPaymentInfo,
} from '../../types/Common';
import { usePayments } from '../../hooks/app/usePayments';
import { useSession } from '../../hooks/app/useSession';
import { useNetworkStatusVar } from '../../hooks/app/useNetworkStatusVar';
import { useNotification } from '../../hooks/Notification';
import ProcessPaymentModal from '../../components/Modals/Payments/ProcessPaymentModal';
import { stripProperties } from '../../utils/stripObjectProps';

const nanoid = customAlphabet(alphanumeric, 10);

export interface UseCardPayFunctionMapRes {
  createCardPayment: (
    orderAmount: number,
    orderNumber: string,
    paymentType: PaymentType,
    surchargeAmount?: number,
    pendingPaymentRequestId?: string,
  ) => void;
}

export const useCardPayFunctionMap = (
  onPressPay?: (
    paymentType: PaymentType,
    amount: number,
    surchargeAmount?: number,
    additionalInfo?: AdditionalPaymentInfo,
  ) => void,
  onPaymentProcessed?: (additionalInfo: AdditionalPaymentInfo) => void,
  onPaymentAdjustment?: (transactionAdjustment: TransactionAdjustment) => void,
): UseCardPayFunctionMapRes => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { showModal, closeModal } = useModal();
  const [session] = useSession();
  const isFocused = useIsFocused();

  const paymentDetailsRef = useRef<TempPaymentInfo | undefined>(undefined);

  const networkStatus = useNetworkStatusVar();
  // Using useRef here because callback sent to showModal is getting previous state
  // State was not getting updated in the callback function sent to showModal
  const networkStatusRef = useRef<boolean>(true);
  networkStatusRef.current = networkStatus;

  const {
    loading: isPaymentLoading,
    cancellationLoading,
    error: paymentError,
    paymentResponse,
    cancellationResponse,
    initiatePayment,
    verifyPayment,
    resetPaymentResponse,
    cancelPayment,
    resetCancellationResponse,
  } = usePayments();

  useEffect((): void => {
    if (paymentError) {
      showNotification(
        {
          error: true,
          message: paymentError,
        },
        false,
      );
    }
  }, [paymentError, showNotification]);

  const ifNoNetwork = useCallback(() => {
    if (!networkStatusRef.current) {
      showNotification({
        error: true,
        message: translate('settings.noInternet'),
      });
      return true;
    }
    return false;
  }, [showNotification, translate]);

  useEffect(() => {
    if (cancellationResponse) {
      showNotification({
        success: true,
        message: translate('payment.oolioPayCancellationSuccess'),
      });

      resetCancellationResponse();
    }
  }, [
    cancellationResponse,
    closeModal,
    resetCancellationResponse,
    showNotification,
    translate,
  ]);

  useEffect(() => {
    if (paymentResponse) {
      // TODO: Cancel original "initiatePayment" request, if success is provided by Verify.
      const paymentDetailsClone = {
        ...(paymentDetailsRef.current as TempPaymentInfo),
      };

      let paymentStatus: OrderPaymentStatus;
      const isPaymentStillInProgress = PAYMENT_INPROGRESS_MESSAGES.includes(
        paymentResponse.message,
      );

      let paymentSurcharge: number | undefined;

      if (paymentResponse.success) {
        paymentStatus = OrderPaymentStatus.COMPLETE;

        if (paymentResponse.additionalInfo?.transactionAdjustment) {
          paymentSurcharge =
            paymentResponse.additionalInfo?.transactionAdjustment?.amount;

          onPaymentAdjustment &&
            onPaymentAdjustment(
              paymentResponse.additionalInfo?.transactionAdjustment,
            );
        }
      } else {
        paymentStatus = POS_PAYMENT_CANCEL_MESSAGES.includes(
          paymentResponse.message,
        )
          ? OrderPaymentStatus.CANCELLED
          : OrderPaymentStatus.REJECTED;

        showNotification(
          {
            ...(!isPaymentStillInProgress && { error: true }),
            ...(isPaymentStillInProgress && { info: true }),
            message: paymentResponse.message,
          },
          false,
        );
      }

      resetPaymentResponse();

      if (!isPaymentStillInProgress) {
        paymentStatus &&
          onPaymentProcessed &&
          onPaymentProcessed({
            paymentStatus,
            paymentRequestId: paymentDetailsClone?.requestId as string,
            paymentTransactionRef:
              paymentResponse.additionalInfo?.transactionId,
            paymentCompletedAt:
              paymentResponse.additionalInfo?.transactionCompletedAt,
            paymentReceipt: paymentResponse.additionalInfo?.paymentReceipt,
            paymentSurcharge,
          });

        paymentStatus && closeModal();

        if (!isPaymentLoading) paymentDetailsRef.current = undefined;
      }
    }
  }, [
    closeModal,
    isPaymentLoading,
    onPaymentProcessed,
    onPaymentAdjustment,
    paymentResponse,
    resetPaymentResponse,
    showNotification,
    translate,
  ]);

  const initiateCardPayment = useCallback(
    (orderNumber: string, surchargeAmount: number) => {
      if (!ifNoNetwork()) {
        const requestId = nanoid();

        initiatePayment({
          deviceId: session.device?.deviceCode as string,
          terminalId: session.device?.paymentTerminal?.uuid as string,
          requestId: requestId,
          transactionId: orderNumber,
          amount: paymentDetailsRef.current?.orderAmount as number,
          currency: DEFAULT_PAYMENT_CURRENCY,
        });

        onPressPay &&
          onPressPay(
            paymentDetailsRef.current?.paymentType as PaymentType,
            paymentDetailsRef.current?.orderAmount as number,
            surchargeAmount,
            {
              paymentRequestId: requestId,
              paymentStatus: OrderPaymentStatus.PENDING,
            },
          );

        paymentDetailsRef.current = {
          ...(paymentDetailsRef.current as TempPaymentInfo),
          requestId,
        };
      }
    },
    [ifNoNetwork, initiatePayment, onPressPay, session.device],
  );

  const verifyCardPayment = useCallback(() => {
    if (!ifNoNetwork()) {
      verifyPayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: paymentDetailsRef.current?.requestId as string,
      });
    }
  }, [ifNoNetwork, session.device, verifyPayment]);

  const cancelCardPayment = useCallback(() => {
    if (!ifNoNetwork()) {
      /**
       * Cancel payment is acknowledgement only.
       * The actual cancellation is verified by original request or
       * verify status.
       */
      cancelPayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: paymentDetailsRef.current?.requestId as string,
      });
    }
  }, [ifNoNetwork, session.device, cancelPayment]);

  const closePaymentModal = useCallback(() => {
    if (!ifNoNetwork()) {
      /**
       * Cancel payment is acknowledgement only.
       * The actual cancellation is verified by original request or
       * verify status.
       */
      cancelPayment({
        deviceId: session.device?.deviceCode as string,
        terminalId: session.device?.paymentTerminal?.uuid as string,
        requestId: paymentDetailsRef.current?.requestId as string,
      });

      /**
       * Sending close modal action for scenarios where internet is up but
       * 3th party api is not retuning any response (due to network drop-out).
       * Staff will be able to close order by cash in such cases.
       */
      setTimeout(() => closeModal(), 4000);
    }
  }, [ifNoNetwork, cancelPayment, session.device, closeModal]);

  const showPaymentModal = useCallback(() => {
    showModal(
      <ProcessPaymentModal
        orderTotal={paymentDetailsRef.current?.orderAmount as number}
        cancellationLoading={cancellationLoading}
        onPressClose={closePaymentModal}
        onPressCancel={cancelCardPayment}
        onPressVerify={verifyCardPayment}
      />,
    );
  }, [
    cancelCardPayment,
    cancellationLoading,
    closePaymentModal,
    showModal,
    verifyCardPayment,
  ]);

  useEffect(() => {
    if (isPaymentLoading) {
      showModal(
        <ProcessPaymentModal
          orderTotal={paymentDetailsRef.current?.orderAmount as number}
          cancellationLoading={cancellationLoading}
          onPressClose={closePaymentModal}
          onPressCancel={cancelCardPayment}
          onPressVerify={verifyCardPayment}
        />,
      );
    }
  }, [
    cancelCardPayment,
    closePaymentModal,
    isPaymentLoading,
    cancellationLoading,
    showModal,
    verifyCardPayment,
  ]);

  const handleOnPressCardPay = useCallback(
    (
      orderAmount: number,
      orderNumber: string,
      paymentType: PaymentType,
      surchargeAmount: number,
    ) => {
      if (orderAmount === 0) {
        showNotification({
          message: translate('payment.payZeroError'),
          error: true,
        });
        closeModal();
        return;
      }
      if (session.device?.paymentTerminal) {
        const paymentTypeInput = stripProperties(
          { ...paymentType },
          'label',
          'value',
        );

        paymentDetailsRef.current = {
          paymentType: paymentTypeInput,
          orderAmount,
        };

        initiateCardPayment(orderNumber, surchargeAmount);
      } else {
        showNotification({
          message: translate('payment.paymentTerminalNotLinked'),
          error: true,
        });
        closeModal();
      }
    },
    [
      closeModal,
      initiateCardPayment,
      session.device?.paymentTerminal,
      showNotification,
      translate,
    ],
  );

  const createCardPayment = useCallback(
    (
      orderAmount: number,
      orderNumber: string,
      paymentType: PaymentType,
      surchargeAmount = 0,
      pendingPaymentRequestId?: string,
    ) => {
      if (isFocused) {
        if (pendingPaymentRequestId) {
          paymentDetailsRef.current = {
            paymentType,
            orderAmount,
            requestId: pendingPaymentRequestId,
          };

          showPaymentModal();
        } else {
          handleOnPressCardPay(
            orderAmount,
            orderNumber,
            paymentType,
            surchargeAmount,
          );
        }
      }
    },
    [handleOnPressCardPay, isFocused, showPaymentModal],
  );

  return {
    createCardPayment,
  };
};
