import { StyleFn } from '@hitz-group/domain';
import * as React from 'react';
import { useFela } from 'react-fela';
import { Animated, StyleProp, ViewStyle } from 'react-native';
import scale from '../../common/theme';

const catalogItemStyle: StyleFn = ({ theme }) => ({
  height: scale.moderateScale(52),
  flexDirection: 'row',
  backgroundColor: theme.colors.boxBorder,
  borderRadius: theme.radius.small,
});

export interface EmptyBlockProps {
  style?: StyleProp<ViewStyle>;
}

const EmptyBlock: React.FC<EmptyBlockProps> = ({ style }) => {
  const { css } = useFela();
  return <Animated.View style={[css(catalogItemStyle), style]}></Animated.View>;
};

export default EmptyBlock;
