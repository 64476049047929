/* eslint-disable @typescript-eslint/no-explicit-any */
import { Theme } from '@hitz-group/domain';
import React, { useCallback, useMemo, useState } from 'react';
import { withTheme } from 'react-fela';
import { StyleSheet, View, Platform, TextInput, Text } from 'react-native';
import { useFela } from 'react-fela';

export interface CodeInputProps {
  noOfInputs: number;
  onEnterDeviceCode: (code: string) => Promise<void>;
}

const getStyles = (theme: Theme) => {
  return StyleSheet.create({
    mt40: {
      marginTop: 40,
    },
    codeInput: {
      ...theme.font14Bold,
      borderColor: theme.colors.boxBorder,
      borderWidth: 1,
      width: 50,
      height: 60,
      textAlign: 'center',
      fontSize: 20,
      borderRadius: theme.radius.small,
      ...Platform.select({
        web: {
          outlineWidth: 0,
        },
      }),
      marginHorizontal: 4,
    },
    hyphenStyle: {
      textAlign: 'center',
      ...theme.font14Bold,
      color: theme.colors.paragraph,
      fontSize: 40,
    },

    direction: {
      flexDirection: 'row',
    },
  });
};

const CodeInput: React.FC<CodeInputProps> = ({
  noOfInputs,
  onEnterDeviceCode,
}) => {
  const { theme } = useFela();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const codeInputRefArr: React.RefObject<TextInput>[] = Array(noOfInputs)
    .fill(null)
    .map(() => React.createRef<TextInput>());

  const [deviceCode, setDeviceCode] = useState(Array(noOfInputs).fill(''));

  const handleSetDeviceCode = useCallback((indx: number, val: string) => {
    setDeviceCode(prev => {
      const newArr = [...prev];
      newArr[indx] = val;
      return newArr;
    });
  }, []);

  const changeInput = useCallback(
    (indx: number, val: string) => {
      const lastRef = indx - 1;
      if (val === 'Backspace' && lastRef > -1) {
        handleSetDeviceCode(indx, '');
        codeInputRefArr[indx - 1].current?.focus();
        return;
      } else if (val !== '' && val !== 'Backspace' && indx < noOfInputs - 1) {
        handleSetDeviceCode(indx, val);
        codeInputRefArr[indx + 1].current?.focus();
        return;
      } else {
        val !== '' && handleSetDeviceCode(indx, val === 'Backspace' ? '' : val);
      }

      if (indx + 1 === noOfInputs) {
        onEnterDeviceCode(deviceCode.join('') + val);
      }
    },
    [
      codeInputRefArr,
      deviceCode,
      handleSetDeviceCode,
      noOfInputs,
      onEnterDeviceCode,
    ],
  );

  const onPressBackspaceKey = useCallback(
    (event: any, indx: number) => {
      const key = event.key || event.nativeEvent.key;
      key === 'Backspace' && changeInput(indx, key);
    },
    [changeInput],
  );

  return (
    <>
      <View style={[styles.direction, styles.mt40]}>
        {Array(noOfInputs)
          .fill(null)
          .map((_, indx) => {
            return indx !== noOfInputs - 1 ? (
              <>
                {indx === 0 || indx % 4 !== 0 ? (
                  <TextInput
                    key={indx}
                    ref={codeInputRefArr[indx]}
                    style={styles.codeInput}
                    maxLength={1}
                    textAlign="center"
                    placeholder="0"
                    caretHidden={true}
                    value={deviceCode[indx]}
                    placeholderTextColor={theme.colors.paragraph}
                    onChangeText={val => changeInput(indx, val)}
                    onKeyPress={(event: any) =>
                      onPressBackspaceKey(event, indx)
                    }
                  />
                ) : (
                  <>
                    <Text style={styles.hyphenStyle}>-</Text>
                    <TextInput
                      key={indx}
                      ref={codeInputRefArr[indx]}
                      style={styles.codeInput}
                      maxLength={1}
                      textAlign="center"
                      placeholder="0"
                      caretHidden={true}
                      value={deviceCode[indx]}
                      placeholderTextColor={theme.colors.paragraph}
                      onChangeText={val => changeInput(indx, val)}
                      onKeyPress={(event: any) =>
                        onPressBackspaceKey(event, indx)
                      }
                    />
                  </>
                )}
              </>
            ) : (
              <TextInput
                key={indx}
                ref={codeInputRefArr[indx]}
                style={styles.codeInput}
                maxLength={1}
                textAlign="center"
                placeholder="0"
                caretHidden={true}
                value={deviceCode[indx]}
                placeholderTextColor={theme.colors.paragraph}
                onChangeText={val => changeInput(indx, val)}
                onKeyPress={(event: any) => onPressBackspaceKey(event, indx)}
              />
            );
          })}
      </View>
    </>
  );
};

export default withTheme(CodeInput);
