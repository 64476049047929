import { StyleFn } from '@hitz-group/domain';

export const cartSummaryStyle: StyleFn = () => ({
  justifyContent: 'flex-end',
  paddingVertical: 0,
  paddingBottom: 0,
});

export const summaryItemStyle: StyleFn = () => ({
  marginVertical: 3,
});

export const summaryContainerStyle: StyleFn = ({ theme }) => ({
  paddingVertical: 0,
  paddingBottom: 0,
  paddingTop: theme.padding.small,
});

export const horizontalLine: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 1,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.colors.boxBorder,
  marginTop: theme.spacing.small,
  marginBottom: 5,
});

export const summaryItemContainer: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  paddingTop: theme.padding.small,
  paddingBottom: 16,
});

export const totalValueTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.warning,
});
