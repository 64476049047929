import {
  StyleFn,
  CreateAndUpdateEarningRule,
  EarningType,
  Venue,
  DEFAULT_ENTITY_ID,
  LoyaltySettings,
} from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import FormInput, { Label } from '../../../../components/FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../components/Button/Button';
import { useNotification } from '../../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';

import Modal from '../../../../components/Modals/Modal';
import { isAndroid, isWeb } from '../../../../common/theme';
import DropDown from '../../../../components/DropDown/DropDown';
import MultipleSelect from '../../../../components/MultipleSelect/MultipleSelect';
import { View } from 'react-native';
import IconButton from '../../../../components/Button/IconButton';
import setKeyValue from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { ObjectSchema, NumberSchema } from 'yup';
import { getCurrencySymbol } from '@hitz-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import { countries } from 'countries-list';

const modalContainerStyle: StyleFn = ({}) => ({
  width: 340,
  padding: 30,
  borderRadius: 10,
});

const headerTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const buttonsContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  height: 44,
  marginTop: theme.spacing.medium,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  height: 44,
  borderRadius: theme.radius.small,
  marginLeft: 10,
  marginTop: theme.spacing.medium,
  paddingHorizontal: 10,
});

const earningPointContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 290,
});

const dropdownContainerStyleProductType: StyleFn = () => ({
  width: 290,
  height: 60,
});

const itemContainer: StyleFn = () => ({
  height: 38 * 3,
});

const touchableDropDownStyle: StyleFn = () => ({
  marginTop: isWeb ? 3 : 0,
});

const extraPopoverStyleProductType: StyleFn = () => ({
  width: 290,
});

const textStyle: StyleFn = () => ({
  marginTop: isAndroid ? -6 : 0,
});

const selectedItemStyle: StyleFn = () => ({
  marginVertical: 5,
});

const venuesContainerStyle: StyleFn = ({ theme }) => ({
  width: 290,
  height: 'auto',
  marginTop: isAndroid ? theme.padding.small + 2 : 0,
});

const prefixItemStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  padding: theme.spacing.small / 2 + 5,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.bold,
  borderRadius: theme.radius.small,
  lineHeight: 15,
});

const amountSpendContainerStyle: StyleFn = ({}) => ({
  width: 290,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  marginBottom: 8,
  marginLeft: 10,
});

const touchableStyle: StyleFn = ({ theme }) => ({
  minHeight: theme.input.height,
  flexWrap: 'wrap',
});

const amountSpendWrapper: StyleFn = ({}) => ({
  marginTop: 10,
});

const checkBoxTitleStyle: StyleFn = ({ theme, isActive }) => ({
  ...theme.font14Regular,
  textTransform: 'none',
  color: isActive ? theme.colors.charcoal : theme.colors.paragraph,
});

const viewStyle: StyleFn = ({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  marginLeft: theme.spacing.small,
  width: 260,
  height: 38,
  marginTop: 20,
});

const titleStyle: StyleFn = ({}) => ({
  marginLeft: 10,
});

const earnByAmountSchema = new ObjectSchema({
  amountSpend: new NumberSchema()
    .transform((value, originalValue) => {
      if (!originalValue) return 0;
      return value;
    })
    .positive('backOfficeLoyalty.invalidSpentAmount')
    .required(),

  earningPoint: new NumberSchema()
    .transform((value, originalValue) => {
      if (!originalValue) return 0;
      return value;
    })
    .positive('backOfficeLoyalty.invalidEarningPoint')
    .required(),
});

type EarningRuleState = {
  venueIds: string[];
  includedTax?: boolean;
  earningType: EarningType;
  amountSpend: string;
  earningPoint: string;
};

interface CreateAndEditEarningPointModalProps {
  onCreateAndUpdate: (earningRule: CreateAndUpdateEarningRule) => void;
  editingEarningPoint?: EarningRuleState;
  allVenues: Venue[];
  loyaltySettings: Partial<LoyaltySettings>;
}

const defaultEarningRule: EarningRuleState = {
  earningType: EarningType.EARN_BY_AMOUNT,
  venueIds: [],
  includedTax: false,
  amountSpend: '',
  earningPoint: '',
};

const CreateAndEditEarningPointModal: React.FC<
  CreateAndEditEarningPointModalProps
> = ({
  onCreateAndUpdate,
  editingEarningPoint,
  allVenues,
  loyaltySettings,
}) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const [earningRuleData, setEarningRuleData] = useState(
    editingEarningPoint || defaultEarningRule,
  );

  const onChangeItem = useCallback((path: string, value) => {
    setEarningRuleData(existingRuleData => {
      const newData = cloneDeep(existingRuleData);
      setKeyValue(newData, path, value);
      return newData;
    });
  }, []);

  const onChangeVenues = useCallback(
    (selectedVenues: string[]) => {
      if (selectedVenues.includes(DEFAULT_ENTITY_ID)) {
        const allVenueIds = allVenues.map(venue => venue.id);
        onChangeItem('venueIds', allVenueIds);
      } else {
        onChangeItem('venueIds', selectedVenues);
      }
    },
    [onChangeItem, allVenues],
  );
  const [session] = useSession();
  const { country = 'AU' } = session?.currentOrganization || {};
  const currencyCode = countries[country]?.currency;

  const onUpdateEarningRule = useCallback(
    (earningRuleData: EarningRuleState) => {
      try {
        const updatedData = earnByAmountSchema.validateSync(
          earningRuleData,
        ) as CreateAndUpdateEarningRule;
        onCreateAndUpdate(updatedData);
      } catch (error) {
        showNotification({
          message: translate((error as { message: string })?.message, {
            points: loyaltySettings.pluralTerm,
          }),
          error: true,
        });
      }
    },
    [
      loyaltySettings.pluralTerm,
      onCreateAndUpdate,
      showNotification,
      translate,
    ],
  );

  const selectedVenues =
    earningRuleData?.venueIds?.length == allVenues.length
      ? [DEFAULT_ENTITY_ID]
      : [...(earningRuleData?.venueIds || [])];

  return (
    <Modal
      title={translate('backOfficeLoyalty.earningPoints', {
        points: loyaltySettings.pluralTerm,
      })}
      contentStyle={css(modalContainerStyle)}
      headerTextStyle={css(headerTextStyle)}
      showCloseButton={false}
    >
      <DropDown
        title={translate('backOfficeLoyalty.earnBy')}
        options={[
          {
            label: translate('backOfficeLoyalty.amountSpent'),
            value: EarningType.EARN_BY_AMOUNT,
          },
        ]}
        selectedValue={earningRuleData?.earningType}
        containerStyle={css(dropdownContainerStyleProductType)}
        itemsContainerStyle={css(itemContainer)}
        onValueChange={onChangeItem.bind(null, 'earningType')}
        touchableStyle={css(touchableDropDownStyle)}
        extraPopoverStyle={css(extraPopoverStyleProductType)}
        titleStyle={css(titleStyle)}
        testID="earn-by"
      />
      <View style={css(amountSpendWrapper)}>
        <FormInput
          title={translate('backOfficeLoyalty.amountSpent')}
          testID="amount-spend"
          value={(earningRuleData?.amountSpend || '').toString()}
          containerStyle={css(amountSpendContainerStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChangeItem.bind(null, 'amountSpend')}
          type={'number'}
          maxLength={8}
          prefix={{
            text: getCurrencySymbol(currencyCode),
            textStyle: css(prefixItemStyle),
          }}
        />
      </View>
      <View style={css(viewStyle)}>
        <IconButton
          onPress={() =>
            onChangeItem('includedTax', !Boolean(earningRuleData?.includedTax))
          }
          icon={earningRuleData?.includedTax ? 'check-square' : 'square-full'}
          iconColor={
            earningRuleData?.includedTax
              ? theme.colors.success
              : theme.colors.darkGrey
          }
          iconSize={22}
          testID={'included-tax'}
        />
        <Label textStyle={css(checkBoxTitleStyle({ theme, isActive: true }))}>
          {translate('backOfficeLoyalty.includeTaxInAmount')}
        </Label>
      </View>
      <View style={css(amountSpendWrapper)}>
        <FormInput
          title={translate('backOfficeLoyalty.pointsEarned', {
            points: loyaltySettings.pluralTerm,
          })}
          value={(earningRuleData?.earningPoint || '').toString()}
          alignTitle={'left'}
          containerStyle={css(earningPointContainerStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChangeItem.bind(null, 'earningPoint')}
          maxLength={50}
          type="number"
          testID="points-earned"
        />
      </View>

      <MultipleSelect
        label={translate('backOfficeLoyalty.venues')}
        values={[
          {
            label: translate('backOfficeLoyalty.allVenues'),
            value: DEFAULT_ENTITY_ID,
          },
        ].concat(
          allVenues.map(venue => ({
            label: venue.name,
            value: venue.id,
          })),
        )}
        textStyle={css(textStyle)}
        selectedValues={selectedVenues}
        selectedItemStyle={css(selectedItemStyle)}
        showCheckboxes={false}
        containerStyle={css(venuesContainerStyle)}
        onValueChange={onChangeVenues}
        touchableStyle={css(touchableStyle)}
        testID="venue-selection"
      />
      <View style={css(buttonsContainerStyle)}>
        <Button
          title={translate('form.dismiss')}
          transparent
          containerStyle={css(buttonStyle)}
          color={theme.colors.green}
          onPress={() => closeModal()}
          testID="dismiss"
        />
        <Button
          secondary
          testID="update-earning-rule"
          title={translate('button.update')}
          containerStyle={css(buttonStyle)}
          color={theme.colors.green}
          onPress={() => onUpdateEarningRule(earningRuleData)}
        />
      </View>
    </Modal>
  );
};
export default CreateAndEditEarningPointModal;
