import { useTranslation } from '@hitz-group/localization';
import { Printer, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { useModal } from '@hitz-group/rn-use-modal';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import PopupView from '../../PopupView/PopupView';
import TitleBar from '../../TitleBar/TitleBar';
import DropDown from '../../FormInput/DropDown';
import scale from '../../../common/theme';

export interface ReprintModalProps {
  printerId?: string;
  printers: Printer[];
  onSubmit: (printerId: string) => void;
}

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 15,
});
const containerStyle: StyleFn = ({ theme }) => ({
  width: 350,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  flex: 1,
});

const popupStyle: StyleFn = ({ theme }) => ({
  paddingTop: theme.padding.medium * 2.5,
  minHeight: 235,
});

const fullWidth: StyleFn = ({}) => ({
  justifyContent: 'space-between',
  width: '100%',
  height: 44,
  alignSelf: 'center',
});
const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  height: scale.moderateScale(35),
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});
const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  height: scale.moderateScale(30),
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

const dropdownViewStyle: StyleFn = () => ({
  alignSelf: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: scale.moderateScale(2),
});

const labelTextStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.smaller,
  paddingLeft: theme.padding.medium,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small * 3.5,
});

const ReprintModal: React.FC<ReprintModalProps> = ({
  printerId,
  printers,
  onSubmit,
}: ReprintModalProps) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [formState, setFormState] = useState(printerId || '');

  const onCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const titleLeft = (
    <IconButton
      containerStyle={css(iconButtonStyle)}
      icon="times"
      onPress={onCloseModal}
    />
  );
  const onSubmitForm = useCallback(() => {
    closeModal();
    onSubmit && onSubmit(formState);
  }, [closeModal, onSubmit, formState]);

  return (
    <View style={css(containerStyle)}>
      <TitleBar
        titleLeft={titleLeft}
        primary
        title={translate('printing.reprint')}
      />
      <PopupView containerStyle={css(popupStyle)}>
        <DropDown
          fluid
          values={(printers || []).map(printer => ({
            label: printer.name,
            value: printer.id,
          }))}
          selectedValue={formState}
          onValueChange={setFormState}
          style={css(fullWidth)}
          extraMainViewStyle={css(dropDownMainViewStyle)}
          extraStyle={css(dropdownExtraStyle)}
          extraViewStyle={css(dropdownViewStyle)}
          title={translate('printing.selectPrinter')}
          textStyle={css(labelTextStyle)}
        />

        <Button
          title={translate('printing.reprint')}
          fluid
          testID="reprint"
          secondary
          size="small"
          containerStyle={css(buttonStyle)}
          onPress={onSubmitForm}
        />
      </PopupView>
    </View>
  );
};

export default ReprintModal;
