import { StyleFn, App } from '@hitz-group/domain';
import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { combineLatest, Subscription } from 'rxjs';
import { useFela } from 'react-fela';
import arrayToTree from 'array-to-tree';
import {
  filterPermissionsBySearchTerm,
  jobRoleSettingsUtility,
  PermissionTreeNode,
} from '../../../../state/jobRoleSettingsUtility';
import { map } from 'rxjs/operators';
import PermissionTree from './PermissionTree';
import { useTranslation } from '@hitz-group/localization';

const container: StyleFn = () => ({
  width: 540,
});

const tableHeader: StyleFn = ({ theme }) => ({
  height: 35,
  width: 540,
  marginVertical: theme.padding.small,
  backgroundColor: theme.colors.greyLight,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: theme.radius.small,
  paddingRight: theme.padding.small,
  paddingLeft: theme.padding.medium,
});

const textContainer: StyleFn = () => ({
  flexDirection: 'row',
});

const headerText: StyleFn = ({ theme }) => ({
  color: theme.colors.text,
  paddingLeft: theme.padding.small,
});

const POSAppPermissions = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const [permissionsTree, setPermissionsTree] = useState<PermissionTreeNode[]>(
    [],
  );

  useEffect(() => {
    const subscription: Subscription = combineLatest([
      jobRoleSettingsUtility.permissions$.pipe(
        map(permissions =>
          permissions.filter(
            permission => permission.clientApp === App.POS_APP,
          ),
        ),
      ),
      jobRoleSettingsUtility.search$,
    ]).subscribe(([posPermissions, searchTerm]) => {
      if (posPermissions?.length > 0) {
        const data = filterPermissionsBySearchTerm(posPermissions, searchTerm);

        const sortedPermissionTree = arrayToTree(data, {
          parentProperty: 'parent',
          customID: 'id',
        }).sort((a, b) => a.sortOrder - b.sortOrder);

        setPermissionsTree(sortedPermissionTree);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <View style={css(tableHeader)}>
        <Text style={css(headerText)}>
          {translate('backofficeManageRoles.permission')}
        </Text>
        <View style={css(textContainer)}>
          <Text style={css(headerText)}>
            {translate('backofficeManageRoles.operations.enable')}
          </Text>
        </View>
      </View>
      <View style={css(container)}>
        {permissionsTree.map(permissionTree => {
          return (
            <PermissionTree
              permission={permissionTree}
              key={permissionTree.id}
            />
          );
        })}
      </View>
    </>
  );
};

export default POSAppPermissions;
