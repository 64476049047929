import { gql } from '@apollo/client';

export const FRAGMENTS = {
  filterObject: gql`
    fragment ReportFilters on FilterObject {
      id
      name
      value
    }
  `,
};

export const GET_REPORT_FILTERS = gql`
  query {
    reportFilters {
      venues {
        ...ReportFilters
      }
      stores {
        ...ReportFilters
      }
      devices {
        ...ReportFilters
      }
      staff {
        ...ReportFilters
      }
      paymentTypes {
        ...ReportFilters
      }
      orderTypes {
        ...ReportFilters
      }
      tradingPeriods {
        ...ReportFilters
      }
      refundReasons {
        id: name
        name
      }
    }
  }
  ${FRAGMENTS.filterObject}
`;

export const GENERATE_DETAILED_SUMMARY_REPORT = gql`
  query generateDetailedSummaryReport($input: DetailedSummaryReportInput!) {
    generateDetailedSummaryReport(input: $input)
  }
`;
