import { RenderProps, StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import React, { useCallback, useState } from 'react';
import { FelaComponent } from 'react-fela';
import { StyleSheet, View } from 'react-native';
import Button, { ButtonProps } from '../../../../../components/Button/Button';
import IconButton from '../../../../../components/Button/IconButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import PopupView from '../../../../../components/PopupView/PopupView';
import TitleBar from '../../../../../components/TitleBar/TitleBar';
import { useFela } from 'react-fela';
import { isWeb } from '../../../../../common/theme';
import { convertAlphaNumbericToNumber } from '@hitz-group/client-utils';

const styles = StyleSheet.create({
  container: {
    width: 400,
    alignSelf: 'center',
  },
  fullWidth: {
    justifyContent: 'space-between',
    width: '100%',
    height: 44,
    alignSelf: 'center',
  },
});

const closeButtonStyle: StyleFn = ({ theme }) => ({
  position: 'absolute',
  left: theme.spacing.big / 2,
});

const CloseButton: React.FC<ButtonProps> = ({ onPress }) => (
  <FelaComponent style={closeButtonStyle}>
    {({ style }: RenderProps) => (
      <IconButton icon="times" onPress={onPress} containerStyle={style} />
    )}
  </FelaComponent>
);

const submitButtonStyle: StyleFn = () => ({
  marginBottom: isWeb ? 0 : 20,
  marginTop: 20,
});

const formContainerStyle: StyleFn = ({ theme }) => ({
  width: 350,
  height: theme.input.height,
});

const popupContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  paddingVertical: theme.padding.medium * 1.9,
});

interface SetBusyModalProps {
  setBusyTime?: (time: number) => void;
}

export const SetBusyModal: React.FC<SetBusyModalProps> = ({
  setBusyTime,
}: SetBusyModalProps) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [busyTime, setTime] = useState(0);
  const { css } = useFela();

  const onSetBusyTime = useCallback(() => {
    setBusyTime && setBusyTime(busyTime);
    closeModal();
  }, [closeModal, setBusyTime, busyTime]);

  return (
    <View style={styles.container}>
      <TitleBar
        primary
        testID="title"
        title={translate('onlineOrders.setBusy').toUpperCase()}
        titleLeft={<CloseButton onPress={closeModal} />}
      />

      <PopupView containerStyle={css(popupContainerStyle)}>
        <FormInput
          error={false}
          testID="set-busy-time"
          placeholder={translate('onlineOrders.setBusyTime')}
          title={translate('onlineOrders.setBusyTime')}
          value={busyTime.toString()}
          alignTitle="left"
          containerStyle={css(formContainerStyle)}
          onChangeText={value => setTime(convertAlphaNumbericToNumber(value))}
        />

        <Button
          fluid
          danger
          onPress={onSetBusyTime}
          containerStyle={css(submitButtonStyle)}
          testID="set-busy-button"
          title={translate('onlineOrders.setBusy').toUpperCase()}
        />
      </PopupView>
    </View>
  );
};
