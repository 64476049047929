import BackgroundWorker from '../workers/BackgroundWorker';
import { makeVar } from '@apollo/client';

export const workerInstanceVar = makeVar<BackgroundWorker | undefined>(
  undefined,
);

export const failedPrintJobsCountVar = makeVar<number>(0);
export const pendingOnlineOrdersCountVar = makeVar<number>(0);
export const ordersUpdatedViaSubscription = makeVar<string[]>([]);
export const areProductsAvailabilityUpdatedViaSubscription =
  makeVar<boolean>(false);
export const isOolioPayRefundLoading = makeVar<boolean>(false);
