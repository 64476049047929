import { getBillingReceiptPrintableBuffer } from '../../../utils/printerTemplates/billingReceiptLayout';
import {
  PrinterConfig,
  validatePrintConfigurationOrInput,
} from '../../../utils/printerTemplates/printingDataUtils';
import { Notification } from '../../../hooks/Notification';
import {
  BufferHandlerResult,
  DEFAULT_NET_PRINTER_PORT,
  PrintBillWorkerInput,
  WorkerInput,
} from '../../utils';

const printBillHandler = (message: WorkerInput): BufferHandlerResult[] => {
  const {
    order,
    printerTemplateMapping,
    session,
    currency,
    nthPaymentToPrint,
  } = message.data as PrintBillWorkerInput;

  const data = validatePrintConfigurationOrInput(order, printerTemplateMapping);

  if (Object.keys(data).length > 0 && (data as Notification).message) {
    throw new Error((data as Notification).message);
  }

  const { printer, template } = data as PrinterConfig;
  const buffer = getBillingReceiptPrintableBuffer(
    order,
    session,
    currency,
    template,
    nthPaymentToPrint,
  );

  return [
    {
      printer: {
        id: printer.id,
        device_name: printer.name,
        host: printer.ipAddress,
        port: DEFAULT_NET_PRINTER_PORT,
        bdAddress: printer.bdAddress,
        brand: printer.brand,
        type: printer.printerType,
      },
      buffer,
    },
  ];
};
export default printBillHandler;
