import { Order, OrderPayment, OrderPaymentStatus } from '@hitz-group/domain';
import { formatMoneyValue } from '@hitz-group/localization';
import { table, getBorderCharacters, TableUserConfig } from 'table';

export type FixedTuple = [string, string, string];

/**
 * Payment details section has two columns and `n` row(s)
 */
const config: TableUserConfig = {
  columns: {
    0: {
      width: 17,
    },
    1: {
      width: 1,
    },
    2: {
      width: 18,
      paddingLeft: 2,
      alignment: 'right',
    },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    // TODO: get these from api / our custom hook
    paddingLeft: 0,
    paddingRight: 1,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

export const generateSplitPaymentDetails = (
  paymentsToPrint: OrderPayment[],
  currency: string,
): string => {
  const paymentsTable: [string, string, string][] = [];
  if (paymentsToPrint.length <= 0) return '';

  paymentsToPrint.forEach(payment => {
    paymentsTable.push([
      payment.paymentType.name || '',
      '',
      formatMoneyValue(payment.amount, currency),
    ]);
  });

  return table(paymentsTable, config);
};

export const generateCurrentNthPayment = (
  order: Order,
  currency: string,
  nthPaymentToPrint?: number,
): string => {
  if (typeof nthPaymentToPrint !== 'number') return '';

  const paymentsTable: [string, string, string][] = [];

  const nthPayment = order.payments[nthPaymentToPrint];

  const paymentTypeName = nthPayment.paymentType.name || '';
  paymentsTable.push([
    paymentTypeName,
    '',
    formatMoneyValue(nthPayment.tendered, currency),
  ]);

  if (paymentTypeName.toUpperCase() === 'CASH') {
    paymentsTable.push([
      'Change due',
      '',
      formatMoneyValue(nthPayment.tendered - nthPayment.amount, currency),
    ]);
  }

  return table(paymentsTable, config);
};

/**
 * Generate the payment details rows for billing receipt
 * @param order
 *
 * Example output:
 * ```bash
 * Total Paid                            $34.34
 * Total Price                          $202.10
 * ```
 */
export const generatePaymentDetails = (
  order: Order,
  currency: string,
  nthPaymentToPrint?: number,
): string => {
  // Print Split Payment
  if (typeof nthPaymentToPrint == 'number')
    return generateSplitPaymentDetails(
      order.payments.slice(0, nthPaymentToPrint),
      currency,
    );

  // Print Payment In Full
  const paymentsTable: [string, string, string][] = [];
  if (order.payments?.length > 1) {
    order.payments
      .filter(x => x.status === OrderPaymentStatus.COMPLETE)
      .forEach(payment => {
        paymentsTable.push([
          payment.paymentType.name || '',
          '',
          formatMoneyValue(payment.amount, currency),
        ]);
      });
    return table(paymentsTable, config);
  } else if (order.payments?.length === 1) {
    paymentsTable.push([
      'Payment Type',
      '',
      order.payments[0].paymentType.name || '',
    ]);
    const amountPaid = order.payments.reduce(
      (t, payment) => t + payment.tendered,
      0,
    );
    paymentsTable.push([
      'Total Paid',
      '',
      formatMoneyValue(amountPaid, currency),
    ]);
    const changeDue =
      amountPaid - (order.roundingAmount || 0) - order.totalPrice;

    if (changeDue >= 0)
      paymentsTable.push(['Change', '', formatMoneyValue(changeDue, currency)]);

    return table(paymentsTable, config);
  } else {
    return '';
  }
};

export const generateRefundPaymentDetails = (
  order: Order,
  currency: string,
): string => {
  const paymentsTable = [
    [
      'Refund Method',
      '',
      (order.payments[0].paymentType as unknown as { name: string })?.name ||
        order.payments[0].paymentType ||
        '',
    ],
    ['Total Refund', '', formatMoneyValue(order.payments[0].amount, currency)],
  ];
  return table(paymentsTable, config);
};

export const generateTotalOutStanding = (
  order: Order,
  currency: string,
  nthPaymentToPrint?: number,
): string => {
  if (typeof nthPaymentToPrint !== 'number') return '';

  const totalAmountDue = order.totalPrice;
  const allPreviousPayments = order.payments.slice(0, nthPaymentToPrint + 1);
  const totalAmountPaid = allPreviousPayments.reduce((sum, payment) => {
    sum = sum + payment.amount - (payment.roundOffDifference || 0);
    return sum;
  }, 0);
  const totalAmountOutstanding = (totalAmountDue - totalAmountPaid).toFixed(2);

  if (Number(totalAmountOutstanding) <= 0) return '';

  const totalOutstandingTable = [
    [
      'Total Outstanding',
      '',
      formatMoneyValue(Number(totalAmountOutstanding), currency),
    ],
  ];
  return table(totalOutstandingTable, config);
};
