import { StyleFn, TextAlignment } from '@hitz-group/domain';
import React, { useCallback, useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { View, TextStyle, ViewStyle, TextInputProps } from 'react-native';
import { Field, Label } from '../FormInput/FormInput';

export interface TimeInputProps extends TextInputProps {
  title?: string;
  fluid?: boolean;
  alignTitle?: TextAlignment;
  textStyle?: TextStyle;
  containerStyle?: ViewStyle;
  inputContainerStyle?: ViewStyle;
  textInputStyle?: TextStyle;
  dropDownStyle?: TextStyle;
}

const containerStyle: StyleFn = ({ fluid }) => ({
  flexDirection: 'column',
  justifyContent: 'space-around',
  width: fluid ? '100%' : 'auto',
});

const inputRowStyle: StyleFn = ({ fluid }) => ({
  flexDirection: 'row',
  justifyContent: 'space-around',
  width: fluid ? '100%' : 'auto',
});

const TimeInput: React.FC<TimeInputProps> = (props: TimeInputProps) => {
  const { css } = useFela({ fluid: props.fluid });
  const [time, setTime] = useState(props.value || '00:00:AM');
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [period, setPeriod] = useState('AM');

  useEffect(() => {
    if (props.value) {
      const result = convert24HrsFormatToString(props.value).split(':');
      setTime(result.join(':'));
      setHours(result[0] || '00');
      setMinutes(result[1] || '00');
      setPeriod(result[2] || 'AM');
    }
  }, [props.value]);

  useEffect(() => {
    setTime(`${hours}:${minutes}:${period}`);
  }, [hours, minutes, period]);

  const convert24HrsFormatToString = (input: string) => {
    const time = input.split(':');
    if (time.length > 1) {
      // Set AM/PM
      time[2] = +time[0] < 12 ? 'AM' : 'PM';
      // Adjust hours
      time[0] = `${+time[0] % 12}`;
    }
    return time.join(':');
  };

  const convertStringTo24HrsFormat = (input: string) => {
    const time = input.split(':');
    if (time.length > 2) {
      // Adjust hours
      time[0] = `${time[2] === 'AM' ? +time[0] : (+time[0] + 12) % 24}`;
      delete time[2];
    }
    return `${time[0]}:${time[1]}`;
  };
  const onBlur = useCallback(async () => {
    props.onChangeText && props.onChangeText(convertStringTo24HrsFormat(time));
  }, [time, props]);

  const onChangeHours = useCallback((value: string) => {
    setHours(value);
  }, []);
  const onChangeMinutes = useCallback((value: string) => {
    setMinutes(value);
  }, []);
  const onChangePeriod = useCallback(
    (value: string) => {
      setPeriod(value);
      onBlur();
    },
    [onBlur],
  );

  return (
    <View style={css(containerStyle)}>
      <Label
        alignTitle={props.alignTitle}
        textStyle={props.textStyle}
        title={props.title}
      >
        {props.title}
      </Label>
      <View style={css(inputRowStyle)}>
        <Field
          containerStyle={props.inputContainerStyle}
          textInputStyle={props.textInputStyle}
          onChangeText={onChangeHours}
          value={hours}
          placeholder="00"
          onBlur={onBlur}
          testID="hours"
        />
        <Field
          containerStyle={props.inputContainerStyle}
          textInputStyle={props.textInputStyle}
          placeholder="00"
          value={minutes}
          onChangeText={onChangeMinutes}
          onBlur={onBlur}
          testID="minutes"
        />
        <Field
          testID="period"
          type="DropDownInput"
          containerStyle={props.inputContainerStyle}
          selectedValue={period}
          onSelectChange={onChangePeriod}
          textInputStyle={props.textInputStyle}
          dropDownStyle={props.dropDownStyle}
          dropdownList={[
            {
              label: 'AM',
              value: 'AM',
            },
            {
              label: 'PM',
              value: 'PM',
            },
          ]}
          onBlur={onBlur}
        />
      </View>
    </View>
  );
};

TimeInput.defaultProps = {
  alignTitle: 'center',
  keyboardType: 'default',
};

export default TimeInput;
