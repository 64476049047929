import React, { useCallback, useEffect, useState } from 'react';
import { useNotification } from '../../../../../hooks/Notification';
import { useNavigation, useRoute } from '@react-navigation/native';
import { usePrinters } from '../../../../../hooks/app/usePrinters';
import PrinterDetails from './UIComponents/PrinterDetails';
import {
  CreatePrinterInput,
  Printer,
  PrinterBrand,
  PrinterType,
  UpdatePrinterInput,
} from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import pick from 'lodash/pick';
import { NEW_PRINTER_ID } from './constants';
import {
  isValidIp,
  isValidBDAddress,
  isNotEmpty,
} from '@hitz-group/client-utils';
import { usePrintingWithTemplate } from '../../../../../hooks/app/usePrintingWithTemplate';

export interface ProductData {
  id: string;
  name: string;
  selected: boolean;
}

export interface PageData {
  id: string;
  name: string;
  selected: boolean;
  products: Record<string, ProductData>;
}

export const PrinterDetailsContainer: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<Printer>({
    printerType: PrinterType.BLUETOOTH,
  } as Printer);

  const params = route.params as {
    storeId: string;
    printerId: string;
  };

  const isNewPrinter = params.printerId === NEW_PRINTER_ID;
  const storeId = params.storeId;
  const printerId = isNewPrinter ? undefined : params.printerId;
  const { printTestTemplate } = usePrintingWithTemplate();

  const {
    printers,
    updatePrinter,
    createPrinter,
    updatedPrinterId,
    createdPrinterId,
    loading,
    error,
  } = usePrinters({
    printerId,
    storeId,
  });

  useEffect(() => {
    if (printerId && printers[printerId]) {
      !isNewPrinter && setForm(printers[printerId]);
      // update the tab title
      navigation.setOptions({
        tabBarLabel: printers[printerId].name,
      });
    }
  }, [navigation, printers, printerId, isNewPrinter]);

  useEffect(() => {
    // update the tab title
    isNewPrinter &&
      navigation.setOptions({
        tabBarLabel: 'Create New',
      });
  }, [navigation, isNewPrinter]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  // on change events
  const onChange = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onSave = useCallback((): void => {
    const input: CreatePrinterInput | UpdatePrinterInput = pick(form, [
      'id',
      'name',
      'printerType',
      'ipAddress',
      'bdAddress',
      'brand',
    ]);

    input.printerType = input.printerType || PrinterType.BLUETOOTH;
    input.brand = input.brand || PrinterBrand.EPSON;
    if (
      !input.name ||
      (!input.bdAddress && !input.ipAddress) ||
      !isNotEmpty(input.name)
    ) {
      showNotification({
        error: true,
        message: translate('backOfficePrinters.fieldsMissing'),
      });
      return;
    }
    if (
      (input.printerType === PrinterType.WIFI ||
        input.printerType === PrinterType.LAN) &&
      !isValidIp(input.ipAddress)
    ) {
      showNotification({
        error: true,
        message: translate('backOfficePrinters.ipInvalid'),
      });
      return;
    }

    if (
      input.printerType === PrinterType.BLUETOOTH &&
      !isValidBDAddress(input.bdAddress)
    ) {
      showNotification({
        error: true,
        message: translate('backOfficePrinters.bdAddressInvalid'),
      });
      return;
    }

    if (isNewPrinter) {
      createPrinter(input as CreatePrinterInput);
    } else {
      updatePrinter(input as UpdatePrinterInput);
    }
  }, [
    showNotification,
    translate,
    createPrinter,
    updatePrinter,
    form,
    isNewPrinter,
  ]);

  useEffect(() => {
    if (updatedPrinterId) {
      showNotification({
        success: true,
        message: translate('backOfficePrinters.printerUpdatedSuccessfully'),
      });
    }
  }, [updatedPrinterId, showNotification, translate]);

  useEffect(() => {
    if (createdPrinterId) {
      showNotification({
        success: true,
        message: translate('backOfficePrinters.printerCreatedSuccessfully'),
      });

      navigation.navigate('StoreSettings', {
        screen: 'Printers',
        storeId,
      });
    }
  }, [createdPrinterId, showNotification, translate, navigation, storeId]);

  const onTestPrint = useCallback(() => {
    printTestTemplate(form);
  }, [form, printTestTemplate]);

  return (
    <PrinterDetails
      printer={form}
      options={{ loading }}
      onChange={onChange}
      onSave={onSave}
      onTestPrint={onTestPrint}
    />
  );
};
