import React from 'react';
import { FelaComponent } from 'react-fela';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import IconButton from '../Button/IconButton';
export interface RedoProps {
  onPress?: () => void;
}
const redoStyle: StyleFn = () => ({
  transform: [{ scaleX: -1 }],
});

const Redo: React.FC<RedoProps> = ({ onPress }: RedoProps) => (
  <FelaComponent style={redoStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <IconButton
        circular
        icon="redo"
        containerStyle={style}
        onPress={onPress}
        iconSize={24}
      />
    )}
  </FelaComponent>
);

export default Redo;
