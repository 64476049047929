import React, { useMemo } from 'react';
import TableRow from '../../../../../../components/TableComponent/TableRow';
import { useNavigation } from '@react-navigation/native';
import IconButton from '../../../../../../components/Button/IconButton';
import { DevicesListRowStyles } from '../styles/Devices.styles';
import { Text, View } from 'react-native';
import { useFela } from 'react-fela';
import { DeviceData } from '../DevicesListContainer';
import { useTranslation } from '@hitz-group/localization';
import Label from '../../../../../../components/Label/Label';
import { NO_DATA } from '@hitz-group/domain/dist';

interface Props {
  device: DeviceData;
  storeId: string;
}

const DevicesListRow: React.FC<Props> = ({ device, storeId }) => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const styles = DevicesListRowStyles();
  const { theme } = useFela();

  const deviceDetails: string = useMemo(() => {
    let text = device.details || NO_DATA;
    if (device.uuid) text += `\n••••${device.uuid.slice(-5)}`;
    return text;
  }, [device.details, device.uuid]);
  return (
    <TableRow
      normalRows={true}
      containerStyle={[
        styles.rowContainerStyle,
        device.isCurrent ? styles.highlightedContainerStyle : null,
      ]}
    >
      <View style={styles.columnRegular}>
        <Text style={styles.textStyle}>{device.name}</Text>
        {device.isCurrent ? (
          <Text style={[styles.textStyle, styles.highlightedTextStyle]}>
            {`(${translate('backOfficeDevices.currentLabel')})`}
          </Text>
        ) : null}
      </View>
      <View style={styles.columnFluid}>
        <Text testID={'deviceDetails'}>{deviceDetails}</Text>
      </View>
      <View style={styles.columnLarge}>
        <Text>{device.appVersion ? device.appVersion : NO_DATA}</Text>
      </View>
      <View style={styles.columnSmall}>
        <Label
          testID={'isPairedStatus'}
          value={
            device.isPaired
              ? translate('backOfficeDevices.paired')
              : translate('backOfficeDevices.unpaired')
          }
          success={device.isPaired}
        />
      </View>
      <View style={styles.actionsViewStyle}>
        <IconButton
          icon="AngleRight"
          iconSize={20}
          containerSize={34}
          containerStyle={styles.navigateContainerStyle}
          iconColor={theme.colors.paragraph}
          onPress={(): void => {
            navigation.navigate('DeviceSettings', {
              deviceId: device.id,
              storeId,
            });
          }}
        />
      </View>
    </TableRow>
  );
};

export default DevicesListRow;
