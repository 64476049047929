import { useLazyQuery } from '@apollo/client/react/hooks';
import { QueryLazyOptions, OperationVariables } from '@apollo/client';
import {
  CubeQuery,
  DateRangeFilterPresets,
  DateRangeFilterType,
  FilterValue,
  ReportFilterDropdown,
  ReportMetadata,
  Widget,
} from '@hitz-group/domain';
import {
  ProductsSummaryReportHelper,
  SalesFeedReportHelper,
  SalesSummaryReportHelper,
  SalesByStaffReportHelper,
  HourlySalesReportHelper,
  PaymentReportHelper,
  DeviceSummaryReportHelper,
  TaxReportHelper,
  SalesByCustomersReportHelper,
  VoidSummaryReportHelper,
  RefundReportHelper,
  SalesDashboardReportHelper,
  AdjustmentsReportHelper,
} from '@hitz-group/analytics-helper';
import { parseApolloError } from '../../utils/errorHandlers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CubejsApi, Query, ResultSet } from '@cubejs-client/core';
import * as storage from '../../storage/interface';
import { stripProperties } from '../../utils/stripObjectProps';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import {
  ShiftReport,
  WorklogReport,
  CustomReports,
  DateRangeFilterInput,
  HelperText,
} from '../../screens/BackOffice/Reports/types';
import { GET_SHIFTS_DIFFERENCE, GET_SHIFTS_REPORT } from '../../graphql/shifts';
import { GET_WORKLOGS_REPORT } from '../../graphql/worklogs';
import { useSession } from './useSession';
import { useReportSettings } from './useReportSettings';

export interface UseReporting {
  loading: boolean;
  error: string | undefined;
  reportMetadata: ReportMetadata | undefined;
  widgetData: Record<string, ResultSet | undefined>;
  shiftReportData: ShiftReport | undefined;
  worklogReportData: WorklogReport | undefined;
  queryData: ResultSet | undefined;
  getWidgetData: (widget: Widget, helper: string) => void;
  getReportMetadata: (helper: string) => void;
  getShiftData: (
    options: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  getWorklogData: (
    options: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  getShiftDifference: (
    options: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  getQueryData: (query: CubeQuery) => void;
}

const HELPER_MAP = {
  [HelperText.SALES_BY_STAFF]: SalesByStaffReportHelper,
  [HelperText.SALES_FEED]: SalesFeedReportHelper,
  [HelperText.SALES_SUMMARY]: SalesSummaryReportHelper,
  [HelperText.HOURLY_SALES]: HourlySalesReportHelper,
  [HelperText.PRODUCTS_SUMMARY]: ProductsSummaryReportHelper,
  [HelperText.PAYMENT_SUMMARY]: PaymentReportHelper,
  [HelperText.DEVICE_SUMMARY]: DeviceSummaryReportHelper,
  [HelperText.TAXES]: TaxReportHelper,
  [HelperText.SALES_CUSTOMER]: SalesByCustomersReportHelper,
  [HelperText.VOID_SUMMARY]: VoidSummaryReportHelper,
  [HelperText.REFUND]: RefundReportHelper,
  [HelperText.SALES_DASHBOARD]: SalesDashboardReportHelper,
  [HelperText.ADJUSTMENTS]: AdjustmentsReportHelper,
};

export const useReporting = (
  cubejsApi: CubejsApi | undefined,
): UseReporting => {
  const [reportMetadata, setReportMetadata] = useState<
    ReportMetadata | undefined
  >(undefined);

  const [shiftReportData, setShiftReportData] = useState<
    ShiftReport | undefined
  >(undefined);

  const [worklogReportData, setWorklogReportData] = useState<
    WorklogReport | undefined
  >(undefined);

  const [widgetData, setWidgetData] = useState<
    Record<string, ResultSet | undefined>
  >({});

  const [queryData, setQueryData] = useState<ResultSet | undefined>(undefined);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string>();
  const [session] = useSession();

  const getReportMetadata = useCallback(
    (helper: string) => {
      const helperHandler = HELPER_MAP[helper as HelperText];
      const ddFilters: ReportFilterDropdown[] =
        helperHandler.getDropdownFilters();
      const widgets = helperHandler.getWidgets();
      setReportMetadata({
        filters: ddFilters,
        widgets: widgets.map(widget => {
          widget.query.timezone = session.currentOrganization?.timezone;
          return widget;
        }),
      } as unknown as ReportMetadata);
    },
    [session.currentOrganization?.timezone],
  );

  const { getDateTime, dateTime } = useReportSettings();

  useEffect(() => {
    getDateTime();
  }, [getDateTime]);

  const onCompleteGetWorklogReport = useCallback(async data => {
    if (data && data.worklogs instanceof Array) {
      const updateMetadata = async () => {
        const cached = await storage.getItem(CustomReports.WORKLOG);
        setWorklogReportData(
          stripProperties(
            {
              worklogs: data.worklogs,
              query: cached || {
                filter: {} as FilterValue,
                dateRange: {
                  key: 'date',
                  text: '',
                  type: DateRangeFilterType.FILTER_BY_PRESET,
                  value: DateRangeFilterPresets.TODAY,
                } as DateRangeFilterInput,
              },
            },
            '__typename',
          ) as unknown as WorklogReport,
        );
      };
      updateMetadata();
    } else {
      return;
    }
  }, []);

  const onCompleteGetShiftReport = useCallback(async data => {
    if (data && data.shifts instanceof Array) {
      const updateMetadata = async () => {
        const cached = await storage.getItem(CustomReports.SHIFT);
        setShiftReportData(
          stripProperties(
            {
              shifts: data.shifts,
              query: cached || {
                filter: {} as FilterValue,
                dateRange: {
                  key: 'date',
                  text: '',
                  type: DateRangeFilterType.FILTER_BY_PRESET,
                  value: DateRangeFilterPresets.TODAY,
                } as DateRangeFilterInput,
              },
            },
            '__typename',
          ) as unknown as ShiftReport,
        );
      };
      updateMetadata();
    } else {
      return;
    }
  }, []);

  const [getShiftDataRequest, getShiftDataResponse] = useLazyQuery(
    GET_SHIFTS_REPORT,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetShiftReport,
    },
  );

  const [getWorklogDataRequest, getWorklogDataResponse] = useLazyQuery(
    GET_WORKLOGS_REPORT,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetWorklogReport,
    },
  );

  const [getShiftDifferenceRequest, getShiftDifferenceResponse] = useLazyQuery(
    GET_SHIFTS_DIFFERENCE,
    {
      fetchPolicy: 'network-only',
      onCompleted: onCompleteGetShiftReport,
    },
  );

  const getWidgetData = useCallback(
    async (widget: Widget, helper?: string) => {
      try {
        setLoadingData(true);
        // Checking dateTime here as well.
        // Hook "useReportSettings" returns data undefined too,
        // That was causing 2 api calls internally (one with no date and one with it).
        if (!cubejsApi || !dateTime) return;
        const helperHandler = HELPER_MAP[helper as HelperText];
        const result: ResultSet = await cubejsApi.load(
          helperHandler.convertToCubeQuery(widget.query, dateTime) as Query,
        );
        setWidgetData(data => ({ ...data, [widget.name]: result }));
      } catch (error: unknown) {
        if (error && error instanceof Error) setLoadingError(error.message);
      } finally {
        setLoadingData(false);
      }
    },
    [cubejsApi, dateTime],
  );

  const getQueryData = useCallback(
    async (query: CubeQuery) => {
      try {
        setLoadingData(true);
        if (!cubejsApi) return;
        const result: ResultSet = await cubejsApi.load(query as Query);
        setQueryData(result);
      } catch (error) {
        if (error && error instanceof Error) setLoadingError(error.message);
      } finally {
        setLoadingData(false);
      }
    },
    [cubejsApi],
  );

  const RESPONSE_ENTITIES = [
    getShiftDataResponse,
    getShiftDifferenceResponse,
    getWorklogDataResponse,
  ];

  const loading = isLoading(RESPONSE_ENTITIES) || loadingData;
  const error = getError(RESPONSE_ENTITIES);

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : loadingError || undefined,
      widgetData,
      reportMetadata,
      shiftReportData,
      worklogReportData,
      queryData,
      getWidgetData,
      getReportMetadata,
      getShiftData: getShiftDataRequest,
      getWorklogData: getWorklogDataRequest,
      getShiftDifference: getShiftDifferenceRequest,
      getQueryData,
    }),
    [
      loading,
      loadingError,
      error,
      widgetData,
      reportMetadata,
      shiftReportData,
      worklogReportData,
      queryData,
      getWidgetData,
      getReportMetadata,
      getShiftDataRequest,
      getWorklogDataRequest,
      getShiftDifferenceRequest,
      getQueryData,
    ],
  );
};
