import {
  StyleFn,
  PricingGroup,
  UpdatePricingGroupInput,
  ProductPricingInput,
  Currency,
  DEFAULT_PRICING_GROUP,
  FeatureContext,
  Features,
} from '@hitz-group/domain';
import { ProductModel } from '@hitz-group/dal';

import { UpdateProductInput } from '@hitz-group/domain';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View, Text } from 'react-native';
import { useModal } from '@hitz-group/rn-use-modal';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import FormInput from '../../../../../components/FormInput/FormInput';
import Button from '../../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import DropDown from '../../../../../components/DropDown/DropDown';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import { useRoute } from '@react-navigation/native';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import { PricingListRow } from './PricingListRow';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { useTaxes } from '../../../../../hooks/app/useTaxes';
import { useProductPricings } from '../../../../../hooks/app/useProductPricings';
import { usePricingGroups } from '../../../../../hooks/app/usePricingGroups';
import { Operation } from '../../../../../types/Operation';
import scale, { isWeb } from '../../../../../common/theme';
import {
  is9DigitWith2Decimal,
  isValidPrice,
} from '../../../../../utils/validator';
import { PRODUCT_PRICING_FRAGMENT } from '../../../../../hooks/app/products/graphql';
import { WithFeature } from '../../../../../../src/components/features/WithFeature';

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 260,
  height: 38,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: theme.padding.medium,
});

const checkBoxTitleStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  textTransform: 'none',
  color: theme.colors.paragraph,
});

const BackOfficeContainerNormalStyle: StyleFn = ({ theme }) => ({
  paddingBottom: theme.padding.medium * 1.5,
  width: 545,
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const innerContainer: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  zIndex: 10,
});
const singleBodyInnerContainer: StyleFn = () => ({
  flexDirection: 'row',
  width: 272,
  zIndex: 5,
});
const costPriceBody: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: theme.colors.white,
  width: 250,
  left: 10,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

export const width100row: StyleFn = () => ({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});
export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const checkContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.big / 2,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const formInputContainerStyle: StyleFn = ({ theme, width, marginTop }) => ({
  width: width,
  height: theme.input.height,
  marginTop: isWeb ? marginTop || 0 : 0,
});
const textInputStyle: StyleFn = ({ theme, centerAlign, width }) => ({
  width: width ? width : 125,
  height: 38,
  textAlign: centerAlign ? 'center' : undefined,
  paddingLeft: centerAlign ? 0 : theme.padding.medium * 1.5,
});

const formLabelStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.padding.medium * 1.5,
  paddingHorizontal: 0,
  lineHeight: 21,
  ...theme.font14Regular,
  color: theme.colors.charcoal,
});
const LabelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Regular,
  lineHeight: 21,
  color: theme.colors.charcoal,
});
const dropDownItemsContainerStyle: StyleFn = () => ({
  height: 38 * 3, // three items of 38 height
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dropDownStyle: StyleFn = ({ theme, width }) => ({
  height: theme.input.height,
  width: width,
  zIndex: 5,
});
const extraPopoverStyle: StyleFn = () => ({
  width: 100,
});

const prefixTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  alignContent: 'center',
  paddingLeft: theme.padding.medium * 1.5,
});
const divStyle: StyleFn = () => ({
  flexDirection: 'row',
  zIndex: 5,
  height: 65,
  justifyContent: 'center',
  alignItems: 'center',
});

const searchResultStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 40,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  paddingLeft: theme.padding.medium / 2,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  marginTop: theme.spacing.small,
});

const searchTitleStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.regular,
  textTransform: 'none',
  letterSpacing: -0.5,
  marginHorizontal: theme.spacing.small,
});

const rightIconStyle: StyleFn = () => ({
  height: 34,
  width: 20,
  justifyContent: 'center',
  alignSelf: 'center',
  marginLeft: 'auto',
});

interface CustomProductModel extends ProductModel {
  priceGroupsDictionary: { [key: string]: UpdatePricingGroupInput };
  defaultPriceGroupId: string;
}

const PRICE_LIST_SETTING = {
  name: Features.PRICE_LIST,
  context: FeatureContext.VENUE,
};

export const Pricing: React.FC = () => {
  const { css, theme } = useFela();
  const route = useRoute();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { currency, formatCurrency } = useCurrency();

  const productId = (route.params as { productId: string })?.productId || '';
  const [productData, setProductData] = useState({} as CustomProductModel);
  const [pricingGroupData, setPricingGroupData] = useState(
    {} as { [key: string]: PricingGroup },
  );
  const [searchString, setSearchString] = useState('');
  const [deletePricingId, setDeletePricingId] = useState('');
  const { taxesOptions, error: taxesError } = useTaxes();
  const {
    products,
    updateProduct: updateProductDetails,
    refetchProduct,
    error: productError,
    loading: productLoading,
    operation: productOperation,
  } = useProducts(productId, PRODUCT_PRICING_FRAGMENT);
  const currencySymbol = formatCurrency(0).split('0.00')[0];
  // PP refers to product pricing
  const {
    update: updatePP,
    addProductPricing,
    error: errorPPReq,
    loading: loadingPP,
    operation: PPMethod,
    delete: deletePricing,
  } = useProductPricings();

  const {
    pricingGroupByName,
    loading: loadingPG,
    error: errorPG,
  } = usePricingGroups();

  const error = errorPPReq || productError || taxesError || errorPG;

  const loading = loadingPP || productLoading || loadingPG;

  useEffect(() => {
    if (!error && !loading && PPMethod === Operation.CREATE) {
      showNotification({
        success: true,
        message: translate('productSettings.productPricesSavedSuccessfully'),
      });
      refetchProduct();
    }
  }, [
    loading,
    error,
    showNotification,
    translate,
    PPMethod,
    refetchProduct,
    productId,
  ]);

  useEffect(() => {
    if (
      !error &&
      !loading &&
      PPMethod === Operation.UPDATE &&
      productOperation === Operation.UPDATE
    ) {
      showNotification({
        success: true,
        message: translate('productSettings.productPricesSavedSuccessfully'),
      });
    }
  }, [loading, error, showNotification, translate, PPMethod, productOperation]);

  useEffect(() => {
    if (
      !error &&
      !loading &&
      PPMethod === Operation.DELETE &&
      deletePricingId
    ) {
      closeModal();
      showNotification({
        success: true,
        message: translate('backOfficeProducts.itemWasDeletedSuccessfully', {
          item: productData.priceGroupsDictionary[deletePricingId].name,
        }),
      });
      const productDataTemp = { ...productData };
      delete productDataTemp.priceGroupsDictionary[deletePricingId];
      setProductData(productDataTemp);
      setDeletePricingId('');
    }
  }, [
    loading,
    error,
    showNotification,
    translate,
    PPMethod,
    closeModal,
    productData,
    deletePricingId,
  ]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const defaultPrice = useMemo(() => {
    if (
      productData?.defaultPriceGroupId &&
      productData?.priceGroupsDictionary
    ) {
      return productData.priceGroupsDictionary[productData.defaultPriceGroupId];
    } else {
      return {} as UpdatePricingGroupInput;
    }
  }, [productData]);

  useEffect(() => {
    if (productId && products[productId]) {
      const productModelData = {} as CustomProductModel;
      const product = products[productId];
      productModelData.id = product.id;
      productModelData.name = product.name;
      productModelData.variablePricing = product.variablePricing || false;
      productModelData.maxSellingPrice = product.maxSellingPrice;
      productModelData.minSellingPrice = product.minSellingPrice;
      const priceGroupsFormated = product.pricingGroups.map(
        eachPriceGroup =>
          ({
            id: eachPriceGroup.id,
            name: eachPriceGroup.name,
            prices: eachPriceGroup.prices.map(eachPrice => ({
              product: eachPrice.product?.id,
              id: eachPrice.id,
              taxInclusive: eachPrice.taxInclusive || false,
              costTax:
                (eachPrice?.costTax as unknown as { id: string })?.id || '',
              sellingTax:
                (eachPrice?.sellingTax as unknown as { id: string })?.id || '',
              costPrice: {
                amount: eachPrice.costPrice?.amount || 0,
                currency: eachPrice?.costPrice?.currency || currency,
              },
              sellingPrice: {
                amount: eachPrice.sellingPrice?.amount || 0,
                currency: eachPrice.sellingPrice?.currency || currency,
              },
            })),
          } as unknown as UpdatePricingGroupInput),
      );
      productModelData.priceGroupsDictionary = keyBy(priceGroupsFormated, 'id');
      productModelData.defaultPriceGroupId =
        find(priceGroupsFormated, { name: DEFAULT_PRICING_GROUP })?.id || '';
      setProductData(productModelData);
    }
  }, [products, productId, currency, productData.id]);

  const onChangeProduct = useCallback((prop, value) => {
    setProductData(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onChange = useCallback(
    (priceGroupId, prop: string, value) => {
      const newValue = value.toString().slice(1, value.length);
      const productInfo = { ...productData };
      if (prop === 'costPrice' || prop === 'sellingPrice') {
        if (!is9DigitWith2Decimal(newValue)) return;
        const temp = value.includes(currencySymbol)
          ? value.split(currencySymbol)[1]
          : value;
        value = { currency: currency as Currency, amount: temp };
      }
      productData.priceGroupsDictionary[priceGroupId].prices[0] = {
        ...productData.priceGroupsDictionary[priceGroupId].prices[0],
        [prop]: value,
      };
      if (
        prop === 'costPrice' &&
        Object.keys(productData.priceGroupsDictionary).length > 1
      ) {
        for (const key in productData.priceGroupsDictionary) {
          // Updating the cost price in other Price lists too.
          if (key !== priceGroupId) {
            productData.priceGroupsDictionary[key].prices[0] = {
              ...productData.priceGroupsDictionary[key].prices[0],
              [prop]: value,
            };
          }
        }
      }
      setProductData(productInfo);
    },
    [productData, currency, currencySymbol],
  );

  const onSaveProduct = useCallback((): void => {
    if (
      (defaultPrice.prices[0].sellingPrice.amount &&
        !isValidPrice(String(defaultPrice.prices[0].sellingPrice.amount))) ||
      (defaultPrice.prices[0].costPrice.amount &&
        !isValidPrice(String(defaultPrice.prices[0].costPrice.amount)))
    ) {
      showNotification({
        error: true,
        message: translate('form.requiredField', { fieldName: 'price' }),
      });
    } else {
      const updateProductInput = {
        id: productData.id,
        variablePricing: productData.variablePricing,
        maxSellingPrice: +productData.maxSellingPrice,
        minSellingPrice: +productData.minSellingPrice,
      } as UpdateProductInput;

      const updateProductPricingsData = Object.values(
        productData.priceGroupsDictionary,
      ).map(x => {
        const tempPrices = { ...x.prices[0] };
        tempPrices.sellingPrice.amount = +tempPrices.sellingPrice.amount;
        tempPrices.costPrice.amount = +tempPrices.costPrice.amount;
        return {
          ...x.prices[0],
          pricingGroupId: x.id,
          product: productData.id,
          // add the default selling tax to pricing groups as it is not available in UI
          sellingTax: defaultPrice?.prices?.[0]?.sellingTax,
        };
      }) as ProductPricingInput[];
      updateProductDetails(updateProductInput);
      updatePP(updateProductPricingsData);
    }
  }, [
    productData,
    updateProductDetails,
    updatePP,
    defaultPrice?.prices,
    showNotification,
    translate,
  ]);

  const onDeletePricing = useCallback(
    (pricingGroupId, name): void => {
      setDeletePricingId(pricingGroupId);
      showModal(
        <ConfirmationDialog
          title={translate('dialog.deleteTitle')}
          message={translate('dialog.deleteConfirmation', { label: name })}
          onConfirm={() => deletePricing([{ pricingGroupId, productId }])}
        />,
      );
    },
    [showModal, productId, deletePricing, translate],
  );
  const onChangePriceListByName = useCallback(
    (name: string) => {
      setSearchString(name);
      const pricingGroup = pricingGroupByName(name);
      setPricingGroupData(pricingGroup);
    },
    [pricingGroupByName],
  );

  const onPressAddOrRemovePriceList = (pricingGrp: PricingGroup) => {
    setSearchString('');
    if (productData.priceGroupsDictionary[pricingGrp.id]) {
      onDeletePricing(pricingGrp.id, pricingGrp.name);
      return;
    }
    const tempPrices = { ...defaultPrice?.prices[0] };
    tempPrices.sellingPrice = {
      amount: +tempPrices.sellingPrice?.amount,
      currency: tempPrices.sellingPrice?.currency,
    };
    tempPrices.costPrice = {
      amount: +tempPrices.costPrice?.amount,
      currency: tempPrices.costPrice?.currency,
    };
    const productPricing = {
      ...tempPrices,
      sellingTax: tempPrices.sellingTax,
      costTax: tempPrices.costTax,
      pricingGroupId: pricingGrp.id,
      product: productData.id,
    } as ProductPricingInput;
    addProductPricing(productId, [
      { pricingGroupId: pricingGrp.id, productPricing },
    ]);
  };

  const pricingGroupDataArray = useMemo(() => {
    return Object.values(pricingGroupData || {});
  }, [pricingGroupData]);

  const defaultSellingPrice = defaultPrice?.prices?.[0]?.sellingPrice?.amount;
  const defaultCostPrice = defaultPrice?.prices?.[0]?.costPrice?.amount;
  const pricesList = Object.values(productData?.priceGroupsDictionary || {});
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.pricing', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView style={css(pageStyle)}>
        <View style={css(width100row)}>
          <BackOfficeSection
            title={translate('productSettings.productPricing')}
            contentContainerStyle={css(BackOfficeContainerNormalStyle)}
            titleBorderBottom
          >
            <View style={css(innerContainer)}>
              <Button
                title={translate('productSettings.variablePricing')}
                labelStyle={css(checkBoxTitleStyle)}
                fluid
                iconPosition={'left'}
                containerStyle={css(checkBoxTitleContainer)}
                iconContainerStyle={css(checkContainer)}
                icon={productData.variablePricing ? 'toggle-on' : 'toggle-off'}
                iconProps={{
                  color: productData.variablePricing
                    ? theme.colors.success
                    : theme.colors.paragraph,
                  size: 26,
                }}
                onPress={() =>
                  onChangeProduct(
                    'variablePricing',
                    !productData.variablePricing,
                  )
                }
              />
              <Button
                title={translate('productSettings.includeTaxInDisplayPrice')}
                labelStyle={css(checkBoxTitleStyle)}
                fluid
                iconPosition={'left'}
                containerStyle={css(checkBoxTitleContainer)}
                iconContainerStyle={css(checkContainer)}
                icon={
                  defaultPrice?.prices?.[0]?.taxInclusive
                    ? 'toggle-on'
                    : 'toggle-off'
                }
                iconProps={{
                  color: defaultPrice?.prices?.[0]?.taxInclusive
                    ? theme.colors.success
                    : theme.colors.paragraph,
                  size: 26,
                }}
                onPress={onChange.bind(
                  null,
                  defaultPrice.id,
                  'taxInclusive',
                  !defaultPrice?.prices?.[0]?.taxInclusive,
                )}
              />
            </View>
            <View style={css(innerContainer)}>
              <View style={css(costPriceBody)}>
                <Text style={css(LabelStyle)}>
                  {translate('productSettings.sellPriceAndTax')}
                </Text>
              </View>
              <View style={css(costPriceBody)}>
                <Text style={css(LabelStyle)}>
                  {translate('productSettings.costPriceAndTax')}
                </Text>
              </View>
            </View>
            <View style={css(innerContainer)}>
              <View style={css(divStyle)}>
                <DropDown
                  options={taxesOptions}
                  selectedValue={defaultPrice?.prices?.[0]?.sellingTax || ''}
                  containerStyle={css(dropDownStyle({ theme, width: 100 }))}
                  onValueChange={value =>
                    onChange(defaultPrice.id, 'sellingTax', value)
                  }
                  itemsContainerStyle={css(dropDownItemsContainerStyle)}
                  extraPopoverStyle={css(extraPopoverStyle)}
                />
                <FormInput
                  error={false}
                  testID="selling-price"
                  value={
                    defaultSellingPrice
                      ? currencySymbol + defaultSellingPrice
                      : ''
                  }
                  alignTitle="left"
                  containerStyle={css(
                    formInputContainerStyle({
                      theme,
                      width: 147,
                      marginTop: 20,
                    }),
                  )}
                  onChangeText={value =>
                    onChange(defaultPrice.id, 'sellingPrice', value)
                  }
                  maxLength={13}
                  keyboardType="numeric"
                />
              </View>
              <View style={css(divStyle)}>
                <DropDown
                  options={taxesOptions}
                  selectedValue={defaultPrice?.prices?.[0]?.costTax || ''}
                  containerStyle={css(dropDownStyle({ theme, width: 100 }))}
                  onValueChange={value =>
                    onChange(defaultPrice.id, 'costTax', value)
                  }
                  itemsContainerStyle={css(dropDownItemsContainerStyle)}
                  extraPopoverStyle={css(extraPopoverStyle)}
                />
                <FormInput
                  error={false}
                  testID="cost-price"
                  value={
                    defaultCostPrice ? currencySymbol + defaultCostPrice : ''
                  }
                  alignTitle="left"
                  containerStyle={css(
                    formInputContainerStyle({
                      theme,
                      width: 147,
                      marginTop: 20,
                    }),
                  )}
                  onChangeText={value =>
                    onChange(defaultPrice.id, 'costPrice', value)
                  }
                  maxLength={13}
                  keyboardType="numeric"
                />
              </View>
            </View>
            <View style={css(singleBodyInnerContainer)}>
              <FormInput
                error={false}
                testID="priceListMin"
                title={translate('productSettings.variablePriceRange')}
                value={
                  productData?.minSellingPrice
                    ? currencySymbol + productData?.minSellingPrice
                    : ''
                }
                alignTitle="left"
                containerStyle={css(
                  formInputContainerStyle({ theme, width: 125 }),
                )}
                onChangeText={value =>
                  onChangeProduct(
                    'minSellingPrice',
                    value.includes(currencySymbol)
                      ? value.split(currencySymbol)[1]
                      : value,
                  )
                }
                prefix={{
                  text: translate('productSettings.min') + ':',
                  textStyle: css(prefixTextStyle),
                }}
              />

              <FormInput
                error={false}
                testID="priceListMax"
                value={
                  (productData?.maxSellingPrice &&
                    currencySymbol + productData?.maxSellingPrice) ||
                  ''
                }
                title={' '}
                alignTitle="left"
                containerStyle={css(
                  formInputContainerStyle({ theme, width: 125 }),
                )}
                textInputStyle={css(textInputStyle({ theme, width: 70 }))}
                textStyle={css(formLabelStyle)}
                onChangeText={value =>
                  onChangeProduct(
                    'maxSellingPrice',
                    value.includes(currencySymbol)
                      ? value.split(currencySymbol)[1]
                      : value,
                  )
                }
                prefix={{
                  text: translate('productSettings.max') + ':',
                  textStyle: css(prefixTextStyle),
                }}
              />
            </View>
          </BackOfficeSection>

          <WithFeature feature={PRICE_LIST_SETTING}>
            <BackOfficeSection
              title={translate('productSettings.priceLists')}
              contentContainerStyle={css(BackOfficeContainerNormalStyle)}
              titleBorderBottom
            >
              <FormInput
                error={false}
                testID="priceList"
                placeholder={
                  translate('productSettings.searchPriceList') + '...'
                }
                value={
                  searchString.trim().length > 0
                    ? searchString.trim()
                    : undefined
                }
                title={translate('productSettings.productPriceList')}
                alignTitle="left"
                containerStyle={css(
                  formInputContainerStyle({ theme, width: 545 }),
                )}
                onChangeText={onChangePriceListByName}
              />
              {searchString.trim().length > 0 &&
                pricingGroupDataArray.map(pricingGrp => (
                  <Button
                    testID="priceListBtn"
                    fluid
                    key={pricingGrp.id}
                    containerStyle={css(searchResultStyle)}
                    labelStyle={css(searchTitleStyle)}
                    iconContainerStyle={css(rightIconStyle)}
                    iconProps={{
                      color: theme.colors.paragraph,
                      size: 24,
                    }}
                    title={pricingGrp.name}
                    iconPosition={'right'}
                    icon={
                      productData.priceGroupsDictionary[pricingGrp.id]
                        ? 'minus'
                        : 'plus'
                    }
                    onPress={() => onPressAddOrRemovePriceList(pricingGrp)}
                  />
                ))}
              <TableComponent
                columns={[
                  {
                    title: translate('productSettings.listName'),
                    width: 150,
                  },
                  {
                    title: translate('productSettings.default'),
                    width: 80,
                  },
                  {
                    title: translate('productSettings.setPrice'),
                    width: 80,
                  },
                  { title: '', width: 30 },
                ]}
                data={pricesList}
                renderRow={(
                  item: PricingGroup,
                  index: number,
                ): React.ReactNode =>
                  item.name !== DEFAULT_PRICING_GROUP && (
                    <PricingListRow
                      pricingGroup={item}
                      defaultPrice={formatCurrency(+(defaultSellingPrice || 0))}
                      key={index}
                      onChange={onChange}
                      onDeletePricing={onDeletePricing}
                    />
                  )
                }
              />
            </BackOfficeSection>
          </WithFeature>
        </View>
        <View style={css(bottomSpace)}></View>
      </ScrollView>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onSaveProduct}
          />
        </View>
      </View>
    </>
  );
};
