import { StyleFn } from '@hitz-group/domain';
import React, { useState } from 'react';
import { useFela } from 'react-fela';
import { View, ScrollView } from 'react-native';
import SelectBar from '../../../components/Button/SelectBar';
import {
  DrawerButton,
  LeftArrow,
  LockIcon,
  SubscriptionStatusButton,
} from '../../../components/HeaderIcons/HeaderIcons';
import { useTranslation } from '@hitz-group/localization';
import { useNavigation } from '@react-navigation/native';
import Layout from '../../../components/POSLayout/POSLayout';
import SettingsAccountTab from '../../../components/SettingsAccountTab/SettingsAccountTab';
import SettingsHardwareTab from '../../../components/SettingsHardwareTab/SettingsHardwareTab';
import NewOrderButton from '../../../components/NewOrder/NewOrderButton';

const containerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.background,
  height: '100%',
});

const headerTitleStyle: StyleFn = () => ({
  width: 300,
  height: 40,
});

export type Tab = {
  label: string;
  value: string;
};

export enum SettingsTab {
  ACCOUNT = 'ACCOUNT',
  HARDWARE = 'HARDWARE',
  NONE = 'NONE',
}

const INVALID_TAB = { label: '', value: '' };

const Settings: React.FC = () => {
  const navigation = useNavigation();
  const backHandler = (): void => {
    navigation.goBack();
  };
  const { css } = useFela();
  const { translate } = useTranslation();
  const currentLayoutTab = SettingsTab.ACCOUNT;
  const TABS = [
    {
      label: translate('settings.account'),
      value: SettingsTab.ACCOUNT,
    },
    {
      label: translate('settings.hardware'),
      value: SettingsTab.HARDWARE,
    },
  ];

  const [currentTab, setCurrentTab] = useState<Tab>(TABS[0]);

  const onPressSettingsTab = (tabValue: string): void => {
    const selectedTab = TABS.find(x => x.value === tabValue) || INVALID_TAB;
    setCurrentTab(selectedTab);
  };

  // Header components
  const headerTitle = (
    <View testID="settings-head-title" style={css(headerTitleStyle)}>
      <SelectBar
        secondary
        options={TABS}
        onPress={onPressSettingsTab}
        selectedOption={currentTab.value}
      />
    </View>
  );
  const SettingsHeaderLeft = <LeftArrow onPress={backHandler} />;
  const settingsHeaderRight = (
    <>
      <LockIcon onPress={(): void => navigation.navigate('Lock')} />
      <SubscriptionStatusButton />
      <NewOrderButton />
      <DrawerButton />
    </>
  );

  return (
    <Layout
      title={translate('navigation.settingsPageTitle', {
        appName: translate('appName'),
      })}
      headerRight={settingsHeaderRight}
      headerLeft={SettingsHeaderLeft}
      headerTitle={headerTitle}
    >
      <View testID="settings" style={css(containerStyle)}>
        {currentTab.value === currentLayoutTab ? (
          <ScrollView>
            <SettingsAccountTab />
          </ScrollView>
        ) : (
          <ScrollView>
            <SettingsHardwareTab />
          </ScrollView>
        )}
      </View>
    </Layout>
  );
};

export default Settings;
