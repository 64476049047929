import { StyleFn, Role } from '@hitz-group/domain';
import React, { useEffect, useCallback, useMemo } from 'react';
import { useFela } from 'react-fela';
import { Helmet } from 'react-helmet';
import { View, ScrollView } from 'react-native';
import TableComponent from '../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { useTranslation } from '@hitz-group/localization';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useNotification } from '../../../../hooks/Notification';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { useRoles } from '../../../../hooks/app/users/useRoles';
import { ManageRoleRow } from './ManageRoleRow';
import { isWeb } from '../../../../common/theme';

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big + theme.spacing.small / 2,
  width: isWeb ? 800 : '100%',
  alignSelf: 'center',
});

const contentStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.medium,
});

export const ManageRoles: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const navigation = useNavigation();

  const { roles, error, loading, fetchRoles } = useRoles();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      fetchRoles({ includeSystemRoles: true, includeUserCount: true });
    }
  }, [fetchRoles, isFocused]);

  const gotoCreateRole = useCallback(() => {
    navigation.navigate('JobRoleSettings', {
      roleId: undefined,
    });
  }, [navigation]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const columns = useMemo(
    () => [
      {
        title: translate('backofficeManageRoles.roleName'),
        flex: 4.2,
        alignItems: 'flex-start',
        containerStyle: { paddingLeft: 10 },
      },
      {
        title: translate('backofficeManageRoles.permissionsCount'),
        flex: 1.5,
        alignItems: 'flex-start',
      },
      {
        title: translate('backofficeManageRoles.userCount'),
        flex: 1.5,
        alignItems: 'flex-start',
      },
      { title: '', width: 38 },
    ],
    [translate],
  );

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView>
          <BackOfficeSection
            title={translate('backofficeManageRoles.manageRoles')}
            contentContainerStyle={css(contentStyle)}
            titleBorderBottom
            action={<BackOfficeCreateNewButton onPress={gotoCreateRole} />}
            containerStyle={css(backOfficeContainerStyle)}
            titleDescription={translate(
              'backofficeManageRoles.manageRolesDescription',
            )}
          >
            <View>
              <TableComponent
                columns={columns}
                data={roles}
                normalRows={true}
                renderRow={(item: Role): React.ReactNode => (
                  <ManageRoleRow role={item} key={item.id} />
                )}
              />
            </View>
          </BackOfficeSection>
        </ScrollView>
      </View>
    </>
  );
};
