import {
  StyleFn,
  MoneyMovementReasonInput,
  MoneyEventType,
} from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import FormInput from '../../../components/FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../components/Button/Button';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import Modal from '../Modal';
import DropDown from '../../DropDown/DropDown';
import scale, { isWeb } from '../../../common/theme';

const createModalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginLeft: 0,
  marginTop: theme.spacing.medium,
});

const formTextStyle: StyleFn = () => ({
  left: 5,
});

const itemContainer: StyleFn = () => ({
  height: 38 * 2,
});

const createLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const formInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: scale.textInputWidth180,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

const dropdownStyle: StyleFn = ({ theme }) => ({
  width: '90%',
  height: theme.input.height,
  alignSelf: 'center',
  marginTop: 20,
  marginBottom: 30,
});
const extraPopoverStyle: StyleFn = () => ({
  width: 280,
});
export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: scale.textInputWidth180,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});
export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? scale.moderateScale(180) : '50%',
  marginTop: scale.moderateScale(2),
});
interface CreateMoneyMovementReasonProps {
  onCreate: (moneyMovementReason: MoneyMovementReasonInput) => void;
}

export const CreateMoneyMovementReason: React.FC<
  CreateMoneyMovementReasonProps
> = ({ onCreate }: CreateMoneyMovementReasonProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [form, setForm] = useState({
    eventType: MoneyEventType.MONEY_IN,
  } as MoneyMovementReasonInput);
  const { showNotification } = useNotification();

  const onChange = useCallback((prop: string, value) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onCreateMoneyMovementReason = useCallback(() => {
    if (!form.name || !form.eventType) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
    } else {
      onCreate(form);
      closeModal();
    }
  }, [form, onCreate, translate, showNotification, closeModal]);

  const eventTypes = [MoneyEventType.MONEY_IN, MoneyEventType.MONEY_OUT];
  const eventTypesData = eventTypes.map(event => ({
    label: event,
    value: event,
  }));

  return (
    <Modal
      title={translate('backOfficeSettings.createReason')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
    >
      <FormInput
        title={translate('form.manageCash.name')}
        placeholder={translate('form.manageCash.namePlaceholder')}
        value={form.name}
        containerStyle={css(formInputStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(null, 'name')}
        testID={'createMoneyMovementReason.name'}
      />
      <DropDown
        title={translate('form.manageCash.event')}
        selectedValue={form.eventType}
        containerStyle={css(dropdownStyle)}
        options={eventTypesData}
        onValueChange={onChange.bind(null, 'eventType')}
        itemsContainerStyle={css(itemContainer)}
        extraPopoverStyle={css(extraPopoverStyle)}
      />

      <FormInput
        title={translate('form.manageCash.notes')}
        placeholder={translate('form.manageCash.notesPlaceholder')}
        value={form.notes}
        containerStyle={css(formInputStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={onChange.bind(null, 'notes')}
        testID={'createMoneyMovementReason.notes'}
      />

      <Button
        title={translate('button.createReason')}
        containerStyle={css(buttonContainerStyle)}
        labelStyle={css(createLabelStyle)}
        color={theme.colors.green}
        onPress={onCreateMoneyMovementReason}
      />
    </Modal>
  );
};
