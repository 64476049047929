import { useModal } from '@hitz-group/rn-use-modal';
import React, { useCallback } from 'react';
import { View, Text, KeyboardAvoidingView, Platform } from 'react-native';
import Button from '../../Button/Button';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';

const actionContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: 30,
  marginBottom: Platform.OS === 'ios' ? 10 : 0,
});

const buttonContainerStyle: StyleFn = () => ({
  marginLeft: 10,
});

const titleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.semibold,
  textTransform: 'capitalize',
  letterSpacing: -0.4,
  color: theme.colors.orange,
  marginTop: 20,
});

const headingContainer: StyleFn = () => ({});

const descriptionStyle: StyleFn = ({ theme }) => ({
  marginTop: 20,
  fontFamily: theme.font.medium,
  lineHeight: 21,
  letterSpacing: -0.5,
  color: theme.colors.textLight,
});

const modalStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.medium,
  elevation: 5,
  borderColor: theme.colors.boxBorder,
  shadowColor: theme.colors.charcoal,
  shadowOpacity: 0.3,
  shadowRadius: 8,
  backgroundColor: theme.colors.white,
  width: 550,
  alignSelf: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: 30,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.bold,
  lineHeight: 21,
  letterSpacing: -0.4,
});

const confirmButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
});

const dismissButtonStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.orange,
});

export interface UnFiredItemsWarningModalProps {
  onConfirm: () => void;
  onCancel?: () => void;
}

const UnFiredItemsWarningModal: React.FC<UnFiredItemsWarningModalProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();

  const { translate } = useTranslation();
  const onPressCancel = useCallback(() => {
    closeModal();
    onCancel && onCancel();
  }, [closeModal, onCancel]);

  const onPressConfirm = useCallback(() => {
    closeModal();
    onConfirm();
  }, [closeModal, onConfirm]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={css(modalStyle)}
    >
      <View style={css(headingContainer)}>
        <Text style={css(titleStyle)}>{translate('payment.unsentItems')}</Text>
        <Text style={css(descriptionStyle)}>
          {translate('payment.unsentItemsDescription')}
        </Text>
      </View>
      <View style={css(actionContainerStyle)}>
        <Button
          size="small"
          testID="dismiss-button"
          title={translate('payment.doNotFire')}
          onPress={onPressCancel}
          labelStyle={css(buttonStyle, dismissButtonStyle)}
          containerStyle={css(buttonContainerStyle)}
          color={theme.colors.orangeLighter}
        />
        <Button
          size="small"
          testID="confirm-button"
          title={translate('payment.fireAndPay')}
          onPress={onPressConfirm}
          labelStyle={css(buttonStyle, confirmButtonStyle)}
          color={theme.colors.orange}
          containerStyle={css(buttonContainerStyle)}
        />
      </View>
    </KeyboardAvoidingView>
  );
};

export default UnFiredItemsWarningModal;
