import {
  StyleFn,
  CreateAndUpdateRewardRule,
  RewardType,
  DiscountType,
  Venue,
  DEFAULT_ENTITY_ID,
  LoyaltySettings,
} from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import FormInput from '../../../../components/FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../components/Button/Button';
import { useNotification } from '../../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import InputWithUnit from '../../../../components/FormInput/FormInputWithDropdown';

import Modal from '../../../../components/Modals/Modal';
import { isAndroid, isWeb } from '../../../../common/theme';
import DropDown from '../../../../components/DropDown/DropDown';
import { View } from 'react-native';
import setKeyValue from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { formatToDigitsStr } from '@hitz-group/client-utils';
import MultipleSelect from '../../../../components/MultipleSelect/MultipleSelect';
import { NumberSchema, ObjectSchema } from 'yup';
import { getCurrencySymbol } from '@hitz-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import { countries } from 'countries-list';

const modalContainerStyle: StyleFn = ({}) => ({
  width: 340,
  padding: 30,
  borderRadius: 10,
});

const headerTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const buttonsContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  height: 44,
  marginTop: theme.spacing.medium,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  height: 44,
  borderRadius: theme.radius.small,
  marginLeft: 10,
  marginTop: theme.spacing.medium,
  paddingHorizontal: 10,
});

const formInputTextStyle: StyleFn = ({}) => ({
  alignSelf: 'flex-start',
  paddingLeft: 10,
});

const earningPointContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 290,
});

const dropdownContainerStyleProductType: StyleFn = () => ({
  width: 290,
  height: 60,
});

const itemContainer: StyleFn = () => ({
  height: 38 * 3,
});

const touchableDropDownStyle: StyleFn = () => ({
  marginTop: isWeb ? 3 : 0,
});

const extraPopoverStyleProductType: StyleFn = () => ({
  width: 290,
});

const textStyle: StyleFn = () => ({
  marginTop: isAndroid ? -6 : 0,
});

const selectedItemStyle: StyleFn = () => ({
  marginVertical: 5,
});

const venuesContainerStyle: StyleFn = ({ theme }) => ({
  width: 290,
  height: 'auto',
  marginTop: isAndroid ? theme.padding.small + 2 : 0,
});

const prefixItemStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  backgroundColor: theme.colors.greyLight,
  padding: theme.spacing.small / 2 + 5,
  marginRight: theme.spacing.small / 2,
  fontFamily: theme.font.bold,
  borderRadius: theme.radius.small,
  lineHeight: 15,
});

const amountSpendContainerStyle: StyleFn = ({}) => ({
  width: 290,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  marginBottom: 8,
  marginLeft: 10,
});

const touchableStyle: StyleFn = ({ theme }) => ({
  minHeight: theme.input.height,
  flexWrap: 'wrap',
});

const amountSpendWrapper: StyleFn = ({}) => ({
  marginTop: 10,
});

const titleStyle: StyleFn = ({}) => ({
  marginLeft: 10,
});

type RewardRuleState = {
  id?: string;
  venueIds: string[];
  rewardType: RewardType;
  rewardName: string;
  pointsRequired: string;
  discountAmount: string;
  discountType: DiscountType;
  maximumDiscountAmount?: string;
};
interface CreateAndEditRewardRuleModalProps {
  onCreateAndUpdate: (rewardRule: CreateAndUpdateRewardRule) => void;
  editingRewardRule?: RewardRuleState;
  allVenues: Venue[];
  loyalSettings: Partial<LoyaltySettings>;
}

const defaultRewardRule: RewardRuleState = {
  rewardType: RewardType.DISCOUNT_ENTIRE_SALE,
  rewardName: '',
  pointsRequired: '',
  discountAmount: '',
  discountType: DiscountType.PERCENTAGE,
  venueIds: [],
  maximumDiscountAmount: '',
};

const discountEntireSaleSchema = new ObjectSchema({
  pointsRequired: new NumberSchema()
    .transform((value, originalValue) => {
      if (!originalValue) return 0;
      return value;
    })
    .positive('backOfficeLoyalty.invalidRewardValue')
    .required(),

  discountAmount: new NumberSchema()
    .transform((value, originalValue) => {
      if (!originalValue) return 0;
      return value;
    })
    .positive('backOfficeLoyalty.invalidDiscountAmount')
    .required(),
});

const CreateAndEditRewardRuleModal: React.FC<
  CreateAndEditRewardRuleModalProps
> = ({ onCreateAndUpdate, editingRewardRule, allVenues, loyalSettings }) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const isEditMode = editingRewardRule !== undefined;

  const [rewardRuleData, setRewardRuleData] = useState(
    editingRewardRule || defaultRewardRule,
  );

  const onChangeItem = useCallback((path: string, value) => {
    setRewardRuleData(existingRuleData => {
      const newData = cloneDeep(existingRuleData);
      setKeyValue(newData, path, value);
      return newData;
    });
  }, []);

  const [session] = useSession();
  const { country = 'AU' } = session?.currentOrganization || {};
  const currencyCode = countries[country]?.currency;
  const onCreateAndUpdateRewardRule = useCallback(
    (rewardData: RewardRuleState) => {
      try {
        const updatedData = discountEntireSaleSchema.validateSync(
          rewardData,
        ) as CreateAndUpdateRewardRule;

        updatedData.maximumDiscountAmount = parseFloat(
          rewardData.maximumDiscountAmount || '0',
        );

        onCreateAndUpdate(updatedData);
      } catch (error) {
        showNotification({
          message: translate((error as { message: string })?.message),
          error: true,
        });
      }
    },
    [onCreateAndUpdate, showNotification, translate],
  );

  const onChangeVenues = useCallback(
    (selectedVenues: string[]) => {
      if (selectedVenues.includes(DEFAULT_ENTITY_ID)) {
        const allVenueIds = allVenues.map(venue => venue.id);
        onChangeItem('venueIds', allVenueIds);
      } else {
        onChangeItem('venueIds', selectedVenues);
      }
    },
    [onChangeItem, allVenues],
  );
  const selectedVenues =
    rewardRuleData.venueIds.length == allVenues.length
      ? [DEFAULT_ENTITY_ID]
      : [...(rewardRuleData?.venueIds || [])];

  return (
    <Modal
      title={isEditMode ? 'Edit Reward' : 'Create Reward'}
      contentStyle={css(modalContainerStyle)}
      headerTextStyle={css(headerTextStyle)}
      showCloseButton={false}
    >
      <DropDown
        title={translate('backOfficeLoyalty.rewardType')}
        options={[
          {
            label: translate('backOfficeLoyalty.discountEntireSale'),
            value: RewardType.DISCOUNT_ENTIRE_SALE,
          },
        ]}
        selectedValue={rewardRuleData.rewardType}
        containerStyle={css(dropdownContainerStyleProductType)}
        itemsContainerStyle={css(itemContainer)}
        onValueChange={onChangeItem.bind(null, 'rewardType')}
        touchableStyle={css(touchableDropDownStyle)}
        extraPopoverStyle={css(extraPopoverStyleProductType)}
        titleStyle={css(titleStyle)}
      />
      <View style={css(amountSpendWrapper)}>
        <FormInput
          title={translate('backOfficeLoyalty.rewardValue')}
          testID="reward-value"
          value={rewardRuleData.pointsRequired.toString()}
          containerStyle={css(amountSpendContainerStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChangeItem.bind(null, 'pointsRequired')}
          type={'number'}
          maxLength={8}
          prefix={{
            text: loyalSettings.pluralTerm || 'Points',
            textStyle: css(prefixItemStyle),
          }}
        />
      </View>

      <View style={css(amountSpendWrapper)}>
        <InputWithUnit
          inputValue={rewardRuleData.discountAmount.toString()}
          dropdownValue={rewardRuleData.discountType}
          onDropdownChange={value => onChangeItem('discountType', value)}
          onInputChange={value =>
            onChangeItem('discountAmount', formatToDigitsStr(value, true))
          }
          dropdownOptions={[
            { value: DiscountType.PERCENTAGE, label: '%' },
            {
              value: DiscountType.FLAT,
              label: getCurrencySymbol(currencyCode),
            },
          ]}
          dropdownWidth={53}
          testID={'discount-amount'}
          reverseRow
          title={translate('backOfficeLoyalty.discountAmount')}
        />
      </View>
      {rewardRuleData.discountType === DiscountType.PERCENTAGE && (
        <View style={css(amountSpendWrapper)}>
          <FormInput
            title={translate('backOfficeLoyalty.maximumDiscount')}
            testID="maximum-discount"
            value={rewardRuleData.maximumDiscountAmount?.toString()}
            containerStyle={css(amountSpendContainerStyle)}
            textStyle={css(formTextStyle)}
            onChangeText={onChangeItem.bind(null, 'maximumDiscountAmount')}
            type={'number'}
            maxLength={8}
            prefix={{
              text: getCurrencySymbol(currencyCode),
              textStyle: css(prefixItemStyle),
            }}
          />
        </View>
      )}
      <View>
        <FormInput
          title={translate('backOfficeLoyalty.rewardName')}
          value={rewardRuleData.rewardName}
          alignTitle={'left'}
          containerStyle={css(earningPointContainerStyle)}
          textStyle={css(formInputTextStyle)}
          onChangeText={onChangeItem.bind(null, 'rewardName')}
          maxLength={50}
          testID="reward-name"
        />
      </View>

      <MultipleSelect
        label={translate('backOfficeLoyalty.venues')}
        values={[
          {
            label: translate('backOfficeLoyalty.allVenues'),
            value: DEFAULT_ENTITY_ID,
          },
        ].concat(
          allVenues.map(venue => ({
            label: venue.name,
            value: venue.id,
          })),
        )}
        textStyle={css(textStyle)}
        selectedValues={selectedVenues}
        selectedItemStyle={css(selectedItemStyle)}
        showCheckboxes={false}
        containerStyle={css(venuesContainerStyle)}
        onValueChange={onChangeVenues}
        touchableStyle={css(touchableStyle)}
        testID="venue-selection"
      />
      <View style={css(buttonsContainerStyle)}>
        <Button
          title={translate('form.dismiss')}
          transparent
          containerStyle={css(buttonStyle)}
          color={theme.colors.green}
          onPress={closeModal}
          testID="dismiss"
        />
        <Button
          secondary
          title={translate('button.update')}
          containerStyle={css(buttonStyle)}
          color={theme.colors.green}
          onPress={() => onCreateAndUpdateRewardRule(rewardRuleData)}
          testID="update-reward-rule"
        />
      </View>
    </Modal>
  );
};

export default CreateAndEditRewardRuleModal;
