import {
  StyleFn,
  User,
  UpdateUserRequest,
  DEFAULT_USER_LANGUAGE,
} from '@hitz-group/domain';
import {
  isValidEmail,
  isValidNumber,
  isValidPinNumber,
} from '../../../../../utils/validator';
import scale from '../../../../../common/theme';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../../components/FormInput/FormInput';
import {
  useTranslation,
  getAvailableLanguages,
  getCountry,
  getCountryCodeByPhoneCode,
} from '@hitz-group/localization';
import Button from '../../../../../components/Button/Button';
import { useNotification } from '../../../../../hooks/Notification';
import DropDown from '../../../../../components/FormInput/DropDown';
import { useRoute } from '@react-navigation/native';
import { useUsers } from '../../../../../hooks/app/useUsers';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import { Operation } from '../../../../../types/Operation';
import { useNavigation } from '@react-navigation/native';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { useModal } from '@hitz-group/rn-use-modal';
import { getDiffValues } from '@hitz-group/client-utils';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import { userUtility } from '../../../../../state/userUtility';
import KeyboardAwareScrollView from '../../../../../components/KeyboardAwareScrollView/KeyboardAwareScrollView';

const phonePrefixStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  backgroundColor: theme.colors.white,
  minWidth: scale.moderateScale(25),
  textAlign: 'left',
  paddingLeft: scale.moderateScale(5),
  fontFamily: theme.font.regular,
  fontSize: scale.moderateScale(8),
});

const phonePrefixErrorBgStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger2,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'space-between',
  width: 260,
  height: theme.input.height,
});
const formDropDownContainerStyle: StyleFn = ({ theme }) => ({
  width: 260,
  height: theme.input.height,
  marginBottom: 'auto',
  marginTop: theme.padding.small,
});

const formContainerStyle: StyleFn = ({ theme }) => ({
  width: 540,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  paddingBottom: theme.spacing.big,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const pinView: StyleFn = () => ({
  width: 270,
});
const inputWrapper: StyleFn = () => ({
  width: 540,
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  alignItems: 'center',
  paddingVertical: theme.spacing.big,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
  ...theme.shadow15,
  paddingLeft: theme.padding.large,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const deleteButtonStyle: StyleFn = ({ theme, disabled }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginRight: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  alignSelf: 'auto',
  ...(disabled && { opacity: 0.5 }),
});

const dangerTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
  letterSpacing: -0.5,
});

const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14SemiBold,
  color: theme.colors.paragraph,
  paddingVertical: theme.spacing.small,
  textAlign: 'left',
});

export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: 260,
  height: theme.input.height,
});

export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: 260,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});

export const whiteSpace: StyleFn = () => ({
  height: 250,
  width: '100%',
});
export const scrollViewStyle: StyleFn = () => ({
  flex: 1,
});

type UserWithPhoneCountryCode = User & { phoneCountryCode: string };

export const General: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const route = useRoute();
  const { showModal, closeModal } = useModal();
  const navigation = useNavigation();
  const [userData, setUserData] = useState<UserWithPhoneCountryCode>(
    {} as UserWithPhoneCountryCode,
  );
  const params = route.params as {
    userId: string;
  };

  const userId = params.userId || '';
  const { css } = useFela({ disabled: userData?.isOwner });

  const {
    users,
    loading,
    error,
    updateUser,
    operation,
    deleteUser,
    deletedUserId,
  } = useUsers();

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error as string,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (userId && users[userId] && !error && !loading) {
      const user = users[userId];
      // mobile no from API is something like this +61 1111333222
      const [_phoneCode, _phoneNumber] = user.phone.split(' ');
      setUserData({
        ...user,
        phoneCountryCode: getCountryCodeByPhoneCode(_phoneCode) || '',
        phone: _phoneNumber || '',
        pin: '',
      } as UserWithPhoneCountryCode);
    }
  }, [userId, users, error, loading]);

  useEffect(() => {
    if (!error && !loading && operation === Operation.UPDATE) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullySaved'),
      });
    }
  }, [error, loading, operation, showNotification, translate]);

  const onChange = useCallback((prop, value): void => {
    setUserData(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const getFormattedPhone = () => {
    if (userData.phoneCountryCode) {
      return `${getCountry(userData.phoneCountryCode).phone} ${userData.phone}`;
    }
    return userData.phone;
  };

  const formValidationStatus: Record<string, boolean> = {
    email: isValidEmail(userData.email),
    pin: userData.pin ? isValidPinNumber(userData.pin) : true,
    phone: userData.phone ? isValidNumber(getFormattedPhone()) : false,
  };

  const onPressSave = async (): Promise<void> => {
    if (
      Object.keys(formValidationStatus).some(
        _key => formValidationStatus[_key] === false,
      )
    ) {
      showNotification({
        error: true,
        message: 'Please check the values provided',
      });
    } else {
      const updatedValues = getDiffValues<
        Partial<User>,
        'preferredLanguage' | 'email' | 'firstName' | 'lastName' | 'phone'
      >(
        ['email', 'firstName', 'lastName', 'preferredLanguage', 'phone'],
        users[userId],
        { ...userData, phone: getFormattedPhone() },
        { preferredLanguage: DEFAULT_USER_LANGUAGE },
      );
      if (Object.keys(updatedValues).length > 0 || userData.pin.length > 0) {
        updateUser(
          stripProperties(
            {
              ...updatedValues,
              id: userData.id,
              ...(userData.pin.length > 0 && { pin: userData.pin }),
            },
            '__typename',
          ) as UpdateUserRequest,
        );
        userUtility.updateOfficeUserAttr(userData.id, {
          ...(userData.name && { name: userData.name }),
          email: userData.email,
        });
      }
    }
  };

  useEffect(() => {
    if (!error && !loading && operation === Operation.DELETE && deletedUserId) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deletedSuccessfully'),
      });
      closeModal();
      navigation.navigate('ManageUsersTab');
    }
  }, [
    deletedUserId,
    error,
    loading,
    operation,
    showNotification,
    translate,
    navigation,
    closeModal,
  ]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('backOfficeUsers.deleteUserMessage')}
        onConfirm={() => userId && deleteUser(userId)}
      />,
    );
  }, [userId, deleteUser, translate, showModal]);

  const formInputContainerStyleCss = css(formInputContainerStyle);

  const localeOptions = useMemo(
    () =>
      getAvailableLanguages().map(locale => ({
        value: locale.languageTag,
        label: locale.label,
      })),
    [],
  );

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <KeyboardAwareScrollView
          testID="general-settings-screen"
          contentContainerStyle={css(generalSettingsContainerWrapper)}
          style={css(scrollViewStyle)}
        >
          <View style={css(pageStyle)}>
            <BackOfficeSection
              title={translate('backOfficeUsers.userDetails')}
              contentContainerStyle={css(formContainerStyle)}
              titleBorderBottom
            >
              <FormInput
                error={false}
                placeholder={translate('form.firstName')}
                title={translate('form.firstName')}
                value={userData.firstName || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                onChangeText={onChange.bind(null, 'firstName')}
              />
              <FormInput
                error={false}
                testID="lastName"
                placeholder={translate('form.lastName')}
                title={translate('form.lastName')}
                value={userData.lastName || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                onChangeText={onChange.bind(null, 'lastName')}
              />
              {/* TODO: this phone number input can be moved to its own component */}
              <FormInput
                testID="phone-number"
                placeholder={translate('form.phone')}
                title={translate('form.phone')}
                value={userData.phone || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                onChangeText={onChange.bind(null, 'phone')}
                type="phone"
                maxLength={10}
                verified={formValidationStatus.phone}
                error={!formValidationStatus.phone}
                country={userData.phoneCountryCode}
                onSelectChange={(country): void =>
                  onChange('phoneCountryCode', country.toString())
                }
                onPressCountry={(country): void =>
                  onChange('phoneCountryCode', country.toString())
                }
                prefix={{
                  text: getCountry(userData.phoneCountryCode)?.phone,
                  textStyle: !formValidationStatus.phone
                    ? css(phonePrefixStyle, phonePrefixErrorBgStyle)
                    : css(phonePrefixStyle),
                }}
              />
              <FormInput
                verified={formValidationStatus.email}
                error={!formValidationStatus.email}
                placeholder={translate('form.email')}
                title={translate('form.email')}
                value={userData.email || ''}
                alignTitle="left"
                containerStyle={formInputContainerStyleCss}
                onChangeText={onChange.bind(null, 'email')}
              />
              <View style={css(inputWrapper)}>
                <View style={css(pinView)}>
                  <FormInput
                    verified={formValidationStatus.pin}
                    error={!formValidationStatus.pin}
                    placeholder={translate('form.pin')}
                    title={translate('form.pin')}
                    value={userData.pin || ''}
                    alignTitle="left"
                    testID="userPin"
                    type={'password'}
                    containerStyle={formInputContainerStyleCss}
                    onChangeText={onChange.bind(null, 'pin')}
                    infoText={translate('backOfficeUsers.pinDigitLength')}
                    infoTextStyle={css(textStyle)}
                  />
                </View>
                <DropDown
                  title={translate('form.preferredLanguage')}
                  values={localeOptions}
                  selectedValue={userData.preferredLanguage}
                  style={css(formDropDownContainerStyle)}
                  extraStyle={css(dropdownExtraStyle)}
                  extraMainViewStyle={css(dropDownMainViewStyle)}
                  onValueChange={onChange.bind(null, 'preferredLanguage')}
                />
              </View>
            </BackOfficeSection>
          </View>
          <View style={css(whiteSpace)} />
        </KeyboardAwareScrollView>

        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="delete-changes"
            title={translate('backOfficeUsers.deleteUser')}
            containerStyle={css(deleteButtonStyle)}
            labelStyle={css(dangerTitleStyle)}
            onPress={onPressDelete}
            disabled={userData.isOwner}
          />
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
