import { StyleFn, Icons, AlternateName, LOCALE } from '@hitz-group/domain';
import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useFela } from 'react-fela';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../../components/FormInput/FormInput';
import Accordion from 'react-native-collapsible/Accordion';
import Icon from '../../../../../components/Icon/Icon';
import { useTranslation } from '@hitz-group/localization';
import scale from '../../../../../common/theme';

export const rowContainer: StyleFn = () => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: scale.moderateScale(10),
});

const closelySpacedFormInputContainerStyle: StyleFn = ({ theme, width }) => ({
  width: width,
  height: theme.input.height,
});

const header: StyleFn = ({ theme }) => ({
  width: 545,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingVertical: 10,
  backgroundColor: theme.colors.lightBlueHeader,
});

const headerText: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  paddingLeft: theme.spacing.small + theme.spacing.small / 2,
  textAlign: 'left',
});

const headerIcon: StyleFn = () => ({
  textAlign: 'right',
  paddingLeft: 10,
});

const formInputContainerStyle: StyleFn = ({ theme, width }) => ({
  width: width,
  height: theme.input.height,
  marginBottom: theme.spacing.small * 1.5,
});

const formTextInputStyle: StyleFn = ({ theme, width }) => ({
  width: width,
  height: theme.input.height,
  paddingLeft: theme.padding.medium * 1.5,
});

const backOfficeContainerStyle: StyleFn = ({ theme }) => ({
  paddingBottom: theme.padding.large,
  width: 545,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: theme.spacing.medium,
});

const formLabelStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
  paddingHorizontal: 10,
  paddingVertical: 0,
  lineHeight: 21,
  paddingBottom: theme.padding.medium,
  letterSpacing: -0.5,
});

interface LocaleTitle {
  locale: LOCALE;
  title: string;
}

const titles = [
  { locale: LOCALE.HINDI, title: 'हिंदी' },
  { locale: LOCALE.SPANISH_SPAIN, title: 'Español' },
  { locale: LOCALE.DUTCH, title: 'Nederlands' },
  { locale: LOCALE.CHINESE, title: '中国人' },
] as LocaleTitle[];

interface AlternateNamesSectionProps {
  alternateNames: Array<AlternateName>;
  onChangeAlternateName: (prop: string, value: string, locale: LOCALE) => void;
}

export const AlternateNamesSection: React.FC<AlternateNamesSectionProps> = ({
  alternateNames,
  onChangeAlternateName,
}: AlternateNamesSectionProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();

  const [activeSections, setActiveSections] = useState<number[]>(
    [] as number[],
  );

  const updateSections = (activeSections: number[]) => {
    setActiveSections(activeSections);
  };

  const renderHeader = (
    section: LocaleTitle,
    index: number,
    isActive: boolean,
  ) => {
    return (
      <View style={css(header)}>
        <Text style={css(headerText)}>{section.title}</Text>
        <Icon
          name={isActive ? Icons.AngleUp : Icons.AngleDown}
          color={theme.colors.charcoal}
          style={css(headerIcon)}
        />
      </View>
    );
  };

  const renderContent = (section: LocaleTitle) => {
    const alternateName = alternateNames?.find(
      altName => altName.locale === section.locale,
    );
    return (
      <View>
        <View style={css(rowContainer)}>
          <FormInput
            error={false}
            placeholder={translate('productSettings.displayName')}
            title={translate('productSettings.displayName')}
            value={alternateName?.display || ''}
            alignTitle="left"
            containerStyle={css(
              closelySpacedFormInputContainerStyle({ theme, width: 260 }),
            )}
            onChangeText={value =>
              onChangeAlternateName('display', value, section.locale)
            }
          />
          <FormInput
            error={false}
            placeholder={translate('productSettings.posName')}
            title={translate('productSettings.posName')}
            value={alternateName?.receipt || ''}
            alignTitle="left"
            containerStyle={css(
              closelySpacedFormInputContainerStyle({ theme, width: 260 }),
            )}
            onChangeText={value =>
              onChangeAlternateName('receipt', value, section.locale)
            }
          />
        </View>
        <View style={css(rowContainer)}>
          <FormInput
            error={false}
            placeholder={translate('productSettings.kitchenName')}
            title={translate('productSettings.kitchenName')}
            value={alternateName?.kitchen || ''}
            alignTitle="left"
            containerStyle={css(formInputContainerStyle({ theme, width: 545 }))}
            textInputStyle={css(formTextInputStyle({ theme, width: 545 }))}
            onChangeText={value =>
              onChangeAlternateName('kitchen', value, section.locale)
            }
          />
        </View>
        <View style={css(rowContainer)}>
          <FormInput
            error={false}
            placeholder={translate('productSettings.addDescription') + '...'}
            title={translate('productSettings.description')}
            value={alternateName?.description || ''}
            alignTitle="left"
            maxLength={300}
            containerStyle={css(formInputContainerStyle({ theme, width: 545 }))}
            textInputStyle={css(formTextInputStyle({ theme, width: 545 }))}
            textStyle={css(formLabelStyle)}
            onChangeText={value =>
              onChangeAlternateName('description', value, section.locale)
            }
          />
        </View>
      </View>
    );
  };

  return (
    <BackOfficeSection
      title={translate('backOfficeSettings.translations')}
      contentContainerStyle={css(backOfficeContainerStyle)}
    >
      <Accordion
        sections={titles}
        activeSections={activeSections}
        renderHeader={renderHeader}
        renderContent={renderContent}
        onChange={updateSections}
        touchableComponent={TouchableOpacity}
        expandMultiple={true}
      />
    </BackOfficeSection>
  );
};
