import { StyleFn, IntegrationApps, Feature } from '@hitz-group/domain';
import React, { useCallback, useEffect } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { View, ScrollView, Text, Image, Linking } from 'react-native';
import Button from '../../../../../../components/Button/Button';
import { ImageCarousel } from '../../../../../../components/ImageCarousel/ImageCarousel';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import { useFeatures } from '../../../../../../hooks/app/features/useFeatures';
import { useWorkforceIntegration } from '../../../../../../hooks/app/workforceIntegrations/useWorkforceIntegration';
import { useNotification } from '../../../../../../hooks/Notification';
import { useIntegrationPartners } from '../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { useIsFocused, useNavigation } from '@react-navigation/native';

const connectTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
  letterSpacing: -0.4,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  textAlign: 'center',
  alignItems: 'center',
  width: 680,
});

const detailsContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big + theme.spacing.small,
  width: 680,
  borderRadius: theme.radius.small,
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.boxBorder,
  borderTopWidth: 1,
  borderTopColor: theme.colors.boxBorder,
});

const detailsTileStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big + theme.spacing.small,
});

const aboutContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  paddingVertical: theme.spacing.big,
  paddingLeft: theme.spacing.big,
  alignItems: 'center',
});

const ctaTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
  marginTop: theme.spacing.medium,
  lineHeight: theme.spacing.medium,
  color: theme.colors.primary,
  fontFamily: theme.font.semibold,
});

const detailsTitleStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  marginTop: theme.spacing.medium,
  lineHeight: theme.spacing.medium,
  color: theme.colors.primary,
  fontFamily: theme.font.semibold,
});

const ctaSubTitle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  lineHeight: theme.spacing.medium,
  marginTop: theme.spacing.small,
  letterSpacing: 1,
});

const connectButtonStyle: StyleFn = ({ theme }) => ({
  width: 120,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.greenLighter,
  alignSelf: 'center',
  marginTop: theme.spacing.medium,
});

const disconnectButtonStyle: StyleFn = ({ theme }) => ({
  width: 120,
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  alignSelf: 'center',
  marginTop: theme.spacing.medium,
});

const disconnectTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
  letterSpacing: -0.4,
});

const imageContainerStyle: StyleFn = ({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: theme.radius.medium,
  marginTop: theme.spacing.big,
});

export const AboutTab: React.FC = () => {
  const { css } = useFela();
  const isFocused = useIsFocused();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const { currentFeature, loadings: featureLoading } = useFeatures(
    IntegrationApps.DEPUTY,
  );

  const {
    workforcePartnerConsentUrl,
    getWorkforcePartnerConsentUrl,
    loading: workforceIntegrationLoading,
    integrationData,
    saveIntegrationDetails,
    toggleWorkforceIntegrationConnection,
  } = useWorkforceIntegration();

  const {
    loading: deputyLoading,
    getIntegrationPartnerSettings,
    integrationPartners,
  } = useIntegrationPartners();

  useEffect(() => {
    if (isFocused)
      getIntegrationPartnerSettings({ appName: IntegrationApps.DEPUTY });
  }, [getIntegrationPartnerSettings, isFocused]);

  useEffect(() => {
    async function redirectToDeputy() {
      if (workforcePartnerConsentUrl) {
        // Checking if the link is supported for links with custom URL scheme.
        const supported = await Linking.canOpenURL(workforcePartnerConsentUrl);

        if (supported) {
          // Opening the link with some app, if the URL scheme is "http" the web link should be opened
          // by some browser in the mobile
          await Linking.openURL(workforcePartnerConsentUrl);
        } else {
          showNotification({
            message: translate('backOfficeFeatures.invalidUrl'),
            error: true,
          });
        }
      }
    }
    redirectToDeputy();
  }, [workforcePartnerConsentUrl, showNotification, translate]);

  useEffect(() => {
    async function getURL() {
      const url = await Linking.getInitialURL();
      const isValidOAuthCallBackURL =
        !url?.includes('error=access_denied') && url?.includes('code');

      if (url && isValidOAuthCallBackURL) {
        saveIntegrationDetails(url);
        setTimeout(() => {
          navigation.navigate('DeputySettings', { screen: 'SettingsTab' });
        }, 5000);
      }
    }
    getURL();
  }, [navigation, saveIntegrationDetails]);

  const integrationParnterData = Object.values(integrationPartners)[0];

  const toggleAccountingPartnerConnection = useCallback(() => {
    if ((integrationData || integrationParnterData)?.isActive) {
      toggleWorkforceIntegrationConnection(IntegrationApps.DEPUTY);
    } else {
      getWorkforcePartnerConsentUrl(IntegrationApps.DEPUTY);
    }
  }, [
    getWorkforcePartnerConsentUrl,
    integrationData,
    integrationParnterData,
    toggleWorkforceIntegrationConnection,
  ]);

  if (featureLoading || workforceIntegrationLoading || deputyLoading) {
    return <LoadingIndicator />;
  }

  const featureData = currentFeature?.feature as Feature;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.integrationsPage', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView
        testID="deputy-about-screen"
        contentContainerStyle={css(aboutContainerStyle)}
      >
        <View style={css(pageStyle)}>
          <Image
            style={css(imageContainerStyle)}
            source={{ uri: featureData?.icon }}
          />
          <Text style={css(ctaTitle)}>{featureData?.name}</Text>
          <Text style={css(ctaSubTitle)}>{featureData?.about}</Text>
          <Button
            fluid
            testID="connect-deputy"
            title={translate(
              (integrationData || integrationParnterData)?.isActive
                ? 'backOfficeFeatures.disconnect'
                : 'backOfficeFeatures.connect',
            )}
            containerStyle={css(
              (integrationData || integrationParnterData)?.isActive
                ? disconnectButtonStyle
                : connectButtonStyle,
            )}
            labelStyle={css(
              (integrationData || integrationParnterData)?.isActive
                ? disconnectTitleStyle
                : connectTitleStyle,
            )}
            disabled={featureLoading}
            onPress={toggleAccountingPartnerConnection}
          />
        </View>

        <View style={css(detailsContainerStyle)}>
          {featureData?.subFeatureList.map(eachList => (
            <View style={css(detailsTileStyle)} key={eachList.name}>
              <Text style={css(detailsTitleStyle)}>{eachList.name}</Text>
              <Text style={css(ctaSubTitle)}>{eachList?.description}</Text>
            </View>
          ))}

          <View style={css(detailsTileStyle)}>
            <Text style={css(detailsTitleStyle)}>
              {translate('backOfficeFeatures.screenshots')}
            </Text>
            <Text style={css(ctaSubTitle)}></Text>
            {featureData?.screenshots?.length && (
              <ImageCarousel imageUrls={featureData?.screenshots} />
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};
