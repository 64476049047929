import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { Helmet } from 'react-helmet';
import { Styles } from '../styles/ProductsSummary.styles';
import { View, ScrollView } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import {
  DateRangeFilter,
  Filters,
  FilterValue,
  Widget,
  WidgetChartType,
} from '@hitz-group/domain';
import { ReportHeader } from '../UIComponents/ReportHeader';
import { Table, TableRef } from '../UIComponents/Table';
import { Filters as ReportFilter } from '../UIComponents/Filters';
import {
  LineChart,
  LineChartData,
  LineChartRow,
  PivotTableData,
} from '../UIComponents/LineChart';
import {
  getHourRangeIn12HrsFormat,
  GRANULARITY_FORMATS,
  transformFieldsToTableColumn,
} from '../reportsHelper';
import {
  LineChartType,
  ReportTableColumn,
  IMap,
  ExportType,
  HelperText,
  Search,
  DropDownFilter,
} from '../types';

import { getHours } from 'date-fns';
import keyBy from 'lodash/keyBy';
import { CubejsApi } from '@cubejs-client/core';

interface ReportProps {
  options: { loading: boolean };
  filterOptions: DropDownFilter[];
  filters: FilterValue;
  search: Search;
  widgets: Widget[];
  dateRangeFilter: DateRangeFilter;
  updateFilters: (filter: string, value: string[]) => void;
  updateSearch: (filter: string, value: string) => void;
  resetFilters: () => void;
  updateReport: () => void;
  updateDateRangeFilters: (value: DateRangeFilter) => void;
  cubejsApi: CubejsApi;
  allFilters: Filters;
  updateCount: number;
}

const TABLE_GRANULARITY_FORMATS: IMap<string> = {
  ...GRANULARITY_FORMATS,
  day: 'MMM dd, yyyy',
};

const DeviceSummaryReport: React.FC<ReportProps> = ({
  options: { loading },
  filters,
  filterOptions,
  widgets,
  dateRangeFilter,
  search,
  updateFilters,
  updateSearch,
  updateReport,
  resetFilters,
  updateDateRangeFilters,
  cubejsApi,
  allFilters,
  updateCount,
}) => {
  const { translate } = useTranslation();
  const styles = Styles();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [selectedColumns, setSelectedColumns] = useState<ReportTableColumn[]>(
    [],
  );

  const toggleFilters = useCallback(() => {
    setShowFilters(value => !value);
  }, []);

  const tableWidget =
    widgets.find(widget => widget.chartType === WidgetChartType.TABLE) ||
    ({} as Widget);

  const tableColumns: ReportTableColumn[] = useMemo(() => {
    const columns = [];
    if (tableWidget.query?.dimensions)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.dimensions),
      );

    if (tableWidget.query?.measures)
      columns.push(
        ...transformFieldsToTableColumn(tableWidget.query?.measures),
      );
    return columns;
  }, [tableWidget.query]);

  const tableWidgetRef = useRef<TableRef>({} as TableRef);
  const exportReport = useCallback((type = ExportType.CSV) => {
    if (type === ExportType.CSV)
      tableWidgetRef.current.exportData &&
        tableWidgetRef.current.exportData(
          `DeviceSummaryReport-${Date.now()}.csv`,
        );
  }, []);

  const tableDataTransformationFn = useCallback(
    (
      pivotTableData: PivotTableData,
      columns: ReportTableColumn[],
    ): PivotTableData => {
      const allDevicesTotalSales =
        pivotTableData.reduce(
          (total, row) => total + +row['Orders.totalRevenue'],
          0,
        ) || 1;
      return pivotTableData.map(row => {
        return columns.reduce((acc, { key, title }) => {
          switch (title) {
            case 'backofficeDevicesSalesSummary.salesPercentage':
              return {
                ...acc,
                [key]: `${
                  (
                    (+row['Orders.totalRevenue'] / allDevicesTotalSales) *
                    100
                  ).toFixed(2) || 0
                }`,
              };

            default:
              return { ...acc, [key]: row[key] || '' };
          }
        }, {});
      }) as unknown as PivotTableData;
    },
    [],
  );

  const lineChartDataTransformationFn = useCallback(
    (serializedData: PivotTableData, widgetName: string): LineChartData[] => {
      const devicesMap = keyBy(serializedData, 'Orders.deviceId');
      const devices = Object.keys(devicesMap);

      const devicesWithDefaultHrs: Record<
        string,
        Array<LineChartRow>
      > = devices.reduce((acc, device) => {
        return {
          ...acc,
          [device]: Array.from({ length: 24 }).map((_, i) => ({
            x: i,
            y: 0,
          })),
        };
      }, {} as Record<string, LineChartRow[]>);

      let metricKey = '';

      switch (widgetName) {
        case 'Orders Per Hour':
          metricKey = 'Orders.salesCount';
          break;

        case 'Sales Per Hour':
          metricKey = 'Orders.totalRevenue';
          break;
      }

      if (metricKey !== '') {
        const metricsByDevice = serializedData.reduce((acc, row) => {
          const deviceId = row['Orders.deviceId'] as string;
          const createdHour = row['Orders.createdAt.hour'] as string;

          const dateHour = getHours(new Date(createdHour));

          return {
            ...acc,
            [deviceId]: {
              ...(acc[deviceId] || {}),
              [dateHour]: +row[metricKey] || 0,
            },
          };
        }, {} as Record<string, Record<string, number>>);

        return Object.keys(devicesWithDefaultHrs).map(device => {
          const currentDeviceMetrics = metricsByDevice[device];
          return devicesWithDefaultHrs[device].map(defaultHrData => {
            const { start, end } = getHourRangeIn12HrsFormat(
              defaultHrData.x as number,
            );
            return {
              x: `${start} - ${end}`,
              y: currentDeviceMetrics[defaultHrData.x + ''] || defaultHrData.y,
              text: devicesMap[device]['Orders.deviceName'] as string,
            };
          });
        }, {});
      } else {
        return [];
      }
    },
    [],
  );

  useEffect(() => {
    setSelectedColumns(
      (tableColumns || []).filter(column => column.showByDefault),
    );
  }, [tableColumns]);

  const updateColumns = useCallback(
    (columnKeys: string[]) => {
      setSelectedColumns(
        tableColumns.filter(col => columnKeys.includes(col.title)),
      );
    },
    [tableColumns],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView scrollEnabled={!showFilters} style={styles.pageStyle}>
        {showFilters ? (
          <View style={styles.filtersStyle}>
            <ReportFilter
              toggleFilters={toggleFilters}
              filterOptions={filterOptions}
              filters={filters}
              updateFilters={updateFilters}
              resetFilters={resetFilters}
            ></ReportFilter>
          </View>
        ) : null}
        <View style={styles.mainSectionStyle}>
          <View style={styles.headersStyle}>
            <ReportHeader
              allFilters={allFilters}
              toggleFilters={toggleFilters}
              updateReport={updateReport}
              exportReport={exportReport}
              filters={filters}
              search={search}
              updateSearch={updateSearch}
              columns={{ all: tableColumns, updateColumns, selectedColumns }}
              updateFilters={updateFilters}
              dateRangeFilter={dateRangeFilter}
              updateDateRangeFilters={updateDateRangeFilters}
              translationParentKey={'backofficeDevicesSalesSummary'}
            />
          </View>
          <View style={styles.chartRowStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <>
                {widgets
                  .filter(widget => widget.chartType === WidgetChartType.LINE)
                  .map(widget => {
                    const renderComp = (
                      <LineChart
                        widget={widget}
                        helper={HelperText.DEVICE_SUMMARY}
                        keys={[]}
                        type={LineChartType.BASIC}
                        dataTransformationFn={lineChartDataTransformationFn}
                        cubejsApi={cubejsApi}
                        updateCount={updateCount}
                      />
                    );
                    return renderComp;
                  })}
              </>
            )}
          </View>
          <View style={styles.tableStyle}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <Table
                widget={tableWidget}
                columns={selectedColumns}
                helper={HelperText.DEVICE_SUMMARY}
                pageSize={5}
                granularityFormats={TABLE_GRANULARITY_FORMATS}
                ref={tableWidgetRef}
                filters={filters}
                dataTransformationFn={tableDataTransformationFn}
                cubejsApi={cubejsApi}
                updateCount={updateCount}
              ></Table>
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default DeviceSummaryReport;
