import { useReporting } from '../../../../hooks/app/useReporting';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import WorklogReport from './WorklogReport';
import { useNotification } from '../../../../hooks/Notification';
import { ReportingContext } from '../ReportingContext';
import {
  FilterValue,
  Worklog,
  DateRangeFilterPresets,
  DateRangeFilterType,
  Filters,
  ReportFilterDropdown,
} from '@hitz-group/domain';
import { View } from 'react-native';
import { ContainerStyles } from '../styles/Component.styles';
import {
  buildFilterObject,
  generateFilterData,
  WORKLOG_REPORT_FILTERS,
} from '../reportsHelper';
import {
  DateRangeFilterInput,
  CustomReports,
  Search,
  WorklogQueryInput,
  DropDownFilter,
} from '../types';
import * as storage from '../../../../storage/interface';

export const WorklogReportContainer: React.FC = () => {
  const { showNotification } = useNotification();
  const { cubejsApi, filters: allFilters } = useContext(ReportingContext);

  const [filters, setFilters] = useState<FilterValue>({});
  const [worklogs, setWorklogs] = useState<Worklog[]>([]);
  const [search, setSearch] = useState<Search>({
    type: 'userName',
    value: '',
  });

  const [dateRangeFilters, setDateRangeFilters] =
    useState<DateRangeFilterInput>({
      key: 'date',
      text: '',
      type: DateRangeFilterType.FILTER_BY_PRESET,
      value: DateRangeFilterPresets.TODAY,
    } as DateRangeFilterInput);

  const styles = ContainerStyles();

  const { worklogReportData, loading, error, getWorklogData } =
    useReporting(cubejsApi);

  const [didLoad, setDidLoad] = useState<boolean>(false);

  const ddFilters = useMemo(() => {
    if (allFilters) {
      return WORKLOG_REPORT_FILTERS.map((filter: ReportFilterDropdown) => {
        return {
          ...filter,
          values: allFilters[filter.key as string as keyof Filters],
        } as DropDownFilter;
      });
    }
  }, [allFilters]);

  useEffect(() => {
    const getReportData = async () => {
      const cached: WorklogQueryInput | undefined = await storage.getItem(
        CustomReports.WORKLOG,
      );

      const input: WorklogQueryInput = cached || {
        filter: filters,
        dateRange: dateRangeFilters,
      };

      getWorklogData &&
        getWorklogData({
          variables: {
            input: generateFilterData(
              input?.filter as FilterValue,
              input?.dateRange as DateRangeFilterInput,
            ),
          },
        });
    };

    if (!didLoad) {
      getReportData();
      setDidLoad(true);
    }
  }, [didLoad, getWorklogData, filters, dateRangeFilters]);

  useEffect(() => {
    worklogReportData &&
      worklogReportData.query &&
      setFilters(worklogReportData.query.filter as FilterValue);
    worklogReportData &&
      worklogReportData.query &&
      setDateRangeFilters(worklogReportData.query.dateRange);

    worklogReportData &&
      worklogReportData.worklogs &&
      setWorklogs(worklogReportData.worklogs);
  }, [worklogReportData]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const updateFilters = useCallback((filter: string, value: string[]) => {
    setFilters((filters: FilterValue) => {
      return {
        ...filters,
        [filter]: value,
      };
    });
  }, []);

  const updateDateRangeFilters = useCallback((value: DateRangeFilterInput) => {
    const toSet = buildFilterObject(value);
    setDateRangeFilters(toSet);
  }, []);

  const updateSearch = useCallback((prop: string, value) => {
    setSearch(search => {
      return {
        ...search,
        [prop]: value,
      } as Search;
    });
  }, []);

  useEffect(() => {
    if (
      worklogReportData?.worklogs instanceof Array &&
      worklogReportData?.worklogs.length > 0
    ) {
      if (search.value.trim() !== '') {
        setWorklogs(
          worklogReportData?.worklogs.filter(summary =>
            summary.user?.name?.includes(search.value),
          ) as Worklog[],
        );
      } else {
        setWorklogs(worklogReportData?.worklogs as Worklog[]);
      }
    }
  }, [search, worklogReportData?.worklogs]);

  const resetFilters = useCallback(() => {
    setFilters({});
  }, []);

  const updateReport = useCallback(() => {
    delete dateRangeFilters?.startDate;
    delete dateRangeFilters?.endDate;
    delete dateRangeFilters?.startTime;
    delete dateRangeFilters?.endTime;

    storage.setItem(CustomReports.WORKLOG, {
      dateRange: dateRangeFilters,
      filter: filters,
    });

    getWorklogData &&
      getWorklogData({
        variables: {
          input: generateFilterData(
            filters as FilterValue,
            dateRangeFilters as DateRangeFilterInput,
          ),
        },
      });
  }, [filters, dateRangeFilters, getWorklogData]);

  const containerLoading = loading || !allFilters;

  return (
    <View style={styles.pageStyle}>
      <WorklogReport
        worklogs={worklogs}
        filters={filters}
        search={search}
        filterOptions={ddFilters || ([] as DropDownFilter[])}
        allFilters={allFilters || ({} as Filters)}
        updateFilters={updateFilters}
        updateSearch={updateSearch}
        resetFilters={resetFilters}
        dateRangeFilter={dateRangeFilters || ({} as DateRangeFilterInput)}
        updateDateRangeFilters={updateDateRangeFilters}
        options={{ loading: containerLoading }}
        updateReport={updateReport}
      />
    </View>
  );
};

export default WorklogReportContainer;
