import { gql } from '@apollo/client';

export const FRAGMENTS = {
  deviceProfileDetails: gql`
    fragment DeviceProfileDetails on DeviceProfile {
      id
      name
      store {
        id
      }
      menu {
        id
        name
      }
      printerOptions {
        emailReceipt
        printReceipt
      }
      functionMap {
        id
        name
        functionActions {
          id
          name
          action
          params
          isGroup
          groupId
          color
          order
          feature
          featureContext
        }
      }
      orderTypes {
        id
        name
        code
      }
      devices {
        id
      }
      postSaleScreen
      defaultSaleScreen
      enableAudio
      receiptPrintOption
      signatureAndTipping
      enableCashManagement
      enableFloorView
      enableCourses
      enableSeatManagement
      enableOnlineOrders
      status
      isActive
      isDefault
      defaultOrderType {
        id
        name
        code
      }
      sections {
        id
        name
        tableCount
        defaultPrefix
        defaultTableShape
        isActive
        tables {
          id
          name
          shape
          guestCount
          position {
            top
            left
          }
        }
      }
    }
  `,
};

export const GET_DEVICE_PROFILE_BY_ID_QUERY = gql`
  query deviceProfileById($id: ID!) {
    deviceProfile(id: $id) {
      ...DeviceProfileDetails
    }
  }
  ${FRAGMENTS.deviceProfileDetails}
`;

export const CREATE_DEVICE_PROFILE = gql`
  mutation createDeviceProfile($input: CreateDeviceProfileInput!) {
    createDeviceProfile(input: $input) {
      id
      name
      isDefault
    }
  }
`;

export const GET_FUNCTION_MAPS_QUERY = gql`
  query getFunctionMaps {
    functionMaps {
      id
      name
    }
  }
`;

export const GET_ORDER_TYPES_QUERY = gql`
  query getOrderTypes {
    orderTypes {
      id
      name
      code
      isActive
    }
  }
`;

export const UPDATE_DEVICE_PROFILE_DETAILS = gql`
  mutation updateDeviceProfileDetails(
    $input: UpdateDeviceProfileDetailsInput!
  ) {
    updateDeviceProfileDetails(input: $input) {
      ...DeviceProfileDetails
    }
  }
  ${FRAGMENTS.deviceProfileDetails}
`;

export const UPDATE_DEVICE_PROFILE_OPTIONS = gql`
  mutation updateDeviceProfileOptions(
    $input: UpdateDeviceProfileOptionsInput!
  ) {
    updateDeviceProfileOptions(input: $input) {
      ...DeviceProfileDetails
    }
  }
  ${FRAGMENTS.deviceProfileDetails}
`;

export const UPDATE_DEVICE_PROFILE_WORKFLOW = gql`
  mutation updateDeviceProfileWorkflow(
    $input: UpdateDeviceProfileWorkflowInput!
  ) {
    updateDeviceProfileWorkflow(input: $input) {
      ...DeviceProfileDetails
    }
  }
  ${FRAGMENTS.deviceProfileDetails}
`;

export const UPDATE_DEVICE_PROFILES = gql`
  mutation updateDeviceProfiles($input: [UpdateDeviceProfileInput!]) {
    updateDeviceProfiles(input: $input) {
      ...DeviceProfileDetails
    }
  }
  ${FRAGMENTS.deviceProfileDetails}
`;

export const DELETE_DEVICE_PROFILE = gql`
  mutation deleteDeviceProfile($id: ID!) {
    deleteDeviceProfile(id: $id)
  }
`;
