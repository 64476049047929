import { BehaviorSubject, Observable } from 'rxjs';
import { App } from '@hitz-group/domain';

export interface TokenInfo {
  token?: string;
  refreshToken?: string;
  expiresAfter?: number;
  authState?: AuthState;
  // Set when clicked on `POS app` button
  // This will be cleared once user landed on backoffice
  posAppToken?: string;
  // Set once user logged-in to the Backoffice
  // This will be cleared once user logged-out
  // ENHANCEMENT: once we enabled rotate tokens, we can keep session for longer hours / days
  backOfficeToken?: string;
  activeApp?: App;
}

export enum AuthState {
  LOGGED_IN = 'logged In',
  LOGOUT = 'logout',
  LOADING = 'loading',
}

export const DEFAULT_TOKEN_INFO = {
  token: undefined,
  refreshToken: undefined,
  expiresAfter: undefined,
  authState: AuthState.LOADING,
  activeApp: App.BACKOFFICE, // the default landed app
  backOfficeToken: undefined,
  posAppToken: undefined,
};

export class TokenUtility {
  private subject = new BehaviorSubject<TokenInfo>(DEFAULT_TOKEN_INFO);

  setTokenInfo(data: TokenInfo) {
    this.subject.next({ ...this.subject.value, ...data });
  }

  clearToken() {
    this.subject.next({ ...DEFAULT_TOKEN_INFO, authState: AuthState.LOGOUT });
  }

  get getTokenInfo$(): Observable<TokenInfo> {
    return this.subject.asObservable();
  }
}

export const tokenUtility = new TokenUtility();
