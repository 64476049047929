import { StyleFn, Modifier as ModifierDefault } from '@hitz-group/domain';
import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { useTranslation, useCurrency } from '@hitz-group/localization';
import TableRow from '../../../../../components/TableComponent/TableRow';
import IconButton from '../../../../../components/Button/IconButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import { getBestPriceOfModifier } from '@hitz-group/catalog-helper';
interface Modifier extends ModifierDefault {
  isSelected?: boolean;
  tax?: string;
  priceAmount?: string;
}

interface ModifierRowProps {
  modifier: Modifier;
  onChangeModifier: (currentId: string, newId: string, index: number) => void;
  availableModifiers: { value: string; label: string }[];
  onDeleteRow: (currentId: string, index: number) => void;
  rowIndex: number;
  onAddModifier: (name: string, modifierIdToReplace: string) => void;
  onChangePrice: (modId: string, value: string) => void;
  drag?: () => void;
  isOnlyShowProductAsOption: boolean;
  isOnlyShowModifierOption: boolean;
}

const iconMarginStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.small / 2,
});

const dragIconContainer: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.big / 2,
  height: 28,
  width: 28,
  borderRadius: theme.radius.small,
  alignSelf: 'center',
});

const taxInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 90,
  height: theme.input.height,
  marginLeft: theme.spacing.small,
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  marginLeft: 'auto',
  alignSelf: 'center',
});

const dragIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.darkGrey,
});

const containerStyle: StyleFn = ({ rowIndex }) => ({
  zIndex: 1000 - 20 * rowIndex,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.red,
});

const attributesDropDownStyle: StyleFn = ({ theme }) => ({
  width: 340,
  minHeight: theme.input.height,
  alignSelf: 'center',
});
const touchableStyle: StyleFn = () => ({
  minHeight: 45,
  flexWrap: 'wrap',
});

const selectPlaceHolderStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});

const searchIconContainerStyle: StyleFn = ({ theme }) => ({
  height: '100%',
  paddingHorizontal: theme.padding.medium,
  justifyContent: 'center',
  marginLeft: 'auto',
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  height: 0,
});

export const ModifierRow: React.FC<ModifierRowProps> = ({
  modifier,
  onChangeModifier,
  onDeleteRow,
  rowIndex,
  availableModifiers,
  onAddModifier,
  onChangePrice,
  drag,
  isOnlyShowProductAsOption,
  isOnlyShowModifierOption,
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { appendCurrency } = useCurrency();
  const price =
    modifier?.price?.amount !== undefined
      ? modifier?.price?.amount
      : getBestPriceOfModifier(modifier as ModifierDefault);

  const labelForSearchModal = useMemo(() => {
    if (!isOnlyShowModifierOption && !isOnlyShowProductAsOption) {
      return translate('modifiers.searchModifierOrProduct');
    }
    if (isOnlyShowProductAsOption) {
      return translate('modifiers.searchProduct');
    }
    return translate('modifiers.searchModifier');
  }, [isOnlyShowModifierOption, isOnlyShowProductAsOption, translate]);

  return (
    <TableRow
      normalRows
      columnSpacing={theme.spacing.big}
      actionStyle={css(iconMarginStyle)}
      containerStyle={css(containerStyle({ theme, rowIndex }))}
    >
      <IconButton
        icon="draggabledots"
        iconSize={18}
        containerSize={28}
        containerStyle={css(dragIconContainer)}
        iconStyle={css(dragIconStyle)}
        onLongPress={drag}
      />

      <MultipleSelect
        values={availableModifiers}
        placeholder={modifier.name}
        showCheckboxes={false}
        containerStyle={css(attributesDropDownStyle)}
        searchLabel={labelForSearchModal + ':'}
        searchPlaceHolder={labelForSearchModal + '...'}
        maxItems={4}
        placeHolderStyle={css(selectPlaceHolderStyle)}
        onValueChange={values =>
          onChangeModifier(modifier.id, values[0], rowIndex)
        }
        onAddItem={
          !isOnlyShowProductAsOption
            ? name => onAddModifier(name, modifier.id)
            : undefined
        }
        collapseOnAddItem
        toggleIcon={
          <IconButton
            icon={'Search'}
            iconSize={20}
            iconColor={theme.colors.paragraph}
            containerStyle={css(searchIconContainerStyle)}
          />
        }
        touchableStyle={css(touchableStyle)}
      />
      <FormInput
        testID="product-price"
        placeholder={translate('backOfficeProducts.productPrice')}
        value={appendCurrency(price.toString())}
        containerStyle={css(taxInputContainerStyle)}
        textStyle={css(formTextStyle)}
        onChangeText={val => onChangePrice(modifier.id, val)}
      />

      <IconButton
        icon="TrashAlt"
        iconSize={24}
        containerSize={34}
        containerStyle={css(closeIconContainerStyle)}
        iconStyle={css(closeIconStyle)}
        onPress={() => onDeleteRow(modifier.id, rowIndex)}
      />
    </TableRow>
  );
};
