import { Section, StyleFn, TableShape } from '@hitz-group/domain';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import Button from '../../../../../components/Button/Button';
import { isAndroid, isIos } from '../../../../../common/theme';
import Modal from '../../../../../components/Modals/Modal';
import { useTranslation } from '@hitz-group/localization';
import FormInput from '../../../../../components/FormInput/FormInput';
import SelectBar from '../../../../../components/Button/SelectBar';
import DropDown from '../../../../../components/FormInput/DropDown';
import { useNotification } from '../../../../../hooks/Notification';
import { isNotEmpty } from '../../../../../utils/validator';
import { isDigit } from '../../../../../utils/validator';

const containerStyle: StyleFn = () => ({
  width: 340,
  minHeight: isAndroid ? 610 : 470,
  alignSelf: 'center',
  height: isAndroid ? 610 : 470,
  paddingTop: 0,
  paddingBottom: 0,
  borderRadius: 10,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1,
  marginTop: theme.spacing.small,
});

const btnRowStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  zIndex: 1,
});

const formInputContainerStyle: StyleFn = () => ({
  width: 130,
});

const formInputLongContainerStyle: StyleFn = () => ({
  width: 290,
});

const extraViewStyle: StyleFn = () => ({
  width: isIos ? 650 : null,
});

const extraMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: 295,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});

const selectBarStyle: StyleFn = ({ theme }) => ({
  height: 40,
  marginVertical: theme.spacing.small,
});

const btnContainer: StyleFn = ({ theme }) => ({
  width: 100,
  height: 38,
  marginVertical: theme.spacing.medium,
  color: theme.colors.white,
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontSize: theme.fontSize.medium,
  marginBottom: theme.spacing.small,
  marginLeft: theme.spacing.small,
});

interface Props {
  onSingleTableCreate: (form: CreateTableModalForm) => void;
  onMultipleTableCreate: (form: CreateTableModalForm) => void;
  section: Section;
}

export interface CreateTableModalForm {
  name: string;
  horizontalSeats: string;
  verticalSeats: string;
  numberOfTables: string;
  shape: TableShape;
  prefix: string;
}

export const CreateTableModal: React.FC<Props> = ({
  section,
  onSingleTableCreate,
  onMultipleTableCreate,
}: Props) => {
  const { css } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [tableCreationOption, setTableCreationOption] = useState('Multiple');
  const [form, setForm] = useState<CreateTableModalForm>({
    name: '',
    horizontalSeats: '2',
    verticalSeats: '2',
    numberOfTables: '0',
    shape: section.defaultTableShape,
    prefix: section.defaultPrefix,
  });

  const onChangeOption = useCallback(
    (selectOption: string) => {
      if (tableCreationOption !== selectOption) {
        setTableCreationOption(selectOption);
      }
    },
    [tableCreationOption],
  );

  const onChange = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onChangeNumber = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value ? parseInt(value.replace(/[^0-9]/g, '')) : '',
    }));
  }, []);

  const onPressCreate = useCallback(() => {
    if (!tableSeatValidation(form.verticalSeats, form.horizontalSeats)) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.createTable.seatNumberRequired'),
      });
      return;
    }
    if (tableCreationOption === 'Single') {
      if (!form.name || !isNotEmpty(form.name)) {
        showNotification({
          error: true,
          message: translate('backOfficeSettings.tableNameIsRequired'),
        });
        return;
      }
      onSingleTableCreate(form);
    } else {
      if (!(parseInt(form.numberOfTables) > 0)) {
        showNotification({
          error: true,
          message: translate(
            'backOfficeSettings.createTable.pleaseEnterTableCount',
          ),
        });
        return;
      }
      onMultipleTableCreate(form);
    }
  }, [
    form,
    onSingleTableCreate,
    onMultipleTableCreate,
    showNotification,
    tableCreationOption,
    translate,
  ]);

  const tableSeatValidation = (
    verticalSeats: string,
    horizontalSeats: string,
  ) => {
    return (
      isDigit(verticalSeats) &&
      isDigit(horizontalSeats) &&
      (Number(verticalSeats) > 0 || Number(horizontalSeats) > 0)
    );
  };
  return (
    <>
      <Modal contentStyle={css(containerStyle)} showCloseButton={false}>
        <Text style={css(titleStyle)}>
          {translate('backOfficeSettings.createTable.createTables')}
        </Text>
        <SelectBar
          options={[
            {
              label: translate('backOfficeSettings.createTable.multiple'),
              value: 'Multiple',
            },
            {
              label: translate('backOfficeSettings.createTable.single'),
              value: 'Single',
            },
          ]}
          selectedOption={tableCreationOption}
          onPress={onChangeOption}
          containerStyle={css(selectBarStyle)}
        />

        {tableCreationOption === 'Multiple' && (
          <View style={css(rowStyle)}>
            <FormInput
              title={translate('backOfficeSettings.createTable.prefix')}
              alignTitle={'left'}
              onChangeText={onChange.bind(null, 'prefix')}
              containerStyle={css(formInputContainerStyle)}
              testID="table-name-prefix"
              value={form.prefix}
            />
            <FormInput
              title={translate('backOfficeSettings.createTable.tables')}
              alignTitle={'left'}
              onChangeText={onChangeNumber.bind(null, 'numberOfTables')}
              containerStyle={css(formInputContainerStyle)}
              testID="tables-count"
              type="number"
              value={form.numberOfTables}
              keyboardType="numeric"
            />
          </View>
        )}

        {tableCreationOption === 'Single' && (
          <View style={css(rowStyle)}>
            <FormInput
              title={translate('backOfficeSettings.createTable.tableName')}
              alignTitle={'left'}
              onChangeText={onChange.bind(null, 'name')}
              containerStyle={css(formInputLongContainerStyle)}
              testID="table-name"
              value={form.name}
            />
          </View>
        )}

        <View style={css(rowStyle)}>
          <FormInput
            title={translate('backOfficeSettings.createTable.seatsHorizontal')}
            alignTitle={'left'}
            onChangeText={onChangeNumber.bind(null, 'horizontalSeats')}
            containerStyle={css(formInputContainerStyle)}
            testID="tables-horizontal-seats"
            type="number"
            value={form.horizontalSeats}
            keyboardType="numeric"
          />
          <FormInput
            title={translate('backOfficeSettings.createTable.seatsVertical')}
            alignTitle={'left'}
            onChangeText={onChangeNumber.bind(null, 'verticalSeats')}
            containerStyle={css(formInputContainerStyle)}
            testID="tables-vertical-seats"
            type="number"
            value={form.verticalSeats}
            keyboardType="numeric"
          />
        </View>

        <View style={css(rowStyle)}>
          <DropDown
            title={translate('backOfficeSettings.createTable.tableShape')}
            values={Object.values(TableShape).map((key: string) => ({
              value: key,
              label: key,
            }))}
            selectedValue={form.shape}
            testID="table-shapes"
            style={css(formInputLongContainerStyle)}
            extraViewStyle={css(extraViewStyle)}
            extraMainViewStyle={css(extraMainViewStyle)}
            onValueChange={onChange.bind(null, 'shape')}
          />
        </View>

        <View style={css(btnRowStyle)}>
          <Button
            title={translate('backOfficeSettings.createTable.dismiss')}
            onPress={closeModal}
            containerStyle={css(btnContainer)}
            transparent
          />
          <Button
            title={translate('backOfficeSettings.createTable.add')}
            onPress={onPressCreate}
            containerStyle={css(btnContainer)}
            success
          />
        </View>
      </Modal>
    </>
  );
};
