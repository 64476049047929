import { useTranslation } from '@hitz-group/localization';
import { RenderProps, StyleFn } from '@hitz-group/domain';
import React from 'react';
import { FelaComponent } from 'react-fela';
import { View, ViewStyle } from 'react-native';
import SummaryItem from '../CartSummary/CartSummaryItem';
export interface ChangeSummaryProps {
  testID?: string;
  change: number;
  containerStyle?: ViewStyle;
}

const changeSummaryStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.padding.large,
  paddingVertical: theme.padding.medium,
  marginBottom: 10,
});

const ChangeSummary: React.FC<ChangeSummaryProps> = ({
  change,
  containerStyle,
}: ChangeSummaryProps) => {
  const { translate } = useTranslation();
  return (
    <FelaComponent style={changeSummaryStyle}>
      {({ style }: RenderProps): React.ReactFragment => (
        <View style={[style, containerStyle]} testID="change-summary-container">
          <SummaryItem
            highlighted
            label={translate('payment.changeDue')}
            value={change}
          />
        </View>
      )}
    </FelaComponent>
  );
};

export default ChangeSummary;
