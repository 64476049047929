import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { View } from 'react-native';
import { useNotification } from '../../hooks/Notification';
import { NotificationList } from '../Notification/NotificationList';
import { useFela } from 'react-fela';

const bodyStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.background,
  opacity: 0.95,
});

const Backdrop: React.FC = () => {
  const { css } = useFela();
  const { closeNotification, notifications } = useNotification();

  return (
    <View style={css(bodyStyle)}>
      <NotificationList
        notifications={notifications}
        onCloseNotification={closeNotification}
      />
    </View>
  );
};

export default Backdrop;
