import { RenderProps, StyleFn, Theme } from '@hitz-group/domain';
import React from 'react';
import { FelaComponent } from 'react-fela';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';

const textStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.danger,
  borderRadius: 20,
  padding: theme.padding.small,
  paddingHorizontal: theme.padding.medium,
  color: theme.colors.white,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.semibold,
  textAlign: 'center',
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getStyles = (theme: Theme) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btn: {
      height: 6,
      width: 6,
      borderRadius: 8,
      backgroundColor: theme.colors.danger,
    },
    line: {
      height: 2,
      width: 8,
      backgroundColor: theme.colors.danger,
    },
  });
  return styles;
};

export interface ErrorProps {
  style?: StyleProp<ViewStyle>;
  text: string;
}

const Error: React.FC<ErrorProps> = ({ style, text }) => (
  <FelaComponent style={textStyle}>
    {({ style: defaultStyle, theme }: RenderProps): React.ReactFragment => (
      <View style={[getStyles(theme).container, style]}>
        <View style={getStyles(theme).btn} />
        <View style={getStyles(theme).line} />
        <Text numberOfLines={1} style={defaultStyle}>
          {text}
        </Text>
      </View>
    )}
  </FelaComponent>
);

export default Error;
