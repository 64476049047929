import { useSubscription } from '@apollo/client/react/hooks';
import {
  Order,
  OrderStatus,
  OrderTypeCode,
  StyleFn,
  Table,
  TableStatus,
  Theme,
} from '@hitz-group/domain';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { useFocusEffect } from '@react-navigation/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFela } from 'react-fela';
import {
  FlatList,
  LayoutChangeEvent,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import scale from '../../../../common/theme';
import IconButton from '../../../../components/Button/IconButton';
import DropDown from '../../../../components/FormInput/DropDown';
import Icon from '../../../../components/Icon/Icon';
import { OrderEvents } from '../../../../graphql/subscriptions';
import { refetchOrderObservable } from '../../../../hooks/app/orders/ordersObservableUtils';
import { useOrders } from '../../../../hooks/app/orders/useOrders';
import { useSession } from '../../../../hooks/app/useSession';
import useBehaviorSubjectEffect from '../../../../hooks/app/useSubjectEffect';
import useBehaviorSubjectState from '../../../../hooks/app/useSubjectState';
import { useTimeout } from '../../../../hooks/useTimeout';
import { lastActiveTimeSubject } from '../../../../state/lastActiveTime';
import { getDayFormat } from '../../../../utils/dateHelper';
import { sortTablesByName } from '../../../../utils/TableHelper';
import {
  sectionIdController,
  sectionStatsVisibilityController,
} from './floorViewObservables';

const tableStatusColorMap = (theme: Theme) => ({
  [TableStatus.AVAILABLE]: theme.colors.boxBorder,
  [TableStatus.DONE_SOON]: theme.colors.green,
  [TableStatus.IN_USE]: theme.colors.blue,
  [TableStatus.OCCUPIED]: theme.colors.blue,
  [TableStatus.SEATED]: theme.colors.blue,
  [TableStatus.YOUR_TABLE]: theme.colors.blue,
  [TableStatus.INACTIVE]: theme.colors.blue,
  [TableStatus.MULTI_USE]: theme.colors.deepPurpleDark,
});

const tableStatusTextColorMap = (theme: Theme) => ({
  [TableStatus.AVAILABLE]: theme.colors.black,
  [TableStatus.DONE_SOON]: theme.colors.green,
  [TableStatus.IN_USE]: theme.colors.blue,
  [TableStatus.OCCUPIED]: theme.colors.blue,
  [TableStatus.SEATED]: theme.colors.blue,
  [TableStatus.YOUR_TABLE]: theme.colors.blue,
  [TableStatus.INACTIVE]: theme.colors.blue,
  [TableStatus.MULTI_USE]: theme.colors.deepPurpleDark,
});

const tableStatusColorBackgroundMap = (theme: Theme) => ({
  [TableStatus.AVAILABLE]: theme.colors.greyLight,
  [TableStatus.DONE_SOON]: theme.colors.successLightest,
  [TableStatus.IN_USE]: theme.colors.lightBlueSelect,
  [TableStatus.OCCUPIED]: theme.colors.lightBlueSelect,
  [TableStatus.SEATED]: theme.colors.lightBlueSelect,
  [TableStatus.YOUR_TABLE]: theme.colors.lightBlueSelect,
  [TableStatus.INACTIVE]: theme.colors.blue,
  [TableStatus.MULTI_USE]: theme.colors.deepPurpleDark,
});

export enum SectionStatsFilterOption {
  currentSpend = 'currentSpend',
  timeSinceOccupied = 'timeSinceOccupied',
}
export const getSectionStatsFilterOption = (t: Function) => [
  {
    value: SectionStatsFilterOption.currentSpend,
    label: t('statisticsPanel.currentSpend'),
  },
  {
    value: SectionStatsFilterOption.timeSinceOccupied,
    label: t('statisticsPanel.timeSinceOccupied'),
  },
];

const sectionSummaryId = 'section-summary-id';

const container: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.border,
  position: 'absolute',
  right: 0,
  width: 330,
  height: '100%',
  shadowOffset: { width: 3, height: 4 },
  shadowOpacity: 0.4,
  shadowColor: theme.colors.Black,
  shadowRadius: 5,
  elevation: 5,
  padding: 5,
});
const mainContainer: StyleFn = ({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  backgroundColor: theme.colors.blackTransparent02,
});
const touchableStyle: StyleFn = () => ({
  flex: 1,
});
const headerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  borderRadius: theme.radius.medium,
  margin: theme.padding.small,
  paddingHorizontal: theme.spacing.small,
  backgroundColor: theme.colors.white,
  flexDirection: 'row',
  alignItems: 'center',
});

const titleStyle: StyleFn = ({ theme }) => ({
  ...theme.font14SemiBold,
  fontSize: theme.fontSize.small,
  textAlign: 'center',
  flex: 1,
  alignSelf: 'center',
  textTransform: 'uppercase',
});
const contentView: StyleFn = ({ theme }) => ({
  flex: 1,
  padding: theme.spacing.small,
  borderRadius: theme.radius.medium,
  backgroundColor: theme.colors.white,
  marginHorizontal: theme.padding.small,
});

const dropDownStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: theme.input.height,
  marginTop: theme.spacing.small,
});
const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: 290,
  borderRadius: theme.radius.medium,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  height: 45,
});
const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 290,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
});
const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: Platform.OS === 'web' ? '100%' : '15%',
  bottom: Platform.OS === 'web' ? 5 : 0,
});
const labelStyle: StyleFn = () => ({
  paddingHorizontal: scale.moderateScale(5),
  paddingVertical: scale.moderateScale(3),
  textTransform: 'none',
});
const angleDownIconStyle: StyleFn = () => ({
  right: 10,
});
const rowStyle: StyleFn = ({ theme }) => ({
  height: 50,
  alignItems: 'center',
  flexDirection: 'row',
  borderBottomWidth: 1,
  borderBottomColor: theme.colors.boxBorder,
});
const squareStyle: StyleFn = ({ theme, status }) => ({
  height: 25,
  width: 45,
  borderRadius: theme.radius.medium,
  marginRight: theme.spacing.small,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: tableStatusColorBackgroundMap(theme)[status as TableStatus],
});
const tableNumberTextStyle: StyleFn = ({ theme, status }) => ({
  ...theme.font14Bold,
  color: tableStatusTextColorMap(theme)[status as TableStatus],
  textTransform: 'uppercase',
});

const tableValueTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Bold,
  color: theme.colors.darkGrey,
});
const tableValueBoldTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.grey2,
});
const dotView: StyleFn = ({ theme, status }) => ({
  height: 8,
  width: 8,
  borderRadius: 5,
  marginLeft: 'auto',
  marginRight: theme.spacing.small,
  backgroundColor: tableStatusColorMap(theme)[status as TableStatus],
});
const arrowView: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  height: 80,
  margin: 5,
  marginTop: -5,
  borderTopWidth: 2,
  borderTopColor: theme.colors.boxBorder,
});
const arrowButton1: StyleFn = ({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderRightWidth: 2,
  borderBottomLeftRadius: theme.radius.medium,
  borderRightColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
});
const arrowButton2: StyleFn = ({ theme }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  borderBottomRightRadius: theme.radius.medium,
});

export interface TableWithOrder extends Table {
  order: Order;
}

interface TableStatisticRowProps {
  table: TableWithOrder;
  statsViewOption: SectionStatsFilterOption;
}

export const TableStatisticRow: React.FC<TableStatisticRowProps> = ({
  table,
  statsViewOption,
}) => {
  const { css } = useFela({ status: table.status });
  const { formatCurrency } = useCurrency();
  const currentSpend = table.order?.totalPrice ?? 0;
  const currentOccupiedTime = table.order?.createdAt
    ? getDayFormat(table.order.createdAt)
    : '--';
  return (
    <View style={css(rowStyle)}>
      <View style={css(squareStyle)}>
        <Text style={css(tableNumberTextStyle)}>{table.name}</Text>
      </View>
      <Text
        style={css([
          tableValueTextStyle,
          statsViewOption == SectionStatsFilterOption.currentSpend &&
            currentSpend > 0 &&
            tableValueBoldTextStyle,
          statsViewOption == SectionStatsFilterOption.timeSinceOccupied &&
            currentOccupiedTime !== '--' &&
            tableValueBoldTextStyle,
        ])}
      >
        {statsViewOption == SectionStatsFilterOption.currentSpend
          ? `${formatCurrency(currentSpend)}`
          : currentOccupiedTime}
      </Text>
      <View style={css(dotView)}></View>
    </View>
  );
};

const StatisticsPanel: React.FC = ({}) => {
  const { css, theme } = useFela();
  const [statsViewOption, setStatsViewOption] =
    useState<SectionStatsFilterOption>(SectionStatsFilterOption.currentSpend);
  const { value: visible, setValue: setVisible } =
    useBehaviorSubjectState<boolean>(sectionStatsVisibilityController);
  const { value: sectionId } =
    useBehaviorSubjectState<string>(sectionIdController);

  const scrollRef = useRef<FlatList<TableWithOrder>>(null);
  const flatListHeightRef = useRef<number>(0);
  const currentFlatListPage = useRef<number>(0);
  const [{ deviceProfile, settings, currentStore, device }] = useSession();
  const { returnOrdersFromCache } = useOrders();
  const section = deviceProfile?.sections?.find(s => s.id === sectionId);
  const { translate } = useTranslation();
  const [tableWithOrdersInfo, setTableWithOrdersInfo] = useState<
    Record<string, Order[]>
  >({} as Record<string, Order[]>);
  const { value: lastActiveTime } = useBehaviorSubjectState(
    lastActiveTimeSubject,
  );

  const { data: subscriptionData } = useSubscription(OrderEvents, {
    variables: {
      storeId: currentStore?.id,
      deviceId: device?.id,
      lastActiveTime,
    },
    shouldResubscribe: true,
  });

  const makeTableOrderMap = useCallback(
    (
      result: Record<string, Order[]>,
      order: Order,
    ): Record<string, Order[]> => {
      if (order.orderType?.code === OrderTypeCode.DINE_IN && order?.table?.id) {
        result[order.table.id] = !!result[order.table.id]
          ? [...result[order.table.id], order].sort(
              (a, b) => a.createdAt - b.createdAt,
            )
          : [order];
      }
      return result;
    },
    [],
  );

  const makeSectionSummaryOrder = useCallback(
    (summaryOrder, order: Order): Order => {
      if (order.orderType?.code === OrderTypeCode.DINE_IN && order?.table?.id) {
        if (section?.tables.find(tab => tab.id === order?.table?.id)) {
          summaryOrder.totalPrice += order.totalPrice;
        }
      }
      return summaryOrder;
    },
    [section],
  );

  const refreshTableWithOrderInfo = useCallback(() => {
    const inProgressOrders = returnOrdersFromCache(OrderStatus.IN_PROGRESS);
    const holdOrders = returnOrdersFromCache(OrderStatus.ON_HOLD);
    const listOpenOrders = [...inProgressOrders, ...holdOrders];
    const mapToOrder = listOpenOrders.reduce<Record<string, Order[]>>(
      makeTableOrderMap,
      {},
    );
    const sectionSummary = listOpenOrders.reduce<Order>(
      makeSectionSummaryOrder,
      {
        table: {
          id: sectionSummaryId,
          status: TableStatus.AVAILABLE,
        } as Table,
        totalPrice: 0,
      } as Order,
    );

    mapToOrder[sectionSummaryId] = [sectionSummary];
    setTableWithOrdersInfo(mapToOrder);
  }, [returnOrdersFromCache, makeTableOrderMap, makeSectionSummaryOrder]);
  const delayRefreshTableWithOrderInfo = useTimeout(refreshTableWithOrderInfo);

  useFocusEffect(refreshTableWithOrderInfo);
  useBehaviorSubjectEffect(refetchOrderObservable, refreshTableWithOrderInfo);
  useEffect(() => {
    if (subscriptionData) {
      delayRefreshTableWithOrderInfo.start(2000);
    }
  }, [delayRefreshTableWithOrderInfo, subscriptionData]);

  const tables = useMemo<TableWithOrder[]>(() => {
    return sortTablesByName(section?.tables ?? [])
      .map(table => {
        return (tableWithOrdersInfo[table.id] ?? [{}]).map((order, index) => {
          return {
            ...table,
            id: `${table.id}-${index}`,
            name:
              tableWithOrdersInfo[table.id]?.length > 1
                ? `${table.name}-${index + 1}`
                : table.name,
            status: order.table?.status ?? TableStatus.AVAILABLE,
            order,
          };
        });
      })
      .flat();
  }, [section, tableWithOrdersInfo]);

  const flatListData = useMemo(() => {
    return statsViewOption === SectionStatsFilterOption.currentSpend
      ? [
          {
            id: sectionSummaryId,
            status: TableStatus.AVAILABLE,
            name: translate('statisticsPanel.all'),
            order: tableWithOrdersInfo[sectionSummaryId]?.[0],
          } as TableWithOrder,
          ...tables,
        ]
      : tables;
  }, [statsViewOption, tables, translate, tableWithOrdersInfo]);

  const scrollPreviousPage = useCallback(() => {
    if (currentFlatListPage.current === 0) return;
    const numberItemPerPage = Math.floor(flatListHeightRef.current / 50);
    scrollRef.current?.scrollToIndex({
      index: (currentFlatListPage.current - 1) * numberItemPerPage,
    });
    currentFlatListPage.current -= 1;
  }, []);

  const scrollNextPage = useCallback(() => {
    const numberItemPerPage = Math.floor(flatListHeightRef.current / 50);
    if ((currentFlatListPage.current + 1) * numberItemPerPage >= tables.length)
      return;
    scrollRef.current?.scrollToIndex({
      index: (currentFlatListPage.current + 1) * numberItemPerPage,
    });
    currentFlatListPage.current += 1;
  }, [tables]);

  const hideSectionStatsPanel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  if (!visible) return null;

  return (
    <View style={css([mainContainer])}>
      <TouchableOpacity
        testID="touch-on-screen"
        style={css(touchableStyle)}
        onPress={hideSectionStatsPanel}
      ></TouchableOpacity>
      <View style={css(container)}>
        <View style={css(headerStyle)}>
          <IconButton
            testID="statistics-cross-icon"
            icon="times"
            onPress={hideSectionStatsPanel}
            iconColor={theme.colors.black}
          />
          <Text testID="title" style={css(titleStyle)}>
            {translate('statisticsPanel.statistic')}
          </Text>
        </View>
        <View style={css(contentView)}>
          <DropDown
            values={getSectionStatsFilterOption(translate)}
            style={css(dropDownStyle)}
            selectedValue={statsViewOption}
            extraMainViewStyle={css(dropDownMainViewStyle)}
            extraStyle={css(dropdownExtraStyle)}
            extraViewStyle={css(dropdownViewStyle)}
            textStyle={css(labelStyle)}
            angleDownIconStyle={css(angleDownIconStyle)}
            testID="statistics-sidebar"
            onValueChange={setStatsViewOption}
          />
          <FlatList
            testID="list-table-statistic"
            ref={scrollRef}
            data={flatListData}
            extraData={statsViewOption}
            renderItem={({ item }) => (
              <TableStatisticRow
                key={item.id}
                table={item}
                statsViewOption={statsViewOption}
              />
            )}
            onLayout={(event: LayoutChangeEvent) => {
              flatListHeightRef.current = event.nativeEvent.layout.height;
            }}
          />
        </View>
        {settings?.showScrollSetting && (
          <View style={css(arrowView)}>
            <TouchableOpacity
              style={css(arrowButton1)}
              testID={'statistics-down-icon'}
              onPress={scrollNextPage}
            >
              <Icon name="AngleDown" color={theme.colors.black} />
            </TouchableOpacity>
            <TouchableOpacity
              style={css(arrowButton2)}
              onPress={scrollPreviousPage}
              testID={'statistics-up-icon'}
            >
              <Icon name="AngleUp" color={theme.colors.black} />
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
};

export default StatisticsPanel;
