import { getLoyaltyUnit } from '@hitz-group/client-utils';
import { LoyaltySettings, RewardRule, StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { Text, View, ViewStyle } from 'react-native';
import Button from '../../Button/Button';

const containerStyle: StyleFn = ({ theme, active }) => {
  let style: object = {
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: theme.padding.medium,
    borderRadius: 6,
    backgroundColor: theme.colors.white,
    ...theme.border.borderSolid,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  if (active) {
    style = {
      ...style,
      borderColor: theme.colors.green,
      backgroundColor: theme.colors.successLightest,
    };
  }
  return style;
};

const buttonGroupStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.greyLight,
  marginLeft: theme.padding.medium,
  borderRadius: theme.radius.small,
});

const buttonStyle: StyleFn = () => ({
  backgroundColor: 'transparent',
});

const buttonIconStyle = (disabled: boolean): StyleFn =>
  disabled
    ? ({ theme }) => ({
        color: theme.colors.paragraphLight,
      })
    : ({ theme }) => ({
        color: theme.colors.black,
      });

const descriptionStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  marginTop: theme.padding.small,
  ...theme.font14Medium,
});

const pointStyle: StyleFn = ({ theme, active }) => ({
  ...theme.font14SemiBold,
  color: active ? theme.colors.green : '#2684fc',
});
export interface RewardDisplayProps {
  loyaltySettings: Partial<LoyaltySettings>;
  reward: RewardRule;
  quantity: number;
  disableIncrement?: boolean;
  onChange: (reward: RewardRule, quantity: number) => void;
  style?: ViewStyle;
}

const RewardDisplay: React.FC<RewardDisplayProps> = ({
  loyaltySettings,
  reward,
  quantity,
  disableIncrement,
  onChange,
  style,
}) => {
  const { css } = useFela({ active: quantity > 0 });

  const increaseQuantity = () => {
    if (!disableIncrement) {
      onChange(reward, quantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 0) {
      onChange(reward, quantity - 1);
    }
  };

  return (
    <View style={[css(containerStyle), style]}>
      <View>
        <Text style={css(pointStyle)}>{`${
          reward.pointsRequired
        } ${getLoyaltyUnit(reward.pointsRequired, loyaltySettings)}`}</Text>
        <Text style={css(descriptionStyle)}>{reward.rewardName}</Text>
      </View>
      <View style={css(buttonGroupStyle)}>
        <Button
          containerStyle={css(buttonStyle)}
          testID="decrease-button"
          onPress={decreaseQuantity}
          disabled={quantity <= 0}
          icon="angle-down"
          size="medium"
          iconProps={{
            style: css(buttonIconStyle(quantity <= 0)),
          }}
        />
        <Text testID="quantity-text">{quantity}</Text>
        <Button
          containerStyle={css(buttonStyle)}
          testID="increase-button"
          onPress={increaseQuantity}
          disabled={!!disableIncrement}
          icon="angle-up"
          size="medium"
          iconProps={{
            style: css(buttonIconStyle(!!disableIncrement)),
          }}
        />
      </View>
    </View>
  );
};

export default RewardDisplay;
