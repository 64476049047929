import {
  StyleFn,
  Role,
  ResourceOperation,
  App,
  AppAccess,
  RolePermissions,
} from '@hitz-group/domain';
import React, { useState, useCallback, useEffect, FC } from 'react';
import { useFela } from 'react-fela';
import { View, ScrollView } from 'react-native';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../../components/BackOfficeSection/BackOfficeSection';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../components/Button/Button';
import { useNotification } from '../../../../hooks/Notification';
import { useRoute } from '@react-navigation/native';
import LoadingIndicator from '../../../../components/LoadingIndicator/LoadingIndicator';
import { useNavigation } from '@react-navigation/native';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import { useModal } from '@hitz-group/rn-use-modal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRoles } from '../../../../hooks/app/users/useRoles';
import { usePermissions } from '../../../../hooks/app/users/usePermissions';
import { jobRoleSettingsUtility } from '../../../../state/jobRoleSettingsUtility';
import SearchPermissionInput from './SearchPermissionInput';
import ControlledFormInput from '../../../../components/FormFields/ControlledFormInput';
import OfficePermissions from './OfficePermissions';
import POSAppPermissions from './POSAppPermissions';
import PermissionSection from './PermissionSection';
import { stripProperties } from '../../../../utils/stripObjectProps';

const formContainerStyle: StyleFn = () => ({
  width: 540,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const space: StyleFn = () => ({
  height: 30,
});

const generalSettingsContainerWrapper: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  alignItems: 'center',
  paddingVertical: theme.spacing.big,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
  ...theme.shadow15,
  paddingLeft: theme.padding.large,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
});

const deleteButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginRight: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger2,
  alignSelf: 'auto',
});

const dangerTitleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.danger,
  fontFamily: theme.font.semibold,
  letterSpacing: -0.5,
});

interface FormData {
  name: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(50)
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed'),
});

export const CreateOrEditRole: FC = () => {
  const { showModal, closeModal } = useModal();
  const { css } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const route = useRoute();
  const params = route.params as {
    roleId: string;
  };
  const navigation = useNavigation();

  const onCreateCompleted = useCallback(
    (role: Role) => {
      showNotification({
        success: true,
        message: translate('backofficeManageRoles.roleCreated'),
      });
      navigation.setParams({
        roleId: role.id,
      });
    },
    [navigation, showNotification, translate],
  );

  const onUpdateCompleted = useCallback(() => {
    showNotification({
      success: true,
      message: translate('backofficeManageRoles.roleUpdated'),
    });
  }, [showNotification, translate]);

  const onDeleteCompleted = useCallback(() => {
    closeModal();
    navigation.navigate('JobRoles');
  }, [navigation, closeModal]);

  const {
    createRole,
    updateRole,
    deleteRole,
    rolesById,
    error,
    loading,
    fetchRoleById,
  } = useRoles({ onCreateCompleted, onDeleteCompleted, onUpdateCompleted });

  const { permissions, fetchPermissions } = usePermissions();
  const [roleData, setRoleData] = useState<Role>();
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const { handleSubmit, control, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    jobRoleSettingsUtility.isEditable = !readOnly;
  }, [readOnly]);

  useEffect(() => {
    if (roleData?.name) {
      reset({ name: roleData.name });
    }
  }, [roleData, reset]);

  useEffect(() => {
    if (rolesById && params.roleId && rolesById[params.roleId]) {
      setRoleData(rolesById[params.roleId]);
      const rolePermissions = rolesById[params.roleId].permissions;
      jobRoleSettingsUtility.selectedPermissions = Object.assign(
        {},
        ...rolePermissions.map(item => ({
          [item.id]: item?.operations,
        })),
      );
      jobRoleSettingsUtility.appAccessSettings = rolesById[params.roleId]
        .apps as AppAccess;
    }
  }, [params.roleId, rolesById]);

  useEffect(() => {
    if (error) {
      showNotification({ error: true, message: error });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (roleData) {
      // update the tab title
      navigation.setOptions({
        tabBarLabel: roleData.name,
      });
    }
  }, [navigation, roleData]);

  useEffect(() => {
    if (params.roleId) {
      fetchRoleById(params.roleId);
    }
  }, [fetchRoleById, params.roleId]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  useEffect(() => {
    return () => {
      jobRoleSettingsUtility.reset();
    };
  }, []);

  useEffect(() => {
    jobRoleSettingsUtility.permissions = permissions;
  }, [permissions]);

  const onSubmit = handleSubmit((data: FormData) => {
    const selectedPermissionsById = jobRoleSettingsUtility.selectedPermissions;

    const commonPayload = {
      apps: stripProperties(
        jobRoleSettingsUtility.appAccessSettings,
        '__typename',
      ),
      permissions: Object.keys(selectedPermissionsById)
        .filter(id => typeof id !== 'undefined')
        .reduce<RolePermissions[]>((acc, id) => {
          const permission = {
            id,
            operations: selectedPermissionsById[id],
          };
          if (Array.isArray(permission.operations)) {
            if ((permission.operations as ResourceOperation[]).length > 0) {
              acc.push(permission);
            }
          }
          if (typeof permission.operations === 'boolean') {
            acc.push(permission);
          }
          return acc;
        }, []),
    };
    if (roleData?.id) {
      updateRole({ id: roleData.id, ...data, ...commonPayload });
    } else {
      createRole({ ...data, ...commonPayload });
    }
  });

  const onDeleteConfirm = useCallback(() => {
    deleteRole(roleData?.id as string);
  }, [deleteRole, roleData?.id]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('backofficeManageRoles.deleteRoleMessage')}
        onConfirm={onDeleteConfirm}
      />,
    );
  }, [translate, showModal, onDeleteConfirm]);

  useEffect(() => {
    setReadOnly(roleData ? !roleData.isEditable : false);
  }, [roleData]);

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView
          testID="general-settings-screen"
          contentContainerStyle={css(generalSettingsContainerWrapper)}
        >
          <View style={css(pageStyle)}>
            <BackOfficeSection
              title={translate('backofficeManageRoles.roleDetails')}
              contentContainerStyle={css(formContainerStyle)}
              titleBorderBottom
            >
              <ControlledFormInput
                title={translate('backofficeManageRoles.form.roleName')}
                placeholder={translate('backofficeManageRoles.form.roleName')}
                readOnly={readOnly}
                control={control}
                name="name"
              />
            </BackOfficeSection>

            <BackOfficeSection
              title={translate('backofficeManageRoles.permissionDetails')}
              contentContainerStyle={css(formContainerStyle)}
              titleBorderBottom
            >
              <SearchPermissionInput />
              <PermissionSection app={App.BACKOFFICE}>
                <OfficePermissions />
              </PermissionSection>
              <View style={css(space)} />
              <PermissionSection app={App.POS_APP}>
                <POSAppPermissions />
              </PermissionSection>
            </BackOfficeSection>
          </View>
        </ScrollView>

        {!readOnly && (
          <View style={css(actionsContainerStyle)}>
            {roleData && (
              <Button
                fluid
                testID="deleteRoleBtn"
                title={translate('button.delete')}
                containerStyle={css(deleteButtonStyle)}
                labelStyle={css(dangerTitleStyle)}
                onPress={onPressDelete}
              />
            )}

            <Button
              fluid
              testID="saveRoleBtn"
              title={translate('button.saveChanges')}
              containerStyle={css(saveButtonStyle)}
              labelStyle={css(titleStyle)}
              onPress={onSubmit}
            />
          </View>
        )}
      </View>
    </>
  );
};
