import { cloneJSON } from '@hitz-group/client-utils';
import {
  Order,
  OrderAction,
  OrderStatus,
  OrderType,
  OrderTypeCode,
  Product,
  Resource,
  StyleFn,
  Table,
  VoidOrderEvent,
  VoidReason,
} from '@hitz-group/domain';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { useModal } from '@hitz-group/rn-use-modal';
import format from 'date-fns/format';
import keyBy from 'lodash/keyBy';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import { productFragment } from '../../../src/hooks/app/catalogue/graphql';
import { useProducts } from '../../../src/hooks/app/products/useProducts';
import {
  productQuantitiesToReturnOnVoidOrder,
  updateProductQuantities,
} from '../../../src/utils/OpenOrdersHelper';
import { isWeb } from '../../common/theme';
import TableComponent from '../../components/TableComponent/TableComponent';
import usePOSUserAuthorization from '../../hooks/app/users/usePOSUserAuthorization';
import { useCartContext as useCart } from '../../hooks/CartProvider';
import { getShortVersion } from '../../hooks/orders/useOrderNumber';
import { getDayFormat } from '../../utils/dateHelper';
import IconButton from '../Button/IconButton';
import Label from '../Label/Label';
import { CancelOrderModal } from '../Modals/CancelOrder/CancelOrder';
import Title from '../Title/Title';
import OrderDataRow from './OrderDataRow';
import { PaymentTypeDisplay } from './OrderHistoryView';
import PrintActionButton from './PrintActionButton';
interface TimerStatus {
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
}
export interface OrderTypeDisplayProps extends OrderType {
  colorId: number;
  code: string;
}
export interface OpenOrdersDataProps {
  id: string;
  orderNumber: string;
  orderItems: Array<Partial<Product>>;
  createdAt: number;
  updatedAt?: number;
  customer: string;
  staff: string;
  orderNote: string;
  orderType: OrderTypeDisplayProps;
  payTypes: Array<PaymentTypeDisplay>;
  totalValue: number;
  table: Table;
}
export interface OpenOrdersViewProps {
  data: OpenOrdersDataProps[];
  orders: Record<string, Order>;
  onSelectOrder?: (id: string) => void;
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
  onVoidOrder: () => void;
}
const orderContainer: StyleFn = () => ({
  justifyContent: 'center',
  width: 65,
  alignItems: 'center',
});
const dateTimeContainer: StyleFn = () => ({
  width: 120,
  alignItems: 'center',
  justifyContent: 'center',
});
const customerContainer: StyleFn = () => ({
  width: 110,
  alignItems: 'center',
  justifyContent: 'center',
});
const orderTypeContainer: StyleFn = () => ({
  justifyContent: 'center',
  width: 65,
  alignItems: 'center',
});
const totalValueContainer: StyleFn = () => ({
  width: 60,
  alignItems: 'center',
});
const actionsContainer: StyleFn = () => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});
const actionIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  marginLeft: theme.spacing.small / 2,
});

const textStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  textTransform: 'capitalize',
});
const textStyleAmount: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  textTransform: 'capitalize',
  textAlign: 'right',
});
const columnContainerStyle: StyleFn = ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  paddingHorizontal: theme.padding.medium,
  marginBottom: 10,
  marginTop: 7,
  height: 41,
  borderBottomWidth: 0,
  marginRight: 0,
  borderRadius: theme.radius.small,
});
const rowStyle: StyleFn = ({ theme }) => ({
  height: 50,
  paddingHorizontal: theme.padding.medium,
  borderRadius: theme.radius.small,
});
const notesContainer: StyleFn = () => ({
  flex: 0.7,
  justifyContent: 'center',
  alignItems: 'center',
});
const timerContainer: StyleFn = () => ({
  alignItems: 'center',
  width: 65,
  justifyContent: 'center',
});
const placeholderStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  color: theme.colors.paragraph,
  fontFamily: theme.font.regular,
  textTransform: 'capitalize',
});
const labelText: StyleFn = ({ theme, isDineInOrder }) => ({
  ...theme.font14RegularCharcoal,
  color: isDineInOrder ? theme.colors.teal : theme.colors.blue,
});
const durationLabelText: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  fontSize: theme.fontSize.small,
  textTransform: 'lowercase',
});
const containerStyle: StyleFn = ({ theme }) => ({
  marginHorizontal: theme.spacing.big,
  marginBottom: theme.spacing.small,
  marginTop: theme.spacing.small * 1.5,
});

const OpenOrdersView: React.FC<OpenOrdersViewProps> = ({
  data,
  orders,
  onSelectOrder,
  onPressPrintReceipt,
  onVoidOrder: voidOrderCallback,
}: OpenOrdersViewProps) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { showModal } = useModal();
  const { setCartParams, updateCart, resetCart, clearPriorPendingEvents } =
    useCart();
  const { getProductsFromCache, updateProductsInCache } = useProducts(
    undefined,
    productFragment,
  );
  const { canI } = usePOSUserAuthorization();

  const onVoidOrder = useCallback(
    async (reason: VoidReason, description: string, selectedOrder: Order) => {
      const order = orders[selectedOrder.id];
      const allProducts = cloneJSON(keyBy(getProductsFromCache(), 'id'));
      const productQuantities = productQuantitiesToReturnOnVoidOrder(
        allProducts,
        order,
      );

      const updatedProducts = updateProductQuantities(
        productQuantities,
        allProducts,
      );

      updateProductsInCache(updatedProducts);

      clearPriorPendingEvents();
      await updateCart<VoidOrderEvent>(OrderAction.ORDER_VOID, {
        reason,
        description,
        productQuantities: Object.keys(productQuantities).map(key => {
          return { id: key, quantity: productQuantities[key] };
        }),
        inventoryTracked: !!Object.keys(productQuantities).length,
      });

      resetCart();
      voidOrderCallback();
    },
    [
      orders,
      getProductsFromCache,
      updateProductsInCache,
      clearPriorPendingEvents,
      updateCart,
      resetCart,
      voidOrderCallback,
    ],
  );
  const onPressCancel = useCallback(
    (orderId: string) => {
      const order = data.find(order => order.id === orderId);
      if (order) {
        const allowVoidOrder = canI([{ onResource: Resource.VOID_ORDERS }], {
          prompt: true,
        });
        if (!allowVoidOrder) return;
        setCartParams(order.id, undefined, undefined, true);
        showModal(
          <CancelOrderModal
            order={order as unknown as Order}
            onCancel={onVoidOrder}
          />,
        );
      }
    },
    [data, setCartParams, showModal, onVoidOrder, canI],
  );
  const orderTimerStatus = useCallback((input: number): TimerStatus => {
    const diff = Date.now() - input;
    return diff <= 600000
      ? { success: true }
      : diff > 600000 && diff <= 900000
      ? { warning: true }
      : { danger: true };
  }, []);

  return (
    <ScrollView style={css(containerStyle)}>
      <TableComponent
        columnSpacing={0}
        columns={[
          {
            title: translate('openOrders.type'),
            width: isWeb ? 70 : 50,
            alignItems: 'center',
          },
          {
            title: translate('openOrders.transaction'),
            width: isWeb ? 100 : 90,
            alignItems: 'center',
          },
          {
            title: translate('openOrders.age'),
            width: 60,
            alignItems: 'center',
          },
          {
            title: translate('openOrders.placedOn'),
            width: 140,
            alignItems: 'center',
          },
          {
            title: translate('openOrders.customer'),
            width: isWeb ? 130 : 110,
            alignItems: 'center',
          },
          {
            title: translate('openOrders.staff'),
            width: 120,
            alignItems: 'center',
          },
          {
            title: translate('openOrders.notes'),
            flex: 1,
            alignItems: 'center',
          },
          {
            title: translate('openOrders.lastAction'),
            width: isWeb ? 110 : 90,
            alignItems: 'flex-start',
          },
          {
            title: translate('openOrders.amount'),
            width: 60,
            alignItems: 'flex-end',
          },
          {
            title: translate('openOrders.actions'),
            width: isWeb ? 110 : 95,
            alignItems: 'center',
            containerStyle: { marginRight: 0 },
          },
          { title: '', width: 15 },
        ]}
        data={data}
        renderRow={(
          order: OpenOrdersDataProps,
          index: number,
        ): React.ReactNode => {
          const isDineInOrder = order.orderType.code === 'D';
          return (
            <OrderDataRow
              key={index}
              canSelect
              containerStyle={css(rowStyle)}
              onPress={onSelectOrder?.bind(null, order.id)}
              testID={`open-order-${index}`}
            >
              <View style={css(orderTypeContainer)}>
                <Label
                  testID={`open-order-${index}-table-number`}
                  value={
                    order.table &&
                    order.orderType.code === OrderTypeCode.DINE_IN
                      ? order?.table.name
                      : order.orderType.code
                  }
                  colorKey={isDineInOrder ? 6 : 4}
                  textStyle={css(labelText({ theme, isDineInOrder }))}
                />
              </View>
              <View style={css(orderContainer)}>
                <Label
                  testID={`open-order-${index}-order-number`}
                  value={getShortVersion(order.orderNumber)}
                  textStyle={css(labelText)}
                />
              </View>
              <View style={css(timerContainer)}>
                <Label
                  testID={`open-order-${index}-age`}
                  {...orderTimerStatus(order.createdAt)}
                  value={getDayFormat(order.createdAt)}
                  textStyle={css(durationLabelText)}
                />
              </View>
              <Title
                containerStyle={css(dateTimeContainer)}
                labelStyle={css(textStyle)}
                numberOfLines={1}
                testID={`open-order-${index}-created-at`}
              >
                {format(new Date(order.createdAt), 'hh:mm aa dd/MM')}
              </Title>
              <Title
                containerStyle={css(customerContainer)}
                labelStyle={css(textStyle)}
                numberOfLines={1}
                testID={`open-order-${index}-customer`}
              >
                {order.customer}
              </Title>
              <Title
                containerStyle={css(customerContainer)}
                labelStyle={css(textStyle)}
                numberOfLines={1}
                testID={`open-order-${index}-staff`}
              >
                {order.staff}
              </Title>
              <Title
                containerStyle={css(notesContainer)}
                labelStyle={
                  order.orderNote ? css(textStyle) : css(placeholderStyle)
                }
                numberOfLines={1}
                testID={`open-order-${index}-order-note`}
              >
                {order.orderNote || 'Notes...'}
              </Title>
              <View style={css(timerContainer)}>
                <Label
                  {...orderTimerStatus(order.updatedAt || 0)}
                  value={getDayFormat(order.updatedAt || 0)}
                  textStyle={css(durationLabelText)}
                  testID={`open-order-${index}-updated-at`}
                />
              </View>
              <Title
                containerStyle={css(totalValueContainer)}
                labelStyle={css(textStyleAmount)}
                numberOfLines={1}
                testID={`open-order-${index}-total`}
              >
                {formatCurrency(order.totalValue)}
              </Title>
              <View style={css(actionsContainer)}>
                <PrintActionButton
                  options={order.payTypes}
                  onPrintReceipt={paymentIndex =>
                    onPressPrintReceipt(order.id, paymentIndex)
                  }
                  orderStatus={OrderStatus.IN_PROGRESS}
                />
                <IconButton
                  key="2"
                  icon="times"
                  color={'danger2'}
                  containerSize={38}
                  iconSize={20}
                  onPress={onPressCancel.bind(null, order.id)}
                  iconStyle={{ color: theme.colors.danger }}
                  containerStyle={css(actionIconContainerStyle)}
                  testID={`open-order-${index}-cancel`}
                />
              </View>
            </OrderDataRow>
          );
        }}
        columnContainerStyle={css(columnContainerStyle)}
        normalRows
        showPagination
      />
    </ScrollView>
  );
};
export default OpenOrdersView;
