import React, { useContext, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { VariantSettingsForVariant } from './VariantSettingsForVariant';
import { VariantSettingsForProduct } from './VariantSettingsForProduct';
import { VariantSettingsForVariantProduct } from './VariantSettingsForVariantProduct';
import { ProductContext } from '../ProductSettingsStack';

export const Variants: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const productContext = useContext(ProductContext);
  const params =
    productContext.params ||
    (route.params as {
      productId: string;
      isVariant: boolean;
      isVariantProduct: boolean;
    });

  useEffect(() => {
    navigation.setParams(params);
  }, [params, navigation]);

  const productId = !(params?.isVariant || params?.isVariantProduct)
    ? params?.productId
    : '';

  const variantId = params?.isVariant ? params?.productId : '';

  const variantProductId = params?.isVariantProduct ? params?.productId : '';

  return (
    (variantId && <VariantSettingsForVariant variantId={variantId} />) ||
    (productId && <VariantSettingsForProduct productId={productId} />) ||
    (variantProductId && (
      <VariantSettingsForVariantProduct variantProductId={variantProductId} />
    )) ||
    null
  );
};
