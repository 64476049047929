import { StyleFn } from '@hitz-group/domain';
import React from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modals/Modal';
import FormInput from '../../../../../../components/FormInput/FormInput';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import IconButton from '../../../../../../components/Button/IconButton';
import { useNotification } from '../../../../../../hooks/Notification';
import { useTranslation } from '@hitz-group/localization';

const modalContainerStyle: StyleFn = ({}) => ({
  width: 360,
  padding: 20,
  borderRadius: 10,
});

const headerTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const buttonsContainerStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  height: 44,
  marginTop: theme.spacing.medium,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  height: 44,
  borderRadius: theme.radius.small,
  marginLeft: 10,
  marginTop: theme.spacing.medium,
  paddingHorizontal: 10,
});

const formInputTextStyle: StyleFn = ({}) => ({
  alignSelf: 'flex-start',
  paddingLeft: 10,
});

const storeIdContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 310,
});

const webhookURLContainerStyle: StyleFn = ({}) => ({
  width: 310,
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
  marginBottom: 8,
  marginLeft: 10,
});

interface IntegrationWebhookInfoModalProps {
  webhookUrl: string;
  storeId: string;
  storeName: string;
}

const IntegrationWebhookInfoModal: React.FC<
  IntegrationWebhookInfoModalProps
> = ({ webhookUrl, storeId, storeName }) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();

  async function copyTextToClipboard(text: string, message: string) {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      showNotification({ message, success: true });
    }
  }

  return (
    <Modal
      title={storeName}
      contentStyle={css(modalContainerStyle)}
      headerTextStyle={css(headerTextStyle)}
      showCloseButton={false}
    >
      <FormInput
        title={translate('backOfficeFeatures.webhookURL')}
        testID="webhookUrl"
        value={webhookUrl}
        containerStyle={css(webhookURLContainerStyle)}
        textStyle={css(formTextStyle)}
        action={
          <IconButton
            icon="copy"
            iconSize={20}
            containerSize={34}
            iconColor={theme.colors.blue}
            testID="webhookURL-copy"
            onPress={() =>
              copyTextToClipboard(
                webhookUrl,
                translate('backOfficeFeatures.webhookURLCopied'),
              )
            }
          />
        }
      />
      <FormInput
        title={translate('backOfficeFeatures.storeID')}
        value={storeId}
        alignTitle={'left'}
        containerStyle={css(storeIdContainerStyle)}
        textStyle={css(formInputTextStyle)}
        maxLength={50}
        testID="store-id"
        action={
          <IconButton
            icon="copy"
            testID="storeId-copy"
            iconSize={20}
            containerSize={34}
            iconColor={theme.colors.blue}
            onPress={() =>
              copyTextToClipboard(
                storeId,
                translate('backOfficeFeatures.storeIDCopied'),
              )
            }
          />
        }
      />

      <View style={css(buttonsContainerStyle)}>
        <Button
          title={translate('form.dismiss')}
          containerStyle={css(buttonStyle)}
          color={theme.colors.blue}
          onPress={closeModal}
          testID="dismiss"
        />
      </View>
    </Modal>
  );
};

export default IntegrationWebhookInfoModal;
