import { useTranslation } from '@hitz-group/localization';
import { Theme, User } from '@hitz-group/domain';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { withTheme } from 'react-fela';
import {
  StyleSheet,
  View,
  Text,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import PopupView from '../../../components/PopupView/PopupView';
import Layout from '../../../components/POSLayout/POSLayout';
import scale from '../../../common/theme';
import { useLogin } from '../../../hooks/app/useLogin';
import Gradient from '../../../components/Gradient/Gradient';
import { useFela } from 'react-fela';
import { Session } from '../../../state/Session';
import { userUtility } from '../../../state/userUtility';
import { navigateToLockScreen } from '../../../state/navigation';
import { useLogout } from '../../../hooks/app/useLogout';
import { useNotification } from '../../../hooks/Notification';
import CodeInput from '../../../../src/components/DeviceCode/CodeInput';
import IconButton from '../../../../src/components/Button/IconButton';
import { LoadingScreen } from '../../Loading/Loading';
import useRolesContext from '../../../hooks/app/users/useRolesContext';

export interface DeviceCodeLoginScreenProps {
  theme: Theme;
}

export interface State {
  deviceCode: {
    email: string;
  };
  isLoggedIn?: boolean;
  empPassCode: string;
  errorMessage: string;
  isFormComplete: boolean;
  hidePassword: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getStyles = (theme: Theme) => {
  return StyleSheet.create({
    screen: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    deviceCodeViewStyle: {
      width: 700,
      alignItems: 'center',
      marginTop: 5,
      paddingHorizontal: 40,
    },
    header: {
      ...theme.font14Medium,
      color: theme.colors.blue,
      fontSize: 16,
      alignSelf: 'center',
      marginRight: 38,
    },
    buttonContainer: {
      width: 280,
      marginTop: 30,
      justifyContent: 'center',
      alignItems: 'stretch',
    },
    button: {
      marginTop: scale.moderateScale(10),
      overflow: 'hidden',
    },
    popupStyle: {
      height: 375,
      width: 700,
      borderRadius: theme.radius.large,
    },
    container: {
      marginTop: 15,
    },
    descriptionText: {
      textAlign: 'center',
      paddingVertical: scale.moderateScale(10),
      ...theme.font14Medium,
      color: theme.colors.charcoal,
    },
    marginTop2: {
      marginTop: 10,
    },
    textInputContainer: {
      width: 440,
    },
    containerStyle: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mt40: {
      marginTop: 40,
    },
    titleView: {
      flex: 1,
      justifyContent: 'center',
    },

    headerView: {
      width: '100%',
      flexDirection: 'row',
    },
    leftAngle: {
      height: 38,
      alignContent: 'flex-start',
    },
  });
};

export const CODE_IN_USE_ERROR =
  'Code already in Use, Contact your administrator';

const DeviceCodeLogin: React.FC<DeviceCodeLoginScreenProps> = ({
  ...props
}: DeviceCodeLoginScreenProps) => {
  const { theme } = useFela();
  const [, setErrorMessage] = useState('');
  const { translate } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const styles = useMemo(() => getStyles(props.theme), [props.theme]);
  const { fetchRolesSync, updateRoles } = useRolesContext();
  const { loginByDeviceCode, loading, error } = useLogin();
  const { logout } = useLogout();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message:
          error === CODE_IN_USE_ERROR
            ? translate('deviceCodeLogin.codeInUseErr')
            : translate('deviceCodeLogin.invalidCode'),
      });
    }
  }, [error, showNotification, translate]);

  const fetchAndUpdateRoles = useCallback(async () => {
    const roles = await fetchRolesSync();
    updateRoles(roles);
  }, [fetchRolesSync, updateRoles]);

  const loginUsingDeviceCode = useCallback(
    async (value: string): Promise<void> => {
      const session = (await loginByDeviceCode(value)) as Session;
      if (session?.user) {
        setErrorMessage('');
        // FIXME: this is not a office user login, so this must be removed
        userUtility.addOfficeUser(session.user as User);
        await fetchAndUpdateRoles();
        // navigate to lock screen (pos app)
        navigation.reset(navigateToLockScreen());
      } else {
        await logout();
        showNotification({
          info: true,
          message: translate('deviceCodeLogin.accessDenied'),
        });
      }
    },
    [
      loginByDeviceCode,
      navigation,
      logout,
      showNotification,
      translate,
      fetchAndUpdateRoles,
    ],
  );

  const onPressBackButton = useCallback((): void => {
    setErrorMessage('');
    navigation.push('Login');
  }, [navigation, setErrorMessage]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <React.Fragment>
      <Layout
        testID="DeviceCodeLoginScreen"
        title={translate('navigation.loginPageTitle', {
          appName: translate('appName'),
        })}
        hasHeader={false}
        navigation={navigation}
        useSafeArea={false}
      >
        <Gradient
          colors={[theme.colors.brandPrimary, theme.colors.brandSecondary]}
          style={styles.screen}
          start={theme.gradient.startAxis}
          end={theme.gradient.endAxis}
          locations={theme.gradient.location}
        >
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={styles.containerStyle}
          >
            <View
              accessible
              accessibilityLabel="sign in with device code"
              style={styles.screen}
            >
              <PopupView containerStyle={styles.popupStyle}>
                <View style={styles.deviceCodeViewStyle}>
                  <View style={styles.headerView}>
                    <IconButton
                      icon="AngleLeft"
                      iconSize={24}
                      containerSize={34}
                      containerStyle={styles.leftAngle}
                      iconColor={theme.colors.paragraph}
                      testID="login"
                      onPress={onPressBackButton}
                    />
                    <View style={styles.titleView}>
                      <Text style={styles.header}>
                        {translate('deviceCodeLogin.title')}
                      </Text>
                    </View>
                  </View>

                  <CodeInput
                    noOfInputs={8}
                    onEnterDeviceCode={loginUsingDeviceCode}
                  />
                  <View style={styles.mt40}>
                    <Text style={styles.descriptionText}>
                      {translate('deviceCodeLogin.description')}
                    </Text>
                    <Text style={styles.descriptionText}>
                      {translate('deviceCodeLogin.steps')}
                    </Text>
                  </View>
                </View>
              </PopupView>
            </View>
          </KeyboardAvoidingView>
        </Gradient>
      </Layout>
    </React.Fragment>
  );
};

export default withTheme(DeviceCodeLogin);
