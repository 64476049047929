import { Order, PaymentType, RoundOffValue } from '@hitz-group/domain';
import { roundOffByValue } from '@hitz-group/client-utils';
import { convertEnumToArray } from './enumToArray';
import { Session } from '../state/Session';

//TODO Add it in a common place
const CASH_PAYMENT_TYPE = 'cash';

export const getRoundOffValue = (roundOffValue: string | undefined) => {
  const roundOffSelector = roundOffValue || '1';
  const roundOffOptions = convertEnumToArray(RoundOffValue);
  const roundOffValueRes = roundOffSelector
    ? parseFloat(
        roundOffOptions.find(elem => elem.key == roundOffSelector)?.value ||
          '1',
      )
    : 1;
  return roundOffValueRes;
};

export const setTotalPriceForRoundOff = (
  order: Order | undefined,
  paymentType: PaymentType,
  session: Session,
): number | undefined => {
  const enableRoundOff =
    session?.currentStore?.checkoutOptions?.enableRoundOff || false;

  const roundOffValue = getRoundOffValue(
    session?.currentStore?.checkoutOptions?.roundOffValue,
  );

  if (paymentType.name.toLowerCase() == CASH_PAYMENT_TYPE && enableRoundOff) {
    return roundOffByValue(order?.totalPrice || 0, roundOffValue || 1);
  }

  return undefined;
};

export const getRoundingAmount = (
  session: Session,
  paymentType: PaymentType,
  order?: Order,
) => {
  let roundingAmount = 0;
  if (order) {
    roundingAmount = order.roundingAmount || 0;
    const roundedTotalPrice = setTotalPriceForRoundOff(
      order,
      paymentType,
      session,
    );
    roundingAmount = roundedTotalPrice
      ? roundedTotalPrice - order.totalPrice
      : 0;

    return roundingAmount;
  }
  return roundingAmount;
};
