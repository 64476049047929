import LogRocket from '@logrocket/react-native';
import { Session } from '../state/Session';

// build user info for logrocket traces
export const buildUserInfo = (session: Session) => ({
  name: session.currentOrganization?.name || 'NA',
  userName: session.user?.name || 'NA',
  email: session.user?.email || 'NA',
  organization: session.currentOrganization?.id || 'NA',
});

export const identifyLogRocketUser = (session: Session) => {
  LogRocket.identify(session.user?.id || 'NA', buildUserInfo(session));
};

export const TRACKING_EVENTS = {
  POS_MISSING_EVENTS_OCCURRED: 'POS missing events occurred',
  POS_MISSING_EVENTS_HANDLING_FAILED: 'POS missing events handling failed',
};
