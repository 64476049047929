import React, { useState, forwardRef } from 'react';
import { useFela } from 'react-fela';
import { StyleFn, Icons, Colors } from '@hitz-group/domain';
import DatePickerComponent, { ReactDatePickerProps } from 'react-datepicker';
import Button from '../Button/Button';
import { formatDate } from '../../utils/dateHelper';
import 'react-datepicker/dist/react-datepicker.css';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  onClick?(): void;
  onChange?: (date: Date) => void;
  placeholder?: string;
  testID?: string;
}

const dateRangeButtonStyle: StyleFn = ({ theme }) => ({
  width: 300,
  height: theme.input.height,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  marginRight: 0,
  justifyContent: 'flex-end',
  bottom: 10,
});

const iconContainerStyle: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  right: 0,
  width: 44,
});

const buttonLabelStyle: StyleFn = ({ theme }) => ({
  textAlign: 'left',
  textTransform: 'capitalize',
  fontFamily: theme.font.regular,
  paddingHorizontal: theme.spacing.small,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
});

const DatePicker = ({ placeholder, testID, onChange }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>();
  const { css } = useFela();

  const handleChange = (date: Date) => {
    setStartDate(date as Date);
    onChange && onChange(date);
  };

  // eslint-disable-next-line react/display-name
  const CustomInput = forwardRef<HTMLButtonElement, Props>(props => (
    <Button
      testID={testID}
      title={startDate ? formatDate(startDate) : placeholder}
      containerStyle={css(dateRangeButtonStyle)}
      size="medium"
      icon={Icons.AngleDown}
      iconPosition="right"
      iconContainerStyle={css(iconContainerStyle)}
      iconProps={{ color: Colors.black }}
      onPress={props.onClick}
      labelStyle={css(buttonLabelStyle)}
    />
  ));

  return (
    <DatePickerComponent
      selected={startDate}
      onChange={handleChange}
      customInput={<CustomInput />}
    />
  );
};

export default DatePicker;
