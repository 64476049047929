import { Text, ViewStyle, FlexAlignType, View } from 'react-native';
import React, { useEffect, useMemo } from 'react';
import { StyleFn, RenderProps } from '@hitz-group/domain';
import { FelaComponent, useFela, withTheme } from 'react-fela';
import TableRow from './TableRow';
import TablePagination from '../TablePagination/TablePagination';
export interface Column {
  title: string | React.ReactNode;
  width?: number;
  flex?: number;
  alignItems?: FlexAlignType;
  containerStyle?: ViewStyle;
}
export interface TableComponentProps {
  columns: Column[];
  renderRow: (item: React.ReactNode, index: number) => React.ReactNode;
  data: React.ReactNode[];
  columnContainerStyle?: ViewStyle;
  normalRows?: boolean;
  columnSpacing?: number;
  containerStyle?: ViewStyle;
  paginationStyle?: ViewStyle;
  pageSize?: number;
  onPageChange?: (page: number) => void;
  showPagination?: boolean;
}
export interface ColumnCellProps {
  title: string | React.ReactNode;
  width: number;
  flex?: number;
  alignItems?: FlexAlignType;
  columnSpacing?: number;
  index: number;
  containerStyle?: ViewStyle;
}

const TableNameStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  fontSize: theme.fontSize.small,
  letterSpacing: 0,
  fontFamily: theme.font.medium,
});

const titleStyle: StyleFn = ({
  width,
  flex,
  index,
  alignItems,
  columnSpacing,
}) => ({
  width: width,
  flex: flex,
  alignItems: alignItems ? alignItems : index ? 'center' : 'flex-start',
  justifyContent: index ? 'center' : 'flex-start',
  marginRight: columnSpacing || 0,
});

export const ColumnCell: React.FC<ColumnCellProps> = props => {
  const { css } = useFela();
  return (
    <FelaComponent style={titleStyle} {...props}>
      {({ style }: RenderProps): React.ReactFragment => (
        <View style={[style, props.containerStyle]}>
          <Text style={css(TableNameStyle)}>{props.title}</Text>
        </View>
      )}
    </FelaComponent>
  );
};

const TableComponent: React.FC<TableComponentProps> = ({
  columns = [],
  renderRow,
  data,
  columnContainerStyle,
  normalRows,
  columnSpacing,
  containerStyle,
  paginationStyle,
  pageSize,
  onPageChange,
  showPagination,
}: TableComponentProps) => {
  const itemsPerPage = pageSize || (data?.length < 10 ? data.length : 10);
  const dataInPageInitial = data && data.slice(0, itemsPerPage);
  const [pageData, setPageData] = React.useState(dataInPageInitial);
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    onPageChange && onPageChange(page);
    const itemsDataInPageIncremental = data?.slice(
      (page - 1) * itemsPerPage,
      page * itemsPerPage,
    );
    setPageData(itemsDataInPageIncremental);
  }, [page, data, itemsPerPage, onPageChange]);

  const onPageNumberPress = (page: number): void => {
    setPage(page);
  };

  const columnCells = useMemo(
    () =>
      columns.map((x, i) => (
        <ColumnCell
          key={i}
          index={i}
          width={x.width || 80}
          title={x.title}
          flex={x.flex}
          alignItems={x.alignItems}
          // column spacing for right most element making 0
          columnSpacing={columns.length > i + 1 ? columnSpacing : 0}
          containerStyle={x.containerStyle}
        />
      )),
    [columns, columnSpacing],
  );

  const dataList = showPagination ? pageData : data;

  return (
    <View style={containerStyle}>
      {columnCells && (
        <TableRow containerStyle={columnContainerStyle} normalRows={normalRows}>
          {columnCells}
        </TableRow>
      )}
      {dataList.map((x, i) => renderRow(x, i))}
      {showPagination && data?.length > itemsPerPage && (
        <View style={paginationStyle}>
          <TablePagination
            isGreyPagination
            page={page}
            numberOfPages={Math.ceil(data?.length / itemsPerPage)}
            startIndex={(page - 1) * itemsPerPage}
            endIndex={page * itemsPerPage}
            total={data?.length}
            onPageChange={(page: number): void => {
              onPageNumberPress(page);
            }}
          />
        </View>
      )}
    </View>
  );
};

export default withTheme(TableComponent);
