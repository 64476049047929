import React from 'react';
import { TaxesScreen } from './Taxes/TaxesScreen';
import { FeesSection } from './Fees/FeesSection';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';

const Tab = createMaterialTopTabNavigator();

// not a stack anymore, rename it to just TaxesAndFees
const TaxesAndFeesStack: React.FC = () => {
  return (
    <Tab.Navigator
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName="Taxes"
    >
      <Tab.Screen name="Taxes" component={TaxesScreen} />
      <Tab.Screen name="Fees" component={FeesSection} />
    </Tab.Navigator>
  );
};

export default TaxesAndFeesStack;
