import {
  getAvailableLanguages,
  Locale,
  useLocalization,
  useTranslation,
} from '@hitz-group/localization';
import { StyleFn, Icons, UpdateDeviceInput } from '@hitz-group/domain';
import React, { useCallback, useMemo } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import * as settings from '../../state/preferences';
import Accordion from '../Accordion/Accordion';
import SettingsItem from '../SettingsItem/SettingsItem';
import SettingsSegment from '../SettingsSegment/SettingsSegment';
import Logo from '../../components/Logo/Logo';
import ConfirmationModal from '../../components/Modals/ConfirmationDialog';
import { useModal } from '@hitz-group/rn-use-modal';
import Button from '../../components/Button/Button';
import IconButton from '../../components/Button/IconButton';
import { useSession } from '../../hooks/app/useSession';
import { useDeviceInfo } from '../../hooks/app/useDeviceInfo';
import { useRestart } from '../../hooks/app/useRestart';
import Spinner from '../../components/Spinner/Spinner';
import { useSettingsSync } from '../../hooks/app/useSettingsSync';
import { useNavigation } from '@react-navigation/native';
import { useDevices } from '../../hooks/app/useDevices';
import { useDeviceId } from '../../hooks/app/useDeviceId';
import pick from 'lodash/pick';

const contactNumber = '02 9556 3566';

const logoStyle: StyleFn = () => ({
  width: 54,
  height: 54,
  alignSelf: 'center',
});

const buttonTextStyle: StyleFn = ({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.colors.secondary,
  fontSize: theme.fontSize.medium,
});

const buttonStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big / 2,
});

const optionsStyle: StyleFn = ({ theme }) => ({
  marginVertical: theme.spacing.medium,
  borderRadius: theme.radius.small,
  width: '97.5%',
  marginLeft: '2.5%',
});

const textStyle: StyleFn = ({ theme }) => ({
  color: 'grey',
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
  letterSpacing: -0.5,
  textAlign: 'center',
  lineHeight: 24,
  width: 500,
  marginTop: theme.spacing.big,
  alignSelf: 'center',
});
const dropDownIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});
const languageText: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.regular,
});

const iconContainerStyle: StyleFn = ({ theme }) => ({
  width: 60,
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
  alignSelf: 'center',
  marginRight: -theme.spacing.small,
});

const editMenuTileStyle: StyleFn = ({ theme }) => ({
  height: 44,
  marginTop: theme.spacing.small,
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.small,
  paddingLeft: theme.padding.large,
  justifyContent: 'center',
  shadowOpacity: 0,
  shadowRadius: 0,
});

const availableLocales = getAvailableLanguages();

const SettingsAccountTab: React.FC = () => {
  const { showModal } = useModal();
  const { css, theme } = useFela();
  const { locale: currentLocale } = useLocalization();
  const { translate } = useTranslation();
  const { versionNum } = useDeviceInfo();
  const { restartDevice } = useRestart();
  const [session, setSession] = useSession();
  const navigation = useNavigation();
  const { loading: syncLoading, syncApp } = useSettingsSync();
  const { deviceId } = useDeviceId();
  const currentDevice = session?.currentStore?.devices?.find(
    device => device.uuid === deviceId,
  );
  const { updateDevice } = useDevices({
    deviceId: currentDevice?.id,
    storeId: session?.currentStore?.id,
  });

  const sync = (): void => {
    syncApp();
  };

  const deviceProfiles = useMemo(
    () => session?.currentStore?.deviceProfiles || [],
    [session],
  );

  const changeLanguage = useCallback(
    (locale: Locale): void => {
      settings.setLocale(locale);
      restartDevice();
    },
    [restartDevice],
  );

  const onChangeLocale = useCallback(
    (locale: Locale): void => {
      showModal(
        <ConfirmationModal
          title={translate('settings.languagePopUpPromptHeader')}
          message={translate('settings.languagePopUpPromptBody')}
          onConfirm={(): void => {
            changeLanguage(locale);
          }}
        />,
      );
    },
    [showModal, translate, changeLanguage],
  );

  const toggleScrollSetting = useCallback((): void => {
    setSession({
      ...session,
      settings: {
        ...session.settings,
        showScrollSetting: !session?.settings?.showScrollSetting,
      },
    });
  }, [session, setSession]);

  const toggleCartKeypadSetting = useCallback((): void => {
    setSession({
      ...session,
      settings: {
        ...session.settings,
        showAdvancedCartActionsSetting:
          !session?.settings?.showAdvancedCartActionsSetting,
      },
    });
  }, [session, setSession]);

  const toggleQuickPaymentdSetting = useCallback((): void => {
    setSession({
      ...session,
      settings: {
        ...session.settings,
        enableQuickPaymentModeSetting:
          !session?.settings?.enableQuickPaymentModeSetting,
      },
    });
  }, [session, setSession]);

  const updateDeviceProfileOnSession = useCallback(
    (deviceProfileId: string): void => {
      const deviceProfile = deviceProfiles.find(
        deviceProfile => deviceProfile.id == deviceProfileId,
      );
      if (deviceProfile) {
        const updatedSession = {
          ...session,
          deviceProfile: {
            ...deviceProfile,
            defaultOrderType:
              deviceProfile?.defaultOrderType || deviceProfile?.orderTypes?.[0],
          },
        };
        setSession(updatedSession);
      }
    },
    [session, deviceProfiles, setSession],
  );

  const onchangeDeviceProfile = useCallback(
    (deviceProfileId: string): void => {
      let input = {
        ...currentDevice,
        deviceProfile: deviceProfileId,
      } as unknown as UpdateDeviceInput;
      if (!input.cashDrawer) input.cashDrawer = 'default';
      if (!input.paymentTerminal) input.paymentTerminal = 'default';
      input = pick(input, [
        'id',
        'name',
        'salesPrefix',
        'returnPrefix',
        'deviceProfile',
        'paymentTerminal',
        'cashDrawer',
      ]) as unknown as UpdateDeviceInput;

      updateDevice(input as unknown as UpdateDeviceInput);
      updateDeviceProfileOnSession(deviceProfileId);
    },
    [currentDevice, updateDevice, updateDeviceProfileOnSession],
  );

  const accordionTitle = useMemo(
    () => (
      <SettingsItem
        title={translate('settings.language')}
        value={currentLocale.label}
        testID="settings-accordion-parent"
      />
    ),
    [currentLocale, translate],
  );

  return (
    <View>
      <SettingsSegment title={'Device Profile'}>
        {deviceProfiles.map((deviceProfile, index) => {
          return (
            <SettingsItem
              testID="deviceProfiles"
              key={index}
              title={deviceProfile.name}
              onPress={(): void => onchangeDeviceProfile(deviceProfile.id)}
              contentRight={
                <IconButton
                  primary
                  icon={
                    session?.deviceProfile?.id == deviceProfile.id
                      ? 'check'
                      : ''
                  }
                  iconSize={26}
                  iconColor={theme.colors.green}
                  containerSize={34}
                  containerStyle={css(iconContainerStyle)}
                />
              }
            />
          );
        })}
      </SettingsSegment>
      <SettingsSegment title={'Appearance'}>
        <SettingsItem
          testID="showScroll"
          title={translate('settings.scrollButtons')}
          contentRight={
            <IconButton
              testID="showScrollToggle"
              primary
              icon={
                (session?.settings?.showScrollSetting && 'toggle-on') ||
                'toggle-off'
              }
              iconSize={26}
              iconColor={
                session?.settings?.showScrollSetting
                  ? theme.colors.green
                  : theme.colors.paragraph
              }
              containerSize={34}
              containerStyle={css(iconContainerStyle)}
              onPress={toggleScrollSetting}
            />
          }
        />
        <SettingsItem
          testID="showAdvancedCartActions"
          title={translate('settings.advancedCartActions')}
          contentRight={
            <IconButton
              testID="showAdvancedCartActionsToggle"
              primary
              icon={
                (session?.settings?.showAdvancedCartActionsSetting &&
                  'toggle-on') ||
                'toggle-off'
              }
              iconSize={26}
              iconColor={
                session?.settings?.showAdvancedCartActionsSetting
                  ? theme.colors.green
                  : theme.colors.paragraph
              }
              containerSize={34}
              containerStyle={css(iconContainerStyle)}
              onPress={toggleCartKeypadSetting}
            />
          }
        />
        <SettingsItem
          testID="enableQuickPaymentMode"
          title={translate('settings.enableQuickPaymentMode')}
          contentRight={
            <IconButton
              testID="enableQuickPaymentModeToggle"
              primary
              icon={
                (session?.settings?.enableQuickPaymentModeSetting &&
                  'toggle-on') ||
                'toggle-off'
              }
              iconSize={26}
              iconColor={
                session?.settings?.enableQuickPaymentModeSetting
                  ? theme.colors.green
                  : theme.colors.paragraph
              }
              containerSize={34}
              containerStyle={css(iconContainerStyle)}
              onPress={toggleQuickPaymentdSetting}
            />
          }
        />

        <SettingsItem
          testID="draggableEditMenuLayout"
          title={translate('menuEditor.editMenuLayout')}
          onPress={(): void => navigation.navigate('POSMenuEditor')}
          customContainerStyle={css(editMenuTileStyle)}
          contentRight={
            <IconButton
              primary
              icon={Icons.AngleRight}
              iconSize={26}
              iconColor={theme.colors.black}
              containerSize={34}
              containerStyle={css(iconContainerStyle)}
            />
          }
        />
      </SettingsSegment>
      <SettingsSegment title={translate('settings.accountSettings')}>
        <Accordion accordionTitle={accordionTitle}>
          <View style={css(optionsStyle)}>
            {availableLocales.map((locale, index) => {
              return (
                <SettingsItem
                  title={locale.label}
                  key={index}
                  testID={`settings-accordion-select-${index}`}
                  icon={
                    locale.languageTag === currentLocale.languageTag
                      ? 'check'
                      : undefined
                  }
                  onPress={onChangeLocale.bind(null, locale)}
                  iconStyle={css(dropDownIconStyle)}
                  titleTextStyle={css(languageText)}
                />
              );
            })}
          </View>
        </Accordion>
        <SettingsItem
          testID="account-settings-contact"
          title={translate('settings.contactSupport')}
          value={contactNumber}
        />
        <SettingsItem
          testID="App_version"
          title={translate('settings.appVersion')}
          value={versionNum}
        />
        <Button
          size="small"
          testID="Sync_button"
          title={translate('settings.syncApp')}
          fluid
          containerStyle={css(buttonStyle)}
          labelStyle={css(buttonTextStyle)}
          onPress={sync}
        />
      </SettingsSegment>
      <Text style={css(textStyle)}>{translate('settings.from')}</Text>
      <Logo style={css(logoStyle)} testID="App-Logo" />
      <Spinner isLoading={syncLoading} testID="Spinner" />
    </View>
  );
};

export default SettingsAccountTab;
