import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';

export const Styles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        filtersStyle: {
          width: 265,
          flexDirection: 'column',
          height: 'calc(100vh - 135px)',
          position: 'absolute',
          zIndex: 1,
        },
        mainSectionStyle: {
          width: 1200,
          height: '100%',
          flexDirection: 'column',
          margin: 'auto',
        },
        headersStyle: {
          zIndex: 900,
          width: '100%',
          height: 130,
          alignSelf: 'flex-start',
        },
        chartRowStyle: {
          zIndex: 800,
          display: 'flex',
          flexGrow: 1,
          width: '100%',
          height: 200,
          flexDirection: 'row',
          alignSelf: 'flex-start',
        },
        tableStyle: {
          width: '100%',
          height: '100%',
          zIndex: 800,
          flex: 1,
          minHeight: 350,
        },
      }),
    [theme],
  );
};
