/* eslint-disable react-native/no-raw-text */
import { StyleFn, TimeRoutine } from '@hitz-group/domain';
import {
  PricingGroup,
  SELECT_ALL,
  TimeBlock,
  DateRange,
} from '@hitz-group/domain';
import React, { useCallback, useMemo } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View } from 'react-native';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import { useTranslation } from '@hitz-group/localization';
import FormInput, {
  Label,
} from '../../../../../components/FormInput/FormInput';
import Button from '../../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import scale, { isWeb } from '../../../../../common/theme';
import { TimeRoutineRow } from './TimeRoutineRow';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import DatePicker from '../../../../../components/DatePicker/DatePicker';
import { formatDate } from '../../../../../utils/dateHelper';

const BackOfficeContainerStyle: StyleFn = ({ theme }) => ({
  paddingBottom: theme.padding.large,
  width: 620,
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});
export const formStyle: StyleFn = ({ theme }) => ({
  width: scale.backOfficeWidth400,
  alignSelf: 'center',
  paddingBottom: theme.padding.medium * 3,
  alignItems: 'center',
});
export const innerContainer: StyleFn = () => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
export const innerContainerDate: StyleFn = () => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
export const innerContainerDiv: StyleFn = () => ({
  width: '40%',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'flex-end',
});
export const width100row: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20,
  zIndex: 999,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme, selected }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: selected ? theme.colors.successLight : theme.colors.border,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme, selected }) => ({
  color: selected ? theme.colors.success : theme.colors.paragraph,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 300,
  height: theme.input.height,
  marginBottom: 20,
});

const storeContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginBottom: 25,
  zIndex: 999,
  width: 300,
  minHeight: theme.input.height,
});

const orderTypeContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 300,
  minHeight: theme.input.height,
});
const venueDropdownContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginBottom: 25,
  width: 300,
  zIndex: 999,
  minHeight: theme.input.height,
});

const touchableStyle: StyleFn = ({ theme }) => ({
  minHeight: theme.input.height,
  flexWrap: 'wrap',
  width: 300,
});

const labelStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  padding: theme.spacing.small,
  marginVertical: theme.spacing.medium,
});
const datePickerStyle: StyleFn = () => ({
  zIndex: -1,
});

export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(170),
});

const deleteButtonStyle: StyleFn = ({ theme, selected }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  borderRadius: theme.radius.small,
  backgroundColor: selected ? theme.colors.danger2 : theme.colors.border,
  alignSelf: 'auto',
});

const deleteTitleStyle: StyleFn = ({ theme, selected }) => ({
  color: selected ? theme.colors.red : theme.colors.paragraph,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});
export const dropdownStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  justifyContent: 'space-between',
  width: 252,
  height: 44,
});
export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: scale.textInputWidth180,
  height: scale.moderateScale(30),
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});
export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? scale.moderateScale(180) : '50%',
  marginTop: scale.moderateScale(2),
});
export const FieldContainerStyle: StyleFn = ({ theme }) => ({
  width: 100,
  height: scale.moderateScale(30),
  marginBottom: theme.spacing.big / 2,
});
const selectPlaceHolderStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.primary,
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.regular,
  lineHeight: theme.input.height,
  letterSpacing: -0.5,
});

interface DetailFormProps {
  pricingGroupData: PricingGroup;
  onChange: (
    prop: string,
    value: string | boolean | string[] | TimeBlock | DateRange,
  ) => void;
  venueOptions: { label: string; value: string }[];
  storeOptions: { label: string; value: string }[];
  orderTypeOptions: { label: string; value: string }[];
  channelOptions: { label: string; value: string }[];
  onSave: () => void;
  onDelete: () => void;
  isDefault: boolean;
  onCreateTimeSlot: () => void;
  onDeleteTimeSlot: (id: number) => void;
}
export const DetailForm: React.FC<DetailFormProps> = ({
  pricingGroupData,
  storeOptions,
  venueOptions,
  orderTypeOptions,
  onChange,
  onSave,
  onDelete,
  isDefault,
  onCreateTimeSlot,
  onDeleteTimeSlot,
}) => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { dateRange, timeBlocks } = pricingGroupData.schedule || {};
  const COLUMNS = useMemo(
    () => [
      {
        title: translate('pricings.weekDays'),
        width: 300,
        containerStyle: {
          marginLeft: 0,
        },
      },
      {
        title: translate('pricings.startTime'),
        width: 115,
        containerStyle: {
          paddingLeft: 10,
        },
      },
      {
        title: translate('pricings.endTime'),
        width: 110,
        containerStyle: {
          paddingLeft: 10,
        },
      },
      { width: 50 },
    ],
    [translate],
  );

  const handleDateRange = useCallback(
    (id: string, date: Date) => {
      if (id === 'fromDateRange') {
        onChange('dateRange', {
          startDate: new Date(date).valueOf(),
          endDate: dateRange ? dateRange.endDate : undefined,
        });
      } else {
        onChange('dateRange', {
          startDate: dateRange ? dateRange.startDate : Date.now(),
          endDate: new Date(date).valueOf(),
        });
      }
    },
    [dateRange, onChange],
  );

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalPriceListPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView testID="overview-screen" style={css(pageStyle)}>
        <View style={css(width100row)}>
          <BackOfficeSection
            title="General"
            contentContainerStyle={css(BackOfficeContainerStyle)}
            iconColor={theme.colors.blue}
            icon={'info-circle'}
          >
            <FormInput
              error={false}
              placeholder={translate('pricings.name')}
              title={translate('pricings.name')}
              value={pricingGroupData?.name || ''}
              alignTitle="left"
              containerStyle={css(formInputContainerStyle)}
              onChangeText={onChange.bind(null, 'name')}
              editable={!isDefault}
            />
            <MultipleSelect
              label={translate('pricings.venues')}
              placeholder={translate('pricings.venues') + '...'}
              values={[
                { label: translate('pricings.allVenues'), value: SELECT_ALL },
              ].concat(venueOptions)}
              selectedValues={(pricingGroupData?.venues || []).map(x => x.id)}
              containerStyle={css(venueDropdownContainerStyle)}
              touchableStyle={css(touchableStyle)}
              onValueChange={onChange.bind(null, 'venues')}
              searchLabel={translate('pricings.searchVenues') + ':'}
              searchPlaceHolder={translate('pricings.searchVenues') + '...'}
              maxItems={3}
              showCheckboxes={true}
              placeHolderStyle={css(selectPlaceHolderStyle)}
            />

            <MultipleSelect
              label={translate('pricings.stores')}
              placeholder={translate('pricings.searchStores') + '...'}
              values={[
                { label: translate('pricings.allStores'), value: SELECT_ALL },
              ].concat(storeOptions)}
              selectedValues={(pricingGroupData?.stores || []).map(x => x.id)}
              containerStyle={css(storeContainerStyle)}
              touchableStyle={css(touchableStyle)}
              onValueChange={onChange.bind(null, 'stores')}
              searchLabel={translate('pricings.searchStores') + ':'}
              searchPlaceHolder={translate('pricings.searchStores') + '...'}
              maxItems={3}
              showCheckboxes={true}
              placeHolderStyle={css(selectPlaceHolderStyle)}
            />

            <MultipleSelect
              label={translate('pricings.orderTypes')}
              placeholder={translate('pricings.orderTypes') + '...'}
              values={[
                {
                  label: translate('pricings.allOrderTypes'),
                  value: SELECT_ALL,
                },
              ].concat(orderTypeOptions)}
              selectedValues={(pricingGroupData?.orderTypes || []).map(
                x => x.id,
              )}
              containerStyle={css(orderTypeContainerStyle)}
              touchableStyle={css(touchableStyle)}
              onValueChange={onChange.bind(null, 'orderTypes')}
              searchLabel={translate('pricings.orderTypes') + ':'}
              searchPlaceHolder={translate('pricings.orderTypes') + '...'}
              maxItems={3}
              showCheckboxes={true}
              placeHolderStyle={css(selectPlaceHolderStyle)}
            />
          </BackOfficeSection>
          <BackOfficeSection
            title="Schedule"
            contentContainerStyle={css(BackOfficeContainerStyle)}
            titleBorderBottom
            actionContainerStyle={css(datePickerStyle)}
            action={
              <BackOfficeCreateNewButton onPress={() => onCreateTimeSlot()} />
            }
          >
            <View style={css(innerContainerDate)}>
              <View>
                <Label textStyle={css(labelStyle)}>{`${translate(
                  'pricings.validFromDate',
                )}`}</Label>
                <View>
                  <DatePicker
                    testID="fromDateRange"
                    onChange={handleDateRange.bind(null, 'fromDateRange')}
                    placeholder={
                      dateRange
                        ? formatDate(dateRange.startDate)
                        : translate('pricings.selectDate')
                    }
                  />
                </View>
              </View>
              <View>
                <Label textStyle={css(labelStyle)}>{`${translate(
                  'pricings.validToDate',
                )}`}</Label>
                <View>
                  <DatePicker
                    testID="toDateRange"
                    onChange={handleDateRange.bind(null, 'toDateRange')}
                    placeholder={
                      dateRange && dateRange.endDate
                        ? formatDate(dateRange.endDate)
                        : translate('pricings.selectDate')
                    }
                  />
                </View>
              </View>
            </View>
            <View style={css(datePickerStyle)}>
              <TableComponent
                columns={COLUMNS}
                data={timeBlocks || []}
                renderRow={(
                  item: TimeRoutine,
                  index: number,
                ): React.ReactNode => (
                  <TimeRoutineRow
                    key={`routine-${index}`}
                    startTime={item.timeSlot.startTime}
                    endTime={item.timeSlot.endTime}
                    day={item.day}
                    onPressDelete={onDeleteTimeSlot}
                    onChange={onChange}
                    index={index}
                  />
                )}
              />
            </View>
          </BackOfficeSection>
        </View>
        <View style={css(bottomSpace)}></View>
      </ScrollView>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          {pricingGroupData.id && (
            <Button
              fluid
              testID="delete"
              title={translate('button.delete')}
              containerStyle={css(
                deleteButtonStyle({ theme, selected: !isDefault }),
              )}
              labelStyle={css(
                deleteTitleStyle({ theme, selected: !isDefault }),
              )}
              onPress={onDelete}
              disabled={isDefault}
            />
          )}
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(
              saveButtonStyle({ theme, selected: !isDefault }),
            )}
            labelStyle={css(titleStyle({ theme, selected: !isDefault }))}
            onPress={onSave}
            disabled={isDefault}
          />
        </View>
      </View>
    </>
  );
};
