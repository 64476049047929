import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { Text, View, StyleSheet, ViewStyle } from 'react-native';
import Button from '../Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';
import ModalDialog from './Modal';
import IconButton from '../Button/IconButton';
export interface AlertModalProps {
  onConfirm?: () => void;
  actionTitle?: string;
  title: string;
  message?: string;
  containerStyle?: ViewStyle;
  iconSize?: number;
  warning?: boolean;
  showDismiss?: boolean;
  showCloseButton?: boolean;
}

const headingStyle: StyleFn = ({ theme, warning }) => ({
  fontSize: theme.fontSize.large,
  lineHeight: 27,
  letterSpacing: 0,
  width: '100%',
  textAlign: 'center',
  color: warning ? theme.colors.orange : theme.colors.primary,
  fontFamily: theme.font.medium,
});

const confirmationStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.medium,
  lineHeight: 24,
  letterSpacing: -0.1,
  width: '100%',
  textAlign: 'center',
  color: theme.colors.primary,
  fontFamily: theme.font.medium,
  marginTop: theme.spacing.medium,
  marginBottom: theme.spacing.big + theme.spacing.medium,
  paddingHorizontal: theme.spacing.big + theme.spacing.small / 2,
});

const closeButtonStyle: StyleFn = () => ({
  width: 34,
  height: 34,
  right: 35,
  top: 35,
});

const actionButtonLabelStyle: StyleFn = ({ theme, warning }) => ({
  color: warning ? theme.colors.white : theme.colors.red,
  textTransform: 'uppercase',
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.bold,
  letterSpacing: 0,
});

const dismissButtonLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.paragraph,
  textTransform: 'uppercase',
  fontSize: theme.fontSize.small,
  fontFamily: theme.font.bold,
  letterSpacing: 0,
});

const alertIconStyle: StyleFn = ({ theme }) => ({
  marginTop: -theme.spacing.big,
  alignSelf: 'center',
  width: 44,
  height: 44,
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  height: 38,
  width: 100,
  marginLeft: 0,
  backgroundColor: theme.colors.danger2,
  alignSelf: 'center',
  color: theme.colors.primary,
});

const actionBtnStyle: StyleFn = ({ theme, warning }) => ({
  height: 38,
  width: 270,
  marginLeft: 0,
  backgroundColor: warning ? theme.colors.orange : theme.colors.danger2,
  color: warning ? theme.colors.white : theme.colors.primary,
  alignSelf: 'center',
});

const dismissBtnStyle: StyleFn = ({ theme }) => ({
  height: 38,
  width: 270,
  marginLeft: 0,
  backgroundColor: theme.colors.background,
  alignSelf: 'center',
  marginTop: theme.spacing.small,
});

const styles = StyleSheet.create({
  container: {
    width: 660,
  },
});

const AlertModal: React.FC<AlertModalProps> = ({
  onConfirm,
  title,
  actionTitle,
  message,
  containerStyle,
  iconSize,
  warning,
  showDismiss,
  showCloseButton,
}: AlertModalProps) => {
  const { css, theme } = useFela({ warning });
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const onPressConfirm = useCallback(() => {
    onConfirm && onConfirm();
  }, [onConfirm]);

  const onPressReject = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <ModalDialog
      onDismiss={onPressReject}
      closeButtonStyle={css(closeButtonStyle)}
      closeIconSize={20}
      showCloseButton={showCloseButton}
    >
      <IconButton
        testID={'error-icon'}
        icon="exclamationTriangle"
        iconColor={warning ? theme.colors.orange : theme.colors.red}
        iconSize={iconSize || 25}
        containerStyle={css(alertIconStyle)}
        disabled
      />

      <View style={[styles.container, containerStyle]}>
        <Text style={css(headingStyle)} testID={'error-message'}>
          {title}
        </Text>
        {message && <Text style={css(confirmationStyle)}>{message}</Text>}
        <Button
          testID={'alert-accept'}
          fluid
          color={theme.colors.danger2}
          onPress={onPressConfirm}
          labelStyle={css(actionButtonLabelStyle)}
          containerStyle={
            showDismiss
              ? actionBtnStyle({ theme, success: true, warning })
              : css(buttonContainerStyle({ theme, success: true }))
          }
          title={actionTitle || translate('dialog.yes')}
        />
        {showDismiss ? (
          <Button
            testID={'alert-dismiss'}
            fluid
            onPress={onPressReject}
            labelStyle={css(dismissButtonLabelStyle)}
            containerStyle={css(
              dismissBtnStyle({ theme, success: true, warning }),
            )}
            title={translate('button.dismiss')}
          />
        ) : null}
      </View>
    </ModalDialog>
  );
};

AlertModal.defaultProps = {
  showCloseButton: true,
};

export default AlertModal;
