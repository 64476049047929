import React, { useEffect, useMemo } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { useFela, withTheme } from 'react-fela';
import Layout from '../../../components/POSLayout/POSLayout';
import {
  DrawerButton,
  LeftArrow,
  SubscriptionStatusButton,
} from '../../../components/HeaderIcons/HeaderIcons';
import { StyleFn } from '@hitz-group/domain';
import MoneyMovementsList from '../../../components/ManageMoneyView/MoneyMovementsList';
import { useQuery } from '@apollo/client/react/hooks';
import { GET_MONEY_MOVEMENTS } from '../../../graphql/manageMoney';
import { useNotification } from '../../../hooks/Notification';
import { parseApolloError } from '../../../utils/errorHandlers';
import { useTranslation } from '@hitz-group/localization';
import Title from '../../../components/Title/Title';
import { useIsFocused } from '@react-navigation/native';
import NewOrderButton from '../../../components/NewOrder/NewOrderButton';

const loadingStyle: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});
const listStyle: StyleFn = () => ({
  flex: 1,
});

const titleContainerStyle: StyleFn = ({ theme }) => ({
  width: 400,
  backgroundColor: theme.colors.primaryDarkest,
  padding: theme.spacing.small * 1.5,
  borderRadius: theme.radius.small,
});

const ManageMoney: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const {
    data: moneyMovements,
    error: moneyMovementsError,
    refetch,
    client,
  } = useQuery(GET_MONEY_MOVEMENTS, { fetchPolicy: 'cache-and-network' });
  useEffect(() => {
    if (moneyMovementsError) {
      showNotification({
        error: true,
        message: parseApolloError(moneyMovementsError),
      });
    }
  }, [moneyMovementsError, showNotification]);

  useEffect(() => {
    if (moneyMovements) {
      client.writeQuery({
        query: GET_MONEY_MOVEMENTS,
        data: { moneymovements: moneyMovements },
      });
    }
  });

  const isFocused = useIsFocused();
  useEffect(() => {
    isFocused && refetch();
  }, [isFocused, refetch]);

  // header components
  const headerTitle = (
    <Title primary containerStyle={css(titleContainerStyle)}>
      {translate('moneyMovements.manageMoney')}
    </Title>
  );
  const headerLeft = <LeftArrow />;
  const headerRight = useMemo(
    () => (
      <>
        <SubscriptionStatusButton />
        <NewOrderButton />
        <DrawerButton />
      </>
    ),
    [],
  );
  return (
    <Layout
      hasHeader={true}
      headerTitle={headerTitle}
      headerLeft={headerLeft}
      headerRight={headerRight}
    >
      {!moneyMovements ? (
        <ActivityIndicator
          color={theme.colors.primary}
          style={css(loadingStyle)}
        />
      ) : (
        <View style={css(listStyle)}>
          <MoneyMovementsList
            data={
              moneyMovements && moneyMovements.moneyMovements
                ? moneyMovements.moneyMovements
                : []
            }
          />
        </View>
      )}
    </Layout>
  );
};

export default withTheme(ManageMoney);
