import React from 'react';
import { useTranslation } from '@hitz-group/localization';
import { useNavigation } from '@react-navigation/native';
import FormInput from '../../../../../../components/FormInput/FormInput';
import IconButton from '../../../../../../components/Button/IconButton';
import TableRow from '../../../../../../components/TableComponent/TableRow';
import DropDown from '../../../../../../components/DropDown/DropDown';
import { Printer, PrinterType } from '@hitz-group/domain';
import { PrintersListRowStyles } from '../styles/Printers.styles';
import { View } from 'react-native';
import { useFela } from 'react-fela';

interface Props {
  index: number;
  printer: Printer;
  storeId: string;
  onChange: (id: string, prop: string, value: string) => void;
  onCopy: (id: string) => void;
  onDelete: (id: string) => void;
}

const PrintersListRow: React.FC<Props> = ({
  index,
  printer,
  storeId,
  onChange,
  onCopy,
  onDelete,
}) => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const styles = PrintersListRowStyles();
  const rowStyle = {
    zIndex: 1000 - 10 * index,
  };
  const { theme } = useFela();
  return (
    <View style={rowStyle}>
      <TableRow normalRows={true} containerStyle={styles.rowContainerStyle}>
        <View style={styles.nameViewStyle}>
          <FormInput
            placeholder={translate('backOfficeSettings.printerName')}
            value={printer.name}
            containerStyle={styles.fieldInputContainerStyle}
            textStyle={styles.formTextStyle}
            onChangeText={onChange.bind(null, printer.id, 'name')}
          />
        </View>
        <View style={styles.ipAddressViewStyle}>
          {printer.printerType === 'BLUETOOTH' ? (
            <FormInput
              key="bdAddress"
              placeholder={translate('backOfficeSettings.bdAddress')}
              value={printer.bdAddress}
              containerStyle={styles.fieldInputContainerStyle}
              textStyle={styles.formTextStyle}
              onChangeText={onChange.bind(null, printer.id, 'bdAddress')}
            />
          ) : (
            <FormInput
              key="ipAddress"
              placeholder={translate('backOfficeSettings.ipAddress')}
              value={printer.ipAddress}
              containerStyle={styles.fieldInputContainerStyle}
              textStyle={styles.formTextStyle}
              onChangeText={onChange.bind(null, printer.id, 'ipAddress')}
            />
          )}
        </View>
        <View style={styles.printerTypeViewStyle}>
          <DropDown
            options={Object.values(PrinterType).map(printerType => ({
              value: printerType,
              label: printerType,
            }))}
            selectedValue={printer.printerType}
            containerStyle={styles.fieldDropDownContainerStyle}
            onValueChange={onChange.bind(null, printer.id, 'printerType')}
            itemsContainerStyle={styles.itemContainer}
            extraPopoverStyle={styles.extraPopoverStyle}
          />
        </View>
        <View style={styles.actionsViewStyle}>
          <IconButton
            icon="copy"
            iconSize={20}
            containerSize={34}
            containerStyle={styles.copyContainerStyle}
            iconStyle={styles.copyIconStyle}
            onPress={onCopy.bind(null, printer.id)}
          />
          <IconButton
            icon="TrashAlt"
            iconSize={24}
            containerSize={34}
            containerStyle={styles.closeIconContainerStyle}
            iconStyle={styles.closeIconStyle}
            onPress={onDelete.bind(null, printer.id)}
          />
          <IconButton
            icon="pen"
            iconSize={20}
            containerSize={34}
            containerStyle={styles.navigateContainerStyle}
            iconColor={theme.colors.paragraph}
            onPress={(): void => {
              navigation.navigate('PrinterDetails', {
                printerId: printer.id,
                storeId,
              });
            }}
          />
        </View>
      </TableRow>
    </View>
  );
};

export default PrintersListRow;
