import {
  IntegrationApps,
  MerchantCodeState,
  OnlineOrderingPreferences,
  TimeBlock,
  UpdateIntegrationPartnerInput,
} from '@hitz-group/domain';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import { View, ScrollView } from 'react-native';
import { useStores } from '../../../../../../../hooks/app/useStores';
import BackOfficeSection from '../../../../../../../components/BackOfficeSection/BackOfficeSection';
import { useIntegrationPartners } from '../../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { Operation } from '../../../../../../../types/Operation';
import Button from '../../../../../../../components/Button/Button';
import { useNotification } from '../../../../../../../hooks/Notification';
import LoadingIndicator from '../../../../../../../components/LoadingIndicator/LoadingIndicator';
import { useRoute } from '@react-navigation/native';
import { keyBy, mergeWith, isNull } from 'lodash';
import { stripProperties } from '../../../../../../../utils/stripObjectProps';
import FormInput from '../../../../../../../components/FormInput/FormInput';
import * as styles from './Checkout.styles';
import { REACT_APP_ONLINE_STORE_PREVIEW_URL } from 'react-native-dotenv';

export enum OrderAcceptance {
  Enable = 'Enable',
  Disable = 'Disable',
}

export const oolioPreviewPrefix =
  process.env['REACT_APP_ONLINE_STORE_PREVIEW_URL'] ||
  REACT_APP_ONLINE_STORE_PREVIEW_URL ||
  'https://tillx-online-store.vercel.app';

type IntegrationPreferences = OnlineOrderingPreferences & {
  operatingHoursMap?: { [key: string]: TimeBlock };
} & { locationId?: string; accountId?: string };

export const Checkout: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [integrationPreferences, setIntegrationPreferences] =
    useState<IntegrationPreferences>({
      merchantCode: '',
    });
  const [merchantCodeState, setMerchantCodeState] =
    useState<MerchantCodeState>();

  const route = useRoute();
  const { storeId, app } = route.params as {
    storeId: string;
    app: IntegrationApps;
  };

  const isDeliverectApp = app === IntegrationApps.DELIVERECT;

  const { css } = useFela({ isRowDisplay: isDeliverectApp });

  const {
    stores,
    loading: storesLoading,
    error: storesError,
  } = useStores({ storeId });

  const venueId = useMemo(() => {
    return storeId && stores[storeId] ? stores[storeId].venue.id : '';
  }, [storeId, stores]);

  const {
    loading: integrationLoading,
    getIntegrationPartnerSettings,
    integrationPartners: allIntegrationPartners,
    updateIntegrationPartnerSettings,
    createIntegrationPartner,
    operation,
    error: integrationErr,
    checkMerchantCodeExists,
  } = useIntegrationPartners();

  const integrationPartners = useMemo(() => {
    return keyBy(Object.values(allIntegrationPartners), 'store');
  }, [allIntegrationPartners]);

  useEffect(() => {
    if (storeId) {
      getIntegrationPartnerSettings({
        appName: app,
        store: storeId,
      });
    }
  }, [getIntegrationPartnerSettings, app, storeId]);

  const checkMerchantCode = useCallback(async () => {
    if (app === IntegrationApps.OOLIO_STORE) {
      const res = await checkMerchantCodeExists(
        IntegrationApps.OOLIO_STORE,
        integrationPreferences?.merchantCode ?? '',
        storeId,
      );
      console.log('checkMerchantCodeExists', res);
      return res;
    }
  }, [
    app,
    checkMerchantCodeExists,
    integrationPreferences?.merchantCode,
    storeId,
  ]);

  useEffect(() => {
    if (integrationPreferences?.merchantCode) {
      checkMerchantCode().then(setMerchantCodeState);
    }
  }, [checkMerchantCode, integrationPreferences?.merchantCode]);

  useEffect(() => {
    if (storeId && integrationPartners[storeId]?.preferences) {
      const preferences =
        integrationPartners[storeId]?.preferences?.onlineOrdering;

      setIntegrationPreferences({
        merchantCode: preferences?.merchantCode || '',
      });
    }
  }, [integrationPartners, storeId]);

  const loading = storesLoading || integrationLoading;

  const error = storesError || integrationErr;

  const isUpdated =
    !integrationErr && !integrationLoading && operation === Operation.UPDATE;

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeFeatures.settingsUpdatedSuccessfully', {
          appName: app,
        }),
      });
    }
  }, [isUpdated, app, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const isSettingsChanged = useCallback(() => {
    const preferences =
      integrationPartners[storeId]?.preferences?.onlineOrdering;
    return (
      preferences &&
      integrationPreferences?.merchantCode !== preferences?.merchantCode
    );
  }, [integrationPartners, integrationPreferences?.merchantCode, storeId]);

  const saveIntegrationSettings = useCallback(async () => {
    const merchantCodeStateResult = await checkMerchantCode();
    setMerchantCodeState(merchantCodeStateResult);

    if (merchantCodeStateResult && merchantCodeStateResult.exist) {
      showNotification({
        error: true,
        message: translate('backOfficeFeatures.merchantCodeExists'),
      });
      return;
    }
    const onlineOrdering =
      integrationPartners[storeId] &&
      mergeWith(
        {},
        integrationPartners[storeId].preferences?.onlineOrdering,
        {
          merchantCode: integrationPreferences?.merchantCode || '',
        },
        (sourceValue, destinationValue) =>
          isNull(destinationValue) ? sourceValue : destinationValue,
      );

    const updateSetting: UpdateIntegrationPartnerInput[] = [
      {
        id: integrationPartners[storeId]?.id,
        isActive: true,
        appName: app,
        modules: {
          onlineOrdering: true,
        },
        store: storeId,
        preferences: {
          onlineOrdering,
        },
        link: '',
        venue: venueId,
      },
    ];

    if (app === IntegrationApps.OOLIO_STORE && !integrationPartners[storeId]) {
      createIntegrationPartner(
        stripProperties({ ...updateSetting[0] }, '__typename'),
      );
    } else {
      if (!isSettingsChanged()) {
        showNotification({
          error: true,
          message: translate('backOfficeFeatures.noSettingsChanged'),
        });
        return;
      }
      updateIntegrationPartnerSettings(
        stripProperties(updateSetting, '__typename'),
      );
    }
  }, [
    checkMerchantCode,
    integrationPartners,
    storeId,
    integrationPreferences?.merchantCode,
    app,
    venueId,
    showNotification,
    translate,
    createIntegrationPartner,
    isSettingsChanged,
    updateIntegrationPartnerSettings,
  ]);

  const onChangeMainCheckout = useCallback((key, value) => {
    setIntegrationPreferences(prev => ({ ...prev, [key]: value }));
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.integrationsPage', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <ScrollView
        testID="general-settings-screen"
        contentContainerStyle={css(styles.generalSettingsContainerWrapper)}
      >
        <View style={css(styles.pageStyle)}>
          <BackOfficeSection
            title={translate('backOfficeFeatures.onlinePayments')}
            contentContainerStyle={css(styles.onlinePaymentsContentStyle)}
          >
            <FormInput
              error={false}
              testID="default-merchant-code"
              placeholder={translate('backOfficeFeatures.merchantCode')}
              title={translate('backOfficeFeatures.merchantCode')}
              value={integrationPreferences?.merchantCode?.toString()}
              alignTitle="left"
              prefix={{
                text: 'ECOMM_',
                textStyle: css(styles.prefixTextStyle),
                containerStyle: css(styles.prefixContainerStyle),
              }}
              containerStyle={css(styles.merchantCodeInputContainerStyle)}
              textStyle={css(styles.titleTextStyle)}
              onChangeText={value =>
                onChangeMainCheckout('merchantCode', value)
              }
            />
            {merchantCodeState && integrationPreferences?.merchantCode && (
              <Button
                fluid
                testID={
                  merchantCodeState?.active
                    ? 'active-button'
                    : 'inactive-button'
                }
                title={translate(
                  merchantCodeState?.active
                    ? 'backOfficeFeatures.merchantCodeActiveMessage'
                    : 'backOfficeFeatures.merchantCodeInactiveMessage',
                )}
                containerStyle={css(
                  merchantCodeState?.active
                    ? styles.activeButtonStyle
                    : styles.inactiveButtonStyle,
                )}
                labelStyle={css(
                  merchantCodeState?.active
                    ? styles.activeTitleStyle
                    : styles.inactiveTitleStyle,
                )}
                disabled={true}
              />
            )}
          </BackOfficeSection>
        </View>
      </ScrollView>

      <View style={css(styles.actionsContainerStyle)}>
        <Button
          fluid
          testID="save-changes"
          title={translate('button.saveChanges')}
          containerStyle={css(styles.saveButtonStyle)}
          labelStyle={css(styles.titleStyle)}
          onPress={saveIntegrationSettings}
        />
      </View>
    </>
  );
};
