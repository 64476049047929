import { StyleFn } from '@hitz-group/domain';
import {
  ModifierGroup as ModifierGroupDefault,
  DEFAULT_ENTITY_ID,
  UpdateModifierGroupInput,
} from '@hitz-group/domain';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useFela } from 'react-fela';
import { ScrollView, View, Text } from 'react-native';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import BackOfficeCreateNewButton from '../../../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import { useNotification } from '../../../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import { ModifierGroupRow } from './ModifierGroupRow';
import { useModifierGroups } from '../../../../../hooks/app/modifierGroups/useModifierGroups';
import { useNavigation } from '@react-navigation/native';
import find from 'lodash/find';
import AlertModal from '../../../../../components/Modals/AlertModal';
import { Operation } from '../../../../../types/Operation';
import { stripProperties } from '../../../../../utils/stripObjectProps';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';
import { useIsFocused } from '@react-navigation/native';
import scale, { isWeb } from '../../../../../common/theme';
interface ModifierGroup extends ModifierGroupDefault {
  isSelected?: boolean;
}

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});

const mainViewStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

export const width100row: StyleFn = () => ({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});
export const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

export const containerStyle: StyleFn = () => ({
  width: isWeb ? '60%' : '100%',
  alignSelf: 'center',
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});
export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(30),
});
const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const deleteButtonStyle: StyleFn = ({ theme, selected }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  borderRadius: theme.radius.small,
  backgroundColor: selected ? theme.colors.danger2 : theme.colors.border,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const deleteTitleStyle: StyleFn = ({ theme, selected }) => ({
  color: selected ? theme.colors.red : theme.colors.paragraph,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const checkBoxTitleContainer: StyleFn = ({ theme }) => ({
  width: 38,
  height: 38,
  backgroundColor: theme.colors.greyLight,
  alignItems: 'center',
  justifyContent: 'center',
});

const noModifierGroupTextStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.big / 2,
  color: theme.colors.primaryLightest,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.medium,
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  height: 17,
  width: 17,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  height: 17,
  width: 17,
});

const groupNameStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

export const ModifierGroupsTab: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const navigation = useNavigation();
  const { showModal, closeModal } = useModal();
  const isFocused = useIsFocused();
  const [modifierGroupsData, setModifierGroupsData] = useState<
    Record<string, ModifierGroup>
  >({});

  const {
    error,
    loading,
    modifierGroups,
    deleteModifierGroups,
    operation,
    updateModifierGroups,
    getAllModifierGroups,
    cloneModifierGroup,
    createdIds,
  } = useModifierGroups();

  useEffect(() => {
    isFocused && getAllModifierGroups();
  }, [getAllModifierGroups, isFocused]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.DELETE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupsDeletedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (!loading && !error && operation === Operation.UPDATE) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupsSavedSuccessfully'),
      });
    }
  }, [loading, operation, closeModal, showNotification, translate, error]);

  useEffect(() => {
    if (
      !loading &&
      !error &&
      operation === Operation.CREATE &&
      createdIds.length
    ) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierGroupCopiedSuccessfully'),
      });
      // navigate to edit modifier group page
      navigation.navigate('CreateModifierGroupTab', {
        modifierGroupId: createdIds[0],
      });
    }
  }, [
    loading,
    operation,
    closeModal,
    showNotification,
    translate,
    error,
    createdIds,
    navigation,
  ]);

  useEffect(() => {
    if (!error && !loading && modifierGroups) {
      setModifierGroupsData(modifierGroups);
    }
  }, [error, loading, modifierGroups]);

  const modifierGroupsArray = useMemo(() => {
    return Object.values(modifierGroupsData || {});
  }, [modifierGroupsData]);

  const titleCheckBoxStatus = useMemo(() => {
    if (find(modifierGroupsArray, { isSelected: true })) {
      return true;
    }
    return false;
  }, [modifierGroupsArray]);

  const onPressSave = useCallback((): void => {
    const filteredModifiedGroupArray: UpdateModifierGroupInput[] =
      stripProperties(modifierGroupsArray, '__typename');
    updateModifierGroups(
      filteredModifiedGroupArray.map(x => ({
        id: x.id,
        name: x.name,
      })) as unknown as UpdateModifierGroupInput[],
    );
  }, [updateModifierGroups, modifierGroupsArray]);

  const onChange = useCallback(
    (id: string, prop: string, value: string | boolean): void => {
      setModifierGroupsData(prev => ({
        ...prev,
        [id]: {
          ...prev[id],
          [prop]: value,
        },
      }));
    },
    [],
  );

  const onCreateModifierGroup = useCallback((): void => {
    // navigate to add modifier group screen
    navigation.navigate('CreateModifierGroupTab', {
      modifierGroupId: DEFAULT_ENTITY_ID,
    });
  }, [navigation]);

  const selectToggle = useCallback(
    (isSelected): void => {
      const tempObj = {} as Record<string, ModifierGroup>;
      modifierGroupsArray.forEach(x => {
        tempObj[x.id] = {
          ...x,
          isSelected: isSelected,
        };
      });
      setModifierGroupsData(tempObj);
    },
    [modifierGroupsArray],
  );

  const selectedModifierGroupsNames = useMemo(() => {
    let nameOfSelected = '';
    modifierGroupsArray.forEach(x => {
      x?.isSelected
        ? (nameOfSelected =
            x.name + `${nameOfSelected && ', ' + nameOfSelected}`)
        : '';
    });
    return nameOfSelected;
  }, [modifierGroupsArray]);

  const deleteSelectedModifierGroups = useCallback((): void => {
    const selectedModGroupIds: string[] = [];

    modifierGroupsArray.forEach(x => {
      if (x?.isSelected) {
        selectedModGroupIds.push(x.id);
      }
    });

    deleteModifierGroups(selectedModGroupIds);
  }, [modifierGroupsArray, deleteModifierGroups]);

  const onPressDelete = useCallback((): void => {
    showModal(
      <AlertModal
        title={translate('modifiers.deletingModifierGroups')}
        actionTitle={translate('dialog.deleteTitle')}
        message={translate('modifiers.deleteModifierGroupMessage', {
          name: selectedModifierGroupsNames,
        })}
        onConfirm={deleteSelectedModifierGroups}
      />,
    );
  }, [
    showModal,
    deleteSelectedModifierGroups,
    selectedModifierGroupsNames,
    translate,
  ]);

  const onPressCopy = useCallback(
    (id: string): void => {
      if (id && modifierGroups[id]) {
        const selectedModifierGroup = modifierGroups[id];
        showModal(
          <AlertModal
            title={translate('modifiers.copyModifierGroup')}
            actionTitle={translate('modifiers.copy')}
            message={translate('modifiers.modifierGroupCopyDiscription', {
              name: selectedModifierGroup.name,
            })}
            onConfirm={() => {
              cloneModifierGroup({
                id: selectedModifierGroup.id,
              });
            }}
          />,
        );
      }
    },
    [showModal, translate, modifierGroups, cloneModifierGroup],
  );

  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.modifierSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <ScrollView contentContainerStyle={css(mainViewStyle)}>
          <BackOfficeSection
            title={translate('modifiers.optionGroups')}
            titleDescription={translate('modifiers.optionGroupsDescription')}
            action={
              <BackOfficeCreateNewButton onPress={onCreateModifierGroup} />
            }
            contentContainerStyle={css(formStyle)}
            containerStyle={css(containerStyle)}
          >
            {(!modifierGroupsArray.length && (
              <Text
                style={css(noModifierGroupTextStyle)}
                testID={'empty-modifier-groups-message'}
              >
                {translate('modifiers.noModifierGroupsMessage')}
              </Text>
            )) || (
              <TableComponent
                columns={[
                  {
                    title: (
                      <Button
                        testID={'title-checkbox'}
                        onPress={() => selectToggle(!titleCheckBoxStatus)}
                        fluid
                        iconPosition={'left'}
                        containerStyle={css(checkBoxTitleContainer)}
                        iconContainerStyle={
                          titleCheckBoxStatus
                            ? css(checkIconContainer)
                            : css(unCheckContainer)
                        }
                        icon={titleCheckBoxStatus ? 'check' : 'null'}
                        iconProps={{
                          color: theme.colors.success,
                          size: 15,
                        }}
                      />
                    ),
                    width: 58,
                  },
                  {
                    title: translate('modifiers.groupName'),
                    width: 300,
                    alignItems: 'flex-start',
                    containerStyle: css(groupNameStyle),
                  },
                  {
                    title: translate('modifiers.options'),
                    flex: 1,
                    alignItems: 'flex-start',
                  },
                ]}
                data={modifierGroupsArray}
                normalRows
                columnContainerStyle={css(columnContainerStyle)}
                renderRow={(
                  item: ModifierGroup,
                  index: number,
                ): React.ReactNode => (
                  <ModifierGroupRow
                    modifierGroup={item}
                    onChange={onChange}
                    key={index}
                    onPressCopy={onPressCopy}
                  />
                )}
              />
            )}
          </BackOfficeSection>
          <View style={css(bottomSpace)}></View>
        </ScrollView>
      </View>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="delete"
            title={translate('button.delete')}
            containerStyle={css(
              deleteButtonStyle({ theme, selected: titleCheckBoxStatus }),
            )}
            labelStyle={css(
              deleteTitleStyle({ theme, selected: titleCheckBoxStatus }),
            )}
            onPress={onPressDelete}
            disabled={!titleCheckBoxStatus}
          />
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onPressSave}
          />
        </View>
      </View>
    </>
  );
};
