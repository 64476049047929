import React, { useCallback, useState } from 'react';
import { CreateStoreInput, Venue } from '@hitz-group/domain';
import FormInput from '../../FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../Button/Button';
import { useNotification } from '../../../hooks/Notification';
import { StoreFormStyles } from './styles';
import DropDown from '../../FormInput/DropDown';

interface Props {
  onCreate: (input: CreateStoreInput) => void;
  venues: Venue[];
}
export const StoreForm: React.FC<Props> = ({ onCreate, venues }) => {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState({ venue: venues[0].id } as CreateStoreInput);
  const styles = StoreFormStyles();

  const onChange = useCallback((prop: string, value: string) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onSubmit = useCallback(() => {
    if (!form.name) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.storeNameIsRequired'),
      });
    } else if (!form.venue) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.venueIsRequired'),
      });
    } else {
      onCreate(form);
    }
  }, [form, onCreate, translate, showNotification]);

  return (
    <>
      <DropDown
        title={translate('backOfficeVenues.selectVenue')}
        values={venues.map(value => ({
          value: value.id,
          label: value.name,
        }))}
        selectedValue={form.venue}
        style={styles.formInputStyle}
        extraMainViewStyle={styles.dropDownMainViewStyle}
        extraViewStyle={styles.dropdownViewStyle}
        extraStyle={styles.dropdownExtraStyle}
        onValueChange={onChange.bind(null, 'venue')}
      />

      <FormInput
        title={translate('form.name')}
        placeholder={translate('form.name')}
        value={form.name}
        containerStyle={styles.formInputStyle}
        onChangeText={onChange.bind(null, 'name')}
        maxLength={50}
        textStyle={styles.formTextStyle}
      />

      <Button
        title={translate('backOfficeSettings.createStore')}
        containerStyle={styles.buttonContainerStyle}
        labelStyle={styles.labelStyle}
        onPress={onSubmit}
      />
    </>
  );
};
