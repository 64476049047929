import { StyleFn, Product } from '@hitz-group/domain';
import { ProductModel } from '@hitz-group/dal';
import { UpdateProductInput } from '@hitz-group/domain';
import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import { CHANNELS_STORES_PAGES_QUERY } from '../../../../../graphql/settings';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import { useNotification } from '../../../../../hooks/Notification';
import { parseApolloError } from '../../../../../utils/errorHandlers';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import { AvailabilityItemRow } from './AvailabilityItemRow';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { Operation } from '../../../../../types/Operation';
import { PRODUCT_AVAILABILITY_FRAGMENT } from '../../../../../hooks/app/products/graphql';

const BackOfficeContainerStyle: StyleFn = ({ theme }) => ({
  width: 540,
  paddingBottom: theme.padding.medium * 3,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  alignItems: 'center',
  zIndex: 100,
  paddingHorizontal: theme.padding.large,
});

export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
  zIndex: 1,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const columnNameStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.big / 2,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});
interface ItemData {
  title: string;
  data: { value: string; label: string }[];
  selectedValues: string[];
  searchTitle: string;
  dropDownTitle: string;
  onChange: (name: string, values: string) => void;
  property: string;
}
interface AvailabilityProps {
  productId: string;
}
export const AvailabilityProduct: React.FC<AvailabilityProps> = ({
  productId,
}) => {
  const { css, theme } = useFela();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [product, setProduct] = useState({} as ProductModel);

  const {
    error: prodErr,
    products,
    updateProduct,
    loading: prodLoading,
    operation: prodOperation,
  } = useProducts(productId, PRODUCT_AVAILABILITY_FRAGMENT);

  const error = prodErr;

  const channelsStoresPagesQuery = useQuery(CHANNELS_STORES_PAGES_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!prodErr && prodOperation === Operation.UPDATE && !prodLoading) {
      showNotification({
        success: true,
        message: translate('productSettings.productSavedSuccessfully'),
      });
    }
  }, [prodErr, prodLoading, prodOperation, showNotification, translate]);

  useEffect(() => {
    if (productId && products && products[productId]) {
      const productData = products[productId] as Product;
      const productModal = {
        ...productData,
        salesChannels: productData.salesChannels.map(x => x.id),
        pages: productData.pages.map(x => x.id),
        stores: productData.stores.map(x => x.id),
      } as unknown as ProductModel;
      setProduct(productModal);
    }
  }, [productId, products]);

  useEffect(() => {
    if (channelsStoresPagesQuery.error) {
      showNotification({
        error: true,
        message: parseApolloError(channelsStoresPagesQuery.error),
      });
    }
  }, [channelsStoresPagesQuery.error, showNotification]);

  const onChange = useCallback((prop: string, value): void => {
    setProduct(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onSaveProduct = useCallback((): void => {
    const updateProductInput = {
      id: product.id,
      name: product.name,
      salesChannels: product.salesChannels,
      pages: product.pages,
      stores: product.stores,
    } as UpdateProductInput;

    updateProduct(updateProductInput);
  }, [product, updateProduct]);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        <BackOfficeSection
          title={translate('productSettings.availability')}
          contentContainerStyle={css(BackOfficeContainerStyle)}
          titleDescription={translate(
            'productSettings.availabilityDescription',
          )}
        >
          <TableComponent
            columns={[
              {
                title: translate('productSettings.type'),
                width: 150,
                containerStyle: css(columnNameStyle),
              },
              {
                title: translate('productSettings.showIn'),
                flex: 1,
                containerStyle: {
                  alignItems: 'flex-start',
                  paddingLeft: theme.spacing.big,
                },
              },
            ]}
            data={
              [
                {
                  title: translate('productSettings.channels'),
                  data: channelsStoresPagesQuery.data?.salesChannels || [],
                  selectedValues: product.salesChannels || [],
                  searchTitle: translate('productSettings.searchForChannels'),
                  dropDownTitle: translate('productSettings.addToChannel'),
                  property: 'salesChannels',
                },
                {
                  title: translate('backOfficeUsers.stores'),
                  data: channelsStoresPagesQuery.data?.stores || [],
                  selectedValues: product.stores || [],
                  searchTitle: translate('productSettings.searchForStores'),
                  dropDownTitle: translate('productSettings.addToStore'),
                  property: 'stores',
                },
                {
                  title: translate('backOfficeProducts.pages'),
                  data: channelsStoresPagesQuery.data?.pages || [],
                  selectedValues: product.pages || [],
                  searchTitle: translate('productSettings.searchForPages'),
                  dropDownTitle: translate('productSettings.addToPage'),
                  property: 'pages',
                },
              ] as ItemData[]
            }
            normalRows
            columnContainerStyle={css(columnContainerStyle)}
            renderRow={(item: ItemData, index: number) => (
              <AvailabilityItemRow
                data={item.data}
                dropDownTitle={item.dropDownTitle}
                key={index}
                searchTitle={item.searchTitle}
                title={item.title}
                selectedValues={item.selectedValues}
                onChange={onChange}
                index={index}
                property={item.property}
              />
            )}
          />
        </BackOfficeSection>
      </View>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onSaveProduct}
          />
        </View>
      </View>
    </>
  );
};
