import { StyleFn } from '@hitz-group/domain';
import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { TouchableOpacity, ViewStyle } from 'react-native';
import Title from '../../Title/Title';

export interface VariantProps {
  name: string;
  onPress?: () => void;
  containerStyle?: ViewStyle;
  selected?: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  lineThrough?: boolean;
}

const variantStyle: StyleFn = ({ theme, selected, highlighted }) => ({
  height: 24,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  borderRadius: theme.radius.small,
  alignItems: 'center',
  marginTop: 2,
  marginRight: 0,
  marginLeft: theme.spacing.big,
  paddingHorizontal: theme.padding.medium,
  marginBottom: theme.spacing.small,
  backgroundColor: selected
    ? theme.colors.blue
    : highlighted
    ? theme.colors.highlighted
    : null,
});

const textStyle: StyleFn = ({ theme, selected, lineThrough }) => ({
  textTransform: 'uppercase',
  fontFamily: theme.font.medium,
  color: selected ? theme.colors.white : theme.colors.textLight,
  textDecorationLine: lineThrough ? 'line-through' : 'none',
  textDecorationStyle: 'solid',
});

const Variant: React.FC<VariantProps> = ({
  onPress,
  name,
  selected,
  highlighted,
  containerStyle,
  disabled = false,
  lineThrough = false,
}: VariantProps) => {
  const { css } = useFela({ selected, highlighted, lineThrough });

  return useMemo(
    () => (
      <TouchableOpacity
        style={[css(variantStyle), containerStyle]}
        onPress={onPress}
        disabled={disabled}
      >
        <Title labelStyle={css(textStyle)} testID="variant-name">
          {name}
        </Title>
      </TouchableOpacity>
    ),
    [name, css, onPress, containerStyle, disabled],
  );
};

export default Variant;
