import {
  StyleFn,
  PaymentType,
  CreatePaymentTypeInput,
} from '@hitz-group/domain';
import React, { useEffect, useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import FormInput from '../../../components/FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../components/Button/Button';
import { useMutation } from '@apollo/client/react/hooks';
import { CREATE_PAYMENT_TYPE } from '../../../graphql/settings';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal';
import Modal from '../Modal';
import scale from '../../../common/theme';
export interface PaymentRowProps {
  paymentType: PaymentType;
  deletePaymentTypeFromState: (index: number, id: string) => void;
  onChange: (index: number, prop: string, value: string | boolean) => void;
  index: number;
}

const checkIconContainerNoMargin: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  marginTop: 0,
  height: 18,
  width: 18,
  marginRight: theme.spacing.medium,
});

const unCheckContainerNoMargin: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  marginTop: 0,
  height: 18,
  width: 18,
  marginRight: theme.spacing.medium,
});

const createModalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  marginLeft: 0,
  marginTop: theme.spacing.medium,
});

const createLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const formInputStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: scale.textInputWidth180,
});

const checkBoxTitleStyle: StyleFn = ({ theme }) => ({
  textTransform: 'none',
  ...theme.font14RegularCharcoal,
});

const checkBoxContainerStyle: StyleFn = () => ({
  justifyContent: 'flex-start',
});

interface CreatePaymentModalProps {
  onAddPayment: (paymentType: PaymentType) => void;
}

export const CreatePaymentModal: React.FC<CreatePaymentModalProps> = ({
  onAddPayment,
}: CreatePaymentModalProps) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [createForm, setCreateForm] = useState({} as CreatePaymentTypeInput);
  const { showNotification } = useNotification();

  const onChangeModal = useCallback((prop: string, value) => {
    setCreateForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const [createPaymentType, createPaymentRequest] = useMutation(
    CREATE_PAYMENT_TYPE,
    {
      onError: noopHandler,
    },
  );

  const onCreatePaymentType = useCallback(() => {
    if (!createForm.name) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.allFieldsMandatory'),
      });
    } else {
      createPaymentType({
        variables: { input: createForm as CreatePaymentTypeInput },
      });
    }
  }, [createForm, createPaymentType, translate, showNotification]);

  useEffect(() => {
    if (createPaymentRequest.data) {
      const createdPaymentTypesData = createPaymentRequest.data
        .createPaymentType as PaymentType;
      onAddPayment({
        id: createdPaymentTypesData.id,
        adjustmentPrompt: createdPaymentTypesData.adjustmentPrompt,
        clearingAccount: createdPaymentTypesData.clearingAccount,
        isActive: createdPaymentTypesData.isActive,
        name: createdPaymentTypesData.name,
        quickPay: createdPaymentTypesData.quickPay,
        requireCustomer: createdPaymentTypesData.requireCustomer,
      });
      closeModal();
      showNotification({
        success: true,
        message: translate(
          'backOfficeSettings.newPaymentTypeSuccessfullyAdded',
        ),
      });
    }
  }, [
    createPaymentRequest.data,
    showNotification,
    closeModal,
    onAddPayment,
    translate,
  ]);

  useEffect(() => {
    if (createPaymentRequest.error) {
      showNotification({
        error: true,
        message: parseApolloError(createPaymentRequest.error),
      });
    }
  }, [createPaymentRequest.error, showNotification]);

  return (
    <Modal
      title={translate('backOfficeSettings.createPaymentType')}
      onDismiss={closeModal}
      contentStyle={css(createModalContainerStyle)}
    >
      <FormInput
        title={translate('backOfficeSettings.paymentTypeName')}
        placeholder={translate('backOfficeSettings.paymentTypeName')}
        value={createForm.name}
        containerStyle={css(formInputStyle)}
        onChangeText={onChangeModal.bind(null, 'name')}
      />
      <Button
        title={translate('backOfficeSettings.quickPayButton')}
        labelStyle={css(checkBoxTitleStyle)}
        onPress={onChangeModal.bind(null, 'quickPay', !createForm.quickPay)}
        fluid
        iconPosition={'left'}
        containerStyle={css(checkBoxContainerStyle)}
        iconContainerStyle={
          createForm.quickPay
            ? css(checkIconContainerNoMargin)
            : css(unCheckContainerNoMargin)
        }
        icon={createForm.quickPay ? 'check' : 'null'}
        iconProps={{
          color: theme.colors.success,
          size: 15,
        }}
      />

      <Button
        title={translate('backOfficeSettings.requireCustomer')}
        labelStyle={css(checkBoxTitleStyle)}
        onPress={onChangeModal.bind(
          null,
          'requireCustomer',
          !createForm.requireCustomer,
        )}
        fluid
        iconPosition={'left'}
        containerStyle={css(checkBoxContainerStyle)}
        iconContainerStyle={
          createForm.requireCustomer
            ? css(checkIconContainerNoMargin)
            : css(unCheckContainerNoMargin)
        }
        icon={createForm.requireCustomer ? 'check' : 'null'}
        iconProps={{
          color: theme.colors.success,
          size: 15,
        }}
      />

      <Button
        title={translate('backOfficeSettings.promptAdjustment')}
        labelStyle={css(checkBoxTitleStyle)}
        onPress={onChangeModal.bind(
          null,
          'adjustmentPrompt',
          !createForm.adjustmentPrompt,
        )}
        fluid
        iconPosition={'left'}
        containerStyle={css(checkBoxContainerStyle)}
        iconContainerStyle={
          createForm.adjustmentPrompt
            ? css(checkIconContainerNoMargin)
            : css(unCheckContainerNoMargin)
        }
        icon={createForm.adjustmentPrompt ? 'check' : 'null'}
        iconProps={{
          color: theme.colors.success,
          size: 15,
        }}
      />

      <Button
        title={translate('backOfficeSettings.createPaymentType')}
        containerStyle={css(buttonContainerStyle)}
        labelStyle={css(createLabelStyle)}
        color={theme.colors.green}
        onPress={onCreatePaymentType}
      />
    </Modal>
  );
};
