import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import ManageFeaturesTabs from './ManageFeature/ManageFeaturesTabs';
import FeatureSettingsTabs from './ManageFeature/FeatureSettingsTabs';
import XeroSettingsTabs from './ManageIntegrations/xeroTabs/XeroSettingsStack';
import DeputySettingsTabs from './ManageIntegrations/deputyIntegration/DeputySettingsStack';
import OnlineOrderIntegrationsStoreSettingsTabs from './ManageIntegrations/onlineOrderIntegrations/StoreSettings/OnlineOrderIntegrationsStoreSettingsTabs';
import OnlineOrderIntegrationsSettingsTab from './ManageIntegrations/onlineOrderIntegrations/OnlineOrderIntegrationsSettingsTabs';
import XeroStoreSettingsPage from './ManageIntegrations/xeroTabs/Settings/StoreSettingsPage';
import DeputyStoreTab from './ManageIntegrations/deputyIntegration/Settings/StoreSettings/DeputyStoreTab';
const Stack = createStackNavigator();

const FeaturesAndIntegrationsStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="ManageFeatures"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={ManageFeaturesTabs} name="ManageFeatures" />

    <Stack.Screen component={FeatureSettingsTabs} name="FeaturesSettings" />

    <Stack.Screen component={DeputyStoreTab} name="DeputyStoreTab" />

    <Stack.Screen component={XeroSettingsTabs} name="XeroSettings" />
    <Stack.Screen component={XeroStoreSettingsPage} name="XeroSettingsPage" />

    <Stack.Screen component={DeputySettingsTabs} name="DeputySettings" />

    <Stack.Screen
      component={OnlineOrderIntegrationsSettingsTab}
      name="OnlineOrderIntegrationsSettings"
    />
    <Stack.Screen
      component={OnlineOrderIntegrationsStoreSettingsTabs}
      name="OnlineOrderIntegrationStoreSettings"
    />
  </Stack.Navigator>
);

export default FeaturesAndIntegrationsStack;
