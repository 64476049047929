import { DeviceProfile, Table } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import React, { useCallback, useMemo } from 'react';
import { useFela } from 'react-fela';
import { Text, TouchableOpacity, View } from 'react-native';
import { isWeb } from '../../../src/common/theme';
import { noopHandler } from '../../../src/utils/errorHandlers';
import { useSession } from '../../hooks/app/useSession';
import { extractCounter } from '../../hooks/orders/useOrderNumber';
import { Session } from '../../state/Session';
import Icon from '../Icon/Icon';
import TitleBar from '../TitleBar/TitleBar';
import { cursorStyle, getStyles } from './styles';
export interface CartHeaderProps {
  table?: Table;
  orderNumber: string;
  tableNumber?: string;
  sectionName?: string;
  orderType?: string;
  onSelectOrderType?: () => void;
  onSelectTableNumber?: () => void;
}

export const CartHeader: React.FC<CartHeaderProps> = ({
  orderNumber,
  orderType,
  onSelectOrderType,
  onSelectTableNumber,
  tableNumber,
  sectionName,
}) => {
  const { css, theme } = useFela();
  const styles = useMemo(() => getStyles(theme), [theme]);
  const { translate } = useTranslation();
  const [session] = useSession();
  const tableLabelWithSectionAndNumber = [sectionName?.slice(0, 3), tableNumber]
    .filter(v => !!v)
    .join(' ');
  const numberOfSections = (
    (session as Session)?.deviceProfile as DeviceProfile
  )?.sections?.length;

  const tableHeader = useMemo(() => {
    if (numberOfSections > 1) {
      return !tableNumber
        ? translate('order.tablePlaceHolder').toUpperCase()
        : tableLabelWithSectionAndNumber;
    } else {
      return !tableNumber
        ? translate('order.tablePlaceHolder').toUpperCase()
        : tableNumber;
    }
  }, [
    tableNumber,
    translate,
    tableLabelWithSectionAndNumber,
    numberOfSections,
  ]);

  const TitleLeft = useCallback((): React.ReactElement => {
    return (
      <TouchableOpacity
        testID="change-order-type"
        style={[styles.cartHeaderLeftView, isWeb && css(cursorStyle)]}
        onPress={onSelectOrderType}
        activeOpacity={!onSelectOrderType ? 1 : 0.2}
      >
        <View style={styles.orderNumberAndTypeContainer}>
          <View style={styles.cartHeaderLeftOrderNumberView}>
            <Text
              testID="order-number"
              style={[styles.cartHeaderBadge, styles.boldText]}
            >
              {`#${extractCounter(orderNumber)}`}
            </Text>
          </View>
          {orderType ? (
            <Text testID="order-type" style={styles.boldText}>
              {`${orderType}`}
            </Text>
          ) : null}
        </View>

        {onSelectOrderType ? (
          <Icon name="AngleDown" size={20} color="black" />
        ) : null}
      </TouchableOpacity>
    );
  }, [css, styles, onSelectOrderType, orderNumber, orderType]);

  const TitleRight = useCallback((): React.ReactElement => {
    return (
      <TouchableOpacity
        testID="change-table-number"
        style={[styles.cartHeaderRightView, isWeb && css(cursorStyle)]}
        onPress={
          orderType && orderType.toLowerCase() === 'dine in'
            ? onSelectTableNumber
            : noopHandler
        }
        activeOpacity={!onSelectTableNumber ? 1 : 0.2}
      >
        {orderType && orderType.toLowerCase() === 'dine in' ? (
          <Text
            testID="table-number"
            style={[styles.tableNumberTextColor, styles.boldText]}
          >
            {tableHeader}
          </Text>
        ) : null}
      </TouchableOpacity>
    );
  }, [css, onSelectTableNumber, orderType, styles, tableHeader]);

  return (
    <TitleBar
      containerStyle={styles.container}
      labelContainerStyle={styles.labelContainer}
      titleLeft={<TitleLeft />}
      titleRight={<TitleRight />}
      testID="cart-header"
    />
  );
};
