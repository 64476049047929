import { StyleFn, Icons, Role } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { View, Text } from 'react-native';
import IconButton from '../../../../components/Button/IconButton';
import { useNavigation } from '@react-navigation/native';
import scale from '../../../../common/theme';

const smallContainer: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'flex-start',
  paddingHorizontal: 14,
  paddingVertical: 12,
  backgroundColor: theme.colors.greyLight,
});

const roleNameContainer: StyleFn = () => ({
  width: scale.moderateScale(172),
});

const roleView: StyleFn = () => ({
  flex: 4.2,
});

const permissionView: StyleFn = () => ({
  flex: 1.5,
});

const countView: StyleFn = () => ({
  flex: 1.5,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

const countContainer: StyleFn = () => ({
  width: scale.moderateScale(60),
  alignItems: 'center',
});

const nameTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  lineHeight: 21,
});

export interface ManageRoleRowProps {
  role: Role;
}

export const ManageRoleRow: React.FC<ManageRoleRowProps> = ({ role }) => {
  const { css, theme } = useFela();
  const navigation = useNavigation();

  const navigateToUserDetails = useCallback(() => {
    navigation.navigate('JobRoleSettings', {
      roleId: role.id,
      title: role.name, // to be able to display this in breadcrumb
    });
  }, [navigation, role.id, role.name]);

  return (
    <View style={css(rowStyle)}>
      <View style={css(roleView)}>
        <View style={css([smallContainer, roleNameContainer])}>
          <Text testID="roleName" style={css(nameTextStyle)}>
            {role.name}
          </Text>
        </View>
      </View>
      <View style={css(permissionView)}>
        <View style={css([smallContainer, countContainer])}>
          <Text testID="permissionsCount" style={css(nameTextStyle)}>
            {role.permissions.length}
          </Text>
        </View>
      </View>
      <View style={css(countView)}>
        <View style={css([smallContainer, countContainer])}>
          <Text testID="usersCount" style={css(nameTextStyle)}>
            {role.usersCount}
          </Text>
        </View>
      </View>
      <IconButton
        testID="roleDetailsPage"
        primary
        icon={Icons.AngleRight}
        iconSize={24}
        containerSize={34}
        iconColor={theme.colors.text}
        onPress={navigateToUserDetails}
      />
    </View>
  );
};
