import React from 'react';
import { Icons, PaymentType, StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useFela } from 'react-fela';
import { Text, View } from 'react-native';
import Button from '../../Button/Button';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import PaymentAmountOptions from '../../PaymentOptions/PaymentAmountOptions';
import PopupView from '../../PopupView/PopupView';
import TitleBar from '../../TitleBar/TitleBar';
import IconButton from '../../Button/IconButton';

export interface ProcessPaymentModalProps {
  orderTotal: number;
  cancellationLoading: boolean;
  onPressCancel: () => void;
  onPressVerify: () => void;
  onPressClose: () => void;
}

export interface OtherPaymentType extends PaymentType {
  color?: string;
}

const containerStyle: StyleFn = ({ theme }) => ({
  width: 600,
  height: 450,
  alignSelf: 'center',
  justifyContent: 'center',
  borderRadius: theme.radius.small,
  flex: 1,
});

const popupStyle: StyleFn = () => ({
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const loadingContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: 90,
  height: 50,
  width: 50,
  overflow: 'hidden',
  borderRadius: theme.radius.small,
});

const editTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing.small / 2,
  color: theme.colors.paragraph,
  fontFamily: theme.font.medium,
  lineHeight: 21,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.small,
});

const loadingTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing.small / 2,
  color: theme.colors.paragraph,
  fontFamily: theme.font.medium,
  lineHeight: 21,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.larger,
});

const verifyPaymentButtonStyle: StyleFn = ({ theme }) => ({
  width: 110,
  marginHorizontal: theme.padding.medium,
  marginTop: theme.padding.medium,
  marginBottom: theme.padding.large,
  backgroundColor: theme.colors.skyBlue,
});

const verifyPaymentLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
  textTransform: 'capitalize',
});

const cancelPaymentButtonStyle: StyleFn = ({ theme }) => ({
  width: 110,
  marginHorizontal: theme.padding.medium,
  marginTop: theme.padding.medium,
  marginBottom: theme.padding.large,
  backgroundColor: theme.colors.greyLight,
});

const cancelPaymentLabelStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.textLight,
  textTransform: 'capitalize',
});

const paymentActionContainerStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.big,
  overflow: 'hidden',
  flexDirection: 'row',
  marginHorizontal: '30%',
});

const iconButtonStyle: StyleFn = () => ({
  position: 'absolute',
  left: 15,
});

const headerStyle: StyleFn = () => ({
  width: 600,
  height: 44,
  alignSelf: 'center',
  marginHorizontal: 8,
});

const ProcessPaymentModal: React.FC<ProcessPaymentModalProps> = ({
  orderTotal,
  cancellationLoading,
  onPressCancel,
  onPressVerify,
  onPressClose,
}: ProcessPaymentModalProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();

  const titleLeft = (
    <IconButton
      testID={'close-modal-icon'}
      containerStyle={css(iconButtonStyle)}
      icon={Icons.Times}
      onPress={onPressClose}
    />
  );

  return (
    <View style={css(containerStyle)} testID="payment-options">
      <TitleBar
        testID="card-pay-header"
        primary
        title={translate('payment.cardPayHeader')}
        titleLeft={titleLeft}
        containerStyle={css(headerStyle)}
      />
      <PopupView containerStyle={css(popupStyle)}>
        <Text style={css(editTextStyle)}>
          {translate('payment.paymentAmount')}
        </Text>
        <PaymentAmountOptions value={orderTotal} />
        <View style={css(loadingContainerStyle)} testID="payment-loader-view">
          <LoadingIndicator size={'large'} testID={'payment-loader'} />
        </View>
        <View testID={'payment-processing-text'}>
          <Text style={css(loadingTextStyle)}>
            {translate('payment.processingPayment')}
          </Text>
        </View>
        <View style={css(paymentActionContainerStyle)}>
          <Button
            testID={'cancel-payment-btn'}
            size="large"
            title={translate('payment.cancelOolioPay')}
            onPress={onPressCancel}
            containerStyle={[css(cancelPaymentButtonStyle)]}
            labelStyle={css(cancelPaymentLabelStyle)}
            disabled={cancellationLoading}
          />
          <Button
            testID={'verify-payment-btn'}
            size="large"
            title={translate('payment.verifyOolioPay')}
            onPress={onPressVerify}
            containerStyle={[css(verifyPaymentButtonStyle)]}
            labelStyle={css(verifyPaymentLabelStyle)}
          />
        </View>
      </PopupView>
    </View>
  );
};

export default ProcessPaymentModal;
