import { gql } from '@apollo/client';

const pricingGroupFragment = `
  id
  name
  isActive
  venues{
    id
  }
  orderTypes {
    id
  }
  salesChannels {
    id
  }
  stores {
    id
  }
  schedule {
    dateRange {
      startDate
      endDate
    }
    timeBlocks {
      day
      isActive
      timeSlot {
        startTime
        endTime
      }
    }
  }
`;
const productModifiers = `
modifierGroups{
  id
  name
  isGrouped
  isRequired,
  priority
  products {
    id
    name
    price {
      amount
      currency
    }
    isDefault
  }
  modifiers {
    id
    name
    isDefault
    priority
    price {
      amount
      currency
    }
    pricingGroups {
      id
      name
      prices {
        id
        sellingPrice{
          amount
          currency
        }
        sellingTax {
          id
          name
          code
          rate
        }
      }
    }
  }
  selectionLimit {
    min
    max
  }
}`;

export const productFragment = `
  id
  name
  isSellable
  isDefault
  isBeingTracked
  isAvailable
  isCombo
  inventory {
    availableQuantity
  }
  ${productModifiers}
  stores {
    id
  }
  optionValues {
    parentOptionId
    key
    value
  }
  minSellingPrice
  maxSellingPrice
  printerProfiles {
    id
  }
  pricings {
    id
    taxInclusive
    sellingPrice {
      amount
      currency
    }
    costPrice {
      amount
      currency
    }
    sellingTax {
      id
      name
      code
      rate
    }
    pricingGroup {
      ${pricingGroupFragment}
    }
  }
  course {
    id
    name
    priority
  }
`;

const pageFragment = `
  id
  name
  color
  products {
    id
    priority
  }
  variants {
    id
    priority
  }
  pages {
    id
    priority
  }
`;

const variantFragment = `
  id
  name
  stores {
    id
  }
  description
  products {
    ${productFragment}
  }
  options {
    id
    key
    values
  }
`;

export const pageFieldsFragment = `
  id
  name
  color
  priority
  products {
    priority
    ${productFragment}
  }
  variants {
    priority
    ${variantFragment}
  }
`;

export const recursivePageFragment = `
${pageFieldsFragment}
pages {
  ${pageFieldsFragment}
}
`;

const menuFragment = `
id
name
count
itemGroups {
  id
  count
  priority
  page {
    ${pageFragment}
  }
  items {
    id
    type
    priority
    item {
      ... on Product {
        ${productFragment}
      }
      ... on Page {
        ${recursivePageFragment}
      }
      ... on Variant {
        ${variantFragment}
      }
    }
  }
}
`;

export const GET_MENU_QUERY = gql`
query catalogue($id: ID!) {
  catalogue(id: $id) {
    ${menuFragment}
  }
}
`;

export const UPDATE_MENU = gql`
  mutation updateCatalogue($input: UpdateCatalogueInput) {
    updateCatalogue(input: $input) {
      ${menuFragment}
    }
  }
`;
