import { useCallback, useState } from 'react';
import { parseApolloError } from '../../utils/errorHandlers';
import { UpdateAdjustmentInput, Adjustment } from '@hitz-group/domain';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useSession } from '../app/useSession';
import { Venue } from '@hitz-group/domain';
import {
  GET_ADJUSTMENTS_BY_VENUE_ID,
  DELETE_ADJUSTMENT_BY_ID,
  CREATE_OR_UPDATE_ADJUSTMENTS,
} from '../../graphql/adjustments';
import { useNotification } from '../Notification';
import { useTranslation } from '@hitz-group/localization';
export interface UseAdjustmentReturn {
  loading: boolean;
  adjustments: Adjustment[];
  getAllAdjustments: () => void;
  deleteAdjustment: (id: string) => void;
  updateAdjustments: (input: UpdateAdjustmentInput) => void;
}

export interface UseAdjustmentInput {
  venueId: string;
  onDeleteComplete?: () => void;
}

export function useAdjustments(input: UseAdjustmentInput): UseAdjustmentReturn {
  const { venueId, onDeleteComplete } = input;
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [session, updateSession] = useSession();

  const [adjustments, setAdjustments] = useState<Adjustment[]>([]);

  const handleCompleteRequest = (response: { venue: Venue }) => {
    setAdjustments(response.venue.adjustments || []);
  };

  const updateAdjustmentInSession = (input: Adjustment[]) => {
    updateSession({
      ...session,
      currentVenue: {
        ...session.currentVenue,
        adjustments: input,
      },
    });
  };

  const [
    getAdjustmentsRequest,
    { loading: getLoading, refetch: refreshAdjustmentList },
  ] = useLazyQuery(GET_ADJUSTMENTS_BY_VENUE_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleteRequest,
  });

  const [deleteAdjustmentRequest, { loading: deleteLoading }] = useMutation(
    DELETE_ADJUSTMENT_BY_ID,
    {
      onError: () => {
        showNotification({
          message: translate(
            'backofficeVenueSettingAdjustments.adjustmentFailedToDelete',
          ),
          error: true,
        });
      },
      onCompleted: () => {
        typeof onDeleteComplete == 'function' && onDeleteComplete();
        showNotification({
          message: translate(
            'backofficeVenueSettingAdjustments.adjustmentDeletedSuccessfully',
          ),
          success: true,
        });
      },
    },
  );

  const [updateAdjustmentsRequest, { loading: updateLoading }] = useMutation(
    CREATE_OR_UPDATE_ADJUSTMENTS,
    {
      onError: error => {
        showNotification({
          message: parseApolloError(error),
          error: true,
        });
      },
      onCompleted: data => {
        let adjustmentArray: Adjustment[] = [];
        data.createOrUpdateAdjustments?.map(
          (resultAdjustment: Adjustment) =>
            (adjustmentArray = adjustments.filter(
              adjustment => adjustment?.id !== resultAdjustment?.id,
            )),
        );
        updateAdjustmentInSession([
          ...adjustmentArray,
          ...data.createOrUpdateAdjustments,
        ]);

        refreshAdjustmentList && refreshAdjustmentList();
        showNotification({
          message: translate(
            'backofficeVenueSettingAdjustments.adjustmentCreatedOrUpdateSuccessfully',
          ),
          success: true,
        });
      },
      context: {
        headers: { venue: venueId },
      },
    },
  );

  const updateAdjustments = (input: UpdateAdjustmentInput) => {
    updateAdjustmentsRequest({ variables: { input } });
  };

  const getAllAdjustments = useCallback(() => {
    getAdjustmentsRequest({ variables: { id: venueId } });
  }, [getAdjustmentsRequest, venueId]);

  const deleteAdjustment = (id: string) => {
    deleteAdjustmentRequest({ variables: { id } });
    updateAdjustmentInSession(
      adjustments.filter(adjustment => adjustment.id !== id),
    );
  };

  const loading = getLoading || deleteLoading || updateLoading;

  return {
    loading,
    adjustments,
    getAllAdjustments,
    deleteAdjustment,
    updateAdjustments,
  };
}
