import { useTranslation } from '@hitz-group/localization';
import { Theme, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import Button from '../../../components/Button/Button';
import FormInput from '../../../components/FormInput/FormInput';
import Logo from '../../../components/Logo/Logo';
import PopupView from '../../../components/PopupView/PopupView';
import scale from '../../../common/theme';
import Gradient from '../../../components/Gradient/Gradient';
import { useFela } from 'react-fela';

export interface CreatePasswordScreenProps {
  theme: Theme;
}

const initialForm = {
  newPassword: '',
  confirmNewPassword: '',
};

const screen: StyleFn = () => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});
const popupStyle: StyleFn = ({ theme }) => ({
  height: 550,
  width: 360,
  borderRadius: theme.radius.large,
});
const forgotPasswordViewStyle: StyleFn = () => ({
  width: scale.moderateScale(180),
  justifyContent: 'space-around',
  alignItems: 'center',
});
const logo: StyleFn = ({ theme }) => ({
  marginBottom: theme.spacing.medium,
  alignSelf: 'center',
  height: 62,
  width: 150,
});
const headerText: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  paddingVertical: scale.moderateScale(10),
  ...theme.font14Medium,
  color: theme.colors.grey2,
});
const textInputContainer: StyleFn = () => ({
  width: 280,
});

const buttonContainer: StyleFn = () => ({
  width: 280,
  marginTop: 30,
  justifyContent: 'center',
  alignItems: 'stretch',
});
const button: StyleFn = () => ({
  marginTop: scale.moderateScale(10),
  overflow: 'hidden',
});
const passwordCriteriaContainer: StyleFn = ({ theme }) => ({
  height: 70,
  width: 280,
  backgroundColor: theme.colors.highlighted,
  borderRadius: theme.radius.small,
  marginTop: 5,
  marginBottom: 10,
  padding: 5,
  justifyContent: 'center',
});
const passwordCriteriaTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14Regular,
  fontSize: theme.fontSize.smallest,
  color: theme.colors.blue,
  textAlign: 'center',
});

const CreatePassword: React.FC = () => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const [form, setForm] = useState({ ...initialForm });
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeFormProp = useCallback(
    (prop: string, value: string): void => {
      if (errorMessage) {
        setErrorMessage('');
      }
      setForm(state => ({
        ...state,
        [prop]: value,
      }));
    },
    [errorMessage],
  );

  const onPressResetPassword = useCallback(async (): Promise<void> => {
    console.log('');
  }, []);

  return (
    <Gradient
      colors={[theme.colors.brandPrimary, theme.colors.brandSecondary]}
      style={css(screen)}
      start={theme.gradient.startAxis}
      end={theme.gradient.endAxis}
      locations={theme.gradient.location}
    >
      <PopupView containerStyle={css(popupStyle)}>
        <View style={css(forgotPasswordViewStyle)}>
          <Logo testID="App-Logo" style={css(logo)} />
          <Text style={css(headerText)}>
            {translate('createPassword.CreatePasswordTitle')}
          </Text>

          <FormInput
            type="password"
            testID="newPassword"
            alignTitle="left"
            title={translate('createPassword.newPassword')}
            value={form.newPassword}
            placeholder={'••••••••'}
            onChangeText={email => onChangeFormProp('newPassword', email)}
            containerStyle={css(textInputContainer)}
          />
          <View style={css(passwordCriteriaContainer)}>
            <Text style={css(passwordCriteriaTextStyle)}>
              {translate('signup.passwordCriteria')}
            </Text>
          </View>
          <FormInput
            testID="confirmNewPassword"
            alignTitle="left"
            title={translate('createPassword.confirmNewPassword')}
            value={form.newPassword}
            placeholder={'••••••••'}
            onChangeText={email =>
              onChangeFormProp('confirmNewPassword', email)
            }
            containerStyle={css(textInputContainer)}
          />
        </View>
        <View style={css(buttonContainer)}>
          <View style={css(button)}>
            <Button
              size="small"
              success
              fluid
              testID="reset"
              title={translate('button.resetPassword')}
              onPress={onPressResetPassword}
            />
          </View>
        </View>
      </PopupView>
    </Gradient>
  );
};

export default CreatePassword;
