import { Icons, IntegrationApps, Store, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from '@hitz-group/localization';
import { Helmet } from 'react-helmet';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import { ScrollView, View, Text } from 'react-native';
import TableComponent from '../../../../../../components/TableComponent/TableComponent';
import { bottomSpace } from '../../../../Settings/GeneralSettings.styles';
import IconButton from '../../../../../../components/Button/IconButton';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import DropDown from '../../../../../../components/FormInput/DropDown';
import Button from '../../../../../../components/Button/Button';
import { useSession } from '../../../../../../hooks/app/useSession';
import { useWorkforceIntegration } from '../../../../../../hooks/app/workforceIntegrations/useWorkforceIntegration';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import { useNotification } from '../../../../../../hooks/Notification';
import { useStores } from '../../../../../../hooks/app/useStores';
import { useIntegrationPartners } from '../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import Icon from '../../../../../../components/Icon/Icon';
const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
});
const scrollStyle: StyleFn = ({ theme }) => ({
  paddingHorizontal: theme.padding.large,
  backgroundColor: theme.colors.white,
});

const formStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing.big,
});

const containerStyle: StyleFn = () => ({
  width: '60%',
  alignSelf: 'center',
});

const tableStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing.small,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.medium,
});

const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

export const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  paddingBottom: theme.padding.medium / 2,
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

export const defaultContainerStyle: StyleFn = ({ theme }) => ({
  width: 240,
  height: theme.input.height,
  marginTop: theme.spacing.small / 2,
  marginRight: theme.spacing.big / 1,
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
});
export const defaultTextStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.spacing.medium,
  marginTop: 10,
  ...theme.font14RegularCharcoal,
});

export const uploadContainerStyle: StyleFn = () => ({
  width: 34,
  height: 34,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  marginLeft: 'auto',
});

export const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: 224,
  height: theme.input.height,
});

export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: 260,
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  marginLeft: 5,
});

const noResult: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.warning,
  marginTop: 30,
});

const noResultIconStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  margin: 'auto',
  color: theme.colors.warning,
});

const noResultTextStyle: StyleFn = ({ theme }) => ({
  textAlign: 'center',
  marginTop: 10,
  color: theme.colors.warning,
});

export const Settings: React.FC = () => {
  const { css, theme } = useFela();
  const [session] = useSession();
  const isFocused = useIsFocused();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { minimalStore: stores, getMinimalStores: getStores } = useStores();
  const navigation = useNavigation();
  const [locationStoreData, setLocationStoreData] = useState({
    storeId: '',
    locationId: 0,
    companyName: '',
  });

  const [partnerLocationStoreData, setpartnerLocationStoreData] = useState({
    storeId: '',
    locationId: 0,
    companyName: '',
  });
  const {
    error,
    loading,
    locations,
    getLocations,
    integrationData,
    linkedDeputyStores,
    integrationPartner,
    getIntegrationPartnerConfig,
    linkStoresWithDeputyLocations,
  } = useWorkforceIntegration();

  const {
    loading: deputyLoading,
    integrationPartners,
    getIntegrationPartnerSettings,
  } = useIntegrationPartners();

  const integrationParnterData = Object.values(integrationPartners)[0];
  useEffect(() => {
    if (isFocused) {
      getLocations();
      getStores();
      getIntegrationPartnerConfig(session?.currentOrganization?.id || '');
      getIntegrationPartnerSettings({ appName: IntegrationApps.DEPUTY });
    }
  }, [
    isFocused,
    getIntegrationPartnerConfig,
    getLocations,
    session?.currentOrganization?.id,
    getStores,
    getIntegrationPartnerSettings,
  ]);
  const storesData = useMemo(() => {
    const storesData = Object.values(stores || []) as Store[];
    return storesData;
  }, [stores]);

  const onPressSave = useCallback(() => {
    const integrationData = {
      locationId: locationStoreData.locationId,
      companyName: locationStoreData.companyName,
      storeId: locationStoreData.storeId,
    };

    setpartnerLocationStoreData(integrationData);
    linkStoresWithDeputyLocations(locationStoreData);
  }, [locationStoreData, linkStoresWithDeputyLocations]);

  useEffect(() => {
    if (isFocused)
      getIntegrationPartnerSettings({ appName: IntegrationApps.DEPUTY });
  }, [getIntegrationPartnerSettings, isFocused]);

  useEffect((): void => {
    if (integrationPartner.length > 0) {
      const integrationData = {
        locationId: integrationPartner[0]?.locationId,
        companyName: integrationPartner[0]?.companyName,
        storeId: integrationPartner[0]?.storeId,
      };
      setpartnerLocationStoreData(integrationData);
    } else {
      const integrationData = {
        locationId: 0,
        companyName: '',
        storeId: '',
      };
      setpartnerLocationStoreData(integrationData);
    }
  }, [
    getIntegrationPartnerSettings,
    integrationPartner,
    integrationPartners,
    deputyLoading,
    session.currentStore?.id,
  ]);

  const onChangeStore = useCallback(
    (store, selectedvalues) => {
      const company = locations.find(
        item => item.id === Number(selectedvalues),
      );
      setLocationStoreData(prev => ({
        ...prev,
        companyName: company?.companyName as string,
        locationId: Number(selectedvalues),
        storeId: store,
      }));
    },
    [locations],
  );

  const navigateToStoreDetails = (
    name: string,
    storeId: string,
    locationId: number,
  ) => {
    navigation.navigate('DeputyStoreTab', {
      screen: 'DeputyAboutTab',
      params: {
        storeName: name,
        storeId: storeId,
        locationId: locationId,
      },
    });
  };
  useEffect((): void => {
    if (linkedDeputyStores) {
      showNotification({
        success: true,
        message: translate('settingSection.deputy.notification.mapLocation'),
      });
    }
  }, [showNotification, error, translate, linkedDeputyStores]);

  if (loading || !integrationPartner) {
    return <LoadingIndicator />;
  }
  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={css(pageStyle)}>
        {(integrationData || integrationParnterData)?.isActive ? (
          <>
            {isFocused && (
              <ScrollView contentContainerStyle={css(scrollStyle)}>
                <BackOfficeSection
                  title={translate('tabHeaders.deputy.connectedLocations')}
                  contentContainerStyle={css(formStyle)}
                  containerStyle={css(containerStyle)}
                >
                  <TableComponent
                    columns={[
                      {
                        title: translate('settingSection.deputy.store'),
                        width: 250,
                        alignItems: 'flex-start',
                        containerStyle: {
                          paddingLeft: theme.spacing.big / 3,
                        },
                      },
                      {
                        title: translate('settingSection.deputy.locationToMap'),
                        flex: 1,
                        alignItems: 'flex-start',
                        containerStyle: {
                          paddingLeft: theme.spacing.small,
                        },
                      },
                    ]}
                    data={Object.values(storesData)}
                    columnContainerStyle={css(tableStyle)}
                    normalRows={true}
                    renderRow={(store: Store): React.ReactNode => (
                      <>
                        <View key={store.id} style={css(rowStyle)}>
                          <View style={css(defaultContainerStyle)}>
                            <Text style={css(defaultTextStyle)}>
                              {store?.name}
                            </Text>
                          </View>

                          <DropDown
                            {...(partnerLocationStoreData.storeId === store.id
                              ? {
                                  values: [
                                    {
                                      value:
                                        partnerLocationStoreData.locationId,
                                      label:
                                        partnerLocationStoreData.companyName,
                                    },
                                    ...locations.map(location => {
                                      return {
                                        value: location.id,
                                        label: location.companyName,
                                      };
                                    }),
                                  ],
                                }
                              : {
                                  values: [
                                    {
                                      value: '',
                                      label: translate(
                                        'settingSection.deputy.selectLocation',
                                      ),
                                    },
                                    ...locations.map(location => {
                                      return {
                                        value: location.id,
                                        label: location.companyName,
                                      };
                                    }),
                                  ],
                                })}
                            onValueChange={onChangeStore.bind(null, store.id)}
                            testID={'change-store' + store.id}
                            extraStyle={css(dropdownExtraStyle)}
                            extraMainViewStyle={css(dropDownMainViewStyle)}
                          />
                          {partnerLocationStoreData.storeId === store.id && (
                            <IconButton
                              primary
                              icon={'AngleRight'}
                              testID="navigate-details"
                              iconSize={26}
                              containerSize={34}
                              iconColor={theme.colors.paragraph}
                              containerStyle={css(uploadContainerStyle)}
                              onPress={() =>
                                navigateToStoreDetails(
                                  store?.name,
                                  store?.id,
                                  Number(partnerLocationStoreData?.locationId),
                                )
                              }
                            />
                          )}
                        </View>
                      </>
                    )}
                  />
                </BackOfficeSection>
                <View style={css(bottomSpace)}></View>
              </ScrollView>
            )}
            <View style={css(mainStyle)}>
              <View style={css(actionsContainerStyle)}>
                <Button
                  fluid
                  testID="save-changes"
                  title={translate('button.saveChanges')}
                  containerStyle={css(saveButtonStyle)}
                  labelStyle={css(titleStyle)}
                  onPress={onPressSave}
                  disabled={loading}
                  loading={loading}
                />
              </View>
            </View>
          </>
        ) : (
          <>
            {/* {location.reload()} */}
            <LoadingIndicator />
            <View style={css(noResult)}>
              <Icon
                name={Icons.Warning}
                color={theme.colors.warning}
                style={css(noResultIconStyle)}
              />
              <Text style={css(noResultTextStyle)}>
                {translate('settingSection.deputy.notActive')}
              </Text>
            </View>
          </>
        )}
      </View>
    </>
  );
};
