import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { useTranslation } from '@hitz-group/localization';
import TabBar from '../../../components/TabBar/TabBar';
import { ReportingProvider } from './ReportingContext';

import SalesFeedListContainer from './SalesFeed/SalesFeedContainer';
import ProductsSummaryListContainer from './ProductsSummary/ProductsSummaryContainer';
import SalesSummaryContainer from './SalesSummary/SalesSummaryContainer';
import SalesByStaffContainer from './SalesByStaff/SalesByStaffContainer';
import HourlySalesContainer from './HourlySales/HourlySalesContainer';
import AdjustmentsContainer from './Adjustments/AdjustmentsContainer';
import ShiftContainer from './Shift/ShiftContainer';
import WorklogReportContainer from './Worklogs/WorklogReportContainer';
import { DeviceSummaryContainer } from './DeviceSummary/DeviceSummaryContainer';
import PaymentContainer from './Payments/PaymentContainer';
import TaxesContainer from './Taxes/TaxesContainer';
import SalesByCustomerContainer from './SalesByCustomer/SalesByCustomerContainer';
import VoidSummaryContainer from './VoidSummary/VoidSummaryContainer';
import RefundsContainer from './Refunds/RefundsContainer';

const Tab = createMaterialTopTabNavigator();
const Stack = createStackNavigator();

export const SalesNavigator: React.FC = () => {
  const { translate } = useTranslation();
  return (
    <Tab.Navigator
      lazy={true}
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName="SalesFeed"
    >
      <Tab.Screen
        component={SalesFeedListContainer}
        name="SalesFeed"
        options={{ tabBarLabel: translate('backOfficeReports.SalesFeed') }}
      />
      <Tab.Screen
        component={SalesSummaryContainer}
        name="SalesSummary"
        options={{ tabBarLabel: translate('backOfficeReports.SalesSummary') }}
      />
      <Tab.Screen
        component={DeviceSummaryContainer}
        name="Devices"
        options={{
          tabBarLabel: translate('backOfficeReports.Devices'),
        }}
      />
      <Tab.Screen
        component={ProductsSummaryListContainer}
        name="Products"
        options={{
          tabBarLabel: translate('backOfficeReports.Products'),
        }}
      />
      <Tab.Screen
        component={HourlySalesContainer}
        name="HourlySales"
        options={{ tabBarLabel: translate('backOfficeReports.HourlySales') }}
      />
      <Tab.Screen
        component={SalesByStaffContainer}
        name="Staff"
        options={{ tabBarLabel: translate('backOfficeReports.Staff') }}
      />
      <Tab.Screen
        component={TaxesContainer}
        name="Taxes"
        options={{ tabBarLabel: translate('backOfficeReports.Taxes') }}
      />
      <Tab.Screen
        component={SalesByCustomerContainer}
        name="Customers"
        options={{ tabBarLabel: translate('backOfficeReports.Customers') }}
      />
      <Tab.Screen
        component={AdjustmentsContainer}
        name="Adjustments"
        options={{ tabBarLabel: translate('backOfficeReports.Adjustments') }}
      />
    </Tab.Navigator>
  );
};

export const PaymentsNavigator: React.FC = () => {
  const { translate } = useTranslation();
  return (
    <Tab.Navigator
      lazy={true}
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName="Payments"
    >
      <Tab.Screen
        component={PaymentContainer}
        name="Payments"
        options={{ tabBarLabel: translate('backOfficeReports.Payments') }}
      />
    </Tab.Navigator>
  );
};
export const ShiftSummariesNavigator: React.FC = () => {
  const { translate } = useTranslation();
  return (
    <Tab.Navigator
      lazy={true}
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName="Shifts"
    >
      <Tab.Screen
        component={ShiftContainer}
        name="Shifts"
        options={{ tabBarLabel: translate('backOfficeReports.Shifts') }}
      />
    </Tab.Navigator>
  );
};

export const UsersNavigator: React.FC = () => {
  const { translate } = useTranslation();
  return (
    <Tab.Navigator
      lazy={true}
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName="Attendance"
    >
      <Tab.Screen
        component={WorklogReportContainer}
        name="Attendance"
        options={{ tabBarLabel: translate('backOfficeReports.Attendance') }}
      />
    </Tab.Navigator>
  );
};

export const VoidsRefundsNavigator: React.FC = () => {
  const { translate } = useTranslation();
  return (
    <Tab.Navigator
      lazy={true}
      tabBar={(props): React.ReactNode => (
        <TabBar tabBar={props as MaterialTopTabBarProps} />
      )}
      initialRouteName="Voids"
    >
      <Tab.Screen
        component={VoidSummaryContainer}
        name="Voids"
        options={{ tabBarLabel: translate('backOfficeReports.Voids') }}
      />
      <Tab.Screen
        component={RefundsContainer}
        name="Refunds"
        options={{ tabBarLabel: translate('backOfficeReports.Refunds') }}
      />
    </Tab.Navigator>
  );
};

const ReportsStack: React.FC = () => {
  return (
    <ReportingProvider>
      <Stack.Navigator
        headerMode="none"
        initialRouteName="Sales"
        screenOptions={{
          cardOverlayEnabled: false,
          cardShadowEnabled: false,
        }}
      >
        <Stack.Screen component={SalesNavigator} name="Sales" />
        <Stack.Screen component={PaymentsNavigator} name="Payments" />
        <Stack.Screen
          component={ShiftSummariesNavigator}
          name="ShiftSummaries"
        />
        <Stack.Screen component={UsersNavigator} name="Users" />
        <Stack.Screen component={VoidsRefundsNavigator} name="VoidsRefunds" />
      </Stack.Navigator>
    </ReportingProvider>
  );
};

export default ReportsStack;
