import { gql } from '@apollo/client';

export const ordersFragment = `
  id
  createdAt
  updatedAt
  lastCheck
  lastOrderedAt
  requiredAt
  prevEventId
  customer {
    firstName
    lastName
    email
    id
    phone
    loyaltyMember
    loyaltyPoints
    preferredAddress {
      line1
      city
      postalCode
      isoCountryCode
    }
    customerAccountDetails {
      maxOrderLimit
      maxBalanceLimit
      currentBalance
      accountPayment
    }
  }
  createdBy {
    id
    name
  }
  updatedBy {
    id
    name
  }
  status
  orderNumber
  createdByDevice {
    id
    name
  }
  updatedByDevice {
    id
    name
  }
  orderType {
    id
    name
    code
  }
  table {
    id
    name
    guestCount
    status
    section {
      id
      name
    }
  }
  orderItems {
    id
    product {
      id
      name
      optionValues {
        parentOptionId
        key
        value
      }
      course {
        name
        priority
        id
      }
      printerProfiles {
        id
      }
    }
    course {
      name
      priority
      id
    }
    variant {
      id
      name
      options {
        id
        key
        values
      }
    }
    quantity
    notes
    unitPrice
    costPrice
    modifiers {
      modifierGroupId
      id
      name
      quantity
      unitPrice
      taxes {
        id
        name
        code
        rate
      }
    }
    taxes {
      id
      name
      rate
      code
    }
    discounts {
      amount
      type
    }
    discountAmount
    surchargeAmount
    taxInclusive
    reason
    status
    itemFired
    seatNumber
    saved
    adjustments {
      id
      name
      amount
      adjustmentUnit
      adjustmentType
      allowOnPaymentType
    }
  }
  payments {
    id
    paymentType {
      id
      name
    }
    status
    amount
    roundOffDifference
    tendered
    paymentRequestId
    paymentTransactionRef
    paymentCompletedAt
    paymentReceipt
    paymentSurcharge
  }
  orderNote
  amountDue
  subTotal
  totalTax
  totalPrice
  taxes {
    tax {
      id
      name
    }
    amount
  }
  discounts {
    type
    amount
  }
  discountAmount
  roundingAmount
  adjustments {
    id
    name
    amount
    adjustmentUnit
    allowOnPaymentType
    adjustmentType
  }
  surchargeAmount
  refundOf
  salesChannel {
    id
    name
  }
  integrationInfo {
    id
    app
    channelOrderId
  }
  isEdited @client
  shippingAddress {
    line1
    line2
    postalCode
    state
    isoCountryCode
    city
    country
    extraAddressInfo
  }
  courier {
    firstName
    lastName
    phoneNumber
    deliveryBy
  }
  deliveryFee
  serviceCharge
  tip
  lastSyncedEventId @client
`;

export const GET_ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    ${ordersFragment}
  }
`;

export const GET_ORDERS = gql`
  query Orders($filter: OrderFilterInput) {
    orders(filter: $filter) @client {
      ... on Order {
        ${ordersFragment}
      }
    }
  }
`;

export const GET_PAGINATED_ORDER_QUERY = gql`
query paginatedOrders ($first: Int, $after: String, $filter: OrderFilterInput){
  paginatedOrders(
    first: $first,
    after: $after,
    filter: $filter
  ){
    edges{
      node{
        ... on Order {
          ${ordersFragment}
        }
      }
      cursor
    }
    pageInfo{
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

export const GET_ORDER = gql`
  query Order($orderId: ID!) {
    order(id: $orderId) {
      ${ordersFragment}
    }
  }
`;

export const GET_REFUND_ORDER_QUERY = gql`
  query refundOrder($refundOf: ID!) {
    refundOrder(refundOf: $refundOf) {
      ${ordersFragment}
  }
}
`;

export const ORDER_SAVE = gql`
  mutation saveOrder($data: Order!) {
    saveOrder(input: $data) @client
  }
`;

export const ORDERS_SAVE = gql`
  mutation saveOrders($data: [Order]!) {
    saveOrders(input: $data) @client
  }
`;
