import React, { useMemo } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { StyleFn, OrderStatus, DateFilter } from '@hitz-group/domain';
import SelectList from '../../../../components/Select/SelectList';
import { useTranslation } from '@hitz-group/localization';

const filtersLHS: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  margin: theme.spacing.medium,
  marginRight: theme.spacing.small,
  borderRadius: theme.radius.small,
  alignItems: 'center',
  flex: 2,
});

const selectOptionStyle: StyleFn = ({ theme }) => ({
  width: '90%',
  marginTop: theme.spacing.small,
});

const allOption = {
  value: '',
  label: 'All',
};

export interface OrderFiltersProps {
  onChangeOfFilter: (key: string, value: string) => void;
  salesChannelOptions: { value: string; label: string }[];
  filters: {
    status: string;
    dateFilter: string;
    salesChannel: string;
    searchValue: string;
  };
}

export const OrderFiltersSection: React.FC<OrderFiltersProps> = ({
  salesChannelOptions,
  filters,
  onChangeOfFilter,
}) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const statusOptions = useMemo(
    () => [
      allOption,
      {
        label: translate('onlineOrders.pending'),
        value: OrderStatus.CREATED,
      },
      {
        label: translate('onlineOrders.inProgress'),
        value: OrderStatus.IN_PROGRESS,
      },
      {
        label: translate('onlineOrders.cancelled'),
        value: OrderStatus.CANCELLED,
      },
    ],
    [translate],
  );

  const dateOptions = useMemo(
    () => [
      {
        label: translate('onlineOrders.olderOrders'),
        value: DateFilter.OLDER,
      },
      {
        label: translate('onlineOrders.today'),
        value: DateFilter.TODAY,
      },
      {
        label: translate('onlineOrders.upcoming'),
        value: DateFilter.UPCOMING,
      },
    ],
    [translate],
  );

  return (
    <View style={css(filtersLHS)}>
      <SelectList
        values={dateOptions}
        selectedValue={filters.dateFilter || DateFilter.TODAY}
        containerStyle={css(selectOptionStyle)}
        title={translate('onlineOrders.date')}
        onPress={value => onChangeOfFilter('dateFilter', value)}
      />
      <SelectList
        values={statusOptions}
        selectedValue={filters.status}
        containerStyle={css(selectOptionStyle)}
        title={translate('onlineOrders.status')}
        onPress={value => onChangeOfFilter('status', value)}
      />
      <SelectList
        values={[allOption, ...salesChannelOptions]}
        selectedValue={filters.salesChannel}
        containerStyle={css(selectOptionStyle)}
        title={translate('onlineOrders.salesChannel')}
        onPress={value => onChangeOfFilter('salesChannel', value)}
      />
    </View>
  );
};
