import {
  MIN_CHARS_TO_TRIGGER_SEARCH,
  usePlaces,
} from '../../hooks/app/usePlaces';
import React, { FC, useEffect, useState } from 'react';
import {
  FlatList,
  Text,
  View,
  ViewStyle,
  TextStyle,
  TouchableOpacity,
} from 'react-native';
import SearchBar from '../SearchBar/SearchBar';
import { useFela } from 'react-fela';
import Label from '../Label/Label';
import {
  flatListContentStyle,
  iconStyle,
  labelContainerStyle,
  labelStyle,
  loadingStyle,
  noResultsStyle,
  noResultsTextStyle,
  placeItemStyle,
  searchContainerStyle,
  searchTextInputStyle,
  placeTextStyle,
} from './styles';
import { getFormattedAddress } from '../../utils/places.util';
import { Icon } from '../Icon/Icon';
import { useTranslation } from '@hitz-group/localization/dist';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { Address } from '@hitz-group/domain';

export interface PlacesInputProps {
  title: string;
  placeholder: string;
  onChangeAddress: (address: Address) => void;
  readOnly?: boolean;
  address?: Address;
  error?: boolean;
  valid?: boolean;
  extraStyleSearchBar?: ViewStyle;
  extraStyleLabel?: ViewStyle;
  extraStyleContainer?: ViewStyle;
  extraTextInputStyle?: TextStyle;
}

const PlacesInput: FC<PlacesInputProps> = ({
  onChangeAddress,
  title,
  placeholder,
  address,
  error = false,
  valid = false,
  extraStyleSearchBar,
  extraStyleLabel,
  extraStyleContainer,
  extraTextInputStyle,
}) => {
  const { translate } = useTranslation();
  const [hidePlacesList, setHidePlacesList] = useState<boolean>(true);
  const { css, theme } = useFela();
  const [searchText, setSearchText] = useState<string>('');
  const { placesList, handlePlaceSelect, placeDetails, loading } = usePlaces(
    searchText,
    hidePlacesList,
  );

  /**
   * If address was specified in props and there is no search text available then set the search text to formatted address
   */
  useEffect(() => {
    if (address) {
      setSearchText(text => {
        if (text.replace(/\s/g, '').length > 0) {
          return text;
        }
        return getFormattedAddress(address);
      });
    }
  }, [address]);

  /**
   * Once received place details from api, emit output to container component
   *
   */
  useEffect(() => {
    if (placeDetails) {
      // update the parent component to use this address object!
      onChangeAddress(placeDetails.address);
      // Set the readable address in search input
      setSearchText(placeDetails.formattedAddress);
    }
  }, [placeDetails, onChangeAddress]);

  const _onSelectPlace = (placeId: string) => {
    setHidePlacesList(true);
    handlePlaceSelect(placeId);
  };

  const _onChangeSearchText = (text: string) => {
    setSearchText(text);
    setHidePlacesList(text.length < MIN_CHARS_TO_TRIGGER_SEARCH);
    if (text.length < MIN_CHARS_TO_TRIGGER_SEARCH) {
      // if address bar cleared, should update the parent component
      onChangeAddress(undefined as unknown as Address);
    }
  };

  return (
    <View style={extraStyleContainer}>
      <Label
        textStyle={css(labelStyle)}
        containerStyle={css(labelContainerStyle)}
        value={title}
        extraStyleLabel={css(extraStyleLabel)}
      ></Label>
      <View>
        <SearchBar
          placeholder={placeholder}
          containerStyle={
            extraStyleSearchBar
              ? extraStyleSearchBar
              : css(searchContainerStyle({ theme, error }))
          }
          textInputStyle={[css(searchTextInputStyle), extraTextInputStyle]}
          iconColor={theme.colors.paragraph}
          placeholderColor={theme.colors.paragraph}
          onChange={_onChangeSearchText}
          value={searchText}
        />
        {error && (
          <Icon
            name="exclamation-triangle"
            size={20}
            color="red"
            style={css(iconStyle)}
          />
        )}
        {valid && (
          <Icon name="check" size={20} color="green" style={css(iconStyle)} />
        )}

        {loading && (
          <View style={css(loadingStyle)}>
            <LoadingIndicator />
          </View>
        )}
      </View>
      {loading
        ? null
        : !hidePlacesList && (
            <FlatList
              scrollEnabled
              data={placesList}
              renderItem={({ item }) => {
                return (
                  <TouchableOpacity
                    testID="placeItem"
                    style={css(placeItemStyle)}
                    onPress={() => _onSelectPlace(item.placeId)}
                  >
                    <Text style={css(placeTextStyle)}>{item.description}</Text>
                  </TouchableOpacity>
                );
              }}
              keyExtractor={item => item.placeId}
              style={
                placesList.length > 0
                  ? css(flatListContentStyle)
                  : [css(noResultsStyle)]
              }
              ListEmptyComponent={() => (
                <Text style={css(noResultsTextStyle)}>
                  {translate('placesInput.noResults')}
                </Text>
              )}
            />
          )}
    </View>
  );
};

export default PlacesInput;
