import { gql } from '@apollo/client';

export const GET_ADJUSTMENTS_BY_VENUE_ID = gql`
  query venueById($id: ID!) {
    venue(id: $id) {
      id
      adjustments {
        id
        name
        amount
        adjustmentUnit
        adjustmentType
        allowOnPaymentType
      }
    }
  }
`;

export const DELETE_ADJUSTMENT_BY_ID = gql`
  mutation DeleteAdjustment($id: ID!) {
    deleteAdjustment(adjustmentId: $id)
  }
`;

export const CREATE_OR_UPDATE_ADJUSTMENTS = gql`
  mutation CreateOrUpdateAdjustments($input: UpdateAdjustmentInput) {
    createOrUpdateAdjustments(input: $input) {
      id
      name
      amount
      adjustmentUnit
      adjustmentType
      allowOnPaymentType
    }
  }
`;
