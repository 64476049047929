import { StyleFn } from '@hitz-group/domain';

export const itemsContainer: StyleFn = () => ({
  height: 230,
  paddingTop: 0,
  marginTop: 0,
});

export const cartItemsStyle: StyleFn = () => ({
  marginBottom: 10,
});

export const customPaginationContainerStyle: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 3,
  height: 32,
});
