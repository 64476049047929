import { useMutation, useReactiveVar } from '@apollo/client/react/hooks';
import {
  getBestPrice,
  getBestPriceOfModifier,
  getTaxFromModifier,
} from '@hitz-group/catalog-helper';
import { cloneJSON, convertToBoolean } from '@hitz-group/client-utils';
import {
  AddModifierEvent,
  AddOrderItemEvent,
  AddOrderItemNotesEvent,
  Adjustment,
  AppScreen,
  AssignCustomerEvent,
  AssignOrderTypeEvent,
  AssignTableEvent,
  CompleteOrderEvent,
  Course,
  Customer,
  DEFAULT_ENTITY_ID,
  DEFAULT_OPTION_GROUP,
  DEFAULT_PRICING_GROUP,
  DeviceProfile,
  DEVICE_PRICING_GROUP_ID,
  FeatureContext,
  Features,
  FireOrderItemsEvent,
  Modifier,
  Order,
  OrderAction,
  OrderEvent,
  OrderItem,
  OrderItemFireStatus,
  OrderItemModifier,
  OrderItemStatus,
  OrderStatus,
  OrderType,
  OrderTypeCode,
  Page,
  PricingGroup,
  Product,
  Product as ProductAlias,
  RemoveModifierEvent,
  RemoveOrderItemEvent,
  RenderProps,
  Resource,
  SwitchCourseItemEvent,
  UpdateModifierQuantityEvent,
  UpdateOrderItemPriceEvent,
  UpdateOrderItemQuantityEvent,
  UpdateOrderNotesEvent,
  USER_EXPLICIT_SELECT_PRICING_GROUP,
  Variant,
  VoidReason,
} from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import {
  calculateTotalPaidAmount,
  computeOrderItemValue,
} from '@hitz-group/order-helper';
import { useModal } from '@hitz-group/rn-use-modal';
import { NavigationAction, useIsFocused } from '@react-navigation/core';
import { EventArg, useNavigation, useRoute } from '@react-navigation/native';
import { differenceBy, groupBy, isEmpty, keyBy } from 'lodash';
import { usePosState } from '../../../hooks/POSStateProvider';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { View } from 'react-native';
import WarningModal from '../../../../src/components/Modals/Warning/WarningModal';
import { productFragment } from '../../../../src/hooks/app/catalogue/graphql';
import { useCheckFeatureEnabled } from '../../../../src/hooks/app/features/useCheckFeatureEnabled';
import { useProducts } from '../../../../src/hooks/app/products/useProducts';
import { noopHandler } from '../../../../src/utils/errorHandlers';
import Button from '../../../components/Button/Button';
import IconButton from '../../../components/Button/IconButton';
import Cart, { CartSelectionState } from '../../../components/Cart/Cart';
import { CartKeyPadActions } from '../../../components/CartKeyPad/CartKeyPadActions';
import Catalog, { CatalogModifier } from '../../../components/Catalog/Catalog';
import {
  ConnectionStatusIcon,
  DrawerButton,
  FloorViewButton,
  PrintButton,
  SearchButton,
  SubscriptionStatusButton,
} from '../../../components/HeaderIcons/HeaderIcons';
import AddNoteModal from '../../../components/Modals/AddNote/AddNoteModal';
import ConfirmationModal from '../../../components/Modals/ConfirmationDialog';
import SearchCustomer from '../../../components/Modals/Customer/SearchCustomer';
import { DiscardChangesModal } from '../../../components/Modals/DiscardChanges/DiscardChanges';
import { CancelOrderItemModalMap } from '../../../components/Modals/FunctionMaps/CancelOrderItem';
import { PickerModal } from '../../../components/Modals/Picker/Picker';
import SearchProduct from '../../../components/Modals/SearchProduct/SearchProduct';
import SectionsSelectModal from '../../../components/Modals/SectionsSelect/SectionsSelectModal';
import SetGuestModal from '../../../components/Modals/SetGuest/SetGuestModal';
import { SetPriceModal } from '../../../components/Modals/SetPrice/SetPrice';
import { SetQuantityModal } from '../../../components/Modals/SetQuantity/SetQuantity';
import { SetTableNameModal } from '../../../components/Modals/SetTableName/SetTableName';
import UnFiredItemsWarningModal from '../../../components/Modals/UnFiredItemsWarningModal/UnFiredItemsWarningModal';
import NewOrderButton from '../../../components/NewOrder/NewOrderButton';
import Layout from '../../../components/POSLayout/POSLayout';
import PromptModal from '../../../components/PromptModal/PromptModal';
import Spinner from '../../../components/Spinner/Spinner';
import { useCatalogue } from '../../../hooks/app/catalogue/useCatalogue';
import { useCourses } from '../../../hooks/app/courses/useCourses';
import { ORDER_SAVE } from '../../../hooks/app/orders/graphql';
import { useOrders } from '../../../hooks/app/orders/useOrders';
import { useNetworkStatusVar } from '../../../hooks/app/useNetworkStatusVar';
import {
  postSalesObservableForLogin,
  usePostSalesNavigation,
} from '../../../hooks/app/usePostSalesNavigation';
import { usePricingGroups } from '../../../hooks/app/usePricingGroups';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import { useSession } from '../../../hooks/app/useSession';
import { useCartContext as useCart } from '../../../hooks/CartProvider';
import { useNotification } from '../../../hooks/Notification';
import { useFunctionMaps } from '../../../hooks/orders/useFunctionMaps';
import { usePrinting } from '../../../hooks/PrintingProvider';
import useChainModal from '../../../hooks/useChainModal';
import {
  areProductsAvailabilityUpdatedViaSubscription,
  failedPrintJobsCountVar,
  ordersUpdatedViaSubscription,
} from '../../../state/cache';
import * as storage from '../../../storage/interface';
import { AppScreen as POSScreens } from '../../../types/AppScreen';
import { sortTablesByName } from '../../../utils/TableHelper';
import { IMap } from '../../BackOffice/Reports/types';
import HeaderTabs from './HeaderTabs';
import {
  catalogStyle,
  closeButtonStyle,
  headerButtonContainerStyle,
  pageContainerStyle,
  styles,
} from './TakeOrder.styles';
import { takeOrderUnFocusClearParamController } from './takeOrderObservable';

const READ_ONLY_STATUSES = [
  OrderStatus.COMPLETED,
  OrderStatus.REFUNDED,
  OrderStatus.VOID,
];

type RouteParams = {
  orderType: string;
  table: string;
  id: string;
  searchedProduct: Product;
  isCompleted: boolean;
  isExisting: boolean;
  showSpinner?: boolean;
};

interface SetOrderTableChainState {
  sectionId?: string;
  tableId?: string;
  guest?: number;
}

type VariantMap = Record<string, Variant>;

const TakeOrderScreen: React.FC = () => {
  const route = useRoute();
  const [session] = useSession();
  const navigation = useNavigation();
  const { css, theme } = useFela();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { pricingGroups, getAllPricingGroupsByStore } = usePricingGroups(true);
  const currentStoreId = session?.currentStore?.id;
  const [menuId, setMenuId] = useState('');
  const {
    products: productsData,
    getAllProducts,
    getProductsFromCache,
    updateProductsInCache,
  } = useProducts(undefined, productFragment);
  const useCatalogueArgs = useMemo(
    () => ({
      store: currentStoreId,
      menuId,
    }),
    [currentStoreId, menuId],
  );
  const { navigateToPostSaleScreen } = usePostSalesNavigation();

  useEffect(() => {
    if (session?.deviceProfile?.menu?.id) {
      setMenuId(session?.deviceProfile?.menu?.id);
    }
  }, [session?.deviceProfile?.menu?.id]);
  const { products, pages, allProducts, allVariants, menus, status } =
    useCatalogue(useCatalogueArgs);
  const variants: Variant[] = useMemo(() => {
    return pages?.map((page: Page) => page?.variants || []).flat();
  }, [pages]);
  const variantMap: VariantMap = useMemo(() => {
    return variants
      ? variants.reduce<VariantMap>((acc, x) => ({ ...acc, [x.id]: x }), {})
      : {};
  }, [variants]);
  const [pricingGroup, setPricingGroup] = useState('');
  const [defaultPricingGroup, setDefaultPricingGroup] = useState('');
  const [selectedCartItem, selectCartItem] = useState<CartSelectionState>();
  const [selectedVariantKey, setSelectedVariantKey] = useState('');
  const [courses, setCourses] = useState<Course[]>([]);
  const onFinishSetTable = useRef<Function>();
  const [selectedPage, setSelectedPage] = useState<string>('');
  const [showRequiredModifierModal, setShowRequiredModifierModal] =
    useState<boolean>(false);
  const [quantityOfProductsRemoved, setQuantityOfProductsRemoved] = useState<
    IMap<number>
  >({});
  const [selectedSeatNumber, setSelectedSeatNumber] = useState<
    number | undefined
  >(undefined);

  const { showNotification } = useNotification();
  const isFocused = useIsFocused();
  const networkStat = useNetworkStatusVar();
  const params = route.params as RouteParams;
  const isOrderComplete = convertToBoolean(params?.isCompleted);
  const willProceedPayOrderRef = useRef<boolean | undefined>(false);
  const escapeDefaultScreenNavigation = useRef<boolean | undefined>(false);
  const { orderTypes, defaultOrderType, postSaleScreen } =
    session.deviceProfile || {};
  const isFeatureEnabled = useCheckFeatureEnabled();
  const [selectedProduct, setSelectedProduct] = useState<Product | Variant>();
  const navigateToPayment = () => {
    navigation.navigate('Payment', {
      orderId: order?.id,
    });
  };
  const isLoyaltyEnabled = isFeatureEnabled(
    Features.LOYALTY,
    FeatureContext.ORGANIZATION,
  );

  const [cacheAndNavigate] = useMutation(ORDER_SAVE, {
    onCompleted: navigateToPayment,
  });

  const { activeUser, canI } = usePOSUserAuthorization();

  const {
    order,
    updateCart,
    isDirty,
    itemsChanged,
    discardChanges,
    setCartParams,
    resetCart,
    clearPriorPendingEvents,
    mergeCachedOrderWithEvents,
    closeOrderCart,
  } = useCart();
  const { customerMaps } = usePosState('customers');

  const { courses: allCourses, getCourses } = useCourses();

  const updatedSubscriptionOrders = useReactiveVar<string[]>(
    ordersUpdatedViaSubscription,
  );
  const areProductAvailabilitiesChanged = useReactiveVar<boolean>(
    areProductsAvailabilityUpdatedViaSubscription,
  );

  const selectedOrderType = order?.orderType?.id;
  const orderType = useMemo(
    () => orderTypes?.find(x => x.id === selectedOrderType),
    [selectedOrderType, orderTypes],
  );

  const isCoursesEnabled =
    isFeatureEnabled(
      Features.COURSES,
      FeatureContext.VENUE,
      session?.currentVenue?.id,
    ) &&
    session.deviceProfile?.enableCourses &&
    orderType?.code === OrderTypeCode.DINE_IN;

  const orderId = order?.id;

  const { orders, returnOrdersFromCache } = useOrders();

  const assignedCustomer = useMemo(
    () => customerMaps[order?.customer?.id || ''],
    [customerMaps, order?.customer?.id],
  );

  const dineInOrdersInProgressCache = useMemo(() => {
    return Object.values(orders).filter(
      order => order.orderType?.code === OrderTypeCode.DINE_IN,
    );
  }, [orders]);

  const [proceedPayOrder, setProceedPayOrder] = useState(false);

  const dineInOrdersInProgress = useCallback(() => {
    if (!dineInOrdersInProgressCache.length) {
      const result = returnOrdersFromCache(OrderStatus.IN_PROGRESS);
      return result.filter(
        order => order.orderType?.code === OrderTypeCode.DINE_IN,
      );
    } else {
      return dineInOrdersInProgressCache;
    }
  }, [dineInOrdersInProgressCache, returnOrdersFromCache]);

  const getAvailableTablesBySection = useCallback(
    (sectionId: string) => {
      const section = session.deviceProfile?.sections?.find(
        x => x.id === sectionId,
      );
      const inProgressDineInOrders = dineInOrdersInProgress();
      const occupiedTable = inProgressDineInOrders
        .map(order => order.table)
        .filter(table => !!table);
      return differenceBy(section?.tables, occupiedTable, t => t.id);
    },
    [dineInOrdersInProgress, session.deviceProfile?.sections],
  );

  const [cartInitialized, setCartInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (isFocused) {
      escapeDiscardModal.current = false;
      escapeDefaultScreenNavigation.current = false;
      setCartInitialized(false);
    }
  }, [isFocused]);

  const goToNewOrder = useCallback(async () => {
    await setCartParams(undefined, defaultOrderType?.id, '', false);
    const newOrderId = await resetCart();
    newOrderId &&
      navigation.setParams({
        id: newOrderId,
        isCompleted: false,
        isExisting: false,
        tableId: '',
        orderTypeId: defaultOrderType?.id,
      });
    setCartInitialized(false);
    selectCartItem(undefined);
    setSelectedSeatNumber(undefined);
    isCoursesEnabled && setCourses(allCourses);
  }, [
    setCartParams,
    defaultOrderType?.id,
    resetCart,
    navigation,
    isCoursesEnabled,
    allCourses,
  ]);

  useEffect(() => {
    if (
      order?.createdBy?.id &&
      order?.createdBy?.id !== activeUser?.id &&
      order.status === OrderStatus.CREATED
    ) {
      //when pos user changed then we create a new order
      goToNewOrder();
    }
  }, [activeUser, goToNewOrder, order?.createdBy?.id, order?.status]);

  const onChangeTrackedProductQuantities = useCallback(
    (updatedTrackedProductQuantities: IMap<number>) => {
      setQuantityOfProductsRemoved(updatedTrackedProductQuantities);
    },
    [],
  );

  //TODO Can be abstracted in a separate hook
  const getUpdatedProductQuantity = useCallback(
    (productId: string, quantity) => {
      const allProductsInCache = keyBy(getProductsFromCache(), 'id');
      return (
        (allProductsInCache[productId].inventory?.availableQuantity || 0) -
        quantity
      );
    },
    [getProductsFromCache],
  );

  const updateInventoryQuantity = useCallback(
    (args: IMap<number>) => {
      const updatedProduct: Record<string, Product> = {};
      const allProductsInCache = keyBy(getProductsFromCache(), 'id');
      Object.keys(args).forEach(productId => {
        const product: Product = cloneJSON(allProductsInCache[productId]);
        if (
          product?.isBeingTracked &&
          product?.inventory?.availableQuantity != null
        ) {
          product.inventory.availableQuantity = getUpdatedProductQuantity(
            productId,
            args[productId] && args[productId],
          );
          updatedProduct[productId] = product;
        }
      });
      updateProductsInCache(updatedProduct);
    },
    [getProductsFromCache, getUpdatedProductQuantity, updateProductsInCache],
  );

  const resetBackToOriginalQuantities = useCallback(() => {
    const trackedProductIds = Object.keys(quantityOfProductsRemoved);
    const removedTrackedProductQuantities = { ...quantityOfProductsRemoved };
    const updatedProducts: Record<string, Product> = {};

    trackedProductIds.forEach(productId => {
      const product = cloneJSON(allProducts[productId]);
      const currentQuantity =
        allProducts[productId].inventory?.availableQuantity || 0;
      product.inventory.availableQuantity =
        currentQuantity + Math.abs(quantityOfProductsRemoved[productId]);
      updatedProducts[productId] = product;
      removedTrackedProductQuantities[productId] = 0;
    });
    updateProductsInCache(updatedProducts);
    onChangeTrackedProductQuantities(removedTrackedProductQuantities);
  }, [
    quantityOfProductsRemoved,
    updateProductsInCache,
    onChangeTrackedProductQuantities,
    allProducts,
  ]);

  const onChangeProductQuantity = useCallback(
    (productId: string | undefined, quantity: number) => {
      productId && updateInventoryQuantity({ [productId]: quantity });
      if (productId && allProducts[productId].isBeingTracked) {
        onChangeTrackedProductQuantities({
          ...quantityOfProductsRemoved,
          [productId]: quantityOfProductsRemoved[productId] - quantity,
        });
      }
    },
    [
      allProducts,
      onChangeTrackedProductQuantities,
      quantityOfProductsRemoved,
      updateInventoryQuantity,
    ],
  );

  const onShowWarningModal = useCallback(
    (productId: string, updateCartCallback: (x?: boolean) => void) => {
      const { name, inventory } = allProducts[productId];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const availableQuantity = inventory!.availableQuantity!;

      showModal(
        <WarningModal
          title={translate(
            'takeOrderScreen.itemAvailability.warningModalTitle',
          )}
          description={translate(
            'takeOrderScreen.itemAvailability.warningModalDescription',
            {
              itemName: name,
            },
          )}
          confirmButtonText={translate(
            'takeOrderScreen.itemAvailability.warningModalAddButton',
          )}
          onCancel={noopHandler}
          onConfirm={updateCartCallback}
          secondConfirmButtonText={
            availableQuantity > 0
              ? translate('takeOrderScreen.itemAvailability.addItem', {
                  availableQuantity,
                })
              : translate('takeOrderScreen.itemAvailability.addItem', {
                  availableQuantity: 0,
                })
          }
          isSecondConfirmButtonDisabled={availableQuantity < 1 && true}
          onConfirmSecondButton={updateCartCallback}
        />,
        {
          onBackButtonPress: closeModal,
        },
      );
    },
    [allProducts, closeModal, showModal, translate],
  );
  useEffect(() => {
    if (!Object.keys(productsData).length && !getProductsFromCache().length) {
      getAllProducts();
    }
  }, [getAllProducts, getProductsFromCache, productsData]);

  useEffect(() => {
    if (
      allProducts &&
      Object.keys(allProducts).length &&
      !Object.keys(quantityOfProductsRemoved).length
    ) {
      const removedQuantities: IMap<number> = {};
      Object.keys(allProducts).forEach(key => {
        if (allProducts[key].isBeingTracked) {
          removedQuantities[key] = 0;
        }
      });
      Object.keys(removedQuantities).length > 0 &&
        setQuantityOfProductsRemoved(removedQuantities);
    }
  }, [allProducts, getAllProducts, quantityOfProductsRemoved]);

  useEffect(() => {
    if (!selectedPage && pages && pages.length) {
      setSelectedPage(pages[0].id);
    }
  }, [pages, selectedPage]);

  const goToPostOrderSaveScreen = useCallback(async () => {
    if (postSaleScreen && postSaleScreen !== AppScreen.NEW_ORDER) {
      await setCartParams(undefined, defaultOrderType?.id, '', false);
      const newOrderId = await resetCart();
      navigation.setParams({
        orderId: newOrderId,
        orderTypeId: defaultOrderType?.id,
        tableId: '',
        isExisting: false,
      });
      if (postSaleScreen == AppScreen.LOGIN) {
        postSalesObservableForLogin.next(true);
      } else {
        navigation.navigate(POSScreens[postSaleScreen]);
      }
    } else {
      await goToNewOrder();
    }
  }, [
    postSaleScreen,
    setCartParams,
    resetCart,
    navigation,
    defaultOrderType?.id,
    goToNewOrder,
  ]);

  const onPressNewOrder = useCallback(async () => {
    await goToPostOrderSaveScreen();
  }, [goToPostOrderSaveScreen]);

  useEffect(() => {
    currentStoreId && getAllPricingGroupsByStore(currentStoreId);
  }, [currentStoreId, getAllPricingGroupsByStore]);

  useEffect(() => {
    if (areProductAvailabilitiesChanged && isDirty) {
      showNotification({
        info: true,
        message: translate(
          'functionMaps.itemAvailability.subscriptionChangesMsg',
        ),
      });
      const productQuantitiesAlreadyRemoved: IMap<number> = {};
      Object.keys(quantityOfProductsRemoved).forEach((productId: string) => {
        productQuantitiesAlreadyRemoved[productId] =
          -quantityOfProductsRemoved[productId];
      });
      Object.keys(productQuantitiesAlreadyRemoved).length &&
        updateInventoryQuantity(productQuantitiesAlreadyRemoved);
    }
    areProductsAvailabilityUpdatedViaSubscription(false);
  }, [
    areProductAvailabilitiesChanged,
    isDirty,
    quantityOfProductsRemoved,
    showNotification,
    translate,
    updateInventoryQuantity,
  ]);

  useEffect(() => {
    if (!isFocused) {
      if (updatedSubscriptionOrders.length > 0)
        ordersUpdatedViaSubscription([]);
      return;
    }

    if (order?.id && updatedSubscriptionOrders.length > 0) {
      const orderIndex = updatedSubscriptionOrders.indexOf(order?.id);
      if (orderIndex !== -1) {
        mergeCachedOrderWithEvents(order?.id).then(updatedOrder => {
          if ((updatedOrder as Order)?.status !== OrderStatus.IN_PROGRESS) {
            let messageTxt = 'order.multiTerminal.orderCompleted';
            if ((updatedOrder as Order)?.status === OrderStatus.VOID)
              messageTxt = 'order.multiTerminal.orderVoided';

            showNotification({
              success: true,
              message: translate(messageTxt, {
                staff: (updatedOrder as Order)?.updatedBy?.name,
              }),
            });

            setTimeout(async () => {
              goToNewOrder();
            }, 2000);
          } else {
            showNotification({
              info: true,
              message: translate('order.multiTerminal.orderUpdated', {
                staff: (updatedOrder as Order)?.updatedBy?.name,
              }),
            });
          }
        });
      }
      // Resetting variable again!
      ordersUpdatedViaSubscription([]);
    }
  }, [
    isFocused,
    mergeCachedOrderWithEvents,
    goToNewOrder,
    order?.id,
    setCartParams,
    showNotification,
    translate,
    updatedSubscriptionOrders,
  ]);

  useEffect(() => {
    if (!cartInitialized) {
      setQuantityOfProductsRemoved({});
      setCartInitialized(true);
      setCartParams(
        params?.id,
        params?.orderType || defaultOrderType?.id,
        params?.table,
        params?.isExisting,
      );
      setSelectedSeatNumber(undefined);
      if (convertToBoolean(params?.isExisting)) {
        clearPriorPendingEvents();
      }
      // TODO add a proper util fn for comparison
      if (params?.id && !order && String(params?.isExisting) == 'false') {
        goToNewOrder();
      }
    }
  }, [
    cartInitialized,
    setCartParams,
    params,
    defaultOrderType,
    order,
    goToNewOrder,
    clearPriorPendingEvents,
  ]);

  useEffect(() => {
    const subscription = takeOrderUnFocusClearParamController.subscribe(() => {
      navigation.setParams({
        id: '',
        orderTypeId: '',
        tableId: '',
        isExisting: false,
      });
    });
    return () => subscription.unsubscribe();
  }, [navigation]);

  const { printBill } = usePrinting();

  const isTableManagementEnabled =
    session.deviceProfile?.enableFloorView &&
    session.deviceProfile?.sections &&
    session.deviceProfile?.sections.length > 0;

  const isAdvancedKeypad: boolean =
    session?.settings?.showAdvancedCartActionsSetting || false;
  const enableQuickPaymentMode: boolean =
    session?.settings?.enableQuickPaymentModeSetting || false;
  const [advancedKeypadValue, setAdvancedKeypadValue] = useState<number>(0);
  const escapeDiscardModal = useRef(false);
  const autoSaveOrder = useRef(false);
  const autoPayOrder = useRef(false);
  const quickPayFunctionMaps = useRef(false);

  const isItemReadyToFire = useCallback(
    (courseId = DEFAULT_ENTITY_ID) => {
      if (!isCoursesEnabled) return true;
      return !!courses.find(course => course.id === courseId)?.autoFire;
    },
    [courses, isCoursesEnabled],
  );

  useEffect(
    () => setAdvancedKeypadValue(0),
    [session?.settings?.showAdvancedCartActionsSetting],
  );

  useEffect(() => {
    if (!defaultPricingGroup) {
      const id = Object.keys(pricingGroups).filter(
        key => pricingGroups[key].name === DEFAULT_PRICING_GROUP,
      )[0];
      setDefaultPricingGroup(id);
    }
  }, [defaultPricingGroup, pricingGroups, session]);

  useEffect(() => {
    if (!params?.id) {
      goToNewOrder();
    }
  }, [params?.id, goToNewOrder]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  useEffect(() => {
    if (!isCoursesEnabled) return;
    setCourses(allCourses);
  }, [isCoursesEnabled, allCourses]);

  const isSeatManagementEnabled: boolean =
    (session?.deviceProfile?.enableSeatManagement &&
      orderType?.code == OrderTypeCode.DINE_IN) ||
    false;

  const orderItems = useMemo(() => {
    const orderItems = (order?.orderItems || [])
      .filter(x => x.status !== OrderItemStatus.CANCELLED)
      .map(x => ({
        ...x,
        product: products[x.product.id] || x.product,
        variant: (x.variant && variantMap[x.variant?.id]) || x.variant,
      }));
    if (selectedSeatNumber && isSeatManagementEnabled) {
      return orderItems.filter(item => item?.seatNumber == selectedSeatNumber);
    } else return orderItems;
  }, [
    order,
    products,
    variantMap,
    selectedSeatNumber,
    isSeatManagementEnabled,
  ]);

  const pricingGroupOptions = useMemo(() => {
    const pricingGroupsOnStore: Array<PricingGroup> = [];
    Object.keys(pricingGroups).forEach(key => {
      if (
        pricingGroups[key].name !== DEFAULT_PRICING_GROUP &&
        pricingGroups[key].isActive
      ) {
        // we only get pricing groups by current store
        pricingGroupsOnStore.push(pricingGroups[key]);
      }
    });
    const option = pricingGroupsOnStore.map(x => ({
      label: x.name,
      value: x.id,
    }));
    const selectedPricingGroup = option.find(x => x.value == pricingGroup);
    if (selectedPricingGroup) {
      const filteredOptions = option.filter(
        x => x.value != selectedPricingGroup.value,
      );
      return [
        { label: DEFAULT_PRICING_GROUP, value: defaultPricingGroup },
        selectedPricingGroup,
        ...filteredOptions,
      ] as {
        label: string;
        value: string;
      }[];
    }
    return [
      { label: DEFAULT_PRICING_GROUP, value: defaultPricingGroup },
      ...option,
    ] as {
      label: string;
      value: string;
    }[];
  }, [pricingGroups, pricingGroup, defaultPricingGroup]);

  const getPricingGroupId = useCallback(async () => {
    const isPriceListFeatureEnabled = isFeatureEnabled(
      Features.PRICE_LIST,
      FeatureContext.VENUE,
    );
    if (!isPriceListFeatureEnabled) {
      setPricingGroup(defaultPricingGroup);
      return;
    }

    const userExplicitSelectPricingGroup = await storage.getItem<boolean>(
      USER_EXPLICIT_SELECT_PRICING_GROUP,
    );

    if (userExplicitSelectPricingGroup !== true) {
      setPricingGroup('');
      return;
    }

    const id: string =
      (await storage.getItem(DEVICE_PRICING_GROUP_ID)) || defaultPricingGroup;
    if (pricingGroupOptions.find(x => x.value === id)) {
      setPricingGroup(id);
    } else {
      setPricingGroup(defaultPricingGroup);
    }
  }, [isFeatureEnabled, defaultPricingGroup, pricingGroupOptions]);

  useEffect(() => {
    getPricingGroupId();
  }, [
    getPricingGroupId,
    pricingGroup,
    defaultPricingGroup,
    pricingGroupOptions,
  ]);

  const getVariantProductBasedOnOptions = useCallback(
    (options: string[], variant: Variant) => {
      let variantProducts = variant.products;
      if (options.length) {
        options.forEach(option => {
          variantProducts = variantProducts.filter(
            product =>
              product.optionValues.filter(
                optionValue => optionValue.value === option,
              ).length,
          );
        });
      }
      return variantProducts[0];
    },
    [],
  );

  const pickDefaultVariant = useCallback(
    (variant: Variant) => variant.products.find(prod => prod.isDefault),
    [],
  );

  const unselectCartItem = useCallback(() => {
    selectedCartItem && selectCartItem(undefined);
    setSelectedVariantKey('');
  }, [selectedCartItem]);

  const addProductToCart = useCallback(
    (
      productInput: Product,
      variant?: Variant,
      quantity?: number,
      orderItemId?: string,
    ) => {
      if (isOrderComplete) {
        goToNewOrder();
        return;
      }
      const { id: productId, name: productName } = productInput;
      const product = products[productId];
      const pricing = getBestPrice(product as ProductAlias, {
        orderType: selectedOrderType,
        venue: session.currentVenue?.id,
        store: session.currentStore?.id,
        pricingGroupId: pricingGroup,
      });

      if (pricing) {
        const isTrackedProduct = !!products[productId].isBeingTracked;
        const productQuantity =
          (isTrackedProduct && product?.inventory?.availableQuantity) || 0;
        const productAvailableQuantity =
          productQuantity - (quantity || advancedKeypadValue || 1);

        const item = orderItems
          .filter(
            item =>
              item.status === OrderItemStatus.CREATED ||
              item.status === OrderItemStatus.ON_HOLD,
          )
          .find(
            x =>
              !x.notes &&
              !x.discountAmount &&
              !x.saved &&
              x.product.id === productId &&
              x.unitPrice === pricing.sellingPrice?.amount &&
              x.course?.id === product.course?.id &&
              x.seatNumber == selectedSeatNumber,
          );

        if (
          item &&
          (item.product.modifierGroups.length === 0 ||
            (item.product.isCombo &&
              item.product.modifierGroups.every(
                mod =>
                  mod.name === DEFAULT_OPTION_GROUP && mod?.products?.length,
              )))
        ) {
          const onUpdateQuantity = (onlyAddAvailableItems?: boolean) => {
            const quantityToReduce = onlyAddAvailableItems
              ? productQuantity
              : quantity || advancedKeypadValue || 1;
            updateCart<UpdateOrderItemQuantityEvent>(
              OrderAction.ORDER_ITEM_UPDATE_QUANTITY,
              {
                quantity: quantityToReduce,
                orderItemId: item.id,
                inventoryTracked: isTrackedProduct,
                productId,
              },
            );
            onChangeProductQuantity(productId, quantityToReduce);
          };
          if (
            productAvailableQuantity < 0 &&
            productAvailableQuantity < productQuantity &&
            isTrackedProduct
          ) {
            onShowWarningModal(productId, onUpdateQuantity);
          } else {
            onUpdateQuantity();
          }
        } else {
          let modifiers: Array<OrderItemModifier> = [];
          if (orderItemId) {
            const orderItem = orderItems.find(x => x.id === orderItemId);
            modifiers = orderItem?.modifiers as Array<OrderItemModifier>;
          } else if (
            productInput.isCombo &&
            productInput.modifierGroups.every(
              mod => mod.name === DEFAULT_OPTION_GROUP && mod?.products?.length,
            )
          ) {
            productInput.modifierGroups.forEach(modGroup => {
              modGroup.products?.length &&
                modGroup.products.forEach(prod =>
                  modifiers.push({
                    quantity: 1,
                    unitPrice: prod?.price?.amount || 0,
                    taxes: [],
                    id: prod.id,
                    name: prod.name,
                    modifierGroupId: modGroup.id,
                  }),
                );
            });
          }
          const onAddItem = (onlyAddAvailableItems?: boolean) => {
            const quantityToReduce = onlyAddAvailableItems
              ? productQuantity
              : quantity || advancedKeypadValue || 1;
            updateCart<AddOrderItemEvent>(OrderAction.ORDER_ITEM_ADD, {
              productId,
              productName,
              quantity: quantityToReduce,
              costPrice: pricing.costPrice?.amount ?? 0,
              unitPrice: pricing.sellingPrice?.amount ?? 0,
              taxInclusive: pricing.taxInclusive ?? false,
              taxes: pricing.sellingTax ? [pricing.sellingTax] : [],
              variantId: variant?.id,
              variantName: variant?.name,
              modifiers,
              fireItem: isItemReadyToFire(product.course?.id),
              courseId: product.course?.id,
              inventoryTracked: isTrackedProduct,
              seatNumber: isSeatManagementEnabled
                ? selectedSeatNumber
                : undefined,
            });
            onChangeProductQuantity(productId, quantityToReduce);
            setShowRequiredModifierModal(true);
          };
          const productQuantity =
            (isTrackedProduct && product?.inventory?.availableQuantity) || 0;
          if (
            productAvailableQuantity < 0 &&
            productAvailableQuantity < productQuantity &&
            isTrackedProduct
          ) {
            showRequiredModifierModal && setShowRequiredModifierModal(false);
            onShowWarningModal(productId, onAddItem);
          } else {
            onAddItem();
          }
        }
        setAdvancedKeypadValue(0);
      }
    },
    [
      isOrderComplete,
      products,
      selectedOrderType,
      session.currentVenue?.id,
      session.currentStore?.id,
      pricingGroup,
      goToNewOrder,
      advancedKeypadValue,
      orderItems,
      selectedSeatNumber,
      updateCart,
      onChangeProductQuantity,
      onShowWarningModal,
      isItemReadyToFire,
      isSeatManagementEnabled,
      showRequiredModifierModal,
    ],
  );

  const splitProductFromCart = useCallback(() => {
    const item = orderItems.find(x => x.id === selectedCartItem?.item);
    const modifiers = item?.modifiers as Array<OrderItemModifier>;
    const itemQuantity = item?.quantity;

    if (
      item &&
      (item.status === OrderItemStatus.CREATED ||
        item.status === OrderItemStatus.ON_HOLD) &&
      itemQuantity &&
      itemQuantity > 1
    ) {
      const isTrackedProduct = !!allProducts[item.product.id].isBeingTracked;
      updateCart<UpdateOrderItemQuantityEvent>(
        OrderAction.ORDER_ITEM_UPDATE_QUANTITY,
        {
          quantity: -(advancedKeypadValue || 1),
          orderItemId: item.id,
          inventoryTracked: isTrackedProduct,
          productId: item.product.id,
        },
      );
      updateCart<AddOrderItemEvent>(OrderAction.ORDER_ITEM_ADD, {
        productId: item.product.id,
        productName: item.product.name,
        quantity: advancedKeypadValue || 1,
        costPrice: item.costPrice,
        unitPrice: item.unitPrice,
        taxInclusive: item.taxInclusive,
        taxes: item.taxes,
        variantId: item.variant?.id,
        variantName: item.variant?.name,
        modifiers,
        fireItem: isItemReadyToFire(item.course?.id),
        courseId: item.course?.id,
        inventoryTracked: isTrackedProduct,
        seatNumber: item.seatNumber,
      });

      unselectCartItem();
    }
  }, [
    orderItems,
    selectedCartItem?.item,
    updateCart,
    advancedKeypadValue,
    isItemReadyToFire,
    allProducts,
    unselectCartItem,
  ]);

  const isProductQualifiedToBeInCart = useCallback(
    (product: ProductAlias, modifiers: OrderItemModifier[]) => {
      let result = true;
      if (product?.modifierGroups?.length) {
        const modifiersInCartWRTGroup = groupBy(modifiers, function (mod) {
          return mod.modifierGroupId;
        });
        product?.modifierGroups?.forEach(eachModGroup => {
          const modifiersInCart = modifiersInCartWRTGroup[eachModGroup?.id];
          const defaultModifiersInProductMap = eachModGroup?.modifiers?.reduce(
            (prev: Record<string, Modifier>, curr: Modifier) => {
              if (curr?.isDefault) {
                return { ...prev, [curr.id]: curr };
              } else {
                return prev;
              }
            },
            {} as Record<string, Modifier>,
          );

          const nonDefaultModifiers = (modifiersInCart || []).filter(
            x => !defaultModifiersInProductMap[x?.id || '']?.isDefault,
          );

          if (eachModGroup?.isRequired) {
            const min = eachModGroup?.selectionLimit?.min;
            const max = eachModGroup?.selectionLimit?.max;
            if (
              !(
                nonDefaultModifiers?.length &&
                nonDefaultModifiers?.length >= min &&
                nonDefaultModifiers?.length <= max
              )
            ) {
              result = false;
            }
          }
        });
      }
      return result;
    },
    [],
  );

  const removeItemFromCart = useCallback(
    (itemId: string, type: 'Variant' | 'Product') => {
      const variantProductItem = orderItems.filter((orderItem: OrderItem) =>
        type === 'Variant'
          ? orderItem?.variant?.id === itemId
          : orderItem?.product?.id === itemId,
      );
      if (variantProductItem.length) {
        let isToRemove = true;
        const cartItem = variantProductItem[variantProductItem.length - 1];
        if (cartItem?.product?.modifierGroups?.length) {
          // if product with modifier is not qualified then remove from cart
          const isQualifiedToBeInCart = isProductQualifiedToBeInCart(
            cartItem.product,
            cartItem.modifiers,
          );
          isToRemove = !isQualifiedToBeInCart;
          !isQualifiedToBeInCart &&
            // product cannot be included in the Order as you have not selected it's required number of modifiers
            showNotification({
              error: true,
              message: translate('order.orderItemRemoved', {
                name: cartItem.product?.name,
              }),
            });
        }
        isToRemove &&
          updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
            orderItemId: cartItem?.id,
            quantity: -cartItem?.quantity,
            inventoryTracked: !!allProducts[itemId].isBeingTracked,
            productId: itemId,
          });
        isToRemove &&
          cartItem.status !== OrderItemStatus.CANCELLED &&
          onChangeProductQuantity(itemId, -cartItem?.quantity);
        isToRemove && selectedCartItem && selectCartItem(undefined);
      }
    },
    [
      orderItems,
      updateCart,
      allProducts,
      onChangeProductQuantity,
      selectedCartItem,
      isProductQualifiedToBeInCart,
      showNotification,
      translate,
    ],
  );

  /**
   * @function addVariantProductToCart
   * @description Adds variant product to the cart.
   * @param {Array} selectedOptions - The variant option that is being added to the cart.
   * @param {Object} variant - The product(catalogue) or the parent with variant.
   */

  const addVariantProductToCart = useCallback(
    (selectedOptions, variant) => {
      if (variant) {
        const defaultVariant = Object.assign({}, pickDefaultVariant(variant));

        // Check if there is already a default variant in the cart
        // it shouldn't be added on the subsequent clicks
        const defaultInCart = orderItems.find(
          ordered => ordered.product.id === defaultVariant.id,
        );

        if (
          defaultVariant &&
          !defaultInCart &&
          !(selectedOptions && selectedOptions.length)
        ) {
          defaultVariant['stores'] = variant['stores'];
          addProductToCart(defaultVariant, variant);
        } else if (selectedOptions && selectedOptions.length) {
          const variantProduct = Object.assign(
            {},
            getVariantProductBasedOnOptions(selectedOptions, variant),
          );

          if (variantProduct) {
            variantProduct['stores'] = variant['stores'];
            addProductToCart(variantProduct, variant);
          }
        }
      }
    },
    [
      pickDefaultVariant,
      getVariantProductBasedOnOptions,
      addProductToCart,
      orderItems,
    ],
  );

  const replaceVariantInTheCart = useCallback(
    (orderItem, selectedProduct, selectedOptions) => {
      unselectCartItem();

      if (selectedCartItem && selectedCartItem.variant) {
        const itemInCart = order?.orderItems.find(
          item => item.id === orderItem,
        );
        const quantity = itemInCart?.quantity || 1;

        const selectedVariantProduct = (
          selectedProduct as Variant
        )?.products?.find(x => x.id === selectedCartItem.product);

        const matchingOptionsOfSelectedProduct =
          selectedVariantProduct?.optionValues?.filter(
            x => x && !selectedOptions.includes(x.value) && x.value,
          );

        if (
          matchingOptionsOfSelectedProduct?.length &&
          selectedCartItem.product
        ) {
          updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
            orderItemId: orderItem,
            quantity: -quantity,
            inventoryTracked:
              !!allProducts[selectedCartItem.product].isBeingTracked,
            productId: selectedCartItem.product,
          });

          itemInCart?.status !== OrderItemStatus.CANCELLED &&
            onChangeProductQuantity(selectedCartItem.product, -quantity);

          const variantProduct = Object.assign(
            {},
            getVariantProductBasedOnOptions(selectedOptions, selectedProduct),
          );
          variantProduct['stores'] = selectedProduct['stores'];
          addProductToCart(variantProduct, selectedProduct, quantity);
          setSelectedProduct(variantProduct);
          selectCartItem(selectedProduct);
        }
      }
      setSelectedVariantKey('');
    },
    [
      unselectCartItem,
      selectedCartItem,
      order?.orderItems,
      updateCart,
      allProducts,
      onChangeProductQuantity,
      getVariantProductBasedOnOptions,
      addProductToCart,
    ],
  );

  const getModifierDetails = useCallback(
    (productId, modifierGroupId, modifierId) => {
      const modGroups =
        (products[productId] as ProductAlias)?.modifierGroups || [];
      const selectedModifierGroup = modGroups.filter(
        x => x.id === modifierGroupId,
      );
      const selectedModifierDetails =
        selectedModifierGroup?.[0]?.modifiers.filter(
          x => x.id === modifierId,
        ) || [];

      return selectedModifierDetails;
    },
    [products],
  );

  const addOrRemoveModifier = useCallback(
    (selectedProd, selectedModifier, orderItemId) => {
      const selectedModifierDetails = getModifierDetails(
        selectedProd?.id,
        selectedModifier?.modifierGroup,
        selectedModifier?.id,
      );
      const isDefaultModRemoved =
        selectedModifier?.isDefault && selectedModifier?.isSelected;
      const isModToAdd = selectedModifier?.isDefault
        ? isDefaultModRemoved
        : selectedModifier?.id && !selectedModifier?.isSelected;
      if (isModToAdd) {
        // adds modifier to order item
        const bestPrice = getBestPriceOfModifier(selectedModifierDetails[0]);
        const tax = getTaxFromModifier(selectedModifierDetails[0]);
        const recentOrderItem =
          orderItemId || order?.orderItems?.[order?.orderItems?.length - 1]?.id;
        const modName = selectedModifierDetails?.[0]?.name || '';
        if (recentOrderItem) {
          updateCart<AddModifierEvent>(OrderAction.ORDER_ITEM_ADD_MODIFIER, {
            modifierId: selectedModifier.id,
            orderItemId: recentOrderItem,
            quantity: advancedKeypadValue || 1,
            taxes: tax,
            unitPrice: isDefaultModRemoved ? 0 : bestPrice,
            name: isDefaultModRemoved ? `- ${modName}` : modName,
            modifierGroupId: selectedModifier.modifierGroup,
          });
          setAdvancedKeypadValue(0);
        }
      } else if (!isModToAdd) {
        // removes modifier from order item
        const recentOrderItem =
          orderItemId || order?.orderItems?.[order?.orderItems?.length - 1]?.id;
        recentOrderItem &&
          updateCart<RemoveModifierEvent>(
            OrderAction.ORDER_ITEM_REMOVE_MODIFIER,
            {
              modifierId: selectedModifier.id,
              orderItemId: recentOrderItem,
              modifierGroupId: selectedModifier.modifierGroup,
            },
          );
      }
    },
    [getModifierDetails, order?.orderItems, updateCart, advancedKeypadValue],
  );

  const addModifierToCart = useCallback(
    (
      selectedProd: ProductAlias,
      selectedModifiers: CatalogModifier[],
      orderItemId,
    ) => {
      if (selectedModifiers.length) {
        selectedModifiers.forEach(eachSelectedMod =>
          addOrRemoveModifier(selectedProd, eachSelectedMod, orderItemId),
        );
      } else {
        addProductToCart(selectedProd);
      }
    },
    [addProductToCart, addOrRemoveModifier],
  );

  const areCartItemsValid = useCallback(
    (errorMessage: string): boolean => {
      let isValid = true;
      let productName = '';

      if (orderItems?.length > 0) {
        for (let i = orderItems.length - 1; i >= 0; i--) {
          const orderItem = orderItems?.[i];
          const isItemValid = isProductQualifiedToBeInCart(
            orderItem.product,
            orderItem.modifiers,
          );
          if (!isItemValid) {
            isValid = false;
            productName = orderItem?.product?.name;
            break;
          }
        }
      }

      !isValid &&
        showNotification({
          error: true,
          message: translate(errorMessage, {
            name: productName,
          }),
        });

      return isValid;
    },
    [isProductQualifiedToBeInCart, orderItems, showNotification, translate],
  );

  const payOrder = useCallback(
    (passedOrder?: Order): void => {
      escapeDiscardModal.current = true;
      const callEvent = areCartItemsValid('order.incorrectOrder');
      callEvent &&
        cacheAndNavigate({ variables: { data: passedOrder || order } });
      autoPayOrder.current = false;
    },
    [areCartItemsValid, cacheAndNavigate, order],
  );

  // FIXME: This needs to work within useCallback itself.
  // But right now Order always picks previous state (inside callback)
  // which is why table number gets neglected
  useEffect(() => {
    if (proceedPayOrder) {
      payOrder(order);
      setProceedPayOrder(false);
    }
  }, [order, proceedPayOrder, payOrder]);

  const assignCustomerToOrder = useCallback(
    (customer: Customer): void => {
      // trigger assign order to customer event
      updateCart<AssignCustomerEvent>(OrderAction.ORDER_ASSIGN_CUSTOMER, {
        customerId: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        loyaltyMember: customer.loyaltyMember,
        customerAccountDetails: isEmpty(customer?.customerAccountDetails)
          ? {}
          : {
              accountPayment: customer?.customerAccountDetails?.accountPayment,
              currentBalance: customer?.customerAccountDetails?.currentBalance,
              maxBalanceLimit:
                customer?.customerAccountDetails?.maxBalanceLimit,
              maxOrderLimit: customer?.customerAccountDetails?.maxOrderLimit,
            },
        isLoyaltyApplied: Boolean(isLoyaltyEnabled && customer.loyaltyMember),
      });
    },
    [isLoyaltyEnabled, updateCart],
  );

  const fireOrderItemsHandler = useCallback(
    (fireOrderItems: OrderItemFireStatus[]) => {
      if (!fireOrderItems.length) return;
      updateCart<FireOrderItemsEvent>(OrderAction.ORDER_FIRE_ITEMS, {
        orderItemsToFire: fireOrderItems,
      });
    },
    [updateCart],
  );

  const handleToggleAutoFire = useCallback(
    (inputCourseIds: string | string[]) => {
      const courseIds =
        typeof inputCourseIds === 'string' ? [inputCourseIds] : inputCourseIds;

      setCourses(prevCourses => {
        const orderItemsToFire: OrderItemFireStatus[] = [];
        const updatedCourses = prevCourses.map(course => {
          const { id: courseId } = course;
          if (courseIds.includes(courseId)) {
            const orderItemIdsByCourse = orderItems
              .filter(orderItem => {
                if (courseId === DEFAULT_ENTITY_ID)
                  return !orderItem.course?.id;
                return orderItem.course?.id === courseId;
              })
              .map(orderItem => ({
                id: orderItem.id,
                status: !course.autoFire,
              })) as OrderItemFireStatus[];

            orderItemsToFire.push(...orderItemIdsByCourse);

            return {
              ...course,
              autoFire: !course.autoFire,
            };
          }
          return course;
        });

        fireOrderItemsHandler(orderItemsToFire);
        return updatedCourses;
      });
    },
    [orderItems, fireOrderItemsHandler],
  );

  const unassignCustomerToOrder = useCallback((): void => {
    // trigger assign order to customer event
    updateCart<OrderEvent>(OrderAction.ORDER_UNASSIGN_CUSTOMER);
  }, [updateCart]);

  const onSelectCartItem = useCallback(
    (state: CartSelectionState) => {
      if (!isOrderComplete) {
        if (state.selectedVariantKey) {
          setSelectedVariantKey(state.selectedVariantKey);
        } else {
          setSelectedVariantKey('');
        }
        selectCartItem(currentState => {
          //This will unselected the modifier when the selected modifier is clicked again in the cart.
          if (
            state.selectedModifierKey &&
            currentState?.selectedModifierKey !== state.selectedModifierKey
          ) {
            return state;
          }
          if (currentState?.item === state.item) {
            return undefined;
          }
          return state;
        });
      }
    },
    [isOrderComplete],
  );

  const saveOrder = useCallback(async () => {
    escapeDiscardModal.current = true;
    autoSaveOrder.current = false;
    const callEvent = areCartItemsValid('order.incorrectOrder');
    callEvent && (await updateCart(OrderAction.ORDER_SAVE));
    if (!escapeDefaultScreenNavigation.current)
      callEvent && (await goToPostOrderSaveScreen());
    else goToNewOrder();
    closeOrderCart();
  }, [
    areCartItemsValid,
    goToPostOrderSaveScreen,
    goToNewOrder,
    updateCart,
    closeOrderCart,
  ]);

  const onSaveOrder = useCallback(async () => {
    await saveOrder();
  }, [saveOrder]);

  const onAssignCustomerToOrder = useCallback(
    customer => {
      assignCustomerToOrder(customer);
      if (autoSaveOrder.current) saveOrder();
      closeModal();
    },
    [saveOrder, assignCustomerToOrder, closeModal],
  );

  const onClickCustomer = useCallback(
    () =>
      !isOrderComplete &&
      showModal(
        <SearchCustomer
          orderId={orderId}
          assignCustomerToOrder={onAssignCustomerToOrder}
          unassignCustomerToOrder={unassignCustomerToOrder}
          onClose={autoSaveOrder.current ? saveOrder : undefined}
          assignedCustomerId={order?.customer?.id as string}
        />,
        {
          onBackdropPress: closeModal,
        },
      ),
    [
      isOrderComplete,
      showModal,
      orderId,
      order?.customer,
      closeModal,
      unassignCustomerToOrder,
      saveOrder,
      onAssignCustomerToOrder,
    ],
  );

  const onConfirmSetQuantity = useCallback(
    (quantity: number, reason?: VoidReason) => {
      if (selectedCartItem) {
        const item = orderItems.find(x => x.id === selectedCartItem.item);
        const quantityToBeUpdated = quantity - (item?.quantity || 0);
        const productQuantity =
          (products[item?.product?.id || '']?.inventory &&
            products[item?.product?.id || ''].inventory?.availableQuantity) ||
          0;
        const productAvailableQuantity = productQuantity - quantityToBeUpdated;

        if (
          quantityToBeUpdated > 0 &&
          !(item?.status === OrderItemStatus.CREATED) &&
          !selectedCartItem?.modifier
        ) {
          addProductToCart(
            { id: item?.product?.id } as Product,
            { id: item?.variant?.id } as Variant,
            quantityToBeUpdated,
            item?.id,
          );
        } else if (
          selectedCartItem?.modifier &&
          selectedCartItem?.modifierGroup
        ) {
          const selectedModifierDetails = getModifierDetails(
            selectedCartItem?.product,
            selectedCartItem?.modifierGroup,
            selectedCartItem?.modifier,
          );
          if (selectedModifierDetails?.length) {
            updateCart<UpdateModifierQuantityEvent>(
              OrderAction.ORDER_ITEM_UPDATE_MODIFIER_QUANTITY,
              {
                modifierId: selectedCartItem.modifier,
                orderItemId: selectedCartItem.item,
                quantity: quantity,
                modifierGroupId: selectedCartItem.modifierGroup,
              },
            );
          }
        } else {
          const allowVoidItem = canI(
            [{ onResource: Resource.VOID_ORDER_ITEMS }],
            {
              prompt: true,
            },
          );
          if (!allowVoidItem) return;
          if (quantity > 0) {
            const onUpdateQuantity = (onlyAddAvailableItems?: boolean) => {
              const quantityToReduce = onlyAddAvailableItems
                ? productQuantity
                : quantityToBeUpdated;
              updateCart<UpdateOrderItemQuantityEvent>(
                OrderAction.ORDER_ITEM_UPDATE_QUANTITY,
                {
                  quantity: quantityToReduce,
                  orderItemId: selectedCartItem.item,
                  reason: reason,
                  inventoryTracked:
                    !!allProducts[item?.product?.id || ''].isBeingTracked,
                  productId: item?.product?.id || '',
                },
              );

              item?.status !== OrderItemStatus.CANCELLED &&
                onChangeProductQuantity(item?.product?.id, quantityToReduce);
            };
            if (
              productAvailableQuantity < 0 &&
              productAvailableQuantity < productQuantity &&
              !!allProducts[item?.product?.id || ''].isBeingTracked
            ) {
              onShowWarningModal(item?.product?.id || '', onUpdateQuantity);
            } else {
              onUpdateQuantity();
            }
          } else {
            updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
              orderItemId: selectedCartItem.item,
              reason,
              quantity: -(item?.quantity || 0),
              inventoryTracked:
                allProducts[item?.product?.id || ''].isBeingTracked,
              productId: item?.product?.id || '',
            });

            item?.status !== OrderItemStatus.CANCELLED &&
              onChangeProductQuantity(
                item?.product?.id,
                -(item?.quantity || 0),
              );
          }
          unselectCartItem();
        }
        setAdvancedKeypadValue(0);
      }
    },
    [
      selectedCartItem,
      orderItems,
      products,
      addProductToCart,
      getModifierDetails,
      updateCart,
      unselectCartItem,
      allProducts,
      onChangeProductQuantity,
      onShowWarningModal,
      canI,
    ],
  );

  const isOrderItemValidAfterModifierDeletion = useCallback(
    (orderItem: OrderItem, selectedCartItem) => {
      const modifierI = orderItem?.modifiers?.findIndex(
        modifier =>
          modifier.id === selectedCartItem.modifier &&
          modifier.modifierGroupId === selectedCartItem.modifierGroup,
      ) as number;

      modifierI >= 0 && orderItem?.modifiers.splice(modifierI, 1);

      return isProductQualifiedToBeInCart(
        orderItem?.product as Product,
        orderItem?.modifiers as OrderItemModifier[],
      );
    },
    [isProductQualifiedToBeInCart],
  );

  const clearCart = useCallback(() => {
    discardChanges();
    closeOrderCart();
    resetBackToOriginalQuantities();
    closeModal();
  }, [
    closeModal,
    discardChanges,
    resetBackToOriginalQuantities,
    closeOrderCart,
  ]);

  const calculateReductionAmount = useCallback(
    (orderItem: OrderItem, quantityToBeKept?: number) => {
      const amountPerItem =
        computeOrderItemValue(orderItem) / orderItem.quantity;

      const reductionQuantity = quantityToBeKept
        ? orderItem.quantity - quantityToBeKept
        : orderItem.quantity;

      return amountPerItem * reductionQuantity;
    },
    [],
  );

  const isValidTotalPriceBeforeRemoveItem = useCallback(
    (quantityToBeKept?: number) => {
      const orderItemsCopy = cloneJSON(orderItems);
      const selectedOrderItem = orderItemsCopy.find(
        (orderItem: OrderItem) => orderItem.id === selectedCartItem?.item,
      );

      if (!selectedOrderItem) return false;
      let reductionAmount = 0;

      if (selectedOrderItem.status === OrderItemStatus.IN_PROGRESS) {
        reductionAmount = calculateReductionAmount(
          selectedOrderItem,
          quantityToBeKept,
        );
      } else {
        if (selectedCartItem?.modifier && selectedCartItem?.modifierGroup) {
          const selectedModifier = selectedOrderItem.modifiers.find(
            (x: OrderItemModifier) => x.id === selectedCartItem.modifier,
          );

          const isItemValid = isOrderItemValidAfterModifierDeletion(
            selectedOrderItem as OrderItem,
            selectedCartItem,
          );

          if (isItemValid) {
            reductionAmount = selectedModifier?.unitPrice || 0;
          } else {
            reductionAmount = computeOrderItemValue(selectedOrderItem);
          }
        } else {
          reductionAmount = calculateReductionAmount(
            selectedOrderItem,
            quantityToBeKept,
          );
        }
      }

      const totalPrice = order?.totalPrice || 0;
      const paidAmount = calculateTotalPaidAmount(order?.payments || []);
      return totalPrice - reductionAmount >= paidAmount;
    },
    [
      orderItems,
      selectedCartItem,
      order?.totalPrice,
      order?.payments,
      calculateReductionAmount,
      isOrderItemValidAfterModifierDeletion,
    ],
  );

  const onPressDangerAction = useCallback(
    (quantityToBeKept?: number) => {
      if (selectedCartItem) {
        if (!isValidTotalPriceBeforeRemoveItem(quantityToBeKept)) {
          showNotification({
            message: translate('payment.amountCannotBeLessThanRemaining'),
            error: true,
          });
          unselectCartItem();
          return;
        }
        const orderItemsCopy = cloneJSON(orderItems);
        const orderItem = orderItemsCopy.find(
          (orderItem: OrderItem) => orderItem.id === selectedCartItem.item,
        );
        //if selected item is a modifier, we check for qty and decrement by 1, if its only 1, then we remove it
        if (selectedCartItem?.modifier && selectedCartItem?.modifierGroup) {
          const modifierQuantity =
            orderItem.modifiers.find(
              (x: OrderItemModifier) => x.id === selectedCartItem.modifier,
            )?.quantity || 0;
          if (modifierQuantity > 1) {
            const quantity = modifierQuantity - 1;
            updateCart<UpdateModifierQuantityEvent>(
              OrderAction.ORDER_ITEM_UPDATE_MODIFIER_QUANTITY,
              {
                modifierId: selectedCartItem.modifier,
                orderItemId: selectedCartItem.item,
                quantity: quantity,
                modifierGroupId: selectedCartItem.modifierGroup,
              },
            );
          } else {
            const isItemValid = isOrderItemValidAfterModifierDeletion(
              orderItem as OrderItem,
              selectedCartItem,
            );

            if (isItemValid) {
              updateCart<RemoveModifierEvent>(
                OrderAction.ORDER_ITEM_REMOVE_MODIFIER,
                {
                  modifierGroupId: selectedCartItem?.modifierGroup || '',
                  modifierId: selectedCartItem?.modifier,
                  orderItemId: selectedCartItem?.item,
                },
              );
            } else {
              showNotification({
                error: true,
                message: translate('order.orderItemRemoved', {
                  name: orderItem?.product?.name,
                }),
              });
              updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
                orderItemId: orderItem?.id as string,
                quantity: -orderItem?.quantity as number,
                inventoryTracked:
                  !!allProducts[orderItem?.product.id as string].isBeingTracked,
                productId: orderItem?.product.id as string,
              });

              orderItem?.status !== OrderItemStatus.CANCELLED &&
                onChangeProductQuantity(
                  orderItem?.product.id as string,
                  -orderItem?.quantity as number,
                );
            }
          }
        } else {
          if (
            orderItem?.status === OrderItemStatus.CREATED ||
            orderItem?.status === OrderItemStatus.ON_HOLD
          ) {
            if (quantityToBeKept) onConfirmSetQuantity(quantityToBeKept);
            else {
              updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
                orderItemId: selectedCartItem.item,
                quantity: -orderItem?.quantity as number,
                inventoryTracked:
                  !!allProducts[orderItem?.product.id as string].isBeingTracked,
                productId: orderItem?.product.id as string,
              });
              orderItem?.status !== OrderItemStatus.CANCELLED &&
                onChangeProductQuantity(
                  orderItem?.product.id as string,
                  -orderItem?.quantity as number,
                );
            }
          }
        }
        unselectCartItem();
      } else if (order?.status === OrderStatus.CREATED) {
        showModal(
          <PromptModal
            onSubmit={clearCart}
            title={translate('order.clearCart')}
            description={translate('order.clearCartDesc')}
            actionButtonTitle={translate('settings.confirm')}
          />,
        );
      } else if (order?.status === OrderStatus.IN_PROGRESS) {
        discardChanges();
        resetBackToOriginalQuantities();
        closeOrderCart();
      }
    },
    [
      selectedCartItem,
      order?.status,
      isValidTotalPriceBeforeRemoveItem,
      orderItems,
      unselectCartItem,
      showNotification,
      updateCart,
      isOrderItemValidAfterModifierDeletion,
      translate,
      allProducts,
      onChangeProductQuantity,
      onConfirmSetQuantity,
      showModal,
      clearCart,
      discardChanges,
      resetBackToOriginalQuantities,
      closeOrderCart,
    ],
  );

  const onConfirmUpdateNotes = useCallback(
    (notes: string) => {
      if (selectedCartItem) {
        updateCart<AddOrderItemNotesEvent>(OrderAction.ORDER_ITEM_ADD_NOTES, {
          note: notes,
          orderItemId: selectedCartItem.item,
        });
        selectCartItem(undefined);
      } else {
        updateCart<UpdateOrderNotesEvent>(
          OrderAction.ORDER_UPDATE_ORDER_NOTES,
          {
            notes,
          },
        );
      }
    },
    [updateCart, selectedCartItem],
  );

  const onSubmitNotes = useCallback(
    (notes: string) => {
      onConfirmUpdateNotes(notes);
      if (autoSaveOrder.current) saveOrder();
    },
    [onConfirmUpdateNotes, saveOrder],
  );

  const onPressUpdateOrderNotes = useCallback(() => {
    const selectedItem =
      selectedCartItem &&
      order?.orderItems.find(x => x.id === selectedCartItem.item);

    escapeDiscardModal.current = false;

    if (selectedItem) {
      showModal(
        <AddNoteModal
          value={selectedItem.notes}
          onSubmit={onConfirmUpdateNotes}
        />,
      );
    } else {
      showModal(
        <AddNoteModal
          value={order?.orderNote}
          onSubmit={onSubmitNotes}
          onClose={autoSaveOrder.current ? saveOrder : undefined}
        />,
      );
    }
  }, [
    showModal,
    order,
    onConfirmUpdateNotes,
    selectedCartItem,
    saveOrder,
    onSubmitNotes,
  ]);

  const onConfirmOrderType = useCallback(
    (orderTypeId: string) => {
      closeModal();
      updateCart<AssignOrderTypeEvent>(OrderAction.ORDER_ASSIGN_ORDER_TYPE, {
        orderTypeId,
        orderTypeName: orderTypes?.find(o => o.id === orderTypeId)
          ?.name as string,
      });
    },
    [updateCart, closeModal, orderTypes],
  );

  const onPressChangeOrderType = useCallback(() => {
    showModal(
      <PickerModal
        onSelect={onConfirmOrderType}
        title={translate('order.changeOrderType')}
        options={(orderTypes || []).map(x => ({
          label: x.name,
          value: x.id,
        }))}
      />,
    );
  }, [showModal, orderTypes, translate, onConfirmOrderType]);

  const getSelectedModiferInCart = useCallback(
    (selectedCartItem: CartSelectionState, parentItem: OrderItem) => {
      const selectedMod = parentItem?.modifiers?.find(
        x =>
          x.modifierGroupId === selectedCartItem.modifierGroup &&
          x.id === selectedCartItem.modifier &&
          x,
      );
      return selectedMod;
    },
    [],
  );

  const onPressUpdateQuantity = useCallback(() => {
    let item = orderItems.find(x => x.id === selectedCartItem?.item);
    if (selectedCartItem?.modifier) {
      const selectedMod = getSelectedModiferInCart(
        selectedCartItem,
        item as OrderItem,
      );

      item = {
        quantity: selectedMod?.quantity,
        id: selectedMod?.id,
        unitPrice: selectedMod?.unitPrice,
        product: {
          id: selectedMod?.id,
          name: selectedMod?.name,
        } as unknown as ProductAlias,
        status: item?.status as OrderItemStatus,
      } as OrderItem;
    }

    if (item) {
      showModal(
        <SetQuantityModal item={item} onSubmit={onConfirmSetQuantity} />,
      );
    }
  }, [
    selectedCartItem,
    orderItems,
    showModal,
    onConfirmSetQuantity,
    getSelectedModiferInCart,
  ]);

  const onConfirmSetPrice = useCallback(
    (unitPrice: number, adjustments: Adjustment[]) => {
      if (selectedCartItem) {
        updateCart<UpdateOrderItemPriceEvent>(
          OrderAction.ORDER_ITEM_UPDATE_PRICE,
          {
            unitPrice,
            adjustments,
            orderItemId: selectedCartItem?.item || '',
          },
        );
        selectCartItem(undefined);
      } else {
        updateCart(OrderAction.ORDER_ADD_ADJUSTMENT, {
          adjustment: adjustments[0],
        });
      }
    },
    [updateCart, selectedCartItem],
  );

  const onPressUpdatePrice = useCallback(() => {
    const allowAdjustment = canI([{ onResource: Resource.ALLOW_ADJUSTMENTS }], {
      prompt: true,
    });
    if (!allowAdjustment) return;
    const item =
      selectedCartItem && orderItems.find(x => x.id === selectedCartItem?.item);

    if (item) {
      const product = products[item.product.id];
      const pricing = getBestPrice(product as ProductAlias, {
        orderType: selectedOrderType,
        venue: session.currentVenue?.id,
        store: session.currentStore?.id,
      });

      showModal(
        <SetPriceModal
          item={item}
          onSubmit={onConfirmSetPrice}
          defaultPrice={pricing.sellingPrice.amount}
          minSellingPrice={product.minSellingPrice}
          maxSellingPrice={product.maxSellingPrice}
          keypadInput={
            advancedKeypadValue ? advancedKeypadValue.toString() : ''
          }
        />,
      );
    } else {
      showModal(
        <SetPriceModal
          item={
            {
              unitPrice: order?.subTotal || 0,
              quantity: 1,
              discounts: order?.discounts || [],
            } as OrderItem
          }
          onSubmit={onConfirmSetPrice}
          maxDiscounts={1}
          priceOverrideMode="discount"
          defaultPrice={order?.subTotal || 0}
          title={translate('order.setOrderPrice')}
          keypadInput={
            advancedKeypadValue ? advancedKeypadValue.toString() : ''
          }
        />,
        {
          onBackdropPress: closeModal,
        },
      );
    }
  }, [
    selectedCartItem,
    orderItems,
    products,
    selectedOrderType,
    session.currentVenue?.id,
    session.currentStore?.id,
    showModal,
    closeModal,
    onConfirmSetPrice,
    order,
    translate,
    advancedKeypadValue,
    canI,
  ]);

  const onTableNumberChange = useCallback(
    (tableNumber: string, guestCount: number) => {
      escapeDiscardModal.current = false;
      updateCart(OrderAction.ORDER_ASSIGN_TABLE, {
        tableId: tableNumber,
        tableName: tableNumber,
        guestCount,
      });
    },
    [updateCart],
  );

  const navigateToOrder = useCallback(
    (orderId: string) => {
      // This is to escape the modal
      escapeDiscardModal.current = true;
      navigation.setParams({
        id: orderId,
        isCompleted: false,
        isExisting: true,
      });
      setCartInitialized(false);
    },
    [navigation],
  );

  const askConfirmationForTableTransfer = useCallback(
    (callback: () => void) => {
      if (order?.status === OrderStatus.CREATED) {
        callback();
      } else if (itemsChanged) {
        showNotification({
          error: true,
          message: translate('order.notificationOnModifiedOrderTableChange'),
        });
      } else {
        showModal(
          <ConfirmationModal
            title={translate('order.confirmTableTransfer')}
            message={translate('order.ConfirmTableTransferMessage')}
            onConfirm={callback}
          />,
        );
      }
    },
    [order?.status, itemsChanged, showNotification, translate, showModal],
  );

  const submitTableNumber = useCallback(
    (tableNumber: string, guestCount: number, callback?: Function) => {
      if (!+tableNumber.split('-')[1]) {
        return showNotification({
          error: true,
          message: translate('order.invalidTableNumber'),
        });
      }
      const existingOrder = dineInOrdersInProgress().find(
        order => order.table?.name === tableNumber,
      );

      if (
        existingOrder &&
        existingOrder.id !== order?.id &&
        ((order?.status === OrderStatus.CREATED && order?.orderItems.length) ||
          autoSaveOrder.current ||
          autoPayOrder.current ||
          quickPayFunctionMaps.current)
      ) {
        return showNotification({
          error: true,
          message: translate('order.existingTableNumberError'),
        });
      } else if (
        existingOrder &&
        existingOrder.id !== order?.id &&
        !(
          autoSaveOrder.current ||
          autoPayOrder.current ||
          quickPayFunctionMaps.current
        )
      ) {
        navigateToOrder(existingOrder.id);
        closeModal();
      } else {
        if (
          order?.table?.name !== tableNumber ||
          order?.table?.guestCount !== guestCount
        ) {
          const updateTableNumber = () => {
            onTableNumberChange(
              tableNumber,
              guestCount || order?.table?.guestCount || 1,
            );
            closeModal();
          };
          askConfirmationForTableTransfer(updateTableNumber);
        }
      }
      if (autoSaveOrder.current) {
        saveOrder();
        closeModal();
      }
      if (autoPayOrder.current) {
        setProceedPayOrder(true);
        closeModal();
      }

      if (quickPayFunctionMaps.current && callback) {
        closeModal();
        callback();
      }
    },
    [
      dineInOrdersInProgress,
      order,
      closeModal,
      showNotification,
      translate,
      navigateToOrder,
      askConfirmationForTableTransfer,
      onTableNumberChange,
      saveOrder,
    ],
  );

  const openTableNumberModal = useCallback(
    (callback?: () => void) => {
      if (callback) quickPayFunctionMaps.current = true;
      const onSubmitTableNumberModal = (
        tableNumber: string,
        guestCount: number,
      ) => submitTableNumber(tableNumber, guestCount, callback);

      showModal(
        <SetTableNameModal order={order} onSubmit={onSubmitTableNumberModal} />,
      );
    },
    [submitTableNumber, order, showModal],
  );

  const openSectionSelectModal = useCallback(
    (
      _: SetOrderTableChainState,
      onSelect: (id: string) => void,
      onBack: () => void,
    ) => {
      const onSelectSection = (sectionId: string) => {
        const availableTable = getAvailableTablesBySection(sectionId);
        if (availableTable.length === 0) {
          showNotification({
            error: true,
            message: translate('order.noAvailableTableForSection'),
          });
        } else {
          onSelect(sectionId);
        }
      };
      showModal(
        <SectionsSelectModal
          onSelectSection={onSelectSection}
          onClose={onBack}
        />,
      );
    },
    [showModal, showNotification, translate, getAvailableTablesBySection],
  );

  const openTableSelectModal = useCallback(
    (
      { sectionId }: SetOrderTableChainState,
      onSelect: (id: string) => void,
      onBack: () => void,
    ) => {
      const availableTable = getAvailableTablesBySection(sectionId as string);
      showModal(
        <PickerModal
          onSelect={onSelect}
          title={translate('order.selectTable')}
          options={sortTablesByName(availableTable).map(x => ({
            label: x.name,
            value: x.id,
          }))}
          closeBtn={
            <IconButton
              icon="arrow-left"
              containerStyle={css(closeButtonStyle)}
              onPress={onBack}
            />
          }
        />,
      );
    },
    [showModal, getAvailableTablesBySection, css, translate],
  );

  const openSetGuestModal = useCallback(
    (
      _: SetOrderTableChainState,
      onSelect: (g: number) => void,
      onBack: () => void,
    ) => {
      showModal(<SetGuestModal onSubmit={onSelect} closeModal={onBack} />);
    },
    [showModal],
  );

  const onFinishSelectTableAndGuest = useCallback(
    (state: SetOrderTableChainState) => {
      const section = session.deviceProfile?.sections?.find(
        x => x.id === state?.sectionId,
      );
      const table = section?.tables.find(x => x.id === state?.tableId);
      updateCart<AssignTableEvent>(OrderAction.ORDER_ASSIGN_TABLE, {
        tableId: state.tableId,
        tableName: table?.name as string,
        guestCount: state.guest,
        sectionId: section?.id as string,
        sectionName: section?.name as string,
      });
      closeModal();
      onFinishSetTable.current && onFinishSetTable.current();
      willProceedPayOrderRef.current && setProceedPayOrder(true);
      onFinishSetTable.current = undefined;
    },
    [closeModal, updateCart, session],
  );

  const openTableModalChain = useChainModal<SetOrderTableChainState>(
    useMemo(() => {
      const steps = [
        {
          action: openSectionSelectModal,
          transformSubmitParamToState: (id: string) => ({ sectionId: id }),
        },
        {
          action: openTableSelectModal,
          transformSubmitParamToState: (id: string) => ({ tableId: id }),
        },
        {
          action: openSetGuestModal,
          transformSubmitParamToState: (count: number) => ({ guest: count }),
        },
      ];
      if ((session?.deviceProfile as DeviceProfile)?.sections?.length > 1) {
        return steps;
      }
      return steps.slice(1, 3);
    }, [
      openSectionSelectModal,
      openTableSelectModal,
      openSetGuestModal,
      session.deviceProfile,
    ]),
    onFinishSelectTableAndGuest,
    useMemo(
      () =>
        (session?.deviceProfile as DeviceProfile)?.sections?.length === 1
          ? {
              sectionId: (session?.deviceProfile as DeviceProfile)?.sections[0]
                ?.id,
            }
          : {},
      [session?.deviceProfile],
    ),
  );

  const setOrderTypeToDineIn = useCallback(() => {
    const dineInOrderType = orderTypes?.find(
      orderType => orderType.code === OrderTypeCode.DINE_IN,
    );
    if (dineInOrderType) {
      if (orderType?.code !== OrderTypeCode.DINE_IN) {
        updateCart<AssignOrderTypeEvent>(OrderAction.ORDER_ASSIGN_ORDER_TYPE, {
          orderTypeId: dineInOrderType.id,
          orderTypeName: dineInOrderType.name,
        });
      }
    }
  }, [orderTypes, orderType?.code, updateCart]);

  const onPressTableNumber = useCallback(() => {
    if (isTableManagementEnabled) {
      if (order?.status !== OrderStatus.CREATED) {
        escapeDiscardModal.current = false;
        navigation.navigate('FloorView');
        return;
      }
      setOrderTypeToDineIn();
      openTableModalChain();
    } else {
      setOrderTypeToDineIn();
      if (advancedKeypadValue) {
        const tableNumber = `T-${advancedKeypadValue}`;
        const existingOrder = dineInOrdersInProgress().find(
          order => order.table?.name === tableNumber,
        );
        if (
          existingOrder &&
          existingOrder.id !== order?.id &&
          order?.status === OrderStatus.CREATED &&
          order?.orderItems.length
        ) {
          return showNotification({
            error: true,
            message: translate('order.existingTableNumberError'),
          });
        } else if (existingOrder) {
          navigateToOrder(existingOrder.id);
        } else {
          if (order?.table?.name !== tableNumber) {
            const updateTableNumber = () => {
              onTableNumberChange(tableNumber, order?.table?.guestCount || 1);
              closeModal();
            };
            askConfirmationForTableTransfer(updateTableNumber);
          }
        }
        setAdvancedKeypadValue(0);
      } else {
        openTableNumberModal();
      }
    }
  }, [
    advancedKeypadValue,
    dineInOrdersInProgress,
    order,
    showNotification,
    translate,
    navigateToOrder,
    askConfirmationForTableTransfer,
    onTableNumberChange,
    closeModal,
    openTableNumberModal,
    setOrderTypeToDineIn,
    isTableManagementEnabled,
    openTableModalChain,
    navigation,
  ]);

  const requestToSetTableOrder = useCallback(
    (callback?: () => void, willProceedPayOrder?: boolean) => {
      if (isTableManagementEnabled) {
        onFinishSetTable.current = callback;
        willProceedPayOrderRef.current = willProceedPayOrder;
        openTableModalChain();
      } else {
        openTableNumberModal(callback);
      }
    },
    [openTableModalChain, isTableManagementEnabled, openTableNumberModal],
  );

  const getNextActionByOrderType = useCallback(
    (type: string) => {
      switch (type) {
        case OrderTypeCode.DINE_IN:
          return order?.table ? undefined : requestToSetTableOrder;
        case OrderTypeCode.DELIVERY:
          return order?.status !== OrderStatus.CREATED || order?.customer
            ? undefined
            : onClickCustomer;
        default:
          return order?.status !== OrderStatus.CREATED || order?.orderNote
            ? undefined
            : onPressUpdateOrderNotes;
      }
    },
    [onClickCustomer, onPressUpdateOrderNotes, requestToSetTableOrder, order],
  );

  const onPressSave = useCallback(() => {
    escapeDiscardModal.current = true;
    autoSaveOrder.current = true;
    const callDineInFlow =
      orderType?.code && getNextActionByOrderType(orderType?.code);
    selectCartItem(undefined);
    callDineInFlow ? callDineInFlow(saveOrder) : saveOrder();
  }, [orderType?.code, getNextActionByOrderType, saveOrder]);

  const checkForUnfiredItems = useCallback(
    callback => {
      const pendingOrderItems = orderItems.filter(
        item => item.status === OrderItemStatus.ON_HOLD,
      );

      const onPressFireItems = () => {
        callback();
      };

      if (pendingOrderItems.length) {
        showModal(<UnFiredItemsWarningModal onConfirm={onPressFireItems} />);
      } else {
        callback();
      }
    },
    [orderItems, showModal],
  );

  const onPressPay = useCallback(async () => {
    checkForUnfiredItems(() => {
      autoPayOrder.current = true;
      if (orderType?.code === OrderTypeCode.DINE_IN && !order?.table)
        requestToSetTableOrder(undefined, true);
      else payOrder();
    });
  }, [
    checkForUnfiredItems,
    order?.table,
    orderType?.code,
    payOrder,
    requestToSetTableOrder,
  ]);

  const openOrderAwayNavigation = useCallback(
    (
      e?: EventArg<
        'beforeRemove',
        true,
        {
          action: NavigationAction;
        }
      >,
    ) => {
      if (!isDirty) {
        // If we don't have unsaved changes, then we don't need to do anything
        return;
      }

      if (
        order &&
        order.status !== OrderStatus.IN_PROGRESS &&
        order?.orderItems.length === 0
      ) {
        // If we don't have unsaved changes, then we don't need to do anything
        return;
      }

      if (order && order.status === OrderStatus.COMPLETED) {
        // If we have completed order, then we don't need to do anything
        return;
      }

      if (escapeDiscardModal.current) {
        // If we clicked on save changes, then we don't need to do anything
        return;
      }

      // Prevent default behavior of leaving the screen
      e && e.preventDefault();
      // Prompt the user before leaving the screen

      const saveOrderAndDispatch = async () => {
        await saveOrder();
        e && navigation.dispatch(e.data.action);
      };

      const onPressSave = () => {
        if (orderType?.code === OrderTypeCode.DINE_IN && !order?.table) {
          requestToSetTableOrder(saveOrderAndDispatch);
        } else {
          saveOrderAndDispatch();
        }
      };

      const discardChangesAndDispatch = () => {
        discardChanges();
        resetBackToOriginalQuantities();
        e && navigation.dispatch(e.data.action);
      };
      const saveOrderThroughDiscardChanges = () => {
        escapeDefaultScreenNavigation.current = true;
        onPressSave();
      };
      showModal(
        <DiscardChangesModal
          onSave={saveOrderThroughDiscardChanges}
          onClose={discardChangesAndDispatch}
          title={translate('order.discardChangeTitle')}
          subTitle={translate('order.discardChangeDescription')}
        />,
      );
    },
    [
      isDirty,
      order,
      showModal,
      saveOrder,
      navigation,
      orderType?.code,
      requestToSetTableOrder,
      translate,
      discardChanges,
      resetBackToOriginalQuantities,
    ],
  );

  useEffect(() => {
    const beforeRemoveSub = navigation.addListener(
      'beforeRemove',
      openOrderAwayNavigation,
    );
    const blurSub = navigation.addListener('blur', () =>
      openOrderAwayNavigation(),
    );
    return () => {
      beforeRemoveSub();
      blurSub();
    };
  }, [navigation, openOrderAwayNavigation]);

  useEffect(() => {
    if (!isFocused || itemsChanged) return;
    if (orderItems.length && courses.length) {
      const orderItemsToFire: OrderItemFireStatus[] = [];
      orderItems.forEach(item => {
        if (
          item.status !== OrderItemStatus.CREATED &&
          item.status !== OrderItemStatus.ON_HOLD
        )
          return;

        const shouldFire = isItemReadyToFire(item.course?.id);
        const expectedStatus = shouldFire
          ? OrderItemStatus.CREATED
          : OrderItemStatus.ON_HOLD;
        if (expectedStatus !== item.status)
          orderItemsToFire.push({
            id: item.id,
            status: shouldFire,
          });
      });
      fireOrderItemsHandler(orderItemsToFire);
    }
  }, [
    courses.length,
    isFocused,
    isItemReadyToFire,
    orderItems,
    fireOrderItemsHandler,
    itemsChanged,
  ]);

  // header components
  const headerTitle = useMemo(
    () => (
      <>
        <HeaderTabs selectedOption={'TakeOrder'} />
      </>
    ),
    [],
  );

  const handleSelectProduct = useCallback(
    (selectedProduct: Product) => {
      setSelectedProduct({ ...selectedProduct });
      addProductToCart(selectedProduct);
    },
    [addProductToCart],
  );

  const onClickSearch = useCallback(
    () =>
      showModal(
        <SearchProduct
          onSelectProduct={handleSelectProduct}
          allProducts={allProducts}
        />,
        {
          onBackdropPress: closeModal,
        },
      ),
    [showModal, handleSelectProduct, allProducts, closeModal],
  );

  const onPressPrintReceipt = useCallback(async () => {
    if (order) {
      // For unsaved order items the product name is not available within the order object
      // So grabbing the product name from unsaved order item object
      const result = await printBill({
        ...order,
        orderItems: order.orderItems.map(eachItem => {
          if (!eachItem.product.name) {
            const unsavedItemName = orderItems.find(
              unsavedOrderItem => unsavedOrderItem.id === eachItem.id,
            )?.product.name;
            if (unsavedItemName) {
              return {
                ...eachItem,
                product: {
                  ...eachItem.product,
                  name: unsavedItemName,
                },
              };
            }
          }
          return eachItem;
        }),
      });
      if (result && Object.keys(result)?.length > 0 && result.error) {
        showNotification(result);
      }
    }
  }, [printBill, order, showNotification, orderItems]);

  const confirmPrintingWithUnSavedData = useCallback(() => {
    // TODO: get message from team
    showModal(
      <ConfirmationModal
        title={translate('cart.printWithUnSavedItems.title')}
        message={translate('cart.printWithUnSavedItems.message')}
        onConfirm={(): void => {
          onPressPrintReceipt();
          closeModal();
        }}
      />,
    );
  }, [showModal, onPressPrintReceipt, translate, closeModal]);

  const newOrderButtonOnSaveDiscardModal = useCallback(
    (callback?: Function) => {
      const saveAndStartNewOrder = () => {
        onSaveOrder();
        callback?.();
      };
      if (orderType?.code === OrderTypeCode.DINE_IN && !order?.table) {
        requestToSetTableOrder(saveAndStartNewOrder);
      } else {
        saveAndStartNewOrder();
      }
    },
    [onSaveOrder, requestToSetTableOrder, orderType, order],
  );

  const handleSwitchCourseItem = useCallback(
    (orderItemId: string, courseId: string) => {
      updateCart<SwitchCourseItemEvent>(OrderAction.ORDER_SWITCH_COURSE_ITEM, {
        courseId: courseId === DEFAULT_ENTITY_ID ? undefined : courseId,
        orderItemId,
      });

      const shouldFireOrderItem =
        courses.find(course => course.id === courseId)?.autoFire || false;

      const orderItemsToFires = orderItems
        .filter(orderItem => orderItem.id === orderItemId)
        .map(item => ({
          id: item.id,
          status: shouldFireOrderItem,
        })) as OrderItemFireStatus[];
      fireOrderItemsHandler(orderItemsToFires);
    },
    [updateCart, courses, orderItems, fireOrderItemsHandler],
  );

  const failedPrintJobsCount = useReactiveVar<number>(failedPrintJobsCountVar);
  const headerRight = useMemo(() => {
    const _hasOrders = (order?.orderItems || []).length > 0;
    const enableFloorView = session?.deviceProfile?.enableFloorView;
    const isTableFeatureEnabled = isFeatureEnabled(
      Features.TABLE_MANAGEMENT,
      FeatureContext.VENUE,
      session.currentVenue?.id,
    );

    const isCartUnSaved =
      isDirty == true &&
      !(
        order &&
        order.status !== OrderStatus.IN_PROGRESS &&
        order?.orderItems.length === 0
      );
    return (
      <>
        {enableFloorView && isTableFeatureEnabled && <FloorViewButton />}
        <SearchButton onPress={onClickSearch} />
        <SubscriptionStatusButton />
        <PrintButton
          failedJobsCount={failedPrintJobsCount}
          disabled={!failedPrintJobsCount && !_hasOrders}
          disabledPrint={!_hasOrders}
          onPress={
            _hasOrders
              ? isDirty
                ? confirmPrintingWithUnSavedData
                : onPressPrintReceipt
              : undefined
          }
        />
        {!networkStat && <ConnectionStatusIcon />}
        <NewOrderButton
          isCartUnSaved={isCartUnSaved}
          onSave={newOrderButtonOnSaveDiscardModal}
        />

        <DrawerButton />
      </>
    );
  }, [
    order,
    isFeatureEnabled,
    session.currentVenue?.id,
    onClickSearch,
    isDirty,
    confirmPrintingWithUnSavedData,
    onPressPrintReceipt,
    networkStat,
    session?.deviceProfile?.enableFloorView,
    failedPrintJobsCount,
    newOrderButtonOnSaveDiscardModal,
  ]);

  const headerLeft = useMemo(() => {
    const customerName = assignedCustomer
      ? `${assignedCustomer?.firstName} ${assignedCustomer?.lastName}`
      : translate('button.addCustomer');

    return (
      <FelaComponent style={headerButtonContainerStyle}>
        {({ style }: RenderProps): React.ReactFragment => (
          <Button
            size="small"
            icon={'user-circle'}
            iconPosition={'left'}
            color={theme.colors.primaryDarkest}
            onPress={onClickCustomer}
            containerStyle={style}
            labelStyle={styles.labelStyle}
            title={customerName}
            testID="assign-customer"
          />
        )}
      </FelaComponent>
    );
  }, [
    assignedCustomer,
    translate,
    theme.colors.primaryDarkest,
    onClickCustomer,
  ]);

  const readOnly = READ_ONLY_STATUSES.includes(
    order?.status || OrderStatus.CREATED,
  );
  const totalCartItems = order?.orderItems.length || 0;
  const disableCartActions = totalCartItems === 0;
  const disableOrderActions = readOnly || disableCartActions;
  const disableSaveAction =
    readOnly ||
    (order?.status !== OrderStatus.IN_PROGRESS &&
      order?.orderItems.length === 0);

  const selectedModifiersOfSelectedProduct = useMemo(() => {
    const modGroups: { [key: string]: string[] } = {};
    const selectedProductItem = order?.orderItems?.find(
      x => x.id === selectedCartItem?.item,
    );
    if (selectedProductItem?.modifiers) {
      selectedProductItem?.modifiers.forEach(x => {
        if (x.modifierGroupId && x.id && !modGroups[x.modifierGroupId]) {
          modGroups[x.modifierGroupId] = [x.id];
        } else if (x.modifierGroupId && x.id) {
          modGroups[x.modifierGroupId].push(x.id);
        }
      });
    }
    return modGroups;
  }, [order?.orderItems, selectedCartItem?.item]);

  const quantityOfSelectedItem = useCallback(
    (item: OrderItem) => {
      let quantity = item?.quantity || 0;
      if (selectedCartItem?.modifier) {
        // if selected a modifier item of product then, modifier quantity should be picked and incremented
        const selectedMod = getSelectedModiferInCart(
          selectedCartItem,
          item as OrderItem,
        );
        if (selectedMod) {
          quantity = selectedMod?.quantity || 0;
        }
      }
      return quantity;
    },
    [getSelectedModiferInCart, selectedCartItem],
  );

  const onPressAction = useCallback(
    (action: string) => {
      const item = orderItems.find(x => x.id === selectedCartItem?.item);
      let quantity: number;
      switch (action) {
        case CartKeyPadActions.CancelAction:
          if (advancedKeypadValue && selectedCartItem) {
            quantity = (item?.quantity || 0) - (advancedKeypadValue || 1);
            if (quantity >= 0) {
              quantity > 0
                ? onPressDangerAction(quantity)
                : onPressDangerAction();
            } else {
              showNotification({
                error: true,
                message: translate('order.cancelOrderItemError'),
              });
            }
          } else {
            onPressDangerAction();
          }
          break;
        case CartKeyPadActions.UpdatePriceAction:
          onPressUpdatePrice();
          break;
        case CartKeyPadActions.AddAction:
          quantity =
            quantityOfSelectedItem(item as OrderItem) +
            (advancedKeypadValue || 1);
          onConfirmSetQuantity(quantity);
          break;
        case CartKeyPadActions.SubtractAction:
          quantity =
            quantityOfSelectedItem(item as OrderItem) -
            (advancedKeypadValue || 1);

          if (!isValidTotalPriceBeforeRemoveItem(Math.max(quantity, 0))) {
            showNotification({
              message: translate('payment.amountCannotBeLessThanRemaining'),
              error: true,
            });
            unselectCartItem();
            return;
          }

          if (
            item?.status === OrderItemStatus.CREATED ||
            item?.status === OrderItemStatus.ON_HOLD
          ) {
            if (quantity > 0) onConfirmSetQuantity(quantity);
            else {
              onPressAction(CartKeyPadActions.CancelAction);
            }
          } else if (item?.status === OrderItemStatus.IN_PROGRESS) {
            const allowVoidItem = canI(
              [{ onResource: Resource.VOID_ORDER_ITEMS }],
              { prompt: true },
            );
            if (!allowVoidItem) return;
            const updatedOrderItem = {
              ...item,
              quantity: advancedKeypadValue || 1,
            };
            showModal(
              <CancelOrderItemModalMap
                orderId={order?.id || ''}
                item={updatedOrderItem as unknown as OrderItem}
                onSubmit={(item: OrderItem, reason: VoidReason) => {
                  onConfirmSetQuantity(quantity, reason);
                }}
              />,
            );
          }
          break;
        default:
          break;
      }
    },
    [
      orderItems,
      selectedCartItem,
      advancedKeypadValue,
      isValidTotalPriceBeforeRemoveItem,
      onPressUpdatePrice,
      quantityOfSelectedItem,
      onConfirmSetQuantity,
      showNotification,
      unselectCartItem,
      onPressDangerAction,
      translate,
      showModal,
      order?.id,
      canI,
    ],
  );

  const onPressSplitProductFromCart = useCallback(() => {
    if (advancedKeypadValue) {
      const item = orderItems.find(x => x.id === selectedCartItem?.item);
      const quantity = (item?.quantity || 0) - (advancedKeypadValue || 1);
      if (quantity > 0) {
        splitProductFromCart();
      } else {
        showNotification({
          error: true,
          message: translate('order.cancelSplitError'),
        });
      }
    } else {
      splitProductFromCart();
    }
  }, [
    selectedCartItem,
    showNotification,
    splitProductFromCart,
    advancedKeypadValue,
    orderItems,
    translate,
  ]);

  const onIncrementSeatNumber = useCallback(() => {
    if (order && order.table) {
      updateCart(OrderAction.ORDER_ASSIGN_TABLE, {
        tableId: order.table.id,
        tableName: order.table.name,
        guestCount: order.table.guestCount + 1,
      });
    }
  }, [order, updateCart]);

  const onSetSelectedSeatNumber = useCallback(seatNumber => {
    setSelectedSeatNumber(seatNumber);
  }, []);

  useEffect(() => {
    const item = orderItems.find(x => x.id === selectedCartItem?.item);
    if (selectedCartItem && isSeatManagementEnabled) {
      if (selectedSeatNumber && item?.seatNumber !== selectedSeatNumber) {
        updateCart(OrderAction.ORDER_UPDATE_SEAT_NUMBER, {
          seatNumber: selectedSeatNumber,
          orderItemIds: [selectedCartItem.item],
        });
        unselectCartItem();
      }
    }
  }, [
    selectedCartItem,
    selectedSeatNumber,
    isSeatManagementEnabled,
    unselectCartItem,
    updateCart,
    setSelectedSeatNumber,
    orderItems,
  ]);
  const actionMap = useFunctionMaps(
    orderItems,
    selectCartItem,
    selectedCartItem,
    escapeDiscardModal,
    advancedKeypadValue,
    pricingGroup,
    pricingGroupOptions,
    isOrderComplete,
    menuId,
    menus,
    setAdvancedKeypadValue,
    setPricingGroup,
    onPressUpdateOrderNotes,
    allProducts,
    onPressNewOrder,
    onPressTableNumber,
    setMenuId,
    orderType,
    requestToSetTableOrder,
    unselectCartItem,
    areCartItemsValid,
    checkForUnfiredItems,
    isValidTotalPriceBeforeRemoveItem,
    navigateToPostSaleScreen,
    assignedCustomer,
  );

  const handleAddProductToCart = useCallback(
    (selectedProduct: Product) => {
      setSelectedProduct(undefined);
      addProductToCart(selectedProduct);
    },
    [addProductToCart],
  );

  const onPressCompleteOrder = useCallback(async () => {
    updateCart<CompleteOrderEvent>(OrderAction.ORDER_COMPLETE);
    saveOrder();
  }, [updateCart, saveOrder]);

  return (
    <Layout
      title={translate('navigation.newOrderPageTitle', {
        appName: translate('appName'),
      })}
      testID="new-order-page"
      headerTitle={headerTitle}
      headerLeft={headerLeft}
      headerRight={headerRight}
      useSafeArea={false}
    >
      <View style={css(pageContainerStyle)}>
        <Cart
          order={order as Order}
          orderType={orderType as OrderType}
          courses={courses}
          onToggleAutoFire={handleToggleAutoFire}
          onPressChangeOrderType={onPressChangeOrderType}
          onIncrementSeatNumber={onIncrementSeatNumber}
          setSelectedSeatNumber={onSetSelectedSeatNumber}
          onPressTableNumber={onPressTableNumber}
          orderItems={orderItems}
          onSelectCartItem={onSelectCartItem}
          onDeselectCartItem={unselectCartItem}
          selectedCartItem={selectedCartItem}
          isAdvancedKeypad={isAdvancedKeypad}
          disableCartActions={disableCartActions}
          isOrderComplete={isOrderComplete}
          onPressDangerAction={onPressDangerAction}
          onPressUpdatePrice={onPressUpdatePrice}
          onPressUpdateQuantity={onPressUpdateQuantity}
          splitProductFromCart={splitProductFromCart}
          onPressAction={onPressAction}
          onPressSplitProductFromCart={onPressSplitProductFromCart}
          advancedKeypadValue={advancedKeypadValue}
          setAdvancedKeypadValue={setAdvancedKeypadValue}
          onPressPay={onPressPay}
          disableOrderActions={disableOrderActions}
          isDirty={isDirty}
          onPressNewOrder={onPressNewOrder}
          onPressSave={onPressSave}
          disableSaveAction={disableSaveAction}
          enableQuickPaymentMode={enableQuickPaymentMode}
          // navigateToRefundScreen={navigateToRefundScreen}
          onSwitchCourseItem={handleSwitchCourseItem}
          onPressCompleteOrder={onPressCompleteOrder}
        />

        <View style={css(catalogStyle)}>
          {useMemo(
            () => (
              <Catalog
                pages={pages}
                allProducts={allProducts}
                allVariants={allVariants}
                addProductToCart={handleAddProductToCart}
                addVariantToProduct={addVariantProductToCart}
                actions={actionMap}
                addModifierToProduct={addModifierToCart}
                removeItemFromCart={removeItemFromCart}
                unselectCartItem={unselectCartItem}
                replaceVariant={replaceVariantInTheCart}
                selectedItem={selectedCartItem}
                selectedVariantKey={selectedVariantKey}
                selectedModifiers={selectedModifiersOfSelectedProduct}
                orderId={orderId}
                selectedProduct={selectedProduct}
                showRequiredModifierModal={showRequiredModifierModal}
                setShowRequiredModifierModal={(val: boolean) =>
                  setShowRequiredModifierModal(val)
                }
              />
            ),
            [
              pages,
              allProducts,
              allVariants,
              handleAddProductToCart,
              addVariantProductToCart,
              actionMap,
              addModifierToCart,
              removeItemFromCart,
              unselectCartItem,
              replaceVariantInTheCart,
              selectedCartItem,
              selectedVariantKey,
              selectedModifiersOfSelectedProduct,
              orderId,
              selectedProduct,
              showRequiredModifierModal,
            ],
          )}
        </View>
        {params && convertToBoolean(params?.showSpinner) ? (
          <Spinner isLoading={status?.loading} />
        ) : null}
      </View>
    </Layout>
  );
};

export default TakeOrderScreen;
