import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from '@hitz-group/localization';
import { PrinterProfileType, StyleFn } from '@hitz-group/domain';
import { useFela } from 'react-fela';
import { Helmet } from 'react-helmet';
import { ScrollView, View, Text } from 'react-native';
import usePrinterOptionsStyles from './printerOptions.styles';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import Button from '../../../../../components/Button/Button';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import PrintingSetupRow, {
  PreferencesByPrinterProfile,
} from './DevicePrinterSetupRow';
import { usePrintingSetup } from './useDevicePrinterSetup';
import { useNotification } from '../../../../../hooks/Notification';

const contentContainerStyle: StyleFn = ({ row }) => ({
  paddingTop: 20,
  zIndex: 1000 - 20 * row,
});

const DevicePrinterSetup: FC = ({}) => {
  const styles = usePrinterOptionsStyles();
  const { css, theme } = useFela();
  const {
    groupPreferencesByPrinterProfileType,
    isLoading,
    titleMappings,
    columns,
    printerOptions,
    printerTemplatesByPrinterProfileType,
    onChange,
    saveOrUpdatePrintingSetup,
    error,
  } = usePrintingSetup();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [showNotification, error]);

  const onSave = useCallback(async (): Promise<void> => {
    const result = await saveOrUpdatePrintingSetup();
    if (result) {
      showNotification(result);
    }
  }, [saveOrUpdatePrintingSetup, showNotification]);

  return (
    <View style={styles.pageStyle}>
      <View style={styles.mainView}>
        <Helmet>
          <title>
            {translate('navigation.generalSettingsPageTitle', {
              appName: translate('appName'),
            })}
          </title>
        </Helmet>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <ScrollView>
            {Object.keys(groupPreferencesByPrinterProfileType).map(
              (preferencesByPrinterProfileType, i) => {
                return (
                  <BackOfficeSection
                    key={preferencesByPrinterProfileType}
                    title={titleMappings[preferencesByPrinterProfileType]}
                    containerStyle={css(
                      contentContainerStyle({
                        row: i,
                        theme,
                      }),
                    )}
                    iconColor={theme.colors.blue}
                    icon={'info-circle'}
                  >
                    {groupPreferencesByPrinterProfileType[
                      preferencesByPrinterProfileType
                    ].length === 0 ? (
                      <View>
                        <Text
                          style={styles.noKitchenPrinterProfile}
                          testID="no-kitchen-profiles"
                        >
                          {translate('printingSetup.noKitchenPrinterProfile')}
                        </Text>
                      </View>
                    ) : (
                      <TableComponent
                        normalRows
                        columnContainerStyle={styles.columnContainerStyle}
                        columns={columns}
                        data={
                          groupPreferencesByPrinterProfileType[
                            preferencesByPrinterProfileType
                          ]
                        }
                        renderRow={(
                          item: PreferencesByPrinterProfile,
                          index: number,
                        ): React.ReactNode => {
                          return (
                            <PrintingSetupRow
                              index={index}
                              printerProfileType={
                                preferencesByPrinterProfileType as PrinterProfileType
                              }
                              preference={item}
                              printerOptions={printerOptions}
                              printerTemplateOptions={
                                printerTemplatesByPrinterProfileType[
                                  preferencesByPrinterProfileType
                                ]
                              }
                              onChange={onChange}
                            />
                          );
                        }}
                        key={preferencesByPrinterProfileType}
                      />
                    )}
                  </BackOfficeSection>
                );
              },
            )}
          </ScrollView>
        )}
      </View>
      <View style={styles.actionsContainerStyle}>
        <Button
          fluid
          testID="save-changes"
          title={translate('button.saveChanges')}
          containerStyle={styles.saveButtonStyle}
          labelStyle={styles.titleStyle}
          onPress={onSave}
          loading={isLoading}
        />
      </View>
    </View>
  );
};

export default DevicePrinterSetup;
