/* eslint-disable @typescript-eslint/no-explicit-any */
import { RenderProps, StyleFn } from '@hitz-group/domain';
import React, { useEffect, useState } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { View, ViewStyle } from 'react-native';
import GridViewPagination from './GridViewPagination';
import { isWeb } from '../../common/theme';

export interface CatalogProps {
  rows: number;
  testID?: string;
  columns: number;
  data?: Array<any>;
  paginate?: boolean;
  renderItem: (index: number, item?: any) => React.ReactNode;
  containerStyle?: ViewStyle | StyleFn;
}

const cell: StyleFn = ({ theme, rows, columns }) => ({
  width: `${(isWeb ? 97 : 95) / columns}%`,
  margin: 2,
  borderRadius: theme.radius.small,
  height: columns > 1 ? `${90 / rows}%` : `${85 / rows}%`,
  overflow: 'hidden',
  backgroundColor: theme.colors.boxBorder,
  justifyContent: 'center',
  alignSelf: 'stretch',
});

const pagination: StyleFn = () => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: null,
});

const CatalogGridView: React.FC<CatalogProps> = ({
  rows,
  columns,
  data,
  paginate,
  containerStyle,
  renderItem,
}: CatalogProps) => {
  const { css, theme } = useFela();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const cellStyle = cell({ theme, rows, columns });

  // excluding the pagination button cell
  const itemsPerPage = rows * columns - (paginate ? 1 : 0);
  const totalPages = data ? Math.ceil(data.length / itemsPerPage) : 1;
  const paginationStartIndex = (currentPage - 1) * itemsPerPage;
  const paginationEndIndex = currentPage * itemsPerPage;

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const paginatedData = data
    ? data.length > itemsPerPage
      ? data.slice(paginationStartIndex, paginationEndIndex)
      : data
    : [];

  const itemRows: Array<React.ReactNode> = paginatedData.map((item, index) =>
    renderItem(paginationStartIndex + index, item),
  );

  for (let i = paginatedData.length; i < itemsPerPage; i++) {
    itemRows.push(renderItem(i));
  }

  if (paginate) {
    itemRows.push(
      <GridViewPagination
        containerStyle={[cellStyle, css(pagination)]}
        scrollDirection={columns === 1 ? 'vertical' : 'horizontal'}
        currentPage={currentPage}
        totalPages={totalPages}
        onPressNext={(): void => setCurrentPage(prevPage => prevPage + 1)}
        onPressBack={(): void => setCurrentPage(prevPage => prevPage - 1)}
      />,
    );
  }
  return (
    <FelaComponent style={containerStyle}>
      {({ style }: RenderProps) => <View style={style}>{itemRows}</View>}
    </FelaComponent>
  );
};

const MemoCatalogGridView = React.memo(CatalogGridView);
export { MemoCatalogGridView };
export default CatalogGridView;
