import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { App, ResourceOperation, Resource } from '@hitz-group/domain';
import useOfficeUserRoles from './app/users/useOfficeUserRoles';
import {
  canPerformOn,
  canPerformOperationsOn,
  ComputedUserPermissions,
  getUserCumulativePermissions,
} from '../state/userUtility';
import useRolesContext from './app/users/useRolesContext';

export interface Props {
  children: ReactNode;
}

export interface AppAccessExtras {
  id?: string;
  withRole?: string;
  venue?: string;
  store?: string;
}

interface CanOptions {
  I?:
    | `${ResourceOperation}`
    | `${ResourceOperation},${ResourceOperation}`
    | boolean;
  resources: Resource[];
  venueIds?: string[];
  storeIds?: string[];
  roleId?: string;
}

interface ResourceWithOperation {
  doOperations?: ResourceOperation[];
  onResource: Resource;
}

export interface OfficeUserAuthorizationContextType {
  canAccessBackOffice: (options?: AppAccessExtras) => boolean;
  canAccessPOSApp: (options?: AppAccessExtras) => boolean;
  /**
   * Helps in validating access based on uniform operations on all resources.
   * like it can do view on all listed resources
   */
  can: (options: CanOptions) => boolean;
  /**
   * Use this in validating access based on non-uniform operations w.r.t resources.
   * like it can do view on all listed resources.
   *
   * Use this when wanted to verify the all permissions (from applicable roles) available for a user on a all locations.
   *
   * @param input - Array of individual resources with a set of expected operations to perform on respective resources
   *
   * @example refer to test cases, which covers most of scenarios
   */
  canI: (options: ResourceWithOperation[]) => boolean;
}

export const OfficeUserAuthorizationContext =
  createContext<OfficeUserAuthorizationContextType>(
    {} as OfficeUserAuthorizationContextType,
  );

const OfficeUserAuthorizationProvider: FC<Props> = ({ children }) => {
  const { isUserAllowedToAccess, userRoles } = useOfficeUserRoles();
  const { rolesById, permissions } = useRolesContext();

  /**
   * User permissions irrespective of location
   * Note: This must not support location or role filters because of complexity (😉 for me)
   */
  const userPermissions = useMemo((): ComputedUserPermissions => {
    return getUserCumulativePermissions(
      permissions,
      rolesById,
      Object.values(userRoles),
    );
  }, [rolesById, userRoles, permissions]);

  // TODO: need to get the venueIds and storeIds from context switcher, and need to filter the outputs based on these. This could lead us to make a decision in moving this to its own hook [or] keeping the implementation here

  const can = useCallback(
    (options: CanOptions) => {
      let operations: boolean | ResourceOperation[];
      if (typeof options.I === 'undefined' || options.I === true) {
        // to assume this is an atomic operation
        operations = true;
      } else {
        operations = (options.I as string).split(',') as ResourceOperation[];
      }
      // TODO: handle location wise filters here

      // Without location filters
      return canPerformOperationsOn(
        options.resources,
        userPermissions,
        operations,
      );
    },
    [userPermissions],
  );

  const canI = useCallback(
    (input: ResourceWithOperation[]) => {
      return canPerformOn(input, userPermissions);
    },
    [userPermissions],
  );

  const canAccessBackOffice = useCallback(
    (options?: AppAccessExtras) => {
      return isUserAllowedToAccess(App.BACKOFFICE, options);
    },
    [isUserAllowedToAccess],
  );

  const canAccessPOSApp = useCallback(
    (options?: AppAccessExtras) => {
      return isUserAllowedToAccess(App.POS_APP, options);
    },
    [isUserAllowedToAccess],
  );

  const value = useMemo(
    () => ({ can, canAccessBackOffice, canAccessPOSApp, canI }),
    [can, canAccessBackOffice, canAccessPOSApp, canI],
  );

  return (
    <OfficeUserAuthorizationContext.Provider value={value}>
      {children}
    </OfficeUserAuthorizationContext.Provider>
  );
};

export default OfficeUserAuthorizationProvider;
