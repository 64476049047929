import { StyleFn } from '@hitz-group/domain';
import { Platform } from 'react-native';
import scale from '../../../common/theme';

export const searchContainerStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  backgroundColor: theme.colors.white,
  borderWidth: 0.8,
  height: theme.input.height,
  flex: Platform.OS === 'web' ? 1 : 0.9,
  borderRadius: theme.radius.small,
  justifyContent: 'center',
  marginTop:
    Platform.OS === 'web' ? scale.moderateScale(18) : scale.moderateScale(17),
});

export const searchTextInputStyle: StyleFn = () => ({
  paddingHorizontal: scale.moderateScale(1),
  paddingVertical: scale.moderateScale(5),
});

export const titleContainerStyle: StyleFn = ({ theme }) => ({
  width: 413,
  backgroundColor: theme.colors.primaryDarkest,
  padding: theme.spacing.small * 1.5,
  borderRadius: theme.radius.small,
});

export const filterContainer: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  paddingHorizontal: theme.spacing.big,
  paddingTop: theme.spacing.small,
  justifyContent: 'space-between',
});

export const dropDownStyle: StyleFn = ({ theme }) => ({
  width: scale.moderateScale(115),
  height: theme.input.height,
  marginRight: theme.spacing.small,
});

export const dateRangeButtonStyle: StyleFn = ({ theme }) => ({
  width:
    Platform.OS === 'web' ? scale.moderateScale(115) : scale.moderateScale(95),
  height: theme.input.height,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  marginRight: Platform.OS === 'web' ? theme.spacing.small : 0,
  justifyContent: 'flex-end',
  bottom: Platform.OS === 'web' ? 10 : 0,
});

export const dateRangeTitleStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularDarkGrey,
  paddingHorizontal: scale.moderateScale(7),
  paddingVertical: scale.moderateScale(2),
  textTransform: 'none',
});

export const lableStyle: StyleFn = () => ({
  paddingHorizontal: scale.moderateScale(5),
  paddingVertical: scale.moderateScale(3),
  textTransform: 'none',
});

export const dateRangeContainer: StyleFn = () => ({
  height:
    Platform.OS === 'web' ? scale.moderateScale(27) : scale.moderateScale(17),
  width:
    Platform.OS === 'web' ? scale.moderateScale(115) : scale.moderateScale(80),
});

export const orderContainerStyle: StyleFn = () => ({
  flex: 1,
  bottom: 15,
});

export const iconContainerStyle: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  right: 0,
  width: 44,
  left: Platform.OS === 'android' || Platform.OS === 'ios' ? '0%' : undefined,
});

export const buttonLabelStyle: StyleFn = ({ theme }) => ({
  textAlign: 'left',
  textTransform: 'capitalize',
  fontFamily: theme.font.regular,
  paddingHorizontal: theme.spacing.small,
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
});

export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  width: '100%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
  height: 40,
});
export const dropdownExtraStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  width: '100%',
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
});
export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: Platform.OS === 'web' ? scale.moderateScale(120) : '15%',
  bottom: Platform.OS === 'web' ? 5 : 0,
});
export const angleDownIconStyle: StyleFn = () => ({
  right: 10,
});
