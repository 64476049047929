import { StyleFn, Variant, UpdateVariantInput } from '@hitz-group/domain';
import { VariantModel } from '@hitz-group/dal';
import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import { useFela } from 'react-fela';
import { View, ScrollView } from 'react-native';
import TableComponent from '../../../../../components/TableComponent/TableComponent';
import { CHANNELS_STORES_PAGES_QUERY } from '../../../../../graphql/settings';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import { useNotification } from '../../../../../hooks/Notification';
import { parseApolloError } from '../../../../../utils/errorHandlers';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../../components/Button/Button';
import { Helmet } from 'react-helmet';
import { AvailabilityItemRow } from './AvailabilityItemRow';
import { useVariants } from '../../../../../hooks/app/variants/useVariants';
import { Operation } from '../../../../../types/Operation';
import scale from '../../../../../common/theme';
import { VARIANT_AVAILABILITY_FRAGMENT } from '../../../../../hooks/app/variants/graphql';

const BackOfficeContainerStyle: StyleFn = ({ theme }) => ({
  width: 540,
  paddingBottom: theme.padding.medium * 3,
});

const pageStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  flex: 1,
  paddingHorizontal: theme.padding.large,
});

export const width100row: StyleFn = () => ({
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});
export const mainStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
});

const actionsContainerStyle: StyleFn = ({ theme }) => ({
  ...theme.footerButtonActionsContainer,
  zIndex: 1,
});
export const bottomSpace: StyleFn = () => ({
  height: scale.moderateScale(200),
});

const saveButtonStyle: StyleFn = ({ theme }) => ({
  width: theme.button.footerButtonWidth,
  height: theme.button.footerButtonHeight,
  marginLeft: 'auto',
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.successLight,
  alignSelf: 'auto',
});

const titleStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.success,
  fontFamily: theme.font.semibold,
  textTransform: 'none',
});

const columnNameStyle: StyleFn = ({ theme }) => ({
  paddingLeft: theme.padding.medium * 1.5,
  alignItems: undefined,
});

const columnContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.greyLight,
  borderRadius: theme.radius.small,
  borderBottomWidth: 0,
  marginTop: theme.spacing.small,
});
interface ItemData {
  title: string;
  data: { value: string; label: string }[];
  selectedValues: string[];
  searchTitle: string;
  dropDownTitle: string;
  onChange: (name: string, values: string) => void;
  property: string;
}

interface AvailabilityVariantProps {
  variantId: string;
}

export const AvailabilityVariant: React.FC<AvailabilityVariantProps> = ({
  variantId,
}) => {
  const { css, theme } = useFela();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [variant, setVariant] = useState({} as VariantModel);

  const {
    error: varErr,
    variants,
    updateVariant,
    loading: varLoading,
    operation: varOperation,
  } = useVariants(variantId, VARIANT_AVAILABILITY_FRAGMENT);

  const error = varErr;

  const channelsStoresPagesQuery = useQuery(CHANNELS_STORES_PAGES_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!varErr && varOperation === Operation.UPDATE && !varLoading) {
      showNotification({
        success: true,
        message: translate('productSettings.variantUpdatedSuccessfully'),
      });
    }
  }, [varErr, varLoading, varOperation, showNotification, translate]);

  useEffect(() => {
    if (variantId && variants && variants[variantId]) {
      const variantData = variants[variantId] as Variant;
      const productModal = {
        ...variantData,
        salesChannels: variantData.salesChannels.map(x => x.id),
        pages: variantData.pages.map(x => x.id),
        stores: variantData.stores.map(x => x.id),
      } as unknown as VariantModel;
      setVariant(productModal);
    }
  }, [variantId, variants]);

  useEffect(() => {
    if (channelsStoresPagesQuery.error) {
      showNotification({
        error: true,
        message: parseApolloError(channelsStoresPagesQuery.error),
      });
    }
  }, [channelsStoresPagesQuery.error, showNotification]);

  const onChange = useCallback((prop: string, value): void => {
    setVariant(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onSaveProduct = useCallback((): void => {
    const updateProductInput = {
      id: variant.id,
      name: variant.name,
      salesChannels: variant.salesChannels,
      pages: variant.pages,
      stores: variant.stores,
    } as UpdateVariantInput;

    updateVariant(updateProductInput);
  }, [variant, updateVariant]);

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      <ScrollView style={css(pageStyle)}>
        <View style={css(width100row)}>
          <BackOfficeSection
            title={translate('productSettings.availability')}
            contentContainerStyle={css(BackOfficeContainerStyle)}
            titleDescription={translate(
              'productSettings.availabilityDescription',
            )}
          >
            <TableComponent
              columns={[
                {
                  title: translate('productSettings.type'),
                  width: 150,
                  containerStyle: css(columnNameStyle),
                },
                {
                  title: translate('productSettings.showIn'),
                  flex: 1,
                  containerStyle: {
                    alignItems: 'flex-start',
                    paddingLeft: theme.spacing.big,
                  },
                },
              ]}
              data={
                [
                  {
                    title: translate('productSettings.channels'),
                    data: channelsStoresPagesQuery.data?.salesChannels || [],
                    selectedValues: variant.salesChannels || [],
                    searchTitle: translate('productSettings.searchForChannels'),
                    dropDownTitle: translate('productSettings.addToChannel'),
                    property: 'salesChannels',
                  },
                  {
                    title: translate('backOfficeUsers.stores'),
                    data: channelsStoresPagesQuery.data?.stores || [],
                    selectedValues: variant.stores || [],
                    searchTitle: translate('productSettings.searchForStores'),
                    dropDownTitle: translate('productSettings.addToStore'),
                    property: 'stores',
                  },
                  {
                    title: translate('backOfficeProducts.pages'),
                    data: channelsStoresPagesQuery.data?.pages || [],
                    selectedValues: variant.pages || [],
                    searchTitle: translate('productSettings.searchForPages'),
                    dropDownTitle: translate('productSettings.addToPage'),
                    property: 'pages',
                  },
                ] as ItemData[]
              }
              normalRows
              columnContainerStyle={css(columnContainerStyle)}
              renderRow={(item: ItemData, index: number) => (
                <AvailabilityItemRow
                  data={item.data}
                  dropDownTitle={item.dropDownTitle}
                  key={index}
                  searchTitle={item.searchTitle}
                  title={item.title}
                  selectedValues={item.selectedValues}
                  onChange={onChange}
                  index={index}
                  property={item.property}
                />
              )}
            />
          </BackOfficeSection>

          <View style={css(bottomSpace)}></View>
        </View>
      </ScrollView>

      <View style={css(mainStyle)}>
        <View style={css(actionsContainerStyle)}>
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={css(saveButtonStyle)}
            labelStyle={css(titleStyle)}
            onPress={onSaveProduct}
          />
        </View>
      </View>
    </>
  );
};
