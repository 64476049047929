import {
  useApolloClient,
  useReactiveVar,
  useSubscription,
} from '@apollo/client/react/hooks';
import {
  DeviceEventActions,
  FeatureContext,
  Features,
  IntegrationApps,
  OrderAction,
  OrderEvent,
} from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { ModalProvider } from '@hitz-group/rn-use-modal';
import { useNetInfo } from '@react-native-community/netinfo';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as Unicons from '@tillpos/react-native-unicons';
import { isEmpty } from 'lodash';
import { useIntegrationPartners } from '../../../src/hooks/app/useIntegrationPartners/useIntegrationPartners';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  AppState,
  Dimensions,
  NativeEventSubscription,
  Platform,
  StyleSheet,
} from 'react-native';
import { Subscription } from 'rxjs/internal/Subscription';
import { distinctUntilChanged, filter, pluck } from 'rxjs/operators';
import { useProductEvents } from '../../../src/hooks/app/events/useProductEvents';
import { useOrderEvents } from '../../../src/hooks/app/orders/useOrderEvents';
import DrawerComponent from '../../components/Drawer/Drawer';
import Backdrop from '../../components/Modals/Backdrop';
import {
  DeviceEvents,
  OrderEvents,
  UpdateProductsAvailabilityEvent,
} from '../../graphql/subscriptions';
import { useListEventsByTime } from '../../hooks/app/events/useListEventsByTime';
import { useCheckFeatureEnabled } from '../../hooks/app/features/useCheckFeatureEnabled';
import { useOrders } from '../../hooks/app/orders/useOrders';
import { useLogout } from '../../hooks/app/useLogout';
import useRolesContext from '../../hooks/app/users/useRolesContext';
import { useSession } from '../../hooks/app/useSession';
import useBehaviorSubjectState from '../../hooks/app/useSubjectState';
import { useSwitchPosUserEffect } from '../../hooks/app/useSwitchPosUserEffect';
import { CartProvider } from '../../hooks/CartProvider';
import { useNotification } from '../../hooks/Notification';
import POSUserAuthorizationProvider from '../../hooks/POSUserAuthorizationProvider';
import POSUserRoleProvider from '../../hooks/POSUserRoleProvider';
import { PrintingProvider } from '../../hooks/PrintingProvider';
import { useAudioNotification } from '../../hooks/useAudioNotification/useAudioNotification';
import {
  failedPrintJobsCountVar,
  ordersUpdatedViaSubscription,
  pendingOnlineOrdersCountVar,
} from '../../state/cache';
import { lastActiveTimeSubject } from '../../state/lastActiveTime';
import { WORKER_MESSAGES_KEY } from '../../state/preferences';
import { AuthState, tokenUtility } from '../../state/tokenUtility';
import * as storage from '../../storage/interface';
import { BUFFER_TIME_TO_FETCH_ORDER } from '../../types/Common';
import { parseApolloError } from '../../utils/errorHandlers';
import {
  groupEventsByOrderId,
  revertConflictingFields,
  sortEventsByPrevious,
} from '../../utils/eventHelper';
import {
  WorkerActionResult,
  WorkerActionResultStatus,
} from '../../workers/utils';
import { LoadingScreen } from '../Loading/Loading';
import AssignToDeviceProfile from './AssignToDeviceProfile/AssignToDeviceProfile';
import Customers from './Customers/Customers';
import LockScreen from './LockScreen/LockScreen';
import ManageMoney from './ManageMoney/ManageMoney';
import OrderHistory from './OrderHistory/OrderHistory';
import {
  default as OrdersStack,
  default as OrderStack,
} from './Orders/OrdersStack';
import SettingsStack from './Settings/SettingsStack';
import Shifts from './Shifts/Shifts';
import { POSStateProvider } from '../../hooks/POSStateProvider';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();
const { height, width } = Dimensions.get('window');

const styles = StyleSheet.create({
  drawer: { width: 275 },
});

const ONLINE_INTEGRATION_APP = [
  IntegrationApps.DELIVERECT,
  IntegrationApps.DOSHII,
  IntegrationApps.OOLIO_STORE,
  IntegrationApps.DELIVERIT,
];

const POSNavigator: React.FC = () => {
  const client = useApolloClient();
  const { translate } = useTranslation();
  const [session] = useSession();
  const netInfo = useNetInfo();
  const { showNotification } = useNotification();
  const { orderEventsHandler } = useOrderEvents();
  const { productEventsHandler } = useProductEvents();
  const { logout } = useLogout();
  const navigation = useNavigation();
  const currentStoreId = session?.currentStore?.id || '';
  const deviceId = session?.device?.id || '';
  const { value: lastActiveTime } = useBehaviorSubjectState(
    lastActiveTimeSubject,
  );

  const isFocused = useIsFocused();

  const { fetchRolesSync, updateRoles, rolesById } = useRolesContext();
  const lastBackgroundTime = useRef<number>();
  const isRefetchingOrdersFromServer = useRef<boolean>(false);

  const appState = useRef(AppState.currentState);
  const { refetchOrdersFromServer } = useOrders();

  useSwitchPosUserEffect();
  const audioNotification = useAudioNotification();
  const pendingOnlineOrdersCount = useReactiveVar<number>(
    pendingOnlineOrdersCountVar,
  );
  const { integrationPartners, getIntegrationPartnerSettings } =
    useIntegrationPartners();
  const integrationPartnersArray = useMemo(() => {
    return Object.values(integrationPartners || {});
  }, [integrationPartners]);
  const onlineIntegrationPartner = useMemo(() => {
    return integrationPartnersArray.find(
      integrationPartner =>
        ONLINE_INTEGRATION_APP.indexOf(
          integrationPartner.appName as IntegrationApps,
        ) !== -1,
    );
  }, [integrationPartnersArray]);

  const { data: subscriptionData, error: subscriptionError } = useSubscription(
    OrderEvents,
    {
      variables: {
        storeId: currentStoreId,
        deviceId,
        lastActiveTime,
      },
      shouldResubscribe: true,
    },
  );

  const { data: deviceSubscriptionData, error: deviceSubscriptionError } =
    useSubscription(DeviceEvents, {
      variables: {
        storeId: currentStoreId,
        deviceId,
      },
    });

  const {
    data: productAvailabilitySubscriptionData,
    error: productAvailabilitySubscriptionError,
  } = useSubscription(UpdateProductsAvailabilityEvent, {
    variables: {
      storeId: currentStoreId,
      deviceId,
    },
  });

  const { getListEventsByTime, listEventsResponse } = useListEventsByTime();

  const isFeatureEnabled = useCheckFeatureEnabled();
  const enableFloorView = session?.deviceProfile?.enableFloorView;
  const isTableFeatureEnabled = isFeatureEnabled(
    Features.TABLE_MANAGEMENT,
    FeatureContext.VENUE,
    session.currentVenue?.id,
  );

  const getAllOrdersFromServer = useCallback(async () => {
    isRefetchingOrdersFromServer.current = true;
    await refetchOrdersFromServer();
    isRefetchingOrdersFromServer.current = false;
  }, [refetchOrdersFromServer]);

  const updateOrderDataToLatest = useCallback(
    (events: OrderEvent[]) => {
      if (
        events.length === 1 &&
        events[0].action === OrderAction.ORDERS_REFETCH
      ) {
        // Preventing server fetch call from happening (if one is already in-progress).
        if (!isRefetchingOrdersFromServer.current) {
          return setTimeout(getAllOrdersFromServer, 5000);
        } else {
          return 0;
        }
      } else {
        const eventsMap: Record<string, OrderEvent[]> =
          groupEventsByOrderId(events);
        for (const key in eventsMap) {
          const eventArray: OrderEvent[] = eventsMap[key];
          eventArray.sort((a, b) => a.timestamp - b.timestamp);
          const sortedEvents = sortEventsByPrevious(eventArray);
          orderEventsHandler(sortedEvents);
        }
        return setTimeout(() => {
          ordersUpdatedViaSubscription(Object.keys(eventsMap));
        }, 2000);
      }
    },
    [getAllOrdersFromServer, orderEventsHandler],
  );

  const fetchAndUpdateRoles = useCallback(async () => {
    const roles = await fetchRolesSync();
    updateRoles(roles);
  }, [fetchRolesSync, updateRoles]);

  useEffect(() => {
    getIntegrationPartnerSettings({
      store: currentStoreId,
    });
  }, [currentStoreId, getIntegrationPartnerSettings]);

  useEffect(() => {
    if (isFocused && isEmpty(rolesById)) {
      fetchAndUpdateRoles();
    }
  }, [fetchAndUpdateRoles, isFocused, rolesById]);

  useEffect(() => {
    // this block will render when app moves from inactive or background to foreground
    // only mean to effect on ios and android.
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      const subscription: NativeEventSubscription = AppState.addEventListener(
        'change',
        nextAppState => {
          if (
            /inactive|background/.test(appState.current) &&
            nextAppState === 'active'
          ) {
            if (lastBackgroundTime.current) {
              const offlineDuration = Math.abs(
                Date.now() - lastBackgroundTime.current,
              );
              if (offlineDuration > BUFFER_TIME_TO_FETCH_ORDER) {
                getAllOrdersFromServer();
              } else {
                getListEventsByTime(lastBackgroundTime.current.valueOf());
              }
            }
          } else if (
            /inactive|background/.test(nextAppState) &&
            appState.current === 'active'
          ) {
            lastBackgroundTime.current = Date.now();
          }
          appState.current = nextAppState;
        },
      ) as unknown as NativeEventSubscription;
      return () => {
        if (subscription) {
          subscription.remove();
        }
      };
    }
  }, [getListEventsByTime, getAllOrdersFromServer]);

  useEffect((): void => {
    const error =
      subscriptionError ||
      productAvailabilitySubscriptionError ||
      deviceSubscriptionError;
    if (error) {
      showNotification({
        error: true,
        message: parseApolloError(error),
      });
    }
  }, [
    subscriptionError,
    showNotification,
    productAvailabilitySubscriptionError,
    deviceSubscriptionError,
  ]);

  useEffect(() => {
    /**
     * Removed clearTimeout from this effect.
     * Subscription data changes to 'undefined' very quick
     * resulting in actual call to be cleared.
     */
    if (subscriptionData) {
      if (subscriptionData['orderEvents']) {
        const allEvents = subscriptionData['orderEvents'];
        const events = revertConflictingFields(allEvents);
        updateOrderDataToLatest(events);
      }
    }
  }, [subscriptionData, updateOrderDataToLatest]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeoutId: any;
    if (listEventsResponse?.data?.events) {
      const events = revertConflictingFields(listEventsResponse?.data?.events);
      timeoutId = updateOrderDataToLatest(events);
    }
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [listEventsResponse?.data, updateOrderDataToLatest]);

  useEffect(() => {
    if (deviceSubscriptionData) {
      if (deviceSubscriptionData['deviceEvents']) {
        const deviceEvent = deviceSubscriptionData['deviceEvents'][0];
        if (deviceEvent.action === DeviceEventActions.LOGOUT_ACTION) {
          logout();
        }
      }
    }
  }, [
    deviceSubscriptionData,
    orderEventsHandler,
    client,
    refetchOrdersFromServer,
    logout,
  ]);

  useEffect(() => {
    if (productAvailabilitySubscriptionData) {
      productEventsHandler(
        productAvailabilitySubscriptionData.updateProductsAvailabilityEvent ||
          [],
      );
    }
  }, [productAvailabilitySubscriptionData, productEventsHandler]);

  useEffect(() => {
    if (
      netInfo.isConnected === false &&
      netInfo.isInternetReachable === false
    ) {
      showNotification({
        error: true,
        message: translate('settings.noInternet'),
      });
    }
  }, [netInfo, showNotification, translate]);

  useEffect(() => {
    const subscription: Subscription = tokenUtility.getTokenInfo$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.authState === curr.authState),
        // select authState
        pluck('authState'),
        // emit only when `authState` is `logout`
        filter(authState => authState === AuthState.LOGOUT),
      )
      .subscribe(() => {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Login' }],
        });
      });

    return () => {
      subscription?.unsubscribe();
    };
  }, [navigation]);
  useEffect(() => {
    (async () => {
      const responses =
        (await storage.getItem<WorkerActionResult[]>(WORKER_MESSAGES_KEY)) ||
        [];
      failedPrintJobsCountVar(
        (responses || []).reduce(
          (acc, item) =>
            item.status === WorkerActionResultStatus.ERROR ? ++acc : acc,
          0,
        ),
      );
    })();
  }, []);

  useEffect(() => {
    if (
      pendingOnlineOrdersCount > 0 &&
      !onlineIntegrationPartner?.preferences?.onlineOrdering
        ?.autoAcceptOrders &&
      deviceId ===
        onlineIntegrationPartner?.preferences?.onlineOrdering?.printDevice
    ) {
      audioNotification.playWithTime(240000);
    } else {
      audioNotification.stopPlayWithTime();
    }
  }, [
    audioNotification,
    deviceId,
    onlineIntegrationPartner,
    pendingOnlineOrdersCount,
  ]);

  if (isEmpty(rolesById)) {
    return <LoadingScreen />;
  }

  return (
    <POSUserRoleProvider>
      <POSStateProvider>
        <PrintingProvider>
          {session.device ? (
            <POSUserAuthorizationProvider>
              <CartProvider>
                <ModalProvider
                  modalProps={{
                    deviceHeight: height,
                    deviceWidth: width,
                    customBackdrop: <Backdrop />,
                    animationInTiming: 50,
                    animationOutTiming: 50,
                    animationIn: 'fadeIn',
                    animationOut: 'fadeOut',
                  }}
                >
                  <Drawer.Navigator
                    initialRouteName="Lock"
                    drawerPosition="right"
                    drawerStyle={styles.drawer}
                    drawerContent={DrawerComponent}
                  >
                    <Drawer.Screen
                      name="Orders"
                      component={OrdersStack}
                      options={{
                        title: 'TakeOrder',
                        drawerLabel: translate('navigation.takeOrder'),
                        drawerIcon: Unicons.UilMessage,
                      }}
                    />
                    <Drawer.Screen
                      name="OrderHistory"
                      component={OrderHistory}
                      options={{
                        drawerLabel: translate('navigation.orderHistory'),
                        drawerIcon: Unicons.UilHistory,
                      }}
                    />
                    <Drawer.Screen
                      name="Customers"
                      component={Customers}
                      options={{
                        drawerLabel: translate('navigation.customers'),
                        drawerIcon: Unicons.UilUserCircle,
                      }}
                    />
                    {enableFloorView && isTableFeatureEnabled && (
                      <Drawer.Screen
                        name="FloorViewStack"
                        component={OrderStack}
                        options={{
                          title: 'FloorView',
                          drawerLabel: translate('navigation.floorView'),
                          drawerIcon: Unicons.UilLayers,
                          unmountOnBlur: true,
                        }}
                      />
                    )}
                    {session.deviceProfile?.enableCashManagement && (
                      <Drawer.Screen
                        name="ManageMoney"
                        component={ManageMoney}
                        options={{
                          drawerLabel: translate('navigation.manageMoney'),
                          drawerIcon: Unicons.UilMoneybag,
                        }}
                      />
                    )}
                    <Drawer.Screen
                      name="Shifts"
                      component={Shifts}
                      options={{
                        drawerLabel: translate('navigation.shifts'),
                        drawerIcon: Unicons.UilStopwatch,
                      }}
                    />
                    <Drawer.Screen
                      name="POSSettings"
                      component={SettingsStack}
                      options={{
                        drawerLabel: translate('navigation.settings'),
                        drawerIcon: Unicons.UilCog,
                      }}
                    />
                    <Drawer.Screen
                      name="OfficeSettings"
                      component={SettingsStack}
                      options={{
                        drawerLabel: translate('navigation.office'),
                        drawerIcon: Unicons.UilTachometerFastAlt,
                      }}
                    />
                    <Drawer.Screen
                      name="Lock"
                      component={LockScreen}
                      options={{
                        drawerLabel: translate('navigation.switchUser'),
                        drawerIcon: Unicons.UilUserCircle,
                        unmountOnBlur: true,
                      }}
                    />
                  </Drawer.Navigator>
                </ModalProvider>
              </CartProvider>
            </POSUserAuthorizationProvider>
          ) : (
            <Stack.Navigator
              screenOptions={{
                animationEnabled: true,
              }}
              headerMode="none"
            >
              <Stack.Screen
                component={AssignToDeviceProfile}
                name="AssignToDeviceProfile"
              />
            </Stack.Navigator>
          )}
        </PrintingProvider>
      </POSStateProvider>
    </POSUserRoleProvider>
  );
};

export default POSNavigator;
