import { Icons, StyleFn } from '@hitz-group/domain';
import React, { useCallback, useState } from 'react';
import { useFela } from 'react-fela';
import { GestureResponderEvent, View, Text } from 'react-native';
import IconButton from '../Button/IconButton';
import { StackActions, useNavigation } from '@react-navigation/native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import Popover from 'react-native-popover-view';
import { Placement } from 'react-native-popover-view/dist/Types';
import Button from '../Button/Button';
import scale from '../../common/theme';
import { useTranslation } from '@hitz-group/localization';
import { useSession } from '../../hooks/app/useSession';
import { SubscriptionState } from '@hitz-group/gql-client/dist';
import { AppScreen } from '../../types/AppScreen';

export interface DefaultButtonOptions {
  onPress?: (event?: GestureResponderEvent) => void;
  disabled?: boolean;
  disabledPrint?: boolean;
  failedJobsCount?: number;
}

const IconContainerStyle: StyleFn = ({ theme, disabled, danger }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: danger ? theme.colors.danger : theme.colors.primaryDarkest,
  marginLeft: 6,
  height: 44,
  width: 44,
  justifyContent: 'center',
  alignItems: 'center',
  opacity: disabled ? 0.15 : 1,
});

export const checkIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.green,
  marginLeft: 6,
  height: 44,
  width: 44,
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 1,
});

export const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.red,
  marginLeft: 6,
  height: 44,
  width: 44,
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 1,
});

const popoverStyle: StyleFn = ({ theme }) => ({
  width: 260,
  borderRadius: theme.radius.large,
  backgroundColor: theme.colors.white,
});

const backgroundStyle: StyleFn = ({ theme }) => ({
  height: scale.deviceHeight,
  width: '100%',
  backgroundColor: theme.colors.transparent,
  position: 'absolute',
});

const dropDownInnerContainer: StyleFn = ({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.white,
  ...theme.shadow30,
  paddingVertical: theme.padding.medium,
  paddingHorizontal: theme.padding.medium,
  borderRadius: theme.radius.large / 2,
});

const rightIconStyle: StyleFn = () => ({
  height: 34,
  width: 20,
  justifyContent: 'center',
  alignSelf: 'center',
  marginLeft: 'auto',
});

const optionLabelStyle: StyleFn = ({ theme }) => ({
  textTransform: undefined,
  letterSpacing: -0.5,
  fontSize: theme.fontSize.medium,
  fontFamily: theme.font.regular,
});

const optionContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.primaryDarkest,
  marginLeft: 6,
  height: 44,
  width: 44,
  justifyContent: 'center',
  alignItems: 'center',
});
const dropdownOptionContainerStyle: StyleFn = ({ disabled }) => ({
  alignSelf: 'center',
  height: 45,
  opacity: disabled ? 0.5 : 1,
});

const newButtonStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  width: 95,
  height: 44,
});

const viewStyle: StyleFn = () => ({
  marginLeft: 6,
});

const iconStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.spacing.small,
});

const badgeStyle: StyleFn = ({ theme }) => ({
  height: 14,
  width: 14,
  borderRadius: 7,
  position: 'absolute',
  top: 6,
  right: 6,
  zIndex: 1,
  backgroundColor: theme.colors.danger,
  alignItems: 'center',
  justifyContent: 'center',
});

const badgeText: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  fontSize: theme.fontSize.medium / 2,
  fontFamily: theme.font.regular,
});

const dotStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.success,
  width: 12,
  height: 12,
  borderRadius: 50,
  position: 'absolute',
  top: 6,
  right: 18,
});

export const SearchButton: React.FC<DefaultButtonOptions> = ({
  onPress,
  disabled,
}: DefaultButtonOptions) => {
  const { css } = useFela({ disabled });
  return (
    <IconButton
      testID="search-button"
      icon="Search"
      onPress={onPress}
      containerStyle={css(IconContainerStyle)}
    />
  );
};

SearchButton.displayName = 'SearchButton';

export const DownloadButton: React.FC<DefaultButtonOptions> = ({
  onPress,
  disabled,
}: DefaultButtonOptions) => {
  const { css } = useFela({ disabled });
  return (
    <IconButton
      testID="Download-button"
      icon="download-alt"
      containerStyle={css(IconContainerStyle)}
      onPress={onPress}
    />
  );
};

DownloadButton.displayName = 'DownloadButton';

export const PrintButton: React.FC<DefaultButtonOptions> = ({
  onPress,
  disabled,
  failedJobsCount = 0,
  disabledPrint,
}: DefaultButtonOptions) => {
  const { css, theme } = useFela({ disabled });
  const [showOptions, setShowOptions] = useState(false);
  const [printReceiptClicked, setPrintReceiptClicked] = useState(false);
  const { translate } = useTranslation();
  const navigation = useNavigation();

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const onPressReceipt = useCallback(() => {
    setShowOptions(false);
    setPrintReceiptClicked(true);
  }, []);

  const onPressPrintIcon = useCallback(() => {
    setShowOptions(showOptions => !showOptions);
  }, []);

  const onCloseComplete = useCallback(() => {
    if (printReceiptClicked && onPress) onPress();
    setPrintReceiptClicked(false);
  }, [onPress, printReceiptClicked]);

  const navigateToPrintJobs = useCallback(() => {
    setShowOptions(false);
    navigation.navigate('PrintJobs');
  }, [navigation]);

  return (
    <>
      {failedJobsCount ? (
        <Popover
          placement={Placement.BOTTOM}
          popoverStyle={css(popoverStyle)}
          from={
            <View
              style={css(
                IconContainerStyle({
                  failed: !!failedJobsCount,
                  theme,
                  disabled,
                }),
              )}
            >
              <IconButton
                testID="print-button"
                icon={Icons.Print}
                onPress={onPressPrintIcon}
              />
              <View style={css(badgeStyle)}>
                <Text style={css(badgeText)}>{failedJobsCount}</Text>
              </View>
            </View>
          }
          backgroundStyle={css(backgroundStyle)}
          isVisible={showOptions}
          onRequestClose={onRequestClosePopover}
          onCloseComplete={onCloseComplete}
        >
          <View style={css(dropDownInnerContainer)}>
            <Button
              title={translate('order.header.printCurrentReceipt')}
              fluid
              onPress={onPressReceipt}
              disabled={disabledPrint}
              iconPosition={'right'}
              icon={'angle-right'}
              labelStyle={css(optionLabelStyle)}
              containerStyle={css(
                dropdownOptionContainerStyle({
                  theme,
                  disabled: disabledPrint,
                }),
              )}
              iconContainerStyle={css(rightIconStyle)}
              iconProps={{
                color: theme.colors.paragraph,
                size: 24,
              }}
            />
            <Button
              title={translate('order.header.viewPrintingQueue', {
                count: failedJobsCount,
              })}
              fluid
              onPress={navigateToPrintJobs}
              iconPosition={'right'}
              icon={'angle-right'}
              labelStyle={css(optionLabelStyle)}
              containerStyle={css(dropdownOptionContainerStyle)}
              iconContainerStyle={css(rightIconStyle)}
              iconProps={{
                color: theme.colors.paragraph,
                size: 24,
              }}
            />
          </View>
        </Popover>
      ) : (
        <View style={css(IconContainerStyle)}>
          <IconButton testID="print-button" icon="print" onPress={onPress} />
        </View>
      )}
    </>
  );
};

PrintButton.displayName = 'PrintButton';

export const EditButton: React.FC<DefaultButtonOptions> = ({
  onPress,
  disabled,
}: DefaultButtonOptions) => {
  const { css } = useFela({ disabled });
  return (
    <IconButton
      testID="edit-button"
      icon="Pen"
      onPress={onPress}
      containerStyle={css(IconContainerStyle)}
    />
  );
};

EditButton.displayName = 'EditButton';

export const ConnectionStatusIconStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.danger,
  marginLeft: 6,
  height: 44,
  width: 44,
  justifyContent: 'center',
  alignItems: 'center',
});

export const ConnectionStatusIcon: React.FC = () => {
  const { css } = useFela();
  return (
    <IconButton
      testID="connection-status-icon"
      icon={'wifi-slash'}
      containerStyle={css(ConnectionStatusIconStyle)}
    />
  );
};

export const DrawerButton: React.FC = () => {
  const { css } = useFela();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  const onPress = useCallback(() => {
    navigation.toggleDrawer();
  }, [navigation]);

  return (
    <IconButton
      testID="drawer-toggle"
      icon="ellipsis-h"
      onPress={onPress}
      containerStyle={css(IconContainerStyle)}
    />
  );
};

DrawerButton.displayName = 'DrawerButton';

export const ListContainerStyle: StyleFn = ({ theme }) => ({
  borderRadius: 100,
  backgroundColor: theme.colors.white,
  marginLeft: 6,
  height: 44,
  width: 44,
  alignItems: 'center',
  justifyContent: 'center',
});

export const UiCalender = ({
  onPress,
}: DefaultButtonOptions): React.ReactElement => {
  const { css } = useFela();
  return (
    <IconButton
      onPress={onPress}
      testID="Download-button"
      icon="calendar-alt"
      containerStyle={css(IconContainerStyle)}
    />
  );
};

export const HistoryButton = ({
  onPress,
}: DefaultButtonOptions): React.ReactElement => {
  const { css } = useFela();
  return (
    <IconButton
      onPress={onPress}
      testID="print-button"
      icon="history"
      containerStyle={css(IconContainerStyle)}
    />
  );
};

export const LeftArrow = ({
  disabled,
}: DefaultButtonOptions): React.ReactElement => {
  const { css } = useFela({ disabled });
  const navigation = useNavigation();

  const onPress = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <IconButton
      testID="back-arrow"
      icon="arrow-left"
      onPress={onPress}
      containerStyle={css(IconContainerStyle)}
    />
  );
};

export const ListIcon = ({
  onPress,
}: DefaultButtonOptions): React.ReactElement => {
  const { css } = useFela();
  return (
    <IconButton
      onPress={onPress}
      testID="edit-button"
      icon="list-ul"
      primary
      containerStyle={css(IconContainerStyle)}
    />
  );
};

export const LockIcon = ({
  onPress,
}: DefaultButtonOptions): React.ReactElement => {
  const { css } = useFela();
  return (
    <IconButton
      onPress={onPress}
      testID="lock-button"
      icon="lock-alt"
      containerStyle={css(IconContainerStyle)}
    />
  );
};

export const CheckIcon = ({
  onPress,
}: DefaultButtonOptions): React.ReactElement => {
  const { css } = useFela();
  return (
    <IconButton
      onPress={onPress}
      testID="check"
      icon="check"
      containerStyle={css(checkIconContainerStyle)}
    />
  );
};

export const CloseIcon = ({
  onPress,
}: DefaultButtonOptions): React.ReactElement => {
  const { css } = useFela();
  return (
    <IconButton
      onPress={onPress}
      testID="times"
      icon="times"
      containerStyle={css(IconContainerStyle)}
    />
  );
};

export const NewOrderButton = ({
  onPress,
}: DefaultButtonOptions): React.ReactElement => {
  const { css, theme } = useFela();
  const { translate } = useTranslation();

  return (
    <View style={css(viewStyle)}>
      <Button
        testID="plus-button"
        size="small"
        fluid
        onPress={onPress}
        color={theme.colors.primaryDarkest}
        containerStyle={css(newButtonStyle)}
        title={translate('order.header.new')}
        icon={Icons.PlusCircle}
        iconPosition="right"
        iconContainerStyle={css(iconStyle)}
      />
    </View>
  );
};

export const FloorViewButton: React.FC = () => {
  const { css } = useFela();
  const navigation = useNavigation();

  const onPress = useCallback(() => {
    const pushAction = StackActions.push(AppScreen.FLOOR_VIEW);
    navigation.dispatch(pushAction);
  }, [navigation]);

  return (
    <IconButton
      testID="floor-view"
      icon="layers"
      onPress={onPress}
      containerStyle={css(optionContainerStyle)}
    />
  );
};

FloorViewButton.displayName = 'FloorViewButton';

export const SubscriptionStatusButton: React.FC = () => {
  const [session] = useSession();
  const { css } = useFela({
    danger: session?.subscriptionState !== SubscriptionState.CONNECTING,
  });

  return (
    <View>
      <IconButton
        testID="subscription-status"
        icon="refresh"
        containerStyle={css(IconContainerStyle)}
      />
      {session?.subscriptionState === SubscriptionState.CONNECTING && (
        <View style={css(dotStyle)}></View>
      )}
    </View>
  );
};

SubscriptionStatusButton.displayName = 'SubscriptionStatusButton';
