import { useReporting } from '../../../../hooks/app/useReporting';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SalesSummaryReport from './SalesSummaryReport';
import { useNotification } from '../../../../hooks/Notification';
import { ReportingContext } from '../ReportingContext';
import {
  DateRangeFilter,
  FilterValue,
  Widget,
  WidgetChartType,
  DateRangeGranularity,
  DateRangeFilterType,
  DateRangeFilterPresets,
  ReportFilters,
  Filters,
  ReportFilterDropdown,
} from '@hitz-group/domain';
import { View } from 'react-native';
import { ContainerStyles } from '../styles/Component.styles';
import {
  buildFilterObject,
  REPORT_TYPES,
  SIMILAR_RANGE_PRESETS,
  SKIP_DATE_RANGE_PRESET,
  WIDGET_GRANULARITY,
} from '../reportsHelper';
import {
  DateRangeFilterInput,
  ReportType,
  HelperText,
  DropDownFilter,
  ReportWidget,
} from '../types';
import { cloneJSON } from '@hitz-group/client-utils';
import * as storage from '../../../../storage/interface';
import { CubejsApi } from '@cubejs-client/core';

export const SalesSummaryListContainer: React.FC = () => {
  const { showNotification } = useNotification();
  const { cubejsApi, filters: allFilters } = useContext(ReportingContext);
  const [filters, setFilters] = useState<FilterValue>({});
  const [reportProps, setReportProps] = useState<ReportWidget>({
    updateCount: 0,
    widgets: [],
  });

  const [reportType, setReportType] = useState<string>(REPORT_TYPES[0]);

  const [dateRangeFilters, setDateRangeFilters] =
    useState<DateRangeFilterInput>();
  const styles = ContainerStyles();

  const { reportMetadata, error, getReportMetadata } = useReporting(cubejsApi);

  const ddFilters = useMemo(() => {
    if (allFilters) {
      return reportMetadata?.filters?.map((filter: ReportFilterDropdown) => {
        return {
          ...filter,
          values: allFilters[filter.key as string as keyof Filters],
        } as DropDownFilter;
      });
    }
  }, [reportMetadata?.filters, allFilters]);

  const { widgets, updateCount } = reportProps;
  const setWidgets = (updatedWidgets: Widget[]) => {
    setReportProps(prev => ({ ...prev, widgets: updatedWidgets }));
  };

  useEffect(() => {
    allFilters &&
      getReportMetadata &&
      getReportMetadata(HelperText.SALES_SUMMARY);
  }, [allFilters, getReportMetadata]);

  const getWidgetGranularity = useCallback(
    (
      widget: Widget,
      dateRangeFilters: DateRangeFilterInput,
      reportType: string,
    ) => {
      let granularity: string = WIDGET_GRANULARITY[1];
      const typeIndex = REPORT_TYPES.indexOf(reportType);
      const similarRanges = SIMILAR_RANGE_PRESETS[reportType as ReportType];

      if (
        widget.chartType === WidgetChartType.LINE &&
        dateRangeFilters?.type === DateRangeFilterType.FILTER_BY_PRESET &&
        Array.isArray(similarRanges) &&
        similarRanges.indexOf(
          dateRangeFilters?.value as DateRangeFilterPresets,
        ) > -1
      ) {
        granularity = WIDGET_GRANULARITY[typeIndex];
      } else {
        granularity = WIDGET_GRANULARITY[typeIndex + 1];
      }
      return granularity;
    },
    [],
  );

  useEffect(() => {
    async function setReportStates() {
      const cachedFilters: ReportFilters | undefined = await storage.getItem(
        HelperText.SALES_SUMMARY,
      );
      if (cachedFilters) {
        const dateRangeFilter = cachedFilters
          ?.dateRangeFilters?.[0] as DateRangeFilterInput;
        const reportTypeIndex: number =
          WIDGET_GRANULARITY.indexOf(
            dateRangeFilter?.granularity as DateRangeGranularity,
          ) - 1;
        const reportType = REPORT_TYPES[reportTypeIndex];
        setWidgets(
          reportMetadata?.widgets.map(widget => {
            const granularity = getWidgetGranularity(
              widget,
              dateRangeFilter,
              reportType,
            );
            const dateRangeClone = cloneJSON(cachedFilters.dateRangeFilters);
            dateRangeClone[0].granularity = granularity;
            return cloneJSON({
              ...widget,
              query: {
                ...widget.query,
                filters: cachedFilters.filters,
                dateRangeFilters: dateRangeClone,
              },
            });
          }) as Widget[],
        );
        setFilters(cloneJSON(cachedFilters.filters));
        setDateRangeFilters(cloneJSON(dateRangeFilter));
        setReportType(reportType);
      } else {
        setWidgets(cloneJSON(reportMetadata?.widgets) as Widget[]);
        const widget = reportMetadata?.widgets?.find(
          w => w.chartType === WidgetChartType.TABLE,
        );
        setFilters(cloneJSON(widget?.query?.filters) as FilterValue);
        setDateRangeFilters(
          cloneJSON(
            widget?.query?.dateRangeFilters?.[0],
          ) as DateRangeFilterInput,
        );
      }
    }
    if (reportMetadata?.widgets) {
      setReportStates();
    }
  }, [getWidgetGranularity, reportMetadata?.widgets]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const updateFilters = useCallback((filter: string, value: string[]) => {
    setFilters(filters => {
      return {
        ...filters,
        [filter]: value,
      };
    });
  }, []);

  const updateDateRangeFilters = useCallback((value: DateRangeFilterInput) => {
    const toSet = buildFilterObject(value);
    setDateRangeFilters(toSet);
  }, []);

  const updateReportType = useCallback(
    (value: string) => {
      setReportType(value);
      const skipRanges = SKIP_DATE_RANGE_PRESET[value];
      skipRanges &&
        dateRangeFilters?.type === DateRangeFilterType.FILTER_BY_PRESET &&
        skipRanges.indexOf(dateRangeFilters?.value as DateRangeFilterPresets) >
          -1 &&
        setDateRangeFilters({
          ...dateRangeFilters,
          value: SIMILAR_RANGE_PRESETS[value as ReportType][0],
        });
    },
    [dateRangeFilters],
  );

  const resetFilters = useCallback(() => {
    setFilters({});
  }, []);

  const updateReport = useCallback(() => {
    delete dateRangeFilters?.startDate;
    delete dateRangeFilters?.endDate;
    delete dateRangeFilters?.startTime;
    delete dateRangeFilters?.endTime;

    const granularity = WIDGET_GRANULARITY[
      REPORT_TYPES.indexOf(reportType) + 1
    ] as DateRangeGranularity;

    const reportFilters: ReportFilters = {
      filters,
      dateRangeFilters: [
        { ...dateRangeFilters, granularity } as DateRangeFilter,
      ],
    };

    storage.setItem(HelperText.SALES_SUMMARY, reportFilters);

    const updatedWidgets = reportProps.widgets.map(widget => {
      const updatedWidget = {
        ...widget,
        query: {
          ...widget?.query,
          ...reportFilters,
        },
      } as Widget;

      const widgetGranularity = getWidgetGranularity(
        widget,
        dateRangeFilters as DateRangeFilterInput,
        reportType,
      );

      const widgetClone = cloneJSON(updatedWidget);
      widgetClone.query.dateRangeFilters[0].granularity = widgetGranularity;

      if (widget.chartType === WidgetChartType.PIE) {
        delete widgetClone.query.dateRangeFilters[0].granularity;
        return widgetClone;
      } else {
        return widgetClone;
      }
    });

    setReportProps(prev => ({
      updateCount: prev.updateCount + 1,
      widgets: updatedWidgets,
    }));
  }, [
    dateRangeFilters,
    reportType,
    filters,
    reportProps.widgets,
    getWidgetGranularity,
  ]);

  const loading = widgets && widgets.length > 0 ? false : true;

  return (
    <View style={styles.pageStyle}>
      <SalesSummaryReport
        filters={filters}
        filterOptions={ddFilters || ([] as DropDownFilter[])}
        allFilters={allFilters || ({} as Filters)}
        updateFilters={updateFilters}
        resetFilters={resetFilters}
        updateReport={updateReport}
        reportTypeSettings={{ value: reportType, options: REPORT_TYPES }}
        updateReportType={updateReportType}
        dateRangeFilter={dateRangeFilters || ({} as DateRangeFilterInput)}
        updateDateRangeFilters={updateDateRangeFilters}
        options={{ loading }}
        widgets={widgets}
        cubejsApi={cubejsApi as CubejsApi}
        updateCount={updateCount}
      />
    </View>
  );
};

export default SalesSummaryListContainer;
