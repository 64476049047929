import { Colors, RenderProps, StyleFn } from '@hitz-group/domain';
import React, { useMemo, useState } from 'react';
import { FelaComponent } from 'react-fela';
import { Text, View, ViewStyle, TextStyle } from 'react-native';

export interface AvatarProps {
  name: string;
  containerStyle?: ViewStyle;
  textStyle?: TextStyle;
  onlySingleCharacter?: boolean;
}

const colorPool = [
  [Colors.amber, Colors.amberLight],
  [Colors.purple, Colors.purpleLight],
  [Colors.green, Colors.greenLight],
  [Colors.blue, Colors.blueLight],
  [Colors.orange, Colors.orangeLight],
  [Colors.teal, Colors.tealLight],
  [Colors.indigo, Colors.indigoLight],
];

const avatarContainerStyle: StyleFn = ({ theme, color }) => ({
  width: 44,
  height: 44,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: color,
  borderRadius: theme.radius.small,
});

const avatarTextStyle: StyleFn = ({ color, theme }) => ({
  color,
  fontFamily: theme.font.bold,
  borderRadius: theme.radius.small,
});

interface AvatarTextProps {
  color: Colors;
  text: string;
  textStyle?: TextStyle;
}

const AvatarText: React.FC<AvatarTextProps> = ({ text, ...props }) => (
  <FelaComponent style={avatarTextStyle} {...props}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text style={[style, props.textStyle]}>{text}</Text>
    )}
  </FelaComponent>
);

const Avatar: React.FC<AvatarProps> = ({
  name,
  containerStyle,
  textStyle,
  onlySingleCharacter,
}) => {
  // select random color from pool
  const [colorIndex] = useState(
    Math.round(Math.random() * (colorPool.length - 1)),
  );

  // First letter of first name and first letter of last name
  // If no last name use first two letters of first name
  const avatarText = useMemo(() => {
    const [firstName, lastName] = (name || '').split(' ');
    if (onlySingleCharacter) return firstName.charAt(0);
    return lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : firstName.substr(0, 2);
  }, [name, onlySingleCharacter]);

  return (
    <FelaComponent
      style={avatarContainerStyle}
      color={colorPool[colorIndex][1]}
    >
      {({ style }: RenderProps): React.ReactFragment => (
        <View style={containerStyle ? [style, containerStyle] : style}>
          <AvatarText
            color={colorPool[colorIndex][0]}
            text={avatarText.toUpperCase()}
            textStyle={textStyle}
          />
        </View>
      )}
    </FelaComponent>
  );
};

export default Avatar;
