import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { View } from 'react-native';
import Layout from '../../../components/POSLayout/POSLayout';
import { LeftArrow } from '../../../components/HeaderIcons/HeaderIcons';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { useTranslation } from '@hitz-group/localization';
import { useFela } from 'react-fela';
import DropDown from '../../../components/FormInput/DropDown';
import Title from '../../../components/Title/Title';
import CustomerView from '../../../components/CustomerDataView/CustomerView';
import BackOfficeCreateNewButton from '../../../components/BackOfficeCreateNewButton/BackOfficeCreateNewButton';
import {
  DrawerButton,
  SubscriptionStatusButton,
} from '../../../components/HeaderIcons/HeaderIcons';
import {
  CreateCustomerRequest,
  Customer,
  UpdateCustomerRequest,
} from '@hitz-group/domain';
import Wrapper from '../../../components/Wrapper/Wrapper';
import * as styles from './Customers.style';
import { useCustomers } from '../../../hooks/orders/useCustomers';
import { useNotification } from '../../../hooks/Notification';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import { Operation } from '../../../types/Operation';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import BackOfficeCustomerModal from '../../../components/Modals/BackOfficeCustomerModal/BackOfficeCustomerModal';
import NewOrderButton from '../../../components/NewOrder/NewOrderButton';
import { useIsFocused } from '@react-navigation/native';

const Customers: React.FC = () => {
  const { css } = useFela();
  const isFocused = useIsFocused();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [paymentTypeFilter] = useState('');
  const [actionData, setActionData] = useState('');
  const { showModal, closeModal } = useModal();
  const {
    addNewCustomer,
    checkEmailExists,
    updateCustomer,
    customers,
    getCustomers,
    loading,
    error: customerDataError,
  } = useCustomers();
  const headerLeft = <LeftArrow />;
  const headerTitle = (
    <Title primary containerStyle={css(styles.titleContainerStyle)}>
      {translate('navigation.customers')}
    </Title>
  );
  const headerRight = useMemo(
    () => (
      <>
        <SubscriptionStatusButton />
        <NewOrderButton />
        <DrawerButton />
      </>
    ),
    [],
  );

  useEffect(() => {
    getCustomers();
  }, [isFocused, getCustomers]);

  useEffect(() => {
    if (customerDataError) {
      showNotification({
        error: true,
        message: customerDataError,
      });
    }
  }, [customerDataError, showNotification]);

  const customersArray = useMemo(() => Object.values(customers), [customers]);

  const [searchCustomer, setSearchCustomer] = useState('');

  const [selectedCategory, setFilteredCustomer] = useState('');

  const getCustomerBySearchQuery = (
    customers: Customer[],
    searchQuery: string,
  ): Customer[] => {
    return customers.filter(
      customer =>
        customer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.phone.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  const getCustomerByFilteredCategory = (
    customers: Customer[],
    selectedCategory: string,
  ): Customer[] => {
    return customers.filter(customer =>
      customer.name.toLowerCase().includes(selectedCategory.toLowerCase()),
    );
  };
  const allCustomersArray = useMemo(() => {
    let allCustomers = customersArray;
    if (searchCustomer) {
      allCustomers = getCustomerBySearchQuery(allCustomers, searchCustomer);
    }

    if (selectedCategory) {
      allCustomers = getCustomerByFilteredCategory(
        allCustomers,
        selectedCategory,
      );
    }
    return allCustomers;
  }, [customersArray, searchCustomer, selectedCategory]);

  const paymentTypesValues = useMemo(
    () => [{ value: '', label: translate('customerView.showAll') }],
    [translate],
  );
  const action = useRef('');
  const customerNameRef = useRef<string>('');
  const createCustomer = useCallback(
    async (customerInfo: Partial<CreateCustomerRequest>) => {
      const { firstName, lastName } = customerInfo;
      setActionData(Operation.CREATE);
      customerNameRef.current = `${firstName} ${lastName}`;
      await addNewCustomer(customerInfo);
      closeModal();
    },
    [addNewCustomer, closeModal],
  );

  const editCustomer = useCallback(
    async (customerInfo: UpdateCustomerRequest) => {
      const { firstName, lastName } = customerInfo;
      action.current = Operation.UPDATE;
      customerNameRef.current = `${firstName} ${lastName}`;
      await updateCustomer(customerInfo);
    },
    [updateCustomer],
  );

  const showCreateCustomerModal: () => void = useCallback(() => {
    showModal(
      <BackOfficeCustomerModal
        action={Operation.CREATE}
        addNewCustomer={createCustomer}
        updateCustomer={editCustomer}
        checkEmailExists={checkEmailExists}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [showModal, createCustomer, editCustomer, checkEmailExists, closeModal]);

  useEffect(() => {
    if (!loading && !customerDataError && actionData !== '') {
      closeModal();
      showNotification({
        success: true,
        message: translate('customer.successMessage'),
      });
      setActionData('');
    }
  }, [
    closeModal,
    loading,
    showNotification,
    translate,
    customerDataError,
    actionData,
  ]);

  if (loading) return <LoadingIndicator />;

  return (
    <Layout
      title={translate('navigation.customersTitle', {
        appName: translate('appName'),
      })}
      headerLeft={headerLeft}
      headerTitle={headerTitle}
      headerRight={headerRight}
      testID="customer-history-page"
    >
      <View style={css(styles.filterContainer)}>
        <DropDown
          values={paymentTypesValues}
          style={css(styles.dropDownStyle)}
          selectedValue={paymentTypeFilter || translate('customerView.showAll')}
          onValueChange={setFilteredCustomer}
          extraMainViewStyle={css(styles.dropDownMainViewStyle)}
          extraStyle={css(styles.dropdownExtraStyle)}
          extraViewStyle={css(styles.dropdownViewStyle)}
          textStyle={css(styles.lableStyle)}
          angleDownIconStyle={css(styles.angleDownIconStyle)}
          testID="order-history-payment-types"
        />
        <SearchBar
          secondary
          placeholder={translate('customerView.searchPlaceholder')}
          containerStyle={css(styles.searchContainerStyle)}
          onChange={setSearchCustomer}
          textInputStyle={css(styles.searchTextInputStyle)}
          testID="order-history-search"
        />
        <View style={css(styles.createButtonContainerStyle)}>
          <BackOfficeCreateNewButton onPress={showCreateCustomerModal} />
        </View>
      </View>

      <Wrapper>
        <View style={css(styles.consumerContainerStyle)}>
          <CustomerView data={allCustomersArray} />
        </View>
      </Wrapper>
    </Layout>
  );
};
export default Customers;
