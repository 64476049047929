import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';
import { isWeb } from '../../../../common/theme';

export const CourseListStyles = () => {
  const { theme } = useFela();
  return useMemo(
    () =>
      StyleSheet.create({
        scrollStyle: {
          paddingHorizontal: theme.padding.large,
          backgroundColor: theme.colors.white,
        },
        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },
        formStyle: {
          width: '100%',
        },
        containerStyle: {
          width: isWeb ? '50%' : '100%',
          alignSelf: 'center',
        },
        columnContainerStyle: {
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          borderBottomWidth: 0,
          marginTop: theme.spacing.small,
        },
        mainStyle: {
          backgroundColor: theme.colors.white,
        },

        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
        },
        saveButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginLeft: 'auto',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
          alignSelf: 'auto',
        },
        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
      }),
    [theme],
  );
};
export const CourseRowStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        formInputContainerStyle: {
          width: 240,
          height: theme.input.height,
          paddingLeft: theme.padding.small,
          justifyContent: 'center',
        },
        formTextStyle: {
          paddingHorizontal: 0,
          paddingVertical: 0,
          height: 0,
        },

        dragIconContainer: {
          marginRight: 14,
          marginLeft: 14,
          height: 28,
          width: 28,
          borderRadius: theme.radius.small,
          alignSelf: 'center',
        },
        dragIconStyle: {
          color: theme.colors.darkGrey,
        },
        productCountText: {
          ...theme.font14RegularCharcoal,
        },
        productCountStyle: {
          width: 60,
          height: theme.input.height,
          borderRadius: theme.radius.small,
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: theme.spacing.small,
          backgroundColor: theme.colors.greyLight,
        },
        rightAngleStyle: {
          height: 38,
        },
        rowContainer: {
          height: 57,
          alignItems: 'center',
        },
        checkboxContainer: {
          width: 60,
          justifyContent: 'center',
          marginLeft: 10,
        },
        detailCourseContainer: {
          marginLeft: 'auto',
        },
      }),
    [theme],
  );
};

export const CourseDetailStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        toggleContainerStyle: {
          width: 38,
          marginLeft: 20,
          alignItems: 'center',
          flexDirection: 'row',
          flex: 1,
        },
        autoFireItemStyle: {
          ...theme.font14RegularCharcoal,
        },
        rowContainerStyle: {
          height: 53,
          alignItems: 'center',
          paddingLeft: 14,
        },
        productNameStyle: {
          marginLeft: 10,
        },
        deleteIconStyle: {
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.danger2,
          marginLeft: 'auto',
        },
        deleteIconContainerStyle: { flex: 1 },

        scrollStyle: {
          paddingHorizontal: theme.padding.large,
          backgroundColor: theme.colors.white,
        },

        pageStyle: {
          backgroundColor: theme.colors.white,
          flex: 1,
        },

        formStyle: {
          width: '100%',
          paddingBottom: theme.spacing.big,
        },

        containerStyle: {
          width: 600,
          alignSelf: 'center',
        },

        formInputContainerStyle: {
          width: 270,
          height: theme.input.height,
          justifyContent: 'center',
        },

        textStyle: {
          marginLeft: theme.padding.small,
        },

        innerContainer: {
          width: '100%',
          flexDirection: 'row',
          paddingRight: theme.spacing.small,
          alignItems: 'flex-end',
        },

        dropDownStyle: {
          width: 590,
          marginLeft: theme.spacing.big / 2,
          marginTop: theme.spacing.medium,
          marginBottom: theme.spacing.small * 2,
          zIndex: 1500,
        },

        dropdownStyle: {
          width: 600,
          marginLeft: theme.spacing.big / 2,
          paddingRight: theme.spacing.small,
          marginTop: theme.spacing.medium,
          marginBottom: theme.spacing.small * 2,
        },

        columnContainerStyle: {
          backgroundColor: theme.colors.greyLight,
          borderRadius: theme.radius.small,
          borderBottomWidth: 0,
          marginTop: theme.spacing.small,
        },

        deleteButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          marginRight: 'auto',
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.danger2,
          alignSelf: 'auto',
        },
        saveButtonContainer: {
          marginLeft: 'auto',
        },
        paginationStyle: {
          marginRight: 10,
          marginTop: 10,
        },

        saveButtonStyle: {
          width: theme.button.footerButtonWidth,
          height: theme.button.footerButtonHeight,
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
          alignSelf: 'auto',
        },

        mainStyle: {
          backgroundColor: theme.colors.white,
        },

        actionsContainerStyle: {
          ...theme.footerButtonActionsContainer,
        },

        titleStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },

        dangerTitleStyle: {
          color: theme.colors.danger,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
          letterSpacing: -0.5,
        },
      }),
    [theme],
  );
};
