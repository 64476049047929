import { StyleFn } from '@hitz-group/domain';
import { PixelRatio } from 'react-native';
import scale, { isIos, isWeb } from '../../../../common/theme';

const createModalContainerStyle: StyleFn = () => ({
  width: 330,
  flexDirection: 'column',
});

const modalTitle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
  marginBottom: PixelRatio.getPixelSizeForLayoutSize(4),
  fontSize: 20,
});

const modalDescription: StyleFn = () => ({
  color: 'grey',
  marginBottom: PixelRatio.getPixelSizeForLayoutSize(5),
});

const buttonStyle: StyleFn = ({ theme, marginLeft, backgroundColor }) => ({
  width: 115,
  height: 44,
  borderRadius: theme.radius.small,
  backgroundColor: backgroundColor || theme.colors.successLight,
  marginLeft: marginLeft || 0,
  marginTop: theme.spacing.medium,
});

const createVenueLabelStyle: StyleFn = ({ theme }) => ({
  fontFamily: theme.font.semibold,
  textTransform: 'uppercase',
});

const checkBoxTitleContainer: StyleFn = ({ theme, noMargin, marginLeft }) => ({
  width: 260,
  height: 38,
  marginBottom: noMargin ? 0 : theme.spacing.big / 2,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: marginLeft ? marginLeft : 0,
});

const checkBoxTitleStyle: StyleFn = ({ theme, isActive }) => ({
  ...theme.font14Regular,
  textTransform: 'none',
  color: isActive ? theme.colors.charcoal : theme.colors.paragraph,
});

const checkIconContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.green,
  borderWidth: 2,
  marginRight: theme.spacing.big / 2,
  height: 17,
  width: 17,
});

const searchContainerStyle: StyleFn = ({ theme }) => ({
  width: isWeb ? '100%' : 260,
  height: theme.input.height,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.boxBorder,
  borderWidth: 1,
  marginTop: PixelRatio.getPixelSizeForLayoutSize(15),
  marginBottom: PixelRatio.getPixelSizeForLayoutSize(15),
  right: isIos ? 320 : 0,
});

const searchTextInputStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  flex: 1,
  height: theme.input.height,
  color: theme.colors.paragraph,
  fontFamily: theme.font.medium,
});

export const dropdownExtraStyle: StyleFn = ({ theme, error }) => ({
  backgroundColor: error ? theme.colors.danger2 : theme.colors.white,
  width: scale.textInputWidth180,
  height: theme.input.height,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});
export const dropdownViewStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? 280 : '100%',
  marginTop: scale.moderateScale(2),
});

export const dropDownMainViewStyle: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.boxBorder,
  height: scale.moderateScale(35),
  width: '90%',
  borderRadius: theme.radius.small,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: 1,
});

export const dropdownStyle: StyleFn = () => ({
  justifyContent: 'center',
  width: isWeb ? 250 : '100%',
  marginTop: isWeb ? scale.moderateScale(10) : 0,
  left: isWeb ? 70 : 0,
});

const rowViewStyle: StyleFn = () => ({
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

const scrollView: StyleFn = () => ({
  flex: 1,
  height: '100%',
  width: '100%',
  zIndex: 0,
});

const iconButtonContainerStyle: StyleFn = () => ({
  width: scale.textInputWidth180,
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
});

const unCheckContainer: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.paragraphLight,
  borderWidth: 2,
  marginTop: 10,
  height: 18,
  width: 18,
});

const checkIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.green,
});

export const styles = {
  createModalContainerStyle,
  searchContainerStyle,
  searchTextInputStyle,
  modalTitle,
  modalDescription,
  buttonStyle,
  createVenueLabelStyle,
  checkBoxTitleContainer,
  checkBoxTitleStyle,
  checkIconContainer,
  rowViewStyle,
  scrollView,
  iconButtonContainerStyle,
  unCheckContainer,
  checkIconStyle,
};
