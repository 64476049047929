import { Order, OrderStatus } from '@hitz-group/domain';
import { Resolvers, InMemoryCache } from '@apollo/client';
import { gql } from '@apollo/client';
import { GET_ORDER, GET_ORDERS } from '../hooks/app/orders/graphql';
import isEmpty from 'lodash/isEmpty';

export const GET_ALL_ORDER_TYPES = gql`
  query {
    orderTypes {
      id: id
      value: name
      label: name
      code
    }
  }
`;

export const EMAIL_ORDER_RECEIPT = gql`
  mutation emailOrderReceipt($input: OrderReceiptInput!) {
    emailOrderReceipt(input: $input)
  }
`;

/**
 * Adds typename property to all objects
 * @param order
 */
export const mapOrderToGraphObject = (order: Order) => ({
  ...order,
  id: order.id,
  __typename: 'Order',
  roundingAmount: order.roundingAmount || 0,
  surchargeAmount: order?.surchargeAmount || 0,
  customer: order.customer
    ? {
        ...order.customer,
        type: 'id',
        id: order.customer.id,
        customerAccountDetails: isEmpty(order?.customer?.customerAccountDetails)
          ? null
          : order?.customer?.customerAccountDetails,
        __typename: 'Customer',
      }
    : null,
  orderItems: (order.orderItems || []).map(item => ({
    ...item,
    id: item.id,
    notes: item.notes || null,
    surchargeAmount: item.surchargeAmount || 0,
    __typename: 'OrderItem',
    product: {
      type: 'id',
      id: item.product.id,
      __typename: 'Product',
    },
    variant: item.variant
      ? {
          type: 'id',
          id: item.variant?.id,
          __typename: 'Variant',
        }
      : null,
    discounts: item.discounts
      ? item.discounts.map(x => ({
          ...x,
          discount: { ...x, type: 'id', __typename: 'Discount' },
          __typename: 'Discount',
        }))
      : [],
    modifiers: item.modifiers
      ? item.modifiers.map(modifier => ({
          ...modifier,
          __typename: 'OrderItemModifier',
          type: 'id',
          taxes: item.taxes
            ? item.taxes.map(x => ({ id: x.id, __typename: 'Tax', type: 'id' }))
            : [],
        }))
      : [],
    adjustments: item.adjustments
      ? item.adjustments.map(adjustment => ({
          ...adjustment,
          type: 'id',
          __typename: 'Adjustment',
        }))
      : [],
    taxes: item.taxes
      ? item.taxes.map(x => ({ id: x.id, __typename: 'Tax', type: 'id' }))
      : [],
    reason: item.reason || null,
    itemFired: item.itemFired || null,
    seatNumber: item.seatNumber || null,
    saved: item.saved || null,
    course: item?.course?.id
      ? {
          id: item?.course?.id,
          __typename: 'Course',
        }
      : null,
  })),
  orderType: order.orderType
    ? {
        id: order.orderType.id,
        __typename: 'OrderType',
        type: 'id',
      }
    : null,
  table:
    order.table && order.table.id
      ? {
          id: order.table.id,
          name: order?.table?.name || null,
          guestCount: order?.table?.guestCount || null,
          status: order?.table?.status || null,
          section: order.table.section
            ? {
                ...order.table.section,
                __typename: 'Section',
                type: 'id',
              }
            : null,
          __typename: 'OrderTable',
          type: 'id',
        }
      : null,
  venue: order.venue
    ? { id: order.venue.id, type: 'id', __typename: 'Venue' }
    : null,
  store: order.store
    ? { id: order.store.id, type: 'id', __typename: 'Store' }
    : null,
  organization: order.organization
    ? { id: order.organization.id, type: 'id', __typename: 'Organization' }
    : null,
  payments: order.payments
    ? order.payments.map(x => ({
        ...x,
        id: x.id,
        roundOffDifference: x.roundOffDifference || null,
        paymentRequestId: x.paymentRequestId || null,
        paymentTransactionRef: x.paymentTransactionRef || null,
        paymentCompletedAt: x.paymentCompletedAt || null,
        paymentReceipt: x.paymentReceipt || null,
        paymentSurcharge: x.paymentSurcharge || null,
        paymentType: x.paymentType?.id
          ? {
              __typename: 'PaymentType',
              id: x.paymentType.id,
              type: 'id',
            }
          : null,
        __typename: 'OrderPayment',
      }))
    : [],
  orderNote: order.orderNote ?? '',
  amountDue: order.amountDue ?? undefined,
  createdBy: order.createdBy
    ? { id: order.createdBy.id, __typename: 'User', type: 'id' }
    : null,
  updatedBy: order.updatedBy
    ? { id: order.updatedBy.id, __typename: 'User', type: 'id' }
    : null,
  createdByDevice: order.createdByDevice
    ? { id: order.createdByDevice.id, __typename: 'Device', type: 'id' }
    : null,
  updatedByDevice: order.updatedByDevice
    ? { id: order.updatedByDevice.id, __typename: 'Device', type: 'id' }
    : null,
  taxes: order.taxes
    ? order.taxes.map(x => ({
        ...x,
        tax: { ...x.tax, type: 'id', __typename: 'Tax' },
        __typename: 'OrderTax',
      }))
    : [],
  discounts: order.discounts
    ? order.discounts.map(x => ({
        ...x,
        discount: { ...x, type: 'id', __typename: 'Discount' },
        __typename: 'Discount',
      }))
    : [],
  adjustments: order.adjustments
    ? order.adjustments.map(x => ({
        ...x,
        adjustment: { ...x, type: 'id', __typename: 'Adjustment' },
        __typename: 'Adjustment',
      }))
    : [],
  reason: order.reason || null,
  refundOf: order.refundOf || null,
  isEdited: order.isEdited || false,
  lastSyncedEventId: order.lastSyncedEventId,
  salesChannel: order.salesChannel
    ? { id: order.salesChannel.id, type: 'id', __typename: 'SalesChannel' }
    : null,
  integrationInfo: order.integrationInfo
    ? {
        id: order.integrationInfo.id,
        app: order.integrationInfo.app,
        channelOrderId: order?.integrationInfo?.channelOrderId,
        type: 'id',
        __typename: 'IntegrationInfo',
      }
    : null,
  requiredAt: order.requiredAt || null,
  lastCheck: order.lastCheck ?? order.updatedAt,
  lastOrderedAt: order.lastOrderedAt || null,
  shippingAddress: order.shippingAddress || null,
  courier: order.courier || null,
  deliveryFee: order.deliveryFee || null,
  ordersMerged: order.ordersMerged || null,
  serviceCharge: order.serviceCharge || null,
  tip: order.tip || null,
});

export const saveOrder = (input: Order, cache: InMemoryCache): Order => {
  const order = mapOrderToGraphObject(input);
  cache.writeQuery({
    query: GET_ORDER,
    variables: { orderId: input.id },
    data: {
      order,
    },
  });

  const isOnline = !!order?.integrationInfo?.id;

  // Orders in this state can be discarded
  // Saving into get orders cache will cause discarded orders to be shown in open orders
  // Exiting now will also improve performance when creating new orders
  if (input.status === OrderStatus.CREATED && !isOnline) {
    return input;
  }

  if (input.status === OrderStatus.COMPLETED) {
    updateOrdersInCache(cache, OrderStatus.IN_PROGRESS, 'REMOVE', order);
    updateOrdersInCache(cache, OrderStatus.COMPLETED, 'ADD', order);
  } else if (input.status === OrderStatus.VOID) {
    updateOrdersInCache(cache, OrderStatus.IN_PROGRESS, 'REMOVE', order);
    updateOrdersInCache(cache, OrderStatus.VOID, 'ADD', order);
  } else if (input.status === OrderStatus.CANCELLED) {
    updateOrdersInCache(cache, OrderStatus.IN_PROGRESS, 'REMOVE', order);
    updateOrdersInCache(cache, OrderStatus.CANCELLED, 'ADD', order);
  } else if (input.status === OrderStatus.PARTNER_CANCELLED) {
    updateOrdersInCache(cache, OrderStatus.IN_PROGRESS, 'REMOVE', order);
    updateOrdersInCache(cache, OrderStatus.PARTNER_CANCELLED, 'ADD', order);
  } else if (input.status === OrderStatus.REFUNDED) {
    updateOrdersInCache(cache, OrderStatus.REFUNDED, 'ADD', order);
  } else if (input.status === OrderStatus.IN_PROGRESS) {
    updateOrdersInCache(cache, OrderStatus.CREATED, 'REMOVE', order);
    updateOrdersInCache(cache, OrderStatus.IN_PROGRESS, 'UPDATE', order);
  } else if (input.status === OrderStatus.MERGED) {
    updateOrdersInCache(cache, OrderStatus.IN_PROGRESS, 'REMOVE', order);
    updateOrdersInCache(cache, OrderStatus.MERGED, 'UPDATE', order);
  } else {
    updateOrdersInCache(cache, input.status, 'UPDATE', order);
  }
  return input;
};

export const resolvers: Resolvers = {
  Mutation: {
    // TODO: benchmark with around 1000 orders in cache
    saveOrder: (
      _,
      { input }: { input: Order },
      { cache }: { cache: InMemoryCache },
    ): Order => {
      const order = saveOrder(input, cache);
      return order;
    },

    // TODO: benchmark with around 1000 orders in cache
    saveOrders: (
      _,
      { input }: { input: Order[] },
      { cache }: { cache: InMemoryCache },
    ): Order[] => {
      const orders = [] as Order[];

      input.forEach(eachOrder => {
        const order = saveOrder(eachOrder, cache);
        orders.push(order);
      });
      return orders;
    },
  },
};

function updateOrdersInCache(
  cache: InMemoryCache,
  status: OrderStatus,
  action: 'ADD' | 'UPDATE' | 'REMOVE',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  order: any,
) {
  let existingOrders: Order[] = [];
  const isOnline =
    status !== OrderStatus.COMPLETED && !!(order as Order).integrationInfo?.id;

  try {
    const { orders } = cache.readQuery({
      query: GET_ORDERS,
      returnPartialData: true,
      variables: { filter: { status, ...(isOnline && { isOnline }) } },
    }) as {
      orders: Order[];
    };
    existingOrders = orders || [];
  } catch (e) {}
  if (action === 'ADD' || action === 'REMOVE') {
    cache.writeQuery({
      query: GET_ORDERS,
      variables: { filter: { status, ...(isOnline && { isOnline }) } },
      data: {
        orders:
          action === 'REMOVE'
            ? existingOrders.filter(x => x.id !== order.id)
            : [...existingOrders, order],
      },
    });
  }
  if (action === 'UPDATE') {
    cache.writeQuery({
      query: GET_ORDERS,
      variables: { filter: { status, ...(isOnline && { isOnline }) } },
      data: {
        orders: [...existingOrders.filter(x => x.id !== order.id), order],
      },
    });
  }
}
