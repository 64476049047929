import { RenderProps, StyleFn } from '@hitz-group/domain';
import React, { useCallback } from 'react';
import { FelaComponent, useFela } from 'react-fela';
import { Text, TouchableOpacity, View } from 'react-native';
const iconStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  width: 44,
  height: 44,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.black,
});

const itemStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: 'transparent',
  marginLeft: 0,
  marginRight: 0,
  marginTop: theme.padding.small,
  marginBottom: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingRight: theme.padding.medium,
});

const itemTextStyle: StyleFn = ({ theme }) => ({
  marginRight: theme.spacing.small,
  color: theme.colors.white,
  fontFamily: theme.font.medium,
  fontSize: theme.fontSize.small,
});

export interface DrawerItemProps {
  title: string;
  onPress: (routeName: string, screenName?: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  screenName?: string;
  routeName?: string;
}

export const DrawerItem: React.FC<DrawerItemProps> = ({
  onPress,
  title,
  routeName,
  screenName,
  icon: IconComponent,
}) => {
  const { css: styles } = useFela();
  const onPressButton = useCallback(() => {
    onPress(routeName || '', screenName);
  }, [onPress, routeName, screenName]);

  return (
    <FelaComponent style={itemStyle}>
      {({ style, theme }: RenderProps): React.ReactFragment => (
        <TouchableOpacity onPress={onPressButton} style={style}>
          <Text style={styles(itemTextStyle)}>{title}</Text>
          <View style={styles(iconStyle)}>
            <IconComponent color={theme.colors.white} size={18} />
          </View>
        </TouchableOpacity>
      )}
    </FelaComponent>
  );
};
