import { RenderProps, StyleFn } from '@hitz-group/domain';
import React from 'react';
import { FelaComponent } from 'react-fela';
import { View } from 'react-native';
import NotificationComponent from './Notification';
import { Notification } from '../../hooks/Notification';

const notificationStyle: StyleFn = () => ({
  top: 0,
  width: '100%',
  zIndex: 20,
  position: 'absolute',
});

export interface NotificationListProps {
  notifications: Array<Notification>;
  onCloseNotification: (id: symbol | undefined) => void;
}

export const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
  onCloseNotification,
}: NotificationListProps) => (
  <FelaComponent style={notificationStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <View style={style}>
        {notifications.length > 0
          ? notifications.map((notification, index) => (
              <NotificationComponent
                key={index}
                {...notification}
                onClose={onCloseNotification.bind(null, notification.id)}
              />
            ))
          : null}
      </View>
    )}
  </FelaComponent>
);

NotificationList.displayName = 'NotificationList';
