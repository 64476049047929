import React from 'react';
import { View, Text, ViewStyle, ScrollView } from 'react-native';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import Pagination from '../Catalog/GridViewPagination';
import TableRow from '../TableComponent/TableRow';
import { useCurrency, useTranslation } from '@hitz-group/localization';
import { startCase } from 'lodash';
import { usePagination } from '../../hooks/usePagination';
import { isWeb } from '../../common/theme';

const displayContainerStyle: StyleFn = ({ theme }) => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  paddingHorizontal: theme.padding.medium,
  paddingTop: theme.padding.medium * 1.5,
  backgroundColor: theme.colors.white,
  borderRadius: theme.radius.small,
});
const summaryTitleStyle: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  fontFamily: theme.font.bold,
  fontSize: theme.fontSize.small,
  textAlign: 'center',
  color: theme.colors.paragraph,
  lineHeight: 21,
  textTransform: 'uppercase',
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.small * 1.5,
});
const paginationContainerStyle: StyleFn = () => ({
  width: '100%',
  flexDirection: 'row',
  height: 40,
  justifyContent: 'space-between',
  alignItems: 'stretch',
  marginTop: 3,
});
const containerStyle: StyleFn = ({ theme }) => ({
  borderRadius: theme.radius.small,
  marginBottom: isWeb ? 10 : 10,
  overflow: 'hidden',
  paddingHorizontal: theme.padding.medium,
  flex: 1,
});
const amountContainerStyle: StyleFn = ({ theme }) => ({
  height: 44,
  width: '100%',
  justifyContent: 'space-between',
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 0.1,
  paddingRight: theme.padding.medium * 1.5,
  paddingTop: theme.padding.medium,
  paddingBottom: theme.padding.medium + 3,
  marginRight: 0,
});
const amountTextStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
  lineHeight: 21,
  textAlign: 'center',
});

const itemContainer: StyleFn = () => ({
  width: 260,
});

interface ShiftDisplayProps {
  data: { [key: string]: number };
  title?: string;
  displayOptions?: React.ReactNode;
  style?: ViewStyle;
}

const ShiftDisplay: React.FC<ShiftDisplayProps> = ({
  data,
  title,
  displayOptions,
  style,
}: ShiftDisplayProps) => {
  const { css } = useFela();
  const { formatCurrency } = useCurrency();
  const { translate } = useTranslation();
  const itemsPerPage = 11;

  const { paginatedItems, onChange, paginate } = usePagination(
    Object.keys(data),
    itemsPerPage,
    'item',
  );

  return (
    <View style={[css(containerStyle), style]}>
      <View style={[css(displayContainerStyle), style]}>
        {title && (
          <Text style={css(summaryTitleStyle)} testID={'shift-number'}>
            {title}
          </Text>
        )}
        {displayOptions}
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={css(itemContainer)} testID={'container'}>
            {paginatedItems.map((x, index) => (
              <TableRow key={index} containerStyle={css(amountContainerStyle)}>
                <Text style={css(amountTextStyle)} testID={`${x}`}>
                  {startCase(x)}
                </Text>
                <Text style={css(amountTextStyle)} testID={`${x}-value`}>
                  {x === 'totalSalesCount' ||
                  x === 'refundCount' ||
                  x === 'refundItemsCount' ||
                  x === translate('shift.refundCount')
                    ? data[x] || 0
                    : formatCurrency(data[x] || 0)}
                </Text>
              </TableRow>
            ))}
          </View>
        </ScrollView>
      </View>
      {paginate && (
        <Pagination
          containerStyle={css(paginationContainerStyle)}
          scrollDirection="vertical"
          onPressNext={() => onChange(1)}
          onPressBack={() => onChange(-1)}
        />
      )}
    </View>
  );
};

export default ShiftDisplay;
