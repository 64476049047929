import {
  Course,
  DEFAULT_ENTITY_ID,
  OrderItem,
  OrderItemStatus,
  DEFAULT_TABLE_ABBREVIATION,
  OrderType,
  OrderTypeCode,
} from '@hitz-group/domain';
import { computeOrderItemTotal } from '@hitz-group/order-helper';
import { useTranslation } from '@hitz-group/localization';
import { StyleFn } from '@hitz-group/domain';
import React, { useCallback, useEffect, useRef, useState, Ref } from 'react';
import { useFela } from 'react-fela';
import {
  ListRenderItemInfo,
  Text,
  View,
  ViewStyle,
  FlatList,
  Platform,
  TouchableOpacity,
} from 'react-native';
import Pagination from '../../components/Catalog/GridViewPagination';
import CartItem from './CartItem/CartItem';
import { useSession } from '../../hooks/app/useSession';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { CartSelectionState } from './Cart';
import CourseWrapper from './CourseWrapper';
import differenceWith from 'lodash/differenceWith';
import { noopHandler } from '../../utils/errorHandlers';
import { useRoute } from '@react-navigation/native';
import CartSeatLayout from './CartSeatLayout';
import { isDigit } from '../../utils/validator';

export interface CartItemProps {
  orderGuestCount?: number;
  orderNote?: string;
  deliveryNote?: string;
  items?: OrderItem[];
  courses?: Course[];
  onToggleAutoFire?: (courseId: string) => void;
  containerStyle?: ViewStyle;
  scrollToBottom?: boolean;
  selectedItem?: CartSelectionState;
  onSelectItem?: (state: CartSelectionState) => void;
  onDeselectItem?: () => void;
  customPaginationContainerStyle?: StyleFn;
  isRefundOrder?: boolean;
  selectedItemIds?: string[];
  onIncrementSeatNumber?: () => void;
  setSelectedSeatNumber?: (seatNumber?: number) => void;
  onSwitchCourseItem?: (courseId: string, orderItemId: string) => void;
  noteStyle?: ViewStyle;
  isCoursesEnabled?: boolean;
  readonlyCourse?: boolean;
  orderType?: OrderType;
}

const cartItemsStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  width: '100%',
  borderBottomLeftRadius: theme.radius.large,
  borderBottomRightRadius: theme.radius.large,
  borderTopLeftRadius: theme.radius.small,
  borderTopRightRadius: theme.radius.small,
  overflow: 'hidden',
  marginBottom: 10,
});

const itemContainerStyle: StyleFn = ({ theme }) => ({
  flex: 1,
  padding: theme.padding.medium,
  backgroundColor: theme.colors.white,
  zIndex: 999,
});

const paginationContainerStyle: StyleFn = () => ({
  flex: 0.1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 3,
});

const orderNoteStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  letterSpacing: -0.5,
  fontFamily: theme.font.medium,
  color: theme.colors.textLight,
  marginBottom: theme.spacing.small,
});

const newLabelRow: StyleFn = () => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginBottom: 10,
  marginTop: 10,
});

const horizontalLineStyle: StyleFn = () => ({
  width: '30%',
  height: 1,
  backgroundColor: '#d9f0ed',
  flexDirection: 'row',
  justifyContent: 'center',
});

const labelContainer: StyleFn = ({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: '#d9f0ed',
  alignItems: 'center',
  borderRadius: theme.radius.small,
});

const newLabelStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  fontWeight: 'bold',
  letterSpacing: 1,
  fontFamily: theme.font.medium,
  color: theme.colors.teal,
  paddingVertical: theme.padding.small,
  paddingHorizontal: theme.padding.small + theme.padding.medium,
});
const dashedLine: StyleFn = ({ theme }) => ({
  borderColor: theme.colors.darkGrey,
  marginTop: theme.padding.small * 2,
  marginBottom: theme.padding.small,
  borderTopWidth: 2,
  borderStyle: 'dashed',
  height: 2,
});

const footerSpace: StyleFn = () => ({
  height: 30,
});
const touchableStyle: StyleFn = () => ({ flex: 1 });

const NewItemLabel: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  return (
    <View style={css(newLabelRow)} testID="label-new">
      <View style={css(horizontalLineStyle)} />
      <View style={css(labelContainer)}>
        <Text
          key="newItemLabel"
          numberOfLines={1}
          ellipsizeMode="tail"
          style={css(newLabelStyle)}
        >
          {translate('order.newLabel')}
        </Text>
      </View>
      <View style={css(horizontalLineStyle)} />
    </View>
  );
};

const FooterSpace: React.FC = () => {
  const { css } = useFela();
  return <View style={css(footerSpace)} />;
};
const CartItems: React.FC<CartItemProps> = ({
  items = [],
  orderGuestCount = 0,
  orderNote,
  onSelectItem,
  onDeselectItem,
  containerStyle,
  selectedItem,
  scrollToBottom,
  customPaginationContainerStyle,
  isRefundOrder,
  courses = [],
  onToggleAutoFire,
  selectedItemIds,
  onIncrementSeatNumber,
  setSelectedSeatNumber: onSeatNumberSelect,
  onSwitchCourseItem,
  noteStyle,
  isCoursesEnabled,
  readonlyCourse,
  orderType,
  deliveryNote,
}: CartItemProps) => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const route = useRoute();
  const flatListRef = useRef<FlatList<OrderItem> | FlatList<Course>>(null);
  const [selectedSeatNumber, setSelectedSeatNumber] = useState('table');
  const [viewableItems, setViewableItems] = useState([0]);
  const [session] = useSession();
  const previousLength = usePreviousValue(items?.length);
  const previousLastItemModifiers = usePreviousValue(
    (items && items.length > 0 && items[items?.length - 1].modifiers) || null,
  );
  const { id: orderId } = (route.params || {}) as {
    id: string;
  };
  const [collapseCourses, setCollapseCourses] = useState<
    Record<string, boolean>
  >({});

  const previousItems = useRef<OrderItem[]>([]);
  const isSeatManagementEnabled =
    session?.deviceProfile?.enableSeatManagement &&
    orderType?.code === OrderTypeCode.DINE_IN;

  const scrollToEnd = () => {
    setTimeout(() => {
      flatListRef && flatListRef.current?.scrollToEnd();
    }, 100);
  };
  const scrollToIndex = (index: number) => {
    if (!flatListRef.current) return;
    flatListRef.current.scrollToIndex({ index });
  };

  useEffect(() => {
    if (
      !isCoursesEnabled &&
      ((scrollToBottom &&
        items &&
        previousLength &&
        items?.length > previousLength) ||
        (scrollToBottom &&
          items &&
          items?.length > 0 &&
          previousLastItemModifiers &&
          items[items?.length - 1].modifiers?.length >
            previousLastItemModifiers.length &&
          items[items?.length - 1].modifiers != previousLastItemModifiers))
    ) {
      scrollToEnd();
      return;
    } else {
      return;
    }
  }, [
    scrollToBottom,
    items,
    previousLength,
    previousLastItemModifiers,
    isCoursesEnabled,
  ]);

  const totalItems = (isCoursesEnabled ? courses?.length : items?.length) || 0;
  const viewportSize = viewableItems.length;

  const oldItems = items?.filter((item: OrderItem) => item.saved);

  const showSeats = isSeatManagementEnabled && orderGuestCount > 0;

  const onPaginateNext = useCallback(() => {
    flatListRef.current?.scrollToIndex({
      index: Math.min(
        viewableItems[viewportSize - 1],
        totalItems ? totalItems - 1 : Infinity,
      ),
    });
  }, [viewableItems, viewportSize, totalItems]);

  const onPaginatePrev = useCallback(() => {
    flatListRef.current?.scrollToIndex({
      index: Math.max(viewableItems[0] - viewportSize, 0),
    });
  }, [viewableItems, viewportSize]);

  // Triggers when flat list is scrolled. Store currently rendered item indices
  const onChangeFlatListViewport = useCallback(({ viewableItems }) => {
    setViewableItems(
      viewableItems.map((x: ListRenderItemInfo<OrderItem>) => x.index),
    );
  }, []);

  const handleCollapseCourse = useCallback(
    (id: string, status: boolean) => {
      if (status === collapseCourses[id]) return;
      setCollapseCourses(preState => ({
        ...preState,
        [id]: status,
      }));
    },
    [collapseCourses],
  );

  const handleCoursePressHeader = useCallback(
    (courseId: string) => {
      const allowSwitchOrderItemStatus = [
        OrderItemStatus.CREATED,
        OrderItemStatus.ON_HOLD,
      ];
      const selectOrderItem = items.find(
        item => item.id === selectedItem?.item,
      ) as OrderItem;

      onDeselectItem?.();
      if (
        !selectOrderItem ||
        !allowSwitchOrderItemStatus.includes(selectOrderItem.status)
      )
        return;

      const defaultCourseId = selectOrderItem.course?.id || DEFAULT_ENTITY_ID;
      if (courseId === defaultCourseId) return;

      onSwitchCourseItem && onSwitchCourseItem(selectOrderItem?.id, courseId);
    },
    [items, onDeselectItem, onSwitchCourseItem, selectedItem?.item],
  );

  useEffect(() => {
    if (!orderId) return;
    setCollapseCourses({});
  }, [orderId]);

  useEffect(() => {
    if (!isCoursesEnabled || !courses.length) return;

    const recentUpsertItems = differenceWith(
      items,
      previousItems.current,
      (preItem, nextItem) => {
        return (
          preItem.id === nextItem.id &&
          preItem?.product?.id === nextItem.product.id &&
          preItem.modifiers?.length === nextItem.modifiers?.length
        );
      },
    );

    if (recentUpsertItems.length) {
      const focusProduct = recentUpsertItems[0].product;
      const courseIndex = Math.max(
        courses.findIndex(course => focusProduct.course?.id === course.id),
        0,
      );
      scrollToIndex(courseIndex);
      handleCollapseCourse(courses[courseIndex].id, false);
      previousItems.current = items;
    }
  }, [isCoursesEnabled, courses, items, handleCollapseCourse]);

  const renderItem = useCallback(
    ({ item, index }: ListRenderItemInfo<OrderItem>) => {
      const variant = item.variant;
      const product = item.product;
      let isSelected = selectedItem?.item === item.id;

      if (!isSelected) {
        const isItemSelected = selectedItemIds?.find(
          itemId => itemId === item.id,
        );
        isSelected = isItemSelected ? true : false;
      }

      const selectedSubItem =
        selectedItem?.selectedModifierKey || selectedItem?.selectedVariantKey;
      const variantOptions = product?.optionValues;

      const newLabel = !isCoursesEnabled &&
        (oldItems?.length || 0) > 0 &&
        oldItems?.length === index && <NewItemLabel />;

      const SeatNumberLabel = showSeats
        ? !item.seatNumber
          ? DEFAULT_TABLE_ABBREVIATION
          : `S${String(item.seatNumber)}`
        : undefined;
      return (
        <>
          {newLabel}
          <CartItem
            id={item?.id}
            key={item?.id}
            variants={variantOptions || []}
            notes={item?.notes}
            productId={product?.id}
            variantId={variant?.id}
            name={variant?.name || product?.name || ''}
            quantity={item?.quantity}
            modifiers={item?.modifiers || []}
            onSelectItem={onSelectItem}
            price={computeOrderItemTotal(item)}
            isSelected={isSelected}
            discountAmount={item?.discountAmount}
            surchargeAmount={item?.surchargeAmount}
            status={item?.status}
            reason={item?.reason}
            testID={`cart-item-${index}`}
            isRefundOrder={isRefundOrder}
            selectedSubItem={selectedSubItem}
            showFiredIcon={!readonlyCourse && isCoursesEnabled}
            itemFired={item.itemFired}
            seatNumber={SeatNumberLabel}
          />
        </>
      );
    },
    [
      selectedItem?.item,
      selectedItem?.selectedModifierKey,
      selectedItem?.selectedVariantKey,
      selectedItemIds,
      isCoursesEnabled,
      oldItems?.length,
      onSelectItem,
      isRefundOrder,
      showSeats,
      readonlyCourse,
    ],
  );

  const renderCourseItem = useCallback(
    ({ item: course }: ListRenderItemInfo<Course>) => {
      const selectedProducts = items.filter(item => {
        if (course.id === DEFAULT_ENTITY_ID) return !item.course?.id;
        return item.course?.id === course.id;
      });

      if (!selectedProducts.length) return null;

      const firedItemsCount = selectedProducts.reduce((total, item) => {
        if (item.itemFired && item.status !== OrderItemStatus.VOID)
          total += item.quantity;
        return total;
      }, 0);

      const totalItemsCount = selectedProducts.reduce((total, item) => {
        if (item.status !== OrderItemStatus.VOID) total += item.quantity;
        return total;
      }, 0);

      const name =
        !readonlyCourse && firedItemsCount > 0
          ? `${course.name} (${firedItemsCount}/${totalItemsCount})`
          : course.name;

      const newItems = selectedProducts?.filter(item => !item.saved);

      const savedUnfiredItems = selectedProducts?.filter(
        item => item.saved && !item.itemFired,
      );

      const firedItems = selectedProducts?.filter(
        item => item.saved && item.itemFired,
      );

      const showDashedLine =
        (savedUnfiredItems.length || firedItems.length) && newItems.length;

      return (
        <CourseWrapper
          name={name}
          id={course.id}
          autoFire={course.autoFire}
          onToggleAutoFire={onToggleAutoFire}
          readonlyCourse={readonlyCourse}
          disabled={firedItemsCount === totalItemsCount}
          isCollapsed={collapseCourses[course.id]}
          onCollapseCourse={handleCollapseCourse}
          onPressCourseHeader={handleCoursePressHeader}
        >
          {newItems?.map((item, index) => (
            <View key={item.id}>
              {renderItem({ item, index } as ListRenderItemInfo<OrderItem>)}
            </View>
          ))}
          {Boolean(showDashedLine) && <View style={css(dashedLine)} />}
          {savedUnfiredItems?.map((item, index) => (
            <View key={item.id}>
              {renderItem({ item, index } as ListRenderItemInfo<OrderItem>)}
            </View>
          ))}
          {firedItems?.map((item, index) => (
            <View key={item.id}>
              {renderItem({ item, index } as ListRenderItemInfo<OrderItem>)}
            </View>
          ))}
        </CourseWrapper>
      );
    },
    [
      items,
      readonlyCourse,
      onToggleAutoFire,
      collapseCourses,
      handleCollapseCourse,
      handleCoursePressHeader,
      css,
      renderItem,
    ],
  );

  const onPressSeatNumber = (seatNumber: string) => {
    if (seatNumber == 'table') {
      onSeatNumberSelect && onSeatNumberSelect(undefined);
    }
    if (seatNumber == '+') {
      onIncrementSeatNumber && onIncrementSeatNumber();
    }
    if (isDigit(seatNumber)) {
      onSeatNumberSelect && onSeatNumberSelect(Number(seatNumber));
    }
    setSelectedSeatNumber(seatNumber);
  };
  const showPagination =
    Platform.OS == 'web' && session?.settings?.showScrollSetting;

  // Compute current page based on viewport items
  // Fool Pagination component into disabling and enabling next and prev buttons the way we want to
  const currentPageSimulated =
    viewableItems[viewportSize - 1] - viewportSize <= 0
      ? 1
      : viewableItems[viewportSize - 1] === totalItems - 1
      ? Infinity
      : 2;

  return (
    <View
      style={[css(cartItemsStyle), containerStyle]}
      testID="cart-items-container"
    >
      {orderNote ? (
        <Text
          testID={'order-note'}
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[css(orderNoteStyle), noteStyle]}
        >
          {translate('order.itemNote', { value: orderNote })}
        </Text>
      ) : undefined}
      {deliveryNote ? (
        <Text
          testID={'delivery-note'}
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[css(orderNoteStyle), noteStyle]}
        >
          {translate('order.deliveryNote', { value: deliveryNote })}
        </Text>
      ) : undefined}
      <TouchableOpacity
        onPress={onDeselectItem}
        style={css(touchableStyle)}
        activeOpacity={1}
        testID="cart-items-container-empty-space"
      >
        {showSeats && (
          <CartSeatLayout
            seatNumbers={orderGuestCount || 0}
            onSelectSeatNumber={onPressSeatNumber}
            selectedSeatNumber={selectedSeatNumber}
          />
        )}

        {isCoursesEnabled ? (
          <FlatList
            data={courses}
            ref={flatListRef as Ref<FlatList<Course>>}
            renderItem={renderCourseItem}
            style={css(itemContainerStyle)}
            showsVerticalScrollIndicator={false}
            onViewableItemsChanged={onChangeFlatListViewport}
            ListFooterComponent={<FooterSpace />}
            onScrollToIndexFailed={noopHandler}
          />
        ) : (
          <FlatList
            data={items}
            ref={flatListRef as Ref<FlatList<OrderItem>>}
            renderItem={renderItem}
            extraData={selectedItem}
            style={css(itemContainerStyle)}
            showsVerticalScrollIndicator={false}
            onViewableItemsChanged={onChangeFlatListViewport}
            ListFooterComponent={<FooterSpace />}
          />
        )}
      </TouchableOpacity>
      {showPagination && (
        <Pagination
          onPressNext={onPaginateNext}
          onPressBack={onPaginatePrev}
          scrollDirection="vertical"
          currentPage={currentPageSimulated}
          totalPages={totalItems}
          containerStyle={css(
            customPaginationContainerStyle
              ? customPaginationContainerStyle
              : paginationContainerStyle,
          )}
        />
      )}
    </View>
  );
};

export default React.memo(CartItems);
