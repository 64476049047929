import React, { createContext, useContext, useEffect } from 'react';
import { UseCustomersProps, useCustomers } from './orders/useCustomers';

export interface POSStateContextValue {
  customers: UseCustomersProps;
}

export const POSStateContext = createContext<POSStateContextValue>(
  {} as POSStateContextValue,
);

export const POSStateProvider: React.FC<{}> = ({ children }) => {
  const customersHooks = useCustomers();
  const { getCustomers } = customersHooks;
  useEffect(() => {
    getCustomers();
  }, [getCustomers]);
  return (
    <POSStateContext.Provider value={{ customers: customersHooks }}>
      {children}
    </POSStateContext.Provider>
  );
};

export const usePosState = <T extends keyof POSStateContextValue>(
  key: T,
): POSStateContextValue[T] => {
  const context = useContext(POSStateContext);
  if (!context)
    throw new Error(
      '`useCartContext` hook must be used within a `POSStateProvider` component',
    );
  return context[key];
};
