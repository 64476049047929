import TableRow from '../../../../../components/TableComponent/TableRow';
import React, { FC } from 'react';
import { View } from 'react-native';
import FormInput from '../../../../../components/FormInput/FormInput';
import { AdjustmentRowItem } from './Adjustments';
import { AdjustmentUnit } from '@hitz-group/domain';
import InputWithUnit from '../../../../../components/FormInput/FormInputWithDropdown';
import { useFela } from 'react-fela';
import IconButton from '../../../../../components/Button/IconButton';
import { AdjustmentsStyles } from '../styles/Adjustments.styles';
import { formatToDigitsStr } from '@hitz-group/client-utils';
import { getCurrencySymbol } from '@hitz-group/localization';
import { useSession } from '../../../../../hooks/app/useSession';
import { countries } from 'countries-list';
export interface AdjustmentRowProp {
  item: AdjustmentRowItem;
  onChangeItem: (newItem: AdjustmentRowItem, index: number) => void;
  index: number;
  onDeleteItem: (item: AdjustmentRowItem, index: number) => void;
  error?: Record<'name' | 'amount', boolean>;
}
export type InputKeys =
  | 'amountStr'
  | 'name'
  | 'allowOnPaymentType'
  | 'adjustmentUnit';

const AdjustmentRow: FC<AdjustmentRowProp> = ({
  onChangeItem,
  item,
  onDeleteItem,
  index,
  error = {},
}) => {
  const { theme } = useFela();
  const [session] = useSession();
  const { country = 'AU' } = session?.currentOrganization || {};
  const currencyCode = countries[country]?.currency;
  const styles = AdjustmentsStyles();
  const handleChange = (key: InputKeys, value: string) => {
    let updateItem: AdjustmentRowItem;
    if (key === 'allowOnPaymentType') {
      updateItem = {
        ...item,
        allowOnPaymentType: !item.allowOnPaymentType,
      };
    } else {
      updateItem = { ...item, [key]: value };
    }

    onChangeItem(updateItem, index);
  };

  return (
    <View>
      <TableRow
        containerStyle={styles.rowItemRowContainerStyle}
        normalRows={true}
      >
        <View style={styles.rowItemNameViewStyle}>
          <FormInput
            testID="adjustment-name"
            value={item.name}
            onChangeText={value => handleChange('name', value)}
            containerStyle={styles.rowItemFormInputContainerStyle}
            error={error['name']}
            maxLength={24}
          />
        </View>
        <View style={styles.rowItemAmountViewStyle}>
          <InputWithUnit
            inputValue={item.amountStr}
            dropdownValue={item.adjustmentUnit || AdjustmentUnit.PERCENTAGE}
            onDropdownChange={value => handleChange('adjustmentUnit', value)}
            onInputChange={value =>
              handleChange('amountStr', formatToDigitsStr(value, true))
            }
            dropdownOptions={[
              { value: AdjustmentUnit.PERCENTAGE, label: '%' },
              {
                value: AdjustmentUnit.FLAT,
                label: getCurrencySymbol(currencyCode),
              },
            ]}
            dropdownWidth={53}
            error={error['amount']}
            testID={'adjustment-amount'}
          />
        </View>
        <View style={styles.rowItemPaymentTypeViewStyle}>
          <IconButton
            onPress={() => handleChange('allowOnPaymentType', '')}
            icon={item.allowOnPaymentType ? 'check-square' : 'square-full'}
            iconColor={
              item.allowOnPaymentType
                ? theme.colors.success
                : theme.colors.darkGrey
            }
            iconSize={22}
            testID="allow-payment-testID"
          />
        </View>
        <View style={styles.rowItemDeleteIconContainerStyle}>
          <IconButton
            icon="TrashAlt"
            testID="delete-icon"
            iconSize={18}
            containerSize={38}
            containerStyle={styles.rowItemCloseIconContainerStyle}
            iconColor={theme.colors.red}
            onPress={() => onDeleteItem(item, index)}
          />
        </View>
      </TableRow>
    </View>
  );
};

export default AdjustmentRow;
