import { CataloguePageInput, Page, Product, Variant } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { useNavigation } from '@react-navigation/native';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { Text, View } from 'react-native';
import {
  CheckIcon,
  CloseIcon,
} from '../../../components/HeaderIcons/HeaderIcons';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import DraggableMenuLayout from '../../../components/MenuEditor/DraggableMenuLayout';
import {
  catalogStyle,
  ContainerStyle,
  pageContainerStyle,
  styles,
  titleFont,
} from '../../../components/MenuEditor/MenuEditorLayout.styles';
import { DraggableTile } from '../../../components/MenuEditor/menuTypes';
import Layout from '../../../components/POSLayout/POSLayout';
import { useCatalogue } from '../../../hooks/app/catalogue/useCatalogue';
import { useSession } from '../../../hooks/app/useSession';
import { useNotification } from '../../../hooks/Notification';

const POSMenuEditor: React.FC = () => {
  const { css } = useFela();
  const { translate } = useTranslation();
  const [session] = useSession();
  const navigation = useNavigation();

  const onPressClose = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const { showNotification } = useNotification();
  const currentMenuId = session?.deviceProfile?.menu?.id;
  const [pageItemsMap, setPageItemsMap] = useState<
    Record<string, DraggableTile[]>
  >({});
  const [selectedPage, setSelectedPage] = useState<string>('');
  const [pageTiles, setPageTiles] = useState<Page[]>([]);
  const { pages, allProducts, allVariants, status, updateCatalogue, menus } =
    useCatalogue({
      menuId: currentMenuId,
      showEmptyPages: true,
    });

  const isLoading = status.loading;
  const error = status.error;

  const pageOptions = useMemo(() => {
    return pages.map(x => ({ value: x.id, label: x.name }));
  }, [pages]);

  useEffect(() => {
    if (error) {
      showNotification({
        message: error,
        error: true,
      });
    }
  }, [error, showNotification]);

  const onSelectPage = useCallback(
    (pageId: string, pageItems: Array<Product | Variant | Page>) => {
      setSelectedPage(pageId);
      const productArray = sortBy(pageItems, ['priority']).map(
        (data: Product | Variant | Page, i: number) => {
          return {
            ...(allProducts[data.id] || allVariants[data.id] || data),
            key: i,
            disabledDrag: false,
            disabledReSorted: false,
          };
        },
      );
      if (productArray?.length) {
        setPageItemsMap(prev => ({ ...prev, [pageId]: productArray }));
      }
    },
    [allProducts, allVariants],
  );

  useEffect(() => {
    const pageToBeSelected = pages?.[0];
    // selecting the first page if no page is selected
    if (!selectedPage && pageToBeSelected?.id) {
      onSelectPage(pageToBeSelected.id, [
        ...(pageToBeSelected?.products || []),
        ...(pageToBeSelected?.variants || []),
      ]);
    }
  }, [onSelectPage, pages, selectedPage]);

  const onDragProducts = useCallback(
    (obj: DraggableTile[]) => {
      const reArrangedProducts = obj.filter(
        x =>
          x &&
          x.__typename &&
          ['Product', 'Variant', 'Page'].includes(x.__typename),
      );
      setPageItemsMap(prev => ({
        ...prev,
        [selectedPage]: reArrangedProducts,
      }));
    },
    [selectedPage],
  );

  const onDragPages = (reArrangedPages: DraggableTile[]) => {
    const pagesData = reArrangedPages
      .filter(x => x && x.name != '' && x.name != 'Add')
      .map((x: DraggableTile) => {
        return x as unknown as Page;
      });
    setPageTiles(pagesData);
  };

  // header components
  const headerTitle = useMemo(
    () => (
      <View style={[styles.headerTitle, css(ContainerStyle)]}>
        <Text style={css(titleFont)}>
          {String(translate('menuEditor.editMenuLayout')).toLocaleLowerCase()}
        </Text>
      </View>
    ),
    [css, translate],
  );

  const onSaveEditedCatalogue = useCallback(() => {
    const updateCatalogueDataMap: { [key: string]: CataloguePageInput } = {};

    const pagesToUpdate = (pageTiles?.length && pageTiles) || pages;

    pagesToUpdate.forEach((eachPage, indexOfPage) => {
      const priorityOfPageItemsMap: Record<string, number> = {};

      pageItemsMap?.[eachPage.id]?.forEach((eachPageItem, indexOfPageItem) => {
        if (eachPageItem?.id) {
          priorityOfPageItemsMap[eachPageItem?.id] = indexOfPageItem;
        }
      });

      const catPage: CataloguePageInput = {
        id: eachPage.id,
        pages: [],
        priority: indexOfPage,
        products: [],
        variants: [],
      };

      if (eachPage?.pages?.length) {
        eachPage?.pages.forEach((eachSubPage, subPageIndex) => {
          catPage.pages.push({
            id: eachSubPage.id,
            priority: priorityOfPageItemsMap[eachSubPage.id] || subPageIndex,
          });
        });
      }

      if (eachPage?.products?.length) {
        // TODO have to re-arrange products
        eachPage?.products?.forEach((eachProduct, prodIndex) => {
          catPage.products.push({
            id: eachProduct.id,
            priority: priorityOfPageItemsMap[eachProduct.id] || prodIndex,
          });
        });
      }

      if (eachPage?.variants?.length) {
        eachPage?.variants?.forEach((eachVar, indexOfVar) => {
          catPage.variants.push({
            id: eachVar.id,
            priority: priorityOfPageItemsMap[eachVar.id] || indexOfVar,
          });
        });
      }
      updateCatalogueDataMap[catPage.id] = catPage;
    });

    const currentMenu = menus.filter(x => x.id === currentMenuId)?.[0];

    if (currentMenuId && currentMenu?.id === currentMenuId) {
      updateCatalogue({
        id: currentMenuId,
        pages: Object.values(updateCatalogueDataMap),
        name: currentMenu.name,
      });
    }
  }, [pageTiles, pages, menus, currentMenuId, pageItemsMap, updateCatalogue]);

  const headerRight = useMemo(() => {
    return <CheckIcon onPress={onSaveEditedCatalogue} />;
  }, [onSaveEditedCatalogue]);

  const headerLeft = useMemo(() => {
    return <CloseIcon onPress={onPressClose} />;
  }, [onPressClose]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Layout
      title={translate('navigation.menuEditor', {
        appName: translate('appName'),
      })}
      testID="pos-menu-editor"
      headerTitle={headerTitle}
      headerLeft={headerLeft}
      headerRight={headerRight}
      contentStyle={css(pageContainerStyle)}
    >
      {currentMenuId && (
        <View style={css(catalogStyle)}>
          <DraggableMenuLayout
            pages={(pageTiles?.length && pageTiles) || pages}
            pageItems={pageItemsMap?.[selectedPage] || []}
            onDragPages={onDragPages}
            onDragProducts={onDragProducts}
            onSelectPage={onSelectPage}
            allProductsMap={allProducts}
            allVariantsMap={allVariants}
            selectedPage={selectedPage}
            pageOptions={pageOptions}
          />
        </View>
      )}
    </Layout>
  );
};

export default POSMenuEditor;
