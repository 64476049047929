import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import ModifierTabNavigator from './ModifierTabNavigator';
import CreateModifierGroupTab from './CreateModifierGroup/CreateModifierGroupNavigator';

const Stack = createStackNavigator();

const ModifiersStack: React.FC = () => (
  <Stack.Navigator
    headerMode="none"
    initialRouteName="OptionsTabs"
    screenOptions={{
      animationEnabled: true,
      cardOverlayEnabled: false,
      cardShadowEnabled: false,
    }}
  >
    <Stack.Screen component={ModifierTabNavigator} name="OptionsTabs" />
    <Stack.Screen
      component={CreateModifierGroupTab}
      name="CreateModifierGroupTab"
    />
  </Stack.Navigator>
);

export default ModifiersStack;
