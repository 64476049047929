import React from 'react';
import { FelaComponent } from 'react-fela';
import { View, Text, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { StyleFn, RenderProps } from '@hitz-group/domain';
const displayContainerStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.primary,
  height: '21%',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.radius.small,
  elevation: 4,
  width: '100%',
});

const textStyle: StyleFn = ({ theme, isSecure }) => ({
  color: theme.colors.warning,
  fontSize: 32,
  fontFamily: isSecure ? theme.font.bold : theme.font.semibold,
  letterSpacing: isSecure ? 15 : 0,
  textAlign: 'center',
});

const styles = StyleSheet.create({
  rightFlank: {
    position: 'absolute',
    right: 25,
    justifyContent: 'center',
  },
  leftFlank: {
    position: 'absolute',
    left: 25,
    justifyContent: 'center',
  },
});

export interface TextDisplayProps {
  testID?: string;
  children: React.ReactChild;
  isSecure?: boolean;
  extraTextStyle?: TextStyle;
}

const TextDisplay: React.FC<TextDisplayProps> = props => (
  <FelaComponent style={textStyle} {...props}>
    {({ style }: RenderProps): React.ReactFragment => (
      <Text
        style={[style, props.extraTextStyle && props.extraTextStyle]}
        {...props}
      />
    )}
  </FelaComponent>
);

export interface InputDisplayProps {
  secure?: boolean;
  value: string;
  testID?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
  containerStyle?: ViewStyle;
  extraTextStyle?: TextStyle;
  extraRightFlank?: ViewStyle;
}

const InputDisplay: React.FC<InputDisplayProps> = ({
  secure,
  value,
  testID,
  right,
  left,
  containerStyle,
  extraTextStyle,
  extraRightFlank,
}: InputDisplayProps) => (
  <FelaComponent style={displayContainerStyle}>
    {({ style }: RenderProps): React.ReactFragment => (
      <View style={[style, containerStyle]} testID={testID}>
        {left && <View style={styles.leftFlank}>{left}</View>}
        <TextDisplay
          testID="textfield"
          isSecure={secure}
          extraTextStyle={extraTextStyle}
        >
          {secure ? value.replace(/[0-9]/g, '•') : value}
        </TextDisplay>
        {right && (
          <View style={[styles.rightFlank, extraRightFlank && extraRightFlank]}>
            {right}
          </View>
        )}
      </View>
    )}
  </FelaComponent>
);

export default InputDisplay;
