import React from 'react';
import { useFela } from 'react-fela';
import IconButton from '../../../../../components/Button/IconButton';
import FormInput from '../../../../../components/FormInput/FormInput';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import scale, { isAndroid, isIos } from '../../../../../common/theme';
import { View, Text } from 'react-native';
import { StyleFn, Section, Store } from '@hitz-group/domain';
import { useNavigation } from '@react-navigation/native';
import { translate } from '@hitz-group/localization';

const sectionDataRowStyle: StyleFn = ({ index }) => ({
  zIndex: 1000 - 10 * index,
});

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 260,
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  marginHorizontal: scale.moderateScale(2),
  top: isAndroid ? -10 : isIos ? -5 : 0,
});

const dropDownContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  flex: 1,
  marginHorizontal: scale.moderateScale(2),
});

const formTextStyle: StyleFn = () => ({
  paddingHorizontal: 0,
  paddingVertical: 0,
});

const rightAngleStyle: StyleFn = () => ({
  height: scale.moderateScale(27),
});

const closeIconContainerStyle: StyleFn = ({ theme }) => ({
  height: 38,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.highlighted,
  alignSelf: 'center',
  marginHorizontal: theme.spacing.small,
});

const closeIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.blue,
});

const productCountStyle: StyleFn = ({ theme }) => ({
  width: 65,
  height: theme.input.height,
  alignItems: 'center',
  justifyContent: 'center',
  marginHorizontal: theme.spacing.small,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.greyLight,
});

const menuStyle: StyleFn = ({ theme }) => ({
  ...theme.font14RegularCharcoal,
});

const rowStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'row',
  minHeight: 57,
  alignItems: 'center',
  marginRight: theme.spacing.small,
  borderBottomColor: theme.colors.boxBorder,
  borderBottomWidth: 1,
});

interface SectionRowProps {
  index: number;
  sectionData: Section;
  stores: Store[];
  onChange: (id: string, prop: string, value: string) => void;
  onStoresSelect: (id: string, values: string[]) => void;
  onSectionCopy: (section: Section) => void;
}

const SectionRow: React.FC<SectionRowProps> = ({
  index,
  sectionData,
  onChange,
  onStoresSelect,
  stores,
  onSectionCopy,
}: SectionRowProps) => {
  const { css, theme } = useFela();
  const navigation = useNavigation();

  const storeNamesData = stores.map(store => ({
    label: store.name,
    value: store.id,
  }));
  const selectedStores = sectionData.stores.map(store => store.id);

  return (
    <View
      testID="section-row"
      style={css(sectionDataRowStyle({ theme, index }))}
    >
      <View style={css(rowStyle)}>
        <FormInput
          testID="sectionData-name"
          value={sectionData.name}
          containerStyle={css(formInputContainerStyle)}
          textStyle={css(formTextStyle)}
          onChangeText={onChange.bind(null, sectionData.id, 'name')}
        />

        <MultipleSelect
          selectedValues={selectedStores}
          containerStyle={css(dropDownContainerStyle)}
          values={storeNamesData}
          searchPlaceHolder={translate('backOfficeSections.searchStores')}
          searchLabel={translate('backOfficeSections.stores')}
          onValueChange={values => onStoresSelect(sectionData.id, values)}
        />

        <View testID="product-count" style={css(productCountStyle)}>
          <Text style={css(menuStyle)}>{sectionData.tables.length}</Text>
        </View>

        <IconButton
          icon="copy"
          testID="copy-button"
          iconSize={24}
          containerSize={34}
          containerStyle={css(closeIconContainerStyle)}
          iconStyle={css(closeIconStyle)}
          onPress={() => onSectionCopy(sectionData)}
        />

        <IconButton
          icon="AngleRight"
          testID="setting-button"
          iconSize={24}
          containerSize={34}
          containerStyle={css(rightAngleStyle)}
          iconColor={theme.colors.paragraphLight}
          onPress={(): void => {
            sectionData.id &&
              navigation.navigate('SectionSettings', {
                sectionId: sectionData.id,
                venueId: sectionData.venue.id,
              });
          }}
        />
      </View>
    </View>
  );
};

export default SectionRow;
