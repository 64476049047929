import {
  Section,
  Store,
  StyleFn,
  TableNamingMethods,
  TableShape,
  CreateTableInput,
  UpdateTableInput,
  CreateSectionInput,
  UpdateSectionInput,
  Table,
} from '@hitz-group/domain';
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { View, ScrollView } from 'react-native';
import { Helmet } from 'react-helmet';
import { isIos, isWeb } from '../../../../../common/theme';
import BackOfficeSection from '../../../../../components/BackOfficeSection/BackOfficeSection';
import FormInput from '../../../../../components/FormInput/FormInput';
import { useTranslation } from '@hitz-group/localization';
import Button from '../../../../../components/Button/Button';
import DropDown from '../../../../../components/FormInput/DropDown';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import { DetailsStyles } from './Details.styles';
import ConfirmationDialog from '../../../../../components/Modals/ConfirmationDialog';
import { useNotification } from '../../../../../hooks/Notification';
import { isNotEmpty } from '../../../../../utils/validator';
import { useFela } from 'react-fela';
import MultipleSelect from '../../../../../components/MultipleSelect/MultipleSelect';
import scale from '../../../../../common/theme';
import { TableIcon } from '../../../../../components/TableIcon/TableIcon';
import IconButton from '../../../../../components/Button/IconButton';
import {
  CreateTableModal,
  CreateTableModalForm,
} from '../CreateTableModal/CreateTableModal';
import { LoadingIndicator } from '../../../../../components/Loading/LoadingIndicator';
import { useTables } from '../../../../../hooks/app/tables/useTables';
import { pick } from 'lodash';
import { getHighestTableNumber } from '../../../../../utils/TableHelper';
import { Operation } from '../../../../../types/Operation';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useSections } from '../../../../../hooks/app/sections/useSections';
import { useStores } from '../../../../../hooks/app/useStores';
import { EditTableModal } from '../EditTableModal/EditTableModal';
import Icon from '../../../../../components/Icon/Icon';

const dropDownContainerStyle: StyleFn = ({ theme }) => ({
  height: theme.input.height,
  width: 260,
  marginHorizontal: scale.moderateScale(2),
  marginTop: scale.moderateScale(3),
});

type FormState = Section & { isChanged: boolean };

export const Details: React.FC = () => {
  const { translate } = useTranslation();
  const styles = DetailsStyles();
  const { showNotification } = useNotification();
  const { css } = useFela();
  const { showModal, closeModal } = useModal();
  const route = useRoute();
  const navigation = useNavigation();
  const [namingMethod, setNamingMethod] = useState<TableNamingMethods>(
    TableNamingMethods.GENERATE_AUTOMATICALLY,
  );
  const [form, setForm] = useState<FormState>({
    name: '',
    defaultPrefix: 'T',
    defaultTableShape: TableShape.RECTANGLE,
  } as FormState);

  const params = route.params as {
    venueId: string;
    sectionId: string;
    operation: Operation;
  };

  const venueId = params.venueId || '';
  const sectionId = params.sectionId || '';
  const operationRef = useRef(params.operation || 'edit');

  const {
    getStores,
    stores,
    loading: loadingStores,
    error: errorStores,
  } = useStores();

  const {
    loading: sectionLoading,
    error: sectionError,
    section,
    getSection,
    createSection,
    createdSectionId,
    updateSection,
    deleteSection,
    operation: sectionOperation,
  } = useSections({ venueId, sectionId });

  const {
    error: tableError,
    getTables,
    createTables,
    deleteTable,
    updateTables,
    operation: tablesOperation,
    loading: tableLoading,
    tables,
  } = useTables({ sectionId });

  useEffect(() => {
    if (sectionId) {
      getSection();
      getTables();
    }
    getStores();
  }, [getSection, sectionId, getTables, getStores]);

  const venueStores = useMemo(() => {
    const storesData = Object.values(stores || []).filter(
      store => store?.venue?.id === venueId,
    );
    return storesData;
  }, [venueId, stores]);

  const storesOptions = useMemo(
    () => venueStores.map(x => ({ label: x.name, value: x.id })),
    [venueStores],
  );

  const error = tableError || sectionError || errorStores;
  const loading = tableLoading || sectionLoading || loadingStores;

  // tables
  const isTableCreated =
    tablesOperation === Operation.CREATE && !tableLoading && !tableError;
  const isTableUpdated =
    tablesOperation === Operation.UPDATE && !tableLoading && !tableError;
  const isTableDeleted =
    tablesOperation === Operation.DELETE && !tableLoading && !tableError;

  // sections
  const isSectionCreated =
    sectionOperation === Operation.CREATE && !sectionLoading && !sectionError;
  const isSectionUpdated =
    sectionOperation === Operation.UPDATE && !sectionLoading && !sectionError;
  const isSectionDeleted =
    sectionOperation === Operation.DELETE && !sectionLoading && !sectionError;

  useEffect((): void => {
    if (isTableCreated) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.tableCreatedSuccessfully'),
      });
      closeModal();
    }
  }, [closeModal, isTableCreated, showNotification, translate]);

  useEffect((): void => {
    if (isTableUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyUpdated'),
      });
      closeModal();
    }
  }, [closeModal, isTableUpdated, showNotification, translate]);

  useEffect((): void => {
    if (isTableDeleted) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.successfullyDeleted'),
      });
      closeModal();
    }
  }, [closeModal, isTableDeleted, showNotification, translate]);

  useEffect(() => {
    if (section) {
      setForm(previousForm => ({ ...previousForm, ...section } as FormState));
    }
  }, [section]);

  useEffect(() => {
    if (Object.values(tables).length) {
      setForm(
        previousForm =>
          ({ ...previousForm, tables: Object.values(tables) } as FormState),
      );
    }
  }, [tables]);

  useEffect((): void => {
    if (isSectionCreated && createdSectionId !== '') {
      operationRef.current = Operation.UPDATE;
      showNotification({
        success: true,
        message: translate('backOfficeSections.sectionCreatedSuccessfully'),
      });
      /*
      navigation.goBack();
         */
      navigation.navigate('SectionSettings', {
        venueId,
        sectionId: createdSectionId,
      });
      setForm(
        previousForm =>
          ({ ...previousForm, id: createdSectionId } as FormState),
      );
    }
  }, [
    isSectionCreated,
    createdSectionId,
    navigation,
    showNotification,
    translate,
    venueId,
  ]);

  useEffect((): void => {
    if (isSectionUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeSections.sectionUpdatedSuccessfully'),
      });
    }
  }, [isSectionUpdated, showNotification, translate]);

  useEffect((): void => {
    if (isSectionDeleted) {
      showNotification({
        success: true,
        message: translate('backOfficeSections.sectionDeletedSuccessfully'),
      });
      closeModal();
      navigation.goBack();
    }
  }, [closeModal, isSectionDeleted, navigation, showNotification, translate]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onChange = useCallback((prop: string, value): void => {
    setForm(sectionDetails => {
      return {
        ...sectionDetails,
        [prop]: value,
      };
    });
  }, []);

  const onStoreSelect = useCallback(
    (values: string[]): void => {
      setForm(sectionDetails => {
        return {
          ...sectionDetails,
          stores: values.map(x => x && stores[x]) as Store[],
        };
      });
    },
    [stores],
  );

  const onPressDelete = useCallback((): void => {
    deleteSection(form.id);
  }, [deleteSection, form.id]);

  const onPressSave = useCallback((): void => {
    if (operationRef.current === Operation.CREATE) {
      // create
      if (!form?.stores || form?.stores?.length < 1) {
        showNotification({
          error: true,
          message: translate('backOfficeSections.storeRequired'),
        });
        return;
      }
      const createdSection = {
        name: form.name,
        venue: venueId,
        defaultPrefix: form.defaultPrefix,
        defaultTableShape: form.defaultTableShape,
        stores: (form.stores || []).map(store => store.id),
        tables: (form.tables || []).map(table => table.id),
        isActive: true,
      } as CreateSectionInput;
      createSection(createdSection);
    } else {
      const updatedSection = {
        ...pick(form, [
          'id',
          'name',
          'defaultPrefix',
          'defaultTableShape',
          'isActive',
        ]),
        venue: venueId,
        stores: (form.stores || []).map(store => store.id),
        tables: (form.tables || []).map(table => table.id),
      } as UpdateSectionInput;
      updateSection(updatedSection);
    }
  }, [
    createSection,
    form,
    showNotification,
    translate,
    updateSection,
    venueId,
  ]);

  const {} = useTables({
    sectionId: form.id,
  });

  const selectedStores = (form.stores || []).map(store => store.id);
  const tableNamingMethods = Object.values(TableNamingMethods);

  const onDelete = useCallback((): void => {
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', {
          label: form.name,
        })}
        onConfirm={onPressDelete}
      />,
    );
  }, [showModal, onPressDelete, translate, form]);

  const onSingleTableCreate = useCallback(
    (createForm: CreateTableModalForm) => {
      const newTable = {
        ...pick(createForm, ['name', 'shape']),
        horizontalSeats: parseInt(createForm.horizontalSeats || '2'),
        verticalSeats: parseInt(createForm.verticalSeats || '2'),
        section: form.id,
      } as CreateTableInput;

      createTables([newTable]);
    },
    [createTables, form.id],
  );

  const onMultipleTableCreate = useCallback(
    (createForm: CreateTableModalForm) => {
      const namePrefix = createForm.prefix;

      const highestTableNumber = getHighestTableNumber(Object.values(tables));

      const tempTable = {
        shape: createForm.shape,
        horizontalSeats: parseInt(createForm.horizontalSeats),
        verticalSeats: parseInt(createForm.verticalSeats),
        section: form.id,
      } as CreateTableInput;

      const newTables = [];

      for (let i = 1; i <= parseInt(createForm.numberOfTables); i++) {
        const newTable = {
          ...tempTable,
          name:
            namePrefix && namePrefix !== ''
              ? `${namePrefix}-${i + highestTableNumber}`
              : `${i + highestTableNumber}`,
        };
        newTables.push(newTable);
      }

      createTables(newTables);
    },
    [createTables, form, tables],
  );

  const onTableUpdate = useCallback(
    (updatedTable: UpdateTableInput) => {
      const isValidTableName = !Object.values(tables)
        .filter(table => table.id !== updatedTable.id)
        .map(table => table.name)
        .includes(updatedTable.name || '');
      if (isValidTableName) {
        updateTables([updatedTable]);
      } else {
        showNotification({
          error: true,
          message: translate('backOfficeSettings.duplicateTableName'),
        });
      }
    },
    [updateTables, tables, showNotification, translate],
  );

  const onTableDelete = useCallback(
    (tableId: string) => deleteTable(tableId),
    [deleteTable],
  );

  const onPressAddTable = useCallback(() => {
    if (form.id)
      showModal(
        <CreateTableModal
          section={form}
          onSingleTableCreate={onSingleTableCreate}
          onMultipleTableCreate={onMultipleTableCreate}
        />,
      );
    else
      showNotification({
        error: true,
        message: translate('backOfficeSettings.pleaseSaveSectionFirst'),
      });
  }, [
    form,
    onMultipleTableCreate,
    onSingleTableCreate,
    showModal,
    showNotification,
    translate,
  ]);

  const onSave = useCallback((): void => {
    if (!form.name || !isNotEmpty(form.name)) {
      showNotification({
        error: true,
        message: translate('backOfficeSettings.sectionNameIsRequired'),
      });
      return;
    }
    onPressSave();
  }, [showNotification, translate, form, onPressSave]);

  const onChangeTableNamingMethod = useCallback(
    (value: TableNamingMethods) => {
      if (value !== namingMethod) setNamingMethod(value);
    },
    [namingMethod],
  );

  const onPressTable = useCallback(
    (table: Table) => {
      showModal(
        <EditTableModal
          onTableUpdate={onTableUpdate}
          onTableDelete={() => onTableDelete(table.id)}
          table={table}
        />,
      );
    },
    [onTableDelete, onTableUpdate, showModal],
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>
      <View style={styles.pageStyle}>
        <ScrollView
          testID="section-settings-screen"
          contentContainerStyle={styles.generalSettingsContainerWrapper}
        >
          <BackOfficeSection
            title={translate('backOfficeSettings.detailsTables')}
            contentContainerStyle={styles.formContainerStyleSmall}
            containerStyle={styles.backOfficeContainerStyle}
          >
            <FormInput
              error={false}
              testID="section-name"
              placeholder={translate('backOfficeSettings.sectionName')}
              title={translate('backOfficeSettings.sectionName')}
              value={form.name || ''}
              alignTitle="left"
              containerStyle={styles.formInputContainerStyle}
              onChangeText={onChange.bind(null, 'name')}
              textInputStyle={styles.formLargeTextStyle}
              textStyle={styles.labelStyle}
              maxLength={50}
            />

            <MultipleSelect
              selectedValues={selectedStores}
              label={translate('backOfficeSettings.storeAvailability')}
              testID="store-availability"
              containerStyle={css(dropDownContainerStyle)}
              values={storesOptions}
              searchPlaceHolder={translate('backOfficeSettings.searchStores')}
              searchLabel={translate('backOfficeSettings.stores')}
              onValueChange={onStoreSelect}
              textStyle={styles.textLabelStyle}
            />

            <DropDown
              title={translate('backOfficeSettings.tableNames')}
              values={tableNamingMethods.map((key: string) => ({
                value: key,
                label: key,
              }))}
              textStyle={isWeb ? {} : styles.dropLabelStyle}
              selectedValue={namingMethod}
              testID="table-name-methods"
              extraMainViewStyle={styles.dropDownMainViewStyle}
              extraStyle={styles.dropdownExtraStyle}
              extraViewStyle={styles.dropdownViewStyle}
              style={styles.formInputContainerStyle}
              onValueChange={onChangeTableNamingMethod}
            />

            <View style={styles.viewContainerStyle}>
              <FormInput
                error={false}
                testID="default-prefix"
                placeholder={translate('backOfficeSettings.defaultPrefix')}
                title={translate('backOfficeSettings.defaultPrefix')}
                value={form.defaultPrefix || ''}
                alignTitle="left"
                containerStyle={css({
                  ...styles.formInputContainerStyle,
                  width: 95,
                  justifyContent: 'flex-start',
                  // marginLeft: -15,
                })}
                onChangeText={onChange.bind(null, 'defaultPrefix')}
                textInputStyle={styles.formLargeTextStyle}
                textStyle={css({
                  ...styles.labelStyle,
                  marginBottom: isIos ? -15 : 0,
                  marginTop: isIos ? 0 : 10,
                  paddingVertical: 0,
                })}
                maxLength={50}
              />
              <View style={styles.viewStyle}>
                <DropDown
                  title={translate('backOfficeSettings.tableShapes')}
                  values={Object.values(TableShape).map((key: string) => ({
                    value: key,
                    label: key,
                  }))}
                  textStyle={isWeb ? {} : styles.textStyle}
                  selectedValue={form.defaultTableShape}
                  testID="table-shapes"
                  extraMainViewStyle={styles.dropDownMainStyle}
                  extraStyle={styles.dropdownStyle}
                  extraViewStyle={css({
                    ...styles.dropdownViewStyle,
                    width: 140,
                    justifyContent: 'flex-start',
                  })}
                  style={css({
                    ...styles.formInputContainerStyle,
                    width: 140,
                    paddingVertical: 7,
                  })}
                  angleDownIconStyle={styles.angleDownIconStyle}
                  onValueChange={onChange.bind(null, 'defaultTableShape')}
                  downIconAndroid={false}
                />
                <View style={styles.dropDownIconContainer}>
                  <Icon name="AngleDown" size={25} color="black" />
                </View>
              </View>
            </View>

            <View style={styles.tablesContainer}>
              {Object.values(tables).map((table, i) => (
                <TableIcon
                  onPressTable={onPressTable}
                  table={table}
                  key={`table-${i}`}
                />
              ))}
              <IconButton
                containerStyle={styles.addTableButton}
                icon="plus"
                iconColor="green"
                color="white"
                onPress={onPressAddTable}
              />
            </View>
          </BackOfficeSection>

          <View style={styles.bottomSpace}></View>
        </ScrollView>

        <View style={styles.actionsContainerStyle}>
          <Button
            fluid
            testID="delete-changes"
            title={translate('button.delete')}
            containerStyle={styles.deleteButtonStyle}
            labelStyle={styles.dangerTitleStyle}
            onPress={onDelete}
          />
          <Button
            fluid
            testID="save-changes"
            title={translate('button.saveChanges')}
            containerStyle={styles.saveButtonStyle}
            labelStyle={styles.titleStyle}
            onPress={onSave}
          />
        </View>
      </View>
    </>
  );
};
