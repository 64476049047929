import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { Icons, StyleFn } from '@hitz-group/domain';
import { useTranslation } from '@hitz-group/localization';
import { Text, View, StyleSheet } from 'react-native';
import Button from '../../Button/Button';
import { useModal } from '@hitz-group/rn-use-modal';
import ModalDialog from '../Modal';
import IconButton from '../../Button/IconButton';
import { noopHandler } from '../../../utils/errorHandlers';

const headingStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
  lineHeight: 20,
  letterSpacing: -0.4,
  width: '100%',
  textAlign: 'center',
  color: theme.colors.orange,
  fontFamily: theme.font.bold,
});

const messageStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  lineHeight: 20,
  letterSpacing: -0.4,
  width: '100%',
  textAlign: 'center',
  color: theme.colors.primary,
  fontFamily: theme.font.medium,
  marginTop: theme.spacing.medium,
  marginBottom: theme.spacing.medium,
  paddingHorizontal: theme.spacing.medium,
});

const dismissTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.white,
  textTransform: 'uppercase',
  ...theme.font14SemiBold,
});

const overrideTextStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.text,
  textTransform: 'uppercase',
  ...theme.font14SemiBold,
});

const warningIconStyle: StyleFn = ({ theme }) => ({
  marginTop: -theme.spacing.big,
  marginBottom: 16,
  alignSelf: 'center',
  width: 44,
  height: 40,
});

const buttonContainerStyle: StyleFn = ({ theme }) => ({
  height: 38,
  width: '100%',
  marginLeft: 0,
  backgroundColor: theme.colors.orange,
  alignSelf: 'center',
  paddingHorizontal: theme.spacing.medium,
  marginBottom: theme.spacing.small - 2,
});

const overrideButtonStyle: StyleFn = ({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginBottom: -theme.spacing.medium,
  opacity: 0.7,
});

const styles = StyleSheet.create({
  container: {
    width: 320,
  },
});

interface Props {
  title: string;
  message: string;
}

const PermissionsModal: React.FC<Props> = ({ title, message }) => {
  const { css, theme } = useFela();
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const onPressOverride = useCallback(() => {
    noopHandler();
  }, []);

  const onPressDismiss = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <ModalDialog onDismiss={onPressDismiss} showCloseButton={false}>
      <IconButton
        testID="warningIcon"
        icon={Icons.ExclamationTriangle}
        iconColor={theme.colors.orange}
        iconSize={44}
        containerStyle={css(warningIconStyle)}
        disabled
      />

      <View style={styles.container}>
        <Text style={css(headingStyle)} testID="permissionTitle">
          {title}
        </Text>
        <Text style={css(messageStyle)} testID="permissionMessage">
          {message}
        </Text>
        <Button
          testID="dismissControl"
          fluid
          onPress={onPressDismiss}
          labelStyle={css(dismissTextStyle)}
          containerStyle={css(buttonContainerStyle)}
          title={translate('authorization.dismiss')}
        />
        <Button
          testID="overrideControl"
          fluid
          onPress={onPressOverride}
          labelStyle={css(overrideTextStyle)}
          containerStyle={css([buttonContainerStyle, overrideButtonStyle])}
          title={translate('authorization.override')}
          disabled
        />
      </View>
    </ModalDialog>
  );
};

export default PermissionsModal;
