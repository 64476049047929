import React, { createContext } from 'react';
import { Availability } from './Availability/Availability';
import { Pricing } from './Pricings/Pricing';
import { General } from './General/General';
import { Variants } from './Variants/Variants';
import { Modifiers } from './Modifiers/Modifiers';
// import { Sides } from './Sides/Sides';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import TabBar from '../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
const Tab = createMaterialTopTabNavigator();

interface RouteParams {
  productId: string;
  isVariant: boolean;
  isVariantProduct: boolean;
  screen?: string;
}
export const ProductContext = createContext({} as { params: RouteParams });

/**
  * For the Main product(Coffee) the below sections are hidden when it's said to have variant products
    - Pricing
    - Options,
    - Modifiers
    - Sides
  * For the Variant Product,  All the tabs are visible except for  ***Availability*** section
*/
const ProductSettingsStack: React.FC = () => {
  const route = useRoute();
  const params = route.params as RouteParams;

  return (
    <ProductContext.Provider value={{ params }}>
      <Tab.Navigator
        tabBar={(props: MaterialTopTabBarProps): React.ReactNode => (
          <TabBar tabBar={props as MaterialTopTabBarProps} />
        )}
        backBehavior="none"
        initialRouteName={params.screen || 'General'}
      >
        <Tab.Screen name="General" component={General} initialParams={params} />

        {!params?.isVariantProduct && (
          <Tab.Screen
            name="Availability"
            component={Availability}
            initialParams={params}
          />
        )}

        {!params?.isVariant && (
          <>
            <Tab.Screen
              name="Pricing"
              component={Pricing}
              initialParams={params}
            />
            <Tab.Screen
              name="Options"
              component={Modifiers}
              initialParams={params}
            />
          </>
        )}

        <Tab.Screen
          name="Variations"
          component={Variants}
          initialParams={params}
        />
        {/* <Tab.Screen name="Sides" component={Sides} initialParams={params} /> */}
      </Tab.Navigator>
    </ProductContext.Provider>
  );
};

export default ProductSettingsStack;
