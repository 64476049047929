import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { ActivityIndicator, View, ScrollView } from 'react-native';
import Layout from '../../../components/POSLayout/POSLayout';
import { useTranslation } from '@hitz-group/localization';
import {
  DrawerButton,
  LeftArrow,
} from '../../../components/HeaderIcons/HeaderIcons';
import Title from '../../../components/Title/Title';
import TableComponent from '../../../components/TableComponent/TableComponent';
import { PrintJobsListStyles } from './PrintJobs.styles';
import PrintJobRow from './PrintJobRow';
import { WORKER_MESSAGES_KEY } from '../../../state/preferences';
import {
  DEFAULT_NET_PRINTER_PORT,
  PrintWorkerActionData,
  WorkerActionResult,
  WorkerActionResultStatus,
} from '../../../workers/utils';
import * as storage from '../../../storage/interface';
import { failedPrintJobsCountVar } from '../../../state/cache';
import { WorkerAction } from '../../../workers/utils';
import { workerInstanceVar } from '../../../state/cache';
import { useReactiveVar } from '@apollo/client/react/hooks/useReactiveVar';
import SearchBar from '../../../components/SearchBar/SearchBar';
import Button from '../../../components/Button/Button';
import ReprintModal from '../../../components/Modals/Printers/ReprintModal';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import { usePrinters } from '../../../hooks/app/usePrinters';
import { useSession } from '../../../hooks/app/useSession';

export type PrintJob = WorkerActionResult;

const PrintJobs: React.FC = () => {
  const { translate } = useTranslation();
  const styles = PrintJobsListStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [filterText, setFilterText] = useState<string>('');
  const [failedPrintJobs, setFailedPrintJobs] = useState<PrintJob[]>([]);
  const failedPrintJobsCount = useReactiveVar(failedPrintJobsCountVar);
  const { showModal } = useModal();
  const [session] = useSession();
  const { printers, getPrinters } = usePrinters({
    storeId: session.currentStore?.id,
  });

  useEffect(() => {
    getPrinters && getPrinters();
  }, [getPrinters]);

  useEffect(() => {
    (async () => {
      failedPrintJobsCount;
      const responses =
        (await storage.getItem<WorkerActionResult[]>(WORKER_MESSAGES_KEY)) ||
        [];

      setFailedPrintJobs(
        (responses || [])
          .filter(item => item.status === WorkerActionResultStatus.ERROR)
          .sort((item1, item2) =>
            (item1.timestamp || 0) < (item2.timestamp || 0) ? 0 : -1,
          ) as [],
      );
    })();
  }, [failedPrintJobsCount]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const workerInstance = workerInstanceVar();

  const remove = useCallback(
    async (printJobs: PrintJob[]) => {
      await storage.setItem<WorkerActionResult[]>(
        WORKER_MESSAGES_KEY,
        failedPrintJobs.filter(
          job =>
            !printJobs
              .map(printJob => printJob.requestId)
              .includes(job.requestId),
        ),
      );
    },
    [failedPrintJobs],
  );

  const reprint = useCallback(
    async (printJobs: PrintJob[]) => {
      await remove(printJobs);
      for (const printJob of printJobs) {
        const data = printJob.data as PrintWorkerActionData;
        if (workerInstance && data) {
          data.buffer &&
            data.printer &&
            workerInstance.send({
              action: WorkerAction.PRINT,
              data: {
                bufferObjs: [{ buffer: data.buffer, printer: data.printer }],
                order: data?.order,
              },
            });
        }
      }
    },
    [workerInstance, remove],
  );

  const filteredJobs = useMemo(
    () =>
      failedPrintJobs.filter(
        job =>
          (job.timestamp || '')
            ?.toString()
            .toLowerCase()
            .startsWith(filterText.toLowerCase()) ||
          ((job.data as PrintWorkerActionData)?.order?.orderNumber || '')
            .toString()
            .toLowerCase()
            .startsWith(filterText.toLowerCase()),
      ),
    [filterText, failedPrintJobs],
  );

  const reprintAll = useCallback(() => {
    reprint(filteredJobs);
  }, [reprint, filteredJobs]);

  const clearAll = useCallback(() => {
    remove(filteredJobs);
  }, [remove, filteredJobs]);

  const onPressReprint = useCallback(
    (printJob: PrintJob) => {
      showModal(
        <ReprintModal
          onSubmit={(printerId?: string) => {
            printerId &&
              printers[printerId] &&
              reprint([
                {
                  ...printJob,
                  data: {
                    ...printJob.data,
                    printer: {
                      id: printerId,
                      device_name: printers[printerId].name,
                      host: printers[printerId].ipAddress,
                      bdAddress: printers[printerId].bdAddress,
                      brand: printers[printerId].brand,
                      port: DEFAULT_NET_PRINTER_PORT,
                      type: printers[printerId].printerType,
                    },
                  },
                },
              ]);
          }}
          printerId={(printJob.data as PrintWorkerActionData)?.printer?.id}
          printers={Object.values(printers)}
        ></ReprintModal>,
      );
    },
    [reprint, showModal, printers],
  );

  const headerTitle = (
    <Title
      primary
      containerStyle={styles.headerTitleStyle}
      testID="print-jobs-title"
    >
      {translate('printing.printingQueueLabel')}
    </Title>
  );
  const headerRight = <DrawerButton />;
  const headerLeft = <LeftArrow />;

  return (
    <Layout
      hasHeader={true}
      title={translate('navigation.shiftsTitle')}
      headerTitle={headerTitle}
      headerLeft={headerLeft}
      headerRight={headerRight}
      testID="print-jobs-page"
    >
      <View style={styles.filterContainerStyle}>
        <SearchBar
          secondary
          placeholder={translate('printing.searchPlaceholder')}
          containerStyle={styles.searchContainerStyle}
          onChange={text => setFilterText(`${text}`)}
          value={filterText}
          textInputStyle={styles.searchTextInputStyle}
          testID="print-jobs-search"
        />
        <Button
          title={translate('printing.reprintAll')}
          containerStyle={styles.printAllBtnStyle}
          labelStyle={styles.btnTextStyle}
          onPress={reprintAll}
          testID="print-jobs-reprint-all"
        />
        <Button
          title={translate('printing.clearAll')}
          containerStyle={styles.clearAllBtnStyle}
          labelStyle={styles.btnTextStyle}
          onPress={clearAll}
          testID="print-jobs-clear-all"
        />
      </View>

      <ScrollView style={styles.scrollStyle}>
        {loading ? (
          <ActivityIndicator style={styles.loadingStyle} />
        ) : (
          <TableComponent
            columnSpacing={25}
            columns={[
              {
                title: translate('printing.status'),
                flex: 1,
                alignItems: 'center',
              },
              {
                title: translate('printing.printType'),
                flex: 1,
                alignItems: 'center',
              },
              {
                title: translate('printing.orderNumber'),
                flex: 1,
                alignItems: 'center',
              },
              {
                title: translate('printing.timestamp'),
                flex: 1,
                alignItems: 'center',
              },
              {
                title: translate('printing.printer'),
                flex: 1,
                alignItems: 'center',
              },
              {
                title: translate('printing.description'),
                flex: 1,
                alignItems: 'center',
              },
              {
                title: '',
                flex: 1,
                alignItems: 'flex-end',
              },
            ]}
            data={filteredJobs}
            renderRow={(item: PrintJob, index: number): React.ReactNode => {
              return (
                <PrintJobRow
                  key={item.requestId}
                  printJob={item}
                  reprint={onPressReprint}
                  remove={remove}
                  testID={`print-job-${index}`}
                />
              );
            }}
            columnContainerStyle={styles.columnContainerStyle}
            normalRows
          />
        )}
      </ScrollView>
    </Layout>
  );
};

export default PrintJobs;
