import React, { useCallback, useRef, useState } from 'react';
import FormInput from '../FormInput/FormInput';
import { useFela } from 'react-fela';
import { StyleFn } from '@hitz-group/domain';
import scale from '../../common/theme';
import { debounce } from 'lodash';
import { Platform } from 'react-native';

export interface ColorPickerProps {
  title: string;
  value?: string;
  onColourChange?: (value: string) => void;
  containerStyle?: StyleFn;
}

const textStyle: StyleFn = ({ theme }) => ({
  marginLeft: theme.padding.small,
});

const textInputStyle: StyleFn = () => {
  return Platform.OS === 'web' ? { cursor: 'pointer' } : {};
};

const formInputContainerStyle: StyleFn = ({ theme }) => ({
  width: 270,
  height: theme.input.height,
  justifyContent: 'center',
});

const inputValueStyle = {
  visibility: 'hidden',
  position: 'absolute',
} as React.CSSProperties;

const hexPrefixStyle: StyleFn = ({ theme }) => ({
  padding: scale.moderateScale(7),
  backgroundColor: theme.colors.greyLight,
  height: '100%',
  color: theme.colors.primaryDarkGrey,
});

const hexPrefix = {
  textStyle: hexPrefixStyle,
  text: 'HEX',
};

const ColorPicker: React.FC<ColorPickerProps> = ({
  title,
  value,
  containerStyle,
  onColourChange,
}) => {
  const { css } = useFela();
  const inputRef = useRef<HTMLInputElement>(null);
  const [hide, setHide] = useState<boolean>(true);
  const [realtimeValue, setRealtimeValue] = useState<string>();

  const debounceInputFunction = debounce(() => {
    onColourChange && onColourChange(inputRef.current?.value || '');
  }, 500);

  const onBlur = useCallback(() => {
    inputRef.current?.blur();
    setHide(true);
  }, []);

  const onClick = useCallback(() => {
    if (hide) {
      inputRef.current?.click();
      setHide(false);
    } else {
      onBlur();
    }
  }, [hide, onBlur]);

  const debounceInput = useCallback(debounceInputFunction, [
    debounceInputFunction,
  ]);

  const handleColourChange = useCallback(
    event => {
      debounceInput();
      setRealtimeValue(event.target.value);
    },
    [debounceInput],
  );

  return (
    <>
      <FormInput
        value={value}
        title={title}
        alignTitle="left"
        containerStyle={css(containerStyle || formInputContainerStyle)}
        maxLength={50}
        textStyle={css(textStyle)}
        textInputStyle={css(textInputStyle)}
        prefix={{ ...hexPrefix, textStyle: css(hexPrefix.textStyle) }}
        onClick={onClick}
        onInputBlur={onBlur}
        verified={value !== ''}
        error={value === ''}
      />
      {Platform.OS === 'web' ? (
        <input
          ref={inputRef}
          type="color"
          value={realtimeValue}
          style={inputValueStyle}
          onChange={event => handleColourChange(event)}
        />
      ) : // TODO: Should add support for native later
      null}
    </>
  );
};

export default ColorPicker;
