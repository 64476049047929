import { useMutation } from '@apollo/client/react/hooks';
import { useCallback, useEffect } from 'react';
import { CreateDeviceInput } from '@hitz-group/domain';
import { CREATE_DEVICE, DELETE_DEVICE } from '../../graphql/devices';
import { Session } from '../../state/Session';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { useOrderNumber } from '../orders/useOrderNumber';
import { useSession } from './useSession';
import { requestPushNotificationToken } from '../../utils/firebaseClientHelper';
import { useDevices } from './useDevices';

export interface DeviceAssignmentProps {
  success: boolean;
  loading: boolean;
  assignDevice: (input: CreateDeviceInput) => void;
  unAssignDevice: (input: { id: string; device: string }) => void;
  error?: string;
}

export function useDeviceAssignment(): DeviceAssignmentProps {
  const [session, setSession] = useSession();
  const { setOrderCounter } = useOrderNumber();
  const { updateDeviceToken } = useDevices({
    storeId: session?.currentStore?.id,
  });
  const [assignDeviceMutation, assignDeviceStatus] = useMutation(
    CREATE_DEVICE,
    {
      onError: noopHandler,
    },
  );

  const assignDevice = useCallback(
    (input: CreateDeviceInput) => {
      assignDeviceMutation({ variables: { input } });
    },
    [assignDeviceMutation],
  );

  useEffect(() => {
    if (!!assignDeviceStatus.data) {
      const device = assignDeviceStatus.data.createDevice;
      const assignedDeviceProfile =
        session.currentStore?.deviceProfiles?.filter(
          x => x.id === device.deviceProfile.id,
        )[0];
      const updatedSession: Session = {
        ...session,
        deviceProfile: {
          ...assignedDeviceProfile,
          defaultOrderType:
            device.deviceProfile?.defaultOrderType ||
            device.deviceProfile?.orderTypes?.[0] ||
            {},
          functionMap:
            device.deviceProfile?.functionMap ||
            assignedDeviceProfile?.functionMap,
        },
        device,
      };
      setSession(updatedSession);
      setOrderCounter(device.previousOrder?.orderNumber);
      if (device) {
        (async () => {
          const fcmToken = await requestPushNotificationToken();
          if (fcmToken) {
            updateDeviceToken({
              id: device.id,
              pushNotificationToken: fcmToken,
            });
          }
        })();
      }
    }
  }, [
    setSession,
    assignDeviceStatus,
    session,
    setOrderCounter,
    updateDeviceToken,
  ]);

  const [unAssignDeviceMutation, unAssignDeviceStatus] = useMutation(
    DELETE_DEVICE,
    {
      onError: noopHandler,
    },
  );

  const unAssignDevice = useCallback(
    (input: { id: string; device: string }) => {
      unAssignDeviceMutation({ variables: input });
    },
    [unAssignDeviceMutation],
  );

  useEffect(() => {
    if (!!unAssignDeviceStatus.data) {
      const updatedSession: Session = {
        ...session,
        deviceProfile: undefined,
      };

      setSession(updatedSession);
    }
  }, [setSession, unAssignDeviceStatus, session]);

  const error = assignDeviceStatus.error || unAssignDeviceStatus.error;
  const success = !!assignDeviceStatus.data || !!unAssignDeviceStatus.data;
  const loading = assignDeviceStatus.loading || unAssignDeviceStatus.loading;
  const result = {
    success,
    loading,
    assignDevice,
    unAssignDevice,
    error: error ? parseApolloError(error) : undefined,
  };

  return result;
}
