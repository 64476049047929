import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { CreateVenueInput, Venue } from '@hitz-group/domain';
import {
  CREATE_VENUE,
  ACTIVATE_VENUE,
  GET_VENUE_BY_ID,
  GET_USER_VENUES_QUERY,
} from '../../graphql/venue';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import keyBy from 'lodash/keyBy';
import { useSession } from './useSession';

export interface UseVenues {
  loading: boolean;
  error?: string;
  venues: { [key: string]: Venue };
  createdVenueId: string | undefined;
  activatedVenueId: string | undefined;
  getVenues: () => void;
  createVenue: (input: CreateVenueInput) => void;
  activateVenue: (input: { id: string; isActive: boolean }) => void;
  searchVenues: (searchString: string) => Promise<Venue[]>;
}

/**
 * This is being used for user specific venues
 * @returns
 */
export const useVenues = (): UseVenues => {
  const [venues, setVenues] = useState<Record<string, Venue>>({});
  const [createdVenueId, setCreatedVenueId] = useState<string | undefined>();
  const [activatedVenueId, setActivatedVenueId] = useState<
    string | undefined
  >();
  const [session, setSession] = useSession();

  // get venues
  const onCompleteGetVenuesRequest = useCallback(data => {
    if (data) {
      setVenues(keyBy(data.me.venues, 'id'));
    }
  }, []);

  const [getVenuesRequest, getVenuesResponse] = useLazyQuery(
    GET_USER_VENUES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetVenuesRequest,
    },
  );

  // get venues
  const onCompleteGetVenueRequest = useCallback(data => {
    if (data) {
      const venue = data.venue;
      setVenues(venues => ({ ...venues, [venue.id]: venue }));
    }
  }, []);

  const [getVenueRequest, getVenueResponse] = useLazyQuery(GET_VENUE_BY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onCompleteGetVenueRequest,
  });

  /**
   * Search venues
   *
   * filters by venue name and store name
   * matches names containing search string
   */
  const searchVenues = useCallback(
    async (searchString: string) => {
      const result: Venue[] = [];
      Object.values(venues).filter(venue => {
        if (venue.name.toLowerCase().includes(searchString.toLowerCase())) {
          result.push({ ...venue });
          return;
        }

        if (
          venue.stores.some(store =>
            store.name.toLowerCase().includes(searchString.toLowerCase()),
          )
        ) {
          return result.push({
            ...venue,
            stores: venue.stores.filter(store =>
              store.name.toLowerCase().includes(searchString.toLowerCase()),
            ),
          });
        }
      });
      return result;
    },
    [venues],
  );

  // create venue
  const [createVenueRequest, createVenueResponse] = useMutation(CREATE_VENUE, {
    onError: noopHandler,
  });

  const addVenueToSession = useCallback(
    newVenue => {
      if (newVenue) {
        if (!session.user?.venues?.find(venue => venue.id == newVenue.id)) {
          setSession({
            ...session,
            user: {
              ...session.user,
              venues: [...(session.user?.venues || []), newVenue],
            },
          });
        }
      }
    },
    [session, setSession],
  );

  useEffect(() => {
    if (createVenueResponse.data) {
      const newVenue = createVenueResponse.data.createVenue;
      getVenueRequest({ variables: { id: newVenue.id } });
      setVenues(venues => ({ ...venues, [newVenue.id]: newVenue }));
      setCreatedVenueId(newVenue.id);
      addVenueToSession(newVenue);
    }
  }, [createVenueResponse.data, getVenueRequest, addVenueToSession]);

  const createVenue = useCallback(
    (input: CreateVenueInput) => {
      createVenueRequest({ variables: { input } });
    },
    [createVenueRequest],
  );

  // update venue status
  const [activateVenueRequest, activateVenueResponse] = useMutation(
    ACTIVATE_VENUE,
    {
      onError: noopHandler,
    },
  );

  useEffect(() => {
    if (activateVenueResponse.data) {
      const newVenue = activateVenueResponse.data.activateVenue;
      setVenues(venues => ({ ...venues, [newVenue.id]: newVenue }));
      setActivatedVenueId(newVenue.id);
    }
  }, [activateVenueResponse.data]);

  const activateVenue = useCallback(
    (input: { id: string; isActive: boolean }) => {
      activateVenueRequest({ variables: { input } });
    },
    [activateVenueRequest],
  );

  const loading =
    getVenuesResponse.loading ||
    getVenueResponse.loading ||
    createVenueResponse.loading ||
    activateVenueResponse.loading;
  const error =
    getVenuesResponse.error ||
    getVenueResponse.error ||
    createVenueResponse.error ||
    activateVenueResponse.error;

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      venues,
      createdVenueId,
      activatedVenueId,
      getVenues: getVenuesRequest,
      searchVenues,
      createVenue,
      activateVenue,
    }),
    [
      loading,
      error,
      venues,
      createdVenueId,
      activatedVenueId,
      getVenuesRequest,
      searchVenues,
      createVenue,
      activateVenue,
    ],
  );
};
