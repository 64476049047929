import { ErrorPolicy } from '@apollo/client';
import { Order, OrderAction, OrderEvent } from '@hitz-group/domain';
import React, { createContext, useContext, useMemo } from 'react';
import { useCart } from './orders/useCart';

export interface CartProviderProps {
  children: React.ReactNode;
}

export interface CartContextValue {
  status: {
    error: string;
    loading: boolean;
  };
  updateCart: <T extends OrderEvent>(
    action: OrderAction,
    input?: Omit<T, keyof OrderEvent>,
  ) => void;
  discardChanges: () => void;
  resetCart: () => Promise<string>;
  clearPriorPendingEvents: () => void;
  order: Order | undefined;
  isDirty: boolean;
  itemsChanged: boolean;
  getOrderData: (
    orderId: string,
    errorPolicy?: ErrorPolicy,
  ) => Promise<void | Order>;
  mergeCachedOrderWithEvents: (orderId: string) => Promise<void | Order>;
  setCartParams: (
    orderId?: string,
    orderTypeId?: string,
    tableId?: string,
    isExisting?: boolean,
  ) => void;
  initiateRefund: (prevOrder: Order, reason: string) => void;
  closeOrderCart: () => void;
  openOrderCart: (orderId: string) => void;
}

export const CartContext = createContext<CartContextValue>(
  {} as CartContextValue,
);

export const CartProvider: React.FC<CartProviderProps> = ({
  children,
}: CartProviderProps) => {
  const result = useCart();
  const value = useMemo(() => result, [result]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export function useCartContext(): CartContextValue {
  const context = useContext(CartContext);
  if (!context)
    throw new Error(
      '`useCartContext` hook must be used within a `CartProvider` component',
    );
  return context;
}
