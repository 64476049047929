import React, { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client/react/hooks';
import { useSession } from './useSession';
import { useOrderNumber } from '../orders/useOrderNumber';
import { tokenUtility } from '../../state/tokenUtility';
import { LOGOUT_MUTATION } from '../../graphql/session';
import { parseApolloError } from '../../utils/errorHandlers';
import { LogoutStatus } from '@hitz-group/domain';
import { getQueue, resetQueue } from '../../events/eventsQueue';
import { useModal } from '@hitz-group/rn-use-modal';
import { OrderSyncAlertModal } from '../../components/Modals/OrderSyncAlert/OrderSyncAlert';
import { useSyncOrderEvents } from './useSyncOrderEvents';
import { onboardingUtility } from '../../state/onboardingUtility';
import { userUtility } from '../../state/userUtility';
import { setUserActivity } from '../../state/preferences';
import { identifyLogRocketUser } from '../../utils/logRocketHelper';
import { Session } from '../../state/Session';
import Database from '../../storage/database';

interface LogoutRequest {
  token: string;
}

export interface UseLogout {
  logout: () => Promise<void>;
  loading: boolean;
  error: string | undefined;
}
/**
 * This hook only be used within protected screens / components
 * It will listen to authState changes, and redirects user back to login screen
 *
 * @returns
 */
export const useLogout = (): UseLogout => {
  const client = useApolloClient();
  const [session, setSession] = useSession();
  const { setOrderCounter } = useOrderNumber();
  const { showModal } = useModal();
  const { syncAllOrderEvents } = useSyncOrderEvents();
  const [logoutRequest, { loading, error }] = useMutation<
    { logout: LogoutStatus },
    LogoutRequest
  >(LOGOUT_MUTATION);

  const resetStorage = useCallback(async () => {
    (await Database.getInstance()).collections.order_events?.remove();
  }, []);

  const resetLocalAuthState = async () => {
    // Reset client cache and store
    client.cache.reset();
    client.clearStore();
    userUtility.clearUserActivity();
    setUserActivity({
      posUser: undefined,
      recentUserId: undefined,
      officeUsers: {},
    });
    onboardingUtility.clearOnboardingInfo();
    tokenUtility.clearToken();
    await setSession({ authorized: false });
    // Clear order queued events
    resetQueue();
    setOrderCounter('-0');
  };

  const performLogout = async () => {
    try {
      if (session.token) {
        const result = await logoutRequest({
          variables: {
            token: session.token,
          },
        });
        if (result && result.data?.logout?.success) {
          await resetStorage();
          await resetLocalAuthState();
        }
      } else {
        await resetStorage();
        await resetLocalAuthState();
      }
      identifyLogRocketUser({} as Session);
    } catch {}
  };

  const onLogout = async () => {
    const data = await getQueue();
    if (!data || !data.length) performLogout();
    else {
      showModal(<OrderSyncAlertModal onSync={syncAllOrderEvents} />);
    }
  };

  return {
    logout: onLogout,
    loading,
    error: error ? parseApolloError(error) : undefined,
  };
};
