/* eslint-disable react-native/no-inline-styles */
import React, { useCallback } from 'react';
import BackOfficeSection from '../../../../../../components/BackOfficeSection/BackOfficeSection';
import { Helmet } from 'react-helmet';
import { useFela } from 'react-fela';
import { View, ScrollView, Text, Linking } from 'react-native';
import { useTranslation } from '@hitz-group/localization';
import { Device, DeviceProfile } from '@hitz-group/domain';
import { DeviceDetailsStyles } from '../styles/Device.styles';
import LoadingIndicator from '../../../../../../components/LoadingIndicator/LoadingIndicator';
import Button from '../../../../../../components/Button/Button';
import FormInput from '../../../../../../components/FormInput/FormInput';
import DropDown from '../../../../../../components/DropDown/DropDown';
import { useModal } from '@hitz-group/rn-use-modal/lib';
import ConfirmationDialog from '../../../../../../components/Modals/ConfirmationDialog';
import {
  REACT_APP_PLAYSTORE_URL,
  REACT_APP_APPSTORE_URL,
} from 'react-native-dotenv';

interface Props {
  device: Device;
  deviceProfiles: DeviceProfile[];
  options: { loading: boolean };
  onChange: (prop: string, value: string) => void;
  onSave: () => void;
  deleteDevice: () => void;
  resetDevice: () => void;
}

const DeviceDetails: React.FC<Props> = ({
  device,
  deviceProfiles,
  options: { loading },
  onChange,
  deleteDevice,
  onSave,
  resetDevice,
}) => {
  const { translate } = useTranslation();
  const styles = DeviceDetailsStyles();
  const { showModal, closeModal } = useModal();
  const { theme } = useFela();

  const onPressPlayStore = () => {
    Linking.openURL(
      REACT_APP_PLAYSTORE_URL ||
        (process.env['REACT_APP_PLAYSTORE_URL'] as string),
    );
  };

  const onPressAppStore = () => {
    Linking.openURL(
      REACT_APP_APPSTORE_URL ||
        (process.env['REACT_APP_APPSTORE_URL'] as string),
    );
  };
  const onPressDelete = useCallback(() => {
    const name = device?.name;
    showModal(
      <ConfirmationDialog
        title={translate('dialog.deleteTitle')}
        message={translate('dialog.deleteConfirmation', { label: name })}
        onConfirm={() => {
          closeModal();
          deleteDevice();
        }}
      />,
    );
  }, [showModal, closeModal, deleteDevice, translate, device]);

  const onPressReset = useCallback(() => {
    const name = device?.name;
    showModal(
      <ConfirmationDialog
        title={translate('dialog.resetTitle')}
        message={translate('dialog.resetConfirmation', { label: name })}
        onConfirm={() => {
          closeModal();
          resetDevice();
        }}
      />,
    );
  }, [showModal, closeModal, resetDevice, translate, device]);
  return (
    <View style={styles.pageStyle}>
      <Helmet>
        <title>
          {translate('navigation.generalSettingsPageTitle', {
            appName: translate('appName'),
          })}
        </title>
      </Helmet>

      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <ScrollView
            contentContainerStyle={styles.generalSettingsContainerWrapper}
          >
            <BackOfficeSection
              title={translate('backOfficeDevices.deviceSettings')}
              contentContainerStyle={styles.formContainerStyleSmall}
              containerStyle={styles.deviceDetailsSectionStyle}
              iconColor={theme.colors.blue}
              icon={'info-circle'}
            >
              <FormInput
                error={false}
                placeholder={translate('backOfficeDevices.deviceName')}
                title={translate('backOfficeDevices.deviceName')}
                value={device.name}
                alignTitle="left"
                testID="device-name"
                containerStyle={styles.formInputContainerStyle}
                onChangeText={onChange.bind(null, 'name')}
              />
              <DropDown
                title={translate('backOfficeDevices.register')}
                options={deviceProfiles.map(deviceProfile => ({
                  value: deviceProfile.id,
                  label: deviceProfile.name,
                }))}
                selectedValue={device.deviceProfile?.id}
                extraPopoverStyle={styles.popOverStyle}
                containerStyle={styles.registerDropdownContainerStyle}
                itemsContainerStyle={styles.dropDownItemsContainerStyle}
                titleStyle={styles.dropDownLabelText}
                onValueChange={onChange.bind(null, 'register')}
              />
              <FormInput
                error={false}
                placeholder={translate('backOfficeDevices.salesPrefix')}
                title={translate('backOfficeDevices.salesPrefix')}
                value={device.salesPrefix}
                alignTitle="left"
                testID="device-salesPrefix"
                containerStyle={styles.formInputContainerStyle}
                textStyle={styles.formLabelStyle}
                onChangeText={onChange.bind(null, 'salesPrefix')}
              />
              <FormInput
                error={false}
                placeholder={translate('backOfficeDevices.returnPrefix')}
                title={translate('backOfficeDevices.returnPrefix')}
                value={device.returnPrefix}
                alignTitle="left"
                testID="device-returnPrefix"
                containerStyle={styles.formInputContainerStyle}
                textStyle={styles.formLabelStyle}
                onChangeText={onChange.bind(null, 'returnPrefix')}
              />
              <DropDown
                title={translate('backOfficeDevices.cashDrawer')}
                options={['Default'].map(cashDrawer => ({
                  value: cashDrawer,
                  label: cashDrawer,
                }))}
                selectedValue={device.cashDrawer}
                extraPopoverStyle={styles.popOverStyle}
                containerStyle={styles.dropdownContainerStyle}
                itemsContainerStyle={styles.dropDownItemsContainerStyle}
                onValueChange={onChange.bind(null, 'cashDrawer')}
                titleStyle={styles.dropDownLabelText}
              />
              <FormInput
                error={false}
                placeholder={device.paymentTerminal?.name}
                title={translate('backOfficeDevices.paymentTerminal')}
                alignTitle="left"
                containerStyle={styles.formInputContainerStyle}
                textStyle={styles.formLabelStyle}
                readOnly
              />
            </BackOfficeSection>
            {device.isPaired ||
              (device.uuid && (
                <BackOfficeSection
                  title={translate('backOfficeDevices.deviceInfo')}
                  contentContainerStyle={styles.formContainerStyleSmall}
                  containerStyle={styles.deviceDetailsSectionStyle}
                  iconColor={theme.colors.blue}
                  icon={'info-circle'}
                >
                  <View style={styles.deviceView}>
                    <Text style={styles.labelStyle}>
                      {translate('backOfficeDevices.details')}
                    </Text>
                    <Text style={styles.textStyle}>{device.details}</Text>
                    <Text style={styles.labelStyle}>
                      {translate('backOfficeDevices.uuid')}
                    </Text>
                    <Text style={styles.textStyle}>{device.uuid}</Text>
                    <Text style={styles.labelStyle}>
                      {translate('backOfficeDevices.appVersion')}
                    </Text>
                    <Text style={styles.textStyle}>{device.appVersion}</Text>
                  </View>
                </BackOfficeSection>
              ))}
            {device.deviceCode && (
              <BackOfficeSection
                title={translate('backOfficeDevices.deviceCode')}
                contentContainerStyle={styles.formContainerStyleSmall}
                containerStyle={styles.deviceDetailsSectionStyle}
                iconColor={theme.colors.blue}
                icon={'info-circle'}
              >
                <View style={styles.deviceCodeSectionStyle}>
                  <View style={styles.deviceCodeContainer}>
                    <Text style={styles.deviceCodeText}>
                      {device.deviceCode?.slice(0, 4) +
                        ' ' +
                        device.deviceCode?.slice(4)}
                    </Text>
                  </View>
                  <Text style={styles.deviceCodeNote}>
                    <Text>
                      {translate('backOfficeDevices.downloadTillXAppFrom')}
                    </Text>
                    <Text
                      style={styles.deviceCodeNoteUrl}
                      onPress={onPressAppStore}
                      testID="app-store"
                    >
                      {translate('backOfficeDevices.appStore')}
                    </Text>
                    <Text>
                      <Text>{translate('backOfficeDevices.or')} </Text>
                      <Text
                        style={styles.deviceCodeNoteUrl}
                        onPress={onPressPlayStore}
                        testID="play-store"
                      >
                        {translate('backOfficeDevices.playStore')}
                      </Text>
                      <Text>
                        {translate('backOfficeDevices.andThen')}
                        {'\n'}
                        {translate(
                          'backOfficeDevices.enterThisCodeToPairYourDevice',
                        )}
                      </Text>
                    </Text>
                  </Text>
                  <Button
                    title={translate('backOfficeDevices.resetCode')}
                    labelStyle={styles.dangerTitleStyle}
                    onPress={onPressReset}
                    testID="reset-code"
                  />
                </View>
              </BackOfficeSection>
            )}
          </ScrollView>
          <View style={styles.actionsContainerStyle}>
            <Button
              fluid
              title={translate('button.delete')}
              containerStyle={styles.deleteButtonStyle}
              labelStyle={styles.dangerTitleStyle}
              onPress={onPressDelete}
              testID="delete-btn"
            />
            <Button
              fluid
              testID="save-changes"
              title={translate('button.saveChanges')}
              containerStyle={styles.saveButtonStyle}
              labelStyle={styles.titleStyle}
              onPress={onSave}
            />
          </View>
        </>
      )}
    </View>
  );
};

export default DeviceDetails;
