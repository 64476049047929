import * as Unicons from '@tillpos/react-native-unicons';
import { pascalCase } from 'change-case';
import React from 'react';
import { TextStyle } from 'react-native';
import { FelaComponent } from 'react-fela';
import { RenderProps, StyleFn } from '@hitz-group/domain';
export interface IconProps {
  name: string;
  color?: string;
  size?: number;
  style?: TextStyle;
  disabled?: boolean;
  primary?: boolean;
  success?: boolean;
  danger?: boolean;
  secondary?: boolean;
  testID?: string;
}

const iconStyle: StyleFn = ({
  theme,
  size,
  disabled,
  primary,
  color,
  success,
  danger,
  secondary,
}) => {
  return {
    color:
      color ||
      (disabled
        ? theme.colors.text
        : primary
        ? theme.colors.primary
        : success
        ? theme.colors.success
        : danger
        ? theme.colors.danger
        : secondary
        ? theme.colors.secondary
        : theme.colors.white),
    width: size,
  };
};

export const Icon: React.FC<IconProps> = ({ name, ...props }: IconProps) => {
  // Icon name examples: user, user-outline
  // This needs to be converted to UilUser, UilUserOutline
  const Component = Unicons['Uil' + pascalCase(name)];

  if (!Component) {
    return null;
  }

  return (
    <FelaComponent {...props} style={iconStyle} testID={props.testID}>
      {({ style }: RenderProps): React.ReactNode => (
        <Component
          size={props.size}
          color={props.color}
          style={[style, props.style]}
        />
      )}
    </FelaComponent>
  );
};

export default Icon;
