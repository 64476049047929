import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useFela } from 'react-fela';
import scale from '../../../common/theme';

export const CreateDeviceModalStyles = () => {
  const { theme } = useFela();

  return useMemo(
    () =>
      StyleSheet.create({
        formInputStyle: {
          height: theme.input.height,
          alignSelf: 'center',
          marginBottom: theme.spacing.small,
          width: scale.textInputWidth180,
        },
        formTextStyle: {
          left: 5,
        },
        buttonContainerStyle: {
          width: '100%',
          height: 44,
          borderRadius: theme.radius.small,
          backgroundColor: theme.colors.successLight,
          marginLeft: 0,
          marginTop: theme.spacing.medium,
        },
        labelStyle: {
          color: theme.colors.success,
          fontFamily: theme.font.semibold,
          textTransform: 'none',
        },
        dropdownViewStyle: {
          justifyContent: 'center',
          width: '100%',
          marginTop: scale.moderateScale(2),
        },
        dropdownExtraStyle: {
          width: scale.textInputWidth180,
          height: theme.input.height,
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          marginHorizontal: scale.moderateScale(2),
        },
        dropDownMainViewStyle: {
          borderColor: theme.colors.boxBorder,
          width: '90%',
          borderRadius: theme.radius.small,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderWidth: 1,
          marginLeft: scale.moderateScale(7),
        },
        modalContainerStyle: {
          width: 330,
          flexDirection: 'column',
        },
      }),
    [theme],
  );
};
