import { setItem, getItem } from '../storage/interface';
import { OrderEvent } from '@hitz-group/domain';
import { ORDER_EVENTS_QUEUE_KEY } from '../state/preferences';

export const getQueue = async (): Promise<OrderEvent[]> => {
  const data = await getItem(ORDER_EVENTS_QUEUE_KEY);
  if (data) {
    return data as OrderEvent[];
  } else return [];
};

export const queueEvents = async (events: OrderEvent[]) => {
  const data = await getQueue();

  setItem(ORDER_EVENTS_QUEUE_KEY, [...data, ...events]);
};

export const resetQueue = () => {
  setItem(ORDER_EVENTS_QUEUE_KEY, []);
};
