import { gql } from '@apollo/client';

export const CUSTOMER_ACCOUNT_FRAGMENT = `
    id
    firstName
    lastName
    name
    email
    phone
    preferredAddress {
      line1
      city
      postalCode
      isoCountryCode
    }
    customerAccountDetails {
      maxOrderLimit
      maxBalanceLimit
      currentBalance
      accountPayment
    }
    loyaltyMember
    loyaltyPoints
    createdAt
  `;

export const GET_CUSTOMERS = gql`
  query getCustomers{
    customers {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const GET_CUSTOMER = gql`
  query customerById($id: ID!){
    customerById(id: $id){
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const ENROLL_CUSTOMER_LOYALTY = gql`
  mutation enrollCustomerLoyalty($input: EnrollCustomerInput!) {
    enrollCustomerLoyalty(input: $input)
    {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;
export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id)
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const UPDATE_CUSTOMER_BALANCE = gql`
  mutation updateCustomerBalance($input: UpdateCustomerBalanceInput!) {
    updateCustomerBalance(input: $input) {
      id
      ${CUSTOMER_ACCOUNT_FRAGMENT}
    }
  }
`;

export const GET_CUSTOMER_ACCOUNT_SETTINGS = gql`
  query customerAccountSettings {
    customerAccountSettings {
      defaultMaxOrderLimit
      defaultMaxBalanceLimit
      scheduleStatements
    }
  }
`;
