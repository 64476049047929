import React from 'react';
import { FelaComponent } from 'react-fela';
import { RenderProps, StyleFn } from '@hitz-group/domain';
import { ActivityIndicator } from 'react-native';
const loadingStyle: StyleFn = () => ({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

export const LoadingIndicator: React.FC = () => (
  <FelaComponent style={loadingStyle}>
    {({ style, theme }: RenderProps): React.ReactNode => (
      <ActivityIndicator color={theme.colors.primary} style={style} />
    )}
  </FelaComponent>
);
